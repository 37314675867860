import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useDataApi from '../../../hooks/useDataApi';
import Countries from '../countries/Countries';
import Checkboxes from './Checkboxes';
import {
	firstPageFormElements,
	fourthPageFormElements,
	pg,
	secondPageFormElements,
	thirdPageFormElements
} from './config';
import Inputs from './Inputs';
import Form, {
	FirstPage,
	SecondPage,
	Button,
	BackButton,
	ThirdPage,
	FourthPage,
	FifthPage
} from './SignUpAllStepsStyles';

const View = ({
	register,
	onSelectCountry,
	selectedCountry,
	handleSubmit,
	onSubmit,
	handleContinue,
	handleBack,
	showPage,
	showError,
	errors
}) => {
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<h1>Account Creation</h1>
			{showPage === pg.PAGE_1 && (
				<FirstPage>
					{firstPageFormElements.map(({ id, label, type, required }) => {
						return (
							<Fragment key={id}>
								<Inputs
									id={id}
									label={label}
									type={type}
									register={register}
									required={required}
								/>
								{showError(errors, id)}
							</Fragment>
						);
					})}
					<label htmlFor="search_input">Country of Citizenship</label>
					<Countries onSelect={onSelectCountry} selectedElement={selectedCountry} />
					<Button onClick={handleContinue}>CONTINUE</Button>
				</FirstPage>
			)}
			{showPage === pg.PAGE_2 && (
				<SecondPage>
					{secondPageFormElements.map(({ id, label, type, required }) => {
						return (
							<Fragment key={id}>
								<Inputs
									id={id}
									label={label}
									type={type}
									register={register}
									required={required}
								/>
								{showError(errors, id)}
							</Fragment>
						);
					})}
					<div className="button-container">
						<BackButton onClick={handleBack}>BACK</BackButton>
						<Button onClick={handleContinue}>CONTINUE</Button>
					</div>
				</SecondPage>
			)}
			{showPage === pg.PAGE_3 && (
				<ThirdPage>
					<p>
						In order to be eligible to receive market data free of charge, you must be a
						&#34;non-professional&#34; user.
					</p>
					<p>How do I qualify as a non-professional subscriber? </p>
					<p>
						To facilitate a decision as to whether a subscriber qualifies as a
						&#34;non-professional subscriber&#34;, the subscriber must answer the
						following questions during the onboarding process:
					</p>
					{thirdPageFormElements.map(({ id, label }) => {
						return <Checkboxes key={id} id={id} label={label} register={register} />;
					})}
					<div className="button-container">
						<BackButton onClick={handleBack}>BACK</BackButton>
						<Button onClick={handleContinue}>CONTINUE</Button>
					</div>
				</ThirdPage>
			)}
			{showPage === pg.PAGE_4 && (
				<FourthPage>
					{fourthPageFormElements.map(({ id, label }) => {
						return <Checkboxes key={id} id={id} label={label} register={register} />;
					})}
					<div className="button-container">
						<BackButton onClick={handleBack}>BACK</BackButton>
						<Button type="submit">SEND</Button>
					</div>
				</FourthPage>
			)}
			{showPage === pg.PAGE_5 && (
				<FifthPage>
					<div className=" text-center">
						Thanks for completing the Non-Professional Data Qualification form. You will
						receive an email shortly with the Login and Password to your personal cloud
						trading platform.
					</div>
				</FifthPage>
			)}
		</Form>
	);
};

const SignUpAllSteps = ({ showPage, setShowPage }) => {
	const [selectedCountry, setSelectedCountry] = useState('');
	const [signupSubmitData, setSignSubmitData] = useState({});
	const url = `/account`;

	const response = useDataApi(
		'',
		{},
		{
			method: 'POST',
			body: JSON.stringify(signupSubmitData),
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);
	const setUrl = response[1];

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isValid },
		trigger
	} = useForm();

	const handleContinue = async (e) => {
		e.preventDefault();
		const isValidate = await trigger();
		if (isValidate) {
			const nextPage = (showPage + 1) % Object.keys(pg).length;
			setShowPage(nextPage);
		} else {
			return;
		}
	};

	const handleBack = (e) => {
		e.preventDefault();
		const prevPage = (showPage - 1) % Object.keys(pg).length;
		setShowPage(prevPage);
	};

	const onSubmit = (data) => {
		setSignSubmitData(createSignupReqBody(data));
		setShowPage(showPage + 1);
	};

	const onSelectCountry = (country) => {
		const countryName = country[0].name;
		setValue('citizenship', countryName);
		setSelectedCountry(country);
	};

	const showError = (errors, field) => {
		return (
			<>
				{errors[field]?.type === 'required' && (
					<span key={field} className="error">
						This field is required.
					</span>
				)}
			</>
		);
	};

	useEffect(() => {
		setUrl(isValid ? url : '');
	}, [signupSubmitData]);

	return (
		<View
			register={register}
			onSelectCountry={onSelectCountry}
			handleSubmit={handleSubmit}
			onSubmit={onSubmit}
			handleContinue={handleContinue}
			handleBack={handleBack}
			showPage={showPage}
			selectedCountry={selectedCountry}
			showError={showError}
			errors={errors}
		/>
	);
};

const createSignupReqBody = (data) => {
	return {
		...data,
		marketdata_personal: getFlag(data.marketdata_personal),
		marketdata_business: getFlag(data.marketdata_business),
		registered_sec: getFlag(data.registered_sec),
		registered_any: getFlag(data.registered_any),
		registered_need: getFlag(data.registered_need),
		advice: getFlag(data.advice),
		asset_manager: getFlag(data.asset_manager),
		manage_capital: getFlag(data.manage_capital),
		manage_trading: getFlag(data.manage_trading),
		share_profits: getFlag(data.share_profits),
		benefits: getFlag(data.benefits)
	};
};

const getFlag = (value) => (value ? 1 : 2);

export default SignUpAllSteps;
