import { useState } from 'react';
import AlgoStrategies from './AlgoStrategies';
import RefreshButton from '../buttons/RefreshButton';
import Styles from './AlgoStrategiesPageStyles';
import { timeStamp } from '../../utils';

import ErrorDialog from '../../components/modals/ErrorDialog/ErrorDialog';

const View = ({ refreshPage, setRefreshPage }) => {

	const [openErrorDialog, setOpenErrorDialog] = useState(false);

		var errors = localStorage.getItem("errors");
		console.log("errors:"+errors)
        var ws;
        var wsUri = "ws:";
        var loc = window.location;
        console.log(loc);
        if (loc.protocol === "https:") { wsUri = "wss:"; }
        // This needs to point to the web socket in the Node-RED flow
        // ... in this case it's ws/simple
		if ( !loc.host.includes("localhost"))
	    {
	        wsUri += "//" + loc.host + "/api/ws/errors";	//loc.pathname.replace("simple","ws/errors");
	    	console.log("server based")
	    }
        else
        {
        	wsUri += "//localhost:1880/ws/errors"
	    	console.log("localhost based")
        }
		console.log(wsUri)
        function wsConnect() {
            console.log("connect",wsUri);
            ws = new WebSocket(wsUri);
            //var line = "";    // either uncomment this for a building list of messages
            ws.onmessage = function(msg) {
                var line = "";  // or uncomment this to overwrite the existing message
                // parse the incoming message as a JSON object
                var data = JSON.parse(msg.data);
//                 console.log(data.error);
                // build the output from the topic and payload parts of the object
                if ("error" in data && typeof data.error === "string" && data.error.length !== 0)
				{
					line += "<p>"+data.error+"</p>";
					// replace the messages div with the new "line"
// 					document.getElementById('messages').innerHTML = line;
					localStorage.setItem("errors", data.error);

					setOpenErrorDialog(true);
					//ws.send(JSON.stringify({data:data}));
				}
	            else
					localStorage.setItem("errors", "");
//     	            document.getElementById('messages').innerHTML = "";
            }
            ws.onopen = function() {
                // update the status div with the connection status
//                 document.getElementById('status').innerHTML = "Interlisted socket connected";
                //ws.send("Open for data");
                console.log("Interlisted socket connected");
// 				if (errors.length > 0)
// 				{
// 					setOpenErrorDialog(true);
// 		
// 				}
            }
            ws.onclose = function() {
                // update the status div with the connection status
                document.getElementById('status').innerHTML = "not connected";
                // in case of lost connection tries to reconnect every 3 secs
                setTimeout(wsConnect,3000);
            }
        }
        
        function doit(m) {
            if (ws) { ws.send(m); }
        }
		wsConnect()
// 		ws.disconnect();

	return (
		<Styles>
			<div className="filter-container">
				<div className="algo-filter-item">
					<div>
						<RefreshButton setRefreshPage={setRefreshPage} />
					</div>
				</div>
			</div>
			<AlgoStrategies isPagination={true} refreshPage={refreshPage} />
			{openErrorDialog && (
				<ErrorDialog
					isOpen={openErrorDialog}
					handleStateChange={setOpenErrorDialog}
					
				/>
			)}
		</Styles>
	);
};

const AlgoStrategiesPage = () => {
	const [refreshPage, setRefreshPage] = useState(timeStamp);
	return <View {...{ refreshPage, setRefreshPage }} />;
};

export default AlgoStrategiesPage;
