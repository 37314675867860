import { formatNumber } from '../../../utils';

const PriceWithoutSysmbol = ({ value }) => {
	return (
		<div className={Number(value) >= 0 ? 'green-value' : 'red-value'}>
			<span>{formatNumber(value)}</span>
		</div>
	);
};

export default PriceWithoutSysmbol;
