import Multiselect from 'multiselect-react-dropdown';
import { useState } from 'react';
import { closeMultiSelectList } from '../../../utils';
import countries from '../../../utils/countries';

const View = ({ options, selectedValues, onSelect, disabled }) => {
	return (
		<div
			aria-labelledby="country"
			className={`country-selector ${disabled ? 'disabled' : ''}`}
			style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
			<Multiselect
				options={options}
				displayValue="name"
				selectedValues={selectedValues}
				onSelect={onSelect}
				selectionLimit={1}
				hidePlaceholder={true}
				closeOnSelect={true}
			/>
		</div>
	);
};
const Countries = ({ onSelect, selectedElement, disabled }) => {
	const [selectedValues] = useState(selectedElement);
	const handleSelect = (value) => {
		closeMultiSelectList();
		onSelect(value);
	};

	return (
		<View
			options={countries}
			selectedValues={selectedValues}
			onSelect={handleSelect}
			disabled={disabled}
		/>
	);
};

export default Countries;
