import styled from 'styled-components';

const NextPrevNavigatorStyles = styled.div`
	button {
		cursor: pointer;
		display: inline-block;
		padding: 8px 16px;
		margin-right: 5px;
	}
	button:disabled {
		background-color: var(--bg-grey3);
	}

	.navigator {
		border-color: transparent;
		background-color: var(--bg-orange);
		color: var(--font-white);
	}

	.round {
		border-radius: 20%;
	}
`;

export default NextPrevNavigatorStyles;
