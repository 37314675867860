export default [
	{ label: 'Login Name', id: 'login', url: '/profile/login' },
	{ label: 'First Name', id: 'firstname', url: '/profile/firstname' },
	{ label: 'Last Name', id: 'lastname', url: '/profile/lastname' },
	{ label: 'Password', id: 'password', url: '/profile/password' },
	{ label: 'Address', id: 'address', url: '/profile/address' },
	{ label: 'City', id: 'city', url: '/profile/city' },
	{ label: 'Zip', id: 'postalcode', url: '/profile/postalcode' },
	{ label: 'State', id: 'state', url: '/profile/state' },
	{ label: 'Country', id: 'country', url: '/profile/country' },
	{ label: 'Alpaca Status', id: 'alpaca_status', url: '/profile/alpaca_status' },
	{ label: 'Live Account API Account ID', id: 'live_acctid', url: '/profile/live_acctid' },
	{
		label: 'Live Account API Secret Key',
		id: 'live_secretkey',
		url: '/profile/live_secretkey'
	},
	{ label: 'Paper Account API Account ID', id: 'paper_acctid', url: '/profile/paper_acctid' },
	{
		label: 'Paper Account API Secret Key',
		id: 'paper_secretkey',
		url: '/profile/paper_secretkey'
	}
];
