import { useState } from 'react';
import TickerSelector from '../../components/tickerSelector/TickerSelector';
import TopStocks from '../../components/topStocks/TopStocks';
import RefreshButton from '../../components/buttons/RefreshButton';
import Styles from './TopStocksPageStyles';
import { timeStamp } from '../../utils';

const View = ({ refreshPage, setRefreshPage }) => (
	<Styles>
		<div className="card-header">
			<h3 className="card-title">
				<img src="/images/stock-icon.png" alt="Top Stocks" /> Top Stocks
			</h3>
			<div className="card-tools">
				<RefreshButton setRefreshPage={setRefreshPage} />
				<div className="multi-select-item">
					<TickerSelector />
					<img className="multi-select-icon" src="/images/search-icon.png" alt="serch" />
				</div>
			</div>
		</div>
		<TopStocks type="page" refreshPage={refreshPage} />
	</Styles>
);

const TopStocksPage = () => {
	const [refreshPage, setRefreshPage] = useState(timeStamp);
	return <View {...{ refreshPage, setRefreshPage }} />;
};

export default TopStocksPage;
