import { useEffect } from 'react';
import useDataApi from '../../hooks/useDataApi';
import getColumns from './getOrderHistoryColumns';
import List from '../list/List';

const View = ({
	data,
	isLoading,
	isError,
	orderType,
	isPagination = false,
	columns = getColumns(orderType)
}) => (
	<>
		{isError && <div>Something went wrong</div>}
		{isLoading ? (
			<div>Loading...</div>
		) : (
			<List newData={data} columns={columns} isPagination={isPagination} />
		)}
	</>
);

const OrderHistory = (props) => {
	const payload = {
		account_type: props.accountType || 'live',
		order_type: props.orderType,
		start_date: props.fromDate,
		end_date: props.toDate
	};

	const url = `/alpaca_orders?accountType=${props.accountType}&hash=${props.refreshPage}`;

	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	});
	useEffect(() => {
		setUrl(url);
	}, [props.accountType, props.refreshPage]);

	return <View {...props} {...dataProps} />;
};

export default OrderHistory;
