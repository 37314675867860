import WidgetBox from '../layout/WidgetBox/WidgetBox';
import AccountDetails from '../accountDetails/AccountDetails';
import './AccountInfo.css';
import { AccountInfoIcon } from '../../styles/icons/icons';

const View = ({ accountType }) => (
	<WidgetBox header="Account Info" link="#">
		<div className="card-header">
			<h3 className="card-title">
				<AccountInfoIcon /> Account Info
			</h3>
		</div>
		<div className="account-info">
			<AccountDetails accountType={accountType} />
		</div>
	</WidgetBox>
);

const AccountInfo = (props) => {
	return <View {...props} />;
};

export default AccountInfo;
