import { NavLink } from 'react-router-dom';
import useDataApi from '../../hooks/useDataApi';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import Amount from '../list/cells/Amount';
import AmountWithDelta from '../list/cells/AmountWithDelta';
import SymbolWithDelta from '../list/cells/SymbolWithDelta';
import List from '../list/List';
import ExpanderColumn from '../list/cells/ExpanderColumn';
import ContextMenu from '../list/cells/ContextMenu';
import { Pophover, PophoverElement, PophoverContent } from '../pophover/Pophover';
import PophoverChart from '../chart/PophoverChart';
import { formatAmount, formatNumber } from '../../utils';
import { SymbolLink } from '../../styles/links';
import { PortfolioIcon } from '../../styles/icons/icons';
import PortfolioNameWithTicker from '../list/cells/PortfolioNameWithTicker';
import { useState } from 'react';

const actionMenuEventHandler = (e, a, v) => {
	console.log(e);
	console.log(a);
	console.log(v);
};

const renderPophoverComponent = (row) => {
	return (
		<Pophover>
			<PophoverElement>
				<SymbolLink to={`/tickers?symbol=${row.symbol || row.ticker || ''}`}>
					<PortfolioNameWithTicker {...row} />
				</SymbolLink>
			</PophoverElement>
			<PophoverContent direction="right">
				<PophoverChart
					symbol={row.symbol}
					chartId="portfolio-pophover-chart"
					symbolType={row.asset_class}
				/>
			</PophoverContent>
		</Pophover>
	);
};

const getColumns = (preview, expandable) => {
	const expanderCol = {
		Header: 'Name',
		accessor: 'symbol',
		id: 'expander',
		Cell: ({ row }) => {
			return <ExpanderColumn row={row} />;
		}
	};
	const nameCol = {
		Header: 'Name',
		accessor: 'symbol',
		Cell: ({ row }) => {
			return renderPophoverComponent(row.original);
		}
	};
	const currentPrice = {
		Header: 'Current Price',
		accessor: 'current_price',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => formatAmount(props.value)
	};
	const shareCol = {
		Header: 'Shares',
		accessor: 'qty',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => formatNumber(props.value)
	};
	const marketCol = {
		Header: 'Market',
		accessor: 'market_value',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	};
	const todayWithDeltaCol = {
		Header: 'Today($)',
		accessor: 'unrealized_intraday_pl',
		SubHeader: '(%)',
		HeaderClass: 'header-notation text-center ',
		alignment: 'center',
		Cell: ({ row }) => {
			return (
				<AmountWithDelta
					value={{
						amount: row.original.unrealized_intraday_pl,
						delta: row.original.unrealized_intraday_plpc
					}}
				/>
			);
		}
	};
	const gainLossWithDeltaCol = {
		Header: 'All Time ($)',
		accessor: 'unrealized_pl',
		SubHeader: '(%)',
		HeaderClass: 'header-notation text-center',
		alignment: 'center',
		Cell: ({ row }) => {
			return (
				<AmountWithDelta
					value={{
						amount: row.original.unrealized_pl,
						delta: row.original.unrealized_plpc
					}}
				/>
			);
		}
	};
	const gainLossCol = {
		Header: 'All Time ($)',
		accessor: 'unrealized_pl',
		HeaderClass: 'header-notation text-center',
		alignment: 'center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	};
	// const actionCol = {
	// 	Header: '',
	// 	accessor: 'id',
	// 	Cell: (props) => {
	// 		return <ContextMenu value={props.value} handleClick={actionMenuEventHandler} />;
	// 	}
	// };
	const columns =
		preview == 'compact'
			? [nameCol, shareCol, marketCol, gainLossCol]
			: [nameCol, currentPrice, shareCol, marketCol, todayWithDeltaCol, gainLossWithDeltaCol];

	if (expandable) {
		columns.shift();
		columns.unshift(expanderCol);
	}

	return columns;
};

const ExpandPortfolioRow = ({ row }) => {
	return (
		<>
			{}
			<tr className="separator">
				<td colSpan="6"> </td>
			</tr>
			{row.original.stocks.map((stock, i) => {
				return (
					<tr key={i}>
						<td role="cell">
							{renderPophoverComponent(
								stock.symbol,
								<SymbolWithDelta value={stock.symbol} />
							)}
						</td>
						<td role="cell" align="center">
							{stock.shares}
						</td>
						<td role="cell" align="center">
							<Amount value={stock.market} />
						</td>
						<td role="cell" align="center">
							<AmountWithDelta value={stock.today} />
						</td>
						<td role="cell" align="center">
							<AmountWithDelta value={stock.gainloss} />
						</td>
						<td role="cell">
							<ContextMenu value={row.id} handleClick={actionMenuEventHandler} />
						</td>
					</tr>
				);
			})}
		</>
	);
};

const PortfolioFilter = ({ data, handleSelectChange, porfolioFilterId }) => {
	const keyValueMap = new Map();
	data.map((d) => keyValueMap.set(d.id, d.portfolioname));

	return (
		<div className="select-in-header">
			<label>Select Subportfolio:</label>
			<select onChange={handleSelectChange} value={porfolioFilterId}>
				<option value="">All Portfolios</option>
				{Array.from(keyValueMap.keys()).map((key) => {
					return (
						<option key={key} value={key}>
							{keyValueMap.get(key)}
						</option>
					);
				})}
			</select>
		</div>
	);
};

const View = ({
	data,
	isLoading,
	isError,
	preview,
	porfolioFilterId,
	handleSelectChange,
	expandable = false,
	type = 'dash'
}) => {
	const isListPage = type === 'page';
	return (
		<>
			<WidgetBox header="Portfolio" link="#">
				<div className="card-header">
					<h3 className="card-title">
						<PortfolioIcon /> Portfolio
					</h3>
				</div>
				{isError && <div>Something went wrong</div>}
				{isLoading ? (
					<div>Loading...</div>
				) : (
					<>
						{isListPage && (
							<PortfolioFilter
								data={data}
								porfolioFilterId={porfolioFilterId}
								handleSelectChange={handleSelectChange}
							/>
						)}
						<List
							newData={data.filter((d) =>
								porfolioFilterId ? d.id == porfolioFilterId : true
							)}
							columns={getColumns(preview, expandable)}
							RowSubComponent={ExpandPortfolioRow}
							isPagination={isListPage}
							pageSize={10}
						/>
					</>
				)}
				{!isListPage && (
					<div className="text-right">
						<NavLink to="/portfolio">
							<button className="button-sm btn-orange">View More</button>
						</NavLink>
					</div>
				)}
			</WidgetBox>
		</>
	);
};

const Portfolio = (props) => {
	const [porfolioFilterId, setPortfolioFilterId] = useState('');
	const [dataProps] = useDataApi('/alpaca_portfolio', [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ account_type: props.accountType || 'live' })
	});
	const handleSelectChange = (e) => {
		setPortfolioFilterId(e.target.value);
	};
	return (
		<View
			handleSelectChange={handleSelectChange}
			porfolioFilterId={porfolioFilterId}
			{...props}
			{...dataProps}
		/>
	);
};

export default Portfolio;
