import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from '../../../auth/AuthContext';
import ThemeSwitch from './ThemeSwitch';
import NavigationLinks from '../navigation/NavigationLinks';
import { headerItems } from '../navigation/navigationItems';
import './Header.css';
import Connect from '../navigation/Connect';
import signOut from '../../../auth/signOut';
import CompanyLogo from '../../../styles/companyLogo/CompanyLogo';
import useDataApi from '../../../hooks/useDataApi';

const View = ({
	toggleNav,
	onMobileNavIconClick,
	toggleTheme,
	onNavigationClick,
	signOut,
	setIsAuthenticated,
	navItems,
	userInitials,
	toggleNavList
}) => (
	<>
		<div className={`top-header ${toggleNav.mobile ? 'mobile-menu-overlay' : ''}`} id="overlay">
			<CompanyLogo />
			<nav className={`top-navbar ${toggleNav.mobile ? 'mobile-view-menu' : ''}`} id="myDIV">
				<ul>
					<NavigationLinks
						{...{
							toggleMobileNavigation: toggleNav.mobile,
							onMobileNavIconClick,
							navItems
						}}
					/>
					<Connect {...{ toggleNav, onNavigationClick, toggleNavList }} />
					<li className="mobile-menu-search">
						<input type="text" placeholder="Search"></input>
						<img className="menu-search_icon hide" src="/images/search-icon.png" />
					</li>
					<li className="mobile-menu-message">
						<div className="mobile-menu-text">Messages</div>
						<div className="menu-message hide">
							<img
								className="message-white-icon"
								src="/images/message-white-icon.png"
							/>
							<img className="message-icon" src="/images/message-icon.png" />
							<div className="message-icon-text"> 5</div>
						</div>
					</li>
					<li className="show-in-mobile">
						<a href="/accounts" onClick={onMobileNavIconClick}>
							Your Account
						</a>
					</li>
					<li>
						<ThemeSwitch toggleTheme={toggleTheme} />
					</li>
					<li
						className={`mobile-view-profile ${
							toggleNav.user ? 'user-navigation' : ''
						}`}>
						<a data-toggle="user" onClick={onNavigationClick}>
							<div className="user-panel">
								{/* <div className="user-panel-name">{userInitials}</div> */}
								<div className="user-panel-name"><img width="65" src="https://mtwelcomeletteremail.s3.amazonaws.com/account_480.png"/></div>
							</div>
						</a>
						<div className="user-navigation-menu" onClick={toggleNavList}>
							<ul>
								<li>
									<NavLink to="settings">Settings</NavLink>
								</li>

								<li>
									<a onClick={() => signOut(setIsAuthenticated)}> Sign Out</a>{' '}
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</nav>
			<div className="mobile-navbar" onClick={onMobileNavIconClick}>
				<img className="mobile-menu-show" src="/images/menu_icon.png" />
				<img className="mobile-menu-close" src="/images/close-menu.png" />
			</div>
		</div>
	</>
);

const Header = ({ toggleTheme }) => {
	const setIsAuthenticated = useContext(AuthContext)[1];
	const [userInitials, setUserInitials] = useState('');
	const defaultToggleNav = {
		mobile: false,
		user: false,
		connect: false
	};
	const [toggleNav, setToggleNav] = useState(defaultToggleNav);

	const [dataProps] = useDataApi('/profile', {}, { method: 'POST' });

	useEffect(() => {
		setUser(dataProps);
	}, [dataProps]);

	const setUser = (dataProps) => {
		if (!dataProps.isLoading && Object.keys(dataProps.data).length) {
			const username =
				dataProps.data?.FIRSTNAME ||
				dataProps.data?.LASTSTNAME ||
				dataProps.data?.login_name ||
				'';
			localStorage.setItem('username', username);
			setUserInitials(username.charAt(0).toUpperCase());
		}
	};

	const onNavigationClick = (e) => {
		const element = e.currentTarget.dataset.toggle;
		setToggleNav({ ...toggleNav, [element]: !toggleNav[element] });
	};
	const onMobileNavIconClick = () => setToggleNav({ ...toggleNav, mobile: !toggleNav.mobile });

	const toggleNavList = () => setToggleNav(defaultToggleNav);

	return (
		<View
			toggleNav={toggleNav}
			onMobileNavIconClick={onMobileNavIconClick}
			toggleTheme={toggleTheme}
			onNavigationClick={onNavigationClick}
			signOut={signOut}
			setIsAuthenticated={setIsAuthenticated}
			navItems={headerItems}
			userInitials={userInitials}
			toggleNavList={toggleNavList}
		/>
	);
};

export default Header;
