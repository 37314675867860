const Stocks = ({ value, classDiv = 'stock-info' }) => {
	return (
		<div className={classDiv}>
			{/* <span className={classSpan}>
				<img src={value.thumbnail || '/images/logo_cti.png'} alt={value.name}></img>
			</span> */}
			<span>
				<label>{value.symbol}</label>
				<label>{value.name}</label>
			</span>
		</div>
	);
};

export default Stocks;
