import { useState } from 'react';
import useDataApi from '../../hooks/useDataApi';
import HeadLines from '../../components/headLines/HeadLines';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../../components/tabs/Tabs';
import StockInfo from '../../components/stockInfo/StockInfo';

import Styles from './TickerPageStyles';
import CustomizeViewButton from '../../components/buttons/CustomizeViewButton';
import { formatAmount } from '../../utils';
import TickerDetail from '../../components/tickers/TickerDetail';
import { useQueryState } from '../../hooks/useQueryState';
import { LiveAccountIcon, PaperAccountIcon, TradeIcon } from '../../styles/icons/icons';

import ErrorDialog from '../../components/modals/ErrorDialog/ErrorDialog';

const View = ({
	setActiveSymbol,
	activeSymbol,
	accountType,
	setAccountType,
	paperAccountProps,
	liveAccountProps
}) => {

	const [openErrorDialog, setOpenErrorDialog] = useState(false);

		var errors = localStorage.getItem("errors");
		console.log("errors:"+errors)
        var ws;
        var wsUri = "ws:";
        var loc = window.location;
        console.log(loc);
        if (loc.protocol === "https:") { wsUri = "wss:"; }
        // This needs to point to the web socket in the Node-RED flow
        // ... in this case it's ws/simple
		if ( !loc.host.includes("localhost"))
	    {
	        wsUri += "//" + loc.host + "/api/ws/errors";	//loc.pathname.replace("simple","ws/errors");
	    	console.log("server based")
	    }
        else
        {
        	wsUri += "//localhost:1880/ws/errors"
	    	console.log("localhost based")
        }
		console.log(wsUri)
        function wsConnect() {
            console.log("connect",wsUri);
            ws = new WebSocket(wsUri);
            //var line = "";    // either uncomment this for a building list of messages
            ws.onmessage = function(msg) {
                var line = "";  // or uncomment this to overwrite the existing message
                // parse the incoming message as a JSON object
                var data = JSON.parse(msg.data);
//                 console.log(data.error);
                // build the output from the topic and payload parts of the object
                if ("error" in data && typeof data.error === "string" && data.error.length !== 0)
				{
					line += "<p>"+data.error+"</p>";
					// replace the messages div with the new "line"
// 					document.getElementById('messages').innerHTML = line;
					localStorage.setItem("errors", data.error);

					setOpenErrorDialog(true);
					//ws.send(JSON.stringify({data:data}));
				}
	            else
					localStorage.setItem("errors", "");
//     	            document.getElementById('messages').innerHTML = "";
            }
            ws.onopen = function() {
                // update the status div with the connection status
//                 document.getElementById('status').innerHTML = "Interlisted socket connected";
                //ws.send("Open for data");
                console.log("Interlisted socket connected");
// 				if (errors.length > 0)
// 				{
// 					setOpenErrorDialog(true);
// 		
// 				}
            }
            ws.onclose = function() {
                // update the status div with the connection status
                document.getElementById('status').innerHTML = "not connected";
                // in case of lost connection tries to reconnect every 3 secs
                setTimeout(wsConnect,3000);
            }
        }
        
        function doit(m) {
            if (ws) { ws.send(m); }
        }
		wsConnect()
// 		ws.disconnect();
	return (
		<Styles>
			<div className="wrapper-container">
				<div className="header-container padding-bttom-10 padding-top-20">
					<h3 className="card-title trade-icon">
						<TradeIcon /> Ticker Detail - Stocks Only
					</h3>

					<div className="header-right-container">
						<CustomizeViewButton />
					</div>
				</div>

				<div className="card-main-container padding-top-0">
					<div className="view-container">
						<div className="card padding-left-zero padding-top-0 tab-transparent">
							<Tabs
								activeId="live-account-tab"
								setAccountType={setAccountType}
								setSymbol={setActiveSymbol}>
								<TabNavItems>
									<TabNavItem
										id="live-account-tab"
										info={formatAmount(liveAccountProps.data?.equity)}
										title="Live Account">
										<LiveAccountIcon />
									</TabNavItem>
									<TabNavItem
										id="demo-account-tab"
										title="Paper Account"
										info={formatAmount(paperAccountProps.data?.equity)}>
										<PaperAccountIcon />
									</TabNavItem>
								</TabNavItems>

								<TabContents>
									<TabContent id="live-account-tab">
										<TickerDetail
											accountType={accountType}
											activeSymbol={activeSymbol}
											setActiveSymbol={setActiveSymbol}
										/>
									</TabContent>

									<TabContent id="demo-account-tab">
										<TickerDetail
											accountType={accountType}
											activeSymbol={activeSymbol}
											setActiveSymbol={setActiveSymbol}
										/>
									</TabContent>
								</TabContents>
							</Tabs>
						</div>
					</div>

					<div className="right-side-bar margin-top-30">
						<div className="trade-info">
							{activeSymbol && <StockInfo symbol={activeSymbol} />}
						</div>
						<HeadLines symbol={activeSymbol} />
					</div>
				</div>
			</div>
			{openErrorDialog && (
				<ErrorDialog
					isOpen={openErrorDialog}
					handleStateChange={setOpenErrorDialog}
					
				/>
			)}
		</Styles>
	);
};

const TickerPage = () => {
	const [activeSymbol, setActiveSymbol] = useQueryState('symbol');
	const [accountType, setAccountType] = useState('live');

	const [paperAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'paper' })
		}
	);
	const [liveAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'live' })
		}
	);

	return (
		<View
			{...{
				setActiveSymbol,
				activeSymbol,
				accountType,
				setAccountType,
				paperAccountProps,
				liveAccountProps
			}}
		/>
	);
};

export default TickerPage;
