export default (data, symbol) => {
	console.log(symbol);
	let seriesData = Array.from(data.values());

	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)'
		},
		series: seriesData.length
			? [
					{
						name: seriesData[0].name,
						data: seriesData[0].data,
						color: 'red'
					},
					{
						name: seriesData[1].name,
						data: seriesData[1].data,
						color: 'blue'
					}
			  ]
			: [],
		legend: {
			enabled: false
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 1
		},
		yAxis: [
			{
				opposite: false,
				lineWidth: 1,
				gridLineWidth: 1,
				title: null,
				tickWidth: 1,
				tickLength: 5,
				tickInterval: 0.5,
				tickPosition: 'inside',
				labels: {
					align: 'left',
					//x: -25
					x: 8
				}
			},
			{
				opposite: true,
				linkedTo: 0,
				lineWidth: 1,
				gridLineWidth: 0,
				tickWidth: 1,
				tickLength: 5,
				tickInterval: 1,
				tickPosition: 'inside',
				labels: {
					align: 'right',
					//x: 15
					x: -8
				},
				title: {
					text: 'Strategy'
				}
			}
		],
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};
