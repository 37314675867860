import { NavLink } from 'react-router-dom';

const OrdersButton = () => (
	<div className="text-right margin-left-auto">
		<NavLink to="/orders">
			<button className="button-sm btn-orange">View More</button>
		</NavLink>
	</div>
);

export default OrdersButton;
