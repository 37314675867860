import HeadLines from '../../components/headLines/HeadLines';
import { useState } from 'react';
import PortfolioChart from '../../components/chart/PortfolioChart';
import AccountMetrics from '../../components/accountMetrics/AccountMetrics';
import AccountDetailDialog from '../../components/modals/AccountDetailDialog/AccountDetailDialog';
import './DashboardPage.css';
import Portfolio from '../../components/portfolio/Portfolio';
import AlgoStrategies from '../../components/algoStrategies/AlgoStrategies';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../../components/tabs/Tabs';
import MarketIndicators from '../../components/marketIndicators/MarketIndicators';
import Watchlist from '../../components/watchList/WatchList';
import TopStocks from '../../components/topStocks/TopStocks';
import NoAlpacaDialog from '../../components/modals/noAlpacaDialog/NoAlpacaDialog';
import useDataApi from '../../hooks/useDataApi';
import OrderHistoryTabs from '../../components/orderHistory/OrderHistoryTabs';
import CustomizeViewButton from '../../components/buttons/CustomizeViewButton';
import OrdersButton from '../../components/buttons/OrdersButton';
import { formatAmount } from '../../utils';
import { LiveAccountIcon, PaperAccountIcon } from '../../styles/icons/icons';
import ErrorDialog from '../../components/modals/ErrorDialog/ErrorDialog';

const DashboardPage = () => {
	const [openAccountDetail, setOpenAccountDetail] = useState(false);
	const [openNoAlpacaDialog, setOpenNoAlpacaDialog] = useState(false);
	
	const [openErrorDialog, setOpenErrorDialog] = useState(false);
	
	const [accountType, setAccountType] = useState('live');

	const [paperAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'paper' })
		}
	);
	const [liveAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'live' })
		}
	);
	var errors = null;
	
	const getUserName = () => {
		const username = localStorage.getItem('username');
		return username ? username.charAt(0).toUpperCase() + username.slice(1) : '';
	};

		var errors = localStorage.getItem("errors");
		console.log("errors:"+errors)
        var ws;
        var wsUri = "ws:";
        var loc = window.location;
        console.log(loc);
        if (loc.protocol === "https:") { wsUri = "wss:"; }
        // This needs to point to the web socket in the Node-RED flow
        // ... in this case it's ws/simple
		if ( !loc.host.includes("localhost"))
	    {
	        wsUri += "//" + loc.host + "/api/ws/errors";	//loc.pathname.replace("simple","ws/errors");
	    	console.log("server based")
	    }
        else
        {
        	wsUri += "//localhost:1880/ws/errors"
	    	console.log("localhost based")
        }
		console.log(wsUri)
        function wsConnect() {
            console.log("connect",wsUri);
            ws = new WebSocket(wsUri);
            //var line = "";    // either uncomment this for a building list of messages
            ws.onmessage = function(msg) {
                var line = "";  // or uncomment this to overwrite the existing message
                // parse the incoming message as a JSON object
                var data = JSON.parse(msg.data);
//                 console.log(data.error);
                // build the output from the topic and payload parts of the object
                if ("error" in data && typeof data.error === "string" && data.error.length !== 0)
				{
					line += "<p>"+data.error+"</p>";
					// replace the messages div with the new "line"
// 					document.getElementById('messages').innerHTML = line;
					localStorage.setItem("errors", data.error);

					setOpenErrorDialog(true);
					//ws.send(JSON.stringify({data:data}));
				}
	            else
					localStorage.setItem("errors", "");
//     	            document.getElementById('messages').innerHTML = "";
            }
            ws.onopen = function() {
                // update the status div with the connection status
//                 document.getElementById('status').innerHTML = "Interlisted socket connected";
                //ws.send("Open for data");
                console.log("Interlisted socket connected");
// 				if (errors.length > 0)
// 				{
// 					setOpenErrorDialog(true);
// 		
// 				}
            }
            ws.onclose = function() {
                // update the status div with the connection status
				if (document.getElementById('status'))
                	document.getElementById('status').innerHTML = "not connected";
                // in case of lost connection tries to reconnect every 3 secs
                setTimeout(wsConnect,3000);
            }
        }
        
        function doit(m) {
            if (ws) { ws.send(m); }
        }
		wsConnect()
// 		ws.disconnect();

	async function getData(url = "") {
	  // Default options are marked with *
	  const response = await fetch(url, {
		method: "GET", // *GET, POST, PUT, DELETE, etc.
		mode: "cors", // no-cors, *cors, same-origin
		cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
		credentials: "same-origin", // include, *same-origin, omit
		headers: {
		  "Content-Type": "application/json",
		  // 'Content-Type': 'application/x-www-form-urlencoded',
		},
		redirect: "follow", // manual, *follow, error
		referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
	  });
	  return response.json(); // parses JSON response into native JavaScript objects
	}



// 	const getError = () => {
// 		getData(`http://localhost:1880/errors`).then((data) => {
// 		  	console.log(data.errors); // JSON data parsed by `data.json()` call
// 		  	return data.errors || "test";
// 		});
// 		return "You can not trade non-interchange instruments.";
// 	
// 	}
/*
I would OK a pilot assuming we could somehow be in a position to confirm only stocks of US issuers listed on US exchanges (ex: MSFT) not Canadian issuers dually listed CAD-USD exchanges (ex: CP).
http://www.superstockpicker.com/canadian_US_stock_symbols.html

*/
/*				<div className="header-errors">
					<div id="status"></div>
					<div id="messages"></div>

				</div>
//*/				

	return (
		<div className="wrapper-container">
			<div className="header-container">
				<div className="header-container-title">
					<h1>Welcome {getUserName()}! </h1>
				</div>
				<div className="header-right-container">
					<CustomizeViewButton />
				</div>
			</div>

			<div className="card-main-container">
				<div className="view-container">
					<div className="card padding-left-zero">
						<Tabs activeId="live-account-tab" setAccountType={setAccountType}>
							<TabNavItems>
								<div className="tab-group-info"> You have 2 accounts:</div>
								<TabNavItem
									id="live-account-tab"
									info={formatAmount(liveAccountProps.data?.equity)}
									title="Live Account">
									<LiveAccountIcon />
								</TabNavItem>

								<TabNavItem
									id="demo-account-tab"
									title="Paper Account"
									info={formatAmount(paperAccountProps.data?.equity)}>
									<PaperAccountIcon />
								</TabNavItem>
							</TabNavItems>

							<TabContents>
								<TabContent id="live-account-tab">
									<AccountMetrics accountType="live" />
									<div className="text-right padding-right-15">
										<a
											onClick={() => setOpenAccountDetail(true)}
											className="link-text">
											&gt; Account Details
										</a>
									</div>

									<AccountDetailDialog
										accountType="live"
										isOpen={openAccountDetail}
										handleStateChange={setOpenAccountDetail}
									/>

									<PortfolioChart
										accountType="live"
										// displayType="intraday"
										isFullScreen={true}
										chartId="dashboard-chart-id"
									/>

									<Portfolio accountType="live" />
								</TabContent>

								<TabContent id="demo-account-tab">
									<AccountMetrics accountType="paper" />
									<div className="text-right padding-right-15">
										<a
											onClick={() => setOpenAccountDetail(true)}
											className="link-text">
											&gt; Account Details
										</a>
									</div>

									<AccountDetailDialog
										accountType="paper"
										isOpen={openAccountDetail}
										handleStateChange={setOpenAccountDetail}
									/>

									<PortfolioChart
										accountType="paper"
										displayType="intraday"
										isFullScreen={true}
										chartId="dashboard-chart-id"
									/>

									<Portfolio accountType="paper" />
								</TabContent>
							</TabContents>
						</Tabs>
					</div>
					<div className="card no-padding-mobile">
						<AlgoStrategies />
					</div>
					<div className="card sm-tab">
						<div className="card no-padding-mobile">
							<OrderHistoryTabs accountType={accountType} />
							<OrdersButton />
						</div>
					</div>
				</div>

				<div className="right-side-bar">
					<HeadLines />

					<MarketIndicators />

					<Watchlist />
					<TopStocks />
				</div>
			</div>
			{openNoAlpacaDialog && (
				<NoAlpacaDialog
					isOpen={openNoAlpacaDialog}
					handleStateChange={setOpenNoAlpacaDialog}
				/>
			)}
			{openErrorDialog && (
				<ErrorDialog
					isOpen={openErrorDialog}
					handleStateChange={setOpenErrorDialog}
					
				/>
			)}

		</div>
	);
};

export default DashboardPage;
