const singleLineChartData = [
	['AAPL', 1263228599914, 10, 30, 6, 6, 4],
	['AAPL', 1643228599914, 10, 20, 5, 3, 4],
	['AAPL', 1653228599914, 50, 30, 5, 4, 4],
	['AAPL', 1673228199914, 10, 20, 7, 5, 8],
	['AAPL', 1683298599914, 10, 15, 8, 3, 4]
];

const chartData = {
	AAPL: [
		['AAPL', 1263228599914, 10, 30, 6, 6, 4],
		['AAPL', 1643228599914, 10, 20, 5, 3, 4],
		['AAPL', 1653228599914, 50, 30, 5, 4, 4],
		['AAPL', 1673228199914, 10, 20, 7, 5, 8],
		['AAPL', 1683298599914, 10, 15, 8, 3, 4]
	],
	SPY: [
		['SPY', 1263228599914, 10, 30, 6, 6, 4],
		['SPY', 1643228599914, 10, 20, 5, 3, 4],
		['SPY', 1653228599914, 50, 30, 5, 4, 4],
		['SPY', 1673228199914, 10, 20, 7, 5, 8],
		['SPY', 1683298599914, 10, 15, 8, 3, 4]
	],
	AA: [
		['AA', 1263228599914, 10, 30, 6, 6, 4],
		['AA', 1643228599914, 10, 20, 5, 3, 4],
		['AA', 1653228599914, 50, 30, 5, 4, 4],
		['AA', 1673228199914, 10, 20, 7, 5, 8],
		['AA', 1683298599914, 10, 15, 8, 3, 4]
	],
	AROW: [
		['AROW', 1263228599914, 10, 30, 6, 6, 4],
		['AROW', 1643228599914, 10, 20, 5, 3, 4],
		['AROW', 1653228599914, 50, 30, 5, 4, 4],
		['AROW', 1673228199914, 10, 20, 7, 5, 8],
		['AROW', 1683298599914, 10, 15, 8, 3, 4]
	]
};

const compareMultipleSeriesData = [
	['AAPL', 1648484827, 10, 20, 5, 9, 4],
	['AAPL', 1649484827, 10, 20, 5, 12, 4],
	['AAPL', 1658484827, 10, 20, 5, 14, 4],
	['AAPL', 1668484827, 10, 20, 5, 5, 4],
	['AAPL', 1678484827, 10, 20, 5, 8, 4],
	['ABPL', 1651163227, 50, 30, 5, 15, 4],
	['ABPL', 1661163227, 50, 30, 5, 17, 4],
	['ABPL', 1671163227, 50, 30, 5, 6, 4],
	['ABPL', 1681163227, 50, 30, 5, 3, 4],
	['ABPL', 1691163227, 50, 30, 5, 12, 4]
];

const pophoverChartData = {
	symbol: {
		name: 'AAPL',
		amount: 419.95,
		title: 'SPDR S&P 500 ETF Trust'
	},
	data: [
		['AAPL', 1263228599914, 10, 30, 6, 6, 4],
		['AAPL', 1643228599914, 10, 20, 5, 3, 4],
		['AAPL', 1653228599914, 50, 30, 5, 4, 4],
		['AAPL', 1673228199914, 10, 20, 7, 5, 8],
		['AAPL', 1683298599914, 10, 15, 8, 3, 4]
	]
};

const algoEditorChartData = [
	['AAPL', 1648484827, 10, 20, 5, 9, 4],
	['AAPL', 1649484827, 10, 20, 5, 12, 4],
	['AAPL', 1658484827, 10, 20, 5, 14, 4],
	['AAPL', 1668484827, 10, 20, 5, 5, 4],
	['AAPL', 1678484827, 10, 20, 5, 8, 4],
	['ABPL', 1651163227, 50, 30, 5, 15, 4],
	['ABPL', 1661163227, 50, 30, 5, 17, 4],
	['ABPL', 1671163227, 50, 30, 5, 6, 4],
	['ABPL', 1681163227, 50, 30, 5, 3, 4],
	['ABPL', 1691163227, 50, 30, 5, 12, 4]
];

const metricsChartDetailData = [
	['AAPL', 10, 10, 30, 6, 6, 4],
	['AAPL', 20, 10, 20, 5, 3, 4],
	['AAPL', 30, 50, 30, 5, 4, 4],
	['AAPL', 40, 10, 20, 7, 5, 8]
];

const accountBalanceData = {
	intraday: [
		{
			unixtime: 1656330967,
			balance: 100000
		},
		{
			unixtime: 1656330970,
			balance: 100000
		},
		{
			unixtime: 1656331027,
			balance: 100000
		},
		{
			unixtime: 1656331087,
			balance: 100000
		},
		{
			unixtime: 1656331147,
			balance: 100000
		},
		{
			unixtime: 1656331207,
			balance: 100000
		},
		{
			unixtime: 1656331267,
			balance: 100000
		},
		{
			unixtime: 1656331327,
			balance: 100000
		},
		{
			unixtime: 1656331387,
			balance: 100000
		},
		{
			unixtime: 1656331447,
			balance: 100000
		},
		{
			unixtime: 1656331507,
			balance: 100000
		},
		{
			unixtime: 1656331567,
			balance: 100000
		},
		{
			unixtime: 1656331627,
			balance: 100000
		},
		{
			unixtime: 1656331687,
			balance: 100000
		},
		{
			unixtime: 1656331748,
			balance: 100000
		},
		{
			unixtime: 1656331807,
			balance: 100000
		},
		{
			unixtime: 1656331867,
			balance: 100000
		},
		{
			unixtime: 1656331927,
			balance: 100000
		},
		{
			unixtime: 1656331987,
			balance: 100000
		},
		{
			unixtime: 1656332061,
			balance: 100000
		},
		{
			unixtime: 1656332121,
			balance: 100000
		},
		{
			unixtime: 1656332205,
			balance: 100000
		},
		{
			unixtime: 1656332265,
			balance: 100000
		},
		{
			unixtime: 1656332325,
			balance: 100000
		},
		{
			unixtime: 1656332385,
			balance: 100000
		},
		{
			unixtime: 1656332445,
			balance: 100000
		},
		{
			unixtime: 1656332505,
			balance: 100000
		},
		{
			unixtime: 1656332565,
			balance: 100000
		},
		{
			unixtime: 1656332625,
			balance: 100000
		},
		{
			unixtime: 1656332685,
			balance: 100000
		},
		{
			unixtime: 1656332745,
			balance: 100000
		},
		{
			unixtime: 1656332805,
			balance: 100000
		},
		{
			unixtime: 1656332865,
			balance: 100000
		},
		{
			unixtime: 1656332925,
			balance: 100000
		},
		{
			unixtime: 1656332985,
			balance: 100000
		},
		{
			unixtime: 1656333045,
			balance: 100000
		},
		{
			unixtime: 1656333105,
			balance: 100000
		},
		{
			unixtime: 1656333174,
			balance: 100000
		},
		{
			unixtime: 1656333234,
			balance: 100000
		},
		{
			unixtime: 1656333294,
			balance: 100000
		},
		{
			unixtime: 1656333354,
			balance: 100000
		},
		{
			unixtime: 1656333414,
			balance: 100000
		},
		{
			unixtime: 1656333474,
			balance: 100000
		},
		{
			unixtime: 1656333534,
			balance: 100000
		},
		{
			unixtime: 1656333594,
			balance: 100000
		},
		{
			unixtime: 1656333654,
			balance: 100000
		},
		{
			unixtime: 1656333714,
			balance: 100000
		},
		{
			unixtime: 1656333774,
			balance: 100000
		},
		{
			unixtime: 1656333834,
			balance: 100000
		},
		{
			unixtime: 1656333894,
			balance: 100000
		},
		{
			unixtime: 1656333954,
			balance: 100000
		},
		{
			unixtime: 1656334014,
			balance: 100000
		},
		{
			unixtime: 1656334074,
			balance: 100000
		},
		{
			unixtime: 1656334134,
			balance: 100000
		},
		{
			unixtime: 1656334194,
			balance: 100000
		},
		{
			unixtime: 1656334254,
			balance: 100000
		},
		{
			unixtime: 1656334314,
			balance: 100000
		},
		{
			unixtime: 1656334374,
			balance: 100000
		},
		{
			unixtime: 1656334434,
			balance: 100000
		},
		{
			unixtime: 1656334494,
			balance: 100000
		},
		{
			unixtime: 1656334554,
			balance: 100000
		},
		{
			unixtime: 1656334614,
			balance: 100000
		},
		{
			unixtime: 1656334674,
			balance: 100000
		},
		{
			unixtime: 1656334734,
			balance: 100000
		},
		{
			unixtime: 1656334794,
			balance: 100000
		},
		{
			unixtime: 1656334854,
			balance: 100000
		},
		{
			unixtime: 1656334914,
			balance: 100000
		},
		{
			unixtime: 1656334974,
			balance: 100000
		},
		{
			unixtime: 1656335034,
			balance: 100000
		},
		{
			unixtime: 1656335094,
			balance: 100000
		},
		{
			unixtime: 1656335154,
			balance: 100000
		},
		{
			unixtime: 1656335214,
			balance: 100000
		},
		{
			unixtime: 1656335274,
			balance: 100000
		},
		{
			unixtime: 1656335334,
			balance: 100000
		},
		{
			unixtime: 1656335394,
			balance: 100000
		},
		{
			unixtime: 1656335454,
			balance: 100000
		},
		{
			unixtime: 1656335514,
			balance: 100000
		},
		{
			unixtime: 1656335574,
			balance: 100000
		},
		{
			unixtime: 1656335634,
			balance: 100000
		},
		{
			unixtime: 1656335694,
			balance: 100000
		},
		{
			unixtime: 1656335754,
			balance: 100000
		},
		{
			unixtime: 1656335814,
			balance: 100000
		},
		{
			unixtime: 1656335874,
			balance: 100000
		},
		{
			unixtime: 1656335934,
			balance: 100000
		},
		{
			unixtime: 1656335994,
			balance: 100000
		},
		{
			unixtime: 1656336054,
			balance: 100000
		},
		{
			unixtime: 1656336114,
			balance: 100000
		},
		{
			unixtime: 1656336174,
			balance: 100000
		},
		{
			unixtime: 1656336234,
			balance: 100000
		},
		{
			unixtime: 1656336294,
			balance: 100000
		},
		{
			unixtime: 1656336354,
			balance: 100000
		},
		{
			unixtime: 1656336414,
			balance: 100000
		},
		{
			unixtime: 1656336474,
			balance: 100000
		},
		{
			unixtime: 1656336534,
			balance: 100000
		},
		{
			unixtime: 1656336594,
			balance: 100000
		},
		{
			unixtime: 1656336654,
			balance: 99995.76
		},
		{
			unixtime: 1656336714,
			balance: 99996.88
		},
		{
			unixtime: 1656336774,
			balance: 99987.04
		},
		{
			unixtime: 1656336834,
			balance: 99982.5
		},
		{
			unixtime: 1656336933,
			balance: 99957.24
		},
		{
			unixtime: 1656336993,
			balance: 99945.2
		},
		{
			unixtime: 1656337053,
			balance: 99931
		},
		{
			unixtime: 1656337113,
			balance: 99932.72
		},
		{
			unixtime: 1656337173,
			balance: 99946.34
		},
		{
			unixtime: 1656337233,
			balance: 99953.86
		},
		{
			unixtime: 1656337293,
			balance: 99934.68
		},
		{
			unixtime: 1656337353,
			balance: 99922.58
		},
		{
			unixtime: 1656337413,
			balance: 99905.44
		},
		{
			unixtime: 1656337473,
			balance: 99909.1
		},
		{
			unixtime: 1656337533,
			balance: 99901.44
		},
		{
			unixtime: 1656337593,
			balance: 99903.22
		},
		{
			unixtime: 1656337653,
			balance: 99897.36
		},
		{
			unixtime: 1656337713,
			balance: 99897.86
		},
		{
			unixtime: 1656337773,
			balance: 99896.28
		},
		{
			unixtime: 1656337833,
			balance: 99894.68
		},
		{
			unixtime: 1656337893,
			balance: 99894.36
		},
		{
			unixtime: 1656337953,
			balance: 99906.46
		},
		{
			unixtime: 1656338013,
			balance: 99917.56
		},
		{
			unixtime: 1656338073,
			balance: 99931.78
		},
		{
			unixtime: 1656338133,
			balance: 99934.2
		},
		{
			unixtime: 1656338193,
			balance: 99925.9
		},
		{
			unixtime: 1656338253,
			balance: 99916.74
		},
		{
			unixtime: 1656338313,
			balance: 99917.82
		},
		{
			unixtime: 1656338373,
			balance: 99902.56
		},
		{
			unixtime: 1656338433,
			balance: 99919.58
		},
		{
			unixtime: 1656338493,
			balance: 99922.84
		},
		{
			unixtime: 1656338553,
			balance: 99927.28
		},
		{
			unixtime: 1656338613,
			balance: 99932.18
		},
		{
			unixtime: 1656338673,
			balance: 99955.06
		},
		{
			unixtime: 1656338733,
			balance: 99946.84
		},
		{
			unixtime: 1656338793,
			balance: 99938.78
		},
		{
			unixtime: 1656338853,
			balance: 99934.52
		},
		{
			unixtime: 1656338913,
			balance: 99921.48
		},
		{
			unixtime: 1656338973,
			balance: 99919.26
		},
		{
			unixtime: 1656339033,
			balance: 99912.78
		},
		{
			unixtime: 1656339093,
			balance: 99903.78
		},
		{
			unixtime: 1656339153,
			balance: 99906.76
		},
		{
			unixtime: 1656339213,
			balance: 99907.18
		},
		{
			unixtime: 1656339273,
			balance: 99898.96
		},
		{
			unixtime: 1656339333,
			balance: 99901.6
		},
		{
			unixtime: 1656339393,
			balance: 99911.76
		},
		{
			unixtime: 1656339453,
			balance: 99908.96
		},
		{
			unixtime: 1656339513,
			balance: 99895.56
		},
		{
			unixtime: 1656339573,
			balance: 99908.72
		},
		{
			unixtime: 1656339633,
			balance: 99917
		},
		{
			unixtime: 1656339693,
			balance: 99924.74
		},
		{
			unixtime: 1656339753,
			balance: 99913.24
		},
		{
			unixtime: 1656339813,
			balance: 99908.16
		},
		{
			unixtime: 1656339873,
			balance: 99905.76
		},
		{
			unixtime: 1656339933,
			balance: 99894.66
		},
		{
			unixtime: 1656339993,
			balance: 99893.16
		},
		{
			unixtime: 1656340053,
			balance: 99889.1
		},
		{
			unixtime: 1656340113,
			balance: 99898.54
		},
		{
			unixtime: 1656340173,
			balance: 99899.54
		},
		{
			unixtime: 1656340233,
			balance: 99910.76
		},
		{
			unixtime: 1656340293,
			balance: 99905.5
		},
		{
			unixtime: 1656340353,
			balance: 99912.7
		},
		{
			unixtime: 1656340413,
			balance: 99907.92
		},
		{
			unixtime: 1656340473,
			balance: 99909.62
		},
		{
			unixtime: 1656340533,
			balance: 99917.12
		},
		{
			unixtime: 1656340593,
			balance: 99906.08
		},
		{
			unixtime: 1656340653,
			balance: 99910.28
		},
		{
			unixtime: 1656340713,
			balance: 99917.46
		},
		{
			unixtime: 1656340773,
			balance: 99923.76
		},
		{
			unixtime: 1656340833,
			balance: 99925.24
		},
		{
			unixtime: 1656340893,
			balance: 99922.68
		},
		{
			unixtime: 1656340953,
			balance: 99919.86
		},
		{
			unixtime: 1656341013,
			balance: 99923.76
		},
		{
			unixtime: 1656341073,
			balance: 99935.7
		},
		{
			unixtime: 1656341133,
			balance: 99927.62
		},
		{
			unixtime: 1656341193,
			balance: 99939.88
		},
		{
			unixtime: 1656341253,
			balance: 99947.64
		},
		{
			unixtime: 1656341313,
			balance: 99957.04
		},
		{
			unixtime: 1656341373,
			balance: 99950.94
		},
		{
			unixtime: 1656341433,
			balance: 99953.34
		},
		{
			unixtime: 1656341493,
			balance: 99955.46
		},
		{
			unixtime: 1656341553,
			balance: 99961.82
		},
		{
			unixtime: 1656341613,
			balance: 99956.36
		},
		{
			unixtime: 1656341673,
			balance: 99957.14
		},
		{
			unixtime: 1656341733,
			balance: 99961.64
		},
		{
			unixtime: 1656341793,
			balance: 99969
		},
		{
			unixtime: 1656341853,
			balance: 99974.16
		},
		{
			unixtime: 1656341913,
			balance: 99965.16
		},
		{
			unixtime: 1656341973,
			balance: 99963.44
		},
		{
			unixtime: 1656342033,
			balance: 99968.74
		},
		{
			unixtime: 1656342153,
			balance: 99957.76
		},
		{
			unixtime: 1656342273,
			balance: 99952.28
		},
		{
			unixtime: 1656342333,
			balance: 99949.96
		},
		{
			unixtime: 1656342393,
			balance: 99950.94
		},
		{
			unixtime: 1656342453,
			balance: 99945.14
		},
		{
			unixtime: 1656342513,
			balance: 99946.48
		},
		{
			unixtime: 1656342573,
			balance: 99945.86
		},
		{
			unixtime: 1656342654,
			balance: 99937.34
		},
		{
			unixtime: 1656342714,
			balance: 99945.3
		},
		{
			unixtime: 1656342818,
			balance: 99937.4
		},
		{
			unixtime: 1656342878,
			balance: 99935.96
		},
		{
			unixtime: 1656342938,
			balance: 99939.1
		},
		{
			unixtime: 1656342998,
			balance: 99953.38
		},
		{
			unixtime: 1656343058,
			balance: 99956.26
		},
		{
			unixtime: 1656343118,
			balance: 99950.84
		},
		{
			unixtime: 1656343178,
			balance: 99956.94
		},
		{
			unixtime: 1656343238,
			balance: 99956.44
		},
		{
			unixtime: 1656343298,
			balance: 99965.48
		},
		{
			unixtime: 1656343358,
			balance: 99976.26
		},
		{
			unixtime: 1656343418,
			balance: 99970.04
		},
		{
			unixtime: 1656343478,
			balance: 99977.64
		},
		{
			unixtime: 1656343538,
			balance: 99984.72
		},
		{
			unixtime: 1656343598,
			balance: 99981.76
		},
		{
			unixtime: 1656343658,
			balance: 99982.94
		},
		{
			unixtime: 1656343718,
			balance: 99981.7
		},
		{
			unixtime: 1656343778,
			balance: 99979.9
		},
		{
			unixtime: 1656343838,
			balance: 99972.54
		},
		{
			unixtime: 1656343898,
			balance: 99973.36
		},
		{
			unixtime: 1656343958,
			balance: 99968.4
		},
		{
			unixtime: 1656344018,
			balance: 99972.74
		},
		{
			unixtime: 1656344078,
			balance: 99964.76
		},
		{
			unixtime: 1656344138,
			balance: 99965.32
		},
		{
			unixtime: 1656344198,
			balance: 99964.96
		},
		{
			unixtime: 1656344258,
			balance: 99960.86
		},
		{
			unixtime: 1656344318,
			balance: 99967.24
		},
		{
			unixtime: 1656344378,
			balance: 99969.6
		},
		{
			unixtime: 1656344438,
			balance: 99977.72
		},
		{
			unixtime: 1656344498,
			balance: 99979.54
		},
		{
			unixtime: 1656344558,
			balance: 99989.04
		},
		{
			unixtime: 1656344618,
			balance: 99992.02
		},
		{
			unixtime: 1656344678,
			balance: 99986.66
		},
		{
			unixtime: 1656344738,
			balance: 99991.72
		},
		{
			unixtime: 1656344798,
			balance: 99991.92
		},
		{
			unixtime: 1656344858,
			balance: 99990.66
		},
		{
			unixtime: 1656344918,
			balance: 99986.84
		},
		{
			unixtime: 1656344978,
			balance: 99990.48
		},
		{
			unixtime: 1656345038,
			balance: 99985.8
		},
		{
			unixtime: 1656345098,
			balance: 99981.64
		},
		{
			unixtime: 1656345158,
			balance: 99981.38
		},
		{
			unixtime: 1656345218,
			balance: 99980.46
		},
		{
			unixtime: 1656345278,
			balance: 99978.64
		},
		{
			unixtime: 1656345338,
			balance: 99982.78
		},
		{
			unixtime: 1656345398,
			balance: 99977.72
		},
		{
			unixtime: 1656345458,
			balance: 99978.68
		},
		{
			unixtime: 1656345518,
			balance: 99978.96
		},
		{
			unixtime: 1656345578,
			balance: 99985.24
		},
		{
			unixtime: 1656345638,
			balance: 99984.34
		},
		{
			unixtime: 1656345698,
			balance: 99977.94
		},
		{
			unixtime: 1656345758,
			balance: 99964.86
		},
		{
			unixtime: 1656345818,
			balance: 99967.3
		},
		{
			unixtime: 1656345878,
			balance: 99955.56
		},
		{
			unixtime: 1656345938,
			balance: 99956.88
		},
		{
			unixtime: 1656345998,
			balance: 99955.14
		},
		{
			unixtime: 1656346058,
			balance: 99960.46
		},
		{
			unixtime: 1656346118,
			balance: 99959.12
		},
		{
			unixtime: 1656346178,
			balance: 99955.7
		},
		{
			unixtime: 1656346238,
			balance: 99951.12
		},
		{
			unixtime: 1656346298,
			balance: 99949.96
		},
		{
			unixtime: 1656346358,
			balance: 99954.46
		},
		{
			unixtime: 1656346418,
			balance: 99956.82
		},
		{
			unixtime: 1656346478,
			balance: 99955.04
		},
		{
			unixtime: 1656346538,
			balance: 99953.18
		},
		{
			unixtime: 1656346598,
			balance: 99947.72
		},
		{
			unixtime: 1656346658,
			balance: 99948
		},
		{
			unixtime: 1656346718,
			balance: 99947.16
		},
		{
			unixtime: 1656346778,
			balance: 99952.52
		},
		{
			unixtime: 1656346838,
			balance: 99952.56
		},
		{
			unixtime: 1656346898,
			balance: 99955.72
		},
		{
			unixtime: 1656346958,
			balance: 99950.44
		},
		{
			unixtime: 1656347018,
			balance: 99945.26
		},
		{
			unixtime: 1656347078,
			balance: 99937.3
		},
		{
			unixtime: 1656347138,
			balance: 99928.72
		},
		{
			unixtime: 1656347198,
			balance: 99922.34
		},
		{
			unixtime: 1656347258,
			balance: 99923.26
		},
		{
			unixtime: 1656347318,
			balance: 99923.82
		},
		{
			unixtime: 1656347378,
			balance: 99926.86
		},
		{
			unixtime: 1656347438,
			balance: 99922.26
		},
		{
			unixtime: 1656347498,
			balance: 99919.56
		},
		{
			unixtime: 1656347558,
			balance: 99913.72
		},
		{
			unixtime: 1656347618,
			balance: 99914.02
		},
		{
			unixtime: 1656347678,
			balance: 99915.84
		},
		{
			unixtime: 1656347738,
			balance: 99920.12
		},
		{
			unixtime: 1656347798,
			balance: 99917.32
		},
		{
			unixtime: 1656347858,
			balance: 99916.56
		},
		{
			unixtime: 1656347918,
			balance: 99927.36
		},
		{
			unixtime: 1656347978,
			balance: 99925.18
		},
		{
			unixtime: 1656348038,
			balance: 99925
		},
		{
			unixtime: 1656348098,
			balance: 99933.06
		},
		{
			unixtime: 1656348158,
			balance: 99933.02
		},
		{
			unixtime: 1656348218,
			balance: 99933.44
		},
		{
			unixtime: 1656348278,
			balance: 99939.94
		},
		{
			unixtime: 1656348338,
			balance: 99938.7
		},
		{
			unixtime: 1656348398,
			balance: 99935.2
		},
		{
			unixtime: 1656348458,
			balance: 99941.76
		},
		{
			unixtime: 1656348518,
			balance: 99940.5
		},
		{
			unixtime: 1656348578,
			balance: 99941.1
		},
		{
			unixtime: 1656348638,
			balance: 99941.36
		},
		{
			unixtime: 1656348698,
			balance: 99942.56
		},
		{
			unixtime: 1656348758,
			balance: 99941.8
		},
		{
			unixtime: 1656348818,
			balance: 99946.32
		},
		{
			unixtime: 1656348878,
			balance: 99943.8
		},
		{
			unixtime: 1656348938,
			balance: 99947.56
		},
		{
			unixtime: 1656348998,
			balance: 99938.82
		},
		{
			unixtime: 1656349058,
			balance: 99938.04
		},
		{
			unixtime: 1656349118,
			balance: 99940.64
		},
		{
			unixtime: 1656349178,
			balance: 99937.08
		},
		{
			unixtime: 1656349238,
			balance: 99944.9
		},
		{
			unixtime: 1656349298,
			balance: 99938.36
		},
		{
			unixtime: 1656349358,
			balance: 99924.12
		},
		{
			unixtime: 1656349418,
			balance: 99930.46
		},
		{
			unixtime: 1656349478,
			balance: 99932.26
		},
		{
			unixtime: 1656349538,
			balance: 99924.18
		},
		{
			unixtime: 1656349598,
			balance: 99917.04
		},
		{
			unixtime: 1656349658,
			balance: 99919.1
		},
		{
			unixtime: 1656349718,
			balance: 99920.16
		},
		{
			unixtime: 1656349778,
			balance: 99928.34
		},
		{
			unixtime: 1656349838,
			balance: 99930.56
		},
		{
			unixtime: 1656349898,
			balance: 99931.86
		},
		{
			unixtime: 1656349958,
			balance: 99935.06
		},
		{
			unixtime: 1656350018,
			balance: 99936.36
		},
		{
			unixtime: 1656350078,
			balance: 99936.36
		},
		{
			unixtime: 1656350138,
			balance: 99937.54
		},
		{
			unixtime: 1656350198,
			balance: 99939.78
		},
		{
			unixtime: 1656350258,
			balance: 99930.04
		},
		{
			unixtime: 1656350318,
			balance: 99936.46
		},
		{
			unixtime: 1656350378,
			balance: 99937.26
		},
		{
			unixtime: 1656350438,
			balance: 99930.8
		},
		{
			unixtime: 1656350498,
			balance: 99933.24
		},
		{
			unixtime: 1656350558,
			balance: 99931.22
		},
		{
			unixtime: 1656350618,
			balance: 99930.82
		},
		{
			unixtime: 1656350678,
			balance: 99923.76
		},
		{
			unixtime: 1656350738,
			balance: 99921.92
		},
		{
			unixtime: 1656350798,
			balance: 99917.28
		},
		{
			unixtime: 1656350858,
			balance: 99916.14
		},
		{
			unixtime: 1656350918,
			balance: 99914.7
		},
		{
			unixtime: 1656350978,
			balance: 99915.26
		},
		{
			unixtime: 1656351038,
			balance: 99914.24
		},
		{
			unixtime: 1656351098,
			balance: 99913.18
		},
		{
			unixtime: 1656351158,
			balance: 99909.08
		},
		{
			unixtime: 1656351218,
			balance: 99909.2
		},
		{
			unixtime: 1656351278,
			balance: 99906.66
		},
		{
			unixtime: 1656351338,
			balance: 99903.54
		},
		{
			unixtime: 1656351398,
			balance: 99905.42
		},
		{
			unixtime: 1656351458,
			balance: 99901.26
		},
		{
			unixtime: 1656351518,
			balance: 99911.48
		},
		{
			unixtime: 1656351578,
			balance: 99908.54
		},
		{
			unixtime: 1656351638,
			balance: 99910.86
		},
		{
			unixtime: 1656351698,
			balance: 99898.34
		},
		{
			unixtime: 1656351758,
			balance: 99897.8
		},
		{
			unixtime: 1656351818,
			balance: 99897.04
		},
		{
			unixtime: 1656351878,
			balance: 99898.46
		},
		{
			unixtime: 1656351938,
			balance: 99890.68
		},
		{
			unixtime: 1656351998,
			balance: 99891.34
		},
		{
			unixtime: 1656352058,
			balance: 99886.34
		},
		{
			unixtime: 1656352118,
			balance: 99886.72
		},
		{
			unixtime: 1656352178,
			balance: 99889.38
		},
		{
			unixtime: 1656352238,
			balance: 99893.78
		},
		{
			unixtime: 1656352298,
			balance: 99895.42
		},
		{
			unixtime: 1656352358,
			balance: 99898.14
		},
		{
			unixtime: 1656352418,
			balance: 99902.22
		},
		{
			unixtime: 1656352478,
			balance: 99904.66
		},
		{
			unixtime: 1656352538,
			balance: 99901.26
		},
		{
			unixtime: 1656352598,
			balance: 99902.18
		},
		{
			unixtime: 1656352658,
			balance: 99897.76
		},
		{
			unixtime: 1656352718,
			balance: 99894.82
		},
		{
			unixtime: 1656352778,
			balance: 99894.42
		},
		{
			unixtime: 1656352838,
			balance: 99889.74
		},
		{
			unixtime: 1656352898,
			balance: 99898.34
		},
		{
			unixtime: 1656352958,
			balance: 99899.18
		},
		{
			unixtime: 1656353018,
			balance: 99902.96
		},
		{
			unixtime: 1656353078,
			balance: 99900.26
		},
		{
			unixtime: 1656353138,
			balance: 99903.9
		},
		{
			unixtime: 1656353198,
			balance: 99898.34
		},
		{
			unixtime: 1656353258,
			balance: 99901.16
		},
		{
			unixtime: 1656353318,
			balance: 99901.88
		},
		{
			unixtime: 1656353378,
			balance: 99905.88
		},
		{
			unixtime: 1656353438,
			balance: 99902.34
		},
		{
			unixtime: 1656353498,
			balance: 99905.64
		},
		{
			unixtime: 1656353558,
			balance: 99899.54
		},
		{
			unixtime: 1656353618,
			balance: 99909.96
		},
		{
			unixtime: 1656353678,
			balance: 99910.96
		},
		{
			unixtime: 1656353738,
			balance: 99913.26
		},
		{
			unixtime: 1656353798,
			balance: 99913.06
		},
		{
			unixtime: 1656353858,
			balance: 99909.46
		},
		{
			unixtime: 1656353918,
			balance: 99918.28
		},
		{
			unixtime: 1656353978,
			balance: 99920.46
		},
		{
			unixtime: 1656354038,
			balance: 99918.22
		},
		{
			unixtime: 1656354098,
			balance: 99914.38
		},
		{
			unixtime: 1656354158,
			balance: 99919.44
		},
		{
			unixtime: 1656354218,
			balance: 99917.16
		},
		{
			unixtime: 1656354278,
			balance: 99911.92
		},
		{
			unixtime: 1656354338,
			balance: 99906.36
		},
		{
			unixtime: 1656354398,
			balance: 99911.66
		},
		{
			unixtime: 1656354458,
			balance: 99911.58
		},
		{
			unixtime: 1656354518,
			balance: 99908.76
		},
		{
			unixtime: 1656354578,
			balance: 99906.66
		},
		{
			unixtime: 1656354638,
			balance: 99904.9
		},
		{
			unixtime: 1656354698,
			balance: 99905.52
		},
		{
			unixtime: 1656354758,
			balance: 99907.9
		},
		{
			unixtime: 1656354818,
			balance: 99910.54
		},
		{
			unixtime: 1656354878,
			balance: 99913.42
		},
		{
			unixtime: 1656354938,
			balance: 99914.16
		},
		{
			unixtime: 1656354998,
			balance: 99915.1
		},
		{
			unixtime: 1656355058,
			balance: 99913.8
		},
		{
			unixtime: 1656355118,
			balance: 99924.42
		},
		{
			unixtime: 1656355178,
			balance: 99926.22
		},
		{
			unixtime: 1656355238,
			balance: 99922.5
		},
		{
			unixtime: 1656355298,
			balance: 99919.18
		},
		{
			unixtime: 1656355358,
			balance: 99918.26
		},
		{
			unixtime: 1656355418,
			balance: 99919.56
		},
		{
			unixtime: 1656355478,
			balance: 99919.82
		},
		{
			unixtime: 1656355538,
			balance: 99919.76
		},
		{
			unixtime: 1656355598,
			balance: 99920.14
		},
		{
			unixtime: 1656355658,
			balance: 99915.02
		},
		{
			unixtime: 1656355718,
			balance: 99926.6
		},
		{
			unixtime: 1656355778,
			balance: 99930.7
		},
		{
			unixtime: 1656355838,
			balance: 99926.1
		},
		{
			unixtime: 1656355898,
			balance: 99917.64
		},
		{
			unixtime: 1656355958,
			balance: 99919.26
		},
		{
			unixtime: 1656356018,
			balance: 99918.38
		},
		{
			unixtime: 1656356078,
			balance: 99917.76
		},
		{
			unixtime: 1656356138,
			balance: 99917.96
		},
		{
			unixtime: 1656356198,
			balance: 99919.24
		},
		{
			unixtime: 1656356258,
			balance: 99922.56
		},
		{
			unixtime: 1656356318,
			balance: 99916.58
		},
		{
			unixtime: 1656356378,
			balance: 99914.42
		},
		{
			unixtime: 1656356438,
			balance: 99913.08
		},
		{
			unixtime: 1656356498,
			balance: 99908.72
		},
		{
			unixtime: 1656356558,
			balance: 99898.84
		},
		{
			unixtime: 1656356618,
			balance: 99896.36
		},
		{
			unixtime: 1656356678,
			balance: 99894.56
		},
		{
			unixtime: 1656356738,
			balance: 99888.26
		},
		{
			unixtime: 1656356798,
			balance: 99891.8
		},
		{
			unixtime: 1656356858,
			balance: 99895.18
		},
		{
			unixtime: 1656356918,
			balance: 99898.76
		},
		{
			unixtime: 1656356978,
			balance: 99906.88
		},
		{
			unixtime: 1656357038,
			balance: 99910.46
		},
		{
			unixtime: 1656357098,
			balance: 99913.98
		},
		{
			unixtime: 1656357158,
			balance: 99913.52
		},
		{
			unixtime: 1656357218,
			balance: 99917.06
		},
		{
			unixtime: 1656357278,
			balance: 99923.5
		},
		{
			unixtime: 1656357338,
			balance: 99922.14
		},
		{
			unixtime: 1656357398,
			balance: 99925.2
		},
		{
			unixtime: 1656357458,
			balance: 99919.94
		},
		{
			unixtime: 1656357518,
			balance: 99918.78
		},
		{
			unixtime: 1656357578,
			balance: 99923.32
		},
		{
			unixtime: 1656357638,
			balance: 99920.84
		},
		{
			unixtime: 1656357698,
			balance: 99917.62
		},
		{
			unixtime: 1656357758,
			balance: 99923.02
		},
		{
			unixtime: 1656357818,
			balance: 99921.84
		},
		{
			unixtime: 1656357878,
			balance: 99922.14
		},
		{
			unixtime: 1656357938,
			balance: 99929.08
		},
		{
			unixtime: 1656357998,
			balance: 99924.48
		},
		{
			unixtime: 1656358058,
			balance: 99921.46
		},
		{
			unixtime: 1656358118,
			balance: 99921.56
		},
		{
			unixtime: 1656358178,
			balance: 99926.76
		},
		{
			unixtime: 1656358238,
			balance: 99921.34
		},
		{
			unixtime: 1656358298,
			balance: 99918.28
		},
		{
			unixtime: 1656358358,
			balance: 99920.86
		},
		{
			unixtime: 1656358418,
			balance: 99916.76
		},
		{
			unixtime: 1656358478,
			balance: 99916.34
		},
		{
			unixtime: 1656358538,
			balance: 99913.6
		},
		{
			unixtime: 1656358598,
			balance: 99905.72
		},
		{
			unixtime: 1656358658,
			balance: 99910.6
		},
		{
			unixtime: 1656358718,
			balance: 99898.68
		},
		{
			unixtime: 1656358778,
			balance: 99903.92
		},
		{
			unixtime: 1656358838,
			balance: 99904.46
		},
		{
			unixtime: 1656358898,
			balance: 99910.92
		},
		{
			unixtime: 1656358958,
			balance: 99907.36
		},
		{
			unixtime: 1656359018,
			balance: 99917.54
		},
		{
			unixtime: 1656359078,
			balance: 99922.98
		},
		{
			unixtime: 1656359138,
			balance: 99935.3
		},
		{
			unixtime: 1656359198,
			balance: 99933.08
		},
		{
			unixtime: 1656359258,
			balance: 99923.66
		},
		{
			unixtime: 1656359318,
			balance: 99925.4
		},
		{
			unixtime: 1656359378,
			balance: 99920.6
		},
		{
			unixtime: 1656359438,
			balance: 99903.06
		},
		{
			unixtime: 1656359498,
			balance: 99910.88
		},
		{
			unixtime: 1656359558,
			balance: 99909.4
		},
		{
			unixtime: 1656359618,
			balance: 99902.62
		},
		{
			unixtime: 1656359678,
			balance: 99905.46
		},
		{
			unixtime: 1656359738,
			balance: 99909.06
		},
		{
			unixtime: 1656359798,
			balance: 99913.84
		},
		{
			unixtime: 1656359858,
			balance: 99915
		},
		{
			unixtime: 1656359918,
			balance: 99915.06
		},
		{
			unixtime: 1656359978,
			balance: 99927.44
		},
		{
			unixtime: 1656360038,
			balance: 99935.4
		},
		{
			unixtime: 1656360098,
			balance: 99937.12
		},
		{
			unixtime: 1656360158,
			balance: 99933.8
		},
		{
			unixtime: 1656360218,
			balance: 99933.54
		},
		{
			unixtime: 1656360278,
			balance: 99932.66
		},
		{
			unixtime: 1656360338,
			balance: 99933.94
		},
		{
			unixtime: 1656360398,
			balance: 99934.96
		},
		{
			unixtime: 1656360458,
			balance: 99938.06
		},
		{
			unixtime: 1656360518,
			balance: 99932.88
		},
		{
			unixtime: 1656360578,
			balance: 99935.02
		},
		{
			unixtime: 1656360638,
			balance: 99938.66
		},
		{
			unixtime: 1656360698,
			balance: 99941.36
		},
		{
			unixtime: 1656360758,
			balance: 99941.16
		},
		{
			unixtime: 1656360818,
			balance: 99940.66
		},
		{
			unixtime: 1656360878,
			balance: 99937.46
		},
		{
			unixtime: 1656360938,
			balance: 99939.46
		},
		{
			unixtime: 1656360998,
			balance: 99941.26
		},
		{
			unixtime: 1656361058,
			balance: 99940.8
		},
		{
			unixtime: 1656361118,
			balance: 99943.2
		},
		{
			unixtime: 1656361178,
			balance: 99942.7
		},
		{
			unixtime: 1656361238,
			balance: 99943.4
		},
		{
			unixtime: 1656361298,
			balance: 99944
		},
		{
			unixtime: 1656361358,
			balance: 99943.3
		},
		{
			unixtime: 1656361418,
			balance: 99943.86
		},
		{
			unixtime: 1656361478,
			balance: 99942.66
		},
		{
			unixtime: 1656361538,
			balance: 99944.88
		},
		{
			unixtime: 1656361598,
			balance: 99943.66
		},
		{
			unixtime: 1656361658,
			balance: 99944.26
		},
		{
			unixtime: 1656361718,
			balance: 99943.56
		},
		{
			unixtime: 1656361778,
			balance: 99941.84
		},
		{
			unixtime: 1656361838,
			balance: 99942.34
		},
		{
			unixtime: 1656361898,
			balance: 99941.7
		},
		{
			unixtime: 1656361958,
			balance: 99942.2
		},
		{
			unixtime: 1656362018,
			balance: 99943.3
		},
		{
			unixtime: 1656362078,
			balance: 99946.46
		},
		{
			unixtime: 1656362138,
			balance: 99946.46
		},
		{
			unixtime: 1656362198,
			balance: 99948.86
		},
		{
			unixtime: 1656362258,
			balance: 99949.86
		},
		{
			unixtime: 1656362318,
			balance: 99950.26
		},
		{
			unixtime: 1656362378,
			balance: 99948.76
		},
		{
			unixtime: 1656362438,
			balance: 99949.28
		},
		{
			unixtime: 1656362498,
			balance: 99945.38
		},
		{
			unixtime: 1656362558,
			balance: 99944.76
		},
		{
			unixtime: 1656362618,
			balance: 99946.76
		},
		{
			unixtime: 1656362678,
			balance: 99943.28
		},
		{
			unixtime: 1656362738,
			balance: 99946.76
		},
		{
			unixtime: 1656362798,
			balance: 99945.26
		},
		{
			unixtime: 1656362858,
			balance: 99946.46
		},
		{
			unixtime: 1656362918,
			balance: 99946.2
		},
		{
			unixtime: 1656362978,
			balance: 99944.9
		},
		{
			unixtime: 1656363038,
			balance: 99945.66
		},
		{
			unixtime: 1656363098,
			balance: 99943.78
		},
		{
			unixtime: 1656363158,
			balance: 99942.78
		},
		{
			unixtime: 1656363218,
			balance: 99944.12
		},
		{
			unixtime: 1656363278,
			balance: 99941.22
		},
		{
			unixtime: 1656363338,
			balance: 99940.52
		},
		{
			unixtime: 1656363398,
			balance: 99938.6
		},
		{
			unixtime: 1656363458,
			balance: 99938.6
		},
		{
			unixtime: 1656363518,
			balance: 99937.98
		},
		{
			unixtime: 1656363578,
			balance: 99940.26
		},
		{
			unixtime: 1656363638,
			balance: 99941.78
		},
		{
			unixtime: 1656363698,
			balance: 99941.48
		},
		{
			unixtime: 1656363758,
			balance: 99942.62
		},
		{
			unixtime: 1656363818,
			balance: 99940.76
		},
		{
			unixtime: 1656363878,
			balance: 99941.26
		},
		{
			unixtime: 1656363938,
			balance: 99943.56
		},
		{
			unixtime: 1656363998,
			balance: 99943.56
		},
		{
			unixtime: 1656364058,
			balance: 99943.28
		},
		{
			unixtime: 1656364118,
			balance: 99943.38
		},
		{
			unixtime: 1656364178,
			balance: 99943.28
		},
		{
			unixtime: 1656364238,
			balance: 99947.28
		},
		{
			unixtime: 1656364298,
			balance: 99947.48
		},
		{
			unixtime: 1656364358,
			balance: 99946.28
		},
		{
			unixtime: 1656364418,
			balance: 99946.48
		},
		{
			unixtime: 1656364478,
			balance: 99945.88
		},
		{
			unixtime: 1656364538,
			balance: 99945.88
		},
		{
			unixtime: 1656364598,
			balance: 99944.18
		},
		{
			unixtime: 1656364658,
			balance: 99943.78
		},
		{
			unixtime: 1656364718,
			balance: 99945.88
		},
		{
			unixtime: 1656364778,
			balance: 99945.98
		},
		{
			unixtime: 1656364838,
			balance: 99945.98
		},
		{
			unixtime: 1656364898,
			balance: 99946.98
		},
		{
			unixtime: 1656364958,
			balance: 99948.66
		},
		{
			unixtime: 1656365018,
			balance: 99948.66
		},
		{
			unixtime: 1656365078,
			balance: 99946.46
		},
		{
			unixtime: 1656365138,
			balance: 99945.9
		},
		{
			unixtime: 1656365198,
			balance: 99946.76
		},
		{
			unixtime: 1656365258,
			balance: 99945.06
		},
		{
			unixtime: 1656365318,
			balance: 99947.96
		},
		{
			unixtime: 1656365378,
			balance: 99951.16
		},
		{
			unixtime: 1656365438,
			balance: 99946.56
		},
		{
			unixtime: 1656365498,
			balance: 99946.36
		},
		{
			unixtime: 1656365558,
			balance: 99946.96
		},
		{
			unixtime: 1656365618,
			balance: 99946.86
		},
		{
			unixtime: 1656365678,
			balance: 99949.56
		},
		{
			unixtime: 1656365738,
			balance: 99950.86
		},
		{
			unixtime: 1656365798,
			balance: 99950.66
		},
		{
			unixtime: 1656365858,
			balance: 99950.16
		},
		{
			unixtime: 1656365918,
			balance: 99949.66
		},
		{
			unixtime: 1656365978,
			balance: 99945.96
		},
		{
			unixtime: 1656366038,
			balance: 99948.86
		},
		{
			unixtime: 1656366098,
			balance: 99949.86
		},
		{
			unixtime: 1656366158,
			balance: 99949.16
		},
		{
			unixtime: 1656366218,
			balance: 99948.36
		},
		{
			unixtime: 1656366278,
			balance: 99948.36
		},
		{
			unixtime: 1656366338,
			balance: 99949.76
		},
		{
			unixtime: 1656366398,
			balance: 99948.06
		},
		{
			unixtime: 1656366458,
			balance: 99948.26
		},
		{
			unixtime: 1656366518,
			balance: 99948.26
		},
		{
			unixtime: 1656366578,
			balance: 99949.06
		},
		{
			unixtime: 1656366638,
			balance: 99948.56
		},
		{
			unixtime: 1656366698,
			balance: 99948.56
		},
		{
			unixtime: 1656366758,
			balance: 99945.52
		},
		{
			unixtime: 1656366818,
			balance: 99946.22
		},
		{
			unixtime: 1656366878,
			balance: 99944.82
		},
		{
			unixtime: 1656366938,
			balance: 99944.72
		},
		{
			unixtime: 1656366998,
			balance: 99944.72
		},
		{
			unixtime: 1656367058,
			balance: 99945.14
		},
		{
			unixtime: 1656367118,
			balance: 99945.14
		},
		{
			unixtime: 1656367178,
			balance: 99945.64
		},
		{
			unixtime: 1656367238,
			balance: 99946.94
		},
		{
			unixtime: 1656367298,
			balance: 99944.64
		},
		{
			unixtime: 1656367358,
			balance: 99941.08
		},
		{
			unixtime: 1656367418,
			balance: 99940.96
		},
		{
			unixtime: 1656367478,
			balance: 99941.46
		},
		{
			unixtime: 1656367538,
			balance: 99939.98
		},
		{
			unixtime: 1656367598,
			balance: 99939.98
		},
		{
			unixtime: 1656367658,
			balance: 99941.16
		},
		{
			unixtime: 1656367718,
			balance: 99941.26
		},
		{
			unixtime: 1656367778,
			balance: 99941.16
		},
		{
			unixtime: 1656367838,
			balance: 99941.16
		},
		{
			unixtime: 1656367898,
			balance: 99940.66
		},
		{
			unixtime: 1656367958,
			balance: 99942.16
		},
		{
			unixtime: 1656368018,
			balance: 99942.36
		},
		{
			unixtime: 1656368078,
			balance: 99941.56
		},
		{
			unixtime: 1656368138,
			balance: 99941.76
		},
		{
			unixtime: 1656368198,
			balance: 99941.76
		},
		{
			unixtime: 1656368258,
			balance: 99941.76
		},
		{
			unixtime: 1656368318,
			balance: 99938.96
		},
		{
			unixtime: 1656368378,
			balance: 99939.26
		},
		{
			unixtime: 1656368438,
			balance: 99938.86
		},
		{
			unixtime: 1656368498,
			balance: 99938.26
		},
		{
			unixtime: 1656368558,
			balance: 99942.16
		},
		{
			unixtime: 1656368618,
			balance: 99940.96
		},
		{
			unixtime: 1656368678,
			balance: 99941.7
		},
		{
			unixtime: 1656368738,
			balance: 99942.54
		},
		{
			unixtime: 1656368798,
			balance: 99942.78
		},
		{
			unixtime: 1656368858,
			balance: 99941.62
		},
		{
			unixtime: 1656368918,
			balance: 99941.72
		},
		{
			unixtime: 1656368978,
			balance: 99941.72
		},
		{
			unixtime: 1656369038,
			balance: 99939.82
		},
		{
			unixtime: 1656369098,
			balance: 99940.66
		},
		{
			unixtime: 1656369158,
			balance: 99938.98
		},
		{
			unixtime: 1656369218,
			balance: 99939.18
		},
		{
			unixtime: 1656369278,
			balance: 99939.16
		},
		{
			unixtime: 1656369338,
			balance: 99940.06
		},
		{
			unixtime: 1656369398,
			balance: 99939.36
		},
		{
			unixtime: 1656369458,
			balance: 99939.36
		},
		{
			unixtime: 1656369518,
			balance: 99939.56
		},
		{
			unixtime: 1656369578,
			balance: 99939.56
		},
		{
			unixtime: 1656369638,
			balance: 99939.56
		},
		{
			unixtime: 1656369698,
			balance: 99939.56
		},
		{
			unixtime: 1656369758,
			balance: 99941.86
		},
		{
			unixtime: 1656369818,
			balance: 99942.06
		},
		{
			unixtime: 1656369878,
			balance: 99942.26
		},
		{
			unixtime: 1656369938,
			balance: 99941.86
		},
		{
			unixtime: 1656369998,
			balance: 99940.76
		},
		{
			unixtime: 1656370058,
			balance: 99938.76
		},
		{
			unixtime: 1656370118,
			balance: 99938.76
		},
		{
			unixtime: 1656370178,
			balance: 99939.56
		},
		{
			unixtime: 1656370238,
			balance: 99939.56
		},
		{
			unixtime: 1656370298,
			balance: 99939.56
		},
		{
			unixtime: 1656370358,
			balance: 99940.56
		},
		{
			unixtime: 1656370418,
			balance: 99945.26
		},
		{
			unixtime: 1656370478,
			balance: 99943.56
		},
		{
			unixtime: 1656370538,
			balance: 99943.56
		},
		{
			unixtime: 1656370598,
			balance: 99943.46
		},
		{
			unixtime: 1656370658,
			balance: 99943.1
		},
		{
			unixtime: 1656370718,
			balance: 99943.86
		},
		{
			unixtime: 1656370778,
			balance: 99943.46
		},
		{
			unixtime: 1656370838,
			balance: 99942.46
		},
		{
			unixtime: 1656370898,
			balance: 99941.46
		},
		{
			unixtime: 1656370958,
			balance: 99943.76
		},
		{
			unixtime: 1656371018,
			balance: 99943.46
		},
		{
			unixtime: 1656371078,
			balance: 99943.96
		},
		{
			unixtime: 1656371138,
			balance: 99943.76
		},
		{
			unixtime: 1656371198,
			balance: 99943.76
		},
		{
			unixtime: 1656371258,
			balance: 99944.66
		},
		{
			unixtime: 1656371318,
			balance: 99944.66
		},
		{
			unixtime: 1656371378,
			balance: 99944.32
		},
		{
			unixtime: 1656371438,
			balance: 99945.8
		},
		{
			unixtime: 1656371498,
			balance: 99945.76
		},
		{
			unixtime: 1656371558,
			balance: 99945.36
		},
		{
			unixtime: 1656371618,
			balance: 99946.52
		},
		{
			unixtime: 1656371678,
			balance: 99946.24
		},
		{
			unixtime: 1656371738,
			balance: 99946.24
		},
		{
			unixtime: 1656371798,
			balance: 99946.24
		},
		{
			unixtime: 1656371858,
			balance: 99945.8
		},
		{
			unixtime: 1656371918,
			balance: 99945.58
		},
		{
			unixtime: 1656371978,
			balance: 99945.68
		},
		{
			unixtime: 1656372038,
			balance: 99945.68
		},
		{
			unixtime: 1656372098,
			balance: 99946.46
		},
		{
			unixtime: 1656372158,
			balance: 99945.82
		},
		{
			unixtime: 1656372218,
			balance: 99945.82
		},
		{
			unixtime: 1656372278,
			balance: 99945.46
		},
		{
			unixtime: 1656372338,
			balance: 99945.46
		},
		{
			unixtime: 1656372398,
			balance: 99944.96
		},
		{
			unixtime: 1656372458,
			balance: 99945.26
		},
		{
			unixtime: 1656372518,
			balance: 99945.34
		},
		{
			unixtime: 1656372578,
			balance: 99944.34
		},
		{
			unixtime: 1656372638,
			balance: 99944.34
		},
		{
			unixtime: 1656372698,
			balance: 99944.34
		},
		{
			unixtime: 1656372758,
			balance: 99945.36
		},
		{
			unixtime: 1656372818,
			balance: 99945.36
		},
		{
			unixtime: 1656372878,
			balance: 99945.96
		},
		{
			unixtime: 1656372938,
			balance: 99945.96
		},
		{
			unixtime: 1656372998,
			balance: 99945.96
		},
		{
			unixtime: 1656373058,
			balance: 99945.76
		},
		{
			unixtime: 1656373118,
			balance: 99945.76
		},
		{
			unixtime: 1656373178,
			balance: 99945.76
		},
		{
			unixtime: 1656373238,
			balance: 99945.66
		},
		{
			unixtime: 1656373298,
			balance: 99945.38
		},
		{
			unixtime: 1656373358,
			balance: 99945.38
		},
		{
			unixtime: 1656373418,
			balance: 99943.68
		},
		{
			unixtime: 1656373478,
			balance: 99943.02
		},
		{
			unixtime: 1656373538,
			balance: 99944.86
		},
		{
			unixtime: 1656373598,
			balance: 99944.76
		},
		{
			unixtime: 1656373658,
			balance: 99945.06
		},
		{
			unixtime: 1656373718,
			balance: 99944.86
		},
		{
			unixtime: 1656373778,
			balance: 99945.06
		},
		{
			unixtime: 1656373838,
			balance: 99946.26
		},
		{
			unixtime: 1656373898,
			balance: 99943.06
		},
		{
			unixtime: 1656373958,
			balance: 99944.36
		},
		{
			unixtime: 1656374018,
			balance: 99942.76
		},
		{
			unixtime: 1656374078,
			balance: 99942.76
		},
		{
			unixtime: 1656374138,
			balance: 99941.86
		},
		{
			unixtime: 1656374198,
			balance: 99942.36
		},
		{
			unixtime: 1656374258,
			balance: 99942.78
		},
		{
			unixtime: 1656374318,
			balance: 99942.46
		},
		{
			unixtime: 1656374378,
			balance: 99943.58
		},
		{
			unixtime: 1656374438,
			balance: 99939.28
		},
		{
			unixtime: 1656374498,
			balance: 99939.28
		},
		{
			unixtime: 1656374558,
			balance: 99939.28
		},
		{
			unixtime: 1656374618,
			balance: 99939.28
		},
		{
			unixtime: 1656374678,
			balance: 99939.28
		},
		{
			unixtime: 1656374738,
			balance: 99939.28
		},
		{
			unixtime: 1656374798,
			balance: 99939.28
		},
		{
			unixtime: 1656374858,
			balance: 99939.28
		},
		{
			unixtime: 1656374918,
			balance: 99939.28
		},
		{
			unixtime: 1656374978,
			balance: 99939.28
		},
		{
			unixtime: 1656375038,
			balance: 99939.28
		},
		{
			unixtime: 1656375098,
			balance: 99939.28
		},
		{
			unixtime: 1656375158,
			balance: 99939.28
		},
		{
			unixtime: 1656375218,
			balance: 99939.28
		},
		{
			unixtime: 1656375278,
			balance: 99939.28
		},
		{
			unixtime: 1656375338,
			balance: 99939.28
		},
		{
			unixtime: 1656375398,
			balance: 99939.28
		},
		{
			unixtime: 1656375458,
			balance: 99939.28
		},
		{
			unixtime: 1656375518,
			balance: 99939.28
		},
		{
			unixtime: 1656375578,
			balance: 99939.28
		},
		{
			unixtime: 1656375638,
			balance: 99939.28
		},
		{
			unixtime: 1656375698,
			balance: 99939.28
		},
		{
			unixtime: 1656375758,
			balance: 99939.28
		},
		{
			unixtime: 1656375818,
			balance: 99939.28
		},
		{
			unixtime: 1656375878,
			balance: 99939.28
		},
		{
			unixtime: 1656375938,
			balance: 99939.28
		},
		{
			unixtime: 1656375998,
			balance: 99939.28
		},
		{
			unixtime: 1656376058,
			balance: 99939.28
		},
		{
			unixtime: 1656376118,
			balance: 99939.28
		},
		{
			unixtime: 1656376178,
			balance: 99939.28
		},
		{
			unixtime: 1656376238,
			balance: 99939.28
		},
		{
			unixtime: 1656376298,
			balance: 99939.28
		},
		{
			unixtime: 1656376358,
			balance: 99939.28
		},
		{
			unixtime: 1656376418,
			balance: 99939.28
		},
		{
			unixtime: 1656376478,
			balance: 99939.28
		},
		{
			unixtime: 1656376538,
			balance: 99939.28
		},
		{
			unixtime: 1656376598,
			balance: 99939.28
		},
		{
			unixtime: 1656376658,
			balance: 99939.28
		},
		{
			unixtime: 1656376718,
			balance: 99939.28
		},
		{
			unixtime: 1656376778,
			balance: 99939.28
		},
		{
			unixtime: 1656376838,
			balance: 99939.28
		},
		{
			unixtime: 1656376898,
			balance: 99939.28
		},
		{
			unixtime: 1656376958,
			balance: 99939.28
		},
		{
			unixtime: 1656377018,
			balance: 99939.28
		},
		{
			unixtime: 1656377078,
			balance: 99939.28
		},
		{
			unixtime: 1656377138,
			balance: 99939.28
		},
		{
			unixtime: 1656377198,
			balance: 99939.28
		},
		{
			unixtime: 1656377258,
			balance: 99939.28
		},
		{
			unixtime: 1656377318,
			balance: 99939.28
		},
		{
			unixtime: 1656377378,
			balance: 99939.28
		},
		{
			unixtime: 1656377438,
			balance: 99939.28
		},
		{
			unixtime: 1656377498,
			balance: 99939.28
		},
		{
			unixtime: 1656377558,
			balance: 99939.28
		},
		{
			unixtime: 1656377618,
			balance: 99939.28
		},
		{
			unixtime: 1656377678,
			balance: 99939.28
		},
		{
			unixtime: 1656377738,
			balance: 99939.28
		},
		{
			unixtime: 1656377798,
			balance: 99939.28
		},
		{
			unixtime: 1656377858,
			balance: 99939.28
		},
		{
			unixtime: 1656377918,
			balance: 99939.28
		},
		{
			unixtime: 1656377978,
			balance: 99939.28
		},
		{
			unixtime: 1656378038,
			balance: 99939.28
		},
		{
			unixtime: 1656378098,
			balance: 99939.28
		},
		{
			unixtime: 1656378158,
			balance: 99939.28
		},
		{
			unixtime: 1656378218,
			balance: 99939.28
		},
		{
			unixtime: 1656378278,
			balance: 99939.28
		},
		{
			unixtime: 1656378338,
			balance: 99939.28
		},
		{
			unixtime: 1656378398,
			balance: 99939.28
		},
		{
			unixtime: 1656378458,
			balance: 99939.28
		},
		{
			unixtime: 1656378518,
			balance: 99939.28
		},
		{
			unixtime: 1656378578,
			balance: 99939.28
		},
		{
			unixtime: 1656378638,
			balance: 99939.28
		},
		{
			unixtime: 1656378698,
			balance: 99939.28
		},
		{
			unixtime: 1656378758,
			balance: 99939.28
		},
		{
			unixtime: 1656378818,
			balance: 99939.28
		},
		{
			unixtime: 1656378878,
			balance: 99939.28
		},
		{
			unixtime: 1656378938,
			balance: 99939.28
		},
		{
			unixtime: 1656378998,
			balance: 99939.28
		},
		{
			unixtime: 1656379058,
			balance: 99939.28
		},
		{
			unixtime: 1656379118,
			balance: 99939.28
		},
		{
			unixtime: 1656379178,
			balance: 99939.28
		},
		{
			unixtime: 1656379238,
			balance: 99939.28
		},
		{
			unixtime: 1656379298,
			balance: 99939.28
		},
		{
			unixtime: 1656379358,
			balance: 99939.28
		},
		{
			unixtime: 1656379418,
			balance: 99939.28
		},
		{
			unixtime: 1656379478,
			balance: 99939.28
		},
		{
			unixtime: 1656379538,
			balance: 99939.28
		},
		{
			unixtime: 1656379598,
			balance: 99939.28
		},
		{
			unixtime: 1656379659,
			balance: 99939.28
		},
		{
			unixtime: 1656379718,
			balance: 99939.28
		},
		{
			unixtime: 1656379778,
			balance: 99939.28
		},
		{
			unixtime: 1656379839,
			balance: 99939.28
		},
		{
			unixtime: 1656379898,
			balance: 99939.28
		},
		{
			unixtime: 1656379959,
			balance: 99939.28
		},
		{
			unixtime: 1656380018,
			balance: 99939.28
		},
		{
			unixtime: 1656380078,
			balance: 99939.28
		},
		{
			unixtime: 1656380138,
			balance: 99939.28
		},
		{
			unixtime: 1656380198,
			balance: 99939.28
		},
		{
			unixtime: 1656380258,
			balance: 99939.28
		},
		{
			unixtime: 1656380318,
			balance: 99939.28
		},
		{
			unixtime: 1656380378,
			balance: 99939.28
		},
		{
			unixtime: 1656380438,
			balance: 99939.28
		},
		{
			unixtime: 1656380499,
			balance: 99939.28
		},
		{
			unixtime: 1656380558,
			balance: 99939.28
		},
		{
			unixtime: 1656380619,
			balance: 99939.28
		},
		{
			unixtime: 1656380679,
			balance: 99939.28
		},
		{
			unixtime: 1656380739,
			balance: 99939.28
		},
		{
			unixtime: 1656380798,
			balance: 99939.28
		},
		{
			unixtime: 1656380859,
			balance: 99939.28
		},
		{
			unixtime: 1656380918,
			balance: 99939.28
		},
		{
			unixtime: 1656380978,
			balance: 99939.28
		},
		{
			unixtime: 1656381038,
			balance: 99939.28
		},
		{
			unixtime: 1656381099,
			balance: 99939.28
		},
		{
			unixtime: 1656381158,
			balance: 99939.28
		},
		{
			unixtime: 1656381219,
			balance: 99939.28
		},
		{
			unixtime: 1656381279,
			balance: 99939.28
		},
		{
			unixtime: 1656381339,
			balance: 99939.28
		},
		{
			unixtime: 1656381398,
			balance: 99939.28
		},
		{
			unixtime: 1656381459,
			balance: 99939.28
		},
		{
			unixtime: 1656381519,
			balance: 99939.28
		},
		{
			unixtime: 1656381579,
			balance: 99939.28
		},
		{
			unixtime: 1656381639,
			balance: 99939.28
		},
		{
			unixtime: 1656381699,
			balance: 99939.28
		},
		{
			unixtime: 1656381759,
			balance: 99939.28
		},
		{
			unixtime: 1656381819,
			balance: 99939.28
		},
		{
			unixtime: 1656381879,
			balance: 99939.28
		},
		{
			unixtime: 1656381939,
			balance: 99939.28
		},
		{
			unixtime: 1656381999,
			balance: 99939.28
		},
		{
			unixtime: 1656382059,
			balance: 99939.28
		},
		{
			unixtime: 1656382119,
			balance: 99939.28
		},
		{
			unixtime: 1656382179,
			balance: 99939.28
		},
		{
			unixtime: 1656382239,
			balance: 99939.28
		},
		{
			unixtime: 1656382299,
			balance: 99939.28
		},
		{
			unixtime: 1656382359,
			balance: 99939.28
		},
		{
			unixtime: 1656382419,
			balance: 99939.28
		},
		{
			unixtime: 1656382479,
			balance: 99939.28
		},
		{
			unixtime: 1656382539,
			balance: 99939.28
		},
		{
			unixtime: 1656382599,
			balance: 99939.28
		},
		{
			unixtime: 1656382659,
			balance: 99939.28
		},
		{
			unixtime: 1656382719,
			balance: 99939.28
		},
		{
			unixtime: 1656382779,
			balance: 99939.28
		},
		{
			unixtime: 1656382839,
			balance: 99939.28
		},
		{
			unixtime: 1656382899,
			balance: 99939.28
		},
		{
			unixtime: 1656382959,
			balance: 99939.28
		},
		{
			unixtime: 1656383019,
			balance: 99939.28
		},
		{
			unixtime: 1656383079,
			balance: 99939.28
		},
		{
			unixtime: 1656383139,
			balance: 99939.28
		},
		{
			unixtime: 1656383199,
			balance: 99939.28
		},
		{
			unixtime: 1656383259,
			balance: 99939.28
		},
		{
			unixtime: 1656383319,
			balance: 99939.28
		},
		{
			unixtime: 1656383379,
			balance: 99939.28
		},
		{
			unixtime: 1656383439,
			balance: 99939.28
		},
		{
			unixtime: 1656383499,
			balance: 99939.28
		},
		{
			unixtime: 1656383559,
			balance: 99939.28
		},
		{
			unixtime: 1656383619,
			balance: 99939.28
		},
		{
			unixtime: 1656383679,
			balance: 99939.28
		},
		{
			unixtime: 1656383739,
			balance: 99939.28
		},
		{
			unixtime: 1656383799,
			balance: 99939.28
		},
		{
			unixtime: 1656383859,
			balance: 99939.28
		},
		{
			unixtime: 1656383919,
			balance: 99939.28
		},
		{
			unixtime: 1656383979,
			balance: 99939.28
		},
		{
			unixtime: 1656384039,
			balance: 99939.28
		},
		{
			unixtime: 1656384099,
			balance: 99939.28
		},
		{
			unixtime: 1656384159,
			balance: 99939.28
		},
		{
			unixtime: 1656384219,
			balance: 99939.28
		},
		{
			unixtime: 1656384279,
			balance: 99939.28
		},
		{
			unixtime: 1656384339,
			balance: 99939.28
		},
		{
			unixtime: 1656384399,
			balance: 99939.28
		},
		{
			unixtime: 1656384459,
			balance: 99939.28
		},
		{
			unixtime: 1656384519,
			balance: 99939.28
		},
		{
			unixtime: 1656384579,
			balance: 99939.28
		},
		{
			unixtime: 1656384639,
			balance: 99939.28
		},
		{
			unixtime: 1656384699,
			balance: 99939.28
		},
		{
			unixtime: 1656384759,
			balance: 99939.28
		},
		{
			unixtime: 1656384819,
			balance: 99939.28
		},
		{
			unixtime: 1656384879,
			balance: 99939.28
		},
		{
			unixtime: 1656384939,
			balance: 99939.28
		},
		{
			unixtime: 1656384999,
			balance: 99939.28
		},
		{
			unixtime: 1656385059,
			balance: 99939.28
		},
		{
			unixtime: 1656385119,
			balance: 99939.28
		},
		{
			unixtime: 1656385179,
			balance: 99939.28
		},
		{
			unixtime: 1656385239,
			balance: 99939.28
		},
		{
			unixtime: 1656385299,
			balance: 99939.28
		},
		{
			unixtime: 1656385359,
			balance: 99939.28
		},
		{
			unixtime: 1656385419,
			balance: 99939.28
		},
		{
			unixtime: 1656385479,
			balance: 99939.28
		},
		{
			unixtime: 1656385539,
			balance: 99939.28
		},
		{
			unixtime: 1656385599,
			balance: 99939.28
		},
		{
			unixtime: 1656385659,
			balance: 99939.28
		},
		{
			unixtime: 1656385719,
			balance: 99939.28
		},
		{
			unixtime: 1656385779,
			balance: 99939.28
		},
		{
			unixtime: 1656385839,
			balance: 99939.28
		},
		{
			unixtime: 1656385899,
			balance: 99939.28
		},
		{
			unixtime: 1656385959,
			balance: 99939.28
		},
		{
			unixtime: 1656386019,
			balance: 99939.28
		},
		{
			unixtime: 1656386079,
			balance: 99939.28
		},
		{
			unixtime: 1656386139,
			balance: 99939.28
		},
		{
			unixtime: 1656386199,
			balance: 99939.28
		},
		{
			unixtime: 1656386259,
			balance: 99939.28
		},
		{
			unixtime: 1656386319,
			balance: 99939.28
		},
		{
			unixtime: 1656386379,
			balance: 99939.28
		},
		{
			unixtime: 1656386439,
			balance: 99939.28
		},
		{
			unixtime: 1656386499,
			balance: 99939.28
		},
		{
			unixtime: 1656386559,
			balance: 99939.28
		},
		{
			unixtime: 1656386619,
			balance: 99939.28
		},
		{
			unixtime: 1656386679,
			balance: 99939.28
		},
		{
			unixtime: 1656386739,
			balance: 99939.28
		},
		{
			unixtime: 1656386799,
			balance: 99939.28
		},
		{
			unixtime: 1656386859,
			balance: 99939.28
		},
		{
			unixtime: 1656386919,
			balance: 99939.28
		},
		{
			unixtime: 1656386979,
			balance: 99939.28
		},
		{
			unixtime: 1656387039,
			balance: 99939.28
		},
		{
			unixtime: 1656387099,
			balance: 99939.28
		},
		{
			unixtime: 1656387159,
			balance: 99939.28
		},
		{
			unixtime: 1656387219,
			balance: 99939.28
		},
		{
			unixtime: 1656387279,
			balance: 99939.28
		},
		{
			unixtime: 1656387339,
			balance: 99939.28
		},
		{
			unixtime: 1656387399,
			balance: 99939.28
		},
		{
			unixtime: 1656387459,
			balance: 99939.28
		},
		{
			unixtime: 1656387519,
			balance: 99939.28
		},
		{
			unixtime: 1656387579,
			balance: 99939.28
		},
		{
			unixtime: 1656387639,
			balance: 99939.28
		},
		{
			unixtime: 1656387699,
			balance: 99939.28
		},
		{
			unixtime: 1656387759,
			balance: 99939.28
		},
		{
			unixtime: 1656387819,
			balance: 99939.28
		},
		{
			unixtime: 1656387879,
			balance: 99939.28
		},
		{
			unixtime: 1656387939,
			balance: 99939.28
		},
		{
			unixtime: 1656387999,
			balance: 99939.28
		},
		{
			unixtime: 1656388059,
			balance: 99939.28
		},
		{
			unixtime: 1656388119,
			balance: 99939.28
		},
		{
			unixtime: 1656388179,
			balance: 99939.28
		},
		{
			unixtime: 1656388239,
			balance: 99939.28
		},
		{
			unixtime: 1656388299,
			balance: 99939.28
		},
		{
			unixtime: 1656388359,
			balance: 99939.28
		},
		{
			unixtime: 1656388419,
			balance: 99939.28
		},
		{
			unixtime: 1656388479,
			balance: 99939.28
		},
		{
			unixtime: 1656388539,
			balance: 99939.28
		},
		{
			unixtime: 1656388599,
			balance: 99939.28
		},
		{
			unixtime: 1656388659,
			balance: 99939.28
		},
		{
			unixtime: 1656388719,
			balance: 99939.28
		},
		{
			unixtime: 1656388779,
			balance: 99939.28
		},
		{
			unixtime: 1656388839,
			balance: 99939.28
		},
		{
			unixtime: 1656388899,
			balance: 99939.28
		},
		{
			unixtime: 1656388959,
			balance: 99939.28
		},
		{
			unixtime: 1656389019,
			balance: 99939.28
		},
		{
			unixtime: 1656389079,
			balance: 99939.28
		},
		{
			unixtime: 1656389139,
			balance: 99939.28
		},
		{
			unixtime: 1656389199,
			balance: 99939.28
		},
		{
			unixtime: 1656389259,
			balance: 99939.28
		},
		{
			unixtime: 1656389319,
			balance: 99939.28
		},
		{
			unixtime: 1656389379,
			balance: 99939.28
		},
		{
			unixtime: 1656389439,
			balance: 99939.28
		},
		{
			unixtime: 1656389499,
			balance: 99939.28
		},
		{
			unixtime: 1656389559,
			balance: 99939.28
		},
		{
			unixtime: 1656389619,
			balance: 99939.28
		},
		{
			unixtime: 1656389679,
			balance: 99939.28
		},
		{
			unixtime: 1656389739,
			balance: 99939.28
		},
		{
			unixtime: 1656389799,
			balance: 99939.28
		},
		{
			unixtime: 1656389859,
			balance: 99939.28
		},
		{
			unixtime: 1656389919,
			balance: 99939.28
		},
		{
			unixtime: 1656389979,
			balance: 99939.28
		},
		{
			unixtime: 1656390039,
			balance: 99939.28
		},
		{
			unixtime: 1656390099,
			balance: 99939.28
		},
		{
			unixtime: 1656390159,
			balance: 99939.28
		},
		{
			unixtime: 1656390219,
			balance: 99939.28
		},
		{
			unixtime: 1656390279,
			balance: 99939.28
		},
		{
			unixtime: 1656390339,
			balance: 99939.28
		},
		{
			unixtime: 1656390399,
			balance: 99939.28
		},
		{
			unixtime: 1656390459,
			balance: 99939.28
		},
		{
			unixtime: 1656390519,
			balance: 99939.28
		},
		{
			unixtime: 1656390579,
			balance: 99939.28
		},
		{
			unixtime: 1656390639,
			balance: 99939.28
		},
		{
			unixtime: 1656390699,
			balance: 99939.28
		},
		{
			unixtime: 1656390759,
			balance: 99939.28
		},
		{
			unixtime: 1656390819,
			balance: 99939.28
		},
		{
			unixtime: 1656390879,
			balance: 99939.28
		},
		{
			unixtime: 1656390939,
			balance: 99939.28
		},
		{
			unixtime: 1656390999,
			balance: 99939.28
		},
		{
			unixtime: 1656391059,
			balance: 99939.28
		},
		{
			unixtime: 1656391119,
			balance: 99939.28
		},
		{
			unixtime: 1656391179,
			balance: 99939.28
		},
		{
			unixtime: 1656391239,
			balance: 99939.28
		},
		{
			unixtime: 1656391299,
			balance: 99939.28
		},
		{
			unixtime: 1656391359,
			balance: 99939.28
		},
		{
			unixtime: 1656391419,
			balance: 99939.28
		},
		{
			unixtime: 1656391479,
			balance: 99939.28
		},
		{
			unixtime: 1656391539,
			balance: 99939.28
		},
		{
			unixtime: 1656391599,
			balance: 99939.28
		},
		{
			unixtime: 1656391659,
			balance: 99939.28
		},
		{
			unixtime: 1656391719,
			balance: 99939.28
		},
		{
			unixtime: 1656391779,
			balance: 99939.28
		},
		{
			unixtime: 1656391839,
			balance: 99939.28
		},
		{
			unixtime: 1656391899,
			balance: 99939.28
		},
		{
			unixtime: 1656391959,
			balance: 99939.28
		},
		{
			unixtime: 1656392019,
			balance: 99939.28
		},
		{
			unixtime: 1656392079,
			balance: 99939.28
		},
		{
			unixtime: 1656392139,
			balance: 99939.28
		},
		{
			unixtime: 1656392199,
			balance: 99939.28
		},
		{
			unixtime: 1656392259,
			balance: 99939.28
		},
		{
			unixtime: 1656392319,
			balance: 99939.28
		},
		{
			unixtime: 1656392379,
			balance: 99939.28
		},
		{
			unixtime: 1656392439,
			balance: 99939.28
		},
		{
			unixtime: 1656392499,
			balance: 99939.28
		},
		{
			unixtime: 1656392559,
			balance: 99939.28
		},
		{
			unixtime: 1656392619,
			balance: 99939.28
		},
		{
			unixtime: 1656392679,
			balance: 99939.28
		},
		{
			unixtime: 1656392739,
			balance: 99939.28
		},
		{
			unixtime: 1656392799,
			balance: 99939.28
		},
		{
			unixtime: 1656392859,
			balance: 99939.28
		},
		{
			unixtime: 1656392919,
			balance: 99939.28
		},
		{
			unixtime: 1656392979,
			balance: 99939.28
		},
		{
			unixtime: 1656393039,
			balance: 99939.28
		},
		{
			unixtime: 1656393099,
			balance: 99939.28
		},
		{
			unixtime: 1656393159,
			balance: 99939.28
		},
		{
			unixtime: 1656393219,
			balance: 99939.28
		},
		{
			unixtime: 1656393279,
			balance: 99939.28
		},
		{
			unixtime: 1656393339,
			balance: 99939.28
		},
		{
			unixtime: 1656393399,
			balance: 99939.28
		},
		{
			unixtime: 1656393459,
			balance: 99939.28
		},
		{
			unixtime: 1656393519,
			balance: 99939.28
		},
		{
			unixtime: 1656393579,
			balance: 99939.28
		},
		{
			unixtime: 1656393639,
			balance: 99939.28
		},
		{
			unixtime: 1656393699,
			balance: 99939.28
		},
		{
			unixtime: 1656393759,
			balance: 99939.28
		},
		{
			unixtime: 1656393819,
			balance: 99939.28
		},
		{
			unixtime: 1656393879,
			balance: 99939.28
		},
		{
			unixtime: 1656393939,
			balance: 99939.28
		},
		{
			unixtime: 1656393999,
			balance: 99939.28
		},
		{
			unixtime: 1656394059,
			balance: 99939.28
		},
		{
			unixtime: 1656394119,
			balance: 99939.28
		},
		{
			unixtime: 1656394179,
			balance: 99939.28
		},
		{
			unixtime: 1656394239,
			balance: 99939.28
		},
		{
			unixtime: 1656394299,
			balance: 99939.28
		},
		{
			unixtime: 1656394359,
			balance: 99939.28
		},
		{
			unixtime: 1656394419,
			balance: 99939.28
		},
		{
			unixtime: 1656394479,
			balance: 99939.28
		},
		{
			unixtime: 1656394539,
			balance: 99939.28
		},
		{
			unixtime: 1656394599,
			balance: 99939.28
		},
		{
			unixtime: 1656394659,
			balance: 99939.28
		},
		{
			unixtime: 1656394719,
			balance: 99939.28
		},
		{
			unixtime: 1656394779,
			balance: 99939.28
		},
		{
			unixtime: 1656394839,
			balance: 99939.28
		},
		{
			unixtime: 1656394899,
			balance: 99939.28
		},
		{
			unixtime: 1656394959,
			balance: 99939.28
		},
		{
			unixtime: 1656395019,
			balance: 99939.28
		},
		{
			unixtime: 1656395079,
			balance: 99939.28
		},
		{
			unixtime: 1656395139,
			balance: 99939.28
		},
		{
			unixtime: 1656395199,
			balance: 99939.28
		},
		{
			unixtime: 1656395259,
			balance: 99939.28
		},
		{
			unixtime: 1656395319,
			balance: 99939.28
		},
		{
			unixtime: 1656395379,
			balance: 99939.28
		},
		{
			unixtime: 1656395439,
			balance: 99939.28
		},
		{
			unixtime: 1656395499,
			balance: 99939.28
		},
		{
			unixtime: 1656395559,
			balance: 99939.28
		},
		{
			unixtime: 1656395619,
			balance: 99939.28
		},
		{
			unixtime: 1656395679,
			balance: 99939.28
		},
		{
			unixtime: 1656395739,
			balance: 99939.28
		},
		{
			unixtime: 1656395799,
			balance: 99939.28
		},
		{
			unixtime: 1656395859,
			balance: 99939.28
		},
		{
			unixtime: 1656395919,
			balance: 99939.28
		},
		{
			unixtime: 1656395979,
			balance: 99939.28
		},
		{
			unixtime: 1656396039,
			balance: 99939.28
		},
		{
			unixtime: 1656396099,
			balance: 99939.28
		},
		{
			unixtime: 1656396159,
			balance: 99939.28
		},
		{
			unixtime: 1656396219,
			balance: 99939.28
		},
		{
			unixtime: 1656396279,
			balance: 99939.28
		},
		{
			unixtime: 1656396339,
			balance: 99939.28
		},
		{
			unixtime: 1656396399,
			balance: 99939.28
		},
		{
			unixtime: 1656396459,
			balance: 99939.28
		},
		{
			unixtime: 1656396519,
			balance: 99939.28
		},
		{
			unixtime: 1656396579,
			balance: 99939.28
		},
		{
			unixtime: 1656396639,
			balance: 99939.28
		},
		{
			unixtime: 1656396699,
			balance: 99939.28
		},
		{
			unixtime: 1656396759,
			balance: 99939.28
		},
		{
			unixtime: 1656396819,
			balance: 99939.28
		},
		{
			unixtime: 1656396879,
			balance: 99939.28
		},
		{
			unixtime: 1656396939,
			balance: 99939.28
		},
		{
			unixtime: 1656396999,
			balance: 99939.28
		},
		{
			unixtime: 1656397059,
			balance: 99939.28
		},
		{
			unixtime: 1656397119,
			balance: 99939.28
		},
		{
			unixtime: 1656397179,
			balance: 99939.28
		},
		{
			unixtime: 1656397239,
			balance: 99939.28
		},
		{
			unixtime: 1656397299,
			balance: 99939.28
		},
		{
			unixtime: 1656397359,
			balance: 99939.28
		},
		{
			unixtime: 1656397419,
			balance: 99939.28
		},
		{
			unixtime: 1656397479,
			balance: 99939.28
		},
		{
			unixtime: 1656397539,
			balance: 99939.28
		},
		{
			unixtime: 1656397599,
			balance: 99939.28
		},
		{
			unixtime: 1656397659,
			balance: 99939.28
		},
		{
			unixtime: 1656397719,
			balance: 99939.28
		},
		{
			unixtime: 1656397779,
			balance: 99939.28
		},
		{
			unixtime: 1656397839,
			balance: 99939.28
		},
		{
			unixtime: 1656397899,
			balance: 99939.28
		},
		{
			unixtime: 1656397959,
			balance: 99939.28
		},
		{
			unixtime: 1656398019,
			balance: 99939.28
		},
		{
			unixtime: 1656398079,
			balance: 99939.28
		},
		{
			unixtime: 1656398139,
			balance: 99939.28
		},
		{
			unixtime: 1656398199,
			balance: 99939.28
		},
		{
			unixtime: 1656398259,
			balance: 99939.28
		},
		{
			unixtime: 1656398319,
			balance: 99939.28
		},
		{
			unixtime: 1656398379,
			balance: 99939.28
		},
		{
			unixtime: 1656398439,
			balance: 99939.28
		},
		{
			unixtime: 1656398499,
			balance: 99939.28
		},
		{
			unixtime: 1656398559,
			balance: 99939.28
		},
		{
			unixtime: 1656398619,
			balance: 99939.28
		},
		{
			unixtime: 1656398679,
			balance: 99939.28
		},
		{
			unixtime: 1656398739,
			balance: 99939.28
		},
		{
			unixtime: 1656398799,
			balance: 99939.28
		},
		{
			unixtime: 1656398859,
			balance: 99939.28
		},
		{
			unixtime: 1656398919,
			balance: 99939.28
		},
		{
			unixtime: 1656398979,
			balance: 99939.28
		},
		{
			unixtime: 1656399039,
			balance: 99939.28
		},
		{
			unixtime: 1656399099,
			balance: 99939.28
		},
		{
			unixtime: 1656399159,
			balance: 99939.28
		},
		{
			unixtime: 1656399219,
			balance: 99939.28
		},
		{
			unixtime: 1656399279,
			balance: 99939.28
		},
		{
			unixtime: 1656399339,
			balance: 99939.28
		},
		{
			unixtime: 1656399399,
			balance: 99939.28
		},
		{
			unixtime: 1656399459,
			balance: 99939.28
		},
		{
			unixtime: 1656399519,
			balance: 99939.28
		},
		{
			unixtime: 1656399579,
			balance: 99939.28
		},
		{
			unixtime: 1656399639,
			balance: 99939.28
		},
		{
			unixtime: 1656399699,
			balance: 99939.28
		},
		{
			unixtime: 1656399759,
			balance: 99939.28
		},
		{
			unixtime: 1656399819,
			balance: 99939.28
		},
		{
			unixtime: 1656399879,
			balance: 99939.28
		},
		{
			unixtime: 1656399939,
			balance: 99939.28
		},
		{
			unixtime: 1656399999,
			balance: 99939.28
		},
		{
			unixtime: 1656400059,
			balance: 99939.28
		},
		{
			unixtime: 1656400119,
			balance: 99939.28
		},
		{
			unixtime: 1656400179,
			balance: 99939.28
		},
		{
			unixtime: 1656400239,
			balance: 99939.28
		},
		{
			unixtime: 1656400299,
			balance: 99939.28
		},
		{
			unixtime: 1656400359,
			balance: 99939.28
		},
		{
			unixtime: 1656400419,
			balance: 99939.28
		},
		{
			unixtime: 1656400479,
			balance: 99939.28
		},
		{
			unixtime: 1656400539,
			balance: 99939.28
		},
		{
			unixtime: 1656400599,
			balance: 99939.28
		},
		{
			unixtime: 1656400659,
			balance: 99939.28
		},
		{
			unixtime: 1656400719,
			balance: 99939.28
		},
		{
			unixtime: 1656400779,
			balance: 99939.28
		},
		{
			unixtime: 1656400839,
			balance: 99939.28
		},
		{
			unixtime: 1656400899,
			balance: 99939.28
		},
		{
			unixtime: 1656400959,
			balance: 99939.28
		},
		{
			unixtime: 1656401019,
			balance: 99939.28
		},
		{
			unixtime: 1656401079,
			balance: 99939.28
		},
		{
			unixtime: 1656401139,
			balance: 99939.28
		},
		{
			unixtime: 1656401199,
			balance: 99939.28
		},
		{
			unixtime: 1656401259,
			balance: 99939.28
		},
		{
			unixtime: 1656401319,
			balance: 99939.28
		},
		{
			unixtime: 1656401379,
			balance: 99939.28
		},
		{
			unixtime: 1656401439,
			balance: 99939.28
		},
		{
			unixtime: 1656401499,
			balance: 99939.28
		},
		{
			unixtime: 1656401559,
			balance: 99939.28
		},
		{
			unixtime: 1656401619,
			balance: 99939.28
		},
		{
			unixtime: 1656401679,
			balance: 99939.28
		},
		{
			unixtime: 1656401739,
			balance: 99939.28
		},
		{
			unixtime: 1656401799,
			balance: 99939.28
		},
		{
			unixtime: 1656401859,
			balance: 99939.28
		},
		{
			unixtime: 1656401919,
			balance: 99939.28
		},
		{
			unixtime: 1656401979,
			balance: 99939.28
		},
		{
			unixtime: 1656402039,
			balance: 99939.28
		},
		{
			unixtime: 1656402099,
			balance: 99939.28
		},
		{
			unixtime: 1656402159,
			balance: 99939.28
		},
		{
			unixtime: 1656402219,
			balance: 99939.28
		},
		{
			unixtime: 1656402279,
			balance: 99939.28
		},
		{
			unixtime: 1656402339,
			balance: 99939.28
		},
		{
			unixtime: 1656402399,
			balance: 99939.28
		},
		{
			unixtime: 1656402459,
			balance: 99939.28
		},
		{
			unixtime: 1656402519,
			balance: 99939.28
		},
		{
			unixtime: 1656402579,
			balance: 99939.28
		},
		{
			unixtime: 1656402639,
			balance: 99939.28
		},
		{
			unixtime: 1656402699,
			balance: 99939.28
		},
		{
			unixtime: 1656402759,
			balance: 99939.28
		},
		{
			unixtime: 1656402819,
			balance: 99939.28
		},
		{
			unixtime: 1656402879,
			balance: 99939.28
		},
		{
			unixtime: 1656402939,
			balance: 99939.28
		},
		{
			unixtime: 1656402999,
			balance: 99939.28
		},
		{
			unixtime: 1656403059,
			balance: 99939.28
		},
		{
			unixtime: 1656403119,
			balance: 99939.28
		},
		{
			unixtime: 1656403179,
			balance: 99939.28
		},
		{
			unixtime: 1656403239,
			balance: 99952.36
		},
		{
			unixtime: 1656403299,
			balance: 99959.86
		},
		{
			unixtime: 1656403359,
			balance: 99966.56
		},
		{
			unixtime: 1656403419,
			balance: 99964.16
		},
		{
			unixtime: 1656403479,
			balance: 99963.4
		},
		{
			unixtime: 1656403539,
			balance: 99964.66
		},
		{
			unixtime: 1656403599,
			balance: 99964.28
		},
		{
			unixtime: 1656403659,
			balance: 99964.1
		},
		{
			unixtime: 1656403719,
			balance: 99964.2
		},
		{
			unixtime: 1656403779,
			balance: 99963.2
		},
		{
			unixtime: 1656403839,
			balance: 99960.9
		},
		{
			unixtime: 1656403899,
			balance: 99959.46
		},
		{
			unixtime: 1656403959,
			balance: 99960.7
		},
		{
			unixtime: 1656404019,
			balance: 99960.6
		},
		{
			unixtime: 1656404079,
			balance: 99960.6
		},
		{
			unixtime: 1656404139,
			balance: 99960.8
		},
		{
			unixtime: 1656404199,
			balance: 99961.86
		},
		{
			unixtime: 1656404259,
			balance: 99961.86
		},
		{
			unixtime: 1656404319,
			balance: 99961.46
		},
		{
			unixtime: 1656404379,
			balance: 99961.16
		},
		{
			unixtime: 1656404439,
			balance: 99962.54
		},
		{
			unixtime: 1656404499,
			balance: 99961.96
		},
		{
			unixtime: 1656404559,
			balance: 99981.96
		},
		{
			unixtime: 1656404619,
			balance: 99980.96
		},
		{
			unixtime: 1656404679,
			balance: 99981.56
		},
		{
			unixtime: 1656404739,
			balance: 99983.1
		},
		{
			unixtime: 1656404799,
			balance: 99983.1
		},
		{
			unixtime: 1656404859,
			balance: 99983.1
		},
		{
			unixtime: 1656404919,
			balance: 99983.16
		},
		{
			unixtime: 1656404979,
			balance: 99982.36
		},
		{
			unixtime: 1656405039,
			balance: 99982.36
		},
		{
			unixtime: 1656405099,
			balance: 99982.66
		},
		{
			unixtime: 1656405159,
			balance: 99983.56
		},
		{
			unixtime: 1656405219,
			balance: 99983.56
		},
		{
			unixtime: 1656405279,
			balance: 99983.56
		},
		{
			unixtime: 1656405339,
			balance: 99983.06
		},
		{
			unixtime: 1656405399,
			balance: 99982.54
		},
		{
			unixtime: 1656405459,
			balance: 99983.56
		},
		{
			unixtime: 1656405519,
			balance: 99984.16
		},
		{
			unixtime: 1656405579,
			balance: 99984.16
		},
		{
			unixtime: 1656405639,
			balance: 99984.16
		},
		{
			unixtime: 1656405699,
			balance: 99983.96
		},
		{
			unixtime: 1656405759,
			balance: 99983.36
		},
		{
			unixtime: 1656405819,
			balance: 99983.36
		},
		{
			unixtime: 1656405879,
			balance: 99983.36
		},
		{
			unixtime: 1656405939,
			balance: 99983.36
		},
		{
			unixtime: 1656405999,
			balance: 99983.96
		},
		{
			unixtime: 1656406059,
			balance: 99983.36
		},
		{
			unixtime: 1656406119,
			balance: 99983.36
		},
		{
			unixtime: 1656406179,
			balance: 99975.26
		},
		{
			unixtime: 1656406239,
			balance: 99976.56
		},
		{
			unixtime: 1656406299,
			balance: 99976.46
		},
		{
			unixtime: 1656406359,
			balance: 99976.66
		},
		{
			unixtime: 1656406419,
			balance: 99977.66
		},
		{
			unixtime: 1656406479,
			balance: 99977.54
		},
		{
			unixtime: 1656406539,
			balance: 99978.96
		},
		{
			unixtime: 1656406599,
			balance: 99979.46
		},
		{
			unixtime: 1656406659,
			balance: 99980.86
		},
		{
			unixtime: 1656406719,
			balance: 99982.76
		},
		{
			unixtime: 1656406779,
			balance: 99991.06
		},
		{
			unixtime: 1656406839,
			balance: 99990.56
		},
		{
			unixtime: 1656406899,
			balance: 99989.76
		},
		{
			unixtime: 1656406959,
			balance: 99990.06
		},
		{
			unixtime: 1656407019,
			balance: 99990.26
		},
		{
			unixtime: 1656407079,
			balance: 99995.26
		},
		{
			unixtime: 1656407139,
			balance: 99994.72
		},
		{
			unixtime: 1656407199,
			balance: 99996.32
		},
		{
			unixtime: 1656407259,
			balance: 99996.62
		},
		{
			unixtime: 1656407319,
			balance: 99996.22
		},
		{
			unixtime: 1656407379,
			balance: 99996.22
		},
		{
			unixtime: 1656407439,
			balance: 99998.82
		},
		{
			unixtime: 1656407499,
			balance: 100001.76
		},
		{
			unixtime: 1656407559,
			balance: 100000.96
		},
		{
			unixtime: 1656407619,
			balance: 100000.1
		},
		{
			unixtime: 1656407679,
			balance: 99999.66
		},
		{
			unixtime: 1656407739,
			balance: 99998.36
		},
		{
			unixtime: 1656407799,
			balance: 99996.86
		},
		{
			unixtime: 1656407859,
			balance: 99996.86
		},
		{
			unixtime: 1656407919,
			balance: 99996.86
		},
		{
			unixtime: 1656407979,
			balance: 99997.06
		},
		{
			unixtime: 1656408039,
			balance: 99994.96
		},
		{
			unixtime: 1656408099,
			balance: 99995.06
		},
		{
			unixtime: 1656408159,
			balance: 99995.06
		},
		{
			unixtime: 1656408219,
			balance: 99996.66
		},
		{
			unixtime: 1656408279,
			balance: 99995.06
		},
		{
			unixtime: 1656408339,
			balance: 99995.06
		},
		{
			unixtime: 1656408399,
			balance: 99995.06
		},
		{
			unixtime: 1656408459,
			balance: 99996.16
		},
		{
			unixtime: 1656408519,
			balance: 99996.16
		},
		{
			unixtime: 1656408579,
			balance: 99996.16
		},
		{
			unixtime: 1656408639,
			balance: 99995.96
		},
		{
			unixtime: 1656408699,
			balance: 99995.46
		},
		{
			unixtime: 1656408759,
			balance: 99991.16
		},
		{
			unixtime: 1656408819,
			balance: 99990.06
		},
		{
			unixtime: 1656408879,
			balance: 99988.36
		},
		{
			unixtime: 1656408939,
			balance: 99988.36
		},
		{
			unixtime: 1656408999,
			balance: 99987.48
		},
		{
			unixtime: 1656409059,
			balance: 99984.68
		},
		{
			unixtime: 1656409119,
			balance: 99983.18
		},
		{
			unixtime: 1656409179,
			balance: 99983.68
		},
		{
			unixtime: 1656409239,
			balance: 99983.48
		},
		{
			unixtime: 1656409299,
			balance: 99982.38
		},
		{
			unixtime: 1656409359,
			balance: 99982.38
		},
		{
			unixtime: 1656409419,
			balance: 99983.18
		},
		{
			unixtime: 1656409479,
			balance: 99982.9
		},
		{
			unixtime: 1656409539,
			balance: 99982.2
		},
		{
			unixtime: 1656409599,
			balance: 99982.2
		},
		{
			unixtime: 1656409659,
			balance: 99974.6
		},
		{
			unixtime: 1656409719,
			balance: 99975.8
		},
		{
			unixtime: 1656409779,
			balance: 99975.8
		},
		{
			unixtime: 1656409839,
			balance: 99976.56
		},
		{
			unixtime: 1656409899,
			balance: 99978.24
		},
		{
			unixtime: 1656409959,
			balance: 99980.34
		},
		{
			unixtime: 1656410019,
			balance: 99981.48
		},
		{
			unixtime: 1656410079,
			balance: 99981.48
		},
		{
			unixtime: 1656410139,
			balance: 99978.28
		},
		{
			unixtime: 1656410199,
			balance: 99978.28
		},
		{
			unixtime: 1656410259,
			balance: 99978.38
		},
		{
			unixtime: 1656410319,
			balance: 99973.96
		},
		{
			unixtime: 1656410379,
			balance: 99974.76
		},
		{
			unixtime: 1656410439,
			balance: 99974.76
		},
		{
			unixtime: 1656410499,
			balance: 99974.76
		},
		{
			unixtime: 1656410559,
			balance: 99974.76
		},
		{
			unixtime: 1656410619,
			balance: 99977.16
		},
		{
			unixtime: 1656410679,
			balance: 99977.16
		},
		{
			unixtime: 1656410739,
			balance: 99976.66
		},
		{
			unixtime: 1656410799,
			balance: 99976.86
		},
		{
			unixtime: 1656410859,
			balance: 99976.86
		},
		{
			unixtime: 1656410919,
			balance: 99976.86
		},
		{
			unixtime: 1656410979,
			balance: 99974.36
		},
		{
			unixtime: 1656411039,
			balance: 99981.66
		},
		{
			unixtime: 1656411099,
			balance: 99981.06
		},
		{
			unixtime: 1656411159,
			balance: 99981.06
		},
		{
			unixtime: 1656411219,
			balance: 99981.16
		},
		{
			unixtime: 1656411279,
			balance: 99981.16
		},
		{
			unixtime: 1656411339,
			balance: 99980.46
		},
		{
			unixtime: 1656411399,
			balance: 99980.66
		},
		{
			unixtime: 1656411459,
			balance: 99981.16
		},
		{
			unixtime: 1656411519,
			balance: 99980.96
		},
		{
			unixtime: 1656411579,
			balance: 99981.56
		},
		{
			unixtime: 1656411639,
			balance: 99981.56
		},
		{
			unixtime: 1656411699,
			balance: 99981.66
		},
		{
			unixtime: 1656411759,
			balance: 99982.56
		},
		{
			unixtime: 1656411819,
			balance: 99983.86
		},
		{
			unixtime: 1656411879,
			balance: 99984.96
		},
		{
			unixtime: 1656411939,
			balance: 99984.96
		},
		{
			unixtime: 1656411999,
			balance: 99983.96
		},
		{
			unixtime: 1656412059,
			balance: 99990.96
		},
		{
			unixtime: 1656412119,
			balance: 99990.96
		},
		{
			unixtime: 1656412179,
			balance: 99990.56
		},
		{
			unixtime: 1656412239,
			balance: 99990.66
		},
		{
			unixtime: 1656412299,
			balance: 99992.96
		},
		{
			unixtime: 1656412359,
			balance: 99992.96
		},
		{
			unixtime: 1656412419,
			balance: 99992.96
		},
		{
			unixtime: 1656412479,
			balance: 99992.96
		},
		{
			unixtime: 1656412539,
			balance: 99993.56
		},
		{
			unixtime: 1656412599,
			balance: 99993.46
		},
		{
			unixtime: 1656412659,
			balance: 99993.46
		},
		{
			unixtime: 1656412719,
			balance: 99993.46
		},
		{
			unixtime: 1656412779,
			balance: 99993.06
		},
		{
			unixtime: 1656412839,
			balance: 99993.06
		},
		{
			unixtime: 1656412899,
			balance: 99993.66
		},
		{
			unixtime: 1656412959,
			balance: 99993.66
		},
		{
			unixtime: 1656413019,
			balance: 99993.66
		},
		{
			unixtime: 1656413079,
			balance: 99993.66
		},
		{
			unixtime: 1656413139,
			balance: 99990.96
		},
		{
			unixtime: 1656413199,
			balance: 99990.96
		},
		{
			unixtime: 1656413259,
			balance: 99990.96
		},
		{
			unixtime: 1656413319,
			balance: 99991.96
		},
		{
			unixtime: 1656413379,
			balance: 99991.58
		},
		{
			unixtime: 1656413439,
			balance: 99991.58
		},
		{
			unixtime: 1656413499,
			balance: 99991.18
		},
		{
			unixtime: 1656413559,
			balance: 99991.18
		},
		{
			unixtime: 1656413619,
			balance: 99992.28
		},
		{
			unixtime: 1656413679,
			balance: 99992.28
		},
		{
			unixtime: 1656413739,
			balance: 99992.28
		},
		{
			unixtime: 1656413799,
			balance: 99996.28
		},
		{
			unixtime: 1656413859,
			balance: 99996.58
		},
		{
			unixtime: 1656413919,
			balance: 99996.58
		},
		{
			unixtime: 1656413979,
			balance: 99994.78
		},
		{
			unixtime: 1656414039,
			balance: 99993.16
		},
		{
			unixtime: 1656414099,
			balance: 99993.96
		},
		{
			unixtime: 1656414159,
			balance: 99989.92
		},
		{
			unixtime: 1656414219,
			balance: 99990.5
		},
		{
			unixtime: 1656414279,
			balance: 99989.28
		},
		{
			unixtime: 1656414339,
			balance: 99990.56
		},
		{
			unixtime: 1656414399,
			balance: 99990.66
		},
		{
			unixtime: 1656414459,
			balance: 99991.16
		},
		{
			unixtime: 1656414519,
			balance: 99992.26
		},
		{
			unixtime: 1656414579,
			balance: 99996.76
		},
		{
			unixtime: 1656414639,
			balance: 99997.28
		},
		{
			unixtime: 1656414699,
			balance: 99997.78
		},
		{
			unixtime: 1656414759,
			balance: 100001.82
		},
		{
			unixtime: 1656414819,
			balance: 100001.2
		},
		{
			unixtime: 1656414879,
			balance: 100001.96
		},
		{
			unixtime: 1656414939,
			balance: 100003.76
		},
		{
			unixtime: 1656414999,
			balance: 100003
		},
		{
			unixtime: 1656415059,
			balance: 100003.1
		},
		{
			unixtime: 1656415119,
			balance: 100003
		},
		{
			unixtime: 1656415179,
			balance: 100002.58
		},
		{
			unixtime: 1656415239,
			balance: 99998.68
		},
		{
			unixtime: 1656415299,
			balance: 99998.3
		},
		{
			unixtime: 1656415359,
			balance: 99998.6
		},
		{
			unixtime: 1656415419,
			balance: 99997.36
		},
		{
			unixtime: 1656415479,
			balance: 99998.16
		},
		{
			unixtime: 1656415539,
			balance: 99997.8
		},
		{
			unixtime: 1656415599,
			balance: 99996.76
		},
		{
			unixtime: 1656415659,
			balance: 99996.76
		},
		{
			unixtime: 1656415719,
			balance: 99997.42
		},
		{
			unixtime: 1656415779,
			balance: 100002.16
		},
		{
			unixtime: 1656415839,
			balance: 100001.54
		},
		{
			unixtime: 1656415899,
			balance: 99996.86
		},
		{
			unixtime: 1656415959,
			balance: 99995.16
		},
		{
			unixtime: 1656416019,
			balance: 99994.76
		},
		{
			unixtime: 1656416079,
			balance: 99994.96
		},
		{
			unixtime: 1656416139,
			balance: 99994.76
		},
		{
			unixtime: 1656416199,
			balance: 99993.46
		},
		{
			unixtime: 1656416259,
			balance: 99993.06
		},
		{
			unixtime: 1656416319,
			balance: 99998.96
		},
		{
			unixtime: 1656416379,
			balance: 99994.96
		},
		{
			unixtime: 1656416439,
			balance: 99994.16
		},
		{
			unixtime: 1656416499,
			balance: 99999.36
		},
		{
			unixtime: 1656416559,
			balance: 100000.22
		},
		{
			unixtime: 1656416619,
			balance: 100003.28
		},
		{
			unixtime: 1656416679,
			balance: 100006.38
		},
		{
			unixtime: 1656416739,
			balance: 100006.86
		},
		{
			unixtime: 1656416799,
			balance: 100005.96
		},
		{
			unixtime: 1656416859,
			balance: 100005.16
		},
		{
			unixtime: 1656416919,
			balance: 100005.06
		},
		{
			unixtime: 1656416979,
			balance: 100005.56
		},
		{
			unixtime: 1656417039,
			balance: 100004.76
		},
		{
			unixtime: 1656417099,
			balance: 100004.76
		},
		{
			unixtime: 1656417159,
			balance: 100003.52
		},
		{
			unixtime: 1656417219,
			balance: 100002.92
		},
		{
			unixtime: 1656417279,
			balance: 100000.76
		},
		{
			unixtime: 1656417339,
			balance: 100000.16
		},
		{
			unixtime: 1656417399,
			balance: 99995.16
		},
		{
			unixtime: 1656417459,
			balance: 100003.16
		},
		{
			unixtime: 1656417519,
			balance: 100005.38
		},
		{
			unixtime: 1656417579,
			balance: 100005.18
		},
		{
			unixtime: 1656417639,
			balance: 100005.26
		},
		{
			unixtime: 1656417699,
			balance: 100006.84
		},
		{
			unixtime: 1656417759,
			balance: 100003.36
		},
		{
			unixtime: 1656417819,
			balance: 100001.96
		},
		{
			unixtime: 1656417879,
			balance: 100002.06
		},
		{
			unixtime: 1656417939,
			balance: 100003.36
		},
		{
			unixtime: 1656417999,
			balance: 100006.16
		},
		{
			unixtime: 1656418059,
			balance: 100000.56
		},
		{
			unixtime: 1656418119,
			balance: 100000.86
		},
		{
			unixtime: 1656418179,
			balance: 100001.16
		},
		{
			unixtime: 1656418239,
			balance: 100000.46
		},
		{
			unixtime: 1656418299,
			balance: 100003.34
		},
		{
			unixtime: 1656418359,
			balance: 99997.26
		},
		{
			unixtime: 1656418419,
			balance: 100001.66
		},
		{
			unixtime: 1656418479,
			balance: 100000.86
		},
		{
			unixtime: 1656418539,
			balance: 99996.12
		},
		{
			unixtime: 1656418599,
			balance: 99996.12
		},
		{
			unixtime: 1656418659,
			balance: 99997.4
		},
		{
			unixtime: 1656418719,
			balance: 99991.26
		},
		{
			unixtime: 1656418779,
			balance: 99989.36
		},
		{
			unixtime: 1656418839,
			balance: 99989.46
		},
		{
			unixtime: 1656418899,
			balance: 99984.46
		},
		{
			unixtime: 1656418959,
			balance: 99980.26
		},
		{
			unixtime: 1656419019,
			balance: 99981.16
		},
		{
			unixtime: 1656419079,
			balance: 99986.36
		},
		{
			unixtime: 1656419139,
			balance: 99985.92
		},
		{
			unixtime: 1656419199,
			balance: 99983.86
		},
		{
			unixtime: 1656419259,
			balance: 99976.56
		},
		{
			unixtime: 1656419319,
			balance: 99976.76
		},
		{
			unixtime: 1656419379,
			balance: 99973.18
		},
		{
			unixtime: 1656419439,
			balance: 99983.16
		},
		{
			unixtime: 1656419499,
			balance: 99980.02
		},
		{
			unixtime: 1656419559,
			balance: 99977.28
		},
		{
			unixtime: 1656419619,
			balance: 99977.08
		},
		{
			unixtime: 1656419679,
			balance: 99976.66
		},
		{
			unixtime: 1656419739,
			balance: 99974.26
		},
		{
			unixtime: 1656419799,
			balance: 99977.88
		},
		{
			unixtime: 1656419859,
			balance: 99972.16
		},
		{
			unixtime: 1656419919,
			balance: 99980.46
		},
		{
			unixtime: 1656419979,
			balance: 99983.04
		},
		{
			unixtime: 1656420039,
			balance: 99985.88
		},
		{
			unixtime: 1656420099,
			balance: 99987.06
		},
		{
			unixtime: 1656420159,
			balance: 99988.56
		},
		{
			unixtime: 1656420219,
			balance: 99990.36
		},
		{
			unixtime: 1656420279,
			balance: 99987.66
		},
		{
			unixtime: 1656420339,
			balance: 99987.36
		},
		{
			unixtime: 1656420399,
			balance: 99988.66
		},
		{
			unixtime: 1656420459,
			balance: 99990.76
		},
		{
			unixtime: 1656420519,
			balance: 99989.16
		},
		{
			unixtime: 1656420579,
			balance: 99987.56
		},
		{
			unixtime: 1656420639,
			balance: 99986.98
		},
		{
			unixtime: 1656420699,
			balance: 99985.78
		},
		{
			unixtime: 1656420759,
			balance: 99989.98
		},
		{
			unixtime: 1656420819,
			balance: 99993.26
		},
		{
			unixtime: 1656420880,
			balance: 99991.46
		},
		{
			unixtime: 1656420939,
			balance: 99990.28
		},
		{
			unixtime: 1656420999,
			balance: 99989.16
		},
		{
			unixtime: 1656421059,
			balance: 99989.06
		},
		{
			unixtime: 1656421119,
			balance: 99989.56
		},
		{
			unixtime: 1656421179,
			balance: 99995.22
		},
		{
			unixtime: 1656421239,
			balance: 99996.9
		},
		{
			unixtime: 1656421299,
			balance: 99991.04
		},
		{
			unixtime: 1656421359,
			balance: 99992.56
		},
		{
			unixtime: 1656421419,
			balance: 99994.36
		},
		{
			unixtime: 1656421479,
			balance: 99994.94
		},
		{
			unixtime: 1656421539,
			balance: 99996.96
		},
		{
			unixtime: 1656421599,
			balance: 99997.86
		},
		{
			unixtime: 1656421659,
			balance: 99999.48
		},
		{
			unixtime: 1656421719,
			balance: 100000.1
		},
		{
			unixtime: 1656421779,
			balance: 99999
		},
		{
			unixtime: 1656421839,
			balance: 99995.68
		},
		{
			unixtime: 1656421899,
			balance: 99995.56
		},
		{
			unixtime: 1656421959,
			balance: 99991.78
		},
		{
			unixtime: 1656422019,
			balance: 99996.16
		},
		{
			unixtime: 1656422079,
			balance: 99999.66
		},
		{
			unixtime: 1656422139,
			balance: 100007
		},
		{
			unixtime: 1656422199,
			balance: 100007.96
		},
		{
			unixtime: 1656422259,
			balance: 100006.86
		},
		{
			unixtime: 1656422319,
			balance: 100007.96
		},
		{
			unixtime: 1656422379,
			balance: 100008.34
		},
		{
			unixtime: 1656422439,
			balance: 100010.06
		},
		{
			unixtime: 1656422499,
			balance: 100006.42
		},
		{
			unixtime: 1656422559,
			balance: 100007.62
		},
		{
			unixtime: 1656422620,
			balance: 100006.74
		},
		{
			unixtime: 1656422679,
			balance: 100005.16
		},
		{
			unixtime: 1656422739,
			balance: 100006.9
		},
		{
			unixtime: 1656422799,
			balance: 99998.38
		},
		{
			unixtime: 1656422859,
			balance: 99999.96
		},
		{
			unixtime: 1656422919,
			balance: 99997.36
		},
		{
			unixtime: 1656422979,
			balance: 100002.16
		},
		{
			unixtime: 1656423039,
			balance: 100004.8
		},
		{
			unixtime: 1656423099,
			balance: 100016.52
		},
		{
			unixtime: 1656423159,
			balance: 100053.1
		},
		{
			unixtime: 1656423219,
			balance: 100071.32
		},
		{
			unixtime: 1656423279,
			balance: 100062.48
		},
		{
			unixtime: 1656423339,
			balance: 100069.04
		},
		{
			unixtime: 1656423399,
			balance: 100070.2
		},
		{
			unixtime: 1656423459,
			balance: 100073.64
		},
		{
			unixtime: 1656423519,
			balance: 100080.96
		},
		{
			unixtime: 1656423579,
			balance: 100086.2
		},
		{
			unixtime: 1656423639,
			balance: 100076.28
		},
		{
			unixtime: 1656423699,
			balance: 100080
		},
		{
			unixtime: 1656423760,
			balance: 100081.6
		},
		{
			unixtime: 1656423819,
			balance: 100071.36
		},
		{
			unixtime: 1656423879,
			balance: 100076.24
		},
		{
			unixtime: 1656423939,
			balance: 100077.84
		},
		{
			unixtime: 1656423999,
			balance: 100086.24
		},
		{
			unixtime: 1656424059,
			balance: 100089.82
		},
		{
			unixtime: 1656424119,
			balance: 100088.48
		},
		{
			unixtime: 1656424179,
			balance: 100097.54
		},
		{
			unixtime: 1656424239,
			balance: 100102
		},
		{
			unixtime: 1656424300,
			balance: 100098.06
		},
		{
			unixtime: 1656424359,
			balance: 100098.6
		},
		{
			unixtime: 1656424419,
			balance: 100098.3
		},
		{
			unixtime: 1656424479,
			balance: 100100.14
		},
		{
			unixtime: 1656424539,
			balance: 100096.32
		},
		{
			unixtime: 1656424599,
			balance: 100091.6
		},
		{
			unixtime: 1656424660,
			balance: 100076.58
		},
		{
			unixtime: 1656424719,
			balance: 100068.96
		},
		{
			unixtime: 1656424780,
			balance: 100081.5
		},
		{
			unixtime: 1656424839,
			balance: 100084
		},
		{
			unixtime: 1656424899,
			balance: 100086.2
		},
		{
			unixtime: 1656424959,
			balance: 100064.98
		},
		{
			unixtime: 1656425019,
			balance: 100044.76
		},
		{
			unixtime: 1656425079,
			balance: 100050.98
		},
		{
			unixtime: 1656425139,
			balance: 100039.06
		},
		{
			unixtime: 1656425199,
			balance: 100028.84
		},
		{
			unixtime: 1656425259,
			balance: 100017.34
		},
		{
			unixtime: 1656425319,
			balance: 100023.46
		},
		{
			unixtime: 1656425379,
			balance: 100021.78
		},
		{
			unixtime: 1656425439,
			balance: 100023.82
		},
		{
			unixtime: 1656425500,
			balance: 100030.36
		},
		{
			unixtime: 1656425559,
			balance: 100040.48
		},
		{
			unixtime: 1656425619,
			balance: 100038.44
		},
		{
			unixtime: 1656425679,
			balance: 100037.76
		},
		{
			unixtime: 1656425739,
			balance: 100036.32
		},
		{
			unixtime: 1656425799,
			balance: 100024.7
		},
		{
			unixtime: 1656425859,
			balance: 100026.78
		},
		{
			unixtime: 1656425919,
			balance: 100026.48
		},
		{
			unixtime: 1656425979,
			balance: 100028.24
		},
		{
			unixtime: 1656426039,
			balance: 100018.44
		},
		{
			unixtime: 1656426099,
			balance: 100001.16
		},
		{
			unixtime: 1656426159,
			balance: 100003.4
		},
		{
			unixtime: 1656426219,
			balance: 100002.88
		},
		{
			unixtime: 1656426279,
			balance: 100010.16
		},
		{
			unixtime: 1656426339,
			balance: 100014.26
		},
		{
			unixtime: 1656426399,
			balance: 100009.82
		},
		{
			unixtime: 1656426459,
			balance: 100014.7
		},
		{
			unixtime: 1656426519,
			balance: 100007.04
		},
		{
			unixtime: 1656426579,
			balance: 100008.78
		},
		{
			unixtime: 1656426639,
			balance: 99998.94
		},
		{
			unixtime: 1656426699,
			balance: 99993.82
		},
		{
			unixtime: 1656426759,
			balance: 99982.3
		},
		{
			unixtime: 1656426819,
			balance: 99984.66
		},
		{
			unixtime: 1656426879,
			balance: 99986.6
		},
		{
			unixtime: 1656426939,
			balance: 99986.4
		},
		{
			unixtime: 1656426999,
			balance: 99989.94
		},
		{
			unixtime: 1656427059,
			balance: 99990.52
		},
		{
			unixtime: 1656427119,
			balance: 99999.58
		},
		{
			unixtime: 1656427179,
			balance: 99999.62
		},
		{
			unixtime: 1656427239,
			balance: 99994.74
		},
		{
			unixtime: 1656427299,
			balance: 99997.44
		},
		{
			unixtime: 1656427359,
			balance: 99975.28
		},
		{
			unixtime: 1656427419,
			balance: 99991.46
		},
		{
			unixtime: 1656427479,
			balance: 99994
		},
		{
			unixtime: 1656427539,
			balance: 99993.6
		},
		{
			unixtime: 1656427599,
			balance: 99978.74
		},
		{
			unixtime: 1656427659,
			balance: 99967.64
		},
		{
			unixtime: 1656427719,
			balance: 99970.36
		},
		{
			unixtime: 1656427779,
			balance: 99981.96
		},
		{
			unixtime: 1656427839,
			balance: 99980.06
		},
		{
			unixtime: 1656427899,
			balance: 99971.7
		},
		{
			unixtime: 1656427959,
			balance: 99972.82
		},
		{
			unixtime: 1656428019,
			balance: 99965.34
		},
		{
			unixtime: 1656428079,
			balance: 99964.28
		},
		{
			unixtime: 1656428139,
			balance: 99971.08
		},
		{
			unixtime: 1656428199,
			balance: 99977.12
		},
		{
			unixtime: 1656428259,
			balance: 99984.04
		},
		{
			unixtime: 1656428319,
			balance: 99981.26
		},
		{
			unixtime: 1656428379,
			balance: 99990.72
		},
		{
			unixtime: 1656428439,
			balance: 99985.62
		},
		{
			unixtime: 1656428499,
			balance: 99980.1
		},
		{
			unixtime: 1656428560,
			balance: 99983.24
		},
		{
			unixtime: 1656428619,
			balance: 99990.18
		},
		{
			unixtime: 1656428679,
			balance: 99996.9
		},
		{
			unixtime: 1656428739,
			balance: 99995.48
		},
		{
			unixtime: 1656428799,
			balance: 99990.54
		},
		{
			unixtime: 1656428859,
			balance: 99991.68
		},
		{
			unixtime: 1656428919,
			balance: 99985.9
		},
		{
			unixtime: 1656428979,
			balance: 99975.8
		},
		{
			unixtime: 1656429039,
			balance: 99968.56
		},
		{
			unixtime: 1656429099,
			balance: 99978.64
		},
		{
			unixtime: 1656429159,
			balance: 99965.96
		},
		{
			unixtime: 1656429219,
			balance: 99968.02
		},
		{
			unixtime: 1656429279,
			balance: 99964.46
		},
		{
			unixtime: 1656429339,
			balance: 99948.8
		},
		{
			unixtime: 1656429399,
			balance: 99942.2
		},
		{
			unixtime: 1656429460,
			balance: 99939.88
		},
		{
			unixtime: 1656429519,
			balance: 99933.88
		},
		{
			unixtime: 1656429579,
			balance: 99926.68
		},
		{
			unixtime: 1656429639,
			balance: 99924.22
		},
		{
			unixtime: 1656429699,
			balance: 99910.7
		},
		{
			unixtime: 1656429759,
			balance: 99907.26
		},
		{
			unixtime: 1656429819,
			balance: 99898.06
		},
		{
			unixtime: 1656429879,
			balance: 99892.96
		},
		{
			unixtime: 1656429939,
			balance: 99898.16
		},
		{
			unixtime: 1656429999,
			balance: 99891.68
		},
		{
			unixtime: 1656430060,
			balance: 99881.22
		},
		{
			unixtime: 1656430119,
			balance: 99884.16
		},
		{
			unixtime: 1656430179,
			balance: 99885.68
		},
		{
			unixtime: 1656430239,
			balance: 99880.58
		},
		{
			unixtime: 1656430299,
			balance: 99892.12
		},
		{
			unixtime: 1656430359,
			balance: 99885.46
		},
		{
			unixtime: 1656430419,
			balance: 99885.58
		},
		{
			unixtime: 1656430479,
			balance: 99892.68
		},
		{
			unixtime: 1656430539,
			balance: 99886.98
		},
		{
			unixtime: 1656430599,
			balance: 99885.24
		},
		{
			unixtime: 1656430659,
			balance: 99882.46
		},
		{
			unixtime: 1656430719,
			balance: 99872.88
		},
		{
			unixtime: 1656430779,
			balance: 99867.44
		},
		{
			unixtime: 1656430839,
			balance: 99857.76
		},
		{
			unixtime: 1656430899,
			balance: 99855.84
		},
		{
			unixtime: 1656430959,
			balance: 99851.44
		},
		{
			unixtime: 1656431019,
			balance: 99854.38
		},
		{
			unixtime: 1656431079,
			balance: 99857.26
		},
		{
			unixtime: 1656431139,
			balance: 99866.74
		},
		{
			unixtime: 1656431199,
			balance: 99862.98
		},
		{
			unixtime: 1656431259,
			balance: 99866.7
		},
		{
			unixtime: 1656431319,
			balance: 99862.14
		},
		{
			unixtime: 1656431379,
			balance: 99862.36
		},
		{
			unixtime: 1656431439,
			balance: 99862.48
		},
		{
			unixtime: 1656431499,
			balance: 99854.54
		},
		{
			unixtime: 1656431559,
			balance: 99860.76
		},
		{
			unixtime: 1656431619,
			balance: 99853.98
		},
		{
			unixtime: 1656431680,
			balance: 99847
		},
		{
			unixtime: 1656431739,
			balance: 99842.76
		},
		{
			unixtime: 1656431799,
			balance: 99841.5
		},
		{
			unixtime: 1656431859,
			balance: 99843.78
		},
		{
			unixtime: 1656431919,
			balance: 99854.18
		},
		{
			unixtime: 1656431979,
			balance: 99850.98
		},
		{
			unixtime: 1656432039,
			balance: 99843.46
		},
		{
			unixtime: 1656432099,
			balance: 99851.1
		},
		{
			unixtime: 1656432159,
			balance: 99837.42
		},
		{
			unixtime: 1656432219,
			balance: 99842.16
		},
		{
			unixtime: 1656432279,
			balance: 99833.74
		},
		{
			unixtime: 1656432339,
			balance: 99839.06
		},
		{
			unixtime: 1656432399,
			balance: 99838.88
		},
		{
			unixtime: 1656432459,
			balance: 99846.32
		},
		{
			unixtime: 1656432519,
			balance: 99849.46
		},
		{
			unixtime: 1656432579,
			balance: 99852.56
		},
		{
			unixtime: 1656432639,
			balance: 99853.4
		},
		{
			unixtime: 1656432699,
			balance: 99860.62
		},
		{
			unixtime: 1656432759,
			balance: 99862.7
		},
		{
			unixtime: 1656432819,
			balance: 99867.36
		},
		{
			unixtime: 1656432879,
			balance: 99860.8
		},
		{
			unixtime: 1656432939,
			balance: 99852.1
		},
		{
			unixtime: 1656432999,
			balance: 99845.7
		},
		{
			unixtime: 1656433059,
			balance: 99841.34
		},
		{
			unixtime: 1656433119,
			balance: 99839.76
		},
		{
			unixtime: 1656433179,
			balance: 99850.68
		},
		{
			unixtime: 1656433239,
			balance: 99859.28
		},
		{
			unixtime: 1656433299,
			balance: 99854.66
		},
		{
			unixtime: 1656433359,
			balance: 99854.5
		},
		{
			unixtime: 1656433419,
			balance: 99854.22
		},
		{
			unixtime: 1656433479,
			balance: 99864.58
		},
		{
			unixtime: 1656433539,
			balance: 99867.16
		},
		{
			unixtime: 1656433599,
			balance: 99868.54
		},
		{
			unixtime: 1656433659,
			balance: 99876.9
		},
		{
			unixtime: 1656433719,
			balance: 99877.54
		},
		{
			unixtime: 1656433779,
			balance: 99879.56
		},
		{
			unixtime: 1656433839,
			balance: 99877.4
		},
		{
			unixtime: 1656433899,
			balance: 99877.36
		},
		{
			unixtime: 1656433960,
			balance: 99869.24
		},
		{
			unixtime: 1656434019,
			balance: 99870.96
		},
		{
			unixtime: 1656434079,
			balance: 99862.76
		},
		{
			unixtime: 1656434139,
			balance: 99867.26
		},
		{
			unixtime: 1656434199,
			balance: 99870.7
		},
		{
			unixtime: 1656434260,
			balance: 99876.66
		},
		{
			unixtime: 1656434320,
			balance: 99873.92
		},
		{
			unixtime: 1656434380,
			balance: 99872.62
		},
		{
			unixtime: 1656434439,
			balance: 99877.04
		},
		{
			unixtime: 1656434500,
			balance: 99878.24
		},
		{
			unixtime: 1656434559,
			balance: 99880.34
		},
		{
			unixtime: 1656434619,
			balance: 99878.64
		},
		{
			unixtime: 1656434679,
			balance: 99885.24
		},
		{
			unixtime: 1656434739,
			balance: 99885.64
		},
		{
			unixtime: 1656434799,
			balance: 99884.32
		},
		{
			unixtime: 1656434859,
			balance: 99883.44
		},
		{
			unixtime: 1656434919,
			balance: 99874.28
		},
		{
			unixtime: 1656434979,
			balance: 99875.44
		},
		{
			unixtime: 1656435039,
			balance: 99873.52
		},
		{
			unixtime: 1656435099,
			balance: 99870.02
		},
		{
			unixtime: 1656435159,
			balance: 99866.36
		},
		{
			unixtime: 1656435219,
			balance: 99872.26
		},
		{
			unixtime: 1656435280,
			balance: 99864.22
		},
		{
			unixtime: 1656435339,
			balance: 99865.38
		},
		{
			unixtime: 1656435399,
			balance: 99869.14
		},
		{
			unixtime: 1656435459,
			balance: 99862.86
		},
		{
			unixtime: 1656435520,
			balance: 99860.72
		},
		{
			unixtime: 1656435580,
			balance: 99864.3
		},
		{
			unixtime: 1656435639,
			balance: 99855.58
		},
		{
			unixtime: 1656435699,
			balance: 99862.96
		},
		{
			unixtime: 1656435759,
			balance: 99859.9
		},
		{
			unixtime: 1656435819,
			balance: 99853.5
		},
		{
			unixtime: 1656435879,
			balance: 99858.16
		},
		{
			unixtime: 1656435939,
			balance: 99856.08
		},
		{
			unixtime: 1656436000,
			balance: 99850.96
		},
		{
			unixtime: 1656436059,
			balance: 99843.64
		},
		{
			unixtime: 1656436119,
			balance: 99837.9
		},
		{
			unixtime: 1656436179,
			balance: 99831.1
		},
		{
			unixtime: 1656436239,
			balance: 99835.46
		},
		{
			unixtime: 1656436299,
			balance: 99827.54
		},
		{
			unixtime: 1656436360,
			balance: 99826.36
		},
		{
			unixtime: 1656436419,
			balance: 99830.9
		},
		{
			unixtime: 1656436479,
			balance: 99824.82
		},
		{
			unixtime: 1656436539,
			balance: 99829.42
		},
		{
			unixtime: 1656436599,
			balance: 99828.84
		},
		{
			unixtime: 1656436659,
			balance: 99830.78
		},
		{
			unixtime: 1656436719,
			balance: 99833.4
		},
		{
			unixtime: 1656436780,
			balance: 99827.24
		},
		{
			unixtime: 1656436839,
			balance: 99826.56
		},
		{
			unixtime: 1656436900,
			balance: 99818.02
		},
		{
			unixtime: 1656436959,
			balance: 99814.82
		},
		{
			unixtime: 1656437019,
			balance: 99822.3
		},
		{
			unixtime: 1656437079,
			balance: 99828.96
		},
		{
			unixtime: 1656437139,
			balance: 99827.26
		},
		{
			unixtime: 1656437199,
			balance: 99828.38
		},
		{
			unixtime: 1656437260,
			balance: 99830.9
		},
		{
			unixtime: 1656437319,
			balance: 99833.26
		},
		{
			unixtime: 1656437379,
			balance: 99841.58
		},
		{
			unixtime: 1656437439,
			balance: 99839
		},
		{
			unixtime: 1656437499,
			balance: 99836.78
		},
		{
			unixtime: 1656437559,
			balance: 99834.26
		},
		{
			unixtime: 1656437620,
			balance: 99835.56
		},
		{
			unixtime: 1656437679,
			balance: 99831.04
		},
		{
			unixtime: 1656437739,
			balance: 99830.9
		},
		{
			unixtime: 1656437799,
			balance: 99823.7
		},
		{
			unixtime: 1656437859,
			balance: 99829.94
		},
		{
			unixtime: 1656437920,
			balance: 99822.06
		},
		{
			unixtime: 1656437979,
			balance: 99822.4
		},
		{
			unixtime: 1656438040,
			balance: 99827.2
		},
		{
			unixtime: 1656438099,
			balance: 99831.42
		},
		{
			unixtime: 1656438159,
			balance: 99834.08
		},
		{
			unixtime: 1656438219,
			balance: 99833.16
		},
		{
			unixtime: 1656438280,
			balance: 99832.02
		},
		{
			unixtime: 1656438339,
			balance: 99829.52
		},
		{
			unixtime: 1656438399,
			balance: 99826.66
		},
		{
			unixtime: 1656438460,
			balance: 99836.26
		},
		{
			unixtime: 1656438519,
			balance: 99835.54
		},
		{
			unixtime: 1656438580,
			balance: 99836.64
		},
		{
			unixtime: 1656438639,
			balance: 99838.5
		},
		{
			unixtime: 1656438699,
			balance: 99840.32
		},
		{
			unixtime: 1656438760,
			balance: 99840.88
		},
		{
			unixtime: 1656438819,
			balance: 99837.6
		},
		{
			unixtime: 1656438880,
			balance: 99832.84
		},
		{
			unixtime: 1656438940,
			balance: 99831.86
		},
		{
			unixtime: 1656438999,
			balance: 99830.7
		},
		{
			unixtime: 1656439060,
			balance: 99836.62
		},
		{
			unixtime: 1656439120,
			balance: 99834.2
		},
		{
			unixtime: 1656439180,
			balance: 99832.14
		},
		{
			unixtime: 1656439240,
			balance: 99832.98
		},
		{
			unixtime: 1656439300,
			balance: 99838.24
		},
		{
			unixtime: 1656439360,
			balance: 99836.32
		},
		{
			unixtime: 1656439420,
			balance: 99831.02
		},
		{
			unixtime: 1656439479,
			balance: 99828.68
		},
		{
			unixtime: 1656439539,
			balance: 99825.56
		},
		{
			unixtime: 1656439599,
			balance: 99824.46
		},
		{
			unixtime: 1656439659,
			balance: 99823.3
		},
		{
			unixtime: 1656439720,
			balance: 99820.98
		},
		{
			unixtime: 1656439780,
			balance: 99813.86
		},
		{
			unixtime: 1656439840,
			balance: 99813.38
		},
		{
			unixtime: 1656439900,
			balance: 99815.44
		},
		{
			unixtime: 1656439960,
			balance: 99814.72
		},
		{
			unixtime: 1656440020,
			balance: 99802.72
		},
		{
			unixtime: 1656440079,
			balance: 99799.06
		},
		{
			unixtime: 1656440140,
			balance: 99796.4
		},
		{
			unixtime: 1656440200,
			balance: 99794.94
		},
		{
			unixtime: 1656440260,
			balance: 99800.7
		},
		{
			unixtime: 1656440319,
			balance: 99796.32
		},
		{
			unixtime: 1656440380,
			balance: 99791.54
		},
		{
			unixtime: 1656440439,
			balance: 99795.18
		},
		{
			unixtime: 1656440500,
			balance: 99794.64
		},
		{
			unixtime: 1656440559,
			balance: 99791.54
		},
		{
			unixtime: 1656440619,
			balance: 99791.76
		},
		{
			unixtime: 1656440679,
			balance: 99793.96
		},
		{
			unixtime: 1656440740,
			balance: 99793.88
		},
		{
			unixtime: 1656440800,
			balance: 99795.2
		},
		{
			unixtime: 1656440860,
			balance: 99793.3
		},
		{
			unixtime: 1656440920,
			balance: 99795.02
		},
		{
			unixtime: 1656440980,
			balance: 99795.68
		},
		{
			unixtime: 1656441040,
			balance: 99788.36
		},
		{
			unixtime: 1656441099,
			balance: 99788.84
		},
		{
			unixtime: 1656441160,
			balance: 99783.94
		},
		{
			unixtime: 1656441220,
			balance: 99783.84
		},
		{
			unixtime: 1656441280,
			balance: 99780.9
		},
		{
			unixtime: 1656441340,
			balance: 99778.32
		},
		{
			unixtime: 1656441400,
			balance: 99778.72
		},
		{
			unixtime: 1656441459,
			balance: 99780.56
		},
		{
			unixtime: 1656441519,
			balance: 99780.62
		},
		{
			unixtime: 1656441580,
			balance: 99774.3
		},
		{
			unixtime: 1656441640,
			balance: 99773.88
		},
		{
			unixtime: 1656441700,
			balance: 99768.64
		},
		{
			unixtime: 1656441760,
			balance: 99765.08
		},
		{
			unixtime: 1656441819,
			balance: 99770.98
		},
		{
			unixtime: 1656441879,
			balance: 99776.44
		},
		{
			unixtime: 1656441940,
			balance: 99777.16
		},
		{
			unixtime: 1656442000,
			balance: 99783.78
		},
		{
			unixtime: 1656442060,
			balance: 99787.64
		},
		{
			unixtime: 1656442120,
			balance: 99790.16
		},
		{
			unixtime: 1656442179,
			balance: 99797.42
		},
		{
			unixtime: 1656442240,
			balance: 99795.64
		},
		{
			unixtime: 1656442300,
			balance: 99792.46
		},
		{
			unixtime: 1656442360,
			balance: 99788.5
		},
		{
			unixtime: 1656442420,
			balance: 99791.82
		},
		{
			unixtime: 1656442479,
			balance: 99791.78
		},
		{
			unixtime: 1656442540,
			balance: 99793.04
		},
		{
			unixtime: 1656442600,
			balance: 99783.04
		},
		{
			unixtime: 1656442660,
			balance: 99787.2
		},
		{
			unixtime: 1656442720,
			balance: 99784.9
		},
		{
			unixtime: 1656442780,
			balance: 99784.24
		},
		{
			unixtime: 1656442839,
			balance: 99788.74
		},
		{
			unixtime: 1656442900,
			balance: 99789.02
		},
		{
			unixtime: 1656442959,
			balance: 99786.34
		},
		{
			unixtime: 1656443020,
			balance: 99775.66
		},
		{
			unixtime: 1656443080,
			balance: 99772.76
		},
		{
			unixtime: 1656443139,
			balance: 99775.16
		},
		{
			unixtime: 1656443200,
			balance: 99770.62
		},
		{
			unixtime: 1656443260,
			balance: 99770.18
		},
		{
			unixtime: 1656443320,
			balance: 99767.82
		},
		{
			unixtime: 1656443379,
			balance: 99771.94
		},
		{
			unixtime: 1656443440,
			balance: 99775.76
		},
		{
			unixtime: 1656443500,
			balance: 99773.9
		},
		{
			unixtime: 1656443560,
			balance: 99774.72
		},
		{
			unixtime: 1656443620,
			balance: 99767.7
		},
		{
			unixtime: 1656443680,
			balance: 99771.56
		},
		{
			unixtime: 1656443739,
			balance: 99772.6
		},
		{
			unixtime: 1656443800,
			balance: 99777.66
		},
		{
			unixtime: 1656443860,
			balance: 99777.28
		},
		{
			unixtime: 1656443920,
			balance: 99780.3
		},
		{
			unixtime: 1656443980,
			balance: 99786.28
		},
		{
			unixtime: 1656444040,
			balance: 99779.84
		},
		{
			unixtime: 1656444100,
			balance: 99781.24
		},
		{
			unixtime: 1656444160,
			balance: 99785.44
		},
		{
			unixtime: 1656444220,
			balance: 99795.28
		},
		{
			unixtime: 1656444280,
			balance: 99800.88
		},
		{
			unixtime: 1656444340,
			balance: 99793.78
		},
		{
			unixtime: 1656444400,
			balance: 99797.36
		},
		{
			unixtime: 1656444460,
			balance: 99789.6
		},
		{
			unixtime: 1656444520,
			balance: 99788.28
		},
		{
			unixtime: 1656444580,
			balance: 99793.4
		},
		{
			unixtime: 1656444640,
			balance: 99792.68
		},
		{
			unixtime: 1656444699,
			balance: 99790.8
		},
		{
			unixtime: 1656444760,
			balance: 99793.64
		},
		{
			unixtime: 1656444820,
			balance: 99791.58
		},
		{
			unixtime: 1656444880,
			balance: 99785.28
		},
		{
			unixtime: 1656444940,
			balance: 99785.08
		},
		{
			unixtime: 1656445000,
			balance: 99787.88
		},
		{
			unixtime: 1656445060,
			balance: 99781.32
		},
		{
			unixtime: 1656445120,
			balance: 99782.82
		},
		{
			unixtime: 1656445180,
			balance: 99785.24
		},
		{
			unixtime: 1656445240,
			balance: 99788.12
		},
		{
			unixtime: 1656445300,
			balance: 99780.76
		},
		{
			unixtime: 1656445360,
			balance: 99786.18
		},
		{
			unixtime: 1656445420,
			balance: 99784.92
		},
		{
			unixtime: 1656445480,
			balance: 99774.36
		},
		{
			unixtime: 1656445540,
			balance: 99773.8
		},
		{
			unixtime: 1656445600,
			balance: 99772.96
		},
		{
			unixtime: 1656445660,
			balance: 99771.06
		},
		{
			unixtime: 1656445720,
			balance: 99769.64
		},
		{
			unixtime: 1656445780,
			balance: 99773.52
		},
		{
			unixtime: 1656445840,
			balance: 99775.56
		},
		{
			unixtime: 1656445900,
			balance: 99775.54
		},
		{
			unixtime: 1656445960,
			balance: 99774.02
		},
		{
			unixtime: 1656446020,
			balance: 99779.32
		},
		{
			unixtime: 1656446080,
			balance: 99783
		},
		{
			unixtime: 1656446140,
			balance: 99774.12
		},
		{
			unixtime: 1656446200,
			balance: 99770.56
		},
		{
			unixtime: 1656446260,
			balance: 99769.18
		},
		{
			unixtime: 1656446320,
			balance: 99774.08
		},
		{
			unixtime: 1656446380,
			balance: 99769.64
		},
		{
			unixtime: 1656446440,
			balance: 99767.66
		},
		{
			unixtime: 1656446500,
			balance: 99763.76
		},
		{
			unixtime: 1656446560,
			balance: 99764.94
		},
		{
			unixtime: 1656446620,
			balance: 99766.54
		},
		{
			unixtime: 1656446680,
			balance: 99768.74
		},
		{
			unixtime: 1656446740,
			balance: 99768.22
		},
		{
			unixtime: 1656446800,
			balance: 99764.32
		},
		{
			unixtime: 1656446860,
			balance: 99763.38
		},
		{
			unixtime: 1656446920,
			balance: 99762.56
		},
		{
			unixtime: 1656446980,
			balance: 99762.24
		},
		{
			unixtime: 1656447040,
			balance: 99767.84
		},
		{
			unixtime: 1656447100,
			balance: 99764.86
		},
		{
			unixtime: 1656447160,
			balance: 99768.9
		},
		{
			unixtime: 1656447220,
			balance: 99770.88
		},
		{
			unixtime: 1656447280,
			balance: 99769.68
		},
		{
			unixtime: 1656447340,
			balance: 99768.38
		},
		{
			unixtime: 1656447400,
			balance: 99766.56
		},
		{
			unixtime: 1656447460,
			balance: 99766.6
		},
		{
			unixtime: 1656447520,
			balance: 99768.96
		},
		{
			unixtime: 1656447580,
			balance: 99765.7
		},
		{
			unixtime: 1656447640,
			balance: 99766.56
		},
		{
			unixtime: 1656447700,
			balance: 99767.36
		},
		{
			unixtime: 1656447760,
			balance: 99766.76
		},
		{
			unixtime: 1656447820,
			balance: 99769.66
		},
		{
			unixtime: 1656447880,
			balance: 99769.32
		},
		{
			unixtime: 1656447940,
			balance: 99768.98
		},
		{
			unixtime: 1656448000,
			balance: 99769.36
		},
		{
			unixtime: 1656448060,
			balance: 99767.76
		},
		{
			unixtime: 1656448120,
			balance: 99766.06
		},
		{
			unixtime: 1656448180,
			balance: 99766.48
		},
		{
			unixtime: 1656448239,
			balance: 99767.38
		},
		{
			unixtime: 1656448300,
			balance: 99768.28
		},
		{
			unixtime: 1656448360,
			balance: 99765.86
		},
		{
			unixtime: 1656448420,
			balance: 99765.86
		},
		{
			unixtime: 1656448480,
			balance: 99767
		},
		{
			unixtime: 1656448540,
			balance: 99767.46
		},
		{
			unixtime: 1656448600,
			balance: 99767.56
		},
		{
			unixtime: 1656448660,
			balance: 99767.16
		},
		{
			unixtime: 1656448720,
			balance: 99773.24
		},
		{
			unixtime: 1656448780,
			balance: 99770.96
		},
		{
			unixtime: 1656448840,
			balance: 99768.36
		},
		{
			unixtime: 1656448900,
			balance: 99768.86
		},
		{
			unixtime: 1656448960,
			balance: 99766.88
		},
		{
			unixtime: 1656449020,
			balance: 99767.66
		},
		{
			unixtime: 1656449080,
			balance: 99767.66
		},
		{
			unixtime: 1656449140,
			balance: 99767.76
		},
		{
			unixtime: 1656449200,
			balance: 99767.36
		},
		{
			unixtime: 1656449260,
			balance: 99767.16
		},
		{
			unixtime: 1656449320,
			balance: 99762.32
		},
		{
			unixtime: 1656449380,
			balance: 99761.6
		},
		{
			unixtime: 1656449440,
			balance: 99760.34
		},
		{
			unixtime: 1656449500,
			balance: 99761.96
		},
		{
			unixtime: 1656449560,
			balance: 99766.04
		},
		{
			unixtime: 1656449620,
			balance: 99765.84
		},
		{
			unixtime: 1656449680,
			balance: 99762.96
		},
		{
			unixtime: 1656449740,
			balance: 99763.86
		},
		{
			unixtime: 1656449800,
			balance: 99765.76
		},
		{
			unixtime: 1656449860,
			balance: 99767.24
		},
		{
			unixtime: 1656449920,
			balance: 99767.2
		},
		{
			unixtime: 1656449980,
			balance: 99769.88
		},
		{
			unixtime: 1656450040,
			balance: 99770.16
		},
		{
			unixtime: 1656450100,
			balance: 99769.74
		},
		{
			unixtime: 1656450160,
			balance: 99769.74
		},
		{
			unixtime: 1656450220,
			balance: 99766.52
		},
		{
			unixtime: 1656450280,
			balance: 99767.74
		},
		{
			unixtime: 1656450340,
			balance: 99772.76
		},
		{
			unixtime: 1656450400,
			balance: 99773.46
		},
		{
			unixtime: 1656450460,
			balance: 99772.08
		},
		{
			unixtime: 1656450520,
			balance: 99771.64
		},
		{
			unixtime: 1656450580,
			balance: 99772.46
		},
		{
			unixtime: 1656450640,
			balance: 99772.16
		},
		{
			unixtime: 1656450700,
			balance: 99771.96
		},
		{
			unixtime: 1656450760,
			balance: 99768.46
		},
		{
			unixtime: 1656450820,
			balance: 99767.46
		},
		{
			unixtime: 1656450880,
			balance: 99767.42
		},
		{
			unixtime: 1656450940,
			balance: 99769.46
		},
		{
			unixtime: 1656451000,
			balance: 99771.44
		},
		{
			unixtime: 1656451060,
			balance: 99770.98
		},
		{
			unixtime: 1656451120,
			balance: 99769.28
		},
		{
			unixtime: 1656451180,
			balance: 99767.88
		},
		{
			unixtime: 1656451240,
			balance: 99767.76
		},
		{
			unixtime: 1656451300,
			balance: 99767.76
		},
		{
			unixtime: 1656451360,
			balance: 99767.28
		},
		{
			unixtime: 1656451420,
			balance: 99769.12
		},
		{
			unixtime: 1656451480,
			balance: 99769.12
		},
		{
			unixtime: 1656451540,
			balance: 99767.68
		},
		{
			unixtime: 1656451600,
			balance: 99772.74
		},
		{
			unixtime: 1656451660,
			balance: 99767.48
		},
		{
			unixtime: 1656451720,
			balance: 99767.48
		},
		{
			unixtime: 1656451780,
			balance: 99768.78
		},
		{
			unixtime: 1656451840,
			balance: 99769.36
		},
		{
			unixtime: 1656451900,
			balance: 99769.46
		},
		{
			unixtime: 1656451960,
			balance: 99769.26
		},
		{
			unixtime: 1656452020,
			balance: 99770.24
		},
		{
			unixtime: 1656452080,
			balance: 99769.26
		},
		{
			unixtime: 1656452140,
			balance: 99769.26
		},
		{
			unixtime: 1656452200,
			balance: 99768.26
		},
		{
			unixtime: 1656452260,
			balance: 99768.76
		},
		{
			unixtime: 1656452320,
			balance: 99769.06
		},
		{
			unixtime: 1656452380,
			balance: 99768.96
		},
		{
			unixtime: 1656452440,
			balance: 99768.46
		},
		{
			unixtime: 1656452500,
			balance: 99768.96
		},
		{
			unixtime: 1656452560,
			balance: 99768.46
		},
		{
			unixtime: 1656452620,
			balance: 99768.46
		},
		{
			unixtime: 1656452680,
			balance: 99769.04
		},
		{
			unixtime: 1656452740,
			balance: 99769.04
		},
		{
			unixtime: 1656452800,
			balance: 99770.5
		},
		{
			unixtime: 1656452860,
			balance: 99770.48
		},
		{
			unixtime: 1656452920,
			balance: 99769.76
		},
		{
			unixtime: 1656452980,
			balance: 99769.86
		},
		{
			unixtime: 1656453040,
			balance: 99770.36
		},
		{
			unixtime: 1656453100,
			balance: 99769.28
		},
		{
			unixtime: 1656453160,
			balance: 99768.7
		},
		{
			unixtime: 1656453220,
			balance: 99768.36
		},
		{
			unixtime: 1656453280,
			balance: 99768.46
		},
		{
			unixtime: 1656453340,
			balance: 99767.4
		},
		{
			unixtime: 1656453400,
			balance: 99767.7
		},
		{
			unixtime: 1656453460,
			balance: 99769.2
		},
		{
			unixtime: 1656453520,
			balance: 99768.78
		},
		{
			unixtime: 1656453580,
			balance: 99768.26
		},
		{
			unixtime: 1656453640,
			balance: 99767.96
		},
		{
			unixtime: 1656453700,
			balance: 99768.36
		},
		{
			unixtime: 1656453760,
			balance: 99768.06
		},
		{
			unixtime: 1656453820,
			balance: 99767.76
		},
		{
			unixtime: 1656453880,
			balance: 99769.1
		},
		{
			unixtime: 1656453940,
			balance: 99773.8
		},
		{
			unixtime: 1656454000,
			balance: 99773.76
		},
		{
			unixtime: 1656454060,
			balance: 99773.68
		},
		{
			unixtime: 1656454120,
			balance: 99773.4
		},
		{
			unixtime: 1656454180,
			balance: 99774.12
		},
		{
			unixtime: 1656454240,
			balance: 99776.1
		},
		{
			unixtime: 1656454300,
			balance: 99777.02
		},
		{
			unixtime: 1656454360,
			balance: 99776.86
		},
		{
			unixtime: 1656454420,
			balance: 99775.56
		},
		{
			unixtime: 1656454480,
			balance: 99775.26
		},
		{
			unixtime: 1656454540,
			balance: 99775.16
		},
		{
			unixtime: 1656454600,
			balance: 99775.26
		},
		{
			unixtime: 1656454660,
			balance: 99775.56
		},
		{
			unixtime: 1656454720,
			balance: 99775.56
		},
		{
			unixtime: 1656454780,
			balance: 99776.36
		},
		{
			unixtime: 1656454840,
			balance: 99776.16
		},
		{
			unixtime: 1656454900,
			balance: 99774.46
		},
		{
			unixtime: 1656454960,
			balance: 99774.26
		},
		{
			unixtime: 1656455020,
			balance: 99775.66
		},
		{
			unixtime: 1656455080,
			balance: 99775.32
		},
		{
			unixtime: 1656455140,
			balance: 99776.46
		},
		{
			unixtime: 1656455200,
			balance: 99778.26
		},
		{
			unixtime: 1656455260,
			balance: 99777.72
		},
		{
			unixtime: 1656455320,
			balance: 99775.72
		},
		{
			unixtime: 1656455380,
			balance: 99776.64
		},
		{
			unixtime: 1656455440,
			balance: 99774.16
		},
		{
			unixtime: 1656455500,
			balance: 99774.26
		},
		{
			unixtime: 1656455560,
			balance: 99774.06
		},
		{
			unixtime: 1656455620,
			balance: 99781.76
		},
		{
			unixtime: 1656455680,
			balance: 99782.56
		},
		{
			unixtime: 1656455740,
			balance: 99782.86
		},
		{
			unixtime: 1656455800,
			balance: 99783.36
		},
		{
			unixtime: 1656455860,
			balance: 99782.84
		},
		{
			unixtime: 1656455920,
			balance: 99781.64
		},
		{
			unixtime: 1656455980,
			balance: 99781.24
		},
		{
			unixtime: 1656456040,
			balance: 99776.58
		},
		{
			unixtime: 1656456100,
			balance: 99775.06
		},
		{
			unixtime: 1656456160,
			balance: 99775.86
		},
		{
			unixtime: 1656456220,
			balance: 99775.96
		},
		{
			unixtime: 1656456280,
			balance: 99775.26
		},
		{
			unixtime: 1656456340,
			balance: 99776.36
		},
		{
			unixtime: 1656456400,
			balance: 99776.04
		},
		{
			unixtime: 1656456460,
			balance: 99775.74
		},
		{
			unixtime: 1656456520,
			balance: 99775.74
		},
		{
			unixtime: 1656456580,
			balance: 99775.44
		},
		{
			unixtime: 1656456640,
			balance: 99775.64
		},
		{
			unixtime: 1656456700,
			balance: 99775.64
		},
		{
			unixtime: 1656456760,
			balance: 99775.66
		},
		{
			unixtime: 1656456820,
			balance: 99777.46
		},
		{
			unixtime: 1656456880,
			balance: 99777.26
		},
		{
			unixtime: 1656456940,
			balance: 99779.76
		},
		{
			unixtime: 1656457000,
			balance: 99780.06
		},
		{
			unixtime: 1656457060,
			balance: 99780.06
		},
		{
			unixtime: 1656457120,
			balance: 99780.06
		},
		{
			unixtime: 1656457180,
			balance: 99780.06
		},
		{
			unixtime: 1656457240,
			balance: 99774.56
		},
		{
			unixtime: 1656457300,
			balance: 99772.26
		},
		{
			unixtime: 1656457360,
			balance: 99775.44
		},
		{
			unixtime: 1656457420,
			balance: 99775.78
		},
		{
			unixtime: 1656457480,
			balance: 99776.66
		},
		{
			unixtime: 1656457540,
			balance: 99776.06
		},
		{
			unixtime: 1656457600,
			balance: 99777.56
		},
		{
			unixtime: 1656457660,
			balance: 99776.46
		},
		{
			unixtime: 1656457720,
			balance: 99776.86
		},
		{
			unixtime: 1656457780,
			balance: 99776.24
		},
		{
			unixtime: 1656457840,
			balance: 99775.6
		},
		{
			unixtime: 1656457900,
			balance: 99775.46
		},
		{
			unixtime: 1656457960,
			balance: 99776.66
		},
		{
			unixtime: 1656458020,
			balance: 99776.62
		},
		{
			unixtime: 1656458080,
			balance: 99773.42
		},
		{
			unixtime: 1656458140,
			balance: 99769.6
		},
		{
			unixtime: 1656458200,
			balance: 99769.54
		},
		{
			unixtime: 1656458260,
			balance: 99769.04
		},
		{
			unixtime: 1656458320,
			balance: 99769.86
		},
		{
			unixtime: 1656458380,
			balance: 99769.76
		},
		{
			unixtime: 1656458440,
			balance: 99770.26
		},
		{
			unixtime: 1656458500,
			balance: 99770.76
		},
		{
			unixtime: 1656458560,
			balance: 99773.06
		},
		{
			unixtime: 1656458620,
			balance: 99775.24
		},
		{
			unixtime: 1656458680,
			balance: 99776.56
		},
		{
			unixtime: 1656458740,
			balance: 99775.36
		},
		{
			unixtime: 1656458800,
			balance: 99775.46
		},
		{
			unixtime: 1656458860,
			balance: 99774.98
		},
		{
			unixtime: 1656458920,
			balance: 99776.26
		},
		{
			unixtime: 1656458980,
			balance: 99780.76
		},
		{
			unixtime: 1656459040,
			balance: 99780.88
		},
		{
			unixtime: 1656459100,
			balance: 99780.96
		},
		{
			unixtime: 1656459160,
			balance: 99781.58
		},
		{
			unixtime: 1656459220,
			balance: 99781.26
		},
		{
			unixtime: 1656459280,
			balance: 99781.76
		},
		{
			unixtime: 1656459340,
			balance: 99781.76
		},
		{
			unixtime: 1656459400,
			balance: 99780.06
		},
		{
			unixtime: 1656459460,
			balance: 99778.66
		},
		{
			unixtime: 1656459520,
			balance: 99778.36
		},
		{
			unixtime: 1656459580,
			balance: 99778.36
		},
		{
			unixtime: 1656459640,
			balance: 99779.72
		},
		{
			unixtime: 1656459700,
			balance: 99780.32
		},
		{
			unixtime: 1656459760,
			balance: 99780.26
		},
		{
			unixtime: 1656459820,
			balance: 99791.76
		},
		{
			unixtime: 1656459880,
			balance: 99792.06
		},
		{
			unixtime: 1656459940,
			balance: 99793.48
		},
		{
			unixtime: 1656460000,
			balance: 99778.9
		},
		{
			unixtime: 1656460060,
			balance: 99779.34
		},
		{
			unixtime: 1656460120,
			balance: 99778.54
		},
		{
			unixtime: 1656460180,
			balance: 99778.64
		},
		{
			unixtime: 1656460240,
			balance: 99777.96
		},
		{
			unixtime: 1656460300,
			balance: 99778.16
		},
		{
			unixtime: 1656460360,
			balance: 99776.66
		},
		{
			unixtime: 1656460420,
			balance: 99776.76
		},
		{
			unixtime: 1656460480,
			balance: 99777.16
		},
		{
			unixtime: 1656460540,
			balance: 99776.28
		},
		{
			unixtime: 1656460600,
			balance: 99774.62
		},
		{
			unixtime: 1656460660,
			balance: 99783.26
		},
		{
			unixtime: 1656460720,
			balance: 99786.26
		},
		{
			unixtime: 1656460780,
			balance: 99778.4
		},
		{
			unixtime: 1656460840,
			balance: 99776.06
		},
		{
			unixtime: 1656460900,
			balance: 99776.06
		},
		{
			unixtime: 1656460960,
			balance: 99776.06
		},
		{
			unixtime: 1656461020,
			balance: 99776.06
		},
		{
			unixtime: 1656461080,
			balance: 99776.06
		},
		{
			unixtime: 1656461140,
			balance: 99776.06
		},
		{
			unixtime: 1656461200,
			balance: 99776.06
		},
		{
			unixtime: 1656461260,
			balance: 99776.06
		},
		{
			unixtime: 1656461320,
			balance: 99776.06
		},
		{
			unixtime: 1656461380,
			balance: 99776.06
		},
		{
			unixtime: 1656461440,
			balance: 99776.06
		},
		{
			unixtime: 1656461500,
			balance: 99776.06
		},
		{
			unixtime: 1656461560,
			balance: 99776.06
		},
		{
			unixtime: 1656461620,
			balance: 99776.06
		},
		{
			unixtime: 1656461680,
			balance: 99776.06
		},
		{
			unixtime: 1656461740,
			balance: 99776.06
		},
		{
			unixtime: 1656461800,
			balance: 99776.06
		},
		{
			unixtime: 1656461860,
			balance: 99776.06
		},
		{
			unixtime: 1656461920,
			balance: 99776.06
		},
		{
			unixtime: 1656461980,
			balance: 99776.06
		},
		{
			unixtime: 1656462040,
			balance: 99776.06
		},
		{
			unixtime: 1656462100,
			balance: 99776.06
		},
		{
			unixtime: 1656462160,
			balance: 99776.06
		},
		{
			unixtime: 1656462220,
			balance: 99776.06
		},
		{
			unixtime: 1656462280,
			balance: 99776.06
		},
		{
			unixtime: 1656462340,
			balance: 99776.06
		},
		{
			unixtime: 1656462400,
			balance: 99776.06
		},
		{
			unixtime: 1656462460,
			balance: 99776.06
		},
		{
			unixtime: 1656462520,
			balance: 99776.06
		},
		{
			unixtime: 1656462580,
			balance: 99776.06
		},
		{
			unixtime: 1656462640,
			balance: 99776.06
		},
		{
			unixtime: 1656462700,
			balance: 99776.06
		},
		{
			unixtime: 1656462760,
			balance: 99776.06
		},
		{
			unixtime: 1656462820,
			balance: 99776.06
		},
		{
			unixtime: 1656462880,
			balance: 99776.06
		},
		{
			unixtime: 1656462940,
			balance: 99776.06
		},
		{
			unixtime: 1656463000,
			balance: 99776.06
		},
		{
			unixtime: 1656463060,
			balance: 99776.06
		},
		{
			unixtime: 1656463120,
			balance: 99776.06
		},
		{
			unixtime: 1656463180,
			balance: 99776.06
		},
		{
			unixtime: 1656463240,
			balance: 99776.06
		},
		{
			unixtime: 1656463300,
			balance: 99776.06
		},
		{
			unixtime: 1656463360,
			balance: 99776.06
		},
		{
			unixtime: 1656463420,
			balance: 99776.06
		},
		{
			unixtime: 1656463480,
			balance: 99776.06
		},
		{
			unixtime: 1656463540,
			balance: 99776.06
		},
		{
			unixtime: 1656463600,
			balance: 99776.06
		},
		{
			unixtime: 1656463660,
			balance: 99776.06
		},
		{
			unixtime: 1656463720,
			balance: 99776.06
		},
		{
			unixtime: 1656463780,
			balance: 99776.06
		},
		{
			unixtime: 1656463840,
			balance: 99776.06
		},
		{
			unixtime: 1656463900,
			balance: 99776.06
		},
		{
			unixtime: 1656463960,
			balance: 99776.06
		},
		{
			unixtime: 1656464020,
			balance: 99776.06
		},
		{
			unixtime: 1656464080,
			balance: 99776.06
		},
		{
			unixtime: 1656464140,
			balance: 99776.06
		},
		{
			unixtime: 1656464200,
			balance: 99776.06
		},
		{
			unixtime: 1656464260,
			balance: 99776.06
		},
		{
			unixtime: 1656464320,
			balance: 99776.06
		},
		{
			unixtime: 1656464380,
			balance: 99776.06
		},
		{
			unixtime: 1656464440,
			balance: 99776.06
		},
		{
			unixtime: 1656464500,
			balance: 99776.06
		},
		{
			unixtime: 1656464560,
			balance: 99776.06
		},
		{
			unixtime: 1656464620,
			balance: 99776.06
		},
		{
			unixtime: 1656464680,
			balance: 99776.06
		},
		{
			unixtime: 1656464740,
			balance: 99776.06
		},
		{
			unixtime: 1656464800,
			balance: 99776.06
		},
		{
			unixtime: 1656464860,
			balance: 99776.06
		},
		{
			unixtime: 1656464920,
			balance: 99776.06
		},
		{
			unixtime: 1656464980,
			balance: 99776.06
		},
		{
			unixtime: 1656465040,
			balance: 99776.06
		},
		{
			unixtime: 1656465100,
			balance: 99776.06
		},
		{
			unixtime: 1656465160,
			balance: 99776.06
		},
		{
			unixtime: 1656465220,
			balance: 99776.06
		},
		{
			unixtime: 1656465280,
			balance: 99776.06
		},
		{
			unixtime: 1656465340,
			balance: 99776.06
		},
		{
			unixtime: 1656465400,
			balance: 99776.06
		},
		{
			unixtime: 1656465460,
			balance: 99776.06
		},
		{
			unixtime: 1656465520,
			balance: 99776.06
		},
		{
			unixtime: 1656465580,
			balance: 99776.06
		},
		{
			unixtime: 1656465640,
			balance: 99776.06
		},
		{
			unixtime: 1656465700,
			balance: 99776.06
		},
		{
			unixtime: 1656465760,
			balance: 99776.06
		},
		{
			unixtime: 1656465820,
			balance: 99776.06
		},
		{
			unixtime: 1656465880,
			balance: 99776.06
		},
		{
			unixtime: 1656465940,
			balance: 99776.06
		},
		{
			unixtime: 1656466000,
			balance: 99776.06
		},
		{
			unixtime: 1656466060,
			balance: 99776.06
		},
		{
			unixtime: 1656466120,
			balance: 99776.06
		},
		{
			unixtime: 1656466180,
			balance: 99776.06
		},
		{
			unixtime: 1656466240,
			balance: 99776.06
		},
		{
			unixtime: 1656466300,
			balance: 99776.06
		},
		{
			unixtime: 1656466360,
			balance: 99776.06
		},
		{
			unixtime: 1656466420,
			balance: 99776.06
		},
		{
			unixtime: 1656466480,
			balance: 99776.06
		},
		{
			unixtime: 1656466540,
			balance: 99776.06
		},
		{
			unixtime: 1656466600,
			balance: 99776.06
		},
		{
			unixtime: 1656466660,
			balance: 99776.06
		},
		{
			unixtime: 1656466720,
			balance: 99776.06
		},
		{
			unixtime: 1656466780,
			balance: 99776.06
		},
		{
			unixtime: 1656466840,
			balance: 99776.06
		},
		{
			unixtime: 1656466900,
			balance: 99776.06
		},
		{
			unixtime: 1656466960,
			balance: 99776.06
		},
		{
			unixtime: 1656467020,
			balance: 99776.06
		},
		{
			unixtime: 1656467080,
			balance: 99776.06
		},
		{
			unixtime: 1656467140,
			balance: 99776.06
		},
		{
			unixtime: 1656467200,
			balance: 99776.06
		},
		{
			unixtime: 1656467260,
			balance: 99776.06
		},
		{
			unixtime: 1656467320,
			balance: 99776.06
		},
		{
			unixtime: 1656467380,
			balance: 99776.06
		},
		{
			unixtime: 1656467440,
			balance: 99776.06
		},
		{
			unixtime: 1656467500,
			balance: 99776.06
		},
		{
			unixtime: 1656467560,
			balance: 99776.06
		},
		{
			unixtime: 1656467620,
			balance: 99776.06
		},
		{
			unixtime: 1656467680,
			balance: 99776.06
		},
		{
			unixtime: 1656467740,
			balance: 99776.06
		},
		{
			unixtime: 1656467800,
			balance: 99776.06
		},
		{
			unixtime: 1656467860,
			balance: 99776.06
		},
		{
			unixtime: 1656467920,
			balance: 99776.06
		},
		{
			unixtime: 1656467980,
			balance: 99776.06
		},
		{
			unixtime: 1656468040,
			balance: 99776.06
		},
		{
			unixtime: 1656468100,
			balance: 99776.06
		},
		{
			unixtime: 1656468160,
			balance: 99776.06
		},
		{
			unixtime: 1656468220,
			balance: 99776.06
		},
		{
			unixtime: 1656468280,
			balance: 99776.06
		},
		{
			unixtime: 1656468340,
			balance: 99776.06
		},
		{
			unixtime: 1656468400,
			balance: 99776.06
		},
		{
			unixtime: 1656468460,
			balance: 99776.06
		},
		{
			unixtime: 1656468520,
			balance: 99776.06
		},
		{
			unixtime: 1656468580,
			balance: 99776.06
		},
		{
			unixtime: 1656468640,
			balance: 99776.06
		},
		{
			unixtime: 1656468700,
			balance: 99776.06
		},
		{
			unixtime: 1656468760,
			balance: 99776.06
		},
		{
			unixtime: 1656468820,
			balance: 99776.06
		},
		{
			unixtime: 1656468880,
			balance: 99776.06
		},
		{
			unixtime: 1656468940,
			balance: 99776.06
		},
		{
			unixtime: 1656469000,
			balance: 99776.06
		},
		{
			unixtime: 1656469060,
			balance: 99776.06
		},
		{
			unixtime: 1656469120,
			balance: 99776.06
		},
		{
			unixtime: 1656469180,
			balance: 99776.06
		},
		{
			unixtime: 1656469240,
			balance: 99776.06
		},
		{
			unixtime: 1656469300,
			balance: 99776.06
		},
		{
			unixtime: 1656469360,
			balance: 99776.06
		},
		{
			unixtime: 1656469420,
			balance: 99776.06
		},
		{
			unixtime: 1656469480,
			balance: 99776.06
		},
		{
			unixtime: 1656469540,
			balance: 99776.06
		},
		{
			unixtime: 1656469600,
			balance: 99776.06
		},
		{
			unixtime: 1656469660,
			balance: 99776.06
		},
		{
			unixtime: 1656469720,
			balance: 99776.06
		},
		{
			unixtime: 1656469780,
			balance: 99776.06
		},
		{
			unixtime: 1656469840,
			balance: 99776.06
		},
		{
			unixtime: 1656469900,
			balance: 99776.06
		},
		{
			unixtime: 1656469960,
			balance: 99776.06
		},
		{
			unixtime: 1656470020,
			balance: 99776.06
		},
		{
			unixtime: 1656470080,
			balance: 99776.06
		},
		{
			unixtime: 1656470140,
			balance: 99776.06
		},
		{
			unixtime: 1656470200,
			balance: 99776.06
		},
		{
			unixtime: 1656470260,
			balance: 99776.06
		},
		{
			unixtime: 1656470320,
			balance: 99776.06
		},
		{
			unixtime: 1656470380,
			balance: 99776.06
		},
		{
			unixtime: 1656470440,
			balance: 99776.06
		},
		{
			unixtime: 1656470500,
			balance: 99776.06
		},
		{
			unixtime: 1656470560,
			balance: 99776.06
		},
		{
			unixtime: 1656470620,
			balance: 99776.06
		},
		{
			unixtime: 1656470680,
			balance: 99776.06
		},
		{
			unixtime: 1656470740,
			balance: 99776.06
		},
		{
			unixtime: 1656470800,
			balance: 99776.06
		},
		{
			unixtime: 1656470860,
			balance: 99776.06
		},
		{
			unixtime: 1656470920,
			balance: 99776.06
		},
		{
			unixtime: 1656470980,
			balance: 99776.06
		},
		{
			unixtime: 1656471040,
			balance: 99776.06
		},
		{
			unixtime: 1656471100,
			balance: 99776.06
		},
		{
			unixtime: 1656471160,
			balance: 99776.06
		},
		{
			unixtime: 1656471220,
			balance: 99776.06
		},
		{
			unixtime: 1656471280,
			balance: 99776.06
		},
		{
			unixtime: 1656471340,
			balance: 99776.06
		},
		{
			unixtime: 1656471400,
			balance: 99776.06
		},
		{
			unixtime: 1656471460,
			balance: 99776.06
		},
		{
			unixtime: 1656471520,
			balance: 99776.06
		},
		{
			unixtime: 1656471580,
			balance: 99776.06
		},
		{
			unixtime: 1656471640,
			balance: 99776.06
		},
		{
			unixtime: 1656471700,
			balance: 99776.06
		},
		{
			unixtime: 1656471760,
			balance: 99776.06
		},
		{
			unixtime: 1656471820,
			balance: 99776.06
		},
		{
			unixtime: 1656471880,
			balance: 99776.06
		},
		{
			unixtime: 1656471940,
			balance: 99776.06
		},
		{
			unixtime: 1656472000,
			balance: 99776.06
		},
		{
			unixtime: 1656472060,
			balance: 99776.06
		},
		{
			unixtime: 1656472120,
			balance: 99776.06
		},
		{
			unixtime: 1656472180,
			balance: 99776.06
		},
		{
			unixtime: 1656472240,
			balance: 99776.06
		},
		{
			unixtime: 1656472300,
			balance: 99776.06
		},
		{
			unixtime: 1656472360,
			balance: 99776.06
		},
		{
			unixtime: 1656472420,
			balance: 99776.06
		},
		{
			unixtime: 1656472480,
			balance: 99776.06
		},
		{
			unixtime: 1656472540,
			balance: 99776.06
		},
		{
			unixtime: 1656472600,
			balance: 99776.06
		},
		{
			unixtime: 1656472660,
			balance: 99776.06
		},
		{
			unixtime: 1656472720,
			balance: 99776.06
		},
		{
			unixtime: 1656472780,
			balance: 99776.06
		},
		{
			unixtime: 1656472840,
			balance: 99776.06
		},
		{
			unixtime: 1656472900,
			balance: 99776.06
		},
		{
			unixtime: 1656472960,
			balance: 99776.06
		},
		{
			unixtime: 1656473020,
			balance: 99776.06
		},
		{
			unixtime: 1656473080,
			balance: 99776.06
		},
		{
			unixtime: 1656473140,
			balance: 99776.06
		},
		{
			unixtime: 1656473200,
			balance: 99776.06
		},
		{
			unixtime: 1656473260,
			balance: 99776.06
		},
		{
			unixtime: 1656473320,
			balance: 99776.06
		},
		{
			unixtime: 1656473380,
			balance: 99776.06
		},
		{
			unixtime: 1656473440,
			balance: 99776.06
		},
		{
			unixtime: 1656473500,
			balance: 99776.06
		},
		{
			unixtime: 1656473560,
			balance: 99776.06
		},
		{
			unixtime: 1656473620,
			balance: 99776.06
		},
		{
			unixtime: 1656473680,
			balance: 99776.06
		},
		{
			unixtime: 1656473740,
			balance: 99776.06
		},
		{
			unixtime: 1656473800,
			balance: 99776.06
		},
		{
			unixtime: 1656473860,
			balance: 99776.06
		},
		{
			unixtime: 1656473920,
			balance: 99776.06
		},
		{
			unixtime: 1656473980,
			balance: 99776.06
		},
		{
			unixtime: 1656474040,
			balance: 99776.06
		},
		{
			unixtime: 1656474100,
			balance: 99776.06
		},
		{
			unixtime: 1656474160,
			balance: 99776.06
		},
		{
			unixtime: 1656474220,
			balance: 99776.06
		},
		{
			unixtime: 1656474280,
			balance: 99776.06
		},
		{
			unixtime: 1656474340,
			balance: 99776.06
		},
		{
			unixtime: 1656474400,
			balance: 99776.06
		},
		{
			unixtime: 1656474460,
			balance: 99776.06
		},
		{
			unixtime: 1656474520,
			balance: 99776.06
		},
		{
			unixtime: 1656474580,
			balance: 99776.06
		},
		{
			unixtime: 1656474640,
			balance: 99776.06
		},
		{
			unixtime: 1656474700,
			balance: 99776.06
		},
		{
			unixtime: 1656474760,
			balance: 99776.06
		},
		{
			unixtime: 1656474820,
			balance: 99776.06
		},
		{
			unixtime: 1656474880,
			balance: 99776.06
		},
		{
			unixtime: 1656474940,
			balance: 99776.06
		},
		{
			unixtime: 1656475000,
			balance: 99776.06
		},
		{
			unixtime: 1656475060,
			balance: 99776.06
		},
		{
			unixtime: 1656475120,
			balance: 99776.06
		},
		{
			unixtime: 1656475180,
			balance: 99776.06
		},
		{
			unixtime: 1656475240,
			balance: 99776.06
		},
		{
			unixtime: 1656475300,
			balance: 99776.06
		},
		{
			unixtime: 1656475360,
			balance: 99776.06
		},
		{
			unixtime: 1656475420,
			balance: 99776.06
		},
		{
			unixtime: 1656475480,
			balance: 99776.06
		},
		{
			unixtime: 1656475540,
			balance: 99776.06
		},
		{
			unixtime: 1656475600,
			balance: 99776.06
		},
		{
			unixtime: 1656475660,
			balance: 99776.06
		},
		{
			unixtime: 1656475720,
			balance: 99776.06
		},
		{
			unixtime: 1656475780,
			balance: 99776.06
		},
		{
			unixtime: 1656475840,
			balance: 99776.06
		},
		{
			unixtime: 1656475900,
			balance: 99776.06
		},
		{
			unixtime: 1656475960,
			balance: 99776.06
		},
		{
			unixtime: 1656476020,
			balance: 99776.06
		},
		{
			unixtime: 1656476080,
			balance: 99776.06
		},
		{
			unixtime: 1656476140,
			balance: 99776.06
		},
		{
			unixtime: 1656476200,
			balance: 99776.06
		},
		{
			unixtime: 1656476260,
			balance: 99776.06
		},
		{
			unixtime: 1656476320,
			balance: 99776.06
		},
		{
			unixtime: 1656476380,
			balance: 99776.06
		},
		{
			unixtime: 1656476440,
			balance: 99776.06
		},
		{
			unixtime: 1656476500,
			balance: 99776.06
		},
		{
			unixtime: 1656476560,
			balance: 99776.06
		},
		{
			unixtime: 1656476620,
			balance: 99776.06
		},
		{
			unixtime: 1656476680,
			balance: 99776.06
		},
		{
			unixtime: 1656476740,
			balance: 99776.06
		},
		{
			unixtime: 1656476800,
			balance: 99776.06
		},
		{
			unixtime: 1656476860,
			balance: 99776.06
		},
		{
			unixtime: 1656476920,
			balance: 99776.06
		},
		{
			unixtime: 1656476980,
			balance: 99776.06
		},
		{
			unixtime: 1656477040,
			balance: 99776.06
		},
		{
			unixtime: 1656477100,
			balance: 99776.06
		},
		{
			unixtime: 1656477160,
			balance: 99776.06
		},
		{
			unixtime: 1656477220,
			balance: 99776.06
		},
		{
			unixtime: 1656477280,
			balance: 99776.06
		},
		{
			unixtime: 1656477340,
			balance: 99776.06
		},
		{
			unixtime: 1656477400,
			balance: 99776.06
		},
		{
			unixtime: 1656477460,
			balance: 99776.06
		},
		{
			unixtime: 1656477520,
			balance: 99776.06
		},
		{
			unixtime: 1656477580,
			balance: 99776.06
		},
		{
			unixtime: 1656477640,
			balance: 99776.06
		},
		{
			unixtime: 1656477700,
			balance: 99776.06
		},
		{
			unixtime: 1656477760,
			balance: 99776.06
		},
		{
			unixtime: 1656477820,
			balance: 99776.06
		},
		{
			unixtime: 1656477880,
			balance: 99776.06
		},
		{
			unixtime: 1656477940,
			balance: 99776.06
		},
		{
			unixtime: 1656478000,
			balance: 99776.06
		},
		{
			unixtime: 1656478060,
			balance: 99776.06
		},
		{
			unixtime: 1656478120,
			balance: 99776.06
		},
		{
			unixtime: 1656478180,
			balance: 99776.06
		},
		{
			unixtime: 1656478240,
			balance: 99776.06
		},
		{
			unixtime: 1656478300,
			balance: 99776.06
		},
		{
			unixtime: 1656478360,
			balance: 99776.06
		},
		{
			unixtime: 1656478420,
			balance: 99776.06
		},
		{
			unixtime: 1656478480,
			balance: 99776.06
		},
		{
			unixtime: 1656478540,
			balance: 99776.06
		},
		{
			unixtime: 1656478600,
			balance: 99776.06
		},
		{
			unixtime: 1656478660,
			balance: 99776.06
		},
		{
			unixtime: 1656478720,
			balance: 99776.06
		},
		{
			unixtime: 1656478780,
			balance: 99776.06
		},
		{
			unixtime: 1656478840,
			balance: 99776.06
		},
		{
			unixtime: 1656478900,
			balance: 99776.06
		},
		{
			unixtime: 1656478960,
			balance: 99776.06
		},
		{
			unixtime: 1656479020,
			balance: 99776.06
		},
		{
			unixtime: 1656479080,
			balance: 99776.06
		},
		{
			unixtime: 1656479140,
			balance: 99776.06
		},
		{
			unixtime: 1656479200,
			balance: 99776.06
		},
		{
			unixtime: 1656479260,
			balance: 99776.06
		},
		{
			unixtime: 1656479320,
			balance: 99776.06
		},
		{
			unixtime: 1656479380,
			balance: 99776.06
		},
		{
			unixtime: 1656479440,
			balance: 99776.06
		},
		{
			unixtime: 1656479500,
			balance: 99776.06
		},
		{
			unixtime: 1656479560,
			balance: 99776.06
		},
		{
			unixtime: 1656479620,
			balance: 99776.06
		},
		{
			unixtime: 1656479680,
			balance: 99776.06
		},
		{
			unixtime: 1656479740,
			balance: 99776.06
		},
		{
			unixtime: 1656479800,
			balance: 99776.06
		},
		{
			unixtime: 1656479860,
			balance: 99776.06
		},
		{
			unixtime: 1656479920,
			balance: 99776.06
		},
		{
			unixtime: 1656479980,
			balance: 99776.06
		},
		{
			unixtime: 1656480040,
			balance: 99776.06
		},
		{
			unixtime: 1656480100,
			balance: 99776.06
		},
		{
			unixtime: 1656480160,
			balance: 99776.06
		},
		{
			unixtime: 1656480220,
			balance: 99776.06
		},
		{
			unixtime: 1656480280,
			balance: 99776.06
		},
		{
			unixtime: 1656480340,
			balance: 99776.06
		},
		{
			unixtime: 1656480400,
			balance: 99776.06
		},
		{
			unixtime: 1656480460,
			balance: 99776.06
		},
		{
			unixtime: 1656480520,
			balance: 99776.06
		},
		{
			unixtime: 1656480580,
			balance: 99776.06
		},
		{
			unixtime: 1656480640,
			balance: 99776.06
		},
		{
			unixtime: 1656480700,
			balance: 99776.06
		},
		{
			unixtime: 1656480760,
			balance: 99776.06
		},
		{
			unixtime: 1656480820,
			balance: 99776.06
		},
		{
			unixtime: 1656480880,
			balance: 99776.06
		},
		{
			unixtime: 1656480940,
			balance: 99776.06
		},
		{
			unixtime: 1656481000,
			balance: 99776.06
		},
		{
			unixtime: 1656481060,
			balance: 99776.06
		},
		{
			unixtime: 1656481120,
			balance: 99776.06
		},
		{
			unixtime: 1656481180,
			balance: 99776.06
		},
		{
			unixtime: 1656481240,
			balance: 99776.06
		},
		{
			unixtime: 1656481300,
			balance: 99776.06
		},
		{
			unixtime: 1656481360,
			balance: 99776.06
		},
		{
			unixtime: 1656481420,
			balance: 99776.06
		},
		{
			unixtime: 1656481480,
			balance: 99776.06
		},
		{
			unixtime: 1656481540,
			balance: 99776.06
		},
		{
			unixtime: 1656481600,
			balance: 99776.06
		},
		{
			unixtime: 1656481660,
			balance: 99776.06
		},
		{
			unixtime: 1656481720,
			balance: 99776.06
		},
		{
			unixtime: 1656481780,
			balance: 99776.06
		},
		{
			unixtime: 1656481840,
			balance: 99776.06
		},
		{
			unixtime: 1656481900,
			balance: 99776.06
		},
		{
			unixtime: 1656481960,
			balance: 99776.06
		},
		{
			unixtime: 1656482020,
			balance: 99776.06
		},
		{
			unixtime: 1656482080,
			balance: 99776.06
		},
		{
			unixtime: 1656482140,
			balance: 99776.06
		},
		{
			unixtime: 1656482200,
			balance: 99776.06
		},
		{
			unixtime: 1656482260,
			balance: 99776.06
		},
		{
			unixtime: 1656482320,
			balance: 99776.06
		},
		{
			unixtime: 1656482380,
			balance: 99776.06
		},
		{
			unixtime: 1656482440,
			balance: 99776.06
		},
		{
			unixtime: 1656482500,
			balance: 99776.06
		},
		{
			unixtime: 1656482560,
			balance: 99776.06
		},
		{
			unixtime: 1656482620,
			balance: 99776.06
		},
		{
			unixtime: 1656482680,
			balance: 99776.06
		},
		{
			unixtime: 1656482740,
			balance: 99776.06
		},
		{
			unixtime: 1656482800,
			balance: 99776.06
		},
		{
			unixtime: 1656482860,
			balance: 99776.06
		},
		{
			unixtime: 1656482920,
			balance: 99776.06
		},
		{
			unixtime: 1656482980,
			balance: 99776.06
		},
		{
			unixtime: 1656483040,
			balance: 99776.06
		},
		{
			unixtime: 1656483100,
			balance: 99776.06
		},
		{
			unixtime: 1656483160,
			balance: 99776.06
		},
		{
			unixtime: 1656483220,
			balance: 99776.06
		},
		{
			unixtime: 1656483280,
			balance: 99776.06
		},
		{
			unixtime: 1656483340,
			balance: 99776.06
		},
		{
			unixtime: 1656483400,
			balance: 99776.06
		},
		{
			unixtime: 1656483460,
			balance: 99776.06
		},
		{
			unixtime: 1656483520,
			balance: 99776.06
		},
		{
			unixtime: 1656483580,
			balance: 99776.06
		},
		{
			unixtime: 1656483640,
			balance: 99776.06
		},
		{
			unixtime: 1656483700,
			balance: 99776.06
		},
		{
			unixtime: 1656483760,
			balance: 99776.06
		},
		{
			unixtime: 1656483820,
			balance: 99776.06
		},
		{
			unixtime: 1656483880,
			balance: 99776.06
		},
		{
			unixtime: 1656483940,
			balance: 99776.06
		},
		{
			unixtime: 1656484000,
			balance: 99776.06
		},
		{
			unixtime: 1656484060,
			balance: 99776.06
		},
		{
			unixtime: 1656484120,
			balance: 99776.06
		},
		{
			unixtime: 1656484180,
			balance: 99776.06
		},
		{
			unixtime: 1656484240,
			balance: 99776.06
		},
		{
			unixtime: 1656484300,
			balance: 99776.06
		},
		{
			unixtime: 1656484360,
			balance: 99776.06
		},
		{
			unixtime: 1656484420,
			balance: 99776.06
		},
		{
			unixtime: 1656484480,
			balance: 99776.06
		},
		{
			unixtime: 1656484540,
			balance: 99776.06
		},
		{
			unixtime: 1656484600,
			balance: 99776.06
		},
		{
			unixtime: 1656484660,
			balance: 99776.06
		},
		{
			unixtime: 1656484720,
			balance: 99776.06
		},
		{
			unixtime: 1656484780,
			balance: 99776.06
		},
		{
			unixtime: 1656484840,
			balance: 99776.06
		},
		{
			unixtime: 1656484900,
			balance: 99776.06
		},
		{
			unixtime: 1656484960,
			balance: 99776.06
		},
		{
			unixtime: 1656485020,
			balance: 99776.06
		},
		{
			unixtime: 1656485080,
			balance: 99776.06
		},
		{
			unixtime: 1656485140,
			balance: 99776.06
		},
		{
			unixtime: 1656485200,
			balance: 99776.06
		},
		{
			unixtime: 1656485260,
			balance: 99776.06
		},
		{
			unixtime: 1656485320,
			balance: 99776.06
		},
		{
			unixtime: 1656485380,
			balance: 99776.06
		},
		{
			unixtime: 1656485440,
			balance: 99776.06
		},
		{
			unixtime: 1656485500,
			balance: 99776.06
		},
		{
			unixtime: 1656485560,
			balance: 99776.06
		},
		{
			unixtime: 1656485620,
			balance: 99776.06
		},
		{
			unixtime: 1656485680,
			balance: 99776.06
		},
		{
			unixtime: 1656485740,
			balance: 99776.06
		},
		{
			unixtime: 1656485800,
			balance: 99776.06
		},
		{
			unixtime: 1656485860,
			balance: 99776.06
		},
		{
			unixtime: 1656485920,
			balance: 99776.06
		},
		{
			unixtime: 1656485980,
			balance: 99776.06
		},
		{
			unixtime: 1656486040,
			balance: 99776.06
		},
		{
			unixtime: 1656486100,
			balance: 99776.06
		},
		{
			unixtime: 1656486160,
			balance: 99776.06
		},
		{
			unixtime: 1656486220,
			balance: 99776.06
		},
		{
			unixtime: 1656486280,
			balance: 99776.06
		},
		{
			unixtime: 1656486340,
			balance: 99776.06
		},
		{
			unixtime: 1656486400,
			balance: 99776.06
		},
		{
			unixtime: 1656486460,
			balance: 99776.06
		},
		{
			unixtime: 1656486520,
			balance: 99776.06
		},
		{
			unixtime: 1656486580,
			balance: 99776.06
		},
		{
			unixtime: 1656486640,
			balance: 99776.06
		},
		{
			unixtime: 1656486700,
			balance: 99776.06
		},
		{
			unixtime: 1656486760,
			balance: 99776.06
		},
		{
			unixtime: 1656486820,
			balance: 99776.06
		},
		{
			unixtime: 1656486880,
			balance: 99776.06
		},
		{
			unixtime: 1656486940,
			balance: 99776.06
		},
		{
			unixtime: 1656487000,
			balance: 99776.06
		},
		{
			unixtime: 1656487060,
			balance: 99776.06
		},
		{
			unixtime: 1656487120,
			balance: 99776.06
		},
		{
			unixtime: 1656487180,
			balance: 99776.06
		},
		{
			unixtime: 1656487240,
			balance: 99776.06
		},
		{
			unixtime: 1656487300,
			balance: 99776.06
		},
		{
			unixtime: 1656487360,
			balance: 99776.06
		},
		{
			unixtime: 1656487420,
			balance: 99776.06
		},
		{
			unixtime: 1656487480,
			balance: 99776.06
		},
		{
			unixtime: 1656487540,
			balance: 99776.06
		},
		{
			unixtime: 1656487600,
			balance: 99776.06
		},
		{
			unixtime: 1656487660,
			balance: 99776.06
		},
		{
			unixtime: 1656487720,
			balance: 99776.06
		},
		{
			unixtime: 1656487780,
			balance: 99776.06
		},
		{
			unixtime: 1656487840,
			balance: 99776.06
		},
		{
			unixtime: 1656487900,
			balance: 99776.06
		},
		{
			unixtime: 1656487960,
			balance: 99776.06
		},
		{
			unixtime: 1656488020,
			balance: 99776.06
		},
		{
			unixtime: 1656488080,
			balance: 99776.06
		},
		{
			unixtime: 1656488140,
			balance: 99776.06
		},
		{
			unixtime: 1656488200,
			balance: 99776.06
		},
		{
			unixtime: 1656488260,
			balance: 99776.06
		},
		{
			unixtime: 1656488320,
			balance: 99776.06
		},
		{
			unixtime: 1656488380,
			balance: 99776.06
		},
		{
			unixtime: 1656488440,
			balance: 99776.06
		},
		{
			unixtime: 1656488500,
			balance: 99776.06
		},
		{
			unixtime: 1656488560,
			balance: 99776.06
		},
		{
			unixtime: 1656488620,
			balance: 99776.06
		},
		{
			unixtime: 1656488680,
			balance: 99776.06
		},
		{
			unixtime: 1656488740,
			balance: 99776.06
		},
		{
			unixtime: 1656488800,
			balance: 99776.06
		},
		{
			unixtime: 1656488860,
			balance: 99776.06
		},
		{
			unixtime: 1656488920,
			balance: 99776.06
		},
		{
			unixtime: 1656488980,
			balance: 99776.06
		},
		{
			unixtime: 1656489040,
			balance: 99776.06
		},
		{
			unixtime: 1656489100,
			balance: 99776.06
		},
		{
			unixtime: 1656489160,
			balance: 99776.06
		},
		{
			unixtime: 1656489220,
			balance: 99776.06
		},
		{
			unixtime: 1656489280,
			balance: 99776.06
		},
		{
			unixtime: 1656489340,
			balance: 99776.06
		},
		{
			unixtime: 1656489400,
			balance: 99776.06
		},
		{
			unixtime: 1656489460,
			balance: 99776.06
		},
		{
			unixtime: 1656489520,
			balance: 99776.06
		},
		{
			unixtime: 1656489580,
			balance: 99776.06
		},
		{
			unixtime: 1656489640,
			balance: 99781.82
		},
		{
			unixtime: 1656489700,
			balance: 99780.12
		},
		{
			unixtime: 1656489760,
			balance: 99774.84
		},
		{
			unixtime: 1656489820,
			balance: 99782.02
		},
		{
			unixtime: 1656489880,
			balance: 99784.86
		},
		{
			unixtime: 1656489940,
			balance: 99781.06
		},
		{
			unixtime: 1656490000,
			balance: 99788.86
		},
		{
			unixtime: 1656490060,
			balance: 99789.22
		},
		{
			unixtime: 1656490120,
			balance: 99768.22
		},
		{
			unixtime: 1656490180,
			balance: 99767
		},
		{
			unixtime: 1656490240,
			balance: 99766.9
		},
		{
			unixtime: 1656490300,
			balance: 99768.1
		},
		{
			unixtime: 1656490360,
			balance: 99774.9
		},
		{
			unixtime: 1656490420,
			balance: 99772.28
		},
		{
			unixtime: 1656490480,
			balance: 99771.16
		},
		{
			unixtime: 1656490540,
			balance: 99767.36
		},
		{
			unixtime: 1656490600,
			balance: 99767.36
		},
		{
			unixtime: 1656490660,
			balance: 99764.5
		},
		{
			unixtime: 1656490720,
			balance: 99765.96
		},
		{
			unixtime: 1656490780,
			balance: 99766.64
		},
		{
			unixtime: 1656490840,
			balance: 99766.64
		},
		{
			unixtime: 1656490900,
			balance: 99766.84
		},
		{
			unixtime: 1656490960,
			balance: 99766.84
		},
		{
			unixtime: 1656491020,
			balance: 99769.38
		},
		{
			unixtime: 1656491080,
			balance: 99769.7
		},
		{
			unixtime: 1656491140,
			balance: 99767.7
		},
		{
			unixtime: 1656491200,
			balance: 99767.46
		},
		{
			unixtime: 1656491260,
			balance: 99766.56
		},
		{
			unixtime: 1656491320,
			balance: 99766.56
		},
		{
			unixtime: 1656491380,
			balance: 99764.56
		},
		{
			unixtime: 1656491440,
			balance: 99764.56
		},
		{
			unixtime: 1656491500,
			balance: 99764.56
		},
		{
			unixtime: 1656491560,
			balance: 99765.98
		},
		{
			unixtime: 1656491620,
			balance: 99764.48
		},
		{
			unixtime: 1656491680,
			balance: 99764.48
		},
		{
			unixtime: 1656491740,
			balance: 99765.08
		},
		{
			unixtime: 1656491800,
			balance: 99762.18
		},
		{
			unixtime: 1656491860,
			balance: 99762.18
		},
		{
			unixtime: 1656491920,
			balance: 99767.16
		},
		{
			unixtime: 1656491980,
			balance: 99767.16
		},
		{
			unixtime: 1656492040,
			balance: 99767.16
		},
		{
			unixtime: 1656492100,
			balance: 99759.56
		},
		{
			unixtime: 1656492160,
			balance: 99754.56
		},
		{
			unixtime: 1656492220,
			balance: 99755.96
		},
		{
			unixtime: 1656492280,
			balance: 99755.96
		},
		{
			unixtime: 1656492340,
			balance: 99755.94
		},
		{
			unixtime: 1656492400,
			balance: 99756.1
		},
		{
			unixtime: 1656492460,
			balance: 99757.6
		},
		{
			unixtime: 1656492520,
			balance: 99757.6
		},
		{
			unixtime: 1656492580,
			balance: 99757.7
		},
		{
			unixtime: 1656492640,
			balance: 99757.7
		},
		{
			unixtime: 1656492700,
			balance: 99756.5
		},
		{
			unixtime: 1656492760,
			balance: 99755.38
		},
		{
			unixtime: 1656492820,
			balance: 99755.38
		},
		{
			unixtime: 1656492880,
			balance: 99754.52
		},
		{
			unixtime: 1656492940,
			balance: 99754.52
		},
		{
			unixtime: 1656493000,
			balance: 99754.46
		},
		{
			unixtime: 1656493060,
			balance: 99751.56
		},
		{
			unixtime: 1656493120,
			balance: 99754.26
		},
		{
			unixtime: 1656493180,
			balance: 99753.94
		},
		{
			unixtime: 1656493240,
			balance: 99752.16
		},
		{
			unixtime: 1656493300,
			balance: 99753.76
		},
		{
			unixtime: 1656493360,
			balance: 99753.76
		},
		{
			unixtime: 1656493420,
			balance: 99754.14
		},
		{
			unixtime: 1656493480,
			balance: 99755.06
		},
		{
			unixtime: 1656493540,
			balance: 99758.52
		},
		{
			unixtime: 1656493600,
			balance: 99759.52
		},
		{
			unixtime: 1656493660,
			balance: 99758.52
		},
		{
			unixtime: 1656493720,
			balance: 99771.52
		},
		{
			unixtime: 1656493780,
			balance: 99772.56
		},
		{
			unixtime: 1656493840,
			balance: 99771.82
		},
		{
			unixtime: 1656493900,
			balance: 99771.82
		},
		{
			unixtime: 1656493960,
			balance: 99772.06
		},
		{
			unixtime: 1656494020,
			balance: 99773.16
		},
		{
			unixtime: 1656494080,
			balance: 99772.46
		},
		{
			unixtime: 1656494140,
			balance: 99772.46
		},
		{
			unixtime: 1656494200,
			balance: 99772.16
		},
		{
			unixtime: 1656494260,
			balance: 99772.06
		},
		{
			unixtime: 1656494320,
			balance: 99770.36
		},
		{
			unixtime: 1656494380,
			balance: 99770.36
		},
		{
			unixtime: 1656494440,
			balance: 99770.36
		},
		{
			unixtime: 1656494500,
			balance: 99770.06
		},
		{
			unixtime: 1656494560,
			balance: 99770.06
		},
		{
			unixtime: 1656494620,
			balance: 99768.56
		},
		{
			unixtime: 1656494680,
			balance: 99769.16
		},
		{
			unixtime: 1656494740,
			balance: 99769.36
		},
		{
			unixtime: 1656494800,
			balance: 99769.36
		},
		{
			unixtime: 1656494860,
			balance: 99766.8
		},
		{
			unixtime: 1656494920,
			balance: 99767.4
		},
		{
			unixtime: 1656494980,
			balance: 99766.3
		},
		{
			unixtime: 1656495040,
			balance: 99766
		},
		{
			unixtime: 1656495100,
			balance: 99762.3
		},
		{
			unixtime: 1656495160,
			balance: 99762.1
		},
		{
			unixtime: 1656495220,
			balance: 99762.1
		},
		{
			unixtime: 1656495280,
			balance: 99751.46
		},
		{
			unixtime: 1656495340,
			balance: 99750.46
		},
		{
			unixtime: 1656495400,
			balance: 99747.66
		},
		{
			unixtime: 1656495460,
			balance: 99747.48
		},
		{
			unixtime: 1656495520,
			balance: 99746.68
		},
		{
			unixtime: 1656495580,
			balance: 99746.68
		},
		{
			unixtime: 1656495640,
			balance: 99746.58
		},
		{
			unixtime: 1656495700,
			balance: 99747.38
		},
		{
			unixtime: 1656495760,
			balance: 99748.38
		},
		{
			unixtime: 1656495820,
			balance: 99749.58
		},
		{
			unixtime: 1656495880,
			balance: 99749.58
		},
		{
			unixtime: 1656495940,
			balance: 99749.58
		},
		{
			unixtime: 1656496000,
			balance: 99749.58
		},
		{
			unixtime: 1656496060,
			balance: 99747.28
		},
		{
			unixtime: 1656496120,
			balance: 99747.62
		},
		{
			unixtime: 1656496180,
			balance: 99748.42
		},
		{
			unixtime: 1656496240,
			balance: 99751.76
		},
		{
			unixtime: 1656496300,
			balance: 99747
		},
		{
			unixtime: 1656496360,
			balance: 99747.16
		},
		{
			unixtime: 1656496420,
			balance: 99745.66
		},
		{
			unixtime: 1656496480,
			balance: 99746.46
		},
		{
			unixtime: 1656496540,
			balance: 99743.88
		},
		{
			unixtime: 1656496600,
			balance: 99742.58
		},
		{
			unixtime: 1656496660,
			balance: 99739.98
		},
		{
			unixtime: 1656496720,
			balance: 99739.26
		},
		{
			unixtime: 1656496780,
			balance: 99738.26
		},
		{
			unixtime: 1656496840,
			balance: 99742.36
		},
		{
			unixtime: 1656496900,
			balance: 99742.46
		},
		{
			unixtime: 1656496960,
			balance: 99743.56
		},
		{
			unixtime: 1656497020,
			balance: 99745.16
		},
		{
			unixtime: 1656497080,
			balance: 99744.76
		},
		{
			unixtime: 1656497140,
			balance: 99745.26
		},
		{
			unixtime: 1656497200,
			balance: 99748.56
		},
		{
			unixtime: 1656497260,
			balance: 99749.56
		},
		{
			unixtime: 1656497320,
			balance: 99747.96
		},
		{
			unixtime: 1656497380,
			balance: 99747.96
		},
		{
			unixtime: 1656497440,
			balance: 99747.96
		},
		{
			unixtime: 1656497500,
			balance: 99747.26
		},
		{
			unixtime: 1656497560,
			balance: 99745.66
		},
		{
			unixtime: 1656497620,
			balance: 99744.76
		},
		{
			unixtime: 1656497680,
			balance: 99744.86
		},
		{
			unixtime: 1656497740,
			balance: 99744.36
		},
		{
			unixtime: 1656497800,
			balance: 99745.86
		},
		{
			unixtime: 1656497860,
			balance: 99744.36
		},
		{
			unixtime: 1656497920,
			balance: 99744.36
		},
		{
			unixtime: 1656497980,
			balance: 99743.36
		},
		{
			unixtime: 1656498040,
			balance: 99743.56
		},
		{
			unixtime: 1656498100,
			balance: 99740.58
		},
		{
			unixtime: 1656498160,
			balance: 99742.36
		},
		{
			unixtime: 1656498220,
			balance: 99742.56
		},
		{
			unixtime: 1656498280,
			balance: 99742.56
		},
		{
			unixtime: 1656498340,
			balance: 99740.48
		},
		{
			unixtime: 1656498400,
			balance: 99740.28
		},
		{
			unixtime: 1656498460,
			balance: 99741.18
		},
		{
			unixtime: 1656498520,
			balance: 99741.96
		},
		{
			unixtime: 1656498580,
			balance: 99741.96
		},
		{
			unixtime: 1656498640,
			balance: 99740.96
		},
		{
			unixtime: 1656498700,
			balance: 99740.96
		},
		{
			unixtime: 1656498760,
			balance: 99740.36
		},
		{
			unixtime: 1656498820,
			balance: 99741.46
		},
		{
			unixtime: 1656498880,
			balance: 99740.96
		},
		{
			unixtime: 1656498940,
			balance: 99744.46
		},
		{
			unixtime: 1656499000,
			balance: 99744.46
		},
		{
			unixtime: 1656499060,
			balance: 99744.96
		},
		{
			unixtime: 1656499120,
			balance: 99744.16
		},
		{
			unixtime: 1656499180,
			balance: 99743.16
		},
		{
			unixtime: 1656499240,
			balance: 99742.96
		},
		{
			unixtime: 1656499300,
			balance: 99743.46
		},
		{
			unixtime: 1656499360,
			balance: 99744.16
		},
		{
			unixtime: 1656499420,
			balance: 99744.56
		},
		{
			unixtime: 1656499480,
			balance: 99744.76
		},
		{
			unixtime: 1656499540,
			balance: 99742.86
		},
		{
			unixtime: 1656499600,
			balance: 99742.86
		},
		{
			unixtime: 1656499660,
			balance: 99739.86
		},
		{
			unixtime: 1656499720,
			balance: 99736.26
		},
		{
			unixtime: 1656499780,
			balance: 99737.26
		},
		{
			unixtime: 1656499840,
			balance: 99738.26
		},
		{
			unixtime: 1656499900,
			balance: 99738.26
		},
		{
			unixtime: 1656499960,
			balance: 99738.4
		},
		{
			unixtime: 1656500020,
			balance: 99740.28
		},
		{
			unixtime: 1656500080,
			balance: 99740.66
		},
		{
			unixtime: 1656500140,
			balance: 99738.06
		},
		{
			unixtime: 1656500200,
			balance: 99738.06
		},
		{
			unixtime: 1656500260,
			balance: 99740.36
		},
		{
			unixtime: 1656500320,
			balance: 99740.96
		},
		{
			unixtime: 1656500380,
			balance: 99741.36
		},
		{
			unixtime: 1656500440,
			balance: 99743.7
		},
		{
			unixtime: 1656500500,
			balance: 99745.34
		},
		{
			unixtime: 1656500560,
			balance: 99741.78
		},
		{
			unixtime: 1656500620,
			balance: 99736.06
		},
		{
			unixtime: 1656500680,
			balance: 99737.76
		},
		{
			unixtime: 1656500740,
			balance: 99737.04
		},
		{
			unixtime: 1656500800,
			balance: 99737.74
		},
		{
			unixtime: 1656500860,
			balance: 99736.98
		},
		{
			unixtime: 1656500920,
			balance: 99738.46
		},
		{
			unixtime: 1656500980,
			balance: 99739.76
		},
		{
			unixtime: 1656501040,
			balance: 99736.96
		},
		{
			unixtime: 1656501100,
			balance: 99732.66
		},
		{
			unixtime: 1656501160,
			balance: 99730.76
		},
		{
			unixtime: 1656501220,
			balance: 99730.98
		},
		{
			unixtime: 1656501280,
			balance: 99732.48
		},
		{
			unixtime: 1656501340,
			balance: 99732.26
		},
		{
			unixtime: 1656501400,
			balance: 99731.1
		},
		{
			unixtime: 1656501460,
			balance: 99733.34
		},
		{
			unixtime: 1656501520,
			balance: 99732.26
		},
		{
			unixtime: 1656501580,
			balance: 99732.66
		},
		{
			unixtime: 1656501640,
			balance: 99734.3
		},
		{
			unixtime: 1656501700,
			balance: 99734.58
		},
		{
			unixtime: 1656501760,
			balance: 99735.56
		},
		{
			unixtime: 1656501820,
			balance: 99734.56
		},
		{
			unixtime: 1656501880,
			balance: 99734.44
		},
		{
			unixtime: 1656501940,
			balance: 99733.86
		},
		{
			unixtime: 1656502000,
			balance: 99734.36
		},
		{
			unixtime: 1656502060,
			balance: 99734.46
		},
		{
			unixtime: 1656502120,
			balance: 99734.84
		},
		{
			unixtime: 1656502180,
			balance: 99735.34
		},
		{
			unixtime: 1656502240,
			balance: 99731.64
		},
		{
			unixtime: 1656502300,
			balance: 99736.26
		},
		{
			unixtime: 1656502360,
			balance: 99736.96
		},
		{
			unixtime: 1656502420,
			balance: 99738.16
		},
		{
			unixtime: 1656502480,
			balance: 99738.14
		},
		{
			unixtime: 1656502540,
			balance: 99736.76
		},
		{
			unixtime: 1656502600,
			balance: 99739.86
		},
		{
			unixtime: 1656502660,
			balance: 99742.44
		},
		{
			unixtime: 1656502720,
			balance: 99748.14
		},
		{
			unixtime: 1656502780,
			balance: 99750.36
		},
		{
			unixtime: 1656502840,
			balance: 99749.94
		},
		{
			unixtime: 1656502900,
			balance: 99751.16
		},
		{
			unixtime: 1656502960,
			balance: 99751.14
		},
		{
			unixtime: 1656503020,
			balance: 99751.36
		},
		{
			unixtime: 1656503080,
			balance: 99752.26
		},
		{
			unixtime: 1656503140,
			balance: 99751.86
		},
		{
			unixtime: 1656503200,
			balance: 99756.7
		},
		{
			unixtime: 1656503260,
			balance: 99757.26
		},
		{
			unixtime: 1656503320,
			balance: 99753.02
		},
		{
			unixtime: 1656503380,
			balance: 99755.06
		},
		{
			unixtime: 1656503440,
			balance: 99754.46
		},
		{
			unixtime: 1656503500,
			balance: 99754.36
		},
		{
			unixtime: 1656503560,
			balance: 99754.76
		},
		{
			unixtime: 1656503620,
			balance: 99758.32
		},
		{
			unixtime: 1656503680,
			balance: 99765.46
		},
		{
			unixtime: 1656503740,
			balance: 99762.7
		},
		{
			unixtime: 1656503800,
			balance: 99763.2
		},
		{
			unixtime: 1656503860,
			balance: 99769.1
		},
		{
			unixtime: 1656503920,
			balance: 99771.76
		},
		{
			unixtime: 1656503980,
			balance: 99772.44
		},
		{
			unixtime: 1656504040,
			balance: 99772.46
		},
		{
			unixtime: 1656504100,
			balance: 99785.1
		},
		{
			unixtime: 1656504160,
			balance: 99792.08
		},
		{
			unixtime: 1656504220,
			balance: 99793.36
		},
		{
			unixtime: 1656504280,
			balance: 99786.36
		},
		{
			unixtime: 1656504340,
			balance: 99785.8
		},
		{
			unixtime: 1656504400,
			balance: 99791.06
		},
		{
			unixtime: 1656504460,
			balance: 99796.76
		},
		{
			unixtime: 1656504520,
			balance: 99794.16
		},
		{
			unixtime: 1656504580,
			balance: 99791.94
		},
		{
			unixtime: 1656504640,
			balance: 99784.92
		},
		{
			unixtime: 1656504700,
			balance: 99786.46
		},
		{
			unixtime: 1656504760,
			balance: 99787.96
		},
		{
			unixtime: 1656504820,
			balance: 99791.96
		},
		{
			unixtime: 1656504880,
			balance: 99791.04
		},
		{
			unixtime: 1656504940,
			balance: 99794.04
		},
		{
			unixtime: 1656505000,
			balance: 99792.76
		},
		{
			unixtime: 1656505060,
			balance: 99795.68
		},
		{
			unixtime: 1656505120,
			balance: 99792.48
		},
		{
			unixtime: 1656505180,
			balance: 99792.56
		},
		{
			unixtime: 1656505240,
			balance: 99792.46
		},
		{
			unixtime: 1656505300,
			balance: 99787.26
		},
		{
			unixtime: 1656505360,
			balance: 99787.46
		},
		{
			unixtime: 1656505420,
			balance: 99781.46
		},
		{
			unixtime: 1656505480,
			balance: 99779.46
		},
		{
			unixtime: 1656505540,
			balance: 99777.1
		},
		{
			unixtime: 1656505600,
			balance: 99778.18
		},
		{
			unixtime: 1656505660,
			balance: 99779.36
		},
		{
			unixtime: 1656505720,
			balance: 99774.86
		},
		{
			unixtime: 1656505780,
			balance: 99775.26
		},
		{
			unixtime: 1656505840,
			balance: 99773.18
		},
		{
			unixtime: 1656505900,
			balance: 99767.76
		},
		{
			unixtime: 1656505960,
			balance: 99755.76
		},
		{
			unixtime: 1656506020,
			balance: 99753.78
		},
		{
			unixtime: 1656506080,
			balance: 99759.66
		},
		{
			unixtime: 1656506140,
			balance: 99754.58
		},
		{
			unixtime: 1656506200,
			balance: 99753.7
		},
		{
			unixtime: 1656506260,
			balance: 99758.2
		},
		{
			unixtime: 1656506320,
			balance: 99756.26
		},
		{
			unixtime: 1656506380,
			balance: 99753.5
		},
		{
			unixtime: 1656506440,
			balance: 99754.48
		},
		{
			unixtime: 1656506500,
			balance: 99754.88
		},
		{
			unixtime: 1656506560,
			balance: 99756.06
		},
		{
			unixtime: 1656506620,
			balance: 99755.68
		},
		{
			unixtime: 1656506680,
			balance: 99757.74
		},
		{
			unixtime: 1656506740,
			balance: 99757.74
		},
		{
			unixtime: 1656506800,
			balance: 99756.4
		},
		{
			unixtime: 1656506860,
			balance: 99760.66
		},
		{
			unixtime: 1656506920,
			balance: 99760.7
		},
		{
			unixtime: 1656506980,
			balance: 99760.54
		},
		{
			unixtime: 1656507040,
			balance: 99757.82
		},
		{
			unixtime: 1656507100,
			balance: 99762.96
		},
		{
			unixtime: 1656507160,
			balance: 99767.52
		},
		{
			unixtime: 1656507220,
			balance: 99765.9
		},
		{
			unixtime: 1656507280,
			balance: 99766.2
		},
		{
			unixtime: 1656507340,
			balance: 99764.66
		},
		{
			unixtime: 1656507400,
			balance: 99756.78
		},
		{
			unixtime: 1656507460,
			balance: 99756.44
		},
		{
			unixtime: 1656507520,
			balance: 99761.84
		},
		{
			unixtime: 1656507580,
			balance: 99764.56
		},
		{
			unixtime: 1656507640,
			balance: 99760.32
		},
		{
			unixtime: 1656507700,
			balance: 99761.2
		},
		{
			unixtime: 1656507760,
			balance: 99764.6
		},
		{
			unixtime: 1656507820,
			balance: 99768.26
		},
		{
			unixtime: 1656507880,
			balance: 99764.76
		},
		{
			unixtime: 1656507940,
			balance: 99763.92
		},
		{
			unixtime: 1656508000,
			balance: 99764.36
		},
		{
			unixtime: 1656508060,
			balance: 99764.28
		},
		{
			unixtime: 1656508120,
			balance: 99759.46
		},
		{
			unixtime: 1656508180,
			balance: 99758.86
		},
		{
			unixtime: 1656508240,
			balance: 99761.62
		},
		{
			unixtime: 1656508300,
			balance: 99767.26
		},
		{
			unixtime: 1656508360,
			balance: 99770.68
		},
		{
			unixtime: 1656508420,
			balance: 99770.76
		},
		{
			unixtime: 1656508480,
			balance: 99771.66
		},
		{
			unixtime: 1656508540,
			balance: 99767.62
		},
		{
			unixtime: 1656508600,
			balance: 99765.42
		},
		{
			unixtime: 1656508660,
			balance: 99767.94
		},
		{
			unixtime: 1656508720,
			balance: 99770.02
		},
		{
			unixtime: 1656508780,
			balance: 99775.46
		},
		{
			unixtime: 1656508840,
			balance: 99778.26
		},
		{
			unixtime: 1656508900,
			balance: 99775.46
		},
		{
			unixtime: 1656508960,
			balance: 99770.16
		},
		{
			unixtime: 1656509020,
			balance: 99771.04
		},
		{
			unixtime: 1656509080,
			balance: 99772.26
		},
		{
			unixtime: 1656509140,
			balance: 99774.92
		},
		{
			unixtime: 1656509200,
			balance: 99770.36
		},
		{
			unixtime: 1656509260,
			balance: 99764.06
		},
		{
			unixtime: 1656509320,
			balance: 99763.26
		},
		{
			unixtime: 1656509380,
			balance: 99764.54
		},
		{
			unixtime: 1656509440,
			balance: 99774.86
		},
		{
			unixtime: 1656509500,
			balance: 99757.94
		},
		{
			unixtime: 1656509560,
			balance: 99745.68
		},
		{
			unixtime: 1656509620,
			balance: 99729.16
		},
		{
			unixtime: 1656509680,
			balance: 99721.5
		},
		{
			unixtime: 1656509740,
			balance: 99717.34
		},
		{
			unixtime: 1656509800,
			balance: 99704.82
		},
		{
			unixtime: 1656509860,
			balance: 99710.86
		},
		{
			unixtime: 1656509920,
			balance: 99706.34
		},
		{
			unixtime: 1656509980,
			balance: 99699.86
		},
		{
			unixtime: 1656510040,
			balance: 99696.46
		},
		{
			unixtime: 1656510100,
			balance: 99688.56
		},
		{
			unixtime: 1656510160,
			balance: 99722.28
		},
		{
			unixtime: 1656510220,
			balance: 99697.86
		},
		{
			unixtime: 1656510280,
			balance: 99699.6
		},
		{
			unixtime: 1656510340,
			balance: 99680.64
		},
		{
			unixtime: 1656510400,
			balance: 99682.44
		},
		{
			unixtime: 1656510460,
			balance: 99706.12
		},
		{
			unixtime: 1656510520,
			balance: 99698.64
		},
		{
			unixtime: 1656510580,
			balance: 99713.24
		},
		{
			unixtime: 1656510640,
			balance: 99728.7
		},
		{
			unixtime: 1656510700,
			balance: 99755.36
		},
		{
			unixtime: 1656510760,
			balance: 99770.72
		},
		{
			unixtime: 1656510820,
			balance: 99766.8
		},
		{
			unixtime: 1656510880,
			balance: 99764.74
		},
		{
			unixtime: 1656510940,
			balance: 99753.48
		},
		{
			unixtime: 1656511000,
			balance: 99751.54
		},
		{
			unixtime: 1656511060,
			balance: 99762.32
		},
		{
			unixtime: 1656511120,
			balance: 99740.2
		},
		{
			unixtime: 1656511180,
			balance: 99738.42
		},
		{
			unixtime: 1656511240,
			balance: 99733.66
		},
		{
			unixtime: 1656511300,
			balance: 99737.08
		},
		{
			unixtime: 1656511360,
			balance: 99759.76
		},
		{
			unixtime: 1656511420,
			balance: 99766.3
		},
		{
			unixtime: 1656511480,
			balance: 99765.1
		},
		{
			unixtime: 1656511540,
			balance: 99755.66
		},
		{
			unixtime: 1656511600,
			balance: 99753.88
		},
		{
			unixtime: 1656511660,
			balance: 99748.18
		},
		{
			unixtime: 1656511720,
			balance: 99756.58
		},
		{
			unixtime: 1656511780,
			balance: 99738.44
		},
		{
			unixtime: 1656511840,
			balance: 99742.06
		},
		{
			unixtime: 1656511900,
			balance: 99749.76
		},
		{
			unixtime: 1656511960,
			balance: 99753.36
		},
		{
			unixtime: 1656512020,
			balance: 99747.58
		},
		{
			unixtime: 1656512080,
			balance: 99734.96
		},
		{
			unixtime: 1656512140,
			balance: 99733.56
		},
		{
			unixtime: 1656512200,
			balance: 99715.76
		},
		{
			unixtime: 1656512260,
			balance: 99699.12
		},
		{
			unixtime: 1656512320,
			balance: 99692.96
		},
		{
			unixtime: 1656512380,
			balance: 99695.66
		},
		{
			unixtime: 1656512440,
			balance: 99700.52
		},
		{
			unixtime: 1656512500,
			balance: 99706.34
		},
		{
			unixtime: 1656512560,
			balance: 99693.52
		},
		{
			unixtime: 1656512620,
			balance: 99692.3
		},
		{
			unixtime: 1656512680,
			balance: 99695.78
		},
		{
			unixtime: 1656512740,
			balance: 99731.4
		},
		{
			unixtime: 1656512800,
			balance: 99722.04
		},
		{
			unixtime: 1656512860,
			balance: 99724.4
		},
		{
			unixtime: 1656512920,
			balance: 99720.04
		},
		{
			unixtime: 1656512980,
			balance: 99719.76
		},
		{
			unixtime: 1656513040,
			balance: 99725.52
		},
		{
			unixtime: 1656513100,
			balance: 99733.04
		},
		{
			unixtime: 1656513160,
			balance: 99722.5
		},
		{
			unixtime: 1656513220,
			balance: 99738.7
		},
		{
			unixtime: 1656513280,
			balance: 99734.26
		},
		{
			unixtime: 1656513340,
			balance: 99726.42
		},
		{
			unixtime: 1656513400,
			balance: 99731.3
		},
		{
			unixtime: 1656513460,
			balance: 99708.16
		},
		{
			unixtime: 1656513520,
			balance: 99706.54
		},
		{
			unixtime: 1656513580,
			balance: 99721.82
		},
		{
			unixtime: 1656513640,
			balance: 99719.32
		},
		{
			unixtime: 1656513700,
			balance: 99723.36
		},
		{
			unixtime: 1656513760,
			balance: 99747.66
		},
		{
			unixtime: 1656513820,
			balance: 99741.64
		},
		{
			unixtime: 1656513880,
			balance: 99740.42
		},
		{
			unixtime: 1656513940,
			balance: 99749.08
		},
		{
			unixtime: 1656514000,
			balance: 99735.82
		},
		{
			unixtime: 1656514060,
			balance: 99747.96
		},
		{
			unixtime: 1656514120,
			balance: 99733.62
		},
		{
			unixtime: 1656514180,
			balance: 99736.48
		},
		{
			unixtime: 1656514240,
			balance: 99744.68
		},
		{
			unixtime: 1656514300,
			balance: 99742.46
		},
		{
			unixtime: 1656514360,
			balance: 99748.76
		},
		{
			unixtime: 1656514420,
			balance: 99733.66
		},
		{
			unixtime: 1656514480,
			balance: 99728.32
		},
		{
			unixtime: 1656514540,
			balance: 99730.24
		},
		{
			unixtime: 1656514600,
			balance: 99720.68
		},
		{
			unixtime: 1656514660,
			balance: 99711.4
		},
		{
			unixtime: 1656514720,
			balance: 99706.96
		},
		{
			unixtime: 1656514780,
			balance: 99715.44
		},
		{
			unixtime: 1656514840,
			balance: 99710.7
		},
		{
			unixtime: 1656514900,
			balance: 99711.1
		},
		{
			unixtime: 1656514960,
			balance: 99710.72
		},
		{
			unixtime: 1656515020,
			balance: 99707.52
		},
		{
			unixtime: 1656515080,
			balance: 99703.52
		},
		{
			unixtime: 1656515140,
			balance: 99694.38
		},
		{
			unixtime: 1656515200,
			balance: 99700.34
		},
		{
			unixtime: 1656515260,
			balance: 99704.32
		},
		{
			unixtime: 1656515320,
			balance: 99710.7
		},
		{
			unixtime: 1656515380,
			balance: 99707.26
		},
		{
			unixtime: 1656515440,
			balance: 99708.04
		},
		{
			unixtime: 1656515500,
			balance: 99713.32
		},
		{
			unixtime: 1656515560,
			balance: 99707.96
		},
		{
			unixtime: 1656515620,
			balance: 99697.2
		},
		{
			unixtime: 1656515680,
			balance: 99693.56
		},
		{
			unixtime: 1656515740,
			balance: 99694.82
		},
		{
			unixtime: 1656515800,
			balance: 99703.9
		},
		{
			unixtime: 1656515860,
			balance: 99705.7
		},
		{
			unixtime: 1656515920,
			balance: 99692.68
		},
		{
			unixtime: 1656515980,
			balance: 99695.28
		},
		{
			unixtime: 1656516040,
			balance: 99690.12
		},
		{
			unixtime: 1656516100,
			balance: 99686.86
		},
		{
			unixtime: 1656516160,
			balance: 99677.88
		},
		{
			unixtime: 1656516220,
			balance: 99678.7
		},
		{
			unixtime: 1656516280,
			balance: 99672.08
		},
		{
			unixtime: 1656516340,
			balance: 99686.82
		},
		{
			unixtime: 1656516400,
			balance: 99680.32
		},
		{
			unixtime: 1656516460,
			balance: 99685.32
		},
		{
			unixtime: 1656516520,
			balance: 99687.8
		},
		{
			unixtime: 1656516580,
			balance: 99688.62
		},
		{
			unixtime: 1656516640,
			balance: 99679.06
		},
		{
			unixtime: 1656516700,
			balance: 99680.36
		},
		{
			unixtime: 1656516760,
			balance: 99693.2
		},
		{
			unixtime: 1656516820,
			balance: 99690.54
		},
		{
			unixtime: 1656516880,
			balance: 99700.3
		},
		{
			unixtime: 1656516940,
			balance: 99706.58
		},
		{
			unixtime: 1656517000,
			balance: 99709.8
		},
		{
			unixtime: 1656517060,
			balance: 99713.72
		},
		{
			unixtime: 1656517120,
			balance: 99707
		},
		{
			unixtime: 1656517180,
			balance: 99705.78
		},
		{
			unixtime: 1656517240,
			balance: 99700.94
		},
		{
			unixtime: 1656517300,
			balance: 99704.66
		},
		{
			unixtime: 1656517360,
			balance: 99690.52
		},
		{
			unixtime: 1656517420,
			balance: 99684.46
		},
		{
			unixtime: 1656517480,
			balance: 99686.82
		},
		{
			unixtime: 1656517540,
			balance: 99680.92
		},
		{
			unixtime: 1656517600,
			balance: 99687.88
		},
		{
			unixtime: 1656517660,
			balance: 99693.86
		},
		{
			unixtime: 1656517720,
			balance: 99688.18
		},
		{
			unixtime: 1656517780,
			balance: 99690.32
		},
		{
			unixtime: 1656517840,
			balance: 99693.98
		},
		{
			unixtime: 1656517900,
			balance: 99697.68
		},
		{
			unixtime: 1656517960,
			balance: 99700.98
		},
		{
			unixtime: 1656518020,
			balance: 99700.86
		},
		{
			unixtime: 1656518080,
			balance: 99708.8
		},
		{
			unixtime: 1656518140,
			balance: 99704.56
		},
		{
			unixtime: 1656518200,
			balance: 99703.22
		},
		{
			unixtime: 1656518260,
			balance: 99702.84
		},
		{
			unixtime: 1656518320,
			balance: 99692.98
		},
		{
			unixtime: 1656518380,
			balance: 99693.44
		},
		{
			unixtime: 1656518440,
			balance: 99694.8
		},
		{
			unixtime: 1656518500,
			balance: 99689.86
		},
		{
			unixtime: 1656518560,
			balance: 99690
		},
		{
			unixtime: 1656518620,
			balance: 99692.7
		},
		{
			unixtime: 1656518680,
			balance: 99688.98
		},
		{
			unixtime: 1656518740,
			balance: 99684.58
		},
		{
			unixtime: 1656518800,
			balance: 99679.18
		},
		{
			unixtime: 1656518860,
			balance: 99671.28
		},
		{
			unixtime: 1656518920,
			balance: 99668.18
		},
		{
			unixtime: 1656518980,
			balance: 99671.1
		},
		{
			unixtime: 1656519040,
			balance: 99668.16
		},
		{
			unixtime: 1656519100,
			balance: 99663.52
		},
		{
			unixtime: 1656519160,
			balance: 99660.4
		},
		{
			unixtime: 1656519220,
			balance: 99653.16
		},
		{
			unixtime: 1656519280,
			balance: 99656.56
		},
		{
			unixtime: 1656519340,
			balance: 99668.76
		},
		{
			unixtime: 1656519400,
			balance: 99663.84
		},
		{
			unixtime: 1656519460,
			balance: 99667.44
		},
		{
			unixtime: 1656519520,
			balance: 99665.62
		},
		{
			unixtime: 1656519580,
			balance: 99666.04
		},
		{
			unixtime: 1656519640,
			balance: 99673.84
		},
		{
			unixtime: 1656519700,
			balance: 99672.48
		},
		{
			unixtime: 1656519760,
			balance: 99664.14
		},
		{
			unixtime: 1656519820,
			balance: 99655.68
		},
		{
			unixtime: 1656519880,
			balance: 99649.3
		},
		{
			unixtime: 1656519940,
			balance: 99648.44
		},
		{
			unixtime: 1656520000,
			balance: 99644.08
		},
		{
			unixtime: 1656520060,
			balance: 99640.06
		},
		{
			unixtime: 1656520120,
			balance: 99643.6
		},
		{
			unixtime: 1656520180,
			balance: 99639.14
		},
		{
			unixtime: 1656520240,
			balance: 99642.54
		},
		{
			unixtime: 1656520300,
			balance: 99642.52
		},
		{
			unixtime: 1656520360,
			balance: 99645.82
		},
		{
			unixtime: 1656520420,
			balance: 99641
		},
		{
			unixtime: 1656520480,
			balance: 99642
		},
		{
			unixtime: 1656520540,
			balance: 99643.04
		},
		{
			unixtime: 1656520600,
			balance: 99649.92
		},
		{
			unixtime: 1656520660,
			balance: 99652.3
		},
		{
			unixtime: 1656520720,
			balance: 99657
		},
		{
			unixtime: 1656520780,
			balance: 99660.22
		},
		{
			unixtime: 1656520840,
			balance: 99657.78
		},
		{
			unixtime: 1656520900,
			balance: 99653.36
		},
		{
			unixtime: 1656520960,
			balance: 99651.88
		},
		{
			unixtime: 1656521020,
			balance: 99650.34
		},
		{
			unixtime: 1656521080,
			balance: 99653.54
		},
		{
			unixtime: 1656521140,
			balance: 99657.38
		},
		{
			unixtime: 1656521200,
			balance: 99655.56
		},
		{
			unixtime: 1656521260,
			balance: 99650.2
		},
		{
			unixtime: 1656521320,
			balance: 99647.92
		},
		{
			unixtime: 1656521380,
			balance: 99653.94
		},
		{
			unixtime: 1656521440,
			balance: 99654.42
		},
		{
			unixtime: 1656521500,
			balance: 99655.26
		},
		{
			unixtime: 1656521560,
			balance: 99656.08
		},
		{
			unixtime: 1656521620,
			balance: 99660.52
		},
		{
			unixtime: 1656521680,
			balance: 99658.26
		},
		{
			unixtime: 1656521740,
			balance: 99650.4
		},
		{
			unixtime: 1656521800,
			balance: 99651.98
		},
		{
			unixtime: 1656521860,
			balance: 99656.96
		},
		{
			unixtime: 1656521920,
			balance: 99664.2
		},
		{
			unixtime: 1656521980,
			balance: 99666.04
		},
		{
			unixtime: 1656522040,
			balance: 99660.44
		},
		{
			unixtime: 1656522100,
			balance: 99664.42
		},
		{
			unixtime: 1656522160,
			balance: 99665.36
		},
		{
			unixtime: 1656522220,
			balance: 99668.04
		},
		{
			unixtime: 1656522280,
			balance: 99659.7
		},
		{
			unixtime: 1656522340,
			balance: 99661.96
		},
		{
			unixtime: 1656522400,
			balance: 99663
		},
		{
			unixtime: 1656522460,
			balance: 99662.56
		},
		{
			unixtime: 1656522520,
			balance: 99667.26
		},
		{
			unixtime: 1656522580,
			balance: 99673.82
		},
		{
			unixtime: 1656522640,
			balance: 99680.66
		},
		{
			unixtime: 1656522700,
			balance: 99677.72
		},
		{
			unixtime: 1656522760,
			balance: 99670.5
		},
		{
			unixtime: 1656522820,
			balance: 99667.28
		},
		{
			unixtime: 1656522880,
			balance: 99658.68
		},
		{
			unixtime: 1656522940,
			balance: 99653.7
		},
		{
			unixtime: 1656523000,
			balance: 99649.68
		},
		{
			unixtime: 1656523060,
			balance: 99648.9
		},
		{
			unixtime: 1656523120,
			balance: 99650.62
		},
		{
			unixtime: 1656523180,
			balance: 99651.32
		},
		{
			unixtime: 1656523240,
			balance: 99645.3
		},
		{
			unixtime: 1656523300,
			balance: 99644.02
		},
		{
			unixtime: 1656523360,
			balance: 99645.56
		},
		{
			unixtime: 1656523420,
			balance: 99646.26
		},
		{
			unixtime: 1656523480,
			balance: 99643.3
		},
		{
			unixtime: 1656523540,
			balance: 99643.06
		},
		{
			unixtime: 1656523600,
			balance: 99648.42
		},
		{
			unixtime: 1656523660,
			balance: 99645.76
		},
		{
			unixtime: 1656523720,
			balance: 99644.84
		},
		{
			unixtime: 1656523780,
			balance: 99659.7
		},
		{
			unixtime: 1656523840,
			balance: 99658.78
		},
		{
			unixtime: 1656523900,
			balance: 99654.3
		},
		{
			unixtime: 1656523960,
			balance: 99653.42
		},
		{
			unixtime: 1656524020,
			balance: 99655.32
		},
		{
			unixtime: 1656524080,
			balance: 99655.32
		},
		{
			unixtime: 1656524140,
			balance: 99659.18
		},
		{
			unixtime: 1656524200,
			balance: 99661.58
		},
		{
			unixtime: 1656524260,
			balance: 99668.56
		},
		{
			unixtime: 1656524320,
			balance: 99668.16
		},
		{
			unixtime: 1656524380,
			balance: 99669.94
		},
		{
			unixtime: 1656524440,
			balance: 99670.8
		},
		{
			unixtime: 1656524500,
			balance: 99673.18
		},
		{
			unixtime: 1656524560,
			balance: 99678.2
		},
		{
			unixtime: 1656524620,
			balance: 99681.7
		},
		{
			unixtime: 1656524680,
			balance: 99677.74
		},
		{
			unixtime: 1656524740,
			balance: 99682.08
		},
		{
			unixtime: 1656524800,
			balance: 99678.5
		},
		{
			unixtime: 1656524860,
			balance: 99675.5
		},
		{
			unixtime: 1656524920,
			balance: 99674.28
		},
		{
			unixtime: 1656524980,
			balance: 99671.86
		},
		{
			unixtime: 1656525040,
			balance: 99674.1
		},
		{
			unixtime: 1656525100,
			balance: 99679.3
		},
		{
			unixtime: 1656525160,
			balance: 99680.2
		},
		{
			unixtime: 1656525220,
			balance: 99678.12
		},
		{
			unixtime: 1656525280,
			balance: 99678.78
		},
		{
			unixtime: 1656525340,
			balance: 99686.84
		},
		{
			unixtime: 1656525400,
			balance: 99686.74
		},
		{
			unixtime: 1656525460,
			balance: 99687.54
		},
		{
			unixtime: 1656525520,
			balance: 99690.46
		},
		{
			unixtime: 1656525580,
			balance: 99689.66
		},
		{
			unixtime: 1656525640,
			balance: 99687.56
		},
		{
			unixtime: 1656525700,
			balance: 99697.54
		},
		{
			unixtime: 1656525760,
			balance: 99708.62
		},
		{
			unixtime: 1656525820,
			balance: 99710.16
		},
		{
			unixtime: 1656525880,
			balance: 99710.16
		},
		{
			unixtime: 1656525940,
			balance: 99712.54
		},
		{
			unixtime: 1656526000,
			balance: 99713.88
		},
		{
			unixtime: 1656526060,
			balance: 99707.96
		},
		{
			unixtime: 1656526120,
			balance: 99708.14
		},
		{
			unixtime: 1656526180,
			balance: 99706.7
		},
		{
			unixtime: 1656526240,
			balance: 99701.46
		},
		{
			unixtime: 1656526300,
			balance: 99701.22
		},
		{
			unixtime: 1656526360,
			balance: 99708.86
		},
		{
			unixtime: 1656526420,
			balance: 99707.56
		},
		{
			unixtime: 1656526480,
			balance: 99709.5
		},
		{
			unixtime: 1656526540,
			balance: 99709.24
		},
		{
			unixtime: 1656526600,
			balance: 99710.02
		},
		{
			unixtime: 1656526660,
			balance: 99708.04
		},
		{
			unixtime: 1656526720,
			balance: 99707.88
		},
		{
			unixtime: 1656526780,
			balance: 99704.38
		},
		{
			unixtime: 1656526840,
			balance: 99696.6
		},
		{
			unixtime: 1656526900,
			balance: 99696.92
		},
		{
			unixtime: 1656526960,
			balance: 99691.8
		},
		{
			unixtime: 1656527020,
			balance: 99694.52
		},
		{
			unixtime: 1656527080,
			balance: 99693.58
		},
		{
			unixtime: 1656527140,
			balance: 99695.46
		},
		{
			unixtime: 1656527200,
			balance: 99696.48
		},
		{
			unixtime: 1656527260,
			balance: 99698.58
		},
		{
			unixtime: 1656527320,
			balance: 99705.66
		},
		{
			unixtime: 1656527380,
			balance: 99706.66
		},
		{
			unixtime: 1656527440,
			balance: 99705.38
		},
		{
			unixtime: 1656527500,
			balance: 99700.28
		},
		{
			unixtime: 1656527560,
			balance: 99700.12
		},
		{
			unixtime: 1656527620,
			balance: 99702.64
		},
		{
			unixtime: 1656527680,
			balance: 99699.3
		},
		{
			unixtime: 1656527740,
			balance: 99697.86
		},
		{
			unixtime: 1656527800,
			balance: 99700.12
		},
		{
			unixtime: 1656527860,
			balance: 99696.06
		},
		{
			unixtime: 1656527920,
			balance: 99698.76
		},
		{
			unixtime: 1656527980,
			balance: 99694.9
		},
		{
			unixtime: 1656528040,
			balance: 99695.58
		},
		{
			unixtime: 1656528100,
			balance: 99685.06
		},
		{
			unixtime: 1656528160,
			balance: 99683.58
		},
		{
			unixtime: 1656528220,
			balance: 99686.28
		},
		{
			unixtime: 1656528280,
			balance: 99681.1
		},
		{
			unixtime: 1656528340,
			balance: 99679
		},
		{
			unixtime: 1656528400,
			balance: 99681.52
		},
		{
			unixtime: 1656528460,
			balance: 99675.28
		},
		{
			unixtime: 1656528520,
			balance: 99683.18
		},
		{
			unixtime: 1656528580,
			balance: 99682.82
		},
		{
			unixtime: 1656528640,
			balance: 99681.98
		},
		{
			unixtime: 1656528700,
			balance: 99686.78
		},
		{
			unixtime: 1656528760,
			balance: 99687.28
		},
		{
			unixtime: 1656528820,
			balance: 99687.22
		},
		{
			unixtime: 1656528880,
			balance: 99688.24
		},
		{
			unixtime: 1656528940,
			balance: 99692.08
		},
		{
			unixtime: 1656529000,
			balance: 99693.76
		},
		{
			unixtime: 1656529060,
			balance: 99692.64
		},
		{
			unixtime: 1656529120,
			balance: 99691.12
		},
		{
			unixtime: 1656529180,
			balance: 99690.6
		},
		{
			unixtime: 1656529240,
			balance: 99691.34
		},
		{
			unixtime: 1656529300,
			balance: 99687.56
		},
		{
			unixtime: 1656529360,
			balance: 99681.68
		},
		{
			unixtime: 1656529420,
			balance: 99678.56
		},
		{
			unixtime: 1656529480,
			balance: 99687.38
		},
		{
			unixtime: 1656529540,
			balance: 99686.14
		},
		{
			unixtime: 1656529600,
			balance: 99698.22
		},
		{
			unixtime: 1656529660,
			balance: 99704.22
		},
		{
			unixtime: 1656529720,
			balance: 99703.66
		},
		{
			unixtime: 1656529780,
			balance: 99704.84
		},
		{
			unixtime: 1656529840,
			balance: 99699.04
		},
		{
			unixtime: 1656529900,
			balance: 99696.78
		},
		{
			unixtime: 1656529960,
			balance: 99697.06
		},
		{
			unixtime: 1656530020,
			balance: 99701.08
		},
		{
			unixtime: 1656530080,
			balance: 99699.92
		},
		{
			unixtime: 1656530140,
			balance: 99695.64
		},
		{
			unixtime: 1656530200,
			balance: 99704.12
		},
		{
			unixtime: 1656530260,
			balance: 99709.18
		},
		{
			unixtime: 1656530320,
			balance: 99709.04
		},
		{
			unixtime: 1656530380,
			balance: 99706.64
		},
		{
			unixtime: 1656530440,
			balance: 99704.66
		},
		{
			unixtime: 1656530500,
			balance: 99706.14
		},
		{
			unixtime: 1656530560,
			balance: 99701.5
		},
		{
			unixtime: 1656530620,
			balance: 99704.9
		},
		{
			unixtime: 1656530680,
			balance: 99707.44
		},
		{
			unixtime: 1656530740,
			balance: 99710.38
		},
		{
			unixtime: 1656530800,
			balance: 99713.94
		},
		{
			unixtime: 1656530860,
			balance: 99714.08
		},
		{
			unixtime: 1656530920,
			balance: 99716.94
		},
		{
			unixtime: 1656530980,
			balance: 99722.98
		},
		{
			unixtime: 1656531040,
			balance: 99715.6
		},
		{
			unixtime: 1656531100,
			balance: 99711.98
		},
		{
			unixtime: 1656531160,
			balance: 99712.24
		},
		{
			unixtime: 1656531220,
			balance: 99704.66
		},
		{
			unixtime: 1656531280,
			balance: 99697.82
		},
		{
			unixtime: 1656531340,
			balance: 99697.72
		},
		{
			unixtime: 1656531400,
			balance: 99693.98
		},
		{
			unixtime: 1656531460,
			balance: 99692.76
		},
		{
			unixtime: 1656531520,
			balance: 99693.16
		},
		{
			unixtime: 1656531580,
			balance: 99698.6
		},
		{
			unixtime: 1656531640,
			balance: 99692.46
		},
		{
			unixtime: 1656531700,
			balance: 99690.66
		},
		{
			unixtime: 1656531760,
			balance: 99690.74
		},
		{
			unixtime: 1656531820,
			balance: 99696.84
		},
		{
			unixtime: 1656531880,
			balance: 99692.12
		},
		{
			unixtime: 1656531940,
			balance: 99694.66
		},
		{
			unixtime: 1656532000,
			balance: 99693.64
		},
		{
			unixtime: 1656532060,
			balance: 99685.86
		},
		{
			unixtime: 1656532120,
			balance: 99688.02
		},
		{
			unixtime: 1656532180,
			balance: 99682.36
		},
		{
			unixtime: 1656532240,
			balance: 99692.96
		},
		{
			unixtime: 1656532300,
			balance: 99695.22
		},
		{
			unixtime: 1656532360,
			balance: 99684.82
		},
		{
			unixtime: 1656532420,
			balance: 99678.46
		},
		{
			unixtime: 1656532480,
			balance: 99686.26
		},
		{
			unixtime: 1656532540,
			balance: 99698.36
		},
		{
			unixtime: 1656532600,
			balance: 99706.42
		},
		{
			unixtime: 1656532660,
			balance: 99711.38
		},
		{
			unixtime: 1656532720,
			balance: 99712.66
		},
		{
			unixtime: 1656532780,
			balance: 99701.96
		},
		{
			unixtime: 1656532840,
			balance: 99704.52
		},
		{
			unixtime: 1656532900,
			balance: 99707.38
		},
		{
			unixtime: 1656532960,
			balance: 99705.64
		},
		{
			unixtime: 1656533020,
			balance: 99704
		},
		{
			unixtime: 1656533080,
			balance: 99705.56
		},
		{
			unixtime: 1656533140,
			balance: 99705.86
		},
		{
			unixtime: 1656533200,
			balance: 99707.04
		},
		{
			unixtime: 1656533260,
			balance: 99705.56
		},
		{
			unixtime: 1656533320,
			balance: 99703.06
		},
		{
			unixtime: 1656533380,
			balance: 99702.9
		},
		{
			unixtime: 1656533440,
			balance: 99702.7
		},
		{
			unixtime: 1656533500,
			balance: 99702.2
		},
		{
			unixtime: 1656533560,
			balance: 99700.36
		},
		{
			unixtime: 1656533620,
			balance: 99699.26
		},
		{
			unixtime: 1656533680,
			balance: 99696.92
		},
		{
			unixtime: 1656533740,
			balance: 99698.24
		},
		{
			unixtime: 1656533800,
			balance: 99699.28
		},
		{
			unixtime: 1656533860,
			balance: 99698.86
		},
		{
			unixtime: 1656533920,
			balance: 99697.76
		},
		{
			unixtime: 1656533980,
			balance: 99697.76
		},
		{
			unixtime: 1656534040,
			balance: 99699.16
		},
		{
			unixtime: 1656534100,
			balance: 99697.76
		},
		{
			unixtime: 1656534160,
			balance: 99695.66
		},
		{
			unixtime: 1656534220,
			balance: 99700.66
		},
		{
			unixtime: 1656534280,
			balance: 99700.26
		},
		{
			unixtime: 1656534340,
			balance: 99700.46
		},
		{
			unixtime: 1656534400,
			balance: 99697.16
		},
		{
			unixtime: 1656534460,
			balance: 99696.76
		},
		{
			unixtime: 1656534520,
			balance: 99695.28
		},
		{
			unixtime: 1656534580,
			balance: 99696.04
		},
		{
			unixtime: 1656534640,
			balance: 99697.88
		},
		{
			unixtime: 1656534700,
			balance: 99698.66
		},
		{
			unixtime: 1656534760,
			balance: 99698.76
		},
		{
			unixtime: 1656534820,
			balance: 99698.56
		},
		{
			unixtime: 1656534880,
			balance: 99699.48
		},
		{
			unixtime: 1656534940,
			balance: 99699.28
		},
		{
			unixtime: 1656535000,
			balance: 99699.28
		},
		{
			unixtime: 1656535060,
			balance: 99699.28
		},
		{
			unixtime: 1656535120,
			balance: 99699.84
		},
		{
			unixtime: 1656535180,
			balance: 99700
		},
		{
			unixtime: 1656535240,
			balance: 99703.1
		},
		{
			unixtime: 1656535300,
			balance: 99703
		},
		{
			unixtime: 1656535360,
			balance: 99703.3
		},
		{
			unixtime: 1656535420,
			balance: 99703.5
		},
		{
			unixtime: 1656535480,
			balance: 99703.86
		},
		{
			unixtime: 1656535540,
			balance: 99703.66
		},
		{
			unixtime: 1656535600,
			balance: 99703.66
		},
		{
			unixtime: 1656535660,
			balance: 99703.96
		},
		{
			unixtime: 1656535720,
			balance: 99704.6
		},
		{
			unixtime: 1656535780,
			balance: 99703.4
		},
		{
			unixtime: 1656535840,
			balance: 99710.24
		},
		{
			unixtime: 1656535900,
			balance: 99710.02
		},
		{
			unixtime: 1656535960,
			balance: 99702.6
		},
		{
			unixtime: 1656536020,
			balance: 99702.8
		},
		{
			unixtime: 1656536080,
			balance: 99702.82
		},
		{
			unixtime: 1656536140,
			balance: 99702.86
		},
		{
			unixtime: 1656536200,
			balance: 99702.56
		},
		{
			unixtime: 1656536260,
			balance: 99702.76
		},
		{
			unixtime: 1656536320,
			balance: 99702.86
		},
		{
			unixtime: 1656536380,
			balance: 99702.86
		},
		{
			unixtime: 1656536440,
			balance: 99703.86
		},
		{
			unixtime: 1656536500,
			balance: 99703.86
		},
		{
			unixtime: 1656536560,
			balance: 99703.36
		},
		{
			unixtime: 1656536620,
			balance: 99704.36
		},
		{
			unixtime: 1656536680,
			balance: 99704.18
		},
		{
			unixtime: 1656536740,
			balance: 99704.18
		},
		{
			unixtime: 1656536800,
			balance: 99704.18
		},
		{
			unixtime: 1656536860,
			balance: 99704.16
		},
		{
			unixtime: 1656536920,
			balance: 99703.36
		},
		{
			unixtime: 1656536980,
			balance: 99703.36
		},
		{
			unixtime: 1656537040,
			balance: 99703.66
		},
		{
			unixtime: 1656537100,
			balance: 99703.66
		},
		{
			unixtime: 1656537160,
			balance: 99703.36
		},
		{
			unixtime: 1656537220,
			balance: 99703.76
		},
		{
			unixtime: 1656537280,
			balance: 99703.36
		},
		{
			unixtime: 1656537340,
			balance: 99703.36
		},
		{
			unixtime: 1656537400,
			balance: 99703.36
		},
		{
			unixtime: 1656537460,
			balance: 99703.36
		},
		{
			unixtime: 1656537520,
			balance: 99703.36
		},
		{
			unixtime: 1656537580,
			balance: 99703.76
		},
		{
			unixtime: 1656537640,
			balance: 99703.36
		},
		{
			unixtime: 1656537700,
			balance: 99703.36
		},
		{
			unixtime: 1656537760,
			balance: 99703.18
		},
		{
			unixtime: 1656537820,
			balance: 99703.68
		},
		{
			unixtime: 1656537880,
			balance: 99703.68
		},
		{
			unixtime: 1656537940,
			balance: 99703.58
		},
		{
			unixtime: 1656538000,
			balance: 99703.58
		},
		{
			unixtime: 1656538060,
			balance: 99706.58
		},
		{
			unixtime: 1656538120,
			balance: 99706.78
		},
		{
			unixtime: 1656538180,
			balance: 99706.42
		},
		{
			unixtime: 1656538240,
			balance: 99706.36
		},
		{
			unixtime: 1656538300,
			balance: 99706.36
		},
		{
			unixtime: 1656538360,
			balance: 99706.96
		},
		{
			unixtime: 1656538420,
			balance: 99702.96
		},
		{
			unixtime: 1656538480,
			balance: 99701.96
		},
		{
			unixtime: 1656538540,
			balance: 99701.96
		},
		{
			unixtime: 1656538600,
			balance: 99701.4
		},
		{
			unixtime: 1656538660,
			balance: 99701.4
		},
		{
			unixtime: 1656538720,
			balance: 99701.4
		},
		{
			unixtime: 1656538780,
			balance: 99701
		},
		{
			unixtime: 1656538840,
			balance: 99701
		},
		{
			unixtime: 1656538900,
			balance: 99701
		},
		{
			unixtime: 1656538960,
			balance: 99700.16
		},
		{
			unixtime: 1656539020,
			balance: 99700.16
		},
		{
			unixtime: 1656539080,
			balance: 99699.96
		},
		{
			unixtime: 1656539140,
			balance: 99699.96
		},
		{
			unixtime: 1656539200,
			balance: 99699.9
		},
		{
			unixtime: 1656539260,
			balance: 99700.9
		},
		{
			unixtime: 1656539320,
			balance: 99700.56
		},
		{
			unixtime: 1656539380,
			balance: 99700.56
		},
		{
			unixtime: 1656539440,
			balance: 99700.86
		},
		{
			unixtime: 1656539500,
			balance: 99700.68
		},
		{
			unixtime: 1656539560,
			balance: 99700.66
		},
		{
			unixtime: 1656539620,
			balance: 99700.96
		},
		{
			unixtime: 1656539680,
			balance: 99704.6
		},
		{
			unixtime: 1656539740,
			balance: 99703.66
		},
		{
			unixtime: 1656539800,
			balance: 99705.18
		},
		{
			unixtime: 1656539860,
			balance: 99704.78
		},
		{
			unixtime: 1656539920,
			balance: 99704.78
		},
		{
			unixtime: 1656539980,
			balance: 99703.52
		},
		{
			unixtime: 1656540040,
			balance: 99703.92
		},
		{
			unixtime: 1656540100,
			balance: 99704.22
		},
		{
			unixtime: 1656540160,
			balance: 99704.22
		},
		{
			unixtime: 1656540220,
			balance: 99703.12
		},
		{
			unixtime: 1656540280,
			balance: 99703.26
		},
		{
			unixtime: 1656540340,
			balance: 99701.26
		},
		{
			unixtime: 1656540400,
			balance: 99701.2
		},
		{
			unixtime: 1656540460,
			balance: 99700.1
		},
		{
			unixtime: 1656540520,
			balance: 99700.7
		},
		{
			unixtime: 1656540580,
			balance: 99700.1
		},
		{
			unixtime: 1656540640,
			balance: 99699.36
		},
		{
			unixtime: 1656540700,
			balance: 99699.36
		},
		{
			unixtime: 1656540760,
			balance: 99699.76
		},
		{
			unixtime: 1656540820,
			balance: 99696.76
		},
		{
			unixtime: 1656540880,
			balance: 99696.76
		},
		{
			unixtime: 1656540940,
			balance: 99697.84
		},
		{
			unixtime: 1656541000,
			balance: 99698.84
		},
		{
			unixtime: 1656541060,
			balance: 99697.86
		},
		{
			unixtime: 1656541120,
			balance: 99698.16
		},
		{
			unixtime: 1656541180,
			balance: 99698.22
		},
		{
			unixtime: 1656541240,
			balance: 99698
		},
		{
			unixtime: 1656541300,
			balance: 99697.9
		},
		{
			unixtime: 1656541360,
			balance: 99698.5
		},
		{
			unixtime: 1656541420,
			balance: 99703.1
		},
		{
			unixtime: 1656541480,
			balance: 99703.3
		},
		{
			unixtime: 1656541540,
			balance: 99703.5
		},
		{
			unixtime: 1656541600,
			balance: 99703.8
		},
		{
			unixtime: 1656541660,
			balance: 99704.4
		},
		{
			unixtime: 1656541720,
			balance: 99703.74
		},
		{
			unixtime: 1656541780,
			balance: 99704.08
		},
		{
			unixtime: 1656541840,
			balance: 99704.18
		},
		{
			unixtime: 1656541900,
			balance: 99703.7
		},
		{
			unixtime: 1656541960,
			balance: 99703.44
		},
		{
			unixtime: 1656542020,
			balance: 99703.44
		},
		{
			unixtime: 1656542080,
			balance: 99702.94
		},
		{
			unixtime: 1656542140,
			balance: 99702.94
		},
		{
			unixtime: 1656542200,
			balance: 99702.44
		},
		{
			unixtime: 1656542260,
			balance: 99702.16
		},
		{
			unixtime: 1656542320,
			balance: 99703.16
		},
		{
			unixtime: 1656542380,
			balance: 99703.16
		},
		{
			unixtime: 1656542440,
			balance: 99701.18
		},
		{
			unixtime: 1656542500,
			balance: 99701.18
		},
		{
			unixtime: 1656542560,
			balance: 99702.64
		},
		{
			unixtime: 1656542620,
			balance: 99702.44
		},
		{
			unixtime: 1656542680,
			balance: 99702.44
		},
		{
			unixtime: 1656542740,
			balance: 99702.44
		},
		{
			unixtime: 1656542800,
			balance: 99702.44
		},
		{
			unixtime: 1656542860,
			balance: 99702.44
		},
		{
			unixtime: 1656542920,
			balance: 99702.4
		},
		{
			unixtime: 1656542980,
			balance: 99702
		},
		{
			unixtime: 1656543040,
			balance: 99701.9
		},
		{
			unixtime: 1656543100,
			balance: 99701.9
		},
		{
			unixtime: 1656543160,
			balance: 99701.66
		},
		{
			unixtime: 1656543220,
			balance: 99701.66
		},
		{
			unixtime: 1656543280,
			balance: 99699.74
		},
		{
			unixtime: 1656543340,
			balance: 99699.74
		},
		{
			unixtime: 1656543400,
			balance: 99699.74
		},
		{
			unixtime: 1656543460,
			balance: 99699.74
		},
		{
			unixtime: 1656543520,
			balance: 99699.74
		},
		{
			unixtime: 1656543580,
			balance: 99698.54
		},
		{
			unixtime: 1656543640,
			balance: 99699.54
		},
		{
			unixtime: 1656543700,
			balance: 99695.46
		},
		{
			unixtime: 1656543760,
			balance: 99694.34
		},
		{
			unixtime: 1656543820,
			balance: 99694.58
		},
		{
			unixtime: 1656543880,
			balance: 99693.78
		},
		{
			unixtime: 1656543940,
			balance: 99693.28
		},
		{
			unixtime: 1656544000,
			balance: 99693.48
		},
		{
			unixtime: 1656544060,
			balance: 99693.48
		},
		{
			unixtime: 1656544120,
			balance: 99693.98
		},
		{
			unixtime: 1656544180,
			balance: 99696.98
		},
		{
			unixtime: 1656544240,
			balance: 99697.78
		},
		{
			unixtime: 1656544300,
			balance: 99697.78
		},
		{
			unixtime: 1656544360,
			balance: 99698.48
		},
		{
			unixtime: 1656544420,
			balance: 99698.38
		},
		{
			unixtime: 1656544480,
			balance: 99698.38
		},
		{
			unixtime: 1656544540,
			balance: 99698.28
		},
		{
			unixtime: 1656544600,
			balance: 99698.28
		},
		{
			unixtime: 1656544660,
			balance: 99697.98
		},
		{
			unixtime: 1656544720,
			balance: 99697.98
		},
		{
			unixtime: 1656544780,
			balance: 99697.78
		},
		{
			unixtime: 1656544840,
			balance: 99697.78
		},
		{
			unixtime: 1656544900,
			balance: 99698.26
		},
		{
			unixtime: 1656544960,
			balance: 99696.86
		},
		{
			unixtime: 1656545020,
			balance: 99691.76
		},
		{
			unixtime: 1656545080,
			balance: 99690.16
		},
		{
			unixtime: 1656545140,
			balance: 99690.36
		},
		{
			unixtime: 1656545200,
			balance: 99692.46
		},
		{
			unixtime: 1656545260,
			balance: 99692.66
		},
		{
			unixtime: 1656545320,
			balance: 99691.96
		},
		{
			unixtime: 1656545380,
			balance: 99692.16
		},
		{
			unixtime: 1656545440,
			balance: 99690.96
		},
		{
			unixtime: 1656545500,
			balance: 99690.36
		},
		{
			unixtime: 1656545560,
			balance: 99691.46
		},
		{
			unixtime: 1656545620,
			balance: 99688.76
		},
		{
			unixtime: 1656545680,
			balance: 99688.96
		},
		{
			unixtime: 1656545740,
			balance: 99687.96
		},
		{
			unixtime: 1656545800,
			balance: 99687.38
		},
		{
			unixtime: 1656545860,
			balance: 99687.38
		},
		{
			unixtime: 1656545920,
			balance: 99688.92
		},
		{
			unixtime: 1656545980,
			balance: 99687.66
		},
		{
			unixtime: 1656546040,
			balance: 99688.32
		},
		{
			unixtime: 1656546100,
			balance: 99687.82
		},
		{
			unixtime: 1656546160,
			balance: 99685.28
		},
		{
			unixtime: 1656546220,
			balance: 99685.28
		},
		{
			unixtime: 1656546280,
			balance: 99687.52
		},
		{
			unixtime: 1656546340,
			balance: 99687.68
		},
		{
			unixtime: 1656546400,
			balance: 99687.72
		},
		{
			unixtime: 1656546460,
			balance: 99686.36
		},
		{
			unixtime: 1656546520,
			balance: 99686.36
		},
		{
			unixtime: 1656546580,
			balance: 99686.78
		},
		{
			unixtime: 1656546640,
			balance: 99687.46
		},
		{
			unixtime: 1656546700,
			balance: 99687.96
		},
		{
			unixtime: 1656546760,
			balance: 99686.76
		},
		{
			unixtime: 1656546820,
			balance: 99685.78
		},
		{
			unixtime: 1656546880,
			balance: 99691.62
		},
		{
			unixtime: 1656546940,
			balance: 99691.42
		},
		{
			unixtime: 1656547000,
			balance: 99690.46
		},
		{
			unixtime: 1656547060,
			balance: 99690.9
		},
		{
			unixtime: 1656547120,
			balance: 99691.14
		},
		{
			unixtime: 1656547180,
			balance: 99685.76
		},
		{
			unixtime: 1656547240,
			balance: 99688.96
		},
		{
			unixtime: 1656547300,
			balance: 99688.96
		},
		{
			unixtime: 1656547360,
			balance: 99688.96
		},
		{
			unixtime: 1656547420,
			balance: 99688.96
		},
		{
			unixtime: 1656547480,
			balance: 99688.96
		},
		{
			unixtime: 1656547540,
			balance: 99688.96
		},
		{
			unixtime: 1656547600,
			balance: 99688.96
		},
		{
			unixtime: 1656547660,
			balance: 99688.96
		},
		{
			unixtime: 1656547720,
			balance: 99688.96
		},
		{
			unixtime: 1656547780,
			balance: 99688.96
		},
		{
			unixtime: 1656547840,
			balance: 99688.96
		},
		{
			unixtime: 1656547900,
			balance: 99688.96
		},
		{
			unixtime: 1656547960,
			balance: 99688.96
		},
		{
			unixtime: 1656548020,
			balance: 99688.96
		},
		{
			unixtime: 1656548080,
			balance: 99688.96
		},
		{
			unixtime: 1656548140,
			balance: 99688.96
		},
		{
			unixtime: 1656548200,
			balance: 99688.96
		},
		{
			unixtime: 1656548260,
			balance: 99688.96
		},
		{
			unixtime: 1656548320,
			balance: 99688.96
		},
		{
			unixtime: 1656548380,
			balance: 99688.96
		},
		{
			unixtime: 1656548440,
			balance: 99688.96
		},
		{
			unixtime: 1656548500,
			balance: 99688.96
		},
		{
			unixtime: 1656548560,
			balance: 99688.96
		},
		{
			unixtime: 1656548620,
			balance: 99688.96
		},
		{
			unixtime: 1656548680,
			balance: 99688.96
		},
		{
			unixtime: 1656548740,
			balance: 99688.96
		},
		{
			unixtime: 1656548800,
			balance: 99688.96
		},
		{
			unixtime: 1656548860,
			balance: 99688.96
		},
		{
			unixtime: 1656548920,
			balance: 99688.96
		},
		{
			unixtime: 1656548980,
			balance: 99688.96
		},
		{
			unixtime: 1656549040,
			balance: 99688.96
		},
		{
			unixtime: 1656549100,
			balance: 99688.96
		},
		{
			unixtime: 1656549160,
			balance: 99688.96
		},
		{
			unixtime: 1656549220,
			balance: 99688.96
		},
		{
			unixtime: 1656549280,
			balance: 99688.96
		},
		{
			unixtime: 1656549340,
			balance: 99688.96
		},
		{
			unixtime: 1656549400,
			balance: 99688.96
		},
		{
			unixtime: 1656549460,
			balance: 99688.96
		},
		{
			unixtime: 1656549520,
			balance: 99688.96
		},
		{
			unixtime: 1656549580,
			balance: 99688.96
		},
		{
			unixtime: 1656549640,
			balance: 99688.96
		},
		{
			unixtime: 1656549700,
			balance: 99688.96
		},
		{
			unixtime: 1656549760,
			balance: 99688.96
		},
		{
			unixtime: 1656549820,
			balance: 99688.96
		},
		{
			unixtime: 1656549880,
			balance: 99688.96
		},
		{
			unixtime: 1656549940,
			balance: 99688.96
		},
		{
			unixtime: 1656550000,
			balance: 99688.96
		},
		{
			unixtime: 1656550060,
			balance: 99688.96
		},
		{
			unixtime: 1656550120,
			balance: 99688.96
		},
		{
			unixtime: 1656550180,
			balance: 99688.96
		},
		{
			unixtime: 1656550240,
			balance: 99688.96
		},
		{
			unixtime: 1656550300,
			balance: 99688.96
		},
		{
			unixtime: 1656550360,
			balance: 99688.96
		},
		{
			unixtime: 1656550420,
			balance: 99688.96
		},
		{
			unixtime: 1656550480,
			balance: 99688.96
		},
		{
			unixtime: 1656550540,
			balance: 99688.96
		},
		{
			unixtime: 1656550600,
			balance: 99688.96
		},
		{
			unixtime: 1656550660,
			balance: 99688.96
		},
		{
			unixtime: 1656550720,
			balance: 99688.96
		},
		{
			unixtime: 1656550780,
			balance: 99688.96
		},
		{
			unixtime: 1656550840,
			balance: 99688.96
		},
		{
			unixtime: 1656550900,
			balance: 99688.96
		},
		{
			unixtime: 1656550960,
			balance: 99688.96
		},
		{
			unixtime: 1656551020,
			balance: 99688.96
		},
		{
			unixtime: 1656551080,
			balance: 99688.96
		},
		{
			unixtime: 1656551140,
			balance: 99688.96
		},
		{
			unixtime: 1656551200,
			balance: 99688.96
		},
		{
			unixtime: 1656551260,
			balance: 99688.96
		},
		{
			unixtime: 1656551320,
			balance: 99688.96
		},
		{
			unixtime: 1656551380,
			balance: 99688.96
		},
		{
			unixtime: 1656551440,
			balance: 99688.96
		},
		{
			unixtime: 1656551500,
			balance: 99688.96
		},
		{
			unixtime: 1656551560,
			balance: 99688.96
		},
		{
			unixtime: 1656551620,
			balance: 99688.96
		},
		{
			unixtime: 1656551680,
			balance: 99688.96
		},
		{
			unixtime: 1656551740,
			balance: 99688.96
		},
		{
			unixtime: 1656551800,
			balance: 99688.96
		},
		{
			unixtime: 1656551860,
			balance: 99688.96
		},
		{
			unixtime: 1656551920,
			balance: 99688.96
		},
		{
			unixtime: 1656551980,
			balance: 99688.96
		},
		{
			unixtime: 1656552040,
			balance: 99688.96
		},
		{
			unixtime: 1656552100,
			balance: 99688.96
		},
		{
			unixtime: 1656552160,
			balance: 99688.96
		},
		{
			unixtime: 1656552220,
			balance: 99688.96
		},
		{
			unixtime: 1656552280,
			balance: 99688.96
		},
		{
			unixtime: 1656552340,
			balance: 99688.96
		},
		{
			unixtime: 1656552400,
			balance: 99688.96
		},
		{
			unixtime: 1656552460,
			balance: 99688.96
		},
		{
			unixtime: 1656552520,
			balance: 99688.96
		},
		{
			unixtime: 1656552580,
			balance: 99688.96
		},
		{
			unixtime: 1656552640,
			balance: 99688.96
		},
		{
			unixtime: 1656552700,
			balance: 99688.96
		},
		{
			unixtime: 1656552760,
			balance: 99688.96
		},
		{
			unixtime: 1656552820,
			balance: 99688.96
		},
		{
			unixtime: 1656552880,
			balance: 99688.96
		},
		{
			unixtime: 1656552940,
			balance: 99688.96
		},
		{
			unixtime: 1656553000,
			balance: 99688.96
		},
		{
			unixtime: 1656553060,
			balance: 99688.96
		},
		{
			unixtime: 1656553120,
			balance: 99688.96
		},
		{
			unixtime: 1656553180,
			balance: 99688.96
		},
		{
			unixtime: 1656553240,
			balance: 99688.96
		},
		{
			unixtime: 1656553300,
			balance: 99688.96
		},
		{
			unixtime: 1656553360,
			balance: 99688.96
		},
		{
			unixtime: 1656553420,
			balance: 99688.96
		},
		{
			unixtime: 1656553480,
			balance: 99688.96
		},
		{
			unixtime: 1656553540,
			balance: 99688.96
		},
		{
			unixtime: 1656553600,
			balance: 99688.96
		},
		{
			unixtime: 1656553660,
			balance: 99688.96
		},
		{
			unixtime: 1656553720,
			balance: 99688.96
		},
		{
			unixtime: 1656553780,
			balance: 99688.96
		},
		{
			unixtime: 1656553840,
			balance: 99688.96
		},
		{
			unixtime: 1656553900,
			balance: 99688.96
		},
		{
			unixtime: 1656553960,
			balance: 99688.96
		},
		{
			unixtime: 1656554020,
			balance: 99688.96
		},
		{
			unixtime: 1656554080,
			balance: 99688.96
		},
		{
			unixtime: 1656554140,
			balance: 99688.96
		},
		{
			unixtime: 1656554200,
			balance: 99688.96
		},
		{
			unixtime: 1656554260,
			balance: 99688.96
		},
		{
			unixtime: 1656554320,
			balance: 99688.96
		},
		{
			unixtime: 1656554380,
			balance: 99688.96
		},
		{
			unixtime: 1656554440,
			balance: 99688.96
		},
		{
			unixtime: 1656554500,
			balance: 99688.96
		},
		{
			unixtime: 1656554560,
			balance: 99688.96
		},
		{
			unixtime: 1656554620,
			balance: 99688.96
		},
		{
			unixtime: 1656554680,
			balance: 99688.96
		},
		{
			unixtime: 1656554740,
			balance: 99688.96
		},
		{
			unixtime: 1656554800,
			balance: 99688.96
		},
		{
			unixtime: 1656554860,
			balance: 99688.96
		},
		{
			unixtime: 1656554920,
			balance: 99688.96
		},
		{
			unixtime: 1656554980,
			balance: 99688.96
		},
		{
			unixtime: 1656555040,
			balance: 99688.96
		},
		{
			unixtime: 1656555100,
			balance: 99688.96
		},
		{
			unixtime: 1656555160,
			balance: 99688.96
		},
		{
			unixtime: 1656555220,
			balance: 99688.96
		},
		{
			unixtime: 1656555280,
			balance: 99688.96
		},
		{
			unixtime: 1656555340,
			balance: 99688.96
		},
		{
			unixtime: 1656555400,
			balance: 99688.96
		},
		{
			unixtime: 1656555460,
			balance: 99688.96
		},
		{
			unixtime: 1656555520,
			balance: 99688.96
		},
		{
			unixtime: 1656555580,
			balance: 99688.96
		},
		{
			unixtime: 1656555640,
			balance: 99688.96
		},
		{
			unixtime: 1656555700,
			balance: 99688.96
		},
		{
			unixtime: 1656555760,
			balance: 99688.96
		},
		{
			unixtime: 1656555820,
			balance: 99688.96
		},
		{
			unixtime: 1656555880,
			balance: 99688.96
		},
		{
			unixtime: 1656555940,
			balance: 99688.96
		},
		{
			unixtime: 1656556000,
			balance: 99688.96
		},
		{
			unixtime: 1656556060,
			balance: 99688.96
		},
		{
			unixtime: 1656556120,
			balance: 99688.96
		},
		{
			unixtime: 1656556180,
			balance: 99688.96
		},
		{
			unixtime: 1656556240,
			balance: 99688.96
		},
		{
			unixtime: 1656556300,
			balance: 99688.96
		},
		{
			unixtime: 1656556360,
			balance: 99688.96
		},
		{
			unixtime: 1656556420,
			balance: 99688.96
		},
		{
			unixtime: 1656556480,
			balance: 99688.96
		},
		{
			unixtime: 1656556540,
			balance: 99688.96
		},
		{
			unixtime: 1656556600,
			balance: 99688.96
		},
		{
			unixtime: 1656556660,
			balance: 99688.96
		},
		{
			unixtime: 1656556720,
			balance: 99688.96
		},
		{
			unixtime: 1656556780,
			balance: 99688.96
		},
		{
			unixtime: 1656556840,
			balance: 99688.96
		},
		{
			unixtime: 1656556900,
			balance: 99688.96
		},
		{
			unixtime: 1656556960,
			balance: 99688.96
		},
		{
			unixtime: 1656557020,
			balance: 99688.96
		},
		{
			unixtime: 1656557080,
			balance: 99688.96
		},
		{
			unixtime: 1656557140,
			balance: 99688.96
		},
		{
			unixtime: 1656557200,
			balance: 99688.96
		},
		{
			unixtime: 1656557260,
			balance: 99688.96
		},
		{
			unixtime: 1656557320,
			balance: 99688.96
		},
		{
			unixtime: 1656557380,
			balance: 99688.96
		},
		{
			unixtime: 1656557440,
			balance: 99688.96
		},
		{
			unixtime: 1656557500,
			balance: 99688.96
		},
		{
			unixtime: 1656557560,
			balance: 99688.96
		},
		{
			unixtime: 1656557620,
			balance: 99688.96
		},
		{
			unixtime: 1656557680,
			balance: 99688.96
		},
		{
			unixtime: 1656557740,
			balance: 99688.96
		},
		{
			unixtime: 1656557800,
			balance: 99688.96
		},
		{
			unixtime: 1656557860,
			balance: 99688.96
		},
		{
			unixtime: 1656557920,
			balance: 99688.96
		},
		{
			unixtime: 1656557980,
			balance: 99688.96
		},
		{
			unixtime: 1656558040,
			balance: 99688.96
		},
		{
			unixtime: 1656558100,
			balance: 99688.96
		},
		{
			unixtime: 1656558160,
			balance: 99688.96
		},
		{
			unixtime: 1656558220,
			balance: 99688.96
		},
		{
			unixtime: 1656558280,
			balance: 99688.96
		},
		{
			unixtime: 1656558340,
			balance: 99688.96
		},
		{
			unixtime: 1656558400,
			balance: 99688.96
		},
		{
			unixtime: 1656558460,
			balance: 99688.96
		},
		{
			unixtime: 1656558520,
			balance: 99688.96
		},
		{
			unixtime: 1656558580,
			balance: 99688.96
		},
		{
			unixtime: 1656558640,
			balance: 99688.96
		},
		{
			unixtime: 1656558700,
			balance: 99688.96
		},
		{
			unixtime: 1656558760,
			balance: 99688.96
		},
		{
			unixtime: 1656558820,
			balance: 99688.96
		},
		{
			unixtime: 1656558880,
			balance: 99688.96
		},
		{
			unixtime: 1656558940,
			balance: 99688.96
		},
		{
			unixtime: 1656559000,
			balance: 99688.96
		},
		{
			unixtime: 1656559060,
			balance: 99688.96
		},
		{
			unixtime: 1656559120,
			balance: 99688.96
		},
		{
			unixtime: 1656559180,
			balance: 99688.96
		},
		{
			unixtime: 1656559240,
			balance: 99688.96
		},
		{
			unixtime: 1656559300,
			balance: 99688.96
		},
		{
			unixtime: 1656559360,
			balance: 99688.96
		},
		{
			unixtime: 1656559420,
			balance: 99688.96
		},
		{
			unixtime: 1656559480,
			balance: 99688.96
		},
		{
			unixtime: 1656559540,
			balance: 99688.96
		},
		{
			unixtime: 1656559600,
			balance: 99688.96
		},
		{
			unixtime: 1656559660,
			balance: 99688.96
		},
		{
			unixtime: 1656559720,
			balance: 99688.96
		},
		{
			unixtime: 1656559780,
			balance: 99688.96
		},
		{
			unixtime: 1656559840,
			balance: 99688.96
		},
		{
			unixtime: 1656559900,
			balance: 99688.96
		},
		{
			unixtime: 1656559960,
			balance: 99688.96
		},
		{
			unixtime: 1656560020,
			balance: 99688.96
		},
		{
			unixtime: 1656560080,
			balance: 99688.96
		},
		{
			unixtime: 1656560140,
			balance: 99688.96
		},
		{
			unixtime: 1656560200,
			balance: 99688.96
		},
		{
			unixtime: 1656560260,
			balance: 99688.96
		},
		{
			unixtime: 1656560320,
			balance: 99688.96
		},
		{
			unixtime: 1656560380,
			balance: 99688.96
		},
		{
			unixtime: 1656560440,
			balance: 99688.96
		},
		{
			unixtime: 1656560500,
			balance: 99688.96
		},
		{
			unixtime: 1656560560,
			balance: 99688.96
		},
		{
			unixtime: 1656560620,
			balance: 99688.96
		},
		{
			unixtime: 1656560680,
			balance: 99688.96
		},
		{
			unixtime: 1656560740,
			balance: 99688.96
		},
		{
			unixtime: 1656560800,
			balance: 99688.96
		},
		{
			unixtime: 1656560860,
			balance: 99688.96
		},
		{
			unixtime: 1656560920,
			balance: 99688.96
		},
		{
			unixtime: 1656560980,
			balance: 99688.96
		},
		{
			unixtime: 1656561040,
			balance: 99688.96
		},
		{
			unixtime: 1656561100,
			balance: 99688.96
		},
		{
			unixtime: 1656561160,
			balance: 99688.96
		},
		{
			unixtime: 1656561220,
			balance: 99688.96
		},
		{
			unixtime: 1656561280,
			balance: 99688.96
		},
		{
			unixtime: 1656561340,
			balance: 99688.96
		},
		{
			unixtime: 1656561400,
			balance: 99688.96
		},
		{
			unixtime: 1656561460,
			balance: 99688.96
		},
		{
			unixtime: 1656561520,
			balance: 99688.96
		},
		{
			unixtime: 1656561580,
			balance: 99688.96
		},
		{
			unixtime: 1656561640,
			balance: 99688.96
		},
		{
			unixtime: 1656561700,
			balance: 99688.96
		},
		{
			unixtime: 1656561760,
			balance: 99688.96
		},
		{
			unixtime: 1656561820,
			balance: 99688.96
		},
		{
			unixtime: 1656561880,
			balance: 99688.96
		},
		{
			unixtime: 1656561940,
			balance: 99688.96
		},
		{
			unixtime: 1656562000,
			balance: 99688.96
		},
		{
			unixtime: 1656562060,
			balance: 99688.96
		},
		{
			unixtime: 1656562120,
			balance: 99688.96
		},
		{
			unixtime: 1656562180,
			balance: 99688.96
		},
		{
			unixtime: 1656562240,
			balance: 99688.96
		},
		{
			unixtime: 1656562300,
			balance: 99688.96
		},
		{
			unixtime: 1656562360,
			balance: 99688.96
		},
		{
			unixtime: 1656562420,
			balance: 99688.96
		},
		{
			unixtime: 1656562480,
			balance: 99688.96
		},
		{
			unixtime: 1656562540,
			balance: 99688.96
		},
		{
			unixtime: 1656562600,
			balance: 99688.96
		},
		{
			unixtime: 1656562660,
			balance: 99688.96
		},
		{
			unixtime: 1656562720,
			balance: 99688.96
		},
		{
			unixtime: 1656562780,
			balance: 99688.96
		},
		{
			unixtime: 1656562840,
			balance: 99688.96
		},
		{
			unixtime: 1656562900,
			balance: 99688.96
		},
		{
			unixtime: 1656562960,
			balance: 99688.96
		},
		{
			unixtime: 1656563020,
			balance: 99688.96
		},
		{
			unixtime: 1656563080,
			balance: 99688.96
		},
		{
			unixtime: 1656563140,
			balance: 99688.96
		},
		{
			unixtime: 1656563200,
			balance: 99688.96
		},
		{
			unixtime: 1656563260,
			balance: 99688.96
		},
		{
			unixtime: 1656563320,
			balance: 99688.96
		},
		{
			unixtime: 1656563380,
			balance: 99688.96
		},
		{
			unixtime: 1656563440,
			balance: 99688.96
		},
		{
			unixtime: 1656563500,
			balance: 99688.96
		},
		{
			unixtime: 1656563560,
			balance: 99688.96
		},
		{
			unixtime: 1656563620,
			balance: 99688.96
		},
		{
			unixtime: 1656563680,
			balance: 99688.96
		},
		{
			unixtime: 1656563740,
			balance: 99688.96
		},
		{
			unixtime: 1656563800,
			balance: 99688.96
		},
		{
			unixtime: 1656563860,
			balance: 99688.96
		},
		{
			unixtime: 1656563920,
			balance: 99688.96
		},
		{
			unixtime: 1656563980,
			balance: 99688.96
		},
		{
			unixtime: 1656564040,
			balance: 99688.96
		},
		{
			unixtime: 1656564100,
			balance: 99688.96
		},
		{
			unixtime: 1656564160,
			balance: 99688.96
		},
		{
			unixtime: 1656564220,
			balance: 99688.96
		},
		{
			unixtime: 1656564280,
			balance: 99688.96
		},
		{
			unixtime: 1656564340,
			balance: 99688.96
		},
		{
			unixtime: 1656564400,
			balance: 99688.96
		},
		{
			unixtime: 1656564460,
			balance: 99688.96
		},
		{
			unixtime: 1656564520,
			balance: 99688.96
		},
		{
			unixtime: 1656564580,
			balance: 99688.96
		},
		{
			unixtime: 1656564640,
			balance: 99688.96
		},
		{
			unixtime: 1656564700,
			balance: 99688.96
		},
		{
			unixtime: 1656564760,
			balance: 99688.96
		},
		{
			unixtime: 1656564820,
			balance: 99688.96
		},
		{
			unixtime: 1656564880,
			balance: 99688.96
		},
		{
			unixtime: 1656564940,
			balance: 99688.96
		},
		{
			unixtime: 1656565000,
			balance: 99688.96
		},
		{
			unixtime: 1656565060,
			balance: 99688.96
		},
		{
			unixtime: 1656565120,
			balance: 99688.96
		},
		{
			unixtime: 1656565180,
			balance: 99688.96
		},
		{
			unixtime: 1656565240,
			balance: 99688.96
		},
		{
			unixtime: 1656565300,
			balance: 99688.96
		},
		{
			unixtime: 1656565360,
			balance: 99688.96
		},
		{
			unixtime: 1656565420,
			balance: 99688.96
		},
		{
			unixtime: 1656565480,
			balance: 99688.96
		},
		{
			unixtime: 1656565540,
			balance: 99688.96
		},
		{
			unixtime: 1656565600,
			balance: 99688.96
		},
		{
			unixtime: 1656565660,
			balance: 99688.96
		},
		{
			unixtime: 1656565720,
			balance: 99688.96
		},
		{
			unixtime: 1656565780,
			balance: 99688.96
		},
		{
			unixtime: 1656565840,
			balance: 99688.96
		},
		{
			unixtime: 1656565900,
			balance: 99688.96
		},
		{
			unixtime: 1656565960,
			balance: 99688.96
		},
		{
			unixtime: 1656566020,
			balance: 99688.96
		},
		{
			unixtime: 1656566080,
			balance: 99688.96
		},
		{
			unixtime: 1656566140,
			balance: 99688.96
		},
		{
			unixtime: 1656566200,
			balance: 99688.96
		},
		{
			unixtime: 1656566260,
			balance: 99688.96
		},
		{
			unixtime: 1656566320,
			balance: 99688.96
		},
		{
			unixtime: 1656566380,
			balance: 99688.96
		},
		{
			unixtime: 1656566440,
			balance: 99688.96
		},
		{
			unixtime: 1656566500,
			balance: 99688.96
		},
		{
			unixtime: 1656566560,
			balance: 99688.96
		},
		{
			unixtime: 1656566620,
			balance: 99688.96
		},
		{
			unixtime: 1656566680,
			balance: 99688.96
		},
		{
			unixtime: 1656566740,
			balance: 99688.96
		},
		{
			unixtime: 1656566800,
			balance: 99688.96
		},
		{
			unixtime: 1656566860,
			balance: 99688.96
		},
		{
			unixtime: 1656566920,
			balance: 99688.96
		},
		{
			unixtime: 1656566980,
			balance: 99688.96
		},
		{
			unixtime: 1656567040,
			balance: 99688.96
		},
		{
			unixtime: 1656567100,
			balance: 99688.96
		},
		{
			unixtime: 1656567160,
			balance: 99688.96
		},
		{
			unixtime: 1656567220,
			balance: 99688.96
		},
		{
			unixtime: 1656567280,
			balance: 99688.96
		},
		{
			unixtime: 1656567340,
			balance: 99688.96
		},
		{
			unixtime: 1656567400,
			balance: 99688.96
		},
		{
			unixtime: 1656567460,
			balance: 99688.96
		},
		{
			unixtime: 1656567520,
			balance: 99688.96
		},
		{
			unixtime: 1656567580,
			balance: 99688.96
		},
		{
			unixtime: 1656567640,
			balance: 99688.96
		},
		{
			unixtime: 1656567700,
			balance: 99688.96
		},
		{
			unixtime: 1656567760,
			balance: 99688.96
		},
		{
			unixtime: 1656567820,
			balance: 99688.96
		},
		{
			unixtime: 1656567880,
			balance: 99688.96
		},
		{
			unixtime: 1656567940,
			balance: 99688.96
		},
		{
			unixtime: 1656568000,
			balance: 99688.96
		},
		{
			unixtime: 1656568060,
			balance: 99688.96
		},
		{
			unixtime: 1656568120,
			balance: 99688.96
		},
		{
			unixtime: 1656568180,
			balance: 99688.96
		},
		{
			unixtime: 1656568240,
			balance: 99688.96
		},
		{
			unixtime: 1656568300,
			balance: 99688.96
		},
		{
			unixtime: 1656568360,
			balance: 99688.96
		},
		{
			unixtime: 1656568420,
			balance: 99688.96
		},
		{
			unixtime: 1656568480,
			balance: 99688.96
		},
		{
			unixtime: 1656568540,
			balance: 99688.96
		},
		{
			unixtime: 1656568600,
			balance: 99688.96
		},
		{
			unixtime: 1656568660,
			balance: 99688.96
		},
		{
			unixtime: 1656568720,
			balance: 99688.96
		},
		{
			unixtime: 1656568780,
			balance: 99688.96
		},
		{
			unixtime: 1656568840,
			balance: 99688.96
		},
		{
			unixtime: 1656568900,
			balance: 99688.96
		},
		{
			unixtime: 1656568960,
			balance: 99688.96
		},
		{
			unixtime: 1656569020,
			balance: 99688.96
		},
		{
			unixtime: 1656569080,
			balance: 99688.96
		},
		{
			unixtime: 1656569140,
			balance: 99688.96
		},
		{
			unixtime: 1656569200,
			balance: 99688.96
		},
		{
			unixtime: 1656569260,
			balance: 99688.96
		},
		{
			unixtime: 1656569320,
			balance: 99688.96
		},
		{
			unixtime: 1656569380,
			balance: 99688.96
		},
		{
			unixtime: 1656569440,
			balance: 99688.96
		},
		{
			unixtime: 1656569500,
			balance: 99688.96
		},
		{
			unixtime: 1656569560,
			balance: 99688.96
		},
		{
			unixtime: 1656569620,
			balance: 99688.96
		},
		{
			unixtime: 1656569680,
			balance: 99688.96
		},
		{
			unixtime: 1656569740,
			balance: 99688.96
		},
		{
			unixtime: 1656569800,
			balance: 99688.96
		},
		{
			unixtime: 1656569860,
			balance: 99688.96
		},
		{
			unixtime: 1656569920,
			balance: 99688.96
		},
		{
			unixtime: 1656569980,
			balance: 99688.96
		},
		{
			unixtime: 1656570040,
			balance: 99688.96
		},
		{
			unixtime: 1656570100,
			balance: 99688.96
		},
		{
			unixtime: 1656570160,
			balance: 99688.96
		},
		{
			unixtime: 1656570220,
			balance: 99688.96
		},
		{
			unixtime: 1656570280,
			balance: 99688.96
		},
		{
			unixtime: 1656570340,
			balance: 99688.96
		},
		{
			unixtime: 1656570400,
			balance: 99688.96
		},
		{
			unixtime: 1656570460,
			balance: 99688.96
		},
		{
			unixtime: 1656570520,
			balance: 99688.96
		},
		{
			unixtime: 1656570580,
			balance: 99688.96
		},
		{
			unixtime: 1656570640,
			balance: 99688.96
		},
		{
			unixtime: 1656570700,
			balance: 99688.96
		},
		{
			unixtime: 1656570760,
			balance: 99688.96
		},
		{
			unixtime: 1656570820,
			balance: 99688.96
		},
		{
			unixtime: 1656570880,
			balance: 99688.96
		},
		{
			unixtime: 1656570940,
			balance: 99688.96
		},
		{
			unixtime: 1656571000,
			balance: 99688.96
		},
		{
			unixtime: 1656571060,
			balance: 99688.96
		},
		{
			unixtime: 1656571120,
			balance: 99688.96
		},
		{
			unixtime: 1656571180,
			balance: 99688.96
		},
		{
			unixtime: 1656571240,
			balance: 99688.96
		},
		{
			unixtime: 1656571300,
			balance: 99688.96
		},
		{
			unixtime: 1656571360,
			balance: 99688.96
		},
		{
			unixtime: 1656571420,
			balance: 99688.96
		},
		{
			unixtime: 1656571480,
			balance: 99688.96
		},
		{
			unixtime: 1656571540,
			balance: 99688.96
		},
		{
			unixtime: 1656571600,
			balance: 99688.96
		},
		{
			unixtime: 1656571660,
			balance: 99688.96
		},
		{
			unixtime: 1656571720,
			balance: 99688.96
		},
		{
			unixtime: 1656571780,
			balance: 99688.96
		},
		{
			unixtime: 1656571840,
			balance: 99688.96
		},
		{
			unixtime: 1656571900,
			balance: 99688.96
		},
		{
			unixtime: 1656571960,
			balance: 99688.96
		},
		{
			unixtime: 1656572020,
			balance: 99688.96
		},
		{
			unixtime: 1656572080,
			balance: 99688.96
		},
		{
			unixtime: 1656572140,
			balance: 99688.96
		},
		{
			unixtime: 1656572200,
			balance: 99688.96
		},
		{
			unixtime: 1656572260,
			balance: 99688.96
		},
		{
			unixtime: 1656572320,
			balance: 99688.96
		},
		{
			unixtime: 1656572380,
			balance: 99688.96
		},
		{
			unixtime: 1656572440,
			balance: 99688.96
		},
		{
			unixtime: 1656572500,
			balance: 99688.96
		},
		{
			unixtime: 1656572560,
			balance: 99688.96
		},
		{
			unixtime: 1656572620,
			balance: 99688.96
		},
		{
			unixtime: 1656572680,
			balance: 99688.96
		},
		{
			unixtime: 1656572740,
			balance: 99688.96
		},
		{
			unixtime: 1656572800,
			balance: 99688.96
		},
		{
			unixtime: 1656572860,
			balance: 99688.96
		},
		{
			unixtime: 1656572920,
			balance: 99688.96
		},
		{
			unixtime: 1656572980,
			balance: 99688.96
		},
		{
			unixtime: 1656573040,
			balance: 99688.96
		},
		{
			unixtime: 1656573100,
			balance: 99688.96
		},
		{
			unixtime: 1656573160,
			balance: 99688.96
		},
		{
			unixtime: 1656573220,
			balance: 99688.96
		},
		{
			unixtime: 1656573280,
			balance: 99688.96
		},
		{
			unixtime: 1656573340,
			balance: 99688.96
		},
		{
			unixtime: 1656573400,
			balance: 99688.96
		},
		{
			unixtime: 1656573460,
			balance: 99688.96
		},
		{
			unixtime: 1656573520,
			balance: 99688.96
		},
		{
			unixtime: 1656573580,
			balance: 99688.96
		},
		{
			unixtime: 1656573640,
			balance: 99688.96
		},
		{
			unixtime: 1656573700,
			balance: 99688.96
		},
		{
			unixtime: 1656573760,
			balance: 99688.96
		},
		{
			unixtime: 1656573820,
			balance: 99688.96
		},
		{
			unixtime: 1656573880,
			balance: 99688.96
		},
		{
			unixtime: 1656573940,
			balance: 99688.96
		},
		{
			unixtime: 1656574000,
			balance: 99688.96
		},
		{
			unixtime: 1656574060,
			balance: 99688.96
		},
		{
			unixtime: 1656574120,
			balance: 99688.96
		},
		{
			unixtime: 1656574180,
			balance: 99688.96
		},
		{
			unixtime: 1656574240,
			balance: 99688.96
		},
		{
			unixtime: 1656574300,
			balance: 99688.96
		},
		{
			unixtime: 1656574360,
			balance: 99688.96
		},
		{
			unixtime: 1656574420,
			balance: 99688.96
		},
		{
			unixtime: 1656574480,
			balance: 99688.96
		},
		{
			unixtime: 1656574540,
			balance: 99688.96
		},
		{
			unixtime: 1656574600,
			balance: 99688.96
		},
		{
			unixtime: 1656574660,
			balance: 99688.96
		},
		{
			unixtime: 1656574720,
			balance: 99688.96
		},
		{
			unixtime: 1656574780,
			balance: 99688.96
		},
		{
			unixtime: 1656574840,
			balance: 99688.96
		},
		{
			unixtime: 1656574900,
			balance: 99688.96
		},
		{
			unixtime: 1656574960,
			balance: 99688.96
		},
		{
			unixtime: 1656575020,
			balance: 99688.96
		},
		{
			unixtime: 1656575080,
			balance: 99688.96
		},
		{
			unixtime: 1656575140,
			balance: 99688.96
		},
		{
			unixtime: 1656575200,
			balance: 99688.96
		},
		{
			unixtime: 1656575260,
			balance: 99688.96
		},
		{
			unixtime: 1656575320,
			balance: 99688.96
		},
		{
			unixtime: 1656575380,
			balance: 99688.96
		},
		{
			unixtime: 1656575440,
			balance: 99688.96
		},
		{
			unixtime: 1656575500,
			balance: 99688.96
		},
		{
			unixtime: 1656575560,
			balance: 99688.96
		},
		{
			unixtime: 1656575620,
			balance: 99688.96
		},
		{
			unixtime: 1656575680,
			balance: 99688.96
		},
		{
			unixtime: 1656575740,
			balance: 99688.96
		},
		{
			unixtime: 1656575800,
			balance: 99688.96
		},
		{
			unixtime: 1656575860,
			balance: 99688.96
		},
		{
			unixtime: 1656575920,
			balance: 99688.96
		},
		{
			unixtime: 1656575980,
			balance: 99688.96
		},
		{
			unixtime: 1656576040,
			balance: 99594.06
		},
		{
			unixtime: 1656576100,
			balance: 99599.76
		},
		{
			unixtime: 1656576160,
			balance: 99600.46
		},
		{
			unixtime: 1656576220,
			balance: 99602.34
		},
		{
			unixtime: 1656576280,
			balance: 99603.82
		},
		{
			unixtime: 1656576340,
			balance: 99606.06
		},
		{
			unixtime: 1656576400,
			balance: 99606.46
		},
		{
			unixtime: 1656576460,
			balance: 99604.68
		},
		{
			unixtime: 1656576520,
			balance: 99604.68
		},
		{
			unixtime: 1656576580,
			balance: 99599.4
		},
		{
			unixtime: 1656576640,
			balance: 99600.3
		},
		{
			unixtime: 1656576700,
			balance: 99603.24
		},
		{
			unixtime: 1656576760,
			balance: 99604.06
		},
		{
			unixtime: 1656576820,
			balance: 99603.6
		},
		{
			unixtime: 1656576880,
			balance: 99603.6
		},
		{
			unixtime: 1656576940,
			balance: 99604.74
		},
		{
			unixtime: 1656577000,
			balance: 99604.84
		},
		{
			unixtime: 1656577060,
			balance: 99603.84
		},
		{
			unixtime: 1656577120,
			balance: 99600.86
		},
		{
			unixtime: 1656577180,
			balance: 99602.86
		},
		{
			unixtime: 1656577240,
			balance: 99602.86
		},
		{
			unixtime: 1656577300,
			balance: 99600.36
		},
		{
			unixtime: 1656577360,
			balance: 99600.36
		},
		{
			unixtime: 1656577420,
			balance: 99602.26
		},
		{
			unixtime: 1656577480,
			balance: 99604.36
		},
		{
			unixtime: 1656577540,
			balance: 99600.26
		},
		{
			unixtime: 1656577600,
			balance: 99597.66
		},
		{
			unixtime: 1656577660,
			balance: 99594.06
		},
		{
			unixtime: 1656577720,
			balance: 99594.56
		},
		{
			unixtime: 1656577780,
			balance: 99593.98
		},
		{
			unixtime: 1656577840,
			balance: 99593.98
		},
		{
			unixtime: 1656577900,
			balance: 99591.46
		},
		{
			unixtime: 1656577960,
			balance: 99594.66
		},
		{
			unixtime: 1656578020,
			balance: 99593.46
		},
		{
			unixtime: 1656578080,
			balance: 99590.36
		},
		{
			unixtime: 1656578140,
			balance: 99587.66
		},
		{
			unixtime: 1656578200,
			balance: 99587.16
		},
		{
			unixtime: 1656578260,
			balance: 99586.66
		},
		{
			unixtime: 1656578320,
			balance: 99601.08
		},
		{
			unixtime: 1656578380,
			balance: 99583.54
		},
		{
			unixtime: 1656578440,
			balance: 99577.3
		},
		{
			unixtime: 1656578500,
			balance: 99579.5
		},
		{
			unixtime: 1656578560,
			balance: 99588.86
		},
		{
			unixtime: 1656578620,
			balance: 99588.06
		},
		{
			unixtime: 1656578680,
			balance: 99586.36
		},
		{
			unixtime: 1656578740,
			balance: 99585.86
		},
		{
			unixtime: 1656578800,
			balance: 99585.86
		},
		{
			unixtime: 1656578860,
			balance: 99585.86
		},
		{
			unixtime: 1656578920,
			balance: 99581.16
		},
		{
			unixtime: 1656578980,
			balance: 99578.22
		},
		{
			unixtime: 1656579040,
			balance: 99577.32
		},
		{
			unixtime: 1656579100,
			balance: 99577.36
		},
		{
			unixtime: 1656579160,
			balance: 99577.86
		},
		{
			unixtime: 1656579220,
			balance: 99577.66
		},
		{
			unixtime: 1656579280,
			balance: 99577.66
		},
		{
			unixtime: 1656579340,
			balance: 99578.46
		},
		{
			unixtime: 1656579400,
			balance: 99578.46
		},
		{
			unixtime: 1656579460,
			balance: 99578.46
		},
		{
			unixtime: 1656579520,
			balance: 99578.72
		},
		{
			unixtime: 1656579580,
			balance: 99574.76
		},
		{
			unixtime: 1656579640,
			balance: 99575.3
		},
		{
			unixtime: 1656579700,
			balance: 99575.66
		},
		{
			unixtime: 1656579760,
			balance: 99575.66
		},
		{
			unixtime: 1656579820,
			balance: 99572.86
		},
		{
			unixtime: 1656579880,
			balance: 99572.16
		},
		{
			unixtime: 1656579940,
			balance: 99574.06
		},
		{
			unixtime: 1656580000,
			balance: 99577.36
		},
		{
			unixtime: 1656580060,
			balance: 99575.06
		},
		{
			unixtime: 1656580120,
			balance: 99574.16
		},
		{
			unixtime: 1656580180,
			balance: 99574.26
		},
		{
			unixtime: 1656580240,
			balance: 99566.92
		},
		{
			unixtime: 1656580300,
			balance: 99563.26
		},
		{
			unixtime: 1656580360,
			balance: 99561.96
		},
		{
			unixtime: 1656580420,
			balance: 99562.24
		},
		{
			unixtime: 1656580480,
			balance: 99557.76
		},
		{
			unixtime: 1656580540,
			balance: 99555.16
		},
		{
			unixtime: 1656580600,
			balance: 99554.86
		},
		{
			unixtime: 1656580660,
			balance: 99554.36
		},
		{
			unixtime: 1656580720,
			balance: 99566.18
		},
		{
			unixtime: 1656580780,
			balance: 99564.88
		},
		{
			unixtime: 1656580840,
			balance: 99554.08
		},
		{
			unixtime: 1656580900,
			balance: 99554.28
		},
		{
			unixtime: 1656580960,
			balance: 99555.82
		},
		{
			unixtime: 1656581020,
			balance: 99556.46
		},
		{
			unixtime: 1656581080,
			balance: 99556.46
		},
		{
			unixtime: 1656581140,
			balance: 99556.46
		},
		{
			unixtime: 1656581200,
			balance: 99554.86
		},
		{
			unixtime: 1656581260,
			balance: 99555.46
		},
		{
			unixtime: 1656581320,
			balance: 99555.76
		},
		{
			unixtime: 1656581380,
			balance: 99555.06
		},
		{
			unixtime: 1656581440,
			balance: 99553.16
		},
		{
			unixtime: 1656581500,
			balance: 99557.26
		},
		{
			unixtime: 1656581560,
			balance: 99558.26
		},
		{
			unixtime: 1656581620,
			balance: 99559.06
		},
		{
			unixtime: 1656581680,
			balance: 99556.24
		},
		{
			unixtime: 1656581740,
			balance: 99556.24
		},
		{
			unixtime: 1656581800,
			balance: 99556.04
		},
		{
			unixtime: 1656581860,
			balance: 99556.04
		},
		{
			unixtime: 1656581920,
			balance: 99556.04
		},
		{
			unixtime: 1656581980,
			balance: 99556.44
		},
		{
			unixtime: 1656582040,
			balance: 99556.06
		},
		{
			unixtime: 1656582100,
			balance: 99556.06
		},
		{
			unixtime: 1656582160,
			balance: 99556.86
		},
		{
			unixtime: 1656582220,
			balance: 99557.08
		},
		{
			unixtime: 1656582280,
			balance: 99557.26
		},
		{
			unixtime: 1656582340,
			balance: 99557.64
		},
		{
			unixtime: 1656582400,
			balance: 99557.08
		},
		{
			unixtime: 1656582460,
			balance: 99559.38
		},
		{
			unixtime: 1656582520,
			balance: 99557.78
		},
		{
			unixtime: 1656582580,
			balance: 99558.78
		},
		{
			unixtime: 1656582640,
			balance: 99559.72
		},
		{
			unixtime: 1656582700,
			balance: 99560.72
		},
		{
			unixtime: 1656582760,
			balance: 99560.52
		},
		{
			unixtime: 1656582820,
			balance: 99561.12
		},
		{
			unixtime: 1656582880,
			balance: 99561.62
		},
		{
			unixtime: 1656582940,
			balance: 99562.22
		},
		{
			unixtime: 1656583000,
			balance: 99561.72
		},
		{
			unixtime: 1656583060,
			balance: 99563.36
		},
		{
			unixtime: 1656583120,
			balance: 99564.92
		},
		{
			unixtime: 1656583180,
			balance: 99570.66
		},
		{
			unixtime: 1656583240,
			balance: 99570.46
		},
		{
			unixtime: 1656583300,
			balance: 99567.56
		},
		{
			unixtime: 1656583360,
			balance: 99567.46
		},
		{
			unixtime: 1656583420,
			balance: 99568.76
		},
		{
			unixtime: 1656583480,
			balance: 99567.26
		},
		{
			unixtime: 1656583540,
			balance: 99566.76
		},
		{
			unixtime: 1656583600,
			balance: 99565.76
		},
		{
			unixtime: 1656583660,
			balance: 99565.26
		},
		{
			unixtime: 1656583720,
			balance: 99565.96
		},
		{
			unixtime: 1656583780,
			balance: 99567.86
		},
		{
			unixtime: 1656583840,
			balance: 99565.28
		},
		{
			unixtime: 1656583900,
			balance: 99565.48
		},
		{
			unixtime: 1656583960,
			balance: 99563.28
		},
		{
			unixtime: 1656584020,
			balance: 99564.18
		},
		{
			unixtime: 1656584080,
			balance: 99563.78
		},
		{
			unixtime: 1656584140,
			balance: 99563.48
		},
		{
			unixtime: 1656584200,
			balance: 99563.48
		},
		{
			unixtime: 1656584260,
			balance: 99563.36
		},
		{
			unixtime: 1656584320,
			balance: 99564.46
		},
		{
			unixtime: 1656584380,
			balance: 99564.96
		},
		{
			unixtime: 1656584440,
			balance: 99564.96
		},
		{
			unixtime: 1656584500,
			balance: 99564.76
		},
		{
			unixtime: 1656584560,
			balance: 99565.26
		},
		{
			unixtime: 1656584620,
			balance: 99567.16
		},
		{
			unixtime: 1656584680,
			balance: 99568.06
		},
		{
			unixtime: 1656584740,
			balance: 99567.16
		},
		{
			unixtime: 1656584800,
			balance: 99563.86
		},
		{
			unixtime: 1656584860,
			balance: 99563.46
		},
		{
			unixtime: 1656584920,
			balance: 99563.36
		},
		{
			unixtime: 1656584980,
			balance: 99562.76
		},
		{
			unixtime: 1656585040,
			balance: 99562.36
		},
		{
			unixtime: 1656585100,
			balance: 99562.56
		},
		{
			unixtime: 1656585160,
			balance: 99560.72
		},
		{
			unixtime: 1656585220,
			balance: 99560.32
		},
		{
			unixtime: 1656585280,
			balance: 99561.52
		},
		{
			unixtime: 1656585340,
			balance: 99559.82
		},
		{
			unixtime: 1656585400,
			balance: 99556.76
		},
		{
			unixtime: 1656585460,
			balance: 99556.2
		},
		{
			unixtime: 1656585520,
			balance: 99556.3
		},
		{
			unixtime: 1656585580,
			balance: 99556.26
		},
		{
			unixtime: 1656585640,
			balance: 99557.26
		},
		{
			unixtime: 1656585700,
			balance: 99556.66
		},
		{
			unixtime: 1656585760,
			balance: 99555.76
		},
		{
			unixtime: 1656585820,
			balance: 99557.86
		},
		{
			unixtime: 1656585880,
			balance: 99560.16
		},
		{
			unixtime: 1656585940,
			balance: 99559.76
		},
		{
			unixtime: 1656586000,
			balance: 99560.76
		},
		{
			unixtime: 1656586060,
			balance: 99560.26
		},
		{
			unixtime: 1656586120,
			balance: 99565.76
		},
		{
			unixtime: 1656586180,
			balance: 99565.86
		},
		{
			unixtime: 1656586240,
			balance: 99565.76
		},
		{
			unixtime: 1656586300,
			balance: 99560.76
		},
		{
			unixtime: 1656586360,
			balance: 99562.66
		},
		{
			unixtime: 1656586420,
			balance: 99571.04
		},
		{
			unixtime: 1656586480,
			balance: 99571.04
		},
		{
			unixtime: 1656586540,
			balance: 99572.96
		},
		{
			unixtime: 1656586600,
			balance: 99573.46
		},
		{
			unixtime: 1656586660,
			balance: 99573.66
		},
		{
			unixtime: 1656586720,
			balance: 99573.64
		},
		{
			unixtime: 1656586780,
			balance: 99574.04
		},
		{
			unixtime: 1656586840,
			balance: 99577.86
		},
		{
			unixtime: 1656586900,
			balance: 99574.26
		},
		{
			unixtime: 1656586960,
			balance: 99573.18
		},
		{
			unixtime: 1656587020,
			balance: 99576.16
		},
		{
			unixtime: 1656587080,
			balance: 99573.56
		},
		{
			unixtime: 1656587140,
			balance: 99570.98
		},
		{
			unixtime: 1656587200,
			balance: 99571.58
		},
		{
			unixtime: 1656587260,
			balance: 99571.52
		},
		{
			unixtime: 1656587320,
			balance: 99573.8
		},
		{
			unixtime: 1656587380,
			balance: 99573.46
		},
		{
			unixtime: 1656587440,
			balance: 99576.66
		},
		{
			unixtime: 1656587500,
			balance: 99570.84
		},
		{
			unixtime: 1656587560,
			balance: 99570.4
		},
		{
			unixtime: 1656587620,
			balance: 99572.16
		},
		{
			unixtime: 1656587680,
			balance: 99571.86
		},
		{
			unixtime: 1656587740,
			balance: 99578.04
		},
		{
			unixtime: 1656587800,
			balance: 99579.46
		},
		{
			unixtime: 1656587860,
			balance: 99575.16
		},
		{
			unixtime: 1656587920,
			balance: 99567.14
		},
		{
			unixtime: 1656587980,
			balance: 99570.36
		},
		{
			unixtime: 1656588040,
			balance: 99570.76
		},
		{
			unixtime: 1656588100,
			balance: 99571.9
		},
		{
			unixtime: 1656588160,
			balance: 99571
		},
		{
			unixtime: 1656588220,
			balance: 99570.6
		},
		{
			unixtime: 1656588280,
			balance: 99571
		},
		{
			unixtime: 1656588340,
			balance: 99570.62
		},
		{
			unixtime: 1656588400,
			balance: 99572.56
		},
		{
			unixtime: 1656588460,
			balance: 99575.02
		},
		{
			unixtime: 1656588520,
			balance: 99573.96
		},
		{
			unixtime: 1656588580,
			balance: 99574.86
		},
		{
			unixtime: 1656588640,
			balance: 99572.32
		},
		{
			unixtime: 1656588700,
			balance: 99570.56
		},
		{
			unixtime: 1656588760,
			balance: 99560.44
		},
		{
			unixtime: 1656588820,
			balance: 99561.16
		},
		{
			unixtime: 1656588880,
			balance: 99560.86
		},
		{
			unixtime: 1656588940,
			balance: 99561.46
		},
		{
			unixtime: 1656589000,
			balance: 99559
		},
		{
			unixtime: 1656589060,
			balance: 99556.12
		},
		{
			unixtime: 1656589120,
			balance: 99557.16
		},
		{
			unixtime: 1656589180,
			balance: 99555.44
		},
		{
			unixtime: 1656589240,
			balance: 99552.88
		},
		{
			unixtime: 1656589300,
			balance: 99548.46
		},
		{
			unixtime: 1656589360,
			balance: 99549.28
		},
		{
			unixtime: 1656589420,
			balance: 99550.82
		},
		{
			unixtime: 1656589480,
			balance: 99555.12
		},
		{
			unixtime: 1656589540,
			balance: 99551.66
		},
		{
			unixtime: 1656589600,
			balance: 99549.28
		},
		{
			unixtime: 1656589660,
			balance: 99550.56
		},
		{
			unixtime: 1656589720,
			balance: 99550.76
		},
		{
			unixtime: 1656589780,
			balance: 99552.86
		},
		{
			unixtime: 1656589840,
			balance: 99553.24
		},
		{
			unixtime: 1656589900,
			balance: 99552.92
		},
		{
			unixtime: 1656589960,
			balance: 99554.72
		},
		{
			unixtime: 1656590020,
			balance: 99555.46
		},
		{
			unixtime: 1656590080,
			balance: 99558.14
		},
		{
			unixtime: 1656590140,
			balance: 99561.6
		},
		{
			unixtime: 1656590200,
			balance: 99563.7
		},
		{
			unixtime: 1656590260,
			balance: 99564.02
		},
		{
			unixtime: 1656590320,
			balance: 99564.22
		},
		{
			unixtime: 1656590380,
			balance: 99563.86
		},
		{
			unixtime: 1656590440,
			balance: 99563.54
		},
		{
			unixtime: 1656590500,
			balance: 99561.24
		},
		{
			unixtime: 1656590560,
			balance: 99561.86
		},
		{
			unixtime: 1656590620,
			balance: 99564.88
		},
		{
			unixtime: 1656590680,
			balance: 99561.02
		},
		{
			unixtime: 1656590740,
			balance: 99562.26
		},
		{
			unixtime: 1656590800,
			balance: 99561.44
		},
		{
			unixtime: 1656590860,
			balance: 99559.94
		},
		{
			unixtime: 1656590920,
			balance: 99560.76
		},
		{
			unixtime: 1656590980,
			balance: 99558.96
		},
		{
			unixtime: 1656591040,
			balance: 99559.46
		},
		{
			unixtime: 1656591100,
			balance: 99559.08
		},
		{
			unixtime: 1656591160,
			balance: 99558.66
		},
		{
			unixtime: 1656591220,
			balance: 99559.94
		},
		{
			unixtime: 1656591280,
			balance: 99560.6
		},
		{
			unixtime: 1656591340,
			balance: 99559.06
		},
		{
			unixtime: 1656591400,
			balance: 99555.24
		},
		{
			unixtime: 1656591460,
			balance: 99555.76
		},
		{
			unixtime: 1656591520,
			balance: 99558.72
		},
		{
			unixtime: 1656591580,
			balance: 99554.96
		},
		{
			unixtime: 1656591640,
			balance: 99553.98
		},
		{
			unixtime: 1656591700,
			balance: 99557.46
		},
		{
			unixtime: 1656591760,
			balance: 99558.54
		},
		{
			unixtime: 1656591820,
			balance: 99562.3
		},
		{
			unixtime: 1656591880,
			balance: 99567.16
		},
		{
			unixtime: 1656591940,
			balance: 99571.22
		},
		{
			unixtime: 1656592000,
			balance: 99571.76
		},
		{
			unixtime: 1656592060,
			balance: 99570.02
		},
		{
			unixtime: 1656592120,
			balance: 99563.46
		},
		{
			unixtime: 1656592180,
			balance: 99562.94
		},
		{
			unixtime: 1656592240,
			balance: 99590.16
		},
		{
			unixtime: 1656592300,
			balance: 99599.46
		},
		{
			unixtime: 1656592360,
			balance: 99600.94
		},
		{
			unixtime: 1656592420,
			balance: 99604.86
		},
		{
			unixtime: 1656592480,
			balance: 99590.14
		},
		{
			unixtime: 1656592540,
			balance: 99598.74
		},
		{
			unixtime: 1656592600,
			balance: 99599
		},
		{
			unixtime: 1656592660,
			balance: 99592.28
		},
		{
			unixtime: 1656592720,
			balance: 99590.14
		},
		{
			unixtime: 1656592780,
			balance: 99586.06
		},
		{
			unixtime: 1656592840,
			balance: 99575.92
		},
		{
			unixtime: 1656592900,
			balance: 99582.76
		},
		{
			unixtime: 1656592960,
			balance: 99585.66
		},
		{
			unixtime: 1656593020,
			balance: 99582.94
		},
		{
			unixtime: 1656593080,
			balance: 99583.02
		},
		{
			unixtime: 1656593140,
			balance: 99590.56
		},
		{
			unixtime: 1656593200,
			balance: 99592.06
		},
		{
			unixtime: 1656593260,
			balance: 99590.68
		},
		{
			unixtime: 1656593320,
			balance: 99586.66
		},
		{
			unixtime: 1656593380,
			balance: 99585.56
		},
		{
			unixtime: 1656593440,
			balance: 99584.62
		},
		{
			unixtime: 1656593500,
			balance: 99571.46
		},
		{
			unixtime: 1656593560,
			balance: 99567.92
		},
		{
			unixtime: 1656593620,
			balance: 99564.26
		},
		{
			unixtime: 1656593680,
			balance: 99561.7
		},
		{
			unixtime: 1656593740,
			balance: 99567.56
		},
		{
			unixtime: 1656593800,
			balance: 99565
		},
		{
			unixtime: 1656593860,
			balance: 99561.84
		},
		{
			unixtime: 1656593920,
			balance: 99557.36
		},
		{
			unixtime: 1656593980,
			balance: 99551.8
		},
		{
			unixtime: 1656594040,
			balance: 99551.52
		},
		{
			unixtime: 1656594100,
			balance: 99550.9
		},
		{
			unixtime: 1656594160,
			balance: 99559.76
		},
		{
			unixtime: 1656594220,
			balance: 99561.2
		},
		{
			unixtime: 1656594280,
			balance: 99559.56
		},
		{
			unixtime: 1656594340,
			balance: 99570.14
		},
		{
			unixtime: 1656594400,
			balance: 99573.34
		},
		{
			unixtime: 1656594460,
			balance: 99571.08
		},
		{
			unixtime: 1656594520,
			balance: 99570.68
		},
		{
			unixtime: 1656594580,
			balance: 99577.96
		},
		{
			unixtime: 1656594640,
			balance: 99565.26
		},
		{
			unixtime: 1656594700,
			balance: 99572.96
		},
		{
			unixtime: 1656594760,
			balance: 99573.36
		},
		{
			unixtime: 1656594820,
			balance: 99572.26
		},
		{
			unixtime: 1656594880,
			balance: 99572.62
		},
		{
			unixtime: 1656594940,
			balance: 99569.9
		},
		{
			unixtime: 1656595000,
			balance: 99574.26
		},
		{
			unixtime: 1656595060,
			balance: 99579.92
		},
		{
			unixtime: 1656595120,
			balance: 99583.32
		},
		{
			unixtime: 1656595180,
			balance: 99582.86
		},
		{
			unixtime: 1656595240,
			balance: 99582.46
		},
		{
			unixtime: 1656595300,
			balance: 99584.26
		},
		{
			unixtime: 1656595360,
			balance: 99577.06
		},
		{
			unixtime: 1656595420,
			balance: 99579.78
		},
		{
			unixtime: 1656595480,
			balance: 99579.42
		},
		{
			unixtime: 1656595540,
			balance: 99568.78
		},
		{
			unixtime: 1656595600,
			balance: 99558.28
		},
		{
			unixtime: 1656595660,
			balance: 99555.82
		},
		{
			unixtime: 1656595720,
			balance: 99576.76
		},
		{
			unixtime: 1656595780,
			balance: 99576.38
		},
		{
			unixtime: 1656595840,
			balance: 99589.6
		},
		{
			unixtime: 1656595900,
			balance: 99563
		},
		{
			unixtime: 1656595960,
			balance: 99535.26
		},
		{
			unixtime: 1656596020,
			balance: 99536.96
		},
		{
			unixtime: 1656596080,
			balance: 99559.9
		},
		{
			unixtime: 1656596140,
			balance: 99558.62
		},
		{
			unixtime: 1656596200,
			balance: 99541
		},
		{
			unixtime: 1656596260,
			balance: 99543.7
		},
		{
			unixtime: 1656596320,
			balance: 99533.04
		},
		{
			unixtime: 1656596380,
			balance: 99520.58
		},
		{
			unixtime: 1656596440,
			balance: 99521.34
		},
		{
			unixtime: 1656596500,
			balance: 99511.86
		},
		{
			unixtime: 1656596560,
			balance: 99519.3
		},
		{
			unixtime: 1656596620,
			balance: 99536.56
		},
		{
			unixtime: 1656596680,
			balance: 99532.58
		},
		{
			unixtime: 1656596740,
			balance: 99531.06
		},
		{
			unixtime: 1656596800,
			balance: 99510.7
		},
		{
			unixtime: 1656596860,
			balance: 99531.24
		},
		{
			unixtime: 1656596920,
			balance: 99517.5
		},
		{
			unixtime: 1656596980,
			balance: 99520.14
		},
		{
			unixtime: 1656597040,
			balance: 99516.68
		},
		{
			unixtime: 1656597100,
			balance: 99522.58
		},
		{
			unixtime: 1656597160,
			balance: 99501.56
		},
		{
			unixtime: 1656597220,
			balance: 99493.36
		},
		{
			unixtime: 1656597280,
			balance: 99495.48
		},
		{
			unixtime: 1656597340,
			balance: 99490.02
		},
		{
			unixtime: 1656597400,
			balance: 99496.08
		},
		{
			unixtime: 1656597460,
			balance: 99498.46
		},
		{
			unixtime: 1656597520,
			balance: 99505.3
		},
		{
			unixtime: 1656597580,
			balance: 99500.58
		},
		{
			unixtime: 1656597640,
			balance: 99491.66
		},
		{
			unixtime: 1656597700,
			balance: 99502.8
		},
		{
			unixtime: 1656597760,
			balance: 99488.86
		},
		{
			unixtime: 1656597820,
			balance: 99485.44
		},
		{
			unixtime: 1656597880,
			balance: 99500.28
		},
		{
			unixtime: 1656597940,
			balance: 99488.72
		},
		{
			unixtime: 1656598000,
			balance: 99496.74
		},
		{
			unixtime: 1656598060,
			balance: 99492.56
		},
		{
			unixtime: 1656598120,
			balance: 99496.78
		},
		{
			unixtime: 1656598180,
			balance: 99484.18
		},
		{
			unixtime: 1656598240,
			balance: 99485.72
		},
		{
			unixtime: 1656598300,
			balance: 99482.88
		},
		{
			unixtime: 1656598360,
			balance: 99488.56
		},
		{
			unixtime: 1656598420,
			balance: 99483.88
		},
		{
			unixtime: 1656598480,
			balance: 99494.48
		},
		{
			unixtime: 1656598540,
			balance: 99498.4
		},
		{
			unixtime: 1656598600,
			balance: 99503.66
		},
		{
			unixtime: 1656598660,
			balance: 99512.42
		},
		{
			unixtime: 1656598720,
			balance: 99519.58
		},
		{
			unixtime: 1656598780,
			balance: 99517.66
		},
		{
			unixtime: 1656598840,
			balance: 99505.64
		},
		{
			unixtime: 1656598900,
			balance: 99501.64
		},
		{
			unixtime: 1656598960,
			balance: 99504
		},
		{
			unixtime: 1656599020,
			balance: 99500.12
		},
		{
			unixtime: 1656599080,
			balance: 99498.22
		},
		{
			unixtime: 1656599140,
			balance: 99505.16
		},
		{
			unixtime: 1656599200,
			balance: 99496.18
		},
		{
			unixtime: 1656599260,
			balance: 99492.84
		},
		{
			unixtime: 1656599320,
			balance: 99482.02
		},
		{
			unixtime: 1656599380,
			balance: 99485.22
		},
		{
			unixtime: 1656599440,
			balance: 99500.62
		},
		{
			unixtime: 1656599500,
			balance: 99501.3
		},
		{
			unixtime: 1656599560,
			balance: 99506.32
		},
		{
			unixtime: 1656599620,
			balance: 99518.14
		},
		{
			unixtime: 1656599680,
			balance: 99516.58
		},
		{
			unixtime: 1656599740,
			balance: 99517.7
		},
		{
			unixtime: 1656599800,
			balance: 99502.26
		},
		{
			unixtime: 1656599860,
			balance: 99509.72
		},
		{
			unixtime: 1656599920,
			balance: 99510.12
		},
		{
			unixtime: 1656599980,
			balance: 99499.9
		},
		{
			unixtime: 1656600040,
			balance: 99493.66
		},
		{
			unixtime: 1656600100,
			balance: 99498
		},
		{
			unixtime: 1656600160,
			balance: 99485.16
		},
		{
			unixtime: 1656600220,
			balance: 99488.66
		},
		{
			unixtime: 1656600280,
			balance: 99488.72
		},
		{
			unixtime: 1656600340,
			balance: 99488.24
		},
		{
			unixtime: 1656600400,
			balance: 99485.7
		},
		{
			unixtime: 1656600460,
			balance: 99488.94
		},
		{
			unixtime: 1656600520,
			balance: 99483.16
		},
		{
			unixtime: 1656600580,
			balance: 99489.14
		},
		{
			unixtime: 1656600640,
			balance: 99491.82
		},
		{
			unixtime: 1656600700,
			balance: 99496.76
		},
		{
			unixtime: 1656600760,
			balance: 99507.9
		},
		{
			unixtime: 1656600820,
			balance: 99522.56
		},
		{
			unixtime: 1656600880,
			balance: 99517.06
		},
		{
			unixtime: 1656600940,
			balance: 99522.82
		},
		{
			unixtime: 1656601000,
			balance: 99522.34
		},
		{
			unixtime: 1656601060,
			balance: 99522.42
		},
		{
			unixtime: 1656601120,
			balance: 99532.54
		},
		{
			unixtime: 1656601180,
			balance: 99537.02
		},
		{
			unixtime: 1656601240,
			balance: 99529.72
		},
		{
			unixtime: 1656601300,
			balance: 99525.08
		},
		{
			unixtime: 1656601360,
			balance: 99526.8
		},
		{
			unixtime: 1656601420,
			balance: 99518.34
		},
		{
			unixtime: 1656601480,
			balance: 99520.92
		},
		{
			unixtime: 1656601540,
			balance: 99530.72
		},
		{
			unixtime: 1656601600,
			balance: 99522.76
		},
		{
			unixtime: 1656601660,
			balance: 99529.74
		},
		{
			unixtime: 1656601720,
			balance: 99544.7
		},
		{
			unixtime: 1656601780,
			balance: 99553.7
		},
		{
			unixtime: 1656601840,
			balance: 99560.92
		},
		{
			unixtime: 1656601900,
			balance: 99546.96
		},
		{
			unixtime: 1656601960,
			balance: 99557.96
		},
		{
			unixtime: 1656602020,
			balance: 99552.04
		},
		{
			unixtime: 1656602080,
			balance: 99552.28
		},
		{
			unixtime: 1656602140,
			balance: 99556.9
		},
		{
			unixtime: 1656602200,
			balance: 99557.22
		},
		{
			unixtime: 1656602260,
			balance: 99563.78
		},
		{
			unixtime: 1656602320,
			balance: 99556.24
		},
		{
			unixtime: 1656602380,
			balance: 99555.42
		},
		{
			unixtime: 1656602440,
			balance: 99559.84
		},
		{
			unixtime: 1656602500,
			balance: 99561.88
		},
		{
			unixtime: 1656602560,
			balance: 99573.64
		},
		{
			unixtime: 1656602620,
			balance: 99578.26
		},
		{
			unixtime: 1656602680,
			balance: 99577.66
		},
		{
			unixtime: 1656602740,
			balance: 99583.88
		},
		{
			unixtime: 1656602800,
			balance: 99602.32
		},
		{
			unixtime: 1656602860,
			balance: 99605.74
		},
		{
			unixtime: 1656602920,
			balance: 99604.78
		},
		{
			unixtime: 1656602980,
			balance: 99606.22
		},
		{
			unixtime: 1656603040,
			balance: 99599.28
		},
		{
			unixtime: 1656603100,
			balance: 99589.34
		},
		{
			unixtime: 1656603160,
			balance: 99592.24
		},
		{
			unixtime: 1656603220,
			balance: 99589.82
		},
		{
			unixtime: 1656603280,
			balance: 99583.26
		},
		{
			unixtime: 1656603340,
			balance: 99579.36
		},
		{
			unixtime: 1656603400,
			balance: 99579.36
		},
		{
			unixtime: 1656603460,
			balance: 99584.62
		},
		{
			unixtime: 1656603520,
			balance: 99580.3
		},
		{
			unixtime: 1656603580,
			balance: 99588.52
		},
		{
			unixtime: 1656603640,
			balance: 99589.46
		},
		{
			unixtime: 1656603700,
			balance: 99589.64
		},
		{
			unixtime: 1656603760,
			balance: 99592.44
		},
		{
			unixtime: 1656603820,
			balance: 99592.16
		},
		{
			unixtime: 1656603880,
			balance: 99602.32
		},
		{
			unixtime: 1656603940,
			balance: 99605.26
		},
		{
			unixtime: 1656604000,
			balance: 99609.16
		},
		{
			unixtime: 1656604060,
			balance: 99618.6
		},
		{
			unixtime: 1656604120,
			balance: 99622.5
		},
		{
			unixtime: 1656604180,
			balance: 99624.58
		},
		{
			unixtime: 1656604240,
			balance: 99611.72
		},
		{
			unixtime: 1656604300,
			balance: 99613.94
		},
		{
			unixtime: 1656604360,
			balance: 99610.86
		},
		{
			unixtime: 1656604420,
			balance: 99619.66
		},
		{
			unixtime: 1656604480,
			balance: 99615.08
		},
		{
			unixtime: 1656604540,
			balance: 99611.76
		},
		{
			unixtime: 1656604600,
			balance: 99612.16
		},
		{
			unixtime: 1656604660,
			balance: 99613.08
		},
		{
			unixtime: 1656604720,
			balance: 99623.58
		},
		{
			unixtime: 1656604780,
			balance: 99624.12
		},
		{
			unixtime: 1656604840,
			balance: 99618.3
		},
		{
			unixtime: 1656604900,
			balance: 99619.24
		},
		{
			unixtime: 1656604960,
			balance: 99612.3
		},
		{
			unixtime: 1656605020,
			balance: 99607.12
		},
		{
			unixtime: 1656605080,
			balance: 99604.68
		},
		{
			unixtime: 1656605140,
			balance: 99602.34
		},
		{
			unixtime: 1656605200,
			balance: 99590.74
		},
		{
			unixtime: 1656605260,
			balance: 99589.14
		},
		{
			unixtime: 1656605320,
			balance: 99589.6
		},
		{
			unixtime: 1656605380,
			balance: 99582.62
		},
		{
			unixtime: 1656605440,
			balance: 99586.06
		},
		{
			unixtime: 1656605500,
			balance: 99583.16
		},
		{
			unixtime: 1656605560,
			balance: 99589.44
		},
		{
			unixtime: 1656605620,
			balance: 99580.82
		},
		{
			unixtime: 1656605680,
			balance: 99592.04
		},
		{
			unixtime: 1656605740,
			balance: 99596.54
		},
		{
			unixtime: 1656605800,
			balance: 99591.8
		},
		{
			unixtime: 1656605860,
			balance: 99590.38
		},
		{
			unixtime: 1656605920,
			balance: 99600.88
		},
		{
			unixtime: 1656605980,
			balance: 99606.44
		},
		{
			unixtime: 1656606040,
			balance: 99610.1
		},
		{
			unixtime: 1656606100,
			balance: 99618.98
		},
		{
			unixtime: 1656606160,
			balance: 99617.98
		},
		{
			unixtime: 1656606220,
			balance: 99610.66
		},
		{
			unixtime: 1656606280,
			balance: 99629.08
		},
		{
			unixtime: 1656606340,
			balance: 99647.12
		},
		{
			unixtime: 1656606400,
			balance: 99650.26
		},
		{
			unixtime: 1656606460,
			balance: 99650.28
		},
		{
			unixtime: 1656606520,
			balance: 99647.84
		},
		{
			unixtime: 1656606580,
			balance: 99652.02
		},
		{
			unixtime: 1656606640,
			balance: 99658.42
		},
		{
			unixtime: 1656606700,
			balance: 99666.66
		},
		{
			unixtime: 1656606760,
			balance: 99672.46
		},
		{
			unixtime: 1656606820,
			balance: 99663.42
		},
		{
			unixtime: 1656606880,
			balance: 99658.36
		},
		{
			unixtime: 1656606940,
			balance: 99652.68
		},
		{
			unixtime: 1656607000,
			balance: 99643.76
		},
		{
			unixtime: 1656607060,
			balance: 99650.54
		},
		{
			unixtime: 1656607120,
			balance: 99650.02
		},
		{
			unixtime: 1656607180,
			balance: 99651.16
		},
		{
			unixtime: 1656607240,
			balance: 99647.36
		},
		{
			unixtime: 1656607300,
			balance: 99647.3
		},
		{
			unixtime: 1656607360,
			balance: 99645.18
		},
		{
			unixtime: 1656607420,
			balance: 99655.5
		},
		{
			unixtime: 1656607480,
			balance: 99653.46
		},
		{
			unixtime: 1656607540,
			balance: 99657.18
		},
		{
			unixtime: 1656607600,
			balance: 99655.94
		},
		{
			unixtime: 1656607660,
			balance: 99658.98
		},
		{
			unixtime: 1656607720,
			balance: 99656.44
		},
		{
			unixtime: 1656607780,
			balance: 99645.22
		},
		{
			unixtime: 1656607840,
			balance: 99636.02
		},
		{
			unixtime: 1656607900,
			balance: 99634.58
		},
		{
			unixtime: 1656607960,
			balance: 99642.48
		},
		{
			unixtime: 1656608020,
			balance: 99639.02
		},
		{
			unixtime: 1656608080,
			balance: 99644.38
		},
		{
			unixtime: 1656608140,
			balance: 99646.42
		},
		{
			unixtime: 1656608200,
			balance: 99637.7
		},
		{
			unixtime: 1656608260,
			balance: 99637.5
		},
		{
			unixtime: 1656608320,
			balance: 99639.3
		},
		{
			unixtime: 1656608380,
			balance: 99645.06
		},
		{
			unixtime: 1656608440,
			balance: 99641.42
		},
		{
			unixtime: 1656608500,
			balance: 99648.82
		},
		{
			unixtime: 1656608560,
			balance: 99653.2
		},
		{
			unixtime: 1656608620,
			balance: 99642.18
		},
		{
			unixtime: 1656608680,
			balance: 99650.12
		},
		{
			unixtime: 1656608740,
			balance: 99641.96
		},
		{
			unixtime: 1656608800,
			balance: 99635.2
		},
		{
			unixtime: 1656608860,
			balance: 99629.68
		},
		{
			unixtime: 1656608920,
			balance: 99629.66
		},
		{
			unixtime: 1656608980,
			balance: 99626.42
		},
		{
			unixtime: 1656609040,
			balance: 99622.46
		},
		{
			unixtime: 1656609100,
			balance: 99619.08
		},
		{
			unixtime: 1656609160,
			balance: 99613.78
		},
		{
			unixtime: 1656609220,
			balance: 99608.14
		},
		{
			unixtime: 1656609280,
			balance: 99597.72
		},
		{
			unixtime: 1656609340,
			balance: 99600.22
		},
		{
			unixtime: 1656609400,
			balance: 99603.98
		},
		{
			unixtime: 1656609460,
			balance: 99600.62
		},
		{
			unixtime: 1656609520,
			balance: 99601.18
		},
		{
			unixtime: 1656609580,
			balance: 99599.98
		},
		{
			unixtime: 1656609640,
			balance: 99602.46
		},
		{
			unixtime: 1656609700,
			balance: 99609.36
		},
		{
			unixtime: 1656609760,
			balance: 99613.42
		},
		{
			unixtime: 1656609820,
			balance: 99617.28
		},
		{
			unixtime: 1656609880,
			balance: 99616.46
		},
		{
			unixtime: 1656609940,
			balance: 99618.02
		},
		{
			unixtime: 1656610000,
			balance: 99621.32
		},
		{
			unixtime: 1656610060,
			balance: 99616.12
		},
		{
			unixtime: 1656610120,
			balance: 99612.76
		},
		{
			unixtime: 1656610180,
			balance: 99616.04
		},
		{
			unixtime: 1656610240,
			balance: 99616.22
		},
		{
			unixtime: 1656610300,
			balance: 99620.34
		},
		{
			unixtime: 1656610360,
			balance: 99623.56
		},
		{
			unixtime: 1656610420,
			balance: 99622.34
		},
		{
			unixtime: 1656610480,
			balance: 99618
		},
		{
			unixtime: 1656610540,
			balance: 99619.34
		},
		{
			unixtime: 1656610600,
			balance: 99625.36
		},
		{
			unixtime: 1656610660,
			balance: 99624.06
		},
		{
			unixtime: 1656610720,
			balance: 99623.18
		},
		{
			unixtime: 1656610780,
			balance: 99624.48
		},
		{
			unixtime: 1656610840,
			balance: 99634.84
		},
		{
			unixtime: 1656610900,
			balance: 99634.48
		},
		{
			unixtime: 1656610960,
			balance: 99634.04
		},
		{
			unixtime: 1656611020,
			balance: 99616.3
		},
		{
			unixtime: 1656611080,
			balance: 99612.72
		},
		{
			unixtime: 1656611140,
			balance: 99614.22
		},
		{
			unixtime: 1656611200,
			balance: 99615.76
		},
		{
			unixtime: 1656611260,
			balance: 99620.72
		},
		{
			unixtime: 1656611320,
			balance: 99623.16
		},
		{
			unixtime: 1656611380,
			balance: 99632.86
		},
		{
			unixtime: 1656611440,
			balance: 99635.1
		},
		{
			unixtime: 1656611500,
			balance: 99638.12
		},
		{
			unixtime: 1656611560,
			balance: 99643.52
		},
		{
			unixtime: 1656611620,
			balance: 99650.44
		},
		{
			unixtime: 1656611680,
			balance: 99645.26
		},
		{
			unixtime: 1656611740,
			balance: 99633.86
		},
		{
			unixtime: 1656611800,
			balance: 99637.08
		},
		{
			unixtime: 1656611860,
			balance: 99636.32
		},
		{
			unixtime: 1656611920,
			balance: 99633.86
		},
		{
			unixtime: 1656611980,
			balance: 99633.82
		},
		{
			unixtime: 1656612040,
			balance: 99635.44
		},
		{
			unixtime: 1656612100,
			balance: 99635.4
		},
		{
			unixtime: 1656612160,
			balance: 99628.04
		},
		{
			unixtime: 1656612220,
			balance: 99632.48
		},
		{
			unixtime: 1656612280,
			balance: 99637.04
		},
		{
			unixtime: 1656612340,
			balance: 99637.56
		},
		{
			unixtime: 1656612400,
			balance: 99638.36
		},
		{
			unixtime: 1656612460,
			balance: 99626.64
		},
		{
			unixtime: 1656612520,
			balance: 99631.94
		},
		{
			unixtime: 1656612580,
			balance: 99621.3
		},
		{
			unixtime: 1656612640,
			balance: 99628.54
		},
		{
			unixtime: 1656612700,
			balance: 99632.64
		},
		{
			unixtime: 1656612760,
			balance: 99635.98
		},
		{
			unixtime: 1656612820,
			balance: 99631.08
		},
		{
			unixtime: 1656612880,
			balance: 99622.48
		},
		{
			unixtime: 1656612940,
			balance: 99621.44
		},
		{
			unixtime: 1656613000,
			balance: 99623.36
		},
		{
			unixtime: 1656613060,
			balance: 99629.34
		},
		{
			unixtime: 1656613120,
			balance: 99624.82
		},
		{
			unixtime: 1656613180,
			balance: 99631.74
		},
		{
			unixtime: 1656613240,
			balance: 99628.9
		},
		{
			unixtime: 1656613300,
			balance: 99624.68
		},
		{
			unixtime: 1656613360,
			balance: 99622.1
		},
		{
			unixtime: 1656613420,
			balance: 99622.24
		},
		{
			unixtime: 1656613480,
			balance: 99612.98
		},
		{
			unixtime: 1656613540,
			balance: 99598.68
		},
		{
			unixtime: 1656613600,
			balance: 99598.5
		},
		{
			unixtime: 1656613660,
			balance: 99619
		},
		{
			unixtime: 1656613720,
			balance: 99618.64
		},
		{
			unixtime: 1656613780,
			balance: 99613.28
		},
		{
			unixtime: 1656613840,
			balance: 99618.1
		},
		{
			unixtime: 1656613900,
			balance: 99616.12
		},
		{
			unixtime: 1656613960,
			balance: 99613.82
		},
		{
			unixtime: 1656614020,
			balance: 99609.54
		},
		{
			unixtime: 1656614080,
			balance: 99613.68
		},
		{
			unixtime: 1656614140,
			balance: 99609.64
		},
		{
			unixtime: 1656614200,
			balance: 99608.04
		},
		{
			unixtime: 1656614260,
			balance: 99611.28
		},
		{
			unixtime: 1656614320,
			balance: 99614.24
		},
		{
			unixtime: 1656614380,
			balance: 99614.6
		},
		{
			unixtime: 1656614440,
			balance: 99610.26
		},
		{
			unixtime: 1656614500,
			balance: 99598.42
		},
		{
			unixtime: 1656614560,
			balance: 99602.38
		},
		{
			unixtime: 1656614620,
			balance: 99581.88
		},
		{
			unixtime: 1656614680,
			balance: 99586.26
		},
		{
			unixtime: 1656614740,
			balance: 99582.8
		},
		{
			unixtime: 1656614800,
			balance: 99587.8
		},
		{
			unixtime: 1656614860,
			balance: 99591.5
		},
		{
			unixtime: 1656614920,
			balance: 99588.7
		},
		{
			unixtime: 1656614980,
			balance: 99588.56
		},
		{
			unixtime: 1656615040,
			balance: 99586.42
		},
		{
			unixtime: 1656615100,
			balance: 99586.36
		},
		{
			unixtime: 1656615160,
			balance: 99579.66
		},
		{
			unixtime: 1656615220,
			balance: 99571.62
		},
		{
			unixtime: 1656615280,
			balance: 99571.82
		},
		{
			unixtime: 1656615340,
			balance: 99571.56
		},
		{
			unixtime: 1656615400,
			balance: 99578.08
		},
		{
			unixtime: 1656615460,
			balance: 99574.26
		},
		{
			unixtime: 1656615520,
			balance: 99579.46
		},
		{
			unixtime: 1656615580,
			balance: 99576.74
		},
		{
			unixtime: 1656615640,
			balance: 99578.52
		},
		{
			unixtime: 1656615700,
			balance: 99578.98
		},
		{
			unixtime: 1656615760,
			balance: 99584.26
		},
		{
			unixtime: 1656615820,
			balance: 99574.8
		},
		{
			unixtime: 1656615880,
			balance: 99576.94
		},
		{
			unixtime: 1656615940,
			balance: 99567.16
		},
		{
			unixtime: 1656616000,
			balance: 99553.8
		},
		{
			unixtime: 1656616060,
			balance: 99550.98
		},
		{
			unixtime: 1656616120,
			balance: 99550.46
		},
		{
			unixtime: 1656616180,
			balance: 99545.26
		},
		{
			unixtime: 1656616240,
			balance: 99548.12
		},
		{
			unixtime: 1656616300,
			balance: 99541.44
		},
		{
			unixtime: 1656616360,
			balance: 99541.98
		},
		{
			unixtime: 1656616420,
			balance: 99546.2
		},
		{
			unixtime: 1656616480,
			balance: 99547.06
		},
		{
			unixtime: 1656616540,
			balance: 99536.88
		},
		{
			unixtime: 1656616600,
			balance: 99532.32
		},
		{
			unixtime: 1656616660,
			balance: 99536.82
		},
		{
			unixtime: 1656616720,
			balance: 99536.62
		},
		{
			unixtime: 1656616781,
			balance: 99534.86
		},
		{
			unixtime: 1656616840,
			balance: 99537.1
		},
		{
			unixtime: 1656616900,
			balance: 99545.12
		},
		{
			unixtime: 1656616960,
			balance: 99543.36
		},
		{
			unixtime: 1656617020,
			balance: 99548.46
		},
		{
			unixtime: 1656617080,
			balance: 99548.3
		},
		{
			unixtime: 1656617140,
			balance: 99562.46
		},
		{
			unixtime: 1656617200,
			balance: 99563.1
		},
		{
			unixtime: 1656617260,
			balance: 99559.44
		},
		{
			unixtime: 1656617320,
			balance: 99570.66
		},
		{
			unixtime: 1656617380,
			balance: 99576.78
		},
		{
			unixtime: 1656617440,
			balance: 99576.54
		},
		{
			unixtime: 1656617500,
			balance: 99585.5
		},
		{
			unixtime: 1656617560,
			balance: 99586.5
		},
		{
			unixtime: 1656617620,
			balance: 99589.82
		},
		{
			unixtime: 1656617680,
			balance: 99594.56
		},
		{
			unixtime: 1656617740,
			balance: 99589.36
		},
		{
			unixtime: 1656617800,
			balance: 99594.36
		},
		{
			unixtime: 1656617860,
			balance: 99597.54
		},
		{
			unixtime: 1656617920,
			balance: 99598.96
		},
		{
			unixtime: 1656617980,
			balance: 99584.92
		},
		{
			unixtime: 1656618040,
			balance: 99584.02
		},
		{
			unixtime: 1656618100,
			balance: 99584.8
		},
		{
			unixtime: 1656618160,
			balance: 99565.5
		},
		{
			unixtime: 1656618220,
			balance: 99564.9
		},
		{
			unixtime: 1656618280,
			balance: 99564.06
		},
		{
			unixtime: 1656618340,
			balance: 99567.06
		},
		{
			unixtime: 1656618400,
			balance: 99577.06
		},
		{
			unixtime: 1656618460,
			balance: 99589.32
		},
		{
			unixtime: 1656618520,
			balance: 99579.38
		},
		{
			unixtime: 1656618580,
			balance: 99574.78
		},
		{
			unixtime: 1656618640,
			balance: 99585.92
		},
		{
			unixtime: 1656618700,
			balance: 99576.92
		},
		{
			unixtime: 1656618760,
			balance: 99550.66
		},
		{
			unixtime: 1656618820,
			balance: 99539.5
		},
		{
			unixtime: 1656618880,
			balance: 99540.5
		},
		{
			unixtime: 1656618940,
			balance: 99539.54
		},
		{
			unixtime: 1656619000,
			balance: 99553.08
		},
		{
			unixtime: 1656619060,
			balance: 99552.26
		},
		{
			unixtime: 1656619120,
			balance: 99563.64
		},
		{
			unixtime: 1656619180,
			balance: 99561.9
		},
		{
			unixtime: 1656619240,
			balance: 99569.1
		},
		{
			unixtime: 1656619300,
			balance: 99569.26
		},
		{
			unixtime: 1656619360,
			balance: 99566.9
		},
		{
			unixtime: 1656619420,
			balance: 99569
		},
		{
			unixtime: 1656619480,
			balance: 99567
		},
		{
			unixtime: 1656619540,
			balance: 99565.5
		},
		{
			unixtime: 1656619600,
			balance: 99563.18
		},
		{
			unixtime: 1656619660,
			balance: 99563.42
		},
		{
			unixtime: 1656619720,
			balance: 99561.08
		},
		{
			unixtime: 1656619780,
			balance: 99570.18
		},
		{
			unixtime: 1656619840,
			balance: 99566.2
		},
		{
			unixtime: 1656619900,
			balance: 99566
		},
		{
			unixtime: 1656619960,
			balance: 99567.86
		},
		{
			unixtime: 1656620020,
			balance: 99568.26
		},
		{
			unixtime: 1656620080,
			balance: 99564.4
		},
		{
			unixtime: 1656620140,
			balance: 99562.02
		},
		{
			unixtime: 1656620200,
			balance: 99572.52
		},
		{
			unixtime: 1656620260,
			balance: 99564.18
		},
		{
			unixtime: 1656620320,
			balance: 99564.46
		},
		{
			unixtime: 1656620380,
			balance: 99567.36
		},
		{
			unixtime: 1656620440,
			balance: 99563.16
		},
		{
			unixtime: 1656620500,
			balance: 99568.66
		},
		{
			unixtime: 1656620560,
			balance: 99568.48
		},
		{
			unixtime: 1656620620,
			balance: 99567.16
		},
		{
			unixtime: 1656620680,
			balance: 99566.96
		},
		{
			unixtime: 1656620740,
			balance: 99568.5
		},
		{
			unixtime: 1656620800,
			balance: 99570.2
		},
		{
			unixtime: 1656620860,
			balance: 99571.72
		},
		{
			unixtime: 1656620920,
			balance: 99570.34
		},
		{
			unixtime: 1656620980,
			balance: 99567.8
		},
		{
			unixtime: 1656621040,
			balance: 99566.06
		},
		{
			unixtime: 1656621100,
			balance: 99568.76
		},
		{
			unixtime: 1656621160,
			balance: 99567.46
		},
		{
			unixtime: 1656621220,
			balance: 99569.48
		},
		{
			unixtime: 1656621280,
			balance: 99570
		},
		{
			unixtime: 1656621340,
			balance: 99570
		},
		{
			unixtime: 1656621400,
			balance: 99569.7
		},
		{
			unixtime: 1656621460,
			balance: 99564
		},
		{
			unixtime: 1656621520,
			balance: 99564.8
		},
		{
			unixtime: 1656621580,
			balance: 99564
		},
		{
			unixtime: 1656621640,
			balance: 99564.6
		},
		{
			unixtime: 1656621700,
			balance: 99565.9
		},
		{
			unixtime: 1656621760,
			balance: 99567.16
		},
		{
			unixtime: 1656621820,
			balance: 99567.34
		},
		{
			unixtime: 1656621880,
			balance: 99568.04
		},
		{
			unixtime: 1656621940,
			balance: 99566.66
		},
		{
			unixtime: 1656622000,
			balance: 99568.66
		},
		{
			unixtime: 1656622060,
			balance: 99570.6
		},
		{
			unixtime: 1656622120,
			balance: 99570.5
		},
		{
			unixtime: 1656622180,
			balance: 99570.2
		},
		{
			unixtime: 1656622240,
			balance: 99570.36
		},
		{
			unixtime: 1656622300,
			balance: 99569.76
		},
		{
			unixtime: 1656622360,
			balance: 99567.86
		},
		{
			unixtime: 1656622420,
			balance: 99565.84
		},
		{
			unixtime: 1656622480,
			balance: 99565.34
		},
		{
			unixtime: 1656622540,
			balance: 99566.74
		},
		{
			unixtime: 1656622600,
			balance: 99566.5
		},
		{
			unixtime: 1656622660,
			balance: 99567.7
		},
		{
			unixtime: 1656622720,
			balance: 99568
		},
		{
			unixtime: 1656622780,
			balance: 99554.86
		},
		{
			unixtime: 1656622840,
			balance: 99553.68
		},
		{
			unixtime: 1656622900,
			balance: 99554.26
		},
		{
			unixtime: 1656622960,
			balance: 99555.04
		},
		{
			unixtime: 1656623020,
			balance: 99554.74
		},
		{
			unixtime: 1656623080,
			balance: 99554.54
		},
		{
			unixtime: 1656623140,
			balance: 99554.34
		},
		{
			unixtime: 1656623200,
			balance: 99561.34
		},
		{
			unixtime: 1656623260,
			balance: 99563.34
		},
		{
			unixtime: 1656623320,
			balance: 99563.44
		},
		{
			unixtime: 1656623380,
			balance: 99563.64
		},
		{
			unixtime: 1656623440,
			balance: 99563.74
		},
		{
			unixtime: 1656623500,
			balance: 99560.92
		},
		{
			unixtime: 1656623560,
			balance: 99560.66
		},
		{
			unixtime: 1656623620,
			balance: 99559.66
		},
		{
			unixtime: 1656623680,
			balance: 99558.96
		},
		{
			unixtime: 1656623740,
			balance: 99559.16
		},
		{
			unixtime: 1656623800,
			balance: 99559.26
		},
		{
			unixtime: 1656623860,
			balance: 99559.16
		},
		{
			unixtime: 1656623920,
			balance: 99557.16
		},
		{
			unixtime: 1656623980,
			balance: 99559.16
		},
		{
			unixtime: 1656624040,
			balance: 99559.16
		},
		{
			unixtime: 1656624100,
			balance: 99559.68
		},
		{
			unixtime: 1656624160,
			balance: 99559.9
		},
		{
			unixtime: 1656624220,
			balance: 99559.7
		},
		{
			unixtime: 1656624280,
			balance: 99558.9
		},
		{
			unixtime: 1656624340,
			balance: 99558.9
		},
		{
			unixtime: 1656624400,
			balance: 99559.64
		},
		{
			unixtime: 1656624460,
			balance: 99559.66
		},
		{
			unixtime: 1656624520,
			balance: 99559.76
		},
		{
			unixtime: 1656624580,
			balance: 99559.64
		},
		{
			unixtime: 1656624640,
			balance: 99559.6
		},
		{
			unixtime: 1656624700,
			balance: 99560.94
		},
		{
			unixtime: 1656624760,
			balance: 99560.04
		},
		{
			unixtime: 1656624820,
			balance: 99560.5
		},
		{
			unixtime: 1656624880,
			balance: 99560.58
		},
		{
			unixtime: 1656624940,
			balance: 99560.06
		},
		{
			unixtime: 1656625000,
			balance: 99559.06
		},
		{
			unixtime: 1656625060,
			balance: 99560.16
		},
		{
			unixtime: 1656625120,
			balance: 99558.3
		},
		{
			unixtime: 1656625180,
			balance: 99557.34
		},
		{
			unixtime: 1656625240,
			balance: 99559.96
		},
		{
			unixtime: 1656625300,
			balance: 99560.32
		},
		{
			unixtime: 1656625360,
			balance: 99555.66
		},
		{
			unixtime: 1656625420,
			balance: 99555.96
		},
		{
			unixtime: 1656625480,
			balance: 99556.16
		},
		{
			unixtime: 1656625540,
			balance: 99557.16
		},
		{
			unixtime: 1656625600,
			balance: 99557.16
		},
		{
			unixtime: 1656625660,
			balance: 99557.56
		},
		{
			unixtime: 1656625720,
			balance: 99557.56
		},
		{
			unixtime: 1656625780,
			balance: 99557.66
		},
		{
			unixtime: 1656625841,
			balance: 99559.76
		},
		{
			unixtime: 1656625900,
			balance: 99560.86
		},
		{
			unixtime: 1656625960,
			balance: 99561.16
		},
		{
			unixtime: 1656626020,
			balance: 99561.04
		},
		{
			unixtime: 1656626080,
			balance: 99559.66
		},
		{
			unixtime: 1656626140,
			balance: 99559.2
		},
		{
			unixtime: 1656626200,
			balance: 99567.16
		},
		{
			unixtime: 1656626260,
			balance: 99566.86
		},
		{
			unixtime: 1656626320,
			balance: 99566.28
		},
		{
			unixtime: 1656626380,
			balance: 99558.46
		},
		{
			unixtime: 1656626440,
			balance: 99558.56
		},
		{
			unixtime: 1656626500,
			balance: 99557.76
		},
		{
			unixtime: 1656626560,
			balance: 99558.36
		},
		{
			unixtime: 1656626620,
			balance: 99556.66
		},
		{
			unixtime: 1656626680,
			balance: 99551.16
		},
		{
			unixtime: 1656626740,
			balance: 99549.7
		},
		{
			unixtime: 1656626800,
			balance: 99548.7
		},
		{
			unixtime: 1656626860,
			balance: 99547.46
		},
		{
			unixtime: 1656626920,
			balance: 99545.78
		},
		{
			unixtime: 1656626980,
			balance: 99545.68
		},
		{
			unixtime: 1656627040,
			balance: 99544.76
		},
		{
			unixtime: 1656627100,
			balance: 99541.06
		},
		{
			unixtime: 1656627160,
			balance: 99542.76
		},
		{
			unixtime: 1656627220,
			balance: 99541.76
		},
		{
			unixtime: 1656627280,
			balance: 99541.56
		},
		{
			unixtime: 1656627340,
			balance: 99541.56
		},
		{
			unixtime: 1656627400,
			balance: 99537.98
		},
		{
			unixtime: 1656627460,
			balance: 99539.88
		},
		{
			unixtime: 1656627520,
			balance: 99541.1
		},
		{
			unixtime: 1656627580,
			balance: 99541
		},
		{
			unixtime: 1656627640,
			balance: 99551.5
		},
		{
			unixtime: 1656627700,
			balance: 99551.4
		},
		{
			unixtime: 1656627760,
			balance: 99551.86
		},
		{
			unixtime: 1656627820,
			balance: 99551.66
		},
		{
			unixtime: 1656627880,
			balance: 99552.26
		},
		{
			unixtime: 1656627941,
			balance: 99552.26
		},
		{
			unixtime: 1656628000,
			balance: 99552.26
		},
		{
			unixtime: 1656628060,
			balance: 99551
		},
		{
			unixtime: 1656628120,
			balance: 99548.66
		},
		{
			unixtime: 1656628180,
			balance: 99547.96
		},
		{
			unixtime: 1656628240,
			balance: 99567.78
		},
		{
			unixtime: 1656628300,
			balance: 99555.58
		},
		{
			unixtime: 1656628360,
			balance: 99556.66
		},
		{
			unixtime: 1656628420,
			balance: 99547.36
		},
		{
			unixtime: 1656628480,
			balance: 99548.36
		},
		{
			unixtime: 1656628540,
			balance: 99547.16
		},
		{
			unixtime: 1656628600,
			balance: 99547.16
		},
		{
			unixtime: 1656628660,
			balance: 99545.06
		},
		{
			unixtime: 1656628720,
			balance: 99545.06
		},
		{
			unixtime: 1656628780,
			balance: 99543.38
		},
		{
			unixtime: 1656628840,
			balance: 99542.86
		},
		{
			unixtime: 1656628900,
			balance: 99544.84
		},
		{
			unixtime: 1656628960,
			balance: 99545.34
		},
		{
			unixtime: 1656629020,
			balance: 99545.44
		},
		{
			unixtime: 1656629080,
			balance: 99547.34
		},
		{
			unixtime: 1656629140,
			balance: 99548.34
		},
		{
			unixtime: 1656629200,
			balance: 99548.34
		},
		{
			unixtime: 1656629260,
			balance: 99549.04
		},
		{
			unixtime: 1656629320,
			balance: 99548.74
		},
		{
			unixtime: 1656629380,
			balance: 99549.64
		},
		{
			unixtime: 1656629440,
			balance: 99549.14
		},
		{
			unixtime: 1656629500,
			balance: 99548.04
		},
		{
			unixtime: 1656629560,
			balance: 99548.04
		},
		{
			unixtime: 1656629620,
			balance: 99548.14
		},
		{
			unixtime: 1656629680,
			balance: 99551.26
		},
		{
			unixtime: 1656629740,
			balance: 99551.76
		},
		{
			unixtime: 1656629800,
			balance: 99551.3
		},
		{
			unixtime: 1656629860,
			balance: 99548.26
		},
		{
			unixtime: 1656629920,
			balance: 99547.46
		},
		{
			unixtime: 1656629980,
			balance: 99547.72
		},
		{
			unixtime: 1656630040,
			balance: 99555.88
		},
		{
			unixtime: 1656630100,
			balance: 99553.76
		},
		{
			unixtime: 1656630160,
			balance: 99553.76
		},
		{
			unixtime: 1656630220,
			balance: 99553.66
		},
		{
			unixtime: 1656630280,
			balance: 99551.96
		},
		{
			unixtime: 1656630340,
			balance: 99548.68
		},
		{
			unixtime: 1656630400,
			balance: 99545.46
		},
		{
			unixtime: 1656630460,
			balance: 99545.16
		},
		{
			unixtime: 1656630520,
			balance: 99544.66
		},
		{
			unixtime: 1656630580,
			balance: 99543.96
		},
		{
			unixtime: 1656630640,
			balance: 99544.76
		},
		{
			unixtime: 1656630700,
			balance: 99544.46
		},
		{
			unixtime: 1656630760,
			balance: 99544.86
		},
		{
			unixtime: 1656630821,
			balance: 99544.82
		},
		{
			unixtime: 1656630880,
			balance: 99545.54
		},
		{
			unixtime: 1656630940,
			balance: 99543.2
		},
		{
			unixtime: 1656631001,
			balance: 99543.26
		},
		{
			unixtime: 1656631060,
			balance: 99542.96
		},
		{
			unixtime: 1656631120,
			balance: 99543.7
		},
		{
			unixtime: 1656631181,
			balance: 99543.4
		},
		{
			unixtime: 1656631241,
			balance: 99542.66
		},
		{
			unixtime: 1656631300,
			balance: 99540.96
		},
		{
			unixtime: 1656631361,
			balance: 99541.22
		},
		{
			unixtime: 1656631421,
			balance: 99542.96
		},
		{
			unixtime: 1656631481,
			balance: 99550.26
		},
		{
			unixtime: 1656631541,
			balance: 99552.26
		},
		{
			unixtime: 1656631601,
			balance: 99555.82
		},
		{
			unixtime: 1656631660,
			balance: 99557.74
		},
		{
			unixtime: 1656631721,
			balance: 99555.96
		},
		{
			unixtime: 1656631781,
			balance: 99556.96
		},
		{
			unixtime: 1656631840,
			balance: 99558.66
		},
		{
			unixtime: 1656631901,
			balance: 99558.56
		},
		{
			unixtime: 1656631961,
			balance: 99558.36
		},
		{
			unixtime: 1656632020,
			balance: 99557.76
		},
		{
			unixtime: 1656632080,
			balance: 99558.66
		},
		{
			unixtime: 1656632140,
			balance: 99557.86
		},
		{
			unixtime: 1656632200,
			balance: 99557.4
		},
		{
			unixtime: 1656632261,
			balance: 99556.1
		},
		{
			unixtime: 1656632321,
			balance: 99557.1
		},
		{
			unixtime: 1656632380,
			balance: 99557.1
		},
		{
			unixtime: 1656632441,
			balance: 99559.02
		},
		{
			unixtime: 1656632501,
			balance: 99558.38
		},
		{
			unixtime: 1656632561,
			balance: 99559.56
		},
		{
			unixtime: 1656632620,
			balance: 99560.16
		},
		{
			unixtime: 1656632681,
			balance: 99560.16
		},
		{
			unixtime: 1656632741,
			balance: 99559.18
		},
		{
			unixtime: 1656632801,
			balance: 99559.68
		},
		{
			unixtime: 1656632860,
			balance: 99558.36
		},
		{
			unixtime: 1656632921,
			balance: 99564.36
		},
		{
			unixtime: 1656632981,
			balance: 99565.06
		},
		{
			unixtime: 1656633041,
			balance: 99567.82
		},
		{
			unixtime: 1656633101,
			balance: 99570.94
		},
		{
			unixtime: 1656633160,
			balance: 99573.32
		},
		{
			unixtime: 1656633220,
			balance: 99572.64
		},
		{
			unixtime: 1656633281,
			balance: 99571.24
		},
		{
			unixtime: 1656633341,
			balance: 99572.96
		},
		{
			unixtime: 1656633400,
			balance: 99572.5
		},
		{
			unixtime: 1656633460,
			balance: 99572.9
		},
		{
			unixtime: 1656633521,
			balance: 99574.06
		},
		{
			unixtime: 1656633581,
			balance: 99575.16
		},
		{
			unixtime: 1656633641,
			balance: 99574.26
		},
		{
			unixtime: 1656633701,
			balance: 99574.26
		},
		{
			unixtime: 1656633761,
			balance: 99574.26
		},
		{
			unixtime: 1656633821,
			balance: 99574.26
		},
		{
			unixtime: 1656633881,
			balance: 99574.26
		},
		{
			unixtime: 1656633941,
			balance: 99574.26
		},
		{
			unixtime: 1656634001,
			balance: 99574.26
		},
		{
			unixtime: 1656634061,
			balance: 99574.26
		},
		{
			unixtime: 1656634121,
			balance: 99574.26
		},
		{
			unixtime: 1656634181,
			balance: 99574.26
		},
		{
			unixtime: 1656634241,
			balance: 99574.26
		},
		{
			unixtime: 1656634301,
			balance: 99574.26
		},
		{
			unixtime: 1656634360,
			balance: 99574.26
		},
		{
			unixtime: 1656634421,
			balance: 99574.26
		},
		{
			unixtime: 1656634481,
			balance: 99574.26
		},
		{
			unixtime: 1656634541,
			balance: 99574.26
		},
		{
			unixtime: 1656634600,
			balance: 99574.26
		},
		{
			unixtime: 1656634661,
			balance: 99574.26
		},
		{
			unixtime: 1656634721,
			balance: 99574.26
		},
		{
			unixtime: 1656634781,
			balance: 99574.26
		},
		{
			unixtime: 1656634841,
			balance: 99574.26
		},
		{
			unixtime: 1656634901,
			balance: 99574.26
		},
		{
			unixtime: 1656634961,
			balance: 99574.26
		},
		{
			unixtime: 1656635021,
			balance: 99574.26
		},
		{
			unixtime: 1656635081,
			balance: 99574.26
		},
		{
			unixtime: 1656635141,
			balance: 99574.26
		},
		{
			unixtime: 1656635201,
			balance: 99574.26
		},
		{
			unixtime: 1656635261,
			balance: 99574.26
		},
		{
			unixtime: 1656635321,
			balance: 99574.26
		},
		{
			unixtime: 1656635381,
			balance: 99574.26
		},
		{
			unixtime: 1656635441,
			balance: 99574.26
		},
		{
			unixtime: 1656635501,
			balance: 99574.26
		},
		{
			unixtime: 1656635561,
			balance: 99574.26
		},
		{
			unixtime: 1656635621,
			balance: 99574.26
		},
		{
			unixtime: 1656635681,
			balance: 99574.26
		},
		{
			unixtime: 1656635741,
			balance: 99574.26
		},
		{
			unixtime: 1656635801,
			balance: 99574.26
		},
		{
			unixtime: 1656635861,
			balance: 99574.26
		},
		{
			unixtime: 1656635921,
			balance: 99574.26
		},
		{
			unixtime: 1656635981,
			balance: 99574.26
		},
		{
			unixtime: 1656636041,
			balance: 99574.26
		},
		{
			unixtime: 1656636101,
			balance: 99574.26
		},
		{
			unixtime: 1656636160,
			balance: 99574.26
		},
		{
			unixtime: 1656636221,
			balance: 99574.26
		},
		{
			unixtime: 1656636281,
			balance: 99574.26
		},
		{
			unixtime: 1656636341,
			balance: 99574.26
		},
		{
			unixtime: 1656636401,
			balance: 99574.26
		},
		{
			unixtime: 1656636461,
			balance: 99574.26
		},
		{
			unixtime: 1656636521,
			balance: 99574.26
		},
		{
			unixtime: 1656636581,
			balance: 99574.26
		},
		{
			unixtime: 1656636641,
			balance: 99574.26
		},
		{
			unixtime: 1656636701,
			balance: 99574.26
		},
		{
			unixtime: 1656636761,
			balance: 99574.26
		},
		{
			unixtime: 1656636821,
			balance: 99574.26
		},
		{
			unixtime: 1656636881,
			balance: 99574.26
		},
		{
			unixtime: 1656636941,
			balance: 99574.26
		},
		{
			unixtime: 1656637001,
			balance: 99574.26
		},
		{
			unixtime: 1656637061,
			balance: 99574.26
		},
		{
			unixtime: 1656637121,
			balance: 99574.26
		},
		{
			unixtime: 1656637181,
			balance: 99574.26
		},
		{
			unixtime: 1656637241,
			balance: 99574.26
		},
		{
			unixtime: 1656637301,
			balance: 99574.26
		},
		{
			unixtime: 1656637361,
			balance: 99574.26
		},
		{
			unixtime: 1656637421,
			balance: 99574.26
		},
		{
			unixtime: 1656637481,
			balance: 99574.26
		},
		{
			unixtime: 1656637541,
			balance: 99574.26
		},
		{
			unixtime: 1656637601,
			balance: 99574.26
		},
		{
			unixtime: 1656637661,
			balance: 99574.26
		},
		{
			unixtime: 1656637721,
			balance: 99574.26
		},
		{
			unixtime: 1656637781,
			balance: 99574.26
		},
		{
			unixtime: 1656637841,
			balance: 99574.26
		},
		{
			unixtime: 1656637901,
			balance: 99574.26
		},
		{
			unixtime: 1656637961,
			balance: 99574.26
		},
		{
			unixtime: 1656638021,
			balance: 99574.26
		},
		{
			unixtime: 1656638081,
			balance: 99574.26
		},
		{
			unixtime: 1656638141,
			balance: 99574.26
		},
		{
			unixtime: 1656638201,
			balance: 99574.26
		},
		{
			unixtime: 1656638261,
			balance: 99574.26
		},
		{
			unixtime: 1656638321,
			balance: 99574.26
		},
		{
			unixtime: 1656638381,
			balance: 99574.26
		},
		{
			unixtime: 1656638441,
			balance: 99574.26
		},
		{
			unixtime: 1656638501,
			balance: 99574.26
		},
		{
			unixtime: 1656638561,
			balance: 99574.26
		},
		{
			unixtime: 1656638621,
			balance: 99574.26
		},
		{
			unixtime: 1656638681,
			balance: 99574.26
		},
		{
			unixtime: 1656638741,
			balance: 99574.26
		},
		{
			unixtime: 1656638801,
			balance: 99574.26
		},
		{
			unixtime: 1656638861,
			balance: 99574.26
		},
		{
			unixtime: 1656638921,
			balance: 99574.26
		},
		{
			unixtime: 1656638981,
			balance: 99574.26
		},
		{
			unixtime: 1656639041,
			balance: 99574.26
		},
		{
			unixtime: 1656639101,
			balance: 99574.26
		},
		{
			unixtime: 1656639161,
			balance: 99574.26
		},
		{
			unixtime: 1656639221,
			balance: 99574.26
		},
		{
			unixtime: 1656639281,
			balance: 99574.26
		},
		{
			unixtime: 1656639341,
			balance: 99574.26
		},
		{
			unixtime: 1656639401,
			balance: 99574.26
		},
		{
			unixtime: 1656639461,
			balance: 99574.26
		},
		{
			unixtime: 1656639521,
			balance: 99574.26
		},
		{
			unixtime: 1656639581,
			balance: 99574.26
		},
		{
			unixtime: 1656639641,
			balance: 99574.26
		},
		{
			unixtime: 1656639701,
			balance: 99574.26
		},
		{
			unixtime: 1656639761,
			balance: 99574.26
		},
		{
			unixtime: 1656639821,
			balance: 99574.26
		},
		{
			unixtime: 1656639881,
			balance: 99574.26
		},
		{
			unixtime: 1656639941,
			balance: 99574.26
		},
		{
			unixtime: 1656640001,
			balance: 99574.26
		},
		{
			unixtime: 1656640061,
			balance: 99574.26
		},
		{
			unixtime: 1656640121,
			balance: 99574.26
		},
		{
			unixtime: 1656640181,
			balance: 99574.26
		},
		{
			unixtime: 1656640241,
			balance: 99574.26
		},
		{
			unixtime: 1656640301,
			balance: 99574.26
		},
		{
			unixtime: 1656640361,
			balance: 99574.26
		},
		{
			unixtime: 1656640421,
			balance: 99574.26
		},
		{
			unixtime: 1656640481,
			balance: 99574.26
		},
		{
			unixtime: 1656640541,
			balance: 99574.26
		},
		{
			unixtime: 1656640601,
			balance: 99574.26
		},
		{
			unixtime: 1656640661,
			balance: 99574.26
		},
		{
			unixtime: 1656640721,
			balance: 99574.26
		},
		{
			unixtime: 1656640781,
			balance: 99574.26
		},
		{
			unixtime: 1656640841,
			balance: 99574.26
		},
		{
			unixtime: 1656640901,
			balance: 99574.26
		},
		{
			unixtime: 1656640961,
			balance: 99574.26
		},
		{
			unixtime: 1656641021,
			balance: 99574.26
		},
		{
			unixtime: 1656641081,
			balance: 99574.26
		},
		{
			unixtime: 1656641141,
			balance: 99574.26
		},
		{
			unixtime: 1656641201,
			balance: 99574.26
		},
		{
			unixtime: 1656641261,
			balance: 99574.26
		},
		{
			unixtime: 1656641321,
			balance: 99574.26
		},
		{
			unixtime: 1656641381,
			balance: 99574.26
		},
		{
			unixtime: 1656641441,
			balance: 99574.26
		},
		{
			unixtime: 1656641501,
			balance: 99574.26
		},
		{
			unixtime: 1656641561,
			balance: 99574.26
		},
		{
			unixtime: 1656641621,
			balance: 99574.26
		},
		{
			unixtime: 1656641681,
			balance: 99574.26
		},
		{
			unixtime: 1656641741,
			balance: 99574.26
		},
		{
			unixtime: 1656641801,
			balance: 99574.26
		},
		{
			unixtime: 1656641861,
			balance: 99574.26
		},
		{
			unixtime: 1656641921,
			balance: 99574.26
		},
		{
			unixtime: 1656641981,
			balance: 99574.26
		},
		{
			unixtime: 1656642041,
			balance: 99574.26
		},
		{
			unixtime: 1656642101,
			balance: 99574.26
		},
		{
			unixtime: 1656642161,
			balance: 99574.26
		},
		{
			unixtime: 1656642221,
			balance: 99574.26
		},
		{
			unixtime: 1656642281,
			balance: 99574.26
		},
		{
			unixtime: 1656642341,
			balance: 99574.26
		},
		{
			unixtime: 1656642401,
			balance: 99574.26
		},
		{
			unixtime: 1656642461,
			balance: 99574.26
		},
		{
			unixtime: 1656642521,
			balance: 99574.26
		},
		{
			unixtime: 1656642581,
			balance: 99574.26
		},
		{
			unixtime: 1656642641,
			balance: 99574.26
		},
		{
			unixtime: 1656642701,
			balance: 99574.26
		},
		{
			unixtime: 1656642761,
			balance: 99574.26
		},
		{
			unixtime: 1656642821,
			balance: 99574.26
		},
		{
			unixtime: 1656642881,
			balance: 99574.26
		},
		{
			unixtime: 1656642941,
			balance: 99574.26
		},
		{
			unixtime: 1656643001,
			balance: 99574.26
		},
		{
			unixtime: 1656643061,
			balance: 99574.26
		},
		{
			unixtime: 1656643121,
			balance: 99574.26
		},
		{
			unixtime: 1656643181,
			balance: 99574.26
		},
		{
			unixtime: 1656643241,
			balance: 99574.26
		},
		{
			unixtime: 1656643301,
			balance: 99574.26
		},
		{
			unixtime: 1656643361,
			balance: 99574.26
		},
		{
			unixtime: 1656643421,
			balance: 99574.26
		},
		{
			unixtime: 1656643481,
			balance: 99574.26
		},
		{
			unixtime: 1656643541,
			balance: 99574.26
		},
		{
			unixtime: 1656643601,
			balance: 99574.26
		},
		{
			unixtime: 1656643661,
			balance: 99574.26
		},
		{
			unixtime: 1656643721,
			balance: 99574.26
		},
		{
			unixtime: 1656643781,
			balance: 99574.26
		},
		{
			unixtime: 1656643841,
			balance: 99574.26
		},
		{
			unixtime: 1656643901,
			balance: 99574.26
		},
		{
			unixtime: 1656643961,
			balance: 99574.26
		},
		{
			unixtime: 1656644021,
			balance: 99574.26
		},
		{
			unixtime: 1656644081,
			balance: 99574.26
		},
		{
			unixtime: 1656644141,
			balance: 99574.26
		},
		{
			unixtime: 1656644201,
			balance: 99574.26
		},
		{
			unixtime: 1656644261,
			balance: 99574.26
		},
		{
			unixtime: 1656644321,
			balance: 99574.26
		},
		{
			unixtime: 1656644381,
			balance: 99574.26
		},
		{
			unixtime: 1656644441,
			balance: 99574.26
		},
		{
			unixtime: 1656644501,
			balance: 99574.26
		},
		{
			unixtime: 1656644561,
			balance: 99574.26
		},
		{
			unixtime: 1656644621,
			balance: 99574.26
		},
		{
			unixtime: 1656644681,
			balance: 99574.26
		},
		{
			unixtime: 1656644741,
			balance: 99574.26
		},
		{
			unixtime: 1656644801,
			balance: 99574.26
		},
		{
			unixtime: 1656644861,
			balance: 99574.26
		},
		{
			unixtime: 1656644921,
			balance: 99574.26
		},
		{
			unixtime: 1656644981,
			balance: 99574.26
		},
		{
			unixtime: 1656645041,
			balance: 99574.26
		},
		{
			unixtime: 1656645101,
			balance: 99574.26
		},
		{
			unixtime: 1656645161,
			balance: 99574.26
		},
		{
			unixtime: 1656645221,
			balance: 99574.26
		},
		{
			unixtime: 1656645281,
			balance: 99574.26
		},
		{
			unixtime: 1656645341,
			balance: 99574.26
		},
		{
			unixtime: 1656645401,
			balance: 99574.26
		},
		{
			unixtime: 1656645461,
			balance: 99574.26
		},
		{
			unixtime: 1656645521,
			balance: 99574.26
		},
		{
			unixtime: 1656645581,
			balance: 99574.26
		},
		{
			unixtime: 1656645641,
			balance: 99574.26
		},
		{
			unixtime: 1656645701,
			balance: 99574.26
		},
		{
			unixtime: 1656645761,
			balance: 99574.26
		},
		{
			unixtime: 1656645821,
			balance: 99574.26
		},
		{
			unixtime: 1656645881,
			balance: 99574.26
		},
		{
			unixtime: 1656645941,
			balance: 99574.26
		},
		{
			unixtime: 1656646001,
			balance: 99574.26
		},
		{
			unixtime: 1656646061,
			balance: 99574.26
		},
		{
			unixtime: 1656646121,
			balance: 99574.26
		},
		{
			unixtime: 1656646181,
			balance: 99574.26
		},
		{
			unixtime: 1656646241,
			balance: 99574.26
		},
		{
			unixtime: 1656646301,
			balance: 99574.26
		},
		{
			unixtime: 1656646361,
			balance: 99574.26
		},
		{
			unixtime: 1656646421,
			balance: 99574.26
		},
		{
			unixtime: 1656646481,
			balance: 99574.26
		},
		{
			unixtime: 1656646541,
			balance: 99574.26
		},
		{
			unixtime: 1656646601,
			balance: 99574.26
		},
		{
			unixtime: 1656646661,
			balance: 99574.26
		},
		{
			unixtime: 1656646721,
			balance: 99574.26
		},
		{
			unixtime: 1656646781,
			balance: 99574.26
		},
		{
			unixtime: 1656646841,
			balance: 99574.26
		},
		{
			unixtime: 1656646901,
			balance: 99574.26
		},
		{
			unixtime: 1656646961,
			balance: 99574.26
		},
		{
			unixtime: 1656647021,
			balance: 99574.26
		},
		{
			unixtime: 1656647081,
			balance: 99574.26
		},
		{
			unixtime: 1656647141,
			balance: 99574.26
		},
		{
			unixtime: 1656647201,
			balance: 99574.26
		},
		{
			unixtime: 1656647261,
			balance: 99574.26
		},
		{
			unixtime: 1656647321,
			balance: 99574.26
		},
		{
			unixtime: 1656647381,
			balance: 99574.26
		},
		{
			unixtime: 1656647441,
			balance: 99574.26
		},
		{
			unixtime: 1656647501,
			balance: 99574.26
		},
		{
			unixtime: 1656647561,
			balance: 99574.26
		},
		{
			unixtime: 1656647621,
			balance: 99574.26
		},
		{
			unixtime: 1656647681,
			balance: 99574.26
		},
		{
			unixtime: 1656647741,
			balance: 99574.26
		},
		{
			unixtime: 1656647801,
			balance: 99574.26
		},
		{
			unixtime: 1656647861,
			balance: 99574.26
		},
		{
			unixtime: 1656647921,
			balance: 99574.26
		},
		{
			unixtime: 1656647981,
			balance: 99574.26
		},
		{
			unixtime: 1656648041,
			balance: 99574.26
		},
		{
			unixtime: 1656648101,
			balance: 99574.26
		},
		{
			unixtime: 1656648161,
			balance: 99574.26
		},
		{
			unixtime: 1656648221,
			balance: 99574.26
		},
		{
			unixtime: 1656648281,
			balance: 99574.26
		},
		{
			unixtime: 1656648341,
			balance: 99574.26
		},
		{
			unixtime: 1656648401,
			balance: 99574.26
		},
		{
			unixtime: 1656648461,
			balance: 99574.26
		},
		{
			unixtime: 1656648521,
			balance: 99574.26
		},
		{
			unixtime: 1656648581,
			balance: 99574.26
		},
		{
			unixtime: 1656648641,
			balance: 99574.26
		},
		{
			unixtime: 1656648701,
			balance: 99574.26
		},
		{
			unixtime: 1656648761,
			balance: 99574.26
		},
		{
			unixtime: 1656648821,
			balance: 99574.26
		},
		{
			unixtime: 1656648881,
			balance: 99574.26
		},
		{
			unixtime: 1656648941,
			balance: 99574.26
		},
		{
			unixtime: 1656649001,
			balance: 99574.26
		},
		{
			unixtime: 1656649061,
			balance: 99574.26
		},
		{
			unixtime: 1656649121,
			balance: 99574.26
		},
		{
			unixtime: 1656649181,
			balance: 99574.26
		},
		{
			unixtime: 1656649241,
			balance: 99574.26
		},
		{
			unixtime: 1656649301,
			balance: 99574.26
		},
		{
			unixtime: 1656649361,
			balance: 99574.26
		},
		{
			unixtime: 1656649421,
			balance: 99574.26
		},
		{
			unixtime: 1656649481,
			balance: 99574.26
		},
		{
			unixtime: 1656649541,
			balance: 99574.26
		},
		{
			unixtime: 1656649601,
			balance: 99574.26
		},
		{
			unixtime: 1656649661,
			balance: 99574.26
		},
		{
			unixtime: 1656649721,
			balance: 99574.26
		},
		{
			unixtime: 1656649781,
			balance: 99574.26
		},
		{
			unixtime: 1656649841,
			balance: 99574.26
		},
		{
			unixtime: 1656649901,
			balance: 99574.26
		},
		{
			unixtime: 1656649961,
			balance: 99574.26
		},
		{
			unixtime: 1656650021,
			balance: 99574.26
		},
		{
			unixtime: 1656650081,
			balance: 99574.26
		},
		{
			unixtime: 1656650141,
			balance: 99574.26
		},
		{
			unixtime: 1656650201,
			balance: 99574.26
		},
		{
			unixtime: 1656650261,
			balance: 99574.26
		},
		{
			unixtime: 1656650321,
			balance: 99574.26
		},
		{
			unixtime: 1656650381,
			balance: 99574.26
		},
		{
			unixtime: 1656650441,
			balance: 99574.26
		},
		{
			unixtime: 1656650501,
			balance: 99574.26
		},
		{
			unixtime: 1656650561,
			balance: 99574.26
		},
		{
			unixtime: 1656650621,
			balance: 99574.26
		},
		{
			unixtime: 1656650681,
			balance: 99574.26
		},
		{
			unixtime: 1656650741,
			balance: 99574.26
		},
		{
			unixtime: 1656650801,
			balance: 99574.26
		},
		{
			unixtime: 1656650861,
			balance: 99574.26
		},
		{
			unixtime: 1656650921,
			balance: 99574.26
		},
		{
			unixtime: 1656650981,
			balance: 99574.26
		},
		{
			unixtime: 1656651041,
			balance: 99574.26
		},
		{
			unixtime: 1656651101,
			balance: 99574.26
		},
		{
			unixtime: 1656651161,
			balance: 99574.26
		},
		{
			unixtime: 1656651221,
			balance: 99574.26
		},
		{
			unixtime: 1656651281,
			balance: 99574.26
		},
		{
			unixtime: 1656651341,
			balance: 99574.26
		},
		{
			unixtime: 1656651401,
			balance: 99574.26
		},
		{
			unixtime: 1656651461,
			balance: 99574.26
		},
		{
			unixtime: 1656651521,
			balance: 99574.26
		},
		{
			unixtime: 1656651581,
			balance: 99574.26
		},
		{
			unixtime: 1656651641,
			balance: 99574.26
		},
		{
			unixtime: 1656651701,
			balance: 99574.26
		},
		{
			unixtime: 1656651761,
			balance: 99574.26
		},
		{
			unixtime: 1656651821,
			balance: 99574.26
		},
		{
			unixtime: 1656651881,
			balance: 99574.26
		},
		{
			unixtime: 1656651941,
			balance: 99574.26
		},
		{
			unixtime: 1656652001,
			balance: 99574.26
		},
		{
			unixtime: 1656652061,
			balance: 99574.26
		},
		{
			unixtime: 1656652121,
			balance: 99574.26
		},
		{
			unixtime: 1656652181,
			balance: 99574.26
		},
		{
			unixtime: 1656652241,
			balance: 99574.26
		},
		{
			unixtime: 1656652301,
			balance: 99574.26
		},
		{
			unixtime: 1656652361,
			balance: 99574.26
		},
		{
			unixtime: 1656652421,
			balance: 99574.26
		},
		{
			unixtime: 1656652481,
			balance: 99574.26
		},
		{
			unixtime: 1656652541,
			balance: 99574.26
		},
		{
			unixtime: 1656652601,
			balance: 99574.26
		},
		{
			unixtime: 1656652661,
			balance: 99574.26
		},
		{
			unixtime: 1656652721,
			balance: 99574.26
		},
		{
			unixtime: 1656652781,
			balance: 99574.26
		},
		{
			unixtime: 1656652841,
			balance: 99574.26
		},
		{
			unixtime: 1656652901,
			balance: 99574.26
		},
		{
			unixtime: 1656652961,
			balance: 99574.26
		},
		{
			unixtime: 1656653021,
			balance: 99574.26
		},
		{
			unixtime: 1656653081,
			balance: 99574.26
		},
		{
			unixtime: 1656653141,
			balance: 99574.26
		},
		{
			unixtime: 1656653201,
			balance: 99574.26
		},
		{
			unixtime: 1656653261,
			balance: 99574.26
		},
		{
			unixtime: 1656653321,
			balance: 99574.26
		},
		{
			unixtime: 1656653381,
			balance: 99574.26
		},
		{
			unixtime: 1656653441,
			balance: 99574.26
		},
		{
			unixtime: 1656653501,
			balance: 99574.26
		},
		{
			unixtime: 1656653561,
			balance: 99574.26
		},
		{
			unixtime: 1656653621,
			balance: 99574.26
		},
		{
			unixtime: 1656653681,
			balance: 99574.26
		},
		{
			unixtime: 1656653741,
			balance: 99574.26
		},
		{
			unixtime: 1656653801,
			balance: 99574.26
		},
		{
			unixtime: 1656653861,
			balance: 99574.26
		},
		{
			unixtime: 1656653921,
			balance: 99574.26
		},
		{
			unixtime: 1656653981,
			balance: 99574.26
		},
		{
			unixtime: 1656654041,
			balance: 99574.26
		},
		{
			unixtime: 1656654101,
			balance: 99574.26
		},
		{
			unixtime: 1656654161,
			balance: 99574.26
		},
		{
			unixtime: 1656654221,
			balance: 99574.26
		},
		{
			unixtime: 1656654281,
			balance: 99574.26
		},
		{
			unixtime: 1656654341,
			balance: 99574.26
		},
		{
			unixtime: 1656654401,
			balance: 99574.26
		},
		{
			unixtime: 1656654461,
			balance: 99574.26
		},
		{
			unixtime: 1656654521,
			balance: 99574.26
		},
		{
			unixtime: 1656654581,
			balance: 99574.26
		},
		{
			unixtime: 1656654641,
			balance: 99574.26
		},
		{
			unixtime: 1656654701,
			balance: 99574.26
		},
		{
			unixtime: 1656654761,
			balance: 99574.26
		},
		{
			unixtime: 1656654821,
			balance: 99574.26
		},
		{
			unixtime: 1656654881,
			balance: 99574.26
		},
		{
			unixtime: 1656654941,
			balance: 99574.26
		},
		{
			unixtime: 1656655001,
			balance: 99574.26
		},
		{
			unixtime: 1656655061,
			balance: 99574.26
		},
		{
			unixtime: 1656655121,
			balance: 99574.26
		},
		{
			unixtime: 1656655181,
			balance: 99574.26
		},
		{
			unixtime: 1656655241,
			balance: 99574.26
		},
		{
			unixtime: 1656655301,
			balance: 99574.26
		},
		{
			unixtime: 1656655361,
			balance: 99574.26
		},
		{
			unixtime: 1656655421,
			balance: 99574.26
		},
		{
			unixtime: 1656655481,
			balance: 99574.26
		},
		{
			unixtime: 1656655541,
			balance: 99574.26
		},
		{
			unixtime: 1656655601,
			balance: 99574.26
		},
		{
			unixtime: 1656655661,
			balance: 99574.26
		},
		{
			unixtime: 1656655721,
			balance: 99574.26
		},
		{
			unixtime: 1656655781,
			balance: 99574.26
		},
		{
			unixtime: 1656655841,
			balance: 99574.26
		},
		{
			unixtime: 1656655901,
			balance: 99574.26
		},
		{
			unixtime: 1656655961,
			balance: 99574.26
		},
		{
			unixtime: 1656656021,
			balance: 99574.26
		},
		{
			unixtime: 1656656081,
			balance: 99574.26
		},
		{
			unixtime: 1656656141,
			balance: 99574.26
		},
		{
			unixtime: 1656656201,
			balance: 99574.26
		},
		{
			unixtime: 1656656261,
			balance: 99574.26
		},
		{
			unixtime: 1656656321,
			balance: 99574.26
		},
		{
			unixtime: 1656656381,
			balance: 99574.26
		},
		{
			unixtime: 1656656441,
			balance: 99574.26
		},
		{
			unixtime: 1656656501,
			balance: 99574.26
		},
		{
			unixtime: 1656656561,
			balance: 99574.26
		},
		{
			unixtime: 1656656621,
			balance: 99574.26
		},
		{
			unixtime: 1656656681,
			balance: 99574.26
		},
		{
			unixtime: 1656656741,
			balance: 99574.26
		},
		{
			unixtime: 1656656801,
			balance: 99574.26
		},
		{
			unixtime: 1656656861,
			balance: 99574.26
		},
		{
			unixtime: 1656656921,
			balance: 99574.26
		},
		{
			unixtime: 1656656981,
			balance: 99574.26
		},
		{
			unixtime: 1656657041,
			balance: 99574.26
		},
		{
			unixtime: 1656657101,
			balance: 99574.26
		},
		{
			unixtime: 1656657161,
			balance: 99574.26
		},
		{
			unixtime: 1656657221,
			balance: 99574.26
		},
		{
			unixtime: 1656657281,
			balance: 99574.26
		},
		{
			unixtime: 1656657341,
			balance: 99574.26
		},
		{
			unixtime: 1656657401,
			balance: 99574.26
		},
		{
			unixtime: 1656657461,
			balance: 99574.26
		},
		{
			unixtime: 1656657521,
			balance: 99574.26
		},
		{
			unixtime: 1656657581,
			balance: 99574.26
		},
		{
			unixtime: 1656657641,
			balance: 99574.26
		},
		{
			unixtime: 1656657701,
			balance: 99574.26
		},
		{
			unixtime: 1656657761,
			balance: 99574.26
		},
		{
			unixtime: 1656657821,
			balance: 99574.26
		},
		{
			unixtime: 1656657881,
			balance: 99574.26
		},
		{
			unixtime: 1656657941,
			balance: 99574.26
		},
		{
			unixtime: 1656658001,
			balance: 99574.26
		},
		{
			unixtime: 1656658061,
			balance: 99574.26
		},
		{
			unixtime: 1656658121,
			balance: 99574.26
		},
		{
			unixtime: 1656658181,
			balance: 99574.26
		},
		{
			unixtime: 1656658241,
			balance: 99574.26
		},
		{
			unixtime: 1656658301,
			balance: 99574.26
		},
		{
			unixtime: 1656658361,
			balance: 99574.26
		},
		{
			unixtime: 1656658421,
			balance: 99574.26
		},
		{
			unixtime: 1656658481,
			balance: 99574.26
		},
		{
			unixtime: 1656658541,
			balance: 99574.26
		},
		{
			unixtime: 1656658601,
			balance: 99574.26
		},
		{
			unixtime: 1656658661,
			balance: 99574.26
		},
		{
			unixtime: 1656658721,
			balance: 99574.26
		},
		{
			unixtime: 1656658781,
			balance: 99574.26
		},
		{
			unixtime: 1656658841,
			balance: 99574.26
		},
		{
			unixtime: 1656658901,
			balance: 99574.26
		},
		{
			unixtime: 1656658961,
			balance: 99574.26
		},
		{
			unixtime: 1656659021,
			balance: 99574.26
		},
		{
			unixtime: 1656659081,
			balance: 99574.26
		},
		{
			unixtime: 1656659141,
			balance: 99574.26
		},
		{
			unixtime: 1656659201,
			balance: 99574.26
		},
		{
			unixtime: 1656659261,
			balance: 99574.26
		},
		{
			unixtime: 1656659321,
			balance: 99574.26
		},
		{
			unixtime: 1656659381,
			balance: 99574.26
		},
		{
			unixtime: 1656659441,
			balance: 99574.26
		},
		{
			unixtime: 1656659501,
			balance: 99574.26
		},
		{
			unixtime: 1656659561,
			balance: 99574.26
		},
		{
			unixtime: 1656659621,
			balance: 99574.26
		},
		{
			unixtime: 1656659681,
			balance: 99574.26
		},
		{
			unixtime: 1656659741,
			balance: 99574.26
		},
		{
			unixtime: 1656659801,
			balance: 99574.26
		},
		{
			unixtime: 1656659861,
			balance: 99574.26
		},
		{
			unixtime: 1656659921,
			balance: 99574.26
		},
		{
			unixtime: 1656659981,
			balance: 99574.26
		},
		{
			unixtime: 1656660041,
			balance: 99574.26
		},
		{
			unixtime: 1656660101,
			balance: 99574.26
		},
		{
			unixtime: 1656660161,
			balance: 99574.26
		},
		{
			unixtime: 1656660221,
			balance: 99574.26
		},
		{
			unixtime: 1656660281,
			balance: 99574.26
		},
		{
			unixtime: 1656660341,
			balance: 99574.26
		},
		{
			unixtime: 1656660401,
			balance: 99574.26
		},
		{
			unixtime: 1656660461,
			balance: 99574.26
		},
		{
			unixtime: 1656660521,
			balance: 99574.26
		},
		{
			unixtime: 1656660581,
			balance: 99574.26
		},
		{
			unixtime: 1656660641,
			balance: 99574.26
		},
		{
			unixtime: 1656660701,
			balance: 99574.26
		},
		{
			unixtime: 1656660761,
			balance: 99574.26
		},
		{
			unixtime: 1656660821,
			balance: 99574.26
		},
		{
			unixtime: 1656660881,
			balance: 99574.26
		},
		{
			unixtime: 1656660941,
			balance: 99574.26
		},
		{
			unixtime: 1656661001,
			balance: 99574.26
		},
		{
			unixtime: 1656661061,
			balance: 99574.26
		},
		{
			unixtime: 1656661121,
			balance: 99574.26
		},
		{
			unixtime: 1656661181,
			balance: 99574.26
		},
		{
			unixtime: 1656661241,
			balance: 99574.26
		},
		{
			unixtime: 1656661301,
			balance: 99574.26
		},
		{
			unixtime: 1656661361,
			balance: 99574.26
		},
		{
			unixtime: 1656661421,
			balance: 99574.26
		},
		{
			unixtime: 1656661481,
			balance: 99574.26
		},
		{
			unixtime: 1656661541,
			balance: 99574.26
		},
		{
			unixtime: 1656661601,
			balance: 99574.26
		},
		{
			unixtime: 1656661661,
			balance: 99574.26
		},
		{
			unixtime: 1656661721,
			balance: 99574.26
		},
		{
			unixtime: 1656661781,
			balance: 99574.26
		},
		{
			unixtime: 1656661841,
			balance: 99574.26
		},
		{
			unixtime: 1656661901,
			balance: 99574.26
		},
		{
			unixtime: 1656661961,
			balance: 99574.26
		},
		{
			unixtime: 1656662021,
			balance: 99574.26
		},
		{
			unixtime: 1656662081,
			balance: 99574.26
		},
		{
			unixtime: 1656662141,
			balance: 99574.26
		},
		{
			unixtime: 1656662201,
			balance: 99574.26
		},
		{
			unixtime: 1656662261,
			balance: 99574.26
		},
		{
			unixtime: 1656662321,
			balance: 99574.26
		},
		{
			unixtime: 1656662381,
			balance: 99574.26
		},
		{
			unixtime: 1656662441,
			balance: 99527.2
		},
		{
			unixtime: 1656662501,
			balance: 99525.26
		},
		{
			unixtime: 1656662561,
			balance: 99524.56
		},
		{
			unixtime: 1656662621,
			balance: 99501.56
		},
		{
			unixtime: 1656662681,
			balance: 99503.46
		},
		{
			unixtime: 1656662741,
			balance: 99503.76
		},
		{
			unixtime: 1656662801,
			balance: 99505.06
		},
		{
			unixtime: 1656662861,
			balance: 99504.56
		},
		{
			unixtime: 1656662921,
			balance: 99500.26
		},
		{
			unixtime: 1656662981,
			balance: 99501.76
		},
		{
			unixtime: 1656663041,
			balance: 99507.4
		},
		{
			unixtime: 1656663101,
			balance: 99508.06
		},
		{
			unixtime: 1656663161,
			balance: 99509.26
		},
		{
			unixtime: 1656663221,
			balance: 99509.76
		},
		{
			unixtime: 1656663281,
			balance: 99533.44
		},
		{
			unixtime: 1656663341,
			balance: 99534.94
		},
		{
			unixtime: 1656663401,
			balance: 99533.04
		},
		{
			unixtime: 1656663461,
			balance: 99534.94
		},
		{
			unixtime: 1656663521,
			balance: 99537.16
		},
		{
			unixtime: 1656663581,
			balance: 99535.26
		},
		{
			unixtime: 1656663641,
			balance: 99536.24
		},
		{
			unixtime: 1656663701,
			balance: 99539.64
		},
		{
			unixtime: 1656663761,
			balance: 99550.22
		},
		{
			unixtime: 1656663821,
			balance: 99552.7
		},
		{
			unixtime: 1656663881,
			balance: 99553.86
		},
		{
			unixtime: 1656663941,
			balance: 99547.66
		},
		{
			unixtime: 1656664001,
			balance: 99547.2
		},
		{
			unixtime: 1656664061,
			balance: 99548.2
		},
		{
			unixtime: 1656664121,
			balance: 99548.8
		},
		{
			unixtime: 1656664181,
			balance: 99551.74
		},
		{
			unixtime: 1656664241,
			balance: 99550.96
		},
		{
			unixtime: 1656664301,
			balance: 99551.56
		},
		{
			unixtime: 1656664361,
			balance: 99549.96
		},
		{
			unixtime: 1656664421,
			balance: 99550.5
		},
		{
			unixtime: 1656664481,
			balance: 99549.5
		},
		{
			unixtime: 1656664541,
			balance: 99550.9
		},
		{
			unixtime: 1656664601,
			balance: 99547.86
		},
		{
			unixtime: 1656664661,
			balance: 99547.86
		},
		{
			unixtime: 1656664721,
			balance: 99548.86
		},
		{
			unixtime: 1656664781,
			balance: 99552.66
		},
		{
			unixtime: 1656664841,
			balance: 99551.66
		},
		{
			unixtime: 1656664901,
			balance: 99551.46
		},
		{
			unixtime: 1656664961,
			balance: 99549.86
		},
		{
			unixtime: 1656665021,
			balance: 99549.86
		},
		{
			unixtime: 1656665081,
			balance: 99549.96
		},
		{
			unixtime: 1656665141,
			balance: 99548.26
		},
		{
			unixtime: 1656665201,
			balance: 99548.36
		},
		{
			unixtime: 1656665261,
			balance: 99549.76
		},
		{
			unixtime: 1656665321,
			balance: 99554
		},
		{
			unixtime: 1656665381,
			balance: 99556.14
		},
		{
			unixtime: 1656665441,
			balance: 99556.5
		},
		{
			unixtime: 1656665501,
			balance: 99556.5
		},
		{
			unixtime: 1656665561,
			balance: 99556.86
		},
		{
			unixtime: 1656665621,
			balance: 99556.86
		},
		{
			unixtime: 1656665681,
			balance: 99556.86
		},
		{
			unixtime: 1656665741,
			balance: 99556.04
		},
		{
			unixtime: 1656665801,
			balance: 99554.16
		},
		{
			unixtime: 1656665861,
			balance: 99558.04
		},
		{
			unixtime: 1656665921,
			balance: 99560.04
		},
		{
			unixtime: 1656665981,
			balance: 99559.44
		},
		{
			unixtime: 1656666041,
			balance: 99557.74
		},
		{
			unixtime: 1656666101,
			balance: 99555.44
		},
		{
			unixtime: 1656666161,
			balance: 99553.66
		},
		{
			unixtime: 1656666221,
			balance: 99555.06
		},
		{
			unixtime: 1656666281,
			balance: 99554.86
		},
		{
			unixtime: 1656666341,
			balance: 99556.26
		},
		{
			unixtime: 1656666401,
			balance: 99560.16
		},
		{
			unixtime: 1656666461,
			balance: 99565.12
		},
		{
			unixtime: 1656666521,
			balance: 99564.92
		},
		{
			unixtime: 1656666581,
			balance: 99566.92
		},
		{
			unixtime: 1656666641,
			balance: 99567.96
		},
		{
			unixtime: 1656666701,
			balance: 99566.26
		},
		{
			unixtime: 1656666761,
			balance: 99565.06
		},
		{
			unixtime: 1656666821,
			balance: 99565.76
		},
		{
			unixtime: 1656666881,
			balance: 99565.06
		},
		{
			unixtime: 1656666941,
			balance: 99564.9
		},
		{
			unixtime: 1656667001,
			balance: 99565.9
		},
		{
			unixtime: 1656667061,
			balance: 99565.6
		},
		{
			unixtime: 1656667121,
			balance: 99565.46
		},
		{
			unixtime: 1656667181,
			balance: 99564.58
		},
		{
			unixtime: 1656667241,
			balance: 99564.98
		},
		{
			unixtime: 1656667301,
			balance: 99564.28
		},
		{
			unixtime: 1656667361,
			balance: 99565.58
		},
		{
			unixtime: 1656667421,
			balance: 99565.88
		},
		{
			unixtime: 1656667481,
			balance: 99566.88
		},
		{
			unixtime: 1656667541,
			balance: 99566.68
		},
		{
			unixtime: 1656667601,
			balance: 99564.78
		},
		{
			unixtime: 1656667661,
			balance: 99563.38
		},
		{
			unixtime: 1656667721,
			balance: 99563
		},
		{
			unixtime: 1656667781,
			balance: 99563
		},
		{
			unixtime: 1656667841,
			balance: 99562.6
		},
		{
			unixtime: 1656667901,
			balance: 99563.8
		},
		{
			unixtime: 1656667961,
			balance: 99580.8
		},
		{
			unixtime: 1656668021,
			balance: 99580.2
		},
		{
			unixtime: 1656668081,
			balance: 99580.5
		},
		{
			unixtime: 1656668141,
			balance: 99582.48
		},
		{
			unixtime: 1656668201,
			balance: 99582.48
		},
		{
			unixtime: 1656668261,
			balance: 99583.48
		},
		{
			unixtime: 1656668321,
			balance: 99584.46
		},
		{
			unixtime: 1656668381,
			balance: 99583.76
		},
		{
			unixtime: 1656668441,
			balance: 99580.56
		},
		{
			unixtime: 1656668501,
			balance: 99579.92
		},
		{
			unixtime: 1656668561,
			balance: 99580.82
		},
		{
			unixtime: 1656668621,
			balance: 99579.92
		},
		{
			unixtime: 1656668681,
			balance: 99579.92
		},
		{
			unixtime: 1656668741,
			balance: 99577.58
		},
		{
			unixtime: 1656668801,
			balance: 99578.38
		},
		{
			unixtime: 1656668861,
			balance: 99579.68
		},
		{
			unixtime: 1656668921,
			balance: 99580.88
		},
		{
			unixtime: 1656668981,
			balance: 99574.98
		},
		{
			unixtime: 1656669041,
			balance: 99578.34
		},
		{
			unixtime: 1656669101,
			balance: 99578.76
		},
		{
			unixtime: 1656669161,
			balance: 99578.66
		},
		{
			unixtime: 1656669221,
			balance: 99580.02
		},
		{
			unixtime: 1656669281,
			balance: 99582.76
		},
		{
			unixtime: 1656669341,
			balance: 99582.36
		},
		{
			unixtime: 1656669401,
			balance: 99580.86
		},
		{
			unixtime: 1656669461,
			balance: 99581.36
		},
		{
			unixtime: 1656669521,
			balance: 99581.36
		},
		{
			unixtime: 1656669581,
			balance: 99581.26
		},
		{
			unixtime: 1656669641,
			balance: 99582.56
		},
		{
			unixtime: 1656669701,
			balance: 99582.16
		},
		{
			unixtime: 1656669761,
			balance: 99579.36
		},
		{
			unixtime: 1656669821,
			balance: 99578.36
		},
		{
			unixtime: 1656669881,
			balance: 99578.42
		},
		{
			unixtime: 1656669941,
			balance: 99577.92
		},
		{
			unixtime: 1656670001,
			balance: 99575.66
		},
		{
			unixtime: 1656670061,
			balance: 99574.26
		},
		{
			unixtime: 1656670121,
			balance: 99566.86
		},
		{
			unixtime: 1656670181,
			balance: 99566.96
		},
		{
			unixtime: 1656670241,
			balance: 99566.96
		},
		{
			unixtime: 1656670301,
			balance: 99566.96
		},
		{
			unixtime: 1656670361,
			balance: 99565.96
		},
		{
			unixtime: 1656670421,
			balance: 99565.96
		},
		{
			unixtime: 1656670481,
			balance: 99567.66
		},
		{
			unixtime: 1656670541,
			balance: 99568.48
		},
		{
			unixtime: 1656670601,
			balance: 99570.78
		},
		{
			unixtime: 1656670661,
			balance: 99570.78
		},
		{
			unixtime: 1656670721,
			balance: 99572.98
		},
		{
			unixtime: 1656670781,
			balance: 99573.16
		},
		{
			unixtime: 1656670841,
			balance: 99573.96
		},
		{
			unixtime: 1656670901,
			balance: 99573.26
		},
		{
			unixtime: 1656670961,
			balance: 99575.58
		},
		{
			unixtime: 1656671021,
			balance: 99575.58
		},
		{
			unixtime: 1656671081,
			balance: 99575.96
		},
		{
			unixtime: 1656671141,
			balance: 99575.56
		},
		{
			unixtime: 1656671201,
			balance: 99574.06
		},
		{
			unixtime: 1656671261,
			balance: 99574.16
		},
		{
			unixtime: 1656671321,
			balance: 99573.24
		},
		{
			unixtime: 1656671381,
			balance: 99574.06
		},
		{
			unixtime: 1656671441,
			balance: 99574.06
		},
		{
			unixtime: 1656671501,
			balance: 99570.96
		},
		{
			unixtime: 1656671561,
			balance: 99570.76
		},
		{
			unixtime: 1656671621,
			balance: 99569.86
		},
		{
			unixtime: 1656671681,
			balance: 99569.86
		},
		{
			unixtime: 1656671741,
			balance: 99570.06
		},
		{
			unixtime: 1656671801,
			balance: 99568.76
		},
		{
			unixtime: 1656671861,
			balance: 99567.68
		},
		{
			unixtime: 1656671921,
			balance: 99568.66
		},
		{
			unixtime: 1656671981,
			balance: 99568.66
		},
		{
			unixtime: 1656672041,
			balance: 99562.78
		},
		{
			unixtime: 1656672101,
			balance: 99563.66
		},
		{
			unixtime: 1656672161,
			balance: 99564.56
		},
		{
			unixtime: 1656672221,
			balance: 99564.56
		},
		{
			unixtime: 1656672281,
			balance: 99563.76
		},
		{
			unixtime: 1656672341,
			balance: 99563.84
		},
		{
			unixtime: 1656672401,
			balance: 99563.84
		},
		{
			unixtime: 1656672461,
			balance: 99560.56
		},
		{
			unixtime: 1656672521,
			balance: 99559.96
		},
		{
			unixtime: 1656672581,
			balance: 99562.46
		},
		{
			unixtime: 1656672641,
			balance: 99561.86
		},
		{
			unixtime: 1656672701,
			balance: 99562.46
		},
		{
			unixtime: 1656672761,
			balance: 99563.62
		},
		{
			unixtime: 1656672821,
			balance: 99562.82
		},
		{
			unixtime: 1656672881,
			balance: 99551.86
		},
		{
			unixtime: 1656672941,
			balance: 99551.1
		},
		{
			unixtime: 1656673001,
			balance: 99549.66
		},
		{
			unixtime: 1656673061,
			balance: 99553.56
		},
		{
			unixtime: 1656673121,
			balance: 99551.36
		},
		{
			unixtime: 1656673181,
			balance: 99549.36
		},
		{
			unixtime: 1656673241,
			balance: 99562.14
		},
		{
			unixtime: 1656673301,
			balance: 99565.44
		},
		{
			unixtime: 1656673361,
			balance: 99561.56
		},
		{
			unixtime: 1656673421,
			balance: 99554.82
		},
		{
			unixtime: 1656673481,
			balance: 99558.2
		},
		{
			unixtime: 1656673541,
			balance: 99554.36
		},
		{
			unixtime: 1656673601,
			balance: 99554.8
		},
		{
			unixtime: 1656673661,
			balance: 99556.7
		},
		{
			unixtime: 1656673721,
			balance: 99557.2
		},
		{
			unixtime: 1656673781,
			balance: 99556.1
		},
		{
			unixtime: 1656673841,
			balance: 99556.9
		},
		{
			unixtime: 1656673901,
			balance: 99556.9
		},
		{
			unixtime: 1656673961,
			balance: 99551.16
		},
		{
			unixtime: 1656674021,
			balance: 99550.28
		},
		{
			unixtime: 1656674081,
			balance: 99549.38
		},
		{
			unixtime: 1656674141,
			balance: 99548.9
		},
		{
			unixtime: 1656674201,
			balance: 99545.9
		},
		{
			unixtime: 1656674261,
			balance: 99551
		},
		{
			unixtime: 1656674321,
			balance: 99552
		},
		{
			unixtime: 1656674381,
			balance: 99553.26
		},
		{
			unixtime: 1656674441,
			balance: 99553.4
		},
		{
			unixtime: 1656674501,
			balance: 99551.9
		},
		{
			unixtime: 1656674561,
			balance: 99552.4
		},
		{
			unixtime: 1656674621,
			balance: 99552.4
		},
		{
			unixtime: 1656674681,
			balance: 99552.26
		},
		{
			unixtime: 1656674741,
			balance: 99553.26
		},
		{
			unixtime: 1656674801,
			balance: 99557.26
		},
		{
			unixtime: 1656674861,
			balance: 99557.16
		},
		{
			unixtime: 1656674921,
			balance: 99559.86
		},
		{
			unixtime: 1656674981,
			balance: 99557.36
		},
		{
			unixtime: 1656675041,
			balance: 99556.98
		},
		{
			unixtime: 1656675101,
			balance: 99556.46
		},
		{
			unixtime: 1656675161,
			balance: 99556.66
		},
		{
			unixtime: 1656675221,
			balance: 99556.26
		},
		{
			unixtime: 1656675281,
			balance: 99555.16
		},
		{
			unixtime: 1656675341,
			balance: 99555.16
		},
		{
			unixtime: 1656675401,
			balance: 99554.36
		},
		{
			unixtime: 1656675461,
			balance: 99554.76
		},
		{
			unixtime: 1656675521,
			balance: 99556.96
		},
		{
			unixtime: 1656675581,
			balance: 99556.24
		},
		{
			unixtime: 1656675641,
			balance: 99551.02
		},
		{
			unixtime: 1656675701,
			balance: 99546.32
		},
		{
			unixtime: 1656675761,
			balance: 99546.28
		},
		{
			unixtime: 1656675821,
			balance: 99546.48
		},
		{
			unixtime: 1656675881,
			balance: 99545.78
		},
		{
			unixtime: 1656675941,
			balance: 99544.68
		},
		{
			unixtime: 1656676001,
			balance: 99542.04
		},
		{
			unixtime: 1656676061,
			balance: 99536.7
		},
		{
			unixtime: 1656676121,
			balance: 99538.82
		},
		{
			unixtime: 1656676181,
			balance: 99535.26
		},
		{
			unixtime: 1656676241,
			balance: 99537.1
		},
		{
			unixtime: 1656676301,
			balance: 99534.34
		},
		{
			unixtime: 1656676361,
			balance: 99535.24
		},
		{
			unixtime: 1656676421,
			balance: 99535.24
		},
		{
			unixtime: 1656676481,
			balance: 99538.2
		},
		{
			unixtime: 1656676541,
			balance: 99537.7
		},
		{
			unixtime: 1656676601,
			balance: 99535.24
		},
		{
			unixtime: 1656676661,
			balance: 99532.16
		},
		{
			unixtime: 1656676721,
			balance: 99530.32
		},
		{
			unixtime: 1656676781,
			balance: 99535.92
		},
		{
			unixtime: 1656676841,
			balance: 99531.2
		},
		{
			unixtime: 1656676901,
			balance: 99536.16
		},
		{
			unixtime: 1656676961,
			balance: 99537.86
		},
		{
			unixtime: 1656677021,
			balance: 99543.16
		},
		{
			unixtime: 1656677081,
			balance: 99549.28
		},
		{
			unixtime: 1656677141,
			balance: 99550.88
		},
		{
			unixtime: 1656677201,
			balance: 99550.3
		},
		{
			unixtime: 1656677261,
			balance: 99549.46
		},
		{
			unixtime: 1656677321,
			balance: 99548.06
		},
		{
			unixtime: 1656677381,
			balance: 99539.86
		},
		{
			unixtime: 1656677441,
			balance: 99539.8
		},
		{
			unixtime: 1656677501,
			balance: 99541.46
		},
		{
			unixtime: 1656677561,
			balance: 99545.86
		},
		{
			unixtime: 1656677621,
			balance: 99546.56
		},
		{
			unixtime: 1656677681,
			balance: 99549.38
		},
		{
			unixtime: 1656677741,
			balance: 99548.6
		},
		{
			unixtime: 1656677801,
			balance: 99547.06
		},
		{
			unixtime: 1656677861,
			balance: 99548.66
		},
		{
			unixtime: 1656677921,
			balance: 99540.86
		},
		{
			unixtime: 1656677981,
			balance: 99540.66
		},
		{
			unixtime: 1656678041,
			balance: 99540.06
		},
		{
			unixtime: 1656678101,
			balance: 99543.46
		},
		{
			unixtime: 1656678161,
			balance: 99544.76
		},
		{
			unixtime: 1656678221,
			balance: 99546.06
		},
		{
			unixtime: 1656678281,
			balance: 99545.76
		},
		{
			unixtime: 1656678341,
			balance: 99543.32
		},
		{
			unixtime: 1656678401,
			balance: 99542.58
		},
		{
			unixtime: 1656678461,
			balance: 99545.18
		},
		{
			unixtime: 1656678521,
			balance: 99546
		},
		{
			unixtime: 1656678581,
			balance: 99546.44
		},
		{
			unixtime: 1656678641,
			balance: 99538.86
		},
		{
			unixtime: 1656678701,
			balance: 99545.86
		},
		{
			unixtime: 1656678761,
			balance: 99548.16
		},
		{
			unixtime: 1656678821,
			balance: 99559.06
		},
		{
			unixtime: 1656678881,
			balance: 99564.48
		},
		{
			unixtime: 1656678941,
			balance: 99565.7
		},
		{
			unixtime: 1656679001,
			balance: 99562.96
		},
		{
			unixtime: 1656679061,
			balance: 99558.66
		},
		{
			unixtime: 1656679121,
			balance: 99557.2
		},
		{
			unixtime: 1656679181,
			balance: 99554.26
		},
		{
			unixtime: 1656679241,
			balance: 99554.16
		},
		{
			unixtime: 1656679301,
			balance: 99554.16
		},
		{
			unixtime: 1656679361,
			balance: 99553.96
		},
		{
			unixtime: 1656679421,
			balance: 99556.42
		},
		{
			unixtime: 1656679481,
			balance: 99557.48
		},
		{
			unixtime: 1656679541,
			balance: 99556.48
		},
		{
			unixtime: 1656679601,
			balance: 99558.52
		},
		{
			unixtime: 1656679661,
			balance: 99566.56
		},
		{
			unixtime: 1656679721,
			balance: 99562.36
		},
		{
			unixtime: 1656679781,
			balance: 99561.92
		},
		{
			unixtime: 1656679841,
			balance: 99564.04
		},
		{
			unixtime: 1656679901,
			balance: 99560.86
		},
		{
			unixtime: 1656679961,
			balance: 99559.16
		},
		{
			unixtime: 1656680021,
			balance: 99562.24
		},
		{
			unixtime: 1656680081,
			balance: 99558.02
		},
		{
			unixtime: 1656680141,
			balance: 99552.76
		},
		{
			unixtime: 1656680201,
			balance: 99556.14
		},
		{
			unixtime: 1656680261,
			balance: 99556.16
		},
		{
			unixtime: 1656680321,
			balance: 99558.76
		},
		{
			unixtime: 1656680381,
			balance: 99556.32
		},
		{
			unixtime: 1656680441,
			balance: 99567.06
		},
		{
			unixtime: 1656680501,
			balance: 99569.26
		},
		{
			unixtime: 1656680561,
			balance: 99569.4
		},
		{
			unixtime: 1656680621,
			balance: 99570.86
		},
		{
			unixtime: 1656680681,
			balance: 99565.4
		},
		{
			unixtime: 1656680741,
			balance: 99561.72
		},
		{
			unixtime: 1656680801,
			balance: 99550.2
		},
		{
			unixtime: 1656680861,
			balance: 99546.6
		},
		{
			unixtime: 1656680921,
			balance: 99552.7
		},
		{
			unixtime: 1656680981,
			balance: 99552.9
		},
		{
			unixtime: 1656681041,
			balance: 99553.56
		},
		{
			unixtime: 1656681101,
			balance: 99562.4
		},
		{
			unixtime: 1656681161,
			balance: 99563.86
		},
		{
			unixtime: 1656681221,
			balance: 99562.08
		},
		{
			unixtime: 1656681281,
			balance: 99561.88
		},
		{
			unixtime: 1656681341,
			balance: 99558.86
		},
		{
			unixtime: 1656681499,
			balance: 99560.96
		},
		{
			unixtime: 1656681559,
			balance: 99562.6
		},
		{
			unixtime: 1656681619,
			balance: 99564.46
		},
		{
			unixtime: 1656681679,
			balance: 99565.66
		},
		{
			unixtime: 1656681739,
			balance: 99566.26
		},
		{
			unixtime: 1656681799,
			balance: 99568.06
		},
		{
			unixtime: 1656681859,
			balance: 99568.36
		},
		{
			unixtime: 1656681919,
			balance: 99564.98
		},
		{
			unixtime: 1656681979,
			balance: 99561.3
		},
		{
			unixtime: 1656682039,
			balance: 99564.3
		},
		{
			unixtime: 1656682099,
			balance: 99572.54
		},
		{
			unixtime: 1656682159,
			balance: 99579
		},
		{
			unixtime: 1656682219,
			balance: 99592.48
		},
		{
			unixtime: 1656682279,
			balance: 99586.08
		},
		{
			unixtime: 1656682339,
			balance: 99561.34
		},
		{
			unixtime: 1656682399,
			balance: 99585.28
		},
		{
			unixtime: 1656682459,
			balance: 99570.4
		},
		{
			unixtime: 1656682519,
			balance: 99564.38
		},
		{
			unixtime: 1656682579,
			balance: 99549.06
		},
		{
			unixtime: 1656682639,
			balance: 99580.36
		},
		{
			unixtime: 1656682699,
			balance: 99571.92
		},
		{
			unixtime: 1656682759,
			balance: 99575.12
		},
		{
			unixtime: 1656682819,
			balance: 99581.44
		},
		{
			unixtime: 1656682879,
			balance: 99602.92
		},
		{
			unixtime: 1656682939,
			balance: 99614.74
		},
		{
			unixtime: 1656682999,
			balance: 99619.32
		},
		{
			unixtime: 1656683059,
			balance: 99618.78
		},
		{
			unixtime: 1656683119,
			balance: 99616.76
		},
		{
			unixtime: 1656683179,
			balance: 99639.1
		},
		{
			unixtime: 1656683239,
			balance: 99652.72
		},
		{
			unixtime: 1656683299,
			balance: 99650.04
		},
		{
			unixtime: 1656683359,
			balance: 99656.72
		},
		{
			unixtime: 1656683419,
			balance: 99653.06
		},
		{
			unixtime: 1656683479,
			balance: 99639.2
		},
		{
			unixtime: 1656683539,
			balance: 99646.06
		},
		{
			unixtime: 1656683599,
			balance: 99661.62
		},
		{
			unixtime: 1656683659,
			balance: 99660.98
		},
		{
			unixtime: 1656683719,
			balance: 99658.14
		},
		{
			unixtime: 1656683779,
			balance: 99651.06
		},
		{
			unixtime: 1656683839,
			balance: 99660.2
		},
		{
			unixtime: 1656683899,
			balance: 99651.22
		},
		{
			unixtime: 1656683959,
			balance: 99632.14
		},
		{
			unixtime: 1656684019,
			balance: 99649.6
		},
		{
			unixtime: 1656684079,
			balance: 99643.42
		},
		{
			unixtime: 1656684139,
			balance: 99637.44
		},
		{
			unixtime: 1656684199,
			balance: 99627.06
		},
		{
			unixtime: 1656684259,
			balance: 99594.46
		},
		{
			unixtime: 1656684319,
			balance: 99610.36
		},
		{
			unixtime: 1656684379,
			balance: 99596.74
		},
		{
			unixtime: 1656684439,
			balance: 99601.66
		},
		{
			unixtime: 1656684499,
			balance: 99606.66
		},
		{
			unixtime: 1656684559,
			balance: 99586.36
		},
		{
			unixtime: 1656684619,
			balance: 99603.16
		},
		{
			unixtime: 1656684679,
			balance: 99587.58
		},
		{
			unixtime: 1656684739,
			balance: 99557.62
		},
		{
			unixtime: 1656684799,
			balance: 99556.74
		},
		{
			unixtime: 1656684859,
			balance: 99562.08
		},
		{
			unixtime: 1656684919,
			balance: 99552.48
		},
		{
			unixtime: 1656684979,
			balance: 99566.94
		},
		{
			unixtime: 1656685039,
			balance: 99552.66
		},
		{
			unixtime: 1656685099,
			balance: 99540.84
		},
		{
			unixtime: 1656685159,
			balance: 99550.36
		},
		{
			unixtime: 1656685219,
			balance: 99551.18
		},
		{
			unixtime: 1656685279,
			balance: 99536.46
		},
		{
			unixtime: 1656685339,
			balance: 99547.34
		},
		{
			unixtime: 1656685399,
			balance: 99541.08
		},
		{
			unixtime: 1656685459,
			balance: 99552.52
		},
		{
			unixtime: 1656685519,
			balance: 99535.72
		},
		{
			unixtime: 1656685579,
			balance: 99521.8
		},
		{
			unixtime: 1656685639,
			balance: 99526.1
		},
		{
			unixtime: 1656685699,
			balance: 99527.56
		},
		{
			unixtime: 1656685759,
			balance: 99527.36
		},
		{
			unixtime: 1656685819,
			balance: 99526.78
		},
		{
			unixtime: 1656685879,
			balance: 99515.6
		},
		{
			unixtime: 1656685939,
			balance: 99507.22
		},
		{
			unixtime: 1656685999,
			balance: 99512.82
		},
		{
			unixtime: 1656686059,
			balance: 99508.54
		},
		{
			unixtime: 1656686119,
			balance: 99507.02
		},
		{
			unixtime: 1656686179,
			balance: 99496.86
		},
		{
			unixtime: 1656686239,
			balance: 99486.66
		},
		{
			unixtime: 1656686299,
			balance: 99486.94
		},
		{
			unixtime: 1656686359,
			balance: 99481.8
		},
		{
			unixtime: 1656686419,
			balance: 99481.76
		},
		{
			unixtime: 1656686479,
			balance: 99494.2
		},
		{
			unixtime: 1656686539,
			balance: 99505.46
		},
		{
			unixtime: 1656686599,
			balance: 99499.46
		},
		{
			unixtime: 1656686659,
			balance: 99498.86
		},
		{
			unixtime: 1656686719,
			balance: 99511.46
		},
		{
			unixtime: 1656686779,
			balance: 99502.82
		},
		{
			unixtime: 1656686839,
			balance: 99524.44
		},
		{
			unixtime: 1656686899,
			balance: 99535.06
		},
		{
			unixtime: 1656686959,
			balance: 99523.5
		},
		{
			unixtime: 1656687019,
			balance: 99513.92
		},
		{
			unixtime: 1656687079,
			balance: 99503.92
		},
		{
			unixtime: 1656687139,
			balance: 99503.62
		},
		{
			unixtime: 1656687199,
			balance: 99516.54
		},
		{
			unixtime: 1656687259,
			balance: 99519.4
		},
		{
			unixtime: 1656687319,
			balance: 99510.82
		},
		{
			unixtime: 1656687379,
			balance: 99519
		},
		{
			unixtime: 1656687439,
			balance: 99531.12
		},
		{
			unixtime: 1656687499,
			balance: 99527.56
		},
		{
			unixtime: 1656687559,
			balance: 99524.08
		},
		{
			unixtime: 1656687619,
			balance: 99513.68
		},
		{
			unixtime: 1656687679,
			balance: 99526.12
		},
		{
			unixtime: 1656687739,
			balance: 99531.12
		},
		{
			unixtime: 1656687799,
			balance: 99519.38
		},
		{
			unixtime: 1656687859,
			balance: 99510.92
		},
		{
			unixtime: 1656687919,
			balance: 99518.74
		},
		{
			unixtime: 1656687979,
			balance: 99530.16
		},
		{
			unixtime: 1656688039,
			balance: 99534.16
		},
		{
			unixtime: 1656688099,
			balance: 99540.44
		},
		{
			unixtime: 1656688159,
			balance: 99549.62
		},
		{
			unixtime: 1656688219,
			balance: 99550.24
		},
		{
			unixtime: 1656688279,
			balance: 99546.04
		},
		{
			unixtime: 1656688339,
			balance: 99557.2
		},
		{
			unixtime: 1656688399,
			balance: 99555.86
		},
		{
			unixtime: 1656688459,
			balance: 99554.8
		},
		{
			unixtime: 1656688519,
			balance: 99555.4
		},
		{
			unixtime: 1656688579,
			balance: 99562.1
		},
		{
			unixtime: 1656688639,
			balance: 99573.56
		},
		{
			unixtime: 1656688699,
			balance: 99574.92
		},
		{
			unixtime: 1656688759,
			balance: 99562.32
		},
		{
			unixtime: 1656688819,
			balance: 99563.96
		},
		{
			unixtime: 1656688879,
			balance: 99560.24
		},
		{
			unixtime: 1656688939,
			balance: 99561.64
		},
		{
			unixtime: 1656688999,
			balance: 99560.2
		},
		{
			unixtime: 1656689059,
			balance: 99550.28
		},
		{
			unixtime: 1656689119,
			balance: 99551.36
		},
		{
			unixtime: 1656689179,
			balance: 99548.1
		},
		{
			unixtime: 1656689239,
			balance: 99546.12
		},
		{
			unixtime: 1656689299,
			balance: 99551.76
		},
		{
			unixtime: 1656689359,
			balance: 99549.74
		},
		{
			unixtime: 1656689419,
			balance: 99554.08
		},
		{
			unixtime: 1656689479,
			balance: 99565.7
		},
		{
			unixtime: 1656689539,
			balance: 99565.26
		},
		{
			unixtime: 1656689599,
			balance: 99562.8
		},
		{
			unixtime: 1656689659,
			balance: 99563.12
		},
		{
			unixtime: 1656689719,
			balance: 99575.3
		},
		{
			unixtime: 1656689779,
			balance: 99574.82
		},
		{
			unixtime: 1656689839,
			balance: 99566.92
		},
		{
			unixtime: 1656689899,
			balance: 99574.32
		},
		{
			unixtime: 1656689959,
			balance: 99568.96
		},
		{
			unixtime: 1656690019,
			balance: 99574.96
		},
		{
			unixtime: 1656690079,
			balance: 99580.88
		},
		{
			unixtime: 1656690139,
			balance: 99580.92
		},
		{
			unixtime: 1656690199,
			balance: 99575.48
		},
		{
			unixtime: 1656690259,
			balance: 99572.46
		},
		{
			unixtime: 1656690319,
			balance: 99569.3
		},
		{
			unixtime: 1656690379,
			balance: 99565.7
		},
		{
			unixtime: 1656690439,
			balance: 99573.14
		},
		{
			unixtime: 1656690499,
			balance: 99576.5
		},
		{
			unixtime: 1656690559,
			balance: 99570.98
		},
		{
			unixtime: 1656690619,
			balance: 99568.1
		},
		{
			unixtime: 1656690679,
			balance: 99554.7
		},
		{
			unixtime: 1656690739,
			balance: 99550.98
		},
		{
			unixtime: 1656690799,
			balance: 99541.64
		},
		{
			unixtime: 1656690859,
			balance: 99536.58
		},
		{
			unixtime: 1656690919,
			balance: 99534.96
		},
		{
			unixtime: 1656690979,
			balance: 99538.1
		},
		{
			unixtime: 1656691039,
			balance: 99535.54
		},
		{
			unixtime: 1656691099,
			balance: 99534.2
		},
		{
			unixtime: 1656691159,
			balance: 99529.34
		},
		{
			unixtime: 1656691219,
			balance: 99530.08
		},
		{
			unixtime: 1656691279,
			balance: 99530.18
		},
		{
			unixtime: 1656691339,
			balance: 99525.86
		},
		{
			unixtime: 1656691399,
			balance: 99523.9
		},
		{
			unixtime: 1656691459,
			balance: 99528.18
		},
		{
			unixtime: 1656691519,
			balance: 99533.82
		},
		{
			unixtime: 1656691579,
			balance: 99539.9
		},
		{
			unixtime: 1656691639,
			balance: 99536.1
		},
		{
			unixtime: 1656691699,
			balance: 99539.92
		},
		{
			unixtime: 1656691759,
			balance: 99546.16
		},
		{
			unixtime: 1656691819,
			balance: 99542.54
		},
		{
			unixtime: 1656691879,
			balance: 99537.9
		},
		{
			unixtime: 1656691939,
			balance: 99537.98
		},
		{
			unixtime: 1656691999,
			balance: 99538.6
		},
		{
			unixtime: 1656692059,
			balance: 99544.14
		},
		{
			unixtime: 1656692119,
			balance: 99540.16
		},
		{
			unixtime: 1656692179,
			balance: 99554.92
		},
		{
			unixtime: 1656692239,
			balance: 99560.22
		},
		{
			unixtime: 1656692299,
			balance: 99558.66
		},
		{
			unixtime: 1656692359,
			balance: 99565.9
		},
		{
			unixtime: 1656692419,
			balance: 99567.82
		},
		{
			unixtime: 1656692479,
			balance: 99573.68
		},
		{
			unixtime: 1656692539,
			balance: 99572.36
		},
		{
			unixtime: 1656692599,
			balance: 99577.12
		},
		{
			unixtime: 1656692659,
			balance: 99579.22
		},
		{
			unixtime: 1656692719,
			balance: 99582.58
		},
		{
			unixtime: 1656692779,
			balance: 99579.46
		},
		{
			unixtime: 1656692839,
			balance: 99582.8
		},
		{
			unixtime: 1656692899,
			balance: 99579.2
		},
		{
			unixtime: 1656692960,
			balance: 99584.84
		},
		{
			unixtime: 1656693019,
			balance: 99583.06
		},
		{
			unixtime: 1656693079,
			balance: 99584.32
		},
		{
			unixtime: 1656693140,
			balance: 99584.88
		},
		{
			unixtime: 1656693199,
			balance: 99574.46
		},
		{
			unixtime: 1656693259,
			balance: 99563.86
		},
		{
			unixtime: 1656693320,
			balance: 99561.34
		},
		{
			unixtime: 1656693380,
			balance: 99568.98
		},
		{
			unixtime: 1656693440,
			balance: 99564.26
		},
		{
			unixtime: 1656693499,
			balance: 99558.56
		},
		{
			unixtime: 1656693559,
			balance: 99556.8
		},
		{
			unixtime: 1656693620,
			balance: 99556.38
		},
		{
			unixtime: 1656693680,
			balance: 99556.56
		},
		{
			unixtime: 1656693740,
			balance: 99554.46
		},
		{
			unixtime: 1656693800,
			balance: 99555.18
		},
		{
			unixtime: 1656693860,
			balance: 99550.66
		},
		{
			unixtime: 1656693920,
			balance: 99546.36
		},
		{
			unixtime: 1656693980,
			balance: 99547.08
		},
		{
			unixtime: 1656694040,
			balance: 99546.86
		},
		{
			unixtime: 1656694100,
			balance: 99545.94
		},
		{
			unixtime: 1656694160,
			balance: 99552.46
		},
		{
			unixtime: 1656694220,
			balance: 99554.38
		},
		{
			unixtime: 1656694280,
			balance: 99560.46
		},
		{
			unixtime: 1656694340,
			balance: 99563.96
		},
		{
			unixtime: 1656694400,
			balance: 99567.2
		},
		{
			unixtime: 1656694460,
			balance: 99566.96
		},
		{
			unixtime: 1656694520,
			balance: 99568.96
		},
		{
			unixtime: 1656694580,
			balance: 99562.68
		},
		{
			unixtime: 1656694640,
			balance: 99553.32
		},
		{
			unixtime: 1656694700,
			balance: 99550.22
		},
		{
			unixtime: 1656694760,
			balance: 99548.2
		},
		{
			unixtime: 1656694820,
			balance: 99544.66
		},
		{
			unixtime: 1656694880,
			balance: 99547.02
		},
		{
			unixtime: 1656694940,
			balance: 99549.72
		},
		{
			unixtime: 1656695000,
			balance: 99541.02
		},
		{
			unixtime: 1656695060,
			balance: 99545.42
		},
		{
			unixtime: 1656695120,
			balance: 99542.98
		},
		{
			unixtime: 1656695180,
			balance: 99543.28
		},
		{
			unixtime: 1656695240,
			balance: 99540.96
		},
		{
			unixtime: 1656695300,
			balance: 99542.58
		},
		{
			unixtime: 1656695360,
			balance: 99546.84
		},
		{
			unixtime: 1656695420,
			balance: 99548.48
		},
		{
			unixtime: 1656695480,
			balance: 99548.84
		},
		{
			unixtime: 1656695540,
			balance: 99550.38
		},
		{
			unixtime: 1656695600,
			balance: 99549.84
		},
		{
			unixtime: 1656695660,
			balance: 99552.8
		},
		{
			unixtime: 1656695720,
			balance: 99557.86
		},
		{
			unixtime: 1656695780,
			balance: 99557.06
		},
		{
			unixtime: 1656695840,
			balance: 99553.6
		},
		{
			unixtime: 1656695900,
			balance: 99545.92
		},
		{
			unixtime: 1656695960,
			balance: 99556.12
		},
		{
			unixtime: 1656696020,
			balance: 99557.78
		},
		{
			unixtime: 1656696080,
			balance: 99557.92
		},
		{
			unixtime: 1656696140,
			balance: 99556.08
		},
		{
			unixtime: 1656696200,
			balance: 99552.64
		},
		{
			unixtime: 1656696260,
			balance: 99557.28
		},
		{
			unixtime: 1656696320,
			balance: 99558.6
		},
		{
			unixtime: 1656696380,
			balance: 99558.44
		},
		{
			unixtime: 1656696440,
			balance: 99561.62
		},
		{
			unixtime: 1656696500,
			balance: 99566.5
		},
		{
			unixtime: 1656696560,
			balance: 99566.46
		},
		{
			unixtime: 1656696620,
			balance: 99568.66
		},
		{
			unixtime: 1656696680,
			balance: 99573.24
		},
		{
			unixtime: 1656696740,
			balance: 99579.38
		},
		{
			unixtime: 1656696800,
			balance: 99579
		},
		{
			unixtime: 1656696860,
			balance: 99575.82
		},
		{
			unixtime: 1656696920,
			balance: 99571.04
		},
		{
			unixtime: 1656696980,
			balance: 99565.6
		},
		{
			unixtime: 1656697040,
			balance: 99571.76
		},
		{
			unixtime: 1656697100,
			balance: 99569.96
		},
		{
			unixtime: 1656697160,
			balance: 99590.66
		},
		{
			unixtime: 1656697220,
			balance: 99583.06
		},
		{
			unixtime: 1656697280,
			balance: 99593.2
		},
		{
			unixtime: 1656697340,
			balance: 99586.06
		},
		{
			unixtime: 1656697400,
			balance: 99591.18
		},
		{
			unixtime: 1656697460,
			balance: 99580.52
		},
		{
			unixtime: 1656697520,
			balance: 99583.9
		},
		{
			unixtime: 1656697580,
			balance: 99594.96
		},
		{
			unixtime: 1656697640,
			balance: 99587.56
		},
		{
			unixtime: 1656697700,
			balance: 99593.28
		},
		{
			unixtime: 1656697760,
			balance: 99599.2
		},
		{
			unixtime: 1656697820,
			balance: 99599.88
		},
		{
			unixtime: 1656697880,
			balance: 99600.7
		},
		{
			unixtime: 1656697940,
			balance: 99596.48
		},
		{
			unixtime: 1656698000,
			balance: 99601.6
		},
		{
			unixtime: 1656698060,
			balance: 99595.64
		},
		{
			unixtime: 1656698120,
			balance: 99591.28
		},
		{
			unixtime: 1656698180,
			balance: 99591.28
		},
		{
			unixtime: 1656698240,
			balance: 99591.38
		},
		{
			unixtime: 1656698300,
			balance: 99598.52
		},
		{
			unixtime: 1656698360,
			balance: 99600.72
		},
		{
			unixtime: 1656698420,
			balance: 99602.02
		},
		{
			unixtime: 1656698480,
			balance: 99600.34
		},
		{
			unixtime: 1656698540,
			balance: 99595.54
		},
		{
			unixtime: 1656698600,
			balance: 99599.06
		},
		{
			unixtime: 1656698660,
			balance: 99593.04
		},
		{
			unixtime: 1656698720,
			balance: 99591.76
		},
		{
			unixtime: 1656698780,
			balance: 99594.28
		},
		{
			unixtime: 1656698840,
			balance: 99594.26
		},
		{
			unixtime: 1656698900,
			balance: 99588.8
		},
		{
			unixtime: 1656698960,
			balance: 99585.86
		},
		{
			unixtime: 1656699020,
			balance: 99590.52
		},
		{
			unixtime: 1656699080,
			balance: 99581.74
		},
		{
			unixtime: 1656699140,
			balance: 99577.5
		},
		{
			unixtime: 1656699200,
			balance: 99582.92
		},
		{
			unixtime: 1656699260,
			balance: 99589.46
		},
		{
			unixtime: 1656699320,
			balance: 99597.1
		},
		{
			unixtime: 1656699380,
			balance: 99590.78
		},
		{
			unixtime: 1656699440,
			balance: 99593.76
		},
		{
			unixtime: 1656699500,
			balance: 99598.74
		},
		{
			unixtime: 1656699560,
			balance: 99597.7
		},
		{
			unixtime: 1656699620,
			balance: 99602.24
		},
		{
			unixtime: 1656699680,
			balance: 99602.54
		},
		{
			unixtime: 1656699740,
			balance: 99608.76
		},
		{
			unixtime: 1656699800,
			balance: 99613.96
		},
		{
			unixtime: 1656699860,
			balance: 99618.9
		},
		{
			unixtime: 1656699920,
			balance: 99616.46
		},
		{
			unixtime: 1656699980,
			balance: 99617.16
		},
		{
			unixtime: 1656700040,
			balance: 99617.92
		},
		{
			unixtime: 1656700100,
			balance: 99621.02
		},
		{
			unixtime: 1656700160,
			balance: 99611.64
		},
		{
			unixtime: 1656700220,
			balance: 99612.08
		},
		{
			unixtime: 1656700280,
			balance: 99618
		},
		{
			unixtime: 1656700340,
			balance: 99623.62
		},
		{
			unixtime: 1656700400,
			balance: 99625.12
		},
		{
			unixtime: 1656700460,
			balance: 99627.02
		},
		{
			unixtime: 1656700520,
			balance: 99629.62
		},
		{
			unixtime: 1656700580,
			balance: 99629.86
		},
		{
			unixtime: 1656700640,
			balance: 99619.98
		},
		{
			unixtime: 1656700700,
			balance: 99622.82
		},
		{
			unixtime: 1656700760,
			balance: 99624.02
		},
		{
			unixtime: 1656700820,
			balance: 99625.92
		},
		{
			unixtime: 1656700880,
			balance: 99624.74
		},
		{
			unixtime: 1656700940,
			balance: 99626.76
		},
		{
			unixtime: 1656701000,
			balance: 99625.72
		},
		{
			unixtime: 1656701060,
			balance: 99628.74
		},
		{
			unixtime: 1656701120,
			balance: 99626.32
		},
		{
			unixtime: 1656701180,
			balance: 99635.3
		},
		{
			unixtime: 1656701240,
			balance: 99637.9
		},
		{
			unixtime: 1656701300,
			balance: 99631.68
		},
		{
			unixtime: 1656701360,
			balance: 99637.44
		},
		{
			unixtime: 1656701420,
			balance: 99638.6
		},
		{
			unixtime: 1656701480,
			balance: 99636.2
		},
		{
			unixtime: 1656701540,
			balance: 99635.58
		},
		{
			unixtime: 1656701600,
			balance: 99633.46
		},
		{
			unixtime: 1656701660,
			balance: 99624.74
		},
		{
			unixtime: 1656701720,
			balance: 99622.54
		},
		{
			unixtime: 1656701780,
			balance: 99624.66
		},
		{
			unixtime: 1656701840,
			balance: 99634.16
		},
		{
			unixtime: 1656701900,
			balance: 99634.66
		},
		{
			unixtime: 1656701960,
			balance: 99635.52
		},
		{
			unixtime: 1656702020,
			balance: 99636.56
		},
		{
			unixtime: 1656702080,
			balance: 99628.14
		},
		{
			unixtime: 1656702140,
			balance: 99621.78
		},
		{
			unixtime: 1656702200,
			balance: 99616.96
		},
		{
			unixtime: 1656702260,
			balance: 99611.02
		},
		{
			unixtime: 1656702320,
			balance: 99617.34
		},
		{
			unixtime: 1656702380,
			balance: 99620.28
		},
		{
			unixtime: 1656702440,
			balance: 99625.1
		},
		{
			unixtime: 1656702500,
			balance: 99622.42
		},
		{
			unixtime: 1656702560,
			balance: 99630.4
		},
		{
			unixtime: 1656702620,
			balance: 99632.8
		},
		{
			unixtime: 1656702680,
			balance: 99637
		},
		{
			unixtime: 1656702740,
			balance: 99637.94
		},
		{
			unixtime: 1656702800,
			balance: 99639.84
		},
		{
			unixtime: 1656702860,
			balance: 99639.48
		},
		{
			unixtime: 1656702920,
			balance: 99642.14
		},
		{
			unixtime: 1656702980,
			balance: 99646.8
		},
		{
			unixtime: 1656703040,
			balance: 99644.14
		},
		{
			unixtime: 1656703100,
			balance: 99641.38
		},
		{
			unixtime: 1656703160,
			balance: 99640.48
		},
		{
			unixtime: 1656703220,
			balance: 99639.8
		},
		{
			unixtime: 1656703280,
			balance: 99635.98
		},
		{
			unixtime: 1656703340,
			balance: 99636.68
		},
		{
			unixtime: 1656703400,
			balance: 99637.54
		},
		{
			unixtime: 1656703460,
			balance: 99641.4
		},
		{
			unixtime: 1656703520,
			balance: 99643.68
		},
		{
			unixtime: 1656703580,
			balance: 99642.76
		},
		{
			unixtime: 1656703640,
			balance: 99647.46
		},
		{
			unixtime: 1656703700,
			balance: 99648.44
		},
		{
			unixtime: 1656703760,
			balance: 99644.58
		},
		{
			unixtime: 1656703820,
			balance: 99652.02
		},
		{
			unixtime: 1656703880,
			balance: 99656.38
		},
		{
			unixtime: 1656703940,
			balance: 99659.34
		},
		{
			unixtime: 1656704000,
			balance: 99664.92
		},
		{
			unixtime: 1656704060,
			balance: 99664.74
		},
		{
			unixtime: 1656704120,
			balance: 99667.64
		},
		{
			unixtime: 1656704180,
			balance: 99664.96
		},
		{
			unixtime: 1656704240,
			balance: 99669.04
		},
		{
			unixtime: 1656704300,
			balance: 99668.5
		},
		{
			unixtime: 1656704360,
			balance: 99675.66
		},
		{
			unixtime: 1656704420,
			balance: 99678.48
		},
		{
			unixtime: 1656704480,
			balance: 99677.34
		},
		{
			unixtime: 1656704540,
			balance: 99677.76
		},
		{
			unixtime: 1656704600,
			balance: 99680.68
		},
		{
			unixtime: 1656704660,
			balance: 99674.48
		},
		{
			unixtime: 1656704720,
			balance: 99672.66
		},
		{
			unixtime: 1656704780,
			balance: 99677.66
		},
		{
			unixtime: 1656704840,
			balance: 99674.98
		},
		{
			unixtime: 1656704900,
			balance: 99665.42
		},
		{
			unixtime: 1656704960,
			balance: 99679.06
		},
		{
			unixtime: 1656705020,
			balance: 99671.66
		},
		{
			unixtime: 1656705080,
			balance: 99659.68
		},
		{
			unixtime: 1656705140,
			balance: 99656.38
		},
		{
			unixtime: 1656705200,
			balance: 99650.96
		},
		{
			unixtime: 1656705260,
			balance: 99654.92
		},
		{
			unixtime: 1656705320,
			balance: 99663.62
		},
		{
			unixtime: 1656705380,
			balance: 99663.54
		},
		{
			unixtime: 1656705440,
			balance: 99658.76
		},
		{
			unixtime: 1656705500,
			balance: 99660.38
		},
		{
			unixtime: 1656705560,
			balance: 99668.3
		},
		{
			unixtime: 1656705620,
			balance: 99675.98
		},
		{
			unixtime: 1656705680,
			balance: 99671.18
		},
		{
			unixtime: 1656705740,
			balance: 99670.34
		},
		{
			unixtime: 1656705800,
			balance: 99673.26
		},
		{
			unixtime: 1656705860,
			balance: 99668.76
		},
		{
			unixtime: 1656705920,
			balance: 99667.42
		},
		{
			unixtime: 1656705980,
			balance: 99666.5
		},
		{
			unixtime: 1656706040,
			balance: 99667.66
		},
		{
			unixtime: 1656706100,
			balance: 99670.26
		},
		{
			unixtime: 1656706160,
			balance: 99670.18
		},
		{
			unixtime: 1656706220,
			balance: 99667.26
		},
		{
			unixtime: 1656706280,
			balance: 99664.56
		},
		{
			unixtime: 1656706340,
			balance: 99666.48
		},
		{
			unixtime: 1656706400,
			balance: 99660.98
		},
		{
			unixtime: 1656706460,
			balance: 99666.78
		},
		{
			unixtime: 1656706520,
			balance: 99665.96
		},
		{
			unixtime: 1656706580,
			balance: 99667.26
		},
		{
			unixtime: 1656706640,
			balance: 99666.86
		},
		{
			unixtime: 1656706700,
			balance: 99667.06
		},
		{
			unixtime: 1656706760,
			balance: 99669.98
		},
		{
			unixtime: 1656706820,
			balance: 99670.34
		},
		{
			unixtime: 1656706880,
			balance: 99669.74
		},
		{
			unixtime: 1656706940,
			balance: 99666.22
		},
		{
			unixtime: 1656707000,
			balance: 99666.08
		},
		{
			unixtime: 1656707060,
			balance: 99666.56
		},
		{
			unixtime: 1656707120,
			balance: 99666.86
		},
		{
			unixtime: 1656707180,
			balance: 99666.38
		},
		{
			unixtime: 1656707240,
			balance: 99667.08
		},
		{
			unixtime: 1656707300,
			balance: 99667.66
		},
		{
			unixtime: 1656707360,
			balance: 99667.76
		},
		{
			unixtime: 1656707420,
			balance: 99668.16
		},
		{
			unixtime: 1656707480,
			balance: 99668.06
		},
		{
			unixtime: 1656707540,
			balance: 99668.46
		},
		{
			unixtime: 1656707600,
			balance: 99668.6
		},
		{
			unixtime: 1656707660,
			balance: 99667.5
		},
		{
			unixtime: 1656707720,
			balance: 99666.86
		},
		{
			unixtime: 1656707780,
			balance: 99666.66
		},
		{
			unixtime: 1656707840,
			balance: 99666.06
		},
		{
			unixtime: 1656707900,
			balance: 99666.06
		},
		{
			unixtime: 1656707960,
			balance: 99666.06
		},
		{
			unixtime: 1656708020,
			balance: 99666.16
		},
		{
			unixtime: 1656708080,
			balance: 99666.08
		},
		{
			unixtime: 1656708140,
			balance: 99669.16
		},
		{
			unixtime: 1656708200,
			balance: 99669.76
		},
		{
			unixtime: 1656708260,
			balance: 99669.22
		},
		{
			unixtime: 1656708320,
			balance: 99669.38
		},
		{
			unixtime: 1656708380,
			balance: 99669.18
		},
		{
			unixtime: 1656708440,
			balance: 99669.36
		},
		{
			unixtime: 1656708500,
			balance: 99670.16
		},
		{
			unixtime: 1656708560,
			balance: 99670.16
		},
		{
			unixtime: 1656708620,
			balance: 99669.56
		},
		{
			unixtime: 1656708680,
			balance: 99669.86
		},
		{
			unixtime: 1656708740,
			balance: 99669.66
		},
		{
			unixtime: 1656708800,
			balance: 99669.72
		},
		{
			unixtime: 1656708860,
			balance: 99669.62
		},
		{
			unixtime: 1656708920,
			balance: 99669.28
		},
		{
			unixtime: 1656708980,
			balance: 99668.06
		},
		{
			unixtime: 1656709040,
			balance: 99668.3
		},
		{
			unixtime: 1656709100,
			balance: 99667.98
		},
		{
			unixtime: 1656709160,
			balance: 99668.48
		},
		{
			unixtime: 1656709220,
			balance: 99668.56
		},
		{
			unixtime: 1656709280,
			balance: 99670.56
		},
		{
			unixtime: 1656709340,
			balance: 99669.26
		},
		{
			unixtime: 1656709400,
			balance: 99669.26
		},
		{
			unixtime: 1656709460,
			balance: 99668.96
		},
		{
			unixtime: 1656709520,
			balance: 99668.92
		},
		{
			unixtime: 1656709580,
			balance: 99668.76
		},
		{
			unixtime: 1656709640,
			balance: 99668.56
		},
		{
			unixtime: 1656709700,
			balance: 99666.46
		},
		{
			unixtime: 1656709760,
			balance: 99666.26
		},
		{
			unixtime: 1656709820,
			balance: 99666.76
		},
		{
			unixtime: 1656709880,
			balance: 99666.86
		},
		{
			unixtime: 1656709940,
			balance: 99667.68
		},
		{
			unixtime: 1656710000,
			balance: 99661.98
		},
		{
			unixtime: 1656710060,
			balance: 99661.98
		},
		{
			unixtime: 1656710120,
			balance: 99662.08
		},
		{
			unixtime: 1656710180,
			balance: 99665.08
		},
		{
			unixtime: 1656710240,
			balance: 99666.58
		},
		{
			unixtime: 1656710300,
			balance: 99665.28
		},
		{
			unixtime: 1656710360,
			balance: 99665.1
		},
		{
			unixtime: 1656710420,
			balance: 99665.58
		},
		{
			unixtime: 1656710480,
			balance: 99665.58
		},
		{
			unixtime: 1656710540,
			balance: 99665.58
		},
		{
			unixtime: 1656710600,
			balance: 99666.34
		},
		{
			unixtime: 1656710660,
			balance: 99666.34
		},
		{
			unixtime: 1656710720,
			balance: 99666.16
		},
		{
			unixtime: 1656710780,
			balance: 99666.56
		},
		{
			unixtime: 1656710840,
			balance: 99666.76
		},
		{
			unixtime: 1656710900,
			balance: 99666.76
		},
		{
			unixtime: 1656710960,
			balance: 99666.76
		},
		{
			unixtime: 1656711020,
			balance: 99666.76
		},
		{
			unixtime: 1656711080,
			balance: 99663.76
		},
		{
			unixtime: 1656711140,
			balance: 99664.16
		},
		{
			unixtime: 1656711200,
			balance: 99664.56
		},
		{
			unixtime: 1656711260,
			balance: 99663.96
		},
		{
			unixtime: 1656711320,
			balance: 99663.96
		},
		{
			unixtime: 1656711380,
			balance: 99663.62
		},
		{
			unixtime: 1656711440,
			balance: 99664.62
		},
		{
			unixtime: 1656711500,
			balance: 99664.62
		},
		{
			unixtime: 1656711560,
			balance: 99664.62
		},
		{
			unixtime: 1656711620,
			balance: 99664.76
		},
		{
			unixtime: 1656711680,
			balance: 99663.96
		},
		{
			unixtime: 1656711740,
			balance: 99664.86
		},
		{
			unixtime: 1656711800,
			balance: 99664.46
		},
		{
			unixtime: 1656711860,
			balance: 99664.46
		},
		{
			unixtime: 1656711920,
			balance: 99664.66
		},
		{
			unixtime: 1656711980,
			balance: 99664.06
		},
		{
			unixtime: 1656712040,
			balance: 99663.06
		},
		{
			unixtime: 1656712100,
			balance: 99665.26
		},
		{
			unixtime: 1656712160,
			balance: 99664.86
		},
		{
			unixtime: 1656712220,
			balance: 99664.86
		},
		{
			unixtime: 1656712280,
			balance: 99664.86
		},
		{
			unixtime: 1656712340,
			balance: 99664.46
		},
		{
			unixtime: 1656712400,
			balance: 99664.46
		},
		{
			unixtime: 1656712460,
			balance: 99664.36
		},
		{
			unixtime: 1656712520,
			balance: 99664.06
		},
		{
			unixtime: 1656712580,
			balance: 99664.06
		},
		{
			unixtime: 1656712640,
			balance: 99664.86
		},
		{
			unixtime: 1656712700,
			balance: 99663.6
		},
		{
			unixtime: 1656712760,
			balance: 99664.16
		},
		{
			unixtime: 1656712820,
			balance: 99667.56
		},
		{
			unixtime: 1656712880,
			balance: 99666.66
		},
		{
			unixtime: 1656712940,
			balance: 99666.66
		},
		{
			unixtime: 1656713000,
			balance: 99666.64
		},
		{
			unixtime: 1656713060,
			balance: 99666.64
		},
		{
			unixtime: 1656713120,
			balance: 99666.64
		},
		{
			unixtime: 1656713180,
			balance: 99666.64
		},
		{
			unixtime: 1656713240,
			balance: 99667.34
		},
		{
			unixtime: 1656713300,
			balance: 99667.1
		},
		{
			unixtime: 1656713360,
			balance: 99667.1
		},
		{
			unixtime: 1656713420,
			balance: 99666.46
		},
		{
			unixtime: 1656713480,
			balance: 99666.46
		},
		{
			unixtime: 1656713540,
			balance: 99667.26
		},
		{
			unixtime: 1656713600,
			balance: 99666.46
		},
		{
			unixtime: 1656713660,
			balance: 99665.26
		},
		{
			unixtime: 1656713720,
			balance: 99665.26
		},
		{
			unixtime: 1656713780,
			balance: 99665.12
		},
		{
			unixtime: 1656713840,
			balance: 99664.96
		},
		{
			unixtime: 1656713900,
			balance: 99664.66
		},
		{
			unixtime: 1656713960,
			balance: 99664.26
		},
		{
			unixtime: 1656714020,
			balance: 99664.26
		},
		{
			unixtime: 1656714080,
			balance: 99663.96
		},
		{
			unixtime: 1656714140,
			balance: 99664.86
		},
		{
			unixtime: 1656714200,
			balance: 99664.86
		},
		{
			unixtime: 1656714260,
			balance: 99664.66
		},
		{
			unixtime: 1656714320,
			balance: 99665.06
		},
		{
			unixtime: 1656714380,
			balance: 99665.06
		},
		{
			unixtime: 1656714440,
			balance: 99665.96
		},
		{
			unixtime: 1656714500,
			balance: 99666.46
		},
		{
			unixtime: 1656714560,
			balance: 99667.46
		},
		{
			unixtime: 1656714620,
			balance: 99667.96
		},
		{
			unixtime: 1656714680,
			balance: 99669.56
		},
		{
			unixtime: 1656714740,
			balance: 99672.56
		},
		{
			unixtime: 1656714800,
			balance: 99672.56
		},
		{
			unixtime: 1656714860,
			balance: 99670.96
		},
		{
			unixtime: 1656714920,
			balance: 99670.96
		},
		{
			unixtime: 1656714980,
			balance: 99671.6
		},
		{
			unixtime: 1656715040,
			balance: 99671.6
		},
		{
			unixtime: 1656715100,
			balance: 99671.6
		},
		{
			unixtime: 1656715160,
			balance: 99671.6
		},
		{
			unixtime: 1656715220,
			balance: 99672.16
		},
		{
			unixtime: 1656715280,
			balance: 99671.96
		},
		{
			unixtime: 1656715340,
			balance: 99671.96
		},
		{
			unixtime: 1656715400,
			balance: 99671.96
		},
		{
			unixtime: 1656715460,
			balance: 99667.86
		},
		{
			unixtime: 1656715520,
			balance: 99667.72
		},
		{
			unixtime: 1656715580,
			balance: 99666.72
		},
		{
			unixtime: 1656715640,
			balance: 99666.72
		},
		{
			unixtime: 1656715700,
			balance: 99666.62
		},
		{
			unixtime: 1656715760,
			balance: 99666.12
		},
		{
			unixtime: 1656715820,
			balance: 99665.96
		},
		{
			unixtime: 1656715880,
			balance: 99665.96
		},
		{
			unixtime: 1656715940,
			balance: 99665.16
		},
		{
			unixtime: 1656716000,
			balance: 99665.16
		},
		{
			unixtime: 1656716060,
			balance: 99665.16
		},
		{
			unixtime: 1656716120,
			balance: 99665.16
		},
		{
			unixtime: 1656716180,
			balance: 99665.16
		},
		{
			unixtime: 1656716240,
			balance: 99665.08
		},
		{
			unixtime: 1656716300,
			balance: 99665.08
		},
		{
			unixtime: 1656716360,
			balance: 99665.08
		},
		{
			unixtime: 1656716420,
			balance: 99667.32
		},
		{
			unixtime: 1656716480,
			balance: 99667.32
		},
		{
			unixtime: 1656716540,
			balance: 99667.32
		},
		{
			unixtime: 1656716600,
			balance: 99667.32
		},
		{
			unixtime: 1656716660,
			balance: 99667.32
		},
		{
			unixtime: 1656716720,
			balance: 99667.32
		},
		{
			unixtime: 1656716780,
			balance: 99667.32
		},
		{
			unixtime: 1656716840,
			balance: 99668.62
		},
		{
			unixtime: 1656716900,
			balance: 99668.92
		},
		{
			unixtime: 1656716960,
			balance: 99664.92
		},
		{
			unixtime: 1656717020,
			balance: 99664.92
		},
		{
			unixtime: 1656717080,
			balance: 99665.36
		},
		{
			unixtime: 1656717140,
			balance: 99665.36
		},
		{
			unixtime: 1656717200,
			balance: 99665.36
		},
		{
			unixtime: 1656717260,
			balance: 99665.36
		},
		{
			unixtime: 1656717320,
			balance: 99665.36
		},
		{
			unixtime: 1656717380,
			balance: 99665.36
		},
		{
			unixtime: 1656717440,
			balance: 99665.56
		},
		{
			unixtime: 1656717500,
			balance: 99665.56
		},
		{
			unixtime: 1656717560,
			balance: 99666.56
		},
		{
			unixtime: 1656717620,
			balance: 99666.56
		},
		{
			unixtime: 1656717680,
			balance: 99667.12
		},
		{
			unixtime: 1656717740,
			balance: 99667.12
		},
		{
			unixtime: 1656717800,
			balance: 99666.12
		},
		{
			unixtime: 1656717860,
			balance: 99665.62
		},
		{
			unixtime: 1656717920,
			balance: 99665.62
		},
		{
			unixtime: 1656717980,
			balance: 99663.72
		},
		{
			unixtime: 1656718040,
			balance: 99664.72
		},
		{
			unixtime: 1656718100,
			balance: 99664.18
		},
		{
			unixtime: 1656718160,
			balance: 99664.18
		},
		{
			unixtime: 1656718220,
			balance: 99664.18
		},
		{
			unixtime: 1656718280,
			balance: 99663.96
		},
		{
			unixtime: 1656718340,
			balance: 99663.56
		},
		{
			unixtime: 1656718400,
			balance: 99666.36
		},
		{
			unixtime: 1656718460,
			balance: 99665.96
		},
		{
			unixtime: 1656718520,
			balance: 99665.76
		},
		{
			unixtime: 1656718580,
			balance: 99663.76
		},
		{
			unixtime: 1656718640,
			balance: 99663.56
		},
		{
			unixtime: 1656718700,
			balance: 99663.56
		},
		{
			unixtime: 1656718760,
			balance: 99663.42
		},
		{
			unixtime: 1656718820,
			balance: 99663.92
		},
		{
			unixtime: 1656718880,
			balance: 99664.12
		},
		{
			unixtime: 1656718940,
			balance: 99664.12
		},
		{
			unixtime: 1656719000,
			balance: 99664.12
		},
		{
			unixtime: 1656719060,
			balance: 99665.12
		},
		{
			unixtime: 1656719120,
			balance: 99665.9
		},
		{
			unixtime: 1656719180,
			balance: 99665.9
		},
		{
			unixtime: 1656719240,
			balance: 99665.6
		},
		{
			unixtime: 1656719300,
			balance: 99665.7
		},
		{
			unixtime: 1656719360,
			balance: 99665.8
		},
		{
			unixtime: 1656719420,
			balance: 99665.46
		},
		{
			unixtime: 1656719480,
			balance: 99666.12
		},
		{
			unixtime: 1656719540,
			balance: 99665.76
		},
		{
			unixtime: 1656719600,
			balance: 99665.28
		},
		{
			unixtime: 1656719660,
			balance: 99665.32
		},
		{
			unixtime: 1656719720,
			balance: 99665.48
		},
		{
			unixtime: 1656719780,
			balance: 99665.48
		},
		{
			unixtime: 1656719840,
			balance: 99669.88
		},
		{
			unixtime: 1656719900,
			balance: 99670.36
		},
		{
			unixtime: 1656719960,
			balance: 99669.96
		},
		{
			unixtime: 1656720020,
			balance: 99672.08
		},
		{
			unixtime: 1656720080,
			balance: 99672.08
		},
		{
			unixtime: 1656720140,
			balance: 99672.08
		},
		{
			unixtime: 1656720200,
			balance: 99672.08
		},
		{
			unixtime: 1656720260,
			balance: 99672.08
		},
		{
			unixtime: 1656720320,
			balance: 99672.08
		},
		{
			unixtime: 1656720380,
			balance: 99672.08
		},
		{
			unixtime: 1656720440,
			balance: 99672.08
		},
		{
			unixtime: 1656720500,
			balance: 99672.08
		},
		{
			unixtime: 1656720560,
			balance: 99672.08
		},
		{
			unixtime: 1656720620,
			balance: 99672.08
		},
		{
			unixtime: 1656720680,
			balance: 99672.08
		},
		{
			unixtime: 1656720740,
			balance: 99672.08
		},
		{
			unixtime: 1656720800,
			balance: 99672.08
		},
		{
			unixtime: 1656720860,
			balance: 99672.08
		},
		{
			unixtime: 1656720920,
			balance: 99672.08
		},
		{
			unixtime: 1656720980,
			balance: 99672.08
		},
		{
			unixtime: 1656721040,
			balance: 99672.08
		},
		{
			unixtime: 1656721100,
			balance: 99672.08
		},
		{
			unixtime: 1656721160,
			balance: 99672.08
		},
		{
			unixtime: 1656721220,
			balance: 99672.08
		},
		{
			unixtime: 1656721280,
			balance: 99672.08
		},
		{
			unixtime: 1656721340,
			balance: 99672.08
		},
		{
			unixtime: 1656721400,
			balance: 99672.08
		},
		{
			unixtime: 1656721460,
			balance: 99672.08
		},
		{
			unixtime: 1656721520,
			balance: 99672.08
		},
		{
			unixtime: 1656721580,
			balance: 99672.08
		},
		{
			unixtime: 1656721640,
			balance: 99672.08
		},
		{
			unixtime: 1656721700,
			balance: 99672.08
		},
		{
			unixtime: 1656721760,
			balance: 99672.08
		},
		{
			unixtime: 1656721820,
			balance: 99672.08
		},
		{
			unixtime: 1656721880,
			balance: 99672.08
		},
		{
			unixtime: 1656721940,
			balance: 99672.08
		},
		{
			unixtime: 1656722000,
			balance: 99672.08
		},
		{
			unixtime: 1656722060,
			balance: 99672.08
		},
		{
			unixtime: 1656722120,
			balance: 99672.08
		},
		{
			unixtime: 1656722180,
			balance: 99672.08
		},
		{
			unixtime: 1656722240,
			balance: 99672.08
		},
		{
			unixtime: 1656722300,
			balance: 99672.08
		},
		{
			unixtime: 1656722360,
			balance: 99672.08
		},
		{
			unixtime: 1656722420,
			balance: 99672.08
		},
		{
			unixtime: 1656722480,
			balance: 99672.08
		},
		{
			unixtime: 1656722540,
			balance: 99672.08
		},
		{
			unixtime: 1656722600,
			balance: 99672.08
		},
		{
			unixtime: 1656722660,
			balance: 99672.08
		},
		{
			unixtime: 1656722720,
			balance: 99672.08
		},
		{
			unixtime: 1656722780,
			balance: 99672.08
		},
		{
			unixtime: 1656722840,
			balance: 99672.08
		},
		{
			unixtime: 1656722900,
			balance: 99672.08
		},
		{
			unixtime: 1656722960,
			balance: 99672.08
		},
		{
			unixtime: 1656723020,
			balance: 99672.08
		},
		{
			unixtime: 1656723080,
			balance: 99672.08
		},
		{
			unixtime: 1656723140,
			balance: 99672.08
		},
		{
			unixtime: 1656723200,
			balance: 99672.08
		},
		{
			unixtime: 1656723260,
			balance: 99672.08
		},
		{
			unixtime: 1656723320,
			balance: 99672.08
		},
		{
			unixtime: 1656723380,
			balance: 99672.08
		},
		{
			unixtime: 1656723440,
			balance: 99672.08
		},
		{
			unixtime: 1656723500,
			balance: 99672.08
		},
		{
			unixtime: 1656723560,
			balance: 99672.08
		},
		{
			unixtime: 1656723620,
			balance: 99672.08
		},
		{
			unixtime: 1656723680,
			balance: 99672.08
		},
		{
			unixtime: 1656723740,
			balance: 99672.08
		},
		{
			unixtime: 1656723800,
			balance: 99672.08
		},
		{
			unixtime: 1656723860,
			balance: 99672.08
		},
		{
			unixtime: 1656723920,
			balance: 99672.08
		},
		{
			unixtime: 1656723980,
			balance: 99672.08
		},
		{
			unixtime: 1656724040,
			balance: 99672.08
		},
		{
			unixtime: 1656724100,
			balance: 99672.08
		},
		{
			unixtime: 1656724160,
			balance: 99672.08
		},
		{
			unixtime: 1656724220,
			balance: 99672.08
		},
		{
			unixtime: 1656724280,
			balance: 99672.08
		},
		{
			unixtime: 1656724340,
			balance: 99672.08
		},
		{
			unixtime: 1656724400,
			balance: 99672.08
		},
		{
			unixtime: 1656724460,
			balance: 99672.08
		},
		{
			unixtime: 1656724520,
			balance: 99672.08
		},
		{
			unixtime: 1656724580,
			balance: 99672.08
		},
		{
			unixtime: 1656724640,
			balance: 99672.08
		},
		{
			unixtime: 1656724700,
			balance: 99672.08
		},
		{
			unixtime: 1656724760,
			balance: 99672.08
		},
		{
			unixtime: 1656724820,
			balance: 99672.08
		},
		{
			unixtime: 1656724880,
			balance: 99672.08
		},
		{
			unixtime: 1656724940,
			balance: 99672.08
		},
		{
			unixtime: 1656725000,
			balance: 99672.08
		},
		{
			unixtime: 1656725060,
			balance: 99672.08
		},
		{
			unixtime: 1656725120,
			balance: 99672.08
		},
		{
			unixtime: 1656725180,
			balance: 99672.08
		},
		{
			unixtime: 1656725240,
			balance: 99672.08
		},
		{
			unixtime: 1656725300,
			balance: 99672.08
		},
		{
			unixtime: 1656725360,
			balance: 99672.08
		},
		{
			unixtime: 1656725420,
			balance: 99672.08
		},
		{
			unixtime: 1656725480,
			balance: 99672.08
		},
		{
			unixtime: 1656725540,
			balance: 99672.08
		},
		{
			unixtime: 1656725600,
			balance: 99672.08
		},
		{
			unixtime: 1656725660,
			balance: 99672.08
		},
		{
			unixtime: 1656725720,
			balance: 99672.08
		},
		{
			unixtime: 1656725780,
			balance: 99672.08
		},
		{
			unixtime: 1656725841,
			balance: 99672.08
		},
		{
			unixtime: 1656725900,
			balance: 99672.08
		},
		{
			unixtime: 1656725961,
			balance: 99672.08
		},
		{
			unixtime: 1656726020,
			balance: 99672.08
		},
		{
			unixtime: 1656726081,
			balance: 99672.08
		},
		{
			unixtime: 1656726140,
			balance: 99672.08
		},
		{
			unixtime: 1656726200,
			balance: 99672.08
		},
		{
			unixtime: 1656726260,
			balance: 99672.08
		},
		{
			unixtime: 1656726321,
			balance: 99672.08
		},
		{
			unixtime: 1656726381,
			balance: 99672.08
		},
		{
			unixtime: 1656726441,
			balance: 99672.08
		},
		{
			unixtime: 1656726500,
			balance: 99672.08
		},
		{
			unixtime: 1656726560,
			balance: 99672.08
		},
		{
			unixtime: 1656726620,
			balance: 99672.08
		},
		{
			unixtime: 1656726681,
			balance: 99672.08
		},
		{
			unixtime: 1656726740,
			balance: 99672.08
		},
		{
			unixtime: 1656726800,
			balance: 99672.08
		},
		{
			unixtime: 1656726860,
			balance: 99672.08
		},
		{
			unixtime: 1656726921,
			balance: 99672.08
		},
		{
			unixtime: 1656726981,
			balance: 99672.08
		},
		{
			unixtime: 1656727041,
			balance: 99672.08
		},
		{
			unixtime: 1656727101,
			balance: 99672.08
		},
		{
			unixtime: 1656727161,
			balance: 99672.08
		},
		{
			unixtime: 1656727221,
			balance: 99672.08
		},
		{
			unixtime: 1656727281,
			balance: 99672.08
		},
		{
			unixtime: 1656727341,
			balance: 99672.08
		},
		{
			unixtime: 1656727401,
			balance: 99672.08
		},
		{
			unixtime: 1656727461,
			balance: 99672.08
		},
		{
			unixtime: 1656727521,
			balance: 99672.08
		},
		{
			unixtime: 1656727581,
			balance: 99672.08
		},
		{
			unixtime: 1656727641,
			balance: 99672.08
		},
		{
			unixtime: 1656727701,
			balance: 99672.08
		},
		{
			unixtime: 1656727761,
			balance: 99672.08
		},
		{
			unixtime: 1656727821,
			balance: 99672.08
		},
		{
			unixtime: 1656727881,
			balance: 99672.08
		},
		{
			unixtime: 1656727941,
			balance: 99672.08
		},
		{
			unixtime: 1656728001,
			balance: 99672.08
		},
		{
			unixtime: 1656728061,
			balance: 99672.08
		},
		{
			unixtime: 1656728121,
			balance: 99672.08
		},
		{
			unixtime: 1656728181,
			balance: 99672.08
		},
		{
			unixtime: 1656728241,
			balance: 99672.08
		},
		{
			unixtime: 1656728301,
			balance: 99672.08
		},
		{
			unixtime: 1656728361,
			balance: 99672.08
		},
		{
			unixtime: 1656728421,
			balance: 99672.08
		},
		{
			unixtime: 1656728481,
			balance: 99672.08
		},
		{
			unixtime: 1656728541,
			balance: 99672.08
		},
		{
			unixtime: 1656728601,
			balance: 99672.08
		},
		{
			unixtime: 1656728661,
			balance: 99672.08
		},
		{
			unixtime: 1656728721,
			balance: 99672.08
		},
		{
			unixtime: 1656728781,
			balance: 99672.08
		},
		{
			unixtime: 1656728841,
			balance: 99672.08
		},
		{
			unixtime: 1656728901,
			balance: 99672.08
		},
		{
			unixtime: 1656728961,
			balance: 99672.08
		},
		{
			unixtime: 1656729021,
			balance: 99672.08
		},
		{
			unixtime: 1656729081,
			balance: 99672.08
		},
		{
			unixtime: 1656729141,
			balance: 99672.08
		},
		{
			unixtime: 1656729201,
			balance: 99672.08
		},
		{
			unixtime: 1656729261,
			balance: 99672.08
		},
		{
			unixtime: 1656729321,
			balance: 99672.08
		},
		{
			unixtime: 1656729381,
			balance: 99672.08
		},
		{
			unixtime: 1656729441,
			balance: 99672.08
		},
		{
			unixtime: 1656729501,
			balance: 99672.08
		},
		{
			unixtime: 1656729561,
			balance: 99672.08
		},
		{
			unixtime: 1656729621,
			balance: 99672.08
		},
		{
			unixtime: 1656729681,
			balance: 99672.08
		},
		{
			unixtime: 1656729741,
			balance: 99672.08
		},
		{
			unixtime: 1656729801,
			balance: 99672.08
		},
		{
			unixtime: 1656729861,
			balance: 99672.08
		},
		{
			unixtime: 1656729921,
			balance: 99672.08
		},
		{
			unixtime: 1656729981,
			balance: 99672.08
		},
		{
			unixtime: 1656730041,
			balance: 99672.08
		},
		{
			unixtime: 1656730101,
			balance: 99672.08
		},
		{
			unixtime: 1656730161,
			balance: 99672.08
		},
		{
			unixtime: 1656730221,
			balance: 99672.08
		},
		{
			unixtime: 1656730281,
			balance: 99672.08
		},
		{
			unixtime: 1656730341,
			balance: 99672.08
		},
		{
			unixtime: 1656730401,
			balance: 99672.08
		},
		{
			unixtime: 1656730461,
			balance: 99672.08
		},
		{
			unixtime: 1656730521,
			balance: 99672.08
		},
		{
			unixtime: 1656730581,
			balance: 99672.08
		},
		{
			unixtime: 1656730641,
			balance: 99672.08
		},
		{
			unixtime: 1656730701,
			balance: 99672.08
		},
		{
			unixtime: 1656730761,
			balance: 99672.08
		},
		{
			unixtime: 1656730821,
			balance: 99672.08
		},
		{
			unixtime: 1656730881,
			balance: 99672.08
		},
		{
			unixtime: 1656730941,
			balance: 99672.08
		},
		{
			unixtime: 1656731001,
			balance: 99672.08
		},
		{
			unixtime: 1656731061,
			balance: 99672.08
		},
		{
			unixtime: 1656731121,
			balance: 99672.08
		},
		{
			unixtime: 1656731181,
			balance: 99672.08
		},
		{
			unixtime: 1656731241,
			balance: 99672.08
		},
		{
			unixtime: 1656731301,
			balance: 99672.08
		},
		{
			unixtime: 1656731361,
			balance: 99672.08
		},
		{
			unixtime: 1656731421,
			balance: 99672.08
		},
		{
			unixtime: 1656731481,
			balance: 99672.08
		},
		{
			unixtime: 1656731541,
			balance: 99672.08
		},
		{
			unixtime: 1656731601,
			balance: 99672.08
		},
		{
			unixtime: 1656731661,
			balance: 99672.08
		},
		{
			unixtime: 1656731721,
			balance: 99672.08
		},
		{
			unixtime: 1656731781,
			balance: 99672.08
		},
		{
			unixtime: 1656731841,
			balance: 99672.08
		},
		{
			unixtime: 1656731901,
			balance: 99672.08
		},
		{
			unixtime: 1656731961,
			balance: 99672.08
		},
		{
			unixtime: 1656732021,
			balance: 99672.08
		},
		{
			unixtime: 1656732081,
			balance: 99672.08
		},
		{
			unixtime: 1656732141,
			balance: 99672.08
		},
		{
			unixtime: 1656732201,
			balance: 99672.08
		},
		{
			unixtime: 1656732261,
			balance: 99672.08
		},
		{
			unixtime: 1656732321,
			balance: 99672.08
		},
		{
			unixtime: 1656732381,
			balance: 99672.08
		},
		{
			unixtime: 1656732441,
			balance: 99672.08
		},
		{
			unixtime: 1656732501,
			balance: 99672.08
		},
		{
			unixtime: 1656732561,
			balance: 99672.08
		},
		{
			unixtime: 1656732621,
			balance: 99672.08
		},
		{
			unixtime: 1656732681,
			balance: 99672.08
		},
		{
			unixtime: 1656732741,
			balance: 99672.08
		},
		{
			unixtime: 1656732801,
			balance: 99672.08
		},
		{
			unixtime: 1656732861,
			balance: 99672.08
		},
		{
			unixtime: 1656732921,
			balance: 99672.08
		},
		{
			unixtime: 1656732981,
			balance: 99672.08
		},
		{
			unixtime: 1656733041,
			balance: 99672.08
		},
		{
			unixtime: 1656733101,
			balance: 99672.08
		},
		{
			unixtime: 1656733161,
			balance: 99672.08
		},
		{
			unixtime: 1656733221,
			balance: 99672.08
		},
		{
			unixtime: 1656733281,
			balance: 99672.08
		},
		{
			unixtime: 1656733341,
			balance: 99672.08
		},
		{
			unixtime: 1656733401,
			balance: 99672.08
		},
		{
			unixtime: 1656733461,
			balance: 99672.08
		},
		{
			unixtime: 1656733521,
			balance: 99672.08
		},
		{
			unixtime: 1656733581,
			balance: 99672.08
		},
		{
			unixtime: 1656733641,
			balance: 99672.08
		},
		{
			unixtime: 1656733701,
			balance: 99672.08
		},
		{
			unixtime: 1656733761,
			balance: 99672.08
		},
		{
			unixtime: 1656733821,
			balance: 99672.08
		},
		{
			unixtime: 1656733881,
			balance: 99672.08
		},
		{
			unixtime: 1656733941,
			balance: 99672.08
		},
		{
			unixtime: 1656734001,
			balance: 99672.08
		},
		{
			unixtime: 1656734061,
			balance: 99672.08
		},
		{
			unixtime: 1656734121,
			balance: 99672.08
		},
		{
			unixtime: 1656734181,
			balance: 99672.08
		},
		{
			unixtime: 1656734241,
			balance: 99672.08
		},
		{
			unixtime: 1656734301,
			balance: 99672.08
		},
		{
			unixtime: 1656734361,
			balance: 99672.08
		},
		{
			unixtime: 1656734421,
			balance: 99672.08
		},
		{
			unixtime: 1656734481,
			balance: 99672.08
		},
		{
			unixtime: 1656734541,
			balance: 99672.08
		},
		{
			unixtime: 1656734601,
			balance: 99672.08
		},
		{
			unixtime: 1656734661,
			balance: 99672.08
		},
		{
			unixtime: 1656734721,
			balance: 99672.08
		},
		{
			unixtime: 1656734781,
			balance: 99672.08
		},
		{
			unixtime: 1656734841,
			balance: 99672.08
		},
		{
			unixtime: 1656734901,
			balance: 99672.08
		},
		{
			unixtime: 1656734961,
			balance: 99672.08
		},
		{
			unixtime: 1656735021,
			balance: 99672.08
		},
		{
			unixtime: 1656735081,
			balance: 99672.08
		},
		{
			unixtime: 1656735141,
			balance: 99672.08
		},
		{
			unixtime: 1656735201,
			balance: 99672.08
		},
		{
			unixtime: 1656735261,
			balance: 99672.08
		},
		{
			unixtime: 1656735321,
			balance: 99672.08
		},
		{
			unixtime: 1656735381,
			balance: 99672.08
		},
		{
			unixtime: 1656735441,
			balance: 99672.08
		},
		{
			unixtime: 1656735501,
			balance: 99672.08
		},
		{
			unixtime: 1656735561,
			balance: 99672.08
		},
		{
			unixtime: 1656735621,
			balance: 99672.08
		},
		{
			unixtime: 1656735681,
			balance: 99672.08
		},
		{
			unixtime: 1656735741,
			balance: 99672.08
		},
		{
			unixtime: 1656735801,
			balance: 99672.08
		},
		{
			unixtime: 1656735861,
			balance: 99672.08
		},
		{
			unixtime: 1656735921,
			balance: 99672.08
		},
		{
			unixtime: 1656735981,
			balance: 99672.08
		},
		{
			unixtime: 1656736041,
			balance: 99672.08
		},
		{
			unixtime: 1656736101,
			balance: 99672.08
		},
		{
			unixtime: 1656736161,
			balance: 99672.08
		},
		{
			unixtime: 1656736221,
			balance: 99672.08
		},
		{
			unixtime: 1656736281,
			balance: 99672.08
		},
		{
			unixtime: 1656736341,
			balance: 99672.08
		},
		{
			unixtime: 1656736401,
			balance: 99672.08
		},
		{
			unixtime: 1656736461,
			balance: 99672.08
		},
		{
			unixtime: 1656736521,
			balance: 99672.08
		},
		{
			unixtime: 1656736581,
			balance: 99672.08
		},
		{
			unixtime: 1656736641,
			balance: 99672.08
		},
		{
			unixtime: 1656736701,
			balance: 99672.08
		},
		{
			unixtime: 1656736761,
			balance: 99672.08
		},
		{
			unixtime: 1656736821,
			balance: 99672.08
		},
		{
			unixtime: 1656736881,
			balance: 99672.08
		},
		{
			unixtime: 1656736941,
			balance: 99672.08
		},
		{
			unixtime: 1656737001,
			balance: 99672.08
		},
		{
			unixtime: 1656737061,
			balance: 99672.08
		},
		{
			unixtime: 1656737121,
			balance: 99672.08
		},
		{
			unixtime: 1656737181,
			balance: 99672.08
		},
		{
			unixtime: 1656737241,
			balance: 99672.08
		},
		{
			unixtime: 1656737301,
			balance: 99672.08
		},
		{
			unixtime: 1656737361,
			balance: 99672.08
		},
		{
			unixtime: 1656737421,
			balance: 99672.08
		},
		{
			unixtime: 1656737481,
			balance: 99672.08
		},
		{
			unixtime: 1656737541,
			balance: 99672.08
		},
		{
			unixtime: 1656737601,
			balance: 99672.08
		},
		{
			unixtime: 1656737661,
			balance: 99672.08
		},
		{
			unixtime: 1656737721,
			balance: 99672.08
		},
		{
			unixtime: 1656737781,
			balance: 99672.08
		},
		{
			unixtime: 1656737841,
			balance: 99672.08
		},
		{
			unixtime: 1656737901,
			balance: 99672.08
		},
		{
			unixtime: 1656737961,
			balance: 99672.08
		},
		{
			unixtime: 1656738021,
			balance: 99672.08
		},
		{
			unixtime: 1656738081,
			balance: 99672.08
		},
		{
			unixtime: 1656738141,
			balance: 99672.08
		},
		{
			unixtime: 1656738201,
			balance: 99672.08
		},
		{
			unixtime: 1656738261,
			balance: 99672.08
		},
		{
			unixtime: 1656738321,
			balance: 99672.08
		},
		{
			unixtime: 1656738381,
			balance: 99672.08
		},
		{
			unixtime: 1656738441,
			balance: 99672.08
		},
		{
			unixtime: 1656738501,
			balance: 99672.08
		},
		{
			unixtime: 1656738561,
			balance: 99672.08
		},
		{
			unixtime: 1656738621,
			balance: 99672.08
		},
		{
			unixtime: 1656738681,
			balance: 99672.08
		},
		{
			unixtime: 1656738741,
			balance: 99672.08
		},
		{
			unixtime: 1656738801,
			balance: 99672.08
		},
		{
			unixtime: 1656738861,
			balance: 99672.08
		},
		{
			unixtime: 1656738921,
			balance: 99672.08
		},
		{
			unixtime: 1656738981,
			balance: 99672.08
		},
		{
			unixtime: 1656739041,
			balance: 99672.08
		},
		{
			unixtime: 1656739101,
			balance: 99672.08
		},
		{
			unixtime: 1656739161,
			balance: 99672.08
		},
		{
			unixtime: 1656739221,
			balance: 99672.08
		},
		{
			unixtime: 1656739281,
			balance: 99672.08
		},
		{
			unixtime: 1656739341,
			balance: 99672.08
		},
		{
			unixtime: 1656739401,
			balance: 99672.08
		},
		{
			unixtime: 1656739461,
			balance: 99672.08
		},
		{
			unixtime: 1656739521,
			balance: 99672.08
		},
		{
			unixtime: 1656739581,
			balance: 99672.08
		},
		{
			unixtime: 1656739641,
			balance: 99672.08
		},
		{
			unixtime: 1656739701,
			balance: 99672.08
		},
		{
			unixtime: 1656739761,
			balance: 99672.08
		},
		{
			unixtime: 1656739821,
			balance: 99672.08
		},
		{
			unixtime: 1656739881,
			balance: 99672.08
		},
		{
			unixtime: 1656739941,
			balance: 99672.08
		},
		{
			unixtime: 1656740001,
			balance: 99672.08
		},
		{
			unixtime: 1656740061,
			balance: 99672.08
		},
		{
			unixtime: 1656740121,
			balance: 99672.08
		},
		{
			unixtime: 1656740181,
			balance: 99672.08
		},
		{
			unixtime: 1656740241,
			balance: 99672.08
		},
		{
			unixtime: 1656740301,
			balance: 99672.08
		},
		{
			unixtime: 1656740361,
			balance: 99672.08
		},
		{
			unixtime: 1656740421,
			balance: 99672.08
		},
		{
			unixtime: 1656740481,
			balance: 99672.08
		},
		{
			unixtime: 1656740541,
			balance: 99672.08
		},
		{
			unixtime: 1656740601,
			balance: 99672.08
		},
		{
			unixtime: 1656740661,
			balance: 99672.08
		},
		{
			unixtime: 1656740721,
			balance: 99672.08
		},
		{
			unixtime: 1656740781,
			balance: 99672.08
		},
		{
			unixtime: 1656740841,
			balance: 99672.08
		},
		{
			unixtime: 1656740901,
			balance: 99672.08
		},
		{
			unixtime: 1656740961,
			balance: 99672.08
		},
		{
			unixtime: 1656741021,
			balance: 99672.08
		},
		{
			unixtime: 1656741081,
			balance: 99672.08
		},
		{
			unixtime: 1656741141,
			balance: 99672.08
		},
		{
			unixtime: 1656741201,
			balance: 99672.08
		},
		{
			unixtime: 1656741261,
			balance: 99672.08
		},
		{
			unixtime: 1656741321,
			balance: 99672.08
		},
		{
			unixtime: 1656741381,
			balance: 99672.08
		},
		{
			unixtime: 1656741441,
			balance: 99672.08
		},
		{
			unixtime: 1656741501,
			balance: 99672.08
		},
		{
			unixtime: 1656741561,
			balance: 99672.08
		},
		{
			unixtime: 1656741621,
			balance: 99672.08
		},
		{
			unixtime: 1656741681,
			balance: 99672.08
		},
		{
			unixtime: 1656741741,
			balance: 99672.08
		},
		{
			unixtime: 1656741801,
			balance: 99672.08
		},
		{
			unixtime: 1656741861,
			balance: 99672.08
		},
		{
			unixtime: 1656741921,
			balance: 99672.08
		},
		{
			unixtime: 1656741981,
			balance: 99672.08
		},
		{
			unixtime: 1656742042,
			balance: 99672.08
		},
		{
			unixtime: 1656742101,
			balance: 99672.08
		},
		{
			unixtime: 1656742161,
			balance: 99672.08
		},
		{
			unixtime: 1656742221,
			balance: 99672.08
		},
		{
			unixtime: 1656742281,
			balance: 99672.08
		},
		{
			unixtime: 1656742341,
			balance: 99672.08
		},
		{
			unixtime: 1656742401,
			balance: 99672.08
		},
		{
			unixtime: 1656742461,
			balance: 99672.08
		},
		{
			unixtime: 1656742521,
			balance: 99672.08
		},
		{
			unixtime: 1656742581,
			balance: 99672.08
		},
		{
			unixtime: 1656742641,
			balance: 99672.08
		},
		{
			unixtime: 1656742701,
			balance: 99672.08
		},
		{
			unixtime: 1656742761,
			balance: 99672.08
		},
		{
			unixtime: 1656742821,
			balance: 99672.08
		},
		{
			unixtime: 1656742881,
			balance: 99672.08
		},
		{
			unixtime: 1656742941,
			balance: 99672.08
		},
		{
			unixtime: 1656743001,
			balance: 99672.08
		},
		{
			unixtime: 1656743061,
			balance: 99672.08
		},
		{
			unixtime: 1656743121,
			balance: 99672.08
		},
		{
			unixtime: 1656743181,
			balance: 99672.08
		},
		{
			unixtime: 1656743241,
			balance: 99672.08
		},
		{
			unixtime: 1656743301,
			balance: 99672.08
		},
		{
			unixtime: 1656743361,
			balance: 99672.08
		},
		{
			unixtime: 1656743421,
			balance: 99672.08
		},
		{
			unixtime: 1656743481,
			balance: 99672.08
		},
		{
			unixtime: 1656743541,
			balance: 99672.08
		},
		{
			unixtime: 1656743601,
			balance: 99672.08
		},
		{
			unixtime: 1656743661,
			balance: 99672.08
		},
		{
			unixtime: 1656743721,
			balance: 99672.08
		},
		{
			unixtime: 1656743781,
			balance: 99672.08
		},
		{
			unixtime: 1656743841,
			balance: 99672.08
		},
		{
			unixtime: 1656743901,
			balance: 99672.08
		},
		{
			unixtime: 1656743961,
			balance: 99672.08
		},
		{
			unixtime: 1656744021,
			balance: 99672.08
		},
		{
			unixtime: 1656744081,
			balance: 99672.08
		},
		{
			unixtime: 1656744141,
			balance: 99672.08
		},
		{
			unixtime: 1656744201,
			balance: 99672.08
		},
		{
			unixtime: 1656744261,
			balance: 99672.08
		},
		{
			unixtime: 1656744321,
			balance: 99672.08
		},
		{
			unixtime: 1656744381,
			balance: 99672.08
		},
		{
			unixtime: 1656744441,
			balance: 99672.08
		},
		{
			unixtime: 1656744501,
			balance: 99672.08
		},
		{
			unixtime: 1656744561,
			balance: 99672.08
		},
		{
			unixtime: 1656744621,
			balance: 99672.08
		},
		{
			unixtime: 1656744681,
			balance: 99672.08
		},
		{
			unixtime: 1656744741,
			balance: 99672.08
		},
		{
			unixtime: 1656744801,
			balance: 99672.08
		},
		{
			unixtime: 1656744861,
			balance: 99672.08
		},
		{
			unixtime: 1656744921,
			balance: 99672.08
		},
		{
			unixtime: 1656744981,
			balance: 99672.08
		},
		{
			unixtime: 1656745041,
			balance: 99672.08
		},
		{
			unixtime: 1656745101,
			balance: 99672.08
		},
		{
			unixtime: 1656745161,
			balance: 99672.08
		},
		{
			unixtime: 1656745221,
			balance: 99672.08
		},
		{
			unixtime: 1656745281,
			balance: 99672.08
		},
		{
			unixtime: 1656745341,
			balance: 99672.08
		},
		{
			unixtime: 1656745401,
			balance: 99672.08
		},
		{
			unixtime: 1656745461,
			balance: 99672.08
		},
		{
			unixtime: 1656745521,
			balance: 99672.08
		},
		{
			unixtime: 1656745581,
			balance: 99672.08
		},
		{
			unixtime: 1656745641,
			balance: 99672.08
		},
		{
			unixtime: 1656745701,
			balance: 99672.08
		},
		{
			unixtime: 1656745761,
			balance: 99672.08
		},
		{
			unixtime: 1656745821,
			balance: 99672.08
		},
		{
			unixtime: 1656745881,
			balance: 99672.08
		},
		{
			unixtime: 1656745941,
			balance: 99672.08
		},
		{
			unixtime: 1656746001,
			balance: 99672.08
		},
		{
			unixtime: 1656746061,
			balance: 99672.08
		},
		{
			unixtime: 1656746121,
			balance: 99672.08
		},
		{
			unixtime: 1656746181,
			balance: 99672.08
		},
		{
			unixtime: 1656746241,
			balance: 99672.08
		},
		{
			unixtime: 1656746301,
			balance: 99672.08
		},
		{
			unixtime: 1656746361,
			balance: 99672.08
		},
		{
			unixtime: 1656746421,
			balance: 99672.08
		},
		{
			unixtime: 1656746481,
			balance: 99672.08
		},
		{
			unixtime: 1656746541,
			balance: 99672.08
		},
		{
			unixtime: 1656746601,
			balance: 99672.08
		},
		{
			unixtime: 1656746661,
			balance: 99672.08
		},
		{
			unixtime: 1656746721,
			balance: 99672.08
		},
		{
			unixtime: 1656746781,
			balance: 99672.08
		},
		{
			unixtime: 1656746841,
			balance: 99672.08
		},
		{
			unixtime: 1656746901,
			balance: 99672.08
		},
		{
			unixtime: 1656746962,
			balance: 99672.08
		},
		{
			unixtime: 1656747021,
			balance: 99672.08
		},
		{
			unixtime: 1656747081,
			balance: 99672.08
		},
		{
			unixtime: 1656747141,
			balance: 99672.08
		},
		{
			unixtime: 1656747201,
			balance: 99672.08
		},
		{
			unixtime: 1656747261,
			balance: 99672.08
		},
		{
			unixtime: 1656747321,
			balance: 99672.08
		},
		{
			unixtime: 1656747381,
			balance: 99672.08
		},
		{
			unixtime: 1656747441,
			balance: 99672.08
		},
		{
			unixtime: 1656747501,
			balance: 99672.08
		},
		{
			unixtime: 1656747561,
			balance: 99672.08
		},
		{
			unixtime: 1656747621,
			balance: 99672.08
		},
		{
			unixtime: 1656747681,
			balance: 99672.08
		},
		{
			unixtime: 1656747741,
			balance: 99672.08
		},
		{
			unixtime: 1656747801,
			balance: 99672.08
		},
		{
			unixtime: 1656747861,
			balance: 99672.08
		},
		{
			unixtime: 1656747921,
			balance: 99672.08
		},
		{
			unixtime: 1656747982,
			balance: 99672.08
		},
		{
			unixtime: 1656748041,
			balance: 99672.08
		},
		{
			unixtime: 1656748101,
			balance: 99672.08
		},
		{
			unixtime: 1656748161,
			balance: 99672.08
		},
		{
			unixtime: 1656748221,
			balance: 99672.08
		},
		{
			unixtime: 1656748281,
			balance: 99672.08
		},
		{
			unixtime: 1656748341,
			balance: 99672.08
		},
		{
			unixtime: 1656748401,
			balance: 99672.08
		},
		{
			unixtime: 1656748461,
			balance: 99672.08
		},
		{
			unixtime: 1656748521,
			balance: 99672.08
		},
		{
			unixtime: 1656748581,
			balance: 99672.08
		},
		{
			unixtime: 1656748641,
			balance: 99672.08
		},
		{
			unixtime: 1656748701,
			balance: 99672.08
		},
		{
			unixtime: 1656748761,
			balance: 99672.08
		},
		{
			unixtime: 1656748821,
			balance: 99672.08
		},
		{
			unixtime: 1656748881,
			balance: 99672.08
		},
		{
			unixtime: 1656748941,
			balance: 99672.08
		},
		{
			unixtime: 1656749001,
			balance: 99672.08
		},
		{
			unixtime: 1656749061,
			balance: 99672.08
		},
		{
			unixtime: 1656749121,
			balance: 99672.08
		},
		{
			unixtime: 1656749181,
			balance: 99672.08
		},
		{
			unixtime: 1656749241,
			balance: 99672.08
		},
		{
			unixtime: 1656749301,
			balance: 99672.08
		},
		{
			unixtime: 1656749361,
			balance: 99672.08
		},
		{
			unixtime: 1656749421,
			balance: 99672.08
		},
		{
			unixtime: 1656749481,
			balance: 99672.08
		},
		{
			unixtime: 1656749541,
			balance: 99672.08
		},
		{
			unixtime: 1656749601,
			balance: 99672.08
		},
		{
			unixtime: 1656749661,
			balance: 99672.08
		},
		{
			unixtime: 1656749721,
			balance: 99672.08
		},
		{
			unixtime: 1656749781,
			balance: 99672.08
		},
		{
			unixtime: 1656749841,
			balance: 99672.08
		},
		{
			unixtime: 1656749901,
			balance: 99672.08
		},
		{
			unixtime: 1656749961,
			balance: 99672.08
		},
		{
			unixtime: 1656750021,
			balance: 99672.08
		},
		{
			unixtime: 1656750081,
			balance: 99672.08
		},
		{
			unixtime: 1656750141,
			balance: 99672.08
		},
		{
			unixtime: 1656750201,
			balance: 99672.08
		},
		{
			unixtime: 1656750261,
			balance: 99672.08
		},
		{
			unixtime: 1656750321,
			balance: 99672.08
		},
		{
			unixtime: 1656750381,
			balance: 99672.08
		},
		{
			unixtime: 1656750441,
			balance: 99672.08
		},
		{
			unixtime: 1656750501,
			balance: 99672.08
		},
		{
			unixtime: 1656750561,
			balance: 99672.08
		},
		{
			unixtime: 1656750621,
			balance: 99672.08
		},
		{
			unixtime: 1656750681,
			balance: 99672.08
		},
		{
			unixtime: 1656750741,
			balance: 99672.08
		},
		{
			unixtime: 1656750801,
			balance: 99672.08
		},
		{
			unixtime: 1656750861,
			balance: 99672.08
		},
		{
			unixtime: 1656750921,
			balance: 99672.08
		},
		{
			unixtime: 1656750981,
			balance: 99672.08
		},
		{
			unixtime: 1656751041,
			balance: 99672.08
		},
		{
			unixtime: 1656751101,
			balance: 99672.08
		},
		{
			unixtime: 1656751161,
			balance: 99672.08
		},
		{
			unixtime: 1656751222,
			balance: 99672.08
		},
		{
			unixtime: 1656751281,
			balance: 99672.08
		},
		{
			unixtime: 1656751341,
			balance: 99672.08
		},
		{
			unixtime: 1656751401,
			balance: 99672.08
		},
		{
			unixtime: 1656751461,
			balance: 99672.08
		},
		{
			unixtime: 1656751521,
			balance: 99672.08
		},
		{
			unixtime: 1656751581,
			balance: 99672.08
		},
		{
			unixtime: 1656751641,
			balance: 99672.08
		},
		{
			unixtime: 1656751701,
			balance: 99672.08
		},
		{
			unixtime: 1656751761,
			balance: 99672.08
		},
		{
			unixtime: 1656751821,
			balance: 99672.08
		},
		{
			unixtime: 1656751881,
			balance: 99672.08
		},
		{
			unixtime: 1656751941,
			balance: 99672.08
		},
		{
			unixtime: 1656752001,
			balance: 99672.08
		},
		{
			unixtime: 1656752062,
			balance: 99672.08
		},
		{
			unixtime: 1656752121,
			balance: 99672.08
		},
		{
			unixtime: 1656752181,
			balance: 99672.08
		},
		{
			unixtime: 1656752241,
			balance: 99672.08
		},
		{
			unixtime: 1656752301,
			balance: 99672.08
		},
		{
			unixtime: 1656752361,
			balance: 99672.08
		},
		{
			unixtime: 1656752421,
			balance: 99672.08
		},
		{
			unixtime: 1656752481,
			balance: 99672.08
		},
		{
			unixtime: 1656752541,
			balance: 99672.08
		},
		{
			unixtime: 1656752601,
			balance: 99672.08
		},
		{
			unixtime: 1656752661,
			balance: 99672.08
		},
		{
			unixtime: 1656752722,
			balance: 99672.08
		},
		{
			unixtime: 1656752781,
			balance: 99672.08
		},
		{
			unixtime: 1656752841,
			balance: 99672.08
		},
		{
			unixtime: 1656752901,
			balance: 99672.08
		},
		{
			unixtime: 1656752961,
			balance: 99672.08
		},
		{
			unixtime: 1656753021,
			balance: 99672.08
		},
		{
			unixtime: 1656753081,
			balance: 99672.08
		},
		{
			unixtime: 1656753141,
			balance: 99672.08
		},
		{
			unixtime: 1656753201,
			balance: 99672.08
		},
		{
			unixtime: 1656753261,
			balance: 99672.08
		},
		{
			unixtime: 1656753321,
			balance: 99672.08
		},
		{
			unixtime: 1656753381,
			balance: 99672.08
		},
		{
			unixtime: 1656753441,
			balance: 99672.08
		},
		{
			unixtime: 1656753501,
			balance: 99672.08
		},
		{
			unixtime: 1656753561,
			balance: 99672.08
		},
		{
			unixtime: 1656753621,
			balance: 99672.08
		},
		{
			unixtime: 1656753682,
			balance: 99672.08
		},
		{
			unixtime: 1656753741,
			balance: 99672.08
		},
		{
			unixtime: 1656753801,
			balance: 99672.08
		},
		{
			unixtime: 1656753862,
			balance: 99672.08
		},
		{
			unixtime: 1656753921,
			balance: 99672.08
		},
		{
			unixtime: 1656753981,
			balance: 99672.08
		},
		{
			unixtime: 1656754041,
			balance: 99672.08
		},
		{
			unixtime: 1656754101,
			balance: 99672.08
		},
		{
			unixtime: 1656754161,
			balance: 99672.08
		},
		{
			unixtime: 1656754221,
			balance: 99672.08
		},
		{
			unixtime: 1656754281,
			balance: 99672.08
		},
		{
			unixtime: 1656754341,
			balance: 99672.08
		},
		{
			unixtime: 1656754401,
			balance: 99672.08
		},
		{
			unixtime: 1656754461,
			balance: 99672.08
		},
		{
			unixtime: 1656754522,
			balance: 99672.08
		},
		{
			unixtime: 1656754581,
			balance: 99672.08
		},
		{
			unixtime: 1656754641,
			balance: 99672.08
		},
		{
			unixtime: 1656754701,
			balance: 99672.08
		},
		{
			unixtime: 1656754761,
			balance: 99672.08
		},
		{
			unixtime: 1656754821,
			balance: 99672.08
		},
		{
			unixtime: 1656754881,
			balance: 99672.08
		},
		{
			unixtime: 1656754942,
			balance: 99672.08
		},
		{
			unixtime: 1656755001,
			balance: 99672.08
		},
		{
			unixtime: 1656755061,
			balance: 99672.08
		},
		{
			unixtime: 1656755121,
			balance: 99672.08
		},
		{
			unixtime: 1656755181,
			balance: 99672.08
		},
		{
			unixtime: 1656755241,
			balance: 99672.08
		},
		{
			unixtime: 1656755301,
			balance: 99672.08
		},
		{
			unixtime: 1656755361,
			balance: 99672.08
		},
		{
			unixtime: 1656755421,
			balance: 99672.08
		},
		{
			unixtime: 1656755481,
			balance: 99672.08
		},
		{
			unixtime: 1656755541,
			balance: 99672.08
		},
		{
			unixtime: 1656755601,
			balance: 99672.08
		},
		{
			unixtime: 1656755661,
			balance: 99672.08
		},
		{
			unixtime: 1656755721,
			balance: 99672.08
		},
		{
			unixtime: 1656755781,
			balance: 99672.08
		},
		{
			unixtime: 1656755841,
			balance: 99672.08
		},
		{
			unixtime: 1656755901,
			balance: 99672.08
		},
		{
			unixtime: 1656755961,
			balance: 99672.08
		},
		{
			unixtime: 1656756021,
			balance: 99672.08
		},
		{
			unixtime: 1656756081,
			balance: 99672.08
		},
		{
			unixtime: 1656756142,
			balance: 99672.08
		},
		{
			unixtime: 1656756201,
			balance: 99672.08
		},
		{
			unixtime: 1656756261,
			balance: 99672.08
		},
		{
			unixtime: 1656756321,
			balance: 99672.08
		},
		{
			unixtime: 1656756381,
			balance: 99672.08
		},
		{
			unixtime: 1656756441,
			balance: 99672.08
		},
		{
			unixtime: 1656756501,
			balance: 99672.08
		},
		{
			unixtime: 1656756561,
			balance: 99672.08
		},
		{
			unixtime: 1656756622,
			balance: 99672.08
		},
		{
			unixtime: 1656756682,
			balance: 99672.08
		},
		{
			unixtime: 1656756741,
			balance: 99672.08
		},
		{
			unixtime: 1656756801,
			balance: 99672.08
		},
		{
			unixtime: 1656756861,
			balance: 99672.08
		},
		{
			unixtime: 1656756922,
			balance: 99672.08
		},
		{
			unixtime: 1656756982,
			balance: 99672.08
		},
		{
			unixtime: 1656757042,
			balance: 99672.08
		},
		{
			unixtime: 1656757102,
			balance: 99672.08
		},
		{
			unixtime: 1656757162,
			balance: 99672.08
		},
		{
			unixtime: 1656757222,
			balance: 99672.08
		},
		{
			unixtime: 1656757282,
			balance: 99672.08
		},
		{
			unixtime: 1656757342,
			balance: 99672.08
		},
		{
			unixtime: 1656757402,
			balance: 99672.08
		},
		{
			unixtime: 1656757462,
			balance: 99672.08
		},
		{
			unixtime: 1656757522,
			balance: 99672.08
		},
		{
			unixtime: 1656757582,
			balance: 99672.08
		},
		{
			unixtime: 1656757642,
			balance: 99672.08
		},
		{
			unixtime: 1656757702,
			balance: 99672.08
		},
		{
			unixtime: 1656757762,
			balance: 99672.08
		},
		{
			unixtime: 1656757822,
			balance: 99672.08
		},
		{
			unixtime: 1656757882,
			balance: 99672.08
		},
		{
			unixtime: 1656757942,
			balance: 99672.08
		},
		{
			unixtime: 1656758002,
			balance: 99672.08
		},
		{
			unixtime: 1656758062,
			balance: 99672.08
		},
		{
			unixtime: 1656758122,
			balance: 99672.08
		},
		{
			unixtime: 1656758182,
			balance: 99672.08
		},
		{
			unixtime: 1656758242,
			balance: 99672.08
		},
		{
			unixtime: 1656758302,
			balance: 99672.08
		},
		{
			unixtime: 1656758362,
			balance: 99672.08
		},
		{
			unixtime: 1656758422,
			balance: 99672.08
		},
		{
			unixtime: 1656758482,
			balance: 99672.08
		},
		{
			unixtime: 1656758542,
			balance: 99672.08
		},
		{
			unixtime: 1656758602,
			balance: 99672.08
		},
		{
			unixtime: 1656758662,
			balance: 99672.08
		},
		{
			unixtime: 1656758722,
			balance: 99672.08
		},
		{
			unixtime: 1656758782,
			balance: 99672.08
		},
		{
			unixtime: 1656758842,
			balance: 99672.08
		},
		{
			unixtime: 1656758902,
			balance: 99672.08
		},
		{
			unixtime: 1656758962,
			balance: 99672.08
		},
		{
			unixtime: 1656759022,
			balance: 99672.08
		},
		{
			unixtime: 1656759082,
			balance: 99672.08
		},
		{
			unixtime: 1656759142,
			balance: 99672.08
		},
		{
			unixtime: 1656759202,
			balance: 99672.08
		},
		{
			unixtime: 1656759262,
			balance: 99672.08
		},
		{
			unixtime: 1656759322,
			balance: 99672.08
		},
		{
			unixtime: 1656759382,
			balance: 99672.08
		},
		{
			unixtime: 1656759442,
			balance: 99672.08
		},
		{
			unixtime: 1656759502,
			balance: 99672.08
		},
		{
			unixtime: 1656759562,
			balance: 99672.08
		},
		{
			unixtime: 1656759622,
			balance: 99672.08
		},
		{
			unixtime: 1656759682,
			balance: 99672.08
		},
		{
			unixtime: 1656759742,
			balance: 99672.08
		},
		{
			unixtime: 1656759802,
			balance: 99672.08
		},
		{
			unixtime: 1656759862,
			balance: 99672.08
		},
		{
			unixtime: 1656759922,
			balance: 99672.08
		},
		{
			unixtime: 1656759982,
			balance: 99672.08
		},
		{
			unixtime: 1656760042,
			balance: 99672.08
		},
		{
			unixtime: 1656760102,
			balance: 99672.08
		},
		{
			unixtime: 1656760162,
			balance: 99672.08
		},
		{
			unixtime: 1656760222,
			balance: 99672.08
		},
		{
			unixtime: 1656760282,
			balance: 99672.08
		},
		{
			unixtime: 1656760342,
			balance: 99672.08
		},
		{
			unixtime: 1656760402,
			balance: 99672.08
		},
		{
			unixtime: 1656760462,
			balance: 99672.08
		},
		{
			unixtime: 1656760522,
			balance: 99672.08
		},
		{
			unixtime: 1656760582,
			balance: 99672.08
		},
		{
			unixtime: 1656760642,
			balance: 99672.08
		},
		{
			unixtime: 1656760702,
			balance: 99672.08
		},
		{
			unixtime: 1656760762,
			balance: 99672.08
		},
		{
			unixtime: 1656760822,
			balance: 99672.08
		},
		{
			unixtime: 1656760882,
			balance: 99672.08
		},
		{
			unixtime: 1656760942,
			balance: 99672.08
		},
		{
			unixtime: 1656761002,
			balance: 99672.08
		},
		{
			unixtime: 1656761062,
			balance: 99672.08
		},
		{
			unixtime: 1656761122,
			balance: 99672.08
		},
		{
			unixtime: 1656761182,
			balance: 99672.08
		},
		{
			unixtime: 1656761242,
			balance: 99672.08
		},
		{
			unixtime: 1656761302,
			balance: 99672.08
		},
		{
			unixtime: 1656761362,
			balance: 99672.08
		},
		{
			unixtime: 1656761422,
			balance: 99672.08
		},
		{
			unixtime: 1656761482,
			balance: 99672.08
		},
		{
			unixtime: 1656761542,
			balance: 99672.08
		},
		{
			unixtime: 1656761602,
			balance: 99672.08
		},
		{
			unixtime: 1656761662,
			balance: 99672.08
		},
		{
			unixtime: 1656761722,
			balance: 99672.08
		},
		{
			unixtime: 1656761782,
			balance: 99672.08
		},
		{
			unixtime: 1656761842,
			balance: 99672.08
		},
		{
			unixtime: 1656761902,
			balance: 99672.08
		},
		{
			unixtime: 1656761962,
			balance: 99672.08
		},
		{
			unixtime: 1656762022,
			balance: 99672.08
		},
		{
			unixtime: 1656762082,
			balance: 99672.08
		},
		{
			unixtime: 1656762142,
			balance: 99672.08
		},
		{
			unixtime: 1656762202,
			balance: 99672.08
		},
		{
			unixtime: 1656762262,
			balance: 99672.08
		},
		{
			unixtime: 1656762322,
			balance: 99672.08
		},
		{
			unixtime: 1656762382,
			balance: 99672.08
		},
		{
			unixtime: 1656762442,
			balance: 99672.08
		},
		{
			unixtime: 1656762502,
			balance: 99672.08
		},
		{
			unixtime: 1656762562,
			balance: 99672.08
		},
		{
			unixtime: 1656762622,
			balance: 99672.08
		},
		{
			unixtime: 1656762682,
			balance: 99672.08
		},
		{
			unixtime: 1656762742,
			balance: 99672.08
		},
		{
			unixtime: 1656762802,
			balance: 99672.08
		},
		{
			unixtime: 1656762862,
			balance: 99672.08
		},
		{
			unixtime: 1656762922,
			balance: 99672.08
		},
		{
			unixtime: 1656762982,
			balance: 99672.08
		},
		{
			unixtime: 1656763042,
			balance: 99672.08
		},
		{
			unixtime: 1656763102,
			balance: 99672.08
		},
		{
			unixtime: 1656763162,
			balance: 99672.08
		},
		{
			unixtime: 1656763222,
			balance: 99672.08
		},
		{
			unixtime: 1656763282,
			balance: 99672.08
		},
		{
			unixtime: 1656763342,
			balance: 99672.08
		},
		{
			unixtime: 1656763402,
			balance: 99672.08
		},
		{
			unixtime: 1656763462,
			balance: 99672.08
		},
		{
			unixtime: 1656763522,
			balance: 99672.08
		},
		{
			unixtime: 1656763582,
			balance: 99672.08
		},
		{
			unixtime: 1656763642,
			balance: 99672.08
		},
		{
			unixtime: 1656763702,
			balance: 99672.08
		},
		{
			unixtime: 1656763762,
			balance: 99672.08
		},
		{
			unixtime: 1656763822,
			balance: 99672.08
		},
		{
			unixtime: 1656763882,
			balance: 99672.08
		},
		{
			unixtime: 1656763942,
			balance: 99672.08
		},
		{
			unixtime: 1656764002,
			balance: 99672.08
		},
		{
			unixtime: 1656764062,
			balance: 99672.08
		},
		{
			unixtime: 1656764122,
			balance: 99672.08
		},
		{
			unixtime: 1656764182,
			balance: 99672.08
		},
		{
			unixtime: 1656764242,
			balance: 99672.08
		},
		{
			unixtime: 1656764302,
			balance: 99672.08
		},
		{
			unixtime: 1656764362,
			balance: 99672.08
		},
		{
			unixtime: 1656764422,
			balance: 99672.08
		},
		{
			unixtime: 1656764482,
			balance: 99672.08
		},
		{
			unixtime: 1656764542,
			balance: 99672.08
		},
		{
			unixtime: 1656764602,
			balance: 99672.08
		},
		{
			unixtime: 1656764662,
			balance: 99672.08
		},
		{
			unixtime: 1656764722,
			balance: 99672.08
		},
		{
			unixtime: 1656764782,
			balance: 99672.08
		},
		{
			unixtime: 1656764842,
			balance: 99672.08
		},
		{
			unixtime: 1656764902,
			balance: 99672.08
		},
		{
			unixtime: 1656764962,
			balance: 99672.08
		},
		{
			unixtime: 1656765022,
			balance: 99672.08
		},
		{
			unixtime: 1656765082,
			balance: 99672.08
		},
		{
			unixtime: 1656765142,
			balance: 99672.08
		},
		{
			unixtime: 1656765202,
			balance: 99672.08
		},
		{
			unixtime: 1656765262,
			balance: 99672.08
		},
		{
			unixtime: 1656765322,
			balance: 99672.08
		},
		{
			unixtime: 1656765382,
			balance: 99672.08
		},
		{
			unixtime: 1656765442,
			balance: 99672.08
		},
		{
			unixtime: 1656765502,
			balance: 99672.08
		},
		{
			unixtime: 1656765562,
			balance: 99672.08
		},
		{
			unixtime: 1656765622,
			balance: 99672.08
		},
		{
			unixtime: 1656765682,
			balance: 99672.08
		},
		{
			unixtime: 1656765742,
			balance: 99672.08
		},
		{
			unixtime: 1656765802,
			balance: 99672.08
		},
		{
			unixtime: 1656765862,
			balance: 99672.08
		},
		{
			unixtime: 1656765922,
			balance: 99672.08
		},
		{
			unixtime: 1656765982,
			balance: 99672.08
		},
		{
			unixtime: 1656766042,
			balance: 99672.08
		},
		{
			unixtime: 1656766102,
			balance: 99672.08
		},
		{
			unixtime: 1656766162,
			balance: 99672.08
		},
		{
			unixtime: 1656766222,
			balance: 99672.08
		},
		{
			unixtime: 1656766282,
			balance: 99672.08
		},
		{
			unixtime: 1656766342,
			balance: 99672.08
		},
		{
			unixtime: 1656766402,
			balance: 99672.08
		},
		{
			unixtime: 1656766462,
			balance: 99672.08
		},
		{
			unixtime: 1656766522,
			balance: 99672.08
		},
		{
			unixtime: 1656766582,
			balance: 99672.08
		},
		{
			unixtime: 1656766642,
			balance: 99672.08
		},
		{
			unixtime: 1656766702,
			balance: 99672.08
		},
		{
			unixtime: 1656766762,
			balance: 99672.08
		},
		{
			unixtime: 1656766822,
			balance: 99672.08
		},
		{
			unixtime: 1656766882,
			balance: 99672.08
		},
		{
			unixtime: 1656766942,
			balance: 99672.08
		},
		{
			unixtime: 1656767002,
			balance: 99672.08
		},
		{
			unixtime: 1656767062,
			balance: 99672.08
		},
		{
			unixtime: 1656767122,
			balance: 99672.08
		},
		{
			unixtime: 1656767182,
			balance: 99672.08
		},
		{
			unixtime: 1656767242,
			balance: 99672.08
		},
		{
			unixtime: 1656767302,
			balance: 99672.08
		},
		{
			unixtime: 1656767362,
			balance: 99672.08
		},
		{
			unixtime: 1656767422,
			balance: 99672.08
		},
		{
			unixtime: 1656767482,
			balance: 99672.08
		},
		{
			unixtime: 1656767542,
			balance: 99672.08
		},
		{
			unixtime: 1656767602,
			balance: 99672.08
		},
		{
			unixtime: 1656767662,
			balance: 99672.08
		},
		{
			unixtime: 1656767722,
			balance: 99672.08
		},
		{
			unixtime: 1656767782,
			balance: 99672.08
		},
		{
			unixtime: 1656767842,
			balance: 99672.08
		},
		{
			unixtime: 1656767902,
			balance: 99672.08
		},
		{
			unixtime: 1656767962,
			balance: 99672.08
		},
		{
			unixtime: 1656768022,
			balance: 99672.08
		},
		{
			unixtime: 1656768082,
			balance: 99672.08
		},
		{
			unixtime: 1656768142,
			balance: 99672.08
		},
		{
			unixtime: 1656768202,
			balance: 99672.08
		},
		{
			unixtime: 1656768262,
			balance: 99672.08
		},
		{
			unixtime: 1656768322,
			balance: 99672.08
		},
		{
			unixtime: 1656768382,
			balance: 99672.08
		},
		{
			unixtime: 1656768442,
			balance: 99672.08
		},
		{
			unixtime: 1656768502,
			balance: 99672.08
		},
		{
			unixtime: 1656768562,
			balance: 99672.08
		},
		{
			unixtime: 1656768622,
			balance: 99672.08
		},
		{
			unixtime: 1656768682,
			balance: 99672.08
		},
		{
			unixtime: 1656768742,
			balance: 99672.08
		},
		{
			unixtime: 1656768802,
			balance: 99672.08
		},
		{
			unixtime: 1656768862,
			balance: 99672.08
		},
		{
			unixtime: 1656768922,
			balance: 99672.08
		},
		{
			unixtime: 1656768982,
			balance: 99672.08
		},
		{
			unixtime: 1656769042,
			balance: 99672.08
		},
		{
			unixtime: 1656769102,
			balance: 99672.08
		},
		{
			unixtime: 1656769162,
			balance: 99672.08
		},
		{
			unixtime: 1656769222,
			balance: 99672.08
		},
		{
			unixtime: 1656769282,
			balance: 99672.08
		},
		{
			unixtime: 1656769342,
			balance: 99672.08
		},
		{
			unixtime: 1656769402,
			balance: 99672.08
		},
		{
			unixtime: 1656769462,
			balance: 99672.08
		},
		{
			unixtime: 1656769522,
			balance: 99672.08
		},
		{
			unixtime: 1656769582,
			balance: 99672.08
		},
		{
			unixtime: 1656769642,
			balance: 99672.08
		},
		{
			unixtime: 1656769702,
			balance: 99672.08
		},
		{
			unixtime: 1656769762,
			balance: 99672.08
		},
		{
			unixtime: 1656769822,
			balance: 99672.08
		},
		{
			unixtime: 1656769882,
			balance: 99672.08
		},
		{
			unixtime: 1656769942,
			balance: 99672.08
		},
		{
			unixtime: 1656770002,
			balance: 99672.08
		},
		{
			unixtime: 1656770062,
			balance: 99672.08
		},
		{
			unixtime: 1656770122,
			balance: 99672.08
		},
		{
			unixtime: 1656770182,
			balance: 99672.08
		},
		{
			unixtime: 1656770242,
			balance: 99672.08
		},
		{
			unixtime: 1656770302,
			balance: 99672.08
		},
		{
			unixtime: 1656770362,
			balance: 99672.08
		},
		{
			unixtime: 1656770422,
			balance: 99672.08
		},
		{
			unixtime: 1656770482,
			balance: 99672.08
		},
		{
			unixtime: 1656770542,
			balance: 99672.08
		},
		{
			unixtime: 1656770602,
			balance: 99672.08
		},
		{
			unixtime: 1656770662,
			balance: 99672.08
		},
		{
			unixtime: 1656770722,
			balance: 99672.08
		},
		{
			unixtime: 1656770782,
			balance: 99672.08
		},
		{
			unixtime: 1656770842,
			balance: 99672.08
		},
		{
			unixtime: 1656770902,
			balance: 99672.08
		},
		{
			unixtime: 1656770962,
			balance: 99672.08
		},
		{
			unixtime: 1656771022,
			balance: 99672.08
		},
		{
			unixtime: 1656771082,
			balance: 99672.08
		},
		{
			unixtime: 1656771142,
			balance: 99672.08
		},
		{
			unixtime: 1656771202,
			balance: 99672.08
		},
		{
			unixtime: 1656771262,
			balance: 99672.08
		},
		{
			unixtime: 1656771322,
			balance: 99672.08
		},
		{
			unixtime: 1656771382,
			balance: 99672.08
		},
		{
			unixtime: 1656771442,
			balance: 99672.08
		},
		{
			unixtime: 1656771502,
			balance: 99672.08
		},
		{
			unixtime: 1656771562,
			balance: 99672.08
		},
		{
			unixtime: 1656771622,
			balance: 99672.08
		},
		{
			unixtime: 1656771682,
			balance: 99672.08
		},
		{
			unixtime: 1656771742,
			balance: 99672.08
		},
		{
			unixtime: 1656771802,
			balance: 99672.08
		},
		{
			unixtime: 1656771862,
			balance: 99672.08
		},
		{
			unixtime: 1656771922,
			balance: 99672.08
		},
		{
			unixtime: 1656771982,
			balance: 99672.08
		},
		{
			unixtime: 1656772042,
			balance: 99672.08
		},
		{
			unixtime: 1656772102,
			balance: 99672.08
		},
		{
			unixtime: 1656772162,
			balance: 99672.08
		},
		{
			unixtime: 1656772222,
			balance: 99672.08
		},
		{
			unixtime: 1656772282,
			balance: 99672.08
		},
		{
			unixtime: 1656772342,
			balance: 99672.08
		},
		{
			unixtime: 1656772402,
			balance: 99672.08
		},
		{
			unixtime: 1656772462,
			balance: 99672.08
		},
		{
			unixtime: 1656772522,
			balance: 99672.08
		},
		{
			unixtime: 1656772582,
			balance: 99672.08
		},
		{
			unixtime: 1656772642,
			balance: 99672.08
		},
		{
			unixtime: 1656772702,
			balance: 99672.08
		},
		{
			unixtime: 1656772762,
			balance: 99672.08
		},
		{
			unixtime: 1656772822,
			balance: 99672.08
		},
		{
			unixtime: 1656772882,
			balance: 99672.08
		},
		{
			unixtime: 1656772942,
			balance: 99672.08
		},
		{
			unixtime: 1656773002,
			balance: 99672.08
		},
		{
			unixtime: 1656773062,
			balance: 99672.08
		},
		{
			unixtime: 1656773122,
			balance: 99672.08
		},
		{
			unixtime: 1656773182,
			balance: 99672.08
		},
		{
			unixtime: 1656773242,
			balance: 99672.08
		},
		{
			unixtime: 1656773302,
			balance: 99672.08
		},
		{
			unixtime: 1656773362,
			balance: 99672.08
		},
		{
			unixtime: 1656773422,
			balance: 99672.08
		},
		{
			unixtime: 1656773482,
			balance: 99672.08
		},
		{
			unixtime: 1656773542,
			balance: 99672.08
		},
		{
			unixtime: 1656773602,
			balance: 99672.08
		},
		{
			unixtime: 1656773662,
			balance: 99672.08
		},
		{
			unixtime: 1656773722,
			balance: 99672.08
		},
		{
			unixtime: 1656773782,
			balance: 99672.08
		},
		{
			unixtime: 1656773842,
			balance: 99672.08
		},
		{
			unixtime: 1656773902,
			balance: 99672.08
		},
		{
			unixtime: 1656773962,
			balance: 99672.08
		},
		{
			unixtime: 1656774030,
			balance: 99672.08
		},
		{
			unixtime: 1656774090,
			balance: 99672.08
		},
		{
			unixtime: 1656774150,
			balance: 99672.08
		},
		{
			unixtime: 1656774299,
			balance: 99672.08
		},
		{
			unixtime: 1656774359,
			balance: 99672.08
		},
		{
			unixtime: 1656774419,
			balance: 99672.08
		},
		{
			unixtime: 1656774479,
			balance: 99672.08
		},
		{
			unixtime: 1656774539,
			balance: 99672.08
		},
		{
			unixtime: 1656774599,
			balance: 99672.08
		},
		{
			unixtime: 1656774659,
			balance: 99672.08
		},
		{
			unixtime: 1656774719,
			balance: 99672.08
		},
		{
			unixtime: 1656774779,
			balance: 99672.08
		},
		{
			unixtime: 1656774839,
			balance: 99672.08
		},
		{
			unixtime: 1656774899,
			balance: 99672.08
		},
		{
			unixtime: 1656774959,
			balance: 99672.08
		},
		{
			unixtime: 1656775019,
			balance: 99672.08
		},
		{
			unixtime: 1656775079,
			balance: 99672.08
		},
		{
			unixtime: 1656775179,
			balance: 99672.08
		},
		{
			unixtime: 1656775239,
			balance: 99672.08
		},
		{
			unixtime: 1656775299,
			balance: 99672.08
		},
		{
			unixtime: 1656775359,
			balance: 99672.08
		},
		{
			unixtime: 1656775419,
			balance: 99672.08
		},
		{
			unixtime: 1656775479,
			balance: 99672.08
		},
		{
			unixtime: 1656775539,
			balance: 99672.08
		},
		{
			unixtime: 1656775599,
			balance: 99672.08
		},
		{
			unixtime: 1656775659,
			balance: 99672.08
		},
		{
			unixtime: 1656775719,
			balance: 99672.08
		},
		{
			unixtime: 1656775779,
			balance: 99672.08
		},
		{
			unixtime: 1656775839,
			balance: 99672.08
		},
		{
			unixtime: 1656775899,
			balance: 99672.08
		},
		{
			unixtime: 1656775959,
			balance: 99672.08
		},
		{
			unixtime: 1656776019,
			balance: 99672.08
		},
		{
			unixtime: 1656776079,
			balance: 99672.08
		},
		{
			unixtime: 1656776139,
			balance: 99672.08
		},
		{
			unixtime: 1656776199,
			balance: 99672.08
		},
		{
			unixtime: 1656776259,
			balance: 99672.08
		},
		{
			unixtime: 1656776319,
			balance: 99672.08
		},
		{
			unixtime: 1656776379,
			balance: 99672.08
		},
		{
			unixtime: 1656776439,
			balance: 99672.08
		},
		{
			unixtime: 1656776499,
			balance: 99672.08
		},
		{
			unixtime: 1656776559,
			balance: 99672.08
		},
		{
			unixtime: 1656776619,
			balance: 99672.08
		},
		{
			unixtime: 1656776679,
			balance: 99672.08
		},
		{
			unixtime: 1656776739,
			balance: 99672.08
		},
		{
			unixtime: 1656776799,
			balance: 99672.08
		},
		{
			unixtime: 1656776859,
			balance: 99672.08
		},
		{
			unixtime: 1656776919,
			balance: 99672.08
		},
		{
			unixtime: 1656776979,
			balance: 99672.08
		},
		{
			unixtime: 1656777039,
			balance: 99672.08
		},
		{
			unixtime: 1656777099,
			balance: 99672.08
		},
		{
			unixtime: 1656777159,
			balance: 99672.08
		},
		{
			unixtime: 1656777219,
			balance: 99672.08
		},
		{
			unixtime: 1656777279,
			balance: 99672.08
		},
		{
			unixtime: 1656777339,
			balance: 99672.08
		},
		{
			unixtime: 1656777399,
			balance: 99672.08
		},
		{
			unixtime: 1656777459,
			balance: 99672.08
		},
		{
			unixtime: 1656777519,
			balance: 99672.08
		},
		{
			unixtime: 1656777579,
			balance: 99672.08
		},
		{
			unixtime: 1656777639,
			balance: 99672.08
		},
		{
			unixtime: 1656777699,
			balance: 99672.08
		},
		{
			unixtime: 1656777759,
			balance: 99672.08
		},
		{
			unixtime: 1656777819,
			balance: 99672.08
		},
		{
			unixtime: 1656777879,
			balance: 99672.08
		},
		{
			unixtime: 1656777939,
			balance: 99672.08
		},
		{
			unixtime: 1656777999,
			balance: 99672.08
		},
		{
			unixtime: 1656778059,
			balance: 99672.08
		},
		{
			unixtime: 1656778119,
			balance: 99672.08
		},
		{
			unixtime: 1656778179,
			balance: 99672.08
		},
		{
			unixtime: 1656778239,
			balance: 99672.08
		},
		{
			unixtime: 1656778299,
			balance: 99672.08
		},
		{
			unixtime: 1656778359,
			balance: 99672.08
		},
		{
			unixtime: 1656778419,
			balance: 99672.08
		},
		{
			unixtime: 1656778479,
			balance: 99672.08
		},
		{
			unixtime: 1656778539,
			balance: 99672.08
		},
		{
			unixtime: 1656778599,
			balance: 99672.08
		},
		{
			unixtime: 1656778659,
			balance: 99672.08
		},
		{
			unixtime: 1656778719,
			balance: 99672.08
		},
		{
			unixtime: 1656778779,
			balance: 99672.08
		},
		{
			unixtime: 1656778839,
			balance: 99672.08
		},
		{
			unixtime: 1656778899,
			balance: 99672.08
		},
		{
			unixtime: 1656778959,
			balance: 99672.08
		},
		{
			unixtime: 1656779019,
			balance: 99672.08
		},
		{
			unixtime: 1656779079,
			balance: 99672.08
		},
		{
			unixtime: 1656779139,
			balance: 99672.08
		},
		{
			unixtime: 1656779199,
			balance: 99672.08
		},
		{
			unixtime: 1656779259,
			balance: 99672.08
		},
		{
			unixtime: 1656779319,
			balance: 99672.08
		},
		{
			unixtime: 1656779379,
			balance: 99672.08
		},
		{
			unixtime: 1656779439,
			balance: 99672.08
		},
		{
			unixtime: 1656779499,
			balance: 99672.08
		},
		{
			unixtime: 1656779559,
			balance: 99672.08
		},
		{
			unixtime: 1656779619,
			balance: 99672.08
		},
		{
			unixtime: 1656779679,
			balance: 99672.08
		},
		{
			unixtime: 1656779739,
			balance: 99672.08
		},
		{
			unixtime: 1656779799,
			balance: 99672.08
		},
		{
			unixtime: 1656779859,
			balance: 99672.08
		},
		{
			unixtime: 1656779919,
			balance: 99672.08
		},
		{
			unixtime: 1656779979,
			balance: 99672.08
		},
		{
			unixtime: 1656780039,
			balance: 99672.08
		},
		{
			unixtime: 1656780099,
			balance: 99672.08
		},
		{
			unixtime: 1656780159,
			balance: 99672.08
		},
		{
			unixtime: 1656780219,
			balance: 99672.08
		},
		{
			unixtime: 1656780279,
			balance: 99672.08
		},
		{
			unixtime: 1656780339,
			balance: 99672.08
		},
		{
			unixtime: 1656780399,
			balance: 99672.08
		},
		{
			unixtime: 1656780459,
			balance: 99672.08
		},
		{
			unixtime: 1656780519,
			balance: 99672.08
		},
		{
			unixtime: 1656780579,
			balance: 99672.08
		},
		{
			unixtime: 1656780639,
			balance: 99672.08
		},
		{
			unixtime: 1656780699,
			balance: 99672.08
		},
		{
			unixtime: 1656780759,
			balance: 99672.08
		},
		{
			unixtime: 1656780819,
			balance: 99672.08
		},
		{
			unixtime: 1656780879,
			balance: 99672.08
		},
		{
			unixtime: 1656780939,
			balance: 99672.08
		},
		{
			unixtime: 1656780999,
			balance: 99672.08
		},
		{
			unixtime: 1656781059,
			balance: 99672.08
		},
		{
			unixtime: 1656781119,
			balance: 99672.08
		},
		{
			unixtime: 1656781179,
			balance: 99672.08
		},
		{
			unixtime: 1656781239,
			balance: 99672.08
		},
		{
			unixtime: 1656781299,
			balance: 99672.08
		},
		{
			unixtime: 1656781359,
			balance: 99672.08
		},
		{
			unixtime: 1656781419,
			balance: 99672.08
		},
		{
			unixtime: 1656781479,
			balance: 99672.08
		},
		{
			unixtime: 1656781539,
			balance: 99672.08
		},
		{
			unixtime: 1656781599,
			balance: 99672.08
		},
		{
			unixtime: 1656781659,
			balance: 99672.08
		},
		{
			unixtime: 1656781719,
			balance: 99672.08
		},
		{
			unixtime: 1656781779,
			balance: 99672.08
		},
		{
			unixtime: 1656781839,
			balance: 99672.08
		},
		{
			unixtime: 1656781899,
			balance: 99672.08
		},
		{
			unixtime: 1656781959,
			balance: 99672.08
		},
		{
			unixtime: 1656782019,
			balance: 99672.08
		},
		{
			unixtime: 1656782079,
			balance: 99672.08
		},
		{
			unixtime: 1656782139,
			balance: 99672.08
		},
		{
			unixtime: 1656782199,
			balance: 99672.08
		},
		{
			unixtime: 1656782259,
			balance: 99672.08
		},
		{
			unixtime: 1656782319,
			balance: 99672.08
		},
		{
			unixtime: 1656782379,
			balance: 99672.08
		},
		{
			unixtime: 1656782439,
			balance: 99672.08
		},
		{
			unixtime: 1656782499,
			balance: 99672.08
		},
		{
			unixtime: 1656782559,
			balance: 99672.08
		},
		{
			unixtime: 1656782619,
			balance: 99672.08
		},
		{
			unixtime: 1656782679,
			balance: 99672.08
		},
		{
			unixtime: 1656782739,
			balance: 99672.08
		},
		{
			unixtime: 1656782799,
			balance: 99672.08
		},
		{
			unixtime: 1656782859,
			balance: 99672.08
		},
		{
			unixtime: 1656782919,
			balance: 99672.08
		},
		{
			unixtime: 1656782979,
			balance: 99672.08
		},
		{
			unixtime: 1656783039,
			balance: 99672.08
		},
		{
			unixtime: 1656783099,
			balance: 99672.08
		},
		{
			unixtime: 1656783159,
			balance: 99672.08
		},
		{
			unixtime: 1656783219,
			balance: 99672.08
		},
		{
			unixtime: 1656783279,
			balance: 99672.08
		},
		{
			unixtime: 1656783339,
			balance: 99672.08
		},
		{
			unixtime: 1656783399,
			balance: 99672.08
		},
		{
			unixtime: 1656783459,
			balance: 99672.08
		},
		{
			unixtime: 1656783519,
			balance: 99672.08
		},
		{
			unixtime: 1656783579,
			balance: 99672.08
		},
		{
			unixtime: 1656783639,
			balance: 99672.08
		},
		{
			unixtime: 1656783699,
			balance: 99672.08
		},
		{
			unixtime: 1656783759,
			balance: 99672.08
		},
		{
			unixtime: 1656783819,
			balance: 99672.08
		},
		{
			unixtime: 1656783879,
			balance: 99672.08
		},
		{
			unixtime: 1656783939,
			balance: 99672.08
		},
		{
			unixtime: 1656783999,
			balance: 99672.08
		},
		{
			unixtime: 1656784059,
			balance: 99672.08
		},
		{
			unixtime: 1656784119,
			balance: 99672.08
		},
		{
			unixtime: 1656784179,
			balance: 99672.08
		},
		{
			unixtime: 1656784239,
			balance: 99672.08
		},
		{
			unixtime: 1656784299,
			balance: 99672.08
		},
		{
			unixtime: 1656784359,
			balance: 99672.08
		},
		{
			unixtime: 1656784419,
			balance: 99672.08
		},
		{
			unixtime: 1656784479,
			balance: 99672.08
		},
		{
			unixtime: 1656784539,
			balance: 99672.08
		},
		{
			unixtime: 1656784599,
			balance: 99672.08
		},
		{
			unixtime: 1656784659,
			balance: 99672.08
		},
		{
			unixtime: 1656784719,
			balance: 99672.08
		},
		{
			unixtime: 1656784779,
			balance: 99672.08
		},
		{
			unixtime: 1656784839,
			balance: 99672.08
		},
		{
			unixtime: 1656784899,
			balance: 99672.08
		},
		{
			unixtime: 1656784959,
			balance: 99672.08
		},
		{
			unixtime: 1656785019,
			balance: 99672.08
		},
		{
			unixtime: 1656785079,
			balance: 99672.08
		},
		{
			unixtime: 1656785139,
			balance: 99672.08
		},
		{
			unixtime: 1656785199,
			balance: 99672.08
		},
		{
			unixtime: 1656785259,
			balance: 99672.08
		},
		{
			unixtime: 1656785319,
			balance: 99672.08
		},
		{
			unixtime: 1656785379,
			balance: 99672.08
		},
		{
			unixtime: 1656785439,
			balance: 99672.08
		},
		{
			unixtime: 1656785499,
			balance: 99672.08
		},
		{
			unixtime: 1656785559,
			balance: 99672.08
		},
		{
			unixtime: 1656785619,
			balance: 99672.08
		},
		{
			unixtime: 1656785679,
			balance: 99672.08
		},
		{
			unixtime: 1656785739,
			balance: 99672.08
		},
		{
			unixtime: 1656785799,
			balance: 99672.08
		},
		{
			unixtime: 1656785859,
			balance: 99672.08
		},
		{
			unixtime: 1656785919,
			balance: 99672.08
		},
		{
			unixtime: 1656785979,
			balance: 99672.08
		},
		{
			unixtime: 1656786039,
			balance: 99672.08
		},
		{
			unixtime: 1656786099,
			balance: 99672.08
		},
		{
			unixtime: 1656786159,
			balance: 99672.08
		},
		{
			unixtime: 1656786219,
			balance: 99672.08
		},
		{
			unixtime: 1656786279,
			balance: 99672.08
		},
		{
			unixtime: 1656786339,
			balance: 99672.08
		},
		{
			unixtime: 1656786399,
			balance: 99672.08
		},
		{
			unixtime: 1656786459,
			balance: 99672.08
		},
		{
			unixtime: 1656786519,
			balance: 99672.08
		},
		{
			unixtime: 1656786579,
			balance: 99672.08
		},
		{
			unixtime: 1656786639,
			balance: 99672.08
		},
		{
			unixtime: 1656786699,
			balance: 99672.08
		},
		{
			unixtime: 1656786927,
			balance: 99672.08
		},
		{
			unixtime: 1656786987,
			balance: 99672.08
		},
		{
			unixtime: 1656787078,
			balance: 99672.08
		},
		{
			unixtime: 1656787138,
			balance: 99672.08
		},
		{
			unixtime: 1656787247,
			balance: 99672.08
		},
		{
			unixtime: 1656787363,
			balance: 99672.08
		},
		{
			unixtime: 1656787423,
			balance: 99672.08
		},
		{
			unixtime: 1656787483,
			balance: 99672.08
		},
		{
			unixtime: 1656787543,
			balance: 99672.08
		},
		{
			unixtime: 1656787603,
			balance: 99672.08
		},
		{
			unixtime: 1656787663,
			balance: 99672.08
		},
		{
			unixtime: 1656787723,
			balance: 99672.08
		},
		{
			unixtime: 1656787783,
			balance: 99672.08
		},
		{
			unixtime: 1656787843,
			balance: 99672.08
		},
		{
			unixtime: 1656787903,
			balance: 99672.08
		},
		{
			unixtime: 1656787963,
			balance: 99672.08
		},
		{
			unixtime: 1656788023,
			balance: 99672.08
		},
		{
			unixtime: 1656788083,
			balance: 99672.08
		},
		{
			unixtime: 1656788143,
			balance: 99672.08
		},
		{
			unixtime: 1656788203,
			balance: 99672.08
		},
		{
			unixtime: 1656788263,
			balance: 99672.08
		},
		{
			unixtime: 1656788323,
			balance: 99672.08
		},
		{
			unixtime: 1656788383,
			balance: 99672.08
		},
		{
			unixtime: 1656788443,
			balance: 99672.08
		},
		{
			unixtime: 1656788503,
			balance: 99672.08
		},
		{
			unixtime: 1656788563,
			balance: 99672.08
		},
		{
			unixtime: 1656788623,
			balance: 99672.08
		},
		{
			unixtime: 1656788683,
			balance: 99672.08
		},
		{
			unixtime: 1656788743,
			balance: 99672.08
		},
		{
			unixtime: 1656788803,
			balance: 99672.08
		},
		{
			unixtime: 1656788863,
			balance: 99672.08
		},
		{
			unixtime: 1656788923,
			balance: 99672.08
		},
		{
			unixtime: 1656788983,
			balance: 99672.08
		},
		{
			unixtime: 1656789043,
			balance: 99672.08
		},
		{
			unixtime: 1656789103,
			balance: 99672.08
		},
		{
			unixtime: 1656789163,
			balance: 99672.08
		},
		{
			unixtime: 1656789223,
			balance: 99672.08
		},
		{
			unixtime: 1656789283,
			balance: 99672.08
		},
		{
			unixtime: 1656789343,
			balance: 99672.08
		},
		{
			unixtime: 1656789403,
			balance: 99672.08
		},
		{
			unixtime: 1656789463,
			balance: 99672.08
		},
		{
			unixtime: 1656789570,
			balance: 99672.08
		},
		{
			unixtime: 1656789630,
			balance: 99672.08
		},
		{
			unixtime: 1656789690,
			balance: 99672.08
		},
		{
			unixtime: 1656789773,
			balance: 99672.08
		},
		{
			unixtime: 1656789833,
			balance: 99672.08
		},
		{
			unixtime: 1656789893,
			balance: 99672.08
		},
		{
			unixtime: 1656789953,
			balance: 99672.08
		},
		{
			unixtime: 1656790013,
			balance: 99672.08
		},
		{
			unixtime: 1656790073,
			balance: 99672.08
		},
		{
			unixtime: 1656790133,
			balance: 99672.08
		},
		{
			unixtime: 1656790193,
			balance: 99672.08
		},
		{
			unixtime: 1656790253,
			balance: 99672.08
		},
		{
			unixtime: 1656790313,
			balance: 99672.08
		},
		{
			unixtime: 1656790373,
			balance: 99672.08
		},
		{
			unixtime: 1656790433,
			balance: 99672.08
		},
		{
			unixtime: 1656790493,
			balance: 99672.08
		},
		{
			unixtime: 1656790553,
			balance: 99672.08
		},
		{
			unixtime: 1656790613,
			balance: 99672.08
		},
		{
			unixtime: 1656790673,
			balance: 99672.08
		},
		{
			unixtime: 1656790733,
			balance: 99672.08
		},
		{
			unixtime: 1656790793,
			balance: 99672.08
		},
		{
			unixtime: 1656790853,
			balance: 99672.08
		},
		{
			unixtime: 1656790913,
			balance: 99672.08
		},
		{
			unixtime: 1656790973,
			balance: 99672.08
		},
		{
			unixtime: 1656791033,
			balance: 99672.08
		},
		{
			unixtime: 1656791093,
			balance: 99672.08
		},
		{
			unixtime: 1656791153,
			balance: 99672.08
		},
		{
			unixtime: 1656791213,
			balance: 99672.08
		},
		{
			unixtime: 1656791273,
			balance: 99672.08
		},
		{
			unixtime: 1656791333,
			balance: 99672.08
		},
		{
			unixtime: 1656791393,
			balance: 99672.08
		},
		{
			unixtime: 1656791453,
			balance: 99672.08
		},
		{
			unixtime: 1656791513,
			balance: 99672.08
		},
		{
			unixtime: 1656791573,
			balance: 99672.08
		},
		{
			unixtime: 1656791633,
			balance: 99672.08
		},
		{
			unixtime: 1656791693,
			balance: 99672.08
		},
		{
			unixtime: 1656791753,
			balance: 99672.08
		},
		{
			unixtime: 1656791813,
			balance: 99672.08
		},
		{
			unixtime: 1656791873,
			balance: 99672.08
		},
		{
			unixtime: 1656791933,
			balance: 99672.08
		},
		{
			unixtime: 1656791993,
			balance: 99672.08
		},
		{
			unixtime: 1656792053,
			balance: 99672.08
		},
		{
			unixtime: 1656792113,
			balance: 99672.08
		},
		{
			unixtime: 1656792173,
			balance: 99672.08
		},
		{
			unixtime: 1656792233,
			balance: 99672.08
		},
		{
			unixtime: 1656792293,
			balance: 99672.08
		},
		{
			unixtime: 1656792353,
			balance: 99672.08
		},
		{
			unixtime: 1656792413,
			balance: 99672.08
		},
		{
			unixtime: 1656792473,
			balance: 99672.08
		},
		{
			unixtime: 1656792533,
			balance: 99672.08
		},
		{
			unixtime: 1656792593,
			balance: 99672.08
		},
		{
			unixtime: 1656792653,
			balance: 99672.08
		},
		{
			unixtime: 1656792713,
			balance: 99672.08
		},
		{
			unixtime: 1656792773,
			balance: 99672.08
		},
		{
			unixtime: 1656792833,
			balance: 99672.08
		},
		{
			unixtime: 1656792893,
			balance: 99672.08
		},
		{
			unixtime: 1656792953,
			balance: 99672.08
		},
		{
			unixtime: 1656793013,
			balance: 99672.08
		},
		{
			unixtime: 1656793073,
			balance: 99672.08
		},
		{
			unixtime: 1656793133,
			balance: 99672.08
		},
		{
			unixtime: 1656793193,
			balance: 99672.08
		},
		{
			unixtime: 1656793253,
			balance: 99672.08
		},
		{
			unixtime: 1656793313,
			balance: 99672.08
		},
		{
			unixtime: 1656793373,
			balance: 99672.08
		},
		{
			unixtime: 1656793433,
			balance: 99672.08
		},
		{
			unixtime: 1656793493,
			balance: 99672.08
		},
		{
			unixtime: 1656793553,
			balance: 99672.08
		},
		{
			unixtime: 1656793613,
			balance: 99672.08
		},
		{
			unixtime: 1656793673,
			balance: 99672.08
		},
		{
			unixtime: 1656793733,
			balance: 99672.08
		},
		{
			unixtime: 1656793793,
			balance: 99672.08
		},
		{
			unixtime: 1656793853,
			balance: 99672.08
		},
		{
			unixtime: 1656793913,
			balance: 99672.08
		},
		{
			unixtime: 1656793973,
			balance: 99672.08
		},
		{
			unixtime: 1656794033,
			balance: 99672.08
		},
		{
			unixtime: 1656794093,
			balance: 99672.08
		},
		{
			unixtime: 1656794153,
			balance: 99672.08
		},
		{
			unixtime: 1656794213,
			balance: 99672.08
		},
		{
			unixtime: 1656794273,
			balance: 99672.08
		},
		{
			unixtime: 1656794333,
			balance: 99672.08
		},
		{
			unixtime: 1656794393,
			balance: 99672.08
		},
		{
			unixtime: 1656794453,
			balance: 99672.08
		},
		{
			unixtime: 1656794513,
			balance: 99672.08
		},
		{
			unixtime: 1656794573,
			balance: 99672.08
		},
		{
			unixtime: 1656794633,
			balance: 99672.08
		},
		{
			unixtime: 1656794693,
			balance: 99672.08
		},
		{
			unixtime: 1656794753,
			balance: 99672.08
		},
		{
			unixtime: 1656794813,
			balance: 99672.08
		},
		{
			unixtime: 1656794873,
			balance: 99672.08
		},
		{
			unixtime: 1656794933,
			balance: 99672.08
		},
		{
			unixtime: 1656794993,
			balance: 99672.08
		},
		{
			unixtime: 1656795053,
			balance: 99672.08
		},
		{
			unixtime: 1656795113,
			balance: 99672.08
		},
		{
			unixtime: 1656795173,
			balance: 99672.08
		},
		{
			unixtime: 1656795233,
			balance: 99672.08
		},
		{
			unixtime: 1656795293,
			balance: 99672.08
		},
		{
			unixtime: 1656795353,
			balance: 99672.08
		},
		{
			unixtime: 1656795413,
			balance: 99672.08
		},
		{
			unixtime: 1656795473,
			balance: 99672.08
		},
		{
			unixtime: 1656795533,
			balance: 99672.08
		},
		{
			unixtime: 1656795593,
			balance: 99672.08
		},
		{
			unixtime: 1656795653,
			balance: 99672.08
		},
		{
			unixtime: 1656795713,
			balance: 99672.08
		},
		{
			unixtime: 1656795773,
			balance: 99672.08
		},
		{
			unixtime: 1656795833,
			balance: 99672.08
		},
		{
			unixtime: 1656795893,
			balance: 99672.08
		},
		{
			unixtime: 1656795953,
			balance: 99672.08
		},
		{
			unixtime: 1656796013,
			balance: 99672.08
		},
		{
			unixtime: 1656796073,
			balance: 99672.08
		},
		{
			unixtime: 1656796133,
			balance: 99672.08
		},
		{
			unixtime: 1656796193,
			balance: 99672.08
		},
		{
			unixtime: 1656796253,
			balance: 99672.08
		},
		{
			unixtime: 1656796313,
			balance: 99672.08
		},
		{
			unixtime: 1656796373,
			balance: 99672.08
		},
		{
			unixtime: 1656796433,
			balance: 99672.08
		},
		{
			unixtime: 1656796493,
			balance: 99672.08
		},
		{
			unixtime: 1656796553,
			balance: 99672.08
		},
		{
			unixtime: 1656796613,
			balance: 99672.08
		},
		{
			unixtime: 1656796673,
			balance: 99672.08
		},
		{
			unixtime: 1656796733,
			balance: 99672.08
		},
		{
			unixtime: 1656796793,
			balance: 99672.08
		},
		{
			unixtime: 1656796853,
			balance: 99672.08
		},
		{
			unixtime: 1656796913,
			balance: 99672.08
		},
		{
			unixtime: 1656796973,
			balance: 99672.08
		},
		{
			unixtime: 1656797033,
			balance: 99672.08
		},
		{
			unixtime: 1656797093,
			balance: 99672.08
		},
		{
			unixtime: 1656797153,
			balance: 99672.08
		},
		{
			unixtime: 1656797213,
			balance: 99672.08
		},
		{
			unixtime: 1656797273,
			balance: 99672.08
		},
		{
			unixtime: 1656797333,
			balance: 99672.08
		},
		{
			unixtime: 1656797393,
			balance: 99672.08
		},
		{
			unixtime: 1656797453,
			balance: 99672.08
		},
		{
			unixtime: 1656797513,
			balance: 99672.08
		},
		{
			unixtime: 1656797573,
			balance: 99672.08
		},
		{
			unixtime: 1656797633,
			balance: 99672.08
		},
		{
			unixtime: 1656797693,
			balance: 99672.08
		},
		{
			unixtime: 1656797753,
			balance: 99672.08
		},
		{
			unixtime: 1656797813,
			balance: 99672.08
		},
		{
			unixtime: 1656797873,
			balance: 99672.08
		},
		{
			unixtime: 1656797933,
			balance: 99672.08
		},
		{
			unixtime: 1656797993,
			balance: 99672.08
		},
		{
			unixtime: 1656798053,
			balance: 99672.08
		},
		{
			unixtime: 1656798113,
			balance: 99672.08
		},
		{
			unixtime: 1656798173,
			balance: 99672.08
		},
		{
			unixtime: 1656798233,
			balance: 99672.08
		},
		{
			unixtime: 1656798293,
			balance: 99672.08
		},
		{
			unixtime: 1656798353,
			balance: 99672.08
		},
		{
			unixtime: 1656798413,
			balance: 99672.08
		},
		{
			unixtime: 1656798473,
			balance: 99672.08
		},
		{
			unixtime: 1656798533,
			balance: 99672.08
		},
		{
			unixtime: 1656798593,
			balance: 99672.08
		},
		{
			unixtime: 1656798653,
			balance: 99672.08
		},
		{
			unixtime: 1656798713,
			balance: 99672.08
		},
		{
			unixtime: 1656798773,
			balance: 99672.08
		},
		{
			unixtime: 1656798833,
			balance: 99672.08
		},
		{
			unixtime: 1656798893,
			balance: 99672.08
		},
		{
			unixtime: 1656798953,
			balance: 99672.08
		},
		{
			unixtime: 1656799013,
			balance: 99672.08
		},
		{
			unixtime: 1656799073,
			balance: 99672.08
		},
		{
			unixtime: 1656799133,
			balance: 99672.08
		},
		{
			unixtime: 1656799193,
			balance: 99672.08
		},
		{
			unixtime: 1656799253,
			balance: 99672.08
		},
		{
			unixtime: 1656799313,
			balance: 99672.08
		},
		{
			unixtime: 1656799373,
			balance: 99672.08
		},
		{
			unixtime: 1656799433,
			balance: 99672.08
		},
		{
			unixtime: 1656799493,
			balance: 99672.08
		},
		{
			unixtime: 1656799553,
			balance: 99672.08
		},
		{
			unixtime: 1656799613,
			balance: 99672.08
		},
		{
			unixtime: 1656799673,
			balance: 99672.08
		},
		{
			unixtime: 1656799733,
			balance: 99672.08
		},
		{
			unixtime: 1656799793,
			balance: 99672.08
		},
		{
			unixtime: 1656799853,
			balance: 99672.08
		},
		{
			unixtime: 1656799913,
			balance: 99672.08
		},
		{
			unixtime: 1656799973,
			balance: 99672.08
		},
		{
			unixtime: 1656800033,
			balance: 99672.08
		},
		{
			unixtime: 1656800093,
			balance: 99672.08
		},
		{
			unixtime: 1656800153,
			balance: 99672.08
		},
		{
			unixtime: 1656800213,
			balance: 99672.08
		},
		{
			unixtime: 1656800273,
			balance: 99672.08
		},
		{
			unixtime: 1656800333,
			balance: 99672.08
		},
		{
			unixtime: 1656800393,
			balance: 99672.08
		},
		{
			unixtime: 1656800453,
			balance: 99672.08
		},
		{
			unixtime: 1656800513,
			balance: 99672.08
		},
		{
			unixtime: 1656800573,
			balance: 99672.08
		},
		{
			unixtime: 1656800633,
			balance: 99672.08
		},
		{
			unixtime: 1656800693,
			balance: 99672.08
		},
		{
			unixtime: 1656800753,
			balance: 99672.08
		},
		{
			unixtime: 1656800813,
			balance: 99672.08
		},
		{
			unixtime: 1656800873,
			balance: 99672.08
		},
		{
			unixtime: 1656800933,
			balance: 99672.08
		},
		{
			unixtime: 1656800993,
			balance: 99672.08
		},
		{
			unixtime: 1656801053,
			balance: 99672.08
		},
		{
			unixtime: 1656801113,
			balance: 99672.08
		},
		{
			unixtime: 1656801173,
			balance: 99672.08
		},
		{
			unixtime: 1656801233,
			balance: 99672.08
		},
		{
			unixtime: 1656801293,
			balance: 99672.08
		},
		{
			unixtime: 1656801353,
			balance: 99672.08
		},
		{
			unixtime: 1656801413,
			balance: 99672.08
		},
		{
			unixtime: 1656801473,
			balance: 99672.08
		},
		{
			unixtime: 1656801533,
			balance: 99672.08
		},
		{
			unixtime: 1656801593,
			balance: 99672.08
		},
		{
			unixtime: 1656801653,
			balance: 99672.08
		},
		{
			unixtime: 1656801713,
			balance: 99672.08
		},
		{
			unixtime: 1656801773,
			balance: 99672.08
		},
		{
			unixtime: 1656801833,
			balance: 99672.08
		},
		{
			unixtime: 1656801893,
			balance: 99672.08
		},
		{
			unixtime: 1656801953,
			balance: 99672.08
		},
		{
			unixtime: 1656802013,
			balance: 99672.08
		},
		{
			unixtime: 1656802073,
			balance: 99672.08
		},
		{
			unixtime: 1656802133,
			balance: 99672.08
		},
		{
			unixtime: 1656802193,
			balance: 99672.08
		},
		{
			unixtime: 1656802253,
			balance: 99672.08
		},
		{
			unixtime: 1656802313,
			balance: 99672.08
		},
		{
			unixtime: 1656802373,
			balance: 99672.08
		},
		{
			unixtime: 1656802433,
			balance: 99672.08
		},
		{
			unixtime: 1656802493,
			balance: 99672.08
		},
		{
			unixtime: 1656802553,
			balance: 99672.08
		},
		{
			unixtime: 1656802613,
			balance: 99672.08
		},
		{
			unixtime: 1656802673,
			balance: 99672.08
		},
		{
			unixtime: 1656802733,
			balance: 99672.08
		},
		{
			unixtime: 1656802793,
			balance: 99672.08
		},
		{
			unixtime: 1656802853,
			balance: 99672.08
		},
		{
			unixtime: 1656802913,
			balance: 99672.08
		},
		{
			unixtime: 1656802973,
			balance: 99672.08
		},
		{
			unixtime: 1656803033,
			balance: 99672.08
		},
		{
			unixtime: 1656803093,
			balance: 99672.08
		},
		{
			unixtime: 1656803153,
			balance: 99672.08
		},
		{
			unixtime: 1656803213,
			balance: 99672.08
		},
		{
			unixtime: 1656803273,
			balance: 99672.08
		},
		{
			unixtime: 1656803333,
			balance: 99672.08
		},
		{
			unixtime: 1656803393,
			balance: 99672.08
		},
		{
			unixtime: 1656803453,
			balance: 99672.08
		},
		{
			unixtime: 1656803513,
			balance: 99672.08
		},
		{
			unixtime: 1656803573,
			balance: 99672.08
		},
		{
			unixtime: 1656803633,
			balance: 99672.08
		},
		{
			unixtime: 1656803693,
			balance: 99672.08
		},
		{
			unixtime: 1656803753,
			balance: 99672.08
		},
		{
			unixtime: 1656803813,
			balance: 99672.08
		},
		{
			unixtime: 1656803873,
			balance: 99672.08
		},
		{
			unixtime: 1656803933,
			balance: 99672.08
		},
		{
			unixtime: 1656803993,
			balance: 99672.08
		},
		{
			unixtime: 1656804053,
			balance: 99672.08
		},
		{
			unixtime: 1656804113,
			balance: 99672.08
		},
		{
			unixtime: 1656804173,
			balance: 99672.08
		},
		{
			unixtime: 1656804233,
			balance: 99672.08
		},
		{
			unixtime: 1656804293,
			balance: 99672.08
		},
		{
			unixtime: 1656804353,
			balance: 99672.08
		},
		{
			unixtime: 1656804413,
			balance: 99672.08
		},
		{
			unixtime: 1656804473,
			balance: 99672.08
		},
		{
			unixtime: 1656804533,
			balance: 99672.08
		},
		{
			unixtime: 1656804593,
			balance: 99672.08
		},
		{
			unixtime: 1656804653,
			balance: 99672.08
		},
		{
			unixtime: 1656804713,
			balance: 99672.08
		},
		{
			unixtime: 1656804773,
			balance: 99672.08
		},
		{
			unixtime: 1656804833,
			balance: 99672.08
		},
		{
			unixtime: 1656804893,
			balance: 99672.08
		},
		{
			unixtime: 1656804953,
			balance: 99672.08
		},
		{
			unixtime: 1656805013,
			balance: 99672.08
		},
		{
			unixtime: 1656805073,
			balance: 99672.08
		},
		{
			unixtime: 1656805133,
			balance: 99672.08
		},
		{
			unixtime: 1656805193,
			balance: 99672.08
		},
		{
			unixtime: 1656805253,
			balance: 99672.08
		},
		{
			unixtime: 1656805313,
			balance: 99672.08
		},
		{
			unixtime: 1656805373,
			balance: 99672.08
		},
		{
			unixtime: 1656805433,
			balance: 99672.08
		},
		{
			unixtime: 1656805493,
			balance: 99672.08
		},
		{
			unixtime: 1656805553,
			balance: 99672.08
		},
		{
			unixtime: 1656805613,
			balance: 99672.08
		},
		{
			unixtime: 1656805673,
			balance: 99672.08
		},
		{
			unixtime: 1656805733,
			balance: 99672.08
		},
		{
			unixtime: 1656805793,
			balance: 99672.08
		},
		{
			unixtime: 1656805853,
			balance: 99672.08
		},
		{
			unixtime: 1656805913,
			balance: 99672.08
		},
		{
			unixtime: 1656805973,
			balance: 99672.08
		},
		{
			unixtime: 1656806033,
			balance: 99672.08
		},
		{
			unixtime: 1656806093,
			balance: 99672.08
		},
		{
			unixtime: 1656806153,
			balance: 99672.08
		},
		{
			unixtime: 1656806213,
			balance: 99672.08
		},
		{
			unixtime: 1656806273,
			balance: 99672.08
		},
		{
			unixtime: 1656806333,
			balance: 99672.08
		},
		{
			unixtime: 1656806393,
			balance: 99672.08
		},
		{
			unixtime: 1656806453,
			balance: 99672.08
		},
		{
			unixtime: 1656806513,
			balance: 99672.08
		},
		{
			unixtime: 1656806573,
			balance: 99672.08
		},
		{
			unixtime: 1656806633,
			balance: 99672.08
		},
		{
			unixtime: 1656806693,
			balance: 99672.08
		},
		{
			unixtime: 1656806753,
			balance: 99672.08
		},
		{
			unixtime: 1656806813,
			balance: 99672.08
		},
		{
			unixtime: 1656806873,
			balance: 99672.08
		},
		{
			unixtime: 1656806933,
			balance: 99672.08
		},
		{
			unixtime: 1656806993,
			balance: 99672.08
		},
		{
			unixtime: 1656807053,
			balance: 99672.08
		},
		{
			unixtime: 1656807113,
			balance: 99672.08
		},
		{
			unixtime: 1656807173,
			balance: 99672.08
		},
		{
			unixtime: 1656807233,
			balance: 99672.08
		},
		{
			unixtime: 1656807293,
			balance: 99672.08
		},
		{
			unixtime: 1656807353,
			balance: 99672.08
		},
		{
			unixtime: 1656807413,
			balance: 99672.08
		},
		{
			unixtime: 1656807473,
			balance: 99672.08
		},
		{
			unixtime: 1656807533,
			balance: 99672.08
		},
		{
			unixtime: 1656807593,
			balance: 99672.08
		},
		{
			unixtime: 1656807653,
			balance: 99672.08
		},
		{
			unixtime: 1656807713,
			balance: 99672.08
		},
		{
			unixtime: 1656807773,
			balance: 99672.08
		},
		{
			unixtime: 1656807833,
			balance: 99672.08
		},
		{
			unixtime: 1656807893,
			balance: 99672.08
		},
		{
			unixtime: 1656807953,
			balance: 99672.08
		},
		{
			unixtime: 1656808013,
			balance: 99672.08
		},
		{
			unixtime: 1656808073,
			balance: 99672.08
		},
		{
			unixtime: 1656808133,
			balance: 99672.08
		},
		{
			unixtime: 1656808193,
			balance: 99672.08
		},
		{
			unixtime: 1656808253,
			balance: 99672.08
		},
		{
			unixtime: 1656808313,
			balance: 99672.08
		},
		{
			unixtime: 1656808373,
			balance: 99672.08
		},
		{
			unixtime: 1656808433,
			balance: 99672.08
		},
		{
			unixtime: 1656808493,
			balance: 99672.08
		},
		{
			unixtime: 1656808553,
			balance: 99672.08
		},
		{
			unixtime: 1656808613,
			balance: 99672.08
		},
		{
			unixtime: 1656808673,
			balance: 99672.08
		},
		{
			unixtime: 1656808733,
			balance: 99672.08
		},
		{
			unixtime: 1656808793,
			balance: 99672.08
		},
		{
			unixtime: 1656808853,
			balance: 99672.08
		},
		{
			unixtime: 1656808913,
			balance: 99672.08
		},
		{
			unixtime: 1656808973,
			balance: 99672.08
		},
		{
			unixtime: 1656809033,
			balance: 99672.08
		},
		{
			unixtime: 1656809093,
			balance: 99672.08
		},
		{
			unixtime: 1656809153,
			balance: 99672.08
		},
		{
			unixtime: 1656809213,
			balance: 99672.08
		},
		{
			unixtime: 1656809273,
			balance: 99672.08
		},
		{
			unixtime: 1656809333,
			balance: 99672.08
		},
		{
			unixtime: 1656809393,
			balance: 99672.08
		},
		{
			unixtime: 1656809453,
			balance: 99672.08
		},
		{
			unixtime: 1656809513,
			balance: 99672.08
		},
		{
			unixtime: 1656809573,
			balance: 99672.08
		},
		{
			unixtime: 1656809633,
			balance: 99672.08
		},
		{
			unixtime: 1656809693,
			balance: 99672.08
		},
		{
			unixtime: 1656809753,
			balance: 99672.08
		},
		{
			unixtime: 1656809813,
			balance: 99672.08
		},
		{
			unixtime: 1656809873,
			balance: 99672.08
		},
		{
			unixtime: 1656809933,
			balance: 99672.08
		},
		{
			unixtime: 1656809993,
			balance: 99672.08
		},
		{
			unixtime: 1656810053,
			balance: 99672.08
		},
		{
			unixtime: 1656810113,
			balance: 99672.08
		},
		{
			unixtime: 1656810173,
			balance: 99672.08
		},
		{
			unixtime: 1656810233,
			balance: 99672.08
		},
		{
			unixtime: 1656810293,
			balance: 99672.08
		},
		{
			unixtime: 1656810353,
			balance: 99672.08
		},
		{
			unixtime: 1656810413,
			balance: 99672.08
		},
		{
			unixtime: 1656810473,
			balance: 99672.08
		},
		{
			unixtime: 1656810533,
			balance: 99672.08
		},
		{
			unixtime: 1656810593,
			balance: 99672.08
		},
		{
			unixtime: 1656810653,
			balance: 99672.08
		},
		{
			unixtime: 1656810713,
			balance: 99672.08
		},
		{
			unixtime: 1656810773,
			balance: 99672.08
		},
		{
			unixtime: 1656810833,
			balance: 99672.08
		},
		{
			unixtime: 1656810893,
			balance: 99672.08
		},
		{
			unixtime: 1656810953,
			balance: 99672.08
		},
		{
			unixtime: 1656811013,
			balance: 99672.08
		},
		{
			unixtime: 1656811073,
			balance: 99672.08
		},
		{
			unixtime: 1656811133,
			balance: 99672.08
		},
		{
			unixtime: 1656811193,
			balance: 99672.08
		},
		{
			unixtime: 1656811253,
			balance: 99672.08
		},
		{
			unixtime: 1656811313,
			balance: 99672.08
		},
		{
			unixtime: 1656811373,
			balance: 99672.08
		},
		{
			unixtime: 1656811433,
			balance: 99672.08
		},
		{
			unixtime: 1656811493,
			balance: 99672.08
		},
		{
			unixtime: 1656811553,
			balance: 99672.08
		},
		{
			unixtime: 1656811613,
			balance: 99672.08
		},
		{
			unixtime: 1656811673,
			balance: 99672.08
		},
		{
			unixtime: 1656811733,
			balance: 99672.08
		},
		{
			unixtime: 1656811793,
			balance: 99672.08
		},
		{
			unixtime: 1656811853,
			balance: 99672.08
		},
		{
			unixtime: 1656811913,
			balance: 99672.08
		},
		{
			unixtime: 1656811973,
			balance: 99672.08
		},
		{
			unixtime: 1656812033,
			balance: 99672.08
		},
		{
			unixtime: 1656812093,
			balance: 99672.08
		},
		{
			unixtime: 1656812153,
			balance: 99672.08
		},
		{
			unixtime: 1656812213,
			balance: 99672.08
		},
		{
			unixtime: 1656812273,
			balance: 99672.08
		},
		{
			unixtime: 1656812333,
			balance: 99672.08
		},
		{
			unixtime: 1656812393,
			balance: 99672.08
		},
		{
			unixtime: 1656812453,
			balance: 99672.08
		},
		{
			unixtime: 1656812513,
			balance: 99672.08
		},
		{
			unixtime: 1656812573,
			balance: 99672.08
		},
		{
			unixtime: 1656812633,
			balance: 99672.08
		},
		{
			unixtime: 1656812693,
			balance: 99672.08
		},
		{
			unixtime: 1656812753,
			balance: 99672.08
		},
		{
			unixtime: 1656812813,
			balance: 99672.08
		},
		{
			unixtime: 1656812873,
			balance: 99672.08
		},
		{
			unixtime: 1656812933,
			balance: 99672.08
		},
		{
			unixtime: 1656812993,
			balance: 99672.08
		},
		{
			unixtime: 1656813053,
			balance: 99672.08
		},
		{
			unixtime: 1656813113,
			balance: 99672.08
		},
		{
			unixtime: 1656813173,
			balance: 99672.08
		},
		{
			unixtime: 1656813233,
			balance: 99672.08
		},
		{
			unixtime: 1656813293,
			balance: 99672.08
		},
		{
			unixtime: 1656813353,
			balance: 99672.08
		},
		{
			unixtime: 1656813413,
			balance: 99672.08
		},
		{
			unixtime: 1656813473,
			balance: 99672.08
		},
		{
			unixtime: 1656813533,
			balance: 99672.08
		},
		{
			unixtime: 1656813593,
			balance: 99672.08
		},
		{
			unixtime: 1656813653,
			balance: 99672.08
		},
		{
			unixtime: 1656813713,
			balance: 99672.08
		},
		{
			unixtime: 1656813773,
			balance: 99672.08
		},
		{
			unixtime: 1656813833,
			balance: 99672.08
		},
		{
			unixtime: 1656813893,
			balance: 99672.08
		},
		{
			unixtime: 1656813953,
			balance: 99672.08
		},
		{
			unixtime: 1656814013,
			balance: 99672.08
		},
		{
			unixtime: 1656814073,
			balance: 99672.08
		},
		{
			unixtime: 1656814133,
			balance: 99672.08
		},
		{
			unixtime: 1656814193,
			balance: 99672.08
		},
		{
			unixtime: 1656814253,
			balance: 99672.08
		},
		{
			unixtime: 1656814313,
			balance: 99672.08
		},
		{
			unixtime: 1656814373,
			balance: 99672.08
		},
		{
			unixtime: 1656814433,
			balance: 99672.08
		},
		{
			unixtime: 1656814493,
			balance: 99672.08
		},
		{
			unixtime: 1656814553,
			balance: 99672.08
		},
		{
			unixtime: 1656814613,
			balance: 99672.08
		},
		{
			unixtime: 1656814673,
			balance: 99672.08
		},
		{
			unixtime: 1656814733,
			balance: 99672.08
		},
		{
			unixtime: 1656814793,
			balance: 99672.08
		},
		{
			unixtime: 1656814853,
			balance: 99672.08
		},
		{
			unixtime: 1656814913,
			balance: 99672.08
		},
		{
			unixtime: 1656814973,
			balance: 99672.08
		},
		{
			unixtime: 1656815033,
			balance: 99672.08
		},
		{
			unixtime: 1656815093,
			balance: 99672.08
		},
		{
			unixtime: 1656815153,
			balance: 99672.08
		},
		{
			unixtime: 1656815213,
			balance: 99672.08
		},
		{
			unixtime: 1656815273,
			balance: 99672.08
		},
		{
			unixtime: 1656815333,
			balance: 99672.08
		},
		{
			unixtime: 1656815393,
			balance: 99672.08
		},
		{
			unixtime: 1656815453,
			balance: 99672.08
		},
		{
			unixtime: 1656815513,
			balance: 99672.08
		},
		{
			unixtime: 1656815573,
			balance: 99672.08
		},
		{
			unixtime: 1656815633,
			balance: 99672.08
		},
		{
			unixtime: 1656815693,
			balance: 99672.08
		},
		{
			unixtime: 1656815753,
			balance: 99672.08
		},
		{
			unixtime: 1656815813,
			balance: 99672.08
		},
		{
			unixtime: 1656815873,
			balance: 99672.08
		},
		{
			unixtime: 1656815933,
			balance: 99672.08
		},
		{
			unixtime: 1656815993,
			balance: 99672.08
		},
		{
			unixtime: 1656816053,
			balance: 99672.08
		},
		{
			unixtime: 1656816113,
			balance: 99672.08
		},
		{
			unixtime: 1656816173,
			balance: 99672.08
		},
		{
			unixtime: 1656816233,
			balance: 99672.08
		},
		{
			unixtime: 1656816293,
			balance: 99672.08
		},
		{
			unixtime: 1656816353,
			balance: 99672.08
		},
		{
			unixtime: 1656816413,
			balance: 99672.08
		},
		{
			unixtime: 1656816473,
			balance: 99672.08
		},
		{
			unixtime: 1656816534,
			balance: 99672.08
		},
		{
			unixtime: 1656816593,
			balance: 99672.08
		},
		{
			unixtime: 1656816653,
			balance: 99672.08
		},
		{
			unixtime: 1656816713,
			balance: 99672.08
		},
		{
			unixtime: 1656816773,
			balance: 99672.08
		},
		{
			unixtime: 1656816833,
			balance: 99672.08
		},
		{
			unixtime: 1656816893,
			balance: 99672.08
		},
		{
			unixtime: 1656816953,
			balance: 99672.08
		},
		{
			unixtime: 1656817013,
			balance: 99672.08
		},
		{
			unixtime: 1656817073,
			balance: 99672.08
		},
		{
			unixtime: 1656817133,
			balance: 99672.08
		},
		{
			unixtime: 1656817193,
			balance: 99672.08
		},
		{
			unixtime: 1656817253,
			balance: 99672.08
		},
		{
			unixtime: 1656817313,
			balance: 99672.08
		},
		{
			unixtime: 1656817373,
			balance: 99672.08
		},
		{
			unixtime: 1656817433,
			balance: 99672.08
		},
		{
			unixtime: 1656817493,
			balance: 99672.08
		},
		{
			unixtime: 1656817553,
			balance: 99672.08
		},
		{
			unixtime: 1656817613,
			balance: 99672.08
		},
		{
			unixtime: 1656817673,
			balance: 99672.08
		},
		{
			unixtime: 1656817733,
			balance: 99672.08
		},
		{
			unixtime: 1656817793,
			balance: 99672.08
		},
		{
			unixtime: 1656817853,
			balance: 99672.08
		},
		{
			unixtime: 1656817913,
			balance: 99672.08
		},
		{
			unixtime: 1656817973,
			balance: 99672.08
		},
		{
			unixtime: 1656818033,
			balance: 99672.08
		},
		{
			unixtime: 1656818093,
			balance: 99672.08
		},
		{
			unixtime: 1656818153,
			balance: 99672.08
		},
		{
			unixtime: 1656818213,
			balance: 99672.08
		},
		{
			unixtime: 1656818273,
			balance: 99672.08
		},
		{
			unixtime: 1656818333,
			balance: 99672.08
		},
		{
			unixtime: 1656818393,
			balance: 99672.08
		},
		{
			unixtime: 1656818453,
			balance: 99672.08
		},
		{
			unixtime: 1656818513,
			balance: 99672.08
		},
		{
			unixtime: 1656818573,
			balance: 99672.08
		},
		{
			unixtime: 1656818633,
			balance: 99672.08
		},
		{
			unixtime: 1656818693,
			balance: 99672.08
		},
		{
			unixtime: 1656818753,
			balance: 99672.08
		},
		{
			unixtime: 1656818813,
			balance: 99672.08
		},
		{
			unixtime: 1656818873,
			balance: 99672.08
		},
		{
			unixtime: 1656818933,
			balance: 99672.08
		},
		{
			unixtime: 1656818993,
			balance: 99672.08
		},
		{
			unixtime: 1656819053,
			balance: 99672.08
		},
		{
			unixtime: 1656819113,
			balance: 99672.08
		},
		{
			unixtime: 1656819173,
			balance: 99672.08
		},
		{
			unixtime: 1656819233,
			balance: 99672.08
		},
		{
			unixtime: 1656819293,
			balance: 99672.08
		},
		{
			unixtime: 1656819353,
			balance: 99672.08
		},
		{
			unixtime: 1656819413,
			balance: 99672.08
		},
		{
			unixtime: 1656819473,
			balance: 99672.08
		},
		{
			unixtime: 1656819533,
			balance: 99672.08
		},
		{
			unixtime: 1656819593,
			balance: 99672.08
		},
		{
			unixtime: 1656819653,
			balance: 99672.08
		},
		{
			unixtime: 1656819713,
			balance: 99672.08
		},
		{
			unixtime: 1656819773,
			balance: 99672.08
		},
		{
			unixtime: 1656819833,
			balance: 99672.08
		},
		{
			unixtime: 1656819893,
			balance: 99672.08
		},
		{
			unixtime: 1656819953,
			balance: 99672.08
		},
		{
			unixtime: 1656820013,
			balance: 99672.08
		},
		{
			unixtime: 1656820073,
			balance: 99672.08
		},
		{
			unixtime: 1656820133,
			balance: 99672.08
		},
		{
			unixtime: 1656820193,
			balance: 99672.08
		},
		{
			unixtime: 1656820253,
			balance: 99672.08
		},
		{
			unixtime: 1656820313,
			balance: 99672.08
		},
		{
			unixtime: 1656820373,
			balance: 99672.08
		},
		{
			unixtime: 1656820433,
			balance: 99672.08
		},
		{
			unixtime: 1656820493,
			balance: 99672.08
		},
		{
			unixtime: 1656820553,
			balance: 99672.08
		},
		{
			unixtime: 1656820613,
			balance: 99672.08
		},
		{
			unixtime: 1656820673,
			balance: 99672.08
		},
		{
			unixtime: 1656820733,
			balance: 99672.08
		},
		{
			unixtime: 1656820793,
			balance: 99672.08
		},
		{
			unixtime: 1656820853,
			balance: 99672.08
		},
		{
			unixtime: 1656820913,
			balance: 99672.08
		},
		{
			unixtime: 1656820973,
			balance: 99672.08
		},
		{
			unixtime: 1656821033,
			balance: 99672.08
		},
		{
			unixtime: 1656821093,
			balance: 99672.08
		},
		{
			unixtime: 1656821153,
			balance: 99672.08
		},
		{
			unixtime: 1656821213,
			balance: 99672.08
		},
		{
			unixtime: 1656821273,
			balance: 99672.08
		},
		{
			unixtime: 1656821333,
			balance: 99672.08
		},
		{
			unixtime: 1656821393,
			balance: 99672.08
		},
		{
			unixtime: 1656821454,
			balance: 99672.08
		},
		{
			unixtime: 1656821514,
			balance: 99672.08
		},
		{
			unixtime: 1656821573,
			balance: 99672.08
		},
		{
			unixtime: 1656821633,
			balance: 99672.08
		},
		{
			unixtime: 1656821694,
			balance: 99672.08
		},
		{
			unixtime: 1656821753,
			balance: 99672.08
		},
		{
			unixtime: 1656821813,
			balance: 99672.08
		},
		{
			unixtime: 1656821873,
			balance: 99672.08
		},
		{
			unixtime: 1656821933,
			balance: 99672.08
		},
		{
			unixtime: 1656821993,
			balance: 99672.08
		},
		{
			unixtime: 1656822053,
			balance: 99672.08
		},
		{
			unixtime: 1656822113,
			balance: 99672.08
		},
		{
			unixtime: 1656822173,
			balance: 99672.08
		},
		{
			unixtime: 1656822233,
			balance: 99672.08
		},
		{
			unixtime: 1656822293,
			balance: 99672.08
		},
		{
			unixtime: 1656822353,
			balance: 99672.08
		},
		{
			unixtime: 1656822413,
			balance: 99672.08
		},
		{
			unixtime: 1656822473,
			balance: 99672.08
		},
		{
			unixtime: 1656822534,
			balance: 99672.08
		},
		{
			unixtime: 1656822593,
			balance: 99672.08
		},
		{
			unixtime: 1656822653,
			balance: 99672.08
		},
		{
			unixtime: 1656822714,
			balance: 99672.08
		},
		{
			unixtime: 1656822773,
			balance: 99672.08
		},
		{
			unixtime: 1656822833,
			balance: 99672.08
		},
		{
			unixtime: 1656822893,
			balance: 99672.08
		},
		{
			unixtime: 1656822953,
			balance: 99672.08
		},
		{
			unixtime: 1656823014,
			balance: 99672.08
		},
		{
			unixtime: 1656823073,
			balance: 99672.08
		},
		{
			unixtime: 1656823133,
			balance: 99672.08
		},
		{
			unixtime: 1656823193,
			balance: 99672.08
		},
		{
			unixtime: 1656823253,
			balance: 99672.08
		},
		{
			unixtime: 1656823313,
			balance: 99672.08
		},
		{
			unixtime: 1656823373,
			balance: 99672.08
		},
		{
			unixtime: 1656823433,
			balance: 99672.08
		},
		{
			unixtime: 1656823493,
			balance: 99672.08
		},
		{
			unixtime: 1656823554,
			balance: 99672.08
		},
		{
			unixtime: 1656823614,
			balance: 99672.08
		},
		{
			unixtime: 1656823673,
			balance: 99672.08
		},
		{
			unixtime: 1656823733,
			balance: 99672.08
		},
		{
			unixtime: 1656823793,
			balance: 99672.08
		},
		{
			unixtime: 1656823854,
			balance: 99672.08
		},
		{
			unixtime: 1656823913,
			balance: 99672.08
		},
		{
			unixtime: 1656823973,
			balance: 99672.08
		},
		{
			unixtime: 1656824033,
			balance: 99672.08
		},
		{
			unixtime: 1656824093,
			balance: 99672.08
		},
		{
			unixtime: 1656824154,
			balance: 99672.08
		},
		{
			unixtime: 1656824213,
			balance: 99672.08
		},
		{
			unixtime: 1656824273,
			balance: 99672.08
		},
		{
			unixtime: 1656824334,
			balance: 99672.08
		},
		{
			unixtime: 1656824393,
			balance: 99672.08
		},
		{
			unixtime: 1656824454,
			balance: 99672.08
		},
		{
			unixtime: 1656824514,
			balance: 99672.08
		},
		{
			unixtime: 1656824573,
			balance: 99672.08
		},
		{
			unixtime: 1656824634,
			balance: 99672.08
		},
		{
			unixtime: 1656824694,
			balance: 99672.08
		},
		{
			unixtime: 1656824754,
			balance: 99672.08
		},
		{
			unixtime: 1656824814,
			balance: 99672.08
		},
		{
			unixtime: 1656824874,
			balance: 99672.08
		},
		{
			unixtime: 1656824934,
			balance: 99672.08
		},
		{
			unixtime: 1656824994,
			balance: 99672.08
		},
		{
			unixtime: 1656825054,
			balance: 99672.08
		},
		{
			unixtime: 1656825114,
			balance: 99672.08
		},
		{
			unixtime: 1656825173,
			balance: 99672.08
		},
		{
			unixtime: 1656825234,
			balance: 99672.08
		},
		{
			unixtime: 1656825294,
			balance: 99672.08
		},
		{
			unixtime: 1656825354,
			balance: 99672.08
		},
		{
			unixtime: 1656825414,
			balance: 99672.08
		},
		{
			unixtime: 1656825474,
			balance: 99672.08
		},
		{
			unixtime: 1656825534,
			balance: 99672.08
		},
		{
			unixtime: 1656825594,
			balance: 99672.08
		},
		{
			unixtime: 1656825654,
			balance: 99672.08
		},
		{
			unixtime: 1656825714,
			balance: 99672.08
		},
		{
			unixtime: 1656825774,
			balance: 99672.08
		},
		{
			unixtime: 1656825834,
			balance: 99672.08
		},
		{
			unixtime: 1656825894,
			balance: 99672.08
		},
		{
			unixtime: 1656825954,
			balance: 99672.08
		},
		{
			unixtime: 1656826014,
			balance: 99672.08
		},
		{
			unixtime: 1656826074,
			balance: 99672.08
		},
		{
			unixtime: 1656826134,
			balance: 99672.08
		},
		{
			unixtime: 1656826194,
			balance: 99672.08
		},
		{
			unixtime: 1656826254,
			balance: 99672.08
		},
		{
			unixtime: 1656826314,
			balance: 99672.08
		},
		{
			unixtime: 1656826374,
			balance: 99672.08
		},
		{
			unixtime: 1656826434,
			balance: 99672.08
		},
		{
			unixtime: 1656826494,
			balance: 99672.08
		},
		{
			unixtime: 1656826554,
			balance: 99672.08
		},
		{
			unixtime: 1656826614,
			balance: 99672.08
		},
		{
			unixtime: 1656826674,
			balance: 99672.08
		},
		{
			unixtime: 1656826734,
			balance: 99672.08
		},
		{
			unixtime: 1656826794,
			balance: 99672.08
		},
		{
			unixtime: 1656826854,
			balance: 99672.08
		},
		{
			unixtime: 1656826914,
			balance: 99672.08
		},
		{
			unixtime: 1656826974,
			balance: 99672.08
		},
		{
			unixtime: 1656827034,
			balance: 99672.08
		},
		{
			unixtime: 1656827094,
			balance: 99672.08
		},
		{
			unixtime: 1656827154,
			balance: 99672.08
		},
		{
			unixtime: 1656827214,
			balance: 99672.08
		},
		{
			unixtime: 1656827274,
			balance: 99672.08
		},
		{
			unixtime: 1656827334,
			balance: 99672.08
		},
		{
			unixtime: 1656827394,
			balance: 99672.08
		},
		{
			unixtime: 1656827454,
			balance: 99672.08
		},
		{
			unixtime: 1656827514,
			balance: 99672.08
		},
		{
			unixtime: 1656827574,
			balance: 99672.08
		},
		{
			unixtime: 1656827634,
			balance: 99672.08
		},
		{
			unixtime: 1656827694,
			balance: 99672.08
		},
		{
			unixtime: 1656827754,
			balance: 99672.08
		},
		{
			unixtime: 1656827814,
			balance: 99672.08
		},
		{
			unixtime: 1656827874,
			balance: 99672.08
		},
		{
			unixtime: 1656827934,
			balance: 99672.08
		},
		{
			unixtime: 1656827994,
			balance: 99672.08
		},
		{
			unixtime: 1656828054,
			balance: 99672.08
		},
		{
			unixtime: 1656828114,
			balance: 99672.08
		},
		{
			unixtime: 1656828174,
			balance: 99672.08
		},
		{
			unixtime: 1656828234,
			balance: 99672.08
		},
		{
			unixtime: 1656828294,
			balance: 99672.08
		},
		{
			unixtime: 1656828354,
			balance: 99672.08
		},
		{
			unixtime: 1656828414,
			balance: 99672.08
		},
		{
			unixtime: 1656828474,
			balance: 99672.08
		},
		{
			unixtime: 1656828534,
			balance: 99672.08
		},
		{
			unixtime: 1656828594,
			balance: 99672.08
		},
		{
			unixtime: 1656828654,
			balance: 99672.08
		},
		{
			unixtime: 1656828714,
			balance: 99672.08
		},
		{
			unixtime: 1656828774,
			balance: 99672.08
		},
		{
			unixtime: 1656828834,
			balance: 99672.08
		},
		{
			unixtime: 1656828894,
			balance: 99672.08
		},
		{
			unixtime: 1656828954,
			balance: 99672.08
		},
		{
			unixtime: 1656829014,
			balance: 99672.08
		},
		{
			unixtime: 1656829074,
			balance: 99672.08
		},
		{
			unixtime: 1656829134,
			balance: 99672.08
		},
		{
			unixtime: 1656829194,
			balance: 99672.08
		},
		{
			unixtime: 1656829254,
			balance: 99672.08
		},
		{
			unixtime: 1656829314,
			balance: 99672.08
		},
		{
			unixtime: 1656829374,
			balance: 99672.08
		},
		{
			unixtime: 1656829434,
			balance: 99672.08
		},
		{
			unixtime: 1656829494,
			balance: 99672.08
		},
		{
			unixtime: 1656829554,
			balance: 99672.08
		},
		{
			unixtime: 1656829614,
			balance: 99672.08
		},
		{
			unixtime: 1656829674,
			balance: 99672.08
		},
		{
			unixtime: 1656829734,
			balance: 99672.08
		},
		{
			unixtime: 1656829794,
			balance: 99672.08
		},
		{
			unixtime: 1656829854,
			balance: 99672.08
		},
		{
			unixtime: 1656829914,
			balance: 99672.08
		},
		{
			unixtime: 1656829974,
			balance: 99672.08
		},
		{
			unixtime: 1656830034,
			balance: 99672.08
		},
		{
			unixtime: 1656830094,
			balance: 99672.08
		},
		{
			unixtime: 1656830154,
			balance: 99672.08
		},
		{
			unixtime: 1656830214,
			balance: 99672.08
		},
		{
			unixtime: 1656830274,
			balance: 99672.08
		},
		{
			unixtime: 1656830334,
			balance: 99672.08
		},
		{
			unixtime: 1656830394,
			balance: 99672.08
		},
		{
			unixtime: 1656830454,
			balance: 99672.08
		},
		{
			unixtime: 1656830514,
			balance: 99672.08
		},
		{
			unixtime: 1656830574,
			balance: 99672.08
		},
		{
			unixtime: 1656830634,
			balance: 99672.08
		},
		{
			unixtime: 1656830694,
			balance: 99672.08
		},
		{
			unixtime: 1656830754,
			balance: 99672.08
		},
		{
			unixtime: 1656830814,
			balance: 99672.08
		},
		{
			unixtime: 1656830874,
			balance: 99672.08
		},
		{
			unixtime: 1656830934,
			balance: 99672.08
		},
		{
			unixtime: 1656830994,
			balance: 99672.08
		},
		{
			unixtime: 1656831054,
			balance: 99672.08
		},
		{
			unixtime: 1656831114,
			balance: 99672.08
		},
		{
			unixtime: 1656831174,
			balance: 99672.08
		},
		{
			unixtime: 1656831234,
			balance: 99672.08
		},
		{
			unixtime: 1656831294,
			balance: 99672.08
		},
		{
			unixtime: 1656831354,
			balance: 99672.08
		},
		{
			unixtime: 1656831414,
			balance: 99672.08
		},
		{
			unixtime: 1656831474,
			balance: 99672.08
		},
		{
			unixtime: 1656831534,
			balance: 99672.08
		},
		{
			unixtime: 1656831594,
			balance: 99672.08
		},
		{
			unixtime: 1656831654,
			balance: 99672.08
		},
		{
			unixtime: 1656831714,
			balance: 99672.08
		},
		{
			unixtime: 1656831774,
			balance: 99672.08
		},
		{
			unixtime: 1656831834,
			balance: 99672.08
		},
		{
			unixtime: 1656831894,
			balance: 99672.08
		},
		{
			unixtime: 1656831954,
			balance: 99672.08
		},
		{
			unixtime: 1656832014,
			balance: 99672.08
		},
		{
			unixtime: 1656832074,
			balance: 99672.08
		},
		{
			unixtime: 1656832134,
			balance: 99672.08
		},
		{
			unixtime: 1656832194,
			balance: 99672.08
		},
		{
			unixtime: 1656832254,
			balance: 99672.08
		},
		{
			unixtime: 1656832314,
			balance: 99672.08
		},
		{
			unixtime: 1656832374,
			balance: 99672.08
		},
		{
			unixtime: 1656832434,
			balance: 99672.08
		},
		{
			unixtime: 1656832494,
			balance: 99672.08
		},
		{
			unixtime: 1656832554,
			balance: 99672.08
		},
		{
			unixtime: 1656832614,
			balance: 99672.08
		},
		{
			unixtime: 1656832674,
			balance: 99672.08
		},
		{
			unixtime: 1656832734,
			balance: 99672.08
		},
		{
			unixtime: 1656832794,
			balance: 99672.08
		},
		{
			unixtime: 1656832854,
			balance: 99672.08
		},
		{
			unixtime: 1656832914,
			balance: 99672.08
		},
		{
			unixtime: 1656832974,
			balance: 99672.08
		},
		{
			unixtime: 1656833034,
			balance: 99672.08
		},
		{
			unixtime: 1656833094,
			balance: 99672.08
		},
		{
			unixtime: 1656833154,
			balance: 99672.08
		},
		{
			unixtime: 1656833214,
			balance: 99672.08
		},
		{
			unixtime: 1656833274,
			balance: 99672.08
		},
		{
			unixtime: 1656833334,
			balance: 99672.08
		},
		{
			unixtime: 1656833394,
			balance: 99672.08
		},
		{
			unixtime: 1656833454,
			balance: 99672.08
		},
		{
			unixtime: 1656833514,
			balance: 99672.08
		},
		{
			unixtime: 1656833574,
			balance: 99672.08
		},
		{
			unixtime: 1656833634,
			balance: 99672.08
		},
		{
			unixtime: 1656833694,
			balance: 99672.08
		},
		{
			unixtime: 1656833754,
			balance: 99672.08
		},
		{
			unixtime: 1656833814,
			balance: 99672.08
		},
		{
			unixtime: 1656833874,
			balance: 99672.08
		},
		{
			unixtime: 1656833934,
			balance: 99672.08
		},
		{
			unixtime: 1656833994,
			balance: 99672.08
		},
		{
			unixtime: 1656834054,
			balance: 99672.08
		},
		{
			unixtime: 1656834114,
			balance: 99672.08
		},
		{
			unixtime: 1656834174,
			balance: 99672.08
		},
		{
			unixtime: 1656834234,
			balance: 99672.08
		},
		{
			unixtime: 1656834294,
			balance: 99672.08
		},
		{
			unixtime: 1656834354,
			balance: 99672.08
		},
		{
			unixtime: 1656834414,
			balance: 99672.08
		},
		{
			unixtime: 1656834474,
			balance: 99672.08
		},
		{
			unixtime: 1656834534,
			balance: 99672.08
		},
		{
			unixtime: 1656834594,
			balance: 99672.08
		},
		{
			unixtime: 1656834654,
			balance: 99672.08
		},
		{
			unixtime: 1656834714,
			balance: 99672.08
		},
		{
			unixtime: 1656834774,
			balance: 99672.08
		},
		{
			unixtime: 1656834834,
			balance: 99672.08
		},
		{
			unixtime: 1656834894,
			balance: 99672.08
		},
		{
			unixtime: 1656834954,
			balance: 99672.08
		},
		{
			unixtime: 1656835014,
			balance: 99672.08
		},
		{
			unixtime: 1656835074,
			balance: 99672.08
		},
		{
			unixtime: 1656835134,
			balance: 99672.08
		},
		{
			unixtime: 1656835194,
			balance: 99672.08
		},
		{
			unixtime: 1656835254,
			balance: 99672.08
		},
		{
			unixtime: 1656835314,
			balance: 99672.08
		},
		{
			unixtime: 1656835374,
			balance: 99672.08
		},
		{
			unixtime: 1656835434,
			balance: 99672.08
		},
		{
			unixtime: 1656835494,
			balance: 99672.08
		},
		{
			unixtime: 1656835554,
			balance: 99672.08
		},
		{
			unixtime: 1656835614,
			balance: 99672.08
		},
		{
			unixtime: 1656835674,
			balance: 99672.08
		},
		{
			unixtime: 1656835734,
			balance: 99672.08
		},
		{
			unixtime: 1656835794,
			balance: 99672.08
		},
		{
			unixtime: 1656835854,
			balance: 99672.08
		},
		{
			unixtime: 1656835914,
			balance: 99672.08
		},
		{
			unixtime: 1656835974,
			balance: 99672.08
		},
		{
			unixtime: 1656836034,
			balance: 99672.08
		},
		{
			unixtime: 1656836094,
			balance: 99672.08
		},
		{
			unixtime: 1656836154,
			balance: 99672.08
		},
		{
			unixtime: 1656836214,
			balance: 99672.08
		},
		{
			unixtime: 1656836274,
			balance: 99672.08
		},
		{
			unixtime: 1656836334,
			balance: 99672.08
		},
		{
			unixtime: 1656836394,
			balance: 99672.08
		},
		{
			unixtime: 1656836454,
			balance: 99672.08
		},
		{
			unixtime: 1656836514,
			balance: 99672.08
		},
		{
			unixtime: 1656836574,
			balance: 99672.08
		},
		{
			unixtime: 1656836634,
			balance: 99672.08
		},
		{
			unixtime: 1656836694,
			balance: 99672.08
		},
		{
			unixtime: 1656836754,
			balance: 99672.08
		},
		{
			unixtime: 1656836814,
			balance: 99672.08
		},
		{
			unixtime: 1656836874,
			balance: 99672.08
		},
		{
			unixtime: 1656836934,
			balance: 99672.08
		},
		{
			unixtime: 1656836994,
			balance: 99672.08
		},
		{
			unixtime: 1656837054,
			balance: 99672.08
		},
		{
			unixtime: 1656837114,
			balance: 99672.08
		},
		{
			unixtime: 1656837174,
			balance: 99672.08
		},
		{
			unixtime: 1656837234,
			balance: 99672.08
		},
		{
			unixtime: 1656837294,
			balance: 99672.08
		},
		{
			unixtime: 1656837354,
			balance: 99672.08
		},
		{
			unixtime: 1656837414,
			balance: 99672.08
		},
		{
			unixtime: 1656837474,
			balance: 99672.08
		},
		{
			unixtime: 1656837534,
			balance: 99672.08
		},
		{
			unixtime: 1656837594,
			balance: 99672.08
		},
		{
			unixtime: 1656837654,
			balance: 99672.08
		},
		{
			unixtime: 1656837714,
			balance: 99672.08
		},
		{
			unixtime: 1656837774,
			balance: 99672.08
		},
		{
			unixtime: 1656837834,
			balance: 99672.08
		},
		{
			unixtime: 1656837894,
			balance: 99672.08
		},
		{
			unixtime: 1656837954,
			balance: 99672.08
		},
		{
			unixtime: 1656838014,
			balance: 99672.08
		},
		{
			unixtime: 1656838074,
			balance: 99672.08
		},
		{
			unixtime: 1656838134,
			balance: 99672.08
		},
		{
			unixtime: 1656838194,
			balance: 99672.08
		},
		{
			unixtime: 1656838254,
			balance: 99672.08
		},
		{
			unixtime: 1656838314,
			balance: 99672.08
		},
		{
			unixtime: 1656838374,
			balance: 99672.08
		},
		{
			unixtime: 1656838434,
			balance: 99672.08
		},
		{
			unixtime: 1656838494,
			balance: 99672.08
		},
		{
			unixtime: 1656838554,
			balance: 99672.08
		},
		{
			unixtime: 1656838614,
			balance: 99672.08
		},
		{
			unixtime: 1656838674,
			balance: 99672.08
		},
		{
			unixtime: 1656838734,
			balance: 99672.08
		},
		{
			unixtime: 1656838794,
			balance: 99672.08
		},
		{
			unixtime: 1656838854,
			balance: 99672.08
		},
		{
			unixtime: 1656838914,
			balance: 99672.08
		},
		{
			unixtime: 1656838974,
			balance: 99672.08
		},
		{
			unixtime: 1656839034,
			balance: 99672.08
		},
		{
			unixtime: 1656839094,
			balance: 99672.08
		},
		{
			unixtime: 1656839154,
			balance: 99672.08
		},
		{
			unixtime: 1656839214,
			balance: 99672.08
		},
		{
			unixtime: 1656839274,
			balance: 99672.08
		},
		{
			unixtime: 1656839334,
			balance: 99672.08
		},
		{
			unixtime: 1656839394,
			balance: 99672.08
		},
		{
			unixtime: 1656839454,
			balance: 99672.08
		},
		{
			unixtime: 1656839514,
			balance: 99672.08
		},
		{
			unixtime: 1656839574,
			balance: 99672.08
		},
		{
			unixtime: 1656839634,
			balance: 99672.08
		},
		{
			unixtime: 1656839694,
			balance: 99672.08
		},
		{
			unixtime: 1656839754,
			balance: 99672.08
		},
		{
			unixtime: 1656839814,
			balance: 99672.08
		},
		{
			unixtime: 1656839874,
			balance: 99672.08
		},
		{
			unixtime: 1656839934,
			balance: 99672.08
		},
		{
			unixtime: 1656839994,
			balance: 99672.08
		},
		{
			unixtime: 1656840054,
			balance: 99672.08
		},
		{
			unixtime: 1656840114,
			balance: 99672.08
		},
		{
			unixtime: 1656840174,
			balance: 99672.08
		},
		{
			unixtime: 1656840234,
			balance: 99672.08
		},
		{
			unixtime: 1656840294,
			balance: 99672.08
		},
		{
			unixtime: 1656840354,
			balance: 99672.08
		},
		{
			unixtime: 1656840414,
			balance: 99672.08
		},
		{
			unixtime: 1656840474,
			balance: 99672.08
		},
		{
			unixtime: 1656840534,
			balance: 99672.08
		},
		{
			unixtime: 1656840594,
			balance: 99672.08
		},
		{
			unixtime: 1656840654,
			balance: 99672.08
		},
		{
			unixtime: 1656840714,
			balance: 99672.08
		},
		{
			unixtime: 1656840774,
			balance: 99672.08
		},
		{
			unixtime: 1656840834,
			balance: 99672.08
		},
		{
			unixtime: 1656840894,
			balance: 99672.08
		},
		{
			unixtime: 1656840954,
			balance: 99672.08
		},
		{
			unixtime: 1656841014,
			balance: 99672.08
		},
		{
			unixtime: 1656841074,
			balance: 99672.08
		},
		{
			unixtime: 1656841134,
			balance: 99672.08
		},
		{
			unixtime: 1656841194,
			balance: 99672.08
		},
		{
			unixtime: 1656841254,
			balance: 99672.08
		},
		{
			unixtime: 1656841314,
			balance: 99672.08
		},
		{
			unixtime: 1656841374,
			balance: 99672.08
		},
		{
			unixtime: 1656841434,
			balance: 99672.08
		},
		{
			unixtime: 1656841494,
			balance: 99672.08
		},
		{
			unixtime: 1656841554,
			balance: 99672.08
		},
		{
			unixtime: 1656841614,
			balance: 99672.08
		},
		{
			unixtime: 1656841674,
			balance: 99672.08
		},
		{
			unixtime: 1656841734,
			balance: 99672.08
		},
		{
			unixtime: 1656841794,
			balance: 99672.08
		},
		{
			unixtime: 1656841854,
			balance: 99672.08
		},
		{
			unixtime: 1656841914,
			balance: 99672.08
		},
		{
			unixtime: 1656841974,
			balance: 99672.08
		},
		{
			unixtime: 1656842034,
			balance: 99672.08
		},
		{
			unixtime: 1656842094,
			balance: 99672.08
		},
		{
			unixtime: 1656842154,
			balance: 99672.08
		},
		{
			unixtime: 1656842214,
			balance: 99672.08
		},
		{
			unixtime: 1656842274,
			balance: 99672.08
		},
		{
			unixtime: 1656842334,
			balance: 99672.08
		},
		{
			unixtime: 1656842394,
			balance: 99672.08
		},
		{
			unixtime: 1656842454,
			balance: 99672.08
		},
		{
			unixtime: 1656842514,
			balance: 99672.08
		},
		{
			unixtime: 1656842574,
			balance: 99672.08
		},
		{
			unixtime: 1656842634,
			balance: 99672.08
		},
		{
			unixtime: 1656842694,
			balance: 99672.08
		},
		{
			unixtime: 1656842754,
			balance: 99672.08
		},
		{
			unixtime: 1656842814,
			balance: 99672.08
		},
		{
			unixtime: 1656842874,
			balance: 99672.08
		},
		{
			unixtime: 1656842934,
			balance: 99672.08
		},
		{
			unixtime: 1656842994,
			balance: 99672.08
		},
		{
			unixtime: 1656843054,
			balance: 99672.08
		},
		{
			unixtime: 1656843114,
			balance: 99672.08
		},
		{
			unixtime: 1656843174,
			balance: 99672.08
		},
		{
			unixtime: 1656843234,
			balance: 99672.08
		},
		{
			unixtime: 1656843294,
			balance: 99672.08
		},
		{
			unixtime: 1656843354,
			balance: 99672.08
		},
		{
			unixtime: 1656843414,
			balance: 99672.08
		},
		{
			unixtime: 1656843474,
			balance: 99672.08
		},
		{
			unixtime: 1656843534,
			balance: 99672.08
		},
		{
			unixtime: 1656843594,
			balance: 99672.08
		},
		{
			unixtime: 1656843654,
			balance: 99672.08
		},
		{
			unixtime: 1656843714,
			balance: 99672.08
		},
		{
			unixtime: 1656843774,
			balance: 99672.08
		},
		{
			unixtime: 1656843834,
			balance: 99672.08
		},
		{
			unixtime: 1656843894,
			balance: 99672.08
		},
		{
			unixtime: 1656843954,
			balance: 99672.08
		},
		{
			unixtime: 1656844014,
			balance: 99672.08
		},
		{
			unixtime: 1656844074,
			balance: 99672.08
		},
		{
			unixtime: 1656844134,
			balance: 99672.08
		},
		{
			unixtime: 1656844194,
			balance: 99672.08
		},
		{
			unixtime: 1656844254,
			balance: 99672.08
		},
		{
			unixtime: 1656844314,
			balance: 99672.08
		},
		{
			unixtime: 1656844374,
			balance: 99672.08
		},
		{
			unixtime: 1656844435,
			balance: 99672.08
		},
		{
			unixtime: 1656844494,
			balance: 99672.08
		},
		{
			unixtime: 1656844554,
			balance: 99672.08
		},
		{
			unixtime: 1656844614,
			balance: 99672.08
		},
		{
			unixtime: 1656844674,
			balance: 99672.08
		},
		{
			unixtime: 1656844735,
			balance: 99672.08
		},
		{
			unixtime: 1656844794,
			balance: 99672.08
		},
		{
			unixtime: 1656844854,
			balance: 99672.08
		},
		{
			unixtime: 1656844914,
			balance: 99672.08
		},
		{
			unixtime: 1656844974,
			balance: 99672.08
		},
		{
			unixtime: 1656845034,
			balance: 99672.08
		},
		{
			unixtime: 1656845094,
			balance: 99672.08
		},
		{
			unixtime: 1656845154,
			balance: 99672.08
		},
		{
			unixtime: 1656845215,
			balance: 99672.08
		},
		{
			unixtime: 1656845275,
			balance: 99672.08
		},
		{
			unixtime: 1656845335,
			balance: 99672.08
		},
		{
			unixtime: 1656845395,
			balance: 99672.08
		},
		{
			unixtime: 1656845455,
			balance: 99672.08
		},
		{
			unixtime: 1656845515,
			balance: 99672.08
		},
		{
			unixtime: 1656845575,
			balance: 99672.08
		},
		{
			unixtime: 1656845635,
			balance: 99672.08
		},
		{
			unixtime: 1656845695,
			balance: 99672.08
		},
		{
			unixtime: 1656845755,
			balance: 99672.08
		},
		{
			unixtime: 1656845815,
			balance: 99672.08
		},
		{
			unixtime: 1656845875,
			balance: 99672.08
		},
		{
			unixtime: 1656845935,
			balance: 99672.08
		},
		{
			unixtime: 1656845995,
			balance: 99672.08
		},
		{
			unixtime: 1656846055,
			balance: 99672.08
		},
		{
			unixtime: 1656846115,
			balance: 99672.08
		},
		{
			unixtime: 1656846175,
			balance: 99672.08
		},
		{
			unixtime: 1656846235,
			balance: 99672.08
		},
		{
			unixtime: 1656846295,
			balance: 99672.08
		},
		{
			unixtime: 1656846355,
			balance: 99672.08
		},
		{
			unixtime: 1656846415,
			balance: 99672.08
		},
		{
			unixtime: 1656846475,
			balance: 99672.08
		},
		{
			unixtime: 1656846535,
			balance: 99672.08
		},
		{
			unixtime: 1656846595,
			balance: 99672.08
		},
		{
			unixtime: 1656846655,
			balance: 99672.08
		},
		{
			unixtime: 1656846715,
			balance: 99672.08
		},
		{
			unixtime: 1656846775,
			balance: 99672.08
		},
		{
			unixtime: 1656846835,
			balance: 99672.08
		},
		{
			unixtime: 1656846895,
			balance: 99672.08
		},
		{
			unixtime: 1656846955,
			balance: 99672.08
		},
		{
			unixtime: 1656847015,
			balance: 99672.08
		},
		{
			unixtime: 1656847075,
			balance: 99672.08
		},
		{
			unixtime: 1656847135,
			balance: 99672.08
		},
		{
			unixtime: 1656847195,
			balance: 99672.08
		},
		{
			unixtime: 1656847255,
			balance: 99672.08
		},
		{
			unixtime: 1656847315,
			balance: 99672.08
		},
		{
			unixtime: 1656847407,
			balance: 99672.08
		},
		{
			unixtime: 1656847467,
			balance: 99672.08
		},
		{
			unixtime: 1656847527,
			balance: 99672.08
		},
		{
			unixtime: 1656847587,
			balance: 99672.08
		},
		{
			unixtime: 1656847647,
			balance: 99672.08
		},
		{
			unixtime: 1656847707,
			balance: 99672.08
		},
		{
			unixtime: 1656847767,
			balance: 99672.08
		},
		{
			unixtime: 1656847827,
			balance: 99672.08
		},
		{
			unixtime: 1656847887,
			balance: 99672.08
		},
		{
			unixtime: 1656847947,
			balance: 99672.08
		},
		{
			unixtime: 1656848007,
			balance: 99672.08
		},
		{
			unixtime: 1656848067,
			balance: 99672.08
		},
		{
			unixtime: 1656848252,
			balance: 99672.08
		},
		{
			unixtime: 1656848312,
			balance: 99672.08
		},
		{
			unixtime: 1656848372,
			balance: 99672.08
		},
		{
			unixtime: 1656848432,
			balance: 99672.08
		},
		{
			unixtime: 1656848492,
			balance: 99672.08
		},
		{
			unixtime: 1656848552,
			balance: 99672.08
		},
		{
			unixtime: 1656848612,
			balance: 99672.08
		},
		{
			unixtime: 1656848672,
			balance: 99672.08
		},
		{
			unixtime: 1656848732,
			balance: 99672.08
		},
		{
			unixtime: 1656848792,
			balance: 99672.08
		},
		{
			unixtime: 1656848852,
			balance: 99672.08
		},
		{
			unixtime: 1656848912,
			balance: 99672.08
		},
		{
			unixtime: 1656848972,
			balance: 99672.08
		},
		{
			unixtime: 1656849032,
			balance: 99672.08
		},
		{
			unixtime: 1656849092,
			balance: 99672.08
		},
		{
			unixtime: 1656849152,
			balance: 99672.08
		},
		{
			unixtime: 1656849282,
			balance: 99672.08
		},
		{
			unixtime: 1656849342,
			balance: 99672.08
		},
		{
			unixtime: 1656849402,
			balance: 99672.08
		},
		{
			unixtime: 1656849462,
			balance: 99672.08
		},
		{
			unixtime: 1656849522,
			balance: 99672.08
		},
		{
			unixtime: 1656849582,
			balance: 99672.08
		},
		{
			unixtime: 1656849642,
			balance: 99672.08
		},
		{
			unixtime: 1656849702,
			balance: 99672.08
		},
		{
			unixtime: 1656849762,
			balance: 99672.08
		},
		{
			unixtime: 1656849822,
			balance: 99672.08
		},
		{
			unixtime: 1656849882,
			balance: 99672.08
		},
		{
			unixtime: 1656849942,
			balance: 99672.08
		},
		{
			unixtime: 1656850002,
			balance: 99672.08
		},
		{
			unixtime: 1656850062,
			balance: 99672.08
		},
		{
			unixtime: 1656850122,
			balance: 99672.08
		},
		{
			unixtime: 1656850182,
			balance: 99672.08
		},
		{
			unixtime: 1656850242,
			balance: 99672.08
		},
		{
			unixtime: 1656850302,
			balance: 99672.08
		},
		{
			unixtime: 1656850362,
			balance: 99672.08
		},
		{
			unixtime: 1656850422,
			balance: 99672.08
		},
		{
			unixtime: 1656850482,
			balance: 99672.08
		},
		{
			unixtime: 1656850542,
			balance: 99672.08
		},
		{
			unixtime: 1656850602,
			balance: 99672.08
		},
		{
			unixtime: 1656850662,
			balance: 99672.08
		},
		{
			unixtime: 1656850722,
			balance: 99672.08
		},
		{
			unixtime: 1656850782,
			balance: 99672.08
		},
		{
			unixtime: 1656850842,
			balance: 99672.08
		},
		{
			unixtime: 1656850902,
			balance: 99672.08
		},
		{
			unixtime: 1656850962,
			balance: 99672.08
		},
		{
			unixtime: 1656851022,
			balance: 99672.08
		},
		{
			unixtime: 1656851082,
			balance: 99672.08
		},
		{
			unixtime: 1656851142,
			balance: 99672.08
		},
		{
			unixtime: 1656851202,
			balance: 99672.08
		},
		{
			unixtime: 1656851301,
			balance: 99672.08
		},
		{
			unixtime: 1656851361,
			balance: 99672.08
		},
		{
			unixtime: 1656851421,
			balance: 99672.08
		},
		{
			unixtime: 1656851481,
			balance: 99672.08
		},
		{
			unixtime: 1656851541,
			balance: 99672.08
		},
		{
			unixtime: 1656851601,
			balance: 99672.08
		},
		{
			unixtime: 1656851661,
			balance: 99672.08
		},
		{
			unixtime: 1656851721,
			balance: 99672.08
		},
		{
			unixtime: 1656851781,
			balance: 99672.08
		},
		{
			unixtime: 1656851841,
			balance: 99672.08
		},
		{
			unixtime: 1656851901,
			balance: 99672.08
		},
		{
			unixtime: 1656851961,
			balance: 99672.08
		},
		{
			unixtime: 1656852021,
			balance: 99672.08
		},
		{
			unixtime: 1656852081,
			balance: 99672.08
		},
		{
			unixtime: 1656852141,
			balance: 99672.08
		},
		{
			unixtime: 1656852201,
			balance: 99672.08
		},
		{
			unixtime: 1656852261,
			balance: 99672.08
		},
		{
			unixtime: 1656852321,
			balance: 99672.08
		},
		{
			unixtime: 1656852381,
			balance: 99672.08
		},
		{
			unixtime: 1656852441,
			balance: 99672.08
		},
		{
			unixtime: 1656852501,
			balance: 99672.08
		},
		{
			unixtime: 1656852561,
			balance: 99672.08
		},
		{
			unixtime: 1656852621,
			balance: 99672.08
		},
		{
			unixtime: 1656852681,
			balance: 99672.08
		},
		{
			unixtime: 1656852741,
			balance: 99672.08
		},
		{
			unixtime: 1656852801,
			balance: 99672.08
		},
		{
			unixtime: 1656852861,
			balance: 99672.08
		},
		{
			unixtime: 1656852921,
			balance: 99672.08
		},
		{
			unixtime: 1656852981,
			balance: 99672.08
		},
		{
			unixtime: 1656853041,
			balance: 99672.08
		},
		{
			unixtime: 1656853101,
			balance: 99672.08
		},
		{
			unixtime: 1656853161,
			balance: 99672.08
		},
		{
			unixtime: 1656853221,
			balance: 99672.08
		},
		{
			unixtime: 1656853281,
			balance: 99672.08
		},
		{
			unixtime: 1656853341,
			balance: 99672.08
		},
		{
			unixtime: 1656853401,
			balance: 99672.08
		},
		{
			unixtime: 1656853540,
			balance: 99672.08
		},
		{
			unixtime: 1656853600,
			balance: 99672.08
		},
		{
			unixtime: 1656853660,
			balance: 99672.08
		},
		{
			unixtime: 1656853720,
			balance: 99672.08
		},
		{
			unixtime: 1656853780,
			balance: 99672.08
		},
		{
			unixtime: 1656853840,
			balance: 99672.08
		},
		{
			unixtime: 1656853900,
			balance: 99672.08
		},
		{
			unixtime: 1656853960,
			balance: 99672.08
		},
		{
			unixtime: 1656854020,
			balance: 99672.08
		},
		{
			unixtime: 1656854080,
			balance: 99672.08
		},
		{
			unixtime: 1656854140,
			balance: 99672.08
		},
		{
			unixtime: 1656854200,
			balance: 99672.08
		},
		{
			unixtime: 1656854260,
			balance: 99672.08
		},
		{
			unixtime: 1656854320,
			balance: 99672.08
		},
		{
			unixtime: 1656854380,
			balance: 99672.08
		},
		{
			unixtime: 1656854440,
			balance: 99672.08
		},
		{
			unixtime: 1656854500,
			balance: 99672.08
		},
		{
			unixtime: 1656854560,
			balance: 99672.08
		},
		{
			unixtime: 1656854620,
			balance: 99672.08
		},
		{
			unixtime: 1656854680,
			balance: 99672.08
		},
		{
			unixtime: 1656854740,
			balance: 99672.08
		},
		{
			unixtime: 1656854800,
			balance: 99672.08
		},
		{
			unixtime: 1656854860,
			balance: 99672.08
		},
		{
			unixtime: 1656854920,
			balance: 99672.08
		},
		{
			unixtime: 1656854980,
			balance: 99672.08
		},
		{
			unixtime: 1656855040,
			balance: 99672.08
		},
		{
			unixtime: 1656855100,
			balance: 99672.08
		},
		{
			unixtime: 1656855160,
			balance: 99672.08
		},
		{
			unixtime: 1656855220,
			balance: 99672.08
		},
		{
			unixtime: 1656855280,
			balance: 99672.08
		},
		{
			unixtime: 1656855340,
			balance: 99672.08
		},
		{
			unixtime: 1656855400,
			balance: 99672.08
		},
		{
			unixtime: 1656855460,
			balance: 99672.08
		},
		{
			unixtime: 1656855520,
			balance: 99672.08
		},
		{
			unixtime: 1656855580,
			balance: 99672.08
		},
		{
			unixtime: 1656855640,
			balance: 99672.08
		},
		{
			unixtime: 1656855700,
			balance: 99672.08
		},
		{
			unixtime: 1656855760,
			balance: 99672.08
		},
		{
			unixtime: 1656855820,
			balance: 99672.08
		},
		{
			unixtime: 1656855880,
			balance: 99672.08
		},
		{
			unixtime: 1656855940,
			balance: 99672.08
		},
		{
			unixtime: 1656856000,
			balance: 99672.08
		},
		{
			unixtime: 1656856060,
			balance: 99672.08
		},
		{
			unixtime: 1656856120,
			balance: 99672.08
		},
		{
			unixtime: 1656856180,
			balance: 99672.08
		},
		{
			unixtime: 1656856240,
			balance: 99672.08
		},
		{
			unixtime: 1656856300,
			balance: 99672.08
		},
		{
			unixtime: 1656856360,
			balance: 99672.08
		},
		{
			unixtime: 1656856420,
			balance: 99672.08
		},
		{
			unixtime: 1656856480,
			balance: 99672.08
		},
		{
			unixtime: 1656856540,
			balance: 99672.08
		},
		{
			unixtime: 1656856600,
			balance: 99672.08
		},
		{
			unixtime: 1656856660,
			balance: 99672.08
		},
		{
			unixtime: 1656856720,
			balance: 99672.08
		},
		{
			unixtime: 1656856780,
			balance: 99672.08
		},
		{
			unixtime: 1656856840,
			balance: 99672.08
		},
		{
			unixtime: 1656856900,
			balance: 99672.08
		},
		{
			unixtime: 1656856960,
			balance: 99672.08
		},
		{
			unixtime: 1656857020,
			balance: 99672.08
		},
		{
			unixtime: 1656857080,
			balance: 99672.08
		},
		{
			unixtime: 1656857140,
			balance: 99672.08
		},
		{
			unixtime: 1656857200,
			balance: 99672.08
		},
		{
			unixtime: 1656857260,
			balance: 99672.08
		},
		{
			unixtime: 1656857320,
			balance: 99672.08
		},
		{
			unixtime: 1656857380,
			balance: 99672.08
		},
		{
			unixtime: 1656857440,
			balance: 99672.08
		},
		{
			unixtime: 1656857500,
			balance: 99672.08
		},
		{
			unixtime: 1656857560,
			balance: 99672.08
		},
		{
			unixtime: 1656857620,
			balance: 99672.08
		},
		{
			unixtime: 1656857680,
			balance: 99672.08
		},
		{
			unixtime: 1656857740,
			balance: 99672.08
		},
		{
			unixtime: 1656857800,
			balance: 99672.08
		},
		{
			unixtime: 1656857860,
			balance: 99672.08
		},
		{
			unixtime: 1656857920,
			balance: 99672.08
		},
		{
			unixtime: 1656857980,
			balance: 99672.08
		},
		{
			unixtime: 1656858040,
			balance: 99672.08
		},
		{
			unixtime: 1656858100,
			balance: 99672.08
		},
		{
			unixtime: 1656858160,
			balance: 99672.08
		},
		{
			unixtime: 1656858220,
			balance: 99672.08
		},
		{
			unixtime: 1656858280,
			balance: 99672.08
		},
		{
			unixtime: 1656858340,
			balance: 99672.08
		},
		{
			unixtime: 1656858400,
			balance: 99672.08
		},
		{
			unixtime: 1656858460,
			balance: 99672.08
		},
		{
			unixtime: 1656858520,
			balance: 99672.08
		},
		{
			unixtime: 1656858580,
			balance: 99672.08
		},
		{
			unixtime: 1656858640,
			balance: 99672.08
		},
		{
			unixtime: 1656858700,
			balance: 99672.08
		},
		{
			unixtime: 1656858760,
			balance: 99672.08
		},
		{
			unixtime: 1656858820,
			balance: 99672.08
		},
		{
			unixtime: 1656858880,
			balance: 99672.08
		},
		{
			unixtime: 1656858940,
			balance: 99672.08
		},
		{
			unixtime: 1656859000,
			balance: 99672.08
		},
		{
			unixtime: 1656859060,
			balance: 99672.08
		},
		{
			unixtime: 1656859120,
			balance: 99672.08
		},
		{
			unixtime: 1656859180,
			balance: 99672.08
		},
		{
			unixtime: 1656859240,
			balance: 99672.08
		},
		{
			unixtime: 1656859300,
			balance: 99672.08
		},
		{
			unixtime: 1656859360,
			balance: 99672.08
		},
		{
			unixtime: 1656859420,
			balance: 99672.08
		},
		{
			unixtime: 1656859480,
			balance: 99672.08
		},
		{
			unixtime: 1656859540,
			balance: 99672.08
		},
		{
			unixtime: 1656859600,
			balance: 99672.08
		},
		{
			unixtime: 1656859660,
			balance: 99672.08
		},
		{
			unixtime: 1656859720,
			balance: 99672.08
		},
		{
			unixtime: 1656859780,
			balance: 99672.08
		},
		{
			unixtime: 1656859840,
			balance: 99672.08
		},
		{
			unixtime: 1656859900,
			balance: 99672.08
		},
		{
			unixtime: 1656859960,
			balance: 99672.08
		},
		{
			unixtime: 1656860020,
			balance: 99672.08
		},
		{
			unixtime: 1656860080,
			balance: 99672.08
		},
		{
			unixtime: 1656860140,
			balance: 99672.08
		},
		{
			unixtime: 1656860200,
			balance: 99672.08
		},
		{
			unixtime: 1656860260,
			balance: 99672.08
		},
		{
			unixtime: 1656860320,
			balance: 99672.08
		},
		{
			unixtime: 1656860380,
			balance: 99672.08
		},
		{
			unixtime: 1656860440,
			balance: 99672.08
		},
		{
			unixtime: 1656860500,
			balance: 99672.08
		},
		{
			unixtime: 1656860560,
			balance: 99672.08
		},
		{
			unixtime: 1656860620,
			balance: 99672.08
		},
		{
			unixtime: 1656860680,
			balance: 99672.08
		},
		{
			unixtime: 1656860740,
			balance: 99672.08
		},
		{
			unixtime: 1656860800,
			balance: 99672.08
		},
		{
			unixtime: 1656860860,
			balance: 99672.08
		},
		{
			unixtime: 1656860920,
			balance: 99672.08
		},
		{
			unixtime: 1656860980,
			balance: 99672.08
		},
		{
			unixtime: 1656861040,
			balance: 99672.08
		},
		{
			unixtime: 1656861100,
			balance: 99672.08
		},
		{
			unixtime: 1656861160,
			balance: 99672.08
		},
		{
			unixtime: 1656861220,
			balance: 99672.08
		},
		{
			unixtime: 1656861280,
			balance: 99672.08
		},
		{
			unixtime: 1656861340,
			balance: 99672.08
		},
		{
			unixtime: 1656861400,
			balance: 99672.08
		},
		{
			unixtime: 1656861460,
			balance: 99672.08
		},
		{
			unixtime: 1656861520,
			balance: 99672.08
		},
		{
			unixtime: 1656861580,
			balance: 99672.08
		},
		{
			unixtime: 1656861640,
			balance: 99672.08
		},
		{
			unixtime: 1656861700,
			balance: 99672.08
		},
		{
			unixtime: 1656861760,
			balance: 99672.08
		},
		{
			unixtime: 1656861820,
			balance: 99672.08
		},
		{
			unixtime: 1656861880,
			balance: 99672.08
		},
		{
			unixtime: 1656861940,
			balance: 99672.08
		},
		{
			unixtime: 1656862000,
			balance: 99672.08
		},
		{
			unixtime: 1656862060,
			balance: 99672.08
		},
		{
			unixtime: 1656862120,
			balance: 99672.08
		},
		{
			unixtime: 1656862180,
			balance: 99672.08
		},
		{
			unixtime: 1656862240,
			balance: 99672.08
		},
		{
			unixtime: 1656862300,
			balance: 99672.08
		},
		{
			unixtime: 1656862360,
			balance: 99672.08
		},
		{
			unixtime: 1656862420,
			balance: 99672.08
		},
		{
			unixtime: 1656862480,
			balance: 99672.08
		},
		{
			unixtime: 1656862540,
			balance: 99672.08
		},
		{
			unixtime: 1656862600,
			balance: 99672.08
		},
		{
			unixtime: 1656862660,
			balance: 99672.08
		},
		{
			unixtime: 1656862720,
			balance: 99672.08
		},
		{
			unixtime: 1656862780,
			balance: 99672.08
		},
		{
			unixtime: 1656862840,
			balance: 99672.08
		},
		{
			unixtime: 1656862900,
			balance: 99672.08
		},
		{
			unixtime: 1656862960,
			balance: 99672.08
		},
		{
			unixtime: 1656863020,
			balance: 99672.08
		},
		{
			unixtime: 1656863080,
			balance: 99672.08
		},
		{
			unixtime: 1656863140,
			balance: 99672.08
		},
		{
			unixtime: 1656863200,
			balance: 99672.08
		},
		{
			unixtime: 1656863260,
			balance: 99672.08
		},
		{
			unixtime: 1656863320,
			balance: 99672.08
		},
		{
			unixtime: 1656863380,
			balance: 99672.08
		},
		{
			unixtime: 1656863440,
			balance: 99672.08
		},
		{
			unixtime: 1656863500,
			balance: 99672.08
		},
		{
			unixtime: 1656863560,
			balance: 99672.08
		},
		{
			unixtime: 1656863620,
			balance: 99672.08
		},
		{
			unixtime: 1656863680,
			balance: 99672.08
		},
		{
			unixtime: 1656863740,
			balance: 99672.08
		},
		{
			unixtime: 1656863800,
			balance: 99672.08
		},
		{
			unixtime: 1656863860,
			balance: 99672.08
		},
		{
			unixtime: 1656863920,
			balance: 99672.08
		},
		{
			unixtime: 1656863980,
			balance: 99672.08
		},
		{
			unixtime: 1656864040,
			balance: 99672.08
		},
		{
			unixtime: 1656864100,
			balance: 99672.08
		},
		{
			unixtime: 1656864160,
			balance: 99672.08
		},
		{
			unixtime: 1656864220,
			balance: 99672.08
		},
		{
			unixtime: 1656864280,
			balance: 99672.08
		},
		{
			unixtime: 1656864340,
			balance: 99672.08
		},
		{
			unixtime: 1656864400,
			balance: 99672.08
		},
		{
			unixtime: 1656864460,
			balance: 99672.08
		},
		{
			unixtime: 1656864520,
			balance: 99672.08
		},
		{
			unixtime: 1656864580,
			balance: 99672.08
		},
		{
			unixtime: 1656864640,
			balance: 99672.08
		},
		{
			unixtime: 1656864700,
			balance: 99672.08
		},
		{
			unixtime: 1656864760,
			balance: 99672.08
		},
		{
			unixtime: 1656864820,
			balance: 99672.08
		},
		{
			unixtime: 1656864880,
			balance: 99672.08
		},
		{
			unixtime: 1656864940,
			balance: 99672.08
		},
		{
			unixtime: 1656865000,
			balance: 99672.08
		},
		{
			unixtime: 1656865060,
			balance: 99672.08
		},
		{
			unixtime: 1656865120,
			balance: 99672.08
		},
		{
			unixtime: 1656865180,
			balance: 99672.08
		},
		{
			unixtime: 1656865240,
			balance: 99672.08
		},
		{
			unixtime: 1656865300,
			balance: 99672.08
		},
		{
			unixtime: 1656865360,
			balance: 99672.08
		},
		{
			unixtime: 1656865420,
			balance: 99672.08
		},
		{
			unixtime: 1656865480,
			balance: 99672.08
		},
		{
			unixtime: 1656865540,
			balance: 99672.08
		},
		{
			unixtime: 1656865600,
			balance: 99672.08
		},
		{
			unixtime: 1656865660,
			balance: 99672.08
		},
		{
			unixtime: 1656865720,
			balance: 99672.08
		},
		{
			unixtime: 1656865780,
			balance: 99672.08
		},
		{
			unixtime: 1656865840,
			balance: 99672.08
		},
		{
			unixtime: 1656865900,
			balance: 99672.08
		},
		{
			unixtime: 1656865960,
			balance: 99672.08
		},
		{
			unixtime: 1656866020,
			balance: 99672.08
		},
		{
			unixtime: 1656866080,
			balance: 99672.08
		},
		{
			unixtime: 1656866140,
			balance: 99672.08
		},
		{
			unixtime: 1656866200,
			balance: 99672.08
		},
		{
			unixtime: 1656866260,
			balance: 99672.08
		},
		{
			unixtime: 1656866320,
			balance: 99672.08
		},
		{
			unixtime: 1656866380,
			balance: 99672.08
		},
		{
			unixtime: 1656866440,
			balance: 99672.08
		},
		{
			unixtime: 1656866500,
			balance: 99672.08
		},
		{
			unixtime: 1656866560,
			balance: 99672.08
		},
		{
			unixtime: 1656866620,
			balance: 99672.08
		},
		{
			unixtime: 1656866680,
			balance: 99672.08
		},
		{
			unixtime: 1656866740,
			balance: 99672.08
		},
		{
			unixtime: 1656866800,
			balance: 99672.08
		},
		{
			unixtime: 1656866860,
			balance: 99672.08
		},
		{
			unixtime: 1656866920,
			balance: 99672.08
		},
		{
			unixtime: 1656866980,
			balance: 99672.08
		},
		{
			unixtime: 1656867040,
			balance: 99672.08
		},
		{
			unixtime: 1656867100,
			balance: 99672.08
		},
		{
			unixtime: 1656867160,
			balance: 99672.08
		},
		{
			unixtime: 1656867220,
			balance: 99672.08
		},
		{
			unixtime: 1656867280,
			balance: 99672.08
		},
		{
			unixtime: 1656867340,
			balance: 99672.08
		},
		{
			unixtime: 1656867400,
			balance: 99672.08
		},
		{
			unixtime: 1656867460,
			balance: 99672.08
		},
		{
			unixtime: 1656867520,
			balance: 99672.08
		},
		{
			unixtime: 1656867580,
			balance: 99672.08
		},
		{
			unixtime: 1656867640,
			balance: 99672.08
		},
		{
			unixtime: 1656867700,
			balance: 99672.08
		},
		{
			unixtime: 1656867760,
			balance: 99672.08
		},
		{
			unixtime: 1656867820,
			balance: 99672.08
		},
		{
			unixtime: 1656867880,
			balance: 99672.08
		},
		{
			unixtime: 1656867940,
			balance: 99672.08
		},
		{
			unixtime: 1656868000,
			balance: 99672.08
		},
		{
			unixtime: 1656868060,
			balance: 99672.08
		},
		{
			unixtime: 1656868120,
			balance: 99672.08
		},
		{
			unixtime: 1656868180,
			balance: 99672.08
		},
		{
			unixtime: 1656868240,
			balance: 99672.08
		},
		{
			unixtime: 1656868300,
			balance: 99672.08
		},
		{
			unixtime: 1656868360,
			balance: 99672.08
		},
		{
			unixtime: 1656868420,
			balance: 99672.08
		},
		{
			unixtime: 1656868480,
			balance: 99672.08
		},
		{
			unixtime: 1656868540,
			balance: 99672.08
		},
		{
			unixtime: 1656868600,
			balance: 99672.08
		},
		{
			unixtime: 1656868660,
			balance: 99672.08
		},
		{
			unixtime: 1656868720,
			balance: 99672.08
		},
		{
			unixtime: 1656868780,
			balance: 99672.08
		},
		{
			unixtime: 1656868840,
			balance: 99672.08
		},
		{
			unixtime: 1656868900,
			balance: 99672.08
		},
		{
			unixtime: 1656868960,
			balance: 99672.08
		},
		{
			unixtime: 1656869020,
			balance: 99672.08
		},
		{
			unixtime: 1656869080,
			balance: 99672.08
		},
		{
			unixtime: 1656869140,
			balance: 99672.08
		},
		{
			unixtime: 1656869200,
			balance: 99672.08
		},
		{
			unixtime: 1656869260,
			balance: 99672.08
		},
		{
			unixtime: 1656869320,
			balance: 99672.08
		},
		{
			unixtime: 1656869380,
			balance: 99672.08
		},
		{
			unixtime: 1656869440,
			balance: 99672.08
		},
		{
			unixtime: 1656869500,
			balance: 99672.08
		},
		{
			unixtime: 1656869560,
			balance: 99672.08
		},
		{
			unixtime: 1656869620,
			balance: 99672.08
		},
		{
			unixtime: 1656869680,
			balance: 99672.08
		},
		{
			unixtime: 1656869740,
			balance: 99672.08
		},
		{
			unixtime: 1656869800,
			balance: 99672.08
		},
		{
			unixtime: 1656869860,
			balance: 99672.08
		},
		{
			unixtime: 1656869920,
			balance: 99672.08
		},
		{
			unixtime: 1656869980,
			balance: 99672.08
		},
		{
			unixtime: 1656870040,
			balance: 99672.08
		},
		{
			unixtime: 1656870100,
			balance: 99672.08
		},
		{
			unixtime: 1656870160,
			balance: 99672.08
		},
		{
			unixtime: 1656870220,
			balance: 99672.08
		},
		{
			unixtime: 1656870280,
			balance: 99672.08
		},
		{
			unixtime: 1656870340,
			balance: 99672.08
		},
		{
			unixtime: 1656870400,
			balance: 99672.08
		},
		{
			unixtime: 1656870460,
			balance: 99672.08
		},
		{
			unixtime: 1656870520,
			balance: 99672.08
		},
		{
			unixtime: 1656870580,
			balance: 99672.08
		},
		{
			unixtime: 1656870640,
			balance: 99672.08
		},
		{
			unixtime: 1656870700,
			balance: 99672.08
		},
		{
			unixtime: 1656870760,
			balance: 99672.08
		},
		{
			unixtime: 1656870820,
			balance: 99672.08
		},
		{
			unixtime: 1656870880,
			balance: 99672.08
		},
		{
			unixtime: 1656870940,
			balance: 99672.08
		},
		{
			unixtime: 1656871000,
			balance: 99672.08
		},
		{
			unixtime: 1656871145,
			balance: 99672.08
		},
		{
			unixtime: 1656871205,
			balance: 99672.08
		},
		{
			unixtime: 1656871265,
			balance: 99672.08
		},
		{
			unixtime: 1656871325,
			balance: 99672.08
		},
		{
			unixtime: 1656871385,
			balance: 99672.08
		},
		{
			unixtime: 1656871445,
			balance: 99672.08
		},
		{
			unixtime: 1656871505,
			balance: 99672.08
		},
		{
			unixtime: 1656871565,
			balance: 99672.08
		},
		{
			unixtime: 1656871625,
			balance: 99672.08
		},
		{
			unixtime: 1656871685,
			balance: 99672.08
		},
		{
			unixtime: 1656871745,
			balance: 99672.08
		},
		{
			unixtime: 1656871805,
			balance: 99672.08
		},
		{
			unixtime: 1656871865,
			balance: 99672.08
		},
		{
			unixtime: 1656871925,
			balance: 99672.08
		},
		{
			unixtime: 1656871985,
			balance: 99672.08
		},
		{
			unixtime: 1656872045,
			balance: 99672.08
		},
		{
			unixtime: 1656872105,
			balance: 99672.08
		},
		{
			unixtime: 1656872165,
			balance: 99672.08
		},
		{
			unixtime: 1656872225,
			balance: 99672.08
		},
		{
			unixtime: 1656872285,
			balance: 99672.08
		},
		{
			unixtime: 1656872345,
			balance: 99672.08
		},
		{
			unixtime: 1656872405,
			balance: 99672.08
		},
		{
			unixtime: 1656872465,
			balance: 99672.08
		},
		{
			unixtime: 1656872525,
			balance: 99672.08
		},
		{
			unixtime: 1656872585,
			balance: 99672.08
		},
		{
			unixtime: 1656872645,
			balance: 99672.08
		},
		{
			unixtime: 1656872705,
			balance: 99672.08
		},
		{
			unixtime: 1656872765,
			balance: 99672.08
		},
		{
			unixtime: 1656872825,
			balance: 99672.08
		},
		{
			unixtime: 1656872885,
			balance: 99672.08
		},
		{
			unixtime: 1656872945,
			balance: 99672.08
		},
		{
			unixtime: 1656873005,
			balance: 99672.08
		},
		{
			unixtime: 1656873065,
			balance: 99672.08
		},
		{
			unixtime: 1656873125,
			balance: 99672.08
		},
		{
			unixtime: 1656873185,
			balance: 99672.08
		},
		{
			unixtime: 1656873245,
			balance: 99672.08
		},
		{
			unixtime: 1656873305,
			balance: 99672.08
		},
		{
			unixtime: 1656873365,
			balance: 99672.08
		},
		{
			unixtime: 1656873425,
			balance: 99672.08
		},
		{
			unixtime: 1656873485,
			balance: 99672.08
		},
		{
			unixtime: 1656873545,
			balance: 99672.08
		},
		{
			unixtime: 1656873605,
			balance: 99672.08
		},
		{
			unixtime: 1656873665,
			balance: 99672.08
		},
		{
			unixtime: 1656873725,
			balance: 99672.08
		},
		{
			unixtime: 1656873785,
			balance: 99672.08
		},
		{
			unixtime: 1656873845,
			balance: 99672.08
		},
		{
			unixtime: 1656873905,
			balance: 99672.08
		},
		{
			unixtime: 1656873965,
			balance: 99672.08
		},
		{
			unixtime: 1656874025,
			balance: 99672.08
		},
		{
			unixtime: 1656874085,
			balance: 99672.08
		},
		{
			unixtime: 1656874145,
			balance: 99672.08
		},
		{
			unixtime: 1656874205,
			balance: 99672.08
		},
		{
			unixtime: 1656874265,
			balance: 99672.08
		},
		{
			unixtime: 1656874325,
			balance: 99672.08
		},
		{
			unixtime: 1656874385,
			balance: 99672.08
		},
		{
			unixtime: 1656874445,
			balance: 99672.08
		},
		{
			unixtime: 1656874505,
			balance: 99672.08
		},
		{
			unixtime: 1656874565,
			balance: 99672.08
		},
		{
			unixtime: 1656874625,
			balance: 99672.08
		},
		{
			unixtime: 1656874685,
			balance: 99672.08
		},
		{
			unixtime: 1656874745,
			balance: 99672.08
		},
		{
			unixtime: 1656874805,
			balance: 99672.08
		},
		{
			unixtime: 1656874865,
			balance: 99672.08
		},
		{
			unixtime: 1656874925,
			balance: 99672.08
		},
		{
			unixtime: 1656874985,
			balance: 99672.08
		},
		{
			unixtime: 1656875045,
			balance: 99672.08
		},
		{
			unixtime: 1656875105,
			balance: 99672.08
		},
		{
			unixtime: 1656875165,
			balance: 99672.08
		},
		{
			unixtime: 1656875225,
			balance: 99672.08
		},
		{
			unixtime: 1656875285,
			balance: 99672.08
		},
		{
			unixtime: 1656875345,
			balance: 99672.08
		},
		{
			unixtime: 1656875405,
			balance: 99672.08
		},
		{
			unixtime: 1656875465,
			balance: 99672.08
		},
		{
			unixtime: 1656875525,
			balance: 99672.08
		},
		{
			unixtime: 1656875585,
			balance: 99672.08
		},
		{
			unixtime: 1656875645,
			balance: 99672.08
		},
		{
			unixtime: 1656875705,
			balance: 99672.08
		},
		{
			unixtime: 1656875765,
			balance: 99672.08
		},
		{
			unixtime: 1656875825,
			balance: 99672.08
		},
		{
			unixtime: 1656875885,
			balance: 99672.08
		},
		{
			unixtime: 1656875945,
			balance: 99672.08
		},
		{
			unixtime: 1656876005,
			balance: 99672.08
		},
		{
			unixtime: 1656876065,
			balance: 99672.08
		},
		{
			unixtime: 1656876125,
			balance: 99672.08
		},
		{
			unixtime: 1656876185,
			balance: 99672.08
		},
		{
			unixtime: 1656876245,
			balance: 99672.08
		},
		{
			unixtime: 1656876305,
			balance: 99672.08
		},
		{
			unixtime: 1656876365,
			balance: 99672.08
		},
		{
			unixtime: 1656876425,
			balance: 99672.08
		},
		{
			unixtime: 1656876485,
			balance: 99672.08
		},
		{
			unixtime: 1656876545,
			balance: 99672.08
		},
		{
			unixtime: 1656876605,
			balance: 99672.08
		},
		{
			unixtime: 1656876665,
			balance: 99672.08
		},
		{
			unixtime: 1656876725,
			balance: 99672.08
		},
		{
			unixtime: 1656876785,
			balance: 99672.08
		},
		{
			unixtime: 1656876845,
			balance: 99672.08
		},
		{
			unixtime: 1656876905,
			balance: 99672.08
		},
		{
			unixtime: 1656876965,
			balance: 99672.08
		},
		{
			unixtime: 1656877025,
			balance: 99672.08
		},
		{
			unixtime: 1656877085,
			balance: 99672.08
		},
		{
			unixtime: 1656877145,
			balance: 99672.08
		},
		{
			unixtime: 1656877205,
			balance: 99672.08
		},
		{
			unixtime: 1656877265,
			balance: 99672.08
		},
		{
			unixtime: 1656877325,
			balance: 99672.08
		},
		{
			unixtime: 1656877385,
			balance: 99672.08
		},
		{
			unixtime: 1656877445,
			balance: 99672.08
		},
		{
			unixtime: 1656877505,
			balance: 99672.08
		},
		{
			unixtime: 1656877565,
			balance: 99672.08
		},
		{
			unixtime: 1656877625,
			balance: 99672.08
		},
		{
			unixtime: 1656877685,
			balance: 99672.08
		},
		{
			unixtime: 1656877745,
			balance: 99672.08
		},
		{
			unixtime: 1656877805,
			balance: 99672.08
		},
		{
			unixtime: 1656877865,
			balance: 99672.08
		},
		{
			unixtime: 1656877925,
			balance: 99672.08
		},
		{
			unixtime: 1656877985,
			balance: 99672.08
		},
		{
			unixtime: 1656878045,
			balance: 99672.08
		},
		{
			unixtime: 1656878105,
			balance: 99672.08
		},
		{
			unixtime: 1656878165,
			balance: 99672.08
		},
		{
			unixtime: 1656878225,
			balance: 99672.08
		},
		{
			unixtime: 1656878285,
			balance: 99672.08
		},
		{
			unixtime: 1656878345,
			balance: 99672.08
		},
		{
			unixtime: 1656878405,
			balance: 99672.08
		},
		{
			unixtime: 1656878465,
			balance: 99672.08
		},
		{
			unixtime: 1656878525,
			balance: 99672.08
		},
		{
			unixtime: 1656878585,
			balance: 99672.08
		},
		{
			unixtime: 1656878645,
			balance: 99672.08
		},
		{
			unixtime: 1656878705,
			balance: 99672.08
		},
		{
			unixtime: 1656878765,
			balance: 99672.08
		},
		{
			unixtime: 1656878825,
			balance: 99672.08
		},
		{
			unixtime: 1656878885,
			balance: 99672.08
		},
		{
			unixtime: 1656878945,
			balance: 99672.08
		},
		{
			unixtime: 1656879005,
			balance: 99672.08
		},
		{
			unixtime: 1656879065,
			balance: 99672.08
		},
		{
			unixtime: 1656879125,
			balance: 99672.08
		},
		{
			unixtime: 1656879185,
			balance: 99672.08
		},
		{
			unixtime: 1656879245,
			balance: 99672.08
		},
		{
			unixtime: 1656879305,
			balance: 99672.08
		},
		{
			unixtime: 1656879365,
			balance: 99672.08
		},
		{
			unixtime: 1656879425,
			balance: 99672.08
		},
		{
			unixtime: 1656879485,
			balance: 99672.08
		},
		{
			unixtime: 1656879545,
			balance: 99672.08
		},
		{
			unixtime: 1656879605,
			balance: 99672.08
		},
		{
			unixtime: 1656879665,
			balance: 99672.08
		},
		{
			unixtime: 1656879725,
			balance: 99672.08
		},
		{
			unixtime: 1656879785,
			balance: 99672.08
		},
		{
			unixtime: 1656879845,
			balance: 99672.08
		},
		{
			unixtime: 1656879905,
			balance: 99672.08
		},
		{
			unixtime: 1656879965,
			balance: 99672.08
		},
		{
			unixtime: 1656880025,
			balance: 99672.08
		},
		{
			unixtime: 1656880085,
			balance: 99672.08
		},
		{
			unixtime: 1656880145,
			balance: 99672.08
		},
		{
			unixtime: 1656880205,
			balance: 99672.08
		},
		{
			unixtime: 1656880265,
			balance: 99672.08
		},
		{
			unixtime: 1656880325,
			balance: 99672.08
		},
		{
			unixtime: 1656880385,
			balance: 99672.08
		},
		{
			unixtime: 1656880445,
			balance: 99672.08
		},
		{
			unixtime: 1656880505,
			balance: 99672.08
		},
		{
			unixtime: 1656880565,
			balance: 99672.08
		},
		{
			unixtime: 1656880625,
			balance: 99672.08
		},
		{
			unixtime: 1656880685,
			balance: 99672.08
		},
		{
			unixtime: 1656880745,
			balance: 99672.08
		},
		{
			unixtime: 1656880805,
			balance: 99672.08
		},
		{
			unixtime: 1656880865,
			balance: 99672.08
		},
		{
			unixtime: 1656880925,
			balance: 99672.08
		},
		{
			unixtime: 1656880985,
			balance: 99672.08
		},
		{
			unixtime: 1656881045,
			balance: 99672.08
		},
		{
			unixtime: 1656881105,
			balance: 99672.08
		},
		{
			unixtime: 1656881165,
			balance: 99672.08
		},
		{
			unixtime: 1656881225,
			balance: 99672.08
		},
		{
			unixtime: 1656881285,
			balance: 99672.08
		},
		{
			unixtime: 1656881345,
			balance: 99672.08
		},
		{
			unixtime: 1656881405,
			balance: 99672.08
		},
		{
			unixtime: 1656881465,
			balance: 99672.08
		},
		{
			unixtime: 1656881525,
			balance: 99672.08
		},
		{
			unixtime: 1656881585,
			balance: 99672.08
		},
		{
			unixtime: 1656881645,
			balance: 99672.08
		},
		{
			unixtime: 1656881705,
			balance: 99672.08
		},
		{
			unixtime: 1656881765,
			balance: 99672.08
		},
		{
			unixtime: 1656881825,
			balance: 99672.08
		},
		{
			unixtime: 1656881885,
			balance: 99672.08
		},
		{
			unixtime: 1656881945,
			balance: 99672.08
		},
		{
			unixtime: 1656882005,
			balance: 99672.08
		},
		{
			unixtime: 1656882065,
			balance: 99672.08
		},
		{
			unixtime: 1656882125,
			balance: 99672.08
		},
		{
			unixtime: 1656882185,
			balance: 99672.08
		},
		{
			unixtime: 1656882245,
			balance: 99672.08
		},
		{
			unixtime: 1656882305,
			balance: 99672.08
		},
		{
			unixtime: 1656882365,
			balance: 99672.08
		},
		{
			unixtime: 1656882425,
			balance: 99672.08
		},
		{
			unixtime: 1656882485,
			balance: 99672.08
		},
		{
			unixtime: 1656882545,
			balance: 99672.08
		},
		{
			unixtime: 1656882605,
			balance: 99672.08
		},
		{
			unixtime: 1656882665,
			balance: 99672.08
		},
		{
			unixtime: 1656882725,
			balance: 99672.08
		},
		{
			unixtime: 1656882785,
			balance: 99672.08
		},
		{
			unixtime: 1656882845,
			balance: 99672.08
		},
		{
			unixtime: 1656882905,
			balance: 99672.08
		},
		{
			unixtime: 1656882965,
			balance: 99672.08
		},
		{
			unixtime: 1656883025,
			balance: 99672.08
		},
		{
			unixtime: 1656883085,
			balance: 99672.08
		},
		{
			unixtime: 1656883145,
			balance: 99672.08
		},
		{
			unixtime: 1656883205,
			balance: 99672.08
		},
		{
			unixtime: 1656883265,
			balance: 99672.08
		},
		{
			unixtime: 1656883325,
			balance: 99672.08
		},
		{
			unixtime: 1656883385,
			balance: 99672.08
		},
		{
			unixtime: 1656883445,
			balance: 99672.08
		},
		{
			unixtime: 1656883505,
			balance: 99672.08
		},
		{
			unixtime: 1656883565,
			balance: 99672.08
		},
		{
			unixtime: 1656883625,
			balance: 99672.08
		},
		{
			unixtime: 1656883685,
			balance: 99672.08
		},
		{
			unixtime: 1656883745,
			balance: 99672.08
		},
		{
			unixtime: 1656883805,
			balance: 99672.08
		},
		{
			unixtime: 1656883865,
			balance: 99672.08
		},
		{
			unixtime: 1656883925,
			balance: 99672.08
		},
		{
			unixtime: 1656883985,
			balance: 99672.08
		},
		{
			unixtime: 1656884045,
			balance: 99672.08
		},
		{
			unixtime: 1656884105,
			balance: 99672.08
		},
		{
			unixtime: 1656884165,
			balance: 99672.08
		},
		{
			unixtime: 1656884225,
			balance: 99672.08
		},
		{
			unixtime: 1656884285,
			balance: 99672.08
		},
		{
			unixtime: 1656884345,
			balance: 99672.08
		},
		{
			unixtime: 1656884405,
			balance: 99672.08
		},
		{
			unixtime: 1656884465,
			balance: 99672.08
		},
		{
			unixtime: 1656884525,
			balance: 99672.08
		},
		{
			unixtime: 1656884585,
			balance: 99672.08
		},
		{
			unixtime: 1656884645,
			balance: 99672.08
		},
		{
			unixtime: 1656884705,
			balance: 99672.08
		},
		{
			unixtime: 1656884765,
			balance: 99672.08
		},
		{
			unixtime: 1656884825,
			balance: 99672.08
		},
		{
			unixtime: 1656884885,
			balance: 99672.08
		},
		{
			unixtime: 1656884945,
			balance: 99672.08
		},
		{
			unixtime: 1656885005,
			balance: 99672.08
		},
		{
			unixtime: 1656885065,
			balance: 99672.08
		},
		{
			unixtime: 1656885125,
			balance: 99672.08
		},
		{
			unixtime: 1656885185,
			balance: 99672.08
		},
		{
			unixtime: 1656885245,
			balance: 99672.08
		},
		{
			unixtime: 1656885305,
			balance: 99672.08
		},
		{
			unixtime: 1656885365,
			balance: 99672.08
		},
		{
			unixtime: 1656885425,
			balance: 99672.08
		},
		{
			unixtime: 1656885485,
			balance: 99672.08
		},
		{
			unixtime: 1656885545,
			balance: 99672.08
		},
		{
			unixtime: 1656885605,
			balance: 99672.08
		},
		{
			unixtime: 1656885665,
			balance: 99672.08
		},
		{
			unixtime: 1656885725,
			balance: 99672.08
		},
		{
			unixtime: 1656885785,
			balance: 99672.08
		},
		{
			unixtime: 1656885845,
			balance: 99672.08
		},
		{
			unixtime: 1656885905,
			balance: 99672.08
		},
		{
			unixtime: 1656885965,
			balance: 99672.08
		},
		{
			unixtime: 1656886025,
			balance: 99672.08
		},
		{
			unixtime: 1656886085,
			balance: 99672.08
		},
		{
			unixtime: 1656886145,
			balance: 99672.08
		},
		{
			unixtime: 1656886205,
			balance: 99672.08
		},
		{
			unixtime: 1656886265,
			balance: 99672.08
		},
		{
			unixtime: 1656886325,
			balance: 99672.08
		},
		{
			unixtime: 1656886385,
			balance: 99672.08
		},
		{
			unixtime: 1656886445,
			balance: 99672.08
		},
		{
			unixtime: 1656886505,
			balance: 99672.08
		},
		{
			unixtime: 1656886565,
			balance: 99672.08
		},
		{
			unixtime: 1656886625,
			balance: 99672.08
		},
		{
			unixtime: 1656886685,
			balance: 99672.08
		},
		{
			unixtime: 1656886745,
			balance: 99672.08
		},
		{
			unixtime: 1656886805,
			balance: 99672.08
		},
		{
			unixtime: 1656886865,
			balance: 99672.08
		},
		{
			unixtime: 1656886925,
			balance: 99672.08
		},
		{
			unixtime: 1656886985,
			balance: 99672.08
		},
		{
			unixtime: 1656887045,
			balance: 99672.08
		},
		{
			unixtime: 1656887105,
			balance: 99672.08
		},
		{
			unixtime: 1656887165,
			balance: 99672.08
		},
		{
			unixtime: 1656887225,
			balance: 99672.08
		},
		{
			unixtime: 1656887285,
			balance: 99672.08
		},
		{
			unixtime: 1656887345,
			balance: 99672.08
		},
		{
			unixtime: 1656887405,
			balance: 99672.08
		},
		{
			unixtime: 1656887465,
			balance: 99672.08
		},
		{
			unixtime: 1656887525,
			balance: 99672.08
		},
		{
			unixtime: 1656887585,
			balance: 99672.08
		},
		{
			unixtime: 1656887645,
			balance: 99672.08
		},
		{
			unixtime: 1656887705,
			balance: 99672.08
		},
		{
			unixtime: 1656887765,
			balance: 99672.08
		},
		{
			unixtime: 1656887825,
			balance: 99672.08
		},
		{
			unixtime: 1656887885,
			balance: 99672.08
		},
		{
			unixtime: 1656887945,
			balance: 99672.08
		},
		{
			unixtime: 1656888005,
			balance: 99672.08
		},
		{
			unixtime: 1656888065,
			balance: 99672.08
		},
		{
			unixtime: 1656888126,
			balance: 99672.08
		},
		{
			unixtime: 1656888185,
			balance: 99672.08
		},
		{
			unixtime: 1656888246,
			balance: 99672.08
		},
		{
			unixtime: 1656888305,
			balance: 99672.08
		},
		{
			unixtime: 1656888365,
			balance: 99672.08
		},
		{
			unixtime: 1656888425,
			balance: 99672.08
		},
		{
			unixtime: 1656888485,
			balance: 99672.08
		},
		{
			unixtime: 1656888545,
			balance: 99672.08
		},
		{
			unixtime: 1656888605,
			balance: 99672.08
		},
		{
			unixtime: 1656888666,
			balance: 99672.08
		},
		{
			unixtime: 1656888725,
			balance: 99672.08
		},
		{
			unixtime: 1656888785,
			balance: 99672.08
		},
		{
			unixtime: 1656888845,
			balance: 99672.08
		},
		{
			unixtime: 1656888905,
			balance: 99672.08
		},
		{
			unixtime: 1656888965,
			balance: 99672.08
		},
		{
			unixtime: 1656889025,
			balance: 99672.08
		},
		{
			unixtime: 1656889085,
			balance: 99672.08
		},
		{
			unixtime: 1656889145,
			balance: 99672.08
		},
		{
			unixtime: 1656889206,
			balance: 99672.08
		},
		{
			unixtime: 1656889265,
			balance: 99672.08
		},
		{
			unixtime: 1656889326,
			balance: 99672.08
		},
		{
			unixtime: 1656889386,
			balance: 99672.08
		},
		{
			unixtime: 1656889446,
			balance: 99672.08
		},
		{
			unixtime: 1656889506,
			balance: 99672.08
		},
		{
			unixtime: 1656889566,
			balance: 99672.08
		},
		{
			unixtime: 1656889626,
			balance: 99672.08
		},
		{
			unixtime: 1656889686,
			balance: 99672.08
		},
		{
			unixtime: 1656889746,
			balance: 99672.08
		},
		{
			unixtime: 1656889806,
			balance: 99672.08
		},
		{
			unixtime: 1656889866,
			balance: 99672.08
		},
		{
			unixtime: 1656889926,
			balance: 99672.08
		},
		{
			unixtime: 1656889986,
			balance: 99672.08
		},
		{
			unixtime: 1656890046,
			balance: 99672.08
		},
		{
			unixtime: 1656890106,
			balance: 99672.08
		},
		{
			unixtime: 1656890166,
			balance: 99672.08
		},
		{
			unixtime: 1656890226,
			balance: 99672.08
		},
		{
			unixtime: 1656890286,
			balance: 99672.08
		},
		{
			unixtime: 1656890346,
			balance: 99672.08
		},
		{
			unixtime: 1656890406,
			balance: 99672.08
		},
		{
			unixtime: 1656890466,
			balance: 99672.08
		},
		{
			unixtime: 1656890526,
			balance: 99672.08
		},
		{
			unixtime: 1656890586,
			balance: 99672.08
		},
		{
			unixtime: 1656890646,
			balance: 99672.08
		},
		{
			unixtime: 1656890706,
			balance: 99672.08
		},
		{
			unixtime: 1656890766,
			balance: 99672.08
		},
		{
			unixtime: 1656890826,
			balance: 99672.08
		},
		{
			unixtime: 1656890886,
			balance: 99672.08
		},
		{
			unixtime: 1656890946,
			balance: 99672.08
		},
		{
			unixtime: 1656891006,
			balance: 99672.08
		},
		{
			unixtime: 1656891066,
			balance: 99672.08
		},
		{
			unixtime: 1656891126,
			balance: 99672.08
		},
		{
			unixtime: 1656891186,
			balance: 99672.08
		},
		{
			unixtime: 1656891246,
			balance: 99672.08
		},
		{
			unixtime: 1656891306,
			balance: 99672.08
		},
		{
			unixtime: 1656891366,
			balance: 99672.08
		},
		{
			unixtime: 1656891426,
			balance: 99672.08
		},
		{
			unixtime: 1656891486,
			balance: 99672.08
		},
		{
			unixtime: 1656891546,
			balance: 99672.08
		},
		{
			unixtime: 1656891606,
			balance: 99672.08
		},
		{
			unixtime: 1656891666,
			balance: 99672.08
		},
		{
			unixtime: 1656891726,
			balance: 99672.08
		},
		{
			unixtime: 1656891786,
			balance: 99672.08
		},
		{
			unixtime: 1656891846,
			balance: 99672.08
		},
		{
			unixtime: 1656891906,
			balance: 99672.08
		},
		{
			unixtime: 1656891966,
			balance: 99672.08
		},
		{
			unixtime: 1656892026,
			balance: 99672.08
		},
		{
			unixtime: 1656892086,
			balance: 99672.08
		},
		{
			unixtime: 1656892146,
			balance: 99672.08
		},
		{
			unixtime: 1656892206,
			balance: 99672.08
		},
		{
			unixtime: 1656892266,
			balance: 99672.08
		},
		{
			unixtime: 1656892326,
			balance: 99672.08
		},
		{
			unixtime: 1656892386,
			balance: 99672.08
		},
		{
			unixtime: 1656892446,
			balance: 99672.08
		},
		{
			unixtime: 1656892506,
			balance: 99672.08
		},
		{
			unixtime: 1656892566,
			balance: 99672.08
		},
		{
			unixtime: 1656892626,
			balance: 99672.08
		},
		{
			unixtime: 1656892686,
			balance: 99672.08
		},
		{
			unixtime: 1656892746,
			balance: 99672.08
		},
		{
			unixtime: 1656892806,
			balance: 99672.08
		},
		{
			unixtime: 1656892866,
			balance: 99672.08
		},
		{
			unixtime: 1656892926,
			balance: 99672.08
		},
		{
			unixtime: 1656892986,
			balance: 99672.08
		},
		{
			unixtime: 1656893046,
			balance: 99672.08
		},
		{
			unixtime: 1656893106,
			balance: 99672.08
		},
		{
			unixtime: 1656893166,
			balance: 99672.08
		},
		{
			unixtime: 1656893226,
			balance: 99672.08
		},
		{
			unixtime: 1656893286,
			balance: 99672.08
		},
		{
			unixtime: 1656893346,
			balance: 99672.08
		},
		{
			unixtime: 1656893406,
			balance: 99672.08
		},
		{
			unixtime: 1656893466,
			balance: 99672.08
		},
		{
			unixtime: 1656893526,
			balance: 99672.08
		},
		{
			unixtime: 1656893586,
			balance: 99672.08
		},
		{
			unixtime: 1656893646,
			balance: 99672.08
		},
		{
			unixtime: 1656893706,
			balance: 99672.08
		},
		{
			unixtime: 1656893766,
			balance: 99672.08
		},
		{
			unixtime: 1656893826,
			balance: 99672.08
		},
		{
			unixtime: 1656893886,
			balance: 99672.08
		},
		{
			unixtime: 1656893946,
			balance: 99672.08
		},
		{
			unixtime: 1656894006,
			balance: 99672.08
		},
		{
			unixtime: 1656894066,
			balance: 99672.08
		},
		{
			unixtime: 1656894126,
			balance: 99672.08
		},
		{
			unixtime: 1656894186,
			balance: 99672.08
		},
		{
			unixtime: 1656894246,
			balance: 99672.08
		},
		{
			unixtime: 1656894306,
			balance: 99672.08
		},
		{
			unixtime: 1656894366,
			balance: 99672.08
		},
		{
			unixtime: 1656894426,
			balance: 99672.08
		},
		{
			unixtime: 1656894486,
			balance: 99672.08
		},
		{
			unixtime: 1656894546,
			balance: 99672.08
		},
		{
			unixtime: 1656894606,
			balance: 99672.08
		},
		{
			unixtime: 1656894666,
			balance: 99672.08
		},
		{
			unixtime: 1656894726,
			balance: 99672.08
		},
		{
			unixtime: 1656894786,
			balance: 99672.08
		},
		{
			unixtime: 1656894846,
			balance: 99672.08
		},
		{
			unixtime: 1656894906,
			balance: 99672.08
		},
		{
			unixtime: 1656894966,
			balance: 99672.08
		},
		{
			unixtime: 1656895026,
			balance: 99672.08
		},
		{
			unixtime: 1656895086,
			balance: 99672.08
		},
		{
			unixtime: 1656895146,
			balance: 99672.08
		},
		{
			unixtime: 1656895206,
			balance: 99672.08
		},
		{
			unixtime: 1656895266,
			balance: 99672.08
		},
		{
			unixtime: 1656895326,
			balance: 99672.08
		},
		{
			unixtime: 1656895386,
			balance: 99672.08
		},
		{
			unixtime: 1656895446,
			balance: 99672.08
		},
		{
			unixtime: 1656895506,
			balance: 99672.08
		},
		{
			unixtime: 1656895566,
			balance: 99672.08
		},
		{
			unixtime: 1656895626,
			balance: 99672.08
		},
		{
			unixtime: 1656895686,
			balance: 99672.08
		},
		{
			unixtime: 1656895746,
			balance: 99672.08
		},
		{
			unixtime: 1656895806,
			balance: 99672.08
		},
		{
			unixtime: 1656895866,
			balance: 99672.08
		},
		{
			unixtime: 1656895926,
			balance: 99672.08
		},
		{
			unixtime: 1656895986,
			balance: 99672.08
		},
		{
			unixtime: 1656896046,
			balance: 99672.08
		},
		{
			unixtime: 1656896106,
			balance: 99672.08
		},
		{
			unixtime: 1656896166,
			balance: 99672.08
		},
		{
			unixtime: 1656896226,
			balance: 99672.08
		},
		{
			unixtime: 1656896286,
			balance: 99672.08
		},
		{
			unixtime: 1656896346,
			balance: 99672.08
		},
		{
			unixtime: 1656896406,
			balance: 99672.08
		},
		{
			unixtime: 1656896466,
			balance: 99672.08
		},
		{
			unixtime: 1656896526,
			balance: 99672.08
		},
		{
			unixtime: 1656896586,
			balance: 99672.08
		},
		{
			unixtime: 1656896646,
			balance: 99672.08
		},
		{
			unixtime: 1656896706,
			balance: 99672.08
		},
		{
			unixtime: 1656896766,
			balance: 99672.08
		},
		{
			unixtime: 1656896826,
			balance: 99672.08
		},
		{
			unixtime: 1656896886,
			balance: 99672.08
		},
		{
			unixtime: 1656896946,
			balance: 99672.08
		},
		{
			unixtime: 1656897006,
			balance: 99672.08
		},
		{
			unixtime: 1656897066,
			balance: 99672.08
		},
		{
			unixtime: 1656897126,
			balance: 99672.08
		},
		{
			unixtime: 1656897186,
			balance: 99672.08
		},
		{
			unixtime: 1656897246,
			balance: 99672.08
		},
		{
			unixtime: 1656897306,
			balance: 99672.08
		},
		{
			unixtime: 1656897366,
			balance: 99672.08
		},
		{
			unixtime: 1656897426,
			balance: 99672.08
		},
		{
			unixtime: 1656897486,
			balance: 99672.08
		},
		{
			unixtime: 1656897546,
			balance: 99672.08
		},
		{
			unixtime: 1656897606,
			balance: 99672.08
		},
		{
			unixtime: 1656897666,
			balance: 99672.08
		},
		{
			unixtime: 1656897726,
			balance: 99672.08
		},
		{
			unixtime: 1656897786,
			balance: 99672.08
		},
		{
			unixtime: 1656897846,
			balance: 99672.08
		},
		{
			unixtime: 1656897906,
			balance: 99672.08
		},
		{
			unixtime: 1656897966,
			balance: 99672.08
		},
		{
			unixtime: 1656898026,
			balance: 99672.08
		},
		{
			unixtime: 1656898086,
			balance: 99672.08
		},
		{
			unixtime: 1656898146,
			balance: 99672.08
		},
		{
			unixtime: 1656898206,
			balance: 99672.08
		},
		{
			unixtime: 1656898266,
			balance: 99672.08
		},
		{
			unixtime: 1656898326,
			balance: 99672.08
		},
		{
			unixtime: 1656898386,
			balance: 99672.08
		},
		{
			unixtime: 1656898446,
			balance: 99672.08
		},
		{
			unixtime: 1656898506,
			balance: 99672.08
		},
		{
			unixtime: 1656898566,
			balance: 99672.08
		},
		{
			unixtime: 1656898626,
			balance: 99672.08
		},
		{
			unixtime: 1656898686,
			balance: 99672.08
		},
		{
			unixtime: 1656898746,
			balance: 99672.08
		},
		{
			unixtime: 1656898806,
			balance: 99672.08
		},
		{
			unixtime: 1656898866,
			balance: 99672.08
		},
		{
			unixtime: 1656898926,
			balance: 99672.08
		},
		{
			unixtime: 1656898986,
			balance: 99672.08
		},
		{
			unixtime: 1656899046,
			balance: 99672.08
		},
		{
			unixtime: 1656899106,
			balance: 99672.08
		},
		{
			unixtime: 1656899166,
			balance: 99672.08
		},
		{
			unixtime: 1656899226,
			balance: 99672.08
		},
		{
			unixtime: 1656899286,
			balance: 99672.08
		},
		{
			unixtime: 1656899346,
			balance: 99672.08
		},
		{
			unixtime: 1656899406,
			balance: 99672.08
		},
		{
			unixtime: 1656899466,
			balance: 99672.08
		},
		{
			unixtime: 1656899526,
			balance: 99672.08
		},
		{
			unixtime: 1656899586,
			balance: 99672.08
		},
		{
			unixtime: 1656899646,
			balance: 99672.08
		},
		{
			unixtime: 1656899706,
			balance: 99672.08
		},
		{
			unixtime: 1656899766,
			balance: 99672.08
		},
		{
			unixtime: 1656899826,
			balance: 99672.08
		},
		{
			unixtime: 1656899886,
			balance: 99672.08
		},
		{
			unixtime: 1656899946,
			balance: 99672.08
		},
		{
			unixtime: 1656900006,
			balance: 99672.08
		},
		{
			unixtime: 1656900066,
			balance: 99672.08
		},
		{
			unixtime: 1656900126,
			balance: 99672.08
		},
		{
			unixtime: 1656900186,
			balance: 99672.08
		},
		{
			unixtime: 1656900246,
			balance: 99672.08
		},
		{
			unixtime: 1656900306,
			balance: 99672.08
		},
		{
			unixtime: 1656900366,
			balance: 99672.08
		},
		{
			unixtime: 1656900426,
			balance: 99672.08
		},
		{
			unixtime: 1656900486,
			balance: 99672.08
		},
		{
			unixtime: 1656900546,
			balance: 99672.08
		},
		{
			unixtime: 1656900606,
			balance: 99672.08
		},
		{
			unixtime: 1656900666,
			balance: 99672.08
		},
		{
			unixtime: 1656900726,
			balance: 99672.08
		},
		{
			unixtime: 1656900786,
			balance: 99672.08
		},
		{
			unixtime: 1656900846,
			balance: 99672.08
		},
		{
			unixtime: 1656900906,
			balance: 99672.08
		},
		{
			unixtime: 1656900966,
			balance: 99672.08
		},
		{
			unixtime: 1656901026,
			balance: 99672.08
		},
		{
			unixtime: 1656901086,
			balance: 99672.08
		},
		{
			unixtime: 1656901146,
			balance: 99672.08
		},
		{
			unixtime: 1656901206,
			balance: 99672.08
		},
		{
			unixtime: 1656901266,
			balance: 99672.08
		},
		{
			unixtime: 1656901326,
			balance: 99672.08
		},
		{
			unixtime: 1656901386,
			balance: 99672.08
		},
		{
			unixtime: 1656901446,
			balance: 99672.08
		},
		{
			unixtime: 1656901506,
			balance: 99672.08
		},
		{
			unixtime: 1656901566,
			balance: 99672.08
		},
		{
			unixtime: 1656901626,
			balance: 99672.08
		},
		{
			unixtime: 1656901686,
			balance: 99672.08
		},
		{
			unixtime: 1656901746,
			balance: 99672.08
		},
		{
			unixtime: 1656901806,
			balance: 99672.08
		},
		{
			unixtime: 1656901866,
			balance: 99672.08
		},
		{
			unixtime: 1656901926,
			balance: 99672.08
		},
		{
			unixtime: 1656901986,
			balance: 99672.08
		},
		{
			unixtime: 1656902046,
			balance: 99672.08
		},
		{
			unixtime: 1656902106,
			balance: 99672.08
		},
		{
			unixtime: 1656902166,
			balance: 99672.08
		},
		{
			unixtime: 1656902226,
			balance: 99672.08
		},
		{
			unixtime: 1656902286,
			balance: 99672.08
		},
		{
			unixtime: 1656902346,
			balance: 99672.08
		},
		{
			unixtime: 1656902406,
			balance: 99672.08
		},
		{
			unixtime: 1656902466,
			balance: 99672.08
		},
		{
			unixtime: 1656902526,
			balance: 99672.08
		},
		{
			unixtime: 1656902586,
			balance: 99672.08
		},
		{
			unixtime: 1656902646,
			balance: 99672.08
		},
		{
			unixtime: 1656902706,
			balance: 99672.08
		},
		{
			unixtime: 1656902766,
			balance: 99672.08
		},
		{
			unixtime: 1656902826,
			balance: 99672.08
		},
		{
			unixtime: 1656902886,
			balance: 99672.08
		},
		{
			unixtime: 1656902946,
			balance: 99672.08
		},
		{
			unixtime: 1656903006,
			balance: 99672.08
		},
		{
			unixtime: 1656903066,
			balance: 99672.08
		},
		{
			unixtime: 1656903126,
			balance: 99672.08
		},
		{
			unixtime: 1656903186,
			balance: 99672.08
		},
		{
			unixtime: 1656903246,
			balance: 99672.08
		},
		{
			unixtime: 1656903306,
			balance: 99672.08
		},
		{
			unixtime: 1656903366,
			balance: 99672.08
		},
		{
			unixtime: 1656903426,
			balance: 99672.08
		},
		{
			unixtime: 1656903486,
			balance: 99672.08
		},
		{
			unixtime: 1656903546,
			balance: 99672.08
		},
		{
			unixtime: 1656903606,
			balance: 99672.08
		},
		{
			unixtime: 1656903666,
			balance: 99672.08
		},
		{
			unixtime: 1656903726,
			balance: 99672.08
		},
		{
			unixtime: 1656903786,
			balance: 99672.08
		},
		{
			unixtime: 1656903846,
			balance: 99672.08
		},
		{
			unixtime: 1656903906,
			balance: 99672.08
		},
		{
			unixtime: 1656903966,
			balance: 99672.08
		},
		{
			unixtime: 1656904026,
			balance: 99672.08
		},
		{
			unixtime: 1656904086,
			balance: 99672.08
		},
		{
			unixtime: 1656904146,
			balance: 99672.08
		},
		{
			unixtime: 1656904206,
			balance: 99672.08
		},
		{
			unixtime: 1656904266,
			balance: 99672.08
		},
		{
			unixtime: 1656904326,
			balance: 99672.08
		},
		{
			unixtime: 1656904386,
			balance: 99672.08
		},
		{
			unixtime: 1656904446,
			balance: 99672.08
		},
		{
			unixtime: 1656904506,
			balance: 99672.08
		},
		{
			unixtime: 1656904566,
			balance: 99672.08
		},
		{
			unixtime: 1656904626,
			balance: 99672.08
		},
		{
			unixtime: 1656904686,
			balance: 99672.08
		},
		{
			unixtime: 1656904746,
			balance: 99672.08
		},
		{
			unixtime: 1656904806,
			balance: 99672.08
		},
		{
			unixtime: 1656904866,
			balance: 99672.08
		},
		{
			unixtime: 1656904926,
			balance: 99672.08
		},
		{
			unixtime: 1656904986,
			balance: 99672.08
		},
		{
			unixtime: 1656905046,
			balance: 99672.08
		},
		{
			unixtime: 1656905106,
			balance: 99672.08
		},
		{
			unixtime: 1656905166,
			balance: 99672.08
		},
		{
			unixtime: 1656905226,
			balance: 99672.08
		},
		{
			unixtime: 1656905286,
			balance: 99672.08
		},
		{
			unixtime: 1656905346,
			balance: 99672.08
		},
		{
			unixtime: 1656905406,
			balance: 99672.08
		},
		{
			unixtime: 1656905466,
			balance: 99672.08
		},
		{
			unixtime: 1656905526,
			balance: 99672.08
		},
		{
			unixtime: 1656905586,
			balance: 99672.08
		},
		{
			unixtime: 1656905646,
			balance: 99672.08
		},
		{
			unixtime: 1656905706,
			balance: 99672.08
		},
		{
			unixtime: 1656905766,
			balance: 99672.08
		},
		{
			unixtime: 1656905826,
			balance: 99672.08
		},
		{
			unixtime: 1656905888,
			balance: 99672.08
		},
		{
			unixtime: 1656905946,
			balance: 99672.08
		},
		{
			unixtime: 1656906006,
			balance: 99672.08
		},
		{
			unixtime: 1656906066,
			balance: 99672.08
		},
		{
			unixtime: 1656906126,
			balance: 99672.08
		},
		{
			unixtime: 1656906186,
			balance: 99672.08
		},
		{
			unixtime: 1656906246,
			balance: 99672.08
		},
		{
			unixtime: 1656906306,
			balance: 99672.08
		},
		{
			unixtime: 1656906366,
			balance: 99672.08
		},
		{
			unixtime: 1656906426,
			balance: 99672.08
		},
		{
			unixtime: 1656906486,
			balance: 99672.08
		},
		{
			unixtime: 1656906546,
			balance: 99672.08
		},
		{
			unixtime: 1656906606,
			balance: 99672.08
		},
		{
			unixtime: 1656906666,
			balance: 99672.08
		},
		{
			unixtime: 1656906726,
			balance: 99672.08
		},
		{
			unixtime: 1656906786,
			balance: 99672.08
		},
		{
			unixtime: 1656906846,
			balance: 99672.08
		},
		{
			unixtime: 1656906906,
			balance: 99672.08
		},
		{
			unixtime: 1656906966,
			balance: 99672.08
		},
		{
			unixtime: 1656907026,
			balance: 99672.08
		},
		{
			unixtime: 1656907086,
			balance: 99672.08
		},
		{
			unixtime: 1656907146,
			balance: 99672.08
		},
		{
			unixtime: 1656907206,
			balance: 99672.08
		},
		{
			unixtime: 1656907266,
			balance: 99672.08
		},
		{
			unixtime: 1656907326,
			balance: 99672.08
		},
		{
			unixtime: 1656907386,
			balance: 99672.08
		},
		{
			unixtime: 1656907446,
			balance: 99672.08
		},
		{
			unixtime: 1656907506,
			balance: 99672.08
		},
		{
			unixtime: 1656907566,
			balance: 99672.08
		},
		{
			unixtime: 1656907626,
			balance: 99672.08
		},
		{
			unixtime: 1656907686,
			balance: 99672.08
		},
		{
			unixtime: 1656907746,
			balance: 99672.08
		},
		{
			unixtime: 1656907806,
			balance: 99672.08
		},
		{
			unixtime: 1656907866,
			balance: 99672.08
		},
		{
			unixtime: 1656907926,
			balance: 99672.08
		},
		{
			unixtime: 1656907986,
			balance: 99672.08
		},
		{
			unixtime: 1656908046,
			balance: 99672.08
		},
		{
			unixtime: 1656908106,
			balance: 99672.08
		},
		{
			unixtime: 1656908166,
			balance: 99672.08
		},
		{
			unixtime: 1656908226,
			balance: 99672.08
		},
		{
			unixtime: 1656908286,
			balance: 99672.08
		},
		{
			unixtime: 1656908346,
			balance: 99672.08
		},
		{
			unixtime: 1656908406,
			balance: 99672.08
		},
		{
			unixtime: 1656908466,
			balance: 99672.08
		},
		{
			unixtime: 1656908526,
			balance: 99672.08
		},
		{
			unixtime: 1656908586,
			balance: 99672.08
		},
		{
			unixtime: 1656908646,
			balance: 99672.08
		},
		{
			unixtime: 1656908706,
			balance: 99672.08
		},
		{
			unixtime: 1656908766,
			balance: 99672.08
		},
		{
			unixtime: 1656908826,
			balance: 99672.08
		},
		{
			unixtime: 1656908886,
			balance: 99672.08
		},
		{
			unixtime: 1656908946,
			balance: 99672.08
		},
		{
			unixtime: 1656909006,
			balance: 99672.08
		},
		{
			unixtime: 1656909066,
			balance: 99672.08
		},
		{
			unixtime: 1656909126,
			balance: 99672.08
		},
		{
			unixtime: 1656909186,
			balance: 99672.08
		},
		{
			unixtime: 1656909246,
			balance: 99672.08
		},
		{
			unixtime: 1656909306,
			balance: 99672.08
		},
		{
			unixtime: 1656909367,
			balance: 99672.08
		},
		{
			unixtime: 1656909426,
			balance: 99672.08
		},
		{
			unixtime: 1656909486,
			balance: 99672.08
		},
		{
			unixtime: 1656909546,
			balance: 99672.08
		},
		{
			unixtime: 1656909606,
			balance: 99672.08
		},
		{
			unixtime: 1656909666,
			balance: 99672.08
		},
		{
			unixtime: 1656909726,
			balance: 99672.08
		},
		{
			unixtime: 1656909786,
			balance: 99672.08
		},
		{
			unixtime: 1656909846,
			balance: 99672.08
		},
		{
			unixtime: 1656909906,
			balance: 99672.08
		},
		{
			unixtime: 1656909967,
			balance: 99672.08
		},
		{
			unixtime: 1656910026,
			balance: 99672.08
		},
		{
			unixtime: 1656910086,
			balance: 99672.08
		},
		{
			unixtime: 1656910146,
			balance: 99672.08
		},
		{
			unixtime: 1656910206,
			balance: 99672.08
		},
		{
			unixtime: 1656910266,
			balance: 99672.08
		},
		{
			unixtime: 1656910326,
			balance: 99672.08
		},
		{
			unixtime: 1656910386,
			balance: 99672.08
		},
		{
			unixtime: 1656910447,
			balance: 99672.08
		},
		{
			unixtime: 1656910507,
			balance: 99672.08
		},
		{
			unixtime: 1656910567,
			balance: 99672.08
		},
		{
			unixtime: 1656910627,
			balance: 99672.08
		},
		{
			unixtime: 1656910687,
			balance: 99672.08
		},
		{
			unixtime: 1656910747,
			balance: 99672.08
		},
		{
			unixtime: 1656910807,
			balance: 99672.08
		},
		{
			unixtime: 1656910867,
			balance: 99672.08
		},
		{
			unixtime: 1656910927,
			balance: 99672.08
		},
		{
			unixtime: 1656910987,
			balance: 99672.08
		},
		{
			unixtime: 1656911047,
			balance: 99672.08
		},
		{
			unixtime: 1656911107,
			balance: 99672.08
		},
		{
			unixtime: 1656911167,
			balance: 99672.08
		},
		{
			unixtime: 1656911227,
			balance: 99672.08
		},
		{
			unixtime: 1656911287,
			balance: 99672.08
		},
		{
			unixtime: 1656911347,
			balance: 99672.08
		},
		{
			unixtime: 1656911407,
			balance: 99672.08
		},
		{
			unixtime: 1656911467,
			balance: 99672.08
		},
		{
			unixtime: 1656911527,
			balance: 99672.08
		},
		{
			unixtime: 1656911587,
			balance: 99672.08
		},
		{
			unixtime: 1656911647,
			balance: 99672.08
		},
		{
			unixtime: 1656911707,
			balance: 99672.08
		},
		{
			unixtime: 1656911767,
			balance: 99672.08
		},
		{
			unixtime: 1656911827,
			balance: 99672.08
		},
		{
			unixtime: 1656911887,
			balance: 99672.08
		},
		{
			unixtime: 1656911947,
			balance: 99672.08
		},
		{
			unixtime: 1656912007,
			balance: 99672.08
		},
		{
			unixtime: 1656912067,
			balance: 99672.08
		},
		{
			unixtime: 1656912127,
			balance: 99672.08
		},
		{
			unixtime: 1656912187,
			balance: 99672.08
		},
		{
			unixtime: 1656912247,
			balance: 99672.08
		},
		{
			unixtime: 1656912307,
			balance: 99672.08
		},
		{
			unixtime: 1656912367,
			balance: 99672.08
		},
		{
			unixtime: 1656912427,
			balance: 99672.08
		},
		{
			unixtime: 1656912487,
			balance: 99672.08
		},
		{
			unixtime: 1656912547,
			balance: 99672.08
		},
		{
			unixtime: 1656912607,
			balance: 99672.08
		},
		{
			unixtime: 1656912667,
			balance: 99672.08
		},
		{
			unixtime: 1656912727,
			balance: 99672.08
		},
		{
			unixtime: 1656912787,
			balance: 99672.08
		},
		{
			unixtime: 1656912847,
			balance: 99672.08
		},
		{
			unixtime: 1656912907,
			balance: 99672.08
		},
		{
			unixtime: 1656912967,
			balance: 99672.08
		},
		{
			unixtime: 1656913027,
			balance: 99672.08
		},
		{
			unixtime: 1656913087,
			balance: 99672.08
		},
		{
			unixtime: 1656913147,
			balance: 99672.08
		},
		{
			unixtime: 1656913207,
			balance: 99672.08
		},
		{
			unixtime: 1656913267,
			balance: 99672.08
		},
		{
			unixtime: 1656913327,
			balance: 99672.08
		},
		{
			unixtime: 1656913387,
			balance: 99672.08
		},
		{
			unixtime: 1656913447,
			balance: 99672.08
		},
		{
			unixtime: 1656913507,
			balance: 99672.08
		},
		{
			unixtime: 1656913567,
			balance: 99672.08
		},
		{
			unixtime: 1656913627,
			balance: 99672.08
		},
		{
			unixtime: 1656913687,
			balance: 99672.08
		},
		{
			unixtime: 1656913747,
			balance: 99672.08
		},
		{
			unixtime: 1656913807,
			balance: 99672.08
		},
		{
			unixtime: 1656913867,
			balance: 99672.08
		},
		{
			unixtime: 1656913927,
			balance: 99672.08
		},
		{
			unixtime: 1656913987,
			balance: 99672.08
		},
		{
			unixtime: 1656914047,
			balance: 99672.08
		},
		{
			unixtime: 1656914107,
			balance: 99672.08
		},
		{
			unixtime: 1656914167,
			balance: 99672.08
		},
		{
			unixtime: 1656914227,
			balance: 99672.08
		},
		{
			unixtime: 1656914287,
			balance: 99672.08
		},
		{
			unixtime: 1656914347,
			balance: 99672.08
		},
		{
			unixtime: 1656914407,
			balance: 99672.08
		},
		{
			unixtime: 1656914467,
			balance: 99672.08
		},
		{
			unixtime: 1656914527,
			balance: 99672.08
		},
		{
			unixtime: 1656914587,
			balance: 99672.08
		},
		{
			unixtime: 1656914647,
			balance: 99672.08
		},
		{
			unixtime: 1656914707,
			balance: 99672.08
		},
		{
			unixtime: 1656914767,
			balance: 99672.08
		},
		{
			unixtime: 1656914827,
			balance: 99672.08
		},
		{
			unixtime: 1656914887,
			balance: 99672.08
		},
		{
			unixtime: 1656914947,
			balance: 99672.08
		},
		{
			unixtime: 1656915007,
			balance: 99672.08
		},
		{
			unixtime: 1656915067,
			balance: 99672.08
		},
		{
			unixtime: 1656915127,
			balance: 99672.08
		},
		{
			unixtime: 1656915187,
			balance: 99672.08
		},
		{
			unixtime: 1656915247,
			balance: 99672.08
		},
		{
			unixtime: 1656915307,
			balance: 99672.08
		},
		{
			unixtime: 1656915367,
			balance: 99672.08
		},
		{
			unixtime: 1656915427,
			balance: 99672.08
		},
		{
			unixtime: 1656915487,
			balance: 99672.08
		},
		{
			unixtime: 1656915547,
			balance: 99672.08
		},
		{
			unixtime: 1656915607,
			balance: 99672.08
		},
		{
			unixtime: 1656915667,
			balance: 99672.08
		},
		{
			unixtime: 1656915727,
			balance: 99672.08
		},
		{
			unixtime: 1656915787,
			balance: 99672.08
		},
		{
			unixtime: 1656915847,
			balance: 99672.08
		},
		{
			unixtime: 1656915907,
			balance: 99672.08
		},
		{
			unixtime: 1656915967,
			balance: 99672.08
		},
		{
			unixtime: 1656916027,
			balance: 99672.08
		},
		{
			unixtime: 1656916087,
			balance: 99672.08
		},
		{
			unixtime: 1656916147,
			balance: 99672.08
		},
		{
			unixtime: 1656916207,
			balance: 99672.08
		},
		{
			unixtime: 1656916267,
			balance: 99672.08
		},
		{
			unixtime: 1656916327,
			balance: 99672.08
		},
		{
			unixtime: 1656916387,
			balance: 99672.08
		},
		{
			unixtime: 1656916447,
			balance: 99672.08
		},
		{
			unixtime: 1656916507,
			balance: 99672.08
		},
		{
			unixtime: 1656916567,
			balance: 99672.08
		},
		{
			unixtime: 1656916627,
			balance: 99672.08
		},
		{
			unixtime: 1656916687,
			balance: 99672.08
		},
		{
			unixtime: 1656916747,
			balance: 99672.08
		},
		{
			unixtime: 1656916807,
			balance: 99672.08
		},
		{
			unixtime: 1656916867,
			balance: 99672.08
		},
		{
			unixtime: 1656916927,
			balance: 99672.08
		},
		{
			unixtime: 1656916987,
			balance: 99672.08
		},
		{
			unixtime: 1656917047,
			balance: 99672.08
		},
		{
			unixtime: 1656917107,
			balance: 99672.08
		},
		{
			unixtime: 1656917167,
			balance: 99672.08
		},
		{
			unixtime: 1656917227,
			balance: 99672.08
		},
		{
			unixtime: 1656917287,
			balance: 99672.08
		},
		{
			unixtime: 1656917347,
			balance: 99672.08
		},
		{
			unixtime: 1656917407,
			balance: 99672.08
		},
		{
			unixtime: 1656917467,
			balance: 99672.08
		},
		{
			unixtime: 1656917527,
			balance: 99672.08
		},
		{
			unixtime: 1656917587,
			balance: 99672.08
		},
		{
			unixtime: 1656917647,
			balance: 99672.08
		},
		{
			unixtime: 1656917707,
			balance: 99672.08
		},
		{
			unixtime: 1656917767,
			balance: 99672.08
		},
		{
			unixtime: 1656917827,
			balance: 99672.08
		},
		{
			unixtime: 1656917887,
			balance: 99672.08
		},
		{
			unixtime: 1656917947,
			balance: 99672.08
		},
		{
			unixtime: 1656918007,
			balance: 99672.08
		},
		{
			unixtime: 1656918067,
			balance: 99672.08
		},
		{
			unixtime: 1656918127,
			balance: 99672.08
		},
		{
			unixtime: 1656918187,
			balance: 99672.08
		},
		{
			unixtime: 1656918247,
			balance: 99672.08
		},
		{
			unixtime: 1656918307,
			balance: 99672.08
		},
		{
			unixtime: 1656918367,
			balance: 99672.08
		},
		{
			unixtime: 1656918427,
			balance: 99672.08
		},
		{
			unixtime: 1656918487,
			balance: 99672.08
		},
		{
			unixtime: 1656918547,
			balance: 99672.08
		},
		{
			unixtime: 1656918607,
			balance: 99672.08
		},
		{
			unixtime: 1656918667,
			balance: 99672.08
		},
		{
			unixtime: 1656918727,
			balance: 99672.08
		},
		{
			unixtime: 1656918787,
			balance: 99672.08
		},
		{
			unixtime: 1656918847,
			balance: 99672.08
		},
		{
			unixtime: 1656918907,
			balance: 99672.08
		},
		{
			unixtime: 1656918967,
			balance: 99672.08
		},
		{
			unixtime: 1656919027,
			balance: 99672.08
		},
		{
			unixtime: 1656919087,
			balance: 99672.08
		},
		{
			unixtime: 1656919147,
			balance: 99672.08
		},
		{
			unixtime: 1656919207,
			balance: 99672.08
		},
		{
			unixtime: 1656919267,
			balance: 99672.08
		},
		{
			unixtime: 1656919327,
			balance: 99672.08
		},
		{
			unixtime: 1656919387,
			balance: 99672.08
		},
		{
			unixtime: 1656919447,
			balance: 99672.08
		},
		{
			unixtime: 1656919507,
			balance: 99672.08
		},
		{
			unixtime: 1656919567,
			balance: 99672.08
		},
		{
			unixtime: 1656919627,
			balance: 99672.08
		},
		{
			unixtime: 1656919687,
			balance: 99672.08
		},
		{
			unixtime: 1656919747,
			balance: 99672.08
		},
		{
			unixtime: 1656919807,
			balance: 99672.08
		},
		{
			unixtime: 1656919867,
			balance: 99672.08
		},
		{
			unixtime: 1656919927,
			balance: 99672.08
		},
		{
			unixtime: 1656919987,
			balance: 99672.08
		},
		{
			unixtime: 1656920047,
			balance: 99672.08
		},
		{
			unixtime: 1656920107,
			balance: 99672.08
		},
		{
			unixtime: 1656920167,
			balance: 99672.08
		},
		{
			unixtime: 1656920227,
			balance: 99672.08
		},
		{
			unixtime: 1656920287,
			balance: 99672.08
		},
		{
			unixtime: 1656920347,
			balance: 99672.08
		},
		{
			unixtime: 1656920407,
			balance: 99672.08
		},
		{
			unixtime: 1656920467,
			balance: 99672.08
		},
		{
			unixtime: 1656920527,
			balance: 99672.08
		},
		{
			unixtime: 1656920587,
			balance: 99672.08
		},
		{
			unixtime: 1656920647,
			balance: 99672.08
		},
		{
			unixtime: 1656920707,
			balance: 99672.08
		},
		{
			unixtime: 1656920767,
			balance: 99672.08
		},
		{
			unixtime: 1656920827,
			balance: 99672.08
		},
		{
			unixtime: 1656920887,
			balance: 99672.08
		},
		{
			unixtime: 1656920947,
			balance: 99672.08
		},
		{
			unixtime: 1656921007,
			balance: 99672.08
		},
		{
			unixtime: 1656921067,
			balance: 99672.08
		},
		{
			unixtime: 1656921127,
			balance: 99672.08
		},
		{
			unixtime: 1656921187,
			balance: 99672.08
		},
		{
			unixtime: 1656921247,
			balance: 99672.08
		},
		{
			unixtime: 1656921307,
			balance: 99672.08
		},
		{
			unixtime: 1656921367,
			balance: 99672.08
		},
		{
			unixtime: 1656921427,
			balance: 99672.08
		},
		{
			unixtime: 1656921487,
			balance: 99672.08
		},
		{
			unixtime: 1656921547,
			balance: 99672.08
		},
		{
			unixtime: 1656921607,
			balance: 99672.08
		},
		{
			unixtime: 1656921667,
			balance: 99672.08
		},
		{
			unixtime: 1656921727,
			balance: 99672.08
		},
		{
			unixtime: 1656921787,
			balance: 99672.08
		},
		{
			unixtime: 1656921847,
			balance: 99672.08
		},
		{
			unixtime: 1656921907,
			balance: 99672.08
		},
		{
			unixtime: 1656921967,
			balance: 99672.08
		},
		{
			unixtime: 1656922027,
			balance: 99672.08
		},
		{
			unixtime: 1656922087,
			balance: 99672.08
		},
		{
			unixtime: 1656922147,
			balance: 99672.08
		},
		{
			unixtime: 1656922207,
			balance: 99672.08
		},
		{
			unixtime: 1656922267,
			balance: 99672.08
		},
		{
			unixtime: 1656922327,
			balance: 99672.08
		},
		{
			unixtime: 1656922387,
			balance: 99672.08
		},
		{
			unixtime: 1656922447,
			balance: 99672.08
		},
		{
			unixtime: 1656922507,
			balance: 99672.08
		},
		{
			unixtime: 1656922567,
			balance: 99672.08
		},
		{
			unixtime: 1656922627,
			balance: 99672.08
		},
		{
			unixtime: 1656922687,
			balance: 99672.08
		},
		{
			unixtime: 1656922747,
			balance: 99672.08
		},
		{
			unixtime: 1656922807,
			balance: 99672.08
		},
		{
			unixtime: 1656922867,
			balance: 99672.08
		},
		{
			unixtime: 1656922927,
			balance: 99672.08
		},
		{
			unixtime: 1656922987,
			balance: 99672.08
		},
		{
			unixtime: 1656923047,
			balance: 99672.08
		},
		{
			unixtime: 1656923107,
			balance: 99672.08
		},
		{
			unixtime: 1656923167,
			balance: 99672.08
		},
		{
			unixtime: 1656923227,
			balance: 99672.08
		},
		{
			unixtime: 1656923287,
			balance: 99672.08
		},
		{
			unixtime: 1656923347,
			balance: 99672.08
		},
		{
			unixtime: 1656923407,
			balance: 99672.08
		},
		{
			unixtime: 1656923467,
			balance: 99672.08
		},
		{
			unixtime: 1656923527,
			balance: 99672.08
		},
		{
			unixtime: 1656923587,
			balance: 99672.08
		},
		{
			unixtime: 1656923647,
			balance: 99672.08
		},
		{
			unixtime: 1656923707,
			balance: 99672.08
		},
		{
			unixtime: 1656923767,
			balance: 99672.08
		},
		{
			unixtime: 1656923827,
			balance: 99672.08
		},
		{
			unixtime: 1656923887,
			balance: 99672.08
		},
		{
			unixtime: 1656923947,
			balance: 99672.08
		},
		{
			unixtime: 1656924007,
			balance: 99672.08
		},
		{
			unixtime: 1656924067,
			balance: 99672.08
		},
		{
			unixtime: 1656924127,
			balance: 99672.08
		},
		{
			unixtime: 1656924187,
			balance: 99672.08
		},
		{
			unixtime: 1656924247,
			balance: 99672.08
		},
		{
			unixtime: 1656924307,
			balance: 99672.08
		},
		{
			unixtime: 1656924367,
			balance: 99672.08
		},
		{
			unixtime: 1656924427,
			balance: 99672.08
		},
		{
			unixtime: 1656924487,
			balance: 99672.08
		},
		{
			unixtime: 1656924547,
			balance: 99672.08
		},
		{
			unixtime: 1656924607,
			balance: 99672.08
		},
		{
			unixtime: 1656924667,
			balance: 99672.08
		},
		{
			unixtime: 1656924727,
			balance: 99672.08
		},
		{
			unixtime: 1656924787,
			balance: 99672.08
		},
		{
			unixtime: 1656924847,
			balance: 99672.08
		},
		{
			unixtime: 1656924907,
			balance: 99672.08
		},
		{
			unixtime: 1656924967,
			balance: 99672.08
		},
		{
			unixtime: 1656925027,
			balance: 99672.08
		},
		{
			unixtime: 1656925087,
			balance: 99672.08
		},
		{
			unixtime: 1656925147,
			balance: 99672.08
		},
		{
			unixtime: 1656925207,
			balance: 99672.08
		},
		{
			unixtime: 1656925267,
			balance: 99672.08
		},
		{
			unixtime: 1656925327,
			balance: 99672.08
		},
		{
			unixtime: 1656925387,
			balance: 99672.08
		},
		{
			unixtime: 1656925447,
			balance: 99672.08
		},
		{
			unixtime: 1656925507,
			balance: 99672.08
		},
		{
			unixtime: 1656925567,
			balance: 99672.08
		},
		{
			unixtime: 1656925627,
			balance: 99672.08
		},
		{
			unixtime: 1656925687,
			balance: 99672.08
		},
		{
			unixtime: 1656925747,
			balance: 99672.08
		},
		{
			unixtime: 1656925807,
			balance: 99672.08
		},
		{
			unixtime: 1656925867,
			balance: 99672.08
		},
		{
			unixtime: 1656925927,
			balance: 99672.08
		},
		{
			unixtime: 1656925987,
			balance: 99672.08
		},
		{
			unixtime: 1656926047,
			balance: 99672.08
		},
		{
			unixtime: 1656926107,
			balance: 99672.08
		},
		{
			unixtime: 1656926167,
			balance: 99672.08
		},
		{
			unixtime: 1656926227,
			balance: 99672.08
		},
		{
			unixtime: 1656926287,
			balance: 99672.08
		},
		{
			unixtime: 1656926347,
			balance: 99672.08
		},
		{
			unixtime: 1656926407,
			balance: 99672.08
		},
		{
			unixtime: 1656926467,
			balance: 99672.08
		},
		{
			unixtime: 1656926527,
			balance: 99672.08
		},
		{
			unixtime: 1656926587,
			balance: 99672.08
		},
		{
			unixtime: 1656926647,
			balance: 99672.08
		},
		{
			unixtime: 1656926707,
			balance: 99672.08
		},
		{
			unixtime: 1656926767,
			balance: 99672.08
		},
		{
			unixtime: 1656926827,
			balance: 99672.08
		},
		{
			unixtime: 1656926887,
			balance: 99672.08
		},
		{
			unixtime: 1656926947,
			balance: 99672.08
		},
		{
			unixtime: 1656927007,
			balance: 99672.08
		},
		{
			unixtime: 1656927067,
			balance: 99672.08
		},
		{
			unixtime: 1656927127,
			balance: 99672.08
		},
		{
			unixtime: 1656927187,
			balance: 99672.08
		},
		{
			unixtime: 1656927247,
			balance: 99672.08
		},
		{
			unixtime: 1656927307,
			balance: 99672.08
		},
		{
			unixtime: 1656927367,
			balance: 99672.08
		},
		{
			unixtime: 1656927427,
			balance: 99672.08
		},
		{
			unixtime: 1656927487,
			balance: 99672.08
		},
		{
			unixtime: 1656927547,
			balance: 99672.08
		},
		{
			unixtime: 1656927607,
			balance: 99672.08
		},
		{
			unixtime: 1656927667,
			balance: 99672.08
		},
		{
			unixtime: 1656927727,
			balance: 99672.08
		},
		{
			unixtime: 1656927787,
			balance: 99672.08
		},
		{
			unixtime: 1656927847,
			balance: 99672.08
		},
		{
			unixtime: 1656927907,
			balance: 99672.08
		},
		{
			unixtime: 1656927967,
			balance: 99672.08
		},
		{
			unixtime: 1656928027,
			balance: 99672.08
		},
		{
			unixtime: 1656928087,
			balance: 99672.08
		},
		{
			unixtime: 1656928147,
			balance: 99672.08
		},
		{
			unixtime: 1656928207,
			balance: 99672.08
		},
		{
			unixtime: 1656928267,
			balance: 99672.08
		},
		{
			unixtime: 1656928327,
			balance: 99672.08
		},
		{
			unixtime: 1656928387,
			balance: 99672.08
		},
		{
			unixtime: 1656928447,
			balance: 99672.08
		},
		{
			unixtime: 1656928507,
			balance: 99672.08
		},
		{
			unixtime: 1656928567,
			balance: 99672.08
		},
		{
			unixtime: 1656928627,
			balance: 99672.08
		},
		{
			unixtime: 1656928687,
			balance: 99672.08
		},
		{
			unixtime: 1656928747,
			balance: 99672.08
		},
		{
			unixtime: 1656928807,
			balance: 99672.08
		},
		{
			unixtime: 1656928867,
			balance: 99672.08
		},
		{
			unixtime: 1656928927,
			balance: 99672.08
		},
		{
			unixtime: 1656928987,
			balance: 99672.08
		},
		{
			unixtime: 1656929047,
			balance: 99672.08
		},
		{
			unixtime: 1656929107,
			balance: 99672.08
		},
		{
			unixtime: 1656929167,
			balance: 99672.08
		},
		{
			unixtime: 1656929227,
			balance: 99672.08
		},
		{
			unixtime: 1656929287,
			balance: 99672.08
		},
		{
			unixtime: 1656929347,
			balance: 99672.08
		},
		{
			unixtime: 1656929407,
			balance: 99672.08
		},
		{
			unixtime: 1656929467,
			balance: 99672.08
		},
		{
			unixtime: 1656929527,
			balance: 99672.08
		},
		{
			unixtime: 1656929587,
			balance: 99672.08
		},
		{
			unixtime: 1656929647,
			balance: 99672.08
		},
		{
			unixtime: 1656929707,
			balance: 99672.08
		},
		{
			unixtime: 1656929767,
			balance: 99672.08
		},
		{
			unixtime: 1656929827,
			balance: 99672.08
		},
		{
			unixtime: 1656929887,
			balance: 99672.08
		},
		{
			unixtime: 1656929947,
			balance: 99672.08
		},
		{
			unixtime: 1656930007,
			balance: 99672.08
		},
		{
			unixtime: 1656930067,
			balance: 99672.08
		},
		{
			unixtime: 1656930127,
			balance: 99672.08
		},
		{
			unixtime: 1656930187,
			balance: 99672.08
		},
		{
			unixtime: 1656930247,
			balance: 99672.08
		},
		{
			unixtime: 1656930307,
			balance: 99672.08
		},
		{
			unixtime: 1656930367,
			balance: 99672.08
		},
		{
			unixtime: 1656930427,
			balance: 99672.08
		},
		{
			unixtime: 1656930487,
			balance: 99672.08
		},
		{
			unixtime: 1656930547,
			balance: 99672.08
		},
		{
			unixtime: 1656930607,
			balance: 99672.08
		},
		{
			unixtime: 1656930667,
			balance: 99672.08
		},
		{
			unixtime: 1656930727,
			balance: 99672.08
		},
		{
			unixtime: 1656930787,
			balance: 99672.08
		},
		{
			unixtime: 1656930847,
			balance: 99672.08
		},
		{
			unixtime: 1656930907,
			balance: 99672.08
		},
		{
			unixtime: 1656930967,
			balance: 99672.08
		},
		{
			unixtime: 1656931027,
			balance: 99672.08
		},
		{
			unixtime: 1656931087,
			balance: 99672.08
		},
		{
			unixtime: 1656931147,
			balance: 99672.08
		},
		{
			unixtime: 1656931207,
			balance: 99672.08
		},
		{
			unixtime: 1656931267,
			balance: 99672.08
		},
		{
			unixtime: 1656931327,
			balance: 99672.08
		},
		{
			unixtime: 1656931387,
			balance: 99672.08
		},
		{
			unixtime: 1656931447,
			balance: 99672.08
		},
		{
			unixtime: 1656931507,
			balance: 99672.08
		},
		{
			unixtime: 1656931567,
			balance: 99672.08
		},
		{
			unixtime: 1656931627,
			balance: 99672.08
		},
		{
			unixtime: 1656931687,
			balance: 99672.08
		},
		{
			unixtime: 1656931747,
			balance: 99672.08
		},
		{
			unixtime: 1656931807,
			balance: 99672.08
		},
		{
			unixtime: 1656931867,
			balance: 99672.08
		},
		{
			unixtime: 1656931927,
			balance: 99672.08
		},
		{
			unixtime: 1656931987,
			balance: 99672.08
		},
		{
			unixtime: 1656932047,
			balance: 99672.08
		},
		{
			unixtime: 1656932107,
			balance: 99672.08
		},
		{
			unixtime: 1656932167,
			balance: 99672.08
		},
		{
			unixtime: 1656932227,
			balance: 99672.08
		},
		{
			unixtime: 1656932287,
			balance: 99672.08
		},
		{
			unixtime: 1656932347,
			balance: 99672.08
		},
		{
			unixtime: 1656932407,
			balance: 99672.08
		},
		{
			unixtime: 1656932467,
			balance: 99672.08
		},
		{
			unixtime: 1656932527,
			balance: 99672.08
		},
		{
			unixtime: 1656932587,
			balance: 99672.08
		},
		{
			unixtime: 1656932647,
			balance: 99672.08
		},
		{
			unixtime: 1656932707,
			balance: 99672.08
		},
		{
			unixtime: 1656932767,
			balance: 99672.08
		},
		{
			unixtime: 1656932827,
			balance: 99672.08
		},
		{
			unixtime: 1656932887,
			balance: 99672.08
		},
		{
			unixtime: 1656932947,
			balance: 99672.08
		},
		{
			unixtime: 1656933007,
			balance: 99672.08
		},
		{
			unixtime: 1656933068,
			balance: 99672.08
		},
		{
			unixtime: 1656933127,
			balance: 99672.08
		},
		{
			unixtime: 1656933187,
			balance: 99672.08
		},
		{
			unixtime: 1656933247,
			balance: 99672.08
		},
		{
			unixtime: 1656933307,
			balance: 99672.08
		},
		{
			unixtime: 1656933367,
			balance: 99672.08
		},
		{
			unixtime: 1656933427,
			balance: 99672.08
		},
		{
			unixtime: 1656933487,
			balance: 99672.08
		},
		{
			unixtime: 1656933547,
			balance: 99672.08
		},
		{
			unixtime: 1656933607,
			balance: 99672.08
		},
		{
			unixtime: 1656933667,
			balance: 99672.08
		},
		{
			unixtime: 1656933727,
			balance: 99672.08
		},
		{
			unixtime: 1656933787,
			balance: 99672.08
		},
		{
			unixtime: 1656933847,
			balance: 99672.08
		},
		{
			unixtime: 1656933907,
			balance: 99672.08
		},
		{
			unixtime: 1656933967,
			balance: 99672.08
		},
		{
			unixtime: 1656934027,
			balance: 99672.08
		},
		{
			unixtime: 1656934087,
			balance: 99672.08
		},
		{
			unixtime: 1656934147,
			balance: 99672.08
		},
		{
			unixtime: 1656934207,
			balance: 99672.08
		},
		{
			unixtime: 1656934267,
			balance: 99672.08
		},
		{
			unixtime: 1656934327,
			balance: 99672.08
		},
		{
			unixtime: 1656934387,
			balance: 99672.08
		},
		{
			unixtime: 1656934448,
			balance: 99672.08
		},
		{
			unixtime: 1656934507,
			balance: 99672.08
		},
		{
			unixtime: 1656934567,
			balance: 99672.08
		},
		{
			unixtime: 1656934627,
			balance: 99672.08
		},
		{
			unixtime: 1656934687,
			balance: 99672.08
		},
		{
			unixtime: 1656934748,
			balance: 99672.08
		},
		{
			unixtime: 1656934807,
			balance: 99672.08
		},
		{
			unixtime: 1656934867,
			balance: 99672.08
		},
		{
			unixtime: 1656934927,
			balance: 99672.08
		},
		{
			unixtime: 1656934987,
			balance: 99672.08
		},
		{
			unixtime: 1656935047,
			balance: 99672.08
		},
		{
			unixtime: 1656935107,
			balance: 99672.08
		},
		{
			unixtime: 1656935167,
			balance: 99672.08
		},
		{
			unixtime: 1656935227,
			balance: 99672.08
		},
		{
			unixtime: 1656935288,
			balance: 99672.08
		},
		{
			unixtime: 1656935348,
			balance: 99672.08
		},
		{
			unixtime: 1656935407,
			balance: 99672.08
		},
		{
			unixtime: 1656935467,
			balance: 99672.08
		},
		{
			unixtime: 1656935527,
			balance: 99672.08
		},
		{
			unixtime: 1656935588,
			balance: 99672.08
		},
		{
			unixtime: 1656935647,
			balance: 99672.08
		},
		{
			unixtime: 1656935708,
			balance: 99672.08
		},
		{
			unixtime: 1656935768,
			balance: 99672.08
		},
		{
			unixtime: 1656935828,
			balance: 99672.08
		},
		{
			unixtime: 1656935888,
			balance: 99672.08
		},
		{
			unixtime: 1656935948,
			balance: 99672.08
		},
		{
			unixtime: 1656936008,
			balance: 99672.08
		},
		{
			unixtime: 1656936068,
			balance: 99672.08
		},
		{
			unixtime: 1656936127,
			balance: 99672.08
		},
		{
			unixtime: 1656936188,
			balance: 99672.08
		},
		{
			unixtime: 1656936248,
			balance: 99672.08
		},
		{
			unixtime: 1656936308,
			balance: 99672.08
		},
		{
			unixtime: 1656936368,
			balance: 99672.08
		},
		{
			unixtime: 1656936428,
			balance: 99672.08
		},
		{
			unixtime: 1656936488,
			balance: 99672.08
		},
		{
			unixtime: 1656936548,
			balance: 99672.08
		},
		{
			unixtime: 1656936608,
			balance: 99672.08
		},
		{
			unixtime: 1656936668,
			balance: 99672.08
		},
		{
			unixtime: 1656936728,
			balance: 99672.08
		},
		{
			unixtime: 1656936788,
			balance: 99672.08
		},
		{
			unixtime: 1656936848,
			balance: 99672.08
		},
		{
			unixtime: 1656936908,
			balance: 99672.08
		},
		{
			unixtime: 1656936968,
			balance: 99672.08
		},
		{
			unixtime: 1656937028,
			balance: 99672.08
		},
		{
			unixtime: 1656937088,
			balance: 99672.08
		},
		{
			unixtime: 1656937148,
			balance: 99672.08
		},
		{
			unixtime: 1656937208,
			balance: 99672.08
		},
		{
			unixtime: 1656937268,
			balance: 99672.08
		},
		{
			unixtime: 1656937328,
			balance: 99672.08
		},
		{
			unixtime: 1656937388,
			balance: 99672.08
		},
		{
			unixtime: 1656937448,
			balance: 99672.08
		},
		{
			unixtime: 1656937508,
			balance: 99672.08
		},
		{
			unixtime: 1656937568,
			balance: 99672.08
		},
		{
			unixtime: 1656937628,
			balance: 99672.08
		},
		{
			unixtime: 1656937688,
			balance: 99672.08
		},
		{
			unixtime: 1656937748,
			balance: 99672.08
		},
		{
			unixtime: 1656937808,
			balance: 99672.08
		},
		{
			unixtime: 1656937868,
			balance: 99672.08
		},
		{
			unixtime: 1656937928,
			balance: 99672.08
		},
		{
			unixtime: 1656937988,
			balance: 99672.08
		},
		{
			unixtime: 1656938048,
			balance: 99672.08
		},
		{
			unixtime: 1656938108,
			balance: 99672.08
		},
		{
			unixtime: 1656938168,
			balance: 99672.08
		},
		{
			unixtime: 1656938228,
			balance: 99672.08
		},
		{
			unixtime: 1656938288,
			balance: 99672.08
		},
		{
			unixtime: 1656938348,
			balance: 99672.08
		},
		{
			unixtime: 1656938408,
			balance: 99672.08
		},
		{
			unixtime: 1656938468,
			balance: 99672.08
		},
		{
			unixtime: 1656938528,
			balance: 99672.08
		},
		{
			unixtime: 1656938588,
			balance: 99672.08
		},
		{
			unixtime: 1656938648,
			balance: 99672.08
		},
		{
			unixtime: 1656938708,
			balance: 99672.08
		},
		{
			unixtime: 1656938768,
			balance: 99672.08
		},
		{
			unixtime: 1656938828,
			balance: 99672.08
		},
		{
			unixtime: 1656938888,
			balance: 99672.08
		},
		{
			unixtime: 1656938948,
			balance: 99672.08
		},
		{
			unixtime: 1656939008,
			balance: 99672.08
		},
		{
			unixtime: 1656939068,
			balance: 99672.08
		},
		{
			unixtime: 1656939128,
			balance: 99672.08
		},
		{
			unixtime: 1656939188,
			balance: 99672.08
		},
		{
			unixtime: 1656939248,
			balance: 99672.08
		},
		{
			unixtime: 1656939308,
			balance: 99672.08
		},
		{
			unixtime: 1656939368,
			balance: 99672.08
		},
		{
			unixtime: 1656939428,
			balance: 99672.08
		},
		{
			unixtime: 1656939488,
			balance: 99672.08
		},
		{
			unixtime: 1656939548,
			balance: 99672.08
		},
		{
			unixtime: 1656939608,
			balance: 99672.08
		},
		{
			unixtime: 1656939668,
			balance: 99672.08
		},
		{
			unixtime: 1656939728,
			balance: 99672.08
		},
		{
			unixtime: 1656939788,
			balance: 99672.08
		},
		{
			unixtime: 1656939848,
			balance: 99672.08
		},
		{
			unixtime: 1656939908,
			balance: 99672.08
		},
		{
			unixtime: 1656939968,
			balance: 99672.08
		},
		{
			unixtime: 1656940028,
			balance: 99672.08
		},
		{
			unixtime: 1656940088,
			balance: 99672.08
		},
		{
			unixtime: 1656940148,
			balance: 99672.08
		},
		{
			unixtime: 1656940208,
			balance: 99672.08
		},
		{
			unixtime: 1656940268,
			balance: 99672.08
		},
		{
			unixtime: 1656940328,
			balance: 99672.08
		},
		{
			unixtime: 1656940388,
			balance: 99672.08
		},
		{
			unixtime: 1656940448,
			balance: 99672.08
		},
		{
			unixtime: 1656940508,
			balance: 99672.08
		},
		{
			unixtime: 1656940568,
			balance: 99672.08
		},
		{
			unixtime: 1656940628,
			balance: 99672.08
		},
		{
			unixtime: 1656940688,
			balance: 99672.08
		},
		{
			unixtime: 1656940748,
			balance: 99672.08
		},
		{
			unixtime: 1656940808,
			balance: 99672.08
		},
		{
			unixtime: 1656940868,
			balance: 99672.08
		},
		{
			unixtime: 1656940928,
			balance: 99672.08
		},
		{
			unixtime: 1656940988,
			balance: 99672.08
		},
		{
			unixtime: 1656941048,
			balance: 99672.08
		},
		{
			unixtime: 1656941108,
			balance: 99672.08
		},
		{
			unixtime: 1656941168,
			balance: 99672.08
		},
		{
			unixtime: 1656941228,
			balance: 99672.08
		},
		{
			unixtime: 1656941288,
			balance: 99672.08
		},
		{
			unixtime: 1656941348,
			balance: 99672.08
		},
		{
			unixtime: 1656941408,
			balance: 99672.08
		},
		{
			unixtime: 1656941468,
			balance: 99672.08
		},
		{
			unixtime: 1656941528,
			balance: 99672.08
		},
		{
			unixtime: 1656941588,
			balance: 99672.08
		},
		{
			unixtime: 1656941648,
			balance: 99672.08
		},
		{
			unixtime: 1656941708,
			balance: 99672.08
		},
		{
			unixtime: 1656941768,
			balance: 99672.08
		},
		{
			unixtime: 1656941828,
			balance: 99672.08
		},
		{
			unixtime: 1656941888,
			balance: 99672.08
		},
		{
			unixtime: 1656941948,
			balance: 99672.08
		},
		{
			unixtime: 1656942008,
			balance: 99672.08
		},
		{
			unixtime: 1656942068,
			balance: 99672.08
		},
		{
			unixtime: 1656942128,
			balance: 99672.08
		},
		{
			unixtime: 1656942188,
			balance: 99672.08
		},
		{
			unixtime: 1656942248,
			balance: 99672.08
		},
		{
			unixtime: 1656942308,
			balance: 99672.08
		},
		{
			unixtime: 1656942368,
			balance: 99672.08
		},
		{
			unixtime: 1656942428,
			balance: 99672.08
		},
		{
			unixtime: 1656942488,
			balance: 99672.08
		},
		{
			unixtime: 1656942548,
			balance: 99672.08
		},
		{
			unixtime: 1656942608,
			balance: 99672.08
		},
		{
			unixtime: 1656942668,
			balance: 99672.08
		},
		{
			unixtime: 1656942728,
			balance: 99672.08
		},
		{
			unixtime: 1656942788,
			balance: 99672.08
		},
		{
			unixtime: 1656942848,
			balance: 99672.08
		},
		{
			unixtime: 1656942908,
			balance: 99672.08
		},
		{
			unixtime: 1656942968,
			balance: 99672.08
		},
		{
			unixtime: 1656943028,
			balance: 99672.08
		},
		{
			unixtime: 1656943088,
			balance: 99672.08
		},
		{
			unixtime: 1656943148,
			balance: 99672.08
		},
		{
			unixtime: 1656943208,
			balance: 99672.08
		},
		{
			unixtime: 1656943268,
			balance: 99672.08
		},
		{
			unixtime: 1656943328,
			balance: 99672.08
		},
		{
			unixtime: 1656943388,
			balance: 99672.08
		},
		{
			unixtime: 1656943448,
			balance: 99672.08
		},
		{
			unixtime: 1656943508,
			balance: 99672.08
		},
		{
			unixtime: 1656943568,
			balance: 99672.08
		},
		{
			unixtime: 1656943628,
			balance: 99672.08
		},
		{
			unixtime: 1656943688,
			balance: 99672.08
		},
		{
			unixtime: 1656943748,
			balance: 99672.08
		},
		{
			unixtime: 1656943808,
			balance: 99672.08
		},
		{
			unixtime: 1656943868,
			balance: 99672.08
		},
		{
			unixtime: 1656943928,
			balance: 99672.08
		},
		{
			unixtime: 1656943988,
			balance: 99672.08
		},
		{
			unixtime: 1656944048,
			balance: 99672.08
		},
		{
			unixtime: 1656944108,
			balance: 99672.08
		},
		{
			unixtime: 1656944168,
			balance: 99672.08
		},
		{
			unixtime: 1656944228,
			balance: 99672.08
		},
		{
			unixtime: 1656944288,
			balance: 99672.08
		},
		{
			unixtime: 1656944348,
			balance: 99672.08
		},
		{
			unixtime: 1656944408,
			balance: 99672.08
		},
		{
			unixtime: 1656944468,
			balance: 99672.08
		},
		{
			unixtime: 1656944528,
			balance: 99672.08
		},
		{
			unixtime: 1656944588,
			balance: 99672.08
		},
		{
			unixtime: 1656944648,
			balance: 99672.08
		},
		{
			unixtime: 1656944708,
			balance: 99672.08
		},
		{
			unixtime: 1656944768,
			balance: 99672.08
		},
		{
			unixtime: 1656944828,
			balance: 99672.08
		},
		{
			unixtime: 1656944888,
			balance: 99672.08
		},
		{
			unixtime: 1656944948,
			balance: 99672.08
		},
		{
			unixtime: 1656945008,
			balance: 99672.08
		},
		{
			unixtime: 1656945068,
			balance: 99672.08
		},
		{
			unixtime: 1656945128,
			balance: 99672.08
		},
		{
			unixtime: 1656945188,
			balance: 99672.08
		},
		{
			unixtime: 1656945248,
			balance: 99672.08
		},
		{
			unixtime: 1656945308,
			balance: 99672.08
		},
		{
			unixtime: 1656945368,
			balance: 99672.08
		},
		{
			unixtime: 1656945428,
			balance: 99672.08
		},
		{
			unixtime: 1656945488,
			balance: 99672.08
		},
		{
			unixtime: 1656945548,
			balance: 99672.08
		},
		{
			unixtime: 1656945608,
			balance: 99672.08
		},
		{
			unixtime: 1656945668,
			balance: 99672.08
		},
		{
			unixtime: 1656945728,
			balance: 99672.08
		},
		{
			unixtime: 1656945788,
			balance: 99672.08
		},
		{
			unixtime: 1656945848,
			balance: 99672.08
		},
		{
			unixtime: 1656945908,
			balance: 99672.08
		},
		{
			unixtime: 1656945968,
			balance: 99672.08
		},
		{
			unixtime: 1656946028,
			balance: 99672.08
		},
		{
			unixtime: 1656946088,
			balance: 99672.08
		},
		{
			unixtime: 1656946148,
			balance: 99672.08
		},
		{
			unixtime: 1656946208,
			balance: 99672.08
		},
		{
			unixtime: 1656946268,
			balance: 99672.08
		},
		{
			unixtime: 1656946328,
			balance: 99672.08
		},
		{
			unixtime: 1656946388,
			balance: 99672.08
		},
		{
			unixtime: 1656946448,
			balance: 99672.08
		},
		{
			unixtime: 1656946508,
			balance: 99672.08
		},
		{
			unixtime: 1656946568,
			balance: 99672.08
		},
		{
			unixtime: 1656946628,
			balance: 99672.08
		},
		{
			unixtime: 1656946688,
			balance: 99672.08
		},
		{
			unixtime: 1656946748,
			balance: 99672.08
		},
		{
			unixtime: 1656946808,
			balance: 99672.08
		},
		{
			unixtime: 1656946868,
			balance: 99672.08
		},
		{
			unixtime: 1656946928,
			balance: 99672.08
		},
		{
			unixtime: 1656946988,
			balance: 99672.08
		},
		{
			unixtime: 1656947048,
			balance: 99672.08
		},
		{
			unixtime: 1656947108,
			balance: 99672.08
		},
		{
			unixtime: 1656947168,
			balance: 99672.08
		},
		{
			unixtime: 1656947228,
			balance: 99672.08
		},
		{
			unixtime: 1656947288,
			balance: 99672.08
		},
		{
			unixtime: 1656947348,
			balance: 99672.08
		},
		{
			unixtime: 1656947408,
			balance: 99672.08
		},
		{
			unixtime: 1656947468,
			balance: 99672.08
		},
		{
			unixtime: 1656947528,
			balance: 99672.08
		},
		{
			unixtime: 1656947588,
			balance: 99672.08
		},
		{
			unixtime: 1656947648,
			balance: 99672.08
		},
		{
			unixtime: 1656947708,
			balance: 99672.08
		},
		{
			unixtime: 1656947768,
			balance: 99672.08
		},
		{
			unixtime: 1656947828,
			balance: 99672.08
		},
		{
			unixtime: 1656947888,
			balance: 99672.08
		},
		{
			unixtime: 1656947948,
			balance: 99672.08
		},
		{
			unixtime: 1656948008,
			balance: 99672.08
		},
		{
			unixtime: 1656948068,
			balance: 99672.08
		},
		{
			unixtime: 1656948128,
			balance: 99672.08
		},
		{
			unixtime: 1656948188,
			balance: 99672.08
		},
		{
			unixtime: 1656948248,
			balance: 99672.08
		},
		{
			unixtime: 1656948308,
			balance: 99672.08
		},
		{
			unixtime: 1656948368,
			balance: 99672.08
		},
		{
			unixtime: 1656948428,
			balance: 99672.08
		},
		{
			unixtime: 1656948488,
			balance: 99672.08
		},
		{
			unixtime: 1656948548,
			balance: 99672.08
		},
		{
			unixtime: 1656948608,
			balance: 99672.08
		},
		{
			unixtime: 1656948668,
			balance: 99672.08
		},
		{
			unixtime: 1656948728,
			balance: 99672.08
		},
		{
			unixtime: 1656948788,
			balance: 99672.08
		},
		{
			unixtime: 1656948848,
			balance: 99672.08
		},
		{
			unixtime: 1656948908,
			balance: 99672.08
		},
		{
			unixtime: 1656948968,
			balance: 99672.08
		},
		{
			unixtime: 1656949028,
			balance: 99672.08
		},
		{
			unixtime: 1656949088,
			balance: 99672.08
		},
		{
			unixtime: 1656949148,
			balance: 99672.08
		},
		{
			unixtime: 1656949208,
			balance: 99672.08
		},
		{
			unixtime: 1656949268,
			balance: 99672.08
		},
		{
			unixtime: 1656949328,
			balance: 99672.08
		},
		{
			unixtime: 1656949388,
			balance: 99672.08
		},
		{
			unixtime: 1656949448,
			balance: 99672.08
		},
		{
			unixtime: 1656949508,
			balance: 99672.08
		},
		{
			unixtime: 1656949568,
			balance: 99672.08
		},
		{
			unixtime: 1656949628,
			balance: 99672.08
		},
		{
			unixtime: 1656949688,
			balance: 99672.08
		},
		{
			unixtime: 1656949748,
			balance: 99672.08
		},
		{
			unixtime: 1656949808,
			balance: 99672.08
		},
		{
			unixtime: 1656949868,
			balance: 99672.08
		},
		{
			unixtime: 1656949928,
			balance: 99672.08
		},
		{
			unixtime: 1656949988,
			balance: 99672.08
		},
		{
			unixtime: 1656950048,
			balance: 99672.08
		},
		{
			unixtime: 1656950108,
			balance: 99672.08
		},
		{
			unixtime: 1656950168,
			balance: 99672.08
		},
		{
			unixtime: 1656950228,
			balance: 99672.08
		},
		{
			unixtime: 1656950288,
			balance: 99672.08
		},
		{
			unixtime: 1656950348,
			balance: 99672.08
		},
		{
			unixtime: 1656950408,
			balance: 99672.08
		},
		{
			unixtime: 1656950468,
			balance: 99672.08
		},
		{
			unixtime: 1656950528,
			balance: 99672.08
		},
		{
			unixtime: 1656950588,
			balance: 99672.08
		},
		{
			unixtime: 1656950648,
			balance: 99672.08
		},
		{
			unixtime: 1656950708,
			balance: 99672.08
		},
		{
			unixtime: 1656950768,
			balance: 99672.08
		},
		{
			unixtime: 1656950828,
			balance: 99672.08
		},
		{
			unixtime: 1656950888,
			balance: 99672.08
		},
		{
			unixtime: 1656950948,
			balance: 99672.08
		},
		{
			unixtime: 1656951008,
			balance: 99672.08
		},
		{
			unixtime: 1656951068,
			balance: 99672.08
		},
		{
			unixtime: 1656951128,
			balance: 99672.08
		},
		{
			unixtime: 1656951188,
			balance: 99672.08
		},
		{
			unixtime: 1656951248,
			balance: 99672.08
		},
		{
			unixtime: 1656951308,
			balance: 99672.08
		},
		{
			unixtime: 1656951368,
			balance: 99672.08
		},
		{
			unixtime: 1656951428,
			balance: 99672.08
		},
		{
			unixtime: 1656951488,
			balance: 99672.08
		},
		{
			unixtime: 1656951548,
			balance: 99672.08
		},
		{
			unixtime: 1656951608,
			balance: 99672.08
		},
		{
			unixtime: 1656951668,
			balance: 99672.08
		},
		{
			unixtime: 1656951728,
			balance: 99672.08
		},
		{
			unixtime: 1656951788,
			balance: 99672.08
		},
		{
			unixtime: 1656951848,
			balance: 99672.08
		},
		{
			unixtime: 1656951908,
			balance: 99672.08
		},
		{
			unixtime: 1656951968,
			balance: 99672.08
		},
		{
			unixtime: 1656952028,
			balance: 99672.08
		},
		{
			unixtime: 1656952088,
			balance: 99672.08
		},
		{
			unixtime: 1656952148,
			balance: 99672.08
		},
		{
			unixtime: 1656952208,
			balance: 99672.08
		},
		{
			unixtime: 1656952268,
			balance: 99672.08
		},
		{
			unixtime: 1656952328,
			balance: 99672.08
		},
		{
			unixtime: 1656952388,
			balance: 99672.08
		},
		{
			unixtime: 1656952448,
			balance: 99672.08
		},
		{
			unixtime: 1656952508,
			balance: 99672.08
		},
		{
			unixtime: 1656952568,
			balance: 99672.08
		},
		{
			unixtime: 1656952628,
			balance: 99672.08
		},
		{
			unixtime: 1656952688,
			balance: 99672.08
		},
		{
			unixtime: 1656952748,
			balance: 99672.08
		},
		{
			unixtime: 1656952808,
			balance: 99672.08
		},
		{
			unixtime: 1656952868,
			balance: 99672.08
		},
		{
			unixtime: 1656952928,
			balance: 99672.08
		},
		{
			unixtime: 1656952988,
			balance: 99672.08
		},
		{
			unixtime: 1656953048,
			balance: 99672.08
		},
		{
			unixtime: 1656953108,
			balance: 99672.08
		},
		{
			unixtime: 1656953168,
			balance: 99672.08
		},
		{
			unixtime: 1656953228,
			balance: 99672.08
		},
		{
			unixtime: 1656953288,
			balance: 99672.08
		},
		{
			unixtime: 1656953348,
			balance: 99672.08
		},
		{
			unixtime: 1656953408,
			balance: 99672.08
		},
		{
			unixtime: 1656953468,
			balance: 99672.08
		},
		{
			unixtime: 1656953528,
			balance: 99672.08
		},
		{
			unixtime: 1656953588,
			balance: 99672.08
		},
		{
			unixtime: 1656953648,
			balance: 99672.08
		},
		{
			unixtime: 1656953708,
			balance: 99672.08
		},
		{
			unixtime: 1656953768,
			balance: 99672.08
		},
		{
			unixtime: 1656953828,
			balance: 99672.08
		},
		{
			unixtime: 1656953888,
			balance: 99672.08
		},
		{
			unixtime: 1656953948,
			balance: 99672.08
		},
		{
			unixtime: 1656954008,
			balance: 99672.08
		},
		{
			unixtime: 1656954068,
			balance: 99672.08
		},
		{
			unixtime: 1656954128,
			balance: 99672.08
		},
		{
			unixtime: 1656954188,
			balance: 99672.08
		},
		{
			unixtime: 1656954248,
			balance: 99672.08
		},
		{
			unixtime: 1656954308,
			balance: 99672.08
		},
		{
			unixtime: 1656954368,
			balance: 99672.08
		},
		{
			unixtime: 1656954428,
			balance: 99672.08
		},
		{
			unixtime: 1656954488,
			balance: 99672.08
		},
		{
			unixtime: 1656954548,
			balance: 99672.08
		},
		{
			unixtime: 1656954608,
			balance: 99672.08
		},
		{
			unixtime: 1656954668,
			balance: 99672.08
		},
		{
			unixtime: 1656954728,
			balance: 99672.08
		},
		{
			unixtime: 1656954788,
			balance: 99672.08
		},
		{
			unixtime: 1656954848,
			balance: 99672.08
		},
		{
			unixtime: 1656954908,
			balance: 99672.08
		},
		{
			unixtime: 1656954968,
			balance: 99672.08
		},
		{
			unixtime: 1656955028,
			balance: 99672.08
		},
		{
			unixtime: 1656955088,
			balance: 99672.08
		},
		{
			unixtime: 1656955148,
			balance: 99672.08
		},
		{
			unixtime: 1656955208,
			balance: 99672.08
		},
		{
			unixtime: 1656955268,
			balance: 99672.08
		},
		{
			unixtime: 1656955328,
			balance: 99672.08
		},
		{
			unixtime: 1656955388,
			balance: 99672.08
		},
		{
			unixtime: 1656955448,
			balance: 99672.08
		},
		{
			unixtime: 1656955508,
			balance: 99672.08
		},
		{
			unixtime: 1656955568,
			balance: 99672.08
		},
		{
			unixtime: 1656955628,
			balance: 99672.08
		},
		{
			unixtime: 1656955688,
			balance: 99672.08
		},
		{
			unixtime: 1656955748,
			balance: 99672.08
		},
		{
			unixtime: 1656955808,
			balance: 99672.08
		},
		{
			unixtime: 1656955868,
			balance: 99672.08
		},
		{
			unixtime: 1656955928,
			balance: 99672.08
		},
		{
			unixtime: 1656955988,
			balance: 99672.08
		},
		{
			unixtime: 1656956048,
			balance: 99672.08
		},
		{
			unixtime: 1656956108,
			balance: 99672.08
		},
		{
			unixtime: 1656956168,
			balance: 99672.08
		},
		{
			unixtime: 1656956228,
			balance: 99672.08
		},
		{
			unixtime: 1656956288,
			balance: 99672.08
		},
		{
			unixtime: 1656956348,
			balance: 99672.08
		},
		{
			unixtime: 1656956408,
			balance: 99672.08
		},
		{
			unixtime: 1656956468,
			balance: 99672.08
		},
		{
			unixtime: 1656956528,
			balance: 99672.08
		},
		{
			unixtime: 1656956588,
			balance: 99672.08
		},
		{
			unixtime: 1656956648,
			balance: 99672.08
		},
		{
			unixtime: 1656956708,
			balance: 99672.08
		},
		{
			unixtime: 1656956768,
			balance: 99672.08
		},
		{
			unixtime: 1656956828,
			balance: 99672.08
		},
		{
			unixtime: 1656956888,
			balance: 99672.08
		},
		{
			unixtime: 1656956948,
			balance: 99672.08
		},
		{
			unixtime: 1656957008,
			balance: 99672.08
		},
		{
			unixtime: 1656957068,
			balance: 99672.08
		},
		{
			unixtime: 1656957128,
			balance: 99672.08
		},
		{
			unixtime: 1656957188,
			balance: 99672.08
		},
		{
			unixtime: 1656957248,
			balance: 99672.08
		},
		{
			unixtime: 1656957308,
			balance: 99672.08
		},
		{
			unixtime: 1656957368,
			balance: 99672.08
		},
		{
			unixtime: 1656957428,
			balance: 99672.08
		},
		{
			unixtime: 1656957488,
			balance: 99672.08
		},
		{
			unixtime: 1656957548,
			balance: 99672.08
		},
		{
			unixtime: 1656957608,
			balance: 99672.08
		},
		{
			unixtime: 1656957668,
			balance: 99672.08
		},
		{
			unixtime: 1656957728,
			balance: 99672.08
		},
		{
			unixtime: 1656957788,
			balance: 99672.08
		},
		{
			unixtime: 1656957848,
			balance: 99672.08
		},
		{
			unixtime: 1656957908,
			balance: 99672.08
		},
		{
			unixtime: 1656957968,
			balance: 99672.08
		},
		{
			unixtime: 1656958028,
			balance: 99672.08
		},
		{
			unixtime: 1656958088,
			balance: 99672.08
		},
		{
			unixtime: 1656958148,
			balance: 99672.08
		},
		{
			unixtime: 1656958208,
			balance: 99672.08
		},
		{
			unixtime: 1656958268,
			balance: 99672.08
		},
		{
			unixtime: 1656958328,
			balance: 99672.08
		},
		{
			unixtime: 1656958388,
			balance: 99672.08
		},
		{
			unixtime: 1656958448,
			balance: 99672.08
		},
		{
			unixtime: 1656958508,
			balance: 99672.08
		},
		{
			unixtime: 1656958568,
			balance: 99672.08
		},
		{
			unixtime: 1656958628,
			balance: 99672.08
		},
		{
			unixtime: 1656958688,
			balance: 99672.08
		},
		{
			unixtime: 1656958748,
			balance: 99672.08
		},
		{
			unixtime: 1656958808,
			balance: 99672.08
		},
		{
			unixtime: 1656958868,
			balance: 99672.08
		},
		{
			unixtime: 1656958928,
			balance: 99672.08
		},
		{
			unixtime: 1656958988,
			balance: 99672.08
		},
		{
			unixtime: 1656959048,
			balance: 99672.08
		},
		{
			unixtime: 1656959108,
			balance: 99672.08
		},
		{
			unixtime: 1656959168,
			balance: 99672.08
		},
		{
			unixtime: 1656959228,
			balance: 99672.08
		},
		{
			unixtime: 1656959288,
			balance: 99672.08
		},
		{
			unixtime: 1656959348,
			balance: 99672.08
		},
		{
			unixtime: 1656959408,
			balance: 99672.08
		},
		{
			unixtime: 1656959468,
			balance: 99672.08
		},
		{
			unixtime: 1656959528,
			balance: 99672.08
		},
		{
			unixtime: 1656959588,
			balance: 99672.08
		},
		{
			unixtime: 1656959648,
			balance: 99672.08
		},
		{
			unixtime: 1656959708,
			balance: 99672.08
		},
		{
			unixtime: 1656959768,
			balance: 99672.08
		},
		{
			unixtime: 1656959828,
			balance: 99672.08
		},
		{
			unixtime: 1656959888,
			balance: 99672.08
		},
		{
			unixtime: 1656959948,
			balance: 99672.08
		},
		{
			unixtime: 1656960008,
			balance: 99672.08
		},
		{
			unixtime: 1656960068,
			balance: 99672.08
		},
		{
			unixtime: 1656960128,
			balance: 99672.08
		},
		{
			unixtime: 1656960188,
			balance: 99672.08
		},
		{
			unixtime: 1656960248,
			balance: 99672.08
		},
		{
			unixtime: 1656960308,
			balance: 99672.08
		},
		{
			unixtime: 1656960368,
			balance: 99672.08
		},
		{
			unixtime: 1656960428,
			balance: 99672.08
		},
		{
			unixtime: 1656960488,
			balance: 99672.08
		},
		{
			unixtime: 1656960548,
			balance: 99672.08
		},
		{
			unixtime: 1656960608,
			balance: 99672.08
		},
		{
			unixtime: 1656960668,
			balance: 99672.08
		},
		{
			unixtime: 1656960728,
			balance: 99672.08
		},
		{
			unixtime: 1656960788,
			balance: 99672.08
		},
		{
			unixtime: 1656960848,
			balance: 99672.08
		},
		{
			unixtime: 1656960908,
			balance: 99672.08
		},
		{
			unixtime: 1656960968,
			balance: 99672.08
		},
		{
			unixtime: 1656961028,
			balance: 99672.08
		},
		{
			unixtime: 1656961088,
			balance: 99672.08
		},
		{
			unixtime: 1656961148,
			balance: 99672.08
		},
		{
			unixtime: 1656961208,
			balance: 99672.08
		},
		{
			unixtime: 1656961268,
			balance: 99672.08
		},
		{
			unixtime: 1656961328,
			balance: 99672.08
		},
		{
			unixtime: 1656961388,
			balance: 99672.08
		},
		{
			unixtime: 1656961448,
			balance: 99672.08
		},
		{
			unixtime: 1656961508,
			balance: 99672.08
		},
		{
			unixtime: 1656961568,
			balance: 99672.08
		},
		{
			unixtime: 1656961628,
			balance: 99672.08
		},
		{
			unixtime: 1656961688,
			balance: 99672.08
		},
		{
			unixtime: 1656961748,
			balance: 99672.08
		},
		{
			unixtime: 1656961808,
			balance: 99672.08
		},
		{
			unixtime: 1656961869,
			balance: 99672.08
		},
		{
			unixtime: 1656961928,
			balance: 99672.08
		},
		{
			unixtime: 1656961988,
			balance: 99672.08
		},
		{
			unixtime: 1656962048,
			balance: 99672.08
		},
		{
			unixtime: 1656962108,
			balance: 99672.08
		},
		{
			unixtime: 1656962168,
			balance: 99672.08
		},
		{
			unixtime: 1656962228,
			balance: 99672.08
		},
		{
			unixtime: 1656962288,
			balance: 99672.08
		},
		{
			unixtime: 1656962348,
			balance: 99672.08
		},
		{
			unixtime: 1656962408,
			balance: 99672.08
		},
		{
			unixtime: 1656962468,
			balance: 99672.08
		},
		{
			unixtime: 1656962528,
			balance: 99672.08
		},
		{
			unixtime: 1656962589,
			balance: 99672.08
		},
		{
			unixtime: 1656962648,
			balance: 99672.08
		},
		{
			unixtime: 1656962708,
			balance: 99672.08
		},
		{
			unixtime: 1656962768,
			balance: 99672.08
		},
		{
			unixtime: 1656962828,
			balance: 99672.08
		},
		{
			unixtime: 1656962888,
			balance: 99672.08
		},
		{
			unixtime: 1656962949,
			balance: 99672.08
		},
		{
			unixtime: 1656963009,
			balance: 99672.08
		},
		{
			unixtime: 1656963069,
			balance: 99672.08
		},
		{
			unixtime: 1656963129,
			balance: 99672.08
		},
		{
			unixtime: 1656963189,
			balance: 99672.08
		},
		{
			unixtime: 1656963249,
			balance: 99672.08
		},
		{
			unixtime: 1656963309,
			balance: 99672.08
		},
		{
			unixtime: 1656963369,
			balance: 99672.08
		},
		{
			unixtime: 1656963429,
			balance: 99672.08
		},
		{
			unixtime: 1656963489,
			balance: 99672.08
		},
		{
			unixtime: 1656963549,
			balance: 99672.08
		},
		{
			unixtime: 1656963609,
			balance: 99672.08
		},
		{
			unixtime: 1656963669,
			balance: 99672.08
		},
		{
			unixtime: 1656963729,
			balance: 99672.08
		},
		{
			unixtime: 1656963789,
			balance: 99672.08
		},
		{
			unixtime: 1656963849,
			balance: 99672.08
		},
		{
			unixtime: 1656963909,
			balance: 99672.08
		},
		{
			unixtime: 1656963969,
			balance: 99672.08
		},
		{
			unixtime: 1656964029,
			balance: 99672.08
		},
		{
			unixtime: 1656964089,
			balance: 99672.08
		},
		{
			unixtime: 1656964149,
			balance: 99672.08
		},
		{
			unixtime: 1656964209,
			balance: 99672.08
		},
		{
			unixtime: 1656964269,
			balance: 99672.08
		},
		{
			unixtime: 1656964329,
			balance: 99672.08
		},
		{
			unixtime: 1656964389,
			balance: 99672.08
		},
		{
			unixtime: 1656964449,
			balance: 99672.08
		},
		{
			unixtime: 1656964509,
			balance: 99672.08
		},
		{
			unixtime: 1656964569,
			balance: 99672.08
		},
		{
			unixtime: 1656964629,
			balance: 99672.08
		},
		{
			unixtime: 1656964689,
			balance: 99672.08
		},
		{
			unixtime: 1656964749,
			balance: 99672.08
		},
		{
			unixtime: 1656964809,
			balance: 99672.08
		},
		{
			unixtime: 1656964869,
			balance: 99672.08
		},
		{
			unixtime: 1656964929,
			balance: 99672.08
		},
		{
			unixtime: 1656964989,
			balance: 99672.08
		},
		{
			unixtime: 1656965049,
			balance: 99672.08
		},
		{
			unixtime: 1656965109,
			balance: 99672.08
		},
		{
			unixtime: 1656965169,
			balance: 99672.08
		},
		{
			unixtime: 1656965229,
			balance: 99672.08
		},
		{
			unixtime: 1656965289,
			balance: 99672.08
		},
		{
			unixtime: 1656965349,
			balance: 99672.08
		},
		{
			unixtime: 1656965409,
			balance: 99672.08
		},
		{
			unixtime: 1656965469,
			balance: 99672.08
		},
		{
			unixtime: 1656965529,
			balance: 99672.08
		},
		{
			unixtime: 1656965589,
			balance: 99672.08
		},
		{
			unixtime: 1656965649,
			balance: 99672.08
		},
		{
			unixtime: 1656965709,
			balance: 99672.08
		},
		{
			unixtime: 1656965769,
			balance: 99672.08
		},
		{
			unixtime: 1656965829,
			balance: 99672.08
		},
		{
			unixtime: 1656965889,
			balance: 99672.08
		},
		{
			unixtime: 1656965949,
			balance: 99672.08
		},
		{
			unixtime: 1656966009,
			balance: 99672.08
		},
		{
			unixtime: 1656966069,
			balance: 99672.08
		},
		{
			unixtime: 1656966129,
			balance: 99672.08
		},
		{
			unixtime: 1656966189,
			balance: 99672.08
		},
		{
			unixtime: 1656966249,
			balance: 99672.08
		},
		{
			unixtime: 1656966309,
			balance: 99672.08
		},
		{
			unixtime: 1656966369,
			balance: 99672.08
		},
		{
			unixtime: 1656966429,
			balance: 99672.08
		},
		{
			unixtime: 1656966489,
			balance: 99672.08
		},
		{
			unixtime: 1656966549,
			balance: 99672.08
		},
		{
			unixtime: 1656966609,
			balance: 99672.08
		},
		{
			unixtime: 1656966669,
			balance: 99672.08
		},
		{
			unixtime: 1656966729,
			balance: 99672.08
		},
		{
			unixtime: 1656966789,
			balance: 99672.08
		},
		{
			unixtime: 1656966849,
			balance: 99672.08
		},
		{
			unixtime: 1656966909,
			balance: 99672.08
		},
		{
			unixtime: 1656966969,
			balance: 99672.08
		},
		{
			unixtime: 1656967029,
			balance: 99672.08
		},
		{
			unixtime: 1656967089,
			balance: 99672.08
		},
		{
			unixtime: 1656967149,
			balance: 99672.08
		},
		{
			unixtime: 1656967209,
			balance: 99672.08
		},
		{
			unixtime: 1656967269,
			balance: 99672.08
		},
		{
			unixtime: 1656967329,
			balance: 99672.08
		},
		{
			unixtime: 1656967389,
			balance: 99672.08
		},
		{
			unixtime: 1656967449,
			balance: 99672.08
		},
		{
			unixtime: 1656967509,
			balance: 99672.08
		},
		{
			unixtime: 1656967569,
			balance: 99672.08
		},
		{
			unixtime: 1656967629,
			balance: 99672.08
		},
		{
			unixtime: 1656967689,
			balance: 99672.08
		},
		{
			unixtime: 1656967749,
			balance: 99672.08
		},
		{
			unixtime: 1656967809,
			balance: 99672.08
		},
		{
			unixtime: 1656967869,
			balance: 99672.08
		},
		{
			unixtime: 1656967929,
			balance: 99672.08
		},
		{
			unixtime: 1656967989,
			balance: 99672.08
		},
		{
			unixtime: 1656968049,
			balance: 99672.08
		},
		{
			unixtime: 1656968109,
			balance: 99672.08
		},
		{
			unixtime: 1656968169,
			balance: 99672.08
		},
		{
			unixtime: 1656968229,
			balance: 99672.08
		},
		{
			unixtime: 1656968289,
			balance: 99672.08
		},
		{
			unixtime: 1656968349,
			balance: 99672.08
		},
		{
			unixtime: 1656968409,
			balance: 99672.08
		},
		{
			unixtime: 1656968469,
			balance: 99672.08
		},
		{
			unixtime: 1656968529,
			balance: 99672.08
		},
		{
			unixtime: 1656968589,
			balance: 99672.08
		},
		{
			unixtime: 1656968649,
			balance: 99672.08
		},
		{
			unixtime: 1656968709,
			balance: 99672.08
		},
		{
			unixtime: 1656968769,
			balance: 99672.08
		},
		{
			unixtime: 1656968829,
			balance: 99672.08
		},
		{
			unixtime: 1656968889,
			balance: 99672.08
		},
		{
			unixtime: 1656968949,
			balance: 99672.08
		},
		{
			unixtime: 1656969009,
			balance: 99672.08
		},
		{
			unixtime: 1656969069,
			balance: 99672.08
		},
		{
			unixtime: 1656969129,
			balance: 99672.08
		},
		{
			unixtime: 1656969189,
			balance: 99672.08
		},
		{
			unixtime: 1656969249,
			balance: 99672.08
		},
		{
			unixtime: 1656969309,
			balance: 99672.08
		},
		{
			unixtime: 1656969369,
			balance: 99672.08
		},
		{
			unixtime: 1656969429,
			balance: 99672.08
		},
		{
			unixtime: 1656969489,
			balance: 99672.08
		},
		{
			unixtime: 1656969549,
			balance: 99672.08
		},
		{
			unixtime: 1656969609,
			balance: 99672.08
		},
		{
			unixtime: 1656969669,
			balance: 99672.08
		},
		{
			unixtime: 1656969729,
			balance: 99672.08
		},
		{
			unixtime: 1656969789,
			balance: 99672.08
		},
		{
			unixtime: 1656969849,
			balance: 99672.08
		},
		{
			unixtime: 1656969909,
			balance: 99672.08
		},
		{
			unixtime: 1656969969,
			balance: 99672.08
		},
		{
			unixtime: 1656970029,
			balance: 99672.08
		},
		{
			unixtime: 1656970089,
			balance: 99672.08
		},
		{
			unixtime: 1656970149,
			balance: 99672.08
		},
		{
			unixtime: 1656970209,
			balance: 99672.08
		},
		{
			unixtime: 1656970269,
			balance: 99672.08
		},
		{
			unixtime: 1656970329,
			balance: 99672.08
		},
		{
			unixtime: 1656970389,
			balance: 99672.08
		},
		{
			unixtime: 1656970449,
			balance: 99672.08
		},
		{
			unixtime: 1656970509,
			balance: 99672.08
		},
		{
			unixtime: 1656970569,
			balance: 99672.08
		},
		{
			unixtime: 1656970629,
			balance: 99672.08
		},
		{
			unixtime: 1656970689,
			balance: 99672.08
		},
		{
			unixtime: 1656970749,
			balance: 99672.08
		},
		{
			unixtime: 1656970809,
			balance: 99672.08
		},
		{
			unixtime: 1656970869,
			balance: 99672.08
		},
		{
			unixtime: 1656970929,
			balance: 99672.08
		},
		{
			unixtime: 1656970989,
			balance: 99672.08
		},
		{
			unixtime: 1656971049,
			balance: 99672.08
		},
		{
			unixtime: 1656971109,
			balance: 99672.08
		},
		{
			unixtime: 1656971169,
			balance: 99672.08
		},
		{
			unixtime: 1656971229,
			balance: 99672.08
		},
		{
			unixtime: 1656971289,
			balance: 99672.08
		},
		{
			unixtime: 1656971349,
			balance: 99672.08
		},
		{
			unixtime: 1656971409,
			balance: 99672.08
		},
		{
			unixtime: 1656971469,
			balance: 99672.08
		},
		{
			unixtime: 1656971529,
			balance: 99672.08
		},
		{
			unixtime: 1656971589,
			balance: 99672.08
		},
		{
			unixtime: 1656971649,
			balance: 99672.08
		},
		{
			unixtime: 1656971709,
			balance: 99672.08
		},
		{
			unixtime: 1656971769,
			balance: 99672.08
		},
		{
			unixtime: 1656971829,
			balance: 99672.08
		},
		{
			unixtime: 1656971889,
			balance: 99672.08
		},
		{
			unixtime: 1656971949,
			balance: 99672.08
		},
		{
			unixtime: 1656972009,
			balance: 99672.08
		},
		{
			unixtime: 1656972069,
			balance: 99672.08
		},
		{
			unixtime: 1656972129,
			balance: 99672.08
		},
		{
			unixtime: 1656972189,
			balance: 99672.08
		},
		{
			unixtime: 1656972249,
			balance: 99672.08
		},
		{
			unixtime: 1656972309,
			balance: 99672.08
		},
		{
			unixtime: 1656972369,
			balance: 99672.08
		},
		{
			unixtime: 1656972429,
			balance: 99672.08
		},
		{
			unixtime: 1656972489,
			balance: 99672.08
		},
		{
			unixtime: 1656972549,
			balance: 99672.08
		},
		{
			unixtime: 1656972609,
			balance: 99672.08
		},
		{
			unixtime: 1656972669,
			balance: 99672.08
		},
		{
			unixtime: 1656972729,
			balance: 99672.08
		},
		{
			unixtime: 1656972789,
			balance: 99672.08
		},
		{
			unixtime: 1656972849,
			balance: 99672.08
		},
		{
			unixtime: 1656972909,
			balance: 99672.08
		},
		{
			unixtime: 1656972969,
			balance: 99672.08
		},
		{
			unixtime: 1656973029,
			balance: 99672.08
		},
		{
			unixtime: 1656973089,
			balance: 99672.08
		},
		{
			unixtime: 1656973149,
			balance: 99672.08
		},
		{
			unixtime: 1656973209,
			balance: 99672.08
		},
		{
			unixtime: 1656973269,
			balance: 99672.08
		},
		{
			unixtime: 1656973329,
			balance: 99672.08
		},
		{
			unixtime: 1656973389,
			balance: 99672.08
		},
		{
			unixtime: 1656973449,
			balance: 99672.08
		},
		{
			unixtime: 1656973509,
			balance: 99672.08
		},
		{
			unixtime: 1656973569,
			balance: 99672.08
		},
		{
			unixtime: 1656973629,
			balance: 99672.08
		},
		{
			unixtime: 1656973689,
			balance: 99672.08
		},
		{
			unixtime: 1656973749,
			balance: 99672.08
		},
		{
			unixtime: 1656973809,
			balance: 99672.08
		},
		{
			unixtime: 1656973869,
			balance: 99672.08
		},
		{
			unixtime: 1656973929,
			balance: 99672.08
		},
		{
			unixtime: 1656973989,
			balance: 99672.08
		},
		{
			unixtime: 1656974049,
			balance: 99672.08
		},
		{
			unixtime: 1656974109,
			balance: 99672.08
		},
		{
			unixtime: 1656974169,
			balance: 99672.08
		},
		{
			unixtime: 1656974229,
			balance: 99672.08
		},
		{
			unixtime: 1656974289,
			balance: 99672.08
		},
		{
			unixtime: 1656974349,
			balance: 99672.08
		},
		{
			unixtime: 1656974409,
			balance: 99672.08
		},
		{
			unixtime: 1656974469,
			balance: 99672.08
		},
		{
			unixtime: 1656974529,
			balance: 99672.08
		},
		{
			unixtime: 1656974589,
			balance: 99672.08
		},
		{
			unixtime: 1656974649,
			balance: 99672.08
		},
		{
			unixtime: 1656974709,
			balance: 99672.08
		},
		{
			unixtime: 1656974769,
			balance: 99672.08
		},
		{
			unixtime: 1656974829,
			balance: 99672.08
		},
		{
			unixtime: 1656974889,
			balance: 99672.08
		},
		{
			unixtime: 1656974949,
			balance: 99672.08
		},
		{
			unixtime: 1656975009,
			balance: 99672.08
		},
		{
			unixtime: 1656975069,
			balance: 99672.08
		},
		{
			unixtime: 1656975129,
			balance: 99672.08
		},
		{
			unixtime: 1656975189,
			balance: 99672.08
		},
		{
			unixtime: 1656975249,
			balance: 99672.08
		},
		{
			unixtime: 1656975309,
			balance: 99672.08
		},
		{
			unixtime: 1656975369,
			balance: 99672.08
		},
		{
			unixtime: 1656975429,
			balance: 99672.08
		},
		{
			unixtime: 1656975489,
			balance: 99672.08
		},
		{
			unixtime: 1656975549,
			balance: 99672.08
		},
		{
			unixtime: 1656975609,
			balance: 99672.08
		},
		{
			unixtime: 1656975669,
			balance: 99672.08
		},
		{
			unixtime: 1656975729,
			balance: 99672.08
		},
		{
			unixtime: 1656975789,
			balance: 99672.08
		},
		{
			unixtime: 1656975849,
			balance: 99672.08
		},
		{
			unixtime: 1656975909,
			balance: 99672.08
		},
		{
			unixtime: 1656975969,
			balance: 99672.08
		},
		{
			unixtime: 1656976029,
			balance: 99672.08
		},
		{
			unixtime: 1656976089,
			balance: 99672.08
		},
		{
			unixtime: 1656976149,
			balance: 99672.08
		},
		{
			unixtime: 1656976209,
			balance: 99672.08
		},
		{
			unixtime: 1656976269,
			balance: 99672.08
		},
		{
			unixtime: 1656976329,
			balance: 99672.08
		},
		{
			unixtime: 1656976389,
			balance: 99672.08
		},
		{
			unixtime: 1656976449,
			balance: 99672.08
		},
		{
			unixtime: 1656976509,
			balance: 99672.08
		},
		{
			unixtime: 1656976569,
			balance: 99672.08
		},
		{
			unixtime: 1656976629,
			balance: 99672.08
		},
		{
			unixtime: 1656976689,
			balance: 99672.08
		},
		{
			unixtime: 1656976749,
			balance: 99672.08
		},
		{
			unixtime: 1656976809,
			balance: 99672.08
		},
		{
			unixtime: 1656976869,
			balance: 99672.08
		},
		{
			unixtime: 1656976929,
			balance: 99672.08
		},
		{
			unixtime: 1656976989,
			balance: 99672.08
		},
		{
			unixtime: 1656977049,
			balance: 99672.08
		},
		{
			unixtime: 1656977109,
			balance: 99672.08
		},
		{
			unixtime: 1656977169,
			balance: 99672.08
		},
		{
			unixtime: 1656977229,
			balance: 99672.08
		},
		{
			unixtime: 1656977289,
			balance: 99672.08
		},
		{
			unixtime: 1656977349,
			balance: 99672.08
		},
		{
			unixtime: 1656977409,
			balance: 99672.08
		},
		{
			unixtime: 1656977469,
			balance: 99672.08
		},
		{
			unixtime: 1656977529,
			balance: 99672.08
		},
		{
			unixtime: 1656977589,
			balance: 99672.08
		},
		{
			unixtime: 1656977649,
			balance: 99672.08
		},
		{
			unixtime: 1656977709,
			balance: 99672.08
		},
		{
			unixtime: 1656977769,
			balance: 99672.08
		},
		{
			unixtime: 1656977829,
			balance: 99672.08
		},
		{
			unixtime: 1656977889,
			balance: 99672.08
		},
		{
			unixtime: 1656977949,
			balance: 99672.08
		},
		{
			unixtime: 1656978009,
			balance: 99672.08
		},
		{
			unixtime: 1656978069,
			balance: 99672.08
		},
		{
			unixtime: 1656978129,
			balance: 99672.08
		},
		{
			unixtime: 1656978189,
			balance: 99672.08
		},
		{
			unixtime: 1656978249,
			balance: 99672.08
		},
		{
			unixtime: 1656978309,
			balance: 99672.08
		},
		{
			unixtime: 1656978369,
			balance: 99672.08
		},
		{
			unixtime: 1656978429,
			balance: 99672.08
		},
		{
			unixtime: 1656978489,
			balance: 99672.08
		},
		{
			unixtime: 1656978549,
			balance: 99672.08
		},
		{
			unixtime: 1656978609,
			balance: 99672.08
		},
		{
			unixtime: 1656978669,
			balance: 99672.08
		},
		{
			unixtime: 1656978729,
			balance: 99672.08
		},
		{
			unixtime: 1656978789,
			balance: 99672.08
		},
		{
			unixtime: 1656978849,
			balance: 99672.08
		},
		{
			unixtime: 1656978909,
			balance: 99672.08
		},
		{
			unixtime: 1656978969,
			balance: 99672.08
		},
		{
			unixtime: 1656979029,
			balance: 99672.08
		},
		{
			unixtime: 1656979089,
			balance: 99672.08
		},
		{
			unixtime: 1656979149,
			balance: 99672.08
		},
		{
			unixtime: 1656979209,
			balance: 99672.08
		},
		{
			unixtime: 1656979269,
			balance: 99672.08
		},
		{
			unixtime: 1656979329,
			balance: 99672.08
		},
		{
			unixtime: 1656979389,
			balance: 99672.08
		},
		{
			unixtime: 1656979449,
			balance: 99672.08
		},
		{
			unixtime: 1656979509,
			balance: 99672.08
		},
		{
			unixtime: 1656979569,
			balance: 99672.08
		},
		{
			unixtime: 1656979629,
			balance: 99672.08
		},
		{
			unixtime: 1656979689,
			balance: 99672.08
		},
		{
			unixtime: 1656979749,
			balance: 99672.08
		},
		{
			unixtime: 1656979809,
			balance: 99672.08
		},
		{
			unixtime: 1656979869,
			balance: 99672.08
		},
		{
			unixtime: 1656979929,
			balance: 99672.08
		},
		{
			unixtime: 1656979989,
			balance: 99672.08
		},
		{
			unixtime: 1656980049,
			balance: 99672.08
		},
		{
			unixtime: 1656980109,
			balance: 99672.08
		},
		{
			unixtime: 1656980169,
			balance: 99672.08
		},
		{
			unixtime: 1656980229,
			balance: 99672.08
		},
		{
			unixtime: 1656980289,
			balance: 99672.08
		},
		{
			unixtime: 1656980349,
			balance: 99672.08
		},
		{
			unixtime: 1656980409,
			balance: 99672.08
		},
		{
			unixtime: 1656980469,
			balance: 99672.08
		},
		{
			unixtime: 1656980529,
			balance: 99672.08
		},
		{
			unixtime: 1656980589,
			balance: 99672.08
		},
		{
			unixtime: 1656980649,
			balance: 99672.08
		},
		{
			unixtime: 1656980709,
			balance: 99672.08
		},
		{
			unixtime: 1656980769,
			balance: 99672.08
		},
		{
			unixtime: 1656980829,
			balance: 99672.08
		},
		{
			unixtime: 1656980889,
			balance: 99672.08
		},
		{
			unixtime: 1656980949,
			balance: 99672.08
		},
		{
			unixtime: 1656981009,
			balance: 99672.08
		},
		{
			unixtime: 1656981069,
			balance: 99672.08
		},
		{
			unixtime: 1656981129,
			balance: 99672.08
		},
		{
			unixtime: 1656981189,
			balance: 99672.08
		},
		{
			unixtime: 1656981249,
			balance: 99672.08
		},
		{
			unixtime: 1656981309,
			balance: 99672.08
		},
		{
			unixtime: 1656981369,
			balance: 99672.08
		},
		{
			unixtime: 1656981429,
			balance: 99672.08
		},
		{
			unixtime: 1656981489,
			balance: 99672.08
		},
		{
			unixtime: 1656981549,
			balance: 99672.08
		},
		{
			unixtime: 1656981609,
			balance: 99672.08
		},
		{
			unixtime: 1656981669,
			balance: 99672.08
		},
		{
			unixtime: 1656981729,
			balance: 99672.08
		},
		{
			unixtime: 1656981789,
			balance: 99672.08
		},
		{
			unixtime: 1656981849,
			balance: 99672.08
		},
		{
			unixtime: 1656981909,
			balance: 99672.08
		},
		{
			unixtime: 1656981969,
			balance: 99672.08
		},
		{
			unixtime: 1656982029,
			balance: 99672.08
		},
		{
			unixtime: 1656982089,
			balance: 99672.08
		},
		{
			unixtime: 1656982149,
			balance: 99672.08
		},
		{
			unixtime: 1656982209,
			balance: 99672.08
		},
		{
			unixtime: 1656982269,
			balance: 99672.08
		},
		{
			unixtime: 1656982329,
			balance: 99672.08
		},
		{
			unixtime: 1656982389,
			balance: 99672.08
		},
		{
			unixtime: 1656982449,
			balance: 99672.08
		},
		{
			unixtime: 1656982509,
			balance: 99672.08
		},
		{
			unixtime: 1656982569,
			balance: 99672.08
		},
		{
			unixtime: 1656982629,
			balance: 99672.08
		},
		{
			unixtime: 1656982689,
			balance: 99672.08
		},
		{
			unixtime: 1656982749,
			balance: 99672.08
		},
		{
			unixtime: 1656982809,
			balance: 99672.08
		},
		{
			unixtime: 1656982869,
			balance: 99672.08
		},
		{
			unixtime: 1656982929,
			balance: 99672.08
		},
		{
			unixtime: 1656982989,
			balance: 99672.08
		},
		{
			unixtime: 1656983049,
			balance: 99672.08
		},
		{
			unixtime: 1656983109,
			balance: 99672.08
		},
		{
			unixtime: 1656983169,
			balance: 99672.08
		},
		{
			unixtime: 1656983229,
			balance: 99672.08
		},
		{
			unixtime: 1656983289,
			balance: 99672.08
		},
		{
			unixtime: 1656983349,
			balance: 99672.08
		},
		{
			unixtime: 1656983409,
			balance: 99672.08
		},
		{
			unixtime: 1656983469,
			balance: 99672.08
		},
		{
			unixtime: 1656983529,
			balance: 99672.08
		},
		{
			unixtime: 1656983589,
			balance: 99672.08
		},
		{
			unixtime: 1656983649,
			balance: 99672.08
		},
		{
			unixtime: 1656983709,
			balance: 99672.08
		},
		{
			unixtime: 1656983769,
			balance: 99672.08
		},
		{
			unixtime: 1656983829,
			balance: 99672.08
		},
		{
			unixtime: 1656983889,
			balance: 99672.08
		},
		{
			unixtime: 1656983949,
			balance: 99672.08
		},
		{
			unixtime: 1656984009,
			balance: 99672.08
		},
		{
			unixtime: 1656984069,
			balance: 99672.08
		},
		{
			unixtime: 1656984129,
			balance: 99672.08
		},
		{
			unixtime: 1656984189,
			balance: 99672.08
		},
		{
			unixtime: 1656984249,
			balance: 99672.08
		},
		{
			unixtime: 1656984309,
			balance: 99672.08
		},
		{
			unixtime: 1656984369,
			balance: 99672.08
		},
		{
			unixtime: 1656984429,
			balance: 99672.08
		},
		{
			unixtime: 1656984489,
			balance: 99672.08
		},
		{
			unixtime: 1656984549,
			balance: 99672.08
		},
		{
			unixtime: 1656984609,
			balance: 99672.08
		},
		{
			unixtime: 1656984669,
			balance: 99672.08
		},
		{
			unixtime: 1656984729,
			balance: 99672.08
		},
		{
			unixtime: 1656984789,
			balance: 99672.08
		},
		{
			unixtime: 1656984849,
			balance: 99672.08
		},
		{
			unixtime: 1656984909,
			balance: 99672.08
		},
		{
			unixtime: 1656984969,
			balance: 99672.08
		},
		{
			unixtime: 1656985029,
			balance: 99672.08
		},
		{
			unixtime: 1656985089,
			balance: 99672.08
		},
		{
			unixtime: 1656985149,
			balance: 99672.08
		},
		{
			unixtime: 1656985209,
			balance: 99672.08
		},
		{
			unixtime: 1656985269,
			balance: 99672.08
		},
		{
			unixtime: 1656985329,
			balance: 99672.08
		},
		{
			unixtime: 1656985389,
			balance: 99672.08
		},
		{
			unixtime: 1656985449,
			balance: 99672.08
		},
		{
			unixtime: 1656985509,
			balance: 99672.08
		},
		{
			unixtime: 1656985569,
			balance: 99672.08
		},
		{
			unixtime: 1656985629,
			balance: 99672.08
		},
		{
			unixtime: 1656985689,
			balance: 99672.08
		},
		{
			unixtime: 1656985749,
			balance: 99672.08
		},
		{
			unixtime: 1656985809,
			balance: 99672.08
		},
		{
			unixtime: 1656985869,
			balance: 99672.08
		},
		{
			unixtime: 1656985929,
			balance: 99672.08
		},
		{
			unixtime: 1656985989,
			balance: 99672.08
		},
		{
			unixtime: 1656986049,
			balance: 99672.08
		},
		{
			unixtime: 1656986109,
			balance: 99672.08
		},
		{
			unixtime: 1656986169,
			balance: 99672.08
		},
		{
			unixtime: 1656986229,
			balance: 99672.08
		},
		{
			unixtime: 1656986289,
			balance: 99672.08
		},
		{
			unixtime: 1656986349,
			balance: 99672.08
		},
		{
			unixtime: 1656986409,
			balance: 99672.08
		},
		{
			unixtime: 1656986469,
			balance: 99672.08
		},
		{
			unixtime: 1656986529,
			balance: 99672.08
		},
		{
			unixtime: 1656986589,
			balance: 99672.08
		},
		{
			unixtime: 1656986649,
			balance: 99672.08
		},
		{
			unixtime: 1656986709,
			balance: 99672.08
		},
		{
			unixtime: 1656986769,
			balance: 99672.08
		},
		{
			unixtime: 1656986829,
			balance: 99672.08
		},
		{
			unixtime: 1656986889,
			balance: 99672.08
		},
		{
			unixtime: 1656986949,
			balance: 99672.08
		},
		{
			unixtime: 1656987009,
			balance: 99672.08
		},
		{
			unixtime: 1656987069,
			balance: 99672.08
		},
		{
			unixtime: 1656987129,
			balance: 99672.08
		},
		{
			unixtime: 1656987189,
			balance: 99672.08
		},
		{
			unixtime: 1656987249,
			balance: 99672.08
		},
		{
			unixtime: 1656987309,
			balance: 99672.08
		},
		{
			unixtime: 1656987369,
			balance: 99672.08
		},
		{
			unixtime: 1656987429,
			balance: 99672.08
		},
		{
			unixtime: 1656987489,
			balance: 99672.08
		},
		{
			unixtime: 1656987549,
			balance: 99672.08
		},
		{
			unixtime: 1656987609,
			balance: 99672.08
		},
		{
			unixtime: 1656987669,
			balance: 99672.08
		},
		{
			unixtime: 1656987729,
			balance: 99672.08
		},
		{
			unixtime: 1656987789,
			balance: 99672.08
		},
		{
			unixtime: 1656987849,
			balance: 99672.08
		},
		{
			unixtime: 1656987909,
			balance: 99672.08
		},
		{
			unixtime: 1656987969,
			balance: 99672.08
		},
		{
			unixtime: 1656988029,
			balance: 99672.08
		},
		{
			unixtime: 1656988089,
			balance: 99672.08
		},
		{
			unixtime: 1656988149,
			balance: 99672.08
		},
		{
			unixtime: 1656988209,
			balance: 99672.08
		},
		{
			unixtime: 1656988269,
			balance: 99672.08
		},
		{
			unixtime: 1656988329,
			balance: 99672.08
		},
		{
			unixtime: 1656988389,
			balance: 99672.08
		},
		{
			unixtime: 1656988449,
			balance: 99672.08
		},
		{
			unixtime: 1656988509,
			balance: 99672.08
		},
		{
			unixtime: 1656988569,
			balance: 99672.08
		},
		{
			unixtime: 1656988629,
			balance: 99672.08
		},
		{
			unixtime: 1656988689,
			balance: 99672.08
		},
		{
			unixtime: 1656988749,
			balance: 99672.08
		},
		{
			unixtime: 1656988809,
			balance: 99672.08
		},
		{
			unixtime: 1656988869,
			balance: 99672.08
		},
		{
			unixtime: 1656988929,
			balance: 99672.08
		},
		{
			unixtime: 1656988989,
			balance: 99672.08
		},
		{
			unixtime: 1656989049,
			balance: 99672.08
		},
		{
			unixtime: 1656989109,
			balance: 99672.08
		},
		{
			unixtime: 1656989169,
			balance: 99672.08
		},
		{
			unixtime: 1656989229,
			balance: 99672.08
		},
		{
			unixtime: 1656989289,
			balance: 99672.08
		},
		{
			unixtime: 1656989349,
			balance: 99672.08
		},
		{
			unixtime: 1656989409,
			balance: 99672.08
		},
		{
			unixtime: 1656989470,
			balance: 99672.08
		},
		{
			unixtime: 1656989529,
			balance: 99672.08
		},
		{
			unixtime: 1656989589,
			balance: 99672.08
		},
		{
			unixtime: 1656989649,
			balance: 99672.08
		},
		{
			unixtime: 1656989709,
			balance: 99672.08
		},
		{
			unixtime: 1656989769,
			balance: 99672.08
		},
		{
			unixtime: 1656989829,
			balance: 99672.08
		},
		{
			unixtime: 1656989889,
			balance: 99672.08
		},
		{
			unixtime: 1656989949,
			balance: 99672.08
		},
		{
			unixtime: 1656990009,
			balance: 99672.08
		},
		{
			unixtime: 1656990069,
			balance: 99672.08
		},
		{
			unixtime: 1656990129,
			balance: 99672.08
		},
		{
			unixtime: 1656990189,
			balance: 99672.08
		},
		{
			unixtime: 1656990249,
			balance: 99672.08
		},
		{
			unixtime: 1656990309,
			balance: 99672.08
		},
		{
			unixtime: 1656990369,
			balance: 99672.08
		},
		{
			unixtime: 1656990429,
			balance: 99672.08
		},
		{
			unixtime: 1656990489,
			balance: 99672.08
		},
		{
			unixtime: 1656990549,
			balance: 99672.08
		},
		{
			unixtime: 1656990609,
			balance: 99672.08
		},
		{
			unixtime: 1656990669,
			balance: 99672.08
		},
		{
			unixtime: 1656990729,
			balance: 99672.08
		},
		{
			unixtime: 1656990789,
			balance: 99672.08
		},
		{
			unixtime: 1656990849,
			balance: 99672.08
		},
		{
			unixtime: 1656990909,
			balance: 99672.08
		},
		{
			unixtime: 1656990969,
			balance: 99672.08
		},
		{
			unixtime: 1656991029,
			balance: 99672.08
		},
		{
			unixtime: 1656991089,
			balance: 99672.08
		},
		{
			unixtime: 1656991149,
			balance: 99672.08
		},
		{
			unixtime: 1656991209,
			balance: 99672.08
		},
		{
			unixtime: 1656991269,
			balance: 99672.08
		},
		{
			unixtime: 1656991329,
			balance: 99672.08
		},
		{
			unixtime: 1656991389,
			balance: 99672.08
		},
		{
			unixtime: 1656991449,
			balance: 99672.08
		},
		{
			unixtime: 1656991509,
			balance: 99672.08
		},
		{
			unixtime: 1656991569,
			balance: 99672.08
		},
		{
			unixtime: 1656991629,
			balance: 99672.08
		},
		{
			unixtime: 1656991689,
			balance: 99672.08
		},
		{
			unixtime: 1656991749,
			balance: 99672.08
		},
		{
			unixtime: 1656991809,
			balance: 99672.08
		},
		{
			unixtime: 1656991869,
			balance: 99672.08
		},
		{
			unixtime: 1656991929,
			balance: 99672.08
		},
		{
			unixtime: 1656991989,
			balance: 99672.08
		},
		{
			unixtime: 1656992049,
			balance: 99672.08
		},
		{
			unixtime: 1656992109,
			balance: 99672.08
		},
		{
			unixtime: 1656992169,
			balance: 99672.08
		},
		{
			unixtime: 1656992229,
			balance: 99672.08
		},
		{
			unixtime: 1656992289,
			balance: 99672.08
		},
		{
			unixtime: 1656992349,
			balance: 99672.08
		},
		{
			unixtime: 1656992409,
			balance: 99672.08
		},
		{
			unixtime: 1656992469,
			balance: 99672.08
		},
		{
			unixtime: 1656992529,
			balance: 99672.08
		},
		{
			unixtime: 1656992589,
			balance: 99672.08
		},
		{
			unixtime: 1656992649,
			balance: 99672.08
		},
		{
			unixtime: 1656992709,
			balance: 99672.08
		},
		{
			unixtime: 1656992769,
			balance: 99672.08
		},
		{
			unixtime: 1656992829,
			balance: 99672.08
		},
		{
			unixtime: 1656992889,
			balance: 99672.08
		},
		{
			unixtime: 1656992949,
			balance: 99672.08
		},
		{
			unixtime: 1656993009,
			balance: 99672.08
		},
		{
			unixtime: 1656993069,
			balance: 99672.08
		},
		{
			unixtime: 1656993129,
			balance: 99672.08
		},
		{
			unixtime: 1656993189,
			balance: 99672.08
		},
		{
			unixtime: 1656993249,
			balance: 99672.08
		},
		{
			unixtime: 1656993309,
			balance: 99672.08
		},
		{
			unixtime: 1656993369,
			balance: 99672.08
		},
		{
			unixtime: 1656993430,
			balance: 99672.08
		},
		{
			unixtime: 1656993489,
			balance: 99672.08
		},
		{
			unixtime: 1656993549,
			balance: 99672.08
		},
		{
			unixtime: 1656993609,
			balance: 99672.08
		},
		{
			unixtime: 1656993669,
			balance: 99672.08
		},
		{
			unixtime: 1656993729,
			balance: 99672.08
		},
		{
			unixtime: 1656993789,
			balance: 99672.08
		},
		{
			unixtime: 1656993849,
			balance: 99672.08
		},
		{
			unixtime: 1656993909,
			balance: 99672.08
		},
		{
			unixtime: 1656993969,
			balance: 99672.08
		},
		{
			unixtime: 1656994029,
			balance: 99672.08
		},
		{
			unixtime: 1656994089,
			balance: 99672.08
		},
		{
			unixtime: 1656994149,
			balance: 99672.08
		},
		{
			unixtime: 1656994209,
			balance: 99672.08
		},
		{
			unixtime: 1656994269,
			balance: 99672.08
		},
		{
			unixtime: 1656994329,
			balance: 99672.08
		},
		{
			unixtime: 1656994389,
			balance: 99672.08
		},
		{
			unixtime: 1656994449,
			balance: 99672.08
		},
		{
			unixtime: 1656994509,
			balance: 99672.08
		},
		{
			unixtime: 1656994570,
			balance: 99672.08
		},
		{
			unixtime: 1656994630,
			balance: 99672.08
		},
		{
			unixtime: 1656994689,
			balance: 99672.08
		},
		{
			unixtime: 1656994750,
			balance: 99672.08
		},
		{
			unixtime: 1656994810,
			balance: 99672.08
		},
		{
			unixtime: 1656994870,
			balance: 99672.08
		},
		{
			unixtime: 1656994930,
			balance: 99672.08
		},
		{
			unixtime: 1656994990,
			balance: 99672.08
		},
		{
			unixtime: 1656995050,
			balance: 99672.08
		},
		{
			unixtime: 1656995110,
			balance: 99672.08
		},
		{
			unixtime: 1656995170,
			balance: 99672.08
		},
		{
			unixtime: 1656995230,
			balance: 99672.08
		},
		{
			unixtime: 1656995290,
			balance: 99672.08
		},
		{
			unixtime: 1656995350,
			balance: 99672.08
		},
		{
			unixtime: 1656995410,
			balance: 99672.08
		},
		{
			unixtime: 1656995470,
			balance: 99672.08
		},
		{
			unixtime: 1656995530,
			balance: 99672.08
		},
		{
			unixtime: 1656995590,
			balance: 99672.08
		},
		{
			unixtime: 1656995650,
			balance: 99672.08
		},
		{
			unixtime: 1656995710,
			balance: 99672.08
		},
		{
			unixtime: 1656995770,
			balance: 99672.08
		},
		{
			unixtime: 1656995830,
			balance: 99672.08
		},
		{
			unixtime: 1656995903,
			balance: 99672.08
		},
		{
			unixtime: 1656995950,
			balance: 99672.08
		},
		{
			unixtime: 1656996010,
			balance: 99672.08
		},
		{
			unixtime: 1656996070,
			balance: 99672.08
		},
		{
			unixtime: 1656996130,
			balance: 99672.08
		},
		{
			unixtime: 1656996190,
			balance: 99672.08
		},
		{
			unixtime: 1656996250,
			balance: 99672.08
		},
		{
			unixtime: 1656996310,
			balance: 99672.08
		},
		{
			unixtime: 1656996370,
			balance: 99672.08
		},
		{
			unixtime: 1656996430,
			balance: 99672.08
		},
		{
			unixtime: 1656996490,
			balance: 99672.08
		},
		{
			unixtime: 1656996550,
			balance: 99672.08
		},
		{
			unixtime: 1656996610,
			balance: 99672.08
		},
		{
			unixtime: 1656996670,
			balance: 99672.08
		},
		{
			unixtime: 1656996730,
			balance: 99672.08
		},
		{
			unixtime: 1656996790,
			balance: 99672.08
		},
		{
			unixtime: 1656996850,
			balance: 99672.08
		},
		{
			unixtime: 1656996910,
			balance: 99672.08
		},
		{
			unixtime: 1656996970,
			balance: 99672.08
		},
		{
			unixtime: 1656997030,
			balance: 99672.08
		},
		{
			unixtime: 1656997090,
			balance: 99672.08
		},
		{
			unixtime: 1656997150,
			balance: 99672.08
		},
		{
			unixtime: 1656997210,
			balance: 99672.08
		},
		{
			unixtime: 1656997270,
			balance: 99672.08
		},
		{
			unixtime: 1656997330,
			balance: 99672.08
		},
		{
			unixtime: 1656997390,
			balance: 99672.08
		},
		{
			unixtime: 1656997450,
			balance: 99672.08
		},
		{
			unixtime: 1656997510,
			balance: 99672.08
		},
		{
			unixtime: 1656997570,
			balance: 99672.08
		},
		{
			unixtime: 1656997630,
			balance: 99672.08
		},
		{
			unixtime: 1656997690,
			balance: 99672.08
		},
		{
			unixtime: 1656997750,
			balance: 99672.08
		},
		{
			unixtime: 1656997810,
			balance: 99672.08
		},
		{
			unixtime: 1656997870,
			balance: 99672.08
		},
		{
			unixtime: 1656997930,
			balance: 99672.08
		},
		{
			unixtime: 1656997990,
			balance: 99672.08
		},
		{
			unixtime: 1656998050,
			balance: 99672.08
		},
		{
			unixtime: 1656998110,
			balance: 99672.08
		},
		{
			unixtime: 1656998170,
			balance: 99672.08
		},
		{
			unixtime: 1656998230,
			balance: 99672.08
		},
		{
			unixtime: 1656998290,
			balance: 99672.08
		},
		{
			unixtime: 1656998350,
			balance: 99672.08
		},
		{
			unixtime: 1656998410,
			balance: 99672.08
		},
		{
			unixtime: 1656998470,
			balance: 99672.08
		},
		{
			unixtime: 1656998530,
			balance: 99672.08
		},
		{
			unixtime: 1656998590,
			balance: 99672.08
		},
		{
			unixtime: 1656998650,
			balance: 99672.08
		},
		{
			unixtime: 1656998710,
			balance: 99672.08
		},
		{
			unixtime: 1656998770,
			balance: 99672.08
		},
		{
			unixtime: 1656998830,
			balance: 99672.08
		},
		{
			unixtime: 1656998890,
			balance: 99672.08
		},
		{
			unixtime: 1656998950,
			balance: 99672.08
		},
		{
			unixtime: 1656999010,
			balance: 99672.08
		},
		{
			unixtime: 1656999070,
			balance: 99672.08
		},
		{
			unixtime: 1656999130,
			balance: 99672.08
		},
		{
			unixtime: 1656999190,
			balance: 99672.08
		},
		{
			unixtime: 1656999250,
			balance: 99672.08
		},
		{
			unixtime: 1656999310,
			balance: 99672.08
		},
		{
			unixtime: 1656999370,
			balance: 99672.08
		},
		{
			unixtime: 1656999430,
			balance: 99672.08
		},
		{
			unixtime: 1656999490,
			balance: 99672.08
		},
		{
			unixtime: 1656999550,
			balance: 99672.08
		},
		{
			unixtime: 1656999610,
			balance: 99672.08
		},
		{
			unixtime: 1656999670,
			balance: 99672.08
		},
		{
			unixtime: 1656999730,
			balance: 99672.08
		},
		{
			unixtime: 1656999884,
			balance: 99672.08
		},
		{
			unixtime: 1656999910,
			balance: 99672.08
		},
		{
			unixtime: 1656999970,
			balance: 99672.08
		},
		{
			unixtime: 1657000032,
			balance: 99672.08
		},
		{
			unixtime: 1657000090,
			balance: 99672.08
		},
		{
			unixtime: 1657000150,
			balance: 99672.08
		},
		{
			unixtime: 1657000210,
			balance: 99672.08
		},
		{
			unixtime: 1657000270,
			balance: 99672.08
		},
		{
			unixtime: 1657000330,
			balance: 99672.08
		},
		{
			unixtime: 1657000390,
			balance: 99672.08
		},
		{
			unixtime: 1657000483,
			balance: 99672.08
		},
		{
			unixtime: 1657000510,
			balance: 99672.08
		},
		{
			unixtime: 1657000570,
			balance: 99672.08
		},
		{
			unixtime: 1657000630,
			balance: 99672.08
		},
		{
			unixtime: 1657000729,
			balance: 99672.08
		},
		{
			unixtime: 1657000750,
			balance: 99672.08
		},
		{
			unixtime: 1657000810,
			balance: 99672.08
		},
		{
			unixtime: 1657000870,
			balance: 99672.08
		},
		{
			unixtime: 1657000930,
			balance: 99672.08
		},
		{
			unixtime: 1657000990,
			balance: 99672.08
		},
		{
			unixtime: 1657001050,
			balance: 99672.08
		},
		{
			unixtime: 1657001110,
			balance: 99672.08
		},
		{
			unixtime: 1657001170,
			balance: 99672.08
		},
		{
			unixtime: 1657001230,
			balance: 99672.08
		},
		{
			unixtime: 1657001290,
			balance: 99672.08
		},
		{
			unixtime: 1657001350,
			balance: 99672.08
		},
		{
			unixtime: 1657001410,
			balance: 99672.08
		},
		{
			unixtime: 1657001470,
			balance: 99672.08
		},
		{
			unixtime: 1657001530,
			balance: 99672.08
		},
		{
			unixtime: 1657001590,
			balance: 99672.08
		},
		{
			unixtime: 1657001650,
			balance: 99672.08
		},
		{
			unixtime: 1657001710,
			balance: 99672.08
		},
		{
			unixtime: 1657001770,
			balance: 99672.08
		},
		{
			unixtime: 1657001830,
			balance: 99672.08
		},
		{
			unixtime: 1657001890,
			balance: 99672.08
		},
		{
			unixtime: 1657001950,
			balance: 99672.08
		},
		{
			unixtime: 1657002010,
			balance: 99672.08
		},
		{
			unixtime: 1657002070,
			balance: 99672.08
		},
		{
			unixtime: 1657002130,
			balance: 99672.08
		},
		{
			unixtime: 1657002190,
			balance: 99672.08
		},
		{
			unixtime: 1657002250,
			balance: 99672.08
		},
		{
			unixtime: 1657002310,
			balance: 99672.08
		},
		{
			unixtime: 1657002370,
			balance: 99672.08
		},
		{
			unixtime: 1657002430,
			balance: 99672.08
		},
		{
			unixtime: 1657002490,
			balance: 99672.08
		},
		{
			unixtime: 1657002550,
			balance: 99672.08
		},
		{
			unixtime: 1657002610,
			balance: 99672.08
		},
		{
			unixtime: 1657002670,
			balance: 99672.08
		},
		{
			unixtime: 1657002730,
			balance: 99672.08
		},
		{
			unixtime: 1657002790,
			balance: 99672.08
		},
		{
			unixtime: 1657002850,
			balance: 99672.08
		},
		{
			unixtime: 1657002910,
			balance: 99672.08
		},
		{
			unixtime: 1657002970,
			balance: 99672.08
		},
		{
			unixtime: 1657003030,
			balance: 99672.08
		},
		{
			unixtime: 1657003090,
			balance: 99672.08
		},
		{
			unixtime: 1657003150,
			balance: 99672.08
		},
		{
			unixtime: 1657003210,
			balance: 99672.08
		},
		{
			unixtime: 1657003270,
			balance: 99672.08
		},
		{
			unixtime: 1657003330,
			balance: 99672.08
		},
		{
			unixtime: 1657003390,
			balance: 99672.08
		},
		{
			unixtime: 1657003450,
			balance: 99672.08
		},
		{
			unixtime: 1657003510,
			balance: 99672.08
		},
		{
			unixtime: 1657003570,
			balance: 99672.08
		},
		{
			unixtime: 1657003630,
			balance: 99672.08
		},
		{
			unixtime: 1657003690,
			balance: 99672.08
		},
		{
			unixtime: 1657003750,
			balance: 99672.08
		},
		{
			unixtime: 1657003810,
			balance: 99672.08
		},
		{
			unixtime: 1657003870,
			balance: 99672.08
		},
		{
			unixtime: 1657003930,
			balance: 99672.08
		},
		{
			unixtime: 1657003990,
			balance: 99672.08
		},
		{
			unixtime: 1657004050,
			balance: 99672.08
		},
		{
			unixtime: 1657004110,
			balance: 99672.08
		},
		{
			unixtime: 1657004170,
			balance: 99672.08
		},
		{
			unixtime: 1657004230,
			balance: 99672.08
		},
		{
			unixtime: 1657004290,
			balance: 99672.08
		},
		{
			unixtime: 1657004350,
			balance: 99672.08
		},
		{
			unixtime: 1657004410,
			balance: 99672.08
		},
		{
			unixtime: 1657004470,
			balance: 99672.08
		},
		{
			unixtime: 1657004530,
			balance: 99672.08
		},
		{
			unixtime: 1657004590,
			balance: 99672.08
		},
		{
			unixtime: 1657004650,
			balance: 99672.08
		},
		{
			unixtime: 1657004710,
			balance: 99672.08
		},
		{
			unixtime: 1657004770,
			balance: 99672.08
		},
		{
			unixtime: 1657004830,
			balance: 99672.08
		},
		{
			unixtime: 1657004890,
			balance: 99672.08
		},
		{
			unixtime: 1657004950,
			balance: 99672.08
		},
		{
			unixtime: 1657005010,
			balance: 99672.08
		},
		{
			unixtime: 1657005070,
			balance: 99672.08
		},
		{
			unixtime: 1657005130,
			balance: 99672.08
		},
		{
			unixtime: 1657005190,
			balance: 99672.08
		},
		{
			unixtime: 1657005250,
			balance: 99672.08
		},
		{
			unixtime: 1657005310,
			balance: 99672.08
		},
		{
			unixtime: 1657005370,
			balance: 99672.08
		},
		{
			unixtime: 1657005430,
			balance: 99672.08
		},
		{
			unixtime: 1657005490,
			balance: 99672.08
		},
		{
			unixtime: 1657005550,
			balance: 99672.08
		},
		{
			unixtime: 1657005610,
			balance: 99672.08
		},
		{
			unixtime: 1657005670,
			balance: 99672.08
		},
		{
			unixtime: 1657005730,
			balance: 99672.08
		},
		{
			unixtime: 1657005790,
			balance: 99672.08
		},
		{
			unixtime: 1657005850,
			balance: 99672.08
		},
		{
			unixtime: 1657005910,
			balance: 99672.08
		},
		{
			unixtime: 1657005970,
			balance: 99672.08
		},
		{
			unixtime: 1657006030,
			balance: 99672.08
		},
		{
			unixtime: 1657006090,
			balance: 99672.08
		},
		{
			unixtime: 1657006150,
			balance: 99672.08
		},
		{
			unixtime: 1657006210,
			balance: 99672.08
		},
		{
			unixtime: 1657006270,
			balance: 99672.08
		},
		{
			unixtime: 1657006330,
			balance: 99672.08
		},
		{
			unixtime: 1657006390,
			balance: 99672.08
		},
		{
			unixtime: 1657006450,
			balance: 99672.08
		},
		{
			unixtime: 1657006510,
			balance: 99672.08
		},
		{
			unixtime: 1657006570,
			balance: 99672.08
		},
		{
			unixtime: 1657006630,
			balance: 99672.08
		},
		{
			unixtime: 1657006690,
			balance: 99672.08
		},
		{
			unixtime: 1657006750,
			balance: 99672.08
		},
		{
			unixtime: 1657006810,
			balance: 99672.08
		},
		{
			unixtime: 1657006870,
			balance: 99672.08
		},
		{
			unixtime: 1657006930,
			balance: 99672.08
		},
		{
			unixtime: 1657006990,
			balance: 99672.08
		},
		{
			unixtime: 1657007050,
			balance: 99672.08
		},
		{
			unixtime: 1657007110,
			balance: 99672.08
		},
		{
			unixtime: 1657007170,
			balance: 99672.08
		},
		{
			unixtime: 1657007230,
			balance: 99672.08
		},
		{
			unixtime: 1657007290,
			balance: 99672.08
		},
		{
			unixtime: 1657007350,
			balance: 99672.08
		},
		{
			unixtime: 1657007410,
			balance: 99672.08
		},
		{
			unixtime: 1657007470,
			balance: 99672.08
		},
		{
			unixtime: 1657007530,
			balance: 99672.08
		},
		{
			unixtime: 1657007590,
			balance: 99672.08
		},
		{
			unixtime: 1657007650,
			balance: 99672.08
		},
		{
			unixtime: 1657007710,
			balance: 99672.08
		},
		{
			unixtime: 1657007770,
			balance: 99672.08
		},
		{
			unixtime: 1657007830,
			balance: 99672.08
		},
		{
			unixtime: 1657007890,
			balance: 99672.08
		},
		{
			unixtime: 1657007950,
			balance: 99672.08
		},
		{
			unixtime: 1657008010,
			balance: 99693.92
		},
		{
			unixtime: 1657008070,
			balance: 99692.76
		},
		{
			unixtime: 1657008130,
			balance: 99697.28
		},
		{
			unixtime: 1657008190,
			balance: 99704.96
		},
		{
			unixtime: 1657008250,
			balance: 99702.66
		},
		{
			unixtime: 1657008310,
			balance: 99703.86
		},
		{
			unixtime: 1657008370,
			balance: 99704.46
		},
		{
			unixtime: 1657008430,
			balance: 99704.86
		},
		{
			unixtime: 1657008490,
			balance: 99701.26
		},
		{
			unixtime: 1657008550,
			balance: 99695.96
		},
		{
			unixtime: 1657008610,
			balance: 99689.76
		},
		{
			unixtime: 1657008670,
			balance: 99682.82
		},
		{
			unixtime: 1657008730,
			balance: 99682.68
		},
		{
			unixtime: 1657008790,
			balance: 99684.24
		},
		{
			unixtime: 1657008850,
			balance: 99678.76
		},
		{
			unixtime: 1657008910,
			balance: 99679.2
		},
		{
			unixtime: 1657008970,
			balance: 99678.26
		},
		{
			unixtime: 1657009030,
			balance: 99676.26
		},
		{
			unixtime: 1657009090,
			balance: 99675.26
		},
		{
			unixtime: 1657009150,
			balance: 99677.62
		},
		{
			unixtime: 1657009210,
			balance: 99676.26
		},
		{
			unixtime: 1657009270,
			balance: 99678.06
		},
		{
			unixtime: 1657009330,
			balance: 99675.46
		},
		{
			unixtime: 1657009390,
			balance: 99674.06
		},
		{
			unixtime: 1657009450,
			balance: 99671.36
		},
		{
			unixtime: 1657009510,
			balance: 99667.14
		},
		{
			unixtime: 1657009570,
			balance: 99665.36
		},
		{
			unixtime: 1657009630,
			balance: 99665.78
		},
		{
			unixtime: 1657009690,
			balance: 99659.06
		},
		{
			unixtime: 1657009750,
			balance: 99660.56
		},
		{
			unixtime: 1657009810,
			balance: 99661.56
		},
		{
			unixtime: 1657009870,
			balance: 99659.46
		},
		{
			unixtime: 1657009930,
			balance: 99665.06
		},
		{
			unixtime: 1657009990,
			balance: 99664.96
		},
		{
			unixtime: 1657010050,
			balance: 99663.06
		},
		{
			unixtime: 1657010110,
			balance: 99663.64
		},
		{
			unixtime: 1657010170,
			balance: 99663.24
		},
		{
			unixtime: 1657010230,
			balance: 99663.24
		},
		{
			unixtime: 1657010290,
			balance: 99663.14
		},
		{
			unixtime: 1657010350,
			balance: 99661.44
		},
		{
			unixtime: 1657010410,
			balance: 99660.34
		},
		{
			unixtime: 1657010470,
			balance: 99655.06
		},
		{
			unixtime: 1657010530,
			balance: 99659.64
		},
		{
			unixtime: 1657010590,
			balance: 99661.06
		},
		{
			unixtime: 1657010650,
			balance: 99661.26
		},
		{
			unixtime: 1657010710,
			balance: 99659.26
		},
		{
			unixtime: 1657010770,
			balance: 99659.3
		},
		{
			unixtime: 1657010830,
			balance: 99659.3
		},
		{
			unixtime: 1657010890,
			balance: 99659.26
		},
		{
			unixtime: 1657010950,
			balance: 99654.76
		},
		{
			unixtime: 1657011010,
			balance: 99613.96
		},
		{
			unixtime: 1657011070,
			balance: 99612.76
		},
		{
			unixtime: 1657011130,
			balance: 99614.26
		},
		{
			unixtime: 1657011190,
			balance: 99615.56
		},
		{
			unixtime: 1657011250,
			balance: 99616.56
		},
		{
			unixtime: 1657011310,
			balance: 99616.06
		},
		{
			unixtime: 1657011370,
			balance: 99614.86
		},
		{
			unixtime: 1657011430,
			balance: 99614.86
		},
		{
			unixtime: 1657011490,
			balance: 99616.46
		},
		{
			unixtime: 1657011550,
			balance: 99616.36
		},
		{
			unixtime: 1657011610,
			balance: 99614.96
		},
		{
			unixtime: 1657011670,
			balance: 99614.66
		},
		{
			unixtime: 1657011730,
			balance: 99613.26
		},
		{
			unixtime: 1657011790,
			balance: 99614.86
		},
		{
			unixtime: 1657011850,
			balance: 99618.02
		},
		{
			unixtime: 1657011910,
			balance: 99618.02
		},
		{
			unixtime: 1657011970,
			balance: 99617.12
		},
		{
			unixtime: 1657012030,
			balance: 99617.72
		},
		{
			unixtime: 1657012090,
			balance: 99616.82
		},
		{
			unixtime: 1657012150,
			balance: 99615.82
		},
		{
			unixtime: 1657012210,
			balance: 99613.42
		},
		{
			unixtime: 1657012270,
			balance: 99612.52
		},
		{
			unixtime: 1657012330,
			balance: 99612.52
		},
		{
			unixtime: 1657012390,
			balance: 99600.72
		},
		{
			unixtime: 1657012450,
			balance: 99606.36
		},
		{
			unixtime: 1657012510,
			balance: 99606.16
		},
		{
			unixtime: 1657012570,
			balance: 99607.76
		},
		{
			unixtime: 1657012630,
			balance: 99608.46
		},
		{
			unixtime: 1657012690,
			balance: 99608.46
		},
		{
			unixtime: 1657012750,
			balance: 99606.06
		},
		{
			unixtime: 1657012810,
			balance: 99605.86
		},
		{
			unixtime: 1657012870,
			balance: 99604.76
		},
		{
			unixtime: 1657012930,
			balance: 99604.96
		},
		{
			unixtime: 1657012990,
			balance: 99606.16
		},
		{
			unixtime: 1657013050,
			balance: 99605.7
		},
		{
			unixtime: 1657013110,
			balance: 99611.7
		},
		{
			unixtime: 1657013170,
			balance: 99611.7
		},
		{
			unixtime: 1657013230,
			balance: 99611.9
		},
		{
			unixtime: 1657013290,
			balance: 99610.7
		},
		{
			unixtime: 1657013350,
			balance: 99609.7
		},
		{
			unixtime: 1657013410,
			balance: 99609.3
		},
		{
			unixtime: 1657013470,
			balance: 99608
		},
		{
			unixtime: 1657013530,
			balance: 99609.5
		},
		{
			unixtime: 1657013590,
			balance: 99609.7
		},
		{
			unixtime: 1657013650,
			balance: 99606.6
		},
		{
			unixtime: 1657013710,
			balance: 99605.68
		},
		{
			unixtime: 1657013770,
			balance: 99612.02
		},
		{
			unixtime: 1657013830,
			balance: 99612.12
		},
		{
			unixtime: 1657013890,
			balance: 99612.12
		},
		{
			unixtime: 1657013950,
			balance: 99612.82
		},
		{
			unixtime: 1657014010,
			balance: 99615.26
		},
		{
			unixtime: 1657014070,
			balance: 99615.26
		},
		{
			unixtime: 1657014130,
			balance: 99615.16
		},
		{
			unixtime: 1657014190,
			balance: 99614.96
		},
		{
			unixtime: 1657014250,
			balance: 99614.96
		},
		{
			unixtime: 1657014310,
			balance: 99616.36
		},
		{
			unixtime: 1657014370,
			balance: 99616.36
		},
		{
			unixtime: 1657014430,
			balance: 99614.56
		},
		{
			unixtime: 1657014490,
			balance: 99614.56
		},
		{
			unixtime: 1657014550,
			balance: 99614.56
		},
		{
			unixtime: 1657014610,
			balance: 99614.56
		},
		{
			unixtime: 1657014670,
			balance: 99614.56
		},
		{
			unixtime: 1657014730,
			balance: 99614.56
		},
		{
			unixtime: 1657014790,
			balance: 99611.26
		},
		{
			unixtime: 1657014850,
			balance: 99612.46
		},
		{
			unixtime: 1657014910,
			balance: 99612.46
		},
		{
			unixtime: 1657014970,
			balance: 99614.36
		},
		{
			unixtime: 1657015030,
			balance: 99615.56
		},
		{
			unixtime: 1657015090,
			balance: 99616.86
		},
		{
			unixtime: 1657015150,
			balance: 99616.26
		},
		{
			unixtime: 1657015210,
			balance: 99615.36
		},
		{
			unixtime: 1657015270,
			balance: 99615.86
		},
		{
			unixtime: 1657015330,
			balance: 99615.86
		},
		{
			unixtime: 1657015390,
			balance: 99615.56
		},
		{
			unixtime: 1657015450,
			balance: 99615.56
		},
		{
			unixtime: 1657015510,
			balance: 99618.96
		},
		{
			unixtime: 1657015570,
			balance: 99619.76
		},
		{
			unixtime: 1657015630,
			balance: 99621.36
		},
		{
			unixtime: 1657015690,
			balance: 99621.06
		},
		{
			unixtime: 1657015750,
			balance: 99622.36
		},
		{
			unixtime: 1657015810,
			balance: 99626.26
		},
		{
			unixtime: 1657015870,
			balance: 99628.44
		},
		{
			unixtime: 1657015930,
			balance: 99627.94
		},
		{
			unixtime: 1657015990,
			balance: 99628.84
		},
		{
			unixtime: 1657016050,
			balance: 99629.44
		},
		{
			unixtime: 1657016110,
			balance: 99629.84
		},
		{
			unixtime: 1657016170,
			balance: 99628.66
		},
		{
			unixtime: 1657016230,
			balance: 99622.86
		},
		{
			unixtime: 1657016290,
			balance: 99622.96
		},
		{
			unixtime: 1657016350,
			balance: 99623.56
		},
		{
			unixtime: 1657016410,
			balance: 99621.66
		},
		{
			unixtime: 1657016470,
			balance: 99622.16
		},
		{
			unixtime: 1657016530,
			balance: 99622.14
		},
		{
			unixtime: 1657016590,
			balance: 99626.36
		},
		{
			unixtime: 1657016650,
			balance: 99627.56
		},
		{
			unixtime: 1657016710,
			balance: 99626.66
		},
		{
			unixtime: 1657016770,
			balance: 99625.94
		},
		{
			unixtime: 1657016830,
			balance: 99625.64
		},
		{
			unixtime: 1657016890,
			balance: 99625.14
		},
		{
			unixtime: 1657016950,
			balance: 99625.44
		},
		{
			unixtime: 1657017010,
			balance: 99624.44
		},
		{
			unixtime: 1657017070,
			balance: 99624.44
		},
		{
			unixtime: 1657017130,
			balance: 99625.64
		},
		{
			unixtime: 1657017190,
			balance: 99625.04
		},
		{
			unixtime: 1657017250,
			balance: 99625.04
		},
		{
			unixtime: 1657017310,
			balance: 99625.54
		},
		{
			unixtime: 1657017370,
			balance: 99628.66
		},
		{
			unixtime: 1657017430,
			balance: 99628.86
		},
		{
			unixtime: 1657017490,
			balance: 99627.66
		},
		{
			unixtime: 1657017550,
			balance: 99629.86
		},
		{
			unixtime: 1657017610,
			balance: 99629.06
		},
		{
			unixtime: 1657017670,
			balance: 99628.22
		},
		{
			unixtime: 1657017730,
			balance: 99626.46
		},
		{
			unixtime: 1657017790,
			balance: 99625.46
		},
		{
			unixtime: 1657017850,
			balance: 99625.46
		},
		{
			unixtime: 1657017910,
			balance: 99623.96
		},
		{
			unixtime: 1657017970,
			balance: 99623.96
		},
		{
			unixtime: 1657018030,
			balance: 99623.96
		},
		{
			unixtime: 1657018090,
			balance: 99623.96
		},
		{
			unixtime: 1657018150,
			balance: 99623.96
		},
		{
			unixtime: 1657018210,
			balance: 99626.86
		},
		{
			unixtime: 1657018270,
			balance: 99627.36
		},
		{
			unixtime: 1657018330,
			balance: 99628.76
		},
		{
			unixtime: 1657018390,
			balance: 99628.66
		},
		{
			unixtime: 1657018450,
			balance: 99642.66
		},
		{
			unixtime: 1657018510,
			balance: 99644.26
		},
		{
			unixtime: 1657018570,
			balance: 99643.16
		},
		{
			unixtime: 1657018630,
			balance: 99641.46
		},
		{
			unixtime: 1657018690,
			balance: 99640.36
		},
		{
			unixtime: 1657018750,
			balance: 99640.36
		},
		{
			unixtime: 1657018810,
			balance: 99636.66
		},
		{
			unixtime: 1657018870,
			balance: 99636.76
		},
		{
			unixtime: 1657018930,
			balance: 99637.76
		},
		{
			unixtime: 1657018990,
			balance: 99635.76
		},
		{
			unixtime: 1657019050,
			balance: 99634.86
		},
		{
			unixtime: 1657019110,
			balance: 99633.96
		},
		{
			unixtime: 1657019170,
			balance: 99638.96
		},
		{
			unixtime: 1657019230,
			balance: 99638.96
		},
		{
			unixtime: 1657019290,
			balance: 99642.96
		},
		{
			unixtime: 1657019350,
			balance: 99640.98
		},
		{
			unixtime: 1657019410,
			balance: 99635.98
		},
		{
			unixtime: 1657019470,
			balance: 99634.88
		},
		{
			unixtime: 1657019530,
			balance: 99636.08
		},
		{
			unixtime: 1657019590,
			balance: 99635.36
		},
		{
			unixtime: 1657019650,
			balance: 99633.06
		},
		{
			unixtime: 1657019710,
			balance: 99628.6
		},
		{
			unixtime: 1657019770,
			balance: 99629.76
		},
		{
			unixtime: 1657019830,
			balance: 99626.26
		},
		{
			unixtime: 1657019890,
			balance: 99616.34
		},
		{
			unixtime: 1657019950,
			balance: 99615.28
		},
		{
			unixtime: 1657020010,
			balance: 99615.38
		},
		{
			unixtime: 1657020070,
			balance: 99619.86
		},
		{
			unixtime: 1657020130,
			balance: 99619.26
		},
		{
			unixtime: 1657020190,
			balance: 99618.76
		},
		{
			unixtime: 1657020250,
			balance: 99618.56
		},
		{
			unixtime: 1657020310,
			balance: 99619.46
		},
		{
			unixtime: 1657020370,
			balance: 99619.52
		},
		{
			unixtime: 1657020430,
			balance: 99619.32
		},
		{
			unixtime: 1657020490,
			balance: 99617.96
		},
		{
			unixtime: 1657020550,
			balance: 99618.56
		},
		{
			unixtime: 1657020610,
			balance: 99618.56
		},
		{
			unixtime: 1657020670,
			balance: 99617
		},
		{
			unixtime: 1657020730,
			balance: 99617.5
		},
		{
			unixtime: 1657020790,
			balance: 99617.46
		},
		{
			unixtime: 1657020850,
			balance: 99617.66
		},
		{
			unixtime: 1657020910,
			balance: 99613.4
		},
		{
			unixtime: 1657020970,
			balance: 99614.26
		},
		{
			unixtime: 1657021030,
			balance: 99614.06
		},
		{
			unixtime: 1657021090,
			balance: 99615.76
		},
		{
			unixtime: 1657021150,
			balance: 99619.76
		},
		{
			unixtime: 1657021210,
			balance: 99617.76
		},
		{
			unixtime: 1657021270,
			balance: 99619.26
		},
		{
			unixtime: 1657021330,
			balance: 99617.66
		},
		{
			unixtime: 1657021390,
			balance: 99617.36
		},
		{
			unixtime: 1657021450,
			balance: 99615.06
		},
		{
			unixtime: 1657021510,
			balance: 99614.56
		},
		{
			unixtime: 1657021570,
			balance: 99610.26
		},
		{
			unixtime: 1657021630,
			balance: 99610.16
		},
		{
			unixtime: 1657021690,
			balance: 99609.36
		},
		{
			unixtime: 1657021750,
			balance: 99605.36
		},
		{
			unixtime: 1657021810,
			balance: 99598.86
		},
		{
			unixtime: 1657021870,
			balance: 99601.46
		},
		{
			unixtime: 1657021930,
			balance: 99599.38
		},
		{
			unixtime: 1657021990,
			balance: 99598.76
		},
		{
			unixtime: 1657022050,
			balance: 99593.36
		},
		{
			unixtime: 1657022110,
			balance: 99592.34
		},
		{
			unixtime: 1657022170,
			balance: 99590.46
		},
		{
			unixtime: 1657022230,
			balance: 99587.78
		},
		{
			unixtime: 1657022290,
			balance: 99589.98
		},
		{
			unixtime: 1657022350,
			balance: 99587.18
		},
		{
			unixtime: 1657022410,
			balance: 99588.96
		},
		{
			unixtime: 1657022470,
			balance: 99589.3
		},
		{
			unixtime: 1657022530,
			balance: 99581.56
		},
		{
			unixtime: 1657022590,
			balance: 99581.96
		},
		{
			unixtime: 1657022650,
			balance: 99575.38
		},
		{
			unixtime: 1657022710,
			balance: 99574.1
		},
		{
			unixtime: 1657022770,
			balance: 99573.16
		},
		{
			unixtime: 1657022830,
			balance: 99567.96
		},
		{
			unixtime: 1657022890,
			balance: 99560.68
		},
		{
			unixtime: 1657022950,
			balance: 99559.16
		},
		{
			unixtime: 1657023010,
			balance: 99568.54
		},
		{
			unixtime: 1657023070,
			balance: 99566.86
		},
		{
			unixtime: 1657023130,
			balance: 99559.5
		},
		{
			unixtime: 1657023190,
			balance: 99554.76
		},
		{
			unixtime: 1657023250,
			balance: 99560.54
		},
		{
			unixtime: 1657023310,
			balance: 99558.62
		},
		{
			unixtime: 1657023370,
			balance: 99553.04
		},
		{
			unixtime: 1657023430,
			balance: 99556.96
		},
		{
			unixtime: 1657023490,
			balance: 99556.42
		},
		{
			unixtime: 1657023551,
			balance: 99559.26
		},
		{
			unixtime: 1657023610,
			balance: 99557.68
		},
		{
			unixtime: 1657023670,
			balance: 99561.5
		},
		{
			unixtime: 1657023730,
			balance: 99555.66
		},
		{
			unixtime: 1657023790,
			balance: 99559.36
		},
		{
			unixtime: 1657023850,
			balance: 99562.86
		},
		{
			unixtime: 1657023910,
			balance: 99562.02
		},
		{
			unixtime: 1657023970,
			balance: 99564.56
		},
		{
			unixtime: 1657024030,
			balance: 99563.58
		},
		{
			unixtime: 1657024090,
			balance: 99564.8
		},
		{
			unixtime: 1657024150,
			balance: 99564.34
		},
		{
			unixtime: 1657024210,
			balance: 99562.26
		},
		{
			unixtime: 1657024270,
			balance: 99557.26
		},
		{
			unixtime: 1657024330,
			balance: 99558.06
		},
		{
			unixtime: 1657024390,
			balance: 99556.68
		},
		{
			unixtime: 1657024450,
			balance: 99554.92
		},
		{
			unixtime: 1657024510,
			balance: 99554.66
		},
		{
			unixtime: 1657024570,
			balance: 99550.6
		},
		{
			unixtime: 1657024630,
			balance: 99549.94
		},
		{
			unixtime: 1657024690,
			balance: 99547.26
		},
		{
			unixtime: 1657024750,
			balance: 99547.8
		},
		{
			unixtime: 1657024810,
			balance: 99556.06
		},
		{
			unixtime: 1657024870,
			balance: 99553.3
		},
		{
			unixtime: 1657024930,
			balance: 99550.66
		},
		{
			unixtime: 1657024990,
			balance: 99553.56
		},
		{
			unixtime: 1657025050,
			balance: 99551.62
		},
		{
			unixtime: 1657025110,
			balance: 99549.1
		},
		{
			unixtime: 1657025170,
			balance: 99550.36
		},
		{
			unixtime: 1657025230,
			balance: 99552.96
		},
		{
			unixtime: 1657025290,
			balance: 99548.1
		},
		{
			unixtime: 1657025350,
			balance: 99551.96
		},
		{
			unixtime: 1657025410,
			balance: 99551.16
		},
		{
			unixtime: 1657025470,
			balance: 99547.44
		},
		{
			unixtime: 1657025530,
			balance: 99546.06
		},
		{
			unixtime: 1657025590,
			balance: 99546.96
		},
		{
			unixtime: 1657025650,
			balance: 99543.98
		},
		{
			unixtime: 1657025710,
			balance: 99539.2
		},
		{
			unixtime: 1657025770,
			balance: 99537.26
		},
		{
			unixtime: 1657025830,
			balance: 99533.16
		},
		{
			unixtime: 1657025890,
			balance: 99529.36
		},
		{
			unixtime: 1657025950,
			balance: 99521.4
		},
		{
			unixtime: 1657026010,
			balance: 99526.6
		},
		{
			unixtime: 1657026070,
			balance: 99526.86
		},
		{
			unixtime: 1657026130,
			balance: 99521.66
		},
		{
			unixtime: 1657026190,
			balance: 99521.76
		},
		{
			unixtime: 1657026250,
			balance: 99519.22
		},
		{
			unixtime: 1657026310,
			balance: 99520.22
		},
		{
			unixtime: 1657026370,
			balance: 99515.76
		},
		{
			unixtime: 1657026430,
			balance: 99521.76
		},
		{
			unixtime: 1657026490,
			balance: 99520.08
		},
		{
			unixtime: 1657026550,
			balance: 99521.16
		},
		{
			unixtime: 1657026610,
			balance: 99522.96
		},
		{
			unixtime: 1657026670,
			balance: 99529.66
		},
		{
			unixtime: 1657026730,
			balance: 99528.96
		},
		{
			unixtime: 1657026790,
			balance: 99531.76
		},
		{
			unixtime: 1657026850,
			balance: 99528.16
		},
		{
			unixtime: 1657026910,
			balance: 99533.96
		},
		{
			unixtime: 1657026970,
			balance: 99528.16
		},
		{
			unixtime: 1657027030,
			balance: 99526.96
		},
		{
			unixtime: 1657027090,
			balance: 99521.36
		},
		{
			unixtime: 1657027150,
			balance: 99518.86
		},
		{
			unixtime: 1657027210,
			balance: 99519.56
		},
		{
			unixtime: 1657027270,
			balance: 99523.96
		},
		{
			unixtime: 1657027330,
			balance: 99521.76
		},
		{
			unixtime: 1657027390,
			balance: 99520.66
		},
		{
			unixtime: 1657027450,
			balance: 99520.86
		},
		{
			unixtime: 1657027510,
			balance: 99522.96
		},
		{
			unixtime: 1657027570,
			balance: 99521.06
		},
		{
			unixtime: 1657027630,
			balance: 99518.76
		},
		{
			unixtime: 1657027690,
			balance: 99520.6
		},
		{
			unixtime: 1657027750,
			balance: 99523.54
		},
		{
			unixtime: 1657027811,
			balance: 99513.76
		},
		{
			unixtime: 1657027870,
			balance: 99488.7
		},
		{
			unixtime: 1657027930,
			balance: 99470.08
		},
		{
			unixtime: 1657027990,
			balance: 99470.66
		},
		{
			unixtime: 1657028050,
			balance: 99477.12
		},
		{
			unixtime: 1657028110,
			balance: 99464.06
		},
		{
			unixtime: 1657028170,
			balance: 99464.36
		},
		{
			unixtime: 1657028230,
			balance: 99461.14
		},
		{
			unixtime: 1657028290,
			balance: 99454.54
		},
		{
			unixtime: 1657028350,
			balance: 99445.78
		},
		{
			unixtime: 1657028410,
			balance: 99441.5
		},
		{
			unixtime: 1657028470,
			balance: 99447.44
		},
		{
			unixtime: 1657028530,
			balance: 99462.94
		},
		{
			unixtime: 1657028590,
			balance: 99458.5
		},
		{
			unixtime: 1657028650,
			balance: 99460.3
		},
		{
			unixtime: 1657028710,
			balance: 99446.22
		},
		{
			unixtime: 1657028770,
			balance: 99467.18
		},
		{
			unixtime: 1657028830,
			balance: 99459.72
		},
		{
			unixtime: 1657028890,
			balance: 99473.1
		},
		{
			unixtime: 1657028950,
			balance: 99468.18
		},
		{
			unixtime: 1657029010,
			balance: 99457.68
		},
		{
			unixtime: 1657029070,
			balance: 99438.12
		},
		{
			unixtime: 1657029130,
			balance: 99443.8
		},
		{
			unixtime: 1657029190,
			balance: 99438.26
		},
		{
			unixtime: 1657029250,
			balance: 99434.68
		},
		{
			unixtime: 1657029310,
			balance: 99440.72
		},
		{
			unixtime: 1657029370,
			balance: 99431.46
		},
		{
			unixtime: 1657029430,
			balance: 99427.06
		},
		{
			unixtime: 1657029490,
			balance: 99445.92
		},
		{
			unixtime: 1657029550,
			balance: 99447.5
		},
		{
			unixtime: 1657029610,
			balance: 99433.12
		},
		{
			unixtime: 1657029671,
			balance: 99453.84
		},
		{
			unixtime: 1657029730,
			balance: 99445.4
		},
		{
			unixtime: 1657029790,
			balance: 99447.78
		},
		{
			unixtime: 1657029850,
			balance: 99431.32
		},
		{
			unixtime: 1657029910,
			balance: 99427.3
		},
		{
			unixtime: 1657029970,
			balance: 99436.52
		},
		{
			unixtime: 1657030030,
			balance: 99439.26
		},
		{
			unixtime: 1657030090,
			balance: 99435.28
		},
		{
			unixtime: 1657030150,
			balance: 99442.88
		},
		{
			unixtime: 1657030210,
			balance: 99445.52
		},
		{
			unixtime: 1657030270,
			balance: 99466.28
		},
		{
			unixtime: 1657030330,
			balance: 99470.56
		},
		{
			unixtime: 1657030390,
			balance: 99481.54
		},
		{
			unixtime: 1657030450,
			balance: 99484.46
		},
		{
			unixtime: 1657030510,
			balance: 99478.42
		},
		{
			unixtime: 1657030570,
			balance: 99470.12
		},
		{
			unixtime: 1657030631,
			balance: 99472.46
		},
		{
			unixtime: 1657030690,
			balance: 99473.22
		},
		{
			unixtime: 1657030750,
			balance: 99465.08
		},
		{
			unixtime: 1657030810,
			balance: 99459.4
		},
		{
			unixtime: 1657030871,
			balance: 99470.34
		},
		{
			unixtime: 1657030930,
			balance: 99465.16
		},
		{
			unixtime: 1657030990,
			balance: 99455.08
		},
		{
			unixtime: 1657031050,
			balance: 99461.24
		},
		{
			unixtime: 1657031110,
			balance: 99454.24
		},
		{
			unixtime: 1657031170,
			balance: 99451.84
		},
		{
			unixtime: 1657031230,
			balance: 99443.54
		},
		{
			unixtime: 1657031290,
			balance: 99465.4
		},
		{
			unixtime: 1657031350,
			balance: 99453.8
		},
		{
			unixtime: 1657031410,
			balance: 99462.32
		},
		{
			unixtime: 1657031470,
			balance: 99463.46
		},
		{
			unixtime: 1657031530,
			balance: 99469.18
		},
		{
			unixtime: 1657031591,
			balance: 99472.2
		},
		{
			unixtime: 1657031651,
			balance: 99470.94
		},
		{
			unixtime: 1657031710,
			balance: 99472.01
		},
		{
			unixtime: 1657031770,
			balance: 99490.53
		},
		{
			unixtime: 1657031830,
			balance: 99509.09
		},
		{
			unixtime: 1657031890,
			balance: 99513.71
		},
		{
			unixtime: 1657031951,
			balance: 99519.91
		},
		{
			unixtime: 1657032010,
			balance: 99516.29
		},
		{
			unixtime: 1657032070,
			balance: 99515.81
		},
		{
			unixtime: 1657032131,
			balance: 99527.47
		},
		{
			unixtime: 1657032190,
			balance: 99512.73
		},
		{
			unixtime: 1657032250,
			balance: 99502.45
		},
		{
			unixtime: 1657032310,
			balance: 99486.93
		},
		{
			unixtime: 1657032371,
			balance: 99482.57
		},
		{
			unixtime: 1657032431,
			balance: 99474.29
		},
		{
			unixtime: 1657032491,
			balance: 99472.39
		},
		{
			unixtime: 1657032551,
			balance: 99476.97
		},
		{
			unixtime: 1657032611,
			balance: 99478.41
		},
		{
			unixtime: 1657032671,
			balance: 99472.09
		},
		{
			unixtime: 1657032731,
			balance: 99480.69
		},
		{
			unixtime: 1657032791,
			balance: 99474.81
		},
		{
			unixtime: 1657032851,
			balance: 99472.55
		},
		{
			unixtime: 1657032911,
			balance: 99471.43
		},
		{
			unixtime: 1657032971,
			balance: 99469.79
		},
		{
			unixtime: 1657033031,
			balance: 99473.11
		},
		{
			unixtime: 1657033091,
			balance: 99482.11
		},
		{
			unixtime: 1657033151,
			balance: 99483.65
		},
		{
			unixtime: 1657033211,
			balance: 99477.29
		},
		{
			unixtime: 1657033271,
			balance: 99458.17
		},
		{
			unixtime: 1657033331,
			balance: 99460.21
		},
		{
			unixtime: 1657033391,
			balance: 99459.13
		},
		{
			unixtime: 1657033451,
			balance: 99471.25
		},
		{
			unixtime: 1657033511,
			balance: 99467.87
		},
		{
			unixtime: 1657033571,
			balance: 99459.99
		},
		{
			unixtime: 1657033631,
			balance: 99472.43
		},
		{
			unixtime: 1657033691,
			balance: 99474.83
		},
		{
			unixtime: 1657033751,
			balance: 99466.51
		},
		{
			unixtime: 1657033811,
			balance: 99473.57
		},
		{
			unixtime: 1657033871,
			balance: 99463.59
		},
		{
			unixtime: 1657033931,
			balance: 99452.79
		},
		{
			unixtime: 1657033991,
			balance: 99459.37
		},
		{
			unixtime: 1657034051,
			balance: 99454.45
		},
		{
			unixtime: 1657034111,
			balance: 99462.95
		},
		{
			unixtime: 1657034171,
			balance: 99463.61
		},
		{
			unixtime: 1657034231,
			balance: 99466.47
		},
		{
			unixtime: 1657034291,
			balance: 99467.39
		},
		{
			unixtime: 1657034351,
			balance: 99459.05
		},
		{
			unixtime: 1657034411,
			balance: 99465.29
		},
		{
			unixtime: 1657034471,
			balance: 99468.21
		},
		{
			unixtime: 1657034531,
			balance: 99464.33
		},
		{
			unixtime: 1657034591,
			balance: 99455.33
		},
		{
			unixtime: 1657034651,
			balance: 99452.01
		},
		{
			unixtime: 1657034711,
			balance: 99455.85
		},
		{
			unixtime: 1657034771,
			balance: 99448.87
		},
		{
			unixtime: 1657034831,
			balance: 99455.49
		},
		{
			unixtime: 1657034891,
			balance: 99455.17
		},
		{
			unixtime: 1657034951,
			balance: 99463.15
		},
		{
			unixtime: 1657035011,
			balance: 99454.47
		},
		{
			unixtime: 1657035071,
			balance: 99453.67
		},
		{
			unixtime: 1657035131,
			balance: 99457.05
		},
		{
			unixtime: 1657035191,
			balance: 99472.47
		},
		{
			unixtime: 1657035251,
			balance: 99464.47
		},
		{
			unixtime: 1657035311,
			balance: 99462.11
		},
		{
			unixtime: 1657035371,
			balance: 99461.41
		},
		{
			unixtime: 1657035431,
			balance: 99453.61
		},
		{
			unixtime: 1657035491,
			balance: 99450.81
		},
		{
			unixtime: 1657035551,
			balance: 99448.21
		},
		{
			unixtime: 1657035611,
			balance: 99453.81
		},
		{
			unixtime: 1657035671,
			balance: 99460.37
		},
		{
			unixtime: 1657035731,
			balance: 99469.09
		},
		{
			unixtime: 1657035791,
			balance: 99479.65
		},
		{
			unixtime: 1657035851,
			balance: 99483.65
		},
		{
			unixtime: 1657035911,
			balance: 99483.33
		},
		{
			unixtime: 1657035971,
			balance: 99492.55
		},
		{
			unixtime: 1657036031,
			balance: 99497.29
		},
		{
			unixtime: 1657036091,
			balance: 99496.43
		},
		{
			unixtime: 1657036151,
			balance: 99492.37
		},
		{
			unixtime: 1657036211,
			balance: 99496.79
		},
		{
			unixtime: 1657036271,
			balance: 99506.11
		},
		{
			unixtime: 1657036331,
			balance: 99500.47
		},
		{
			unixtime: 1657036391,
			balance: 99497.89
		},
		{
			unixtime: 1657036451,
			balance: 99507.25
		},
		{
			unixtime: 1657036511,
			balance: 99510.95
		},
		{
			unixtime: 1657036571,
			balance: 99511.69
		},
		{
			unixtime: 1657036631,
			balance: 99513.93
		},
		{
			unixtime: 1657036691,
			balance: 99504.39
		},
		{
			unixtime: 1657036751,
			balance: 99502.79
		},
		{
			unixtime: 1657036811,
			balance: 99501.43
		},
		{
			unixtime: 1657036871,
			balance: 99510.33
		},
		{
			unixtime: 1657036931,
			balance: 99523.73
		},
		{
			unixtime: 1657036991,
			balance: 99510.85
		},
		{
			unixtime: 1657037051,
			balance: 99510.17
		},
		{
			unixtime: 1657037111,
			balance: 99512.61
		},
		{
			unixtime: 1657037171,
			balance: 99505.59
		},
		{
			unixtime: 1657037231,
			balance: 99509.19
		},
		{
			unixtime: 1657037291,
			balance: 99517.83
		},
		{
			unixtime: 1657037351,
			balance: 99520.97
		},
		{
			unixtime: 1657037411,
			balance: 99518.89
		},
		{
			unixtime: 1657037471,
			balance: 99530.79
		},
		{
			unixtime: 1657037531,
			balance: 99532.33
		},
		{
			unixtime: 1657037591,
			balance: 99529.43
		},
		{
			unixtime: 1657037651,
			balance: 99509.87
		},
		{
			unixtime: 1657037711,
			balance: 99510.75
		},
		{
			unixtime: 1657037771,
			balance: 99506.91
		},
		{
			unixtime: 1657037831,
			balance: 99505.57
		},
		{
			unixtime: 1657037891,
			balance: 99521.95
		},
		{
			unixtime: 1657037951,
			balance: 99522.59
		},
		{
			unixtime: 1657038011,
			balance: 99525.27
		},
		{
			unixtime: 1657038071,
			balance: 99520.59
		},
		{
			unixtime: 1657038131,
			balance: 99527.03
		},
		{
			unixtime: 1657038191,
			balance: 99536.25
		},
		{
			unixtime: 1657038251,
			balance: 99549.85
		},
		{
			unixtime: 1657038311,
			balance: 99553.23
		},
		{
			unixtime: 1657038371,
			balance: 99553.73
		},
		{
			unixtime: 1657038431,
			balance: 99549.59
		},
		{
			unixtime: 1657038491,
			balance: 99554.95
		},
		{
			unixtime: 1657038551,
			balance: 99543.29
		},
		{
			unixtime: 1657038611,
			balance: 99549.25
		},
		{
			unixtime: 1657038671,
			balance: 99547.57
		},
		{
			unixtime: 1657038731,
			balance: 99542.57
		},
		{
			unixtime: 1657038791,
			balance: 99538.13
		},
		{
			unixtime: 1657038851,
			balance: 99544.01
		},
		{
			unixtime: 1657038911,
			balance: 99543.01
		},
		{
			unixtime: 1657038971,
			balance: 99545.27
		},
		{
			unixtime: 1657039031,
			balance: 99548.15
		},
		{
			unixtime: 1657039091,
			balance: 99551.71
		},
		{
			unixtime: 1657039151,
			balance: 99552.77
		},
		{
			unixtime: 1657039211,
			balance: 99546.65
		},
		{
			unixtime: 1657039271,
			balance: 99551.39
		},
		{
			unixtime: 1657039331,
			balance: 99541.45
		},
		{
			unixtime: 1657039391,
			balance: 99540.35
		},
		{
			unixtime: 1657039451,
			balance: 99530.87
		},
		{
			unixtime: 1657039511,
			balance: 99528.87
		},
		{
			unixtime: 1657039571,
			balance: 99523.81
		},
		{
			unixtime: 1657039631,
			balance: 99514.27
		},
		{
			unixtime: 1657039691,
			balance: 99519.71
		},
		{
			unixtime: 1657039751,
			balance: 99521.63
		},
		{
			unixtime: 1657039811,
			balance: 99518.73
		},
		{
			unixtime: 1657039871,
			balance: 99512.89
		},
		{
			unixtime: 1657039931,
			balance: 99514.97
		},
		{
			unixtime: 1657039991,
			balance: 99519.59
		},
		{
			unixtime: 1657040051,
			balance: 99519.01
		},
		{
			unixtime: 1657040111,
			balance: 99522.17
		},
		{
			unixtime: 1657040171,
			balance: 99518.89
		},
		{
			unixtime: 1657040231,
			balance: 99516.53
		},
		{
			unixtime: 1657040291,
			balance: 99519.33
		},
		{
			unixtime: 1657040351,
			balance: 99520.19
		},
		{
			unixtime: 1657040411,
			balance: 99519.95
		},
		{
			unixtime: 1657040471,
			balance: 99525.29
		},
		{
			unixtime: 1657040531,
			balance: 99522.73
		},
		{
			unixtime: 1657040591,
			balance: 99528.47
		},
		{
			unixtime: 1657040651,
			balance: 99531.47
		},
		{
			unixtime: 1657040711,
			balance: 99540.23
		},
		{
			unixtime: 1657040771,
			balance: 99525.33
		},
		{
			unixtime: 1657040831,
			balance: 99522.19
		},
		{
			unixtime: 1657040891,
			balance: 99519.59
		},
		{
			unixtime: 1657040951,
			balance: 99528.45
		},
		{
			unixtime: 1657041011,
			balance: 99532.85
		},
		{
			unixtime: 1657041071,
			balance: 99539.15
		},
		{
			unixtime: 1657041131,
			balance: 99546.13
		},
		{
			unixtime: 1657041191,
			balance: 99549.07
		},
		{
			unixtime: 1657041251,
			balance: 99547.53
		},
		{
			unixtime: 1657041311,
			balance: 99548.91
		},
		{
			unixtime: 1657041371,
			balance: 99552.09
		},
		{
			unixtime: 1657041431,
			balance: 99551.67
		},
		{
			unixtime: 1657041491,
			balance: 99556.33
		},
		{
			unixtime: 1657041551,
			balance: 99566.05
		},
		{
			unixtime: 1657041611,
			balance: 99566.83
		},
		{
			unixtime: 1657041671,
			balance: 99571.57
		},
		{
			unixtime: 1657041731,
			balance: 99570.27
		},
		{
			unixtime: 1657041791,
			balance: 99575.75
		},
		{
			unixtime: 1657041851,
			balance: 99569.53
		},
		{
			unixtime: 1657041911,
			balance: 99565.83
		},
		{
			unixtime: 1657041971,
			balance: 99570.57
		},
		{
			unixtime: 1657042031,
			balance: 99565.89
		},
		{
			unixtime: 1657042091,
			balance: 99571.09
		},
		{
			unixtime: 1657042151,
			balance: 99572.19
		},
		{
			unixtime: 1657042211,
			balance: 99569.07
		},
		{
			unixtime: 1657042271,
			balance: 99563.53
		},
		{
			unixtime: 1657042331,
			balance: 99567.39
		},
		{
			unixtime: 1657042391,
			balance: 99567.87
		},
		{
			unixtime: 1657042451,
			balance: 99574.87
		},
		{
			unixtime: 1657042511,
			balance: 99578.51
		},
		{
			unixtime: 1657042571,
			balance: 99579.21
		},
		{
			unixtime: 1657042631,
			balance: 99587.79
		},
		{
			unixtime: 1657042691,
			balance: 99585.69
		},
		{
			unixtime: 1657042751,
			balance: 99587.07
		},
		{
			unixtime: 1657042811,
			balance: 99589.17
		},
		{
			unixtime: 1657042871,
			balance: 99593.41
		},
		{
			unixtime: 1657042931,
			balance: 99599.65
		},
		{
			unixtime: 1657042991,
			balance: 99596.93
		},
		{
			unixtime: 1657043051,
			balance: 99587.29
		},
		{
			unixtime: 1657043111,
			balance: 99581.61
		},
		{
			unixtime: 1657043171,
			balance: 99577.09
		},
		{
			unixtime: 1657043231,
			balance: 99572.23
		},
		{
			unixtime: 1657043291,
			balance: 99562.85
		},
		{
			unixtime: 1657043351,
			balance: 99568.53
		},
		{
			unixtime: 1657043411,
			balance: 99572.51
		},
		{
			unixtime: 1657043471,
			balance: 99574.31
		},
		{
			unixtime: 1657043531,
			balance: 99572.97
		},
		{
			unixtime: 1657043591,
			balance: 99575.57
		},
		{
			unixtime: 1657043651,
			balance: 99576.45
		},
		{
			unixtime: 1657043711,
			balance: 99576.35
		},
		{
			unixtime: 1657043771,
			balance: 99573.87
		},
		{
			unixtime: 1657043831,
			balance: 99574.53
		},
		{
			unixtime: 1657043891,
			balance: 99573.33
		},
		{
			unixtime: 1657043951,
			balance: 99576.11
		},
		{
			unixtime: 1657044011,
			balance: 99576.45
		},
		{
			unixtime: 1657044071,
			balance: 99574.69
		},
		{
			unixtime: 1657044131,
			balance: 99577.57
		},
		{
			unixtime: 1657044191,
			balance: 99580.71
		},
		{
			unixtime: 1657044251,
			balance: 99589.61
		},
		{
			unixtime: 1657044311,
			balance: 99594.51
		},
		{
			unixtime: 1657044371,
			balance: 99585.73
		},
		{
			unixtime: 1657044431,
			balance: 99580.87
		},
		{
			unixtime: 1657044491,
			balance: 99590.19
		},
		{
			unixtime: 1657044551,
			balance: 99579.49
		},
		{
			unixtime: 1657044611,
			balance: 99585.65
		},
		{
			unixtime: 1657044671,
			balance: 99581.81
		},
		{
			unixtime: 1657044731,
			balance: 99589.17
		},
		{
			unixtime: 1657044791,
			balance: 99588.89
		},
		{
			unixtime: 1657044851,
			balance: 99586.39
		},
		{
			unixtime: 1657044911,
			balance: 99584.49
		},
		{
			unixtime: 1657044971,
			balance: 99574.85
		},
		{
			unixtime: 1657045031,
			balance: 99572.85
		},
		{
			unixtime: 1657045091,
			balance: 99573.69
		},
		{
			unixtime: 1657045151,
			balance: 99576.15
		},
		{
			unixtime: 1657045211,
			balance: 99583.35
		},
		{
			unixtime: 1657045271,
			balance: 99580.33
		},
		{
			unixtime: 1657045331,
			balance: 99585.05
		},
		{
			unixtime: 1657045391,
			balance: 99586.77
		},
		{
			unixtime: 1657045451,
			balance: 99589.59
		},
		{
			unixtime: 1657045511,
			balance: 99596.77
		},
		{
			unixtime: 1657045571,
			balance: 99598.55
		},
		{
			unixtime: 1657045631,
			balance: 99607.69
		},
		{
			unixtime: 1657045691,
			balance: 99608.81
		},
		{
			unixtime: 1657045751,
			balance: 99610.51
		},
		{
			unixtime: 1657045811,
			balance: 99611.99
		},
		{
			unixtime: 1657045871,
			balance: 99619.29
		},
		{
			unixtime: 1657045931,
			balance: 99613.75
		},
		{
			unixtime: 1657045991,
			balance: 99623.67
		},
		{
			unixtime: 1657046051,
			balance: 99618.95
		},
		{
			unixtime: 1657046111,
			balance: 99622.65
		},
		{
			unixtime: 1657046171,
			balance: 99627.01
		},
		{
			unixtime: 1657046231,
			balance: 99631.81
		},
		{
			unixtime: 1657046291,
			balance: 99633.19
		},
		{
			unixtime: 1657046351,
			balance: 99645.33
		},
		{
			unixtime: 1657046411,
			balance: 99648.33
		},
		{
			unixtime: 1657046471,
			balance: 99647.03
		},
		{
			unixtime: 1657046531,
			balance: 99640.79
		},
		{
			unixtime: 1657046591,
			balance: 99635.91
		},
		{
			unixtime: 1657046651,
			balance: 99633.69
		},
		{
			unixtime: 1657046711,
			balance: 99635.29
		},
		{
			unixtime: 1657046771,
			balance: 99635.11
		},
		{
			unixtime: 1657046831,
			balance: 99632.61
		},
		{
			unixtime: 1657046891,
			balance: 99628.03
		},
		{
			unixtime: 1657046951,
			balance: 99625.21
		},
		{
			unixtime: 1657047011,
			balance: 99629.93
		},
		{
			unixtime: 1657047071,
			balance: 99632.07
		},
		{
			unixtime: 1657047131,
			balance: 99632.11
		},
		{
			unixtime: 1657047191,
			balance: 99628.51
		},
		{
			unixtime: 1657047251,
			balance: 99629.51
		},
		{
			unixtime: 1657047311,
			balance: 99632.67
		},
		{
			unixtime: 1657047371,
			balance: 99627.59
		},
		{
			unixtime: 1657047431,
			balance: 99628.75
		},
		{
			unixtime: 1657047491,
			balance: 99636.31
		},
		{
			unixtime: 1657047551,
			balance: 99636.37
		},
		{
			unixtime: 1657047611,
			balance: 99629.15
		},
		{
			unixtime: 1657047671,
			balance: 99623.21
		},
		{
			unixtime: 1657047731,
			balance: 99615.85
		},
		{
			unixtime: 1657047791,
			balance: 99616.25
		},
		{
			unixtime: 1657047851,
			balance: 99624.81
		},
		{
			unixtime: 1657047911,
			balance: 99632.37
		},
		{
			unixtime: 1657047971,
			balance: 99632.85
		},
		{
			unixtime: 1657048031,
			balance: 99641.61
		},
		{
			unixtime: 1657048091,
			balance: 99644.73
		},
		{
			unixtime: 1657048151,
			balance: 99640.75
		},
		{
			unixtime: 1657048211,
			balance: 99648.11
		},
		{
			unixtime: 1657048271,
			balance: 99644.91
		},
		{
			unixtime: 1657048331,
			balance: 99641.51
		},
		{
			unixtime: 1657048391,
			balance: 99642.93
		},
		{
			unixtime: 1657048451,
			balance: 99646.83
		},
		{
			unixtime: 1657048511,
			balance: 99650.13
		},
		{
			unixtime: 1657048571,
			balance: 99647.65
		},
		{
			unixtime: 1657048631,
			balance: 99640.41
		},
		{
			unixtime: 1657048691,
			balance: 99636.39
		},
		{
			unixtime: 1657048751,
			balance: 99622.65
		},
		{
			unixtime: 1657048811,
			balance: 99625.67
		},
		{
			unixtime: 1657048871,
			balance: 99639.09
		},
		{
			unixtime: 1657048931,
			balance: 99638.49
		},
		{
			unixtime: 1657048991,
			balance: 99647.73
		},
		{
			unixtime: 1657049051,
			balance: 99653.47
		},
		{
			unixtime: 1657049111,
			balance: 99654.61
		},
		{
			unixtime: 1657049171,
			balance: 99650.31
		},
		{
			unixtime: 1657049231,
			balance: 99651.25
		},
		{
			unixtime: 1657049291,
			balance: 99652.05
		},
		{
			unixtime: 1657049351,
			balance: 99661.71
		},
		{
			unixtime: 1657049411,
			balance: 99662.67
		},
		{
			unixtime: 1657049471,
			balance: 99654.41
		},
		{
			unixtime: 1657049531,
			balance: 99654.05
		},
		{
			unixtime: 1657049591,
			balance: 99656.85
		},
		{
			unixtime: 1657049651,
			balance: 99660.11
		},
		{
			unixtime: 1657049711,
			balance: 99661.69
		},
		{
			unixtime: 1657049771,
			balance: 99658.05
		},
		{
			unixtime: 1657049831,
			balance: 99654.05
		},
		{
			unixtime: 1657049891,
			balance: 99653.51
		},
		{
			unixtime: 1657049951,
			balance: 99661.51
		},
		{
			unixtime: 1657050011,
			balance: 99658.09
		},
		{
			unixtime: 1657050071,
			balance: 99658.09
		},
		{
			unixtime: 1657050131,
			balance: 99655.61
		},
		{
			unixtime: 1657050191,
			balance: 99656.91
		},
		{
			unixtime: 1657050251,
			balance: 99660.77
		},
		{
			unixtime: 1657050311,
			balance: 99660.71
		},
		{
			unixtime: 1657050371,
			balance: 99658.43
		},
		{
			unixtime: 1657050431,
			balance: 99660.37
		},
		{
			unixtime: 1657050491,
			balance: 99664.13
		},
		{
			unixtime: 1657050551,
			balance: 99668.67
		},
		{
			unixtime: 1657050611,
			balance: 99672.13
		},
		{
			unixtime: 1657050671,
			balance: 99671.61
		},
		{
			unixtime: 1657050731,
			balance: 99674.03
		},
		{
			unixtime: 1657050791,
			balance: 99662.43
		},
		{
			unixtime: 1657050851,
			balance: 99669.01
		},
		{
			unixtime: 1657050911,
			balance: 99675.37
		},
		{
			unixtime: 1657050971,
			balance: 99672.33
		},
		{
			unixtime: 1657051031,
			balance: 99681.93
		},
		{
			unixtime: 1657051091,
			balance: 99689.41
		},
		{
			unixtime: 1657051151,
			balance: 99688.91
		},
		{
			unixtime: 1657051211,
			balance: 99695.21
		},
		{
			unixtime: 1657051271,
			balance: 99690.25
		},
		{
			unixtime: 1657051331,
			balance: 99693.25
		},
		{
			unixtime: 1657051391,
			balance: 99685.51
		},
		{
			unixtime: 1657051451,
			balance: 99695.33
		},
		{
			unixtime: 1657051511,
			balance: 99693.05
		},
		{
			unixtime: 1657051571,
			balance: 99691.89
		},
		{
			unixtime: 1657051631,
			balance: 99688.57
		},
		{
			unixtime: 1657051691,
			balance: 99689.85
		},
		{
			unixtime: 1657051751,
			balance: 99690.37
		},
		{
			unixtime: 1657051811,
			balance: 99695.17
		},
		{
			unixtime: 1657051871,
			balance: 99692.85
		},
		{
			unixtime: 1657051931,
			balance: 99692.95
		},
		{
			unixtime: 1657051991,
			balance: 99693.13
		},
		{
			unixtime: 1657052051,
			balance: 99693.49
		},
		{
			unixtime: 1657052111,
			balance: 99691.87
		},
		{
			unixtime: 1657052171,
			balance: 99689.15
		},
		{
			unixtime: 1657052231,
			balance: 99689.91
		},
		{
			unixtime: 1657052291,
			balance: 99690.31
		},
		{
			unixtime: 1657052351,
			balance: 99691.49
		},
		{
			unixtime: 1657052411,
			balance: 99691.33
		},
		{
			unixtime: 1657052471,
			balance: 99692.29
		},
		{
			unixtime: 1657052531,
			balance: 99693.69
		},
		{
			unixtime: 1657052591,
			balance: 99694.53
		},
		{
			unixtime: 1657052651,
			balance: 99694.17
		},
		{
			unixtime: 1657052711,
			balance: 99695.43
		},
		{
			unixtime: 1657052771,
			balance: 99695.73
		},
		{
			unixtime: 1657052831,
			balance: 99696.09
		},
		{
			unixtime: 1657052891,
			balance: 99692.93
		},
		{
			unixtime: 1657052951,
			balance: 99695.01
		},
		{
			unixtime: 1657053011,
			balance: 99697.25
		},
		{
			unixtime: 1657053071,
			balance: 99698.13
		},
		{
			unixtime: 1657053131,
			balance: 99699.59
		},
		{
			unixtime: 1657053191,
			balance: 99697.23
		},
		{
			unixtime: 1657053251,
			balance: 99700.81
		},
		{
			unixtime: 1657053311,
			balance: 99700.13
		},
		{
			unixtime: 1657053371,
			balance: 99702.15
		},
		{
			unixtime: 1657053431,
			balance: 99700.09
		},
		{
			unixtime: 1657053491,
			balance: 99698.75
		},
		{
			unixtime: 1657053551,
			balance: 99699.73
		},
		{
			unixtime: 1657053611,
			balance: 99699.61
		},
		{
			unixtime: 1657053671,
			balance: 99700.25
		},
		{
			unixtime: 1657053731,
			balance: 99699.75
		},
		{
			unixtime: 1657053791,
			balance: 99700.21
		},
		{
			unixtime: 1657053851,
			balance: 99701.05
		},
		{
			unixtime: 1657053911,
			balance: 99700.81
		},
		{
			unixtime: 1657053971,
			balance: 99700.31
		},
		{
			unixtime: 1657054031,
			balance: 99700.75
		},
		{
			unixtime: 1657054091,
			balance: 99700.25
		},
		{
			unixtime: 1657054151,
			balance: 99700.45
		},
		{
			unixtime: 1657054211,
			balance: 99701.73
		},
		{
			unixtime: 1657054271,
			balance: 99701.37
		},
		{
			unixtime: 1657054331,
			balance: 99700.97
		},
		{
			unixtime: 1657054391,
			balance: 99701.45
		},
		{
			unixtime: 1657054451,
			balance: 99699.09
		},
		{
			unixtime: 1657054511,
			balance: 99698.71
		},
		{
			unixtime: 1657054571,
			balance: 99699.01
		},
		{
			unixtime: 1657054631,
			balance: 99698.25
		},
		{
			unixtime: 1657054691,
			balance: 99696.81
		},
		{
			unixtime: 1657054751,
			balance: 99697.61
		},
		{
			unixtime: 1657054811,
			balance: 99696.81
		},
		{
			unixtime: 1657054871,
			balance: 99697.09
		},
		{
			unixtime: 1657054931,
			balance: 99694.73
		},
		{
			unixtime: 1657054991,
			balance: 99694.85
		},
		{
			unixtime: 1657055051,
			balance: 99696.05
		},
		{
			unixtime: 1657055111,
			balance: 99695.61
		},
		{
			unixtime: 1657055171,
			balance: 99697.01
		},
		{
			unixtime: 1657055231,
			balance: 99696.59
		},
		{
			unixtime: 1657055291,
			balance: 99697.21
		},
		{
			unixtime: 1657055351,
			balance: 99697.21
		},
		{
			unixtime: 1657055411,
			balance: 99696.71
		},
		{
			unixtime: 1657055471,
			balance: 99696.99
		},
		{
			unixtime: 1657055531,
			balance: 99695.95
		},
		{
			unixtime: 1657055591,
			balance: 99695.95
		},
		{
			unixtime: 1657055651,
			balance: 99695.95
		},
		{
			unixtime: 1657055711,
			balance: 99696.67
		},
		{
			unixtime: 1657055771,
			balance: 99695.07
		},
		{
			unixtime: 1657055831,
			balance: 99694.47
		},
		{
			unixtime: 1657055891,
			balance: 99694.37
		},
		{
			unixtime: 1657055951,
			balance: 99694.37
		},
		{
			unixtime: 1657056011,
			balance: 99694.37
		},
		{
			unixtime: 1657056071,
			balance: 99694.37
		},
		{
			unixtime: 1657056131,
			balance: 99692.95
		},
		{
			unixtime: 1657056191,
			balance: 99692.95
		},
		{
			unixtime: 1657056251,
			balance: 99693.31
		},
		{
			unixtime: 1657056311,
			balance: 99693.09
		},
		{
			unixtime: 1657056371,
			balance: 99693.09
		},
		{
			unixtime: 1657056431,
			balance: 99693.25
		},
		{
			unixtime: 1657056491,
			balance: 99693.63
		},
		{
			unixtime: 1657056551,
			balance: 99693.97
		},
		{
			unixtime: 1657056611,
			balance: 99694.49
		},
		{
			unixtime: 1657056671,
			balance: 99694.73
		},
		{
			unixtime: 1657056731,
			balance: 99692.19
		},
		{
			unixtime: 1657056791,
			balance: 99692.53
		},
		{
			unixtime: 1657056851,
			balance: 99692.35
		},
		{
			unixtime: 1657056911,
			balance: 99693.35
		},
		{
			unixtime: 1657056971,
			balance: 99694.33
		},
		{
			unixtime: 1657057031,
			balance: 99697.17
		},
		{
			unixtime: 1657057091,
			balance: 99692.29
		},
		{
			unixtime: 1657057151,
			balance: 99693.19
		},
		{
			unixtime: 1657057211,
			balance: 99697.15
		},
		{
			unixtime: 1657057271,
			balance: 99697.15
		},
		{
			unixtime: 1657057331,
			balance: 99698.35
		},
		{
			unixtime: 1657057391,
			balance: 99697.51
		},
		{
			unixtime: 1657057451,
			balance: 99697.21
		},
		{
			unixtime: 1657057511,
			balance: 99696.73
		},
		{
			unixtime: 1657057571,
			balance: 99696.73
		},
		{
			unixtime: 1657057631,
			balance: 99693.61
		},
		{
			unixtime: 1657057691,
			balance: 99693.61
		},
		{
			unixtime: 1657057751,
			balance: 99693.13
		},
		{
			unixtime: 1657057811,
			balance: 99693.13
		},
		{
			unixtime: 1657057871,
			balance: 99693.01
		},
		{
			unixtime: 1657057931,
			balance: 99693.13
		},
		{
			unixtime: 1657057991,
			balance: 99693.13
		},
		{
			unixtime: 1657058051,
			balance: 99693.13
		},
		{
			unixtime: 1657058111,
			balance: 99691.93
		},
		{
			unixtime: 1657058171,
			balance: 99693.49
		},
		{
			unixtime: 1657058231,
			balance: 99695.37
		},
		{
			unixtime: 1657058291,
			balance: 99695.97
		},
		{
			unixtime: 1657058351,
			balance: 99695.61
		},
		{
			unixtime: 1657058411,
			balance: 99704.09
		},
		{
			unixtime: 1657058471,
			balance: 99700.75
		},
		{
			unixtime: 1657058531,
			balance: 99701.73
		},
		{
			unixtime: 1657058591,
			balance: 99702.09
		},
		{
			unixtime: 1657058651,
			balance: 99703.93
		},
		{
			unixtime: 1657058711,
			balance: 99705.49
		},
		{
			unixtime: 1657058771,
			balance: 99706.33
		},
		{
			unixtime: 1657058831,
			balance: 99701.47
		},
		{
			unixtime: 1657058891,
			balance: 99703.73
		},
		{
			unixtime: 1657058951,
			balance: 99702.57
		},
		{
			unixtime: 1657059011,
			balance: 99700.73
		},
		{
			unixtime: 1657059071,
			balance: 99700.37
		},
		{
			unixtime: 1657059131,
			balance: 99702.63
		},
		{
			unixtime: 1657059191,
			balance: 99702.15
		},
		{
			unixtime: 1657059251,
			balance: 99706.55
		},
		{
			unixtime: 1657059311,
			balance: 99706.67
		},
		{
			unixtime: 1657059371,
			balance: 99706.91
		},
		{
			unixtime: 1657059431,
			balance: 99707.97
		},
		{
			unixtime: 1657059491,
			balance: 99708.09
		},
		{
			unixtime: 1657059551,
			balance: 99708.81
		},
		{
			unixtime: 1657059611,
			balance: 99710.93
		},
		{
			unixtime: 1657059671,
			balance: 99713.13
		},
		{
			unixtime: 1657059731,
			balance: 99710.13
		},
		{
			unixtime: 1657059791,
			balance: 99710.27
		},
		{
			unixtime: 1657059851,
			balance: 99709.01
		},
		{
			unixtime: 1657059911,
			balance: 99708.05
		},
		{
			unixtime: 1657059971,
			balance: 99705.85
		},
		{
			unixtime: 1657060031,
			balance: 99702.93
		},
		{
			unixtime: 1657060091,
			balance: 99704.73
		},
		{
			unixtime: 1657060151,
			balance: 99706.29
		},
		{
			unixtime: 1657060211,
			balance: 99701.77
		},
		{
			unixtime: 1657060271,
			balance: 99701.77
		},
		{
			unixtime: 1657060331,
			balance: 99703.35
		},
		{
			unixtime: 1657060391,
			balance: 99700.95
		},
		{
			unixtime: 1657060451,
			balance: 99701.55
		},
		{
			unixtime: 1657060511,
			balance: 99702.39
		},
		{
			unixtime: 1657060571,
			balance: 99702.03
		},
		{
			unixtime: 1657060631,
			balance: 99704.03
		},
		{
			unixtime: 1657060691,
			balance: 99703.31
		},
		{
			unixtime: 1657060751,
			balance: 99704.15
		},
		{
			unixtime: 1657060811,
			balance: 99704.93
		},
		{
			unixtime: 1657060871,
			balance: 99703.73
		},
		{
			unixtime: 1657060931,
			balance: 99702.53
		},
		{
			unixtime: 1657060991,
			balance: 99699.47
		},
		{
			unixtime: 1657061051,
			balance: 99697.55
		},
		{
			unixtime: 1657061111,
			balance: 99699.23
		},
		{
			unixtime: 1657061171,
			balance: 99699.73
		},
		{
			unixtime: 1657061231,
			balance: 99699.97
		},
		{
			unixtime: 1657061291,
			balance: 99700.63
		},
		{
			unixtime: 1657061351,
			balance: 99700.63
		},
		{
			unixtime: 1657061411,
			balance: 99700.63
		},
		{
			unixtime: 1657061471,
			balance: 99701.35
		},
		{
			unixtime: 1657061531,
			balance: 99700.35
		},
		{
			unixtime: 1657061591,
			balance: 99700.35
		},
		{
			unixtime: 1657061651,
			balance: 99696.95
		},
		{
			unixtime: 1657061711,
			balance: 99696.95
		},
		{
			unixtime: 1657061771,
			balance: 99696.89
		},
		{
			unixtime: 1657061831,
			balance: 99694.53
		},
		{
			unixtime: 1657061891,
			balance: 99693.53
		},
		{
			unixtime: 1657061951,
			balance: 99694.85
		},
		{
			unixtime: 1657062011,
			balance: 99698.99
		},
		{
			unixtime: 1657062071,
			balance: 99699.11
		},
		{
			unixtime: 1657062131,
			balance: 99700.19
		},
		{
			unixtime: 1657062191,
			balance: 99700.55
		},
		{
			unixtime: 1657062251,
			balance: 99699.83
		},
		{
			unixtime: 1657062311,
			balance: 99700.43
		},
		{
			unixtime: 1657062371,
			balance: 99700.83
		},
		{
			unixtime: 1657062431,
			balance: 99701.17
		},
		{
			unixtime: 1657062491,
			balance: 99701.21
		},
		{
			unixtime: 1657062551,
			balance: 99700.37
		},
		{
			unixtime: 1657062611,
			balance: 99699.53
		},
		{
			unixtime: 1657062671,
			balance: 99700.29
		},
		{
			unixtime: 1657062731,
			balance: 99699.97
		},
		{
			unixtime: 1657062791,
			balance: 99700.17
		},
		{
			unixtime: 1657062851,
			balance: 99700.05
		},
		{
			unixtime: 1657062911,
			balance: 99699.85
		},
		{
			unixtime: 1657062971,
			balance: 99699.13
		},
		{
			unixtime: 1657063031,
			balance: 99698.59
		},
		{
			unixtime: 1657063091,
			balance: 99698.47
		},
		{
			unixtime: 1657063151,
			balance: 99698.47
		},
		{
			unixtime: 1657063211,
			balance: 99698.35
		},
		{
			unixtime: 1657063271,
			balance: 99698.55
		},
		{
			unixtime: 1657063331,
			balance: 99698.45
		},
		{
			unixtime: 1657063391,
			balance: 99698.97
		},
		{
			unixtime: 1657063451,
			balance: 99696.85
		},
		{
			unixtime: 1657063511,
			balance: 99696.61
		},
		{
			unixtime: 1657063571,
			balance: 99696.61
		},
		{
			unixtime: 1657063631,
			balance: 99693.45
		},
		{
			unixtime: 1657063691,
			balance: 99693.69
		},
		{
			unixtime: 1657063751,
			balance: 99693.79
		},
		{
			unixtime: 1657063811,
			balance: 99691.89
		},
		{
			unixtime: 1657063871,
			balance: 99692.11
		},
		{
			unixtime: 1657063931,
			balance: 99691.39
		},
		{
			unixtime: 1657063991,
			balance: 99688.73
		},
		{
			unixtime: 1657064051,
			balance: 99688.37
		},
		{
			unixtime: 1657064111,
			balance: 99687.53
		},
		{
			unixtime: 1657064171,
			balance: 99687.33
		},
		{
			unixtime: 1657064231,
			balance: 99688.45
		},
		{
			unixtime: 1657064291,
			balance: 99687.25
		},
		{
			unixtime: 1657064351,
			balance: 99686.85
		},
		{
			unixtime: 1657064411,
			balance: 99685.33
		},
		{
			unixtime: 1657064471,
			balance: 99685.83
		},
		{
			unixtime: 1657064531,
			balance: 99687.27
		},
		{
			unixtime: 1657064591,
			balance: 99685.69
		},
		{
			unixtime: 1657064651,
			balance: 99684.13
		},
		{
			unixtime: 1657064711,
			balance: 99682.23
		},
		{
			unixtime: 1657064771,
			balance: 99682.17
		},
		{
			unixtime: 1657064831,
			balance: 99681.93
		},
		{
			unixtime: 1657064891,
			balance: 99681.69
		},
		{
			unixtime: 1657064951,
			balance: 99681.75
		},
		{
			unixtime: 1657065011,
			balance: 99681.03
		},
		{
			unixtime: 1657065071,
			balance: 99679.83
		},
		{
			unixtime: 1657065131,
			balance: 99677.13
		},
		{
			unixtime: 1657065191,
			balance: 99676.77
		},
		{
			unixtime: 1657065251,
			balance: 99675.11
		},
		{
			unixtime: 1657065311,
			balance: 99674.53
		},
		{
			unixtime: 1657065371,
			balance: 99672.33
		},
		{
			unixtime: 1657065431,
			balance: 99673.43
		},
		{
			unixtime: 1657065491,
			balance: 99672.29
		},
		{
			unixtime: 1657065551,
			balance: 99678.49
		},
		{
			unixtime: 1657065611,
			balance: 99696.49
		},
		{
			unixtime: 1657065671,
			balance: 99696.49
		},
		{
			unixtime: 1657065731,
			balance: 99696.49
		},
		{
			unixtime: 1657065791,
			balance: 99696.49
		},
		{
			unixtime: 1657065851,
			balance: 99696.49
		},
		{
			unixtime: 1657065911,
			balance: 99696.49
		},
		{
			unixtime: 1657065971,
			balance: 99696.49
		},
		{
			unixtime: 1657066031,
			balance: 99696.49
		},
		{
			unixtime: 1657066091,
			balance: 99696.49
		},
		{
			unixtime: 1657066151,
			balance: 99696.49
		},
		{
			unixtime: 1657066211,
			balance: 99696.49
		},
		{
			unixtime: 1657066271,
			balance: 99696.49
		},
		{
			unixtime: 1657066331,
			balance: 99696.49
		},
		{
			unixtime: 1657066391,
			balance: 99696.49
		},
		{
			unixtime: 1657066451,
			balance: 99696.49
		},
		{
			unixtime: 1657066511,
			balance: 99696.49
		},
		{
			unixtime: 1657066571,
			balance: 99696.49
		},
		{
			unixtime: 1657066631,
			balance: 99696.49
		},
		{
			unixtime: 1657066691,
			balance: 99696.49
		},
		{
			unixtime: 1657066751,
			balance: 99696.49
		},
		{
			unixtime: 1657066811,
			balance: 99696.49
		},
		{
			unixtime: 1657066871,
			balance: 99696.49
		},
		{
			unixtime: 1657066931,
			balance: 99696.49
		},
		{
			unixtime: 1657066991,
			balance: 99696.49
		},
		{
			unixtime: 1657067051,
			balance: 99696.49
		},
		{
			unixtime: 1657067111,
			balance: 99696.49
		},
		{
			unixtime: 1657067171,
			balance: 99696.49
		},
		{
			unixtime: 1657067231,
			balance: 99696.49
		},
		{
			unixtime: 1657067291,
			balance: 99696.49
		},
		{
			unixtime: 1657067351,
			balance: 99696.49
		},
		{
			unixtime: 1657067411,
			balance: 99696.49
		},
		{
			unixtime: 1657067471,
			balance: 99696.49
		},
		{
			unixtime: 1657067532,
			balance: 99696.49
		},
		{
			unixtime: 1657067591,
			balance: 99696.49
		},
		{
			unixtime: 1657067651,
			balance: 99696.49
		},
		{
			unixtime: 1657067711,
			balance: 99696.49
		},
		{
			unixtime: 1657067772,
			balance: 99696.49
		},
		{
			unixtime: 1657067832,
			balance: 99696.49
		},
		{
			unixtime: 1657067892,
			balance: 99696.49
		},
		{
			unixtime: 1657067951,
			balance: 99696.49
		},
		{
			unixtime: 1657068011,
			balance: 99696.49
		},
		{
			unixtime: 1657068072,
			balance: 99696.49
		},
		{
			unixtime: 1657068131,
			balance: 99696.49
		},
		{
			unixtime: 1657068191,
			balance: 99696.49
		},
		{
			unixtime: 1657068251,
			balance: 99696.49
		},
		{
			unixtime: 1657068312,
			balance: 99696.49
		},
		{
			unixtime: 1657068372,
			balance: 99696.49
		},
		{
			unixtime: 1657068432,
			balance: 99696.49
		},
		{
			unixtime: 1657068491,
			balance: 99696.49
		},
		{
			unixtime: 1657068552,
			balance: 99696.49
		},
		{
			unixtime: 1657068612,
			balance: 99696.49
		},
		{
			unixtime: 1657068672,
			balance: 99696.49
		},
		{
			unixtime: 1657068732,
			balance: 99696.49
		},
		{
			unixtime: 1657068792,
			balance: 99696.49
		},
		{
			unixtime: 1657068852,
			balance: 99696.49
		},
		{
			unixtime: 1657068912,
			balance: 99696.49
		},
		{
			unixtime: 1657068972,
			balance: 99696.49
		},
		{
			unixtime: 1657069032,
			balance: 99696.49
		},
		{
			unixtime: 1657069092,
			balance: 99696.49
		},
		{
			unixtime: 1657069152,
			balance: 99696.49
		},
		{
			unixtime: 1657069212,
			balance: 99696.49
		},
		{
			unixtime: 1657069272,
			balance: 99696.49
		},
		{
			unixtime: 1657069332,
			balance: 99696.49
		},
		{
			unixtime: 1657069392,
			balance: 99696.49
		},
		{
			unixtime: 1657069452,
			balance: 99696.49
		},
		{
			unixtime: 1657069512,
			balance: 99696.49
		},
		{
			unixtime: 1657069572,
			balance: 99696.49
		},
		{
			unixtime: 1657069632,
			balance: 99696.49
		},
		{
			unixtime: 1657069692,
			balance: 99696.49
		},
		{
			unixtime: 1657069752,
			balance: 99696.49
		},
		{
			unixtime: 1657069812,
			balance: 99696.49
		},
		{
			unixtime: 1657069872,
			balance: 99696.49
		},
		{
			unixtime: 1657069932,
			balance: 99696.49
		},
		{
			unixtime: 1657069992,
			balance: 99696.49
		},
		{
			unixtime: 1657070052,
			balance: 99696.49
		},
		{
			unixtime: 1657070112,
			balance: 99696.49
		},
		{
			unixtime: 1657070172,
			balance: 99696.49
		},
		{
			unixtime: 1657070232,
			balance: 99696.49
		},
		{
			unixtime: 1657070292,
			balance: 99696.49
		},
		{
			unixtime: 1657070352,
			balance: 99696.49
		},
		{
			unixtime: 1657070412,
			balance: 99696.49
		},
		{
			unixtime: 1657070472,
			balance: 99696.49
		},
		{
			unixtime: 1657070532,
			balance: 99696.49
		},
		{
			unixtime: 1657070592,
			balance: 99696.49
		},
		{
			unixtime: 1657070652,
			balance: 99696.49
		},
		{
			unixtime: 1657070712,
			balance: 99696.49
		},
		{
			unixtime: 1657070772,
			balance: 99696.49
		},
		{
			unixtime: 1657070832,
			balance: 99696.49
		},
		{
			unixtime: 1657070892,
			balance: 99696.49
		},
		{
			unixtime: 1657070952,
			balance: 99696.49
		},
		{
			unixtime: 1657071012,
			balance: 99696.49
		},
		{
			unixtime: 1657071072,
			balance: 99696.49
		},
		{
			unixtime: 1657071132,
			balance: 99696.49
		},
		{
			unixtime: 1657071192,
			balance: 99696.49
		},
		{
			unixtime: 1657071252,
			balance: 99696.49
		},
		{
			unixtime: 1657071312,
			balance: 99696.49
		},
		{
			unixtime: 1657071372,
			balance: 99696.49
		},
		{
			unixtime: 1657071432,
			balance: 99696.49
		},
		{
			unixtime: 1657071492,
			balance: 99696.49
		},
		{
			unixtime: 1657071552,
			balance: 99696.49
		},
		{
			unixtime: 1657071612,
			balance: 99696.49
		},
		{
			unixtime: 1657071672,
			balance: 99696.49
		},
		{
			unixtime: 1657071732,
			balance: 99696.49
		},
		{
			unixtime: 1657071792,
			balance: 99696.49
		},
		{
			unixtime: 1657071852,
			balance: 99696.49
		},
		{
			unixtime: 1657071912,
			balance: 99696.49
		},
		{
			unixtime: 1657071972,
			balance: 99696.49
		},
		{
			unixtime: 1657072032,
			balance: 99696.49
		},
		{
			unixtime: 1657072092,
			balance: 99696.49
		},
		{
			unixtime: 1657072152,
			balance: 99696.49
		},
		{
			unixtime: 1657072212,
			balance: 99696.49
		},
		{
			unixtime: 1657072272,
			balance: 99696.49
		},
		{
			unixtime: 1657072332,
			balance: 99696.49
		},
		{
			unixtime: 1657072392,
			balance: 99696.49
		},
		{
			unixtime: 1657072452,
			balance: 99696.49
		},
		{
			unixtime: 1657072512,
			balance: 99696.49
		},
		{
			unixtime: 1657072572,
			balance: 99696.49
		},
		{
			unixtime: 1657072632,
			balance: 99696.49
		},
		{
			unixtime: 1657072692,
			balance: 99696.49
		},
		{
			unixtime: 1657072752,
			balance: 99696.49
		},
		{
			unixtime: 1657072812,
			balance: 99696.49
		},
		{
			unixtime: 1657072872,
			balance: 99696.49
		},
		{
			unixtime: 1657072932,
			balance: 99696.49
		},
		{
			unixtime: 1657072992,
			balance: 99696.49
		},
		{
			unixtime: 1657073052,
			balance: 99696.49
		},
		{
			unixtime: 1657073112,
			balance: 99696.49
		},
		{
			unixtime: 1657073172,
			balance: 99696.49
		},
		{
			unixtime: 1657073232,
			balance: 99696.49
		},
		{
			unixtime: 1657073292,
			balance: 99696.49
		},
		{
			unixtime: 1657073352,
			balance: 99696.49
		},
		{
			unixtime: 1657073412,
			balance: 99696.49
		},
		{
			unixtime: 1657073472,
			balance: 99696.49
		},
		{
			unixtime: 1657073532,
			balance: 99696.49
		},
		{
			unixtime: 1657073592,
			balance: 99696.49
		},
		{
			unixtime: 1657073652,
			balance: 99696.49
		},
		{
			unixtime: 1657073712,
			balance: 99696.49
		},
		{
			unixtime: 1657073772,
			balance: 99696.49
		},
		{
			unixtime: 1657073832,
			balance: 99696.49
		},
		{
			unixtime: 1657073892,
			balance: 99696.49
		},
		{
			unixtime: 1657073952,
			balance: 99696.49
		},
		{
			unixtime: 1657074012,
			balance: 99696.49
		},
		{
			unixtime: 1657074072,
			balance: 99696.49
		},
		{
			unixtime: 1657074132,
			balance: 99696.49
		},
		{
			unixtime: 1657074192,
			balance: 99696.49
		},
		{
			unixtime: 1657074252,
			balance: 99696.49
		},
		{
			unixtime: 1657074312,
			balance: 99696.49
		},
		{
			unixtime: 1657074372,
			balance: 99696.49
		},
		{
			unixtime: 1657074432,
			balance: 99696.49
		},
		{
			unixtime: 1657074492,
			balance: 99696.49
		},
		{
			unixtime: 1657074552,
			balance: 99696.49
		},
		{
			unixtime: 1657074612,
			balance: 99696.49
		},
		{
			unixtime: 1657074672,
			balance: 99696.49
		},
		{
			unixtime: 1657074732,
			balance: 99696.49
		},
		{
			unixtime: 1657074792,
			balance: 99696.49
		},
		{
			unixtime: 1657074852,
			balance: 99696.49
		},
		{
			unixtime: 1657074912,
			balance: 99696.49
		},
		{
			unixtime: 1657074972,
			balance: 99696.49
		},
		{
			unixtime: 1657075032,
			balance: 99696.49
		},
		{
			unixtime: 1657075092,
			balance: 99696.49
		},
		{
			unixtime: 1657075152,
			balance: 99696.49
		},
		{
			unixtime: 1657075212,
			balance: 99696.49
		},
		{
			unixtime: 1657075272,
			balance: 99696.49
		},
		{
			unixtime: 1657075332,
			balance: 99696.49
		},
		{
			unixtime: 1657075392,
			balance: 99696.49
		},
		{
			unixtime: 1657075452,
			balance: 99696.49
		},
		{
			unixtime: 1657075512,
			balance: 99696.49
		},
		{
			unixtime: 1657075572,
			balance: 99696.49
		},
		{
			unixtime: 1657075632,
			balance: 99696.49
		},
		{
			unixtime: 1657075692,
			balance: 99696.49
		},
		{
			unixtime: 1657075752,
			balance: 99696.49
		},
		{
			unixtime: 1657075812,
			balance: 99696.49
		},
		{
			unixtime: 1657075872,
			balance: 99696.49
		},
		{
			unixtime: 1657075932,
			balance: 99696.49
		},
		{
			unixtime: 1657075992,
			balance: 99696.49
		},
		{
			unixtime: 1657076052,
			balance: 99696.49
		},
		{
			unixtime: 1657076112,
			balance: 99696.49
		},
		{
			unixtime: 1657076172,
			balance: 99696.49
		},
		{
			unixtime: 1657076232,
			balance: 99696.49
		},
		{
			unixtime: 1657076292,
			balance: 99696.49
		},
		{
			unixtime: 1657076352,
			balance: 99696.49
		},
		{
			unixtime: 1657076412,
			balance: 99696.49
		},
		{
			unixtime: 1657076472,
			balance: 99696.49
		},
		{
			unixtime: 1657076532,
			balance: 99696.49
		},
		{
			unixtime: 1657076592,
			balance: 99696.49
		},
		{
			unixtime: 1657076652,
			balance: 99696.49
		},
		{
			unixtime: 1657076712,
			balance: 99696.49
		},
		{
			unixtime: 1657076772,
			balance: 99696.49
		},
		{
			unixtime: 1657076832,
			balance: 99696.49
		},
		{
			unixtime: 1657076892,
			balance: 99696.49
		},
		{
			unixtime: 1657076952,
			balance: 99696.49
		},
		{
			unixtime: 1657077012,
			balance: 99696.49
		},
		{
			unixtime: 1657077072,
			balance: 99696.49
		},
		{
			unixtime: 1657077132,
			balance: 99696.49
		},
		{
			unixtime: 1657077192,
			balance: 99696.49
		},
		{
			unixtime: 1657077252,
			balance: 99696.49
		},
		{
			unixtime: 1657077312,
			balance: 99696.49
		},
		{
			unixtime: 1657077372,
			balance: 99696.49
		},
		{
			unixtime: 1657077432,
			balance: 99696.49
		},
		{
			unixtime: 1657077492,
			balance: 99696.49
		},
		{
			unixtime: 1657077552,
			balance: 99696.49
		},
		{
			unixtime: 1657077612,
			balance: 99696.49
		},
		{
			unixtime: 1657077672,
			balance: 99696.49
		},
		{
			unixtime: 1657077732,
			balance: 99696.49
		},
		{
			unixtime: 1657077792,
			balance: 99696.49
		},
		{
			unixtime: 1657077852,
			balance: 99696.49
		},
		{
			unixtime: 1657077912,
			balance: 99696.49
		},
		{
			unixtime: 1657077972,
			balance: 99696.49
		},
		{
			unixtime: 1657078032,
			balance: 99696.49
		},
		{
			unixtime: 1657078092,
			balance: 99696.49
		},
		{
			unixtime: 1657078152,
			balance: 99696.49
		},
		{
			unixtime: 1657078212,
			balance: 99696.49
		},
		{
			unixtime: 1657078272,
			balance: 99696.49
		},
		{
			unixtime: 1657078332,
			balance: 99696.49
		},
		{
			unixtime: 1657078392,
			balance: 99696.49
		},
		{
			unixtime: 1657078452,
			balance: 99696.49
		},
		{
			unixtime: 1657078512,
			balance: 99696.49
		},
		{
			unixtime: 1657078572,
			balance: 99696.49
		},
		{
			unixtime: 1657078632,
			balance: 99696.49
		},
		{
			unixtime: 1657078692,
			balance: 99696.49
		},
		{
			unixtime: 1657078752,
			balance: 99696.49
		},
		{
			unixtime: 1657078812,
			balance: 99696.49
		},
		{
			unixtime: 1657078872,
			balance: 99696.49
		},
		{
			unixtime: 1657078932,
			balance: 99696.49
		},
		{
			unixtime: 1657078992,
			balance: 99696.49
		},
		{
			unixtime: 1657079052,
			balance: 99696.49
		},
		{
			unixtime: 1657079112,
			balance: 99696.49
		},
		{
			unixtime: 1657079172,
			balance: 99696.49
		},
		{
			unixtime: 1657079232,
			balance: 99696.49
		},
		{
			unixtime: 1657079292,
			balance: 99696.49
		},
		{
			unixtime: 1657079352,
			balance: 99696.49
		},
		{
			unixtime: 1657079412,
			balance: 99696.49
		},
		{
			unixtime: 1657079472,
			balance: 99696.49
		},
		{
			unixtime: 1657079532,
			balance: 99696.49
		},
		{
			unixtime: 1657079592,
			balance: 99696.49
		},
		{
			unixtime: 1657079652,
			balance: 99696.49
		},
		{
			unixtime: 1657079712,
			balance: 99696.49
		},
		{
			unixtime: 1657079772,
			balance: 99696.49
		},
		{
			unixtime: 1657079832,
			balance: 99696.49
		},
		{
			unixtime: 1657079892,
			balance: 99696.49
		},
		{
			unixtime: 1657079952,
			balance: 99696.49
		},
		{
			unixtime: 1657080012,
			balance: 99696.49
		},
		{
			unixtime: 1657080072,
			balance: 99696.49
		},
		{
			unixtime: 1657080132,
			balance: 99696.49
		},
		{
			unixtime: 1657080192,
			balance: 99696.49
		},
		{
			unixtime: 1657080252,
			balance: 99696.49
		},
		{
			unixtime: 1657080312,
			balance: 99696.49
		},
		{
			unixtime: 1657080372,
			balance: 99696.49
		},
		{
			unixtime: 1657080432,
			balance: 99696.49
		},
		{
			unixtime: 1657080492,
			balance: 99696.49
		},
		{
			unixtime: 1657080552,
			balance: 99696.49
		},
		{
			unixtime: 1657080612,
			balance: 99696.49
		},
		{
			unixtime: 1657080672,
			balance: 99696.49
		},
		{
			unixtime: 1657080732,
			balance: 99696.49
		},
		{
			unixtime: 1657080792,
			balance: 99696.49
		},
		{
			unixtime: 1657080852,
			balance: 99696.49
		},
		{
			unixtime: 1657080912,
			balance: 99696.49
		},
		{
			unixtime: 1657080972,
			balance: 99696.49
		},
		{
			unixtime: 1657081032,
			balance: 99696.49
		},
		{
			unixtime: 1657081092,
			balance: 99696.49
		},
		{
			unixtime: 1657081152,
			balance: 99696.49
		},
		{
			unixtime: 1657081212,
			balance: 99696.49
		},
		{
			unixtime: 1657081272,
			balance: 99696.49
		},
		{
			unixtime: 1657081332,
			balance: 99696.49
		},
		{
			unixtime: 1657081392,
			balance: 99696.49
		},
		{
			unixtime: 1657081452,
			balance: 99696.49
		},
		{
			unixtime: 1657081512,
			balance: 99696.49
		},
		{
			unixtime: 1657081572,
			balance: 99696.49
		},
		{
			unixtime: 1657081632,
			balance: 99696.49
		},
		{
			unixtime: 1657081692,
			balance: 99696.49
		},
		{
			unixtime: 1657081752,
			balance: 99696.49
		},
		{
			unixtime: 1657081812,
			balance: 99696.49
		},
		{
			unixtime: 1657081872,
			balance: 99696.49
		},
		{
			unixtime: 1657081932,
			balance: 99696.49
		},
		{
			unixtime: 1657081992,
			balance: 99696.49
		},
		{
			unixtime: 1657082052,
			balance: 99696.49
		},
		{
			unixtime: 1657082112,
			balance: 99696.49
		},
		{
			unixtime: 1657082172,
			balance: 99696.49
		},
		{
			unixtime: 1657082232,
			balance: 99696.49
		},
		{
			unixtime: 1657082292,
			balance: 99696.49
		},
		{
			unixtime: 1657082352,
			balance: 99696.49
		},
		{
			unixtime: 1657082412,
			balance: 99696.49
		},
		{
			unixtime: 1657082472,
			balance: 99696.49
		},
		{
			unixtime: 1657082532,
			balance: 99696.49
		},
		{
			unixtime: 1657082592,
			balance: 99696.49
		},
		{
			unixtime: 1657082652,
			balance: 99696.49
		},
		{
			unixtime: 1657082712,
			balance: 99696.49
		},
		{
			unixtime: 1657082772,
			balance: 99696.49
		},
		{
			unixtime: 1657082832,
			balance: 99696.49
		},
		{
			unixtime: 1657082892,
			balance: 99696.49
		},
		{
			unixtime: 1657082952,
			balance: 99696.49
		},
		{
			unixtime: 1657083012,
			balance: 99696.49
		},
		{
			unixtime: 1657083072,
			balance: 99696.49
		},
		{
			unixtime: 1657083132,
			balance: 99696.49
		},
		{
			unixtime: 1657083192,
			balance: 99696.49
		},
		{
			unixtime: 1657083252,
			balance: 99696.49
		},
		{
			unixtime: 1657083312,
			balance: 99696.49
		},
		{
			unixtime: 1657083372,
			balance: 99696.49
		},
		{
			unixtime: 1657083432,
			balance: 99696.49
		},
		{
			unixtime: 1657083492,
			balance: 99696.49
		},
		{
			unixtime: 1657083552,
			balance: 99696.49
		},
		{
			unixtime: 1657083612,
			balance: 99696.49
		},
		{
			unixtime: 1657083672,
			balance: 99696.49
		},
		{
			unixtime: 1657083732,
			balance: 99696.49
		},
		{
			unixtime: 1657083792,
			balance: 99696.49
		},
		{
			unixtime: 1657083852,
			balance: 99696.49
		},
		{
			unixtime: 1657083912,
			balance: 99696.49
		},
		{
			unixtime: 1657083972,
			balance: 99696.49
		},
		{
			unixtime: 1657084032,
			balance: 99696.49
		},
		{
			unixtime: 1657084092,
			balance: 99696.49
		},
		{
			unixtime: 1657084152,
			balance: 99696.49
		},
		{
			unixtime: 1657084212,
			balance: 99696.49
		},
		{
			unixtime: 1657084272,
			balance: 99696.49
		},
		{
			unixtime: 1657084332,
			balance: 99696.49
		},
		{
			unixtime: 1657084392,
			balance: 99696.49
		},
		{
			unixtime: 1657084452,
			balance: 99696.49
		},
		{
			unixtime: 1657084512,
			balance: 99696.49
		},
		{
			unixtime: 1657084572,
			balance: 99696.49
		},
		{
			unixtime: 1657084632,
			balance: 99696.49
		},
		{
			unixtime: 1657084692,
			balance: 99696.49
		},
		{
			unixtime: 1657084752,
			balance: 99696.49
		},
		{
			unixtime: 1657084812,
			balance: 99696.49
		},
		{
			unixtime: 1657084872,
			balance: 99696.49
		},
		{
			unixtime: 1657084932,
			balance: 99696.49
		},
		{
			unixtime: 1657084992,
			balance: 99696.49
		},
		{
			unixtime: 1657085052,
			balance: 99696.49
		},
		{
			unixtime: 1657085112,
			balance: 99696.49
		},
		{
			unixtime: 1657085172,
			balance: 99696.49
		},
		{
			unixtime: 1657085232,
			balance: 99696.49
		},
		{
			unixtime: 1657085292,
			balance: 99696.49
		},
		{
			unixtime: 1657085352,
			balance: 99696.49
		},
		{
			unixtime: 1657085412,
			balance: 99696.49
		},
		{
			unixtime: 1657085472,
			balance: 99696.49
		},
		{
			unixtime: 1657085532,
			balance: 99696.49
		},
		{
			unixtime: 1657085592,
			balance: 99696.49
		},
		{
			unixtime: 1657085652,
			balance: 99696.49
		},
		{
			unixtime: 1657085712,
			balance: 99696.49
		},
		{
			unixtime: 1657085772,
			balance: 99696.49
		},
		{
			unixtime: 1657085832,
			balance: 99696.49
		},
		{
			unixtime: 1657085892,
			balance: 99696.49
		},
		{
			unixtime: 1657085952,
			balance: 99696.49
		},
		{
			unixtime: 1657086012,
			balance: 99696.49
		},
		{
			unixtime: 1657086072,
			balance: 99696.49
		},
		{
			unixtime: 1657086132,
			balance: 99696.49
		},
		{
			unixtime: 1657086192,
			balance: 99696.49
		},
		{
			unixtime: 1657086252,
			balance: 99696.49
		},
		{
			unixtime: 1657086312,
			balance: 99696.49
		},
		{
			unixtime: 1657086372,
			balance: 99696.49
		},
		{
			unixtime: 1657086432,
			balance: 99696.49
		},
		{
			unixtime: 1657086492,
			balance: 99696.49
		},
		{
			unixtime: 1657086552,
			balance: 99696.49
		},
		{
			unixtime: 1657086612,
			balance: 99696.49
		},
		{
			unixtime: 1657086672,
			balance: 99696.49
		},
		{
			unixtime: 1657086732,
			balance: 99696.49
		},
		{
			unixtime: 1657086792,
			balance: 99696.49
		},
		{
			unixtime: 1657086852,
			balance: 99696.49
		},
		{
			unixtime: 1657086912,
			balance: 99696.49
		},
		{
			unixtime: 1657086972,
			balance: 99696.49
		},
		{
			unixtime: 1657087032,
			balance: 99696.49
		},
		{
			unixtime: 1657087092,
			balance: 99696.49
		},
		{
			unixtime: 1657087152,
			balance: 99696.49
		},
		{
			unixtime: 1657087212,
			balance: 99696.49
		},
		{
			unixtime: 1657087272,
			balance: 99696.49
		},
		{
			unixtime: 1657087332,
			balance: 99696.49
		},
		{
			unixtime: 1657087392,
			balance: 99696.49
		},
		{
			unixtime: 1657087452,
			balance: 99696.49
		},
		{
			unixtime: 1657087512,
			balance: 99696.49
		},
		{
			unixtime: 1657087572,
			balance: 99696.49
		},
		{
			unixtime: 1657087632,
			balance: 99696.49
		},
		{
			unixtime: 1657087692,
			balance: 99696.49
		},
		{
			unixtime: 1657087752,
			balance: 99696.49
		},
		{
			unixtime: 1657087812,
			balance: 99696.49
		},
		{
			unixtime: 1657087872,
			balance: 99696.49
		},
		{
			unixtime: 1657087932,
			balance: 99696.49
		},
		{
			unixtime: 1657087992,
			balance: 99696.49
		},
		{
			unixtime: 1657088052,
			balance: 99696.49
		},
		{
			unixtime: 1657088112,
			balance: 99696.49
		},
		{
			unixtime: 1657088172,
			balance: 99696.49
		},
		{
			unixtime: 1657088232,
			balance: 99696.49
		},
		{
			unixtime: 1657088292,
			balance: 99696.49
		},
		{
			unixtime: 1657088352,
			balance: 99696.49
		},
		{
			unixtime: 1657088412,
			balance: 99696.49
		},
		{
			unixtime: 1657088472,
			balance: 99696.49
		},
		{
			unixtime: 1657088532,
			balance: 99696.49
		},
		{
			unixtime: 1657088592,
			balance: 99696.49
		},
		{
			unixtime: 1657088652,
			balance: 99696.49
		},
		{
			unixtime: 1657088712,
			balance: 99696.49
		},
		{
			unixtime: 1657088772,
			balance: 99696.49
		},
		{
			unixtime: 1657088832,
			balance: 99696.49
		},
		{
			unixtime: 1657088892,
			balance: 99696.49
		},
		{
			unixtime: 1657088952,
			balance: 99696.49
		},
		{
			unixtime: 1657089012,
			balance: 99696.49
		},
		{
			unixtime: 1657089072,
			balance: 99696.49
		},
		{
			unixtime: 1657089132,
			balance: 99696.49
		},
		{
			unixtime: 1657089192,
			balance: 99696.49
		},
		{
			unixtime: 1657089252,
			balance: 99696.49
		},
		{
			unixtime: 1657089312,
			balance: 99696.49
		},
		{
			unixtime: 1657089372,
			balance: 99696.49
		},
		{
			unixtime: 1657089432,
			balance: 99696.49
		},
		{
			unixtime: 1657089492,
			balance: 99696.49
		},
		{
			unixtime: 1657089552,
			balance: 99696.49
		},
		{
			unixtime: 1657089612,
			balance: 99696.49
		},
		{
			unixtime: 1657089672,
			balance: 99696.49
		},
		{
			unixtime: 1657089732,
			balance: 99696.49
		},
		{
			unixtime: 1657089792,
			balance: 99696.49
		},
		{
			unixtime: 1657089852,
			balance: 99696.49
		},
		{
			unixtime: 1657089912,
			balance: 99696.49
		},
		{
			unixtime: 1657089972,
			balance: 99696.49
		},
		{
			unixtime: 1657090032,
			balance: 99696.49
		},
		{
			unixtime: 1657090092,
			balance: 99696.49
		},
		{
			unixtime: 1657090152,
			balance: 99696.49
		},
		{
			unixtime: 1657090212,
			balance: 99696.49
		},
		{
			unixtime: 1657090272,
			balance: 99696.49
		},
		{
			unixtime: 1657090332,
			balance: 99696.49
		},
		{
			unixtime: 1657090392,
			balance: 99696.49
		},
		{
			unixtime: 1657090452,
			balance: 99696.49
		},
		{
			unixtime: 1657090512,
			balance: 99696.49
		},
		{
			unixtime: 1657090572,
			balance: 99696.49
		},
		{
			unixtime: 1657090632,
			balance: 99696.49
		},
		{
			unixtime: 1657090692,
			balance: 99696.49
		},
		{
			unixtime: 1657090752,
			balance: 99696.49
		},
		{
			unixtime: 1657090812,
			balance: 99696.49
		},
		{
			unixtime: 1657090872,
			balance: 99696.49
		},
		{
			unixtime: 1657090932,
			balance: 99696.49
		},
		{
			unixtime: 1657090992,
			balance: 99696.49
		},
		{
			unixtime: 1657091052,
			balance: 99696.49
		},
		{
			unixtime: 1657091112,
			balance: 99696.49
		},
		{
			unixtime: 1657091172,
			balance: 99696.49
		},
		{
			unixtime: 1657091232,
			balance: 99696.49
		},
		{
			unixtime: 1657091292,
			balance: 99696.49
		},
		{
			unixtime: 1657091352,
			balance: 99696.49
		},
		{
			unixtime: 1657091412,
			balance: 99696.49
		},
		{
			unixtime: 1657091472,
			balance: 99696.49
		},
		{
			unixtime: 1657091532,
			balance: 99696.49
		},
		{
			unixtime: 1657091592,
			balance: 99696.49
		},
		{
			unixtime: 1657091652,
			balance: 99696.49
		},
		{
			unixtime: 1657091712,
			balance: 99696.49
		},
		{
			unixtime: 1657091772,
			balance: 99696.49
		},
		{
			unixtime: 1657091832,
			balance: 99696.49
		},
		{
			unixtime: 1657091892,
			balance: 99696.49
		},
		{
			unixtime: 1657091952,
			balance: 99696.49
		},
		{
			unixtime: 1657092012,
			balance: 99696.49
		},
		{
			unixtime: 1657092072,
			balance: 99696.49
		},
		{
			unixtime: 1657092132,
			balance: 99696.49
		},
		{
			unixtime: 1657092192,
			balance: 99696.49
		},
		{
			unixtime: 1657092252,
			balance: 99696.49
		},
		{
			unixtime: 1657092312,
			balance: 99696.49
		},
		{
			unixtime: 1657092372,
			balance: 99696.49
		},
		{
			unixtime: 1657092432,
			balance: 99696.49
		},
		{
			unixtime: 1657092492,
			balance: 99696.49
		},
		{
			unixtime: 1657092552,
			balance: 99696.49
		},
		{
			unixtime: 1657092612,
			balance: 99696.49
		},
		{
			unixtime: 1657092672,
			balance: 99696.49
		},
		{
			unixtime: 1657092732,
			balance: 99696.49
		},
		{
			unixtime: 1657092792,
			balance: 99696.49
		},
		{
			unixtime: 1657092852,
			balance: 99696.49
		},
		{
			unixtime: 1657092912,
			balance: 99696.49
		},
		{
			unixtime: 1657092972,
			balance: 99696.49
		},
		{
			unixtime: 1657093032,
			balance: 99696.49
		},
		{
			unixtime: 1657093092,
			balance: 99696.49
		},
		{
			unixtime: 1657093152,
			balance: 99696.49
		},
		{
			unixtime: 1657093212,
			balance: 99696.49
		},
		{
			unixtime: 1657093272,
			balance: 99696.49
		},
		{
			unixtime: 1657093332,
			balance: 99696.49
		},
		{
			unixtime: 1657093392,
			balance: 99696.49
		},
		{
			unixtime: 1657093452,
			balance: 99696.49
		},
		{
			unixtime: 1657093512,
			balance: 99696.49
		},
		{
			unixtime: 1657093572,
			balance: 99696.49
		},
		{
			unixtime: 1657093632,
			balance: 99696.49
		},
		{
			unixtime: 1657093692,
			balance: 99696.49
		},
		{
			unixtime: 1657093752,
			balance: 99696.49
		},
		{
			unixtime: 1657093812,
			balance: 99696.49
		},
		{
			unixtime: 1657093872,
			balance: 99696.49
		},
		{
			unixtime: 1657093932,
			balance: 99696.49
		},
		{
			unixtime: 1657093992,
			balance: 99696.49
		},
		{
			unixtime: 1657094052,
			balance: 99696.49
		},
		{
			unixtime: 1657094112,
			balance: 99696.49
		},
		{
			unixtime: 1657094172,
			balance: 99696.49
		},
		{
			unixtime: 1657094232,
			balance: 99696.49
		},
		{
			unixtime: 1657094292,
			balance: 99696.49
		},
		{
			unixtime: 1657094352,
			balance: 99696.49
		},
		{
			unixtime: 1657094412,
			balance: 99700.57
		},
		{
			unixtime: 1657094472,
			balance: 99703.45
		},
		{
			unixtime: 1657094532,
			balance: 99698.81
		},
		{
			unixtime: 1657094592,
			balance: 99695.71
		},
		{
			unixtime: 1657094652,
			balance: 99689.53
		},
		{
			unixtime: 1657094712,
			balance: 99677.75
		},
		{
			unixtime: 1657094772,
			balance: 99681.67
		},
		{
			unixtime: 1657094832,
			balance: 99685.87
		},
		{
			unixtime: 1657094892,
			balance: 99689.75
		},
		{
			unixtime: 1657094952,
			balance: 99697.41
		},
		{
			unixtime: 1657095012,
			balance: 99695.51
		},
		{
			unixtime: 1657095072,
			balance: 99695.51
		},
		{
			unixtime: 1657095132,
			balance: 99697.73
		},
		{
			unixtime: 1657095192,
			balance: 99697.73
		},
		{
			unixtime: 1657095252,
			balance: 99696.41
		},
		{
			unixtime: 1657095312,
			balance: 99697.73
		},
		{
			unixtime: 1657095372,
			balance: 99694.49
		},
		{
			unixtime: 1657095432,
			balance: 99686.53
		},
		{
			unixtime: 1657095492,
			balance: 99685.45
		},
		{
			unixtime: 1657095552,
			balance: 99684.49
		},
		{
			unixtime: 1657095612,
			balance: 99683.33
		},
		{
			unixtime: 1657095672,
			balance: 99683.21
		},
		{
			unixtime: 1657095732,
			balance: 99673.17
		},
		{
			unixtime: 1657095792,
			balance: 99674.85
		},
		{
			unixtime: 1657095852,
			balance: 99674.85
		},
		{
			unixtime: 1657095912,
			balance: 99674.97
		},
		{
			unixtime: 1657095972,
			balance: 99673.53
		},
		{
			unixtime: 1657096032,
			balance: 99669.33
		},
		{
			unixtime: 1657096092,
			balance: 99669.33
		},
		{
			unixtime: 1657096152,
			balance: 99670.69
		},
		{
			unixtime: 1657096212,
			balance: 99669.49
		},
		{
			unixtime: 1657096272,
			balance: 99663.17
		},
		{
			unixtime: 1657096332,
			balance: 99662.21
		},
		{
			unixtime: 1657096392,
			balance: 99662.09
		},
		{
			unixtime: 1657096452,
			balance: 99661.61
		},
		{
			unixtime: 1657096512,
			balance: 99660.53
		},
		{
			unixtime: 1657096572,
			balance: 99659.53
		},
		{
			unixtime: 1657096632,
			balance: 99660.73
		},
		{
			unixtime: 1657096692,
			balance: 99662.93
		},
		{
			unixtime: 1657096752,
			balance: 99658.77
		},
		{
			unixtime: 1657096812,
			balance: 99659.61
		},
		{
			unixtime: 1657096872,
			balance: 99659.41
		},
		{
			unixtime: 1657096932,
			balance: 99652.41
		},
		{
			unixtime: 1657096992,
			balance: 99651.65
		},
		{
			unixtime: 1657097052,
			balance: 99652.97
		},
		{
			unixtime: 1657097112,
			balance: 99652.73
		},
		{
			unixtime: 1657097172,
			balance: 99654.93
		},
		{
			unixtime: 1657097232,
			balance: 99656.49
		},
		{
			unixtime: 1657097292,
			balance: 99656.17
		},
		{
			unixtime: 1657097352,
			balance: 99655.45
		},
		{
			unixtime: 1657097412,
			balance: 99654.61
		},
		{
			unixtime: 1657097472,
			balance: 99654.17
		},
		{
			unixtime: 1657097532,
			balance: 99656.69
		},
		{
			unixtime: 1657097592,
			balance: 99656.13
		},
		{
			unixtime: 1657097652,
			balance: 99658.69
		},
		{
			unixtime: 1657097712,
			balance: 99661.13
		},
		{
			unixtime: 1657097772,
			balance: 99658.93
		},
		{
			unixtime: 1657097832,
			balance: 99657.97
		},
		{
			unixtime: 1657097892,
			balance: 99656.67
		},
		{
			unixtime: 1657097952,
			balance: 99656.67
		},
		{
			unixtime: 1657098012,
			balance: 99655.59
		},
		{
			unixtime: 1657098072,
			balance: 99655.95
		},
		{
			unixtime: 1657098132,
			balance: 99654.15
		},
		{
			unixtime: 1657098192,
			balance: 99651.95
		},
		{
			unixtime: 1657098252,
			balance: 99652.19
		},
		{
			unixtime: 1657098312,
			balance: 99651.69
		},
		{
			unixtime: 1657098373,
			balance: 99639.61
		},
		{
			unixtime: 1657098432,
			balance: 99641.05
		},
		{
			unixtime: 1657098492,
			balance: 99641.33
		},
		{
			unixtime: 1657098552,
			balance: 99641.15
		},
		{
			unixtime: 1657098612,
			balance: 99644.51
		},
		{
			unixtime: 1657098672,
			balance: 99643.79
		},
		{
			unixtime: 1657098732,
			balance: 99643.55
		},
		{
			unixtime: 1657098792,
			balance: 99641.51
		},
		{
			unixtime: 1657098852,
			balance: 99641.51
		},
		{
			unixtime: 1657098912,
			balance: 99640.43
		},
		{
			unixtime: 1657098972,
			balance: 99641.03
		},
		{
			unixtime: 1657099032,
			balance: 99638.15
		},
		{
			unixtime: 1657099092,
			balance: 99638.15
		},
		{
			unixtime: 1657099152,
			balance: 99642.83
		},
		{
			unixtime: 1657099212,
			balance: 99646.99
		},
		{
			unixtime: 1657099272,
			balance: 99647.35
		},
		{
			unixtime: 1657099332,
			balance: 99648.31
		},
		{
			unixtime: 1657099392,
			balance: 99648.31
		},
		{
			unixtime: 1657099452,
			balance: 99648.43
		},
		{
			unixtime: 1657099512,
			balance: 99649.99
		},
		{
			unixtime: 1657099572,
			balance: 99666.27
		},
		{
			unixtime: 1657099632,
			balance: 99666.27
		},
		{
			unixtime: 1657099692,
			balance: 99667.35
		},
		{
			unixtime: 1657099752,
			balance: 99667.59
		},
		{
			unixtime: 1657099812,
			balance: 99669.93
		},
		{
			unixtime: 1657099872,
			balance: 99667.65
		},
		{
			unixtime: 1657099932,
			balance: 99667.65
		},
		{
			unixtime: 1657099992,
			balance: 99666.93
		},
		{
			unixtime: 1657100052,
			balance: 99666.81
		},
		{
			unixtime: 1657100112,
			balance: 99665.85
		},
		{
			unixtime: 1657100172,
			balance: 99665.85
		},
		{
			unixtime: 1657100232,
			balance: 99665.37
		},
		{
			unixtime: 1657100292,
			balance: 99664.05
		},
		{
			unixtime: 1657100352,
			balance: 99663.81
		},
		{
			unixtime: 1657100412,
			balance: 99663.81
		},
		{
			unixtime: 1657100472,
			balance: 99663.81
		},
		{
			unixtime: 1657100532,
			balance: 99665.85
		},
		{
			unixtime: 1657100592,
			balance: 99668.49
		},
		{
			unixtime: 1657100652,
			balance: 99668.49
		},
		{
			unixtime: 1657100712,
			balance: 99668.49
		},
		{
			unixtime: 1657100772,
			balance: 99668.49
		},
		{
			unixtime: 1657100832,
			balance: 99668.97
		},
		{
			unixtime: 1657100892,
			balance: 99668.97
		},
		{
			unixtime: 1657100952,
			balance: 99668.97
		},
		{
			unixtime: 1657101012,
			balance: 99668.73
		},
		{
			unixtime: 1657101072,
			balance: 99668.97
		},
		{
			unixtime: 1657101132,
			balance: 99668.97
		},
		{
			unixtime: 1657101192,
			balance: 99668.97
		},
		{
			unixtime: 1657101252,
			balance: 99680.13
		},
		{
			unixtime: 1657101312,
			balance: 99680.13
		},
		{
			unixtime: 1657101372,
			balance: 99680.13
		},
		{
			unixtime: 1657101432,
			balance: 99679.29
		},
		{
			unixtime: 1657101492,
			balance: 99680.49
		},
		{
			unixtime: 1657101552,
			balance: 99685.13
		},
		{
			unixtime: 1657101612,
			balance: 99684.89
		},
		{
			unixtime: 1657101672,
			balance: 99685.37
		},
		{
			unixtime: 1657101732,
			balance: 99686.81
		},
		{
			unixtime: 1657101792,
			balance: 99686.09
		},
		{
			unixtime: 1657101852,
			balance: 99686.09
		},
		{
			unixtime: 1657101912,
			balance: 99684.19
		},
		{
			unixtime: 1657101972,
			balance: 99684.19
		},
		{
			unixtime: 1657102032,
			balance: 99684.19
		},
		{
			unixtime: 1657102092,
			balance: 99688.97
		},
		{
			unixtime: 1657102152,
			balance: 99686.61
		},
		{
			unixtime: 1657102212,
			balance: 99686.61
		},
		{
			unixtime: 1657102272,
			balance: 99683.49
		},
		{
			unixtime: 1657102332,
			balance: 99682.65
		},
		{
			unixtime: 1657102392,
			balance: 99683.61
		},
		{
			unixtime: 1657102452,
			balance: 99683.25
		},
		{
			unixtime: 1657102512,
			balance: 99683.37
		},
		{
			unixtime: 1657102573,
			balance: 99684.69
		},
		{
			unixtime: 1657102632,
			balance: 99683.25
		},
		{
			unixtime: 1657102692,
			balance: 99683.25
		},
		{
			unixtime: 1657102752,
			balance: 99685.41
		},
		{
			unixtime: 1657102812,
			balance: 99685.41
		},
		{
			unixtime: 1657102873,
			balance: 99683.13
		},
		{
			unixtime: 1657102932,
			balance: 99683.13
		},
		{
			unixtime: 1657102993,
			balance: 99685.05
		},
		{
			unixtime: 1657103053,
			balance: 99687.33
		},
		{
			unixtime: 1657103113,
			balance: 99687.93
		},
		{
			unixtime: 1657103173,
			balance: 99689.15
		},
		{
			unixtime: 1657103232,
			balance: 99689.15
		},
		{
			unixtime: 1657103293,
			balance: 99686.79
		},
		{
			unixtime: 1657103353,
			balance: 99688.35
		},
		{
			unixtime: 1657103412,
			balance: 99688.47
		},
		{
			unixtime: 1657103472,
			balance: 99689.07
		},
		{
			unixtime: 1657103533,
			balance: 99688.35
		},
		{
			unixtime: 1657103593,
			balance: 99690.03
		},
		{
			unixtime: 1657103652,
			balance: 99688.83
		},
		{
			unixtime: 1657103713,
			balance: 99688.95
		},
		{
			unixtime: 1657103772,
			balance: 99688.95
		},
		{
			unixtime: 1657103832,
			balance: 99685.93
		},
		{
			unixtime: 1657103893,
			balance: 99685.93
		},
		{
			unixtime: 1657103952,
			balance: 99685.93
		},
		{
			unixtime: 1657104013,
			balance: 99685.93
		},
		{
			unixtime: 1657104073,
			balance: 99681.25
		},
		{
			unixtime: 1657104133,
			balance: 99678.69
		},
		{
			unixtime: 1657104193,
			balance: 99677.77
		},
		{
			unixtime: 1657104253,
			balance: 99677.3216
		},
		{
			unixtime: 1657104313,
			balance: 99677.2316
		},
		{
			unixtime: 1657104373,
			balance: 99674.5316
		},
		{
			unixtime: 1657104433,
			balance: 99676.3316
		},
		{
			unixtime: 1657104493,
			balance: 99676.4716
		},
		{
			unixtime: 1657104553,
			balance: 99676.4716
		},
		{
			unixtime: 1657104613,
			balance: 99676.1516
		},
		{
			unixtime: 1657104673,
			balance: 99676.3416
		},
		{
			unixtime: 1657104733,
			balance: 99675.9416
		},
		{
			unixtime: 1657104793,
			balance: 99673.9616
		},
		{
			unixtime: 1657104853,
			balance: 99671.4416
		},
		{
			unixtime: 1657104913,
			balance: 99672.0116
		},
		{
			unixtime: 1657104973,
			balance: 99672.4216
		},
		{
			unixtime: 1657105033,
			balance: 99673.3116
		},
		{
			unixtime: 1657105093,
			balance: 99673.7716
		},
		{
			unixtime: 1657105153,
			balance: 99673.6916
		},
		{
			unixtime: 1657105213,
			balance: 99669.1216
		},
		{
			unixtime: 1657105273,
			balance: 99667.3216
		},
		{
			unixtime: 1657105333,
			balance: 99667.8416
		},
		{
			unixtime: 1657105393,
			balance: 99666.9716
		},
		{
			unixtime: 1657105453,
			balance: 99660.3416
		},
		{
			unixtime: 1657105513,
			balance: 99658.9116
		},
		{
			unixtime: 1657105573,
			balance: 99663.1116
		},
		{
			unixtime: 1657105633,
			balance: 99662.5516
		},
		{
			unixtime: 1657105693,
			balance: 99661.3816
		},
		{
			unixtime: 1657105753,
			balance: 99662.4416
		},
		{
			unixtime: 1657105813,
			balance: 99662.5116
		},
		{
			unixtime: 1657105873,
			balance: 99662.7016
		},
		{
			unixtime: 1657105933,
			balance: 99662.5516
		},
		{
			unixtime: 1657105993,
			balance: 99661.5216
		},
		{
			unixtime: 1657106053,
			balance: 99660.1616
		},
		{
			unixtime: 1657106113,
			balance: 99661.6516
		},
		{
			unixtime: 1657106173,
			balance: 99660.8516
		},
		{
			unixtime: 1657106233,
			balance: 99655.7816
		},
		{
			unixtime: 1657106293,
			balance: 99657.9016
		},
		{
			unixtime: 1657106353,
			balance: 99657.4716
		},
		{
			unixtime: 1657106413,
			balance: 99657.5916
		},
		{
			unixtime: 1657106473,
			balance: 99656.0916
		},
		{
			unixtime: 1657106533,
			balance: 99655.9416
		},
		{
			unixtime: 1657106593,
			balance: 99655.6516
		},
		{
			unixtime: 1657106653,
			balance: 99656.2416
		},
		{
			unixtime: 1657106713,
			balance: 99656.6916
		},
		{
			unixtime: 1657106773,
			balance: 99658.9716
		},
		{
			unixtime: 1657106833,
			balance: 99655.7716
		},
		{
			unixtime: 1657106893,
			balance: 99653.5716
		},
		{
			unixtime: 1657106953,
			balance: 99654.0016
		},
		{
			unixtime: 1657107013,
			balance: 99652.7716
		},
		{
			unixtime: 1657107073,
			balance: 99653.3316
		},
		{
			unixtime: 1657107133,
			balance: 99654.7316
		},
		{
			unixtime: 1657107193,
			balance: 99654.7616
		},
		{
			unixtime: 1657107253,
			balance: 99653.4116
		},
		{
			unixtime: 1657107313,
			balance: 99655.6816
		},
		{
			unixtime: 1657107373,
			balance: 99655.6716
		},
		{
			unixtime: 1657107433,
			balance: 99655.1916
		},
		{
			unixtime: 1657107493,
			balance: 99656.2116
		},
		{
			unixtime: 1657107553,
			balance: 99657.8316
		},
		{
			unixtime: 1657107613,
			balance: 99661.1516
		},
		{
			unixtime: 1657107673,
			balance: 99659.7116
		},
		{
			unixtime: 1657107733,
			balance: 99652.7516
		},
		{
			unixtime: 1657107793,
			balance: 99649.0116
		},
		{
			unixtime: 1657107853,
			balance: 99648.2016
		},
		{
			unixtime: 1657107913,
			balance: 99645.0816
		},
		{
			unixtime: 1657107973,
			balance: 99637.1416
		},
		{
			unixtime: 1657108033,
			balance: 99635.0016
		},
		{
			unixtime: 1657108093,
			balance: 99633.0316
		},
		{
			unixtime: 1657108153,
			balance: 99634.8616
		},
		{
			unixtime: 1657108213,
			balance: 99632.4016
		},
		{
			unixtime: 1657108273,
			balance: 99630.1316
		},
		{
			unixtime: 1657108333,
			balance: 99631.5716
		},
		{
			unixtime: 1657108393,
			balance: 99630.2916
		},
		{
			unixtime: 1657108453,
			balance: 99630.8916
		},
		{
			unixtime: 1657108513,
			balance: 99634.4916
		},
		{
			unixtime: 1657108573,
			balance: 99635.2116
		},
		{
			unixtime: 1657108633,
			balance: 99635.4516
		},
		{
			unixtime: 1657108693,
			balance: 99635.2116
		},
		{
			unixtime: 1657108753,
			balance: 99634.4116
		},
		{
			unixtime: 1657108813,
			balance: 99635.1916
		},
		{
			unixtime: 1657108873,
			balance: 99634.9316
		},
		{
			unixtime: 1657108933,
			balance: 99632.8316
		},
		{
			unixtime: 1657108993,
			balance: 99626.8316
		},
		{
			unixtime: 1657109053,
			balance: 99618.0316
		},
		{
			unixtime: 1657109113,
			balance: 99621.9016
		},
		{
			unixtime: 1657109173,
			balance: 99619.3216
		},
		{
			unixtime: 1657109233,
			balance: 99628.4416
		},
		{
			unixtime: 1657109293,
			balance: 99630.9116
		},
		{
			unixtime: 1657109353,
			balance: 99629.0616
		},
		{
			unixtime: 1657109413,
			balance: 99625.8316
		},
		{
			unixtime: 1657109473,
			balance: 99625.9016
		},
		{
			unixtime: 1657109533,
			balance: 99624.1916
		},
		{
			unixtime: 1657109593,
			balance: 99622.2916
		},
		{
			unixtime: 1657109653,
			balance: 99626.8616
		},
		{
			unixtime: 1657109713,
			balance: 99631.6816
		},
		{
			unixtime: 1657109773,
			balance: 99629.5716
		},
		{
			unixtime: 1657109833,
			balance: 99631.5016
		},
		{
			unixtime: 1657109893,
			balance: 99635.5016
		},
		{
			unixtime: 1657109953,
			balance: 99635.4416
		},
		{
			unixtime: 1657110013,
			balance: 99636.8816
		},
		{
			unixtime: 1657110073,
			balance: 99644.8016
		},
		{
			unixtime: 1657110133,
			balance: 99643.7116
		},
		{
			unixtime: 1657110193,
			balance: 99643.6316
		},
		{
			unixtime: 1657110253,
			balance: 99643.7616
		},
		{
			unixtime: 1657110313,
			balance: 99642.9916
		},
		{
			unixtime: 1657110373,
			balance: 99645.1916
		},
		{
			unixtime: 1657110433,
			balance: 99642.6116
		},
		{
			unixtime: 1657110493,
			balance: 99636.2316
		},
		{
			unixtime: 1657110553,
			balance: 99635.7716
		},
		{
			unixtime: 1657110613,
			balance: 99638.4916
		},
		{
			unixtime: 1657110673,
			balance: 99641.4016
		},
		{
			unixtime: 1657110733,
			balance: 99644.5916
		},
		{
			unixtime: 1657110793,
			balance: 99644.9716
		},
		{
			unixtime: 1657110853,
			balance: 99645.6916
		},
		{
			unixtime: 1657110913,
			balance: 99646.8516
		},
		{
			unixtime: 1657110973,
			balance: 99653.2416
		},
		{
			unixtime: 1657111033,
			balance: 99653.5616
		},
		{
			unixtime: 1657111093,
			balance: 99650.8616
		},
		{
			unixtime: 1657111153,
			balance: 99652.1916
		},
		{
			unixtime: 1657111213,
			balance: 99653.0716
		},
		{
			unixtime: 1657111273,
			balance: 99648.2516
		},
		{
			unixtime: 1657111333,
			balance: 99654.7316
		},
		{
			unixtime: 1657111393,
			balance: 99656.7616
		},
		{
			unixtime: 1657111453,
			balance: 99664.6916
		},
		{
			unixtime: 1657111513,
			balance: 99662.5716
		},
		{
			unixtime: 1657111573,
			balance: 99664.0916
		},
		{
			unixtime: 1657111633,
			balance: 99667.3116
		},
		{
			unixtime: 1657111693,
			balance: 99669.2616
		},
		{
			unixtime: 1657111753,
			balance: 99667.4716
		},
		{
			unixtime: 1657111813,
			balance: 99662.3616
		},
		{
			unixtime: 1657111873,
			balance: 99663.1216
		},
		{
			unixtime: 1657111933,
			balance: 99660.5616
		},
		{
			unixtime: 1657111993,
			balance: 99654.8716
		},
		{
			unixtime: 1657112053,
			balance: 99656.1916
		},
		{
			unixtime: 1657112113,
			balance: 99653.5316
		},
		{
			unixtime: 1657112173,
			balance: 99650.9316
		},
		{
			unixtime: 1657112233,
			balance: 99647.6716
		},
		{
			unixtime: 1657112293,
			balance: 99646.9316
		},
		{
			unixtime: 1657112353,
			balance: 99651.5316
		},
		{
			unixtime: 1657112413,
			balance: 99650.7516
		},
		{
			unixtime: 1657112473,
			balance: 99655.6716
		},
		{
			unixtime: 1657112533,
			balance: 99657.1116
		},
		{
			unixtime: 1657112593,
			balance: 99654.3916
		},
		{
			unixtime: 1657112653,
			balance: 99654.5316
		},
		{
			unixtime: 1657112713,
			balance: 99651.3316
		},
		{
			unixtime: 1657112773,
			balance: 99652.4316
		},
		{
			unixtime: 1657112833,
			balance: 99652.4116
		},
		{
			unixtime: 1657112893,
			balance: 99657.9716
		},
		{
			unixtime: 1657112953,
			balance: 99655.9516
		},
		{
			unixtime: 1657113013,
			balance: 99660.2116
		},
		{
			unixtime: 1657113073,
			balance: 99660.6016
		},
		{
			unixtime: 1657113133,
			balance: 99659.9916
		},
		{
			unixtime: 1657113193,
			balance: 99661.9416
		},
		{
			unixtime: 1657113253,
			balance: 99668.0316
		},
		{
			unixtime: 1657113313,
			balance: 99666.2416
		},
		{
			unixtime: 1657113373,
			balance: 99659.4216
		},
		{
			unixtime: 1657113433,
			balance: 99658.3016
		},
		{
			unixtime: 1657113493,
			balance: 99656.8416
		},
		{
			unixtime: 1657113553,
			balance: 99660.1016
		},
		{
			unixtime: 1657113613,
			balance: 99661.0216
		},
		{
			unixtime: 1657113673,
			balance: 99665.0216
		},
		{
			unixtime: 1657113733,
			balance: 99665.5316
		},
		{
			unixtime: 1657113793,
			balance: 99666.8216
		},
		{
			unixtime: 1657113853,
			balance: 99673.2416
		},
		{
			unixtime: 1657113913,
			balance: 99672.9316
		},
		{
			unixtime: 1657113973,
			balance: 99674.5016
		},
		{
			unixtime: 1657114033,
			balance: 99673.6516
		},
		{
			unixtime: 1657114093,
			balance: 99663.9216
		},
		{
			unixtime: 1657114153,
			balance: 99667.0816
		},
		{
			unixtime: 1657114213,
			balance: 99666.9716
		},
		{
			unixtime: 1657114273,
			balance: 99675.5016
		},
		{
			unixtime: 1657114333,
			balance: 99690.0816
		},
		{
			unixtime: 1657114393,
			balance: 99713.1116
		},
		{
			unixtime: 1657114453,
			balance: 99716.3416
		},
		{
			unixtime: 1657114513,
			balance: 99679.7016
		},
		{
			unixtime: 1657114573,
			balance: 99675.3016
		},
		{
			unixtime: 1657114633,
			balance: 99665.8116
		},
		{
			unixtime: 1657114693,
			balance: 99649.3316
		},
		{
			unixtime: 1657114753,
			balance: 99648.0116
		},
		{
			unixtime: 1657114813,
			balance: 99653.8516
		},
		{
			unixtime: 1657114873,
			balance: 99651.7016
		},
		{
			unixtime: 1657114933,
			balance: 99642.6916
		},
		{
			unixtime: 1657114993,
			balance: 99651.5916
		},
		{
			unixtime: 1657115053,
			balance: 99649.1316
		},
		{
			unixtime: 1657115113,
			balance: 99648.5816
		},
		{
			unixtime: 1657115173,
			balance: 99614.5516
		},
		{
			unixtime: 1657115233,
			balance: 99625.5616
		},
		{
			unixtime: 1657115293,
			balance: 99627.4416
		},
		{
			unixtime: 1657115353,
			balance: 99637.2016
		},
		{
			unixtime: 1657115413,
			balance: 99619.1616
		},
		{
			unixtime: 1657115473,
			balance: 99625.7616
		},
		{
			unixtime: 1657115533,
			balance: 99611.3016
		},
		{
			unixtime: 1657115593,
			balance: 99621.1816
		},
		{
			unixtime: 1657115653,
			balance: 99615.3416
		},
		{
			unixtime: 1657115713,
			balance: 99613.9516
		},
		{
			unixtime: 1657115773,
			balance: 99624.8016
		},
		{
			unixtime: 1657115833,
			balance: 99631.6216
		},
		{
			unixtime: 1657115893,
			balance: 99642.5716
		},
		{
			unixtime: 1657115953,
			balance: 99644.3716
		},
		{
			unixtime: 1657116013,
			balance: 99639.3016
		},
		{
			unixtime: 1657116073,
			balance: 99621.6016
		},
		{
			unixtime: 1657116133,
			balance: 99607.8016
		},
		{
			unixtime: 1657116193,
			balance: 99628.5116
		},
		{
			unixtime: 1657116253,
			balance: 99636.0216
		},
		{
			unixtime: 1657116313,
			balance: 99629.3616
		},
		{
			unixtime: 1657116373,
			balance: 99654.2416
		},
		{
			unixtime: 1657116433,
			balance: 99654.1416
		},
		{
			unixtime: 1657116493,
			balance: 99679.2516
		},
		{
			unixtime: 1657116553,
			balance: 99673.9116
		},
		{
			unixtime: 1657116613,
			balance: 99679.7116
		},
		{
			unixtime: 1657116673,
			balance: 99661.3016
		},
		{
			unixtime: 1657116733,
			balance: 99664.0716
		},
		{
			unixtime: 1657116793,
			balance: 99651.8716
		},
		{
			unixtime: 1657116853,
			balance: 99621.1516
		},
		{
			unixtime: 1657116913,
			balance: 99632.1616
		},
		{
			unixtime: 1657116973,
			balance: 99621.9316
		},
		{
			unixtime: 1657117033,
			balance: 99629.6316
		},
		{
			unixtime: 1657117093,
			balance: 99621.6916
		},
		{
			unixtime: 1657117153,
			balance: 99632.7716
		},
		{
			unixtime: 1657117213,
			balance: 99627.0216
		},
		{
			unixtime: 1657117273,
			balance: 99628.2116
		},
		{
			unixtime: 1657117333,
			balance: 99616.6216
		},
		{
			unixtime: 1657117393,
			balance: 99628.9316
		},
		{
			unixtime: 1657117453,
			balance: 99642.9316
		},
		{
			unixtime: 1657117513,
			balance: 99631.4516
		},
		{
			unixtime: 1657117573,
			balance: 99640.8216
		},
		{
			unixtime: 1657117633,
			balance: 99650.8116
		},
		{
			unixtime: 1657117693,
			balance: 99645.0916
		},
		{
			unixtime: 1657117753,
			balance: 99647.0716
		},
		{
			unixtime: 1657117813,
			balance: 99644.9516
		},
		{
			unixtime: 1657117873,
			balance: 99646.0216
		},
		{
			unixtime: 1657117933,
			balance: 99652.2516
		},
		{
			unixtime: 1657117993,
			balance: 99635.6316
		},
		{
			unixtime: 1657118053,
			balance: 99637.5716
		},
		{
			unixtime: 1657118113,
			balance: 99636.4016
		},
		{
			unixtime: 1657118173,
			balance: 99642.4916
		},
		{
			unixtime: 1657118233,
			balance: 99641.3816
		},
		{
			unixtime: 1657118293,
			balance: 99641.8216
		},
		{
			unixtime: 1657118353,
			balance: 99642.9116
		},
		{
			unixtime: 1657118413,
			balance: 99641.5816
		},
		{
			unixtime: 1657118473,
			balance: 99632.0116
		},
		{
			unixtime: 1657118533,
			balance: 99631.1416
		},
		{
			unixtime: 1657118593,
			balance: 99647.5516
		},
		{
			unixtime: 1657118653,
			balance: 99649.4016
		},
		{
			unixtime: 1657118713,
			balance: 99655.1016
		},
		{
			unixtime: 1657118773,
			balance: 99655.7316
		},
		{
			unixtime: 1657118833,
			balance: 99647.3516
		},
		{
			unixtime: 1657118893,
			balance: 99647.5916
		},
		{
			unixtime: 1657118953,
			balance: 99635.8416
		},
		{
			unixtime: 1657119013,
			balance: 99630.8416
		},
		{
			unixtime: 1657119073,
			balance: 99632.1516
		},
		{
			unixtime: 1657119133,
			balance: 99642.0616
		},
		{
			unixtime: 1657119193,
			balance: 99637.3616
		},
		{
			unixtime: 1657119253,
			balance: 99636.7516
		},
		{
			unixtime: 1657119313,
			balance: 99630.3216
		},
		{
			unixtime: 1657119373,
			balance: 99633.4116
		},
		{
			unixtime: 1657119433,
			balance: 99637.0716
		},
		{
			unixtime: 1657119493,
			balance: 99640.1716
		},
		{
			unixtime: 1657119553,
			balance: 99646.0116
		},
		{
			unixtime: 1657119613,
			balance: 99644.7416
		},
		{
			unixtime: 1657119673,
			balance: 99641.2516
		},
		{
			unixtime: 1657119733,
			balance: 99639.3916
		},
		{
			unixtime: 1657119793,
			balance: 99630.9316
		},
		{
			unixtime: 1657119853,
			balance: 99612.9316
		},
		{
			unixtime: 1657119913,
			balance: 99621.6116
		},
		{
			unixtime: 1657119973,
			balance: 99619.4716
		},
		{
			unixtime: 1657120033,
			balance: 99615.2316
		},
		{
			unixtime: 1657120093,
			balance: 99611.5316
		},
		{
			unixtime: 1657120153,
			balance: 99617.7916
		},
		{
			unixtime: 1657120213,
			balance: 99609.7216
		},
		{
			unixtime: 1657120273,
			balance: 99606.5616
		},
		{
			unixtime: 1657120333,
			balance: 99616.3816
		},
		{
			unixtime: 1657120393,
			balance: 99617.7216
		},
		{
			unixtime: 1657120453,
			balance: 99615.9716
		},
		{
			unixtime: 1657120513,
			balance: 99618.3216
		},
		{
			unixtime: 1657120573,
			balance: 99619.3716
		},
		{
			unixtime: 1657120633,
			balance: 99631.5816
		},
		{
			unixtime: 1657120693,
			balance: 99630.6016
		},
		{
			unixtime: 1657120753,
			balance: 99628.5716
		},
		{
			unixtime: 1657120813,
			balance: 99624.7016
		},
		{
			unixtime: 1657120873,
			balance: 99628.0416
		},
		{
			unixtime: 1657120933,
			balance: 99623.3816
		},
		{
			unixtime: 1657120993,
			balance: 99620.6516
		},
		{
			unixtime: 1657121053,
			balance: 99625.1816
		},
		{
			unixtime: 1657121113,
			balance: 99620.8216
		},
		{
			unixtime: 1657121173,
			balance: 99616.9516
		},
		{
			unixtime: 1657121233,
			balance: 99609.4716
		},
		{
			unixtime: 1657121293,
			balance: 99608.0916
		},
		{
			unixtime: 1657121353,
			balance: 99605.3616
		},
		{
			unixtime: 1657121413,
			balance: 99605.3116
		},
		{
			unixtime: 1657121473,
			balance: 99591.6816
		},
		{
			unixtime: 1657121533,
			balance: 99590.6016
		},
		{
			unixtime: 1657121593,
			balance: 99579.5816
		},
		{
			unixtime: 1657121653,
			balance: 99578.6216
		},
		{
			unixtime: 1657121713,
			balance: 99590.5716
		}
	],
	interday: [
		{
			unixtime: 1656336739,
			balance: 100001.54
		},
		{
			unixtime: 1656336740,
			balance: 99998.52
		},
		{
			unixtime: 1656336752,
			balance: 99983.88
		},
		{
			unixtime: 1656374400,
			balance: 99939.28
		},
		{
			unixtime: 1656460800,
			balance: 99776.06
		},
		{
			unixtime: 1656547200,
			balance: 99685.96
		},
		{
			unixtime: 1656633600,
			balance: 99574.26
		},
		{
			unixtime: 1656720000,
			balance: 99672.08
		},
		{
			unixtime: 1656806400,
			balance: 99672.08
		},
		{
			unixtime: 1656892800,
			balance: 99672.08
		},
		{
			unixtime: 1656979200,
			balance: 99672.08
		},
		{
			unixtime: 1657065600,
			balance: 99696.49
		}
	]
};

const mericsChartData = [
	{
	  0: 3.33,
	  date: "2022-08-09T00:00:00.000Z"
	},
	{
	  0: 3.3,
	  date: "2022-08-08T00:00:00.000Z"
	},
	{
	  0: 3.29,
	  date: "2022-08-05T00:00:00.000Z"
	},
	{
	  0: 3.11,
	  date: "2022-08-04T00:00:00.000Z"
	},
	{
	  0: 3.14,
	  date: "2022-08-03T00:00:00.000Z"
	},
	{
	  0: 3.09,
	  date: "2022-08-02T00:00:00.000Z"
	},
	{
	  0: 2.98,
	  date: "2022-08-01T00:00:00.000Z"
	},
	{
	  0: 2.98,
	  date: "2022-07-29T00:00:00.000Z"
	},
	{
	  0: 2.93,
	  date: "2022-07-28T00:00:00.000Z"
	},
	{
	  0: 3,
	  date: "2022-07-27T00:00:00.000Z"
	},
	{
	  0: 3.06,
	  date: "2022-07-26T00:00:00.000Z"
	},
	{
	  0: 3.07,
	  date: "2022-07-25T00:00:00.000Z"
	},
	{
	  0: 3.01,
	  date: "2022-07-22T00:00:00.000Z"
	},
	{
	  0: 3.11,
	  date: "2022-07-21T00:00:00.000Z"
	},
	{
	  0: 3.18,
	  date: "2022-07-20T00:00:00.000Z"
	},
	{
	  0: 3.18,
	  date: "2022-07-19T00:00:00.000Z"
	},
	{
	  0: 3.13,
	  date: "2022-07-18T00:00:00.000Z"
	},
	{
	  0: 3.12,
	  date: "2022-07-15T00:00:00.000Z"
	},
	{
	  0: 3.16,
	  date: "2022-07-14T00:00:00.000Z"
	},
	{
	  0: 3.21,
	  date: "2022-07-13T00:00:00.000Z"
	},
	{
	  0: 3.07,
	  date: "2022-07-12T00:00:00.000Z"
	},
	{
	  0: 2.97,
	  date: "2022-07-11T00:00:00.000Z"
	},
	{
	  0: 2.96,
	  date: "2022-07-08T00:00:00.000Z"
	},
	{
	  0: 2.87,
	  date: "2022-07-07T00:00:00.000Z"
	},
	{
	  0: 2.82,
	  date: "2022-07-06T00:00:00.000Z"
	},
	{
	  0: 2.77,
	  date: "2022-07-05T00:00:00.000Z"
	},
	{
	  0: null,
	  date: "2022-07-04T00:00:00.000Z"
	},
	{
	  0: 2.79,
	  date: "2022-07-01T00:00:00.000Z"
	},
	{
	  0: 2.8,
	  date: "2022-06-30T00:00:00.000Z"
	},
	{
	  0: 2.88,
	  date: "2022-06-29T00:00:00.000Z"
	},
	{
	  0: 2.88,
	  date: "2022-06-28T00:00:00.000Z"
	},
	{
	  0: 2.89,
	  date: "2022-06-27T00:00:00.000Z"
	},
	{
	  0: 2.83,
	  date: "2022-06-24T00:00:00.000Z"
	},
	{
	  0: 2.78,
	  date: "2022-06-23T00:00:00.000Z"
	},
	{
	  0: 2.79,
	  date: "2022-06-22T00:00:00.000Z"
	},
	{
	  0: 2.92,
	  date: "2022-06-21T00:00:00.000Z"
	},
	{
	  0: null,
	  date: "2022-06-20T00:00:00.000Z"
	},
	{
	  0: 2.86,
	  date: "2022-06-17T00:00:00.000Z"
	},
	{
	  0: 2.88,
	  date: "2022-06-16T00:00:00.000Z"
	},
	{
	  0: 2.93,
	  date: "2022-06-15T00:00:00.000Z"
	},
	{
	  0: 3.15,
	  date: "2022-06-14T00:00:00.000Z"
	},
	{
	  0: 2.89,
	  date: "2022-06-13T00:00:00.000Z"
	},
	{
	  0: 2.58,
	  date: "2022-06-10T00:00:00.000Z"
	},
	{
	  0: 2.35,
	  date: "2022-06-09T00:00:00.000Z"
	},
	{
	  0: 2.29,
	  date: "2022-06-08T00:00:00.000Z"
	},
	{
	  0: 2.26,
	  date: "2022-06-07T00:00:00.000Z"
	},
	{
	  0: 2.23,
	  date: "2022-06-06T00:00:00.000Z"
	},
	{
	  0: 2.18,
	  date: "2022-06-03T00:00:00.000Z"
	},
	{
	  0: 2.15,
	  date: "2022-06-02T00:00:00.000Z"
	},
	{
	  0: 2.16,
	  date: "2022-06-01T00:00:00.000Z"
	},
	{
	  0: 2.08,
	  date: "2022-05-31T00:00:00.000Z"
	},
	{
	  0: null,
	  date: "2022-05-30T00:00:00.000Z"
	},
	{
	  0: 2.01,
	  date: "2022-05-27T00:00:00.000Z"
	},
	{
	  0: 1.99,
	  date: "2022-05-26T00:00:00.000Z"
	},
	{
	  0: 2.01,
	  date: "2022-05-25T00:00:00.000Z"
	},
	{
	  0: 2.02,
	  date: "2022-05-24T00:00:00.000Z"
	},
	{
	  0: 2.09,
	  date: "2022-05-23T00:00:00.000Z"
	},
	{
	  0: 2.07,
	  date: "2022-05-20T00:00:00.000Z"
	},
	{
	  0: 2.11,
	  date: "2022-05-19T00:00:00.000Z"
	},
	{
	  0: 2.16,
	  date: "2022-05-18T00:00:00.000Z"
	},
	{
	  0: 2.16,
	  date: "2022-05-17T00:00:00.000Z"
	},
	{
	  0: 2.07,
	  date: "2022-05-16T00:00:00.000Z"
	},
	{
	  0: 2.04,
	  date: "2022-05-13T00:00:00.000Z"
	},
	{
	  0: 1.96,
	  date: "2022-05-12T00:00:00.000Z"
	},
	{
	  0: 1.99,
	  date: "2022-05-11T00:00:00.000Z"
	},
	{
	  0: 2.01,
	  date: "2022-05-10T00:00:00.000Z"
	},
	{
	  0: 1.99,
	  date: "2022-05-09T00:00:00.000Z"
	},
	{
	  0: 2.08,
	  date: "2022-05-06T00:00:00.000Z"
	},
	{
	  0: 2.08,
	  date: "2022-05-05T00:00:00.000Z"
	},
	{
	  0: 2.07,
	  date: "2022-05-04T00:00:00.000Z"
	},
	{
	  0: 2.16,
	  date: "2022-05-03T00:00:00.000Z"
	},
	{
	  0: 2.1,
	  date: "2022-05-02T00:00:00.000Z"
	},
	{
	  0: 2.1,
	  date: "2022-04-29T00:00:00.000Z"
	},
	{
	  0: 2.04,
	  date: "2022-04-28T00:00:00.000Z"
	},
	{
	  0: 1.97,
	  date: "2022-04-27T00:00:00.000Z"
	},
	{
	  0: 1.99,
	  date: "2022-04-26T00:00:00.000Z"
	},
	{
	  0: 2.03,
	  date: "2022-04-25T00:00:00.000Z"
	},
	{
	  0: 2.06,
	  date: "2022-04-22T00:00:00.000Z"
	},
	{
	  0: 2.01,
	  date: "2022-04-21T00:00:00.000Z"
	},
	{
	  0: 1.93,
	  date: "2022-04-20T00:00:00.000Z"
	},
	{
	  0: 1.94,
	  date: "2022-04-19T00:00:00.000Z"
	},
	{
	  0: 1.84,
	  date: "2022-04-18T00:00:00.000Z"
	},
	{
	  0: null,
	  date: "2022-04-15T00:00:00.000Z"
	},
	{
	  0: 1.84,
	  date: "2022-04-14T00:00:00.000Z"
	},
	{
	  0: 1.78,
	  date: "2022-04-13T00:00:00.000Z"
	},
	{
	  0: 1.77,
	  date: "2022-04-12T00:00:00.000Z"
	},
	{
	  0: 1.85,
	  date: "2022-04-11T00:00:00.000Z"
	},
	{
	  0: 1.81,
	  date: "2022-04-08T00:00:00.000Z"
	},
	{
	  0: 1.78,
	  date: "2022-04-07T00:00:00.000Z"
	},
	{
	  0: 1.79,
	  date: "2022-04-06T00:00:00.000Z"
	}
  ];

const linearChartData = {
	ticker: 'AAPL',
	queryCount: 4530,
	resultsCount: 4530,
	adjusted: true,
	results: [
		{
			open: 148.95,
			close: 149.1,
			volume: 1470,
			time: 1654070400000,
			lowest: 148.95,
			highest: 149.48,
			volumePrice: 149.0892,
			transactionCount: 36,
			voluemPrice: 149.0892
		},
		{
			open: 149.18,
			close: 149.08,
			volume: 689,
			time: 1654070460000,
			lowest: 149.08,
			highest: 149.18,
			volumePrice: 149.1247,
			transactionCount: 20,
			voluemPrice: 149.1247
		},
		{
			open: 149.08,
			close: 149.11,
			volume: 981,
			time: 1654070520000,
			lowest: 149.07,
			highest: 149.11,
			volumePrice: 149.0865,
			transactionCount: 22,
			voluemPrice: 149.0865
		},
		{
			open: 149.17,
			close: 149.17,
			volume: 331,
			time: 1654070700000,
			lowest: 149.17,
			highest: 149.17,
			volumePrice: 149.1673,
			transactionCount: 10,
			voluemPrice: 149.1673
		},
		{
			open: 149.07,
			close: 148.9,
			volume: 5131,
			time: 1654070760000,
			lowest: 148.9,
			highest: 149.07,
			volumePrice: 149.0249,
			transactionCount: 65,
			voluemPrice: 149.0249
		},
		{
			open: 148.97,
			close: 148.8,
			volume: 4572,
			time: 1654070820000,
			lowest: 148.56,
			highest: 148.97,
			volumePrice: 148.8224,
			transactionCount: 72,
			voluemPrice: 148.8224
		},
		{
			open: 148.64,
			close: 148.62,
			volume: 726,
			time: 1654070880000,
			lowest: 148.62,
			highest: 148.64,
			volumePrice: 148.6505,
			transactionCount: 19,
			voluemPrice: 148.6505
		},
		{
			open: 148.56,
			close: 148.4,
			volume: 1207,
			time: 1654070940000,
			lowest: 148.35,
			highest: 148.56,
			volumePrice: 148.451,
			transactionCount: 31,
			voluemPrice: 148.451
		},
		{
			open: 148.59,
			close: 148.57,
			volume: 1599,
			time: 1654071060000,
			lowest: 148.55,
			highest: 148.59,
			volumePrice: 148.5734,
			transactionCount: 16,
			voluemPrice: 148.5734
		},
		{
			open: 148.52,
			close: 148.48,
			volume: 1197,
			time: 1654071120000,
			lowest: 148.48,
			highest: 148.52,
			volumePrice: 148.5121,
			transactionCount: 35,
			voluemPrice: 148.5121
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 356,
			time: 1654071180000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.5262,
			transactionCount: 8,
			voluemPrice: 148.5262
		},
		{
			open: 148.67,
			close: 148.5,
			volume: 2364,
			time: 1654071240000,
			lowest: 148.5,
			highest: 148.67,
			volumePrice: 148.5616,
			transactionCount: 39,
			voluemPrice: 148.5616
		},
		{
			open: 148.59,
			close: 148.59,
			volume: 286,
			time: 1654071300000,
			lowest: 148.59,
			highest: 148.59,
			volumePrice: 148.5678,
			transactionCount: 8,
			voluemPrice: 148.5678
		},
		{
			open: 148.58,
			close: 148.52,
			volume: 1018,
			time: 1654071360000,
			lowest: 148.52,
			highest: 148.58,
			volumePrice: 148.5573,
			transactionCount: 25,
			voluemPrice: 148.5573
		},
		{
			open: 148.52,
			close: 148.52,
			volume: 256,
			time: 1654071420000,
			lowest: 148.52,
			highest: 148.52,
			volumePrice: 148.5288,
			transactionCount: 13,
			voluemPrice: 148.5288
		},
		{
			open: 148.57,
			close: 148.58,
			volume: 375,
			time: 1654071480000,
			lowest: 148.57,
			highest: 148.58,
			volumePrice: 148.5731,
			transactionCount: 11,
			voluemPrice: 148.5731
		},
		{
			open: 148.53,
			close: 148.52,
			volume: 436,
			time: 1654071540000,
			lowest: 148.52,
			highest: 148.53,
			volumePrice: 148.5258,
			transactionCount: 7,
			voluemPrice: 148.5258
		},
		{
			open: 148.51,
			close: 148.5,
			volume: 3654,
			time: 1654071600000,
			lowest: 148.5,
			highest: 148.51,
			volumePrice: 148.5032,
			transactionCount: 60,
			voluemPrice: 148.5032
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 1008,
			time: 1654071660000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.4975,
			transactionCount: 21,
			voluemPrice: 148.4975
		},
		{
			open: 148.59,
			close: 148.6,
			volume: 790,
			time: 1654071840000,
			lowest: 148.59,
			highest: 148.6,
			volumePrice: 148.5963,
			transactionCount: 21,
			voluemPrice: 148.5963
		},
		{
			open: 148.73,
			close: 148.75,
			volume: 623,
			time: 1654072140000,
			lowest: 148.73,
			highest: 148.75,
			volumePrice: 148.7463,
			transactionCount: 15,
			voluemPrice: 148.7463
		},
		{
			open: 148.75,
			close: 148.83,
			volume: 731,
			time: 1654072200000,
			lowest: 148.75,
			highest: 148.83,
			volumePrice: 148.7833,
			transactionCount: 8,
			voluemPrice: 148.7833
		},
		{
			open: 148.87,
			close: 148.88,
			volume: 879,
			time: 1654072320000,
			lowest: 148.87,
			highest: 148.88,
			volumePrice: 148.8596,
			transactionCount: 21,
			voluemPrice: 148.8596
		},
		{
			open: 148.83,
			close: 148.83,
			volume: 129,
			time: 1654072380000,
			lowest: 148.83,
			highest: 148.83,
			volumePrice: 148.8364,
			transactionCount: 10,
			voluemPrice: 148.8364
		},
		{
			open: 148.83,
			close: 148.83,
			volume: 513,
			time: 1654072440000,
			lowest: 148.83,
			highest: 148.83,
			volumePrice: 148.8122,
			transactionCount: 16,
			voluemPrice: 148.8122
		},
		{
			open: 148.71,
			close: 148.71,
			volume: 504,
			time: 1654072680000,
			lowest: 148.71,
			highest: 148.71,
			volumePrice: 148.6807,
			transactionCount: 15,
			voluemPrice: 148.6807
		},
		{
			open: 148.63,
			close: 148.63,
			volume: 231,
			time: 1654072740000,
			lowest: 148.63,
			highest: 148.63,
			volumePrice: 148.6304,
			transactionCount: 6,
			voluemPrice: 148.6304
		},
		{
			open: 148.62,
			close: 148.61,
			volume: 325,
			time: 1654072800000,
			lowest: 148.61,
			highest: 148.62,
			volumePrice: 148.6403,
			transactionCount: 14,
			voluemPrice: 148.6403
		},
		{
			open: 148.56,
			close: 148.51,
			volume: 608,
			time: 1654072860000,
			lowest: 148.51,
			highest: 148.56,
			volumePrice: 148.5554,
			transactionCount: 23,
			voluemPrice: 148.5554
		},
		{
			open: 148.68,
			close: 148.7,
			volume: 1510,
			time: 1654073040000,
			lowest: 148.68,
			highest: 148.7,
			volumePrice: 148.7008,
			transactionCount: 30,
			voluemPrice: 148.7008
		},
		{
			open: 148.83,
			close: 148.85,
			volume: 1389,
			time: 1654073100000,
			lowest: 148.83,
			highest: 148.85,
			volumePrice: 148.853,
			transactionCount: 20,
			voluemPrice: 148.853
		},
		{
			open: 148.83,
			close: 148.79,
			volume: 1278,
			time: 1654073160000,
			lowest: 148.79,
			highest: 148.83,
			volumePrice: 148.7982,
			transactionCount: 26,
			voluemPrice: 148.7982
		},
		{
			open: 148.88,
			close: 148.88,
			volume: 205,
			time: 1654073700000,
			lowest: 148.88,
			highest: 148.88,
			volumePrice: 148.892,
			transactionCount: 3,
			voluemPrice: 148.892
		},
		{
			open: 148.76,
			close: 148.76,
			volume: 344,
			time: 1654073940000,
			lowest: 148.76,
			highest: 148.76,
			volumePrice: 148.8094,
			transactionCount: 17,
			voluemPrice: 148.8094
		},
		{
			open: 148.85,
			close: 148.89,
			volume: 332,
			time: 1654074000000,
			lowest: 148.85,
			highest: 148.89,
			volumePrice: 148.8658,
			transactionCount: 9,
			voluemPrice: 148.8658
		},
		{
			open: 148.88,
			close: 148.88,
			volume: 419,
			time: 1654074060000,
			lowest: 148.88,
			highest: 148.88,
			volumePrice: 148.8822,
			transactionCount: 9,
			voluemPrice: 148.8822
		},
		{
			open: 148.83,
			close: 148.83,
			volume: 367,
			time: 1654074240000,
			lowest: 148.83,
			highest: 148.83,
			volumePrice: 148.8489,
			transactionCount: 20,
			voluemPrice: 148.8489
		},
		{
			open: 148.76,
			close: 148.76,
			volume: 252,
			time: 1654074300000,
			lowest: 148.76,
			highest: 148.76,
			volumePrice: 148.7623,
			transactionCount: 6,
			voluemPrice: 148.7623
		},
		{
			open: 148.92,
			close: 148.92,
			volume: 257,
			time: 1654074360000,
			lowest: 148.92,
			highest: 148.92,
			volumePrice: 148.9125,
			transactionCount: 12,
			voluemPrice: 148.9125
		},
		{
			open: 148.94,
			close: 148.94,
			volume: 746,
			time: 1654074420000,
			lowest: 148.94,
			highest: 148.94,
			volumePrice: 148.9338,
			transactionCount: 20,
			voluemPrice: 148.9338
		},
		{
			open: 148.95,
			close: 148.95,
			volume: 412,
			time: 1654074780000,
			lowest: 148.95,
			highest: 148.95,
			volumePrice: 148.9487,
			transactionCount: 11,
			voluemPrice: 148.9487
		},
		{
			open: 149,
			close: 149,
			volume: 326,
			time: 1654075260000,
			lowest: 149,
			highest: 149,
			volumePrice: 148.9913,
			transactionCount: 25,
			voluemPrice: 148.9913
		},
		{
			open: 148.99,
			close: 148.99,
			volume: 160,
			time: 1654075320000,
			lowest: 148.99,
			highest: 148.99,
			volumePrice: 148.9869,
			transactionCount: 3,
			voluemPrice: 148.9869
		},
		{
			open: 148.98,
			close: 149,
			volume: 2220,
			time: 1654075380000,
			lowest: 148.98,
			highest: 149,
			volumePrice: 148.9952,
			transactionCount: 42,
			voluemPrice: 148.9952
		},
		{
			open: 149.04,
			close: 149.04,
			volume: 217,
			time: 1654075800000,
			lowest: 149.04,
			highest: 149.04,
			volumePrice: 149.0435,
			transactionCount: 4,
			voluemPrice: 149.0435
		},
		{
			open: 149,
			close: 149,
			volume: 1046,
			time: 1654076160000,
			lowest: 149,
			highest: 149,
			volumePrice: 148.9997,
			transactionCount: 16,
			voluemPrice: 148.9997
		},
		{
			open: 148.91,
			close: 148.9,
			volume: 543,
			time: 1654076400000,
			lowest: 148.9,
			highest: 148.91,
			volumePrice: 148.9059,
			transactionCount: 12,
			voluemPrice: 148.9059
		},
		{
			open: 148.97,
			close: 148.97,
			volume: 901,
			time: 1654076580000,
			lowest: 148.97,
			highest: 148.97,
			volumePrice: 148.9648,
			transactionCount: 10,
			voluemPrice: 148.9648
		},
		{
			open: 148.86,
			close: 148.86,
			volume: 1066,
			time: 1654076880000,
			lowest: 148.85,
			highest: 148.87,
			volumePrice: 148.8648,
			transactionCount: 27,
			voluemPrice: 148.8648
		},
		{
			open: 148.85,
			close: 148.85,
			volume: 545,
			time: 1654076940000,
			lowest: 148.85,
			highest: 148.85,
			volumePrice: 148.8497,
			transactionCount: 14,
			voluemPrice: 148.8497
		},
		{
			open: 148.84,
			close: 148.8,
			volume: 994,
			time: 1654077960000,
			lowest: 148.8,
			highest: 148.84,
			volumePrice: 148.8118,
			transactionCount: 25,
			voluemPrice: 148.8118
		},
		{
			open: 148.81,
			close: 148.85,
			volume: 1765,
			time: 1654078020000,
			lowest: 148.81,
			highest: 148.85,
			volumePrice: 148.8403,
			transactionCount: 19,
			voluemPrice: 148.8403
		},
		{
			open: 148.8,
			close: 148.8,
			volume: 1911,
			time: 1654078080000,
			lowest: 148.8,
			highest: 148.81,
			volumePrice: 148.8011,
			transactionCount: 30,
			voluemPrice: 148.8011
		},
		{
			open: 148.81,
			close: 148.78,
			volume: 982,
			time: 1654078380000,
			lowest: 148.78,
			highest: 148.81,
			volumePrice: 148.7861,
			transactionCount: 16,
			voluemPrice: 148.7861
		},
		{
			open: 148.78,
			close: 148.77,
			volume: 536,
			time: 1654078440000,
			lowest: 148.77,
			highest: 148.78,
			volumePrice: 148.7781,
			transactionCount: 11,
			voluemPrice: 148.7781
		},
		{
			open: 148.78,
			close: 148.78,
			volume: 350,
			time: 1654078500000,
			lowest: 148.78,
			highest: 148.78,
			volumePrice: 148.78,
			transactionCount: 2,
			voluemPrice: 148.78
		},
		{
			open: 148.95,
			close: 148.96,
			volume: 1103,
			time: 1654078800000,
			lowest: 148.95,
			highest: 148.96,
			volumePrice: 148.9442,
			transactionCount: 18,
			voluemPrice: 148.9442
		},
		{
			open: 148.95,
			close: 148.95,
			volume: 106,
			time: 1654078920000,
			lowest: 148.95,
			highest: 148.95,
			volumePrice: 148.9487,
			transactionCount: 6,
			voluemPrice: 148.9487
		},
		{
			open: 148.83,
			close: 148.81,
			volume: 740,
			time: 1654078980000,
			lowest: 148.81,
			highest: 148.83,
			volumePrice: 148.8223,
			transactionCount: 17,
			voluemPrice: 148.8223
		},
		{
			open: 148.87,
			close: 148.87,
			volume: 249,
			time: 1654079100000,
			lowest: 148.87,
			highest: 148.87,
			volumePrice: 148.8778,
			transactionCount: 6,
			voluemPrice: 148.8778
		},
		{
			open: 148.78,
			close: 148.76,
			volume: 764,
			time: 1654079220000,
			lowest: 148.76,
			highest: 148.78,
			volumePrice: 148.7734,
			transactionCount: 14,
			voluemPrice: 148.7734
		},
		{
			open: 148.78,
			close: 148.76,
			volume: 375,
			time: 1654079280000,
			lowest: 148.76,
			highest: 148.78,
			volumePrice: 148.7662,
			transactionCount: 6,
			voluemPrice: 148.7662
		},
		{
			open: 148.9,
			close: 148.9,
			volume: 416,
			time: 1654079520000,
			lowest: 148.9,
			highest: 148.9,
			volumePrice: 148.9201,
			transactionCount: 13,
			voluemPrice: 148.9201
		},
		{
			open: 148.81,
			close: 148.81,
			volume: 302,
			time: 1654079580000,
			lowest: 148.81,
			highest: 148.81,
			volumePrice: 148.8109,
			transactionCount: 3,
			voluemPrice: 148.8109
		},
		{
			open: 148.9,
			close: 148.9,
			volume: 1849,
			time: 1654079760000,
			lowest: 148.9,
			highest: 148.9,
			volumePrice: 148.899,
			transactionCount: 10,
			voluemPrice: 148.899
		},
		{
			open: 148.88,
			close: 148.88,
			volume: 338,
			time: 1654079820000,
			lowest: 148.88,
			highest: 148.88,
			volumePrice: 148.8842,
			transactionCount: 8,
			voluemPrice: 148.8842
		},
		{
			open: 148.81,
			close: 148.8,
			volume: 1109,
			time: 1654079940000,
			lowest: 148.8,
			highest: 148.81,
			volumePrice: 148.8084,
			transactionCount: 40,
			voluemPrice: 148.8084
		},
		{
			open: 148.79,
			close: 148.79,
			volume: 2480,
			time: 1654080000000,
			lowest: 148.78,
			highest: 148.8,
			volumePrice: 148.7892,
			transactionCount: 21,
			voluemPrice: 148.7892
		},
		{
			open: 148.8,
			close: 148.81,
			volume: 732,
			time: 1654080060000,
			lowest: 148.8,
			highest: 148.81,
			volumePrice: 148.809,
			transactionCount: 9,
			voluemPrice: 148.809
		},
		{
			open: 148.83,
			close: 148.83,
			volume: 312,
			time: 1654080120000,
			lowest: 148.83,
			highest: 148.83,
			volumePrice: 148.8282,
			transactionCount: 6,
			voluemPrice: 148.8282
		},
		{
			open: 148.84,
			close: 148.85,
			volume: 2440,
			time: 1654080240000,
			lowest: 148.84,
			highest: 148.85,
			volumePrice: 148.8467,
			transactionCount: 15,
			voluemPrice: 148.8467
		},
		{
			open: 148.8,
			close: 148.78,
			volume: 704,
			time: 1654080420000,
			lowest: 148.78,
			highest: 148.8,
			volumePrice: 148.7892,
			transactionCount: 17,
			voluemPrice: 148.7892
		},
		{
			open: 148.71,
			close: 148.71,
			volume: 263,
			time: 1654080600000,
			lowest: 148.71,
			highest: 148.71,
			volumePrice: 148.7348,
			transactionCount: 20,
			voluemPrice: 148.7348
		},
		{
			open: 148.7,
			close: 148.7,
			volume: 942,
			time: 1654080660000,
			lowest: 148.7,
			highest: 148.7,
			volumePrice: 148.7036,
			transactionCount: 30,
			voluemPrice: 148.7036
		},
		{
			open: 148.7,
			close: 148.7,
			volume: 472,
			time: 1654080780000,
			lowest: 148.7,
			highest: 148.7,
			volumePrice: 148.7111,
			transactionCount: 10,
			voluemPrice: 148.7111
		},
		{
			open: 148.68,
			close: 148.6,
			volume: 748,
			time: 1654080840000,
			lowest: 148.6,
			highest: 148.68,
			volumePrice: 148.6332,
			transactionCount: 15,
			voluemPrice: 148.6332
		},
		{
			open: 148.61,
			close: 148.61,
			volume: 821,
			time: 1654080900000,
			lowest: 148.6,
			highest: 148.69,
			volumePrice: 148.6397,
			transactionCount: 21,
			voluemPrice: 148.6397
		},
		{
			open: 148.74,
			close: 148.67,
			volume: 324,
			time: 1654081080000,
			lowest: 148.67,
			highest: 148.74,
			volumePrice: 148.7047,
			transactionCount: 9,
			voluemPrice: 148.7047
		},
		{
			open: 148.75,
			close: 148.75,
			volume: 1312,
			time: 1654081140000,
			lowest: 148.75,
			highest: 148.75,
			volumePrice: 148.7491,
			transactionCount: 6,
			voluemPrice: 148.7491
		},
		{
			open: 148.75,
			close: 148.52,
			volume: 16705,
			time: 1654081200000,
			lowest: 148.52,
			highest: 148.87,
			volumePrice: 148.7463,
			transactionCount: 314,
			voluemPrice: 148.7463
		},
		{
			open: 148.62,
			close: 148.74,
			volume: 6977,
			time: 1654081260000,
			lowest: 148.62,
			highest: 148.74,
			volumePrice: 148.6855,
			transactionCount: 45,
			voluemPrice: 148.6855
		},
		{
			open: 148.74,
			close: 148.67,
			volume: 4423,
			time: 1654081320000,
			lowest: 148.63,
			highest: 148.75,
			volumePrice: 148.6828,
			transactionCount: 47,
			voluemPrice: 148.6828
		},
		{
			open: 148.66,
			close: 148.7,
			volume: 1267,
			time: 1654081440000,
			lowest: 148.66,
			highest: 148.7,
			volumePrice: 148.6872,
			transactionCount: 35,
			voluemPrice: 148.6872
		},
		{
			open: 148.68,
			close: 148.68,
			volume: 318,
			time: 1654081500000,
			lowest: 148.68,
			highest: 148.68,
			volumePrice: 148.6761,
			transactionCount: 14,
			voluemPrice: 148.6761
		},
		{
			open: 148.8,
			close: 148.8,
			volume: 2429,
			time: 1654081620000,
			lowest: 148.8,
			highest: 148.86,
			volumePrice: 148.8197,
			transactionCount: 30,
			voluemPrice: 148.8197
		},
		{
			open: 148.8,
			close: 148.8,
			volume: 1687,
			time: 1654081680000,
			lowest: 148.8,
			highest: 148.88,
			volumePrice: 148.844,
			transactionCount: 29,
			voluemPrice: 148.844
		},
		{
			open: 148.79,
			close: 148.78,
			volume: 786,
			time: 1654081740000,
			lowest: 148.78,
			highest: 148.79,
			volumePrice: 148.7861,
			transactionCount: 19,
			voluemPrice: 148.7861
		},
		{
			open: 148.77,
			close: 148.77,
			volume: 269,
			time: 1654081800000,
			lowest: 148.77,
			highest: 148.77,
			volumePrice: 148.7749,
			transactionCount: 11,
			voluemPrice: 148.7749
		},
		{
			open: 148.8,
			close: 148.83,
			volume: 1491,
			time: 1654081860000,
			lowest: 148.8,
			highest: 148.83,
			volumePrice: 148.8153,
			transactionCount: 29,
			voluemPrice: 148.8153
		},
		{
			open: 148.87,
			close: 148.83,
			volume: 1825,
			time: 1654081980000,
			lowest: 148.83,
			highest: 148.88,
			volumePrice: 148.8596,
			transactionCount: 39,
			voluemPrice: 148.8596
		},
		{
			open: 148.84,
			close: 148.85,
			volume: 274,
			time: 1654082040000,
			lowest: 148.84,
			highest: 148.85,
			volumePrice: 148.8445,
			transactionCount: 7,
			voluemPrice: 148.8445
		},
		{
			open: 148.87,
			close: 148.84,
			volume: 1550,
			time: 1654082100000,
			lowest: 148.84,
			highest: 148.89,
			volumePrice: 148.8658,
			transactionCount: 21,
			voluemPrice: 148.8658
		},
		{
			open: 148.83,
			close: 148.8,
			volume: 8377,
			time: 1654082160000,
			lowest: 148.8,
			highest: 148.84,
			volumePrice: 148.8162,
			transactionCount: 59,
			voluemPrice: 148.8162
		},
		{
			open: 148.81,
			close: 148.75,
			volume: 1287,
			time: 1654082280000,
			lowest: 148.75,
			highest: 148.81,
			volumePrice: 148.7924,
			transactionCount: 36,
			voluemPrice: 148.7924
		},
		{
			open: 148.78,
			close: 148.7,
			volume: 2217,
			time: 1654082400000,
			lowest: 148.7,
			highest: 148.78,
			volumePrice: 148.7253,
			transactionCount: 45,
			voluemPrice: 148.7253
		},
		{
			open: 148.7,
			close: 148.67,
			volume: 748,
			time: 1654082460000,
			lowest: 148.67,
			highest: 148.7,
			volumePrice: 148.6816,
			transactionCount: 14,
			voluemPrice: 148.6816
		},
		{
			open: 148.76,
			close: 148.78,
			volume: 1118,
			time: 1654082520000,
			lowest: 148.76,
			highest: 148.78,
			volumePrice: 148.77,
			transactionCount: 21,
			voluemPrice: 148.77
		},
		{
			open: 148.84,
			close: 148.84,
			volume: 1337,
			time: 1654082580000,
			lowest: 148.83,
			highest: 148.84,
			volumePrice: 148.8318,
			transactionCount: 41,
			voluemPrice: 148.8318
		},
		{
			open: 148.83,
			close: 148.83,
			volume: 103,
			time: 1654082640000,
			lowest: 148.83,
			highest: 148.83,
			volumePrice: 148.83,
			transactionCount: 4,
			voluemPrice: 148.83
		},
		{
			open: 148.83,
			close: 148.85,
			volume: 1647,
			time: 1654082700000,
			lowest: 148.83,
			highest: 148.85,
			volumePrice: 148.8476,
			transactionCount: 17,
			voluemPrice: 148.8476
		},
		{
			open: 148.85,
			close: 148.89,
			volume: 1162,
			time: 1654082760000,
			lowest: 148.85,
			highest: 148.89,
			volumePrice: 148.8726,
			transactionCount: 39,
			voluemPrice: 148.8726
		},
		{
			open: 148.85,
			close: 148.85,
			volume: 180,
			time: 1654082820000,
			lowest: 148.85,
			highest: 148.85,
			volumePrice: 148.8576,
			transactionCount: 8,
			voluemPrice: 148.8576
		},
		{
			open: 148.85,
			close: 148.77,
			volume: 2065,
			time: 1654082880000,
			lowest: 148.77,
			highest: 148.86,
			volumePrice: 148.8251,
			transactionCount: 48,
			voluemPrice: 148.8251
		},
		{
			open: 148.81,
			close: 148.76,
			volume: 4772,
			time: 1654083000000,
			lowest: 148.76,
			highest: 148.83,
			volumePrice: 148.7968,
			transactionCount: 54,
			voluemPrice: 148.7968
		},
		{
			open: 148.89,
			close: 148.81,
			volume: 3322,
			time: 1654083060000,
			lowest: 148.81,
			highest: 148.9,
			volumePrice: 148.896,
			transactionCount: 63,
			voluemPrice: 148.896
		},
		{
			open: 148.96,
			close: 148.96,
			volume: 383,
			time: 1654083120000,
			lowest: 148.96,
			highest: 148.96,
			volumePrice: 148.9599,
			transactionCount: 10,
			voluemPrice: 148.9599
		},
		{
			open: 148.94,
			close: 148.94,
			volume: 470,
			time: 1654083180000,
			lowest: 148.94,
			highest: 148.94,
			volumePrice: 148.944,
			transactionCount: 9,
			voluemPrice: 148.944
		},
		{
			open: 148.93,
			close: 148.93,
			volume: 718,
			time: 1654083240000,
			lowest: 148.91,
			highest: 148.93,
			volumePrice: 148.9206,
			transactionCount: 17,
			voluemPrice: 148.9206
		},
		{
			open: 148.97,
			close: 148.97,
			volume: 300,
			time: 1654083360000,
			lowest: 148.97,
			highest: 148.97,
			volumePrice: 148.963,
			transactionCount: 11,
			voluemPrice: 148.963
		},
		{
			open: 148.97,
			close: 148.97,
			volume: 346,
			time: 1654083420000,
			lowest: 148.97,
			highest: 148.97,
			volumePrice: 148.9775,
			transactionCount: 10,
			voluemPrice: 148.9775
		},
		{
			open: 148.99,
			close: 148.97,
			volume: 3714,
			time: 1654083480000,
			lowest: 148.97,
			highest: 149,
			volumePrice: 148.9867,
			transactionCount: 84,
			voluemPrice: 148.9867
		},
		{
			open: 149.04,
			close: 149.01,
			volume: 3356,
			time: 1654083540000,
			lowest: 148.98,
			highest: 149.05,
			volumePrice: 149.0096,
			transactionCount: 75,
			voluemPrice: 149.0096
		},
		{
			open: 149,
			close: 149.01,
			volume: 1195,
			time: 1654083600000,
			lowest: 149,
			highest: 149.01,
			volumePrice: 148.966,
			transactionCount: 32,
			voluemPrice: 148.966
		},
		{
			open: 148.94,
			close: 148.94,
			volume: 3700,
			time: 1654083720000,
			lowest: 148.94,
			highest: 148.94,
			volumePrice: 148.9393,
			transactionCount: 29,
			voluemPrice: 148.9393
		},
		{
			open: 148.98,
			close: 148.9,
			volume: 5816,
			time: 1654083780000,
			lowest: 148.9,
			highest: 149,
			volumePrice: 148.9433,
			transactionCount: 67,
			voluemPrice: 148.9433
		},
		{
			open: 148.89,
			close: 148.88,
			volume: 598,
			time: 1654083840000,
			lowest: 148.88,
			highest: 148.89,
			volumePrice: 148.8898,
			transactionCount: 15,
			voluemPrice: 148.8898
		},
		{
			open: 148.85,
			close: 148.91,
			volume: 1408,
			time: 1654083900000,
			lowest: 148.85,
			highest: 148.91,
			volumePrice: 148.8701,
			transactionCount: 34,
			voluemPrice: 148.8701
		},
		{
			open: 148.9,
			close: 148.9,
			volume: 356,
			time: 1654083960000,
			lowest: 148.9,
			highest: 148.9,
			volumePrice: 148.9034,
			transactionCount: 7,
			voluemPrice: 148.9034
		},
		{
			open: 149,
			close: 148.98,
			volume: 3061,
			time: 1654084020000,
			lowest: 148.98,
			highest: 149.05,
			volumePrice: 149.0107,
			transactionCount: 55,
			voluemPrice: 149.0107
		},
		{
			open: 148.99,
			close: 148.99,
			volume: 1944,
			time: 1654084080000,
			lowest: 148.99,
			highest: 148.99,
			volumePrice: 148.9861,
			transactionCount: 42,
			voluemPrice: 148.9861
		},
		{
			open: 149,
			close: 148.97,
			volume: 4769,
			time: 1654084140000,
			lowest: 148.97,
			highest: 149.01,
			volumePrice: 148.9784,
			transactionCount: 28,
			voluemPrice: 148.9784
		},
		{
			open: 148.98,
			close: 148.98,
			volume: 230,
			time: 1654084200000,
			lowest: 148.98,
			highest: 148.98,
			volumePrice: 148.9948,
			transactionCount: 9,
			voluemPrice: 148.9948
		},
		{
			open: 149,
			close: 148.98,
			volume: 1064,
			time: 1654084260000,
			lowest: 148.98,
			highest: 149,
			volumePrice: 148.9891,
			transactionCount: 26,
			voluemPrice: 148.9891
		},
		{
			open: 149.02,
			close: 149,
			volume: 2614,
			time: 1654084380000,
			lowest: 149,
			highest: 149.04,
			volumePrice: 149.0303,
			transactionCount: 39,
			voluemPrice: 149.0303
		},
		{
			open: 149,
			close: 149,
			volume: 413,
			time: 1654084440000,
			lowest: 149,
			highest: 149,
			volumePrice: 149.0117,
			transactionCount: 9,
			voluemPrice: 149.0117
		},
		{
			open: 149.04,
			close: 149.06,
			volume: 559,
			time: 1654084500000,
			lowest: 149.04,
			highest: 149.06,
			volumePrice: 149.0516,
			transactionCount: 13,
			voluemPrice: 149.0516
		},
		{
			open: 149.14,
			close: 149.18,
			volume: 2791,
			time: 1654084620000,
			lowest: 149.14,
			highest: 149.19,
			volumePrice: 149.1735,
			transactionCount: 60,
			voluemPrice: 149.1735
		},
		{
			open: 149.16,
			close: 149.27,
			volume: 4211,
			time: 1654084680000,
			lowest: 149.16,
			highest: 149.27,
			volumePrice: 149.222,
			transactionCount: 70,
			voluemPrice: 149.222
		},
		{
			open: 149.27,
			close: 149.27,
			volume: 1492,
			time: 1654084740000,
			lowest: 149.26,
			highest: 149.27,
			volumePrice: 149.2696,
			transactionCount: 30,
			voluemPrice: 149.2696
		},
		{
			open: 148.75,
			close: 149.36,
			volume: 63957,
			time: 1654084800000,
			lowest: 148.5312,
			highest: 149.42,
			volumePrice: 149.0584,
			transactionCount: 821,
			voluemPrice: 149.0584
		},
		{
			open: 149.42,
			close: 149.4,
			volume: 23925,
			time: 1654084860000,
			lowest: 149.35,
			highest: 149.42,
			volumePrice: 149.3706,
			transactionCount: 404,
			voluemPrice: 149.3706
		},
		{
			open: 149.42,
			close: 149.4499,
			volume: 3338,
			time: 1654084920000,
			lowest: 149.41,
			highest: 149.45,
			volumePrice: 149.4229,
			transactionCount: 87,
			voluemPrice: 149.4229
		},
		{
			open: 149.48,
			close: 149.53,
			volume: 30023,
			time: 1654084980000,
			lowest: 149.44,
			highest: 149.53,
			volumePrice: 149.4907,
			transactionCount: 155,
			voluemPrice: 149.4907
		},
		{
			open: 149.53,
			close: 149.53,
			volume: 12263,
			time: 1654085040000,
			lowest: 149.5,
			highest: 149.5795,
			volumePrice: 149.5358,
			transactionCount: 170,
			voluemPrice: 149.5358
		},
		{
			open: 149.55,
			close: 149.6,
			volume: 9754,
			time: 1654085100000,
			lowest: 149.4804,
			highest: 149.6,
			volumePrice: 149.5529,
			transactionCount: 79,
			voluemPrice: 149.5529
		},
		{
			open: 149.63,
			close: 149.5101,
			volume: 7760,
			time: 1654085160000,
			lowest: 149.5101,
			highest: 149.63,
			volumePrice: 149.5866,
			transactionCount: 56,
			voluemPrice: 149.5866
		},
		{
			open: 149.5101,
			close: 149.55,
			volume: 13277,
			time: 1654085220000,
			lowest: 149.49,
			highest: 149.58,
			volumePrice: 149.5128,
			transactionCount: 55,
			voluemPrice: 149.5128
		},
		{
			open: 149.57,
			close: 149.48,
			volume: 6052,
			time: 1654085280000,
			lowest: 149.46,
			highest: 149.57,
			volumePrice: 149.5203,
			transactionCount: 107,
			voluemPrice: 149.5203
		},
		{
			open: 149.4999,
			close: 149.51,
			volume: 1944,
			time: 1654085340000,
			lowest: 149.4999,
			highest: 149.51,
			volumePrice: 149.5047,
			transactionCount: 38,
			voluemPrice: 149.5047
		},
		{
			open: 149.54,
			close: 149.56,
			volume: 4215,
			time: 1654085400000,
			lowest: 149.52,
			highest: 149.57,
			volumePrice: 149.5485,
			transactionCount: 65,
			voluemPrice: 149.5485
		},
		{
			open: 149.55,
			close: 149.57,
			volume: 6761,
			time: 1654085460000,
			lowest: 149.55,
			highest: 149.57,
			volumePrice: 149.5657,
			transactionCount: 30,
			voluemPrice: 149.5657
		},
		{
			open: 149.57,
			close: 149.54,
			volume: 4447,
			time: 1654085520000,
			lowest: 149.5,
			highest: 149.57,
			volumePrice: 149.537,
			transactionCount: 58,
			voluemPrice: 149.537
		},
		{
			open: 149.5396,
			close: 149.5399,
			volume: 4930,
			time: 1654085580000,
			lowest: 149.5396,
			highest: 149.5399,
			volumePrice: 149.5393,
			transactionCount: 25,
			voluemPrice: 149.5393
		},
		{
			open: 149.5399,
			close: 149.5,
			volume: 2452,
			time: 1654085640000,
			lowest: 149.49,
			highest: 149.5399,
			volumePrice: 149.5175,
			transactionCount: 49,
			voluemPrice: 149.5175
		},
		{
			open: 149.5,
			close: 149.53,
			volume: 7365,
			time: 1654085700000,
			lowest: 149.5,
			highest: 149.55,
			volumePrice: 149.5382,
			transactionCount: 45,
			voluemPrice: 149.5382
		},
		{
			open: 149.54,
			close: 149.54,
			volume: 267,
			time: 1654085760000,
			lowest: 149.54,
			highest: 149.54,
			volumePrice: 149.5465,
			transactionCount: 22,
			voluemPrice: 149.5465
		},
		{
			open: 149.5599,
			close: 149.52,
			volume: 2686,
			time: 1654085820000,
			lowest: 149.52,
			highest: 149.5599,
			volumePrice: 149.5392,
			transactionCount: 46,
			voluemPrice: 149.5392
		},
		{
			open: 149.5001,
			close: 149.46,
			volume: 20228,
			time: 1654085880000,
			lowest: 149.45,
			highest: 149.53,
			volumePrice: 149.4952,
			transactionCount: 107,
			voluemPrice: 149.4952
		},
		{
			open: 149.43,
			close: 149.43,
			volume: 3142,
			time: 1654085940000,
			lowest: 149.4,
			highest: 149.4402,
			volumePrice: 149.4187,
			transactionCount: 70,
			voluemPrice: 149.4187
		},
		{
			open: 149.4201,
			close: 149.4,
			volume: 1369,
			time: 1654086000000,
			lowest: 149.4,
			highest: 149.4201,
			volumePrice: 149.4114,
			transactionCount: 37,
			voluemPrice: 149.4114
		},
		{
			open: 149.34,
			close: 149.4,
			volume: 2752,
			time: 1654086060000,
			lowest: 149.34,
			highest: 149.4,
			volumePrice: 149.3796,
			transactionCount: 79,
			voluemPrice: 149.3796
		},
		{
			open: 149.45,
			close: 149.57,
			volume: 28476,
			time: 1654086120000,
			lowest: 149.45,
			highest: 149.57,
			volumePrice: 149.5398,
			transactionCount: 112,
			voluemPrice: 149.5398
		},
		{
			open: 149.58,
			close: 149.7,
			volume: 7901,
			time: 1654086180000,
			lowest: 149.58,
			highest: 149.75,
			volumePrice: 149.6442,
			transactionCount: 137,
			voluemPrice: 149.6442
		},
		{
			open: 149.72,
			close: 149.671,
			volume: 1943,
			time: 1654086240000,
			lowest: 149.671,
			highest: 149.72,
			volumePrice: 149.696,
			transactionCount: 63,
			voluemPrice: 149.696
		},
		{
			open: 149.63,
			close: 149.69,
			volume: 3170,
			time: 1654086300000,
			lowest: 149.63,
			highest: 149.69,
			volumePrice: 149.6718,
			transactionCount: 46,
			voluemPrice: 149.6718
		},
		{
			open: 149.66,
			close: 149.74,
			volume: 5292,
			time: 1654086360000,
			lowest: 149.66,
			highest: 149.75,
			volumePrice: 149.7307,
			transactionCount: 59,
			voluemPrice: 149.7307
		},
		{
			open: 149.75,
			close: 149.85,
			volume: 9522,
			time: 1654086420000,
			lowest: 149.71,
			highest: 149.89,
			volumePrice: 149.8001,
			transactionCount: 113,
			voluemPrice: 149.8001
		},
		{
			open: 149.88,
			close: 149.8,
			volume: 16673,
			time: 1654086480000,
			lowest: 149.7501,
			highest: 149.89,
			volumePrice: 149.8013,
			transactionCount: 156,
			voluemPrice: 149.8013
		},
		{
			open: 149.8,
			close: 149.8,
			volume: 7127,
			time: 1654086540000,
			lowest: 149.74,
			highest: 149.8,
			volumePrice: 149.7786,
			transactionCount: 118,
			voluemPrice: 149.7786
		},
		{
			open: 149.78,
			close: 149.75,
			volume: 29462,
			time: 1654086600000,
			lowest: 149.68,
			highest: 149.78,
			volumePrice: 149.7358,
			transactionCount: 240,
			voluemPrice: 149.7358
		},
		{
			open: 149.73,
			close: 149.7,
			volume: 7433,
			time: 1654086660000,
			lowest: 149.68,
			highest: 149.75,
			volumePrice: 149.724,
			transactionCount: 153,
			voluemPrice: 149.724
		},
		{
			open: 149.71,
			close: 149.73,
			volume: 8733,
			time: 1654086720000,
			lowest: 149.71,
			highest: 149.75,
			volumePrice: 149.7283,
			transactionCount: 65,
			voluemPrice: 149.7283
		},
		{
			open: 149.72,
			close: 149.81,
			volume: 12499,
			time: 1654086780000,
			lowest: 149.72,
			highest: 149.81,
			volumePrice: 149.7594,
			transactionCount: 89,
			voluemPrice: 149.7594
		},
		{
			open: 149.8,
			close: 149.8299,
			volume: 2726,
			time: 1654086840000,
			lowest: 149.76,
			highest: 149.8299,
			volumePrice: 149.803,
			transactionCount: 56,
			voluemPrice: 149.803
		},
		{
			open: 149.81,
			close: 149.76,
			volume: 676,
			time: 1654086900000,
			lowest: 149.76,
			highest: 149.81,
			volumePrice: 149.7889,
			transactionCount: 34,
			voluemPrice: 149.7889
		},
		{
			open: 149.76,
			close: 149.72,
			volume: 5207,
			time: 1654086960000,
			lowest: 149.71,
			highest: 149.76,
			volumePrice: 149.7404,
			transactionCount: 63,
			voluemPrice: 149.7404
		},
		{
			open: 149.74,
			close: 149.74,
			volume: 910,
			time: 1654087020000,
			lowest: 149.73,
			highest: 149.74,
			volumePrice: 149.7391,
			transactionCount: 40,
			voluemPrice: 149.7391
		},
		{
			open: 149.74,
			close: 149.76,
			volume: 8307,
			time: 1654087080000,
			lowest: 149.7201,
			highest: 149.77,
			volumePrice: 149.7436,
			transactionCount: 129,
			voluemPrice: 149.7436
		},
		{
			open: 149.8,
			close: 149.83,
			volume: 3737,
			time: 1654087140000,
			lowest: 149.8,
			highest: 149.85,
			volumePrice: 149.8319,
			transactionCount: 61,
			voluemPrice: 149.8319
		},
		{
			open: 149.8,
			close: 149.65,
			volume: 13323,
			time: 1654087200000,
			lowest: 149.65,
			highest: 149.8,
			volumePrice: 149.7379,
			transactionCount: 173,
			voluemPrice: 149.7379
		},
		{
			open: 149.68,
			close: 149.6901,
			volume: 11451,
			time: 1654087260000,
			lowest: 149.65,
			highest: 149.6901,
			volumePrice: 149.6655,
			transactionCount: 119,
			voluemPrice: 149.6655
		},
		{
			open: 149.75,
			close: 149.72,
			volume: 2880,
			time: 1654087320000,
			lowest: 149.6901,
			highest: 149.75,
			volumePrice: 149.7433,
			transactionCount: 37,
			voluemPrice: 149.7433
		},
		{
			open: 149.7699,
			close: 149.8,
			volume: 5668,
			time: 1654087380000,
			lowest: 149.7499,
			highest: 149.8,
			volumePrice: 149.763,
			transactionCount: 125,
			voluemPrice: 149.763
		},
		{
			open: 149.8,
			close: 149.8,
			volume: 836,
			time: 1654087440000,
			lowest: 149.79,
			highest: 149.8,
			volumePrice: 149.8057,
			transactionCount: 44,
			voluemPrice: 149.8057
		},
		{
			open: 149.79,
			close: 149.79,
			volume: 3903,
			time: 1654087500000,
			lowest: 149.79,
			highest: 149.81,
			volumePrice: 149.792,
			transactionCount: 54,
			voluemPrice: 149.792
		},
		{
			open: 149.75,
			close: 149.79,
			volume: 2715,
			time: 1654087620000,
			lowest: 149.75,
			highest: 149.79,
			volumePrice: 149.7589,
			transactionCount: 62,
			voluemPrice: 149.7589
		},
		{
			open: 149.79,
			close: 149.72,
			volume: 11455,
			time: 1654087680000,
			lowest: 149.7099,
			highest: 149.79,
			volumePrice: 149.7334,
			transactionCount: 136,
			voluemPrice: 149.7334
		},
		{
			open: 149.71,
			close: 149.6,
			volume: 8758,
			time: 1654087740000,
			lowest: 149.55,
			highest: 149.71,
			volumePrice: 149.6034,
			transactionCount: 151,
			voluemPrice: 149.6034
		},
		{
			open: 149.61,
			close: 149.55,
			volume: 15984,
			time: 1654087800000,
			lowest: 149.55,
			highest: 149.62,
			volumePrice: 149.5792,
			transactionCount: 134,
			voluemPrice: 149.5792
		},
		{
			open: 149.5401,
			close: 149.5745,
			volume: 1844,
			time: 1654087860000,
			lowest: 149.54,
			highest: 149.5745,
			volumePrice: 149.5468,
			transactionCount: 31,
			voluemPrice: 149.5468
		},
		{
			open: 149.6,
			close: 149.61,
			volume: 2545,
			time: 1654087920000,
			lowest: 149.6,
			highest: 149.61,
			volumePrice: 149.6075,
			transactionCount: 81,
			voluemPrice: 149.6075
		},
		{
			open: 149.61,
			close: 149.63,
			volume: 964,
			time: 1654087980000,
			lowest: 149.61,
			highest: 149.63,
			volumePrice: 149.6149,
			transactionCount: 36,
			voluemPrice: 149.6149
		},
		{
			open: 149.61,
			close: 149.7499,
			volume: 5111,
			time: 1654088040000,
			lowest: 149.61,
			highest: 149.7499,
			volumePrice: 149.6706,
			transactionCount: 98,
			voluemPrice: 149.6706
		},
		{
			open: 149.7301,
			close: 149.76,
			volume: 654,
			time: 1654088100000,
			lowest: 149.73,
			highest: 149.76,
			volumePrice: 149.7392,
			transactionCount: 34,
			voluemPrice: 149.7392
		},
		{
			open: 149.75,
			close: 149.69,
			volume: 1979,
			time: 1654088160000,
			lowest: 149.69,
			highest: 149.75,
			volumePrice: 149.7114,
			transactionCount: 78,
			voluemPrice: 149.7114
		},
		{
			open: 149.66,
			close: 149.61,
			volume: 17587,
			time: 1654088280000,
			lowest: 149.58,
			highest: 149.66,
			volumePrice: 149.5957,
			transactionCount: 149,
			voluemPrice: 149.5957
		},
		{
			open: 149.61,
			close: 149.6,
			volume: 28474,
			time: 1654088340000,
			lowest: 149.55,
			highest: 149.6101,
			volumePrice: 149.5981,
			transactionCount: 355,
			voluemPrice: 149.5981
		},
		{
			open: 149.56,
			close: 149.7,
			volume: 6165,
			time: 1654088400000,
			lowest: 149.56,
			highest: 149.7,
			volumePrice: 149.5998,
			transactionCount: 110,
			voluemPrice: 149.5998
		},
		{
			open: 149.67,
			close: 149.6,
			volume: 10956,
			time: 1654088460000,
			lowest: 149.55,
			highest: 149.67,
			volumePrice: 149.5944,
			transactionCount: 236,
			voluemPrice: 149.5944
		},
		{
			open: 149.62,
			close: 149.53,
			volume: 3418,
			time: 1654088520000,
			lowest: 149.53,
			highest: 149.62,
			volumePrice: 149.5946,
			transactionCount: 92,
			voluemPrice: 149.5946
		},
		{
			open: 149.54,
			close: 149.52,
			volume: 8322,
			time: 1654088580000,
			lowest: 149.51,
			highest: 149.54,
			volumePrice: 149.5175,
			transactionCount: 101,
			voluemPrice: 149.5175
		},
		{
			open: 149.56,
			close: 149.57,
			volume: 671,
			time: 1654088640000,
			lowest: 149.56,
			highest: 149.57,
			volumePrice: 149.5567,
			transactionCount: 44,
			voluemPrice: 149.5567
		},
		{
			open: 149.6,
			close: 149.62,
			volume: 7874,
			time: 1654088700000,
			lowest: 149.59,
			highest: 149.62,
			volumePrice: 149.6023,
			transactionCount: 57,
			voluemPrice: 149.6023
		},
		{
			open: 149.61,
			close: 149.64,
			volume: 5520,
			time: 1654088760000,
			lowest: 149.61,
			highest: 149.64,
			volumePrice: 149.6118,
			transactionCount: 53,
			voluemPrice: 149.6118
		},
		{
			open: 149.64,
			close: 149.64,
			volume: 3923,
			time: 1654088820000,
			lowest: 149.61,
			highest: 149.64,
			volumePrice: 149.6309,
			transactionCount: 72,
			voluemPrice: 149.6309
		},
		{
			open: 149.63,
			close: 149.68,
			volume: 3277,
			time: 1654088880000,
			lowest: 149.6,
			highest: 149.72,
			volumePrice: 149.6627,
			transactionCount: 79,
			voluemPrice: 149.6627
		},
		{
			open: 149.6257,
			close: 149.75,
			volume: 10991,
			time: 1654088940000,
			lowest: 149.6257,
			highest: 149.75,
			volumePrice: 149.692,
			transactionCount: 72,
			voluemPrice: 149.692
		},
		{
			open: 149.73,
			close: 149.85,
			volume: 9244,
			time: 1654089000000,
			lowest: 149.73,
			highest: 149.86,
			volumePrice: 149.8013,
			transactionCount: 161,
			voluemPrice: 149.8013
		},
		{
			open: 149.83,
			close: 149.92,
			volume: 13034,
			time: 1654089060000,
			lowest: 149.81,
			highest: 149.92,
			volumePrice: 149.8932,
			transactionCount: 179,
			voluemPrice: 149.8932
		},
		{
			open: 149.92,
			close: 149.85,
			volume: 27234,
			time: 1654089120000,
			lowest: 149.85,
			highest: 149.9899,
			volumePrice: 149.9144,
			transactionCount: 191,
			voluemPrice: 149.9144
		},
		{
			open: 149.85,
			close: 149.79,
			volume: 4811,
			time: 1654089180000,
			lowest: 149.76,
			highest: 149.87,
			volumePrice: 149.8299,
			transactionCount: 82,
			voluemPrice: 149.8299
		},
		{
			open: 149.83,
			close: 149.85,
			volume: 4423,
			time: 1654089240000,
			lowest: 149.79,
			highest: 149.85,
			volumePrice: 149.8372,
			transactionCount: 59,
			voluemPrice: 149.8372
		},
		{
			open: 149.85,
			close: 149.76,
			volume: 2886,
			time: 1654089300000,
			lowest: 149.76,
			highest: 149.88,
			volumePrice: 149.8374,
			transactionCount: 73,
			voluemPrice: 149.8374
		},
		{
			open: 149.87,
			close: 149.79,
			volume: 6297,
			time: 1654089360000,
			lowest: 149.79,
			highest: 149.87,
			volumePrice: 149.8422,
			transactionCount: 103,
			voluemPrice: 149.8422
		},
		{
			open: 149.79,
			close: 149.88,
			volume: 13561,
			time: 1654089420000,
			lowest: 149.79,
			highest: 149.91,
			volumePrice: 149.8509,
			transactionCount: 144,
			voluemPrice: 149.8509
		},
		{
			open: 149.92,
			close: 149.99,
			volume: 65038,
			time: 1654089480000,
			lowest: 149.89,
			highest: 149.99,
			volumePrice: 149.2478,
			transactionCount: 212,
			voluemPrice: 149.2478
		},
		{
			open: 149.98,
			close: 150.05,
			volume: 13592,
			time: 1654089540000,
			lowest: 149.98,
			highest: 150.05,
			volumePrice: 150.0051,
			transactionCount: 319,
			voluemPrice: 150.0051
		},
		{
			open: 150.14,
			close: 149.94,
			volume: 20841,
			time: 1654089600000,
			lowest: 149.93,
			highest: 150.14,
			volumePrice: 149.9834,
			transactionCount: 159,
			voluemPrice: 149.9834
		},
		{
			open: 149.9,
			close: 149.93,
			volume: 8325,
			time: 1654089660000,
			lowest: 149.86,
			highest: 149.93,
			volumePrice: 149.8827,
			transactionCount: 120,
			voluemPrice: 149.8827
		},
		{
			open: 149.97,
			close: 149.96,
			volume: 24951,
			time: 1654089720000,
			lowest: 149.9,
			highest: 150,
			volumePrice: 149.9659,
			transactionCount: 165,
			voluemPrice: 149.9659
		},
		{
			open: 149.95,
			close: 150,
			volume: 4078,
			time: 1654089780000,
			lowest: 149.9,
			highest: 150.03,
			volumePrice: 149.9711,
			transactionCount: 74,
			voluemPrice: 149.9711
		},
		{
			open: 150,
			close: 150,
			volume: 3050,
			time: 1654089840000,
			lowest: 150,
			highest: 150.03,
			volumePrice: 150.0112,
			transactionCount: 83,
			voluemPrice: 150.0112
		},
		{
			open: 150.01,
			close: 150,
			volume: 8532,
			time: 1654089900000,
			lowest: 150,
			highest: 150.15,
			volumePrice: 150.0568,
			transactionCount: 141,
			voluemPrice: 150.0568
		},
		{
			open: 150,
			close: 149.97,
			volume: 5253,
			time: 1654089960000,
			lowest: 149.97,
			highest: 150,
			volumePrice: 149.9855,
			transactionCount: 94,
			voluemPrice: 149.9855
		},
		{
			open: 149.9999,
			close: 150.23,
			volume: 16105,
			time: 1654090020000,
			lowest: 149.98,
			highest: 150.23,
			volumePrice: 150.05,
			transactionCount: 201,
			voluemPrice: 150.05
		},
		{
			open: 150.08,
			close: 149.97,
			volume: 3975,
			time: 1654090080000,
			lowest: 149.95,
			highest: 150.19,
			volumePrice: 150.0273,
			transactionCount: 90,
			voluemPrice: 150.0273
		},
		{
			open: 149.98,
			close: 149.91,
			volume: 4587,
			time: 1654090140000,
			lowest: 149.91,
			highest: 150.04,
			volumePrice: 149.9688,
			transactionCount: 120,
			voluemPrice: 149.9688
		},
		{
			open: 149.9,
			close: 150.13,
			volume: 1382944,
			time: 1654090200000,
			lowest: 149.88,
			highest: 150.4,
			volumePrice: 150.019,
			transactionCount: 13457,
			voluemPrice: 150.019
		},
		{
			open: 150.1,
			close: 150.31,
			volume: 502330,
			time: 1654090260000,
			lowest: 149.96,
			highest: 150.41,
			volumePrice: 150.2226,
			transactionCount: 5256,
			voluemPrice: 150.2226
		},
		{
			open: 150.31,
			close: 150.33,
			volume: 513029,
			time: 1654090320000,
			lowest: 150.16,
			highest: 150.47,
			volumePrice: 150.3526,
			transactionCount: 4628,
			voluemPrice: 150.3526
		},
		{
			open: 150.34,
			close: 150.14,
			volume: 652145,
			time: 1654090380000,
			lowest: 150.09,
			highest: 150.6,
			volumePrice: 150.4029,
			transactionCount: 5218,
			voluemPrice: 150.4029
		},
		{
			open: 150.14,
			close: 150.54,
			volume: 367106,
			time: 1654090440000,
			lowest: 150.13,
			highest: 150.565,
			volumePrice: 150.3751,
			transactionCount: 3050,
			voluemPrice: 150.3751
		},
		{
			open: 150.53,
			close: 150.3703,
			volume: 450249,
			time: 1654090500000,
			lowest: 150.35,
			highest: 150.66,
			volumePrice: 150.5173,
			transactionCount: 4049,
			voluemPrice: 150.5173
		},
		{
			open: 150.3579,
			close: 150.89,
			volume: 599249,
			time: 1654090560000,
			lowest: 150.29,
			highest: 151.03,
			volumePrice: 150.7647,
			transactionCount: 5298,
			voluemPrice: 150.7647
		},
		{
			open: 150.9,
			close: 150.9359,
			volume: 380880,
			time: 1654090620000,
			lowest: 150.86,
			highest: 151.08,
			volumePrice: 150.9667,
			transactionCount: 3680,
			voluemPrice: 150.9667
		},
		{
			open: 150.93,
			close: 150.64,
			volume: 422549,
			time: 1654090680000,
			lowest: 150.58,
			highest: 150.975,
			volumePrice: 150.7845,
			transactionCount: 3629,
			voluemPrice: 150.7845
		},
		{
			open: 150.63,
			close: 150.5,
			volume: 328573,
			time: 1654090740000,
			lowest: 150.46,
			highest: 150.7669,
			volumePrice: 150.5839,
			transactionCount: 3317,
			voluemPrice: 150.5839
		},
		{
			open: 150.5,
			close: 150.3799,
			volume: 419880,
			time: 1654090800000,
			lowest: 150.27,
			highest: 150.6499,
			volumePrice: 150.4221,
			transactionCount: 3381,
			voluemPrice: 150.4221
		},
		{
			open: 150.37,
			close: 150.7035,
			volume: 332975,
			time: 1654090860000,
			lowest: 150.35,
			highest: 150.77,
			volumePrice: 150.6119,
			transactionCount: 2698,
			voluemPrice: 150.6119
		},
		{
			open: 150.72,
			close: 150.8311,
			volume: 320378,
			time: 1654090920000,
			lowest: 150.55,
			highest: 150.89,
			volumePrice: 150.6964,
			transactionCount: 2920,
			voluemPrice: 150.6964
		},
		{
			open: 150.845,
			close: 150.7694,
			volume: 226758,
			time: 1654090980000,
			lowest: 150.7,
			highest: 150.845,
			volumePrice: 150.7723,
			transactionCount: 1902,
			voluemPrice: 150.7723
		},
		{
			open: 150.76,
			close: 150.73,
			volume: 322187,
			time: 1654091040000,
			lowest: 150.41,
			highest: 150.8282,
			volumePrice: 150.6428,
			transactionCount: 2775,
			voluemPrice: 150.6428
		},
		{
			open: 150.74,
			close: 151.05,
			volume: 541399,
			time: 1654091100000,
			lowest: 150.68,
			highest: 151.13,
			volumePrice: 150.9073,
			transactionCount: 4404,
			voluemPrice: 150.9073
		},
		{
			open: 151.055,
			close: 151.0428,
			volume: 277935,
			time: 1654091160000,
			lowest: 150.93,
			highest: 151.08,
			volumePrice: 151.0228,
			transactionCount: 2565,
			voluemPrice: 151.0228
		},
		{
			open: 151.06,
			close: 151.04,
			volume: 542643,
			time: 1654091220000,
			lowest: 151,
			highest: 151.52,
			volumePrice: 151.2776,
			transactionCount: 4640,
			voluemPrice: 151.2776
		},
		{
			open: 151.02,
			close: 150.87,
			volume: 407323,
			time: 1654091280000,
			lowest: 150.5,
			highest: 151.02,
			volumePrice: 150.7779,
			transactionCount: 3526,
			voluemPrice: 150.7779
		},
		{
			open: 150.85,
			close: 150.86,
			volume: 265443,
			time: 1654091340000,
			lowest: 150.8,
			highest: 151.06,
			volumePrice: 150.914,
			transactionCount: 2269,
			voluemPrice: 150.914
		},
		{
			open: 150.87,
			close: 150.88,
			volume: 249849,
			time: 1654091400000,
			lowest: 150.76,
			highest: 151.05,
			volumePrice: 150.9089,
			transactionCount: 2415,
			voluemPrice: 150.9089
		},
		{
			open: 150.85,
			close: 150.98,
			volume: 178603,
			time: 1654091460000,
			lowest: 150.78,
			highest: 151.0856,
			volumePrice: 150.9153,
			transactionCount: 1940,
			voluemPrice: 150.9153
		},
		{
			open: 150.99,
			close: 151.44,
			volume: 403676,
			time: 1654091520000,
			lowest: 150.99,
			highest: 151.47,
			volumePrice: 151.3346,
			transactionCount: 3194,
			voluemPrice: 151.3346
		},
		{
			open: 151.4429,
			close: 151.26,
			volume: 306838,
			time: 1654091580000,
			lowest: 151.22,
			highest: 151.51,
			volumePrice: 151.3721,
			transactionCount: 2401,
			voluemPrice: 151.3721
		},
		{
			open: 151.25,
			close: 151.3894,
			volume: 240481,
			time: 1654091640000,
			lowest: 151.08,
			highest: 151.4,
			volumePrice: 151.2359,
			transactionCount: 2317,
			voluemPrice: 151.2359
		},
		{
			open: 151.382,
			close: 151.4,
			volume: 289581,
			time: 1654091700000,
			lowest: 151.25,
			highest: 151.5,
			volumePrice: 151.4063,
			transactionCount: 2439,
			voluemPrice: 151.4063
		},
		{
			open: 151.395,
			close: 151.4347,
			volume: 173565,
			time: 1654091760000,
			lowest: 151.29,
			highest: 151.46,
			volumePrice: 151.3817,
			transactionCount: 1776,
			voluemPrice: 151.3817
		},
		{
			open: 151.45,
			close: 151.2328,
			volume: 272203,
			time: 1654091820000,
			lowest: 151.2301,
			highest: 151.5,
			volumePrice: 151.3899,
			transactionCount: 2642,
			voluemPrice: 151.3899
		},
		{
			open: 151.23,
			close: 151.21,
			volume: 247492,
			time: 1654091880000,
			lowest: 151.13,
			highest: 151.2837,
			volumePrice: 151.2195,
			transactionCount: 2061,
			voluemPrice: 151.2195
		},
		{
			open: 151.19,
			close: 151.3401,
			volume: 249951,
			time: 1654091940000,
			lowest: 151.16,
			highest: 151.4582,
			volumePrice: 151.3047,
			transactionCount: 2189,
			voluemPrice: 151.3047
		},
		{
			open: 151.3687,
			close: 150.875,
			volume: 832855,
			time: 1654092000000,
			lowest: 150.67,
			highest: 151.57,
			volumePrice: 151.0802,
			transactionCount: 7845,
			voluemPrice: 151.0802
		},
		{
			open: 150.88,
			close: 151.37,
			volume: 276748,
			time: 1654092060000,
			lowest: 150.77,
			highest: 151.37,
			volumePrice: 151.0925,
			transactionCount: 2656,
			voluemPrice: 151.0925
		},
		{
			open: 151.3705,
			close: 151.66,
			volume: 437634,
			time: 1654092120000,
			lowest: 151.3121,
			highest: 151.74,
			volumePrice: 151.5678,
			transactionCount: 3798,
			voluemPrice: 151.5678
		},
		{
			open: 151.6687,
			close: 151.53,
			volume: 254125,
			time: 1654092180000,
			lowest: 151.455,
			highest: 151.67,
			volumePrice: 151.571,
			transactionCount: 2444,
			voluemPrice: 151.571
		},
		{
			open: 151.52,
			close: 151.335,
			volume: 230780,
			time: 1654092240000,
			lowest: 151.31,
			highest: 151.5699,
			volumePrice: 151.4532,
			transactionCount: 2454,
			voluemPrice: 151.4532
		},
		{
			open: 151.332,
			close: 150.86,
			volume: 363567,
			time: 1654092300000,
			lowest: 150.725,
			highest: 151.37,
			volumePrice: 150.9974,
			transactionCount: 3580,
			voluemPrice: 150.9974
		},
		{
			open: 150.87,
			close: 150.66,
			volume: 376090,
			time: 1654092360000,
			lowest: 150.48,
			highest: 150.89,
			volumePrice: 150.6422,
			transactionCount: 3766,
			voluemPrice: 150.6422
		},
		{
			open: 150.66,
			close: 150.65,
			volume: 286425,
			time: 1654092420000,
			lowest: 150.53,
			highest: 150.75,
			volumePrice: 150.6147,
			transactionCount: 2225,
			voluemPrice: 150.6147
		},
		{
			open: 150.64,
			close: 150.34,
			volume: 290722,
			time: 1654092480000,
			lowest: 150.28,
			highest: 150.67,
			volumePrice: 150.489,
			transactionCount: 2729,
			voluemPrice: 150.489
		},
		{
			open: 150.34,
			close: 150.31,
			volume: 355251,
			time: 1654092540000,
			lowest: 150.12,
			highest: 150.49,
			volumePrice: 150.2634,
			transactionCount: 2888,
			voluemPrice: 150.2634
		},
		{
			open: 150.31,
			close: 150.3754,
			volume: 539013,
			time: 1654092600000,
			lowest: 149.92,
			highest: 150.53,
			volumePrice: 150.1584,
			transactionCount: 2736,
			voluemPrice: 150.1584
		},
		{
			open: 150.3508,
			close: 150.15,
			volume: 306099,
			time: 1654092660000,
			lowest: 150.06,
			highest: 150.36,
			volumePrice: 150.195,
			transactionCount: 2535,
			voluemPrice: 150.195
		},
		{
			open: 150.12,
			close: 150.1469,
			volume: 315082,
			time: 1654092720000,
			lowest: 150.01,
			highest: 150.23,
			volumePrice: 150.0933,
			transactionCount: 2997,
			voluemPrice: 150.0933
		},
		{
			open: 150.1411,
			close: 150.1357,
			volume: 210430,
			time: 1654092780000,
			lowest: 150.015,
			highest: 150.19,
			volumePrice: 150.0956,
			transactionCount: 1926,
			voluemPrice: 150.0956
		},
		{
			open: 150.13,
			close: 150.0923,
			volume: 199443,
			time: 1654092840000,
			lowest: 150.03,
			highest: 150.2,
			volumePrice: 150.1235,
			transactionCount: 1976,
			voluemPrice: 150.1235
		},
		{
			open: 150.09,
			close: 149.675,
			volume: 493561,
			time: 1654092900000,
			lowest: 149.59,
			highest: 150.1,
			volumePrice: 149.827,
			transactionCount: 4762,
			voluemPrice: 149.827
		},
		{
			open: 149.66,
			close: 149.315,
			volume: 336974,
			time: 1654092960000,
			lowest: 149.315,
			highest: 149.7,
			volumePrice: 149.5556,
			transactionCount: 3092,
			voluemPrice: 149.5556
		},
		{
			open: 149.32,
			close: 149.4133,
			volume: 325895,
			time: 1654093020000,
			lowest: 149.1188,
			highest: 149.4133,
			volumePrice: 149.2551,
			transactionCount: 2922,
			voluemPrice: 149.2551
		},
		{
			open: 149.3938,
			close: 149.59,
			volume: 260906,
			time: 1654093080000,
			lowest: 149.36,
			highest: 149.6,
			volumePrice: 149.4854,
			transactionCount: 2261,
			voluemPrice: 149.4854
		},
		{
			open: 149.58,
			close: 149.5163,
			volume: 235378,
			time: 1654093140000,
			lowest: 149.43,
			highest: 149.64,
			volumePrice: 149.5199,
			transactionCount: 1969,
			voluemPrice: 149.5199
		},
		{
			open: 149.5199,
			close: 149.7,
			volume: 311331,
			time: 1654093200000,
			lowest: 149.515,
			highest: 149.82,
			volumePrice: 149.6718,
			transactionCount: 2542,
			voluemPrice: 149.6718
		},
		{
			open: 149.72,
			close: 149.6579,
			volume: 224385,
			time: 1654093260000,
			lowest: 149.64,
			highest: 149.82,
			volumePrice: 149.7368,
			transactionCount: 1929,
			voluemPrice: 149.7368
		},
		{
			open: 149.67,
			close: 149.475,
			volume: 226800,
			time: 1654093320000,
			lowest: 149.455,
			highest: 149.73,
			volumePrice: 149.588,
			transactionCount: 2163,
			voluemPrice: 149.588
		},
		{
			open: 149.47,
			close: 149.63,
			volume: 202587,
			time: 1654093380000,
			lowest: 149.35,
			highest: 149.69,
			volumePrice: 149.5404,
			transactionCount: 2037,
			voluemPrice: 149.5404
		},
		{
			open: 149.63,
			close: 149.445,
			volume: 216478,
			time: 1654093440000,
			lowest: 149.44,
			highest: 149.73,
			volumePrice: 149.5773,
			transactionCount: 2242,
			voluemPrice: 149.5773
		},
		{
			open: 149.45,
			close: 149.4,
			volume: 207590,
			time: 1654093500000,
			lowest: 149.38,
			highest: 149.67,
			volumePrice: 149.4926,
			transactionCount: 1806,
			voluemPrice: 149.4926
		},
		{
			open: 149.41,
			close: 149.061,
			volume: 281105,
			time: 1654093560000,
			lowest: 149.05,
			highest: 149.42,
			volumePrice: 149.2196,
			transactionCount: 2660,
			voluemPrice: 149.2196
		},
		{
			open: 149.07,
			close: 149.08,
			volume: 298483,
			time: 1654093620000,
			lowest: 149.03,
			highest: 149.27,
			volumePrice: 149.1595,
			transactionCount: 2757,
			voluemPrice: 149.1595
		},
		{
			open: 149.085,
			close: 149.1,
			volume: 189753,
			time: 1654093680000,
			lowest: 149.02,
			highest: 149.2101,
			volumePrice: 149.1157,
			transactionCount: 1898,
			voluemPrice: 149.1157
		},
		{
			open: 149.09,
			close: 149.1572,
			volume: 107661,
			time: 1654093740000,
			lowest: 149.09,
			highest: 149.23,
			volumePrice: 149.1664,
			transactionCount: 1180,
			voluemPrice: 149.1664
		},
		{
			open: 149.14,
			close: 149.41,
			volume: 255396,
			time: 1654093800000,
			lowest: 149.03,
			highest: 149.46,
			volumePrice: 149.3036,
			transactionCount: 2398,
			voluemPrice: 149.3036
		},
		{
			open: 149.41,
			close: 149.35,
			volume: 193234,
			time: 1654093860000,
			lowest: 149.27,
			highest: 149.44,
			volumePrice: 149.3747,
			transactionCount: 1766,
			voluemPrice: 149.3747
		},
		{
			open: 149.345,
			close: 149.03,
			volume: 171039,
			time: 1654093920000,
			lowest: 149.01,
			highest: 149.36,
			volumePrice: 149.2324,
			transactionCount: 1808,
			voluemPrice: 149.2324
		},
		{
			open: 149.03,
			close: 149.2487,
			volume: 210111,
			time: 1654093980000,
			lowest: 149.01,
			highest: 149.249,
			volumePrice: 149.146,
			transactionCount: 1949,
			voluemPrice: 149.146
		},
		{
			open: 149.25,
			close: 149.01,
			volume: 144517,
			time: 1654094040000,
			lowest: 149.01,
			highest: 149.28,
			volumePrice: 149.1184,
			transactionCount: 1489,
			voluemPrice: 149.1184
		},
		{
			open: 149.01,
			close: 148.87,
			volume: 346175,
			time: 1654094100000,
			lowest: 148.78,
			highest: 149.0291,
			volumePrice: 148.9439,
			transactionCount: 4104,
			voluemPrice: 148.9439
		},
		{
			open: 148.85,
			close: 148.7554,
			volume: 214617,
			time: 1654094160000,
			lowest: 148.66,
			highest: 148.86,
			volumePrice: 148.7502,
			transactionCount: 2066,
			voluemPrice: 148.7502
		},
		{
			open: 148.75,
			close: 148.8901,
			volume: 259947,
			time: 1654094220000,
			lowest: 148.6607,
			highest: 148.94,
			volumePrice: 148.8111,
			transactionCount: 1630,
			voluemPrice: 148.8111
		},
		{
			open: 148.91,
			close: 149.1,
			volume: 174300,
			time: 1654094280000,
			lowest: 148.91,
			highest: 149.12,
			volumePrice: 149.0315,
			transactionCount: 1530,
			voluemPrice: 149.0315
		},
		{
			open: 149.0975,
			close: 149.19,
			volume: 206201,
			time: 1654094340000,
			lowest: 149.0199,
			highest: 149.24,
			volumePrice: 149.1668,
			transactionCount: 1693,
			voluemPrice: 149.1668
		},
		{
			open: 149.2,
			close: 149.24,
			volume: 454416,
			time: 1654094400000,
			lowest: 149.15,
			highest: 149.4,
			volumePrice: 149.2542,
			transactionCount: 2542,
			voluemPrice: 149.2542
		},
		{
			open: 149.2365,
			close: 149.33,
			volume: 183632,
			time: 1654094460000,
			lowest: 149.17,
			highest: 149.4,
			volumePrice: 149.3185,
			transactionCount: 1763,
			voluemPrice: 149.3185
		},
		{
			open: 149.32,
			close: 149.49,
			volume: 194821,
			time: 1654094520000,
			lowest: 149.2001,
			highest: 149.55,
			volumePrice: 149.3809,
			transactionCount: 1714,
			voluemPrice: 149.3809
		},
		{
			open: 149.48,
			close: 149.6618,
			volume: 133045,
			time: 1654094580000,
			lowest: 149.3412,
			highest: 149.6911,
			volumePrice: 149.5576,
			transactionCount: 1431,
			voluemPrice: 149.5576
		},
		{
			open: 149.65,
			close: 149.6,
			volume: 131322,
			time: 1654094640000,
			lowest: 149.58,
			highest: 149.7,
			volumePrice: 149.6425,
			transactionCount: 1251,
			voluemPrice: 149.6425
		},
		{
			open: 149.6,
			close: 149.58,
			volume: 152719,
			time: 1654094700000,
			lowest: 149.49,
			highest: 149.6598,
			volumePrice: 149.5738,
			transactionCount: 1518,
			voluemPrice: 149.5738
		},
		{
			open: 149.575,
			close: 149.5799,
			volume: 126357,
			time: 1654094760000,
			lowest: 149.51,
			highest: 149.7,
			volumePrice: 149.598,
			transactionCount: 1254,
			voluemPrice: 149.598
		},
		{
			open: 149.58,
			close: 149.51,
			volume: 188257,
			time: 1654094820000,
			lowest: 149.47,
			highest: 149.7,
			volumePrice: 149.5723,
			transactionCount: 1800,
			voluemPrice: 149.5723
		},
		{
			open: 149.51,
			close: 149.4,
			volume: 114751,
			time: 1654094880000,
			lowest: 149.4,
			highest: 149.57,
			volumePrice: 149.5058,
			transactionCount: 1245,
			voluemPrice: 149.5058
		},
		{
			open: 149.4034,
			close: 149.29,
			volume: 109153,
			time: 1654094940000,
			lowest: 149.28,
			highest: 149.52,
			volumePrice: 149.4023,
			transactionCount: 1177,
			voluemPrice: 149.4023
		},
		{
			open: 149.32,
			close: 149.31,
			volume: 144684,
			time: 1654095000000,
			lowest: 149.21,
			highest: 149.38,
			volumePrice: 149.2878,
			transactionCount: 1514,
			voluemPrice: 149.2878
		},
		{
			open: 149.3099,
			close: 149.45,
			volume: 146681,
			time: 1654095060000,
			lowest: 149.14,
			highest: 149.45,
			volumePrice: 149.2727,
			transactionCount: 1372,
			voluemPrice: 149.2727
		},
		{
			open: 149.46,
			close: 149.49,
			volume: 188523,
			time: 1654095120000,
			lowest: 149.431,
			highest: 149.61,
			volumePrice: 149.5262,
			transactionCount: 1868,
			voluemPrice: 149.5262
		},
		{
			open: 149.49,
			close: 149.7,
			volume: 113467,
			time: 1654095180000,
			lowest: 149.49,
			highest: 149.7,
			volumePrice: 149.6136,
			transactionCount: 1248,
			voluemPrice: 149.6136
		},
		{
			open: 149.69,
			close: 149.795,
			volume: 220623,
			time: 1654095240000,
			lowest: 149.67,
			highest: 149.91,
			volumePrice: 149.804,
			transactionCount: 1907,
			voluemPrice: 149.804
		},
		{
			open: 149.8,
			close: 149.935,
			volume: 189050,
			time: 1654095300000,
			lowest: 149.72,
			highest: 149.94,
			volumePrice: 149.8286,
			transactionCount: 1529,
			voluemPrice: 149.8286
		},
		{
			open: 149.9399,
			close: 149.915,
			volume: 183340,
			time: 1654095360000,
			lowest: 149.81,
			highest: 149.99,
			volumePrice: 149.907,
			transactionCount: 1593,
			voluemPrice: 149.907
		},
		{
			open: 149.92,
			close: 149.91,
			volume: 126632,
			time: 1654095420000,
			lowest: 149.8496,
			highest: 149.94,
			volumePrice: 149.8975,
			transactionCount: 1166,
			voluemPrice: 149.8975
		},
		{
			open: 149.915,
			close: 149.92,
			volume: 140408,
			time: 1654095480000,
			lowest: 149.87,
			highest: 149.945,
			volumePrice: 149.9149,
			transactionCount: 1316,
			voluemPrice: 149.9149
		},
		{
			open: 149.92,
			close: 149.815,
			volume: 182635,
			time: 1654095540000,
			lowest: 149.77,
			highest: 149.93,
			volumePrice: 149.84,
			transactionCount: 1658,
			voluemPrice: 149.84
		},
		{
			open: 149.81,
			close: 149.58,
			volume: 207453,
			time: 1654095600000,
			lowest: 149.53,
			highest: 149.81,
			volumePrice: 149.6576,
			transactionCount: 1828,
			voluemPrice: 149.6576
		},
		{
			open: 149.56,
			close: 149.32,
			volume: 144938,
			time: 1654095660000,
			lowest: 149.32,
			highest: 149.6682,
			volumePrice: 149.5178,
			transactionCount: 1403,
			voluemPrice: 149.5178
		},
		{
			open: 149.3015,
			close: 149.1756,
			volume: 210992,
			time: 1654095720000,
			lowest: 149.03,
			highest: 149.31,
			volumePrice: 149.161,
			transactionCount: 1929,
			voluemPrice: 149.161
		},
		{
			open: 149.1756,
			close: 149.3301,
			volume: 132524,
			time: 1654095780000,
			lowest: 149.0983,
			highest: 149.4,
			volumePrice: 149.2217,
			transactionCount: 1228,
			voluemPrice: 149.2217
		},
		{
			open: 149.34,
			close: 149.18,
			volume: 163434,
			time: 1654095840000,
			lowest: 149.11,
			highest: 149.39,
			volumePrice: 149.2917,
			transactionCount: 1290,
			voluemPrice: 149.2917
		},
		{
			open: 149.18,
			close: 149.23,
			volume: 156107,
			time: 1654095900000,
			lowest: 149.1,
			highest: 149.37,
			volumePrice: 149.2362,
			transactionCount: 1313,
			voluemPrice: 149.2362
		},
		{
			open: 149.24,
			close: 149.22,
			volume: 97682,
			time: 1654095960000,
			lowest: 149.16,
			highest: 149.28,
			volumePrice: 149.2168,
			transactionCount: 1035,
			voluemPrice: 149.2168
		},
		{
			open: 149.24,
			close: 149.11,
			volume: 97414,
			time: 1654096020000,
			lowest: 149.07,
			highest: 149.275,
			volumePrice: 149.1717,
			transactionCount: 1165,
			voluemPrice: 149.1717
		},
		{
			open: 149.11,
			close: 149.12,
			volume: 113441,
			time: 1654096080000,
			lowest: 149.03,
			highest: 149.26,
			volumePrice: 149.1446,
			transactionCount: 1148,
			voluemPrice: 149.1446
		},
		{
			open: 149.1601,
			close: 148.915,
			volume: 203446,
			time: 1654096140000,
			lowest: 148.86,
			highest: 149.18,
			volumePrice: 149.0209,
			transactionCount: 1945,
			voluemPrice: 149.0209
		},
		{
			open: 148.909,
			close: 149.09,
			volume: 233677,
			time: 1654096200000,
			lowest: 148.9,
			highest: 149.2,
			volumePrice: 149.036,
			transactionCount: 1657,
			voluemPrice: 149.036
		},
		{
			open: 149.08,
			close: 149.1099,
			volume: 135004,
			time: 1654096260000,
			lowest: 148.97,
			highest: 149.23,
			volumePrice: 149.1122,
			transactionCount: 1340,
			voluemPrice: 149.1122
		},
		{
			open: 149.1001,
			close: 149.2558,
			volume: 144086,
			time: 1654096320000,
			lowest: 149.1001,
			highest: 149.32,
			volumePrice: 149.2531,
			transactionCount: 1308,
			voluemPrice: 149.2531
		},
		{
			open: 149.2301,
			close: 149.12,
			volume: 92361,
			time: 1654096380000,
			lowest: 149.08,
			highest: 149.24,
			volumePrice: 149.1513,
			transactionCount: 841,
			voluemPrice: 149.1513
		},
		{
			open: 149.115,
			close: 149.01,
			volume: 91100,
			time: 1654096440000,
			lowest: 148.97,
			highest: 149.17,
			volumePrice: 149.0554,
			transactionCount: 999,
			voluemPrice: 149.0554
		},
		{
			open: 149.01,
			close: 148.895,
			volume: 168486,
			time: 1654096500000,
			lowest: 148.8,
			highest: 149.08,
			volumePrice: 148.9321,
			transactionCount: 1421,
			voluemPrice: 148.9321
		},
		{
			open: 148.8931,
			close: 148.7043,
			volume: 142460,
			time: 1654096560000,
			lowest: 148.6701,
			highest: 149.05,
			volumePrice: 148.8656,
			transactionCount: 1585,
			voluemPrice: 148.8656
		},
		{
			open: 148.7,
			close: 148.71,
			volume: 201495,
			time: 1654096620000,
			lowest: 148.62,
			highest: 148.85,
			volumePrice: 148.6961,
			transactionCount: 1869,
			voluemPrice: 148.6961
		},
		{
			open: 148.7,
			close: 148.615,
			volume: 210038,
			time: 1654096680000,
			lowest: 148.45,
			highest: 148.76,
			volumePrice: 148.5981,
			transactionCount: 2526,
			voluemPrice: 148.5981
		},
		{
			open: 148.6199,
			close: 148.74,
			volume: 124620,
			time: 1654096740000,
			lowest: 148.6037,
			highest: 148.78,
			volumePrice: 148.7025,
			transactionCount: 1297,
			voluemPrice: 148.7025
		},
		{
			open: 148.72,
			close: 148.57,
			volume: 127171,
			time: 1654096800000,
			lowest: 148.48,
			highest: 148.73,
			volumePrice: 148.5663,
			transactionCount: 1444,
			voluemPrice: 148.5663
		},
		{
			open: 148.535,
			close: 148.5,
			volume: 135637,
			time: 1654096860000,
			lowest: 148.44,
			highest: 148.6,
			volumePrice: 148.523,
			transactionCount: 1306,
			voluemPrice: 148.523
		},
		{
			open: 148.4866,
			close: 148.45,
			volume: 208350,
			time: 1654096920000,
			lowest: 148.43,
			highest: 148.5652,
			volumePrice: 148.4843,
			transactionCount: 1817,
			voluemPrice: 148.4843
		},
		{
			open: 148.4465,
			close: 148.35,
			volume: 140463,
			time: 1654096980000,
			lowest: 148.34,
			highest: 148.54,
			volumePrice: 148.4382,
			transactionCount: 1507,
			voluemPrice: 148.4382
		},
		{
			open: 148.34,
			close: 148.33,
			volume: 186714,
			time: 1654097040000,
			lowest: 148.19,
			highest: 148.38,
			volumePrice: 148.2765,
			transactionCount: 1694,
			voluemPrice: 148.2765
		},
		{
			open: 148.34,
			close: 148.26,
			volume: 200425,
			time: 1654097100000,
			lowest: 148.255,
			highest: 148.43,
			volumePrice: 148.3529,
			transactionCount: 1430,
			voluemPrice: 148.3529
		},
		{
			open: 148.27,
			close: 148.3,
			volume: 138617,
			time: 1654097160000,
			lowest: 148.22,
			highest: 148.305,
			volumePrice: 148.2698,
			transactionCount: 1262,
			voluemPrice: 148.2698
		},
		{
			open: 148.3,
			close: 148.3936,
			volume: 143275,
			time: 1654097220000,
			lowest: 148.25,
			highest: 148.42,
			volumePrice: 148.3106,
			transactionCount: 1371,
			voluemPrice: 148.3106
		},
		{
			open: 148.405,
			close: 148.63,
			volume: 221992,
			time: 1654097280000,
			lowest: 148.389,
			highest: 148.65,
			volumePrice: 148.5322,
			transactionCount: 1720,
			voluemPrice: 148.5322
		},
		{
			open: 148.63,
			close: 148.68,
			volume: 135211,
			time: 1654097340000,
			lowest: 148.495,
			highest: 148.7,
			volumePrice: 148.6067,
			transactionCount: 1458,
			voluemPrice: 148.6067
		},
		{
			open: 148.7,
			close: 148.5377,
			volume: 139818,
			time: 1654097400000,
			lowest: 148.47,
			highest: 148.7,
			volumePrice: 148.5722,
			transactionCount: 1351,
			voluemPrice: 148.5722
		},
		{
			open: 148.5101,
			close: 148.71,
			volume: 145359,
			time: 1654097460000,
			lowest: 148.51,
			highest: 148.75,
			volumePrice: 148.6396,
			transactionCount: 1347,
			voluemPrice: 148.6396
		},
		{
			open: 148.7,
			close: 148.52,
			volume: 148064,
			time: 1654097520000,
			lowest: 148.485,
			highest: 148.775,
			volumePrice: 148.6296,
			transactionCount: 1604,
			voluemPrice: 148.6296
		},
		{
			open: 148.5,
			close: 148.48,
			volume: 169103,
			time: 1654097580000,
			lowest: 148.47,
			highest: 148.57,
			volumePrice: 148.506,
			transactionCount: 1263,
			voluemPrice: 148.506
		},
		{
			open: 148.47,
			close: 148.44,
			volume: 98107,
			time: 1654097640000,
			lowest: 148.42,
			highest: 148.56,
			volumePrice: 148.502,
			transactionCount: 1050,
			voluemPrice: 148.502
		},
		{
			open: 148.45,
			close: 148.34,
			volume: 122401,
			time: 1654097700000,
			lowest: 148.2559,
			highest: 148.49,
			volumePrice: 148.3578,
			transactionCount: 1298,
			voluemPrice: 148.3578
		},
		{
			open: 148.335,
			close: 148.115,
			volume: 345872,
			time: 1654097760000,
			lowest: 148.11,
			highest: 148.335,
			volumePrice: 148.2841,
			transactionCount: 1640,
			voluemPrice: 148.2841
		},
		{
			open: 148.115,
			close: 148.18,
			volume: 119759,
			time: 1654097820000,
			lowest: 148.098,
			highest: 148.275,
			volumePrice: 148.2051,
			transactionCount: 1325,
			voluemPrice: 148.2051
		},
		{
			open: 148.175,
			close: 148.4294,
			volume: 96377,
			time: 1654097880000,
			lowest: 148.15,
			highest: 148.4653,
			volumePrice: 148.3271,
			transactionCount: 1102,
			voluemPrice: 148.3271
		},
		{
			open: 148.43,
			close: 148.2617,
			volume: 119641,
			time: 1654097940000,
			lowest: 148.23,
			highest: 148.43,
			volumePrice: 148.3066,
			transactionCount: 1228,
			voluemPrice: 148.3066
		},
		{
			open: 148.26,
			close: 148.0305,
			volume: 140074,
			time: 1654098000000,
			lowest: 148.0305,
			highest: 148.28,
			volumePrice: 148.1648,
			transactionCount: 1383,
			voluemPrice: 148.1648
		},
		{
			open: 148.045,
			close: 148.0889,
			volume: 123394,
			time: 1654098060000,
			lowest: 148.03,
			highest: 148.21,
			volumePrice: 148.1358,
			transactionCount: 1225,
			voluemPrice: 148.1358
		},
		{
			open: 148.0701,
			close: 148.0939,
			volume: 106324,
			time: 1654098120000,
			lowest: 148.02,
			highest: 148.2,
			volumePrice: 148.0937,
			transactionCount: 1208,
			voluemPrice: 148.0937
		},
		{
			open: 148.09,
			close: 148.135,
			volume: 98195,
			time: 1654098180000,
			lowest: 148.04,
			highest: 148.2,
			volumePrice: 148.1207,
			transactionCount: 1023,
			voluemPrice: 148.1207
		},
		{
			open: 148.14,
			close: 147.9928,
			volume: 230982,
			time: 1654098240000,
			lowest: 147.935,
			highest: 148.18,
			volumePrice: 148.0305,
			transactionCount: 3080,
			voluemPrice: 148.0305
		},
		{
			open: 147.98,
			close: 148.11,
			volume: 125248,
			time: 1654098300000,
			lowest: 147.91,
			highest: 148.13,
			volumePrice: 148.027,
			transactionCount: 1215,
			voluemPrice: 148.027
		},
		{
			open: 148.1084,
			close: 148.14,
			volume: 120873,
			time: 1654098360000,
			lowest: 148.06,
			highest: 148.2362,
			volumePrice: 148.1712,
			transactionCount: 1218,
			voluemPrice: 148.1712
		},
		{
			open: 148.145,
			close: 148.13,
			volume: 112850,
			time: 1654098420000,
			lowest: 148.0817,
			highest: 148.255,
			volumePrice: 148.1672,
			transactionCount: 1142,
			voluemPrice: 148.1672
		},
		{
			open: 148.13,
			close: 148.2,
			volume: 84405,
			time: 1654098480000,
			lowest: 148.04,
			highest: 148.2,
			volumePrice: 148.1007,
			transactionCount: 947,
			voluemPrice: 148.1007
		},
		{
			open: 148.19,
			close: 148,
			volume: 122697,
			time: 1654098540000,
			lowest: 148,
			highest: 148.26,
			volumePrice: 148.1487,
			transactionCount: 1166,
			voluemPrice: 148.1487
		},
		{
			open: 148,
			close: 148.164,
			volume: 165752,
			time: 1654098600000,
			lowest: 147.96,
			highest: 148.21,
			volumePrice: 148.0756,
			transactionCount: 1395,
			voluemPrice: 148.0756
		},
		{
			open: 148.165,
			close: 148.1,
			volume: 90252,
			time: 1654098660000,
			lowest: 148.07,
			highest: 148.23,
			volumePrice: 148.1631,
			transactionCount: 1062,
			voluemPrice: 148.1631
		},
		{
			open: 148.105,
			close: 148.2147,
			volume: 86071,
			time: 1654098720000,
			lowest: 148.0613,
			highest: 148.24,
			volumePrice: 148.1748,
			transactionCount: 861,
			voluemPrice: 148.1748
		},
		{
			open: 148.215,
			close: 148.26,
			volume: 111584,
			time: 1654098780000,
			lowest: 148.0535,
			highest: 148.29,
			volumePrice: 148.194,
			transactionCount: 1089,
			voluemPrice: 148.194
		},
		{
			open: 148.26,
			close: 148.37,
			volume: 144116,
			time: 1654098840000,
			lowest: 148.2028,
			highest: 148.37,
			volumePrice: 148.2936,
			transactionCount: 1289,
			voluemPrice: 148.2936
		},
		{
			open: 148.37,
			close: 148.426,
			volume: 170298,
			time: 1654098900000,
			lowest: 148.31,
			highest: 148.54,
			volumePrice: 148.4226,
			transactionCount: 1602,
			voluemPrice: 148.4226
		},
		{
			open: 148.42,
			close: 148.385,
			volume: 103647,
			time: 1654098960000,
			lowest: 148.36,
			highest: 148.49,
			volumePrice: 148.4232,
			transactionCount: 927,
			voluemPrice: 148.4232
		},
		{
			open: 148.38,
			close: 148.3701,
			volume: 116619,
			time: 1654099020000,
			lowest: 148.27,
			highest: 148.4,
			volumePrice: 148.3439,
			transactionCount: 1231,
			voluemPrice: 148.3439
		},
		{
			open: 148.37,
			close: 148.2496,
			volume: 120042,
			time: 1654099080000,
			lowest: 148.2126,
			highest: 148.4467,
			volumePrice: 148.3508,
			transactionCount: 1239,
			voluemPrice: 148.3508
		},
		{
			open: 148.23,
			close: 148.24,
			volume: 90764,
			time: 1654099140000,
			lowest: 148.1601,
			highest: 148.31,
			volumePrice: 148.2302,
			transactionCount: 873,
			voluemPrice: 148.2302
		},
		{
			open: 148.2309,
			close: 148.0887,
			volume: 124774,
			time: 1654099200000,
			lowest: 148.05,
			highest: 148.3099,
			volumePrice: 148.2036,
			transactionCount: 1190,
			voluemPrice: 148.2036
		},
		{
			open: 148.07,
			close: 147.99,
			volume: 156999,
			time: 1654099260000,
			lowest: 147.92,
			highest: 148.1,
			volumePrice: 148.0128,
			transactionCount: 1565,
			voluemPrice: 148.0128
		},
		{
			open: 147.975,
			close: 148.11,
			volume: 151583,
			time: 1654099320000,
			lowest: 147.9405,
			highest: 148.1474,
			volumePrice: 148.0447,
			transactionCount: 1437,
			voluemPrice: 148.0447
		},
		{
			open: 148.13,
			close: 147.91,
			volume: 101570,
			time: 1654099380000,
			lowest: 147.91,
			highest: 148.1499,
			volumePrice: 147.983,
			transactionCount: 1094,
			voluemPrice: 147.983
		},
		{
			open: 147.91,
			close: 147.9173,
			volume: 174442,
			time: 1654099440000,
			lowest: 147.8685,
			highest: 147.95,
			volumePrice: 147.903,
			transactionCount: 1574,
			voluemPrice: 147.903
		},
		{
			open: 147.92,
			close: 148.2115,
			volume: 154011,
			time: 1654099500000,
			lowest: 147.9,
			highest: 148.22,
			volumePrice: 148.0625,
			transactionCount: 1394,
			voluemPrice: 148.0625
		},
		{
			open: 148.19,
			close: 148.1601,
			volume: 102510,
			time: 1654099560000,
			lowest: 148.13,
			highest: 148.26,
			volumePrice: 148.2046,
			transactionCount: 997,
			voluemPrice: 148.2046
		},
		{
			open: 148.15,
			close: 148.25,
			volume: 65949,
			time: 1654099620000,
			lowest: 148.1265,
			highest: 148.2743,
			volumePrice: 148.1936,
			transactionCount: 781,
			voluemPrice: 148.1936
		},
		{
			open: 148.24,
			close: 148.16,
			volume: 74910,
			time: 1654099680000,
			lowest: 148.1409,
			highest: 148.31,
			volumePrice: 148.231,
			transactionCount: 788,
			voluemPrice: 148.231
		},
		{
			open: 148.1688,
			close: 148.17,
			volume: 82202,
			time: 1654099740000,
			lowest: 148.13,
			highest: 148.23,
			volumePrice: 148.1704,
			transactionCount: 939,
			voluemPrice: 148.1704
		},
		{
			open: 148.16,
			close: 147.8199,
			volume: 189010,
			time: 1654099800000,
			lowest: 147.8,
			highest: 148.16,
			volumePrice: 147.8891,
			transactionCount: 1740,
			voluemPrice: 147.8891
		},
		{
			open: 147.81,
			close: 147.79,
			volume: 128560,
			time: 1654099860000,
			lowest: 147.79,
			highest: 147.97,
			volumePrice: 147.8792,
			transactionCount: 1401,
			voluemPrice: 147.8792
		},
		{
			open: 147.79,
			close: 147.71,
			volume: 119944,
			time: 1654099920000,
			lowest: 147.68,
			highest: 147.89,
			volumePrice: 147.7756,
			transactionCount: 1353,
			voluemPrice: 147.7756
		},
		{
			open: 147.715,
			close: 147.98,
			volume: 111187,
			time: 1654099980000,
			lowest: 147.6901,
			highest: 147.99,
			volumePrice: 147.8784,
			transactionCount: 1189,
			voluemPrice: 147.8784
		},
		{
			open: 147.965,
			close: 148.05,
			volume: 118131,
			time: 1654100040000,
			lowest: 147.94,
			highest: 148.081,
			volumePrice: 148.0092,
			transactionCount: 1072,
			voluemPrice: 148.0092
		},
		{
			open: 148.05,
			close: 148.34,
			volume: 140101,
			time: 1654100100000,
			lowest: 148.005,
			highest: 148.36,
			volumePrice: 148.1929,
			transactionCount: 1395,
			voluemPrice: 148.1929
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 162795,
			time: 1654100160000,
			lowest: 148.225,
			highest: 148.43,
			volumePrice: 148.327,
			transactionCount: 1427,
			voluemPrice: 148.327
		},
		{
			open: 148.34,
			close: 148.15,
			volume: 131704,
			time: 1654100220000,
			lowest: 148.03,
			highest: 148.35,
			volumePrice: 148.2071,
			transactionCount: 1301,
			voluemPrice: 148.2071
		},
		{
			open: 148.13,
			close: 148.105,
			volume: 58445,
			time: 1654100280000,
			lowest: 148.064,
			highest: 148.14,
			volumePrice: 148.1044,
			transactionCount: 800,
			voluemPrice: 148.1044
		},
		{
			open: 148.105,
			close: 148.3599,
			volume: 151798,
			time: 1654100340000,
			lowest: 148.095,
			highest: 148.43,
			volumePrice: 148.2422,
			transactionCount: 1350,
			voluemPrice: 148.2422
		},
		{
			open: 148.34,
			close: 148.485,
			volume: 115671,
			time: 1654100400000,
			lowest: 148.3051,
			highest: 148.49,
			volumePrice: 148.3978,
			transactionCount: 912,
			voluemPrice: 148.3978
		},
		{
			open: 148.48,
			close: 148.36,
			volume: 99908,
			time: 1654100460000,
			lowest: 148.34,
			highest: 148.48,
			volumePrice: 148.3966,
			transactionCount: 1175,
			voluemPrice: 148.3966
		},
		{
			open: 148.36,
			close: 148.48,
			volume: 118805,
			time: 1654100520000,
			lowest: 148.355,
			highest: 148.51,
			volumePrice: 148.4527,
			transactionCount: 1141,
			voluemPrice: 148.4527
		},
		{
			open: 148.4799,
			close: 148.43,
			volume: 77835,
			time: 1654100580000,
			lowest: 148.37,
			highest: 148.48,
			volumePrice: 148.4215,
			transactionCount: 796,
			voluemPrice: 148.4215
		},
		{
			open: 148.425,
			close: 148.36,
			volume: 61364,
			time: 1654100640000,
			lowest: 148.36,
			highest: 148.425,
			volumePrice: 148.3876,
			transactionCount: 709,
			voluemPrice: 148.3876
		},
		{
			open: 148.36,
			close: 148.2677,
			volume: 84470,
			time: 1654100700000,
			lowest: 148.24,
			highest: 148.41,
			volumePrice: 148.3322,
			transactionCount: 787,
			voluemPrice: 148.3322
		},
		{
			open: 148.24,
			close: 148.28,
			volume: 81249,
			time: 1654100760000,
			lowest: 148.18,
			highest: 148.3264,
			volumePrice: 148.2687,
			transactionCount: 825,
			voluemPrice: 148.2687
		},
		{
			open: 148.27,
			close: 148.42,
			volume: 118278,
			time: 1654100820000,
			lowest: 148.26,
			highest: 148.47,
			volumePrice: 148.3831,
			transactionCount: 1179,
			voluemPrice: 148.3831
		},
		{
			open: 148.42,
			close: 148.2458,
			volume: 82700,
			time: 1654100880000,
			lowest: 148.24,
			highest: 148.46,
			volumePrice: 148.3714,
			transactionCount: 860,
			voluemPrice: 148.3714
		},
		{
			open: 148.24,
			close: 148.06,
			volume: 110075,
			time: 1654100940000,
			lowest: 148.06,
			highest: 148.27,
			volumePrice: 148.157,
			transactionCount: 1073,
			voluemPrice: 148.157
		},
		{
			open: 148.06,
			close: 148.31,
			volume: 111223,
			time: 1654101000000,
			lowest: 148.04,
			highest: 148.34,
			volumePrice: 148.2115,
			transactionCount: 952,
			voluemPrice: 148.2115
		},
		{
			open: 148.34,
			close: 148.38,
			volume: 73669,
			time: 1654101060000,
			lowest: 148.3187,
			highest: 148.42,
			volumePrice: 148.3756,
			transactionCount: 807,
			voluemPrice: 148.3756
		},
		{
			open: 148.38,
			close: 148.515,
			volume: 118289,
			time: 1654101120000,
			lowest: 148.36,
			highest: 148.54,
			volumePrice: 148.4695,
			transactionCount: 1049,
			voluemPrice: 148.4695
		},
		{
			open: 148.53,
			close: 148.335,
			volume: 97777,
			time: 1654101180000,
			lowest: 148.33,
			highest: 148.59,
			volumePrice: 148.4543,
			transactionCount: 993,
			voluemPrice: 148.4543
		},
		{
			open: 148.33,
			close: 148.42,
			volume: 72474,
			time: 1654101240000,
			lowest: 148.23,
			highest: 148.4278,
			volumePrice: 148.3192,
			transactionCount: 729,
			voluemPrice: 148.3192
		},
		{
			open: 148.43,
			close: 148.435,
			volume: 71121,
			time: 1654101300000,
			lowest: 148.4,
			highest: 148.49,
			volumePrice: 148.4463,
			transactionCount: 746,
			voluemPrice: 148.4463
		},
		{
			open: 148.435,
			close: 148.44,
			volume: 79168,
			time: 1654101360000,
			lowest: 148.3847,
			highest: 148.48,
			volumePrice: 148.4458,
			transactionCount: 835,
			voluemPrice: 148.4458
		},
		{
			open: 148.43,
			close: 148.325,
			volume: 54102,
			time: 1654101420000,
			lowest: 148.31,
			highest: 148.44,
			volumePrice: 148.3536,
			transactionCount: 698,
			voluemPrice: 148.3536
		},
		{
			open: 148.31,
			close: 148.395,
			volume: 79509,
			time: 1654101480000,
			lowest: 148.2599,
			highest: 148.4,
			volumePrice: 148.3207,
			transactionCount: 926,
			voluemPrice: 148.3207
		},
		{
			open: 148.39,
			close: 148.42,
			volume: 50617,
			time: 1654101540000,
			lowest: 148.37,
			highest: 148.461,
			volumePrice: 148.4148,
			transactionCount: 704,
			voluemPrice: 148.4148
		},
		{
			open: 148.4,
			close: 148.5,
			volume: 96073,
			time: 1654101600000,
			lowest: 148.4,
			highest: 148.53,
			volumePrice: 148.485,
			transactionCount: 758,
			voluemPrice: 148.485
		},
		{
			open: 148.495,
			close: 148.41,
			volume: 85315,
			time: 1654101660000,
			lowest: 148.41,
			highest: 148.53,
			volumePrice: 148.4707,
			transactionCount: 782,
			voluemPrice: 148.4707
		},
		{
			open: 148.41,
			close: 148.25,
			volume: 85122,
			time: 1654101720000,
			lowest: 148.24,
			highest: 148.41,
			volumePrice: 148.3349,
			transactionCount: 801,
			voluemPrice: 148.3349
		},
		{
			open: 148.258,
			close: 148.24,
			volume: 58404,
			time: 1654101780000,
			lowest: 148.2,
			highest: 148.3,
			volumePrice: 148.248,
			transactionCount: 700,
			voluemPrice: 148.248
		},
		{
			open: 148.22,
			close: 148.1808,
			volume: 78414,
			time: 1654101840000,
			lowest: 148.155,
			highest: 148.225,
			volumePrice: 148.1915,
			transactionCount: 745,
			voluemPrice: 148.1915
		},
		{
			open: 148.1948,
			close: 148.17,
			volume: 58510,
			time: 1654101900000,
			lowest: 148.12,
			highest: 148.23,
			volumePrice: 148.1861,
			transactionCount: 756,
			voluemPrice: 148.1861
		},
		{
			open: 148.19,
			close: 148.21,
			volume: 45514,
			time: 1654101960000,
			lowest: 148.16,
			highest: 148.25,
			volumePrice: 148.1971,
			transactionCount: 620,
			voluemPrice: 148.1971
		},
		{
			open: 148.205,
			close: 148.24,
			volume: 63822,
			time: 1654102020000,
			lowest: 148.12,
			highest: 148.255,
			volumePrice: 148.1932,
			transactionCount: 755,
			voluemPrice: 148.1932
		},
		{
			open: 148.23,
			close: 147.97,
			volume: 150995,
			time: 1654102080000,
			lowest: 147.92,
			highest: 148.23,
			volumePrice: 148.0371,
			transactionCount: 1406,
			voluemPrice: 148.0371
		},
		{
			open: 147.98,
			close: 148.06,
			volume: 66046,
			time: 1654102140000,
			lowest: 147.94,
			highest: 148.07,
			volumePrice: 148.0162,
			transactionCount: 749,
			voluemPrice: 148.0162
		},
		{
			open: 148.06,
			close: 147.9499,
			volume: 72275,
			time: 1654102200000,
			lowest: 147.9499,
			highest: 148.1159,
			volumePrice: 148.0304,
			transactionCount: 825,
			voluemPrice: 148.0304
		},
		{
			open: 147.94,
			close: 147.92,
			volume: 75500,
			time: 1654102260000,
			lowest: 147.9,
			highest: 147.98,
			volumePrice: 147.9369,
			transactionCount: 689,
			voluemPrice: 147.9369
		},
		{
			open: 147.915,
			close: 148.1325,
			volume: 126818,
			time: 1654102320000,
			lowest: 147.91,
			highest: 148.1599,
			volumePrice: 148.0565,
			transactionCount: 1130,
			voluemPrice: 148.0565
		},
		{
			open: 148.14,
			close: 148.21,
			volume: 75426,
			time: 1654102380000,
			lowest: 148.08,
			highest: 148.24,
			volumePrice: 148.1642,
			transactionCount: 825,
			voluemPrice: 148.1642
		},
		{
			open: 148.2091,
			close: 148.25,
			volume: 71931,
			time: 1654102440000,
			lowest: 148.19,
			highest: 148.27,
			volumePrice: 148.235,
			transactionCount: 730,
			voluemPrice: 148.235
		},
		{
			open: 148.2501,
			close: 148.145,
			volume: 89019,
			time: 1654102500000,
			lowest: 148.09,
			highest: 148.27,
			volumePrice: 148.173,
			transactionCount: 919,
			voluemPrice: 148.173
		},
		{
			open: 148.14,
			close: 148.2,
			volume: 113960,
			time: 1654102560000,
			lowest: 148.13,
			highest: 148.32,
			volumePrice: 148.2121,
			transactionCount: 1415,
			voluemPrice: 148.2121
		},
		{
			open: 148.2,
			close: 148.22,
			volume: 112481,
			time: 1654102620000,
			lowest: 148.2,
			highest: 148.36,
			volumePrice: 148.2868,
			transactionCount: 1134,
			voluemPrice: 148.2868
		},
		{
			open: 148.2206,
			close: 148.23,
			volume: 59891,
			time: 1654102680000,
			lowest: 148.1741,
			highest: 148.258,
			volumePrice: 148.2137,
			transactionCount: 688,
			voluemPrice: 148.2137
		},
		{
			open: 148.24,
			close: 148.25,
			volume: 73703,
			time: 1654102740000,
			lowest: 148.17,
			highest: 148.26,
			volumePrice: 148.224,
			transactionCount: 800,
			voluemPrice: 148.224
		},
		{
			open: 148.25,
			close: 148.39,
			volume: 88957,
			time: 1654102800000,
			lowest: 148.19,
			highest: 148.42,
			volumePrice: 148.3019,
			transactionCount: 1013,
			voluemPrice: 148.3019
		},
		{
			open: 148.4,
			close: 148.4519,
			volume: 83975,
			time: 1654102860000,
			lowest: 148.38,
			highest: 148.48,
			volumePrice: 148.4397,
			transactionCount: 993,
			voluemPrice: 148.4397
		},
		{
			open: 148.44,
			close: 148.4099,
			volume: 106076,
			time: 1654102920000,
			lowest: 148.36,
			highest: 148.5,
			volumePrice: 148.4335,
			transactionCount: 1148,
			voluemPrice: 148.4335
		},
		{
			open: 148.4,
			close: 148.39,
			volume: 60753,
			time: 1654102980000,
			lowest: 148.31,
			highest: 148.44,
			volumePrice: 148.3646,
			transactionCount: 714,
			voluemPrice: 148.3646
		},
		{
			open: 148.4,
			close: 148.321,
			volume: 68162,
			time: 1654103040000,
			lowest: 148.29,
			highest: 148.42,
			volumePrice: 148.3625,
			transactionCount: 686,
			voluemPrice: 148.3625
		},
		{
			open: 148.315,
			close: 148.465,
			volume: 85116,
			time: 1654103100000,
			lowest: 148.29,
			highest: 148.465,
			volumePrice: 148.3899,
			transactionCount: 745,
			voluemPrice: 148.3899
		},
		{
			open: 148.47,
			close: 148.52,
			volume: 135195,
			time: 1654103160000,
			lowest: 148.41,
			highest: 148.57,
			volumePrice: 148.4923,
			transactionCount: 1106,
			voluemPrice: 148.4923
		},
		{
			open: 148.54,
			close: 148.655,
			volume: 126865,
			time: 1654103220000,
			lowest: 148.46,
			highest: 148.66,
			volumePrice: 148.5768,
			transactionCount: 1045,
			voluemPrice: 148.5768
		},
		{
			open: 148.65,
			close: 148.5711,
			volume: 178832,
			time: 1654103280000,
			lowest: 148.57,
			highest: 148.7695,
			volumePrice: 148.6893,
			transactionCount: 1301,
			voluemPrice: 148.6893
		},
		{
			open: 148.56,
			close: 148.755,
			volume: 108393,
			time: 1654103340000,
			lowest: 148.54,
			highest: 148.7701,
			volumePrice: 148.6879,
			transactionCount: 1010,
			voluemPrice: 148.6879
		},
		{
			open: 148.75,
			close: 148.83,
			volume: 144686,
			time: 1654103400000,
			lowest: 148.69,
			highest: 148.88,
			volumePrice: 148.7965,
			transactionCount: 1234,
			voluemPrice: 148.7965
		},
		{
			open: 148.825,
			close: 148.8,
			volume: 125484,
			time: 1654103460000,
			lowest: 148.7401,
			highest: 148.879,
			volumePrice: 148.7994,
			transactionCount: 1019,
			voluemPrice: 148.7994
		},
		{
			open: 148.795,
			close: 148.625,
			volume: 134398,
			time: 1654103520000,
			lowest: 148.59,
			highest: 148.795,
			volumePrice: 148.6723,
			transactionCount: 1107,
			voluemPrice: 148.6723
		},
		{
			open: 148.625,
			close: 148.6,
			volume: 83586,
			time: 1654103580000,
			lowest: 148.58,
			highest: 148.66,
			volumePrice: 148.6158,
			transactionCount: 747,
			voluemPrice: 148.6158
		},
		{
			open: 148.58,
			close: 148.5401,
			volume: 87590,
			time: 1654103640000,
			lowest: 148.485,
			highest: 148.58,
			volumePrice: 148.5391,
			transactionCount: 930,
			voluemPrice: 148.5391
		},
		{
			open: 148.545,
			close: 148.76,
			volume: 107670,
			time: 1654103700000,
			lowest: 148.51,
			highest: 148.76,
			volumePrice: 148.6414,
			transactionCount: 1014,
			voluemPrice: 148.6414
		},
		{
			open: 148.75,
			close: 148.7034,
			volume: 93518,
			time: 1654103760000,
			lowest: 148.61,
			highest: 148.76,
			volumePrice: 148.691,
			transactionCount: 865,
			voluemPrice: 148.691
		},
		{
			open: 148.69,
			close: 148.71,
			volume: 125541,
			time: 1654103820000,
			lowest: 148.65,
			highest: 148.775,
			volumePrice: 148.709,
			transactionCount: 1026,
			voluemPrice: 148.709
		},
		{
			open: 148.74,
			close: 148.5598,
			volume: 109852,
			time: 1654103880000,
			lowest: 148.51,
			highest: 148.745,
			volumePrice: 148.6166,
			transactionCount: 1042,
			voluemPrice: 148.6166
		},
		{
			open: 148.56,
			close: 148.67,
			volume: 101601,
			time: 1654103940000,
			lowest: 148.56,
			highest: 148.68,
			volumePrice: 148.6317,
			transactionCount: 982,
			voluemPrice: 148.6317
		},
		{
			open: 148.675,
			close: 148.68,
			volume: 154674,
			time: 1654104000000,
			lowest: 148.59,
			highest: 148.7,
			volumePrice: 148.6536,
			transactionCount: 900,
			voluemPrice: 148.6536
		},
		{
			open: 148.7,
			close: 148.68,
			volume: 92635,
			time: 1654104060000,
			lowest: 148.62,
			highest: 148.77,
			volumePrice: 148.7061,
			transactionCount: 887,
			voluemPrice: 148.7061
		},
		{
			open: 148.6986,
			close: 148.42,
			volume: 169493,
			time: 1654104120000,
			lowest: 148.4,
			highest: 148.7,
			volumePrice: 148.5048,
			transactionCount: 1481,
			voluemPrice: 148.5048
		},
		{
			open: 148.42,
			close: 148.3899,
			volume: 73148,
			time: 1654104180000,
			lowest: 148.36,
			highest: 148.49,
			volumePrice: 148.4325,
			transactionCount: 750,
			voluemPrice: 148.4325
		},
		{
			open: 148.38,
			close: 148.3343,
			volume: 181065,
			time: 1654104240000,
			lowest: 148.21,
			highest: 148.39,
			volumePrice: 148.2796,
			transactionCount: 1180,
			voluemPrice: 148.2796
		},
		{
			open: 148.34,
			close: 148.46,
			volume: 117805,
			time: 1654104300000,
			lowest: 148.32,
			highest: 148.465,
			volumePrice: 148.3991,
			transactionCount: 900,
			voluemPrice: 148.3991
		},
		{
			open: 148.4632,
			close: 148.425,
			volume: 70895,
			time: 1654104360000,
			lowest: 148.33,
			highest: 148.49,
			volumePrice: 148.4029,
			transactionCount: 708,
			voluemPrice: 148.4029
		},
		{
			open: 148.44,
			close: 148.53,
			volume: 77600,
			time: 1654104420000,
			lowest: 148.44,
			highest: 148.56,
			volumePrice: 148.4983,
			transactionCount: 766,
			voluemPrice: 148.4983
		},
		{
			open: 148.52,
			close: 148.51,
			volume: 88439,
			time: 1654104480000,
			lowest: 148.4,
			highest: 148.585,
			volumePrice: 148.4893,
			transactionCount: 922,
			voluemPrice: 148.4893
		},
		{
			open: 148.49,
			close: 148.3322,
			volume: 93517,
			time: 1654104540000,
			lowest: 148.33,
			highest: 148.57,
			volumePrice: 148.43,
			transactionCount: 982,
			voluemPrice: 148.43
		},
		{
			open: 148.35,
			close: 148.21,
			volume: 103483,
			time: 1654104600000,
			lowest: 148.18,
			highest: 148.38,
			volumePrice: 148.2875,
			transactionCount: 983,
			voluemPrice: 148.2875
		},
		{
			open: 148.2,
			close: 148.07,
			volume: 113764,
			time: 1654104660000,
			lowest: 148.01,
			highest: 148.215,
			volumePrice: 148.1219,
			transactionCount: 1232,
			voluemPrice: 148.1219
		},
		{
			open: 148.1,
			close: 148.28,
			volume: 94533,
			time: 1654104720000,
			lowest: 148.09,
			highest: 148.28,
			volumePrice: 148.17,
			transactionCount: 921,
			voluemPrice: 148.17
		},
		{
			open: 148.28,
			close: 148.2293,
			volume: 92242,
			time: 1654104780000,
			lowest: 148.17,
			highest: 148.3499,
			volumePrice: 148.2732,
			transactionCount: 844,
			voluemPrice: 148.2732
		},
		{
			open: 148.2139,
			close: 148.3717,
			volume: 81619,
			time: 1654104840000,
			lowest: 148.2139,
			highest: 148.41,
			volumePrice: 148.345,
			transactionCount: 715,
			voluemPrice: 148.345
		},
		{
			open: 148.3601,
			close: 148.47,
			volume: 86039,
			time: 1654104900000,
			lowest: 148.3,
			highest: 148.54,
			volumePrice: 148.4561,
			transactionCount: 966,
			voluemPrice: 148.4561
		},
		{
			open: 148.465,
			close: 148.4499,
			volume: 93726,
			time: 1654104960000,
			lowest: 148.3745,
			highest: 148.56,
			volumePrice: 148.478,
			transactionCount: 907,
			voluemPrice: 148.478
		},
		{
			open: 148.445,
			close: 148.56,
			volume: 54855,
			time: 1654105020000,
			lowest: 148.3874,
			highest: 148.56,
			volumePrice: 148.4503,
			transactionCount: 570,
			voluemPrice: 148.4503
		},
		{
			open: 148.53,
			close: 148.6593,
			volume: 73775,
			time: 1654105080000,
			lowest: 148.5274,
			highest: 148.71,
			volumePrice: 148.6536,
			transactionCount: 793,
			voluemPrice: 148.6536
		},
		{
			open: 148.66,
			close: 148.6,
			volume: 102965,
			time: 1654105140000,
			lowest: 148.53,
			highest: 148.67,
			volumePrice: 148.6103,
			transactionCount: 1060,
			voluemPrice: 148.6103
		},
		{
			open: 148.6,
			close: 148.6891,
			volume: 70109,
			time: 1654105200000,
			lowest: 148.55,
			highest: 148.705,
			volumePrice: 148.6581,
			transactionCount: 752,
			voluemPrice: 148.6581
		},
		{
			open: 148.682,
			close: 148.53,
			volume: 119398,
			time: 1654105260000,
			lowest: 148.49,
			highest: 148.71,
			volumePrice: 148.588,
			transactionCount: 1157,
			voluemPrice: 148.588
		},
		{
			open: 148.52,
			close: 148.672,
			volume: 80625,
			time: 1654105320000,
			lowest: 148.48,
			highest: 148.73,
			volumePrice: 148.632,
			transactionCount: 842,
			voluemPrice: 148.632
		},
		{
			open: 148.68,
			close: 148.63,
			volume: 75432,
			time: 1654105380000,
			lowest: 148.58,
			highest: 148.75,
			volumePrice: 148.6669,
			transactionCount: 836,
			voluemPrice: 148.6669
		},
		{
			open: 148.62,
			close: 148.71,
			volume: 52236,
			time: 1654105440000,
			lowest: 148.58,
			highest: 148.71,
			volumePrice: 148.642,
			transactionCount: 612,
			voluemPrice: 148.642
		},
		{
			open: 148.72,
			close: 148.62,
			volume: 83824,
			time: 1654105500000,
			lowest: 148.6,
			highest: 148.74,
			volumePrice: 148.6788,
			transactionCount: 905,
			voluemPrice: 148.6788
		},
		{
			open: 148.625,
			close: 148.695,
			volume: 120894,
			time: 1654105560000,
			lowest: 148.54,
			highest: 148.71,
			volumePrice: 148.6188,
			transactionCount: 922,
			voluemPrice: 148.6188
		},
		{
			open: 148.69,
			close: 148.65,
			volume: 69062,
			time: 1654105620000,
			lowest: 148.65,
			highest: 148.8,
			volumePrice: 148.7245,
			transactionCount: 765,
			voluemPrice: 148.7245
		},
		{
			open: 148.6575,
			close: 148.51,
			volume: 133713,
			time: 1654105680000,
			lowest: 148.46,
			highest: 148.6575,
			volumePrice: 148.5237,
			transactionCount: 1065,
			voluemPrice: 148.5237
		},
		{
			open: 148.5181,
			close: 148.49,
			volume: 80457,
			time: 1654105740000,
			lowest: 148.4305,
			highest: 148.56,
			volumePrice: 148.499,
			transactionCount: 817,
			voluemPrice: 148.499
		},
		{
			open: 148.485,
			close: 148.63,
			volume: 73016,
			time: 1654105800000,
			lowest: 148.4801,
			highest: 148.64,
			volumePrice: 148.5746,
			transactionCount: 624,
			voluemPrice: 148.5746
		},
		{
			open: 148.61,
			close: 148.47,
			volume: 86719,
			time: 1654105860000,
			lowest: 148.4,
			highest: 148.64,
			volumePrice: 148.4897,
			transactionCount: 796,
			voluemPrice: 148.4897
		},
		{
			open: 148.4686,
			close: 148.39,
			volume: 81146,
			time: 1654105920000,
			lowest: 148.35,
			highest: 148.485,
			volumePrice: 148.4213,
			transactionCount: 756,
			voluemPrice: 148.4213
		},
		{
			open: 148.3801,
			close: 148.45,
			volume: 77970,
			time: 1654105980000,
			lowest: 148.37,
			highest: 148.485,
			volumePrice: 148.4355,
			transactionCount: 643,
			voluemPrice: 148.4355
		},
		{
			open: 148.44,
			close: 148.2999,
			volume: 106093,
			time: 1654106040000,
			lowest: 148.26,
			highest: 148.455,
			volumePrice: 148.3456,
			transactionCount: 1080,
			voluemPrice: 148.3456
		},
		{
			open: 148.285,
			close: 148.54,
			volume: 210048,
			time: 1654106100000,
			lowest: 148.28,
			highest: 148.56,
			volumePrice: 148.4602,
			transactionCount: 1021,
			voluemPrice: 148.4602
		},
		{
			open: 148.5414,
			close: 148.57,
			volume: 135926,
			time: 1654106160000,
			lowest: 148.5,
			highest: 148.679,
			volumePrice: 148.5968,
			transactionCount: 999,
			voluemPrice: 148.5968
		},
		{
			open: 148.565,
			close: 148.705,
			volume: 108469,
			time: 1654106220000,
			lowest: 148.5051,
			highest: 148.73,
			volumePrice: 148.6161,
			transactionCount: 803,
			voluemPrice: 148.6161
		},
		{
			open: 148.7188,
			close: 148.7,
			volume: 86693,
			time: 1654106280000,
			lowest: 148.68,
			highest: 148.84,
			volumePrice: 148.7723,
			transactionCount: 795,
			voluemPrice: 148.7723
		},
		{
			open: 148.72,
			close: 148.575,
			volume: 71205,
			time: 1654106340000,
			lowest: 148.56,
			highest: 148.775,
			volumePrice: 148.6594,
			transactionCount: 764,
			voluemPrice: 148.6594
		},
		{
			open: 148.57,
			close: 148.74,
			volume: 133459,
			time: 1654106400000,
			lowest: 148.52,
			highest: 148.81,
			volumePrice: 148.6781,
			transactionCount: 986,
			voluemPrice: 148.6781
		},
		{
			open: 148.725,
			close: 148.63,
			volume: 86847,
			time: 1654106460000,
			lowest: 148.59,
			highest: 148.725,
			volumePrice: 148.6494,
			transactionCount: 826,
			voluemPrice: 148.6494
		},
		{
			open: 148.625,
			close: 148.73,
			volume: 112422,
			time: 1654106520000,
			lowest: 148.5115,
			highest: 148.74,
			volumePrice: 148.5896,
			transactionCount: 974,
			voluemPrice: 148.5896
		},
		{
			open: 148.74,
			close: 148.7601,
			volume: 82044,
			time: 1654106580000,
			lowest: 148.59,
			highest: 148.77,
			volumePrice: 148.6723,
			transactionCount: 774,
			voluemPrice: 148.6723
		},
		{
			open: 148.77,
			close: 148.8032,
			volume: 162451,
			time: 1654106640000,
			lowest: 148.63,
			highest: 148.87,
			volumePrice: 148.7878,
			transactionCount: 1172,
			voluemPrice: 148.7878
		},
		{
			open: 148.8,
			close: 148.92,
			volume: 191153,
			time: 1654106700000,
			lowest: 148.72,
			highest: 148.93,
			volumePrice: 148.8463,
			transactionCount: 1615,
			voluemPrice: 148.8463
		},
		{
			open: 148.93,
			close: 148.7301,
			volume: 161495,
			time: 1654106760000,
			lowest: 148.65,
			highest: 148.93,
			volumePrice: 148.7765,
			transactionCount: 1513,
			voluemPrice: 148.7765
		},
		{
			open: 148.745,
			close: 148.688,
			volume: 107406,
			time: 1654106820000,
			lowest: 148.57,
			highest: 148.76,
			volumePrice: 148.6516,
			transactionCount: 1023,
			voluemPrice: 148.6516
		},
		{
			open: 148.685,
			close: 148.65,
			volume: 123297,
			time: 1654106880000,
			lowest: 148.6,
			highest: 148.76,
			volumePrice: 148.6813,
			transactionCount: 1487,
			voluemPrice: 148.6813
		},
		{
			open: 148.665,
			close: 148.83,
			volume: 112448,
			time: 1654106940000,
			lowest: 148.63,
			highest: 148.86,
			volumePrice: 148.7824,
			transactionCount: 978,
			voluemPrice: 148.7824
		},
		{
			open: 148.82,
			close: 148.72,
			volume: 105096,
			time: 1654107000000,
			lowest: 148.72,
			highest: 148.88,
			volumePrice: 148.8029,
			transactionCount: 961,
			voluemPrice: 148.8029
		},
		{
			open: 148.7151,
			close: 148.76,
			volume: 85272,
			time: 1654107060000,
			lowest: 148.61,
			highest: 148.76,
			volumePrice: 148.6643,
			transactionCount: 866,
			voluemPrice: 148.6643
		},
		{
			open: 148.76,
			close: 148.75,
			volume: 80906,
			time: 1654107120000,
			lowest: 148.7,
			highest: 148.825,
			volumePrice: 148.7649,
			transactionCount: 833,
			voluemPrice: 148.7649
		},
		{
			open: 148.74,
			close: 148.8189,
			volume: 74740,
			time: 1654107180000,
			lowest: 148.7,
			highest: 148.8195,
			volumePrice: 148.7641,
			transactionCount: 891,
			voluemPrice: 148.7641
		},
		{
			open: 148.8001,
			close: 148.8,
			volume: 141774,
			time: 1654107240000,
			lowest: 148.79,
			highest: 148.95,
			volumePrice: 148.8648,
			transactionCount: 1045,
			voluemPrice: 148.8648
		},
		{
			open: 148.8,
			close: 148.96,
			volume: 187793,
			time: 1654107300000,
			lowest: 148.7,
			highest: 149.04,
			volumePrice: 148.9145,
			transactionCount: 1714,
			voluemPrice: 148.9145
		},
		{
			open: 148.95,
			close: 148.99,
			volume: 140693,
			time: 1654107360000,
			lowest: 148.89,
			highest: 149.06,
			volumePrice: 148.974,
			transactionCount: 1389,
			voluemPrice: 148.974
		},
		{
			open: 148.99,
			close: 148.885,
			volume: 105162,
			time: 1654107420000,
			lowest: 148.85,
			highest: 148.99,
			volumePrice: 148.911,
			transactionCount: 927,
			voluemPrice: 148.911
		},
		{
			open: 148.885,
			close: 148.83,
			volume: 88302,
			time: 1654107480000,
			lowest: 148.7482,
			highest: 148.885,
			volumePrice: 148.8058,
			transactionCount: 861,
			voluemPrice: 148.8058
		},
		{
			open: 148.8201,
			close: 148.89,
			volume: 117572,
			time: 1654107540000,
			lowest: 148.74,
			highest: 148.89,
			volumePrice: 148.7944,
			transactionCount: 1094,
			voluemPrice: 148.7944
		},
		{
			open: 148.89,
			close: 148.77,
			volume: 90175,
			time: 1654107600000,
			lowest: 148.76,
			highest: 148.905,
			volumePrice: 148.8444,
			transactionCount: 971,
			voluemPrice: 148.8444
		},
		{
			open: 148.775,
			close: 148.95,
			volume: 87969,
			time: 1654107660000,
			lowest: 148.76,
			highest: 148.96,
			volumePrice: 148.8901,
			transactionCount: 919,
			voluemPrice: 148.8901
		},
		{
			open: 148.95,
			close: 149.2041,
			volume: 187288,
			time: 1654107720000,
			lowest: 148.94,
			highest: 149.2041,
			volumePrice: 149.0739,
			transactionCount: 1345,
			voluemPrice: 149.0739
		},
		{
			open: 149.2,
			close: 149.39,
			volume: 320786,
			time: 1654107780000,
			lowest: 149.18,
			highest: 149.4866,
			volumePrice: 149.3869,
			transactionCount: 2864,
			voluemPrice: 149.3869
		},
		{
			open: 149.39,
			close: 149.42,
			volume: 134977,
			time: 1654107840000,
			lowest: 149.28,
			highest: 149.42,
			volumePrice: 149.3467,
			transactionCount: 1034,
			voluemPrice: 149.3467
		},
		{
			open: 149.41,
			close: 149.635,
			volume: 257520,
			time: 1654107900000,
			lowest: 149.35,
			highest: 149.64,
			volumePrice: 149.5257,
			transactionCount: 1826,
			voluemPrice: 149.5257
		},
		{
			open: 149.64,
			close: 149.6899,
			volume: 181805,
			time: 1654107960000,
			lowest: 149.64,
			highest: 149.7949,
			volumePrice: 149.7373,
			transactionCount: 1540,
			voluemPrice: 149.7373
		},
		{
			open: 149.69,
			close: 149.73,
			volume: 86812,
			time: 1654108020000,
			lowest: 149.67,
			highest: 149.78,
			volumePrice: 149.7332,
			transactionCount: 821,
			voluemPrice: 149.7332
		},
		{
			open: 149.745,
			close: 149.61,
			volume: 170721,
			time: 1654108080000,
			lowest: 149.5806,
			highest: 149.79,
			volumePrice: 149.684,
			transactionCount: 1263,
			voluemPrice: 149.684
		},
		{
			open: 149.625,
			close: 149.59,
			volume: 184405,
			time: 1654108140000,
			lowest: 149.54,
			highest: 149.71,
			volumePrice: 149.6222,
			transactionCount: 1466,
			voluemPrice: 149.6222
		},
		{
			open: 149.6,
			close: 149.66,
			volume: 109536,
			time: 1654108200000,
			lowest: 149.6,
			highest: 149.7292,
			volumePrice: 149.6684,
			transactionCount: 941,
			voluemPrice: 149.6684
		},
		{
			open: 149.665,
			close: 149.7,
			volume: 125387,
			time: 1654108260000,
			lowest: 149.62,
			highest: 149.74,
			volumePrice: 149.6724,
			transactionCount: 994,
			voluemPrice: 149.6724
		},
		{
			open: 149.7045,
			close: 149.655,
			volume: 121100,
			time: 1654108320000,
			lowest: 149.6093,
			highest: 149.72,
			volumePrice: 149.652,
			transactionCount: 1104,
			voluemPrice: 149.652
		},
		{
			open: 149.66,
			close: 149.67,
			volume: 84590,
			time: 1654108380000,
			lowest: 149.65,
			highest: 149.75,
			volumePrice: 149.7001,
			transactionCount: 693,
			voluemPrice: 149.7001
		},
		{
			open: 149.65,
			close: 149.695,
			volume: 85743,
			time: 1654108440000,
			lowest: 149.6,
			highest: 149.71,
			volumePrice: 149.6679,
			transactionCount: 760,
			voluemPrice: 149.6679
		},
		{
			open: 149.69,
			close: 149.6301,
			volume: 104671,
			time: 1654108500000,
			lowest: 149.62,
			highest: 149.7552,
			volumePrice: 149.683,
			transactionCount: 937,
			voluemPrice: 149.683
		},
		{
			open: 149.64,
			close: 149.68,
			volume: 116263,
			time: 1654108560000,
			lowest: 149.57,
			highest: 149.69,
			volumePrice: 149.6255,
			transactionCount: 989,
			voluemPrice: 149.6255
		},
		{
			open: 149.67,
			close: 149.75,
			volume: 155205,
			time: 1654108620000,
			lowest: 149.67,
			highest: 149.86,
			volumePrice: 149.771,
			transactionCount: 1403,
			voluemPrice: 149.771
		},
		{
			open: 149.74,
			close: 149.71,
			volume: 104297,
			time: 1654108680000,
			lowest: 149.7,
			highest: 149.8,
			volumePrice: 149.7448,
			transactionCount: 853,
			voluemPrice: 149.7448
		},
		{
			open: 149.7036,
			close: 149.47,
			volume: 164085,
			time: 1654108740000,
			lowest: 149.47,
			highest: 149.7036,
			volumePrice: 149.5859,
			transactionCount: 1316,
			voluemPrice: 149.5859
		},
		{
			open: 149.47,
			close: 149.6,
			volume: 129638,
			time: 1654108800000,
			lowest: 149.4588,
			highest: 149.6186,
			volumePrice: 149.5215,
			transactionCount: 1253,
			voluemPrice: 149.5215
		},
		{
			open: 149.595,
			close: 149.55,
			volume: 76763,
			time: 1654108860000,
			lowest: 149.5209,
			highest: 149.64,
			volumePrice: 149.5682,
			transactionCount: 737,
			voluemPrice: 149.5682
		},
		{
			open: 149.55,
			close: 149.75,
			volume: 116142,
			time: 1654108920000,
			lowest: 149.55,
			highest: 149.76,
			volumePrice: 149.6552,
			transactionCount: 1001,
			voluemPrice: 149.6552
		},
		{
			open: 149.7539,
			close: 149.7275,
			volume: 112596,
			time: 1654108980000,
			lowest: 149.6901,
			highest: 149.78,
			volumePrice: 149.7423,
			transactionCount: 780,
			voluemPrice: 149.7423
		},
		{
			open: 149.73,
			close: 149.735,
			volume: 76196,
			time: 1654109040000,
			lowest: 149.67,
			highest: 149.79,
			volumePrice: 149.7266,
			transactionCount: 754,
			voluemPrice: 149.7266
		},
		{
			open: 149.75,
			close: 149.6,
			volume: 127433,
			time: 1654109100000,
			lowest: 149.57,
			highest: 149.79,
			volumePrice: 149.6654,
			transactionCount: 1090,
			voluemPrice: 149.6654
		},
		{
			open: 149.6,
			close: 149.56,
			volume: 108886,
			time: 1654109160000,
			lowest: 149.48,
			highest: 149.62,
			volumePrice: 149.5396,
			transactionCount: 1058,
			voluemPrice: 149.5396
		},
		{
			open: 149.58,
			close: 149.5279,
			volume: 97128,
			time: 1654109220000,
			lowest: 149.52,
			highest: 149.675,
			volumePrice: 149.6019,
			transactionCount: 902,
			voluemPrice: 149.6019
		},
		{
			open: 149.53,
			close: 149.4911,
			volume: 99315,
			time: 1654109280000,
			lowest: 149.44,
			highest: 149.57,
			volumePrice: 149.487,
			transactionCount: 1004,
			voluemPrice: 149.487
		},
		{
			open: 149.5,
			close: 149.515,
			volume: 87313,
			time: 1654109340000,
			lowest: 149.4874,
			highest: 149.65,
			volumePrice: 149.5683,
			transactionCount: 904,
			voluemPrice: 149.5683
		},
		{
			open: 149.51,
			close: 149.3486,
			volume: 182391,
			time: 1654109400000,
			lowest: 149.29,
			highest: 149.53,
			volumePrice: 149.3837,
			transactionCount: 1503,
			voluemPrice: 149.3837
		},
		{
			open: 149.34,
			close: 149.2999,
			volume: 151913,
			time: 1654109460000,
			lowest: 149.19,
			highest: 149.3455,
			volumePrice: 149.2691,
			transactionCount: 1268,
			voluemPrice: 149.2691
		},
		{
			open: 149.3,
			close: 149.4,
			volume: 104260,
			time: 1654109520000,
			lowest: 149.23,
			highest: 149.4082,
			volumePrice: 149.3157,
			transactionCount: 1053,
			voluemPrice: 149.3157
		},
		{
			open: 149.4,
			close: 149.37,
			volume: 112466,
			time: 1654109580000,
			lowest: 149.28,
			highest: 149.49,
			volumePrice: 149.3802,
			transactionCount: 1026,
			voluemPrice: 149.3802
		},
		{
			open: 149.3583,
			close: 149.23,
			volume: 67045,
			time: 1654109640000,
			lowest: 149.18,
			highest: 149.3583,
			volumePrice: 149.2619,
			transactionCount: 738,
			voluemPrice: 149.2619
		},
		{
			open: 149.235,
			close: 149.34,
			volume: 96451,
			time: 1654109700000,
			lowest: 149.22,
			highest: 149.39,
			volumePrice: 149.3076,
			transactionCount: 965,
			voluemPrice: 149.3076
		},
		{
			open: 149.3501,
			close: 149.2601,
			volume: 84390,
			time: 1654109760000,
			lowest: 149.25,
			highest: 149.355,
			volumePrice: 149.2835,
			transactionCount: 959,
			voluemPrice: 149.2835
		},
		{
			open: 149.26,
			close: 149.08,
			volume: 164821,
			time: 1654109820000,
			lowest: 149.032,
			highest: 149.26,
			volumePrice: 149.1246,
			transactionCount: 1622,
			voluemPrice: 149.1246
		},
		{
			open: 149.08,
			close: 149.18,
			volume: 110437,
			time: 1654109880000,
			lowest: 149.07,
			highest: 149.22,
			volumePrice: 149.148,
			transactionCount: 958,
			voluemPrice: 149.148
		},
		{
			open: 149.19,
			close: 149.2175,
			volume: 79876,
			time: 1654109940000,
			lowest: 149.145,
			highest: 149.27,
			volumePrice: 149.2007,
			transactionCount: 726,
			voluemPrice: 149.2007
		},
		{
			open: 149.215,
			close: 149.1398,
			volume: 110619,
			time: 1654110000000,
			lowest: 149.07,
			highest: 149.26,
			volumePrice: 149.1629,
			transactionCount: 1019,
			voluemPrice: 149.1629
		},
		{
			open: 149.15,
			close: 149.28,
			volume: 105581,
			time: 1654110060000,
			lowest: 149.15,
			highest: 149.3193,
			volumePrice: 149.245,
			transactionCount: 991,
			voluemPrice: 149.245
		},
		{
			open: 149.29,
			close: 149.13,
			volume: 81689,
			time: 1654110120000,
			lowest: 149.12,
			highest: 149.32,
			volumePrice: 149.2391,
			transactionCount: 862,
			voluemPrice: 149.2391
		},
		{
			open: 149.1314,
			close: 148.86,
			volume: 195333,
			time: 1654110180000,
			lowest: 148.8141,
			highest: 149.16,
			volumePrice: 148.9489,
			transactionCount: 1736,
			voluemPrice: 148.9489
		},
		{
			open: 148.87,
			close: 149.005,
			volume: 158026,
			time: 1654110240000,
			lowest: 148.87,
			highest: 149.05,
			volumePrice: 148.9917,
			transactionCount: 1130,
			voluemPrice: 148.9917
		},
		{
			open: 149,
			close: 149.12,
			volume: 101909,
			time: 1654110300000,
			lowest: 148.975,
			highest: 149.14,
			volumePrice: 149.0827,
			transactionCount: 838,
			voluemPrice: 149.0827
		},
		{
			open: 149.12,
			close: 149.2184,
			volume: 393655,
			time: 1654110360000,
			lowest: 149.095,
			highest: 149.25,
			volumePrice: 149.1046,
			transactionCount: 891,
			voluemPrice: 149.1046
		},
		{
			open: 149.22,
			close: 149.17,
			volume: 86646,
			time: 1654110420000,
			lowest: 149.12,
			highest: 149.24,
			volumePrice: 149.1716,
			transactionCount: 874,
			voluemPrice: 149.1716
		},
		{
			open: 149.18,
			close: 149.1946,
			volume: 111414,
			time: 1654110480000,
			lowest: 149.1605,
			highest: 149.2975,
			volumePrice: 149.2402,
			transactionCount: 993,
			voluemPrice: 149.2402
		},
		{
			open: 149.205,
			close: 149.16,
			volume: 477581,
			time: 1654110540000,
			lowest: 149.15,
			highest: 149.29,
			volumePrice: 149.2259,
			transactionCount: 1196,
			voluemPrice: 149.2259
		},
		{
			open: 149.145,
			close: 149.27,
			volume: 116325,
			time: 1654110600000,
			lowest: 149.08,
			highest: 149.2755,
			volumePrice: 149.1773,
			transactionCount: 1010,
			voluemPrice: 149.1773
		},
		{
			open: 149.27,
			close: 149.26,
			volume: 87916,
			time: 1654110660000,
			lowest: 149.2116,
			highest: 149.32,
			volumePrice: 149.2726,
			transactionCount: 896,
			voluemPrice: 149.2726
		},
		{
			open: 149.28,
			close: 149.38,
			volume: 94264,
			time: 1654110720000,
			lowest: 149.22,
			highest: 149.39,
			volumePrice: 149.3026,
			transactionCount: 991,
			voluemPrice: 149.3026
		},
		{
			open: 149.375,
			close: 149.54,
			volume: 202444,
			time: 1654110780000,
			lowest: 149.32,
			highest: 149.5738,
			volumePrice: 149.4285,
			transactionCount: 1549,
			voluemPrice: 149.4285
		},
		{
			open: 149.533,
			close: 149.56,
			volume: 163791,
			time: 1654110840000,
			lowest: 149.51,
			highest: 149.66,
			volumePrice: 149.5854,
			transactionCount: 1277,
			voluemPrice: 149.5854
		},
		{
			open: 149.56,
			close: 149.6,
			volume: 136384,
			time: 1654110900000,
			lowest: 149.425,
			highest: 149.6,
			volumePrice: 149.5045,
			transactionCount: 1243,
			voluemPrice: 149.5045
		},
		{
			open: 149.6041,
			close: 149.695,
			volume: 191171,
			time: 1654110960000,
			lowest: 149.6029,
			highest: 149.78,
			volumePrice: 149.7072,
			transactionCount: 1433,
			voluemPrice: 149.7072
		},
		{
			open: 149.7,
			close: 149.7201,
			volume: 157746,
			time: 1654111020000,
			lowest: 149.7,
			highest: 149.85,
			volumePrice: 149.7625,
			transactionCount: 1241,
			voluemPrice: 149.7625
		},
		{
			open: 149.72,
			close: 149.53,
			volume: 200823,
			time: 1654111080000,
			lowest: 149.445,
			highest: 149.72,
			volumePrice: 149.5656,
			transactionCount: 1686,
			voluemPrice: 149.5656
		},
		{
			open: 149.52,
			close: 149.66,
			volume: 147308,
			time: 1654111140000,
			lowest: 149.5,
			highest: 149.6826,
			volumePrice: 149.595,
			transactionCount: 1071,
			voluemPrice: 149.595
		},
		{
			open: 149.66,
			close: 149.75,
			volume: 138427,
			time: 1654111200000,
			lowest: 149.63,
			highest: 149.765,
			volumePrice: 149.6913,
			transactionCount: 965,
			voluemPrice: 149.6913
		},
		{
			open: 149.75,
			close: 149.7488,
			volume: 150760,
			time: 1654111260000,
			lowest: 149.66,
			highest: 149.8084,
			volumePrice: 149.7355,
			transactionCount: 1167,
			voluemPrice: 149.7355
		},
		{
			open: 149.74,
			close: 149.8,
			volume: 132303,
			time: 1654111320000,
			lowest: 149.68,
			highest: 149.81,
			volumePrice: 149.7566,
			transactionCount: 1037,
			voluemPrice: 149.7566
		},
		{
			open: 149.803,
			close: 149.74,
			volume: 161316,
			time: 1654111380000,
			lowest: 149.73,
			highest: 149.88,
			volumePrice: 149.805,
			transactionCount: 1377,
			voluemPrice: 149.805
		},
		{
			open: 149.75,
			close: 149.795,
			volume: 156606,
			time: 1654111440000,
			lowest: 149.67,
			highest: 149.81,
			volumePrice: 149.7334,
			transactionCount: 1319,
			voluemPrice: 149.7334
		},
		{
			open: 149.7918,
			close: 149.64,
			volume: 208433,
			time: 1654111500000,
			lowest: 149.4911,
			highest: 149.81,
			volumePrice: 149.6285,
			transactionCount: 1885,
			voluemPrice: 149.6285
		},
		{
			open: 149.65,
			close: 149.75,
			volume: 121107,
			time: 1654111560000,
			lowest: 149.64,
			highest: 149.765,
			volumePrice: 149.7152,
			transactionCount: 1228,
			voluemPrice: 149.7152
		},
		{
			open: 149.7499,
			close: 149.71,
			volume: 103045,
			time: 1654111620000,
			lowest: 149.66,
			highest: 149.77,
			volumePrice: 149.7063,
			transactionCount: 967,
			voluemPrice: 149.7063
		},
		{
			open: 149.71,
			close: 149.71,
			volume: 105891,
			time: 1654111680000,
			lowest: 149.59,
			highest: 149.71,
			volumePrice: 149.654,
			transactionCount: 1147,
			voluemPrice: 149.654
		},
		{
			open: 149.71,
			close: 149.668,
			volume: 167784,
			time: 1654111740000,
			lowest: 149.62,
			highest: 149.83,
			volumePrice: 149.7463,
			transactionCount: 1298,
			voluemPrice: 149.7463
		},
		{
			open: 149.67,
			close: 149.52,
			volume: 324569,
			time: 1654111800000,
			lowest: 149.385,
			highest: 149.6799,
			volumePrice: 149.5107,
			transactionCount: 3441,
			voluemPrice: 149.5107
		},
		{
			open: 149.51,
			close: 149.5,
			volume: 252959,
			time: 1654111860000,
			lowest: 149.47,
			highest: 149.64,
			volumePrice: 149.5336,
			transactionCount: 2567,
			voluemPrice: 149.5336
		},
		{
			open: 149.5,
			close: 149.5399,
			volume: 158196,
			time: 1654111920000,
			lowest: 149.45,
			highest: 149.6,
			volumePrice: 149.5217,
			transactionCount: 1655,
			voluemPrice: 149.5217
		},
		{
			open: 149.54,
			close: 149.6601,
			volume: 173966,
			time: 1654111980000,
			lowest: 149.51,
			highest: 149.73,
			volumePrice: 149.6415,
			transactionCount: 1571,
			voluemPrice: 149.6415
		},
		{
			open: 149.67,
			close: 149.5661,
			volume: 147377,
			time: 1654112040000,
			lowest: 149.53,
			highest: 149.7,
			volumePrice: 149.6185,
			transactionCount: 1432,
			voluemPrice: 149.6185
		},
		{
			open: 149.56,
			close: 149.54,
			volume: 175423,
			time: 1654112100000,
			lowest: 149.42,
			highest: 149.565,
			volumePrice: 149.4935,
			transactionCount: 1669,
			voluemPrice: 149.4935
		},
		{
			open: 149.54,
			close: 149.7401,
			volume: 216985,
			time: 1654112160000,
			lowest: 149.41,
			highest: 149.76,
			volumePrice: 149.553,
			transactionCount: 1994,
			voluemPrice: 149.553
		},
		{
			open: 149.75,
			close: 149.83,
			volume: 185664,
			time: 1654112220000,
			lowest: 149.67,
			highest: 149.85,
			volumePrice: 149.7584,
			transactionCount: 1788,
			voluemPrice: 149.7584
		},
		{
			open: 149.8101,
			close: 149.87,
			volume: 193776,
			time: 1654112280000,
			lowest: 149.7199,
			highest: 149.88,
			volumePrice: 149.7912,
			transactionCount: 1689,
			voluemPrice: 149.7912
		},
		{
			open: 149.8762,
			close: 149.8499,
			volume: 176828,
			time: 1654112340000,
			lowest: 149.7,
			highest: 149.88,
			volumePrice: 149.8079,
			transactionCount: 1699,
			voluemPrice: 149.8079
		},
		{
			open: 149.84,
			close: 149.885,
			volume: 244053,
			time: 1654112400000,
			lowest: 149.78,
			highest: 149.91,
			volumePrice: 149.8612,
			transactionCount: 2128,
			voluemPrice: 149.8612
		},
		{
			open: 149.9,
			close: 149.898,
			volume: 264373,
			time: 1654112460000,
			lowest: 149.83,
			highest: 149.99,
			volumePrice: 149.9197,
			transactionCount: 2290,
			voluemPrice: 149.9197
		},
		{
			open: 149.89,
			close: 149.97,
			volume: 146002,
			time: 1654112520000,
			lowest: 149.85,
			highest: 149.97,
			volumePrice: 149.9044,
			transactionCount: 1418,
			voluemPrice: 149.9044
		},
		{
			open: 149.97,
			close: 149.72,
			volume: 216054,
			time: 1654112580000,
			lowest: 149.665,
			highest: 149.99,
			volumePrice: 149.8351,
			transactionCount: 1799,
			voluemPrice: 149.8351
		},
		{
			open: 149.7185,
			close: 149.685,
			volume: 145266,
			time: 1654112640000,
			lowest: 149.67,
			highest: 149.8295,
			volumePrice: 149.7544,
			transactionCount: 1325,
			voluemPrice: 149.7544
		},
		{
			open: 149.67,
			close: 149.67,
			volume: 211708,
			time: 1654112700000,
			lowest: 149.51,
			highest: 149.67,
			volumePrice: 149.5943,
			transactionCount: 2098,
			voluemPrice: 149.5943
		},
		{
			open: 149.6615,
			close: 149.74,
			volume: 119777,
			time: 1654112760000,
			lowest: 149.57,
			highest: 149.77,
			volumePrice: 149.6884,
			transactionCount: 1170,
			voluemPrice: 149.6884
		},
		{
			open: 149.74,
			close: 149.85,
			volume: 164929,
			time: 1654112820000,
			lowest: 149.74,
			highest: 149.89,
			volumePrice: 149.8294,
			transactionCount: 1733,
			voluemPrice: 149.8294
		},
		{
			open: 149.86,
			close: 149.71,
			volume: 169449,
			time: 1654112880000,
			lowest: 149.7003,
			highest: 149.93,
			volumePrice: 149.8173,
			transactionCount: 1780,
			voluemPrice: 149.8173
		},
		{
			open: 149.705,
			close: 149.8,
			volume: 165335,
			time: 1654112940000,
			lowest: 149.64,
			highest: 149.9,
			volumePrice: 149.7495,
			transactionCount: 1761,
			voluemPrice: 149.7495
		},
		{
			open: 149.8,
			close: 149.57,
			volume: 402998,
			time: 1654113000000,
			lowest: 149.36,
			highest: 149.91,
			volumePrice: 149.6092,
			transactionCount: 4597,
			voluemPrice: 149.6092
		},
		{
			open: 149.565,
			close: 149.5012,
			volume: 199862,
			time: 1654113060000,
			lowest: 149.41,
			highest: 149.64,
			volumePrice: 149.5127,
			transactionCount: 2146,
			voluemPrice: 149.5127
		},
		{
			open: 149.5,
			close: 149.4653,
			volume: 188006,
			time: 1654113120000,
			lowest: 149.46,
			highest: 149.66,
			volumePrice: 149.5688,
			transactionCount: 1841,
			voluemPrice: 149.5688
		},
		{
			open: 149.465,
			close: 149.22,
			volume: 184819,
			time: 1654113180000,
			lowest: 149.2,
			highest: 149.48,
			volumePrice: 149.3469,
			transactionCount: 1932,
			voluemPrice: 149.3469
		},
		{
			open: 149.22,
			close: 148.99,
			volume: 322525,
			time: 1654113240000,
			lowest: 148.95,
			highest: 149.22,
			volumePrice: 149.078,
			transactionCount: 3226,
			voluemPrice: 149.078
		},
		{
			open: 148.99,
			close: 148.85,
			volume: 387904,
			time: 1654113300000,
			lowest: 148.82,
			highest: 149.0739,
			volumePrice: 148.9412,
			transactionCount: 3800,
			voluemPrice: 148.9412
		},
		{
			open: 148.845,
			close: 148.8223,
			volume: 300288,
			time: 1654113360000,
			lowest: 148.7413,
			highest: 148.9,
			volumePrice: 148.817,
			transactionCount: 3013,
			voluemPrice: 148.817
		},
		{
			open: 148.83,
			close: 148.825,
			volume: 320789,
			time: 1654113420000,
			lowest: 148.775,
			highest: 148.9248,
			volumePrice: 148.8459,
			transactionCount: 3091,
			voluemPrice: 148.8459
		},
		{
			open: 148.825,
			close: 148.83,
			volume: 296652,
			time: 1654113480000,
			lowest: 148.76,
			highest: 148.89,
			volumePrice: 148.8392,
			transactionCount: 2440,
			voluemPrice: 148.8392
		},
		{
			open: 148.83,
			close: 148.7,
			volume: 1189941,
			time: 1654113540000,
			lowest: 148.52,
			highest: 148.85,
			volumePrice: 148.6729,
			transactionCount: 6355,
			voluemPrice: 148.6729
		},
		{
			open: 148.71,
			close: 148.71,
			volume: 360038,
			time: 1654113600000,
			lowest: 148.5,
			highest: 148.79,
			volumePrice: 148.6996,
			transactionCount: 288,
			voluemPrice: 148.6996
		},
		{
			open: 148.6,
			close: 148.71,
			volume: 607326,
			time: 1654113660000,
			lowest: 148.5,
			highest: 148.71,
			volumePrice: 148.7081,
			transactionCount: 122,
			voluemPrice: 148.7081
		},
		{
			open: 148.71,
			close: 148.68,
			volume: 541959,
			time: 1654113720000,
			lowest: 148.57,
			highest: 148.71,
			volumePrice: 148.7074,
			transactionCount: 147,
			voluemPrice: 148.7074
		},
		{
			open: 148.64,
			close: 148.58,
			volume: 10001,
			time: 1654113780000,
			lowest: 148.57,
			highest: 148.65,
			volumePrice: 148.6386,
			transactionCount: 63,
			voluemPrice: 148.6386
		},
		{
			open: 148.56,
			close: 148.64,
			volume: 8913,
			time: 1654113840000,
			lowest: 148.56,
			highest: 148.71,
			volumePrice: 148.6473,
			transactionCount: 118,
			voluemPrice: 148.6473
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 2758,
			time: 1654113900000,
			lowest: 148.59,
			highest: 148.6,
			volumePrice: 148.597,
			transactionCount: 39,
			voluemPrice: 148.597
		},
		{
			open: 148.55,
			close: 148.5001,
			volume: 11559,
			time: 1654113960000,
			lowest: 148.5,
			highest: 148.58,
			volumePrice: 148.6735,
			transactionCount: 49,
			voluemPrice: 148.6735
		},
		{
			open: 148.51,
			close: 148.4601,
			volume: 7633,
			time: 1654114020000,
			lowest: 148.46,
			highest: 148.51,
			volumePrice: 148.4856,
			transactionCount: 101,
			voluemPrice: 148.4856
		},
		{
			open: 148.4601,
			close: 148.54,
			volume: 18896,
			time: 1654114080000,
			lowest: 148.46,
			highest: 148.71,
			volumePrice: 148.6289,
			transactionCount: 92,
			voluemPrice: 148.6289
		},
		{
			open: 148.54,
			close: 148.54,
			volume: 6355,
			time: 1654114140000,
			lowest: 148.51,
			highest: 148.65,
			volumePrice: 148.5499,
			transactionCount: 115,
			voluemPrice: 148.5499
		},
		{
			open: 148.55,
			close: 148.53,
			volume: 1654,
			time: 1654114200000,
			lowest: 148.53,
			highest: 148.55,
			volumePrice: 148.5383,
			transactionCount: 38,
			voluemPrice: 148.5383
		},
		{
			open: 148.5,
			close: 148.4999,
			volume: 3691,
			time: 1654114260000,
			lowest: 148.49,
			highest: 148.5,
			volumePrice: 148.5007,
			transactionCount: 66,
			voluemPrice: 148.5007
		},
		{
			open: 148.5,
			close: 148.55,
			volume: 6647,
			time: 1654114320000,
			lowest: 148.49,
			highest: 148.71,
			volumePrice: 148.5236,
			transactionCount: 73,
			voluemPrice: 148.5236
		},
		{
			open: 148.53,
			close: 148.54,
			volume: 182706,
			time: 1654114380000,
			lowest: 148.51,
			highest: 148.71,
			volumePrice: 148.702,
			transactionCount: 95,
			voluemPrice: 148.702
		},
		{
			open: 148.56,
			close: 148.57,
			volume: 1593,
			time: 1654114440000,
			lowest: 148.55,
			highest: 148.57,
			volumePrice: 148.5704,
			transactionCount: 57,
			voluemPrice: 148.5704
		},
		{
			open: 148.6,
			close: 148.57,
			volume: 1644,
			time: 1654114500000,
			lowest: 148.57,
			highest: 148.62,
			volumePrice: 148.5948,
			transactionCount: 45,
			voluemPrice: 148.5948
		},
		{
			open: 148.58,
			close: 148.58,
			volume: 938,
			time: 1654114560000,
			lowest: 148.58,
			highest: 148.58,
			volumePrice: 148.5776,
			transactionCount: 33,
			voluemPrice: 148.5776
		},
		{
			open: 148.55,
			close: 148.71,
			volume: 5843,
			time: 1654114620000,
			lowest: 148.54,
			highest: 148.71,
			volumePrice: 148.6273,
			transactionCount: 24,
			voluemPrice: 148.6273
		},
		{
			open: 148.54,
			close: 148.54,
			volume: 691,
			time: 1654114680000,
			lowest: 148.54,
			highest: 148.54,
			volumePrice: 148.55,
			transactionCount: 22,
			voluemPrice: 148.55
		},
		{
			open: 148.54,
			close: 148.4896,
			volume: 2754,
			time: 1654114740000,
			lowest: 148.4896,
			highest: 148.54,
			volumePrice: 148.5329,
			transactionCount: 43,
			voluemPrice: 148.5329
		},
		{
			open: 148.5,
			close: 148.46,
			volume: 1799,
			time: 1654114800000,
			lowest: 148.46,
			highest: 148.5,
			volumePrice: 148.481,
			transactionCount: 64,
			voluemPrice: 148.481
		},
		{
			open: 148.46,
			close: 148.39,
			volume: 3925,
			time: 1654114860000,
			lowest: 148.39,
			highest: 148.46,
			volumePrice: 148.4132,
			transactionCount: 87,
			voluemPrice: 148.4132
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 3136,
			time: 1654114920000,
			lowest: 148.41,
			highest: 148.49,
			volumePrice: 148.4977,
			transactionCount: 327,
			voluemPrice: 148.4977
		},
		{
			open: 148.49,
			close: 148.71,
			volume: 5075,
			time: 1654114980000,
			lowest: 148.49,
			highest: 148.71,
			volumePrice: 148.5903,
			transactionCount: 45,
			voluemPrice: 148.5903
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 140,
			time: 1654115040000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4584,
			transactionCount: 16,
			voluemPrice: 148.4584
		},
		{
			open: 148.5,
			close: 148.5939,
			volume: 3261,
			time: 1654115100000,
			lowest: 148.5,
			highest: 148.5939,
			volumePrice: 148.5363,
			transactionCount: 41,
			voluemPrice: 148.5363
		},
		{
			open: 148.59,
			close: 148.5891,
			volume: 1008,
			time: 1654115160000,
			lowest: 148.5891,
			highest: 148.59,
			volumePrice: 148.5785,
			transactionCount: 11,
			voluemPrice: 148.5785
		},
		{
			open: 148.52,
			close: 148.46,
			volume: 1157,
			time: 1654115340000,
			lowest: 148.46,
			highest: 148.52,
			volumePrice: 148.5058,
			transactionCount: 57,
			voluemPrice: 148.5058
		},
		{
			open: 148.4792,
			close: 148.48,
			volume: 1546,
			time: 1654115400000,
			lowest: 148.4701,
			highest: 148.52,
			volumePrice: 148.495,
			transactionCount: 32,
			voluemPrice: 148.495
		},
		{
			open: 148.54,
			close: 148.55,
			volume: 3555,
			time: 1654115460000,
			lowest: 148.54,
			highest: 148.58,
			volumePrice: 148.5613,
			transactionCount: 44,
			voluemPrice: 148.5613
		},
		{
			open: 148.58,
			close: 148.58,
			volume: 691,
			time: 1654115520000,
			lowest: 148.58,
			highest: 148.58,
			volumePrice: 148.5672,
			transactionCount: 28,
			voluemPrice: 148.5672
		},
		{
			open: 148.5899,
			close: 148.5819,
			volume: 1386,
			time: 1654115580000,
			lowest: 148.55,
			highest: 148.59,
			volumePrice: 148.5719,
			transactionCount: 24,
			voluemPrice: 148.5719
		},
		{
			open: 148.59,
			close: 148.59,
			volume: 617,
			time: 1654115640000,
			lowest: 148.56,
			highest: 148.59,
			volumePrice: 148.5791,
			transactionCount: 23,
			voluemPrice: 148.5791
		},
		{
			open: 148.55,
			close: 148.5,
			volume: 2164,
			time: 1654115700000,
			lowest: 148.5,
			highest: 148.55,
			volumePrice: 148.7521,
			transactionCount: 39,
			voluemPrice: 148.7521
		},
		{
			open: 148.51,
			close: 148.51,
			volume: 639,
			time: 1654115820000,
			lowest: 148.51,
			highest: 148.51,
			volumePrice: 148.5258,
			transactionCount: 24,
			voluemPrice: 148.5258
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 313,
			time: 1654115880000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5467,
			transactionCount: 29,
			voluemPrice: 148.5467
		},
		{
			open: 148.52,
			close: 148.5201,
			volume: 266,
			time: 1654116000000,
			lowest: 148.52,
			highest: 148.5201,
			volumePrice: 148.5239,
			transactionCount: 12,
			voluemPrice: 148.5239
		},
		{
			open: 148.56,
			close: 148.42,
			volume: 2819,
			time: 1654116180000,
			lowest: 148.42,
			highest: 148.57,
			volumePrice: 148.5068,
			transactionCount: 67,
			voluemPrice: 148.5068
		},
		{
			open: 148.49,
			close: 148.4499,
			volume: 3088,
			time: 1654116240000,
			lowest: 148.43,
			highest: 148.49,
			volumePrice: 148.4574,
			transactionCount: 25,
			voluemPrice: 148.4574
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 371,
			time: 1654116300000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4277,
			transactionCount: 31,
			voluemPrice: 148.4277
		},
		{
			open: 148.48,
			close: 148.48,
			volume: 619,
			time: 1654116420000,
			lowest: 148.48,
			highest: 148.48,
			volumePrice: 148.4549,
			transactionCount: 23,
			voluemPrice: 148.4549
		},
		{
			open: 148.49,
			close: 148.41,
			volume: 7088,
			time: 1654116480000,
			lowest: 148.4,
			highest: 148.5,
			volumePrice: 148.4738,
			transactionCount: 51,
			voluemPrice: 148.4738
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 829,
			time: 1654116540000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4506,
			transactionCount: 15,
			voluemPrice: 148.4506
		},
		{
			open: 148.51,
			close: 148.5,
			volume: 895,
			time: 1654116600000,
			lowest: 148.5,
			highest: 148.51,
			volumePrice: 148.4919,
			transactionCount: 33,
			voluemPrice: 148.4919
		},
		{
			open: 148.49,
			close: 148.5,
			volume: 456,
			time: 1654116660000,
			lowest: 148.49,
			highest: 148.5,
			volumePrice: 148.4938,
			transactionCount: 18,
			voluemPrice: 148.4938
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 5646,
			time: 1654116780000,
			lowest: 148.41,
			highest: 148.45,
			volumePrice: 148.4206,
			transactionCount: 64,
			voluemPrice: 148.4206
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 462,
			time: 1654116840000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4516,
			transactionCount: 27,
			voluemPrice: 148.4516
		},
		{
			open: 148.42,
			close: 148.4,
			volume: 1595,
			time: 1654116900000,
			lowest: 148.4,
			highest: 148.42,
			volumePrice: 148.4102,
			transactionCount: 24,
			voluemPrice: 148.4102
		},
		{
			open: 148.4,
			close: 148.41,
			volume: 2149,
			time: 1654116960000,
			lowest: 148.39,
			highest: 148.41,
			volumePrice: 148.4028,
			transactionCount: 27,
			voluemPrice: 148.4028
		},
		{
			open: 148.45,
			close: 148.48,
			volume: 801,
			time: 1654117020000,
			lowest: 148.45,
			highest: 148.48,
			volumePrice: 148.455,
			transactionCount: 21,
			voluemPrice: 148.455
		},
		{
			open: 148.49,
			close: 148.5001,
			volume: 591,
			time: 1654117080000,
			lowest: 148.49,
			highest: 148.5001,
			volumePrice: 148.493,
			transactionCount: 29,
			voluemPrice: 148.493
		},
		{
			open: 148.49,
			close: 148.4075,
			volume: 1911,
			time: 1654117140000,
			lowest: 148.4075,
			highest: 148.5,
			volumePrice: 148.4891,
			transactionCount: 33,
			voluemPrice: 148.4891
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 905,
			time: 1654117260000,
			lowest: 148.4962,
			highest: 148.5,
			volumePrice: 148.494,
			transactionCount: 21,
			voluemPrice: 148.494
		},
		{
			open: 148.4902,
			close: 148.56,
			volume: 814,
			time: 1654117320000,
			lowest: 148.4902,
			highest: 148.56,
			volumePrice: 148.5242,
			transactionCount: 30,
			voluemPrice: 148.5242
		},
		{
			open: 148.52,
			close: 148.52,
			volume: 189,
			time: 1654117380000,
			lowest: 148.52,
			highest: 148.52,
			volumePrice: 148.5024,
			transactionCount: 18,
			voluemPrice: 148.5024
		},
		{
			open: 148.51,
			close: 148.5599,
			volume: 2883,
			time: 1654117620000,
			lowest: 148.51,
			highest: 148.5599,
			volumePrice: 148.5463,
			transactionCount: 42,
			voluemPrice: 148.5463
		},
		{
			open: 148.55,
			close: 148.57,
			volume: 2885,
			time: 1654117680000,
			lowest: 148.55,
			highest: 148.57,
			volumePrice: 148.5616,
			transactionCount: 56,
			voluemPrice: 148.5616
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 639,
			time: 1654117920000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5963,
			transactionCount: 15,
			voluemPrice: 148.5963
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 479,
			time: 1654117980000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5999,
			transactionCount: 9,
			voluemPrice: 148.5999
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 333,
			time: 1654118040000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.6006,
			transactionCount: 17,
			voluemPrice: 148.6006
		},
		{
			open: 148.64,
			close: 148.6,
			volume: 883,
			time: 1654118100000,
			lowest: 148.6,
			highest: 148.64,
			volumePrice: 148.6234,
			transactionCount: 24,
			voluemPrice: 148.6234
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 484,
			time: 1654118160000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5928,
			transactionCount: 14,
			voluemPrice: 148.5928
		},
		{
			open: 148.6,
			close: 148.64,
			volume: 883,
			time: 1654118220000,
			lowest: 148.6,
			highest: 148.64,
			volumePrice: 148.6214,
			transactionCount: 17,
			voluemPrice: 148.6214
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 1195,
			time: 1654118280000,
			lowest: 148.58,
			highest: 148.6,
			volumePrice: 148.6022,
			transactionCount: 21,
			voluemPrice: 148.6022
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 693,
			time: 1654118340000,
			lowest: 148.59,
			highest: 148.6,
			volumePrice: 148.5988,
			transactionCount: 18,
			voluemPrice: 148.5988
		},
		{
			open: 148.65,
			close: 148.65,
			volume: 208,
			time: 1654118400000,
			lowest: 148.65,
			highest: 148.65,
			volumePrice: 148.6253,
			transactionCount: 12,
			voluemPrice: 148.6253
		},
		{
			open: 148.645,
			close: 148.645,
			volume: 304,
			time: 1654118460000,
			lowest: 148.645,
			highest: 148.645,
			volumePrice: 148.6363,
			transactionCount: 12,
			voluemPrice: 148.6363
		},
		{
			open: 148.6,
			close: 148.58,
			volume: 928,
			time: 1654118640000,
			lowest: 148.58,
			highest: 148.6,
			volumePrice: 148.5947,
			transactionCount: 26,
			voluemPrice: 148.5947
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 880,
			time: 1654118760000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5968,
			transactionCount: 15,
			voluemPrice: 148.5968
		},
		{
			open: 148.6,
			close: 148.55,
			volume: 1326,
			time: 1654118820000,
			lowest: 148.55,
			highest: 148.6,
			volumePrice: 148.5716,
			transactionCount: 22,
			voluemPrice: 148.5716
		},
		{
			open: 148.59,
			close: 148.59,
			volume: 828,
			time: 1654118880000,
			lowest: 148.59,
			highest: 148.6,
			volumePrice: 148.5959,
			transactionCount: 14,
			voluemPrice: 148.5959
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 836,
			time: 1654119300000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5995,
			transactionCount: 17,
			voluemPrice: 148.5995
		},
		{
			open: 148.52,
			close: 148.56,
			volume: 1359,
			time: 1654119360000,
			lowest: 148.51,
			highest: 148.57,
			volumePrice: 148.5449,
			transactionCount: 52,
			voluemPrice: 148.5449
		},
		{
			open: 148.56,
			close: 148.58,
			volume: 404,
			time: 1654119480000,
			lowest: 148.56,
			highest: 148.58,
			volumePrice: 148.575,
			transactionCount: 8,
			voluemPrice: 148.575
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 420,
			time: 1654119600000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5981,
			transactionCount: 21,
			voluemPrice: 148.5981
		},
		{
			open: 148.59,
			close: 148.59,
			volume: 324,
			time: 1654119660000,
			lowest: 148.59,
			highest: 148.59,
			volumePrice: 148.5897,
			transactionCount: 19,
			voluemPrice: 148.5897
		},
		{
			open: 148.58,
			close: 148.6,
			volume: 1514,
			time: 1654119720000,
			lowest: 148.55,
			highest: 148.6,
			volumePrice: 148.558,
			transactionCount: 25,
			voluemPrice: 148.558
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 1297,
			time: 1654119780000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5591,
			transactionCount: 14,
			voluemPrice: 148.5591
		},
		{
			open: 148.55,
			close: 148.59,
			volume: 1661,
			time: 1654119840000,
			lowest: 148.55,
			highest: 148.59,
			volumePrice: 148.5657,
			transactionCount: 25,
			voluemPrice: 148.5657
		},
		{
			open: 148.59,
			close: 148.6,
			volume: 554,
			time: 1654119960000,
			lowest: 148.59,
			highest: 148.6,
			volumePrice: 148.5949,
			transactionCount: 15,
			voluemPrice: 148.5949
		},
		{
			open: 148.5999,
			close: 148.61,
			volume: 1475,
			time: 1654120020000,
			lowest: 148.5999,
			highest: 148.61,
			volumePrice: 148.6021,
			transactionCount: 24,
			voluemPrice: 148.6021
		},
		{
			open: 148.61,
			close: 148.61,
			volume: 507,
			time: 1654120080000,
			lowest: 148.61,
			highest: 148.61,
			volumePrice: 148.6229,
			transactionCount: 65,
			voluemPrice: 148.6229
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 195,
			time: 1654120200000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.6026,
			transactionCount: 16,
			voluemPrice: 148.6026
		},
		{
			open: 148.58,
			close: 148.58,
			volume: 509,
			time: 1654120260000,
			lowest: 148.58,
			highest: 148.58,
			volumePrice: 148.5798,
			transactionCount: 15,
			voluemPrice: 148.5798
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 199,
			time: 1654120320000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5519,
			transactionCount: 11,
			voluemPrice: 148.5519
		},
		{
			open: 148.6,
			close: 148.6192,
			volume: 772,
			time: 1654120380000,
			lowest: 148.6,
			highest: 148.6192,
			volumePrice: 148.606,
			transactionCount: 13,
			voluemPrice: 148.606
		},
		{
			open: 148.61,
			close: 148.64,
			volume: 2437,
			time: 1654120440000,
			lowest: 148.61,
			highest: 148.65,
			volumePrice: 148.6293,
			transactionCount: 30,
			voluemPrice: 148.6293
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 2386,
			time: 1654120500000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.62,
			transactionCount: 23,
			voluemPrice: 148.62
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 326,
			time: 1654120680000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6204,
			transactionCount: 16,
			voluemPrice: 148.6204
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 664,
			time: 1654120740000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6209,
			transactionCount: 15,
			voluemPrice: 148.6209
		},
		{
			open: 148.63,
			close: 148.62,
			volume: 746,
			time: 1654120800000,
			lowest: 148.62,
			highest: 148.63,
			volumePrice: 148.6259,
			transactionCount: 15,
			voluemPrice: 148.6259
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 2273,
			time: 1654120860000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6203,
			transactionCount: 27,
			voluemPrice: 148.6203
		},
		{
			open: 148.62,
			close: 148.66,
			volume: 1752,
			time: 1654120920000,
			lowest: 148.62,
			highest: 148.66,
			volumePrice: 148.6414,
			transactionCount: 17,
			voluemPrice: 148.6414
		},
		{
			open: 148.64,
			close: 148.63,
			volume: 504,
			time: 1654120980000,
			lowest: 148.63,
			highest: 148.64,
			volumePrice: 148.6341,
			transactionCount: 9,
			voluemPrice: 148.6341
		},
		{
			open: 148.63,
			close: 148.62,
			volume: 2173,
			time: 1654121040000,
			lowest: 148.62,
			highest: 148.63,
			volumePrice: 148.6216,
			transactionCount: 21,
			voluemPrice: 148.6216
		},
		{
			open: 148.63,
			close: 148.62,
			volume: 1464,
			time: 1654121100000,
			lowest: 148.62,
			highest: 148.63,
			volumePrice: 148.6285,
			transactionCount: 9,
			voluemPrice: 148.6285
		},
		{
			open: 148.63,
			close: 148.62,
			volume: 1743,
			time: 1654121160000,
			lowest: 148.62,
			highest: 148.63,
			volumePrice: 148.6251,
			transactionCount: 15,
			voluemPrice: 148.6251
		},
		{
			open: 148.63,
			close: 148.63,
			volume: 3293,
			time: 1654121220000,
			lowest: 148.63,
			highest: 148.63,
			volumePrice: 148.6305,
			transactionCount: 11,
			voluemPrice: 148.6305
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 2619,
			time: 1654121460000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6202,
			transactionCount: 27,
			voluemPrice: 148.6202
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 1336,
			time: 1654121520000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6204,
			transactionCount: 33,
			voluemPrice: 148.6204
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 368,
			time: 1654121580000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6203,
			transactionCount: 12,
			voluemPrice: 148.6203
		},
		{
			open: 148.65,
			close: 148.62,
			volume: 5169,
			time: 1654121640000,
			lowest: 148.55,
			highest: 148.65,
			volumePrice: 148.6226,
			transactionCount: 57,
			voluemPrice: 148.6226
		},
		{
			open: 148.62,
			close: 148.67,
			volume: 1546,
			time: 1654121760000,
			lowest: 148.62,
			highest: 148.67,
			volumePrice: 148.6607,
			transactionCount: 20,
			voluemPrice: 148.6607
		},
		{
			open: 148.67,
			close: 148.67,
			volume: 633,
			time: 1654121820000,
			lowest: 148.67,
			highest: 148.67,
			volumePrice: 148.6688,
			transactionCount: 15,
			voluemPrice: 148.6688
		},
		{
			open: 148.69,
			close: 148.69,
			volume: 218,
			time: 1654121880000,
			lowest: 148.69,
			highest: 148.69,
			volumePrice: 148.6854,
			transactionCount: 18,
			voluemPrice: 148.6854
		},
		{
			open: 148.67,
			close: 148.67,
			volume: 1858,
			time: 1654122000000,
			lowest: 148.67,
			highest: 148.67,
			volumePrice: 148.6692,
			transactionCount: 19,
			voluemPrice: 148.6692
		},
		{
			open: 148.62,
			close: 148.62,
			volume: 2882,
			time: 1654122120000,
			lowest: 148.62,
			highest: 148.62,
			volumePrice: 148.6216,
			transactionCount: 29,
			voluemPrice: 148.6216
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 1832,
			time: 1654122180000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5502,
			transactionCount: 16,
			voluemPrice: 148.5502
		},
		{
			open: 148.56,
			close: 148.55,
			volume: 1747,
			time: 1654122360000,
			lowest: 148.42,
			highest: 148.56,
			volumePrice: 148.5063,
			transactionCount: 48,
			voluemPrice: 148.5063
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 109,
			time: 1654122420000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5508,
			transactionCount: 5,
			voluemPrice: 148.5508
		},
		{
			open: 148.61,
			close: 148.61,
			volume: 547,
			time: 1654122540000,
			lowest: 148.61,
			highest: 148.61,
			volumePrice: 148.5999,
			transactionCount: 16,
			voluemPrice: 148.5999
		},
		{
			open: 148.58,
			close: 148.55,
			volume: 1098,
			time: 1654122600000,
			lowest: 148.55,
			highest: 148.58,
			volumePrice: 148.5674,
			transactionCount: 19,
			voluemPrice: 148.5674
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 4143,
			time: 1654122660000,
			lowest: 148.55,
			highest: 148.6,
			volumePrice: 148.5688,
			transactionCount: 24,
			voluemPrice: 148.5688
		},
		{
			open: 148.56,
			close: 148.55,
			volume: 3845,
			time: 1654122720000,
			lowest: 148.55,
			highest: 148.56,
			volumePrice: 148.5511,
			transactionCount: 28,
			voluemPrice: 148.5511
		},
		{
			open: 148.55,
			close: 148.57,
			volume: 835,
			time: 1654122780000,
			lowest: 148.55,
			highest: 148.57,
			volumePrice: 148.5549,
			transactionCount: 20,
			voluemPrice: 148.5549
		},
		{
			open: 148.56,
			close: 148.56,
			volume: 1966,
			time: 1654122840000,
			lowest: 148.56,
			highest: 148.57,
			volumePrice: 148.5625,
			transactionCount: 26,
			voluemPrice: 148.5625
		},
		{
			open: 148.55,
			close: 148.56,
			volume: 5921,
			time: 1654122900000,
			lowest: 148.55,
			highest: 148.56,
			volumePrice: 148.5597,
			transactionCount: 14,
			voluemPrice: 148.5597
		},
		{
			open: 148.55,
			close: 148.56,
			volume: 1121,
			time: 1654123020000,
			lowest: 148.55,
			highest: 148.56,
			volumePrice: 148.551,
			transactionCount: 17,
			voluemPrice: 148.551
		},
		{
			open: 148.56,
			close: 148.56,
			volume: 284,
			time: 1654123080000,
			lowest: 148.56,
			highest: 148.56,
			volumePrice: 148.5679,
			transactionCount: 14,
			voluemPrice: 148.5679
		},
		{
			open: 148.58,
			close: 148.55,
			volume: 1436,
			time: 1654123140000,
			lowest: 148.55,
			highest: 148.58,
			volumePrice: 148.5635,
			transactionCount: 17,
			voluemPrice: 148.5635
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 330,
			time: 1654123260000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5525,
			transactionCount: 10,
			voluemPrice: 148.5525
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 584,
			time: 1654123320000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.594,
			transactionCount: 17,
			voluemPrice: 148.594
		},
		{
			open: 148.6499,
			close: 148.6499,
			volume: 180,
			time: 1654123380000,
			lowest: 148.6499,
			highest: 148.6499,
			volumePrice: 148.6286,
			transactionCount: 11,
			voluemPrice: 148.6286
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 322,
			time: 1654123440000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.6002,
			transactionCount: 10,
			voluemPrice: 148.6002
		},
		{
			open: 148.6,
			close: 148.6,
			volume: 142,
			time: 1654123620000,
			lowest: 148.6,
			highest: 148.6,
			volumePrice: 148.5926,
			transactionCount: 8,
			voluemPrice: 148.5926
		},
		{
			open: 148.56,
			close: 148.56,
			volume: 403,
			time: 1654123680000,
			lowest: 148.56,
			highest: 148.56,
			volumePrice: 148.561,
			transactionCount: 8,
			voluemPrice: 148.561
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 4388,
			time: 1654123740000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5502,
			transactionCount: 27,
			voluemPrice: 148.5502
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 394,
			time: 1654123800000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5504,
			transactionCount: 10,
			voluemPrice: 148.5504
		},
		{
			open: 148.55,
			close: 148.55,
			volume: 3912,
			time: 1654123860000,
			lowest: 148.55,
			highest: 148.55,
			volumePrice: 148.5482,
			transactionCount: 23,
			voluemPrice: 148.5482
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 213,
			time: 1654123980000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.4715,
			transactionCount: 12,
			voluemPrice: 148.4715
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 479,
			time: 1654124040000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4909,
			transactionCount: 10,
			voluemPrice: 148.4909
		},
		{
			open: 148.53,
			close: 148.54,
			volume: 738,
			time: 1654124160000,
			lowest: 148.53,
			highest: 148.54,
			volumePrice: 148.5271,
			transactionCount: 20,
			voluemPrice: 148.5271
		},
		{
			open: 148.53,
			close: 148.53,
			volume: 376,
			time: 1654124220000,
			lowest: 148.53,
			highest: 148.53,
			volumePrice: 148.5295,
			transactionCount: 6,
			voluemPrice: 148.5295
		},
		{
			open: 148.5,
			close: 148.53,
			volume: 1409,
			time: 1654124340000,
			lowest: 148.5,
			highest: 148.53,
			volumePrice: 148.5216,
			transactionCount: 15,
			voluemPrice: 148.5216
		},
		{
			open: 148.48,
			close: 148.4999,
			volume: 228,
			time: 1654124640000,
			lowest: 148.48,
			highest: 148.4999,
			volumePrice: 148.4909,
			transactionCount: 9,
			voluemPrice: 148.4909
		},
		{
			open: 148.45,
			close: 148.43,
			volume: 705,
			time: 1654124760000,
			lowest: 148.43,
			highest: 148.45,
			volumePrice: 148.4409,
			transactionCount: 23,
			voluemPrice: 148.4409
		},
		{
			open: 148.45,
			close: 148.48,
			volume: 1008,
			time: 1654124820000,
			lowest: 148.45,
			highest: 148.48,
			volumePrice: 148.4559,
			transactionCount: 15,
			voluemPrice: 148.4559
		},
		{
			open: 148.45,
			close: 148.4,
			volume: 2110,
			time: 1654125000000,
			lowest: 148.4,
			highest: 148.45,
			volumePrice: 148.4246,
			transactionCount: 23,
			voluemPrice: 148.4246
		},
		{
			open: 148.38,
			close: 148.38,
			volume: 358,
			time: 1654125060000,
			lowest: 148.38,
			highest: 148.38,
			volumePrice: 148.3839,
			transactionCount: 22,
			voluemPrice: 148.3839
		},
		{
			open: 148.38,
			close: 148.4,
			volume: 8457,
			time: 1654125120000,
			lowest: 148.38,
			highest: 148.4,
			volumePrice: 148.3991,
			transactionCount: 19,
			voluemPrice: 148.3991
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 475,
			time: 1654125180000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4266,
			transactionCount: 13,
			voluemPrice: 148.4266
		},
		{
			open: 148.42,
			close: 148.41,
			volume: 923,
			time: 1654125300000,
			lowest: 148.41,
			highest: 148.42,
			volumePrice: 148.4126,
			transactionCount: 14,
			voluemPrice: 148.4126
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 145,
			time: 1654125420000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4381,
			transactionCount: 7,
			voluemPrice: 148.4381
		},
		{
			open: 148.44,
			close: 148.45,
			volume: 1026,
			time: 1654125480000,
			lowest: 148.44,
			highest: 148.45,
			volumePrice: 148.4466,
			transactionCount: 17,
			voluemPrice: 148.4466
		},
		{
			open: 148.48,
			close: 148.44,
			volume: 1092,
			time: 1654125600000,
			lowest: 148.44,
			highest: 148.48,
			volumePrice: 148.4525,
			transactionCount: 11,
			voluemPrice: 148.4525
		},
		{
			open: 148.47,
			close: 148.46,
			volume: 1253,
			time: 1654125780000,
			lowest: 148.46,
			highest: 148.47,
			volumePrice: 148.4639,
			transactionCount: 12,
			voluemPrice: 148.4639
		},
		{
			open: 148.47,
			close: 148.48,
			volume: 1564,
			time: 1654125960000,
			lowest: 148.47,
			highest: 148.48,
			volumePrice: 148.4761,
			transactionCount: 19,
			voluemPrice: 148.4761
		},
		{
			open: 148.47,
			close: 148.5,
			volume: 3369,
			time: 1654126020000,
			lowest: 148.45,
			highest: 148.5,
			volumePrice: 148.4666,
			transactionCount: 29,
			voluemPrice: 148.4666
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 235,
			time: 1654126080000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4546,
			transactionCount: 7,
			voluemPrice: 148.4546
		},
		{
			open: 148.48,
			close: 148.5,
			volume: 858,
			time: 1654126140000,
			lowest: 148.47,
			highest: 148.5,
			volumePrice: 148.4813,
			transactionCount: 15,
			voluemPrice: 148.4813
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 580,
			time: 1654126200000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.5015,
			transactionCount: 11,
			voluemPrice: 148.5015
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 1024,
			time: 1654126260000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.5008,
			transactionCount: 11,
			voluemPrice: 148.5008
		},
		{
			open: 148.5,
			close: 148.5,
			volume: 552,
			time: 1654126320000,
			lowest: 148.5,
			highest: 148.5,
			volumePrice: 148.5029,
			transactionCount: 19,
			voluemPrice: 148.5029
		},
		{
			open: 148.47,
			close: 148.47,
			volume: 539,
			time: 1654126380000,
			lowest: 148.47,
			highest: 148.47,
			volumePrice: 148.4703,
			transactionCount: 15,
			voluemPrice: 148.4703
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 437,
			time: 1654126440000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4583,
			transactionCount: 14,
			voluemPrice: 148.4583
		},
		{
			open: 148.41,
			close: 148.4,
			volume: 849,
			time: 1654126620000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.4024,
			transactionCount: 24,
			voluemPrice: 148.4024
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 3157,
			time: 1654126680000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.41,
			transactionCount: 11,
			voluemPrice: 148.41
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 449,
			time: 1654126740000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.4057,
			transactionCount: 19,
			voluemPrice: 148.4057
		},
		{
			open: 148.41,
			close: 148.4,
			volume: 1549,
			time: 1654126800000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.405,
			transactionCount: 13,
			voluemPrice: 148.405
		},
		{
			open: 148.4,
			close: 148.41,
			volume: 1105,
			time: 1654126860000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.409,
			transactionCount: 13,
			voluemPrice: 148.409
		},
		{
			open: 148.4,
			close: 148.39,
			volume: 3235,
			time: 1654126920000,
			lowest: 148.39,
			highest: 148.42,
			volumePrice: 148.401,
			transactionCount: 23,
			voluemPrice: 148.401
		},
		{
			open: 148.39,
			close: 148.32,
			volume: 2189,
			time: 1654126980000,
			lowest: 148.32,
			highest: 148.39,
			volumePrice: 148.3653,
			transactionCount: 38,
			voluemPrice: 148.3653
		},
		{
			open: 148.34,
			close: 148.31,
			volume: 1321,
			time: 1654127040000,
			lowest: 148.3,
			highest: 148.34,
			volumePrice: 148.3127,
			transactionCount: 30,
			voluemPrice: 148.3127
		},
		{
			open: 148.31,
			close: 148.32,
			volume: 1497,
			time: 1654127100000,
			lowest: 148.31,
			highest: 148.32,
			volumePrice: 148.3115,
			transactionCount: 20,
			voluemPrice: 148.3115
		},
		{
			open: 148.39,
			close: 148.39,
			volume: 634,
			time: 1654127220000,
			lowest: 148.39,
			highest: 148.39,
			volumePrice: 148.3791,
			transactionCount: 17,
			voluemPrice: 148.3791
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 297,
			time: 1654127280000,
			lowest: 148.33,
			highest: 148.33,
			volumePrice: 148.3349,
			transactionCount: 8,
			voluemPrice: 148.3349
		},
		{
			open: 148.35,
			close: 148.33,
			volume: 437,
			time: 1654127340000,
			lowest: 148.33,
			highest: 148.35,
			volumePrice: 148.3402,
			transactionCount: 12,
			voluemPrice: 148.3402
		},
		{
			open: 148.34,
			close: 148.38,
			volume: 1956,
			time: 1654127400000,
			lowest: 148.34,
			highest: 148.38,
			volumePrice: 148.3549,
			transactionCount: 18,
			voluemPrice: 148.3549
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 251,
			time: 1654127460000,
			lowest: 148.33,
			highest: 148.33,
			volumePrice: 148.3378,
			transactionCount: 11,
			voluemPrice: 148.3378
		},
		{
			open: 148.3799,
			close: 148.33,
			volume: 1119,
			time: 1654127520000,
			lowest: 148.33,
			highest: 148.3799,
			volumePrice: 148.3443,
			transactionCount: 15,
			voluemPrice: 148.3443
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 592,
			time: 1654127580000,
			lowest: 148.33,
			highest: 148.33,
			volumePrice: 148.3353,
			transactionCount: 14,
			voluemPrice: 148.3353
		},
		{
			open: 148.34,
			close: 148.33,
			volume: 1161,
			time: 1654127640000,
			lowest: 148.33,
			highest: 148.34,
			volumePrice: 148.3384,
			transactionCount: 27,
			voluemPrice: 148.3384
		},
		{
			open: 148.33,
			close: 148.28,
			volume: 2192,
			time: 1654127700000,
			lowest: 148.28,
			highest: 148.33,
			volumePrice: 148.3139,
			transactionCount: 28,
			voluemPrice: 148.3139
		},
		{
			open: 148.26,
			close: 148.22,
			volume: 8071,
			time: 1654127760000,
			lowest: 148.2,
			highest: 148.26,
			volumePrice: 148.228,
			transactionCount: 58,
			voluemPrice: 148.228
		},
		{
			open: 148.15,
			close: 148.1,
			volume: 19469,
			time: 1654127820000,
			lowest: 148,
			highest: 148.15,
			volumePrice: 148.0336,
			transactionCount: 186,
			voluemPrice: 148.0336
		},
		{
			open: 148.05,
			close: 148,
			volume: 3247,
			time: 1654127880000,
			lowest: 148,
			highest: 148.1,
			volumePrice: 148.0611,
			transactionCount: 43,
			voluemPrice: 148.0611
		},
		{
			open: 148,
			close: 148.01,
			volume: 7869,
			time: 1654127940000,
			lowest: 147.9,
			highest: 148.05,
			volumePrice: 147.9697,
			transactionCount: 58,
			voluemPrice: 147.9697
		},
		{
			open: 148.57,
			close: 148.98,
			volume: 3229,
			time: 1654156800000,
			lowest: 148.57,
			highest: 149.35,
			volumePrice: 148.9808,
			transactionCount: 50,
			voluemPrice: 148.9808
		},
		{
			open: 148.93,
			close: 148.98,
			volume: 1144,
			time: 1654156860000,
			lowest: 148.93,
			highest: 148.98,
			volumePrice: 148.9527,
			transactionCount: 19,
			voluemPrice: 148.9527
		},
		{
			open: 149.07,
			close: 149.05,
			volume: 810,
			time: 1654156920000,
			lowest: 149.05,
			highest: 149.07,
			volumePrice: 149.0551,
			transactionCount: 26,
			voluemPrice: 149.0551
		},
		{
			open: 149.05,
			close: 149.05,
			volume: 337,
			time: 1654156980000,
			lowest: 149.05,
			highest: 149.05,
			volumePrice: 149.049,
			transactionCount: 18,
			voluemPrice: 149.049
		},
		{
			open: 149.04,
			close: 149.04,
			volume: 814,
			time: 1654157220000,
			lowest: 149.04,
			highest: 149.04,
			volumePrice: 149.04,
			transactionCount: 20,
			voluemPrice: 149.04
		},
		{
			open: 149.07,
			close: 149.11,
			volume: 867,
			time: 1654157280000,
			lowest: 149.07,
			highest: 149.11,
			volumePrice: 149.0783,
			transactionCount: 28,
			voluemPrice: 149.0783
		},
		{
			open: 149.11,
			close: 149.11,
			volume: 325,
			time: 1654157340000,
			lowest: 149.11,
			highest: 149.11,
			volumePrice: 149.1103,
			transactionCount: 7,
			voluemPrice: 149.1103
		},
		{
			open: 149.13,
			close: 149.13,
			volume: 362,
			time: 1654157400000,
			lowest: 149.13,
			highest: 149.13,
			volumePrice: 149.1292,
			transactionCount: 9,
			voluemPrice: 149.1292
		},
		{
			open: 149.12,
			close: 149.13,
			volume: 1064,
			time: 1654157520000,
			lowest: 149.12,
			highest: 149.13,
			volumePrice: 149.1241,
			transactionCount: 11,
			voluemPrice: 149.1241
		},
		{
			open: 149.13,
			close: 149.17,
			volume: 5165,
			time: 1654157580000,
			lowest: 149.06,
			highest: 149.17,
			volumePrice: 149.1396,
			transactionCount: 82,
			voluemPrice: 149.1396
		},
		{
			open: 149.15,
			close: 149.19,
			volume: 2908,
			time: 1654157640000,
			lowest: 149.15,
			highest: 149.19,
			volumePrice: 149.1749,
			transactionCount: 27,
			voluemPrice: 149.1749
		},
		{
			open: 149.19,
			close: 149.19,
			volume: 982,
			time: 1654157700000,
			lowest: 149.19,
			highest: 149.19,
			volumePrice: 149.195,
			transactionCount: 14,
			voluemPrice: 149.195
		},
		{
			open: 149.2,
			close: 149.22,
			volume: 613,
			time: 1654157760000,
			lowest: 149.2,
			highest: 149.22,
			volumePrice: 149.2086,
			transactionCount: 14,
			voluemPrice: 149.2086
		},
		{
			open: 149.26,
			close: 149.27,
			volume: 1378,
			time: 1654157820000,
			lowest: 149.26,
			highest: 149.27,
			volumePrice: 149.2674,
			transactionCount: 17,
			voluemPrice: 149.2674
		},
		{
			open: 149.45,
			close: 149.45,
			volume: 587,
			time: 1654157940000,
			lowest: 149.45,
			highest: 149.45,
			volumePrice: 149.4447,
			transactionCount: 16,
			voluemPrice: 149.4447
		},
		{
			open: 149.45,
			close: 149.5,
			volume: 2526,
			time: 1654158060000,
			lowest: 149.45,
			highest: 149.5,
			volumePrice: 149.4825,
			transactionCount: 48,
			voluemPrice: 149.4825
		},
		{
			open: 149.43,
			close: 149.42,
			volume: 1057,
			time: 1654158180000,
			lowest: 149.42,
			highest: 149.43,
			volumePrice: 149.4221,
			transactionCount: 25,
			voluemPrice: 149.4221
		},
		{
			open: 149.46,
			close: 149.47,
			volume: 496,
			time: 1654158300000,
			lowest: 149.46,
			highest: 149.47,
			volumePrice: 149.4629,
			transactionCount: 13,
			voluemPrice: 149.4629
		},
		{
			open: 149.45,
			close: 149.45,
			volume: 373,
			time: 1654158420000,
			lowest: 149.45,
			highest: 149.45,
			volumePrice: 149.4533,
			transactionCount: 6,
			voluemPrice: 149.4533
		},
		{
			open: 149.44,
			close: 149.44,
			volume: 1892,
			time: 1654158480000,
			lowest: 149.44,
			highest: 149.47,
			volumePrice: 149.4526,
			transactionCount: 33,
			voluemPrice: 149.4526
		},
		{
			open: 149.45,
			close: 149.45,
			volume: 187,
			time: 1654158540000,
			lowest: 149.45,
			highest: 149.45,
			volumePrice: 149.4473,
			transactionCount: 12,
			voluemPrice: 149.4473
		},
		{
			open: 149.47,
			close: 149.6,
			volume: 1383,
			time: 1654158600000,
			lowest: 149.47,
			highest: 149.6,
			volumePrice: 149.5362,
			transactionCount: 32,
			voluemPrice: 149.5362
		},
		{
			open: 149.49,
			close: 149.5,
			volume: 563,
			time: 1654158900000,
			lowest: 149.49,
			highest: 149.5,
			volumePrice: 149.4945,
			transactionCount: 17,
			voluemPrice: 149.4945
		},
		{
			open: 149.47,
			close: 149.47,
			volume: 213,
			time: 1654159080000,
			lowest: 149.47,
			highest: 149.47,
			volumePrice: 149.4724,
			transactionCount: 7,
			voluemPrice: 149.4724
		},
		{
			open: 149.44,
			close: 149.44,
			volume: 314,
			time: 1654159200000,
			lowest: 149.44,
			highest: 149.44,
			volumePrice: 149.4411,
			transactionCount: 10,
			voluemPrice: 149.4411
		},
		{
			open: 149.44,
			close: 149.43,
			volume: 637,
			time: 1654159260000,
			lowest: 149.43,
			highest: 149.44,
			volumePrice: 149.4261,
			transactionCount: 19,
			voluemPrice: 149.4261
		},
		{
			open: 149.43,
			close: 149.43,
			volume: 654,
			time: 1654159380000,
			lowest: 149.43,
			highest: 149.43,
			volumePrice: 149.4342,
			transactionCount: 11,
			voluemPrice: 149.4342
		},
		{
			open: 149.45,
			close: 149.45,
			volume: 755,
			time: 1654159440000,
			lowest: 149.45,
			highest: 149.45,
			volumePrice: 149.4511,
			transactionCount: 11,
			voluemPrice: 149.4511
		},
		{
			open: 149.49,
			close: 149.5,
			volume: 1301,
			time: 1654159500000,
			lowest: 149.49,
			highest: 149.5,
			volumePrice: 149.4867,
			transactionCount: 23,
			voluemPrice: 149.4867
		},
		{
			open: 149.45,
			close: 149.45,
			volume: 376,
			time: 1654159560000,
			lowest: 149.45,
			highest: 149.45,
			volumePrice: 149.4573,
			transactionCount: 17,
			voluemPrice: 149.4573
		},
		{
			open: 149.32,
			close: 149.33,
			volume: 802,
			time: 1654159620000,
			lowest: 149.32,
			highest: 149.33,
			volumePrice: 149.3485,
			transactionCount: 35,
			voluemPrice: 149.3485
		},
		{
			open: 149.42,
			close: 149.42,
			volume: 140,
			time: 1654159980000,
			lowest: 149.42,
			highest: 149.42,
			volumePrice: 149.4193,
			transactionCount: 6,
			voluemPrice: 149.4193
		},
		{
			open: 149.33,
			close: 149.33,
			volume: 341,
			time: 1654160040000,
			lowest: 149.33,
			highest: 149.33,
			volumePrice: 149.3352,
			transactionCount: 15,
			voluemPrice: 149.3352
		},
		{
			open: 149.31,
			close: 149.31,
			volume: 354,
			time: 1654160100000,
			lowest: 149.31,
			highest: 149.31,
			volumePrice: 149.315,
			transactionCount: 12,
			voluemPrice: 149.315
		},
		{
			open: 149.31,
			close: 149.31,
			volume: 397,
			time: 1654160160000,
			lowest: 149.31,
			highest: 149.31,
			volumePrice: 149.3108,
			transactionCount: 7,
			voluemPrice: 149.3108
		},
		{
			open: 149.32,
			close: 149.3,
			volume: 1112,
			time: 1654160220000,
			lowest: 149.3,
			highest: 149.32,
			volumePrice: 149.3114,
			transactionCount: 25,
			voluemPrice: 149.3114
		},
		{
			open: 149.29,
			close: 149.29,
			volume: 1041,
			time: 1654160340000,
			lowest: 149.29,
			highest: 149.29,
			volumePrice: 149.289,
			transactionCount: 18,
			voluemPrice: 149.289
		},
		{
			open: 149.23,
			close: 149.3,
			volume: 1972,
			time: 1654160460000,
			lowest: 149.23,
			highest: 149.3,
			volumePrice: 149.2741,
			transactionCount: 22,
			voluemPrice: 149.2741
		},
		{
			open: 149.23,
			close: 149.23,
			volume: 605,
			time: 1654160580000,
			lowest: 149.23,
			highest: 149.23,
			volumePrice: 149.2315,
			transactionCount: 22,
			voluemPrice: 149.2315
		},
		{
			open: 149.34,
			close: 149.39,
			volume: 1028,
			time: 1654161540000,
			lowest: 149.34,
			highest: 149.39,
			volumePrice: 149.3602,
			transactionCount: 24,
			voluemPrice: 149.3602
		},
		{
			open: 149.37,
			close: 149.37,
			volume: 384,
			time: 1654161780000,
			lowest: 149.37,
			highest: 149.37,
			volumePrice: 149.3701,
			transactionCount: 8,
			voluemPrice: 149.3701
		},
		{
			open: 149.41,
			close: 149.48,
			volume: 1146,
			time: 1654162020000,
			lowest: 149.41,
			highest: 149.48,
			volumePrice: 149.4646,
			transactionCount: 21,
			voluemPrice: 149.4646
		},
		{
			open: 149.46,
			close: 149.46,
			volume: 682,
			time: 1654162980000,
			lowest: 149.46,
			highest: 149.46,
			volumePrice: 149.4461,
			transactionCount: 14,
			voluemPrice: 149.4461
		},
		{
			open: 149.38,
			close: 149.4,
			volume: 582,
			time: 1654163400000,
			lowest: 149.38,
			highest: 149.4,
			volumePrice: 149.3827,
			transactionCount: 8,
			voluemPrice: 149.3827
		},
		{
			open: 149.4,
			close: 149.4,
			volume: 700,
			time: 1654163460000,
			lowest: 149.4,
			highest: 149.4,
			volumePrice: 149.3876,
			transactionCount: 19,
			voluemPrice: 149.3876
		},
		{
			open: 149.29,
			close: 149.29,
			volume: 488,
			time: 1654163580000,
			lowest: 149.29,
			highest: 149.29,
			volumePrice: 149.29,
			transactionCount: 2,
			voluemPrice: 149.29
		},
		{
			open: 149.34,
			close: 149.34,
			volume: 203,
			time: 1654163940000,
			lowest: 149.34,
			highest: 149.34,
			volumePrice: 149.34,
			transactionCount: 3,
			voluemPrice: 149.34
		},
		{
			open: 149.34,
			close: 149.34,
			volume: 258,
			time: 1654164060000,
			lowest: 149.34,
			highest: 149.34,
			volumePrice: 149.3394,
			transactionCount: 5,
			voluemPrice: 149.3394
		},
		{
			open: 149.4,
			close: 149.4,
			volume: 253,
			time: 1654164360000,
			lowest: 149.4,
			highest: 149.4,
			volumePrice: 149.3762,
			transactionCount: 11,
			voluemPrice: 149.3762
		},
		{
			open: 149.34,
			close: 149.4,
			volume: 903,
			time: 1654164720000,
			lowest: 149.34,
			highest: 149.4,
			volumePrice: 149.3872,
			transactionCount: 8,
			voluemPrice: 149.3872
		},
		{
			open: 149.35,
			close: 149.35,
			volume: 799,
			time: 1654164840000,
			lowest: 149.35,
			highest: 149.35,
			volumePrice: 149.35,
			transactionCount: 5,
			voluemPrice: 149.35
		},
		{
			open: 149.34,
			close: 149.34,
			volume: 206,
			time: 1654165140000,
			lowest: 149.34,
			highest: 149.34,
			volumePrice: 149.3403,
			transactionCount: 5,
			voluemPrice: 149.3403
		},
		{
			open: 149.3,
			close: 149.29,
			volume: 7521,
			time: 1654165320000,
			lowest: 149.29,
			highest: 149.3,
			volumePrice: 149.2903,
			transactionCount: 38,
			voluemPrice: 149.2903
		},
		{
			open: 149.21,
			close: 149.19,
			volume: 1549,
			time: 1654165560000,
			lowest: 149.19,
			highest: 149.22,
			volumePrice: 149.2084,
			transactionCount: 44,
			voluemPrice: 149.2084
		},
		{
			open: 149.24,
			close: 149.31,
			volume: 1099,
			time: 1654165680000,
			lowest: 149.24,
			highest: 149.31,
			volumePrice: 149.2488,
			transactionCount: 22,
			voluemPrice: 149.2488
		},
		{
			open: 149.3,
			close: 149.3,
			volume: 322,
			time: 1654166220000,
			lowest: 149.3,
			highest: 149.3,
			volumePrice: 149.2976,
			transactionCount: 5,
			voluemPrice: 149.2976
		},
		{
			open: 149.36,
			close: 149.37,
			volume: 1471,
			time: 1654166460000,
			lowest: 149.36,
			highest: 149.37,
			volumePrice: 149.3683,
			transactionCount: 13,
			voluemPrice: 149.3683
		},
		{
			open: 149.46,
			close: 149.68,
			volume: 5346,
			time: 1654166700000,
			lowest: 149.46,
			highest: 149.68,
			volumePrice: 149.5024,
			transactionCount: 89,
			voluemPrice: 149.5024
		},
		{
			open: 149.52,
			close: 149.52,
			volume: 730,
			time: 1654166820000,
			lowest: 149.52,
			highest: 149.52,
			volumePrice: 149.5167,
			transactionCount: 13,
			voluemPrice: 149.5167
		},
		{
			open: 149.62,
			close: 149.66,
			volume: 829,
			time: 1654166880000,
			lowest: 149.62,
			highest: 149.66,
			volumePrice: 149.6318,
			transactionCount: 14,
			voluemPrice: 149.6318
		},
		{
			open: 149.63,
			close: 149.25,
			volume: 2310,
			time: 1654167000000,
			lowest: 149.25,
			highest: 149.63,
			volumePrice: 149.4355,
			transactionCount: 42,
			voluemPrice: 149.4355
		},
		{
			open: 149.5,
			close: 149.5,
			volume: 257,
			time: 1654167060000,
			lowest: 149.5,
			highest: 149.5,
			volumePrice: 149.5002,
			transactionCount: 6,
			voluemPrice: 149.5002
		},
		{
			open: 149.5,
			close: 149.52,
			volume: 2284,
			time: 1654167120000,
			lowest: 149.45,
			highest: 149.52,
			volumePrice: 149.5039,
			transactionCount: 35,
			voluemPrice: 149.5039
		},
		{
			open: 149.58,
			close: 149.58,
			volume: 355,
			time: 1654167240000,
			lowest: 149.58,
			highest: 149.58,
			volumePrice: 149.5611,
			transactionCount: 11,
			voluemPrice: 149.5611
		},
		{
			open: 149.64,
			close: 149.7,
			volume: 5607,
			time: 1654167420000,
			lowest: 149.64,
			highest: 149.7,
			volumePrice: 149.6722,
			transactionCount: 37,
			voluemPrice: 149.6722
		},
		{
			open: 149.56,
			close: 149.6,
			volume: 1099,
			time: 1654167480000,
			lowest: 149.56,
			highest: 149.6,
			volumePrice: 149.5898,
			transactionCount: 9,
			voluemPrice: 149.5898
		},
		{
			open: 149.58,
			close: 149.42,
			volume: 8347,
			time: 1654167600000,
			lowest: 149.26,
			highest: 149.65,
			volumePrice: 149.4518,
			transactionCount: 146,
			voluemPrice: 149.4518
		},
		{
			open: 149.41,
			close: 149.37,
			volume: 1477,
			time: 1654167660000,
			lowest: 149.37,
			highest: 149.41,
			volumePrice: 149.4018,
			transactionCount: 18,
			voluemPrice: 149.4018
		},
		{
			open: 149.37,
			close: 149.35,
			volume: 1540,
			time: 1654167720000,
			lowest: 149.35,
			highest: 149.37,
			volumePrice: 149.3542,
			transactionCount: 29,
			voluemPrice: 149.3542
		},
		{
			open: 149.31,
			close: 149.34,
			volume: 3981,
			time: 1654167780000,
			lowest: 149.3,
			highest: 149.36,
			volumePrice: 149.3126,
			transactionCount: 55,
			voluemPrice: 149.3126
		},
		{
			open: 149.33,
			close: 149.27,
			volume: 2079,
			time: 1654167840000,
			lowest: 149.24,
			highest: 149.33,
			volumePrice: 149.2739,
			transactionCount: 59,
			voluemPrice: 149.2739
		},
		{
			open: 149.26,
			close: 149.27,
			volume: 1119,
			time: 1654167900000,
			lowest: 149.26,
			highest: 149.27,
			volumePrice: 149.2644,
			transactionCount: 13,
			voluemPrice: 149.2644
		},
		{
			open: 149.31,
			close: 149.4,
			volume: 3200,
			time: 1654168020000,
			lowest: 149.31,
			highest: 149.47,
			volumePrice: 149.3842,
			transactionCount: 41,
			voluemPrice: 149.3842
		},
		{
			open: 149.33,
			close: 149.33,
			volume: 322,
			time: 1654168080000,
			lowest: 149.33,
			highest: 149.33,
			volumePrice: 149.3284,
			transactionCount: 10,
			voluemPrice: 149.3284
		},
		{
			open: 149.33,
			close: 149.38,
			volume: 914,
			time: 1654168140000,
			lowest: 149.33,
			highest: 149.38,
			volumePrice: 149.361,
			transactionCount: 19,
			voluemPrice: 149.361
		},
		{
			open: 149.3,
			close: 149.3,
			volume: 2778,
			time: 1654168260000,
			lowest: 149.3,
			highest: 149.34,
			volumePrice: 149.3182,
			transactionCount: 60,
			voluemPrice: 149.3182
		},
		{
			open: 149.31,
			close: 149.3,
			volume: 1969,
			time: 1654168320000,
			lowest: 149.3,
			highest: 149.31,
			volumePrice: 149.3072,
			transactionCount: 8,
			voluemPrice: 149.3072
		},
		{
			open: 149.3,
			close: 149.38,
			volume: 1432,
			time: 1654168380000,
			lowest: 149.3,
			highest: 149.41,
			volumePrice: 149.3543,
			transactionCount: 29,
			voluemPrice: 149.3543
		},
		{
			open: 149.4,
			close: 149.5,
			volume: 6082,
			time: 1654168440000,
			lowest: 149.4,
			highest: 149.5,
			volumePrice: 149.4666,
			transactionCount: 66,
			voluemPrice: 149.4666
		},
		{
			open: 149.32,
			close: 149.36,
			volume: 2795,
			time: 1654168500000,
			lowest: 149.32,
			highest: 149.36,
			volumePrice: 149.3309,
			transactionCount: 21,
			voluemPrice: 149.3309
		},
		{
			open: 149.46,
			close: 149.46,
			volume: 8132,
			time: 1654168560000,
			lowest: 149.36,
			highest: 149.5,
			volumePrice: 149.4544,
			transactionCount: 100,
			voluemPrice: 149.4544
		},
		{
			open: 149.37,
			close: 149.3,
			volume: 9856,
			time: 1654168620000,
			lowest: 149.27,
			highest: 149.37,
			volumePrice: 149.3442,
			transactionCount: 51,
			voluemPrice: 149.3442
		},
		{
			open: 149.35,
			close: 149.35,
			volume: 155,
			time: 1654168680000,
			lowest: 149.35,
			highest: 149.35,
			volumePrice: 149.3497,
			transactionCount: 4,
			voluemPrice: 149.3497
		},
		{
			open: 149.3,
			close: 149.32,
			volume: 1327,
			time: 1654168740000,
			lowest: 149.3,
			highest: 149.32,
			volumePrice: 149.314,
			transactionCount: 16,
			voluemPrice: 149.314
		},
		{
			open: 149.3,
			close: 149.32,
			volume: 6051,
			time: 1654168800000,
			lowest: 149.29,
			highest: 149.43,
			volumePrice: 149.3786,
			transactionCount: 56,
			voluemPrice: 149.3786
		},
		{
			open: 149.4,
			close: 149.4,
			volume: 402,
			time: 1654168860000,
			lowest: 149.4,
			highest: 149.4,
			volumePrice: 149.4001,
			transactionCount: 18,
			voluemPrice: 149.4001
		},
		{
			open: 149.43,
			close: 149.43,
			volume: 465,
			time: 1654168920000,
			lowest: 149.43,
			highest: 149.43,
			volumePrice: 149.4249,
			transactionCount: 14,
			voluemPrice: 149.4249
		},
		{
			open: 149.42,
			close: 149.54,
			volume: 3853,
			time: 1654168980000,
			lowest: 149.42,
			highest: 149.54,
			volumePrice: 149.4816,
			transactionCount: 47,
			voluemPrice: 149.4816
		},
		{
			open: 149.44,
			close: 149.5,
			volume: 1317,
			time: 1654169040000,
			lowest: 149.44,
			highest: 149.5,
			volumePrice: 149.4814,
			transactionCount: 30,
			voluemPrice: 149.4814
		},
		{
			open: 149.5,
			close: 149.52,
			volume: 2043,
			time: 1654169100000,
			lowest: 149.5,
			highest: 149.52,
			volumePrice: 149.5108,
			transactionCount: 26,
			voluemPrice: 149.5108
		},
		{
			open: 149.52,
			close: 149.52,
			volume: 188,
			time: 1654169160000,
			lowest: 149.52,
			highest: 149.52,
			volumePrice: 149.5191,
			transactionCount: 2,
			voluemPrice: 149.5191
		},
		{
			open: 149.49,
			close: 149.54,
			volume: 1922,
			time: 1654169220000,
			lowest: 149.49,
			highest: 149.54,
			volumePrice: 149.5165,
			transactionCount: 22,
			voluemPrice: 149.5165
		},
		{
			open: 149.52,
			close: 149.52,
			volume: 759,
			time: 1654169340000,
			lowest: 149.52,
			highest: 149.52,
			volumePrice: 149.5181,
			transactionCount: 17,
			voluemPrice: 149.5181
		},
		{
			open: 149.54,
			close: 149.56,
			volume: 1141,
			time: 1654169400000,
			lowest: 149.54,
			highest: 149.56,
			volumePrice: 149.5525,
			transactionCount: 34,
			voluemPrice: 149.5525
		},
		{
			open: 149.42,
			close: 149.36,
			volume: 2961,
			time: 1654169580000,
			lowest: 149.36,
			highest: 149.42,
			volumePrice: 149.3991,
			transactionCount: 36,
			voluemPrice: 149.3991
		},
		{
			open: 149.42,
			close: 149.42,
			volume: 561,
			time: 1654169640000,
			lowest: 149.42,
			highest: 149.42,
			volumePrice: 149.4446,
			transactionCount: 12,
			voluemPrice: 149.4446
		},
		{
			open: 149.48,
			close: 149.49,
			volume: 1506,
			time: 1654169700000,
			lowest: 149.48,
			highest: 149.49,
			volumePrice: 149.4767,
			transactionCount: 23,
			voluemPrice: 149.4767
		},
		{
			open: 149.49,
			close: 149.5,
			volume: 4157,
			time: 1654169760000,
			lowest: 149.49,
			highest: 149.54,
			volumePrice: 149.5129,
			transactionCount: 44,
			voluemPrice: 149.5129
		},
		{
			open: 149.46,
			close: 149.45,
			volume: 407,
			time: 1654169820000,
			lowest: 149.45,
			highest: 149.46,
			volumePrice: 149.4548,
			transactionCount: 6,
			voluemPrice: 149.4548
		},
		{
			open: 149.43,
			close: 149.47,
			volume: 582,
			time: 1654169880000,
			lowest: 149.41,
			highest: 149.47,
			volumePrice: 149.4266,
			transactionCount: 11,
			voluemPrice: 149.4266
		},
		{
			open: 149.46,
			close: 149.54,
			volume: 3712,
			time: 1654169940000,
			lowest: 149.46,
			highest: 149.54,
			volumePrice: 149.4903,
			transactionCount: 41,
			voluemPrice: 149.4903
		},
		{
			open: 149.54,
			close: 149.58,
			volume: 1013,
			time: 1654170000000,
			lowest: 149.54,
			highest: 149.59,
			volumePrice: 149.5718,
			transactionCount: 28,
			voluemPrice: 149.5718
		},
		{
			open: 149.56,
			close: 149.56,
			volume: 1435,
			time: 1654170120000,
			lowest: 149.56,
			highest: 149.56,
			volumePrice: 149.5575,
			transactionCount: 20,
			voluemPrice: 149.5575
		},
		{
			open: 149.52,
			close: 149.5,
			volume: 2827,
			time: 1654170180000,
			lowest: 149.5,
			highest: 149.52,
			volumePrice: 149.5029,
			transactionCount: 34,
			voluemPrice: 149.5029
		},
		{
			open: 149.5,
			close: 149.47,
			volume: 320,
			time: 1654170240000,
			lowest: 149.47,
			highest: 149.5,
			volumePrice: 149.4911,
			transactionCount: 7,
			voluemPrice: 149.4911
		},
		{
			open: 149.45,
			close: 149.51,
			volume: 2598,
			time: 1654170300000,
			lowest: 149.45,
			highest: 149.51,
			volumePrice: 149.4769,
			transactionCount: 36,
			voluemPrice: 149.4769
		},
		{
			open: 149.51,
			close: 149.49,
			volume: 2092,
			time: 1654170360000,
			lowest: 149.49,
			highest: 149.52,
			volumePrice: 149.504,
			transactionCount: 36,
			voluemPrice: 149.504
		},
		{
			open: 149.48,
			close: 149.47,
			volume: 4696,
			time: 1654170420000,
			lowest: 149.47,
			highest: 149.48,
			volumePrice: 149.4698,
			transactionCount: 67,
			voluemPrice: 149.4698
		},
		{
			open: 149.41,
			close: 149.36,
			volume: 1948,
			time: 1654170540000,
			lowest: 149.36,
			highest: 149.41,
			volumePrice: 149.3885,
			transactionCount: 42,
			voluemPrice: 149.3885
		},
		{
			open: 149.36,
			close: 149.37,
			volume: 507,
			time: 1654170600000,
			lowest: 149.36,
			highest: 149.37,
			volumePrice: 149.3616,
			transactionCount: 8,
			voluemPrice: 149.3616
		},
		{
			open: 149.39,
			close: 149.39,
			volume: 391,
			time: 1654170720000,
			lowest: 149.39,
			highest: 149.39,
			volumePrice: 149.3899,
			transactionCount: 6,
			voluemPrice: 149.3899
		},
		{
			open: 149.41,
			close: 149.39,
			volume: 588,
			time: 1654170840000,
			lowest: 149.39,
			highest: 149.41,
			volumePrice: 149.3986,
			transactionCount: 14,
			voluemPrice: 149.3986
		},
		{
			open: 149.38,
			close: 149.38,
			volume: 249,
			time: 1654170900000,
			lowest: 149.38,
			highest: 149.38,
			volumePrice: 149.3804,
			transactionCount: 10,
			voluemPrice: 149.3804
		},
		{
			open: 149.4,
			close: 149.42,
			volume: 919,
			time: 1654171020000,
			lowest: 149.4,
			highest: 149.42,
			volumePrice: 149.4117,
			transactionCount: 15,
			voluemPrice: 149.4117
		},
		{
			open: 149.42,
			close: 149.41,
			volume: 358,
			time: 1654171080000,
			lowest: 149.41,
			highest: 149.42,
			volumePrice: 149.4142,
			transactionCount: 5,
			voluemPrice: 149.4142
		},
		{
			open: 149.4,
			close: 149.3,
			volume: 78258,
			time: 1654171200000,
			lowest: 149.2205,
			highest: 149.56,
			volumePrice: 149.4152,
			transactionCount: 730,
			voluemPrice: 149.4152
		},
		{
			open: 149.37,
			close: 149.39,
			volume: 4205,
			time: 1654171260000,
			lowest: 149.37,
			highest: 149.39,
			volumePrice: 149.3728,
			transactionCount: 257,
			voluemPrice: 149.3728
		},
		{
			open: 149.3118,
			close: 149.35,
			volume: 23977,
			time: 1654171320000,
			lowest: 149.3118,
			highest: 149.47,
			volumePrice: 149.4544,
			transactionCount: 196,
			voluemPrice: 149.4544
		},
		{
			open: 149.34,
			close: 149.34,
			volume: 725,
			time: 1654171380000,
			lowest: 149.34,
			highest: 149.34,
			volumePrice: 149.3408,
			transactionCount: 20,
			voluemPrice: 149.3408
		},
		{
			open: 149.35,
			close: 149.3002,
			volume: 996,
			time: 1654171440000,
			lowest: 149.3002,
			highest: 149.3798,
			volumePrice: 149.3498,
			transactionCount: 20,
			voluemPrice: 149.3498
		},
		{
			open: 149.3,
			close: 149.3,
			volume: 3000,
			time: 1654171500000,
			lowest: 149.3,
			highest: 149.3,
			volumePrice: 149.4347,
			transactionCount: 42,
			voluemPrice: 149.4347
		},
		{
			open: 149.25,
			close: 149.24,
			volume: 7946,
			time: 1654171560000,
			lowest: 149.17,
			highest: 149.25,
			volumePrice: 149.2328,
			transactionCount: 82,
			voluemPrice: 149.2328
		},
		{
			open: 149.1201,
			close: 149.14,
			volume: 1206,
			time: 1654171620000,
			lowest: 149.12,
			highest: 149.14,
			volumePrice: 149.1533,
			transactionCount: 53,
			voluemPrice: 149.1533
		},
		{
			open: 149.16,
			close: 149.16,
			volume: 7280,
			time: 1654171680000,
			lowest: 149.12,
			highest: 149.16,
			volumePrice: 149.1504,
			transactionCount: 56,
			voluemPrice: 149.1504
		},
		{
			open: 149.17,
			close: 149.05,
			volume: 9756,
			time: 1654171740000,
			lowest: 149.05,
			highest: 149.17,
			volumePrice: 149.0876,
			transactionCount: 93,
			voluemPrice: 149.0876
		},
		{
			open: 149.05,
			close: 149.0101,
			volume: 3001,
			time: 1654171800000,
			lowest: 149,
			highest: 149.05,
			volumePrice: 149.0356,
			transactionCount: 97,
			voluemPrice: 149.0356
		},
		{
			open: 149.04,
			close: 149.05,
			volume: 955,
			time: 1654171860000,
			lowest: 149.04,
			highest: 149.0501,
			volumePrice: 149.0609,
			transactionCount: 27,
			voluemPrice: 149.0609
		},
		{
			open: 149.02,
			close: 148.99,
			volume: 7060,
			time: 1654171920000,
			lowest: 148.96,
			highest: 149.1,
			volumePrice: 149.0174,
			transactionCount: 180,
			voluemPrice: 149.0174
		},
		{
			open: 149.02,
			close: 149.03,
			volume: 1414,
			time: 1654171980000,
			lowest: 149,
			highest: 149.0699,
			volumePrice: 149.0284,
			transactionCount: 33,
			voluemPrice: 149.0284
		},
		{
			open: 149.06,
			close: 149.1,
			volume: 6986,
			time: 1654172040000,
			lowest: 149.06,
			highest: 149.13,
			volumePrice: 149.0955,
			transactionCount: 43,
			voluemPrice: 149.0955
		},
		{
			open: 149.1,
			close: 149.26,
			volume: 19198,
			time: 1654172100000,
			lowest: 149.06,
			highest: 149.4299,
			volumePrice: 149.2996,
			transactionCount: 178,
			voluemPrice: 149.2996
		},
		{
			open: 149.33,
			close: 149.0326,
			volume: 20231,
			time: 1654172160000,
			lowest: 149.0326,
			highest: 149.33,
			volumePrice: 149.1876,
			transactionCount: 84,
			voluemPrice: 149.1876
		},
		{
			open: 149.0307,
			close: 148.91,
			volume: 15628,
			time: 1654172220000,
			lowest: 148.85,
			highest: 149.1,
			volumePrice: 148.9598,
			transactionCount: 191,
			voluemPrice: 148.9598
		},
		{
			open: 148.9899,
			close: 149.1,
			volume: 9250,
			time: 1654172280000,
			lowest: 148.87,
			highest: 149.1101,
			volumePrice: 149.0659,
			transactionCount: 66,
			voluemPrice: 149.0659
		},
		{
			open: 149.09,
			close: 149.09,
			volume: 1162,
			time: 1654172340000,
			lowest: 149.09,
			highest: 149.09,
			volumePrice: 149.091,
			transactionCount: 33,
			voluemPrice: 149.091
		},
		{
			open: 149.1,
			close: 149.2899,
			volume: 18123,
			time: 1654172400000,
			lowest: 149.09,
			highest: 149.2899,
			volumePrice: 149.1442,
			transactionCount: 68,
			voluemPrice: 149.1442
		},
		{
			open: 149.21,
			close: 149.19,
			volume: 4354,
			time: 1654172460000,
			lowest: 149.1,
			highest: 149.21,
			volumePrice: 149.13,
			transactionCount: 44,
			voluemPrice: 149.13
		},
		{
			open: 149.19,
			close: 149.1,
			volume: 2702,
			time: 1654172520000,
			lowest: 149.09,
			highest: 149.19,
			volumePrice: 149.1381,
			transactionCount: 62,
			voluemPrice: 149.1381
		},
		{
			open: 149.1,
			close: 149.1,
			volume: 1075,
			time: 1654172580000,
			lowest: 149.1,
			highest: 149.15,
			volumePrice: 149.1346,
			transactionCount: 20,
			voluemPrice: 149.1346
		},
		{
			open: 149.15,
			close: 149.15,
			volume: 1894,
			time: 1654172640000,
			lowest: 149.15,
			highest: 149.15,
			volumePrice: 149.1433,
			transactionCount: 41,
			voluemPrice: 149.1433
		},
		{
			open: 149.14,
			close: 149.09,
			volume: 1449,
			time: 1654172700000,
			lowest: 149.09,
			highest: 149.14,
			volumePrice: 149.1085,
			transactionCount: 40,
			voluemPrice: 149.1085
		},
		{
			open: 149.1,
			close: 149.18,
			volume: 8884,
			time: 1654172760000,
			lowest: 149.1,
			highest: 149.19,
			volumePrice: 149.1709,
			transactionCount: 46,
			voluemPrice: 149.1709
		},
		{
			open: 149.18,
			close: 149.15,
			volume: 1451,
			time: 1654172820000,
			lowest: 149.15,
			highest: 149.18,
			volumePrice: 149.1767,
			transactionCount: 42,
			voluemPrice: 149.1767
		},
		{
			open: 149.15,
			close: 149.14,
			volume: 5873,
			time: 1654172940000,
			lowest: 149.11,
			highest: 149.15,
			volumePrice: 149.1146,
			transactionCount: 19,
			voluemPrice: 149.1146
		},
		{
			open: 149.1202,
			close: 149.05,
			volume: 11188,
			time: 1654173000000,
			lowest: 149.05,
			highest: 149.19,
			volumePrice: 149.1186,
			transactionCount: 110,
			voluemPrice: 149.1186
		},
		{
			open: 149.05,
			close: 149.1,
			volume: 3144,
			time: 1654173060000,
			lowest: 149.04,
			highest: 149.18,
			volumePrice: 149.0898,
			transactionCount: 62,
			voluemPrice: 149.0898
		},
		{
			open: 149.1,
			close: 149.04,
			volume: 21039,
			time: 1654173120000,
			lowest: 149,
			highest: 149.14,
			volumePrice: 149.1051,
			transactionCount: 100,
			voluemPrice: 149.1051
		},
		{
			open: 149,
			close: 148.94,
			volume: 4831,
			time: 1654173180000,
			lowest: 148.93,
			highest: 149,
			volumePrice: 148.9729,
			transactionCount: 88,
			voluemPrice: 148.9729
		},
		{
			open: 148.9201,
			close: 148.9,
			volume: 2957,
			time: 1654173240000,
			lowest: 148.9,
			highest: 148.9201,
			volumePrice: 148.8997,
			transactionCount: 106,
			voluemPrice: 148.8997
		},
		{
			open: 148.81,
			close: 148.95,
			volume: 2928,
			time: 1654173300000,
			lowest: 148.81,
			highest: 148.95,
			volumePrice: 148.8969,
			transactionCount: 75,
			voluemPrice: 148.8969
		},
		{
			open: 148.9,
			close: 148.9799,
			volume: 7550,
			time: 1654173360000,
			lowest: 148.9,
			highest: 148.98,
			volumePrice: 148.9708,
			transactionCount: 48,
			voluemPrice: 148.9708
		},
		{
			open: 148.94,
			close: 149.01,
			volume: 10774,
			time: 1654173420000,
			lowest: 148.9,
			highest: 149.02,
			volumePrice: 148.9499,
			transactionCount: 119,
			voluemPrice: 148.9499
		},
		{
			open: 149.02,
			close: 149.01,
			volume: 2405,
			time: 1654173480000,
			lowest: 149,
			highest: 149.03,
			volumePrice: 149.0051,
			transactionCount: 57,
			voluemPrice: 149.0051
		},
		{
			open: 149,
			close: 149,
			volume: 4969,
			time: 1654173540000,
			lowest: 149,
			highest: 149.05,
			volumePrice: 149.0208,
			transactionCount: 56,
			voluemPrice: 149.0208
		},
		{
			open: 148.99,
			close: 149,
			volume: 7528,
			time: 1654173600000,
			lowest: 148.99,
			highest: 149.03,
			volumePrice: 149.0152,
			transactionCount: 77,
			voluemPrice: 149.0152
		},
		{
			open: 148.98,
			close: 149.04,
			volume: 12356,
			time: 1654173660000,
			lowest: 148.98,
			highest: 149.11,
			volumePrice: 149.0748,
			transactionCount: 122,
			voluemPrice: 149.0748
		},
		{
			open: 149.08,
			close: 149.02,
			volume: 1998,
			time: 1654173720000,
			lowest: 149.02,
			highest: 149.08,
			volumePrice: 149.0493,
			transactionCount: 38,
			voluemPrice: 149.0493
		},
		{
			open: 149.04,
			close: 148.98,
			volume: 14619,
			time: 1654173780000,
			lowest: 148.98,
			highest: 149.04,
			volumePrice: 148.9949,
			transactionCount: 63,
			voluemPrice: 148.9949
		},
		{
			open: 148.97,
			close: 148.89,
			volume: 4404,
			time: 1654173840000,
			lowest: 148.89,
			highest: 149,
			volumePrice: 148.9501,
			transactionCount: 111,
			voluemPrice: 148.9501
		},
		{
			open: 148.89,
			close: 148.8,
			volume: 16695,
			time: 1654173900000,
			lowest: 148.76,
			highest: 148.89,
			volumePrice: 148.7979,
			transactionCount: 200,
			voluemPrice: 148.7979
		},
		{
			open: 148.77,
			close: 148.77,
			volume: 5150,
			time: 1654173960000,
			lowest: 148.77,
			highest: 148.82,
			volumePrice: 148.7833,
			transactionCount: 63,
			voluemPrice: 148.7833
		},
		{
			open: 148.75,
			close: 148.89,
			volume: 8634,
			time: 1654174020000,
			lowest: 148.75,
			highest: 148.89,
			volumePrice: 148.8203,
			transactionCount: 100,
			voluemPrice: 148.8203
		},
		{
			open: 148.89,
			close: 148.88,
			volume: 2510,
			time: 1654174080000,
			lowest: 148.88,
			highest: 148.94,
			volumePrice: 148.8935,
			transactionCount: 57,
			voluemPrice: 148.8935
		},
		{
			open: 148.8368,
			close: 148.88,
			volume: 2129,
			time: 1654174140000,
			lowest: 148.8368,
			highest: 148.9799,
			volumePrice: 148.9067,
			transactionCount: 62,
			voluemPrice: 148.9067
		},
		{
			open: 148.9,
			close: 148.98,
			volume: 7076,
			time: 1654174200000,
			lowest: 148.9,
			highest: 148.98,
			volumePrice: 148.912,
			transactionCount: 58,
			voluemPrice: 148.912
		},
		{
			open: 149,
			close: 148.97,
			volume: 8178,
			time: 1654174260000,
			lowest: 148.97,
			highest: 149.06,
			volumePrice: 149.002,
			transactionCount: 92,
			voluemPrice: 149.002
		},
		{
			open: 148.8828,
			close: 148.8828,
			volume: 574,
			time: 1654174320000,
			lowest: 148.8828,
			highest: 148.8828,
			volumePrice: 148.9059,
			transactionCount: 27,
			voluemPrice: 148.9059
		},
		{
			open: 148.92,
			close: 148.94,
			volume: 4719,
			time: 1654174380000,
			lowest: 148.9112,
			highest: 148.97,
			volumePrice: 148.9486,
			transactionCount: 99,
			voluemPrice: 148.9486
		},
		{
			open: 148.94,
			close: 148.96,
			volume: 969,
			time: 1654174440000,
			lowest: 148.94,
			highest: 148.96,
			volumePrice: 148.9392,
			transactionCount: 35,
			voluemPrice: 148.9392
		},
		{
			open: 148.99,
			close: 148.98,
			volume: 3555,
			time: 1654174500000,
			lowest: 148.98,
			highest: 149.02,
			volumePrice: 148.9988,
			transactionCount: 71,
			voluemPrice: 148.9988
		},
		{
			open: 148.98,
			close: 148.9101,
			volume: 8716,
			time: 1654174560000,
			lowest: 148.9101,
			highest: 148.99,
			volumePrice: 148.9546,
			transactionCount: 70,
			voluemPrice: 148.9546
		},
		{
			open: 148.92,
			close: 148.92,
			volume: 27686,
			time: 1654174620000,
			lowest: 148.9,
			highest: 148.9201,
			volumePrice: 148.92,
			transactionCount: 47,
			voluemPrice: 148.92
		},
		{
			open: 148.9,
			close: 148.77,
			volume: 4365,
			time: 1654174680000,
			lowest: 148.77,
			highest: 148.9,
			volumePrice: 148.8332,
			transactionCount: 121,
			voluemPrice: 148.8332
		},
		{
			open: 148.77,
			close: 148.7,
			volume: 28137,
			time: 1654174740000,
			lowest: 148.66,
			highest: 148.77,
			volumePrice: 148.7172,
			transactionCount: 202,
			voluemPrice: 148.7172
		},
		{
			open: 148.74,
			close: 148.82,
			volume: 11709,
			time: 1654174800000,
			lowest: 148.74,
			highest: 148.82,
			volumePrice: 148.8114,
			transactionCount: 49,
			voluemPrice: 148.8114
		},
		{
			open: 148.8618,
			close: 148.8,
			volume: 12559,
			time: 1654174860000,
			lowest: 148.8,
			highest: 148.9,
			volumePrice: 148.8391,
			transactionCount: 77,
			voluemPrice: 148.8391
		},
		{
			open: 148.76,
			close: 148.4899,
			volume: 54076,
			time: 1654174920000,
			lowest: 148.48,
			highest: 148.92,
			volumePrice: 148.757,
			transactionCount: 574,
			voluemPrice: 148.757
		},
		{
			open: 148.45,
			close: 148.61,
			volume: 80485,
			time: 1654174980000,
			lowest: 147.87,
			highest: 148.7,
			volumePrice: 148.2427,
			transactionCount: 1121,
			voluemPrice: 148.2427
		},
		{
			open: 148.6199,
			close: 148.23,
			volume: 73238,
			time: 1654175040000,
			lowest: 148.23,
			highest: 148.68,
			volumePrice: 148.4713,
			transactionCount: 657,
			voluemPrice: 148.4713
		},
		{
			open: 148.25,
			close: 148.25,
			volume: 19979,
			time: 1654175100000,
			lowest: 148.21,
			highest: 148.43,
			volumePrice: 148.3651,
			transactionCount: 314,
			voluemPrice: 148.3651
		},
		{
			open: 148.22,
			close: 148.27,
			volume: 40314,
			time: 1654175160000,
			lowest: 148.06,
			highest: 148.3,
			volumePrice: 148.1987,
			transactionCount: 343,
			voluemPrice: 148.1987
		},
		{
			open: 148.25,
			close: 148.1,
			volume: 12600,
			time: 1654175220000,
			lowest: 148.05,
			highest: 148.27,
			volumePrice: 148.1117,
			transactionCount: 207,
			voluemPrice: 148.1117
		},
		{
			open: 148.07,
			close: 148.07,
			volume: 21365,
			time: 1654175280000,
			lowest: 148.02,
			highest: 148.26,
			volumePrice: 148.0971,
			transactionCount: 320,
			voluemPrice: 148.0971
		},
		{
			open: 148.05,
			close: 147.99,
			volume: 33206,
			time: 1654175340000,
			lowest: 147.82,
			highest: 148.13,
			volumePrice: 147.9964,
			transactionCount: 520,
			voluemPrice: 147.9964
		},
		{
			open: 147.99,
			close: 148.02,
			volume: 17195,
			time: 1654175400000,
			lowest: 147.8713,
			highest: 148.13,
			volumePrice: 148.0384,
			transactionCount: 218,
			voluemPrice: 148.0384
		},
		{
			open: 148.03,
			close: 147.8502,
			volume: 17089,
			time: 1654175460000,
			lowest: 147.85,
			highest: 148.07,
			volumePrice: 147.9631,
			transactionCount: 273,
			voluemPrice: 147.9631
		},
		{
			open: 147.91,
			close: 147.73,
			volume: 49868,
			time: 1654175520000,
			lowest: 147.71,
			highest: 147.93,
			volumePrice: 147.8198,
			transactionCount: 369,
			voluemPrice: 147.8198
		},
		{
			open: 147.7,
			close: 147.81,
			volume: 53483,
			time: 1654175580000,
			lowest: 147.61,
			highest: 147.85,
			volumePrice: 147.7324,
			transactionCount: 389,
			voluemPrice: 147.7324
		},
		{
			open: 147.81,
			close: 148,
			volume: 34086,
			time: 1654175640000,
			lowest: 147.81,
			highest: 148,
			volumePrice: 147.8909,
			transactionCount: 379,
			voluemPrice: 147.8909
		},
		{
			open: 148.02,
			close: 147.65,
			volume: 36237,
			time: 1654175700000,
			lowest: 147.64,
			highest: 148.05,
			volumePrice: 147.8834,
			transactionCount: 300,
			voluemPrice: 147.8834
		},
		{
			open: 147.65,
			close: 147.84,
			volume: 33697,
			time: 1654175760000,
			lowest: 147.65,
			highest: 147.84,
			volumePrice: 147.7241,
			transactionCount: 294,
			voluemPrice: 147.7241
		},
		{
			open: 147.82,
			close: 147.8261,
			volume: 23857,
			time: 1654175820000,
			lowest: 147.7613,
			highest: 148.09,
			volumePrice: 147.937,
			transactionCount: 167,
			voluemPrice: 147.937
		},
		{
			open: 147.97,
			close: 147.98,
			volume: 16737,
			time: 1654175880000,
			lowest: 147.9,
			highest: 148.05,
			volumePrice: 148.0008,
			transactionCount: 176,
			voluemPrice: 148.0008
		},
		{
			open: 148,
			close: 147.91,
			volume: 6034,
			time: 1654175940000,
			lowest: 147.91,
			highest: 148,
			volumePrice: 147.9346,
			transactionCount: 114,
			voluemPrice: 147.9346
		},
		{
			open: 147.96,
			close: 147.94,
			volume: 29057,
			time: 1654176000000,
			lowest: 147.91,
			highest: 148,
			volumePrice: 147.9352,
			transactionCount: 175,
			voluemPrice: 147.9352
		},
		{
			open: 147.97,
			close: 148.0901,
			volume: 8740,
			time: 1654176060000,
			lowest: 147.96,
			highest: 148.0901,
			volumePrice: 148.0203,
			transactionCount: 158,
			voluemPrice: 148.0203
		},
		{
			open: 148.1,
			close: 147.96,
			volume: 17583,
			time: 1654176120000,
			lowest: 147.96,
			highest: 148.15,
			volumePrice: 148.0831,
			transactionCount: 201,
			voluemPrice: 148.0831
		},
		{
			open: 147.95,
			close: 147.92,
			volume: 36704,
			time: 1654176180000,
			lowest: 147.91,
			highest: 147.95,
			volumePrice: 147.9383,
			transactionCount: 166,
			voluemPrice: 147.9383
		},
		{
			open: 147.96,
			close: 147.92,
			volume: 5320,
			time: 1654176240000,
			lowest: 147.91,
			highest: 148,
			volumePrice: 147.9458,
			transactionCount: 123,
			voluemPrice: 147.9458
		},
		{
			open: 147.92,
			close: 147.85,
			volume: 27562,
			time: 1654176300000,
			lowest: 147.61,
			highest: 147.97,
			volumePrice: 147.78,
			transactionCount: 295,
			voluemPrice: 147.78
		},
		{
			open: 147.85,
			close: 147.85,
			volume: 13610,
			time: 1654176360000,
			lowest: 147.7,
			highest: 147.96,
			volumePrice: 147.8327,
			transactionCount: 214,
			voluemPrice: 147.8327
		},
		{
			open: 147.94,
			close: 147.96,
			volume: 8760,
			time: 1654176420000,
			lowest: 147.7,
			highest: 147.99,
			volumePrice: 147.8681,
			transactionCount: 142,
			voluemPrice: 147.8681
		},
		{
			open: 147.83,
			close: 147.84,
			volume: 9843,
			time: 1654176480000,
			lowest: 147.71,
			highest: 147.9,
			volumePrice: 147.7858,
			transactionCount: 176,
			voluemPrice: 147.7858
		},
		{
			open: 147.84,
			close: 147.81,
			volume: 4732,
			time: 1654176540000,
			lowest: 147.81,
			highest: 147.85,
			volumePrice: 147.8442,
			transactionCount: 125,
			voluemPrice: 147.8442
		},
		{
			open: 147.83,
			close: 147.49,
			volume: 1004594,
			time: 1654176600000,
			lowest: 147.32,
			highest: 148.16,
			volumePrice: 147.7687,
			transactionCount: 13650,
			voluemPrice: 147.7687
		},
		{
			open: 147.49,
			close: 147.06,
			volume: 586588,
			time: 1654176660000,
			lowest: 146.86,
			highest: 147.61,
			volumePrice: 147.1506,
			transactionCount: 6059,
			voluemPrice: 147.1506
		},
		{
			open: 147.07,
			close: 147.42,
			volume: 435073,
			time: 1654176720000,
			lowest: 147.07,
			highest: 147.47,
			volumePrice: 147.2199,
			transactionCount: 4263,
			voluemPrice: 147.2199
		},
		{
			open: 147.44,
			close: 147.51,
			volume: 290344,
			time: 1654176780000,
			lowest: 147.15,
			highest: 147.59,
			volumePrice: 147.3615,
			transactionCount: 2818,
			voluemPrice: 147.3615
		},
		{
			open: 147.5061,
			close: 147.75,
			volume: 419700,
			time: 1654176840000,
			lowest: 147.35,
			highest: 147.92,
			volumePrice: 147.6379,
			transactionCount: 3975,
			voluemPrice: 147.6379
		},
		{
			open: 147.75,
			close: 147.56,
			volume: 545226,
			time: 1654176900000,
			lowest: 147.19,
			highest: 147.78,
			volumePrice: 147.5049,
			transactionCount: 5721,
			voluemPrice: 147.5049
		},
		{
			open: 147.535,
			close: 147.17,
			volume: 381759,
			time: 1654176960000,
			lowest: 147.11,
			highest: 147.72,
			volumePrice: 147.395,
			transactionCount: 3982,
			voluemPrice: 147.395
		},
		{
			open: 147.2,
			close: 147.6,
			volume: 313771,
			time: 1654177020000,
			lowest: 147.13,
			highest: 147.6,
			volumePrice: 147.4129,
			transactionCount: 2805,
			voluemPrice: 147.4129
		},
		{
			open: 147.5947,
			close: 147.6799,
			volume: 287321,
			time: 1654177080000,
			lowest: 147.52,
			highest: 147.78,
			volumePrice: 147.6656,
			transactionCount: 2629,
			voluemPrice: 147.6656
		},
		{
			open: 147.67,
			close: 147.92,
			volume: 281032,
			time: 1654177140000,
			lowest: 147.53,
			highest: 147.92,
			volumePrice: 147.7297,
			transactionCount: 2400,
			voluemPrice: 147.7297
		},
		{
			open: 147.92,
			close: 147.93,
			volume: 282241,
			time: 1654177200000,
			lowest: 147.51,
			highest: 147.94,
			volumePrice: 147.7268,
			transactionCount: 2699,
			voluemPrice: 147.7268
		},
		{
			open: 147.92,
			close: 147.75,
			volume: 272133,
			time: 1654177260000,
			lowest: 147.64,
			highest: 147.94,
			volumePrice: 147.8215,
			transactionCount: 2224,
			voluemPrice: 147.8215
		},
		{
			open: 147.755,
			close: 148.0864,
			volume: 307344,
			time: 1654177320000,
			lowest: 147.59,
			highest: 148.155,
			volumePrice: 147.9191,
			transactionCount: 2905,
			voluemPrice: 147.9191
		},
		{
			open: 148.09,
			close: 148.189,
			volume: 309602,
			time: 1654177380000,
			lowest: 147.96,
			highest: 148.27,
			volumePrice: 148.1041,
			transactionCount: 3177,
			voluemPrice: 148.1041
		},
		{
			open: 148.17,
			close: 148.25,
			volume: 250489,
			time: 1654177440000,
			lowest: 148.1,
			highest: 148.32,
			volumePrice: 148.2129,
			transactionCount: 2503,
			voluemPrice: 148.2129
		},
		{
			open: 148.2389,
			close: 148.25,
			volume: 211322,
			time: 1654177500000,
			lowest: 148.05,
			highest: 148.29,
			volumePrice: 148.2085,
			transactionCount: 2322,
			voluemPrice: 148.2085
		},
		{
			open: 148.24,
			close: 148.17,
			volume: 220524,
			time: 1654177560000,
			lowest: 147.93,
			highest: 148.2799,
			volumePrice: 148.0946,
			transactionCount: 2238,
			voluemPrice: 148.0946
		},
		{
			open: 148.1533,
			close: 148.08,
			volume: 200895,
			time: 1654177620000,
			lowest: 148,
			highest: 148.27,
			volumePrice: 148.1508,
			transactionCount: 1990,
			voluemPrice: 148.1508
		},
		{
			open: 148.07,
			close: 148.06,
			volume: 187718,
			time: 1654177680000,
			lowest: 147.92,
			highest: 148.25,
			volumePrice: 148.1072,
			transactionCount: 2012,
			voluemPrice: 148.1072
		},
		{
			open: 148.08,
			close: 148.4,
			volume: 954437,
			time: 1654177740000,
			lowest: 147.87,
			highest: 148.42,
			volumePrice: 148.0122,
			transactionCount: 2877,
			voluemPrice: 148.0122
		},
		{
			open: 148.39,
			close: 148.3,
			volume: 176029,
			time: 1654177800000,
			lowest: 148.18,
			highest: 148.4,
			volumePrice: 148.295,
			transactionCount: 1840,
			voluemPrice: 148.295
		},
		{
			open: 148.3,
			close: 148.1901,
			volume: 199095,
			time: 1654177860000,
			lowest: 148.18,
			highest: 148.43,
			volumePrice: 148.3012,
			transactionCount: 1861,
			voluemPrice: 148.3012
		},
		{
			open: 148.18,
			close: 148.25,
			volume: 190349,
			time: 1654177920000,
			lowest: 148.16,
			highest: 148.39,
			volumePrice: 148.2946,
			transactionCount: 1877,
			voluemPrice: 148.2946
		},
		{
			open: 148.25,
			close: 148.3862,
			volume: 174482,
			time: 1654177980000,
			lowest: 148.2,
			highest: 148.4697,
			volumePrice: 148.357,
			transactionCount: 1650,
			voluemPrice: 148.357
		},
		{
			open: 148.381,
			close: 148.23,
			volume: 230454,
			time: 1654178040000,
			lowest: 148.22,
			highest: 148.49,
			volumePrice: 148.3863,
			transactionCount: 1957,
			voluemPrice: 148.3863
		},
		{
			open: 148.23,
			close: 147.84,
			volume: 258727,
			time: 1654178100000,
			lowest: 147.8334,
			highest: 148.265,
			volumePrice: 148.0331,
			transactionCount: 2714,
			voluemPrice: 148.0331
		},
		{
			open: 147.85,
			close: 147.66,
			volume: 255805,
			time: 1654178160000,
			lowest: 147.5502,
			highest: 147.85,
			volumePrice: 147.7145,
			transactionCount: 2277,
			voluemPrice: 147.7145
		},
		{
			open: 147.65,
			close: 147.7677,
			volume: 246222,
			time: 1654178220000,
			lowest: 147.58,
			highest: 147.8,
			volumePrice: 147.6948,
			transactionCount: 2050,
			voluemPrice: 147.6948
		},
		{
			open: 147.765,
			close: 148.065,
			volume: 228236,
			time: 1654178280000,
			lowest: 147.74,
			highest: 148.188,
			volumePrice: 147.992,
			transactionCount: 1666,
			voluemPrice: 147.992
		},
		{
			open: 148.06,
			close: 148.1601,
			volume: 184128,
			time: 1654178340000,
			lowest: 148.01,
			highest: 148.26,
			volumePrice: 148.1375,
			transactionCount: 1317,
			voluemPrice: 148.1375
		},
		{
			open: 148.16,
			close: 148.45,
			volume: 300419,
			time: 1654178400000,
			lowest: 147.93,
			highest: 148.47,
			volumePrice: 148.1953,
			transactionCount: 2784,
			voluemPrice: 148.1953
		},
		{
			open: 148.44,
			close: 148.4,
			volume: 391544,
			time: 1654178460000,
			lowest: 148.34,
			highest: 148.65,
			volumePrice: 148.4954,
			transactionCount: 2912,
			voluemPrice: 148.4954
		},
		{
			open: 148.385,
			close: 148.48,
			volume: 198421,
			time: 1654178520000,
			lowest: 148.38,
			highest: 148.59,
			volumePrice: 148.5009,
			transactionCount: 1586,
			voluemPrice: 148.5009
		},
		{
			open: 148.48,
			close: 148.23,
			volume: 177690,
			time: 1654178580000,
			lowest: 148.13,
			highest: 148.48,
			volumePrice: 148.3068,
			transactionCount: 1643,
			voluemPrice: 148.3068
		},
		{
			open: 148.23,
			close: 148.17,
			volume: 808447,
			time: 1654178640000,
			lowest: 148.02,
			highest: 148.28,
			volumePrice: 148.2727,
			transactionCount: 1557,
			voluemPrice: 148.2727
		},
		{
			open: 148.19,
			close: 148.01,
			volume: 185304,
			time: 1654178700000,
			lowest: 147.946,
			highest: 148.19,
			volumePrice: 148.0428,
			transactionCount: 1997,
			voluemPrice: 148.0428
		},
		{
			open: 147.99,
			close: 148.24,
			volume: 240495,
			time: 1654178760000,
			lowest: 147.84,
			highest: 148.29,
			volumePrice: 148.0376,
			transactionCount: 2044,
			voluemPrice: 148.0376
		},
		{
			open: 148.232,
			close: 148.17,
			volume: 155738,
			time: 1654178820000,
			lowest: 148.091,
			highest: 148.35,
			volumePrice: 148.2017,
			transactionCount: 1420,
			voluemPrice: 148.2017
		},
		{
			open: 148.1401,
			close: 147.99,
			volume: 166766,
			time: 1654178880000,
			lowest: 147.94,
			highest: 148.21,
			volumePrice: 148.0554,
			transactionCount: 1438,
			voluemPrice: 148.0554
		},
		{
			open: 147.98,
			close: 147.99,
			volume: 179903,
			time: 1654178940000,
			lowest: 147.86,
			highest: 148.15,
			volumePrice: 147.9929,
			transactionCount: 1494,
			voluemPrice: 147.9929
		},
		{
			open: 147.98,
			close: 148.1,
			volume: 174036,
			time: 1654179000000,
			lowest: 147.88,
			highest: 148.14,
			volumePrice: 148.0265,
			transactionCount: 1766,
			voluemPrice: 148.0265
		},
		{
			open: 148.09,
			close: 148.18,
			volume: 218208,
			time: 1654179060000,
			lowest: 148.071,
			highest: 148.38,
			volumePrice: 148.2492,
			transactionCount: 1904,
			voluemPrice: 148.2492
		},
		{
			open: 148.18,
			close: 148.46,
			volume: 207617,
			time: 1654179120000,
			lowest: 148.17,
			highest: 148.47,
			volumePrice: 148.3241,
			transactionCount: 1804,
			voluemPrice: 148.3241
		},
		{
			open: 148.46,
			close: 148.96,
			volume: 459203,
			time: 1654179180000,
			lowest: 148.4327,
			highest: 148.9691,
			volumePrice: 148.7228,
			transactionCount: 3875,
			voluemPrice: 148.7228
		},
		{
			open: 148.96,
			close: 148.76,
			volume: 321462,
			time: 1654179240000,
			lowest: 148.74,
			highest: 149.03,
			volumePrice: 148.8934,
			transactionCount: 2411,
			voluemPrice: 148.8934
		},
		{
			open: 148.75,
			close: 148.71,
			volume: 241275,
			time: 1654179300000,
			lowest: 148.6318,
			highest: 148.85,
			volumePrice: 148.7201,
			transactionCount: 2132,
			voluemPrice: 148.7201
		},
		{
			open: 148.72,
			close: 148.8719,
			volume: 195432,
			time: 1654179360000,
			lowest: 148.66,
			highest: 148.899,
			volumePrice: 148.7866,
			transactionCount: 1472,
			voluemPrice: 148.7866
		},
		{
			open: 148.88,
			close: 148.63,
			volume: 274568,
			time: 1654179420000,
			lowest: 148.56,
			highest: 148.92,
			volumePrice: 148.6925,
			transactionCount: 2557,
			voluemPrice: 148.6925
		},
		{
			open: 148.63,
			close: 148.62,
			volume: 128467,
			time: 1654179480000,
			lowest: 148.5504,
			highest: 148.73,
			volumePrice: 148.6462,
			transactionCount: 1227,
			voluemPrice: 148.6462
		},
		{
			open: 148.635,
			close: 148.1732,
			volume: 220119,
			time: 1654179540000,
			lowest: 148.135,
			highest: 148.66,
			volumePrice: 148.4034,
			transactionCount: 2299,
			voluemPrice: 148.4034
		},
		{
			open: 148.18,
			close: 148.32,
			volume: 232722,
			time: 1654179600000,
			lowest: 148.1,
			highest: 148.37,
			volumePrice: 148.2562,
			transactionCount: 1852,
			voluemPrice: 148.2562
		},
		{
			open: 148.3271,
			close: 147.87,
			volume: 345352,
			time: 1654179660000,
			lowest: 147.775,
			highest: 148.335,
			volumePrice: 147.9844,
			transactionCount: 3155,
			voluemPrice: 147.9844
		},
		{
			open: 147.87,
			close: 147.84,
			volume: 240966,
			time: 1654179720000,
			lowest: 147.67,
			highest: 147.98,
			volumePrice: 147.8427,
			transactionCount: 2159,
			voluemPrice: 147.8427
		},
		{
			open: 147.84,
			close: 147.58,
			volume: 289525,
			time: 1654179780000,
			lowest: 147.5747,
			highest: 147.9156,
			volumePrice: 147.7855,
			transactionCount: 2335,
			voluemPrice: 147.7855
		},
		{
			open: 147.59,
			close: 147.63,
			volume: 213564,
			time: 1654179840000,
			lowest: 147.5704,
			highest: 147.8,
			volumePrice: 147.7028,
			transactionCount: 1864,
			voluemPrice: 147.7028
		},
		{
			open: 147.64,
			close: 147.69,
			volume: 138483,
			time: 1654179900000,
			lowest: 147.6,
			highest: 147.79,
			volumePrice: 147.6989,
			transactionCount: 1261,
			voluemPrice: 147.6989
		},
		{
			open: 147.68,
			close: 147.8188,
			volume: 238266,
			time: 1654179960000,
			lowest: 147.65,
			highest: 147.915,
			volumePrice: 147.846,
			transactionCount: 1765,
			voluemPrice: 147.846
		},
		{
			open: 147.815,
			close: 147.5899,
			volume: 285200,
			time: 1654180020000,
			lowest: 147.56,
			highest: 147.82,
			volumePrice: 147.6522,
			transactionCount: 2391,
			voluemPrice: 147.6522
		},
		{
			open: 147.571,
			close: 147.3,
			volume: 482843,
			time: 1654180080000,
			lowest: 147.24,
			highest: 147.58,
			volumePrice: 147.3857,
			transactionCount: 4361,
			voluemPrice: 147.3857
		},
		{
			open: 147.3,
			close: 147.41,
			volume: 366838,
			time: 1654180140000,
			lowest: 147.3,
			highest: 147.53,
			volumePrice: 147.4345,
			transactionCount: 3211,
			voluemPrice: 147.4345
		},
		{
			open: 147.41,
			close: 147.635,
			volume: 287329,
			time: 1654180200000,
			lowest: 147.34,
			highest: 147.71,
			volumePrice: 147.5504,
			transactionCount: 2659,
			voluemPrice: 147.5504
		},
		{
			open: 147.64,
			close: 147.53,
			volume: 190812,
			time: 1654180260000,
			lowest: 147.43,
			highest: 147.72,
			volumePrice: 147.5746,
			transactionCount: 1747,
			voluemPrice: 147.5746
		},
		{
			open: 147.5,
			close: 147.415,
			volume: 186691,
			time: 1654180320000,
			lowest: 147.395,
			highest: 147.61,
			volumePrice: 147.4901,
			transactionCount: 1439,
			voluemPrice: 147.4901
		},
		{
			open: 147.41,
			close: 147.47,
			volume: 171129,
			time: 1654180380000,
			lowest: 147.37,
			highest: 147.56,
			volumePrice: 147.4637,
			transactionCount: 1298,
			voluemPrice: 147.4637
		},
		{
			open: 147.48,
			close: 147.55,
			volume: 230305,
			time: 1654180440000,
			lowest: 147.47,
			highest: 147.72,
			volumePrice: 147.615,
			transactionCount: 1996,
			voluemPrice: 147.615
		},
		{
			open: 147.55,
			close: 147.7311,
			volume: 212997,
			time: 1654180500000,
			lowest: 147.47,
			highest: 147.84,
			volumePrice: 147.6434,
			transactionCount: 1811,
			voluemPrice: 147.6434
		},
		{
			open: 147.7367,
			close: 147.6709,
			volume: 162298,
			time: 1654180560000,
			lowest: 147.54,
			highest: 147.8,
			volumePrice: 147.6583,
			transactionCount: 1438,
			voluemPrice: 147.6583
		},
		{
			open: 147.66,
			close: 147.7501,
			volume: 162180,
			time: 1654180620000,
			lowest: 147.5229,
			highest: 147.7501,
			volumePrice: 147.6248,
			transactionCount: 1510,
			voluemPrice: 147.6248
		},
		{
			open: 147.72,
			close: 147.93,
			volume: 202368,
			time: 1654180680000,
			lowest: 147.68,
			highest: 148.04,
			volumePrice: 147.8908,
			transactionCount: 1702,
			voluemPrice: 147.8908
		},
		{
			open: 147.91,
			close: 147.98,
			volume: 133771,
			time: 1654180740000,
			lowest: 147.76,
			highest: 147.99,
			volumePrice: 147.875,
			transactionCount: 1208,
			voluemPrice: 147.875
		},
		{
			open: 147.98,
			close: 147.88,
			volume: 184961,
			time: 1654180800000,
			lowest: 147.8422,
			highest: 148.15,
			volumePrice: 148.0019,
			transactionCount: 1526,
			voluemPrice: 148.0019
		},
		{
			open: 147.89,
			close: 147.89,
			volume: 103341,
			time: 1654180860000,
			lowest: 147.8,
			highest: 147.92,
			volumePrice: 147.8792,
			transactionCount: 1070,
			voluemPrice: 147.8792
		},
		{
			open: 147.9,
			close: 147.965,
			volume: 153280,
			time: 1654180920000,
			lowest: 147.89,
			highest: 148.1401,
			volumePrice: 148.0284,
			transactionCount: 1500,
			voluemPrice: 148.0284
		},
		{
			open: 147.965,
			close: 148.025,
			volume: 138469,
			time: 1654180980000,
			lowest: 147.9,
			highest: 148.18,
			volumePrice: 148.0764,
			transactionCount: 1173,
			voluemPrice: 148.0764
		},
		{
			open: 148.03,
			close: 148.0841,
			volume: 114822,
			time: 1654181040000,
			lowest: 148.02,
			highest: 148.2,
			volumePrice: 148.1118,
			transactionCount: 995,
			voluemPrice: 148.1118
		},
		{
			open: 148.07,
			close: 148.0113,
			volume: 121738,
			time: 1654181100000,
			lowest: 147.92,
			highest: 148.09,
			volumePrice: 148.0021,
			transactionCount: 1243,
			voluemPrice: 148.0021
		},
		{
			open: 148.0101,
			close: 148.1841,
			volume: 189401,
			time: 1654181160000,
			lowest: 147.8215,
			highest: 148.185,
			volumePrice: 147.9826,
			transactionCount: 1636,
			voluemPrice: 147.9826
		},
		{
			open: 148.18,
			close: 148.14,
			volume: 136643,
			time: 1654181220000,
			lowest: 148.06,
			highest: 148.22,
			volumePrice: 148.1436,
			transactionCount: 1095,
			voluemPrice: 148.1436
		},
		{
			open: 148.16,
			close: 148.17,
			volume: 103016,
			time: 1654181280000,
			lowest: 148.07,
			highest: 148.23,
			volumePrice: 148.1609,
			transactionCount: 1175,
			voluemPrice: 148.1609
		},
		{
			open: 148.19,
			close: 148.13,
			volume: 105331,
			time: 1654181340000,
			lowest: 148.01,
			highest: 148.235,
			volumePrice: 148.1189,
			transactionCount: 1122,
			voluemPrice: 148.1189
		},
		{
			open: 148.125,
			close: 147.905,
			volume: 126158,
			time: 1654181400000,
			lowest: 147.9,
			highest: 148.1599,
			volumePrice: 148.0226,
			transactionCount: 1228,
			voluemPrice: 148.0226
		},
		{
			open: 147.9,
			close: 148.1041,
			volume: 175052,
			time: 1654181460000,
			lowest: 147.8661,
			highest: 148.15,
			volumePrice: 147.9952,
			transactionCount: 1664,
			voluemPrice: 147.9952
		},
		{
			open: 148.115,
			close: 148.02,
			volume: 166403,
			time: 1654181520000,
			lowest: 148,
			highest: 148.24,
			volumePrice: 148.1258,
			transactionCount: 1253,
			voluemPrice: 148.1258
		},
		{
			open: 148.02,
			close: 148.09,
			volume: 85879,
			time: 1654181580000,
			lowest: 148.01,
			highest: 148.108,
			volumePrice: 148.0535,
			transactionCount: 799,
			voluemPrice: 148.0535
		},
		{
			open: 148.11,
			close: 148.015,
			volume: 96136,
			time: 1654181640000,
			lowest: 147.98,
			highest: 148.14,
			volumePrice: 148.0527,
			transactionCount: 1018,
			voluemPrice: 148.0527
		},
		{
			open: 148.01,
			close: 148.175,
			volume: 142378,
			time: 1654181700000,
			lowest: 147.97,
			highest: 148.21,
			volumePrice: 148.0866,
			transactionCount: 1255,
			voluemPrice: 148.0866
		},
		{
			open: 148.16,
			close: 148.165,
			volume: 159483,
			time: 1654181760000,
			lowest: 148.09,
			highest: 148.32,
			volumePrice: 148.2187,
			transactionCount: 1305,
			voluemPrice: 148.2187
		},
		{
			open: 148.16,
			close: 148.02,
			volume: 147740,
			time: 1654181820000,
			lowest: 147.91,
			highest: 148.16,
			volumePrice: 148.0092,
			transactionCount: 1320,
			voluemPrice: 148.0092
		},
		{
			open: 148.025,
			close: 148.195,
			volume: 126124,
			time: 1654181880000,
			lowest: 147.9618,
			highest: 148.23,
			volumePrice: 148.1019,
			transactionCount: 1115,
			voluemPrice: 148.1019
		},
		{
			open: 148.2,
			close: 148.23,
			volume: 128022,
			time: 1654181940000,
			lowest: 148.09,
			highest: 148.28,
			volumePrice: 148.1983,
			transactionCount: 1103,
			voluemPrice: 148.1983
		},
		{
			open: 148.21,
			close: 148.41,
			volume: 173671,
			time: 1654182000000,
			lowest: 148.11,
			highest: 148.43,
			volumePrice: 148.2815,
			transactionCount: 1730,
			voluemPrice: 148.2815
		},
		{
			open: 148.4123,
			close: 148.5027,
			volume: 151194,
			time: 1654182060000,
			lowest: 148.36,
			highest: 148.54,
			volumePrice: 148.4525,
			transactionCount: 1364,
			voluemPrice: 148.4525
		},
		{
			open: 148.52,
			close: 148.6712,
			volume: 166115,
			time: 1654182120000,
			lowest: 148.49,
			highest: 148.72,
			volumePrice: 148.6403,
			transactionCount: 1546,
			voluemPrice: 148.6403
		},
		{
			open: 148.67,
			close: 148.755,
			volume: 202980,
			time: 1654182180000,
			lowest: 148.67,
			highest: 148.83,
			volumePrice: 148.7564,
			transactionCount: 1654,
			voluemPrice: 148.7564
		},
		{
			open: 148.75,
			close: 148.655,
			volume: 425400,
			time: 1654182240000,
			lowest: 148.64,
			highest: 148.7699,
			volumePrice: 148.7383,
			transactionCount: 1777,
			voluemPrice: 148.7383
		},
		{
			open: 148.67,
			close: 148.53,
			volume: 155509,
			time: 1654182300000,
			lowest: 148.53,
			highest: 148.73,
			volumePrice: 148.6315,
			transactionCount: 1260,
			voluemPrice: 148.6315
		},
		{
			open: 148.5371,
			close: 148.64,
			volume: 159388,
			time: 1654182360000,
			lowest: 148.4905,
			highest: 148.65,
			volumePrice: 148.5586,
			transactionCount: 1383,
			voluemPrice: 148.5586
		},
		{
			open: 148.63,
			close: 148.65,
			volume: 93102,
			time: 1654182420000,
			lowest: 148.58,
			highest: 148.651,
			volumePrice: 148.6151,
			transactionCount: 850,
			voluemPrice: 148.6151
		},
		{
			open: 148.64,
			close: 148.765,
			volume: 112013,
			time: 1654182480000,
			lowest: 148.59,
			highest: 148.7699,
			volumePrice: 148.6818,
			transactionCount: 1018,
			voluemPrice: 148.6818
		},
		{
			open: 148.76,
			close: 148.7229,
			volume: 123649,
			time: 1654182540000,
			lowest: 148.6501,
			highest: 148.7794,
			volumePrice: 148.7117,
			transactionCount: 1140,
			voluemPrice: 148.7117
		},
		{
			open: 148.715,
			close: 148.99,
			volume: 365780,
			time: 1654182600000,
			lowest: 148.7103,
			highest: 149.05,
			volumePrice: 148.9006,
			transactionCount: 2458,
			voluemPrice: 148.9006
		},
		{
			open: 148.99,
			close: 149.14,
			volume: 222190,
			time: 1654182660000,
			lowest: 148.96,
			highest: 149.15,
			volumePrice: 149.0583,
			transactionCount: 1508,
			voluemPrice: 149.0583
		},
		{
			open: 149.1354,
			close: 149.24,
			volume: 219633,
			time: 1654182720000,
			lowest: 149.12,
			highest: 149.28,
			volumePrice: 149.1939,
			transactionCount: 1718,
			voluemPrice: 149.1939
		},
		{
			open: 149.23,
			close: 149.17,
			volume: 208869,
			time: 1654182780000,
			lowest: 149.12,
			highest: 149.3,
			volumePrice: 149.2073,
			transactionCount: 1710,
			voluemPrice: 149.2073
		},
		{
			open: 149.2,
			close: 149.278,
			volume: 230015,
			time: 1654182840000,
			lowest: 149.14,
			highest: 149.31,
			volumePrice: 149.2495,
			transactionCount: 1660,
			voluemPrice: 149.2495
		},
		{
			open: 149.28,
			close: 149.265,
			volume: 212057,
			time: 1654182900000,
			lowest: 149.19,
			highest: 149.295,
			volumePrice: 149.247,
			transactionCount: 1211,
			voluemPrice: 149.247
		},
		{
			open: 149.26,
			close: 149.205,
			volume: 161777,
			time: 1654182960000,
			lowest: 149.2,
			highest: 149.32,
			volumePrice: 149.2721,
			transactionCount: 1307,
			voluemPrice: 149.2721
		},
		{
			open: 149.215,
			close: 149.005,
			volume: 222765,
			time: 1654183020000,
			lowest: 148.97,
			highest: 149.25,
			volumePrice: 149.0548,
			transactionCount: 1846,
			voluemPrice: 149.0548
		},
		{
			open: 149.008,
			close: 149.015,
			volume: 115870,
			time: 1654183080000,
			lowest: 148.9,
			highest: 149.0299,
			volumePrice: 148.9787,
			transactionCount: 1253,
			voluemPrice: 148.9787
		},
		{
			open: 149.005,
			close: 149.04,
			volume: 137013,
			time: 1654183140000,
			lowest: 148.9851,
			highest: 149.095,
			volumePrice: 149.0511,
			transactionCount: 1316,
			voluemPrice: 149.0511
		},
		{
			open: 149.04,
			close: 149.01,
			volume: 137579,
			time: 1654183200000,
			lowest: 148.9,
			highest: 149.06,
			volumePrice: 148.9833,
			transactionCount: 1223,
			voluemPrice: 148.9833
		},
		{
			open: 149.02,
			close: 148.959,
			volume: 110431,
			time: 1654183260000,
			lowest: 148.93,
			highest: 149.03,
			volumePrice: 148.9882,
			transactionCount: 1029,
			voluemPrice: 148.9882
		},
		{
			open: 148.96,
			close: 149.09,
			volume: 164213,
			time: 1654183320000,
			lowest: 148.89,
			highest: 149.1,
			volumePrice: 148.9953,
			transactionCount: 1414,
			voluemPrice: 148.9953
		},
		{
			open: 149.08,
			close: 148.989,
			volume: 115306,
			time: 1654183380000,
			lowest: 148.97,
			highest: 149.089,
			volumePrice: 149.0325,
			transactionCount: 1018,
			voluemPrice: 149.0325
		},
		{
			open: 148.985,
			close: 149.03,
			volume: 91299,
			time: 1654183440000,
			lowest: 148.9639,
			highest: 149.03,
			volumePrice: 148.9982,
			transactionCount: 714,
			voluemPrice: 148.9982
		},
		{
			open: 149.03,
			close: 149,
			volume: 109087,
			time: 1654183500000,
			lowest: 148.92,
			highest: 149.03,
			volumePrice: 148.9767,
			transactionCount: 937,
			voluemPrice: 148.9767
		},
		{
			open: 149,
			close: 149.09,
			volume: 132731,
			time: 1654183560000,
			lowest: 148.98,
			highest: 149.0959,
			volumePrice: 149.034,
			transactionCount: 993,
			voluemPrice: 149.034
		},
		{
			open: 149.095,
			close: 148.8999,
			volume: 259056,
			time: 1654183620000,
			lowest: 148.75,
			highest: 149.1,
			volumePrice: 148.8823,
			transactionCount: 2437,
			voluemPrice: 148.8823
		},
		{
			open: 148.89,
			close: 148.8,
			volume: 133432,
			time: 1654183680000,
			lowest: 148.73,
			highest: 148.89,
			volumePrice: 148.7969,
			transactionCount: 1385,
			voluemPrice: 148.7969
		},
		{
			open: 148.7913,
			close: 148.93,
			volume: 124237,
			time: 1654183740000,
			lowest: 148.66,
			highest: 148.96,
			volumePrice: 148.8052,
			transactionCount: 1248,
			voluemPrice: 148.8052
		},
		{
			open: 148.94,
			close: 148.759,
			volume: 151526,
			time: 1654183800000,
			lowest: 148.6899,
			highest: 148.99,
			volumePrice: 148.8318,
			transactionCount: 1313,
			voluemPrice: 148.8318
		},
		{
			open: 148.75,
			close: 148.7399,
			volume: 228404,
			time: 1654183860000,
			lowest: 148.52,
			highest: 148.81,
			volumePrice: 148.6599,
			transactionCount: 2084,
			voluemPrice: 148.6599
		},
		{
			open: 148.75,
			close: 148.74,
			volume: 92628,
			time: 1654183920000,
			lowest: 148.615,
			highest: 148.76,
			volumePrice: 148.699,
			transactionCount: 1101,
			voluemPrice: 148.699
		},
		{
			open: 148.74,
			close: 148.75,
			volume: 109185,
			time: 1654183980000,
			lowest: 148.69,
			highest: 148.8099,
			volumePrice: 148.7512,
			transactionCount: 958,
			voluemPrice: 148.7512
		},
		{
			open: 148.75,
			close: 148.79,
			volume: 124475,
			time: 1654184040000,
			lowest: 148.74,
			highest: 148.86,
			volumePrice: 148.7948,
			transactionCount: 1217,
			voluemPrice: 148.7948
		},
		{
			open: 148.7721,
			close: 148.5995,
			volume: 153986,
			time: 1654184100000,
			lowest: 148.565,
			highest: 148.775,
			volumePrice: 148.6491,
			transactionCount: 1577,
			voluemPrice: 148.6491
		},
		{
			open: 148.59,
			close: 148.5915,
			volume: 222435,
			time: 1654184160000,
			lowest: 148.52,
			highest: 148.669,
			volumePrice: 148.5984,
			transactionCount: 1858,
			voluemPrice: 148.5984
		},
		{
			open: 148.6,
			close: 148.59,
			volume: 137480,
			time: 1654184220000,
			lowest: 148.55,
			highest: 148.6389,
			volumePrice: 148.6014,
			transactionCount: 1203,
			voluemPrice: 148.6014
		},
		{
			open: 148.57,
			close: 148.74,
			volume: 135793,
			time: 1654184280000,
			lowest: 148.56,
			highest: 148.7699,
			volumePrice: 148.6623,
			transactionCount: 1076,
			voluemPrice: 148.6623
		},
		{
			open: 148.75,
			close: 148.67,
			volume: 79479,
			time: 1654184340000,
			lowest: 148.66,
			highest: 148.75,
			volumePrice: 148.7046,
			transactionCount: 864,
			voluemPrice: 148.7046
		},
		{
			open: 148.68,
			close: 148.838,
			volume: 145310,
			time: 1654184400000,
			lowest: 148.67,
			highest: 148.85,
			volumePrice: 148.7412,
			transactionCount: 1251,
			voluemPrice: 148.7412
		},
		{
			open: 148.835,
			close: 149.15,
			volume: 260638,
			time: 1654184460000,
			lowest: 148.79,
			highest: 149.15,
			volumePrice: 148.9997,
			transactionCount: 2050,
			voluemPrice: 148.9997
		},
		{
			open: 149.1409,
			close: 149.21,
			volume: 173808,
			time: 1654184520000,
			lowest: 149.0312,
			highest: 149.2175,
			volumePrice: 149.1043,
			transactionCount: 1518,
			voluemPrice: 149.1043
		},
		{
			open: 149.2191,
			close: 149.22,
			volume: 175092,
			time: 1654184580000,
			lowest: 149.13,
			highest: 149.29,
			volumePrice: 149.2263,
			transactionCount: 1352,
			voluemPrice: 149.2263
		},
		{
			open: 149.23,
			close: 149.19,
			volume: 93711,
			time: 1654184640000,
			lowest: 149.18,
			highest: 149.28,
			volumePrice: 149.2218,
			transactionCount: 761,
			voluemPrice: 149.2218
		},
		{
			open: 149.2,
			close: 149.4286,
			volume: 276845,
			time: 1654184700000,
			lowest: 149.12,
			highest: 149.49,
			volumePrice: 149.3399,
			transactionCount: 2323,
			voluemPrice: 149.3399
		},
		{
			open: 149.43,
			close: 149.638,
			volume: 224425,
			time: 1654184760000,
			lowest: 149.4,
			highest: 149.65,
			volumePrice: 149.5423,
			transactionCount: 1729,
			voluemPrice: 149.5423
		},
		{
			open: 149.64,
			close: 149.64,
			volume: 181880,
			time: 1654184820000,
			lowest: 149.57,
			highest: 149.71,
			volumePrice: 149.6296,
			transactionCount: 1481,
			voluemPrice: 149.6296
		},
		{
			open: 149.65,
			close: 149.73,
			volume: 167764,
			time: 1654184880000,
			lowest: 149.6275,
			highest: 149.755,
			volumePrice: 149.7009,
			transactionCount: 1208,
			voluemPrice: 149.7009
		},
		{
			open: 149.73,
			close: 149.7748,
			volume: 207868,
			time: 1654184940000,
			lowest: 149.73,
			highest: 149.8319,
			volumePrice: 149.7826,
			transactionCount: 1416,
			voluemPrice: 149.7826
		},
		{
			open: 149.78,
			close: 149.82,
			volume: 180139,
			time: 1654185000000,
			lowest: 149.7,
			highest: 149.84,
			volumePrice: 149.767,
			transactionCount: 1366,
			voluemPrice: 149.767
		},
		{
			open: 149.825,
			close: 149.83,
			volume: 138711,
			time: 1654185060000,
			lowest: 149.79,
			highest: 149.895,
			volumePrice: 149.8377,
			transactionCount: 1200,
			voluemPrice: 149.8377
		},
		{
			open: 149.83,
			close: 149.9,
			volume: 134224,
			time: 1654185120000,
			lowest: 149.79,
			highest: 149.92,
			volumePrice: 149.8546,
			transactionCount: 1233,
			voluemPrice: 149.8546
		},
		{
			open: 149.91,
			close: 149.8701,
			volume: 108068,
			time: 1654185180000,
			lowest: 149.84,
			highest: 149.9385,
			volumePrice: 149.8929,
			transactionCount: 1065,
			voluemPrice: 149.8929
		},
		{
			open: 149.87,
			close: 149.8,
			volume: 291757,
			time: 1654185240000,
			lowest: 149.69,
			highest: 149.9399,
			volumePrice: 149.8055,
			transactionCount: 3141,
			voluemPrice: 149.8055
		},
		{
			open: 149.8,
			close: 149.68,
			volume: 176060,
			time: 1654185300000,
			lowest: 149.66,
			highest: 149.85,
			volumePrice: 149.7457,
			transactionCount: 1623,
			voluemPrice: 149.7457
		},
		{
			open: 149.69,
			close: 149.57,
			volume: 202600,
			time: 1654185360000,
			lowest: 149.56,
			highest: 149.72,
			volumePrice: 149.634,
			transactionCount: 1542,
			voluemPrice: 149.634
		},
		{
			open: 149.57,
			close: 149.4051,
			volume: 205818,
			time: 1654185420000,
			lowest: 149.35,
			highest: 149.58,
			volumePrice: 149.4588,
			transactionCount: 1557,
			voluemPrice: 149.4588
		},
		{
			open: 149.4,
			close: 149.3899,
			volume: 128467,
			time: 1654185480000,
			lowest: 149.35,
			highest: 149.45,
			volumePrice: 149.406,
			transactionCount: 897,
			voluemPrice: 149.406
		},
		{
			open: 149.385,
			close: 149.4412,
			volume: 129854,
			time: 1654185540000,
			lowest: 149.37,
			highest: 149.48,
			volumePrice: 149.4258,
			transactionCount: 962,
			voluemPrice: 149.4258
		},
		{
			open: 149.45,
			close: 149.4157,
			volume: 120802,
			time: 1654185600000,
			lowest: 149.41,
			highest: 149.53,
			volumePrice: 149.4638,
			transactionCount: 1104,
			voluemPrice: 149.4638
		},
		{
			open: 149.412,
			close: 149.435,
			volume: 127352,
			time: 1654185660000,
			lowest: 149.3512,
			highest: 149.4708,
			volumePrice: 149.4143,
			transactionCount: 1158,
			voluemPrice: 149.4143
		},
		{
			open: 149.43,
			close: 149.485,
			volume: 88584,
			time: 1654185720000,
			lowest: 149.41,
			highest: 149.5,
			volumePrice: 149.4534,
			transactionCount: 839,
			voluemPrice: 149.4534
		},
		{
			open: 149.5,
			close: 149.6555,
			volume: 133866,
			time: 1654185780000,
			lowest: 149.49,
			highest: 149.68,
			volumePrice: 149.5942,
			transactionCount: 1193,
			voluemPrice: 149.5942
		},
		{
			open: 149.65,
			close: 149.7,
			volume: 135448,
			time: 1654185840000,
			lowest: 149.6414,
			highest: 149.735,
			volumePrice: 149.697,
			transactionCount: 1036,
			voluemPrice: 149.697
		},
		{
			open: 149.7,
			close: 149.69,
			volume: 102328,
			time: 1654185900000,
			lowest: 149.58,
			highest: 149.71,
			volumePrice: 149.6408,
			transactionCount: 827,
			voluemPrice: 149.6408
		},
		{
			open: 149.6887,
			close: 149.645,
			volume: 80567,
			time: 1654185960000,
			lowest: 149.58,
			highest: 149.72,
			volumePrice: 149.6646,
			transactionCount: 884,
			voluemPrice: 149.6646
		},
		{
			open: 149.64,
			close: 149.725,
			volume: 248978,
			time: 1654186020000,
			lowest: 149.54,
			highest: 149.96,
			volumePrice: 149.7259,
			transactionCount: 2207,
			voluemPrice: 149.7259
		},
		{
			open: 149.72,
			close: 149.54,
			volume: 128246,
			time: 1654186080000,
			lowest: 149.51,
			highest: 149.73,
			volumePrice: 149.5986,
			transactionCount: 1253,
			voluemPrice: 149.5986
		},
		{
			open: 149.546,
			close: 149.56,
			volume: 117994,
			time: 1654186140000,
			lowest: 149.45,
			highest: 149.56,
			volumePrice: 149.5157,
			transactionCount: 1090,
			voluemPrice: 149.5157
		},
		{
			open: 149.56,
			close: 149.69,
			volume: 80998,
			time: 1654186200000,
			lowest: 149.52,
			highest: 149.7,
			volumePrice: 149.6062,
			transactionCount: 802,
			voluemPrice: 149.6062
		},
		{
			open: 149.6805,
			close: 149.54,
			volume: 125600,
			time: 1654186260000,
			lowest: 149.5,
			highest: 149.729,
			volumePrice: 149.6255,
			transactionCount: 1039,
			voluemPrice: 149.6255
		},
		{
			open: 149.55,
			close: 149.5499,
			volume: 84242,
			time: 1654186320000,
			lowest: 149.53,
			highest: 149.65,
			volumePrice: 149.5886,
			transactionCount: 834,
			voluemPrice: 149.5886
		},
		{
			open: 149.54,
			close: 149.65,
			volume: 79098,
			time: 1654186380000,
			lowest: 149.5313,
			highest: 149.68,
			volumePrice: 149.6203,
			transactionCount: 722,
			voluemPrice: 149.6203
		},
		{
			open: 149.6577,
			close: 149.705,
			volume: 108836,
			time: 1654186440000,
			lowest: 149.6,
			highest: 149.795,
			volumePrice: 149.7016,
			transactionCount: 834,
			voluemPrice: 149.7016
		},
		{
			open: 149.705,
			close: 149.7357,
			volume: 75873,
			time: 1654186500000,
			lowest: 149.68,
			highest: 149.775,
			volumePrice: 149.7288,
			transactionCount: 732,
			voluemPrice: 149.7288
		},
		{
			open: 149.74,
			close: 149.7541,
			volume: 73856,
			time: 1654186560000,
			lowest: 149.71,
			highest: 149.8197,
			volumePrice: 149.7603,
			transactionCount: 782,
			voluemPrice: 149.7603
		},
		{
			open: 149.77,
			close: 149.852,
			volume: 103740,
			time: 1654186620000,
			lowest: 149.73,
			highest: 149.8799,
			volumePrice: 149.8192,
			transactionCount: 814,
			voluemPrice: 149.8192
		},
		{
			open: 149.87,
			close: 149.985,
			volume: 159506,
			time: 1654186680000,
			lowest: 149.86,
			highest: 149.99,
			volumePrice: 149.9378,
			transactionCount: 1192,
			voluemPrice: 149.9378
		},
		{
			open: 149.9861,
			close: 150.3601,
			volume: 544145,
			time: 1654186740000,
			lowest: 149.98,
			highest: 150.37,
			volumePrice: 150.1432,
			transactionCount: 4681,
			voluemPrice: 150.1432
		},
		{
			open: 150.36,
			close: 150.315,
			volume: 254696,
			time: 1654186800000,
			lowest: 150.21,
			highest: 150.38,
			volumePrice: 150.3134,
			transactionCount: 1981,
			voluemPrice: 150.3134
		},
		{
			open: 150.32,
			close: 150.25,
			volume: 152102,
			time: 1654186860000,
			lowest: 150.17,
			highest: 150.3284,
			volumePrice: 150.251,
			transactionCount: 1294,
			voluemPrice: 150.251
		},
		{
			open: 150.25,
			close: 150.22,
			volume: 163236,
			time: 1654186920000,
			lowest: 150.19,
			highest: 150.3,
			volumePrice: 150.2467,
			transactionCount: 1292,
			voluemPrice: 150.2467
		},
		{
			open: 150.22,
			close: 150.3661,
			volume: 126955,
			time: 1654186980000,
			lowest: 150.1986,
			highest: 150.3757,
			volumePrice: 150.2984,
			transactionCount: 1022,
			voluemPrice: 150.2984
		},
		{
			open: 150.37,
			close: 150.47,
			volume: 228930,
			time: 1654187040000,
			lowest: 150.3601,
			highest: 150.5,
			volumePrice: 150.4392,
			transactionCount: 1925,
			voluemPrice: 150.4392
		},
		{
			open: 150.46,
			close: 150.38,
			volume: 117411,
			time: 1654187100000,
			lowest: 150.36,
			highest: 150.46,
			volumePrice: 150.413,
			transactionCount: 1099,
			voluemPrice: 150.413
		},
		{
			open: 150.385,
			close: 150.345,
			volume: 134051,
			time: 1654187160000,
			lowest: 150.269,
			highest: 150.4,
			volumePrice: 150.329,
			transactionCount: 1072,
			voluemPrice: 150.329
		},
		{
			open: 150.34,
			close: 150.445,
			volume: 104557,
			time: 1654187220000,
			lowest: 150.32,
			highest: 150.46,
			volumePrice: 150.4028,
			transactionCount: 923,
			voluemPrice: 150.4028
		},
		{
			open: 150.45,
			close: 150.37,
			volume: 135876,
			time: 1654187280000,
			lowest: 150.33,
			highest: 150.54,
			volumePrice: 150.46,
			transactionCount: 1216,
			voluemPrice: 150.46
		},
		{
			open: 150.365,
			close: 150.5011,
			volume: 105072,
			time: 1654187340000,
			lowest: 150.3136,
			highest: 150.52,
			volumePrice: 150.434,
			transactionCount: 910,
			voluemPrice: 150.434
		},
		{
			open: 150.5199,
			close: 150.445,
			volume: 228394,
			time: 1654187400000,
			lowest: 150.445,
			highest: 150.56,
			volumePrice: 150.5097,
			transactionCount: 1002,
			voluemPrice: 150.5097
		},
		{
			open: 150.4418,
			close: 150.4099,
			volume: 120374,
			time: 1654187460000,
			lowest: 150.3519,
			highest: 150.4418,
			volumePrice: 150.3949,
			transactionCount: 1089,
			voluemPrice: 150.3949
		},
		{
			open: 150.3935,
			close: 150.4672,
			volume: 89732,
			time: 1654187520000,
			lowest: 150.38,
			highest: 150.49,
			volumePrice: 150.4474,
			transactionCount: 926,
			voluemPrice: 150.4474
		},
		{
			open: 150.47,
			close: 150.53,
			volume: 122469,
			time: 1654187580000,
			lowest: 150.41,
			highest: 150.58,
			volumePrice: 150.5006,
			transactionCount: 1078,
			voluemPrice: 150.5006
		},
		{
			open: 150.53,
			close: 150.435,
			volume: 117922,
			time: 1654187640000,
			lowest: 150.38,
			highest: 150.54,
			volumePrice: 150.4267,
			transactionCount: 1107,
			voluemPrice: 150.4267
		},
		{
			open: 150.45,
			close: 150.4153,
			volume: 148867,
			time: 1654187700000,
			lowest: 150.37,
			highest: 150.4915,
			volumePrice: 150.4316,
			transactionCount: 1143,
			voluemPrice: 150.4316
		},
		{
			open: 150.42,
			close: 150.5,
			volume: 109220,
			time: 1654187760000,
			lowest: 150.4,
			highest: 150.52,
			volumePrice: 150.4612,
			transactionCount: 1183,
			voluemPrice: 150.4612
		},
		{
			open: 150.51,
			close: 150.5,
			volume: 87337,
			time: 1654187820000,
			lowest: 150.39,
			highest: 150.5181,
			volumePrice: 150.4806,
			transactionCount: 948,
			voluemPrice: 150.4806
		},
		{
			open: 150.51,
			close: 150.52,
			volume: 85681,
			time: 1654187880000,
			lowest: 150.42,
			highest: 150.54,
			volumePrice: 150.4876,
			transactionCount: 798,
			voluemPrice: 150.4876
		},
		{
			open: 150.52,
			close: 150.56,
			volume: 86214,
			time: 1654187940000,
			lowest: 150.47,
			highest: 150.5732,
			volumePrice: 150.48,
			transactionCount: 787,
			voluemPrice: 150.48
		},
		{
			open: 150.565,
			close: 150.51,
			volume: 93963,
			time: 1654188000000,
			lowest: 150.47,
			highest: 150.59,
			volumePrice: 150.5276,
			transactionCount: 976,
			voluemPrice: 150.5276
		},
		{
			open: 150.51,
			close: 150.54,
			volume: 111171,
			time: 1654188060000,
			lowest: 150.49,
			highest: 150.6,
			volumePrice: 150.5645,
			transactionCount: 1001,
			voluemPrice: 150.5645
		},
		{
			open: 150.55,
			close: 150.42,
			volume: 197936,
			time: 1654188120000,
			lowest: 150.34,
			highest: 150.56,
			volumePrice: 150.4581,
			transactionCount: 2156,
			voluemPrice: 150.4581
		},
		{
			open: 150.425,
			close: 150.385,
			volume: 156390,
			time: 1654188180000,
			lowest: 150.35,
			highest: 150.445,
			volumePrice: 150.3974,
			transactionCount: 1371,
			voluemPrice: 150.3974
		},
		{
			open: 150.39,
			close: 150.47,
			volume: 70048,
			time: 1654188240000,
			lowest: 150.36,
			highest: 150.48,
			volumePrice: 150.4231,
			transactionCount: 802,
			voluemPrice: 150.4231
		},
		{
			open: 150.47,
			close: 150.6201,
			volume: 118509,
			time: 1654188300000,
			lowest: 150.46,
			highest: 150.63,
			volumePrice: 150.5427,
			transactionCount: 1040,
			voluemPrice: 150.5427
		},
		{
			open: 150.62,
			close: 150.605,
			volume: 184008,
			time: 1654188360000,
			lowest: 150.54,
			highest: 150.69,
			volumePrice: 150.6318,
			transactionCount: 1396,
			voluemPrice: 150.6318
		},
		{
			open: 150.6,
			close: 150.5106,
			volume: 107526,
			time: 1654188420000,
			lowest: 150.48,
			highest: 150.6,
			volumePrice: 150.5397,
			transactionCount: 859,
			voluemPrice: 150.5397
		},
		{
			open: 150.5,
			close: 150.48,
			volume: 104275,
			time: 1654188480000,
			lowest: 150.45,
			highest: 150.5501,
			volumePrice: 150.503,
			transactionCount: 985,
			voluemPrice: 150.503
		},
		{
			open: 150.48,
			close: 150.48,
			volume: 106692,
			time: 1654188540000,
			lowest: 150.42,
			highest: 150.4886,
			volumePrice: 150.4519,
			transactionCount: 898,
			voluemPrice: 150.4519
		},
		{
			open: 150.485,
			close: 150.48,
			volume: 117249,
			time: 1654188600000,
			lowest: 150.44,
			highest: 150.525,
			volumePrice: 150.4794,
			transactionCount: 1075,
			voluemPrice: 150.4794
		},
		{
			open: 150.4801,
			close: 150.501,
			volume: 90533,
			time: 1654188660000,
			lowest: 150.4538,
			highest: 150.53,
			volumePrice: 150.4949,
			transactionCount: 873,
			voluemPrice: 150.4949
		},
		{
			open: 150.5022,
			close: 150.565,
			volume: 103440,
			time: 1654188720000,
			lowest: 150.4301,
			highest: 150.59,
			volumePrice: 150.5347,
			transactionCount: 778,
			voluemPrice: 150.5347
		},
		{
			open: 150.56,
			close: 150.628,
			volume: 130815,
			time: 1654188780000,
			lowest: 150.55,
			highest: 150.6599,
			volumePrice: 150.6078,
			transactionCount: 1088,
			voluemPrice: 150.6078
		},
		{
			open: 150.62,
			close: 150.66,
			volume: 116921,
			time: 1654188840000,
			lowest: 150.58,
			highest: 150.69,
			volumePrice: 150.649,
			transactionCount: 1018,
			voluemPrice: 150.649
		},
		{
			open: 150.6537,
			close: 150.585,
			volume: 95743,
			time: 1654188900000,
			lowest: 150.53,
			highest: 150.6537,
			volumePrice: 150.5886,
			transactionCount: 907,
			voluemPrice: 150.5886
		},
		{
			open: 150.5786,
			close: 150.485,
			volume: 95819,
			time: 1654188960000,
			lowest: 150.45,
			highest: 150.5799,
			volumePrice: 150.5144,
			transactionCount: 848,
			voluemPrice: 150.5144
		},
		{
			open: 150.485,
			close: 150.48,
			volume: 81220,
			time: 1654189020000,
			lowest: 150.4541,
			highest: 150.51,
			volumePrice: 150.4853,
			transactionCount: 702,
			voluemPrice: 150.4853
		},
		{
			open: 150.48,
			close: 150.42,
			volume: 103113,
			time: 1654189080000,
			lowest: 150.42,
			highest: 150.508,
			volumePrice: 150.4703,
			transactionCount: 823,
			voluemPrice: 150.4703
		},
		{
			open: 150.42,
			close: 150.48,
			volume: 72933,
			time: 1654189140000,
			lowest: 150.37,
			highest: 150.4999,
			volumePrice: 150.4366,
			transactionCount: 768,
			voluemPrice: 150.4366
		},
		{
			open: 150.49,
			close: 150.44,
			volume: 86324,
			time: 1654189200000,
			lowest: 150.4,
			highest: 150.52,
			volumePrice: 150.4688,
			transactionCount: 861,
			voluemPrice: 150.4688
		},
		{
			open: 150.45,
			close: 150.45,
			volume: 202645,
			time: 1654189260000,
			lowest: 150.3701,
			highest: 150.5,
			volumePrice: 150.4481,
			transactionCount: 870,
			voluemPrice: 150.4481
		},
		{
			open: 150.46,
			close: 150.51,
			volume: 66637,
			time: 1654189320000,
			lowest: 150.435,
			highest: 150.54,
			volumePrice: 150.4905,
			transactionCount: 652,
			voluemPrice: 150.4905
		},
		{
			open: 150.5,
			close: 150.57,
			volume: 94126,
			time: 1654189380000,
			lowest: 150.465,
			highest: 150.6101,
			volumePrice: 150.5708,
			transactionCount: 811,
			voluemPrice: 150.5708
		},
		{
			open: 150.5799,
			close: 150.59,
			volume: 54114,
			time: 1654189440000,
			lowest: 150.53,
			highest: 150.6153,
			volumePrice: 150.5813,
			transactionCount: 564,
			voluemPrice: 150.5813
		},
		{
			open: 150.595,
			close: 150.4586,
			volume: 122349,
			time: 1654189500000,
			lowest: 150.45,
			highest: 150.6,
			volumePrice: 150.5226,
			transactionCount: 1289,
			voluemPrice: 150.5226
		},
		{
			open: 150.45,
			close: 150.56,
			volume: 62731,
			time: 1654189560000,
			lowest: 150.45,
			highest: 150.565,
			volumePrice: 150.4971,
			transactionCount: 625,
			voluemPrice: 150.4971
		},
		{
			open: 150.56,
			close: 150.565,
			volume: 58697,
			time: 1654189620000,
			lowest: 150.51,
			highest: 150.6,
			volumePrice: 150.558,
			transactionCount: 614,
			voluemPrice: 150.558
		},
		{
			open: 150.57,
			close: 150.48,
			volume: 47389,
			time: 1654189680000,
			lowest: 150.48,
			highest: 150.57,
			volumePrice: 150.5391,
			transactionCount: 575,
			voluemPrice: 150.5391
		},
		{
			open: 150.4899,
			close: 150.41,
			volume: 80706,
			time: 1654189740000,
			lowest: 150.41,
			highest: 150.5,
			volumePrice: 150.4537,
			transactionCount: 836,
			voluemPrice: 150.4537
		},
		{
			open: 150.41,
			close: 150.42,
			volume: 103504,
			time: 1654189800000,
			lowest: 150.41,
			highest: 150.49,
			volumePrice: 150.4402,
			transactionCount: 877,
			voluemPrice: 150.4402
		},
		{
			open: 150.45,
			close: 150.2918,
			volume: 145784,
			time: 1654189860000,
			lowest: 150.265,
			highest: 150.45,
			volumePrice: 150.3466,
			transactionCount: 1307,
			voluemPrice: 150.3466
		},
		{
			open: 150.29,
			close: 150.215,
			volume: 228773,
			time: 1654189920000,
			lowest: 150.18,
			highest: 150.315,
			volumePrice: 150.2933,
			transactionCount: 1601,
			voluemPrice: 150.2933
		},
		{
			open: 150.22,
			close: 150.12,
			volume: 134584,
			time: 1654189980000,
			lowest: 150.12,
			highest: 150.26,
			volumePrice: 150.1927,
			transactionCount: 1175,
			voluemPrice: 150.1927
		},
		{
			open: 150.125,
			close: 150.07,
			volume: 247948,
			time: 1654190040000,
			lowest: 149.96,
			highest: 150.14,
			volumePrice: 150.05,
			transactionCount: 2104,
			voluemPrice: 150.05
		},
		{
			open: 150.07,
			close: 150.08,
			volume: 101582,
			time: 1654190100000,
			lowest: 149.99,
			highest: 150.125,
			volumePrice: 150.0583,
			transactionCount: 952,
			voluemPrice: 150.0583
		},
		{
			open: 150.075,
			close: 150.08,
			volume: 91136,
			time: 1654190160000,
			lowest: 150.025,
			highest: 150.0999,
			volumePrice: 150.0632,
			transactionCount: 770,
			voluemPrice: 150.0632
		},
		{
			open: 150.075,
			close: 150.265,
			volume: 148977,
			time: 1654190220000,
			lowest: 150.0709,
			highest: 150.27,
			volumePrice: 150.1975,
			transactionCount: 1004,
			voluemPrice: 150.1975
		},
		{
			open: 150.265,
			close: 150.24,
			volume: 93425,
			time: 1654190280000,
			lowest: 150.18,
			highest: 150.3,
			volumePrice: 150.2541,
			transactionCount: 810,
			voluemPrice: 150.2541
		},
		{
			open: 150.25,
			close: 150.32,
			volume: 88261,
			time: 1654190340000,
			lowest: 150.23,
			highest: 150.33,
			volumePrice: 150.2846,
			transactionCount: 767,
			voluemPrice: 150.2846
		},
		{
			open: 150.3,
			close: 150.26,
			volume: 65534,
			time: 1654190400000,
			lowest: 150.2,
			highest: 150.31,
			volumePrice: 150.2389,
			transactionCount: 714,
			voluemPrice: 150.2389
		},
		{
			open: 150.25,
			close: 150.23,
			volume: 59767,
			time: 1654190460000,
			lowest: 150.15,
			highest: 150.25,
			volumePrice: 150.2015,
			transactionCount: 655,
			voluemPrice: 150.2015
		},
		{
			open: 150.2499,
			close: 150.215,
			volume: 60180,
			time: 1654190520000,
			lowest: 150.19,
			highest: 150.2499,
			volumePrice: 150.2248,
			transactionCount: 554,
			voluemPrice: 150.2248
		},
		{
			open: 150.215,
			close: 150.28,
			volume: 72257,
			time: 1654190580000,
			lowest: 150.1701,
			highest: 150.29,
			volumePrice: 150.2331,
			transactionCount: 827,
			voluemPrice: 150.2331
		},
		{
			open: 150.28,
			close: 150.245,
			volume: 105144,
			time: 1654190640000,
			lowest: 150.215,
			highest: 150.34,
			volumePrice: 150.2877,
			transactionCount: 958,
			voluemPrice: 150.2877
		},
		{
			open: 150.2404,
			close: 150.29,
			volume: 51443,
			time: 1654190700000,
			lowest: 150.21,
			highest: 150.3046,
			volumePrice: 150.2759,
			transactionCount: 591,
			voluemPrice: 150.2759
		},
		{
			open: 150.295,
			close: 150.1701,
			volume: 64686,
			time: 1654190760000,
			lowest: 150.155,
			highest: 150.307,
			volumePrice: 150.227,
			transactionCount: 751,
			voluemPrice: 150.227
		},
		{
			open: 150.18,
			close: 150.11,
			volume: 94633,
			time: 1654190820000,
			lowest: 150.08,
			highest: 150.225,
			volumePrice: 150.143,
			transactionCount: 840,
			voluemPrice: 150.143
		},
		{
			open: 150.1,
			close: 150.09,
			volume: 103943,
			time: 1654190880000,
			lowest: 149.97,
			highest: 150.1,
			volumePrice: 150.034,
			transactionCount: 1026,
			voluemPrice: 150.034
		},
		{
			open: 150.1,
			close: 150.1791,
			volume: 78190,
			time: 1654190940000,
			lowest: 150.08,
			highest: 150.185,
			volumePrice: 150.1279,
			transactionCount: 792,
			voluemPrice: 150.1279
		},
		{
			open: 150.18,
			close: 150.22,
			volume: 107130,
			time: 1654191000000,
			lowest: 150.17,
			highest: 150.24,
			volumePrice: 150.2066,
			transactionCount: 677,
			voluemPrice: 150.2066
		},
		{
			open: 150.21,
			close: 150.14,
			volume: 83756,
			time: 1654191060000,
			lowest: 150.03,
			highest: 150.21,
			volumePrice: 150.1279,
			transactionCount: 747,
			voluemPrice: 150.1279
		},
		{
			open: 150.1313,
			close: 150.11,
			volume: 48392,
			time: 1654191120000,
			lowest: 150.0688,
			highest: 150.14,
			volumePrice: 150.1061,
			transactionCount: 533,
			voluemPrice: 150.1061
		},
		{
			open: 150.12,
			close: 150.145,
			volume: 111827,
			time: 1654191180000,
			lowest: 150.11,
			highest: 150.22,
			volumePrice: 150.1625,
			transactionCount: 943,
			voluemPrice: 150.1625
		},
		{
			open: 150.16,
			close: 150.24,
			volume: 79021,
			time: 1654191240000,
			lowest: 150.11,
			highest: 150.25,
			volumePrice: 150.1891,
			transactionCount: 731,
			voluemPrice: 150.1891
		},
		{
			open: 150.24,
			close: 150.22,
			volume: 97315,
			time: 1654191300000,
			lowest: 150.19,
			highest: 150.27,
			volumePrice: 150.2306,
			transactionCount: 666,
			voluemPrice: 150.2306
		},
		{
			open: 150.23,
			close: 150.155,
			volume: 82003,
			time: 1654191360000,
			lowest: 150.105,
			highest: 150.2558,
			volumePrice: 150.1692,
			transactionCount: 762,
			voluemPrice: 150.1692
		},
		{
			open: 150.16,
			close: 150.1399,
			volume: 42337,
			time: 1654191420000,
			lowest: 150.11,
			highest: 150.21,
			volumePrice: 150.1692,
			transactionCount: 549,
			voluemPrice: 150.1692
		},
		{
			open: 150.14,
			close: 150.171,
			volume: 55605,
			time: 1654191480000,
			lowest: 150.12,
			highest: 150.22,
			volumePrice: 150.161,
			transactionCount: 575,
			voluemPrice: 150.161
		},
		{
			open: 150.175,
			close: 150.215,
			volume: 61224,
			time: 1654191540000,
			lowest: 150.15,
			highest: 150.22,
			volumePrice: 150.184,
			transactionCount: 637,
			voluemPrice: 150.184
		},
		{
			open: 150.22,
			close: 150.17,
			volume: 110900,
			time: 1654191600000,
			lowest: 150.11,
			highest: 150.25,
			volumePrice: 150.1722,
			transactionCount: 1052,
			voluemPrice: 150.1722
		},
		{
			open: 150.18,
			close: 150.16,
			volume: 47901,
			time: 1654191660000,
			lowest: 150.11,
			highest: 150.1968,
			volumePrice: 150.1423,
			transactionCount: 590,
			voluemPrice: 150.1423
		},
		{
			open: 150.17,
			close: 150.19,
			volume: 53007,
			time: 1654191720000,
			lowest: 150.16,
			highest: 150.22,
			volumePrice: 150.1908,
			transactionCount: 628,
			voluemPrice: 150.1908
		},
		{
			open: 150.1899,
			close: 150.285,
			volume: 72598,
			time: 1654191780000,
			lowest: 150.15,
			highest: 150.29,
			volumePrice: 150.2308,
			transactionCount: 629,
			voluemPrice: 150.2308
		},
		{
			open: 150.29,
			close: 150.405,
			volume: 104256,
			time: 1654191840000,
			lowest: 150.27,
			highest: 150.415,
			volumePrice: 150.3485,
			transactionCount: 936,
			voluemPrice: 150.3485
		},
		{
			open: 150.41,
			close: 150.3773,
			volume: 128444,
			time: 1654191900000,
			lowest: 150.31,
			highest: 150.46,
			volumePrice: 150.3931,
			transactionCount: 1030,
			voluemPrice: 150.3931
		},
		{
			open: 150.38,
			close: 150.4,
			volume: 87715,
			time: 1654191960000,
			lowest: 150.343,
			highest: 150.43,
			volumePrice: 150.3837,
			transactionCount: 798,
			voluemPrice: 150.3837
		},
		{
			open: 150.4,
			close: 150.37,
			volume: 59328,
			time: 1654192020000,
			lowest: 150.34,
			highest: 150.41,
			volumePrice: 150.375,
			transactionCount: 616,
			voluemPrice: 150.375
		},
		{
			open: 150.37,
			close: 150.38,
			volume: 38662,
			time: 1654192080000,
			lowest: 150.35,
			highest: 150.3999,
			volumePrice: 150.3739,
			transactionCount: 519,
			voluemPrice: 150.3739
		},
		{
			open: 150.38,
			close: 150.44,
			volume: 73978,
			time: 1654192140000,
			lowest: 150.37,
			highest: 150.4447,
			volumePrice: 150.403,
			transactionCount: 683,
			voluemPrice: 150.403
		},
		{
			open: 150.44,
			close: 150.5256,
			volume: 138786,
			time: 1654192200000,
			lowest: 150.44,
			highest: 150.54,
			volumePrice: 150.4903,
			transactionCount: 1124,
			voluemPrice: 150.4903
		},
		{
			open: 150.5201,
			close: 150.465,
			volume: 106085,
			time: 1654192260000,
			lowest: 150.42,
			highest: 150.54,
			volumePrice: 150.4792,
			transactionCount: 967,
			voluemPrice: 150.4792
		},
		{
			open: 150.47,
			close: 150.42,
			volume: 93610,
			time: 1654192320000,
			lowest: 150.4,
			highest: 150.475,
			volumePrice: 150.4255,
			transactionCount: 857,
			voluemPrice: 150.4255
		},
		{
			open: 150.41,
			close: 150.385,
			volume: 70696,
			time: 1654192380000,
			lowest: 150.38,
			highest: 150.45,
			volumePrice: 150.4143,
			transactionCount: 730,
			voluemPrice: 150.4143
		},
		{
			open: 150.38,
			close: 150.365,
			volume: 97714,
			time: 1654192440000,
			lowest: 150.3,
			highest: 150.38,
			volumePrice: 150.3428,
			transactionCount: 956,
			voluemPrice: 150.3428
		},
		{
			open: 150.36,
			close: 150.2,
			volume: 124967,
			time: 1654192500000,
			lowest: 150.12,
			highest: 150.365,
			volumePrice: 150.2294,
			transactionCount: 1236,
			voluemPrice: 150.2294
		},
		{
			open: 150.1901,
			close: 150.28,
			volume: 75441,
			time: 1654192560000,
			lowest: 150.17,
			highest: 150.29,
			volumePrice: 150.2357,
			transactionCount: 707,
			voluemPrice: 150.2357
		},
		{
			open: 150.265,
			close: 150.24,
			volume: 80785,
			time: 1654192620000,
			lowest: 150.2,
			highest: 150.32,
			volumePrice: 150.2562,
			transactionCount: 911,
			voluemPrice: 150.2562
		},
		{
			open: 150.245,
			close: 150.255,
			volume: 119036,
			time: 1654192680000,
			lowest: 150.23,
			highest: 150.33,
			volumePrice: 150.2855,
			transactionCount: 1117,
			voluemPrice: 150.2855
		},
		{
			open: 150.255,
			close: 150.305,
			volume: 74512,
			time: 1654192740000,
			lowest: 150.21,
			highest: 150.3427,
			volumePrice: 150.2801,
			transactionCount: 751,
			voluemPrice: 150.2801
		},
		{
			open: 150.31,
			close: 150.28,
			volume: 159689,
			time: 1654192800000,
			lowest: 150.13,
			highest: 150.35,
			volumePrice: 150.2654,
			transactionCount: 1480,
			voluemPrice: 150.2654
		},
		{
			open: 150.28,
			close: 150.295,
			volume: 97233,
			time: 1654192860000,
			lowest: 150.235,
			highest: 150.33,
			volumePrice: 150.296,
			transactionCount: 842,
			voluemPrice: 150.296
		},
		{
			open: 150.31,
			close: 150.43,
			volume: 107262,
			time: 1654192920000,
			lowest: 150.28,
			highest: 150.4427,
			volumePrice: 150.3633,
			transactionCount: 968,
			voluemPrice: 150.3633
		},
		{
			open: 150.435,
			close: 150.56,
			volume: 159006,
			time: 1654192980000,
			lowest: 150.41,
			highest: 150.56,
			volumePrice: 150.4926,
			transactionCount: 1241,
			voluemPrice: 150.4926
		},
		{
			open: 150.56,
			close: 150.555,
			volume: 74578,
			time: 1654193040000,
			lowest: 150.52,
			highest: 150.59,
			volumePrice: 150.5598,
			transactionCount: 777,
			voluemPrice: 150.5598
		},
		{
			open: 150.56,
			close: 150.534,
			volume: 77227,
			time: 1654193100000,
			lowest: 150.5,
			highest: 150.575,
			volumePrice: 150.5302,
			transactionCount: 724,
			voluemPrice: 150.5302
		},
		{
			open: 150.54,
			close: 150.625,
			volume: 117399,
			time: 1654193160000,
			lowest: 150.52,
			highest: 150.6346,
			volumePrice: 150.5739,
			transactionCount: 1061,
			voluemPrice: 150.5739
		},
		{
			open: 150.63,
			close: 150.55,
			volume: 193601,
			time: 1654193220000,
			lowest: 150.44,
			highest: 150.68,
			volumePrice: 150.5987,
			transactionCount: 1779,
			voluemPrice: 150.5987
		},
		{
			open: 150.55,
			close: 150.5778,
			volume: 67947,
			time: 1654193280000,
			lowest: 150.5,
			highest: 150.59,
			volumePrice: 150.5519,
			transactionCount: 636,
			voluemPrice: 150.5519
		},
		{
			open: 150.58,
			close: 150.59,
			volume: 103368,
			time: 1654193340000,
			lowest: 150.5355,
			highest: 150.6101,
			volumePrice: 150.5428,
			transactionCount: 705,
			voluemPrice: 150.5428
		},
		{
			open: 150.59,
			close: 150.6599,
			volume: 75913,
			time: 1654193400000,
			lowest: 150.5,
			highest: 150.67,
			volumePrice: 150.5945,
			transactionCount: 672,
			voluemPrice: 150.5945
		},
		{
			open: 150.6522,
			close: 150.5499,
			volume: 112947,
			time: 1654193460000,
			lowest: 150.52,
			highest: 150.67,
			volumePrice: 150.6028,
			transactionCount: 941,
			voluemPrice: 150.6028
		},
		{
			open: 150.55,
			close: 150.625,
			volume: 91510,
			time: 1654193520000,
			lowest: 150.47,
			highest: 150.625,
			volumePrice: 150.5346,
			transactionCount: 845,
			voluemPrice: 150.5346
		},
		{
			open: 150.6239,
			close: 150.63,
			volume: 64782,
			time: 1654193580000,
			lowest: 150.57,
			highest: 150.6399,
			volumePrice: 150.6135,
			transactionCount: 710,
			voluemPrice: 150.6135
		},
		{
			open: 150.64,
			close: 150.775,
			volume: 177240,
			time: 1654193640000,
			lowest: 150.62,
			highest: 150.8,
			volumePrice: 150.7231,
			transactionCount: 1388,
			voluemPrice: 150.7231
		},
		{
			open: 150.7745,
			close: 150.75,
			volume: 140216,
			time: 1654193700000,
			lowest: 150.68,
			highest: 150.78,
			volumePrice: 150.7391,
			transactionCount: 1004,
			voluemPrice: 150.7391
		},
		{
			open: 150.75,
			close: 150.67,
			volume: 103832,
			time: 1654193760000,
			lowest: 150.66,
			highest: 150.7799,
			volumePrice: 150.7174,
			transactionCount: 770,
			voluemPrice: 150.7174
		},
		{
			open: 150.66,
			close: 150.742,
			volume: 94084,
			time: 1654193820000,
			lowest: 150.61,
			highest: 150.7428,
			volumePrice: 150.6688,
			transactionCount: 876,
			voluemPrice: 150.6688
		},
		{
			open: 150.742,
			close: 150.744,
			volume: 71150,
			time: 1654193880000,
			lowest: 150.7,
			highest: 150.76,
			volumePrice: 150.7279,
			transactionCount: 604,
			voluemPrice: 150.7279
		},
		{
			open: 150.75,
			close: 150.75,
			volume: 87172,
			time: 1654193940000,
			lowest: 150.69,
			highest: 150.79,
			volumePrice: 150.7485,
			transactionCount: 773,
			voluemPrice: 150.7485
		},
		{
			open: 150.75,
			close: 150.765,
			volume: 107873,
			time: 1654194000000,
			lowest: 150.7,
			highest: 150.79,
			volumePrice: 150.7528,
			transactionCount: 802,
			voluemPrice: 150.7528
		},
		{
			open: 150.77,
			close: 150.7799,
			volume: 118149,
			time: 1654194060000,
			lowest: 150.75,
			highest: 150.83,
			volumePrice: 150.783,
			transactionCount: 762,
			voluemPrice: 150.783
		},
		{
			open: 150.78,
			close: 150.7057,
			volume: 98196,
			time: 1654194120000,
			lowest: 150.68,
			highest: 150.79,
			volumePrice: 150.7472,
			transactionCount: 695,
			voluemPrice: 150.7472
		},
		{
			open: 150.71,
			close: 150.72,
			volume: 78958,
			time: 1654194180000,
			lowest: 150.69,
			highest: 150.77,
			volumePrice: 150.7321,
			transactionCount: 706,
			voluemPrice: 150.7321
		},
		{
			open: 150.72,
			close: 150.67,
			volume: 50940,
			time: 1654194240000,
			lowest: 150.65,
			highest: 150.7275,
			volumePrice: 150.6962,
			transactionCount: 574,
			voluemPrice: 150.6962
		},
		{
			open: 150.68,
			close: 150.62,
			volume: 69193,
			time: 1654194300000,
			lowest: 150.61,
			highest: 150.69,
			volumePrice: 150.6493,
			transactionCount: 687,
			voluemPrice: 150.6493
		},
		{
			open: 150.625,
			close: 150.625,
			volume: 107314,
			time: 1654194360000,
			lowest: 150.57,
			highest: 150.64,
			volumePrice: 150.608,
			transactionCount: 856,
			voluemPrice: 150.608
		},
		{
			open: 150.63,
			close: 150.57,
			volume: 85806,
			time: 1654194420000,
			lowest: 150.55,
			highest: 150.67,
			volumePrice: 150.5989,
			transactionCount: 811,
			voluemPrice: 150.5989
		},
		{
			open: 150.57,
			close: 150.4891,
			volume: 111555,
			time: 1654194480000,
			lowest: 150.4301,
			highest: 150.575,
			volumePrice: 150.5192,
			transactionCount: 906,
			voluemPrice: 150.5192
		},
		{
			open: 150.489,
			close: 150.53,
			volume: 99437,
			time: 1654194540000,
			lowest: 150.43,
			highest: 150.55,
			volumePrice: 150.4921,
			transactionCount: 938,
			voluemPrice: 150.4921
		},
		{
			open: 150.525,
			close: 150.59,
			volume: 96921,
			time: 1654194600000,
			lowest: 150.49,
			highest: 150.59,
			volumePrice: 150.5366,
			transactionCount: 706,
			voluemPrice: 150.5366
		},
		{
			open: 150.59,
			close: 150.61,
			volume: 113466,
			time: 1654194660000,
			lowest: 150.56,
			highest: 150.64,
			volumePrice: 150.6011,
			transactionCount: 862,
			voluemPrice: 150.6011
		},
		{
			open: 150.605,
			close: 150.659,
			volume: 73248,
			time: 1654194720000,
			lowest: 150.59,
			highest: 150.69,
			volumePrice: 150.6507,
			transactionCount: 639,
			voluemPrice: 150.6507
		},
		{
			open: 150.66,
			close: 150.625,
			volume: 74377,
			time: 1654194780000,
			lowest: 150.62,
			highest: 150.7,
			volumePrice: 150.6543,
			transactionCount: 779,
			voluemPrice: 150.6543
		},
		{
			open: 150.62,
			close: 150.64,
			volume: 57392,
			time: 1654194840000,
			lowest: 150.6101,
			highest: 150.68,
			volumePrice: 150.645,
			transactionCount: 600,
			voluemPrice: 150.645
		},
		{
			open: 150.63,
			close: 150.6575,
			volume: 47494,
			time: 1654194900000,
			lowest: 150.61,
			highest: 150.66,
			volumePrice: 150.6265,
			transactionCount: 580,
			voluemPrice: 150.6265
		},
		{
			open: 150.65,
			close: 150.57,
			volume: 93671,
			time: 1654194960000,
			lowest: 150.55,
			highest: 150.65,
			volumePrice: 150.5981,
			transactionCount: 757,
			voluemPrice: 150.5981
		},
		{
			open: 150.57,
			close: 150.55,
			volume: 78432,
			time: 1654195020000,
			lowest: 150.5,
			highest: 150.59,
			volumePrice: 150.5424,
			transactionCount: 874,
			voluemPrice: 150.5424
		},
		{
			open: 150.55,
			close: 150.58,
			volume: 60990,
			time: 1654195080000,
			lowest: 150.55,
			highest: 150.61,
			volumePrice: 150.5859,
			transactionCount: 535,
			voluemPrice: 150.5859
		},
		{
			open: 150.59,
			close: 150.5542,
			volume: 43791,
			time: 1654195140000,
			lowest: 150.54,
			highest: 150.6,
			volumePrice: 150.5708,
			transactionCount: 515,
			voluemPrice: 150.5708
		},
		{
			open: 150.56,
			close: 150.4826,
			volume: 61940,
			time: 1654195200000,
			lowest: 150.47,
			highest: 150.56,
			volumePrice: 150.5092,
			transactionCount: 680,
			voluemPrice: 150.5092
		},
		{
			open: 150.4826,
			close: 150.48,
			volume: 120207,
			time: 1654195260000,
			lowest: 150.43,
			highest: 150.545,
			volumePrice: 150.4946,
			transactionCount: 854,
			voluemPrice: 150.4946
		},
		{
			open: 150.481,
			close: 150.3944,
			volume: 105066,
			time: 1654195320000,
			lowest: 150.36,
			highest: 150.481,
			volumePrice: 150.3962,
			transactionCount: 910,
			voluemPrice: 150.3962
		},
		{
			open: 150.395,
			close: 150.3201,
			volume: 116539,
			time: 1654195380000,
			lowest: 150.27,
			highest: 150.41,
			volumePrice: 150.3293,
			transactionCount: 914,
			voluemPrice: 150.3293
		},
		{
			open: 150.325,
			close: 150.43,
			volume: 95094,
			time: 1654195440000,
			lowest: 150.32,
			highest: 150.46,
			volumePrice: 150.3992,
			transactionCount: 874,
			voluemPrice: 150.3992
		},
		{
			open: 150.43,
			close: 150.33,
			volume: 73026,
			time: 1654195500000,
			lowest: 150.33,
			highest: 150.43,
			volumePrice: 150.3829,
			transactionCount: 768,
			voluemPrice: 150.3829
		},
		{
			open: 150.33,
			close: 150.38,
			volume: 65506,
			time: 1654195560000,
			lowest: 150.32,
			highest: 150.41,
			volumePrice: 150.3674,
			transactionCount: 689,
			voluemPrice: 150.3674
		},
		{
			open: 150.3829,
			close: 150.44,
			volume: 101210,
			time: 1654195620000,
			lowest: 150.34,
			highest: 150.46,
			volumePrice: 150.4143,
			transactionCount: 926,
			voluemPrice: 150.4143
		},
		{
			open: 150.44,
			close: 150.46,
			volume: 61274,
			time: 1654195680000,
			lowest: 150.395,
			highest: 150.47,
			volumePrice: 150.429,
			transactionCount: 631,
			voluemPrice: 150.429
		},
		{
			open: 150.46,
			close: 150.2815,
			volume: 131640,
			time: 1654195740000,
			lowest: 150.28,
			highest: 150.4979,
			volumePrice: 150.3751,
			transactionCount: 1406,
			voluemPrice: 150.3751
		},
		{
			open: 150.28,
			close: 150.31,
			volume: 129760,
			time: 1654195800000,
			lowest: 150.1932,
			highest: 150.3551,
			volumePrice: 150.2529,
			transactionCount: 1041,
			voluemPrice: 150.2529
		},
		{
			open: 150.31,
			close: 150.4189,
			volume: 82621,
			time: 1654195860000,
			lowest: 150.27,
			highest: 150.4191,
			volumePrice: 150.3262,
			transactionCount: 783,
			voluemPrice: 150.3262
		},
		{
			open: 150.415,
			close: 150.532,
			volume: 157408,
			time: 1654195920000,
			lowest: 150.375,
			highest: 150.56,
			volumePrice: 150.4801,
			transactionCount: 1445,
			voluemPrice: 150.4801
		},
		{
			open: 150.535,
			close: 150.5,
			volume: 74740,
			time: 1654195980000,
			lowest: 150.46,
			highest: 150.56,
			volumePrice: 150.5004,
			transactionCount: 705,
			voluemPrice: 150.5004
		},
		{
			open: 150.495,
			close: 150.4,
			volume: 89238,
			time: 1654196040000,
			lowest: 150.39,
			highest: 150.54,
			volumePrice: 150.46,
			transactionCount: 790,
			voluemPrice: 150.46
		},
		{
			open: 150.39,
			close: 150.4182,
			volume: 76140,
			time: 1654196100000,
			lowest: 150.3359,
			highest: 150.4899,
			volumePrice: 150.4168,
			transactionCount: 752,
			voluemPrice: 150.4168
		},
		{
			open: 150.4,
			close: 150.27,
			volume: 84081,
			time: 1654196160000,
			lowest: 150.2584,
			highest: 150.415,
			volumePrice: 150.3191,
			transactionCount: 810,
			voluemPrice: 150.3191
		},
		{
			open: 150.25,
			close: 150.16,
			volume: 131982,
			time: 1654196220000,
			lowest: 150.16,
			highest: 150.27,
			volumePrice: 150.2052,
			transactionCount: 1151,
			voluemPrice: 150.2052
		},
		{
			open: 150.15,
			close: 150.11,
			volume: 100996,
			time: 1654196280000,
			lowest: 150.11,
			highest: 150.22,
			volumePrice: 150.1787,
			transactionCount: 871,
			voluemPrice: 150.1787
		},
		{
			open: 150.11,
			close: 150.11,
			volume: 138321,
			time: 1654196340000,
			lowest: 150.0701,
			highest: 150.15,
			volumePrice: 150.1072,
			transactionCount: 1034,
			voluemPrice: 150.1072
		},
		{
			open: 150.1091,
			close: 150.21,
			volume: 134029,
			time: 1654196400000,
			lowest: 150.06,
			highest: 150.27,
			volumePrice: 150.1772,
			transactionCount: 1290,
			voluemPrice: 150.1772
		},
		{
			open: 150.21,
			close: 150.1938,
			volume: 86577,
			time: 1654196460000,
			lowest: 150.11,
			highest: 150.2557,
			volumePrice: 150.1685,
			transactionCount: 993,
			voluemPrice: 150.1685
		},
		{
			open: 150.195,
			close: 150.2399,
			volume: 119418,
			time: 1654196520000,
			lowest: 150.16,
			highest: 150.265,
			volumePrice: 150.2218,
			transactionCount: 988,
			voluemPrice: 150.2218
		},
		{
			open: 150.2457,
			close: 150.375,
			volume: 98159,
			time: 1654196580000,
			lowest: 150.16,
			highest: 150.41,
			volumePrice: 150.2673,
			transactionCount: 889,
			voluemPrice: 150.2673
		},
		{
			open: 150.38,
			close: 150.2963,
			volume: 111358,
			time: 1654196640000,
			lowest: 150.29,
			highest: 150.39,
			volumePrice: 150.3436,
			transactionCount: 995,
			voluemPrice: 150.3436
		},
		{
			open: 150.295,
			close: 150.34,
			volume: 85758,
			time: 1654196700000,
			lowest: 150.2302,
			highest: 150.3699,
			volumePrice: 150.2919,
			transactionCount: 694,
			voluemPrice: 150.2919
		},
		{
			open: 150.3475,
			close: 150.31,
			volume: 121035,
			time: 1654196760000,
			lowest: 150.2612,
			highest: 150.38,
			volumePrice: 150.3217,
			transactionCount: 980,
			voluemPrice: 150.3217
		},
		{
			open: 150.32,
			close: 150.35,
			volume: 68969,
			time: 1654196820000,
			lowest: 150.24,
			highest: 150.37,
			volumePrice: 150.2985,
			transactionCount: 667,
			voluemPrice: 150.2985
		},
		{
			open: 150.34,
			close: 150.32,
			volume: 82872,
			time: 1654196880000,
			lowest: 150.285,
			highest: 150.3599,
			volumePrice: 150.3267,
			transactionCount: 759,
			voluemPrice: 150.3267
		},
		{
			open: 150.31,
			close: 150.32,
			volume: 114466,
			time: 1654196940000,
			lowest: 150.285,
			highest: 150.42,
			volumePrice: 150.3562,
			transactionCount: 1052,
			voluemPrice: 150.3562
		},
		{
			open: 150.315,
			close: 150.36,
			volume: 141511,
			time: 1654197000000,
			lowest: 150.245,
			highest: 150.37,
			volumePrice: 150.3111,
			transactionCount: 940,
			voluemPrice: 150.3111
		},
		{
			open: 150.37,
			close: 150.45,
			volume: 108878,
			time: 1654197060000,
			lowest: 150.3501,
			highest: 150.49,
			volumePrice: 150.4208,
			transactionCount: 864,
			voluemPrice: 150.4208
		},
		{
			open: 150.46,
			close: 150.3947,
			volume: 95970,
			time: 1654197120000,
			lowest: 150.37,
			highest: 150.46,
			volumePrice: 150.4173,
			transactionCount: 970,
			voluemPrice: 150.4173
		},
		{
			open: 150.385,
			close: 150.345,
			volume: 99151,
			time: 1654197180000,
			lowest: 150.3219,
			highest: 150.43,
			volumePrice: 150.3792,
			transactionCount: 897,
			voluemPrice: 150.3792
		},
		{
			open: 150.35,
			close: 150.4328,
			volume: 86454,
			time: 1654197240000,
			lowest: 150.35,
			highest: 150.47,
			volumePrice: 150.4211,
			transactionCount: 772,
			voluemPrice: 150.4211
		},
		{
			open: 150.4321,
			close: 150.4,
			volume: 70703,
			time: 1654197300000,
			lowest: 150.36,
			highest: 150.4546,
			volumePrice: 150.402,
			transactionCount: 802,
			voluemPrice: 150.402
		},
		{
			open: 150.4,
			close: 150.485,
			volume: 82575,
			time: 1654197360000,
			lowest: 150.385,
			highest: 150.49,
			volumePrice: 150.4299,
			transactionCount: 671,
			voluemPrice: 150.4299
		},
		{
			open: 150.48,
			close: 150.47,
			volume: 161248,
			time: 1654197420000,
			lowest: 150.455,
			highest: 150.6099,
			volumePrice: 150.5415,
			transactionCount: 1445,
			voluemPrice: 150.5415
		},
		{
			open: 150.4686,
			close: 150.5299,
			volume: 97179,
			time: 1654197480000,
			lowest: 150.45,
			highest: 150.56,
			volumePrice: 150.5152,
			transactionCount: 723,
			voluemPrice: 150.5152
		},
		{
			open: 150.52,
			close: 150.525,
			volume: 78858,
			time: 1654197540000,
			lowest: 150.45,
			highest: 150.545,
			volumePrice: 150.4928,
			transactionCount: 757,
			voluemPrice: 150.4928
		},
		{
			open: 150.53,
			close: 150.52,
			volume: 83666,
			time: 1654197600000,
			lowest: 150.455,
			highest: 150.5377,
			volumePrice: 150.4972,
			transactionCount: 744,
			voluemPrice: 150.4972
		},
		{
			open: 150.53,
			close: 150.5099,
			volume: 122849,
			time: 1654197660000,
			lowest: 150.48,
			highest: 150.6,
			volumePrice: 150.5332,
			transactionCount: 997,
			voluemPrice: 150.5332
		},
		{
			open: 150.501,
			close: 150.4071,
			volume: 149041,
			time: 1654197720000,
			lowest: 150.32,
			highest: 150.51,
			volumePrice: 150.3984,
			transactionCount: 1161,
			voluemPrice: 150.3984
		},
		{
			open: 150.41,
			close: 150.415,
			volume: 94297,
			time: 1654197780000,
			lowest: 150.33,
			highest: 150.42,
			volumePrice: 150.3826,
			transactionCount: 804,
			voluemPrice: 150.3826
		},
		{
			open: 150.42,
			close: 150.501,
			volume: 159136,
			time: 1654197840000,
			lowest: 150.42,
			highest: 150.57,
			volumePrice: 150.5167,
			transactionCount: 1136,
			voluemPrice: 150.5167
		},
		{
			open: 150.51,
			close: 150.55,
			volume: 137256,
			time: 1654197900000,
			lowest: 150.44,
			highest: 150.6,
			volumePrice: 150.5265,
			transactionCount: 1195,
			voluemPrice: 150.5265
		},
		{
			open: 150.555,
			close: 150.25,
			volume: 253497,
			time: 1654197960000,
			lowest: 150.19,
			highest: 150.555,
			volumePrice: 150.3179,
			transactionCount: 2194,
			voluemPrice: 150.3179
		},
		{
			open: 150.24,
			close: 150.26,
			volume: 151233,
			time: 1654198020000,
			lowest: 150.18,
			highest: 150.31,
			volumePrice: 150.2593,
			transactionCount: 1303,
			voluemPrice: 150.2593
		},
		{
			open: 150.255,
			close: 150.2605,
			volume: 118154,
			time: 1654198080000,
			lowest: 150.2,
			highest: 150.3,
			volumePrice: 150.2611,
			transactionCount: 1010,
			voluemPrice: 150.2611
		},
		{
			open: 150.265,
			close: 150.3477,
			volume: 123220,
			time: 1654198140000,
			lowest: 150.195,
			highest: 150.3799,
			volumePrice: 150.2632,
			transactionCount: 1012,
			voluemPrice: 150.2632
		},
		{
			open: 150.34,
			close: 150.4,
			volume: 188699,
			time: 1654198200000,
			lowest: 150.3,
			highest: 150.51,
			volumePrice: 150.4266,
			transactionCount: 1446,
			voluemPrice: 150.4266
		},
		{
			open: 150.41,
			close: 150.47,
			volume: 154751,
			time: 1654198260000,
			lowest: 150.37,
			highest: 150.53,
			volumePrice: 150.463,
			transactionCount: 1226,
			voluemPrice: 150.463
		},
		{
			open: 150.48,
			close: 150.455,
			volume: 138432,
			time: 1654198320000,
			lowest: 150.37,
			highest: 150.52,
			volumePrice: 150.4315,
			transactionCount: 1056,
			voluemPrice: 150.4315
		},
		{
			open: 150.46,
			close: 150.33,
			volume: 158193,
			time: 1654198380000,
			lowest: 150.325,
			highest: 150.517,
			volumePrice: 150.4455,
			transactionCount: 1304,
			voluemPrice: 150.4455
		},
		{
			open: 150.32,
			close: 150.4657,
			volume: 90480,
			time: 1654198440000,
			lowest: 150.32,
			highest: 150.48,
			volumePrice: 150.4008,
			transactionCount: 827,
			voluemPrice: 150.4008
		},
		{
			open: 150.47,
			close: 150.595,
			volume: 212144,
			time: 1654198500000,
			lowest: 150.45,
			highest: 150.63,
			volumePrice: 150.5508,
			transactionCount: 1239,
			voluemPrice: 150.5508
		},
		{
			open: 150.5925,
			close: 150.5605,
			volume: 127729,
			time: 1654198560000,
			lowest: 150.51,
			highest: 150.62,
			volumePrice: 150.5751,
			transactionCount: 1237,
			voluemPrice: 150.5751
		},
		{
			open: 150.56,
			close: 150.48,
			volume: 121284,
			time: 1654198620000,
			lowest: 150.4301,
			highest: 150.585,
			volumePrice: 150.4915,
			transactionCount: 1199,
			voluemPrice: 150.4915
		},
		{
			open: 150.49,
			close: 150.49,
			volume: 96915,
			time: 1654198680000,
			lowest: 150.44,
			highest: 150.53,
			volumePrice: 150.4926,
			transactionCount: 889,
			voluemPrice: 150.4926
		},
		{
			open: 150.49,
			close: 150.48,
			volume: 110870,
			time: 1654198740000,
			lowest: 150.4002,
			highest: 150.49,
			volumePrice: 150.4518,
			transactionCount: 1078,
			voluemPrice: 150.4518
		},
		{
			open: 150.49,
			close: 150.59,
			volume: 129958,
			time: 1654198800000,
			lowest: 150.44,
			highest: 150.6,
			volumePrice: 150.5156,
			transactionCount: 1325,
			voluemPrice: 150.5156
		},
		{
			open: 150.6,
			close: 150.51,
			volume: 127769,
			time: 1654198860000,
			lowest: 150.51,
			highest: 150.63,
			volumePrice: 150.5847,
			transactionCount: 1166,
			voluemPrice: 150.5847
		},
		{
			open: 150.52,
			close: 150.565,
			volume: 133153,
			time: 1654198920000,
			lowest: 150.48,
			highest: 150.6,
			volumePrice: 150.5378,
			transactionCount: 1191,
			voluemPrice: 150.5378
		},
		{
			open: 150.58,
			close: 150.56,
			volume: 96444,
			time: 1654198980000,
			lowest: 150.53,
			highest: 150.61,
			volumePrice: 150.5691,
			transactionCount: 901,
			voluemPrice: 150.5691
		},
		{
			open: 150.565,
			close: 150.73,
			volume: 222427,
			time: 1654199040000,
			lowest: 150.56,
			highest: 150.735,
			volumePrice: 150.6559,
			transactionCount: 1686,
			voluemPrice: 150.6559
		},
		{
			open: 150.725,
			close: 150.742,
			volume: 209572,
			time: 1654199100000,
			lowest: 150.62,
			highest: 150.78,
			volumePrice: 150.7103,
			transactionCount: 1873,
			voluemPrice: 150.7103
		},
		{
			open: 150.74,
			close: 150.75,
			volume: 129588,
			time: 1654199160000,
			lowest: 150.675,
			highest: 150.7599,
			volumePrice: 150.7061,
			transactionCount: 1261,
			voluemPrice: 150.7061
		},
		{
			open: 150.75,
			close: 150.545,
			volume: 194468,
			time: 1654199220000,
			lowest: 150.51,
			highest: 150.78,
			volumePrice: 150.6647,
			transactionCount: 1646,
			voluemPrice: 150.6647
		},
		{
			open: 150.55,
			close: 150.6862,
			volume: 232637,
			time: 1654199280000,
			lowest: 150.54,
			highest: 150.73,
			volumePrice: 150.6468,
			transactionCount: 2112,
			voluemPrice: 150.6468
		},
		{
			open: 150.69,
			close: 150.72,
			volume: 297621,
			time: 1654199340000,
			lowest: 150.685,
			highest: 150.89,
			volumePrice: 150.7771,
			transactionCount: 2545,
			voluemPrice: 150.7771
		},
		{
			open: 150.73,
			close: 150.905,
			volume: 378941,
			time: 1654199400000,
			lowest: 150.73,
			highest: 151,
			volumePrice: 150.8992,
			transactionCount: 3720,
			voluemPrice: 150.8992
		},
		{
			open: 150.91,
			close: 150.7989,
			volume: 411649,
			time: 1654199460000,
			lowest: 150.79,
			highest: 151.08,
			volumePrice: 150.9374,
			transactionCount: 4443,
			voluemPrice: 150.9374
		},
		{
			open: 150.78,
			close: 150.82,
			volume: 294703,
			time: 1654199520000,
			lowest: 150.66,
			highest: 150.86,
			volumePrice: 150.7504,
			transactionCount: 2752,
			voluemPrice: 150.7504
		},
		{
			open: 150.825,
			close: 150.9,
			volume: 220495,
			time: 1654199580000,
			lowest: 150.82,
			highest: 150.94,
			volumePrice: 150.9007,
			transactionCount: 1906,
			voluemPrice: 150.9007
		},
		{
			open: 150.8911,
			close: 150.9845,
			volume: 542538,
			time: 1654199640000,
			lowest: 150.86,
			highest: 151.08,
			volumePrice: 150.9717,
			transactionCount: 5022,
			voluemPrice: 150.9717
		},
		{
			open: 150.98,
			close: 151.015,
			volume: 439434,
			time: 1654199700000,
			lowest: 150.885,
			highest: 151.09,
			volumePrice: 151.0114,
			transactionCount: 3829,
			voluemPrice: 151.0114
		},
		{
			open: 151.02,
			close: 151.1335,
			volume: 431706,
			time: 1654199760000,
			lowest: 150.795,
			highest: 151.21,
			volumePrice: 151.098,
			transactionCount: 3786,
			voluemPrice: 151.098
		},
		{
			open: 151.13,
			close: 151.195,
			volume: 424469,
			time: 1654199820000,
			lowest: 151.08,
			highest: 151.2,
			volumePrice: 151.1441,
			transactionCount: 3366,
			voluemPrice: 151.1441
		},
		{
			open: 151.2,
			close: 151.205,
			volume: 627484,
			time: 1654199880000,
			lowest: 151.14,
			highest: 151.24,
			volumePrice: 151.1883,
			transactionCount: 4683,
			voluemPrice: 151.1883
		},
		{
			open: 151.2,
			close: 151.23,
			volume: 1177124,
			time: 1654199940000,
			lowest: 151.13,
			highest: 151.27,
			volumePrice: 151.2176,
			transactionCount: 7213,
			voluemPrice: 151.2176
		},
		{
			open: 151.21,
			close: 151.21,
			volume: 202018,
			time: 1654200000000,
			lowest: 151.09,
			highest: 151.21,
			volumePrice: 151.1931,
			transactionCount: 122,
			voluemPrice: 151.1931
		},
		{
			open: 151.17,
			close: 151.21,
			volume: 795118,
			time: 1654200060000,
			lowest: 151.1,
			highest: 151.21,
			volumePrice: 151.2094,
			transactionCount: 151,
			voluemPrice: 151.2094
		},
		{
			open: 151.1101,
			close: 151.17,
			volume: 8654,
			time: 1654200120000,
			lowest: 151.1101,
			highest: 151.17,
			volumePrice: 151.1863,
			transactionCount: 58,
			voluemPrice: 151.1863
		},
		{
			open: 151.16,
			close: 151.14,
			volume: 19765,
			time: 1654200180000,
			lowest: 151.14,
			highest: 151.2,
			volumePrice: 151.1542,
			transactionCount: 75,
			voluemPrice: 151.1542
		},
		{
			open: 151.11,
			close: 151.09,
			volume: 5647,
			time: 1654200240000,
			lowest: 151.01,
			highest: 151.11,
			volumePrice: 151.0745,
			transactionCount: 95,
			voluemPrice: 151.0745
		},
		{
			open: 151.09,
			close: 151.09,
			volume: 9377,
			time: 1654200300000,
			lowest: 151.04,
			highest: 151.1,
			volumePrice: 151.0586,
			transactionCount: 71,
			voluemPrice: 151.0586
		},
		{
			open: 151.09,
			close: 151.0482,
			volume: 13172,
			time: 1654200360000,
			lowest: 151.02,
			highest: 151.21,
			volumePrice: 151.1664,
			transactionCount: 69,
			voluemPrice: 151.1664
		},
		{
			open: 151.05,
			close: 150.9973,
			volume: 110552,
			time: 1654200420000,
			lowest: 150.96,
			highest: 151.21,
			volumePrice: 151.2036,
			transactionCount: 70,
			voluemPrice: 151.2036
		},
		{
			open: 150.96,
			close: 151,
			volume: 3485,
			time: 1654200480000,
			lowest: 150.96,
			highest: 151.21,
			volumePrice: 151.0807,
			transactionCount: 62,
			voluemPrice: 151.0807
		},
		{
			open: 151,
			close: 151,
			volume: 34804,
			time: 1654200540000,
			lowest: 151,
			highest: 151.21,
			volumePrice: 151.2073,
			transactionCount: 32,
			voluemPrice: 151.2073
		},
		{
			open: 150.99,
			close: 150.9,
			volume: 4855,
			time: 1654200600000,
			lowest: 150.89,
			highest: 151,
			volumePrice: 150.9545,
			transactionCount: 78,
			voluemPrice: 150.9545
		},
		{
			open: 150.9,
			close: 150.94,
			volume: 13625,
			time: 1654200660000,
			lowest: 150.8899,
			highest: 151.21,
			volumePrice: 151.1683,
			transactionCount: 52,
			voluemPrice: 151.1683
		},
		{
			open: 150.9,
			close: 150.89,
			volume: 13303,
			time: 1654200720000,
			lowest: 150.89,
			highest: 151.21,
			volumePrice: 151.0027,
			transactionCount: 173,
			voluemPrice: 151.0027
		},
		{
			open: 150.91,
			close: 151.21,
			volume: 8202,
			time: 1654200780000,
			lowest: 150.8,
			highest: 151.21,
			volumePrice: 150.9533,
			transactionCount: 74,
			voluemPrice: 150.9533
		},
		{
			open: 150.9,
			close: 150.84,
			volume: 937,
			time: 1654200840000,
			lowest: 150.84,
			highest: 150.9,
			volumePrice: 150.8804,
			transactionCount: 28,
			voluemPrice: 150.8804
		},
		{
			open: 150.935,
			close: 150.91,
			volume: 1132,
			time: 1654200900000,
			lowest: 150.91,
			highest: 150.935,
			volumePrice: 150.9092,
			transactionCount: 36,
			voluemPrice: 150.9092
		},
		{
			open: 150.91,
			close: 150.91,
			volume: 374,
			time: 1654200960000,
			lowest: 150.91,
			highest: 150.91,
			volumePrice: 150.9038,
			transactionCount: 19,
			voluemPrice: 150.9038
		},
		{
			open: 150.8805,
			close: 150.87,
			volume: 1244,
			time: 1654201020000,
			lowest: 150.87,
			highest: 150.8805,
			volumePrice: 150.8883,
			transactionCount: 28,
			voluemPrice: 150.8883
		},
		{
			open: 150.91,
			close: 151.0899,
			volume: 2737,
			time: 1654201080000,
			lowest: 150.85,
			highest: 151.09,
			volumePrice: 150.9779,
			transactionCount: 73,
			voluemPrice: 150.9779
		},
		{
			open: 150.91,
			close: 151,
			volume: 1152,
			time: 1654201140000,
			lowest: 150.91,
			highest: 151,
			volumePrice: 150.6925,
			transactionCount: 236,
			voluemPrice: 150.6925
		},
		{
			open: 150.99,
			close: 151.0276,
			volume: 2569,
			time: 1654201200000,
			lowest: 150.99,
			highest: 151.03,
			volumePrice: 151.0147,
			transactionCount: 40,
			voluemPrice: 151.0147
		},
		{
			open: 150.95,
			close: 150.95,
			volume: 212,
			time: 1654201260000,
			lowest: 150.95,
			highest: 150.95,
			volumePrice: 150.948,
			transactionCount: 19,
			voluemPrice: 150.948
		},
		{
			open: 150.9407,
			close: 151.0299,
			volume: 2047,
			time: 1654201380000,
			lowest: 150.9407,
			highest: 151.05,
			volumePrice: 151.0006,
			transactionCount: 53,
			voluemPrice: 151.0006
		},
		{
			open: 151.01,
			close: 151.05,
			volume: 1841,
			time: 1654201440000,
			lowest: 151.01,
			highest: 151.05,
			volumePrice: 151.0181,
			transactionCount: 30,
			voluemPrice: 151.0181
		},
		{
			open: 151.0599,
			close: 151.1,
			volume: 7639,
			time: 1654201500000,
			lowest: 151.05,
			highest: 151.1,
			volumePrice: 151.0692,
			transactionCount: 45,
			voluemPrice: 151.0692
		},
		{
			open: 151.0501,
			close: 151.1,
			volume: 1859,
			time: 1654201560000,
			lowest: 151.0501,
			highest: 151.15,
			volumePrice: 151.1064,
			transactionCount: 52,
			voluemPrice: 151.1064
		},
		{
			open: 151.1,
			close: 151.0506,
			volume: 2240,
			time: 1654201620000,
			lowest: 151.0506,
			highest: 151.1293,
			volumePrice: 151.0871,
			transactionCount: 34,
			voluemPrice: 151.0871
		},
		{
			open: 151.0505,
			close: 151.05,
			volume: 1544,
			time: 1654201680000,
			lowest: 151.05,
			highest: 151.0505,
			volumePrice: 151.0535,
			transactionCount: 38,
			voluemPrice: 151.0535
		},
		{
			open: 151.0736,
			close: 151.1,
			volume: 1789,
			time: 1654201740000,
			lowest: 151.07,
			highest: 151.1,
			volumePrice: 151.0726,
			transactionCount: 39,
			voluemPrice: 151.0726
		},
		{
			open: 151.0601,
			close: 151.09,
			volume: 9530,
			time: 1654201860000,
			lowest: 151.06,
			highest: 151.21,
			volumePrice: 151.1936,
			transactionCount: 35,
			voluemPrice: 151.1936
		},
		{
			open: 151.0719,
			close: 151.0719,
			volume: 200,
			time: 1654201920000,
			lowest: 151.0719,
			highest: 151.0719,
			volumePrice: 151.0788,
			transactionCount: 20,
			voluemPrice: 151.0788
		},
		{
			open: 151.21,
			close: 150.97,
			volume: 30063,
			time: 1654201980000,
			lowest: 150.97,
			highest: 151.21,
			volumePrice: 151.1958,
			transactionCount: 51,
			voluemPrice: 151.1958
		},
		{
			open: 151.05,
			close: 151.01,
			volume: 2107,
			time: 1654202040000,
			lowest: 151.01,
			highest: 151.21,
			volumePrice: 151.079,
			transactionCount: 37,
			voluemPrice: 151.079
		},
		{
			open: 151.04,
			close: 151.04,
			volume: 264,
			time: 1654202100000,
			lowest: 151.04,
			highest: 151.04,
			volumePrice: 151.0425,
			transactionCount: 23,
			voluemPrice: 151.0425
		},
		{
			open: 151.0329,
			close: 151.0329,
			volume: 700,
			time: 1654202280000,
			lowest: 151.0329,
			highest: 151.0329,
			volumePrice: 151.0409,
			transactionCount: 23,
			voluemPrice: 151.0409
		},
		{
			open: 151.08,
			close: 151.08,
			volume: 369,
			time: 1654202340000,
			lowest: 151.08,
			highest: 151.08,
			volumePrice: 151.067,
			transactionCount: 14,
			voluemPrice: 151.067
		},
		{
			open: 151.0762,
			close: 151.0762,
			volume: 443,
			time: 1654202400000,
			lowest: 151.0762,
			highest: 151.0762,
			volumePrice: 151.0666,
			transactionCount: 33,
			voluemPrice: 151.0666
		},
		{
			open: 151.07,
			close: 151.1,
			volume: 1641,
			time: 1654202520000,
			lowest: 151.07,
			highest: 151.1,
			volumePrice: 151.0893,
			transactionCount: 42,
			voluemPrice: 151.0893
		},
		{
			open: 151.1,
			close: 151.1,
			volume: 257,
			time: 1654202580000,
			lowest: 151.1,
			highest: 151.1,
			volumePrice: 151.1053,
			transactionCount: 31,
			voluemPrice: 151.1053
		},
		{
			open: 151.12,
			close: 151.13,
			volume: 939,
			time: 1654202640000,
			lowest: 151.12,
			highest: 151.13,
			volumePrice: 151.1218,
			transactionCount: 34,
			voluemPrice: 151.1218
		},
		{
			open: 151.14,
			close: 151.1401,
			volume: 3547,
			time: 1654202700000,
			lowest: 151.14,
			highest: 151.17,
			volumePrice: 151.1503,
			transactionCount: 51,
			voluemPrice: 151.1503
		},
		{
			open: 151.14,
			close: 151.13,
			volume: 550,
			time: 1654202760000,
			lowest: 151.13,
			highest: 151.14,
			volumePrice: 151.1384,
			transactionCount: 27,
			voluemPrice: 151.1384
		},
		{
			open: 151.13,
			close: 151.14,
			volume: 847,
			time: 1654202820000,
			lowest: 151.13,
			highest: 151.17,
			volumePrice: 151.1477,
			transactionCount: 34,
			voluemPrice: 151.1477
		},
		{
			open: 151.19,
			close: 151.16,
			volume: 1500,
			time: 1654202880000,
			lowest: 151.15,
			highest: 151.19,
			volumePrice: 151.1782,
			transactionCount: 43,
			voluemPrice: 151.1782
		},
		{
			open: 151.14,
			close: 151.19,
			volume: 5206,
			time: 1654203000000,
			lowest: 151.14,
			highest: 151.19,
			volumePrice: 151.1604,
			transactionCount: 34,
			voluemPrice: 151.1604
		},
		{
			open: 151.17,
			close: 151.17,
			volume: 144,
			time: 1654203120000,
			lowest: 151.17,
			highest: 151.17,
			volumePrice: 151.1657,
			transactionCount: 19,
			voluemPrice: 151.1657
		},
		{
			open: 151.17,
			close: 151.17,
			volume: 260,
			time: 1654203180000,
			lowest: 151.17,
			highest: 151.17,
			volumePrice: 151.1591,
			transactionCount: 23,
			voluemPrice: 151.1591
		},
		{
			open: 151.17,
			close: 151.18,
			volume: 2703,
			time: 1654203240000,
			lowest: 151.15,
			highest: 151.18,
			volumePrice: 151.1728,
			transactionCount: 59,
			voluemPrice: 151.1728
		},
		{
			open: 151.18,
			close: 151.18,
			volume: 312,
			time: 1654203300000,
			lowest: 151.18,
			highest: 151.18,
			volumePrice: 151.178,
			transactionCount: 22,
			voluemPrice: 151.178
		},
		{
			open: 151.17,
			close: 151.17,
			volume: 526,
			time: 1654203360000,
			lowest: 151.17,
			highest: 151.17,
			volumePrice: 151.1707,
			transactionCount: 32,
			voluemPrice: 151.1707
		},
		{
			open: 151.18,
			close: 151.19,
			volume: 816,
			time: 1654203420000,
			lowest: 151.18,
			highest: 151.19,
			volumePrice: 151.1761,
			transactionCount: 42,
			voluemPrice: 151.1761
		},
		{
			open: 151.15,
			close: 151.16,
			volume: 1180,
			time: 1654203480000,
			lowest: 151.14,
			highest: 151.16,
			volumePrice: 151.1537,
			transactionCount: 38,
			voluemPrice: 151.1537
		},
		{
			open: 151.15,
			close: 151.15,
			volume: 446,
			time: 1654203540000,
			lowest: 151.15,
			highest: 151.15,
			volumePrice: 151.1518,
			transactionCount: 34,
			voluemPrice: 151.1518
		},
		{
			open: 151.1599,
			close: 151.15,
			volume: 657,
			time: 1654203600000,
			lowest: 151.1499,
			highest: 151.1599,
			volumePrice: 151.1496,
			transactionCount: 30,
			voluemPrice: 151.1496
		},
		{
			open: 151.1011,
			close: 151.12,
			volume: 1194,
			time: 1654203660000,
			lowest: 151.1011,
			highest: 151.12,
			volumePrice: 151.1101,
			transactionCount: 33,
			voluemPrice: 151.1101
		},
		{
			open: 151.1,
			close: 151.1,
			volume: 389,
			time: 1654203720000,
			lowest: 151.1,
			highest: 151.1,
			volumePrice: 151.098,
			transactionCount: 35,
			voluemPrice: 151.098
		},
		{
			open: 151.03,
			close: 151.05,
			volume: 598,
			time: 1654203780000,
			lowest: 151.03,
			highest: 151.05,
			volumePrice: 151.0546,
			transactionCount: 22,
			voluemPrice: 151.0546
		},
		{
			open: 151.06,
			close: 151.05,
			volume: 1237,
			time: 1654203900000,
			lowest: 151.05,
			highest: 151.06,
			volumePrice: 151.0633,
			transactionCount: 29,
			voluemPrice: 151.0633
		},
		{
			open: 151.0801,
			close: 151.09,
			volume: 366,
			time: 1654203960000,
			lowest: 151.0801,
			highest: 151.09,
			volumePrice: 151.0808,
			transactionCount: 15,
			voluemPrice: 151.0808
		},
		{
			open: 151.0901,
			close: 151.1,
			volume: 692,
			time: 1654204020000,
			lowest: 151.0901,
			highest: 151.12,
			volumePrice: 151.1085,
			transactionCount: 21,
			voluemPrice: 151.1085
		},
		{
			open: 151.1,
			close: 151.1,
			volume: 351,
			time: 1654204080000,
			lowest: 151.1,
			highest: 151.1,
			volumePrice: 151.1027,
			transactionCount: 23,
			voluemPrice: 151.1027
		},
		{
			open: 151.11,
			close: 151.11,
			volume: 368,
			time: 1654204140000,
			lowest: 151.11,
			highest: 151.11,
			volumePrice: 150.1346,
			transactionCount: 19,
			voluemPrice: 150.1346
		},
		{
			open: 151.1001,
			close: 151.1,
			volume: 348,
			time: 1654204200000,
			lowest: 151.1,
			highest: 151.1001,
			volumePrice: 151.1089,
			transactionCount: 26,
			voluemPrice: 151.1089
		},
		{
			open: 151.1001,
			close: 151.12,
			volume: 2655,
			time: 1654204320000,
			lowest: 151.1001,
			highest: 151.14,
			volumePrice: 151.1329,
			transactionCount: 26,
			voluemPrice: 151.1329
		},
		{
			open: 151.1,
			close: 151.1,
			volume: 1069,
			time: 1654204380000,
			lowest: 151.1,
			highest: 151.1,
			volumePrice: 151.1002,
			transactionCount: 17,
			voluemPrice: 151.1002
		},
		{
			open: 151.1,
			close: 151.1,
			volume: 548,
			time: 1654204500000,
			lowest: 151.1,
			highest: 151.1,
			volumePrice: 151.0951,
			transactionCount: 31,
			voluemPrice: 151.0951
		},
		{
			open: 151.15,
			close: 151.21,
			volume: 5384,
			time: 1654204620000,
			lowest: 151.15,
			highest: 151.21,
			volumePrice: 151.1838,
			transactionCount: 91,
			voluemPrice: 151.1838
		},
		{
			open: 151.164,
			close: 151.1273,
			volume: 525,
			time: 1654204740000,
			lowest: 151.1273,
			highest: 151.164,
			volumePrice: 151.139,
			transactionCount: 23,
			voluemPrice: 151.139
		},
		{
			open: 151.15,
			close: 151.14,
			volume: 1025,
			time: 1654204860000,
			lowest: 151.14,
			highest: 151.1699,
			volumePrice: 151.1488,
			transactionCount: 25,
			voluemPrice: 151.1488
		},
		{
			open: 151.08,
			close: 151.08,
			volume: 999,
			time: 1654205040000,
			lowest: 151.08,
			highest: 151.08,
			volumePrice: 151.0939,
			transactionCount: 41,
			voluemPrice: 151.0939
		},
		{
			open: 151.143,
			close: 151.15,
			volume: 534,
			time: 1654205160000,
			lowest: 151.143,
			highest: 151.15,
			volumePrice: 151.1417,
			transactionCount: 20,
			voluemPrice: 151.1417
		},
		{
			open: 151.1235,
			close: 151.12,
			volume: 342,
			time: 1654205220000,
			lowest: 151.12,
			highest: 151.1235,
			volumePrice: 151.1221,
			transactionCount: 16,
			voluemPrice: 151.1221
		},
		{
			open: 151.12,
			close: 151.12,
			volume: 1079,
			time: 1654205400000,
			lowest: 151.12,
			highest: 151.12,
			volumePrice: 151.1218,
			transactionCount: 19,
			voluemPrice: 151.1218
		},
		{
			open: 151.1597,
			close: 151.1597,
			volume: 1714,
			time: 1654205460000,
			lowest: 151.1597,
			highest: 151.1597,
			volumePrice: 151.1596,
			transactionCount: 16,
			voluemPrice: 151.1596
		},
		{
			open: 151.16,
			close: 151.15,
			volume: 3362,
			time: 1654205520000,
			lowest: 151.15,
			highest: 151.16,
			volumePrice: 151.1589,
			transactionCount: 19,
			voluemPrice: 151.1589
		},
		{
			open: 151.15,
			close: 151.12,
			volume: 1281,
			time: 1654205580000,
			lowest: 151.12,
			highest: 151.15,
			volumePrice: 151.1321,
			transactionCount: 19,
			voluemPrice: 151.1321
		},
		{
			open: 151.1229,
			close: 151.15,
			volume: 527,
			time: 1654205640000,
			lowest: 151.1229,
			highest: 151.15,
			volumePrice: 151.1286,
			transactionCount: 21,
			voluemPrice: 151.1286
		},
		{
			open: 151.15,
			close: 151.15,
			volume: 1039,
			time: 1654205760000,
			lowest: 151.15,
			highest: 151.15,
			volumePrice: 151.1488,
			transactionCount: 27,
			voluemPrice: 151.1488
		},
		{
			open: 151.16,
			close: 151.16,
			volume: 624,
			time: 1654205820000,
			lowest: 151.16,
			highest: 151.16,
			volumePrice: 151.1578,
			transactionCount: 13,
			voluemPrice: 151.1578
		},
		{
			open: 151.14,
			close: 151.14,
			volume: 291,
			time: 1654206000000,
			lowest: 151.14,
			highest: 151.14,
			volumePrice: 151.1371,
			transactionCount: 23,
			voluemPrice: 151.1371
		},
		{
			open: 151.14,
			close: 151.14,
			volume: 1129,
			time: 1654206120000,
			lowest: 151.14,
			highest: 151.16,
			volumePrice: 151.1522,
			transactionCount: 24,
			voluemPrice: 151.1522
		},
		{
			open: 151.1157,
			close: 151.16,
			volume: 2564,
			time: 1654206180000,
			lowest: 151.1,
			highest: 151.16,
			volumePrice: 151.1495,
			transactionCount: 21,
			voluemPrice: 151.1495
		},
		{
			open: 151.15,
			close: 151.2,
			volume: 1613,
			time: 1654206240000,
			lowest: 151.15,
			highest: 151.2,
			volumePrice: 151.1693,
			transactionCount: 19,
			voluemPrice: 151.1693
		},
		{
			open: 151.12,
			close: 151.15,
			volume: 611,
			time: 1654206300000,
			lowest: 151.12,
			highest: 151.15,
			volumePrice: 151.1394,
			transactionCount: 23,
			voluemPrice: 151.1394
		},
		{
			open: 151.17,
			close: 151.17,
			volume: 1360,
			time: 1654206360000,
			lowest: 151.17,
			highest: 151.17,
			volumePrice: 151.1703,
			transactionCount: 16,
			voluemPrice: 151.1703
		},
		{
			open: 151.12,
			close: 151.12,
			volume: 239,
			time: 1654206540000,
			lowest: 151.12,
			highest: 151.12,
			volumePrice: 151.1228,
			transactionCount: 19,
			voluemPrice: 151.1228
		},
		{
			open: 151.147,
			close: 151.147,
			volume: 408,
			time: 1654206660000,
			lowest: 151.147,
			highest: 151.147,
			volumePrice: 151.1279,
			transactionCount: 15,
			voluemPrice: 151.1279
		},
		{
			open: 151.11,
			close: 151.12,
			volume: 2923,
			time: 1654206840000,
			lowest: 151.11,
			highest: 151.12,
			volumePrice: 151.1124,
			transactionCount: 18,
			voluemPrice: 151.1124
		},
		{
			open: 151.11,
			close: 151.09,
			volume: 3053,
			time: 1654206900000,
			lowest: 151.09,
			highest: 151.11,
			volumePrice: 151.1008,
			transactionCount: 42,
			voluemPrice: 151.1008
		},
		{
			open: 151.0902,
			close: 151.0902,
			volume: 719,
			time: 1654206960000,
			lowest: 151.0902,
			highest: 151.0902,
			volumePrice: 151.0931,
			transactionCount: 14,
			voluemPrice: 151.0931
		},
		{
			open: 151.1,
			close: 151.15,
			volume: 408,
			time: 1654207200000,
			lowest: 151.1,
			highest: 151.15,
			volumePrice: 151.1306,
			transactionCount: 19,
			voluemPrice: 151.1306
		},
		{
			open: 151.14,
			close: 151.19,
			volume: 1363,
			time: 1654207440000,
			lowest: 151.12,
			highest: 151.19,
			volumePrice: 151.166,
			transactionCount: 22,
			voluemPrice: 151.166
		},
		{
			open: 151.19,
			close: 151.28,
			volume: 4229,
			time: 1654207500000,
			lowest: 151.19,
			highest: 151.28,
			volumePrice: 151.245,
			transactionCount: 63,
			voluemPrice: 151.245
		},
		{
			open: 151.29,
			close: 151.35,
			volume: 6274,
			time: 1654207560000,
			lowest: 151.27,
			highest: 151.35,
			volumePrice: 151.297,
			transactionCount: 67,
			voluemPrice: 151.297
		},
		{
			open: 151.35,
			close: 151.39,
			volume: 3362,
			time: 1654207620000,
			lowest: 151.35,
			highest: 151.39,
			volumePrice: 151.3819,
			transactionCount: 56,
			voluemPrice: 151.3819
		},
		{
			open: 151.33,
			close: 151.4,
			volume: 1623,
			time: 1654207680000,
			lowest: 151.33,
			highest: 151.4,
			volumePrice: 151.3613,
			transactionCount: 83,
			voluemPrice: 151.3613
		},
		{
			open: 151.36,
			close: 151.49,
			volume: 4662,
			time: 1654207740000,
			lowest: 151.22,
			highest: 151.49,
			volumePrice: 151.3726,
			transactionCount: 71,
			voluemPrice: 151.3726
		},
		{
			open: 151.33,
			close: 151.46,
			volume: 709,
			time: 1654207800000,
			lowest: 151.33,
			highest: 151.46,
			volumePrice: 151.4237,
			transactionCount: 20,
			voluemPrice: 151.4237
		},
		{
			open: 151.42,
			close: 151.42,
			volume: 196,
			time: 1654207860000,
			lowest: 151.42,
			highest: 151.42,
			volumePrice: 151.3993,
			transactionCount: 16,
			voluemPrice: 151.3993
		},
		{
			open: 151.33,
			close: 151.33,
			volume: 223,
			time: 1654207920000,
			lowest: 151.33,
			highest: 151.33,
			volumePrice: 151.3402,
			transactionCount: 17,
			voluemPrice: 151.3402
		},
		{
			open: 151.34,
			close: 151.34,
			volume: 143,
			time: 1654208040000,
			lowest: 151.34,
			highest: 151.34,
			volumePrice: 151.3591,
			transactionCount: 19,
			voluemPrice: 151.3591
		},
		{
			open: 151.36,
			close: 151.36,
			volume: 650,
			time: 1654208100000,
			lowest: 151.36,
			highest: 151.36,
			volumePrice: 151.3677,
			transactionCount: 23,
			voluemPrice: 151.3677
		},
		{
			open: 151.3,
			close: 151.26,
			volume: 2443,
			time: 1654208160000,
			lowest: 151.25,
			highest: 151.4,
			volumePrice: 151.3196,
			transactionCount: 45,
			voluemPrice: 151.3196
		},
		{
			open: 151.26,
			close: 151.3,
			volume: 1303,
			time: 1654208220000,
			lowest: 151.26,
			highest: 151.3,
			volumePrice: 151.2844,
			transactionCount: 28,
			voluemPrice: 151.2844
		},
		{
			open: 151.3,
			close: 151.3,
			volume: 1191,
			time: 1654208280000,
			lowest: 151.3,
			highest: 151.3,
			volumePrice: 151.2944,
			transactionCount: 26,
			voluemPrice: 151.2944
		},
		{
			open: 151.3,
			close: 151.48,
			volume: 2113,
			time: 1654208340000,
			lowest: 151.3,
			highest: 151.48,
			volumePrice: 151.3417,
			transactionCount: 24,
			voluemPrice: 151.3417
		},
		{
			open: 151.47,
			close: 151.33,
			volume: 949,
			time: 1654208400000,
			lowest: 151.33,
			highest: 151.47,
			volumePrice: 151.3958,
			transactionCount: 22,
			voluemPrice: 151.3958
		},
		{
			open: 151.36,
			close: 151.33,
			volume: 1886,
			time: 1654208460000,
			lowest: 151.33,
			highest: 151.36,
			volumePrice: 151.3384,
			transactionCount: 19,
			voluemPrice: 151.3384
		},
		{
			open: 151.36,
			close: 151.36,
			volume: 457,
			time: 1654208520000,
			lowest: 151.36,
			highest: 151.36,
			volumePrice: 151.3526,
			transactionCount: 18,
			voluemPrice: 151.3526
		},
		{
			open: 151.36,
			close: 151.4,
			volume: 979,
			time: 1654208640000,
			lowest: 151.36,
			highest: 151.4,
			volumePrice: 151.3754,
			transactionCount: 21,
			voluemPrice: 151.3754
		},
		{
			open: 151.4,
			close: 151.4,
			volume: 1076,
			time: 1654208700000,
			lowest: 151.4,
			highest: 151.42,
			volumePrice: 151.4039,
			transactionCount: 32,
			voluemPrice: 151.4039
		},
		{
			open: 151.38,
			close: 151.4,
			volume: 965,
			time: 1654208760000,
			lowest: 151.38,
			highest: 151.4,
			volumePrice: 151.3958,
			transactionCount: 28,
			voluemPrice: 151.3958
		},
		{
			open: 151.36,
			close: 151.4,
			volume: 5372,
			time: 1654208880000,
			lowest: 151.31,
			highest: 151.4,
			volumePrice: 151.364,
			transactionCount: 44,
			voluemPrice: 151.364
		},
		{
			open: 151.32,
			close: 151.31,
			volume: 1156,
			time: 1654208940000,
			lowest: 151.31,
			highest: 151.32,
			volumePrice: 151.3212,
			transactionCount: 22,
			voluemPrice: 151.3212
		},
		{
			open: 151.43,
			close: 151.43,
			volume: 284,
			time: 1654209000000,
			lowest: 151.43,
			highest: 151.43,
			volumePrice: 151.4176,
			transactionCount: 14,
			voluemPrice: 151.4176
		},
		{
			open: 151.36,
			close: 151.35,
			volume: 852,
			time: 1654209060000,
			lowest: 151.35,
			highest: 151.36,
			volumePrice: 151.3611,
			transactionCount: 12,
			voluemPrice: 151.3611
		},
		{
			open: 151.39,
			close: 151.43,
			volume: 669,
			time: 1654209120000,
			lowest: 151.39,
			highest: 151.43,
			volumePrice: 151.4209,
			transactionCount: 12,
			voluemPrice: 151.4209
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 333,
			time: 1654209180000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4048,
			transactionCount: 12,
			voluemPrice: 151.4048
		},
		{
			open: 151.38,
			close: 151.38,
			volume: 345,
			time: 1654209240000,
			lowest: 151.38,
			highest: 151.38,
			volumePrice: 151.3854,
			transactionCount: 20,
			voluemPrice: 151.3854
		},
		{
			open: 151.41,
			close: 151.4,
			volume: 1261,
			time: 1654209360000,
			lowest: 151.4,
			highest: 151.45,
			volumePrice: 151.4134,
			transactionCount: 18,
			voluemPrice: 151.4134
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 912,
			time: 1654209420000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4123,
			transactionCount: 20,
			voluemPrice: 151.4123
		},
		{
			open: 151.41,
			close: 151.43,
			volume: 867,
			time: 1654209480000,
			lowest: 151.41,
			highest: 151.43,
			volumePrice: 151.423,
			transactionCount: 18,
			voluemPrice: 151.423
		},
		{
			open: 151.43,
			close: 151.41,
			volume: 2516,
			time: 1654209540000,
			lowest: 151.4,
			highest: 151.43,
			volumePrice: 151.4138,
			transactionCount: 39,
			voluemPrice: 151.4138
		},
		{
			open: 151.43,
			close: 151.43,
			volume: 2265,
			time: 1654209600000,
			lowest: 151.4,
			highest: 151.43,
			volumePrice: 151.4182,
			transactionCount: 20,
			voluemPrice: 151.4182
		},
		{
			open: 151.4,
			close: 151.4,
			volume: 1569,
			time: 1654209660000,
			lowest: 151.4,
			highest: 151.4,
			volumePrice: 151.4009,
			transactionCount: 14,
			voluemPrice: 151.4009
		},
		{
			open: 151.4,
			close: 151.42,
			volume: 2539,
			time: 1654209720000,
			lowest: 151.4,
			highest: 151.42,
			volumePrice: 151.4041,
			transactionCount: 7,
			voluemPrice: 151.4041
		},
		{
			open: 151.4,
			close: 151.4,
			volume: 573,
			time: 1654209780000,
			lowest: 151.4,
			highest: 151.4,
			volumePrice: 151.4022,
			transactionCount: 16,
			voluemPrice: 151.4022
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 310,
			time: 1654209840000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4107,
			transactionCount: 15,
			voluemPrice: 151.4107
		},
		{
			open: 151.42,
			close: 151.43,
			volume: 638,
			time: 1654209900000,
			lowest: 151.42,
			highest: 151.43,
			volumePrice: 151.423,
			transactionCount: 23,
			voluemPrice: 151.423
		},
		{
			open: 151.44,
			close: 151.44,
			volume: 269,
			time: 1654209960000,
			lowest: 151.44,
			highest: 151.44,
			volumePrice: 151.4338,
			transactionCount: 16,
			voluemPrice: 151.4338
		},
		{
			open: 151.4499,
			close: 151.4499,
			volume: 1005,
			time: 1654210020000,
			lowest: 151.4499,
			highest: 151.4499,
			volumePrice: 151.4497,
			transactionCount: 12,
			voluemPrice: 151.4497
		},
		{
			open: 151.45,
			close: 151.45,
			volume: 1091,
			time: 1654210140000,
			lowest: 151.45,
			highest: 151.45,
			volumePrice: 151.4485,
			transactionCount: 9,
			voluemPrice: 151.4485
		},
		{
			open: 151.4499,
			close: 151.4499,
			volume: 159,
			time: 1654210200000,
			lowest: 151.4499,
			highest: 151.4499,
			volumePrice: 151.4438,
			transactionCount: 13,
			voluemPrice: 151.4438
		},
		{
			open: 151.41,
			close: 151.4,
			volume: 1148,
			time: 1654210320000,
			lowest: 151.4,
			highest: 151.41,
			volumePrice: 151.4069,
			transactionCount: 17,
			voluemPrice: 151.4069
		},
		{
			open: 151.44,
			close: 151.44,
			volume: 570,
			time: 1654210380000,
			lowest: 151.44,
			highest: 151.44,
			volumePrice: 151.4379,
			transactionCount: 9,
			voluemPrice: 151.4379
		},
		{
			open: 151.43,
			close: 151.43,
			volume: 566,
			time: 1654210500000,
			lowest: 151.43,
			highest: 151.43,
			volumePrice: 151.429,
			transactionCount: 14,
			voluemPrice: 151.429
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 563,
			time: 1654210560000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4112,
			transactionCount: 16,
			voluemPrice: 151.4112
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 319,
			time: 1654210620000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4115,
			transactionCount: 18,
			voluemPrice: 151.4115
		},
		{
			open: 151.41,
			close: 151.42,
			volume: 1828,
			time: 1654210680000,
			lowest: 151.41,
			highest: 151.42,
			volumePrice: 151.4122,
			transactionCount: 25,
			voluemPrice: 151.4122
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 233,
			time: 1654210740000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4202,
			transactionCount: 15,
			voluemPrice: 151.4202
		},
		{
			open: 151.41,
			close: 151.42,
			volume: 978,
			time: 1654210800000,
			lowest: 151.41,
			highest: 151.42,
			volumePrice: 151.4179,
			transactionCount: 21,
			voluemPrice: 151.4179
		},
		{
			open: 151.42,
			close: 151.42,
			volume: 991,
			time: 1654210860000,
			lowest: 151.42,
			highest: 151.42,
			volumePrice: 151.4222,
			transactionCount: 21,
			voluemPrice: 151.4222
		},
		{
			open: 151.42,
			close: 151.42,
			volume: 260,
			time: 1654210920000,
			lowest: 151.42,
			highest: 151.42,
			volumePrice: 151.4246,
			transactionCount: 21,
			voluemPrice: 151.4246
		},
		{
			open: 151.43,
			close: 151.43,
			volume: 220,
			time: 1654211040000,
			lowest: 151.43,
			highest: 151.43,
			volumePrice: 151.4324,
			transactionCount: 12,
			voluemPrice: 151.4324
		},
		{
			open: 151.43,
			close: 151.42,
			volume: 263,
			time: 1654211100000,
			lowest: 151.42,
			highest: 151.43,
			volumePrice: 151.4243,
			transactionCount: 11,
			voluemPrice: 151.4243
		},
		{
			open: 151.44,
			close: 151.43,
			volume: 1903,
			time: 1654211220000,
			lowest: 151.43,
			highest: 151.45,
			volumePrice: 151.4369,
			transactionCount: 23,
			voluemPrice: 151.4369
		},
		{
			open: 151.43,
			close: 151.43,
			volume: 407,
			time: 1654211280000,
			lowest: 151.43,
			highest: 151.43,
			volumePrice: 151.4301,
			transactionCount: 16,
			voluemPrice: 151.4301
		},
		{
			open: 151.41,
			close: 151.41,
			volume: 731,
			time: 1654211520000,
			lowest: 151.41,
			highest: 151.41,
			volumePrice: 151.4205,
			transactionCount: 20,
			voluemPrice: 151.4205
		},
		{
			open: 151.44,
			close: 151.43,
			volume: 266,
			time: 1654211580000,
			lowest: 151.43,
			highest: 151.44,
			volumePrice: 151.4352,
			transactionCount: 10,
			voluemPrice: 151.4352
		},
		{
			open: 151.43,
			close: 151.45,
			volume: 2237,
			time: 1654211640000,
			lowest: 151.42,
			highest: 151.45,
			volumePrice: 151.4389,
			transactionCount: 32,
			voluemPrice: 151.4389
		},
		{
			open: 151.49,
			close: 151.51,
			volume: 5974,
			time: 1654212060000,
			lowest: 151.49,
			highest: 151.51,
			volumePrice: 151.4981,
			transactionCount: 80,
			voluemPrice: 151.4981
		},
		{
			open: 151.54,
			close: 151.52,
			volume: 1529,
			time: 1654212120000,
			lowest: 151.51,
			highest: 151.54,
			volumePrice: 151.5257,
			transactionCount: 27,
			voluemPrice: 151.5257
		},
		{
			open: 151.5,
			close: 151.53,
			volume: 2727,
			time: 1654212180000,
			lowest: 151.5,
			highest: 151.53,
			volumePrice: 151.5103,
			transactionCount: 26,
			voluemPrice: 151.5103
		},
		{
			open: 151.52,
			close: 151.53,
			volume: 535,
			time: 1654212240000,
			lowest: 151.52,
			highest: 151.53,
			volumePrice: 151.5261,
			transactionCount: 18,
			voluemPrice: 151.5261
		},
		{
			open: 151.54,
			close: 151.55,
			volume: 1244,
			time: 1654212300000,
			lowest: 151.54,
			highest: 151.55,
			volumePrice: 151.5471,
			transactionCount: 26,
			voluemPrice: 151.5471
		},
		{
			open: 151.52,
			close: 151.52,
			volume: 555,
			time: 1654212360000,
			lowest: 151.52,
			highest: 151.52,
			volumePrice: 151.5294,
			transactionCount: 10,
			voluemPrice: 151.5294
		},
		{
			open: 151.56,
			close: 151.56,
			volume: 333,
			time: 1654212420000,
			lowest: 151.56,
			highest: 151.56,
			volumePrice: 151.5589,
			transactionCount: 14,
			voluemPrice: 151.5589
		},
		{
			open: 151.56,
			close: 151.56,
			volume: 240,
			time: 1654212600000,
			lowest: 151.56,
			highest: 151.56,
			volumePrice: 151.5426,
			transactionCount: 23,
			voluemPrice: 151.5426
		},
		{
			open: 151.52,
			close: 151.53,
			volume: 513,
			time: 1654212660000,
			lowest: 151.52,
			highest: 151.53,
			volumePrice: 151.5288,
			transactionCount: 15,
			voluemPrice: 151.5288
		},
		{
			open: 151.52,
			close: 151.5201,
			volume: 285,
			time: 1654212720000,
			lowest: 151.52,
			highest: 151.5201,
			volumePrice: 151.5293,
			transactionCount: 10,
			voluemPrice: 151.5293
		},
		{
			open: 151.56,
			close: 151.58,
			volume: 1170,
			time: 1654212840000,
			lowest: 151.56,
			highest: 151.58,
			volumePrice: 151.5701,
			transactionCount: 16,
			voluemPrice: 151.5701
		},
		{
			open: 151.57,
			close: 151.57,
			volume: 510,
			time: 1654212900000,
			lowest: 151.57,
			highest: 151.57,
			volumePrice: 151.5687,
			transactionCount: 15,
			voluemPrice: 151.5687
		},
		{
			open: 151.55,
			close: 151.55,
			volume: 896,
			time: 1654212960000,
			lowest: 151.55,
			highest: 151.55,
			volumePrice: 151.548,
			transactionCount: 17,
			voluemPrice: 151.548
		},
		{
			open: 151.5301,
			close: 151.56,
			volume: 2361,
			time: 1654213020000,
			lowest: 151.53,
			highest: 151.58,
			volumePrice: 151.5439,
			transactionCount: 17,
			voluemPrice: 151.5439
		},
		{
			open: 151.58,
			close: 151.55,
			volume: 1526,
			time: 1654213080000,
			lowest: 151.55,
			highest: 151.58,
			volumePrice: 151.5751,
			transactionCount: 20,
			voluemPrice: 151.5751
		},
		{
			open: 151.54,
			close: 151.54,
			volume: 981,
			time: 1654213140000,
			lowest: 151.54,
			highest: 151.54,
			volumePrice: 151.5413,
			transactionCount: 20,
			voluemPrice: 151.5413
		},
		{
			open: 151.55,
			close: 151.52,
			volume: 490,
			time: 1654213200000,
			lowest: 151.52,
			highest: 151.55,
			volumePrice: 151.529,
			transactionCount: 11,
			voluemPrice: 151.529
		},
		{
			open: 151.56,
			close: 151.55,
			volume: 425,
			time: 1654213260000,
			lowest: 151.55,
			highest: 151.56,
			volumePrice: 151.5548,
			transactionCount: 8,
			voluemPrice: 151.5548
		},
		{
			open: 151.55,
			close: 151.57,
			volume: 1683,
			time: 1654213320000,
			lowest: 151.55,
			highest: 151.57,
			volumePrice: 151.5658,
			transactionCount: 17,
			voluemPrice: 151.5658
		},
		{
			open: 151.59,
			close: 151.57,
			volume: 1416,
			time: 1654213380000,
			lowest: 151.57,
			highest: 151.59,
			volumePrice: 151.5832,
			transactionCount: 27,
			voluemPrice: 151.5832
		},
		{
			open: 151.57,
			close: 151.57,
			volume: 1994,
			time: 1654213440000,
			lowest: 151.56,
			highest: 151.58,
			volumePrice: 151.5695,
			transactionCount: 16,
			voluemPrice: 151.5695
		},
		{
			open: 151.56,
			close: 151.56,
			volume: 739,
			time: 1654213500000,
			lowest: 151.55,
			highest: 151.56,
			volumePrice: 151.5579,
			transactionCount: 15,
			voluemPrice: 151.5579
		},
		{
			open: 151.52,
			close: 151.53,
			volume: 1254,
			time: 1654213620000,
			lowest: 151.52,
			highest: 151.53,
			volumePrice: 151.5216,
			transactionCount: 18,
			voluemPrice: 151.5216
		},
		{
			open: 151.54,
			close: 151.53,
			volume: 1164,
			time: 1654213680000,
			lowest: 151.53,
			highest: 151.54,
			volumePrice: 151.5347,
			transactionCount: 19,
			voluemPrice: 151.5347
		},
		{
			open: 151.5,
			close: 151.5,
			volume: 8996,
			time: 1654213740000,
			lowest: 151.5,
			highest: 151.51,
			volumePrice: 151.5005,
			transactionCount: 21,
			voluemPrice: 151.5005
		},
		{
			open: 151.52,
			close: 151.5,
			volume: 4745,
			time: 1654213800000,
			lowest: 151.5,
			highest: 151.52,
			volumePrice: 151.5065,
			transactionCount: 23,
			voluemPrice: 151.5065
		},
		{
			open: 151.52,
			close: 151.48,
			volume: 1756,
			time: 1654213920000,
			lowest: 151.48,
			highest: 151.52,
			volumePrice: 151.5003,
			transactionCount: 33,
			voluemPrice: 151.5003
		},
		{
			open: 151.5,
			close: 151.47,
			volume: 2142,
			time: 1654213980000,
			lowest: 151.47,
			highest: 151.5,
			volumePrice: 151.4916,
			transactionCount: 25,
			voluemPrice: 151.4916
		},
		{
			open: 151.52,
			close: 151.5,
			volume: 1111,
			time: 1654214040000,
			lowest: 151.48,
			highest: 151.52,
			volumePrice: 151.5067,
			transactionCount: 23,
			voluemPrice: 151.5067
		},
		{
			open: 151.53,
			close: 151.53,
			volume: 1526,
			time: 1654214100000,
			lowest: 151.47,
			highest: 151.53,
			volumePrice: 151.5031,
			transactionCount: 22,
			voluemPrice: 151.5031
		},
		{
			open: 151.53,
			close: 151.5,
			volume: 1826,
			time: 1654214160000,
			lowest: 151.5,
			highest: 151.54,
			volumePrice: 151.5277,
			transactionCount: 25,
			voluemPrice: 151.5277
		},
		{
			open: 151.56,
			close: 151.57,
			volume: 1918,
			time: 1654214220000,
			lowest: 151.55,
			highest: 151.57,
			volumePrice: 151.5552,
			transactionCount: 24,
			voluemPrice: 151.5552
		},
		{
			open: 151.59,
			close: 151.59,
			volume: 1964,
			time: 1654214280000,
			lowest: 151.59,
			highest: 151.6,
			volumePrice: 151.5963,
			transactionCount: 36,
			voluemPrice: 151.5963
		},
		{
			open: 151.6,
			close: 151.55,
			volume: 4126,
			time: 1654214340000,
			lowest: 151.55,
			highest: 151.6,
			volumePrice: 151.5805,
			transactionCount: 34,
			voluemPrice: 151.5805
		},
		{
			open: 150.7,
			close: 149.87,
			volume: 969,
			time: 1654243200000,
			lowest: 149.87,
			highest: 150.7,
			volumePrice: 150.2016,
			transactionCount: 59,
			voluemPrice: 150.2016
		},
		{
			open: 150.18,
			close: 150.1,
			volume: 1748,
			time: 1654243260000,
			lowest: 150.1,
			highest: 150.18,
			volumePrice: 150.1272,
			transactionCount: 55,
			voluemPrice: 150.1272
		},
		{
			open: 150.08,
			close: 150.17,
			volume: 1674,
			time: 1654243320000,
			lowest: 150.07,
			highest: 150.17,
			volumePrice: 150.1179,
			transactionCount: 38,
			voluemPrice: 150.1179
		},
		{
			open: 150.13,
			close: 150.14,
			volume: 2161,
			time: 1654243380000,
			lowest: 150.1,
			highest: 150.14,
			volumePrice: 150.1255,
			transactionCount: 45,
			voluemPrice: 150.1255
		},
		{
			open: 150.13,
			close: 150.12,
			volume: 949,
			time: 1654243440000,
			lowest: 150.12,
			highest: 150.13,
			volumePrice: 150.1317,
			transactionCount: 25,
			voluemPrice: 150.1317
		},
		{
			open: 150.19,
			close: 150.14,
			volume: 6936,
			time: 1654243500000,
			lowest: 150.14,
			highest: 150.19,
			volumePrice: 150.1495,
			transactionCount: 50,
			voluemPrice: 150.1495
		},
		{
			open: 150.14,
			close: 150.14,
			volume: 11526,
			time: 1654243560000,
			lowest: 150.11,
			highest: 150.14,
			volumePrice: 150.1381,
			transactionCount: 87,
			voluemPrice: 150.1381
		},
		{
			open: 150.11,
			close: 150.11,
			volume: 734,
			time: 1654243620000,
			lowest: 150.11,
			highest: 150.11,
			volumePrice: 150.1089,
			transactionCount: 24,
			voluemPrice: 150.1089
		},
		{
			open: 150.14,
			close: 150.14,
			volume: 1430,
			time: 1654243680000,
			lowest: 150.14,
			highest: 150.15,
			volumePrice: 150.1356,
			transactionCount: 40,
			voluemPrice: 150.1356
		},
		{
			open: 150.14,
			close: 150.13,
			volume: 6865,
			time: 1654243740000,
			lowest: 150.13,
			highest: 150.14,
			volumePrice: 150.1382,
			transactionCount: 54,
			voluemPrice: 150.1382
		},
		{
			open: 150.13,
			close: 150.22,
			volume: 5375,
			time: 1654243800000,
			lowest: 150.13,
			highest: 150.22,
			volumePrice: 150.1422,
			transactionCount: 83,
			voluemPrice: 150.1422
		},
		{
			open: 150.2,
			close: 150.2,
			volume: 386,
			time: 1654243860000,
			lowest: 150.2,
			highest: 150.2,
			volumePrice: 150.1982,
			transactionCount: 21,
			voluemPrice: 150.1982
		},
		{
			open: 150.3,
			close: 150.26,
			volume: 1049,
			time: 1654244040000,
			lowest: 150.26,
			highest: 150.3,
			volumePrice: 150.2833,
			transactionCount: 27,
			voluemPrice: 150.2833
		},
		{
			open: 150.26,
			close: 150.32,
			volume: 854,
			time: 1654244100000,
			lowest: 150.26,
			highest: 150.32,
			volumePrice: 150.2818,
			transactionCount: 23,
			voluemPrice: 150.2818
		},
		{
			open: 150.32,
			close: 150.32,
			volume: 1245,
			time: 1654244160000,
			lowest: 150.32,
			highest: 150.32,
			volumePrice: 150.3227,
			transactionCount: 11,
			voluemPrice: 150.3227
		},
		{
			open: 150.45,
			close: 150.46,
			volume: 309,
			time: 1654244220000,
			lowest: 150.45,
			highest: 150.46,
			volumePrice: 150.4514,
			transactionCount: 9,
			voluemPrice: 150.4514
		},
		{
			open: 150.32,
			close: 150.32,
			volume: 398,
			time: 1654244280000,
			lowest: 150.32,
			highest: 150.32,
			volumePrice: 150.3271,
			transactionCount: 17,
			voluemPrice: 150.3271
		},
		{
			open: 150.32,
			close: 150.33,
			volume: 1624,
			time: 1654244340000,
			lowest: 150.32,
			highest: 150.33,
			volumePrice: 150.3208,
			transactionCount: 18,
			voluemPrice: 150.3208
		},
		{
			open: 150.35,
			close: 150.36,
			volume: 1223,
			time: 1654244640000,
			lowest: 150.35,
			highest: 150.36,
			volumePrice: 150.3605,
			transactionCount: 15,
			voluemPrice: 150.3605
		},
		{
			open: 150.36,
			close: 150.24,
			volume: 990,
			time: 1654244700000,
			lowest: 150.24,
			highest: 150.36,
			volumePrice: 150.2709,
			transactionCount: 31,
			voluemPrice: 150.2709
		},
		{
			open: 150.34,
			close: 150.34,
			volume: 512,
			time: 1654245000000,
			lowest: 150.34,
			highest: 150.34,
			volumePrice: 150.3308,
			transactionCount: 23,
			voluemPrice: 150.3308
		},
		{
			open: 150.32,
			close: 150.33,
			volume: 324,
			time: 1654245120000,
			lowest: 150.32,
			highest: 150.33,
			volumePrice: 150.3252,
			transactionCount: 14,
			voluemPrice: 150.3252
		},
		{
			open: 150.38,
			close: 150.48,
			volume: 1351,
			time: 1654245180000,
			lowest: 150.38,
			highest: 150.48,
			volumePrice: 150.4329,
			transactionCount: 26,
			voluemPrice: 150.4329
		},
		{
			open: 150.4,
			close: 150.4,
			volume: 177,
			time: 1654245300000,
			lowest: 150.4,
			highest: 150.4,
			volumePrice: 150.3921,
			transactionCount: 9,
			voluemPrice: 150.3921
		},
		{
			open: 150.42,
			close: 150.32,
			volume: 2370,
			time: 1654245480000,
			lowest: 150.32,
			highest: 150.42,
			volumePrice: 150.3519,
			transactionCount: 34,
			voluemPrice: 150.3519
		},
		{
			open: 150.31,
			close: 150.31,
			volume: 240,
			time: 1654245540000,
			lowest: 150.31,
			highest: 150.31,
			volumePrice: 150.3123,
			transactionCount: 14,
			voluemPrice: 150.3123
		},
		{
			open: 150.29,
			close: 150.29,
			volume: 533,
			time: 1654245600000,
			lowest: 150.29,
			highest: 150.29,
			volumePrice: 150.2926,
			transactionCount: 18,
			voluemPrice: 150.2926
		},
		{
			open: 150.29,
			close: 150.29,
			volume: 253,
			time: 1654245660000,
			lowest: 150.29,
			highest: 150.29,
			volumePrice: 150.2837,
			transactionCount: 2,
			voluemPrice: 150.2837
		},
		{
			open: 150.23,
			close: 150.21,
			volume: 2978,
			time: 1654245720000,
			lowest: 150.21,
			highest: 150.23,
			volumePrice: 150.2214,
			transactionCount: 33,
			voluemPrice: 150.2214
		},
		{
			open: 150.3,
			close: 150.3,
			volume: 521,
			time: 1654245780000,
			lowest: 150.3,
			highest: 150.3,
			volumePrice: 150.2861,
			transactionCount: 15,
			voluemPrice: 150.2861
		},
		{
			open: 150.29,
			close: 150.3,
			volume: 1022,
			time: 1654245900000,
			lowest: 150.29,
			highest: 150.3,
			volumePrice: 150.293,
			transactionCount: 17,
			voluemPrice: 150.293
		},
		{
			open: 150.25,
			close: 150.25,
			volume: 481,
			time: 1654245960000,
			lowest: 150.25,
			highest: 150.25,
			volumePrice: 150.2655,
			transactionCount: 15,
			voluemPrice: 150.2655
		},
		{
			open: 150.3,
			close: 150.3,
			volume: 309,
			time: 1654246020000,
			lowest: 150.3,
			highest: 150.3,
			volumePrice: 150.3021,
			transactionCount: 9,
			voluemPrice: 150.3021
		},
		{
			open: 150.3,
			close: 150.3,
			volume: 236,
			time: 1654246080000,
			lowest: 150.3,
			highest: 150.3,
			volumePrice: 150.3032,
			transactionCount: 10,
			voluemPrice: 150.3032
		},
		{
			open: 150.23,
			close: 150.23,
			volume: 159,
			time: 1654246260000,
			lowest: 150.23,
			highest: 150.23,
			volumePrice: 150.2377,
			transactionCount: 5,
			voluemPrice: 150.2377
		},
		{
			open: 150.22,
			close: 150.25,
			volume: 1230,
			time: 1654246320000,
			lowest: 150.22,
			highest: 150.25,
			volumePrice: 150.2366,
			transactionCount: 14,
			voluemPrice: 150.2366
		},
		{
			open: 150.26,
			close: 150.25,
			volume: 1795,
			time: 1654246380000,
			lowest: 150.25,
			highest: 150.26,
			volumePrice: 150.2533,
			transactionCount: 11,
			voluemPrice: 150.2533
		},
		{
			open: 150.3,
			close: 150.3,
			volume: 286,
			time: 1654246500000,
			lowest: 150.3,
			highest: 150.3,
			volumePrice: 150.3028,
			transactionCount: 9,
			voluemPrice: 150.3028
		},
		{
			open: 150.39,
			close: 150.39,
			volume: 2015,
			time: 1654246560000,
			lowest: 150.38,
			highest: 150.39,
			volumePrice: 150.381,
			transactionCount: 15,
			voluemPrice: 150.381
		},
		{
			open: 150.29,
			close: 150.28,
			volume: 1014,
			time: 1654246800000,
			lowest: 150.28,
			highest: 150.29,
			volumePrice: 150.2896,
			transactionCount: 15,
			voluemPrice: 150.2896
		},
		{
			open: 150.31,
			close: 150.31,
			volume: 577,
			time: 1654246860000,
			lowest: 150.31,
			highest: 150.31,
			volumePrice: 150.3094,
			transactionCount: 10,
			voluemPrice: 150.3094
		},
		{
			open: 150.32,
			close: 150.32,
			volume: 1160,
			time: 1654247220000,
			lowest: 150.32,
			highest: 150.32,
			volumePrice: 150.3265,
			transactionCount: 12,
			voluemPrice: 150.3265
		},
		{
			open: 150.26,
			close: 150.25,
			volume: 3041,
			time: 1654247460000,
			lowest: 150.25,
			highest: 150.26,
			volumePrice: 150.2517,
			transactionCount: 37,
			voluemPrice: 150.2517
		},
		{
			open: 150.24,
			close: 150.23,
			volume: 979,
			time: 1654247580000,
			lowest: 150.23,
			highest: 150.24,
			volumePrice: 150.2455,
			transactionCount: 9,
			voluemPrice: 150.2455
		},
		{
			open: 150.21,
			close: 150.2,
			volume: 3146,
			time: 1654247820000,
			lowest: 150.2,
			highest: 150.21,
			volumePrice: 150.2056,
			transactionCount: 53,
			voluemPrice: 150.2056
		},
		{
			open: 150.11,
			close: 150.11,
			volume: 525,
			time: 1654247940000,
			lowest: 150.11,
			highest: 150.11,
			volumePrice: 150.116,
			transactionCount: 18,
			voluemPrice: 150.116
		},
		{
			open: 150.19,
			close: 150.19,
			volume: 660,
			time: 1654248000000,
			lowest: 150.19,
			highest: 150.19,
			volumePrice: 150.1852,
			transactionCount: 11,
			voluemPrice: 150.1852
		},
		{
			open: 150.11,
			close: 150.1,
			volume: 1057,
			time: 1654248180000,
			lowest: 150.1,
			highest: 150.11,
			volumePrice: 150.1103,
			transactionCount: 33,
			voluemPrice: 150.1103
		},
		{
			open: 150.11,
			close: 150.1,
			volume: 707,
			time: 1654248240000,
			lowest: 150.1,
			highest: 150.11,
			volumePrice: 150.1071,
			transactionCount: 6,
			voluemPrice: 150.1071
		},
		{
			open: 150.11,
			close: 150.11,
			volume: 860,
			time: 1654248360000,
			lowest: 150.11,
			highest: 150.11,
			volumePrice: 150.1163,
			transactionCount: 22,
			voluemPrice: 150.1163
		},
		{
			open: 150.11,
			close: 150.1,
			volume: 633,
			time: 1654248480000,
			lowest: 150.1,
			highest: 150.11,
			volumePrice: 150.1052,
			transactionCount: 12,
			voluemPrice: 150.1052
		},
		{
			open: 150.1,
			close: 150.08,
			volume: 1767,
			time: 1654248540000,
			lowest: 150.08,
			highest: 150.1,
			volumePrice: 150.0845,
			transactionCount: 30,
			voluemPrice: 150.0845
		},
		{
			open: 150.1,
			close: 150.06,
			volume: 1076,
			time: 1654248780000,
			lowest: 150.06,
			highest: 150.1,
			volumePrice: 150.0782,
			transactionCount: 24,
			voluemPrice: 150.0782
		},
		{
			open: 150.07,
			close: 150.02,
			volume: 2040,
			time: 1654248840000,
			lowest: 150.02,
			highest: 150.07,
			volumePrice: 150.0265,
			transactionCount: 16,
			voluemPrice: 150.0265
		},
		{
			open: 150.04,
			close: 150.1,
			volume: 3011,
			time: 1654248900000,
			lowest: 150.04,
			highest: 150.1,
			volumePrice: 150.0886,
			transactionCount: 32,
			voluemPrice: 150.0886
		},
		{
			open: 150.06,
			close: 150.06,
			volume: 511,
			time: 1654249080000,
			lowest: 150.06,
			highest: 150.06,
			volumePrice: 150.0671,
			transactionCount: 13,
			voluemPrice: 150.0671
		},
		{
			open: 150.08,
			close: 150.08,
			volume: 116,
			time: 1654249320000,
			lowest: 150.08,
			highest: 150.08,
			volumePrice: 150.0797,
			transactionCount: 5,
			voluemPrice: 150.0797
		},
		{
			open: 150.08,
			close: 150.07,
			volume: 558,
			time: 1654249500000,
			lowest: 150.07,
			highest: 150.08,
			volumePrice: 150.0781,
			transactionCount: 14,
			voluemPrice: 150.0781
		},
		{
			open: 150.06,
			close: 150.05,
			volume: 927,
			time: 1654249620000,
			lowest: 150.05,
			highest: 150.06,
			volumePrice: 150.0531,
			transactionCount: 9,
			voluemPrice: 150.0531
		},
		{
			open: 150.12,
			close: 150.12,
			volume: 490,
			time: 1654249860000,
			lowest: 150.12,
			highest: 150.12,
			volumePrice: 150.1024,
			transactionCount: 20,
			voluemPrice: 150.1024
		},
		{
			open: 150.12,
			close: 150.12,
			volume: 241,
			time: 1654249980000,
			lowest: 150.12,
			highest: 150.12,
			volumePrice: 150.1212,
			transactionCount: 8,
			voluemPrice: 150.1212
		},
		{
			open: 150.23,
			close: 150.24,
			volume: 1790,
			time: 1654250340000,
			lowest: 150.23,
			highest: 150.24,
			volumePrice: 150.233,
			transactionCount: 13,
			voluemPrice: 150.233
		},
		{
			open: 150.2,
			close: 150.21,
			volume: 500,
			time: 1654250580000,
			lowest: 150.2,
			highest: 150.21,
			volumePrice: 150.2049,
			transactionCount: 10,
			voluemPrice: 150.2049
		},
		{
			open: 150.21,
			close: 150.13,
			volume: 648,
			time: 1654250640000,
			lowest: 150.13,
			highest: 150.21,
			volumePrice: 150.1723,
			transactionCount: 19,
			voluemPrice: 150.1723
		},
		{
			open: 150.27,
			close: 150.27,
			volume: 427,
			time: 1654250700000,
			lowest: 150.27,
			highest: 150.27,
			volumePrice: 150.2344,
			transactionCount: 20,
			voluemPrice: 150.2344
		},
		{
			open: 150.23,
			close: 150.2,
			volume: 905,
			time: 1654250820000,
			lowest: 150.2,
			highest: 150.23,
			volumePrice: 150.2275,
			transactionCount: 10,
			voluemPrice: 150.2275
		},
		{
			open: 150.2,
			close: 150.2,
			volume: 527,
			time: 1654250940000,
			lowest: 150.2,
			highest: 150.2,
			volumePrice: 150.2011,
			transactionCount: 9,
			voluemPrice: 150.2011
		},
		{
			open: 150.12,
			close: 150.2,
			volume: 787,
			time: 1654251000000,
			lowest: 150.12,
			highest: 150.2,
			volumePrice: 150.1478,
			transactionCount: 17,
			voluemPrice: 150.1478
		},
		{
			open: 150.2,
			close: 150.2,
			volume: 1013,
			time: 1654251120000,
			lowest: 150.2,
			highest: 150.2,
			volumePrice: 150.1999,
			transactionCount: 12,
			voluemPrice: 150.1999
		},
		{
			open: 150.11,
			close: 150.11,
			volume: 706,
			time: 1654251300000,
			lowest: 150.11,
			highest: 150.11,
			volumePrice: 150.1228,
			transactionCount: 11,
			voluemPrice: 150.1228
		},
		{
			open: 150.15,
			close: 150.15,
			volume: 529,
			time: 1654251420000,
			lowest: 150.15,
			highest: 150.15,
			volumePrice: 150.15,
			transactionCount: 14,
			voluemPrice: 150.15
		},
		{
			open: 150.12,
			close: 150.11,
			volume: 2024,
			time: 1654251720000,
			lowest: 150.11,
			highest: 150.15,
			volumePrice: 150.1151,
			transactionCount: 22,
			voluemPrice: 150.1151
		},
		{
			open: 150.1,
			close: 150.09,
			volume: 2222,
			time: 1654251780000,
			lowest: 150,
			highest: 150.1,
			volumePrice: 150.0688,
			transactionCount: 70,
			voluemPrice: 150.0688
		},
		{
			open: 150.1,
			close: 150.1,
			volume: 180,
			time: 1654252080000,
			lowest: 150.1,
			highest: 150.1,
			volumePrice: 150.1006,
			transactionCount: 4,
			voluemPrice: 150.1006
		},
		{
			open: 150.22,
			close: 150.22,
			volume: 912,
			time: 1654252140000,
			lowest: 150.22,
			highest: 150.22,
			volumePrice: 150.2071,
			transactionCount: 30,
			voluemPrice: 150.2071
		},
		{
			open: 150.17,
			close: 150,
			volume: 13581,
			time: 1654252260000,
			lowest: 150,
			highest: 150.21,
			volumePrice: 150.0788,
			transactionCount: 131,
			voluemPrice: 150.0788
		},
		{
			open: 150.07,
			close: 150.04,
			volume: 1867,
			time: 1654252320000,
			lowest: 150.02,
			highest: 150.08,
			volumePrice: 150.0517,
			transactionCount: 19,
			voluemPrice: 150.0517
		},
		{
			open: 150.08,
			close: 149.5,
			volume: 24901,
			time: 1654252380000,
			lowest: 149.43,
			highest: 150.08,
			volumePrice: 149.7292,
			transactionCount: 215,
			voluemPrice: 149.7292
		},
		{
			open: 149.74,
			close: 149.68,
			volume: 2127,
			time: 1654252440000,
			lowest: 149.67,
			highest: 149.74,
			volumePrice: 149.6959,
			transactionCount: 26,
			voluemPrice: 149.6959
		},
		{
			open: 149.59,
			close: 149.2,
			volume: 7276,
			time: 1654252500000,
			lowest: 149.2,
			highest: 149.59,
			volumePrice: 149.4289,
			transactionCount: 82,
			voluemPrice: 149.4289
		},
		{
			open: 149.39,
			close: 149.34,
			volume: 2364,
			time: 1654252560000,
			lowest: 149.34,
			highest: 149.39,
			volumePrice: 149.361,
			transactionCount: 21,
			voluemPrice: 149.361
		},
		{
			open: 149.34,
			close: 149.25,
			volume: 2618,
			time: 1654252620000,
			lowest: 149.25,
			highest: 149.34,
			volumePrice: 149.3227,
			transactionCount: 23,
			voluemPrice: 149.3227
		},
		{
			open: 149.21,
			close: 148.31,
			volume: 20716,
			time: 1654252680000,
			lowest: 148.31,
			highest: 149.29,
			volumePrice: 148.8367,
			transactionCount: 262,
			voluemPrice: 148.8367
		},
		{
			open: 148.57,
			close: 148.51,
			volume: 5180,
			time: 1654252740000,
			lowest: 148.44,
			highest: 148.69,
			volumePrice: 148.5672,
			transactionCount: 80,
			voluemPrice: 148.5672
		},
		{
			open: 148.43,
			close: 148.55,
			volume: 9881,
			time: 1654252800000,
			lowest: 148.21,
			highest: 148.55,
			volumePrice: 148.2807,
			transactionCount: 80,
			voluemPrice: 148.2807
		},
		{
			open: 148.5,
			close: 148.36,
			volume: 6158,
			time: 1654252860000,
			lowest: 148.25,
			highest: 148.5,
			volumePrice: 148.3428,
			transactionCount: 43,
			voluemPrice: 148.3428
		},
		{
			open: 148.3,
			close: 148.2,
			volume: 3998,
			time: 1654252920000,
			lowest: 148.12,
			highest: 148.35,
			volumePrice: 148.2804,
			transactionCount: 62,
			voluemPrice: 148.2804
		},
		{
			open: 148.08,
			close: 147.9,
			volume: 14671,
			time: 1654252980000,
			lowest: 147.9,
			highest: 148.1,
			volumePrice: 148.0179,
			transactionCount: 206,
			voluemPrice: 148.0179
		},
		{
			open: 147.9,
			close: 147.85,
			volume: 3354,
			time: 1654253040000,
			lowest: 147.8,
			highest: 148,
			volumePrice: 147.898,
			transactionCount: 107,
			voluemPrice: 147.898
		},
		{
			open: 147.87,
			close: 147.8,
			volume: 8173,
			time: 1654253100000,
			lowest: 147.65,
			highest: 147.87,
			volumePrice: 147.7917,
			transactionCount: 164,
			voluemPrice: 147.7917
		},
		{
			open: 147.96,
			close: 147.98,
			volume: 2898,
			time: 1654253160000,
			lowest: 147.9,
			highest: 147.98,
			volumePrice: 147.9579,
			transactionCount: 40,
			voluemPrice: 147.9579
		},
		{
			open: 148.24,
			close: 148.06,
			volume: 4092,
			time: 1654253220000,
			lowest: 148.06,
			highest: 148.39,
			volumePrice: 148.2037,
			transactionCount: 69,
			voluemPrice: 148.2037
		},
		{
			open: 148.06,
			close: 148.02,
			volume: 5027,
			time: 1654253280000,
			lowest: 147.93,
			highest: 148.06,
			volumePrice: 148.0168,
			transactionCount: 56,
			voluemPrice: 148.0168
		},
		{
			open: 148.09,
			close: 148.15,
			volume: 1549,
			time: 1654253340000,
			lowest: 148.09,
			highest: 148.17,
			volumePrice: 148.1235,
			transactionCount: 31,
			voluemPrice: 148.1235
		},
		{
			open: 148.18,
			close: 148.11,
			volume: 3314,
			time: 1654253400000,
			lowest: 148.11,
			highest: 148.28,
			volumePrice: 148.1547,
			transactionCount: 76,
			voluemPrice: 148.1547
		},
		{
			open: 148.2,
			close: 148.18,
			volume: 979,
			time: 1654253460000,
			lowest: 148.18,
			highest: 148.2,
			volumePrice: 148.1717,
			transactionCount: 28,
			voluemPrice: 148.1717
		},
		{
			open: 148.15,
			close: 148.23,
			volume: 3940,
			time: 1654253520000,
			lowest: 148.09,
			highest: 148.23,
			volumePrice: 148.1863,
			transactionCount: 45,
			voluemPrice: 148.1863
		},
		{
			open: 148.2,
			close: 148.2,
			volume: 365,
			time: 1654253580000,
			lowest: 148.2,
			highest: 148.2,
			volumePrice: 148.2011,
			transactionCount: 11,
			voluemPrice: 148.2011
		},
		{
			open: 148.16,
			close: 148.11,
			volume: 1145,
			time: 1654253640000,
			lowest: 148.11,
			highest: 148.16,
			volumePrice: 148.1323,
			transactionCount: 38,
			voluemPrice: 148.1323
		},
		{
			open: 148.22,
			close: 148.28,
			volume: 4454,
			time: 1654253700000,
			lowest: 148.22,
			highest: 148.31,
			volumePrice: 148.252,
			transactionCount: 55,
			voluemPrice: 148.252
		},
		{
			open: 148.28,
			close: 148.34,
			volume: 3595,
			time: 1654253760000,
			lowest: 148.25,
			highest: 148.34,
			volumePrice: 148.3055,
			transactionCount: 46,
			voluemPrice: 148.3055
		},
		{
			open: 148.4,
			close: 148.38,
			volume: 6409,
			time: 1654253820000,
			lowest: 148.31,
			highest: 148.45,
			volumePrice: 148.4065,
			transactionCount: 54,
			voluemPrice: 148.4065
		},
		{
			open: 148.37,
			close: 148.3,
			volume: 1279,
			time: 1654253880000,
			lowest: 148.3,
			highest: 148.37,
			volumePrice: 148.3225,
			transactionCount: 33,
			voluemPrice: 148.3225
		},
		{
			open: 148.3,
			close: 148.21,
			volume: 3416,
			time: 1654253940000,
			lowest: 148.2,
			highest: 148.3,
			volumePrice: 148.2572,
			transactionCount: 44,
			voluemPrice: 148.2572
		},
		{
			open: 148.34,
			close: 148.51,
			volume: 24593,
			time: 1654254000000,
			lowest: 148.15,
			highest: 148.79,
			volumePrice: 148.4752,
			transactionCount: 403,
			voluemPrice: 148.4752
		},
		{
			open: 148.53,
			close: 148.44,
			volume: 8877,
			time: 1654254060000,
			lowest: 148.3,
			highest: 148.6,
			volumePrice: 148.4364,
			transactionCount: 83,
			voluemPrice: 148.4364
		},
		{
			open: 148.54,
			close: 148.7,
			volume: 5402,
			time: 1654254120000,
			lowest: 148.54,
			highest: 148.7,
			volumePrice: 148.6495,
			transactionCount: 81,
			voluemPrice: 148.6495
		},
		{
			open: 148.68,
			close: 148.64,
			volume: 5253,
			time: 1654254180000,
			lowest: 148.64,
			highest: 148.73,
			volumePrice: 148.7002,
			transactionCount: 83,
			voluemPrice: 148.7002
		},
		{
			open: 148.65,
			close: 148.59,
			volume: 4617,
			time: 1654254240000,
			lowest: 148.5,
			highest: 148.65,
			volumePrice: 148.6078,
			transactionCount: 63,
			voluemPrice: 148.6078
		},
		{
			open: 148.59,
			close: 148.52,
			volume: 6981,
			time: 1654254300000,
			lowest: 148.5,
			highest: 148.63,
			volumePrice: 148.546,
			transactionCount: 94,
			voluemPrice: 148.546
		},
		{
			open: 148.65,
			close: 148.45,
			volume: 5222,
			time: 1654254360000,
			lowest: 148.35,
			highest: 148.65,
			volumePrice: 148.4367,
			transactionCount: 87,
			voluemPrice: 148.4367
		},
		{
			open: 148.41,
			close: 148.38,
			volume: 17046,
			time: 1654254420000,
			lowest: 148.25,
			highest: 148.47,
			volumePrice: 148.31,
			transactionCount: 177,
			voluemPrice: 148.31
		},
		{
			open: 148.36,
			close: 148.41,
			volume: 6381,
			time: 1654254480000,
			lowest: 148.36,
			highest: 148.44,
			volumePrice: 148.4136,
			transactionCount: 94,
			voluemPrice: 148.4136
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 2959,
			time: 1654254540000,
			lowest: 148.32,
			highest: 148.41,
			volumePrice: 148.3845,
			transactionCount: 46,
			voluemPrice: 148.3845
		},
		{
			open: 148.45,
			close: 148.3,
			volume: 3460,
			time: 1654254600000,
			lowest: 148.3,
			highest: 148.45,
			volumePrice: 148.3889,
			transactionCount: 47,
			voluemPrice: 148.3889
		},
		{
			open: 148.35,
			close: 148.3,
			volume: 6862,
			time: 1654254660000,
			lowest: 148.22,
			highest: 148.35,
			volumePrice: 148.2734,
			transactionCount: 88,
			voluemPrice: 148.2734
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 536,
			time: 1654254720000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3309,
			transactionCount: 15,
			voluemPrice: 148.3309
		},
		{
			open: 148.3,
			close: 148.21,
			volume: 2985,
			time: 1654254780000,
			lowest: 148.21,
			highest: 148.3,
			volumePrice: 148.2514,
			transactionCount: 58,
			voluemPrice: 148.2514
		},
		{
			open: 148.32,
			close: 148.45,
			volume: 5435,
			time: 1654254840000,
			lowest: 148.31,
			highest: 148.45,
			volumePrice: 148.3905,
			transactionCount: 92,
			voluemPrice: 148.3905
		},
		{
			open: 148.48,
			close: 148.5,
			volume: 8328,
			time: 1654254900000,
			lowest: 148.4,
			highest: 148.5,
			volumePrice: 148.4599,
			transactionCount: 128,
			voluemPrice: 148.4599
		},
		{
			open: 148.42,
			close: 148.6,
			volume: 4314,
			time: 1654254960000,
			lowest: 148.4,
			highest: 148.6,
			volumePrice: 148.5092,
			transactionCount: 82,
			voluemPrice: 148.5092
		},
		{
			open: 148.65,
			close: 148.82,
			volume: 3957,
			time: 1654255020000,
			lowest: 148.65,
			highest: 148.82,
			volumePrice: 148.7287,
			transactionCount: 81,
			voluemPrice: 148.7287
		},
		{
			open: 148.82,
			close: 148.8,
			volume: 7197,
			time: 1654255080000,
			lowest: 148.8,
			highest: 148.82,
			volumePrice: 148.8028,
			transactionCount: 94,
			voluemPrice: 148.8028
		},
		{
			open: 148.8,
			close: 148.8,
			volume: 4773,
			time: 1654255140000,
			lowest: 148.8,
			highest: 148.8,
			volumePrice: 148.7954,
			transactionCount: 39,
			voluemPrice: 148.7954
		},
		{
			open: 148.75,
			close: 148.6,
			volume: 7636,
			time: 1654255200000,
			lowest: 148.6,
			highest: 148.75,
			volumePrice: 148.6379,
			transactionCount: 98,
			voluemPrice: 148.6379
		},
		{
			open: 148.61,
			close: 148.5,
			volume: 4502,
			time: 1654255260000,
			lowest: 148.5,
			highest: 148.61,
			volumePrice: 148.5687,
			transactionCount: 42,
			voluemPrice: 148.5687
		},
		{
			open: 148.62,
			close: 148.4,
			volume: 13969,
			time: 1654255320000,
			lowest: 148.4,
			highest: 148.62,
			volumePrice: 148.5032,
			transactionCount: 135,
			voluemPrice: 148.5032
		},
		{
			open: 148.55,
			close: 148.5,
			volume: 2710,
			time: 1654255440000,
			lowest: 148.5,
			highest: 148.58,
			volumePrice: 148.5086,
			transactionCount: 33,
			voluemPrice: 148.5086
		},
		{
			open: 148.48,
			close: 148.22,
			volume: 30431,
			time: 1654255500000,
			lowest: 148,
			highest: 148.5,
			volumePrice: 148.1351,
			transactionCount: 435,
			voluemPrice: 148.1351
		},
		{
			open: 148.16,
			close: 148.4,
			volume: 3221,
			time: 1654255560000,
			lowest: 148.14,
			highest: 148.4,
			volumePrice: 148.2603,
			transactionCount: 47,
			voluemPrice: 148.2603
		},
		{
			open: 148.35,
			close: 148.3,
			volume: 623,
			time: 1654255620000,
			lowest: 148.3,
			highest: 148.35,
			volumePrice: 148.3353,
			transactionCount: 39,
			voluemPrice: 148.3353
		},
		{
			open: 148.35,
			close: 148.43,
			volume: 6340,
			time: 1654255680000,
			lowest: 148.25,
			highest: 148.43,
			volumePrice: 148.3432,
			transactionCount: 134,
			voluemPrice: 148.3432
		},
		{
			open: 148.5,
			close: 148.48,
			volume: 7546,
			time: 1654255740000,
			lowest: 148.44,
			highest: 148.53,
			volumePrice: 148.4794,
			transactionCount: 96,
			voluemPrice: 148.4794
		},
		{
			open: 148.45,
			close: 148.4,
			volume: 5642,
			time: 1654255800000,
			lowest: 148.35,
			highest: 148.48,
			volumePrice: 148.4306,
			transactionCount: 122,
			voluemPrice: 148.4306
		},
		{
			open: 148.53,
			close: 148.53,
			volume: 1129,
			time: 1654255860000,
			lowest: 148.53,
			highest: 148.53,
			volumePrice: 148.5091,
			transactionCount: 28,
			voluemPrice: 148.5091
		},
		{
			open: 148.55,
			close: 148.6,
			volume: 15081,
			time: 1654255920000,
			lowest: 148.5,
			highest: 148.6,
			volumePrice: 148.5436,
			transactionCount: 120,
			voluemPrice: 148.5436
		},
		{
			open: 148.57,
			close: 148.6,
			volume: 1811,
			time: 1654255980000,
			lowest: 148.57,
			highest: 148.6,
			volumePrice: 148.589,
			transactionCount: 66,
			voluemPrice: 148.589
		},
		{
			open: 148.65,
			close: 148.61,
			volume: 1402,
			time: 1654256100000,
			lowest: 148.61,
			highest: 148.68,
			volumePrice: 148.659,
			transactionCount: 37,
			voluemPrice: 148.659
		},
		{
			open: 148.6,
			close: 148.35,
			volume: 8109,
			time: 1654256160000,
			lowest: 148.35,
			highest: 148.61,
			volumePrice: 148.5402,
			transactionCount: 136,
			voluemPrice: 148.5402
		},
		{
			open: 148.42,
			close: 148.28,
			volume: 14018,
			time: 1654256220000,
			lowest: 148.09,
			highest: 148.42,
			volumePrice: 148.2322,
			transactionCount: 179,
			voluemPrice: 148.2322
		},
		{
			open: 148.29,
			close: 148.1,
			volume: 8437,
			time: 1654256280000,
			lowest: 148.08,
			highest: 148.29,
			volumePrice: 148.1525,
			transactionCount: 102,
			voluemPrice: 148.1525
		},
		{
			open: 148.08,
			close: 148.2,
			volume: 17698,
			time: 1654256340000,
			lowest: 147.95,
			highest: 148.2,
			volumePrice: 148.0349,
			transactionCount: 308,
			voluemPrice: 148.0349
		},
		{
			open: 148.3,
			close: 148.4,
			volume: 3996,
			time: 1654256400000,
			lowest: 148.28,
			highest: 148.4,
			volumePrice: 148.3139,
			transactionCount: 62,
			voluemPrice: 148.3139
		},
		{
			open: 148.16,
			close: 148.15,
			volume: 473,
			time: 1654256460000,
			lowest: 148.15,
			highest: 148.16,
			volumePrice: 148.1633,
			transactionCount: 19,
			voluemPrice: 148.1633
		},
		{
			open: 148.17,
			close: 148.2,
			volume: 6247,
			time: 1654256520000,
			lowest: 148.15,
			highest: 148.2,
			volumePrice: 148.188,
			transactionCount: 83,
			voluemPrice: 148.188
		},
		{
			open: 148.2,
			close: 148.06,
			volume: 9300,
			time: 1654256580000,
			lowest: 148.06,
			highest: 148.2,
			volumePrice: 148.1262,
			transactionCount: 143,
			voluemPrice: 148.1262
		},
		{
			open: 148.12,
			close: 148.07,
			volume: 1699,
			time: 1654256640000,
			lowest: 148.07,
			highest: 148.12,
			volumePrice: 148.0935,
			transactionCount: 31,
			voluemPrice: 148.0935
		},
		{
			open: 148.03,
			close: 147.99,
			volume: 10487,
			time: 1654256700000,
			lowest: 147.99,
			highest: 148.03,
			volumePrice: 148.0036,
			transactionCount: 169,
			voluemPrice: 148.0036
		},
		{
			open: 148,
			close: 147.76,
			volume: 20121,
			time: 1654256760000,
			lowest: 147.76,
			highest: 148,
			volumePrice: 147.9368,
			transactionCount: 304,
			voluemPrice: 147.9368
		},
		{
			open: 147.78,
			close: 147.74,
			volume: 33433,
			time: 1654256820000,
			lowest: 147.74,
			highest: 147.9,
			volumePrice: 147.8167,
			transactionCount: 348,
			voluemPrice: 147.8167
		},
		{
			open: 147.72,
			close: 147.7,
			volume: 7792,
			time: 1654256880000,
			lowest: 147.67,
			highest: 147.72,
			volumePrice: 147.6974,
			transactionCount: 108,
			voluemPrice: 147.6974
		},
		{
			open: 147.72,
			close: 147.74,
			volume: 13110,
			time: 1654256940000,
			lowest: 147.7,
			highest: 147.79,
			volumePrice: 147.7577,
			transactionCount: 185,
			voluemPrice: 147.7577
		},
		{
			open: 147.74,
			close: 147.75,
			volume: 9271,
			time: 1654257000000,
			lowest: 147.69,
			highest: 147.75,
			volumePrice: 147.7372,
			transactionCount: 141,
			voluemPrice: 147.7372
		},
		{
			open: 147.71,
			close: 147.75,
			volume: 22364,
			time: 1654257060000,
			lowest: 147.69,
			highest: 147.75,
			volumePrice: 147.7431,
			transactionCount: 221,
			voluemPrice: 147.7431
		},
		{
			open: 147.75,
			close: 147.75,
			volume: 7256,
			time: 1654257120000,
			lowest: 147.69,
			highest: 147.75,
			volumePrice: 147.7362,
			transactionCount: 121,
			voluemPrice: 147.7362
		},
		{
			open: 147.75,
			close: 147.75,
			volume: 3934,
			time: 1654257180000,
			lowest: 147.73,
			highest: 147.75,
			volumePrice: 147.7442,
			transactionCount: 58,
			voluemPrice: 147.7442
		},
		{
			open: 147.75,
			close: 147.71,
			volume: 7559,
			time: 1654257240000,
			lowest: 147.67,
			highest: 147.75,
			volumePrice: 147.7089,
			transactionCount: 117,
			voluemPrice: 147.7089
		},
		{
			open: 147.7,
			close: 147.75,
			volume: 4391,
			time: 1654257300000,
			lowest: 147.7,
			highest: 147.75,
			volumePrice: 147.7237,
			transactionCount: 93,
			voluemPrice: 147.7237
		},
		{
			open: 147.75,
			close: 147.75,
			volume: 12800,
			time: 1654257360000,
			lowest: 147.73,
			highest: 147.75,
			volumePrice: 147.7476,
			transactionCount: 174,
			voluemPrice: 147.7476
		},
		{
			open: 147.72,
			close: 147.59,
			volume: 11375,
			time: 1654257420000,
			lowest: 147.58,
			highest: 147.73,
			volumePrice: 147.6562,
			transactionCount: 279,
			voluemPrice: 147.6562
		},
		{
			open: 147.64,
			close: 147.75,
			volume: 8781,
			time: 1654257480000,
			lowest: 147.64,
			highest: 147.75,
			volumePrice: 147.7428,
			transactionCount: 70,
			voluemPrice: 147.7428
		},
		{
			open: 147.75,
			close: 147.71,
			volume: 9160,
			time: 1654257540000,
			lowest: 147.71,
			highest: 147.75,
			volumePrice: 147.7442,
			transactionCount: 104,
			voluemPrice: 147.7442
		},
		{
			open: 148.7,
			close: 147.6,
			volume: 246870,
			time: 1654257600000,
			lowest: 147.59,
			highest: 150.0812,
			volumePrice: 148.232,
			transactionCount: 1703,
			voluemPrice: 148.232
		},
		{
			open: 150.79,
			close: 147.65,
			volume: 5167,
			time: 1654257660000,
			lowest: 147.61,
			highest: 151,
			volumePrice: 148.6209,
			transactionCount: 454,
			voluemPrice: 148.6209
		},
		{
			open: 147.65,
			close: 147.7,
			volume: 8295,
			time: 1654257720000,
			lowest: 147.65,
			highest: 147.74,
			volumePrice: 147.715,
			transactionCount: 174,
			voluemPrice: 147.715
		},
		{
			open: 147.7,
			close: 147.65,
			volume: 9830,
			time: 1654257780000,
			lowest: 147.65,
			highest: 147.7,
			volumePrice: 147.6792,
			transactionCount: 168,
			voluemPrice: 147.6792
		},
		{
			open: 147.65,
			close: 147.6499,
			volume: 24267,
			time: 1654257840000,
			lowest: 147.53,
			highest: 147.68,
			volumePrice: 147.6195,
			transactionCount: 288,
			voluemPrice: 147.6195
		},
		{
			open: 147.65,
			close: 147.65,
			volume: 11024,
			time: 1654257900000,
			lowest: 147.6399,
			highest: 147.65,
			volumePrice: 147.6464,
			transactionCount: 155,
			voluemPrice: 147.6464
		},
		{
			open: 147.65,
			close: 147.65,
			volume: 5363,
			time: 1654257960000,
			lowest: 147.63,
			highest: 147.65,
			volumePrice: 147.6459,
			transactionCount: 68,
			voluemPrice: 147.6459
		},
		{
			open: 147.65,
			close: 147.92,
			volume: 8484,
			time: 1654258020000,
			lowest: 147.64,
			highest: 147.941,
			volumePrice: 147.7023,
			transactionCount: 167,
			voluemPrice: 147.7023
		},
		{
			open: 147.87,
			close: 147.9,
			volume: 9956,
			time: 1654258080000,
			lowest: 147.83,
			highest: 147.92,
			volumePrice: 147.8893,
			transactionCount: 132,
			voluemPrice: 147.8893
		},
		{
			open: 147.89,
			close: 147.9001,
			volume: 7125,
			time: 1654258140000,
			lowest: 147.88,
			highest: 147.95,
			volumePrice: 147.9106,
			transactionCount: 86,
			voluemPrice: 147.9106
		},
		{
			open: 147.9001,
			close: 148.0601,
			volume: 12225,
			time: 1654258200000,
			lowest: 147.9001,
			highest: 148.15,
			volumePrice: 147.9649,
			transactionCount: 155,
			voluemPrice: 147.9649
		},
		{
			open: 148.1,
			close: 148.195,
			volume: 5078,
			time: 1654258260000,
			lowest: 148.06,
			highest: 148.2,
			volumePrice: 148.1479,
			transactionCount: 110,
			voluemPrice: 148.1479
		},
		{
			open: 148.24,
			close: 148.18,
			volume: 7524,
			time: 1654258320000,
			lowest: 148.1504,
			highest: 148.25,
			volumePrice: 148.1841,
			transactionCount: 111,
			voluemPrice: 148.1841
		},
		{
			open: 148.16,
			close: 148.1701,
			volume: 3977,
			time: 1654258380000,
			lowest: 148.11,
			highest: 148.18,
			volumePrice: 148.1639,
			transactionCount: 89,
			voluemPrice: 148.1639
		},
		{
			open: 148.27,
			close: 148.28,
			volume: 3232,
			time: 1654258440000,
			lowest: 148.24,
			highest: 148.28,
			volumePrice: 148.2558,
			transactionCount: 63,
			voluemPrice: 148.2558
		},
		{
			open: 148.31,
			close: 148.28,
			volume: 15454,
			time: 1654258500000,
			lowest: 148.28,
			highest: 148.3717,
			volumePrice: 148.2933,
			transactionCount: 111,
			voluemPrice: 148.2933
		},
		{
			open: 148.29,
			close: 148.33,
			volume: 6740,
			time: 1654258560000,
			lowest: 148.29,
			highest: 148.37,
			volumePrice: 148.3518,
			transactionCount: 57,
			voluemPrice: 148.3518
		},
		{
			open: 148.33,
			close: 148.15,
			volume: 31452,
			time: 1654258620000,
			lowest: 148.15,
			highest: 148.33,
			volumePrice: 148.1949,
			transactionCount: 203,
			voluemPrice: 148.1949
		},
		{
			open: 148.15,
			close: 148.1,
			volume: 11605,
			time: 1654258680000,
			lowest: 148,
			highest: 148.15,
			volumePrice: 148.079,
			transactionCount: 209,
			voluemPrice: 148.079
		},
		{
			open: 148.08,
			close: 147.99,
			volume: 20227,
			time: 1654258740000,
			lowest: 147.95,
			highest: 148.08,
			volumePrice: 148.0671,
			transactionCount: 162,
			voluemPrice: 148.0671
		},
		{
			open: 147.98,
			close: 147.95,
			volume: 4081,
			time: 1654258800000,
			lowest: 147.95,
			highest: 148,
			volumePrice: 147.9873,
			transactionCount: 137,
			voluemPrice: 147.9873
		},
		{
			open: 147.94,
			close: 147.92,
			volume: 24783,
			time: 1654258860000,
			lowest: 147.9,
			highest: 147.95,
			volumePrice: 147.944,
			transactionCount: 113,
			voluemPrice: 147.944
		},
		{
			open: 147.9392,
			close: 147.85,
			volume: 25536,
			time: 1654258920000,
			lowest: 147.84,
			highest: 147.95,
			volumePrice: 147.9151,
			transactionCount: 237,
			voluemPrice: 147.9151
		},
		{
			open: 147.85,
			close: 148.05,
			volume: 21625,
			time: 1654258980000,
			lowest: 147.78,
			highest: 148.05,
			volumePrice: 147.8294,
			transactionCount: 236,
			voluemPrice: 147.8294
		},
		{
			open: 148.01,
			close: 147.85,
			volume: 16017,
			time: 1654259040000,
			lowest: 147.83,
			highest: 148.01,
			volumePrice: 147.9145,
			transactionCount: 126,
			voluemPrice: 147.9145
		},
		{
			open: 147.85,
			close: 147.81,
			volume: 7816,
			time: 1654259100000,
			lowest: 147.81,
			highest: 147.89,
			volumePrice: 147.8469,
			transactionCount: 121,
			voluemPrice: 147.8469
		},
		{
			open: 147.84,
			close: 147.75,
			volume: 9085,
			time: 1654259160000,
			lowest: 147.74,
			highest: 147.85,
			volumePrice: 147.771,
			transactionCount: 182,
			voluemPrice: 147.771
		},
		{
			open: 147.71,
			close: 147.7,
			volume: 14427,
			time: 1654259220000,
			lowest: 147.6,
			highest: 147.75,
			volumePrice: 147.7049,
			transactionCount: 290,
			voluemPrice: 147.7049
		},
		{
			open: 147.9825,
			close: 147.8201,
			volume: 9775,
			time: 1654259280000,
			lowest: 147.8,
			highest: 147.9825,
			volumePrice: 147.8189,
			transactionCount: 63,
			voluemPrice: 147.8189
		},
		{
			open: 147.82,
			close: 148,
			volume: 6626,
			time: 1654259340000,
			lowest: 147.82,
			highest: 148,
			volumePrice: 147.9625,
			transactionCount: 92,
			voluemPrice: 147.9625
		},
		{
			open: 148.07,
			close: 148.79,
			volume: 124315,
			time: 1654259400000,
			lowest: 147.5,
			highest: 148.94,
			volumePrice: 148.0319,
			transactionCount: 781,
			voluemPrice: 148.0319
		},
		{
			open: 148.65,
			close: 148.22,
			volume: 48674,
			time: 1654259460000,
			lowest: 148.22,
			highest: 148.89,
			volumePrice: 148.6161,
			transactionCount: 271,
			voluemPrice: 148.6161
		},
		{
			open: 148.21,
			close: 148.44,
			volume: 91119,
			time: 1654259520000,
			lowest: 148.1,
			highest: 148.8,
			volumePrice: 148.2403,
			transactionCount: 592,
			voluemPrice: 148.2403
		},
		{
			open: 148.58,
			close: 148.4,
			volume: 35893,
			time: 1654259580000,
			lowest: 148.03,
			highest: 148.58,
			volumePrice: 148.3589,
			transactionCount: 242,
			voluemPrice: 148.3589
		},
		{
			open: 148.42,
			close: 148.59,
			volume: 35482,
			time: 1654259640000,
			lowest: 148.25,
			highest: 148.6,
			volumePrice: 148.4617,
			transactionCount: 282,
			voluemPrice: 148.4617
		},
		{
			open: 148.59,
			close: 148,
			volume: 83682,
			time: 1654259700000,
			lowest: 148,
			highest: 148.79,
			volumePrice: 148.4251,
			transactionCount: 575,
			voluemPrice: 148.4251
		},
		{
			open: 147.9999,
			close: 147.4,
			volume: 72859,
			time: 1654259760000,
			lowest: 147.23,
			highest: 147.9999,
			volumePrice: 147.4948,
			transactionCount: 880,
			voluemPrice: 147.4948
		},
		{
			open: 147.59,
			close: 147.56,
			volume: 23167,
			time: 1654259820000,
			lowest: 147.36,
			highest: 147.68,
			volumePrice: 147.4715,
			transactionCount: 393,
			voluemPrice: 147.4715
		},
		{
			open: 147.596,
			close: 147.3,
			volume: 106700,
			time: 1654259880000,
			lowest: 147.23,
			highest: 147.6,
			volumePrice: 147.4237,
			transactionCount: 756,
			voluemPrice: 147.4237
		},
		{
			open: 147.32,
			close: 147.38,
			volume: 25870,
			time: 1654259940000,
			lowest: 147.2,
			highest: 147.47,
			volumePrice: 147.2702,
			transactionCount: 309,
			voluemPrice: 147.2702
		},
		{
			open: 147.38,
			close: 147.4,
			volume: 21746,
			time: 1654260000000,
			lowest: 147.33,
			highest: 147.51,
			volumePrice: 147.4345,
			transactionCount: 293,
			voluemPrice: 147.4345
		},
		{
			open: 147.4,
			close: 147.37,
			volume: 27624,
			time: 1654260060000,
			lowest: 147.3,
			highest: 147.43,
			volumePrice: 147.345,
			transactionCount: 383,
			voluemPrice: 147.345
		},
		{
			open: 147.44,
			close: 147.3,
			volume: 30368,
			time: 1654260120000,
			lowest: 147.07,
			highest: 147.45,
			volumePrice: 147.2632,
			transactionCount: 537,
			voluemPrice: 147.2632
		},
		{
			open: 147.23,
			close: 147.3601,
			volume: 8260,
			time: 1654260180000,
			lowest: 147.22,
			highest: 147.4,
			volumePrice: 147.3239,
			transactionCount: 159,
			voluemPrice: 147.3239
		},
		{
			open: 147.33,
			close: 147.38,
			volume: 32582,
			time: 1654260240000,
			lowest: 147.28,
			highest: 147.49,
			volumePrice: 147.388,
			transactionCount: 337,
			voluemPrice: 147.388
		},
		{
			open: 147.4499,
			close: 147.46,
			volume: 13898,
			time: 1654260300000,
			lowest: 147.38,
			highest: 147.5,
			volumePrice: 147.4344,
			transactionCount: 138,
			voluemPrice: 147.4344
		},
		{
			open: 147.4701,
			close: 147.5001,
			volume: 17262,
			time: 1654260360000,
			lowest: 147.47,
			highest: 147.58,
			volumePrice: 147.5254,
			transactionCount: 266,
			voluemPrice: 147.5254
		},
		{
			open: 147.47,
			close: 147.5492,
			volume: 53578,
			time: 1654260420000,
			lowest: 147.47,
			highest: 147.58,
			volumePrice: 147.5346,
			transactionCount: 485,
			voluemPrice: 147.5346
		},
		{
			open: 147.5101,
			close: 147.4,
			volume: 32653,
			time: 1654260480000,
			lowest: 147.4,
			highest: 147.55,
			volumePrice: 147.4977,
			transactionCount: 286,
			voluemPrice: 147.4977
		},
		{
			open: 147.4,
			close: 147.31,
			volume: 38671,
			time: 1654260540000,
			lowest: 147.28,
			highest: 147.4,
			volumePrice: 147.3381,
			transactionCount: 255,
			voluemPrice: 147.3381
		},
		{
			open: 147.35,
			close: 147.2101,
			volume: 22464,
			time: 1654260600000,
			lowest: 147.17,
			highest: 147.4,
			volumePrice: 147.2461,
			transactionCount: 327,
			voluemPrice: 147.2461
		},
		{
			open: 147.21,
			close: 147.29,
			volume: 15645,
			time: 1654260660000,
			lowest: 147.21,
			highest: 147.29,
			volumePrice: 147.2219,
			transactionCount: 162,
			voluemPrice: 147.2219
		},
		{
			open: 147.2901,
			close: 147.21,
			volume: 18950,
			time: 1654260720000,
			lowest: 147.2,
			highest: 147.4,
			volumePrice: 147.2785,
			transactionCount: 258,
			voluemPrice: 147.2785
		},
		{
			open: 147.25,
			close: 147.26,
			volume: 36926,
			time: 1654260780000,
			lowest: 147.21,
			highest: 147.3,
			volumePrice: 147.2555,
			transactionCount: 202,
			voluemPrice: 147.2555
		},
		{
			open: 147.29,
			close: 147.38,
			volume: 7060,
			time: 1654260840000,
			lowest: 147.29,
			highest: 147.38,
			volumePrice: 147.3454,
			transactionCount: 102,
			voluemPrice: 147.3454
		},
		{
			open: 147.38,
			close: 147.31,
			volume: 25309,
			time: 1654260900000,
			lowest: 147.31,
			highest: 147.4,
			volumePrice: 147.3503,
			transactionCount: 256,
			voluemPrice: 147.3503
		},
		{
			open: 147.31,
			close: 147.3,
			volume: 49889,
			time: 1654260960000,
			lowest: 147.23,
			highest: 147.32,
			volumePrice: 147.2695,
			transactionCount: 328,
			voluemPrice: 147.2695
		},
		{
			open: 147.32,
			close: 147.27,
			volume: 14353,
			time: 1654261020000,
			lowest: 147.25,
			highest: 147.32,
			volumePrice: 147.2823,
			transactionCount: 173,
			voluemPrice: 147.2823
		},
		{
			open: 147.25,
			close: 147.4,
			volume: 49959,
			time: 1654261080000,
			lowest: 147.15,
			highest: 147.4,
			volumePrice: 147.2531,
			transactionCount: 394,
			voluemPrice: 147.2531
		},
		{
			open: 147.4,
			close: 147.56,
			volume: 47603,
			time: 1654261140000,
			lowest: 147.4,
			highest: 147.7,
			volumePrice: 147.555,
			transactionCount: 405,
			voluemPrice: 147.555
		},
		{
			open: 147.6199,
			close: 147.8,
			volume: 11930,
			time: 1654261200000,
			lowest: 147.59,
			highest: 147.8,
			volumePrice: 147.7065,
			transactionCount: 199,
			voluemPrice: 147.7065
		},
		{
			open: 147.81,
			close: 147.6797,
			volume: 29979,
			time: 1654261260000,
			lowest: 147.61,
			highest: 147.82,
			volumePrice: 147.7564,
			transactionCount: 245,
			voluemPrice: 147.7564
		},
		{
			open: 147.6329,
			close: 147.6,
			volume: 34316,
			time: 1654261320000,
			lowest: 147.4999,
			highest: 147.7,
			volumePrice: 147.6339,
			transactionCount: 262,
			voluemPrice: 147.6339
		},
		{
			open: 147.68,
			close: 147.64,
			volume: 7214,
			time: 1654261380000,
			lowest: 147.55,
			highest: 147.68,
			volumePrice: 147.6206,
			transactionCount: 131,
			voluemPrice: 147.6206
		},
		{
			open: 147.65,
			close: 147.6899,
			volume: 18047,
			time: 1654261440000,
			lowest: 147.52,
			highest: 147.7,
			volumePrice: 147.6324,
			transactionCount: 221,
			voluemPrice: 147.6324
		},
		{
			open: 147.7,
			close: 147.8999,
			volume: 39440,
			time: 1654261500000,
			lowest: 147.7,
			highest: 147.95,
			volumePrice: 147.8683,
			transactionCount: 378,
			voluemPrice: 147.8683
		},
		{
			open: 147.8701,
			close: 147.97,
			volume: 34851,
			time: 1654261560000,
			lowest: 147.87,
			highest: 148.05,
			volumePrice: 147.9601,
			transactionCount: 353,
			voluemPrice: 147.9601
		},
		{
			open: 147.95,
			close: 147.81,
			volume: 26019,
			time: 1654261620000,
			lowest: 147.8,
			highest: 148.02,
			volumePrice: 147.918,
			transactionCount: 402,
			voluemPrice: 147.918
		},
		{
			open: 147.93,
			close: 147.7201,
			volume: 7220,
			time: 1654261680000,
			lowest: 147.71,
			highest: 147.93,
			volumePrice: 147.7945,
			transactionCount: 174,
			voluemPrice: 147.7945
		},
		{
			open: 147.74,
			close: 147.54,
			volume: 11601,
			time: 1654261740000,
			lowest: 147.54,
			highest: 147.74,
			volumePrice: 147.612,
			transactionCount: 160,
			voluemPrice: 147.612
		},
		{
			open: 147.53,
			close: 147.65,
			volume: 17577,
			time: 1654261800000,
			lowest: 147.5,
			highest: 147.69,
			volumePrice: 147.622,
			transactionCount: 271,
			voluemPrice: 147.622
		},
		{
			open: 147.6,
			close: 147.37,
			volume: 38035,
			time: 1654261860000,
			lowest: 147.3,
			highest: 147.6,
			volumePrice: 147.4634,
			transactionCount: 358,
			voluemPrice: 147.4634
		},
		{
			open: 147.36,
			close: 147.51,
			volume: 42501,
			time: 1654261920000,
			lowest: 147.3,
			highest: 147.51,
			volumePrice: 147.3655,
			transactionCount: 346,
			voluemPrice: 147.3655
		},
		{
			open: 147.58,
			close: 147.4,
			volume: 17956,
			time: 1654261980000,
			lowest: 147.4,
			highest: 147.72,
			volumePrice: 147.5922,
			transactionCount: 175,
			voluemPrice: 147.5922
		},
		{
			open: 147.39,
			close: 147.4,
			volume: 22742,
			time: 1654262040000,
			lowest: 147.26,
			highest: 147.43,
			volumePrice: 147.3598,
			transactionCount: 321,
			voluemPrice: 147.3598
		},
		{
			open: 147.33,
			close: 147.39,
			volume: 13359,
			time: 1654262100000,
			lowest: 147.3,
			highest: 147.43,
			volumePrice: 147.3815,
			transactionCount: 247,
			voluemPrice: 147.3815
		},
		{
			open: 147.4,
			close: 147.32,
			volume: 51288,
			time: 1654262160000,
			lowest: 147.3,
			highest: 147.43,
			volumePrice: 147.3579,
			transactionCount: 430,
			voluemPrice: 147.3579
		},
		{
			open: 147.3599,
			close: 147.3499,
			volume: 32112,
			time: 1654262220000,
			lowest: 147.21,
			highest: 147.3599,
			volumePrice: 147.268,
			transactionCount: 355,
			voluemPrice: 147.268
		},
		{
			open: 147.35,
			close: 147.3,
			volume: 9981,
			time: 1654262280000,
			lowest: 147.3,
			highest: 147.44,
			volumePrice: 147.3648,
			transactionCount: 181,
			voluemPrice: 147.3648
		},
		{
			open: 147.35,
			close: 147.3,
			volume: 19522,
			time: 1654262340000,
			lowest: 147.28,
			highest: 147.36,
			volumePrice: 147.3032,
			transactionCount: 167,
			voluemPrice: 147.3032
		},
		{
			open: 147.32,
			close: 147.26,
			volume: 30753,
			time: 1654262400000,
			lowest: 147.26,
			highest: 147.35,
			volumePrice: 147.3062,
			transactionCount: 220,
			voluemPrice: 147.3062
		},
		{
			open: 147.25,
			close: 147.3,
			volume: 51453,
			time: 1654262460000,
			lowest: 147.22,
			highest: 147.3,
			volumePrice: 147.2678,
			transactionCount: 296,
			voluemPrice: 147.2678
		},
		{
			open: 147.27,
			close: 147.24,
			volume: 11960,
			time: 1654262520000,
			lowest: 147.21,
			highest: 147.27,
			volumePrice: 147.246,
			transactionCount: 202,
			voluemPrice: 147.246
		},
		{
			open: 147.24,
			close: 147.2499,
			volume: 33654,
			time: 1654262580000,
			lowest: 147.2,
			highest: 147.28,
			volumePrice: 147.223,
			transactionCount: 263,
			voluemPrice: 147.223
		},
		{
			open: 147.25,
			close: 147.31,
			volume: 9847,
			time: 1654262640000,
			lowest: 147.25,
			highest: 147.35,
			volumePrice: 147.2849,
			transactionCount: 136,
			voluemPrice: 147.2849
		},
		{
			open: 147.31,
			close: 147,
			volume: 64785,
			time: 1654262700000,
			lowest: 146.94,
			highest: 147.31,
			volumePrice: 147.0578,
			transactionCount: 1209,
			voluemPrice: 147.0578
		},
		{
			open: 147.05,
			close: 147.08,
			volume: 16137,
			time: 1654262760000,
			lowest: 147,
			highest: 147.14,
			volumePrice: 147.0786,
			transactionCount: 251,
			voluemPrice: 147.0786
		},
		{
			open: 147.08,
			close: 146.94,
			volume: 32662,
			time: 1654262820000,
			lowest: 146.89,
			highest: 147.12,
			volumePrice: 147.018,
			transactionCount: 490,
			voluemPrice: 147.018
		},
		{
			open: 146.9,
			close: 146.85,
			volume: 17227,
			time: 1654262880000,
			lowest: 146.81,
			highest: 146.99,
			volumePrice: 146.8988,
			transactionCount: 366,
			voluemPrice: 146.8988
		},
		{
			open: 146.89,
			close: 146.89,
			volume: 35212,
			time: 1654262940000,
			lowest: 146.75,
			highest: 146.9,
			volumePrice: 146.8251,
			transactionCount: 443,
			voluemPrice: 146.8251
		},
		{
			open: 146.9,
			close: 146.9965,
			volume: 1759935,
			time: 1654263000000,
			lowest: 146.51,
			highest: 147.09,
			volumePrice: 146.7802,
			transactionCount: 17901,
			voluemPrice: 146.7802
		},
		{
			open: 147.02,
			close: 147.59,
			volume: 876688,
			time: 1654263060000,
			lowest: 146.98,
			highest: 147.72,
			volumePrice: 147.3747,
			transactionCount: 7477,
			voluemPrice: 147.3747
		},
		{
			open: 147.6,
			close: 147.67,
			volume: 609764,
			time: 1654263120000,
			lowest: 147.53,
			highest: 147.97,
			volumePrice: 147.7863,
			transactionCount: 5188,
			voluemPrice: 147.7863
		},
		{
			open: 147.69,
			close: 147.7,
			volume: 521594,
			time: 1654263180000,
			lowest: 147.38,
			highest: 147.77,
			volumePrice: 147.6244,
			transactionCount: 4818,
			voluemPrice: 147.6244
		},
		{
			open: 147.7,
			close: 147.64,
			volume: 371200,
			time: 1654263240000,
			lowest: 147.5,
			highest: 147.76,
			volumePrice: 147.6171,
			transactionCount: 3359,
			voluemPrice: 147.6171
		},
		{
			open: 147.64,
			close: 147.26,
			volume: 429275,
			time: 1654263300000,
			lowest: 147.2,
			highest: 147.695,
			volumePrice: 147.4781,
			transactionCount: 4065,
			voluemPrice: 147.4781
		},
		{
			open: 147.2644,
			close: 146.51,
			volume: 679539,
			time: 1654263360000,
			lowest: 146.51,
			highest: 147.32,
			volumePrice: 146.888,
			transactionCount: 6258,
			voluemPrice: 146.888
		},
		{
			open: 146.51,
			close: 146.37,
			volume: 700623,
			time: 1654263420000,
			lowest: 146.12,
			highest: 146.53,
			volumePrice: 146.3274,
			transactionCount: 6448,
			voluemPrice: 146.3274
		},
		{
			open: 146.38,
			close: 146.14,
			volume: 618471,
			time: 1654263480000,
			lowest: 146.01,
			highest: 146.4123,
			volumePrice: 146.2407,
			transactionCount: 5518,
			voluemPrice: 146.2407
		},
		{
			open: 146.1464,
			close: 146.385,
			volume: 466758,
			time: 1654263540000,
			lowest: 146.08,
			highest: 146.39,
			volumePrice: 146.2301,
			transactionCount: 4520,
			voluemPrice: 146.2301
		},
		{
			open: 146.39,
			close: 146.5111,
			volume: 567039,
			time: 1654263600000,
			lowest: 146.33,
			highest: 146.72,
			volumePrice: 146.5446,
			transactionCount: 4661,
			voluemPrice: 146.5446
		},
		{
			open: 146.52,
			close: 146.47,
			volume: 430225,
			time: 1654263660000,
			lowest: 146.42,
			highest: 146.62,
			volumePrice: 146.5131,
			transactionCount: 3758,
			voluemPrice: 146.5131
		},
		{
			open: 146.46,
			close: 146.25,
			volume: 395878,
			time: 1654263720000,
			lowest: 146.215,
			highest: 146.57,
			volumePrice: 146.358,
			transactionCount: 3700,
			voluemPrice: 146.358
		},
		{
			open: 146.26,
			close: 146.245,
			volume: 495866,
			time: 1654263780000,
			lowest: 146.09,
			highest: 146.43,
			volumePrice: 146.2705,
			transactionCount: 2983,
			voluemPrice: 146.2705
		},
		{
			open: 146.2475,
			close: 145.91,
			volume: 708038,
			time: 1654263840000,
			lowest: 145.83,
			highest: 146.31,
			volumePrice: 146.0361,
			transactionCount: 6321,
			voluemPrice: 146.0361
		},
		{
			open: 145.9,
			close: 145.895,
			volume: 387254,
			time: 1654263900000,
			lowest: 145.72,
			highest: 145.96,
			volumePrice: 145.8402,
			transactionCount: 3804,
			voluemPrice: 145.8402
		},
		{
			open: 145.895,
			close: 145.95,
			volume: 287850,
			time: 1654263960000,
			lowest: 145.85,
			highest: 146.089,
			volumePrice: 145.9822,
			transactionCount: 2842,
			voluemPrice: 145.9822
		},
		{
			open: 145.95,
			close: 146.19,
			volume: 392691,
			time: 1654264020000,
			lowest: 145.9,
			highest: 146.28,
			volumePrice: 146.0585,
			transactionCount: 3764,
			voluemPrice: 146.0585
		},
		{
			open: 146.19,
			close: 146.45,
			volume: 346317,
			time: 1654264080000,
			lowest: 146.19,
			highest: 146.45,
			volumePrice: 146.3026,
			transactionCount: 3274,
			voluemPrice: 146.3026
		},
		{
			open: 146.46,
			close: 146.4301,
			volume: 396250,
			time: 1654264140000,
			lowest: 146.34,
			highest: 146.6584,
			volumePrice: 146.5021,
			transactionCount: 3661,
			voluemPrice: 146.5021
		},
		{
			open: 146.45,
			close: 146.58,
			volume: 249828,
			time: 1654264200000,
			lowest: 146.22,
			highest: 146.59,
			volumePrice: 146.4007,
			transactionCount: 2702,
			voluemPrice: 146.4007
		},
		{
			open: 146.58,
			close: 146.47,
			volume: 221865,
			time: 1654264260000,
			lowest: 146.39,
			highest: 146.62,
			volumePrice: 146.503,
			transactionCount: 2383,
			voluemPrice: 146.503
		},
		{
			open: 146.47,
			close: 146.4789,
			volume: 198917,
			time: 1654264320000,
			lowest: 146.3807,
			highest: 146.64,
			volumePrice: 146.5019,
			transactionCount: 2036,
			voluemPrice: 146.5019
		},
		{
			open: 146.48,
			close: 146.8883,
			volume: 444888,
			time: 1654264380000,
			lowest: 146.48,
			highest: 146.9,
			volumePrice: 146.6647,
			transactionCount: 2995,
			voluemPrice: 146.6647
		},
		{
			open: 146.89,
			close: 146.65,
			volume: 336862,
			time: 1654264440000,
			lowest: 146.55,
			highest: 146.96,
			volumePrice: 146.7679,
			transactionCount: 2978,
			voluemPrice: 146.7679
		},
		{
			open: 146.65,
			close: 146.88,
			volume: 350818,
			time: 1654264500000,
			lowest: 146.595,
			highest: 147.08,
			volumePrice: 146.9136,
			transactionCount: 3236,
			voluemPrice: 146.9136
		},
		{
			open: 146.89,
			close: 147.1687,
			volume: 325397,
			time: 1654264560000,
			lowest: 146.86,
			highest: 147.17,
			volumePrice: 147.0014,
			transactionCount: 2973,
			voluemPrice: 147.0014
		},
		{
			open: 147.1883,
			close: 146.87,
			volume: 372819,
			time: 1654264620000,
			lowest: 146.8,
			highest: 147.1964,
			volumePrice: 146.9864,
			transactionCount: 3341,
			voluemPrice: 146.9864
		},
		{
			open: 146.87,
			close: 146.84,
			volume: 267979,
			time: 1654264680000,
			lowest: 146.61,
			highest: 146.94,
			volumePrice: 146.7717,
			transactionCount: 2699,
			voluemPrice: 146.7717
		},
		{
			open: 146.84,
			close: 147.135,
			volume: 280401,
			time: 1654264740000,
			lowest: 146.69,
			highest: 147.2,
			volumePrice: 146.9467,
			transactionCount: 2365,
			voluemPrice: 146.9467
		},
		{
			open: 147.15,
			close: 147.395,
			volume: 424114,
			time: 1654264800000,
			lowest: 146.82,
			highest: 147.41,
			volumePrice: 147.1399,
			transactionCount: 3778,
			voluemPrice: 147.1399
		},
		{
			open: 147.4,
			close: 147.38,
			volume: 365203,
			time: 1654264860000,
			lowest: 147.26,
			highest: 147.465,
			volumePrice: 147.3725,
			transactionCount: 2853,
			voluemPrice: 147.3725
		},
		{
			open: 147.3799,
			close: 147.2957,
			volume: 312372,
			time: 1654264920000,
			lowest: 147.2,
			highest: 147.4782,
			volumePrice: 147.319,
			transactionCount: 2836,
			voluemPrice: 147.319
		},
		{
			open: 147.3,
			close: 147.1914,
			volume: 335497,
			time: 1654264980000,
			lowest: 147.14,
			highest: 147.4353,
			volumePrice: 147.3009,
			transactionCount: 2829,
			voluemPrice: 147.3009
		},
		{
			open: 147.19,
			close: 147.0047,
			volume: 305647,
			time: 1654265040000,
			lowest: 146.91,
			highest: 147.19,
			volumePrice: 147.0444,
			transactionCount: 2759,
			voluemPrice: 147.0444
		},
		{
			open: 147.0016,
			close: 146.77,
			volume: 386135,
			time: 1654265100000,
			lowest: 146.61,
			highest: 147.0655,
			volumePrice: 146.7999,
			transactionCount: 3455,
			voluemPrice: 146.7999
		},
		{
			open: 146.77,
			close: 146.7604,
			volume: 223260,
			time: 1654265160000,
			lowest: 146.67,
			highest: 146.9078,
			volumePrice: 146.7817,
			transactionCount: 2058,
			voluemPrice: 146.7817
		},
		{
			open: 146.769,
			close: 147.08,
			volume: 251606,
			time: 1654265220000,
			lowest: 146.69,
			highest: 147.24,
			volumePrice: 147.0658,
			transactionCount: 2386,
			voluemPrice: 147.0658
		},
		{
			open: 147.08,
			close: 147.01,
			volume: 295042,
			time: 1654265280000,
			lowest: 146.88,
			highest: 147.14,
			volumePrice: 147.0638,
			transactionCount: 1874,
			voluemPrice: 147.0638
		},
		{
			open: 147.02,
			close: 146.95,
			volume: 149818,
			time: 1654265340000,
			lowest: 146.92,
			highest: 147.11,
			volumePrice: 147.0124,
			transactionCount: 1746,
			voluemPrice: 147.0124
		},
		{
			open: 146.94,
			close: 146.805,
			volume: 294531,
			time: 1654265400000,
			lowest: 146.7,
			highest: 147.07,
			volumePrice: 146.9009,
			transactionCount: 2835,
			voluemPrice: 146.9009
		},
		{
			open: 146.8253,
			close: 146.565,
			volume: 338692,
			time: 1654265460000,
			lowest: 146.48,
			highest: 146.88,
			volumePrice: 146.632,
			transactionCount: 3340,
			voluemPrice: 146.632
		},
		{
			open: 146.564,
			close: 146.4708,
			volume: 254464,
			time: 1654265520000,
			lowest: 146.3191,
			highest: 146.5986,
			volumePrice: 146.449,
			transactionCount: 2638,
			voluemPrice: 146.449
		},
		{
			open: 146.48,
			close: 146.6387,
			volume: 288034,
			time: 1654265580000,
			lowest: 146.465,
			highest: 146.75,
			volumePrice: 146.6156,
			transactionCount: 2667,
			voluemPrice: 146.6156
		},
		{
			open: 146.6501,
			close: 146.57,
			volume: 260202,
			time: 1654265640000,
			lowest: 146.52,
			highest: 146.69,
			volumePrice: 146.6002,
			transactionCount: 2397,
			voluemPrice: 146.6002
		},
		{
			open: 146.6074,
			close: 146.8,
			volume: 261540,
			time: 1654265700000,
			lowest: 146.51,
			highest: 146.9,
			volumePrice: 146.6625,
			transactionCount: 2489,
			voluemPrice: 146.6625
		},
		{
			open: 146.8,
			close: 146.7,
			volume: 194283,
			time: 1654265760000,
			lowest: 146.53,
			highest: 146.89,
			volumePrice: 146.6698,
			transactionCount: 2124,
			voluemPrice: 146.6698
		},
		{
			open: 146.71,
			close: 147.12,
			volume: 401805,
			time: 1654265820000,
			lowest: 146.59,
			highest: 147.17,
			volumePrice: 146.8318,
			transactionCount: 2191,
			voluemPrice: 146.8318
		},
		{
			open: 147.1181,
			close: 146.9253,
			volume: 239949,
			time: 1654265880000,
			lowest: 146.86,
			highest: 147.15,
			volumePrice: 146.9855,
			transactionCount: 2126,
			voluemPrice: 146.9855
		},
		{
			open: 146.9401,
			close: 146.84,
			volume: 194888,
			time: 1654265940000,
			lowest: 146.73,
			highest: 146.945,
			volumePrice: 146.8316,
			transactionCount: 1914,
			voluemPrice: 146.8316
		},
		{
			open: 146.84,
			close: 146.6046,
			volume: 254098,
			time: 1654266000000,
			lowest: 146.56,
			highest: 146.851,
			volumePrice: 146.7124,
			transactionCount: 2338,
			voluemPrice: 146.7124
		},
		{
			open: 146.6,
			close: 146.425,
			volume: 280878,
			time: 1654266060000,
			lowest: 146.305,
			highest: 146.6999,
			volumePrice: 146.4878,
			transactionCount: 2747,
			voluemPrice: 146.4878
		},
		{
			open: 146.43,
			close: 146.6739,
			volume: 203816,
			time: 1654266120000,
			lowest: 146.43,
			highest: 146.74,
			volumePrice: 146.6013,
			transactionCount: 1955,
			voluemPrice: 146.6013
		},
		{
			open: 146.69,
			close: 146.61,
			volume: 263972,
			time: 1654266180000,
			lowest: 146.52,
			highest: 146.89,
			volumePrice: 146.6928,
			transactionCount: 2167,
			voluemPrice: 146.6928
		},
		{
			open: 146.5697,
			close: 146.3581,
			volume: 274278,
			time: 1654266240000,
			lowest: 146.26,
			highest: 146.59,
			volumePrice: 146.3997,
			transactionCount: 2441,
			voluemPrice: 146.3997
		},
		{
			open: 146.34,
			close: 146.24,
			volume: 218856,
			time: 1654266300000,
			lowest: 146.23,
			highest: 146.44,
			volumePrice: 146.3361,
			transactionCount: 2039,
			voluemPrice: 146.3361
		},
		{
			open: 146.25,
			close: 146.191,
			volume: 214450,
			time: 1654266360000,
			lowest: 146.18,
			highest: 146.359,
			volumePrice: 146.2528,
			transactionCount: 2180,
			voluemPrice: 146.2528
		},
		{
			open: 146.195,
			close: 146.365,
			volume: 214583,
			time: 1654266420000,
			lowest: 146.19,
			highest: 146.49,
			volumePrice: 146.3304,
			transactionCount: 2191,
			voluemPrice: 146.3304
		},
		{
			open: 146.3611,
			close: 146.2999,
			volume: 250266,
			time: 1654266480000,
			lowest: 146.2,
			highest: 146.39,
			volumePrice: 146.2908,
			transactionCount: 2396,
			voluemPrice: 146.2908
		},
		{
			open: 146.3,
			close: 146.345,
			volume: 182266,
			time: 1654266540000,
			lowest: 146.1416,
			highest: 146.36,
			volumePrice: 146.2323,
			transactionCount: 1921,
			voluemPrice: 146.2323
		},
		{
			open: 146.345,
			close: 146.1099,
			volume: 444922,
			time: 1654266600000,
			lowest: 145.94,
			highest: 146.345,
			volumePrice: 146.0703,
			transactionCount: 4982,
			voluemPrice: 146.0703
		},
		{
			open: 146.11,
			close: 146.27,
			volume: 261936,
			time: 1654266660000,
			lowest: 146,
			highest: 146.33,
			volumePrice: 146.1622,
			transactionCount: 2286,
			voluemPrice: 146.1622
		},
		{
			open: 146.28,
			close: 146.11,
			volume: 169262,
			time: 1654266720000,
			lowest: 146.0212,
			highest: 146.3,
			volumePrice: 146.1205,
			transactionCount: 1785,
			voluemPrice: 146.1205
		},
		{
			open: 146.12,
			close: 146.06,
			volume: 135494,
			time: 1654266780000,
			lowest: 146.04,
			highest: 146.16,
			volumePrice: 146.1037,
			transactionCount: 1612,
			voluemPrice: 146.1037
		},
		{
			open: 146.06,
			close: 146.1,
			volume: 152340,
			time: 1654266840000,
			lowest: 145.98,
			highest: 146.18,
			volumePrice: 146.0772,
			transactionCount: 1797,
			voluemPrice: 146.0772
		},
		{
			open: 146.1,
			close: 146.0101,
			volume: 161609,
			time: 1654266900000,
			lowest: 146,
			highest: 146.21,
			volumePrice: 146.0915,
			transactionCount: 1675,
			voluemPrice: 146.0915
		},
		{
			open: 146.02,
			close: 145.775,
			volume: 371362,
			time: 1654266960000,
			lowest: 145.73,
			highest: 146.04,
			volumePrice: 145.8734,
			transactionCount: 3709,
			voluemPrice: 145.8734
		},
		{
			open: 145.78,
			close: 145.5621,
			volume: 401828,
			time: 1654267020000,
			lowest: 145.5601,
			highest: 145.8,
			volumePrice: 145.7,
			transactionCount: 3693,
			voluemPrice: 145.7
		},
		{
			open: 145.56,
			close: 145.415,
			volume: 454084,
			time: 1654267080000,
			lowest: 145.38,
			highest: 145.65,
			volumePrice: 145.4953,
			transactionCount: 4378,
			voluemPrice: 145.4953
		},
		{
			open: 145.4223,
			close: 145.47,
			volume: 354334,
			time: 1654267140000,
			lowest: 145.25,
			highest: 145.53,
			volumePrice: 145.4078,
			transactionCount: 3229,
			voluemPrice: 145.4078
		},
		{
			open: 145.47,
			close: 145.36,
			volume: 250600,
			time: 1654267200000,
			lowest: 145.32,
			highest: 145.5199,
			volumePrice: 145.4177,
			transactionCount: 2303,
			voluemPrice: 145.4177
		},
		{
			open: 145.35,
			close: 145.43,
			volume: 167126,
			time: 1654267260000,
			lowest: 145.31,
			highest: 145.54,
			volumePrice: 145.427,
			transactionCount: 1964,
			voluemPrice: 145.427
		},
		{
			open: 145.425,
			close: 145.4,
			volume: 141408,
			time: 1654267320000,
			lowest: 145.36,
			highest: 145.46,
			volumePrice: 145.4041,
			transactionCount: 1576,
			voluemPrice: 145.4041
		},
		{
			open: 145.3961,
			close: 145.505,
			volume: 201447,
			time: 1654267380000,
			lowest: 145.39,
			highest: 145.55,
			volumePrice: 145.4846,
			transactionCount: 1963,
			voluemPrice: 145.4846
		},
		{
			open: 145.5146,
			close: 145.73,
			volume: 273809,
			time: 1654267440000,
			lowest: 145.47,
			highest: 145.75,
			volumePrice: 145.6112,
			transactionCount: 2803,
			voluemPrice: 145.6112
		},
		{
			open: 145.7314,
			close: 145.67,
			volume: 238183,
			time: 1654267500000,
			lowest: 145.58,
			highest: 145.74,
			volumePrice: 145.6747,
			transactionCount: 2103,
			voluemPrice: 145.6747
		},
		{
			open: 145.67,
			close: 145.8,
			volume: 204320,
			time: 1654267560000,
			lowest: 145.67,
			highest: 145.91,
			volumePrice: 145.8044,
			transactionCount: 2052,
			voluemPrice: 145.8044
		},
		{
			open: 145.805,
			close: 145.79,
			volume: 210967,
			time: 1654267620000,
			lowest: 145.72,
			highest: 145.875,
			volumePrice: 145.8016,
			transactionCount: 1689,
			voluemPrice: 145.8016
		},
		{
			open: 145.79,
			close: 145.93,
			volume: 141737,
			time: 1654267680000,
			lowest: 145.76,
			highest: 145.9397,
			volumePrice: 145.8714,
			transactionCount: 1490,
			voluemPrice: 145.8714
		},
		{
			open: 145.94,
			close: 145.8358,
			volume: 124085,
			time: 1654267740000,
			lowest: 145.7939,
			highest: 145.95,
			volumePrice: 145.8611,
			transactionCount: 1408,
			voluemPrice: 145.8611
		},
		{
			open: 145.83,
			close: 145.7501,
			volume: 129590,
			time: 1654267800000,
			lowest: 145.71,
			highest: 145.845,
			volumePrice: 145.7698,
			transactionCount: 1608,
			voluemPrice: 145.7698
		},
		{
			open: 145.77,
			close: 145.71,
			volume: 196687,
			time: 1654267860000,
			lowest: 145.6313,
			highest: 145.7892,
			volumePrice: 145.7435,
			transactionCount: 1674,
			voluemPrice: 145.7435
		},
		{
			open: 145.7064,
			close: 145.6219,
			volume: 161340,
			time: 1654267920000,
			lowest: 145.5801,
			highest: 145.73,
			volumePrice: 145.6547,
			transactionCount: 1622,
			voluemPrice: 145.6547
		},
		{
			open: 145.62,
			close: 145.66,
			volume: 161785,
			time: 1654267980000,
			lowest: 145.52,
			highest: 145.79,
			volumePrice: 145.6844,
			transactionCount: 1600,
			voluemPrice: 145.6844
		},
		{
			open: 145.665,
			close: 145.5515,
			volume: 116548,
			time: 1654268040000,
			lowest: 145.5201,
			highest: 145.7599,
			volumePrice: 145.6552,
			transactionCount: 1295,
			voluemPrice: 145.6552
		},
		{
			open: 145.56,
			close: 145.58,
			volume: 148073,
			time: 1654268100000,
			lowest: 145.48,
			highest: 145.68,
			volumePrice: 145.5623,
			transactionCount: 1939,
			voluemPrice: 145.5623
		},
		{
			open: 145.57,
			close: 145.581,
			volume: 131052,
			time: 1654268160000,
			lowest: 145.46,
			highest: 145.64,
			volumePrice: 145.5543,
			transactionCount: 1451,
			voluemPrice: 145.5543
		},
		{
			open: 145.59,
			close: 145.71,
			volume: 175638,
			time: 1654268220000,
			lowest: 145.57,
			highest: 145.74,
			volumePrice: 145.6391,
			transactionCount: 1902,
			voluemPrice: 145.6391
		},
		{
			open: 145.71,
			close: 145.655,
			volume: 164516,
			time: 1654268280000,
			lowest: 145.6299,
			highest: 145.84,
			volumePrice: 145.7493,
			transactionCount: 1575,
			voluemPrice: 145.7493
		},
		{
			open: 145.66,
			close: 145.52,
			volume: 135362,
			time: 1654268340000,
			lowest: 145.47,
			highest: 145.69,
			volumePrice: 145.5676,
			transactionCount: 1507,
			voluemPrice: 145.5676
		},
		{
			open: 145.51,
			close: 145.3799,
			volume: 177107,
			time: 1654268400000,
			lowest: 145.37,
			highest: 145.585,
			volumePrice: 145.4649,
			transactionCount: 1948,
			voluemPrice: 145.4649
		},
		{
			open: 145.375,
			close: 145.375,
			volume: 157581,
			time: 1654268460000,
			lowest: 145.36,
			highest: 145.53,
			volumePrice: 145.4256,
			transactionCount: 1693,
			voluemPrice: 145.4256
		},
		{
			open: 145.37,
			close: 145.3299,
			volume: 171685,
			time: 1654268520000,
			lowest: 145.3,
			highest: 145.4514,
			volumePrice: 145.3657,
			transactionCount: 1941,
			voluemPrice: 145.3657
		},
		{
			open: 145.32,
			close: 145.16,
			volume: 218141,
			time: 1654268580000,
			lowest: 145.16,
			highest: 145.36,
			volumePrice: 145.2623,
			transactionCount: 2566,
			voluemPrice: 145.2623
		},
		{
			open: 145.162,
			close: 145.1523,
			volume: 247258,
			time: 1654268640000,
			lowest: 145.09,
			highest: 145.22,
			volumePrice: 145.1529,
			transactionCount: 2609,
			voluemPrice: 145.1529
		},
		{
			open: 145.16,
			close: 145.05,
			volume: 200249,
			time: 1654268700000,
			lowest: 145.05,
			highest: 145.2,
			volumePrice: 145.1308,
			transactionCount: 2072,
			voluemPrice: 145.1308
		},
		{
			open: 145.055,
			close: 145.1199,
			volume: 174513,
			time: 1654268760000,
			lowest: 145.0349,
			highest: 145.19,
			volumePrice: 145.1108,
			transactionCount: 1862,
			voluemPrice: 145.1108
		},
		{
			open: 145.11,
			close: 145.1393,
			volume: 120295,
			time: 1654268820000,
			lowest: 145.08,
			highest: 145.185,
			volumePrice: 145.137,
			transactionCount: 1462,
			voluemPrice: 145.137
		},
		{
			open: 145.13,
			close: 145.14,
			volume: 111217,
			time: 1654268880000,
			lowest: 145.03,
			highest: 145.17,
			volumePrice: 145.0915,
			transactionCount: 1458,
			voluemPrice: 145.0915
		},
		{
			open: 145.14,
			close: 145.0759,
			volume: 163237,
			time: 1654268940000,
			lowest: 145.06,
			highest: 145.215,
			volumePrice: 145.1491,
			transactionCount: 1768,
			voluemPrice: 145.1491
		},
		{
			open: 145.0791,
			close: 144.94,
			volume: 740113,
			time: 1654269000000,
			lowest: 144.79,
			highest: 145.1,
			volumePrice: 144.9628,
			transactionCount: 10820,
			voluemPrice: 144.9628
		},
		{
			open: 144.95,
			close: 144.713,
			volume: 509916,
			time: 1654269060000,
			lowest: 144.67,
			highest: 144.97,
			volumePrice: 144.7877,
			transactionCount: 3387,
			voluemPrice: 144.7877
		},
		{
			open: 144.725,
			close: 144.59,
			volume: 269452,
			time: 1654269120000,
			lowest: 144.59,
			highest: 144.79,
			volumePrice: 144.6992,
			transactionCount: 2523,
			voluemPrice: 144.6992
		},
		{
			open: 144.595,
			close: 144.57,
			volume: 258807,
			time: 1654269180000,
			lowest: 144.5646,
			highest: 144.78,
			volumePrice: 144.6516,
			transactionCount: 2351,
			voluemPrice: 144.6516
		},
		{
			open: 144.574,
			close: 144.54,
			volume: 265425,
			time: 1654269240000,
			lowest: 144.51,
			highest: 144.66,
			volumePrice: 144.5774,
			transactionCount: 2322,
			voluemPrice: 144.5774
		},
		{
			open: 144.54,
			close: 144.665,
			volume: 322998,
			time: 1654269300000,
			lowest: 144.475,
			highest: 144.68,
			volumePrice: 144.5613,
			transactionCount: 3519,
			voluemPrice: 144.5613
		},
		{
			open: 144.65,
			close: 144.525,
			volume: 157233,
			time: 1654269360000,
			lowest: 144.47,
			highest: 144.655,
			volumePrice: 144.557,
			transactionCount: 1781,
			voluemPrice: 144.557
		},
		{
			open: 144.525,
			close: 144.4841,
			volume: 153482,
			time: 1654269420000,
			lowest: 144.46,
			highest: 144.585,
			volumePrice: 144.5274,
			transactionCount: 1733,
			voluemPrice: 144.5274
		},
		{
			open: 144.475,
			close: 144.66,
			volume: 245205,
			time: 1654269480000,
			lowest: 144.46,
			highest: 144.75,
			volumePrice: 144.605,
			transactionCount: 1934,
			voluemPrice: 144.605
		},
		{
			open: 144.6643,
			close: 144.68,
			volume: 205464,
			time: 1654269540000,
			lowest: 144.65,
			highest: 144.7399,
			volumePrice: 144.7041,
			transactionCount: 1834,
			voluemPrice: 144.7041
		},
		{
			open: 144.67,
			close: 144.68,
			volume: 136122,
			time: 1654269600000,
			lowest: 144.58,
			highest: 144.71,
			volumePrice: 144.6409,
			transactionCount: 1470,
			voluemPrice: 144.6409
		},
		{
			open: 144.68,
			close: 144.645,
			volume: 178518,
			time: 1654269660000,
			lowest: 144.56,
			highest: 144.75,
			volumePrice: 144.6747,
			transactionCount: 1939,
			voluemPrice: 144.6747
		},
		{
			open: 144.6483,
			close: 144.75,
			volume: 192896,
			time: 1654269720000,
			lowest: 144.5,
			highest: 144.78,
			volumePrice: 144.6604,
			transactionCount: 1787,
			voluemPrice: 144.6604
		},
		{
			open: 144.75,
			close: 144.6018,
			volume: 101251,
			time: 1654269780000,
			lowest: 144.595,
			highest: 144.7546,
			volumePrice: 144.6691,
			transactionCount: 1220,
			voluemPrice: 144.6691
		},
		{
			open: 144.605,
			close: 144.6424,
			volume: 117234,
			time: 1654269840000,
			lowest: 144.605,
			highest: 144.71,
			volumePrice: 144.6509,
			transactionCount: 1195,
			voluemPrice: 144.6509
		},
		{
			open: 144.64,
			close: 144.5802,
			volume: 184020,
			time: 1654269900000,
			lowest: 144.57,
			highest: 144.75,
			volumePrice: 144.6548,
			transactionCount: 1734,
			voluemPrice: 144.6548
		},
		{
			open: 144.58,
			close: 144.74,
			volume: 194448,
			time: 1654269960000,
			lowest: 144.58,
			highest: 144.86,
			volumePrice: 144.7606,
			transactionCount: 1776,
			voluemPrice: 144.7606
		},
		{
			open: 144.74,
			close: 144.655,
			volume: 136082,
			time: 1654270020000,
			lowest: 144.62,
			highest: 144.745,
			volumePrice: 144.6676,
			transactionCount: 1493,
			voluemPrice: 144.6676
		},
		{
			open: 144.65,
			close: 144.71,
			volume: 109712,
			time: 1654270080000,
			lowest: 144.61,
			highest: 144.71,
			volumePrice: 144.6544,
			transactionCount: 1279,
			voluemPrice: 144.6544
		},
		{
			open: 144.7,
			close: 144.76,
			volume: 144678,
			time: 1654270140000,
			lowest: 144.655,
			highest: 144.785,
			volumePrice: 144.7416,
			transactionCount: 1495,
			voluemPrice: 144.7416
		},
		{
			open: 144.7639,
			close: 144.84,
			volume: 154396,
			time: 1654270200000,
			lowest: 144.71,
			highest: 144.86,
			volumePrice: 144.8064,
			transactionCount: 1657,
			voluemPrice: 144.8064
		},
		{
			open: 144.84,
			close: 145.015,
			volume: 183465,
			time: 1654270260000,
			lowest: 144.78,
			highest: 145.02,
			volumePrice: 144.8714,
			transactionCount: 1815,
			voluemPrice: 144.8714
		},
		{
			open: 145.015,
			close: 145.12,
			volume: 252251,
			time: 1654270320000,
			lowest: 144.955,
			highest: 145.13,
			volumePrice: 145.0519,
			transactionCount: 2244,
			voluemPrice: 145.0519
		},
		{
			open: 145.12,
			close: 145.085,
			volume: 273974,
			time: 1654270380000,
			lowest: 145.04,
			highest: 145.17,
			volumePrice: 145.1054,
			transactionCount: 2396,
			voluemPrice: 145.1054
		},
		{
			open: 145.08,
			close: 145.2,
			volume: 140982,
			time: 1654270440000,
			lowest: 145.07,
			highest: 145.2,
			volumePrice: 145.1094,
			transactionCount: 1585,
			voluemPrice: 145.1094
		},
		{
			open: 145.2,
			close: 145.31,
			volume: 238859,
			time: 1654270500000,
			lowest: 145.19,
			highest: 145.32,
			volumePrice: 145.2344,
			transactionCount: 1937,
			voluemPrice: 145.2344
		},
		{
			open: 145.31,
			close: 145.3999,
			volume: 220873,
			time: 1654270560000,
			lowest: 145.3057,
			highest: 145.4461,
			volumePrice: 145.3863,
			transactionCount: 1803,
			voluemPrice: 145.3863
		},
		{
			open: 145.3918,
			close: 145.52,
			volume: 243521,
			time: 1654270620000,
			lowest: 145.3709,
			highest: 145.535,
			volumePrice: 145.4568,
			transactionCount: 1998,
			voluemPrice: 145.4568
		},
		{
			open: 145.5267,
			close: 145.43,
			volume: 192094,
			time: 1654270680000,
			lowest: 145.34,
			highest: 145.53,
			volumePrice: 145.4351,
			transactionCount: 1876,
			voluemPrice: 145.4351
		},
		{
			open: 145.43,
			close: 145.41,
			volume: 117724,
			time: 1654270740000,
			lowest: 145.3401,
			highest: 145.44,
			volumePrice: 145.4029,
			transactionCount: 1253,
			voluemPrice: 145.4029
		},
		{
			open: 145.4,
			close: 145.405,
			volume: 129041,
			time: 1654270800000,
			lowest: 145.34,
			highest: 145.43,
			volumePrice: 145.3912,
			transactionCount: 1445,
			voluemPrice: 145.3912
		},
		{
			open: 145.41,
			close: 145.345,
			volume: 158683,
			time: 1654270860000,
			lowest: 145.28,
			highest: 145.42,
			volumePrice: 145.3305,
			transactionCount: 1526,
			voluemPrice: 145.3305
		},
		{
			open: 145.35,
			close: 145.29,
			volume: 141365,
			time: 1654270920000,
			lowest: 145.275,
			highest: 145.38,
			volumePrice: 145.3127,
			transactionCount: 1334,
			voluemPrice: 145.3127
		},
		{
			open: 145.29,
			close: 145.305,
			volume: 87899,
			time: 1654270980000,
			lowest: 145.28,
			highest: 145.39,
			volumePrice: 145.3278,
			transactionCount: 1029,
			voluemPrice: 145.3278
		},
		{
			open: 145.305,
			close: 145.3143,
			volume: 164833,
			time: 1654271040000,
			lowest: 145.2,
			highest: 145.3362,
			volumePrice: 145.264,
			transactionCount: 1551,
			voluemPrice: 145.264
		},
		{
			open: 145.31,
			close: 145.25,
			volume: 112782,
			time: 1654271100000,
			lowest: 145.23,
			highest: 145.38,
			volumePrice: 145.3006,
			transactionCount: 1316,
			voluemPrice: 145.3006
		},
		{
			open: 145.24,
			close: 145.18,
			volume: 160334,
			time: 1654271160000,
			lowest: 145.105,
			highest: 145.27,
			volumePrice: 145.1567,
			transactionCount: 1438,
			voluemPrice: 145.1567
		},
		{
			open: 145.1864,
			close: 145.2,
			volume: 111663,
			time: 1654271220000,
			lowest: 145.15,
			highest: 145.2699,
			volumePrice: 145.2227,
			transactionCount: 1083,
			voluemPrice: 145.2227
		},
		{
			open: 145.205,
			close: 145.0824,
			volume: 109881,
			time: 1654271280000,
			lowest: 145.0824,
			highest: 145.21,
			volumePrice: 145.1287,
			transactionCount: 1282,
			voluemPrice: 145.1287
		},
		{
			open: 145.09,
			close: 145.06,
			volume: 137432,
			time: 1654271340000,
			lowest: 145.02,
			highest: 145.15,
			volumePrice: 145.0766,
			transactionCount: 1465,
			voluemPrice: 145.0766
		},
		{
			open: 145.06,
			close: 144.94,
			volume: 175671,
			time: 1654271400000,
			lowest: 144.88,
			highest: 145.06,
			volumePrice: 144.974,
			transactionCount: 2284,
			voluemPrice: 144.974
		},
		{
			open: 144.94,
			close: 145,
			volume: 144981,
			time: 1654271460000,
			lowest: 144.93,
			highest: 145.12,
			volumePrice: 145.0343,
			transactionCount: 1370,
			voluemPrice: 145.0343
		},
		{
			open: 144.9999,
			close: 144.98,
			volume: 86941,
			time: 1654271520000,
			lowest: 144.9654,
			highest: 145.0942,
			volumePrice: 145.033,
			transactionCount: 937,
			voluemPrice: 145.033
		},
		{
			open: 144.9701,
			close: 144.898,
			volume: 110692,
			time: 1654271580000,
			lowest: 144.89,
			highest: 145.03,
			volumePrice: 144.9545,
			transactionCount: 1202,
			voluemPrice: 144.9545
		},
		{
			open: 144.9,
			close: 144.925,
			volume: 124449,
			time: 1654271640000,
			lowest: 144.86,
			highest: 144.97,
			volumePrice: 144.9033,
			transactionCount: 1221,
			voluemPrice: 144.9033
		},
		{
			open: 144.925,
			close: 145.15,
			volume: 159131,
			time: 1654271700000,
			lowest: 144.9,
			highest: 145.1599,
			volumePrice: 145.0517,
			transactionCount: 1658,
			voluemPrice: 145.0517
		},
		{
			open: 145.15,
			close: 145.02,
			volume: 94656,
			time: 1654271760000,
			lowest: 145.001,
			highest: 145.16,
			volumePrice: 145.0819,
			transactionCount: 1153,
			voluemPrice: 145.0819
		},
		{
			open: 145.025,
			close: 145.095,
			volume: 100453,
			time: 1654271820000,
			lowest: 145.0082,
			highest: 145.13,
			volumePrice: 145.0761,
			transactionCount: 1159,
			voluemPrice: 145.0761
		},
		{
			open: 145.09,
			close: 145.1,
			volume: 103178,
			time: 1654271880000,
			lowest: 145.06,
			highest: 145.1399,
			volumePrice: 145.0972,
			transactionCount: 1353,
			voluemPrice: 145.0972
		},
		{
			open: 145.1,
			close: 145.1316,
			volume: 89845,
			time: 1654271940000,
			lowest: 145.0843,
			highest: 145.17,
			volumePrice: 145.1291,
			transactionCount: 1134,
			voluemPrice: 145.1291
		},
		{
			open: 145.13,
			close: 145.4,
			volume: 183243,
			time: 1654272000000,
			lowest: 145.095,
			highest: 145.405,
			volumePrice: 145.2904,
			transactionCount: 1832,
			voluemPrice: 145.2904
		},
		{
			open: 145.4013,
			close: 145.6329,
			volume: 238947,
			time: 1654272060000,
			lowest: 145.38,
			highest: 145.65,
			volumePrice: 145.5134,
			transactionCount: 2270,
			voluemPrice: 145.5134
		},
		{
			open: 145.6387,
			close: 145.62,
			volume: 237613,
			time: 1654272120000,
			lowest: 145.525,
			highest: 145.69,
			volumePrice: 145.6119,
			transactionCount: 2128,
			voluemPrice: 145.6119
		},
		{
			open: 145.61,
			close: 145.675,
			volume: 201708,
			time: 1654272180000,
			lowest: 145.57,
			highest: 145.74,
			volumePrice: 145.6647,
			transactionCount: 1668,
			voluemPrice: 145.6647
		},
		{
			open: 145.675,
			close: 145.55,
			volume: 172446,
			time: 1654272240000,
			lowest: 145.52,
			highest: 145.69,
			volumePrice: 145.5679,
			transactionCount: 1515,
			voluemPrice: 145.5679
		},
		{
			open: 145.56,
			close: 145.59,
			volume: 103199,
			time: 1654272300000,
			lowest: 145.55,
			highest: 145.67,
			volumePrice: 145.5864,
			transactionCount: 1292,
			voluemPrice: 145.5864
		},
		{
			open: 145.59,
			close: 145.51,
			volume: 122121,
			time: 1654272360000,
			lowest: 145.065,
			highest: 145.63,
			volumePrice: 145.5402,
			transactionCount: 1303,
			voluemPrice: 145.5402
		},
		{
			open: 145.5119,
			close: 145.51,
			volume: 175220,
			time: 1654272420000,
			lowest: 145.47,
			highest: 145.5618,
			volumePrice: 145.5069,
			transactionCount: 1730,
			voluemPrice: 145.5069
		},
		{
			open: 145.51,
			close: 145.3517,
			volume: 163583,
			time: 1654272480000,
			lowest: 145.35,
			highest: 145.51,
			volumePrice: 145.4299,
			transactionCount: 1589,
			voluemPrice: 145.4299
		},
		{
			open: 145.3582,
			close: 145.36,
			volume: 151427,
			time: 1654272540000,
			lowest: 145.3,
			highest: 145.39,
			volumePrice: 145.348,
			transactionCount: 1356,
			voluemPrice: 145.348
		},
		{
			open: 145.37,
			close: 145.265,
			volume: 139009,
			time: 1654272600000,
			lowest: 145.23,
			highest: 145.375,
			volumePrice: 145.2838,
			transactionCount: 1369,
			voluemPrice: 145.2838
		},
		{
			open: 145.26,
			close: 145.33,
			volume: 151474,
			time: 1654272660000,
			lowest: 145.21,
			highest: 145.35,
			volumePrice: 145.285,
			transactionCount: 1424,
			voluemPrice: 145.285
		},
		{
			open: 145.34,
			close: 145.28,
			volume: 155551,
			time: 1654272720000,
			lowest: 145.28,
			highest: 145.45,
			volumePrice: 145.3771,
			transactionCount: 1352,
			voluemPrice: 145.3771
		},
		{
			open: 145.285,
			close: 145.3583,
			volume: 102057,
			time: 1654272780000,
			lowest: 145.24,
			highest: 145.37,
			volumePrice: 145.2945,
			transactionCount: 983,
			voluemPrice: 145.2945
		},
		{
			open: 145.35,
			close: 145.2978,
			volume: 80397,
			time: 1654272840000,
			lowest: 145.275,
			highest: 145.37,
			volumePrice: 145.3145,
			transactionCount: 1005,
			voluemPrice: 145.3145
		},
		{
			open: 145.3,
			close: 145.405,
			volume: 104624,
			time: 1654272900000,
			lowest: 145.26,
			highest: 145.44,
			volumePrice: 145.349,
			transactionCount: 1079,
			voluemPrice: 145.349
		},
		{
			open: 145.4,
			close: 145.51,
			volume: 164660,
			time: 1654272960000,
			lowest: 145.4,
			highest: 145.56,
			volumePrice: 145.478,
			transactionCount: 1538,
			voluemPrice: 145.478
		},
		{
			open: 145.5,
			close: 145.45,
			volume: 96285,
			time: 1654273020000,
			lowest: 145.4081,
			highest: 145.53,
			volumePrice: 145.4698,
			transactionCount: 1017,
			voluemPrice: 145.4698
		},
		{
			open: 145.45,
			close: 145.1599,
			volume: 184889,
			time: 1654273080000,
			lowest: 145.13,
			highest: 145.46,
			volumePrice: 145.2732,
			transactionCount: 1944,
			voluemPrice: 145.2732
		},
		{
			open: 145.1538,
			close: 145.035,
			volume: 156376,
			time: 1654273140000,
			lowest: 145.035,
			highest: 145.19,
			volumePrice: 145.1108,
			transactionCount: 1562,
			voluemPrice: 145.1108
		},
		{
			open: 145.0308,
			close: 145.06,
			volume: 158182,
			time: 1654273200000,
			lowest: 145.01,
			highest: 145.0924,
			volumePrice: 145.0545,
			transactionCount: 1323,
			voluemPrice: 145.0545
		},
		{
			open: 145.06,
			close: 145.21,
			volume: 115938,
			time: 1654273260000,
			lowest: 145.03,
			highest: 145.22,
			volumePrice: 145.1163,
			transactionCount: 1141,
			voluemPrice: 145.1163
		},
		{
			open: 145.21,
			close: 145.23,
			volume: 82217,
			time: 1654273320000,
			lowest: 145.2017,
			highest: 145.34,
			volumePrice: 145.2628,
			transactionCount: 903,
			voluemPrice: 145.2628
		},
		{
			open: 145.2234,
			close: 145.5612,
			volume: 146451,
			time: 1654273380000,
			lowest: 145.2234,
			highest: 145.595,
			volumePrice: 145.4165,
			transactionCount: 1334,
			voluemPrice: 145.4165
		},
		{
			open: 145.5755,
			close: 145.6001,
			volume: 166645,
			time: 1654273440000,
			lowest: 145.5,
			highest: 145.73,
			volumePrice: 145.6146,
			transactionCount: 1518,
			voluemPrice: 145.6146
		},
		{
			open: 145.6001,
			close: 145.605,
			volume: 133830,
			time: 1654273500000,
			lowest: 145.53,
			highest: 145.69,
			volumePrice: 145.6044,
			transactionCount: 1257,
			voluemPrice: 145.6044
		},
		{
			open: 145.6099,
			close: 145.56,
			volume: 64901,
			time: 1654273560000,
			lowest: 145.5316,
			highest: 145.6099,
			volumePrice: 145.5713,
			transactionCount: 710,
			voluemPrice: 145.5713
		},
		{
			open: 145.56,
			close: 145.515,
			volume: 93348,
			time: 1654273620000,
			lowest: 145.48,
			highest: 145.56,
			volumePrice: 145.5192,
			transactionCount: 1005,
			voluemPrice: 145.5192
		},
		{
			open: 145.515,
			close: 145.579,
			volume: 63492,
			time: 1654273680000,
			lowest: 145.515,
			highest: 145.61,
			volumePrice: 145.5679,
			transactionCount: 812,
			voluemPrice: 145.5679
		},
		{
			open: 145.57,
			close: 145.66,
			volume: 113958,
			time: 1654273740000,
			lowest: 145.55,
			highest: 145.67,
			volumePrice: 145.6161,
			transactionCount: 1107,
			voluemPrice: 145.6161
		},
		{
			open: 145.67,
			close: 145.575,
			volume: 115213,
			time: 1654273800000,
			lowest: 145.5723,
			highest: 145.7192,
			volumePrice: 145.6419,
			transactionCount: 1119,
			voluemPrice: 145.6419
		},
		{
			open: 145.59,
			close: 145.573,
			volume: 49599,
			time: 1654273860000,
			lowest: 145.56,
			highest: 145.64,
			volumePrice: 145.5928,
			transactionCount: 739,
			voluemPrice: 145.5928
		},
		{
			open: 145.5701,
			close: 145.61,
			volume: 93962,
			time: 1654273920000,
			lowest: 145.53,
			highest: 145.66,
			volumePrice: 145.5846,
			transactionCount: 976,
			voluemPrice: 145.5846
		},
		{
			open: 145.605,
			close: 145.6,
			volume: 81458,
			time: 1654273980000,
			lowest: 145.475,
			highest: 145.6165,
			volumePrice: 145.5345,
			transactionCount: 942,
			voluemPrice: 145.5345
		},
		{
			open: 145.608,
			close: 145.6,
			volume: 68046,
			time: 1654274040000,
			lowest: 145.54,
			highest: 145.63,
			volumePrice: 145.5967,
			transactionCount: 797,
			voluemPrice: 145.5967
		},
		{
			open: 145.6,
			close: 145.71,
			volume: 108571,
			time: 1654274100000,
			lowest: 145.02,
			highest: 145.728,
			volumePrice: 145.6257,
			transactionCount: 1145,
			voluemPrice: 145.6257
		},
		{
			open: 145.7101,
			close: 145.77,
			volume: 122723,
			time: 1654274160000,
			lowest: 145.66,
			highest: 145.78,
			volumePrice: 145.7274,
			transactionCount: 1291,
			voluemPrice: 145.7274
		},
		{
			open: 145.77,
			close: 145.8337,
			volume: 147573,
			time: 1654274220000,
			lowest: 145.71,
			highest: 145.84,
			volumePrice: 145.778,
			transactionCount: 1352,
			voluemPrice: 145.778
		},
		{
			open: 145.835,
			close: 145.8,
			volume: 173685,
			time: 1654274280000,
			lowest: 145.77,
			highest: 145.865,
			volumePrice: 145.8306,
			transactionCount: 2021,
			voluemPrice: 145.8306
		},
		{
			open: 145.8,
			close: 145.86,
			volume: 141069,
			time: 1654274340000,
			lowest: 145.74,
			highest: 145.94,
			volumePrice: 145.8535,
			transactionCount: 1361,
			voluemPrice: 145.8535
		},
		{
			open: 145.86,
			close: 145.81,
			volume: 109893,
			time: 1654274400000,
			lowest: 145.81,
			highest: 145.91,
			volumePrice: 145.8624,
			transactionCount: 991,
			voluemPrice: 145.8624
		},
		{
			open: 145.8101,
			close: 145.6518,
			volume: 114887,
			time: 1654274460000,
			lowest: 145.63,
			highest: 145.8152,
			volumePrice: 145.7262,
			transactionCount: 1099,
			voluemPrice: 145.7262
		},
		{
			open: 145.66,
			close: 145.74,
			volume: 91751,
			time: 1654274520000,
			lowest: 145.6501,
			highest: 145.82,
			volumePrice: 145.7488,
			transactionCount: 989,
			voluemPrice: 145.7488
		},
		{
			open: 145.73,
			close: 145.705,
			volume: 68574,
			time: 1654274580000,
			lowest: 145.67,
			highest: 145.76,
			volumePrice: 145.7051,
			transactionCount: 780,
			voluemPrice: 145.7051
		},
		{
			open: 145.708,
			close: 145.7276,
			volume: 90321,
			time: 1654274640000,
			lowest: 145.67,
			highest: 145.7495,
			volumePrice: 145.7059,
			transactionCount: 896,
			voluemPrice: 145.7059
		},
		{
			open: 145.7238,
			close: 145.7099,
			volume: 75485,
			time: 1654274700000,
			lowest: 145.675,
			highest: 145.775,
			volumePrice: 145.7121,
			transactionCount: 960,
			voluemPrice: 145.7121
		},
		{
			open: 145.7,
			close: 145.75,
			volume: 77955,
			time: 1654274760000,
			lowest: 145.66,
			highest: 145.775,
			volumePrice: 145.7161,
			transactionCount: 777,
			voluemPrice: 145.7161
		},
		{
			open: 145.74,
			close: 145.778,
			volume: 68751,
			time: 1654274820000,
			lowest: 145.7007,
			highest: 145.7976,
			volumePrice: 145.7504,
			transactionCount: 585,
			voluemPrice: 145.7504
		},
		{
			open: 145.78,
			close: 145.92,
			volume: 145333,
			time: 1654274880000,
			lowest: 145.77,
			highest: 145.9337,
			volumePrice: 145.8667,
			transactionCount: 1246,
			voluemPrice: 145.8667
		},
		{
			open: 145.936,
			close: 145.85,
			volume: 107867,
			time: 1654274940000,
			lowest: 145.84,
			highest: 145.95,
			volumePrice: 145.9006,
			transactionCount: 1043,
			voluemPrice: 145.9006
		},
		{
			open: 145.85,
			close: 145.705,
			volume: 109253,
			time: 1654275000000,
			lowest: 145.6999,
			highest: 145.85,
			volumePrice: 145.7673,
			transactionCount: 1261,
			voluemPrice: 145.7673
		},
		{
			open: 145.705,
			close: 145.7,
			volume: 112164,
			time: 1654275060000,
			lowest: 145.63,
			highest: 145.738,
			volumePrice: 145.6756,
			transactionCount: 1033,
			voluemPrice: 145.6756
		},
		{
			open: 145.72,
			close: 145.795,
			volume: 147409,
			time: 1654275120000,
			lowest: 145.69,
			highest: 145.81,
			volumePrice: 145.7354,
			transactionCount: 1188,
			voluemPrice: 145.7354
		},
		{
			open: 145.79,
			close: 145.835,
			volume: 61580,
			time: 1654275180000,
			lowest: 145.75,
			highest: 145.86,
			volumePrice: 145.804,
			transactionCount: 750,
			voluemPrice: 145.804
		},
		{
			open: 145.8358,
			close: 145.84,
			volume: 40050,
			time: 1654275240000,
			lowest: 145.82,
			highest: 145.8647,
			volumePrice: 145.8414,
			transactionCount: 637,
			voluemPrice: 145.8414
		},
		{
			open: 145.84,
			close: 145.945,
			volume: 168267,
			time: 1654275300000,
			lowest: 145.81,
			highest: 146.0087,
			volumePrice: 145.9264,
			transactionCount: 1680,
			voluemPrice: 145.9264
		},
		{
			open: 145.95,
			close: 146.04,
			volume: 187981,
			time: 1654275360000,
			lowest: 145.95,
			highest: 146.105,
			volumePrice: 146.04,
			transactionCount: 1510,
			voluemPrice: 146.04
		},
		{
			open: 146.035,
			close: 145.96,
			volume: 98428,
			time: 1654275420000,
			lowest: 145.92,
			highest: 146.04,
			volumePrice: 145.9735,
			transactionCount: 1051,
			voluemPrice: 145.9735
		},
		{
			open: 145.96,
			close: 145.905,
			volume: 112016,
			time: 1654275480000,
			lowest: 145.86,
			highest: 145.965,
			volumePrice: 145.9011,
			transactionCount: 1111,
			voluemPrice: 145.9011
		},
		{
			open: 145.9003,
			close: 145.95,
			volume: 70590,
			time: 1654275540000,
			lowest: 145.86,
			highest: 145.97,
			volumePrice: 145.9156,
			transactionCount: 893,
			voluemPrice: 145.9156
		},
		{
			open: 145.9521,
			close: 145.995,
			volume: 95559,
			time: 1654275600000,
			lowest: 145.89,
			highest: 146,
			volumePrice: 145.9601,
			transactionCount: 992,
			voluemPrice: 145.9601
		},
		{
			open: 145.9955,
			close: 146.02,
			volume: 378025,
			time: 1654275660000,
			lowest: 145.97,
			highest: 146.14,
			volumePrice: 146.0631,
			transactionCount: 3231,
			voluemPrice: 146.0631
		},
		{
			open: 146.02,
			close: 146.1619,
			volume: 183843,
			time: 1654275720000,
			lowest: 145.995,
			highest: 146.18,
			volumePrice: 146.1032,
			transactionCount: 1624,
			voluemPrice: 146.1032
		},
		{
			open: 146.17,
			close: 146.2399,
			volume: 198313,
			time: 1654275780000,
			lowest: 146.1,
			highest: 146.24,
			volumePrice: 146.1723,
			transactionCount: 1519,
			voluemPrice: 146.1723
		},
		{
			open: 146.24,
			close: 146.245,
			volume: 229425,
			time: 1654275840000,
			lowest: 146.21,
			highest: 146.3442,
			volumePrice: 146.2845,
			transactionCount: 1892,
			voluemPrice: 146.2845
		},
		{
			open: 146.25,
			close: 146.13,
			volume: 201939,
			time: 1654275900000,
			lowest: 146.06,
			highest: 146.26,
			volumePrice: 146.1603,
			transactionCount: 1632,
			voluemPrice: 146.1603
		},
		{
			open: 146.1276,
			close: 146.205,
			volume: 115002,
			time: 1654275960000,
			lowest: 146.115,
			highest: 146.2363,
			volumePrice: 146.1726,
			transactionCount: 938,
			voluemPrice: 146.1726
		},
		{
			open: 146.2099,
			close: 146.099,
			volume: 115294,
			time: 1654276020000,
			lowest: 146.09,
			highest: 146.24,
			volumePrice: 146.1504,
			transactionCount: 1147,
			voluemPrice: 146.1504
		},
		{
			open: 146.0903,
			close: 145.985,
			volume: 182992,
			time: 1654276080000,
			lowest: 145.9101,
			highest: 146.11,
			volumePrice: 145.9875,
			transactionCount: 1395,
			voluemPrice: 145.9875
		},
		{
			open: 145.985,
			close: 146.02,
			volume: 123710,
			time: 1654276140000,
			lowest: 145.94,
			highest: 146.05,
			volumePrice: 145.9991,
			transactionCount: 1148,
			voluemPrice: 145.9991
		},
		{
			open: 146.0159,
			close: 146.06,
			volume: 126167,
			time: 1654276200000,
			lowest: 145.93,
			highest: 146.1,
			volumePrice: 145.9899,
			transactionCount: 1178,
			voluemPrice: 145.9899
		},
		{
			open: 146.055,
			close: 146,
			volume: 117021,
			time: 1654276260000,
			lowest: 145.9124,
			highest: 146.0595,
			volumePrice: 145.9733,
			transactionCount: 1040,
			voluemPrice: 145.9733
		},
		{
			open: 146,
			close: 145.9499,
			volume: 50414,
			time: 1654276320000,
			lowest: 145.9301,
			highest: 146.01,
			volumePrice: 145.9706,
			transactionCount: 684,
			voluemPrice: 145.9706
		},
		{
			open: 145.93,
			close: 145.915,
			volume: 135468,
			time: 1654276380000,
			lowest: 145.83,
			highest: 145.9535,
			volumePrice: 145.8879,
			transactionCount: 1229,
			voluemPrice: 145.8879
		},
		{
			open: 145.92,
			close: 145.88,
			volume: 150627,
			time: 1654276440000,
			lowest: 145.83,
			highest: 145.97,
			volumePrice: 145.9082,
			transactionCount: 1343,
			voluemPrice: 145.9082
		},
		{
			open: 145.88,
			close: 145.738,
			volume: 179598,
			time: 1654276500000,
			lowest: 145.7,
			highest: 145.8901,
			volumePrice: 145.7864,
			transactionCount: 1469,
			voluemPrice: 145.7864
		},
		{
			open: 145.74,
			close: 145.74,
			volume: 117597,
			time: 1654276560000,
			lowest: 145.73,
			highest: 145.8672,
			volumePrice: 145.7947,
			transactionCount: 1004,
			voluemPrice: 145.7947
		},
		{
			open: 145.745,
			close: 145.65,
			volume: 113387,
			time: 1654276620000,
			lowest: 145.62,
			highest: 145.79,
			volumePrice: 145.7057,
			transactionCount: 1201,
			voluemPrice: 145.7057
		},
		{
			open: 145.64,
			close: 145.395,
			volume: 198112,
			time: 1654276680000,
			lowest: 145.39,
			highest: 145.64,
			volumePrice: 145.5072,
			transactionCount: 1985,
			voluemPrice: 145.5072
		},
		{
			open: 145.39,
			close: 145.3701,
			volume: 174135,
			time: 1654276740000,
			lowest: 145.29,
			highest: 145.4794,
			volumePrice: 145.3833,
			transactionCount: 1551,
			voluemPrice: 145.3833
		},
		{
			open: 145.37,
			close: 145.395,
			volume: 110154,
			time: 1654276800000,
			lowest: 145.355,
			highest: 145.4399,
			volumePrice: 145.3965,
			transactionCount: 1047,
			voluemPrice: 145.3965
		},
		{
			open: 145.4,
			close: 145.44,
			volume: 88432,
			time: 1654276860000,
			lowest: 145.37,
			highest: 145.48,
			volumePrice: 145.4269,
			transactionCount: 844,
			voluemPrice: 145.4269
		},
		{
			open: 145.44,
			close: 145.4105,
			volume: 134185,
			time: 1654276920000,
			lowest: 145.35,
			highest: 145.44,
			volumePrice: 145.3894,
			transactionCount: 1452,
			voluemPrice: 145.3894
		},
		{
			open: 145.41,
			close: 145.2479,
			volume: 223170,
			time: 1654276980000,
			lowest: 145.195,
			highest: 145.41,
			volumePrice: 145.2764,
			transactionCount: 1857,
			voluemPrice: 145.2764
		},
		{
			open: 145.235,
			close: 145.41,
			volume: 198414,
			time: 1654277040000,
			lowest: 145.16,
			highest: 145.41,
			volumePrice: 145.2636,
			transactionCount: 1645,
			voluemPrice: 145.2636
		},
		{
			open: 145.405,
			close: 145.455,
			volume: 132484,
			time: 1654277100000,
			lowest: 145.37,
			highest: 145.47,
			volumePrice: 145.4201,
			transactionCount: 973,
			voluemPrice: 145.4201
		},
		{
			open: 145.46,
			close: 145.35,
			volume: 75504,
			time: 1654277160000,
			lowest: 145.35,
			highest: 145.46,
			volumePrice: 145.401,
			transactionCount: 757,
			voluemPrice: 145.401
		},
		{
			open: 145.37,
			close: 145.31,
			volume: 77120,
			time: 1654277220000,
			lowest: 145.3,
			highest: 145.44,
			volumePrice: 145.3717,
			transactionCount: 876,
			voluemPrice: 145.3717
		},
		{
			open: 145.3009,
			close: 145.3273,
			volume: 147213,
			time: 1654277280000,
			lowest: 145.2,
			highest: 145.328,
			volumePrice: 145.2799,
			transactionCount: 1050,
			voluemPrice: 145.2799
		},
		{
			open: 145.33,
			close: 145.35,
			volume: 88616,
			time: 1654277340000,
			lowest: 145.325,
			highest: 145.425,
			volumePrice: 145.3757,
			transactionCount: 903,
			voluemPrice: 145.3757
		},
		{
			open: 145.34,
			close: 145.1342,
			volume: 175758,
			time: 1654277400000,
			lowest: 145.1,
			highest: 145.34,
			volumePrice: 145.1861,
			transactionCount: 1806,
			voluemPrice: 145.1861
		},
		{
			open: 145.1459,
			close: 145.1,
			volume: 158640,
			time: 1654277460000,
			lowest: 145.02,
			highest: 145.1999,
			volumePrice: 145.0964,
			transactionCount: 1552,
			voluemPrice: 145.0964
		},
		{
			open: 145.105,
			close: 145.04,
			volume: 187231,
			time: 1654277520000,
			lowest: 145.04,
			highest: 145.17,
			volumePrice: 145.1009,
			transactionCount: 1811,
			voluemPrice: 145.1009
		},
		{
			open: 145.045,
			close: 145.07,
			volume: 115651,
			time: 1654277580000,
			lowest: 145.03,
			highest: 145.12,
			volumePrice: 145.0623,
			transactionCount: 1322,
			voluemPrice: 145.0623
		},
		{
			open: 145.07,
			close: 145.165,
			volume: 64760,
			time: 1654277640000,
			lowest: 145.04,
			highest: 145.17,
			volumePrice: 145.0852,
			transactionCount: 810,
			voluemPrice: 145.0852
		},
		{
			open: 145.17,
			close: 145.125,
			volume: 125325,
			time: 1654277700000,
			lowest: 145.125,
			highest: 145.2486,
			volumePrice: 145.1831,
			transactionCount: 1154,
			voluemPrice: 145.1831
		},
		{
			open: 145.12,
			close: 145.22,
			volume: 122389,
			time: 1654277760000,
			lowest: 145.11,
			highest: 145.31,
			volumePrice: 145.2387,
			transactionCount: 994,
			voluemPrice: 145.2387
		},
		{
			open: 145.2201,
			close: 145.29,
			volume: 211605,
			time: 1654277820000,
			lowest: 145.22,
			highest: 145.42,
			volumePrice: 145.3367,
			transactionCount: 1408,
			voluemPrice: 145.3367
		},
		{
			open: 145.3,
			close: 145.22,
			volume: 110254,
			time: 1654277880000,
			lowest: 145.215,
			highest: 145.3099,
			volumePrice: 145.258,
			transactionCount: 836,
			voluemPrice: 145.258
		},
		{
			open: 145.24,
			close: 145.2186,
			volume: 83244,
			time: 1654277940000,
			lowest: 145.16,
			highest: 145.24,
			volumePrice: 145.191,
			transactionCount: 779,
			voluemPrice: 145.191
		},
		{
			open: 145.22,
			close: 145.17,
			volume: 104213,
			time: 1654278000000,
			lowest: 145.14,
			highest: 145.2499,
			volumePrice: 145.1934,
			transactionCount: 911,
			voluemPrice: 145.1934
		},
		{
			open: 145.17,
			close: 145.0411,
			volume: 147683,
			time: 1654278060000,
			lowest: 145.02,
			highest: 145.2,
			volumePrice: 145.0948,
			transactionCount: 1309,
			voluemPrice: 145.0948
		},
		{
			open: 145.03,
			close: 144.945,
			volume: 252271,
			time: 1654278120000,
			lowest: 144.9097,
			highest: 145.06,
			volumePrice: 144.9907,
			transactionCount: 3542,
			voluemPrice: 144.9907
		},
		{
			open: 144.945,
			close: 144.87,
			volume: 127609,
			time: 1654278180000,
			lowest: 144.85,
			highest: 144.98,
			volumePrice: 144.9,
			transactionCount: 1334,
			voluemPrice: 144.9
		},
		{
			open: 144.87,
			close: 144.9417,
			volume: 102456,
			time: 1654278240000,
			lowest: 144.86,
			highest: 144.99,
			volumePrice: 144.9207,
			transactionCount: 980,
			voluemPrice: 144.9207
		},
		{
			open: 144.95,
			close: 144.95,
			volume: 73094,
			time: 1654278300000,
			lowest: 144.8711,
			highest: 144.98,
			volumePrice: 144.9289,
			transactionCount: 878,
			voluemPrice: 144.9289
		},
		{
			open: 144.9528,
			close: 144.99,
			volume: 160646,
			time: 1654278360000,
			lowest: 144.91,
			highest: 145.02,
			volumePrice: 144.9838,
			transactionCount: 1083,
			voluemPrice: 144.9838
		},
		{
			open: 144.995,
			close: 145.04,
			volume: 146569,
			time: 1654278420000,
			lowest: 144.97,
			highest: 145.065,
			volumePrice: 145.0283,
			transactionCount: 799,
			voluemPrice: 145.0283
		},
		{
			open: 145.0333,
			close: 145.07,
			volume: 105156,
			time: 1654278480000,
			lowest: 145.03,
			highest: 145.125,
			volumePrice: 145.0953,
			transactionCount: 1060,
			voluemPrice: 145.0953
		},
		{
			open: 145.06,
			close: 145.14,
			volume: 109161,
			time: 1654278540000,
			lowest: 145.01,
			highest: 145.16,
			volumePrice: 145.0733,
			transactionCount: 915,
			voluemPrice: 145.0733
		},
		{
			open: 145.1501,
			close: 145.075,
			volume: 76380,
			time: 1654278600000,
			lowest: 145.05,
			highest: 145.16,
			volumePrice: 145.1014,
			transactionCount: 785,
			voluemPrice: 145.1014
		},
		{
			open: 145.08,
			close: 145.08,
			volume: 105145,
			time: 1654278660000,
			lowest: 145.03,
			highest: 145.14,
			volumePrice: 145.0847,
			transactionCount: 960,
			voluemPrice: 145.0847
		},
		{
			open: 145.085,
			close: 145.06,
			volume: 66054,
			time: 1654278720000,
			lowest: 145.02,
			highest: 145.085,
			volumePrice: 145.048,
			transactionCount: 673,
			voluemPrice: 145.048
		},
		{
			open: 145.0659,
			close: 145.02,
			volume: 88377,
			time: 1654278780000,
			lowest: 145,
			highest: 145.14,
			volumePrice: 145.0603,
			transactionCount: 865,
			voluemPrice: 145.0603
		},
		{
			open: 145.01,
			close: 144.99,
			volume: 86657,
			time: 1654278840000,
			lowest: 144.98,
			highest: 145.11,
			volumePrice: 145.0245,
			transactionCount: 1031,
			voluemPrice: 145.0245
		},
		{
			open: 144.99,
			close: 145.06,
			volume: 119978,
			time: 1654278900000,
			lowest: 144.93,
			highest: 145.06,
			volumePrice: 144.9982,
			transactionCount: 1067,
			voluemPrice: 144.9982
		},
		{
			open: 145.0487,
			close: 144.98,
			volume: 69180,
			time: 1654278960000,
			lowest: 144.98,
			highest: 145.0999,
			volumePrice: 145.0426,
			transactionCount: 825,
			voluemPrice: 145.0426
		},
		{
			open: 144.98,
			close: 145.1,
			volume: 101841,
			time: 1654279020000,
			lowest: 144.96,
			highest: 145.14,
			volumePrice: 145.0725,
			transactionCount: 833,
			voluemPrice: 145.0725
		},
		{
			open: 145.1,
			close: 145.08,
			volume: 63337,
			time: 1654279080000,
			lowest: 145.08,
			highest: 145.14,
			volumePrice: 145.1027,
			transactionCount: 802,
			voluemPrice: 145.1027
		},
		{
			open: 145.09,
			close: 145.0499,
			volume: 86989,
			time: 1654279140000,
			lowest: 145.01,
			highest: 145.09,
			volumePrice: 145.0401,
			transactionCount: 906,
			voluemPrice: 145.0401
		},
		{
			open: 145.0451,
			close: 145.04,
			volume: 131581,
			time: 1654279200000,
			lowest: 144.95,
			highest: 145.085,
			volumePrice: 145.0118,
			transactionCount: 1251,
			voluemPrice: 145.0118
		},
		{
			open: 145.04,
			close: 144.9521,
			volume: 94534,
			time: 1654279260000,
			lowest: 144.94,
			highest: 145.0774,
			volumePrice: 144.9986,
			transactionCount: 981,
			voluemPrice: 144.9986
		},
		{
			open: 144.9599,
			close: 145.01,
			volume: 92987,
			time: 1654279320000,
			lowest: 144.92,
			highest: 145.01,
			volumePrice: 144.9592,
			transactionCount: 966,
			voluemPrice: 144.9592
		},
		{
			open: 145.02,
			close: 145.215,
			volume: 122764,
			time: 1654279380000,
			lowest: 145.013,
			highest: 145.229,
			volumePrice: 145.1309,
			transactionCount: 1056,
			voluemPrice: 145.1309
		},
		{
			open: 145.22,
			close: 145.165,
			volume: 90265,
			time: 1654279440000,
			lowest: 145.15,
			highest: 145.26,
			volumePrice: 145.204,
			transactionCount: 872,
			voluemPrice: 145.204
		},
		{
			open: 145.16,
			close: 145.07,
			volume: 82396,
			time: 1654279500000,
			lowest: 145.04,
			highest: 145.16,
			volumePrice: 145.0852,
			transactionCount: 849,
			voluemPrice: 145.0852
		},
		{
			open: 145.06,
			close: 145.2346,
			volume: 81348,
			time: 1654279560000,
			lowest: 145.0521,
			highest: 145.24,
			volumePrice: 145.1624,
			transactionCount: 855,
			voluemPrice: 145.1624
		},
		{
			open: 145.24,
			close: 145.16,
			volume: 83016,
			time: 1654279620000,
			lowest: 145.1201,
			highest: 145.25,
			volumePrice: 145.1754,
			transactionCount: 845,
			voluemPrice: 145.1754
		},
		{
			open: 145.16,
			close: 145.23,
			volume: 68150,
			time: 1654279680000,
			lowest: 145.12,
			highest: 145.25,
			volumePrice: 145.186,
			transactionCount: 762,
			voluemPrice: 145.186
		},
		{
			open: 145.24,
			close: 145.2301,
			volume: 139370,
			time: 1654279740000,
			lowest: 145.17,
			highest: 145.3,
			volumePrice: 145.2332,
			transactionCount: 1299,
			voluemPrice: 145.2332
		},
		{
			open: 145.2399,
			close: 145.3,
			volume: 108999,
			time: 1654279800000,
			lowest: 145.2054,
			highest: 145.3494,
			volumePrice: 145.2872,
			transactionCount: 969,
			voluemPrice: 145.2872
		},
		{
			open: 145.3024,
			close: 145.21,
			volume: 73996,
			time: 1654279860000,
			lowest: 145.19,
			highest: 145.334,
			volumePrice: 145.2493,
			transactionCount: 812,
			voluemPrice: 145.2493
		},
		{
			open: 145.205,
			close: 145.2605,
			volume: 80408,
			time: 1654279920000,
			lowest: 145.16,
			highest: 145.27,
			volumePrice: 145.2148,
			transactionCount: 810,
			voluemPrice: 145.2148
		},
		{
			open: 145.2699,
			close: 145.2346,
			volume: 55313,
			time: 1654279980000,
			lowest: 145.19,
			highest: 145.2699,
			volumePrice: 145.2193,
			transactionCount: 560,
			voluemPrice: 145.2193
		},
		{
			open: 145.2289,
			close: 145.2977,
			volume: 60372,
			time: 1654280040000,
			lowest: 145.22,
			highest: 145.3,
			volumePrice: 145.2503,
			transactionCount: 642,
			voluemPrice: 145.2503
		},
		{
			open: 145.292,
			close: 145.183,
			volume: 110416,
			time: 1654280100000,
			lowest: 145.17,
			highest: 145.31,
			volumePrice: 145.23,
			transactionCount: 1046,
			voluemPrice: 145.23
		},
		{
			open: 145.19,
			close: 145.155,
			volume: 104923,
			time: 1654280160000,
			lowest: 145.11,
			highest: 145.205,
			volumePrice: 145.1305,
			transactionCount: 900,
			voluemPrice: 145.1305
		},
		{
			open: 145.165,
			close: 145.18,
			volume: 54639,
			time: 1654280220000,
			lowest: 145.14,
			highest: 145.22,
			volumePrice: 145.1846,
			transactionCount: 615,
			voluemPrice: 145.1846
		},
		{
			open: 145.175,
			close: 145.3664,
			volume: 113329,
			time: 1654280280000,
			lowest: 145.17,
			highest: 145.37,
			volumePrice: 145.2697,
			transactionCount: 1060,
			voluemPrice: 145.2697
		},
		{
			open: 145.36,
			close: 145.4201,
			volume: 118201,
			time: 1654280340000,
			lowest: 145.29,
			highest: 145.4293,
			volumePrice: 145.3651,
			transactionCount: 1009,
			voluemPrice: 145.3651
		},
		{
			open: 145.4299,
			close: 145.295,
			volume: 115141,
			time: 1654280400000,
			lowest: 145.28,
			highest: 145.43,
			volumePrice: 145.3528,
			transactionCount: 1051,
			voluemPrice: 145.3528
		},
		{
			open: 145.3,
			close: 145.4,
			volume: 166970,
			time: 1654280460000,
			lowest: 145.29,
			highest: 145.4057,
			volumePrice: 145.3625,
			transactionCount: 1620,
			voluemPrice: 145.3625
		},
		{
			open: 145.3994,
			close: 145.37,
			volume: 107873,
			time: 1654280520000,
			lowest: 145.34,
			highest: 145.43,
			volumePrice: 145.3797,
			transactionCount: 1116,
			voluemPrice: 145.3797
		},
		{
			open: 145.3621,
			close: 145.31,
			volume: 89820,
			time: 1654280580000,
			lowest: 145.27,
			highest: 145.41,
			volumePrice: 145.3149,
			transactionCount: 780,
			voluemPrice: 145.3149
		},
		{
			open: 145.319,
			close: 145.395,
			volume: 80979,
			time: 1654280640000,
			lowest: 145.27,
			highest: 145.41,
			volumePrice: 145.3457,
			transactionCount: 791,
			voluemPrice: 145.3457
		},
		{
			open: 145.4,
			close: 145.425,
			volume: 88821,
			time: 1654280700000,
			lowest: 145.33,
			highest: 145.46,
			volumePrice: 145.3962,
			transactionCount: 844,
			voluemPrice: 145.3962
		},
		{
			open: 145.43,
			close: 145.49,
			volume: 138208,
			time: 1654280760000,
			lowest: 145.37,
			highest: 145.514,
			volumePrice: 145.4467,
			transactionCount: 1016,
			voluemPrice: 145.4467
		},
		{
			open: 145.4854,
			close: 145.44,
			volume: 114641,
			time: 1654280820000,
			lowest: 145.42,
			highest: 145.4999,
			volumePrice: 145.4493,
			transactionCount: 1006,
			voluemPrice: 145.4493
		},
		{
			open: 145.4399,
			close: 145.5216,
			volume: 145935,
			time: 1654280880000,
			lowest: 145.41,
			highest: 145.53,
			volumePrice: 145.4712,
			transactionCount: 1151,
			voluemPrice: 145.4712
		},
		{
			open: 145.52,
			close: 145.6807,
			volume: 225285,
			time: 1654280940000,
			lowest: 145.47,
			highest: 145.7012,
			volumePrice: 145.6098,
			transactionCount: 1431,
			voluemPrice: 145.6098
		},
		{
			open: 145.68,
			close: 145.54,
			volume: 137417,
			time: 1654281000000,
			lowest: 145.53,
			highest: 145.69,
			volumePrice: 145.6311,
			transactionCount: 1157,
			voluemPrice: 145.6311
		},
		{
			open: 145.54,
			close: 145.7001,
			volume: 201529,
			time: 1654281060000,
			lowest: 145.53,
			highest: 145.72,
			volumePrice: 145.6559,
			transactionCount: 1653,
			voluemPrice: 145.6559
		},
		{
			open: 145.702,
			close: 145.5694,
			volume: 135208,
			time: 1654281120000,
			lowest: 145.54,
			highest: 145.72,
			volumePrice: 145.627,
			transactionCount: 1003,
			voluemPrice: 145.627
		},
		{
			open: 145.57,
			close: 145.5201,
			volume: 132772,
			time: 1654281180000,
			lowest: 145.41,
			highest: 145.57,
			volumePrice: 145.4884,
			transactionCount: 1163,
			voluemPrice: 145.4884
		},
		{
			open: 145.5243,
			close: 145.449,
			volume: 90567,
			time: 1654281240000,
			lowest: 145.41,
			highest: 145.5299,
			volumePrice: 145.4537,
			transactionCount: 798,
			voluemPrice: 145.4537
		},
		{
			open: 145.45,
			close: 145.315,
			volume: 172979,
			time: 1654281300000,
			lowest: 145.31,
			highest: 145.48,
			volumePrice: 145.3969,
			transactionCount: 1304,
			voluemPrice: 145.3969
		},
		{
			open: 145.31,
			close: 145.365,
			volume: 82706,
			time: 1654281360000,
			lowest: 145.3,
			highest: 145.41,
			volumePrice: 145.3602,
			transactionCount: 825,
			voluemPrice: 145.3602
		},
		{
			open: 145.365,
			close: 145.23,
			volume: 199529,
			time: 1654281420000,
			lowest: 145.18,
			highest: 145.37,
			volumePrice: 145.2563,
			transactionCount: 1737,
			voluemPrice: 145.2563
		},
		{
			open: 145.225,
			close: 145.17,
			volume: 108408,
			time: 1654281480000,
			lowest: 145.14,
			highest: 145.29,
			volumePrice: 145.2188,
			transactionCount: 983,
			voluemPrice: 145.2188
		},
		{
			open: 145.18,
			close: 145.245,
			volume: 109041,
			time: 1654281540000,
			lowest: 145.145,
			highest: 145.25,
			volumePrice: 145.196,
			transactionCount: 977,
			voluemPrice: 145.196
		},
		{
			open: 145.24,
			close: 145.205,
			volume: 119104,
			time: 1654281600000,
			lowest: 145.16,
			highest: 145.26,
			volumePrice: 145.2055,
			transactionCount: 1088,
			voluemPrice: 145.2055
		},
		{
			open: 145.21,
			close: 145.1717,
			volume: 54617,
			time: 1654281660000,
			lowest: 145.1717,
			highest: 145.235,
			volumePrice: 145.2051,
			transactionCount: 652,
			voluemPrice: 145.2051
		},
		{
			open: 145.1717,
			close: 145.225,
			volume: 88031,
			time: 1654281720000,
			lowest: 145.17,
			highest: 145.23,
			volumePrice: 145.1974,
			transactionCount: 815,
			voluemPrice: 145.1974
		},
		{
			open: 145.22,
			close: 145.2022,
			volume: 80037,
			time: 1654281780000,
			lowest: 145.2,
			highest: 145.26,
			volumePrice: 145.2266,
			transactionCount: 696,
			voluemPrice: 145.2266
		},
		{
			open: 145.2001,
			close: 145.3,
			volume: 139041,
			time: 1654281840000,
			lowest: 145.2001,
			highest: 145.3,
			volumePrice: 145.2497,
			transactionCount: 1160,
			voluemPrice: 145.2497
		},
		{
			open: 145.2975,
			close: 145.25,
			volume: 117980,
			time: 1654281900000,
			lowest: 145.21,
			highest: 145.35,
			volumePrice: 145.2733,
			transactionCount: 977,
			voluemPrice: 145.2733
		},
		{
			open: 145.24,
			close: 145.2485,
			volume: 182889,
			time: 1654281960000,
			lowest: 145.21,
			highest: 145.27,
			volumePrice: 145.2953,
			transactionCount: 538,
			voluemPrice: 145.2953
		},
		{
			open: 145.245,
			close: 145.3114,
			volume: 60965,
			time: 1654282020000,
			lowest: 145.23,
			highest: 145.315,
			volumePrice: 145.259,
			transactionCount: 747,
			voluemPrice: 145.259
		},
		{
			open: 145.3142,
			close: 145.4208,
			volume: 91420,
			time: 1654282080000,
			lowest: 145.304,
			highest: 145.43,
			volumePrice: 145.3616,
			transactionCount: 893,
			voluemPrice: 145.3616
		},
		{
			open: 145.427,
			close: 145.4259,
			volume: 94801,
			time: 1654282140000,
			lowest: 145.38,
			highest: 145.475,
			volumePrice: 145.4297,
			transactionCount: 909,
			voluemPrice: 145.4297
		},
		{
			open: 145.4251,
			close: 145.415,
			volume: 93280,
			time: 1654282200000,
			lowest: 145.335,
			highest: 145.43,
			volumePrice: 145.3763,
			transactionCount: 837,
			voluemPrice: 145.3763
		},
		{
			open: 145.4101,
			close: 145.4142,
			volume: 121851,
			time: 1654282260000,
			lowest: 145.34,
			highest: 145.45,
			volumePrice: 145.3884,
			transactionCount: 1003,
			voluemPrice: 145.3884
		},
		{
			open: 145.4007,
			close: 145.475,
			volume: 87344,
			time: 1654282320000,
			lowest: 145.385,
			highest: 145.5,
			volumePrice: 145.4278,
			transactionCount: 897,
			voluemPrice: 145.4278
		},
		{
			open: 145.466,
			close: 145.395,
			volume: 136401,
			time: 1654282380000,
			lowest: 145.39,
			highest: 145.52,
			volumePrice: 145.4587,
			transactionCount: 958,
			voluemPrice: 145.4587
		},
		{
			open: 145.39,
			close: 145.4754,
			volume: 200121,
			time: 1654282440000,
			lowest: 145.3259,
			highest: 145.52,
			volumePrice: 145.4164,
			transactionCount: 1595,
			voluemPrice: 145.4164
		},
		{
			open: 145.46,
			close: 145.5328,
			volume: 104936,
			time: 1654282500000,
			lowest: 145.4,
			highest: 145.545,
			volumePrice: 145.4642,
			transactionCount: 1008,
			voluemPrice: 145.4642
		},
		{
			open: 145.5315,
			close: 145.625,
			volume: 132679,
			time: 1654282560000,
			lowest: 145.53,
			highest: 145.6401,
			volumePrice: 145.5905,
			transactionCount: 1127,
			voluemPrice: 145.5905
		},
		{
			open: 145.625,
			close: 145.445,
			volume: 113845,
			time: 1654282620000,
			lowest: 145.445,
			highest: 145.64,
			volumePrice: 145.5335,
			transactionCount: 1132,
			voluemPrice: 145.5335
		},
		{
			open: 145.45,
			close: 145.5,
			volume: 148902,
			time: 1654282680000,
			lowest: 145.43,
			highest: 145.61,
			volumePrice: 145.5151,
			transactionCount: 1279,
			voluemPrice: 145.5151
		},
		{
			open: 145.51,
			close: 145.48,
			volume: 80265,
			time: 1654282740000,
			lowest: 145.45,
			highest: 145.55,
			volumePrice: 145.4856,
			transactionCount: 840,
			voluemPrice: 145.4856
		},
		{
			open: 145.48,
			close: 145.63,
			volume: 333556,
			time: 1654282800000,
			lowest: 145.475,
			highest: 145.74,
			volumePrice: 145.6419,
			transactionCount: 2183,
			voluemPrice: 145.6419
		},
		{
			open: 145.63,
			close: 145.4214,
			volume: 189080,
			time: 1654282860000,
			lowest: 145.4,
			highest: 145.72,
			volumePrice: 145.5759,
			transactionCount: 1425,
			voluemPrice: 145.5759
		},
		{
			open: 145.44,
			close: 145.43,
			volume: 105292,
			time: 1654282920000,
			lowest: 145.35,
			highest: 145.45,
			volumePrice: 145.4035,
			transactionCount: 912,
			voluemPrice: 145.4035
		},
		{
			open: 145.4301,
			close: 145.605,
			volume: 133049,
			time: 1654282980000,
			lowest: 145.4125,
			highest: 145.61,
			volumePrice: 145.515,
			transactionCount: 1157,
			voluemPrice: 145.515
		},
		{
			open: 145.605,
			close: 145.5884,
			volume: 85575,
			time: 1654283040000,
			lowest: 145.57,
			highest: 145.66,
			volumePrice: 145.6149,
			transactionCount: 887,
			voluemPrice: 145.6149
		},
		{
			open: 145.585,
			close: 145.5422,
			volume: 91495,
			time: 1654283100000,
			lowest: 145.52,
			highest: 145.595,
			volumePrice: 145.5562,
			transactionCount: 841,
			voluemPrice: 145.5562
		},
		{
			open: 145.55,
			close: 145.5901,
			volume: 148219,
			time: 1654283160000,
			lowest: 145.53,
			highest: 145.6,
			volumePrice: 145.5765,
			transactionCount: 1078,
			voluemPrice: 145.5765
		},
		{
			open: 145.59,
			close: 145.26,
			volume: 187379,
			time: 1654283220000,
			lowest: 145.25,
			highest: 145.59,
			volumePrice: 145.4051,
			transactionCount: 1549,
			voluemPrice: 145.4051
		},
		{
			open: 145.26,
			close: 145.345,
			volume: 217561,
			time: 1654283280000,
			lowest: 145.21,
			highest: 145.395,
			volumePrice: 145.281,
			transactionCount: 1788,
			voluemPrice: 145.281
		},
		{
			open: 145.345,
			close: 145.31,
			volume: 155309,
			time: 1654283340000,
			lowest: 145.28,
			highest: 145.455,
			volumePrice: 145.3586,
			transactionCount: 1488,
			voluemPrice: 145.3586
		},
		{
			open: 145.31,
			close: 145.375,
			volume: 91667,
			time: 1654283400000,
			lowest: 145.275,
			highest: 145.45,
			volumePrice: 145.3686,
			transactionCount: 1004,
			voluemPrice: 145.3686
		},
		{
			open: 145.37,
			close: 145.2,
			volume: 158102,
			time: 1654283460000,
			lowest: 145.18,
			highest: 145.38,
			volumePrice: 145.2751,
			transactionCount: 1423,
			voluemPrice: 145.2751
		},
		{
			open: 145.2,
			close: 145.16,
			volume: 168415,
			time: 1654283520000,
			lowest: 145.12,
			highest: 145.24,
			volumePrice: 145.1756,
			transactionCount: 1512,
			voluemPrice: 145.1756
		},
		{
			open: 145.16,
			close: 145.17,
			volume: 162939,
			time: 1654283580000,
			lowest: 145.11,
			highest: 145.18,
			volumePrice: 145.1441,
			transactionCount: 1547,
			voluemPrice: 145.1441
		},
		{
			open: 145.18,
			close: 145.01,
			volume: 214819,
			time: 1654283640000,
			lowest: 145.01,
			highest: 145.18,
			volumePrice: 145.087,
			transactionCount: 1792,
			voluemPrice: 145.087
		},
		{
			open: 145.01,
			close: 145.01,
			volume: 294324,
			time: 1654283700000,
			lowest: 144.91,
			highest: 145.04,
			volumePrice: 144.9876,
			transactionCount: 3084,
			voluemPrice: 144.9876
		},
		{
			open: 145.0099,
			close: 144.995,
			volume: 116952,
			time: 1654283760000,
			lowest: 144.97,
			highest: 145.07,
			volumePrice: 145.0277,
			transactionCount: 1212,
			voluemPrice: 145.0277
		},
		{
			open: 144.9927,
			close: 144.945,
			volume: 147623,
			time: 1654283820000,
			lowest: 144.94,
			highest: 145,
			volumePrice: 144.9696,
			transactionCount: 1392,
			voluemPrice: 144.9696
		},
		{
			open: 144.945,
			close: 145.08,
			volume: 292896,
			time: 1654283880000,
			lowest: 144.81,
			highest: 145.09,
			volumePrice: 144.9383,
			transactionCount: 2522,
			voluemPrice: 144.9383
		},
		{
			open: 145.085,
			close: 145.12,
			volume: 163425,
			time: 1654283940000,
			lowest: 144.965,
			highest: 145.12,
			volumePrice: 145.0396,
			transactionCount: 1391,
			voluemPrice: 145.0396
		},
		{
			open: 145.12,
			close: 145.1812,
			volume: 136246,
			time: 1654284000000,
			lowest: 145.09,
			highest: 145.19,
			volumePrice: 145.142,
			transactionCount: 1258,
			voluemPrice: 145.142
		},
		{
			open: 145.19,
			close: 145.13,
			volume: 127972,
			time: 1654284060000,
			lowest: 145.09,
			highest: 145.2,
			volumePrice: 145.137,
			transactionCount: 1153,
			voluemPrice: 145.137
		},
		{
			open: 145.1391,
			close: 145.03,
			volume: 95040,
			time: 1654284120000,
			lowest: 145.03,
			highest: 145.15,
			volumePrice: 145.0886,
			transactionCount: 1080,
			voluemPrice: 145.0886
		},
		{
			open: 145.04,
			close: 145.015,
			volume: 149974,
			time: 1654284180000,
			lowest: 144.97,
			highest: 145.08,
			volumePrice: 145.0215,
			transactionCount: 1526,
			voluemPrice: 145.0215
		},
		{
			open: 145.01,
			close: 145.065,
			volume: 184192,
			time: 1654284240000,
			lowest: 144.97,
			highest: 145.0799,
			volumePrice: 145.0361,
			transactionCount: 1449,
			voluemPrice: 145.0361
		},
		{
			open: 145.0641,
			close: 145.175,
			volume: 177707,
			time: 1654284300000,
			lowest: 145.02,
			highest: 145.23,
			volumePrice: 145.1288,
			transactionCount: 1702,
			voluemPrice: 145.1288
		},
		{
			open: 145.17,
			close: 145.29,
			volume: 152625,
			time: 1654284360000,
			lowest: 145.11,
			highest: 145.29,
			volumePrice: 145.1909,
			transactionCount: 1391,
			voluemPrice: 145.1909
		},
		{
			open: 145.2839,
			close: 145.22,
			volume: 193352,
			time: 1654284420000,
			lowest: 145.21,
			highest: 145.41,
			volumePrice: 145.3135,
			transactionCount: 1668,
			voluemPrice: 145.3135
		},
		{
			open: 145.205,
			close: 145.245,
			volume: 137515,
			time: 1654284480000,
			lowest: 145.15,
			highest: 145.27,
			volumePrice: 145.226,
			transactionCount: 1359,
			voluemPrice: 145.226
		},
		{
			open: 145.25,
			close: 145.4331,
			volume: 162983,
			time: 1654284540000,
			lowest: 145.22,
			highest: 145.45,
			volumePrice: 145.3371,
			transactionCount: 1439,
			voluemPrice: 145.3371
		},
		{
			open: 145.44,
			close: 145.34,
			volume: 228633,
			time: 1654284600000,
			lowest: 145.27,
			highest: 145.445,
			volumePrice: 145.3451,
			transactionCount: 1762,
			voluemPrice: 145.3451
		},
		{
			open: 145.34,
			close: 145.395,
			volume: 234052,
			time: 1654284660000,
			lowest: 145.31,
			highest: 145.5,
			volumePrice: 145.3959,
			transactionCount: 1791,
			voluemPrice: 145.3959
		},
		{
			open: 145.38,
			close: 145.3876,
			volume: 148828,
			time: 1654284720000,
			lowest: 145.32,
			highest: 145.44,
			volumePrice: 145.3724,
			transactionCount: 1433,
			voluemPrice: 145.3724
		},
		{
			open: 145.39,
			close: 145.5099,
			volume: 221654,
			time: 1654284780000,
			lowest: 145.385,
			highest: 145.58,
			volumePrice: 145.4754,
			transactionCount: 2050,
			voluemPrice: 145.4754
		},
		{
			open: 145.49,
			close: 145.435,
			volume: 135389,
			time: 1654284840000,
			lowest: 145.39,
			highest: 145.51,
			volumePrice: 145.4378,
			transactionCount: 1389,
			voluemPrice: 145.4378
		},
		{
			open: 145.43,
			close: 145.4235,
			volume: 212212,
			time: 1654284900000,
			lowest: 145.2701,
			highest: 145.4582,
			volumePrice: 145.3552,
			transactionCount: 2124,
			voluemPrice: 145.3552
		},
		{
			open: 145.42,
			close: 145.33,
			volume: 206077,
			time: 1654284960000,
			lowest: 145.32,
			highest: 145.48,
			volumePrice: 145.4117,
			transactionCount: 1644,
			voluemPrice: 145.4117
		},
		{
			open: 145.33,
			close: 145.6379,
			volume: 345503,
			time: 1654285020000,
			lowest: 145.28,
			highest: 145.7,
			volumePrice: 145.5248,
			transactionCount: 3462,
			voluemPrice: 145.5248
		},
		{
			open: 145.63,
			close: 145.59,
			volume: 225191,
			time: 1654285080000,
			lowest: 145.505,
			highest: 145.67,
			volumePrice: 145.5961,
			transactionCount: 1947,
			voluemPrice: 145.5961
		},
		{
			open: 145.58,
			close: 145.36,
			volume: 283588,
			time: 1654285140000,
			lowest: 145.3001,
			highest: 145.62,
			volumePrice: 145.4537,
			transactionCount: 2736,
			voluemPrice: 145.4537
		},
		{
			open: 145.36,
			close: 145.515,
			volume: 206020,
			time: 1654285200000,
			lowest: 145.34,
			highest: 145.56,
			volumePrice: 145.4527,
			transactionCount: 1849,
			voluemPrice: 145.4527
		},
		{
			open: 145.51,
			close: 145.4,
			volume: 186904,
			time: 1654285260000,
			lowest: 145.39,
			highest: 145.55,
			volumePrice: 145.4777,
			transactionCount: 1801,
			voluemPrice: 145.4777
		},
		{
			open: 145.3942,
			close: 145.43,
			volume: 157144,
			time: 1654285320000,
			lowest: 145.355,
			highest: 145.515,
			volumePrice: 145.4503,
			transactionCount: 1645,
			voluemPrice: 145.4503
		},
		{
			open: 145.4301,
			close: 145.225,
			volume: 220819,
			time: 1654285380000,
			lowest: 145.222,
			highest: 145.435,
			volumePrice: 145.3199,
			transactionCount: 1956,
			voluemPrice: 145.3199
		},
		{
			open: 145.22,
			close: 145.1866,
			volume: 277237,
			time: 1654285440000,
			lowest: 145.12,
			highest: 145.23,
			volumePrice: 145.1684,
			transactionCount: 2341,
			voluemPrice: 145.1684
		},
		{
			open: 145.185,
			close: 145.1197,
			volume: 205528,
			time: 1654285500000,
			lowest: 145.09,
			highest: 145.24,
			volumePrice: 145.1452,
			transactionCount: 1937,
			voluemPrice: 145.1452
		},
		{
			open: 145.11,
			close: 145.24,
			volume: 331760,
			time: 1654285560000,
			lowest: 145.02,
			highest: 145.2592,
			volumePrice: 145.085,
			transactionCount: 2424,
			voluemPrice: 145.085
		},
		{
			open: 145.23,
			close: 145.5,
			volume: 435241,
			time: 1654285620000,
			lowest: 145.195,
			highest: 145.54,
			volumePrice: 145.3697,
			transactionCount: 3131,
			voluemPrice: 145.3697
		},
		{
			open: 145.5,
			close: 145.46,
			volume: 455092,
			time: 1654285680000,
			lowest: 145.41,
			highest: 145.55,
			volumePrice: 145.4802,
			transactionCount: 3294,
			voluemPrice: 145.4802
		},
		{
			open: 145.46,
			close: 145.365,
			volume: 271583,
			time: 1654285740000,
			lowest: 145.31,
			highest: 145.46,
			volumePrice: 145.3688,
			transactionCount: 2275,
			voluemPrice: 145.3688
		},
		{
			open: 145.36,
			close: 145.36,
			volume: 383712,
			time: 1654285800000,
			lowest: 145.07,
			highest: 145.37,
			volumePrice: 145.2253,
			transactionCount: 3973,
			voluemPrice: 145.2253
		},
		{
			open: 145.36,
			close: 145.26,
			volume: 302163,
			time: 1654285860000,
			lowest: 145.215,
			highest: 145.43,
			volumePrice: 145.3375,
			transactionCount: 3006,
			voluemPrice: 145.3375
		},
		{
			open: 145.265,
			close: 145.3457,
			volume: 292916,
			time: 1654285920000,
			lowest: 145.21,
			highest: 145.405,
			volumePrice: 145.2968,
			transactionCount: 2475,
			voluemPrice: 145.2968
		},
		{
			open: 145.34,
			close: 145.3,
			volume: 345447,
			time: 1654285980000,
			lowest: 145.29,
			highest: 145.46,
			volumePrice: 145.3784,
			transactionCount: 3552,
			voluemPrice: 145.3784
		},
		{
			open: 145.298,
			close: 145.44,
			volume: 426249,
			time: 1654286040000,
			lowest: 145.05,
			highest: 145.47,
			volumePrice: 145.229,
			transactionCount: 4474,
			voluemPrice: 145.229
		},
		{
			open: 145.44,
			close: 145.37,
			volume: 360579,
			time: 1654286100000,
			lowest: 145.36,
			highest: 145.49,
			volumePrice: 145.4294,
			transactionCount: 3860,
			voluemPrice: 145.4294
		},
		{
			open: 145.37,
			close: 145.44,
			volume: 321849,
			time: 1654286160000,
			lowest: 145.25,
			highest: 145.45,
			volumePrice: 145.3451,
			transactionCount: 2718,
			voluemPrice: 145.3451
		},
		{
			open: 145.44,
			close: 145.41,
			volume: 392624,
			time: 1654286220000,
			lowest: 145.4,
			highest: 145.56,
			volumePrice: 145.473,
			transactionCount: 3429,
			voluemPrice: 145.473
		},
		{
			open: 145.4,
			close: 145.375,
			volume: 469454,
			time: 1654286280000,
			lowest: 145.33,
			highest: 145.51,
			volumePrice: 145.4211,
			transactionCount: 4108,
			voluemPrice: 145.4211
		},
		{
			open: 145.375,
			close: 145.39,
			volume: 1173297,
			time: 1654286340000,
			lowest: 145.28,
			highest: 145.53,
			volumePrice: 145.3733,
			transactionCount: 7212,
			voluemPrice: 145.3733
		},
		{
			open: 145.38,
			close: 145.38,
			volume: 386951,
			time: 1654286400000,
			lowest: 145.25,
			highest: 145.38,
			volumePrice: 145.3793,
			transactionCount: 110,
			voluemPrice: 145.3793
		},
		{
			open: 145.38,
			close: 145.29,
			volume: 685329,
			time: 1654286460000,
			lowest: 145.29,
			highest: 145.38,
			volumePrice: 145.3791,
			transactionCount: 128,
			voluemPrice: 145.3791
		},
		{
			open: 145.29,
			close: 145.3799,
			volume: 10850,
			time: 1654286520000,
			lowest: 145.24,
			highest: 145.38,
			volumePrice: 145.3165,
			transactionCount: 128,
			voluemPrice: 145.3165
		},
		{
			open: 145.37,
			close: 145.37,
			volume: 5077,
			time: 1654286580000,
			lowest: 145.37,
			highest: 145.38,
			volumePrice: 145.7615,
			transactionCount: 626,
			voluemPrice: 145.7615
		},
		{
			open: 145.38,
			close: 145.4,
			volume: 7958,
			time: 1654286640000,
			lowest: 145.38,
			highest: 145.4619,
			volumePrice: 145.4167,
			transactionCount: 83,
			voluemPrice: 145.4167
		},
		{
			open: 145.4,
			close: 145.4008,
			volume: 3372,
			time: 1654286700000,
			lowest: 145.4,
			highest: 145.43,
			volumePrice: 145.4067,
			transactionCount: 67,
			voluemPrice: 145.4067
		},
		{
			open: 145.4,
			close: 145.4,
			volume: 2895,
			time: 1654286760000,
			lowest: 145.4,
			highest: 145.41,
			volumePrice: 145.4022,
			transactionCount: 66,
			voluemPrice: 145.4022
		},
		{
			open: 145.4,
			close: 145.42,
			volume: 1857,
			time: 1654286820000,
			lowest: 145.4,
			highest: 145.42,
			volumePrice: 145.3992,
			transactionCount: 64,
			voluemPrice: 145.3992
		},
		{
			open: 145.38,
			close: 145.43,
			volume: 240561,
			time: 1654286880000,
			lowest: 145.38,
			highest: 145.43,
			volumePrice: 145.3803,
			transactionCount: 57,
			voluemPrice: 145.3803
		},
		{
			open: 145.4,
			close: 145.38,
			volume: 15177,
			time: 1654286940000,
			lowest: 145.38,
			highest: 145.4,
			volumePrice: 145.3828,
			transactionCount: 65,
			voluemPrice: 145.3828
		},
		{
			open: 145.4,
			close: 145.43,
			volume: 5510,
			time: 1654287000000,
			lowest: 145.4,
			highest: 145.45,
			volumePrice: 145.4107,
			transactionCount: 52,
			voluemPrice: 145.4107
		},
		{
			open: 145.4,
			close: 145.43,
			volume: 751,
			time: 1654287060000,
			lowest: 145.4,
			highest: 145.43,
			volumePrice: 145.4114,
			transactionCount: 37,
			voluemPrice: 145.4114
		},
		{
			open: 145.38,
			close: 145.4,
			volume: 78278,
			time: 1654287120000,
			lowest: 145.38,
			highest: 145.42,
			volumePrice: 145.3805,
			transactionCount: 47,
			voluemPrice: 145.3805
		},
		{
			open: 145.3801,
			close: 145.43,
			volume: 6235,
			time: 1654287180000,
			lowest: 145.3801,
			highest: 145.43,
			volumePrice: 145.4106,
			transactionCount: 35,
			voluemPrice: 145.4106
		},
		{
			open: 145.38,
			close: 145.42,
			volume: 4266,
			time: 1654287240000,
			lowest: 145.38,
			highest: 145.42,
			volumePrice: 145.3878,
			transactionCount: 28,
			voluemPrice: 145.3878
		},
		{
			open: 145.4,
			close: 145.43,
			volume: 2107,
			time: 1654287300000,
			lowest: 145.4,
			highest: 145.43,
			volumePrice: 145.4168,
			transactionCount: 68,
			voluemPrice: 145.4168
		},
		{
			open: 145.4299,
			close: 145.4,
			volume: 1601,
			time: 1654287360000,
			lowest: 145.4,
			highest: 145.43,
			volumePrice: 145.4066,
			transactionCount: 40,
			voluemPrice: 145.4066
		},
		{
			open: 145.43,
			close: 145.4,
			volume: 2696,
			time: 1654287420000,
			lowest: 145.4,
			highest: 145.43,
			volumePrice: 145.4131,
			transactionCount: 54,
			voluemPrice: 145.4131
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 2588,
			time: 1654287480000,
			lowest: 145.4,
			highest: 145.46,
			volumePrice: 145.4381,
			transactionCount: 37,
			voluemPrice: 145.4381
		},
		{
			open: 145.42,
			close: 145.41,
			volume: 401,
			time: 1654287540000,
			lowest: 145.41,
			highest: 145.42,
			volumePrice: 145.4181,
			transactionCount: 20,
			voluemPrice: 145.4181
		},
		{
			open: 145.44,
			close: 145.45,
			volume: 989,
			time: 1654287600000,
			lowest: 145.44,
			highest: 145.45,
			volumePrice: 145.4486,
			transactionCount: 36,
			voluemPrice: 145.4486
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 638,
			time: 1654287660000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.4536,
			transactionCount: 32,
			voluemPrice: 145.4536
		},
		{
			open: 145.47,
			close: 145.5,
			volume: 3640,
			time: 1654287720000,
			lowest: 145.47,
			highest: 145.5,
			volumePrice: 145.4941,
			transactionCount: 51,
			voluemPrice: 145.4941
		},
		{
			open: 145.5,
			close: 145.49,
			volume: 1798,
			time: 1654287780000,
			lowest: 145.47,
			highest: 145.5026,
			volumePrice: 145.4909,
			transactionCount: 41,
			voluemPrice: 145.4909
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 994,
			time: 1654287840000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.4797,
			transactionCount: 34,
			voluemPrice: 145.4797
		},
		{
			open: 145.49,
			close: 145.5,
			volume: 989,
			time: 1654287900000,
			lowest: 145.49,
			highest: 145.5,
			volumePrice: 145.4972,
			transactionCount: 47,
			voluemPrice: 145.4972
		},
		{
			open: 145.51,
			close: 145.51,
			volume: 2598,
			time: 1654287960000,
			lowest: 145.5099,
			highest: 145.525,
			volumePrice: 145.5166,
			transactionCount: 36,
			voluemPrice: 145.5166
		},
		{
			open: 145.5199,
			close: 145.5199,
			volume: 431,
			time: 1654288020000,
			lowest: 145.5199,
			highest: 145.5199,
			volumePrice: 145.5183,
			transactionCount: 37,
			voluemPrice: 145.5183
		},
		{
			open: 145.52,
			close: 145.54,
			volume: 1666,
			time: 1654288080000,
			lowest: 145.5,
			highest: 145.54,
			volumePrice: 145.5305,
			transactionCount: 34,
			voluemPrice: 145.5305
		},
		{
			open: 145.53,
			close: 145.54,
			volume: 1521,
			time: 1654288140000,
			lowest: 145.53,
			highest: 145.54,
			volumePrice: 145.5348,
			transactionCount: 43,
			voluemPrice: 145.5348
		},
		{
			open: 145.54,
			close: 145.52,
			volume: 2587,
			time: 1654288200000,
			lowest: 145.5,
			highest: 145.55,
			volumePrice: 145.5168,
			transactionCount: 60,
			voluemPrice: 145.5168
		},
		{
			open: 145.53,
			close: 145.54,
			volume: 645,
			time: 1654288260000,
			lowest: 145.53,
			highest: 145.54,
			volumePrice: 145.5336,
			transactionCount: 28,
			voluemPrice: 145.5336
		},
		{
			open: 145.52,
			close: 145.55,
			volume: 949619,
			time: 1654288320000,
			lowest: 145.38,
			highest: 145.55,
			volumePrice: 145.3802,
			transactionCount: 52,
			voluemPrice: 145.3802
		},
		{
			open: 145.5201,
			close: 145.5201,
			volume: 395,
			time: 1654288380000,
			lowest: 145.5201,
			highest: 145.5201,
			volumePrice: 145.5444,
			transactionCount: 23,
			voluemPrice: 145.5444
		},
		{
			open: 145.54,
			close: 145.55,
			volume: 47534,
			time: 1654288440000,
			lowest: 145.38,
			highest: 145.57,
			volumePrice: 145.3866,
			transactionCount: 55,
			voluemPrice: 145.3866
		},
		{
			open: 145.56,
			close: 145.55,
			volume: 1818,
			time: 1654288500000,
			lowest: 145.55,
			highest: 145.57,
			volumePrice: 145.5549,
			transactionCount: 38,
			voluemPrice: 145.5549
		},
		{
			open: 145.52,
			close: 145.51,
			volume: 957,
			time: 1654288560000,
			lowest: 145.51,
			highest: 145.52,
			volumePrice: 145.5178,
			transactionCount: 34,
			voluemPrice: 145.5178
		},
		{
			open: 145.52,
			close: 145.5,
			volume: 460,
			time: 1654288620000,
			lowest: 145.5,
			highest: 145.52,
			volumePrice: 145.5162,
			transactionCount: 35,
			voluemPrice: 145.5162
		},
		{
			open: 145.5,
			close: 145.47,
			volume: 1372,
			time: 1654288680000,
			lowest: 145.47,
			highest: 145.5,
			volumePrice: 145.4927,
			transactionCount: 67,
			voluemPrice: 145.4927
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 987,
			time: 1654288740000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.4982,
			transactionCount: 20,
			voluemPrice: 145.4982
		},
		{
			open: 145.4801,
			close: 145.5,
			volume: 2519,
			time: 1654288860000,
			lowest: 145.4801,
			highest: 145.5,
			volumePrice: 145.4864,
			transactionCount: 35,
			voluemPrice: 145.4864
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 2964,
			time: 1654288920000,
			lowest: 145.48,
			highest: 145.5,
			volumePrice: 145.4932,
			transactionCount: 46,
			voluemPrice: 145.4932
		},
		{
			open: 145.5,
			close: 145.47,
			volume: 5657,
			time: 1654288980000,
			lowest: 145.47,
			highest: 145.5,
			volumePrice: 145.4901,
			transactionCount: 40,
			voluemPrice: 145.4901
		},
		{
			open: 145.48,
			close: 145.48,
			volume: 939,
			time: 1654289040000,
			lowest: 145.48,
			highest: 145.48,
			volumePrice: 145.4967,
			transactionCount: 39,
			voluemPrice: 145.4967
		},
		{
			open: 145.48,
			close: 145.46,
			volume: 1825,
			time: 1654289100000,
			lowest: 145.46,
			highest: 145.48,
			volumePrice: 145.4729,
			transactionCount: 54,
			voluemPrice: 145.4729
		},
		{
			open: 145.46,
			close: 145.46,
			volume: 1620,
			time: 1654289160000,
			lowest: 145.46,
			highest: 145.46,
			volumePrice: 145.4612,
			transactionCount: 29,
			voluemPrice: 145.4612
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 884,
			time: 1654289220000,
			lowest: 145.45,
			highest: 145.4501,
			volumePrice: 145.4527,
			transactionCount: 23,
			voluemPrice: 145.4527
		},
		{
			open: 145.41,
			close: 145.39,
			volume: 7742,
			time: 1654289280000,
			lowest: 145.39,
			highest: 145.42,
			volumePrice: 145.3996,
			transactionCount: 86,
			voluemPrice: 145.3996
		},
		{
			open: 145.39,
			close: 145.38,
			volume: 3273,
			time: 1654289340000,
			lowest: 145.38,
			highest: 145.4,
			volumePrice: 145.3845,
			transactionCount: 123,
			voluemPrice: 145.3845
		},
		{
			open: 145.38,
			close: 145.39,
			volume: 2341,
			time: 1654289400000,
			lowest: 145.38,
			highest: 145.39,
			volumePrice: 145.3827,
			transactionCount: 43,
			voluemPrice: 145.3827
		},
		{
			open: 145.39,
			close: 145.4,
			volume: 3027,
			time: 1654289460000,
			lowest: 145.39,
			highest: 145.41,
			volumePrice: 145.4006,
			transactionCount: 29,
			voluemPrice: 145.4006
		},
		{
			open: 145.4,
			close: 145.42,
			volume: 104537,
			time: 1654289520000,
			lowest: 145.38,
			highest: 145.44,
			volumePrice: 145.3803,
			transactionCount: 30,
			voluemPrice: 145.3803
		},
		{
			open: 145.41,
			close: 145.41,
			volume: 4857,
			time: 1654289580000,
			lowest: 145.41,
			highest: 145.42,
			volumePrice: 145.4118,
			transactionCount: 21,
			voluemPrice: 145.4118
		},
		{
			open: 145.4299,
			close: 145.4299,
			volume: 322,
			time: 1654289640000,
			lowest: 145.4299,
			highest: 145.4299,
			volumePrice: 145.4269,
			transactionCount: 27,
			voluemPrice: 145.4269
		},
		{
			open: 145.43,
			close: 145.43,
			volume: 1146,
			time: 1654289700000,
			lowest: 145.4299,
			highest: 145.43,
			volumePrice: 145.4281,
			transactionCount: 31,
			voluemPrice: 145.4281
		},
		{
			open: 145.43,
			close: 145.42,
			volume: 943,
			time: 1654289760000,
			lowest: 145.42,
			highest: 145.43,
			volumePrice: 145.4221,
			transactionCount: 19,
			voluemPrice: 145.4221
		},
		{
			open: 145.41,
			close: 145.41,
			volume: 633,
			time: 1654289820000,
			lowest: 145.41,
			highest: 145.41,
			volumePrice: 145.41,
			transactionCount: 33,
			voluemPrice: 145.41
		},
		{
			open: 145.4,
			close: 145.4,
			volume: 2541,
			time: 1654289880000,
			lowest: 145.4,
			highest: 145.44,
			volumePrice: 145.4027,
			transactionCount: 35,
			voluemPrice: 145.4027
		},
		{
			open: 145.3901,
			close: 145.39,
			volume: 3389,
			time: 1654290000000,
			lowest: 145.39,
			highest: 145.3901,
			volumePrice: 145.3907,
			transactionCount: 30,
			voluemPrice: 145.3907
		},
		{
			open: 145.439,
			close: 145.44,
			volume: 3403,
			time: 1654290060000,
			lowest: 145.4,
			highest: 145.44,
			volumePrice: 145.4224,
			transactionCount: 40,
			voluemPrice: 145.4224
		},
		{
			open: 145.4,
			close: 145.4003,
			volume: 1565,
			time: 1654290120000,
			lowest: 145.4,
			highest: 145.4796,
			volumePrice: 145.4144,
			transactionCount: 41,
			voluemPrice: 145.4144
		},
		{
			open: 145.4689,
			close: 145.4689,
			volume: 792,
			time: 1654290180000,
			lowest: 145.4689,
			highest: 145.4689,
			volumePrice: 145.4164,
			transactionCount: 21,
			voluemPrice: 145.4164
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 166,
			time: 1654290300000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.4454,
			transactionCount: 14,
			voluemPrice: 145.4454
		},
		{
			open: 145.4484,
			close: 145.45,
			volume: 2104,
			time: 1654290360000,
			lowest: 145.4484,
			highest: 145.45,
			volumePrice: 145.4484,
			transactionCount: 23,
			voluemPrice: 145.4484
		},
		{
			open: 145.46,
			close: 145.44,
			volume: 1106,
			time: 1654290420000,
			lowest: 145.44,
			highest: 145.46,
			volumePrice: 145.4451,
			transactionCount: 32,
			voluemPrice: 145.4451
		},
		{
			open: 145.46,
			close: 145.44,
			volume: 1570,
			time: 1654290480000,
			lowest: 145.44,
			highest: 145.46,
			volumePrice: 145.4431,
			transactionCount: 30,
			voluemPrice: 145.4431
		},
		{
			open: 145.43,
			close: 145.41,
			volume: 1054,
			time: 1654290540000,
			lowest: 145.41,
			highest: 145.43,
			volumePrice: 145.4235,
			transactionCount: 34,
			voluemPrice: 145.4235
		},
		{
			open: 145.4001,
			close: 145.4001,
			volume: 468,
			time: 1654290600000,
			lowest: 145.4001,
			highest: 145.4001,
			volumePrice: 145.4008,
			transactionCount: 44,
			voluemPrice: 145.4008
		},
		{
			open: 145.4,
			close: 145.351,
			volume: 1651,
			time: 1654290660000,
			lowest: 145.3507,
			highest: 145.4,
			volumePrice: 145.3602,
			transactionCount: 70,
			voluemPrice: 145.3602
		},
		{
			open: 145.38,
			close: 145.52,
			volume: 6061,
			time: 1654290720000,
			lowest: 145.38,
			highest: 145.52,
			volumePrice: 145.4383,
			transactionCount: 54,
			voluemPrice: 145.4383
		},
		{
			open: 145.5079,
			close: 145.53,
			volume: 2858,
			time: 1654290780000,
			lowest: 145.5,
			highest: 145.55,
			volumePrice: 145.5265,
			transactionCount: 73,
			voluemPrice: 145.5265
		},
		{
			open: 145.57,
			close: 145.61,
			volume: 8955,
			time: 1654290840000,
			lowest: 145.5501,
			highest: 145.61,
			volumePrice: 145.6192,
			transactionCount: 90,
			voluemPrice: 145.6192
		},
		{
			open: 145.6,
			close: 145.63,
			volume: 3411,
			time: 1654290900000,
			lowest: 145.59,
			highest: 145.63,
			volumePrice: 145.6177,
			transactionCount: 64,
			voluemPrice: 145.6177
		},
		{
			open: 145.63,
			close: 145.6,
			volume: 4415,
			time: 1654290960000,
			lowest: 145.6,
			highest: 145.64,
			volumePrice: 145.6248,
			transactionCount: 77,
			voluemPrice: 145.6248
		},
		{
			open: 145.6,
			close: 145.57,
			volume: 1816,
			time: 1654291020000,
			lowest: 145.57,
			highest: 145.63,
			volumePrice: 145.7683,
			transactionCount: 36,
			voluemPrice: 145.7683
		},
		{
			open: 145.5513,
			close: 145.5999,
			volume: 1893,
			time: 1654291080000,
			lowest: 145.55,
			highest: 145.62,
			volumePrice: 145.569,
			transactionCount: 46,
			voluemPrice: 145.569
		},
		{
			open: 145.5511,
			close: 145.6,
			volume: 2402,
			time: 1654291140000,
			lowest: 145.5,
			highest: 145.6,
			volumePrice: 145.5302,
			transactionCount: 51,
			voluemPrice: 145.5302
		},
		{
			open: 145.5982,
			close: 145.53,
			volume: 933,
			time: 1654291200000,
			lowest: 145.53,
			highest: 145.5982,
			volumePrice: 145.5618,
			transactionCount: 28,
			voluemPrice: 145.5618
		},
		{
			open: 145.5897,
			close: 145.5897,
			volume: 208,
			time: 1654291260000,
			lowest: 145.5897,
			highest: 145.5897,
			volumePrice: 145.5834,
			transactionCount: 21,
			voluemPrice: 145.5834
		},
		{
			open: 145.53,
			close: 145.58,
			volume: 943,
			time: 1654291320000,
			lowest: 145.53,
			highest: 145.5899,
			volumePrice: 145.564,
			transactionCount: 25,
			voluemPrice: 145.564
		},
		{
			open: 145.55,
			close: 145.5499,
			volume: 1041,
			time: 1654291440000,
			lowest: 145.5,
			highest: 145.55,
			volumePrice: 145.5264,
			transactionCount: 17,
			voluemPrice: 145.5264
		},
		{
			open: 145.46,
			close: 145.46,
			volume: 226,
			time: 1654291500000,
			lowest: 145.46,
			highest: 145.46,
			volumePrice: 145.4881,
			transactionCount: 18,
			voluemPrice: 145.4881
		},
		{
			open: 145.53,
			close: 145.53,
			volume: 635,
			time: 1654291560000,
			lowest: 145.529,
			highest: 145.53,
			volumePrice: 145.5281,
			transactionCount: 32,
			voluemPrice: 145.5281
		},
		{
			open: 145.54,
			close: 145.55,
			volume: 622,
			time: 1654291620000,
			lowest: 145.54,
			highest: 145.55,
			volumePrice: 145.5459,
			transactionCount: 21,
			voluemPrice: 145.5459
		},
		{
			open: 145.56,
			close: 145.56,
			volume: 1481,
			time: 1654291680000,
			lowest: 145.56,
			highest: 145.56,
			volumePrice: 145.5602,
			transactionCount: 25,
			voluemPrice: 145.5602
		},
		{
			open: 145.51,
			close: 145.4608,
			volume: 1157,
			time: 1654291740000,
			lowest: 145.4608,
			highest: 145.51,
			volumePrice: 145.5066,
			transactionCount: 45,
			voluemPrice: 145.5066
		},
		{
			open: 145.46,
			close: 145.46,
			volume: 593,
			time: 1654291800000,
			lowest: 145.46,
			highest: 145.46,
			volumePrice: 145.4845,
			transactionCount: 21,
			voluemPrice: 145.4845
		},
		{
			open: 145.55,
			close: 145.54,
			volume: 2425,
			time: 1654291860000,
			lowest: 145.53,
			highest: 145.55,
			volumePrice: 145.5395,
			transactionCount: 35,
			voluemPrice: 145.5395
		},
		{
			open: 145.53,
			close: 145.53,
			volume: 339,
			time: 1654291920000,
			lowest: 145.53,
			highest: 145.53,
			volumePrice: 145.5296,
			transactionCount: 15,
			voluemPrice: 145.5296
		},
		{
			open: 145.5,
			close: 145.49,
			volume: 1242,
			time: 1654291980000,
			lowest: 145.47,
			highest: 145.5,
			volumePrice: 145.4905,
			transactionCount: 27,
			voluemPrice: 145.4905
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 574,
			time: 1654292040000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5061,
			transactionCount: 21,
			voluemPrice: 145.5061
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 303,
			time: 1654292100000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5021,
			transactionCount: 26,
			voluemPrice: 145.5021
		},
		{
			open: 145.4815,
			close: 145.4815,
			volume: 269,
			time: 1654292220000,
			lowest: 145.4815,
			highest: 145.4815,
			volumePrice: 145.4809,
			transactionCount: 32,
			voluemPrice: 145.4809
		},
		{
			open: 145.48,
			close: 145.48,
			volume: 135,
			time: 1654292280000,
			lowest: 145.48,
			highest: 145.48,
			volumePrice: 145.4802,
			transactionCount: 15,
			voluemPrice: 145.4802
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 499,
			time: 1654292340000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.4707,
			transactionCount: 21,
			voluemPrice: 145.4707
		},
		{
			open: 145.4738,
			close: 145.4738,
			volume: 309,
			time: 1654292400000,
			lowest: 145.4738,
			highest: 145.4738,
			volumePrice: 145.4864,
			transactionCount: 26,
			voluemPrice: 145.4864
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 198,
			time: 1654292460000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.4947,
			transactionCount: 23,
			voluemPrice: 145.4947
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 280,
			time: 1654292520000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5033,
			transactionCount: 18,
			voluemPrice: 145.5033
		},
		{
			open: 145.48,
			close: 145.4701,
			volume: 828,
			time: 1654292580000,
			lowest: 145.47,
			highest: 145.52,
			volumePrice: 145.4793,
			transactionCount: 33,
			voluemPrice: 145.4793
		},
		{
			open: 145.4701,
			close: 145.4407,
			volume: 1889,
			time: 1654292640000,
			lowest: 145.4407,
			highest: 145.4701,
			volumePrice: 145.4489,
			transactionCount: 40,
			voluemPrice: 145.4489
		},
		{
			open: 145.45,
			close: 145.47,
			volume: 1350,
			time: 1654292700000,
			lowest: 145.45,
			highest: 145.47,
			volumePrice: 145.4548,
			transactionCount: 22,
			voluemPrice: 145.4548
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 269,
			time: 1654292760000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.487,
			transactionCount: 15,
			voluemPrice: 145.487
		},
		{
			open: 145.5,
			close: 145.4409,
			volume: 1715,
			time: 1654292880000,
			lowest: 145.4409,
			highest: 145.5,
			volumePrice: 145.486,
			transactionCount: 24,
			voluemPrice: 145.486
		},
		{
			open: 145.44,
			close: 145.45,
			volume: 707,
			time: 1654292940000,
			lowest: 145.44,
			highest: 145.45,
			volumePrice: 145.442,
			transactionCount: 45,
			voluemPrice: 145.442
		},
		{
			open: 145.44,
			close: 145.42,
			volume: 807,
			time: 1654293000000,
			lowest: 145.42,
			highest: 145.44,
			volumePrice: 145.4278,
			transactionCount: 22,
			voluemPrice: 145.4278
		},
		{
			open: 145.44,
			close: 145.4301,
			volume: 517,
			time: 1654293120000,
			lowest: 145.4301,
			highest: 145.44,
			volumePrice: 145.4321,
			transactionCount: 19,
			voluemPrice: 145.4321
		},
		{
			open: 145.46,
			close: 145.46,
			volume: 205,
			time: 1654293180000,
			lowest: 145.46,
			highest: 145.46,
			volumePrice: 145.4523,
			transactionCount: 14,
			voluemPrice: 145.4523
		},
		{
			open: 145.4501,
			close: 145.4205,
			volume: 1266,
			time: 1654293240000,
			lowest: 145.4205,
			highest: 145.46,
			volumePrice: 145.433,
			transactionCount: 23,
			voluemPrice: 145.433
		},
		{
			open: 145.45,
			close: 145.43,
			volume: 1570,
			time: 1654293300000,
			lowest: 145.43,
			highest: 145.5,
			volumePrice: 145.4414,
			transactionCount: 35,
			voluemPrice: 145.4414
		},
		{
			open: 145.5,
			close: 145.47,
			volume: 1046,
			time: 1654293360000,
			lowest: 145.43,
			highest: 145.5,
			volumePrice: 145.4601,
			transactionCount: 30,
			voluemPrice: 145.4601
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 780,
			time: 1654293420000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.469,
			transactionCount: 34,
			voluemPrice: 145.469
		},
		{
			open: 145.4899,
			close: 145.4899,
			volume: 605,
			time: 1654293480000,
			lowest: 145.4899,
			highest: 145.4899,
			volumePrice: 145.4863,
			transactionCount: 36,
			voluemPrice: 145.4863
		},
		{
			open: 145.48,
			close: 145.47,
			volume: 2410,
			time: 1654293540000,
			lowest: 145.4,
			highest: 145.48,
			volumePrice: 145.4195,
			transactionCount: 45,
			voluemPrice: 145.4195
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 512,
			time: 1654293600000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.4609,
			transactionCount: 13,
			voluemPrice: 145.4609
		},
		{
			open: 145.45,
			close: 145.41,
			volume: 2640,
			time: 1654293660000,
			lowest: 145.41,
			highest: 145.45,
			volumePrice: 145.4174,
			transactionCount: 38,
			voluemPrice: 145.4174
		},
		{
			open: 145.43,
			close: 145.41,
			volume: 1818,
			time: 1654293780000,
			lowest: 145.41,
			highest: 145.43,
			volumePrice: 145.4126,
			transactionCount: 29,
			voluemPrice: 145.4126
		},
		{
			open: 145.4,
			close: 145.39,
			volume: 4109,
			time: 1654293840000,
			lowest: 145.39,
			highest: 145.43,
			volumePrice: 145.4076,
			transactionCount: 44,
			voluemPrice: 145.4076
		},
		{
			open: 145.39,
			close: 145.35,
			volume: 2772,
			time: 1654293900000,
			lowest: 145.35,
			highest: 145.39,
			volumePrice: 145.3735,
			transactionCount: 58,
			voluemPrice: 145.3735
		},
		{
			open: 145.35,
			close: 145.35,
			volume: 866,
			time: 1654293960000,
			lowest: 145.35,
			highest: 145.35,
			volumePrice: 145.3494,
			transactionCount: 31,
			voluemPrice: 145.3494
		},
		{
			open: 145.33,
			close: 145.35,
			volume: 1209,
			time: 1654294020000,
			lowest: 145.33,
			highest: 145.35,
			volumePrice: 145.3327,
			transactionCount: 28,
			voluemPrice: 145.3327
		},
		{
			open: 145.35,
			close: 145.35,
			volume: 957,
			time: 1654294080000,
			lowest: 145.35,
			highest: 145.35,
			volumePrice: 145.341,
			transactionCount: 31,
			voluemPrice: 145.341
		},
		{
			open: 145.32,
			close: 145.34,
			volume: 2196,
			time: 1654294140000,
			lowest: 145.32,
			highest: 145.35,
			volumePrice: 145.3311,
			transactionCount: 36,
			voluemPrice: 145.3311
		},
		{
			open: 145.31,
			close: 145.3,
			volume: 1144,
			time: 1654294200000,
			lowest: 145.26,
			highest: 145.31,
			volumePrice: 145.2945,
			transactionCount: 35,
			voluemPrice: 145.2945
		},
		{
			open: 145.29,
			close: 145.29,
			volume: 523,
			time: 1654294260000,
			lowest: 145.29,
			highest: 145.29,
			volumePrice: 145.2819,
			transactionCount: 29,
			voluemPrice: 145.2819
		},
		{
			open: 145.28,
			close: 145.28,
			volume: 738,
			time: 1654294320000,
			lowest: 145.27,
			highest: 145.28,
			volumePrice: 145.2752,
			transactionCount: 23,
			voluemPrice: 145.2752
		},
		{
			open: 145.31,
			close: 145.32,
			volume: 938,
			time: 1654294380000,
			lowest: 145.31,
			highest: 145.34,
			volumePrice: 145.3267,
			transactionCount: 26,
			voluemPrice: 145.3267
		},
		{
			open: 145.32,
			close: 145.31,
			volume: 2370,
			time: 1654294440000,
			lowest: 145.29,
			highest: 145.33,
			volumePrice: 145.3097,
			transactionCount: 36,
			voluemPrice: 145.3097
		},
		{
			open: 145.31,
			close: 145.31,
			volume: 254,
			time: 1654294500000,
			lowest: 145.31,
			highest: 145.31,
			volumePrice: 145.3071,
			transactionCount: 11,
			voluemPrice: 145.3071
		},
		{
			open: 145.3,
			close: 145.3,
			volume: 1660,
			time: 1654294560000,
			lowest: 145.29,
			highest: 145.3,
			volumePrice: 145.2991,
			transactionCount: 20,
			voluemPrice: 145.2991
		},
		{
			open: 145.28,
			close: 145.27,
			volume: 1049,
			time: 1654294620000,
			lowest: 145.27,
			highest: 145.28,
			volumePrice: 145.2823,
			transactionCount: 29,
			voluemPrice: 145.2823
		},
		{
			open: 145.31,
			close: 145.3,
			volume: 638,
			time: 1654294680000,
			lowest: 145.3,
			highest: 145.31,
			volumePrice: 145.3013,
			transactionCount: 25,
			voluemPrice: 145.3013
		},
		{
			open: 145.28,
			close: 145.28,
			volume: 352,
			time: 1654294980000,
			lowest: 145.28,
			highest: 145.28,
			volumePrice: 145.2884,
			transactionCount: 13,
			voluemPrice: 145.2884
		},
		{
			open: 145.3,
			close: 145.3,
			volume: 608,
			time: 1654295040000,
			lowest: 145.3,
			highest: 145.3,
			volumePrice: 145.2988,
			transactionCount: 12,
			voluemPrice: 145.2988
		},
		{
			open: 145.31,
			close: 145.31,
			volume: 1587,
			time: 1654295100000,
			lowest: 145.31,
			highest: 145.33,
			volumePrice: 145.3145,
			transactionCount: 21,
			voluemPrice: 145.3145
		},
		{
			open: 145.27,
			close: 145.25,
			volume: 1004,
			time: 1654295160000,
			lowest: 145.25,
			highest: 145.27,
			volumePrice: 145.2618,
			transactionCount: 28,
			voluemPrice: 145.2618
		},
		{
			open: 145.31,
			close: 145.35,
			volume: 3889,
			time: 1654295220000,
			lowest: 145.3,
			highest: 145.35,
			volumePrice: 145.318,
			transactionCount: 30,
			voluemPrice: 145.318
		},
		{
			open: 145.32,
			close: 145.32,
			volume: 627,
			time: 1654295400000,
			lowest: 145.32,
			highest: 145.32,
			volumePrice: 145.3142,
			transactionCount: 11,
			voluemPrice: 145.3142
		},
		{
			open: 145.28,
			close: 145.32,
			volume: 439,
			time: 1654295520000,
			lowest: 145.28,
			highest: 145.32,
			volumePrice: 145.2972,
			transactionCount: 21,
			voluemPrice: 145.2972
		},
		{
			open: 145.31,
			close: 145.31,
			volume: 234,
			time: 1654295580000,
			lowest: 145.31,
			highest: 145.31,
			volumePrice: 145.3065,
			transactionCount: 12,
			voluemPrice: 145.3065
		},
		{
			open: 145.31,
			close: 145.31,
			volume: 383,
			time: 1654295640000,
			lowest: 145.31,
			highest: 145.31,
			volumePrice: 145.3098,
			transactionCount: 11,
			voluemPrice: 145.3098
		},
		{
			open: 145.31,
			close: 145.34,
			volume: 1613,
			time: 1654295700000,
			lowest: 145.31,
			highest: 145.34,
			volumePrice: 145.3149,
			transactionCount: 28,
			voluemPrice: 145.3149
		},
		{
			open: 145.31,
			close: 145.3,
			volume: 1003,
			time: 1654295760000,
			lowest: 145.3,
			highest: 145.31,
			volumePrice: 145.3069,
			transactionCount: 22,
			voluemPrice: 145.3069
		},
		{
			open: 145.28,
			close: 145.27,
			volume: 1059,
			time: 1654295820000,
			lowest: 145.27,
			highest: 145.28,
			volumePrice: 145.2765,
			transactionCount: 20,
			voluemPrice: 145.2765
		},
		{
			open: 145.25,
			close: 145.26,
			volume: 2248,
			time: 1654295880000,
			lowest: 145.25,
			highest: 145.29,
			volumePrice: 145.2638,
			transactionCount: 34,
			voluemPrice: 145.2638
		},
		{
			open: 145.31,
			close: 145.26,
			volume: 1010,
			time: 1654295940000,
			lowest: 145.26,
			highest: 145.31,
			volumePrice: 145.2692,
			transactionCount: 19,
			voluemPrice: 145.2692
		},
		{
			open: 145.28,
			close: 145.3,
			volume: 721,
			time: 1654296000000,
			lowest: 145.28,
			highest: 145.3,
			volumePrice: 145.293,
			transactionCount: 11,
			voluemPrice: 145.293
		},
		{
			open: 145.3,
			close: 145.3,
			volume: 1197,
			time: 1654296060000,
			lowest: 145.29,
			highest: 145.3,
			volumePrice: 145.2994,
			transactionCount: 28,
			voluemPrice: 145.2994
		},
		{
			open: 145.3,
			close: 145.3,
			volume: 145,
			time: 1654296120000,
			lowest: 145.3,
			highest: 145.3,
			volumePrice: 145.2983,
			transactionCount: 11,
			voluemPrice: 145.2983
		},
		{
			open: 145.28,
			close: 145.21,
			volume: 3460,
			time: 1654296180000,
			lowest: 145.21,
			highest: 145.28,
			volumePrice: 145.2471,
			transactionCount: 56,
			voluemPrice: 145.2471
		},
		{
			open: 145.23,
			close: 145.2,
			volume: 2903,
			time: 1654296240000,
			lowest: 145.2,
			highest: 145.24,
			volumePrice: 145.2262,
			transactionCount: 30,
			voluemPrice: 145.2262
		},
		{
			open: 145.2,
			close: 145.16,
			volume: 1422,
			time: 1654296300000,
			lowest: 145.16,
			highest: 145.2,
			volumePrice: 145.1933,
			transactionCount: 23,
			voluemPrice: 145.1933
		},
		{
			open: 145.16,
			close: 145.18,
			volume: 848,
			time: 1654296360000,
			lowest: 145.16,
			highest: 145.18,
			volumePrice: 145.1697,
			transactionCount: 16,
			voluemPrice: 145.1697
		},
		{
			open: 145.2,
			close: 145.2,
			volume: 3961,
			time: 1654296420000,
			lowest: 145.15,
			highest: 145.2,
			volumePrice: 145.1812,
			transactionCount: 33,
			voluemPrice: 145.1812
		},
		{
			open: 145.24,
			close: 145.24,
			volume: 146,
			time: 1654296480000,
			lowest: 145.24,
			highest: 145.24,
			volumePrice: 145.2269,
			transactionCount: 7,
			voluemPrice: 145.2269
		},
		{
			open: 145.23,
			close: 145.23,
			volume: 383,
			time: 1654296540000,
			lowest: 145.23,
			highest: 145.23,
			volumePrice: 145.2285,
			transactionCount: 18,
			voluemPrice: 145.2285
		},
		{
			open: 145.23,
			close: 145.23,
			volume: 702,
			time: 1654296600000,
			lowest: 145.23,
			highest: 145.23,
			volumePrice: 145.231,
			transactionCount: 16,
			voluemPrice: 145.231
		},
		{
			open: 145.24,
			close: 145.24,
			volume: 345,
			time: 1654296720000,
			lowest: 145.24,
			highest: 145.24,
			volumePrice: 145.2328,
			transactionCount: 20,
			voluemPrice: 145.2328
		},
		{
			open: 145.23,
			close: 145.21,
			volume: 401,
			time: 1654296840000,
			lowest: 145.21,
			highest: 145.23,
			volumePrice: 145.2179,
			transactionCount: 22,
			voluemPrice: 145.2179
		},
		{
			open: 145.2,
			close: 145.2,
			volume: 889,
			time: 1654296900000,
			lowest: 145.2,
			highest: 145.2,
			volumePrice: 145.2016,
			transactionCount: 22,
			voluemPrice: 145.2016
		},
		{
			open: 145.2,
			close: 145.2,
			volume: 459,
			time: 1654296960000,
			lowest: 145.2,
			highest: 145.2,
			volumePrice: 145.2004,
			transactionCount: 12,
			voluemPrice: 145.2004
		},
		{
			open: 145.2,
			close: 145.2,
			volume: 430,
			time: 1654297080000,
			lowest: 145.2,
			highest: 145.2,
			volumePrice: 145.1999,
			transactionCount: 9,
			voluemPrice: 145.1999
		},
		{
			open: 145.19,
			close: 145.2,
			volume: 1955,
			time: 1654297140000,
			lowest: 145.19,
			highest: 145.2,
			volumePrice: 145.2157,
			transactionCount: 29,
			voluemPrice: 145.2157
		},
		{
			open: 145.21,
			close: 145.21,
			volume: 234,
			time: 1654297200000,
			lowest: 145.21,
			highest: 145.21,
			volumePrice: 145.2183,
			transactionCount: 15,
			voluemPrice: 145.2183
		},
		{
			open: 145.29,
			close: 145.29,
			volume: 414,
			time: 1654297260000,
			lowest: 145.29,
			highest: 145.29,
			volumePrice: 145.2812,
			transactionCount: 18,
			voluemPrice: 145.2812
		},
		{
			open: 145.21,
			close: 145.25,
			volume: 1447,
			time: 1654297320000,
			lowest: 145.2,
			highest: 145.25,
			volumePrice: 145.2329,
			transactionCount: 15,
			voluemPrice: 145.2329
		},
		{
			open: 145.27,
			close: 145.27,
			volume: 255,
			time: 1654297440000,
			lowest: 145.27,
			highest: 145.27,
			volumePrice: 145.2599,
			transactionCount: 17,
			voluemPrice: 145.2599
		},
		{
			open: 145.24,
			close: 145.24,
			volume: 265,
			time: 1654297620000,
			lowest: 145.24,
			highest: 145.24,
			volumePrice: 145.2405,
			transactionCount: 10,
			voluemPrice: 145.2405
		},
		{
			open: 145.21,
			close: 145.23,
			volume: 683,
			time: 1654297740000,
			lowest: 145.21,
			highest: 145.23,
			volumePrice: 145.2256,
			transactionCount: 17,
			voluemPrice: 145.2256
		},
		{
			open: 145.27,
			close: 145.28,
			volume: 555,
			time: 1654297860000,
			lowest: 145.27,
			highest: 145.28,
			volumePrice: 145.267,
			transactionCount: 17,
			voluemPrice: 145.267
		},
		{
			open: 145.22,
			close: 145.21,
			volume: 648,
			time: 1654297920000,
			lowest: 145.21,
			highest: 145.22,
			volumePrice: 145.2204,
			transactionCount: 20,
			voluemPrice: 145.2204
		},
		{
			open: 145.21,
			close: 145.3,
			volume: 4770,
			time: 1654297980000,
			lowest: 145.21,
			highest: 145.33,
			volumePrice: 145.2849,
			transactionCount: 41,
			voluemPrice: 145.2849
		},
		{
			open: 145.22,
			close: 145.27,
			volume: 1566,
			time: 1654298100000,
			lowest: 145.22,
			highest: 145.27,
			volumePrice: 145.2443,
			transactionCount: 13,
			voluemPrice: 145.2443
		},
		{
			open: 145.33,
			close: 145.33,
			volume: 307,
			time: 1654298160000,
			lowest: 145.33,
			highest: 145.33,
			volumePrice: 145.3236,
			transactionCount: 14,
			voluemPrice: 145.3236
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 216,
			time: 1654298280000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2516,
			transactionCount: 12,
			voluemPrice: 145.2516
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 446,
			time: 1654298400000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2526,
			transactionCount: 19,
			voluemPrice: 145.2526
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 2450,
			time: 1654298520000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2505,
			transactionCount: 22,
			voluemPrice: 145.2505
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 388,
			time: 1654298580000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2461,
			transactionCount: 16,
			voluemPrice: 145.2461
		},
		{
			open: 145.28,
			close: 145.28,
			volume: 353,
			time: 1654298640000,
			lowest: 145.28,
			highest: 145.28,
			volumePrice: 145.262,
			transactionCount: 12,
			voluemPrice: 145.262
		},
		{
			open: 145.28,
			close: 145.28,
			volume: 581,
			time: 1654298700000,
			lowest: 145.28,
			highest: 145.28,
			volumePrice: 145.2634,
			transactionCount: 14,
			voluemPrice: 145.2634
		},
		{
			open: 145.23,
			close: 145.25,
			volume: 495,
			time: 1654298820000,
			lowest: 145.23,
			highest: 145.25,
			volumePrice: 145.2552,
			transactionCount: 17,
			voluemPrice: 145.2552
		},
		{
			open: 145.25,
			close: 145.24,
			volume: 439,
			time: 1654298880000,
			lowest: 145.24,
			highest: 145.25,
			volumePrice: 145.2466,
			transactionCount: 20,
			voluemPrice: 145.2466
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 183,
			time: 1654299000000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2502,
			transactionCount: 10,
			voluemPrice: 145.2502
		},
		{
			open: 145.22,
			close: 145.22,
			volume: 1156,
			time: 1654299120000,
			lowest: 145.22,
			highest: 145.22,
			volumePrice: 145.2242,
			transactionCount: 23,
			voluemPrice: 145.2242
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 2208,
			time: 1654299300000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2482,
			transactionCount: 15,
			voluemPrice: 145.2482
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 639,
			time: 1654299360000,
			lowest: 145.25,
			highest: 145.25,
			volumePrice: 145.2489,
			transactionCount: 11,
			voluemPrice: 145.2489
		},
		{
			open: 145.23,
			close: 145.23,
			volume: 152,
			time: 1654299420000,
			lowest: 145.23,
			highest: 145.23,
			volumePrice: 145.2328,
			transactionCount: 15,
			voluemPrice: 145.2328
		},
		{
			open: 145.25,
			close: 145.23,
			volume: 420,
			time: 1654299480000,
			lowest: 145.23,
			highest: 145.25,
			volumePrice: 145.2381,
			transactionCount: 17,
			voluemPrice: 145.2381
		},
		{
			open: 145.23,
			close: 145.21,
			volume: 2162,
			time: 1654299540000,
			lowest: 145.21,
			highest: 145.23,
			volumePrice: 145.2196,
			transactionCount: 25,
			voluemPrice: 145.2196
		},
		{
			open: 145.2,
			close: 145.2,
			volume: 1060,
			time: 1654299600000,
			lowest: 145.2,
			highest: 145.2,
			volumePrice: 145.2006,
			transactionCount: 47,
			voluemPrice: 145.2006
		},
		{
			open: 145.19,
			close: 145.19,
			volume: 770,
			time: 1654299660000,
			lowest: 145.19,
			highest: 145.19,
			volumePrice: 145.192,
			transactionCount: 23,
			voluemPrice: 145.192
		},
		{
			open: 145.15,
			close: 145.13,
			volume: 2266,
			time: 1654299780000,
			lowest: 145.13,
			highest: 145.18,
			volumePrice: 145.1527,
			transactionCount: 38,
			voluemPrice: 145.1527
		},
		{
			open: 145.17,
			close: 145.17,
			volume: 244,
			time: 1654299840000,
			lowest: 145.17,
			highest: 145.17,
			volumePrice: 145.1688,
			transactionCount: 13,
			voluemPrice: 145.1688
		},
		{
			open: 145.1799,
			close: 145.18,
			volume: 743,
			time: 1654299900000,
			lowest: 145.1799,
			highest: 145.18,
			volumePrice: 145.179,
			transactionCount: 18,
			voluemPrice: 145.179
		},
		{
			open: 145.18,
			close: 145.18,
			volume: 319,
			time: 1654299960000,
			lowest: 145.18,
			highest: 145.18,
			volumePrice: 145.1685,
			transactionCount: 25,
			voluemPrice: 145.1685
		},
		{
			open: 145.1501,
			close: 145.15,
			volume: 432,
			time: 1654300020000,
			lowest: 145.15,
			highest: 145.1501,
			volumePrice: 145.1578,
			transactionCount: 16,
			voluemPrice: 145.1578
		},
		{
			open: 145.17,
			close: 145.17,
			volume: 595,
			time: 1654300080000,
			lowest: 145.17,
			highest: 145.17,
			volumePrice: 145.1587,
			transactionCount: 30,
			voluemPrice: 145.1587
		},
		{
			open: 145.16,
			close: 145.16,
			volume: 390,
			time: 1654300140000,
			lowest: 145.16,
			highest: 145.16,
			volumePrice: 145.1628,
			transactionCount: 20,
			voluemPrice: 145.1628
		},
		{
			open: 145.15,
			close: 145.1,
			volume: 6929,
			time: 1654300200000,
			lowest: 145.1,
			highest: 145.17,
			volumePrice: 145.1317,
			transactionCount: 54,
			voluemPrice: 145.1317
		},
		{
			open: 145.1,
			close: 145.1199,
			volume: 832,
			time: 1654300320000,
			lowest: 145.1,
			highest: 145.1599,
			volumePrice: 145.1138,
			transactionCount: 27,
			voluemPrice: 145.1138
		},
		{
			open: 145.12,
			close: 145.11,
			volume: 1544,
			time: 1654300380000,
			lowest: 145.11,
			highest: 145.17,
			volumePrice: 145.1225,
			transactionCount: 20,
			voluemPrice: 145.1225
		},
		{
			open: 145.14,
			close: 145.14,
			volume: 265,
			time: 1654300440000,
			lowest: 145.14,
			highest: 145.14,
			volumePrice: 145.1406,
			transactionCount: 24,
			voluemPrice: 145.1406
		},
		{
			open: 145.17,
			close: 145.18,
			volume: 1329,
			time: 1654300500000,
			lowest: 145.16,
			highest: 145.18,
			volumePrice: 145.1707,
			transactionCount: 28,
			voluemPrice: 145.1707
		},
		{
			open: 145.16,
			close: 145.16,
			volume: 737,
			time: 1654300560000,
			lowest: 145.16,
			highest: 145.16,
			volumePrice: 145.1447,
			transactionCount: 32,
			voluemPrice: 145.1447
		},
		{
			open: 145.12,
			close: 145.12,
			volume: 3749,
			time: 1654300620000,
			lowest: 145.12,
			highest: 145.15,
			volumePrice: 145.1262,
			transactionCount: 33,
			voluemPrice: 145.1262
		},
		{
			open: 145.18,
			close: 145.13,
			volume: 1987,
			time: 1654300680000,
			lowest: 145.13,
			highest: 145.18,
			volumePrice: 145.1589,
			transactionCount: 35,
			voluemPrice: 145.1589
		},
		{
			open: 145.15,
			close: 145.2,
			volume: 4371,
			time: 1654300740000,
			lowest: 145.15,
			highest: 145.2,
			volumePrice: 145.1696,
			transactionCount: 46,
			voluemPrice: 145.1696
		},
		{
			open: 146.19,
			close: 147.49,
			volume: 6143,
			time: 1654502400000,
			lowest: 146.19,
			highest: 147.72,
			volumePrice: 147.2291,
			transactionCount: 116,
			voluemPrice: 147.2291
		},
		{
			open: 147.42,
			close: 147.3,
			volume: 3957,
			time: 1654502460000,
			lowest: 147,
			highest: 147.42,
			volumePrice: 147.2008,
			transactionCount: 93,
			voluemPrice: 147.2008
		},
		{
			open: 147.3,
			close: 147.32,
			volume: 4281,
			time: 1654502520000,
			lowest: 147.3,
			highest: 147.32,
			volumePrice: 147.3006,
			transactionCount: 38,
			voluemPrice: 147.3006
		},
		{
			open: 147.34,
			close: 147.3,
			volume: 1600,
			time: 1654502580000,
			lowest: 147.3,
			highest: 147.35,
			volumePrice: 147.3092,
			transactionCount: 61,
			voluemPrice: 147.3092
		},
		{
			open: 147.27,
			close: 147.25,
			volume: 1838,
			time: 1654502640000,
			lowest: 147.25,
			highest: 147.29,
			volumePrice: 147.268,
			transactionCount: 67,
			voluemPrice: 147.268
		},
		{
			open: 147.23,
			close: 147.15,
			volume: 2177,
			time: 1654502700000,
			lowest: 147.15,
			highest: 147.24,
			volumePrice: 147.2278,
			transactionCount: 53,
			voluemPrice: 147.2278
		},
		{
			open: 147.12,
			close: 147.12,
			volume: 618,
			time: 1654502760000,
			lowest: 147.12,
			highest: 147.12,
			volumePrice: 147.1606,
			transactionCount: 32,
			voluemPrice: 147.1606
		},
		{
			open: 147.18,
			close: 147.18,
			volume: 447,
			time: 1654502880000,
			lowest: 147.18,
			highest: 147.18,
			volumePrice: 147.1829,
			transactionCount: 24,
			voluemPrice: 147.1829
		},
		{
			open: 147.2,
			close: 147.2,
			volume: 443,
			time: 1654502940000,
			lowest: 147.2,
			highest: 147.2,
			volumePrice: 147.2186,
			transactionCount: 20,
			voluemPrice: 147.2186
		},
		{
			open: 147.28,
			close: 147.3,
			volume: 1973,
			time: 1654503060000,
			lowest: 147.28,
			highest: 147.3,
			volumePrice: 147.2967,
			transactionCount: 39,
			voluemPrice: 147.2967
		},
		{
			open: 147.4,
			close: 147.47,
			volume: 3716,
			time: 1654503120000,
			lowest: 147.4,
			highest: 147.5,
			volumePrice: 147.4559,
			transactionCount: 58,
			voluemPrice: 147.4559
		},
		{
			open: 147.49,
			close: 147.5,
			volume: 3966,
			time: 1654503180000,
			lowest: 147.49,
			highest: 147.5,
			volumePrice: 147.4941,
			transactionCount: 55,
			voluemPrice: 147.4941
		},
		{
			open: 147.45,
			close: 147.54,
			volume: 1062,
			time: 1654503240000,
			lowest: 147.45,
			highest: 147.54,
			volumePrice: 147.5162,
			transactionCount: 32,
			voluemPrice: 147.5162
		},
		{
			open: 147.5,
			close: 147.43,
			volume: 2208,
			time: 1654503300000,
			lowest: 147.43,
			highest: 147.58,
			volumePrice: 147.504,
			transactionCount: 56,
			voluemPrice: 147.504
		},
		{
			open: 147.46,
			close: 147.46,
			volume: 274,
			time: 1654503420000,
			lowest: 147.46,
			highest: 147.46,
			volumePrice: 147.4619,
			transactionCount: 25,
			voluemPrice: 147.4619
		},
		{
			open: 147.42,
			close: 147.42,
			volume: 1000,
			time: 1654503480000,
			lowest: 147.42,
			highest: 147.42,
			volumePrice: 147.4205,
			transactionCount: 37,
			voluemPrice: 147.4205
		},
		{
			open: 147.4,
			close: 147.38,
			volume: 603,
			time: 1654503540000,
			lowest: 147.38,
			highest: 147.4,
			volumePrice: 147.3942,
			transactionCount: 14,
			voluemPrice: 147.3942
		},
		{
			open: 147.4,
			close: 147.38,
			volume: 1141,
			time: 1654503600000,
			lowest: 147.36,
			highest: 147.4,
			volumePrice: 147.3867,
			transactionCount: 24,
			voluemPrice: 147.3867
		},
		{
			open: 147.36,
			close: 147.36,
			volume: 401,
			time: 1654503660000,
			lowest: 147.36,
			highest: 147.36,
			volumePrice: 147.356,
			transactionCount: 22,
			voluemPrice: 147.356
		},
		{
			open: 147.36,
			close: 147.49,
			volume: 4652,
			time: 1654503720000,
			lowest: 147.36,
			highest: 147.5,
			volumePrice: 147.4542,
			transactionCount: 64,
			voluemPrice: 147.4542
		},
		{
			open: 147.36,
			close: 147.35,
			volume: 1148,
			time: 1654503840000,
			lowest: 147.35,
			highest: 147.36,
			volumePrice: 147.3732,
			transactionCount: 27,
			voluemPrice: 147.3732
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 800,
			time: 1654503960000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.4797,
			transactionCount: 25,
			voluemPrice: 147.4797
		},
		{
			open: 147.44,
			close: 147.5,
			volume: 1892,
			time: 1654504080000,
			lowest: 147.44,
			highest: 147.5,
			volumePrice: 147.4833,
			transactionCount: 31,
			voluemPrice: 147.4833
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 1414,
			time: 1654504140000,
			lowest: 147.48,
			highest: 147.49,
			volumePrice: 147.4817,
			transactionCount: 17,
			voluemPrice: 147.4817
		},
		{
			open: 147.5,
			close: 147.55,
			volume: 2916,
			time: 1654504200000,
			lowest: 147.5,
			highest: 147.56,
			volumePrice: 147.5332,
			transactionCount: 51,
			voluemPrice: 147.5332
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 518,
			time: 1654504260000,
			lowest: 147.55,
			highest: 147.55,
			volumePrice: 147.5309,
			transactionCount: 16,
			voluemPrice: 147.5309
		},
		{
			open: 147.52,
			close: 147.48,
			volume: 2089,
			time: 1654504320000,
			lowest: 147.48,
			highest: 147.52,
			volumePrice: 147.5058,
			transactionCount: 33,
			voluemPrice: 147.5058
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 1587,
			time: 1654504380000,
			lowest: 147.5,
			highest: 147.57,
			volumePrice: 147.5351,
			transactionCount: 28,
			voluemPrice: 147.5351
		},
		{
			open: 147.54,
			close: 147.55,
			volume: 1005,
			time: 1654504440000,
			lowest: 147.54,
			highest: 147.55,
			volumePrice: 147.5313,
			transactionCount: 34,
			voluemPrice: 147.5313
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 843,
			time: 1654504500000,
			lowest: 147.55,
			highest: 147.55,
			volumePrice: 147.5368,
			transactionCount: 26,
			voluemPrice: 147.5368
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 193,
			time: 1654504560000,
			lowest: 147.55,
			highest: 147.55,
			volumePrice: 147.5423,
			transactionCount: 14,
			voluemPrice: 147.5423
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 1298,
			time: 1654504620000,
			lowest: 147.53,
			highest: 147.55,
			volumePrice: 147.5435,
			transactionCount: 22,
			voluemPrice: 147.5435
		},
		{
			open: 147.48,
			close: 147.5,
			volume: 503,
			time: 1654504740000,
			lowest: 147.48,
			highest: 147.5,
			volumePrice: 147.4892,
			transactionCount: 15,
			voluemPrice: 147.4892
		},
		{
			open: 147.53,
			close: 147.53,
			volume: 291,
			time: 1654504920000,
			lowest: 147.53,
			highest: 147.53,
			volumePrice: 147.5337,
			transactionCount: 16,
			voluemPrice: 147.5337
		},
		{
			open: 147.52,
			close: 147.52,
			volume: 726,
			time: 1654504980000,
			lowest: 147.52,
			highest: 147.53,
			volumePrice: 147.5232,
			transactionCount: 19,
			voluemPrice: 147.5232
		},
		{
			open: 147.5,
			close: 147.48,
			volume: 1408,
			time: 1654505040000,
			lowest: 147.48,
			highest: 147.51,
			volumePrice: 147.4979,
			transactionCount: 19,
			voluemPrice: 147.4979
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 287,
			time: 1654505100000,
			lowest: 147.48,
			highest: 147.48,
			volumePrice: 147.4844,
			transactionCount: 18,
			voluemPrice: 147.4844
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 213,
			time: 1654505220000,
			lowest: 147.48,
			highest: 147.48,
			volumePrice: 147.4823,
			transactionCount: 9,
			voluemPrice: 147.4823
		},
		{
			open: 147.45,
			close: 147.45,
			volume: 399,
			time: 1654505280000,
			lowest: 147.45,
			highest: 147.45,
			volumePrice: 147.4619,
			transactionCount: 23,
			voluemPrice: 147.4619
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 288,
			time: 1654505340000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.4894,
			transactionCount: 13,
			voluemPrice: 147.4894
		},
		{
			open: 147.55,
			close: 147.58,
			volume: 1493,
			time: 1654505640000,
			lowest: 147.55,
			highest: 147.58,
			volumePrice: 147.5738,
			transactionCount: 47,
			voluemPrice: 147.5738
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 743,
			time: 1654505880000,
			lowest: 147.6,
			highest: 147.6,
			volumePrice: 147.5998,
			transactionCount: 34,
			voluemPrice: 147.5998
		},
		{
			open: 147.64,
			close: 147.68,
			volume: 1294,
			time: 1654505940000,
			lowest: 147.6,
			highest: 147.68,
			volumePrice: 147.6373,
			transactionCount: 28,
			voluemPrice: 147.6373
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 2766,
			time: 1654506120000,
			lowest: 147.6,
			highest: 147.67,
			volumePrice: 147.6128,
			transactionCount: 53,
			voluemPrice: 147.6128
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 756,
			time: 1654506360000,
			lowest: 147.6,
			highest: 147.6,
			volumePrice: 147.6181,
			transactionCount: 31,
			voluemPrice: 147.6181
		},
		{
			open: 147.6,
			close: 147.53,
			volume: 638,
			time: 1654506420000,
			lowest: 147.53,
			highest: 147.6,
			volumePrice: 147.5496,
			transactionCount: 25,
			voluemPrice: 147.5496
		},
		{
			open: 147.64,
			close: 147.67,
			volume: 860,
			time: 1654506480000,
			lowest: 147.64,
			highest: 147.67,
			volumePrice: 147.6367,
			transactionCount: 19,
			voluemPrice: 147.6367
		},
		{
			open: 147.67,
			close: 147.67,
			volume: 526,
			time: 1654506600000,
			lowest: 147.67,
			highest: 147.67,
			volumePrice: 147.6687,
			transactionCount: 14,
			voluemPrice: 147.6687
		},
		{
			open: 147.67,
			close: 147.67,
			volume: 238,
			time: 1654506660000,
			lowest: 147.67,
			highest: 147.67,
			volumePrice: 147.6655,
			transactionCount: 9,
			voluemPrice: 147.6655
		},
		{
			open: 147.58,
			close: 147.62,
			volume: 1313,
			time: 1654506900000,
			lowest: 147.57,
			highest: 147.62,
			volumePrice: 147.6002,
			transactionCount: 22,
			voluemPrice: 147.6002
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 311,
			time: 1654506960000,
			lowest: 147.6,
			highest: 147.6,
			volumePrice: 147.602,
			transactionCount: 6,
			voluemPrice: 147.602
		},
		{
			open: 147.57,
			close: 147.53,
			volume: 2074,
			time: 1654507020000,
			lowest: 147.53,
			highest: 147.57,
			volumePrice: 147.5372,
			transactionCount: 46,
			voluemPrice: 147.5372
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 754,
			time: 1654507140000,
			lowest: 147.55,
			highest: 147.55,
			volumePrice: 147.5455,
			transactionCount: 11,
			voluemPrice: 147.5455
		},
		{
			open: 147.55,
			close: 147.55,
			volume: 1039,
			time: 1654507200000,
			lowest: 147.55,
			highest: 147.55,
			volumePrice: 147.542,
			transactionCount: 15,
			voluemPrice: 147.542
		},
		{
			open: 147.49,
			close: 147.49,
			volume: 619,
			time: 1654507380000,
			lowest: 147.49,
			highest: 147.49,
			volumePrice: 147.4953,
			transactionCount: 14,
			voluemPrice: 147.4953
		},
		{
			open: 147.62,
			close: 147.62,
			volume: 462,
			time: 1654507740000,
			lowest: 147.62,
			highest: 147.62,
			volumePrice: 147.5529,
			transactionCount: 18,
			voluemPrice: 147.5529
		},
		{
			open: 147.49,
			close: 147.49,
			volume: 311,
			time: 1654507860000,
			lowest: 147.49,
			highest: 147.49,
			volumePrice: 147.4915,
			transactionCount: 9,
			voluemPrice: 147.4915
		},
		{
			open: 147.49,
			close: 147.49,
			volume: 219,
			time: 1654507920000,
			lowest: 147.49,
			highest: 147.49,
			volumePrice: 147.4882,
			transactionCount: 7,
			voluemPrice: 147.4882
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 522,
			time: 1654508040000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.4931,
			transactionCount: 14,
			voluemPrice: 147.4931
		},
		{
			open: 147.41,
			close: 147.41,
			volume: 656,
			time: 1654508280000,
			lowest: 147.41,
			highest: 147.41,
			volumePrice: 147.3999,
			transactionCount: 26,
			voluemPrice: 147.3999
		},
		{
			open: 147.31,
			close: 147.3,
			volume: 1210,
			time: 1654508400000,
			lowest: 147.3,
			highest: 147.31,
			volumePrice: 147.316,
			transactionCount: 55,
			voluemPrice: 147.316
		},
		{
			open: 147.3,
			close: 147.27,
			volume: 855,
			time: 1654508460000,
			lowest: 147.27,
			highest: 147.3,
			volumePrice: 147.2863,
			transactionCount: 14,
			voluemPrice: 147.2863
		},
		{
			open: 147.35,
			close: 147.35,
			volume: 665,
			time: 1654508520000,
			lowest: 147.35,
			highest: 147.35,
			volumePrice: 147.3436,
			transactionCount: 10,
			voluemPrice: 147.3436
		},
		{
			open: 147.42,
			close: 147.42,
			volume: 530,
			time: 1654508760000,
			lowest: 147.42,
			highest: 147.42,
			volumePrice: 147.422,
			transactionCount: 20,
			voluemPrice: 147.422
		},
		{
			open: 147.4,
			close: 147.4,
			volume: 535,
			time: 1654508880000,
			lowest: 147.4,
			highest: 147.4,
			volumePrice: 147.3984,
			transactionCount: 14,
			voluemPrice: 147.3984
		},
		{
			open: 147.45,
			close: 147.45,
			volume: 467,
			time: 1654508940000,
			lowest: 147.45,
			highest: 147.45,
			volumePrice: 147.4344,
			transactionCount: 12,
			voluemPrice: 147.4344
		},
		{
			open: 147.44,
			close: 147.44,
			volume: 400,
			time: 1654509120000,
			lowest: 147.44,
			highest: 147.44,
			volumePrice: 147.44,
			transactionCount: 4,
			voluemPrice: 147.44
		},
		{
			open: 147.39,
			close: 147.4,
			volume: 317,
			time: 1654509180000,
			lowest: 147.39,
			highest: 147.4,
			volumePrice: 147.3923,
			transactionCount: 6,
			voluemPrice: 147.3923
		},
		{
			open: 147.4,
			close: 147.38,
			volume: 754,
			time: 1654509240000,
			lowest: 147.37,
			highest: 147.4,
			volumePrice: 147.3888,
			transactionCount: 17,
			voluemPrice: 147.3888
		},
		{
			open: 147.4,
			close: 147.4,
			volume: 261,
			time: 1654509360000,
			lowest: 147.4,
			highest: 147.4,
			volumePrice: 147.4068,
			transactionCount: 11,
			voluemPrice: 147.4068
		},
		{
			open: 147.39,
			close: 147.39,
			volume: 253,
			time: 1654509420000,
			lowest: 147.39,
			highest: 147.39,
			volumePrice: 147.3985,
			transactionCount: 8,
			voluemPrice: 147.3985
		},
		{
			open: 147.44,
			close: 147.44,
			volume: 532,
			time: 1654509660000,
			lowest: 147.44,
			highest: 147.44,
			volumePrice: 147.4408,
			transactionCount: 10,
			voluemPrice: 147.4408
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 957,
			time: 1654509780000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.4837,
			transactionCount: 36,
			voluemPrice: 147.4837
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 213,
			time: 1654509900000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.5083,
			transactionCount: 11,
			voluemPrice: 147.5083
		},
		{
			open: 147.53,
			close: 147.53,
			volume: 559,
			time: 1654509960000,
			lowest: 147.53,
			highest: 147.53,
			volumePrice: 147.5165,
			transactionCount: 18,
			voluemPrice: 147.5165
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 1160,
			time: 1654510080000,
			lowest: 147.48,
			highest: 147.48,
			volumePrice: 147.4835,
			transactionCount: 24,
			voluemPrice: 147.4835
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 377,
			time: 1654510140000,
			lowest: 147.48,
			highest: 147.48,
			volumePrice: 147.4806,
			transactionCount: 10,
			voluemPrice: 147.4806
		},
		{
			open: 147.48,
			close: 147.48,
			volume: 202,
			time: 1654510260000,
			lowest: 147.48,
			highest: 147.48,
			volumePrice: 147.4773,
			transactionCount: 5,
			voluemPrice: 147.4773
		},
		{
			open: 147.59,
			close: 147.61,
			volume: 2681,
			time: 1654510440000,
			lowest: 147.59,
			highest: 147.61,
			volumePrice: 147.5999,
			transactionCount: 30,
			voluemPrice: 147.5999
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 626,
			time: 1654510740000,
			lowest: 147.5,
			highest: 147.5,
			volumePrice: 147.5108,
			transactionCount: 15,
			voluemPrice: 147.5108
		},
		{
			open: 147.47,
			close: 147.63,
			volume: 3906,
			time: 1654510860000,
			lowest: 147.47,
			highest: 147.63,
			volumePrice: 147.5436,
			transactionCount: 34,
			voluemPrice: 147.5436
		},
		{
			open: 147.64,
			close: 147.7,
			volume: 4711,
			time: 1654510920000,
			lowest: 147.64,
			highest: 147.7,
			volumePrice: 147.6779,
			transactionCount: 54,
			voluemPrice: 147.6779
		},
		{
			open: 147.69,
			close: 147.69,
			volume: 497,
			time: 1654511160000,
			lowest: 147.69,
			highest: 147.69,
			volumePrice: 147.6896,
			transactionCount: 7,
			voluemPrice: 147.6896
		},
		{
			open: 147.64,
			close: 147.64,
			volume: 354,
			time: 1654511280000,
			lowest: 147.64,
			highest: 147.64,
			volumePrice: 147.641,
			transactionCount: 9,
			voluemPrice: 147.641
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 238,
			time: 1654511340000,
			lowest: 147.6,
			highest: 147.6,
			volumePrice: 147.6034,
			transactionCount: 7,
			voluemPrice: 147.6034
		},
		{
			open: 147.58,
			close: 147.6,
			volume: 735,
			time: 1654511460000,
			lowest: 147.58,
			highest: 147.6,
			volumePrice: 147.5937,
			transactionCount: 17,
			voluemPrice: 147.5937
		},
		{
			open: 147.6,
			close: 147.6,
			volume: 1641,
			time: 1654511520000,
			lowest: 147.6,
			highest: 147.6,
			volumePrice: 147.5999,
			transactionCount: 16,
			voluemPrice: 147.5999
		},
		{
			open: 147.65,
			close: 147.75,
			volume: 2639,
			time: 1654511580000,
			lowest: 147.65,
			highest: 147.75,
			volumePrice: 147.6962,
			transactionCount: 65,
			voluemPrice: 147.6962
		},
		{
			open: 147.73,
			close: 147.7,
			volume: 2332,
			time: 1654511760000,
			lowest: 147.7,
			highest: 147.73,
			volumePrice: 147.7099,
			transactionCount: 26,
			voluemPrice: 147.7099
		},
		{
			open: 147.65,
			close: 147.7,
			volume: 584,
			time: 1654511820000,
			lowest: 147.65,
			highest: 147.7,
			volumePrice: 147.677,
			transactionCount: 18,
			voluemPrice: 147.677
		},
		{
			open: 147.74,
			close: 147.75,
			volume: 637,
			time: 1654512060000,
			lowest: 147.74,
			highest: 147.75,
			volumePrice: 147.747,
			transactionCount: 17,
			voluemPrice: 147.747
		},
		{
			open: 147.74,
			close: 147.74,
			volume: 662,
			time: 1654512180000,
			lowest: 147.74,
			highest: 147.75,
			volumePrice: 147.7372,
			transactionCount: 19,
			voluemPrice: 147.7372
		},
		{
			open: 147.76,
			close: 147.78,
			volume: 2630,
			time: 1654512300000,
			lowest: 147.76,
			highest: 147.78,
			volumePrice: 147.7694,
			transactionCount: 31,
			voluemPrice: 147.7694
		},
		{
			open: 147.78,
			close: 147.78,
			volume: 906,
			time: 1654512420000,
			lowest: 147.78,
			highest: 147.78,
			volumePrice: 147.7828,
			transactionCount: 30,
			voluemPrice: 147.7828
		},
		{
			open: 147.81,
			close: 147.81,
			volume: 558,
			time: 1654512660000,
			lowest: 147.81,
			highest: 147.81,
			volumePrice: 147.8117,
			transactionCount: 25,
			voluemPrice: 147.8117
		},
		{
			open: 147.8,
			close: 147.8,
			volume: 703,
			time: 1654512720000,
			lowest: 147.8,
			highest: 147.8,
			volumePrice: 147.8158,
			transactionCount: 18,
			voluemPrice: 147.8158
		},
		{
			open: 147.85,
			close: 147.9,
			volume: 5771,
			time: 1654512900000,
			lowest: 147.85,
			highest: 148,
			volumePrice: 147.9065,
			transactionCount: 110,
			voluemPrice: 147.9065
		},
		{
			open: 147.8,
			close: 147.74,
			volume: 4505,
			time: 1654513080000,
			lowest: 147.74,
			highest: 147.81,
			volumePrice: 147.7928,
			transactionCount: 89,
			voluemPrice: 147.7928
		},
		{
			open: 147.69,
			close: 147.71,
			volume: 901,
			time: 1654513140000,
			lowest: 147.69,
			highest: 147.71,
			volumePrice: 147.7113,
			transactionCount: 16,
			voluemPrice: 147.7113
		},
		{
			open: 147.75,
			close: 147.3,
			volume: 29057,
			time: 1654513200000,
			lowest: 147.3,
			highest: 147.75,
			volumePrice: 147.5008,
			transactionCount: 373,
			voluemPrice: 147.5008
		},
		{
			open: 147.35,
			close: 147.46,
			volume: 13317,
			time: 1654513260000,
			lowest: 147.33,
			highest: 147.47,
			volumePrice: 147.4136,
			transactionCount: 137,
			voluemPrice: 147.4136
		},
		{
			open: 147.46,
			close: 147.4,
			volume: 9001,
			time: 1654513320000,
			lowest: 147.39,
			highest: 147.5,
			volumePrice: 147.4466,
			transactionCount: 125,
			voluemPrice: 147.4466
		},
		{
			open: 147.36,
			close: 147.39,
			volume: 1868,
			time: 1654513380000,
			lowest: 147.36,
			highest: 147.4,
			volumePrice: 147.3806,
			transactionCount: 48,
			voluemPrice: 147.3806
		},
		{
			open: 147.41,
			close: 147.43,
			volume: 1033,
			time: 1654513440000,
			lowest: 147.41,
			highest: 147.45,
			volumePrice: 147.4243,
			transactionCount: 28,
			voluemPrice: 147.4243
		},
		{
			open: 147.4,
			close: 147.34,
			volume: 2076,
			time: 1654513500000,
			lowest: 147.34,
			highest: 147.4,
			volumePrice: 147.3629,
			transactionCount: 42,
			voluemPrice: 147.3629
		},
		{
			open: 147.34,
			close: 147.34,
			volume: 1193,
			time: 1654513560000,
			lowest: 147.34,
			highest: 147.4,
			volumePrice: 147.3617,
			transactionCount: 44,
			voluemPrice: 147.3617
		},
		{
			open: 147.34,
			close: 147.32,
			volume: 2303,
			time: 1654513620000,
			lowest: 147.32,
			highest: 147.35,
			volumePrice: 147.3372,
			transactionCount: 49,
			voluemPrice: 147.3372
		},
		{
			open: 147.32,
			close: 147.35,
			volume: 2117,
			time: 1654513680000,
			lowest: 147.3,
			highest: 147.35,
			volumePrice: 147.3294,
			transactionCount: 62,
			voluemPrice: 147.3294
		},
		{
			open: 147.38,
			close: 147.33,
			volume: 1120,
			time: 1654513740000,
			lowest: 147.33,
			highest: 147.38,
			volumePrice: 147.3337,
			transactionCount: 36,
			voluemPrice: 147.3337
		},
		{
			open: 147.3,
			close: 147.27,
			volume: 1592,
			time: 1654513800000,
			lowest: 147.27,
			highest: 147.3,
			volumePrice: 147.2925,
			transactionCount: 33,
			voluemPrice: 147.2925
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 2103,
			time: 1654513920000,
			lowest: 147.27,
			highest: 147.3,
			volumePrice: 147.2723,
			transactionCount: 27,
			voluemPrice: 147.2723
		},
		{
			open: 147.28,
			close: 147.29,
			volume: 551,
			time: 1654513980000,
			lowest: 147.28,
			highest: 147.29,
			volumePrice: 147.278,
			transactionCount: 37,
			voluemPrice: 147.278
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 2001,
			time: 1654514040000,
			lowest: 147.27,
			highest: 147.29,
			volumePrice: 147.2738,
			transactionCount: 24,
			voluemPrice: 147.2738
		},
		{
			open: 147.27,
			close: 147.29,
			volume: 1259,
			time: 1654514100000,
			lowest: 147.27,
			highest: 147.29,
			volumePrice: 147.2819,
			transactionCount: 28,
			voluemPrice: 147.2819
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 684,
			time: 1654514160000,
			lowest: 147.27,
			highest: 147.27,
			volumePrice: 147.2687,
			transactionCount: 33,
			voluemPrice: 147.2687
		},
		{
			open: 147.28,
			close: 147.27,
			volume: 1407,
			time: 1654514220000,
			lowest: 147.27,
			highest: 147.28,
			volumePrice: 147.2866,
			transactionCount: 35,
			voluemPrice: 147.2866
		},
		{
			open: 147.34,
			close: 147.41,
			volume: 4729,
			time: 1654514280000,
			lowest: 147.34,
			highest: 147.41,
			volumePrice: 147.3735,
			transactionCount: 61,
			voluemPrice: 147.3735
		},
		{
			open: 147.39,
			close: 147.31,
			volume: 2520,
			time: 1654514340000,
			lowest: 147.31,
			highest: 147.39,
			volumePrice: 147.3512,
			transactionCount: 45,
			voluemPrice: 147.3512
		},
		{
			open: 147.38,
			close: 147.38,
			volume: 739,
			time: 1654514460000,
			lowest: 147.38,
			highest: 147.38,
			volumePrice: 147.3654,
			transactionCount: 26,
			voluemPrice: 147.3654
		},
		{
			open: 147.38,
			close: 147.38,
			volume: 672,
			time: 1654514520000,
			lowest: 147.38,
			highest: 147.38,
			volumePrice: 147.3701,
			transactionCount: 26,
			voluemPrice: 147.3701
		},
		{
			open: 147.4,
			close: 147.35,
			volume: 860,
			time: 1654514580000,
			lowest: 147.35,
			highest: 147.4,
			volumePrice: 147.3648,
			transactionCount: 24,
			voluemPrice: 147.3648
		},
		{
			open: 147.38,
			close: 147.46,
			volume: 5161,
			time: 1654514640000,
			lowest: 147.34,
			highest: 147.46,
			volumePrice: 147.4203,
			transactionCount: 73,
			voluemPrice: 147.4203
		},
		{
			open: 147.44,
			close: 147.43,
			volume: 1848,
			time: 1654514700000,
			lowest: 147.43,
			highest: 147.45,
			volumePrice: 147.4383,
			transactionCount: 27,
			voluemPrice: 147.4383
		},
		{
			open: 147.4,
			close: 147.4,
			volume: 2989,
			time: 1654514760000,
			lowest: 147.36,
			highest: 147.4,
			volumePrice: 147.3931,
			transactionCount: 44,
			voluemPrice: 147.3931
		},
		{
			open: 147.4,
			close: 147.4,
			volume: 1499,
			time: 1654514820000,
			lowest: 147.4,
			highest: 147.4,
			volumePrice: 147.4061,
			transactionCount: 30,
			voluemPrice: 147.4061
		},
		{
			open: 147.38,
			close: 147.4,
			volume: 5903,
			time: 1654514880000,
			lowest: 147.35,
			highest: 147.4,
			volumePrice: 147.3938,
			transactionCount: 48,
			voluemPrice: 147.3938
		},
		{
			open: 147.36,
			close: 147.36,
			volume: 992,
			time: 1654515000000,
			lowest: 147.36,
			highest: 147.36,
			volumePrice: 147.3749,
			transactionCount: 46,
			voluemPrice: 147.3749
		},
		{
			open: 147.4,
			close: 147.49,
			volume: 3337,
			time: 1654515060000,
			lowest: 147.4,
			highest: 147.5,
			volumePrice: 147.4563,
			transactionCount: 81,
			voluemPrice: 147.4563
		},
		{
			open: 147.45,
			close: 147.36,
			volume: 2062,
			time: 1654515120000,
			lowest: 147.36,
			highest: 147.45,
			volumePrice: 147.3976,
			transactionCount: 54,
			voluemPrice: 147.3976
		},
		{
			open: 147.36,
			close: 147.34,
			volume: 3999,
			time: 1654515300000,
			lowest: 147.34,
			highest: 147.36,
			volumePrice: 147.3455,
			transactionCount: 68,
			voluemPrice: 147.3455
		},
		{
			open: 147.34,
			close: 147.3,
			volume: 480,
			time: 1654515360000,
			lowest: 147.3,
			highest: 147.34,
			volumePrice: 147.3166,
			transactionCount: 32,
			voluemPrice: 147.3166
		},
		{
			open: 147.3,
			close: 147.28,
			volume: 1642,
			time: 1654515420000,
			lowest: 147.28,
			highest: 147.3,
			volumePrice: 147.293,
			transactionCount: 47,
			voluemPrice: 147.293
		},
		{
			open: 147.26,
			close: 147.29,
			volume: 3796,
			time: 1654515480000,
			lowest: 147.26,
			highest: 147.29,
			volumePrice: 147.2772,
			transactionCount: 42,
			voluemPrice: 147.2772
		},
		{
			open: 147.25,
			close: 147.27,
			volume: 1088,
			time: 1654515600000,
			lowest: 147.25,
			highest: 147.27,
			volumePrice: 147.2576,
			transactionCount: 31,
			voluemPrice: 147.2576
		},
		{
			open: 147.26,
			close: 147.26,
			volume: 101,
			time: 1654515720000,
			lowest: 147.26,
			highest: 147.26,
			volumePrice: 147.2599,
			transactionCount: 2,
			voluemPrice: 147.2599
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 387,
			time: 1654515780000,
			lowest: 147.27,
			highest: 147.27,
			volumePrice: 147.2714,
			transactionCount: 23,
			voluemPrice: 147.2714
		},
		{
			open: 147.29,
			close: 147.27,
			volume: 1079,
			time: 1654515840000,
			lowest: 147.27,
			highest: 147.29,
			volumePrice: 147.285,
			transactionCount: 20,
			voluemPrice: 147.285
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 2245,
			time: 1654515960000,
			lowest: 147.27,
			highest: 147.27,
			volumePrice: 147.2701,
			transactionCount: 20,
			voluemPrice: 147.2701
		},
		{
			open: 147.27,
			close: 147.27,
			volume: 1055,
			time: 1654516020000,
			lowest: 147.27,
			highest: 147.27,
			volumePrice: 147.27,
			transactionCount: 8,
			voluemPrice: 147.27
		},
		{
			open: 147.27,
			close: 147.24,
			volume: 2940,
			time: 1654516080000,
			lowest: 147.24,
			highest: 147.27,
			volumePrice: 147.2571,
			transactionCount: 61,
			voluemPrice: 147.2571
		},
		{
			open: 147.24,
			close: 147.15,
			volume: 8922,
			time: 1654516140000,
			lowest: 147.14,
			highest: 147.24,
			volumePrice: 147.191,
			transactionCount: 157,
			voluemPrice: 147.191
		},
		{
			open: 147.15,
			close: 147.2,
			volume: 2269,
			time: 1654516200000,
			lowest: 147.15,
			highest: 147.2,
			volumePrice: 147.1822,
			transactionCount: 53,
			voluemPrice: 147.1822
		},
		{
			open: 147.18,
			close: 147.19,
			volume: 2943,
			time: 1654516260000,
			lowest: 147.15,
			highest: 147.19,
			volumePrice: 147.1746,
			transactionCount: 54,
			voluemPrice: 147.1746
		},
		{
			open: 147.2,
			close: 147.29,
			volume: 4024,
			time: 1654516320000,
			lowest: 147.15,
			highest: 147.29,
			volumePrice: 147.2253,
			transactionCount: 113,
			voluemPrice: 147.2253
		},
		{
			open: 147.28,
			close: 147.24,
			volume: 3634,
			time: 1654516380000,
			lowest: 147.24,
			highest: 147.28,
			volumePrice: 147.2676,
			transactionCount: 55,
			voluemPrice: 147.2676
		},
		{
			open: 147.25,
			close: 147.29,
			volume: 5518,
			time: 1654516440000,
			lowest: 147.25,
			highest: 147.29,
			volumePrice: 147.2706,
			transactionCount: 99,
			voluemPrice: 147.2706
		},
		{
			open: 147.35,
			close: 147.33,
			volume: 1483,
			time: 1654516500000,
			lowest: 147.33,
			highest: 147.35,
			volumePrice: 147.3347,
			transactionCount: 26,
			voluemPrice: 147.3347
		},
		{
			open: 147.33,
			close: 147.33,
			volume: 1739,
			time: 1654516560000,
			lowest: 147.33,
			highest: 147.33,
			volumePrice: 147.3268,
			transactionCount: 29,
			voluemPrice: 147.3268
		},
		{
			open: 147.35,
			close: 147.34,
			volume: 1106,
			time: 1654516620000,
			lowest: 147.34,
			highest: 147.35,
			volumePrice: 147.3473,
			transactionCount: 10,
			voluemPrice: 147.3473
		},
		{
			open: 147.35,
			close: 147.34,
			volume: 8895,
			time: 1654516680000,
			lowest: 147.34,
			highest: 147.35,
			volumePrice: 147.3497,
			transactionCount: 37,
			voluemPrice: 147.3497
		},
		{
			open: 147.35,
			close: 147.4,
			volume: 2968,
			time: 1654516740000,
			lowest: 147.35,
			highest: 147.4,
			volumePrice: 147.374,
			transactionCount: 72,
			voluemPrice: 147.374
		},
		{
			open: 147.43,
			close: 147.39,
			volume: 129415,
			time: 1654516800000,
			lowest: 147.14,
			highest: 147.56,
			volumePrice: 147.3366,
			transactionCount: 1542,
			voluemPrice: 147.3366
		},
		{
			open: 147.4,
			close: 147.47,
			volume: 5316,
			time: 1654516860000,
			lowest: 146.76,
			highest: 147.47,
			volumePrice: 147.337,
			transactionCount: 449,
			voluemPrice: 147.337
		},
		{
			open: 147.43,
			close: 147.4,
			volume: 3767,
			time: 1654516920000,
			lowest: 147.4,
			highest: 147.4496,
			volumePrice: 147.3985,
			transactionCount: 119,
			voluemPrice: 147.3985
		},
		{
			open: 147.39,
			close: 147.31,
			volume: 9184,
			time: 1654516980000,
			lowest: 147.3,
			highest: 147.39,
			volumePrice: 147.3144,
			transactionCount: 89,
			voluemPrice: 147.3144
		},
		{
			open: 147.3,
			close: 147.29,
			volume: 4339,
			time: 1654517040000,
			lowest: 147.26,
			highest: 147.35,
			volumePrice: 147.3191,
			transactionCount: 74,
			voluemPrice: 147.3191
		},
		{
			open: 147.2999,
			close: 147.3299,
			volume: 6514,
			time: 1654517100000,
			lowest: 147.28,
			highest: 147.3299,
			volumePrice: 147.3114,
			transactionCount: 60,
			voluemPrice: 147.3114
		},
		{
			open: 147.33,
			close: 147.34,
			volume: 19265,
			time: 1654517160000,
			lowest: 147.33,
			highest: 147.3875,
			volumePrice: 147.3605,
			transactionCount: 103,
			voluemPrice: 147.3605
		},
		{
			open: 147.33,
			close: 147.37,
			volume: 3040,
			time: 1654517220000,
			lowest: 147.3022,
			highest: 147.37,
			volumePrice: 147.3411,
			transactionCount: 64,
			voluemPrice: 147.3411
		},
		{
			open: 147.4,
			close: 147.5,
			volume: 11919,
			time: 1654517280000,
			lowest: 147.4,
			highest: 147.5,
			volumePrice: 147.4568,
			transactionCount: 160,
			voluemPrice: 147.4568
		},
		{
			open: 147.5,
			close: 147.5,
			volume: 34647,
			time: 1654517340000,
			lowest: 147.47,
			highest: 147.64,
			volumePrice: 147.5274,
			transactionCount: 281,
			voluemPrice: 147.5274
		},
		{
			open: 147.49,
			close: 147.47,
			volume: 40493,
			time: 1654517400000,
			lowest: 147.47,
			highest: 147.51,
			volumePrice: 147.4901,
			transactionCount: 173,
			voluemPrice: 147.4901
		},
		{
			open: 147.47,
			close: 147.47,
			volume: 4176,
			time: 1654517460000,
			lowest: 147.47,
			highest: 147.5,
			volumePrice: 147.4856,
			transactionCount: 80,
			voluemPrice: 147.4856
		},
		{
			open: 147.4599,
			close: 147.4,
			volume: 4874,
			time: 1654517520000,
			lowest: 147.4,
			highest: 147.46,
			volumePrice: 147.4516,
			transactionCount: 63,
			voluemPrice: 147.4516
		},
		{
			open: 147.4,
			close: 147.39,
			volume: 2154,
			time: 1654517580000,
			lowest: 147.37,
			highest: 147.4,
			volumePrice: 147.3796,
			transactionCount: 49,
			voluemPrice: 147.3796
		},
		{
			open: 147.4275,
			close: 147.37,
			volume: 473,
			time: 1654517640000,
			lowest: 147.37,
			highest: 147.4275,
			volumePrice: 147.3886,
			transactionCount: 19,
			voluemPrice: 147.3886
		},
		{
			open: 147.4,
			close: 147.4,
			volume: 4133,
			time: 1654517700000,
			lowest: 147.37,
			highest: 147.45,
			volumePrice: 147.3978,
			transactionCount: 53,
			voluemPrice: 147.3978
		},
		{
			open: 147.45,
			close: 147.3,
			volume: 6177,
			time: 1654517760000,
			lowest: 147.3,
			highest: 147.45,
			volumePrice: 147.3752,
			transactionCount: 127,
			voluemPrice: 147.3752
		},
		{
			open: 147.27,
			close: 147.3,
			volume: 1040,
			time: 1654517820000,
			lowest: 147.27,
			highest: 147.3,
			volumePrice: 147.2853,
			transactionCount: 43,
			voluemPrice: 147.2853
		},
		{
			open: 147.28,
			close: 147.28,
			volume: 5804,
			time: 1654517880000,
			lowest: 147.27,
			highest: 147.2997,
			volumePrice: 147.2873,
			transactionCount: 58,
			voluemPrice: 147.2873
		},
		{
			open: 147.25,
			close: 147.27,
			volume: 4660,
			time: 1654517940000,
			lowest: 147.25,
			highest: 147.28,
			volumePrice: 147.2674,
			transactionCount: 90,
			voluemPrice: 147.2674
		},
		{
			open: 147.25,
			close: 147.2399,
			volume: 3000,
			time: 1654518000000,
			lowest: 147.22,
			highest: 147.25,
			volumePrice: 147.2408,
			transactionCount: 71,
			voluemPrice: 147.2408
		},
		{
			open: 147.24,
			close: 147.19,
			volume: 10509,
			time: 1654518060000,
			lowest: 147.19,
			highest: 147.24,
			volumePrice: 147.2174,
			transactionCount: 143,
			voluemPrice: 147.2174
		},
		{
			open: 147.1899,
			close: 147.2,
			volume: 3264,
			time: 1654518120000,
			lowest: 147.1899,
			highest: 147.2,
			volumePrice: 147.195,
			transactionCount: 44,
			voluemPrice: 147.195
		},
		{
			open: 147.2,
			close: 147.29,
			volume: 847,
			time: 1654518180000,
			lowest: 147.2,
			highest: 147.29,
			volumePrice: 147.2246,
			transactionCount: 31,
			voluemPrice: 147.2246
		},
		{
			open: 147.22,
			close: 147.19,
			volume: 16944,
			time: 1654518240000,
			lowest: 147.19,
			highest: 147.2799,
			volumePrice: 147.1938,
			transactionCount: 63,
			voluemPrice: 147.1938
		},
		{
			open: 147.1601,
			close: 147.15,
			volume: 9456,
			time: 1654518300000,
			lowest: 147.15,
			highest: 147.2,
			volumePrice: 147.1617,
			transactionCount: 92,
			voluemPrice: 147.1617
		},
		{
			open: 147.18,
			close: 147.24,
			volume: 13384,
			time: 1654518360000,
			lowest: 147.1501,
			highest: 147.24,
			volumePrice: 147.221,
			transactionCount: 58,
			voluemPrice: 147.221
		},
		{
			open: 147.24,
			close: 147.2688,
			volume: 3369,
			time: 1654518420000,
			lowest: 147.23,
			highest: 147.27,
			volumePrice: 147.2396,
			transactionCount: 76,
			voluemPrice: 147.2396
		},
		{
			open: 147.27,
			close: 147.28,
			volume: 21187,
			time: 1654518480000,
			lowest: 147.27,
			highest: 147.3,
			volumePrice: 147.2858,
			transactionCount: 81,
			voluemPrice: 147.2858
		},
		{
			open: 147.29,
			close: 147.23,
			volume: 2370,
			time: 1654518540000,
			lowest: 147.23,
			highest: 147.29,
			volumePrice: 147.271,
			transactionCount: 46,
			voluemPrice: 147.271
		},
		{
			open: 147.24,
			close: 147.2501,
			volume: 1738,
			time: 1654518600000,
			lowest: 147.24,
			highest: 147.2501,
			volumePrice: 147.2454,
			transactionCount: 39,
			voluemPrice: 147.2454
		},
		{
			open: 147.25,
			close: 147.2284,
			volume: 8928,
			time: 1654518660000,
			lowest: 147.2,
			highest: 147.25,
			volumePrice: 147.2451,
			transactionCount: 70,
			voluemPrice: 147.2451
		},
		{
			open: 147.22,
			close: 147.2199,
			volume: 3849,
			time: 1654518720000,
			lowest: 147.2,
			highest: 147.22,
			volumePrice: 147.2053,
			transactionCount: 38,
			voluemPrice: 147.2053
		},
		{
			open: 147.2,
			close: 147.17,
			volume: 1172,
			time: 1654518780000,
			lowest: 147.17,
			highest: 147.2098,
			volumePrice: 147.1943,
			transactionCount: 43,
			voluemPrice: 147.1943
		},
		{
			open: 147.19,
			close: 147.22,
			volume: 5303,
			time: 1654518840000,
			lowest: 147.19,
			highest: 147.22,
			volumePrice: 147.216,
			transactionCount: 40,
			voluemPrice: 147.216
		},
		{
			open: 147.2,
			close: 147.25,
			volume: 18672,
			time: 1654518900000,
			lowest: 147.2,
			highest: 147.28,
			volumePrice: 147.2464,
			transactionCount: 75,
			voluemPrice: 147.2464
		},
		{
			open: 147.265,
			close: 147.25,
			volume: 3750,
			time: 1654518960000,
			lowest: 147.25,
			highest: 147.265,
			volumePrice: 147.2515,
			transactionCount: 48,
			voluemPrice: 147.2515
		},
		{
			open: 147.22,
			close: 147.24,
			volume: 2680,
			time: 1654519020000,
			lowest: 147.22,
			highest: 147.25,
			volumePrice: 147.2397,
			transactionCount: 48,
			voluemPrice: 147.2397
		},
		{
			open: 147.3,
			close: 147.29,
			volume: 1728,
			time: 1654519080000,
			lowest: 147.29,
			highest: 147.3,
			volumePrice: 147.2879,
			transactionCount: 69,
			voluemPrice: 147.2879
		},
		{
			open: 147.29,
			close: 147.27,
			volume: 13754,
			time: 1654519140000,
			lowest: 147.17,
			highest: 147.3,
			volumePrice: 147.2535,
			transactionCount: 193,
			voluemPrice: 147.2535
		},
		{
			open: 147.27,
			close: 147.26,
			volume: 9004,
			time: 1654519200000,
			lowest: 147.26,
			highest: 147.27,
			volumePrice: 147.2683,
			transactionCount: 64,
			voluemPrice: 147.2683
		},
		{
			open: 147.24,
			close: 147.18,
			volume: 6663,
			time: 1654519260000,
			lowest: 147.17,
			highest: 147.26,
			volumePrice: 147.216,
			transactionCount: 139,
			voluemPrice: 147.216
		},
		{
			open: 147.2,
			close: 147.17,
			volume: 9103,
			time: 1654519320000,
			lowest: 147.1501,
			highest: 147.2,
			volumePrice: 147.1666,
			transactionCount: 74,
			voluemPrice: 147.1666
		},
		{
			open: 147.1501,
			close: 147.13,
			volume: 6505,
			time: 1654519380000,
			lowest: 147.12,
			highest: 147.1501,
			volumePrice: 147.1408,
			transactionCount: 111,
			voluemPrice: 147.1408
		},
		{
			open: 147.17,
			close: 147.17,
			volume: 13857,
			time: 1654519440000,
			lowest: 147.15,
			highest: 147.17,
			volumePrice: 147.1691,
			transactionCount: 58,
			voluemPrice: 147.1691
		},
		{
			open: 147.17,
			close: 147.18,
			volume: 3871,
			time: 1654519500000,
			lowest: 147.1601,
			highest: 147.18,
			volumePrice: 147.1652,
			transactionCount: 59,
			voluemPrice: 147.1652
		},
		{
			open: 147.19,
			close: 147.3,
			volume: 36531,
			time: 1654519560000,
			lowest: 147.12,
			highest: 147.3,
			volumePrice: 147.2311,
			transactionCount: 244,
			voluemPrice: 147.2311
		},
		{
			open: 147.32,
			close: 147.18,
			volume: 36777,
			time: 1654519620000,
			lowest: 147.13,
			highest: 147.33,
			volumePrice: 147.1836,
			transactionCount: 212,
			voluemPrice: 147.1836
		},
		{
			open: 147.17,
			close: 147,
			volume: 18976,
			time: 1654519680000,
			lowest: 146.95,
			highest: 147.17,
			volumePrice: 147.041,
			transactionCount: 476,
			voluemPrice: 147.041
		},
		{
			open: 147.059,
			close: 147.09,
			volume: 4018,
			time: 1654519740000,
			lowest: 147.059,
			highest: 147.13,
			volumePrice: 147.0861,
			transactionCount: 111,
			voluemPrice: 147.0861
		},
		{
			open: 147.07,
			close: 147.13,
			volume: 2618,
			time: 1654519800000,
			lowest: 147.04,
			highest: 147.13,
			volumePrice: 147.0846,
			transactionCount: 81,
			voluemPrice: 147.0846
		},
		{
			open: 147.0901,
			close: 147.0699,
			volume: 16110,
			time: 1654519860000,
			lowest: 147.01,
			highest: 147.0901,
			volumePrice: 147.0724,
			transactionCount: 109,
			voluemPrice: 147.0724
		},
		{
			open: 147.0899,
			close: 147.048,
			volume: 13095,
			time: 1654519920000,
			lowest: 147.04,
			highest: 147.09,
			volumePrice: 147.0428,
			transactionCount: 74,
			voluemPrice: 147.0428
		},
		{
			open: 147.03,
			close: 147.05,
			volume: 1368,
			time: 1654519980000,
			lowest: 147.03,
			highest: 147.0799,
			volumePrice: 147.0558,
			transactionCount: 49,
			voluemPrice: 147.0558
		},
		{
			open: 147.05,
			close: 147.1,
			volume: 1399,
			time: 1654520040000,
			lowest: 147.05,
			highest: 147.1,
			volumePrice: 147.0832,
			transactionCount: 56,
			voluemPrice: 147.0832
		},
		{
			open: 147.1,
			close: 147.13,
			volume: 3221,
			time: 1654520100000,
			lowest: 147.1,
			highest: 147.1499,
			volumePrice: 147.1362,
			transactionCount: 75,
			voluemPrice: 147.1362
		},
		{
			open: 147.18,
			close: 147.1,
			volume: 12000,
			time: 1654520160000,
			lowest: 147.1,
			highest: 147.18,
			volumePrice: 147.1734,
			transactionCount: 65,
			voluemPrice: 147.1734
		},
		{
			open: 147.1399,
			close: 147.18,
			volume: 7891,
			time: 1654520220000,
			lowest: 147.1,
			highest: 147.18,
			volumePrice: 147.1624,
			transactionCount: 75,
			voluemPrice: 147.1624
		},
		{
			open: 147.18,
			close: 147.1,
			volume: 4438,
			time: 1654520280000,
			lowest: 147.08,
			highest: 147.18,
			volumePrice: 147.1257,
			transactionCount: 116,
			voluemPrice: 147.1257
		},
		{
			open: 147.09,
			close: 146.96,
			volume: 22331,
			time: 1654520340000,
			lowest: 146.95,
			highest: 147.09,
			volumePrice: 147.0305,
			transactionCount: 236,
			voluemPrice: 147.0305
		},
		{
			open: 146.96,
			close: 147.01,
			volume: 21381,
			time: 1654520400000,
			lowest: 146.92,
			highest: 147.01,
			volumePrice: 146.9806,
			transactionCount: 159,
			voluemPrice: 146.9806
		},
		{
			open: 146.98,
			close: 147,
			volume: 20229,
			time: 1654520460000,
			lowest: 146.98,
			highest: 147.01,
			volumePrice: 147.0036,
			transactionCount: 105,
			voluemPrice: 147.0036
		},
		{
			open: 146.96,
			close: 146.92,
			volume: 11236,
			time: 1654520520000,
			lowest: 146.92,
			highest: 147,
			volumePrice: 146.9662,
			transactionCount: 108,
			voluemPrice: 146.9662
		},
		{
			open: 146.92,
			close: 146.999,
			volume: 7748,
			time: 1654520580000,
			lowest: 146.91,
			highest: 147,
			volumePrice: 146.9639,
			transactionCount: 98,
			voluemPrice: 146.9639
		},
		{
			open: 146.98,
			close: 147.03,
			volume: 7778,
			time: 1654520640000,
			lowest: 146.98,
			highest: 147.07,
			volumePrice: 147.004,
			transactionCount: 105,
			voluemPrice: 147.004
		},
		{
			open: 147.03,
			close: 147.05,
			volume: 11290,
			time: 1654520700000,
			lowest: 147,
			highest: 147.05,
			volumePrice: 147.0289,
			transactionCount: 115,
			voluemPrice: 147.0289
		},
		{
			open: 147.06,
			close: 147.0302,
			volume: 9999,
			time: 1654520760000,
			lowest: 147.0302,
			highest: 147.1399,
			volumePrice: 147.0717,
			transactionCount: 120,
			voluemPrice: 147.0717
		},
		{
			open: 147.04,
			close: 147,
			volume: 13483,
			time: 1654520820000,
			lowest: 147,
			highest: 147.05,
			volumePrice: 147.0157,
			transactionCount: 145,
			voluemPrice: 147.0157
		},
		{
			open: 147.05,
			close: 147.05,
			volume: 7383,
			time: 1654520880000,
			lowest: 147.05,
			highest: 147.05,
			volumePrice: 147.0487,
			transactionCount: 68,
			voluemPrice: 147.0487
		},
		{
			open: 147.06,
			close: 147.1,
			volume: 12706,
			time: 1654520940000,
			lowest: 147.05,
			highest: 147.1,
			volumePrice: 147.0652,
			transactionCount: 138,
			voluemPrice: 147.0652
		},
		{
			open: 147.1,
			close: 147.11,
			volume: 2789,
			time: 1654521000000,
			lowest: 147.03,
			highest: 147.11,
			volumePrice: 147.1,
			transactionCount: 90,
			voluemPrice: 147.1
		},
		{
			open: 147.1,
			close: 147.1,
			volume: 5445,
			time: 1654521060000,
			lowest: 147.09,
			highest: 147.15,
			volumePrice: 147.1072,
			transactionCount: 90,
			voluemPrice: 147.1072
		},
		{
			open: 147.1,
			close: 147.07,
			volume: 3041,
			time: 1654521120000,
			lowest: 147.05,
			highest: 147.1,
			volumePrice: 147.0698,
			transactionCount: 72,
			voluemPrice: 147.0698
		},
		{
			open: 147.06,
			close: 147,
			volume: 12987,
			time: 1654521180000,
			lowest: 147,
			highest: 147.1,
			volumePrice: 147.049,
			transactionCount: 161,
			voluemPrice: 147.049
		},
		{
			open: 147.02,
			close: 147.03,
			volume: 2211,
			time: 1654521240000,
			lowest: 147.0023,
			highest: 147.03,
			volumePrice: 147.0152,
			transactionCount: 71,
			voluemPrice: 147.0152
		},
		{
			open: 147.04,
			close: 146.921,
			volume: 41653,
			time: 1654521300000,
			lowest: 146.92,
			highest: 147.06,
			volumePrice: 146.9942,
			transactionCount: 304,
			voluemPrice: 146.9942
		},
		{
			open: 146.9,
			close: 146.8,
			volume: 40049,
			time: 1654521360000,
			lowest: 146.75,
			highest: 146.9,
			volumePrice: 146.8158,
			transactionCount: 414,
			voluemPrice: 146.8158
		},
		{
			open: 146.92,
			close: 146.943,
			volume: 5242,
			time: 1654521420000,
			lowest: 146.88,
			highest: 147,
			volumePrice: 146.9346,
			transactionCount: 104,
			voluemPrice: 146.9346
		},
		{
			open: 146.99,
			close: 147.0099,
			volume: 14017,
			time: 1654521480000,
			lowest: 146.98,
			highest: 147.02,
			volumePrice: 146.9972,
			transactionCount: 142,
			voluemPrice: 146.9972
		},
		{
			open: 146.92,
			close: 146.94,
			volume: 16396,
			time: 1654521540000,
			lowest: 146.92,
			highest: 146.99,
			volumePrice: 146.9634,
			transactionCount: 106,
			voluemPrice: 146.9634
		},
		{
			open: 146.94,
			close: 146.99,
			volume: 2229,
			time: 1654521600000,
			lowest: 146.94,
			highest: 146.99,
			volumePrice: 146.9678,
			transactionCount: 84,
			voluemPrice: 146.9678
		},
		{
			open: 146.98,
			close: 146.87,
			volume: 36897,
			time: 1654521660000,
			lowest: 146.8699,
			highest: 147.03,
			volumePrice: 146.9583,
			transactionCount: 231,
			voluemPrice: 146.9583
		},
		{
			open: 146.8699,
			close: 146.87,
			volume: 29530,
			time: 1654521720000,
			lowest: 146.81,
			highest: 146.87,
			volumePrice: 146.8556,
			transactionCount: 230,
			voluemPrice: 146.8556
		},
		{
			open: 146.86,
			close: 146.93,
			volume: 15787,
			time: 1654521780000,
			lowest: 146.82,
			highest: 146.93,
			volumePrice: 146.8587,
			transactionCount: 176,
			voluemPrice: 146.8587
		},
		{
			open: 146.94,
			close: 146.99,
			volume: 28288,
			time: 1654521840000,
			lowest: 146.811,
			highest: 146.99,
			volumePrice: 146.89,
			transactionCount: 118,
			voluemPrice: 146.89
		},
		{
			open: 146.98,
			close: 146.94,
			volume: 7091,
			time: 1654521900000,
			lowest: 146.87,
			highest: 146.98,
			volumePrice: 146.9227,
			transactionCount: 116,
			voluemPrice: 146.9227
		},
		{
			open: 146.93,
			close: 146.85,
			volume: 14419,
			time: 1654521960000,
			lowest: 146.85,
			highest: 146.94,
			volumePrice: 146.9151,
			transactionCount: 145,
			voluemPrice: 146.9151
		},
		{
			open: 146.86,
			close: 146.95,
			volume: 4634,
			time: 1654522020000,
			lowest: 146.8,
			highest: 146.99,
			volumePrice: 146.8967,
			transactionCount: 157,
			voluemPrice: 146.8967
		},
		{
			open: 146.9,
			close: 147,
			volume: 5388,
			time: 1654522080000,
			lowest: 146.9,
			highest: 147,
			volumePrice: 146.9769,
			transactionCount: 94,
			voluemPrice: 146.9769
		},
		{
			open: 147,
			close: 146.95,
			volume: 9733,
			time: 1654522140000,
			lowest: 146.9,
			highest: 147,
			volumePrice: 146.9383,
			transactionCount: 163,
			voluemPrice: 146.9383
		},
		{
			open: 147.03,
			close: 147.94,
			volume: 1314253,
			time: 1654522200000,
			lowest: 147.03,
			highest: 147.94,
			volumePrice: 147.2971,
			transactionCount: 15038,
			voluemPrice: 147.2971
		},
		{
			open: 147.94,
			close: 147.79,
			volume: 571846,
			time: 1654522260000,
			lowest: 147.73,
			highest: 148.11,
			volumePrice: 147.9455,
			transactionCount: 7722,
			voluemPrice: 147.9455
		},
		{
			open: 147.79,
			close: 147.86,
			volume: 384788,
			time: 1654522320000,
			lowest: 147.66,
			highest: 147.98,
			volumePrice: 147.8251,
			transactionCount: 5289,
			voluemPrice: 147.8251
		},
		{
			open: 147.9,
			close: 148.0193,
			volume: 513032,
			time: 1654522380000,
			lowest: 147.79,
			highest: 148.13,
			volumePrice: 148.0077,
			transactionCount: 4620,
			voluemPrice: 148.0077
		},
		{
			open: 148.01,
			close: 147.84,
			volume: 379846,
			time: 1654522440000,
			lowest: 147.77,
			highest: 148.1266,
			volumePrice: 148.0057,
			transactionCount: 3166,
			voluemPrice: 148.0057
		},
		{
			open: 147.85,
			close: 147.77,
			volume: 304997,
			time: 1654522500000,
			lowest: 147.68,
			highest: 148.02,
			volumePrice: 147.842,
			transactionCount: 3394,
			voluemPrice: 147.842
		},
		{
			open: 147.78,
			close: 147.4,
			volume: 417618,
			time: 1654522560000,
			lowest: 147.36,
			highest: 147.84,
			volumePrice: 147.5864,
			transactionCount: 3884,
			voluemPrice: 147.5864
		},
		{
			open: 147.42,
			close: 147.5336,
			volume: 282866,
			time: 1654522620000,
			lowest: 147.378,
			highest: 147.61,
			volumePrice: 147.5225,
			transactionCount: 2766,
			voluemPrice: 147.5225
		},
		{
			open: 147.52,
			close: 147.58,
			volume: 306272,
			time: 1654522680000,
			lowest: 147.43,
			highest: 147.77,
			volumePrice: 147.6138,
			transactionCount: 2843,
			voluemPrice: 147.6138
		},
		{
			open: 147.58,
			close: 147.7017,
			volume: 307910,
			time: 1654522740000,
			lowest: 147.38,
			highest: 147.72,
			volumePrice: 147.5431,
			transactionCount: 2731,
			voluemPrice: 147.5431
		},
		{
			open: 147.7,
			close: 147.6,
			volume: 330414,
			time: 1654522800000,
			lowest: 147.57,
			highest: 147.8,
			volumePrice: 147.6884,
			transactionCount: 3061,
			voluemPrice: 147.6884
		},
		{
			open: 147.6,
			close: 147.955,
			volume: 445752,
			time: 1654522860000,
			lowest: 147.57,
			highest: 148.02,
			volumePrice: 147.8549,
			transactionCount: 3517,
			voluemPrice: 147.8549
		},
		{
			open: 147.95,
			close: 148.14,
			volume: 578684,
			time: 1654522920000,
			lowest: 147.91,
			highest: 148.31,
			volumePrice: 148.1432,
			transactionCount: 4791,
			voluemPrice: 148.1432
		},
		{
			open: 148.14,
			close: 148.21,
			volume: 266096,
			time: 1654522980000,
			lowest: 148.0119,
			highest: 148.25,
			volumePrice: 148.1287,
			transactionCount: 2345,
			voluemPrice: 148.1287
		},
		{
			open: 148.19,
			close: 148.015,
			volume: 188036,
			time: 1654523040000,
			lowest: 148.01,
			highest: 148.24,
			volumePrice: 148.0939,
			transactionCount: 1878,
			voluemPrice: 148.0939
		},
		{
			open: 148.03,
			close: 147.81,
			volume: 295518,
			time: 1654523100000,
			lowest: 147.751,
			highest: 148.09,
			volumePrice: 147.9258,
			transactionCount: 2728,
			voluemPrice: 147.9258
		},
		{
			open: 147.815,
			close: 147.57,
			volume: 304839,
			time: 1654523160000,
			lowest: 147.5099,
			highest: 147.82,
			volumePrice: 147.6407,
			transactionCount: 2930,
			voluemPrice: 147.6407
		},
		{
			open: 147.5661,
			close: 147.605,
			volume: 202496,
			time: 1654523220000,
			lowest: 147.55,
			highest: 147.77,
			volumePrice: 147.646,
			transactionCount: 2087,
			voluemPrice: 147.646
		},
		{
			open: 147.6,
			close: 147.27,
			volume: 292727,
			time: 1654523280000,
			lowest: 147.15,
			highest: 147.66,
			volumePrice: 147.3785,
			transactionCount: 2776,
			voluemPrice: 147.3785
		},
		{
			open: 147.26,
			close: 147.4007,
			volume: 217801,
			time: 1654523340000,
			lowest: 147.17,
			highest: 147.47,
			volumePrice: 147.3177,
			transactionCount: 1892,
			voluemPrice: 147.3177
		},
		{
			open: 147.41,
			close: 147.3,
			volume: 160706,
			time: 1654523400000,
			lowest: 147.23,
			highest: 147.47,
			volumePrice: 147.3275,
			transactionCount: 1727,
			voluemPrice: 147.3275
		},
		{
			open: 147.31,
			close: 147.3537,
			volume: 194782,
			time: 1654523460000,
			lowest: 147.22,
			highest: 147.46,
			volumePrice: 147.3443,
			transactionCount: 1696,
			voluemPrice: 147.3443
		},
		{
			open: 147.34,
			close: 147.36,
			volume: 215362,
			time: 1654523520000,
			lowest: 147.27,
			highest: 147.58,
			volumePrice: 147.4097,
			transactionCount: 2208,
			voluemPrice: 147.4097
		},
		{
			open: 147.37,
			close: 147.6201,
			volume: 180775,
			time: 1654523580000,
			lowest: 147.271,
			highest: 147.65,
			volumePrice: 147.4351,
			transactionCount: 1813,
			voluemPrice: 147.4351
		},
		{
			open: 147.63,
			close: 147.515,
			volume: 231246,
			time: 1654523640000,
			lowest: 147.3801,
			highest: 147.7,
			volumePrice: 147.543,
			transactionCount: 2009,
			voluemPrice: 147.543
		},
		{
			open: 147.51,
			close: 147.565,
			volume: 146384,
			time: 1654523700000,
			lowest: 147.3805,
			highest: 147.57,
			volumePrice: 147.4657,
			transactionCount: 1549,
			voluemPrice: 147.4657
		},
		{
			open: 147.5635,
			close: 147.4,
			volume: 204715,
			time: 1654523760000,
			lowest: 147.19,
			highest: 147.57,
			volumePrice: 147.3835,
			transactionCount: 2022,
			voluemPrice: 147.3835
		},
		{
			open: 147.38,
			close: 147.34,
			volume: 134506,
			time: 1654523820000,
			lowest: 147.29,
			highest: 147.45,
			volumePrice: 147.3673,
			transactionCount: 1400,
			voluemPrice: 147.3673
		},
		{
			open: 147.36,
			close: 147.22,
			volume: 203701,
			time: 1654523880000,
			lowest: 147.19,
			highest: 147.45,
			volumePrice: 147.2905,
			transactionCount: 1775,
			voluemPrice: 147.2905
		},
		{
			open: 147.215,
			close: 147.83,
			volume: 420719,
			time: 1654523940000,
			lowest: 147.192,
			highest: 147.85,
			volumePrice: 147.5862,
			transactionCount: 3572,
			voluemPrice: 147.5862
		},
		{
			open: 147.83,
			close: 148.19,
			volume: 401715,
			time: 1654524000000,
			lowest: 147.75,
			highest: 148.1942,
			volumePrice: 147.9699,
			transactionCount: 3505,
			voluemPrice: 147.9699
		},
		{
			open: 148.1912,
			close: 148.029,
			volume: 226005,
			time: 1654524060000,
			lowest: 147.984,
			highest: 148.2014,
			volumePrice: 148.1206,
			transactionCount: 2412,
			voluemPrice: 148.1206
		},
		{
			open: 148.0299,
			close: 148.075,
			volume: 177634,
			time: 1654524120000,
			lowest: 147.9895,
			highest: 148.135,
			volumePrice: 148.0647,
			transactionCount: 1781,
			voluemPrice: 148.0647
		},
		{
			open: 148.08,
			close: 148.1192,
			volume: 182749,
			time: 1654524180000,
			lowest: 148.075,
			highest: 148.21,
			volumePrice: 148.1507,
			transactionCount: 1760,
			voluemPrice: 148.1507
		},
		{
			open: 148.13,
			close: 148.035,
			volume: 151241,
			time: 1654524240000,
			lowest: 148.03,
			highest: 148.16,
			volumePrice: 148.0931,
			transactionCount: 1531,
			voluemPrice: 148.0931
		},
		{
			open: 148.03,
			close: 148.09,
			volume: 205355,
			time: 1654524300000,
			lowest: 147.92,
			highest: 148.13,
			volumePrice: 148.038,
			transactionCount: 1926,
			voluemPrice: 148.038
		},
		{
			open: 148.1,
			close: 148.26,
			volume: 337794,
			time: 1654524360000,
			lowest: 148.06,
			highest: 148.42,
			volumePrice: 148.2797,
			transactionCount: 2870,
			voluemPrice: 148.2797
		},
		{
			open: 148.26,
			close: 148.09,
			volume: 162323,
			time: 1654524420000,
			lowest: 148.0452,
			highest: 148.2899,
			volumePrice: 148.1527,
			transactionCount: 1667,
			voluemPrice: 148.1527
		},
		{
			open: 148.09,
			close: 148.02,
			volume: 173357,
			time: 1654524480000,
			lowest: 147.97,
			highest: 148.12,
			volumePrice: 148.0359,
			transactionCount: 1751,
			voluemPrice: 148.0359
		},
		{
			open: 148.02,
			close: 148.045,
			volume: 181470,
			time: 1654524540000,
			lowest: 147.99,
			highest: 148.15,
			volumePrice: 148.0666,
			transactionCount: 1566,
			voluemPrice: 148.0666
		},
		{
			open: 148.05,
			close: 147.9999,
			volume: 148382,
			time: 1654524600000,
			lowest: 147.94,
			highest: 148.1,
			volumePrice: 148.0284,
			transactionCount: 1662,
			voluemPrice: 148.0284
		},
		{
			open: 147.99,
			close: 148.191,
			volume: 231394,
			time: 1654524660000,
			lowest: 147.88,
			highest: 148.22,
			volumePrice: 148.0421,
			transactionCount: 2237,
			voluemPrice: 148.0421
		},
		{
			open: 148.1899,
			close: 148.1796,
			volume: 171765,
			time: 1654524720000,
			lowest: 148.05,
			highest: 148.21,
			volumePrice: 148.1459,
			transactionCount: 1641,
			voluemPrice: 148.1459
		},
		{
			open: 148.19,
			close: 148.17,
			volume: 211335,
			time: 1654524780000,
			lowest: 148.08,
			highest: 148.27,
			volumePrice: 148.1848,
			transactionCount: 1791,
			voluemPrice: 148.1848
		},
		{
			open: 148.165,
			close: 148.37,
			volume: 284806,
			time: 1654524840000,
			lowest: 148.16,
			highest: 148.38,
			volumePrice: 148.2667,
			transactionCount: 2476,
			voluemPrice: 148.2667
		},
		{
			open: 148.37,
			close: 148.2697,
			volume: 323926,
			time: 1654524900000,
			lowest: 148.21,
			highest: 148.44,
			volumePrice: 148.344,
			transactionCount: 2863,
			voluemPrice: 148.344
		},
		{
			open: 148.2601,
			close: 148.2577,
			volume: 289969,
			time: 1654524960000,
			lowest: 147.98,
			highest: 148.2601,
			volumePrice: 148.1206,
			transactionCount: 2928,
			voluemPrice: 148.1206
		},
		{
			open: 148.28,
			close: 148.12,
			volume: 178065,
			time: 1654525020000,
			lowest: 148.1,
			highest: 148.36,
			volumePrice: 148.2324,
			transactionCount: 1618,
			voluemPrice: 148.2324
		},
		{
			open: 148.11,
			close: 148.1,
			volume: 168546,
			time: 1654525080000,
			lowest: 147.97,
			highest: 148.12,
			volumePrice: 148.0527,
			transactionCount: 1551,
			voluemPrice: 148.0527
		},
		{
			open: 148.11,
			close: 148.11,
			volume: 95939,
			time: 1654525140000,
			lowest: 148.02,
			highest: 148.15,
			volumePrice: 148.0948,
			transactionCount: 1074,
			voluemPrice: 148.0948
		},
		{
			open: 148.12,
			close: 148.13,
			volume: 136202,
			time: 1654525200000,
			lowest: 148.04,
			highest: 148.18,
			volumePrice: 148.1142,
			transactionCount: 1348,
			voluemPrice: 148.1142
		},
		{
			open: 148.13,
			close: 148.03,
			volume: 213445,
			time: 1654525260000,
			lowest: 147.955,
			highest: 148.18,
			volumePrice: 148.0756,
			transactionCount: 1652,
			voluemPrice: 148.0756
		},
		{
			open: 148.04,
			close: 148.15,
			volume: 95125,
			time: 1654525320000,
			lowest: 147.99,
			highest: 148.16,
			volumePrice: 148.0664,
			transactionCount: 1126,
			voluemPrice: 148.0664
		},
		{
			open: 148.16,
			close: 148.04,
			volume: 134670,
			time: 1654525380000,
			lowest: 148.03,
			highest: 148.24,
			volumePrice: 148.1452,
			transactionCount: 1303,
			voluemPrice: 148.1452
		},
		{
			open: 148.0399,
			close: 148.05,
			volume: 97319,
			time: 1654525440000,
			lowest: 148.01,
			highest: 148.12,
			volumePrice: 148.068,
			transactionCount: 1048,
			voluemPrice: 148.068
		},
		{
			open: 148.04,
			close: 148.06,
			volume: 142798,
			time: 1654525500000,
			lowest: 147.91,
			highest: 148.06,
			volumePrice: 147.9932,
			transactionCount: 1519,
			voluemPrice: 147.9932
		},
		{
			open: 148.06,
			close: 148.226,
			volume: 185822,
			time: 1654525560000,
			lowest: 147.94,
			highest: 148.226,
			volumePrice: 148.1062,
			transactionCount: 1661,
			voluemPrice: 148.1062
		},
		{
			open: 148.22,
			close: 148.36,
			volume: 162656,
			time: 1654525620000,
			lowest: 148.19,
			highest: 148.4,
			volumePrice: 148.2974,
			transactionCount: 1645,
			voluemPrice: 148.2974
		},
		{
			open: 148.34,
			close: 148.26,
			volume: 110610,
			time: 1654525680000,
			lowest: 148.23,
			highest: 148.355,
			volumePrice: 148.2717,
			transactionCount: 1127,
			voluemPrice: 148.2717
		},
		{
			open: 148.255,
			close: 148.21,
			volume: 134727,
			time: 1654525740000,
			lowest: 148.21,
			highest: 148.32,
			volumePrice: 148.2621,
			transactionCount: 1260,
			voluemPrice: 148.2621
		},
		{
			open: 148.21,
			close: 148.0718,
			volume: 186241,
			time: 1654525800000,
			lowest: 148.06,
			highest: 148.35,
			volumePrice: 148.2337,
			transactionCount: 1813,
			voluemPrice: 148.2337
		},
		{
			open: 148.07,
			close: 148.17,
			volume: 114984,
			time: 1654525860000,
			lowest: 148.07,
			highest: 148.2396,
			volumePrice: 148.1592,
			transactionCount: 1175,
			voluemPrice: 148.1592
		},
		{
			open: 148.17,
			close: 148.3481,
			volume: 109330,
			time: 1654525920000,
			lowest: 148.15,
			highest: 148.36,
			volumePrice: 148.2464,
			transactionCount: 1182,
			voluemPrice: 148.2464
		},
		{
			open: 148.34,
			close: 148.405,
			volume: 209469,
			time: 1654525980000,
			lowest: 148.26,
			highest: 148.43,
			volumePrice: 148.3607,
			transactionCount: 1882,
			voluemPrice: 148.3607
		},
		{
			open: 148.4,
			close: 148.2902,
			volume: 206898,
			time: 1654526040000,
			lowest: 148.29,
			highest: 148.48,
			volumePrice: 148.4105,
			transactionCount: 1972,
			voluemPrice: 148.4105
		},
		{
			open: 148.295,
			close: 148.33,
			volume: 130056,
			time: 1654526100000,
			lowest: 148.26,
			highest: 148.4,
			volumePrice: 148.3423,
			transactionCount: 1386,
			voluemPrice: 148.3423
		},
		{
			open: 148.32,
			close: 148.3,
			volume: 135790,
			time: 1654526160000,
			lowest: 148.22,
			highest: 148.39,
			volumePrice: 148.292,
			transactionCount: 1349,
			voluemPrice: 148.292
		},
		{
			open: 148.3,
			close: 148.27,
			volume: 117897,
			time: 1654526220000,
			lowest: 148.24,
			highest: 148.36,
			volumePrice: 148.3131,
			transactionCount: 1127,
			voluemPrice: 148.3131
		},
		{
			open: 148.2699,
			close: 148.3241,
			volume: 105836,
			time: 1654526280000,
			lowest: 148.19,
			highest: 148.3284,
			volumePrice: 148.2394,
			transactionCount: 1124,
			voluemPrice: 148.2394
		},
		{
			open: 148.325,
			close: 148.4451,
			volume: 106135,
			time: 1654526340000,
			lowest: 148.304,
			highest: 148.45,
			volumePrice: 148.3815,
			transactionCount: 1230,
			voluemPrice: 148.3815
		},
		{
			open: 148.45,
			close: 148.445,
			volume: 169837,
			time: 1654526400000,
			lowest: 148.41,
			highest: 148.5,
			volumePrice: 148.4648,
			transactionCount: 1466,
			voluemPrice: 148.4648
		},
		{
			open: 148.45,
			close: 148.525,
			volume: 213003,
			time: 1654526460000,
			lowest: 148.44,
			highest: 148.5689,
			volumePrice: 148.5056,
			transactionCount: 1815,
			voluemPrice: 148.5056
		},
		{
			open: 148.521,
			close: 148.2931,
			volume: 157934,
			time: 1654526520000,
			lowest: 148.2699,
			highest: 148.54,
			volumePrice: 148.3889,
			transactionCount: 1435,
			voluemPrice: 148.3889
		},
		{
			open: 148.29,
			close: 148.23,
			volume: 164243,
			time: 1654526580000,
			lowest: 148.07,
			highest: 148.29,
			volumePrice: 148.1799,
			transactionCount: 1688,
			voluemPrice: 148.1799
		},
		{
			open: 148.2205,
			close: 148.46,
			volume: 175168,
			time: 1654526640000,
			lowest: 148.2205,
			highest: 148.47,
			volumePrice: 148.3466,
			transactionCount: 1604,
			voluemPrice: 148.3466
		},
		{
			open: 148.47,
			close: 148.42,
			volume: 114350,
			time: 1654526700000,
			lowest: 148.3599,
			highest: 148.49,
			volumePrice: 148.4313,
			transactionCount: 1166,
			voluemPrice: 148.4313
		},
		{
			open: 148.4101,
			close: 148.36,
			volume: 98411,
			time: 1654526760000,
			lowest: 148.285,
			highest: 148.45,
			volumePrice: 148.3607,
			transactionCount: 1016,
			voluemPrice: 148.3607
		},
		{
			open: 148.355,
			close: 148.37,
			volume: 93077,
			time: 1654526820000,
			lowest: 148.3,
			highest: 148.45,
			volumePrice: 148.3744,
			transactionCount: 968,
			voluemPrice: 148.3744
		},
		{
			open: 148.37,
			close: 148.34,
			volume: 107393,
			time: 1654526880000,
			lowest: 148.25,
			highest: 148.37,
			volumePrice: 148.3209,
			transactionCount: 1204,
			voluemPrice: 148.3209
		},
		{
			open: 148.345,
			close: 148.38,
			volume: 109049,
			time: 1654526940000,
			lowest: 148.32,
			highest: 148.41,
			volumePrice: 148.3712,
			transactionCount: 1120,
			voluemPrice: 148.3712
		},
		{
			open: 148.38,
			close: 148.24,
			volume: 102091,
			time: 1654527000000,
			lowest: 148.24,
			highest: 148.4,
			volumePrice: 148.3201,
			transactionCount: 1066,
			voluemPrice: 148.3201
		},
		{
			open: 148.235,
			close: 148.4157,
			volume: 133985,
			time: 1654527060000,
			lowest: 148.1855,
			highest: 148.425,
			volumePrice: 148.3052,
			transactionCount: 1363,
			voluemPrice: 148.3052
		},
		{
			open: 148.42,
			close: 148.3599,
			volume: 169890,
			time: 1654527120000,
			lowest: 148.35,
			highest: 148.5,
			volumePrice: 148.4383,
			transactionCount: 1338,
			voluemPrice: 148.4383
		},
		{
			open: 148.36,
			close: 148.2213,
			volume: 152303,
			time: 1654527180000,
			lowest: 148.19,
			highest: 148.36,
			volumePrice: 148.2656,
			transactionCount: 1309,
			voluemPrice: 148.2656
		},
		{
			open: 148.22,
			close: 148.14,
			volume: 107265,
			time: 1654527240000,
			lowest: 148.1,
			highest: 148.22,
			volumePrice: 148.1584,
			transactionCount: 1218,
			voluemPrice: 148.1584
		},
		{
			open: 148.15,
			close: 148.085,
			volume: 138113,
			time: 1654527300000,
			lowest: 148.05,
			highest: 148.18,
			volumePrice: 148.1198,
			transactionCount: 1406,
			voluemPrice: 148.1198
		},
		{
			open: 148.08,
			close: 148.065,
			volume: 130636,
			time: 1654527360000,
			lowest: 148,
			highest: 148.1,
			volumePrice: 148.0453,
			transactionCount: 1280,
			voluemPrice: 148.0453
		},
		{
			open: 148.07,
			close: 148.06,
			volume: 293225,
			time: 1654527420000,
			lowest: 148.01,
			highest: 148.15,
			volumePrice: 148.0635,
			transactionCount: 2008,
			voluemPrice: 148.0635
		},
		{
			open: 148.06,
			close: 148.0691,
			volume: 124657,
			time: 1654527480000,
			lowest: 148.01,
			highest: 148.17,
			volumePrice: 148.1054,
			transactionCount: 1276,
			voluemPrice: 148.1054
		},
		{
			open: 148.07,
			close: 148.17,
			volume: 128667,
			time: 1654527540000,
			lowest: 148.03,
			highest: 148.2,
			volumePrice: 148.1027,
			transactionCount: 1245,
			voluemPrice: 148.1027
		},
		{
			open: 148.18,
			close: 148.25,
			volume: 140888,
			time: 1654527600000,
			lowest: 148.11,
			highest: 148.25,
			volumePrice: 148.1904,
			transactionCount: 1268,
			voluemPrice: 148.1904
		},
		{
			open: 148.251,
			close: 148.285,
			volume: 117675,
			time: 1654527660000,
			lowest: 148.14,
			highest: 148.2899,
			volumePrice: 148.2106,
			transactionCount: 1172,
			voluemPrice: 148.2106
		},
		{
			open: 148.2835,
			close: 148.21,
			volume: 144731,
			time: 1654527720000,
			lowest: 148.17,
			highest: 148.3385,
			volumePrice: 148.2493,
			transactionCount: 1413,
			voluemPrice: 148.2493
		},
		{
			open: 148.22,
			close: 148.11,
			volume: 206863,
			time: 1654527780000,
			lowest: 148.11,
			highest: 148.265,
			volumePrice: 148.1869,
			transactionCount: 1280,
			voluemPrice: 148.1869
		},
		{
			open: 148.1,
			close: 147.9601,
			volume: 223971,
			time: 1654527840000,
			lowest: 147.935,
			highest: 148.1385,
			volumePrice: 148.023,
			transactionCount: 2120,
			voluemPrice: 148.023
		},
		{
			open: 147.95,
			close: 147.9459,
			volume: 243296,
			time: 1654527900000,
			lowest: 147.87,
			highest: 148.0247,
			volumePrice: 147.9541,
			transactionCount: 2007,
			voluemPrice: 147.9541
		},
		{
			open: 147.96,
			close: 147.915,
			volume: 129208,
			time: 1654527960000,
			lowest: 147.9,
			highest: 148.02,
			volumePrice: 147.9751,
			transactionCount: 1275,
			voluemPrice: 147.9751
		},
		{
			open: 147.9122,
			close: 147.94,
			volume: 111787,
			time: 1654528020000,
			lowest: 147.84,
			highest: 148.01,
			volumePrice: 147.9206,
			transactionCount: 1289,
			voluemPrice: 147.9206
		},
		{
			open: 147.94,
			close: 147.78,
			volume: 173865,
			time: 1654528080000,
			lowest: 147.73,
			highest: 147.96,
			volumePrice: 147.8137,
			transactionCount: 1668,
			voluemPrice: 147.8137
		},
		{
			open: 147.79,
			close: 147.5399,
			volume: 321468,
			time: 1654528140000,
			lowest: 147.475,
			highest: 147.8001,
			volumePrice: 147.5968,
			transactionCount: 2688,
			voluemPrice: 147.5968
		},
		{
			open: 147.53,
			close: 147.5024,
			volume: 172109,
			time: 1654528200000,
			lowest: 147.42,
			highest: 147.55,
			volumePrice: 147.4894,
			transactionCount: 1668,
			voluemPrice: 147.4894
		},
		{
			open: 147.5,
			close: 147.33,
			volume: 153682,
			time: 1654528260000,
			lowest: 147.31,
			highest: 147.51,
			volumePrice: 147.3894,
			transactionCount: 1453,
			voluemPrice: 147.3894
		},
		{
			open: 147.32,
			close: 147.1575,
			volume: 223700,
			time: 1654528320000,
			lowest: 147.1249,
			highest: 147.32,
			volumePrice: 147.1962,
			transactionCount: 2019,
			voluemPrice: 147.1962
		},
		{
			open: 147.16,
			close: 147.111,
			volume: 198125,
			time: 1654528380000,
			lowest: 147.04,
			highest: 147.16,
			volumePrice: 147.1028,
			transactionCount: 1745,
			voluemPrice: 147.1028
		},
		{
			open: 147.11,
			close: 147.04,
			volume: 268465,
			time: 1654528440000,
			lowest: 146.98,
			highest: 147.14,
			volumePrice: 147.0326,
			transactionCount: 2964,
			voluemPrice: 147.0326
		},
		{
			open: 147.03,
			close: 147.04,
			volume: 140167,
			time: 1654528500000,
			lowest: 146.9881,
			highest: 147.1,
			volumePrice: 147.0461,
			transactionCount: 1528,
			voluemPrice: 147.0461
		},
		{
			open: 147.05,
			close: 146.75,
			volume: 301333,
			time: 1654528560000,
			lowest: 146.69,
			highest: 147.06,
			volumePrice: 146.8566,
			transactionCount: 2839,
			voluemPrice: 146.8566
		},
		{
			open: 146.76,
			close: 146.5175,
			volume: 343323,
			time: 1654528620000,
			lowest: 146.39,
			highest: 146.76,
			volumePrice: 146.5459,
			transactionCount: 2821,
			voluemPrice: 146.5459
		},
		{
			open: 146.52,
			close: 146.5,
			volume: 197828,
			time: 1654528680000,
			lowest: 146.445,
			highest: 146.58,
			volumePrice: 146.5242,
			transactionCount: 1912,
			voluemPrice: 146.5242
		},
		{
			open: 146.5,
			close: 146.1988,
			volume: 281122,
			time: 1654528740000,
			lowest: 146.16,
			highest: 146.55,
			volumePrice: 146.3559,
			transactionCount: 2821,
			voluemPrice: 146.3559
		},
		{
			open: 146.195,
			close: 146.27,
			volume: 299511,
			time: 1654528800000,
			lowest: 146.15,
			highest: 146.3,
			volumePrice: 146.2269,
			transactionCount: 2468,
			voluemPrice: 146.2269
		},
		{
			open: 146.27,
			close: 146.165,
			volume: 294272,
			time: 1654528860000,
			lowest: 146.15,
			highest: 146.34,
			volumePrice: 146.2387,
			transactionCount: 2526,
			voluemPrice: 146.2387
		},
		{
			open: 146.16,
			close: 146.015,
			volume: 275191,
			time: 1654528920000,
			lowest: 146.01,
			highest: 146.185,
			volumePrice: 146.1073,
			transactionCount: 2482,
			voluemPrice: 146.1073
		},
		{
			open: 146.02,
			close: 146.27,
			volume: 278511,
			time: 1654528980000,
			lowest: 146.01,
			highest: 146.27,
			volumePrice: 146.1648,
			transactionCount: 2299,
			voluemPrice: 146.1648
		},
		{
			open: 146.265,
			close: 146.27,
			volume: 363571,
			time: 1654529040000,
			lowest: 146.21,
			highest: 146.37,
			volumePrice: 146.3029,
			transactionCount: 2964,
			voluemPrice: 146.3029
		},
		{
			open: 146.26,
			close: 146.29,
			volume: 206790,
			time: 1654529100000,
			lowest: 146.14,
			highest: 146.38,
			volumePrice: 146.2699,
			transactionCount: 1733,
			voluemPrice: 146.2699
		},
		{
			open: 146.285,
			close: 146.44,
			volume: 190374,
			time: 1654529160000,
			lowest: 146.285,
			highest: 146.47,
			volumePrice: 146.3985,
			transactionCount: 1539,
			voluemPrice: 146.3985
		},
		{
			open: 146.44,
			close: 146.4879,
			volume: 161496,
			time: 1654529220000,
			lowest: 146.32,
			highest: 146.49,
			volumePrice: 146.4227,
			transactionCount: 1469,
			voluemPrice: 146.4227
		},
		{
			open: 146.49,
			close: 146.545,
			volume: 190122,
			time: 1654529280000,
			lowest: 146.44,
			highest: 146.555,
			volumePrice: 146.5018,
			transactionCount: 1646,
			voluemPrice: 146.5018
		},
		{
			open: 146.5401,
			close: 146.5591,
			volume: 277823,
			time: 1654529340000,
			lowest: 146.46,
			highest: 146.67,
			volumePrice: 146.5513,
			transactionCount: 2102,
			voluemPrice: 146.5513
		},
		{
			open: 146.56,
			close: 146.41,
			volume: 141647,
			time: 1654529400000,
			lowest: 146.4,
			highest: 146.6199,
			volumePrice: 146.4941,
			transactionCount: 1400,
			voluemPrice: 146.4941
		},
		{
			open: 146.4028,
			close: 146.5,
			volume: 267274,
			time: 1654529460000,
			lowest: 146.3,
			highest: 146.59,
			volumePrice: 146.4223,
			transactionCount: 2189,
			voluemPrice: 146.4223
		},
		{
			open: 146.51,
			close: 146.37,
			volume: 119035,
			time: 1654529520000,
			lowest: 146.31,
			highest: 146.539,
			volumePrice: 146.3968,
			transactionCount: 1154,
			voluemPrice: 146.3968
		},
		{
			open: 146.37,
			close: 146.3454,
			volume: 69184,
			time: 1654529580000,
			lowest: 146.33,
			highest: 146.42,
			volumePrice: 146.3791,
			transactionCount: 927,
			voluemPrice: 146.3791
		},
		{
			open: 146.3377,
			close: 146.28,
			volume: 146080,
			time: 1654529640000,
			lowest: 146.2,
			highest: 146.345,
			volumePrice: 146.2717,
			transactionCount: 1373,
			voluemPrice: 146.2717
		},
		{
			open: 146.28,
			close: 146.32,
			volume: 160593,
			time: 1654529700000,
			lowest: 146.14,
			highest: 146.35,
			volumePrice: 146.2438,
			transactionCount: 1452,
			voluemPrice: 146.2438
		},
		{
			open: 146.31,
			close: 146.2301,
			volume: 139459,
			time: 1654529760000,
			lowest: 146.18,
			highest: 146.4,
			volumePrice: 146.2989,
			transactionCount: 1256,
			voluemPrice: 146.2989
		},
		{
			open: 146.24,
			close: 146.1691,
			volume: 141207,
			time: 1654529820000,
			lowest: 146.12,
			highest: 146.26,
			volumePrice: 146.1841,
			transactionCount: 1201,
			voluemPrice: 146.1841
		},
		{
			open: 146.17,
			close: 146.385,
			volume: 108241,
			time: 1654529880000,
			lowest: 146.17,
			highest: 146.4,
			volumePrice: 146.3015,
			transactionCount: 1034,
			voluemPrice: 146.3015
		},
		{
			open: 146.3889,
			close: 146.27,
			volume: 131338,
			time: 1654529940000,
			lowest: 146.26,
			highest: 146.46,
			volumePrice: 146.3638,
			transactionCount: 1314,
			voluemPrice: 146.3638
		},
		{
			open: 146.28,
			close: 146.41,
			volume: 111935,
			time: 1654530000000,
			lowest: 146.2554,
			highest: 146.46,
			volumePrice: 146.3782,
			transactionCount: 980,
			voluemPrice: 146.3782
		},
		{
			open: 146.405,
			close: 146.42,
			volume: 75226,
			time: 1654530060000,
			lowest: 146.345,
			highest: 146.4599,
			volumePrice: 146.4091,
			transactionCount: 823,
			voluemPrice: 146.4091
		},
		{
			open: 146.43,
			close: 146.47,
			volume: 66200,
			time: 1654530120000,
			lowest: 146.38,
			highest: 146.47,
			volumePrice: 146.4157,
			transactionCount: 860,
			voluemPrice: 146.4157
		},
		{
			open: 146.47,
			close: 146.48,
			volume: 69159,
			time: 1654530180000,
			lowest: 146.36,
			highest: 146.48,
			volumePrice: 146.4074,
			transactionCount: 816,
			voluemPrice: 146.4074
		},
		{
			open: 146.48,
			close: 146.6137,
			volume: 154538,
			time: 1654530240000,
			lowest: 146.465,
			highest: 146.65,
			volumePrice: 146.5635,
			transactionCount: 1338,
			voluemPrice: 146.5635
		},
		{
			open: 146.63,
			close: 146.7551,
			volume: 158170,
			time: 1654530300000,
			lowest: 146.5701,
			highest: 146.7699,
			volumePrice: 146.6719,
			transactionCount: 1334,
			voluemPrice: 146.6719
		},
		{
			open: 146.7599,
			close: 146.67,
			volume: 136526,
			time: 1654530360000,
			lowest: 146.67,
			highest: 146.8,
			volumePrice: 146.7392,
			transactionCount: 1066,
			voluemPrice: 146.7392
		},
		{
			open: 146.66,
			close: 146.69,
			volume: 104027,
			time: 1654530420000,
			lowest: 146.635,
			highest: 146.7764,
			volumePrice: 146.6944,
			transactionCount: 895,
			voluemPrice: 146.6944
		},
		{
			open: 146.6885,
			close: 146.6075,
			volume: 123875,
			time: 1654530480000,
			lowest: 146.54,
			highest: 146.6885,
			volumePrice: 146.5997,
			transactionCount: 1096,
			voluemPrice: 146.5997
		},
		{
			open: 146.62,
			close: 146.505,
			volume: 122945,
			time: 1654530540000,
			lowest: 146.49,
			highest: 146.68,
			volumePrice: 146.5682,
			transactionCount: 859,
			voluemPrice: 146.5682
		},
		{
			open: 146.505,
			close: 146.51,
			volume: 87123,
			time: 1654530600000,
			lowest: 146.42,
			highest: 146.59,
			volumePrice: 146.5152,
			transactionCount: 863,
			voluemPrice: 146.5152
		},
		{
			open: 146.51,
			close: 146.56,
			volume: 59385,
			time: 1654530660000,
			lowest: 146.49,
			highest: 146.64,
			volumePrice: 146.5723,
			transactionCount: 708,
			voluemPrice: 146.5723
		},
		{
			open: 146.565,
			close: 146.545,
			volume: 83323,
			time: 1654530720000,
			lowest: 146.53,
			highest: 146.67,
			volumePrice: 146.6093,
			transactionCount: 777,
			voluemPrice: 146.6093
		},
		{
			open: 146.54,
			close: 146.655,
			volume: 65146,
			time: 1654530780000,
			lowest: 146.47,
			highest: 146.66,
			volumePrice: 146.5508,
			transactionCount: 769,
			voluemPrice: 146.5508
		},
		{
			open: 146.6599,
			close: 146.5958,
			volume: 61622,
			time: 1654530840000,
			lowest: 146.55,
			highest: 146.66,
			volumePrice: 146.6165,
			transactionCount: 639,
			voluemPrice: 146.6165
		},
		{
			open: 146.59,
			close: 146.62,
			volume: 56875,
			time: 1654530900000,
			lowest: 146.5581,
			highest: 146.66,
			volumePrice: 146.5954,
			transactionCount: 736,
			voluemPrice: 146.5954
		},
		{
			open: 146.605,
			close: 146.55,
			volume: 91917,
			time: 1654530960000,
			lowest: 146.46,
			highest: 146.62,
			volumePrice: 146.5384,
			transactionCount: 841,
			voluemPrice: 146.5384
		},
		{
			open: 146.555,
			close: 146.62,
			volume: 66349,
			time: 1654531020000,
			lowest: 146.555,
			highest: 146.655,
			volumePrice: 146.6093,
			transactionCount: 696,
			voluemPrice: 146.6093
		},
		{
			open: 146.6,
			close: 146.64,
			volume: 60857,
			time: 1654531080000,
			lowest: 146.57,
			highest: 146.66,
			volumePrice: 146.6341,
			transactionCount: 714,
			voluemPrice: 146.6341
		},
		{
			open: 146.64,
			close: 146.52,
			volume: 75977,
			time: 1654531140000,
			lowest: 146.52,
			highest: 146.69,
			volumePrice: 146.6129,
			transactionCount: 869,
			voluemPrice: 146.6129
		},
		{
			open: 146.52,
			close: 146.48,
			volume: 94101,
			time: 1654531200000,
			lowest: 146.44,
			highest: 146.57,
			volumePrice: 146.4926,
			transactionCount: 964,
			voluemPrice: 146.4926
		},
		{
			open: 146.46,
			close: 146.14,
			volume: 243005,
			time: 1654531260000,
			lowest: 146.11,
			highest: 146.4802,
			volumePrice: 146.3306,
			transactionCount: 2113,
			voluemPrice: 146.3306
		},
		{
			open: 146.1382,
			close: 145.83,
			volume: 415875,
			time: 1654531320000,
			lowest: 145.7,
			highest: 146.14,
			volumePrice: 145.9127,
			transactionCount: 4140,
			voluemPrice: 145.9127
		},
		{
			open: 145.82,
			close: 145.76,
			volume: 161676,
			time: 1654531380000,
			lowest: 145.7501,
			highest: 145.92,
			volumePrice: 145.8355,
			transactionCount: 1371,
			voluemPrice: 145.8355
		},
		{
			open: 145.76,
			close: 145.69,
			volume: 240658,
			time: 1654531440000,
			lowest: 145.57,
			highest: 145.7601,
			volumePrice: 145.6631,
			transactionCount: 2022,
			voluemPrice: 145.6631
		},
		{
			open: 145.68,
			close: 145.745,
			volume: 139259,
			time: 1654531500000,
			lowest: 145.65,
			highest: 145.7767,
			volumePrice: 145.7151,
			transactionCount: 1177,
			voluemPrice: 145.7151
		},
		{
			open: 145.7499,
			close: 145.66,
			volume: 107826,
			time: 1654531560000,
			lowest: 145.6197,
			highest: 145.75,
			volumePrice: 145.7018,
			transactionCount: 1064,
			voluemPrice: 145.7018
		},
		{
			open: 145.67,
			close: 145.7853,
			volume: 120734,
			time: 1654531620000,
			lowest: 145.67,
			highest: 145.79,
			volumePrice: 145.725,
			transactionCount: 975,
			voluemPrice: 145.725
		},
		{
			open: 145.79,
			close: 145.6199,
			volume: 125803,
			time: 1654531680000,
			lowest: 145.6199,
			highest: 145.79,
			volumePrice: 145.6902,
			transactionCount: 1130,
			voluemPrice: 145.6902
		},
		{
			open: 145.62,
			close: 145.515,
			volume: 144118,
			time: 1654531740000,
			lowest: 145.51,
			highest: 145.63,
			volumePrice: 145.5649,
			transactionCount: 1308,
			voluemPrice: 145.5649
		},
		{
			open: 145.52,
			close: 145.4801,
			volume: 184906,
			time: 1654531800000,
			lowest: 145.4,
			highest: 145.53,
			volumePrice: 145.4658,
			transactionCount: 2003,
			voluemPrice: 145.4658
		},
		{
			open: 145.4899,
			close: 145.5712,
			volume: 125657,
			time: 1654531860000,
			lowest: 145.43,
			highest: 145.63,
			volumePrice: 145.5569,
			transactionCount: 1181,
			voluemPrice: 145.5569
		},
		{
			open: 145.57,
			close: 145.45,
			volume: 106539,
			time: 1654531920000,
			lowest: 145.44,
			highest: 145.605,
			volumePrice: 145.5085,
			transactionCount: 1122,
			voluemPrice: 145.5085
		},
		{
			open: 145.46,
			close: 145.53,
			volume: 101631,
			time: 1654531980000,
			lowest: 145.4,
			highest: 145.56,
			volumePrice: 145.477,
			transactionCount: 1098,
			voluemPrice: 145.477
		},
		{
			open: 145.52,
			close: 145.54,
			volume: 75204,
			time: 1654532040000,
			lowest: 145.45,
			highest: 145.56,
			volumePrice: 145.505,
			transactionCount: 866,
			voluemPrice: 145.505
		},
		{
			open: 145.53,
			close: 145.52,
			volume: 126738,
			time: 1654532100000,
			lowest: 145.43,
			highest: 145.5894,
			volumePrice: 145.5129,
			transactionCount: 1153,
			voluemPrice: 145.5129
		},
		{
			open: 145.53,
			close: 145.71,
			volume: 169081,
			time: 1654532160000,
			lowest: 145.48,
			highest: 145.73,
			volumePrice: 145.5994,
			transactionCount: 1578,
			voluemPrice: 145.5994
		},
		{
			open: 145.7,
			close: 145.641,
			volume: 143398,
			time: 1654532220000,
			lowest: 145.62,
			highest: 145.76,
			volumePrice: 145.6927,
			transactionCount: 1277,
			voluemPrice: 145.6927
		},
		{
			open: 145.65,
			close: 145.7323,
			volume: 94165,
			time: 1654532280000,
			lowest: 145.65,
			highest: 145.795,
			volumePrice: 145.7367,
			transactionCount: 906,
			voluemPrice: 145.7367
		},
		{
			open: 145.7364,
			close: 145.71,
			volume: 115174,
			time: 1654532340000,
			lowest: 145.7,
			highest: 145.8,
			volumePrice: 145.7479,
			transactionCount: 1080,
			voluemPrice: 145.7479
		},
		{
			open: 145.7,
			close: 145.72,
			volume: 106640,
			time: 1654532400000,
			lowest: 145.6501,
			highest: 145.7809,
			volumePrice: 145.7265,
			transactionCount: 1020,
			voluemPrice: 145.7265
		},
		{
			open: 145.71,
			close: 145.64,
			volume: 113845,
			time: 1654532460000,
			lowest: 145.62,
			highest: 145.71,
			volumePrice: 145.6574,
			transactionCount: 855,
			voluemPrice: 145.6574
		},
		{
			open: 145.64,
			close: 145.62,
			volume: 88173,
			time: 1654532520000,
			lowest: 145.58,
			highest: 145.7181,
			volumePrice: 145.6467,
			transactionCount: 769,
			voluemPrice: 145.6467
		},
		{
			open: 145.62,
			close: 145.7395,
			volume: 69534,
			time: 1654532580000,
			lowest: 145.575,
			highest: 145.7395,
			volumePrice: 145.6587,
			transactionCount: 761,
			voluemPrice: 145.6587
		},
		{
			open: 145.7301,
			close: 145.585,
			volume: 92440,
			time: 1654532640000,
			lowest: 145.57,
			highest: 145.77,
			volumePrice: 145.7026,
			transactionCount: 937,
			voluemPrice: 145.7026
		},
		{
			open: 145.5801,
			close: 145.705,
			volume: 61267,
			time: 1654532700000,
			lowest: 145.5801,
			highest: 145.75,
			volumePrice: 145.6749,
			transactionCount: 625,
			voluemPrice: 145.6749
		},
		{
			open: 145.72,
			close: 145.6701,
			volume: 95013,
			time: 1654532760000,
			lowest: 145.67,
			highest: 145.7699,
			volumePrice: 145.7175,
			transactionCount: 827,
			voluemPrice: 145.7175
		},
		{
			open: 145.6781,
			close: 145.64,
			volume: 66153,
			time: 1654532820000,
			lowest: 145.64,
			highest: 145.7101,
			volumePrice: 145.6807,
			transactionCount: 696,
			voluemPrice: 145.6807
		},
		{
			open: 145.64,
			close: 145.6282,
			volume: 103326,
			time: 1654532880000,
			lowest: 145.55,
			highest: 145.65,
			volumePrice: 145.6088,
			transactionCount: 895,
			voluemPrice: 145.6088
		},
		{
			open: 145.6224,
			close: 145.58,
			volume: 122460,
			time: 1654532940000,
			lowest: 145.49,
			highest: 145.6494,
			volumePrice: 145.5652,
			transactionCount: 1118,
			voluemPrice: 145.5652
		},
		{
			open: 145.58,
			close: 145.3114,
			volume: 225779,
			time: 1654533000000,
			lowest: 145.26,
			highest: 145.58,
			volumePrice: 145.3904,
			transactionCount: 2507,
			voluemPrice: 145.3904
		},
		{
			open: 145.32,
			close: 145.4,
			volume: 174999,
			time: 1654533060000,
			lowest: 145.2,
			highest: 145.46,
			volumePrice: 145.3119,
			transactionCount: 1592,
			voluemPrice: 145.3119
		},
		{
			open: 145.4,
			close: 145.58,
			volume: 156676,
			time: 1654533120000,
			lowest: 145.39,
			highest: 145.62,
			volumePrice: 145.5075,
			transactionCount: 1017,
			voluemPrice: 145.5075
		},
		{
			open: 145.58,
			close: 145.54,
			volume: 113812,
			time: 1654533180000,
			lowest: 145.54,
			highest: 145.62,
			volumePrice: 145.5874,
			transactionCount: 1023,
			voluemPrice: 145.5874
		},
		{
			open: 145.52,
			close: 145.65,
			volume: 82008,
			time: 1654533240000,
			lowest: 145.52,
			highest: 145.66,
			volumePrice: 145.6098,
			transactionCount: 762,
			voluemPrice: 145.6098
		},
		{
			open: 145.65,
			close: 145.67,
			volume: 115311,
			time: 1654533300000,
			lowest: 145.595,
			highest: 145.73,
			volumePrice: 145.659,
			transactionCount: 935,
			voluemPrice: 145.659
		},
		{
			open: 145.68,
			close: 145.7101,
			volume: 113846,
			time: 1654533360000,
			lowest: 145.65,
			highest: 145.75,
			volumePrice: 145.7182,
			transactionCount: 966,
			voluemPrice: 145.7182
		},
		{
			open: 145.7,
			close: 145.795,
			volume: 128275,
			time: 1654533420000,
			lowest: 145.68,
			highest: 145.839,
			volumePrice: 145.757,
			transactionCount: 1092,
			voluemPrice: 145.757
		},
		{
			open: 145.795,
			close: 145.82,
			volume: 84399,
			time: 1654533480000,
			lowest: 145.72,
			highest: 145.82,
			volumePrice: 145.7663,
			transactionCount: 791,
			voluemPrice: 145.7663
		},
		{
			open: 145.8193,
			close: 145.8892,
			volume: 104688,
			time: 1654533540000,
			lowest: 145.805,
			highest: 145.8892,
			volumePrice: 145.8468,
			transactionCount: 951,
			voluemPrice: 145.8468
		},
		{
			open: 145.89,
			close: 145.79,
			volume: 95072,
			time: 1654533600000,
			lowest: 145.775,
			highest: 145.89,
			volumePrice: 145.849,
			transactionCount: 904,
			voluemPrice: 145.849
		},
		{
			open: 145.8,
			close: 145.85,
			volume: 102258,
			time: 1654533660000,
			lowest: 145.7846,
			highest: 145.945,
			volumePrice: 145.8631,
			transactionCount: 925,
			voluemPrice: 145.8631
		},
		{
			open: 145.85,
			close: 145.92,
			volume: 69109,
			time: 1654533720000,
			lowest: 145.78,
			highest: 145.94,
			volumePrice: 145.8563,
			transactionCount: 695,
			voluemPrice: 145.8563
		},
		{
			open: 145.915,
			close: 145.9016,
			volume: 94783,
			time: 1654533780000,
			lowest: 145.87,
			highest: 145.985,
			volumePrice: 145.9317,
			transactionCount: 808,
			voluemPrice: 145.9317
		},
		{
			open: 145.9,
			close: 145.91,
			volume: 53064,
			time: 1654533840000,
			lowest: 145.88,
			highest: 145.95,
			volumePrice: 145.9087,
			transactionCount: 692,
			voluemPrice: 145.9087
		},
		{
			open: 145.9,
			close: 145.9,
			volume: 58196,
			time: 1654533900000,
			lowest: 145.86,
			highest: 145.9599,
			volumePrice: 145.912,
			transactionCount: 615,
			voluemPrice: 145.912
		},
		{
			open: 145.89,
			close: 145.99,
			volume: 128768,
			time: 1654533960000,
			lowest: 145.85,
			highest: 146.03,
			volumePrice: 145.9439,
			transactionCount: 1089,
			voluemPrice: 145.9439
		},
		{
			open: 145.995,
			close: 145.9,
			volume: 89706,
			time: 1654534020000,
			lowest: 145.89,
			highest: 146,
			volumePrice: 145.9222,
			transactionCount: 911,
			voluemPrice: 145.9222
		},
		{
			open: 145.9078,
			close: 145.9239,
			volume: 74320,
			time: 1654534080000,
			lowest: 145.9,
			highest: 145.965,
			volumePrice: 145.9222,
			transactionCount: 720,
			voluemPrice: 145.9222
		},
		{
			open: 145.93,
			close: 145.93,
			volume: 80565,
			time: 1654534140000,
			lowest: 145.8901,
			highest: 145.9798,
			volumePrice: 145.9373,
			transactionCount: 715,
			voluemPrice: 145.9373
		},
		{
			open: 145.92,
			close: 145.8454,
			volume: 107765,
			time: 1654534200000,
			lowest: 145.83,
			highest: 145.945,
			volumePrice: 145.8883,
			transactionCount: 921,
			voluemPrice: 145.8883
		},
		{
			open: 145.84,
			close: 145.7701,
			volume: 95198,
			time: 1654534260000,
			lowest: 145.76,
			highest: 145.88,
			volumePrice: 145.8242,
			transactionCount: 881,
			voluemPrice: 145.8242
		},
		{
			open: 145.775,
			close: 145.785,
			volume: 105010,
			time: 1654534320000,
			lowest: 145.74,
			highest: 145.87,
			volumePrice: 145.7993,
			transactionCount: 1023,
			voluemPrice: 145.7993
		},
		{
			open: 145.79,
			close: 145.83,
			volume: 59773,
			time: 1654534380000,
			lowest: 145.79,
			highest: 145.87,
			volumePrice: 145.8439,
			transactionCount: 684,
			voluemPrice: 145.8439
		},
		{
			open: 145.82,
			close: 145.9001,
			volume: 70455,
			time: 1654534440000,
			lowest: 145.78,
			highest: 145.92,
			volumePrice: 145.852,
			transactionCount: 694,
			voluemPrice: 145.852
		},
		{
			open: 145.9,
			close: 145.9889,
			volume: 73623,
			time: 1654534500000,
			lowest: 145.86,
			highest: 145.99,
			volumePrice: 145.9367,
			transactionCount: 707,
			voluemPrice: 145.9367
		},
		{
			open: 145.98,
			close: 145.985,
			volume: 145331,
			time: 1654534560000,
			lowest: 145.94,
			highest: 146.12,
			volumePrice: 146.0451,
			transactionCount: 1203,
			voluemPrice: 146.0451
		},
		{
			open: 145.99,
			close: 146.03,
			volume: 98799,
			time: 1654534620000,
			lowest: 145.95,
			highest: 146.06,
			volumePrice: 146.0056,
			transactionCount: 868,
			voluemPrice: 146.0056
		},
		{
			open: 146.025,
			close: 146.0949,
			volume: 97556,
			time: 1654534680000,
			lowest: 146.025,
			highest: 146.13,
			volumePrice: 146.0893,
			transactionCount: 785,
			voluemPrice: 146.0893
		},
		{
			open: 146.09,
			close: 146.085,
			volume: 76524,
			time: 1654534740000,
			lowest: 146.0357,
			highest: 146.14,
			volumePrice: 146.0868,
			transactionCount: 670,
			voluemPrice: 146.0868
		},
		{
			open: 146.08,
			close: 145.9929,
			volume: 92951,
			time: 1654534800000,
			lowest: 145.975,
			highest: 146.1,
			volumePrice: 146.0429,
			transactionCount: 813,
			voluemPrice: 146.0429
		},
		{
			open: 145.995,
			close: 145.9308,
			volume: 63191,
			time: 1654534860000,
			lowest: 145.92,
			highest: 146.0099,
			volumePrice: 145.9659,
			transactionCount: 622,
			voluemPrice: 145.9659
		},
		{
			open: 145.94,
			close: 146.1,
			volume: 133212,
			time: 1654534920000,
			lowest: 145.928,
			highest: 146.11,
			volumePrice: 146.0321,
			transactionCount: 1018,
			voluemPrice: 146.0321
		},
		{
			open: 146.1,
			close: 145.93,
			volume: 122046,
			time: 1654534980000,
			lowest: 145.919,
			highest: 146.12,
			volumePrice: 146.0469,
			transactionCount: 1203,
			voluemPrice: 146.0469
		},
		{
			open: 145.9301,
			close: 145.92,
			volume: 112754,
			time: 1654535040000,
			lowest: 145.88,
			highest: 145.98,
			volumePrice: 145.9319,
			transactionCount: 930,
			voluemPrice: 145.9319
		},
		{
			open: 145.93,
			close: 146.03,
			volume: 90449,
			time: 1654535100000,
			lowest: 145.92,
			highest: 146.08,
			volumePrice: 146.0126,
			transactionCount: 811,
			voluemPrice: 146.0126
		},
		{
			open: 146.035,
			close: 146.06,
			volume: 148693,
			time: 1654535160000,
			lowest: 146.03,
			highest: 146.14,
			volumePrice: 146.0894,
			transactionCount: 951,
			voluemPrice: 146.0894
		},
		{
			open: 146.05,
			close: 146.1615,
			volume: 117967,
			time: 1654535220000,
			lowest: 146.03,
			highest: 146.19,
			volumePrice: 146.132,
			transactionCount: 935,
			voluemPrice: 146.132
		},
		{
			open: 146.1682,
			close: 146.16,
			volume: 187993,
			time: 1654535280000,
			lowest: 146.14,
			highest: 146.23,
			volumePrice: 146.1941,
			transactionCount: 1187,
			voluemPrice: 146.1941
		},
		{
			open: 146.1665,
			close: 145.995,
			volume: 182662,
			time: 1654535340000,
			lowest: 145.98,
			highest: 146.17,
			volumePrice: 146.0571,
			transactionCount: 1253,
			voluemPrice: 146.0571
		},
		{
			open: 145.995,
			close: 146.025,
			volume: 65613,
			time: 1654535400000,
			lowest: 145.975,
			highest: 146.04,
			volumePrice: 146.0065,
			transactionCount: 681,
			voluemPrice: 146.0065
		},
		{
			open: 146.01,
			close: 145.8513,
			volume: 103056,
			time: 1654535460000,
			lowest: 145.84,
			highest: 146.01,
			volumePrice: 145.9217,
			transactionCount: 955,
			voluemPrice: 145.9217
		},
		{
			open: 145.85,
			close: 145.66,
			volume: 171250,
			time: 1654535520000,
			lowest: 145.66,
			highest: 145.9,
			volumePrice: 145.7953,
			transactionCount: 1282,
			voluemPrice: 145.7953
		},
		{
			open: 145.64,
			close: 145.8557,
			volume: 150390,
			time: 1654535580000,
			lowest: 145.63,
			highest: 145.8557,
			volumePrice: 145.768,
			transactionCount: 969,
			voluemPrice: 145.768
		},
		{
			open: 145.84,
			close: 145.8,
			volume: 76820,
			time: 1654535640000,
			lowest: 145.7901,
			highest: 145.87,
			volumePrice: 145.8354,
			transactionCount: 685,
			voluemPrice: 145.8354
		},
		{
			open: 145.795,
			close: 145.795,
			volume: 87600,
			time: 1654535700000,
			lowest: 145.73,
			highest: 145.84,
			volumePrice: 145.7891,
			transactionCount: 690,
			voluemPrice: 145.7891
		},
		{
			open: 145.79,
			close: 145.72,
			volume: 100648,
			time: 1654535760000,
			lowest: 145.6,
			highest: 145.79,
			volumePrice: 145.6845,
			transactionCount: 899,
			voluemPrice: 145.6845
		},
		{
			open: 145.73,
			close: 145.665,
			volume: 98192,
			time: 1654535820000,
			lowest: 145.62,
			highest: 145.75,
			volumePrice: 145.6664,
			transactionCount: 888,
			voluemPrice: 145.6664
		},
		{
			open: 145.6657,
			close: 145.72,
			volume: 90813,
			time: 1654535880000,
			lowest: 145.63,
			highest: 145.76,
			volumePrice: 145.6936,
			transactionCount: 806,
			voluemPrice: 145.6936
		},
		{
			open: 145.7188,
			close: 145.78,
			volume: 111210,
			time: 1654535940000,
			lowest: 145.7188,
			highest: 145.86,
			volumePrice: 145.8001,
			transactionCount: 917,
			voluemPrice: 145.8001
		},
		{
			open: 145.78,
			close: 145.7,
			volume: 92150,
			time: 1654536000000,
			lowest: 145.67,
			highest: 145.78,
			volumePrice: 145.7214,
			transactionCount: 955,
			voluemPrice: 145.7214
		},
		{
			open: 145.69,
			close: 145.77,
			volume: 107481,
			time: 1654536060000,
			lowest: 145.62,
			highest: 145.785,
			volumePrice: 145.7042,
			transactionCount: 832,
			voluemPrice: 145.7042
		},
		{
			open: 145.79,
			close: 145.8499,
			volume: 111149,
			time: 1654536120000,
			lowest: 145.78,
			highest: 145.85,
			volumePrice: 145.8109,
			transactionCount: 960,
			voluemPrice: 145.8109
		},
		{
			open: 145.86,
			close: 145.9081,
			volume: 84322,
			time: 1654536180000,
			lowest: 145.8461,
			highest: 145.94,
			volumePrice: 145.8997,
			transactionCount: 851,
			voluemPrice: 145.8997
		},
		{
			open: 145.91,
			close: 145.83,
			volume: 99592,
			time: 1654536240000,
			lowest: 145.825,
			highest: 145.96,
			volumePrice: 145.9026,
			transactionCount: 943,
			voluemPrice: 145.9026
		},
		{
			open: 145.83,
			close: 145.78,
			volume: 76125,
			time: 1654536300000,
			lowest: 145.765,
			highest: 145.8401,
			volumePrice: 145.8042,
			transactionCount: 884,
			voluemPrice: 145.8042
		},
		{
			open: 145.79,
			close: 145.845,
			volume: 79541,
			time: 1654536360000,
			lowest: 145.765,
			highest: 145.87,
			volumePrice: 145.8296,
			transactionCount: 849,
			voluemPrice: 145.8296
		},
		{
			open: 145.84,
			close: 145.83,
			volume: 55020,
			time: 1654536420000,
			lowest: 145.76,
			highest: 145.91,
			volumePrice: 145.8284,
			transactionCount: 680,
			voluemPrice: 145.8284
		},
		{
			open: 145.82,
			close: 145.79,
			volume: 46007,
			time: 1654536480000,
			lowest: 145.7524,
			highest: 145.82,
			volumePrice: 145.7799,
			transactionCount: 557,
			voluemPrice: 145.7799
		},
		{
			open: 145.78,
			close: 145.8701,
			volume: 65525,
			time: 1654536540000,
			lowest: 145.76,
			highest: 145.895,
			volumePrice: 145.8277,
			transactionCount: 690,
			voluemPrice: 145.8277
		},
		{
			open: 145.87,
			close: 145.9743,
			volume: 120401,
			time: 1654536600000,
			lowest: 145.81,
			highest: 146,
			volumePrice: 145.9283,
			transactionCount: 922,
			voluemPrice: 145.9283
		},
		{
			open: 145.97,
			close: 146.125,
			volume: 159401,
			time: 1654536660000,
			lowest: 145.9266,
			highest: 146.16,
			volumePrice: 146.0802,
			transactionCount: 1252,
			voluemPrice: 146.0802
		},
		{
			open: 146.12,
			close: 146.02,
			volume: 83522,
			time: 1654536720000,
			lowest: 145.96,
			highest: 146.12,
			volumePrice: 146.0235,
			transactionCount: 990,
			voluemPrice: 146.0235
		},
		{
			open: 146.01,
			close: 146.0434,
			volume: 63231,
			time: 1654536780000,
			lowest: 145.97,
			highest: 146.06,
			volumePrice: 146.016,
			transactionCount: 608,
			voluemPrice: 146.016
		},
		{
			open: 146.0449,
			close: 146.09,
			volume: 60694,
			time: 1654536840000,
			lowest: 145.99,
			highest: 146.105,
			volumePrice: 146.0528,
			transactionCount: 646,
			voluemPrice: 146.0528
		},
		{
			open: 146.09,
			close: 146.07,
			volume: 77045,
			time: 1654536900000,
			lowest: 146.03,
			highest: 146.145,
			volumePrice: 146.075,
			transactionCount: 780,
			voluemPrice: 146.075
		},
		{
			open: 146.07,
			close: 146.18,
			volume: 87059,
			time: 1654536960000,
			lowest: 146.03,
			highest: 146.19,
			volumePrice: 146.1239,
			transactionCount: 781,
			voluemPrice: 146.1239
		},
		{
			open: 146.16,
			close: 146.34,
			volume: 137438,
			time: 1654537020000,
			lowest: 146.13,
			highest: 146.36,
			volumePrice: 146.2203,
			transactionCount: 1248,
			voluemPrice: 146.2203
		},
		{
			open: 146.355,
			close: 146.479,
			volume: 108600,
			time: 1654537080000,
			lowest: 146.28,
			highest: 146.4899,
			volumePrice: 146.3782,
			transactionCount: 1169,
			voluemPrice: 146.3782
		},
		{
			open: 146.4781,
			close: 146.4799,
			volume: 160091,
			time: 1654537140000,
			lowest: 146.41,
			highest: 146.58,
			volumePrice: 146.4946,
			transactionCount: 1259,
			voluemPrice: 146.4946
		},
		{
			open: 146.475,
			close: 146.4746,
			volume: 102115,
			time: 1654537200000,
			lowest: 146.39,
			highest: 146.5,
			volumePrice: 146.4422,
			transactionCount: 921,
			voluemPrice: 146.4422
		},
		{
			open: 146.47,
			close: 146.58,
			volume: 109859,
			time: 1654537260000,
			lowest: 146.47,
			highest: 146.6229,
			volumePrice: 146.5633,
			transactionCount: 952,
			voluemPrice: 146.5633
		},
		{
			open: 146.56,
			close: 146.63,
			volume: 132084,
			time: 1654537320000,
			lowest: 146.51,
			highest: 146.66,
			volumePrice: 146.581,
			transactionCount: 1027,
			voluemPrice: 146.581
		},
		{
			open: 146.6398,
			close: 146.595,
			volume: 133502,
			time: 1654537380000,
			lowest: 146.5617,
			highest: 146.69,
			volumePrice: 146.6248,
			transactionCount: 1018,
			voluemPrice: 146.6248
		},
		{
			open: 146.5957,
			close: 146.57,
			volume: 109883,
			time: 1654537440000,
			lowest: 146.49,
			highest: 146.64,
			volumePrice: 146.5702,
			transactionCount: 1022,
			voluemPrice: 146.5702
		},
		{
			open: 146.565,
			close: 146.4751,
			volume: 132877,
			time: 1654537500000,
			lowest: 146.47,
			highest: 146.635,
			volumePrice: 146.5548,
			transactionCount: 1124,
			voluemPrice: 146.5548
		},
		{
			open: 146.4801,
			close: 146.4801,
			volume: 114755,
			time: 1654537560000,
			lowest: 146.4,
			highest: 146.52,
			volumePrice: 146.4698,
			transactionCount: 1148,
			voluemPrice: 146.4698
		},
		{
			open: 146.4917,
			close: 146.47,
			volume: 128498,
			time: 1654537620000,
			lowest: 146.44,
			highest: 146.625,
			volumePrice: 146.5173,
			transactionCount: 1151,
			voluemPrice: 146.5173
		},
		{
			open: 146.465,
			close: 146.3725,
			volume: 141743,
			time: 1654537680000,
			lowest: 146.34,
			highest: 146.48,
			volumePrice: 146.3964,
			transactionCount: 1021,
			voluemPrice: 146.3964
		},
		{
			open: 146.38,
			close: 146.5358,
			volume: 109328,
			time: 1654537740000,
			lowest: 146.345,
			highest: 146.54,
			volumePrice: 146.4597,
			transactionCount: 749,
			voluemPrice: 146.4597
		},
		{
			open: 146.54,
			close: 146.615,
			volume: 136136,
			time: 1654537800000,
			lowest: 146.47,
			highest: 146.69,
			volumePrice: 146.5741,
			transactionCount: 1237,
			voluemPrice: 146.5741
		},
		{
			open: 146.61,
			close: 146.6414,
			volume: 136023,
			time: 1654537860000,
			lowest: 146.56,
			highest: 146.74,
			volumePrice: 146.645,
			transactionCount: 1161,
			voluemPrice: 146.645
		},
		{
			open: 146.6499,
			close: 146.6,
			volume: 128979,
			time: 1654537920000,
			lowest: 146.5316,
			highest: 146.6617,
			volumePrice: 146.5699,
			transactionCount: 1071,
			voluemPrice: 146.5699
		},
		{
			open: 146.605,
			close: 146.57,
			volume: 94514,
			time: 1654537980000,
			lowest: 146.5,
			highest: 146.64,
			volumePrice: 146.55,
			transactionCount: 743,
			voluemPrice: 146.55
		},
		{
			open: 146.57,
			close: 146.5219,
			volume: 56217,
			time: 1654538040000,
			lowest: 146.515,
			highest: 146.575,
			volumePrice: 146.5456,
			transactionCount: 557,
			voluemPrice: 146.5456
		},
		{
			open: 146.525,
			close: 146.5713,
			volume: 94295,
			time: 1654538100000,
			lowest: 146.52,
			highest: 146.65,
			volumePrice: 146.6024,
			transactionCount: 775,
			voluemPrice: 146.6024
		},
		{
			open: 146.57,
			close: 146.61,
			volume: 120180,
			time: 1654538160000,
			lowest: 146.535,
			highest: 146.645,
			volumePrice: 146.5834,
			transactionCount: 850,
			voluemPrice: 146.5834
		},
		{
			open: 146.6079,
			close: 146.5745,
			volume: 71577,
			time: 1654538220000,
			lowest: 146.525,
			highest: 146.635,
			volumePrice: 146.5794,
			transactionCount: 757,
			voluemPrice: 146.5794
		},
		{
			open: 146.57,
			close: 146.63,
			volume: 76959,
			time: 1654538280000,
			lowest: 146.57,
			highest: 146.64,
			volumePrice: 146.6171,
			transactionCount: 823,
			voluemPrice: 146.6171
		},
		{
			open: 146.625,
			close: 146.6487,
			volume: 80466,
			time: 1654538340000,
			lowest: 146.6,
			highest: 146.6897,
			volumePrice: 146.6441,
			transactionCount: 882,
			voluemPrice: 146.6441
		},
		{
			open: 146.65,
			close: 146.57,
			volume: 135528,
			time: 1654538400000,
			lowest: 146.53,
			highest: 146.69,
			volumePrice: 146.6084,
			transactionCount: 1161,
			voluemPrice: 146.6084
		},
		{
			open: 146.57,
			close: 146.63,
			volume: 84792,
			time: 1654538460000,
			lowest: 146.5696,
			highest: 146.66,
			volumePrice: 146.6298,
			transactionCount: 735,
			voluemPrice: 146.6298
		},
		{
			open: 146.6326,
			close: 146.72,
			volume: 116009,
			time: 1654538520000,
			lowest: 146.62,
			highest: 146.76,
			volumePrice: 146.7239,
			transactionCount: 1148,
			voluemPrice: 146.7239
		},
		{
			open: 146.715,
			close: 146.7099,
			volume: 104519,
			time: 1654538580000,
			lowest: 146.64,
			highest: 146.73,
			volumePrice: 146.6841,
			transactionCount: 989,
			voluemPrice: 146.6841
		},
		{
			open: 146.7012,
			close: 146.85,
			volume: 250653,
			time: 1654538640000,
			lowest: 146.6807,
			highest: 146.89,
			volumePrice: 146.81,
			transactionCount: 2055,
			voluemPrice: 146.81
		},
		{
			open: 146.85,
			close: 146.77,
			volume: 113407,
			time: 1654538700000,
			lowest: 146.76,
			highest: 146.86,
			volumePrice: 146.8039,
			transactionCount: 958,
			voluemPrice: 146.8039
		},
		{
			open: 146.76,
			close: 146.805,
			volume: 99015,
			time: 1654538760000,
			lowest: 146.72,
			highest: 146.82,
			volumePrice: 146.7905,
			transactionCount: 824,
			voluemPrice: 146.7905
		},
		{
			open: 146.8,
			close: 146.6134,
			volume: 173338,
			time: 1654538820000,
			lowest: 146.59,
			highest: 146.8,
			volumePrice: 146.684,
			transactionCount: 1205,
			voluemPrice: 146.684
		},
		{
			open: 146.615,
			close: 146.6285,
			volume: 119219,
			time: 1654538880000,
			lowest: 146.615,
			highest: 146.7,
			volumePrice: 146.6561,
			transactionCount: 949,
			voluemPrice: 146.6561
		},
		{
			open: 146.62,
			close: 146.725,
			volume: 119139,
			time: 1654538940000,
			lowest: 146.58,
			highest: 146.79,
			volumePrice: 146.6776,
			transactionCount: 944,
			voluemPrice: 146.6776
		},
		{
			open: 146.7101,
			close: 146.71,
			volume: 97833,
			time: 1654539000000,
			lowest: 146.66,
			highest: 146.78,
			volumePrice: 146.7292,
			transactionCount: 885,
			voluemPrice: 146.7292
		},
		{
			open: 146.705,
			close: 146.6638,
			volume: 78457,
			time: 1654539060000,
			lowest: 146.62,
			highest: 146.705,
			volumePrice: 146.6534,
			transactionCount: 693,
			voluemPrice: 146.6534
		},
		{
			open: 146.64,
			close: 146.6,
			volume: 72674,
			time: 1654539120000,
			lowest: 146.59,
			highest: 146.695,
			volumePrice: 146.624,
			transactionCount: 604,
			voluemPrice: 146.624
		},
		{
			open: 146.59,
			close: 146.62,
			volume: 102723,
			time: 1654539180000,
			lowest: 146.53,
			highest: 146.6301,
			volumePrice: 146.5774,
			transactionCount: 846,
			voluemPrice: 146.5774
		},
		{
			open: 146.6299,
			close: 146.58,
			volume: 72412,
			time: 1654539240000,
			lowest: 146.57,
			highest: 146.68,
			volumePrice: 146.63,
			transactionCount: 714,
			voluemPrice: 146.63
		},
		{
			open: 146.575,
			close: 146.58,
			volume: 145510,
			time: 1654539300000,
			lowest: 146.46,
			highest: 146.61,
			volumePrice: 146.554,
			transactionCount: 1309,
			voluemPrice: 146.554
		},
		{
			open: 146.58,
			close: 146.5126,
			volume: 135665,
			time: 1654539360000,
			lowest: 146.46,
			highest: 146.58,
			volumePrice: 146.5321,
			transactionCount: 1328,
			voluemPrice: 146.5321
		},
		{
			open: 146.51,
			close: 146.54,
			volume: 113075,
			time: 1654539420000,
			lowest: 146.48,
			highest: 146.6,
			volumePrice: 146.5525,
			transactionCount: 1016,
			voluemPrice: 146.5525
		},
		{
			open: 146.53,
			close: 146.5122,
			volume: 96780,
			time: 1654539480000,
			lowest: 146.45,
			highest: 146.54,
			volumePrice: 146.4861,
			transactionCount: 836,
			voluemPrice: 146.4861
		},
		{
			open: 146.5199,
			close: 146.5416,
			volume: 82612,
			time: 1654539540000,
			lowest: 146.43,
			highest: 146.57,
			volumePrice: 146.4991,
			transactionCount: 858,
			voluemPrice: 146.4991
		},
		{
			open: 146.545,
			close: 146.4,
			volume: 133031,
			time: 1654539600000,
			lowest: 146.37,
			highest: 146.545,
			volumePrice: 146.4243,
			transactionCount: 962,
			voluemPrice: 146.4243
		},
		{
			open: 146.41,
			close: 146.23,
			volume: 157097,
			time: 1654539660000,
			lowest: 146.17,
			highest: 146.425,
			volumePrice: 146.2814,
			transactionCount: 1333,
			voluemPrice: 146.2814
		},
		{
			open: 146.235,
			close: 146.06,
			volume: 192344,
			time: 1654539720000,
			lowest: 146.06,
			highest: 146.25,
			volumePrice: 146.1475,
			transactionCount: 1393,
			voluemPrice: 146.1475
		},
		{
			open: 146.05,
			close: 145.96,
			volume: 247730,
			time: 1654539780000,
			lowest: 145.88,
			highest: 146.05,
			volumePrice: 145.9664,
			transactionCount: 1850,
			voluemPrice: 145.9664
		},
		{
			open: 145.96,
			close: 145.9907,
			volume: 122421,
			time: 1654539840000,
			lowest: 145.9,
			highest: 146.03,
			volumePrice: 145.973,
			transactionCount: 1093,
			voluemPrice: 145.973
		},
		{
			open: 146,
			close: 145.9,
			volume: 74665,
			time: 1654539900000,
			lowest: 145.89,
			highest: 146.01,
			volumePrice: 145.9603,
			transactionCount: 795,
			voluemPrice: 145.9603
		},
		{
			open: 145.895,
			close: 145.855,
			volume: 136284,
			time: 1654539960000,
			lowest: 145.8,
			highest: 145.91,
			volumePrice: 145.8603,
			transactionCount: 1105,
			voluemPrice: 145.8603
		},
		{
			open: 145.86,
			close: 145.815,
			volume: 128101,
			time: 1654540020000,
			lowest: 145.69,
			highest: 145.87,
			volumePrice: 145.7671,
			transactionCount: 1202,
			voluemPrice: 145.7671
		},
		{
			open: 145.81,
			close: 145.74,
			volume: 91392,
			time: 1654540080000,
			lowest: 145.73,
			highest: 145.86,
			volumePrice: 145.805,
			transactionCount: 897,
			voluemPrice: 145.805
		},
		{
			open: 145.7399,
			close: 145.76,
			volume: 92806,
			time: 1654540140000,
			lowest: 145.66,
			highest: 145.81,
			volumePrice: 145.7493,
			transactionCount: 847,
			voluemPrice: 145.7493
		},
		{
			open: 145.75,
			close: 145.795,
			volume: 122280,
			time: 1654540200000,
			lowest: 145.68,
			highest: 145.8658,
			volumePrice: 145.784,
			transactionCount: 1037,
			voluemPrice: 145.784
		},
		{
			open: 145.79,
			close: 145.855,
			volume: 118244,
			time: 1654540260000,
			lowest: 145.7399,
			highest: 145.865,
			volumePrice: 145.8032,
			transactionCount: 945,
			voluemPrice: 145.8032
		},
		{
			open: 145.86,
			close: 145.7601,
			volume: 131410,
			time: 1654540320000,
			lowest: 145.75,
			highest: 145.86,
			volumePrice: 145.8007,
			transactionCount: 819,
			voluemPrice: 145.8007
		},
		{
			open: 145.76,
			close: 145.88,
			volume: 100493,
			time: 1654540380000,
			lowest: 145.7323,
			highest: 145.89,
			volumePrice: 145.8062,
			transactionCount: 782,
			voluemPrice: 145.8062
		},
		{
			open: 145.88,
			close: 145.87,
			volume: 111404,
			time: 1654540440000,
			lowest: 145.835,
			highest: 145.95,
			volumePrice: 145.9044,
			transactionCount: 983,
			voluemPrice: 145.9044
		},
		{
			open: 145.8685,
			close: 145.94,
			volume: 97780,
			time: 1654540500000,
			lowest: 145.8401,
			highest: 145.97,
			volumePrice: 145.9171,
			transactionCount: 805,
			voluemPrice: 145.9171
		},
		{
			open: 145.93,
			close: 145.88,
			volume: 79369,
			time: 1654540560000,
			lowest: 145.82,
			highest: 145.94,
			volumePrice: 145.8843,
			transactionCount: 841,
			voluemPrice: 145.8843
		},
		{
			open: 145.87,
			close: 145.69,
			volume: 117582,
			time: 1654540620000,
			lowest: 145.68,
			highest: 145.87,
			volumePrice: 145.7776,
			transactionCount: 880,
			voluemPrice: 145.7776
		},
		{
			open: 145.6942,
			close: 145.5967,
			volume: 201403,
			time: 1654540680000,
			lowest: 145.54,
			highest: 145.72,
			volumePrice: 145.6006,
			transactionCount: 1750,
			voluemPrice: 145.6006
		},
		{
			open: 145.6,
			close: 145.62,
			volume: 167952,
			time: 1654540740000,
			lowest: 145.51,
			highest: 145.635,
			volumePrice: 145.5902,
			transactionCount: 1225,
			voluemPrice: 145.5902
		},
		{
			open: 145.625,
			close: 145.5799,
			volume: 143250,
			time: 1654540800000,
			lowest: 145.55,
			highest: 145.7,
			volumePrice: 145.645,
			transactionCount: 971,
			voluemPrice: 145.645
		},
		{
			open: 145.58,
			close: 145.55,
			volume: 134974,
			time: 1654540860000,
			lowest: 145.52,
			highest: 145.59,
			volumePrice: 145.5453,
			transactionCount: 947,
			voluemPrice: 145.5453
		},
		{
			open: 145.545,
			close: 145.575,
			volume: 209993,
			time: 1654540920000,
			lowest: 145.49,
			highest: 145.58,
			volumePrice: 145.5363,
			transactionCount: 1460,
			voluemPrice: 145.5363
		},
		{
			open: 145.57,
			close: 145.645,
			volume: 172656,
			time: 1654540980000,
			lowest: 145.445,
			highest: 145.6797,
			volumePrice: 145.5421,
			transactionCount: 1326,
			voluemPrice: 145.5421
		},
		{
			open: 145.65,
			close: 145.6599,
			volume: 164451,
			time: 1654541040000,
			lowest: 145.6081,
			highest: 145.72,
			volumePrice: 145.6623,
			transactionCount: 1056,
			voluemPrice: 145.6623
		},
		{
			open: 145.645,
			close: 145.62,
			volume: 121646,
			time: 1654541100000,
			lowest: 145.55,
			highest: 145.68,
			volumePrice: 145.6141,
			transactionCount: 850,
			voluemPrice: 145.6141
		},
		{
			open: 145.6124,
			close: 145.688,
			volume: 160739,
			time: 1654541160000,
			lowest: 145.56,
			highest: 145.71,
			volumePrice: 145.622,
			transactionCount: 1315,
			voluemPrice: 145.622
		},
		{
			open: 145.675,
			close: 145.65,
			volume: 209430,
			time: 1654541220000,
			lowest: 145.6,
			highest: 145.74,
			volumePrice: 145.6757,
			transactionCount: 1585,
			voluemPrice: 145.6757
		},
		{
			open: 145.6401,
			close: 145.4801,
			volume: 186470,
			time: 1654541280000,
			lowest: 145.4608,
			highest: 145.67,
			volumePrice: 145.5658,
			transactionCount: 1618,
			voluemPrice: 145.5658
		},
		{
			open: 145.48,
			close: 145.3861,
			volume: 196605,
			time: 1654541340000,
			lowest: 145.37,
			highest: 145.58,
			volumePrice: 145.4872,
			transactionCount: 1722,
			voluemPrice: 145.4872
		},
		{
			open: 145.3754,
			close: 145.2409,
			volume: 456953,
			time: 1654541400000,
			lowest: 145.12,
			highest: 145.41,
			volumePrice: 145.2353,
			transactionCount: 3823,
			voluemPrice: 145.2353
		},
		{
			open: 145.25,
			close: 145.17,
			volume: 264459,
			time: 1654541460000,
			lowest: 145.07,
			highest: 145.25,
			volumePrice: 145.1482,
			transactionCount: 2259,
			voluemPrice: 145.1482
		},
		{
			open: 145.1725,
			close: 145.23,
			volume: 132996,
			time: 1654541520000,
			lowest: 145.155,
			highest: 145.27,
			volumePrice: 145.1992,
			transactionCount: 1208,
			voluemPrice: 145.1992
		},
		{
			open: 145.23,
			close: 145.34,
			volume: 160351,
			time: 1654541580000,
			lowest: 145.225,
			highest: 145.3778,
			volumePrice: 145.3032,
			transactionCount: 1245,
			voluemPrice: 145.3032
		},
		{
			open: 145.33,
			close: 145.4147,
			volume: 207594,
			time: 1654541640000,
			lowest: 145.28,
			highest: 145.46,
			volumePrice: 145.3536,
			transactionCount: 1472,
			voluemPrice: 145.3536
		},
		{
			open: 145.4011,
			close: 145.36,
			volume: 95804,
			time: 1654541700000,
			lowest: 145.28,
			highest: 145.4011,
			volumePrice: 145.3448,
			transactionCount: 962,
			voluemPrice: 145.3448
		},
		{
			open: 145.36,
			close: 145.485,
			volume: 197848,
			time: 1654541760000,
			lowest: 145.36,
			highest: 145.585,
			volumePrice: 145.5114,
			transactionCount: 1374,
			voluemPrice: 145.5114
		},
		{
			open: 145.49,
			close: 145.4699,
			volume: 108591,
			time: 1654541820000,
			lowest: 145.42,
			highest: 145.51,
			volumePrice: 145.4659,
			transactionCount: 970,
			voluemPrice: 145.4659
		},
		{
			open: 145.46,
			close: 145.45,
			volume: 123977,
			time: 1654541880000,
			lowest: 145.4299,
			highest: 145.59,
			volumePrice: 145.504,
			transactionCount: 1212,
			voluemPrice: 145.504
		},
		{
			open: 145.4597,
			close: 145.3999,
			volume: 117679,
			time: 1654541940000,
			lowest: 145.38,
			highest: 145.49,
			volumePrice: 145.4417,
			transactionCount: 1045,
			voluemPrice: 145.4417
		},
		{
			open: 145.4,
			close: 145.45,
			volume: 187495,
			time: 1654542000000,
			lowest: 145.31,
			highest: 145.47,
			volumePrice: 145.373,
			transactionCount: 1672,
			voluemPrice: 145.373
		},
		{
			open: 145.44,
			close: 145.5612,
			volume: 148873,
			time: 1654542060000,
			lowest: 145.365,
			highest: 145.57,
			volumePrice: 145.4681,
			transactionCount: 1243,
			voluemPrice: 145.4681
		},
		{
			open: 145.56,
			close: 145.75,
			volume: 191976,
			time: 1654542120000,
			lowest: 145.5,
			highest: 145.75,
			volumePrice: 145.6144,
			transactionCount: 1447,
			voluemPrice: 145.6144
		},
		{
			open: 145.72,
			close: 145.66,
			volume: 166609,
			time: 1654542180000,
			lowest: 145.62,
			highest: 145.75,
			volumePrice: 145.6888,
			transactionCount: 1227,
			voluemPrice: 145.6888
		},
		{
			open: 145.67,
			close: 145.51,
			volume: 127860,
			time: 1654542240000,
			lowest: 145.5024,
			highest: 145.6773,
			volumePrice: 145.5796,
			transactionCount: 1017,
			voluemPrice: 145.5796
		},
		{
			open: 145.5,
			close: 145.59,
			volume: 113538,
			time: 1654542300000,
			lowest: 145.47,
			highest: 145.62,
			volumePrice: 145.5574,
			transactionCount: 942,
			voluemPrice: 145.5574
		},
		{
			open: 145.59,
			close: 145.45,
			volume: 122487,
			time: 1654542360000,
			lowest: 145.42,
			highest: 145.62,
			volumePrice: 145.4983,
			transactionCount: 1188,
			voluemPrice: 145.4983
		},
		{
			open: 145.45,
			close: 145.47,
			volume: 168514,
			time: 1654542420000,
			lowest: 145.295,
			highest: 145.505,
			volumePrice: 145.3997,
			transactionCount: 1369,
			voluemPrice: 145.3997
		},
		{
			open: 145.46,
			close: 145.4851,
			volume: 156318,
			time: 1654542480000,
			lowest: 145.34,
			highest: 145.5,
			volumePrice: 145.4293,
			transactionCount: 1359,
			voluemPrice: 145.4293
		},
		{
			open: 145.48,
			close: 145.447,
			volume: 150704,
			time: 1654542540000,
			lowest: 145.3914,
			highest: 145.5446,
			volumePrice: 145.4736,
			transactionCount: 1295,
			voluemPrice: 145.4736
		},
		{
			open: 145.435,
			close: 145.44,
			volume: 129690,
			time: 1654542600000,
			lowest: 145.35,
			highest: 145.47,
			volumePrice: 145.4121,
			transactionCount: 1210,
			voluemPrice: 145.4121
		},
		{
			open: 145.43,
			close: 145.41,
			volume: 100940,
			time: 1654542660000,
			lowest: 145.34,
			highest: 145.45,
			volumePrice: 145.4082,
			transactionCount: 955,
			voluemPrice: 145.4082
		},
		{
			open: 145.4199,
			close: 145.3345,
			volume: 83419,
			time: 1654542720000,
			lowest: 145.32,
			highest: 145.47,
			volumePrice: 145.3924,
			transactionCount: 909,
			voluemPrice: 145.3924
		},
		{
			open: 145.34,
			close: 145.4462,
			volume: 146531,
			time: 1654542780000,
			lowest: 145.26,
			highest: 145.4698,
			volumePrice: 145.3463,
			transactionCount: 1176,
			voluemPrice: 145.3463
		},
		{
			open: 145.47,
			close: 145.45,
			volume: 530837,
			time: 1654542840000,
			lowest: 145.42,
			highest: 145.585,
			volumePrice: 145.4776,
			transactionCount: 1525,
			voluemPrice: 145.4776
		},
		{
			open: 145.445,
			close: 145.4899,
			volume: 81670,
			time: 1654542900000,
			lowest: 145.39,
			highest: 145.49,
			volumePrice: 145.4337,
			transactionCount: 827,
			voluemPrice: 145.4337
		},
		{
			open: 145.47,
			close: 145.595,
			volume: 118292,
			time: 1654542960000,
			lowest: 145.43,
			highest: 145.64,
			volumePrice: 145.5455,
			transactionCount: 1005,
			voluemPrice: 145.5455
		},
		{
			open: 145.596,
			close: 145.52,
			volume: 85869,
			time: 1654543020000,
			lowest: 145.505,
			highest: 145.62,
			volumePrice: 145.5491,
			transactionCount: 907,
			voluemPrice: 145.5491
		},
		{
			open: 145.52,
			close: 145.42,
			volume: 76918,
			time: 1654543080000,
			lowest: 145.41,
			highest: 145.54,
			volumePrice: 145.4865,
			transactionCount: 905,
			voluemPrice: 145.4865
		},
		{
			open: 145.415,
			close: 145.1699,
			volume: 192636,
			time: 1654543140000,
			lowest: 145.1624,
			highest: 145.415,
			volumePrice: 145.2677,
			transactionCount: 1683,
			voluemPrice: 145.2677
		},
		{
			open: 145.165,
			close: 144.9,
			volume: 545533,
			time: 1654543200000,
			lowest: 144.9,
			highest: 145.18,
			volumePrice: 145.0043,
			transactionCount: 6283,
			voluemPrice: 145.0043
		},
		{
			open: 144.9,
			close: 145.26,
			volume: 258106,
			time: 1654543260000,
			lowest: 144.9,
			highest: 145.2694,
			volumePrice: 145.0844,
			transactionCount: 2275,
			voluemPrice: 145.0844
		},
		{
			open: 145.2441,
			close: 145.29,
			volume: 162114,
			time: 1654543320000,
			lowest: 145.215,
			highest: 145.35,
			volumePrice: 145.2786,
			transactionCount: 1364,
			voluemPrice: 145.2786
		},
		{
			open: 145.28,
			close: 145.3,
			volume: 123558,
			time: 1654543380000,
			lowest: 145.22,
			highest: 145.35,
			volumePrice: 145.2883,
			transactionCount: 1110,
			voluemPrice: 145.2883
		},
		{
			open: 145.305,
			close: 145.3,
			volume: 158141,
			time: 1654543440000,
			lowest: 145.25,
			highest: 145.3599,
			volumePrice: 145.3093,
			transactionCount: 1187,
			voluemPrice: 145.3093
		},
		{
			open: 145.31,
			close: 145.33,
			volume: 123980,
			time: 1654543500000,
			lowest: 145.28,
			highest: 145.3699,
			volumePrice: 145.3242,
			transactionCount: 1233,
			voluemPrice: 145.3242
		},
		{
			open: 145.33,
			close: 145.2965,
			volume: 137200,
			time: 1654543560000,
			lowest: 145.2882,
			highest: 145.39,
			volumePrice: 145.3437,
			transactionCount: 1198,
			voluemPrice: 145.3437
		},
		{
			open: 145.2907,
			close: 145.39,
			volume: 121333,
			time: 1654543620000,
			lowest: 145.25,
			highest: 145.395,
			volumePrice: 145.3204,
			transactionCount: 1024,
			voluemPrice: 145.3204
		},
		{
			open: 145.3889,
			close: 145.33,
			volume: 118000,
			time: 1654543680000,
			lowest: 145.28,
			highest: 145.43,
			volumePrice: 145.3738,
			transactionCount: 1034,
			voluemPrice: 145.3738
		},
		{
			open: 145.3298,
			close: 145.3495,
			volume: 92465,
			time: 1654543740000,
			lowest: 145.3,
			highest: 145.39,
			volumePrice: 145.3545,
			transactionCount: 885,
			voluemPrice: 145.3545
		},
		{
			open: 145.345,
			close: 145.34,
			volume: 136026,
			time: 1654543800000,
			lowest: 145.27,
			highest: 145.35,
			volumePrice: 145.316,
			transactionCount: 1229,
			voluemPrice: 145.316
		},
		{
			open: 145.345,
			close: 145.588,
			volume: 238105,
			time: 1654543860000,
			lowest: 145.34,
			highest: 145.6,
			volumePrice: 145.4941,
			transactionCount: 1933,
			voluemPrice: 145.4941
		},
		{
			open: 145.565,
			close: 145.54,
			volume: 131018,
			time: 1654543920000,
			lowest: 145.4603,
			highest: 145.64,
			volumePrice: 145.5471,
			transactionCount: 1430,
			voluemPrice: 145.5471
		},
		{
			open: 145.54,
			close: 145.585,
			volume: 134097,
			time: 1654543980000,
			lowest: 145.47,
			highest: 145.605,
			volumePrice: 145.5249,
			transactionCount: 1191,
			voluemPrice: 145.5249
		},
		{
			open: 145.58,
			close: 145.5201,
			volume: 108295,
			time: 1654544040000,
			lowest: 145.51,
			highest: 145.6079,
			volumePrice: 145.5513,
			transactionCount: 1027,
			voluemPrice: 145.5513
		},
		{
			open: 145.53,
			close: 145.545,
			volume: 114084,
			time: 1654544100000,
			lowest: 145.41,
			highest: 145.56,
			volumePrice: 145.4803,
			transactionCount: 1207,
			voluemPrice: 145.4803
		},
		{
			open: 145.5489,
			close: 145.44,
			volume: 101897,
			time: 1654544160000,
			lowest: 145.38,
			highest: 145.5489,
			volumePrice: 145.441,
			transactionCount: 998,
			voluemPrice: 145.441
		},
		{
			open: 145.44,
			close: 145.3,
			volume: 190027,
			time: 1654544220000,
			lowest: 145.27,
			highest: 145.44,
			volumePrice: 145.3494,
			transactionCount: 1696,
			voluemPrice: 145.3494
		},
		{
			open: 145.29,
			close: 145.505,
			volume: 173903,
			time: 1654544280000,
			lowest: 145.2401,
			highest: 145.5088,
			volumePrice: 145.349,
			transactionCount: 1553,
			voluemPrice: 145.349
		},
		{
			open: 145.51,
			close: 145.4701,
			volume: 113918,
			time: 1654544340000,
			lowest: 145.45,
			highest: 145.5685,
			volumePrice: 145.5121,
			transactionCount: 1105,
			voluemPrice: 145.5121
		},
		{
			open: 145.46,
			close: 145.585,
			volume: 159425,
			time: 1654544400000,
			lowest: 145.36,
			highest: 145.6,
			volumePrice: 145.4802,
			transactionCount: 1316,
			voluemPrice: 145.4802
		},
		{
			open: 145.59,
			close: 145.84,
			volume: 283391,
			time: 1654544460000,
			lowest: 145.59,
			highest: 145.86,
			volumePrice: 145.7259,
			transactionCount: 2332,
			voluemPrice: 145.7259
		},
		{
			open: 145.84,
			close: 145.9699,
			volume: 377566,
			time: 1654544520000,
			lowest: 145.82,
			highest: 146.09,
			volumePrice: 145.9581,
			transactionCount: 2747,
			voluemPrice: 145.9581
		},
		{
			open: 145.95,
			close: 145.8,
			volume: 180450,
			time: 1654544580000,
			lowest: 145.785,
			highest: 145.97,
			volumePrice: 145.8682,
			transactionCount: 1506,
			voluemPrice: 145.8682
		},
		{
			open: 145.8,
			close: 145.89,
			volume: 89029,
			time: 1654544640000,
			lowest: 145.77,
			highest: 145.89,
			volumePrice: 145.8311,
			transactionCount: 978,
			voluemPrice: 145.8311
		},
		{
			open: 145.8899,
			close: 145.8188,
			volume: 161693,
			time: 1654544700000,
			lowest: 145.8078,
			highest: 145.98,
			volumePrice: 145.8844,
			transactionCount: 1403,
			voluemPrice: 145.8844
		},
		{
			open: 145.815,
			close: 145.66,
			volume: 175802,
			time: 1654544760000,
			lowest: 145.6206,
			highest: 145.825,
			volumePrice: 145.735,
			transactionCount: 1392,
			voluemPrice: 145.735
		},
		{
			open: 145.65,
			close: 145.6101,
			volume: 166144,
			time: 1654544820000,
			lowest: 145.54,
			highest: 145.655,
			volumePrice: 145.5901,
			transactionCount: 1519,
			voluemPrice: 145.5901
		},
		{
			open: 145.61,
			close: 145.6513,
			volume: 138806,
			time: 1654544880000,
			lowest: 145.58,
			highest: 145.71,
			volumePrice: 145.6439,
			transactionCount: 1299,
			voluemPrice: 145.6439
		},
		{
			open: 145.635,
			close: 145.73,
			volume: 190535,
			time: 1654544940000,
			lowest: 145.63,
			highest: 145.85,
			volumePrice: 145.7563,
			transactionCount: 1588,
			voluemPrice: 145.7563
		},
		{
			open: 145.74,
			close: 145.895,
			volume: 377018,
			time: 1654545000000,
			lowest: 145.74,
			highest: 146.1129,
			volumePrice: 145.9735,
			transactionCount: 3990,
			voluemPrice: 145.9735
		},
		{
			open: 145.89,
			close: 145.96,
			volume: 182611,
			time: 1654545060000,
			lowest: 145.74,
			highest: 146,
			volumePrice: 145.8807,
			transactionCount: 1982,
			voluemPrice: 145.8807
		},
		{
			open: 145.965,
			close: 145.965,
			volume: 189191,
			time: 1654545120000,
			lowest: 145.79,
			highest: 146,
			volumePrice: 145.888,
			transactionCount: 2125,
			voluemPrice: 145.888
		},
		{
			open: 145.97,
			close: 145.9457,
			volume: 231533,
			time: 1654545180000,
			lowest: 145.8976,
			highest: 146.1,
			volumePrice: 145.9798,
			transactionCount: 2390,
			voluemPrice: 145.9798
		},
		{
			open: 145.95,
			close: 146.155,
			volume: 287690,
			time: 1654545240000,
			lowest: 145.91,
			highest: 146.26,
			volumePrice: 146.0717,
			transactionCount: 3009,
			voluemPrice: 146.0717
		},
		{
			open: 146.15,
			close: 146.26,
			volume: 385270,
			time: 1654545300000,
			lowest: 146.07,
			highest: 146.38,
			volumePrice: 146.2311,
			transactionCount: 3394,
			voluemPrice: 146.2311
		},
		{
			open: 146.2664,
			close: 146.305,
			volume: 306660,
			time: 1654545360000,
			lowest: 146.2,
			highest: 146.345,
			volumePrice: 146.2937,
			transactionCount: 2502,
			voluemPrice: 146.2937
		},
		{
			open: 146.305,
			close: 146.14,
			volume: 333291,
			time: 1654545420000,
			lowest: 146.125,
			highest: 146.33,
			volumePrice: 146.2199,
			transactionCount: 2741,
			voluemPrice: 146.2199
		},
		{
			open: 146.14,
			close: 146.1468,
			volume: 358166,
			time: 1654545480000,
			lowest: 146.08,
			highest: 146.18,
			volumePrice: 146.1248,
			transactionCount: 3105,
			voluemPrice: 146.1248
		},
		{
			open: 146.15,
			close: 146.14,
			volume: 1364771,
			time: 1654545540000,
			lowest: 146.09,
			highest: 146.47,
			volumePrice: 146.3279,
			transactionCount: 8042,
			voluemPrice: 146.3279
		},
		{
			open: 146.14,
			close: 146.14,
			volume: 2270544,
			time: 1654545600000,
			lowest: 146.07,
			highest: 146.5,
			volumePrice: 146.1425,
			transactionCount: 183,
			voluemPrice: 146.1425
		},
		{
			open: 146.14,
			close: 146.16,
			volume: 292307,
			time: 1654545660000,
			lowest: 146.14,
			highest: 146.3,
			volumePrice: 146.1429,
			transactionCount: 116,
			voluemPrice: 146.1429
		},
		{
			open: 146.15,
			close: 146.17,
			volume: 4365,
			time: 1654545720000,
			lowest: 146.15,
			highest: 146.22,
			volumePrice: 146.192,
			transactionCount: 72,
			voluemPrice: 146.192
		},
		{
			open: 146.18,
			close: 146.16,
			volume: 11432,
			time: 1654545780000,
			lowest: 146.15,
			highest: 146.27,
			volumePrice: 146.1944,
			transactionCount: 127,
			voluemPrice: 146.1944
		},
		{
			open: 146.2,
			close: 146.14,
			volume: 9190,
			time: 1654545840000,
			lowest: 146.14,
			highest: 146.25,
			volumePrice: 146.1665,
			transactionCount: 97,
			voluemPrice: 146.1665
		},
		{
			open: 146.14,
			close: 146.14,
			volume: 4758,
			time: 1654545900000,
			lowest: 146.14,
			highest: 146.15,
			volumePrice: 146.1621,
			transactionCount: 230,
			voluemPrice: 146.1621
		},
		{
			open: 146.17,
			close: 146.16,
			volume: 9355,
			time: 1654545960000,
			lowest: 146.15,
			highest: 146.25,
			volumePrice: 146.1525,
			transactionCount: 789,
			voluemPrice: 146.1525
		},
		{
			open: 146.17,
			close: 146.19,
			volume: 30680,
			time: 1654546020000,
			lowest: 146.14,
			highest: 146.2084,
			volumePrice: 146.1428,
			transactionCount: 67,
			voluemPrice: 146.1428
		},
		{
			open: 146.16,
			close: 146.16,
			volume: 6080,
			time: 1654546080000,
			lowest: 146.14,
			highest: 146.16,
			volumePrice: 146.1489,
			transactionCount: 73,
			voluemPrice: 146.1489
		},
		{
			open: 146.15,
			close: 146.15,
			volume: 1406,
			time: 1654546140000,
			lowest: 146.15,
			highest: 146.15,
			volumePrice: 146.1509,
			transactionCount: 35,
			voluemPrice: 146.1509
		},
		{
			open: 146.14,
			close: 146.14,
			volume: 6048,
			time: 1654546200000,
			lowest: 146.14,
			highest: 146.16,
			volumePrice: 146.1435,
			transactionCount: 75,
			voluemPrice: 146.1435
		},
		{
			open: 146.15,
			close: 146.17,
			volume: 6998,
			time: 1654546260000,
			lowest: 146.14,
			highest: 146.18,
			volumePrice: 146.156,
			transactionCount: 79,
			voluemPrice: 146.156
		},
		{
			open: 146.14,
			close: 146.18,
			volume: 4685,
			time: 1654546320000,
			lowest: 146.14,
			highest: 146.2,
			volumePrice: 146.1682,
			transactionCount: 81,
			voluemPrice: 146.1682
		},
		{
			open: 146.18,
			close: 146.14,
			volume: 8568,
			time: 1654546380000,
			lowest: 146.14,
			highest: 146.18,
			volumePrice: 146.1433,
			transactionCount: 46,
			voluemPrice: 146.1433
		},
		{
			open: 146.14,
			close: 146.11,
			volume: 3147,
			time: 1654546440000,
			lowest: 146.11,
			highest: 146.14,
			volumePrice: 146.1275,
			transactionCount: 64,
			voluemPrice: 146.1275
		},
		{
			open: 146.11,
			close: 146.14,
			volume: 10566,
			time: 1654546500000,
			lowest: 146.11,
			highest: 146.16,
			volumePrice: 146.14,
			transactionCount: 37,
			voluemPrice: 146.14
		},
		{
			open: 146.12,
			close: 146.17,
			volume: 3000,
			time: 1654546560000,
			lowest: 146.11,
			highest: 146.17,
			volumePrice: 146.126,
			transactionCount: 59,
			voluemPrice: 146.126
		},
		{
			open: 146.14,
			close: 146.1,
			volume: 6130,
			time: 1654546620000,
			lowest: 146.1,
			highest: 146.14,
			volumePrice: 146.1282,
			transactionCount: 50,
			voluemPrice: 146.1282
		},
		{
			open: 146.06,
			close: 146.1,
			volume: 9146,
			time: 1654546680000,
			lowest: 146.05,
			highest: 146.1,
			volumePrice: 146.0709,
			transactionCount: 62,
			voluemPrice: 146.0709
		},
		{
			open: 146.12,
			close: 146.14,
			volume: 1877,
			time: 1654546740000,
			lowest: 146.08,
			highest: 146.14,
			volumePrice: 146.1076,
			transactionCount: 49,
			voluemPrice: 146.1076
		},
		{
			open: 146.1499,
			close: 146.14,
			volume: 2638,
			time: 1654546800000,
			lowest: 146.14,
			highest: 146.15,
			volumePrice: 146.1441,
			transactionCount: 45,
			voluemPrice: 146.1441
		},
		{
			open: 146.14,
			close: 146.2,
			volume: 2060,
			time: 1654546860000,
			lowest: 146.14,
			highest: 146.2,
			volumePrice: 146.182,
			transactionCount: 60,
			voluemPrice: 146.182
		},
		{
			open: 146.2,
			close: 146.22,
			volume: 1985,
			time: 1654546920000,
			lowest: 146.2,
			highest: 146.22,
			volumePrice: 146.2181,
			transactionCount: 44,
			voluemPrice: 146.2181
		},
		{
			open: 146.24,
			close: 146.2,
			volume: 766,
			time: 1654546980000,
			lowest: 146.2,
			highest: 146.24,
			volumePrice: 146.2169,
			transactionCount: 24,
			voluemPrice: 146.2169
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 23589,
			time: 1654547040000,
			lowest: 146.14,
			highest: 146.2,
			volumePrice: 146.1447,
			transactionCount: 41,
			voluemPrice: 146.1447
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 1168,
			time: 1654547100000,
			lowest: 146.22,
			highest: 146.23,
			volumePrice: 146.2264,
			transactionCount: 39,
			voluemPrice: 146.2264
		},
		{
			open: 146.23,
			close: 146.22,
			volume: 729,
			time: 1654547160000,
			lowest: 146.2,
			highest: 146.23,
			volumePrice: 146.2119,
			transactionCount: 39,
			voluemPrice: 146.2119
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 4205,
			time: 1654547220000,
			lowest: 146.18,
			highest: 146.23,
			volumePrice: 146.2076,
			transactionCount: 47,
			voluemPrice: 146.2076
		},
		{
			open: 146.22,
			close: 146.2,
			volume: 1433,
			time: 1654547280000,
			lowest: 146.2,
			highest: 146.22,
			volumePrice: 146.2158,
			transactionCount: 22,
			voluemPrice: 146.2158
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 1473,
			time: 1654547340000,
			lowest: 146.2,
			highest: 146.2001,
			volumePrice: 146.2025,
			transactionCount: 35,
			voluemPrice: 146.2025
		},
		{
			open: 146.21,
			close: 146.23,
			volume: 1517,
			time: 1654547400000,
			lowest: 146.2,
			highest: 146.24,
			volumePrice: 146.2251,
			transactionCount: 35,
			voluemPrice: 146.2251
		},
		{
			open: 146.24,
			close: 146.24,
			volume: 2049,
			time: 1654547460000,
			lowest: 146.14,
			highest: 146.24,
			volumePrice: 146.1677,
			transactionCount: 29,
			voluemPrice: 146.1677
		},
		{
			open: 146.2,
			close: 146.22,
			volume: 2194,
			time: 1654547520000,
			lowest: 146.2,
			highest: 146.22,
			volumePrice: 146.2124,
			transactionCount: 41,
			voluemPrice: 146.2124
		},
		{
			open: 146.24,
			close: 146.14,
			volume: 315662,
			time: 1654547580000,
			lowest: 146.14,
			highest: 146.24,
			volumePrice: 146.1402,
			transactionCount: 29,
			voluemPrice: 146.1402
		},
		{
			open: 146.21,
			close: 146.2,
			volume: 960,
			time: 1654547640000,
			lowest: 146.2,
			highest: 146.21,
			volumePrice: 146.2079,
			transactionCount: 26,
			voluemPrice: 146.2079
		},
		{
			open: 146.23,
			close: 146.25,
			volume: 1401,
			time: 1654547700000,
			lowest: 146.23,
			highest: 146.25,
			volumePrice: 146.2334,
			transactionCount: 47,
			voluemPrice: 146.2334
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 960,
			time: 1654547760000,
			lowest: 146.24,
			highest: 146.25,
			volumePrice: 146.2448,
			transactionCount: 41,
			voluemPrice: 146.2448
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 394,
			time: 1654547820000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2477,
			transactionCount: 26,
			voluemPrice: 146.2477
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 612,
			time: 1654547880000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2384,
			transactionCount: 32,
			voluemPrice: 146.2384
		},
		{
			open: 146.26,
			close: 146.26,
			volume: 4118,
			time: 1654547940000,
			lowest: 146.25,
			highest: 146.28,
			volumePrice: 146.2609,
			transactionCount: 42,
			voluemPrice: 146.2609
		},
		{
			open: 146.25,
			close: 146.23,
			volume: 1616,
			time: 1654548180000,
			lowest: 146.23,
			highest: 146.25,
			volumePrice: 146.2342,
			transactionCount: 34,
			voluemPrice: 146.2342
		},
		{
			open: 146.25,
			close: 146.23,
			volume: 903,
			time: 1654548240000,
			lowest: 146.22,
			highest: 146.25,
			volumePrice: 146.24,
			transactionCount: 24,
			voluemPrice: 146.24
		},
		{
			open: 146.25,
			close: 146.21,
			volume: 450,
			time: 1654548300000,
			lowest: 146.21,
			highest: 146.25,
			volumePrice: 146.238,
			transactionCount: 33,
			voluemPrice: 146.238
		},
		{
			open: 146.24,
			close: 146.22,
			volume: 1980,
			time: 1654548360000,
			lowest: 146.22,
			highest: 146.26,
			volumePrice: 146.2442,
			transactionCount: 39,
			voluemPrice: 146.2442
		},
		{
			open: 146.25,
			close: 146.29,
			volume: 3626,
			time: 1654548420000,
			lowest: 146.25,
			highest: 146.29,
			volumePrice: 146.2731,
			transactionCount: 64,
			voluemPrice: 146.2731
		},
		{
			open: 146.28,
			close: 146.25,
			volume: 56743,
			time: 1654548480000,
			lowest: 146.14,
			highest: 146.28,
			volumePrice: 146.1464,
			transactionCount: 33,
			voluemPrice: 146.1464
		},
		{
			open: 146.3,
			close: 146.25,
			volume: 1184,
			time: 1654548600000,
			lowest: 146.25,
			highest: 146.3,
			volumePrice: 146.2942,
			transactionCount: 14,
			voluemPrice: 146.2942
		},
		{
			open: 146.27,
			close: 146.27,
			volume: 1537,
			time: 1654548660000,
			lowest: 146.27,
			highest: 146.27,
			volumePrice: 146.2687,
			transactionCount: 24,
			voluemPrice: 146.2687
		},
		{
			open: 146.2507,
			close: 146.2899,
			volume: 1013,
			time: 1654548720000,
			lowest: 146.25,
			highest: 146.2899,
			volumePrice: 146.2722,
			transactionCount: 30,
			voluemPrice: 146.2722
		},
		{
			open: 146.25,
			close: 146.26,
			volume: 544,
			time: 1654548780000,
			lowest: 146.25,
			highest: 146.26,
			volumePrice: 146.2562,
			transactionCount: 33,
			voluemPrice: 146.2562
		},
		{
			open: 146.27,
			close: 146.29,
			volume: 2182,
			time: 1654548840000,
			lowest: 146.27,
			highest: 146.29,
			volumePrice: 146.2766,
			transactionCount: 33,
			voluemPrice: 146.2766
		},
		{
			open: 146.3,
			close: 146.32,
			volume: 2714,
			time: 1654548900000,
			lowest: 146.3,
			highest: 146.32,
			volumePrice: 146.3039,
			transactionCount: 33,
			voluemPrice: 146.3039
		},
		{
			open: 146.29,
			close: 146.27,
			volume: 867,
			time: 1654548960000,
			lowest: 146.27,
			highest: 146.29,
			volumePrice: 146.291,
			transactionCount: 30,
			voluemPrice: 146.291
		},
		{
			open: 146.31,
			close: 146.27,
			volume: 938,
			time: 1654549020000,
			lowest: 146.27,
			highest: 146.32,
			volumePrice: 145.2207,
			transactionCount: 27,
			voluemPrice: 145.2207
		},
		{
			open: 146.27,
			close: 146.21,
			volume: 6406,
			time: 1654549200000,
			lowest: 146.21,
			highest: 146.27,
			volumePrice: 146.2319,
			transactionCount: 52,
			voluemPrice: 146.2319
		},
		{
			open: 146.21,
			close: 146.24,
			volume: 1888,
			time: 1654549260000,
			lowest: 146.1791,
			highest: 146.24,
			volumePrice: 146.2249,
			transactionCount: 47,
			voluemPrice: 146.2249
		},
		{
			open: 146.24,
			close: 146.35,
			volume: 9844,
			time: 1654549320000,
			lowest: 146.24,
			highest: 146.35,
			volumePrice: 146.2556,
			transactionCount: 56,
			voluemPrice: 146.2556
		},
		{
			open: 146.35,
			close: 146.35,
			volume: 535,
			time: 1654549440000,
			lowest: 146.35,
			highest: 146.35,
			volumePrice: 146.3393,
			transactionCount: 23,
			voluemPrice: 146.3393
		},
		{
			open: 146.35,
			close: 146.39,
			volume: 3744,
			time: 1654549620000,
			lowest: 146.35,
			highest: 146.39,
			volumePrice: 146.362,
			transactionCount: 46,
			voluemPrice: 146.362
		},
		{
			open: 146.4,
			close: 146.36,
			volume: 5096,
			time: 1654549680000,
			lowest: 146.36,
			highest: 146.42,
			volumePrice: 146.4096,
			transactionCount: 57,
			voluemPrice: 146.4096
		},
		{
			open: 146.39,
			close: 146.33,
			volume: 2227,
			time: 1654549740000,
			lowest: 146.33,
			highest: 146.39,
			volumePrice: 146.3402,
			transactionCount: 32,
			voluemPrice: 146.3402
		},
		{
			open: 146.34,
			close: 146.34,
			volume: 283,
			time: 1654549860000,
			lowest: 146.34,
			highest: 146.34,
			volumePrice: 146.3579,
			transactionCount: 18,
			voluemPrice: 146.3579
		},
		{
			open: 146.4,
			close: 146.41,
			volume: 614,
			time: 1654549920000,
			lowest: 146.4,
			highest: 146.41,
			volumePrice: 146.3958,
			transactionCount: 18,
			voluemPrice: 146.3958
		},
		{
			open: 146.35,
			close: 146.34,
			volume: 1029,
			time: 1654549980000,
			lowest: 146.34,
			highest: 146.35,
			volumePrice: 146.342,
			transactionCount: 24,
			voluemPrice: 146.342
		},
		{
			open: 146.32,
			close: 146.3964,
			volume: 830,
			time: 1654550040000,
			lowest: 146.32,
			highest: 146.3964,
			volumePrice: 146.3695,
			transactionCount: 18,
			voluemPrice: 146.3695
		},
		{
			open: 146.36,
			close: 146.14,
			volume: 10898,
			time: 1654550160000,
			lowest: 146.14,
			highest: 146.36,
			volumePrice: 146.1526,
			transactionCount: 21,
			voluemPrice: 146.1526
		},
		{
			open: 146.3416,
			close: 146.3416,
			volume: 124,
			time: 1654550280000,
			lowest: 146.3416,
			highest: 146.3416,
			volumePrice: 146.3455,
			transactionCount: 14,
			voluemPrice: 146.3455
		},
		{
			open: 146.32,
			close: 146.34,
			volume: 1121,
			time: 1654550340000,
			lowest: 146.32,
			highest: 146.34,
			volumePrice: 146.328,
			transactionCount: 20,
			voluemPrice: 146.328
		},
		{
			open: 146.3005,
			close: 146.3,
			volume: 1657,
			time: 1654550400000,
			lowest: 146.3,
			highest: 146.3005,
			volumePrice: 146.3015,
			transactionCount: 27,
			voluemPrice: 146.3015
		},
		{
			open: 146.3,
			close: 146.3,
			volume: 314,
			time: 1654550460000,
			lowest: 146.3,
			highest: 146.3,
			volumePrice: 146.3021,
			transactionCount: 21,
			voluemPrice: 146.3021
		},
		{
			open: 146.28,
			close: 146.28,
			volume: 464,
			time: 1654550520000,
			lowest: 146.28,
			highest: 146.28,
			volumePrice: 146.2847,
			transactionCount: 23,
			voluemPrice: 146.2847
		},
		{
			open: 146.27,
			close: 146.27,
			volume: 456,
			time: 1654550580000,
			lowest: 146.27,
			highest: 146.27,
			volumePrice: 146.2705,
			transactionCount: 19,
			voluemPrice: 146.2705
		},
		{
			open: 146.279,
			close: 146.279,
			volume: 245,
			time: 1654550700000,
			lowest: 146.279,
			highest: 146.279,
			volumePrice: 146.2737,
			transactionCount: 10,
			voluemPrice: 146.2737
		},
		{
			open: 146.27,
			close: 146.23,
			volume: 925,
			time: 1654550760000,
			lowest: 146.23,
			highest: 146.27,
			volumePrice: 146.2478,
			transactionCount: 32,
			voluemPrice: 146.2478
		},
		{
			open: 146.21,
			close: 146.25,
			volume: 3870,
			time: 1654550820000,
			lowest: 146.2,
			highest: 146.25,
			volumePrice: 146.2152,
			transactionCount: 37,
			voluemPrice: 146.2152
		},
		{
			open: 146.24,
			close: 146.2399,
			volume: 1672,
			time: 1654550880000,
			lowest: 146.2,
			highest: 146.24,
			volumePrice: 146.2127,
			transactionCount: 27,
			voluemPrice: 146.2127
		},
		{
			open: 146.24,
			close: 146.24,
			volume: 2559,
			time: 1654550940000,
			lowest: 146.23,
			highest: 146.24,
			volumePrice: 146.2383,
			transactionCount: 18,
			voluemPrice: 146.2383
		},
		{
			open: 146.2,
			close: 146.17,
			volume: 266,
			time: 1654551000000,
			lowest: 146.17,
			highest: 146.2,
			volumePrice: 146.1864,
			transactionCount: 18,
			voluemPrice: 146.1864
		},
		{
			open: 146.22,
			close: 146.22,
			volume: 1168,
			time: 1654551060000,
			lowest: 146.22,
			highest: 146.22,
			volumePrice: 146.2174,
			transactionCount: 20,
			voluemPrice: 146.2174
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 242,
			time: 1654551120000,
			lowest: 146.2,
			highest: 146.2,
			volumePrice: 146.2019,
			transactionCount: 24,
			voluemPrice: 146.2019
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 547,
			time: 1654551360000,
			lowest: 146.2,
			highest: 146.2,
			volumePrice: 146.1996,
			transactionCount: 17,
			voluemPrice: 146.1996
		},
		{
			open: 146.21,
			close: 146.2,
			volume: 337,
			time: 1654551420000,
			lowest: 146.2,
			highest: 146.21,
			volumePrice: 146.2057,
			transactionCount: 18,
			voluemPrice: 146.2057
		},
		{
			open: 146.1801,
			close: 146.1801,
			volume: 566,
			time: 1654551480000,
			lowest: 146.1801,
			highest: 146.1801,
			volumePrice: 146.1823,
			transactionCount: 15,
			voluemPrice: 146.1823
		},
		{
			open: 146.2,
			close: 146.2,
			volume: 723,
			time: 1654551540000,
			lowest: 146.19,
			highest: 146.2,
			volumePrice: 146.1974,
			transactionCount: 20,
			voluemPrice: 146.1974
		},
		{
			open: 146.18,
			close: 146.18,
			volume: 286,
			time: 1654551600000,
			lowest: 146.18,
			highest: 146.18,
			volumePrice: 146.1809,
			transactionCount: 14,
			voluemPrice: 146.1809
		},
		{
			open: 146.18,
			close: 146.18,
			volume: 925,
			time: 1654551660000,
			lowest: 146.18,
			highest: 146.18,
			volumePrice: 146.184,
			transactionCount: 10,
			voluemPrice: 146.184
		},
		{
			open: 146.22,
			close: 146.22,
			volume: 648,
			time: 1654551720000,
			lowest: 146.22,
			highest: 146.22,
			volumePrice: 146.2199,
			transactionCount: 12,
			voluemPrice: 146.2199
		},
		{
			open: 146.22,
			close: 146.23,
			volume: 577,
			time: 1654551780000,
			lowest: 146.22,
			highest: 146.23,
			volumePrice: 146.2269,
			transactionCount: 14,
			voluemPrice: 146.2269
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 343,
			time: 1654551840000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2294,
			transactionCount: 18,
			voluemPrice: 146.2294
		},
		{
			open: 146.22,
			close: 146.22,
			volume: 703,
			time: 1654551900000,
			lowest: 146.22,
			highest: 146.22,
			volumePrice: 146.2212,
			transactionCount: 15,
			voluemPrice: 146.2212
		},
		{
			open: 146.23,
			close: 146.25,
			volume: 802,
			time: 1654551960000,
			lowest: 146.23,
			highest: 146.25,
			volumePrice: 146.2407,
			transactionCount: 16,
			voluemPrice: 146.2407
		},
		{
			open: 146.29,
			close: 146.29,
			volume: 120,
			time: 1654552080000,
			lowest: 146.29,
			highest: 146.29,
			volumePrice: 146.2867,
			transactionCount: 9,
			voluemPrice: 146.2867
		},
		{
			open: 146.29,
			close: 146.29,
			volume: 2692,
			time: 1654552260000,
			lowest: 146.29,
			highest: 146.29,
			volumePrice: 146.2883,
			transactionCount: 26,
			voluemPrice: 146.2883
		},
		{
			open: 146.27,
			close: 146.27,
			volume: 180,
			time: 1654552320000,
			lowest: 146.27,
			highest: 146.27,
			volumePrice: 146.2712,
			transactionCount: 12,
			voluemPrice: 146.2712
		},
		{
			open: 146.274,
			close: 146.274,
			volume: 328,
			time: 1654552380000,
			lowest: 146.274,
			highest: 146.274,
			volumePrice: 146.272,
			transactionCount: 17,
			voluemPrice: 146.272
		},
		{
			open: 146.2701,
			close: 146.2701,
			volume: 205,
			time: 1654552440000,
			lowest: 146.2701,
			highest: 146.2701,
			volumePrice: 146.2768,
			transactionCount: 15,
			voluemPrice: 146.2768
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 403,
			time: 1654552500000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2566,
			transactionCount: 13,
			voluemPrice: 146.2566
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 297,
			time: 1654552620000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2569,
			transactionCount: 8,
			voluemPrice: 146.2569
		},
		{
			open: 146.28,
			close: 146.2883,
			volume: 603,
			time: 1654552680000,
			lowest: 146.28,
			highest: 146.2883,
			volumePrice: 146.2852,
			transactionCount: 21,
			voluemPrice: 146.2852
		},
		{
			open: 146.29,
			close: 146.3,
			volume: 512,
			time: 1654552800000,
			lowest: 146.29,
			highest: 146.3,
			volumePrice: 146.2909,
			transactionCount: 19,
			voluemPrice: 146.2909
		},
		{
			open: 146.29,
			close: 146.3,
			volume: 841,
			time: 1654552860000,
			lowest: 146.29,
			highest: 146.3,
			volumePrice: 146.2976,
			transactionCount: 16,
			voluemPrice: 146.2976
		},
		{
			open: 146.3,
			close: 146.3,
			volume: 723,
			time: 1654552920000,
			lowest: 146.3,
			highest: 146.3,
			volumePrice: 146.2988,
			transactionCount: 23,
			voluemPrice: 146.2988
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 3291,
			time: 1654552980000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2513,
			transactionCount: 28,
			voluemPrice: 146.2513
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 2343,
			time: 1654553040000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2509,
			transactionCount: 16,
			voluemPrice: 146.2509
		},
		{
			open: 146.29,
			close: 146.28,
			volume: 256,
			time: 1654553160000,
			lowest: 146.28,
			highest: 146.29,
			volumePrice: 146.2789,
			transactionCount: 11,
			voluemPrice: 146.2789
		},
		{
			open: 146.24,
			close: 146.22,
			volume: 923,
			time: 1654553340000,
			lowest: 146.22,
			highest: 146.24,
			volumePrice: 146.2292,
			transactionCount: 21,
			voluemPrice: 146.2292
		},
		{
			open: 146.25,
			close: 146.25,
			volume: 454,
			time: 1654553580000,
			lowest: 146.25,
			highest: 146.25,
			volumePrice: 146.2503,
			transactionCount: 19,
			voluemPrice: 146.2503
		},
		{
			open: 146.22,
			close: 146.22,
			volume: 360,
			time: 1654553640000,
			lowest: 146.22,
			highest: 146.22,
			volumePrice: 146.2385,
			transactionCount: 15,
			voluemPrice: 146.2385
		},
		{
			open: 146.22,
			close: 146.22,
			volume: 617,
			time: 1654553700000,
			lowest: 146.22,
			highest: 146.22,
			volumePrice: 146.2186,
			transactionCount: 27,
			voluemPrice: 146.2186
		},
		{
			open: 146.19,
			close: 146.21,
			volume: 996,
			time: 1654553880000,
			lowest: 146.18,
			highest: 146.21,
			volumePrice: 146.201,
			transactionCount: 23,
			voluemPrice: 146.201
		},
		{
			open: 146.18,
			close: 146.18,
			volume: 277,
			time: 1654553940000,
			lowest: 146.18,
			highest: 146.18,
			volumePrice: 146.1874,
			transactionCount: 18,
			voluemPrice: 146.1874
		},
		{
			open: 146.22,
			close: 146.23,
			volume: 1339,
			time: 1654554000000,
			lowest: 146.22,
			highest: 146.23,
			volumePrice: 146.2181,
			transactionCount: 26,
			voluemPrice: 146.2181
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 711,
			time: 1654554060000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.236,
			transactionCount: 17,
			voluemPrice: 146.236
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 108,
			time: 1654554120000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2312,
			transactionCount: 8,
			voluemPrice: 146.2312
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 266,
			time: 1654554180000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2312,
			transactionCount: 16,
			voluemPrice: 146.2312
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 977,
			time: 1654554240000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2304,
			transactionCount: 15,
			voluemPrice: 146.2304
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 657,
			time: 1654554300000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.231,
			transactionCount: 17,
			voluemPrice: 146.231
		},
		{
			open: 146.2499,
			close: 146.23,
			volume: 3263,
			time: 1654554360000,
			lowest: 146.22,
			highest: 146.2499,
			volumePrice: 146.2288,
			transactionCount: 33,
			voluemPrice: 146.2288
		},
		{
			open: 146.24,
			close: 146.24,
			volume: 484,
			time: 1654554420000,
			lowest: 146.24,
			highest: 146.24,
			volumePrice: 146.2387,
			transactionCount: 15,
			voluemPrice: 146.2387
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 1251,
			time: 1654554660000,
			lowest: 146.2,
			highest: 146.23,
			volumePrice: 146.2218,
			transactionCount: 22,
			voluemPrice: 146.2218
		},
		{
			open: 146.23,
			close: 146.23,
			volume: 1281,
			time: 1654554720000,
			lowest: 146.23,
			highest: 146.23,
			volumePrice: 146.2302,
			transactionCount: 17,
			voluemPrice: 146.2302
		},
		{
			open: 146.23,
			close: 146.14,
			volume: 4936,
			time: 1654554780000,
			lowest: 146.14,
			highest: 146.23,
			volumePrice: 146.1681,
			transactionCount: 66,
			voluemPrice: 146.1681
		},
		{
			open: 146.14,
			close: 146.14,
			volume: 3070,
			time: 1654554840000,
			lowest: 146.14,
			highest: 146.14,
			volumePrice: 146.1411,
			transactionCount: 15,
			voluemPrice: 146.1411
		},
		{
			open: 146.14,
			close: 146.15,
			volume: 486,
			time: 1654554960000,
			lowest: 146.14,
			highest: 146.15,
			volumePrice: 146.1479,
			transactionCount: 23,
			voluemPrice: 146.1479
		},
		{
			open: 146.15,
			close: 146.14,
			volume: 7309,
			time: 1654555020000,
			lowest: 146.14,
			highest: 146.15,
			volumePrice: 146.142,
			transactionCount: 95,
			voluemPrice: 146.142
		},
		{
			open: 146.14,
			close: 146.06,
			volume: 4270,
			time: 1654555080000,
			lowest: 146.05,
			highest: 146.14,
			volumePrice: 146.1045,
			transactionCount: 49,
			voluemPrice: 146.1045
		},
		{
			open: 146.09,
			close: 146.13,
			volume: 1036,
			time: 1654555140000,
			lowest: 146.09,
			highest: 146.13,
			volumePrice: 146.1191,
			transactionCount: 13,
			voluemPrice: 146.1191
		},
		{
			open: 146.13,
			close: 146.13,
			volume: 1025,
			time: 1654555260000,
			lowest: 146.13,
			highest: 146.13,
			volumePrice: 146.1296,
			transactionCount: 15,
			voluemPrice: 146.1296
		},
		{
			open: 146.13,
			close: 146.13,
			volume: 620,
			time: 1654555320000,
			lowest: 146.13,
			highest: 146.13,
			volumePrice: 146.1295,
			transactionCount: 11,
			voluemPrice: 146.1295
		},
		{
			open: 146.08,
			close: 146.05,
			volume: 1046,
			time: 1654555440000,
			lowest: 146.03,
			highest: 146.08,
			volumePrice: 146.0683,
			transactionCount: 27,
			voluemPrice: 146.0683
		},
		{
			open: 146.07,
			close: 146.08,
			volume: 479,
			time: 1654555500000,
			lowest: 146.07,
			highest: 146.08,
			volumePrice: 146.0815,
			transactionCount: 11,
			voluemPrice: 146.0815
		},
		{
			open: 146.1,
			close: 146.09,
			volume: 872,
			time: 1654555560000,
			lowest: 146.09,
			highest: 146.1,
			volumePrice: 146.0974,
			transactionCount: 19,
			voluemPrice: 146.0974
		},
		{
			open: 146.12,
			close: 146.08,
			volume: 453,
			time: 1654555620000,
			lowest: 146.08,
			highest: 146.12,
			volumePrice: 146.1075,
			transactionCount: 21,
			voluemPrice: 146.1075
		},
		{
			open: 146.12,
			close: 146.12,
			volume: 376,
			time: 1654555740000,
			lowest: 146.12,
			highest: 146.12,
			volumePrice: 146.1117,
			transactionCount: 17,
			voluemPrice: 146.1117
		},
		{
			open: 146.06,
			close: 146.06,
			volume: 335,
			time: 1654555800000,
			lowest: 146.06,
			highest: 146.06,
			volumePrice: 146.0635,
			transactionCount: 19,
			voluemPrice: 146.0635
		},
		{
			open: 146.06,
			close: 146.06,
			volume: 128,
			time: 1654555920000,
			lowest: 146.06,
			highest: 146.06,
			volumePrice: 146.0622,
			transactionCount: 6,
			voluemPrice: 146.0622
		},
		{
			open: 146.05,
			close: 146.07,
			volume: 285,
			time: 1654556040000,
			lowest: 146.05,
			highest: 146.07,
			volumePrice: 146.0628,
			transactionCount: 17,
			voluemPrice: 146.0628
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 629,
			time: 1654556220000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.0737,
			transactionCount: 12,
			voluemPrice: 146.0737
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 584,
			time: 1654556280000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.0689,
			transactionCount: 15,
			voluemPrice: 146.0689
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 331,
			time: 1654556340000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.0671,
			transactionCount: 13,
			voluemPrice: 146.0671
		},
		{
			open: 146.06,
			close: 146.06,
			volume: 255,
			time: 1654556580000,
			lowest: 146.06,
			highest: 146.06,
			volumePrice: 146.0637,
			transactionCount: 11,
			voluemPrice: 146.0637
		},
		{
			open: 146.06,
			close: 146.1199,
			volume: 1016,
			time: 1654556640000,
			lowest: 146.06,
			highest: 146.1199,
			volumePrice: 146.0914,
			transactionCount: 17,
			voluemPrice: 146.0914
		},
		{
			open: 146.12,
			close: 146.12,
			volume: 4121,
			time: 1654556700000,
			lowest: 146.12,
			highest: 146.12,
			volumePrice: 146.1186,
			transactionCount: 17,
			voluemPrice: 146.1186
		},
		{
			open: 146.08,
			close: 146.06,
			volume: 782,
			time: 1654556760000,
			lowest: 146.06,
			highest: 146.08,
			volumePrice: 146.0662,
			transactionCount: 17,
			voluemPrice: 146.0662
		},
		{
			open: 146.06,
			close: 146.12,
			volume: 1285,
			time: 1654556940000,
			lowest: 146.06,
			highest: 146.12,
			volumePrice: 146.0962,
			transactionCount: 29,
			voluemPrice: 146.0962
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 281,
			time: 1654557000000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.0991,
			transactionCount: 16,
			voluemPrice: 146.0991
		},
		{
			open: 146.1,
			close: 146.12,
			volume: 872,
			time: 1654557060000,
			lowest: 146.1,
			highest: 146.12,
			volumePrice: 146.1035,
			transactionCount: 17,
			voluemPrice: 146.1035
		},
		{
			open: 146.06,
			close: 146.06,
			volume: 399,
			time: 1654557180000,
			lowest: 146.06,
			highest: 146.06,
			volumePrice: 146.0609,
			transactionCount: 22,
			voluemPrice: 146.0609
		},
		{
			open: 146.09,
			close: 146.1,
			volume: 1421,
			time: 1654557240000,
			lowest: 146.08,
			highest: 146.1,
			volumePrice: 146.0901,
			transactionCount: 22,
			voluemPrice: 146.0901
		},
		{
			open: 146.12,
			close: 146.12,
			volume: 435,
			time: 1654557300000,
			lowest: 146.12,
			highest: 146.12,
			volumePrice: 146.1129,
			transactionCount: 26,
			voluemPrice: 146.1129
		},
		{
			open: 146.1,
			close: 146.07,
			volume: 647,
			time: 1654557360000,
			lowest: 146.07,
			highest: 146.1,
			volumePrice: 146.0935,
			transactionCount: 17,
			voluemPrice: 146.0935
		},
		{
			open: 146.11,
			close: 146.13,
			volume: 1042,
			time: 1654557420000,
			lowest: 146.1,
			highest: 146.13,
			volumePrice: 146.1119,
			transactionCount: 17,
			voluemPrice: 146.1119
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 263,
			time: 1654557480000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.0987,
			transactionCount: 10,
			voluemPrice: 146.0987
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 268,
			time: 1654557540000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.1032,
			transactionCount: 16,
			voluemPrice: 146.1032
		},
		{
			open: 146.1,
			close: 146.06,
			volume: 4298,
			time: 1654557660000,
			lowest: 146.06,
			highest: 146.1,
			volumePrice: 146.0627,
			transactionCount: 22,
			voluemPrice: 146.0627
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 324,
			time: 1654557720000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.0934,
			transactionCount: 15,
			voluemPrice: 146.0934
		},
		{
			open: 146.0801,
			close: 146.0801,
			volume: 312,
			time: 1654557780000,
			lowest: 146.0801,
			highest: 146.0801,
			volumePrice: 146.081,
			transactionCount: 8,
			voluemPrice: 146.081
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 325,
			time: 1654558080000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.0998,
			transactionCount: 18,
			voluemPrice: 146.0998
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 150,
			time: 1654558200000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.0775,
			transactionCount: 13,
			voluemPrice: 146.0775
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 183,
			time: 1654558260000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.0913,
			transactionCount: 21,
			voluemPrice: 146.0913
		},
		{
			open: 146.07,
			close: 146.07,
			volume: 1534,
			time: 1654558320000,
			lowest: 146.07,
			highest: 146.07,
			volumePrice: 146.0701,
			transactionCount: 20,
			voluemPrice: 146.0701
		},
		{
			open: 146.09,
			close: 146.09,
			volume: 578,
			time: 1654558560000,
			lowest: 146.09,
			highest: 146.09,
			volumePrice: 146.0917,
			transactionCount: 15,
			voluemPrice: 146.0917
		},
		{
			open: 146.16,
			close: 146.16,
			volume: 578,
			time: 1654558620000,
			lowest: 146.16,
			highest: 146.16,
			volumePrice: 146.1581,
			transactionCount: 19,
			voluemPrice: 146.1581
		},
		{
			open: 146.15,
			close: 146.15,
			volume: 448,
			time: 1654558740000,
			lowest: 146.15,
			highest: 146.15,
			volumePrice: 146.1527,
			transactionCount: 21,
			voluemPrice: 146.1527
		},
		{
			open: 146.12,
			close: 146.08,
			volume: 776,
			time: 1654558800000,
			lowest: 146.08,
			highest: 146.12,
			volumePrice: 146.1139,
			transactionCount: 16,
			voluemPrice: 146.1139
		},
		{
			open: 146.09,
			close: 146.08,
			volume: 454,
			time: 1654558860000,
			lowest: 146.08,
			highest: 146.09,
			volumePrice: 146.0865,
			transactionCount: 21,
			voluemPrice: 146.0865
		},
		{
			open: 146.0801,
			close: 146.1,
			volume: 613,
			time: 1654558980000,
			lowest: 146.0801,
			highest: 146.1,
			volumePrice: 146.0863,
			transactionCount: 38,
			voluemPrice: 146.0863
		},
		{
			open: 146.1,
			close: 146.1,
			volume: 336,
			time: 1654559040000,
			lowest: 146.1,
			highest: 146.1,
			volumePrice: 146.1096,
			transactionCount: 17,
			voluemPrice: 146.1096
		},
		{
			open: 146.15,
			close: 146.2,
			volume: 1334,
			time: 1654559100000,
			lowest: 146.15,
			highest: 146.2,
			volumePrice: 146.1782,
			transactionCount: 34,
			voluemPrice: 146.1782
		},
		{
			open: 146.2,
			close: 146.22,
			volume: 2160,
			time: 1654559160000,
			lowest: 146.2,
			highest: 146.22,
			volumePrice: 146.2037,
			transactionCount: 24,
			voluemPrice: 146.2037
		},
		{
			open: 146.21,
			close: 146.18,
			volume: 944,
			time: 1654559220000,
			lowest: 146.18,
			highest: 146.22,
			volumePrice: 146.1998,
			transactionCount: 8,
			voluemPrice: 146.1998
		},
		{
			open: 146.22,
			close: 146.28,
			volume: 1238,
			time: 1654559280000,
			lowest: 146.22,
			highest: 146.28,
			volumePrice: 146.2472,
			transactionCount: 36,
			voluemPrice: 146.2472
		},
		{
			open: 146.29,
			close: 146.35,
			volume: 3883,
			time: 1654559340000,
			lowest: 146.29,
			highest: 146.37,
			volumePrice: 146.336,
			transactionCount: 38,
			voluemPrice: 146.336
		},
		{
			open: 146.32,
			close: 146.32,
			volume: 565,
			time: 1654559460000,
			lowest: 146.32,
			highest: 146.32,
			volumePrice: 146.3277,
			transactionCount: 22,
			voluemPrice: 146.3277
		},
		{
			open: 146.33,
			close: 146.33,
			volume: 387,
			time: 1654559520000,
			lowest: 146.33,
			highest: 146.33,
			volumePrice: 146.3301,
			transactionCount: 14,
			voluemPrice: 146.3301
		},
		{
			open: 146.37,
			close: 146.41,
			volume: 1263,
			time: 1654559580000,
			lowest: 146.37,
			highest: 146.46,
			volumePrice: 146.4069,
			transactionCount: 35,
			voluemPrice: 146.4069
		},
		{
			open: 146.42,
			close: 146.49,
			volume: 1182,
			time: 1654559640000,
			lowest: 146.42,
			highest: 146.49,
			volumePrice: 146.4562,
			transactionCount: 27,
			voluemPrice: 146.4562
		},
		{
			open: 146.49,
			close: 146.5,
			volume: 4802,
			time: 1654559700000,
			lowest: 146.49,
			highest: 146.5,
			volumePrice: 146.4956,
			transactionCount: 57,
			voluemPrice: 146.4956
		},
		{
			open: 146.5,
			close: 146.5,
			volume: 2574,
			time: 1654559760000,
			lowest: 146.5,
			highest: 146.51,
			volumePrice: 146.5013,
			transactionCount: 25,
			voluemPrice: 146.5013
		},
		{
			open: 146.46,
			close: 146.49,
			volume: 1008,
			time: 1654559820000,
			lowest: 146.46,
			highest: 146.49,
			volumePrice: 146.4714,
			transactionCount: 21,
			voluemPrice: 146.4714
		},
		{
			open: 146.5,
			close: 146.46,
			volume: 8231,
			time: 1654559880000,
			lowest: 146.46,
			highest: 146.51,
			volumePrice: 146.5086,
			transactionCount: 50,
			voluemPrice: 146.5086
		},
		{
			open: 146.54,
			close: 146.5,
			volume: 9616,
			time: 1654559940000,
			lowest: 146.44,
			highest: 146.54,
			volumePrice: 146.5081,
			transactionCount: 40,
			voluemPrice: 146.5081
		},
		{
			open: 145.88,
			close: 145.55,
			volume: 1925,
			time: 1654588800000,
			lowest: 145.55,
			highest: 145.88,
			volumePrice: 145.6101,
			transactionCount: 99,
			voluemPrice: 145.6101
		},
		{
			open: 145.43,
			close: 145.41,
			volume: 1165,
			time: 1654588860000,
			lowest: 145.41,
			highest: 145.43,
			volumePrice: 145.4332,
			transactionCount: 22,
			voluemPrice: 145.4332
		},
		{
			open: 145.42,
			close: 145.42,
			volume: 1543,
			time: 1654588920000,
			lowest: 145.42,
			highest: 145.42,
			volumePrice: 145.4201,
			transactionCount: 40,
			voluemPrice: 145.4201
		},
		{
			open: 145.42,
			close: 145.42,
			volume: 1220,
			time: 1654588980000,
			lowest: 145.42,
			highest: 145.43,
			volumePrice: 145.4218,
			transactionCount: 51,
			voluemPrice: 145.4218
		},
		{
			open: 145.42,
			close: 145.42,
			volume: 1012,
			time: 1654589040000,
			lowest: 145.42,
			highest: 145.42,
			volumePrice: 145.4271,
			transactionCount: 21,
			voluemPrice: 145.4271
		},
		{
			open: 145.42,
			close: 145.49,
			volume: 2591,
			time: 1654589100000,
			lowest: 145.42,
			highest: 145.5,
			volumePrice: 145.4692,
			transactionCount: 51,
			voluemPrice: 145.4692
		},
		{
			open: 145.58,
			close: 145.65,
			volume: 2213,
			time: 1654589160000,
			lowest: 145.58,
			highest: 145.65,
			volumePrice: 145.6362,
			transactionCount: 34,
			voluemPrice: 145.6362
		},
		{
			open: 145.65,
			close: 145.63,
			volume: 1271,
			time: 1654589220000,
			lowest: 145.63,
			highest: 145.65,
			volumePrice: 145.6349,
			transactionCount: 26,
			voluemPrice: 145.6349
		},
		{
			open: 145.52,
			close: 145.52,
			volume: 122,
			time: 1654589400000,
			lowest: 145.52,
			highest: 145.52,
			volumePrice: 145.5316,
			transactionCount: 7,
			voluemPrice: 145.5316
		},
		{
			open: 145.57,
			close: 145.57,
			volume: 131,
			time: 1654589520000,
			lowest: 145.57,
			highest: 145.57,
			volumePrice: 145.5739,
			transactionCount: 3,
			voluemPrice: 145.5739
		},
		{
			open: 145.54,
			close: 145.52,
			volume: 599,
			time: 1654589580000,
			lowest: 145.52,
			highest: 145.54,
			volumePrice: 145.5381,
			transactionCount: 22,
			voluemPrice: 145.5381
		},
		{
			open: 145.54,
			close: 145.51,
			volume: 1287,
			time: 1654589700000,
			lowest: 145.51,
			highest: 145.54,
			volumePrice: 145.5232,
			transactionCount: 34,
			voluemPrice: 145.5232
		},
		{
			open: 145.46,
			close: 145.45,
			volume: 766,
			time: 1654589940000,
			lowest: 145.45,
			highest: 145.46,
			volumePrice: 145.4664,
			transactionCount: 19,
			voluemPrice: 145.4664
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 502,
			time: 1654590120000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5,
			transactionCount: 13,
			voluemPrice: 145.5
		},
		{
			open: 145.53,
			close: 145.54,
			volume: 853,
			time: 1654590180000,
			lowest: 145.53,
			highest: 145.54,
			volumePrice: 145.5311,
			transactionCount: 16,
			voluemPrice: 145.5311
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 1884,
			time: 1654590300000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.4738,
			transactionCount: 19,
			voluemPrice: 145.4738
		},
		{
			open: 145.49,
			close: 145.49,
			volume: 361,
			time: 1654590660000,
			lowest: 145.49,
			highest: 145.49,
			volumePrice: 145.4886,
			transactionCount: 4,
			voluemPrice: 145.4886
		},
		{
			open: 145.48,
			close: 145.5,
			volume: 1764,
			time: 1654590720000,
			lowest: 145.48,
			highest: 145.5,
			volumePrice: 145.4872,
			transactionCount: 17,
			voluemPrice: 145.4872
		},
		{
			open: 145.53,
			close: 145.57,
			volume: 2174,
			time: 1654590960000,
			lowest: 145.53,
			highest: 145.57,
			volumePrice: 145.5487,
			transactionCount: 23,
			voluemPrice: 145.5487
		},
		{
			open: 145.65,
			close: 145.65,
			volume: 214,
			time: 1654591080000,
			lowest: 145.65,
			highest: 145.65,
			volumePrice: 145.6497,
			transactionCount: 6,
			voluemPrice: 145.6497
		},
		{
			open: 145.68,
			close: 145.7,
			volume: 2272,
			time: 1654591140000,
			lowest: 145.66,
			highest: 145.7,
			volumePrice: 145.691,
			transactionCount: 17,
			voluemPrice: 145.691
		},
		{
			open: 145.63,
			close: 145.63,
			volume: 324,
			time: 1654591320000,
			lowest: 145.63,
			highest: 145.63,
			volumePrice: 145.6329,
			transactionCount: 9,
			voluemPrice: 145.6329
		},
		{
			open: 145.6,
			close: 145.61,
			volume: 999,
			time: 1654591380000,
			lowest: 145.6,
			highest: 145.61,
			volumePrice: 145.613,
			transactionCount: 14,
			voluemPrice: 145.613
		},
		{
			open: 145.65,
			close: 145.65,
			volume: 500,
			time: 1654591620000,
			lowest: 145.65,
			highest: 145.65,
			volumePrice: 145.65,
			transactionCount: 1,
			voluemPrice: 145.65
		},
		{
			open: 145.66,
			close: 145.65,
			volume: 547,
			time: 1654591680000,
			lowest: 145.65,
			highest: 145.66,
			volumePrice: 145.6547,
			transactionCount: 7,
			voluemPrice: 145.6547
		},
		{
			open: 145.66,
			close: 145.66,
			volume: 252,
			time: 1654591800000,
			lowest: 145.66,
			highest: 145.66,
			volumePrice: 145.658,
			transactionCount: 5,
			voluemPrice: 145.658
		},
		{
			open: 145.61,
			close: 145.61,
			volume: 652,
			time: 1654591860000,
			lowest: 145.61,
			highest: 145.61,
			volumePrice: 145.6265,
			transactionCount: 24,
			voluemPrice: 145.6265
		},
		{
			open: 145.66,
			close: 145.61,
			volume: 2559,
			time: 1654592100000,
			lowest: 145.61,
			highest: 145.67,
			volumePrice: 145.6384,
			transactionCount: 32,
			voluemPrice: 145.6384
		},
		{
			open: 145.62,
			close: 145.62,
			volume: 154,
			time: 1654592280000,
			lowest: 145.62,
			highest: 145.62,
			volumePrice: 145.6215,
			transactionCount: 5,
			voluemPrice: 145.6215
		},
		{
			open: 145.6,
			close: 145.59,
			volume: 1885,
			time: 1654592640000,
			lowest: 145.59,
			highest: 145.6,
			volumePrice: 145.5924,
			transactionCount: 23,
			voluemPrice: 145.5924
		},
		{
			open: 145.57,
			close: 145.57,
			volume: 777,
			time: 1654592820000,
			lowest: 145.57,
			highest: 145.57,
			volumePrice: 145.5515,
			transactionCount: 30,
			voluemPrice: 145.5515
		},
		{
			open: 145.55,
			close: 145.55,
			volume: 341,
			time: 1654592880000,
			lowest: 145.55,
			highest: 145.55,
			volumePrice: 145.5489,
			transactionCount: 10,
			voluemPrice: 145.5489
		},
		{
			open: 145.51,
			close: 145.52,
			volume: 1400,
			time: 1654593000000,
			lowest: 145.51,
			highest: 145.52,
			volumePrice: 145.5141,
			transactionCount: 29,
			voluemPrice: 145.5141
		},
		{
			open: 145.52,
			close: 145.52,
			volume: 200,
			time: 1654593060000,
			lowest: 145.52,
			highest: 145.52,
			volumePrice: 145.5194,
			transactionCount: 3,
			voluemPrice: 145.5194
		},
		{
			open: 145.5,
			close: 145.45,
			volume: 1900,
			time: 1654593120000,
			lowest: 145.45,
			highest: 145.5,
			volumePrice: 145.4639,
			transactionCount: 50,
			voluemPrice: 145.4639
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 504,
			time: 1654593180000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.4999,
			transactionCount: 3,
			voluemPrice: 145.4999
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 402,
			time: 1654593300000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.455,
			transactionCount: 16,
			voluemPrice: 145.455
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 510,
			time: 1654593360000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.4992,
			transactionCount: 5,
			voluemPrice: 145.4992
		},
		{
			open: 145.52,
			close: 145.52,
			volume: 801,
			time: 1654593480000,
			lowest: 145.48,
			highest: 145.52,
			volumePrice: 145.5086,
			transactionCount: 11,
			voluemPrice: 145.5086
		},
		{
			open: 145.52,
			close: 145.52,
			volume: 278,
			time: 1654593540000,
			lowest: 145.52,
			highest: 145.52,
			volumePrice: 145.5213,
			transactionCount: 6,
			voluemPrice: 145.5213
		},
		{
			open: 145.59,
			close: 145.59,
			volume: 481,
			time: 1654593660000,
			lowest: 145.59,
			highest: 145.59,
			volumePrice: 145.5896,
			transactionCount: 5,
			voluemPrice: 145.5896
		},
		{
			open: 145.72,
			close: 145.72,
			volume: 406,
			time: 1654593900000,
			lowest: 145.72,
			highest: 145.72,
			volumePrice: 145.7036,
			transactionCount: 11,
			voluemPrice: 145.7036
		},
		{
			open: 145.72,
			close: 145.72,
			volume: 190,
			time: 1654593960000,
			lowest: 145.72,
			highest: 145.72,
			volumePrice: 145.72,
			transactionCount: 5,
			voluemPrice: 145.72
		},
		{
			open: 145.72,
			close: 145.72,
			volume: 249,
			time: 1654594380000,
			lowest: 145.72,
			highest: 145.72,
			volumePrice: 145.7192,
			transactionCount: 5,
			voluemPrice: 145.7192
		},
		{
			open: 145.62,
			close: 145.61,
			volume: 1789,
			time: 1654594800000,
			lowest: 145.61,
			highest: 145.62,
			volumePrice: 145.6138,
			transactionCount: 21,
			voluemPrice: 145.6138
		},
		{
			open: 145.51,
			close: 145.5,
			volume: 661,
			time: 1654594860000,
			lowest: 145.5,
			highest: 145.51,
			volumePrice: 145.5132,
			transactionCount: 30,
			voluemPrice: 145.5132
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 331,
			time: 1654594980000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5,
			transactionCount: 7,
			voluemPrice: 145.5
		},
		{
			open: 145.55,
			close: 145.55,
			volume: 193,
			time: 1654595100000,
			lowest: 145.55,
			highest: 145.55,
			volumePrice: 145.5217,
			transactionCount: 13,
			voluemPrice: 145.5217
		},
		{
			open: 145.55,
			close: 145.55,
			volume: 201,
			time: 1654595160000,
			lowest: 145.55,
			highest: 145.55,
			volumePrice: 145.55,
			transactionCount: 2,
			voluemPrice: 145.55
		},
		{
			open: 145.54,
			close: 145.54,
			volume: 100,
			time: 1654595220000,
			lowest: 145.54,
			highest: 145.54,
			volumePrice: 145.54,
			transactionCount: 1,
			voluemPrice: 145.54
		},
		{
			open: 145.47,
			close: 145.46,
			volume: 872,
			time: 1654595640000,
			lowest: 145.46,
			highest: 145.47,
			volumePrice: 145.4661,
			transactionCount: 24,
			voluemPrice: 145.4661
		},
		{
			open: 145.49,
			close: 145.49,
			volume: 502,
			time: 1654596180000,
			lowest: 145.49,
			highest: 145.49,
			volumePrice: 145.4914,
			transactionCount: 6,
			voluemPrice: 145.4914
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 903,
			time: 1654596240000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.4999,
			transactionCount: 10,
			voluemPrice: 145.4999
		},
		{
			open: 145.5,
			close: 145.52,
			volume: 4827,
			time: 1654596420000,
			lowest: 145.5,
			highest: 145.52,
			volumePrice: 145.5049,
			transactionCount: 15,
			voluemPrice: 145.5049
		},
		{
			open: 145.5,
			close: 145.5,
			volume: 220,
			time: 1654596720000,
			lowest: 145.5,
			highest: 145.5,
			volumePrice: 145.5048,
			transactionCount: 13,
			voluemPrice: 145.5048
		},
		{
			open: 145.5,
			close: 145.47,
			volume: 1430,
			time: 1654596960000,
			lowest: 145.47,
			highest: 145.5,
			volumePrice: 145.4866,
			transactionCount: 30,
			voluemPrice: 145.4866
		},
		{
			open: 145.46,
			close: 145.4,
			volume: 5774,
			time: 1654597020000,
			lowest: 145.4,
			highest: 145.47,
			volumePrice: 145.431,
			transactionCount: 83,
			voluemPrice: 145.431
		},
		{
			open: 145.36,
			close: 145.25,
			volume: 1737,
			time: 1654597080000,
			lowest: 145.25,
			highest: 145.36,
			volumePrice: 145.3169,
			transactionCount: 65,
			voluemPrice: 145.3169
		},
		{
			open: 145.25,
			close: 145.11,
			volume: 1994,
			time: 1654597200000,
			lowest: 145.1,
			highest: 145.25,
			volumePrice: 145.2041,
			transactionCount: 51,
			voluemPrice: 145.2041
		},
		{
			open: 145.26,
			close: 145.26,
			volume: 657,
			time: 1654597260000,
			lowest: 145.26,
			highest: 145.26,
			volumePrice: 145.2588,
			transactionCount: 13,
			voluemPrice: 145.2588
		},
		{
			open: 145.22,
			close: 145.11,
			volume: 2955,
			time: 1654597320000,
			lowest: 145.11,
			highest: 145.22,
			volumePrice: 145.1668,
			transactionCount: 39,
			voluemPrice: 145.1668
		},
		{
			open: 145.11,
			close: 145.11,
			volume: 1501,
			time: 1654597500000,
			lowest: 145.11,
			highest: 145.11,
			volumePrice: 145.1196,
			transactionCount: 16,
			voluemPrice: 145.1196
		},
		{
			open: 145.28,
			close: 145.28,
			volume: 384,
			time: 1654597740000,
			lowest: 145.28,
			highest: 145.28,
			volumePrice: 145.2459,
			transactionCount: 17,
			voluemPrice: 145.2459
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 1124,
			time: 1654597980000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.4452,
			transactionCount: 12,
			voluemPrice: 145.4452
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 2426,
			time: 1654598040000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.45,
			transactionCount: 5,
			voluemPrice: 145.45
		},
		{
			open: 145.43,
			close: 145.44,
			volume: 531,
			time: 1654598100000,
			lowest: 145.43,
			highest: 145.44,
			volumePrice: 145.4322,
			transactionCount: 9,
			voluemPrice: 145.4322
		},
		{
			open: 145.43,
			close: 145.46,
			volume: 1369,
			time: 1654598220000,
			lowest: 145.43,
			highest: 145.46,
			volumePrice: 145.448,
			transactionCount: 39,
			voluemPrice: 145.448
		},
		{
			open: 145.4,
			close: 145.4,
			volume: 4073,
			time: 1654598280000,
			lowest: 145.4,
			highest: 145.4,
			volumePrice: 145.403,
			transactionCount: 17,
			voluemPrice: 145.403
		},
		{
			open: 145.46,
			close: 145.5,
			volume: 1553,
			time: 1654598520000,
			lowest: 145.46,
			highest: 145.5,
			volumePrice: 145.4856,
			transactionCount: 21,
			voluemPrice: 145.4856
		},
		{
			open: 145.45,
			close: 145.45,
			volume: 262,
			time: 1654598580000,
			lowest: 145.45,
			highest: 145.45,
			volumePrice: 145.4448,
			transactionCount: 12,
			voluemPrice: 145.4448
		},
		{
			open: 145.35,
			close: 145.35,
			volume: 1228,
			time: 1654598640000,
			lowest: 145.3,
			highest: 145.35,
			volumePrice: 145.3376,
			transactionCount: 31,
			voluemPrice: 145.3376
		},
		{
			open: 145.38,
			close: 145.38,
			volume: 253,
			time: 1654598760000,
			lowest: 145.38,
			highest: 145.38,
			volumePrice: 145.3772,
			transactionCount: 10,
			voluemPrice: 145.3772
		},
		{
			open: 145.4,
			close: 145.37,
			volume: 927,
			time: 1654598820000,
			lowest: 145.37,
			highest: 145.4,
			volumePrice: 145.3854,
			transactionCount: 17,
			voluemPrice: 145.3854
		},
		{
			open: 145.45,
			close: 145.47,
			volume: 752,
			time: 1654598880000,
			lowest: 145.45,
			highest: 145.47,
			volumePrice: 145.4606,
			transactionCount: 4,
			voluemPrice: 145.4606
		},
		{
			open: 145.49,
			close: 145.47,
			volume: 1289,
			time: 1654598940000,
			lowest: 145.47,
			highest: 145.51,
			volumePrice: 145.494,
			transactionCount: 17,
			voluemPrice: 145.494
		},
		{
			open: 145.52,
			close: 145.54,
			volume: 4713,
			time: 1654599000000,
			lowest: 145.5,
			highest: 145.55,
			volumePrice: 145.5234,
			transactionCount: 34,
			voluemPrice: 145.5234
		},
		{
			open: 145.46,
			close: 145.46,
			volume: 208,
			time: 1654599300000,
			lowest: 145.46,
			highest: 145.46,
			volumePrice: 145.466,
			transactionCount: 12,
			voluemPrice: 145.466
		},
		{
			open: 145.47,
			close: 145.45,
			volume: 301,
			time: 1654599360000,
			lowest: 145.45,
			highest: 145.47,
			volumePrice: 145.4531,
			transactionCount: 12,
			voluemPrice: 145.4531
		},
		{
			open: 145.4,
			close: 145.4,
			volume: 554,
			time: 1654599420000,
			lowest: 145.4,
			highest: 145.4,
			volumePrice: 145.3902,
			transactionCount: 22,
			voluemPrice: 145.3902
		},
		{
			open: 145.47,
			close: 145.47,
			volume: 452,
			time: 1654599480000,
			lowest: 145.47,
			highest: 145.47,
			volumePrice: 145.447,
			transactionCount: 15,
			voluemPrice: 145.447
		},
		{
			open: 145.41,
			close: 144.96,
			volume: 16449,
			time: 1654599540000,
			lowest: 144.96,
			highest: 145.41,
			volumePrice: 145.1447,
			transactionCount: 406,
			voluemPrice: 145.1447
		},
		{
			open: 144.96,
			close: 145.34,
			volume: 6224,
			time: 1654599600000,
			lowest: 144.96,
			highest: 145.34,
			volumePrice: 145.1681,
			transactionCount: 146,
			voluemPrice: 145.1681
		},
		{
			open: 145.36,
			close: 145.25,
			volume: 1655,
			time: 1654599660000,
			lowest: 145.24,
			highest: 145.36,
			volumePrice: 145.2682,
			transactionCount: 29,
			voluemPrice: 145.2682
		},
		{
			open: 145.25,
			close: 145.25,
			volume: 4940,
			time: 1654599720000,
			lowest: 145.2,
			highest: 145.25,
			volumePrice: 145.2421,
			transactionCount: 49,
			voluemPrice: 145.2421
		},
		{
			open: 145.3,
			close: 145.25,
			volume: 5133,
			time: 1654599780000,
			lowest: 145.25,
			highest: 145.32,
			volumePrice: 145.267,
			transactionCount: 74,
			voluemPrice: 145.267
		},
		{
			open: 145.24,
			close: 145.21,
			volume: 5856,
			time: 1654599840000,
			lowest: 145.19,
			highest: 145.24,
			volumePrice: 145.2278,
			transactionCount: 42,
			voluemPrice: 145.2278
		},
		{
			open: 145.23,
			close: 145.15,
			volume: 1123,
			time: 1654599900000,
			lowest: 145.15,
			highest: 145.23,
			volumePrice: 145.202,
			transactionCount: 24,
			voluemPrice: 145.202
		},
		{
			open: 145.13,
			close: 145,
			volume: 6579,
			time: 1654599960000,
			lowest: 145,
			highest: 145.13,
			volumePrice: 145.0293,
			transactionCount: 121,
			voluemPrice: 145.0293
		},
		{
			open: 144.99,
			close: 144.97,
			volume: 2448,
			time: 1654600020000,
			lowest: 144.97,
			highest: 144.99,
			volumePrice: 144.9852,
			transactionCount: 30,
			voluemPrice: 144.9852
		},
		{
			open: 144.97,
			close: 144.97,
			volume: 2726,
			time: 1654600080000,
			lowest: 144.97,
			highest: 144.98,
			volumePrice: 144.9944,
			transactionCount: 65,
			voluemPrice: 144.9944
		},
		{
			open: 144.98,
			close: 144.9,
			volume: 8855,
			time: 1654600140000,
			lowest: 144.83,
			highest: 145.05,
			volumePrice: 144.9253,
			transactionCount: 182,
			voluemPrice: 144.9253
		},
		{
			open: 144.86,
			close: 144.65,
			volume: 6288,
			time: 1654600200000,
			lowest: 144.65,
			highest: 144.86,
			volumePrice: 144.7688,
			transactionCount: 140,
			voluemPrice: 144.7688
		},
		{
			open: 144.63,
			close: 144.6,
			volume: 5586,
			time: 1654600260000,
			lowest: 144.6,
			highest: 144.65,
			volumePrice: 144.6225,
			transactionCount: 90,
			voluemPrice: 144.6225
		},
		{
			open: 144.59,
			close: 144.71,
			volume: 3183,
			time: 1654600320000,
			lowest: 144.59,
			highest: 144.71,
			volumePrice: 144.6221,
			transactionCount: 69,
			voluemPrice: 144.6221
		},
		{
			open: 144.56,
			close: 144.4,
			volume: 10077,
			time: 1654600380000,
			lowest: 144.4,
			highest: 144.56,
			volumePrice: 144.4829,
			transactionCount: 218,
			voluemPrice: 144.4829
		},
		{
			open: 144.4,
			close: 144.38,
			volume: 7601,
			time: 1654600440000,
			lowest: 144.36,
			highest: 144.4,
			volumePrice: 144.3902,
			transactionCount: 61,
			voluemPrice: 144.3902
		},
		{
			open: 144.4,
			close: 144.37,
			volume: 21335,
			time: 1654600500000,
			lowest: 144.36,
			highest: 144.4,
			volumePrice: 144.3951,
			transactionCount: 62,
			voluemPrice: 144.3951
		},
		{
			open: 144.49,
			close: 144.53,
			volume: 1667,
			time: 1654600560000,
			lowest: 144.49,
			highest: 144.53,
			volumePrice: 144.4967,
			transactionCount: 42,
			voluemPrice: 144.4967
		},
		{
			open: 144.59,
			close: 144.69,
			volume: 1680,
			time: 1654600620000,
			lowest: 144.59,
			highest: 144.69,
			volumePrice: 144.619,
			transactionCount: 47,
			voluemPrice: 144.619
		},
		{
			open: 144.58,
			close: 144.58,
			volume: 848,
			time: 1654600680000,
			lowest: 144.58,
			highest: 144.58,
			volumePrice: 144.5765,
			transactionCount: 39,
			voluemPrice: 144.5765
		},
		{
			open: 144.55,
			close: 144.59,
			volume: 2705,
			time: 1654600740000,
			lowest: 144.54,
			highest: 144.6,
			volumePrice: 144.583,
			transactionCount: 37,
			voluemPrice: 144.583
		},
		{
			open: 144.56,
			close: 144.54,
			volume: 1445,
			time: 1654600800000,
			lowest: 144.52,
			highest: 144.56,
			volumePrice: 144.5385,
			transactionCount: 36,
			voluemPrice: 144.5385
		},
		{
			open: 144.5,
			close: 144.5,
			volume: 7101,
			time: 1654600860000,
			lowest: 144.45,
			highest: 144.5,
			volumePrice: 144.4959,
			transactionCount: 58,
			voluemPrice: 144.4959
		},
		{
			open: 144.5,
			close: 144.5,
			volume: 5178,
			time: 1654600920000,
			lowest: 144.5,
			highest: 144.54,
			volumePrice: 144.5035,
			transactionCount: 71,
			voluemPrice: 144.5035
		},
		{
			open: 144.5,
			close: 144.5,
			volume: 2970,
			time: 1654600980000,
			lowest: 144.5,
			highest: 144.5,
			volumePrice: 144.5,
			transactionCount: 25,
			voluemPrice: 144.5
		},
		{
			open: 144.5,
			close: 144.5,
			volume: 1900,
			time: 1654601040000,
			lowest: 144.5,
			highest: 144.52,
			volumePrice: 144.503,
			transactionCount: 22,
			voluemPrice: 144.503
		},
		{
			open: 144.49,
			close: 144.49,
			volume: 954,
			time: 1654601100000,
			lowest: 144.49,
			highest: 144.49,
			volumePrice: 144.489,
			transactionCount: 22,
			voluemPrice: 144.489
		},
		{
			open: 144.5,
			close: 144.45,
			volume: 2884,
			time: 1654601160000,
			lowest: 144.45,
			highest: 144.5,
			volumePrice: 144.4958,
			transactionCount: 56,
			voluemPrice: 144.4958
		},
		{
			open: 144.44,
			close: 144.5,
			volume: 5812,
			time: 1654601220000,
			lowest: 144.4,
			highest: 144.5,
			volumePrice: 144.4817,
			transactionCount: 65,
			voluemPrice: 144.4817
		},
		{
			open: 144.5,
			close: 144.5,
			volume: 1024,
			time: 1654601280000,
			lowest: 144.5,
			highest: 144.5,
			volumePrice: 144.502,
			transactionCount: 25,
			voluemPrice: 144.502
		},
		{
			open: 144.5,
			close: 144.58,
			volume: 10257,
			time: 1654601340000,
			lowest: 144.45,
			highest: 144.58,
			volumePrice: 144.5042,
			transactionCount: 89,
			voluemPrice: 144.5042
		},
		{
			open: 144.53,
			close: 144.51,
			volume: 1134,
			time: 1654601400000,
			lowest: 144.51,
			highest: 144.57,
			volumePrice: 144.5374,
			transactionCount: 45,
			voluemPrice: 144.5374
		},
		{
			open: 144.54,
			close: 144.55,
			volume: 2822,
			time: 1654601460000,
			lowest: 144.52,
			highest: 144.57,
			volumePrice: 144.5428,
			transactionCount: 27,
			voluemPrice: 144.5428
		},
		{
			open: 144.54,
			close: 144.4,
			volume: 8956,
			time: 1654601520000,
			lowest: 144.38,
			highest: 144.54,
			volumePrice: 144.4541,
			transactionCount: 164,
			voluemPrice: 144.4541
		},
		{
			open: 144.4,
			close: 144.42,
			volume: 2235,
			time: 1654601580000,
			lowest: 144.4,
			highest: 144.5,
			volumePrice: 144.4411,
			transactionCount: 59,
			voluemPrice: 144.4411
		},
		{
			open: 144.45,
			close: 144.55,
			volume: 2498,
			time: 1654601640000,
			lowest: 144.44,
			highest: 144.55,
			volumePrice: 144.4845,
			transactionCount: 48,
			voluemPrice: 144.4845
		},
		{
			open: 144.57,
			close: 144.65,
			volume: 3051,
			time: 1654601700000,
			lowest: 144.57,
			highest: 144.65,
			volumePrice: 144.5926,
			transactionCount: 44,
			voluemPrice: 144.5926
		},
		{
			open: 144.67,
			close: 144.65,
			volume: 4631,
			time: 1654601760000,
			lowest: 144.65,
			highest: 144.68,
			volumePrice: 144.675,
			transactionCount: 30,
			voluemPrice: 144.675
		},
		{
			open: 144.62,
			close: 144.68,
			volume: 747,
			time: 1654601820000,
			lowest: 144.62,
			highest: 144.68,
			volumePrice: 144.651,
			transactionCount: 13,
			voluemPrice: 144.651
		},
		{
			open: 144.69,
			close: 144.61,
			volume: 2839,
			time: 1654601880000,
			lowest: 144.6,
			highest: 144.69,
			volumePrice: 144.6254,
			transactionCount: 47,
			voluemPrice: 144.6254
		},
		{
			open: 144.6,
			close: 144.6,
			volume: 799,
			time: 1654601940000,
			lowest: 144.6,
			highest: 144.6,
			volumePrice: 144.6001,
			transactionCount: 21,
			voluemPrice: 144.6001
		},
		{
			open: 144.57,
			close: 144.51,
			volume: 2716,
			time: 1654602000000,
			lowest: 144.5,
			highest: 144.57,
			volumePrice: 144.5257,
			transactionCount: 63,
			voluemPrice: 144.5257
		},
		{
			open: 144.5,
			close: 144.46,
			volume: 3938,
			time: 1654602060000,
			lowest: 144.4,
			highest: 144.51,
			volumePrice: 144.4539,
			transactionCount: 76,
			voluemPrice: 144.4539
		},
		{
			open: 144.43,
			close: 144.44,
			volume: 1602,
			time: 1654602180000,
			lowest: 144.42,
			highest: 144.44,
			volumePrice: 144.4289,
			transactionCount: 31,
			voluemPrice: 144.4289
		},
		{
			open: 144.44,
			close: 144.37,
			volume: 2820,
			time: 1654602240000,
			lowest: 144.37,
			highest: 144.45,
			volumePrice: 144.4148,
			transactionCount: 69,
			voluemPrice: 144.4148
		},
		{
			open: 144.37,
			close: 144.37,
			volume: 3466,
			time: 1654602300000,
			lowest: 144.37,
			highest: 144.37,
			volumePrice: 144.3693,
			transactionCount: 55,
			voluemPrice: 144.3693
		},
		{
			open: 144.39,
			close: 144.5,
			volume: 4800,
			time: 1654602420000,
			lowest: 144.39,
			highest: 144.5,
			volumePrice: 144.4567,
			transactionCount: 87,
			voluemPrice: 144.4567
		},
		{
			open: 144.49,
			close: 144.4,
			volume: 8760,
			time: 1654602480000,
			lowest: 144.39,
			highest: 144.49,
			volumePrice: 144.4136,
			transactionCount: 99,
			voluemPrice: 144.4136
		},
		{
			open: 144.39,
			close: 144.32,
			volume: 5484,
			time: 1654602540000,
			lowest: 144.32,
			highest: 144.39,
			volumePrice: 144.3666,
			transactionCount: 125,
			voluemPrice: 144.3666
		},
		{
			open: 144.32,
			close: 144.25,
			volume: 10046,
			time: 1654602600000,
			lowest: 144.25,
			highest: 144.34,
			volumePrice: 144.2912,
			transactionCount: 157,
			voluemPrice: 144.2912
		},
		{
			open: 144.25,
			close: 144.25,
			volume: 4675,
			time: 1654602660000,
			lowest: 144.24,
			highest: 144.25,
			volumePrice: 144.2504,
			transactionCount: 103,
			voluemPrice: 144.2504
		},
		{
			open: 144.23,
			close: 144.13,
			volume: 18843,
			time: 1654602720000,
			lowest: 144.13,
			highest: 144.23,
			volumePrice: 144.1748,
			transactionCount: 183,
			voluemPrice: 144.1748
		},
		{
			open: 144.13,
			close: 144.13,
			volume: 11110,
			time: 1654602780000,
			lowest: 144.07,
			highest: 144.17,
			volumePrice: 144.132,
			transactionCount: 179,
			voluemPrice: 144.132
		},
		{
			open: 144.13,
			close: 143.95,
			volume: 19790,
			time: 1654602840000,
			lowest: 143.95,
			highest: 144.14,
			volumePrice: 144.0208,
			transactionCount: 519,
			voluemPrice: 144.0208
		},
		{
			open: 143.96,
			close: 144,
			volume: 5244,
			time: 1654602900000,
			lowest: 143.96,
			highest: 144,
			volumePrice: 143.9993,
			transactionCount: 55,
			voluemPrice: 143.9993
		},
		{
			open: 143.99,
			close: 144,
			volume: 1478,
			time: 1654602960000,
			lowest: 143.99,
			highest: 144,
			volumePrice: 144.0191,
			transactionCount: 57,
			voluemPrice: 144.0191
		},
		{
			open: 144.06,
			close: 144.13,
			volume: 2568,
			time: 1654603020000,
			lowest: 144.06,
			highest: 144.13,
			volumePrice: 144.1001,
			transactionCount: 56,
			voluemPrice: 144.1001
		},
		{
			open: 144.15,
			close: 144.16,
			volume: 669,
			time: 1654603080000,
			lowest: 144.15,
			highest: 144.16,
			volumePrice: 144.1552,
			transactionCount: 24,
			voluemPrice: 144.1552
		},
		{
			open: 145.1,
			close: 144.25,
			volume: 254069,
			time: 1654603200000,
			lowest: 143.96,
			highest: 145.41,
			volumePrice: 144.4898,
			transactionCount: 1305,
			voluemPrice: 144.4898
		},
		{
			open: 144.2,
			close: 144.13,
			volume: 19881,
			time: 1654603260000,
			lowest: 144.13,
			highest: 145.88,
			volumePrice: 144.3628,
			transactionCount: 409,
			voluemPrice: 144.3628
		},
		{
			open: 144.13,
			close: 144.21,
			volume: 16956,
			time: 1654603320000,
			lowest: 144.1,
			highest: 144.21,
			volumePrice: 144.1301,
			transactionCount: 172,
			voluemPrice: 144.1301
		},
		{
			open: 144.21,
			close: 144.1799,
			volume: 6352,
			time: 1654603380000,
			lowest: 144.15,
			highest: 144.21,
			volumePrice: 144.1582,
			transactionCount: 64,
			voluemPrice: 144.1582
		},
		{
			open: 144.21,
			close: 144.1,
			volume: 2027,
			time: 1654603440000,
			lowest: 144.1,
			highest: 144.21,
			volumePrice: 144.1575,
			transactionCount: 76,
			voluemPrice: 144.1575
		},
		{
			open: 144.1,
			close: 144.18,
			volume: 8162,
			time: 1654603500000,
			lowest: 144.04,
			highest: 144.18,
			volumePrice: 144.0629,
			transactionCount: 109,
			voluemPrice: 144.0629
		},
		{
			open: 144.12,
			close: 143.9701,
			volume: 26920,
			time: 1654603560000,
			lowest: 143.9701,
			highest: 144.12,
			volumePrice: 144.0287,
			transactionCount: 193,
			voluemPrice: 144.0287
		},
		{
			open: 144,
			close: 143.98,
			volume: 7237,
			time: 1654603620000,
			lowest: 143.95,
			highest: 144,
			volumePrice: 143.9729,
			transactionCount: 129,
			voluemPrice: 143.9729
		},
		{
			open: 143.98,
			close: 143.98,
			volume: 17331,
			time: 1654603680000,
			lowest: 143.97,
			highest: 144,
			volumePrice: 143.9818,
			transactionCount: 67,
			voluemPrice: 143.9818
		},
		{
			open: 143.97,
			close: 144.02,
			volume: 4746,
			time: 1654603740000,
			lowest: 143.97,
			highest: 144.02,
			volumePrice: 143.9977,
			transactionCount: 75,
			voluemPrice: 143.9977
		},
		{
			open: 144.02,
			close: 143.97,
			volume: 4170,
			time: 1654603800000,
			lowest: 143.97,
			highest: 144.04,
			volumePrice: 143.9966,
			transactionCount: 92,
			voluemPrice: 143.9966
		},
		{
			open: 143.95,
			close: 143.93,
			volume: 59661,
			time: 1654603860000,
			lowest: 143.89,
			highest: 144,
			volumePrice: 143.9313,
			transactionCount: 257,
			voluemPrice: 143.9313
		},
		{
			open: 143.9201,
			close: 143.9001,
			volume: 3329,
			time: 1654603920000,
			lowest: 143.88,
			highest: 143.9201,
			volumePrice: 143.8984,
			transactionCount: 69,
			voluemPrice: 143.8984
		},
		{
			open: 143.9,
			close: 143.92,
			volume: 3175,
			time: 1654603980000,
			lowest: 143.9,
			highest: 143.94,
			volumePrice: 143.9126,
			transactionCount: 56,
			voluemPrice: 143.9126
		},
		{
			open: 143.9,
			close: 143.98,
			volume: 16098,
			time: 1654604040000,
			lowest: 143.9,
			highest: 144,
			volumePrice: 143.9733,
			transactionCount: 113,
			voluemPrice: 143.9733
		},
		{
			open: 143.98,
			close: 144,
			volume: 9389,
			time: 1654604100000,
			lowest: 143.98,
			highest: 144,
			volumePrice: 143.9947,
			transactionCount: 74,
			voluemPrice: 143.9947
		},
		{
			open: 143.96,
			close: 143.98,
			volume: 11314,
			time: 1654604160000,
			lowest: 143.91,
			highest: 143.98,
			volumePrice: 143.9631,
			transactionCount: 54,
			voluemPrice: 143.9631
		},
		{
			open: 143.97,
			close: 143.92,
			volume: 18335,
			time: 1654604220000,
			lowest: 143.92,
			highest: 144,
			volumePrice: 143.9742,
			transactionCount: 122,
			voluemPrice: 143.9742
		},
		{
			open: 143.95,
			close: 143.85,
			volume: 25859,
			time: 1654604280000,
			lowest: 143.85,
			highest: 143.95,
			volumePrice: 143.9017,
			transactionCount: 242,
			voluemPrice: 143.9017
		},
		{
			open: 143.85,
			close: 143.85,
			volume: 1720,
			time: 1654604340000,
			lowest: 143.83,
			highest: 143.85,
			volumePrice: 143.8451,
			transactionCount: 44,
			voluemPrice: 143.8451
		},
		{
			open: 143.85,
			close: 144.01,
			volume: 14200,
			time: 1654604400000,
			lowest: 143.83,
			highest: 144.05,
			volumePrice: 143.893,
			transactionCount: 171,
			voluemPrice: 143.893
		},
		{
			open: 144.05,
			close: 144.18,
			volume: 38087,
			time: 1654604460000,
			lowest: 144,
			highest: 144.24,
			volumePrice: 144.1225,
			transactionCount: 282,
			voluemPrice: 144.1225
		},
		{
			open: 144.19,
			close: 144.28,
			volume: 18368,
			time: 1654604520000,
			lowest: 144.19,
			highest: 144.37,
			volumePrice: 144.256,
			transactionCount: 176,
			voluemPrice: 144.256
		},
		{
			open: 144.2836,
			close: 144.33,
			volume: 8294,
			time: 1654604580000,
			lowest: 144.23,
			highest: 144.34,
			volumePrice: 144.2656,
			transactionCount: 149,
			voluemPrice: 144.2656
		},
		{
			open: 144.376,
			close: 144.23,
			volume: 3129,
			time: 1654604640000,
			lowest: 144.23,
			highest: 144.4,
			volumePrice: 144.3255,
			transactionCount: 94,
			voluemPrice: 144.3255
		},
		{
			open: 144.23,
			close: 144,
			volume: 48640,
			time: 1654604700000,
			lowest: 143.99,
			highest: 144.23,
			volumePrice: 144.0185,
			transactionCount: 292,
			voluemPrice: 144.0185
		},
		{
			open: 144.03,
			close: 144,
			volume: 10883,
			time: 1654604760000,
			lowest: 143.96,
			highest: 144.04,
			volumePrice: 144.0038,
			transactionCount: 109,
			voluemPrice: 144.0038
		},
		{
			open: 143.98,
			close: 144.14,
			volume: 38589,
			time: 1654604820000,
			lowest: 143.98,
			highest: 144.25,
			volumePrice: 144.1717,
			transactionCount: 214,
			voluemPrice: 144.1717
		},
		{
			open: 144.16,
			close: 144.13,
			volume: 3379,
			time: 1654604880000,
			lowest: 144.13,
			highest: 144.16,
			volumePrice: 144.1447,
			transactionCount: 39,
			voluemPrice: 144.1447
		},
		{
			open: 144.13,
			close: 144.1899,
			volume: 5455,
			time: 1654604940000,
			lowest: 144.13,
			highest: 144.19,
			volumePrice: 144.1759,
			transactionCount: 69,
			voluemPrice: 144.1759
		},
		{
			open: 144.1801,
			close: 144.1,
			volume: 33500,
			time: 1654605000000,
			lowest: 144,
			highest: 144.1801,
			volumePrice: 144.1116,
			transactionCount: 115,
			voluemPrice: 144.1116
		},
		{
			open: 144.01,
			close: 143.9341,
			volume: 18567,
			time: 1654605060000,
			lowest: 143.85,
			highest: 144.02,
			volumePrice: 143.9315,
			transactionCount: 205,
			voluemPrice: 143.9315
		},
		{
			open: 143.87,
			close: 143.84,
			volume: 12889,
			time: 1654605120000,
			lowest: 143.8,
			highest: 143.88,
			volumePrice: 143.8236,
			transactionCount: 203,
			voluemPrice: 143.8236
		},
		{
			open: 143.91,
			close: 144.1,
			volume: 14812,
			time: 1654605180000,
			lowest: 143.91,
			highest: 144.1,
			volumePrice: 144.0716,
			transactionCount: 109,
			voluemPrice: 144.0716
		},
		{
			open: 144.11,
			close: 144.09,
			volume: 10116,
			time: 1654605240000,
			lowest: 144.09,
			highest: 144.14,
			volumePrice: 144.11,
			transactionCount: 95,
			voluemPrice: 144.11
		},
		{
			open: 144.0799,
			close: 143.92,
			volume: 12324,
			time: 1654605300000,
			lowest: 143.92,
			highest: 144.0799,
			volumePrice: 144.0309,
			transactionCount: 98,
			voluemPrice: 144.0309
		},
		{
			open: 143.98,
			close: 144.1,
			volume: 20960,
			time: 1654605360000,
			lowest: 143.98,
			highest: 144.1,
			volumePrice: 144.013,
			transactionCount: 150,
			voluemPrice: 144.013
		},
		{
			open: 144.07,
			close: 144.09,
			volume: 13413,
			time: 1654605420000,
			lowest: 144.07,
			highest: 144.11,
			volumePrice: 144.0961,
			transactionCount: 85,
			voluemPrice: 144.0961
		},
		{
			open: 144.1,
			close: 144.13,
			volume: 2363,
			time: 1654605480000,
			lowest: 144.08,
			highest: 144.13,
			volumePrice: 144.0953,
			transactionCount: 50,
			voluemPrice: 144.0953
		},
		{
			open: 144.1001,
			close: 144.05,
			volume: 1261,
			time: 1654605540000,
			lowest: 144.05,
			highest: 144.13,
			volumePrice: 144.0985,
			transactionCount: 69,
			voluemPrice: 144.0985
		},
		{
			open: 144.07,
			close: 144.07,
			volume: 2460,
			time: 1654605600000,
			lowest: 144.04,
			highest: 144.07,
			volumePrice: 144.0605,
			transactionCount: 77,
			voluemPrice: 144.0605
		},
		{
			open: 144.08,
			close: 144.15,
			volume: 4255,
			time: 1654605660000,
			lowest: 144.08,
			highest: 144.15,
			volumePrice: 144.1149,
			transactionCount: 64,
			voluemPrice: 144.1149
		},
		{
			open: 144.15,
			close: 144.09,
			volume: 6913,
			time: 1654605720000,
			lowest: 144.09,
			highest: 144.15,
			volumePrice: 144.1325,
			transactionCount: 73,
			voluemPrice: 144.1325
		},
		{
			open: 144.13,
			close: 144.2,
			volume: 2702,
			time: 1654605780000,
			lowest: 144.13,
			highest: 144.2,
			volumePrice: 144.1646,
			transactionCount: 57,
			voluemPrice: 144.1646
		},
		{
			open: 144.17,
			close: 144.2,
			volume: 8414,
			time: 1654605840000,
			lowest: 144.17,
			highest: 144.2,
			volumePrice: 144.1973,
			transactionCount: 54,
			voluemPrice: 144.1973
		},
		{
			open: 144.2,
			close: 144.34,
			volume: 11857,
			time: 1654605900000,
			lowest: 144.19,
			highest: 144.34,
			volumePrice: 144.2402,
			transactionCount: 119,
			voluemPrice: 144.2402
		},
		{
			open: 144.3201,
			close: 144.1501,
			volume: 16158,
			time: 1654605960000,
			lowest: 144.15,
			highest: 144.34,
			volumePrice: 144.2194,
			transactionCount: 124,
			voluemPrice: 144.2194
		},
		{
			open: 144.15,
			close: 144.1,
			volume: 5060,
			time: 1654606020000,
			lowest: 144.07,
			highest: 144.18,
			volumePrice: 144.1217,
			transactionCount: 134,
			voluemPrice: 144.1217
		},
		{
			open: 144.1,
			close: 144.03,
			volume: 6949,
			time: 1654606080000,
			lowest: 144.02,
			highest: 144.1,
			volumePrice: 144.089,
			transactionCount: 111,
			voluemPrice: 144.089
		},
		{
			open: 144.07,
			close: 144,
			volume: 9104,
			time: 1654606140000,
			lowest: 143.96,
			highest: 144.07,
			volumePrice: 144.0047,
			transactionCount: 237,
			voluemPrice: 144.0047
		},
		{
			open: 143.9605,
			close: 144.11,
			volume: 6096,
			time: 1654606200000,
			lowest: 143.9605,
			highest: 144.11,
			volumePrice: 144.0027,
			transactionCount: 54,
			voluemPrice: 144.0027
		},
		{
			open: 144.09,
			close: 144.15,
			volume: 1468,
			time: 1654606260000,
			lowest: 144.09,
			highest: 144.15,
			volumePrice: 144.1107,
			transactionCount: 51,
			voluemPrice: 144.1107
		},
		{
			open: 144.15,
			close: 144.1601,
			volume: 1232,
			time: 1654606320000,
			lowest: 144.15,
			highest: 144.1601,
			volumePrice: 144.1572,
			transactionCount: 40,
			voluemPrice: 144.1572
		},
		{
			open: 144.15,
			close: 144.07,
			volume: 8518,
			time: 1654606380000,
			lowest: 144.05,
			highest: 144.15,
			volumePrice: 144.1028,
			transactionCount: 82,
			voluemPrice: 144.1028
		},
		{
			open: 144.07,
			close: 143.95,
			volume: 22780,
			time: 1654606440000,
			lowest: 143.95,
			highest: 144.07,
			volumePrice: 144.0296,
			transactionCount: 235,
			voluemPrice: 144.0296
		},
		{
			open: 143.9754,
			close: 143.99,
			volume: 7455,
			time: 1654606500000,
			lowest: 143.9754,
			highest: 144,
			volumePrice: 143.9902,
			transactionCount: 70,
			voluemPrice: 143.9902
		},
		{
			open: 144.0103,
			close: 144.06,
			volume: 7180,
			time: 1654606560000,
			lowest: 144,
			highest: 144.07,
			volumePrice: 144.044,
			transactionCount: 61,
			voluemPrice: 144.044
		},
		{
			open: 144.06,
			close: 144.08,
			volume: 1702,
			time: 1654606620000,
			lowest: 144.06,
			highest: 144.08,
			volumePrice: 144.0794,
			transactionCount: 48,
			voluemPrice: 144.0794
		},
		{
			open: 144.09,
			close: 144.08,
			volume: 2226,
			time: 1654606680000,
			lowest: 144.08,
			highest: 144.09,
			volumePrice: 144.0843,
			transactionCount: 74,
			voluemPrice: 144.0843
		},
		{
			open: 144.0357,
			close: 144.069,
			volume: 3181,
			time: 1654606740000,
			lowest: 144.0357,
			highest: 144.07,
			volumePrice: 144.0631,
			transactionCount: 50,
			voluemPrice: 144.0631
		},
		{
			open: 144.05,
			close: 144.11,
			volume: 2880,
			time: 1654606800000,
			lowest: 144.05,
			highest: 144.11,
			volumePrice: 144.098,
			transactionCount: 84,
			voluemPrice: 144.098
		},
		{
			open: 144.12,
			close: 144.1099,
			volume: 1677,
			time: 1654606860000,
			lowest: 144.1099,
			highest: 144.1456,
			volumePrice: 144.1205,
			transactionCount: 80,
			voluemPrice: 144.1205
		},
		{
			open: 144.0601,
			close: 144.05,
			volume: 3312,
			time: 1654606920000,
			lowest: 144,
			highest: 144.07,
			volumePrice: 144.0298,
			transactionCount: 138,
			voluemPrice: 144.0298
		},
		{
			open: 144.0995,
			close: 144.11,
			volume: 11685,
			time: 1654606980000,
			lowest: 144.0796,
			highest: 144.11,
			volumePrice: 144.0994,
			transactionCount: 86,
			voluemPrice: 144.0994
		},
		{
			open: 144.12,
			close: 144.13,
			volume: 21639,
			time: 1654607040000,
			lowest: 144.1125,
			highest: 144.15,
			volumePrice: 144.1482,
			transactionCount: 74,
			voluemPrice: 144.1482
		},
		{
			open: 144.13,
			close: 144.06,
			volume: 12962,
			time: 1654607100000,
			lowest: 144.06,
			highest: 144.15,
			volumePrice: 144.141,
			transactionCount: 127,
			voluemPrice: 144.141
		},
		{
			open: 144.06,
			close: 144.01,
			volume: 4104,
			time: 1654607160000,
			lowest: 144,
			highest: 144.08,
			volumePrice: 144.0435,
			transactionCount: 112,
			voluemPrice: 144.0435
		},
		{
			open: 144.04,
			close: 144.04,
			volume: 1088,
			time: 1654607220000,
			lowest: 144.01,
			highest: 144.04,
			volumePrice: 144.0352,
			transactionCount: 60,
			voluemPrice: 144.0352
		},
		{
			open: 144.05,
			close: 144.09,
			volume: 3123,
			time: 1654607280000,
			lowest: 144.05,
			highest: 144.09,
			volumePrice: 144.0553,
			transactionCount: 69,
			voluemPrice: 144.0553
		},
		{
			open: 144.09,
			close: 144.08,
			volume: 2209,
			time: 1654607340000,
			lowest: 144.07,
			highest: 144.09,
			volumePrice: 144.0796,
			transactionCount: 59,
			voluemPrice: 144.0796
		},
		{
			open: 144.1,
			close: 144.1,
			volume: 3240,
			time: 1654607400000,
			lowest: 144.1,
			highest: 144.12,
			volumePrice: 144.1074,
			transactionCount: 68,
			voluemPrice: 144.1074
		},
		{
			open: 144.1,
			close: 144,
			volume: 14571,
			time: 1654607460000,
			lowest: 144,
			highest: 144.1,
			volumePrice: 144.0311,
			transactionCount: 239,
			voluemPrice: 144.0311
		},
		{
			open: 144,
			close: 144.03,
			volume: 18188,
			time: 1654607520000,
			lowest: 143.99,
			highest: 144.03,
			volumePrice: 143.9956,
			transactionCount: 112,
			voluemPrice: 143.9956
		},
		{
			open: 144,
			close: 143.9497,
			volume: 16346,
			time: 1654607580000,
			lowest: 143.93,
			highest: 144.01,
			volumePrice: 143.95,
			transactionCount: 231,
			voluemPrice: 143.95
		},
		{
			open: 143.94,
			close: 143.96,
			volume: 27698,
			time: 1654607640000,
			lowest: 143.8614,
			highest: 144,
			volumePrice: 143.9231,
			transactionCount: 267,
			voluemPrice: 143.9231
		},
		{
			open: 143.98,
			close: 144.17,
			volume: 24313,
			time: 1654607700000,
			lowest: 143.98,
			highest: 144.22,
			volumePrice: 144.0593,
			transactionCount: 154,
			voluemPrice: 144.0593
		},
		{
			open: 144.15,
			close: 144,
			volume: 14038,
			time: 1654607760000,
			lowest: 143.99,
			highest: 144.15,
			volumePrice: 144.0571,
			transactionCount: 184,
			voluemPrice: 144.0571
		},
		{
			open: 143.99,
			close: 144.05,
			volume: 7483,
			time: 1654607820000,
			lowest: 143.96,
			highest: 144.05,
			volumePrice: 143.9999,
			transactionCount: 93,
			voluemPrice: 143.9999
		},
		{
			open: 144.05,
			close: 144.1295,
			volume: 29101,
			time: 1654607880000,
			lowest: 144.05,
			highest: 144.13,
			volumePrice: 144.1071,
			transactionCount: 192,
			voluemPrice: 144.1071
		},
		{
			open: 144.09,
			close: 144.06,
			volume: 12286,
			time: 1654607940000,
			lowest: 144.05,
			highest: 144.09,
			volumePrice: 144.0738,
			transactionCount: 78,
			voluemPrice: 144.0738
		},
		{
			open: 144.06,
			close: 144.07,
			volume: 3971,
			time: 1654608000000,
			lowest: 144.05,
			highest: 144.07,
			volumePrice: 144.0553,
			transactionCount: 73,
			voluemPrice: 144.0553
		},
		{
			open: 144.0699,
			close: 144.13,
			volume: 5202,
			time: 1654608060000,
			lowest: 144.0699,
			highest: 146.14,
			volumePrice: 144.2418,
			transactionCount: 97,
			voluemPrice: 144.2418
		},
		{
			open: 144.1,
			close: 144.18,
			volume: 9785,
			time: 1654608120000,
			lowest: 144.1,
			highest: 144.18,
			volumePrice: 144.1165,
			transactionCount: 70,
			voluemPrice: 144.1165
		},
		{
			open: 144.18,
			close: 144.19,
			volume: 3285,
			time: 1654608180000,
			lowest: 144.16,
			highest: 144.2099,
			volumePrice: 144.193,
			transactionCount: 93,
			voluemPrice: 144.193
		},
		{
			open: 144.18,
			close: 144.2,
			volume: 5447,
			time: 1654608240000,
			lowest: 144.18,
			highest: 144.2,
			volumePrice: 144.1923,
			transactionCount: 96,
			voluemPrice: 144.1923
		},
		{
			open: 144.22,
			close: 144.21,
			volume: 7745,
			time: 1654608300000,
			lowest: 144.21,
			highest: 144.35,
			volumePrice: 144.2506,
			transactionCount: 109,
			voluemPrice: 144.2506
		},
		{
			open: 144.25,
			close: 144.16,
			volume: 4990,
			time: 1654608360000,
			lowest: 144.16,
			highest: 144.25,
			volumePrice: 144.1962,
			transactionCount: 103,
			voluemPrice: 144.1962
		},
		{
			open: 144.2,
			close: 144.39,
			volume: 24840,
			time: 1654608420000,
			lowest: 144.17,
			highest: 146.14,
			volumePrice: 144.3512,
			transactionCount: 201,
			voluemPrice: 144.3512
		},
		{
			open: 144.39,
			close: 144.39,
			volume: 4044,
			time: 1654608480000,
			lowest: 144.36,
			highest: 144.39,
			volumePrice: 144.3734,
			transactionCount: 95,
			voluemPrice: 144.3734
		},
		{
			open: 144.39,
			close: 144.36,
			volume: 2981,
			time: 1654608540000,
			lowest: 144.35,
			highest: 144.39,
			volumePrice: 144.3725,
			transactionCount: 71,
			voluemPrice: 144.3725
		},
		{
			open: 144.345,
			close: 144.8599,
			volume: 1158365,
			time: 1654608600000,
			lowest: 144.21,
			highest: 144.864,
			volumePrice: 144.4677,
			transactionCount: 13668,
			voluemPrice: 144.4677
		},
		{
			open: 144.85,
			close: 144.42,
			volume: 328567,
			time: 1654608660000,
			lowest: 144.37,
			highest: 144.85,
			volumePrice: 144.6224,
			transactionCount: 4196,
			voluemPrice: 144.6224
		},
		{
			open: 144.45,
			close: 144.89,
			volume: 512183,
			time: 1654608720000,
			lowest: 144.1,
			highest: 144.89,
			volumePrice: 144.3895,
			transactionCount: 4553,
			voluemPrice: 144.3895
		},
		{
			open: 144.875,
			close: 145.2008,
			volume: 604574,
			time: 1654608780000,
			lowest: 144.87,
			highest: 145.65,
			volumePrice: 145.2845,
			transactionCount: 5513,
			voluemPrice: 145.2845
		},
		{
			open: 145.2001,
			close: 145.525,
			volume: 371736,
			time: 1654608840000,
			lowest: 145.11,
			highest: 145.67,
			volumePrice: 145.364,
			transactionCount: 3318,
			voluemPrice: 145.364
		},
		{
			open: 145.54,
			close: 145.659,
			volume: 373810,
			time: 1654608900000,
			lowest: 145.38,
			highest: 145.73,
			volumePrice: 145.5684,
			transactionCount: 3209,
			voluemPrice: 145.5684
		},
		{
			open: 145.6594,
			close: 145.43,
			volume: 268686,
			time: 1654608960000,
			lowest: 145.35,
			highest: 145.66,
			volumePrice: 145.4669,
			transactionCount: 2573,
			voluemPrice: 145.4669
		},
		{
			open: 145.45,
			close: 145.81,
			volume: 377431,
			time: 1654609020000,
			lowest: 145.42,
			highest: 145.92,
			volumePrice: 145.7219,
			transactionCount: 3122,
			voluemPrice: 145.7219
		},
		{
			open: 145.805,
			close: 145.87,
			volume: 340854,
			time: 1654609080000,
			lowest: 145.6,
			highest: 145.99,
			volumePrice: 145.8234,
			transactionCount: 2754,
			voluemPrice: 145.8234
		},
		{
			open: 145.8674,
			close: 145.74,
			volume: 339815,
			time: 1654609140000,
			lowest: 145.58,
			highest: 145.9,
			volumePrice: 145.7317,
			transactionCount: 2495,
			voluemPrice: 145.7317
		},
		{
			open: 145.75,
			close: 146.07,
			volume: 433226,
			time: 1654609200000,
			lowest: 145.6,
			highest: 146.085,
			volumePrice: 145.8406,
			transactionCount: 3756,
			voluemPrice: 145.8406
		},
		{
			open: 146.08,
			close: 146.02,
			volume: 410792,
			time: 1654609260000,
			lowest: 145.89,
			highest: 146.19,
			volumePrice: 146.0777,
			transactionCount: 3119,
			voluemPrice: 146.0777
		},
		{
			open: 146.01,
			close: 145.85,
			volume: 270520,
			time: 1654609320000,
			lowest: 145.84,
			highest: 146.1,
			volumePrice: 145.9698,
			transactionCount: 2306,
			voluemPrice: 145.9698
		},
		{
			open: 145.88,
			close: 145.86,
			volume: 230655,
			time: 1654609380000,
			lowest: 145.83,
			highest: 146.02,
			volumePrice: 145.9014,
			transactionCount: 2251,
			voluemPrice: 145.9014
		},
		{
			open: 145.87,
			close: 145.98,
			volume: 308974,
			time: 1654609440000,
			lowest: 145.86,
			highest: 146.11,
			volumePrice: 145.995,
			transactionCount: 2672,
			voluemPrice: 145.995
		},
		{
			open: 146.03,
			close: 146.08,
			volume: 319001,
			time: 1654609500000,
			lowest: 145.76,
			highest: 146.08,
			volumePrice: 145.907,
			transactionCount: 2908,
			voluemPrice: 145.907
		},
		{
			open: 146.08,
			close: 146.1,
			volume: 279089,
			time: 1654609560000,
			lowest: 145.895,
			highest: 146.125,
			volumePrice: 146.0327,
			transactionCount: 2337,
			voluemPrice: 146.0327
		},
		{
			open: 146.1039,
			close: 146.235,
			volume: 359683,
			time: 1654609620000,
			lowest: 145.93,
			highest: 146.34,
			volumePrice: 146.1843,
			transactionCount: 3077,
			voluemPrice: 146.1843
		},
		{
			open: 146.235,
			close: 146.2439,
			volume: 294327,
			time: 1654609680000,
			lowest: 146.13,
			highest: 146.32,
			volumePrice: 146.2245,
			transactionCount: 2589,
			voluemPrice: 146.2245
		},
		{
			open: 146.25,
			close: 146.46,
			volume: 307106,
			time: 1654609740000,
			lowest: 146.25,
			highest: 146.47,
			volumePrice: 146.3728,
			transactionCount: 2728,
			voluemPrice: 146.3728
		},
		{
			open: 146.47,
			close: 146.58,
			volume: 407606,
			time: 1654609800000,
			lowest: 146.43,
			highest: 146.6799,
			volumePrice: 146.5495,
			transactionCount: 3384,
			voluemPrice: 146.5495
		},
		{
			open: 146.58,
			close: 146.8901,
			volume: 589216,
			time: 1654609860000,
			lowest: 146.57,
			highest: 147.0999,
			volumePrice: 146.8982,
			transactionCount: 4606,
			voluemPrice: 146.8982
		},
		{
			open: 146.91,
			close: 146.8099,
			volume: 281964,
			time: 1654609920000,
			lowest: 146.76,
			highest: 146.9899,
			volumePrice: 146.8527,
			transactionCount: 2337,
			voluemPrice: 146.8527
		},
		{
			open: 146.815,
			close: 146.52,
			volume: 287115,
			time: 1654609980000,
			lowest: 146.5138,
			highest: 146.8565,
			volumePrice: 146.7386,
			transactionCount: 2421,
			voluemPrice: 146.7386
		},
		{
			open: 146.5469,
			close: 146.59,
			volume: 264206,
			time: 1654610040000,
			lowest: 146.4501,
			highest: 146.7,
			volumePrice: 146.5616,
			transactionCount: 2488,
			voluemPrice: 146.5616
		},
		{
			open: 146.62,
			close: 146.73,
			volume: 234528,
			time: 1654610100000,
			lowest: 146.58,
			highest: 146.78,
			volumePrice: 146.6847,
			transactionCount: 2042,
			voluemPrice: 146.6847
		},
		{
			open: 146.75,
			close: 146.715,
			volume: 231306,
			time: 1654610160000,
			lowest: 146.61,
			highest: 146.905,
			volumePrice: 146.7789,
			transactionCount: 1970,
			voluemPrice: 146.7789
		},
		{
			open: 146.72,
			close: 146.5566,
			volume: 272379,
			time: 1654610220000,
			lowest: 146.41,
			highest: 146.79,
			volumePrice: 146.5188,
			transactionCount: 2559,
			voluemPrice: 146.5188
		},
		{
			open: 146.54,
			close: 146.59,
			volume: 177906,
			time: 1654610280000,
			lowest: 146.48,
			highest: 146.68,
			volumePrice: 146.6018,
			transactionCount: 1684,
			voluemPrice: 146.6018
		},
		{
			open: 146.58,
			close: 146.6,
			volume: 157209,
			time: 1654610340000,
			lowest: 146.48,
			highest: 146.67,
			volumePrice: 146.5762,
			transactionCount: 1759,
			voluemPrice: 146.5762
		},
		{
			open: 146.62,
			close: 146.4299,
			volume: 269152,
			time: 1654610400000,
			lowest: 146.35,
			highest: 146.64,
			volumePrice: 146.4497,
			transactionCount: 2308,
			voluemPrice: 146.4497
		},
		{
			open: 146.42,
			close: 146.7,
			volume: 244113,
			time: 1654610460000,
			lowest: 146.4,
			highest: 146.7,
			volumePrice: 146.537,
			transactionCount: 2050,
			voluemPrice: 146.537
		},
		{
			open: 146.7,
			close: 146.67,
			volume: 205436,
			time: 1654610520000,
			lowest: 146.54,
			highest: 146.7956,
			volumePrice: 146.6911,
			transactionCount: 1649,
			voluemPrice: 146.6911
		},
		{
			open: 146.68,
			close: 146.71,
			volume: 161847,
			time: 1654610580000,
			lowest: 146.6018,
			highest: 146.78,
			volumePrice: 146.713,
			transactionCount: 1439,
			voluemPrice: 146.713
		},
		{
			open: 146.71,
			close: 146.75,
			volume: 148596,
			time: 1654610640000,
			lowest: 146.64,
			highest: 146.83,
			volumePrice: 146.7451,
			transactionCount: 1514,
			voluemPrice: 146.7451
		},
		{
			open: 146.76,
			close: 146.87,
			volume: 268948,
			time: 1654610700000,
			lowest: 146.735,
			highest: 147,
			volumePrice: 146.8923,
			transactionCount: 1988,
			voluemPrice: 146.8923
		},
		{
			open: 146.88,
			close: 146.45,
			volume: 214105,
			time: 1654610760000,
			lowest: 146.43,
			highest: 146.9,
			volumePrice: 146.6606,
			transactionCount: 2199,
			voluemPrice: 146.6606
		},
		{
			open: 146.43,
			close: 146.47,
			volume: 239298,
			time: 1654610820000,
			lowest: 146.31,
			highest: 146.54,
			volumePrice: 146.4095,
			transactionCount: 2229,
			voluemPrice: 146.4095
		},
		{
			open: 146.4899,
			close: 146.34,
			volume: 196785,
			time: 1654610880000,
			lowest: 146.22,
			highest: 146.5695,
			volumePrice: 146.3305,
			transactionCount: 1789,
			voluemPrice: 146.3305
		},
		{
			open: 146.35,
			close: 146.39,
			volume: 118718,
			time: 1654610940000,
			lowest: 146.33,
			highest: 146.4783,
			volumePrice: 146.4115,
			transactionCount: 1248,
			voluemPrice: 146.4115
		},
		{
			open: 146.3897,
			close: 146.2242,
			volume: 210406,
			time: 1654611000000,
			lowest: 146.22,
			highest: 146.5799,
			volumePrice: 146.4028,
			transactionCount: 1860,
			voluemPrice: 146.4028
		},
		{
			open: 146.23,
			close: 146.14,
			volume: 278959,
			time: 1654611060000,
			lowest: 146.03,
			highest: 146.32,
			volumePrice: 146.1563,
			transactionCount: 2559,
			voluemPrice: 146.1563
		},
		{
			open: 146.15,
			close: 146.1,
			volume: 324891,
			time: 1654611120000,
			lowest: 145.93,
			highest: 146.19,
			volumePrice: 146.0471,
			transactionCount: 2736,
			voluemPrice: 146.0471
		},
		{
			open: 146.1,
			close: 146.0519,
			volume: 109563,
			time: 1654611180000,
			lowest: 145.98,
			highest: 146.12,
			volumePrice: 146.0592,
			transactionCount: 1162,
			voluemPrice: 146.0592
		},
		{
			open: 146.0589,
			close: 146.355,
			volume: 184944,
			time: 1654611240000,
			lowest: 146.045,
			highest: 146.37,
			volumePrice: 146.2164,
			transactionCount: 1564,
			voluemPrice: 146.2164
		},
		{
			open: 146.36,
			close: 146.26,
			volume: 153227,
			time: 1654611300000,
			lowest: 146.15,
			highest: 146.39,
			volumePrice: 146.2858,
			transactionCount: 1619,
			voluemPrice: 146.2858
		},
		{
			open: 146.255,
			close: 146.6,
			volume: 246499,
			time: 1654611360000,
			lowest: 146.25,
			highest: 146.72,
			volumePrice: 146.5114,
			transactionCount: 2142,
			voluemPrice: 146.5114
		},
		{
			open: 146.58,
			close: 146.665,
			volume: 159187,
			time: 1654611420000,
			lowest: 146.49,
			highest: 146.71,
			volumePrice: 146.626,
			transactionCount: 1550,
			voluemPrice: 146.626
		},
		{
			open: 146.6619,
			close: 146.47,
			volume: 121823,
			time: 1654611480000,
			lowest: 146.45,
			highest: 146.67,
			volumePrice: 146.5715,
			transactionCount: 1237,
			voluemPrice: 146.5715
		},
		{
			open: 146.46,
			close: 146.4099,
			volume: 127970,
			time: 1654611540000,
			lowest: 146.27,
			highest: 146.54,
			volumePrice: 146.4044,
			transactionCount: 1537,
			voluemPrice: 146.4044
		},
		{
			open: 146.42,
			close: 146.5,
			volume: 159837,
			time: 1654611600000,
			lowest: 146.3,
			highest: 146.51,
			volumePrice: 146.4004,
			transactionCount: 1504,
			voluemPrice: 146.4004
		},
		{
			open: 146.5,
			close: 146.79,
			volume: 183948,
			time: 1654611660000,
			lowest: 146.5,
			highest: 146.79,
			volumePrice: 146.6435,
			transactionCount: 1541,
			voluemPrice: 146.6435
		},
		{
			open: 146.77,
			close: 146.94,
			volume: 310951,
			time: 1654611720000,
			lowest: 146.68,
			highest: 147.025,
			volumePrice: 146.882,
			transactionCount: 2408,
			voluemPrice: 146.882
		},
		{
			open: 146.95,
			close: 146.92,
			volume: 157612,
			time: 1654611780000,
			lowest: 146.83,
			highest: 146.95,
			volumePrice: 146.8922,
			transactionCount: 1559,
			voluemPrice: 146.8922
		},
		{
			open: 146.93,
			close: 146.83,
			volume: 113301,
			time: 1654611840000,
			lowest: 146.83,
			highest: 146.94,
			volumePrice: 146.8947,
			transactionCount: 1230,
			voluemPrice: 146.8947
		},
		{
			open: 146.8399,
			close: 146.88,
			volume: 155530,
			time: 1654611900000,
			lowest: 146.71,
			highest: 146.9,
			volumePrice: 146.7945,
			transactionCount: 1540,
			voluemPrice: 146.7945
		},
		{
			open: 146.88,
			close: 146.8502,
			volume: 179969,
			time: 1654611960000,
			lowest: 146.7594,
			highest: 146.97,
			volumePrice: 146.8756,
			transactionCount: 1351,
			voluemPrice: 146.8756
		},
		{
			open: 146.86,
			close: 146.9,
			volume: 102167,
			time: 1654612020000,
			lowest: 146.8,
			highest: 146.915,
			volumePrice: 146.8631,
			transactionCount: 1067,
			voluemPrice: 146.8631
		},
		{
			open: 146.895,
			close: 147.22,
			volume: 565293,
			time: 1654612080000,
			lowest: 146.88,
			highest: 147.28,
			volumePrice: 147.1267,
			transactionCount: 4916,
			voluemPrice: 147.1267
		},
		{
			open: 147.21,
			close: 147.18,
			volume: 194465,
			time: 1654612140000,
			lowest: 147.14,
			highest: 147.29,
			volumePrice: 147.2232,
			transactionCount: 1806,
			voluemPrice: 147.2232
		},
		{
			open: 147.18,
			close: 147.16,
			volume: 239913,
			time: 1654612200000,
			lowest: 147.13,
			highest: 147.26,
			volumePrice: 147.2028,
			transactionCount: 2106,
			voluemPrice: 147.2028
		},
		{
			open: 147.16,
			close: 147.275,
			volume: 191654,
			time: 1654612260000,
			lowest: 147.15,
			highest: 147.33,
			volumePrice: 147.2763,
			transactionCount: 1722,
			voluemPrice: 147.2763
		},
		{
			open: 147.27,
			close: 147.525,
			volume: 390706,
			time: 1654612320000,
			lowest: 147.27,
			highest: 147.67,
			volumePrice: 147.4799,
			transactionCount: 3289,
			voluemPrice: 147.4799
		},
		{
			open: 147.53,
			close: 147.5286,
			volume: 152863,
			time: 1654612380000,
			lowest: 147.485,
			highest: 147.58,
			volumePrice: 147.511,
			transactionCount: 1234,
			voluemPrice: 147.511
		},
		{
			open: 147.52,
			close: 147.63,
			volume: 238215,
			time: 1654612440000,
			lowest: 147.44,
			highest: 147.69,
			volumePrice: 147.6143,
			transactionCount: 1690,
			voluemPrice: 147.6143
		},
		{
			open: 147.64,
			close: 147.65,
			volume: 144810,
			time: 1654612500000,
			lowest: 147.57,
			highest: 147.67,
			volumePrice: 147.6217,
			transactionCount: 1243,
			voluemPrice: 147.6217
		},
		{
			open: 147.665,
			close: 147.96,
			volume: 313203,
			time: 1654612560000,
			lowest: 147.66,
			highest: 147.995,
			volumePrice: 147.8604,
			transactionCount: 2631,
			voluemPrice: 147.8604
		},
		{
			open: 147.97,
			close: 147.99,
			volume: 363583,
			time: 1654612620000,
			lowest: 147.86,
			highest: 148.0658,
			volumePrice: 147.9791,
			transactionCount: 3054,
			voluemPrice: 147.9791
		},
		{
			open: 147.995,
			close: 147.94,
			volume: 267619,
			time: 1654612680000,
			lowest: 147.91,
			highest: 148.09,
			volumePrice: 147.9931,
			transactionCount: 1912,
			voluemPrice: 147.9931
		},
		{
			open: 147.93,
			close: 147.94,
			volume: 162655,
			time: 1654612740000,
			lowest: 147.9,
			highest: 148.0562,
			volumePrice: 147.9696,
			transactionCount: 1589,
			voluemPrice: 147.9696
		},
		{
			open: 147.94,
			close: 147.805,
			volume: 247583,
			time: 1654612800000,
			lowest: 147.7701,
			highest: 147.99,
			volumePrice: 147.8659,
			transactionCount: 2330,
			voluemPrice: 147.8659
		},
		{
			open: 147.8,
			close: 147.7962,
			volume: 132267,
			time: 1654612860000,
			lowest: 147.7801,
			highest: 147.9,
			volumePrice: 147.8518,
			transactionCount: 1417,
			voluemPrice: 147.8518
		},
		{
			open: 147.8,
			close: 148.05,
			volume: 253119,
			time: 1654612920000,
			lowest: 147.77,
			highest: 148.05,
			volumePrice: 147.9226,
			transactionCount: 2231,
			voluemPrice: 147.9226
		},
		{
			open: 148.03,
			close: 148.01,
			volume: 174881,
			time: 1654612980000,
			lowest: 148,
			highest: 148.1079,
			volumePrice: 148.0548,
			transactionCount: 1545,
			voluemPrice: 148.0548
		},
		{
			open: 148.01,
			close: 147.9401,
			volume: 165169,
			time: 1654613040000,
			lowest: 147.88,
			highest: 148.065,
			volumePrice: 147.9641,
			transactionCount: 1692,
			voluemPrice: 147.9641
		},
		{
			open: 147.95,
			close: 148.01,
			volume: 130993,
			time: 1654613100000,
			lowest: 147.89,
			highest: 148.06,
			volumePrice: 147.9865,
			transactionCount: 1293,
			voluemPrice: 147.9865
		},
		{
			open: 148,
			close: 148.24,
			volume: 289672,
			time: 1654613160000,
			lowest: 148,
			highest: 148.3,
			volumePrice: 148.2006,
			transactionCount: 2626,
			voluemPrice: 148.2006
		},
		{
			open: 148.2372,
			close: 148.1963,
			volume: 137672,
			time: 1654613220000,
			lowest: 148.14,
			highest: 148.27,
			volumePrice: 148.1933,
			transactionCount: 1286,
			voluemPrice: 148.1933
		},
		{
			open: 148.2,
			close: 148.19,
			volume: 110390,
			time: 1654613280000,
			lowest: 148.16,
			highest: 148.23,
			volumePrice: 148.1898,
			transactionCount: 1021,
			voluemPrice: 148.1898
		},
		{
			open: 148.19,
			close: 148.17,
			volume: 134930,
			time: 1654613340000,
			lowest: 148.13,
			highest: 148.24,
			volumePrice: 148.1875,
			transactionCount: 1015,
			voluemPrice: 148.1875
		},
		{
			open: 148.17,
			close: 148.08,
			volume: 134622,
			time: 1654613400000,
			lowest: 148.06,
			highest: 148.22,
			volumePrice: 148.157,
			transactionCount: 1252,
			voluemPrice: 148.157
		},
		{
			open: 148.0848,
			close: 147.9199,
			volume: 240158,
			time: 1654613460000,
			lowest: 147.89,
			highest: 148.1,
			volumePrice: 147.9966,
			transactionCount: 2043,
			voluemPrice: 147.9966
		},
		{
			open: 147.912,
			close: 147.93,
			volume: 204044,
			time: 1654613520000,
			lowest: 147.82,
			highest: 147.948,
			volumePrice: 147.8808,
			transactionCount: 1441,
			voluemPrice: 147.8808
		},
		{
			open: 147.92,
			close: 147.8607,
			volume: 84607,
			time: 1654613580000,
			lowest: 147.855,
			highest: 147.96,
			volumePrice: 147.8942,
			transactionCount: 892,
			voluemPrice: 147.8942
		},
		{
			open: 147.865,
			close: 147.8701,
			volume: 83199,
			time: 1654613640000,
			lowest: 147.8502,
			highest: 147.93,
			volumePrice: 147.8933,
			transactionCount: 872,
			voluemPrice: 147.8933
		},
		{
			open: 147.87,
			close: 147.8025,
			volume: 164766,
			time: 1654613700000,
			lowest: 147.715,
			highest: 147.89,
			volumePrice: 147.7917,
			transactionCount: 1510,
			voluemPrice: 147.7917
		},
		{
			open: 147.8,
			close: 147.79,
			volume: 139109,
			time: 1654613760000,
			lowest: 147.74,
			highest: 147.86,
			volumePrice: 147.8028,
			transactionCount: 1200,
			voluemPrice: 147.8028
		},
		{
			open: 147.8,
			close: 147.71,
			volume: 139130,
			time: 1654613820000,
			lowest: 147.66,
			highest: 147.82,
			volumePrice: 147.7232,
			transactionCount: 1363,
			voluemPrice: 147.7232
		},
		{
			open: 147.7049,
			close: 147.61,
			volume: 129489,
			time: 1654613880000,
			lowest: 147.61,
			highest: 147.7814,
			volumePrice: 147.6857,
			transactionCount: 1229,
			voluemPrice: 147.6857
		},
		{
			open: 147.61,
			close: 147.745,
			volume: 141577,
			time: 1654613940000,
			lowest: 147.58,
			highest: 147.765,
			volumePrice: 147.6805,
			transactionCount: 1153,
			voluemPrice: 147.6805
		},
		{
			open: 147.75,
			close: 147.73,
			volume: 131932,
			time: 1654614000000,
			lowest: 147.66,
			highest: 147.83,
			volumePrice: 147.7485,
			transactionCount: 1182,
			voluemPrice: 147.7485
		},
		{
			open: 147.725,
			close: 147.725,
			volume: 78921,
			time: 1654614060000,
			lowest: 147.675,
			highest: 147.76,
			volumePrice: 147.7228,
			transactionCount: 752,
			voluemPrice: 147.7228
		},
		{
			open: 147.7278,
			close: 147.7508,
			volume: 151783,
			time: 1654614120000,
			lowest: 147.7,
			highest: 147.85,
			volumePrice: 147.7912,
			transactionCount: 1199,
			voluemPrice: 147.7912
		},
		{
			open: 147.75,
			close: 147.66,
			volume: 105129,
			time: 1654614180000,
			lowest: 147.64,
			highest: 147.8,
			volumePrice: 147.7251,
			transactionCount: 1006,
			voluemPrice: 147.7251
		},
		{
			open: 147.6553,
			close: 147.4155,
			volume: 179546,
			time: 1654614240000,
			lowest: 147.39,
			highest: 147.69,
			volumePrice: 147.5278,
			transactionCount: 1719,
			voluemPrice: 147.5278
		},
		{
			open: 147.4153,
			close: 147.2686,
			volume: 204571,
			time: 1654614300000,
			lowest: 147.21,
			highest: 147.4363,
			volumePrice: 147.3328,
			transactionCount: 1715,
			voluemPrice: 147.3328
		},
		{
			open: 147.27,
			close: 147.34,
			volume: 139591,
			time: 1654614360000,
			lowest: 147.22,
			highest: 147.34,
			volumePrice: 147.2734,
			transactionCount: 978,
			voluemPrice: 147.2734
		},
		{
			open: 147.3381,
			close: 147.47,
			volume: 208979,
			time: 1654614420000,
			lowest: 147.3201,
			highest: 147.5,
			volumePrice: 147.4326,
			transactionCount: 1203,
			voluemPrice: 147.4326
		},
		{
			open: 147.475,
			close: 147.54,
			volume: 105058,
			time: 1654614480000,
			lowest: 147.43,
			highest: 147.54,
			volumePrice: 147.4835,
			transactionCount: 963,
			voluemPrice: 147.4835
		},
		{
			open: 147.54,
			close: 147.5265,
			volume: 114467,
			time: 1654614540000,
			lowest: 147.4001,
			highest: 147.55,
			volumePrice: 147.4721,
			transactionCount: 905,
			voluemPrice: 147.4721
		},
		{
			open: 147.53,
			close: 147.6783,
			volume: 151669,
			time: 1654614600000,
			lowest: 147.42,
			highest: 147.7,
			volumePrice: 147.5762,
			transactionCount: 1228,
			voluemPrice: 147.5762
		},
		{
			open: 147.66,
			close: 147.55,
			volume: 112814,
			time: 1654614660000,
			lowest: 147.535,
			highest: 147.67,
			volumePrice: 147.5986,
			transactionCount: 1004,
			voluemPrice: 147.5986
		},
		{
			open: 147.5501,
			close: 147.7319,
			volume: 171596,
			time: 1654614720000,
			lowest: 147.43,
			highest: 147.75,
			volumePrice: 147.5794,
			transactionCount: 1314,
			voluemPrice: 147.5794
		},
		{
			open: 147.74,
			close: 147.64,
			volume: 155321,
			time: 1654614780000,
			lowest: 147.64,
			highest: 147.8,
			volumePrice: 147.7087,
			transactionCount: 969,
			voluemPrice: 147.7087
		},
		{
			open: 147.64,
			close: 147.725,
			volume: 77089,
			time: 1654614840000,
			lowest: 147.5846,
			highest: 147.74,
			volumePrice: 147.6588,
			transactionCount: 702,
			voluemPrice: 147.6588
		},
		{
			open: 147.72,
			close: 147.73,
			volume: 102767,
			time: 1654614900000,
			lowest: 147.66,
			highest: 147.77,
			volumePrice: 147.7175,
			transactionCount: 892,
			voluemPrice: 147.7175
		},
		{
			open: 147.73,
			close: 147.685,
			volume: 91017,
			time: 1654614960000,
			lowest: 147.6212,
			highest: 147.75,
			volumePrice: 147.6875,
			transactionCount: 887,
			voluemPrice: 147.6875
		},
		{
			open: 147.68,
			close: 147.52,
			volume: 102196,
			time: 1654615020000,
			lowest: 147.515,
			highest: 147.68,
			volumePrice: 147.5945,
			transactionCount: 992,
			voluemPrice: 147.5945
		},
		{
			open: 147.515,
			close: 147.475,
			volume: 130618,
			time: 1654615080000,
			lowest: 147.39,
			highest: 147.56,
			volumePrice: 147.465,
			transactionCount: 1087,
			voluemPrice: 147.465
		},
		{
			open: 147.4771,
			close: 147.465,
			volume: 110491,
			time: 1654615140000,
			lowest: 147.39,
			highest: 147.5361,
			volumePrice: 147.4743,
			transactionCount: 929,
			voluemPrice: 147.4743
		},
		{
			open: 147.47,
			close: 147.48,
			volume: 87847,
			time: 1654615200000,
			lowest: 147.42,
			highest: 147.54,
			volumePrice: 147.502,
			transactionCount: 785,
			voluemPrice: 147.502
		},
		{
			open: 147.4897,
			close: 147.33,
			volume: 151380,
			time: 1654615260000,
			lowest: 147.23,
			highest: 147.49,
			volumePrice: 147.3249,
			transactionCount: 1216,
			voluemPrice: 147.3249
		},
		{
			open: 147.3377,
			close: 147.11,
			volume: 182276,
			time: 1654615320000,
			lowest: 147.06,
			highest: 147.3377,
			volumePrice: 147.1578,
			transactionCount: 1519,
			voluemPrice: 147.1578
		},
		{
			open: 147.11,
			close: 147.15,
			volume: 147036,
			time: 1654615380000,
			lowest: 147.03,
			highest: 147.15,
			volumePrice: 147.0926,
			transactionCount: 1160,
			voluemPrice: 147.0926
		},
		{
			open: 147.15,
			close: 147.16,
			volume: 155815,
			time: 1654615440000,
			lowest: 147.1484,
			highest: 147.28,
			volumePrice: 147.2146,
			transactionCount: 1076,
			voluemPrice: 147.2146
		},
		{
			open: 147.16,
			close: 147.195,
			volume: 64902,
			time: 1654615500000,
			lowest: 147.105,
			highest: 147.21,
			volumePrice: 147.1617,
			transactionCount: 745,
			voluemPrice: 147.1617
		},
		{
			open: 147.2,
			close: 147.25,
			volume: 106275,
			time: 1654615560000,
			lowest: 147.1823,
			highest: 147.265,
			volumePrice: 147.2264,
			transactionCount: 849,
			voluemPrice: 147.2264
		},
		{
			open: 147.25,
			close: 147.21,
			volume: 109354,
			time: 1654615620000,
			lowest: 147.11,
			highest: 147.275,
			volumePrice: 147.1919,
			transactionCount: 869,
			voluemPrice: 147.1919
		},
		{
			open: 147.2086,
			close: 147.0864,
			volume: 965993,
			time: 1654615680000,
			lowest: 147.03,
			highest: 147.22,
			volumePrice: 147.1933,
			transactionCount: 1107,
			voluemPrice: 147.1933
		},
		{
			open: 147.085,
			close: 146.97,
			volume: 171415,
			time: 1654615740000,
			lowest: 146.93,
			highest: 147.085,
			volumePrice: 146.9934,
			transactionCount: 1431,
			voluemPrice: 146.9934
		},
		{
			open: 146.97,
			close: 146.9089,
			volume: 163792,
			time: 1654615800000,
			lowest: 146.8489,
			highest: 146.97,
			volumePrice: 146.8965,
			transactionCount: 1360,
			voluemPrice: 146.8965
		},
		{
			open: 146.9001,
			close: 146.89,
			volume: 211859,
			time: 1654615860000,
			lowest: 146.785,
			highest: 146.98,
			volumePrice: 146.8831,
			transactionCount: 1402,
			voluemPrice: 146.8831
		},
		{
			open: 146.88,
			close: 147.0379,
			volume: 145859,
			time: 1654615920000,
			lowest: 146.855,
			highest: 147.04,
			volumePrice: 146.9456,
			transactionCount: 1037,
			voluemPrice: 146.9456
		},
		{
			open: 147.03,
			close: 147.12,
			volume: 118315,
			time: 1654615980000,
			lowest: 147.015,
			highest: 147.16,
			volumePrice: 147.0869,
			transactionCount: 975,
			voluemPrice: 147.0869
		},
		{
			open: 147.12,
			close: 147.17,
			volume: 101968,
			time: 1654616040000,
			lowest: 147.05,
			highest: 147.17,
			volumePrice: 147.1104,
			transactionCount: 963,
			voluemPrice: 147.1104
		},
		{
			open: 147.16,
			close: 147.19,
			volume: 84188,
			time: 1654616100000,
			lowest: 147.09,
			highest: 147.2,
			volumePrice: 147.1496,
			transactionCount: 910,
			voluemPrice: 147.1496
		},
		{
			open: 147.19,
			close: 147.09,
			volume: 104954,
			time: 1654616160000,
			lowest: 147,
			highest: 147.19,
			volumePrice: 147.0744,
			transactionCount: 802,
			voluemPrice: 147.0744
		},
		{
			open: 147.09,
			close: 147.0495,
			volume: 78667,
			time: 1654616220000,
			lowest: 146.985,
			highest: 147.19,
			volumePrice: 147.1003,
			transactionCount: 863,
			voluemPrice: 147.1003
		},
		{
			open: 147.0401,
			close: 147.22,
			volume: 77619,
			time: 1654616280000,
			lowest: 147.01,
			highest: 147.22,
			volumePrice: 147.1029,
			transactionCount: 813,
			voluemPrice: 147.1029
		},
		{
			open: 147.2199,
			close: 147.44,
			volume: 174340,
			time: 1654616340000,
			lowest: 147.195,
			highest: 147.45,
			volumePrice: 147.3186,
			transactionCount: 1346,
			voluemPrice: 147.3186
		},
		{
			open: 147.4382,
			close: 147.3744,
			volume: 127172,
			time: 1654616400000,
			lowest: 147.29,
			highest: 147.44,
			volumePrice: 147.3739,
			transactionCount: 925,
			voluemPrice: 147.3739
		},
		{
			open: 147.37,
			close: 147.465,
			volume: 133873,
			time: 1654616460000,
			lowest: 147.36,
			highest: 147.4985,
			volumePrice: 147.454,
			transactionCount: 1053,
			voluemPrice: 147.454
		},
		{
			open: 147.47,
			close: 147.425,
			volume: 148483,
			time: 1654616520000,
			lowest: 147.41,
			highest: 147.53,
			volumePrice: 147.4802,
			transactionCount: 962,
			voluemPrice: 147.4802
		},
		{
			open: 147.42,
			close: 147.4345,
			volume: 87516,
			time: 1654616580000,
			lowest: 147.33,
			highest: 147.44,
			volumePrice: 147.3932,
			transactionCount: 779,
			voluemPrice: 147.3932
		},
		{
			open: 147.44,
			close: 147.31,
			volume: 111993,
			time: 1654616640000,
			lowest: 147.26,
			highest: 147.4458,
			volumePrice: 147.3329,
			transactionCount: 972,
			voluemPrice: 147.3329
		},
		{
			open: 147.32,
			close: 147.34,
			volume: 108707,
			time: 1654616700000,
			lowest: 147.215,
			highest: 147.34,
			volumePrice: 147.2629,
			transactionCount: 713,
			voluemPrice: 147.2629
		},
		{
			open: 147.335,
			close: 147.34,
			volume: 172182,
			time: 1654616760000,
			lowest: 147.28,
			highest: 147.54,
			volumePrice: 147.4209,
			transactionCount: 1541,
			voluemPrice: 147.4209
		},
		{
			open: 147.315,
			close: 147.09,
			volume: 155013,
			time: 1654616820000,
			lowest: 146.97,
			highest: 147.3593,
			volumePrice: 147.1186,
			transactionCount: 1406,
			voluemPrice: 147.1186
		},
		{
			open: 147.085,
			close: 147.12,
			volume: 127641,
			time: 1654616880000,
			lowest: 146.96,
			highest: 147.15,
			volumePrice: 147.0324,
			transactionCount: 1079,
			voluemPrice: 147.0324
		},
		{
			open: 147.135,
			close: 146.93,
			volume: 107882,
			time: 1654616940000,
			lowest: 146.885,
			highest: 147.1882,
			volumePrice: 147.003,
			transactionCount: 1087,
			voluemPrice: 147.003
		},
		{
			open: 146.939,
			close: 147.12,
			volume: 87019,
			time: 1654617000000,
			lowest: 146.9,
			highest: 147.13,
			volumePrice: 147.0161,
			transactionCount: 870,
			voluemPrice: 147.0161
		},
		{
			open: 147.12,
			close: 147.23,
			volume: 76535,
			time: 1654617060000,
			lowest: 147.1,
			highest: 147.2699,
			volumePrice: 147.1871,
			transactionCount: 770,
			voluemPrice: 147.1871
		},
		{
			open: 147.24,
			close: 147.275,
			volume: 69077,
			time: 1654617120000,
			lowest: 147.2201,
			highest: 147.3,
			volumePrice: 147.2611,
			transactionCount: 733,
			voluemPrice: 147.2611
		},
		{
			open: 147.295,
			close: 147.27,
			volume: 69750,
			time: 1654617180000,
			lowest: 147.255,
			highest: 147.36,
			volumePrice: 147.3031,
			transactionCount: 753,
			voluemPrice: 147.3031
		},
		{
			open: 147.25,
			close: 147.21,
			volume: 90566,
			time: 1654617240000,
			lowest: 147.15,
			highest: 147.285,
			volumePrice: 147.2216,
			transactionCount: 842,
			voluemPrice: 147.2216
		},
		{
			open: 147.2,
			close: 147.32,
			volume: 91968,
			time: 1654617300000,
			lowest: 147.1799,
			highest: 147.32,
			volumePrice: 147.263,
			transactionCount: 855,
			voluemPrice: 147.263
		},
		{
			open: 147.31,
			close: 147.49,
			volume: 142265,
			time: 1654617360000,
			lowest: 147.2904,
			highest: 147.49,
			volumePrice: 147.3999,
			transactionCount: 1104,
			voluemPrice: 147.3999
		},
		{
			open: 147.495,
			close: 147.592,
			volume: 158167,
			time: 1654617420000,
			lowest: 147.42,
			highest: 147.61,
			volumePrice: 147.543,
			transactionCount: 1145,
			voluemPrice: 147.543
		},
		{
			open: 147.595,
			close: 147.62,
			volume: 133306,
			time: 1654617480000,
			lowest: 147.55,
			highest: 147.6953,
			volumePrice: 147.6448,
			transactionCount: 1145,
			voluemPrice: 147.6448
		},
		{
			open: 147.62,
			close: 147.57,
			volume: 148847,
			time: 1654617540000,
			lowest: 147.56,
			highest: 147.69,
			volumePrice: 147.6135,
			transactionCount: 1192,
			voluemPrice: 147.6135
		},
		{
			open: 147.565,
			close: 147.6701,
			volume: 151354,
			time: 1654617600000,
			lowest: 147.53,
			highest: 147.75,
			volumePrice: 147.6687,
			transactionCount: 1356,
			voluemPrice: 147.6687
		},
		{
			open: 147.6666,
			close: 147.68,
			volume: 81883,
			time: 1654617660000,
			lowest: 147.5837,
			highest: 147.74,
			volumePrice: 147.6706,
			transactionCount: 688,
			voluemPrice: 147.6706
		},
		{
			open: 147.68,
			close: 147.6999,
			volume: 119122,
			time: 1654617720000,
			lowest: 147.52,
			highest: 147.72,
			volumePrice: 147.6235,
			transactionCount: 1108,
			voluemPrice: 147.6235
		},
		{
			open: 147.7,
			close: 147.61,
			volume: 83446,
			time: 1654617780000,
			lowest: 147.6,
			highest: 147.73,
			volumePrice: 147.6764,
			transactionCount: 690,
			voluemPrice: 147.6764
		},
		{
			open: 147.615,
			close: 147.55,
			volume: 86493,
			time: 1654617840000,
			lowest: 147.471,
			highest: 147.63,
			volumePrice: 147.5494,
			transactionCount: 782,
			voluemPrice: 147.5494
		},
		{
			open: 147.55,
			close: 147.4201,
			volume: 77995,
			time: 1654617900000,
			lowest: 147.42,
			highest: 147.555,
			volumePrice: 147.4904,
			transactionCount: 747,
			voluemPrice: 147.4904
		},
		{
			open: 147.42,
			close: 147.4399,
			volume: 114104,
			time: 1654617960000,
			lowest: 147.28,
			highest: 147.44,
			volumePrice: 147.3719,
			transactionCount: 798,
			voluemPrice: 147.3719
		},
		{
			open: 147.43,
			close: 147.43,
			volume: 86624,
			time: 1654618020000,
			lowest: 147.4202,
			highest: 147.5198,
			volumePrice: 147.4852,
			transactionCount: 713,
			voluemPrice: 147.4852
		},
		{
			open: 147.44,
			close: 147.49,
			volume: 69196,
			time: 1654618080000,
			lowest: 147.375,
			highest: 147.53,
			volumePrice: 147.4823,
			transactionCount: 653,
			voluemPrice: 147.4823
		},
		{
			open: 147.48,
			close: 147.5,
			volume: 66034,
			time: 1654618140000,
			lowest: 147.44,
			highest: 147.6,
			volumePrice: 147.5183,
			transactionCount: 715,
			voluemPrice: 147.5183
		},
		{
			open: 147.525,
			close: 147.5109,
			volume: 74466,
			time: 1654618200000,
			lowest: 147.48,
			highest: 147.63,
			volumePrice: 147.5439,
			transactionCount: 677,
			voluemPrice: 147.5439
		},
		{
			open: 147.5146,
			close: 147.4999,
			volume: 82824,
			time: 1654618260000,
			lowest: 147.48,
			highest: 147.58,
			volumePrice: 147.5314,
			transactionCount: 708,
			voluemPrice: 147.5314
		},
		{
			open: 147.5,
			close: 147.6157,
			volume: 107750,
			time: 1654618320000,
			lowest: 147.47,
			highest: 147.67,
			volumePrice: 147.5809,
			transactionCount: 864,
			voluemPrice: 147.5809
		},
		{
			open: 147.6263,
			close: 147.595,
			volume: 54538,
			time: 1654618380000,
			lowest: 147.57,
			highest: 147.665,
			volumePrice: 147.6185,
			transactionCount: 536,
			voluemPrice: 147.6185
		},
		{
			open: 147.5801,
			close: 147.64,
			volume: 54992,
			time: 1654618440000,
			lowest: 147.58,
			highest: 147.655,
			volumePrice: 147.6184,
			transactionCount: 605,
			voluemPrice: 147.6184
		},
		{
			open: 147.63,
			close: 147.325,
			volume: 108847,
			time: 1654618500000,
			lowest: 147.3084,
			highest: 147.65,
			volumePrice: 147.5022,
			transactionCount: 988,
			voluemPrice: 147.5022
		},
		{
			open: 147.33,
			close: 147.2454,
			volume: 142626,
			time: 1654618560000,
			lowest: 147.11,
			highest: 147.33,
			volumePrice: 147.2173,
			transactionCount: 1207,
			voluemPrice: 147.2173
		},
		{
			open: 147.25,
			close: 147.21,
			volume: 93687,
			time: 1654618620000,
			lowest: 147.16,
			highest: 147.3072,
			volumePrice: 147.237,
			transactionCount: 893,
			voluemPrice: 147.237
		},
		{
			open: 147.2083,
			close: 147.225,
			volume: 86425,
			time: 1654618680000,
			lowest: 147.19,
			highest: 147.34,
			volumePrice: 147.2727,
			transactionCount: 880,
			voluemPrice: 147.2727
		},
		{
			open: 147.22,
			close: 147.24,
			volume: 93499,
			time: 1654618740000,
			lowest: 147.155,
			highest: 147.27,
			volumePrice: 147.2281,
			transactionCount: 688,
			voluemPrice: 147.2281
		},
		{
			open: 147.23,
			close: 147.275,
			volume: 69148,
			time: 1654618800000,
			lowest: 147.21,
			highest: 147.35,
			volumePrice: 147.2844,
			transactionCount: 746,
			voluemPrice: 147.2844
		},
		{
			open: 147.28,
			close: 147.155,
			volume: 85661,
			time: 1654618860000,
			lowest: 147.09,
			highest: 147.31,
			volumePrice: 147.1743,
			transactionCount: 853,
			voluemPrice: 147.1743
		},
		{
			open: 147.1502,
			close: 147.15,
			volume: 84685,
			time: 1654618920000,
			lowest: 147.12,
			highest: 147.24,
			volumePrice: 147.1969,
			transactionCount: 697,
			voluemPrice: 147.1969
		},
		{
			open: 147.145,
			close: 147.178,
			volume: 58701,
			time: 1654618980000,
			lowest: 147.14,
			highest: 147.21,
			volumePrice: 147.1824,
			transactionCount: 674,
			voluemPrice: 147.1824
		},
		{
			open: 147.175,
			close: 147.22,
			volume: 53472,
			time: 1654619040000,
			lowest: 147.156,
			highest: 147.2399,
			volumePrice: 147.2038,
			transactionCount: 623,
			voluemPrice: 147.2038
		},
		{
			open: 147.22,
			close: 147.25,
			volume: 58628,
			time: 1654619100000,
			lowest: 147.185,
			highest: 147.29,
			volumePrice: 147.2403,
			transactionCount: 683,
			voluemPrice: 147.2403
		},
		{
			open: 147.26,
			close: 147.29,
			volume: 78157,
			time: 1654619160000,
			lowest: 147.1956,
			highest: 147.3,
			volumePrice: 147.2532,
			transactionCount: 849,
			voluemPrice: 147.2532
		},
		{
			open: 147.29,
			close: 147.3073,
			volume: 84239,
			time: 1654619220000,
			lowest: 147.28,
			highest: 147.38,
			volumePrice: 147.3242,
			transactionCount: 738,
			voluemPrice: 147.3242
		},
		{
			open: 147.3,
			close: 147.28,
			volume: 83332,
			time: 1654619280000,
			lowest: 147.25,
			highest: 147.36,
			volumePrice: 147.2936,
			transactionCount: 796,
			voluemPrice: 147.2936
		},
		{
			open: 147.27,
			close: 147.28,
			volume: 49308,
			time: 1654619340000,
			lowest: 147.23,
			highest: 147.325,
			volumePrice: 147.2795,
			transactionCount: 579,
			voluemPrice: 147.2795
		},
		{
			open: 147.28,
			close: 147.19,
			volume: 61016,
			time: 1654619400000,
			lowest: 147.18,
			highest: 147.2899,
			volumePrice: 147.2301,
			transactionCount: 670,
			voluemPrice: 147.2301
		},
		{
			open: 147.19,
			close: 147.22,
			volume: 81767,
			time: 1654619460000,
			lowest: 147.14,
			highest: 147.31,
			volumePrice: 147.2215,
			transactionCount: 768,
			voluemPrice: 147.2215
		},
		{
			open: 147.23,
			close: 147.29,
			volume: 58232,
			time: 1654619520000,
			lowest: 147.23,
			highest: 147.35,
			volumePrice: 147.3079,
			transactionCount: 615,
			voluemPrice: 147.3079
		},
		{
			open: 147.29,
			close: 147.6267,
			volume: 174109,
			time: 1654619580000,
			lowest: 147.26,
			highest: 147.635,
			volumePrice: 147.4501,
			transactionCount: 1484,
			voluemPrice: 147.4501
		},
		{
			open: 147.62,
			close: 147.5401,
			volume: 92709,
			time: 1654619640000,
			lowest: 147.5,
			highest: 147.63,
			volumePrice: 147.5629,
			transactionCount: 746,
			voluemPrice: 147.5629
		},
		{
			open: 147.54,
			close: 147.5,
			volume: 93216,
			time: 1654619700000,
			lowest: 147.4237,
			highest: 147.555,
			volumePrice: 147.4935,
			transactionCount: 645,
			voluemPrice: 147.4935
		},
		{
			open: 147.5,
			close: 147.525,
			volume: 85816,
			time: 1654619760000,
			lowest: 147.43,
			highest: 147.57,
			volumePrice: 147.5175,
			transactionCount: 645,
			voluemPrice: 147.5175
		},
		{
			open: 147.54,
			close: 147.585,
			volume: 51529,
			time: 1654619820000,
			lowest: 147.49,
			highest: 147.585,
			volumePrice: 147.5342,
			transactionCount: 572,
			voluemPrice: 147.5342
		},
		{
			open: 147.59,
			close: 147.605,
			volume: 62253,
			time: 1654619880000,
			lowest: 147.5,
			highest: 147.605,
			volumePrice: 147.5467,
			transactionCount: 632,
			voluemPrice: 147.5467
		},
		{
			open: 147.61,
			close: 147.65,
			volume: 65503,
			time: 1654619940000,
			lowest: 147.56,
			highest: 147.66,
			volumePrice: 147.6135,
			transactionCount: 567,
			voluemPrice: 147.6135
		},
		{
			open: 147.65,
			close: 147.8157,
			volume: 187490,
			time: 1654620000000,
			lowest: 147.645,
			highest: 147.8299,
			volumePrice: 147.7542,
			transactionCount: 1521,
			voluemPrice: 147.7542
		},
		{
			open: 147.825,
			close: 147.74,
			volume: 132311,
			time: 1654620060000,
			lowest: 147.74,
			highest: 147.87,
			volumePrice: 147.8188,
			transactionCount: 1102,
			voluemPrice: 147.8188
		},
		{
			open: 147.73,
			close: 147.8401,
			volume: 100761,
			time: 1654620120000,
			lowest: 147.73,
			highest: 147.86,
			volumePrice: 147.8051,
			transactionCount: 748,
			voluemPrice: 147.8051
		},
		{
			open: 147.85,
			close: 147.82,
			volume: 74420,
			time: 1654620180000,
			lowest: 147.79,
			highest: 147.885,
			volumePrice: 147.835,
			transactionCount: 609,
			voluemPrice: 147.835
		},
		{
			open: 147.81,
			close: 147.72,
			volume: 108977,
			time: 1654620240000,
			lowest: 147.65,
			highest: 147.81,
			volumePrice: 147.717,
			transactionCount: 894,
			voluemPrice: 147.717
		},
		{
			open: 147.7136,
			close: 147.76,
			volume: 72226,
			time: 1654620300000,
			lowest: 147.7136,
			highest: 147.82,
			volumePrice: 147.7843,
			transactionCount: 635,
			voluemPrice: 147.7843
		},
		{
			open: 147.75,
			close: 147.74,
			volume: 63696,
			time: 1654620360000,
			lowest: 147.695,
			highest: 147.7649,
			volumePrice: 147.7333,
			transactionCount: 686,
			voluemPrice: 147.7333
		},
		{
			open: 147.7395,
			close: 147.74,
			volume: 74385,
			time: 1654620420000,
			lowest: 147.7,
			highest: 147.7899,
			volumePrice: 147.7454,
			transactionCount: 647,
			voluemPrice: 147.7454
		},
		{
			open: 147.74,
			close: 147.78,
			volume: 63639,
			time: 1654620480000,
			lowest: 147.7321,
			highest: 147.815,
			volumePrice: 147.7792,
			transactionCount: 567,
			voluemPrice: 147.7792
		},
		{
			open: 147.77,
			close: 147.785,
			volume: 85097,
			time: 1654620540000,
			lowest: 147.76,
			highest: 147.85,
			volumePrice: 147.8069,
			transactionCount: 695,
			voluemPrice: 147.8069
		},
		{
			open: 147.79,
			close: 147.65,
			volume: 116706,
			time: 1654620600000,
			lowest: 147.645,
			highest: 147.8,
			volumePrice: 147.6971,
			transactionCount: 1009,
			voluemPrice: 147.6971
		},
		{
			open: 147.65,
			close: 147.6002,
			volume: 135172,
			time: 1654620660000,
			lowest: 147.57,
			highest: 147.685,
			volumePrice: 147.6237,
			transactionCount: 1200,
			voluemPrice: 147.6237
		},
		{
			open: 147.62,
			close: 147.63,
			volume: 166042,
			time: 1654620720000,
			lowest: 147.52,
			highest: 147.64,
			volumePrice: 147.5815,
			transactionCount: 1523,
			voluemPrice: 147.5815
		},
		{
			open: 147.63,
			close: 147.73,
			volume: 85944,
			time: 1654620780000,
			lowest: 147.62,
			highest: 147.7599,
			volumePrice: 147.6883,
			transactionCount: 714,
			voluemPrice: 147.6883
		},
		{
			open: 147.7243,
			close: 147.74,
			volume: 40631,
			time: 1654620840000,
			lowest: 147.7,
			highest: 147.76,
			volumePrice: 147.725,
			transactionCount: 562,
			voluemPrice: 147.725
		},
		{
			open: 147.745,
			close: 147.6642,
			volume: 67026,
			time: 1654620900000,
			lowest: 147.64,
			highest: 147.78,
			volumePrice: 147.706,
			transactionCount: 563,
			voluemPrice: 147.706
		},
		{
			open: 147.67,
			close: 147.6901,
			volume: 90479,
			time: 1654620960000,
			lowest: 147.63,
			highest: 147.73,
			volumePrice: 147.679,
			transactionCount: 602,
			voluemPrice: 147.679
		},
		{
			open: 147.71,
			close: 147.6199,
			volume: 43215,
			time: 1654621020000,
			lowest: 147.615,
			highest: 147.7151,
			volumePrice: 147.6644,
			transactionCount: 501,
			voluemPrice: 147.6644
		},
		{
			open: 147.615,
			close: 147.63,
			volume: 68529,
			time: 1654621080000,
			lowest: 147.56,
			highest: 147.7,
			volumePrice: 147.6376,
			transactionCount: 612,
			voluemPrice: 147.6376
		},
		{
			open: 147.623,
			close: 147.68,
			volume: 40483,
			time: 1654621140000,
			lowest: 147.623,
			highest: 147.695,
			volumePrice: 147.6733,
			transactionCount: 437,
			voluemPrice: 147.6733
		},
		{
			open: 147.685,
			close: 147.71,
			volume: 56831,
			time: 1654621200000,
			lowest: 147.65,
			highest: 147.72,
			volumePrice: 147.6891,
			transactionCount: 565,
			voluemPrice: 147.6891
		},
		{
			open: 147.705,
			close: 147.63,
			volume: 116650,
			time: 1654621260000,
			lowest: 147.62,
			highest: 147.785,
			volumePrice: 147.7112,
			transactionCount: 1135,
			voluemPrice: 147.7112
		},
		{
			open: 147.645,
			close: 147.78,
			volume: 85071,
			time: 1654621320000,
			lowest: 147.645,
			highest: 147.79,
			volumePrice: 147.7316,
			transactionCount: 740,
			voluemPrice: 147.7316
		},
		{
			open: 147.79,
			close: 147.77,
			volume: 63546,
			time: 1654621380000,
			lowest: 147.75,
			highest: 147.81,
			volumePrice: 147.7794,
			transactionCount: 586,
			voluemPrice: 147.7794
		},
		{
			open: 147.775,
			close: 147.905,
			volume: 160739,
			time: 1654621440000,
			lowest: 147.76,
			highest: 147.93,
			volumePrice: 147.8731,
			transactionCount: 1193,
			voluemPrice: 147.8731
		},
		{
			open: 147.905,
			close: 147.8901,
			volume: 92864,
			time: 1654621500000,
			lowest: 147.885,
			highest: 147.98,
			volumePrice: 147.9341,
			transactionCount: 880,
			voluemPrice: 147.9341
		},
		{
			open: 147.905,
			close: 147.87,
			volume: 87330,
			time: 1654621560000,
			lowest: 147.8101,
			highest: 147.91,
			volumePrice: 147.8536,
			transactionCount: 768,
			voluemPrice: 147.8536
		},
		{
			open: 147.88,
			close: 147.9176,
			volume: 51151,
			time: 1654621620000,
			lowest: 147.8786,
			highest: 147.93,
			volumePrice: 147.9088,
			transactionCount: 498,
			voluemPrice: 147.9088
		},
		{
			open: 147.92,
			close: 147.96,
			volume: 107132,
			time: 1654621680000,
			lowest: 147.9001,
			highest: 147.99,
			volumePrice: 147.9493,
			transactionCount: 797,
			voluemPrice: 147.9493
		},
		{
			open: 147.98,
			close: 148.09,
			volume: 281084,
			time: 1654621740000,
			lowest: 147.96,
			highest: 148.1,
			volumePrice: 148.0265,
			transactionCount: 2208,
			voluemPrice: 148.0265
		},
		{
			open: 148.09,
			close: 148.1377,
			volume: 182447,
			time: 1654621800000,
			lowest: 148.04,
			highest: 148.16,
			volumePrice: 148.1024,
			transactionCount: 1088,
			voluemPrice: 148.1024
		},
		{
			open: 148.13,
			close: 147.9801,
			volume: 102769,
			time: 1654621860000,
			lowest: 147.9787,
			highest: 148.14,
			volumePrice: 148.0524,
			transactionCount: 800,
			voluemPrice: 148.0524
		},
		{
			open: 147.9815,
			close: 148.075,
			volume: 124391,
			time: 1654621920000,
			lowest: 147.95,
			highest: 148.08,
			volumePrice: 148.011,
			transactionCount: 1048,
			voluemPrice: 148.011
		},
		{
			open: 148.07,
			close: 148.0199,
			volume: 67851,
			time: 1654621980000,
			lowest: 148.015,
			highest: 148.08,
			volumePrice: 148.0475,
			transactionCount: 589,
			voluemPrice: 148.0475
		},
		{
			open: 148.015,
			close: 148.085,
			volume: 79622,
			time: 1654622040000,
			lowest: 148,
			highest: 148.09,
			volumePrice: 148.0445,
			transactionCount: 749,
			voluemPrice: 148.0445
		},
		{
			open: 148.0899,
			close: 148.11,
			volume: 77478,
			time: 1654622100000,
			lowest: 148.06,
			highest: 148.12,
			volumePrice: 148.0858,
			transactionCount: 677,
			voluemPrice: 148.0858
		},
		{
			open: 148.11,
			close: 148.16,
			volume: 120488,
			time: 1654622160000,
			lowest: 148.09,
			highest: 148.19,
			volumePrice: 148.1472,
			transactionCount: 880,
			voluemPrice: 148.1472
		},
		{
			open: 148.16,
			close: 148.175,
			volume: 86412,
			time: 1654622220000,
			lowest: 148.12,
			highest: 148.2,
			volumePrice: 148.1646,
			transactionCount: 790,
			voluemPrice: 148.1646
		},
		{
			open: 148.195,
			close: 148.109,
			volume: 64797,
			time: 1654622280000,
			lowest: 148.109,
			highest: 148.21,
			volumePrice: 148.1514,
			transactionCount: 631,
			voluemPrice: 148.1514
		},
		{
			open: 148.115,
			close: 148.14,
			volume: 71995,
			time: 1654622340000,
			lowest: 148.1,
			highest: 148.19,
			volumePrice: 148.1463,
			transactionCount: 665,
			voluemPrice: 148.1463
		},
		{
			open: 148.14,
			close: 148.11,
			volume: 67314,
			time: 1654622400000,
			lowest: 148.085,
			highest: 148.15,
			volumePrice: 148.1139,
			transactionCount: 712,
			voluemPrice: 148.1139
		},
		{
			open: 148.127,
			close: 148.215,
			volume: 129828,
			time: 1654622460000,
			lowest: 148.115,
			highest: 148.23,
			volumePrice: 148.1822,
			transactionCount: 918,
			voluemPrice: 148.1822
		},
		{
			open: 148.215,
			close: 148.239,
			volume: 109083,
			time: 1654622520000,
			lowest: 148.17,
			highest: 148.25,
			volumePrice: 148.2132,
			transactionCount: 822,
			voluemPrice: 148.2132
		},
		{
			open: 148.239,
			close: 148.18,
			volume: 145544,
			time: 1654622580000,
			lowest: 148.09,
			highest: 148.25,
			volumePrice: 148.183,
			transactionCount: 1151,
			voluemPrice: 148.183
		},
		{
			open: 148.1789,
			close: 148.1501,
			volume: 49315,
			time: 1654622640000,
			lowest: 148.14,
			highest: 148.185,
			volumePrice: 148.1596,
			transactionCount: 565,
			voluemPrice: 148.1596
		},
		{
			open: 148.16,
			close: 148.19,
			volume: 71558,
			time: 1654622700000,
			lowest: 148.1,
			highest: 148.2,
			volumePrice: 148.1497,
			transactionCount: 813,
			voluemPrice: 148.1497
		},
		{
			open: 148.19,
			close: 148.18,
			volume: 66356,
			time: 1654622760000,
			lowest: 148.15,
			highest: 148.21,
			volumePrice: 148.1762,
			transactionCount: 659,
			voluemPrice: 148.1762
		},
		{
			open: 148.1889,
			close: 148.1801,
			volume: 47092,
			time: 1654622820000,
			lowest: 148.17,
			highest: 148.24,
			volumePrice: 148.2045,
			transactionCount: 584,
			voluemPrice: 148.2045
		},
		{
			open: 148.1884,
			close: 148.24,
			volume: 70136,
			time: 1654622880000,
			lowest: 148.14,
			highest: 148.261,
			volumePrice: 148.2025,
			transactionCount: 685,
			voluemPrice: 148.2025
		},
		{
			open: 148.25,
			close: 148.17,
			volume: 85244,
			time: 1654622940000,
			lowest: 148.17,
			highest: 148.2523,
			volumePrice: 148.2079,
			transactionCount: 650,
			voluemPrice: 148.2079
		},
		{
			open: 148.18,
			close: 148.19,
			volume: 82845,
			time: 1654623000000,
			lowest: 148.14,
			highest: 148.25,
			volumePrice: 148.1889,
			transactionCount: 844,
			voluemPrice: 148.1889
		},
		{
			open: 148.195,
			close: 148.23,
			volume: 72355,
			time: 1654623060000,
			lowest: 148.145,
			highest: 148.255,
			volumePrice: 148.2028,
			transactionCount: 693,
			voluemPrice: 148.2028
		},
		{
			open: 148.225,
			close: 148.2999,
			volume: 186481,
			time: 1654623120000,
			lowest: 148.205,
			highest: 148.33,
			volumePrice: 148.2719,
			transactionCount: 1186,
			voluemPrice: 148.2719
		},
		{
			open: 148.2985,
			close: 148.185,
			volume: 95515,
			time: 1654623180000,
			lowest: 148.181,
			highest: 148.305,
			volumePrice: 148.2536,
			transactionCount: 677,
			voluemPrice: 148.2536
		},
		{
			open: 148.185,
			close: 148.221,
			volume: 144988,
			time: 1654623240000,
			lowest: 148.07,
			highest: 148.235,
			volumePrice: 148.1326,
			transactionCount: 1141,
			voluemPrice: 148.1326
		},
		{
			open: 148.22,
			close: 148.29,
			volume: 113477,
			time: 1654623300000,
			lowest: 148.2,
			highest: 148.29,
			volumePrice: 148.2552,
			transactionCount: 729,
			voluemPrice: 148.2552
		},
		{
			open: 148.29,
			close: 148.2301,
			volume: 60800,
			time: 1654623360000,
			lowest: 148.18,
			highest: 148.295,
			volumePrice: 148.2448,
			transactionCount: 687,
			voluemPrice: 148.2448
		},
		{
			open: 148.24,
			close: 148.205,
			volume: 140689,
			time: 1654623420000,
			lowest: 148.12,
			highest: 148.24,
			volumePrice: 148.1761,
			transactionCount: 993,
			voluemPrice: 148.1761
		},
		{
			open: 148.2,
			close: 148.1,
			volume: 83709,
			time: 1654623480000,
			lowest: 148.07,
			highest: 148.21,
			volumePrice: 148.1202,
			transactionCount: 754,
			voluemPrice: 148.1202
		},
		{
			open: 148.1092,
			close: 148.205,
			volume: 82892,
			time: 1654623540000,
			lowest: 148.1,
			highest: 148.22,
			volumePrice: 148.1781,
			transactionCount: 679,
			voluemPrice: 148.1781
		},
		{
			open: 148.21,
			close: 148.1542,
			volume: 64790,
			time: 1654623600000,
			lowest: 148.14,
			highest: 148.22,
			volumePrice: 148.1793,
			transactionCount: 650,
			voluemPrice: 148.1793
		},
		{
			open: 148.15,
			close: 148.145,
			volume: 159705,
			time: 1654623660000,
			lowest: 148.11,
			highest: 148.16,
			volumePrice: 148.1507,
			transactionCount: 614,
			voluemPrice: 148.1507
		},
		{
			open: 148.15,
			close: 148.1179,
			volume: 63279,
			time: 1654623720000,
			lowest: 148.1012,
			highest: 148.185,
			volumePrice: 148.1366,
			transactionCount: 565,
			voluemPrice: 148.1366
		},
		{
			open: 148.11,
			close: 148.1548,
			volume: 103499,
			time: 1654623780000,
			lowest: 148.06,
			highest: 148.17,
			volumePrice: 148.114,
			transactionCount: 836,
			voluemPrice: 148.114
		},
		{
			open: 148.15,
			close: 148.1701,
			volume: 155668,
			time: 1654623840000,
			lowest: 148.115,
			highest: 148.19,
			volumePrice: 148.1544,
			transactionCount: 559,
			voluemPrice: 148.1544
		},
		{
			open: 148.1701,
			close: 148.2,
			volume: 71478,
			time: 1654623900000,
			lowest: 148.16,
			highest: 148.22,
			volumePrice: 148.1942,
			transactionCount: 616,
			voluemPrice: 148.1942
		},
		{
			open: 148.22,
			close: 148.26,
			volume: 110092,
			time: 1654623960000,
			lowest: 148.19,
			highest: 148.27,
			volumePrice: 148.2363,
			transactionCount: 765,
			voluemPrice: 148.2363
		},
		{
			open: 148.2586,
			close: 148.19,
			volume: 77785,
			time: 1654624020000,
			lowest: 148.159,
			highest: 148.29,
			volumePrice: 148.2238,
			transactionCount: 657,
			voluemPrice: 148.2238
		},
		{
			open: 148.18,
			close: 148.202,
			volume: 134007,
			time: 1654624080000,
			lowest: 148.075,
			highest: 148.23,
			volumePrice: 148.1583,
			transactionCount: 1083,
			voluemPrice: 148.1583
		},
		{
			open: 148.2,
			close: 148.17,
			volume: 72348,
			time: 1654624140000,
			lowest: 148.09,
			highest: 148.22,
			volumePrice: 148.1568,
			transactionCount: 720,
			voluemPrice: 148.1568
		},
		{
			open: 148.17,
			close: 148.2388,
			volume: 62497,
			time: 1654624200000,
			lowest: 148.13,
			highest: 148.248,
			volumePrice: 148.1839,
			transactionCount: 626,
			voluemPrice: 148.1839
		},
		{
			open: 148.2305,
			close: 148.24,
			volume: 127126,
			time: 1654624260000,
			lowest: 148.21,
			highest: 148.29,
			volumePrice: 148.2618,
			transactionCount: 954,
			voluemPrice: 148.2618
		},
		{
			open: 148.2581,
			close: 148.32,
			volume: 90941,
			time: 1654624320000,
			lowest: 148.19,
			highest: 148.32,
			volumePrice: 148.2653,
			transactionCount: 802,
			voluemPrice: 148.2653
		},
		{
			open: 148.32,
			close: 148.27,
			volume: 158248,
			time: 1654624380000,
			lowest: 148.25,
			highest: 148.36,
			volumePrice: 148.3119,
			transactionCount: 892,
			voluemPrice: 148.3119
		},
		{
			open: 148.26,
			close: 148.1785,
			volume: 94809,
			time: 1654624440000,
			lowest: 148.1401,
			highest: 148.2661,
			volumePrice: 148.2023,
			transactionCount: 724,
			voluemPrice: 148.2023
		},
		{
			open: 148.17,
			close: 148.13,
			volume: 90889,
			time: 1654624500000,
			lowest: 148.08,
			highest: 148.17,
			volumePrice: 148.132,
			transactionCount: 871,
			voluemPrice: 148.132
		},
		{
			open: 148.135,
			close: 148.1584,
			volume: 106945,
			time: 1654624560000,
			lowest: 148.042,
			highest: 148.16,
			volumePrice: 148.0929,
			transactionCount: 840,
			voluemPrice: 148.0929
		},
		{
			open: 148.1585,
			close: 148.205,
			volume: 91117,
			time: 1654624620000,
			lowest: 148.13,
			highest: 148.215,
			volumePrice: 148.1785,
			transactionCount: 710,
			voluemPrice: 148.1785
		},
		{
			open: 148.22,
			close: 148.18,
			volume: 57976,
			time: 1654624680000,
			lowest: 148.135,
			highest: 148.2284,
			volumePrice: 148.1898,
			transactionCount: 568,
			voluemPrice: 148.1898
		},
		{
			open: 148.185,
			close: 148.1388,
			volume: 47644,
			time: 1654624740000,
			lowest: 148.1173,
			highest: 148.23,
			volumePrice: 148.1833,
			transactionCount: 495,
			voluemPrice: 148.1833
		},
		{
			open: 148.14,
			close: 148.065,
			volume: 70873,
			time: 1654624800000,
			lowest: 148.03,
			highest: 148.1502,
			volumePrice: 148.0731,
			transactionCount: 760,
			voluemPrice: 148.0731
		},
		{
			open: 148.07,
			close: 148.23,
			volume: 101929,
			time: 1654624860000,
			lowest: 148.0648,
			highest: 148.23,
			volumePrice: 148.1458,
			transactionCount: 828,
			voluemPrice: 148.1458
		},
		{
			open: 148.24,
			close: 148.2251,
			volume: 95759,
			time: 1654624920000,
			lowest: 148.205,
			highest: 148.2807,
			volumePrice: 148.2546,
			transactionCount: 740,
			voluemPrice: 148.2546
		},
		{
			open: 148.2299,
			close: 148.2171,
			volume: 51194,
			time: 1654624980000,
			lowest: 148.152,
			highest: 148.23,
			volumePrice: 148.1842,
			transactionCount: 513,
			voluemPrice: 148.1842
		},
		{
			open: 148.21,
			close: 148.31,
			volume: 137790,
			time: 1654625040000,
			lowest: 148.19,
			highest: 148.33,
			volumePrice: 148.2797,
			transactionCount: 860,
			voluemPrice: 148.2797
		},
		{
			open: 148.315,
			close: 148.42,
			volume: 157721,
			time: 1654625100000,
			lowest: 148.27,
			highest: 148.43,
			volumePrice: 148.3589,
			transactionCount: 1371,
			voluemPrice: 148.3589
		},
		{
			open: 148.41,
			close: 148.53,
			volume: 252461,
			time: 1654625160000,
			lowest: 148.41,
			highest: 148.53,
			volumePrice: 148.4878,
			transactionCount: 1889,
			voluemPrice: 148.4878
		},
		{
			open: 148.525,
			close: 148.4799,
			volume: 119431,
			time: 1654625220000,
			lowest: 148.405,
			highest: 148.525,
			volumePrice: 148.4632,
			transactionCount: 778,
			voluemPrice: 148.4632
		},
		{
			open: 148.4767,
			close: 148.5899,
			volume: 100978,
			time: 1654625280000,
			lowest: 148.475,
			highest: 148.59,
			volumePrice: 148.5421,
			transactionCount: 807,
			voluemPrice: 148.5421
		},
		{
			open: 148.5801,
			close: 148.735,
			volume: 324307,
			time: 1654625340000,
			lowest: 148.5801,
			highest: 148.79,
			volumePrice: 148.6823,
			transactionCount: 2562,
			voluemPrice: 148.6823
		},
		{
			open: 148.7387,
			close: 148.75,
			volume: 206116,
			time: 1654625400000,
			lowest: 148.66,
			highest: 148.81,
			volumePrice: 148.7527,
			transactionCount: 1419,
			voluemPrice: 148.7527
		},
		{
			open: 148.75,
			close: 148.69,
			volume: 86087,
			time: 1654625460000,
			lowest: 148.68,
			highest: 148.7642,
			volumePrice: 148.7313,
			transactionCount: 732,
			voluemPrice: 148.7313
		},
		{
			open: 148.682,
			close: 148.77,
			volume: 83346,
			time: 1654625520000,
			lowest: 148.682,
			highest: 148.81,
			volumePrice: 148.758,
			transactionCount: 683,
			voluemPrice: 148.758
		},
		{
			open: 148.7777,
			close: 148.88,
			volume: 157885,
			time: 1654625580000,
			lowest: 148.745,
			highest: 148.88,
			volumePrice: 148.8093,
			transactionCount: 988,
			voluemPrice: 148.8093
		},
		{
			open: 148.875,
			close: 148.86,
			volume: 146316,
			time: 1654625640000,
			lowest: 148.84,
			highest: 148.93,
			volumePrice: 148.8813,
			transactionCount: 1160,
			voluemPrice: 148.8813
		},
		{
			open: 148.87,
			close: 148.9111,
			volume: 132375,
			time: 1654625700000,
			lowest: 148.85,
			highest: 148.95,
			volumePrice: 148.9019,
			transactionCount: 1030,
			voluemPrice: 148.9019
		},
		{
			open: 148.9101,
			close: 148.83,
			volume: 115137,
			time: 1654625760000,
			lowest: 148.81,
			highest: 148.93,
			volumePrice: 148.8494,
			transactionCount: 881,
			voluemPrice: 148.8494
		},
		{
			open: 148.84,
			close: 148.775,
			volume: 92139,
			time: 1654625820000,
			lowest: 148.74,
			highest: 148.85,
			volumePrice: 148.7963,
			transactionCount: 890,
			voluemPrice: 148.7963
		},
		{
			open: 148.7701,
			close: 148.775,
			volume: 95102,
			time: 1654625880000,
			lowest: 148.73,
			highest: 148.815,
			volumePrice: 148.7745,
			transactionCount: 889,
			voluemPrice: 148.7745
		},
		{
			open: 148.7778,
			close: 148.7,
			volume: 119926,
			time: 1654625940000,
			lowest: 148.65,
			highest: 148.7778,
			volumePrice: 148.6929,
			transactionCount: 924,
			voluemPrice: 148.6929
		},
		{
			open: 148.68,
			close: 148.69,
			volume: 133419,
			time: 1654626000000,
			lowest: 148.67,
			highest: 148.76,
			volumePrice: 148.7201,
			transactionCount: 966,
			voluemPrice: 148.7201
		},
		{
			open: 148.695,
			close: 148.7643,
			volume: 67333,
			time: 1654626060000,
			lowest: 148.69,
			highest: 148.7786,
			volumePrice: 148.7381,
			transactionCount: 589,
			voluemPrice: 148.7381
		},
		{
			open: 148.77,
			close: 148.78,
			volume: 100090,
			time: 1654626120000,
			lowest: 148.73,
			highest: 148.8,
			volumePrice: 148.768,
			transactionCount: 700,
			voluemPrice: 148.768
		},
		{
			open: 148.775,
			close: 148.8106,
			volume: 119717,
			time: 1654626180000,
			lowest: 148.76,
			highest: 148.84,
			volumePrice: 148.7918,
			transactionCount: 906,
			voluemPrice: 148.7918
		},
		{
			open: 148.8108,
			close: 148.78,
			volume: 113751,
			time: 1654626240000,
			lowest: 148.7,
			highest: 148.8108,
			volumePrice: 148.7513,
			transactionCount: 967,
			voluemPrice: 148.7513
		},
		{
			open: 148.79,
			close: 148.74,
			volume: 101994,
			time: 1654626300000,
			lowest: 148.7,
			highest: 148.82,
			volumePrice: 148.7536,
			transactionCount: 907,
			voluemPrice: 148.7536
		},
		{
			open: 148.75,
			close: 148.7557,
			volume: 55502,
			time: 1654626360000,
			lowest: 148.695,
			highest: 148.78,
			volumePrice: 148.737,
			transactionCount: 584,
			voluemPrice: 148.737
		},
		{
			open: 148.755,
			close: 148.775,
			volume: 58873,
			time: 1654626420000,
			lowest: 148.73,
			highest: 148.82,
			volumePrice: 148.7811,
			transactionCount: 582,
			voluemPrice: 148.7811
		},
		{
			open: 148.775,
			close: 148.87,
			volume: 87343,
			time: 1654626480000,
			lowest: 148.775,
			highest: 148.87,
			volumePrice: 148.825,
			transactionCount: 794,
			voluemPrice: 148.825
		},
		{
			open: 148.86,
			close: 148.755,
			volume: 102920,
			time: 1654626540000,
			lowest: 148.75,
			highest: 148.865,
			volumePrice: 148.7851,
			transactionCount: 788,
			voluemPrice: 148.7851
		},
		{
			open: 148.7506,
			close: 148.7059,
			volume: 69929,
			time: 1654626600000,
			lowest: 148.6941,
			highest: 148.76,
			volumePrice: 148.7194,
			transactionCount: 669,
			voluemPrice: 148.7194
		},
		{
			open: 148.7088,
			close: 148.81,
			volume: 106961,
			time: 1654626660000,
			lowest: 148.6901,
			highest: 148.82,
			volumePrice: 148.7663,
			transactionCount: 696,
			voluemPrice: 148.7663
		},
		{
			open: 148.8101,
			close: 148.8039,
			volume: 71320,
			time: 1654626720000,
			lowest: 148.78,
			highest: 148.83,
			volumePrice: 148.8119,
			transactionCount: 496,
			voluemPrice: 148.8119
		},
		{
			open: 148.8,
			close: 148.89,
			volume: 90405,
			time: 1654626780000,
			lowest: 148.7785,
			highest: 148.9,
			volumePrice: 148.8341,
			transactionCount: 829,
			voluemPrice: 148.8341
		},
		{
			open: 148.8847,
			close: 148.88,
			volume: 162037,
			time: 1654626840000,
			lowest: 148.8,
			highest: 148.92,
			volumePrice: 148.8752,
			transactionCount: 1052,
			voluemPrice: 148.8752
		},
		{
			open: 148.8882,
			close: 148.9314,
			volume: 114761,
			time: 1654626900000,
			lowest: 148.86,
			highest: 148.94,
			volumePrice: 148.9023,
			transactionCount: 941,
			voluemPrice: 148.9023
		},
		{
			open: 148.94,
			close: 148.915,
			volume: 96286,
			time: 1654626960000,
			lowest: 148.9,
			highest: 148.97,
			volumePrice: 148.9377,
			transactionCount: 823,
			voluemPrice: 148.9377
		},
		{
			open: 148.92,
			close: 148.8299,
			volume: 78127,
			time: 1654627020000,
			lowest: 148.8299,
			highest: 148.925,
			volumePrice: 148.8784,
			transactionCount: 754,
			voluemPrice: 148.8784
		},
		{
			open: 148.8201,
			close: 148.865,
			volume: 85477,
			time: 1654627080000,
			lowest: 148.76,
			highest: 148.9,
			volumePrice: 148.8505,
			transactionCount: 748,
			voluemPrice: 148.8505
		},
		{
			open: 148.86,
			close: 148.795,
			volume: 92698,
			time: 1654627140000,
			lowest: 148.77,
			highest: 148.9,
			volumePrice: 148.8381,
			transactionCount: 748,
			voluemPrice: 148.8381
		},
		{
			open: 148.7987,
			close: 148.8083,
			volume: 67899,
			time: 1654627200000,
			lowest: 148.7987,
			highest: 148.84,
			volumePrice: 148.8126,
			transactionCount: 706,
			voluemPrice: 148.8126
		},
		{
			open: 148.8,
			close: 148.6453,
			volume: 132532,
			time: 1654627260000,
			lowest: 148.62,
			highest: 148.805,
			volumePrice: 148.7058,
			transactionCount: 1113,
			voluemPrice: 148.7058
		},
		{
			open: 148.6499,
			close: 148.5199,
			volume: 168584,
			time: 1654627320000,
			lowest: 148.515,
			highest: 148.7,
			volumePrice: 148.6202,
			transactionCount: 1285,
			voluemPrice: 148.6202
		},
		{
			open: 148.51,
			close: 148.35,
			volume: 239567,
			time: 1654627380000,
			lowest: 148.31,
			highest: 148.51,
			volumePrice: 148.4108,
			transactionCount: 2026,
			voluemPrice: 148.4108
		},
		{
			open: 148.35,
			close: 148.265,
			volume: 185063,
			time: 1654627440000,
			lowest: 148.13,
			highest: 148.41,
			volumePrice: 148.2758,
			transactionCount: 1566,
			voluemPrice: 148.2758
		},
		{
			open: 148.26,
			close: 148.35,
			volume: 137615,
			time: 1654627500000,
			lowest: 148.23,
			highest: 148.4,
			volumePrice: 148.348,
			transactionCount: 1114,
			voluemPrice: 148.348
		},
		{
			open: 148.36,
			close: 148.34,
			volume: 94063,
			time: 1654627560000,
			lowest: 148.34,
			highest: 148.43,
			volumePrice: 148.3913,
			transactionCount: 842,
			voluemPrice: 148.3913
		},
		{
			open: 148.33,
			close: 148.16,
			volume: 193114,
			time: 1654627620000,
			lowest: 148.08,
			highest: 148.36,
			volumePrice: 148.2285,
			transactionCount: 1665,
			voluemPrice: 148.2285
		},
		{
			open: 148.14,
			close: 148.35,
			volume: 104544,
			time: 1654627680000,
			lowest: 148.13,
			highest: 148.35,
			volumePrice: 148.267,
			transactionCount: 913,
			voluemPrice: 148.267
		},
		{
			open: 148.3418,
			close: 148.305,
			volume: 137637,
			time: 1654627740000,
			lowest: 148.27,
			highest: 148.4,
			volumePrice: 148.3363,
			transactionCount: 1169,
			voluemPrice: 148.3363
		},
		{
			open: 148.3,
			close: 148.4,
			volume: 122652,
			time: 1654627800000,
			lowest: 148.25,
			highest: 148.41,
			volumePrice: 148.3458,
			transactionCount: 822,
			voluemPrice: 148.3458
		},
		{
			open: 148.41,
			close: 148.36,
			volume: 100964,
			time: 1654627860000,
			lowest: 148.33,
			highest: 148.45,
			volumePrice: 148.3965,
			transactionCount: 880,
			voluemPrice: 148.3965
		},
		{
			open: 148.37,
			close: 148.3647,
			volume: 56784,
			time: 1654627920000,
			lowest: 148.29,
			highest: 148.39,
			volumePrice: 148.3471,
			transactionCount: 572,
			voluemPrice: 148.3471
		},
		{
			open: 148.36,
			close: 148.4794,
			volume: 100189,
			time: 1654627980000,
			lowest: 148.34,
			highest: 148.5,
			volumePrice: 148.4406,
			transactionCount: 911,
			voluemPrice: 148.4406
		},
		{
			open: 148.475,
			close: 148.405,
			volume: 46774,
			time: 1654628040000,
			lowest: 148.39,
			highest: 148.48,
			volumePrice: 148.43,
			transactionCount: 524,
			voluemPrice: 148.43
		},
		{
			open: 148.4,
			close: 148.27,
			volume: 94550,
			time: 1654628100000,
			lowest: 148.26,
			highest: 148.42,
			volumePrice: 148.3564,
			transactionCount: 802,
			voluemPrice: 148.3564
		},
		{
			open: 148.2757,
			close: 148.36,
			volume: 56594,
			time: 1654628160000,
			lowest: 148.2757,
			highest: 148.36,
			volumePrice: 148.3318,
			transactionCount: 530,
			voluemPrice: 148.3318
		},
		{
			open: 148.36,
			close: 148.42,
			volume: 95030,
			time: 1654628220000,
			lowest: 148.28,
			highest: 148.44,
			volumePrice: 148.3691,
			transactionCount: 872,
			voluemPrice: 148.3691
		},
		{
			open: 148.43,
			close: 148.411,
			volume: 82390,
			time: 1654628280000,
			lowest: 148.38,
			highest: 148.47,
			volumePrice: 148.4233,
			transactionCount: 672,
			voluemPrice: 148.4233
		},
		{
			open: 148.4,
			close: 148.365,
			volume: 63254,
			time: 1654628340000,
			lowest: 148.35,
			highest: 148.43,
			volumePrice: 148.4017,
			transactionCount: 660,
			voluemPrice: 148.4017
		},
		{
			open: 148.37,
			close: 148.47,
			volume: 99091,
			time: 1654628400000,
			lowest: 148.32,
			highest: 148.485,
			volumePrice: 148.4005,
			transactionCount: 922,
			voluemPrice: 148.4005
		},
		{
			open: 148.49,
			close: 148.55,
			volume: 123609,
			time: 1654628460000,
			lowest: 148.48,
			highest: 148.57,
			volumePrice: 148.519,
			transactionCount: 840,
			voluemPrice: 148.519
		},
		{
			open: 148.55,
			close: 148.56,
			volume: 119534,
			time: 1654628520000,
			lowest: 148.53,
			highest: 148.615,
			volumePrice: 148.5733,
			transactionCount: 877,
			voluemPrice: 148.5733
		},
		{
			open: 148.57,
			close: 148.54,
			volume: 77502,
			time: 1654628580000,
			lowest: 148.52,
			highest: 148.6,
			volumePrice: 148.5651,
			transactionCount: 604,
			voluemPrice: 148.5651
		},
		{
			open: 148.5388,
			close: 148.55,
			volume: 83428,
			time: 1654628640000,
			lowest: 148.53,
			highest: 148.6,
			volumePrice: 148.5746,
			transactionCount: 660,
			voluemPrice: 148.5746
		},
		{
			open: 148.55,
			close: 148.47,
			volume: 99474,
			time: 1654628700000,
			lowest: 148.45,
			highest: 148.56,
			volumePrice: 148.502,
			transactionCount: 826,
			voluemPrice: 148.502
		},
		{
			open: 148.49,
			close: 148.565,
			volume: 82101,
			time: 1654628760000,
			lowest: 148.49,
			highest: 148.595,
			volumePrice: 148.5442,
			transactionCount: 682,
			voluemPrice: 148.5442
		},
		{
			open: 148.57,
			close: 148.66,
			volume: 109784,
			time: 1654628820000,
			lowest: 148.57,
			highest: 148.7,
			volumePrice: 148.6439,
			transactionCount: 843,
			voluemPrice: 148.6439
		},
		{
			open: 148.66,
			close: 148.74,
			volume: 112366,
			time: 1654628880000,
			lowest: 148.66,
			highest: 148.76,
			volumePrice: 148.715,
			transactionCount: 764,
			voluemPrice: 148.715
		},
		{
			open: 148.75,
			close: 148.645,
			volume: 90409,
			time: 1654628940000,
			lowest: 148.62,
			highest: 148.755,
			volumePrice: 148.6891,
			transactionCount: 833,
			voluemPrice: 148.6891
		},
		{
			open: 148.645,
			close: 148.6946,
			volume: 126282,
			time: 1654629000000,
			lowest: 148.645,
			highest: 148.75,
			volumePrice: 148.7075,
			transactionCount: 853,
			voluemPrice: 148.7075
		},
		{
			open: 148.69,
			close: 148.4999,
			volume: 133698,
			time: 1654629060000,
			lowest: 148.49,
			highest: 148.6999,
			volumePrice: 148.5959,
			transactionCount: 1147,
			voluemPrice: 148.5959
		},
		{
			open: 148.5,
			close: 148.5699,
			volume: 118086,
			time: 1654629120000,
			lowest: 148.42,
			highest: 148.5786,
			volumePrice: 148.5027,
			transactionCount: 921,
			voluemPrice: 148.5027
		},
		{
			open: 148.5657,
			close: 148.5,
			volume: 76931,
			time: 1654629180000,
			lowest: 148.46,
			highest: 148.59,
			volumePrice: 148.5301,
			transactionCount: 786,
			voluemPrice: 148.5301
		},
		{
			open: 148.5,
			close: 148.478,
			volume: 99222,
			time: 1654629240000,
			lowest: 148.46,
			highest: 148.5637,
			volumePrice: 148.5102,
			transactionCount: 882,
			voluemPrice: 148.5102
		},
		{
			open: 148.49,
			close: 148.555,
			volume: 74351,
			time: 1654629300000,
			lowest: 148.485,
			highest: 148.59,
			volumePrice: 148.5405,
			transactionCount: 740,
			voluemPrice: 148.5405
		},
		{
			open: 148.58,
			close: 148.6375,
			volume: 64878,
			time: 1654629360000,
			lowest: 148.52,
			highest: 148.65,
			volumePrice: 148.602,
			transactionCount: 689,
			voluemPrice: 148.602
		},
		{
			open: 148.64,
			close: 148.59,
			volume: 80168,
			time: 1654629420000,
			lowest: 148.585,
			highest: 148.66,
			volumePrice: 148.6174,
			transactionCount: 627,
			voluemPrice: 148.6174
		},
		{
			open: 148.59,
			close: 148.6202,
			volume: 86880,
			time: 1654629480000,
			lowest: 148.59,
			highest: 148.67,
			volumePrice: 148.6416,
			transactionCount: 719,
			voluemPrice: 148.6416
		},
		{
			open: 148.625,
			close: 148.66,
			volume: 111898,
			time: 1654629540000,
			lowest: 148.62,
			highest: 148.695,
			volumePrice: 148.6662,
			transactionCount: 969,
			voluemPrice: 148.6662
		},
		{
			open: 148.65,
			close: 148.685,
			volume: 61624,
			time: 1654629600000,
			lowest: 148.635,
			highest: 148.689,
			volumePrice: 148.6613,
			transactionCount: 753,
			voluemPrice: 148.6613
		},
		{
			open: 148.69,
			close: 148.725,
			volume: 112210,
			time: 1654629660000,
			lowest: 148.6804,
			highest: 148.76,
			volumePrice: 148.7245,
			transactionCount: 858,
			voluemPrice: 148.7245
		},
		{
			open: 148.73,
			close: 148.82,
			volume: 155147,
			time: 1654629720000,
			lowest: 148.715,
			highest: 148.8299,
			volumePrice: 148.7866,
			transactionCount: 902,
			voluemPrice: 148.7866
		},
		{
			open: 148.82,
			close: 148.7676,
			volume: 126883,
			time: 1654629780000,
			lowest: 147.8,
			highest: 148.82,
			volumePrice: 148.7695,
			transactionCount: 1020,
			voluemPrice: 148.7695
		},
		{
			open: 148.77,
			close: 148.72,
			volume: 143126,
			time: 1654629840000,
			lowest: 148.06,
			highest: 148.78,
			volumePrice: 148.694,
			transactionCount: 972,
			voluemPrice: 148.694
		},
		{
			open: 148.72,
			close: 148.71,
			volume: 88913,
			time: 1654629900000,
			lowest: 148.64,
			highest: 148.765,
			volumePrice: 148.6975,
			transactionCount: 894,
			voluemPrice: 148.6975
		},
		{
			open: 148.72,
			close: 148.75,
			volume: 74037,
			time: 1654629960000,
			lowest: 148.67,
			highest: 148.7599,
			volumePrice: 148.7147,
			transactionCount: 715,
			voluemPrice: 148.7147
		},
		{
			open: 148.7582,
			close: 148.67,
			volume: 75797,
			time: 1654630020000,
			lowest: 148.67,
			highest: 148.79,
			volumePrice: 148.7216,
			transactionCount: 761,
			voluemPrice: 148.7216
		},
		{
			open: 148.6766,
			close: 148.68,
			volume: 99245,
			time: 1654630080000,
			lowest: 148.57,
			highest: 148.69,
			volumePrice: 148.6446,
			transactionCount: 883,
			voluemPrice: 148.6446
		},
		{
			open: 148.679,
			close: 148.645,
			volume: 81017,
			time: 1654630140000,
			lowest: 148.625,
			highest: 148.71,
			volumePrice: 148.6721,
			transactionCount: 813,
			voluemPrice: 148.6721
		},
		{
			open: 148.64,
			close: 148.6915,
			volume: 135698,
			time: 1654630200000,
			lowest: 148.53,
			highest: 148.7,
			volumePrice: 148.6205,
			transactionCount: 1110,
			voluemPrice: 148.6205
		},
		{
			open: 148.7,
			close: 148.7035,
			volume: 120154,
			time: 1654630260000,
			lowest: 148.685,
			highest: 148.79,
			volumePrice: 148.741,
			transactionCount: 1064,
			voluemPrice: 148.741
		},
		{
			open: 148.71,
			close: 148.77,
			volume: 105545,
			time: 1654630320000,
			lowest: 148.68,
			highest: 148.81,
			volumePrice: 148.7546,
			transactionCount: 1303,
			voluemPrice: 148.7546
		},
		{
			open: 148.77,
			close: 148.715,
			volume: 99147,
			time: 1654630380000,
			lowest: 148.69,
			highest: 148.79,
			volumePrice: 148.7519,
			transactionCount: 1550,
			voluemPrice: 148.7519
		},
		{
			open: 148.7244,
			close: 148.81,
			volume: 204576,
			time: 1654630440000,
			lowest: 148.67,
			highest: 148.825,
			volumePrice: 148.7587,
			transactionCount: 1551,
			voluemPrice: 148.7587
		},
		{
			open: 148.805,
			close: 148.81,
			volume: 148799,
			time: 1654630500000,
			lowest: 148.75,
			highest: 148.83,
			volumePrice: 148.7964,
			transactionCount: 1233,
			voluemPrice: 148.7964
		},
		{
			open: 148.82,
			close: 148.785,
			volume: 146972,
			time: 1654630560000,
			lowest: 148.765,
			highest: 148.87,
			volumePrice: 148.8128,
			transactionCount: 1318,
			voluemPrice: 148.8128
		},
		{
			open: 148.787,
			close: 148.91,
			volume: 239689,
			time: 1654630620000,
			lowest: 148.78,
			highest: 148.95,
			volumePrice: 148.8775,
			transactionCount: 1919,
			voluemPrice: 148.8775
		},
		{
			open: 148.91,
			close: 148.8379,
			volume: 122782,
			time: 1654630680000,
			lowest: 148.83,
			highest: 148.92,
			volumePrice: 148.8738,
			transactionCount: 1146,
			voluemPrice: 148.8738
		},
		{
			open: 148.84,
			close: 148.9,
			volume: 114665,
			time: 1654630740000,
			lowest: 148.83,
			highest: 148.9,
			volumePrice: 148.873,
			transactionCount: 1152,
			voluemPrice: 148.873
		},
		{
			open: 148.905,
			close: 148.93,
			volume: 157769,
			time: 1654630800000,
			lowest: 148.89,
			highest: 148.95,
			volumePrice: 148.9236,
			transactionCount: 1465,
			voluemPrice: 148.9236
		},
		{
			open: 148.925,
			close: 148.88,
			volume: 171021,
			time: 1654630860000,
			lowest: 148.86,
			highest: 148.96,
			volumePrice: 148.9129,
			transactionCount: 1521,
			voluemPrice: 148.9129
		},
		{
			open: 148.8785,
			close: 148.83,
			volume: 206873,
			time: 1654630920000,
			lowest: 148.78,
			highest: 148.93,
			volumePrice: 148.853,
			transactionCount: 1793,
			voluemPrice: 148.853
		},
		{
			open: 148.825,
			close: 148.7,
			volume: 142916,
			time: 1654630980000,
			lowest: 148.69,
			highest: 148.83,
			volumePrice: 148.7477,
			transactionCount: 1320,
			voluemPrice: 148.7477
		},
		{
			open: 148.705,
			close: 148.805,
			volume: 158934,
			time: 1654631040000,
			lowest: 148.7,
			highest: 148.84,
			volumePrice: 148.7792,
			transactionCount: 1543,
			voluemPrice: 148.7792
		},
		{
			open: 148.8,
			close: 148.8134,
			volume: 117977,
			time: 1654631100000,
			lowest: 148.76,
			highest: 148.83,
			volumePrice: 148.7989,
			transactionCount: 1389,
			voluemPrice: 148.7989
		},
		{
			open: 148.82,
			close: 148.825,
			volume: 162373,
			time: 1654631160000,
			lowest: 148.79,
			highest: 148.87,
			volumePrice: 148.8263,
			transactionCount: 1262,
			voluemPrice: 148.8263
		},
		{
			open: 148.825,
			close: 148.79,
			volume: 174997,
			time: 1654631220000,
			lowest: 148.72,
			highest: 148.825,
			volumePrice: 148.7743,
			transactionCount: 1730,
			voluemPrice: 148.7743
		},
		{
			open: 148.78,
			close: 148.78,
			volume: 140887,
			time: 1654631280000,
			lowest: 148.765,
			highest: 148.855,
			volumePrice: 148.8208,
			transactionCount: 1411,
			voluemPrice: 148.8208
		},
		{
			open: 148.79,
			close: 148.8173,
			volume: 169907,
			time: 1654631340000,
			lowest: 148.78,
			highest: 148.9,
			volumePrice: 148.8392,
			transactionCount: 1488,
			voluemPrice: 148.8392
		},
		{
			open: 148.8,
			close: 148.8,
			volume: 515940,
			time: 1654631400000,
			lowest: 148.71,
			highest: 149,
			volumePrice: 148.9024,
			transactionCount: 4664,
			voluemPrice: 148.9024
		},
		{
			open: 148.8,
			close: 148.9,
			volume: 190692,
			time: 1654631460000,
			lowest: 148.7761,
			highest: 148.9,
			volumePrice: 148.8281,
			transactionCount: 1956,
			voluemPrice: 148.8281
		},
		{
			open: 148.9,
			close: 148.86,
			volume: 240540,
			time: 1654631520000,
			lowest: 148.82,
			highest: 148.9,
			volumePrice: 148.8741,
			transactionCount: 2291,
			voluemPrice: 148.8741
		},
		{
			open: 148.85,
			close: 148.815,
			volume: 190054,
			time: 1654631580000,
			lowest: 148.77,
			highest: 148.88,
			volumePrice: 148.8263,
			transactionCount: 1859,
			voluemPrice: 148.8263
		},
		{
			open: 148.81,
			close: 148.815,
			volume: 389944,
			time: 1654631640000,
			lowest: 148.7939,
			highest: 148.94,
			volumePrice: 148.864,
			transactionCount: 3641,
			voluemPrice: 148.864
		},
		{
			open: 148.815,
			close: 148.785,
			volume: 339730,
			time: 1654631700000,
			lowest: 148.65,
			highest: 148.82,
			volumePrice: 148.7376,
			transactionCount: 3443,
			voluemPrice: 148.7376
		},
		{
			open: 148.785,
			close: 148.72,
			volume: 321919,
			time: 1654631760000,
			lowest: 148.7,
			highest: 148.83,
			volumePrice: 148.7629,
			transactionCount: 2502,
			voluemPrice: 148.7629
		},
		{
			open: 148.71,
			close: 148.75,
			volume: 302095,
			time: 1654631820000,
			lowest: 148.7,
			highest: 148.82,
			volumePrice: 148.7557,
			transactionCount: 2476,
			voluemPrice: 148.7557
		},
		{
			open: 148.755,
			close: 148.7597,
			volume: 435515,
			time: 1654631880000,
			lowest: 148.58,
			highest: 148.81,
			volumePrice: 148.7274,
			transactionCount: 3658,
			voluemPrice: 148.7274
		},
		{
			open: 148.76,
			close: 148.69,
			volume: 854173,
			time: 1654631940000,
			lowest: 148.605,
			highest: 148.77,
			volumePrice: 148.6517,
			transactionCount: 5223,
			voluemPrice: 148.6517
		},
		{
			open: 148.68,
			close: 148.71,
			volume: 783238,
			time: 1654632000000,
			lowest: 148.23,
			highest: 148.71,
			volumePrice: 148.6968,
			transactionCount: 357,
			voluemPrice: 148.6968
		},
		{
			open: 148.4201,
			close: 148.4,
			volume: 360713,
			time: 1654632060000,
			lowest: 148.36,
			highest: 148.71,
			volumePrice: 148.7007,
			transactionCount: 169,
			voluemPrice: 148.7007
		},
		{
			open: 148.4,
			close: 148.43,
			volume: 11463,
			time: 1654632120000,
			lowest: 148.4,
			highest: 148.48,
			volumePrice: 148.4161,
			transactionCount: 109,
			voluemPrice: 148.4161
		},
		{
			open: 148.43,
			close: 148.42,
			volume: 3274,
			time: 1654632180000,
			lowest: 148.42,
			highest: 148.43,
			volumePrice: 148.4255,
			transactionCount: 61,
			voluemPrice: 148.4255
		},
		{
			open: 148.4,
			close: 148.3465,
			volume: 13538,
			time: 1654632240000,
			lowest: 148.3,
			highest: 148.71,
			volumePrice: 148.3713,
			transactionCount: 809,
			voluemPrice: 148.3713
		},
		{
			open: 148.35,
			close: 148.38,
			volume: 42983,
			time: 1654632300000,
			lowest: 148.35,
			highest: 148.71,
			volumePrice: 148.6705,
			transactionCount: 68,
			voluemPrice: 148.6705
		},
		{
			open: 148.3799,
			close: 148.43,
			volume: 23775,
			time: 1654632360000,
			lowest: 148.37,
			highest: 148.43,
			volumePrice: 148.6448,
			transactionCount: 71,
			voluemPrice: 148.6448
		},
		{
			open: 148.43,
			close: 148.41,
			volume: 3050,
			time: 1654632420000,
			lowest: 148.41,
			highest: 148.43,
			volumePrice: 148.4149,
			transactionCount: 56,
			voluemPrice: 148.4149
		},
		{
			open: 148.43,
			close: 148.37,
			volume: 9581,
			time: 1654632480000,
			lowest: 148.34,
			highest: 148.43,
			volumePrice: 148.3822,
			transactionCount: 77,
			voluemPrice: 148.3822
		},
		{
			open: 148.37,
			close: 148.36,
			volume: 3252,
			time: 1654632540000,
			lowest: 148.36,
			highest: 148.4,
			volumePrice: 148.3784,
			transactionCount: 51,
			voluemPrice: 148.3784
		},
		{
			open: 148.71,
			close: 148.35,
			volume: 100330,
			time: 1654632600000,
			lowest: 148.35,
			highest: 148.71,
			volumePrice: 148.7052,
			transactionCount: 39,
			voluemPrice: 148.7052
		},
		{
			open: 148.37,
			close: 148.3798,
			volume: 14416,
			time: 1654632660000,
			lowest: 148.3567,
			highest: 148.4,
			volumePrice: 148.6082,
			transactionCount: 42,
			voluemPrice: 148.6082
		},
		{
			open: 148.37,
			close: 148.36,
			volume: 1320,
			time: 1654632720000,
			lowest: 148.36,
			highest: 148.38,
			volumePrice: 148.3702,
			transactionCount: 26,
			voluemPrice: 148.3702
		},
		{
			open: 148.36,
			close: 148.3107,
			volume: 10519,
			time: 1654632780000,
			lowest: 148.31,
			highest: 148.36,
			volumePrice: 148.3437,
			transactionCount: 78,
			voluemPrice: 148.3437
		},
		{
			open: 148.3311,
			close: 148.35,
			volume: 6179,
			time: 1654632840000,
			lowest: 148.32,
			highest: 148.38,
			volumePrice: 148.3371,
			transactionCount: 64,
			voluemPrice: 148.3371
		},
		{
			open: 148.35,
			close: 148.3271,
			volume: 1127,
			time: 1654632900000,
			lowest: 148.3271,
			highest: 148.35,
			volumePrice: 148.3408,
			transactionCount: 38,
			voluemPrice: 148.3408
		},
		{
			open: 148.32,
			close: 148.4001,
			volume: 67893,
			time: 1654632960000,
			lowest: 148.29,
			highest: 148.71,
			volumePrice: 148.5956,
			transactionCount: 130,
			voluemPrice: 148.5956
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 8531,
			time: 1654633020000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4005,
			transactionCount: 61,
			voluemPrice: 148.4005
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 3531,
			time: 1654633080000,
			lowest: 148.39,
			highest: 148.4,
			volumePrice: 148.3998,
			transactionCount: 32,
			voluemPrice: 148.3998
		},
		{
			open: 148.71,
			close: 148.71,
			volume: 5670,
			time: 1654633140000,
			lowest: 148.71,
			highest: 148.71,
			volumePrice: 148.7,
			transactionCount: 16,
			voluemPrice: 148.7
		},
		{
			open: 148.36,
			close: 148.35,
			volume: 1065,
			time: 1654633200000,
			lowest: 148.35,
			highest: 148.36,
			volumePrice: 148.3578,
			transactionCount: 33,
			voluemPrice: 148.3578
		},
		{
			open: 148.37,
			close: 148.39,
			volume: 695,
			time: 1654633260000,
			lowest: 148.37,
			highest: 148.39,
			volumePrice: 148.3722,
			transactionCount: 25,
			voluemPrice: 148.3722
		},
		{
			open: 148.35,
			close: 148.4,
			volume: 2195,
			time: 1654633320000,
			lowest: 148.35,
			highest: 148.4,
			volumePrice: 148.3914,
			transactionCount: 39,
			voluemPrice: 148.3914
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 204,
			time: 1654633380000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3643,
			transactionCount: 19,
			voluemPrice: 148.3643
		},
		{
			open: 148.35,
			close: 148.34,
			volume: 994,
			time: 1654633440000,
			lowest: 148.34,
			highest: 148.35,
			volumePrice: 148.3542,
			transactionCount: 40,
			voluemPrice: 148.3542
		},
		{
			open: 148.71,
			close: 148.35,
			volume: 58957,
			time: 1654633560000,
			lowest: 148.35,
			highest: 148.71,
			volumePrice: 148.7079,
			transactionCount: 27,
			voluemPrice: 148.7079
		},
		{
			open: 148.35,
			close: 148.3501,
			volume: 2065,
			time: 1654633620000,
			lowest: 148.35,
			highest: 148.3899,
			volumePrice: 148.3549,
			transactionCount: 28,
			voluemPrice: 148.3549
		},
		{
			open: 148.38,
			close: 148.42,
			volume: 60506,
			time: 1654633680000,
			lowest: 148.38,
			highest: 148.71,
			volumePrice: 148.6925,
			transactionCount: 51,
			voluemPrice: 148.6925
		},
		{
			open: 148.38,
			close: 148.35,
			volume: 1215,
			time: 1654633740000,
			lowest: 148.35,
			highest: 148.38,
			volumePrice: 148.3717,
			transactionCount: 29,
			voluemPrice: 148.3717
		},
		{
			open: 148.3969,
			close: 148.33,
			volume: 518284,
			time: 1654633800000,
			lowest: 148.33,
			highest: 148.71,
			volumePrice: 148.7055,
			transactionCount: 43,
			voluemPrice: 148.7055
		},
		{
			open: 148.33,
			close: 148.3,
			volume: 2012,
			time: 1654633860000,
			lowest: 148.3,
			highest: 148.33,
			volumePrice: 148.3269,
			transactionCount: 38,
			voluemPrice: 148.3269
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 361,
			time: 1654633920000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.3013,
			transactionCount: 24,
			voluemPrice: 148.3013
		},
		{
			open: 148.3338,
			close: 148.3338,
			volume: 252,
			time: 1654633980000,
			lowest: 148.3338,
			highest: 148.3338,
			volumePrice: 148.3245,
			transactionCount: 26,
			voluemPrice: 148.3245
		},
		{
			open: 148.29,
			close: 148.2927,
			volume: 1053,
			time: 1654634040000,
			lowest: 148.29,
			highest: 148.2927,
			volumePrice: 148.292,
			transactionCount: 15,
			voluemPrice: 148.292
		},
		{
			open: 148.35,
			close: 148.37,
			volume: 948,
			time: 1654634100000,
			lowest: 148.35,
			highest: 148.37,
			volumePrice: 148.3605,
			transactionCount: 33,
			voluemPrice: 148.3605
		},
		{
			open: 148.37,
			close: 148.37,
			volume: 315,
			time: 1654634160000,
			lowest: 148.37,
			highest: 148.37,
			volumePrice: 148.3692,
			transactionCount: 18,
			voluemPrice: 148.3692
		},
		{
			open: 148.39,
			close: 148.39,
			volume: 3499,
			time: 1654634220000,
			lowest: 148.34,
			highest: 148.39,
			volumePrice: 148.3623,
			transactionCount: 117,
			voluemPrice: 148.3623
		},
		{
			open: 148.35,
			close: 148.3943,
			volume: 1931,
			time: 1654634280000,
			lowest: 148.32,
			highest: 148.3943,
			volumePrice: 148.3369,
			transactionCount: 31,
			voluemPrice: 148.3369
		},
		{
			open: 148.3592,
			close: 148.37,
			volume: 367,
			time: 1654634340000,
			lowest: 148.3592,
			highest: 148.37,
			volumePrice: 148.3634,
			transactionCount: 22,
			voluemPrice: 148.3634
		},
		{
			open: 148.34,
			close: 148.33,
			volume: 1481,
			time: 1654634400000,
			lowest: 148.33,
			highest: 148.3584,
			volumePrice: 148.3337,
			transactionCount: 32,
			voluemPrice: 148.3337
		},
		{
			open: 148.3599,
			close: 148.35,
			volume: 1322,
			time: 1654634460000,
			lowest: 148.35,
			highest: 148.36,
			volumePrice: 148.3547,
			transactionCount: 23,
			voluemPrice: 148.3547
		},
		{
			open: 148.33,
			close: 148.31,
			volume: 2780,
			time: 1654634640000,
			lowest: 148.31,
			highest: 148.34,
			volumePrice: 148.3195,
			transactionCount: 45,
			voluemPrice: 148.3195
		},
		{
			open: 148.32,
			close: 148.33,
			volume: 548,
			time: 1654634700000,
			lowest: 148.32,
			highest: 148.33,
			volumePrice: 148.3264,
			transactionCount: 18,
			voluemPrice: 148.3264
		},
		{
			open: 148.31,
			close: 148.33,
			volume: 830,
			time: 1654634760000,
			lowest: 148.31,
			highest: 148.33,
			volumePrice: 148.3238,
			transactionCount: 30,
			voluemPrice: 148.3238
		},
		{
			open: 148.3,
			close: 148.28,
			volume: 872,
			time: 1654634880000,
			lowest: 148.28,
			highest: 148.3,
			volumePrice: 148.2947,
			transactionCount: 30,
			voluemPrice: 148.2947
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 454,
			time: 1654634940000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.3196,
			transactionCount: 26,
			voluemPrice: 148.3196
		},
		{
			open: 148.3399,
			close: 148.3399,
			volume: 574,
			time: 1654635000000,
			lowest: 148.3399,
			highest: 148.3399,
			volumePrice: 148.3386,
			transactionCount: 24,
			voluemPrice: 148.3386
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 963,
			time: 1654635060000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.3036,
			transactionCount: 28,
			voluemPrice: 148.3036
		},
		{
			open: 148.2712,
			close: 148.2712,
			volume: 709,
			time: 1654635180000,
			lowest: 148.2712,
			highest: 148.2712,
			volumePrice: 148.2783,
			transactionCount: 22,
			voluemPrice: 148.2783
		},
		{
			open: 148.34,
			close: 148.35,
			volume: 4302,
			time: 1654635300000,
			lowest: 148.34,
			highest: 148.35,
			volumePrice: 148.3478,
			transactionCount: 30,
			voluemPrice: 148.3478
		},
		{
			open: 148.31,
			close: 148.31,
			volume: 549,
			time: 1654635420000,
			lowest: 148.31,
			highest: 148.31,
			volumePrice: 148.3142,
			transactionCount: 29,
			voluemPrice: 148.3142
		},
		{
			open: 148.2867,
			close: 148.31,
			volume: 251,
			time: 1654635480000,
			lowest: 148.2867,
			highest: 148.31,
			volumePrice: 148.3022,
			transactionCount: 14,
			voluemPrice: 148.3022
		},
		{
			open: 148.3474,
			close: 148.35,
			volume: 556,
			time: 1654635600000,
			lowest: 148.3474,
			highest: 148.35,
			volumePrice: 148.3475,
			transactionCount: 25,
			voluemPrice: 148.3475
		},
		{
			open: 148.35,
			close: 148.35,
			volume: 684,
			time: 1654635720000,
			lowest: 148.35,
			highest: 148.35,
			volumePrice: 148.3443,
			transactionCount: 14,
			voluemPrice: 148.3443
		},
		{
			open: 148.3499,
			close: 148.3499,
			volume: 1112,
			time: 1654635780000,
			lowest: 148.3499,
			highest: 148.3499,
			volumePrice: 148.345,
			transactionCount: 21,
			voluemPrice: 148.345
		},
		{
			open: 148.3535,
			close: 148.3535,
			volume: 461,
			time: 1654635840000,
			lowest: 148.3535,
			highest: 148.3535,
			volumePrice: 148.3307,
			transactionCount: 22,
			voluemPrice: 148.3307
		},
		{
			open: 148.37,
			close: 148.37,
			volume: 1626,
			time: 1654635900000,
			lowest: 148.37,
			highest: 148.37,
			volumePrice: 149.038,
			transactionCount: 13,
			voluemPrice: 149.038
		},
		{
			open: 148.36,
			close: 148.31,
			volume: 494,
			time: 1654636080000,
			lowest: 148.31,
			highest: 148.36,
			volumePrice: 148.3224,
			transactionCount: 20,
			voluemPrice: 148.3224
		},
		{
			open: 148.36,
			close: 148.38,
			volume: 1140,
			time: 1654636140000,
			lowest: 148.35,
			highest: 148.38,
			volumePrice: 148.3631,
			transactionCount: 25,
			voluemPrice: 148.3631
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 685,
			time: 1654636200000,
			lowest: 148.34,
			highest: 148.34,
			volumePrice: 148.3448,
			transactionCount: 17,
			voluemPrice: 148.3448
		},
		{
			open: 148.35,
			close: 148.35,
			volume: 420,
			time: 1654636260000,
			lowest: 148.35,
			highest: 148.35,
			volumePrice: 148.351,
			transactionCount: 26,
			voluemPrice: 148.351
		},
		{
			open: 148.3782,
			close: 148.3782,
			volume: 176,
			time: 1654636380000,
			lowest: 148.3782,
			highest: 148.3782,
			volumePrice: 148.3691,
			transactionCount: 14,
			voluemPrice: 148.3691
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 635,
			time: 1654636440000,
			lowest: 148.34,
			highest: 148.34,
			volumePrice: 148.3403,
			transactionCount: 25,
			voluemPrice: 148.3403
		},
		{
			open: 148.3199,
			close: 148.32,
			volume: 549,
			time: 1654636500000,
			lowest: 148.3199,
			highest: 148.32,
			volumePrice: 148.3155,
			transactionCount: 29,
			voluemPrice: 148.3155
		},
		{
			open: 148.32,
			close: 148.32,
			volume: 528,
			time: 1654636560000,
			lowest: 148.32,
			highest: 148.32,
			volumePrice: 148.317,
			transactionCount: 18,
			voluemPrice: 148.317
		},
		{
			open: 148.3185,
			close: 148.32,
			volume: 1265,
			time: 1654636620000,
			lowest: 148.3,
			highest: 148.32,
			volumePrice: 148.3178,
			transactionCount: 18,
			voluemPrice: 148.3178
		},
		{
			open: 148.2817,
			close: 148.2817,
			volume: 229,
			time: 1654636800000,
			lowest: 148.2817,
			highest: 148.2817,
			volumePrice: 148.2874,
			transactionCount: 17,
			voluemPrice: 148.2874
		},
		{
			open: 148.33,
			close: 148.35,
			volume: 1117,
			time: 1654636860000,
			lowest: 148.33,
			highest: 148.35,
			volumePrice: 148.335,
			transactionCount: 38,
			voluemPrice: 148.335
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 319,
			time: 1654636920000,
			lowest: 148.34,
			highest: 148.34,
			volumePrice: 148.3372,
			transactionCount: 16,
			voluemPrice: 148.3372
		},
		{
			open: 148.35,
			close: 148.3201,
			volume: 431,
			time: 1654636980000,
			lowest: 148.3201,
			highest: 148.35,
			volumePrice: 148.3395,
			transactionCount: 18,
			voluemPrice: 148.3395
		},
		{
			open: 148.3402,
			close: 148.3402,
			volume: 521,
			time: 1654637040000,
			lowest: 148.3402,
			highest: 148.3402,
			volumePrice: 148.3408,
			transactionCount: 13,
			voluemPrice: 148.3408
		},
		{
			open: 148.34,
			close: 148.33,
			volume: 595,
			time: 1654637160000,
			lowest: 148.33,
			highest: 148.34,
			volumePrice: 148.335,
			transactionCount: 25,
			voluemPrice: 148.335
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 2533,
			time: 1654637220000,
			lowest: 148.32,
			highest: 148.33,
			volumePrice: 148.3291,
			transactionCount: 20,
			voluemPrice: 148.3291
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 222,
			time: 1654637340000,
			lowest: 148.33,
			highest: 148.33,
			volumePrice: 148.3386,
			transactionCount: 17,
			voluemPrice: 148.3386
		},
		{
			open: 148.38,
			close: 148.35,
			volume: 452,
			time: 1654637400000,
			lowest: 148.3203,
			highest: 148.38,
			volumePrice: 148.3431,
			transactionCount: 22,
			voluemPrice: 148.3431
		},
		{
			open: 148.38,
			close: 148.38,
			volume: 679,
			time: 1654637460000,
			lowest: 148.38,
			highest: 148.38,
			volumePrice: 148.3725,
			transactionCount: 21,
			voluemPrice: 148.3725
		},
		{
			open: 148.33,
			close: 148.33,
			volume: 184,
			time: 1654637640000,
			lowest: 148.33,
			highest: 148.33,
			volumePrice: 148.3365,
			transactionCount: 13,
			voluemPrice: 148.3365
		},
		{
			open: 148.35,
			close: 148.35,
			volume: 295,
			time: 1654637760000,
			lowest: 148.35,
			highest: 148.35,
			volumePrice: 148.3489,
			transactionCount: 11,
			voluemPrice: 148.3489
		},
		{
			open: 148.3337,
			close: 148.3337,
			volume: 755,
			time: 1654638180000,
			lowest: 148.3337,
			highest: 148.3337,
			volumePrice: 148.3354,
			transactionCount: 15,
			voluemPrice: 148.3354
		},
		{
			open: 148.38,
			close: 148.38,
			volume: 1062,
			time: 1654638240000,
			lowest: 148.38,
			highest: 148.38,
			volumePrice: 148.3782,
			transactionCount: 12,
			voluemPrice: 148.3782
		},
		{
			open: 148.3338,
			close: 148.3338,
			volume: 540,
			time: 1654638300000,
			lowest: 148.3338,
			highest: 148.3338,
			volumePrice: 148.3353,
			transactionCount: 16,
			voluemPrice: 148.3353
		},
		{
			open: 148.3699,
			close: 148.35,
			volume: 896,
			time: 1654638420000,
			lowest: 148.35,
			highest: 148.3699,
			volumePrice: 148.3631,
			transactionCount: 13,
			voluemPrice: 148.3631
		},
		{
			open: 148.35,
			close: 148.33,
			volume: 876,
			time: 1654638540000,
			lowest: 148.33,
			highest: 148.35,
			volumePrice: 148.3394,
			transactionCount: 27,
			voluemPrice: 148.3394
		},
		{
			open: 148.3329,
			close: 148.35,
			volume: 411,
			time: 1654638600000,
			lowest: 148.3329,
			highest: 148.35,
			volumePrice: 148.3482,
			transactionCount: 20,
			voluemPrice: 148.3482
		},
		{
			open: 148.37,
			close: 148.35,
			volume: 1077,
			time: 1654638720000,
			lowest: 148.35,
			highest: 148.37,
			volumePrice: 148.3519,
			transactionCount: 16,
			voluemPrice: 148.3519
		},
		{
			open: 148.36,
			close: 148.34,
			volume: 2036,
			time: 1654638780000,
			lowest: 148.34,
			highest: 148.36,
			volumePrice: 148.3486,
			transactionCount: 18,
			voluemPrice: 148.3486
		},
		{
			open: 148.36,
			close: 148.35,
			volume: 1304,
			time: 1654638840000,
			lowest: 148.35,
			highest: 148.37,
			volumePrice: 148.3563,
			transactionCount: 35,
			voluemPrice: 148.3563
		},
		{
			open: 148.35,
			close: 148.36,
			volume: 723,
			time: 1654638900000,
			lowest: 148.35,
			highest: 148.36,
			volumePrice: 148.3583,
			transactionCount: 19,
			voluemPrice: 148.3583
		},
		{
			open: 148.35,
			close: 148.38,
			volume: 2306,
			time: 1654638960000,
			lowest: 148.35,
			highest: 148.38,
			volumePrice: 148.3602,
			transactionCount: 25,
			voluemPrice: 148.3602
		},
		{
			open: 148.4,
			close: 148.41,
			volume: 748,
			time: 1654639020000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.4,
			transactionCount: 30,
			voluemPrice: 148.4
		},
		{
			open: 148.37,
			close: 148.37,
			volume: 667,
			time: 1654639080000,
			lowest: 148.37,
			highest: 148.37,
			volumePrice: 148.3703,
			transactionCount: 15,
			voluemPrice: 148.3703
		},
		{
			open: 148.37,
			close: 148.41,
			volume: 4579,
			time: 1654639140000,
			lowest: 148.37,
			highest: 148.41,
			volumePrice: 148.4047,
			transactionCount: 27,
			voluemPrice: 148.4047
		},
		{
			open: 148.41,
			close: 148.44,
			volume: 4171,
			time: 1654639200000,
			lowest: 148.41,
			highest: 148.44,
			volumePrice: 148.4211,
			transactionCount: 27,
			voluemPrice: 148.4211
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 228,
			time: 1654639260000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4455,
			transactionCount: 10,
			voluemPrice: 148.4455
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 3738,
			time: 1654639440000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4492,
			transactionCount: 19,
			voluemPrice: 148.4492
		},
		{
			open: 148.47,
			close: 148.48,
			volume: 2058,
			time: 1654639560000,
			lowest: 148.47,
			highest: 148.48,
			volumePrice: 148.4782,
			transactionCount: 26,
			voluemPrice: 148.4782
		},
		{
			open: 148.45,
			close: 148.46,
			volume: 379,
			time: 1654639860000,
			lowest: 148.45,
			highest: 148.46,
			volumePrice: 148.4472,
			transactionCount: 27,
			voluemPrice: 148.4472
		},
		{
			open: 148.48,
			close: 148.48,
			volume: 411,
			time: 1654639920000,
			lowest: 148.48,
			highest: 148.48,
			volumePrice: 148.4718,
			transactionCount: 23,
			voluemPrice: 148.4718
		},
		{
			open: 148.48,
			close: 148.48,
			volume: 180,
			time: 1654640040000,
			lowest: 148.48,
			highest: 148.48,
			volumePrice: 148.4749,
			transactionCount: 19,
			voluemPrice: 148.4749
		},
		{
			open: 148.49,
			close: 148.49,
			volume: 1378,
			time: 1654640100000,
			lowest: 148.49,
			highest: 148.49,
			volumePrice: 148.4883,
			transactionCount: 24,
			voluemPrice: 148.4883
		},
		{
			open: 148.49,
			close: 148.49,
			volume: 2317,
			time: 1654640160000,
			lowest: 148.49,
			highest: 148.49,
			volumePrice: 148.49,
			transactionCount: 17,
			voluemPrice: 148.49
		},
		{
			open: 148.49,
			close: 148.49,
			volume: 921,
			time: 1654640220000,
			lowest: 148.49,
			highest: 148.49,
			volumePrice: 148.4908,
			transactionCount: 22,
			voluemPrice: 148.4908
		},
		{
			open: 148.49,
			close: 148.48,
			volume: 2195,
			time: 1654640280000,
			lowest: 148.48,
			highest: 148.49,
			volumePrice: 148.4882,
			transactionCount: 28,
			voluemPrice: 148.4882
		},
		{
			open: 148.46,
			close: 148.49,
			volume: 384,
			time: 1654640400000,
			lowest: 148.46,
			highest: 148.49,
			volumePrice: 148.4716,
			transactionCount: 18,
			voluemPrice: 148.4716
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 433,
			time: 1654640520000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4467,
			transactionCount: 22,
			voluemPrice: 148.4467
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 378,
			time: 1654640580000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.4139,
			transactionCount: 20,
			voluemPrice: 148.4139
		},
		{
			open: 148.42,
			close: 148.42,
			volume: 357,
			time: 1654640640000,
			lowest: 148.42,
			highest: 148.42,
			volumePrice: 148.4178,
			transactionCount: 18,
			voluemPrice: 148.4178
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 490,
			time: 1654640700000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4261,
			transactionCount: 11,
			voluemPrice: 148.4261
		},
		{
			open: 148.4899,
			close: 148.43,
			volume: 525,
			time: 1654640760000,
			lowest: 148.43,
			highest: 148.4899,
			volumePrice: 148.4476,
			transactionCount: 16,
			voluemPrice: 148.4476
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 307,
			time: 1654640820000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4421,
			transactionCount: 18,
			voluemPrice: 148.4421
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 1362,
			time: 1654640940000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4295,
			transactionCount: 23,
			voluemPrice: 148.4295
		},
		{
			open: 148.41,
			close: 148.4,
			volume: 4716,
			time: 1654641060000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.4034,
			transactionCount: 29,
			voluemPrice: 148.4034
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 234,
			time: 1654641120000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4279,
			transactionCount: 12,
			voluemPrice: 148.4279
		},
		{
			open: 148.42,
			close: 148.42,
			volume: 158,
			time: 1654641180000,
			lowest: 148.42,
			highest: 148.42,
			volumePrice: 148.4212,
			transactionCount: 17,
			voluemPrice: 148.4212
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 253,
			time: 1654641240000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.4114,
			transactionCount: 14,
			voluemPrice: 148.4114
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 135,
			time: 1654641540000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4293,
			transactionCount: 20,
			voluemPrice: 148.4293
		},
		{
			open: 148.42,
			close: 148.42,
			volume: 4024,
			time: 1654641720000,
			lowest: 148.42,
			highest: 148.42,
			volumePrice: 148.4201,
			transactionCount: 7,
			voluemPrice: 148.4201
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 314,
			time: 1654641840000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4495,
			transactionCount: 19,
			voluemPrice: 148.4495
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 1031,
			time: 1654641900000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4402,
			transactionCount: 16,
			voluemPrice: 148.4402
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 5107,
			time: 1654642020000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4503,
			transactionCount: 15,
			voluemPrice: 148.4503
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 388,
			time: 1654642200000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4573,
			transactionCount: 19,
			voluemPrice: 148.4573
		},
		{
			open: 148.44,
			close: 148.46,
			volume: 989,
			time: 1654642260000,
			lowest: 148.44,
			highest: 148.46,
			volumePrice: 148.448,
			transactionCount: 33,
			voluemPrice: 148.448
		},
		{
			open: 148.42,
			close: 148.41,
			volume: 542,
			time: 1654642320000,
			lowest: 148.4,
			highest: 148.42,
			volumePrice: 148.4118,
			transactionCount: 33,
			voluemPrice: 148.4118
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 257,
			time: 1654642440000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4476,
			transactionCount: 22,
			voluemPrice: 148.4476
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 285,
			time: 1654642500000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.4164,
			transactionCount: 13,
			voluemPrice: 148.4164
		},
		{
			open: 148.48,
			close: 148.48,
			volume: 234,
			time: 1654642560000,
			lowest: 148.48,
			highest: 148.48,
			volumePrice: 148.4434,
			transactionCount: 12,
			voluemPrice: 148.4434
		},
		{
			open: 148.47,
			close: 148.47,
			volume: 361,
			time: 1654643040000,
			lowest: 148.47,
			highest: 148.47,
			volumePrice: 148.4583,
			transactionCount: 22,
			voluemPrice: 148.4583
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 5014,
			time: 1654643100000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4401,
			transactionCount: 10,
			voluemPrice: 148.4401
		},
		{
			open: 148.46,
			close: 148.48,
			volume: 306,
			time: 1654643220000,
			lowest: 148.46,
			highest: 148.48,
			volumePrice: 148.4667,
			transactionCount: 7,
			voluemPrice: 148.4667
		},
		{
			open: 148.48,
			close: 148.49,
			volume: 737,
			time: 1654643280000,
			lowest: 148.48,
			highest: 148.49,
			volumePrice: 148.4822,
			transactionCount: 18,
			voluemPrice: 148.4822
		},
		{
			open: 148.48,
			close: 148.46,
			volume: 1015,
			time: 1654643340000,
			lowest: 148.46,
			highest: 148.49,
			volumePrice: 148.475,
			transactionCount: 29,
			voluemPrice: 148.475
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 622,
			time: 1654643520000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4616,
			transactionCount: 24,
			voluemPrice: 148.4616
		},
		{
			open: 148.47,
			close: 148.47,
			volume: 738,
			time: 1654643700000,
			lowest: 148.47,
			highest: 148.47,
			volumePrice: 148.4685,
			transactionCount: 10,
			voluemPrice: 148.4685
		},
		{
			open: 148.44,
			close: 148.48,
			volume: 758,
			time: 1654643760000,
			lowest: 148.44,
			highest: 148.48,
			volumePrice: 148.4677,
			transactionCount: 19,
			voluemPrice: 148.4677
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 556,
			time: 1654644000000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4586,
			transactionCount: 15,
			voluemPrice: 148.4586
		},
		{
			open: 148.48,
			close: 148.42,
			volume: 363,
			time: 1654644060000,
			lowest: 148.42,
			highest: 148.48,
			volumePrice: 148.4604,
			transactionCount: 13,
			voluemPrice: 148.4604
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 1086,
			time: 1654644120000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4507,
			transactionCount: 12,
			voluemPrice: 148.4507
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 206,
			time: 1654644240000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4468,
			transactionCount: 9,
			voluemPrice: 148.4468
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 317,
			time: 1654644300000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4533,
			transactionCount: 15,
			voluemPrice: 148.4533
		},
		{
			open: 148.46,
			close: 148.5,
			volume: 2394,
			time: 1654644360000,
			lowest: 148.46,
			highest: 148.5,
			volumePrice: 148.4936,
			transactionCount: 43,
			voluemPrice: 148.4936
		},
		{
			open: 148.46,
			close: 148.46,
			volume: 204,
			time: 1654644420000,
			lowest: 148.46,
			highest: 148.46,
			volumePrice: 148.4612,
			transactionCount: 9,
			voluemPrice: 148.4612
		},
		{
			open: 148.4899,
			close: 148.4899,
			volume: 452,
			time: 1654644480000,
			lowest: 148.4899,
			highest: 148.4899,
			volumePrice: 148.4772,
			transactionCount: 17,
			voluemPrice: 148.4772
		},
		{
			open: 148.46,
			close: 148.43,
			volume: 596,
			time: 1654644600000,
			lowest: 148.43,
			highest: 148.46,
			volumePrice: 148.4478,
			transactionCount: 12,
			voluemPrice: 148.4478
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 476,
			time: 1654644720000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4427,
			transactionCount: 23,
			voluemPrice: 148.4427
		},
		{
			open: 148.47,
			close: 148.41,
			volume: 1665,
			time: 1654644780000,
			lowest: 148.41,
			highest: 148.47,
			volumePrice: 148.4458,
			transactionCount: 23,
			voluemPrice: 148.4458
		},
		{
			open: 148.4,
			close: 148.38,
			volume: 919,
			time: 1654644840000,
			lowest: 148.38,
			highest: 148.4,
			volumePrice: 148.3923,
			transactionCount: 43,
			voluemPrice: 148.3923
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 1175,
			time: 1654644900000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.3999,
			transactionCount: 12,
			voluemPrice: 148.3999
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 282,
			time: 1654645080000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4001,
			transactionCount: 19,
			voluemPrice: 148.4001
		},
		{
			open: 148.37,
			close: 148.37,
			volume: 343,
			time: 1654645140000,
			lowest: 148.37,
			highest: 148.37,
			volumePrice: 148.379,
			transactionCount: 21,
			voluemPrice: 148.379
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 112,
			time: 1654645260000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3638,
			transactionCount: 10,
			voluemPrice: 148.3638
		},
		{
			open: 148.35,
			close: 148.35,
			volume: 9246,
			time: 1654645320000,
			lowest: 148.34,
			highest: 148.35,
			volumePrice: 148.3442,
			transactionCount: 53,
			voluemPrice: 148.3442
		},
		{
			open: 148.35,
			close: 148.34,
			volume: 743,
			time: 1654645380000,
			lowest: 148.34,
			highest: 148.35,
			volumePrice: 148.3451,
			transactionCount: 14,
			voluemPrice: 148.3451
		},
		{
			open: 148.36,
			close: 148.34,
			volume: 499,
			time: 1654645500000,
			lowest: 148.34,
			highest: 148.36,
			volumePrice: 148.3568,
			transactionCount: 37,
			voluemPrice: 148.3568
		},
		{
			open: 148.34,
			close: 148.3,
			volume: 3155,
			time: 1654645560000,
			lowest: 148.3,
			highest: 148.34,
			volumePrice: 148.3317,
			transactionCount: 37,
			voluemPrice: 148.3317
		},
		{
			open: 148.22,
			close: 148.27,
			volume: 598,
			time: 1654645620000,
			lowest: 148.22,
			highest: 148.27,
			volumePrice: 148.2577,
			transactionCount: 31,
			voluemPrice: 148.2577
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 303,
			time: 1654645680000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2997,
			transactionCount: 7,
			voluemPrice: 148.2997
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 2052,
			time: 1654645740000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2996,
			transactionCount: 23,
			voluemPrice: 148.2996
		},
		{
			open: 148.27,
			close: 148.25,
			volume: 3451,
			time: 1654645800000,
			lowest: 148.24,
			highest: 148.3,
			volumePrice: 148.2716,
			transactionCount: 50,
			voluemPrice: 148.2716
		},
		{
			open: 148.23,
			close: 148.27,
			volume: 5923,
			time: 1654645860000,
			lowest: 148.2,
			highest: 148.27,
			volumePrice: 148.213,
			transactionCount: 40,
			voluemPrice: 148.213
		},
		{
			open: 148.21,
			close: 148.21,
			volume: 603,
			time: 1654646040000,
			lowest: 148.21,
			highest: 148.21,
			volumePrice: 148.212,
			transactionCount: 19,
			voluemPrice: 148.212
		},
		{
			open: 148.22,
			close: 148.25,
			volume: 1105,
			time: 1654646100000,
			lowest: 148.22,
			highest: 148.25,
			volumePrice: 148.2325,
			transactionCount: 29,
			voluemPrice: 148.2325
		},
		{
			open: 148.23,
			close: 148.22,
			volume: 1201,
			time: 1654646160000,
			lowest: 148.22,
			highest: 148.23,
			volumePrice: 148.2288,
			transactionCount: 31,
			voluemPrice: 148.2288
		},
		{
			open: 148.22,
			close: 148.25,
			volume: 1793,
			time: 1654646220000,
			lowest: 148.22,
			highest: 148.25,
			volumePrice: 148.2376,
			transactionCount: 18,
			voluemPrice: 148.2376
		},
		{
			open: 148.21,
			close: 148.22,
			volume: 927,
			time: 1654646340000,
			lowest: 148.21,
			highest: 148.22,
			volumePrice: 148.2122,
			transactionCount: 41,
			voluemPrice: 148.2122
		},
		{
			open: 147.85,
			close: 148.3,
			volume: 3247,
			time: 1654675200000,
			lowest: 147.85,
			highest: 148.41,
			volumePrice: 148.2918,
			transactionCount: 80,
			voluemPrice: 148.2918
		},
		{
			open: 148.41,
			close: 148.5,
			volume: 1513,
			time: 1654675320000,
			lowest: 148.41,
			highest: 148.5,
			volumePrice: 148.4304,
			transactionCount: 30,
			voluemPrice: 148.4304
		},
		{
			open: 148.39,
			close: 148.3,
			volume: 716,
			time: 1654675380000,
			lowest: 148.3,
			highest: 148.39,
			volumePrice: 148.335,
			transactionCount: 36,
			voluemPrice: 148.335
		},
		{
			open: 148.23,
			close: 148.18,
			volume: 1818,
			time: 1654675500000,
			lowest: 148.18,
			highest: 148.24,
			volumePrice: 148.2176,
			transactionCount: 40,
			voluemPrice: 148.2176
		},
		{
			open: 148.18,
			close: 148.15,
			volume: 410,
			time: 1654675620000,
			lowest: 148.15,
			highest: 148.18,
			volumePrice: 148.1658,
			transactionCount: 15,
			voluemPrice: 148.1658
		},
		{
			open: 148.1,
			close: 148.15,
			volume: 608,
			time: 1654675680000,
			lowest: 148.1,
			highest: 148.15,
			volumePrice: 148.1357,
			transactionCount: 15,
			voluemPrice: 148.1357
		},
		{
			open: 148.16,
			close: 148.15,
			volume: 2044,
			time: 1654675740000,
			lowest: 148.08,
			highest: 148.16,
			volumePrice: 148.1387,
			transactionCount: 21,
			voluemPrice: 148.1387
		},
		{
			open: 148.1,
			close: 148.15,
			volume: 616,
			time: 1654675800000,
			lowest: 148.1,
			highest: 148.15,
			volumePrice: 148.1291,
			transactionCount: 8,
			voluemPrice: 148.1291
		},
		{
			open: 148.15,
			close: 148.15,
			volume: 1017,
			time: 1654675860000,
			lowest: 148.15,
			highest: 148.15,
			volumePrice: 148.1421,
			transactionCount: 25,
			voluemPrice: 148.1421
		},
		{
			open: 148.12,
			close: 148.12,
			volume: 335,
			time: 1654675920000,
			lowest: 148.12,
			highest: 148.12,
			volumePrice: 148.12,
			transactionCount: 11,
			voluemPrice: 148.12
		},
		{
			open: 148.1,
			close: 148.1,
			volume: 1185,
			time: 1654675980000,
			lowest: 148.1,
			highest: 148.1,
			volumePrice: 148.1017,
			transactionCount: 20,
			voluemPrice: 148.1017
		},
		{
			open: 148.12,
			close: 148.1,
			volume: 603,
			time: 1654676040000,
			lowest: 148.1,
			highest: 148.12,
			volumePrice: 148.1051,
			transactionCount: 19,
			voluemPrice: 148.1051
		},
		{
			open: 148.13,
			close: 148.15,
			volume: 285,
			time: 1654676100000,
			lowest: 148.13,
			highest: 148.15,
			volumePrice: 148.1361,
			transactionCount: 11,
			voluemPrice: 148.1361
		},
		{
			open: 148.15,
			close: 148.15,
			volume: 3654,
			time: 1654676520000,
			lowest: 148.13,
			highest: 148.15,
			volumePrice: 148.1481,
			transactionCount: 27,
			voluemPrice: 148.1481
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 430,
			time: 1654676640000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2999,
			transactionCount: 9,
			voluemPrice: 148.2999
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 512,
			time: 1654676700000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.301,
			transactionCount: 9,
			voluemPrice: 148.301
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 224,
			time: 1654676940000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4025,
			transactionCount: 7,
			voluemPrice: 148.4025
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 298,
			time: 1654677000000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4663,
			transactionCount: 15,
			voluemPrice: 148.4663
		},
		{
			open: 148.45,
			close: 148.44,
			volume: 975,
			time: 1654677060000,
			lowest: 148.44,
			highest: 148.45,
			volumePrice: 148.4477,
			transactionCount: 15,
			voluemPrice: 148.4477
		},
		{
			open: 148.53,
			close: 148.53,
			volume: 272,
			time: 1654677420000,
			lowest: 148.53,
			highest: 148.53,
			volumePrice: 148.5225,
			transactionCount: 6,
			voluemPrice: 148.5225
		},
		{
			open: 148.52,
			close: 148.52,
			volume: 648,
			time: 1654677540000,
			lowest: 148.52,
			highest: 148.52,
			volumePrice: 148.5104,
			transactionCount: 17,
			voluemPrice: 148.5104
		},
		{
			open: 148.48,
			close: 148.48,
			volume: 620,
			time: 1654677840000,
			lowest: 148.48,
			highest: 148.48,
			volumePrice: 148.463,
			transactionCount: 16,
			voluemPrice: 148.463
		},
		{
			open: 148.51,
			close: 148.51,
			volume: 301,
			time: 1654677900000,
			lowest: 148.51,
			highest: 148.51,
			volumePrice: 148.5095,
			transactionCount: 7,
			voluemPrice: 148.5095
		},
		{
			open: 148.51,
			close: 148.51,
			volume: 212,
			time: 1654677960000,
			lowest: 148.51,
			highest: 148.51,
			volumePrice: 148.5104,
			transactionCount: 7,
			voluemPrice: 148.5104
		},
		{
			open: 148.53,
			close: 148.5,
			volume: 666,
			time: 1654678020000,
			lowest: 148.5,
			highest: 148.53,
			volumePrice: 148.5152,
			transactionCount: 12,
			voluemPrice: 148.5152
		},
		{
			open: 148.46,
			close: 148.45,
			volume: 700,
			time: 1654678080000,
			lowest: 148.45,
			highest: 148.46,
			volumePrice: 148.4417,
			transactionCount: 18,
			voluemPrice: 148.4417
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 183,
			time: 1654678140000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4501,
			transactionCount: 3,
			voluemPrice: 148.4501
		},
		{
			open: 148.43,
			close: 148.43,
			volume: 355,
			time: 1654678260000,
			lowest: 148.43,
			highest: 148.43,
			volumePrice: 148.4308,
			transactionCount: 9,
			voluemPrice: 148.4308
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 259,
			time: 1654678380000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.422,
			transactionCount: 12,
			voluemPrice: 148.422
		},
		{
			open: 148.45,
			close: 148.46,
			volume: 1216,
			time: 1654678440000,
			lowest: 148.45,
			highest: 148.46,
			volumePrice: 148.4587,
			transactionCount: 10,
			voluemPrice: 148.4587
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 171,
			time: 1654678560000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4571,
			transactionCount: 5,
			voluemPrice: 148.4571
		},
		{
			open: 148.48,
			close: 148.44,
			volume: 380,
			time: 1654678680000,
			lowest: 148.44,
			highest: 148.48,
			volumePrice: 148.4547,
			transactionCount: 17,
			voluemPrice: 148.4547
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 1030,
			time: 1654678860000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4428,
			transactionCount: 13,
			voluemPrice: 148.4428
		},
		{
			open: 148.44,
			close: 148.44,
			volume: 594,
			time: 1654678920000,
			lowest: 148.44,
			highest: 148.44,
			volumePrice: 148.4406,
			transactionCount: 12,
			voluemPrice: 148.4406
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 931,
			time: 1654679040000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4025,
			transactionCount: 24,
			voluemPrice: 148.4025
		},
		{
			open: 148.32,
			close: 148.32,
			volume: 372,
			time: 1654679160000,
			lowest: 148.32,
			highest: 148.32,
			volumePrice: 148.3639,
			transactionCount: 17,
			voluemPrice: 148.3639
		},
		{
			open: 148.27,
			close: 148.27,
			volume: 671,
			time: 1654679340000,
			lowest: 148.27,
			highest: 148.27,
			volumePrice: 148.2522,
			transactionCount: 14,
			voluemPrice: 148.2522
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 484,
			time: 1654679640000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.3041,
			transactionCount: 10,
			voluemPrice: 148.3041
		},
		{
			open: 148.4,
			close: 148.45,
			volume: 392,
			time: 1654680300000,
			lowest: 148.4,
			highest: 148.45,
			volumePrice: 148.425,
			transactionCount: 5,
			voluemPrice: 148.425
		},
		{
			open: 148.36,
			close: 148.37,
			volume: 372,
			time: 1654680720000,
			lowest: 148.36,
			highest: 148.37,
			volumePrice: 148.3601,
			transactionCount: 20,
			voluemPrice: 148.3601
		},
		{
			open: 148.39,
			close: 148.4,
			volume: 808,
			time: 1654681020000,
			lowest: 148.39,
			highest: 148.4,
			volumePrice: 148.3955,
			transactionCount: 7,
			voluemPrice: 148.3955
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 521,
			time: 1654681140000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.3978,
			transactionCount: 9,
			voluemPrice: 148.3978
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 359,
			time: 1654681440000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4431,
			transactionCount: 14,
			voluemPrice: 148.4431
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 156,
			time: 1654681500000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4501,
			transactionCount: 11,
			voluemPrice: 148.4501
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 142,
			time: 1654681560000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.45,
			transactionCount: 4,
			voluemPrice: 148.45
		},
		{
			open: 148.25,
			close: 148.2,
			volume: 2090,
			time: 1654682460000,
			lowest: 148.2,
			highest: 148.25,
			volumePrice: 148.2352,
			transactionCount: 44,
			voluemPrice: 148.2352
		},
		{
			open: 148.15,
			close: 148.15,
			volume: 884,
			time: 1654682580000,
			lowest: 148.15,
			highest: 148.15,
			volumePrice: 148.1561,
			transactionCount: 18,
			voluemPrice: 148.1561
		},
		{
			open: 148.03,
			close: 147.88,
			volume: 2764,
			time: 1654682760000,
			lowest: 147.88,
			highest: 148.11,
			volumePrice: 148.0013,
			transactionCount: 119,
			voluemPrice: 148.0013
		},
		{
			open: 147.97,
			close: 148,
			volume: 454,
			time: 1654682940000,
			lowest: 147.97,
			highest: 148,
			volumePrice: 147.9899,
			transactionCount: 8,
			voluemPrice: 147.9899
		},
		{
			open: 148.04,
			close: 148.04,
			volume: 155,
			time: 1654683000000,
			lowest: 148.04,
			highest: 148.04,
			volumePrice: 148.0394,
			transactionCount: 3,
			voluemPrice: 148.0394
		},
		{
			open: 147.91,
			close: 147.91,
			volume: 199,
			time: 1654683180000,
			lowest: 147.91,
			highest: 147.91,
			volumePrice: 147.9159,
			transactionCount: 8,
			voluemPrice: 147.9159
		},
		{
			open: 147.92,
			close: 147.92,
			volume: 816,
			time: 1654684020000,
			lowest: 147.92,
			highest: 147.92,
			volumePrice: 147.9254,
			transactionCount: 21,
			voluemPrice: 147.9254
		},
		{
			open: 147.92,
			close: 147.92,
			volume: 250,
			time: 1654684080000,
			lowest: 147.92,
			highest: 147.92,
			volumePrice: 147.92,
			transactionCount: 5,
			voluemPrice: 147.92
		},
		{
			open: 147.93,
			close: 147.9,
			volume: 1104,
			time: 1654684320000,
			lowest: 147.9,
			highest: 147.93,
			volumePrice: 147.9121,
			transactionCount: 16,
			voluemPrice: 147.9121
		},
		{
			open: 147.9,
			close: 147.9,
			volume: 180,
			time: 1654684380000,
			lowest: 147.9,
			highest: 147.9,
			volumePrice: 147.9,
			transactionCount: 2,
			voluemPrice: 147.9
		},
		{
			open: 147.97,
			close: 148,
			volume: 1280,
			time: 1654684740000,
			lowest: 147.97,
			highest: 148,
			volumePrice: 147.9938,
			transactionCount: 27,
			voluemPrice: 147.9938
		},
		{
			open: 148.05,
			close: 148.05,
			volume: 393,
			time: 1654684800000,
			lowest: 148.05,
			highest: 148.05,
			volumePrice: 148.0595,
			transactionCount: 4,
			voluemPrice: 148.0595
		},
		{
			open: 148.24,
			close: 148.25,
			volume: 597,
			time: 1654684860000,
			lowest: 148.24,
			highest: 148.25,
			volumePrice: 148.1963,
			transactionCount: 17,
			voluemPrice: 148.1963
		},
		{
			open: 148.2,
			close: 148.2,
			volume: 4635,
			time: 1654684920000,
			lowest: 148.2,
			highest: 148.2,
			volumePrice: 148.2,
			transactionCount: 12,
			voluemPrice: 148.2
		},
		{
			open: 148.2,
			close: 148.2,
			volume: 230,
			time: 1654685400000,
			lowest: 148.2,
			highest: 148.2,
			volumePrice: 148.2001,
			transactionCount: 8,
			voluemPrice: 148.2001
		},
		{
			open: 148.24,
			close: 148.3,
			volume: 2111,
			time: 1654685520000,
			lowest: 148.24,
			highest: 148.3,
			volumePrice: 148.2514,
			transactionCount: 20,
			voluemPrice: 148.2514
		},
		{
			open: 148.3,
			close: 148.37,
			volume: 1114,
			time: 1654685580000,
			lowest: 148.3,
			highest: 148.37,
			volumePrice: 148.3316,
			transactionCount: 25,
			voluemPrice: 148.3316
		},
		{
			open: 148.38,
			close: 148.4,
			volume: 3883,
			time: 1654685640000,
			lowest: 148.38,
			highest: 148.4,
			volumePrice: 148.3921,
			transactionCount: 28,
			voluemPrice: 148.3921
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 270,
			time: 1654685760000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4047,
			transactionCount: 9,
			voluemPrice: 148.4047
		},
		{
			open: 148.33,
			close: 148.32,
			volume: 1440,
			time: 1654686000000,
			lowest: 148.32,
			highest: 148.33,
			volumePrice: 148.3304,
			transactionCount: 38,
			voluemPrice: 148.3304
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 455,
			time: 1654686060000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.3022,
			transactionCount: 13,
			voluemPrice: 148.3022
		},
		{
			open: 148.33,
			close: 148.3,
			volume: 2350,
			time: 1654686180000,
			lowest: 148.29,
			highest: 148.33,
			volumePrice: 148.3066,
			transactionCount: 31,
			voluemPrice: 148.3066
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 568,
			time: 1654686240000,
			lowest: 148.34,
			highest: 148.34,
			volumePrice: 148.3398,
			transactionCount: 17,
			voluemPrice: 148.3398
		},
		{
			open: 148.28,
			close: 148.25,
			volume: 656,
			time: 1654686360000,
			lowest: 148.25,
			highest: 148.28,
			volumePrice: 148.2631,
			transactionCount: 13,
			voluemPrice: 148.2631
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 407,
			time: 1654686420000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2873,
			transactionCount: 7,
			voluemPrice: 148.2873
		},
		{
			open: 148.32,
			close: 148.32,
			volume: 343,
			time: 1654686660000,
			lowest: 148.32,
			highest: 148.32,
			volumePrice: 148.3223,
			transactionCount: 13,
			voluemPrice: 148.3223
		},
		{
			open: 148.36,
			close: 148.4,
			volume: 9338,
			time: 1654686720000,
			lowest: 148.36,
			highest: 148.4,
			volumePrice: 148.3626,
			transactionCount: 33,
			voluemPrice: 148.3626
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 477,
			time: 1654686780000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.406,
			transactionCount: 16,
			voluemPrice: 148.406
		},
		{
			open: 148.41,
			close: 148.42,
			volume: 819,
			time: 1654686840000,
			lowest: 148.41,
			highest: 148.42,
			volumePrice: 148.4066,
			transactionCount: 20,
			voluemPrice: 148.4066
		},
		{
			open: 148.43,
			close: 148.42,
			volume: 1689,
			time: 1654686900000,
			lowest: 148.42,
			highest: 148.43,
			volumePrice: 148.417,
			transactionCount: 31,
			voluemPrice: 148.417
		},
		{
			open: 148.43,
			close: 148.44,
			volume: 1540,
			time: 1654686960000,
			lowest: 148.43,
			highest: 148.44,
			volumePrice: 148.4419,
			transactionCount: 32,
			voluemPrice: 148.4419
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 2036,
			time: 1654687080000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.4044,
			transactionCount: 27,
			voluemPrice: 148.4044
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 470,
			time: 1654687140000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.402,
			transactionCount: 11,
			voluemPrice: 148.402
		},
		{
			open: 148.38,
			close: 148.38,
			volume: 1237,
			time: 1654687200000,
			lowest: 148.38,
			highest: 148.38,
			volumePrice: 148.3826,
			transactionCount: 19,
			voluemPrice: 148.3826
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 466,
			time: 1654687260000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3636,
			transactionCount: 16,
			voluemPrice: 148.3636
		},
		{
			open: 148.35,
			close: 148.41,
			volume: 1933,
			time: 1654687320000,
			lowest: 148.35,
			highest: 148.41,
			volumePrice: 148.3684,
			transactionCount: 49,
			voluemPrice: 148.3684
		},
		{
			open: 148.35,
			close: 148.35,
			volume: 589,
			time: 1654687380000,
			lowest: 148.35,
			highest: 148.35,
			volumePrice: 148.3282,
			transactionCount: 21,
			voluemPrice: 148.3282
		},
		{
			open: 148.35,
			close: 148.45,
			volume: 3023,
			time: 1654687500000,
			lowest: 148.35,
			highest: 148.45,
			volumePrice: 148.4032,
			transactionCount: 33,
			voluemPrice: 148.4032
		},
		{
			open: 148.43,
			close: 148.44,
			volume: 2470,
			time: 1654687560000,
			lowest: 148.43,
			highest: 148.46,
			volumePrice: 148.4509,
			transactionCount: 23,
			voluemPrice: 148.4509
		},
		{
			open: 148.45,
			close: 148.45,
			volume: 521,
			time: 1654687620000,
			lowest: 148.45,
			highest: 148.45,
			volumePrice: 148.4386,
			transactionCount: 17,
			voluemPrice: 148.4386
		},
		{
			open: 148.46,
			close: 148.38,
			volume: 1467,
			time: 1654687680000,
			lowest: 148.38,
			highest: 148.46,
			volumePrice: 148.4238,
			transactionCount: 19,
			voluemPrice: 148.4238
		},
		{
			open: 148.41,
			close: 148.41,
			volume: 389,
			time: 1654687740000,
			lowest: 148.41,
			highest: 148.41,
			volumePrice: 148.4154,
			transactionCount: 12,
			voluemPrice: 148.4154
		},
		{
			open: 148.44,
			close: 148.51,
			volume: 6568,
			time: 1654687800000,
			lowest: 148.44,
			highest: 148.69,
			volumePrice: 148.5207,
			transactionCount: 144,
			voluemPrice: 148.5207
		},
		{
			open: 148.64,
			close: 148.8,
			volume: 9316,
			time: 1654687920000,
			lowest: 148.64,
			highest: 148.8,
			volumePrice: 148.753,
			transactionCount: 112,
			voluemPrice: 148.753
		},
		{
			open: 148.73,
			close: 148.78,
			volume: 1754,
			time: 1654687980000,
			lowest: 148.7,
			highest: 148.78,
			volumePrice: 148.7562,
			transactionCount: 32,
			voluemPrice: 148.7562
		},
		{
			open: 148.79,
			close: 148.75,
			volume: 1991,
			time: 1654688040000,
			lowest: 148.75,
			highest: 148.8,
			volumePrice: 148.7578,
			transactionCount: 40,
			voluemPrice: 148.7578
		},
		{
			open: 148.65,
			close: 148.63,
			volume: 1825,
			time: 1654688220000,
			lowest: 148.58,
			highest: 148.65,
			volumePrice: 148.6114,
			transactionCount: 34,
			voluemPrice: 148.6114
		},
		{
			open: 148.71,
			close: 148.94,
			volume: 10134,
			time: 1654688280000,
			lowest: 148.71,
			highest: 148.94,
			volumePrice: 148.7452,
			transactionCount: 74,
			voluemPrice: 148.7452
		},
		{
			open: 148.73,
			close: 148.73,
			volume: 690,
			time: 1654688340000,
			lowest: 148.73,
			highest: 148.73,
			volumePrice: 148.7412,
			transactionCount: 10,
			voluemPrice: 148.7412
		},
		{
			open: 148.75,
			close: 148.7,
			volume: 3586,
			time: 1654688400000,
			lowest: 148.7,
			highest: 148.75,
			volumePrice: 148.7291,
			transactionCount: 26,
			voluemPrice: 148.7291
		},
		{
			open: 148.67,
			close: 148.63,
			volume: 1179,
			time: 1654688460000,
			lowest: 148.63,
			highest: 148.67,
			volumePrice: 148.6508,
			transactionCount: 26,
			voluemPrice: 148.6508
		},
		{
			open: 148.62,
			close: 148.58,
			volume: 5529,
			time: 1654688520000,
			lowest: 148.5,
			highest: 148.63,
			volumePrice: 148.5626,
			transactionCount: 98,
			voluemPrice: 148.5626
		},
		{
			open: 148.58,
			close: 148.58,
			volume: 296,
			time: 1654688580000,
			lowest: 148.58,
			highest: 148.58,
			volumePrice: 148.5828,
			transactionCount: 15,
			voluemPrice: 148.5828
		},
		{
			open: 148.55,
			close: 148.56,
			volume: 1584,
			time: 1654688640000,
			lowest: 148.55,
			highest: 148.56,
			volumePrice: 148.5602,
			transactionCount: 34,
			voluemPrice: 148.5602
		},
		{
			open: 148.51,
			close: 148.51,
			volume: 391,
			time: 1654688700000,
			lowest: 148.51,
			highest: 148.51,
			volumePrice: 148.522,
			transactionCount: 17,
			voluemPrice: 148.522
		},
		{
			open: 148.53,
			close: 148.46,
			volume: 4957,
			time: 1654688760000,
			lowest: 148.46,
			highest: 148.55,
			volumePrice: 148.5186,
			transactionCount: 79,
			voluemPrice: 148.5186
		},
		{
			open: 148.48,
			close: 148.52,
			volume: 680,
			time: 1654688820000,
			lowest: 148.48,
			highest: 148.52,
			volumePrice: 148.5094,
			transactionCount: 21,
			voluemPrice: 148.5094
		},
		{
			open: 148.51,
			close: 148.51,
			volume: 817,
			time: 1654688880000,
			lowest: 148.51,
			highest: 148.51,
			volumePrice: 148.529,
			transactionCount: 23,
			voluemPrice: 148.529
		},
		{
			open: 148.52,
			close: 148.48,
			volume: 2189,
			time: 1654688940000,
			lowest: 148.48,
			highest: 148.52,
			volumePrice: 148.4891,
			transactionCount: 49,
			voluemPrice: 148.4891
		},
		{
			open: 148.42,
			close: 148.41,
			volume: 3375,
			time: 1654689000000,
			lowest: 148.37,
			highest: 148.43,
			volumePrice: 148.4114,
			transactionCount: 65,
			voluemPrice: 148.4114
		},
		{
			open: 148.41,
			close: 148.4,
			volume: 1574,
			time: 1654689060000,
			lowest: 148.4,
			highest: 148.41,
			volumePrice: 148.4045,
			transactionCount: 32,
			voluemPrice: 148.4045
		},
		{
			open: 148.41,
			close: 148.42,
			volume: 804,
			time: 1654689120000,
			lowest: 148.41,
			highest: 148.42,
			volumePrice: 148.4321,
			transactionCount: 22,
			voluemPrice: 148.4321
		},
		{
			open: 148.42,
			close: 148.38,
			volume: 1366,
			time: 1654689180000,
			lowest: 148.37,
			highest: 148.42,
			volumePrice: 148.4027,
			transactionCount: 23,
			voluemPrice: 148.4027
		},
		{
			open: 148.42,
			close: 148.36,
			volume: 1983,
			time: 1654689240000,
			lowest: 148.36,
			highest: 148.42,
			volumePrice: 148.3769,
			transactionCount: 56,
			voluemPrice: 148.3769
		},
		{
			open: 148.36,
			close: 148.35,
			volume: 1446,
			time: 1654689300000,
			lowest: 148.35,
			highest: 148.36,
			volumePrice: 148.354,
			transactionCount: 23,
			voluemPrice: 148.354
		},
		{
			open: 148.37,
			close: 148.41,
			volume: 1283,
			time: 1654689360000,
			lowest: 148.37,
			highest: 148.41,
			volumePrice: 148.3998,
			transactionCount: 29,
			voluemPrice: 148.3998
		},
		{
			open: 148.42,
			close: 148.42,
			volume: 561,
			time: 1654689420000,
			lowest: 148.42,
			highest: 148.42,
			volumePrice: 148.4346,
			transactionCount: 22,
			voluemPrice: 148.4346
		},
		{
			open: 148.46,
			close: 148.45,
			volume: 1152,
			time: 1654689540000,
			lowest: 148.45,
			highest: 148.46,
			volumePrice: 148.4494,
			transactionCount: 33,
			voluemPrice: 148.4494
		},
		{
			open: 148.2982,
			close: 148.41,
			volume: 208563,
			time: 1654689600000,
			lowest: 148.1112,
			highest: 148.8499,
			volumePrice: 148.528,
			transactionCount: 864,
			voluemPrice: 148.528
		},
		{
			open: 148.41,
			close: 148.26,
			volume: 11285,
			time: 1654689660000,
			lowest: 148.26,
			highest: 148.41,
			volumePrice: 148.3027,
			transactionCount: 345,
			voluemPrice: 148.3027
		},
		{
			open: 148.29,
			close: 148.25,
			volume: 10393,
			time: 1654689720000,
			lowest: 148.21,
			highest: 148.29,
			volumePrice: 148.2421,
			transactionCount: 126,
			voluemPrice: 148.2421
		},
		{
			open: 148.25,
			close: 148.2121,
			volume: 1153,
			time: 1654689780000,
			lowest: 148.2121,
			highest: 148.25,
			volumePrice: 148.2374,
			transactionCount: 39,
			voluemPrice: 148.2374
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 1428,
			time: 1654689900000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2988,
			transactionCount: 31,
			voluemPrice: 148.2988
		},
		{
			open: 148.32,
			close: 148.33,
			volume: 604,
			time: 1654690020000,
			lowest: 148.32,
			highest: 148.33,
			volumePrice: 148.3308,
			transactionCount: 27,
			voluemPrice: 148.3308
		},
		{
			open: 148.38,
			close: 148.4,
			volume: 1224,
			time: 1654690080000,
			lowest: 148.38,
			highest: 148.4,
			volumePrice: 148.3751,
			transactionCount: 34,
			voluemPrice: 148.3751
		},
		{
			open: 148.4,
			close: 148.4,
			volume: 452,
			time: 1654690140000,
			lowest: 148.4,
			highest: 148.4,
			volumePrice: 148.3993,
			transactionCount: 15,
			voluemPrice: 148.3993
		},
		{
			open: 148.38,
			close: 148.4,
			volume: 2956,
			time: 1654690200000,
			lowest: 148.38,
			highest: 148.41,
			volumePrice: 148.3995,
			transactionCount: 56,
			voluemPrice: 148.3995
		},
		{
			open: 148.4,
			close: 148.37,
			volume: 2181,
			time: 1654690260000,
			lowest: 148.37,
			highest: 148.4,
			volumePrice: 148.3974,
			transactionCount: 32,
			voluemPrice: 148.3974
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 740,
			time: 1654690320000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3532,
			transactionCount: 31,
			voluemPrice: 148.3532
		},
		{
			open: 148.35,
			close: 148.46,
			volume: 4695,
			time: 1654690380000,
			lowest: 148.35,
			highest: 148.46,
			volumePrice: 148.3841,
			transactionCount: 66,
			voluemPrice: 148.3841
		},
		{
			open: 148.42,
			close: 148.32,
			volume: 3545,
			time: 1654690500000,
			lowest: 148.32,
			highest: 148.42,
			volumePrice: 148.3998,
			transactionCount: 46,
			voluemPrice: 148.3998
		},
		{
			open: 148.32,
			close: 148.28,
			volume: 2521,
			time: 1654690560000,
			lowest: 148.28,
			highest: 148.32,
			volumePrice: 148.3002,
			transactionCount: 66,
			voluemPrice: 148.3002
		},
		{
			open: 148.31,
			close: 148.29,
			volume: 1236,
			time: 1654690620000,
			lowest: 148.29,
			highest: 148.31,
			volumePrice: 148.2977,
			transactionCount: 24,
			voluemPrice: 148.2977
		},
		{
			open: 148.3,
			close: 148.3,
			volume: 1009,
			time: 1654690680000,
			lowest: 148.3,
			highest: 148.3,
			volumePrice: 148.2979,
			transactionCount: 41,
			voluemPrice: 148.2979
		},
		{
			open: 148.32,
			close: 148.34,
			volume: 1101,
			time: 1654690740000,
			lowest: 148.32,
			highest: 148.34,
			volumePrice: 148.3294,
			transactionCount: 42,
			voluemPrice: 148.3294
		},
		{
			open: 148.36,
			close: 148.36,
			volume: 472,
			time: 1654690860000,
			lowest: 148.36,
			highest: 148.36,
			volumePrice: 148.3582,
			transactionCount: 21,
			voluemPrice: 148.3582
		},
		{
			open: 148.37,
			close: 148.37,
			volume: 271,
			time: 1654690920000,
			lowest: 148.37,
			highest: 148.37,
			volumePrice: 148.363,
			transactionCount: 19,
			voluemPrice: 148.363
		},
		{
			open: 148.36,
			close: 148.25,
			volume: 6942,
			time: 1654690980000,
			lowest: 148.25,
			highest: 148.4,
			volumePrice: 148.3509,
			transactionCount: 141,
			voluemPrice: 148.3509
		},
		{
			open: 148.26,
			close: 148.16,
			volume: 15263,
			time: 1654691040000,
			lowest: 148.11,
			highest: 148.26,
			volumePrice: 148.1909,
			transactionCount: 139,
			voluemPrice: 148.1909
		},
		{
			open: 148.16,
			close: 148.08,
			volume: 10824,
			time: 1654691100000,
			lowest: 148.08,
			highest: 148.16,
			volumePrice: 148.1232,
			transactionCount: 93,
			voluemPrice: 148.1232
		},
		{
			open: 148.09,
			close: 148.11,
			volume: 3300,
			time: 1654691160000,
			lowest: 148.06,
			highest: 148.12,
			volumePrice: 148.0928,
			transactionCount: 52,
			voluemPrice: 148.0928
		},
		{
			open: 148.23,
			close: 148.16,
			volume: 4129,
			time: 1654691220000,
			lowest: 148.15,
			highest: 148.23,
			volumePrice: 148.1604,
			transactionCount: 62,
			voluemPrice: 148.1604
		},
		{
			open: 148.12,
			close: 148.15,
			volume: 2777,
			time: 1654691280000,
			lowest: 148.12,
			highest: 148.16,
			volumePrice: 148.1435,
			transactionCount: 77,
			voluemPrice: 148.1435
		},
		{
			open: 148.18,
			close: 148.15,
			volume: 1160,
			time: 1654691340000,
			lowest: 148.15,
			highest: 148.18,
			volumePrice: 148.1584,
			transactionCount: 27,
			voluemPrice: 148.1584
		},
		{
			open: 148.17,
			close: 148.1,
			volume: 11334,
			time: 1654691400000,
			lowest: 148.09,
			highest: 148.17,
			volumePrice: 148.1134,
			transactionCount: 83,
			voluemPrice: 148.1134
		},
		{
			open: 148.07,
			close: 148.01,
			volume: 13139,
			time: 1654691460000,
			lowest: 148.01,
			highest: 148.09,
			volumePrice: 148.0655,
			transactionCount: 131,
			voluemPrice: 148.0655
		},
		{
			open: 148,
			close: 147.78,
			volume: 23134,
			time: 1654691520000,
			lowest: 147.75,
			highest: 148.02,
			volumePrice: 147.8943,
			transactionCount: 398,
			voluemPrice: 147.8943
		},
		{
			open: 147.82,
			close: 147.89,
			volume: 6958,
			time: 1654691580000,
			lowest: 147.82,
			highest: 147.89,
			volumePrice: 147.8798,
			transactionCount: 105,
			voluemPrice: 147.8798
		},
		{
			open: 147.87,
			close: 147.91,
			volume: 3928,
			time: 1654691640000,
			lowest: 147.87,
			highest: 147.95,
			volumePrice: 147.9203,
			transactionCount: 67,
			voluemPrice: 147.9203
		},
		{
			open: 147.9,
			close: 147.95,
			volume: 6834,
			time: 1654691700000,
			lowest: 147.86,
			highest: 147.95,
			volumePrice: 147.9287,
			transactionCount: 55,
			voluemPrice: 147.9287
		},
		{
			open: 148,
			close: 148.05,
			volume: 4648,
			time: 1654691760000,
			lowest: 148,
			highest: 148.05,
			volumePrice: 148.0231,
			transactionCount: 80,
			voluemPrice: 148.0231
		},
		{
			open: 148.07,
			close: 148.08,
			volume: 4472,
			time: 1654691820000,
			lowest: 148.05,
			highest: 148.08,
			volumePrice: 148.0626,
			transactionCount: 95,
			voluemPrice: 148.0626
		},
		{
			open: 148.07,
			close: 148.04,
			volume: 2355,
			time: 1654691880000,
			lowest: 148.04,
			highest: 148.08,
			volumePrice: 148.0673,
			transactionCount: 54,
			voluemPrice: 148.0673
		},
		{
			open: 148.03,
			close: 148.12,
			volume: 11141,
			time: 1654691940000,
			lowest: 148.03,
			highest: 148.12,
			volumePrice: 148.0821,
			transactionCount: 48,
			voluemPrice: 148.0821
		},
		{
			open: 148.09,
			close: 148.14,
			volume: 7869,
			time: 1654692000000,
			lowest: 148.09,
			highest: 148.14,
			volumePrice: 148.101,
			transactionCount: 69,
			voluemPrice: 148.101
		},
		{
			open: 148.1,
			close: 147.97,
			volume: 3771,
			time: 1654692060000,
			lowest: 147.97,
			highest: 148.1,
			volumePrice: 148.0253,
			transactionCount: 127,
			voluemPrice: 148.0253
		},
		{
			open: 147.95,
			close: 148,
			volume: 1107,
			time: 1654692120000,
			lowest: 147.95,
			highest: 148,
			volumePrice: 147.991,
			transactionCount: 49,
			voluemPrice: 147.991
		},
		{
			open: 148.01,
			close: 148.06,
			volume: 850,
			time: 1654692180000,
			lowest: 148.01,
			highest: 148.06,
			volumePrice: 148.0517,
			transactionCount: 27,
			voluemPrice: 148.0517
		},
		{
			open: 148.13,
			close: 148.23,
			volume: 14625,
			time: 1654692240000,
			lowest: 148.13,
			highest: 148.27,
			volumePrice: 148.172,
			transactionCount: 93,
			voluemPrice: 148.172
		},
		{
			open: 148.21,
			close: 148.17,
			volume: 2741,
			time: 1654692300000,
			lowest: 148.16,
			highest: 148.21,
			volumePrice: 148.181,
			transactionCount: 80,
			voluemPrice: 148.181
		},
		{
			open: 148.15,
			close: 148.18,
			volume: 6896,
			time: 1654692360000,
			lowest: 148.15,
			highest: 148.18,
			volumePrice: 148.1711,
			transactionCount: 39,
			voluemPrice: 148.1711
		},
		{
			open: 148.18,
			close: 148.16,
			volume: 323,
			time: 1654692420000,
			lowest: 148.16,
			highest: 148.18,
			volumePrice: 148.1711,
			transactionCount: 24,
			voluemPrice: 148.1711
		},
		{
			open: 148.15,
			close: 148.21,
			volume: 1391,
			time: 1654692480000,
			lowest: 148.15,
			highest: 148.21,
			volumePrice: 148.1843,
			transactionCount: 45,
			voluemPrice: 148.1843
		},
		{
			open: 148.22,
			close: 148.24,
			volume: 3005,
			time: 1654692540000,
			lowest: 148.2,
			highest: 148.24,
			volumePrice: 148.2193,
			transactionCount: 83,
			voluemPrice: 148.2193
		},
		{
			open: 148.2,
			close: 148.25,
			volume: 3239,
			time: 1654692600000,
			lowest: 148.2,
			highest: 148.25,
			volumePrice: 148.2466,
			transactionCount: 42,
			voluemPrice: 148.2466
		},
		{
			open: 148.28,
			close: 148.36,
			volume: 7619,
			time: 1654692660000,
			lowest: 148.28,
			highest: 148.37,
			volumePrice: 148.345,
			transactionCount: 99,
			voluemPrice: 148.345
		},
		{
			open: 148.37,
			close: 148.34,
			volume: 20853,
			time: 1654692720000,
			lowest: 148.34,
			highest: 148.4,
			volumePrice: 148.3609,
			transactionCount: 123,
			voluemPrice: 148.3609
		},
		{
			open: 148.36,
			close: 148.3,
			volume: 2072,
			time: 1654692780000,
			lowest: 148.3,
			highest: 148.36,
			volumePrice: 148.3365,
			transactionCount: 37,
			voluemPrice: 148.3365
		},
		{
			open: 148.3,
			close: 148.22,
			volume: 2927,
			time: 1654692840000,
			lowest: 148.2,
			highest: 148.3,
			volumePrice: 148.2383,
			transactionCount: 103,
			voluemPrice: 148.2383
		},
		{
			open: 148.23,
			close: 148.21,
			volume: 12055,
			time: 1654692900000,
			lowest: 148.17,
			highest: 148.24,
			volumePrice: 148.2241,
			transactionCount: 74,
			voluemPrice: 148.2241
		},
		{
			open: 148.27,
			close: 148.3,
			volume: 4376,
			time: 1654692960000,
			lowest: 148.27,
			highest: 148.3,
			volumePrice: 148.2784,
			transactionCount: 60,
			voluemPrice: 148.2784
		},
		{
			open: 148.34,
			close: 148.34,
			volume: 692,
			time: 1654693020000,
			lowest: 148.34,
			highest: 148.34,
			volumePrice: 148.3442,
			transactionCount: 37,
			voluemPrice: 148.3442
		},
		{
			open: 148.27,
			close: 148.27,
			volume: 7767,
			time: 1654693080000,
			lowest: 148.27,
			highest: 148.3,
			volumePrice: 148.2941,
			transactionCount: 57,
			voluemPrice: 148.2941
		},
		{
			open: 148.27,
			close: 148.25,
			volume: 3065,
			time: 1654693140000,
			lowest: 148.22,
			highest: 148.28,
			volumePrice: 148.2667,
			transactionCount: 66,
			voluemPrice: 148.2667
		},
		{
			open: 148.25,
			close: 148.16,
			volume: 13625,
			time: 1654693200000,
			lowest: 148.16,
			highest: 148.25,
			volumePrice: 148.2047,
			transactionCount: 77,
			voluemPrice: 148.2047
		},
		{
			open: 148.14,
			close: 148.18,
			volume: 3823,
			time: 1654693260000,
			lowest: 148.13,
			highest: 148.2,
			volumePrice: 148.1631,
			transactionCount: 118,
			voluemPrice: 148.1631
		},
		{
			open: 148.17,
			close: 148.16,
			volume: 2190,
			time: 1654693320000,
			lowest: 148.15,
			highest: 148.18,
			volumePrice: 148.165,
			transactionCount: 61,
			voluemPrice: 148.165
		},
		{
			open: 148.17,
			close: 148,
			volume: 5057,
			time: 1654693380000,
			lowest: 148,
			highest: 148.18,
			volumePrice: 148.0971,
			transactionCount: 109,
			voluemPrice: 148.0971
		},
		{
			open: 148,
			close: 148.09,
			volume: 9141,
			time: 1654693440000,
			lowest: 148,
			highest: 148.09,
			volumePrice: 148.0622,
			transactionCount: 106,
			voluemPrice: 148.0622
		},
		{
			open: 148.08,
			close: 148.15,
			volume: 1141,
			time: 1654693500000,
			lowest: 148.08,
			highest: 148.15,
			volumePrice: 148.0992,
			transactionCount: 42,
			voluemPrice: 148.0992
		},
		{
			open: 148.15,
			close: 148.15,
			volume: 1385,
			time: 1654693560000,
			lowest: 148.11,
			highest: 148.15,
			volumePrice: 148.135,
			transactionCount: 49,
			voluemPrice: 148.135
		},
		{
			open: 148.15,
			close: 148.14,
			volume: 5158,
			time: 1654693620000,
			lowest: 148.14,
			highest: 148.15,
			volumePrice: 148.1473,
			transactionCount: 61,
			voluemPrice: 148.1473
		},
		{
			open: 148.18,
			close: 148.28,
			volume: 8368,
			time: 1654693680000,
			lowest: 148.18,
			highest: 148.28,
			volumePrice: 148.1917,
			transactionCount: 70,
			voluemPrice: 148.1917
		},
		{
			open: 148.29,
			close: 148.28,
			volume: 7777,
			time: 1654693740000,
			lowest: 148.27,
			highest: 148.29,
			volumePrice: 148.2708,
			transactionCount: 43,
			voluemPrice: 148.2708
		},
		{
			open: 148.27,
			close: 148.16,
			volume: 8420,
			time: 1654693800000,
			lowest: 148.16,
			highest: 148.27,
			volumePrice: 148.2285,
			transactionCount: 92,
			voluemPrice: 148.2285
		},
		{
			open: 148.25,
			close: 148.28,
			volume: 5996,
			time: 1654693860000,
			lowest: 148.23,
			highest: 148.28,
			volumePrice: 148.2763,
			transactionCount: 38,
			voluemPrice: 148.2763
		},
		{
			open: 148.28,
			close: 148.29,
			volume: 3486,
			time: 1654693920000,
			lowest: 148.26,
			highest: 148.29,
			volumePrice: 148.2833,
			transactionCount: 64,
			voluemPrice: 148.2833
		},
		{
			open: 148.29,
			close: 148.36,
			volume: 37795,
			time: 1654693980000,
			lowest: 148.27,
			highest: 148.36,
			volumePrice: 148.2846,
			transactionCount: 114,
			voluemPrice: 148.2846
		},
		{
			open: 148.33,
			close: 148.28,
			volume: 5553,
			time: 1654694040000,
			lowest: 148.28,
			highest: 148.33,
			volumePrice: 148.3191,
			transactionCount: 95,
			voluemPrice: 148.3191
		},
		{
			open: 148.3,
			close: 148.24,
			volume: 11746,
			time: 1654694100000,
			lowest: 148.21,
			highest: 148.3,
			volumePrice: 148.22,
			transactionCount: 148,
			voluemPrice: 148.22
		},
		{
			open: 148.24,
			close: 148.23,
			volume: 2041,
			time: 1654694160000,
			lowest: 148.23,
			highest: 148.24,
			volumePrice: 148.2411,
			transactionCount: 28,
			voluemPrice: 148.2411
		},
		{
			open: 148.2,
			close: 148.17,
			volume: 11907,
			time: 1654694220000,
			lowest: 148.159,
			highest: 148.2,
			volumePrice: 148.1664,
			transactionCount: 74,
			voluemPrice: 148.1664
		},
		{
			open: 148.21,
			close: 148.17,
			volume: 6547,
			time: 1654694280000,
			lowest: 148.17,
			highest: 148.21,
			volumePrice: 148.1732,
			transactionCount: 66,
			voluemPrice: 148.1732
		},
		{
			open: 148.17,
			close: 148.17,
			volume: 1611,
			time: 1654694340000,
			lowest: 148.17,
			highest: 148.17,
			volumePrice: 148.1612,
			transactionCount: 40,
			voluemPrice: 148.1612
		},
		{
			open: 148.17,
			close: 148.17,
			volume: 3934,
			time: 1654694400000,
			lowest: 148.15,
			highest: 148.17,
			volumePrice: 148.1706,
			transactionCount: 42,
			voluemPrice: 148.1706
		},
		{
			open: 148.18,
			close: 148.2,
			volume: 4478,
			time: 1654694460000,
			lowest: 148.15,
			highest: 148.2,
			volumePrice: 148.1836,
			transactionCount: 56,
			voluemPrice: 148.1836
		},
		{
			open: 148.19,
			close: 148.15,
			volume: 3672,
			time: 1654694520000,
			lowest: 148.15,
			highest: 148.19,
			volumePrice: 148.1816,
			transactionCount: 24,
			voluemPrice: 148.1816
		},
		{
			open: 148.19,
			close: 148.22,
			volume: 2751,
			time: 1654694580000,
			lowest: 148.19,
			highest: 148.22,
			volumePrice: 148.2096,
			transactionCount: 51,
			voluemPrice: 148.2096
		},
		{
			open: 148.23,
			close: 148.21,
			volume: 1246,
			time: 1654694640000,
			lowest: 148.21,
			highest: 148.23,
			volumePrice: 148.2209,
			transactionCount: 39,
			voluemPrice: 148.2209
		},
		{
			open: 148.33,
			close: 148.31,
			volume: 7123,
			time: 1654694700000,
			lowest: 148.3,
			highest: 148.43,
			volumePrice: 148.3488,
			transactionCount: 94,
			voluemPrice: 148.3488
		},
		{
			open: 148.32,
			close: 148.3,
			volume: 7194,
			time: 1654694760000,
			lowest: 148.25,
			highest: 148.32,
			volumePrice: 148.2755,
			transactionCount: 81,
			voluemPrice: 148.2755
		},
		{
			open: 148.29,
			close: 148.64,
			volume: 13383,
			time: 1654694820000,
			lowest: 148.29,
			highest: 148.64,
			volumePrice: 148.4157,
			transactionCount: 152,
			voluemPrice: 148.4157
		},
		{
			open: 148.58,
			close: 148.55,
			volume: 13235,
			time: 1654694880000,
			lowest: 148.48,
			highest: 148.66,
			volumePrice: 148.5702,
			transactionCount: 124,
			voluemPrice: 148.5702
		},
		{
			open: 148.53,
			close: 148.51,
			volume: 9843,
			time: 1654694940000,
			lowest: 148.5,
			highest: 148.57,
			volumePrice: 148.5346,
			transactionCount: 141,
			voluemPrice: 148.5346
		},
		{
			open: 148.58,
			close: 148.5298,
			volume: 1315170,
			time: 1654695000000,
			lowest: 148.51,
			highest: 148.81,
			volumePrice: 148.6361,
			transactionCount: 13161,
			voluemPrice: 148.6361
		},
		{
			open: 148.52,
			close: 148.48,
			volume: 355386,
			time: 1654695060000,
			lowest: 148.3,
			highest: 148.6,
			volumePrice: 148.4507,
			transactionCount: 3456,
			voluemPrice: 148.4507
		},
		{
			open: 148.47,
			close: 148.4947,
			volume: 308836,
			time: 1654695120000,
			lowest: 148.1106,
			highest: 148.5,
			volumePrice: 148.301,
			transactionCount: 2967,
			voluemPrice: 148.301
		},
		{
			open: 148.49,
			close: 148.935,
			volume: 360329,
			time: 1654695180000,
			lowest: 148.45,
			highest: 148.9582,
			volumePrice: 148.7713,
			transactionCount: 3013,
			voluemPrice: 148.7713
		},
		{
			open: 148.94,
			close: 149.02,
			volume: 495283,
			time: 1654695240000,
			lowest: 148.85,
			highest: 149.15,
			volumePrice: 149.0382,
			transactionCount: 3926,
			voluemPrice: 149.0382
		},
		{
			open: 149.015,
			close: 149.0245,
			volume: 450554,
			time: 1654695300000,
			lowest: 148.93,
			highest: 149.33,
			volumePrice: 149.1582,
			transactionCount: 3857,
			voluemPrice: 149.1582
		},
		{
			open: 149.03,
			close: 149.345,
			volume: 396925,
			time: 1654695360000,
			lowest: 149.02,
			highest: 149.41,
			volumePrice: 149.27,
			transactionCount: 3240,
			voluemPrice: 149.27
		},
		{
			open: 149.349,
			close: 149.27,
			volume: 259859,
			time: 1654695420000,
			lowest: 149.26,
			highest: 149.4299,
			volumePrice: 149.3428,
			transactionCount: 2191,
			voluemPrice: 149.3428
		},
		{
			open: 149.27,
			close: 149.32,
			volume: 279210,
			time: 1654695480000,
			lowest: 149.05,
			highest: 149.32,
			volumePrice: 149.2125,
			transactionCount: 2277,
			voluemPrice: 149.2125
		},
		{
			open: 149.31,
			close: 149.4701,
			volume: 431116,
			time: 1654695540000,
			lowest: 149.23,
			highest: 149.62,
			volumePrice: 149.4458,
			transactionCount: 3163,
			voluemPrice: 149.4458
		},
		{
			open: 149.4794,
			close: 149.2187,
			volume: 204341,
			time: 1654695600000,
			lowest: 149.16,
			highest: 149.52,
			volumePrice: 149.2842,
			transactionCount: 2129,
			voluemPrice: 149.2842
		},
		{
			open: 149.22,
			close: 149.2211,
			volume: 218848,
			time: 1654695660000,
			lowest: 149.18,
			highest: 149.43,
			volumePrice: 149.2981,
			transactionCount: 2104,
			voluemPrice: 149.2981
		},
		{
			open: 149.23,
			close: 149.34,
			volume: 289109,
			time: 1654695720000,
			lowest: 149.03,
			highest: 149.47,
			volumePrice: 149.2292,
			transactionCount: 2513,
			voluemPrice: 149.2292
		},
		{
			open: 149.3399,
			close: 149.2297,
			volume: 191749,
			time: 1654695780000,
			lowest: 149.2,
			highest: 149.3793,
			volumePrice: 149.2644,
			transactionCount: 1738,
			voluemPrice: 149.2644
		},
		{
			open: 149.23,
			close: 149.11,
			volume: 179905,
			time: 1654695840000,
			lowest: 149.08,
			highest: 149.25,
			volumePrice: 149.1527,
			transactionCount: 1608,
			voluemPrice: 149.1527
		},
		{
			open: 149.11,
			close: 149.15,
			volume: 226958,
			time: 1654695900000,
			lowest: 149.06,
			highest: 149.3499,
			volumePrice: 149.239,
			transactionCount: 1898,
			voluemPrice: 149.239
		},
		{
			open: 149.16,
			close: 149.2,
			volume: 185832,
			time: 1654695960000,
			lowest: 149.01,
			highest: 149.24,
			volumePrice: 149.1293,
			transactionCount: 1806,
			voluemPrice: 149.1293
		},
		{
			open: 149.195,
			close: 149.11,
			volume: 289532,
			time: 1654696020000,
			lowest: 148.94,
			highest: 149.275,
			volumePrice: 149.1079,
			transactionCount: 2469,
			voluemPrice: 149.1079
		},
		{
			open: 149.11,
			close: 149.35,
			volume: 255180,
			time: 1654696080000,
			lowest: 149.09,
			highest: 149.37,
			volumePrice: 149.235,
			transactionCount: 2130,
			voluemPrice: 149.235
		},
		{
			open: 149.3402,
			close: 149.59,
			volume: 436860,
			time: 1654696140000,
			lowest: 149.3402,
			highest: 149.83,
			volumePrice: 149.6425,
			transactionCount: 3508,
			voluemPrice: 149.6425
		},
		{
			open: 149.6,
			close: 149.54,
			volume: 283106,
			time: 1654696200000,
			lowest: 149.53,
			highest: 149.8,
			volumePrice: 149.6678,
			transactionCount: 2180,
			voluemPrice: 149.6678
		},
		{
			open: 149.55,
			close: 149.54,
			volume: 204512,
			time: 1654696260000,
			lowest: 149.455,
			highest: 149.57,
			volumePrice: 149.5092,
			transactionCount: 1713,
			voluemPrice: 149.5092
		},
		{
			open: 149.54,
			close: 149.58,
			volume: 212195,
			time: 1654696320000,
			lowest: 149.5,
			highest: 149.6264,
			volumePrice: 149.5763,
			transactionCount: 1827,
			voluemPrice: 149.5763
		},
		{
			open: 149.5741,
			close: 149.46,
			volume: 260704,
			time: 1654696380000,
			lowest: 149.39,
			highest: 149.615,
			volumePrice: 149.5009,
			transactionCount: 1916,
			voluemPrice: 149.5009
		},
		{
			open: 149.48,
			close: 149.65,
			volume: 173551,
			time: 1654696440000,
			lowest: 149.44,
			highest: 149.67,
			volumePrice: 149.5519,
			transactionCount: 1642,
			voluemPrice: 149.5519
		},
		{
			open: 149.6652,
			close: 149.75,
			volume: 292921,
			time: 1654696500000,
			lowest: 149.625,
			highest: 149.78,
			volumePrice: 149.7058,
			transactionCount: 2297,
			voluemPrice: 149.7058
		},
		{
			open: 149.7558,
			close: 149.7093,
			volume: 346631,
			time: 1654696560000,
			lowest: 148.58,
			highest: 149.8697,
			volumePrice: 149.3486,
			transactionCount: 2102,
			voluemPrice: 149.3486
		},
		{
			open: 149.71,
			close: 149.64,
			volume: 210634,
			time: 1654696620000,
			lowest: 149.56,
			highest: 149.82,
			volumePrice: 149.6892,
			transactionCount: 1696,
			voluemPrice: 149.6892
		},
		{
			open: 149.645,
			close: 149.62,
			volume: 153353,
			time: 1654696680000,
			lowest: 149.5123,
			highest: 149.705,
			volumePrice: 149.622,
			transactionCount: 1284,
			voluemPrice: 149.622
		},
		{
			open: 149.6291,
			close: 149.73,
			volume: 316673,
			time: 1654696740000,
			lowest: 149.61,
			highest: 149.8,
			volumePrice: 149.7218,
			transactionCount: 2815,
			voluemPrice: 149.7218
		},
		{
			open: 149.73,
			close: 149.43,
			volume: 197836,
			time: 1654696800000,
			lowest: 149.42,
			highest: 149.77,
			volumePrice: 149.5789,
			transactionCount: 2037,
			voluemPrice: 149.5789
		},
		{
			open: 149.4201,
			close: 149.12,
			volume: 333636,
			time: 1654696860000,
			lowest: 149.0434,
			highest: 149.4397,
			volumePrice: 149.1961,
			transactionCount: 2332,
			voluemPrice: 149.1961
		},
		{
			open: 149.11,
			close: 149.22,
			volume: 186483,
			time: 1654696920000,
			lowest: 149.08,
			highest: 149.26,
			volumePrice: 149.1797,
			transactionCount: 1461,
			voluemPrice: 149.1797
		},
		{
			open: 149.225,
			close: 149.4299,
			volume: 153930,
			time: 1654696980000,
			lowest: 149.2,
			highest: 149.45,
			volumePrice: 149.3398,
			transactionCount: 1286,
			voluemPrice: 149.3398
		},
		{
			open: 149.43,
			close: 149.595,
			volume: 185901,
			time: 1654697040000,
			lowest: 149.385,
			highest: 149.6,
			volumePrice: 149.4818,
			transactionCount: 1408,
			voluemPrice: 149.4818
		},
		{
			open: 149.59,
			close: 149.55,
			volume: 145337,
			time: 1654697100000,
			lowest: 149.39,
			highest: 149.615,
			volumePrice: 149.4947,
			transactionCount: 1207,
			voluemPrice: 149.4947
		},
		{
			open: 149.55,
			close: 149.5191,
			volume: 141530,
			time: 1654697160000,
			lowest: 149.42,
			highest: 149.655,
			volumePrice: 149.5356,
			transactionCount: 1287,
			voluemPrice: 149.5356
		},
		{
			open: 149.52,
			close: 149.47,
			volume: 150799,
			time: 1654697220000,
			lowest: 149.4499,
			highest: 149.6205,
			volumePrice: 149.5397,
			transactionCount: 1367,
			voluemPrice: 149.5397
		},
		{
			open: 149.48,
			close: 149.6056,
			volume: 109608,
			time: 1654697280000,
			lowest: 149.4,
			highest: 149.6056,
			volumePrice: 149.5195,
			transactionCount: 1217,
			voluemPrice: 149.5195
		},
		{
			open: 149.59,
			close: 149.665,
			volume: 138375,
			time: 1654697340000,
			lowest: 149.56,
			highest: 149.7289,
			volumePrice: 149.6477,
			transactionCount: 1281,
			voluemPrice: 149.6477
		},
		{
			open: 149.6614,
			close: 149.565,
			volume: 158254,
			time: 1654697400000,
			lowest: 149.4501,
			highest: 149.685,
			volumePrice: 149.5555,
			transactionCount: 1584,
			voluemPrice: 149.5555
		},
		{
			open: 149.575,
			close: 149.53,
			volume: 167996,
			time: 1654697460000,
			lowest: 149.37,
			highest: 149.6095,
			volumePrice: 149.4862,
			transactionCount: 1648,
			voluemPrice: 149.4862
		},
		{
			open: 149.54,
			close: 149.4499,
			volume: 99027,
			time: 1654697520000,
			lowest: 149.4309,
			highest: 149.56,
			volumePrice: 149.5018,
			transactionCount: 991,
			voluemPrice: 149.5018
		},
		{
			open: 149.44,
			close: 149.2899,
			volume: 146803,
			time: 1654697580000,
			lowest: 149.2689,
			highest: 149.515,
			volumePrice: 149.3621,
			transactionCount: 1347,
			voluemPrice: 149.3621
		},
		{
			open: 149.29,
			close: 148.78,
			volume: 386298,
			time: 1654697640000,
			lowest: 148.7,
			highest: 149.3014,
			volumePrice: 148.933,
			transactionCount: 3155,
			voluemPrice: 148.933
		},
		{
			open: 148.7741,
			close: 148.795,
			volume: 270233,
			time: 1654697700000,
			lowest: 148.57,
			highest: 148.82,
			volumePrice: 148.7068,
			transactionCount: 2213,
			voluemPrice: 148.7068
		},
		{
			open: 148.81,
			close: 148.81,
			volume: 170102,
			time: 1654697760000,
			lowest: 148.7719,
			highest: 148.89,
			volumePrice: 148.8236,
			transactionCount: 1343,
			voluemPrice: 148.8236
		},
		{
			open: 148.82,
			close: 148.88,
			volume: 185469,
			time: 1654697820000,
			lowest: 148.7222,
			highest: 148.905,
			volumePrice: 148.8546,
			transactionCount: 1253,
			voluemPrice: 148.8546
		},
		{
			open: 148.89,
			close: 148.925,
			volume: 179230,
			time: 1654697880000,
			lowest: 148.82,
			highest: 148.93,
			volumePrice: 148.894,
			transactionCount: 1246,
			voluemPrice: 148.894
		},
		{
			open: 148.93,
			close: 149.22,
			volume: 239442,
			time: 1654697940000,
			lowest: 148.88,
			highest: 149.23,
			volumePrice: 149.0813,
			transactionCount: 1840,
			voluemPrice: 149.0813
		},
		{
			open: 149.225,
			close: 149.41,
			volume: 251932,
			time: 1654698000000,
			lowest: 149.225,
			highest: 149.45,
			volumePrice: 149.3743,
			transactionCount: 1986,
			voluemPrice: 149.3743
		},
		{
			open: 149.41,
			close: 149.5701,
			volume: 217004,
			time: 1654698060000,
			lowest: 149.4,
			highest: 149.5899,
			volumePrice: 149.5159,
			transactionCount: 1650,
			voluemPrice: 149.5159
		},
		{
			open: 149.575,
			close: 149.62,
			volume: 166219,
			time: 1654698120000,
			lowest: 149.48,
			highest: 149.7,
			volumePrice: 149.5527,
			transactionCount: 1559,
			voluemPrice: 149.5527
		},
		{
			open: 149.63,
			close: 149.589,
			volume: 184045,
			time: 1654698180000,
			lowest: 149.45,
			highest: 149.6624,
			volumePrice: 149.5546,
			transactionCount: 1480,
			voluemPrice: 149.5546
		},
		{
			open: 149.59,
			close: 149.725,
			volume: 180169,
			time: 1654698240000,
			lowest: 149.51,
			highest: 149.73,
			volumePrice: 149.627,
			transactionCount: 1257,
			voluemPrice: 149.627
		},
		{
			open: 149.72,
			close: 149.571,
			volume: 178847,
			time: 1654698300000,
			lowest: 149.5,
			highest: 149.74,
			volumePrice: 149.6105,
			transactionCount: 1418,
			voluemPrice: 149.6105
		},
		{
			open: 149.5846,
			close: 149.47,
			volume: 130222,
			time: 1654698360000,
			lowest: 149.44,
			highest: 149.64,
			volumePrice: 149.5594,
			transactionCount: 1099,
			voluemPrice: 149.5594
		},
		{
			open: 149.47,
			close: 149.445,
			volume: 121067,
			time: 1654698420000,
			lowest: 149.37,
			highest: 149.55,
			volumePrice: 149.4657,
			transactionCount: 1061,
			voluemPrice: 149.4657
		},
		{
			open: 149.43,
			close: 149.36,
			volume: 126420,
			time: 1654698480000,
			lowest: 149.33,
			highest: 149.47,
			volumePrice: 149.417,
			transactionCount: 1111,
			voluemPrice: 149.417
		},
		{
			open: 149.36,
			close: 149.13,
			volume: 183007,
			time: 1654698540000,
			lowest: 149.13,
			highest: 149.425,
			volumePrice: 149.2796,
			transactionCount: 1300,
			voluemPrice: 149.2796
		},
		{
			open: 149.14,
			close: 149.374,
			volume: 212113,
			time: 1654698600000,
			lowest: 149.06,
			highest: 149.4,
			volumePrice: 149.2223,
			transactionCount: 1658,
			voluemPrice: 149.2223
		},
		{
			open: 149.3691,
			close: 149.2713,
			volume: 138880,
			time: 1654698660000,
			lowest: 149.215,
			highest: 149.41,
			volumePrice: 149.3026,
			transactionCount: 1061,
			voluemPrice: 149.3026
		},
		{
			open: 149.2797,
			close: 149.3801,
			volume: 124672,
			time: 1654698720000,
			lowest: 149.24,
			highest: 149.43,
			volumePrice: 149.3316,
			transactionCount: 1101,
			voluemPrice: 149.3316
		},
		{
			open: 149.39,
			close: 149.28,
			volume: 95207,
			time: 1654698780000,
			lowest: 149.28,
			highest: 149.45,
			volumePrice: 149.3386,
			transactionCount: 819,
			voluemPrice: 149.3386
		},
		{
			open: 149.26,
			close: 149.32,
			volume: 142794,
			time: 1654698840000,
			lowest: 149.24,
			highest: 149.4914,
			volumePrice: 149.3687,
			transactionCount: 1044,
			voluemPrice: 149.3687
		},
		{
			open: 149.32,
			close: 149.3959,
			volume: 143182,
			time: 1654698900000,
			lowest: 149.32,
			highest: 149.46,
			volumePrice: 149.4038,
			transactionCount: 1318,
			voluemPrice: 149.4038
		},
		{
			open: 149.4,
			close: 149.39,
			volume: 138247,
			time: 1654698960000,
			lowest: 149.3766,
			highest: 149.52,
			volumePrice: 149.4465,
			transactionCount: 1071,
			voluemPrice: 149.4465
		},
		{
			open: 149.38,
			close: 149.29,
			volume: 167474,
			time: 1654699020000,
			lowest: 149.16,
			highest: 149.43,
			volumePrice: 149.2782,
			transactionCount: 1574,
			voluemPrice: 149.2782
		},
		{
			open: 149.2825,
			close: 149.34,
			volume: 102885,
			time: 1654699080000,
			lowest: 149.27,
			highest: 149.42,
			volumePrice: 149.3552,
			transactionCount: 957,
			voluemPrice: 149.3552
		},
		{
			open: 149.33,
			close: 149.305,
			volume: 53276,
			time: 1654699140000,
			lowest: 149.305,
			highest: 149.4009,
			volumePrice: 149.3502,
			transactionCount: 712,
			voluemPrice: 149.3502
		},
		{
			open: 149.305,
			close: 149.375,
			volume: 83654,
			time: 1654699200000,
			lowest: 149.29,
			highest: 149.402,
			volumePrice: 149.3551,
			transactionCount: 939,
			voluemPrice: 149.3551
		},
		{
			open: 149.37,
			close: 149.38,
			volume: 98218,
			time: 1654699260000,
			lowest: 149.37,
			highest: 149.47,
			volumePrice: 149.4278,
			transactionCount: 1015,
			voluemPrice: 149.4278
		},
		{
			open: 149.37,
			close: 149.135,
			volume: 157932,
			time: 1654699320000,
			lowest: 149.09,
			highest: 149.39,
			volumePrice: 149.2255,
			transactionCount: 1245,
			voluemPrice: 149.2255
		},
		{
			open: 149.13,
			close: 148.843,
			volume: 280882,
			time: 1654699380000,
			lowest: 148.73,
			highest: 149.2,
			volumePrice: 148.9317,
			transactionCount: 2332,
			voluemPrice: 148.9317
		},
		{
			open: 148.8566,
			close: 149.01,
			volume: 159044,
			time: 1654699440000,
			lowest: 148.8435,
			highest: 149.06,
			volumePrice: 148.9637,
			transactionCount: 1071,
			voluemPrice: 148.9637
		},
		{
			open: 149.02,
			close: 149.13,
			volume: 129411,
			time: 1654699500000,
			lowest: 149.02,
			highest: 149.17,
			volumePrice: 149.1001,
			transactionCount: 1011,
			voluemPrice: 149.1001
		},
		{
			open: 149.13,
			close: 149.205,
			volume: 125788,
			time: 1654699560000,
			lowest: 149.08,
			highest: 149.21,
			volumePrice: 149.1365,
			transactionCount: 951,
			voluemPrice: 149.1365
		},
		{
			open: 149.21,
			close: 149.21,
			volume: 92581,
			time: 1654699620000,
			lowest: 149.14,
			highest: 149.2499,
			volumePrice: 149.2157,
			transactionCount: 807,
			voluemPrice: 149.2157
		},
		{
			open: 149.21,
			close: 149.182,
			volume: 105754,
			time: 1654699680000,
			lowest: 149.025,
			highest: 149.21,
			volumePrice: 149.1219,
			transactionCount: 965,
			voluemPrice: 149.1219
		},
		{
			open: 149.17,
			close: 149.09,
			volume: 93632,
			time: 1654699740000,
			lowest: 149.045,
			highest: 149.18,
			volumePrice: 149.12,
			transactionCount: 837,
			voluemPrice: 149.12
		},
		{
			open: 149.09,
			close: 149.0896,
			volume: 110112,
			time: 1654699800000,
			lowest: 149.03,
			highest: 149.25,
			volumePrice: 149.1438,
			transactionCount: 987,
			voluemPrice: 149.1438
		},
		{
			open: 149.085,
			close: 149.1,
			volume: 123541,
			time: 1654699860000,
			lowest: 148.96,
			highest: 149.124,
			volumePrice: 149.042,
			transactionCount: 1072,
			voluemPrice: 149.042
		},
		{
			open: 149.1,
			close: 149.13,
			volume: 77568,
			time: 1654699920000,
			lowest: 148.99,
			highest: 149.13,
			volumePrice: 149.0677,
			transactionCount: 848,
			voluemPrice: 149.0677
		},
		{
			open: 149.13,
			close: 149.13,
			volume: 107548,
			time: 1654699980000,
			lowest: 149.04,
			highest: 149.195,
			volumePrice: 149.1364,
			transactionCount: 938,
			voluemPrice: 149.1364
		},
		{
			open: 149.1201,
			close: 149.19,
			volume: 103856,
			time: 1654700040000,
			lowest: 149.08,
			highest: 149.25,
			volumePrice: 149.1867,
			transactionCount: 992,
			voluemPrice: 149.1867
		},
		{
			open: 149.18,
			close: 149.0901,
			volume: 67127,
			time: 1654700100000,
			lowest: 149.09,
			highest: 149.22,
			volumePrice: 149.1531,
			transactionCount: 786,
			voluemPrice: 149.1531
		},
		{
			open: 149.1,
			close: 149.018,
			volume: 128634,
			time: 1654700160000,
			lowest: 149,
			highest: 149.15,
			volumePrice: 149.0545,
			transactionCount: 1225,
			voluemPrice: 149.0545
		},
		{
			open: 149.0101,
			close: 149.06,
			volume: 113823,
			time: 1654700220000,
			lowest: 149.01,
			highest: 149.16,
			volumePrice: 149.1077,
			transactionCount: 1012,
			voluemPrice: 149.1077
		},
		{
			open: 149.08,
			close: 148.914,
			volume: 172358,
			time: 1654700280000,
			lowest: 148.89,
			highest: 149.17,
			volumePrice: 149.0345,
			transactionCount: 1600,
			voluemPrice: 149.0345
		},
		{
			open: 148.9199,
			close: 149.01,
			volume: 153437,
			time: 1654700340000,
			lowest: 148.87,
			highest: 149.04,
			volumePrice: 148.9435,
			transactionCount: 1292,
			voluemPrice: 148.9435
		},
		{
			open: 149.01,
			close: 148.9221,
			volume: 119306,
			time: 1654700400000,
			lowest: 148.87,
			highest: 149.04,
			volumePrice: 148.9328,
			transactionCount: 1037,
			voluemPrice: 148.9328
		},
		{
			open: 148.92,
			close: 148.8127,
			volume: 181708,
			time: 1654700460000,
			lowest: 148.71,
			highest: 148.93,
			volumePrice: 148.8136,
			transactionCount: 1544,
			voluemPrice: 148.8136
		},
		{
			open: 148.82,
			close: 148.85,
			volume: 95855,
			time: 1654700520000,
			lowest: 148.77,
			highest: 148.9709,
			volumePrice: 148.8872,
			transactionCount: 872,
			voluemPrice: 148.8872
		},
		{
			open: 148.85,
			close: 148.8092,
			volume: 119063,
			time: 1654700580000,
			lowest: 148.77,
			highest: 148.92,
			volumePrice: 148.8375,
			transactionCount: 1028,
			voluemPrice: 148.8375
		},
		{
			open: 148.82,
			close: 148.79,
			volume: 92786,
			time: 1654700640000,
			lowest: 148.65,
			highest: 148.85,
			volumePrice: 148.777,
			transactionCount: 1029,
			voluemPrice: 148.777
		},
		{
			open: 148.78,
			close: 148.94,
			volume: 139700,
			time: 1654700700000,
			lowest: 148.78,
			highest: 148.99,
			volumePrice: 148.8915,
			transactionCount: 1136,
			voluemPrice: 148.8915
		},
		{
			open: 148.9353,
			close: 148.93,
			volume: 112207,
			time: 1654700760000,
			lowest: 148.89,
			highest: 149.04,
			volumePrice: 148.9616,
			transactionCount: 882,
			voluemPrice: 148.9616
		},
		{
			open: 148.9219,
			close: 148.82,
			volume: 104124,
			time: 1654700820000,
			lowest: 148.81,
			highest: 148.935,
			volumePrice: 148.8629,
			transactionCount: 1019,
			voluemPrice: 148.8629
		},
		{
			open: 148.81,
			close: 148.82,
			volume: 142307,
			time: 1654700880000,
			lowest: 148.75,
			highest: 148.86,
			volumePrice: 148.8044,
			transactionCount: 1205,
			voluemPrice: 148.8044
		},
		{
			open: 148.82,
			close: 148.935,
			volume: 68672,
			time: 1654700940000,
			lowest: 148.8,
			highest: 148.94,
			volumePrice: 148.8746,
			transactionCount: 709,
			voluemPrice: 148.8746
		},
		{
			open: 148.935,
			close: 149.08,
			volume: 132818,
			time: 1654701000000,
			lowest: 148.87,
			highest: 149.08,
			volumePrice: 148.9526,
			transactionCount: 1241,
			voluemPrice: 148.9526
		},
		{
			open: 149.0763,
			close: 149.1781,
			volume: 148473,
			time: 1654701060000,
			lowest: 149.075,
			highest: 149.26,
			volumePrice: 149.1744,
			transactionCount: 1270,
			voluemPrice: 149.1744
		},
		{
			open: 149.175,
			close: 149.24,
			volume: 117777,
			time: 1654701120000,
			lowest: 149.115,
			highest: 149.26,
			volumePrice: 149.1868,
			transactionCount: 860,
			voluemPrice: 149.1868
		},
		{
			open: 149.24,
			close: 149.3036,
			volume: 131728,
			time: 1654701180000,
			lowest: 149.19,
			highest: 149.32,
			volumePrice: 149.2274,
			transactionCount: 866,
			voluemPrice: 149.2274
		},
		{
			open: 149.31,
			close: 149.36,
			volume: 95344,
			time: 1654701240000,
			lowest: 149.24,
			highest: 149.36,
			volumePrice: 149.3014,
			transactionCount: 804,
			voluemPrice: 149.3014
		},
		{
			open: 149.36,
			close: 149.2885,
			volume: 121304,
			time: 1654701300000,
			lowest: 149.27,
			highest: 149.39,
			volumePrice: 149.3445,
			transactionCount: 999,
			voluemPrice: 149.3445
		},
		{
			open: 149.29,
			close: 149.4026,
			volume: 105641,
			time: 1654701360000,
			lowest: 149.275,
			highest: 149.42,
			volumePrice: 149.364,
			transactionCount: 886,
			voluemPrice: 149.364
		},
		{
			open: 149.4001,
			close: 149.51,
			volume: 162839,
			time: 1654701420000,
			lowest: 149.4001,
			highest: 149.5481,
			volumePrice: 149.4776,
			transactionCount: 1253,
			voluemPrice: 149.4776
		},
		{
			open: 149.51,
			close: 149.5854,
			volume: 174440,
			time: 1654701480000,
			lowest: 149.5,
			highest: 149.62,
			volumePrice: 149.5664,
			transactionCount: 1246,
			voluemPrice: 149.5664
		},
		{
			open: 149.59,
			close: 149.59,
			volume: 138252,
			time: 1654701540000,
			lowest: 149.58,
			highest: 149.6472,
			volumePrice: 149.6127,
			transactionCount: 816,
			voluemPrice: 149.6127
		},
		{
			open: 149.59,
			close: 149.5342,
			volume: 159816,
			time: 1654701600000,
			lowest: 149.47,
			highest: 149.5982,
			volumePrice: 149.5196,
			transactionCount: 1195,
			voluemPrice: 149.5196
		},
		{
			open: 149.535,
			close: 149.5099,
			volume: 81710,
			time: 1654701660000,
			lowest: 149.5,
			highest: 149.58,
			volumePrice: 149.553,
			transactionCount: 664,
			voluemPrice: 149.553
		},
		{
			open: 149.5,
			close: 149.3801,
			volume: 113426,
			time: 1654701720000,
			lowest: 149.36,
			highest: 149.5,
			volumePrice: 149.4059,
			transactionCount: 851,
			voluemPrice: 149.4059
		},
		{
			open: 149.38,
			close: 149.34,
			volume: 80287,
			time: 1654701780000,
			lowest: 149.33,
			highest: 149.44,
			volumePrice: 149.3879,
			transactionCount: 671,
			voluemPrice: 149.3879
		},
		{
			open: 149.34,
			close: 149.31,
			volume: 142252,
			time: 1654701840000,
			lowest: 149.27,
			highest: 149.355,
			volumePrice: 149.3105,
			transactionCount: 998,
			voluemPrice: 149.3105
		},
		{
			open: 149.3081,
			close: 149.37,
			volume: 54316,
			time: 1654701900000,
			lowest: 149.3,
			highest: 149.39,
			volumePrice: 149.3521,
			transactionCount: 649,
			voluemPrice: 149.3521
		},
		{
			open: 149.3604,
			close: 149.34,
			volume: 79551,
			time: 1654701960000,
			lowest: 149.34,
			highest: 149.4,
			volumePrice: 149.3689,
			transactionCount: 657,
			voluemPrice: 149.3689
		},
		{
			open: 149.34,
			close: 149.38,
			volume: 86022,
			time: 1654702020000,
			lowest: 149.3301,
			highest: 149.405,
			volumePrice: 149.3704,
			transactionCount: 831,
			voluemPrice: 149.3704
		},
		{
			open: 149.3874,
			close: 149.41,
			volume: 63448,
			time: 1654702080000,
			lowest: 149.3601,
			highest: 149.42,
			volumePrice: 149.3977,
			transactionCount: 570,
			voluemPrice: 149.3977
		},
		{
			open: 149.4,
			close: 149.3601,
			volume: 57926,
			time: 1654702140000,
			lowest: 149.34,
			highest: 149.42,
			volumePrice: 149.3781,
			transactionCount: 560,
			voluemPrice: 149.3781
		},
		{
			open: 149.37,
			close: 149.28,
			volume: 123820,
			time: 1654702200000,
			lowest: 149.28,
			highest: 149.38,
			volumePrice: 149.3206,
			transactionCount: 1061,
			voluemPrice: 149.3206
		},
		{
			open: 149.28,
			close: 149.34,
			volume: 146413,
			time: 1654702260000,
			lowest: 149.225,
			highest: 149.35,
			volumePrice: 149.2775,
			transactionCount: 1064,
			voluemPrice: 149.2775
		},
		{
			open: 149.335,
			close: 149.4,
			volume: 93217,
			time: 1654702320000,
			lowest: 149.335,
			highest: 149.45,
			volumePrice: 149.4008,
			transactionCount: 852,
			voluemPrice: 149.4008
		},
		{
			open: 149.405,
			close: 149.4508,
			volume: 104064,
			time: 1654702380000,
			lowest: 149.3934,
			highest: 149.51,
			volumePrice: 149.4684,
			transactionCount: 1034,
			voluemPrice: 149.4684
		},
		{
			open: 149.45,
			close: 149.44,
			volume: 56781,
			time: 1654702440000,
			lowest: 149.355,
			highest: 149.46,
			volumePrice: 149.4173,
			transactionCount: 699,
			voluemPrice: 149.4173
		},
		{
			open: 149.46,
			close: 149.4457,
			volume: 62901,
			time: 1654702500000,
			lowest: 149.38,
			highest: 149.48,
			volumePrice: 149.4251,
			transactionCount: 709,
			voluemPrice: 149.4251
		},
		{
			open: 149.44,
			close: 149.43,
			volume: 65390,
			time: 1654702560000,
			lowest: 149.3882,
			highest: 149.49,
			volumePrice: 149.4458,
			transactionCount: 592,
			voluemPrice: 149.4458
		},
		{
			open: 149.43,
			close: 149.4899,
			volume: 48731,
			time: 1654702620000,
			lowest: 149.41,
			highest: 149.4899,
			volumePrice: 149.4561,
			transactionCount: 510,
			voluemPrice: 149.4561
		},
		{
			open: 149.49,
			close: 149.46,
			volume: 89303,
			time: 1654702680000,
			lowest: 149.4147,
			highest: 149.528,
			volumePrice: 149.485,
			transactionCount: 839,
			voluemPrice: 149.485
		},
		{
			open: 149.4504,
			close: 149.4351,
			volume: 51083,
			time: 1654702740000,
			lowest: 149.4,
			highest: 149.48,
			volumePrice: 149.4341,
			transactionCount: 533,
			voluemPrice: 149.4341
		},
		{
			open: 149.44,
			close: 149.475,
			volume: 132070,
			time: 1654702800000,
			lowest: 149.42,
			highest: 149.6,
			volumePrice: 149.5298,
			transactionCount: 1083,
			voluemPrice: 149.5298
		},
		{
			open: 149.4644,
			close: 149.45,
			volume: 126649,
			time: 1654702860000,
			lowest: 149.36,
			highest: 149.49,
			volumePrice: 149.4337,
			transactionCount: 1080,
			voluemPrice: 149.4337
		},
		{
			open: 149.46,
			close: 149.3999,
			volume: 48106,
			time: 1654702920000,
			lowest: 149.37,
			highest: 149.46,
			volumePrice: 149.4214,
			transactionCount: 541,
			voluemPrice: 149.4214
		},
		{
			open: 149.395,
			close: 149.355,
			volume: 92670,
			time: 1654702980000,
			lowest: 149.29,
			highest: 149.43,
			volumePrice: 149.3641,
			transactionCount: 871,
			voluemPrice: 149.3641
		},
		{
			open: 149.35,
			close: 149.12,
			volume: 187863,
			time: 1654703040000,
			lowest: 149.0801,
			highest: 149.39,
			volumePrice: 149.1903,
			transactionCount: 1898,
			voluemPrice: 149.1903
		},
		{
			open: 149.135,
			close: 149.2199,
			volume: 137939,
			time: 1654703100000,
			lowest: 149.135,
			highest: 149.34,
			volumePrice: 149.2729,
			transactionCount: 1066,
			voluemPrice: 149.2729
		},
		{
			open: 149.22,
			close: 149.29,
			volume: 59943,
			time: 1654703160000,
			lowest: 149.22,
			highest: 149.31,
			volumePrice: 149.2825,
			transactionCount: 648,
			voluemPrice: 149.2825
		},
		{
			open: 149.28,
			close: 149.26,
			volume: 66970,
			time: 1654703220000,
			lowest: 149.22,
			highest: 149.36,
			volumePrice: 149.2751,
			transactionCount: 666,
			voluemPrice: 149.2751
		},
		{
			open: 149.28,
			close: 149.2402,
			volume: 60404,
			time: 1654703280000,
			lowest: 149.21,
			highest: 149.295,
			volumePrice: 149.245,
			transactionCount: 662,
			voluemPrice: 149.245
		},
		{
			open: 149.25,
			close: 149.2101,
			volume: 57524,
			time: 1654703340000,
			lowest: 149.2,
			highest: 149.34,
			volumePrice: 149.2849,
			transactionCount: 587,
			voluemPrice: 149.2849
		},
		{
			open: 149.2184,
			close: 149.275,
			volume: 49801,
			time: 1654703400000,
			lowest: 149.18,
			highest: 149.2912,
			volumePrice: 149.2423,
			transactionCount: 543,
			voluemPrice: 149.2423
		},
		{
			open: 149.28,
			close: 149.3039,
			volume: 56827,
			time: 1654703460000,
			lowest: 149.245,
			highest: 149.32,
			volumePrice: 149.291,
			transactionCount: 585,
			voluemPrice: 149.291
		},
		{
			open: 149.305,
			close: 149.3001,
			volume: 49500,
			time: 1654703520000,
			lowest: 149.28,
			highest: 149.37,
			volumePrice: 149.3144,
			transactionCount: 445,
			voluemPrice: 149.3144
		},
		{
			open: 149.3082,
			close: 149.2931,
			volume: 70126,
			time: 1654703580000,
			lowest: 149.27,
			highest: 149.3904,
			volumePrice: 149.3193,
			transactionCount: 615,
			voluemPrice: 149.3193
		},
		{
			open: 149.29,
			close: 149.32,
			volume: 76871,
			time: 1654703640000,
			lowest: 149.22,
			highest: 149.37,
			volumePrice: 149.2922,
			transactionCount: 664,
			voluemPrice: 149.2922
		},
		{
			open: 149.32,
			close: 149.24,
			volume: 51294,
			time: 1654703700000,
			lowest: 149.235,
			highest: 149.32,
			volumePrice: 149.2651,
			transactionCount: 501,
			voluemPrice: 149.2651
		},
		{
			open: 149.25,
			close: 149.27,
			volume: 64724,
			time: 1654703760000,
			lowest: 149.22,
			highest: 149.35,
			volumePrice: 149.2891,
			transactionCount: 598,
			voluemPrice: 149.2891
		},
		{
			open: 149.26,
			close: 149.1301,
			volume: 85493,
			time: 1654703820000,
			lowest: 149.125,
			highest: 149.28,
			volumePrice: 149.1861,
			transactionCount: 767,
			voluemPrice: 149.1861
		},
		{
			open: 149.1283,
			close: 149.12,
			volume: 82764,
			time: 1654703880000,
			lowest: 149.11,
			highest: 149.21,
			volumePrice: 149.1496,
			transactionCount: 749,
			voluemPrice: 149.1496
		},
		{
			open: 149.12,
			close: 149.06,
			volume: 85904,
			time: 1654703940000,
			lowest: 149.06,
			highest: 149.175,
			volumePrice: 149.116,
			transactionCount: 785,
			voluemPrice: 149.116
		},
		{
			open: 149.06,
			close: 149.165,
			volume: 153984,
			time: 1654704000000,
			lowest: 148.96,
			highest: 149.2,
			volumePrice: 149.0602,
			transactionCount: 1304,
			voluemPrice: 149.0602
		},
		{
			open: 149.17,
			close: 149.239,
			volume: 84186,
			time: 1654704060000,
			lowest: 149.13,
			highest: 149.25,
			volumePrice: 149.2034,
			transactionCount: 717,
			voluemPrice: 149.2034
		},
		{
			open: 149.24,
			close: 149.32,
			volume: 71284,
			time: 1654704120000,
			lowest: 149.22,
			highest: 149.32,
			volumePrice: 149.2724,
			transactionCount: 575,
			voluemPrice: 149.2724
		},
		{
			open: 149.3134,
			close: 149.28,
			volume: 82697,
			time: 1654704180000,
			lowest: 149.25,
			highest: 149.37,
			volumePrice: 149.3179,
			transactionCount: 674,
			voluemPrice: 149.3179
		},
		{
			open: 149.29,
			close: 149.27,
			volume: 64551,
			time: 1654704240000,
			lowest: 149.245,
			highest: 149.31,
			volumePrice: 149.2761,
			transactionCount: 527,
			voluemPrice: 149.2761
		},
		{
			open: 149.29,
			close: 149.1016,
			volume: 94812,
			time: 1654704300000,
			lowest: 149.1,
			highest: 149.3,
			volumePrice: 149.1999,
			transactionCount: 786,
			voluemPrice: 149.1999
		},
		{
			open: 149.1,
			close: 149.175,
			volume: 120421,
			time: 1654704360000,
			lowest: 148.99,
			highest: 149.175,
			volumePrice: 149.0785,
			transactionCount: 1030,
			voluemPrice: 149.0785
		},
		{
			open: 149.175,
			close: 149.15,
			volume: 80412,
			time: 1654704420000,
			lowest: 149.05,
			highest: 149.185,
			volumePrice: 149.1308,
			transactionCount: 486,
			voluemPrice: 149.1308
		},
		{
			open: 149.15,
			close: 149.13,
			volume: 40893,
			time: 1654704480000,
			lowest: 149.06,
			highest: 149.1801,
			volumePrice: 149.1289,
			transactionCount: 426,
			voluemPrice: 149.1289
		},
		{
			open: 149.1234,
			close: 149.1799,
			volume: 57595,
			time: 1654704540000,
			lowest: 149.1,
			highest: 149.18,
			volumePrice: 149.1445,
			transactionCount: 451,
			voluemPrice: 149.1445
		},
		{
			open: 149.18,
			close: 149.16,
			volume: 57929,
			time: 1654704600000,
			lowest: 149.1,
			highest: 149.2,
			volumePrice: 149.1668,
			transactionCount: 554,
			voluemPrice: 149.1668
		},
		{
			open: 149.15,
			close: 149.2037,
			volume: 48782,
			time: 1654704660000,
			lowest: 149.13,
			highest: 149.2037,
			volumePrice: 149.1706,
			transactionCount: 486,
			voluemPrice: 149.1706
		},
		{
			open: 149.205,
			close: 149.16,
			volume: 36927,
			time: 1654704720000,
			lowest: 149.16,
			highest: 149.219,
			volumePrice: 149.1931,
			transactionCount: 444,
			voluemPrice: 149.1931
		},
		{
			open: 149.16,
			close: 149.21,
			volume: 51489,
			time: 1654704780000,
			lowest: 149.1518,
			highest: 149.26,
			volumePrice: 149.2142,
			transactionCount: 494,
			voluemPrice: 149.2142
		},
		{
			open: 149.2079,
			close: 149.23,
			volume: 31512,
			time: 1654704840000,
			lowest: 149.16,
			highest: 149.267,
			volumePrice: 149.2081,
			transactionCount: 416,
			voluemPrice: 149.2081
		},
		{
			open: 149.24,
			close: 149.16,
			volume: 48227,
			time: 1654704900000,
			lowest: 149.125,
			highest: 149.24,
			volumePrice: 149.1779,
			transactionCount: 495,
			voluemPrice: 149.1779
		},
		{
			open: 149.15,
			close: 149.23,
			volume: 35206,
			time: 1654704960000,
			lowest: 149.14,
			highest: 149.235,
			volumePrice: 149.1809,
			transactionCount: 407,
			voluemPrice: 149.1809
		},
		{
			open: 149.21,
			close: 149.1199,
			volume: 58282,
			time: 1654705020000,
			lowest: 149.09,
			highest: 149.22,
			volumePrice: 149.1428,
			transactionCount: 542,
			voluemPrice: 149.1428
		},
		{
			open: 149.12,
			close: 149.12,
			volume: 122249,
			time: 1654705080000,
			lowest: 149,
			highest: 149.17,
			volumePrice: 149.0817,
			transactionCount: 1021,
			voluemPrice: 149.0817
		},
		{
			open: 149.12,
			close: 149.15,
			volume: 62537,
			time: 1654705140000,
			lowest: 149.09,
			highest: 149.1978,
			volumePrice: 149.1542,
			transactionCount: 562,
			voluemPrice: 149.1542
		},
		{
			open: 149.16,
			close: 149.1,
			volume: 53005,
			time: 1654705200000,
			lowest: 149.07,
			highest: 149.2083,
			volumePrice: 149.1295,
			transactionCount: 618,
			voluemPrice: 149.1295
		},
		{
			open: 149.1,
			close: 148.93,
			volume: 114444,
			time: 1654705260000,
			lowest: 148.92,
			highest: 149.1,
			volumePrice: 148.9915,
			transactionCount: 1180,
			voluemPrice: 148.9915
		},
		{
			open: 148.93,
			close: 149,
			volume: 109528,
			time: 1654705320000,
			lowest: 148.85,
			highest: 149,
			volumePrice: 148.9521,
			transactionCount: 866,
			voluemPrice: 148.9521
		},
		{
			open: 149,
			close: 148.879,
			volume: 88923,
			time: 1654705380000,
			lowest: 148.839,
			highest: 149.0184,
			volumePrice: 148.9121,
			transactionCount: 709,
			voluemPrice: 148.9121
		},
		{
			open: 148.885,
			close: 148.925,
			volume: 84737,
			time: 1654705440000,
			lowest: 148.82,
			highest: 148.95,
			volumePrice: 148.8822,
			transactionCount: 649,
			voluemPrice: 148.8822
		},
		{
			open: 148.925,
			close: 148.815,
			volume: 79041,
			time: 1654705500000,
			lowest: 148.8,
			highest: 148.94,
			volumePrice: 148.8795,
			transactionCount: 729,
			voluemPrice: 148.8795
		},
		{
			open: 148.82,
			close: 148.73,
			volume: 111125,
			time: 1654705560000,
			lowest: 148.71,
			highest: 148.89,
			volumePrice: 148.8056,
			transactionCount: 968,
			voluemPrice: 148.8056
		},
		{
			open: 148.73,
			close: 148.6244,
			volume: 179633,
			time: 1654705620000,
			lowest: 148.55,
			highest: 148.75,
			volumePrice: 148.6522,
			transactionCount: 1393,
			voluemPrice: 148.6522
		},
		{
			open: 148.63,
			close: 148.7,
			volume: 87091,
			time: 1654705680000,
			lowest: 148.59,
			highest: 148.71,
			volumePrice: 148.6507,
			transactionCount: 753,
			voluemPrice: 148.6507
		},
		{
			open: 148.695,
			close: 148.8,
			volume: 65515,
			time: 1654705740000,
			lowest: 148.685,
			highest: 148.81,
			volumePrice: 148.7549,
			transactionCount: 635,
			voluemPrice: 148.7549
		},
		{
			open: 148.8,
			close: 148.74,
			volume: 51679,
			time: 1654705800000,
			lowest: 148.7186,
			highest: 148.81,
			volumePrice: 148.7635,
			transactionCount: 537,
			voluemPrice: 148.7635
		},
		{
			open: 148.73,
			close: 148.62,
			volume: 60020,
			time: 1654705860000,
			lowest: 148.6,
			highest: 148.74,
			volumePrice: 148.679,
			transactionCount: 597,
			voluemPrice: 148.679
		},
		{
			open: 148.62,
			close: 148.3411,
			volume: 178647,
			time: 1654705920000,
			lowest: 148.33,
			highest: 148.62,
			volumePrice: 148.4896,
			transactionCount: 1856,
			voluemPrice: 148.4896
		},
		{
			open: 148.345,
			close: 148.3479,
			volume: 118232,
			time: 1654705980000,
			lowest: 148.28,
			highest: 148.36,
			volumePrice: 148.3312,
			transactionCount: 1089,
			voluemPrice: 148.3312
		},
		{
			open: 148.35,
			close: 148.4683,
			volume: 112216,
			time: 1654706040000,
			lowest: 148.31,
			highest: 148.47,
			volumePrice: 148.4064,
			transactionCount: 920,
			voluemPrice: 148.4064
		},
		{
			open: 148.4598,
			close: 148.46,
			volume: 57445,
			time: 1654706100000,
			lowest: 148.38,
			highest: 148.48,
			volumePrice: 148.4379,
			transactionCount: 536,
			voluemPrice: 148.4379
		},
		{
			open: 148.465,
			close: 148.43,
			volume: 53024,
			time: 1654706160000,
			lowest: 148.39,
			highest: 148.475,
			volumePrice: 148.4395,
			transactionCount: 537,
			voluemPrice: 148.4395
		},
		{
			open: 148.43,
			close: 148.4501,
			volume: 77304,
			time: 1654706220000,
			lowest: 148.3533,
			highest: 148.459,
			volumePrice: 148.4037,
			transactionCount: 700,
			voluemPrice: 148.4037
		},
		{
			open: 148.4586,
			close: 148.3841,
			volume: 65162,
			time: 1654706280000,
			lowest: 148.36,
			highest: 148.4586,
			volumePrice: 148.3901,
			transactionCount: 668,
			voluemPrice: 148.3901
		},
		{
			open: 148.3705,
			close: 148.065,
			volume: 189633,
			time: 1654706340000,
			lowest: 148.05,
			highest: 148.3764,
			volumePrice: 148.1841,
			transactionCount: 2066,
			voluemPrice: 148.1841
		},
		{
			open: 148.06,
			close: 148.125,
			volume: 131852,
			time: 1654706400000,
			lowest: 148.035,
			highest: 148.2141,
			volumePrice: 148.1334,
			transactionCount: 1074,
			voluemPrice: 148.1334
		},
		{
			open: 148.13,
			close: 148.15,
			volume: 72306,
			time: 1654706460000,
			lowest: 148.09,
			highest: 148.18,
			volumePrice: 148.1359,
			transactionCount: 695,
			voluemPrice: 148.1359
		},
		{
			open: 148.14,
			close: 148.0481,
			volume: 112194,
			time: 1654706520000,
			lowest: 148.01,
			highest: 148.14,
			volumePrice: 148.0613,
			transactionCount: 812,
			voluemPrice: 148.0613
		},
		{
			open: 148.0373,
			close: 148.002,
			volume: 236067,
			time: 1654706580000,
			lowest: 147.87,
			highest: 148.1,
			volumePrice: 147.9776,
			transactionCount: 2521,
			voluemPrice: 147.9776
		},
		{
			open: 148.0001,
			close: 147.825,
			volume: 115963,
			time: 1654706640000,
			lowest: 147.79,
			highest: 148.005,
			volumePrice: 147.9093,
			transactionCount: 1012,
			voluemPrice: 147.9093
		},
		{
			open: 147.82,
			close: 147.85,
			volume: 150770,
			time: 1654706700000,
			lowest: 147.74,
			highest: 147.88,
			volumePrice: 147.8002,
			transactionCount: 1371,
			voluemPrice: 147.8002
		},
		{
			open: 147.84,
			close: 147.94,
			volume: 98552,
			time: 1654706760000,
			lowest: 147.7698,
			highest: 147.94,
			volumePrice: 147.8694,
			transactionCount: 885,
			voluemPrice: 147.8694
		},
		{
			open: 147.93,
			close: 148.03,
			volume: 128998,
			time: 1654706820000,
			lowest: 147.885,
			highest: 148.05,
			volumePrice: 147.9642,
			transactionCount: 1188,
			voluemPrice: 147.9642
		},
		{
			open: 148.02,
			close: 148.07,
			volume: 98486,
			time: 1654706880000,
			lowest: 148.02,
			highest: 148.11,
			volumePrice: 148.0659,
			transactionCount: 914,
			voluemPrice: 148.0659
		},
		{
			open: 148.07,
			close: 148.08,
			volume: 92994,
			time: 1654706940000,
			lowest: 148,
			highest: 148.11,
			volumePrice: 148.0561,
			transactionCount: 717,
			voluemPrice: 148.0561
		},
		{
			open: 148.06,
			close: 148.095,
			volume: 70933,
			time: 1654707000000,
			lowest: 148.025,
			highest: 148.12,
			volumePrice: 148.081,
			transactionCount: 596,
			voluemPrice: 148.081
		},
		{
			open: 148.1,
			close: 148.0901,
			volume: 81251,
			time: 1654707060000,
			lowest: 148.06,
			highest: 148.14,
			volumePrice: 148.0981,
			transactionCount: 606,
			voluemPrice: 148.0981
		},
		{
			open: 148.09,
			close: 148.1,
			volume: 74603,
			time: 1654707120000,
			lowest: 148,
			highest: 148.12,
			volumePrice: 148.066,
			transactionCount: 717,
			voluemPrice: 148.066
		},
		{
			open: 148.105,
			close: 148.058,
			volume: 67186,
			time: 1654707180000,
			lowest: 147.99,
			highest: 148.1085,
			volumePrice: 148.0425,
			transactionCount: 709,
			voluemPrice: 148.0425
		},
		{
			open: 148.05,
			close: 148.025,
			volume: 54817,
			time: 1654707240000,
			lowest: 147.98,
			highest: 148.06,
			volumePrice: 148.0237,
			transactionCount: 585,
			voluemPrice: 148.0237
		},
		{
			open: 148.03,
			close: 147.995,
			volume: 53327,
			time: 1654707300000,
			lowest: 147.9701,
			highest: 148.04,
			volumePrice: 148.0065,
			transactionCount: 466,
			voluemPrice: 148.0065
		},
		{
			open: 148,
			close: 148.1,
			volume: 73873,
			time: 1654707360000,
			lowest: 147.9835,
			highest: 148.12,
			volumePrice: 148.0642,
			transactionCount: 618,
			voluemPrice: 148.0642
		},
		{
			open: 148.11,
			close: 148.0937,
			volume: 65383,
			time: 1654707420000,
			lowest: 148.0401,
			highest: 148.15,
			volumePrice: 148.0975,
			transactionCount: 622,
			voluemPrice: 148.0975
		},
		{
			open: 148.09,
			close: 148.1,
			volume: 30651,
			time: 1654707480000,
			lowest: 148.05,
			highest: 148.1,
			volumePrice: 148.0778,
			transactionCount: 401,
			voluemPrice: 148.0778
		},
		{
			open: 148.1,
			close: 148.23,
			volume: 127379,
			time: 1654707540000,
			lowest: 148.1,
			highest: 148.26,
			volumePrice: 148.195,
			transactionCount: 1105,
			voluemPrice: 148.195
		},
		{
			open: 148.22,
			close: 148.14,
			volume: 106133,
			time: 1654707600000,
			lowest: 148.13,
			highest: 148.24,
			volumePrice: 148.188,
			transactionCount: 894,
			voluemPrice: 148.188
		},
		{
			open: 148.1304,
			close: 148.1101,
			volume: 73114,
			time: 1654707660000,
			lowest: 148.041,
			highest: 148.19,
			volumePrice: 148.103,
			transactionCount: 694,
			voluemPrice: 148.103
		},
		{
			open: 148.08,
			close: 148.05,
			volume: 142262,
			time: 1654707720000,
			lowest: 147.91,
			highest: 148.08,
			volumePrice: 147.9906,
			transactionCount: 1129,
			voluemPrice: 147.9906
		},
		{
			open: 148.0499,
			close: 148.07,
			volume: 48379,
			time: 1654707780000,
			lowest: 147.96,
			highest: 148.08,
			volumePrice: 148.0189,
			transactionCount: 498,
			voluemPrice: 148.0189
		},
		{
			open: 148.0674,
			close: 148.015,
			volume: 55579,
			time: 1654707840000,
			lowest: 148.01,
			highest: 148.14,
			volumePrice: 148.0885,
			transactionCount: 572,
			voluemPrice: 148.0885
		},
		{
			open: 148.02,
			close: 148.06,
			volume: 55891,
			time: 1654707900000,
			lowest: 148,
			highest: 148.1199,
			volumePrice: 148.0422,
			transactionCount: 529,
			voluemPrice: 148.0422
		},
		{
			open: 148.06,
			close: 148.13,
			volume: 50462,
			time: 1654707960000,
			lowest: 148.03,
			highest: 148.1361,
			volumePrice: 148.0856,
			transactionCount: 592,
			voluemPrice: 148.0856
		},
		{
			open: 148.13,
			close: 148.0869,
			volume: 64934,
			time: 1654708020000,
			lowest: 148.07,
			highest: 148.19,
			volumePrice: 148.1451,
			transactionCount: 629,
			voluemPrice: 148.1451
		},
		{
			open: 148.08,
			close: 148.1263,
			volume: 39064,
			time: 1654708080000,
			lowest: 148.065,
			highest: 148.1718,
			volumePrice: 148.1372,
			transactionCount: 440,
			voluemPrice: 148.1372
		},
		{
			open: 148.1106,
			close: 148.1385,
			volume: 49156,
			time: 1654708140000,
			lowest: 148.1106,
			highest: 148.18,
			volumePrice: 148.1518,
			transactionCount: 438,
			voluemPrice: 148.1518
		},
		{
			open: 148.13,
			close: 148.1786,
			volume: 62583,
			time: 1654708200000,
			lowest: 148.06,
			highest: 148.1879,
			volumePrice: 148.1219,
			transactionCount: 548,
			voluemPrice: 148.1219
		},
		{
			open: 148.179,
			close: 148.04,
			volume: 69196,
			time: 1654708260000,
			lowest: 148.02,
			highest: 148.19,
			volumePrice: 148.0933,
			transactionCount: 628,
			voluemPrice: 148.0933
		},
		{
			open: 148.0301,
			close: 147.9593,
			volume: 91946,
			time: 1654708320000,
			lowest: 147.94,
			highest: 148.06,
			volumePrice: 147.9906,
			transactionCount: 795,
			voluemPrice: 147.9906
		},
		{
			open: 147.95,
			close: 148.05,
			volume: 80456,
			time: 1654708380000,
			lowest: 147.93,
			highest: 148.07,
			volumePrice: 148.0108,
			transactionCount: 644,
			voluemPrice: 148.0108
		},
		{
			open: 148.06,
			close: 148.08,
			volume: 43931,
			time: 1654708440000,
			lowest: 148.02,
			highest: 148.1,
			volumePrice: 148.0673,
			transactionCount: 434,
			voluemPrice: 148.0673
		},
		{
			open: 148.09,
			close: 148.01,
			volume: 70892,
			time: 1654708500000,
			lowest: 148.005,
			highest: 148.15,
			volumePrice: 148.091,
			transactionCount: 636,
			voluemPrice: 148.091
		},
		{
			open: 148.0001,
			close: 147.96,
			volume: 125489,
			time: 1654708560000,
			lowest: 147.9,
			highest: 148.01,
			volumePrice: 147.953,
			transactionCount: 892,
			voluemPrice: 147.953
		},
		{
			open: 147.9507,
			close: 148.031,
			volume: 156961,
			time: 1654708620000,
			lowest: 147.85,
			highest: 148.04,
			volumePrice: 147.9442,
			transactionCount: 830,
			voluemPrice: 147.9442
		},
		{
			open: 148.04,
			close: 148.14,
			volume: 60977,
			time: 1654708680000,
			lowest: 148.03,
			highest: 148.14,
			volumePrice: 148.0819,
			transactionCount: 577,
			voluemPrice: 148.0819
		},
		{
			open: 148.15,
			close: 148.15,
			volume: 54309,
			time: 1654708740000,
			lowest: 148.135,
			highest: 148.2,
			volumePrice: 148.1671,
			transactionCount: 573,
			voluemPrice: 148.1671
		},
		{
			open: 148.14,
			close: 148.0399,
			volume: 143927,
			time: 1654708800000,
			lowest: 148.0399,
			highest: 148.17,
			volumePrice: 148.0947,
			transactionCount: 991,
			voluemPrice: 148.0947
		},
		{
			open: 148.03,
			close: 148.14,
			volume: 105150,
			time: 1654708860000,
			lowest: 147.99,
			highest: 148.14,
			volumePrice: 148.0301,
			transactionCount: 537,
			voluemPrice: 148.0301
		},
		{
			open: 148.135,
			close: 148.08,
			volume: 40838,
			time: 1654708920000,
			lowest: 148.04,
			highest: 148.14,
			volumePrice: 148.0923,
			transactionCount: 427,
			voluemPrice: 148.0923
		},
		{
			open: 148.07,
			close: 148.0725,
			volume: 51771,
			time: 1654708980000,
			lowest: 148.05,
			highest: 148.15,
			volumePrice: 148.1121,
			transactionCount: 554,
			voluemPrice: 148.1121
		},
		{
			open: 148.078,
			close: 148.05,
			volume: 29925,
			time: 1654709040000,
			lowest: 148.03,
			highest: 148.08,
			volumePrice: 148.0542,
			transactionCount: 396,
			voluemPrice: 148.0542
		},
		{
			open: 148.07,
			close: 147.9,
			volume: 79661,
			time: 1654709100000,
			lowest: 147.9,
			highest: 148.1087,
			volumePrice: 147.9882,
			transactionCount: 843,
			voluemPrice: 147.9882
		},
		{
			open: 147.9,
			close: 148,
			volume: 72795,
			time: 1654709160000,
			lowest: 147.89,
			highest: 148.01,
			volumePrice: 147.938,
			transactionCount: 670,
			voluemPrice: 147.938
		},
		{
			open: 148,
			close: 148.01,
			volume: 51271,
			time: 1654709220000,
			lowest: 147.945,
			highest: 148.03,
			volumePrice: 147.9982,
			transactionCount: 474,
			voluemPrice: 147.9982
		},
		{
			open: 148.02,
			close: 147.88,
			volume: 52668,
			time: 1654709280000,
			lowest: 147.88,
			highest: 148.06,
			volumePrice: 147.9896,
			transactionCount: 647,
			voluemPrice: 147.9896
		},
		{
			open: 147.8872,
			close: 147.85,
			volume: 68134,
			time: 1654709340000,
			lowest: 147.85,
			highest: 147.95,
			volumePrice: 147.8927,
			transactionCount: 616,
			voluemPrice: 147.8927
		},
		{
			open: 147.84,
			close: 147.6327,
			volume: 192554,
			time: 1654709400000,
			lowest: 147.61,
			highest: 147.88,
			volumePrice: 147.7475,
			transactionCount: 1726,
			voluemPrice: 147.7475
		},
		{
			open: 147.64,
			close: 147.63,
			volume: 96731,
			time: 1654709460000,
			lowest: 147.57,
			highest: 147.7,
			volumePrice: 147.6296,
			transactionCount: 1061,
			voluemPrice: 147.6296
		},
		{
			open: 147.61,
			close: 147.65,
			volume: 65894,
			time: 1654709520000,
			lowest: 147.6,
			highest: 147.75,
			volumePrice: 147.6904,
			transactionCount: 540,
			voluemPrice: 147.6904
		},
		{
			open: 147.65,
			close: 147.59,
			volume: 69572,
			time: 1654709580000,
			lowest: 147.58,
			highest: 147.69,
			volumePrice: 147.633,
			transactionCount: 682,
			voluemPrice: 147.633
		},
		{
			open: 147.585,
			close: 147.69,
			volume: 78155,
			time: 1654709640000,
			lowest: 147.54,
			highest: 147.73,
			volumePrice: 147.6368,
			transactionCount: 771,
			voluemPrice: 147.6368
		},
		{
			open: 147.695,
			close: 147.6101,
			volume: 73561,
			time: 1654709700000,
			lowest: 147.5704,
			highest: 147.7087,
			volumePrice: 147.6365,
			transactionCount: 585,
			voluemPrice: 147.6365
		},
		{
			open: 147.62,
			close: 147.63,
			volume: 66130,
			time: 1654709760000,
			lowest: 147.56,
			highest: 147.665,
			volumePrice: 147.6302,
			transactionCount: 551,
			voluemPrice: 147.6302
		},
		{
			open: 147.63,
			close: 147.52,
			volume: 86974,
			time: 1654709820000,
			lowest: 147.51,
			highest: 147.65,
			volumePrice: 147.5696,
			transactionCount: 861,
			voluemPrice: 147.5696
		},
		{
			open: 147.5149,
			close: 147.5,
			volume: 115491,
			time: 1654709880000,
			lowest: 147.46,
			highest: 147.5157,
			volumePrice: 147.4883,
			transactionCount: 1264,
			voluemPrice: 147.4883
		},
		{
			open: 147.51,
			close: 147.52,
			volume: 78114,
			time: 1654709940000,
			lowest: 147.5,
			highest: 147.55,
			volumePrice: 147.5219,
			transactionCount: 775,
			voluemPrice: 147.5219
		},
		{
			open: 147.515,
			close: 147.63,
			volume: 95773,
			time: 1654710000000,
			lowest: 147.5,
			highest: 147.63,
			volumePrice: 147.5573,
			transactionCount: 791,
			voluemPrice: 147.5573
		},
		{
			open: 147.6244,
			close: 147.785,
			volume: 135483,
			time: 1654710060000,
			lowest: 147.6244,
			highest: 147.82,
			volumePrice: 147.7251,
			transactionCount: 1241,
			voluemPrice: 147.7251
		},
		{
			open: 147.79,
			close: 147.87,
			volume: 107380,
			time: 1654710120000,
			lowest: 147.7871,
			highest: 147.8799,
			volumePrice: 147.8315,
			transactionCount: 885,
			voluemPrice: 147.8315
		},
		{
			open: 147.88,
			close: 147.8774,
			volume: 66384,
			time: 1654710180000,
			lowest: 147.79,
			highest: 147.8885,
			volumePrice: 147.8426,
			transactionCount: 653,
			voluemPrice: 147.8426
		},
		{
			open: 147.87,
			close: 147.91,
			volume: 96312,
			time: 1654710240000,
			lowest: 147.8625,
			highest: 147.94,
			volumePrice: 147.9016,
			transactionCount: 761,
			voluemPrice: 147.9016
		},
		{
			open: 147.9,
			close: 147.89,
			volume: 90622,
			time: 1654710300000,
			lowest: 147.87,
			highest: 147.99,
			volumePrice: 147.9249,
			transactionCount: 731,
			voluemPrice: 147.9249
		},
		{
			open: 147.895,
			close: 147.99,
			volume: 74202,
			time: 1654710360000,
			lowest: 147.88,
			highest: 148,
			volumePrice: 147.953,
			transactionCount: 584,
			voluemPrice: 147.953
		},
		{
			open: 148,
			close: 148.06,
			volume: 100738,
			time: 1654710420000,
			lowest: 147.986,
			highest: 148.08,
			volumePrice: 148.0293,
			transactionCount: 815,
			voluemPrice: 148.0293
		},
		{
			open: 148.0687,
			close: 148.1379,
			volume: 108999,
			time: 1654710480000,
			lowest: 148.06,
			highest: 148.1408,
			volumePrice: 148.1046,
			transactionCount: 939,
			voluemPrice: 148.1046
		},
		{
			open: 148.13,
			close: 148.11,
			volume: 77987,
			time: 1654710540000,
			lowest: 148.07,
			highest: 148.16,
			volumePrice: 148.1046,
			transactionCount: 614,
			voluemPrice: 148.1046
		},
		{
			open: 148.11,
			close: 148.0612,
			volume: 61997,
			time: 1654710600000,
			lowest: 148.06,
			highest: 148.15,
			volumePrice: 148.111,
			transactionCount: 544,
			voluemPrice: 148.111
		},
		{
			open: 148.0664,
			close: 148.02,
			volume: 99836,
			time: 1654710660000,
			lowest: 147.99,
			highest: 148.07,
			volumePrice: 148.0305,
			transactionCount: 708,
			voluemPrice: 148.0305
		},
		{
			open: 148.01,
			close: 148.05,
			volume: 67914,
			time: 1654710720000,
			lowest: 147.96,
			highest: 148.058,
			volumePrice: 148.001,
			transactionCount: 618,
			voluemPrice: 148.001
		},
		{
			open: 148.0534,
			close: 148.1281,
			volume: 74476,
			time: 1654710780000,
			lowest: 148.03,
			highest: 148.1384,
			volumePrice: 148.091,
			transactionCount: 554,
			voluemPrice: 148.091
		},
		{
			open: 148.13,
			close: 148.169,
			volume: 112778,
			time: 1654710840000,
			lowest: 148.13,
			highest: 148.205,
			volumePrice: 148.1714,
			transactionCount: 850,
			voluemPrice: 148.1714
		},
		{
			open: 148.18,
			close: 148.23,
			volume: 99969,
			time: 1654710900000,
			lowest: 148.11,
			highest: 148.23,
			volumePrice: 148.1709,
			transactionCount: 728,
			voluemPrice: 148.1709
		},
		{
			open: 148.24,
			close: 148.37,
			volume: 135864,
			time: 1654710960000,
			lowest: 148.24,
			highest: 148.37,
			volumePrice: 148.3098,
			transactionCount: 1038,
			voluemPrice: 148.3098
		},
		{
			open: 148.38,
			close: 148.2769,
			volume: 100352,
			time: 1654711020000,
			lowest: 148.26,
			highest: 148.38,
			volumePrice: 148.3038,
			transactionCount: 858,
			voluemPrice: 148.3038
		},
		{
			open: 148.2798,
			close: 148.245,
			volume: 64412,
			time: 1654711080000,
			lowest: 148.24,
			highest: 148.28,
			volumePrice: 148.2576,
			transactionCount: 476,
			voluemPrice: 148.2576
		},
		{
			open: 148.24,
			close: 148.2538,
			volume: 69664,
			time: 1654711140000,
			lowest: 148.2,
			highest: 148.27,
			volumePrice: 148.2345,
			transactionCount: 606,
			voluemPrice: 148.2345
		},
		{
			open: 148.26,
			close: 148.29,
			volume: 91747,
			time: 1654711200000,
			lowest: 148.22,
			highest: 148.335,
			volumePrice: 148.2898,
			transactionCount: 739,
			voluemPrice: 148.2898
		},
		{
			open: 148.28,
			close: 148.275,
			volume: 82546,
			time: 1654711260000,
			lowest: 148.21,
			highest: 148.3277,
			volumePrice: 148.2538,
			transactionCount: 710,
			voluemPrice: 148.2538
		},
		{
			open: 148.2801,
			close: 148.315,
			volume: 79186,
			time: 1654711320000,
			lowest: 148.24,
			highest: 148.355,
			volumePrice: 148.3176,
			transactionCount: 624,
			voluemPrice: 148.3176
		},
		{
			open: 148.31,
			close: 148.38,
			volume: 91963,
			time: 1654711380000,
			lowest: 148.31,
			highest: 148.43,
			volumePrice: 148.3716,
			transactionCount: 738,
			voluemPrice: 148.3716
		},
		{
			open: 148.3799,
			close: 148.29,
			volume: 93130,
			time: 1654711440000,
			lowest: 148.25,
			highest: 148.3799,
			volumePrice: 148.2921,
			transactionCount: 747,
			voluemPrice: 148.2921
		},
		{
			open: 148.2773,
			close: 148.2512,
			volume: 103632,
			time: 1654711500000,
			lowest: 148.2,
			highest: 148.31,
			volumePrice: 148.2504,
			transactionCount: 772,
			voluemPrice: 148.2504
		},
		{
			open: 148.25,
			close: 148.18,
			volume: 84945,
			time: 1654711560000,
			lowest: 148.15,
			highest: 148.255,
			volumePrice: 148.1906,
			transactionCount: 741,
			voluemPrice: 148.1906
		},
		{
			open: 148.18,
			close: 148.24,
			volume: 78912,
			time: 1654711620000,
			lowest: 148.175,
			highest: 148.315,
			volumePrice: 148.2264,
			transactionCount: 538,
			voluemPrice: 148.2264
		},
		{
			open: 148.23,
			close: 148.2,
			volume: 51111,
			time: 1654711680000,
			lowest: 148.18,
			highest: 148.255,
			volumePrice: 148.2193,
			transactionCount: 502,
			voluemPrice: 148.2193
		},
		{
			open: 148.1949,
			close: 148.24,
			volume: 62244,
			time: 1654711740000,
			lowest: 148.12,
			highest: 148.25,
			volumePrice: 148.1727,
			transactionCount: 590,
			voluemPrice: 148.1727
		},
		{
			open: 148.24,
			close: 148.235,
			volume: 82624,
			time: 1654711800000,
			lowest: 148.155,
			highest: 148.275,
			volumePrice: 148.213,
			transactionCount: 604,
			voluemPrice: 148.213
		},
		{
			open: 148.2399,
			close: 148.26,
			volume: 114603,
			time: 1654711860000,
			lowest: 148.2,
			highest: 148.295,
			volumePrice: 148.2507,
			transactionCount: 640,
			voluemPrice: 148.2507
		},
		{
			open: 148.249,
			close: 148.175,
			volume: 70670,
			time: 1654711920000,
			lowest: 148.1701,
			highest: 148.34,
			volumePrice: 148.2618,
			transactionCount: 594,
			voluemPrice: 148.2618
		},
		{
			open: 148.1743,
			close: 148.06,
			volume: 81025,
			time: 1654711980000,
			lowest: 148.06,
			highest: 148.2299,
			volumePrice: 148.1479,
			transactionCount: 781,
			voluemPrice: 148.1479
		},
		{
			open: 148.06,
			close: 148.2174,
			volume: 95779,
			time: 1654712040000,
			lowest: 148.06,
			highest: 148.26,
			volumePrice: 148.1766,
			transactionCount: 776,
			voluemPrice: 148.1766
		},
		{
			open: 148.22,
			close: 148.23,
			volume: 75697,
			time: 1654712100000,
			lowest: 148.21,
			highest: 148.2882,
			volumePrice: 148.248,
			transactionCount: 523,
			voluemPrice: 148.248
		},
		{
			open: 148.21,
			close: 148.13,
			volume: 53882,
			time: 1654712160000,
			lowest: 148.13,
			highest: 148.22,
			volumePrice: 148.1872,
			transactionCount: 478,
			voluemPrice: 148.1872
		},
		{
			open: 148.13,
			close: 148.1279,
			volume: 81841,
			time: 1654712220000,
			lowest: 148.06,
			highest: 148.21,
			volumePrice: 148.1291,
			transactionCount: 716,
			voluemPrice: 148.1291
		},
		{
			open: 148.11,
			close: 148,
			volume: 103849,
			time: 1654712280000,
			lowest: 148,
			highest: 148.17,
			volumePrice: 148.0655,
			transactionCount: 798,
			voluemPrice: 148.0655
		},
		{
			open: 148.01,
			close: 148.06,
			volume: 74132,
			time: 1654712340000,
			lowest: 148.005,
			highest: 148.0932,
			volumePrice: 148.0507,
			transactionCount: 666,
			voluemPrice: 148.0507
		},
		{
			open: 148.05,
			close: 147.9801,
			volume: 95214,
			time: 1654712400000,
			lowest: 147.98,
			highest: 148.14,
			volumePrice: 148.0579,
			transactionCount: 734,
			voluemPrice: 148.0579
		},
		{
			open: 147.975,
			close: 148.09,
			volume: 83678,
			time: 1654712460000,
			lowest: 147.93,
			highest: 148.1,
			volumePrice: 147.9992,
			transactionCount: 657,
			voluemPrice: 147.9992
		},
		{
			open: 148.08,
			close: 148.08,
			volume: 75924,
			time: 1654712520000,
			lowest: 148.08,
			highest: 148.17,
			volumePrice: 148.1343,
			transactionCount: 561,
			voluemPrice: 148.1343
		},
		{
			open: 148.07,
			close: 148.09,
			volume: 70381,
			time: 1654712580000,
			lowest: 148.01,
			highest: 148.13,
			volumePrice: 148.0702,
			transactionCount: 519,
			voluemPrice: 148.0702
		},
		{
			open: 148.09,
			close: 148.1,
			volume: 65675,
			time: 1654712640000,
			lowest: 148.07,
			highest: 148.18,
			volumePrice: 148.1301,
			transactionCount: 554,
			voluemPrice: 148.1301
		},
		{
			open: 148.09,
			close: 148.07,
			volume: 57167,
			time: 1654712700000,
			lowest: 148.06,
			highest: 148.16,
			volumePrice: 148.113,
			transactionCount: 496,
			voluemPrice: 148.113
		},
		{
			open: 148.075,
			close: 147.9899,
			volume: 105497,
			time: 1654712760000,
			lowest: 147.95,
			highest: 148.09,
			volumePrice: 147.9945,
			transactionCount: 685,
			voluemPrice: 147.9945
		},
		{
			open: 147.99,
			close: 147.84,
			volume: 113947,
			time: 1654712820000,
			lowest: 147.82,
			highest: 147.9901,
			volumePrice: 147.8779,
			transactionCount: 829,
			voluemPrice: 147.8779
		},
		{
			open: 147.84,
			close: 147.755,
			volume: 127955,
			time: 1654712880000,
			lowest: 147.66,
			highest: 147.84,
			volumePrice: 147.732,
			transactionCount: 996,
			voluemPrice: 147.732
		},
		{
			open: 147.7414,
			close: 147.85,
			volume: 85321,
			time: 1654712940000,
			lowest: 147.74,
			highest: 147.9099,
			volumePrice: 147.8295,
			transactionCount: 653,
			voluemPrice: 147.8295
		},
		{
			open: 147.8301,
			close: 148,
			volume: 103579,
			time: 1654713000000,
			lowest: 147.79,
			highest: 148.01,
			volumePrice: 147.9072,
			transactionCount: 887,
			voluemPrice: 147.9072
		},
		{
			open: 147.99,
			close: 147.93,
			volume: 90826,
			time: 1654713060000,
			lowest: 147.9,
			highest: 148.02,
			volumePrice: 147.9742,
			transactionCount: 585,
			voluemPrice: 147.9742
		},
		{
			open: 147.92,
			close: 147.855,
			volume: 63200,
			time: 1654713120000,
			lowest: 147.85,
			highest: 147.94,
			volumePrice: 147.8965,
			transactionCount: 550,
			voluemPrice: 147.8965
		},
		{
			open: 147.8597,
			close: 147.835,
			volume: 52640,
			time: 1654713180000,
			lowest: 147.825,
			highest: 147.91,
			volumePrice: 147.8648,
			transactionCount: 492,
			voluemPrice: 147.8648
		},
		{
			open: 147.84,
			close: 147.74,
			volume: 83942,
			time: 1654713240000,
			lowest: 147.72,
			highest: 147.86,
			volumePrice: 147.7849,
			transactionCount: 717,
			voluemPrice: 147.7849
		},
		{
			open: 147.73,
			close: 147.6053,
			volume: 137837,
			time: 1654713300000,
			lowest: 147.59,
			highest: 147.7791,
			volumePrice: 147.681,
			transactionCount: 832,
			voluemPrice: 147.681
		},
		{
			open: 147.5925,
			close: 147.65,
			volume: 65213,
			time: 1654713360000,
			lowest: 147.5873,
			highest: 147.67,
			volumePrice: 147.6417,
			transactionCount: 597,
			voluemPrice: 147.6417
		},
		{
			open: 147.6501,
			close: 147.615,
			volume: 100560,
			time: 1654713420000,
			lowest: 147.57,
			highest: 147.66,
			volumePrice: 147.6246,
			transactionCount: 995,
			voluemPrice: 147.6246
		},
		{
			open: 147.6189,
			close: 147.69,
			volume: 90891,
			time: 1654713480000,
			lowest: 147.59,
			highest: 147.73,
			volumePrice: 147.6672,
			transactionCount: 815,
			voluemPrice: 147.6672
		},
		{
			open: 147.6887,
			close: 147.655,
			volume: 82353,
			time: 1654713540000,
			lowest: 147.57,
			highest: 147.695,
			volumePrice: 147.6255,
			transactionCount: 726,
			voluemPrice: 147.6255
		},
		{
			open: 147.66,
			close: 147.715,
			volume: 69208,
			time: 1654713600000,
			lowest: 147.655,
			highest: 147.73,
			volumePrice: 147.6934,
			transactionCount: 641,
			voluemPrice: 147.6934
		},
		{
			open: 147.72,
			close: 147.7502,
			volume: 70031,
			time: 1654713660000,
			lowest: 147.685,
			highest: 147.76,
			volumePrice: 147.7182,
			transactionCount: 603,
			voluemPrice: 147.7182
		},
		{
			open: 147.7576,
			close: 147.82,
			volume: 97084,
			time: 1654713720000,
			lowest: 147.75,
			highest: 147.84,
			volumePrice: 147.7913,
			transactionCount: 792,
			voluemPrice: 147.7913
		},
		{
			open: 147.83,
			close: 147.94,
			volume: 116707,
			time: 1654713780000,
			lowest: 147.8,
			highest: 147.97,
			volumePrice: 147.8894,
			transactionCount: 1051,
			voluemPrice: 147.8894
		},
		{
			open: 147.94,
			close: 147.945,
			volume: 88651,
			time: 1654713840000,
			lowest: 147.86,
			highest: 147.99,
			volumePrice: 147.9226,
			transactionCount: 715,
			voluemPrice: 147.9226
		},
		{
			open: 147.9499,
			close: 148.085,
			volume: 114646,
			time: 1654713900000,
			lowest: 147.93,
			highest: 148.14,
			volumePrice: 148.0516,
			transactionCount: 1001,
			voluemPrice: 148.0516
		},
		{
			open: 148.08,
			close: 148.02,
			volume: 59040,
			time: 1654713960000,
			lowest: 148.01,
			highest: 148.08,
			volumePrice: 148.0399,
			transactionCount: 612,
			voluemPrice: 148.0399
		},
		{
			open: 148.01,
			close: 148.0594,
			volume: 102458,
			time: 1654714020000,
			lowest: 147.93,
			highest: 148.11,
			volumePrice: 148.0203,
			transactionCount: 728,
			voluemPrice: 148.0203
		},
		{
			open: 148.05,
			close: 147.96,
			volume: 53844,
			time: 1654714080000,
			lowest: 147.96,
			highest: 148.075,
			volumePrice: 148.0188,
			transactionCount: 597,
			voluemPrice: 148.0188
		},
		{
			open: 147.97,
			close: 147.97,
			volume: 49215,
			time: 1654714140000,
			lowest: 147.9,
			highest: 148.01,
			volumePrice: 147.969,
			transactionCount: 530,
			voluemPrice: 147.969
		},
		{
			open: 147.98,
			close: 147.92,
			volume: 119196,
			time: 1654714200000,
			lowest: 147.8418,
			highest: 148.05,
			volumePrice: 147.9397,
			transactionCount: 1012,
			voluemPrice: 147.9397
		},
		{
			open: 147.91,
			close: 147.87,
			volume: 51460,
			time: 1654714260000,
			lowest: 147.85,
			highest: 147.93,
			volumePrice: 147.887,
			transactionCount: 489,
			voluemPrice: 147.887
		},
		{
			open: 147.87,
			close: 147.925,
			volume: 70017,
			time: 1654714320000,
			lowest: 147.87,
			highest: 147.94,
			volumePrice: 147.9051,
			transactionCount: 480,
			voluemPrice: 147.9051
		},
		{
			open: 147.93,
			close: 147.94,
			volume: 65342,
			time: 1654714380000,
			lowest: 147.9,
			highest: 147.98,
			volumePrice: 147.9435,
			transactionCount: 569,
			voluemPrice: 147.9435
		},
		{
			open: 147.94,
			close: 147.845,
			volume: 55685,
			time: 1654714440000,
			lowest: 147.84,
			highest: 147.9799,
			volumePrice: 147.9157,
			transactionCount: 583,
			voluemPrice: 147.9157
		},
		{
			open: 147.84,
			close: 147.9,
			volume: 97919,
			time: 1654714500000,
			lowest: 147.76,
			highest: 147.93,
			volumePrice: 147.8334,
			transactionCount: 805,
			voluemPrice: 147.8334
		}
	],
	status: 'OK',
	request_id: '5faf18d0ae07c6ff41a8a251a04d152f',
	count: 4530
};

const newsSentimentChartData = [
	{
		date: '2022-05-26',
		sentiment: 0.0758
	},
	{
		date: '2022-05-25',
		sentiment: 0.1062
	},
	{
		date: '2022-05-23',
		sentiment: 0.2518
	},
	{
		date: '2022-05-22',
		sentiment: 0.0977
	},
	{
		date: '2022-05-21',
		sentiment: 0.1526
	},
	{
		date: '2022-05-20',
		sentiment: 0.1814
	},
	{
		date: '2022-05-19',
		sentiment: 0.1663
	},
	{
		date: '2022-05-18',
		sentiment: 0.1908
	},
	{
		date: '2022-05-16',
		sentiment: 0.2657
	},
	{
		date: '2022-05-15',
		sentiment: 0.1697
	},
	{
		date: '2022-05-14',
		sentiment: 0.0513
	},
	{
		date: '2022-05-13',
		sentiment: 0.0964
	},
	{
		date: '2022-05-12',
		sentiment: 0.1217
	},
	{
		date: '2022-05-11',
		sentiment: 0.0905
	},
	{
		date: '2022-05-09',
		sentiment: 0.1793
	},
	{
		date: '2022-05-08',
		sentiment: 0.1708
	},
	{
		date: '2022-05-07',
		sentiment: 0.1955
	},
	{
		date: '2022-05-06',
		sentiment: 0.1335
	},
	{
		date: '2022-05-05',
		sentiment: 0.1083
	},
	{
		date: '2022-05-04',
		sentiment: 0.2098
	},
	{
		date: '2022-05-02',
		sentiment: 0.1582
	},
	{
		date: '2022-05-01',
		sentiment: 0.0529
	},
	{
		date: '2022-06-30',
		sentiment: 0.0859
	},
	{
		date: '2022-06-29',
		sentiment: 0.0971
	},
	{
		date: '2022-06-28',
		sentiment: 0.1417
	},
	{
		date: '2022-06-27',
		sentiment: 0.2649
	},
	{
		date: '2022-06-25',
		sentiment: 0.3037
	},
	{
		date: '2022-06-24',
		sentiment: 0.2069
	},
	{
		date: '2022-06-23',
		sentiment: 0.1551
	},
	{
		date: '2022-06-22',
		sentiment: 0.1226
	}
];

const oneSecChartData = {
  "results": [
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12492",
      "participant_timestamp": 1659360935375000000,
      "price": 162.04,
      "sequence_number": 395313,
      "sip_timestamp": 1659360935376506400,
      "size": 8,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935376483800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27495",
      "participant_timestamp": 1659360935306000000,
      "price": 161.0488,
      "sequence_number": 395312,
      "sip_timestamp": 1659360935371402200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935371064300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27494",
      "participant_timestamp": 1659360935296000000,
      "price": 161.0488,
      "sequence_number": 395305,
      "sip_timestamp": 1659360935362444800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935362108400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27493",
      "participant_timestamp": 1659360935316000000,
      "price": 161.0488,
      "sequence_number": 395304,
      "sip_timestamp": 1659360935361826300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935361490000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27492",
      "participant_timestamp": 1659360935286000000,
      "price": 161.0488,
      "sequence_number": 395297,
      "sip_timestamp": 1659360935344930000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935344593000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27491",
      "participant_timestamp": 1659360935276000000,
      "price": 161.0488,
      "sequence_number": 395293,
      "sip_timestamp": 1659360935323790600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935323453200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27490",
      "participant_timestamp": 1659360935266000000,
      "price": 161.0488,
      "sequence_number": 395286,
      "sip_timestamp": 1659360935315784400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935315447000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27489",
      "participant_timestamp": 1659360935246000000,
      "price": 161.0488,
      "sequence_number": 395284,
      "sip_timestamp": 1659360935315610000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935315272400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27488",
      "participant_timestamp": 1659360935256000000,
      "price": 161.0488,
      "sequence_number": 395266,
      "sip_timestamp": 1659360935300897000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935300559000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27487",
      "participant_timestamp": 1659360935226000000,
      "price": 161.0488,
      "sequence_number": 395264,
      "sip_timestamp": 1659360935299835000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935299497000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27486",
      "participant_timestamp": 1659360935216000000,
      "price": 161.0488,
      "sequence_number": 395262,
      "sip_timestamp": 1659360935298721800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935298385000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27485",
      "participant_timestamp": 1659360935236000000,
      "price": 161.0488,
      "sequence_number": 395261,
      "sip_timestamp": 1659360935298132500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935297794800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12491",
      "participant_timestamp": 1659360935298000000,
      "price": 162.05,
      "sequence_number": 395260,
      "sip_timestamp": 1659360935291910400,
      "size": 3,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935291888000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9715",
      "participant_timestamp": 1659360935279077000,
      "price": 162.04,
      "sequence_number": 395255,
      "sip_timestamp": 1659360935279096000,
      "size": 15,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9714",
      "participant_timestamp": 1659360935279077000,
      "price": 162.04,
      "sequence_number": 395254,
      "sip_timestamp": 1659360935279093200,
      "size": 97,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27484",
      "participant_timestamp": 1659360935206000000,
      "price": 161.0488,
      "sequence_number": 395250,
      "sip_timestamp": 1659360935271796200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935271458600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27483",
      "participant_timestamp": 1659360935196000000,
      "price": 161.0488,
      "sequence_number": 395247,
      "sip_timestamp": 1659360935267188200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935266851300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12490",
      "participant_timestamp": 1659360935265252600,
      "price": 162.0413,
      "sequence_number": 395245,
      "sip_timestamp": 1659360935266329000,
      "size": 50,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935266304500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27482",
      "participant_timestamp": 1659360935186000000,
      "price": 161.0488,
      "sequence_number": 395243,
      "sip_timestamp": 1659360935260876000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935260539400
    },
    {
      "exchange": 4,
      "id": "12489",
      "participant_timestamp": 1659360935250173000,
      "price": 162.0402,
      "sequence_number": 395241,
      "sip_timestamp": 1659360935251306500,
      "size": 1400,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935251284700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27481",
      "participant_timestamp": 1659360935166000000,
      "price": 161.0488,
      "sequence_number": 395238,
      "sip_timestamp": 1659360935243430100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935243092700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27480",
      "participant_timestamp": 1659360935175000000,
      "price": 161.0488,
      "sequence_number": 395232,
      "sip_timestamp": 1659360935224533500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935224196900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27479",
      "participant_timestamp": 1659360935155000000,
      "price": 161.0488,
      "sequence_number": 395231,
      "sip_timestamp": 1659360935216550400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935216214000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27478",
      "participant_timestamp": 1659360935145000000,
      "price": 161.0488,
      "sequence_number": 395230,
      "sip_timestamp": 1659360935216467500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935216131000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9713",
      "participant_timestamp": 1659360935201620200,
      "price": 162.04,
      "sequence_number": 395212,
      "sip_timestamp": 1659360935201636600,
      "size": 3,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1401",
      "participant_timestamp": 1659360935191360000,
      "price": 162.04,
      "sequence_number": 395185,
      "sip_timestamp": 1659360935191590700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27477",
      "participant_timestamp": 1659360935135000000,
      "price": 161.0488,
      "sequence_number": 395183,
      "sip_timestamp": 1659360935189518000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935189181000
    },
    {
      "exchange": 8,
      "id": "4836",
      "participant_timestamp": 1659360935187165000,
      "price": 162.04,
      "sequence_number": 395182,
      "sip_timestamp": 1659360935189380900,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9712",
      "participant_timestamp": 1659360935187686100,
      "price": 162.04,
      "sequence_number": 395181,
      "sip_timestamp": 1659360935187705900,
      "size": 2,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9711",
      "participant_timestamp": 1659360935187686100,
      "price": 162.04,
      "sequence_number": 395180,
      "sip_timestamp": 1659360935187704000,
      "size": 102,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8477",
      "participant_timestamp": 1659360935186025200,
      "price": 162.04,
      "sequence_number": 395147,
      "sip_timestamp": 1659360935186370600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3144",
      "participant_timestamp": 1659360935186158000,
      "price": 162.04,
      "sequence_number": 395146,
      "sip_timestamp": 1659360935186365200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9710",
      "participant_timestamp": 1659360935186016300,
      "price": 162.04,
      "sequence_number": 395139,
      "sip_timestamp": 1659360935186038300,
      "size": 26,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9709",
      "participant_timestamp": 1659360935185901600,
      "price": 162.04,
      "sequence_number": 395137,
      "sip_timestamp": 1659360935185924600,
      "size": 26,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9708",
      "participant_timestamp": 1659360935184674300,
      "price": 162.04,
      "sequence_number": 395130,
      "sip_timestamp": 1659360935184695600,
      "size": 74,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9707",
      "participant_timestamp": 1659360935184674300,
      "price": 162.04,
      "sequence_number": 395129,
      "sip_timestamp": 1659360935184693200,
      "size": 62,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4835",
      "participant_timestamp": 1659360935184465000,
      "price": 162.04,
      "sequence_number": 395128,
      "sip_timestamp": 1659360935184663800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4834",
      "participant_timestamp": 1659360935184379000,
      "price": 162.04,
      "sequence_number": 395124,
      "sip_timestamp": 1659360935184595000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4833",
      "participant_timestamp": 1659360935184205000,
      "price": 162.04,
      "sequence_number": 395120,
      "sip_timestamp": 1659360935184422000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3143",
      "participant_timestamp": 1659360935184113000,
      "price": 162.04,
      "sequence_number": 395116,
      "sip_timestamp": 1659360935184311000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9706",
      "participant_timestamp": 1659360935183671300,
      "price": 162.04,
      "sequence_number": 395110,
      "sip_timestamp": 1659360935183692800,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8476",
      "participant_timestamp": 1659360935183287600,
      "price": 162.04,
      "sequence_number": 395109,
      "sip_timestamp": 1659360935183632400,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9705",
      "participant_timestamp": 1659360935183609000,
      "price": 162.04,
      "sequence_number": 395108,
      "sip_timestamp": 1659360935183629800,
      "size": 6,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8475",
      "participant_timestamp": 1659360935183225000,
      "price": 162.04,
      "sequence_number": 395106,
      "sip_timestamp": 1659360935183569000,
      "size": 13,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9704",
      "participant_timestamp": 1659360935179620600,
      "price": 162.05,
      "sequence_number": 395087,
      "sip_timestamp": 1659360935179635500,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12488",
      "participant_timestamp": 1659360935168956700,
      "price": 162.05,
      "sequence_number": 395056,
      "sip_timestamp": 1659360935170802200,
      "size": 3,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935170779600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12487",
      "participant_timestamp": 1659360935175000000,
      "price": 162.0599,
      "sequence_number": 395054,
      "sip_timestamp": 1659360935169939200,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935169912800
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9703",
      "participant_timestamp": 1659360935169510100,
      "price": 162.05,
      "sequence_number": 395051,
      "sip_timestamp": 1659360935169525500,
      "size": 39,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9702",
      "participant_timestamp": 1659360935168884700,
      "price": 162.05,
      "sequence_number": 395047,
      "sip_timestamp": 1659360935168900600,
      "size": 24,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9701",
      "participant_timestamp": 1659360935168755500,
      "price": 162.05,
      "sequence_number": 395046,
      "sip_timestamp": 1659360935168771300,
      "size": 27,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27476",
      "participant_timestamp": 1659360935115000000,
      "price": 161.0488,
      "sequence_number": 395044,
      "sip_timestamp": 1659360935159322600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935158986000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27475",
      "participant_timestamp": 1659360935105000000,
      "price": 161.0488,
      "sequence_number": 395043,
      "sip_timestamp": 1659360935159280000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935158943200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27474",
      "participant_timestamp": 1659360935125000000,
      "price": 161.0488,
      "sequence_number": 395042,
      "sip_timestamp": 1659360935158314000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935157976300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27473",
      "participant_timestamp": 1659360935095000000,
      "price": 161.0488,
      "sequence_number": 395041,
      "sip_timestamp": 1659360935158144000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935157806800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27472",
      "participant_timestamp": 1659360935075000000,
      "price": 161.0488,
      "sequence_number": 395040,
      "sip_timestamp": 1659360935158051300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935157714000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27471",
      "participant_timestamp": 1659360935085000000,
      "price": 161.0488,
      "sequence_number": 395030,
      "sip_timestamp": 1659360935142375000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935142037000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12486",
      "participant_timestamp": 1659360935127000000,
      "price": 162.05,
      "sequence_number": 395024,
      "sip_timestamp": 1659360935127787300,
      "size": 6,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935127763500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27470",
      "participant_timestamp": 1659360935065000000,
      "price": 161.0488,
      "sequence_number": 395022,
      "sip_timestamp": 1659360935112574000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935112237000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27469",
      "participant_timestamp": 1659360935035000000,
      "price": 161.0488,
      "sequence_number": 395019,
      "sip_timestamp": 1659360935112403500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935112066600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27468",
      "participant_timestamp": 1659360935045000000,
      "price": 161.0488,
      "sequence_number": 395018,
      "sip_timestamp": 1659360935112271600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935111934700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27467",
      "participant_timestamp": 1659360935015000000,
      "price": 161.0488,
      "sequence_number": 395016,
      "sip_timestamp": 1659360935105025300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935104688000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27466",
      "participant_timestamp": 1659360935055000000,
      "price": 161.0488,
      "sequence_number": 395012,
      "sip_timestamp": 1659360935096713000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935096375600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27465",
      "participant_timestamp": 1659360935025000000,
      "price": 161.0488,
      "sequence_number": 395011,
      "sip_timestamp": 1659360935096569300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935096232400
    },
    {
      "exchange": 4,
      "id": "27464",
      "participant_timestamp": 1659360935087000000,
      "price": 162.05,
      "sequence_number": 395008,
      "sip_timestamp": 1659360935089024300,
      "size": 389,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935088685300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27463",
      "participant_timestamp": 1659360935006000000,
      "price": 161.0488,
      "sequence_number": 394992,
      "sip_timestamp": 1659360935058732800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935058395100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27462",
      "participant_timestamp": 1659360935054000000,
      "price": 162.0699,
      "sequence_number": 394989,
      "sip_timestamp": 1659360935057069800,
      "size": 25,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935056733200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27461",
      "participant_timestamp": 1659360934995000000,
      "price": 161.0488,
      "sequence_number": 394988,
      "sip_timestamp": 1659360935050982700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935050645800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27460",
      "participant_timestamp": 1659360934943000000,
      "price": 161.0488,
      "sequence_number": 394986,
      "sip_timestamp": 1659360935042836200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935042497800
    },
    {
      "exchange": 4,
      "id": "27459",
      "participant_timestamp": 1659360935039000000,
      "price": 162.0527,
      "sequence_number": 394982,
      "sip_timestamp": 1659360935041590000,
      "size": 300,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935041253400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27458",
      "participant_timestamp": 1659360934975000000,
      "price": 161.0488,
      "sequence_number": 394980,
      "sip_timestamp": 1659360935041054200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935040715800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12485",
      "participant_timestamp": 1659360934750322000,
      "price": 162.077,
      "sequence_number": 394974,
      "sip_timestamp": 1659360935021235700,
      "size": 15,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935021213000
    },
    {
      "exchange": 4,
      "id": "12484",
      "participant_timestamp": 1659360934746472400,
      "price": 162.077,
      "sequence_number": 394973,
      "sip_timestamp": 1659360935021150000,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360935021124400
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "762",
      "participant_timestamp": 1659360935006422000,
      "price": 162.05,
      "sequence_number": 394953,
      "sip_timestamp": 1659360935006626600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3142",
      "participant_timestamp": 1659360935003911000,
      "price": 162.06,
      "sequence_number": 394950,
      "sip_timestamp": 1659360935004106200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27457",
      "participant_timestamp": 1659360934965000000,
      "price": 161.0488,
      "sequence_number": 394946,
      "sip_timestamp": 1659360935003038700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935002700000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27456",
      "participant_timestamp": 1659360934933000000,
      "price": 161.0488,
      "sequence_number": 394945,
      "sip_timestamp": 1659360935002989000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935002651400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27455",
      "participant_timestamp": 1659360934914000000,
      "price": 161.0488,
      "sequence_number": 394943,
      "sip_timestamp": 1659360935002775600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360935002438700
    },
    {
      "exchange": 8,
      "id": "4832",
      "participant_timestamp": 1659360935001866000,
      "price": 162.05,
      "sequence_number": 394938,
      "sip_timestamp": 1659360935002085400,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1400",
      "participant_timestamp": 1659360935001632300,
      "price": 162.05,
      "sequence_number": 394935,
      "sip_timestamp": 1659360935002006300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8474",
      "participant_timestamp": 1659360935000129800,
      "price": 162.05,
      "sequence_number": 394931,
      "sip_timestamp": 1659360935000479700,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8473",
      "participant_timestamp": 1659360935000114200,
      "price": 162.05,
      "sequence_number": 394930,
      "sip_timestamp": 1659360935000467200,
      "size": 98,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8472",
      "participant_timestamp": 1659360935000114200,
      "price": 162.05,
      "sequence_number": 394929,
      "sip_timestamp": 1659360935000467200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3141",
      "participant_timestamp": 1659360935000088000,
      "price": 162.05,
      "sequence_number": 394928,
      "sip_timestamp": 1659360935000277800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3140",
      "participant_timestamp": 1659360935000053000,
      "price": 162.05,
      "sequence_number": 394927,
      "sip_timestamp": 1659360935000248300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3139",
      "participant_timestamp": 1659360935000026000,
      "price": 162.05,
      "sequence_number": 394925,
      "sip_timestamp": 1659360935000243700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9700",
      "participant_timestamp": 1659360935000053200,
      "price": 162.05,
      "sequence_number": 394921,
      "sip_timestamp": 1659360935000068400,
      "size": 75,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9699",
      "participant_timestamp": 1659360934999917000,
      "price": 162.05,
      "sequence_number": 394920,
      "sip_timestamp": 1659360934999935000,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9698",
      "participant_timestamp": 1659360934999917000,
      "price": 162.05,
      "sequence_number": 394919,
      "sip_timestamp": 1659360934999933200,
      "size": 15,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9697",
      "participant_timestamp": 1659360934999908900,
      "price": 162.05,
      "sequence_number": 394918,
      "sip_timestamp": 1659360934999924500,
      "size": 40,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9696",
      "participant_timestamp": 1659360934999875600,
      "price": 162.05,
      "sequence_number": 394917,
      "sip_timestamp": 1659360934999894800,
      "size": 60,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9695",
      "participant_timestamp": 1659360934999875600,
      "price": 162.05,
      "sequence_number": 394916,
      "sip_timestamp": 1659360934999893000,
      "size": 99,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27454",
      "participant_timestamp": 1659360934924000000,
      "price": 161.0488,
      "sequence_number": 394903,
      "sip_timestamp": 1659360934985479200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934985142000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12483",
      "participant_timestamp": 1659360934959264500,
      "price": 162.0521,
      "sequence_number": 394898,
      "sip_timestamp": 1659360934960407600,
      "size": 5,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934960382700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27453",
      "participant_timestamp": 1659360934903000000,
      "price": 161.0488,
      "sequence_number": 394897,
      "sip_timestamp": 1659360934956213800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934955876900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27452",
      "participant_timestamp": 1659360934893000000,
      "price": 161.0488,
      "sequence_number": 394894,
      "sip_timestamp": 1659360934955455700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934955118600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27451",
      "participant_timestamp": 1659360934873000000,
      "price": 161.0488,
      "sequence_number": 394890,
      "sip_timestamp": 1659360934939243000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934938905600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27450",
      "participant_timestamp": 1659360934883000000,
      "price": 161.0488,
      "sequence_number": 394889,
      "sip_timestamp": 1659360934935678000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934935340300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27449",
      "participant_timestamp": 1659360934932000000,
      "price": 162.0577,
      "sequence_number": 394887,
      "sip_timestamp": 1659360934934177000,
      "size": 50,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934933838800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27448",
      "participant_timestamp": 1659360934834000000,
      "price": 161.0488,
      "sequence_number": 394880,
      "sip_timestamp": 1659360934918577400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934918240300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27447",
      "participant_timestamp": 1659360934844000000,
      "price": 161.0488,
      "sequence_number": 394872,
      "sip_timestamp": 1659360934909969700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934909632000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27446",
      "participant_timestamp": 1659360934863000000,
      "price": 161.0488,
      "sequence_number": 394870,
      "sip_timestamp": 1659360934909144600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934908808200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27445",
      "participant_timestamp": 1659360934853000000,
      "price": 161.0488,
      "sequence_number": 394838,
      "sip_timestamp": 1659360934894369300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934894032400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27444",
      "participant_timestamp": 1659360934824000000,
      "price": 161.0488,
      "sequence_number": 394834,
      "sip_timestamp": 1659360934893002200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934892663800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9694",
      "participant_timestamp": 1659360934884300500,
      "price": 162.05,
      "sequence_number": 394831,
      "sip_timestamp": 1659360934884316700,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4831",
      "participant_timestamp": 1659360934872731000,
      "price": 162.06,
      "sequence_number": 394825,
      "sip_timestamp": 1659360934872948500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 15,
      "id": "597",
      "participant_timestamp": 1659360934868143600,
      "price": 162.06,
      "sequence_number": 394815,
      "sip_timestamp": 1659360934868423000,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12482",
      "participant_timestamp": 1659360934861739300,
      "price": 162.06,
      "sequence_number": 394814,
      "sip_timestamp": 1659360934867387000,
      "size": 188,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934867359500
    },
    {
      "exchange": 15,
      "id": "596",
      "participant_timestamp": 1659360934864581600,
      "price": 162.06,
      "sequence_number": 394812,
      "sip_timestamp": 1659360934864836900,
      "size": 112,
      "tape": 3
    },
    {
      "exchange": 15,
      "id": "595",
      "participant_timestamp": 1659360934863110100,
      "price": 162.06,
      "sequence_number": 394796,
      "sip_timestamp": 1659360934863368200,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12481",
      "participant_timestamp": 1659360934862992400,
      "price": 162.06,
      "sequence_number": 394795,
      "sip_timestamp": 1659360934863353900,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934863330600
    },
    {
      "exchange": 2,
      "id": "118",
      "participant_timestamp": 1659360934862784300,
      "price": 162.06,
      "sequence_number": 394793,
      "sip_timestamp": 1659360934862801000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27443",
      "participant_timestamp": 1659360934794000000,
      "price": 161.0488,
      "sequence_number": 394790,
      "sip_timestamp": 1659360934862367700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934862029800
    },
    {
      "exchange": 18,
      "id": "148",
      "participant_timestamp": 1659360934862149000,
      "price": 162.06,
      "sequence_number": 394789,
      "sip_timestamp": 1659360934862355500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 7,
      "id": "761",
      "participant_timestamp": 1659360934862152000,
      "price": 162.06,
      "sequence_number": 394788,
      "sip_timestamp": 1659360934862353700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 2,
      "id": "117",
      "participant_timestamp": 1659360934862277600,
      "price": 162.06,
      "sequence_number": 394783,
      "sip_timestamp": 1659360934862295000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27442",
      "participant_timestamp": 1659360934814000000,
      "price": 161.0488,
      "sequence_number": 394781,
      "sip_timestamp": 1659360934861799200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934861460500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27441",
      "participant_timestamp": 1659360934804000000,
      "price": 161.0488,
      "sequence_number": 394725,
      "sip_timestamp": 1659360934846458400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934846120700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27440",
      "participant_timestamp": 1659360934784000000,
      "price": 161.0488,
      "sequence_number": 394715,
      "sip_timestamp": 1659360934845533000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934845196000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12480",
      "participant_timestamp": 1659360934833000000,
      "price": 162.08,
      "sequence_number": 394696,
      "sip_timestamp": 1659360934835145200,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934835121400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27439",
      "participant_timestamp": 1659360934743000000,
      "price": 161.0488,
      "sequence_number": 394694,
      "sip_timestamp": 1659360934829471700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934829133600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27438",
      "participant_timestamp": 1659360934753000000,
      "price": 161.0488,
      "sequence_number": 394670,
      "sip_timestamp": 1659360934816772000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934816435200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27437",
      "participant_timestamp": 1659360934774000000,
      "price": 161.0488,
      "sequence_number": 394669,
      "sip_timestamp": 1659360934816524800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934816188400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27436",
      "participant_timestamp": 1659360934763000000,
      "price": 161.0488,
      "sequence_number": 394662,
      "sip_timestamp": 1659360934809936100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934809598700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27435",
      "participant_timestamp": 1659360934733000000,
      "price": 161.0488,
      "sequence_number": 394516,
      "sip_timestamp": 1659360934799979300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934799642600
    },
    {
      "exchange": 4,
      "id": "12479",
      "participant_timestamp": 1659360934783000000,
      "price": 162.07,
      "sequence_number": 394505,
      "sip_timestamp": 1659360934784995600,
      "size": 585,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934784973800
    },
    {
      "exchange": 4,
      "id": "12478",
      "participant_timestamp": 1659360934775000000,
      "price": 162.07,
      "sequence_number": 394499,
      "sip_timestamp": 1659360934777927700,
      "size": 175,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934777903600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27434",
      "participant_timestamp": 1659360934702000000,
      "price": 161.0488,
      "sequence_number": 394488,
      "sip_timestamp": 1659360934768744000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934768405800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27433",
      "participant_timestamp": 1659360934722000000,
      "price": 161.0488,
      "sequence_number": 394487,
      "sip_timestamp": 1659360934768158200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934767820500
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8471",
      "participant_timestamp": 1659360934756004400,
      "price": 162.07,
      "sequence_number": 394465,
      "sip_timestamp": 1659360934756354800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8470",
      "participant_timestamp": 1659360934756004400,
      "price": 162.07,
      "sequence_number": 394464,
      "sip_timestamp": 1659360934756354800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9693",
      "participant_timestamp": 1659360934755979800,
      "price": 162.07,
      "sequence_number": 394458,
      "sip_timestamp": 1659360934755995000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27432",
      "participant_timestamp": 1659360934691000000,
      "price": 161.0488,
      "sequence_number": 394428,
      "sip_timestamp": 1659360934751853000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934751516000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27431",
      "participant_timestamp": 1659360934681000000,
      "price": 161.0488,
      "sequence_number": 394427,
      "sip_timestamp": 1659360934751145200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934750808000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27430",
      "participant_timestamp": 1659360934712000000,
      "price": 161.0488,
      "sequence_number": 394424,
      "sip_timestamp": 1659360934744643300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934744306200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27429",
      "participant_timestamp": 1659360934659000000,
      "price": 161.0488,
      "sequence_number": 394385,
      "sip_timestamp": 1659360934721652500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934721313000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27428",
      "participant_timestamp": 1659360934669000000,
      "price": 161.0488,
      "sequence_number": 394366,
      "sip_timestamp": 1659360934714264800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934713927400
    },
    {
      "exchange": 4,
      "id": "27427",
      "participant_timestamp": 1659360934702000000,
      "price": 162.0793,
      "sequence_number": 394357,
      "sip_timestamp": 1659360934704707000,
      "size": 200,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934704370200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27426",
      "participant_timestamp": 1659360934648000000,
      "price": 161.0488,
      "sequence_number": 394356,
      "sip_timestamp": 1659360934704117500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934703779800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27425",
      "participant_timestamp": 1659360934638000000,
      "price": 161.0488,
      "sequence_number": 394347,
      "sip_timestamp": 1659360934676870700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934676534000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27424",
      "participant_timestamp": 1659360934605000000,
      "price": 161.0488,
      "sequence_number": 394345,
      "sip_timestamp": 1659360934676700700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934676363000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27423",
      "participant_timestamp": 1659360934626000000,
      "price": 161.0488,
      "sequence_number": 394344,
      "sip_timestamp": 1659360934674937300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934674599700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27422",
      "participant_timestamp": 1659360934616000000,
      "price": 161.0488,
      "sequence_number": 394342,
      "sip_timestamp": 1659360934674252300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934673914400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27421",
      "participant_timestamp": 1659360934570000000,
      "price": 162.0876,
      "sequence_number": 394340,
      "sip_timestamp": 1659360934662975000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934662636800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27420",
      "participant_timestamp": 1659360934594000000,
      "price": 161.0488,
      "sequence_number": 394339,
      "sip_timestamp": 1659360934658020900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934657683500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12477",
      "participant_timestamp": 1659360934634730200,
      "price": 162.09,
      "sequence_number": 394328,
      "sip_timestamp": 1659360934635018200,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934634995700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27419",
      "participant_timestamp": 1659360934577000000,
      "price": 161.0488,
      "sequence_number": 394318,
      "sip_timestamp": 1659360934627429400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934627091700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27418",
      "participant_timestamp": 1659360934584000000,
      "price": 161.0488,
      "sequence_number": 394297,
      "sip_timestamp": 1659360934611843800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934611507000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27417",
      "participant_timestamp": 1659360934551000000,
      "price": 161.0488,
      "sequence_number": 394296,
      "sip_timestamp": 1659360934611059000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934610722300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12476",
      "participant_timestamp": 1659360934545000000,
      "price": 162.0888,
      "sequence_number": 394273,
      "sip_timestamp": 1659360934569173200,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934569151200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27416",
      "participant_timestamp": 1659360934535000000,
      "price": 161.0488,
      "sequence_number": 394243,
      "sip_timestamp": 1659360934565497600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934565161000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27415",
      "participant_timestamp": 1659360934516000000,
      "price": 161.0488,
      "sequence_number": 394242,
      "sip_timestamp": 1659360934565420800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934565079000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27414",
      "participant_timestamp": 1659360934506000000,
      "price": 161.0488,
      "sequence_number": 394240,
      "sip_timestamp": 1659360934564250000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934563913700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12475",
      "participant_timestamp": 1659360934568000000,
      "price": 162.08,
      "sequence_number": 394238,
      "sip_timestamp": 1659360934561930800,
      "size": 5,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934561905400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27413",
      "participant_timestamp": 1659360934467000000,
      "price": 161.0488,
      "sequence_number": 394237,
      "sip_timestamp": 1659360934560092700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934559755800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27412",
      "participant_timestamp": 1659360934544733000,
      "price": 162.07,
      "sequence_number": 394234,
      "sip_timestamp": 1659360934545962500,
      "size": 2,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934545625000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27411",
      "participant_timestamp": 1659360934486000000,
      "price": 161.0488,
      "sequence_number": 394232,
      "sip_timestamp": 1659360934534254800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934533918200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27410",
      "participant_timestamp": 1659360934477000000,
      "price": 161.0488,
      "sequence_number": 394230,
      "sip_timestamp": 1659360934533589800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934533252900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27409",
      "participant_timestamp": 1659360934496000000,
      "price": 161.0488,
      "sequence_number": 394222,
      "sip_timestamp": 1659360934525982000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934525643500
    },
    {
      "exchange": 4,
      "id": "12474",
      "participant_timestamp": 1659360934518036500,
      "price": 162.08,
      "sequence_number": 394221,
      "sip_timestamp": 1659360934518156300,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934518130400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9692",
      "participant_timestamp": 1659360934517749500,
      "price": 162.07,
      "sequence_number": 394220,
      "sip_timestamp": 1659360934517769700,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9691",
      "participant_timestamp": 1659360934517749500,
      "price": 162.07,
      "sequence_number": 394219,
      "sip_timestamp": 1659360934517767200,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9690",
      "participant_timestamp": 1659360934517749500,
      "price": 162.07,
      "sequence_number": 394218,
      "sip_timestamp": 1659360934517765400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27408",
      "participant_timestamp": 1659360934457000000,
      "price": 161.0488,
      "sequence_number": 394214,
      "sip_timestamp": 1659360934517302500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934516965600
    },
    {
      "exchange": 4,
      "id": "27407",
      "participant_timestamp": 1659360934513000000,
      "price": 162.07,
      "sequence_number": 394212,
      "sip_timestamp": 1659360934516422100,
      "size": 2000,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934516084500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27406",
      "participant_timestamp": 1659360934447000000,
      "price": 161.0488,
      "sequence_number": 394207,
      "sip_timestamp": 1659360934501878800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934501542000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27405",
      "participant_timestamp": 1659360934437000000,
      "price": 161.0488,
      "sequence_number": 394201,
      "sip_timestamp": 1659360934487290400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934486953200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27404",
      "participant_timestamp": 1659360934427000000,
      "price": 161.0488,
      "sequence_number": 394200,
      "sip_timestamp": 1659360934486667000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934486328800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12473",
      "participant_timestamp": 1659360933959129600,
      "price": 162.095,
      "sequence_number": 394199,
      "sip_timestamp": 1659360934481561000,
      "size": 14,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934481536800
    },
    {
      "exchange": 4,
      "id": "12472",
      "participant_timestamp": 1659360933913802200,
      "price": 162.096,
      "sequence_number": 394198,
      "sip_timestamp": 1659360934481472000,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934481448400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27403",
      "participant_timestamp": 1659360934407000000,
      "price": 161.0488,
      "sequence_number": 394190,
      "sip_timestamp": 1659360934470383900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934470047000
    },
    {
      "exchange": 8,
      "id": "4830",
      "participant_timestamp": 1659360934465102000,
      "price": 162.08,
      "sequence_number": 394188,
      "sip_timestamp": 1659360934465304600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9689",
      "participant_timestamp": 1659360934465152500,
      "price": 162.08,
      "sequence_number": 394187,
      "sip_timestamp": 1659360934465168000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4829",
      "participant_timestamp": 1659360934458062000,
      "price": 162.09,
      "sequence_number": 394184,
      "sip_timestamp": 1659360934458269000,
      "size": 20,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27402",
      "participant_timestamp": 1659360934417000000,
      "price": 161.0488,
      "sequence_number": 394182,
      "sip_timestamp": 1659360934456145200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934455807700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27401",
      "participant_timestamp": 1659360934387000000,
      "price": 161.0488,
      "sequence_number": 394180,
      "sip_timestamp": 1659360934455983000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934455646500
    },
    {
      "exchange": 11,
      "id": "8469",
      "participant_timestamp": 1659360934452776000,
      "price": 162.09,
      "sequence_number": 394175,
      "sip_timestamp": 1659360934453122300,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27400",
      "participant_timestamp": 1659360934378000000,
      "price": 161.0488,
      "sequence_number": 394171,
      "sip_timestamp": 1659360934440473300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934440136700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27399",
      "participant_timestamp": 1659360934397000000,
      "price": 161.0488,
      "sequence_number": 394170,
      "sip_timestamp": 1659360934439934200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934439594500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3138",
      "participant_timestamp": 1659360934431672000,
      "price": 162.09,
      "sequence_number": 394164,
      "sip_timestamp": 1659360934431869000,
      "size": 99,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9688",
      "participant_timestamp": 1659360934431724300,
      "price": 162.09,
      "sequence_number": 394163,
      "sip_timestamp": 1659360934431743000,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9687",
      "participant_timestamp": 1659360934431724300,
      "price": 162.09,
      "sequence_number": 394162,
      "sip_timestamp": 1659360934431740400,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3137",
      "participant_timestamp": 1659360934431319000,
      "price": 162.09,
      "sequence_number": 394161,
      "sip_timestamp": 1659360934431516700,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27398",
      "participant_timestamp": 1659360934358000000,
      "price": 161.0488,
      "sequence_number": 394160,
      "sip_timestamp": 1659360934425075200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934424736800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27397",
      "participant_timestamp": 1659360934368000000,
      "price": 161.0488,
      "sequence_number": 394150,
      "sip_timestamp": 1659360934407107800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934406769200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27396",
      "participant_timestamp": 1659360934329000000,
      "price": 161.0488,
      "sequence_number": 394141,
      "sip_timestamp": 1659360934393503700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934393166800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27395",
      "participant_timestamp": 1659360934348000000,
      "price": 161.0488,
      "sequence_number": 394140,
      "sip_timestamp": 1659360934393099800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934392761600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27394",
      "participant_timestamp": 1659360934339000000,
      "price": 161.0488,
      "sequence_number": 394138,
      "sip_timestamp": 1659360934390283500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934389947100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27393",
      "participant_timestamp": 1659360934309000000,
      "price": 161.0488,
      "sequence_number": 394137,
      "sip_timestamp": 1659360934390120200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934389782800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27392",
      "participant_timestamp": 1659360934319000000,
      "price": 161.0488,
      "sequence_number": 394133,
      "sip_timestamp": 1659360934376756000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934376419000
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8468",
      "participant_timestamp": 1659360934358473200,
      "price": 162.09,
      "sequence_number": 394128,
      "sip_timestamp": 1659360934358819800,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27391",
      "participant_timestamp": 1659360934289000000,
      "price": 161.0488,
      "sequence_number": 394125,
      "sip_timestamp": 1659360934347579400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934347242200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27390",
      "participant_timestamp": 1659360934299000000,
      "price": 161.0488,
      "sequence_number": 394122,
      "sip_timestamp": 1659360934346782500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934346444500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27389",
      "participant_timestamp": 1659360934259000000,
      "price": 161.0488,
      "sequence_number": 394117,
      "sip_timestamp": 1659360934330686500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934330350000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27388",
      "participant_timestamp": 1659360934269000000,
      "price": 161.0488,
      "sequence_number": 394116,
      "sip_timestamp": 1659360934329840000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934329502200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27387",
      "participant_timestamp": 1659360934279000000,
      "price": 161.0488,
      "sequence_number": 394102,
      "sip_timestamp": 1659360934324008200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934323670800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27386",
      "participant_timestamp": 1659360934239000000,
      "price": 161.0488,
      "sequence_number": 394086,
      "sip_timestamp": 1659360934299740200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934299403300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27385",
      "participant_timestamp": 1659360934229000000,
      "price": 161.0488,
      "sequence_number": 394084,
      "sip_timestamp": 1659360934299212800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934298876000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27384",
      "participant_timestamp": 1659360934249000000,
      "price": 161.0488,
      "sequence_number": 394082,
      "sip_timestamp": 1659360934283261400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934282923000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27383",
      "participant_timestamp": 1659360934219000000,
      "price": 161.0488,
      "sequence_number": 394077,
      "sip_timestamp": 1659360934282996500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934282658600
    },
    {
      "exchange": 4,
      "id": "12471",
      "participant_timestamp": 1659360934272000000,
      "price": 162.09,
      "sequence_number": 394076,
      "sip_timestamp": 1659360934274980900,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934274955800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27382",
      "participant_timestamp": 1659360934189000000,
      "price": 161.0488,
      "sequence_number": 394056,
      "sip_timestamp": 1659360934253104400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934252767500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27381",
      "participant_timestamp": 1659360934199000000,
      "price": 161.0488,
      "sequence_number": 394054,
      "sip_timestamp": 1659360934252388900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934252051700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27380",
      "participant_timestamp": 1659360934179000000,
      "price": 161.0488,
      "sequence_number": 394053,
      "sip_timestamp": 1659360934252332300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934251993900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27379",
      "participant_timestamp": 1659360934159000000,
      "price": 161.0488,
      "sequence_number": 394032,
      "sip_timestamp": 1659360934244863200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934244524000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12470",
      "participant_timestamp": 1659360934240000000,
      "price": 162.08,
      "sequence_number": 394028,
      "sip_timestamp": 1659360934242245000,
      "size": 50,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934242217200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12469",
      "participant_timestamp": 1659360934217000000,
      "price": 162.09,
      "sequence_number": 394020,
      "sip_timestamp": 1659360934211281700,
      "size": 50,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934211257600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27378",
      "participant_timestamp": 1659360934149000000,
      "price": 161.0488,
      "sequence_number": 394018,
      "sip_timestamp": 1659360934206227000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934205890000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27377",
      "participant_timestamp": 1659360934169000000,
      "price": 161.0488,
      "sequence_number": 394011,
      "sip_timestamp": 1659360934202509800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934202172400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27376",
      "participant_timestamp": 1659360934120000000,
      "price": 161.0488,
      "sequence_number": 394009,
      "sip_timestamp": 1659360934189291300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934188954000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27375",
      "participant_timestamp": 1659360934130000000,
      "price": 161.0488,
      "sequence_number": 394008,
      "sip_timestamp": 1659360934189129700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934188792600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27374",
      "participant_timestamp": 1659360934139000000,
      "price": 161.0488,
      "sequence_number": 394006,
      "sip_timestamp": 1659360934185641700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934185303300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27373",
      "participant_timestamp": 1659360934110000000,
      "price": 161.0488,
      "sequence_number": 394003,
      "sip_timestamp": 1659360934173622000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934173282000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12468",
      "participant_timestamp": 1659360934170548000,
      "price": 162.0983,
      "sequence_number": 394001,
      "sip_timestamp": 1659360934171863000,
      "size": 33,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934171838500
    },
    {
      "exchange": 8,
      "id": "4828",
      "participant_timestamp": 1659360934159583000,
      "price": 162.08,
      "sequence_number": 393994,
      "sip_timestamp": 1659360934159805000,
      "size": 200,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4827",
      "participant_timestamp": 1659360934159583000,
      "price": 162.08,
      "sequence_number": 393993,
      "sip_timestamp": 1659360934159798800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27372",
      "participant_timestamp": 1659360934100000000,
      "price": 161.0488,
      "sequence_number": 393992,
      "sip_timestamp": 1659360934159340300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934159003600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27371",
      "participant_timestamp": 1659360934090000000,
      "price": 161.0488,
      "sequence_number": 393991,
      "sip_timestamp": 1659360934159269600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934158932200
    },
    {
      "exchange": 4,
      "id": "27370",
      "participant_timestamp": 1659360934150000000,
      "price": 162.0832,
      "sequence_number": 393978,
      "sip_timestamp": 1659360934152560400,
      "size": 300,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934152223500
    },
    {
      "exchange": 8,
      "id": "4826",
      "participant_timestamp": 1659360934149230000,
      "price": 162.08,
      "sequence_number": 393971,
      "sip_timestamp": 1659360934149437400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 7,
      "id": "760",
      "participant_timestamp": 1659360934149238000,
      "price": 162.08,
      "sequence_number": 393970,
      "sip_timestamp": 1659360934149433000,
      "size": 85,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 7,
      "id": "759",
      "participant_timestamp": 1659360934149125000,
      "price": 162.08,
      "sequence_number": 393969,
      "sip_timestamp": 1659360934149330200,
      "size": 15,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1399",
      "participant_timestamp": 1659360934149122600,
      "price": 162.08,
      "sequence_number": 393968,
      "sip_timestamp": 1659360934149315000,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4825",
      "participant_timestamp": 1659360934149108000,
      "price": 162.08,
      "sequence_number": 393967,
      "sip_timestamp": 1659360934149310500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9686",
      "participant_timestamp": 1659360934149158100,
      "price": 162.08,
      "sequence_number": 393966,
      "sip_timestamp": 1659360934149173500,
      "size": 4071,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3136",
      "participant_timestamp": 1659360934148974000,
      "price": 162.08,
      "sequence_number": 393965,
      "sip_timestamp": 1659360934149166800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9685",
      "participant_timestamp": 1659360934149137700,
      "price": 162.08,
      "sequence_number": 393964,
      "sip_timestamp": 1659360934149152500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4824",
      "participant_timestamp": 1659360934148201000,
      "price": 162.08,
      "sequence_number": 393958,
      "sip_timestamp": 1659360934148419800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3135",
      "participant_timestamp": 1659360934148182000,
      "price": 162.08,
      "sequence_number": 393956,
      "sip_timestamp": 1659360934148396000,
      "size": 63,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3134",
      "participant_timestamp": 1659360934148182000,
      "price": 162.08,
      "sequence_number": 393955,
      "sip_timestamp": 1659360934148382500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9684",
      "participant_timestamp": 1659360934148301000,
      "price": 162.08,
      "sequence_number": 393954,
      "sip_timestamp": 1659360934148318700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9683",
      "participant_timestamp": 1659360934148301000,
      "price": 162.09,
      "sequence_number": 393953,
      "sip_timestamp": 1659360934148317000,
      "size": 311,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8467",
      "participant_timestamp": 1659360934147835000,
      "price": 162.08,
      "sequence_number": 393952,
      "sip_timestamp": 1659360934148190000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9682",
      "participant_timestamp": 1659360934148168000,
      "price": 162.09,
      "sequence_number": 393951,
      "sip_timestamp": 1659360934148188200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9681",
      "participant_timestamp": 1659360934148167700,
      "price": 162.09,
      "sequence_number": 393950,
      "sip_timestamp": 1659360934148186400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9680",
      "participant_timestamp": 1659360934148166100,
      "price": 162.09,
      "sequence_number": 393949,
      "sip_timestamp": 1659360934148183600,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9679",
      "participant_timestamp": 1659360934148166000,
      "price": 162.09,
      "sequence_number": 393948,
      "sip_timestamp": 1659360934148182500,
      "size": 1,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12467",
      "participant_timestamp": 1659360934144000000,
      "price": 162.08,
      "sequence_number": 393944,
      "sip_timestamp": 1659360934145419500,
      "size": 255,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934145396700
    },
    {
      "exchange": 4,
      "id": "12466",
      "participant_timestamp": 1659360934143416300,
      "price": 162.0838,
      "sequence_number": 393943,
      "sip_timestamp": 1659360934144894200,
      "size": 403,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934144870400
    },
    {
      "exchange": 4,
      "id": "12465",
      "participant_timestamp": 1659360934143291000,
      "price": 162.08,
      "sequence_number": 393942,
      "sip_timestamp": 1659360934144048400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934144024800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27369",
      "participant_timestamp": 1659360934080000000,
      "price": 161.0488,
      "sequence_number": 393939,
      "sip_timestamp": 1659360934141740000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934141400600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27368",
      "participant_timestamp": 1659360934070000000,
      "price": 161.0488,
      "sequence_number": 393928,
      "sip_timestamp": 1659360934112486400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934112144100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27367",
      "participant_timestamp": 1659360934060000000,
      "price": 161.0488,
      "sequence_number": 393926,
      "sip_timestamp": 1659360934112363500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934112027000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27366",
      "participant_timestamp": 1659360934040000000,
      "price": 161.0488,
      "sequence_number": 393925,
      "sip_timestamp": 1659360934112322300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934111985200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27365",
      "participant_timestamp": 1659360934050000000,
      "price": 161.0488,
      "sequence_number": 393897,
      "sip_timestamp": 1659360934096772900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934096435200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27364",
      "participant_timestamp": 1659360934030000000,
      "price": 161.0488,
      "sequence_number": 393895,
      "sip_timestamp": 1659360934094766600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934094426000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27363",
      "participant_timestamp": 1659360934020000000,
      "price": 161.0488,
      "sequence_number": 393840,
      "sip_timestamp": 1659360934075965000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934075627500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12464",
      "participant_timestamp": 1659360934077000000,
      "price": 162.0801,
      "sequence_number": 393839,
      "sip_timestamp": 1659360934071324400,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934071300400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27362",
      "participant_timestamp": 1659360934001000000,
      "price": 161.0488,
      "sequence_number": 393836,
      "sip_timestamp": 1659360934065390800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934065053400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27361",
      "participant_timestamp": 1659360933991000000,
      "price": 161.0488,
      "sequence_number": 393833,
      "sip_timestamp": 1659360934063090700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934062754000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12463",
      "participant_timestamp": 1659360933672000000,
      "price": 162.1,
      "sequence_number": 393832,
      "sip_timestamp": 1659360934062964700,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934062942500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27360",
      "participant_timestamp": 1659360934011000000,
      "price": 161.0488,
      "sequence_number": 393824,
      "sip_timestamp": 1659360934057803500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934057466600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27359",
      "participant_timestamp": 1659360933980000000,
      "price": 161.0488,
      "sequence_number": 393823,
      "sip_timestamp": 1659360934057632000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934057295000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27358",
      "participant_timestamp": 1659360933949000000,
      "price": 161.0488,
      "sequence_number": 393822,
      "sip_timestamp": 1659360934056835300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934056498400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12462",
      "participant_timestamp": 1659360933368317000,
      "price": 162.08,
      "sequence_number": 393819,
      "sip_timestamp": 1659360934049748500,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934049723600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27357",
      "participant_timestamp": 1659360933990000000,
      "price": 162.09,
      "sequence_number": 393816,
      "sip_timestamp": 1659360934047906300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934047568100
    },
    {
      "exchange": 4,
      "id": "12461",
      "participant_timestamp": 1659360934028000000,
      "price": 162.08,
      "sequence_number": 393807,
      "sip_timestamp": 1659360934022137300,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360934022107100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27356",
      "participant_timestamp": 1659360933969000000,
      "price": 161.0488,
      "sequence_number": 393806,
      "sip_timestamp": 1659360934020501800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934020164900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27355",
      "participant_timestamp": 1659360933959000000,
      "price": 161.0488,
      "sequence_number": 393803,
      "sip_timestamp": 1659360934019819500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934019482000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27354",
      "participant_timestamp": 1659360933929000000,
      "price": 161.0488,
      "sequence_number": 393800,
      "sip_timestamp": 1659360934003216000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360934002877400
    },
    {
      "exchange": 1,
      "id": "17",
      "participant_timestamp": 1659360933974035500,
      "price": 162.08,
      "sequence_number": 393731,
      "sip_timestamp": 1659360933974388700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8466",
      "participant_timestamp": 1659360933974016500,
      "price": 162.08,
      "sequence_number": 393730,
      "sip_timestamp": 1659360933974365700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9678",
      "participant_timestamp": 1659360933974263800,
      "price": 162.08,
      "sequence_number": 393729,
      "sip_timestamp": 1659360933974280200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3133",
      "participant_timestamp": 1659360933973909000,
      "price": 162.08,
      "sequence_number": 393728,
      "sip_timestamp": 1659360933974107100,
      "size": 20,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9677",
      "participant_timestamp": 1659360933973775000,
      "price": 162.08,
      "sequence_number": 393726,
      "sip_timestamp": 1659360933973789700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27353",
      "participant_timestamp": 1659360933919000000,
      "price": 161.0488,
      "sequence_number": 393719,
      "sip_timestamp": 1659360933972078800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933971742200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27352",
      "participant_timestamp": 1659360933909000000,
      "price": 161.0488,
      "sequence_number": 393717,
      "sip_timestamp": 1659360933971341800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933971004700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27351",
      "participant_timestamp": 1659360933953381000,
      "price": 162.0984,
      "sequence_number": 393714,
      "sip_timestamp": 1659360933955276500,
      "size": 14,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933954938600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27350",
      "participant_timestamp": 1659360933889000000,
      "price": 161.0488,
      "sequence_number": 393713,
      "sip_timestamp": 1659360933954505500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933954168600
    },
    {
      "exchange": 4,
      "id": "12460",
      "participant_timestamp": 1659360933952016000,
      "price": 162.095,
      "sequence_number": 393712,
      "sip_timestamp": 1659360933952306700,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933952284000
    },
    {
      "exchange": 4,
      "id": "12459",
      "participant_timestamp": 1659360933950854700,
      "price": 162.087,
      "sequence_number": 393711,
      "sip_timestamp": 1659360933951903500,
      "size": 150,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933951881000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12458",
      "participant_timestamp": 1659360933950605800,
      "price": 162.0912,
      "sequence_number": 393710,
      "sip_timestamp": 1659360933951733800,
      "size": 12,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933951708200
    },
    {
      "exchange": 4,
      "id": "12457",
      "participant_timestamp": 1659360933955000000,
      "price": 162.095,
      "sequence_number": 393709,
      "sip_timestamp": 1659360933949665300,
      "size": 2000,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933949642800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12456",
      "participant_timestamp": 1659360933955000000,
      "price": 162.1099,
      "sequence_number": 393708,
      "sip_timestamp": 1659360933949198600,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933949172700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27349",
      "participant_timestamp": 1659360933899000000,
      "price": 161.0488,
      "sequence_number": 393707,
      "sip_timestamp": 1659360933940427300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933940090600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27348",
      "participant_timestamp": 1659360933865000000,
      "price": 161.0488,
      "sequence_number": 393706,
      "sip_timestamp": 1659360933940266200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933939929600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3132",
      "participant_timestamp": 1659360933931400000,
      "price": 162.09,
      "sequence_number": 393705,
      "sip_timestamp": 1659360933931600000,
      "size": 55,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27347",
      "participant_timestamp": 1659360933855000000,
      "price": 161.0488,
      "sequence_number": 393703,
      "sip_timestamp": 1659360933924897800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933924561200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27346",
      "participant_timestamp": 1659360933835000000,
      "price": 161.0488,
      "sequence_number": 393701,
      "sip_timestamp": 1659360933924358700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933924021000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27345",
      "participant_timestamp": 1659360933875000000,
      "price": 161.0488,
      "sequence_number": 393700,
      "sip_timestamp": 1659360933924164900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933923828200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12455",
      "participant_timestamp": 1659360933711739000,
      "price": 162.085,
      "sequence_number": 393696,
      "sip_timestamp": 1659360933915967000,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933915941400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27344",
      "participant_timestamp": 1659360933845000000,
      "price": 161.0488,
      "sequence_number": 393692,
      "sip_timestamp": 1659360933907267000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933906930000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12454",
      "participant_timestamp": 1659360933900636200,
      "price": 162.0912,
      "sequence_number": 393690,
      "sip_timestamp": 1659360933901656300,
      "size": 25,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933901634300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27343",
      "participant_timestamp": 1659360933825000000,
      "price": 161.0488,
      "sequence_number": 393679,
      "sip_timestamp": 1659360933878144300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933877807000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27342",
      "participant_timestamp": 1659360933816000000,
      "price": 161.0488,
      "sequence_number": 393678,
      "sip_timestamp": 1659360933878058800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933877722000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27341",
      "participant_timestamp": 1659360933806000000,
      "price": 161.0488,
      "sequence_number": 393670,
      "sip_timestamp": 1659360933861130200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933860793300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27340",
      "participant_timestamp": 1659360933796000000,
      "price": 161.0488,
      "sequence_number": 393669,
      "sip_timestamp": 1659360933860420900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933860082700
    },
    {
      "exchange": 19,
      "id": "3131",
      "participant_timestamp": 1659360933839968000,
      "price": 162.09,
      "sequence_number": 393667,
      "sip_timestamp": 1659360933840171800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "86",
      "participant_timestamp": 1659360933812272000,
      "price": 162.1,
      "sequence_number": 393666,
      "sip_timestamp": 1659360933839546600,
      "size": 100,
      "tape": 3,
      "trf_id": 203,
      "trf_timestamp": 1659360933832372200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27339",
      "participant_timestamp": 1659360933786000000,
      "price": 161.0488,
      "sequence_number": 393662,
      "sip_timestamp": 1659360933831350800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933831014000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27338",
      "participant_timestamp": 1659360933776000000,
      "price": 161.0488,
      "sequence_number": 393659,
      "sip_timestamp": 1659360933831190800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933830854000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27337",
      "participant_timestamp": 1659360933766000000,
      "price": 161.0488,
      "sequence_number": 393658,
      "sip_timestamp": 1659360933831150800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933830814000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27336",
      "participant_timestamp": 1659360933746000000,
      "price": 161.0488,
      "sequence_number": 393656,
      "sip_timestamp": 1659360933830936600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933830599400
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3130",
      "participant_timestamp": 1659360933817495000,
      "price": 162.09,
      "sequence_number": 393654,
      "sip_timestamp": 1659360933817695700,
      "size": 47,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4823",
      "participant_timestamp": 1659360933817388000,
      "price": 162.1,
      "sequence_number": 393653,
      "sip_timestamp": 1659360933817606100,
      "size": 95,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4822",
      "participant_timestamp": 1659360933817388000,
      "price": 162.1,
      "sequence_number": 393652,
      "sip_timestamp": 1659360933817598700,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27335",
      "participant_timestamp": 1659360933756000000,
      "price": 161.0488,
      "sequence_number": 393651,
      "sip_timestamp": 1659360933814413300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933814075400
    },
    {
      "exchange": 4,
      "id": "12453",
      "participant_timestamp": 1659360933811672600,
      "price": 162.0962,
      "sequence_number": 393649,
      "sip_timestamp": 1659360933812629500,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933812606500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27334",
      "participant_timestamp": 1659360933737000000,
      "price": 161.0488,
      "sequence_number": 393648,
      "sip_timestamp": 1659360933812444000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933812106500
    },
    {
      "exchange": 4,
      "id": "12452",
      "participant_timestamp": 1659360933811582500,
      "price": 162.098,
      "sequence_number": 393647,
      "sip_timestamp": 1659360933811843800,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933811818200
    },
    {
      "exchange": 4,
      "id": "12451",
      "participant_timestamp": 1659360933806000000,
      "price": 162.09,
      "sequence_number": 393645,
      "sip_timestamp": 1659360933806510800,
      "size": 255,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933806488800
    },
    {
      "exchange": 4,
      "id": "12450",
      "participant_timestamp": 1659360933804509000,
      "price": 162.09,
      "sequence_number": 393644,
      "sip_timestamp": 1659360933805457400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933805432600
    },
    {
      "exchange": 4,
      "id": "12449",
      "participant_timestamp": 1659360933804492800,
      "price": 162.0999,
      "sequence_number": 393643,
      "sip_timestamp": 1659360933804807400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933804783900
    },
    {
      "exchange": 4,
      "id": "12448",
      "participant_timestamp": 1659360933804427800,
      "price": 162.098,
      "sequence_number": 393642,
      "sip_timestamp": 1659360933804717000,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933804692500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27333",
      "participant_timestamp": 1659360933717000000,
      "price": 161.0488,
      "sequence_number": 393638,
      "sip_timestamp": 1659360933797519400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933797181200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27332",
      "participant_timestamp": 1659360933727000000,
      "price": 161.0488,
      "sequence_number": 393635,
      "sip_timestamp": 1659360933784134700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933783798000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27331",
      "participant_timestamp": 1659360933697000000,
      "price": 161.0488,
      "sequence_number": 393631,
      "sip_timestamp": 1659360933780614700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933780277500
    },
    {
      "exchange": 4,
      "id": "27330",
      "participant_timestamp": 1659360933767000000,
      "price": 162.0869,
      "sequence_number": 393630,
      "sip_timestamp": 1659360933769215000,
      "size": 100,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933768876800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27329",
      "participant_timestamp": 1659360933707000000,
      "price": 161.0488,
      "sequence_number": 393628,
      "sip_timestamp": 1659360933767274800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933766938400
    },
    {
      "conditions": [
        32,
        37
      ],
      "exchange": 4,
      "id": "27328",
      "participant_timestamp": 1659360933755000000,
      "price": 162.0701,
      "sequence_number": 393625,
      "sip_timestamp": 1659360933749782300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933749443000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27327",
      "participant_timestamp": 1659360933677000000,
      "price": 161.0488,
      "sequence_number": 393621,
      "sip_timestamp": 1659360933742449400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933742112300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27326",
      "participant_timestamp": 1659360933687000000,
      "price": 161.0488,
      "sequence_number": 393620,
      "sip_timestamp": 1659360933737364000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933737026800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27325",
      "participant_timestamp": 1659360933666000000,
      "price": 161.0488,
      "sequence_number": 393617,
      "sip_timestamp": 1659360933736581400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933736243000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27324",
      "participant_timestamp": 1659360933656000000,
      "price": 161.0488,
      "sequence_number": 393603,
      "sip_timestamp": 1659360933711133700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933710796500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27323",
      "participant_timestamp": 1659360933647000000,
      "price": 161.0488,
      "sequence_number": 393602,
      "sip_timestamp": 1659360933711040300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933710701800
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9676",
      "participant_timestamp": 1659360933704063200,
      "price": 162.08,
      "sequence_number": 393599,
      "sip_timestamp": 1659360933704079600,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27322",
      "participant_timestamp": 1659360933617000000,
      "price": 161.0488,
      "sequence_number": 393598,
      "sip_timestamp": 1659360933702188000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933701850000
    },
    {
      "exchange": 4,
      "id": "12447",
      "participant_timestamp": 1659360933701000000,
      "price": 162.0999,
      "sequence_number": 393596,
      "sip_timestamp": 1659360933695792000,
      "size": 185,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933695766800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27321",
      "participant_timestamp": 1659360933637000000,
      "price": 161.0488,
      "sequence_number": 393593,
      "sip_timestamp": 1659360933690485800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933690149400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27320",
      "participant_timestamp": 1659360933627000000,
      "price": 161.0488,
      "sequence_number": 393590,
      "sip_timestamp": 1659360933689737000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933689399600
    },
    {
      "exchange": 4,
      "id": "12446",
      "participant_timestamp": 1659360933688473300,
      "price": 162.0999,
      "sequence_number": 393589,
      "sip_timestamp": 1659360933689540000,
      "size": 591,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933689516500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9675",
      "participant_timestamp": 1659360933687083500,
      "price": 162.09,
      "sequence_number": 393588,
      "sip_timestamp": 1659360933687099100,
      "size": 47,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3129",
      "participant_timestamp": 1659360933686591000,
      "price": 162.1,
      "sequence_number": 393587,
      "sip_timestamp": 1659360933686789600,
      "size": 76,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12445",
      "participant_timestamp": 1659360933684734200,
      "price": 162.1,
      "sequence_number": 393586,
      "sip_timestamp": 1659360933685815600,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933685792000
    },
    {
      "exchange": 4,
      "id": "12444",
      "participant_timestamp": 1659360933683401500,
      "price": 162.0901,
      "sequence_number": 393585,
      "sip_timestamp": 1659360933685024000,
      "size": 773,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933684998400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3128",
      "participant_timestamp": 1659360933680173000,
      "price": 162.09,
      "sequence_number": 393584,
      "sip_timestamp": 1659360933680373500,
      "size": 32,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4821",
      "participant_timestamp": 1659360933680151000,
      "price": 162.09,
      "sequence_number": 393583,
      "sip_timestamp": 1659360933680363300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1398",
      "participant_timestamp": 1659360933675626000,
      "price": 162.09,
      "sequence_number": 393580,
      "sip_timestamp": 1659360933675821300,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4820",
      "participant_timestamp": 1659360933675295000,
      "price": 162.09,
      "sequence_number": 393579,
      "sip_timestamp": 1659360933675505700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4819",
      "participant_timestamp": 1659360933675030000,
      "price": 162.09,
      "sequence_number": 393578,
      "sip_timestamp": 1659360933675249200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27319",
      "participant_timestamp": 1659360933607000000,
      "price": 161.0488,
      "sequence_number": 393576,
      "sip_timestamp": 1659360933673490200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933673151500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12443",
      "participant_timestamp": 1659360933666606300,
      "price": 162.09,
      "sequence_number": 393575,
      "sip_timestamp": 1659360933666887400,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933666864600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12442",
      "participant_timestamp": 1659360933659000000,
      "price": 162.09,
      "sequence_number": 393574,
      "sip_timestamp": 1659360933653745000,
      "size": 15,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933653722600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27318",
      "participant_timestamp": 1659360933587000000,
      "price": 161.0488,
      "sequence_number": 393571,
      "sip_timestamp": 1659360933643564500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933643228200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27317",
      "participant_timestamp": 1659360933567000000,
      "price": 161.0488,
      "sequence_number": 393570,
      "sip_timestamp": 1659360933643481600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933643144000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27316",
      "participant_timestamp": 1659360933597000000,
      "price": 161.0488,
      "sequence_number": 393566,
      "sip_timestamp": 1659360933637536800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933637200100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27315",
      "participant_timestamp": 1659360933557000000,
      "price": 161.0488,
      "sequence_number": 393563,
      "sip_timestamp": 1659360933626670800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933626331400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27314",
      "participant_timestamp": 1659360933577000000,
      "price": 161.0488,
      "sequence_number": 393562,
      "sip_timestamp": 1659360933620772900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933620435700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12441",
      "participant_timestamp": 1659360933616000000,
      "price": 162.08,
      "sequence_number": 393561,
      "sip_timestamp": 1659360933610102500,
      "size": 20,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933610079700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27313",
      "participant_timestamp": 1659360933527000000,
      "price": 161.0488,
      "sequence_number": 393559,
      "sip_timestamp": 1659360933607857000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933607518700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27312",
      "participant_timestamp": 1659360933537000000,
      "price": 161.0488,
      "sequence_number": 393558,
      "sip_timestamp": 1659360933603850800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933603511000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27311",
      "participant_timestamp": 1659360933547000000,
      "price": 161.0488,
      "sequence_number": 393554,
      "sip_timestamp": 1659360933596014300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933595677200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27310",
      "participant_timestamp": 1659360933517000000,
      "price": 161.0488,
      "sequence_number": 393549,
      "sip_timestamp": 1659360933579769900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933579432000
    },
    {
      "exchange": 4,
      "id": "12440",
      "participant_timestamp": 1659360933582000000,
      "price": 162.08,
      "sequence_number": 393548,
      "sip_timestamp": 1659360933576810800,
      "size": 166,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933576786200
    },
    {
      "exchange": 4,
      "id": "12439",
      "participant_timestamp": 1659360933551000000,
      "price": 162.08,
      "sequence_number": 393537,
      "sip_timestamp": 1659360933553453800,
      "size": 150,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933553430300
    },
    {
      "exchange": 4,
      "id": "12438",
      "participant_timestamp": 1659360933552144100,
      "price": 162.0801,
      "sequence_number": 393536,
      "sip_timestamp": 1659360933553156400,
      "size": 150,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933553134300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27309",
      "participant_timestamp": 1659360933487000000,
      "price": 161.0488,
      "sequence_number": 393534,
      "sip_timestamp": 1659360933549898200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933549561300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27308",
      "participant_timestamp": 1659360933507000000,
      "price": 161.0488,
      "sequence_number": 393533,
      "sip_timestamp": 1659360933549412900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933549075500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27307",
      "participant_timestamp": 1659360933477000000,
      "price": 161.0488,
      "sequence_number": 393532,
      "sip_timestamp": 1659360933549281300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933548943400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27306",
      "participant_timestamp": 1659360933497000000,
      "price": 161.0488,
      "sequence_number": 393531,
      "sip_timestamp": 1659360933540873000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933540535600
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9674",
      "participant_timestamp": 1659360933539843000,
      "price": 162.08,
      "sequence_number": 393526,
      "sip_timestamp": 1659360933539859200,
      "size": 52,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12437",
      "participant_timestamp": 1659360933544000000,
      "price": 162.09,
      "sequence_number": 393525,
      "sip_timestamp": 1659360933538780200,
      "size": 15,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933538756600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27305",
      "participant_timestamp": 1659360933467000000,
      "price": 161.0488,
      "sequence_number": 393521,
      "sip_timestamp": 1659360933532397800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933532060700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27304",
      "participant_timestamp": 1659360933457000000,
      "price": 161.0488,
      "sequence_number": 393515,
      "sip_timestamp": 1659360933521754600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933521417500
    },
    {
      "exchange": 4,
      "id": "12436",
      "participant_timestamp": 1659360932496100000,
      "price": 162.0698,
      "sequence_number": 393510,
      "sip_timestamp": 1659360933510731000,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933510709000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27303",
      "participant_timestamp": 1659360933447000000,
      "price": 161.0488,
      "sequence_number": 393486,
      "sip_timestamp": 1659360933503016700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933502678800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27302",
      "participant_timestamp": 1659360933437000000,
      "price": 161.0488,
      "sequence_number": 393484,
      "sip_timestamp": 1659360933502892800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933502555100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27301",
      "participant_timestamp": 1659360933427000000,
      "price": 161.0488,
      "sequence_number": 393478,
      "sip_timestamp": 1659360933496490200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933496151300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12435",
      "participant_timestamp": 1659360933248733000,
      "price": 162.105,
      "sequence_number": 393477,
      "sip_timestamp": 1659360933494901500,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933494879500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27300",
      "participant_timestamp": 1659360933417000000,
      "price": 161.0488,
      "sequence_number": 393473,
      "sip_timestamp": 1659360933486068200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933485731600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27299",
      "participant_timestamp": 1659360933397000000,
      "price": 161.0488,
      "sequence_number": 393471,
      "sip_timestamp": 1659360933482849800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933482512600
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 18,
      "id": "147",
      "participant_timestamp": 1659360933477859000,
      "price": 162.08,
      "sequence_number": 393467,
      "sip_timestamp": 1659360933478068700,
      "size": 32,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9673",
      "participant_timestamp": 1659360933463231000,
      "price": 162.09,
      "sequence_number": 393463,
      "sip_timestamp": 1659360933463245800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3127",
      "participant_timestamp": 1659360933462897000,
      "price": 162.09,
      "sequence_number": 393462,
      "sip_timestamp": 1659360933463100000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27298",
      "participant_timestamp": 1659360933387000000,
      "price": 161.0488,
      "sequence_number": 393458,
      "sip_timestamp": 1659360933456008700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933455671600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27297",
      "participant_timestamp": 1659360933407000000,
      "price": 161.0488,
      "sequence_number": 393457,
      "sip_timestamp": 1659360933455398100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933455061800
    },
    {
      "exchange": 4,
      "id": "12434",
      "participant_timestamp": 1659360933455000000,
      "price": 162.0899,
      "sequence_number": 393455,
      "sip_timestamp": 1659360933449406700,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933449380900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12433",
      "participant_timestamp": 1659360932898456300,
      "price": 162.09,
      "sequence_number": 393442,
      "sip_timestamp": 1659360933434344400,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933434321200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27296",
      "participant_timestamp": 1659360933377000000,
      "price": 161.0488,
      "sequence_number": 393441,
      "sip_timestamp": 1659360933423219700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933422882800
    },
    {
      "exchange": 4,
      "id": "12432",
      "participant_timestamp": 1659360933408792600,
      "price": 162.07,
      "sequence_number": 393414,
      "sip_timestamp": 1659360933409795800,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933409772300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27295",
      "participant_timestamp": 1659360933368000000,
      "price": 161.0488,
      "sequence_number": 393413,
      "sip_timestamp": 1659360933409306600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933408970200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27294",
      "participant_timestamp": 1659360933358000000,
      "price": 161.0488,
      "sequence_number": 393411,
      "sip_timestamp": 1659360933409222700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933408886300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27293",
      "participant_timestamp": 1659360933338000000,
      "price": 161.0488,
      "sequence_number": 393410,
      "sip_timestamp": 1659360933409181700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933408844800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12431",
      "participant_timestamp": 1659360933415000000,
      "price": 162.0899,
      "sequence_number": 393409,
      "sip_timestamp": 1659360933409177300,
      "size": 7,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933409155300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27292",
      "participant_timestamp": 1659360933348000000,
      "price": 161.0488,
      "sequence_number": 393406,
      "sip_timestamp": 1659360933406391600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933406054100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27291",
      "participant_timestamp": 1659360933329000000,
      "price": 161.0488,
      "sequence_number": 393402,
      "sip_timestamp": 1659360933391577900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933391239400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27290",
      "participant_timestamp": 1659360933319000000,
      "price": 161.0488,
      "sequence_number": 393401,
      "sip_timestamp": 1659360933389574100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933389236700
    },
    {
      "conditions": [
        32,
        37
      ],
      "exchange": 4,
      "id": "27289",
      "participant_timestamp": 1659360933382000000,
      "price": 162.08,
      "sequence_number": 393400,
      "sip_timestamp": 1659360933377440500,
      "size": 25,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933377101600
    },
    {
      "exchange": 4,
      "id": "12430",
      "participant_timestamp": 1659360933378000000,
      "price": 162.08,
      "sequence_number": 393398,
      "sip_timestamp": 1659360933372276000,
      "size": 540,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933372252000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27288",
      "participant_timestamp": 1659360933299000000,
      "price": 161.0488,
      "sequence_number": 393375,
      "sip_timestamp": 1659360933362337000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933361999000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27287",
      "participant_timestamp": 1659360933309000000,
      "price": 161.0488,
      "sequence_number": 393374,
      "sip_timestamp": 1659360933362291700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933361954800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12429",
      "participant_timestamp": 1659360933359654400,
      "price": 162.09,
      "sequence_number": 393370,
      "sip_timestamp": 1659360933360749300,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933360724700
    },
    {
      "exchange": 4,
      "id": "12428",
      "participant_timestamp": 1659360933357897200,
      "price": 162.08,
      "sequence_number": 393369,
      "sip_timestamp": 1659360933358184400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933358162400
    },
    {
      "exchange": 4,
      "id": "12427",
      "participant_timestamp": 1659360933355150600,
      "price": 162.07,
      "sequence_number": 393368,
      "sip_timestamp": 1659360933356163600,
      "size": 1000,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933356137500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27286",
      "participant_timestamp": 1659360933288000000,
      "price": 161.0488,
      "sequence_number": 393364,
      "sip_timestamp": 1659360933344760300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933344421400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27285",
      "participant_timestamp": 1659360933278000000,
      "price": 161.0488,
      "sequence_number": 393360,
      "sip_timestamp": 1659360933321778200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933321440800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27284",
      "participant_timestamp": 1659360933269000000,
      "price": 161.0488,
      "sequence_number": 393354,
      "sip_timestamp": 1659360933315454500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933315117800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27283",
      "participant_timestamp": 1659360933249000000,
      "price": 161.0488,
      "sequence_number": 393353,
      "sip_timestamp": 1659360933315377000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933315040300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27282",
      "participant_timestamp": 1659360933259000000,
      "price": 161.0488,
      "sequence_number": 393351,
      "sip_timestamp": 1659360933313650200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933313313000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27281",
      "participant_timestamp": 1659360933228000000,
      "price": 161.0488,
      "sequence_number": 393349,
      "sip_timestamp": 1659360933298999000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933298662000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8465",
      "participant_timestamp": 1659360933297707800,
      "price": 162.07,
      "sequence_number": 393347,
      "sip_timestamp": 1659360933298052900,
      "size": 7,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27280",
      "participant_timestamp": 1659360933239000000,
      "price": 161.0488,
      "sequence_number": 393346,
      "sip_timestamp": 1659360933296793600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933296455400
    },
    {
      "exchange": 4,
      "id": "27279",
      "participant_timestamp": 1659360933278000000,
      "price": 162.0795,
      "sequence_number": 393341,
      "sip_timestamp": 1659360933280742000,
      "size": 500,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933280404500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27278",
      "participant_timestamp": 1659360933218000000,
      "price": 161.0488,
      "sequence_number": 393334,
      "sip_timestamp": 1659360933268147500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933267810800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27277",
      "participant_timestamp": 1659360933208000000,
      "price": 161.0488,
      "sequence_number": 393333,
      "sip_timestamp": 1659360933268080600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933267742500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27276",
      "participant_timestamp": 1659360933198000000,
      "price": 161.0488,
      "sequence_number": 393332,
      "sip_timestamp": 1659360933268003300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933267665700
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3126",
      "participant_timestamp": 1659360933264717000,
      "price": 162.08,
      "sequence_number": 393329,
      "sip_timestamp": 1659360933264914000,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1397",
      "participant_timestamp": 1659360933264703500,
      "price": 162.08,
      "sequence_number": 393328,
      "sip_timestamp": 1659360933264906500,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12426",
      "participant_timestamp": 1659360933251000000,
      "price": 162.1,
      "sequence_number": 393320,
      "sip_timestamp": 1659360933252547000,
      "size": 30,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933252525300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27275",
      "participant_timestamp": 1659360933188000000,
      "price": 161.0488,
      "sequence_number": 393318,
      "sip_timestamp": 1659360933251076400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933250739000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3125",
      "participant_timestamp": 1659360933249698000,
      "price": 162.09,
      "sequence_number": 393317,
      "sip_timestamp": 1659360933249896400,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12425",
      "participant_timestamp": 1659360933254000000,
      "price": 162.0999,
      "sequence_number": 393316,
      "sip_timestamp": 1659360933248728600,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933248703500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27274",
      "participant_timestamp": 1659360933168000000,
      "price": 161.0488,
      "sequence_number": 393312,
      "sip_timestamp": 1659360933221885400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933221549000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27273",
      "participant_timestamp": 1659360933128000000,
      "price": 161.0488,
      "sequence_number": 393310,
      "sip_timestamp": 1659360933221724400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933221387500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27272",
      "participant_timestamp": 1659360933178000000,
      "price": 161.0488,
      "sequence_number": 393309,
      "sip_timestamp": 1659360933221639700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933221298200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27271",
      "participant_timestamp": 1659360933158000000,
      "price": 161.0488,
      "sequence_number": 393308,
      "sip_timestamp": 1659360933221550300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933221212700
    },
    {
      "exchange": 21,
      "id": "1396",
      "participant_timestamp": 1659360933206918700,
      "price": 162.09,
      "sequence_number": 393305,
      "sip_timestamp": 1659360933207108900,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3124",
      "participant_timestamp": 1659360933206876000,
      "price": 162.09,
      "sequence_number": 393304,
      "sip_timestamp": 1659360933207081700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3123",
      "participant_timestamp": 1659360933206876000,
      "price": 162.09,
      "sequence_number": 393303,
      "sip_timestamp": 1659360933207076600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12424",
      "participant_timestamp": 1659360933205974500,
      "price": 162.0901,
      "sequence_number": 393302,
      "sip_timestamp": 1659360933207029000,
      "size": 61,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933206999300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9672",
      "participant_timestamp": 1659360933206973200,
      "price": 162.09,
      "sequence_number": 393301,
      "sip_timestamp": 1659360933206988500,
      "size": 19,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27270",
      "participant_timestamp": 1659360933138000000,
      "price": 161.0488,
      "sequence_number": 393298,
      "sip_timestamp": 1659360933204699100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933204362000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27269",
      "participant_timestamp": 1659360933148000000,
      "price": 161.0488,
      "sequence_number": 393295,
      "sip_timestamp": 1659360933177226000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933176889300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27268",
      "participant_timestamp": 1659360933098000000,
      "price": 161.0488,
      "sequence_number": 393294,
      "sip_timestamp": 1659360933176966000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933176626700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27267",
      "participant_timestamp": 1659360933118000000,
      "price": 161.0488,
      "sequence_number": 393291,
      "sip_timestamp": 1659360933174552300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933174215400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27266",
      "participant_timestamp": 1659360933108000000,
      "price": 161.0488,
      "sequence_number": 393290,
      "sip_timestamp": 1659360933174045400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933173707500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27265",
      "participant_timestamp": 1659360933088000000,
      "price": 161.0488,
      "sequence_number": 393282,
      "sip_timestamp": 1659360933157855500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933157518800
    },
    {
      "exchange": 4,
      "id": "12423",
      "participant_timestamp": 1659360933141158000,
      "price": 162.1,
      "sequence_number": 393277,
      "sip_timestamp": 1659360933143121400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933143094300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27264",
      "participant_timestamp": 1659360933068000000,
      "price": 161.0488,
      "sequence_number": 393273,
      "sip_timestamp": 1659360933127937500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933127599400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27263",
      "participant_timestamp": 1659360933058000000,
      "price": 161.0488,
      "sequence_number": 393272,
      "sip_timestamp": 1659360933127195000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933126857500
    },
    {
      "exchange": 4,
      "id": "12422",
      "participant_timestamp": 1659360933120785400,
      "price": 162.1,
      "sequence_number": 393271,
      "sip_timestamp": 1659360933121176800,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933121154600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27262",
      "participant_timestamp": 1659360933078000000,
      "price": 161.0488,
      "sequence_number": 393270,
      "sip_timestamp": 1659360933119562200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933119224800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 21,
      "id": "1395",
      "participant_timestamp": 1659360933115229700,
      "price": 162.1,
      "sequence_number": 393269,
      "sip_timestamp": 1659360933115427800,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 21,
      "id": "1394",
      "participant_timestamp": 1659360933115229700,
      "price": 162.1,
      "sequence_number": 393268,
      "sip_timestamp": 1659360933115427800,
      "size": 3,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12421",
      "participant_timestamp": 1659360933114206000,
      "price": 162.1,
      "sequence_number": 393267,
      "sip_timestamp": 1659360933115315000,
      "size": 2000,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933115290000
    },
    {
      "exchange": 8,
      "id": "4818",
      "participant_timestamp": 1659360933115069000,
      "price": 162.1,
      "sequence_number": 393266,
      "sip_timestamp": 1659360933115292000,
      "size": 197,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4817",
      "participant_timestamp": 1659360933115069000,
      "price": 162.1,
      "sequence_number": 393265,
      "sip_timestamp": 1659360933115266600,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4816",
      "participant_timestamp": 1659360933115049000,
      "price": 162.09,
      "sequence_number": 393264,
      "sip_timestamp": 1659360933115262500,
      "size": 19,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3122",
      "participant_timestamp": 1659360933115031000,
      "price": 162.1,
      "sequence_number": 393263,
      "sip_timestamp": 1659360933115250400,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3121",
      "participant_timestamp": 1659360933115031000,
      "price": 162.1,
      "sequence_number": 393262,
      "sip_timestamp": 1659360933115247600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3120",
      "participant_timestamp": 1659360933115031000,
      "price": 162.1,
      "sequence_number": 393261,
      "sip_timestamp": 1659360933115235600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4815",
      "participant_timestamp": 1659360933115032000,
      "price": 162.09,
      "sequence_number": 393260,
      "sip_timestamp": 1659360933115231700,
      "size": 81,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3119",
      "participant_timestamp": 1659360933115031000,
      "price": 162.1,
      "sequence_number": 393259,
      "sip_timestamp": 1659360933115231700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3118",
      "participant_timestamp": 1659360933115031000,
      "price": 162.1,
      "sequence_number": 393258,
      "sip_timestamp": 1659360933115226000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3117",
      "participant_timestamp": 1659360933115021000,
      "price": 162.09,
      "sequence_number": 393257,
      "sip_timestamp": 1659360933115221200,
      "size": 38,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9671",
      "participant_timestamp": 1659360933115198200,
      "price": 162.09,
      "sequence_number": 393256,
      "sip_timestamp": 1659360933115216400,
      "size": 81,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9670",
      "participant_timestamp": 1659360933115198200,
      "price": 162.09,
      "sequence_number": 393255,
      "sip_timestamp": 1659360933115214000,
      "size": 124,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3116",
      "participant_timestamp": 1659360933114983000,
      "price": 162.09,
      "sequence_number": 393254,
      "sip_timestamp": 1659360933115178200,
      "size": 47,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 21,
      "id": "1393",
      "participant_timestamp": 1659360933114954200,
      "price": 162.09,
      "sequence_number": 393253,
      "sip_timestamp": 1659360933115143700,
      "size": 40,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 21,
      "id": "1392",
      "participant_timestamp": 1659360933114942700,
      "price": 162.09,
      "sequence_number": 393252,
      "sip_timestamp": 1659360933115134500,
      "size": 60,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9669",
      "participant_timestamp": 1659360933114923300,
      "price": 162.1,
      "sequence_number": 393251,
      "sip_timestamp": 1659360933114937300,
      "size": 800,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9668",
      "participant_timestamp": 1659360933114826500,
      "price": 162.09,
      "sequence_number": 393250,
      "sip_timestamp": 1659360933114840600,
      "size": 213,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9667",
      "participant_timestamp": 1659360933114795500,
      "price": 162.1,
      "sequence_number": 393249,
      "sip_timestamp": 1659360933114820000,
      "size": 497,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9666",
      "participant_timestamp": 1659360933114795500,
      "price": 162.1,
      "sequence_number": 393248,
      "sip_timestamp": 1659360933114817000,
      "size": 3,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9665",
      "participant_timestamp": 1659360933114795500,
      "price": 162.1,
      "sequence_number": 393247,
      "sip_timestamp": 1659360933114814500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9664",
      "participant_timestamp": 1659360933114795500,
      "price": 162.1,
      "sequence_number": 393246,
      "sip_timestamp": 1659360933114812700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9663",
      "participant_timestamp": 1659360933114780700,
      "price": 162.09,
      "sequence_number": 393245,
      "sip_timestamp": 1659360933114799600,
      "size": 163,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9662",
      "participant_timestamp": 1659360933114780700,
      "price": 162.09,
      "sequence_number": 393244,
      "sip_timestamp": 1659360933114799600,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3115",
      "participant_timestamp": 1659360933114571000,
      "price": 162.1,
      "sequence_number": 393243,
      "sip_timestamp": 1659360933114768100,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1391",
      "participant_timestamp": 1659360933114460400,
      "price": 162.1,
      "sequence_number": 393242,
      "sip_timestamp": 1659360933114659600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8464",
      "participant_timestamp": 1659360933114224000,
      "price": 162.1,
      "sequence_number": 393241,
      "sip_timestamp": 1659360933114568400,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9661",
      "participant_timestamp": 1659360933114501000,
      "price": 162.1,
      "sequence_number": 393240,
      "sip_timestamp": 1659360933114517500,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27261",
      "participant_timestamp": 1659360933048000000,
      "price": 161.0488,
      "sequence_number": 393238,
      "sip_timestamp": 1659360933095505200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933095168500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27260",
      "participant_timestamp": 1659360933018000000,
      "price": 161.0488,
      "sequence_number": 393236,
      "sip_timestamp": 1659360933095339500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933095003000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27259",
      "participant_timestamp": 1659360933028000000,
      "price": 161.0488,
      "sequence_number": 393226,
      "sip_timestamp": 1659360933080892700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933080556300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27258",
      "participant_timestamp": 1659360933038000000,
      "price": 161.0488,
      "sequence_number": 393222,
      "sip_timestamp": 1659360933067450600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933067114200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27257",
      "participant_timestamp": 1659360933008000000,
      "price": 161.0488,
      "sequence_number": 393217,
      "sip_timestamp": 1659360933064074000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933063735600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27256",
      "participant_timestamp": 1659360932998000000,
      "price": 161.0488,
      "sequence_number": 393216,
      "sip_timestamp": 1659360933063547400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933063210800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12420",
      "participant_timestamp": 1659360933040389400,
      "price": 162.108,
      "sequence_number": 393191,
      "sip_timestamp": 1659360933040658200,
      "size": 90,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933040633600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27255",
      "participant_timestamp": 1659360932988000000,
      "price": 161.0488,
      "sequence_number": 393187,
      "sip_timestamp": 1659360933034163700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933033827300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27254",
      "participant_timestamp": 1659360932967000000,
      "price": 161.0488,
      "sequence_number": 393186,
      "sip_timestamp": 1659360933034074600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933033738000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27253",
      "participant_timestamp": 1659360932977000000,
      "price": 161.0488,
      "sequence_number": 393185,
      "sip_timestamp": 1659360933033381000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933033043200
    },
    {
      "exchange": 4,
      "id": "12419",
      "participant_timestamp": 1659360933039000000,
      "price": 162.1001,
      "sequence_number": 393184,
      "sip_timestamp": 1659360933033272600,
      "size": 700,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933033243000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12418",
      "participant_timestamp": 1659360932156000000,
      "price": 162.03,
      "sequence_number": 393183,
      "sip_timestamp": 1659360933029358000,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933029334300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12417",
      "participant_timestamp": 1659360932148000000,
      "price": 162,
      "sequence_number": 393182,
      "sip_timestamp": 1659360933029268700,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933029245200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12416",
      "participant_timestamp": 1659360932139000000,
      "price": 162,
      "sequence_number": 393181,
      "sip_timestamp": 1659360933029177000,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933029155000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4814",
      "participant_timestamp": 1659360933027406000,
      "price": 162.11,
      "sequence_number": 393179,
      "sip_timestamp": 1659360933027609600,
      "size": 2,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12415",
      "participant_timestamp": 1659360933023794000,
      "price": 162.12,
      "sequence_number": 393178,
      "sip_timestamp": 1659360933026448100,
      "size": 851,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933026427000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8463",
      "participant_timestamp": 1659360933022650000,
      "price": 162.11,
      "sequence_number": 393176,
      "sip_timestamp": 1659360933022995500,
      "size": 1,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4813",
      "participant_timestamp": 1659360933022571000,
      "price": 162.11,
      "sequence_number": 393175,
      "sip_timestamp": 1659360933022802400,
      "size": 200,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4812",
      "participant_timestamp": 1659360933022571000,
      "price": 162.11,
      "sequence_number": 393174,
      "sip_timestamp": 1659360933022797800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3114",
      "participant_timestamp": 1659360933022576000,
      "price": 162.11,
      "sequence_number": 393173,
      "sip_timestamp": 1659360933022785500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3113",
      "participant_timestamp": 1659360933022576000,
      "price": 162.11,
      "sequence_number": 393172,
      "sip_timestamp": 1659360933022781400,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4811",
      "participant_timestamp": 1659360933022571000,
      "price": 162.11,
      "sequence_number": 393171,
      "sip_timestamp": 1659360933022779400,
      "size": 125,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3112",
      "participant_timestamp": 1659360933022576000,
      "price": 162.11,
      "sequence_number": 393170,
      "sip_timestamp": 1659360933022776000,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1390",
      "participant_timestamp": 1659360933022531300,
      "price": 162.11,
      "sequence_number": 393169,
      "sip_timestamp": 1659360933022730200,
      "size": 200,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9660",
      "participant_timestamp": 1659360933022624000,
      "price": 162.11,
      "sequence_number": 393168,
      "sip_timestamp": 1659360933022648000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9659",
      "participant_timestamp": 1659360933022624000,
      "price": 162.11,
      "sequence_number": 393167,
      "sip_timestamp": 1659360933022646300,
      "size": 22,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9658",
      "participant_timestamp": 1659360933022624000,
      "price": 162.11,
      "sequence_number": 393166,
      "sip_timestamp": 1659360933022643700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9657",
      "participant_timestamp": 1659360933022624000,
      "price": 162.11,
      "sequence_number": 393165,
      "sip_timestamp": 1659360933022642000,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12414",
      "participant_timestamp": 1659360933018142700,
      "price": 162.105,
      "sequence_number": 393163,
      "sip_timestamp": 1659360933018417000,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933018391000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27252",
      "participant_timestamp": 1659360932957000000,
      "price": 161.0488,
      "sequence_number": 393162,
      "sip_timestamp": 1659360933017282300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360933016944000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12413",
      "participant_timestamp": 1659360933004000000,
      "price": 162.105,
      "sequence_number": 393160,
      "sip_timestamp": 1659360933005802500,
      "size": 18,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933005781000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12412",
      "participant_timestamp": 1659360933002000000,
      "price": 162.11,
      "sequence_number": 393157,
      "sip_timestamp": 1659360933004523000,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933004501200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12411",
      "participant_timestamp": 1659360933001000000,
      "price": 162.11,
      "sequence_number": 393154,
      "sip_timestamp": 1659360933002961700,
      "size": 5,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933002940000
    },
    {
      "exchange": 4,
      "id": "12410",
      "participant_timestamp": 1659360932999000000,
      "price": 162.11,
      "sequence_number": 393152,
      "sip_timestamp": 1659360933000957000,
      "size": 220,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360933000934400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27251",
      "participant_timestamp": 1659360932991000000,
      "price": 162.11,
      "sequence_number": 393150,
      "sip_timestamp": 1659360932994220000,
      "size": 5,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932993883100
    },
    {
      "exchange": 4,
      "id": "27250",
      "participant_timestamp": 1659360932990000000,
      "price": 162.1045,
      "sequence_number": 393148,
      "sip_timestamp": 1659360932993614600,
      "size": 495,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932993276700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27249",
      "participant_timestamp": 1659360932990000000,
      "price": 162.1045,
      "sequence_number": 393146,
      "sip_timestamp": 1659360932992899600,
      "size": 5,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932992562700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27248",
      "participant_timestamp": 1659360932948000000,
      "price": 161.0488,
      "sequence_number": 393143,
      "sip_timestamp": 1659360932988279000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932987941600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27247",
      "participant_timestamp": 1659360932928000000,
      "price": 161.0488,
      "sequence_number": 393137,
      "sip_timestamp": 1659360932987217400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932986880300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27246",
      "participant_timestamp": 1659360932938000000,
      "price": 161.0488,
      "sequence_number": 393134,
      "sip_timestamp": 1659360932979890700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932979553300
    },
    {
      "exchange": 4,
      "id": "12409",
      "participant_timestamp": 1659360932973879600,
      "price": 162.1,
      "sequence_number": 393131,
      "sip_timestamp": 1659360932975451100,
      "size": 3600,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932975427800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12408",
      "participant_timestamp": 1659360932973441000,
      "price": 162.1,
      "sequence_number": 393128,
      "sip_timestamp": 1659360932973747000,
      "size": 85,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932973725400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12407",
      "participant_timestamp": 1659360932973421600,
      "price": 162.1,
      "sequence_number": 393127,
      "sip_timestamp": 1659360932973708800,
      "size": 75,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932973687800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3111",
      "participant_timestamp": 1659360932972326000,
      "price": 162.1,
      "sequence_number": 393126,
      "sip_timestamp": 1659360932972524300,
      "size": 32,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3110",
      "participant_timestamp": 1659360932972326000,
      "price": 162.1,
      "sequence_number": 393125,
      "sip_timestamp": 1659360932972519700,
      "size": 8,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8462",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393124,
      "sip_timestamp": 1659360932972283100,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8461",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393123,
      "sip_timestamp": 1659360932972280000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8460",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393122,
      "sip_timestamp": 1659360932972279800,
      "size": 3,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8459",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393121,
      "sip_timestamp": 1659360932972279800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8458",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393120,
      "sip_timestamp": 1659360932972274700,
      "size": 24,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8457",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393119,
      "sip_timestamp": 1659360932972266800,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8456",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393118,
      "sip_timestamp": 1659360932972266800,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8455",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393117,
      "sip_timestamp": 1659360932972266800,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8454",
      "participant_timestamp": 1659360932971906600,
      "price": 162.1,
      "sequence_number": 393116,
      "sip_timestamp": 1659360932972266500,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 18,
      "id": "146",
      "participant_timestamp": 1659360932971984000,
      "price": 162.09,
      "sequence_number": 393115,
      "sip_timestamp": 1659360932972198400,
      "size": 32,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 7,
      "id": "758",
      "participant_timestamp": 1659360932971984000,
      "price": 162.09,
      "sequence_number": 393114,
      "sip_timestamp": 1659360932972186400,
      "size": 8,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4810",
      "participant_timestamp": 1659360932971751000,
      "price": 162.1,
      "sequence_number": 393113,
      "sip_timestamp": 1659360932971988500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4809",
      "participant_timestamp": 1659360932971751000,
      "price": 162.1,
      "sequence_number": 393112,
      "sip_timestamp": 1659360932971984600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4808",
      "participant_timestamp": 1659360932971751000,
      "price": 162.1,
      "sequence_number": 393111,
      "sip_timestamp": 1659360932971976400,
      "size": 106,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4807",
      "participant_timestamp": 1659360932971751000,
      "price": 162.1,
      "sequence_number": 393110,
      "sip_timestamp": 1659360932971966000,
      "size": 115,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4806",
      "participant_timestamp": 1659360932971751000,
      "price": 162.1,
      "sequence_number": 393109,
      "sip_timestamp": 1659360932971961000,
      "size": 91,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3109",
      "participant_timestamp": 1659360932971709000,
      "price": 162.1,
      "sequence_number": 393108,
      "sip_timestamp": 1659360932971906600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9656",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393107,
      "sip_timestamp": 1659360932971906000,
      "size": 250,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9655",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393106,
      "sip_timestamp": 1659360932971905800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9654",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393105,
      "sip_timestamp": 1659360932971898600,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9653",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393104,
      "sip_timestamp": 1659360932971895300,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9652",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393103,
      "sip_timestamp": 1659360932971895000,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9651",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393102,
      "sip_timestamp": 1659360932971895000,
      "size": 71,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9650",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393101,
      "sip_timestamp": 1659360932971891000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9649",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393100,
      "sip_timestamp": 1659360932971882800,
      "size": 14,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9648",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393099,
      "sip_timestamp": 1659360932971882800,
      "size": 15,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9647",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393098,
      "sip_timestamp": 1659360932971882500,
      "size": 25,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9646",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393097,
      "sip_timestamp": 1659360932971882500,
      "size": 140,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1389",
      "participant_timestamp": 1659360932971676400,
      "price": 162.1,
      "sequence_number": 393096,
      "sip_timestamp": 1659360932971879400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9645",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393095,
      "sip_timestamp": 1659360932971872800,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9644",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393094,
      "sip_timestamp": 1659360932971872500,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9643",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393093,
      "sip_timestamp": 1659360932971869200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9642",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393092,
      "sip_timestamp": 1659360932971869000,
      "size": 58,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9641",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393091,
      "sip_timestamp": 1659360932971863300,
      "size": 73,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9640",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393090,
      "sip_timestamp": 1659360932971861000,
      "size": 5,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9639",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393089,
      "sip_timestamp": 1659360932971858400,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9638",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393088,
      "sip_timestamp": 1659360932971856100,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9637",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393087,
      "sip_timestamp": 1659360932971852800,
      "size": 10,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9636",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393086,
      "sip_timestamp": 1659360932971850800,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9635",
      "participant_timestamp": 1659360932971831800,
      "price": 162.1,
      "sequence_number": 393085,
      "sip_timestamp": 1659360932971848200,
      "size": 68,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4805",
      "participant_timestamp": 1659360932971521000,
      "price": 162.1,
      "sequence_number": 393083,
      "sip_timestamp": 1659360932971757000,
      "size": 709,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4804",
      "participant_timestamp": 1659360932971521000,
      "price": 162.1,
      "sequence_number": 393082,
      "sip_timestamp": 1659360932971748600,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4803",
      "participant_timestamp": 1659360932971521000,
      "price": 162.1,
      "sequence_number": 393081,
      "sip_timestamp": 1659360932971745500,
      "size": 89,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4802",
      "participant_timestamp": 1659360932971521000,
      "price": 162.09,
      "sequence_number": 393080,
      "sip_timestamp": 1659360932971732200,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 7,
      "id": "757",
      "participant_timestamp": 1659360932971483000,
      "price": 162.1,
      "sequence_number": 393079,
      "sip_timestamp": 1659360932971686000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27245",
      "participant_timestamp": 1659360932918000000,
      "price": 161.0488,
      "sequence_number": 393077,
      "sip_timestamp": 1659360932969719300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932969380000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12406",
      "participant_timestamp": 1659360932960348000,
      "price": 162.08,
      "sequence_number": 393076,
      "sip_timestamp": 1659360932961447700,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932961425000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12405",
      "participant_timestamp": 1659360932960113400,
      "price": 162.08,
      "sequence_number": 393075,
      "sip_timestamp": 1659360932961084200,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932961059300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27244",
      "participant_timestamp": 1659360932908000000,
      "price": 161.0488,
      "sequence_number": 393074,
      "sip_timestamp": 1659360932955971600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932955635000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27243",
      "participant_timestamp": 1659360932888000000,
      "price": 161.0488,
      "sequence_number": 393073,
      "sip_timestamp": 1659360932955878700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932955542000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27242",
      "participant_timestamp": 1659360932878000000,
      "price": 161.0488,
      "sequence_number": 393068,
      "sip_timestamp": 1659360932940301300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932939964200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27241",
      "participant_timestamp": 1659360932898000000,
      "price": 161.0488,
      "sequence_number": 393066,
      "sip_timestamp": 1659360932939840500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932939503900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27240",
      "participant_timestamp": 1659360932858000000,
      "price": 161.0488,
      "sequence_number": 393063,
      "sip_timestamp": 1659360932923495000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932923156700
    },
    {
      "exchange": 4,
      "id": "12404",
      "participant_timestamp": 1659360932911098400,
      "price": 162.0838,
      "sequence_number": 393061,
      "sip_timestamp": 1659360932912362500,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932912337200
    },
    {
      "exchange": 4,
      "id": "12403",
      "participant_timestamp": 1659360932904000000,
      "price": 162.1,
      "sequence_number": 393057,
      "sip_timestamp": 1659360932904696000,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932904670700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27239",
      "participant_timestamp": 1659360932868000000,
      "price": 161.0488,
      "sequence_number": 393056,
      "sip_timestamp": 1659360932900801800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932900461600
    },
    {
      "exchange": 4,
      "id": "12402",
      "participant_timestamp": 1659360932899728100,
      "price": 162.098,
      "sequence_number": 393055,
      "sip_timestamp": 1659360932900004600,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932899979500
    },
    {
      "exchange": 4,
      "id": "12401",
      "participant_timestamp": 1659360932893649700,
      "price": 162.0821,
      "sequence_number": 393048,
      "sip_timestamp": 1659360932894653400,
      "size": 500,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932894631700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27238",
      "participant_timestamp": 1659360932828000000,
      "price": 161.0488,
      "sequence_number": 393047,
      "sip_timestamp": 1659360932893528800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932893192400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27237",
      "participant_timestamp": 1659360932848000000,
      "price": 161.0488,
      "sequence_number": 393046,
      "sip_timestamp": 1659360932892870700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932892534300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27236",
      "participant_timestamp": 1659360932838000000,
      "price": 161.0488,
      "sequence_number": 393044,
      "sip_timestamp": 1659360932884187100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932883849700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27235",
      "participant_timestamp": 1659360932787000000,
      "price": 161.0488,
      "sequence_number": 393041,
      "sip_timestamp": 1659360932876697900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932876361000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27234",
      "participant_timestamp": 1659360932818000000,
      "price": 161.0488,
      "sequence_number": 393039,
      "sip_timestamp": 1659360932875956700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932875620000
    },
    {
      "conditions": [
        32,
        37
      ],
      "exchange": 4,
      "id": "27233",
      "participant_timestamp": 1659360932880000000,
      "price": 162.09,
      "sequence_number": 393038,
      "sip_timestamp": 1659360932875070200,
      "size": 50,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932874731300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12400",
      "participant_timestamp": 1659360932879000000,
      "price": 162.09,
      "sequence_number": 393037,
      "sip_timestamp": 1659360932873153000,
      "size": 25,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932873131000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27232",
      "participant_timestamp": 1659360932808000000,
      "price": 161.0488,
      "sequence_number": 393028,
      "sip_timestamp": 1659360932846769000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932846432500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27231",
      "participant_timestamp": 1659360932797000000,
      "price": 161.0488,
      "sequence_number": 393027,
      "sip_timestamp": 1659360932846712600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932846375700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27230",
      "participant_timestamp": 1659360932757000000,
      "price": 161.0488,
      "sequence_number": 393024,
      "sip_timestamp": 1659360932846497500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932846161200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27229",
      "participant_timestamp": 1659360932737000000,
      "price": 161.0488,
      "sequence_number": 393020,
      "sip_timestamp": 1659360932833398300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932833060000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27228",
      "participant_timestamp": 1659360932777000000,
      "price": 161.0488,
      "sequence_number": 393018,
      "sip_timestamp": 1659360932829620500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932829283600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27227",
      "participant_timestamp": 1659360932767000000,
      "price": 161.0488,
      "sequence_number": 393017,
      "sip_timestamp": 1659360932829116200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932828777700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12399",
      "participant_timestamp": 1659360932827000000,
      "price": 162.0801,
      "sequence_number": 393015,
      "sip_timestamp": 1659360932821291800,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932821259300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27226",
      "participant_timestamp": 1659360932747000000,
      "price": 161.0488,
      "sequence_number": 393009,
      "sip_timestamp": 1659360932799636200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932799299000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27225",
      "participant_timestamp": 1659360932727000000,
      "price": 161.0488,
      "sequence_number": 393005,
      "sip_timestamp": 1659360932782774300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932782436600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27224",
      "participant_timestamp": 1659360932730000000,
      "price": 162.07,
      "sequence_number": 392984,
      "sip_timestamp": 1659360932767490000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932767152000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27223",
      "participant_timestamp": 1659360932717000000,
      "price": 161.0488,
      "sequence_number": 392983,
      "sip_timestamp": 1659360932767406800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932767067400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27222",
      "participant_timestamp": 1659360932667000000,
      "price": 161.0488,
      "sequence_number": 392968,
      "sip_timestamp": 1659360932754683100,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932754346500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27221",
      "participant_timestamp": 1659360932707000000,
      "price": 161.0488,
      "sequence_number": 392967,
      "sip_timestamp": 1659360932754337300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932754000600
    },
    {
      "exchange": 4,
      "id": "12398",
      "participant_timestamp": 1659360932751145200,
      "price": 162.09,
      "sequence_number": 392966,
      "sip_timestamp": 1659360932753778000,
      "size": 1294,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932753754600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27220",
      "participant_timestamp": 1659360932697000000,
      "price": 161.0488,
      "sequence_number": 392964,
      "sip_timestamp": 1659360932752118000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932751781400
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1388",
      "participant_timestamp": 1659360932750648000,
      "price": 162.09,
      "sequence_number": 392962,
      "sip_timestamp": 1659360932750839600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3108",
      "participant_timestamp": 1659360932750356000,
      "price": 162.09,
      "sequence_number": 392960,
      "sip_timestamp": 1659360932750558500,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3107",
      "participant_timestamp": 1659360932750356000,
      "price": 162.09,
      "sequence_number": 392959,
      "sip_timestamp": 1659360932750555100,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4801",
      "participant_timestamp": 1659360932750331000,
      "price": 162.09,
      "sequence_number": 392958,
      "sip_timestamp": 1659360932750540800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1387",
      "participant_timestamp": 1659360932750333700,
      "price": 162.09,
      "sequence_number": 392957,
      "sip_timestamp": 1659360932750526000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9634",
      "participant_timestamp": 1659360932750396000,
      "price": 162.09,
      "sequence_number": 392956,
      "sip_timestamp": 1659360932750414600,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9633",
      "participant_timestamp": 1659360932750396000,
      "price": 162.09,
      "sequence_number": 392955,
      "sip_timestamp": 1659360932750412300,
      "size": 6,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12397",
      "participant_timestamp": 1659360932747743000,
      "price": 162.0886,
      "sequence_number": 392934,
      "sip_timestamp": 1659360932748720600,
      "size": 24,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932748698000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12396",
      "participant_timestamp": 1659360932746000000,
      "price": 162.0779,
      "sequence_number": 392933,
      "sip_timestamp": 1659360932748100000,
      "size": 20,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932748072700
    },
    {
      "exchange": 4,
      "id": "12395",
      "participant_timestamp": 1659360932738596900,
      "price": 162.09,
      "sequence_number": 392930,
      "sip_timestamp": 1659360932739691800,
      "size": 2000,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932739670000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27219",
      "participant_timestamp": 1659360932687000000,
      "price": 161.0488,
      "sequence_number": 392927,
      "sip_timestamp": 1659360932737207000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932736869400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27218",
      "participant_timestamp": 1659360932677000000,
      "price": 161.0488,
      "sequence_number": 392925,
      "sip_timestamp": 1659360932735967200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932735630600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3106",
      "participant_timestamp": 1659360932707180000,
      "price": 162.08,
      "sequence_number": 392920,
      "sip_timestamp": 1659360932707370000,
      "size": 14,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8453",
      "participant_timestamp": 1659360932706962000,
      "price": 162.08,
      "sequence_number": 392919,
      "sip_timestamp": 1659360932707306500,
      "size": 110,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3105",
      "participant_timestamp": 1659360932706720000,
      "price": 162.08,
      "sequence_number": 392918,
      "sip_timestamp": 1659360932706920700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3104",
      "participant_timestamp": 1659360932706720000,
      "price": 162.08,
      "sequence_number": 392917,
      "sip_timestamp": 1659360932706915300,
      "size": 700,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8452",
      "participant_timestamp": 1659360932706548000,
      "price": 162.08,
      "sequence_number": 392916,
      "sip_timestamp": 1659360932706892800,
      "size": 590,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9632",
      "participant_timestamp": 1659360932706569500,
      "price": 162.08,
      "sequence_number": 392915,
      "sip_timestamp": 1659360932706587400,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9631",
      "participant_timestamp": 1659360932706569500,
      "price": 162.08,
      "sequence_number": 392914,
      "sip_timestamp": 1659360932706585600,
      "size": 500,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1386",
      "participant_timestamp": 1659360932706351600,
      "price": 162.08,
      "sequence_number": 392913,
      "sip_timestamp": 1659360932706543600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4800",
      "participant_timestamp": 1659360932706325000,
      "price": 162.08,
      "sequence_number": 392912,
      "sip_timestamp": 1659360932706529300,
      "size": 397,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27217",
      "participant_timestamp": 1659360932655000000,
      "price": 161.0488,
      "sequence_number": 392911,
      "sip_timestamp": 1659360932706070000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932705733400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27216",
      "participant_timestamp": 1659360932645000000,
      "price": 161.0488,
      "sequence_number": 392910,
      "sip_timestamp": 1659360932705981200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932705644300
    },
    {
      "exchange": 4,
      "id": "12394",
      "participant_timestamp": 1659360932704957400,
      "price": 162.08,
      "sequence_number": 392907,
      "sip_timestamp": 1659360932705336800,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932705315000
    },
    {
      "exchange": 4,
      "id": "12393",
      "participant_timestamp": 1659360932709000000,
      "price": 162.07,
      "sequence_number": 392906,
      "sip_timestamp": 1659360932703617300,
      "size": 450,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932703595300
    },
    {
      "exchange": 4,
      "id": "12392",
      "participant_timestamp": 1659360932709000000,
      "price": 162.075,
      "sequence_number": 392905,
      "sip_timestamp": 1659360932702989300,
      "size": 300,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932702962400
    },
    {
      "exchange": 8,
      "id": "4799",
      "participant_timestamp": 1659360932701341000,
      "price": 162.08,
      "sequence_number": 392904,
      "sip_timestamp": 1659360932701543000,
      "size": 200,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12391",
      "participant_timestamp": 1659360932699754000,
      "price": 162.079,
      "sequence_number": 392903,
      "sip_timestamp": 1659360932700790500,
      "size": 1999,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932700766500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12390",
      "participant_timestamp": 1659360932696228000,
      "price": 162.08,
      "sequence_number": 392902,
      "sip_timestamp": 1659360932700289800,
      "size": 66,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932700266000
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4798",
      "participant_timestamp": 1659360932699835000,
      "price": 162.08,
      "sequence_number": 392901,
      "sip_timestamp": 1659360932700040400,
      "size": 3,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12389",
      "participant_timestamp": 1659360932696000000,
      "price": 162.09,
      "sequence_number": 392900,
      "sip_timestamp": 1659360932698828300,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932698806000
    },
    {
      "exchange": 4,
      "id": "12388",
      "participant_timestamp": 1659360932695000000,
      "price": 162.09,
      "sequence_number": 392899,
      "sip_timestamp": 1659360932697606400,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932697584000
    },
    {
      "exchange": 12,
      "id": "9630",
      "participant_timestamp": 1659360932697326800,
      "price": 162.08,
      "sequence_number": 392898,
      "sip_timestamp": 1659360932697343000,
      "size": 300,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3103",
      "participant_timestamp": 1659360932697116000,
      "price": 162.08,
      "sequence_number": 392897,
      "sip_timestamp": 1659360932697315600,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1385",
      "participant_timestamp": 1659360932697109800,
      "price": 162.08,
      "sequence_number": 392896,
      "sip_timestamp": 1659360932697308200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 21,
      "id": "1384",
      "participant_timestamp": 1659360932697109800,
      "price": 162.08,
      "sequence_number": 392895,
      "sip_timestamp": 1659360932697308200,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12387",
      "participant_timestamp": 1659360932696888000,
      "price": 162.08,
      "sequence_number": 392894,
      "sip_timestamp": 1659360932697007900,
      "size": 18,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932696984000
    },
    {
      "exchange": 4,
      "id": "12386",
      "participant_timestamp": 1659360932693000000,
      "price": 162.09,
      "sequence_number": 392893,
      "sip_timestamp": 1659360932695535900,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932695510000
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "756",
      "participant_timestamp": 1659360932691484000,
      "price": 162.08,
      "sequence_number": 392892,
      "sip_timestamp": 1659360932691684900,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8451",
      "participant_timestamp": 1659360932691007200,
      "price": 162.08,
      "sequence_number": 392890,
      "sip_timestamp": 1659360932691357700,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8450",
      "participant_timestamp": 1659360932691007200,
      "price": 162.08,
      "sequence_number": 392889,
      "sip_timestamp": 1659360932691357400,
      "size": 11,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8449",
      "participant_timestamp": 1659360932691007200,
      "price": 162.08,
      "sequence_number": 392888,
      "sip_timestamp": 1659360932691354000,
      "size": 200,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3102",
      "participant_timestamp": 1659360932690857000,
      "price": 162.08,
      "sequence_number": 392887,
      "sip_timestamp": 1659360932691064000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9629",
      "participant_timestamp": 1659360932691042000,
      "price": 162.08,
      "sequence_number": 392886,
      "sip_timestamp": 1659360932691061000,
      "size": 92,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3101",
      "participant_timestamp": 1659360932690857000,
      "price": 162.08,
      "sequence_number": 392885,
      "sip_timestamp": 1659360932691058700,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9628",
      "participant_timestamp": 1659360932691042000,
      "price": 162.08,
      "sequence_number": 392884,
      "sip_timestamp": 1659360932691058400,
      "size": 41,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9627",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392883,
      "sip_timestamp": 1659360932691047400,
      "size": 59,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9626",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392882,
      "sip_timestamp": 1659360932691045400,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9625",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392881,
      "sip_timestamp": 1659360932691042300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9624",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392880,
      "sip_timestamp": 1659360932691037700,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9623",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392879,
      "sip_timestamp": 1659360932691035000,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9622",
      "participant_timestamp": 1659360932691013000,
      "price": 162.08,
      "sequence_number": 392878,
      "sip_timestamp": 1659360932691032000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4797",
      "participant_timestamp": 1659360932690810000,
      "price": 162.08,
      "sequence_number": 392877,
      "sip_timestamp": 1659360932691031800,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4796",
      "participant_timestamp": 1659360932690810000,
      "price": 162.08,
      "sequence_number": 392876,
      "sip_timestamp": 1659360932691024100,
      "size": 150,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12385",
      "participant_timestamp": 1659360932688000000,
      "price": 162.08,
      "sequence_number": 392874,
      "sip_timestamp": 1659360932690576600,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932690553000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27215",
      "participant_timestamp": 1659360932635000000,
      "price": 161.0488,
      "sequence_number": 392870,
      "sip_timestamp": 1659360932688487200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932688147500
    },
    {
      "exchange": 4,
      "id": "12384",
      "participant_timestamp": 1659360932686000000,
      "price": 162.08,
      "sequence_number": 392869,
      "sip_timestamp": 1659360932688390700,
      "size": 300,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932688367400
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 21,
      "id": "1383",
      "participant_timestamp": 1659360932679847200,
      "price": 162.07,
      "sequence_number": 392864,
      "sip_timestamp": 1659360932680036400,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1382",
      "participant_timestamp": 1659360932679575800,
      "price": 162.07,
      "sequence_number": 392863,
      "sip_timestamp": 1659360932679770000,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12383",
      "participant_timestamp": 1659360932677342000,
      "price": 162.0782,
      "sequence_number": 392861,
      "sip_timestamp": 1659360932678397000,
      "size": 122,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932678374400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9621",
      "participant_timestamp": 1659360932675390500,
      "price": 162.07,
      "sequence_number": 392860,
      "sip_timestamp": 1659360932675406600,
      "size": 44,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27214",
      "participant_timestamp": 1659360932625000000,
      "price": 161.0488,
      "sequence_number": 392840,
      "sip_timestamp": 1659360932665421600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932665084200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27213",
      "participant_timestamp": 1659360932594000000,
      "price": 161.0488,
      "sequence_number": 392836,
      "sip_timestamp": 1659360932659342300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932659003000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27212",
      "participant_timestamp": 1659360932615000000,
      "price": 161.0488,
      "sequence_number": 392835,
      "sip_timestamp": 1659360932659301400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932658962200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27211",
      "participant_timestamp": 1659360932585000000,
      "price": 161.0488,
      "sequence_number": 392834,
      "sip_timestamp": 1659360932659138600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932658802000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27210",
      "participant_timestamp": 1659360932604000000,
      "price": 161.0488,
      "sequence_number": 392831,
      "sip_timestamp": 1659360932657994200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932657657900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27209",
      "participant_timestamp": 1659360932575000000,
      "price": 161.0488,
      "sequence_number": 392830,
      "sip_timestamp": 1659360932657822700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932657485300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27208",
      "participant_timestamp": 1659360932565000000,
      "price": 161.0488,
      "sequence_number": 392827,
      "sip_timestamp": 1659360932645896700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932645559300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27207",
      "participant_timestamp": 1659360932555000000,
      "price": 161.0488,
      "sequence_number": 392816,
      "sip_timestamp": 1659360932612204000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932611866400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27206",
      "participant_timestamp": 1659360932525000000,
      "price": 161.0488,
      "sequence_number": 392804,
      "sip_timestamp": 1659360932596917000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932596580000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27205",
      "participant_timestamp": 1659360932535000000,
      "price": 161.0488,
      "sequence_number": 392799,
      "sip_timestamp": 1659360932595301000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932594963500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27204",
      "participant_timestamp": 1659360932545000000,
      "price": 161.0488,
      "sequence_number": 392798,
      "sip_timestamp": 1659360932594606000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932594269000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3100",
      "participant_timestamp": 1659360932583465000,
      "price": 162.06,
      "sequence_number": 392793,
      "sip_timestamp": 1659360932583656400,
      "size": 11,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3099",
      "participant_timestamp": 1659360932583410000,
      "price": 162.06,
      "sequence_number": 392792,
      "sip_timestamp": 1659360932583598300,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3098",
      "participant_timestamp": 1659360932583323000,
      "price": 162.06,
      "sequence_number": 392791,
      "sip_timestamp": 1659360932583524400,
      "size": 88,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9620",
      "participant_timestamp": 1659360932582692400,
      "price": 162.06,
      "sequence_number": 392790,
      "sip_timestamp": 1659360932582707700,
      "size": 112,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1381",
      "participant_timestamp": 1659360932581590800,
      "price": 162.05,
      "sequence_number": 392788,
      "sip_timestamp": 1659360932581790500,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27203",
      "participant_timestamp": 1659360932484000000,
      "price": 161.0488,
      "sequence_number": 392787,
      "sip_timestamp": 1659360932579901000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932579563800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27202",
      "participant_timestamp": 1659360932515000000,
      "price": 161.0488,
      "sequence_number": 392779,
      "sip_timestamp": 1659360932565694200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932565357300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27201",
      "participant_timestamp": 1659360932505000000,
      "price": 161.0488,
      "sequence_number": 392776,
      "sip_timestamp": 1659360932553887000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932553549600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27200",
      "participant_timestamp": 1659360932550000000,
      "price": 162.06,
      "sequence_number": 392775,
      "sip_timestamp": 1659360932551483000,
      "size": 37,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932551144400
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3097",
      "participant_timestamp": 1659360932550310000,
      "price": 162.05,
      "sequence_number": 392772,
      "sip_timestamp": 1659360932550515700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27199",
      "participant_timestamp": 1659360932494000000,
      "price": 161.0488,
      "sequence_number": 392764,
      "sip_timestamp": 1659360932548861700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932548525300
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9619",
      "participant_timestamp": 1659360932547286800,
      "price": 162.05,
      "sequence_number": 392761,
      "sip_timestamp": 1659360932547304000,
      "size": 52,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12382",
      "participant_timestamp": 1659360932546000000,
      "price": 162.06,
      "sequence_number": 392760,
      "sip_timestamp": 1659360932547194000,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932547161900
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 17,
      "id": "195",
      "participant_timestamp": 1659360932527671600,
      "price": 162.05,
      "sequence_number": 392755,
      "sip_timestamp": 1659360932527688000,
      "size": 32,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12381",
      "participant_timestamp": 1659360932524743400,
      "price": 162.06,
      "sequence_number": 392752,
      "sip_timestamp": 1659360932525151700,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932525123300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27198",
      "participant_timestamp": 1659360932464000000,
      "price": 161.0488,
      "sequence_number": 392749,
      "sip_timestamp": 1659360932518576000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932518239200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27197",
      "participant_timestamp": 1659360932474000000,
      "price": 161.0488,
      "sequence_number": 392748,
      "sip_timestamp": 1659360932518534400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932518197800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27196",
      "participant_timestamp": 1659360932454000000,
      "price": 161.0488,
      "sequence_number": 392743,
      "sip_timestamp": 1659360932515461600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932515125000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27195",
      "participant_timestamp": 1659360932424000000,
      "price": 161.0488,
      "sequence_number": 392742,
      "sip_timestamp": 1659360932515278800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932514941700
    },
    {
      "exchange": 4,
      "id": "12380",
      "participant_timestamp": 1659360932514721500,
      "price": 162.068,
      "sequence_number": 392741,
      "sip_timestamp": 1659360932515047400,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932515023400
    },
    {
      "exchange": 4,
      "id": "12379",
      "participant_timestamp": 1659360932514677500,
      "price": 162.065,
      "sequence_number": 392740,
      "sip_timestamp": 1659360932514956000,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932514934500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27194",
      "participant_timestamp": 1659360932444000000,
      "price": 161.0488,
      "sequence_number": 392734,
      "sip_timestamp": 1659360932501575700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932501239000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27193",
      "participant_timestamp": 1659360932434000000,
      "price": 161.0488,
      "sequence_number": 392733,
      "sip_timestamp": 1659360932500931800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932500594700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9618",
      "participant_timestamp": 1659360932498579500,
      "price": 162.06,
      "sequence_number": 392732,
      "sip_timestamp": 1659360932498598400,
      "size": 24,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9617",
      "participant_timestamp": 1659360932498579500,
      "price": 162.06,
      "sequence_number": 392731,
      "sip_timestamp": 1659360932498595600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4795",
      "participant_timestamp": 1659360932484360000,
      "price": 162.07,
      "sequence_number": 392724,
      "sip_timestamp": 1659360932484562700,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3096",
      "participant_timestamp": 1659360932484347000,
      "price": 162.07,
      "sequence_number": 392723,
      "sip_timestamp": 1659360932484541200,
      "size": 44,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 21,
      "id": "1380",
      "participant_timestamp": 1659360932484331500,
      "price": 162.07,
      "sequence_number": 392722,
      "sip_timestamp": 1659360932484525300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3095",
      "participant_timestamp": 1659360932484245000,
      "price": 162.07,
      "sequence_number": 392721,
      "sip_timestamp": 1659360932484449500,
      "size": 56,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27192",
      "participant_timestamp": 1659360932414000000,
      "price": 161.0488,
      "sequence_number": 392717,
      "sip_timestamp": 1659360932471608300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932471271400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27191",
      "participant_timestamp": 1659360932404000000,
      "price": 161.0488,
      "sequence_number": 392716,
      "sip_timestamp": 1659360932470819800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932470482000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27190",
      "participant_timestamp": 1659360932374000000,
      "price": 161.0488,
      "sequence_number": 392713,
      "sip_timestamp": 1659360932461129000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932460791000
    },
    {
      "exchange": 4,
      "id": "12378",
      "participant_timestamp": 1659360932456000000,
      "price": 162.08,
      "sequence_number": 392712,
      "sip_timestamp": 1659360932458509000,
      "size": 107,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932458486500
    },
    {
      "exchange": 4,
      "id": "12377",
      "participant_timestamp": 1659360932454000000,
      "price": 162.08,
      "sequence_number": 392710,
      "sip_timestamp": 1659360932456452600,
      "size": 602,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932456430600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27189",
      "participant_timestamp": 1659360932000000000,
      "price": 162.08,
      "sequence_number": 392706,
      "sip_timestamp": 1659360932455087900,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932454750000
    },
    {
      "exchange": 4,
      "id": "12376",
      "participant_timestamp": 1659360932452000000,
      "price": 162.075,
      "sequence_number": 392705,
      "sip_timestamp": 1659360932454789400,
      "size": 1291,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932454767900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27188",
      "participant_timestamp": 1659360932384000000,
      "price": 161.0488,
      "sequence_number": 392703,
      "sip_timestamp": 1659360932454699000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932454362000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4794",
      "participant_timestamp": 1659360932439600000,
      "price": 162.08,
      "sequence_number": 392697,
      "sip_timestamp": 1659360932439815400,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12375",
      "participant_timestamp": 1659360932431000000,
      "price": 162.08,
      "sequence_number": 392695,
      "sip_timestamp": 1659360932433396000,
      "size": 1312,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932433366800
    },
    {
      "exchange": 4,
      "id": "12374",
      "participant_timestamp": 1659360932432030200,
      "price": 162.08,
      "sequence_number": 392694,
      "sip_timestamp": 1659360932433276700,
      "size": 688,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932433252600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27187",
      "participant_timestamp": 1659360932394000000,
      "price": 161.0488,
      "sequence_number": 392693,
      "sip_timestamp": 1659360932432879400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932432540200
    },
    {
      "exchange": 4,
      "id": "12373",
      "participant_timestamp": 1659360932428250400,
      "price": 162.0787,
      "sequence_number": 392692,
      "sip_timestamp": 1659360932429489400,
      "size": 859,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932429465600
    },
    {
      "exchange": 4,
      "id": "12372",
      "participant_timestamp": 1659360932426801000,
      "price": 162.08,
      "sequence_number": 392691,
      "sip_timestamp": 1659360932427742500,
      "size": 900,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932427719700
    },
    {
      "exchange": 4,
      "id": "12371",
      "participant_timestamp": 1659360932426316800,
      "price": 162.08,
      "sequence_number": 392690,
      "sip_timestamp": 1659360932427303700,
      "size": 800,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932427276800
    },
    {
      "exchange": 4,
      "id": "12370",
      "participant_timestamp": 1659360932425000000,
      "price": 162.07,
      "sequence_number": 392689,
      "sip_timestamp": 1659360932427048400,
      "size": 200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932427026700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8448",
      "participant_timestamp": 1659360932425519400,
      "price": 162.07,
      "sequence_number": 392687,
      "sip_timestamp": 1659360932425868300,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8447",
      "participant_timestamp": 1659360932425519400,
      "price": 162.07,
      "sequence_number": 392686,
      "sip_timestamp": 1659360932425868300,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8446",
      "participant_timestamp": 1659360932425519400,
      "price": 162.07,
      "sequence_number": 392685,
      "sip_timestamp": 1659360932425868300,
      "size": 2,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3094",
      "participant_timestamp": 1659360932425418000,
      "price": 162.07,
      "sequence_number": 392684,
      "sip_timestamp": 1659360932425627400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3093",
      "participant_timestamp": 1659360932425418000,
      "price": 162.07,
      "sequence_number": 392683,
      "sip_timestamp": 1659360932425614000,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4793",
      "participant_timestamp": 1659360932425397000,
      "price": 162.07,
      "sequence_number": 392682,
      "sip_timestamp": 1659360932425610000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9616",
      "participant_timestamp": 1659360932425493500,
      "price": 162.07,
      "sequence_number": 392680,
      "sip_timestamp": 1659360932425508900,
      "size": 35,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27186",
      "participant_timestamp": 1659360932365000000,
      "price": 161.0488,
      "sequence_number": 392677,
      "sip_timestamp": 1659360932424635600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932424298200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27185",
      "participant_timestamp": 1659360932355000000,
      "price": 161.0488,
      "sequence_number": 392673,
      "sip_timestamp": 1659360932423967000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932423630300
    },
    {
      "exchange": 4,
      "id": "12369",
      "participant_timestamp": 1659360932420000000,
      "price": 162.07,
      "sequence_number": 392672,
      "sip_timestamp": 1659360932423905000,
      "size": 300,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932423883000
    },
    {
      "exchange": 4,
      "id": "12368",
      "participant_timestamp": 1659360932418000000,
      "price": 162.07,
      "sequence_number": 392670,
      "sip_timestamp": 1659360932423117000,
      "size": 300,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932423092500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12367",
      "participant_timestamp": 1659360932421871000,
      "price": 162.0694,
      "sequence_number": 392669,
      "sip_timestamp": 1659360932422837200,
      "size": 30,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932422814700
    },
    {
      "exchange": 4,
      "id": "27184",
      "participant_timestamp": 1659360932416000000,
      "price": 162.06,
      "sequence_number": 392662,
      "sip_timestamp": 1659360932419839500,
      "size": 2600,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932419501000
    },
    {
      "exchange": 4,
      "id": "12366",
      "participant_timestamp": 1659360932415000000,
      "price": 162.06,
      "sequence_number": 392661,
      "sip_timestamp": 1659360932418690300,
      "size": 299,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932418669000
    },
    {
      "exchange": 11,
      "id": "8445",
      "participant_timestamp": 1659360932416459800,
      "price": 162.06,
      "sequence_number": 392660,
      "sip_timestamp": 1659360932416810200,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 11,
      "id": "8444",
      "participant_timestamp": 1659360932416459800,
      "price": 162.06,
      "sequence_number": 392659,
      "sip_timestamp": 1659360932416810000,
      "size": 307,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9615",
      "participant_timestamp": 1659360932416628200,
      "price": 162.06,
      "sequence_number": 392658,
      "sip_timestamp": 1659360932416643000,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9614",
      "participant_timestamp": 1659360932416527600,
      "price": 162.06,
      "sequence_number": 392657,
      "sip_timestamp": 1659360932416554800,
      "size": 70,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9613",
      "participant_timestamp": 1659360932416527600,
      "price": 162.06,
      "sequence_number": 392656,
      "sip_timestamp": 1659360932416552400,
      "size": 28,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9612",
      "participant_timestamp": 1659360932416527600,
      "price": 162.06,
      "sequence_number": 392655,
      "sip_timestamp": 1659360932416548900,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9611",
      "participant_timestamp": 1659360932416527600,
      "price": 162.06,
      "sequence_number": 392654,
      "sip_timestamp": 1659360932416546800,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9610",
      "participant_timestamp": 1659360932416527600,
      "price": 162.06,
      "sequence_number": 392653,
      "sip_timestamp": 1659360932416544800,
      "size": 13,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3092",
      "participant_timestamp": 1659360932416332000,
      "price": 162.06,
      "sequence_number": 392652,
      "sip_timestamp": 1659360932416524500,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9609",
      "participant_timestamp": 1659360932416473600,
      "price": 162.06,
      "sequence_number": 392651,
      "sip_timestamp": 1659360932416492500,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9608",
      "participant_timestamp": 1659360932416473600,
      "price": 162.06,
      "sequence_number": 392650,
      "sip_timestamp": 1659360932416489700,
      "size": 6,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1379",
      "participant_timestamp": 1659360932416228600,
      "price": 162.06,
      "sequence_number": 392649,
      "sip_timestamp": 1659360932416422100,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4792",
      "participant_timestamp": 1659360932416209000,
      "price": 162.06,
      "sequence_number": 392648,
      "sip_timestamp": 1659360932416415700,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12365",
      "participant_timestamp": 1659360932413000000,
      "price": 162.06,
      "sequence_number": 392646,
      "sip_timestamp": 1659360932415697400,
      "size": 301,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932415672300
    },
    {
      "exchange": 4,
      "id": "12364",
      "participant_timestamp": 1659360932412000000,
      "price": 162.055,
      "sequence_number": 392645,
      "sip_timestamp": 1659360932414900500,
      "size": 900,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932414878200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27183",
      "participant_timestamp": 1659360932325000000,
      "price": 161.0488,
      "sequence_number": 392641,
      "sip_timestamp": 1659360932409222000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932408884000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27182",
      "participant_timestamp": 1659360932345000000,
      "price": 161.0488,
      "sequence_number": 392640,
      "sip_timestamp": 1659360932409032200,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932408694800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12363",
      "participant_timestamp": 1659360932406000000,
      "price": 162.06,
      "sequence_number": 392639,
      "sip_timestamp": 1659360932408944400,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932408914700
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8443",
      "participant_timestamp": 1659360932396829000,
      "price": 162.06,
      "sequence_number": 392610,
      "sip_timestamp": 1659360932397173200,
      "size": 2088,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "755",
      "participant_timestamp": 1659360932396931000,
      "price": 162.05,
      "sequence_number": 392609,
      "sip_timestamp": 1659360932397143000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8442",
      "participant_timestamp": 1659360932396706300,
      "price": 162.06,
      "sequence_number": 392608,
      "sip_timestamp": 1659360932397055500,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8441",
      "participant_timestamp": 1659360932396706300,
      "price": 162.06,
      "sequence_number": 392607,
      "sip_timestamp": 1659360932397055500,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4791",
      "participant_timestamp": 1659360932396500000,
      "price": 162.06,
      "sequence_number": 392606,
      "sip_timestamp": 1659360932396726800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4790",
      "participant_timestamp": 1659360932396500000,
      "price": 162.06,
      "sequence_number": 392605,
      "sip_timestamp": 1659360932396721200,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4789",
      "participant_timestamp": 1659360932396500000,
      "price": 162.06,
      "sequence_number": 392604,
      "sip_timestamp": 1659360932396707800,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9607",
      "participant_timestamp": 1659360932396661200,
      "price": 162.06,
      "sequence_number": 392603,
      "sip_timestamp": 1659360932396678000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3091",
      "participant_timestamp": 1659360932396365000,
      "price": 162.06,
      "sequence_number": 392602,
      "sip_timestamp": 1659360932396573400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3090",
      "participant_timestamp": 1659360932396365000,
      "price": 162.06,
      "sequence_number": 392601,
      "sip_timestamp": 1659360932396568300,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3089",
      "participant_timestamp": 1659360932396365000,
      "price": 162.05,
      "sequence_number": 392600,
      "sip_timestamp": 1659360932396562700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27181",
      "participant_timestamp": 1659360932379000000,
      "price": 162.0424,
      "sequence_number": 392594,
      "sip_timestamp": 1659360932381518000,
      "size": 25,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932381180700
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9606",
      "participant_timestamp": 1659360932380561200,
      "price": 162.04,
      "sequence_number": 392593,
      "sip_timestamp": 1659360932380576800,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27180",
      "participant_timestamp": 1659360932335000000,
      "price": 161.0488,
      "sequence_number": 392591,
      "sip_timestamp": 1659360932377892000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932377555700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27179",
      "participant_timestamp": 1659360932315000000,
      "price": 161.0488,
      "sequence_number": 392590,
      "sip_timestamp": 1659360932377852400,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932377514800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12362",
      "participant_timestamp": 1659360932372182800,
      "price": 162.0408,
      "sequence_number": 392588,
      "sip_timestamp": 1659360932373247700,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932373222100
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27178",
      "participant_timestamp": 1659360932305000000,
      "price": 161.0488,
      "sequence_number": 392584,
      "sip_timestamp": 1659360932360269600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932359932700
    },
    {
      "exchange": 15,
      "id": "594",
      "participant_timestamp": 1659360932323955500,
      "price": 162.05,
      "sequence_number": 392579,
      "sip_timestamp": 1659360932324214300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27177",
      "participant_timestamp": 1659360932294000000,
      "price": 161.0488,
      "sequence_number": 392578,
      "sip_timestamp": 1659360932319828700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932319491800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27176",
      "participant_timestamp": 1659360932284000000,
      "price": 161.0488,
      "sequence_number": 392575,
      "sip_timestamp": 1659360932308588300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932308251100
    },
    {
      "exchange": 4,
      "id": "27175",
      "participant_timestamp": 1659360932303000000,
      "price": 162.0445,
      "sequence_number": 392573,
      "sip_timestamp": 1659360932305866200,
      "size": 200,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932305529000
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3088",
      "participant_timestamp": 1659360932281296000,
      "price": 162.05,
      "sequence_number": 392525,
      "sip_timestamp": 1659360932281501000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27174",
      "participant_timestamp": 1659360932185000000,
      "price": 161.0488,
      "sequence_number": 392524,
      "sip_timestamp": 1659360932267645700,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932267309000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27173",
      "participant_timestamp": 1659360932165000000,
      "price": 161.0488,
      "sequence_number": 392521,
      "sip_timestamp": 1659360932256291000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932255954000
    },
    {
      "conditions": [
        10,
        37,
        41
      ],
      "exchange": 4,
      "id": "12361",
      "participant_timestamp": 1659360932206825000,
      "price": 162,
      "sequence_number": 392517,
      "sip_timestamp": 1659360932252913700,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932252890000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12360",
      "participant_timestamp": 1659360932248166100,
      "price": 162.045,
      "sequence_number": 392516,
      "sip_timestamp": 1659360932248459800,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932248435500
    },
    {
      "exchange": 4,
      "id": "12359",
      "participant_timestamp": 1659360932186944800,
      "price": 162.06,
      "sequence_number": 392515,
      "sip_timestamp": 1659360932247174000,
      "size": 400,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932247150000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12358",
      "participant_timestamp": 1659360932242700000,
      "price": 162.04,
      "sequence_number": 392513,
      "sip_timestamp": 1659360932244623600,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932244601600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12357",
      "participant_timestamp": 1659360932237000000,
      "price": 162.0564,
      "sequence_number": 392503,
      "sip_timestamp": 1659360932239507700,
      "size": 50,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932239484400
    },
    {
      "exchange": 7,
      "id": "754",
      "participant_timestamp": 1659360932236669000,
      "price": 162.06,
      "sequence_number": 392502,
      "sip_timestamp": 1659360932236867300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27172",
      "participant_timestamp": 1659360932155000000,
      "price": 161.0488,
      "sequence_number": 392498,
      "sip_timestamp": 1659360932230004500,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932229667600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27171",
      "participant_timestamp": 1659360932175000000,
      "price": 161.0488,
      "sequence_number": 392495,
      "sip_timestamp": 1659360932225742600,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932225405200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12356",
      "participant_timestamp": 1659360932218000000,
      "price": 162.0599,
      "sequence_number": 392489,
      "sip_timestamp": 1659360932211834000,
      "size": 50,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932211812600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27170",
      "participant_timestamp": 1659360932145000000,
      "price": 161.0488,
      "sequence_number": 392486,
      "sip_timestamp": 1659360932208899800,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932208560600
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9605",
      "participant_timestamp": 1659360932200068000,
      "price": 162.05,
      "sequence_number": 392484,
      "sip_timestamp": 1659360932200083500,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27169",
      "participant_timestamp": 1659360932125000000,
      "price": 161.0488,
      "sequence_number": 392482,
      "sip_timestamp": 1659360932195948300,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932195610400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27168",
      "participant_timestamp": 1659360932135000000,
      "price": 161.0488,
      "sequence_number": 392480,
      "sip_timestamp": 1659360932194528000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932194191400
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 7,
      "id": "753",
      "participant_timestamp": 1659360932192740000,
      "price": 162.06,
      "sequence_number": 392478,
      "sip_timestamp": 1659360932192941000,
      "size": 85,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 7,
      "id": "752",
      "participant_timestamp": 1659360932192663000,
      "price": 162.06,
      "sequence_number": 392477,
      "sip_timestamp": 1659360932192858600,
      "size": 15,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9604",
      "participant_timestamp": 1659360932192618200,
      "price": 162.05,
      "sequence_number": 392475,
      "sip_timestamp": 1659360932192633900,
      "size": 139,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27167",
      "participant_timestamp": 1659360932104000000,
      "price": 161.0488,
      "sequence_number": 392472,
      "sip_timestamp": 1659360932191946000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932191609300
    },
    {
      "exchange": 12,
      "id": "9603",
      "participant_timestamp": 1659360932187960000,
      "price": 162.05,
      "sequence_number": 392447,
      "sip_timestamp": 1659360932187976700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12355",
      "participant_timestamp": 1659360932184000000,
      "price": 162.05,
      "sequence_number": 392440,
      "sip_timestamp": 1659360932185086500,
      "size": 12,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932185059800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27166",
      "participant_timestamp": 1659360932115000000,
      "price": 161.0488,
      "sequence_number": 392436,
      "sip_timestamp": 1659360932177436000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932177098000
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8440",
      "participant_timestamp": 1659360932171892500,
      "price": 162.05,
      "sequence_number": 392402,
      "sip_timestamp": 1659360932172244200,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8439",
      "participant_timestamp": 1659360932171892500,
      "price": 162.05,
      "sequence_number": 392401,
      "sip_timestamp": 1659360932172244200,
      "size": 1,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3087",
      "participant_timestamp": 1659360932171422000,
      "price": 162.05,
      "sequence_number": 392396,
      "sip_timestamp": 1659360932171633400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3086",
      "participant_timestamp": 1659360932171422000,
      "price": 162.05,
      "sequence_number": 392395,
      "sip_timestamp": 1659360932171625500,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3085",
      "participant_timestamp": 1659360932171422000,
      "price": 162.05,
      "sequence_number": 392394,
      "sip_timestamp": 1659360932171620400,
      "size": 55,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9602",
      "participant_timestamp": 1659360932171587000,
      "price": 162.05,
      "sequence_number": 392393,
      "sip_timestamp": 1659360932171615700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9601",
      "participant_timestamp": 1659360932171587000,
      "price": 162.05,
      "sequence_number": 392392,
      "sip_timestamp": 1659360932171613700,
      "size": 45,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9600",
      "participant_timestamp": 1659360932171587000,
      "price": 162.05,
      "sequence_number": 392391,
      "sip_timestamp": 1659360932171611600,
      "size": 6,
      "tape": 3
    },
    {
      "exchange": 12,
      "id": "9599",
      "participant_timestamp": 1659360932171587000,
      "price": 162.05,
      "sequence_number": 392390,
      "sip_timestamp": 1659360932171610000,
      "size": 449,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9598",
      "participant_timestamp": 1659360932171564300,
      "price": 162.05,
      "sequence_number": 392389,
      "sip_timestamp": 1659360932171594500,
      "size": 51,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9597",
      "participant_timestamp": 1659360932171564300,
      "price": 162.05,
      "sequence_number": 392388,
      "sip_timestamp": 1659360932171591400,
      "size": 47,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9596",
      "participant_timestamp": 1659360932171564300,
      "price": 162.05,
      "sequence_number": 392387,
      "sip_timestamp": 1659360932171589400,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 18,
      "id": "145",
      "participant_timestamp": 1659360932171323000,
      "price": 162.05,
      "sequence_number": 392384,
      "sip_timestamp": 1659360932171533300,
      "size": 30,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12354",
      "participant_timestamp": 1659360932170367500,
      "price": 162.05,
      "sequence_number": 392382,
      "sip_timestamp": 1659360932171478800,
      "size": 2900,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932171455500
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3084",
      "participant_timestamp": 1659360932169873000,
      "price": 162.04,
      "sequence_number": 392367,
      "sip_timestamp": 1659360932170070300,
      "size": 46,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9595",
      "participant_timestamp": 1659360932169683000,
      "price": 162.04,
      "sequence_number": 392366,
      "sip_timestamp": 1659360932169702400,
      "size": 25,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12353",
      "participant_timestamp": 1659360932140814000,
      "price": 162.05,
      "sequence_number": 392364,
      "sip_timestamp": 1659360932167121200,
      "size": 255,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932167097300
    },
    {
      "exchange": 4,
      "id": "12352",
      "participant_timestamp": 1659360932162699300,
      "price": 162.0392,
      "sequence_number": 392363,
      "sip_timestamp": 1659360932163672300,
      "size": 222,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932163650300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12351",
      "participant_timestamp": 1659360932161089800,
      "price": 162.04,
      "sequence_number": 392362,
      "sip_timestamp": 1659360932162072800,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932162048800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12350",
      "participant_timestamp": 1659360932158938000,
      "price": 162.03,
      "sequence_number": 392361,
      "sip_timestamp": 1659360932161937200,
      "size": 10,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932161914600
    },
    {
      "exchange": 4,
      "id": "27165",
      "participant_timestamp": 1659360932157000000,
      "price": 162.03,
      "sequence_number": 392358,
      "sip_timestamp": 1659360932159822000,
      "size": 300,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932159484400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27164",
      "participant_timestamp": 1659360932152000000,
      "price": 162.01,
      "sequence_number": 392355,
      "sip_timestamp": 1659360932157372200,
      "size": 40,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932157035000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27163",
      "participant_timestamp": 1659360932152000000,
      "price": 162,
      "sequence_number": 392354,
      "sip_timestamp": 1659360932157283000,
      "size": 41,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932156944400
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27162",
      "participant_timestamp": 1659360932149000000,
      "price": 162.02,
      "sequence_number": 392352,
      "sip_timestamp": 1659360932155931400,
      "size": 15,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932155594500
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27161",
      "participant_timestamp": 1659360932146000000,
      "price": 162,
      "sequence_number": 392351,
      "sip_timestamp": 1659360932155892200,
      "size": 13,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932155554600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27160",
      "participant_timestamp": 1659360932150000000,
      "price": 162.02,
      "sequence_number": 392350,
      "sip_timestamp": 1659360932155848000,
      "size": 21,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932155511000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 15,
      "id": "593",
      "participant_timestamp": 1659360932155487500,
      "price": 162.03,
      "sequence_number": 392348,
      "sip_timestamp": 1659360932155735000,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 15,
      "id": "592",
      "participant_timestamp": 1659360932155405600,
      "price": 162.03,
      "sequence_number": 392347,
      "sip_timestamp": 1659360932155667500,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27159",
      "participant_timestamp": 1659360932146000000,
      "price": 162,
      "sequence_number": 392346,
      "sip_timestamp": 1659360932155664400,
      "size": 54,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932155326200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12349",
      "participant_timestamp": 1659360932149000000,
      "price": 162,
      "sequence_number": 392345,
      "sip_timestamp": 1659360932155367400,
      "size": 5,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932155342800
    },
    {
      "exchange": 4,
      "id": "12348",
      "participant_timestamp": 1659360932155178500,
      "price": 162.03,
      "sequence_number": 392344,
      "sip_timestamp": 1659360932155277600,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932155255800
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27158",
      "participant_timestamp": 1659360932148000000,
      "price": 162.01,
      "sequence_number": 392343,
      "sip_timestamp": 1659360932155076600,
      "size": 61,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932154739700
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12347",
      "participant_timestamp": 1659360932153803300,
      "price": 162.04,
      "sequence_number": 392342,
      "sip_timestamp": 1659360932154787800,
      "size": 1,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932154764000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27157",
      "participant_timestamp": 1659360932147000000,
      "price": 162.01,
      "sequence_number": 392341,
      "sip_timestamp": 1659360932154605000,
      "size": 54,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932154266400
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "751",
      "participant_timestamp": 1659360932154378000,
      "price": 162.03,
      "sequence_number": 392340,
      "sip_timestamp": 1659360932154582800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12346",
      "participant_timestamp": 1659360932154171600,
      "price": 162.04,
      "sequence_number": 392339,
      "sip_timestamp": 1659360932154576100,
      "size": 100,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932154552300
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27156",
      "participant_timestamp": 1659360932146000000,
      "price": 162.01,
      "sequence_number": 392335,
      "sip_timestamp": 1659360932154308400,
      "size": 20,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932153970200
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "750",
      "participant_timestamp": 1659360932154102000,
      "price": 162.04,
      "sequence_number": 392334,
      "sip_timestamp": 1659360932154299100,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27155",
      "participant_timestamp": 1659360932145000000,
      "price": 162.01,
      "sequence_number": 392332,
      "sip_timestamp": 1659360932154191000,
      "size": 13,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932153854200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8438",
      "participant_timestamp": 1659360932153044000,
      "price": 162.03,
      "sequence_number": 392329,
      "sip_timestamp": 1659360932153389600,
      "size": 46,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27154",
      "participant_timestamp": 1659360932144000000,
      "price": 162.02,
      "sequence_number": 392328,
      "sip_timestamp": 1659360932153149400,
      "size": 33,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932152810200
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27153",
      "participant_timestamp": 1659360932142000000,
      "price": 162.02,
      "sequence_number": 392327,
      "sip_timestamp": 1659360932152847000,
      "size": 68,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932152509400
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4788",
      "participant_timestamp": 1659360932148942000,
      "price": 162.04,
      "sequence_number": 392306,
      "sip_timestamp": 1659360932149151200,
      "size": 22,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4787",
      "participant_timestamp": 1659360932148942000,
      "price": 162.04,
      "sequence_number": 392305,
      "sip_timestamp": 1659360932149143600,
      "size": 98,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8437",
      "participant_timestamp": 1659360932148687000,
      "price": 162.04,
      "sequence_number": 392304,
      "sip_timestamp": 1659360932149033000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4786",
      "participant_timestamp": 1659360932148758000,
      "price": 162.04,
      "sequence_number": 392303,
      "sip_timestamp": 1659360932148966000,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 7,
      "id": "749",
      "participant_timestamp": 1659360932148636000,
      "price": 162.04,
      "sequence_number": 392302,
      "sip_timestamp": 1659360932148837400,
      "size": 27,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 7,
      "id": "748",
      "participant_timestamp": 1659360932148515000,
      "price": 162.04,
      "sequence_number": 392301,
      "sip_timestamp": 1659360932148764000,
      "size": 73,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3083",
      "participant_timestamp": 1659360932148511000,
      "price": 162.04,
      "sequence_number": 392300,
      "sip_timestamp": 1659360932148711200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3082",
      "participant_timestamp": 1659360932148511000,
      "price": 162.04,
      "sequence_number": 392299,
      "sip_timestamp": 1659360932148705300,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9594",
      "participant_timestamp": 1659360932148614100,
      "price": 162.04,
      "sequence_number": 392298,
      "sip_timestamp": 1659360932148637000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9593",
      "participant_timestamp": 1659360932148614100,
      "price": 162.04,
      "sequence_number": 392297,
      "sip_timestamp": 1659360932148634000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9592",
      "participant_timestamp": 1659360932148614100,
      "price": 162.04,
      "sequence_number": 392296,
      "sip_timestamp": 1659360932148632000,
      "size": 27,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4785",
      "participant_timestamp": 1659360932147006000,
      "price": 162.03,
      "sequence_number": 392295,
      "sip_timestamp": 1659360932147204600,
      "size": 29,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4784",
      "participant_timestamp": 1659360932146924000,
      "price": 162.03,
      "sequence_number": 392294,
      "sip_timestamp": 1659360932147123500,
      "size": 20,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3081",
      "participant_timestamp": 1659360932146873000,
      "price": 162.03,
      "sequence_number": 392293,
      "sip_timestamp": 1659360932147065000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3080",
      "participant_timestamp": 1659360932146864000,
      "price": 162.03,
      "sequence_number": 392292,
      "sip_timestamp": 1659360932147059700,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3079",
      "participant_timestamp": 1659360932146854000,
      "price": 162.03,
      "sequence_number": 392291,
      "sip_timestamp": 1659360932147048200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4783",
      "participant_timestamp": 1659360932146818000,
      "price": 162.03,
      "sequence_number": 392290,
      "sip_timestamp": 1659360932147037700,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4782",
      "participant_timestamp": 1659360932146784000,
      "price": 162.03,
      "sequence_number": 392289,
      "sip_timestamp": 1659360932147028700,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3078",
      "participant_timestamp": 1659360932146835000,
      "price": 162.03,
      "sequence_number": 392288,
      "sip_timestamp": 1659360932147026400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4781",
      "participant_timestamp": 1659360932146773000,
      "price": 162.03,
      "sequence_number": 392287,
      "sip_timestamp": 1659360932147022800,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4780",
      "participant_timestamp": 1659360932146754000,
      "price": 162.03,
      "sequence_number": 392286,
      "sip_timestamp": 1659360932146977500,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4779",
      "participant_timestamp": 1659360932146723000,
      "price": 162.03,
      "sequence_number": 392285,
      "sip_timestamp": 1659360932146969900,
      "size": 1,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12345",
      "participant_timestamp": 1659360932132748000,
      "price": 162,
      "sequence_number": 392283,
      "sip_timestamp": 1659360932144669000,
      "size": 300,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932144644900
    },
    {
      "conditions": [
        37
      ],
      "exchange": 11,
      "id": "8436",
      "participant_timestamp": 1659360932140800800,
      "price": 162.02,
      "sequence_number": 392261,
      "sip_timestamp": 1659360932141145300,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4778",
      "participant_timestamp": 1659360932140501000,
      "price": 162.05,
      "sequence_number": 392260,
      "sip_timestamp": 1659360932140779000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4777",
      "participant_timestamp": 1659360932140501000,
      "price": 162.05,
      "sequence_number": 392259,
      "sip_timestamp": 1659360932140774100,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4776",
      "participant_timestamp": 1659360932140501000,
      "price": 162.05,
      "sequence_number": 392258,
      "sip_timestamp": 1659360932140740000,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4775",
      "participant_timestamp": 1659360932140501000,
      "price": 162.05,
      "sequence_number": 392257,
      "sip_timestamp": 1659360932140725800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4774",
      "participant_timestamp": 1659360932140501000,
      "price": 162.05,
      "sequence_number": 392256,
      "sip_timestamp": 1659360932140721700,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "12344",
      "participant_timestamp": 1659360932139166700,
      "price": 162.0394,
      "sequence_number": 392251,
      "sip_timestamp": 1659360932140226300,
      "size": 22,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932140204300
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 7,
      "id": "747",
      "participant_timestamp": 1659360932139598000,
      "price": 162.03,
      "sequence_number": 392249,
      "sip_timestamp": 1659360932139819000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3077",
      "participant_timestamp": 1659360932139304000,
      "price": 162.05,
      "sequence_number": 392248,
      "sip_timestamp": 1659360932139528700,
      "size": 31,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3076",
      "participant_timestamp": 1659360932139304000,
      "price": 162.05,
      "sequence_number": 392247,
      "sip_timestamp": 1659360932139523600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3075",
      "participant_timestamp": 1659360932139304000,
      "price": 162.05,
      "sequence_number": 392246,
      "sip_timestamp": 1659360932139513300,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3074",
      "participant_timestamp": 1659360932139304000,
      "price": 162.05,
      "sequence_number": 392245,
      "sip_timestamp": 1659360932139504600,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3073",
      "participant_timestamp": 1659360932139304000,
      "price": 162.04,
      "sequence_number": 392244,
      "sip_timestamp": 1659360932139502000,
      "size": 71,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3072",
      "participant_timestamp": 1659360932139028000,
      "price": 162.04,
      "sequence_number": 392243,
      "sip_timestamp": 1659360932139220500,
      "size": 18,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "27152",
      "participant_timestamp": 1659360932135822600,
      "price": 162.01,
      "sequence_number": 392242,
      "sip_timestamp": 1659360932138972200,
      "size": 11872,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932138634000
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3071",
      "participant_timestamp": 1659360932138526000,
      "price": 162.04,
      "sequence_number": 392241,
      "sip_timestamp": 1659360932138718200,
      "size": 11,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4773",
      "participant_timestamp": 1659360932138435000,
      "price": 162.04,
      "sequence_number": 392240,
      "sip_timestamp": 1659360932138640600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3070",
      "participant_timestamp": 1659360932138049000,
      "price": 162.04,
      "sequence_number": 392239,
      "sip_timestamp": 1659360932138246400,
      "size": 32,
      "tape": 3
    },
    {
      "exchange": 21,
      "id": "1378",
      "participant_timestamp": 1659360932137504300,
      "price": 162.04,
      "sequence_number": 392238,
      "sip_timestamp": 1659360932137985800,
      "size": 100,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "27151",
      "participant_timestamp": 1659360932134525200,
      "price": 162.02,
      "sequence_number": 392237,
      "sip_timestamp": 1659360932137624000,
      "size": 2720,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932137285600
    },
    {
      "exchange": 4,
      "id": "12343",
      "participant_timestamp": 1659360932136091400,
      "price": 162.01,
      "sequence_number": 392236,
      "sip_timestamp": 1659360932137554400,
      "size": 1600,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932137530600
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3069",
      "participant_timestamp": 1659360932137086000,
      "price": 162.04,
      "sequence_number": 392235,
      "sip_timestamp": 1659360932137279000,
      "size": 68,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 21,
      "id": "1377",
      "participant_timestamp": 1659360932136314400,
      "price": 162.04,
      "sequence_number": 392234,
      "sip_timestamp": 1659360932136983600,
      "size": 32,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "12342",
      "participant_timestamp": 1659360932135535000,
      "price": 162.01,
      "sequence_number": 392233,
      "sip_timestamp": 1659360932136919800,
      "size": 1200,
      "tape": 3,
      "trf_id": 202,
      "trf_timestamp": 1659360932136892000
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8435",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392231,
      "sip_timestamp": 1659360932136668200,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8434",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392230,
      "sip_timestamp": 1659360932136668200,
      "size": 18,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8433",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392229,
      "sip_timestamp": 1659360932136662000,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8432",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392228,
      "sip_timestamp": 1659360932136662000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8431",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392227,
      "sip_timestamp": 1659360932136658200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8430",
      "participant_timestamp": 1659360932136307000,
      "price": 162.04,
      "sequence_number": 392226,
      "sip_timestamp": 1659360932136658200,
      "size": 98,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9591",
      "participant_timestamp": 1659360932136364000,
      "price": 162.02,
      "sequence_number": 392224,
      "sip_timestamp": 1659360932136383700,
      "size": 49,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 18,
      "id": "144",
      "participant_timestamp": 1659360932136092000,
      "price": 162.04,
      "sequence_number": 392223,
      "sip_timestamp": 1659360932136310800,
      "size": 18,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9590",
      "participant_timestamp": 1659360932136280800,
      "price": 162.04,
      "sequence_number": 392222,
      "sip_timestamp": 1659360932136299800,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 4,
      "id": "27150",
      "participant_timestamp": 1659360932093000000,
      "price": 161.0488,
      "sequence_number": 392221,
      "sip_timestamp": 1659360932136191000,
      "size": 1,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932135849200
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3068",
      "participant_timestamp": 1659360932135943000,
      "price": 162.03,
      "sequence_number": 392220,
      "sip_timestamp": 1659360932136145400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4772",
      "participant_timestamp": 1659360932135363000,
      "price": 162.03,
      "sequence_number": 392219,
      "sip_timestamp": 1659360932135567400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9589",
      "participant_timestamp": 1659360932135401500,
      "price": 162.03,
      "sequence_number": 392218,
      "sip_timestamp": 1659360932135430400,
      "size": 6,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9588",
      "participant_timestamp": 1659360932135401500,
      "price": 162.03,
      "sequence_number": 392217,
      "sip_timestamp": 1659360932135423700,
      "size": 40,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9587",
      "participant_timestamp": 1659360932135401500,
      "price": 162.03,
      "sequence_number": 392216,
      "sip_timestamp": 1659360932135422000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9586",
      "participant_timestamp": 1659360932135401500,
      "price": 162.03,
      "sequence_number": 392215,
      "sip_timestamp": 1659360932135419600,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3067",
      "participant_timestamp": 1659360932135062000,
      "price": 162.03,
      "sequence_number": 392214,
      "sip_timestamp": 1659360932135262500,
      "size": 50,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3066",
      "participant_timestamp": 1659360932135062000,
      "price": 162.03,
      "sequence_number": 392213,
      "sip_timestamp": 1659360932135257600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4771",
      "participant_timestamp": 1659360932134542000,
      "price": 162.02,
      "sequence_number": 392212,
      "sip_timestamp": 1659360932134742800,
      "size": 64,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4770",
      "participant_timestamp": 1659360932133790000,
      "price": 162.01,
      "sequence_number": 392211,
      "sip_timestamp": 1659360932134002200,
      "size": 72,
      "tape": 3
    },
    {
      "exchange": 8,
      "id": "4769",
      "participant_timestamp": 1659360932133790000,
      "price": 162.01,
      "sequence_number": 392210,
      "sip_timestamp": 1659360932133997800,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3065",
      "participant_timestamp": 1659360932133682000,
      "price": 162.02,
      "sequence_number": 392209,
      "sip_timestamp": 1659360932133883100,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 19,
      "id": "3064",
      "participant_timestamp": 1659360932133682000,
      "price": 162.02,
      "sequence_number": 392208,
      "sip_timestamp": 1659360932133877500,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 8,
      "id": "4768",
      "participant_timestamp": 1659360932133579000,
      "price": 162.02,
      "sequence_number": 392207,
      "sip_timestamp": 1659360932133820000,
      "size": 64,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9585",
      "participant_timestamp": 1659360932133615600,
      "price": 162.01,
      "sequence_number": 392206,
      "sip_timestamp": 1659360932133633500,
      "size": 52,
      "tape": 3
    },
    {
      "conditions": [
        37
      ],
      "exchange": 12,
      "id": "9584",
      "participant_timestamp": 1659360932133615600,
      "price": 162.01,
      "sequence_number": 392205,
      "sip_timestamp": 1659360932133631700,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8429",
      "participant_timestamp": 1659360932133170000,
      "price": 162.01,
      "sequence_number": 392204,
      "sip_timestamp": 1659360932133520100,
      "size": 131,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8428",
      "participant_timestamp": 1659360932133169200,
      "price": 162.01,
      "sequence_number": 392203,
      "sip_timestamp": 1659360932133515500,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9583",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392202,
      "sip_timestamp": 1659360932133433600,
      "size": 31,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9582",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392201,
      "sip_timestamp": 1659360932133433600,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8427",
      "participant_timestamp": 1659360932133079300,
      "price": 162.01,
      "sequence_number": 392200,
      "sip_timestamp": 1659360932133427000,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9581",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392199,
      "sip_timestamp": 1659360932133426400,
      "size": 95,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9580",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392198,
      "sip_timestamp": 1659360932133424400,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9579",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392197,
      "sip_timestamp": 1659360932133422600,
      "size": 70,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9578",
      "participant_timestamp": 1659360932133402000,
      "price": 162.02,
      "sequence_number": 392196,
      "sip_timestamp": 1659360932133420500,
      "size": 11,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8426",
      "participant_timestamp": 1659360932133000700,
      "price": 162.01,
      "sequence_number": 392195,
      "sip_timestamp": 1659360932133344300,
      "size": 187,
      "tape": 3
    },
    {
      "exchange": 19,
      "id": "3063",
      "participant_timestamp": 1659360932133099000,
      "price": 162,
      "sequence_number": 392194,
      "sip_timestamp": 1659360932133293600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8425",
      "participant_timestamp": 1659360932132923000,
      "price": 162.01,
      "sequence_number": 392193,
      "sip_timestamp": 1659360932133266400,
      "size": 76,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8424",
      "participant_timestamp": 1659360932132893200,
      "price": 162.01,
      "sequence_number": 392192,
      "sip_timestamp": 1659360932133237500,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4767",
      "participant_timestamp": 1659360932132841000,
      "price": 162.01,
      "sequence_number": 392191,
      "sip_timestamp": 1659360932133046800,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4766",
      "participant_timestamp": 1659360932132841000,
      "price": 162.01,
      "sequence_number": 392190,
      "sip_timestamp": 1659360932133044200,
      "size": 165,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4765",
      "participant_timestamp": 1659360932132828000,
      "price": 162.01,
      "sequence_number": 392189,
      "sip_timestamp": 1659360932133034800,
      "size": 187,
      "tape": 3
    },
    {
      "exchange": 4,
      "id": "27149",
      "participant_timestamp": 1659360932131789000,
      "price": 162,
      "sequence_number": 392188,
      "sip_timestamp": 1659360932133017300,
      "size": 1330,
      "tape": 3,
      "trf_id": 201,
      "trf_timestamp": 1659360932132677000
    },
    {
      "exchange": 8,
      "id": "4764",
      "participant_timestamp": 1659360932132796000,
      "price": 162.01,
      "sequence_number": 392187,
      "sip_timestamp": 1659360932132992000,
      "size": 109,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4763",
      "participant_timestamp": 1659360932132754000,
      "price": 162.01,
      "sequence_number": 392186,
      "sip_timestamp": 1659360932132957200,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 8,
      "id": "4762",
      "participant_timestamp": 1659360932132722000,
      "price": 162.01,
      "sequence_number": 392185,
      "sip_timestamp": 1659360932132933000,
      "size": 187,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4761",
      "participant_timestamp": 1659360932132633000,
      "price": 162.01,
      "sequence_number": 392183,
      "sip_timestamp": 1659360932132832300,
      "size": 95,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8423",
      "participant_timestamp": 1659360932132460300,
      "price": 162.01,
      "sequence_number": 392182,
      "sip_timestamp": 1659360932132807400,
      "size": 45,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8422",
      "participant_timestamp": 1659360932132460300,
      "price": 162.01,
      "sequence_number": 392181,
      "sip_timestamp": 1659360932132807200,
      "size": 142,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8421",
      "participant_timestamp": 1659360932132136400,
      "price": 162.01,
      "sequence_number": 392180,
      "sip_timestamp": 1659360932132485000,
      "size": 58,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8420",
      "participant_timestamp": 1659360932132136400,
      "price": 162.01,
      "sequence_number": 392179,
      "sip_timestamp": 1659360932132485000,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 8,
      "id": "4760",
      "participant_timestamp": 1659360932132221000,
      "price": 162.01,
      "sequence_number": 392178,
      "sip_timestamp": 1659360932132417800,
      "size": 70,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9577",
      "participant_timestamp": 1659360932131896600,
      "price": 162.01,
      "sequence_number": 392177,
      "sip_timestamp": 1659360932131923500,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9576",
      "participant_timestamp": 1659360932131896600,
      "price": 162.01,
      "sequence_number": 392176,
      "sip_timestamp": 1659360932131921400,
      "size": 6,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9575",
      "participant_timestamp": 1659360932131896600,
      "price": 162.01,
      "sequence_number": 392175,
      "sip_timestamp": 1659360932131920100,
      "size": 65,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9574",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392171,
      "sip_timestamp": 1659360932131903200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9573",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392170,
      "sip_timestamp": 1659360932131899000,
      "size": 8,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9572",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392169,
      "sip_timestamp": 1659360932131897000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9571",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392168,
      "sip_timestamp": 1659360932131897000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9570",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392167,
      "sip_timestamp": 1659360932131894500,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9569",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392166,
      "sip_timestamp": 1659360932131890400,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9568",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392165,
      "sip_timestamp": 1659360932131886800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9567",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392164,
      "sip_timestamp": 1659360932131886600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9566",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392163,
      "sip_timestamp": 1659360932131886600,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9565",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392162,
      "sip_timestamp": 1659360932131882200,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9564",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392161,
      "sip_timestamp": 1659360932131877000,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9563",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392160,
      "sip_timestamp": 1659360932131875300,
      "size": 20,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9562",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392159,
      "sip_timestamp": 1659360932131875000,
      "size": 1900,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9561",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392158,
      "sip_timestamp": 1659360932131875000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9560",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392157,
      "sip_timestamp": 1659360932131869200,
      "size": 1000,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9559",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392156,
      "sip_timestamp": 1659360932131864800,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9558",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392155,
      "sip_timestamp": 1659360932131864600,
      "size": 40,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9557",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392154,
      "sip_timestamp": 1659360932131864600,
      "size": 3,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9556",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392153,
      "sip_timestamp": 1659360932131861200,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9555",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392152,
      "sip_timestamp": 1659360932131853800,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9554",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392151,
      "sip_timestamp": 1659360932131852000,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9553",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392150,
      "sip_timestamp": 1659360932131852000,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9552",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392149,
      "sip_timestamp": 1659360932131851800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3062",
      "participant_timestamp": 1659360932131645000,
      "price": 162,
      "sequence_number": 392148,
      "sip_timestamp": 1659360932131846100,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9551",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392147,
      "sip_timestamp": 1659360932131844600,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9550",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392146,
      "sip_timestamp": 1659360932131840300,
      "size": 192,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9549",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392145,
      "sip_timestamp": 1659360932131840300,
      "size": 150,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9548",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392144,
      "sip_timestamp": 1659360932131840000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9547",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392143,
      "sip_timestamp": 1659360932131836700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3061",
      "participant_timestamp": 1659360932131637000,
      "price": 162,
      "sequence_number": 392142,
      "sip_timestamp": 1659360932131833000,
      "size": 17,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9546",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392141,
      "sip_timestamp": 1659360932131829000,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9545",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392140,
      "sip_timestamp": 1659360932131829000,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9544",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392139,
      "sip_timestamp": 1659360932131828700,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9543",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392138,
      "sip_timestamp": 1659360932131828700,
      "size": 200,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8419",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392137,
      "sip_timestamp": 1659360932131824600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8418",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392136,
      "sip_timestamp": 1659360932131822600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8417",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392135,
      "sip_timestamp": 1659360932131822600,
      "size": 8,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 19,
      "id": "3060",
      "participant_timestamp": 1659360932131623000,
      "price": 162,
      "sequence_number": 392134,
      "sip_timestamp": 1659360932131818500,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9542",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392133,
      "sip_timestamp": 1659360932131818500,
      "size": 75,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9541",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392132,
      "sip_timestamp": 1659360932131816700,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9540",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392131,
      "sip_timestamp": 1659360932131816400,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9539",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392130,
      "sip_timestamp": 1659360932131816400,
      "size": 20,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8416",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392129,
      "sip_timestamp": 1659360932131816000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8415",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392128,
      "sip_timestamp": 1659360932131816000,
      "size": 60,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8414",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392127,
      "sip_timestamp": 1659360932131812400,
      "size": 1900,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8413",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392126,
      "sip_timestamp": 1659360932131812000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9538",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392125,
      "sip_timestamp": 1659360932131807700,
      "size": 2,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9537",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392124,
      "sip_timestamp": 1659360932131805400,
      "size": 500,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8412",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392123,
      "sip_timestamp": 1659360932131804700,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9536",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392122,
      "sip_timestamp": 1659360932131803600,
      "size": 61,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9535",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392121,
      "sip_timestamp": 1659360932131803600,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3059",
      "participant_timestamp": 1659360932131599000,
      "price": 162,
      "sequence_number": 392120,
      "sip_timestamp": 1659360932131803400,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8411",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392119,
      "sip_timestamp": 1659360932131802600,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8410",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392118,
      "sip_timestamp": 1659360932131802600,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8409",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392117,
      "sip_timestamp": 1659360932131802400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9534",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392116,
      "sip_timestamp": 1659360932131799800,
      "size": 20,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9533",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392115,
      "sip_timestamp": 1659360932131797800,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8408",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392114,
      "sip_timestamp": 1659360932131794400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9532",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392113,
      "sip_timestamp": 1659360932131793400,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9531",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392112,
      "sip_timestamp": 1659360932131793400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8407",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392111,
      "sip_timestamp": 1659360932131792000,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8406",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392110,
      "sip_timestamp": 1659360932131790600,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8405",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392109,
      "sip_timestamp": 1659360932131790300,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9530",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392108,
      "sip_timestamp": 1659360932131789800,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9529",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392107,
      "sip_timestamp": 1659360932131789800,
      "size": 500,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3058",
      "participant_timestamp": 1659360932131599000,
      "price": 162,
      "sequence_number": 392106,
      "sip_timestamp": 1659360932131787800,
      "size": 88,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8404",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392105,
      "sip_timestamp": 1659360932131785700,
      "size": 25,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9528",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392104,
      "sip_timestamp": 1659360932131784400,
      "size": 500,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9527",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392103,
      "sip_timestamp": 1659360932131784200,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8403",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392102,
      "sip_timestamp": 1659360932131782000,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9526",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392101,
      "sip_timestamp": 1659360932131780000,
      "size": 150,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9525",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392100,
      "sip_timestamp": 1659360932131779800,
      "size": 30,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8402",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392099,
      "sip_timestamp": 1659360932131779300,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8401",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392098,
      "sip_timestamp": 1659360932131777300,
      "size": 60,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8400",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392097,
      "sip_timestamp": 1659360932131777300,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9524",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392096,
      "sip_timestamp": 1659360932131774000,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 12,
      "id": "9523",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392095,
      "sip_timestamp": 1659360932131773700,
      "size": 100,
      "tape": 3
    },
    {
      "conditions": [
        14,
        41
      ],
      "exchange": 11,
      "id": "8399",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392094,
      "sip_timestamp": 1659360932131772400,
      "size": 300,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 19,
      "id": "3057",
      "participant_timestamp": 1659360932131581000,
      "price": 162,
      "sequence_number": 392093,
      "sip_timestamp": 1659360932131771600,
      "size": 12,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9522",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392092,
      "sip_timestamp": 1659360932131770600,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9521",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392091,
      "sip_timestamp": 1659360932131770400,
      "size": 1,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8398",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392090,
      "sip_timestamp": 1659360932131770000,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8397",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392089,
      "sip_timestamp": 1659360932131767800,
      "size": 50,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8396",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392088,
      "sip_timestamp": 1659360932131767800,
      "size": 5,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9520",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392086,
      "sip_timestamp": 1659360932131764500,
      "size": 4,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 12,
      "id": "9519",
      "participant_timestamp": 1659360932131216000,
      "price": 162,
      "sequence_number": 392085,
      "sip_timestamp": 1659360932131764200,
      "size": 10,
      "tape": 3
    },
    {
      "conditions": [
        14,
        37,
        41
      ],
      "exchange": 11,
      "id": "8395",
      "participant_timestamp": 1659360932131104800,
      "price": 162,
      "sequence_number": 392084,
      "sip_timestamp": 1659360932131764000,
      "size": 2,
      "tape": 3
    }
  ],
  "status": "OK",
  "request_id": "876855bdded3eb3a559766d5f6353a07",
  "next_url": "https://api.polygon.io/v3/trades/AAPL?cursor=YXA9MzkyMDg0JmFzPSZsaW1pdD0xMDAwJm9yZGVyPWRlc2Mmc29ydD10aW1lc3RhbXAmdGltZXN0YW1wLmx0ZT0yMDIyLTA4LTAxVDEzJTNBMzUlM0EzMi4xMzE3NjM4NDFa"
}
const oneMinuteChartData = {
	ticker: 'AAPL',
	queryCount: 4254,
	resultsCount: 4254,
	adjusted: true,
	results: [
		{
			v: 53664,
			vw: 133.51,
			o: 134.98,
			c: 133.68,
			h: 135.98,
			l: 132.83,
			t: 1655107200000,
			n: 1335
		},
		{
			v: 12516,
			vw: 133.5165,
			o: 133.5,
			c: 133.46,
			h: 133.65,
			l: 133.36,
			t: 1655107260000,
			n: 171
		},
		{
			v: 8142,
			vw: 133.3829,
			o: 133.42,
			c: 133.3,
			h: 133.47,
			l: 133.29,
			t: 1655107320000,
			n: 213
		},
		{
			v: 6223,
			vw: 133.3844,
			o: 133.36,
			c: 133.36,
			h: 133.45,
			l: 133.35,
			t: 1655107380000,
			n: 259
		},
		{
			v: 4411,
			vw: 133.3415,
			o: 133.36,
			c: 133.31,
			h: 133.36,
			l: 133.31,
			t: 1655107440000,
			n: 114
		},
		{
			v: 7415,
			vw: 133.4431,
			o: 133.33,
			c: 133.41,
			h: 133.5,
			l: 133.33,
			t: 1655107500000,
			n: 117
		},
		{
			v: 2924,
			vw: 133.4191,
			o: 133.43,
			c: 133.35,
			h: 133.46,
			l: 133.35,
			t: 1655107560000,
			n: 109
		},
		{
			v: 4509,
			vw: 133.4223,
			o: 133.36,
			c: 133.5,
			h: 133.5,
			l: 133.36,
			t: 1655107620000,
			n: 134
		},
		{
			v: 6722,
			vw: 133.5359,
			o: 133.5,
			c: 133.66,
			h: 133.66,
			l: 133.48,
			t: 1655107680000,
			n: 110
		},
		{
			v: 3595,
			vw: 133.5676,
			o: 133.6,
			c: 133.5,
			h: 133.6,
			l: 133.5,
			t: 1655107740000,
			n: 106
		},
		{
			v: 7319,
			vw: 133.6297,
			o: 133.63,
			c: 133.55,
			h: 133.7,
			l: 133.51,
			t: 1655107800000,
			n: 118
		},
		{
			v: 9638,
			vw: 133.5292,
			o: 133.54,
			c: 133.46,
			h: 133.64,
			l: 133.46,
			t: 1655107860000,
			n: 175
		},
		{
			v: 2578,
			vw: 133.5208,
			o: 133.53,
			c: 133.57,
			h: 133.57,
			l: 133.5,
			t: 1655107920000,
			n: 71
		},
		{
			v: 6543,
			vw: 133.4056,
			o: 133.54,
			c: 133.46,
			h: 133.54,
			l: 133.29,
			t: 1655107980000,
			n: 165
		},
		{
			v: 5048,
			vw: 133.2586,
			o: 133.28,
			c: 133.22,
			h: 133.3,
			l: 133.21,
			t: 1655108040000,
			n: 154
		},
		{
			v: 4425,
			vw: 133.1465,
			o: 133.21,
			c: 133.14,
			h: 133.21,
			l: 133.08,
			t: 1655108100000,
			n: 128
		},
		{
			v: 6196,
			vw: 133.1878,
			o: 133.19,
			c: 133.1,
			h: 133.25,
			l: 133.1,
			t: 1655108160000,
			n: 114
		},
		{
			v: 6486,
			vw: 133.0447,
			o: 133.1,
			c: 133.02,
			h: 133.1,
			l: 133.02,
			t: 1655108220000,
			n: 133
		},
		{
			v: 19339,
			vw: 132.8809,
			o: 133.02,
			c: 132.54,
			h: 133.02,
			l: 132.54,
			t: 1655108280000,
			n: 635
		},
		{
			v: 6830,
			vw: 132.6553,
			o: 132.61,
			c: 132.71,
			h: 132.77,
			l: 132.56,
			t: 1655108340000,
			n: 128
		},
		{
			v: 5433,
			vw: 132.7388,
			o: 132.72,
			c: 132.74,
			h: 132.77,
			l: 132.71,
			t: 1655108400000,
			n: 129
		},
		{
			v: 4955,
			vw: 132.7199,
			o: 132.72,
			c: 132.74,
			h: 132.74,
			l: 132.69,
			t: 1655108460000,
			n: 111
		},
		{
			v: 9819,
			vw: 132.7228,
			o: 132.74,
			c: 132.7,
			h: 132.78,
			l: 132.68,
			t: 1655108520000,
			n: 179
		},
		{
			v: 3980,
			vw: 132.7521,
			o: 132.68,
			c: 132.8,
			h: 132.8,
			l: 132.68,
			t: 1655108580000,
			n: 79
		},
		{
			v: 8198,
			vw: 132.7154,
			o: 132.81,
			c: 132.7,
			h: 132.81,
			l: 132.67,
			t: 1655108640000,
			n: 209
		},
		{
			v: 5502,
			vw: 132.7842,
			o: 132.72,
			c: 132.79,
			h: 132.81,
			l: 132.72,
			t: 1655108700000,
			n: 130
		},
		{
			v: 12479,
			vw: 132.7839,
			o: 132.77,
			c: 132.98,
			h: 132.99,
			l: 132.7,
			t: 1655108760000,
			n: 155
		},
		{
			v: 2695,
			vw: 132.8584,
			o: 132.82,
			c: 132.9,
			h: 132.9,
			l: 132.82,
			t: 1655108820000,
			n: 70
		},
		{
			v: 7785,
			vw: 132.9955,
			o: 132.96,
			c: 133,
			h: 133.01,
			l: 132.96,
			t: 1655108880000,
			n: 80
		},
		{
			v: 3408,
			vw: 132.9446,
			o: 132.96,
			c: 132.91,
			h: 132.98,
			l: 132.9,
			t: 1655108940000,
			n: 94
		},
		{
			v: 2248,
			vw: 132.9348,
			o: 132.91,
			c: 132.93,
			h: 132.97,
			l: 132.9,
			t: 1655109000000,
			n: 75
		},
		{
			v: 10766,
			vw: 132.9932,
			o: 132.96,
			c: 132.94,
			h: 133,
			l: 132.94,
			t: 1655109060000,
			n: 141
		},
		{
			v: 9238,
			vw: 133.0219,
			o: 133,
			c: 133.1,
			h: 133.1,
			l: 133,
			t: 1655109120000,
			n: 128
		},
		{
			v: 1702,
			vw: 133.0836,
			o: 133.08,
			c: 133.1,
			h: 133.1,
			l: 133.08,
			t: 1655109180000,
			n: 45
		},
		{
			v: 3729,
			vw: 133.195,
			o: 133.14,
			c: 133.2,
			h: 133.26,
			l: 133.14,
			t: 1655109240000,
			n: 87
		},
		{
			v: 1642,
			vw: 133.1789,
			o: 133.2,
			c: 133.17,
			h: 133.2,
			l: 133.17,
			t: 1655109300000,
			n: 64
		},
		{
			v: 3000,
			vw: 133.0042,
			o: 133.01,
			c: 132.99,
			h: 133.01,
			l: 132.99,
			t: 1655109420000,
			n: 94
		},
		{
			v: 2160,
			vw: 133.0351,
			o: 133.02,
			c: 133.04,
			h: 133.04,
			l: 133.02,
			t: 1655109480000,
			n: 50
		},
		{
			v: 2299,
			vw: 133.131,
			o: 133.14,
			c: 133.14,
			h: 133.15,
			l: 133.14,
			t: 1655109600000,
			n: 67
		},
		{
			v: 3216,
			vw: 133.1987,
			o: 133.2,
			c: 133.17,
			h: 133.21,
			l: 133.17,
			t: 1655109660000,
			n: 64
		},
		{
			v: 6166,
			vw: 133.162,
			o: 133.15,
			c: 133.16,
			h: 133.27,
			l: 133.06,
			t: 1655109720000,
			n: 111
		},
		{
			v: 1882,
			vw: 133.0563,
			o: 133.05,
			c: 133.05,
			h: 133.05,
			l: 133.04,
			t: 1655109780000,
			n: 67
		},
		{
			v: 1093,
			vw: 133.0803,
			o: 133.08,
			c: 133.08,
			h: 133.08,
			l: 133.08,
			t: 1655109840000,
			n: 32
		},
		{
			v: 1249,
			vw: 133.0758,
			o: 133.15,
			c: 133.03,
			h: 133.15,
			l: 133.03,
			t: 1655109900000,
			n: 39
		},
		{
			v: 528,
			vw: 133.1434,
			o: 133.14,
			c: 133.17,
			h: 133.17,
			l: 133.14,
			t: 1655109960000,
			n: 22
		},
		{
			v: 702,
			vw: 133.1755,
			o: 133.2,
			c: 133.14,
			h: 133.2,
			l: 133.14,
			t: 1655110020000,
			n: 32
		},
		{
			v: 1809,
			vw: 133.1562,
			o: 133.12,
			c: 133.2,
			h: 133.2,
			l: 133.12,
			t: 1655110200000,
			n: 43
		},
		{
			v: 3855,
			vw: 133.0065,
			o: 133.05,
			c: 133,
			h: 133.05,
			l: 133,
			t: 1655110320000,
			n: 182
		},
		{
			v: 1636,
			vw: 132.9268,
			o: 132.93,
			c: 132.93,
			h: 132.93,
			l: 132.93,
			t: 1655110380000,
			n: 103
		},
		{
			v: 1039,
			vw: 132.9257,
			o: 132.9,
			c: 132.94,
			h: 132.94,
			l: 132.9,
			t: 1655110440000,
			n: 44
		},
		{
			v: 17488,
			vw: 132.9937,
			o: 132.89,
			c: 133,
			h: 133,
			l: 132.89,
			t: 1655110500000,
			n: 134
		},
		{
			v: 2011,
			vw: 132.9914,
			o: 132.97,
			c: 132.98,
			h: 133.02,
			l: 132.97,
			t: 1655110560000,
			n: 48
		},
		{
			v: 3985,
			vw: 132.9362,
			o: 132.98,
			c: 132.9,
			h: 132.98,
			l: 132.9,
			t: 1655110620000,
			n: 82
		},
		{
			v: 1356,
			vw: 132.9171,
			o: 132.96,
			c: 132.92,
			h: 132.96,
			l: 132.9,
			t: 1655110680000,
			n: 48
		},
		{
			v: 767,
			vw: 132.8821,
			o: 132.89,
			c: 132.89,
			h: 132.89,
			l: 132.89,
			t: 1655110740000,
			n: 42
		},
		{
			v: 3876,
			vw: 132.8916,
			o: 132.88,
			c: 132.9,
			h: 132.9,
			l: 132.88,
			t: 1655110860000,
			n: 65
		},
		{
			v: 2784,
			vw: 132.9722,
			o: 132.94,
			c: 133,
			h: 133,
			l: 132.94,
			t: 1655110980000,
			n: 87
		},
		{
			v: 11737,
			vw: 133.0423,
			o: 133,
			c: 133,
			h: 133.1,
			l: 133,
			t: 1655111040000,
			n: 107
		},
		{
			v: 7925,
			vw: 133.0248,
			o: 133,
			c: 132.99,
			h: 133.1,
			l: 132.98,
			t: 1655111100000,
			n: 182
		},
		{
			v: 6437,
			vw: 133.0337,
			o: 133.1,
			c: 133,
			h: 133.1,
			l: 133,
			t: 1655111160000,
			n: 62
		},
		{
			v: 3504,
			vw: 133.0053,
			o: 133.01,
			c: 132.98,
			h: 133.03,
			l: 132.98,
			t: 1655111220000,
			n: 92
		},
		{
			v: 2610,
			vw: 132.9031,
			o: 132.95,
			c: 132.81,
			h: 132.95,
			l: 132.81,
			t: 1655111280000,
			n: 116
		},
		{
			v: 1029,
			vw: 132.9425,
			o: 132.91,
			c: 132.97,
			h: 132.97,
			l: 132.91,
			t: 1655111340000,
			n: 38
		},
		{
			v: 2048,
			vw: 132.9962,
			o: 132.99,
			c: 133,
			h: 133,
			l: 132.99,
			t: 1655111400000,
			n: 47
		},
		{
			v: 617,
			vw: 132.9442,
			o: 132.95,
			c: 132.95,
			h: 132.95,
			l: 132.95,
			t: 1655111460000,
			n: 42
		},
		{
			v: 1258,
			vw: 132.9874,
			o: 132.99,
			c: 133,
			h: 133,
			l: 132.99,
			t: 1655111520000,
			n: 31
		},
		{
			v: 311,
			vw: 132.9454,
			o: 132.95,
			c: 132.95,
			h: 132.95,
			l: 132.95,
			t: 1655111580000,
			n: 33
		},
		{
			v: 13100,
			vw: 133.0155,
			o: 132.99,
			c: 133,
			h: 133.1,
			l: 132.99,
			t: 1655111700000,
			n: 182
		},
		{
			v: 4318,
			vw: 133.0475,
			o: 133,
			c: 133.04,
			h: 133.1,
			l: 133,
			t: 1655111760000,
			n: 73
		},
		{
			v: 3692,
			vw: 132.8536,
			o: 132.96,
			c: 132.81,
			h: 132.96,
			l: 132.8,
			t: 1655111820000,
			n: 144
		},
		{
			v: 1513,
			vw: 132.7847,
			o: 132.83,
			c: 132.74,
			h: 132.83,
			l: 132.74,
			t: 1655111880000,
			n: 68
		},
		{
			v: 938,
			vw: 132.9249,
			o: 132.91,
			c: 132.91,
			h: 132.91,
			l: 132.91,
			t: 1655111940000,
			n: 42
		},
		{
			v: 2654,
			vw: 132.9969,
			o: 133,
			c: 133,
			h: 133,
			l: 133,
			t: 1655112000000,
			n: 49
		},
		{
			v: 1916,
			vw: 133.0509,
			o: 133.03,
			c: 133.1,
			h: 133.1,
			l: 133.03,
			t: 1655112060000,
			n: 35
		},
		{
			v: 10662,
			vw: 133.0996,
			o: 133.09,
			c: 133.1,
			h: 133.11,
			l: 133.09,
			t: 1655112120000,
			n: 38
		},
		{
			v: 10736,
			vw: 133.1152,
			o: 133.09,
			c: 133.18,
			h: 133.2,
			l: 133.05,
			t: 1655112180000,
			n: 75
		},
		{
			v: 1197,
			vw: 133.1019,
			o: 133.11,
			c: 133.11,
			h: 133.11,
			l: 133.11,
			t: 1655112240000,
			n: 46
		},
		{
			v: 490,
			vw: 133.1519,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655112360000,
			n: 30
		},
		{
			v: 3916,
			vw: 133.0258,
			o: 133.05,
			c: 133.12,
			h: 133.12,
			l: 133,
			t: 1655112420000,
			n: 107
		},
		{
			v: 1978,
			vw: 132.9876,
			o: 133,
			c: 133,
			h: 133,
			l: 132.97,
			t: 1655112480000,
			n: 71
		},
		{
			v: 2992,
			vw: 132.9794,
			o: 133.03,
			c: 132.86,
			h: 133.04,
			l: 132.86,
			t: 1655112540000,
			n: 79
		},
		{
			v: 1657,
			vw: 132.9275,
			o: 132.87,
			c: 133.03,
			h: 133.03,
			l: 132.87,
			t: 1655112600000,
			n: 57
		},
		{
			v: 2068,
			vw: 132.9781,
			o: 132.98,
			c: 132.97,
			h: 132.98,
			l: 132.97,
			t: 1655112660000,
			n: 34
		},
		{
			v: 2389,
			vw: 132.9269,
			o: 132.98,
			c: 132.9,
			h: 132.99,
			l: 132.9,
			t: 1655112720000,
			n: 51
		},
		{
			v: 2542,
			vw: 132.8831,
			o: 132.9,
			c: 132.87,
			h: 132.9,
			l: 132.87,
			t: 1655112780000,
			n: 54
		},
		{
			v: 4938,
			vw: 132.96,
			o: 132.95,
			c: 132.94,
			h: 133,
			l: 132.92,
			t: 1655112840000,
			n: 74
		},
		{
			v: 4530,
			vw: 132.8752,
			o: 132.99,
			c: 132.74,
			h: 132.99,
			l: 132.74,
			t: 1655112900000,
			n: 132
		},
		{
			v: 1511,
			vw: 132.859,
			o: 132.87,
			c: 132.86,
			h: 132.87,
			l: 132.86,
			t: 1655112960000,
			n: 32
		},
		{
			v: 729,
			vw: 132.837,
			o: 132.84,
			c: 132.84,
			h: 132.84,
			l: 132.84,
			t: 1655113020000,
			n: 42
		},
		{
			v: 1150,
			vw: 132.829,
			o: 132.83,
			c: 132.83,
			h: 132.83,
			l: 132.83,
			t: 1655113080000,
			n: 46
		},
		{
			v: 944,
			vw: 132.8176,
			o: 132.83,
			c: 132.82,
			h: 132.83,
			l: 132.82,
			t: 1655113140000,
			n: 40
		},
		{
			v: 793,
			vw: 132.8647,
			o: 132.86,
			c: 132.86,
			h: 132.86,
			l: 132.86,
			t: 1655113200000,
			n: 33
		},
		{
			v: 1331,
			vw: 132.873,
			o: 132.85,
			c: 132.84,
			h: 132.85,
			l: 132.84,
			t: 1655113260000,
			n: 46
		},
		{
			v: 5298,
			vw: 132.7536,
			o: 132.84,
			c: 132.73,
			h: 132.84,
			l: 132.71,
			t: 1655113320000,
			n: 157
		},
		{
			v: 2852,
			vw: 132.623,
			o: 132.61,
			c: 132.68,
			h: 132.68,
			l: 132.56,
			t: 1655113380000,
			n: 121
		},
		{
			v: 1572,
			vw: 132.8051,
			o: 132.79,
			c: 132.85,
			h: 132.85,
			l: 132.79,
			t: 1655113500000,
			n: 52
		},
		{
			v: 1263,
			vw: 132.7983,
			o: 132.8,
			c: 132.8,
			h: 132.8,
			l: 132.8,
			t: 1655113560000,
			n: 30
		},
		{
			v: 5628,
			vw: 132.7996,
			o: 132.79,
			c: 132.8,
			h: 132.8,
			l: 132.79,
			t: 1655113620000,
			n: 70
		},
		{
			v: 317,
			vw: 132.8364,
			o: 132.82,
			c: 132.82,
			h: 132.82,
			l: 132.82,
			t: 1655113680000,
			n: 17
		},
		{
			v: 1240,
			vw: 132.8872,
			o: 132.85,
			c: 132.91,
			h: 132.91,
			l: 132.85,
			t: 1655113740000,
			n: 38
		},
		{
			v: 1146,
			vw: 132.9087,
			o: 132.94,
			c: 132.89,
			h: 132.94,
			l: 132.89,
			t: 1655113800000,
			n: 36
		},
		{
			v: 1614,
			vw: 132.8767,
			o: 132.93,
			c: 132.87,
			h: 132.93,
			l: 132.87,
			t: 1655113860000,
			n: 58
		},
		{
			v: 787,
			vw: 132.7767,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655113980000,
			n: 81
		},
		{
			v: 2512,
			vw: 132.7334,
			o: 132.73,
			c: 132.75,
			h: 132.75,
			l: 132.69,
			t: 1655114040000,
			n: 71
		},
		{
			v: 674,
			vw: 132.8492,
			o: 132.86,
			c: 132.86,
			h: 132.86,
			l: 132.86,
			t: 1655114100000,
			n: 22
		},
		{
			v: 2419,
			vw: 132.909,
			o: 132.91,
			c: 132.94,
			h: 132.95,
			l: 132.8,
			t: 1655114160000,
			n: 61
		},
		{
			v: 1187,
			vw: 133.0144,
			o: 133,
			c: 133,
			h: 133,
			l: 133,
			t: 1655114220000,
			n: 48
		},
		{
			v: 2346,
			vw: 133.0797,
			o: 133.09,
			c: 133.07,
			h: 133.1,
			l: 133.07,
			t: 1655114280000,
			n: 47
		},
		{
			v: 436,
			vw: 133.0582,
			o: 133.07,
			c: 133.07,
			h: 133.07,
			l: 133.07,
			t: 1655114340000,
			n: 15
		},
		{
			v: 5508,
			vw: 133.0738,
			o: 133.02,
			c: 133.19,
			h: 133.19,
			l: 133.02,
			t: 1655114400000,
			n: 123
		},
		{
			v: 608,
			vw: 133.1371,
			o: 133.14,
			c: 133.14,
			h: 133.14,
			l: 133.14,
			t: 1655114460000,
			n: 25
		},
		{
			v: 880,
			vw: 133.1061,
			o: 133.14,
			c: 133.1,
			h: 133.14,
			l: 133.07,
			t: 1655114520000,
			n: 35
		},
		{
			v: 1669,
			vw: 133.2001,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655114760000,
			n: 46
		},
		{
			v: 1217,
			vw: 133.2609,
			o: 133.21,
			c: 133.3,
			h: 133.3,
			l: 133.21,
			t: 1655114820000,
			n: 34
		},
		{
			v: 394,
			vw: 133.2633,
			o: 133.25,
			c: 133.25,
			h: 133.25,
			l: 133.25,
			t: 1655114940000,
			n: 20
		},
		{
			v: 1206,
			vw: 133.3527,
			o: 133.3,
			c: 133.38,
			h: 133.44,
			l: 133.3,
			t: 1655115000000,
			n: 51
		},
		{
			v: 826,
			vw: 133.338,
			o: 133.34,
			c: 133.35,
			h: 133.35,
			l: 133.34,
			t: 1655115060000,
			n: 27
		},
		{
			v: 834,
			vw: 133.3183,
			o: 133.32,
			c: 133.32,
			h: 133.32,
			l: 133.32,
			t: 1655115120000,
			n: 29
		},
		{
			v: 1756,
			vw: 133.3618,
			o: 133.39,
			c: 133.38,
			h: 133.39,
			l: 133.38,
			t: 1655115180000,
			n: 60
		},
		{
			v: 3823,
			vw: 133.4358,
			o: 133.38,
			c: 133.5,
			h: 133.5,
			l: 133.38,
			t: 1655115240000,
			n: 70
		},
		{
			v: 2037,
			vw: 133.3428,
			o: 133.37,
			c: 133.31,
			h: 133.37,
			l: 133.31,
			t: 1655115420000,
			n: 59
		},
		{
			v: 1391,
			vw: 133.2716,
			o: 133.3,
			c: 133.23,
			h: 133.3,
			l: 133.23,
			t: 1655115480000,
			n: 53
		},
		{
			v: 4562,
			vw: 133.2034,
			o: 133.23,
			c: 133.2,
			h: 133.23,
			l: 133.2,
			t: 1655115540000,
			n: 116
		},
		{
			v: 403,
			vw: 133.1547,
			o: 133.15,
			c: 133.15,
			h: 133.15,
			l: 133.15,
			t: 1655115600000,
			n: 22
		},
		{
			v: 1639,
			vw: 133.1732,
			o: 133.2,
			c: 133.15,
			h: 133.2,
			l: 133.15,
			t: 1655115660000,
			n: 43
		},
		{
			v: 2246,
			vw: 133.1436,
			o: 133.15,
			c: 133.15,
			h: 133.16,
			l: 133.12,
			t: 1655115720000,
			n: 39
		},
		{
			v: 1250,
			vw: 133.052,
			o: 133.08,
			c: 133.01,
			h: 133.08,
			l: 133.01,
			t: 1655115900000,
			n: 44
		},
		{
			v: 5403,
			vw: 133.1208,
			o: 133.13,
			c: 133.13,
			h: 133.13,
			l: 133.05,
			t: 1655116020000,
			n: 54
		},
		{
			v: 367,
			vw: 133.2032,
			o: 133.21,
			c: 133.21,
			h: 133.21,
			l: 133.21,
			t: 1655116080000,
			n: 19
		},
		{
			v: 4926,
			vw: 133.0691,
			o: 133.05,
			c: 133.07,
			h: 133.07,
			l: 133.05,
			t: 1655116140000,
			n: 66
		},
		{
			v: 4134,
			vw: 132.9984,
			o: 133.07,
			c: 132.98,
			h: 133.07,
			l: 132.92,
			t: 1655116200000,
			n: 199
		},
		{
			v: 8050,
			vw: 133.0505,
			o: 133,
			c: 133.24,
			h: 133.24,
			l: 133,
			t: 1655116260000,
			n: 93
		},
		{
			v: 3340,
			vw: 133.231,
			o: 133.25,
			c: 133.25,
			h: 133.25,
			l: 133.2,
			t: 1655116320000,
			n: 39
		},
		{
			v: 6388,
			vw: 133.4279,
			o: 133.36,
			c: 133.5,
			h: 133.5,
			l: 133.36,
			t: 1655116380000,
			n: 84
		},
		{
			v: 2088,
			vw: 133.5508,
			o: 133.55,
			c: 133.56,
			h: 133.56,
			l: 133.52,
			t: 1655116440000,
			n: 65
		},
		{
			v: 664,
			vw: 133.5162,
			o: 133.51,
			c: 133.51,
			h: 133.51,
			l: 133.51,
			t: 1655116500000,
			n: 24
		},
		{
			v: 5086,
			vw: 133.4916,
			o: 133.51,
			c: 133.55,
			h: 133.55,
			l: 133.39,
			t: 1655116560000,
			n: 102
		},
		{
			v: 637,
			vw: 133.4801,
			o: 133.47,
			c: 133.47,
			h: 133.47,
			l: 133.47,
			t: 1655116680000,
			n: 29
		},
		{
			v: 241,
			vw: 133.3846,
			o: 133.39,
			c: 133.39,
			h: 133.39,
			l: 133.39,
			t: 1655116800000,
			n: 12
		},
		{
			v: 5342,
			vw: 133.415,
			o: 133.39,
			c: 133.49,
			h: 133.49,
			l: 133.38,
			t: 1655116860000,
			n: 82
		},
		{
			v: 705,
			vw: 133.4538,
			o: 133.48,
			c: 133.48,
			h: 133.48,
			l: 133.48,
			t: 1655116920000,
			n: 31
		},
		{
			v: 1561,
			vw: 133.5989,
			o: 133.59,
			c: 133.6,
			h: 133.6,
			l: 133.59,
			t: 1655117040000,
			n: 32
		},
		{
			v: 838,
			vw: 133.5918,
			o: 133.6,
			c: 133.57,
			h: 133.6,
			l: 133.57,
			t: 1655117100000,
			n: 26
		},
		{
			v: 1275,
			vw: 133.4078,
			o: 133.38,
			c: 133.38,
			h: 133.38,
			l: 133.38,
			t: 1655117220000,
			n: 46
		},
		{
			v: 914,
			vw: 133.3155,
			o: 133.3,
			c: 133.3,
			h: 133.3,
			l: 133.3,
			t: 1655117280000,
			n: 63
		},
		{
			v: 458,
			vw: 133.2681,
			o: 133.28,
			c: 133.28,
			h: 133.28,
			l: 133.28,
			t: 1655117520000,
			n: 32
		},
		{
			v: 2662,
			vw: 133.287,
			o: 133.3,
			c: 133.42,
			h: 133.42,
			l: 133.25,
			t: 1655117580000,
			n: 63
		},
		{
			v: 907,
			vw: 133.482,
			o: 133.43,
			c: 133.53,
			h: 133.53,
			l: 133.43,
			t: 1655117640000,
			n: 21
		},
		{
			v: 1144,
			vw: 133.4789,
			o: 133.48,
			c: 133.48,
			h: 133.49,
			l: 133.48,
			t: 1655117700000,
			n: 27
		},
		{
			v: 455,
			vw: 133.4048,
			o: 133.38,
			c: 133.38,
			h: 133.38,
			l: 133.38,
			t: 1655117760000,
			n: 31
		},
		{
			v: 1923,
			vw: 133.3674,
			o: 133.38,
			c: 133.35,
			h: 133.38,
			l: 133.35,
			t: 1655117820000,
			n: 38
		},
		{
			v: 4193,
			vw: 133.4471,
			o: 133.43,
			c: 133.46,
			h: 133.46,
			l: 133.43,
			t: 1655117880000,
			n: 50
		},
		{
			v: 711,
			vw: 133.4509,
			o: 133.48,
			c: 133.48,
			h: 133.48,
			l: 133.48,
			t: 1655117940000,
			n: 34
		},
		{
			v: 37229,
			vw: 133.6233,
			o: 133.48,
			c: 133.78,
			h: 133.8,
			l: 133.41,
			t: 1655118000000,
			n: 1438
		},
		{
			v: 8513,
			vw: 133.6918,
			o: 133.8,
			c: 133.6,
			h: 133.8,
			l: 133.56,
			t: 1655118060000,
			n: 167
		},
		{
			v: 2684,
			vw: 133.6017,
			o: 133.64,
			c: 133.6,
			h: 133.64,
			l: 133.6,
			t: 1655118120000,
			n: 57
		},
		{
			v: 6672,
			vw: 133.6369,
			o: 133.6,
			c: 133.73,
			h: 133.73,
			l: 133.57,
			t: 1655118180000,
			n: 133
		},
		{
			v: 3211,
			vw: 133.7095,
			o: 133.72,
			c: 133.67,
			h: 133.74,
			l: 133.67,
			t: 1655118240000,
			n: 94
		},
		{
			v: 3437,
			vw: 133.6584,
			o: 133.6,
			c: 133.69,
			h: 133.69,
			l: 133.6,
			t: 1655118300000,
			n: 52
		},
		{
			v: 10916,
			vw: 133.5213,
			o: 133.7,
			c: 133.4,
			h: 133.7,
			l: 133.4,
			t: 1655118360000,
			n: 179
		},
		{
			v: 7648,
			vw: 133.4186,
			o: 133.4,
			c: 133.4,
			h: 133.49,
			l: 133.4,
			t: 1655118420000,
			n: 112
		},
		{
			v: 1910,
			vw: 133.3618,
			o: 133.41,
			c: 133.31,
			h: 133.41,
			l: 133.31,
			t: 1655118480000,
			n: 69
		},
		{
			v: 3036,
			vw: 133.3226,
			o: 133.38,
			c: 133.26,
			h: 133.38,
			l: 133.26,
			t: 1655118540000,
			n: 115
		},
		{
			v: 3806,
			vw: 133.2229,
			o: 133.25,
			c: 133.19,
			h: 133.25,
			l: 133.19,
			t: 1655118600000,
			n: 106
		},
		{
			v: 12237,
			vw: 133.1496,
			o: 133.19,
			c: 133.1,
			h: 133.21,
			l: 133.03,
			t: 1655118660000,
			n: 185
		},
		{
			v: 13107,
			vw: 133.1021,
			o: 133.13,
			c: 133.1,
			h: 133.14,
			l: 133.09,
			t: 1655118720000,
			n: 87
		},
		{
			v: 32487,
			vw: 133.002,
			o: 133.13,
			c: 132.99,
			h: 133.13,
			l: 132.98,
			t: 1655118780000,
			n: 568
		},
		{
			v: 6163,
			vw: 132.9776,
			o: 132.99,
			c: 132.98,
			h: 132.99,
			l: 132.92,
			t: 1655118840000,
			n: 107
		},
		{
			v: 30703,
			vw: 133.0654,
			o: 132.98,
			c: 133.23,
			h: 133.23,
			l: 132.96,
			t: 1655118900000,
			n: 278
		},
		{
			v: 3723,
			vw: 133.194,
			o: 133.23,
			c: 133.15,
			h: 133.23,
			l: 133.1,
			t: 1655118960000,
			n: 52
		},
		{
			v: 5679,
			vw: 133.1599,
			o: 133.18,
			c: 133.09,
			h: 133.23,
			l: 133.08,
			t: 1655119020000,
			n: 96
		},
		{
			v: 2744,
			vw: 133.069,
			o: 133.09,
			c: 133.08,
			h: 133.09,
			l: 133.03,
			t: 1655119080000,
			n: 57
		},
		{
			v: 5715,
			vw: 132.986,
			o: 133.02,
			c: 132.94,
			h: 133.07,
			l: 132.92,
			t: 1655119140000,
			n: 109
		},
		{
			v: 9382,
			vw: 132.9347,
			o: 132.94,
			c: 132.91,
			h: 133,
			l: 132.89,
			t: 1655119200000,
			n: 163
		},
		{
			v: 2883,
			vw: 132.867,
			o: 132.9,
			c: 132.83,
			h: 132.9,
			l: 132.82,
			t: 1655119260000,
			n: 121
		},
		{
			v: 6105,
			vw: 132.8362,
			o: 132.81,
			c: 132.9,
			h: 132.9,
			l: 132.81,
			t: 1655119320000,
			n: 124
		},
		{
			v: 12390,
			vw: 132.8166,
			o: 132.86,
			c: 132.83,
			h: 132.87,
			l: 132.76,
			t: 1655119380000,
			n: 211
		},
		{
			v: 9166,
			vw: 132.8328,
			o: 132.83,
			c: 132.88,
			h: 132.88,
			l: 132.78,
			t: 1655119440000,
			n: 142
		},
		{
			v: 3932,
			vw: 132.8358,
			o: 132.84,
			c: 132.82,
			h: 132.88,
			l: 132.82,
			t: 1655119500000,
			n: 95
		},
		{
			v: 5214,
			vw: 132.8345,
			o: 132.83,
			c: 132.85,
			h: 132.85,
			l: 132.81,
			t: 1655119560000,
			n: 102
		},
		{
			v: 3219,
			vw: 132.8723,
			o: 132.9,
			c: 132.88,
			h: 132.9,
			l: 132.85,
			t: 1655119620000,
			n: 63
		},
		{
			v: 13122,
			vw: 132.9766,
			o: 132.87,
			c: 133.15,
			h: 133.15,
			l: 132.87,
			t: 1655119680000,
			n: 137
		},
		{
			v: 9125,
			vw: 133.1874,
			o: 133.21,
			c: 133.17,
			h: 133.24,
			l: 133.17,
			t: 1655119740000,
			n: 88
		},
		{
			v: 4132,
			vw: 133.1614,
			o: 133.17,
			c: 133.21,
			h: 133.21,
			l: 133.13,
			t: 1655119800000,
			n: 135
		},
		{
			v: 2229,
			vw: 133.2314,
			o: 133.23,
			c: 133.23,
			h: 133.25,
			l: 133.23,
			t: 1655119860000,
			n: 54
		},
		{
			v: 3075,
			vw: 133.1898,
			o: 133.21,
			c: 133.13,
			h: 133.21,
			l: 133.13,
			t: 1655119920000,
			n: 36
		},
		{
			v: 4467,
			vw: 133.1419,
			o: 133.2,
			c: 133.13,
			h: 133.2,
			l: 133.13,
			t: 1655119980000,
			n: 73
		},
		{
			v: 3208,
			vw: 133.039,
			o: 133.09,
			c: 133,
			h: 133.1,
			l: 133,
			t: 1655120040000,
			n: 94
		},
		{
			v: 6542,
			vw: 133.0034,
			o: 133,
			c: 133.1,
			h: 133.1,
			l: 132.92,
			t: 1655120100000,
			n: 97
		},
		{
			v: 8586,
			vw: 133.052,
			o: 133.03,
			c: 132.99,
			h: 133.09,
			l: 132.96,
			t: 1655120160000,
			n: 66
		},
		{
			v: 10193,
			vw: 133.1277,
			o: 133.05,
			c: 133.2,
			h: 133.24,
			l: 133.05,
			t: 1655120220000,
			n: 114
		},
		{
			v: 2499,
			vw: 133.1151,
			o: 133.2,
			c: 133.1,
			h: 133.2,
			l: 133.1,
			t: 1655120280000,
			n: 57
		},
		{
			v: 2096,
			vw: 133.1941,
			o: 133.2,
			c: 133.21,
			h: 133.21,
			l: 133.2,
			t: 1655120340000,
			n: 52
		},
		{
			v: 1754,
			vw: 133.11,
			o: 133.12,
			c: 133.1,
			h: 133.12,
			l: 133.1,
			t: 1655120400000,
			n: 57
		},
		{
			v: 3764,
			vw: 133.0614,
			o: 133.1,
			c: 133.01,
			h: 133.12,
			l: 133.01,
			t: 1655120460000,
			n: 115
		},
		{
			v: 2953,
			vw: 132.9677,
			o: 132.97,
			c: 132.95,
			h: 132.98,
			l: 132.95,
			t: 1655120520000,
			n: 62
		},
		{
			v: 7638,
			vw: 132.8824,
			o: 132.9,
			c: 132.91,
			h: 132.91,
			l: 132.85,
			t: 1655120580000,
			n: 175
		},
		{
			v: 8734,
			vw: 132.8572,
			o: 132.9,
			c: 132.85,
			h: 132.9,
			l: 132.85,
			t: 1655120640000,
			n: 195
		},
		{
			v: 3833,
			vw: 132.9144,
			o: 132.86,
			c: 132.94,
			h: 132.94,
			l: 132.86,
			t: 1655120700000,
			n: 87
		},
		{
			v: 12529,
			vw: 132.8699,
			o: 132.92,
			c: 132.82,
			h: 132.92,
			l: 132.81,
			t: 1655120760000,
			n: 158
		},
		{
			v: 13643,
			vw: 132.8341,
			o: 132.84,
			c: 132.8,
			h: 132.85,
			l: 132.8,
			t: 1655120820000,
			n: 142
		},
		{
			v: 39999,
			vw: 132.7893,
			o: 132.82,
			c: 132.75,
			h: 132.82,
			l: 132.75,
			t: 1655120880000,
			n: 264
		},
		{
			v: 6305,
			vw: 132.708,
			o: 132.75,
			c: 132.65,
			h: 132.75,
			l: 132.65,
			t: 1655120940000,
			n: 219
		},
		{
			v: 11312,
			vw: 132.7665,
			o: 132.71,
			c: 132.84,
			h: 132.85,
			l: 132.68,
			t: 1655121000000,
			n: 159
		},
		{
			v: 13210,
			vw: 132.8236,
			o: 132.82,
			c: 132.82,
			h: 132.85,
			l: 132.77,
			t: 1655121060000,
			n: 192
		},
		{
			v: 11322,
			vw: 132.6502,
			o: 132.78,
			c: 132.58,
			h: 132.78,
			l: 132.58,
			t: 1655121120000,
			n: 333
		},
		{
			v: 5773,
			vw: 132.5677,
			o: 132.55,
			c: 132.6,
			h: 132.6,
			l: 132.53,
			t: 1655121180000,
			n: 154
		},
		{
			v: 5862,
			vw: 132.6934,
			o: 132.64,
			c: 132.72,
			h: 132.75,
			l: 132.64,
			t: 1655121240000,
			n: 110
		},
		{
			v: 4478,
			vw: 132.7802,
			o: 132.75,
			c: 132.79,
			h: 132.8,
			l: 132.75,
			t: 1655121300000,
			n: 104
		},
		{
			v: 9841,
			vw: 132.6562,
			o: 132.72,
			c: 132.65,
			h: 132.72,
			l: 132.64,
			t: 1655121360000,
			n: 104
		},
		{
			v: 10297,
			vw: 132.6167,
			o: 132.65,
			c: 132.6,
			h: 132.65,
			l: 132.6,
			t: 1655121420000,
			n: 97
		},
		{
			v: 8598,
			vw: 132.6056,
			o: 132.56,
			c: 132.68,
			h: 132.68,
			l: 132.55,
			t: 1655121480000,
			n: 125
		},
		{
			v: 11630,
			vw: 132.7264,
			o: 132.71,
			c: 132.69,
			h: 132.76,
			l: 132.69,
			t: 1655121540000,
			n: 134
		},
		{
			v: 407432,
			vw: 133.1011,
			o: 133.69,
			c: 132.83,
			h: 133.8,
			l: 132.54,
			t: 1655121600000,
			n: 3652
		},
		{
			v: 12721,
			vw: 133.1096,
			o: 132.83,
			c: 132.87,
			h: 134.5,
			l: 132.83,
			t: 1655121660000,
			n: 929
		},
		{
			v: 27428,
			vw: 132.9748,
			o: 132.94,
			c: 132.94,
			h: 133.004,
			l: 132.91,
			t: 1655121720000,
			n: 273
		},
		{
			v: 14163,
			vw: 133.0575,
			o: 132.99,
			c: 133.0495,
			h: 133.13,
			l: 132.9502,
			t: 1655121780000,
			n: 228
		},
		{
			v: 13330,
			vw: 132.9679,
			o: 133.05,
			c: 132.93,
			h: 133.05,
			l: 132.92,
			t: 1655121840000,
			n: 158
		},
		{
			v: 12514,
			vw: 132.9273,
			o: 132.9301,
			c: 132.94,
			h: 132.95,
			l: 132.9,
			t: 1655121900000,
			n: 182
		},
		{
			v: 8463,
			vw: 132.8733,
			o: 132.94,
			c: 132.85,
			h: 132.94,
			l: 132.81,
			t: 1655121960000,
			n: 228
		},
		{
			v: 17530,
			vw: 132.9603,
			o: 132.86,
			c: 132.99,
			h: 132.99,
			l: 132.86,
			t: 1655122020000,
			n: 160
		},
		{
			v: 11591,
			vw: 133.0456,
			o: 132.94,
			c: 133.08,
			h: 133.11,
			l: 132.94,
			t: 1655122080000,
			n: 194
		},
		{
			v: 5341,
			vw: 133.0399,
			o: 133.04,
			c: 133,
			h: 133.07,
			l: 133,
			t: 1655122140000,
			n: 140
		},
		{
			v: 30582,
			vw: 133.1509,
			o: 133,
			c: 133.28,
			h: 133.33,
			l: 132.98,
			t: 1655122200000,
			n: 262
		},
		{
			v: 10074,
			vw: 133.2278,
			o: 133.25,
			c: 133.19,
			h: 133.25,
			l: 133.19,
			t: 1655122260000,
			n: 156
		},
		{
			v: 6476,
			vw: 133.2571,
			o: 133.21,
			c: 133.22,
			h: 133.3,
			l: 133.21,
			t: 1655122320000,
			n: 161
		},
		{
			v: 11456,
			vw: 133.185,
			o: 133.22,
			c: 133.13,
			h: 133.25,
			l: 133.13,
			t: 1655122380000,
			n: 163
		},
		{
			v: 19737,
			vw: 133.2061,
			o: 133.1501,
			c: 133.25,
			h: 133.25,
			l: 133.1501,
			t: 1655122440000,
			n: 114
		},
		{
			v: 6352,
			vw: 133.2289,
			o: 133.2201,
			c: 133.27,
			h: 133.27,
			l: 133.16,
			t: 1655122500000,
			n: 165
		},
		{
			v: 16441,
			vw: 133.2885,
			o: 133.27,
			c: 133.17,
			h: 133.4,
			l: 133.17,
			t: 1655122560000,
			n: 269
		},
		{
			v: 18165,
			vw: 133.1426,
			o: 133.19,
			c: 133.09,
			h: 133.21,
			l: 133.09,
			t: 1655122620000,
			n: 231
		},
		{
			v: 4754,
			vw: 133.1392,
			o: 133.09,
			c: 133.1,
			h: 133.28,
			l: 133.09,
			t: 1655122680000,
			n: 113
		},
		{
			v: 21943,
			vw: 133.0661,
			o: 133.1,
			c: 133.18,
			h: 133.18,
			l: 133,
			t: 1655122740000,
			n: 225
		},
		{
			v: 11212,
			vw: 133.2403,
			o: 133.22,
			c: 133.21,
			h: 133.3,
			l: 133.21,
			t: 1655122800000,
			n: 117
		},
		{
			v: 8389,
			vw: 133.2568,
			o: 133.25,
			c: 133.25,
			h: 133.28,
			l: 133.23,
			t: 1655122860000,
			n: 118
		},
		{
			v: 8913,
			vw: 133.27,
			o: 133.28,
			c: 133.28,
			h: 133.28,
			l: 133.2,
			t: 1655122920000,
			n: 103
		},
		{
			v: 27553,
			vw: 133.2256,
			o: 133.2,
			c: 133.2,
			h: 133.3,
			l: 133.2,
			t: 1655122980000,
			n: 250
		},
		{
			v: 14876,
			vw: 133.2164,
			o: 133.22,
			c: 133.21,
			h: 133.22,
			l: 133.18,
			t: 1655123040000,
			n: 145
		},
		{
			v: 19117,
			vw: 133.291,
			o: 133.26,
			c: 133.28,
			h: 133.32,
			l: 133.24,
			t: 1655123100000,
			n: 131
		},
		{
			v: 3797,
			vw: 133.2117,
			o: 133.24,
			c: 133.15,
			h: 133.24,
			l: 133.15,
			t: 1655123160000,
			n: 136
		},
		{
			v: 3560,
			vw: 133.1955,
			o: 133.19,
			c: 133.19,
			h: 133.21,
			l: 133.16,
			t: 1655123220000,
			n: 100
		},
		{
			v: 3582,
			vw: 133.2939,
			o: 133.25,
			c: 133.24,
			h: 133.35,
			l: 133.22,
			t: 1655123280000,
			n: 107
		},
		{
			v: 8424,
			vw: 133.2433,
			o: 133.25,
			c: 133.29,
			h: 133.29,
			l: 133.2,
			t: 1655123340000,
			n: 137
		},
		{
			v: 4877,
			vw: 133.3064,
			o: 133.2312,
			c: 133.33,
			h: 133.33,
			l: 133.2312,
			t: 1655123400000,
			n: 133
		},
		{
			v: 3311,
			vw: 133.2894,
			o: 133.3,
			c: 133.29,
			h: 133.3,
			l: 133.28,
			t: 1655123460000,
			n: 101
		},
		{
			v: 30577,
			vw: 133.2847,
			o: 133.3,
			c: 133.33,
			h: 133.35,
			l: 133.12,
			t: 1655123520000,
			n: 392
		},
		{
			v: 17524,
			vw: 133.4165,
			o: 133.35,
			c: 133.34,
			h: 133.48,
			l: 133.34,
			t: 1655123580000,
			n: 178
		},
		{
			v: 9019,
			vw: 133.2072,
			o: 133.27,
			c: 133.1,
			h: 133.3,
			l: 133.1,
			t: 1655123640000,
			n: 192
		},
		{
			v: 12535,
			vw: 133.1012,
			o: 133.12,
			c: 133.08,
			h: 133.12,
			l: 133.07,
			t: 1655123700000,
			n: 160
		},
		{
			v: 27755,
			vw: 133.0757,
			o: 133.08,
			c: 133,
			h: 133.14,
			l: 133,
			t: 1655123760000,
			n: 406
		},
		{
			v: 16853,
			vw: 132.9557,
			o: 133.01,
			c: 133.04,
			h: 133.04,
			l: 132.83,
			t: 1655123820000,
			n: 470
		},
		{
			v: 22745,
			vw: 133.0789,
			o: 133,
			c: 133.13,
			h: 133.19,
			l: 132.98,
			t: 1655123880000,
			n: 329
		},
		{
			v: 13703,
			vw: 133.0911,
			o: 133.1,
			c: 133.19,
			h: 133.19,
			l: 132.99,
			t: 1655123940000,
			n: 225
		},
		{
			v: 9077,
			vw: 133.2661,
			o: 133.22,
			c: 133.29,
			h: 133.33,
			l: 133.21,
			t: 1655124000000,
			n: 214
		},
		{
			v: 5786,
			vw: 133.1873,
			o: 133.22,
			c: 133.13,
			h: 133.22,
			l: 133.13,
			t: 1655124060000,
			n: 121
		},
		{
			v: 5814,
			vw: 133.1134,
			o: 133.21,
			c: 133.1,
			h: 133.21,
			l: 133.07,
			t: 1655124120000,
			n: 130
		},
		{
			v: 7000,
			vw: 133.094,
			o: 133.11,
			c: 133.07,
			h: 133.15,
			l: 133.07,
			t: 1655124180000,
			n: 136
		},
		{
			v: 7927,
			vw: 133.0872,
			o: 133.06,
			c: 133.1,
			h: 133.1,
			l: 133.05,
			t: 1655124240000,
			n: 98
		},
		{
			v: 17390,
			vw: 133.0579,
			o: 133.1,
			c: 133.27,
			h: 133.28,
			l: 133,
			t: 1655124300000,
			n: 376
		},
		{
			v: 11052,
			vw: 133.2082,
			o: 133.26,
			c: 133.16,
			h: 133.28,
			l: 133.16,
			t: 1655124360000,
			n: 176
		},
		{
			v: 9240,
			vw: 133.1288,
			o: 133.19,
			c: 133.15,
			h: 133.19,
			l: 133.02,
			t: 1655124420000,
			n: 168
		},
		{
			v: 3342,
			vw: 133.143,
			o: 133.15,
			c: 133.1101,
			h: 133.19,
			l: 133.1101,
			t: 1655124480000,
			n: 111
		},
		{
			v: 6451,
			vw: 133.0554,
			o: 133.12,
			c: 133.04,
			h: 133.12,
			l: 133,
			t: 1655124540000,
			n: 183
		},
		{
			v: 18448,
			vw: 133.1151,
			o: 133.09,
			c: 133.2,
			h: 133.2,
			l: 133.09,
			t: 1655124600000,
			n: 135
		},
		{
			v: 14288,
			vw: 133.1678,
			o: 133.18,
			c: 133.19,
			h: 133.2,
			l: 133.15,
			t: 1655124660000,
			n: 90
		},
		{
			v: 14062,
			vw: 133.2319,
			o: 133.2,
			c: 133.21,
			h: 133.27,
			l: 133.2,
			t: 1655124720000,
			n: 199
		},
		{
			v: 11318,
			vw: 133.1042,
			o: 133.16,
			c: 133.07,
			h: 133.17,
			l: 133.04,
			t: 1655124780000,
			n: 182
		},
		{
			v: 3508,
			vw: 133.081,
			o: 133.08,
			c: 133.1,
			h: 133.1,
			l: 133.05,
			t: 1655124840000,
			n: 79
		},
		{
			v: 25522,
			vw: 133.2007,
			o: 133.15,
			c: 133.28,
			h: 133.29,
			l: 133.14,
			t: 1655124900000,
			n: 273
		},
		{
			v: 31592,
			vw: 133.2187,
			o: 133.22,
			c: 133.25,
			h: 133.27,
			l: 133.16,
			t: 1655124960000,
			n: 247
		},
		{
			v: 22634,
			vw: 133.1868,
			o: 133.25,
			c: 133.09,
			h: 133.26,
			l: 133.09,
			t: 1655125020000,
			n: 278
		},
		{
			v: 8351,
			vw: 133.1217,
			o: 133.11,
			c: 133.11,
			h: 133.16,
			l: 133.1,
			t: 1655125080000,
			n: 200
		},
		{
			v: 2747,
			vw: 133.1151,
			o: 133.14,
			c: 133.1,
			h: 133.14,
			l: 133.09,
			t: 1655125140000,
			n: 115
		},
		{
			v: 15154,
			vw: 132.9967,
			o: 133.07,
			c: 132.95,
			h: 133.07,
			l: 132.92,
			t: 1655125200000,
			n: 404
		},
		{
			v: 6510,
			vw: 132.9922,
			o: 132.99,
			c: 132.97,
			h: 133.03,
			l: 132.96,
			t: 1655125260000,
			n: 172
		},
		{
			v: 25653,
			vw: 133.0662,
			o: 133.01,
			c: 133.02,
			h: 133.19,
			l: 132.99,
			t: 1655125320000,
			n: 410
		},
		{
			v: 23040,
			vw: 133.0708,
			o: 133.03,
			c: 133.06,
			h: 133.13,
			l: 133,
			t: 1655125380000,
			n: 262
		},
		{
			v: 10099,
			vw: 133.0592,
			o: 133.06,
			c: 133.09,
			h: 133.1,
			l: 133.04,
			t: 1655125440000,
			n: 164
		},
		{
			v: 4185,
			vw: 133.0831,
			o: 133.13,
			c: 133.05,
			h: 133.1399,
			l: 133.05,
			t: 1655125500000,
			n: 162
		},
		{
			v: 6577,
			vw: 133.0796,
			o: 133.06,
			c: 133.09,
			h: 133.1,
			l: 133.06,
			t: 1655125560000,
			n: 205
		},
		{
			v: 15325,
			vw: 133.1856,
			o: 133.1,
			c: 133.19,
			h: 133.27,
			l: 133.1,
			t: 1655125620000,
			n: 346
		},
		{
			v: 9670,
			vw: 133.1516,
			o: 133.11,
			c: 133.19,
			h: 133.25,
			l: 133.11,
			t: 1655125680000,
			n: 253
		},
		{
			v: 24928,
			vw: 133.1818,
			o: 133.2,
			c: 133.13,
			h: 133.25,
			l: 133.13,
			t: 1655125740000,
			n: 240
		},
		{
			v: 19384,
			vw: 133.1815,
			o: 133.1101,
			c: 133.3,
			h: 133.3,
			l: 133.11,
			t: 1655125800000,
			n: 213
		},
		{
			v: 14137,
			vw: 133.2341,
			o: 133.26,
			c: 133.22,
			h: 133.28,
			l: 133.18,
			t: 1655125860000,
			n: 319
		},
		{
			v: 8121,
			vw: 133.2193,
			o: 133.2,
			c: 133.23,
			h: 133.23,
			l: 133.18,
			t: 1655125920000,
			n: 138
		},
		{
			v: 22595,
			vw: 133.305,
			o: 133.23,
			c: 133.37,
			h: 133.4,
			l: 133.23,
			t: 1655125980000,
			n: 434
		},
		{
			v: 9821,
			vw: 133.3954,
			o: 133.37,
			c: 133.45,
			h: 133.45,
			l: 133.31,
			t: 1655126040000,
			n: 214
		},
		{
			v: 12137,
			vw: 133.4639,
			o: 133.49,
			c: 133.39,
			h: 133.49,
			l: 133.39,
			t: 1655126100000,
			n: 295
		},
		{
			v: 7273,
			vw: 133.4215,
			o: 133.39,
			c: 133.43,
			h: 133.45,
			l: 133.38,
			t: 1655126160000,
			n: 183
		},
		{
			v: 9463,
			vw: 133.375,
			o: 133.45,
			c: 133.34,
			h: 133.45,
			l: 133.34,
			t: 1655126220000,
			n: 223
		},
		{
			v: 30940,
			vw: 133.1529,
			o: 133.33,
			c: 133,
			h: 133.33,
			l: 133,
			t: 1655126280000,
			n: 641
		},
		{
			v: 14587,
			vw: 133.2092,
			o: 133.04,
			c: 133.19,
			h: 133.29,
			l: 133.04,
			t: 1655126340000,
			n: 185
		},
		{
			v: 14809,
			vw: 133.1327,
			o: 133.22,
			c: 133.12,
			h: 133.22,
			l: 133.11,
			t: 1655126400000,
			n: 190
		},
		{
			v: 23932,
			vw: 133.119,
			o: 133.11,
			c: 133.11,
			h: 133.18,
			l: 133.06,
			t: 1655126460000,
			n: 335
		},
		{
			v: 19406,
			vw: 133.0254,
			o: 133.11,
			c: 133.0201,
			h: 133.12,
			l: 133,
			t: 1655126520000,
			n: 298
		},
		{
			v: 19614,
			vw: 133.0815,
			o: 133.02,
			c: 133.15,
			h: 133.18,
			l: 133.02,
			t: 1655126580000,
			n: 272
		},
		{
			v: 19443,
			vw: 133.1865,
			o: 133.15,
			c: 133.22,
			h: 133.28,
			l: 133.15,
			t: 1655126640000,
			n: 263
		},
		{
			v: 66254,
			vw: 132.8407,
			o: 133.17,
			c: 132.75,
			h: 133.17,
			l: 132.68,
			t: 1655126700000,
			n: 1054
		},
		{
			v: 38162,
			vw: 132.7636,
			o: 132.75,
			c: 132.79,
			h: 132.97,
			l: 132.69,
			t: 1655126760000,
			n: 487
		},
		{
			v: 30929,
			vw: 132.783,
			o: 132.81,
			c: 132.75,
			h: 132.88,
			l: 132.62,
			t: 1655126820000,
			n: 407
		},
		{
			v: 18183,
			vw: 132.8036,
			o: 132.71,
			c: 132.9,
			h: 132.9,
			l: 132.67,
			t: 1655126880000,
			n: 283
		},
		{
			v: 22583,
			vw: 132.8703,
			o: 132.84,
			c: 132.87,
			h: 132.98,
			l: 132.81,
			t: 1655126940000,
			n: 285
		},
		{
			v: 3392292,
			vw: 132.9472,
			o: 132.87,
			c: 133.52,
			h: 133.7,
			l: 132.62,
			t: 1655127000000,
			n: 29032
		},
		{
			v: 875242,
			vw: 133.7084,
			o: 133.515,
			c: 133.6426,
			h: 133.96,
			l: 133.42,
			t: 1655127060000,
			n: 12079
		},
		{
			v: 616463,
			vw: 133.4034,
			o: 133.66,
			c: 133.445,
			h: 133.7,
			l: 133.17,
			t: 1655127120000,
			n: 6237
		},
		{
			v: 588306,
			vw: 133.6118,
			o: 133.45,
			c: 133.49,
			h: 133.76,
			l: 133.4201,
			t: 1655127180000,
			n: 6197
		},
		{
			v: 486125,
			vw: 133.1951,
			o: 133.49,
			c: 133.08,
			h: 133.4978,
			l: 133.01,
			t: 1655127240000,
			n: 5239
		},
		{
			v: 743518,
			vw: 132.8439,
			o: 133.08,
			c: 132.75,
			h: 133.17,
			l: 132.582,
			t: 1655127300000,
			n: 7809
		},
		{
			v: 1172546,
			vw: 133.0633,
			o: 132.75,
			c: 133.6015,
			h: 133.655,
			l: 132.65,
			t: 1655127360000,
			n: 6877
		},
		{
			v: 846806,
			vw: 133.877,
			o: 133.59,
			c: 133.6774,
			h: 134.02,
			l: 133.54,
			t: 1655127420000,
			n: 7399
		},
		{
			v: 368275,
			vw: 133.628,
			o: 133.6761,
			c: 133.65,
			h: 133.83,
			l: 133.46,
			t: 1655127480000,
			n: 4317
		},
		{
			v: 539154,
			vw: 133.6347,
			o: 133.65,
			c: 133.45,
			h: 133.9,
			l: 133.41,
			t: 1655127540000,
			n: 4726
		},
		{
			v: 549447,
			vw: 133.4855,
			o: 133.46,
			c: 133.4,
			h: 133.66,
			l: 133.32,
			t: 1655127600000,
			n: 5084
		},
		{
			v: 395537,
			vw: 133.3325,
			o: 133.4,
			c: 133.53,
			h: 133.57,
			l: 133.1039,
			t: 1655127660000,
			n: 4240
		},
		{
			v: 431283,
			vw: 133.604,
			o: 133.55,
			c: 133.68,
			h: 133.71,
			l: 133.49,
			t: 1655127720000,
			n: 3678
		},
		{
			v: 675197,
			vw: 133.8808,
			o: 133.6981,
			c: 134.15,
			h: 134.31,
			l: 133.4,
			t: 1655127780000,
			n: 6411
		},
		{
			v: 370915,
			vw: 134.1463,
			o: 134.15,
			c: 134.13,
			h: 134.26,
			l: 134,
			t: 1655127840000,
			n: 3949
		},
		{
			v: 455406,
			vw: 134.1871,
			o: 134.11,
			c: 134.12,
			h: 134.33,
			l: 134.0213,
			t: 1655127900000,
			n: 4422
		},
		{
			v: 276011,
			vw: 134.1902,
			o: 134.12,
			c: 134.12,
			h: 134.3097,
			l: 134.04,
			t: 1655127960000,
			n: 3034
		},
		{
			v: 493703,
			vw: 133.7259,
			o: 134.125,
			c: 133.56,
			h: 134.15,
			l: 133.38,
			t: 1655128020000,
			n: 4991
		},
		{
			v: 336001,
			vw: 133.438,
			o: 133.54,
			c: 133.37,
			h: 133.58,
			l: 133.36,
			t: 1655128080000,
			n: 3375
		},
		{
			v: 229071,
			vw: 133.4075,
			o: 133.37,
			c: 133.3638,
			h: 133.54,
			l: 133.3,
			t: 1655128140000,
			n: 2743
		},
		{
			v: 536821,
			vw: 133.4333,
			o: 133.37,
			c: 133.76,
			h: 133.78,
			l: 133.17,
			t: 1655128200000,
			n: 4426
		},
		{
			v: 327728,
			vw: 133.8099,
			o: 133.75,
			c: 133.8503,
			h: 133.96,
			l: 133.66,
			t: 1655128260000,
			n: 3118
		},
		{
			v: 415634,
			vw: 133.927,
			o: 133.85,
			c: 133.92,
			h: 134.07,
			l: 133.8,
			t: 1655128320000,
			n: 3621
		},
		{
			v: 297393,
			vw: 133.9025,
			o: 133.93,
			c: 134,
			h: 134.02,
			l: 133.781,
			t: 1655128380000,
			n: 3104
		},
		{
			v: 356561,
			vw: 134.0398,
			o: 134,
			c: 133.9679,
			h: 134.2,
			l: 133.93,
			t: 1655128440000,
			n: 3030
		},
		{
			v: 368265,
			vw: 134.0712,
			o: 133.97,
			c: 134.105,
			h: 134.22,
			l: 133.93,
			t: 1655128500000,
			n: 3204
		},
		{
			v: 368815,
			vw: 134.176,
			o: 134.11,
			c: 134.285,
			h: 134.32,
			l: 134,
			t: 1655128560000,
			n: 3214
		},
		{
			v: 237253,
			vw: 134.1465,
			o: 134.28,
			c: 134.105,
			h: 134.28,
			l: 134.06,
			t: 1655128620000,
			n: 2213
		},
		{
			v: 440764,
			vw: 134.0571,
			o: 134.1,
			c: 134.2425,
			h: 134.29,
			l: 133.88,
			t: 1655128680000,
			n: 3982
		},
		{
			v: 632923,
			vw: 134.5193,
			o: 134.24,
			c: 134.5999,
			h: 134.66,
			l: 134.14,
			t: 1655128740000,
			n: 5161
		},
		{
			v: 821162,
			vw: 134.9318,
			o: 134.6,
			c: 134.7201,
			h: 135.2,
			l: 134.59,
			t: 1655128800000,
			n: 6450
		},
		{
			v: 390962,
			vw: 134.6636,
			o: 134.7,
			c: 134.5664,
			h: 134.83,
			l: 134.53,
			t: 1655128860000,
			n: 3374
		},
		{
			v: 444128,
			vw: 134.2344,
			o: 134.55,
			c: 134.04,
			h: 134.61,
			l: 134.0301,
			t: 1655128920000,
			n: 3968
		},
		{
			v: 292307,
			vw: 134.1706,
			o: 134.03,
			c: 134.18,
			h: 134.285,
			l: 134.02,
			t: 1655128980000,
			n: 2690
		},
		{
			v: 290832,
			vw: 134.3341,
			o: 134.16,
			c: 134.51,
			h: 134.52,
			l: 134.15,
			t: 1655129040000,
			n: 2594
		},
		{
			v: 425583,
			vw: 134.5454,
			o: 134.5,
			c: 134.58,
			h: 134.739,
			l: 134.31,
			t: 1655129100000,
			n: 3732
		},
		{
			v: 257015,
			vw: 134.5431,
			o: 134.5801,
			c: 134.48,
			h: 134.68,
			l: 134.43,
			t: 1655129160000,
			n: 2473
		},
		{
			v: 282365,
			vw: 134.5971,
			o: 134.47,
			c: 134.5399,
			h: 134.7799,
			l: 134.43,
			t: 1655129220000,
			n: 2818
		},
		{
			v: 358518,
			vw: 134.3,
			o: 134.52,
			c: 134.3687,
			h: 134.56,
			l: 134.0901,
			t: 1655129280000,
			n: 3357
		},
		{
			v: 277246,
			vw: 134.2605,
			o: 134.3699,
			c: 134.123,
			h: 134.54,
			l: 134.04,
			t: 1655129340000,
			n: 2649
		},
		{
			v: 381127,
			vw: 134.1271,
			o: 134.12,
			c: 133.904,
			h: 134.33,
			l: 133.9,
			t: 1655129400000,
			n: 3885
		},
		{
			v: 431665,
			vw: 133.7367,
			o: 133.91,
			c: 133.64,
			h: 133.92,
			l: 133.61,
			t: 1655129460000,
			n: 3616
		},
		{
			v: 366518,
			vw: 133.5831,
			o: 133.63,
			c: 133.725,
			h: 133.755,
			l: 133.44,
			t: 1655129520000,
			n: 3433
		},
		{
			v: 390862,
			vw: 133.726,
			o: 133.725,
			c: 133.51,
			h: 133.92,
			l: 133.465,
			t: 1655129580000,
			n: 3444
		},
		{
			v: 312000,
			vw: 133.4626,
			o: 133.51,
			c: 133.515,
			h: 133.575,
			l: 133.37,
			t: 1655129640000,
			n: 2982
		},
		{
			v: 307943,
			vw: 133.3881,
			o: 133.515,
			c: 133.335,
			h: 133.539,
			l: 133.26,
			t: 1655129700000,
			n: 3058
		},
		{
			v: 222484,
			vw: 133.4185,
			o: 133.345,
			c: 133.31,
			h: 133.51,
			l: 133.295,
			t: 1655129760000,
			n: 2133
		},
		{
			v: 276360,
			vw: 133.2342,
			o: 133.305,
			c: 133.23,
			h: 133.34,
			l: 133.1505,
			t: 1655129820000,
			n: 2660
		},
		{
			v: 419843,
			vw: 133.0656,
			o: 133.225,
			c: 132.965,
			h: 133.225,
			l: 132.945,
			t: 1655129880000,
			n: 6320
		},
		{
			v: 299328,
			vw: 133.0978,
			o: 132.98,
			c: 133.1249,
			h: 133.21,
			l: 132.9,
			t: 1655129940000,
			n: 3009
		},
		{
			v: 317783,
			vw: 133.262,
			o: 133.12,
			c: 133.355,
			h: 133.42,
			l: 133.09,
			t: 1655130000000,
			n: 2899
		},
		{
			v: 277524,
			vw: 133.3333,
			o: 133.359,
			c: 133.165,
			h: 133.46,
			l: 133.16,
			t: 1655130060000,
			n: 2646
		},
		{
			v: 306663,
			vw: 133.0068,
			o: 133.16,
			c: 132.94,
			h: 133.185,
			l: 132.885,
			t: 1655130120000,
			n: 3142
		},
		{
			v: 275903,
			vw: 132.9255,
			o: 132.94,
			c: 132.905,
			h: 132.99,
			l: 132.87,
			t: 1655130180000,
			n: 2663
		},
		{
			v: 316491,
			vw: 132.874,
			o: 132.905,
			c: 132.8091,
			h: 132.99,
			l: 132.78,
			t: 1655130240000,
			n: 3207
		},
		{
			v: 481774,
			vw: 132.6794,
			o: 132.8,
			c: 132.6695,
			h: 132.87,
			l: 132.57,
			t: 1655130300000,
			n: 5186
		},
		{
			v: 294762,
			vw: 132.8026,
			o: 132.6601,
			c: 132.825,
			h: 132.9,
			l: 132.66,
			t: 1655130360000,
			n: 2563
		},
		{
			v: 202530,
			vw: 132.7897,
			o: 132.8166,
			c: 132.7584,
			h: 132.85,
			l: 132.71,
			t: 1655130420000,
			n: 2342
		},
		{
			v: 287516,
			vw: 132.7855,
			o: 132.76,
			c: 132.75,
			h: 132.89,
			l: 132.685,
			t: 1655130480000,
			n: 2698
		},
		{
			v: 288922,
			vw: 132.854,
			o: 132.745,
			c: 132.86,
			h: 132.905,
			l: 132.7389,
			t: 1655130540000,
			n: 2648
		},
		{
			v: 281423,
			vw: 132.8643,
			o: 132.85,
			c: 132.8297,
			h: 132.93,
			l: 132.77,
			t: 1655130600000,
			n: 3092
		},
		{
			v: 257039,
			vw: 132.8302,
			o: 132.8277,
			c: 132.875,
			h: 132.905,
			l: 132.74,
			t: 1655130660000,
			n: 2649
		},
		{
			v: 355014,
			vw: 132.9526,
			o: 132.87,
			c: 133.01,
			h: 133.07,
			l: 132.865,
			t: 1655130720000,
			n: 3422
		},
		{
			v: 297289,
			vw: 133.1387,
			o: 133.01,
			c: 133.28,
			h: 133.31,
			l: 133,
			t: 1655130780000,
			n: 2681
		},
		{
			v: 363444,
			vw: 133.4134,
			o: 133.28,
			c: 133.45,
			h: 133.52,
			l: 133.2713,
			t: 1655130840000,
			n: 3039
		},
		{
			v: 375458,
			vw: 133.4951,
			o: 133.44,
			c: 133.64,
			h: 133.67,
			l: 133.38,
			t: 1655130900000,
			n: 3509
		},
		{
			v: 447825,
			vw: 133.8374,
			o: 133.63,
			c: 133.81,
			h: 133.94,
			l: 133.6,
			t: 1655130960000,
			n: 4052
		},
		{
			v: 358432,
			vw: 133.9217,
			o: 133.8,
			c: 133.83,
			h: 134.0599,
			l: 133.77,
			t: 1655131020000,
			n: 3497
		},
		{
			v: 214402,
			vw: 133.8852,
			o: 133.82,
			c: 133.8,
			h: 133.95,
			l: 133.8,
			t: 1655131080000,
			n: 2347
		},
		{
			v: 368863,
			vw: 133.8505,
			o: 133.81,
			c: 133.76,
			h: 133.98,
			l: 133.721,
			t: 1655131140000,
			n: 3778
		},
		{
			v: 336183,
			vw: 133.7317,
			o: 133.75,
			c: 133.75,
			h: 133.8,
			l: 133.649,
			t: 1655131200000,
			n: 3154
		},
		{
			v: 198828,
			vw: 133.7104,
			o: 133.75,
			c: 133.62,
			h: 133.79,
			l: 133.62,
			t: 1655131260000,
			n: 1940
		},
		{
			v: 274160,
			vw: 133.5269,
			o: 133.63,
			c: 133.435,
			h: 133.69,
			l: 133.42,
			t: 1655131320000,
			n: 2761
		},
		{
			v: 278438,
			vw: 133.2858,
			o: 133.44,
			c: 133.22,
			h: 133.46,
			l: 133.17,
			t: 1655131380000,
			n: 2812
		},
		{
			v: 238933,
			vw: 133.3109,
			o: 133.2154,
			c: 133.33,
			h: 133.44,
			l: 133.2,
			t: 1655131440000,
			n: 2285
		},
		{
			v: 222726,
			vw: 133.3478,
			o: 133.3344,
			c: 133.36,
			h: 133.4681,
			l: 133.22,
			t: 1655131500000,
			n: 1996
		},
		{
			v: 167676,
			vw: 133.3086,
			o: 133.35,
			c: 133.3389,
			h: 133.38,
			l: 133.241,
			t: 1655131560000,
			n: 1588
		},
		{
			v: 293263,
			vw: 133.1723,
			o: 133.33,
			c: 133.07,
			h: 133.36,
			l: 133.02,
			t: 1655131620000,
			n: 2936
		},
		{
			v: 175856,
			vw: 133.0483,
			o: 133.07,
			c: 133.0899,
			h: 133.1,
			l: 133.0139,
			t: 1655131680000,
			n: 2050
		},
		{
			v: 168156,
			vw: 133.1052,
			o: 133.09,
			c: 133.09,
			h: 133.17,
			l: 133.06,
			t: 1655131740000,
			n: 1801
		},
		{
			v: 306439,
			vw: 132.9633,
			o: 133.09,
			c: 132.86,
			h: 133.09,
			l: 132.86,
			t: 1655131800000,
			n: 3637
		},
		{
			v: 205552,
			vw: 132.9256,
			o: 132.86,
			c: 133.09,
			h: 133.11,
			l: 132.81,
			t: 1655131860000,
			n: 2246
		},
		{
			v: 263510,
			vw: 133.2392,
			o: 133.09,
			c: 133.215,
			h: 133.36,
			l: 133.06,
			t: 1655131920000,
			n: 2531
		},
		{
			v: 190986,
			vw: 133.3014,
			o: 133.22,
			c: 133.33,
			h: 133.39,
			l: 133.1501,
			t: 1655131980000,
			n: 1917
		},
		{
			v: 159198,
			vw: 133.2688,
			o: 133.33,
			c: 133.31,
			h: 133.38,
			l: 133.205,
			t: 1655132040000,
			n: 1574
		},
		{
			v: 194221,
			vw: 133.3109,
			o: 133.3,
			c: 133.22,
			h: 133.43,
			l: 133.22,
			t: 1655132100000,
			n: 1823
		},
		{
			v: 210413,
			vw: 133.1876,
			o: 133.225,
			c: 133.1141,
			h: 133.3266,
			l: 133.09,
			t: 1655132160000,
			n: 2092
		},
		{
			v: 192522,
			vw: 133.1862,
			o: 133.11,
			c: 133.305,
			h: 133.34,
			l: 133.0941,
			t: 1655132220000,
			n: 1807
		},
		{
			v: 176151,
			vw: 133.2329,
			o: 133.3,
			c: 133.25,
			h: 133.3,
			l: 133.14,
			t: 1655132280000,
			n: 1804
		},
		{
			v: 188816,
			vw: 133.186,
			o: 133.245,
			c: 133.07,
			h: 133.28,
			l: 133.07,
			t: 1655132340000,
			n: 1758
		},
		{
			v: 170855,
			vw: 133.0782,
			o: 133.0899,
			c: 133.0308,
			h: 133.1656,
			l: 133.01,
			t: 1655132400000,
			n: 1532
		},
		{
			v: 287069,
			vw: 132.8897,
			o: 133.04,
			c: 132.925,
			h: 133.04,
			l: 132.815,
			t: 1655132460000,
			n: 2926
		},
		{
			v: 250675,
			vw: 132.8913,
			o: 132.9299,
			c: 133.06,
			h: 133.07,
			l: 132.77,
			t: 1655132520000,
			n: 2389
		},
		{
			v: 210252,
			vw: 132.9295,
			o: 133.06,
			c: 132.8,
			h: 133.08,
			l: 132.785,
			t: 1655132580000,
			n: 2137
		},
		{
			v: 260293,
			vw: 132.781,
			o: 132.7999,
			c: 132.81,
			h: 132.89,
			l: 132.71,
			t: 1655132640000,
			n: 2370
		},
		{
			v: 194249,
			vw: 132.822,
			o: 132.81,
			c: 132.88,
			h: 132.89,
			l: 132.75,
			t: 1655132700000,
			n: 1969
		},
		{
			v: 207931,
			vw: 132.9921,
			o: 132.88,
			c: 133.055,
			h: 133.07,
			l: 132.87,
			t: 1655132760000,
			n: 2165
		},
		{
			v: 185218,
			vw: 132.9789,
			o: 133.0496,
			c: 132.9899,
			h: 133.09,
			l: 132.89,
			t: 1655132820000,
			n: 2039
		},
		{
			v: 149548,
			vw: 132.995,
			o: 132.98,
			c: 132.96,
			h: 133.0514,
			l: 132.94,
			t: 1655132880000,
			n: 2043
		},
		{
			v: 238292,
			vw: 132.9945,
			o: 132.95,
			c: 133,
			h: 133.051,
			l: 132.9,
			t: 1655132940000,
			n: 2213
		},
		{
			v: 251346,
			vw: 132.8145,
			o: 133,
			c: 132.74,
			h: 133.005,
			l: 132.63,
			t: 1655133000000,
			n: 3280
		},
		{
			v: 317447,
			vw: 133.0224,
			o: 132.73,
			c: 133.12,
			h: 133.14,
			l: 132.72,
			t: 1655133060000,
			n: 2968
		},
		{
			v: 284650,
			vw: 133.2611,
			o: 133.1207,
			c: 133.17,
			h: 133.3864,
			l: 133.12,
			t: 1655133120000,
			n: 2714
		},
		{
			v: 199941,
			vw: 133.1655,
			o: 133.17,
			c: 133.1664,
			h: 133.265,
			l: 133.07,
			t: 1655133180000,
			n: 1979
		},
		{
			v: 167824,
			vw: 133.1624,
			o: 133.16,
			c: 133.2057,
			h: 133.24,
			l: 133.1,
			t: 1655133240000,
			n: 1577
		},
		{
			v: 257415,
			vw: 133.3763,
			o: 133.205,
			c: 133.5322,
			h: 133.54,
			l: 133.13,
			t: 1655133300000,
			n: 2383
		},
		{
			v: 266271,
			vw: 133.5796,
			o: 133.53,
			c: 133.51,
			h: 133.64,
			l: 133.505,
			t: 1655133360000,
			n: 2226
		},
		{
			v: 185945,
			vw: 133.5626,
			o: 133.5111,
			c: 133.6476,
			h: 133.6476,
			l: 133.45,
			t: 1655133420000,
			n: 1729
		},
		{
			v: 256401,
			vw: 133.7036,
			o: 133.64,
			c: 133.735,
			h: 133.75,
			l: 133.64,
			t: 1655133480000,
			n: 2288
		},
		{
			v: 254453,
			vw: 133.7702,
			o: 133.735,
			c: 133.755,
			h: 133.87,
			l: 133.69,
			t: 1655133540000,
			n: 2141
		},
		{
			v: 208169,
			vw: 133.7332,
			o: 133.75,
			c: 133.7536,
			h: 133.8099,
			l: 133.68,
			t: 1655133600000,
			n: 1918
		},
		{
			v: 259103,
			vw: 133.8524,
			o: 133.755,
			c: 133.95,
			h: 133.98,
			l: 133.725,
			t: 1655133660000,
			n: 2236
		},
		{
			v: 400107,
			vw: 134.0042,
			o: 133.96,
			c: 134.015,
			h: 134.15,
			l: 133.88,
			t: 1655133720000,
			n: 3015
		},
		{
			v: 310323,
			vw: 134.1613,
			o: 134.01,
			c: 134.24,
			h: 134.26,
			l: 133.9487,
			t: 1655133780000,
			n: 2495
		},
		{
			v: 304238,
			vw: 134.2928,
			o: 134.234,
			c: 134.38,
			h: 134.39,
			l: 134.17,
			t: 1655133840000,
			n: 2420
		},
		{
			v: 359483,
			vw: 134.4278,
			o: 134.37,
			c: 134.3614,
			h: 134.53,
			l: 134.3301,
			t: 1655133900000,
			n: 2961
		},
		{
			v: 424921,
			vw: 134.266,
			o: 134.37,
			c: 134.18,
			h: 134.39,
			l: 134.18,
			t: 1655133960000,
			n: 2920
		},
		{
			v: 366362,
			vw: 134.3031,
			o: 134.1879,
			c: 134.32,
			h: 134.38,
			l: 134.17,
			t: 1655134020000,
			n: 2359
		},
		{
			v: 200018,
			vw: 134.2789,
			o: 134.3101,
			c: 134.21,
			h: 134.38,
			l: 134.17,
			t: 1655134080000,
			n: 1928
		},
		{
			v: 222603,
			vw: 134.1232,
			o: 134.21,
			c: 134.145,
			h: 134.22,
			l: 134.06,
			t: 1655134140000,
			n: 2033
		},
		{
			v: 234270,
			vw: 134.2305,
			o: 134.1409,
			c: 134.19,
			h: 134.34,
			l: 134.09,
			t: 1655134200000,
			n: 2019
		},
		{
			v: 184175,
			vw: 134.1353,
			o: 134.2,
			c: 134.0884,
			h: 134.2878,
			l: 134.05,
			t: 1655134260000,
			n: 1775
		},
		{
			v: 200151,
			vw: 133.9456,
			o: 134.085,
			c: 133.89,
			h: 134.12,
			l: 133.85,
			t: 1655134320000,
			n: 2320
		},
		{
			v: 292882,
			vw: 134.0722,
			o: 133.8838,
			c: 134.0957,
			h: 134.22,
			l: 133.88,
			t: 1655134380000,
			n: 2584
		},
		{
			v: 170423,
			vw: 133.9951,
			o: 134.09,
			c: 133.94,
			h: 134.135,
			l: 133.93,
			t: 1655134440000,
			n: 1841
		},
		{
			v: 255166,
			vw: 134.2579,
			o: 133.93,
			c: 134.27,
			h: 134.4,
			l: 133.91,
			t: 1655134500000,
			n: 2203
		},
		{
			v: 240853,
			vw: 134.1452,
			o: 134.265,
			c: 134.1799,
			h: 134.33,
			l: 134.0143,
			t: 1655134560000,
			n: 2054
		},
		{
			v: 116182,
			vw: 134.0736,
			o: 134.18,
			c: 134.08,
			h: 134.19,
			l: 134.011,
			t: 1655134620000,
			n: 1274
		},
		{
			v: 155618,
			vw: 134.1974,
			o: 134.09,
			c: 134.19,
			h: 134.27,
			l: 134.09,
			t: 1655134680000,
			n: 1572
		},
		{
			v: 167121,
			vw: 134.298,
			o: 134.2,
			c: 134.285,
			h: 134.36,
			l: 134.18,
			t: 1655134740000,
			n: 1608
		},
		{
			v: 227799,
			vw: 134.3808,
			o: 134.31,
			c: 134.32,
			h: 134.48,
			l: 134.28,
			t: 1655134800000,
			n: 2101
		},
		{
			v: 188760,
			vw: 134.1652,
			o: 134.34,
			c: 134.045,
			h: 134.37,
			l: 133.895,
			t: 1655134860000,
			n: 1891
		},
		{
			v: 210290,
			vw: 134.159,
			o: 134.0493,
			c: 134.35,
			h: 134.38,
			l: 134.03,
			t: 1655134920000,
			n: 1550
		},
		{
			v: 223510,
			vw: 134.3636,
			o: 134.34,
			c: 134.46,
			h: 134.47,
			l: 134.265,
			t: 1655134980000,
			n: 1767
		},
		{
			v: 146670,
			vw: 134.3605,
			o: 134.455,
			c: 134.3157,
			h: 134.4699,
			l: 134.25,
			t: 1655135040000,
			n: 1560
		},
		{
			v: 143932,
			vw: 134.1972,
			o: 134.31,
			c: 134.13,
			h: 134.3659,
			l: 134.11,
			t: 1655135100000,
			n: 1399
		},
		{
			v: 164483,
			vw: 134.0299,
			o: 134.12,
			c: 133.9201,
			h: 134.15,
			l: 133.9201,
			t: 1655135160000,
			n: 1963
		},
		{
			v: 245426,
			vw: 133.8396,
			o: 133.92,
			c: 133.7699,
			h: 133.95,
			l: 133.7,
			t: 1655135220000,
			n: 2499
		},
		{
			v: 209693,
			vw: 133.9431,
			o: 133.78,
			c: 134.04,
			h: 134.0688,
			l: 133.755,
			t: 1655135280000,
			n: 1638
		},
		{
			v: 136101,
			vw: 134.0448,
			o: 134.04,
			c: 134.02,
			h: 134.11,
			l: 134,
			t: 1655135340000,
			n: 1437
		},
		{
			v: 279445,
			vw: 134.0477,
			o: 134.01,
			c: 133.95,
			h: 134.26,
			l: 133.865,
			t: 1655135400000,
			n: 2187
		},
		{
			v: 154273,
			vw: 133.8197,
			o: 133.94,
			c: 133.715,
			h: 133.98,
			l: 133.7,
			t: 1655135460000,
			n: 1676
		},
		{
			v: 267761,
			vw: 133.6834,
			o: 133.71,
			c: 133.665,
			h: 133.8788,
			l: 133.57,
			t: 1655135520000,
			n: 2801
		},
		{
			v: 218952,
			vw: 133.5499,
			o: 133.68,
			c: 133.69,
			h: 133.71,
			l: 133.44,
			t: 1655135580000,
			n: 2291
		},
		{
			v: 220858,
			vw: 133.5043,
			o: 133.68,
			c: 133.5,
			h: 133.68,
			l: 133.44,
			t: 1655135640000,
			n: 2209
		},
		{
			v: 156409,
			vw: 133.6203,
			o: 133.51,
			c: 133.53,
			h: 133.74,
			l: 133.49,
			t: 1655135700000,
			n: 1459
		},
		{
			v: 206325,
			vw: 133.5887,
			o: 133.5368,
			c: 133.69,
			h: 133.7201,
			l: 133.47,
			t: 1655135760000,
			n: 1784
		},
		{
			v: 126530,
			vw: 133.6165,
			o: 133.68,
			c: 133.55,
			h: 133.71,
			l: 133.54,
			t: 1655135820000,
			n: 1317
		},
		{
			v: 159506,
			vw: 133.6201,
			o: 133.56,
			c: 133.67,
			h: 133.71,
			l: 133.53,
			t: 1655135880000,
			n: 1702
		},
		{
			v: 147261,
			vw: 133.7282,
			o: 133.67,
			c: 133.73,
			h: 133.81,
			l: 133.65,
			t: 1655135940000,
			n: 1403
		},
		{
			v: 145480,
			vw: 133.7082,
			o: 133.73,
			c: 133.6886,
			h: 133.8189,
			l: 133.6497,
			t: 1655136000000,
			n: 1437
		},
		{
			v: 128164,
			vw: 133.6335,
			o: 133.67,
			c: 133.63,
			h: 133.72,
			l: 133.55,
			t: 1655136060000,
			n: 1370
		},
		{
			v: 237359,
			vw: 133.909,
			o: 133.64,
			c: 133.9883,
			h: 134.04,
			l: 133.64,
			t: 1655136120000,
			n: 2048
		},
		{
			v: 191133,
			vw: 133.7375,
			o: 133.98,
			c: 133.6389,
			h: 133.98,
			l: 133.6,
			t: 1655136180000,
			n: 1675
		},
		{
			v: 2280280,
			vw: 133.6629,
			o: 133.63,
			c: 133.84,
			h: 133.9,
			l: 133.6,
			t: 1655136240000,
			n: 1730
		},
		{
			v: 130677,
			vw: 133.7674,
			o: 133.8386,
			c: 133.86,
			h: 133.86,
			l: 133.71,
			t: 1655136300000,
			n: 1228
		},
		{
			v: 149868,
			vw: 133.8162,
			o: 133.86,
			c: 133.76,
			h: 133.9258,
			l: 133.705,
			t: 1655136360000,
			n: 1354
		},
		{
			v: 147688,
			vw: 133.6188,
			o: 133.76,
			c: 133.62,
			h: 133.81,
			l: 133.51,
			t: 1655136420000,
			n: 1581
		},
		{
			v: 143130,
			vw: 133.8502,
			o: 133.64,
			c: 134.02,
			h: 134.04,
			l: 133.64,
			t: 1655136480000,
			n: 1314
		},
		{
			v: 269873,
			vw: 134.0744,
			o: 134.04,
			c: 133.8491,
			h: 134.17,
			l: 133.8491,
			t: 1655136540000,
			n: 1945
		},
		{
			v: 160844,
			vw: 133.838,
			o: 133.84,
			c: 134.0264,
			h: 134.0501,
			l: 133.68,
			t: 1655136600000,
			n: 1547
		},
		{
			v: 214030,
			vw: 134.1531,
			o: 134.01,
			c: 134.15,
			h: 134.24,
			l: 133.98,
			t: 1655136660000,
			n: 1801
		},
		{
			v: 174708,
			vw: 134.1942,
			o: 134.17,
			c: 134.31,
			h: 134.33,
			l: 134.08,
			t: 1655136720000,
			n: 1600
		},
		{
			v: 221092,
			vw: 134.2077,
			o: 134.32,
			c: 134.04,
			h: 134.395,
			l: 134.04,
			t: 1655136780000,
			n: 1927
		},
		{
			v: 188944,
			vw: 134.0308,
			o: 134.05,
			c: 133.99,
			h: 134.135,
			l: 133.92,
			t: 1655136840000,
			n: 1700
		},
		{
			v: 145788,
			vw: 134.1713,
			o: 134,
			c: 134.24,
			h: 134.31,
			l: 133.99,
			t: 1655136900000,
			n: 1311
		},
		{
			v: 133668,
			vw: 134.1239,
			o: 134.245,
			c: 133.9749,
			h: 134.27,
			l: 133.96,
			t: 1655136960000,
			n: 1308
		},
		{
			v: 144305,
			vw: 133.9311,
			o: 133.97,
			c: 133.895,
			h: 134.04,
			l: 133.82,
			t: 1655137020000,
			n: 1398
		},
		{
			v: 116860,
			vw: 133.9345,
			o: 133.89,
			c: 134.05,
			h: 134.05,
			l: 133.85,
			t: 1655137080000,
			n: 1022
		},
		{
			v: 91330,
			vw: 134.0088,
			o: 134.0495,
			c: 134.03,
			h: 134.08,
			l: 133.94,
			t: 1655137140000,
			n: 1026
		},
		{
			v: 134534,
			vw: 134.0952,
			o: 134.04,
			c: 134.2,
			h: 134.2,
			l: 134,
			t: 1655137200000,
			n: 1153
		},
		{
			v: 118232,
			vw: 134.1277,
			o: 134.1901,
			c: 134.15,
			h: 134.2004,
			l: 134.02,
			t: 1655137260000,
			n: 1184
		},
		{
			v: 135997,
			vw: 134.1715,
			o: 134.15,
			c: 134.19,
			h: 134.27,
			l: 134.05,
			t: 1655137320000,
			n: 1170
		},
		{
			v: 328895,
			vw: 134.0314,
			o: 134.19,
			c: 133.9498,
			h: 134.19,
			l: 133.9,
			t: 1655137380000,
			n: 3523
		},
		{
			v: 112417,
			vw: 134.0003,
			o: 133.955,
			c: 134.013,
			h: 134.11,
			l: 133.9,
			t: 1655137440000,
			n: 1104
		},
		{
			v: 124225,
			vw: 134.1289,
			o: 134.02,
			c: 134.1445,
			h: 134.2085,
			l: 134,
			t: 1655137500000,
			n: 1117
		},
		{
			v: 129536,
			vw: 134.17,
			o: 134.16,
			c: 134.14,
			h: 134.27,
			l: 134.06,
			t: 1655137560000,
			n: 1377
		},
		{
			v: 97000,
			vw: 134.1558,
			o: 134.1383,
			c: 134.21,
			h: 134.22,
			l: 134.06,
			t: 1655137620000,
			n: 1125
		},
		{
			v: 133881,
			vw: 134.0991,
			o: 134.2199,
			c: 134.09,
			h: 134.22,
			l: 134.01,
			t: 1655137680000,
			n: 1333
		},
		{
			v: 113177,
			vw: 134.1366,
			o: 134.09,
			c: 134.18,
			h: 134.24,
			l: 134.05,
			t: 1655137740000,
			n: 1094
		},
		{
			v: 125144,
			vw: 134.0856,
			o: 134.17,
			c: 134.13,
			h: 134.17,
			l: 134.0001,
			t: 1655137800000,
			n: 1303
		},
		{
			v: 173349,
			vw: 134.2083,
			o: 134.14,
			c: 134.31,
			h: 134.3302,
			l: 134.11,
			t: 1655137860000,
			n: 1248
		},
		{
			v: 140915,
			vw: 134.3097,
			o: 134.31,
			c: 134.34,
			h: 134.36,
			l: 134.2319,
			t: 1655137920000,
			n: 1348
		},
		{
			v: 232537,
			vw: 134.4679,
			o: 134.32,
			c: 134.59,
			h: 134.62,
			l: 134.31,
			t: 1655137980000,
			n: 2101
		},
		{
			v: 284795,
			vw: 134.6652,
			o: 134.58,
			c: 134.66,
			h: 134.74,
			l: 134.58,
			t: 1655138040000,
			n: 2043
		},
		{
			v: 187024,
			vw: 134.667,
			o: 134.64,
			c: 134.775,
			h: 134.78,
			l: 134.59,
			t: 1655138100000,
			n: 1676
		},
		{
			v: 142750,
			vw: 134.7135,
			o: 134.7747,
			c: 134.7501,
			h: 134.79,
			l: 134.64,
			t: 1655138160000,
			n: 1411
		},
		{
			v: 167040,
			vw: 134.7921,
			o: 134.76,
			c: 134.7608,
			h: 134.85,
			l: 134.705,
			t: 1655138220000,
			n: 1436
		},
		{
			v: 187023,
			vw: 134.8871,
			o: 134.765,
			c: 134.9801,
			h: 134.99,
			l: 134.71,
			t: 1655138280000,
			n: 1597
		},
		{
			v: 304117,
			vw: 134.9814,
			o: 134.98,
			c: 135,
			h: 135.0569,
			l: 134.8801,
			t: 1655138340000,
			n: 2391
		},
		{
			v: 154718,
			vw: 134.8759,
			o: 134.99,
			c: 134.83,
			h: 135.03,
			l: 134.76,
			t: 1655138400000,
			n: 1474
		},
		{
			v: 230823,
			vw: 134.686,
			o: 134.82,
			c: 134.66,
			h: 134.8202,
			l: 134.6,
			t: 1655138460000,
			n: 2042
		},
		{
			v: 146860,
			vw: 134.6332,
			o: 134.65,
			c: 134.6613,
			h: 134.68,
			l: 134.56,
			t: 1655138520000,
			n: 1371
		},
		{
			v: 88873,
			vw: 134.5834,
			o: 134.68,
			c: 134.555,
			h: 134.68,
			l: 134.54,
			t: 1655138580000,
			n: 990
		},
		{
			v: 191767,
			vw: 134.4628,
			o: 134.54,
			c: 134.39,
			h: 134.55,
			l: 134.34,
			t: 1655138640000,
			n: 1827
		},
		{
			v: 102101,
			vw: 134.456,
			o: 134.4,
			c: 134.48,
			h: 134.5087,
			l: 134.39,
			t: 1655138700000,
			n: 1087
		},
		{
			v: 123687,
			vw: 134.4134,
			o: 134.4812,
			c: 134.43,
			h: 134.51,
			l: 134.34,
			t: 1655138760000,
			n: 1169
		},
		{
			v: 160127,
			vw: 134.3457,
			o: 134.4201,
			c: 134.2605,
			h: 134.48,
			l: 134.22,
			t: 1655138820000,
			n: 1698
		},
		{
			v: 115451,
			vw: 134.3235,
			o: 134.25,
			c: 134.305,
			h: 134.4,
			l: 134.2389,
			t: 1655138880000,
			n: 1182
		},
		{
			v: 90432,
			vw: 134.2822,
			o: 134.3,
			c: 134.29,
			h: 134.3399,
			l: 134.22,
			t: 1655138940000,
			n: 1016
		},
		{
			v: 98178,
			vw: 134.3287,
			o: 134.29,
			c: 134.31,
			h: 134.405,
			l: 134.25,
			t: 1655139000000,
			n: 1032
		},
		{
			v: 124167,
			vw: 134.2492,
			o: 134.3084,
			c: 134.23,
			h: 134.32,
			l: 134.1703,
			t: 1655139060000,
			n: 1229
		},
		{
			v: 77909,
			vw: 134.29,
			o: 134.21,
			c: 134.385,
			h: 134.39,
			l: 134.195,
			t: 1655139120000,
			n: 896
		},
		{
			v: 117210,
			vw: 134.2358,
			o: 134.3876,
			c: 134.145,
			h: 134.39,
			l: 134.135,
			t: 1655139180000,
			n: 1224
		},
		{
			v: 180933,
			vw: 134.0348,
			o: 134.1408,
			c: 133.975,
			h: 134.16,
			l: 133.95,
			t: 1655139240000,
			n: 2128
		},
		{
			v: 107243,
			vw: 134.0048,
			o: 133.98,
			c: 134.0372,
			h: 134.06,
			l: 133.9403,
			t: 1655139300000,
			n: 1130
		},
		{
			v: 106949,
			vw: 133.9079,
			o: 134.025,
			c: 133.88,
			h: 134.025,
			l: 133.8,
			t: 1655139360000,
			n: 1378
		},
		{
			v: 128121,
			vw: 133.8604,
			o: 133.8899,
			c: 133.7873,
			h: 133.95,
			l: 133.76,
			t: 1655139420000,
			n: 1297
		},
		{
			v: 169744,
			vw: 133.7342,
			o: 133.7805,
			c: 133.7299,
			h: 133.81,
			l: 133.66,
			t: 1655139480000,
			n: 1857
		},
		{
			v: 81858,
			vw: 133.7143,
			o: 133.73,
			c: 133.725,
			h: 133.7441,
			l: 133.68,
			t: 1655139540000,
			n: 1068
		},
		{
			v: 103881,
			vw: 133.6686,
			o: 133.73,
			c: 133.6539,
			h: 133.74,
			l: 133.625,
			t: 1655139600000,
			n: 1209
		},
		{
			v: 135652,
			vw: 133.5727,
			o: 133.655,
			c: 133.54,
			h: 133.659,
			l: 133.52,
			t: 1655139660000,
			n: 1598
		},
		{
			v: 125525,
			vw: 133.5551,
			o: 133.55,
			c: 133.57,
			h: 133.6199,
			l: 133.52,
			t: 1655139720000,
			n: 1211
		},
		{
			v: 142900,
			vw: 133.5363,
			o: 133.5699,
			c: 133.52,
			h: 133.6,
			l: 133.47,
			t: 1655139780000,
			n: 1762
		},
		{
			v: 96528,
			vw: 133.5506,
			o: 133.518,
			c: 133.5102,
			h: 133.6292,
			l: 133.49,
			t: 1655139840000,
			n: 975
		},
		{
			v: 114029,
			vw: 133.4721,
			o: 133.51,
			c: 133.4278,
			h: 133.54,
			l: 133.42,
			t: 1655139900000,
			n: 1272
		},
		{
			v: 191837,
			vw: 133.4393,
			o: 133.43,
			c: 133.53,
			h: 133.54,
			l: 133.37,
			t: 1655139960000,
			n: 1554
		},
		{
			v: 139591,
			vw: 133.5465,
			o: 133.5211,
			c: 133.5089,
			h: 133.5974,
			l: 133.49,
			t: 1655140020000,
			n: 1303
		},
		{
			v: 95245,
			vw: 133.5363,
			o: 133.5,
			c: 133.52,
			h: 133.595,
			l: 133.46,
			t: 1655140080000,
			n: 978
		},
		{
			v: 164626,
			vw: 133.5729,
			o: 133.51,
			c: 133.6301,
			h: 133.64,
			l: 133.48,
			t: 1655140140000,
			n: 1413
		},
		{
			v: 96456,
			vw: 133.5828,
			o: 133.6393,
			c: 133.54,
			h: 133.6393,
			l: 133.53,
			t: 1655140200000,
			n: 991
		},
		{
			v: 114771,
			vw: 133.5307,
			o: 133.535,
			c: 133.6186,
			h: 133.619,
			l: 133.475,
			t: 1655140260000,
			n: 1125
		},
		{
			v: 81708,
			vw: 133.566,
			o: 133.6162,
			c: 133.5735,
			h: 133.62,
			l: 133.52,
			t: 1655140320000,
			n: 853
		},
		{
			v: 132128,
			vw: 133.5102,
			o: 133.58,
			c: 133.4921,
			h: 133.6,
			l: 133.45,
			t: 1655140380000,
			n: 1205
		},
		{
			v: 122464,
			vw: 133.584,
			o: 133.49,
			c: 133.655,
			h: 133.6927,
			l: 133.47,
			t: 1655140440000,
			n: 1025
		},
		{
			v: 116178,
			vw: 133.6452,
			o: 133.655,
			c: 133.62,
			h: 133.6899,
			l: 133.5999,
			t: 1655140500000,
			n: 1267
		},
		{
			v: 147804,
			vw: 133.6753,
			o: 133.63,
			c: 133.685,
			h: 133.75,
			l: 133.595,
			t: 1655140560000,
			n: 1247
		},
		{
			v: 140218,
			vw: 133.6679,
			o: 133.685,
			c: 133.62,
			h: 133.74,
			l: 133.59,
			t: 1655140620000,
			n: 1263
		},
		{
			v: 126882,
			vw: 133.5762,
			o: 133.6237,
			c: 133.52,
			h: 133.68,
			l: 133.5,
			t: 1655140680000,
			n: 1297
		},
		{
			v: 84643,
			vw: 133.5746,
			o: 133.53,
			c: 133.63,
			h: 133.645,
			l: 133.509,
			t: 1655140740000,
			n: 917
		},
		{
			v: 95404,
			vw: 133.5755,
			o: 133.6201,
			c: 133.6,
			h: 133.64,
			l: 133.52,
			t: 1655140800000,
			n: 843
		},
		{
			v: 131808,
			vw: 133.5401,
			o: 133.6,
			c: 133.5278,
			h: 133.64,
			l: 133.485,
			t: 1655140860000,
			n: 1275
		},
		{
			v: 58589,
			vw: 133.4996,
			o: 133.52,
			c: 133.5,
			h: 133.53,
			l: 133.46,
			t: 1655140920000,
			n: 828
		},
		{
			v: 95765,
			vw: 133.5371,
			o: 133.51,
			c: 133.5616,
			h: 133.575,
			l: 133.5,
			t: 1655140980000,
			n: 964
		},
		{
			v: 210811,
			vw: 133.4438,
			o: 133.565,
			c: 133.4951,
			h: 133.565,
			l: 133.34,
			t: 1655141040000,
			n: 1969
		},
		{
			v: 111069,
			vw: 133.4685,
			o: 133.5,
			c: 133.53,
			h: 133.555,
			l: 133.37,
			t: 1655141100000,
			n: 1204
		},
		{
			v: 194073,
			vw: 133.3447,
			o: 133.5241,
			c: 133.2801,
			h: 133.54,
			l: 133.26,
			t: 1655141160000,
			n: 2198
		},
		{
			v: 179924,
			vw: 133.2495,
			o: 133.28,
			c: 133.2,
			h: 133.35,
			l: 133.17,
			t: 1655141220000,
			n: 1705
		},
		{
			v: 103947,
			vw: 133.1888,
			o: 133.18,
			c: 133.23,
			h: 133.2359,
			l: 133.14,
			t: 1655141280000,
			n: 1077
		},
		{
			v: 126766,
			vw: 133.2955,
			o: 133.23,
			c: 133.31,
			h: 133.36,
			l: 133.2,
			t: 1655141340000,
			n: 1093
		},
		{
			v: 134814,
			vw: 133.3348,
			o: 133.315,
			c: 133.3506,
			h: 133.38,
			l: 133.25,
			t: 1655141400000,
			n: 1165
		},
		{
			v: 134671,
			vw: 133.4182,
			o: 133.36,
			c: 133.405,
			h: 133.47,
			l: 133.33,
			t: 1655141460000,
			n: 1096
		},
		{
			v: 101605,
			vw: 133.3792,
			o: 133.39,
			c: 133.35,
			h: 133.45,
			l: 133.3256,
			t: 1655141520000,
			n: 986
		},
		{
			v: 186987,
			vw: 133.255,
			o: 133.35,
			c: 133.28,
			h: 133.35,
			l: 133.16,
			t: 1655141580000,
			n: 1368
		},
		{
			v: 87409,
			vw: 133.2134,
			o: 133.28,
			c: 133.2,
			h: 133.28,
			l: 133.17,
			t: 1655141640000,
			n: 889
		},
		{
			v: 115034,
			vw: 133.2291,
			o: 133.19,
			c: 133.285,
			h: 133.285,
			l: 133.165,
			t: 1655141700000,
			n: 1117
		},
		{
			v: 117075,
			vw: 133.3519,
			o: 133.285,
			c: 133.4025,
			h: 133.42,
			l: 133.27,
			t: 1655141760000,
			n: 1052
		},
		{
			v: 136528,
			vw: 133.3932,
			o: 133.4,
			c: 133.32,
			h: 133.437,
			l: 133.28,
			t: 1655141820000,
			n: 1037
		},
		{
			v: 72187,
			vw: 133.3198,
			o: 133.3,
			c: 133.3001,
			h: 133.375,
			l: 133.27,
			t: 1655141880000,
			n: 1151
		},
		{
			v: 127015,
			vw: 133.4307,
			o: 133.31,
			c: 133.4901,
			h: 133.54,
			l: 133.31,
			t: 1655141940000,
			n: 1269
		},
		{
			v: 144297,
			vw: 133.5174,
			o: 133.5,
			c: 133.51,
			h: 133.57,
			l: 133.465,
			t: 1655142000000,
			n: 1412
		},
		{
			v: 139229,
			vw: 133.5361,
			o: 133.5125,
			c: 133.62,
			h: 133.62,
			l: 133.46,
			t: 1655142060000,
			n: 1427
		},
		{
			v: 244793,
			vw: 133.7477,
			o: 133.6213,
			c: 133.88,
			h: 133.89,
			l: 133.61,
			t: 1655142120000,
			n: 2059
		},
		{
			v: 203397,
			vw: 133.9047,
			o: 133.88,
			c: 133.9,
			h: 133.96,
			l: 133.85,
			t: 1655142180000,
			n: 1632
		},
		{
			v: 233245,
			vw: 133.9608,
			o: 133.893,
			c: 134.02,
			h: 134.05,
			l: 133.85,
			t: 1655142240000,
			n: 1819
		},
		{
			v: 177644,
			vw: 134.0054,
			o: 134.03,
			c: 134.0201,
			h: 134.07,
			l: 133.93,
			t: 1655142300000,
			n: 1488
		},
		{
			v: 236394,
			vw: 134.0783,
			o: 134.04,
			c: 134.11,
			h: 134.125,
			l: 134.02,
			t: 1655142360000,
			n: 1432
		},
		{
			v: 180822,
			vw: 134.1539,
			o: 134.12,
			c: 134.1866,
			h: 134.21,
			l: 134.115,
			t: 1655142420000,
			n: 1538
		},
		{
			v: 201416,
			vw: 134.1808,
			o: 134.1823,
			c: 134.16,
			h: 134.23,
			l: 134.1305,
			t: 1655142480000,
			n: 1418
		},
		{
			v: 121712,
			vw: 134.2014,
			o: 134.16,
			c: 134.2201,
			h: 134.24,
			l: 134.15,
			t: 1655142540000,
			n: 1112
		},
		{
			v: 211932,
			vw: 134.1828,
			o: 134.2399,
			c: 134.055,
			h: 134.27,
			l: 134.035,
			t: 1655142600000,
			n: 1513
		},
		{
			v: 133101,
			vw: 134.1112,
			o: 134.055,
			c: 134.12,
			h: 134.18,
			l: 134.055,
			t: 1655142660000,
			n: 1079
		},
		{
			v: 137816,
			vw: 134.0873,
			o: 134.11,
			c: 134.06,
			h: 134.145,
			l: 134.02,
			t: 1655142720000,
			n: 1244
		},
		{
			v: 181985,
			vw: 133.9873,
			o: 134.05,
			c: 133.995,
			h: 134.055,
			l: 133.95,
			t: 1655142780000,
			n: 1499
		},
		{
			v: 97040,
			vw: 133.9943,
			o: 133.99,
			c: 134.055,
			h: 134.07,
			l: 133.9514,
			t: 1655142840000,
			n: 948
		},
		{
			v: 132990,
			vw: 134.1412,
			o: 134.06,
			c: 134.18,
			h: 134.22,
			l: 134.06,
			t: 1655142900000,
			n: 1121
		},
		{
			v: 149161,
			vw: 134.1361,
			o: 134.175,
			c: 134.1117,
			h: 134.26,
			l: 134.0916,
			t: 1655142960000,
			n: 1312
		},
		{
			v: 80340,
			vw: 134.1483,
			o: 134.13,
			c: 134.175,
			h: 134.21,
			l: 134.09,
			t: 1655143020000,
			n: 772
		},
		{
			v: 106410,
			vw: 134.1701,
			o: 134.17,
			c: 134.205,
			h: 134.24,
			l: 134.115,
			t: 1655143080000,
			n: 990
		},
		{
			v: 125589,
			vw: 134.2625,
			o: 134.2002,
			c: 134.2325,
			h: 134.3179,
			l: 134.19,
			t: 1655143140000,
			n: 1090
		},
		{
			v: 154911,
			vw: 134.1603,
			o: 134.23,
			c: 134.065,
			h: 134.25,
			l: 134.05,
			t: 1655143200000,
			n: 1360
		},
		{
			v: 186245,
			vw: 134.0436,
			o: 134.06,
			c: 134.075,
			h: 134.1301,
			l: 133.99,
			t: 1655143260000,
			n: 1355
		},
		{
			v: 210456,
			vw: 133.9726,
			o: 134.0781,
			c: 133.96,
			h: 134.08,
			l: 133.9,
			t: 1655143320000,
			n: 1810
		},
		{
			v: 178885,
			vw: 133.8702,
			o: 133.965,
			c: 133.92,
			h: 133.97,
			l: 133.805,
			t: 1655143380000,
			n: 1356
		},
		{
			v: 128087,
			vw: 133.9192,
			o: 133.92,
			c: 133.82,
			h: 134.015,
			l: 133.82,
			t: 1655143440000,
			n: 1057
		},
		{
			v: 128374,
			vw: 133.817,
			o: 133.83,
			c: 133.7825,
			h: 133.89,
			l: 133.75,
			t: 1655143500000,
			n: 1095
		},
		{
			v: 109270,
			vw: 133.7861,
			o: 133.785,
			c: 133.78,
			h: 133.87,
			l: 133.71,
			t: 1655143560000,
			n: 1158
		},
		{
			v: 128762,
			vw: 133.6816,
			o: 133.78,
			c: 133.585,
			h: 133.79,
			l: 133.58,
			t: 1655143620000,
			n: 1179
		},
		{
			v: 145656,
			vw: 133.6225,
			o: 133.58,
			c: 133.61,
			h: 133.665,
			l: 133.58,
			t: 1655143680000,
			n: 1167
		},
		{
			v: 142700,
			vw: 133.5738,
			o: 133.6,
			c: 133.5206,
			h: 133.62,
			l: 133.5206,
			t: 1655143740000,
			n: 1102
		},
		{
			v: 146769,
			vw: 133.4543,
			o: 133.52,
			c: 133.455,
			h: 133.53,
			l: 133.37,
			t: 1655143800000,
			n: 1557
		},
		{
			v: 82343,
			vw: 133.4339,
			o: 133.44,
			c: 133.43,
			h: 133.48,
			l: 133.38,
			t: 1655143860000,
			n: 830
		},
		{
			v: 127285,
			vw: 133.5111,
			o: 133.44,
			c: 133.5599,
			h: 133.6,
			l: 133.4,
			t: 1655143920000,
			n: 1107
		},
		{
			v: 150464,
			vw: 133.5175,
			o: 133.548,
			c: 133.4445,
			h: 133.6,
			l: 133.4,
			t: 1655143980000,
			n: 1276
		},
		{
			v: 155475,
			vw: 133.3351,
			o: 133.44,
			c: 133.31,
			h: 133.445,
			l: 133.28,
			t: 1655144040000,
			n: 1323
		},
		{
			v: 140509,
			vw: 133.3603,
			o: 133.3,
			c: 133.4059,
			h: 133.44,
			l: 133.26,
			t: 1655144100000,
			n: 1088
		},
		{
			v: 169168,
			vw: 133.5022,
			o: 133.41,
			c: 133.495,
			h: 133.56,
			l: 133.39,
			t: 1655144160000,
			n: 1203
		},
		{
			v: 151414,
			vw: 133.4819,
			o: 133.495,
			c: 133.45,
			h: 133.56,
			l: 133.41,
			t: 1655144220000,
			n: 1272
		},
		{
			v: 129270,
			vw: 133.4889,
			o: 133.4588,
			c: 133.505,
			h: 133.54,
			l: 133.41,
			t: 1655144280000,
			n: 971
		},
		{
			v: 112469,
			vw: 133.4999,
			o: 133.5,
			c: 133.5399,
			h: 133.57,
			l: 133.45,
			t: 1655144340000,
			n: 1052
		},
		{
			v: 185160,
			vw: 133.5569,
			o: 133.54,
			c: 133.5,
			h: 133.62,
			l: 133.49,
			t: 1655144400000,
			n: 1572
		},
		{
			v: 159246,
			vw: 133.6382,
			o: 133.5066,
			c: 133.57,
			h: 133.7407,
			l: 133.49,
			t: 1655144460000,
			n: 1425
		},
		{
			v: 139154,
			vw: 133.5782,
			o: 133.57,
			c: 133.58,
			h: 133.6199,
			l: 133.52,
			t: 1655144520000,
			n: 1158
		},
		{
			v: 117684,
			vw: 133.5328,
			o: 133.5878,
			c: 133.535,
			h: 133.59,
			l: 133.4919,
			t: 1655144580000,
			n: 1172
		},
		{
			v: 131504,
			vw: 133.5624,
			o: 133.53,
			c: 133.53,
			h: 133.62,
			l: 133.51,
			t: 1655144640000,
			n: 1300
		},
		{
			v: 106461,
			vw: 133.5582,
			o: 133.53,
			c: 133.56,
			h: 133.625,
			l: 133.505,
			t: 1655144700000,
			n: 952
		},
		{
			v: 152300,
			vw: 133.5909,
			o: 133.575,
			c: 133.519,
			h: 133.66,
			l: 133.5,
			t: 1655144760000,
			n: 1302
		},
		{
			v: 143718,
			vw: 133.5785,
			o: 133.515,
			c: 133.6,
			h: 133.66,
			l: 133.4902,
			t: 1655144820000,
			n: 1058
		},
		{
			v: 113960,
			vw: 133.5399,
			o: 133.59,
			c: 133.545,
			h: 133.6,
			l: 133.49,
			t: 1655144880000,
			n: 1122
		},
		{
			v: 210154,
			vw: 133.4711,
			o: 133.56,
			c: 133.3783,
			h: 133.56,
			l: 133.36,
			t: 1655144940000,
			n: 1546
		},
		{
			v: 126858,
			vw: 133.3886,
			o: 133.38,
			c: 133.37,
			h: 133.45,
			l: 133.345,
			t: 1655145000000,
			n: 1059
		},
		{
			v: 255245,
			vw: 133.2106,
			o: 133.36,
			c: 133.0699,
			h: 133.38,
			l: 133.05,
			t: 1655145060000,
			n: 2713
		},
		{
			v: 350581,
			vw: 133.0104,
			o: 133.06,
			c: 132.9701,
			h: 133.09,
			l: 132.9401,
			t: 1655145120000,
			n: 6021
		},
		{
			v: 186520,
			vw: 132.9805,
			o: 132.975,
			c: 133.0901,
			h: 133.105,
			l: 132.88,
			t: 1655145180000,
			n: 1753
		},
		{
			v: 188541,
			vw: 133.1553,
			o: 133.09,
			c: 133.19,
			h: 133.225,
			l: 133.07,
			t: 1655145240000,
			n: 1641
		},
		{
			v: 133470,
			vw: 133.2064,
			o: 133.1901,
			c: 133.25,
			h: 133.28,
			l: 133.1218,
			t: 1655145300000,
			n: 1300
		},
		{
			v: 211588,
			vw: 133.3453,
			o: 133.26,
			c: 133.44,
			h: 133.4891,
			l: 133.18,
			t: 1655145360000,
			n: 1696
		},
		{
			v: 224181,
			vw: 133.4974,
			o: 133.44,
			c: 133.43,
			h: 133.57,
			l: 133.39,
			t: 1655145420000,
			n: 1827
		},
		{
			v: 129313,
			vw: 133.4133,
			o: 133.43,
			c: 133.3743,
			h: 133.48,
			l: 133.34,
			t: 1655145480000,
			n: 1176
		},
		{
			v: 174932,
			vw: 133.4371,
			o: 133.3799,
			c: 133.4,
			h: 133.51,
			l: 133.33,
			t: 1655145540000,
			n: 1265
		},
		{
			v: 117358,
			vw: 133.422,
			o: 133.4,
			c: 133.3697,
			h: 133.48,
			l: 133.35,
			t: 1655145600000,
			n: 1102
		},
		{
			v: 201293,
			vw: 133.5273,
			o: 133.36,
			c: 133.59,
			h: 133.63,
			l: 133.36,
			t: 1655145660000,
			n: 1401
		},
		{
			v: 163514,
			vw: 133.4436,
			o: 133.5858,
			c: 133.385,
			h: 133.59,
			l: 133.335,
			t: 1655145720000,
			n: 1418
		},
		{
			v: 158533,
			vw: 133.3206,
			o: 133.38,
			c: 133.235,
			h: 133.3999,
			l: 133.23,
			t: 1655145780000,
			n: 1407
		},
		{
			v: 146428,
			vw: 133.3032,
			o: 133.23,
			c: 133.375,
			h: 133.4001,
			l: 133.22,
			t: 1655145840000,
			n: 1385
		},
		{
			v: 188575,
			vw: 133.4314,
			o: 133.385,
			c: 133.43,
			h: 133.49,
			l: 133.37,
			t: 1655145900000,
			n: 1453
		},
		{
			v: 150911,
			vw: 133.4353,
			o: 133.43,
			c: 133.335,
			h: 133.5,
			l: 133.32,
			t: 1655145960000,
			n: 1382
		},
		{
			v: 220774,
			vw: 133.2034,
			o: 133.33,
			c: 133.12,
			h: 133.33,
			l: 133.085,
			t: 1655146020000,
			n: 2100
		},
		{
			v: 130071,
			vw: 133.1456,
			o: 133.11,
			c: 133.1888,
			h: 133.1999,
			l: 133.055,
			t: 1655146080000,
			n: 1150
		},
		{
			v: 152779,
			vw: 133.155,
			o: 133.1899,
			c: 133.19,
			h: 133.24,
			l: 133.05,
			t: 1655146140000,
			n: 1502
		},
		{
			v: 198546,
			vw: 133.1359,
			o: 133.195,
			c: 133.125,
			h: 133.2101,
			l: 133.035,
			t: 1655146200000,
			n: 1329
		},
		{
			v: 186909,
			vw: 133.1725,
			o: 133.12,
			c: 133.1782,
			h: 133.23,
			l: 133.1,
			t: 1655146260000,
			n: 1361
		},
		{
			v: 201037,
			vw: 133.0652,
			o: 133.17,
			c: 132.995,
			h: 133.18,
			l: 132.9948,
			t: 1655146320000,
			n: 2113
		},
		{
			v: 121247,
			vw: 133.0426,
			o: 133.01,
			c: 133.0296,
			h: 133.095,
			l: 133,
			t: 1655146380000,
			n: 1332
		},
		{
			v: 125454,
			vw: 133.0791,
			o: 133.02,
			c: 133.085,
			h: 133.17,
			l: 132.99,
			t: 1655146440000,
			n: 1296
		},
		{
			v: 168306,
			vw: 132.9709,
			o: 133.08,
			c: 132.915,
			h: 133.08,
			l: 132.89,
			t: 1655146500000,
			n: 1707
		},
		{
			v: 132290,
			vw: 132.8969,
			o: 132.924,
			c: 132.86,
			h: 132.97,
			l: 132.84,
			t: 1655146560000,
			n: 1474
		},
		{
			v: 197412,
			vw: 132.7885,
			o: 132.85,
			c: 132.7748,
			h: 132.8599,
			l: 132.715,
			t: 1655146620000,
			n: 2363
		},
		{
			v: 215688,
			vw: 132.8544,
			o: 132.775,
			c: 132.9217,
			h: 132.97,
			l: 132.71,
			t: 1655146680000,
			n: 1770
		},
		{
			v: 174986,
			vw: 132.857,
			o: 132.93,
			c: 132.745,
			h: 132.97,
			l: 132.72,
			t: 1655146740000,
			n: 1749
		},
		{
			v: 219192,
			vw: 132.7779,
			o: 132.75,
			c: 132.82,
			h: 132.9,
			l: 132.6824,
			t: 1655146800000,
			n: 2306
		},
		{
			v: 217175,
			vw: 132.7348,
			o: 132.82,
			c: 132.65,
			h: 132.84,
			l: 132.64,
			t: 1655146860000,
			n: 2008
		},
		{
			v: 315010,
			vw: 132.6006,
			o: 132.65,
			c: 132.59,
			h: 132.66,
			l: 132.56,
			t: 1655146920000,
			n: 3284
		},
		{
			v: 258978,
			vw: 132.7863,
			o: 132.61,
			c: 132.9048,
			h: 132.97,
			l: 132.57,
			t: 1655146980000,
			n: 2160
		},
		{
			v: 241110,
			vw: 132.8638,
			o: 132.895,
			c: 132.78,
			h: 132.9492,
			l: 132.78,
			t: 1655147040000,
			n: 2011
		},
		{
			v: 265821,
			vw: 132.8438,
			o: 132.775,
			c: 132.8399,
			h: 132.95,
			l: 132.75,
			t: 1655147100000,
			n: 2043
		},
		{
			v: 277525,
			vw: 132.6697,
			o: 132.83,
			c: 132.53,
			h: 132.83,
			l: 132.53,
			t: 1655147160000,
			n: 2655
		},
		{
			v: 493058,
			vw: 132.5585,
			o: 132.54,
			c: 132.695,
			h: 132.72,
			l: 132.47,
			t: 1655147220000,
			n: 6637
		},
		{
			v: 233452,
			vw: 132.6176,
			o: 132.7,
			c: 132.4864,
			h: 132.76,
			l: 132.48,
			t: 1655147280000,
			n: 2246
		},
		{
			v: 282209,
			vw: 132.4999,
			o: 132.48,
			c: 132.44,
			h: 132.6,
			l: 132.4228,
			t: 1655147340000,
			n: 2800
		},
		{
			v: 391100,
			vw: 132.3711,
			o: 132.45,
			c: 132.3755,
			h: 132.48,
			l: 132.28,
			t: 1655147400000,
			n: 3787
		},
		{
			v: 254807,
			vw: 132.3589,
			o: 132.375,
			c: 132.38,
			h: 132.46,
			l: 132.29,
			t: 1655147460000,
			n: 2516
		},
		{
			v: 224263,
			vw: 132.399,
			o: 132.38,
			c: 132.42,
			h: 132.44,
			l: 132.3501,
			t: 1655147520000,
			n: 1720
		},
		{
			v: 273129,
			vw: 132.2942,
			o: 132.425,
			c: 132.21,
			h: 132.4284,
			l: 132.21,
			t: 1655147580000,
			n: 2718
		},
		{
			v: 328012,
			vw: 132.3167,
			o: 132.215,
			c: 132.375,
			h: 132.44,
			l: 132.21,
			t: 1655147640000,
			n: 2946
		},
		{
			v: 434134,
			vw: 132.2232,
			o: 132.375,
			c: 132.13,
			h: 132.4299,
			l: 132.0683,
			t: 1655147700000,
			n: 4768
		},
		{
			v: 739658,
			vw: 132.4647,
			o: 132.12,
			c: 132.7197,
			h: 132.73,
			l: 132.09,
			t: 1655147760000,
			n: 6685
		},
		{
			v: 848174,
			vw: 132.8957,
			o: 132.72,
			c: 132.58,
			h: 133.32,
			l: 132.46,
			t: 1655147820000,
			n: 7433
		},
		{
			v: 404249,
			vw: 132.5656,
			o: 132.585,
			c: 132.5114,
			h: 132.75,
			l: 132.39,
			t: 1655147880000,
			n: 4281
		},
		{
			v: 417955,
			vw: 132.5166,
			o: 132.51,
			c: 132.64,
			h: 132.83,
			l: 132.35,
			t: 1655147940000,
			n: 3767
		},
		{
			v: 484522,
			vw: 132.7124,
			o: 132.63,
			c: 132.8,
			h: 132.89,
			l: 132.53,
			t: 1655148000000,
			n: 4053
		},
		{
			v: 347996,
			vw: 132.8211,
			o: 132.81,
			c: 132.77,
			h: 132.92,
			l: 132.73,
			t: 1655148060000,
			n: 2885
		},
		{
			v: 277290,
			vw: 132.6866,
			o: 132.78,
			c: 132.5899,
			h: 132.8,
			l: 132.52,
			t: 1655148120000,
			n: 2468
		},
		{
			v: 242585,
			vw: 132.5511,
			o: 132.58,
			c: 132.53,
			h: 132.62,
			l: 132.4789,
			t: 1655148180000,
			n: 2211
		},
		{
			v: 284088,
			vw: 132.5727,
			o: 132.52,
			c: 132.47,
			h: 132.66,
			l: 132.45,
			t: 1655148240000,
			n: 2407
		},
		{
			v: 242694,
			vw: 132.4862,
			o: 132.46,
			c: 132.525,
			h: 132.595,
			l: 132.4,
			t: 1655148300000,
			n: 2125
		},
		{
			v: 205977,
			vw: 132.4268,
			o: 132.52,
			c: 132.305,
			h: 132.57,
			l: 132.3,
			t: 1655148360000,
			n: 1973
		},
		{
			v: 286148,
			vw: 132.3594,
			o: 132.3,
			c: 132.475,
			h: 132.5,
			l: 132.27,
			t: 1655148420000,
			n: 2531
		},
		{
			v: 190467,
			vw: 132.4032,
			o: 132.48,
			c: 132.35,
			h: 132.49,
			l: 132.31,
			t: 1655148480000,
			n: 1969
		},
		{
			v: 208611,
			vw: 132.3277,
			o: 132.3405,
			c: 132.305,
			h: 132.375,
			l: 132.28,
			t: 1655148540000,
			n: 1951
		},
		{
			v: 387988,
			vw: 132.2643,
			o: 132.305,
			c: 132.2942,
			h: 132.33,
			l: 132.19,
			t: 1655148600000,
			n: 3340
		},
		{
			v: 342657,
			vw: 132.3136,
			o: 132.28,
			c: 132.3399,
			h: 132.45,
			l: 132.19,
			t: 1655148660000,
			n: 2969
		},
		{
			v: 272967,
			vw: 132.2164,
			o: 132.34,
			c: 132.11,
			h: 132.35,
			l: 132.1,
			t: 1655148720000,
			n: 2698
		},
		{
			v: 341595,
			vw: 132.1409,
			o: 132.11,
			c: 132.1757,
			h: 132.25,
			l: 132.04,
			t: 1655148780000,
			n: 3288
		},
		{
			v: 250829,
			vw: 132.2044,
			o: 132.18,
			c: 132.195,
			h: 132.29,
			l: 132.11,
			t: 1655148840000,
			n: 2490
		},
		{
			v: 350744,
			vw: 132.2604,
			o: 132.19,
			c: 132.2901,
			h: 132.35,
			l: 132.18,
			t: 1655148900000,
			n: 3059
		},
		{
			v: 235155,
			vw: 132.3572,
			o: 132.29,
			c: 132.3701,
			h: 132.44,
			l: 132.245,
			t: 1655148960000,
			n: 2397
		},
		{
			v: 342134,
			vw: 132.4707,
			o: 132.3781,
			c: 132.4519,
			h: 132.57,
			l: 132.36,
			t: 1655149020000,
			n: 2720
		},
		{
			v: 304509,
			vw: 132.3221,
			o: 132.46,
			c: 132.205,
			h: 132.46,
			l: 132.2,
			t: 1655149080000,
			n: 3269
		},
		{
			v: 744615,
			vw: 132.0427,
			o: 132.2084,
			c: 132.07,
			h: 132.22,
			l: 131.9,
			t: 1655149140000,
			n: 14409
		},
		{
			v: 371368,
			vw: 132.0728,
			o: 132.07,
			c: 132.03,
			h: 132.18,
			l: 131.98,
			t: 1655149200000,
			n: 3605
		},
		{
			v: 294827,
			vw: 132.1353,
			o: 132.04,
			c: 132.175,
			h: 132.25,
			l: 132.0316,
			t: 1655149260000,
			n: 2783
		},
		{
			v: 267960,
			vw: 132.1313,
			o: 132.1781,
			c: 132.14,
			h: 132.25,
			l: 132.03,
			t: 1655149320000,
			n: 2763
		},
		{
			v: 234127,
			vw: 132.0943,
			o: 132.14,
			c: 132.09,
			h: 132.15,
			l: 132.04,
			t: 1655149380000,
			n: 2418
		},
		{
			v: 372700,
			vw: 132.1194,
			o: 132.0821,
			c: 132.025,
			h: 132.23,
			l: 132.02,
			t: 1655149440000,
			n: 3536
		},
		{
			v: 373081,
			vw: 132.0496,
			o: 132.03,
			c: 132.14,
			h: 132.14,
			l: 131.97,
			t: 1655149500000,
			n: 3697
		},
		{
			v: 438142,
			vw: 132.0418,
			o: 132.13,
			c: 132.015,
			h: 132.18,
			l: 131.91,
			t: 1655149560000,
			n: 4307
		},
		{
			v: 320814,
			vw: 132.0192,
			o: 132.01,
			c: 132.08,
			h: 132.09,
			l: 131.9219,
			t: 1655149620000,
			n: 3318
		},
		{
			v: 435455,
			vw: 132.166,
			o: 132.07,
			c: 132.03,
			h: 132.3,
			l: 132.03,
			t: 1655149680000,
			n: 3806
		},
		{
			v: 469526,
			vw: 131.9594,
			o: 132.02,
			c: 132.02,
			h: 132.13,
			l: 131.8653,
			t: 1655149740000,
			n: 4811
		},
		{
			v: 948273,
			vw: 131.77,
			o: 132.015,
			c: 131.57,
			h: 132.09,
			l: 131.55,
			t: 1655149800000,
			n: 9178
		},
		{
			v: 579831,
			vw: 131.5602,
			o: 131.58,
			c: 131.45,
			h: 131.6677,
			l: 131.44,
			t: 1655149860000,
			n: 6094
		},
		{
			v: 637807,
			vw: 131.6233,
			o: 131.45,
			c: 131.7,
			h: 131.77,
			l: 131.45,
			t: 1655149920000,
			n: 5207
		},
		{
			v: 669143,
			vw: 131.8877,
			o: 131.69,
			c: 131.8487,
			h: 132.01,
			l: 131.65,
			t: 1655149980000,
			n: 5863
		},
		{
			v: 690034,
			vw: 131.8687,
			o: 131.84,
			c: 131.915,
			h: 132.02,
			l: 131.72,
			t: 1655150040000,
			n: 6427
		},
		{
			v: 735907,
			vw: 131.9584,
			o: 131.92,
			c: 132.125,
			h: 132.22,
			l: 131.81,
			t: 1655150100000,
			n: 6218
		},
		{
			v: 693719,
			vw: 132.1633,
			o: 132.13,
			c: 131.98,
			h: 132.29,
			l: 131.97,
			t: 1655150160000,
			n: 5389
		},
		{
			v: 777638,
			vw: 132.0187,
			o: 131.99,
			c: 132.06,
			h: 132.095,
			l: 131.86,
			t: 1655150220000,
			n: 5481
		},
		{
			v: 853982,
			vw: 131.9227,
			o: 132.06,
			c: 131.81,
			h: 132.1,
			l: 131.79,
			t: 1655150280000,
			n: 6508
		},
		{
			v: 1957253,
			vw: 131.9109,
			o: 131.8,
			c: 131.88,
			h: 132.08,
			l: 131.78,
			t: 1655150340000,
			n: 12504
		},
		{
			v: 1588647,
			vw: 131.9171,
			o: 131.88,
			c: 132.01,
			h: 132.3,
			l: 131.88,
			t: 1655150400000,
			n: 933
		},
		{
			v: 31892,
			vw: 132.0092,
			o: 132.01,
			c: 132.07,
			h: 132.08,
			l: 131.93,
			t: 1655150460000,
			n: 268
		},
		{
			v: 17536,
			vw: 132.0398,
			o: 132.03,
			c: 132.08,
			h: 132.08,
			l: 131.88,
			t: 1655150520000,
			n: 155
		},
		{
			v: 6844,
			vw: 132.1147,
			o: 132.11,
			c: 132.15,
			h: 132.16,
			l: 132.0601,
			t: 1655150580000,
			n: 148
		},
		{
			v: 12313,
			vw: 132.0945,
			o: 132.14,
			c: 132.14,
			h: 132.17,
			l: 132.06,
			t: 1655150640000,
			n: 183
		},
		{
			v: 23799,
			vw: 132.0874,
			o: 132.17,
			c: 132.25,
			h: 132.25,
			l: 131.88,
			t: 1655150700000,
			n: 188
		},
		{
			v: 62803,
			vw: 132.0304,
			o: 132.25,
			c: 132.34,
			h: 132.47,
			l: 132.21,
			t: 1655150760000,
			n: 201
		},
		{
			v: 8594,
			vw: 132.3104,
			o: 132.32,
			c: 132.3,
			h: 132.37,
			l: 132.3,
			t: 1655150820000,
			n: 135
		},
		{
			v: 6308,
			vw: 132.2701,
			o: 132.3,
			c: 132.33,
			h: 132.37,
			l: 131.88,
			t: 1655150880000,
			n: 126
		},
		{
			v: 20790,
			vw: 132.3164,
			o: 132.35,
			c: 132.37,
			h: 132.4,
			l: 132.3,
			t: 1655150940000,
			n: 141
		},
		{
			v: 10778,
			vw: 132.4214,
			o: 132.4,
			c: 132.37,
			h: 132.47,
			l: 132.37,
			t: 1655151000000,
			n: 169
		},
		{
			v: 19826,
			vw: 132.4561,
			o: 132.38,
			c: 132.51,
			h: 132.51,
			l: 132.38,
			t: 1655151060000,
			n: 237
		},
		{
			v: 16898,
			vw: 132.4601,
			o: 132.44,
			c: 132.36,
			h: 132.58,
			l: 132.32,
			t: 1655151120000,
			n: 207
		},
		{
			v: 5809,
			vw: 132.838,
			o: 132.35,
			c: 132.41,
			h: 132.56,
			l: 132.35,
			t: 1655151180000,
			n: 491
		},
		{
			v: 7135,
			vw: 132.3644,
			o: 132.41,
			c: 132.47,
			h: 132.47,
			l: 131.88,
			t: 1655151240000,
			n: 107
		},
		{
			v: 10097,
			vw: 132.4388,
			o: 132.42,
			c: 132.53,
			h: 132.53,
			l: 132.37,
			t: 1655151300000,
			n: 146
		},
		{
			v: 4230,
			vw: 132.4687,
			o: 132.48,
			c: 132.45,
			h: 132.5,
			l: 132.45,
			t: 1655151360000,
			n: 93
		},
		{
			v: 146207,
			vw: 131.891,
			o: 131.88,
			c: 132.47,
			h: 132.49,
			l: 131.88,
			t: 1655151420000,
			n: 80
		},
		{
			v: 9884,
			vw: 132.3964,
			o: 132.47,
			c: 132.35,
			h: 132.47,
			l: 132.35,
			t: 1655151480000,
			n: 131
		},
		{
			v: 5340,
			vw: 132.3582,
			o: 132.36,
			c: 132.35,
			h: 132.4,
			l: 132.35,
			t: 1655151540000,
			n: 86
		},
		{
			v: 203922,
			vw: 131.8831,
			o: 132.36,
			c: 132.32,
			h: 132.36,
			l: 131.88,
			t: 1655151600000,
			n: 78
		},
		{
			v: 2591,
			vw: 132.316,
			o: 132.3,
			c: 132.28,
			h: 132.37,
			l: 132.28,
			t: 1655151660000,
			n: 109
		},
		{
			v: 104365,
			vw: 131.8926,
			o: 131.88,
			c: 132.3,
			h: 132.35,
			l: 131.88,
			t: 1655151720000,
			n: 77
		},
		{
			v: 1918,
			vw: 132.2879,
			o: 132.27,
			c: 132.29,
			h: 132.34,
			l: 132.27,
			t: 1655151780000,
			n: 81
		},
		{
			v: 4798,
			vw: 132.2502,
			o: 132.28,
			c: 132.22,
			h: 132.28,
			l: 132.22,
			t: 1655151840000,
			n: 88
		},
		{
			v: 241768,
			vw: 131.9005,
			o: 132.21,
			c: 132.12,
			h: 132.25,
			l: 132,
			t: 1655151900000,
			n: 202
		},
		{
			v: 6330,
			vw: 132.1761,
			o: 132.18,
			c: 132.16,
			h: 132.2,
			l: 132.12,
			t: 1655151960000,
			n: 68
		},
		{
			v: 3757,
			vw: 132.1702,
			o: 132.15,
			c: 132.2,
			h: 132.25,
			l: 132.14,
			t: 1655152020000,
			n: 54
		},
		{
			v: 4682,
			vw: 132.3072,
			o: 132.25,
			c: 132.3,
			h: 132.46,
			l: 132.25,
			t: 1655152080000,
			n: 94
		},
		{
			v: 448,
			vw: 132.4084,
			o: 132.35,
			c: 132.35,
			h: 132.35,
			l: 132.35,
			t: 1655152140000,
			n: 44
		},
		{
			v: 5874,
			vw: 132.3604,
			o: 132.34,
			c: 132.35,
			h: 132.39,
			l: 132.34,
			t: 1655152200000,
			n: 102
		},
		{
			v: 1584,
			vw: 132.3501,
			o: 132.35,
			c: 132.35,
			h: 132.35,
			l: 132.35,
			t: 1655152260000,
			n: 71
		},
		{
			v: 2839,
			vw: 132.3848,
			o: 132.38,
			c: 132.39,
			h: 132.39,
			l: 132.38,
			t: 1655152320000,
			n: 64
		},
		{
			v: 1473,
			vw: 132.3763,
			o: 132.39,
			c: 132.38,
			h: 132.39,
			l: 132.38,
			t: 1655152380000,
			n: 48
		},
		{
			v: 691,
			vw: 132.3156,
			o: 132.3,
			c: 132.3,
			h: 132.3,
			l: 132.3,
			t: 1655152440000,
			n: 57
		},
		{
			v: 2666,
			vw: 132.2872,
			o: 132.31,
			c: 132.26,
			h: 132.33,
			l: 132.26,
			t: 1655152500000,
			n: 80
		},
		{
			v: 2378,
			vw: 132.2082,
			o: 132.3,
			c: 132.3,
			h: 132.3,
			l: 131.88,
			t: 1655152560000,
			n: 71
		},
		{
			v: 3531,
			vw: 132.1993,
			o: 132.3,
			c: 132.35,
			h: 132.35,
			l: 131.88,
			t: 1655152680000,
			n: 57
		},
		{
			v: 22146,
			vw: 132.3891,
			o: 132.35,
			c: 132.52,
			h: 132.52,
			l: 132.35,
			t: 1655152740000,
			n: 117
		},
		{
			v: 4444,
			vw: 132.5414,
			o: 132.4,
			c: 132.56,
			h: 132.56,
			l: 132.4,
			t: 1655152800000,
			n: 65
		},
		{
			v: 3972,
			vw: 132.5597,
			o: 132.54,
			c: 132.62,
			h: 132.62,
			l: 132.5,
			t: 1655152860000,
			n: 86
		},
		{
			v: 4584,
			vw: 132.5414,
			o: 132.59,
			c: 132.5,
			h: 132.6,
			l: 132.5,
			t: 1655152920000,
			n: 136
		},
		{
			v: 1850,
			vw: 132.4684,
			o: 132.41,
			c: 132.5,
			h: 132.5,
			l: 132.41,
			t: 1655152980000,
			n: 73
		},
		{
			v: 874,
			vw: 132.5528,
			o: 132.52,
			c: 132.56,
			h: 132.56,
			l: 132.52,
			t: 1655153040000,
			n: 55
		},
		{
			v: 2763,
			vw: 132.5552,
			o: 132.54,
			c: 132.59,
			h: 132.59,
			l: 132.51,
			t: 1655153100000,
			n: 59
		},
		{
			v: 1569,
			vw: 132.5424,
			o: 132.54,
			c: 132.52,
			h: 132.55,
			l: 132.52,
			t: 1655153160000,
			n: 55
		},
		{
			v: 1617,
			vw: 132.5013,
			o: 132.5,
			c: 132.48,
			h: 132.5,
			l: 132.48,
			t: 1655153220000,
			n: 76
		},
		{
			v: 997,
			vw: 132.5237,
			o: 132.45,
			c: 132.55,
			h: 132.55,
			l: 132.45,
			t: 1655153280000,
			n: 49
		},
		{
			v: 3503,
			vw: 132.4716,
			o: 132.55,
			c: 132.45,
			h: 132.55,
			l: 132.45,
			t: 1655153340000,
			n: 100
		},
		{
			v: 613,
			vw: 132.4887,
			o: 132.41,
			c: 132.41,
			h: 132.41,
			l: 132.41,
			t: 1655153400000,
			n: 59
		},
		{
			v: 1322,
			vw: 132.4669,
			o: 132.5,
			c: 132.42,
			h: 132.5,
			l: 132.42,
			t: 1655153460000,
			n: 60
		},
		{
			v: 456978,
			vw: 131.881,
			o: 132.41,
			c: 132.45,
			h: 132.45,
			l: 131.88,
			t: 1655153520000,
			n: 79
		},
		{
			v: 79326,
			vw: 131.8851,
			o: 131.88,
			c: 132.405,
			h: 132.405,
			l: 131.88,
			t: 1655153580000,
			n: 56
		},
		{
			v: 110572,
			vw: 131.8848,
			o: 132.49,
			c: 132.45,
			h: 132.49,
			l: 131.88,
			t: 1655153640000,
			n: 67
		},
		{
			v: 614,
			vw: 132.4304,
			o: 132.45,
			c: 132.42,
			h: 132.45,
			l: 132.42,
			t: 1655153700000,
			n: 43
		},
		{
			v: 2159,
			vw: 132.4106,
			o: 132.41,
			c: 132.36,
			h: 132.48,
			l: 132.36,
			t: 1655153760000,
			n: 70
		},
		{
			v: 556,
			vw: 132.3861,
			o: 132.4,
			c: 132.35,
			h: 132.4,
			l: 132.35,
			t: 1655153820000,
			n: 40
		},
		{
			v: 357,
			vw: 132.4325,
			o: 132.44,
			c: 132.44,
			h: 132.44,
			l: 132.44,
			t: 1655153880000,
			n: 45
		},
		{
			v: 1493,
			vw: 133.1917,
			o: 132.45,
			c: 132.45,
			h: 132.45,
			l: 132.45,
			t: 1655153940000,
			n: 67
		},
		{
			v: 935,
			vw: 132.4128,
			o: 132.37,
			c: 132.36,
			h: 132.37,
			l: 132.36,
			t: 1655154000000,
			n: 60
		},
		{
			v: 617,
			vw: 133.0093,
			o: 132.35,
			c: 132.35,
			h: 132.35,
			l: 132.35,
			t: 1655154060000,
			n: 57
		},
		{
			v: 1789,
			vw: 132.3784,
			o: 132.35,
			c: 132.43,
			h: 132.45,
			l: 132.31,
			t: 1655154120000,
			n: 54
		},
		{
			v: 882,
			vw: 132.3797,
			o: 132.42,
			c: 132.42,
			h: 132.42,
			l: 132.3101,
			t: 1655154180000,
			n: 53
		},
		{
			v: 1295,
			vw: 132.3698,
			o: 132.42,
			c: 132.34,
			h: 132.42,
			l: 132.32,
			t: 1655154240000,
			n: 63
		},
		{
			v: 4354,
			vw: 132.4263,
			o: 132.43,
			c: 132.4,
			h: 132.48,
			l: 132.4,
			t: 1655154300000,
			n: 70
		},
		{
			v: 1792,
			vw: 132.3783,
			o: 132.38,
			c: 132.38,
			h: 132.38,
			l: 132.38,
			t: 1655154360000,
			n: 37
		},
		{
			v: 818,
			vw: 132.3386,
			o: 132.33,
			c: 132.33,
			h: 132.33,
			l: 132.33,
			t: 1655154420000,
			n: 58
		},
		{
			v: 395,
			vw: 132.4022,
			o: 132.38,
			c: 132.38,
			h: 132.38,
			l: 132.38,
			t: 1655154480000,
			n: 28
		},
		{
			v: 2582,
			vw: 132.3642,
			o: 132.37,
			c: 132.35,
			h: 132.47,
			l: 132.35,
			t: 1655154660000,
			n: 64
		},
		{
			v: 1999,
			vw: 132.4037,
			o: 132.37,
			c: 132.4,
			h: 132.47,
			l: 132.37,
			t: 1655154720000,
			n: 67
		},
		{
			v: 1117,
			vw: 132.4284,
			o: 132.4,
			c: 132.46,
			h: 132.46,
			l: 132.4,
			t: 1655154780000,
			n: 58
		},
		{
			v: 2027,
			vw: 132.3967,
			o: 132.37,
			c: 132.37,
			h: 132.45,
			l: 132.37,
			t: 1655154840000,
			n: 43
		},
		{
			v: 2896,
			vw: 132.3746,
			o: 132.4,
			c: 132.36,
			h: 132.4,
			l: 132.35,
			t: 1655155020000,
			n: 75
		},
		{
			v: 1036,
			vw: 132.951,
			o: 132.32,
			c: 132.31,
			h: 132.32,
			l: 132.31,
			t: 1655155080000,
			n: 78
		},
		{
			v: 2232,
			vw: 132.2898,
			o: 132.3,
			c: 132.26,
			h: 132.3,
			l: 132.26,
			t: 1655155140000,
			n: 81
		},
		{
			v: 324,
			vw: 132.364,
			o: 132.26,
			c: 132.26,
			h: 132.26,
			l: 132.26,
			t: 1655155200000,
			n: 43
		},
		{
			v: 768,
			vw: 132.263,
			o: 132.25,
			c: 132.26,
			h: 132.26,
			l: 132.25,
			t: 1655155260000,
			n: 35
		},
		{
			v: 7119,
			vw: 132.3577,
			o: 132.35,
			c: 132.36,
			h: 132.36,
			l: 132.35,
			t: 1655155320000,
			n: 38
		},
		{
			v: 3912,
			vw: 132.3577,
			o: 132.37,
			c: 132.36,
			h: 132.41,
			l: 132.35,
			t: 1655155380000,
			n: 38
		},
		{
			v: 1165,
			vw: 132.4492,
			o: 132.46,
			c: 132.46,
			h: 132.46,
			l: 132.46,
			t: 1655155440000,
			n: 48
		},
		{
			v: 2244,
			vw: 132.4157,
			o: 132.44,
			c: 132.45,
			h: 132.45,
			l: 132.4,
			t: 1655155500000,
			n: 39
		},
		{
			v: 5322,
			vw: 132.4182,
			o: 132.4,
			c: 132.43,
			h: 132.47,
			l: 132.36,
			t: 1655155680000,
			n: 51
		},
		{
			v: 1129,
			vw: 132.4114,
			o: 132.41,
			c: 132.4,
			h: 132.41,
			l: 132.4,
			t: 1655155740000,
			n: 50
		},
		{
			v: 1557,
			vw: 132.4637,
			o: 132.39,
			c: 132.48,
			h: 132.48,
			l: 132.39,
			t: 1655155800000,
			n: 37
		},
		{
			v: 1817,
			vw: 132.466,
			o: 132.5,
			c: 132.45,
			h: 132.5,
			l: 132.45,
			t: 1655155860000,
			n: 47
		},
		{
			v: 2673,
			vw: 132.438,
			o: 132.4,
			c: 132.45,
			h: 132.48,
			l: 132.4,
			t: 1655155920000,
			n: 57
		},
		{
			v: 2276,
			vw: 132.4859,
			o: 132.48,
			c: 132.46,
			h: 132.5,
			l: 132.43,
			t: 1655155980000,
			n: 48
		},
		{
			v: 2076,
			vw: 132.4822,
			o: 132.45,
			c: 132.5,
			h: 132.5,
			l: 132.45,
			t: 1655156040000,
			n: 79
		},
		{
			v: 1467,
			vw: 132.4702,
			o: 132.51,
			c: 132.5,
			h: 132.52,
			l: 132.44,
			t: 1655156100000,
			n: 48
		},
		{
			v: 578,
			vw: 132.4525,
			o: 132.44,
			c: 132.44,
			h: 132.44,
			l: 132.44,
			t: 1655156160000,
			n: 30
		},
		{
			v: 558,
			vw: 132.4785,
			o: 132.4799,
			c: 132.4799,
			h: 132.4799,
			l: 132.4799,
			t: 1655156220000,
			n: 28
		},
		{
			v: 1162,
			vw: 132.4958,
			o: 132.48,
			c: 132.52,
			h: 132.52,
			l: 132.48,
			t: 1655156280000,
			n: 36
		},
		{
			v: 1555,
			vw: 132.4837,
			o: 132.45,
			c: 132.5,
			h: 132.5,
			l: 132.45,
			t: 1655156400000,
			n: 62
		},
		{
			v: 669,
			vw: 132.4931,
			o: 132.5,
			c: 132.5,
			h: 132.5,
			l: 132.5,
			t: 1655156460000,
			n: 43
		},
		{
			v: 1299,
			vw: 132.4936,
			o: 132.5,
			c: 132.5,
			h: 132.5,
			l: 132.48,
			t: 1655156580000,
			n: 41
		},
		{
			v: 288,
			vw: 132.4967,
			o: 132.4999,
			c: 132.4999,
			h: 132.4999,
			l: 132.4999,
			t: 1655156640000,
			n: 22
		},
		{
			v: 550,
			vw: 132.4553,
			o: 132.45,
			c: 132.45,
			h: 132.45,
			l: 132.45,
			t: 1655156760000,
			n: 31
		},
		{
			v: 1114,
			vw: 132.442,
			o: 132.4001,
			c: 132.45,
			h: 132.45,
			l: 132.4001,
			t: 1655156880000,
			n: 25
		},
		{
			v: 3038,
			vw: 132.4116,
			o: 132.4,
			c: 132.4,
			h: 132.44,
			l: 132.4,
			t: 1655156940000,
			n: 46
		},
		{
			v: 548,
			vw: 132.4134,
			o: 132.4,
			c: 132.4,
			h: 132.4,
			l: 132.4,
			t: 1655157060000,
			n: 64
		},
		{
			v: 1867,
			vw: 132.4062,
			o: 132.42,
			c: 132.4,
			h: 132.42,
			l: 132.39,
			t: 1655157120000,
			n: 39
		},
		{
			v: 639,
			vw: 132.43,
			o: 132.44,
			c: 132.44,
			h: 132.44,
			l: 132.44,
			t: 1655157180000,
			n: 42
		},
		{
			v: 445,
			vw: 132.4017,
			o: 132.4,
			c: 132.4,
			h: 132.4,
			l: 132.4,
			t: 1655157240000,
			n: 43
		},
		{
			v: 549,
			vw: 132.3927,
			o: 132.4,
			c: 132.38,
			h: 132.4,
			l: 132.38,
			t: 1655157300000,
			n: 46
		},
		{
			v: 368,
			vw: 132.3683,
			o: 132.37,
			c: 132.37,
			h: 132.37,
			l: 132.37,
			t: 1655157360000,
			n: 35
		},
		{
			v: 254,
			vw: 132.3725,
			o: 132.37,
			c: 132.37,
			h: 132.37,
			l: 132.37,
			t: 1655157480000,
			n: 28
		},
		{
			v: 1305,
			vw: 132.3504,
			o: 132.35,
			c: 132.35,
			h: 132.35,
			l: 132.32,
			t: 1655157540000,
			n: 61
		},
		{
			v: 8281,
			vw: 132.5465,
			o: 132.4,
			c: 132.79,
			h: 132.79,
			l: 132.4,
			t: 1655157600000,
			n: 111
		},
		{
			v: 1644,
			vw: 132.6191,
			o: 132.75,
			c: 132.6,
			h: 132.75,
			l: 132.58,
			t: 1655157660000,
			n: 35
		},
		{
			v: 540,
			vw: 132.6378,
			o: 132.63,
			c: 132.64,
			h: 132.64,
			l: 132.63,
			t: 1655157720000,
			n: 28
		},
		{
			v: 891,
			vw: 132.6968,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655157780000,
			n: 38
		},
		{
			v: 1449,
			vw: 132.6867,
			o: 132.7,
			c: 132.68,
			h: 132.7,
			l: 132.68,
			t: 1655157840000,
			n: 44
		},
		{
			v: 762,
			vw: 132.6387,
			o: 132.65,
			c: 132.65,
			h: 132.65,
			l: 132.59,
			t: 1655157900000,
			n: 44
		},
		{
			v: 2004,
			vw: 132.6148,
			o: 132.62,
			c: 132.6,
			h: 132.62,
			l: 132.6,
			t: 1655157960000,
			n: 30
		},
		{
			v: 1701,
			vw: 132.6808,
			o: 132.62,
			c: 132.74,
			h: 132.74,
			l: 132.62,
			t: 1655158020000,
			n: 45
		},
		{
			v: 1127,
			vw: 132.7398,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655158080000,
			n: 32
		},
		{
			v: 742,
			vw: 132.7478,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655158140000,
			n: 29
		},
		{
			v: 561,
			vw: 132.7354,
			o: 132.72,
			c: 132.74,
			h: 132.74,
			l: 132.72,
			t: 1655158200000,
			n: 32
		},
		{
			v: 649,
			vw: 132.7465,
			o: 132.7499,
			c: 132.75,
			h: 132.75,
			l: 132.7499,
			t: 1655158260000,
			n: 31
		},
		{
			v: 3002,
			vw: 132.7218,
			o: 132.75,
			c: 132.74,
			h: 132.78,
			l: 132.7,
			t: 1655158380000,
			n: 49
		},
		{
			v: 5773,
			vw: 132.7588,
			o: 132.74,
			c: 132.78,
			h: 132.8,
			l: 132.74,
			t: 1655158440000,
			n: 38
		},
		{
			v: 5504,
			vw: 132.7102,
			o: 132.7,
			c: 132.75,
			h: 132.75,
			l: 132.7,
			t: 1655158500000,
			n: 58
		},
		{
			v: 2026,
			vw: 132.7497,
			o: 132.75,
			c: 132.76,
			h: 132.76,
			l: 132.75,
			t: 1655158560000,
			n: 44
		},
		{
			v: 738,
			vw: 132.7587,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655158620000,
			n: 29
		},
		{
			v: 3450,
			vw: 132.523,
			o: 132.54,
			c: 132.3,
			h: 132.66,
			l: 132.3,
			t: 1655158680000,
			n: 100
		},
		{
			v: 2299,
			vw: 132.6303,
			o: 132.7,
			c: 132.75,
			h: 132.75,
			l: 132.57,
			t: 1655158740000,
			n: 46
		},
		{
			v: 1548,
			vw: 132.6324,
			o: 132.52,
			c: 132.68,
			h: 132.68,
			l: 132.52,
			t: 1655158800000,
			n: 36
		},
		{
			v: 883,
			vw: 132.7288,
			o: 132.75,
			c: 132.72,
			h: 132.75,
			l: 132.72,
			t: 1655158860000,
			n: 34
		},
		{
			v: 5368,
			vw: 132.8089,
			o: 132.74,
			c: 132.87,
			h: 132.87,
			l: 132.74,
			t: 1655158920000,
			n: 74
		},
		{
			v: 4991,
			vw: 132.8547,
			o: 132.87,
			c: 132.87,
			h: 132.87,
			l: 132.8,
			t: 1655158980000,
			n: 45
		},
		{
			v: 7045,
			vw: 132.8634,
			o: 132.83,
			c: 132.87,
			h: 132.87,
			l: 132.7701,
			t: 1655159040000,
			n: 42
		},
		{
			v: 2004,
			vw: 132.8191,
			o: 132.8,
			c: 132.82,
			h: 132.85,
			l: 132.8,
			t: 1655159100000,
			n: 52
		},
		{
			v: 1076,
			vw: 132.8131,
			o: 132.84,
			c: 132.78,
			h: 132.84,
			l: 132.78,
			t: 1655159160000,
			n: 36
		},
		{
			v: 3131,
			vw: 132.7176,
			o: 132.74,
			c: 132.69,
			h: 132.74,
			l: 132.69,
			t: 1655159220000,
			n: 50
		},
		{
			v: 1816,
			vw: 132.7433,
			o: 132.74,
			c: 132.72,
			h: 132.79,
			l: 132.72,
			t: 1655159280000,
			n: 26
		},
		{
			v: 520,
			vw: 132.7731,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655159340000,
			n: 34
		},
		{
			v: 1004,
			vw: 132.7865,
			o: 132.78,
			c: 132.79,
			h: 132.79,
			l: 132.78,
			t: 1655159400000,
			n: 46
		},
		{
			v: 519,
			vw: 132.7303,
			o: 132.74,
			c: 132.74,
			h: 132.74,
			l: 132.74,
			t: 1655159520000,
			n: 36
		},
		{
			v: 773,
			vw: 132.7113,
			o: 132.7,
			c: 132.69,
			h: 132.74,
			l: 132.68,
			t: 1655159580000,
			n: 38
		},
		{
			v: 1469,
			vw: 132.636,
			o: 132.6,
			c: 132.67,
			h: 132.68,
			l: 132.52,
			t: 1655159640000,
			n: 46
		},
		{
			v: 1424,
			vw: 132.5523,
			o: 132.55,
			c: 132.51,
			h: 132.55,
			l: 132.51,
			t: 1655159700000,
			n: 37
		},
		{
			v: 3575,
			vw: 132.5478,
			o: 132.52,
			c: 132.69,
			h: 132.6999,
			l: 132.4,
			t: 1655159760000,
			n: 72
		},
		{
			v: 1530,
			vw: 132.6892,
			o: 132.69,
			c: 132.69,
			h: 132.7,
			l: 132.69,
			t: 1655159820000,
			n: 35
		},
		{
			v: 213,
			vw: 132.6506,
			o: 132.68,
			c: 132.68,
			h: 132.68,
			l: 132.68,
			t: 1655159940000,
			n: 27
		},
		{
			v: 680,
			vw: 132.6421,
			o: 132.66,
			c: 132.61,
			h: 132.66,
			l: 132.61,
			t: 1655160060000,
			n: 41
		},
		{
			v: 1112,
			vw: 132.6543,
			o: 132.61,
			c: 132.61,
			h: 132.69,
			l: 132.61,
			t: 1655160120000,
			n: 42
		},
		{
			v: 554,
			vw: 132.6212,
			o: 132.61,
			c: 132.61,
			h: 132.61,
			l: 132.61,
			t: 1655160180000,
			n: 34
		},
		{
			v: 476,
			vw: 132.6185,
			o: 132.61,
			c: 132.61,
			h: 132.61,
			l: 132.61,
			t: 1655160240000,
			n: 36
		},
		{
			v: 731,
			vw: 132.6351,
			o: 132.66,
			c: 132.6,
			h: 132.66,
			l: 132.6,
			t: 1655160420000,
			n: 41
		},
		{
			v: 594,
			vw: 132.6511,
			o: 132.69,
			c: 132.69,
			h: 132.69,
			l: 132.69,
			t: 1655160480000,
			n: 51
		},
		{
			v: 641,
			vw: 132.6164,
			o: 132.6,
			c: 132.6,
			h: 132.6001,
			l: 132.6,
			t: 1655160540000,
			n: 36
		},
		{
			v: 2205,
			vw: 132.5622,
			o: 132.6,
			c: 132.53,
			h: 132.68,
			l: 132.49,
			t: 1655160600000,
			n: 65
		},
		{
			v: 1246,
			vw: 132.5401,
			o: 132.51,
			c: 132.6,
			h: 132.6,
			l: 132.51,
			t: 1655160660000,
			n: 30
		},
		{
			v: 687,
			vw: 132.5199,
			o: 132.51,
			c: 132.5,
			h: 132.51,
			l: 132.5,
			t: 1655160720000,
			n: 30
		},
		{
			v: 2484,
			vw: 132.6017,
			o: 132.6,
			c: 132.6,
			h: 132.65,
			l: 132.59,
			t: 1655160780000,
			n: 32
		},
		{
			v: 882,
			vw: 132.5854,
			o: 132.5501,
			c: 132.6,
			h: 132.6,
			l: 132.5501,
			t: 1655160840000,
			n: 26
		},
		{
			v: 354,
			vw: 132.6341,
			o: 132.64,
			c: 132.64,
			h: 132.64,
			l: 132.64,
			t: 1655160900000,
			n: 20
		},
		{
			v: 1208,
			vw: 132.5872,
			o: 132.59,
			c: 132.6,
			h: 132.6,
			l: 132.59,
			t: 1655160960000,
			n: 42
		},
		{
			v: 475,
			vw: 132.5604,
			o: 132.53,
			c: 132.6,
			h: 132.6,
			l: 132.53,
			t: 1655161020000,
			n: 23
		},
		{
			v: 9927,
			vw: 132.5474,
			o: 132.55,
			c: 132.53,
			h: 132.55,
			l: 132.53,
			t: 1655161080000,
			n: 40
		},
		{
			v: 4015,
			vw: 132.5006,
			o: 132.53,
			c: 132.5,
			h: 132.53,
			l: 132.5,
			t: 1655161140000,
			n: 46
		},
		{
			v: 3169,
			vw: 132.4976,
			o: 132.5,
			c: 132.5,
			h: 132.5,
			l: 132.5,
			t: 1655161200000,
			n: 32
		},
		{
			v: 1544,
			vw: 132.4561,
			o: 132.45,
			c: 132.45,
			h: 132.45,
			l: 132.45,
			t: 1655161260000,
			n: 24
		},
		{
			v: 1924,
			vw: 132.4553,
			o: 132.45,
			c: 132.45,
			h: 132.45,
			l: 132.45,
			t: 1655161320000,
			n: 22
		},
		{
			v: 4365,
			vw: 132.4573,
			o: 132.5,
			c: 132.45,
			h: 132.5,
			l: 132.45,
			t: 1655161380000,
			n: 41
		},
		{
			v: 1420,
			vw: 132.4547,
			o: 132.45,
			c: 132.4899,
			h: 132.4899,
			l: 132.45,
			t: 1655161440000,
			n: 32
		},
		{
			v: 255,
			vw: 132.5066,
			o: 132.52,
			c: 132.52,
			h: 132.52,
			l: 132.52,
			t: 1655161620000,
			n: 23
		},
		{
			v: 249,
			vw: 132.5131,
			o: 132.52,
			c: 132.52,
			h: 132.52,
			l: 132.52,
			t: 1655161680000,
			n: 30
		},
		{
			v: 161,
			vw: 132.506,
			o: 132.51,
			c: 132.51,
			h: 132.51,
			l: 132.51,
			t: 1655161740000,
			n: 19
		},
		{
			v: 349,
			vw: 132.494,
			o: 132.49,
			c: 132.49,
			h: 132.49,
			l: 132.49,
			t: 1655161800000,
			n: 30
		},
		{
			v: 2573,
			vw: 132.4478,
			o: 132.47,
			c: 132.41,
			h: 132.47,
			l: 132.41,
			t: 1655161860000,
			n: 40
		},
		{
			v: 460,
			vw: 132.4107,
			o: 132.41,
			c: 132.41,
			h: 132.41,
			l: 132.41,
			t: 1655161920000,
			n: 37
		},
		{
			v: 1282,
			vw: 132.4272,
			o: 132.44,
			c: 132.4,
			h: 132.44,
			l: 132.4,
			t: 1655161980000,
			n: 35
		},
		{
			v: 2242,
			vw: 132.4273,
			o: 132.4,
			c: 132.44,
			h: 132.44,
			l: 132.4,
			t: 1655162040000,
			n: 38
		},
		{
			v: 2566,
			vw: 132.3974,
			o: 132.42,
			c: 132.32,
			h: 132.44,
			l: 132.32,
			t: 1655162100000,
			n: 45
		},
		{
			v: 2550,
			vw: 132.3503,
			o: 132.35,
			c: 132.35,
			h: 132.35,
			l: 132.35,
			t: 1655162220000,
			n: 37
		},
		{
			v: 688,
			vw: 132.3065,
			o: 132.3,
			c: 132.29,
			h: 132.31,
			l: 132.29,
			t: 1655162280000,
			n: 30
		},
		{
			v: 1743,
			vw: 132.2616,
			o: 132.27,
			c: 132.27,
			h: 132.27,
			l: 132.25,
			t: 1655162340000,
			n: 31
		},
		{
			v: 2289,
			vw: 132.2703,
			o: 132.27,
			c: 132.34,
			h: 132.34,
			l: 132.25,
			t: 1655162400000,
			n: 41
		},
		{
			v: 2243,
			vw: 132.2837,
			o: 132.28,
			c: 132.27,
			h: 132.28,
			l: 132.27,
			t: 1655162520000,
			n: 47
		},
		{
			v: 1199,
			vw: 132.2708,
			o: 132.26,
			c: 132.25,
			h: 132.26,
			l: 132.25,
			t: 1655162640000,
			n: 42
		},
		{
			v: 657,
			vw: 132.2653,
			o: 132.26,
			c: 132.26,
			h: 132.26,
			l: 132.26,
			t: 1655162760000,
			n: 40
		},
		{
			v: 1214,
			vw: 132.253,
			o: 132.25,
			c: 132.25,
			h: 132.25,
			l: 132.25,
			t: 1655162820000,
			n: 27
		},
		{
			v: 994,
			vw: 132.2858,
			o: 132.29,
			c: 132.29,
			h: 132.29,
			l: 132.29,
			t: 1655162880000,
			n: 43
		},
		{
			v: 1121,
			vw: 132.2821,
			o: 132.28,
			c: 132.27,
			h: 132.29,
			l: 132.27,
			t: 1655162940000,
			n: 25
		},
		{
			v: 555,
			vw: 132.2897,
			o: 132.3,
			c: 132.26,
			h: 132.3,
			l: 132.26,
			t: 1655163000000,
			n: 19
		},
		{
			v: 3126,
			vw: 132.2727,
			o: 132.3,
			c: 132.3,
			h: 132.3,
			l: 132.25,
			t: 1655163060000,
			n: 43
		},
		{
			v: 2589,
			vw: 132.2962,
			o: 132.29,
			c: 132.3,
			h: 132.3,
			l: 132.28,
			t: 1655163180000,
			n: 31
		},
		{
			v: 1734,
			vw: 132.3083,
			o: 132.33,
			c: 132.3,
			h: 132.33,
			l: 132.3,
			t: 1655163240000,
			n: 45
		},
		{
			v: 340,
			vw: 132.2962,
			o: 132.3,
			c: 132.3,
			h: 132.3,
			l: 132.3,
			t: 1655163300000,
			n: 27
		},
		{
			v: 13694,
			vw: 132.2515,
			o: 132.3,
			c: 132.25,
			h: 132.3,
			l: 132.25,
			t: 1655163360000,
			n: 37
		},
		{
			v: 5959,
			vw: 132.2468,
			o: 132.25,
			c: 132.21,
			h: 132.25,
			l: 132.21,
			t: 1655163420000,
			n: 59
		},
		{
			v: 1101,
			vw: 132.1592,
			o: 132.11,
			c: 132.11,
			h: 132.11,
			l: 132.11,
			t: 1655163480000,
			n: 52
		},
		{
			v: 2404,
			vw: 132.1446,
			o: 132.13,
			c: 132.13,
			h: 132.18,
			l: 132.13,
			t: 1655163540000,
			n: 22
		},
		{
			v: 1057,
			vw: 132.1307,
			o: 132.13,
			c: 132.13,
			h: 132.13,
			l: 132.13,
			t: 1655163600000,
			n: 33
		},
		{
			v: 2681,
			vw: 132.106,
			o: 132.12,
			c: 132.07,
			h: 132.18,
			l: 132.07,
			t: 1655163660000,
			n: 54
		},
		{
			v: 3054,
			vw: 132.0581,
			o: 132.06,
			c: 132.06,
			h: 132.06,
			l: 132.0501,
			t: 1655163720000,
			n: 27
		},
		{
			v: 3574,
			vw: 132.0747,
			o: 132.07,
			c: 132.06,
			h: 132.11,
			l: 132.06,
			t: 1655163780000,
			n: 43
		},
		{
			v: 4402,
			vw: 132.0868,
			o: 132.1,
			c: 132.06,
			h: 132.11,
			l: 132.06,
			t: 1655163840000,
			n: 35
		},
		{
			v: 4308,
			vw: 132.0507,
			o: 132.06,
			c: 132.06,
			h: 132.06,
			l: 132.03,
			t: 1655163900000,
			n: 43
		},
		{
			v: 1514,
			vw: 132.0559,
			o: 132.06,
			c: 132.06,
			h: 132.06,
			l: 132.05,
			t: 1655163960000,
			n: 34
		},
		{
			v: 3132,
			vw: 132.0679,
			o: 132.06,
			c: 132.11,
			h: 132.11,
			l: 132.03,
			t: 1655164020000,
			n: 50
		},
		{
			v: 651,
			vw: 132.1658,
			o: 132.17,
			c: 132.17,
			h: 132.17,
			l: 132.17,
			t: 1655164080000,
			n: 12
		},
		{
			v: 515,
			vw: 132.1471,
			o: 132.17,
			c: 132.17,
			h: 132.17,
			l: 132.1,
			t: 1655164140000,
			n: 33
		},
		{
			v: 884,
			vw: 132.1383,
			o: 132.15,
			c: 132.1,
			h: 132.15,
			l: 132.1,
			t: 1655164200000,
			n: 44
		},
		{
			v: 5170,
			vw: 132.1881,
			o: 132.1899,
			c: 132.1899,
			h: 132.1899,
			l: 132.1899,
			t: 1655164260000,
			n: 37
		},
		{
			v: 1012,
			vw: 132.1778,
			o: 132.18,
			c: 132.18,
			h: 132.18,
			l: 132.18,
			t: 1655164320000,
			n: 24
		},
		{
			v: 996,
			vw: 132.1845,
			o: 132.19,
			c: 132.18,
			h: 132.19,
			l: 132.18,
			t: 1655164380000,
			n: 35
		},
		{
			v: 1934,
			vw: 132.1488,
			o: 132.16,
			c: 132.16,
			h: 132.16,
			l: 132.1,
			t: 1655164440000,
			n: 61
		},
		{
			v: 915,
			vw: 132.1136,
			o: 132.15,
			c: 132.08,
			h: 132.15,
			l: 132.08,
			t: 1655164500000,
			n: 42
		},
		{
			v: 5758,
			vw: 132.2021,
			o: 132.1,
			c: 132.16,
			h: 132.35,
			l: 132.1,
			t: 1655164560000,
			n: 52
		},
		{
			v: 1844,
			vw: 132.1796,
			o: 132.19,
			c: 132.1899,
			h: 132.19,
			l: 132.1,
			t: 1655164620000,
			n: 53
		},
		{
			v: 3108,
			vw: 132.2409,
			o: 132.19,
			c: 132.25,
			h: 132.25,
			l: 132.19,
			t: 1655164680000,
			n: 46
		},
		{
			v: 4556,
			vw: 132.3733,
			o: 132.28,
			c: 132.4,
			h: 132.4,
			l: 132.28,
			t: 1655164740000,
			n: 56
		},
		{
			v: 3226,
			vw: 133.6135,
			o: 133.01,
			c: 133.78,
			h: 134,
			l: 133.01,
			t: 1655193600000,
			n: 98
		},
		{
			v: 2612,
			vw: 133.6865,
			o: 133.68,
			c: 133.65,
			h: 133.68,
			l: 133.65,
			t: 1655193660000,
			n: 94
		},
		{
			v: 3165,
			vw: 133.548,
			o: 133.59,
			c: 133.57,
			h: 133.59,
			l: 133.5,
			t: 1655193780000,
			n: 94
		},
		{
			v: 3534,
			vw: 133.649,
			o: 133.61,
			c: 133.65,
			h: 133.68,
			l: 133.61,
			t: 1655193840000,
			n: 64
		},
		{
			v: 2352,
			vw: 133.4991,
			o: 133.5,
			c: 133.45,
			h: 133.51,
			l: 133.45,
			t: 1655193960000,
			n: 83
		},
		{
			v: 2716,
			vw: 133.474,
			o: 133.45,
			c: 133.5,
			h: 133.5,
			l: 133.45,
			t: 1655194020000,
			n: 49
		},
		{
			v: 545,
			vw: 133.503,
			o: 133.48,
			c: 133.48,
			h: 133.48,
			l: 133.48,
			t: 1655194080000,
			n: 25
		},
		{
			v: 401,
			vw: 133.5674,
			o: 133.57,
			c: 133.57,
			h: 133.57,
			l: 133.57,
			t: 1655194200000,
			n: 21
		},
		{
			v: 4234,
			vw: 133.5669,
			o: 133.57,
			c: 133.59,
			h: 133.6,
			l: 133.55,
			t: 1655194260000,
			n: 39
		},
		{
			v: 1223,
			vw: 133.6108,
			o: 133.6,
			c: 133.64,
			h: 133.64,
			l: 133.6,
			t: 1655194320000,
			n: 44
		},
		{
			v: 880,
			vw: 133.5642,
			o: 133.54,
			c: 133.54,
			h: 133.54,
			l: 133.54,
			t: 1655194620000,
			n: 33
		},
		{
			v: 2174,
			vw: 133.5346,
			o: 133.51,
			c: 133.54,
			h: 133.54,
			l: 133.51,
			t: 1655194920000,
			n: 42
		},
		{
			v: 2094,
			vw: 133.5788,
			o: 133.58,
			c: 133.55,
			h: 133.61,
			l: 133.55,
			t: 1655194980000,
			n: 45
		},
		{
			v: 908,
			vw: 133.6009,
			o: 133.59,
			c: 133.6,
			h: 133.61,
			l: 133.59,
			t: 1655195040000,
			n: 9
		},
		{
			v: 3538,
			vw: 133.6198,
			o: 133.59,
			c: 133.65,
			h: 133.65,
			l: 133.59,
			t: 1655195100000,
			n: 35
		},
		{
			v: 1939,
			vw: 133.6965,
			o: 133.69,
			c: 133.7,
			h: 133.7,
			l: 133.69,
			t: 1655195160000,
			n: 44
		},
		{
			v: 1494,
			vw: 133.7018,
			o: 133.7,
			c: 133.7,
			h: 133.7,
			l: 133.7,
			t: 1655195220000,
			n: 40
		},
		{
			v: 1374,
			vw: 133.6546,
			o: 133.65,
			c: 133.65,
			h: 133.65,
			l: 133.65,
			t: 1655195280000,
			n: 24
		},
		{
			v: 883,
			vw: 133.6825,
			o: 133.68,
			c: 133.68,
			h: 133.68,
			l: 133.68,
			t: 1655195340000,
			n: 26
		},
		{
			v: 1604,
			vw: 133.7001,
			o: 133.71,
			c: 133.7,
			h: 133.71,
			l: 133.7,
			t: 1655195400000,
			n: 29
		},
		{
			v: 2554,
			vw: 133.639,
			o: 133.66,
			c: 133.65,
			h: 133.66,
			l: 133.61,
			t: 1655195520000,
			n: 67
		},
		{
			v: 1778,
			vw: 133.7019,
			o: 133.71,
			c: 133.72,
			h: 133.72,
			l: 133.7,
			t: 1655195580000,
			n: 43
		},
		{
			v: 284,
			vw: 133.7636,
			o: 133.78,
			c: 133.78,
			h: 133.78,
			l: 133.78,
			t: 1655195640000,
			n: 9
		},
		{
			v: 258,
			vw: 133.6719,
			o: 133.67,
			c: 133.67,
			h: 133.67,
			l: 133.67,
			t: 1655195760000,
			n: 14
		},
		{
			v: 606,
			vw: 133.6153,
			o: 133.61,
			c: 133.6,
			h: 133.61,
			l: 133.6,
			t: 1655195940000,
			n: 34
		},
		{
			v: 4407,
			vw: 133.5986,
			o: 133.6,
			c: 133.6,
			h: 133.6,
			l: 133.6,
			t: 1655196000000,
			n: 75
		},
		{
			v: 411,
			vw: 133.5543,
			o: 133.55,
			c: 133.55,
			h: 133.55,
			l: 133.55,
			t: 1655196060000,
			n: 20
		},
		{
			v: 1587,
			vw: 133.5693,
			o: 133.57,
			c: 133.57,
			h: 133.6,
			l: 133.55,
			t: 1655196360000,
			n: 32
		},
		{
			v: 1850,
			vw: 133.5265,
			o: 133.53,
			c: 133.52,
			h: 133.53,
			l: 133.52,
			t: 1655196480000,
			n: 33
		},
		{
			v: 1152,
			vw: 133.4691,
			o: 133.51,
			c: 133.41,
			h: 133.51,
			l: 133.41,
			t: 1655196540000,
			n: 91
		},
		{
			v: 679,
			vw: 133.3988,
			o: 133.4,
			c: 133.4,
			h: 133.4,
			l: 133.4,
			t: 1655196600000,
			n: 24
		},
		{
			v: 348,
			vw: 133.389,
			o: 133.39,
			c: 133.39,
			h: 133.39,
			l: 133.39,
			t: 1655196660000,
			n: 14
		},
		{
			v: 418,
			vw: 133.3023,
			o: 133.29,
			c: 133.29,
			h: 133.29,
			l: 133.29,
			t: 1655196720000,
			n: 22
		},
		{
			v: 608,
			vw: 133.3898,
			o: 133.39,
			c: 133.4,
			h: 133.4,
			l: 133.39,
			t: 1655196780000,
			n: 17
		},
		{
			v: 644,
			vw: 133.3514,
			o: 133.35,
			c: 133.35,
			h: 133.35,
			l: 133.35,
			t: 1655196840000,
			n: 11
		},
		{
			v: 2370,
			vw: 133.2768,
			o: 133.28,
			c: 133.28,
			h: 133.28,
			l: 133.27,
			t: 1655196960000,
			n: 51
		},
		{
			v: 1720,
			vw: 133.2175,
			o: 133.22,
			c: 133.2,
			h: 133.22,
			l: 133.2,
			t: 1655197020000,
			n: 41
		},
		{
			v: 800,
			vw: 133.0325,
			o: 133.02,
			c: 133.02,
			h: 133.02,
			l: 133.02,
			t: 1655197080000,
			n: 26
		},
		{
			v: 684,
			vw: 133.0957,
			o: 133.11,
			c: 133.1,
			h: 133.11,
			l: 133.1,
			t: 1655197140000,
			n: 17
		},
		{
			v: 1068,
			vw: 133.1483,
			o: 133.15,
			c: 133.11,
			h: 133.15,
			l: 133.11,
			t: 1655197200000,
			n: 29
		},
		{
			v: 709,
			vw: 133.2587,
			o: 133.29,
			c: 133.28,
			h: 133.29,
			l: 133.28,
			t: 1655197260000,
			n: 27
		},
		{
			v: 1873,
			vw: 133.2887,
			o: 133.29,
			c: 133.29,
			h: 133.3,
			l: 133.28,
			t: 1655197320000,
			n: 23
		},
		{
			v: 814,
			vw: 133.2053,
			o: 133.22,
			c: 133.2,
			h: 133.22,
			l: 133.2,
			t: 1655197380000,
			n: 23
		},
		{
			v: 333,
			vw: 133.1728,
			o: 133.17,
			c: 133.17,
			h: 133.17,
			l: 133.17,
			t: 1655197440000,
			n: 16
		},
		{
			v: 267,
			vw: 133.1766,
			o: 133.17,
			c: 133.17,
			h: 133.17,
			l: 133.17,
			t: 1655197500000,
			n: 9
		},
		{
			v: 2533,
			vw: 133.1319,
			o: 133.17,
			c: 133.17,
			h: 133.17,
			l: 133.08,
			t: 1655197560000,
			n: 34
		},
		{
			v: 516,
			vw: 133.1906,
			o: 133.18,
			c: 133.2,
			h: 133.2,
			l: 133.18,
			t: 1655197620000,
			n: 14
		},
		{
			v: 286,
			vw: 133.2093,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655197860000,
			n: 14
		},
		{
			v: 1963,
			vw: 133.1511,
			o: 133.24,
			c: 133.12,
			h: 133.24,
			l: 133.1,
			t: 1655197920000,
			n: 63
		},
		{
			v: 1194,
			vw: 133.0904,
			o: 133.09,
			c: 133.09,
			h: 133.09,
			l: 133.09,
			t: 1655197980000,
			n: 20
		},
		{
			v: 451,
			vw: 133.0576,
			o: 133.05,
			c: 133.05,
			h: 133.05,
			l: 133.05,
			t: 1655198100000,
			n: 32
		},
		{
			v: 716,
			vw: 133.0569,
			o: 133.03,
			c: 133.09,
			h: 133.09,
			l: 133.03,
			t: 1655198160000,
			n: 30
		},
		{
			v: 484,
			vw: 133.1752,
			o: 133.18,
			c: 133.18,
			h: 133.18,
			l: 133.18,
			t: 1655198340000,
			n: 14
		},
		{
			v: 469,
			vw: 133.26,
			o: 133.28,
			c: 133.28,
			h: 133.28,
			l: 133.28,
			t: 1655198580000,
			n: 16
		},
		{
			v: 636,
			vw: 133.3016,
			o: 133.3,
			c: 133.3,
			h: 133.3,
			l: 133.3,
			t: 1655198640000,
			n: 30
		},
		{
			v: 831,
			vw: 133.1775,
			o: 133.17,
			c: 133.17,
			h: 133.17,
			l: 133.17,
			t: 1655198820000,
			n: 22
		},
		{
			v: 1168,
			vw: 133.1019,
			o: 133.13,
			c: 133.13,
			h: 133.13,
			l: 133.13,
			t: 1655198880000,
			n: 55
		},
		{
			v: 938,
			vw: 133.0431,
			o: 133.05,
			c: 133.04,
			h: 133.05,
			l: 133.04,
			t: 1655199120000,
			n: 18
		},
		{
			v: 3379,
			vw: 133.0281,
			o: 133.05,
			c: 133,
			h: 133.05,
			l: 133,
			t: 1655199240000,
			n: 133
		},
		{
			v: 243,
			vw: 133.2001,
			o: 133.18,
			c: 133.18,
			h: 133.18,
			l: 133.18,
			t: 1655200260000,
			n: 18
		},
		{
			v: 630,
			vw: 133.2241,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655200320000,
			n: 20
		},
		{
			v: 157,
			vw: 133.272,
			o: 133.27,
			c: 133.27,
			h: 133.27,
			l: 133.27,
			t: 1655200380000,
			n: 7
		},
		{
			v: 769,
			vw: 133.1127,
			o: 133.13,
			c: 133.1,
			h: 133.13,
			l: 133.1,
			t: 1655200620000,
			n: 38
		},
		{
			v: 251,
			vw: 133.15,
			o: 133.15,
			c: 133.15,
			h: 133.15,
			l: 133.15,
			t: 1655200680000,
			n: 2
		},
		{
			v: 5685,
			vw: 132.9878,
			o: 133,
			c: 132.87,
			h: 133.01,
			l: 132.84,
			t: 1655200860000,
			n: 100
		},
		{
			v: 1400,
			vw: 132.7688,
			o: 132.86,
			c: 132.71,
			h: 132.86,
			l: 132.7,
			t: 1655200920000,
			n: 39
		},
		{
			v: 1809,
			vw: 132.6927,
			o: 132.7,
			c: 132.71,
			h: 132.71,
			l: 132.64,
			t: 1655200980000,
			n: 30
		},
		{
			v: 439,
			vw: 132.5986,
			o: 132.61,
			c: 132.56,
			h: 132.61,
			l: 132.56,
			t: 1655201040000,
			n: 15
		},
		{
			v: 739,
			vw: 132.6481,
			o: 132.58,
			c: 132.71,
			h: 132.71,
			l: 132.58,
			t: 1655201100000,
			n: 22
		},
		{
			v: 406,
			vw: 132.7605,
			o: 132.8,
			c: 132.8,
			h: 132.8,
			l: 132.8,
			t: 1655201160000,
			n: 17
		},
		{
			v: 764,
			vw: 132.7602,
			o: 132.8,
			c: 132.69,
			h: 132.8,
			l: 132.69,
			t: 1655201280000,
			n: 23
		},
		{
			v: 292,
			vw: 132.7271,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655201580000,
			n: 16
		},
		{
			v: 572,
			vw: 132.6821,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655201700000,
			n: 32
		},
		{
			v: 2740,
			vw: 132.5454,
			o: 132.6,
			c: 132.5,
			h: 132.6,
			l: 132.5,
			t: 1655201760000,
			n: 81
		},
		{
			v: 612,
			vw: 132.5094,
			o: 132.51,
			c: 132.5,
			h: 132.51,
			l: 132.5,
			t: 1655201820000,
			n: 19
		},
		{
			v: 3483,
			vw: 132.5009,
			o: 132.52,
			c: 132.5,
			h: 132.52,
			l: 132.5,
			t: 1655202000000,
			n: 43
		},
		{
			v: 1952,
			vw: 132.7308,
			o: 132.62,
			c: 132.78,
			h: 132.78,
			l: 132.62,
			t: 1655202120000,
			n: 25
		},
		{
			v: 416,
			vw: 132.6302,
			o: 132.65,
			c: 132.64,
			h: 132.65,
			l: 132.64,
			t: 1655202180000,
			n: 17
		},
		{
			v: 135,
			vw: 132.6478,
			o: 132.65,
			c: 132.65,
			h: 132.65,
			l: 132.65,
			t: 1655202420000,
			n: 5
		},
		{
			v: 1117,
			vw: 132.5767,
			o: 132.6,
			c: 132.52,
			h: 132.6,
			l: 132.52,
			t: 1655202480000,
			n: 45
		},
		{
			v: 3060,
			vw: 132.6987,
			o: 132.59,
			c: 132.82,
			h: 132.82,
			l: 132.59,
			t: 1655202600000,
			n: 59
		},
		{
			v: 879,
			vw: 132.8853,
			o: 132.9,
			c: 132.91,
			h: 132.91,
			l: 132.9,
			t: 1655202660000,
			n: 18
		},
		{
			v: 268,
			vw: 132.8764,
			o: 132.9,
			c: 132.9,
			h: 132.9,
			l: 132.9,
			t: 1655202720000,
			n: 10
		},
		{
			v: 138,
			vw: 132.791,
			o: 132.79,
			c: 132.79,
			h: 132.79,
			l: 132.79,
			t: 1655202780000,
			n: 6
		},
		{
			v: 804,
			vw: 132.8362,
			o: 132.83,
			c: 132.84,
			h: 132.84,
			l: 132.83,
			t: 1655202840000,
			n: 7
		},
		{
			v: 606,
			vw: 132.8963,
			o: 132.9,
			c: 132.9,
			h: 132.9,
			l: 132.9,
			t: 1655203020000,
			n: 11
		},
		{
			v: 257,
			vw: 132.8213,
			o: 132.85,
			c: 132.85,
			h: 132.85,
			l: 132.85,
			t: 1655203080000,
			n: 28
		},
		{
			v: 1176,
			vw: 132.7985,
			o: 132.8,
			c: 132.79,
			h: 132.8,
			l: 132.79,
			t: 1655203320000,
			n: 14
		},
		{
			v: 2126,
			vw: 133.0221,
			o: 132.95,
			c: 133.1,
			h: 133.1,
			l: 132.95,
			t: 1655203500000,
			n: 51
		},
		{
			v: 3938,
			vw: 133.1719,
			o: 133.05,
			c: 133.27,
			h: 133.28,
			l: 133.05,
			t: 1655203560000,
			n: 54
		},
		{
			v: 392,
			vw: 133.2013,
			o: 133.22,
			c: 133.22,
			h: 133.22,
			l: 133.22,
			t: 1655203920000,
			n: 10
		},
		{
			v: 673,
			vw: 133.0781,
			o: 133.09,
			c: 133.09,
			h: 133.09,
			l: 133.09,
			t: 1655204040000,
			n: 25
		},
		{
			v: 260,
			vw: 133.0542,
			o: 133.13,
			c: 133.13,
			h: 133.13,
			l: 133.13,
			t: 1655204100000,
			n: 36
		},
		{
			v: 8651,
			vw: 132.9571,
			o: 133,
			c: 132.89,
			h: 133.06,
			l: 132.83,
			t: 1655204400000,
			n: 176
		},
		{
			v: 3811,
			vw: 132.7897,
			o: 132.8,
			c: 132.77,
			h: 132.82,
			l: 132.75,
			t: 1655204460000,
			n: 71
		},
		{
			v: 4025,
			vw: 132.7513,
			o: 132.79,
			c: 132.66,
			h: 132.8,
			l: 132.66,
			t: 1655204520000,
			n: 92
		},
		{
			v: 2408,
			vw: 132.6668,
			o: 132.7,
			c: 132.65,
			h: 132.72,
			l: 132.65,
			t: 1655204580000,
			n: 45
		},
		{
			v: 1814,
			vw: 132.7128,
			o: 132.71,
			c: 132.71,
			h: 132.73,
			l: 132.71,
			t: 1655204640000,
			n: 29
		},
		{
			v: 3442,
			vw: 132.6125,
			o: 132.7,
			c: 132.55,
			h: 132.7,
			l: 132.55,
			t: 1655204760000,
			n: 97
		},
		{
			v: 3181,
			vw: 132.5972,
			o: 132.65,
			c: 132.63,
			h: 132.65,
			l: 132.56,
			t: 1655204820000,
			n: 53
		},
		{
			v: 1793,
			vw: 132.6076,
			o: 132.63,
			c: 132.59,
			h: 132.65,
			l: 132.56,
			t: 1655204880000,
			n: 50
		},
		{
			v: 1560,
			vw: 132.5691,
			o: 132.56,
			c: 132.63,
			h: 132.63,
			l: 132.53,
			t: 1655204940000,
			n: 51
		},
		{
			v: 3405,
			vw: 132.5933,
			o: 132.56,
			c: 132.7,
			h: 132.7,
			l: 132.54,
			t: 1655205000000,
			n: 50
		},
		{
			v: 2924,
			vw: 132.6167,
			o: 132.65,
			c: 132.58,
			h: 132.65,
			l: 132.58,
			t: 1655205060000,
			n: 27
		},
		{
			v: 3028,
			vw: 132.6343,
			o: 132.58,
			c: 132.7,
			h: 132.7,
			l: 132.55,
			t: 1655205120000,
			n: 68
		},
		{
			v: 1699,
			vw: 132.7188,
			o: 132.7,
			c: 132.74,
			h: 132.74,
			l: 132.7,
			t: 1655205180000,
			n: 37
		},
		{
			v: 594,
			vw: 132.7306,
			o: 132.72,
			c: 132.74,
			h: 132.74,
			l: 132.72,
			t: 1655205240000,
			n: 11
		},
		{
			v: 875,
			vw: 132.7166,
			o: 132.71,
			c: 132.72,
			h: 132.72,
			l: 132.7,
			t: 1655205360000,
			n: 27
		},
		{
			v: 3357,
			vw: 132.7296,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.7,
			t: 1655205480000,
			n: 68
		},
		{
			v: 1070,
			vw: 132.7027,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655205540000,
			n: 28
		},
		{
			v: 852,
			vw: 132.7195,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655205600000,
			n: 24
		},
		{
			v: 1850,
			vw: 132.7901,
			o: 132.75,
			c: 132.8,
			h: 132.8,
			l: 132.75,
			t: 1655205660000,
			n: 45
		},
		{
			v: 1159,
			vw: 132.7814,
			o: 132.81,
			c: 132.77,
			h: 132.81,
			l: 132.77,
			t: 1655205720000,
			n: 26
		},
		{
			v: 520,
			vw: 132.8028,
			o: 132.8,
			c: 132.8,
			h: 132.8,
			l: 132.8,
			t: 1655205780000,
			n: 17
		},
		{
			v: 508,
			vw: 132.8077,
			o: 132.8,
			c: 132.81,
			h: 132.81,
			l: 132.8,
			t: 1655205900000,
			n: 24
		},
		{
			v: 992,
			vw: 132.7746,
			o: 132.79,
			c: 132.75,
			h: 132.79,
			l: 132.75,
			t: 1655206140000,
			n: 18
		},
		{
			v: 1321,
			vw: 132.756,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655206260000,
			n: 20
		},
		{
			v: 502,
			vw: 132.7605,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655206320000,
			n: 23
		},
		{
			v: 3595,
			vw: 132.6974,
			o: 132.71,
			c: 132.7,
			h: 132.71,
			l: 132.7,
			t: 1655206380000,
			n: 72
		},
		{
			v: 498,
			vw: 132.6537,
			o: 132.65,
			c: 132.65,
			h: 132.65,
			l: 132.65,
			t: 1655206440000,
			n: 13
		},
		{
			v: 2077,
			vw: 132.6033,
			o: 132.61,
			c: 132.62,
			h: 132.62,
			l: 132.58,
			t: 1655206500000,
			n: 46
		},
		{
			v: 2019,
			vw: 132.5596,
			o: 132.57,
			c: 132.55,
			h: 132.57,
			l: 132.55,
			t: 1655206560000,
			n: 52
		},
		{
			v: 2551,
			vw: 132.5953,
			o: 132.58,
			c: 132.64,
			h: 132.64,
			l: 132.56,
			t: 1655206620000,
			n: 40
		},
		{
			v: 1948,
			vw: 132.5746,
			o: 132.56,
			c: 132.6,
			h: 132.6,
			l: 132.55,
			t: 1655206680000,
			n: 41
		},
		{
			v: 744,
			vw: 132.5899,
			o: 132.59,
			c: 132.59,
			h: 132.59,
			l: 132.59,
			t: 1655206800000,
			n: 19
		},
		{
			v: 938,
			vw: 132.5617,
			o: 132.6,
			c: 132.55,
			h: 132.6,
			l: 132.54,
			t: 1655206860000,
			n: 23
		},
		{
			v: 7017,
			vw: 132.5129,
			o: 132.56,
			c: 132.5,
			h: 132.56,
			l: 132.5,
			t: 1655206980000,
			n: 157
		},
		{
			v: 4449,
			vw: 132.5148,
			o: 132.5,
			c: 132.51,
			h: 132.54,
			l: 132.5,
			t: 1655207040000,
			n: 72
		},
		{
			v: 6591,
			vw: 132.4396,
			o: 132.47,
			c: 132.4,
			h: 132.47,
			l: 132.4,
			t: 1655207100000,
			n: 137
		},
		{
			v: 3112,
			vw: 132.4239,
			o: 132.4,
			c: 132.45,
			h: 132.45,
			l: 132.4,
			t: 1655207160000,
			n: 83
		},
		{
			v: 5003,
			vw: 132.3938,
			o: 132.45,
			c: 132.38,
			h: 132.45,
			l: 132.36,
			t: 1655207220000,
			n: 91
		},
		{
			v: 7073,
			vw: 132.5295,
			o: 132.4,
			c: 132.57,
			h: 132.59,
			l: 132.4,
			t: 1655207280000,
			n: 92
		},
		{
			v: 4268,
			vw: 132.5709,
			o: 132.57,
			c: 132.58,
			h: 132.58,
			l: 132.56,
			t: 1655207340000,
			n: 87
		},
		{
			v: 1487,
			vw: 132.576,
			o: 132.6,
			c: 132.56,
			h: 132.6,
			l: 132.56,
			t: 1655207400000,
			n: 44
		},
		{
			v: 575,
			vw: 132.557,
			o: 132.55,
			c: 132.55,
			h: 132.55,
			l: 132.55,
			t: 1655207460000,
			n: 14
		},
		{
			v: 670,
			vw: 132.5709,
			o: 132.57,
			c: 132.57,
			h: 132.57,
			l: 132.57,
			t: 1655207520000,
			n: 26
		},
		{
			v: 4026,
			vw: 132.4774,
			o: 132.57,
			c: 132.41,
			h: 132.57,
			l: 132.41,
			t: 1655207640000,
			n: 103
		},
		{
			v: 2210,
			vw: 132.4177,
			o: 132.43,
			c: 132.45,
			h: 132.45,
			l: 132.4,
			t: 1655207700000,
			n: 59
		},
		{
			v: 1677,
			vw: 132.4015,
			o: 132.45,
			c: 132.37,
			h: 132.45,
			l: 132.37,
			t: 1655207760000,
			n: 40
		},
		{
			v: 4650,
			vw: 132.3776,
			o: 132.44,
			c: 132.35,
			h: 132.45,
			l: 132.35,
			t: 1655207820000,
			n: 112
		},
		{
			v: 596,
			vw: 132.3973,
			o: 132.4,
			c: 132.4,
			h: 132.4,
			l: 132.4,
			t: 1655207880000,
			n: 23
		},
		{
			v: 1128,
			vw: 132.4216,
			o: 132.46,
			c: 132.4,
			h: 132.46,
			l: 132.4,
			t: 1655207940000,
			n: 22
		},
		{
			v: 148214,
			vw: 132.6743,
			o: 132.8299,
			c: 132.5,
			h: 133,
			l: 132.37,
			t: 1655208000000,
			n: 1204
		},
		{
			v: 8987,
			vw: 132.9045,
			o: 132.58,
			c: 132.72,
			h: 134.47,
			l: 131.551,
			t: 1655208060000,
			n: 558
		},
		{
			v: 23954,
			vw: 132.7482,
			o: 132.61,
			c: 132.7801,
			h: 132.7801,
			l: 132.59,
			t: 1655208120000,
			n: 180
		},
		{
			v: 1612,
			vw: 132.7823,
			o: 132.8,
			c: 132.79,
			h: 132.8,
			l: 132.77,
			t: 1655208180000,
			n: 67
		},
		{
			v: 4921,
			vw: 132.7138,
			o: 132.72,
			c: 132.71,
			h: 132.72,
			l: 132.71,
			t: 1655208240000,
			n: 67
		},
		{
			v: 8212,
			vw: 132.654,
			o: 132.65,
			c: 132.66,
			h: 132.6601,
			l: 132.6499,
			t: 1655208300000,
			n: 61
		},
		{
			v: 6483,
			vw: 132.6506,
			o: 132.66,
			c: 132.66,
			h: 132.66,
			l: 132.62,
			t: 1655208360000,
			n: 70
		},
		{
			v: 791,
			vw: 132.6851,
			o: 132.66,
			c: 132.7,
			h: 132.7,
			l: 132.66,
			t: 1655208420000,
			n: 43
		},
		{
			v: 26610,
			vw: 132.6883,
			o: 132.66,
			c: 132.6,
			h: 132.72,
			l: 132.6,
			t: 1655208480000,
			n: 100
		},
		{
			v: 2877,
			vw: 132.604,
			o: 132.63,
			c: 132.56,
			h: 132.65,
			l: 132.56,
			t: 1655208540000,
			n: 63
		},
		{
			v: 11391,
			vw: 132.5199,
			o: 132.5599,
			c: 132.52,
			h: 132.5599,
			l: 132.51,
			t: 1655208600000,
			n: 64
		},
		{
			v: 3681,
			vw: 132.537,
			o: 132.55,
			c: 132.5,
			h: 132.5899,
			l: 132.5,
			t: 1655208660000,
			n: 87
		},
		{
			v: 1864,
			vw: 132.5357,
			o: 132.55,
			c: 132.55,
			h: 132.55,
			l: 132.55,
			t: 1655208720000,
			n: 46
		},
		{
			v: 2679,
			vw: 132.5184,
			o: 132.5,
			c: 132.5601,
			h: 132.5601,
			l: 132.49,
			t: 1655208780000,
			n: 43
		},
		{
			v: 711,
			vw: 132.5729,
			o: 132.59,
			c: 132.57,
			h: 132.59,
			l: 132.5699,
			t: 1655208840000,
			n: 38
		},
		{
			v: 613,
			vw: 132.5845,
			o: 132.57,
			c: 132.55,
			h: 132.57,
			l: 132.55,
			t: 1655208900000,
			n: 40
		},
		{
			v: 9434,
			vw: 132.5742,
			o: 132.6,
			c: 132.53,
			h: 132.6499,
			l: 132.53,
			t: 1655208960000,
			n: 124
		},
		{
			v: 6518,
			vw: 132.6503,
			o: 132.61,
			c: 132.67,
			h: 132.68,
			l: 132.5601,
			t: 1655209020000,
			n: 59
		},
		{
			v: 1690,
			vw: 132.637,
			o: 132.6699,
			c: 132.65,
			h: 132.6699,
			l: 132.62,
			t: 1655209080000,
			n: 48
		},
		{
			v: 17392,
			vw: 132.6558,
			o: 132.66,
			c: 132.63,
			h: 132.7,
			l: 132.63,
			t: 1655209140000,
			n: 77
		},
		{
			v: 2617,
			vw: 132.6114,
			o: 132.61,
			c: 132.68,
			h: 132.68,
			l: 132.5776,
			t: 1655209200000,
			n: 84
		},
		{
			v: 8665,
			vw: 132.7218,
			o: 132.7,
			c: 132.7601,
			h: 132.8,
			l: 132.6999,
			t: 1655209260000,
			n: 61
		},
		{
			v: 6999,
			vw: 132.7681,
			o: 132.76,
			c: 132.72,
			h: 132.79,
			l: 132.71,
			t: 1655209320000,
			n: 97
		},
		{
			v: 8545,
			vw: 132.7515,
			o: 132.71,
			c: 132.7699,
			h: 132.7699,
			l: 132.71,
			t: 1655209380000,
			n: 46
		},
		{
			v: 3598,
			vw: 132.7689,
			o: 132.75,
			c: 132.79,
			h: 132.79,
			l: 132.75,
			t: 1655209440000,
			n: 63
		},
		{
			v: 3841,
			vw: 132.7185,
			o: 132.8,
			c: 132.7,
			h: 132.8,
			l: 132.69,
			t: 1655209500000,
			n: 86
		},
		{
			v: 8133,
			vw: 132.7919,
			o: 132.74,
			c: 132.8485,
			h: 132.85,
			l: 132.74,
			t: 1655209560000,
			n: 58
		},
		{
			v: 2461,
			vw: 132.7934,
			o: 132.8,
			c: 132.77,
			h: 132.8312,
			l: 132.77,
			t: 1655209620000,
			n: 76
		},
		{
			v: 1737,
			vw: 132.8459,
			o: 132.7966,
			c: 132.9195,
			h: 132.9195,
			l: 132.7966,
			t: 1655209680000,
			n: 51
		},
		{
			v: 5005,
			vw: 132.8418,
			o: 132.9245,
			c: 132.8,
			h: 132.94,
			l: 132.8,
			t: 1655209740000,
			n: 70
		},
		{
			v: 15885,
			vw: 133.0738,
			o: 132.8,
			c: 133.11,
			h: 133.29,
			l: 132.8,
			t: 1655209800000,
			n: 219
		},
		{
			v: 20127,
			vw: 133.1588,
			o: 133.1297,
			c: 133.24,
			h: 133.2499,
			l: 132.94,
			t: 1655209860000,
			n: 101
		},
		{
			v: 24517,
			vw: 133.3039,
			o: 133.22,
			c: 133.34,
			h: 133.3501,
			l: 133.22,
			t: 1655209920000,
			n: 237
		},
		{
			v: 13534,
			vw: 133.2495,
			o: 133.31,
			c: 133.2301,
			h: 133.33,
			l: 133.21,
			t: 1655209980000,
			n: 147
		},
		{
			v: 16096,
			vw: 133.3319,
			o: 133.27,
			c: 133.37,
			h: 133.4,
			l: 133.25,
			t: 1655210040000,
			n: 179
		},
		{
			v: 33382,
			vw: 133.2894,
			o: 133.4,
			c: 133.28,
			h: 133.4199,
			l: 133.25,
			t: 1655210100000,
			n: 245
		},
		{
			v: 16256,
			vw: 133.2023,
			o: 133.2601,
			c: 133.23,
			h: 133.27,
			l: 133.1599,
			t: 1655210160000,
			n: 210
		},
		{
			v: 3461,
			vw: 133.2727,
			o: 133.29,
			c: 133.2799,
			h: 133.29,
			l: 133.26,
			t: 1655210220000,
			n: 78
		},
		{
			v: 5865,
			vw: 133.2417,
			o: 133.26,
			c: 133.22,
			h: 133.28,
			l: 133.14,
			t: 1655210280000,
			n: 110
		},
		{
			v: 1810,
			vw: 133.2548,
			o: 133.25,
			c: 133.28,
			h: 133.28,
			l: 133.25,
			t: 1655210340000,
			n: 64
		},
		{
			v: 19396,
			vw: 133.4195,
			o: 133.2999,
			c: 133.73,
			h: 133.73,
			l: 133.26,
			t: 1655210400000,
			n: 322
		},
		{
			v: 26929,
			vw: 133.5987,
			o: 133.73,
			c: 133.7699,
			h: 133.79,
			l: 133.47,
			t: 1655210460000,
			n: 256
		},
		{
			v: 22236,
			vw: 133.7158,
			o: 133.76,
			c: 133.69,
			h: 133.76,
			l: 133.62,
			t: 1655210520000,
			n: 178
		},
		{
			v: 35326,
			vw: 133.7237,
			o: 133.69,
			c: 133.62,
			h: 133.79,
			l: 133.6199,
			t: 1655210580000,
			n: 208
		},
		{
			v: 23597,
			vw: 133.6524,
			o: 133.63,
			c: 133.64,
			h: 133.72,
			l: 133.6,
			t: 1655210640000,
			n: 225
		},
		{
			v: 54160,
			vw: 133.5825,
			o: 133.64,
			c: 133.57,
			h: 133.64,
			l: 133.55,
			t: 1655210700000,
			n: 393
		},
		{
			v: 30070,
			vw: 133.4881,
			o: 133.5501,
			c: 133.48,
			h: 133.5501,
			l: 133.44,
			t: 1655210760000,
			n: 286
		},
		{
			v: 20650,
			vw: 133.4029,
			o: 133.48,
			c: 133.32,
			h: 133.49,
			l: 133.32,
			t: 1655210820000,
			n: 210
		},
		{
			v: 15531,
			vw: 133.3395,
			o: 133.36,
			c: 133.37,
			h: 133.38,
			l: 133.32,
			t: 1655210880000,
			n: 170
		},
		{
			v: 27064,
			vw: 133.2885,
			o: 133.39,
			c: 133.28,
			h: 133.39,
			l: 133.26,
			t: 1655210940000,
			n: 137
		},
		{
			v: 17808,
			vw: 133.2189,
			o: 133.25,
			c: 133.2199,
			h: 133.29,
			l: 133.18,
			t: 1655211000000,
			n: 163
		},
		{
			v: 5453,
			vw: 133.243,
			o: 133.22,
			c: 133.26,
			h: 133.27,
			l: 133.22,
			t: 1655211060000,
			n: 90
		},
		{
			v: 6702,
			vw: 133.2134,
			o: 133.2599,
			c: 133.17,
			h: 133.2599,
			l: 133.17,
			t: 1655211120000,
			n: 153
		},
		{
			v: 14502,
			vw: 133.3105,
			o: 133.1813,
			c: 133.29,
			h: 133.3634,
			l: 133.1813,
			t: 1655211180000,
			n: 168
		},
		{
			v: 11685,
			vw: 133.2158,
			o: 133.26,
			c: 133.22,
			h: 133.26,
			l: 133.18,
			t: 1655211240000,
			n: 140
		},
		{
			v: 2895,
			vw: 133.2509,
			o: 133.2,
			c: 133.3,
			h: 133.3,
			l: 133.2,
			t: 1655211300000,
			n: 86
		},
		{
			v: 15290,
			vw: 133.3171,
			o: 133.3,
			c: 133.3,
			h: 133.35,
			l: 133.27,
			t: 1655211360000,
			n: 121
		},
		{
			v: 7725,
			vw: 133.2932,
			o: 133.2999,
			c: 133.32,
			h: 133.33,
			l: 133.25,
			t: 1655211420000,
			n: 234
		},
		{
			v: 16618,
			vw: 133.3493,
			o: 133.34,
			c: 133.35,
			h: 133.38,
			l: 133.3,
			t: 1655211480000,
			n: 182
		},
		{
			v: 8255,
			vw: 133.319,
			o: 133.38,
			c: 133.2,
			h: 133.38,
			l: 133.2,
			t: 1655211540000,
			n: 160
		},
		{
			v: 16717,
			vw: 133.1393,
			o: 133.16,
			c: 133.35,
			h: 133.35,
			l: 133.05,
			t: 1655211600000,
			n: 201
		},
		{
			v: 13738,
			vw: 133.3604,
			o: 133.34,
			c: 133.38,
			h: 133.38,
			l: 133.33,
			t: 1655211660000,
			n: 185
		},
		{
			v: 5570,
			vw: 133.3545,
			o: 133.4,
			c: 133.31,
			h: 133.42,
			l: 133.3,
			t: 1655211720000,
			n: 129
		},
		{
			v: 5801,
			vw: 133.3019,
			o: 133.3401,
			c: 133.28,
			h: 133.35,
			l: 133.26,
			t: 1655211780000,
			n: 138
		},
		{
			v: 2713,
			vw: 133.2956,
			o: 133.28,
			c: 133.33,
			h: 133.33,
			l: 133.28,
			t: 1655211840000,
			n: 62
		},
		{
			v: 5772,
			vw: 133.2986,
			o: 133.3594,
			c: 133.25,
			h: 133.3594,
			l: 133.23,
			t: 1655211900000,
			n: 145
		},
		{
			v: 13520,
			vw: 133.2009,
			o: 133.24,
			c: 133.2,
			h: 133.24,
			l: 133.14,
			t: 1655211960000,
			n: 150
		},
		{
			v: 9900,
			vw: 133.1941,
			o: 133.17,
			c: 133.19,
			h: 133.21,
			l: 133.17,
			t: 1655212020000,
			n: 107
		},
		{
			v: 7037,
			vw: 133.1206,
			o: 133.13,
			c: 133.05,
			h: 133.16,
			l: 133.05,
			t: 1655212080000,
			n: 146
		},
		{
			v: 19324,
			vw: 133.1234,
			o: 133.04,
			c: 133.16,
			h: 133.21,
			l: 133.03,
			t: 1655212140000,
			n: 177
		},
		{
			v: 10383,
			vw: 133.1593,
			o: 133.14,
			c: 133.24,
			h: 133.24,
			l: 133.09,
			t: 1655212200000,
			n: 155
		},
		{
			v: 8807,
			vw: 133.241,
			o: 133.239,
			c: 133.29,
			h: 133.29,
			l: 133.2,
			t: 1655212260000,
			n: 161
		},
		{
			v: 12561,
			vw: 133.3182,
			o: 133.3,
			c: 133.35,
			h: 133.35,
			l: 133.29,
			t: 1655212320000,
			n: 192
		},
		{
			v: 6737,
			vw: 133.3076,
			o: 133.33,
			c: 133.29,
			h: 133.33,
			l: 133.27,
			t: 1655212380000,
			n: 137
		},
		{
			v: 5889,
			vw: 133.2681,
			o: 133.3,
			c: 133.3,
			h: 133.3,
			l: 133.21,
			t: 1655212440000,
			n: 138
		},
		{
			v: 8538,
			vw: 133.3451,
			o: 133.3,
			c: 133.37,
			h: 133.37,
			l: 133.3,
			t: 1655212500000,
			n: 169
		},
		{
			v: 11532,
			vw: 133.4451,
			o: 133.39,
			c: 133.47,
			h: 133.48,
			l: 133.39,
			t: 1655212560000,
			n: 141
		},
		{
			v: 11781,
			vw: 133.5045,
			o: 133.47,
			c: 133.59,
			h: 133.59,
			l: 133.46,
			t: 1655212620000,
			n: 187
		},
		{
			v: 25082,
			vw: 133.6609,
			o: 133.6,
			c: 133.64,
			h: 133.72,
			l: 133.6,
			t: 1655212680000,
			n: 281
		},
		{
			v: 13417,
			vw: 133.6289,
			o: 133.64,
			c: 133.58,
			h: 133.68,
			l: 133.55,
			t: 1655212740000,
			n: 230
		},
		{
			v: 19630,
			vw: 133.5845,
			o: 133.58,
			c: 133.59,
			h: 133.61,
			l: 133.5,
			t: 1655212800000,
			n: 172
		},
		{
			v: 18285,
			vw: 133.6455,
			o: 133.59,
			c: 133.7,
			h: 133.7,
			l: 133.59,
			t: 1655212860000,
			n: 156
		},
		{
			v: 5169,
			vw: 133.7301,
			o: 133.71,
			c: 133.74,
			h: 133.77,
			l: 133.7,
			t: 1655212920000,
			n: 148
		},
		{
			v: 7234,
			vw: 133.7225,
			o: 133.73,
			c: 133.72,
			h: 133.74,
			l: 133.701,
			t: 1655212980000,
			n: 163
		},
		{
			v: 19877,
			vw: 133.7266,
			o: 133.71,
			c: 133.75,
			h: 133.75,
			l: 133.71,
			t: 1655213040000,
			n: 139
		},
		{
			v: 28900,
			vw: 133.5132,
			o: 133.73,
			c: 133.5,
			h: 133.73,
			l: 133.25,
			t: 1655213100000,
			n: 458
		},
		{
			v: 18590,
			vw: 133.4187,
			o: 133.46,
			c: 133.37,
			h: 133.5,
			l: 133.35,
			t: 1655213160000,
			n: 268
		},
		{
			v: 32575,
			vw: 133.2417,
			o: 133.4,
			c: 133.11,
			h: 133.51,
			l: 133.1,
			t: 1655213220000,
			n: 428
		},
		{
			v: 74580,
			vw: 132.9887,
			o: 133.08,
			c: 132.97,
			h: 133.16,
			l: 132.84,
			t: 1655213280000,
			n: 559
		},
		{
			v: 44167,
			vw: 133.0595,
			o: 133,
			c: 133.11,
			h: 133.13,
			l: 132.93,
			t: 1655213340000,
			n: 721
		},
		{
			v: 1498677,
			vw: 132.9673,
			o: 133.13,
			c: 133.11,
			h: 133.19,
			l: 132.69,
			t: 1655213400000,
			n: 15426
		},
		{
			v: 373863,
			vw: 132.9359,
			o: 133.09,
			c: 132.87,
			h: 133.1675,
			l: 132.82,
			t: 1655213460000,
			n: 3840
		},
		{
			v: 566646,
			vw: 132.9414,
			o: 132.89,
			c: 132.51,
			h: 133.2099,
			l: 132.4765,
			t: 1655213520000,
			n: 5425
		},
		{
			v: 400375,
			vw: 132.576,
			o: 132.5,
			c: 132.56,
			h: 132.74,
			l: 132.4168,
			t: 1655213580000,
			n: 3630
		},
		{
			v: 324330,
			vw: 132.5352,
			o: 132.56,
			c: 132.37,
			h: 132.74,
			l: 132.37,
			t: 1655213640000,
			n: 3327
		},
		{
			v: 443742,
			vw: 132.1491,
			o: 132.37,
			c: 132.23,
			h: 132.42,
			l: 132,
			t: 1655213700000,
			n: 5491
		},
		{
			v: 313375,
			vw: 132.2793,
			o: 132.23,
			c: 132.33,
			h: 132.41,
			l: 132.1755,
			t: 1655213760000,
			n: 2856
		},
		{
			v: 329741,
			vw: 132.3119,
			o: 132.33,
			c: 132.225,
			h: 132.51,
			l: 132.1101,
			t: 1655213820000,
			n: 2997
		},
		{
			v: 321807,
			vw: 132.1964,
			o: 132.23,
			c: 132.12,
			h: 132.31,
			l: 132.07,
			t: 1655213880000,
			n: 2804
		},
		{
			v: 258847,
			vw: 132.1538,
			o: 132.115,
			c: 132.05,
			h: 132.26,
			l: 132.0409,
			t: 1655213940000,
			n: 2387
		},
		{
			v: 401955,
			vw: 131.93,
			o: 132.06,
			c: 131.825,
			h: 132.11,
			l: 131.78,
			t: 1655214000000,
			n: 5043
		},
		{
			v: 268017,
			vw: 131.8282,
			o: 131.825,
			c: 131.761,
			h: 131.97,
			l: 131.71,
			t: 1655214060000,
			n: 2800
		},
		{
			v: 335479,
			vw: 131.8075,
			o: 131.75,
			c: 131.87,
			h: 131.9002,
			l: 131.67,
			t: 1655214120000,
			n: 3082
		},
		{
			v: 240524,
			vw: 131.8163,
			o: 131.87,
			c: 131.812,
			h: 131.92,
			l: 131.72,
			t: 1655214180000,
			n: 2387
		},
		{
			v: 375322,
			vw: 131.7121,
			o: 131.81,
			c: 131.54,
			h: 131.875,
			l: 131.54,
			t: 1655214240000,
			n: 3513
		},
		{
			v: 469802,
			vw: 131.6753,
			o: 131.54,
			c: 131.81,
			h: 131.83,
			l: 131.53,
			t: 1655214300000,
			n: 3981
		},
		{
			v: 560095,
			vw: 131.9391,
			o: 131.81,
			c: 132.01,
			h: 132.0459,
			l: 131.79,
			t: 1655214360000,
			n: 3783
		},
		{
			v: 370617,
			vw: 132.1241,
			o: 131.99,
			c: 132.295,
			h: 132.32,
			l: 131.9558,
			t: 1655214420000,
			n: 3094
		},
		{
			v: 299440,
			vw: 132.2504,
			o: 132.2901,
			c: 132.27,
			h: 132.33,
			l: 132.1734,
			t: 1655214480000,
			n: 2756
		},
		{
			v: 266977,
			vw: 132.1863,
			o: 132.25,
			c: 132.0994,
			h: 132.33,
			l: 132.05,
			t: 1655214540000,
			n: 2543
		},
		{
			v: 318460,
			vw: 131.9206,
			o: 132.1,
			c: 131.8167,
			h: 132.11,
			l: 131.81,
			t: 1655214600000,
			n: 2606
		},
		{
			v: 234432,
			vw: 131.9233,
			o: 131.81,
			c: 132.02,
			h: 132.095,
			l: 131.7,
			t: 1655214660000,
			n: 2207
		},
		{
			v: 300983,
			vw: 132.2505,
			o: 132.015,
			c: 132.5701,
			h: 132.58,
			l: 131.92,
			t: 1655214720000,
			n: 2934
		},
		{
			v: 276761,
			vw: 132.4123,
			o: 132.58,
			c: 132.405,
			h: 132.6301,
			l: 132.2159,
			t: 1655214780000,
			n: 2683
		},
		{
			v: 359753,
			vw: 132.615,
			o: 132.41,
			c: 132.8101,
			h: 132.85,
			l: 132.32,
			t: 1655214840000,
			n: 2842
		},
		{
			v: 288108,
			vw: 132.7678,
			o: 132.82,
			c: 132.7918,
			h: 132.85,
			l: 132.71,
			t: 1655214900000,
			n: 2429
		},
		{
			v: 261213,
			vw: 132.776,
			o: 132.8,
			c: 132.75,
			h: 132.87,
			l: 132.71,
			t: 1655214960000,
			n: 2179
		},
		{
			v: 252178,
			vw: 132.6987,
			o: 132.7519,
			c: 132.8168,
			h: 132.8374,
			l: 132.57,
			t: 1655215020000,
			n: 2198
		},
		{
			v: 330429,
			vw: 132.9158,
			o: 132.825,
			c: 133.07,
			h: 133.07,
			l: 132.785,
			t: 1655215080000,
			n: 2694
		},
		{
			v: 354197,
			vw: 133.0887,
			o: 133.07,
			c: 133.1001,
			h: 133.19,
			l: 132.89,
			t: 1655215140000,
			n: 2688
		},
		{
			v: 385456,
			vw: 133.1255,
			o: 133.11,
			c: 133.05,
			h: 133.25,
			l: 132.98,
			t: 1655215200000,
			n: 3199
		},
		{
			v: 860100,
			vw: 133.0176,
			o: 133.0613,
			c: 133.08,
			h: 133.15,
			l: 133,
			t: 1655215260000,
			n: 2268
		},
		{
			v: 291724,
			vw: 133.014,
			o: 133.07,
			c: 133.1201,
			h: 133.13,
			l: 132.88,
			t: 1655215320000,
			n: 2496
		},
		{
			v: 233917,
			vw: 133.0989,
			o: 133.13,
			c: 133.04,
			h: 133.19,
			l: 133,
			t: 1655215380000,
			n: 2125
		},
		{
			v: 371462,
			vw: 132.8432,
			o: 133.03,
			c: 132.631,
			h: 133.0325,
			l: 132.631,
			t: 1655215440000,
			n: 2642
		},
		{
			v: 279288,
			vw: 132.6101,
			o: 132.63,
			c: 132.605,
			h: 132.72,
			l: 132.48,
			t: 1655215500000,
			n: 2584
		},
		{
			v: 274610,
			vw: 132.7892,
			o: 132.605,
			c: 132.81,
			h: 132.9,
			l: 132.58,
			t: 1655215560000,
			n: 1994
		},
		{
			v: 267792,
			vw: 132.9468,
			o: 132.815,
			c: 132.99,
			h: 133.13,
			l: 132.74,
			t: 1655215620000,
			n: 2171
		},
		{
			v: 198870,
			vw: 132.8199,
			o: 133,
			c: 132.69,
			h: 133,
			l: 132.68,
			t: 1655215680000,
			n: 2012
		},
		{
			v: 336030,
			vw: 132.5138,
			o: 132.68,
			c: 132.4692,
			h: 132.68,
			l: 132.33,
			t: 1655215740000,
			n: 2645
		},
		{
			v: 183059,
			vw: 132.4559,
			o: 132.4699,
			c: 132.49,
			h: 132.545,
			l: 132.3688,
			t: 1655215800000,
			n: 1703
		},
		{
			v: 218509,
			vw: 132.4354,
			o: 132.49,
			c: 132.41,
			h: 132.54,
			l: 132.35,
			t: 1655215860000,
			n: 1783
		},
		{
			v: 234206,
			vw: 132.552,
			o: 132.42,
			c: 132.63,
			h: 132.69,
			l: 132.41,
			t: 1655215920000,
			n: 2059
		},
		{
			v: 222886,
			vw: 132.4148,
			o: 132.65,
			c: 132.2745,
			h: 132.68,
			l: 132.2745,
			t: 1655215980000,
			n: 2102
		},
		{
			v: 283818,
			vw: 132.1118,
			o: 132.2846,
			c: 132.11,
			h: 132.2846,
			l: 131.99,
			t: 1655216040000,
			n: 3090
		},
		{
			v: 296679,
			vw: 131.9773,
			o: 132.1,
			c: 131.93,
			h: 132.11,
			l: 131.88,
			t: 1655216100000,
			n: 2516
		},
		{
			v: 220929,
			vw: 132.0022,
			o: 131.935,
			c: 131.9697,
			h: 132.07,
			l: 131.905,
			t: 1655216160000,
			n: 2193
		},
		{
			v: 202253,
			vw: 132.104,
			o: 131.97,
			c: 132.08,
			h: 132.2,
			l: 131.95,
			t: 1655216220000,
			n: 1845
		},
		{
			v: 225584,
			vw: 132.254,
			o: 132.0897,
			c: 132.2871,
			h: 132.43,
			l: 132.08,
			t: 1655216280000,
			n: 2105
		},
		{
			v: 203376,
			vw: 132.235,
			o: 132.28,
			c: 132.18,
			h: 132.33,
			l: 132.16,
			t: 1655216340000,
			n: 1797
		},
		{
			v: 240550,
			vw: 132.0392,
			o: 132.18,
			c: 131.9,
			h: 132.19,
			l: 131.89,
			t: 1655216400000,
			n: 2490
		},
		{
			v: 252848,
			vw: 131.9656,
			o: 131.895,
			c: 132.0399,
			h: 132.09,
			l: 131.87,
			t: 1655216460000,
			n: 2248
		},
		{
			v: 258228,
			vw: 132.0914,
			o: 132.0399,
			c: 132.2299,
			h: 132.295,
			l: 131.93,
			t: 1655216520000,
			n: 2270
		},
		{
			v: 228832,
			vw: 132.2438,
			o: 132.225,
			c: 132.2399,
			h: 132.3225,
			l: 132.14,
			t: 1655216580000,
			n: 1811
		},
		{
			v: 158890,
			vw: 132.1982,
			o: 132.225,
			c: 132.19,
			h: 132.27,
			l: 132.125,
			t: 1655216640000,
			n: 1632
		},
		{
			v: 171227,
			vw: 132.0786,
			o: 132.18,
			c: 132.08,
			h: 132.18,
			l: 132.01,
			t: 1655216700000,
			n: 1546
		},
		{
			v: 269304,
			vw: 132.1482,
			o: 132.08,
			c: 132.31,
			h: 132.315,
			l: 131.985,
			t: 1655216760000,
			n: 2138
		},
		{
			v: 216995,
			vw: 132.2367,
			o: 132.295,
			c: 132.1,
			h: 132.39,
			l: 132.065,
			t: 1655216820000,
			n: 1898
		},
		{
			v: 172954,
			vw: 132.0371,
			o: 132.1063,
			c: 131.97,
			h: 132.19,
			l: 131.95,
			t: 1655216880000,
			n: 1734
		},
		{
			v: 162965,
			vw: 131.9832,
			o: 131.98,
			c: 131.93,
			h: 132.06,
			l: 131.91,
			t: 1655216940000,
			n: 1573
		},
		{
			v: 356793,
			vw: 131.8194,
			o: 131.94,
			c: 131.898,
			h: 131.945,
			l: 131.7,
			t: 1655217000000,
			n: 3221
		},
		{
			v: 255440,
			vw: 132.0533,
			o: 131.8901,
			c: 132.205,
			h: 132.23,
			l: 131.88,
			t: 1655217060000,
			n: 2105
		},
		{
			v: 160296,
			vw: 132.1439,
			o: 132.21,
			c: 132.07,
			h: 132.24,
			l: 132.0688,
			t: 1655217120000,
			n: 1353
		},
		{
			v: 203763,
			vw: 131.9982,
			o: 132.07,
			c: 131.903,
			h: 132.14,
			l: 131.88,
			t: 1655217180000,
			n: 1771
		},
		{
			v: 174518,
			vw: 132.0326,
			o: 131.905,
			c: 132.12,
			h: 132.14,
			l: 131.9,
			t: 1655217240000,
			n: 1393
		},
		{
			v: 148539,
			vw: 132.0925,
			o: 132.125,
			c: 132.04,
			h: 132.18,
			l: 132.01,
			t: 1655217300000,
			n: 1554
		},
		{
			v: 176480,
			vw: 132.1385,
			o: 132.05,
			c: 132.24,
			h: 132.27,
			l: 131.9916,
			t: 1655217360000,
			n: 1765
		},
		{
			v: 200041,
			vw: 132.1315,
			o: 132.253,
			c: 132.05,
			h: 132.3,
			l: 131.9901,
			t: 1655217420000,
			n: 1975
		},
		{
			v: 105072,
			vw: 132.1148,
			o: 132.06,
			c: 132.16,
			h: 132.19,
			l: 132.0201,
			t: 1655217480000,
			n: 1202
		},
		{
			v: 161113,
			vw: 132.0734,
			o: 132.15,
			c: 132.1,
			h: 132.26,
			l: 131.925,
			t: 1655217540000,
			n: 1826
		},
		{
			v: 177702,
			vw: 132.0812,
			o: 132.09,
			c: 132.06,
			h: 132.175,
			l: 131.975,
			t: 1655217600000,
			n: 1551
		},
		{
			v: 194759,
			vw: 131.9642,
			o: 132.08,
			c: 131.94,
			h: 132.09,
			l: 131.9,
			t: 1655217660000,
			n: 1840
		},
		{
			v: 273383,
			vw: 131.7708,
			o: 131.94,
			c: 131.58,
			h: 131.94,
			l: 131.58,
			t: 1655217720000,
			n: 2701
		},
		{
			v: 283689,
			vw: 131.5622,
			o: 131.5801,
			c: 131.56,
			h: 131.65,
			l: 131.48,
			t: 1655217780000,
			n: 3300
		},
		{
			v: 228021,
			vw: 131.6616,
			o: 131.5503,
			c: 131.7314,
			h: 131.7801,
			l: 131.535,
			t: 1655217840000,
			n: 1737
		},
		{
			v: 226772,
			vw: 131.8059,
			o: 131.74,
			c: 131.86,
			h: 131.96,
			l: 131.63,
			t: 1655217900000,
			n: 1847
		},
		{
			v: 278868,
			vw: 132.0807,
			o: 131.89,
			c: 132.2594,
			h: 132.28,
			l: 131.86,
			t: 1655217960000,
			n: 2395
		},
		{
			v: 308855,
			vw: 132.4311,
			o: 132.27,
			c: 132.43,
			h: 132.55,
			l: 132.25,
			t: 1655218020000,
			n: 2419
		},
		{
			v: 250800,
			vw: 132.5133,
			o: 132.435,
			c: 132.58,
			h: 132.62,
			l: 132.355,
			t: 1655218080000,
			n: 1893
		},
		{
			v: 235805,
			vw: 132.5697,
			o: 132.555,
			c: 132.71,
			h: 132.71,
			l: 132.4601,
			t: 1655218140000,
			n: 1786
		},
		{
			v: 248919,
			vw: 132.5815,
			o: 132.7168,
			c: 132.6888,
			h: 132.72,
			l: 132.46,
			t: 1655218200000,
			n: 2010
		},
		{
			v: 127635,
			vw: 132.6566,
			o: 132.68,
			c: 132.6801,
			h: 132.7394,
			l: 132.58,
			t: 1655218260000,
			n: 1323
		},
		{
			v: 173297,
			vw: 132.7221,
			o: 132.68,
			c: 132.841,
			h: 132.85,
			l: 132.59,
			t: 1655218320000,
			n: 1590
		},
		{
			v: 206767,
			vw: 132.7475,
			o: 132.84,
			c: 132.75,
			h: 132.8418,
			l: 132.6401,
			t: 1655218380000,
			n: 1673
		},
		{
			v: 170000,
			vw: 132.6338,
			o: 132.76,
			c: 132.53,
			h: 132.7701,
			l: 132.51,
			t: 1655218440000,
			n: 1557
		},
		{
			v: 129742,
			vw: 132.5384,
			o: 132.55,
			c: 132.56,
			h: 132.62,
			l: 132.44,
			t: 1655218500000,
			n: 1298
		},
		{
			v: 134378,
			vw: 132.5711,
			o: 132.56,
			c: 132.58,
			h: 132.62,
			l: 132.49,
			t: 1655218560000,
			n: 1168
		},
		{
			v: 93834,
			vw: 132.592,
			o: 132.58,
			c: 132.635,
			h: 132.64,
			l: 132.52,
			t: 1655218620000,
			n: 853
		},
		{
			v: 161858,
			vw: 132.6427,
			o: 132.64,
			c: 132.65,
			h: 132.72,
			l: 132.545,
			t: 1655218680000,
			n: 1256
		},
		{
			v: 841221,
			vw: 132.5895,
			o: 132.655,
			c: 132.5898,
			h: 132.67,
			l: 132.5,
			t: 1655218740000,
			n: 1295
		},
		{
			v: 199380,
			vw: 132.5998,
			o: 132.58,
			c: 132.55,
			h: 132.7,
			l: 132.49,
			t: 1655218800000,
			n: 1712
		},
		{
			v: 252088,
			vw: 132.6488,
			o: 132.535,
			c: 132.6599,
			h: 132.765,
			l: 132.535,
			t: 1655218860000,
			n: 2200
		},
		{
			v: 170584,
			vw: 132.6489,
			o: 132.665,
			c: 132.61,
			h: 132.72,
			l: 132.57,
			t: 1655218920000,
			n: 1575
		},
		{
			v: 425059,
			vw: 132.57,
			o: 132.6215,
			c: 132.45,
			h: 132.6215,
			l: 132.4305,
			t: 1655218980000,
			n: 1347
		},
		{
			v: 128455,
			vw: 132.488,
			o: 132.45,
			c: 132.53,
			h: 132.55,
			l: 132.42,
			t: 1655219040000,
			n: 1192
		},
		{
			v: 206091,
			vw: 132.345,
			o: 132.525,
			c: 132.2499,
			h: 132.53,
			l: 132.22,
			t: 1655219100000,
			n: 1852
		},
		{
			v: 191074,
			vw: 132.1591,
			o: 132.23,
			c: 132.04,
			h: 132.3099,
			l: 132.02,
			t: 1655219160000,
			n: 1873
		},
		{
			v: 205735,
			vw: 132.0033,
			o: 132.02,
			c: 132.02,
			h: 132.06,
			l: 131.93,
			t: 1655219220000,
			n: 2248
		},
		{
			v: 157361,
			vw: 131.9946,
			o: 132.025,
			c: 131.92,
			h: 132.11,
			l: 131.9,
			t: 1655219280000,
			n: 1455
		},
		{
			v: 123098,
			vw: 131.9946,
			o: 131.92,
			c: 131.97,
			h: 132.07,
			l: 131.91,
			t: 1655219340000,
			n: 1270
		},
		{
			v: 128953,
			vw: 131.9729,
			o: 131.96,
			c: 132.03,
			h: 132.035,
			l: 131.92,
			t: 1655219400000,
			n: 1058
		},
		{
			v: 264373,
			vw: 132.0158,
			o: 132.035,
			c: 131.96,
			h: 132.09,
			l: 131.92,
			t: 1655219460000,
			n: 1858
		},
		{
			v: 149427,
			vw: 132.0511,
			o: 131.955,
			c: 132.02,
			h: 132.13,
			l: 131.947,
			t: 1655219520000,
			n: 1396
		},
		{
			v: 140032,
			vw: 132.0075,
			o: 132.02,
			c: 132.05,
			h: 132.075,
			l: 131.93,
			t: 1655219580000,
			n: 1402
		},
		{
			v: 149365,
			vw: 132.0361,
			o: 132.06,
			c: 132.0191,
			h: 132.11,
			l: 131.96,
			t: 1655219640000,
			n: 1367
		},
		{
			v: 225371,
			vw: 131.9604,
			o: 132.02,
			c: 131.865,
			h: 132.08,
			l: 131.84,
			t: 1655219700000,
			n: 2159
		},
		{
			v: 173184,
			vw: 131.9348,
			o: 131.87,
			c: 131.9091,
			h: 132.0101,
			l: 131.82,
			t: 1655219760000,
			n: 1636
		},
		{
			v: 192909,
			vw: 132.0454,
			o: 131.91,
			c: 132.069,
			h: 132.15,
			l: 131.91,
			t: 1655219820000,
			n: 1619
		},
		{
			v: 157635,
			vw: 132.0799,
			o: 132.08,
			c: 132.09,
			h: 132.17,
			l: 132.01,
			t: 1655219880000,
			n: 1521
		},
		{
			v: 93677,
			vw: 132.1,
			o: 132.09,
			c: 132.1627,
			h: 132.19,
			l: 132.02,
			t: 1655219940000,
			n: 1008
		},
		{
			v: 186442,
			vw: 132.0788,
			o: 132.17,
			c: 132.06,
			h: 132.2181,
			l: 131.95,
			t: 1655220000000,
			n: 2086
		},
		{
			v: 104258,
			vw: 131.9748,
			o: 132.07,
			c: 131.93,
			h: 132.07,
			l: 131.9204,
			t: 1655220060000,
			n: 1219
		},
		{
			v: 85417,
			vw: 131.971,
			o: 131.92,
			c: 131.95,
			h: 132.0387,
			l: 131.92,
			t: 1655220120000,
			n: 1131
		},
		{
			v: 135925,
			vw: 132.0983,
			o: 131.95,
			c: 132.12,
			h: 132.19,
			l: 131.93,
			t: 1655220180000,
			n: 1266
		},
		{
			v: 196937,
			vw: 132.2705,
			o: 132.115,
			c: 132.4491,
			h: 132.46,
			l: 132.1041,
			t: 1655220240000,
			n: 1692
		},
		{
			v: 143429,
			vw: 132.4408,
			o: 132.44,
			c: 132.43,
			h: 132.485,
			l: 132.375,
			t: 1655220300000,
			n: 1311
		},
		{
			v: 175301,
			vw: 132.4626,
			o: 132.42,
			c: 132.48,
			h: 132.53,
			l: 132.3905,
			t: 1655220360000,
			n: 1444
		},
		{
			v: 127965,
			vw: 132.4073,
			o: 132.48,
			c: 132.38,
			h: 132.48,
			l: 132.35,
			t: 1655220420000,
			n: 1131
		},
		{
			v: 127945,
			vw: 132.3448,
			o: 132.375,
			c: 132.33,
			h: 132.39,
			l: 132.3,
			t: 1655220480000,
			n: 1213
		},
		{
			v: 108639,
			vw: 132.2577,
			o: 132.325,
			c: 132.28,
			h: 132.33,
			l: 132.2,
			t: 1655220540000,
			n: 1036
		},
		{
			v: 142618,
			vw: 132.1825,
			o: 132.28,
			c: 132.193,
			h: 132.28,
			l: 132.12,
			t: 1655220600000,
			n: 1251
		},
		{
			v: 114935,
			vw: 132.1846,
			o: 132.2,
			c: 132.185,
			h: 132.25,
			l: 132.1,
			t: 1655220660000,
			n: 988
		},
		{
			v: 118905,
			vw: 132.0928,
			o: 132.18,
			c: 132.07,
			h: 132.2,
			l: 132.01,
			t: 1655220720000,
			n: 1092
		},
		{
			v: 159583,
			vw: 132.1614,
			o: 132.065,
			c: 132.205,
			h: 132.28,
			l: 132.055,
			t: 1655220780000,
			n: 1548
		},
		{
			v: 215353,
			vw: 132.4519,
			o: 132.225,
			c: 132.52,
			h: 132.583,
			l: 132.225,
			t: 1655220840000,
			n: 1451
		},
		{
			v: 181203,
			vw: 132.5719,
			o: 132.52,
			c: 132.665,
			h: 132.67,
			l: 132.46,
			t: 1655220900000,
			n: 1689
		},
		{
			v: 161517,
			vw: 132.6573,
			o: 132.64,
			c: 132.71,
			h: 132.74,
			l: 132.55,
			t: 1655220960000,
			n: 1552
		},
		{
			v: 122279,
			vw: 132.5472,
			o: 132.7101,
			c: 132.4282,
			h: 132.73,
			l: 132.405,
			t: 1655221020000,
			n: 1352
		},
		{
			v: 122918,
			vw: 132.3847,
			o: 132.42,
			c: 132.405,
			h: 132.49,
			l: 132.28,
			t: 1655221080000,
			n: 1267
		},
		{
			v: 475256,
			vw: 132.3921,
			o: 132.41,
			c: 132.46,
			h: 132.5062,
			l: 132.35,
			t: 1655221140000,
			n: 1093
		},
		{
			v: 120478,
			vw: 132.4394,
			o: 132.45,
			c: 132.38,
			h: 132.52,
			l: 132.35,
			t: 1655221200000,
			n: 1105
		},
		{
			v: 123130,
			vw: 132.427,
			o: 132.3901,
			c: 132.49,
			h: 132.52,
			l: 132.35,
			t: 1655221260000,
			n: 1131
		},
		{
			v: 119812,
			vw: 132.4464,
			o: 132.47,
			c: 132.4878,
			h: 132.54,
			l: 132.37,
			t: 1655221320000,
			n: 1118
		},
		{
			v: 146226,
			vw: 132.5922,
			o: 132.48,
			c: 132.595,
			h: 132.72,
			l: 132.44,
			t: 1655221380000,
			n: 1455
		},
		{
			v: 167631,
			vw: 132.7359,
			o: 132.6,
			c: 132.779,
			h: 132.8,
			l: 132.6,
			t: 1655221440000,
			n: 1401
		},
		{
			v: 114632,
			vw: 132.6669,
			o: 132.7755,
			c: 132.66,
			h: 132.81,
			l: 132.55,
			t: 1655221500000,
			n: 1173
		},
		{
			v: 137789,
			vw: 132.7437,
			o: 132.66,
			c: 132.6,
			h: 132.8595,
			l: 132.5937,
			t: 1655221560000,
			n: 1196
		},
		{
			v: 132141,
			vw: 132.6037,
			o: 132.595,
			c: 132.63,
			h: 132.69,
			l: 132.54,
			t: 1655221620000,
			n: 1213
		},
		{
			v: 138449,
			vw: 132.5422,
			o: 132.63,
			c: 132.56,
			h: 132.65,
			l: 132.48,
			t: 1655221680000,
			n: 1333
		},
		{
			v: 109272,
			vw: 132.5354,
			o: 132.56,
			c: 132.51,
			h: 132.595,
			l: 132.476,
			t: 1655221740000,
			n: 1168
		},
		{
			v: 112204,
			vw: 132.582,
			o: 132.5,
			c: 132.69,
			h: 132.69,
			l: 132.49,
			t: 1655221800000,
			n: 1017
		},
		{
			v: 154634,
			vw: 132.5325,
			o: 132.6801,
			c: 132.4628,
			h: 132.7,
			l: 132.39,
			t: 1655221860000,
			n: 1493
		},
		{
			v: 86195,
			vw: 132.426,
			o: 132.47,
			c: 132.3701,
			h: 132.5,
			l: 132.355,
			t: 1655221920000,
			n: 985
		},
		{
			v: 152246,
			vw: 132.4696,
			o: 132.39,
			c: 132.57,
			h: 132.57,
			l: 132.39,
			t: 1655221980000,
			n: 1366
		},
		{
			v: 148672,
			vw: 132.5979,
			o: 132.57,
			c: 132.6299,
			h: 132.7,
			l: 132.535,
			t: 1655222040000,
			n: 1266
		},
		{
			v: 118753,
			vw: 132.6747,
			o: 132.6269,
			c: 132.6486,
			h: 132.73,
			l: 132.59,
			t: 1655222100000,
			n: 1070
		},
		{
			v: 104810,
			vw: 132.6472,
			o: 132.6499,
			c: 132.615,
			h: 132.71,
			l: 132.595,
			t: 1655222160000,
			n: 1037
		},
		{
			v: 89976,
			vw: 132.6445,
			o: 132.62,
			c: 132.725,
			h: 132.725,
			l: 132.59,
			t: 1655222220000,
			n: 891
		},
		{
			v: 208777,
			vw: 132.8047,
			o: 132.725,
			c: 132.81,
			h: 132.95,
			l: 132.62,
			t: 1655222280000,
			n: 1790
		},
		{
			v: 101231,
			vw: 132.85,
			o: 132.81,
			c: 132.81,
			h: 132.9,
			l: 132.8,
			t: 1655222340000,
			n: 1014
		},
		{
			v: 247325,
			vw: 132.6036,
			o: 132.81,
			c: 132.59,
			h: 132.84,
			l: 132.46,
			t: 1655222400000,
			n: 2425
		},
		{
			v: 122373,
			vw: 132.5516,
			o: 132.59,
			c: 132.625,
			h: 132.63,
			l: 132.48,
			t: 1655222460000,
			n: 942
		},
		{
			v: 143534,
			vw: 132.4604,
			o: 132.625,
			c: 132.34,
			h: 132.625,
			l: 132.34,
			t: 1655222520000,
			n: 1411
		},
		{
			v: 135710,
			vw: 132.3102,
			o: 132.35,
			c: 132.28,
			h: 132.35,
			l: 132.27,
			t: 1655222580000,
			n: 1238
		},
		{
			v: 84654,
			vw: 132.2975,
			o: 132.28,
			c: 132.3,
			h: 132.34,
			l: 132.25,
			t: 1655222640000,
			n: 890
		},
		{
			v: 124508,
			vw: 132.2325,
			o: 132.28,
			c: 132.23,
			h: 132.29,
			l: 132.17,
			t: 1655222700000,
			n: 1294
		},
		{
			v: 76666,
			vw: 132.2043,
			o: 132.22,
			c: 132.17,
			h: 132.248,
			l: 132.1639,
			t: 1655222760000,
			n: 871
		},
		{
			v: 117462,
			vw: 132.2117,
			o: 132.18,
			c: 132.2794,
			h: 132.2799,
			l: 132.14,
			t: 1655222820000,
			n: 1167
		},
		{
			v: 97377,
			vw: 132.2629,
			o: 132.26,
			c: 132.2882,
			h: 132.32,
			l: 132.185,
			t: 1655222880000,
			n: 866
		},
		{
			v: 76904,
			vw: 132.2166,
			o: 132.2897,
			c: 132.21,
			h: 132.3,
			l: 132.15,
			t: 1655222940000,
			n: 757
		},
		{
			v: 83199,
			vw: 132.2465,
			o: 132.22,
			c: 132.26,
			h: 132.3073,
			l: 132.2,
			t: 1655223000000,
			n: 802
		},
		{
			v: 98604,
			vw: 132.329,
			o: 132.27,
			c: 132.325,
			h: 132.4085,
			l: 132.24,
			t: 1655223060000,
			n: 949
		},
		{
			v: 78727,
			vw: 132.2571,
			o: 132.3201,
			c: 132.17,
			h: 132.35,
			l: 132.16,
			t: 1655223120000,
			n: 884
		},
		{
			v: 71149,
			vw: 132.2201,
			o: 132.1695,
			c: 132.19,
			h: 132.28,
			l: 132.15,
			t: 1655223180000,
			n: 729
		},
		{
			v: 101133,
			vw: 132.1108,
			o: 132.19,
			c: 132.04,
			h: 132.1999,
			l: 132.025,
			t: 1655223240000,
			n: 1061
		},
		{
			v: 211701,
			vw: 132.0063,
			o: 132.0548,
			c: 132.095,
			h: 132.0991,
			l: 131.91,
			t: 1655223300000,
			n: 2575
		},
		{
			v: 101356,
			vw: 132.0713,
			o: 132.1,
			c: 132.15,
			h: 132.17,
			l: 132.005,
			t: 1655223360000,
			n: 1099
		},
		{
			v: 141474,
			vw: 132.197,
			o: 132.16,
			c: 132.17,
			h: 132.25,
			l: 132.1462,
			t: 1655223420000,
			n: 1088
		},
		{
			v: 148185,
			vw: 132.302,
			o: 132.17,
			c: 132.33,
			h: 132.41,
			l: 132.16,
			t: 1655223480000,
			n: 1249
		},
		{
			v: 121998,
			vw: 132.416,
			o: 132.32,
			c: 132.42,
			h: 132.49,
			l: 132.3,
			t: 1655223540000,
			n: 1144
		},
		{
			v: 144875,
			vw: 132.4822,
			o: 132.44,
			c: 132.5,
			h: 132.555,
			l: 132.43,
			t: 1655223600000,
			n: 991
		},
		{
			v: 113905,
			vw: 132.4115,
			o: 132.49,
			c: 132.4,
			h: 132.52,
			l: 132.3202,
			t: 1655223660000,
			n: 1151
		},
		{
			v: 129619,
			vw: 132.3969,
			o: 132.405,
			c: 132.36,
			h: 132.4399,
			l: 132.345,
			t: 1655223720000,
			n: 877
		},
		{
			v: 109054,
			vw: 132.3219,
			o: 132.36,
			c: 132.28,
			h: 132.4099,
			l: 132.25,
			t: 1655223780000,
			n: 1034
		},
		{
			v: 60382,
			vw: 132.3167,
			o: 132.27,
			c: 132.3624,
			h: 132.38,
			l: 132.25,
			t: 1655223840000,
			n: 682
		},
		{
			v: 63564,
			vw: 132.3025,
			o: 132.3648,
			c: 132.26,
			h: 132.38,
			l: 132.25,
			t: 1655223900000,
			n: 694
		},
		{
			v: 79949,
			vw: 132.2389,
			o: 132.257,
			c: 132.2561,
			h: 132.31,
			l: 132.17,
			t: 1655223960000,
			n: 872
		},
		{
			v: 70968,
			vw: 132.281,
			o: 132.2501,
			c: 132.35,
			h: 132.35,
			l: 132.2246,
			t: 1655224020000,
			n: 842
		},
		{
			v: 66196,
			vw: 132.2851,
			o: 132.35,
			c: 132.235,
			h: 132.38,
			l: 132.23,
			t: 1655224080000,
			n: 765
		},
		{
			v: 80423,
			vw: 132.2741,
			o: 132.25,
			c: 132.315,
			h: 132.34,
			l: 132.21,
			t: 1655224140000,
			n: 845
		},
		{
			v: 69329,
			vw: 132.2856,
			o: 132.32,
			c: 132.32,
			h: 132.37,
			l: 132.2265,
			t: 1655224200000,
			n: 786
		},
		{
			v: 111362,
			vw: 132.2708,
			o: 132.31,
			c: 132.21,
			h: 132.38,
			l: 132.2001,
			t: 1655224260000,
			n: 1127
		},
		{
			v: 162167,
			vw: 132.1421,
			o: 132.21,
			c: 132.1952,
			h: 132.245,
			l: 132.08,
			t: 1655224320000,
			n: 1595
		},
		{
			v: 127098,
			vw: 132.2639,
			o: 132.2,
			c: 132.2442,
			h: 132.31,
			l: 132.19,
			t: 1655224380000,
			n: 1116
		},
		{
			v: 85177,
			vw: 132.2026,
			o: 132.24,
			c: 132.1399,
			h: 132.29,
			l: 132.13,
			t: 1655224440000,
			n: 893
		},
		{
			v: 148758,
			vw: 132.1248,
			o: 132.13,
			c: 132.02,
			h: 132.22,
			l: 132.02,
			t: 1655224500000,
			n: 1575
		},
		{
			v: 77450,
			vw: 132.1426,
			o: 132.02,
			c: 132.2117,
			h: 132.24,
			l: 132.02,
			t: 1655224560000,
			n: 872
		},
		{
			v: 72553,
			vw: 132.1696,
			o: 132.21,
			c: 132.1001,
			h: 132.24,
			l: 132.1,
			t: 1655224620000,
			n: 914
		},
		{
			v: 179743,
			vw: 132.0348,
			o: 132.11,
			c: 132.07,
			h: 132.14,
			l: 131.94,
			t: 1655224680000,
			n: 2116
		},
		{
			v: 59413,
			vw: 132.0734,
			o: 132.08,
			c: 132.0903,
			h: 132.11,
			l: 132.03,
			t: 1655224740000,
			n: 718
		},
		{
			v: 74670,
			vw: 132.071,
			o: 132.09,
			c: 132.15,
			h: 132.155,
			l: 131.98,
			t: 1655224800000,
			n: 827
		},
		{
			v: 107165,
			vw: 132.1795,
			o: 132.14,
			c: 132.09,
			h: 132.23,
			l: 132.08,
			t: 1655224860000,
			n: 1109
		},
		{
			v: 78554,
			vw: 132.13,
			o: 132.09,
			c: 132.23,
			h: 132.23,
			l: 132.0212,
			t: 1655224920000,
			n: 797
		},
		{
			v: 72758,
			vw: 132.2307,
			o: 132.24,
			c: 132.2501,
			h: 132.3,
			l: 132.16,
			t: 1655224980000,
			n: 752
		},
		{
			v: 76328,
			vw: 132.2184,
			o: 132.26,
			c: 132.14,
			h: 132.3,
			l: 132.14,
			t: 1655225040000,
			n: 751
		},
		{
			v: 94712,
			vw: 132.1681,
			o: 132.14,
			c: 132.15,
			h: 132.2401,
			l: 132.1,
			t: 1655225100000,
			n: 1013
		},
		{
			v: 77444,
			vw: 132.1814,
			o: 132.16,
			c: 132.2,
			h: 132.26,
			l: 132.12,
			t: 1655225160000,
			n: 830
		},
		{
			v: 73057,
			vw: 132.249,
			o: 132.205,
			c: 132.245,
			h: 132.2898,
			l: 132.2,
			t: 1655225220000,
			n: 830
		},
		{
			v: 96461,
			vw: 132.2673,
			o: 132.25,
			c: 132.31,
			h: 132.32,
			l: 132.2,
			t: 1655225280000,
			n: 855
		},
		{
			v: 86170,
			vw: 132.3326,
			o: 132.31,
			c: 132.34,
			h: 132.36,
			l: 132.29,
			t: 1655225340000,
			n: 848
		},
		{
			v: 117099,
			vw: 132.2922,
			o: 132.34,
			c: 132.3,
			h: 132.39,
			l: 132.21,
			t: 1655225400000,
			n: 1234
		},
		{
			v: 100280,
			vw: 132.2647,
			o: 132.3,
			c: 132.3101,
			h: 132.34,
			l: 132.18,
			t: 1655225460000,
			n: 839
		},
		{
			v: 110170,
			vw: 132.3306,
			o: 132.31,
			c: 132.2897,
			h: 132.3976,
			l: 132.24,
			t: 1655225520000,
			n: 874
		},
		{
			v: 124934,
			vw: 132.2695,
			o: 132.29,
			c: 132.33,
			h: 132.3781,
			l: 132.245,
			t: 1655225580000,
			n: 792
		},
		{
			v: 119412,
			vw: 132.372,
			o: 132.35,
			c: 132.37,
			h: 132.44,
			l: 132.31,
			t: 1655225640000,
			n: 1022
		},
		{
			v: 151512,
			vw: 132.4148,
			o: 132.38,
			c: 132.435,
			h: 132.48,
			l: 132.35,
			t: 1655225700000,
			n: 1478
		},
		{
			v: 137511,
			vw: 132.4769,
			o: 132.435,
			c: 132.52,
			h: 132.52,
			l: 132.38,
			t: 1655225760000,
			n: 1301
		},
		{
			v: 123892,
			vw: 132.4622,
			o: 132.51,
			c: 132.38,
			h: 132.5432,
			l: 132.365,
			t: 1655225820000,
			n: 1030
		},
		{
			v: 81777,
			vw: 132.4127,
			o: 132.37,
			c: 132.49,
			h: 132.491,
			l: 132.35,
			t: 1655225880000,
			n: 786
		},
		{
			v: 86321,
			vw: 132.4914,
			o: 132.5,
			c: 132.5,
			h: 132.53,
			l: 132.44,
			t: 1655225940000,
			n: 962
		},
		{
			v: 110567,
			vw: 132.5692,
			o: 132.5,
			c: 132.625,
			h: 132.64,
			l: 132.49,
			t: 1655226000000,
			n: 1073
		},
		{
			v: 87278,
			vw: 132.5672,
			o: 132.62,
			c: 132.505,
			h: 132.6599,
			l: 132.49,
			t: 1655226060000,
			n: 999
		},
		{
			v: 118973,
			vw: 132.4192,
			o: 132.51,
			c: 132.4,
			h: 132.51,
			l: 132.36,
			t: 1655226120000,
			n: 1090
		},
		{
			v: 67579,
			vw: 132.3437,
			o: 132.3962,
			c: 132.345,
			h: 132.3962,
			l: 132.3001,
			t: 1655226180000,
			n: 716
		},
		{
			v: 86917,
			vw: 132.4186,
			o: 132.3426,
			c: 132.5,
			h: 132.52,
			l: 132.3401,
			t: 1655226240000,
			n: 932
		},
		{
			v: 88061,
			vw: 132.492,
			o: 132.5099,
			c: 132.4899,
			h: 132.55,
			l: 132.44,
			t: 1655226300000,
			n: 1020
		},
		{
			v: 122020,
			vw: 132.5436,
			o: 132.48,
			c: 132.67,
			h: 132.69,
			l: 132.43,
			t: 1655226360000,
			n: 1147
		},
		{
			v: 154074,
			vw: 132.6872,
			o: 132.69,
			c: 132.775,
			h: 132.78,
			l: 132.57,
			t: 1655226420000,
			n: 1416
		},
		{
			v: 180861,
			vw: 132.8367,
			o: 132.77,
			c: 132.89,
			h: 132.91,
			l: 132.75,
			t: 1655226480000,
			n: 1587
		},
		{
			v: 127727,
			vw: 132.9368,
			o: 132.89,
			c: 132.945,
			h: 132.985,
			l: 132.855,
			t: 1655226540000,
			n: 1148
		},
		{
			v: 111194,
			vw: 132.941,
			o: 132.95,
			c: 132.98,
			h: 132.9899,
			l: 132.905,
			t: 1655226600000,
			n: 971
		},
		{
			v: 136140,
			vw: 132.9357,
			o: 132.98,
			c: 132.915,
			h: 132.99,
			l: 132.88,
			t: 1655226660000,
			n: 1100
		},
		{
			v: 238918,
			vw: 132.9325,
			o: 132.92,
			c: 133.05,
			h: 133.05,
			l: 132.78,
			t: 1655226720000,
			n: 2050
		},
		{
			v: 200032,
			vw: 132.9701,
			o: 133.05,
			c: 132.9451,
			h: 133.05,
			l: 132.89,
			t: 1655226780000,
			n: 1675
		},
		{
			v: 112577,
			vw: 132.8723,
			o: 132.93,
			c: 132.93,
			h: 132.938,
			l: 132.82,
			t: 1655226840000,
			n: 1018
		},
		{
			v: 126124,
			vw: 133.0279,
			o: 132.93,
			c: 133.12,
			h: 133.12,
			l: 132.91,
			t: 1655226900000,
			n: 1130
		},
		{
			v: 174286,
			vw: 133.147,
			o: 133.115,
			c: 133.225,
			h: 133.225,
			l: 133.0801,
			t: 1655226960000,
			n: 1415
		},
		{
			v: 349615,
			vw: 133.3847,
			o: 133.23,
			c: 133.5133,
			h: 133.52,
			l: 133.23,
			t: 1655227020000,
			n: 3171
		},
		{
			v: 274555,
			vw: 133.5461,
			o: 133.515,
			c: 133.555,
			h: 133.63,
			l: 133.505,
			t: 1655227080000,
			n: 2307
		},
		{
			v: 181822,
			vw: 133.6047,
			o: 133.555,
			c: 133.6,
			h: 133.68,
			l: 133.53,
			t: 1655227140000,
			n: 1568
		},
		{
			v: 152010,
			vw: 133.6362,
			o: 133.605,
			c: 133.575,
			h: 133.685,
			l: 133.56,
			t: 1655227200000,
			n: 1469
		},
		{
			v: 159132,
			vw: 133.5789,
			o: 133.57,
			c: 133.6399,
			h: 133.68,
			l: 133.5,
			t: 1655227260000,
			n: 1532
		},
		{
			v: 227572,
			vw: 133.7199,
			o: 133.6207,
			c: 133.84,
			h: 133.84,
			l: 133.58,
			t: 1655227320000,
			n: 1788
		},
		{
			v: 179434,
			vw: 133.8311,
			o: 133.83,
			c: 133.725,
			h: 133.89,
			l: 133.72,
			t: 1655227380000,
			n: 1505
		},
		{
			v: 214734,
			vw: 133.6319,
			o: 133.7275,
			c: 133.66,
			h: 133.735,
			l: 133.56,
			t: 1655227440000,
			n: 1392
		},
		{
			v: 170906,
			vw: 133.5047,
			o: 133.654,
			c: 133.53,
			h: 133.6584,
			l: 133.4,
			t: 1655227500000,
			n: 1660
		},
		{
			v: 121456,
			vw: 133.4497,
			o: 133.52,
			c: 133.39,
			h: 133.5319,
			l: 133.37,
			t: 1655227560000,
			n: 1181
		},
		{
			v: 96819,
			vw: 133.4258,
			o: 133.4,
			c: 133.47,
			h: 133.48,
			l: 133.355,
			t: 1655227620000,
			n: 1035
		},
		{
			v: 107378,
			vw: 133.4336,
			o: 133.475,
			c: 133.42,
			h: 133.5081,
			l: 133.35,
			t: 1655227680000,
			n: 1092
		},
		{
			v: 113261,
			vw: 133.3438,
			o: 133.41,
			c: 133.26,
			h: 133.43,
			l: 133.26,
			t: 1655227740000,
			n: 987
		},
		{
			v: 120109,
			vw: 133.2628,
			o: 133.26,
			c: 133.245,
			h: 133.35,
			l: 133.2001,
			t: 1655227800000,
			n: 1247
		},
		{
			v: 109377,
			vw: 133.2203,
			o: 133.255,
			c: 133.215,
			h: 133.29,
			l: 133.17,
			t: 1655227860000,
			n: 1020
		},
		{
			v: 131470,
			vw: 133.2574,
			o: 133.21,
			c: 133.3,
			h: 133.37,
			l: 133.175,
			t: 1655227920000,
			n: 1148
		},
		{
			v: 98187,
			vw: 133.2361,
			o: 133.299,
			c: 133.16,
			h: 133.31,
			l: 133.13,
			t: 1655227980000,
			n: 1015
		},
		{
			v: 155808,
			vw: 133.0749,
			o: 133.16,
			c: 133.03,
			h: 133.16,
			l: 133.01,
			t: 1655228040000,
			n: 1399
		},
		{
			v: 123747,
			vw: 133.0227,
			o: 133.015,
			c: 132.99,
			h: 133.09,
			l: 132.9699,
			t: 1655228100000,
			n: 1317
		},
		{
			v: 631933,
			vw: 133.0418,
			o: 132.9999,
			c: 132.905,
			h: 133.02,
			l: 132.8882,
			t: 1655228160000,
			n: 1358
		},
		{
			v: 77552,
			vw: 132.8887,
			o: 132.9052,
			c: 132.85,
			h: 132.9268,
			l: 132.83,
			t: 1655228220000,
			n: 977
		},
		{
			v: 96906,
			vw: 132.8702,
			o: 132.86,
			c: 132.8379,
			h: 132.915,
			l: 132.82,
			t: 1655228280000,
			n: 990
		},
		{
			v: 140521,
			vw: 132.7867,
			o: 132.84,
			c: 132.725,
			h: 132.87,
			l: 132.705,
			t: 1655228340000,
			n: 1469
		},
		{
			v: 117174,
			vw: 132.7036,
			o: 132.72,
			c: 132.69,
			h: 132.76,
			l: 132.62,
			t: 1655228400000,
			n: 1258
		},
		{
			v: 122155,
			vw: 132.6628,
			o: 132.695,
			c: 132.7,
			h: 132.72,
			l: 132.61,
			t: 1655228460000,
			n: 1116
		},
		{
			v: 107344,
			vw: 132.6336,
			o: 132.71,
			c: 132.61,
			h: 132.72,
			l: 132.56,
			t: 1655228520000,
			n: 1246
		},
		{
			v: 162007,
			vw: 132.5236,
			o: 132.59,
			c: 132.4437,
			h: 132.65,
			l: 132.4299,
			t: 1655228580000,
			n: 1453
		},
		{
			v: 170873,
			vw: 132.4217,
			o: 132.445,
			c: 132.44,
			h: 132.49,
			l: 132.35,
			t: 1655228640000,
			n: 1645
		},
		{
			v: 156091,
			vw: 132.487,
			o: 132.45,
			c: 132.54,
			h: 132.58,
			l: 132.385,
			t: 1655228700000,
			n: 1429
		},
		{
			v: 102816,
			vw: 132.4937,
			o: 132.54,
			c: 132.4699,
			h: 132.5459,
			l: 132.44,
			t: 1655228760000,
			n: 1088
		},
		{
			v: 143351,
			vw: 132.4279,
			o: 132.48,
			c: 132.3415,
			h: 132.54,
			l: 132.335,
			t: 1655228820000,
			n: 1097
		},
		{
			v: 130185,
			vw: 132.3502,
			o: 132.35,
			c: 132.4,
			h: 132.4101,
			l: 132.29,
			t: 1655228880000,
			n: 1194
		},
		{
			v: 91886,
			vw: 132.4046,
			o: 132.4,
			c: 132.39,
			h: 132.46,
			l: 132.3501,
			t: 1655228940000,
			n: 991
		},
		{
			v: 89500,
			vw: 132.4304,
			o: 132.39,
			c: 132.49,
			h: 132.49,
			l: 132.38,
			t: 1655229000000,
			n: 915
		},
		{
			v: 101818,
			vw: 132.4805,
			o: 132.485,
			c: 132.46,
			h: 132.53,
			l: 132.43,
			t: 1655229060000,
			n: 1044
		},
		{
			v: 67363,
			vw: 132.4726,
			o: 132.4675,
			c: 132.48,
			h: 132.52,
			l: 132.44,
			t: 1655229120000,
			n: 814
		},
		{
			v: 113753,
			vw: 132.5092,
			o: 132.489,
			c: 132.48,
			h: 132.54,
			l: 132.455,
			t: 1655229180000,
			n: 1006
		},
		{
			v: 232440,
			vw: 132.5369,
			o: 132.47,
			c: 132.495,
			h: 132.57,
			l: 132.47,
			t: 1655229240000,
			n: 1260
		},
		{
			v: 138762,
			vw: 132.4676,
			o: 132.495,
			c: 132.4748,
			h: 132.54,
			l: 132.42,
			t: 1655229300000,
			n: 1175
		},
		{
			v: 138571,
			vw: 132.5381,
			o: 132.4869,
			c: 132.53,
			h: 132.59,
			l: 132.45,
			t: 1655229360000,
			n: 1231
		},
		{
			v: 89752,
			vw: 132.56,
			o: 132.525,
			c: 132.585,
			h: 132.59,
			l: 132.5,
			t: 1655229420000,
			n: 826
		},
		{
			v: 111640,
			vw: 132.5591,
			o: 132.59,
			c: 132.5,
			h: 132.6,
			l: 132.47,
			t: 1655229480000,
			n: 1058
		},
		{
			v: 125745,
			vw: 132.4905,
			o: 132.495,
			c: 132.4802,
			h: 132.55,
			l: 132.4203,
			t: 1655229540000,
			n: 1021
		},
		{
			v: 266213,
			vw: 132.5925,
			o: 132.49,
			c: 132.635,
			h: 132.6485,
			l: 132.455,
			t: 1655229600000,
			n: 1358
		},
		{
			v: 292126,
			vw: 132.7225,
			o: 132.63,
			c: 132.8399,
			h: 132.84,
			l: 132.62,
			t: 1655229660000,
			n: 1745
		},
		{
			v: 107747,
			vw: 132.7397,
			o: 132.8392,
			c: 132.675,
			h: 132.8392,
			l: 132.67,
			t: 1655229720000,
			n: 1019
		},
		{
			v: 94613,
			vw: 132.7048,
			o: 132.6799,
			c: 132.79,
			h: 132.79,
			l: 132.65,
			t: 1655229780000,
			n: 1002
		},
		{
			v: 167964,
			vw: 132.7607,
			o: 132.785,
			c: 132.706,
			h: 132.81,
			l: 132.69,
			t: 1655229840000,
			n: 1244
		},
		{
			v: 84716,
			vw: 132.7573,
			o: 132.705,
			c: 132.795,
			h: 132.818,
			l: 132.6899,
			t: 1655229900000,
			n: 1062
		},
		{
			v: 140733,
			vw: 132.8537,
			o: 132.8,
			c: 132.86,
			h: 132.94,
			l: 132.78,
			t: 1655229960000,
			n: 1284
		},
		{
			v: 174774,
			vw: 132.9587,
			o: 132.86,
			c: 132.9657,
			h: 132.99,
			l: 132.86,
			t: 1655230020000,
			n: 1255
		},
		{
			v: 244963,
			vw: 132.9805,
			o: 132.96,
			c: 132.95,
			h: 133.0399,
			l: 132.89,
			t: 1655230080000,
			n: 1397
		},
		{
			v: 109500,
			vw: 132.9938,
			o: 132.955,
			c: 132.9598,
			h: 133.04,
			l: 132.94,
			t: 1655230140000,
			n: 1063
		},
		{
			v: 106778,
			vw: 132.846,
			o: 132.96,
			c: 132.8433,
			h: 132.96,
			l: 132.76,
			t: 1655230200000,
			n: 1197
		},
		{
			v: 131037,
			vw: 132.7433,
			o: 132.84,
			c: 132.64,
			h: 132.8771,
			l: 132.62,
			t: 1655230260000,
			n: 1201
		},
		{
			v: 114248,
			vw: 132.7318,
			o: 132.65,
			c: 132.71,
			h: 132.81,
			l: 132.65,
			t: 1655230320000,
			n: 1159
		},
		{
			v: 106731,
			vw: 132.6558,
			o: 132.7,
			c: 132.5899,
			h: 132.71,
			l: 132.58,
			t: 1655230380000,
			n: 1039
		},
		{
			v: 105338,
			vw: 132.6073,
			o: 132.585,
			c: 132.6983,
			h: 132.7,
			l: 132.55,
			t: 1655230440000,
			n: 1229
		},
		{
			v: 90448,
			vw: 132.7421,
			o: 132.695,
			c: 132.745,
			h: 132.8,
			l: 132.68,
			t: 1655230500000,
			n: 938
		},
		{
			v: 91321,
			vw: 132.7707,
			o: 132.74,
			c: 132.75,
			h: 132.82,
			l: 132.715,
			t: 1655230560000,
			n: 930
		},
		{
			v: 71916,
			vw: 132.7345,
			o: 132.765,
			c: 132.69,
			h: 132.8,
			l: 132.69,
			t: 1655230620000,
			n: 836
		},
		{
			v: 89528,
			vw: 132.7783,
			o: 132.695,
			c: 132.805,
			h: 132.86,
			l: 132.675,
			t: 1655230680000,
			n: 995
		},
		{
			v: 69523,
			vw: 132.8194,
			o: 132.8199,
			c: 132.8524,
			h: 132.8642,
			l: 132.76,
			t: 1655230740000,
			n: 916
		},
		{
			v: 145952,
			vw: 132.8909,
			o: 132.86,
			c: 132.945,
			h: 132.9599,
			l: 132.76,
			t: 1655230800000,
			n: 1392
		},
		{
			v: 116114,
			vw: 132.9551,
			o: 132.95,
			c: 132.98,
			h: 133.005,
			l: 132.8994,
			t: 1655230860000,
			n: 1178
		},
		{
			v: 84862,
			vw: 132.9421,
			o: 132.98,
			c: 133.01,
			h: 133.01,
			l: 132.94,
			t: 1655230920000,
			n: 849
		},
		{
			v: 89518,
			vw: 132.9645,
			o: 133.01,
			c: 132.9801,
			h: 133.035,
			l: 132.9266,
			t: 1655230980000,
			n: 884
		},
		{
			v: 134510,
			vw: 132.9714,
			o: 132.98,
			c: 132.86,
			h: 133.085,
			l: 132.825,
			t: 1655231040000,
			n: 1279
		},
		{
			v: 90888,
			vw: 132.8859,
			o: 132.85,
			c: 132.8482,
			h: 132.93,
			l: 132.83,
			t: 1655231100000,
			n: 869
		},
		{
			v: 98554,
			vw: 132.8343,
			o: 132.85,
			c: 132.905,
			h: 132.92,
			l: 132.75,
			t: 1655231160000,
			n: 997
		},
		{
			v: 130414,
			vw: 132.9777,
			o: 132.9182,
			c: 132.9901,
			h: 133.05,
			l: 132.9,
			t: 1655231220000,
			n: 1334
		},
		{
			v: 101974,
			vw: 133.0216,
			o: 132.995,
			c: 133.0501,
			h: 133.06,
			l: 132.97,
			t: 1655231280000,
			n: 1130
		},
		{
			v: 120180,
			vw: 133.005,
			o: 133.0564,
			c: 132.955,
			h: 133.06,
			l: 132.9458,
			t: 1655231340000,
			n: 1286
		},
		{
			v: 128657,
			vw: 132.8833,
			o: 132.945,
			c: 132.76,
			h: 132.96,
			l: 132.76,
			t: 1655231400000,
			n: 1115
		},
		{
			v: 114852,
			vw: 132.7639,
			o: 132.7643,
			c: 132.76,
			h: 132.81,
			l: 132.69,
			t: 1655231460000,
			n: 1159
		},
		{
			v: 70691,
			vw: 132.7227,
			o: 132.75,
			c: 132.6899,
			h: 132.76,
			l: 132.68,
			t: 1655231520000,
			n: 912
		},
		{
			v: 98558,
			vw: 132.7597,
			o: 132.7,
			c: 132.8741,
			h: 132.88,
			l: 132.64,
			t: 1655231580000,
			n: 1023
		},
		{
			v: 133237,
			vw: 132.9343,
			o: 132.879,
			c: 132.98,
			h: 133.02,
			l: 132.84,
			t: 1655231640000,
			n: 1202
		},
		{
			v: 135791,
			vw: 132.9812,
			o: 132.96,
			c: 133.0399,
			h: 133.055,
			l: 132.89,
			t: 1655231700000,
			n: 1078
		},
		{
			v: 108719,
			vw: 133.0329,
			o: 133.05,
			c: 133.03,
			h: 133.08,
			l: 132.96,
			t: 1655231760000,
			n: 986
		},
		{
			v: 92680,
			vw: 133.0004,
			o: 133.02,
			c: 132.985,
			h: 133.06,
			l: 132.9401,
			t: 1655231820000,
			n: 944
		},
		{
			v: 145347,
			vw: 132.954,
			o: 132.96,
			c: 133.04,
			h: 133.04,
			l: 132.864,
			t: 1655231880000,
			n: 1092
		},
		{
			v: 159480,
			vw: 133.0613,
			o: 133.035,
			c: 132.98,
			h: 133.14,
			l: 132.9785,
			t: 1655231940000,
			n: 1427
		},
		{
			v: 197278,
			vw: 132.9067,
			o: 132.97,
			c: 132.89,
			h: 133.01,
			l: 132.8,
			t: 1655232000000,
			n: 1541
		},
		{
			v: 80019,
			vw: 132.8503,
			o: 132.8897,
			c: 132.78,
			h: 132.91,
			l: 132.78,
			t: 1655232060000,
			n: 930
		},
		{
			v: 175832,
			vw: 132.7621,
			o: 132.781,
			c: 132.73,
			h: 132.79,
			l: 132.7091,
			t: 1655232120000,
			n: 988
		},
		{
			v: 140815,
			vw: 132.7251,
			o: 132.73,
			c: 132.7323,
			h: 132.82,
			l: 132.65,
			t: 1655232180000,
			n: 1387
		},
		{
			v: 79276,
			vw: 132.7428,
			o: 132.7388,
			c: 132.7427,
			h: 132.78,
			l: 132.68,
			t: 1655232240000,
			n: 989
		},
		{
			v: 157443,
			vw: 132.7696,
			o: 132.735,
			c: 132.75,
			h: 132.85,
			l: 132.715,
			t: 1655232300000,
			n: 1271
		},
		{
			v: 123431,
			vw: 132.6366,
			o: 132.75,
			c: 132.6268,
			h: 132.75,
			l: 132.58,
			t: 1655232360000,
			n: 1258
		},
		{
			v: 90339,
			vw: 132.6425,
			o: 132.62,
			c: 132.6146,
			h: 132.695,
			l: 132.5982,
			t: 1655232420000,
			n: 1045
		},
		{
			v: 127285,
			vw: 132.487,
			o: 132.61,
			c: 132.435,
			h: 132.61,
			l: 132.41,
			t: 1655232480000,
			n: 1553
		},
		{
			v: 99097,
			vw: 132.4873,
			o: 132.4399,
			c: 132.4999,
			h: 132.5311,
			l: 132.4399,
			t: 1655232540000,
			n: 1099
		},
		{
			v: 158255,
			vw: 132.3938,
			o: 132.4991,
			c: 132.3551,
			h: 132.5045,
			l: 132.325,
			t: 1655232600000,
			n: 1584
		},
		{
			v: 155147,
			vw: 132.3844,
			o: 132.36,
			c: 132.5,
			h: 132.54,
			l: 132.285,
			t: 1655232660000,
			n: 1516
		},
		{
			v: 133312,
			vw: 132.5631,
			o: 132.5037,
			c: 132.575,
			h: 132.625,
			l: 132.474,
			t: 1655232720000,
			n: 1219
		},
		{
			v: 124817,
			vw: 132.6469,
			o: 132.5799,
			c: 132.605,
			h: 132.695,
			l: 132.5783,
			t: 1655232780000,
			n: 1133
		},
		{
			v: 137368,
			vw: 132.5714,
			o: 132.605,
			c: 132.505,
			h: 132.7,
			l: 132.49,
			t: 1655232840000,
			n: 1298
		},
		{
			v: 120263,
			vw: 132.4496,
			o: 132.49,
			c: 132.4175,
			h: 132.505,
			l: 132.3964,
			t: 1655232900000,
			n: 1225
		},
		{
			v: 148458,
			vw: 132.4693,
			o: 132.41,
			c: 132.47,
			h: 132.54,
			l: 132.38,
			t: 1655232960000,
			n: 1297
		},
		{
			v: 104979,
			vw: 132.4669,
			o: 132.4699,
			c: 132.475,
			h: 132.5182,
			l: 132.4,
			t: 1655233020000,
			n: 1043
		},
		{
			v: 89182,
			vw: 132.4319,
			o: 132.475,
			c: 132.43,
			h: 132.48,
			l: 132.385,
			t: 1655233080000,
			n: 958
		},
		{
			v: 91103,
			vw: 132.4131,
			o: 132.43,
			c: 132.4,
			h: 132.455,
			l: 132.37,
			t: 1655233140000,
			n: 1038
		},
		{
			v: 255643,
			vw: 132.3611,
			o: 132.395,
			c: 132.35,
			h: 132.455,
			l: 132.285,
			t: 1655233200000,
			n: 1710
		},
		{
			v: 127088,
			vw: 132.3946,
			o: 132.35,
			c: 132.44,
			h: 132.44,
			l: 132.3389,
			t: 1655233260000,
			n: 1142
		},
		{
			v: 139575,
			vw: 132.4108,
			o: 132.4299,
			c: 132.3,
			h: 132.485,
			l: 132.3,
			t: 1655233320000,
			n: 1381
		},
		{
			v: 110732,
			vw: 132.3626,
			o: 132.315,
			c: 132.3999,
			h: 132.435,
			l: 132.2803,
			t: 1655233380000,
			n: 1147
		},
		{
			v: 138176,
			vw: 132.4022,
			o: 132.39,
			c: 132.375,
			h: 132.48,
			l: 132.2901,
			t: 1655233440000,
			n: 1185
		},
		{
			v: 116152,
			vw: 132.3386,
			o: 132.376,
			c: 132.345,
			h: 132.41,
			l: 132.27,
			t: 1655233500000,
			n: 1192
		},
		{
			v: 161636,
			vw: 132.2776,
			o: 132.35,
			c: 132.28,
			h: 132.35,
			l: 132.22,
			t: 1655233560000,
			n: 1340
		},
		{
			v: 122304,
			vw: 132.2149,
			o: 132.305,
			c: 132.1768,
			h: 132.305,
			l: 132.155,
			t: 1655233620000,
			n: 1305
		},
		{
			v: 130980,
			vw: 132.1332,
			o: 132.18,
			c: 132.1267,
			h: 132.18,
			l: 132.09,
			t: 1655233680000,
			n: 1258
		},
		{
			v: 147285,
			vw: 132.1003,
			o: 132.13,
			c: 132.04,
			h: 132.18,
			l: 132.032,
			t: 1655233740000,
			n: 1298
		},
		{
			v: 117671,
			vw: 132.1277,
			o: 132.04,
			c: 132.1,
			h: 132.1799,
			l: 132.04,
			t: 1655233800000,
			n: 1075
		},
		{
			v: 229056,
			vw: 132.0945,
			o: 132.11,
			c: 132.07,
			h: 132.18,
			l: 132.0417,
			t: 1655233860000,
			n: 1368
		},
		{
			v: 187488,
			vw: 132.0112,
			o: 132.0703,
			c: 131.99,
			h: 132.085,
			l: 131.955,
			t: 1655233920000,
			n: 2428
		},
		{
			v: 150544,
			vw: 131.8935,
			o: 131.985,
			c: 131.835,
			h: 132,
			l: 131.8316,
			t: 1655233980000,
			n: 1578
		},
		{
			v: 142441,
			vw: 131.8997,
			o: 131.835,
			c: 131.885,
			h: 131.96,
			l: 131.8314,
			t: 1655234040000,
			n: 1201
		},
		{
			v: 108721,
			vw: 131.9156,
			o: 131.89,
			c: 131.9,
			h: 131.95,
			l: 131.88,
			t: 1655234100000,
			n: 1077
		},
		{
			v: 178336,
			vw: 131.9276,
			o: 131.9,
			c: 131.88,
			h: 131.97,
			l: 131.88,
			t: 1655234160000,
			n: 1350
		},
		{
			v: 128346,
			vw: 131.8518,
			o: 131.877,
			c: 131.835,
			h: 131.91,
			l: 131.79,
			t: 1655234220000,
			n: 1406
		},
		{
			v: 175324,
			vw: 131.7595,
			o: 131.835,
			c: 131.7499,
			h: 131.835,
			l: 131.71,
			t: 1655234280000,
			n: 1586
		},
		{
			v: 193355,
			vw: 131.7836,
			o: 131.74,
			c: 131.825,
			h: 131.835,
			l: 131.72,
			t: 1655234340000,
			n: 1515
		},
		{
			v: 242390,
			vw: 131.9064,
			o: 131.83,
			c: 131.915,
			h: 131.96,
			l: 131.82,
			t: 1655234400000,
			n: 1996
		},
		{
			v: 230936,
			vw: 131.9846,
			o: 131.915,
			c: 132.055,
			h: 132.07,
			l: 131.9,
			t: 1655234460000,
			n: 1852
		},
		{
			v: 181363,
			vw: 132.0294,
			o: 132.055,
			c: 132.04,
			h: 132.09,
			l: 131.96,
			t: 1655234520000,
			n: 1480
		},
		{
			v: 179827,
			vw: 131.9419,
			o: 132.035,
			c: 131.9394,
			h: 132.0494,
			l: 131.885,
			t: 1655234580000,
			n: 1674
		},
		{
			v: 135561,
			vw: 131.9663,
			o: 131.94,
			c: 131.965,
			h: 132.01,
			l: 131.92,
			t: 1655234640000,
			n: 1345
		},
		{
			v: 206328,
			vw: 132.1011,
			o: 131.965,
			c: 132.15,
			h: 132.22,
			l: 131.96,
			t: 1655234700000,
			n: 1687
		},
		{
			v: 112034,
			vw: 132.144,
			o: 132.1499,
			c: 132.14,
			h: 132.2,
			l: 132.06,
			t: 1655234760000,
			n: 1116
		},
		{
			v: 226100,
			vw: 132.2901,
			o: 132.14,
			c: 132.31,
			h: 132.37,
			l: 132.13,
			t: 1655234820000,
			n: 1813
		},
		{
			v: 208711,
			vw: 132.3523,
			o: 132.32,
			c: 132.335,
			h: 132.425,
			l: 132.29,
			t: 1655234880000,
			n: 1592
		},
		{
			v: 154087,
			vw: 132.4003,
			o: 132.34,
			c: 132.3887,
			h: 132.46,
			l: 132.3327,
			t: 1655234940000,
			n: 1235
		},
		{
			v: 327985,
			vw: 132.5598,
			o: 132.38,
			c: 132.6232,
			h: 132.71,
			l: 132.34,
			t: 1655235000000,
			n: 2632
		},
		{
			v: 200879,
			vw: 132.5478,
			o: 132.6167,
			c: 132.565,
			h: 132.68,
			l: 132.46,
			t: 1655235060000,
			n: 1683
		},
		{
			v: 217368,
			vw: 132.653,
			o: 132.58,
			c: 132.758,
			h: 132.7799,
			l: 132.55,
			t: 1655235120000,
			n: 1645
		},
		{
			v: 174932,
			vw: 132.6637,
			o: 132.7482,
			c: 132.614,
			h: 132.77,
			l: 132.5552,
			t: 1655235180000,
			n: 1559
		},
		{
			v: 178941,
			vw: 132.5673,
			o: 132.61,
			c: 132.5957,
			h: 132.63,
			l: 132.519,
			t: 1655235240000,
			n: 1348
		},
		{
			v: 194311,
			vw: 132.6881,
			o: 132.595,
			c: 132.725,
			h: 132.77,
			l: 132.58,
			t: 1655235300000,
			n: 1897
		},
		{
			v: 213660,
			vw: 132.7994,
			o: 132.715,
			c: 132.77,
			h: 132.865,
			l: 132.715,
			t: 1655235360000,
			n: 1619
		},
		{
			v: 355745,
			vw: 132.9481,
			o: 132.78,
			c: 133.07,
			h: 133.1,
			l: 132.76,
			t: 1655235420000,
			n: 2473
		},
		{
			v: 327179,
			vw: 133.1465,
			o: 133.07,
			c: 133.2242,
			h: 133.255,
			l: 133.04,
			t: 1655235480000,
			n: 2663
		},
		{
			v: 308589,
			vw: 133.2873,
			o: 133.225,
			c: 133.175,
			h: 133.4199,
			l: 133.135,
			t: 1655235540000,
			n: 2378
		},
		{
			v: 251808,
			vw: 133.2522,
			o: 133.17,
			c: 133.19,
			h: 133.34,
			l: 133.16,
			t: 1655235600000,
			n: 2281
		},
		{
			v: 226187,
			vw: 133.2648,
			o: 133.18,
			c: 133.4091,
			h: 133.41,
			l: 133.15,
			t: 1655235660000,
			n: 1949
		},
		{
			v: 195880,
			vw: 133.3643,
			o: 133.4,
			c: 133.255,
			h: 133.49,
			l: 133.215,
			t: 1655235720000,
			n: 1917
		},
		{
			v: 209326,
			vw: 133.1081,
			o: 133.25,
			c: 133.065,
			h: 133.25,
			l: 133.05,
			t: 1655235780000,
			n: 1950
		},
		{
			v: 269902,
			vw: 133.0157,
			o: 133.06,
			c: 132.965,
			h: 133.13,
			l: 132.9,
			t: 1655235840000,
			n: 2041
		},
		{
			v: 208387,
			vw: 132.9549,
			o: 132.95,
			c: 132.965,
			h: 133.03,
			l: 132.9,
			t: 1655235900000,
			n: 2065
		},
		{
			v: 252229,
			vw: 133.0829,
			o: 132.9616,
			c: 133.31,
			h: 133.315,
			l: 132.92,
			t: 1655235960000,
			n: 2148
		},
		{
			v: 221535,
			vw: 133.2541,
			o: 133.31,
			c: 133.21,
			h: 133.37,
			l: 133.16,
			t: 1655236020000,
			n: 2101
		},
		{
			v: 240255,
			vw: 133.2453,
			o: 133.24,
			c: 133.34,
			h: 133.36,
			l: 133.16,
			t: 1655236080000,
			n: 2149
		},
		{
			v: 261228,
			vw: 133.3681,
			o: 133.35,
			c: 133.34,
			h: 133.48,
			l: 133.285,
			t: 1655236140000,
			n: 2501
		},
		{
			v: 474907,
			vw: 133.1723,
			o: 133.33,
			c: 133,
			h: 133.49,
			l: 132.989,
			t: 1655236200000,
			n: 5155
		},
		{
			v: 334681,
			vw: 133.0804,
			o: 132.995,
			c: 132.99,
			h: 133.2,
			l: 132.96,
			t: 1655236260000,
			n: 3208
		},
		{
			v: 265489,
			vw: 133.1846,
			o: 133,
			c: 133.22,
			h: 133.31,
			l: 133,
			t: 1655236320000,
			n: 2500
		},
		{
			v: 259322,
			vw: 133.0847,
			o: 133.23,
			c: 132.9202,
			h: 133.28,
			l: 132.92,
			t: 1655236380000,
			n: 2553
		},
		{
			v: 651826,
			vw: 132.9088,
			o: 132.925,
			c: 132.87,
			h: 133.18,
			l: 132.67,
			t: 1655236440000,
			n: 5247
		},
		{
			v: 549840,
			vw: 132.8209,
			o: 132.86,
			c: 132.79,
			h: 132.91,
			l: 132.71,
			t: 1655236500000,
			n: 4672
		},
		{
			v: 529134,
			vw: 132.6862,
			o: 132.79,
			c: 132.58,
			h: 132.8,
			l: 132.58,
			t: 1655236560000,
			n: 4152
		},
		{
			v: 533268,
			vw: 132.5809,
			o: 132.5801,
			c: 132.585,
			h: 132.65,
			l: 132.52,
			t: 1655236620000,
			n: 3843
		},
		{
			v: 640628,
			vw: 132.6594,
			o: 132.585,
			c: 132.63,
			h: 132.72,
			l: 132.585,
			t: 1655236680000,
			n: 4661
		},
		{
			v: 1221859,
			vw: 132.6779,
			o: 132.63,
			c: 132.86,
			h: 132.87,
			l: 132.56,
			t: 1655236740000,
			n: 7509
		},
		{
			v: 1828787,
			vw: 132.7598,
			o: 132.76,
			c: 132.76,
			h: 132.8,
			l: 132.62,
			t: 1655236800000,
			n: 197
		},
		{
			v: 12107,
			vw: 132.7375,
			o: 132.76,
			c: 132.74,
			h: 132.76,
			l: 132.66,
			t: 1655236860000,
			n: 113
		},
		{
			v: 23331,
			vw: 132.748,
			o: 132.72,
			c: 132.72,
			h: 132.76,
			l: 132.71,
			t: 1655236920000,
			n: 85
		},
		{
			v: 21215,
			vw: 132.7148,
			o: 132.72,
			c: 132.66,
			h: 132.74,
			l: 132.63,
			t: 1655236980000,
			n: 120
		},
		{
			v: 1028,
			vw: 132.6622,
			o: 132.66,
			c: 132.66,
			h: 132.66,
			l: 132.66,
			t: 1655237040000,
			n: 46
		},
		{
			v: 13620,
			vw: 132.7237,
			o: 132.76,
			c: 132.66,
			h: 132.76,
			l: 132.65,
			t: 1655237100000,
			n: 86
		},
		{
			v: 2185,
			vw: 132.6922,
			o: 132.69,
			c: 132.73,
			h: 132.73,
			l: 132.68,
			t: 1655237160000,
			n: 45
		},
		{
			v: 23125,
			vw: 132.7571,
			o: 132.76,
			c: 132.69,
			h: 132.76,
			l: 132.69,
			t: 1655237220000,
			n: 38
		},
		{
			v: 4135,
			vw: 132.7308,
			o: 132.7,
			c: 132.73,
			h: 132.75,
			l: 132.7,
			t: 1655237280000,
			n: 40
		},
		{
			v: 1068,
			vw: 132.7546,
			o: 132.75,
			c: 132.78,
			h: 132.78,
			l: 132.75,
			t: 1655237340000,
			n: 43
		},
		{
			v: 49796,
			vw: 132.7601,
			o: 132.78,
			c: 132.78,
			h: 132.78,
			l: 132.76,
			t: 1655237400000,
			n: 51
		},
		{
			v: 15568,
			vw: 132.7498,
			o: 132.75,
			c: 132.73,
			h: 132.75,
			l: 132.73,
			t: 1655237460000,
			n: 59
		},
		{
			v: 5174,
			vw: 132.776,
			o: 132.77,
			c: 132.78,
			h: 132.8,
			l: 132.75,
			t: 1655237520000,
			n: 50
		},
		{
			v: 8853,
			vw: 132.8435,
			o: 132.79,
			c: 132.83,
			h: 132.85,
			l: 132.79,
			t: 1655237580000,
			n: 69
		},
		{
			v: 6797,
			vw: 132.8351,
			o: 132.8,
			c: 132.8,
			h: 132.82,
			l: 132.76,
			t: 1655237640000,
			n: 512
		},
		{
			v: 5882,
			vw: 132.7688,
			o: 132.79,
			c: 132.76,
			h: 132.8,
			l: 132.76,
			t: 1655237700000,
			n: 75
		},
		{
			v: 3840,
			vw: 132.7131,
			o: 132.75,
			c: 132.68,
			h: 132.75,
			l: 132.68,
			t: 1655237760000,
			n: 77
		},
		{
			v: 226984,
			vw: 132.7596,
			o: 132.66,
			c: 132.68,
			h: 132.68,
			l: 132.66,
			t: 1655237820000,
			n: 34
		},
		{
			v: 1304,
			vw: 132.6796,
			o: 132.68,
			c: 132.68,
			h: 132.68,
			l: 132.68,
			t: 1655237880000,
			n: 40
		},
		{
			v: 6630,
			vw: 132.7149,
			o: 132.76,
			c: 132.66,
			h: 132.76,
			l: 132.66,
			t: 1655237940000,
			n: 46
		},
		{
			v: 179,
			vw: 132.6821,
			o: 132.69,
			c: 132.69,
			h: 132.69,
			l: 132.69,
			t: 1655238000000,
			n: 18
		},
		{
			v: 258,
			vw: 132.6881,
			o: 132.69,
			c: 132.69,
			h: 132.69,
			l: 132.69,
			t: 1655238060000,
			n: 14
		},
		{
			v: 309,
			vw: 132.6789,
			o: 132.67,
			c: 132.67,
			h: 132.67,
			l: 132.67,
			t: 1655238120000,
			n: 24
		},
		{
			v: 202,
			vw: 132.7041,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655238360000,
			n: 30
		},
		{
			v: 13393,
			vw: 132.7585,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655238420000,
			n: 38
		},
		{
			v: 587,
			vw: 132.7022,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655238480000,
			n: 31
		},
		{
			v: 884,
			vw: 132.6891,
			o: 132.71,
			c: 132.68,
			h: 132.71,
			l: 132.68,
			t: 1655238540000,
			n: 37
		},
		{
			v: 484,
			vw: 132.7003,
			o: 132.7,
			c: 132.72,
			h: 132.72,
			l: 132.7,
			t: 1655238600000,
			n: 26
		},
		{
			v: 581,
			vw: 132.6992,
			o: 132.7,
			c: 132.7,
			h: 132.7,
			l: 132.7,
			t: 1655238660000,
			n: 20
		},
		{
			v: 6532,
			vw: 132.7109,
			o: 132.7,
			c: 132.72,
			h: 132.72,
			l: 132.68,
			t: 1655238720000,
			n: 64
		},
		{
			v: 103204,
			vw: 132.7595,
			o: 132.69,
			c: 132.71,
			h: 132.76,
			l: 132.69,
			t: 1655238780000,
			n: 35
		},
		{
			v: 269815,
			vw: 132.7598,
			o: 132.7,
			c: 132.72,
			h: 132.76,
			l: 132.7,
			t: 1655238840000,
			n: 34
		},
		{
			v: 10769,
			vw: 132.758,
			o: 132.72,
			c: 132.75,
			h: 132.76,
			l: 132.72,
			t: 1655238900000,
			n: 55
		},
		{
			v: 863,
			vw: 132.7483,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655238960000,
			n: 29
		},
		{
			v: 950,
			vw: 132.7535,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655239020000,
			n: 31
		},
		{
			v: 4995,
			vw: 132.7869,
			o: 132.76,
			c: 132.79,
			h: 132.79,
			l: 132.76,
			t: 1655239140000,
			n: 23
		},
		{
			v: 1523,
			vw: 132.772,
			o: 132.78,
			c: 132.77,
			h: 132.78,
			l: 132.77,
			t: 1655239200000,
			n: 32
		},
		{
			v: 5905,
			vw: 132.7601,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655239260000,
			n: 39
		},
		{
			v: 3088,
			vw: 132.7816,
			o: 132.7601,
			c: 132.8,
			h: 132.8,
			l: 132.7601,
			t: 1655239320000,
			n: 47
		},
		{
			v: 1011,
			vw: 132.8184,
			o: 132.77,
			c: 132.83,
			h: 132.83,
			l: 132.77,
			t: 1655239380000,
			n: 45
		},
		{
			v: 4336,
			vw: 132.8109,
			o: 132.81,
			c: 132.81,
			h: 132.81,
			l: 132.81,
			t: 1655239440000,
			n: 35
		},
		{
			v: 516,
			vw: 132.7957,
			o: 132.8,
			c: 132.8,
			h: 132.8,
			l: 132.8,
			t: 1655239500000,
			n: 36
		},
		{
			v: 2651,
			vw: 132.7795,
			o: 132.79,
			c: 132.77,
			h: 132.8,
			l: 132.77,
			t: 1655239680000,
			n: 42
		},
		{
			v: 4015,
			vw: 132.8136,
			o: 132.77,
			c: 132.87,
			h: 132.87,
			l: 132.77,
			t: 1655239740000,
			n: 58
		},
		{
			v: 390,
			vw: 132.8555,
			o: 132.86,
			c: 132.86,
			h: 132.86,
			l: 132.86,
			t: 1655239800000,
			n: 22
		},
		{
			v: 895,
			vw: 132.8572,
			o: 132.86,
			c: 132.86,
			h: 132.86,
			l: 132.85,
			t: 1655239860000,
			n: 33
		},
		{
			v: 451,
			vw: 132.8508,
			o: 132.85,
			c: 132.85,
			h: 132.85,
			l: 132.85,
			t: 1655239920000,
			n: 25
		},
		{
			v: 174,
			vw: 132.8505,
			o: 132.85,
			c: 132.85,
			h: 132.85,
			l: 132.85,
			t: 1655239980000,
			n: 14
		},
		{
			v: 3386,
			vw: 132.3712,
			o: 132.86,
			c: 132.85,
			h: 132.86,
			l: 132.85,
			t: 1655240040000,
			n: 40
		},
		{
			v: 8961,
			vw: 132.8473,
			o: 132.85,
			c: 132.82,
			h: 132.85,
			l: 132.82,
			t: 1655240100000,
			n: 45
		},
		{
			v: 1174,
			vw: 131.4598,
			o: 132.84,
			c: 132.85,
			h: 132.85,
			l: 132.84,
			t: 1655240220000,
			n: 26
		},
		{
			v: 480,
			vw: 132.8404,
			o: 132.85,
			c: 132.84,
			h: 132.85,
			l: 132.84,
			t: 1655240280000,
			n: 23
		},
		{
			v: 308,
			vw: 132.8334,
			o: 132.84,
			c: 132.84,
			h: 132.84,
			l: 132.84,
			t: 1655240340000,
			n: 25
		},
		{
			v: 429,
			vw: 132.8173,
			o: 132.81,
			c: 132.81,
			h: 132.81,
			l: 132.81,
			t: 1655240400000,
			n: 20
		},
		{
			v: 665,
			vw: 132.8333,
			o: 132.81,
			c: 132.85,
			h: 132.85,
			l: 132.81,
			t: 1655240580000,
			n: 30
		},
		{
			v: 235,
			vw: 132.8394,
			o: 132.85,
			c: 132.85,
			h: 132.85,
			l: 132.85,
			t: 1655240640000,
			n: 25
		},
		{
			v: 1050,
			vw: 132.821,
			o: 132.82,
			c: 132.82,
			h: 132.82,
			l: 132.82,
			t: 1655240700000,
			n: 28
		},
		{
			v: 667,
			vw: 132.8373,
			o: 132.82,
			c: 132.85,
			h: 132.85,
			l: 132.82,
			t: 1655240760000,
			n: 18
		},
		{
			v: 195,
			vw: 132.8235,
			o: 132.82,
			c: 132.82,
			h: 132.82,
			l: 132.82,
			t: 1655240820000,
			n: 14
		},
		{
			v: 1583,
			vw: 132.8164,
			o: 132.82,
			c: 132.81,
			h: 132.82,
			l: 132.81,
			t: 1655240880000,
			n: 21
		},
		{
			v: 1673,
			vw: 132.8474,
			o: 132.85,
			c: 132.85,
			h: 132.85,
			l: 132.85,
			t: 1655241000000,
			n: 34
		},
		{
			v: 1706,
			vw: 132.8467,
			o: 132.85,
			c: 132.84,
			h: 132.86,
			l: 132.84,
			t: 1655241060000,
			n: 38
		},
		{
			v: 150543,
			vw: 132.7603,
			o: 132.76,
			c: 132.84,
			h: 132.84,
			l: 132.76,
			t: 1655241120000,
			n: 34
		},
		{
			v: 4049,
			vw: 132.8574,
			o: 132.81,
			c: 132.9,
			h: 132.9,
			l: 132.81,
			t: 1655241180000,
			n: 68
		},
		{
			v: 297,
			vw: 132.8888,
			o: 132.9099,
			c: 132.9099,
			h: 132.9099,
			l: 132.9099,
			t: 1655241300000,
			n: 21
		},
		{
			v: 556,
			vw: 132.8799,
			o: 132.88,
			c: 132.88,
			h: 132.88,
			l: 132.88,
			t: 1655241420000,
			n: 27
		},
		{
			v: 761,
			vw: 132.8882,
			o: 132.89,
			c: 132.9,
			h: 132.9,
			l: 132.89,
			t: 1655241480000,
			n: 40
		},
		{
			v: 753,
			vw: 132.8678,
			o: 132.89,
			c: 132.9,
			h: 132.9,
			l: 132.79,
			t: 1655241660000,
			n: 34
		},
		{
			v: 237,
			vw: 132.8849,
			o: 132.88,
			c: 132.88,
			h: 132.88,
			l: 132.88,
			t: 1655241720000,
			n: 17
		},
		{
			v: 1521,
			vw: 132.8783,
			o: 132.88,
			c: 132.88,
			h: 132.88,
			l: 132.88,
			t: 1655241900000,
			n: 25
		},
		{
			v: 373,
			vw: 132.8665,
			o: 132.87,
			c: 132.87,
			h: 132.87,
			l: 132.87,
			t: 1655241960000,
			n: 26
		},
		{
			v: 662,
			vw: 132.8567,
			o: 132.87,
			c: 132.87,
			h: 132.87,
			l: 132.87,
			t: 1655242320000,
			n: 31
		},
		{
			v: 810,
			vw: 132.8333,
			o: 132.83,
			c: 132.82,
			h: 132.83,
			l: 132.82,
			t: 1655242380000,
			n: 32
		},
		{
			v: 563,
			vw: 132.8035,
			o: 132.81,
			c: 132.81,
			h: 132.81,
			l: 132.81,
			t: 1655242440000,
			n: 48
		},
		{
			v: 2271,
			vw: 132.7632,
			o: 132.76,
			c: 132.76,
			h: 132.76,
			l: 132.76,
			t: 1655242500000,
			n: 64
		},
		{
			v: 376,
			vw: 132.783,
			o: 132.79,
			c: 132.79,
			h: 132.79,
			l: 132.79,
			t: 1655242560000,
			n: 40
		},
		{
			v: 1183,
			vw: 132.7491,
			o: 132.76,
			c: 132.71,
			h: 132.77,
			l: 132.71,
			t: 1655242620000,
			n: 68
		},
		{
			v: 1508,
			vw: 132.7251,
			o: 132.71,
			c: 132.74,
			h: 132.74,
			l: 132.71,
			t: 1655242680000,
			n: 47
		},
		{
			v: 1686,
			vw: 132.7462,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655242740000,
			n: 33
		},
		{
			v: 1709,
			vw: 132.7489,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655242800000,
			n: 35
		},
		{
			v: 674,
			vw: 132.7888,
			o: 132.79,
			c: 132.79,
			h: 132.79,
			l: 132.79,
			t: 1655242860000,
			n: 19
		},
		{
			v: 2053,
			vw: 132.7949,
			o: 132.79,
			c: 132.8,
			h: 132.8,
			l: 132.79,
			t: 1655242920000,
			n: 19
		},
		{
			v: 410,
			vw: 132.83,
			o: 132.88,
			c: 132.88,
			h: 132.88,
			l: 132.88,
			t: 1655242980000,
			n: 29
		},
		{
			v: 416,
			vw: 132.8715,
			o: 132.88,
			c: 132.88,
			h: 132.88,
			l: 132.88,
			t: 1655243040000,
			n: 10
		},
		{
			v: 763,
			vw: 132.8149,
			o: 132.83,
			c: 132.79,
			h: 132.83,
			l: 132.79,
			t: 1655243100000,
			n: 31
		},
		{
			v: 5527,
			vw: 132.6989,
			o: 132.73,
			c: 132.75,
			h: 132.75,
			l: 132.69,
			t: 1655243220000,
			n: 58
		},
		{
			v: 232,
			vw: 132.7396,
			o: 132.75,
			c: 132.75,
			h: 132.75,
			l: 132.75,
			t: 1655243820000,
			n: 19
		},
		{
			v: 886,
			vw: 132.7278,
			o: 132.73,
			c: 132.72,
			h: 132.75,
			l: 132.72,
			t: 1655243940000,
			n: 17
		},
		{
			v: 3900,
			vw: 132.7762,
			o: 132.74,
			c: 132.88,
			h: 132.88,
			l: 132.74,
			t: 1655244000000,
			n: 46
		},
		{
			v: 2209,
			vw: 132.8749,
			o: 132.89,
			c: 132.89,
			h: 132.89,
			l: 132.85,
			t: 1655244060000,
			n: 37
		},
		{
			v: 3215,
			vw: 132.8647,
			o: 132.78,
			c: 132.91,
			h: 132.91,
			l: 132.77,
			t: 1655244120000,
			n: 42
		},
		{
			v: 3059,
			vw: 132.9169,
			o: 132.91,
			c: 132.98,
			h: 132.98,
			l: 132.9,
			t: 1655244180000,
			n: 26
		},
		{
			v: 3632,
			vw: 133.0001,
			o: 133,
			c: 133.05,
			h: 133.05,
			l: 132.99,
			t: 1655244300000,
			n: 55
		},
		{
			v: 994,
			vw: 132.9949,
			o: 132.98,
			c: 133.02,
			h: 133.02,
			l: 132.96,
			t: 1655244420000,
			n: 22
		},
		{
			v: 1308,
			vw: 133.0906,
			o: 133.1,
			c: 133.1,
			h: 133.1,
			l: 133.1,
			t: 1655244480000,
			n: 22
		},
		{
			v: 769,
			vw: 133.0162,
			o: 133.1,
			c: 133,
			h: 133.1,
			l: 133,
			t: 1655244540000,
			n: 22
		},
		{
			v: 1179,
			vw: 132.9723,
			o: 132.95,
			c: 133,
			h: 133,
			l: 132.95,
			t: 1655244660000,
			n: 18
		},
		{
			v: 1487,
			vw: 133.0661,
			o: 133.1,
			c: 133.05,
			h: 133.1,
			l: 133.05,
			t: 1655244720000,
			n: 23
		},
		{
			v: 1500,
			vw: 133.0104,
			o: 133.01,
			c: 133,
			h: 133.01,
			l: 133,
			t: 1655244780000,
			n: 22
		},
		{
			v: 960,
			vw: 133.0632,
			o: 133.06,
			c: 133.06,
			h: 133.06,
			l: 133.06,
			t: 1655244900000,
			n: 23
		},
		{
			v: 852,
			vw: 133.0375,
			o: 133.03,
			c: 133.03,
			h: 133.03,
			l: 133.03,
			t: 1655244960000,
			n: 27
		},
		{
			v: 1789,
			vw: 133.0075,
			o: 133.03,
			c: 133,
			h: 133.03,
			l: 132.97,
			t: 1655245020000,
			n: 21
		},
		{
			v: 7063,
			vw: 132.8325,
			o: 132.9,
			c: 133.02,
			h: 133.02,
			l: 132.78,
			t: 1655245080000,
			n: 54
		},
		{
			v: 210,
			vw: 132.8512,
			o: 132.8,
			c: 132.8,
			h: 132.8,
			l: 132.8,
			t: 1655245140000,
			n: 16
		},
		{
			v: 610,
			vw: 132.9142,
			o: 132.9,
			c: 132.9,
			h: 132.9,
			l: 132.9,
			t: 1655245200000,
			n: 22
		},
		{
			v: 2228,
			vw: 132.9532,
			o: 132.86,
			c: 132.98,
			h: 132.98,
			l: 132.86,
			t: 1655245260000,
			n: 26
		},
		{
			v: 216,
			vw: 132.9847,
			o: 132.98,
			c: 132.98,
			h: 132.98,
			l: 132.98,
			t: 1655245320000,
			n: 14
		},
		{
			v: 7354,
			vw: 133.0014,
			o: 132.9,
			c: 132.99,
			h: 133.02,
			l: 132.9,
			t: 1655245380000,
			n: 47
		},
		{
			v: 864,
			vw: 133.008,
			o: 133.02,
			c: 132.99,
			h: 133.02,
			l: 132.99,
			t: 1655245440000,
			n: 34
		},
		{
			v: 2961,
			vw: 132.9719,
			o: 133.01,
			c: 132.88,
			h: 133.02,
			l: 132.88,
			t: 1655245500000,
			n: 45
		},
		{
			v: 4172,
			vw: 132.8756,
			o: 132.89,
			c: 132.92,
			h: 132.92,
			l: 132.81,
			t: 1655245560000,
			n: 49
		},
		{
			v: 963,
			vw: 132.9185,
			o: 132.91,
			c: 132.92,
			h: 132.98,
			l: 132.9,
			t: 1655245620000,
			n: 21
		},
		{
			v: 1658,
			vw: 132.9773,
			o: 132.98,
			c: 133,
			h: 133,
			l: 132.95,
			t: 1655245680000,
			n: 24
		},
		{
			v: 2395,
			vw: 132.9987,
			o: 133,
			c: 133.02,
			h: 133.02,
			l: 132.94,
			t: 1655245740000,
			n: 22
		},
		{
			v: 1337,
			vw: 133.0147,
			o: 133.02,
			c: 133.02,
			h: 133.02,
			l: 133.02,
			t: 1655245800000,
			n: 27
		},
		{
			v: 1655,
			vw: 133.0175,
			o: 133.02,
			c: 133.02,
			h: 133.02,
			l: 133,
			t: 1655245860000,
			n: 24
		},
		{
			v: 4386,
			vw: 133.0484,
			o: 133.02,
			c: 133.03,
			h: 133.09,
			l: 133.02,
			t: 1655245920000,
			n: 32
		},
		{
			v: 551,
			vw: 133.0554,
			o: 133.08,
			c: 133.04,
			h: 133.08,
			l: 133.04,
			t: 1655245980000,
			n: 11
		},
		{
			v: 1190,
			vw: 133.0545,
			o: 133.07,
			c: 133.05,
			h: 133.07,
			l: 133.05,
			t: 1655246040000,
			n: 13
		},
		{
			v: 2959,
			vw: 133.0889,
			o: 133.08,
			c: 133.1,
			h: 133.1,
			l: 133.08,
			t: 1655246100000,
			n: 20
		},
		{
			v: 426,
			vw: 133.0913,
			o: 133.1,
			c: 133.1,
			h: 133.1,
			l: 133.1,
			t: 1655246160000,
			n: 10
		},
		{
			v: 1365,
			vw: 133.0915,
			o: 133.09,
			c: 133.09,
			h: 133.11,
			l: 133.09,
			t: 1655246220000,
			n: 31
		},
		{
			v: 1225,
			vw: 133.1003,
			o: 133.1,
			c: 133.11,
			h: 133.11,
			l: 133.1,
			t: 1655246280000,
			n: 17
		},
		{
			v: 728,
			vw: 133.0974,
			o: 133.12,
			c: 133.14,
			h: 133.14,
			l: 133.12,
			t: 1655246340000,
			n: 26
		},
		{
			v: 2042,
			vw: 133.1736,
			o: 133.15,
			c: 133.19,
			h: 133.19,
			l: 133.15,
			t: 1655246400000,
			n: 34
		},
		{
			v: 3822,
			vw: 133.2154,
			o: 133.2,
			c: 133.2,
			h: 133.24,
			l: 133.19,
			t: 1655246460000,
			n: 40
		},
		{
			v: 5438,
			vw: 133.2443,
			o: 133.2,
			c: 133.27,
			h: 133.27,
			l: 133.2,
			t: 1655246520000,
			n: 38
		},
		{
			v: 5295,
			vw: 133.2873,
			o: 133.29,
			c: 133.2,
			h: 133.3,
			l: 133.2,
			t: 1655246580000,
			n: 35
		},
		{
			v: 208,
			vw: 133.2486,
			o: 133.25,
			c: 133.25,
			h: 133.25,
			l: 133.25,
			t: 1655246640000,
			n: 15
		},
		{
			v: 1073,
			vw: 133.2779,
			o: 133.25,
			c: 133.29,
			h: 133.29,
			l: 133.25,
			t: 1655246700000,
			n: 16
		},
		{
			v: 1833,
			vw: 133.2534,
			o: 133.26,
			c: 133.25,
			h: 133.26,
			l: 133.25,
			t: 1655246760000,
			n: 17
		},
		{
			v: 394,
			vw: 133.2036,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655246820000,
			n: 18
		},
		{
			v: 664,
			vw: 133.2375,
			o: 133.24,
			c: 133.24,
			h: 133.24,
			l: 133.24,
			t: 1655246940000,
			n: 19
		},
		{
			v: 3858,
			vw: 133.1805,
			o: 133.2,
			c: 133.12,
			h: 133.2,
			l: 133.12,
			t: 1655247000000,
			n: 38
		},
		{
			v: 744,
			vw: 133.1562,
			o: 133.16,
			c: 133.16,
			h: 133.16,
			l: 133.16,
			t: 1655247060000,
			n: 11
		},
		{
			v: 3707,
			vw: 133.1364,
			o: 133.15,
			c: 133.1,
			h: 133.16,
			l: 133.1,
			t: 1655247120000,
			n: 41
		},
		{
			v: 4776,
			vw: 133.0608,
			o: 133.09,
			c: 133.04,
			h: 133.09,
			l: 133.04,
			t: 1655247180000,
			n: 29
		},
		{
			v: 2222,
			vw: 133.0423,
			o: 133.05,
			c: 133.05,
			h: 133.05,
			l: 133.04,
			t: 1655247240000,
			n: 14
		},
		{
			v: 4166,
			vw: 133.1073,
			o: 133.1,
			c: 133.1,
			h: 133.11,
			l: 133.1,
			t: 1655247360000,
			n: 17
		},
		{
			v: 2107,
			vw: 133.0977,
			o: 133.0801,
			c: 133.07,
			h: 133.14,
			l: 133.07,
			t: 1655247480000,
			n: 25
		},
		{
			v: 1972,
			vw: 133.1035,
			o: 133.15,
			c: 133.1,
			h: 133.15,
			l: 133.1,
			t: 1655247600000,
			n: 17
		},
		{
			v: 1001,
			vw: 133.1341,
			o: 133.13,
			c: 133.16,
			h: 133.16,
			l: 133.13,
			t: 1655247780000,
			n: 20
		},
		{
			v: 847,
			vw: 133.1519,
			o: 133.15,
			c: 133.16,
			h: 133.16,
			l: 133.15,
			t: 1655247900000,
			n: 23
		},
		{
			v: 319,
			vw: 133.125,
			o: 133.15,
			c: 133.1,
			h: 133.15,
			l: 133.1,
			t: 1655247960000,
			n: 16
		},
		{
			v: 702,
			vw: 133.1395,
			o: 133.14,
			c: 133.15,
			h: 133.15,
			l: 133.14,
			t: 1655248200000,
			n: 19
		},
		{
			v: 625,
			vw: 133.1082,
			o: 133.1,
			c: 133.1,
			h: 133.1,
			l: 133.1,
			t: 1655248260000,
			n: 28
		},
		{
			v: 672,
			vw: 133.086,
			o: 133.08,
			c: 133.08,
			h: 133.08,
			l: 133.08,
			t: 1655248320000,
			n: 18
		},
		{
			v: 1617,
			vw: 133.0742,
			o: 133.09,
			c: 133.06,
			h: 133.09,
			l: 133.06,
			t: 1655248380000,
			n: 27
		},
		{
			v: 5058,
			vw: 133.0335,
			o: 133.05,
			c: 133.02,
			h: 133.05,
			l: 133.02,
			t: 1655248440000,
			n: 41
		},
		{
			v: 886,
			vw: 133.0639,
			o: 133.06,
			c: 133.07,
			h: 133.07,
			l: 133.06,
			t: 1655248500000,
			n: 22
		},
		{
			v: 8510,
			vw: 133.008,
			o: 133.07,
			c: 133,
			h: 133.07,
			l: 133,
			t: 1655248560000,
			n: 44
		},
		{
			v: 823,
			vw: 133.008,
			o: 133,
			c: 133.04,
			h: 133.04,
			l: 133,
			t: 1655248620000,
			n: 26
		},
		{
			v: 2351,
			vw: 133.0244,
			o: 133.04,
			c: 133,
			h: 133.04,
			l: 133,
			t: 1655248740000,
			n: 27
		},
		{
			v: 446,
			vw: 133.02,
			o: 133.01,
			c: 133.01,
			h: 133.01,
			l: 133.01,
			t: 1655248800000,
			n: 18
		},
		{
			v: 4900,
			vw: 133.1064,
			o: 133.05,
			c: 133.2,
			h: 133.25,
			l: 133.01,
			t: 1655248860000,
			n: 49
		},
		{
			v: 229,
			vw: 133.071,
			o: 133.05,
			c: 133.05,
			h: 133.05,
			l: 133.05,
			t: 1655248920000,
			n: 17
		},
		{
			v: 837,
			vw: 133.1499,
			o: 133.15,
			c: 133.17,
			h: 133.17,
			l: 133.15,
			t: 1655248980000,
			n: 31
		},
		{
			v: 1652,
			vw: 133.1919,
			o: 133.18,
			c: 133.23,
			h: 133.23,
			l: 133.17,
			t: 1655249040000,
			n: 23
		},
		{
			v: 2515,
			vw: 133.2588,
			o: 133.24,
			c: 133.35,
			h: 133.35,
			l: 133.24,
			t: 1655249100000,
			n: 38
		},
		{
			v: 7410,
			vw: 133.3727,
			o: 133.37,
			c: 133.3,
			h: 133.46,
			l: 133.3,
			t: 1655249160000,
			n: 50
		},
		{
			v: 6817,
			vw: 133.3154,
			o: 133.3,
			c: 133.27,
			h: 133.35,
			l: 133.27,
			t: 1655249220000,
			n: 42
		},
		{
			v: 4910,
			vw: 133.3143,
			o: 133.3,
			c: 133.35,
			h: 133.35,
			l: 133.3,
			t: 1655249280000,
			n: 26
		},
		{
			v: 4057,
			vw: 133.347,
			o: 133.35,
			c: 133.35,
			h: 133.35,
			l: 133.32,
			t: 1655249340000,
			n: 27
		},
		{
			v: 1069,
			vw: 133.3076,
			o: 133.31,
			c: 133.32,
			h: 133.32,
			l: 133.3,
			t: 1655249400000,
			n: 29
		},
		{
			v: 1239,
			vw: 133.2586,
			o: 133.27,
			c: 133.29,
			h: 133.29,
			l: 133.27,
			t: 1655249460000,
			n: 36
		},
		{
			v: 377,
			vw: 133.2353,
			o: 133.25,
			c: 133.25,
			h: 133.25,
			l: 133.25,
			t: 1655249520000,
			n: 16
		},
		{
			v: 488,
			vw: 133.167,
			o: 133.17,
			c: 133.17,
			h: 133.17,
			l: 133.17,
			t: 1655249580000,
			n: 22
		},
		{
			v: 147,
			vw: 133.1613,
			o: 133.14,
			c: 133.14,
			h: 133.14,
			l: 133.14,
			t: 1655249640000,
			n: 16
		},
		{
			v: 217,
			vw: 133.2222,
			o: 133.21,
			c: 133.21,
			h: 133.21,
			l: 133.21,
			t: 1655249700000,
			n: 17
		},
		{
			v: 1540,
			vw: 133.1925,
			o: 133.19,
			c: 133.19,
			h: 133.19,
			l: 133.19,
			t: 1655249760000,
			n: 7
		},
		{
			v: 621,
			vw: 133.1958,
			o: 133.19,
			c: 133.2,
			h: 133.2,
			l: 133.19,
			t: 1655249820000,
			n: 20
		},
		{
			v: 2205,
			vw: 133.2123,
			o: 133.2,
			c: 133.25,
			h: 133.25,
			l: 133.2,
			t: 1655249940000,
			n: 20
		},
		{
			v: 601,
			vw: 133.2296,
			o: 133.23,
			c: 133.2,
			h: 133.23,
			l: 133.2,
			t: 1655250060000,
			n: 25
		},
		{
			v: 419,
			vw: 133.2278,
			o: 133.22,
			c: 133.22,
			h: 133.22,
			l: 133.22,
			t: 1655250180000,
			n: 21
		},
		{
			v: 1175,
			vw: 133.2051,
			o: 133.2,
			c: 133.2,
			h: 133.2,
			l: 133.2,
			t: 1655250240000,
			n: 18
		},
		{
			v: 531,
			vw: 133.2441,
			o: 133.25,
			c: 133.25,
			h: 133.25,
			l: 133.25,
			t: 1655250300000,
			n: 17
		},
		{
			v: 7988,
			vw: 133.2543,
			o: 133.25,
			c: 133.3,
			h: 133.3,
			l: 133.25,
			t: 1655250360000,
			n: 37
		},
		{
			v: 2631,
			vw: 133.3573,
			o: 133.31,
			c: 133.4,
			h: 133.4,
			l: 133.31,
			t: 1655250420000,
			n: 43
		},
		{
			v: 9804,
			vw: 133.2791,
			o: 133.35,
			c: 133.3,
			h: 133.39,
			l: 133.2,
			t: 1655250480000,
			n: 80
		},
		{
			v: 568,
			vw: 133.3554,
			o: 133.36,
			c: 133.36,
			h: 133.36,
			l: 133.36,
			t: 1655250540000,
			n: 28
		},
		{
			v: 4367,
			vw: 133.2812,
			o: 133.36,
			c: 133.28,
			h: 133.36,
			l: 133.25,
			t: 1655250600000,
			n: 45
		},
		{
			v: 1660,
			vw: 133.2721,
			o: 133.26,
			c: 133.27,
			h: 133.28,
			l: 133.26,
			t: 1655250660000,
			n: 24
		},
		{
			v: 5952,
			vw: 133.3018,
			o: 133.28,
			c: 133.35,
			h: 133.35,
			l: 133.2,
			t: 1655250720000,
			n: 50
		},
		{
			v: 5315,
			vw: 133.4104,
			o: 133.38,
			c: 133.37,
			h: 133.45,
			l: 133.37,
			t: 1655250780000,
			n: 54
		},
		{
			v: 3260,
			vw: 133.4234,
			o: 133.37,
			c: 133.45,
			h: 133.45,
			l: 133.37,
			t: 1655250840000,
			n: 30
		},
		{
			v: 3954,
			vw: 133.4254,
			o: 133.4,
			c: 133.42,
			h: 133.46,
			l: 133.4,
			t: 1655250900000,
			n: 43
		},
		{
			v: 10163,
			vw: 133.4884,
			o: 133.45,
			c: 133.55,
			h: 133.59,
			l: 133.42,
			t: 1655250960000,
			n: 79
		},
		{
			v: 11219,
			vw: 133.5819,
			o: 133.5,
			c: 133.74,
			h: 133.74,
			l: 133.5,
			t: 1655251020000,
			n: 94
		},
		{
			v: 3115,
			vw: 133.8234,
			o: 133.74,
			c: 133.9,
			h: 133.9,
			l: 133.74,
			t: 1655251080000,
			n: 83
		},
		{
			v: 3634,
			vw: 133.8298,
			o: 133.82,
			c: 133.79,
			h: 133.9,
			l: 133.79,
			t: 1655251140000,
			n: 69
		},
		{
			v: 2856,
			vw: 133.4929,
			o: 133.46,
			c: 133.42,
			h: 133.46,
			l: 133.42,
			t: 1655280000000,
			n: 51
		},
		{
			v: 1175,
			vw: 133.5973,
			o: 133.7,
			c: 133.61,
			h: 133.7,
			l: 133.52,
			t: 1655280060000,
			n: 18
		},
		{
			v: 617,
			vw: 133.5139,
			o: 133.5,
			c: 133.5,
			h: 133.5,
			l: 133.5,
			t: 1655280120000,
			n: 20
		},
		{
			v: 320,
			vw: 133.4404,
			o: 133.43,
			c: 133.43,
			h: 133.43,
			l: 133.43,
			t: 1655280180000,
			n: 22
		},
		{
			v: 1614,
			vw: 133.3606,
			o: 133.3,
			c: 133.4,
			h: 133.4,
			l: 133.3,
			t: 1655280240000,
			n: 27
		},
		{
			v: 957,
			vw: 133.4712,
			o: 133.4,
			c: 133.48,
			h: 133.48,
			l: 133.4,
			t: 1655280300000,
			n: 15
		},
		{
			v: 2255,
			vw: 133.444,
			o: 133.45,
			c: 133.51,
			h: 133.51,
			l: 133.4,
			t: 1655280360000,
			n: 31
		},
		{
			v: 8598,
			vw: 133.5139,
			o: 133.5,
			c: 133.52,
			h: 133.52,
			l: 133.5,
			t: 1655280420000,
			n: 78
		},
		{
			v: 1008,
			vw: 133.6587,
			o: 133.7,
			c: 133.64,
			h: 133.7,
			l: 133.64,
			t: 1655280480000,
			n: 31
		},
		{
			v: 268,
			vw: 133.5577,
			o: 133.53,
			c: 133.53,
			h: 133.53,
			l: 133.53,
			t: 1655280540000,
			n: 14
		},
		{
			v: 745,
			vw: 133.6008,
			o: 133.63,
			c: 133.63,
			h: 133.63,
			l: 133.63,
			t: 1655280720000,
			n: 25
		},
		{
			v: 250,
			vw: 133.5517,
			o: 133.55,
			c: 133.55,
			h: 133.55,
			l: 133.55,
			t: 1655281080000,
			n: 11
		},
		{
			v: 360,
			vw: 133.5376,
			o: 133.54,
			c: 133.54,
			h: 133.54,
			l: 133.54,
			t: 1655281140000,
			n: 18
		},
		{
			v: 1428,
			vw: 133.5537,
			o: 133.54,
			c: 133.57,
			h: 133.57,
			l: 133.54,
			t: 1655281200000,
			n: 32
		},
		{
			v: 253,
			vw: 133.5659,
			o: 133.56,
			c: 133.56,
			h: 133.56,
			l: 133.56,
			t: 1655281260000,
			n: 5
		},
		{
			v: 5175,
			vw: 133.6309,
			o: 133.59,
			c: 133.65,
			h: 133.65,
			l: 133.59,
			t: 1655281380000,
			n: 39
		},
		{
			v: 453,
			vw: 133.5968,
			o: 133.6,
			c: 133.6,
			h: 133.6,
			l: 133.6,
			t: 1655281500000,
			n: 14
		},
		{
			v: 3797,
			vw: 133.5996,
			o: 133.6,
			c: 133.6,
			h: 133.6,
			l: 133.59,
			t: 1655281560000,
			n: 22
		},
		{
			v: 9802,
			vw: 133.6271,
			o: 133.6,
			c: 133.73,
			h: 133.73,
			l: 133.6,
			t: 1655281620000,
			n: 95
		},
		{
			v: 1221,
			vw: 133.7473,
			o: 133.74,
			c: 133.76,
			h: 133.76,
			l: 133.74,
			t: 1655281680000,
			n: 16
		},
		{
			v: 285,
			vw: 133.7096,
			o: 133.71,
			c: 133.71,
			h: 133.71,
			l: 133.71,
			t: 1655281800000,
			n: 7
		},
		{
			v: 1256,
			vw: 133.7357,
			o: 133.7,
			c: 133.75,
			h: 133.76,
			l: 133.7,
			t: 1655281860000,
			n: 21
		},
		{
			v: 424,
			vw: 133.7538,
			o: 133.75,
			c: 133.77,
			h: 133.77,
			l: 133.75,
			t: 1655281920000,
			n: 13
		},
		{
			v: 951,
			vw: 133.7717,
			o: 133.77,
			c: 133.79,
			h: 133.79,
			l: 133.77,
			t: 1655281980000,
			n: 41
		},
		{
			v: 574,
			vw: 133.7911,
			o: 133.79,
			c: 133.79,
			h: 133.79,
			l: 133.79,
			t: 1655282040000,
			n: 13
		},
		{
			v: 314,
			vw: 133.7685,
			o: 133.8,
			c: 133.8,
			h: 133.8,
			l: 133.8,
			t: 1655282160000,
			n: 9
		},
		{
			v: 1273,
			vw: 133.8067,
			o: 133.81,
			c: 133.81,
			h: 133.81,
			l: 133.8,
			t: 1655282280000,
			n: 19
		},
		{
			v: 366,
			vw: 133.8013,
			o: 133.8,
			c: 133.8,
			h: 133.8,
			l: 133.8,
			t: 1655282340000,
			n: 16
		},
		{
			v: 543,
			vw: 133.8403,
			o: 133.8,
			c: 133.84,
			h: 133.84,
			l: 133.8,
			t: 1655282400000,
			n: 19
		},
		{
			v: 820,
			vw: 133.7641,
			o: 133.76,
			c: 133.78,
			h: 133.78,
			l: 133.76,
			t: 1655282640000,
			n: 21
		},
		{
			v: 513,
			vw: 133.7579,
			o: 133.77,
			c: 133.76,
			h: 133.77,
			l: 133.76,
			t: 1655282700000,
			n: 12
		},
		{
			v: 1373,
			vw: 133.6967,
			o: 133.76,
			c: 133.65,
			h: 133.76,
			l: 133.65,
			t: 1655282760000,
			n: 53
		},
		{
			v: 1706,
			vw: 133.4927,
			o: 133.52,
			c: 133.49,
			h: 133.52,
			l: 133.45,
			t: 1655282940000,
			n: 52
		},
		{
			v: 678,
			vw: 133.453,
			o: 133.46,
			c: 133.46,
			h: 133.46,
			l: 133.46,
			t: 1655283000000,
			n: 25
		},
		{
			v: 632,
			vw: 133.471,
			o: 133.48,
			c: 133.46,
			h: 133.48,
			l: 133.46,
			t: 1655283060000,
			n: 8
		},
		{
			v: 1572,
			vw: 133.571,
			o: 133.48,
			c: 133.68,
			h: 133.68,
			l: 133.48,
			t: 1655283240000,
			n: 35
		},
		{
			v: 853,
			vw: 133.6097,
			o: 133.61,
			c: 133.61,
			h: 133.61,
			l: 133.61,
			t: 1655283300000,
			n: 10
		},
		{
			v: 502,
			vw: 133.5684,
			o: 133.56,
			c: 133.56,
			h: 133.56,
			l: 133.56,
			t: 1655283480000,
			n: 11
		},
		{
			v: 239,
			vw: 133.5506,
			o: 133.55,
			c: 133.55,
			h: 133.55,
			l: 133.55,
			t: 1655283540000,
			n: 6
		},
		{
			v: 2168,
			vw: 133.4446,
			o: 133.5,
			c: 133.4,
			h: 133.5,
			l: 133.4,
			t: 1655283600000,
			n: 55
		},
		{
			v: 543,
			vw: 133.3244,
			o: 133.3,
			c: 133.3,
			h: 133.3,
			l: 133.3,
			t: 1655283720000,
			n: 20
		},
		{
			v: 618,
			vw: 133.2919,
			o: 133.28,
			c: 133.31,
			h: 133.31,
			l: 133.28,
			t: 1655283780000,
			n: 12
		},
		{
			v: 1309,
			vw: 133.2897,
			o: 133.31,
			c: 133.26,
			h: 133.31,
			l: 133.26,
			t: 1655283840000,
			n: 13
		},
		{
			v: 619,
			vw: 133.3245,
			o: 133.33,
			c: 133.33,
			h: 133.33,
			l: 133.33,
			t: 1655284140000,
			n: 13
		},
		{
			v: 1943,
			vw: 133.3256,
			o: 133.33,
			c: 133.3,
			h: 133.33,
			l: 133.3,
			t: 1655284260000,
			n: 25
		},
		{
			v: 192,
			vw: 133.3406,
			o: 133.34,
			c: 133.34,
			h: 133.34,
			l: 133.34,
			t: 1655284440000,
			n: 5
		},
		{
			v: 1241,
			vw: 133.3419,
			o: 133.34,
			c: 133.39,
			h: 133.39,
			l: 133.34,
			t: 1655284500000,
			n: 21
		},
		{
			v: 557,
			vw: 133.4201,
			o: 133.4,
			c: 133.4,
			h: 133.4,
			l: 133.4,
			t: 1655284680000,
			n: 25
		},
		{
			v: 220,
			vw: 133.3614,
			o: 133.36,
			c: 133.36,
			h: 133.36,
			l: 133.36,
			t: 1655284860000,
			n: 12
		},
		{
			v: 109,
			vw: 133.3017,
			o: 133.3,
			c: 133.3,
			h: 133.3,
			l: 133.3,
			t: 1655284980000,
			n: 4
		},
		{
			v: 421,
			vw: 133.3613,
			o: 133.34,
			c: 133.34,
			h: 133.34,
			l: 133.34,
			t: 1655285220000,
			n: 22
		},
		{
			v: 544,
			vw: 133.5432,
			o: 133.57,
			c: 133.57,
			h: 133.57,
			l: 133.57,
			t: 1655285520000,
			n: 14
		},
		{
			v: 233,
			vw: 133.5399,
			o: 133.54,
			c: 133.54,
			h: 133.54,
			l: 133.54,
			t: 1655285700000,
			n: 12
		},
		{
			v: 483,
			vw: 133.5125,
			o: 133.49,
			c: 133.5,
			h: 133.5,
			l: 133.49,
			t: 1655285760000,
			n: 22
		},
		{
			v: 379,
			vw: 133.4876,
			o: 133.52,
			c: 133.52,
			h: 133.52,
			l: 133.52,
			t: 1655285880000,
			n: 15
		},
		{
			v: 163,
			vw: 133.469,
			o: 133.48,
			c: 133.48,
			h: 133.48,
			l: 133.48,
			t: 1655286000000,
			n: 8
		},
		{
			v: 1281,
			vw: 133.5735,
			o: 133.55,
			c: 133.56,
			h: 133.56,
			l: 133.55,
			t: 1655286180000,
			n: 37
		},
		{
			v: 657,
			vw: 133.6495,
			o: 133.65,
			c: 133.65,
			h: 133.65,
			l: 133.65,
			t: 1655286240000,
			n: 9
		},
		{
			v: 533,
			vw: 133.452,
			o: 133.44,
			c: 133.44,
			h: 133.44,
			l: 133.44,
			t: 1655286480000,
			n: 21
		},
		{
			v: 540,
			vw: 133.4299,
			o: 133.42,
			c: 133.42,
			h: 133.42,
			l: 133.42,
			t: 1655286660000,
			n: 16
		},
		{
			v: 159,
			vw: 133.3869,
			o: 133.38,
			c: 133.38,
			h: 133.38,
			l: 133.38,
			t: 1655287020000,
			n: 10
		},
		{
			v: 1431,
			vw: 133.4434,
			o: 133.44,
			c: 133.44,
			h: 133.45,
			l: 133.44,
			t: 1655287080000,
			n: 28
		},
		{
			v: 177,
			vw: 133.4988,
			o: 133.5,
			c: 133.5,
			h: 133.5,
			l: 133.5,
			t: 1655287260000,
			n: 10
		},
		{
			v: 773,
			vw: 133.3801,
			o: 133.38,
			c: 133.38,
			h: 133.38,
			l: 133.38,
			t: 1655287620000,
			n: 11
		},
		{
			v: 122,
			vw: 133.4443,
			o: 133.45,
			c: 133.45,
			h: 133.45,
			l: 133.45,
			t: 1655287740000,
			n: 7
		},
		{
			v: 1043,
			vw: 133.4119,
			o: 133.41,
			c: 133.41,
			h: 133.41,
			l: 133.41,
			t: 1655288280000,
			n: 12
		},
		{
			v: 473,
			vw: 133.3953,
			o: 133.41,
			c: 133.41,
			h: 133.41,
			l: 133.41,
			t: 1655288700000,
			n: 20
		},
		{
			v: 2489,
			vw: 133.3552,
			o: 133.37,
			c: 133.34,
			h: 133.37,
			l: 133.34,
			t: 1655288760000,
			n: 52
		},
		{
			v: 306,
			vw: 133.3909,
			o: 133.39,
			c: 133.39,
			h: 133.39,
			l: 133.39,
			t: 1655289180000,
			n: 5
		},
		{
			v: 283,
			vw: 133.3527,
			o: 133.34,
			c: 133.34,
			h: 133.34,
			l: 133.34,
			t: 1655289240000,
			n: 23
		},
		{
			v: 10667,
			vw: 133.4926,
			o: 133.5,
			c: 133.39,
			h: 133.58,
			l: 133.39,
			t: 1655290800000,
			n: 231
		},
		{
			v: 3677,
			vw: 133.3894,
			o: 133.4,
			c: 133.32,
			h: 133.42,
			l: 133.32,
			t: 1655290860000,
			n: 35
		},
		{
			v: 851,
			vw: 133.3754,
			o: 133.39,
			c: 133.37,
			h: 133.39,
			l: 133.37,
			t: 1655290920000,
			n: 27
		},
		{
			v: 657,
			vw: 133.3876,
			o: 133.4,
			c: 133.39,
			h: 133.4,
			l: 133.39,
			t: 1655290980000,
			n: 17
		},
		{
			v: 609,
			vw: 133.3385,
			o: 133.35,
			c: 133.35,
			h: 133.35,
			l: 133.35,
			t: 1655291040000,
			n: 24
		},
		{
			v: 1792,
			vw: 133.3105,
			o: 133.31,
			c: 133.32,
			h: 133.32,
			l: 133.3,
			t: 1655291100000,
			n: 40
		},
		{
			v: 1389,
			vw: 133.337,
			o: 133.33,
			c: 133.32,
			h: 133.33,
			l: 133.32,
			t: 1655291160000,
			n: 26
		},
		{
			v: 1154,
			vw: 133.365,
			o: 133.35,
			c: 133.39,
			h: 133.39,
			l: 133.35,
			t: 1655291280000,
			n: 26
		},
		{
			v: 391,
			vw: 133.4049,
			o: 133.4,
			c: 133.4,
			h: 133.4,
			l: 133.4,
			t: 1655291340000,
			n: 13
		},
		{
			v: 225,
			vw: 133.3958,
			o: 133.4,
			c: 133.4,
			h: 133.4,
			l: 133.4,
			t: 1655291400000,
			n: 6
		},
		{
			v: 1276,
			vw: 133.4135,
			o: 133.42,
			c: 133.4,
			h: 133.42,
			l: 133.4,
			t: 1655291460000,
			n: 11
		},
		{
			v: 2424,
			vw: 133.4097,
			o: 133.38,
			c: 133.4,
			h: 133.45,
			l: 133.38,
			t: 1655291520000,
			n: 42
		},
		{
			v: 809,
			vw: 133.4457,
			o: 133.45,
			c: 133.45,
			h: 133.45,
			l: 133.45,
			t: 1655291580000,
			n: 7
		},
		{
			v: 2973,
			vw: 133.5366,
			o: 133.47,
			c: 133.58,
			h: 133.58,
			l: 133.47,
			t: 1655291700000,
			n: 52
		},
		{
			v: 7191,
			vw: 133.5712,
			o: 133.55,
			c: 133.58,
			h: 133.58,
			l: 133.53,
			t: 1655291760000,
			n: 73
		},
		{
			v: 2263,
			vw: 133.5755,
			o: 133.58,
			c: 133.53,
			h: 133.6,
			l: 133.53,
			t: 1655291820000,
			n: 41
		},
		{
			v: 228,
			vw: 133.5402,
			o: 133.55,
			c: 133.55,
			h: 133.55,
			l: 133.55,
			t: 1655291880000,
			n: 9
		},
		{
			v: 1273,
			vw: 133.5387,
			o: 133.52,
			c: 133.57,
			h: 133.57,
			l: 133.51,
			t: 1655291940000,
			n: 25
		},
		{
			v: 5088,
			vw: 133.5955,
			o: 133.57,
			c: 133.6,
			h: 133.6,
			l: 133.57,
			t: 1655292000000,
			n: 38
		},
		{
			v: 856,
			vw: 133.599,
			o: 133.58,
			c: 133.65,
			h: 133.65,
			l: 133.58,
			t: 1655292060000,
			n: 26
		},
		{
			v: 419,
			vw: 133.6711,
			o: 133.65,
			c: 133.65,
			h: 133.65,
			l: 133.65,
			t: 1655292120000,
			n: 20
		},
		{
			v: 3346,
			vw: 133.6552,
			o: 133.67,
			c: 133.72,
			h: 133.72,
			l: 133.61,
			t: 1655292180000,
			n: 64
		},
		{
			v: 2607,
			vw: 133.7625,
			o: 133.74,
			c: 133.75,
			h: 133.78,
			l: 133.74,
			t: 1655292240000,
			n: 23
		},
		{
			v: 1523,
			vw: 133.7657,
			o: 133.77,
			c: 133.76,
			h: 133.78,
			l: 133.76,
			t: 1655292300000,
			n: 34
		},
		{
			v: 657,
			vw: 133.6504,
			o: 133.64,
			c: 133.65,
			h: 133.65,
			l: 133.64,
			t: 1655292360000,
			n: 23
		},
		{
			v: 1262,
			vw: 133.6849,
			o: 133.69,
			c: 133.68,
			h: 133.69,
			l: 133.68,
			t: 1655292420000,
			n: 19
		},
		{
			v: 1184,
			vw: 133.7046,
			o: 133.68,
			c: 133.69,
			h: 133.69,
			l: 133.68,
			t: 1655292480000,
			n: 21
		},
		{
			v: 5070,
			vw: 133.7794,
			o: 133.69,
			c: 133.83,
			h: 133.83,
			l: 133.69,
			t: 1655292540000,
			n: 62
		},
		{
			v: 7532,
			vw: 133.8611,
			o: 133.8,
			c: 133.95,
			h: 133.95,
			l: 133.75,
			t: 1655292600000,
			n: 159
		},
		{
			v: 6711,
			vw: 133.9748,
			o: 133.93,
			c: 134,
			h: 134,
			l: 133.93,
			t: 1655292660000,
			n: 154
		},
		{
			v: 1393,
			vw: 133.9365,
			o: 133.9,
			c: 133.97,
			h: 133.97,
			l: 133.9,
			t: 1655292720000,
			n: 30
		},
		{
			v: 5190,
			vw: 133.9229,
			o: 133.97,
			c: 133.95,
			h: 133.97,
			l: 133.86,
			t: 1655292780000,
			n: 65
		},
		{
			v: 3192,
			vw: 134.0202,
			o: 133.95,
			c: 134.08,
			h: 134.08,
			l: 133.95,
			t: 1655292840000,
			n: 65
		},
		{
			v: 3666,
			vw: 134.0232,
			o: 134.08,
			c: 133.96,
			h: 134.08,
			l: 133.96,
			t: 1655292900000,
			n: 60
		},
		{
			v: 2123,
			vw: 133.9037,
			o: 133.91,
			c: 133.86,
			h: 133.92,
			l: 133.86,
			t: 1655292960000,
			n: 36
		},
		{
			v: 5235,
			vw: 133.9853,
			o: 133.94,
			c: 134,
			h: 134,
			l: 133.94,
			t: 1655293020000,
			n: 61
		},
		{
			v: 8293,
			vw: 133.883,
			o: 134.01,
			c: 133.82,
			h: 134.01,
			l: 133.8,
			t: 1655293080000,
			n: 120
		},
		{
			v: 1115,
			vw: 133.852,
			o: 133.82,
			c: 133.87,
			h: 133.87,
			l: 133.82,
			t: 1655293140000,
			n: 25
		},
		{
			v: 2782,
			vw: 133.8494,
			o: 133.84,
			c: 133.94,
			h: 133.94,
			l: 133.8,
			t: 1655293200000,
			n: 55
		},
		{
			v: 688,
			vw: 133.9361,
			o: 133.92,
			c: 133.92,
			h: 133.92,
			l: 133.92,
			t: 1655293260000,
			n: 19
		},
		{
			v: 738,
			vw: 133.8611,
			o: 133.85,
			c: 133.85,
			h: 133.85,
			l: 133.85,
			t: 1655293320000,
			n: 28
		},
		{
			v: 934,
			vw: 133.8635,
			o: 133.84,
			c: 133.87,
			h: 133.87,
			l: 133.84,
			t: 1655293380000,
			n: 32
		},
		{
			v: 1279,
			vw: 133.9247,
			o: 133.91,
			c: 133.91,
			h: 133.91,
			l: 133.91,
			t: 1655293440000,
			n: 51
		},
		{
			v: 323,
			vw: 133.8432,
			o: 133.81,
			c: 133.81,
			h: 133.81,
			l: 133.81,
			t: 1655293500000,
			n: 15
		},
		{
			v: 3871,
			vw: 133.7369,
			o: 133.8,
			c: 133.7,
			h: 133.8,
			l: 133.7,
			t: 1655293560000,
			n: 89
		},
		{
			v: 841,
			vw: 133.791,
			o: 133.78,
			c: 133.79,
			h: 133.79,
			l: 133.78,
			t: 1655293620000,
			n: 25
		},
		{
			v: 2368,
			vw: 133.724,
			o: 133.72,
			c: 133.72,
			h: 133.72,
			l: 133.71,
			t: 1655293680000,
			n: 69
		},
		{
			v: 1147,
			vw: 133.7514,
			o: 133.74,
			c: 133.76,
			h: 133.76,
			l: 133.74,
			t: 1655293740000,
			n: 24
		},
		{
			v: 3123,
			vw: 133.7746,
			o: 133.78,
			c: 133.78,
			h: 133.78,
			l: 133.76,
			t: 1655293800000,
			n: 54
		},
		{
			v: 12775,
			vw: 133.9629,
			o: 133.78,
			c: 134.02,
			h: 134.1,
			l: 133.78,
			t: 1655293860000,
			n: 196
		},
		{
			v: 2157,
			vw: 134.0062,
			o: 134.01,
			c: 134,
			h: 134.01,
			l: 134,
			t: 1655293920000,
			n: 48
		},
		{
			v: 9476,
			vw: 134.0683,
			o: 134,
			c: 134.1,
			h: 134.13,
			l: 134,
			t: 1655293980000,
			n: 124
		},
		{
			v: 1945,
			vw: 134.0585,
			o: 134.03,
			c: 134.09,
			h: 134.09,
			l: 134.03,
			t: 1655294040000,
			n: 47
		},
		{
			v: 5849,
			vw: 134.1405,
			o: 134.12,
			c: 134.13,
			h: 134.21,
			l: 134.12,
			t: 1655294100000,
			n: 93
		},
		{
			v: 2110,
			vw: 134.1161,
			o: 134.09,
			c: 134.12,
			h: 134.12,
			l: 134.08,
			t: 1655294160000,
			n: 43
		},
		{
			v: 1681,
			vw: 134.1436,
			o: 134.16,
			c: 134.13,
			h: 134.16,
			l: 134.13,
			t: 1655294220000,
			n: 32
		},
		{
			v: 2593,
			vw: 134.0928,
			o: 134.11,
			c: 134.11,
			h: 134.11,
			l: 134.06,
			t: 1655294280000,
			n: 48
		},
		{
			v: 3709,
			vw: 134.1882,
			o: 134.16,
			c: 134.17,
			h: 134.21,
			l: 134.15,
			t: 1655294340000,
			n: 87
		},
		{
			v: 183899,
			vw: 133.8315,
			o: 133.476,
			c: 133.99,
			h: 134.23,
			l: 133.32,
			t: 1655294400000,
			n: 1155
		},
		{
			v: 22217,
			vw: 133.9146,
			o: 134.04,
			c: 133.99,
			h: 134.05,
			l: 132.86,
			t: 1655294460000,
			n: 459
		},
		{
			v: 9819,
			vw: 134.0831,
			o: 134.04,
			c: 134.0999,
			h: 134.1101,
			l: 134.03,
			t: 1655294520000,
			n: 146
		},
		{
			v: 7868,
			vw: 133.8416,
			o: 134.07,
			c: 134.03,
			h: 134.07,
			l: 132.429,
			t: 1655294580000,
			n: 95
		},
		{
			v: 3797,
			vw: 133.9791,
			o: 133.99,
			c: 133.9799,
			h: 133.99,
			l: 133.9501,
			t: 1655294640000,
			n: 83
		},
		{
			v: 4686,
			vw: 133.9465,
			o: 133.93,
			c: 133.93,
			h: 133.98,
			l: 133.91,
			t: 1655294700000,
			n: 60
		},
		{
			v: 3203,
			vw: 133.9022,
			o: 133.9312,
			c: 133.89,
			h: 133.94,
			l: 133.89,
			t: 1655294760000,
			n: 80
		},
		{
			v: 3875,
			vw: 133.8454,
			o: 133.85,
			c: 133.81,
			h: 133.85,
			l: 133.81,
			t: 1655294820000,
			n: 74
		},
		{
			v: 2665,
			vw: 133.8233,
			o: 133.83,
			c: 133.8,
			h: 133.8499,
			l: 133.8,
			t: 1655294880000,
			n: 63
		},
		{
			v: 824,
			vw: 133.79,
			o: 133.8,
			c: 133.8,
			h: 133.8,
			l: 133.8,
			t: 1655294940000,
			n: 51
		},
		{
			v: 4205,
			vw: 133.834,
			o: 133.78,
			c: 133.89,
			h: 133.9,
			l: 133.76,
			t: 1655295000000,
			n: 102
		},
		{
			v: 12146,
			vw: 133.7463,
			o: 133.89,
			c: 133.585,
			h: 133.94,
			l: 133.52,
			t: 1655295060000,
			n: 237
		},
		{
			v: 5415,
			vw: 133.6425,
			o: 133.7,
			c: 133.57,
			h: 133.71,
			l: 133.57,
			t: 1655295120000,
			n: 100
		},
		{
			v: 3251,
			vw: 133.6786,
			o: 133.59,
			c: 133.7,
			h: 133.74,
			l: 133.59,
			t: 1655295180000,
			n: 74
		},
		{
			v: 3845,
			vw: 133.745,
			o: 133.74,
			c: 133.78,
			h: 133.78,
			l: 133.68,
			t: 1655295240000,
			n: 81
		},
		{
			v: 6096,
			vw: 133.7472,
			o: 133.8,
			c: 133.6832,
			h: 133.8,
			l: 133.66,
			t: 1655295300000,
			n: 104
		},
		{
			v: 2729,
			vw: 133.6858,
			o: 133.7,
			c: 133.73,
			h: 133.73,
			l: 133.65,
			t: 1655295360000,
			n: 69
		},
		{
			v: 6099,
			vw: 133.7755,
			o: 133.72,
			c: 133.82,
			h: 133.82,
			l: 133.71,
			t: 1655295420000,
			n: 95
		},
		{
			v: 2908,
			vw: 133.7908,
			o: 133.73,
			c: 133.77,
			h: 133.8,
			l: 133.73,
			t: 1655295480000,
			n: 74
		},
		{
			v: 6291,
			vw: 133.7532,
			o: 133.82,
			c: 133.7,
			h: 133.82,
			l: 133.7,
			t: 1655295540000,
			n: 78
		},
		{
			v: 9710,
			vw: 133.8118,
			o: 133.79,
			c: 133.7999,
			h: 133.83,
			l: 133.79,
			t: 1655295600000,
			n: 113
		},
		{
			v: 2739,
			vw: 133.764,
			o: 133.8,
			c: 133.74,
			h: 133.8,
			l: 133.74,
			t: 1655295660000,
			n: 71
		},
		{
			v: 1837,
			vw: 133.8029,
			o: 133.79,
			c: 133.87,
			h: 133.87,
			l: 133.79,
			t: 1655295720000,
			n: 45
		},
		{
			v: 3037,
			vw: 133.8748,
			o: 133.8399,
			c: 133.9,
			h: 133.9,
			l: 133.8399,
			t: 1655295780000,
			n: 48
		},
		{
			v: 4824,
			vw: 133.9459,
			o: 133.94,
			c: 133.88,
			h: 133.98,
			l: 133.8626,
			t: 1655295840000,
			n: 87
		},
		{
			v: 1465,
			vw: 133.8991,
			o: 133.87,
			c: 133.9,
			h: 133.9,
			l: 133.87,
			t: 1655295900000,
			n: 62
		},
		{
			v: 3293,
			vw: 133.8708,
			o: 133.9,
			c: 133.88,
			h: 133.9,
			l: 133.8499,
			t: 1655295960000,
			n: 59
		},
		{
			v: 2320,
			vw: 133.8682,
			o: 133.88,
			c: 133.85,
			h: 133.88,
			l: 133.8399,
			t: 1655296020000,
			n: 51
		},
		{
			v: 2591,
			vw: 133.8839,
			o: 133.91,
			c: 133.87,
			h: 133.91,
			l: 133.87,
			t: 1655296080000,
			n: 72
		},
		{
			v: 4601,
			vw: 133.8148,
			o: 133.8357,
			c: 133.84,
			h: 133.84,
			l: 133.78,
			t: 1655296140000,
			n: 88
		},
		{
			v: 17783,
			vw: 133.8396,
			o: 133.84,
			c: 133.7,
			h: 134,
			l: 133.7,
			t: 1655296200000,
			n: 179
		},
		{
			v: 23695,
			vw: 134.0338,
			o: 133.89,
			c: 134.01,
			h: 134.15,
			l: 133.89,
			t: 1655296260000,
			n: 162
		},
		{
			v: 21760,
			vw: 133.7801,
			o: 134.01,
			c: 133.7301,
			h: 134.01,
			l: 133.6,
			t: 1655296320000,
			n: 191
		},
		{
			v: 4696,
			vw: 133.8639,
			o: 133.81,
			c: 133.9,
			h: 133.9,
			l: 133.81,
			t: 1655296380000,
			n: 67
		},
		{
			v: 19045,
			vw: 133.9127,
			o: 133.94,
			c: 133.9,
			h: 133.96,
			l: 133.76,
			t: 1655296440000,
			n: 152
		},
		{
			v: 6124,
			vw: 133.9409,
			o: 134,
			c: 133.945,
			h: 134,
			l: 133.91,
			t: 1655296500000,
			n: 113
		},
		{
			v: 9882,
			vw: 134.0431,
			o: 133.95,
			c: 133.99,
			h: 134.12,
			l: 133.95,
			t: 1655296560000,
			n: 119
		},
		{
			v: 15960,
			vw: 134.0222,
			o: 133.98,
			c: 134.05,
			h: 134.09,
			l: 133.94,
			t: 1655296620000,
			n: 188
		},
		{
			v: 3700,
			vw: 134.0088,
			o: 134.03,
			c: 133.96,
			h: 134.1,
			l: 133.96,
			t: 1655296680000,
			n: 81
		},
		{
			v: 2379,
			vw: 133.9555,
			o: 133.98,
			c: 133.95,
			h: 133.98,
			l: 133.9318,
			t: 1655296740000,
			n: 41
		},
		{
			v: 15186,
			vw: 133.9903,
			o: 133.96,
			c: 133.96,
			h: 134.05,
			l: 133.95,
			t: 1655296800000,
			n: 164
		},
		{
			v: 14133,
			vw: 133.8991,
			o: 133.96,
			c: 133.82,
			h: 133.99,
			l: 133.8,
			t: 1655296860000,
			n: 205
		},
		{
			v: 9667,
			vw: 133.9354,
			o: 133.84,
			c: 133.92,
			h: 133.99,
			l: 133.84,
			t: 1655296920000,
			n: 126
		},
		{
			v: 12827,
			vw: 134.0094,
			o: 133.9,
			c: 134.1412,
			h: 134.16,
			l: 133.9,
			t: 1655296980000,
			n: 232
		},
		{
			v: 6245,
			vw: 134.1229,
			o: 134.14,
			c: 134.1,
			h: 134.17,
			l: 134.08,
			t: 1655297040000,
			n: 86
		},
		{
			v: 11115,
			vw: 134.1623,
			o: 134.12,
			c: 134.19,
			h: 134.22,
			l: 134.03,
			t: 1655297100000,
			n: 192
		},
		{
			v: 3007,
			vw: 134.1393,
			o: 134.2,
			c: 134.08,
			h: 134.2,
			l: 134.0401,
			t: 1655297160000,
			n: 105
		},
		{
			v: 11558,
			vw: 134.1128,
			o: 134.1,
			c: 134.19,
			h: 134.19,
			l: 134.06,
			t: 1655297220000,
			n: 182
		},
		{
			v: 12167,
			vw: 134.1628,
			o: 134.19,
			c: 134.1299,
			h: 134.2,
			l: 134.1299,
			t: 1655297280000,
			n: 200
		},
		{
			v: 36481,
			vw: 134.3626,
			o: 134.11,
			c: 134.4899,
			h: 134.55,
			l: 134.11,
			t: 1655297340000,
			n: 569
		},
		{
			v: 13949,
			vw: 134.4325,
			o: 134.47,
			c: 134.4,
			h: 134.51,
			l: 134.38,
			t: 1655297400000,
			n: 260
		},
		{
			v: 22035,
			vw: 134.4099,
			o: 134.32,
			c: 134.33,
			h: 134.55,
			l: 134.3,
			t: 1655297460000,
			n: 279
		},
		{
			v: 9885,
			vw: 134.368,
			o: 134.36,
			c: 134.37,
			h: 134.43,
			l: 134.34,
			t: 1655297520000,
			n: 142
		},
		{
			v: 11607,
			vw: 134.4308,
			o: 134.41,
			c: 134.48,
			h: 134.49,
			l: 134.4,
			t: 1655297580000,
			n: 99
		},
		{
			v: 9728,
			vw: 134.4805,
			o: 134.48,
			c: 134.38,
			h: 134.54,
			l: 134.37,
			t: 1655297640000,
			n: 170
		},
		{
			v: 10629,
			vw: 134.392,
			o: 134.38,
			c: 134.34,
			h: 134.43,
			l: 134.34,
			t: 1655297700000,
			n: 183
		},
		{
			v: 21634,
			vw: 134.3279,
			o: 134.3,
			c: 134.37,
			h: 134.37,
			l: 134.26,
			t: 1655297760000,
			n: 205
		},
		{
			v: 15410,
			vw: 134.3594,
			o: 134.3801,
			c: 134.33,
			h: 134.39,
			l: 134.3,
			t: 1655297820000,
			n: 238
		},
		{
			v: 13460,
			vw: 134.3564,
			o: 134.32,
			c: 134.4,
			h: 134.41,
			l: 134.29,
			t: 1655297880000,
			n: 146
		},
		{
			v: 2009,
			vw: 134.3727,
			o: 134.38,
			c: 134.33,
			h: 134.38,
			l: 134.33,
			t: 1655297940000,
			n: 78
		},
		{
			v: 35673,
			vw: 134.4959,
			o: 134.38,
			c: 134.48,
			h: 134.55,
			l: 134.38,
			t: 1655298000000,
			n: 276
		},
		{
			v: 7590,
			vw: 134.3685,
			o: 134.47,
			c: 134.3599,
			h: 134.47,
			l: 134.29,
			t: 1655298060000,
			n: 170
		},
		{
			v: 39096,
			vw: 134.2711,
			o: 134.35,
			c: 134.23,
			h: 134.35,
			l: 134.22,
			t: 1655298120000,
			n: 217
		},
		{
			v: 5319,
			vw: 134.2493,
			o: 134.2301,
			c: 134.24,
			h: 134.26,
			l: 134.2301,
			t: 1655298180000,
			n: 93
		},
		{
			v: 8257,
			vw: 134.2023,
			o: 134.26,
			c: 134.18,
			h: 134.26,
			l: 134.18,
			t: 1655298240000,
			n: 102
		},
		{
			v: 5695,
			vw: 134.2304,
			o: 134.22,
			c: 134.25,
			h: 134.26,
			l: 134.19,
			t: 1655298300000,
			n: 105
		},
		{
			v: 4464,
			vw: 134.2667,
			o: 134.25,
			c: 134.2201,
			h: 134.31,
			l: 134.2201,
			t: 1655298360000,
			n: 109
		},
		{
			v: 2142,
			vw: 134.2426,
			o: 134.25,
			c: 134.24,
			h: 134.25,
			l: 134.2301,
			t: 1655298420000,
			n: 67
		},
		{
			v: 6580,
			vw: 134.229,
			o: 134.23,
			c: 134.1801,
			h: 134.26,
			l: 134.18,
			t: 1655298480000,
			n: 132
		},
		{
			v: 18815,
			vw: 134.2082,
			o: 134.24,
			c: 134.18,
			h: 134.26,
			l: 134.18,
			t: 1655298540000,
			n: 153
		},
		{
			v: 18230,
			vw: 134.1786,
			o: 134.18,
			c: 134.25,
			h: 134.25,
			l: 134.16,
			t: 1655298600000,
			n: 97
		},
		{
			v: 10149,
			vw: 134.3913,
			o: 134.35,
			c: 134.39,
			h: 134.47,
			l: 134.32,
			t: 1655298660000,
			n: 187
		},
		{
			v: 25831,
			vw: 134.2788,
			o: 134.4,
			c: 134.28,
			h: 134.41,
			l: 134.11,
			t: 1655298720000,
			n: 354
		},
		{
			v: 20231,
			vw: 134.2045,
			o: 134.29,
			c: 134.2101,
			h: 134.29,
			l: 134.18,
			t: 1655298780000,
			n: 102
		},
		{
			v: 11001,
			vw: 134.2842,
			o: 134.25,
			c: 134.27,
			h: 134.2999,
			l: 134.24,
			t: 1655298840000,
			n: 105
		},
		{
			v: 3608,
			vw: 134.2598,
			o: 134.2601,
			c: 134.22,
			h: 134.3,
			l: 134.22,
			t: 1655298900000,
			n: 109
		},
		{
			v: 14544,
			vw: 134.1915,
			o: 134.28,
			c: 134.17,
			h: 134.28,
			l: 134.1303,
			t: 1655298960000,
			n: 131
		},
		{
			v: 11866,
			vw: 134.1945,
			o: 134.19,
			c: 134.19,
			h: 134.2899,
			l: 134.19,
			t: 1655299020000,
			n: 61
		},
		{
			v: 5406,
			vw: 134.2562,
			o: 134.18,
			c: 134.3,
			h: 134.32,
			l: 134.18,
			t: 1655299080000,
			n: 158
		},
		{
			v: 16375,
			vw: 134.3747,
			o: 134.29,
			c: 134.42,
			h: 134.43,
			l: 134.29,
			t: 1655299140000,
			n: 244
		},
		{
			v: 15278,
			vw: 134.465,
			o: 134.42,
			c: 134.4799,
			h: 134.49,
			l: 134.4005,
			t: 1655299200000,
			n: 143
		},
		{
			v: 11699,
			vw: 134.3737,
			o: 134.46,
			c: 134.33,
			h: 134.46,
			l: 134.33,
			t: 1655299260000,
			n: 142
		},
		{
			v: 20895,
			vw: 134.3084,
			o: 134.34,
			c: 134.2,
			h: 134.37,
			l: 134.2,
			t: 1655299320000,
			n: 223
		},
		{
			v: 6884,
			vw: 134.2673,
			o: 134.3,
			c: 134.29,
			h: 134.3,
			l: 134.2201,
			t: 1655299380000,
			n: 131
		},
		{
			v: 3854,
			vw: 134.2644,
			o: 134.28,
			c: 134.22,
			h: 134.29,
			l: 134.22,
			t: 1655299440000,
			n: 84
		},
		{
			v: 14924,
			vw: 134.1549,
			o: 134.15,
			c: 134.2,
			h: 134.2,
			l: 134.11,
			t: 1655299500000,
			n: 157
		},
		{
			v: 4818,
			vw: 134.126,
			o: 134.15,
			c: 134.16,
			h: 134.16,
			l: 134.11,
			t: 1655299560000,
			n: 89
		},
		{
			v: 16005,
			vw: 134.1388,
			o: 134.16,
			c: 134.21,
			h: 134.39,
			l: 134.03,
			t: 1655299620000,
			n: 266
		},
		{
			v: 4982,
			vw: 134.1508,
			o: 134.18,
			c: 134.11,
			h: 134.18,
			l: 134.07,
			t: 1655299680000,
			n: 128
		},
		{
			v: 11347,
			vw: 134.1627,
			o: 134.16,
			c: 134.23,
			h: 134.23,
			l: 134.12,
			t: 1655299740000,
			n: 176
		},
		{
			v: 1465268,
			vw: 134.2708,
			o: 134.29,
			c: 134.11,
			h: 134.74,
			l: 134.05,
			t: 1655299800000,
			n: 16024
		},
		{
			v: 448994,
			vw: 134.1333,
			o: 134.11,
			c: 134.2715,
			h: 134.285,
			l: 134.01,
			t: 1655299860000,
			n: 4039
		},
		{
			v: 389368,
			vw: 134.2774,
			o: 134.28,
			c: 134.43,
			h: 134.5099,
			l: 134.0572,
			t: 1655299920000,
			n: 3636
		},
		{
			v: 430012,
			vw: 134.2115,
			o: 134.46,
			c: 133.8699,
			h: 134.5194,
			l: 133.7505,
			t: 1655299980000,
			n: 4281
		},
		{
			v: 301880,
			vw: 133.8157,
			o: 133.87,
			c: 133.98,
			h: 133.98,
			l: 133.67,
			t: 1655300040000,
			n: 2590
		},
		{
			v: 352215,
			vw: 134.0476,
			o: 133.98,
			c: 133.81,
			h: 134.24,
			l: 133.8,
			t: 1655300100000,
			n: 3132
		},
		{
			v: 295944,
			vw: 133.8654,
			o: 133.8,
			c: 133.98,
			h: 134.02,
			l: 133.71,
			t: 1655300160000,
			n: 2826
		},
		{
			v: 284809,
			vw: 134.0363,
			o: 133.9701,
			c: 134.02,
			h: 134.19,
			l: 133.85,
			t: 1655300220000,
			n: 2542
		},
		{
			v: 265368,
			vw: 134.0768,
			o: 134.06,
			c: 134.0616,
			h: 134.22,
			l: 133.93,
			t: 1655300280000,
			n: 2307
		},
		{
			v: 195161,
			vw: 133.9737,
			o: 134.07,
			c: 133.932,
			h: 134.13,
			l: 133.85,
			t: 1655300340000,
			n: 1956
		},
		{
			v: 346212,
			vw: 134.1555,
			o: 133.94,
			c: 134.1402,
			h: 134.3786,
			l: 133.9101,
			t: 1655300400000,
			n: 3112
		},
		{
			v: 259066,
			vw: 134.2287,
			o: 134.13,
			c: 134.31,
			h: 134.31,
			l: 134.07,
			t: 1655300460000,
			n: 2254
		},
		{
			v: 364271,
			vw: 134.4155,
			o: 134.31,
			c: 134.53,
			h: 134.5325,
			l: 134.28,
			t: 1655300520000,
			n: 2871
		},
		{
			v: 327595,
			vw: 134.4816,
			o: 134.5118,
			c: 134.3909,
			h: 134.5799,
			l: 134.3642,
			t: 1655300580000,
			n: 2812
		},
		{
			v: 245436,
			vw: 134.3254,
			o: 134.4,
			c: 134.38,
			h: 134.41,
			l: 134.2388,
			t: 1655300640000,
			n: 2299
		},
		{
			v: 277930,
			vw: 134.3988,
			o: 134.395,
			c: 134.37,
			h: 134.49,
			l: 134.28,
			t: 1655300700000,
			n: 2618
		},
		{
			v: 278369,
			vw: 134.5107,
			o: 134.37,
			c: 134.57,
			h: 134.6,
			l: 134.34,
			t: 1655300760000,
			n: 2530
		},
		{
			v: 244078,
			vw: 134.5118,
			o: 134.59,
			c: 134.485,
			h: 134.595,
			l: 134.45,
			t: 1655300820000,
			n: 2265
		},
		{
			v: 223697,
			vw: 134.4856,
			o: 134.48,
			c: 134.58,
			h: 134.6,
			l: 134.38,
			t: 1655300880000,
			n: 2205
		},
		{
			v: 251786,
			vw: 134.5872,
			o: 134.6,
			c: 134.47,
			h: 134.69,
			l: 134.47,
			t: 1655300940000,
			n: 2332
		},
		{
			v: 223510,
			vw: 134.5161,
			o: 134.49,
			c: 134.5225,
			h: 134.63,
			l: 134.41,
			t: 1655301000000,
			n: 1954
		},
		{
			v: 196180,
			vw: 134.5516,
			o: 134.53,
			c: 134.61,
			h: 134.65,
			l: 134.44,
			t: 1655301060000,
			n: 1780
		},
		{
			v: 224926,
			vw: 134.5405,
			o: 134.62,
			c: 134.415,
			h: 134.67,
			l: 134.3728,
			t: 1655301120000,
			n: 2004
		},
		{
			v: 215110,
			vw: 134.306,
			o: 134.41,
			c: 134.24,
			h: 134.44,
			l: 134.19,
			t: 1655301180000,
			n: 2002
		},
		{
			v: 263490,
			vw: 134.1485,
			o: 134.25,
			c: 133.99,
			h: 134.325,
			l: 133.96,
			t: 1655301240000,
			n: 2482
		},
		{
			v: 232625,
			vw: 134.0649,
			o: 133.985,
			c: 134.065,
			h: 134.18,
			l: 133.9144,
			t: 1655301300000,
			n: 1867
		},
		{
			v: 179652,
			vw: 134.179,
			o: 134.06,
			c: 134.2111,
			h: 134.2499,
			l: 134.041,
			t: 1655301360000,
			n: 1731
		},
		{
			v: 150796,
			vw: 134.2831,
			o: 134.2299,
			c: 134.31,
			h: 134.36,
			l: 134.225,
			t: 1655301420000,
			n: 1387
		},
		{
			v: 170228,
			vw: 134.1987,
			o: 134.31,
			c: 134.2,
			h: 134.325,
			l: 134.105,
			t: 1655301480000,
			n: 1609
		},
		{
			v: 131805,
			vw: 134.1593,
			o: 134.18,
			c: 134.2,
			h: 134.2386,
			l: 134.07,
			t: 1655301540000,
			n: 1500
		},
		{
			v: 270441,
			vw: 134.2753,
			o: 134.22,
			c: 134.24,
			h: 134.44,
			l: 134.09,
			t: 1655301600000,
			n: 2404
		},
		{
			v: 231116,
			vw: 134.1325,
			o: 134.23,
			c: 134.1,
			h: 134.2504,
			l: 134.04,
			t: 1655301660000,
			n: 2136
		},
		{
			v: 260546,
			vw: 134.2004,
			o: 134.0926,
			c: 134.18,
			h: 134.355,
			l: 134.02,
			t: 1655301720000,
			n: 2276
		},
		{
			v: 243234,
			vw: 134.0509,
			o: 134.17,
			c: 134.02,
			h: 134.17,
			l: 133.97,
			t: 1655301780000,
			n: 2369
		},
		{
			v: 228279,
			vw: 134.153,
			o: 134.03,
			c: 134.135,
			h: 134.24,
			l: 134.02,
			t: 1655301840000,
			n: 2051
		},
		{
			v: 231819,
			vw: 134.1161,
			o: 134.13,
			c: 134.12,
			h: 134.1899,
			l: 134.02,
			t: 1655301900000,
			n: 1793
		},
		{
			v: 312621,
			vw: 134.2382,
			o: 134.13,
			c: 134.25,
			h: 134.33,
			l: 134.12,
			t: 1655301960000,
			n: 2487
		},
		{
			v: 394339,
			vw: 134.4531,
			o: 134.26,
			c: 134.57,
			h: 134.585,
			l: 134.25,
			t: 1655302020000,
			n: 2940
		},
		{
			v: 214807,
			vw: 134.5271,
			o: 134.57,
			c: 134.44,
			h: 134.59,
			l: 134.43,
			t: 1655302080000,
			n: 1671
		},
		{
			v: 208122,
			vw: 134.4158,
			o: 134.44,
			c: 134.36,
			h: 134.52,
			l: 134.31,
			t: 1655302140000,
			n: 1822
		},
		{
			v: 167554,
			vw: 134.2748,
			o: 134.36,
			c: 134.26,
			h: 134.37,
			l: 134.2,
			t: 1655302200000,
			n: 1585
		},
		{
			v: 206466,
			vw: 134.2489,
			o: 134.26,
			c: 134.25,
			h: 134.3401,
			l: 134.17,
			t: 1655302260000,
			n: 1536
		},
		{
			v: 307858,
			vw: 134.3361,
			o: 134.25,
			c: 134.52,
			h: 134.52,
			l: 134.19,
			t: 1655302320000,
			n: 1661
		},
		{
			v: 237624,
			vw: 134.5293,
			o: 134.485,
			c: 134.46,
			h: 134.6108,
			l: 134.44,
			t: 1655302380000,
			n: 1787
		},
		{
			v: 165016,
			vw: 134.4629,
			o: 134.46,
			c: 134.32,
			h: 134.54,
			l: 134.32,
			t: 1655302440000,
			n: 1469
		},
		{
			v: 133095,
			vw: 134.39,
			o: 134.335,
			c: 134.45,
			h: 134.46,
			l: 134.29,
			t: 1655302500000,
			n: 1255
		},
		{
			v: 135317,
			vw: 134.4866,
			o: 134.46,
			c: 134.5227,
			h: 134.58,
			l: 134.38,
			t: 1655302560000,
			n: 1222
		},
		{
			v: 178843,
			vw: 134.3628,
			o: 134.53,
			c: 134.27,
			h: 134.5398,
			l: 134.2059,
			t: 1655302620000,
			n: 1470
		},
		{
			v: 239518,
			vw: 134.1972,
			o: 134.28,
			c: 134.2228,
			h: 134.3,
			l: 134.1,
			t: 1655302680000,
			n: 1729
		},
		{
			v: 127356,
			vw: 134.281,
			o: 134.2204,
			c: 134.2499,
			h: 134.35,
			l: 134.2202,
			t: 1655302740000,
			n: 1103
		},
		{
			v: 149345,
			vw: 134.2196,
			o: 134.2301,
			c: 134.1567,
			h: 134.295,
			l: 134.12,
			t: 1655302800000,
			n: 1118
		},
		{
			v: 165574,
			vw: 134.1499,
			o: 134.155,
			c: 134.21,
			h: 134.25,
			l: 134.0501,
			t: 1655302860000,
			n: 1306
		},
		{
			v: 144843,
			vw: 134.2369,
			o: 134.21,
			c: 134.235,
			h: 134.31,
			l: 134.1601,
			t: 1655302920000,
			n: 1063
		},
		{
			v: 159309,
			vw: 134.286,
			o: 134.25,
			c: 134.42,
			h: 134.42,
			l: 134.1612,
			t: 1655302980000,
			n: 1276
		},
		{
			v: 133523,
			vw: 134.3858,
			o: 134.4199,
			c: 134.34,
			h: 134.445,
			l: 134.32,
			t: 1655303040000,
			n: 1257
		},
		{
			v: 149600,
			vw: 134.2782,
			o: 134.32,
			c: 134.1885,
			h: 134.39,
			l: 134.15,
			t: 1655303100000,
			n: 1250
		},
		{
			v: 129386,
			vw: 134.1643,
			o: 134.18,
			c: 134.12,
			h: 134.2669,
			l: 134.08,
			t: 1655303160000,
			n: 1219
		},
		{
			v: 181866,
			vw: 134.1088,
			o: 134.11,
			c: 134.12,
			h: 134.17,
			l: 134.04,
			t: 1655303220000,
			n: 1214
		},
		{
			v: 143586,
			vw: 134.1561,
			o: 134.13,
			c: 134.19,
			h: 134.23,
			l: 134.04,
			t: 1655303280000,
			n: 1085
		},
		{
			v: 158268,
			vw: 134.1623,
			o: 134.1728,
			c: 134.1565,
			h: 134.23,
			l: 134.11,
			t: 1655303340000,
			n: 1213
		},
		{
			v: 174912,
			vw: 134.1304,
			o: 134.2,
			c: 134.0852,
			h: 134.25,
			l: 134.05,
			t: 1655303400000,
			n: 1548
		},
		{
			v: 342642,
			vw: 133.9623,
			o: 134.09,
			c: 133.94,
			h: 134.1011,
			l: 133.87,
			t: 1655303460000,
			n: 3082
		},
		{
			v: 304047,
			vw: 133.9537,
			o: 133.95,
			c: 133.9607,
			h: 134.05,
			l: 133.9,
			t: 1655303520000,
			n: 1320
		},
		{
			v: 170245,
			vw: 133.9145,
			o: 133.975,
			c: 133.87,
			h: 133.9884,
			l: 133.835,
			t: 1655303580000,
			n: 1568
		},
		{
			v: 161888,
			vw: 133.8388,
			o: 133.88,
			c: 133.87,
			h: 133.91,
			l: 133.75,
			t: 1655303640000,
			n: 1601
		},
		{
			v: 137450,
			vw: 133.9053,
			o: 133.875,
			c: 133.8399,
			h: 133.96,
			l: 133.82,
			t: 1655303700000,
			n: 1302
		},
		{
			v: 167840,
			vw: 133.8508,
			o: 133.84,
			c: 133.87,
			h: 133.94,
			l: 133.75,
			t: 1655303760000,
			n: 1443
		},
		{
			v: 134594,
			vw: 133.8528,
			o: 133.87,
			c: 133.745,
			h: 133.92,
			l: 133.73,
			t: 1655303820000,
			n: 1238
		},
		{
			v: 141732,
			vw: 133.7657,
			o: 133.73,
			c: 133.755,
			h: 133.84,
			l: 133.71,
			t: 1655303880000,
			n: 1223
		},
		{
			v: 98422,
			vw: 133.7988,
			o: 133.7572,
			c: 133.8001,
			h: 133.8485,
			l: 133.745,
			t: 1655303940000,
			n: 1108
		},
		{
			v: 183665,
			vw: 133.6916,
			o: 133.78,
			c: 133.655,
			h: 133.7899,
			l: 133.6284,
			t: 1655304000000,
			n: 1704
		},
		{
			v: 155681,
			vw: 133.7494,
			o: 133.67,
			c: 133.785,
			h: 133.83,
			l: 133.63,
			t: 1655304060000,
			n: 1478
		},
		{
			v: 175034,
			vw: 133.848,
			o: 133.75,
			c: 133.81,
			h: 133.925,
			l: 133.73,
			t: 1655304120000,
			n: 1623
		},
		{
			v: 162931,
			vw: 133.7272,
			o: 133.8,
			c: 133.7365,
			h: 133.82,
			l: 133.66,
			t: 1655304180000,
			n: 1400
		},
		{
			v: 124556,
			vw: 133.7594,
			o: 133.71,
			c: 133.7901,
			h: 133.8501,
			l: 133.675,
			t: 1655304240000,
			n: 1195
		},
		{
			v: 116232,
			vw: 133.7243,
			o: 133.8,
			c: 133.645,
			h: 133.81,
			l: 133.64,
			t: 1655304300000,
			n: 1182
		},
		{
			v: 148108,
			vw: 133.6352,
			o: 133.64,
			c: 133.5982,
			h: 133.72,
			l: 133.56,
			t: 1655304360000,
			n: 1509
		},
		{
			v: 187564,
			vw: 133.6512,
			o: 133.6,
			c: 133.717,
			h: 133.72,
			l: 133.58,
			t: 1655304420000,
			n: 1471
		},
		{
			v: 188361,
			vw: 133.7328,
			o: 133.725,
			c: 133.69,
			h: 133.8,
			l: 133.66,
			t: 1655304480000,
			n: 1469
		},
		{
			v: 127388,
			vw: 133.7179,
			o: 133.69,
			c: 133.77,
			h: 133.8161,
			l: 133.63,
			t: 1655304540000,
			n: 1319
		},
		{
			v: 134464,
			vw: 133.7683,
			o: 133.77,
			c: 133.698,
			h: 133.84,
			l: 133.69,
			t: 1655304600000,
			n: 1152
		},
		{
			v: 181766,
			vw: 133.7028,
			o: 133.69,
			c: 133.73,
			h: 133.79,
			l: 133.61,
			t: 1655304660000,
			n: 1161
		},
		{
			v: 70505,
			vw: 133.7284,
			o: 133.75,
			c: 133.7,
			h: 133.78,
			l: 133.68,
			t: 1655304720000,
			n: 764
		},
		{
			v: 394161,
			vw: 133.6411,
			o: 133.7,
			c: 133.52,
			h: 133.7,
			l: 133.43,
			t: 1655304780000,
			n: 2543
		},
		{
			v: 130551,
			vw: 133.4421,
			o: 133.52,
			c: 133.4596,
			h: 133.52,
			l: 133.38,
			t: 1655304840000,
			n: 1270
		},
		{
			v: 133020,
			vw: 133.5053,
			o: 133.46,
			c: 133.485,
			h: 133.58,
			l: 133.43,
			t: 1655304900000,
			n: 1346
		},
		{
			v: 153175,
			vw: 133.5756,
			o: 133.47,
			c: 133.6391,
			h: 133.67,
			l: 133.44,
			t: 1655304960000,
			n: 1419
		},
		{
			v: 89658,
			vw: 133.649,
			o: 133.6301,
			c: 133.67,
			h: 133.7,
			l: 133.6,
			t: 1655305020000,
			n: 1011
		},
		{
			v: 175890,
			vw: 133.7387,
			o: 133.675,
			c: 133.83,
			h: 133.85,
			l: 133.61,
			t: 1655305080000,
			n: 1538
		},
		{
			v: 149696,
			vw: 133.8386,
			o: 133.84,
			c: 133.82,
			h: 133.9186,
			l: 133.77,
			t: 1655305140000,
			n: 1227
		},
		{
			v: 216833,
			vw: 133.9641,
			o: 133.81,
			c: 133.97,
			h: 134.04,
			l: 133.805,
			t: 1655305200000,
			n: 1727
		},
		{
			v: 136071,
			vw: 133.8661,
			o: 133.97,
			c: 133.88,
			h: 133.97,
			l: 133.795,
			t: 1655305260000,
			n: 1172
		},
		{
			v: 96781,
			vw: 133.9003,
			o: 133.865,
			c: 133.9353,
			h: 133.96,
			l: 133.83,
			t: 1655305320000,
			n: 976
		},
		{
			v: 169138,
			vw: 134.0066,
			o: 133.925,
			c: 134.0184,
			h: 134.05,
			l: 133.92,
			t: 1655305380000,
			n: 1307
		},
		{
			v: 96183,
			vw: 134.0183,
			o: 134.0167,
			c: 134.0325,
			h: 134.08,
			l: 133.95,
			t: 1655305440000,
			n: 872
		},
		{
			v: 114244,
			vw: 133.9579,
			o: 134.03,
			c: 133.98,
			h: 134.06,
			l: 133.9,
			t: 1655305500000,
			n: 1290
		},
		{
			v: 111064,
			vw: 134.0068,
			o: 133.98,
			c: 134.02,
			h: 134.06,
			l: 133.95,
			t: 1655305560000,
			n: 1122
		},
		{
			v: 104201,
			vw: 134.0278,
			o: 134.025,
			c: 134.0122,
			h: 134.09,
			l: 133.975,
			t: 1655305620000,
			n: 1030
		},
		{
			v: 122414,
			vw: 133.9807,
			o: 134.01,
			c: 133.9657,
			h: 134.04,
			l: 133.9299,
			t: 1655305680000,
			n: 1233
		},
		{
			v: 112240,
			vw: 133.8727,
			o: 133.96,
			c: 133.835,
			h: 133.96,
			l: 133.82,
			t: 1655305740000,
			n: 1210
		},
		{
			v: 129949,
			vw: 133.8306,
			o: 133.825,
			c: 133.8997,
			h: 133.92,
			l: 133.73,
			t: 1655305800000,
			n: 1239
		},
		{
			v: 176235,
			vw: 133.7238,
			o: 133.895,
			c: 133.715,
			h: 133.9,
			l: 133.61,
			t: 1655305860000,
			n: 1753
		},
		{
			v: 110530,
			vw: 133.7184,
			o: 133.69,
			c: 133.71,
			h: 133.7748,
			l: 133.65,
			t: 1655305920000,
			n: 985
		},
		{
			v: 92754,
			vw: 133.7113,
			o: 133.7172,
			c: 133.7099,
			h: 133.76,
			l: 133.669,
			t: 1655305980000,
			n: 993
		},
		{
			v: 97250,
			vw: 133.7572,
			o: 133.71,
			c: 133.75,
			h: 133.82,
			l: 133.69,
			t: 1655306040000,
			n: 1189
		},
		{
			v: 93543,
			vw: 133.7593,
			o: 133.755,
			c: 133.82,
			h: 133.83,
			l: 133.7,
			t: 1655306100000,
			n: 1098
		},
		{
			v: 102117,
			vw: 133.8049,
			o: 133.81,
			c: 133.8,
			h: 133.85,
			l: 133.75,
			t: 1655306160000,
			n: 1159
		},
		{
			v: 108213,
			vw: 133.815,
			o: 133.8,
			c: 133.8484,
			h: 133.85,
			l: 133.76,
			t: 1655306220000,
			n: 1014
		},
		{
			v: 145363,
			vw: 133.9581,
			o: 133.84,
			c: 133.99,
			h: 134,
			l: 133.83,
			t: 1655306280000,
			n: 1218
		},
		{
			v: 134169,
			vw: 133.9619,
			o: 133.99,
			c: 133.9623,
			h: 134.04,
			l: 133.88,
			t: 1655306340000,
			n: 1191
		},
		{
			v: 92256,
			vw: 133.9238,
			o: 133.97,
			c: 133.9086,
			h: 133.99,
			l: 133.88,
			t: 1655306400000,
			n: 974
		},
		{
			v: 107656,
			vw: 133.8533,
			o: 133.9,
			c: 133.81,
			h: 133.9232,
			l: 133.79,
			t: 1655306460000,
			n: 1117
		},
		{
			v: 91801,
			vw: 133.83,
			o: 133.815,
			c: 133.92,
			h: 133.92,
			l: 133.7501,
			t: 1655306520000,
			n: 902
		},
		{
			v: 141141,
			vw: 133.9789,
			o: 133.92,
			c: 134.025,
			h: 134.06,
			l: 133.91,
			t: 1655306580000,
			n: 1145
		},
		{
			v: 133787,
			vw: 134.0666,
			o: 134.02,
			c: 134.05,
			h: 134.14,
			l: 133.9999,
			t: 1655306640000,
			n: 1240
		},
		{
			v: 108170,
			vw: 134.0385,
			o: 134.04,
			c: 134.07,
			h: 134.08,
			l: 133.99,
			t: 1655306700000,
			n: 846
		},
		{
			v: 87159,
			vw: 133.9874,
			o: 134.05,
			c: 133.92,
			h: 134.086,
			l: 133.91,
			t: 1655306760000,
			n: 916
		},
		{
			v: 94078,
			vw: 133.9046,
			o: 133.945,
			c: 133.93,
			h: 133.975,
			l: 133.855,
			t: 1655306820000,
			n: 950
		},
		{
			v: 126120,
			vw: 133.9932,
			o: 133.925,
			c: 134.02,
			h: 134.0599,
			l: 133.91,
			t: 1655306880000,
			n: 1022
		},
		{
			v: 115317,
			vw: 134.0784,
			o: 134.03,
			c: 134.07,
			h: 134.14,
			l: 133.98,
			t: 1655306940000,
			n: 986
		},
		{
			v: 141563,
			vw: 134.088,
			o: 134.08,
			c: 134.03,
			h: 134.17,
			l: 133.975,
			t: 1655307000000,
			n: 1219
		},
		{
			v: 117939,
			vw: 134.1033,
			o: 134.025,
			c: 134.105,
			h: 134.15,
			l: 134.025,
			t: 1655307060000,
			n: 986
		},
		{
			v: 84285,
			vw: 134.0477,
			o: 134.1,
			c: 134.05,
			h: 134.11,
			l: 134,
			t: 1655307120000,
			n: 841
		},
		{
			v: 116243,
			vw: 134.0592,
			o: 134.045,
			c: 134.13,
			h: 134.155,
			l: 133.95,
			t: 1655307180000,
			n: 992
		},
		{
			v: 126257,
			vw: 134.1455,
			o: 134.14,
			c: 134.03,
			h: 134.23,
			l: 134.01,
			t: 1655307240000,
			n: 1140
		},
		{
			v: 89012,
			vw: 134.127,
			o: 134.03,
			c: 134.19,
			h: 134.2,
			l: 134,
			t: 1655307300000,
			n: 955
		},
		{
			v: 139911,
			vw: 134.1786,
			o: 134.175,
			c: 134.165,
			h: 134.23,
			l: 134.1299,
			t: 1655307360000,
			n: 1086
		},
		{
			v: 113258,
			vw: 134.1566,
			o: 134.16,
			c: 134.2001,
			h: 134.22,
			l: 134.11,
			t: 1655307420000,
			n: 1038
		},
		{
			v: 146440,
			vw: 134.2286,
			o: 134.21,
			c: 134.275,
			h: 134.315,
			l: 134.16,
			t: 1655307480000,
			n: 1147
		},
		{
			v: 120533,
			vw: 134.2908,
			o: 134.275,
			c: 134.2369,
			h: 134.34,
			l: 134.21,
			t: 1655307540000,
			n: 1274
		},
		{
			v: 112436,
			vw: 134.246,
			o: 134.235,
			c: 134.28,
			h: 134.3108,
			l: 134.17,
			t: 1655307600000,
			n: 1034
		},
		{
			v: 86005,
			vw: 134.286,
			o: 134.285,
			c: 134.3001,
			h: 134.3168,
			l: 134.25,
			t: 1655307660000,
			n: 843
		},
		{
			v: 69858,
			vw: 134.2821,
			o: 134.31,
			c: 134.23,
			h: 134.33,
			l: 134.225,
			t: 1655307720000,
			n: 936
		},
		{
			v: 89705,
			vw: 134.2091,
			o: 134.22,
			c: 134.24,
			h: 134.26,
			l: 134.1411,
			t: 1655307780000,
			n: 1028
		},
		{
			v: 59752,
			vw: 134.2503,
			o: 134.24,
			c: 134.26,
			h: 134.3,
			l: 134.205,
			t: 1655307840000,
			n: 779
		},
		{
			v: 120739,
			vw: 134.3218,
			o: 134.255,
			c: 134.3663,
			h: 134.4,
			l: 134.22,
			t: 1655307900000,
			n: 1125
		},
		{
			v: 153871,
			vw: 134.3476,
			o: 134.36,
			c: 134.32,
			h: 134.39,
			l: 134.29,
			t: 1655307960000,
			n: 1142
		},
		{
			v: 68215,
			vw: 134.2937,
			o: 134.32,
			c: 134.31,
			h: 134.33,
			l: 134.26,
			t: 1655308020000,
			n: 765
		},
		{
			v: 65789,
			vw: 134.2734,
			o: 134.3001,
			c: 134.24,
			h: 134.34,
			l: 134.22,
			t: 1655308080000,
			n: 769
		},
		{
			v: 103727,
			vw: 134.2488,
			o: 134.2398,
			c: 134.21,
			h: 134.3001,
			l: 134.18,
			t: 1655308140000,
			n: 946
		},
		{
			v: 86548,
			vw: 134.2176,
			o: 134.2,
			c: 134.2,
			h: 134.27,
			l: 134.16,
			t: 1655308200000,
			n: 870
		},
		{
			v: 141049,
			vw: 134.2138,
			o: 134.2,
			c: 134.26,
			h: 134.27,
			l: 134.16,
			t: 1655308260000,
			n: 837
		},
		{
			v: 64897,
			vw: 134.272,
			o: 134.26,
			c: 134.287,
			h: 134.3099,
			l: 134.25,
			t: 1655308320000,
			n: 690
		},
		{
			v: 116283,
			vw: 134.1875,
			o: 134.28,
			c: 134.1701,
			h: 134.28,
			l: 134.11,
			t: 1655308380000,
			n: 1116
		},
		{
			v: 108486,
			vw: 134.2773,
			o: 134.18,
			c: 134.36,
			h: 134.36,
			l: 134.16,
			t: 1655308440000,
			n: 1029
		},
		{
			v: 138452,
			vw: 134.3752,
			o: 134.36,
			c: 134.39,
			h: 134.43,
			l: 134.31,
			t: 1655308500000,
			n: 1047
		},
		{
			v: 94023,
			vw: 134.3971,
			o: 134.38,
			c: 134.46,
			h: 134.46,
			l: 134.33,
			t: 1655308560000,
			n: 830
		},
		{
			v: 126122,
			vw: 134.4431,
			o: 134.4501,
			c: 134.4599,
			h: 134.485,
			l: 134.39,
			t: 1655308620000,
			n: 962
		},
		{
			v: 85485,
			vw: 134.4274,
			o: 134.45,
			c: 134.417,
			h: 134.48,
			l: 134.38,
			t: 1655308680000,
			n: 769
		},
		{
			v: 83886,
			vw: 134.4205,
			o: 134.41,
			c: 134.42,
			h: 134.45,
			l: 134.39,
			t: 1655308740000,
			n: 618
		},
		{
			v: 87193,
			vw: 134.4294,
			o: 134.425,
			c: 134.445,
			h: 134.46,
			l: 134.375,
			t: 1655308800000,
			n: 629
		},
		{
			v: 83791,
			vw: 134.4345,
			o: 134.445,
			c: 134.4346,
			h: 134.48,
			l: 134.37,
			t: 1655308860000,
			n: 775
		},
		{
			v: 206583,
			vw: 134.5506,
			o: 134.43,
			c: 134.655,
			h: 134.66,
			l: 134.42,
			t: 1655308920000,
			n: 1464
		},
		{
			v: 123163,
			vw: 134.6144,
			o: 134.655,
			c: 134.59,
			h: 134.69,
			l: 134.5701,
			t: 1655308980000,
			n: 1027
		},
		{
			v: 78999,
			vw: 134.5578,
			o: 134.59,
			c: 134.61,
			h: 134.61,
			l: 134.52,
			t: 1655309040000,
			n: 737
		},
		{
			v: 123474,
			vw: 134.5455,
			o: 134.62,
			c: 134.475,
			h: 134.65,
			l: 134.4501,
			t: 1655309100000,
			n: 1000
		},
		{
			v: 104120,
			vw: 134.4764,
			o: 134.47,
			c: 134.44,
			h: 134.55,
			l: 134.42,
			t: 1655309160000,
			n: 824
		},
		{
			v: 83994,
			vw: 134.4877,
			o: 134.45,
			c: 134.4526,
			h: 134.55,
			l: 134.43,
			t: 1655309220000,
			n: 816
		},
		{
			v: 87882,
			vw: 134.4292,
			o: 134.46,
			c: 134.46,
			h: 134.48,
			l: 134.38,
			t: 1655309280000,
			n: 824
		},
		{
			v: 81357,
			vw: 134.4813,
			o: 134.46,
			c: 134.425,
			h: 134.5,
			l: 134.4159,
			t: 1655309340000,
			n: 656
		},
		{
			v: 73309,
			vw: 134.4898,
			o: 134.4291,
			c: 134.48,
			h: 134.5347,
			l: 134.403,
			t: 1655309400000,
			n: 688
		},
		{
			v: 60593,
			vw: 134.4886,
			o: 134.4884,
			c: 134.47,
			h: 134.54,
			l: 134.43,
			t: 1655309460000,
			n: 633
		},
		{
			v: 64862,
			vw: 134.49,
			o: 134.465,
			c: 134.51,
			h: 134.53,
			l: 134.44,
			t: 1655309520000,
			n: 635
		},
		{
			v: 68976,
			vw: 134.5012,
			o: 134.51,
			c: 134.52,
			h: 134.53,
			l: 134.46,
			t: 1655309580000,
			n: 606
		},
		{
			v: 92197,
			vw: 134.5532,
			o: 134.52,
			c: 134.585,
			h: 134.585,
			l: 134.5179,
			t: 1655309640000,
			n: 842
		},
		{
			v: 166031,
			vw: 134.6289,
			o: 134.5899,
			c: 134.68,
			h: 134.7004,
			l: 134.5399,
			t: 1655309700000,
			n: 1350
		},
		{
			v: 159051,
			vw: 134.6952,
			o: 134.68,
			c: 134.685,
			h: 134.74,
			l: 134.63,
			t: 1655309760000,
			n: 1190
		},
		{
			v: 185173,
			vw: 134.7129,
			o: 134.69,
			c: 134.785,
			h: 134.79,
			l: 134.63,
			t: 1655309820000,
			n: 1371
		},
		{
			v: 161963,
			vw: 134.7581,
			o: 134.79,
			c: 134.7001,
			h: 134.83,
			l: 134.6801,
			t: 1655309880000,
			n: 1049
		},
		{
			v: 115599,
			vw: 134.6823,
			o: 134.7001,
			c: 134.735,
			h: 134.74,
			l: 134.62,
			t: 1655309940000,
			n: 900
		},
		{
			v: 84785,
			vw: 134.7465,
			o: 134.74,
			c: 134.78,
			h: 134.785,
			l: 134.71,
			t: 1655310000000,
			n: 721
		},
		{
			v: 84845,
			vw: 134.7648,
			o: 134.78,
			c: 134.73,
			h: 134.81,
			l: 134.72,
			t: 1655310060000,
			n: 880
		},
		{
			v: 118023,
			vw: 134.8422,
			o: 134.735,
			c: 134.82,
			h: 134.88,
			l: 134.735,
			t: 1655310120000,
			n: 994
		},
		{
			v: 97731,
			vw: 134.799,
			o: 134.815,
			c: 134.79,
			h: 134.84,
			l: 134.75,
			t: 1655310180000,
			n: 797
		},
		{
			v: 61230,
			vw: 134.837,
			o: 134.78,
			c: 134.8385,
			h: 134.87,
			l: 134.775,
			t: 1655310240000,
			n: 608
		},
		{
			v: 129098,
			vw: 134.7803,
			o: 134.83,
			c: 134.73,
			h: 134.86,
			l: 134.73,
			t: 1655310300000,
			n: 1083
		},
		{
			v: 65557,
			vw: 134.7338,
			o: 134.74,
			c: 134.745,
			h: 134.76,
			l: 134.7,
			t: 1655310360000,
			n: 774
		},
		{
			v: 107728,
			vw: 134.7641,
			o: 134.74,
			c: 134.755,
			h: 134.79,
			l: 134.7201,
			t: 1655310420000,
			n: 829
		},
		{
			v: 134298,
			vw: 134.7047,
			o: 134.76,
			c: 134.655,
			h: 134.79,
			l: 134.6501,
			t: 1655310480000,
			n: 1143
		},
		{
			v: 98040,
			vw: 134.6501,
			o: 134.655,
			c: 134.66,
			h: 134.68,
			l: 134.61,
			t: 1655310540000,
			n: 881
		},
		{
			v: 94707,
			vw: 134.6041,
			o: 134.65,
			c: 134.545,
			h: 134.66,
			l: 134.54,
			t: 1655310600000,
			n: 978
		},
		{
			v: 94962,
			vw: 134.6369,
			o: 134.545,
			c: 134.62,
			h: 134.7,
			l: 134.545,
			t: 1655310660000,
			n: 854
		},
		{
			v: 110144,
			vw: 134.6576,
			o: 134.63,
			c: 134.65,
			h: 134.69,
			l: 134.615,
			t: 1655310720000,
			n: 881
		},
		{
			v: 93149,
			vw: 134.6199,
			o: 134.64,
			c: 134.57,
			h: 134.66,
			l: 134.57,
			t: 1655310780000,
			n: 830
		},
		{
			v: 104071,
			vw: 134.5037,
			o: 134.56,
			c: 134.46,
			h: 134.57,
			l: 134.44,
			t: 1655310840000,
			n: 1074
		},
		{
			v: 84064,
			vw: 134.4672,
			o: 134.47,
			c: 134.4407,
			h: 134.5,
			l: 134.43,
			t: 1655310900000,
			n: 832
		},
		{
			v: 124799,
			vw: 134.4195,
			o: 134.44,
			c: 134.3982,
			h: 134.47,
			l: 134.3547,
			t: 1655310960000,
			n: 1222
		},
		{
			v: 73089,
			vw: 134.4418,
			o: 134.4,
			c: 134.47,
			h: 134.48,
			l: 134.4,
			t: 1655311020000,
			n: 792
		},
		{
			v: 109480,
			vw: 134.3785,
			o: 134.46,
			c: 134.34,
			h: 134.46,
			l: 134.33,
			t: 1655311080000,
			n: 1071
		},
		{
			v: 162658,
			vw: 134.3437,
			o: 134.34,
			c: 134.26,
			h: 134.3635,
			l: 134.26,
			t: 1655311140000,
			n: 1262
		},
		{
			v: 112155,
			vw: 134.2594,
			o: 134.2772,
			c: 134.22,
			h: 134.33,
			l: 134.21,
			t: 1655311200000,
			n: 1001
		},
		{
			v: 107429,
			vw: 134.2049,
			o: 134.23,
			c: 134.17,
			h: 134.28,
			l: 134.13,
			t: 1655311260000,
			n: 1003
		},
		{
			v: 97942,
			vw: 134.1291,
			o: 134.1501,
			c: 134.13,
			h: 134.17,
			l: 134.09,
			t: 1655311320000,
			n: 953
		},
		{
			v: 79753,
			vw: 134.1244,
			o: 134.1254,
			c: 134.095,
			h: 134.16,
			l: 134.0737,
			t: 1655311380000,
			n: 825
		},
		{
			v: 140784,
			vw: 134.0818,
			o: 134.09,
			c: 134.1401,
			h: 134.1414,
			l: 134.04,
			t: 1655311440000,
			n: 1131
		},
		{
			v: 113630,
			vw: 134.0924,
			o: 134.145,
			c: 134.12,
			h: 134.145,
			l: 134.04,
			t: 1655311500000,
			n: 971
		},
		{
			v: 97743,
			vw: 134.0873,
			o: 134.12,
			c: 134.06,
			h: 134.15,
			l: 134.0401,
			t: 1655311560000,
			n: 851
		},
		{
			v: 96263,
			vw: 134.0413,
			o: 134.0547,
			c: 134.06,
			h: 134.08,
			l: 134.01,
			t: 1655311620000,
			n: 842
		},
		{
			v: 76218,
			vw: 134.0384,
			o: 134.07,
			c: 134.0446,
			h: 134.07,
			l: 134.01,
			t: 1655311680000,
			n: 802
		},
		{
			v: 101378,
			vw: 134.0598,
			o: 134.045,
			c: 134.09,
			h: 134.09,
			l: 134.0199,
			t: 1655311740000,
			n: 812
		},
		{
			v: 134807,
			vw: 134.0381,
			o: 134.09,
			c: 134.0297,
			h: 134.095,
			l: 133.98,
			t: 1655311800000,
			n: 1393
		},
		{
			v: 85892,
			vw: 133.98,
			o: 134.0225,
			c: 133.95,
			h: 134.05,
			l: 133.93,
			t: 1655311860000,
			n: 872
		},
		{
			v: 133479,
			vw: 133.95,
			o: 133.95,
			c: 133.9201,
			h: 134.03,
			l: 133.89,
			t: 1655311920000,
			n: 1032
		},
		{
			v: 171917,
			vw: 133.8161,
			o: 133.9328,
			c: 133.78,
			h: 133.9328,
			l: 133.755,
			t: 1655311980000,
			n: 1558
		},
		{
			v: 106926,
			vw: 133.7803,
			o: 133.7799,
			c: 133.81,
			h: 133.81,
			l: 133.75,
			t: 1655312040000,
			n: 1021
		},
		{
			v: 97338,
			vw: 133.7653,
			o: 133.81,
			c: 133.79,
			h: 133.81,
			l: 133.7201,
			t: 1655312100000,
			n: 924
		},
		{
			v: 123002,
			vw: 133.7204,
			o: 133.78,
			c: 133.69,
			h: 133.79,
			l: 133.68,
			t: 1655312160000,
			n: 1016
		},
		{
			v: 86408,
			vw: 133.715,
			o: 133.69,
			c: 133.66,
			h: 133.76,
			l: 133.66,
			t: 1655312220000,
			n: 918
		},
		{
			v: 199612,
			vw: 133.6255,
			o: 133.665,
			c: 133.62,
			h: 133.67,
			l: 133.6,
			t: 1655312280000,
			n: 1280
		},
		{
			v: 168630,
			vw: 133.605,
			o: 133.615,
			c: 133.615,
			h: 133.665,
			l: 133.52,
			t: 1655312340000,
			n: 1558
		},
		{
			v: 178057,
			vw: 133.5405,
			o: 133.62,
			c: 133.555,
			h: 133.62,
			l: 133.49,
			t: 1655312400000,
			n: 1872
		},
		{
			v: 81699,
			vw: 133.5782,
			o: 133.56,
			c: 133.6067,
			h: 133.62,
			l: 133.53,
			t: 1655312460000,
			n: 756
		},
		{
			v: 124139,
			vw: 133.6361,
			o: 133.607,
			c: 133.66,
			h: 133.67,
			l: 133.6,
			t: 1655312520000,
			n: 954
		},
		{
			v: 91900,
			vw: 133.6523,
			o: 133.6639,
			c: 133.66,
			h: 133.68,
			l: 133.62,
			t: 1655312580000,
			n: 787
		},
		{
			v: 130770,
			vw: 133.6941,
			o: 133.67,
			c: 133.68,
			h: 133.74,
			l: 133.65,
			t: 1655312640000,
			n: 1061
		},
		{
			v: 118896,
			vw: 133.7126,
			o: 133.68,
			c: 133.72,
			h: 133.76,
			l: 133.67,
			t: 1655312700000,
			n: 1024
		},
		{
			v: 91316,
			vw: 133.6691,
			o: 133.7144,
			c: 133.685,
			h: 133.72,
			l: 133.63,
			t: 1655312760000,
			n: 739
		},
		{
			v: 85126,
			vw: 133.6505,
			o: 133.6854,
			c: 133.625,
			h: 133.72,
			l: 133.61,
			t: 1655312820000,
			n: 826
		},
		{
			v: 110822,
			vw: 133.6192,
			o: 133.63,
			c: 133.605,
			h: 133.67,
			l: 133.58,
			t: 1655312880000,
			n: 925
		},
		{
			v: 90486,
			vw: 133.5903,
			o: 133.63,
			c: 133.625,
			h: 133.64,
			l: 133.54,
			t: 1655312940000,
			n: 976
		},
		{
			v: 110468,
			vw: 133.6522,
			o: 133.64,
			c: 133.73,
			h: 133.73,
			l: 133.56,
			t: 1655313000000,
			n: 973
		},
		{
			v: 97749,
			vw: 133.7393,
			o: 133.72,
			c: 133.78,
			h: 133.79,
			l: 133.7,
			t: 1655313060000,
			n: 986
		},
		{
			v: 85369,
			vw: 133.7803,
			o: 133.7787,
			c: 133.78,
			h: 133.81,
			l: 133.73,
			t: 1655313120000,
			n: 839
		},
		{
			v: 129451,
			vw: 133.8429,
			o: 133.78,
			c: 133.8432,
			h: 133.91,
			l: 133.74,
			t: 1655313180000,
			n: 1164
		},
		{
			v: 87495,
			vw: 133.8604,
			o: 133.855,
			c: 133.88,
			h: 133.9,
			l: 133.81,
			t: 1655313240000,
			n: 884
		},
		{
			v: 102529,
			vw: 133.8731,
			o: 133.88,
			c: 133.83,
			h: 133.93,
			l: 133.82,
			t: 1655313300000,
			n: 1008
		},
		{
			v: 171564,
			vw: 133.8472,
			o: 133.82,
			c: 133.88,
			h: 133.94,
			l: 133.77,
			t: 1655313360000,
			n: 1101
		},
		{
			v: 139635,
			vw: 133.8944,
			o: 133.87,
			c: 133.82,
			h: 133.95,
			l: 133.81,
			t: 1655313420000,
			n: 1093
		},
		{
			v: 94726,
			vw: 133.8507,
			o: 133.81,
			c: 133.9,
			h: 133.91,
			l: 133.8,
			t: 1655313480000,
			n: 787
		},
		{
			v: 71763,
			vw: 133.9071,
			o: 133.91,
			c: 133.9299,
			h: 133.95,
			l: 133.875,
			t: 1655313540000,
			n: 784
		},
		{
			v: 85575,
			vw: 133.9502,
			o: 133.92,
			c: 133.96,
			h: 134,
			l: 133.91,
			t: 1655313600000,
			n: 796
		},
		{
			v: 64007,
			vw: 133.9233,
			o: 133.95,
			c: 133.975,
			h: 133.98,
			l: 133.88,
			t: 1655313660000,
			n: 655
		},
		{
			v: 67241,
			vw: 133.9525,
			o: 133.9801,
			c: 133.965,
			h: 133.99,
			l: 133.9287,
			t: 1655313720000,
			n: 720
		},
		{
			v: 71822,
			vw: 133.9625,
			o: 133.96,
			c: 133.94,
			h: 133.98,
			l: 133.925,
			t: 1655313780000,
			n: 705
		},
		{
			v: 92586,
			vw: 133.9714,
			o: 133.94,
			c: 133.978,
			h: 134.04,
			l: 133.9101,
			t: 1655313840000,
			n: 988
		},
		{
			v: 81814,
			vw: 133.9833,
			o: 133.97,
			c: 133.9991,
			h: 134.03,
			l: 133.94,
			t: 1655313900000,
			n: 833
		},
		{
			v: 96781,
			vw: 134.0408,
			o: 134,
			c: 134.065,
			h: 134.08,
			l: 133.995,
			t: 1655313960000,
			n: 759
		},
		{
			v: 177066,
			vw: 134.1527,
			o: 134.07,
			c: 134.215,
			h: 134.24,
			l: 134.05,
			t: 1655314020000,
			n: 1372
		},
		{
			v: 133880,
			vw: 134.2455,
			o: 134.22,
			c: 134.27,
			h: 134.2909,
			l: 134.19,
			t: 1655314080000,
			n: 1176
		},
		{
			v: 131305,
			vw: 134.2301,
			o: 134.285,
			c: 134.21,
			h: 134.3197,
			l: 134.2,
			t: 1655314140000,
			n: 1122
		},
		{
			v: 82236,
			vw: 134.1678,
			o: 134.2194,
			c: 134.1901,
			h: 134.22,
			l: 134.15,
			t: 1655314200000,
			n: 736
		},
		{
			v: 82528,
			vw: 134.1611,
			o: 134.21,
			c: 134.145,
			h: 134.22,
			l: 134.12,
			t: 1655314260000,
			n: 732
		},
		{
			v: 72157,
			vw: 134.1944,
			o: 134.16,
			c: 134.18,
			h: 134.23,
			l: 134.15,
			t: 1655314320000,
			n: 594
		},
		{
			v: 59291,
			vw: 134.157,
			o: 134.1834,
			c: 134.1642,
			h: 134.19,
			l: 134.1301,
			t: 1655314380000,
			n: 626
		},
		{
			v: 96433,
			vw: 134.1382,
			o: 134.175,
			c: 134.11,
			h: 134.23,
			l: 134.06,
			t: 1655314440000,
			n: 947
		},
		{
			v: 89435,
			vw: 134.0977,
			o: 134.1,
			c: 134.1,
			h: 134.14,
			l: 134.05,
			t: 1655314500000,
			n: 785
		},
		{
			v: 60343,
			vw: 134.0941,
			o: 134.1,
			c: 134.05,
			h: 134.13,
			l: 134.05,
			t: 1655314560000,
			n: 646
		},
		{
			v: 112278,
			vw: 134.0129,
			o: 134.05,
			c: 134.0499,
			h: 134.0712,
			l: 133.96,
			t: 1655314620000,
			n: 1122
		},
		{
			v: 85702,
			vw: 134.0967,
			o: 134.0401,
			c: 134.1,
			h: 134.13,
			l: 134.04,
			t: 1655314680000,
			n: 660
		},
		{
			v: 85156,
			vw: 134.1426,
			o: 134.11,
			c: 134.1593,
			h: 134.18,
			l: 134.1059,
			t: 1655314740000,
			n: 722
		},
		{
			v: 65318,
			vw: 134.1252,
			o: 134.16,
			c: 134.1,
			h: 134.17,
			l: 134.09,
			t: 1655314800000,
			n: 654
		},
		{
			v: 77129,
			vw: 134.0506,
			o: 134.1,
			c: 134.06,
			h: 134.1,
			l: 134,
			t: 1655314860000,
			n: 694
		},
		{
			v: 90980,
			vw: 134.0779,
			o: 134.06,
			c: 134.115,
			h: 134.12,
			l: 134.015,
			t: 1655314920000,
			n: 672
		},
		{
			v: 65604,
			vw: 134.116,
			o: 134.11,
			c: 134.0712,
			h: 134.15,
			l: 134.07,
			t: 1655314980000,
			n: 627
		},
		{
			v: 54332,
			vw: 134.0658,
			o: 134.075,
			c: 134.08,
			h: 134.09,
			l: 134.045,
			t: 1655315040000,
			n: 579
		},
		{
			v: 83790,
			vw: 134.0538,
			o: 134.07,
			c: 134.02,
			h: 134.09,
			l: 134.01,
			t: 1655315100000,
			n: 687
		},
		{
			v: 76822,
			vw: 134.0893,
			o: 134.03,
			c: 134.1301,
			h: 134.14,
			l: 134.03,
			t: 1655315160000,
			n: 683
		},
		{
			v: 53152,
			vw: 134.1036,
			o: 134.13,
			c: 134.065,
			h: 134.15,
			l: 134.05,
			t: 1655315220000,
			n: 623
		},
		{
			v: 83948,
			vw: 134.0455,
			o: 134.065,
			c: 134.06,
			h: 134.08,
			l: 134.0142,
			t: 1655315280000,
			n: 707
		},
		{
			v: 92772,
			vw: 133.9878,
			o: 134.06,
			c: 133.95,
			h: 134.07,
			l: 133.9386,
			t: 1655315340000,
			n: 892
		},
		{
			v: 167572,
			vw: 133.8118,
			o: 133.95,
			c: 133.77,
			h: 133.95,
			l: 133.7001,
			t: 1655315400000,
			n: 1489
		},
		{
			v: 125353,
			vw: 133.7694,
			o: 133.765,
			c: 133.91,
			h: 133.96,
			l: 133.675,
			t: 1655315460000,
			n: 1099
		},
		{
			v: 169828,
			vw: 133.9666,
			o: 133.9299,
			c: 133.965,
			h: 134.03,
			l: 133.9001,
			t: 1655315520000,
			n: 1178
		},
		{
			v: 87711,
			vw: 133.9795,
			o: 133.97,
			c: 133.91,
			h: 134.05,
			l: 133.91,
			t: 1655315580000,
			n: 765
		},
		{
			v: 159129,
			vw: 134.0331,
			o: 133.91,
			c: 134.11,
			h: 134.1487,
			l: 133.905,
			t: 1655315640000,
			n: 1022
		},
		{
			v: 166720,
			vw: 134.2056,
			o: 134.1095,
			c: 134.2745,
			h: 134.28,
			l: 134.07,
			t: 1655315700000,
			n: 1358
		},
		{
			v: 210398,
			vw: 134.2637,
			o: 134.28,
			c: 134.2316,
			h: 134.309,
			l: 134.16,
			t: 1655315760000,
			n: 1370
		},
		{
			v: 112366,
			vw: 134.1729,
			o: 134.23,
			c: 134.21,
			h: 134.25,
			l: 134.06,
			t: 1655315820000,
			n: 1059
		},
		{
			v: 168580,
			vw: 134.2401,
			o: 134.2045,
			c: 134.16,
			h: 134.42,
			l: 134.09,
			t: 1655315880000,
			n: 1422
		},
		{
			v: 226752,
			vw: 134.3391,
			o: 134.1732,
			c: 134.3899,
			h: 134.5299,
			l: 134.05,
			t: 1655315940000,
			n: 1950
		},
		{
			v: 1120843,
			vw: 134.6515,
			o: 134.36,
			c: 134.46,
			h: 135.28,
			l: 133.53,
			t: 1655316000000,
			n: 10553
		},
		{
			v: 720895,
			vw: 133.6986,
			o: 134.58,
			c: 133.72,
			h: 134.58,
			l: 133.09,
			t: 1655316060000,
			n: 6760
		},
		{
			v: 326672,
			vw: 133.9879,
			o: 133.735,
			c: 133.6,
			h: 134.3199,
			l: 133.53,
			t: 1655316120000,
			n: 3117
		},
		{
			v: 308913,
			vw: 133.4692,
			o: 133.5799,
			c: 133.385,
			h: 133.75,
			l: 133.22,
			t: 1655316180000,
			n: 3046
		},
		{
			v: 537816,
			vw: 133.0804,
			o: 133.3999,
			c: 133.1128,
			h: 133.64,
			l: 132.72,
			t: 1655316240000,
			n: 6980
		},
		{
			v: 463813,
			vw: 133.1472,
			o: 133.12,
			c: 132.94,
			h: 133.51,
			l: 132.9,
			t: 1655316300000,
			n: 3676
		},
		{
			v: 388595,
			vw: 132.6886,
			o: 132.92,
			c: 132.3499,
			h: 133.14,
			l: 132.31,
			t: 1655316360000,
			n: 3805
		},
		{
			v: 417677,
			vw: 132.7135,
			o: 132.32,
			c: 132.7956,
			h: 133.13,
			l: 132.25,
			t: 1655316420000,
			n: 3692
		},
		{
			v: 223129,
			vw: 132.762,
			o: 132.78,
			c: 132.69,
			h: 133.03,
			l: 132.57,
			t: 1655316480000,
			n: 2035
		},
		{
			v: 300676,
			vw: 133.0154,
			o: 132.7,
			c: 133.22,
			h: 133.38,
			l: 132.53,
			t: 1655316540000,
			n: 2495
		},
		{
			v: 277296,
			vw: 132.8888,
			o: 133.22,
			c: 132.75,
			h: 133.31,
			l: 132.62,
			t: 1655316600000,
			n: 2294
		},
		{
			v: 295297,
			vw: 133.1687,
			o: 132.74,
			c: 133.2301,
			h: 133.48,
			l: 132.69,
			t: 1655316660000,
			n: 2413
		},
		{
			v: 205865,
			vw: 133.142,
			o: 133.23,
			c: 133.2,
			h: 133.32,
			l: 132.96,
			t: 1655316720000,
			n: 1648
		},
		{
			v: 199895,
			vw: 133.2598,
			o: 133.23,
			c: 133.28,
			h: 133.42,
			l: 133.11,
			t: 1655316780000,
			n: 1756
		},
		{
			v: 190424,
			vw: 133.2279,
			o: 133.29,
			c: 133.12,
			h: 133.389,
			l: 133.06,
			t: 1655316840000,
			n: 1508
		},
		{
			v: 324748,
			vw: 133.2093,
			o: 133.13,
			c: 133.51,
			h: 133.55,
			l: 132.9,
			t: 1655316900000,
			n: 2874
		},
		{
			v: 256216,
			vw: 133.5506,
			o: 133.505,
			c: 133.82,
			h: 133.8399,
			l: 133.2701,
			t: 1655316960000,
			n: 1922
		},
		{
			v: 244806,
			vw: 133.8647,
			o: 133.83,
			c: 133.85,
			h: 134,
			l: 133.74,
			t: 1655317020000,
			n: 2137
		},
		{
			v: 227332,
			vw: 133.8104,
			o: 133.86,
			c: 133.7701,
			h: 133.95,
			l: 133.73,
			t: 1655317080000,
			n: 1905
		},
		{
			v: 218715,
			vw: 133.7365,
			o: 133.7779,
			c: 133.74,
			h: 133.89,
			l: 133.61,
			t: 1655317140000,
			n: 1836
		},
		{
			v: 280722,
			vw: 133.92,
			o: 133.76,
			c: 133.84,
			h: 134.11,
			l: 133.62,
			t: 1655317200000,
			n: 2216
		},
		{
			v: 196747,
			vw: 133.8962,
			o: 133.8401,
			c: 133.76,
			h: 134.07,
			l: 133.73,
			t: 1655317260000,
			n: 1830
		},
		{
			v: 286119,
			vw: 133.5804,
			o: 133.755,
			c: 133.48,
			h: 133.76,
			l: 133.47,
			t: 1655317320000,
			n: 2396
		},
		{
			v: 212335,
			vw: 133.3762,
			o: 133.48,
			c: 133.11,
			h: 133.57,
			l: 133.09,
			t: 1655317380000,
			n: 1798
		},
		{
			v: 221811,
			vw: 133.146,
			o: 133.09,
			c: 133.205,
			h: 134.1379,
			l: 132.98,
			t: 1655317440000,
			n: 1981
		},
		{
			v: 197930,
			vw: 133.2655,
			o: 133.23,
			c: 133.2,
			h: 133.41,
			l: 133.13,
			t: 1655317500000,
			n: 1666
		},
		{
			v: 148050,
			vw: 133.2034,
			o: 133.2,
			c: 133.36,
			h: 133.38,
			l: 133.05,
			t: 1655317560000,
			n: 1200
		},
		{
			v: 156790,
			vw: 133.3716,
			o: 133.3401,
			c: 133.22,
			h: 133.54,
			l: 133.13,
			t: 1655317620000,
			n: 1487
		},
		{
			v: 116589,
			vw: 133.2324,
			o: 133.2395,
			c: 133.187,
			h: 133.38,
			l: 133.14,
			t: 1655317680000,
			n: 1206
		},
		{
			v: 165791,
			vw: 133.1957,
			o: 133.19,
			c: 133.11,
			h: 133.36,
			l: 133.08,
			t: 1655317740000,
			n: 1518
		},
		{
			v: 231116,
			vw: 133.1411,
			o: 133.11,
			c: 132.94,
			h: 133.39,
			l: 132.94,
			t: 1655317800000,
			n: 2234
		},
		{
			v: 268752,
			vw: 132.8643,
			o: 132.94,
			c: 132.92,
			h: 133.04,
			l: 132.68,
			t: 1655317860000,
			n: 2527
		},
		{
			v: 315663,
			vw: 132.5782,
			o: 132.9,
			c: 132.32,
			h: 132.94,
			l: 132.32,
			t: 1655317920000,
			n: 3082
		},
		{
			v: 427412,
			vw: 132.3778,
			o: 132.315,
			c: 132.5852,
			h: 132.71,
			l: 132.16,
			t: 1655317980000,
			n: 3990
		},
		{
			v: 366034,
			vw: 132.7493,
			o: 132.545,
			c: 132.8,
			h: 133.1,
			l: 132.45,
			t: 1655318040000,
			n: 3733
		},
		{
			v: 237135,
			vw: 132.6982,
			o: 132.7099,
			c: 132.75,
			h: 132.85,
			l: 132.5,
			t: 1655318100000,
			n: 2549
		},
		{
			v: 260109,
			vw: 132.9397,
			o: 132.7501,
			c: 133.05,
			h: 133.11,
			l: 132.7,
			t: 1655318160000,
			n: 2527
		},
		{
			v: 291397,
			vw: 133.2074,
			o: 133.05,
			c: 133.28,
			h: 133.39,
			l: 133.01,
			t: 1655318220000,
			n: 2669
		},
		{
			v: 986135,
			vw: 134.17,
			o: 133.3,
			c: 135.04,
			h: 135.04,
			l: 133.2801,
			t: 1655318280000,
			n: 9079
		},
		{
			v: 522116,
			vw: 134.6371,
			o: 135.08,
			c: 134.29,
			h: 135.17,
			l: 134.26,
			t: 1655318340000,
			n: 4910
		},
		{
			v: 483866,
			vw: 134.5823,
			o: 134.28,
			c: 135.13,
			h: 135.13,
			l: 134.07,
			t: 1655318400000,
			n: 4282
		},
		{
			v: 644678,
			vw: 135.5845,
			o: 135.125,
			c: 135.5578,
			h: 135.89,
			l: 135.125,
			t: 1655318460000,
			n: 5473
		},
		{
			v: 511748,
			vw: 135.8823,
			o: 135.5491,
			c: 135.73,
			h: 136.1653,
			l: 135.5,
			t: 1655318520000,
			n: 4437
		},
		{
			v: 352895,
			vw: 135.7448,
			o: 135.73,
			c: 135.8465,
			h: 136.12,
			l: 135.44,
			t: 1655318580000,
			n: 3078
		},
		{
			v: 414855,
			vw: 136.0175,
			o: 135.86,
			c: 136.12,
			h: 136.32,
			l: 135.79,
			t: 1655318640000,
			n: 3166
		},
		{
			v: 422418,
			vw: 136.3299,
			o: 136.16,
			c: 136.28,
			h: 136.47,
			l: 136.16,
			t: 1655318700000,
			n: 3224
		},
		{
			v: 497863,
			vw: 136.1485,
			o: 136.29,
			c: 136.1894,
			h: 136.49,
			l: 135.87,
			t: 1655318760000,
			n: 4044
		},
		{
			v: 361934,
			vw: 135.9244,
			o: 136.174,
			c: 135.61,
			h: 136.26,
			l: 135.6,
			t: 1655318820000,
			n: 3193
		},
		{
			v: 424352,
			vw: 135.5963,
			o: 135.6101,
			c: 135.57,
			h: 135.78,
			l: 135.44,
			t: 1655318880000,
			n: 3627
		},
		{
			v: 265582,
			vw: 135.637,
			o: 135.5799,
			c: 135.49,
			h: 135.82,
			l: 135.46,
			t: 1655318940000,
			n: 2419
		},
		{
			v: 300619,
			vw: 135.6244,
			o: 135.49,
			c: 135.69,
			h: 135.83,
			l: 135.4,
			t: 1655319000000,
			n: 2667
		},
		{
			v: 273192,
			vw: 135.5854,
			o: 135.72,
			c: 135.4,
			h: 135.87,
			l: 135.32,
			t: 1655319060000,
			n: 2941
		},
		{
			v: 464396,
			vw: 134.9498,
			o: 135.43,
			c: 135.12,
			h: 135.43,
			l: 134.59,
			t: 1655319120000,
			n: 4321
		},
		{
			v: 240414,
			vw: 135.043,
			o: 135.14,
			c: 134.935,
			h: 135.32,
			l: 134.77,
			t: 1655319180000,
			n: 2504
		},
		{
			v: 281655,
			vw: 134.8629,
			o: 134.98,
			c: 135.09,
			h: 135.12,
			l: 134.59,
			t: 1655319240000,
			n: 2821
		},
		{
			v: 309567,
			vw: 135.2134,
			o: 135.06,
			c: 135.26,
			h: 135.36,
			l: 135.05,
			t: 1655319300000,
			n: 3051
		},
		{
			v: 221001,
			vw: 135.0364,
			o: 135.25,
			c: 135,
			h: 135.25,
			l: 134.85,
			t: 1655319360000,
			n: 2358
		},
		{
			v: 224766,
			vw: 134.8952,
			o: 135.025,
			c: 134.67,
			h: 135.19,
			l: 134.55,
			t: 1655319420000,
			n: 2081
		},
		{
			v: 278162,
			vw: 134.8213,
			o: 134.69,
			c: 135,
			h: 135.14,
			l: 134.4601,
			t: 1655319480000,
			n: 2506
		},
		{
			v: 181299,
			vw: 134.9829,
			o: 134.99,
			c: 134.865,
			h: 135.16,
			l: 134.77,
			t: 1655319540000,
			n: 1771
		},
		{
			v: 349478,
			vw: 135.2104,
			o: 134.88,
			c: 135.43,
			h: 135.7,
			l: 134.7501,
			t: 1655319600000,
			n: 3544
		},
		{
			v: 219043,
			vw: 135.3597,
			o: 135.4302,
			c: 135.23,
			h: 135.63,
			l: 135.17,
			t: 1655319660000,
			n: 2301
		},
		{
			v: 233860,
			vw: 134.9689,
			o: 135.22,
			c: 135.1799,
			h: 135.2959,
			l: 134.71,
			t: 1655319720000,
			n: 2429
		},
		{
			v: 194772,
			vw: 134.9457,
			o: 135.1538,
			c: 134.89,
			h: 135.1832,
			l: 134.8,
			t: 1655319780000,
			n: 2057
		},
		{
			v: 239803,
			vw: 135.1637,
			o: 134.9,
			c: 135.2,
			h: 135.35,
			l: 134.8103,
			t: 1655319840000,
			n: 2194
		},
		{
			v: 201816,
			vw: 135.143,
			o: 135.2099,
			c: 134.96,
			h: 135.36,
			l: 134.93,
			t: 1655319900000,
			n: 2000
		},
		{
			v: 194703,
			vw: 135.1552,
			o: 134.96,
			c: 135.34,
			h: 135.35,
			l: 134.87,
			t: 1655319960000,
			n: 1708
		},
		{
			v: 252874,
			vw: 135.4238,
			o: 135.35,
			c: 135.39,
			h: 135.54,
			l: 135.29,
			t: 1655320020000,
			n: 1875
		},
		{
			v: 213562,
			vw: 135.2891,
			o: 135.39,
			c: 135.26,
			h: 135.53,
			l: 135.11,
			t: 1655320080000,
			n: 1961
		},
		{
			v: 201182,
			vw: 135.4404,
			o: 135.26,
			c: 135.46,
			h: 135.54,
			l: 135.22,
			t: 1655320140000,
			n: 1929
		},
		{
			v: 259858,
			vw: 135.509,
			o: 135.48,
			c: 135.32,
			h: 135.7,
			l: 135.29,
			t: 1655320200000,
			n: 2260
		},
		{
			v: 263711,
			vw: 135.3564,
			o: 135.3,
			c: 135.4,
			h: 135.55,
			l: 135.2,
			t: 1655320260000,
			n: 2275
		},
		{
			v: 272514,
			vw: 135.1237,
			o: 135.4,
			c: 134.84,
			h: 135.49,
			l: 134.77,
			t: 1655320320000,
			n: 2671
		},
		{
			v: 259756,
			vw: 134.9257,
			o: 134.81,
			c: 135.11,
			h: 135.16,
			l: 134.66,
			t: 1655320380000,
			n: 2242
		},
		{
			v: 177412,
			vw: 135.1613,
			o: 135.1362,
			c: 135.05,
			h: 135.25,
			l: 135.03,
			t: 1655320440000,
			n: 1801
		},
		{
			v: 264513,
			vw: 134.8735,
			o: 135.0599,
			c: 135.1101,
			h: 135.14,
			l: 134.67,
			t: 1655320500000,
			n: 2587
		},
		{
			v: 252698,
			vw: 135.1782,
			o: 135.14,
			c: 135.09,
			h: 135.34,
			l: 135.04,
			t: 1655320560000,
			n: 2369
		},
		{
			v: 164708,
			vw: 135.11,
			o: 135.11,
			c: 135.12,
			h: 135.2401,
			l: 134.95,
			t: 1655320620000,
			n: 1627
		},
		{
			v: 228822,
			vw: 135.1178,
			o: 135.13,
			c: 135.1,
			h: 135.35,
			l: 134.91,
			t: 1655320680000,
			n: 2157
		},
		{
			v: 229530,
			vw: 135.1996,
			o: 135.1,
			c: 135.38,
			h: 135.39,
			l: 135.02,
			t: 1655320740000,
			n: 2153
		},
		{
			v: 325220,
			vw: 135.5367,
			o: 135.39,
			c: 135.64,
			h: 135.78,
			l: 135.36,
			t: 1655320800000,
			n: 2810
		},
		{
			v: 335184,
			vw: 135.8416,
			o: 135.63,
			c: 135.81,
			h: 135.98,
			l: 135.63,
			t: 1655320860000,
			n: 2599
		},
		{
			v: 225527,
			vw: 135.8774,
			o: 135.8,
			c: 135.915,
			h: 135.99,
			l: 135.74,
			t: 1655320920000,
			n: 2104
		},
		{
			v: 228593,
			vw: 135.8789,
			o: 135.93,
			c: 136.05,
			h: 136.08,
			l: 135.63,
			t: 1655320980000,
			n: 2375
		},
		{
			v: 306099,
			vw: 136.1914,
			o: 136.06,
			c: 136.285,
			h: 136.34,
			l: 135.99,
			t: 1655321040000,
			n: 2709
		},
		{
			v: 184061,
			vw: 136.1709,
			o: 136.3,
			c: 136.2,
			h: 136.335,
			l: 136.01,
			t: 1655321100000,
			n: 1902
		},
		{
			v: 352817,
			vw: 136.3511,
			o: 136.195,
			c: 136.3464,
			h: 136.48,
			l: 136.18,
			t: 1655321160000,
			n: 2831
		},
		{
			v: 248628,
			vw: 136.3066,
			o: 136.33,
			c: 136.47,
			h: 136.48,
			l: 136.14,
			t: 1655321220000,
			n: 2217
		},
		{
			v: 244988,
			vw: 136.4689,
			o: 136.48,
			c: 136.4,
			h: 136.53,
			l: 136.38,
			t: 1655321280000,
			n: 2419
		},
		{
			v: 324109,
			vw: 136.5332,
			o: 136.41,
			c: 136.5891,
			h: 136.68,
			l: 136.41,
			t: 1655321340000,
			n: 2574
		},
		{
			v: 303563,
			vw: 136.4108,
			o: 136.59,
			c: 136.34,
			h: 136.6101,
			l: 136.22,
			t: 1655321400000,
			n: 2928
		},
		{
			v: 316151,
			vw: 136.4844,
			o: 136.34,
			c: 136.59,
			h: 136.7,
			l: 136.255,
			t: 1655321460000,
			n: 2867
		},
		{
			v: 302314,
			vw: 136.7462,
			o: 136.59,
			c: 136.8549,
			h: 136.8757,
			l: 136.59,
			t: 1655321520000,
			n: 2512
		},
		{
			v: 462024,
			vw: 137.0453,
			o: 136.88,
			c: 137.0165,
			h: 137.1656,
			l: 136.83,
			t: 1655321580000,
			n: 4204
		},
		{
			v: 317341,
			vw: 137.1124,
			o: 137.02,
			c: 137.13,
			h: 137.205,
			l: 136.9882,
			t: 1655321640000,
			n: 2583
		},
		{
			v: 392665,
			vw: 137.0906,
			o: 137.15,
			c: 136.95,
			h: 137.34,
			l: 136.84,
			t: 1655321700000,
			n: 3646
		},
		{
			v: 288355,
			vw: 136.9273,
			o: 136.95,
			c: 136.81,
			h: 137.04,
			l: 136.81,
			t: 1655321760000,
			n: 2514
		},
		{
			v: 220742,
			vw: 136.7204,
			o: 136.8,
			c: 136.5988,
			h: 136.85,
			l: 136.58,
			t: 1655321820000,
			n: 2400
		},
		{
			v: 263522,
			vw: 136.5328,
			o: 136.59,
			c: 136.44,
			h: 136.62,
			l: 136.42,
			t: 1655321880000,
			n: 2749
		},
		{
			v: 297090,
			vw: 136.376,
			o: 136.44,
			c: 136.34,
			h: 136.48,
			l: 136.29,
			t: 1655321940000,
			n: 2807
		},
		{
			v: 324915,
			vw: 136.1299,
			o: 136.32,
			c: 136.0536,
			h: 136.3399,
			l: 135.94,
			t: 1655322000000,
			n: 3023
		},
		{
			v: 293029,
			vw: 136.1091,
			o: 136.04,
			c: 135.95,
			h: 136.22,
			l: 135.92,
			t: 1655322060000,
			n: 2814
		},
		{
			v: 297301,
			vw: 135.8788,
			o: 135.96,
			c: 135.7542,
			h: 135.99,
			l: 135.74,
			t: 1655322120000,
			n: 2397
		},
		{
			v: 254435,
			vw: 135.8307,
			o: 135.75,
			c: 135.94,
			h: 135.97,
			l: 135.63,
			t: 1655322180000,
			n: 2294
		},
		{
			v: 201106,
			vw: 135.872,
			o: 135.94,
			c: 135.715,
			h: 136.02,
			l: 135.66,
			t: 1655322240000,
			n: 1902
		},
		{
			v: 292405,
			vw: 135.6464,
			o: 135.7,
			c: 135.8,
			h: 135.84,
			l: 135.5,
			t: 1655322300000,
			n: 2748
		},
		{
			v: 205530,
			vw: 135.7557,
			o: 135.795,
			c: 135.805,
			h: 135.9099,
			l: 135.59,
			t: 1655322360000,
			n: 2246
		},
		{
			v: 164720,
			vw: 135.7247,
			o: 135.8,
			c: 135.6569,
			h: 135.85,
			l: 135.63,
			t: 1655322420000,
			n: 1758
		},
		{
			v: 174032,
			vw: 135.6911,
			o: 135.64,
			c: 135.705,
			h: 135.7936,
			l: 135.59,
			t: 1655322480000,
			n: 1963
		},
		{
			v: 351559,
			vw: 135.8107,
			o: 135.705,
			c: 135.9,
			h: 135.93,
			l: 135.69,
			t: 1655322540000,
			n: 3094
		},
		{
			v: 711480,
			vw: 135.3178,
			o: 135.8924,
			c: 135.26,
			h: 135.901,
			l: 135.07,
			t: 1655322600000,
			n: 6474
		},
		{
			v: 342037,
			vw: 135.0005,
			o: 135.26,
			c: 134.94,
			h: 135.26,
			l: 134.88,
			t: 1655322660000,
			n: 3401
		},
		{
			v: 373598,
			vw: 135.0497,
			o: 134.92,
			c: 135.03,
			h: 135.13,
			l: 134.91,
			t: 1655322720000,
			n: 3339
		},
		{
			v: 300494,
			vw: 134.9505,
			o: 135.03,
			c: 134.9,
			h: 135.1,
			l: 134.86,
			t: 1655322780000,
			n: 2753
		},
		{
			v: 465977,
			vw: 135.044,
			o: 134.89,
			c: 135.0825,
			h: 135.23,
			l: 134.86,
			t: 1655322840000,
			n: 3980
		},
		{
			v: 492891,
			vw: 135.3369,
			o: 135.1,
			c: 135.45,
			h: 135.48,
			l: 135.09,
			t: 1655322900000,
			n: 4187
		},
		{
			v: 435649,
			vw: 135.3805,
			o: 135.46,
			c: 135.445,
			h: 135.47,
			l: 135.26,
			t: 1655322960000,
			n: 3453
		},
		{
			v: 545438,
			vw: 135.3142,
			o: 135.45,
			c: 135.21,
			h: 135.49,
			l: 135.21,
			t: 1655323020000,
			n: 3855
		},
		{
			v: 546526,
			vw: 135.354,
			o: 135.21,
			c: 135.32,
			h: 135.44,
			l: 135.21,
			t: 1655323080000,
			n: 4074
		},
		{
			v: 1393502,
			vw: 135.4062,
			o: 135.32,
			c: 135.42,
			h: 135.505,
			l: 135.25,
			t: 1655323140000,
			n: 7792
		},
		{
			v: 823919,
			vw: 135.4247,
			o: 135.43,
			c: 135.44,
			h: 135.5,
			l: 135.06,
			t: 1655323200000,
			n: 253
		},
		{
			v: 10160,
			vw: 135.4076,
			o: 135.43,
			c: 135.35,
			h: 135.53,
			l: 135.34,
			t: 1655323260000,
			n: 106
		},
		{
			v: 11870,
			vw: 135.3875,
			o: 135.38,
			c: 135.41,
			h: 135.43,
			l: 135.37,
			t: 1655323320000,
			n: 88
		},
		{
			v: 7182,
			vw: 135.3902,
			o: 135.4,
			c: 135.3501,
			h: 135.4,
			l: 135.3501,
			t: 1655323380000,
			n: 45
		},
		{
			v: 11808,
			vw: 135.3527,
			o: 135.3799,
			c: 135.35,
			h: 135.38,
			l: 135.34,
			t: 1655323440000,
			n: 80
		},
		{
			v: 15874,
			vw: 135.4189,
			o: 135.35,
			c: 135.4,
			h: 135.43,
			l: 135.35,
			t: 1655323500000,
			n: 44
		},
		{
			v: 2943,
			vw: 135.426,
			o: 135.42,
			c: 135.43,
			h: 135.45,
			l: 135.42,
			t: 1655323560000,
			n: 63
		},
		{
			v: 10332,
			vw: 135.4764,
			o: 135.49,
			c: 135.53,
			h: 135.5347,
			l: 135.46,
			t: 1655323620000,
			n: 104
		},
		{
			v: 41404,
			vw: 135.4336,
			o: 135.49,
			c: 135.5,
			h: 135.5,
			l: 135.44,
			t: 1655323680000,
			n: 74
		},
		{
			v: 5501,
			vw: 135.5086,
			o: 135.5,
			c: 135.55,
			h: 135.55,
			l: 135.5,
			t: 1655323740000,
			n: 84
		},
		{
			v: 866,
			vw: 135.4452,
			o: 135.45,
			c: 135.44,
			h: 135.45,
			l: 135.44,
			t: 1655323800000,
			n: 39
		},
		{
			v: 10903,
			vw: 135.4177,
			o: 135.43,
			c: 135.36,
			h: 135.48,
			l: 135.36,
			t: 1655323860000,
			n: 105
		},
		{
			v: 132674,
			vw: 135.4298,
			o: 135.36,
			c: 135.43,
			h: 135.43,
			l: 135.36,
			t: 1655323920000,
			n: 51
		},
		{
			v: 14098,
			vw: 135.4078,
			o: 135.42,
			c: 135.43,
			h: 135.43,
			l: 135.37,
			t: 1655323980000,
			n: 67
		},
		{
			v: 16579,
			vw: 135.4157,
			o: 135.43,
			c: 135.35,
			h: 135.44,
			l: 135.35,
			t: 1655324040000,
			n: 109
		},
		{
			v: 237102,
			vw: 135.4102,
			o: 135.35,
			c: 134.62,
			h: 135.38,
			l: 134.62,
			t: 1655324100000,
			n: 63
		},
		{
			v: 12355,
			vw: 135.3643,
			o: 135.42,
			c: 135.34,
			h: 135.42,
			l: 135.34,
			t: 1655324160000,
			n: 81
		},
		{
			v: 7816,
			vw: 135.4061,
			o: 135.37,
			c: 135.43,
			h: 135.43,
			l: 135.37,
			t: 1655324220000,
			n: 72
		},
		{
			v: 4823,
			vw: 135.3238,
			o: 135.37,
			c: 135.3,
			h: 135.37,
			l: 135.3,
			t: 1655324280000,
			n: 62
		},
		{
			v: 7496,
			vw: 135.2135,
			o: 135.35,
			c: 135.3,
			h: 135.35,
			l: 135.3,
			t: 1655324340000,
			n: 321
		},
		{
			v: 13943,
			vw: 135.231,
			o: 135.3,
			c: 135.26,
			h: 135.3,
			l: 135.25,
			t: 1655324400000,
			n: 87
		},
		{
			v: 56716,
			vw: 135.4159,
			o: 135.26,
			c: 135.26,
			h: 135.43,
			l: 135.2,
			t: 1655324460000,
			n: 78
		},
		{
			v: 2129,
			vw: 135.2818,
			o: 135.28,
			c: 135.28,
			h: 135.3,
			l: 135.25,
			t: 1655324520000,
			n: 49
		},
		{
			v: 830,
			vw: 135.2594,
			o: 135.27,
			c: 135.21,
			h: 135.27,
			l: 135.21,
			t: 1655324580000,
			n: 41
		},
		{
			v: 3596,
			vw: 135.2314,
			o: 135.2599,
			c: 135.26,
			h: 135.26,
			l: 135.21,
			t: 1655324640000,
			n: 60
		},
		{
			v: 1545,
			vw: 135.2536,
			o: 135.26,
			c: 135.26,
			h: 135.26,
			l: 135.22,
			t: 1655324700000,
			n: 42
		},
		{
			v: 3096,
			vw: 135.2235,
			o: 135.23,
			c: 135.22,
			h: 135.25,
			l: 135.21,
			t: 1655324760000,
			n: 65
		},
		{
			v: 1148,
			vw: 135.2647,
			o: 135.29,
			c: 135.24,
			h: 135.3,
			l: 135.24,
			t: 1655324820000,
			n: 58
		},
		{
			v: 1260,
			vw: 135.2196,
			o: 135.22,
			c: 135.21,
			h: 135.22,
			l: 135.21,
			t: 1655324880000,
			n: 45
		},
		{
			v: 2352,
			vw: 135.2201,
			o: 135.25,
			c: 135.21,
			h: 135.25,
			l: 135.21,
			t: 1655324940000,
			n: 53
		},
		{
			v: 980,
			vw: 135.2562,
			o: 135.29,
			c: 135.21,
			h: 135.29,
			l: 135.21,
			t: 1655325000000,
			n: 33
		},
		{
			v: 200304,
			vw: 135.4289,
			o: 135.28,
			c: 135.25,
			h: 135.43,
			l: 135.25,
			t: 1655325060000,
			n: 46
		},
		{
			v: 1305,
			vw: 135.2529,
			o: 135.25,
			c: 135.26,
			h: 135.26,
			l: 135.25,
			t: 1655325120000,
			n: 35
		},
		{
			v: 3977,
			vw: 135.2952,
			o: 135.23,
			c: 135.31,
			h: 135.31,
			l: 135.23,
			t: 1655325180000,
			n: 56
		},
		{
			v: 5807,
			vw: 135.3542,
			o: 135.36,
			c: 135.34,
			h: 135.39,
			l: 135.33,
			t: 1655325240000,
			n: 58
		},
		{
			v: 8653,
			vw: 135.4094,
			o: 135.38,
			c: 135.44,
			h: 135.45,
			l: 135.37,
			t: 1655325300000,
			n: 86
		},
		{
			v: 5139,
			vw: 135.4373,
			o: 135.45,
			c: 135.44,
			h: 135.48,
			l: 135.41,
			t: 1655325360000,
			n: 53
		},
		{
			v: 1160,
			vw: 135.4307,
			o: 135.43,
			c: 135.43,
			h: 135.43,
			l: 135.43,
			t: 1655325480000,
			n: 32
		},
		{
			v: 2971,
			vw: 135.4335,
			o: 135.43,
			c: 135.46,
			h: 135.46,
			l: 135.43,
			t: 1655325540000,
			n: 32
		},
		{
			v: 1331,
			vw: 135.4353,
			o: 135.43,
			c: 135.4,
			h: 135.45,
			l: 135.4,
			t: 1655325600000,
			n: 51
		},
		{
			v: 438,
			vw: 135.4358,
			o: 135.4299,
			c: 135.4299,
			h: 135.4299,
			l: 135.4299,
			t: 1655325660000,
			n: 26
		},
		{
			v: 35550,
			vw: 135.4299,
			o: 135.43,
			c: 135.43,
			h: 135.43,
			l: 135.43,
			t: 1655325720000,
			n: 27
		},
		{
			v: 452,
			vw: 135.4242,
			o: 135.42,
			c: 135.42,
			h: 135.42,
			l: 135.42,
			t: 1655325780000,
			n: 34
		},
		{
			v: 18324,
			vw: 135.4712,
			o: 135.43,
			c: 135.43,
			h: 135.57,
			l: 135.42,
			t: 1655325840000,
			n: 125
		},
		{
			v: 1038,
			vw: 135.4261,
			o: 135.44,
			c: 135.42,
			h: 135.44,
			l: 135.42,
			t: 1655325900000,
			n: 32
		},
		{
			v: 335,
			vw: 135.4184,
			o: 135.41,
			c: 135.41,
			h: 135.41,
			l: 135.41,
			t: 1655325960000,
			n: 30
		},
		{
			v: 689,
			vw: 135.3907,
			o: 135.4,
			c: 135.39,
			h: 135.4,
			l: 135.39,
			t: 1655326020000,
			n: 33
		},
		{
			v: 290,
			vw: 135.3793,
			o: 135.3664,
			c: 135.3664,
			h: 135.3664,
			l: 135.3664,
			t: 1655326080000,
			n: 18
		},
		{
			v: 2827,
			vw: 135.457,
			o: 135.43,
			c: 135.5076,
			h: 135.5076,
			l: 135.43,
			t: 1655326140000,
			n: 43
		},
		{
			v: 1103,
			vw: 135.4675,
			o: 135.4609,
			c: 135.4609,
			h: 135.4609,
			l: 135.4609,
			t: 1655326200000,
			n: 34
		},
		{
			v: 1232,
			vw: 135.4537,
			o: 135.4999,
			c: 135.44,
			h: 135.4999,
			l: 135.44,
			t: 1655326260000,
			n: 28
		},
		{
			v: 520,
			vw: 135.478,
			o: 135.47,
			c: 135.49,
			h: 135.49,
			l: 135.47,
			t: 1655326380000,
			n: 26
		},
		{
			v: 2293,
			vw: 135.4774,
			o: 135.45,
			c: 135.51,
			h: 135.51,
			l: 135.43,
			t: 1655326440000,
			n: 51
		},
		{
			v: 782,
			vw: 135.5124,
			o: 135.54,
			c: 135.54,
			h: 135.54,
			l: 135.4725,
			t: 1655326500000,
			n: 30
		},
		{
			v: 896,
			vw: 135.5328,
			o: 135.54,
			c: 135.54,
			h: 135.54,
			l: 135.54,
			t: 1655326560000,
			n: 23
		},
		{
			v: 2234,
			vw: 135.4825,
			o: 135.54,
			c: 135.46,
			h: 135.54,
			l: 135.46,
			t: 1655326620000,
			n: 40
		},
		{
			v: 2293,
			vw: 135.4339,
			o: 135.45,
			c: 135.43,
			h: 135.45,
			l: 135.43,
			t: 1655326680000,
			n: 39
		},
		{
			v: 412,
			vw: 135.4079,
			o: 135.4,
			c: 135.4,
			h: 135.4,
			l: 135.4,
			t: 1655326740000,
			n: 24
		},
		{
			v: 2122,
			vw: 136.7037,
			o: 135.45,
			c: 135.4815,
			h: 135.49,
			l: 135.45,
			t: 1655326800000,
			n: 33
		},
		{
			v: 422,
			vw: 135.4536,
			o: 135.44,
			c: 135.44,
			h: 135.44,
			l: 135.44,
			t: 1655326860000,
			n: 28
		},
		{
			v: 808,
			vw: 135.459,
			o: 135.47,
			c: 135.47,
			h: 135.47,
			l: 135.44,
			t: 1655326920000,
			n: 25
		},
		{
			v: 5072,
			vw: 135.4728,
			o: 135.44,
			c: 135.5,
			h: 135.5,
			l: 135.44,
			t: 1655326980000,
			n: 43
		},
		{
			v: 1341,
			vw: 135.5012,
			o: 135.5,
			c: 135.5001,
			h: 135.5001,
			l: 135.5,
			t: 1655327040000,
			n: 36
		},
		{
			v: 575,
			vw: 135.5299,
			o: 135.52,
			c: 135.5201,
			h: 135.5201,
			l: 135.52,
			t: 1655327100000,
			n: 26
		},
		{
			v: 4705,
			vw: 135.5829,
			o: 135.5487,
			c: 135.55,
			h: 135.6,
			l: 135.5487,
			t: 1655327160000,
			n: 58
		},
		{
			v: 749,
			vw: 135.5863,
			o: 135.55,
			c: 135.6,
			h: 135.6,
			l: 135.55,
			t: 1655327220000,
			n: 18
		},
		{
			v: 1637,
			vw: 135.5906,
			o: 135.6,
			c: 135.6,
			h: 135.6001,
			l: 135.57,
			t: 1655327280000,
			n: 30
		},
		{
			v: 1229,
			vw: 135.5838,
			o: 135.56,
			c: 135.6,
			h: 135.6,
			l: 135.55,
			t: 1655327340000,
			n: 41
		},
		{
			v: 281,
			vw: 135.6115,
			o: 135.62,
			c: 135.62,
			h: 135.62,
			l: 135.62,
			t: 1655327460000,
			n: 23
		},
		{
			v: 760,
			vw: 135.6151,
			o: 135.62,
			c: 135.6,
			h: 135.62,
			l: 135.6,
			t: 1655327520000,
			n: 32
		},
		{
			v: 861,
			vw: 135.5682,
			o: 135.58,
			c: 135.56,
			h: 135.58,
			l: 135.56,
			t: 1655327580000,
			n: 31
		},
		{
			v: 986,
			vw: 135.6064,
			o: 135.6,
			c: 135.61,
			h: 135.61,
			l: 135.6,
			t: 1655327640000,
			n: 25
		},
		{
			v: 3123,
			vw: 135.644,
			o: 135.62,
			c: 135.65,
			h: 135.65,
			l: 135.62,
			t: 1655327700000,
			n: 58
		},
		{
			v: 382,
			vw: 135.6143,
			o: 135.62,
			c: 135.6,
			h: 135.62,
			l: 135.6,
			t: 1655327760000,
			n: 16
		},
		{
			v: 4534,
			vw: 135.5464,
			o: 135.61,
			c: 135.5993,
			h: 135.65,
			l: 135.51,
			t: 1655327820000,
			n: 49
		},
		{
			v: 1825,
			vw: 135.5773,
			o: 135.6,
			c: 135.57,
			h: 135.6,
			l: 135.57,
			t: 1655327940000,
			n: 20
		},
		{
			v: 2074,
			vw: 135.5365,
			o: 135.52,
			c: 135.54,
			h: 135.54,
			l: 135.52,
			t: 1655328000000,
			n: 25
		},
		{
			v: 933,
			vw: 135.5421,
			o: 135.52,
			c: 135.57,
			h: 135.57,
			l: 135.52,
			t: 1655328060000,
			n: 25
		},
		{
			v: 2242,
			vw: 135.6277,
			o: 135.58,
			c: 135.64,
			h: 135.64,
			l: 135.58,
			t: 1655328120000,
			n: 35
		},
		{
			v: 2561,
			vw: 135.6185,
			o: 135.64,
			c: 135.64,
			h: 135.64,
			l: 135.58,
			t: 1655328180000,
			n: 45
		},
		{
			v: 538,
			vw: 135.6014,
			o: 135.6,
			c: 135.6,
			h: 135.6,
			l: 135.6,
			t: 1655328240000,
			n: 18
		},
		{
			v: 1083,
			vw: 135.5552,
			o: 135.5501,
			c: 135.5501,
			h: 135.5501,
			l: 135.5501,
			t: 1655328300000,
			n: 19
		},
		{
			v: 397,
			vw: 135.6138,
			o: 135.62,
			c: 135.5941,
			h: 135.62,
			l: 135.5941,
			t: 1655328420000,
			n: 18
		},
		{
			v: 1099,
			vw: 135.633,
			o: 135.63,
			c: 135.64,
			h: 135.64,
			l: 135.63,
			t: 1655328540000,
			n: 32
		},
		{
			v: 1518,
			vw: 135.5984,
			o: 135.6,
			c: 135.5899,
			h: 135.6,
			l: 135.5899,
			t: 1655328600000,
			n: 20
		},
		{
			v: 10219,
			vw: 135.6484,
			o: 135.63,
			c: 135.7,
			h: 135.7,
			l: 135.63,
			t: 1655328660000,
			n: 49
		},
		{
			v: 902,
			vw: 135.6994,
			o: 135.6512,
			c: 135.71,
			h: 135.71,
			l: 135.6512,
			t: 1655328720000,
			n: 27
		},
		{
			v: 350,
			vw: 135.6878,
			o: 135.6835,
			c: 135.69,
			h: 135.69,
			l: 135.6835,
			t: 1655328780000,
			n: 24
		},
		{
			v: 2977,
			vw: 135.7172,
			o: 135.69,
			c: 135.72,
			h: 135.74,
			l: 135.69,
			t: 1655328840000,
			n: 48
		},
		{
			v: 1043,
			vw: 135.7349,
			o: 135.72,
			c: 135.74,
			h: 135.74,
			l: 135.72,
			t: 1655328900000,
			n: 28
		},
		{
			v: 3051,
			vw: 135.7363,
			o: 135.74,
			c: 135.71,
			h: 135.74,
			l: 135.71,
			t: 1655328960000,
			n: 42
		},
		{
			v: 2050,
			vw: 135.7202,
			o: 135.73,
			c: 135.7101,
			h: 135.74,
			l: 135.71,
			t: 1655329020000,
			n: 36
		},
		{
			v: 1985,
			vw: 135.7236,
			o: 135.7299,
			c: 135.71,
			h: 135.73,
			l: 135.71,
			t: 1655329080000,
			n: 25
		},
		{
			v: 1351,
			vw: 135.7326,
			o: 135.72,
			c: 135.74,
			h: 135.74,
			l: 135.72,
			t: 1655329140000,
			n: 22
		},
		{
			v: 1831,
			vw: 135.7251,
			o: 135.74,
			c: 135.73,
			h: 135.74,
			l: 135.71,
			t: 1655329200000,
			n: 29
		},
		{
			v: 901,
			vw: 135.7334,
			o: 135.72,
			c: 135.74,
			h: 135.74,
			l: 135.72,
			t: 1655329320000,
			n: 30
		},
		{
			v: 1141,
			vw: 135.7392,
			o: 135.74,
			c: 135.74,
			h: 135.74,
			l: 135.74,
			t: 1655329380000,
			n: 19
		},
		{
			v: 1560,
			vw: 135.7371,
			o: 135.73,
			c: 135.74,
			h: 135.74,
			l: 135.73,
			t: 1655329440000,
			n: 49
		},
		{
			v: 1204,
			vw: 135.7368,
			o: 135.74,
			c: 135.72,
			h: 135.74,
			l: 135.72,
			t: 1655329500000,
			n: 27
		},
		{
			v: 559,
			vw: 135.7253,
			o: 135.7213,
			c: 135.7213,
			h: 135.7213,
			l: 135.7213,
			t: 1655329560000,
			n: 27
		},
		{
			v: 1017,
			vw: 135.7314,
			o: 135.73,
			c: 135.74,
			h: 135.74,
			l: 135.73,
			t: 1655329620000,
			n: 25
		},
		{
			v: 8560,
			vw: 135.7716,
			o: 135.73,
			c: 135.83,
			h: 135.83,
			l: 135.73,
			t: 1655329740000,
			n: 47
		},
		{
			v: 301,
			vw: 135.7784,
			o: 135.76,
			c: 135.76,
			h: 135.76,
			l: 135.76,
			t: 1655329800000,
			n: 26
		},
		{
			v: 1621,
			vw: 135.819,
			o: 135.82,
			c: 135.83,
			h: 135.83,
			l: 135.82,
			t: 1655329860000,
			n: 28
		},
		{
			v: 6421,
			vw: 135.8051,
			o: 135.83,
			c: 135.74,
			h: 135.83,
			l: 135.74,
			t: 1655329920000,
			n: 39
		},
		{
			v: 1674,
			vw: 135.7998,
			o: 135.8,
			c: 135.82,
			h: 135.82,
			l: 135.79,
			t: 1655329980000,
			n: 28
		},
		{
			v: 3573,
			vw: 135.8306,
			o: 135.83,
			c: 135.85,
			h: 135.85,
			l: 135.8299,
			t: 1655330040000,
			n: 46
		},
		{
			v: 1455,
			vw: 135.8895,
			o: 135.89,
			c: 135.89,
			h: 135.89,
			l: 135.89,
			t: 1655330100000,
			n: 31
		},
		{
			v: 17330,
			vw: 135.4788,
			o: 135.89,
			c: 135.9,
			h: 135.9,
			l: 135.43,
			t: 1655330220000,
			n: 38
		},
		{
			v: 942,
			vw: 135.8923,
			o: 135.89,
			c: 135.8901,
			h: 135.8901,
			l: 135.89,
			t: 1655330280000,
			n: 24
		},
		{
			v: 3490,
			vw: 135.8707,
			o: 135.9,
			c: 135.86,
			h: 135.9,
			l: 135.86,
			t: 1655330340000,
			n: 25
		},
		{
			v: 3518,
			vw: 135.8367,
			o: 135.86,
			c: 135.8,
			h: 135.86,
			l: 135.8,
			t: 1655330400000,
			n: 43
		},
		{
			v: 1421,
			vw: 135.7961,
			o: 135.78,
			c: 135.8,
			h: 135.84,
			l: 135.78,
			t: 1655330460000,
			n: 23
		},
		{
			v: 2543,
			vw: 135.7849,
			o: 135.75,
			c: 135.8,
			h: 135.8,
			l: 135.75,
			t: 1655330520000,
			n: 33
		},
		{
			v: 2320,
			vw: 135.8046,
			o: 135.8,
			c: 135.8,
			h: 135.8,
			l: 135.8,
			t: 1655330580000,
			n: 32
		},
		{
			v: 829,
			vw: 135.8511,
			o: 135.85,
			c: 135.85,
			h: 135.85,
			l: 135.85,
			t: 1655330640000,
			n: 21
		},
		{
			v: 1627,
			vw: 135.8493,
			o: 135.85,
			c: 135.85,
			h: 135.85,
			l: 135.85,
			t: 1655330700000,
			n: 26
		},
		{
			v: 1039,
			vw: 135.8466,
			o: 135.85,
			c: 135.85,
			h: 135.85,
			l: 135.85,
			t: 1655330760000,
			n: 23
		},
		{
			v: 1113,
			vw: 135.8338,
			o: 135.83,
			c: 135.85,
			h: 135.85,
			l: 135.83,
			t: 1655330820000,
			n: 19
		},
		{
			v: 1289,
			vw: 135.845,
			o: 135.84,
			c: 135.84,
			h: 135.84,
			l: 135.84,
			t: 1655330940000,
			n: 40
		},
		{
			v: 5897,
			vw: 135.8501,
			o: 135.85,
			c: 135.85,
			h: 135.85,
			l: 135.85,
			t: 1655331000000,
			n: 53
		},
		{
			v: 744,
			vw: 135.8875,
			o: 135.9,
			c: 135.87,
			h: 135.9,
			l: 135.87,
			t: 1655331060000,
			n: 27
		},
		{
			v: 1654,
			vw: 135.8991,
			o: 135.9,
			c: 135.9,
			h: 135.92,
			l: 135.9,
			t: 1655331120000,
			n: 29
		},
		{
			v: 1009,
			vw: 135.9066,
			o: 135.9,
			c: 135.92,
			h: 135.92,
			l: 135.9,
			t: 1655331180000,
			n: 20
		},
		{
			v: 8453,
			vw: 135.9009,
			o: 135.9,
			c: 135.9,
			h: 135.92,
			l: 135.89,
			t: 1655331240000,
			n: 83
		},
		{
			v: 12414,
			vw: 135.974,
			o: 135.94,
			c: 136.01,
			h: 136.01,
			l: 135.94,
			t: 1655331300000,
			n: 85
		},
		{
			v: 2152,
			vw: 136.0607,
			o: 136.02,
			c: 136.09,
			h: 136.09,
			l: 136.02,
			t: 1655331360000,
			n: 29
		},
		{
			v: 781,
			vw: 136.0986,
			o: 136.09,
			c: 136.09,
			h: 136.09,
			l: 136.09,
			t: 1655331420000,
			n: 35
		},
		{
			v: 1319,
			vw: 136.1905,
			o: 136.18,
			c: 136.21,
			h: 136.21,
			l: 136.18,
			t: 1655331480000,
			n: 36
		},
		{
			v: 2768,
			vw: 136.1898,
			o: 136.15,
			c: 136.18,
			h: 136.21,
			l: 136.15,
			t: 1655331540000,
			n: 51
		},
		{
			v: 4386,
			vw: 136.1982,
			o: 136.2,
			c: 136.18,
			h: 136.21,
			l: 136.17,
			t: 1655331600000,
			n: 48
		},
		{
			v: 1056,
			vw: 136.1628,
			o: 136.2,
			c: 136.19,
			h: 136.2,
			l: 136.12,
			t: 1655331660000,
			n: 35
		},
		{
			v: 1681,
			vw: 136.1385,
			o: 136.11,
			c: 136.15,
			h: 136.15,
			l: 136.11,
			t: 1655331720000,
			n: 31
		},
		{
			v: 2110,
			vw: 136.1263,
			o: 136.11,
			c: 136.12,
			h: 136.15,
			l: 136.11,
			t: 1655331780000,
			n: 36
		},
		{
			v: 1306,
			vw: 136.1486,
			o: 136.13,
			c: 136.15,
			h: 136.15,
			l: 136.13,
			t: 1655331840000,
			n: 37
		},
		{
			v: 1051,
			vw: 136.1748,
			o: 136.18,
			c: 136.18,
			h: 136.18,
			l: 136.1799,
			t: 1655331900000,
			n: 27
		},
		{
			v: 1986,
			vw: 136.1752,
			o: 136.18,
			c: 136.16,
			h: 136.18,
			l: 136.16,
			t: 1655331960000,
			n: 37
		},
		{
			v: 1714,
			vw: 136.1279,
			o: 136.15,
			c: 136.16,
			h: 136.16,
			l: 136.15,
			t: 1655332020000,
			n: 45
		},
		{
			v: 1781,
			vw: 136.1875,
			o: 136.17,
			c: 136.2,
			h: 136.2,
			l: 136.17,
			t: 1655332080000,
			n: 26
		},
		{
			v: 5804,
			vw: 136.2169,
			o: 136.2,
			c: 136.25,
			h: 136.25,
			l: 136.2,
			t: 1655332140000,
			n: 42
		},
		{
			v: 1619,
			vw: 136.3246,
			o: 136.32,
			c: 136.33,
			h: 136.33,
			l: 136.32,
			t: 1655332200000,
			n: 29
		},
		{
			v: 1460,
			vw: 136.2563,
			o: 136.27,
			c: 136.2,
			h: 136.3,
			l: 136.2,
			t: 1655332260000,
			n: 33
		},
		{
			v: 1316,
			vw: 136.1714,
			o: 136.19,
			c: 136.16,
			h: 136.19,
			l: 136.16,
			t: 1655332320000,
			n: 24
		},
		{
			v: 1249,
			vw: 136.2869,
			o: 136.28,
			c: 136.3,
			h: 136.3,
			l: 136.28,
			t: 1655332380000,
			n: 28
		},
		{
			v: 352,
			vw: 136.3178,
			o: 136.3,
			c: 136.3,
			h: 136.3,
			l: 136.3,
			t: 1655332440000,
			n: 27
		},
		{
			v: 3473,
			vw: 136.2629,
			o: 136.28,
			c: 136.28,
			h: 136.29,
			l: 136.25,
			t: 1655332500000,
			n: 33
		},
		{
			v: 6192,
			vw: 136.2176,
			o: 136.23,
			c: 136.23,
			h: 136.23,
			l: 136.15,
			t: 1655332560000,
			n: 38
		},
		{
			v: 1778,
			vw: 136.1979,
			o: 136.17,
			c: 136.23,
			h: 136.23,
			l: 136.16,
			t: 1655332620000,
			n: 31
		},
		{
			v: 1411,
			vw: 136.2389,
			o: 136.25,
			c: 136.22,
			h: 136.25,
			l: 136.22,
			t: 1655332680000,
			n: 22
		},
		{
			v: 1681,
			vw: 136.2868,
			o: 136.25,
			c: 136.34,
			h: 136.34,
			l: 136.25,
			t: 1655332740000,
			n: 37
		},
		{
			v: 2528,
			vw: 136.2231,
			o: 136.26,
			c: 136.22,
			h: 136.26,
			l: 136.2,
			t: 1655332800000,
			n: 36
		},
		{
			v: 3543,
			vw: 136.2515,
			o: 136.25,
			c: 136.26,
			h: 136.26,
			l: 136.25,
			t: 1655332920000,
			n: 31
		},
		{
			v: 992,
			vw: 136.2572,
			o: 136.27,
			c: 136.26,
			h: 136.27,
			l: 136.25,
			t: 1655332980000,
			n: 18
		},
		{
			v: 596,
			vw: 136.272,
			o: 136.27,
			c: 136.27,
			h: 136.27,
			l: 136.27,
			t: 1655333040000,
			n: 26
		},
		{
			v: 986,
			vw: 136.2519,
			o: 136.28,
			c: 136.22,
			h: 136.28,
			l: 136.22,
			t: 1655333100000,
			n: 31
		},
		{
			v: 2089,
			vw: 136.2545,
			o: 136.23,
			c: 136.25,
			h: 136.27,
			l: 136.23,
			t: 1655333160000,
			n: 30
		},
		{
			v: 696,
			vw: 136.23,
			o: 136.23,
			c: 136.23,
			h: 136.23,
			l: 136.23,
			t: 1655333220000,
			n: 25
		},
		{
			v: 2572,
			vw: 136.2386,
			o: 136.24,
			c: 136.2699,
			h: 136.2699,
			l: 136.23,
			t: 1655333280000,
			n: 25
		},
		{
			v: 1796,
			vw: 136.2472,
			o: 136.25,
			c: 136.25,
			h: 136.25,
			l: 136.24,
			t: 1655333340000,
			n: 28
		},
		{
			v: 424,
			vw: 136.2409,
			o: 136.24,
			c: 136.24,
			h: 136.24,
			l: 136.24,
			t: 1655333400000,
			n: 15
		},
		{
			v: 1783,
			vw: 136.2361,
			o: 136.24,
			c: 136.21,
			h: 136.24,
			l: 136.21,
			t: 1655333460000,
			n: 24
		},
		{
			v: 781,
			vw: 136.2323,
			o: 136.25,
			c: 136.23,
			h: 136.25,
			l: 136.23,
			t: 1655333520000,
			n: 26
		},
		{
			v: 2107,
			vw: 136.2416,
			o: 136.23,
			c: 136.25,
			h: 136.25,
			l: 136.23,
			t: 1655333580000,
			n: 30
		},
		{
			v: 4206,
			vw: 136.2033,
			o: 136.25,
			c: 136.19,
			h: 136.25,
			l: 136.19,
			t: 1655333640000,
			n: 46
		},
		{
			v: 997,
			vw: 136.24,
			o: 136.23,
			c: 136.25,
			h: 136.25,
			l: 136.23,
			t: 1655333760000,
			n: 33
		},
		{
			v: 2638,
			vw: 136.25,
			o: 136.25,
			c: 136.25,
			h: 136.25,
			l: 136.25,
			t: 1655333940000,
			n: 23
		},
		{
			v: 1154,
			vw: 136.25,
			o: 136.21,
			c: 136.27,
			h: 136.27,
			l: 136.21,
			t: 1655334000000,
			n: 26
		},
		{
			v: 237,
			vw: 136.2679,
			o: 136.28,
			c: 136.28,
			h: 136.28,
			l: 136.28,
			t: 1655334060000,
			n: 18
		},
		{
			v: 1722,
			vw: 136.2661,
			o: 136.28,
			c: 136.28,
			h: 136.28,
			l: 136.25,
			t: 1655334120000,
			n: 23
		},
		{
			v: 1931,
			vw: 136.2637,
			o: 136.27,
			c: 136.25,
			h: 136.27,
			l: 136.25,
			t: 1655334180000,
			n: 23
		},
		{
			v: 2223,
			vw: 136.209,
			o: 136.25,
			c: 136.2,
			h: 136.25,
			l: 136.2,
			t: 1655334240000,
			n: 24
		},
		{
			v: 736,
			vw: 136.2018,
			o: 136.19,
			c: 136.25,
			h: 136.25,
			l: 136.18,
			t: 1655334300000,
			n: 17
		},
		{
			v: 1341,
			vw: 136.1745,
			o: 136.16,
			c: 136.15,
			h: 136.17,
			l: 136.15,
			t: 1655334360000,
			n: 32
		},
		{
			v: 2112,
			vw: 136.1672,
			o: 136.22,
			c: 136.21,
			h: 136.22,
			l: 136.11,
			t: 1655334420000,
			n: 41
		},
		{
			v: 1319,
			vw: 136.2009,
			o: 136.2,
			c: 136.2,
			h: 136.2,
			l: 136.2,
			t: 1655334480000,
			n: 20
		},
		{
			v: 2752,
			vw: 136.1909,
			o: 136.19,
			c: 136.19,
			h: 136.19,
			l: 136.19,
			t: 1655334540000,
			n: 18
		},
		{
			v: 386,
			vw: 136.2373,
			o: 136.25,
			c: 136.23,
			h: 136.25,
			l: 136.23,
			t: 1655334600000,
			n: 12
		},
		{
			v: 1104,
			vw: 136.2322,
			o: 136.25,
			c: 136.24,
			h: 136.25,
			l: 136.23,
			t: 1655334660000,
			n: 18
		},
		{
			v: 845,
			vw: 136.2082,
			o: 136.2,
			c: 136.24,
			h: 136.24,
			l: 136.2,
			t: 1655334720000,
			n: 28
		},
		{
			v: 2212,
			vw: 136.1473,
			o: 136.18,
			c: 136.19,
			h: 136.19,
			l: 136.1,
			t: 1655334840000,
			n: 46
		},
		{
			v: 4619,
			vw: 136.0719,
			o: 136.15,
			c: 136.1,
			h: 136.15,
			l: 136.05,
			t: 1655334900000,
			n: 41
		},
		{
			v: 530,
			vw: 136.0869,
			o: 136.12,
			c: 136.05,
			h: 136.12,
			l: 136.05,
			t: 1655334960000,
			n: 24
		},
		{
			v: 1529,
			vw: 136.0475,
			o: 136.05,
			c: 136.05,
			h: 136.05,
			l: 136.04,
			t: 1655335020000,
			n: 28
		},
		{
			v: 382,
			vw: 136.1338,
			o: 136.12,
			c: 136.12,
			h: 136.12,
			l: 136.12,
			t: 1655335200000,
			n: 21
		},
		{
			v: 718,
			vw: 136.1463,
			o: 136.14,
			c: 136.15,
			h: 136.15,
			l: 136.14,
			t: 1655335260000,
			n: 18
		},
		{
			v: 261,
			vw: 136.1969,
			o: 136.2,
			c: 136.2,
			h: 136.2,
			l: 136.2,
			t: 1655335320000,
			n: 14
		},
		{
			v: 275,
			vw: 136.1767,
			o: 136.18,
			c: 136.18,
			h: 136.18,
			l: 136.18,
			t: 1655335380000,
			n: 14
		},
		{
			v: 3050,
			vw: 136.1452,
			o: 136.15,
			c: 136.1,
			h: 136.2,
			l: 136.1,
			t: 1655335440000,
			n: 39
		},
		{
			v: 1396,
			vw: 136.2103,
			o: 136.18,
			c: 136.21,
			h: 136.24,
			l: 136.18,
			t: 1655335860000,
			n: 25
		},
		{
			v: 158,
			vw: 136.1697,
			o: 136.16,
			c: 136.16,
			h: 136.16,
			l: 136.16,
			t: 1655335920000,
			n: 14
		},
		{
			v: 469,
			vw: 136.1889,
			o: 136.19,
			c: 136.19,
			h: 136.19,
			l: 136.19,
			t: 1655335980000,
			n: 17
		},
		{
			v: 743,
			vw: 136.2142,
			o: 136.2,
			c: 136.25,
			h: 136.25,
			l: 136.2,
			t: 1655336100000,
			n: 32
		},
		{
			v: 311,
			vw: 136.2289,
			o: 136.24,
			c: 136.24,
			h: 136.24,
			l: 136.24,
			t: 1655336160000,
			n: 16
		},
		{
			v: 244,
			vw: 136.2449,
			o: 136.25,
			c: 136.25,
			h: 136.25,
			l: 136.25,
			t: 1655336220000,
			n: 9
		},
		{
			v: 526,
			vw: 136.2499,
			o: 136.25,
			c: 136.25,
			h: 136.25,
			l: 136.25,
			t: 1655336340000,
			n: 24
		},
		{
			v: 646,
			vw: 136.2771,
			o: 136.28,
			c: 136.28,
			h: 136.28,
			l: 136.28,
			t: 1655336400000,
			n: 30
		},
		{
			v: 2676,
			vw: 136.3023,
			o: 136.29,
			c: 136.33,
			h: 136.33,
			l: 136.29,
			t: 1655336460000,
			n: 46
		},
		{
			v: 2666,
			vw: 136.3541,
			o: 136.33,
			c: 136.38,
			h: 136.38,
			l: 136.33,
			t: 1655336520000,
			n: 51
		},
		{
			v: 2843,
			vw: 136.3861,
			o: 136.38,
			c: 136.4,
			h: 136.4,
			l: 136.37,
			t: 1655336580000,
			n: 40
		},
		{
			v: 640,
			vw: 136.422,
			o: 136.43,
			c: 136.43,
			h: 136.43,
			l: 136.43,
			t: 1655336640000,
			n: 31
		},
		{
			v: 1307,
			vw: 136.4005,
			o: 136.4,
			c: 136.4,
			h: 136.42,
			l: 136.4,
			t: 1655336700000,
			n: 30
		},
		{
			v: 854,
			vw: 136.3879,
			o: 136.38,
			c: 136.38,
			h: 136.38,
			l: 136.38,
			t: 1655336760000,
			n: 37
		},
		{
			v: 2538,
			vw: 136.4302,
			o: 136.4,
			c: 136.49,
			h: 136.49,
			l: 136.39,
			t: 1655336820000,
			n: 49
		},
		{
			v: 1945,
			vw: 136.4611,
			o: 136.48,
			c: 136.43,
			h: 136.5,
			l: 136.43,
			t: 1655336880000,
			n: 45
		},
		{
			v: 1573,
			vw: 136.4533,
			o: 136.49,
			c: 136.45,
			h: 136.49,
			l: 136.45,
			t: 1655336940000,
			n: 22
		},
		{
			v: 2913,
			vw: 136.4581,
			o: 136.44,
			c: 136.49,
			h: 136.49,
			l: 136.44,
			t: 1655337000000,
			n: 47
		},
		{
			v: 352,
			vw: 136.4636,
			o: 136.45,
			c: 136.46,
			h: 136.46,
			l: 136.45,
			t: 1655337060000,
			n: 23
		},
		{
			v: 1063,
			vw: 136.4539,
			o: 136.45,
			c: 136.45,
			h: 136.46,
			l: 136.45,
			t: 1655337120000,
			n: 37
		},
		{
			v: 1879,
			vw: 136.4879,
			o: 136.48,
			c: 136.49,
			h: 136.49,
			l: 136.48,
			t: 1655337240000,
			n: 31
		},
		{
			v: 3877,
			vw: 136.536,
			o: 136.49,
			c: 136.69,
			h: 136.69,
			l: 136.49,
			t: 1655337300000,
			n: 85
		},
		{
			v: 721,
			vw: 136.6172,
			o: 136.66,
			c: 136.6,
			h: 136.66,
			l: 136.6,
			t: 1655337360000,
			n: 26
		},
		{
			v: 1431,
			vw: 136.6353,
			o: 136.6499,
			c: 136.65,
			h: 136.65,
			l: 136.6499,
			t: 1655337420000,
			n: 42
		},
		{
			v: 3575,
			vw: 136.6362,
			o: 136.65,
			c: 136.61,
			h: 136.65,
			l: 136.61,
			t: 1655337480000,
			n: 51
		},
		{
			v: 7461,
			vw: 136.5799,
			o: 136.64,
			c: 136.5,
			h: 136.65,
			l: 136.5,
			t: 1655337540000,
			n: 94
		},
		{
			v: 5532,
			vw: 132.4148,
			o: 133,
			c: 132.07,
			h: 133,
			l: 132.07,
			t: 1655366400000,
			n: 150
		},
		{
			v: 1833,
			vw: 132.1587,
			o: 132.16,
			c: 132.11,
			h: 132.18,
			l: 132.1,
			t: 1655366460000,
			n: 74
		},
		{
			v: 3771,
			vw: 132.1693,
			o: 132.11,
			c: 132.2,
			h: 132.3,
			l: 132.11,
			t: 1655366520000,
			n: 84
		},
		{
			v: 7131,
			vw: 132.4336,
			o: 132.3,
			c: 132.37,
			h: 132.51,
			l: 132.3,
			t: 1655366580000,
			n: 135
		},
		{
			v: 4268,
			vw: 132.4984,
			o: 132.41,
			c: 132.53,
			h: 132.58,
			l: 132.41,
			t: 1655366640000,
			n: 39
		},
		{
			v: 6740,
			vw: 132.4901,
			o: 132.53,
			c: 132.42,
			h: 132.53,
			l: 132.4,
			t: 1655366700000,
			n: 93
		},
		{
			v: 3744,
			vw: 132.4454,
			o: 132.58,
			c: 132.58,
			h: 132.58,
			l: 132.4,
			t: 1655366760000,
			n: 86
		},
		{
			v: 5153,
			vw: 132.4787,
			o: 132.5,
			c: 132.47,
			h: 132.5,
			l: 132.47,
			t: 1655366820000,
			n: 49
		},
		{
			v: 3684,
			vw: 132.4619,
			o: 132.49,
			c: 132.56,
			h: 132.56,
			l: 132.4,
			t: 1655366880000,
			n: 59
		},
		{
			v: 4507,
			vw: 132.4592,
			o: 132.46,
			c: 132.4,
			h: 132.47,
			l: 132.4,
			t: 1655366940000,
			n: 59
		},
		{
			v: 7497,
			vw: 132.4028,
			o: 132.4,
			c: 132.4,
			h: 132.44,
			l: 132.4,
			t: 1655367000000,
			n: 108
		},
		{
			v: 2986,
			vw: 132.4037,
			o: 132.41,
			c: 132.4,
			h: 132.41,
			l: 132.4,
			t: 1655367060000,
			n: 44
		},
		{
			v: 4840,
			vw: 132.4145,
			o: 132.4,
			c: 132.45,
			h: 132.45,
			l: 132.4,
			t: 1655367120000,
			n: 59
		},
		{
			v: 1268,
			vw: 132.4761,
			o: 132.49,
			c: 132.49,
			h: 132.49,
			l: 132.49,
			t: 1655367180000,
			n: 27
		},
		{
			v: 3232,
			vw: 132.4383,
			o: 132.49,
			c: 132.4,
			h: 132.49,
			l: 132.4,
			t: 1655367240000,
			n: 39
		},
		{
			v: 764,
			vw: 132.4803,
			o: 132.49,
			c: 132.49,
			h: 132.49,
			l: 132.49,
			t: 1655367300000,
			n: 29
		},
		{
			v: 338,
			vw: 132.4651,
			o: 132.47,
			c: 132.47,
			h: 132.47,
			l: 132.47,
			t: 1655367360000,
			n: 19
		},
		{
			v: 3915,
			vw: 132.531,
			o: 132.5,
			c: 132.6,
			h: 132.6,
			l: 132.5,
			t: 1655367420000,
			n: 61
		},
		{
			v: 4728,
			vw: 132.5365,
			o: 132.6,
			c: 132.5,
			h: 132.6,
			l: 132.49,
			t: 1655367480000,
			n: 85
		},
		{
			v: 3836,
			vw: 132.4916,
			o: 132.49,
			c: 132.48,
			h: 132.49,
			l: 132.48,
			t: 1655367540000,
			n: 34
		},
		{
			v: 1489,
			vw: 132.484,
			o: 132.47,
			c: 132.48,
			h: 132.51,
			l: 132.47,
			t: 1655367600000,
			n: 24
		},
		{
			v: 9891,
			vw: 132.4652,
			o: 132.47,
			c: 132.45,
			h: 132.47,
			l: 132.45,
			t: 1655367660000,
			n: 123
		},
		{
			v: 7337,
			vw: 132.4305,
			o: 132.45,
			c: 132.43,
			h: 132.46,
			l: 132.4,
			t: 1655367720000,
			n: 111
		},
		{
			v: 3423,
			vw: 132.3967,
			o: 132.41,
			c: 132.42,
			h: 132.43,
			l: 132.33,
			t: 1655367780000,
			n: 78
		},
		{
			v: 7744,
			vw: 132.3389,
			o: 132.41,
			c: 132.33,
			h: 132.41,
			l: 132.32,
			t: 1655367840000,
			n: 74
		},
		{
			v: 4449,
			vw: 132.3334,
			o: 132.35,
			c: 132.43,
			h: 132.44,
			l: 132.31,
			t: 1655367900000,
			n: 70
		},
		{
			v: 435,
			vw: 132.4043,
			o: 132.4,
			c: 132.45,
			h: 132.45,
			l: 132.4,
			t: 1655367960000,
			n: 10
		},
		{
			v: 1039,
			vw: 132.4604,
			o: 132.45,
			c: 132.44,
			h: 132.5,
			l: 132.44,
			t: 1655368020000,
			n: 19
		},
		{
			v: 2313,
			vw: 132.4947,
			o: 132.5,
			c: 132.51,
			h: 132.52,
			l: 132.42,
			t: 1655368140000,
			n: 37
		},
		{
			v: 894,
			vw: 132.4436,
			o: 132.44,
			c: 132.44,
			h: 132.44,
			l: 132.44,
			t: 1655368200000,
			n: 26
		},
		{
			v: 2305,
			vw: 132.3323,
			o: 132.4,
			c: 132.32,
			h: 132.4,
			l: 132.25,
			t: 1655368260000,
			n: 85
		},
		{
			v: 1186,
			vw: 132.2681,
			o: 132.26,
			c: 132.25,
			h: 132.32,
			l: 132.25,
			t: 1655368320000,
			n: 33
		},
		{
			v: 1054,
			vw: 132.2865,
			o: 132.34,
			c: 132.24,
			h: 132.34,
			l: 132.24,
			t: 1655368380000,
			n: 14
		},
		{
			v: 5910,
			vw: 132.1644,
			o: 132.25,
			c: 132.08,
			h: 132.25,
			l: 132.08,
			t: 1655368440000,
			n: 100
		},
		{
			v: 2689,
			vw: 132.0623,
			o: 132.15,
			c: 132.02,
			h: 132.15,
			l: 132.02,
			t: 1655368560000,
			n: 65
		},
		{
			v: 6514,
			vw: 132.0269,
			o: 132.14,
			c: 132.1,
			h: 132.14,
			l: 132.01,
			t: 1655368620000,
			n: 54
		},
		{
			v: 1682,
			vw: 132.1027,
			o: 132.17,
			c: 132.03,
			h: 132.17,
			l: 132.03,
			t: 1655368740000,
			n: 30
		},
		{
			v: 1082,
			vw: 132.183,
			o: 132.16,
			c: 132.2,
			h: 132.2,
			l: 132.16,
			t: 1655368800000,
			n: 18
		},
		{
			v: 1052,
			vw: 132.0561,
			o: 132.07,
			c: 132.04,
			h: 132.07,
			l: 132.04,
			t: 1655368860000,
			n: 35
		},
		{
			v: 1138,
			vw: 132.0254,
			o: 132.03,
			c: 132.02,
			h: 132.03,
			l: 132.02,
			t: 1655368920000,
			n: 39
		},
		{
			v: 3839,
			vw: 131.9542,
			o: 132.01,
			c: 131.88,
			h: 132.01,
			l: 131.82,
			t: 1655368980000,
			n: 189
		},
		{
			v: 1946,
			vw: 131.7509,
			o: 131.86,
			c: 131.61,
			h: 131.86,
			l: 131.61,
			t: 1655369040000,
			n: 106
		},
		{
			v: 4103,
			vw: 131.7942,
			o: 131.68,
			c: 131.87,
			h: 131.87,
			l: 131.68,
			t: 1655369100000,
			n: 60
		},
		{
			v: 1965,
			vw: 131.8527,
			o: 131.87,
			c: 131.81,
			h: 131.87,
			l: 131.81,
			t: 1655369160000,
			n: 52
		},
		{
			v: 493,
			vw: 131.8647,
			o: 131.82,
			c: 131.82,
			h: 131.82,
			l: 131.82,
			t: 1655369220000,
			n: 19
		},
		{
			v: 1004,
			vw: 131.9025,
			o: 131.83,
			c: 131.83,
			h: 131.83,
			l: 131.83,
			t: 1655369340000,
			n: 28
		},
		{
			v: 11786,
			vw: 131.892,
			o: 132,
			c: 131.72,
			h: 132,
			l: 131.62,
			t: 1655369400000,
			n: 178
		},
		{
			v: 2791,
			vw: 131.6937,
			o: 131.69,
			c: 131.63,
			h: 131.81,
			l: 131.62,
			t: 1655369460000,
			n: 49
		},
		{
			v: 2493,
			vw: 131.6783,
			o: 131.75,
			c: 131.65,
			h: 131.76,
			l: 131.65,
			t: 1655369520000,
			n: 58
		},
		{
			v: 749,
			vw: 131.632,
			o: 131.62,
			c: 131.67,
			h: 131.67,
			l: 131.62,
			t: 1655369580000,
			n: 32
		},
		{
			v: 1172,
			vw: 131.804,
			o: 131.81,
			c: 131.8,
			h: 131.81,
			l: 131.8,
			t: 1655369640000,
			n: 29
		},
		{
			v: 894,
			vw: 131.733,
			o: 131.8,
			c: 131.7,
			h: 131.8,
			l: 131.7,
			t: 1655369820000,
			n: 29
		},
		{
			v: 1896,
			vw: 131.6108,
			o: 131.63,
			c: 131.62,
			h: 131.63,
			l: 131.51,
			t: 1655369880000,
			n: 61
		},
		{
			v: 3410,
			vw: 131.5794,
			o: 131.61,
			c: 131.67,
			h: 131.67,
			l: 131.55,
			t: 1655369940000,
			n: 94
		},
		{
			v: 8504,
			vw: 131.4072,
			o: 131.53,
			c: 131.26,
			h: 131.53,
			l: 131.26,
			t: 1655370000000,
			n: 263
		},
		{
			v: 2121,
			vw: 131.3806,
			o: 131.32,
			c: 131.43,
			h: 131.43,
			l: 131.32,
			t: 1655370060000,
			n: 35
		},
		{
			v: 4130,
			vw: 131.4195,
			o: 131.45,
			c: 131.33,
			h: 131.45,
			l: 131.33,
			t: 1655370120000,
			n: 60
		},
		{
			v: 1591,
			vw: 131.4577,
			o: 131.46,
			c: 131.47,
			h: 131.47,
			l: 131.46,
			t: 1655370180000,
			n: 35
		},
		{
			v: 2656,
			vw: 131.3405,
			o: 131.33,
			c: 131.32,
			h: 131.33,
			l: 131.32,
			t: 1655370300000,
			n: 48
		},
		{
			v: 2958,
			vw: 131.3034,
			o: 131.3,
			c: 131.22,
			h: 131.33,
			l: 131.22,
			t: 1655370360000,
			n: 84
		},
		{
			v: 2297,
			vw: 131.2122,
			o: 131.2,
			c: 131.25,
			h: 131.25,
			l: 131.2,
			t: 1655370420000,
			n: 71
		},
		{
			v: 396,
			vw: 131.3198,
			o: 131.34,
			c: 131.34,
			h: 131.34,
			l: 131.34,
			t: 1655370480000,
			n: 24
		},
		{
			v: 2084,
			vw: 131.2674,
			o: 131.3,
			c: 131.21,
			h: 131.3,
			l: 131.21,
			t: 1655370540000,
			n: 39
		},
		{
			v: 565,
			vw: 131.4918,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655370780000,
			n: 20
		},
		{
			v: 395,
			vw: 131.5253,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655370840000,
			n: 21
		},
		{
			v: 683,
			vw: 131.6517,
			o: 131.67,
			c: 131.67,
			h: 131.67,
			l: 131.67,
			t: 1655370960000,
			n: 32
		},
		{
			v: 320,
			vw: 131.669,
			o: 131.67,
			c: 131.67,
			h: 131.67,
			l: 131.67,
			t: 1655371020000,
			n: 12
		},
		{
			v: 386,
			vw: 131.6731,
			o: 131.67,
			c: 131.67,
			h: 131.67,
			l: 131.67,
			t: 1655371080000,
			n: 19
		},
		{
			v: 3588,
			vw: 131.6493,
			o: 131.74,
			c: 131.61,
			h: 131.74,
			l: 131.61,
			t: 1655371140000,
			n: 46
		},
		{
			v: 6319,
			vw: 131.7119,
			o: 131.62,
			c: 131.81,
			h: 131.81,
			l: 131.6,
			t: 1655371200000,
			n: 102
		},
		{
			v: 2166,
			vw: 131.5182,
			o: 131.61,
			c: 131.5,
			h: 131.61,
			l: 131.5,
			t: 1655371260000,
			n: 99
		},
		{
			v: 1843,
			vw: 131.4439,
			o: 131.42,
			c: 131.39,
			h: 131.42,
			l: 131.39,
			t: 1655371320000,
			n: 58
		},
		{
			v: 414,
			vw: 131.6506,
			o: 131.61,
			c: 131.7,
			h: 131.7,
			l: 131.61,
			t: 1655371380000,
			n: 9
		},
		{
			v: 430,
			vw: 131.7095,
			o: 131.71,
			c: 131.7,
			h: 131.71,
			l: 131.7,
			t: 1655371440000,
			n: 12
		},
		{
			v: 704,
			vw: 131.739,
			o: 131.71,
			c: 131.71,
			h: 131.71,
			l: 131.71,
			t: 1655371620000,
			n: 26
		},
		{
			v: 287,
			vw: 131.8052,
			o: 131.81,
			c: 131.81,
			h: 131.81,
			l: 131.81,
			t: 1655371680000,
			n: 13
		},
		{
			v: 309,
			vw: 131.8253,
			o: 131.83,
			c: 131.83,
			h: 131.83,
			l: 131.83,
			t: 1655371740000,
			n: 15
		},
		{
			v: 938,
			vw: 131.6388,
			o: 131.64,
			c: 131.61,
			h: 131.71,
			l: 131.61,
			t: 1655371860000,
			n: 47
		},
		{
			v: 1932,
			vw: 131.5269,
			o: 131.53,
			c: 131.51,
			h: 131.55,
			l: 131.51,
			t: 1655371920000,
			n: 61
		},
		{
			v: 2174,
			vw: 131.4416,
			o: 131.45,
			c: 131.36,
			h: 131.46,
			l: 131.36,
			t: 1655371980000,
			n: 32
		},
		{
			v: 599,
			vw: 131.3862,
			o: 131.38,
			c: 131.38,
			h: 131.38,
			l: 131.38,
			t: 1655372040000,
			n: 23
		},
		{
			v: 599,
			vw: 131.3956,
			o: 131.38,
			c: 131.4,
			h: 131.4,
			l: 131.38,
			t: 1655372100000,
			n: 17
		},
		{
			v: 1346,
			vw: 131.3874,
			o: 131.39,
			c: 131.45,
			h: 131.45,
			l: 131.32,
			t: 1655372160000,
			n: 50
		},
		{
			v: 3333,
			vw: 131.4865,
			o: 131.45,
			c: 131.53,
			h: 131.53,
			l: 131.45,
			t: 1655372220000,
			n: 31
		},
		{
			v: 1305,
			vw: 131.5498,
			o: 131.55,
			c: 131.61,
			h: 131.61,
			l: 131.52,
			t: 1655372280000,
			n: 30
		},
		{
			v: 621,
			vw: 131.6553,
			o: 131.65,
			c: 131.7,
			h: 131.7,
			l: 131.65,
			t: 1655372340000,
			n: 23
		},
		{
			v: 2298,
			vw: 131.4745,
			o: 131.51,
			c: 131.41,
			h: 131.51,
			l: 131.41,
			t: 1655372460000,
			n: 45
		},
		{
			v: 1155,
			vw: 131.5007,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655372520000,
			n: 7
		},
		{
			v: 227,
			vw: 131.4957,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655372580000,
			n: 10
		},
		{
			v: 1027,
			vw: 131.4608,
			o: 131.46,
			c: 131.46,
			h: 131.46,
			l: 131.46,
			t: 1655372700000,
			n: 7
		},
		{
			v: 691,
			vw: 131.5557,
			o: 131.5,
			c: 131.55,
			h: 131.61,
			l: 131.5,
			t: 1655372760000,
			n: 11
		},
		{
			v: 1200,
			vw: 131.5897,
			o: 131.58,
			c: 131.6,
			h: 131.6,
			l: 131.58,
			t: 1655372820000,
			n: 16
		},
		{
			v: 597,
			vw: 131.6476,
			o: 131.62,
			c: 131.69,
			h: 131.69,
			l: 131.62,
			t: 1655372880000,
			n: 12
		},
		{
			v: 668,
			vw: 131.6542,
			o: 131.71,
			c: 131.61,
			h: 131.71,
			l: 131.61,
			t: 1655372940000,
			n: 19
		},
		{
			v: 273,
			vw: 131.6276,
			o: 131.64,
			c: 131.64,
			h: 131.64,
			l: 131.64,
			t: 1655373060000,
			n: 11
		},
		{
			v: 558,
			vw: 131.7404,
			o: 131.73,
			c: 131.73,
			h: 131.73,
			l: 131.73,
			t: 1655373180000,
			n: 19
		},
		{
			v: 150,
			vw: 131.7806,
			o: 131.78,
			c: 131.78,
			h: 131.78,
			l: 131.78,
			t: 1655373240000,
			n: 13
		},
		{
			v: 785,
			vw: 131.7619,
			o: 131.78,
			c: 131.73,
			h: 131.78,
			l: 131.73,
			t: 1655373300000,
			n: 28
		},
		{
			v: 773,
			vw: 131.7309,
			o: 131.69,
			c: 131.69,
			h: 131.69,
			l: 131.69,
			t: 1655373600000,
			n: 39
		},
		{
			v: 1153,
			vw: 131.68,
			o: 131.68,
			c: 131.68,
			h: 131.68,
			l: 131.68,
			t: 1655373660000,
			n: 23
		},
		{
			v: 4216,
			vw: 131.6808,
			o: 131.68,
			c: 131.68,
			h: 131.68,
			l: 131.67,
			t: 1655373720000,
			n: 36
		},
		{
			v: 1498,
			vw: 131.7986,
			o: 131.78,
			c: 131.83,
			h: 131.83,
			l: 131.78,
			t: 1655373780000,
			n: 20
		},
		{
			v: 1035,
			vw: 131.8802,
			o: 131.86,
			c: 131.9,
			h: 131.9,
			l: 131.86,
			t: 1655373840000,
			n: 24
		},
		{
			v: 1045,
			vw: 131.9043,
			o: 131.9,
			c: 131.9,
			h: 131.91,
			l: 131.9,
			t: 1655373900000,
			n: 19
		},
		{
			v: 457,
			vw: 131.8179,
			o: 131.81,
			c: 131.81,
			h: 131.81,
			l: 131.81,
			t: 1655373960000,
			n: 24
		},
		{
			v: 697,
			vw: 131.8263,
			o: 131.82,
			c: 131.84,
			h: 131.84,
			l: 131.82,
			t: 1655374020000,
			n: 25
		},
		{
			v: 383,
			vw: 131.842,
			o: 131.84,
			c: 131.84,
			h: 131.84,
			l: 131.84,
			t: 1655374080000,
			n: 17
		},
		{
			v: 638,
			vw: 131.9074,
			o: 131.91,
			c: 131.91,
			h: 131.91,
			l: 131.91,
			t: 1655374140000,
			n: 20
		},
		{
			v: 272,
			vw: 131.8769,
			o: 131.86,
			c: 131.86,
			h: 131.86,
			l: 131.86,
			t: 1655374260000,
			n: 22
		},
		{
			v: 2378,
			vw: 131.8395,
			o: 131.88,
			c: 131.82,
			h: 131.88,
			l: 131.82,
			t: 1655374320000,
			n: 38
		},
		{
			v: 772,
			vw: 131.8084,
			o: 131.8,
			c: 131.8,
			h: 131.8,
			l: 131.8,
			t: 1655374380000,
			n: 44
		},
		{
			v: 2217,
			vw: 131.7797,
			o: 131.78,
			c: 131.78,
			h: 131.78,
			l: 131.78,
			t: 1655374440000,
			n: 36
		},
		{
			v: 2818,
			vw: 131.6931,
			o: 131.77,
			c: 131.65,
			h: 131.77,
			l: 131.65,
			t: 1655374500000,
			n: 52
		},
		{
			v: 2673,
			vw: 131.6699,
			o: 131.6,
			c: 131.74,
			h: 131.74,
			l: 131.6,
			t: 1655374560000,
			n: 86
		},
		{
			v: 3012,
			vw: 131.6161,
			o: 131.7,
			c: 131.6,
			h: 131.7,
			l: 131.6,
			t: 1655374800000,
			n: 60
		},
		{
			v: 260,
			vw: 131.6077,
			o: 131.6,
			c: 131.6,
			h: 131.6,
			l: 131.6,
			t: 1655374860000,
			n: 4
		},
		{
			v: 1356,
			vw: 131.5525,
			o: 131.55,
			c: 131.56,
			h: 131.56,
			l: 131.54,
			t: 1655374920000,
			n: 45
		},
		{
			v: 2295,
			vw: 131.5085,
			o: 131.53,
			c: 131.48,
			h: 131.53,
			l: 131.48,
			t: 1655374980000,
			n: 99
		},
		{
			v: 487,
			vw: 131.4718,
			o: 131.48,
			c: 131.48,
			h: 131.48,
			l: 131.48,
			t: 1655375100000,
			n: 34
		},
		{
			v: 614,
			vw: 131.4935,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655375160000,
			n: 19
		},
		{
			v: 224,
			vw: 131.5035,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655375400000,
			n: 9
		},
		{
			v: 266,
			vw: 131.4639,
			o: 131.46,
			c: 131.46,
			h: 131.46,
			l: 131.46,
			t: 1655375460000,
			n: 12
		},
		{
			v: 258,
			vw: 131.462,
			o: 131.46,
			c: 131.46,
			h: 131.46,
			l: 131.46,
			t: 1655375520000,
			n: 7
		},
		{
			v: 488,
			vw: 131.4591,
			o: 131.46,
			c: 131.45,
			h: 131.46,
			l: 131.45,
			t: 1655375580000,
			n: 18
		},
		{
			v: 5330,
			vw: 131.3995,
			o: 131.41,
			c: 131.35,
			h: 131.41,
			l: 131.35,
			t: 1655375640000,
			n: 104
		},
		{
			v: 1473,
			vw: 131.3535,
			o: 131.35,
			c: 131.35,
			h: 131.36,
			l: 131.35,
			t: 1655375700000,
			n: 48
		},
		{
			v: 1244,
			vw: 131.3495,
			o: 131.35,
			c: 131.32,
			h: 131.35,
			l: 131.32,
			t: 1655375760000,
			n: 29
		},
		{
			v: 218,
			vw: 131.3998,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655375820000,
			n: 9
		},
		{
			v: 338,
			vw: 131.3302,
			o: 131.34,
			c: 131.32,
			h: 131.34,
			l: 131.32,
			t: 1655375940000,
			n: 12
		},
		{
			v: 2533,
			vw: 131.3687,
			o: 131.32,
			c: 131.4,
			h: 131.4,
			l: 131.3,
			t: 1655376000000,
			n: 64
		},
		{
			v: 2792,
			vw: 131.2946,
			o: 131.31,
			c: 131.3,
			h: 131.35,
			l: 131.25,
			t: 1655376060000,
			n: 95
		},
		{
			v: 600,
			vw: 131.3538,
			o: 131.35,
			c: 131.35,
			h: 131.35,
			l: 131.35,
			t: 1655376180000,
			n: 10
		},
		{
			v: 1555,
			vw: 131.3986,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655376240000,
			n: 35
		},
		{
			v: 8529,
			vw: 131.4823,
			o: 131.4,
			c: 131.54,
			h: 131.54,
			l: 131.4,
			t: 1655376300000,
			n: 148
		},
		{
			v: 5949,
			vw: 131.4152,
			o: 131.41,
			c: 131.5,
			h: 131.5,
			l: 131.4,
			t: 1655376480000,
			n: 66
		},
		{
			v: 4401,
			vw: 131.5751,
			o: 131.55,
			c: 131.58,
			h: 131.58,
			l: 131.55,
			t: 1655376540000,
			n: 51
		},
		{
			v: 1447,
			vw: 131.5325,
			o: 131.6,
			c: 131.45,
			h: 131.6,
			l: 131.45,
			t: 1655376600000,
			n: 33
		},
		{
			v: 2404,
			vw: 131.3759,
			o: 131.36,
			c: 131.32,
			h: 131.44,
			l: 131.31,
			t: 1655376660000,
			n: 63
		},
		{
			v: 2373,
			vw: 131.3502,
			o: 131.37,
			c: 131.3,
			h: 131.43,
			l: 131.3,
			t: 1655376720000,
			n: 36
		},
		{
			v: 1587,
			vw: 131.5284,
			o: 131.54,
			c: 131.51,
			h: 131.6,
			l: 131.51,
			t: 1655376840000,
			n: 30
		},
		{
			v: 3454,
			vw: 131.4948,
			o: 131.59,
			c: 131.47,
			h: 131.6,
			l: 131.36,
			t: 1655376900000,
			n: 60
		},
		{
			v: 954,
			vw: 131.6383,
			o: 131.64,
			c: 131.64,
			h: 131.64,
			l: 131.64,
			t: 1655377020000,
			n: 13
		},
		{
			v: 2357,
			vw: 131.7268,
			o: 131.7,
			c: 131.7,
			h: 131.79,
			l: 131.7,
			t: 1655377080000,
			n: 41
		},
		{
			v: 5591,
			vw: 131.6272,
			o: 131.6,
			c: 131.63,
			h: 131.63,
			l: 131.6,
			t: 1655377140000,
			n: 81
		},
		{
			v: 32913,
			vw: 131.7837,
			o: 131.63,
			c: 131.83,
			h: 131.97,
			l: 131.63,
			t: 1655377200000,
			n: 593
		},
		{
			v: 5826,
			vw: 131.8229,
			o: 131.83,
			c: 131.85,
			h: 131.85,
			l: 131.79,
			t: 1655377260000,
			n: 61
		},
		{
			v: 7138,
			vw: 131.9273,
			o: 131.9,
			c: 131.9,
			h: 131.96,
			l: 131.88,
			t: 1655377320000,
			n: 86
		},
		{
			v: 7733,
			vw: 131.9111,
			o: 131.9,
			c: 131.85,
			h: 131.94,
			l: 131.85,
			t: 1655377380000,
			n: 97
		},
		{
			v: 10527,
			vw: 131.8477,
			o: 131.85,
			c: 131.87,
			h: 131.9,
			l: 131.8,
			t: 1655377440000,
			n: 97
		},
		{
			v: 2710,
			vw: 131.9118,
			o: 131.88,
			c: 131.92,
			h: 131.96,
			l: 131.88,
			t: 1655377500000,
			n: 50
		},
		{
			v: 7005,
			vw: 131.9875,
			o: 131.97,
			c: 132,
			h: 132,
			l: 131.96,
			t: 1655377560000,
			n: 90
		},
		{
			v: 8507,
			vw: 132.0016,
			o: 132.02,
			c: 131.93,
			h: 132.05,
			l: 131.93,
			t: 1655377620000,
			n: 89
		},
		{
			v: 3530,
			vw: 131.9129,
			o: 131.96,
			c: 131.92,
			h: 131.96,
			l: 131.87,
			t: 1655377680000,
			n: 56
		},
		{
			v: 2328,
			vw: 131.935,
			o: 131.9,
			c: 131.95,
			h: 131.97,
			l: 131.9,
			t: 1655377740000,
			n: 34
		},
		{
			v: 5046,
			vw: 131.9191,
			o: 131.94,
			c: 131.88,
			h: 131.97,
			l: 131.87,
			t: 1655377800000,
			n: 79
		},
		{
			v: 3878,
			vw: 131.9262,
			o: 131.92,
			c: 131.9,
			h: 131.95,
			l: 131.9,
			t: 1655377860000,
			n: 52
		},
		{
			v: 7572,
			vw: 131.837,
			o: 131.88,
			c: 131.82,
			h: 131.88,
			l: 131.81,
			t: 1655377920000,
			n: 82
		},
		{
			v: 10712,
			vw: 131.8076,
			o: 131.82,
			c: 131.85,
			h: 131.85,
			l: 131.8,
			t: 1655377980000,
			n: 73
		},
		{
			v: 5366,
			vw: 131.8106,
			o: 131.81,
			c: 131.85,
			h: 131.85,
			l: 131.78,
			t: 1655378040000,
			n: 63
		},
		{
			v: 11090,
			vw: 131.8195,
			o: 131.8,
			c: 131.75,
			h: 131.88,
			l: 131.75,
			t: 1655378100000,
			n: 119
		},
		{
			v: 6471,
			vw: 131.7561,
			o: 131.75,
			c: 131.75,
			h: 131.78,
			l: 131.75,
			t: 1655378160000,
			n: 59
		},
		{
			v: 7561,
			vw: 131.7443,
			o: 131.75,
			c: 131.73,
			h: 131.79,
			l: 131.73,
			t: 1655378220000,
			n: 94
		},
		{
			v: 3234,
			vw: 131.7995,
			o: 131.74,
			c: 131.8,
			h: 131.85,
			l: 131.74,
			t: 1655378280000,
			n: 46
		},
		{
			v: 1125,
			vw: 131.8044,
			o: 131.8,
			c: 131.83,
			h: 131.83,
			l: 131.8,
			t: 1655378340000,
			n: 28
		},
		{
			v: 6393,
			vw: 131.7935,
			o: 131.8,
			c: 131.75,
			h: 131.83,
			l: 131.75,
			t: 1655378400000,
			n: 122
		},
		{
			v: 4195,
			vw: 131.7809,
			o: 131.75,
			c: 131.77,
			h: 131.82,
			l: 131.73,
			t: 1655378460000,
			n: 75
		},
		{
			v: 3567,
			vw: 131.7601,
			o: 131.77,
			c: 131.79,
			h: 131.79,
			l: 131.73,
			t: 1655378520000,
			n: 73
		},
		{
			v: 1911,
			vw: 131.8433,
			o: 131.82,
			c: 131.83,
			h: 131.88,
			l: 131.82,
			t: 1655378580000,
			n: 33
		},
		{
			v: 1268,
			vw: 131.8415,
			o: 131.81,
			c: 131.87,
			h: 131.87,
			l: 131.81,
			t: 1655378640000,
			n: 18
		},
		{
			v: 1083,
			vw: 131.8378,
			o: 131.87,
			c: 131.8,
			h: 131.87,
			l: 131.8,
			t: 1655378700000,
			n: 28
		},
		{
			v: 4374,
			vw: 131.9443,
			o: 131.8,
			c: 132,
			h: 132,
			l: 131.8,
			t: 1655378760000,
			n: 88
		},
		{
			v: 5633,
			vw: 132.2018,
			o: 132.07,
			c: 132.25,
			h: 132.28,
			l: 132.07,
			t: 1655378820000,
			n: 66
		},
		{
			v: 3619,
			vw: 132.2412,
			o: 132.25,
			c: 132.25,
			h: 132.27,
			l: 132.22,
			t: 1655378880000,
			n: 60
		},
		{
			v: 1487,
			vw: 132.1234,
			o: 132.1,
			c: 132.09,
			h: 132.11,
			l: 132.09,
			t: 1655378940000,
			n: 34
		},
		{
			v: 4481,
			vw: 132.0555,
			o: 132.11,
			c: 132.06,
			h: 132.11,
			l: 132.03,
			t: 1655379000000,
			n: 51
		},
		{
			v: 3848,
			vw: 132.0873,
			o: 132.08,
			c: 132.09,
			h: 132.1,
			l: 132.08,
			t: 1655379060000,
			n: 28
		},
		{
			v: 11005,
			vw: 132.0328,
			o: 132.11,
			c: 131.85,
			h: 132.11,
			l: 131.85,
			t: 1655379120000,
			n: 132
		},
		{
			v: 2756,
			vw: 131.9987,
			o: 131.9,
			c: 132.03,
			h: 132.03,
			l: 131.9,
			t: 1655379180000,
			n: 52
		},
		{
			v: 3864,
			vw: 132.0288,
			o: 131.98,
			c: 132.07,
			h: 132.07,
			l: 131.98,
			t: 1655379240000,
			n: 57
		},
		{
			v: 6642,
			vw: 132.0663,
			o: 132.07,
			c: 132.07,
			h: 132.07,
			l: 132.05,
			t: 1655379300000,
			n: 44
		},
		{
			v: 12860,
			vw: 132.0935,
			o: 132.065,
			c: 132.09,
			h: 132.1,
			l: 132.065,
			t: 1655379360000,
			n: 94
		},
		{
			v: 2866,
			vw: 132.1413,
			o: 132.12,
			c: 132.15,
			h: 132.15,
			l: 132.11,
			t: 1655379420000,
			n: 46
		},
		{
			v: 1374,
			vw: 132.1572,
			o: 132.15,
			c: 132.15,
			h: 132.18,
			l: 132.15,
			t: 1655379480000,
			n: 26
		},
		{
			v: 4619,
			vw: 132.1103,
			o: 132.15,
			c: 132.08,
			h: 132.15,
			l: 132.08,
			t: 1655379540000,
			n: 44
		},
		{
			v: 3415,
			vw: 132.0736,
			o: 132.07,
			c: 132.11,
			h: 132.11,
			l: 132.05,
			t: 1655379600000,
			n: 60
		},
		{
			v: 3401,
			vw: 132.0426,
			o: 132.09,
			c: 132.05,
			h: 132.09,
			l: 132.02,
			t: 1655379660000,
			n: 109
		},
		{
			v: 2890,
			vw: 132.0178,
			o: 132.06,
			c: 131.99,
			h: 132.06,
			l: 131.98,
			t: 1655379720000,
			n: 50
		},
		{
			v: 3630,
			vw: 131.9097,
			o: 131.95,
			c: 131.91,
			h: 131.95,
			l: 131.895,
			t: 1655379780000,
			n: 69
		},
		{
			v: 6719,
			vw: 131.9517,
			o: 131.89,
			c: 131.98,
			h: 131.99,
			l: 131.89,
			t: 1655379840000,
			n: 95
		},
		{
			v: 21455,
			vw: 131.8478,
			o: 131.99,
			c: 131.72,
			h: 132,
			l: 131.72,
			t: 1655379900000,
			n: 317
		},
		{
			v: 7300,
			vw: 131.7015,
			o: 131.76,
			c: 131.76,
			h: 131.76,
			l: 131.65,
			t: 1655379960000,
			n: 165
		},
		{
			v: 3094,
			vw: 131.7641,
			o: 131.73,
			c: 131.75,
			h: 131.83,
			l: 131.73,
			t: 1655380020000,
			n: 59
		},
		{
			v: 3817,
			vw: 131.7235,
			o: 131.73,
			c: 131.71,
			h: 131.74,
			l: 131.71,
			t: 1655380080000,
			n: 89
		},
		{
			v: 1662,
			vw: 131.7548,
			o: 131.74,
			c: 131.76,
			h: 131.78,
			l: 131.74,
			t: 1655380140000,
			n: 36
		},
		{
			v: 3718,
			vw: 131.725,
			o: 131.75,
			c: 131.72,
			h: 131.75,
			l: 131.7,
			t: 1655380200000,
			n: 112
		},
		{
			v: 4824,
			vw: 131.826,
			o: 131.82,
			c: 131.82,
			h: 131.85,
			l: 131.82,
			t: 1655380260000,
			n: 56
		},
		{
			v: 1160,
			vw: 131.8052,
			o: 131.8,
			c: 131.83,
			h: 131.83,
			l: 131.78,
			t: 1655380320000,
			n: 29
		},
		{
			v: 3485,
			vw: 131.868,
			o: 131.87,
			c: 131.89,
			h: 131.9,
			l: 131.84,
			t: 1655380380000,
			n: 58
		},
		{
			v: 1374,
			vw: 131.8709,
			o: 131.87,
			c: 131.87,
			h: 131.87,
			l: 131.87,
			t: 1655380440000,
			n: 31
		},
		{
			v: 2324,
			vw: 131.8689,
			o: 131.9,
			c: 131.87,
			h: 131.9,
			l: 131.86,
			t: 1655380500000,
			n: 54
		},
		{
			v: 7705,
			vw: 131.9964,
			o: 131.92,
			c: 131.98,
			h: 132.05,
			l: 131.92,
			t: 1655380560000,
			n: 117
		},
		{
			v: 17220,
			vw: 132.2285,
			o: 132.05,
			c: 132.34,
			h: 132.34,
			l: 132.05,
			t: 1655380620000,
			n: 161
		},
		{
			v: 797,
			vw: 132.2841,
			o: 132.31,
			c: 132.31,
			h: 132.31,
			l: 132.31,
			t: 1655380680000,
			n: 23
		},
		{
			v: 12883,
			vw: 132.2575,
			o: 132.225,
			c: 132.3,
			h: 132.3,
			l: 132.225,
			t: 1655380740000,
			n: 106
		},
		{
			v: 300734,
			vw: 131.9449,
			o: 131.8399,
			c: 132.32,
			h: 132.5388,
			l: 131.65,
			t: 1655380800000,
			n: 1885
		},
		{
			v: 16860,
			vw: 133.2292,
			o: 132.3,
			c: 132.25,
			h: 134.75,
			l: 132.25,
			t: 1655380860000,
			n: 446
		},
		{
			v: 40211,
			vw: 132.326,
			o: 132.2999,
			c: 132.48,
			h: 132.48,
			l: 132.25,
			t: 1655380920000,
			n: 191
		},
		{
			v: 21805,
			vw: 132.4742,
			o: 132.5,
			c: 132.53,
			h: 132.55,
			l: 132.45,
			t: 1655380980000,
			n: 194
		},
		{
			v: 19488,
			vw: 132.44,
			o: 132.52,
			c: 132.37,
			h: 132.66,
			l: 132.3263,
			t: 1655381040000,
			n: 171
		},
		{
			v: 11684,
			vw: 132.3831,
			o: 132.35,
			c: 132.37,
			h: 132.4,
			l: 132.34,
			t: 1655381100000,
			n: 130
		},
		{
			v: 2338,
			vw: 132.3325,
			o: 132.32,
			c: 132.32,
			h: 132.3684,
			l: 132.32,
			t: 1655381160000,
			n: 58
		},
		{
			v: 32829,
			vw: 132.4727,
			o: 132.35,
			c: 132.54,
			h: 132.54,
			l: 132.35,
			t: 1655381220000,
			n: 256
		},
		{
			v: 9063,
			vw: 132.5819,
			o: 132.5299,
			c: 132.6,
			h: 132.6,
			l: 132.5,
			t: 1655381280000,
			n: 82
		},
		{
			v: 38419,
			vw: 132.5927,
			o: 132.6,
			c: 132.64,
			h: 132.64,
			l: 132.5401,
			t: 1655381340000,
			n: 249
		},
		{
			v: 17364,
			vw: 132.6814,
			o: 132.64,
			c: 132.65,
			h: 132.77,
			l: 132.64,
			t: 1655381400000,
			n: 150
		},
		{
			v: 43260,
			vw: 132.7124,
			o: 132.67,
			c: 133,
			h: 133,
			l: 132.65,
			t: 1655381460000,
			n: 248
		},
		{
			v: 8108,
			vw: 132.8082,
			o: 132.97,
			c: 132.75,
			h: 132.98,
			l: 132.67,
			t: 1655381520000,
			n: 150
		},
		{
			v: 18668,
			vw: 132.7654,
			o: 132.74,
			c: 132.65,
			h: 132.98,
			l: 132.65,
			t: 1655381580000,
			n: 192
		},
		{
			v: 5942,
			vw: 132.822,
			o: 132.7899,
			c: 132.93,
			h: 132.96,
			l: 132.71,
			t: 1655381640000,
			n: 119
		},
		{
			v: 6900,
			vw: 132.8701,
			o: 132.94,
			c: 132.93,
			h: 132.94,
			l: 132.81,
			t: 1655381700000,
			n: 143
		},
		{
			v: 7355,
			vw: 132.9288,
			o: 132.9199,
			c: 132.98,
			h: 132.98,
			l: 132.85,
			t: 1655381760000,
			n: 94
		},
		{
			v: 13681,
			vw: 132.7416,
			o: 132.87,
			c: 132.7,
			h: 132.88,
			l: 132.69,
			t: 1655381820000,
			n: 100
		},
		{
			v: 38646,
			vw: 132.6638,
			o: 132.69,
			c: 132.65,
			h: 132.74,
			l: 132.65,
			t: 1655381880000,
			n: 185
		},
		{
			v: 9817,
			vw: 132.6166,
			o: 132.65,
			c: 132.52,
			h: 132.67,
			l: 132.52,
			t: 1655381940000,
			n: 134
		},
		{
			v: 5322,
			vw: 132.5698,
			o: 132.5301,
			c: 132.5601,
			h: 132.59,
			l: 132.5301,
			t: 1655382000000,
			n: 91
		},
		{
			v: 11425,
			vw: 132.7639,
			o: 132.63,
			c: 132.69,
			h: 132.88,
			l: 132.63,
			t: 1655382060000,
			n: 219
		},
		{
			v: 26040,
			vw: 132.8764,
			o: 132.75,
			c: 132.9,
			h: 132.95,
			l: 132.74,
			t: 1655382120000,
			n: 174
		},
		{
			v: 8996,
			vw: 132.9422,
			o: 132.9,
			c: 132.97,
			h: 132.99,
			l: 132.9,
			t: 1655382180000,
			n: 125
		},
		{
			v: 3501,
			vw: 132.9482,
			o: 132.94,
			c: 132.95,
			h: 132.99,
			l: 132.9,
			t: 1655382240000,
			n: 99
		},
		{
			v: 17474,
			vw: 132.9608,
			o: 132.91,
			c: 132.94,
			h: 133,
			l: 132.9,
			t: 1655382300000,
			n: 277
		},
		{
			v: 25698,
			vw: 132.9035,
			o: 132.93,
			c: 132.84,
			h: 132.94,
			l: 132.84,
			t: 1655382360000,
			n: 255
		},
		{
			v: 30579,
			vw: 132.9205,
			o: 132.88,
			c: 132.98,
			h: 133,
			l: 132.8,
			t: 1655382420000,
			n: 275
		},
		{
			v: 3431,
			vw: 132.9711,
			o: 132.97,
			c: 132.98,
			h: 132.98,
			l: 132.96,
			t: 1655382480000,
			n: 59
		},
		{
			v: 2644,
			vw: 132.866,
			o: 132.93,
			c: 132.89,
			h: 132.93,
			l: 132.8,
			t: 1655382540000,
			n: 88
		},
		{
			v: 16950,
			vw: 132.8936,
			o: 132.75,
			c: 132.92,
			h: 133,
			l: 132.65,
			t: 1655382600000,
			n: 276
		},
		{
			v: 15025,
			vw: 132.9085,
			o: 132.81,
			c: 132.9799,
			h: 133,
			l: 132.67,
			t: 1655382660000,
			n: 248
		},
		{
			v: 68861,
			vw: 133.0062,
			o: 132.97,
			c: 133.05,
			h: 133.1,
			l: 132.87,
			t: 1655382720000,
			n: 576
		},
		{
			v: 26234,
			vw: 133.0395,
			o: 133.05,
			c: 133,
			h: 133.0501,
			l: 132.93,
			t: 1655382780000,
			n: 159
		},
		{
			v: 4692,
			vw: 133.0979,
			o: 133,
			c: 133.1,
			h: 133.16,
			l: 133,
			t: 1655382840000,
			n: 104
		},
		{
			v: 18380,
			vw: 133.1497,
			o: 133.1,
			c: 133.2,
			h: 133.3,
			l: 133,
			t: 1655382900000,
			n: 323
		},
		{
			v: 13224,
			vw: 133.3302,
			o: 133.29,
			c: 133.35,
			h: 133.39,
			l: 133.28,
			t: 1655382960000,
			n: 222
		},
		{
			v: 15983,
			vw: 133.3197,
			o: 133.32,
			c: 133.43,
			h: 133.47,
			l: 133.21,
			t: 1655383020000,
			n: 260
		},
		{
			v: 19258,
			vw: 133.3916,
			o: 133.38,
			c: 133.3,
			h: 133.48,
			l: 133.3,
			t: 1655383080000,
			n: 277
		},
		{
			v: 4670,
			vw: 133.1847,
			o: 133.28,
			c: 133.1601,
			h: 133.28,
			l: 133.13,
			t: 1655383140000,
			n: 110
		},
		{
			v: 3787,
			vw: 133.1399,
			o: 133.2,
			c: 133.15,
			h: 133.2,
			l: 133.11,
			t: 1655383200000,
			n: 101
		},
		{
			v: 7846,
			vw: 133.0708,
			o: 133.11,
			c: 133.11,
			h: 133.12,
			l: 133.01,
			t: 1655383260000,
			n: 142
		},
		{
			v: 20879,
			vw: 133.0261,
			o: 133.15,
			c: 133.01,
			h: 133.17,
			l: 132.94,
			t: 1655383320000,
			n: 180
		},
		{
			v: 40636,
			vw: 132.9557,
			o: 133.01,
			c: 133,
			h: 133.01,
			l: 132.88,
			t: 1655383380000,
			n: 413
		},
		{
			v: 15594,
			vw: 132.8914,
			o: 133,
			c: 132.8799,
			h: 133.01,
			l: 132.8,
			t: 1655383440000,
			n: 165
		},
		{
			v: 19326,
			vw: 132.9085,
			o: 132.89,
			c: 132.96,
			h: 132.9996,
			l: 132.89,
			t: 1655383500000,
			n: 90
		},
		{
			v: 6906,
			vw: 132.826,
			o: 132.9599,
			c: 132.7899,
			h: 132.96,
			l: 132.76,
			t: 1655383560000,
			n: 135
		},
		{
			v: 20040,
			vw: 132.6987,
			o: 132.75,
			c: 132.69,
			h: 132.75,
			l: 132.65,
			t: 1655383620000,
			n: 166
		},
		{
			v: 8837,
			vw: 132.7052,
			o: 132.67,
			c: 132.7,
			h: 132.7401,
			l: 132.65,
			t: 1655383680000,
			n: 109
		},
		{
			v: 2560,
			vw: 132.7481,
			o: 132.7,
			c: 132.76,
			h: 132.7699,
			l: 132.7,
			t: 1655383740000,
			n: 73
		},
		{
			v: 14665,
			vw: 132.9106,
			o: 132.88,
			c: 132.89,
			h: 132.97,
			l: 132.88,
			t: 1655383800000,
			n: 185
		},
		{
			v: 6594,
			vw: 132.8029,
			o: 132.8201,
			c: 132.7699,
			h: 132.83,
			l: 132.74,
			t: 1655383860000,
			n: 115
		},
		{
			v: 13888,
			vw: 132.7982,
			o: 132.73,
			c: 132.84,
			h: 132.87,
			l: 132.7,
			t: 1655383920000,
			n: 207
		},
		{
			v: 5167,
			vw: 132.8218,
			o: 132.84,
			c: 132.8,
			h: 132.8501,
			l: 132.72,
			t: 1655383980000,
			n: 104
		},
		{
			v: 3616,
			vw: 132.8142,
			o: 132.8,
			c: 132.79,
			h: 132.85,
			l: 132.79,
			t: 1655384040000,
			n: 69
		},
		{
			v: 3253,
			vw: 132.7761,
			o: 132.75,
			c: 132.76,
			h: 132.79,
			l: 132.75,
			t: 1655384100000,
			n: 107
		},
		{
			v: 10616,
			vw: 132.7356,
			o: 132.72,
			c: 132.8,
			h: 132.8,
			l: 132.7,
			t: 1655384160000,
			n: 144
		},
		{
			v: 57756,
			vw: 132.6162,
			o: 132.7401,
			c: 132.52,
			h: 132.7401,
			l: 132.51,
			t: 1655384220000,
			n: 477
		},
		{
			v: 9960,
			vw: 132.5539,
			o: 132.55,
			c: 132.56,
			h: 132.61,
			l: 132.51,
			t: 1655384280000,
			n: 170
		},
		{
			v: 4608,
			vw: 132.5967,
			o: 132.55,
			c: 132.63,
			h: 132.68,
			l: 132.53,
			t: 1655384340000,
			n: 97
		},
		{
			v: 10377,
			vw: 132.6195,
			o: 132.63,
			c: 132.5899,
			h: 132.65,
			l: 132.5899,
			t: 1655384400000,
			n: 122
		},
		{
			v: 58760,
			vw: 132.5006,
			o: 132.52,
			c: 132.46,
			h: 132.54,
			l: 132.41,
			t: 1655384460000,
			n: 350
		},
		{
			v: 85977,
			vw: 132.515,
			o: 132.4201,
			c: 132.62,
			h: 132.62,
			l: 132.4201,
			t: 1655384520000,
			n: 564
		},
		{
			v: 38678,
			vw: 132.5385,
			o: 132.68,
			c: 132.52,
			h: 132.68,
			l: 132.4851,
			t: 1655384580000,
			n: 502
		},
		{
			v: 5531,
			vw: 132.467,
			o: 132.53,
			c: 132.4,
			h: 132.53,
			l: 132.4,
			t: 1655384640000,
			n: 143
		},
		{
			v: 28359,
			vw: 132.3876,
			o: 132.35,
			c: 132.26,
			h: 132.48,
			l: 132.26,
			t: 1655384700000,
			n: 222
		},
		{
			v: 36164,
			vw: 132.2561,
			o: 132.29,
			c: 132.25,
			h: 132.29,
			l: 132.2401,
			t: 1655384760000,
			n: 217
		},
		{
			v: 18206,
			vw: 132.2111,
			o: 132.29,
			c: 132.15,
			h: 132.32,
			l: 132.14,
			t: 1655384820000,
			n: 299
		},
		{
			v: 10307,
			vw: 132.2236,
			o: 132.19,
			c: 132.3,
			h: 132.3197,
			l: 132.19,
			t: 1655384880000,
			n: 129
		},
		{
			v: 11966,
			vw: 132.3987,
			o: 132.3,
			c: 132.4299,
			h: 132.53,
			l: 132.29,
			t: 1655384940000,
			n: 182
		},
		{
			v: 12131,
			vw: 132.4769,
			o: 132.43,
			c: 132.5,
			h: 132.6295,
			l: 132.41,
			t: 1655385000000,
			n: 158
		},
		{
			v: 12786,
			vw: 132.4316,
			o: 132.45,
			c: 132.32,
			h: 132.48,
			l: 132.32,
			t: 1655385060000,
			n: 110
		},
		{
			v: 9817,
			vw: 132.2965,
			o: 132.37,
			c: 132.19,
			h: 132.38,
			l: 132.19,
			t: 1655385120000,
			n: 212
		},
		{
			v: 15785,
			vw: 132.2725,
			o: 132.2,
			c: 132.34,
			h: 132.34,
			l: 132.2,
			t: 1655385180000,
			n: 119
		},
		{
			v: 9725,
			vw: 132.3238,
			o: 132.34,
			c: 132.33,
			h: 132.35,
			l: 132.2705,
			t: 1655385240000,
			n: 136
		},
		{
			v: 12899,
			vw: 132.2741,
			o: 132.3301,
			c: 132.27,
			h: 132.3301,
			l: 132.2201,
			t: 1655385300000,
			n: 116
		},
		{
			v: 19334,
			vw: 132.2155,
			o: 132.29,
			c: 132.1899,
			h: 132.29,
			l: 132.15,
			t: 1655385360000,
			n: 242
		},
		{
			v: 8047,
			vw: 132.2833,
			o: 132.2,
			c: 132.29,
			h: 132.3,
			l: 132.2,
			t: 1655385420000,
			n: 126
		},
		{
			v: 20258,
			vw: 132.3321,
			o: 132.29,
			c: 132.42,
			h: 132.49,
			l: 132.24,
			t: 1655385480000,
			n: 263
		},
		{
			v: 12188,
			vw: 132.4209,
			o: 132.38,
			c: 132.38,
			h: 132.48,
			l: 132.38,
			t: 1655385540000,
			n: 183
		},
		{
			v: 8468,
			vw: 132.3887,
			o: 132.39,
			c: 132.3799,
			h: 132.43,
			l: 132.36,
			t: 1655385600000,
			n: 119
		},
		{
			v: 5356,
			vw: 132.3149,
			o: 132.34,
			c: 132.24,
			h: 132.37,
			l: 132.24,
			t: 1655385660000,
			n: 121
		},
		{
			v: 34069,
			vw: 132.2868,
			o: 132.25,
			c: 132.32,
			h: 132.33,
			l: 132.24,
			t: 1655385720000,
			n: 143
		},
		{
			v: 3923,
			vw: 132.3049,
			o: 132.33,
			c: 132.28,
			h: 132.33,
			l: 132.28,
			t: 1655385780000,
			n: 71
		},
		{
			v: 43802,
			vw: 132.1994,
			o: 132.2899,
			c: 132.18,
			h: 132.2899,
			l: 132.14,
			t: 1655385840000,
			n: 255
		},
		{
			v: 57614,
			vw: 131.9968,
			o: 132.16,
			c: 132,
			h: 132.16,
			l: 131.8,
			t: 1655385900000,
			n: 852
		},
		{
			v: 16299,
			vw: 131.973,
			o: 131.95,
			c: 131.98,
			h: 132,
			l: 131.95,
			t: 1655385960000,
			n: 185
		},
		{
			v: 61097,
			vw: 131.9646,
			o: 131.98,
			c: 132.07,
			h: 132.09,
			l: 131.8,
			t: 1655386020000,
			n: 515
		},
		{
			v: 24140,
			vw: 132.0195,
			o: 132.07,
			c: 132,
			h: 132.139,
			l: 131.93,
			t: 1655386080000,
			n: 212
		},
		{
			v: 14828,
			vw: 132.0411,
			o: 132,
			c: 132.08,
			h: 132.08,
			l: 131.99,
			t: 1655386140000,
			n: 148
		},
		{
			v: 2195257,
			vw: 131.912,
			o: 132.08,
			c: 132.02,
			h: 132.08,
			l: 131.59,
			t: 1655386200000,
			n: 17733
		},
		{
			v: 566812,
			vw: 132.1964,
			o: 132.03,
			c: 132.14,
			h: 132.39,
			l: 132.02,
			t: 1655386260000,
			n: 5348
		},
		{
			v: 472861,
			vw: 132.0299,
			o: 132.135,
			c: 132.185,
			h: 132.185,
			l: 131.83,
			t: 1655386320000,
			n: 4381
		},
		{
			v: 502940,
			vw: 131.7217,
			o: 132.14,
			c: 131.525,
			h: 132.19,
			l: 131.51,
			t: 1655386380000,
			n: 4487
		},
		{
			v: 555900,
			vw: 131.3104,
			o: 131.5178,
			c: 131.19,
			h: 131.61,
			l: 131.11,
			t: 1655386440000,
			n: 5949
		},
		{
			v: 483361,
			vw: 131.1575,
			o: 131.19,
			c: 131.34,
			h: 131.349,
			l: 131.01,
			t: 1655386500000,
			n: 4790
		},
		{
			v: 390836,
			vw: 131.4086,
			o: 131.33,
			c: 131.48,
			h: 131.55,
			l: 131.23,
			t: 1655386560000,
			n: 4225
		},
		{
			v: 315199,
			vw: 131.2785,
			o: 131.45,
			c: 131.2,
			h: 131.465,
			l: 131.14,
			t: 1655386620000,
			n: 3537
		},
		{
			v: 851375,
			vw: 131.0114,
			o: 131.195,
			c: 130.85,
			h: 131.21,
			l: 130.83,
			t: 1655386680000,
			n: 9177
		},
		{
			v: 522385,
			vw: 130.8027,
			o: 130.85,
			c: 130.855,
			h: 130.935,
			l: 130.61,
			t: 1655386740000,
			n: 5337
		},
		{
			v: 317274,
			vw: 130.7851,
			o: 130.85,
			c: 130.78,
			h: 130.88,
			l: 130.7,
			t: 1655386800000,
			n: 3444
		},
		{
			v: 442591,
			vw: 130.6543,
			o: 130.795,
			c: 130.6,
			h: 130.8599,
			l: 130.5,
			t: 1655386860000,
			n: 4920
		},
		{
			v: 432905,
			vw: 130.772,
			o: 130.6,
			c: 130.705,
			h: 130.935,
			l: 130.59,
			t: 1655386920000,
			n: 4197
		},
		{
			v: 399873,
			vw: 130.8621,
			o: 130.7,
			c: 130.855,
			h: 130.97,
			l: 130.7,
			t: 1655386980000,
			n: 4057
		},
		{
			v: 358101,
			vw: 130.8189,
			o: 130.85,
			c: 130.9318,
			h: 131.02,
			l: 130.68,
			t: 1655387040000,
			n: 3695
		},
		{
			v: 468213,
			vw: 131.2158,
			o: 130.94,
			c: 131.26,
			h: 131.45,
			l: 130.81,
			t: 1655387100000,
			n: 4465
		},
		{
			v: 372325,
			vw: 131.3935,
			o: 131.27,
			c: 131.61,
			h: 131.7,
			l: 131.16,
			t: 1655387160000,
			n: 3685
		},
		{
			v: 375012,
			vw: 131.6289,
			o: 131.6001,
			c: 131.4899,
			h: 131.7799,
			l: 131.44,
			t: 1655387220000,
			n: 3551
		},
		{
			v: 357679,
			vw: 131.6214,
			o: 131.49,
			c: 131.73,
			h: 131.77,
			l: 131.44,
			t: 1655387280000,
			n: 3326
		},
		{
			v: 301665,
			vw: 131.6158,
			o: 131.74,
			c: 131.51,
			h: 131.77,
			l: 131.44,
			t: 1655387340000,
			n: 2820
		},
		{
			v: 389619,
			vw: 131.4314,
			o: 131.51,
			c: 131.23,
			h: 131.61,
			l: 131.23,
			t: 1655387400000,
			n: 3538
		},
		{
			v: 281902,
			vw: 131.1813,
			o: 131.24,
			c: 131.0989,
			h: 131.28,
			l: 131.075,
			t: 1655387460000,
			n: 2698
		},
		{
			v: 217805,
			vw: 131.1466,
			o: 131.09,
			c: 131.165,
			h: 131.25,
			l: 131.06,
			t: 1655387520000,
			n: 2242
		},
		{
			v: 244570,
			vw: 131.0986,
			o: 131.16,
			c: 131.02,
			h: 131.21,
			l: 130.98,
			t: 1655387580000,
			n: 2802
		},
		{
			v: 263118,
			vw: 131.015,
			o: 131.01,
			c: 131.1208,
			h: 131.13,
			l: 130.9,
			t: 1655387640000,
			n: 2811
		},
		{
			v: 276687,
			vw: 131.2617,
			o: 131.13,
			c: 131.06,
			h: 131.45,
			l: 131.05,
			t: 1655387700000,
			n: 2744
		},
		{
			v: 195967,
			vw: 131.1599,
			o: 131.055,
			c: 131.19,
			h: 131.28,
			l: 131.05,
			t: 1655387760000,
			n: 1826
		},
		{
			v: 238617,
			vw: 131.2176,
			o: 131.18,
			c: 131.41,
			h: 131.41,
			l: 131.06,
			t: 1655387820000,
			n: 2423
		},
		{
			v: 274618,
			vw: 131.4217,
			o: 131.4101,
			c: 131.35,
			h: 131.55,
			l: 131.27,
			t: 1655387880000,
			n: 2620
		},
		{
			v: 217504,
			vw: 131.2948,
			o: 131.3566,
			c: 131.21,
			h: 131.39,
			l: 131.19,
			t: 1655387940000,
			n: 2301
		},
		{
			v: 331095,
			vw: 131.0868,
			o: 131.21,
			c: 131.07,
			h: 131.22,
			l: 130.95,
			t: 1655388000000,
			n: 3313
		},
		{
			v: 292283,
			vw: 131.1091,
			o: 131.06,
			c: 131.25,
			h: 131.29,
			l: 130.96,
			t: 1655388060000,
			n: 2751
		},
		{
			v: 241510,
			vw: 131.1212,
			o: 131.26,
			c: 131.07,
			h: 131.27,
			l: 131,
			t: 1655388120000,
			n: 2518
		},
		{
			v: 239449,
			vw: 131.1092,
			o: 131.06,
			c: 131.04,
			h: 131.23,
			l: 131.02,
			t: 1655388180000,
			n: 2460
		},
		{
			v: 261354,
			vw: 130.9711,
			o: 131.04,
			c: 130.92,
			h: 131.08,
			l: 130.8525,
			t: 1655388240000,
			n: 2966
		},
		{
			v: 271044,
			vw: 130.8226,
			o: 130.9293,
			c: 130.79,
			h: 130.9395,
			l: 130.7,
			t: 1655388300000,
			n: 3044
		},
		{
			v: 251378,
			vw: 130.9619,
			o: 130.785,
			c: 130.953,
			h: 131.11,
			l: 130.765,
			t: 1655388360000,
			n: 2504
		},
		{
			v: 189291,
			vw: 130.8952,
			o: 130.95,
			c: 130.9,
			h: 130.99,
			l: 130.82,
			t: 1655388420000,
			n: 1973
		},
		{
			v: 203410,
			vw: 130.8979,
			o: 130.89,
			c: 131,
			h: 131.05,
			l: 130.8,
			t: 1655388480000,
			n: 2399
		},
		{
			v: 229048,
			vw: 131.0233,
			o: 130.99,
			c: 131.11,
			h: 131.11,
			l: 130.94,
			t: 1655388540000,
			n: 2284
		},
		{
			v: 228924,
			vw: 131.0185,
			o: 131.095,
			c: 130.985,
			h: 131.095,
			l: 130.93,
			t: 1655388600000,
			n: 2241
		},
		{
			v: 200673,
			vw: 130.9026,
			o: 130.9899,
			c: 130.9,
			h: 131.015,
			l: 130.82,
			t: 1655388660000,
			n: 2304
		},
		{
			v: 141189,
			vw: 130.9398,
			o: 130.897,
			c: 130.99,
			h: 131.02,
			l: 130.87,
			t: 1655388720000,
			n: 1595
		},
		{
			v: 242949,
			vw: 131.0737,
			o: 130.99,
			c: 131.04,
			h: 131.12,
			l: 130.99,
			t: 1655388780000,
			n: 2338
		},
		{
			v: 237492,
			vw: 131.1351,
			o: 131.0301,
			c: 130.995,
			h: 131.29,
			l: 130.99,
			t: 1655388840000,
			n: 2475
		},
		{
			v: 276240,
			vw: 130.9593,
			o: 130.99,
			c: 130.97,
			h: 131.08,
			l: 130.88,
			t: 1655388900000,
			n: 2764
		},
		{
			v: 235077,
			vw: 131.1267,
			o: 130.97,
			c: 131.2558,
			h: 131.28,
			l: 130.97,
			t: 1655388960000,
			n: 2319
		},
		{
			v: 281028,
			vw: 131.3449,
			o: 131.255,
			c: 131.38,
			h: 131.43,
			l: 131.225,
			t: 1655389020000,
			n: 2627
		},
		{
			v: 225469,
			vw: 131.3149,
			o: 131.3786,
			c: 131.2315,
			h: 131.44,
			l: 131.16,
			t: 1655389080000,
			n: 1912
		},
		{
			v: 192735,
			vw: 131.0648,
			o: 131.24,
			c: 130.999,
			h: 131.24,
			l: 130.999,
			t: 1655389140000,
			n: 2114
		},
		{
			v: 240467,
			vw: 130.94,
			o: 130.99,
			c: 130.87,
			h: 131.04,
			l: 130.85,
			t: 1655389200000,
			n: 2439
		},
		{
			v: 226948,
			vw: 130.9438,
			o: 130.875,
			c: 130.93,
			h: 132.08,
			l: 130.83,
			t: 1655389260000,
			n: 2223
		},
		{
			v: 261703,
			vw: 130.9415,
			o: 130.92,
			c: 130.8807,
			h: 131.015,
			l: 130.88,
			t: 1655389320000,
			n: 2627
		},
		{
			v: 250329,
			vw: 131.0299,
			o: 130.88,
			c: 131.1207,
			h: 131.165,
			l: 130.8601,
			t: 1655389380000,
			n: 2300
		},
		{
			v: 216561,
			vw: 131.1842,
			o: 131.12,
			c: 131.14,
			h: 131.26,
			l: 131.08,
			t: 1655389440000,
			n: 1945
		},
		{
			v: 249249,
			vw: 131.2238,
			o: 131.13,
			c: 131.2293,
			h: 131.32,
			l: 131.075,
			t: 1655389500000,
			n: 2179
		},
		{
			v: 262417,
			vw: 131.2809,
			o: 131.23,
			c: 131.21,
			h: 131.35,
			l: 131.19,
			t: 1655389560000,
			n: 2231
		},
		{
			v: 272748,
			vw: 131.1662,
			o: 131.225,
			c: 131.03,
			h: 131.325,
			l: 131.01,
			t: 1655389620000,
			n: 2482
		},
		{
			v: 252537,
			vw: 131.0323,
			o: 131.03,
			c: 130.945,
			h: 131.15,
			l: 130.935,
			t: 1655389680000,
			n: 2522
		},
		{
			v: 165129,
			vw: 131.1169,
			o: 130.93,
			c: 131.16,
			h: 131.2301,
			l: 130.92,
			t: 1655389740000,
			n: 1766
		},
		{
			v: 206495,
			vw: 131.0326,
			o: 131.16,
			c: 130.985,
			h: 131.16,
			l: 130.9329,
			t: 1655389800000,
			n: 2414
		},
		{
			v: 117392,
			vw: 131.0381,
			o: 130.995,
			c: 131.015,
			h: 131.1388,
			l: 130.94,
			t: 1655389860000,
			n: 1346
		},
		{
			v: 184452,
			vw: 131.0229,
			o: 131.01,
			c: 131.1,
			h: 131.11,
			l: 130.94,
			t: 1655389920000,
			n: 1890
		},
		{
			v: 255436,
			vw: 131.2649,
			o: 131.105,
			c: 131.26,
			h: 131.37,
			l: 131.08,
			t: 1655389980000,
			n: 2075
		},
		{
			v: 227356,
			vw: 131.179,
			o: 131.25,
			c: 131.18,
			h: 131.28,
			l: 131.095,
			t: 1655390040000,
			n: 1997
		},
		{
			v: 220234,
			vw: 131.1296,
			o: 131.17,
			c: 131.0217,
			h: 131.26,
			l: 131.01,
			t: 1655390100000,
			n: 2185
		},
		{
			v: 294704,
			vw: 131.0315,
			o: 131.03,
			c: 130.865,
			h: 131.18,
			l: 130.86,
			t: 1655390160000,
			n: 2760
		},
		{
			v: 195314,
			vw: 130.9427,
			o: 130.86,
			c: 131.01,
			h: 131.03,
			l: 130.86,
			t: 1655390220000,
			n: 1906
		},
		{
			v: 282762,
			vw: 130.8719,
			o: 130.99,
			c: 130.76,
			h: 131.02,
			l: 130.75,
			t: 1655390280000,
			n: 2777
		},
		{
			v: 339191,
			vw: 130.7089,
			o: 130.7687,
			c: 130.695,
			h: 130.8,
			l: 130.65,
			t: 1655390340000,
			n: 2732
		},
		{
			v: 217600,
			vw: 130.6679,
			o: 130.695,
			c: 130.63,
			h: 130.71,
			l: 130.62,
			t: 1655390400000,
			n: 2124
		},
		{
			v: 221984,
			vw: 130.6407,
			o: 130.62,
			c: 130.63,
			h: 130.7393,
			l: 130.58,
			t: 1655390460000,
			n: 2502
		},
		{
			v: 211820,
			vw: 130.5751,
			o: 130.63,
			c: 130.585,
			h: 130.6467,
			l: 130.51,
			t: 1655390520000,
			n: 2183
		},
		{
			v: 403522,
			vw: 130.5257,
			o: 130.58,
			c: 130.3952,
			h: 130.72,
			l: 130.37,
			t: 1655390580000,
			n: 5146
		},
		{
			v: 366591,
			vw: 130.3314,
			o: 130.39,
			c: 130.3,
			h: 130.42,
			l: 130.26,
			t: 1655390640000,
			n: 3562
		},
		{
			v: 170298,
			vw: 130.3017,
			o: 130.3,
			c: 130.295,
			h: 130.34,
			l: 130.26,
			t: 1655390700000,
			n: 1759
		},
		{
			v: 217074,
			vw: 130.2991,
			o: 130.29,
			c: 130.2584,
			h: 130.38,
			l: 130.24,
			t: 1655390760000,
			n: 2247
		},
		{
			v: 201377,
			vw: 130.2853,
			o: 130.255,
			c: 130.37,
			h: 130.37,
			l: 130.25,
			t: 1655390820000,
			n: 1935
		},
		{
			v: 284039,
			vw: 130.2975,
			o: 130.39,
			c: 130.2099,
			h: 130.39,
			l: 130.18,
			t: 1655390880000,
			n: 3128
		},
		{
			v: 461366,
			vw: 130.1071,
			o: 130.21,
			c: 130.04,
			h: 130.26,
			l: 130.02,
			t: 1655390940000,
			n: 4940
		},
		{
			v: 1101915,
			vw: 129.9975,
			o: 130.05,
			c: 129.91,
			h: 130.1652,
			l: 129.89,
			t: 1655391000000,
			n: 24344
		},
		{
			v: 330341,
			vw: 129.9588,
			o: 129.91,
			c: 129.96,
			h: 130.04,
			l: 129.89,
			t: 1655391060000,
			n: 2858
		},
		{
			v: 212138,
			vw: 130.0008,
			o: 129.96,
			c: 130.045,
			h: 130.071,
			l: 129.94,
			t: 1655391120000,
			n: 2152
		},
		{
			v: 252427,
			vw: 130.0393,
			o: 130.0435,
			c: 130.15,
			h: 130.1791,
			l: 129.92,
			t: 1655391180000,
			n: 2483
		},
		{
			v: 278699,
			vw: 130.262,
			o: 130.15,
			c: 130.265,
			h: 130.35,
			l: 130.14,
			t: 1655391240000,
			n: 2410
		},
		{
			v: 196327,
			vw: 130.2721,
			o: 130.26,
			c: 130.298,
			h: 130.337,
			l: 130.22,
			t: 1655391300000,
			n: 2002
		},
		{
			v: 137077,
			vw: 130.2878,
			o: 130.29,
			c: 130.31,
			h: 130.33,
			l: 130.25,
			t: 1655391360000,
			n: 1661
		},
		{
			v: 183034,
			vw: 130.2959,
			o: 130.32,
			c: 130.27,
			h: 130.35,
			l: 130.22,
			t: 1655391420000,
			n: 1685
		},
		{
			v: 159758,
			vw: 130.2795,
			o: 130.27,
			c: 130.28,
			h: 130.32,
			l: 130.2311,
			t: 1655391480000,
			n: 1659
		},
		{
			v: 175037,
			vw: 130.2275,
			o: 130.2728,
			c: 130.1281,
			h: 130.33,
			l: 130.1101,
			t: 1655391540000,
			n: 1842
		},
		{
			v: 219193,
			vw: 130.3263,
			o: 130.1285,
			c: 130.44,
			h: 130.46,
			l: 130.09,
			t: 1655391600000,
			n: 1998
		},
		{
			v: 231079,
			vw: 130.4713,
			o: 130.45,
			c: 130.54,
			h: 130.5699,
			l: 130.38,
			t: 1655391660000,
			n: 2117
		},
		{
			v: 161339,
			vw: 130.4693,
			o: 130.538,
			c: 130.48,
			h: 130.538,
			l: 130.4,
			t: 1655391720000,
			n: 1651
		},
		{
			v: 119921,
			vw: 130.4597,
			o: 130.49,
			c: 130.43,
			h: 130.5,
			l: 130.41,
			t: 1655391780000,
			n: 1386
		},
		{
			v: 151618,
			vw: 130.4738,
			o: 130.44,
			c: 130.45,
			h: 130.5291,
			l: 130.42,
			t: 1655391840000,
			n: 1700
		},
		{
			v: 219348,
			vw: 130.3437,
			o: 130.427,
			c: 130.31,
			h: 130.47,
			l: 130.27,
			t: 1655391900000,
			n: 1927
		},
		{
			v: 156193,
			vw: 130.3835,
			o: 130.3184,
			c: 130.41,
			h: 130.44,
			l: 130.3,
			t: 1655391960000,
			n: 1414
		},
		{
			v: 114902,
			vw: 130.4049,
			o: 130.42,
			c: 130.365,
			h: 130.47,
			l: 130.31,
			t: 1655392020000,
			n: 1343
		},
		{
			v: 132304,
			vw: 130.354,
			o: 130.365,
			c: 130.31,
			h: 130.41,
			l: 130.3,
			t: 1655392080000,
			n: 1404
		},
		{
			v: 238767,
			vw: 130.4801,
			o: 130.32,
			c: 130.655,
			h: 130.68,
			l: 130.3,
			t: 1655392140000,
			n: 2056
		},
		{
			v: 195201,
			vw: 130.6239,
			o: 130.68,
			c: 130.67,
			h: 130.71,
			l: 130.545,
			t: 1655392200000,
			n: 1723
		},
		{
			v: 242216,
			vw: 130.7448,
			o: 130.66,
			c: 130.79,
			h: 130.89,
			l: 130.595,
			t: 1655392260000,
			n: 2375
		},
		{
			v: 264402,
			vw: 130.8759,
			o: 130.8,
			c: 130.8,
			h: 130.99,
			l: 130.77,
			t: 1655392320000,
			n: 2544
		},
		{
			v: 220047,
			vw: 130.9418,
			o: 130.81,
			c: 130.9843,
			h: 131.02,
			l: 130.79,
			t: 1655392380000,
			n: 2181
		},
		{
			v: 286824,
			vw: 130.9186,
			o: 130.9801,
			c: 131.02,
			h: 131.02,
			l: 130.84,
			t: 1655392440000,
			n: 2661
		},
		{
			v: 217162,
			vw: 130.9068,
			o: 131.0101,
			c: 131.01,
			h: 131.03,
			l: 130.78,
			t: 1655392500000,
			n: 2149
		},
		{
			v: 144772,
			vw: 130.9544,
			o: 131.01,
			c: 130.92,
			h: 131.03,
			l: 130.87,
			t: 1655392560000,
			n: 1636
		},
		{
			v: 176111,
			vw: 130.812,
			o: 130.912,
			c: 130.7926,
			h: 130.915,
			l: 130.75,
			t: 1655392620000,
			n: 1875
		},
		{
			v: 146739,
			vw: 130.9207,
			o: 130.8,
			c: 130.96,
			h: 130.98,
			l: 130.775,
			t: 1655392680000,
			n: 1472
		},
		{
			v: 129696,
			vw: 130.8126,
			o: 130.94,
			c: 130.82,
			h: 130.955,
			l: 130.75,
			t: 1655392740000,
			n: 1579
		},
		{
			v: 204583,
			vw: 130.7293,
			o: 130.8298,
			c: 130.6208,
			h: 130.84,
			l: 130.595,
			t: 1655392800000,
			n: 2035
		},
		{
			v: 214311,
			vw: 130.6337,
			o: 130.625,
			c: 130.72,
			h: 130.78,
			l: 130.53,
			t: 1655392860000,
			n: 2003
		},
		{
			v: 117170,
			vw: 130.7463,
			o: 130.73,
			c: 130.783,
			h: 130.8,
			l: 130.67,
			t: 1655392920000,
			n: 1345
		},
		{
			v: 154467,
			vw: 130.8665,
			o: 130.78,
			c: 130.97,
			h: 130.979,
			l: 130.78,
			t: 1655392980000,
			n: 1447
		},
		{
			v: 120157,
			vw: 130.9324,
			o: 130.97,
			c: 130.86,
			h: 130.99,
			l: 130.85,
			t: 1655393040000,
			n: 1367
		},
		{
			v: 223522,
			vw: 130.9532,
			o: 130.87,
			c: 130.955,
			h: 131.05,
			l: 130.83,
			t: 1655393100000,
			n: 2076
		},
		{
			v: 193885,
			vw: 130.9941,
			o: 130.97,
			c: 130.99,
			h: 131.07,
			l: 130.88,
			t: 1655393160000,
			n: 1636
		},
		{
			v: 163555,
			vw: 131.0729,
			o: 130.995,
			c: 131.11,
			h: 131.14,
			l: 130.99,
			t: 1655393220000,
			n: 1545
		},
		{
			v: 151398,
			vw: 131.0948,
			o: 131.1185,
			c: 131.0911,
			h: 131.16,
			l: 131.0201,
			t: 1655393280000,
			n: 1492
		},
		{
			v: 160485,
			vw: 130.9986,
			o: 131.09,
			c: 130.91,
			h: 131.0984,
			l: 130.88,
			t: 1655393340000,
			n: 1857
		},
		{
			v: 216953,
			vw: 130.8072,
			o: 130.9,
			c: 130.7491,
			h: 130.9699,
			l: 130.69,
			t: 1655393400000,
			n: 2322
		},
		{
			v: 163709,
			vw: 130.7816,
			o: 130.75,
			c: 130.86,
			h: 130.87,
			l: 130.66,
			t: 1655393460000,
			n: 1696
		},
		{
			v: 113127,
			vw: 130.8006,
			o: 130.86,
			c: 130.75,
			h: 130.89,
			l: 130.73,
			t: 1655393520000,
			n: 1395
		},
		{
			v: 146083,
			vw: 130.7589,
			o: 130.75,
			c: 130.6799,
			h: 130.84,
			l: 130.675,
			t: 1655393580000,
			n: 1667
		},
		{
			v: 208306,
			vw: 130.6086,
			o: 130.67,
			c: 130.56,
			h: 130.69,
			l: 130.535,
			t: 1655393640000,
			n: 2303
		},
		{
			v: 149600,
			vw: 130.5268,
			o: 130.55,
			c: 130.565,
			h: 130.59,
			l: 130.47,
			t: 1655393700000,
			n: 1738
		},
		{
			v: 97829,
			vw: 130.5452,
			o: 130.55,
			c: 130.5286,
			h: 130.6147,
			l: 130.495,
			t: 1655393760000,
			n: 1240
		},
		{
			v: 148140,
			vw: 130.5334,
			o: 130.525,
			c: 130.55,
			h: 130.58,
			l: 130.485,
			t: 1655393820000,
			n: 1422
		},
		{
			v: 142654,
			vw: 130.6421,
			o: 130.5631,
			c: 130.755,
			h: 130.77,
			l: 130.539,
			t: 1655393880000,
			n: 1458
		},
		{
			v: 143959,
			vw: 130.7456,
			o: 130.755,
			c: 130.7514,
			h: 130.79,
			l: 130.68,
			t: 1655393940000,
			n: 1424
		},
		{
			v: 125205,
			vw: 130.693,
			o: 130.76,
			c: 130.62,
			h: 130.78,
			l: 130.6,
			t: 1655394000000,
			n: 1427
		},
		{
			v: 165100,
			vw: 130.5553,
			o: 130.62,
			c: 130.5,
			h: 130.62,
			l: 130.49,
			t: 1655394060000,
			n: 1716
		},
		{
			v: 195287,
			vw: 130.3643,
			o: 130.4978,
			c: 130.2501,
			h: 130.4978,
			l: 130.2501,
			t: 1655394120000,
			n: 2341
		},
		{
			v: 132251,
			vw: 130.3075,
			o: 130.261,
			c: 130.28,
			h: 130.36,
			l: 130.25,
			t: 1655394180000,
			n: 1347
		},
		{
			v: 121510,
			vw: 130.3803,
			o: 130.28,
			c: 130.4384,
			h: 130.4401,
			l: 130.28,
			t: 1655394240000,
			n: 1297
		},
		{
			v: 142217,
			vw: 130.4089,
			o: 130.4399,
			c: 130.37,
			h: 130.48,
			l: 130.35,
			t: 1655394300000,
			n: 1515
		},
		{
			v: 119163,
			vw: 130.4374,
			o: 130.37,
			c: 130.42,
			h: 130.52,
			l: 130.36,
			t: 1655394360000,
			n: 1234
		},
		{
			v: 91849,
			vw: 130.3968,
			o: 130.4137,
			c: 130.4091,
			h: 130.45,
			l: 130.34,
			t: 1655394420000,
			n: 1167
		},
		{
			v: 102511,
			vw: 130.3965,
			o: 130.4,
			c: 130.4008,
			h: 130.47,
			l: 130.355,
			t: 1655394480000,
			n: 1161
		},
		{
			v: 106255,
			vw: 130.427,
			o: 130.41,
			c: 130.52,
			h: 130.55,
			l: 130.36,
			t: 1655394540000,
			n: 1304
		},
		{
			v: 173926,
			vw: 130.5112,
			o: 130.5231,
			c: 130.53,
			h: 130.63,
			l: 130.4801,
			t: 1655394600000,
			n: 1346
		},
		{
			v: 158839,
			vw: 130.4305,
			o: 130.52,
			c: 130.415,
			h: 130.52,
			l: 130.325,
			t: 1655394660000,
			n: 1584
		},
		{
			v: 156824,
			vw: 130.4235,
			o: 130.42,
			c: 130.375,
			h: 130.4979,
			l: 130.35,
			t: 1655394720000,
			n: 1468
		},
		{
			v: 105333,
			vw: 130.3912,
			o: 130.37,
			c: 130.43,
			h: 130.45,
			l: 130.3478,
			t: 1655394780000,
			n: 1196
		},
		{
			v: 121186,
			vw: 130.4406,
			o: 130.4376,
			c: 130.4,
			h: 130.518,
			l: 130.38,
			t: 1655394840000,
			n: 1156
		},
		{
			v: 83691,
			vw: 130.4183,
			o: 130.4,
			c: 130.44,
			h: 130.48,
			l: 130.36,
			t: 1655394900000,
			n: 1144
		},
		{
			v: 106799,
			vw: 130.517,
			o: 130.44,
			c: 130.53,
			h: 130.595,
			l: 130.415,
			t: 1655394960000,
			n: 1215
		},
		{
			v: 131434,
			vw: 130.6267,
			o: 130.53,
			c: 130.6,
			h: 130.69,
			l: 130.51,
			t: 1655395020000,
			n: 1279
		},
		{
			v: 148919,
			vw: 130.6614,
			o: 130.6,
			c: 130.64,
			h: 130.695,
			l: 130.57,
			t: 1655395080000,
			n: 1200
		},
		{
			v: 145159,
			vw: 130.7724,
			o: 130.645,
			c: 130.89,
			h: 130.91,
			l: 130.645,
			t: 1655395140000,
			n: 1465
		},
		{
			v: 143892,
			vw: 130.8436,
			o: 130.89,
			c: 130.865,
			h: 130.92,
			l: 130.77,
			t: 1655395200000,
			n: 1457
		},
		{
			v: 151487,
			vw: 130.8397,
			o: 130.86,
			c: 130.72,
			h: 130.98,
			l: 130.705,
			t: 1655395260000,
			n: 1553
		},
		{
			v: 132198,
			vw: 130.6982,
			o: 130.725,
			c: 130.63,
			h: 130.79,
			l: 130.62,
			t: 1655395320000,
			n: 1350
		},
		{
			v: 114473,
			vw: 130.7358,
			o: 130.63,
			c: 130.775,
			h: 130.838,
			l: 130.62,
			t: 1655395380000,
			n: 1241
		},
		{
			v: 102484,
			vw: 130.797,
			o: 130.78,
			c: 130.715,
			h: 130.86,
			l: 130.71,
			t: 1655395440000,
			n: 1142
		},
		{
			v: 117202,
			vw: 130.6746,
			o: 130.71,
			c: 130.625,
			h: 130.7199,
			l: 130.62,
			t: 1655395500000,
			n: 1287
		},
		{
			v: 144198,
			vw: 130.6046,
			o: 130.6282,
			c: 130.52,
			h: 130.715,
			l: 130.475,
			t: 1655395560000,
			n: 1545
		},
		{
			v: 150747,
			vw: 130.4508,
			o: 130.53,
			c: 130.39,
			h: 130.53,
			l: 130.38,
			t: 1655395620000,
			n: 1569
		},
		{
			v: 200833,
			vw: 130.3518,
			o: 130.385,
			c: 130.32,
			h: 130.41,
			l: 130.3,
			t: 1655395680000,
			n: 2007
		},
		{
			v: 153450,
			vw: 130.2696,
			o: 130.31,
			c: 130.25,
			h: 130.32,
			l: 130.22,
			t: 1655395740000,
			n: 1949
		},
		{
			v: 134170,
			vw: 130.3719,
			o: 130.25,
			c: 130.49,
			h: 130.4901,
			l: 130.23,
			t: 1655395800000,
			n: 1457
		},
		{
			v: 138536,
			vw: 130.3836,
			o: 130.485,
			c: 130.2175,
			h: 130.54,
			l: 130.21,
			t: 1655395860000,
			n: 1380
		},
		{
			v: 142100,
			vw: 130.2359,
			o: 130.2001,
			c: 130.285,
			h: 130.3,
			l: 130.195,
			t: 1655395920000,
			n: 1831
		},
		{
			v: 92021,
			vw: 130.2822,
			o: 130.28,
			c: 130.2575,
			h: 130.3299,
			l: 130.23,
			t: 1655395980000,
			n: 1142
		},
		{
			v: 95204,
			vw: 130.2342,
			o: 130.26,
			c: 130.2101,
			h: 130.272,
			l: 130.2,
			t: 1655396040000,
			n: 1164
		},
		{
			v: 117926,
			vw: 130.2604,
			o: 130.2,
			c: 130.31,
			h: 130.31,
			l: 130.2,
			t: 1655396100000,
			n: 1148
		},
		{
			v: 111350,
			vw: 130.3174,
			o: 130.3095,
			c: 130.275,
			h: 130.37,
			l: 130.25,
			t: 1655396160000,
			n: 1200
		},
		{
			v: 119007,
			vw: 130.3074,
			o: 130.275,
			c: 130.37,
			h: 130.39,
			l: 130.22,
			t: 1655396220000,
			n: 1360
		},
		{
			v: 160729,
			vw: 130.427,
			o: 130.37,
			c: 130.4,
			h: 130.5,
			l: 130.355,
			t: 1655396280000,
			n: 1331
		},
		{
			v: 110205,
			vw: 130.3604,
			o: 130.4,
			c: 130.335,
			h: 130.415,
			l: 130.3,
			t: 1655396340000,
			n: 1150
		},
		{
			v: 135842,
			vw: 130.318,
			o: 130.335,
			c: 130.4035,
			h: 130.41,
			l: 130.245,
			t: 1655396400000,
			n: 1282
		},
		{
			v: 157498,
			vw: 130.4514,
			o: 130.41,
			c: 130.44,
			h: 130.5077,
			l: 130.41,
			t: 1655396460000,
			n: 1306
		},
		{
			v: 117268,
			vw: 130.4605,
			o: 130.4491,
			c: 130.48,
			h: 130.5063,
			l: 130.4,
			t: 1655396520000,
			n: 1242
		},
		{
			v: 128257,
			vw: 130.4241,
			o: 130.48,
			c: 130.41,
			h: 130.48,
			l: 130.39,
			t: 1655396580000,
			n: 1503
		},
		{
			v: 264892,
			vw: 130.473,
			o: 130.405,
			c: 130.532,
			h: 130.55,
			l: 130.3801,
			t: 1655396640000,
			n: 2248
		},
		{
			v: 132698,
			vw: 130.556,
			o: 130.54,
			c: 130.565,
			h: 130.63,
			l: 130.47,
			t: 1655396700000,
			n: 1356
		},
		{
			v: 135284,
			vw: 130.6489,
			o: 130.57,
			c: 130.72,
			h: 130.72,
			l: 130.57,
			t: 1655396760000,
			n: 1355
		},
		{
			v: 353101,
			vw: 130.6979,
			o: 130.7188,
			c: 130.63,
			h: 130.8,
			l: 130.55,
			t: 1655396820000,
			n: 2205
		},
		{
			v: 125463,
			vw: 130.6986,
			o: 130.62,
			c: 130.75,
			h: 130.8,
			l: 130.59,
			t: 1655396880000,
			n: 1412
		},
		{
			v: 358641,
			vw: 130.9536,
			o: 130.7536,
			c: 131.01,
			h: 131.08,
			l: 130.75,
			t: 1655396940000,
			n: 2669
		},
		{
			v: 172919,
			vw: 130.9346,
			o: 131.01,
			c: 130.8099,
			h: 131.08,
			l: 130.79,
			t: 1655397000000,
			n: 1966
		},
		{
			v: 145557,
			vw: 130.7787,
			o: 130.805,
			c: 130.7399,
			h: 130.84,
			l: 130.7,
			t: 1655397060000,
			n: 1435
		},
		{
			v: 132107,
			vw: 130.847,
			o: 130.735,
			c: 130.91,
			h: 130.91,
			l: 130.73,
			t: 1655397120000,
			n: 1293
		},
		{
			v: 126299,
			vw: 130.9435,
			o: 130.91,
			c: 130.995,
			h: 131,
			l: 130.8806,
			t: 1655397180000,
			n: 1249
		},
		{
			v: 179931,
			vw: 130.9886,
			o: 130.995,
			c: 130.96,
			h: 131.06,
			l: 130.91,
			t: 1655397240000,
			n: 1617
		},
		{
			v: 127246,
			vw: 130.876,
			o: 130.95,
			c: 130.829,
			h: 130.95,
			l: 130.81,
			t: 1655397300000,
			n: 1324
		},
		{
			v: 136757,
			vw: 130.7219,
			o: 130.82,
			c: 130.7,
			h: 130.82,
			l: 130.67,
			t: 1655397360000,
			n: 1473
		},
		{
			v: 111983,
			vw: 130.7396,
			o: 130.71,
			c: 130.78,
			h: 130.8,
			l: 130.69,
			t: 1655397420000,
			n: 1209
		},
		{
			v: 151297,
			vw: 130.8473,
			o: 130.7801,
			c: 130.9098,
			h: 130.945,
			l: 130.745,
			t: 1655397480000,
			n: 1356
		},
		{
			v: 125238,
			vw: 130.8549,
			o: 130.915,
			c: 130.84,
			h: 130.92,
			l: 130.79,
			t: 1655397540000,
			n: 1400
		},
		{
			v: 139307,
			vw: 130.8928,
			o: 130.85,
			c: 130.94,
			h: 130.97,
			l: 130.82,
			t: 1655397600000,
			n: 1448
		},
		{
			v: 152083,
			vw: 130.8998,
			o: 130.95,
			c: 130.78,
			h: 131,
			l: 130.76,
			t: 1655397660000,
			n: 1394
		},
		{
			v: 120451,
			vw: 130.7685,
			o: 130.775,
			c: 130.81,
			h: 130.82,
			l: 130.71,
			t: 1655397720000,
			n: 1236
		},
		{
			v: 95206,
			vw: 130.8098,
			o: 130.8,
			c: 130.81,
			h: 130.86,
			l: 130.7518,
			t: 1655397780000,
			n: 1023
		},
		{
			v: 265437,
			vw: 131.0513,
			o: 130.815,
			c: 131.06,
			h: 131.165,
			l: 130.814,
			t: 1655397840000,
			n: 1987
		},
		{
			v: 145885,
			vw: 131.0273,
			o: 131.065,
			c: 131.0201,
			h: 131.11,
			l: 130.92,
			t: 1655397900000,
			n: 1360
		},
		{
			v: 168441,
			vw: 131.1034,
			o: 131.04,
			c: 131.1099,
			h: 131.186,
			l: 131.03,
			t: 1655397960000,
			n: 1680
		},
		{
			v: 145441,
			vw: 130.9707,
			o: 131.1064,
			c: 130.8746,
			h: 131.13,
			l: 130.8538,
			t: 1655398020000,
			n: 1474
		},
		{
			v: 103936,
			vw: 130.9378,
			o: 130.88,
			c: 131.03,
			h: 131.03,
			l: 130.86,
			t: 1655398080000,
			n: 1214
		},
		{
			v: 207065,
			vw: 131.002,
			o: 131.03,
			c: 130.97,
			h: 131.1,
			l: 130.89,
			t: 1655398140000,
			n: 2212
		},
		{
			v: 99051,
			vw: 130.9058,
			o: 130.965,
			c: 130.925,
			h: 130.98,
			l: 130.84,
			t: 1655398200000,
			n: 1107
		},
		{
			v: 102692,
			vw: 130.9192,
			o: 130.925,
			c: 130.845,
			h: 130.98,
			l: 130.8343,
			t: 1655398260000,
			n: 1188
		},
		{
			v: 164507,
			vw: 130.8631,
			o: 130.83,
			c: 131.065,
			h: 131.0767,
			l: 130.738,
			t: 1655398320000,
			n: 1637
		},
		{
			v: 126384,
			vw: 130.9867,
			o: 131.07,
			c: 130.89,
			h: 131.13,
			l: 130.89,
			t: 1655398380000,
			n: 1175
		},
		{
			v: 105691,
			vw: 130.9598,
			o: 130.89,
			c: 130.91,
			h: 131.04,
			l: 130.8834,
			t: 1655398440000,
			n: 1005
		},
		{
			v: 113039,
			vw: 130.8501,
			o: 130.91,
			c: 130.8388,
			h: 130.9297,
			l: 130.77,
			t: 1655398500000,
			n: 1315
		},
		{
			v: 90186,
			vw: 130.8439,
			o: 130.84,
			c: 130.8952,
			h: 130.9,
			l: 130.79,
			t: 1655398560000,
			n: 962
		},
		{
			v: 90831,
			vw: 130.8856,
			o: 130.9,
			c: 130.91,
			h: 130.94,
			l: 130.81,
			t: 1655398620000,
			n: 998
		},
		{
			v: 82634,
			vw: 130.9072,
			o: 130.8993,
			c: 130.8639,
			h: 130.9599,
			l: 130.85,
			t: 1655398680000,
			n: 924
		},
		{
			v: 152201,
			vw: 130.9213,
			o: 130.86,
			c: 131.04,
			h: 131.04,
			l: 130.81,
			t: 1655398740000,
			n: 1772
		},
		{
			v: 166035,
			vw: 131.0382,
			o: 131.03,
			c: 131.065,
			h: 131.125,
			l: 130.94,
			t: 1655398800000,
			n: 1575
		},
		{
			v: 211264,
			vw: 131.1898,
			o: 131.07,
			c: 131.2744,
			h: 131.29,
			l: 131.06,
			t: 1655398860000,
			n: 1802
		},
		{
			v: 201248,
			vw: 131.2124,
			o: 131.28,
			c: 131.27,
			h: 131.35,
			l: 131.12,
			t: 1655398920000,
			n: 1831
		},
		{
			v: 133306,
			vw: 131.163,
			o: 131.28,
			c: 131.048,
			h: 131.3,
			l: 131.03,
			t: 1655398980000,
			n: 1251
		},
		{
			v: 182634,
			vw: 130.9923,
			o: 131.04,
			c: 130.97,
			h: 131.08,
			l: 130.91,
			t: 1655399040000,
			n: 1758
		},
		{
			v: 100432,
			vw: 130.9784,
			o: 130.96,
			c: 130.93,
			h: 131.039,
			l: 130.92,
			t: 1655399100000,
			n: 1234
		},
		{
			v: 285683,
			vw: 130.8511,
			o: 130.93,
			c: 130.93,
			h: 130.94,
			l: 130.745,
			t: 1655399160000,
			n: 2492
		},
		{
			v: 261814,
			vw: 131.0891,
			o: 130.9237,
			c: 131.25,
			h: 131.25,
			l: 130.91,
			t: 1655399220000,
			n: 2014
		},
		{
			v: 159199,
			vw: 131.2893,
			o: 131.25,
			c: 131.22,
			h: 131.39,
			l: 131.1837,
			t: 1655399280000,
			n: 1650
		},
		{
			v: 97258,
			vw: 131.172,
			o: 131.21,
			c: 131.21,
			h: 131.22,
			l: 131.1,
			t: 1655399340000,
			n: 1125
		},
		{
			v: 110361,
			vw: 131.1254,
			o: 131.22,
			c: 131.08,
			h: 131.23,
			l: 131.0599,
			t: 1655399400000,
			n: 1167
		},
		{
			v: 135686,
			vw: 131.2032,
			o: 131.085,
			c: 131.28,
			h: 131.3501,
			l: 131.02,
			t: 1655399460000,
			n: 1314
		},
		{
			v: 211786,
			vw: 131.3747,
			o: 131.28,
			c: 131.29,
			h: 131.4601,
			l: 131.27,
			t: 1655399520000,
			n: 2095
		},
		{
			v: 132710,
			vw: 131.2064,
			o: 131.29,
			c: 131.15,
			h: 131.33,
			l: 131.09,
			t: 1655399580000,
			n: 1397
		},
		{
			v: 106918,
			vw: 131.2159,
			o: 131.16,
			c: 131.205,
			h: 131.31,
			l: 131.1,
			t: 1655399640000,
			n: 1158
		},
		{
			v: 86749,
			vw: 131.2324,
			o: 131.21,
			c: 131.2204,
			h: 131.3,
			l: 131.1499,
			t: 1655399700000,
			n: 939
		},
		{
			v: 102363,
			vw: 131.1916,
			o: 131.22,
			c: 131.2,
			h: 131.75,
			l: 131.13,
			t: 1655399760000,
			n: 1123
		},
		{
			v: 97717,
			vw: 131.277,
			o: 131.19,
			c: 131.26,
			h: 131.37,
			l: 131.18,
			t: 1655399820000,
			n: 1042
		},
		{
			v: 108174,
			vw: 131.3189,
			o: 131.2604,
			c: 131.27,
			h: 131.371,
			l: 131.2499,
			t: 1655399880000,
			n: 1081
		},
		{
			v: 111082,
			vw: 131.1716,
			o: 131.275,
			c: 131.11,
			h: 131.275,
			l: 131.1,
			t: 1655399940000,
			n: 1141
		},
		{
			v: 187176,
			vw: 131.0477,
			o: 131.12,
			c: 131.055,
			h: 131.16,
			l: 130.97,
			t: 1655400000000,
			n: 1706
		},
		{
			v: 107682,
			vw: 130.9586,
			o: 131.04,
			c: 130.92,
			h: 131.04,
			l: 130.91,
			t: 1655400060000,
			n: 1108
		},
		{
			v: 103108,
			vw: 130.989,
			o: 130.905,
			c: 130.96,
			h: 131.06,
			l: 130.9,
			t: 1655400120000,
			n: 1142
		},
		{
			v: 113925,
			vw: 130.9146,
			o: 130.96,
			c: 130.82,
			h: 131.01,
			l: 130.81,
			t: 1655400180000,
			n: 1255
		},
		{
			v: 102170,
			vw: 130.8654,
			o: 130.815,
			c: 130.845,
			h: 130.93,
			l: 130.78,
			t: 1655400240000,
			n: 1204
		},
		{
			v: 114510,
			vw: 130.8211,
			o: 130.845,
			c: 130.855,
			h: 130.89,
			l: 130.775,
			t: 1655400300000,
			n: 1326
		},
		{
			v: 109639,
			vw: 130.886,
			o: 130.85,
			c: 130.92,
			h: 130.9467,
			l: 130.83,
			t: 1655400360000,
			n: 1113
		},
		{
			v: 194767,
			vw: 130.9521,
			o: 130.92,
			c: 130.9572,
			h: 131.125,
			l: 130.834,
			t: 1655400420000,
			n: 2022
		},
		{
			v: 324368,
			vw: 131.2087,
			o: 130.94,
			c: 131.37,
			h: 131.385,
			l: 130.89,
			t: 1655400480000,
			n: 2853
		},
		{
			v: 242446,
			vw: 131.3953,
			o: 131.375,
			c: 131.255,
			h: 131.53,
			l: 131.23,
			t: 1655400540000,
			n: 2080
		},
		{
			v: 173990,
			vw: 131.252,
			o: 131.26,
			c: 131.43,
			h: 131.49,
			l: 131.1,
			t: 1655400600000,
			n: 1688
		},
		{
			v: 172383,
			vw: 131.3584,
			o: 131.415,
			c: 131.23,
			h: 131.52,
			l: 131.16,
			t: 1655400660000,
			n: 1600
		},
		{
			v: 162876,
			vw: 131.1134,
			o: 131.225,
			c: 131.25,
			h: 131.257,
			l: 131.01,
			t: 1655400720000,
			n: 1543
		},
		{
			v: 118135,
			vw: 131.2908,
			o: 131.26,
			c: 131.24,
			h: 131.36,
			l: 131.21,
			t: 1655400780000,
			n: 1214
		},
		{
			v: 142388,
			vw: 131.2337,
			o: 131.26,
			c: 131.31,
			h: 131.32,
			l: 131.11,
			t: 1655400840000,
			n: 1308
		},
		{
			v: 135894,
			vw: 131.2161,
			o: 131.315,
			c: 131.17,
			h: 131.36,
			l: 131.12,
			t: 1655400900000,
			n: 1361
		},
		{
			v: 148335,
			vw: 131.1854,
			o: 131.17,
			c: 131.08,
			h: 131.28,
			l: 131.08,
			t: 1655400960000,
			n: 1523
		},
		{
			v: 119866,
			vw: 131.1239,
			o: 131.1,
			c: 131.21,
			h: 131.23,
			l: 131.06,
			t: 1655401020000,
			n: 1187
		},
		{
			v: 124595,
			vw: 131.1414,
			o: 131.21,
			c: 131.21,
			h: 131.2499,
			l: 131.05,
			t: 1655401080000,
			n: 1254
		},
		{
			v: 102340,
			vw: 131.2399,
			o: 131.22,
			c: 131.279,
			h: 131.32,
			l: 131.14,
			t: 1655401140000,
			n: 1078
		},
		{
			v: 129727,
			vw: 131.2443,
			o: 131.2601,
			c: 131.1001,
			h: 131.35,
			l: 131.09,
			t: 1655401200000,
			n: 1261
		},
		{
			v: 106881,
			vw: 131.1045,
			o: 131.08,
			c: 131.1,
			h: 131.18,
			l: 131.05,
			t: 1655401260000,
			n: 1004
		},
		{
			v: 108799,
			vw: 131.1012,
			o: 131.105,
			c: 131.13,
			h: 131.16,
			l: 131.03,
			t: 1655401320000,
			n: 1240
		},
		{
			v: 153478,
			vw: 131.0231,
			o: 131.12,
			c: 130.92,
			h: 131.15,
			l: 130.92,
			t: 1655401380000,
			n: 1562
		},
		{
			v: 164407,
			vw: 130.8966,
			o: 130.93,
			c: 130.84,
			h: 130.99,
			l: 130.8,
			t: 1655401440000,
			n: 1563
		},
		{
			v: 175343,
			vw: 130.7966,
			o: 130.83,
			c: 130.82,
			h: 130.84,
			l: 130.74,
			t: 1655401500000,
			n: 1583
		},
		{
			v: 183072,
			vw: 130.7349,
			o: 130.81,
			c: 130.6699,
			h: 130.82,
			l: 130.64,
			t: 1655401560000,
			n: 1876
		},
		{
			v: 248380,
			vw: 130.5874,
			o: 130.67,
			c: 130.58,
			h: 130.6967,
			l: 130.51,
			t: 1655401620000,
			n: 2288
		},
		{
			v: 163337,
			vw: 130.6122,
			o: 130.57,
			c: 130.63,
			h: 130.7,
			l: 130.535,
			t: 1655401680000,
			n: 1451
		},
		{
			v: 137539,
			vw: 130.5692,
			o: 130.61,
			c: 130.6159,
			h: 130.62,
			l: 130.51,
			t: 1655401740000,
			n: 1165
		},
		{
			v: 273319,
			vw: 130.4948,
			o: 130.61,
			c: 130.47,
			h: 130.66,
			l: 130.41,
			t: 1655401800000,
			n: 2995
		},
		{
			v: 145798,
			vw: 130.4306,
			o: 130.46,
			c: 130.43,
			h: 130.52,
			l: 130.38,
			t: 1655401860000,
			n: 1461
		},
		{
			v: 114460,
			vw: 130.4687,
			o: 130.425,
			c: 130.43,
			h: 130.53,
			l: 130.405,
			t: 1655401920000,
			n: 1161
		},
		{
			v: 121786,
			vw: 130.4897,
			o: 130.43,
			c: 130.49,
			h: 130.54,
			l: 130.42,
			t: 1655401980000,
			n: 1241
		},
		{
			v: 91014,
			vw: 130.4698,
			o: 130.49,
			c: 130.48,
			h: 130.5,
			l: 130.42,
			t: 1655402040000,
			n: 1078
		},
		{
			v: 143569,
			vw: 130.4096,
			o: 130.48,
			c: 130.36,
			h: 130.5,
			l: 130.34,
			t: 1655402100000,
			n: 1593
		},
		{
			v: 156321,
			vw: 130.3374,
			o: 130.3568,
			c: 130.32,
			h: 130.41,
			l: 130.28,
			t: 1655402160000,
			n: 1730
		},
		{
			v: 133512,
			vw: 130.3356,
			o: 130.32,
			c: 130.265,
			h: 130.38,
			l: 130.26,
			t: 1655402220000,
			n: 1275
		},
		{
			v: 204476,
			vw: 130.2281,
			o: 130.265,
			c: 130.16,
			h: 130.3,
			l: 130.1542,
			t: 1655402280000,
			n: 2219
		},
		{
			v: 271419,
			vw: 130.1015,
			o: 130.15,
			c: 130.02,
			h: 130.23,
			l: 130.01,
			t: 1655402340000,
			n: 2559
		},
		{
			v: 532048,
			vw: 129.9909,
			o: 130.01,
			c: 129.965,
			h: 130.04,
			l: 129.92,
			t: 1655402400000,
			n: 8638
		},
		{
			v: 198722,
			vw: 130.0971,
			o: 129.96,
			c: 130.1686,
			h: 130.19,
			l: 129.96,
			t: 1655402460000,
			n: 1993
		},
		{
			v: 176701,
			vw: 130.0735,
			o: 130.16,
			c: 130.0228,
			h: 130.17,
			l: 130.01,
			t: 1655402520000,
			n: 1720
		},
		{
			v: 210923,
			vw: 130.1328,
			o: 130.0299,
			c: 130.11,
			h: 130.2,
			l: 130.025,
			t: 1655402580000,
			n: 1990
		},
		{
			v: 193063,
			vw: 130.0569,
			o: 130.1084,
			c: 129.97,
			h: 130.15,
			l: 129.9697,
			t: 1655402640000,
			n: 2122
		},
		{
			v: 244687,
			vw: 130.0282,
			o: 129.97,
			c: 129.931,
			h: 130.05,
			l: 129.915,
			t: 1655402700000,
			n: 1739
		},
		{
			v: 132040,
			vw: 129.9259,
			o: 129.9308,
			c: 129.92,
			h: 129.95,
			l: 129.91,
			t: 1655402760000,
			n: 1404
		},
		{
			v: 358195,
			vw: 129.7989,
			o: 129.915,
			c: 129.75,
			h: 129.915,
			l: 129.68,
			t: 1655402820000,
			n: 5956
		},
		{
			v: 180547,
			vw: 129.7564,
			o: 129.7499,
			c: 129.69,
			h: 129.79,
			l: 129.69,
			t: 1655402880000,
			n: 1678
		},
		{
			v: 185751,
			vw: 129.7298,
			o: 129.6902,
			c: 129.735,
			h: 129.76,
			l: 129.69,
			t: 1655402940000,
			n: 1646
		},
		{
			v: 302327,
			vw: 129.7651,
			o: 129.74,
			c: 129.73,
			h: 129.8499,
			l: 129.71,
			t: 1655403000000,
			n: 1732
		},
		{
			v: 153540,
			vw: 129.7948,
			o: 129.725,
			c: 129.74,
			h: 129.87,
			l: 129.7,
			t: 1655403060000,
			n: 1551
		},
		{
			v: 138120,
			vw: 129.7993,
			o: 129.7491,
			c: 129.82,
			h: 129.86,
			l: 129.71,
			t: 1655403120000,
			n: 1365
		},
		{
			v: 118457,
			vw: 129.8232,
			o: 129.83,
			c: 129.8758,
			h: 129.8758,
			l: 129.77,
			t: 1655403180000,
			n: 1347
		},
		{
			v: 148549,
			vw: 129.923,
			o: 129.875,
			c: 129.91,
			h: 129.96,
			l: 129.87,
			t: 1655403240000,
			n: 1587
		},
		{
			v: 134184,
			vw: 129.8909,
			o: 129.92,
			c: 129.85,
			h: 129.96,
			l: 129.82,
			t: 1655403300000,
			n: 1646
		},
		{
			v: 117646,
			vw: 129.9067,
			o: 129.845,
			c: 129.9,
			h: 129.97,
			l: 129.825,
			t: 1655403360000,
			n: 1276
		},
		{
			v: 111739,
			vw: 129.8704,
			o: 129.9,
			c: 129.8605,
			h: 129.93,
			l: 129.8301,
			t: 1655403420000,
			n: 1166
		},
		{
			v: 166511,
			vw: 129.9467,
			o: 129.8676,
			c: 130.025,
			h: 130.06,
			l: 129.845,
			t: 1655403480000,
			n: 1570
		},
		{
			v: 197913,
			vw: 130.0024,
			o: 130.03,
			c: 130.02,
			h: 130.07,
			l: 129.925,
			t: 1655403540000,
			n: 1488
		},
		{
			v: 160279,
			vw: 129.9685,
			o: 130.01,
			c: 129.9187,
			h: 130.06,
			l: 129.9,
			t: 1655403600000,
			n: 1279
		},
		{
			v: 113976,
			vw: 129.9181,
			o: 129.91,
			c: 129.9401,
			h: 129.965,
			l: 129.89,
			t: 1655403660000,
			n: 1243
		},
		{
			v: 188922,
			vw: 129.8368,
			o: 129.94,
			c: 129.78,
			h: 129.9685,
			l: 129.745,
			t: 1655403720000,
			n: 1794
		},
		{
			v: 146199,
			vw: 129.7851,
			o: 129.78,
			c: 129.7956,
			h: 129.82,
			l: 129.735,
			t: 1655403780000,
			n: 1398
		},
		{
			v: 149850,
			vw: 129.783,
			o: 129.79,
			c: 129.78,
			h: 129.825,
			l: 129.76,
			t: 1655403840000,
			n: 1377
		},
		{
			v: 181552,
			vw: 129.7924,
			o: 129.78,
			c: 129.8004,
			h: 129.86,
			l: 129.73,
			t: 1655403900000,
			n: 1683
		},
		{
			v: 111661,
			vw: 129.8061,
			o: 129.81,
			c: 129.8,
			h: 129.85,
			l: 129.77,
			t: 1655403960000,
			n: 1093
		},
		{
			v: 119567,
			vw: 129.7991,
			o: 129.8,
			c: 129.8059,
			h: 129.84,
			l: 129.735,
			t: 1655404020000,
			n: 1243
		},
		{
			v: 155835,
			vw: 129.7438,
			o: 129.8,
			c: 129.715,
			h: 129.805,
			l: 129.71,
			t: 1655404080000,
			n: 1297
		},
		{
			v: 153764,
			vw: 129.721,
			o: 129.71,
			c: 129.685,
			h: 129.77,
			l: 129.67,
			t: 1655404140000,
			n: 1703
		},
		{
			v: 236902,
			vw: 129.7877,
			o: 129.68,
			c: 129.89,
			h: 129.89,
			l: 129.66,
			t: 1655404200000,
			n: 2032
		},
		{
			v: 126922,
			vw: 129.9215,
			o: 129.885,
			c: 129.91,
			h: 129.9639,
			l: 129.885,
			t: 1655404260000,
			n: 1299
		},
		{
			v: 145559,
			vw: 129.8059,
			o: 129.91,
			c: 129.8,
			h: 129.9281,
			l: 129.7383,
			t: 1655404320000,
			n: 1412
		},
		{
			v: 238465,
			vw: 129.7269,
			o: 129.8001,
			c: 129.6707,
			h: 129.8402,
			l: 129.6601,
			t: 1655404380000,
			n: 1486
		},
		{
			v: 128895,
			vw: 129.7265,
			o: 129.67,
			c: 129.805,
			h: 129.81,
			l: 129.67,
			t: 1655404440000,
			n: 1362
		},
		{
			v: 162952,
			vw: 129.7756,
			o: 129.8001,
			c: 129.76,
			h: 129.86,
			l: 129.73,
			t: 1655404500000,
			n: 1285
		},
		{
			v: 111246,
			vw: 129.8091,
			o: 129.765,
			c: 129.86,
			h: 129.88,
			l: 129.715,
			t: 1655404560000,
			n: 1351
		},
		{
			v: 137599,
			vw: 129.8551,
			o: 129.86,
			c: 129.86,
			h: 129.92,
			l: 129.795,
			t: 1655404620000,
			n: 1489
		},
		{
			v: 2165296,
			vw: 129.9027,
			o: 129.87,
			c: 129.91,
			h: 130.0013,
			l: 129.86,
			t: 1655404680000,
			n: 1637
		},
		{
			v: 124092,
			vw: 129.9028,
			o: 129.9,
			c: 129.89,
			h: 129.98,
			l: 129.805,
			t: 1655404740000,
			n: 1477
		},
		{
			v: 271933,
			vw: 130.0226,
			o: 129.88,
			c: 130.045,
			h: 130.14,
			l: 129.855,
			t: 1655404800000,
			n: 2265
		},
		{
			v: 221716,
			vw: 130.1401,
			o: 130.04,
			c: 130.07,
			h: 130.205,
			l: 130.04,
			t: 1655404860000,
			n: 1761
		},
		{
			v: 136821,
			vw: 130.0174,
			o: 130.0679,
			c: 130,
			h: 130.08,
			l: 129.94,
			t: 1655404920000,
			n: 1490
		},
		{
			v: 128482,
			vw: 129.9769,
			o: 130.005,
			c: 129.9699,
			h: 130.02,
			l: 129.93,
			t: 1655404980000,
			n: 1318
		},
		{
			v: 112815,
			vw: 129.9179,
			o: 129.96,
			c: 129.9637,
			h: 129.98,
			l: 129.84,
			t: 1655405040000,
			n: 1317
		},
		{
			v: 145651,
			vw: 129.9586,
			o: 129.97,
			c: 130.015,
			h: 130.05,
			l: 129.88,
			t: 1655405100000,
			n: 1511
		},
		{
			v: 138127,
			vw: 129.9177,
			o: 129.997,
			c: 129.81,
			h: 130.03,
			l: 129.81,
			t: 1655405160000,
			n: 1597
		},
		{
			v: 146613,
			vw: 129.784,
			o: 129.81,
			c: 129.78,
			h: 129.83,
			l: 129.73,
			t: 1655405220000,
			n: 1655
		},
		{
			v: 314629,
			vw: 129.6287,
			o: 129.78,
			c: 129.52,
			h: 129.7807,
			l: 129.51,
			t: 1655405280000,
			n: 3551
		},
		{
			v: 255200,
			vw: 129.5602,
			o: 129.52,
			c: 129.585,
			h: 129.63,
			l: 129.5191,
			t: 1655405340000,
			n: 2170
		},
		{
			v: 233566,
			vw: 129.6231,
			o: 129.585,
			c: 129.58,
			h: 129.7,
			l: 129.55,
			t: 1655405400000,
			n: 2163
		},
		{
			v: 155409,
			vw: 129.5641,
			o: 129.57,
			c: 129.5409,
			h: 129.63,
			l: 129.53,
			t: 1655405460000,
			n: 1644
		},
		{
			v: 353189,
			vw: 129.559,
			o: 129.55,
			c: 129.6085,
			h: 129.64,
			l: 129.52,
			t: 1655405520000,
			n: 1765
		},
		{
			v: 315442,
			vw: 129.4507,
			o: 129.605,
			c: 129.345,
			h: 129.61,
			l: 129.33,
			t: 1655405580000,
			n: 4683
		},
		{
			v: 210087,
			vw: 129.3547,
			o: 129.3499,
			c: 129.38,
			h: 129.41,
			l: 129.3,
			t: 1655405640000,
			n: 2012
		},
		{
			v: 171723,
			vw: 129.3399,
			o: 129.37,
			c: 129.385,
			h: 129.41,
			l: 129.29,
			t: 1655405700000,
			n: 1798
		},
		{
			v: 197219,
			vw: 129.4451,
			o: 129.39,
			c: 129.51,
			h: 129.51,
			l: 129.39,
			t: 1655405760000,
			n: 1832
		},
		{
			v: 161932,
			vw: 129.4469,
			o: 129.51,
			c: 129.41,
			h: 129.53,
			l: 129.38,
			t: 1655405820000,
			n: 1703
		},
		{
			v: 136739,
			vw: 129.4178,
			o: 129.395,
			c: 129.405,
			h: 129.48,
			l: 129.36,
			t: 1655405880000,
			n: 1431
		},
		{
			v: 143523,
			vw: 129.4888,
			o: 129.41,
			c: 129.55,
			h: 129.55,
			l: 129.39,
			t: 1655405940000,
			n: 1357
		},
		{
			v: 313728,
			vw: 129.7202,
			o: 129.54,
			c: 129.85,
			h: 129.85,
			l: 129.47,
			t: 1655406000000,
			n: 2807
		},
		{
			v: 346150,
			vw: 129.9457,
			o: 129.84,
			c: 130.1135,
			h: 130.13,
			l: 129.76,
			t: 1655406060000,
			n: 3129
		},
		{
			v: 208977,
			vw: 129.9813,
			o: 130.11,
			c: 129.9993,
			h: 130.117,
			l: 129.915,
			t: 1655406120000,
			n: 1762
		},
		{
			v: 170584,
			vw: 129.9376,
			o: 129.99,
			c: 130.0099,
			h: 130.02,
			l: 129.85,
			t: 1655406180000,
			n: 1756
		},
		{
			v: 285030,
			vw: 129.9944,
			o: 130.01,
			c: 129.88,
			h: 130.0984,
			l: 129.86,
			t: 1655406240000,
			n: 2693
		},
		{
			v: 219356,
			vw: 129.7879,
			o: 129.86,
			c: 129.7501,
			h: 129.919,
			l: 129.73,
			t: 1655406300000,
			n: 2351
		},
		{
			v: 267743,
			vw: 129.7273,
			o: 129.755,
			c: 129.8199,
			h: 129.85,
			l: 129.5908,
			t: 1655406360000,
			n: 2514
		},
		{
			v: 268014,
			vw: 129.7881,
			o: 129.815,
			c: 129.825,
			h: 129.87,
			l: 129.72,
			t: 1655406420000,
			n: 2140
		},
		{
			v: 221487,
			vw: 129.8029,
			o: 129.825,
			c: 129.86,
			h: 129.86,
			l: 129.75,
			t: 1655406480000,
			n: 2123
		},
		{
			v: 296637,
			vw: 129.9357,
			o: 129.85,
			c: 130.065,
			h: 130.09,
			l: 129.74,
			t: 1655406540000,
			n: 2894
		},
		{
			v: 321911,
			vw: 130.1174,
			o: 130.06,
			c: 130.03,
			h: 130.22,
			l: 129.95,
			t: 1655406600000,
			n: 2688
		},
		{
			v: 172076,
			vw: 129.9896,
			o: 130.0367,
			c: 129.9699,
			h: 130.06,
			l: 129.94,
			t: 1655406660000,
			n: 1793
		},
		{
			v: 206921,
			vw: 129.8907,
			o: 129.96,
			c: 129.88,
			h: 129.99,
			l: 129.82,
			t: 1655406720000,
			n: 2274
		},
		{
			v: 216077,
			vw: 129.7366,
			o: 129.87,
			c: 129.63,
			h: 129.87,
			l: 129.63,
			t: 1655406780000,
			n: 2491
		},
		{
			v: 221056,
			vw: 129.7711,
			o: 129.63,
			c: 129.76,
			h: 129.87,
			l: 129.59,
			t: 1655406840000,
			n: 2457
		},
		{
			v: 191567,
			vw: 129.6853,
			o: 129.75,
			c: 129.58,
			h: 129.75,
			l: 129.58,
			t: 1655406900000,
			n: 1976
		},
		{
			v: 183927,
			vw: 129.682,
			o: 129.59,
			c: 129.685,
			h: 129.75,
			l: 129.56,
			t: 1655406960000,
			n: 1996
		},
		{
			v: 203634,
			vw: 129.7658,
			o: 129.69,
			c: 129.87,
			h: 129.9,
			l: 129.67,
			t: 1655407020000,
			n: 1980
		},
		{
			v: 137715,
			vw: 129.7471,
			o: 129.855,
			c: 129.73,
			h: 129.86,
			l: 129.675,
			t: 1655407080000,
			n: 1624
		},
		{
			v: 289864,
			vw: 129.8035,
			o: 129.73,
			c: 129.87,
			h: 129.88,
			l: 129.71,
			t: 1655407140000,
			n: 2379
		},
		{
			v: 331607,
			vw: 129.9907,
			o: 129.865,
			c: 129.95,
			h: 130.08,
			l: 129.83,
			t: 1655407200000,
			n: 2874
		},
		{
			v: 197826,
			vw: 129.8899,
			o: 129.935,
			c: 129.8667,
			h: 129.96,
			l: 129.83,
			t: 1655407260000,
			n: 2065
		},
		{
			v: 171671,
			vw: 129.887,
			o: 129.8573,
			c: 129.84,
			h: 129.93,
			l: 129.825,
			t: 1655407320000,
			n: 1591
		},
		{
			v: 223025,
			vw: 129.9628,
			o: 129.835,
			c: 130.03,
			h: 130.07,
			l: 129.83,
			t: 1655407380000,
			n: 2111
		},
		{
			v: 182039,
			vw: 130.0332,
			o: 130.03,
			c: 129.975,
			h: 130.1,
			l: 129.97,
			t: 1655407440000,
			n: 1754
		},
		{
			v: 169915,
			vw: 129.9941,
			o: 129.98,
			c: 130.05,
			h: 130.07,
			l: 129.9208,
			t: 1655407500000,
			n: 1884
		},
		{
			v: 513581,
			vw: 130.0868,
			o: 130.0401,
			c: 130.1399,
			h: 130.14,
			l: 130.03,
			t: 1655407560000,
			n: 2420
		},
		{
			v: 271855,
			vw: 130.2221,
			o: 130.14,
			c: 130.1754,
			h: 130.3,
			l: 130.14,
			t: 1655407620000,
			n: 2316
		},
		{
			v: 208390,
			vw: 130.2385,
			o: 130.175,
			c: 130.14,
			h: 130.2998,
			l: 130.13,
			t: 1655407680000,
			n: 2083
		},
		{
			v: 209932,
			vw: 130.1213,
			o: 130.1401,
			c: 130.115,
			h: 130.165,
			l: 130.07,
			t: 1655407740000,
			n: 2092
		},
		{
			v: 307089,
			vw: 130.154,
			o: 130.115,
			c: 130.235,
			h: 130.28,
			l: 130.04,
			t: 1655407800000,
			n: 2792
		},
		{
			v: 211970,
			vw: 130.1779,
			o: 130.2301,
			c: 130.0435,
			h: 130.28,
			l: 130.02,
			t: 1655407860000,
			n: 2055
		},
		{
			v: 270557,
			vw: 129.8978,
			o: 130.04,
			c: 129.91,
			h: 130.045,
			l: 129.8061,
			t: 1655407920000,
			n: 2834
		},
		{
			v: 221670,
			vw: 129.8355,
			o: 129.91,
			c: 129.83,
			h: 129.92,
			l: 129.745,
			t: 1655407980000,
			n: 2285
		},
		{
			v: 307913,
			vw: 129.6185,
			o: 129.82,
			c: 129.54,
			h: 129.83,
			l: 129.5,
			t: 1655408040000,
			n: 3616
		},
		{
			v: 205440,
			vw: 129.6062,
			o: 129.53,
			c: 129.62,
			h: 129.64,
			l: 129.52,
			t: 1655408100000,
			n: 2101
		},
		{
			v: 252956,
			vw: 129.685,
			o: 129.62,
			c: 129.72,
			h: 129.76,
			l: 129.615,
			t: 1655408160000,
			n: 2378
		},
		{
			v: 283724,
			vw: 129.7303,
			o: 129.71,
			c: 129.6942,
			h: 129.8,
			l: 129.64,
			t: 1655408220000,
			n: 2870
		},
		{
			v: 199259,
			vw: 129.6453,
			o: 129.69,
			c: 129.7499,
			h: 129.7515,
			l: 129.57,
			t: 1655408280000,
			n: 2146
		},
		{
			v: 207619,
			vw: 129.7582,
			o: 129.745,
			c: 129.74,
			h: 129.8343,
			l: 129.67,
			t: 1655408340000,
			n: 2189
		},
		{
			v: 345037,
			vw: 129.9939,
			o: 129.74,
			c: 130.075,
			h: 130.145,
			l: 129.72,
			t: 1655408400000,
			n: 3140
		},
		{
			v: 292761,
			vw: 129.9241,
			o: 130.065,
			c: 129.6826,
			h: 130.1499,
			l: 129.66,
			t: 1655408460000,
			n: 2564
		},
		{
			v: 336652,
			vw: 129.6717,
			o: 129.68,
			c: 129.8,
			h: 129.8199,
			l: 129.58,
			t: 1655408520000,
			n: 2789
		},
		{
			v: 203212,
			vw: 129.724,
			o: 129.795,
			c: 129.67,
			h: 129.8,
			l: 129.67,
			t: 1655408580000,
			n: 2010
		},
		{
			v: 322808,
			vw: 129.5539,
			o: 129.66,
			c: 129.47,
			h: 129.66,
			l: 129.46,
			t: 1655408640000,
			n: 3152
		},
		{
			v: 1146605,
			vw: 129.4927,
			o: 129.47,
			c: 129.5771,
			h: 129.66,
			l: 129.3801,
			t: 1655408700000,
			n: 3292
		},
		{
			v: 376669,
			vw: 129.3994,
			o: 129.58,
			c: 129.3,
			h: 129.59,
			l: 129.28,
			t: 1655408760000,
			n: 3647
		},
		{
			v: 300348,
			vw: 129.3166,
			o: 129.29,
			c: 129.27,
			h: 129.39,
			l: 129.26,
			t: 1655408820000,
			n: 2935
		},
		{
			v: 408430,
			vw: 129.2486,
			o: 129.2691,
			c: 129.19,
			h: 129.39,
			l: 129.18,
			t: 1655408880000,
			n: 3175
		},
		{
			v: 402813,
			vw: 129.1454,
			o: 129.1821,
			c: 129.1947,
			h: 129.23,
			l: 129.07,
			t: 1655408940000,
			n: 3575
		},
		{
			v: 735719,
			vw: 129.3229,
			o: 129.2,
			c: 129.435,
			h: 129.46,
			l: 129.16,
			t: 1655409000000,
			n: 6151
		},
		{
			v: 385778,
			vw: 129.3458,
			o: 129.43,
			c: 129.242,
			h: 129.48,
			l: 129.24,
			t: 1655409060000,
			n: 3756
		},
		{
			v: 633072,
			vw: 129.4285,
			o: 129.245,
			c: 129.555,
			h: 129.77,
			l: 129.235,
			t: 1655409120000,
			n: 6236
		},
		{
			v: 410638,
			vw: 129.4841,
			o: 129.55,
			c: 129.4499,
			h: 129.5999,
			l: 129.37,
			t: 1655409180000,
			n: 4071
		},
		{
			v: 553109,
			vw: 129.3244,
			o: 129.45,
			c: 129.48,
			h: 129.48,
			l: 129.04,
			t: 1655409240000,
			n: 6084
		},
		{
			v: 554729,
			vw: 129.4143,
			o: 129.49,
			c: 129.43,
			h: 129.51,
			l: 129.31,
			t: 1655409300000,
			n: 5105
		},
		{
			v: 566815,
			vw: 129.5576,
			o: 129.425,
			c: 129.59,
			h: 129.69,
			l: 129.42,
			t: 1655409360000,
			n: 4825
		},
		{
			v: 588848,
			vw: 129.7014,
			o: 129.585,
			c: 129.78,
			h: 129.83,
			l: 129.55,
			t: 1655409420000,
			n: 4570
		},
		{
			v: 777168,
			vw: 129.7813,
			o: 129.79,
			c: 129.83,
			h: 129.89,
			l: 129.65,
			t: 1655409480000,
			n: 5986
		},
		{
			v: 2046870,
			vw: 129.8477,
			o: 129.83,
			c: 130.1,
			h: 130.12,
			l: 129.58,
			t: 1655409540000,
			n: 13660
		},
		{
			v: 258431,
			vw: 130.0868,
			o: 130.1,
			c: 130.16,
			h: 130.57,
			l: 130.05,
			t: 1655409600000,
			n: 370
		},
		{
			v: 25390,
			vw: 130.257,
			o: 130.24,
			c: 130.15,
			h: 130.4,
			l: 130.15,
			t: 1655409660000,
			n: 252
		},
		{
			v: 1187482,
			vw: 130.0639,
			o: 130.22,
			c: 130.29,
			h: 130.32,
			l: 130.06,
			t: 1655409720000,
			n: 184
		},
		{
			v: 516995,
			vw: 130.0668,
			o: 130.29,
			c: 130.2,
			h: 130.31,
			l: 130.06,
			t: 1655409780000,
			n: 153
		},
		{
			v: 21608,
			vw: 130.2244,
			o: 130.2,
			c: 130.3,
			h: 130.3,
			l: 130.06,
			t: 1655409840000,
			n: 424
		},
		{
			v: 21850,
			vw: 130.2614,
			o: 130.3,
			c: 130.25,
			h: 130.31,
			l: 130.23,
			t: 1655409900000,
			n: 173
		},
		{
			v: 373174,
			vw: 130.0727,
			o: 130.26,
			c: 130.34,
			h: 130.45,
			l: 130.06,
			t: 1655409960000,
			n: 212
		},
		{
			v: 535507,
			vw: 130.1281,
			o: 130.39,
			c: 130.25,
			h: 131.195,
			l: 130.06,
			t: 1655410020000,
			n: 87
		},
		{
			v: 4670,
			vw: 130.2999,
			o: 130.25,
			c: 130.3,
			h: 130.35,
			l: 130.25,
			t: 1655410080000,
			n: 141
		},
		{
			v: 24592,
			vw: 130.2048,
			o: 130.26,
			c: 130.15,
			h: 130.28,
			l: 130.11,
			t: 1655410140000,
			n: 255
		},
		{
			v: 10391,
			vw: 130.1035,
			o: 130.14,
			c: 130.09,
			h: 130.14,
			l: 130.07,
			t: 1655410200000,
			n: 123
		},
		{
			v: 4264,
			vw: 130.1517,
			o: 130.17,
			c: 130.13,
			h: 130.2,
			l: 130.1,
			t: 1655410260000,
			n: 70
		},
		{
			v: 18092,
			vw: 130.1711,
			o: 130.12,
			c: 130.23,
			h: 130.25,
			l: 130.1,
			t: 1655410320000,
			n: 88
		},
		{
			v: 9585,
			vw: 130.2396,
			o: 130.22,
			c: 130.31,
			h: 130.31,
			l: 130.2,
			t: 1655410380000,
			n: 102
		},
		{
			v: 15838,
			vw: 130.3814,
			o: 130.33,
			c: 130.35,
			h: 130.46,
			l: 130.3,
			t: 1655410440000,
			n: 193
		},
		{
			v: 9227,
			vw: 130.4389,
			o: 130.41,
			c: 130.4,
			h: 130.49,
			l: 130.36,
			t: 1655410500000,
			n: 107
		},
		{
			v: 8961,
			vw: 130.3506,
			o: 130.37,
			c: 130.33,
			h: 130.37,
			l: 130.3,
			t: 1655410560000,
			n: 94
		},
		{
			v: 11744,
			vw: 130.358,
			o: 130.37,
			c: 130.36,
			h: 130.4,
			l: 130.33,
			t: 1655410620000,
			n: 74
		},
		{
			v: 1960,
			vw: 130.3376,
			o: 130.32,
			c: 130.3401,
			h: 130.35,
			l: 130.31,
			t: 1655410680000,
			n: 68
		},
		{
			v: 10608,
			vw: 130.3194,
			o: 130.32,
			c: 130.32,
			h: 130.35,
			l: 130.26,
			t: 1655410740000,
			n: 113
		},
		{
			v: 15626,
			vw: 130.2873,
			o: 130.31,
			c: 130.25,
			h: 130.33,
			l: 130.25,
			t: 1655410800000,
			n: 105
		},
		{
			v: 104193,
			vw: 130.0625,
			o: 130.2899,
			c: 130.24,
			h: 130.29,
			l: 130.06,
			t: 1655410860000,
			n: 43
		},
		{
			v: 102499,
			vw: 130.0742,
			o: 130.25,
			c: 130.32,
			h: 130.32,
			l: 130.06,
			t: 1655410920000,
			n: 64
		},
		{
			v: 1171,
			vw: 130.2798,
			o: 130.31,
			c: 130.29,
			h: 130.31,
			l: 130.27,
			t: 1655410980000,
			n: 56
		},
		{
			v: 2664,
			vw: 130.2962,
			o: 130.27,
			c: 130.2853,
			h: 130.3,
			l: 130.27,
			t: 1655411040000,
			n: 35
		},
		{
			v: 2026,
			vw: 130.2892,
			o: 130.3,
			c: 130.28,
			h: 130.3,
			l: 130.28,
			t: 1655411100000,
			n: 58
		},
		{
			v: 6511,
			vw: 130.3172,
			o: 130.32,
			c: 130.39,
			h: 130.39,
			l: 130.26,
			t: 1655411160000,
			n: 90
		},
		{
			v: 102628,
			vw: 130.0668,
			o: 130.39,
			c: 130.34,
			h: 130.39,
			l: 130.25,
			t: 1655411220000,
			n: 73
		},
		{
			v: 2732,
			vw: 130.3639,
			o: 130.35,
			c: 130.37,
			h: 130.38,
			l: 130.35,
			t: 1655411280000,
			n: 68
		},
		{
			v: 1617,
			vw: 130.3649,
			o: 130.36,
			c: 130.37,
			h: 130.4,
			l: 130.36,
			t: 1655411340000,
			n: 80
		},
		{
			v: 3547,
			vw: 130.3404,
			o: 130.39,
			c: 130.3,
			h: 130.44,
			l: 130.06,
			t: 1655411400000,
			n: 81
		},
		{
			v: 1707,
			vw: 130.3538,
			o: 130.35,
			c: 130.35,
			h: 130.41,
			l: 130.35,
			t: 1655411460000,
			n: 59
		},
		{
			v: 2738,
			vw: 130.3548,
			o: 130.34,
			c: 130.4,
			h: 130.4,
			l: 130.34,
			t: 1655411520000,
			n: 51
		},
		{
			v: 2484,
			vw: 130.356,
			o: 130.35,
			c: 130.35,
			h: 130.36,
			l: 130.35,
			t: 1655411580000,
			n: 80
		},
		{
			v: 890,
			vw: 130.3821,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655411640000,
			n: 53
		},
		{
			v: 157511,
			vw: 130.0664,
			o: 130.35,
			c: 130.3501,
			h: 130.3501,
			l: 130.06,
			t: 1655411700000,
			n: 52
		},
		{
			v: 3828,
			vw: 130.3517,
			o: 130.35,
			c: 130.35,
			h: 130.36,
			l: 130.35,
			t: 1655411760000,
			n: 70
		},
		{
			v: 1599,
			vw: 130.3638,
			o: 130.3879,
			c: 130.35,
			h: 130.3879,
			l: 130.35,
			t: 1655411820000,
			n: 40
		},
		{
			v: 8948,
			vw: 130.3324,
			o: 130.33,
			c: 130.35,
			h: 130.35,
			l: 130.3,
			t: 1655411880000,
			n: 76
		},
		{
			v: 4119,
			vw: 130.3552,
			o: 130.39,
			c: 130.35,
			h: 130.39,
			l: 130.34,
			t: 1655411940000,
			n: 80
		},
		{
			v: 3131,
			vw: 130.343,
			o: 130.38,
			c: 130.32,
			h: 130.38,
			l: 130.32,
			t: 1655412000000,
			n: 48
		},
		{
			v: 9406,
			vw: 130.342,
			o: 130.35,
			c: 130.35,
			h: 130.4,
			l: 130.3,
			t: 1655412060000,
			n: 107
		},
		{
			v: 1739,
			vw: 130.3561,
			o: 130.35,
			c: 130.35,
			h: 130.39,
			l: 130.35,
			t: 1655412120000,
			n: 43
		},
		{
			v: 1474,
			vw: 130.3783,
			o: 130.38,
			c: 130.39,
			h: 130.39,
			l: 130.36,
			t: 1655412180000,
			n: 35
		},
		{
			v: 1718,
			vw: 130.3856,
			o: 130.38,
			c: 130.39,
			h: 130.39,
			l: 130.38,
			t: 1655412240000,
			n: 43
		},
		{
			v: 2982,
			vw: 130.3802,
			o: 130.37,
			c: 130.39,
			h: 130.39,
			l: 130.37,
			t: 1655412300000,
			n: 38
		},
		{
			v: 3696,
			vw: 130.4001,
			o: 130.38,
			c: 130.38,
			h: 130.42,
			l: 130.38,
			t: 1655412360000,
			n: 63
		},
		{
			v: 74649,
			vw: 130.0795,
			o: 130.4,
			c: 130.46,
			h: 130.46,
			l: 130.06,
			t: 1655412420000,
			n: 58
		},
		{
			v: 2144,
			vw: 130.4768,
			o: 130.47,
			c: 130.49,
			h: 130.5,
			l: 130.43,
			t: 1655412480000,
			n: 57
		},
		{
			v: 2355,
			vw: 130.4663,
			o: 130.49,
			c: 130.45,
			h: 130.49,
			l: 130.45,
			t: 1655412540000,
			n: 58
		},
		{
			v: 1080,
			vw: 130.4754,
			o: 130.49,
			c: 130.49,
			h: 130.49,
			l: 130.48,
			t: 1655412600000,
			n: 45
		},
		{
			v: 5799,
			vw: 130.4348,
			o: 130.46,
			c: 130.45,
			h: 130.46,
			l: 130.41,
			t: 1655412660000,
			n: 86
		},
		{
			v: 6970,
			vw: 130.4467,
			o: 130.43,
			c: 130.42,
			h: 130.45,
			l: 130.42,
			t: 1655412720000,
			n: 42
		},
		{
			v: 5775,
			vw: 130.4875,
			o: 130.43,
			c: 130.4864,
			h: 130.55,
			l: 130.43,
			t: 1655412780000,
			n: 109
		},
		{
			v: 2376,
			vw: 130.5256,
			o: 130.52,
			c: 130.51,
			h: 130.55,
			l: 130.5062,
			t: 1655412840000,
			n: 53
		},
		{
			v: 2009,
			vw: 130.5473,
			o: 130.49,
			c: 130.58,
			h: 130.58,
			l: 130.49,
			t: 1655412900000,
			n: 67
		},
		{
			v: 1139,
			vw: 130.5275,
			o: 130.55,
			c: 130.55,
			h: 130.57,
			l: 130.55,
			t: 1655412960000,
			n: 61
		},
		{
			v: 1335,
			vw: 130.5208,
			o: 130.53,
			c: 130.54,
			h: 130.54,
			l: 130.52,
			t: 1655413020000,
			n: 53
		},
		{
			v: 2986,
			vw: 130.5184,
			o: 130.5,
			c: 130.5,
			h: 130.54,
			l: 130.5,
			t: 1655413080000,
			n: 54
		},
		{
			v: 1472,
			vw: 130.5122,
			o: 130.51,
			c: 130.55,
			h: 130.55,
			l: 130.49,
			t: 1655413140000,
			n: 44
		},
		{
			v: 1354,
			vw: 130.493,
			o: 130.5,
			c: 130.5,
			h: 130.51,
			l: 130.5,
			t: 1655413200000,
			n: 47
		},
		{
			v: 1461,
			vw: 130.4486,
			o: 130.45,
			c: 130.45,
			h: 130.45,
			l: 130.45,
			t: 1655413320000,
			n: 54
		},
		{
			v: 3127,
			vw: 130.4326,
			o: 130.42,
			c: 130.5,
			h: 130.5,
			l: 130.41,
			t: 1655413380000,
			n: 61
		},
		{
			v: 1050,
			vw: 130.4778,
			o: 130.46,
			c: 130.45,
			h: 130.46,
			l: 130.45,
			t: 1655413440000,
			n: 47
		},
		{
			v: 547,
			vw: 130.4916,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.5,
			t: 1655413500000,
			n: 44
		},
		{
			v: 4724,
			vw: 130.4889,
			o: 130.5,
			c: 130.45,
			h: 130.53,
			l: 130.45,
			t: 1655413560000,
			n: 69
		},
		{
			v: 929,
			vw: 130.4709,
			o: 130.45,
			c: 130.48,
			h: 130.48,
			l: 130.45,
			t: 1655413620000,
			n: 33
		},
		{
			v: 1089,
			vw: 130.4691,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655413680000,
			n: 34
		},
		{
			v: 816,
			vw: 130.4613,
			o: 130.46,
			c: 130.48,
			h: 130.48,
			l: 130.46,
			t: 1655413800000,
			n: 41
		},
		{
			v: 377,
			vw: 130.4776,
			o: 130.48,
			c: 130.48,
			h: 130.48,
			l: 130.48,
			t: 1655413860000,
			n: 27
		},
		{
			v: 1576,
			vw: 130.4907,
			o: 130.48,
			c: 130.5,
			h: 130.5,
			l: 130.48,
			t: 1655413920000,
			n: 53
		},
		{
			v: 904,
			vw: 130.4988,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.5,
			t: 1655413980000,
			n: 22
		},
		{
			v: 2874,
			vw: 130.4668,
			o: 130.49,
			c: 130.41,
			h: 130.51,
			l: 130.41,
			t: 1655414100000,
			n: 80
		},
		{
			v: 2158,
			vw: 130.4243,
			o: 130.44,
			c: 130.45,
			h: 130.45,
			l: 130.41,
			t: 1655414160000,
			n: 36
		},
		{
			v: 1766,
			vw: 130.4886,
			o: 130.49,
			c: 130.49,
			h: 130.49,
			l: 130.49,
			t: 1655414220000,
			n: 35
		},
		{
			v: 604,
			vw: 130.4476,
			o: 130.44,
			c: 130.43,
			h: 130.44,
			l: 130.43,
			t: 1655414280000,
			n: 21
		},
		{
			v: 1522,
			vw: 130.4712,
			o: 130.48,
			c: 130.44,
			h: 130.48,
			l: 130.44,
			t: 1655414340000,
			n: 40
		},
		{
			v: 249,
			vw: 130.4172,
			o: 130.4001,
			c: 130.4001,
			h: 130.4001,
			l: 130.4001,
			t: 1655414400000,
			n: 34
		},
		{
			v: 445,
			vw: 130.4392,
			o: 130.43,
			c: 130.43,
			h: 130.43,
			l: 130.43,
			t: 1655414460000,
			n: 30
		},
		{
			v: 9845,
			vw: 130.2302,
			o: 130.42,
			c: 130.4,
			h: 130.42,
			l: 130.4,
			t: 1655414520000,
			n: 80
		},
		{
			v: 994,
			vw: 130.399,
			o: 130.36,
			c: 130.41,
			h: 130.43,
			l: 130.36,
			t: 1655414580000,
			n: 45
		},
		{
			v: 1076,
			vw: 130.3954,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655414640000,
			n: 40
		},
		{
			v: 1994,
			vw: 130.4477,
			o: 130.44,
			c: 130.48,
			h: 130.48,
			l: 130.44,
			t: 1655414700000,
			n: 38
		},
		{
			v: 683,
			vw: 130.449,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655414760000,
			n: 45
		},
		{
			v: 731,
			vw: 130.4602,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655414820000,
			n: 37
		},
		{
			v: 2477,
			vw: 130.4528,
			o: 130.47,
			c: 130.44,
			h: 130.47,
			l: 130.44,
			t: 1655414880000,
			n: 31
		},
		{
			v: 6581,
			vw: 130.4321,
			o: 130.44,
			c: 130.47,
			h: 130.47,
			l: 130.38,
			t: 1655414940000,
			n: 79
		},
		{
			v: 1791,
			vw: 130.468,
			o: 130.46,
			c: 130.47,
			h: 130.48,
			l: 130.46,
			t: 1655415000000,
			n: 40
		},
		{
			v: 659,
			vw: 130.4348,
			o: 130.42,
			c: 130.42,
			h: 130.42,
			l: 130.42,
			t: 1655415120000,
			n: 39
		},
		{
			v: 801,
			vw: 130.3866,
			o: 130.4,
			c: 130.37,
			h: 130.44,
			l: 130.37,
			t: 1655415180000,
			n: 51
		},
		{
			v: 447,
			vw: 130.3913,
			o: 130.36,
			c: 130.36,
			h: 130.36,
			l: 130.36,
			t: 1655415240000,
			n: 43
		},
		{
			v: 2513,
			vw: 130.379,
			o: 130.36,
			c: 130.39,
			h: 130.39,
			l: 130.36,
			t: 1655415300000,
			n: 41
		},
		{
			v: 4570,
			vw: 130.3707,
			o: 130.36,
			c: 130.35,
			h: 130.47,
			l: 130.35,
			t: 1655415420000,
			n: 50
		},
		{
			v: 1749,
			vw: 130.45,
			o: 130.44,
			c: 130.46,
			h: 130.46,
			l: 130.44,
			t: 1655415600000,
			n: 45
		},
		{
			v: 663,
			vw: 130.3964,
			o: 130.38,
			c: 130.38,
			h: 130.38,
			l: 130.38,
			t: 1655415660000,
			n: 30
		},
		{
			v: 4161,
			vw: 130.3821,
			o: 130.39,
			c: 130.44,
			h: 130.44,
			l: 130.33,
			t: 1655415720000,
			n: 75
		},
		{
			v: 651,
			vw: 130.3485,
			o: 130.34,
			c: 130.33,
			h: 130.34,
			l: 130.33,
			t: 1655415780000,
			n: 27
		},
		{
			v: 1170,
			vw: 130.3551,
			o: 130.34,
			c: 130.34,
			h: 130.34,
			l: 130.34,
			t: 1655415900000,
			n: 40
		},
		{
			v: 695,
			vw: 130.3669,
			o: 130.4,
			c: 130.35,
			h: 130.4,
			l: 130.35,
			t: 1655415960000,
			n: 35
		},
		{
			v: 6630,
			vw: 130.412,
			o: 130.42,
			c: 130.4,
			h: 130.44,
			l: 130.35,
			t: 1655416020000,
			n: 63
		},
		{
			v: 486,
			vw: 130.4038,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655416080000,
			n: 25
		},
		{
			v: 780,
			vw: 130.3907,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655416140000,
			n: 26
		},
		{
			v: 705,
			vw: 130.3762,
			o: 130.36,
			c: 130.35,
			h: 130.36,
			l: 130.35,
			t: 1655416200000,
			n: 40
		},
		{
			v: 934,
			vw: 130.4114,
			o: 130.43,
			c: 130.43,
			h: 130.43,
			l: 130.43,
			t: 1655416260000,
			n: 32
		},
		{
			v: 1173,
			vw: 130.3715,
			o: 130.4,
			c: 130.35,
			h: 130.4,
			l: 130.35,
			t: 1655416320000,
			n: 55
		},
		{
			v: 1328,
			vw: 130.3961,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655416380000,
			n: 26
		},
		{
			v: 2289,
			vw: 130.4418,
			o: 130.43,
			c: 130.47,
			h: 130.47,
			l: 130.43,
			t: 1655416440000,
			n: 39
		},
		{
			v: 1007,
			vw: 130.4655,
			o: 130.47,
			c: 130.48,
			h: 130.48,
			l: 130.47,
			t: 1655416560000,
			n: 31
		},
		{
			v: 171,
			vw: 130.4552,
			o: 130.45,
			c: 130.45,
			h: 130.45,
			l: 130.45,
			t: 1655416620000,
			n: 17
		},
		{
			v: 317,
			vw: 130.4743,
			o: 130.48,
			c: 130.48,
			h: 130.48,
			l: 130.48,
			t: 1655416680000,
			n: 16
		},
		{
			v: 1979,
			vw: 130.474,
			o: 130.46,
			c: 130.48,
			h: 130.48,
			l: 130.46,
			t: 1655416740000,
			n: 50
		},
		{
			v: 16446,
			vw: 130.6091,
			o: 130.48,
			c: 130.7,
			h: 130.7,
			l: 130.48,
			t: 1655416800000,
			n: 124
		},
		{
			v: 1753,
			vw: 130.6268,
			o: 130.7,
			c: 130.6,
			h: 130.7,
			l: 130.6,
			t: 1655416860000,
			n: 37
		},
		{
			v: 394,
			vw: 130.6048,
			o: 130.6,
			c: 130.6,
			h: 130.6,
			l: 130.6,
			t: 1655416920000,
			n: 24
		},
		{
			v: 956,
			vw: 130.6062,
			o: 130.6,
			c: 130.6,
			h: 130.6,
			l: 130.6,
			t: 1655416980000,
			n: 26
		},
		{
			v: 4835,
			vw: 130.6874,
			o: 130.69,
			c: 130.7,
			h: 130.7,
			l: 130.68,
			t: 1655417040000,
			n: 33
		},
		{
			v: 5877,
			vw: 130.7579,
			o: 130.7,
			c: 130.75,
			h: 130.8,
			l: 130.7,
			t: 1655417100000,
			n: 58
		},
		{
			v: 1713,
			vw: 130.695,
			o: 130.7,
			c: 130.7,
			h: 130.7,
			l: 130.68,
			t: 1655417160000,
			n: 33
		},
		{
			v: 1651,
			vw: 130.6556,
			o: 130.67,
			c: 130.64,
			h: 130.7,
			l: 130.63,
			t: 1655417220000,
			n: 31
		},
		{
			v: 2287,
			vw: 130.6031,
			o: 130.61,
			c: 130.56,
			h: 130.61,
			l: 130.56,
			t: 1655417280000,
			n: 30
		},
		{
			v: 648,
			vw: 130.5839,
			o: 130.57,
			c: 130.57,
			h: 130.57,
			l: 130.57,
			t: 1655417400000,
			n: 18
		},
		{
			v: 14276,
			vw: 130.5167,
			o: 130.67,
			c: 130.5,
			h: 130.67,
			l: 130.5,
			t: 1655417460000,
			n: 80
		},
		{
			v: 336,
			vw: 130.4588,
			o: 130.45,
			c: 130.45,
			h: 130.45,
			l: 130.45,
			t: 1655417520000,
			n: 26
		},
		{
			v: 1976,
			vw: 130.4727,
			o: 130.48,
			c: 130.44,
			h: 130.48,
			l: 130.44,
			t: 1655417580000,
			n: 31
		},
		{
			v: 1836,
			vw: 130.4264,
			o: 130.4,
			c: 130.44,
			h: 130.44,
			l: 130.4,
			t: 1655417640000,
			n: 25
		},
		{
			v: 994,
			vw: 130.4228,
			o: 130.44,
			c: 130.4,
			h: 130.45,
			l: 130.4,
			t: 1655417700000,
			n: 15
		},
		{
			v: 1980,
			vw: 130.4717,
			o: 130.45,
			c: 130.5,
			h: 130.5,
			l: 130.45,
			t: 1655417760000,
			n: 30
		},
		{
			v: 272,
			vw: 130.488,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.5,
			t: 1655417820000,
			n: 16
		},
		{
			v: 6185,
			vw: 130.4966,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.45,
			t: 1655417880000,
			n: 42
		},
		{
			v: 2648,
			vw: 130.4995,
			o: 130.5,
			c: 130.49,
			h: 130.51,
			l: 130.49,
			t: 1655417940000,
			n: 33
		},
		{
			v: 958,
			vw: 130.5037,
			o: 130.5,
			c: 130.51,
			h: 130.51,
			l: 130.5,
			t: 1655418000000,
			n: 34
		},
		{
			v: 1035,
			vw: 130.5295,
			o: 130.5299,
			c: 130.54,
			h: 130.54,
			l: 130.5299,
			t: 1655418060000,
			n: 26
		},
		{
			v: 2028,
			vw: 130.524,
			o: 130.54,
			c: 130.5,
			h: 130.54,
			l: 130.5,
			t: 1655418120000,
			n: 23
		},
		{
			v: 374,
			vw: 130.518,
			o: 130.51,
			c: 130.51,
			h: 130.51,
			l: 130.51,
			t: 1655418180000,
			n: 19
		},
		{
			v: 913,
			vw: 130.5425,
			o: 130.52,
			c: 130.57,
			h: 130.57,
			l: 130.52,
			t: 1655418240000,
			n: 22
		},
		{
			v: 1158,
			vw: 130.5183,
			o: 130.52,
			c: 130.51,
			h: 130.52,
			l: 130.51,
			t: 1655418300000,
			n: 16
		},
		{
			v: 774,
			vw: 130.5647,
			o: 130.55,
			c: 130.6,
			h: 130.6,
			l: 130.55,
			t: 1655418420000,
			n: 35
		},
		{
			v: 377,
			vw: 130.5463,
			o: 130.55,
			c: 130.55,
			h: 130.55,
			l: 130.55,
			t: 1655418540000,
			n: 16
		},
		{
			v: 1702,
			vw: 130.5268,
			o: 130.55,
			c: 130.51,
			h: 130.55,
			l: 130.51,
			t: 1655418600000,
			n: 20
		},
		{
			v: 558,
			vw: 130.5551,
			o: 130.55,
			c: 130.55,
			h: 130.56,
			l: 130.55,
			t: 1655418660000,
			n: 23
		},
		{
			v: 345,
			vw: 130.5513,
			o: 130.55,
			c: 130.55,
			h: 130.55,
			l: 130.55,
			t: 1655418720000,
			n: 17
		},
		{
			v: 476,
			vw: 130.5266,
			o: 130.52,
			c: 130.53,
			h: 130.53,
			l: 130.52,
			t: 1655418780000,
			n: 12
		},
		{
			v: 803,
			vw: 130.5301,
			o: 130.53,
			c: 130.53,
			h: 130.53,
			l: 130.53,
			t: 1655418900000,
			n: 20
		},
		{
			v: 354,
			vw: 130.5,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.5,
			t: 1655418960000,
			n: 29
		},
		{
			v: 721,
			vw: 130.4927,
			o: 130.5,
			c: 130.49,
			h: 130.5,
			l: 130.49,
			t: 1655419020000,
			n: 11
		},
		{
			v: 619,
			vw: 130.5364,
			o: 130.54,
			c: 130.55,
			h: 130.55,
			l: 130.54,
			t: 1655419080000,
			n: 31
		},
		{
			v: 2317,
			vw: 130.5231,
			o: 130.52,
			c: 130.57,
			h: 130.57,
			l: 130.52,
			t: 1655419140000,
			n: 25
		},
		{
			v: 330,
			vw: 130.5539,
			o: 130.56,
			c: 130.56,
			h: 130.56,
			l: 130.56,
			t: 1655419200000,
			n: 20
		},
		{
			v: 1019,
			vw: 130.5638,
			o: 130.57,
			c: 130.56,
			h: 130.57,
			l: 130.56,
			t: 1655419260000,
			n: 15
		},
		{
			v: 1395,
			vw: 130.5514,
			o: 130.53,
			c: 130.5599,
			h: 130.5599,
			l: 130.53,
			t: 1655419320000,
			n: 17
		},
		{
			v: 253,
			vw: 130.5555,
			o: 130.55,
			c: 130.55,
			h: 130.55,
			l: 130.55,
			t: 1655419380000,
			n: 15
		},
		{
			v: 493,
			vw: 130.517,
			o: 130.5,
			c: 130.53,
			h: 130.53,
			l: 130.5,
			t: 1655419440000,
			n: 21
		},
		{
			v: 276,
			vw: 130.5246,
			o: 130.52,
			c: 130.52,
			h: 130.52,
			l: 130.52,
			t: 1655419500000,
			n: 13
		},
		{
			v: 725,
			vw: 130.537,
			o: 130.53,
			c: 130.55,
			h: 130.55,
			l: 130.53,
			t: 1655419560000,
			n: 14
		},
		{
			v: 477,
			vw: 130.5416,
			o: 130.54,
			c: 130.54,
			h: 130.54,
			l: 130.54,
			t: 1655419620000,
			n: 10
		},
		{
			v: 901,
			vw: 130.5261,
			o: 130.53,
			c: 130.52,
			h: 130.53,
			l: 130.52,
			t: 1655419680000,
			n: 17
		},
		{
			v: 8102,
			vw: 130.5115,
			o: 130.51,
			c: 130.5,
			h: 130.52,
			l: 130.5,
			t: 1655419740000,
			n: 35
		},
		{
			v: 291,
			vw: 130.504,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.5,
			t: 1655419800000,
			n: 21
		},
		{
			v: 3658,
			vw: 130.5105,
			o: 130.52,
			c: 130.5,
			h: 130.52,
			l: 130.5,
			t: 1655419860000,
			n: 23
		},
		{
			v: 940,
			vw: 130.5102,
			o: 130.51,
			c: 130.51,
			h: 130.51,
			l: 130.51,
			t: 1655419920000,
			n: 20
		},
		{
			v: 393,
			vw: 130.5204,
			o: 130.51,
			c: 130.53,
			h: 130.53,
			l: 130.51,
			t: 1655419980000,
			n: 27
		},
		{
			v: 394,
			vw: 130.5314,
			o: 130.53,
			c: 130.53,
			h: 130.53,
			l: 130.53,
			t: 1655420040000,
			n: 24
		},
		{
			v: 2192,
			vw: 130.5169,
			o: 130.52,
			c: 130.51,
			h: 130.52,
			l: 130.51,
			t: 1655420100000,
			n: 28
		},
		{
			v: 955,
			vw: 130.4848,
			o: 130.48,
			c: 130.48,
			h: 130.5,
			l: 130.48,
			t: 1655420160000,
			n: 21
		},
		{
			v: 182,
			vw: 130.4713,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655420220000,
			n: 8
		},
		{
			v: 1435,
			vw: 130.4747,
			o: 130.48,
			c: 130.47,
			h: 130.48,
			l: 130.47,
			t: 1655420280000,
			n: 23
		},
		{
			v: 1778,
			vw: 130.4677,
			o: 130.47,
			c: 130.46,
			h: 130.48,
			l: 130.46,
			t: 1655420340000,
			n: 28
		},
		{
			v: 1590,
			vw: 130.4507,
			o: 130.45,
			c: 130.45,
			h: 130.47,
			l: 130.44,
			t: 1655420400000,
			n: 28
		},
		{
			v: 673,
			vw: 130.4567,
			o: 130.46,
			c: 130.45,
			h: 130.46,
			l: 130.45,
			t: 1655420460000,
			n: 20
		},
		{
			v: 1515,
			vw: 130.4586,
			o: 130.48,
			c: 130.45,
			h: 130.48,
			l: 130.45,
			t: 1655420520000,
			n: 26
		},
		{
			v: 724,
			vw: 130.4813,
			o: 130.48,
			c: 130.48,
			h: 130.48,
			l: 130.48,
			t: 1655420580000,
			n: 18
		},
		{
			v: 3493,
			vw: 130.4695,
			o: 130.47,
			c: 130.46,
			h: 130.47,
			l: 130.46,
			t: 1655420640000,
			n: 30
		},
		{
			v: 161,
			vw: 130.4696,
			o: 130.46,
			c: 130.46,
			h: 130.46,
			l: 130.46,
			t: 1655420700000,
			n: 20
		},
		{
			v: 416,
			vw: 130.4655,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655420760000,
			n: 11
		},
		{
			v: 775,
			vw: 130.4653,
			o: 130.46,
			c: 130.46,
			h: 130.46,
			l: 130.46,
			t: 1655420880000,
			n: 21
		},
		{
			v: 469,
			vw: 130.488,
			o: 130.49,
			c: 130.5,
			h: 130.5,
			l: 130.49,
			t: 1655420940000,
			n: 27
		},
		{
			v: 1028,
			vw: 130.4825,
			o: 130.5,
			c: 130.5,
			h: 130.5,
			l: 130.4701,
			t: 1655421000000,
			n: 32
		},
		{
			v: 303,
			vw: 130.4678,
			o: 130.47,
			c: 130.47,
			h: 130.47,
			l: 130.47,
			t: 1655421120000,
			n: 21
		},
		{
			v: 1740,
			vw: 130.4371,
			o: 130.47,
			c: 130.42,
			h: 130.47,
			l: 130.42,
			t: 1655421240000,
			n: 29
		},
		{
			v: 1668,
			vw: 130.4438,
			o: 130.42,
			c: 130.45,
			h: 130.45,
			l: 130.42,
			t: 1655421360000,
			n: 31
		},
		{
			v: 839,
			vw: 130.4336,
			o: 130.45,
			c: 130.42,
			h: 130.45,
			l: 130.42,
			t: 1655421420000,
			n: 29
		},
		{
			v: 3095,
			vw: 130.4028,
			o: 130.41,
			c: 130.39,
			h: 130.43,
			l: 130.39,
			t: 1655421480000,
			n: 40
		},
		{
			v: 721,
			vw: 130.3799,
			o: 130.38,
			c: 130.38,
			h: 130.38,
			l: 130.38,
			t: 1655421540000,
			n: 27
		},
		{
			v: 8019,
			vw: 130.3071,
			o: 130.36,
			c: 130.25,
			h: 130.36,
			l: 130.25,
			t: 1655421600000,
			n: 90
		},
		{
			v: 1149,
			vw: 130.2532,
			o: 130.25,
			c: 130.27,
			h: 130.27,
			l: 130.25,
			t: 1655421660000,
			n: 19
		},
		{
			v: 1545,
			vw: 130.2585,
			o: 130.26,
			c: 130.25,
			h: 130.26,
			l: 130.25,
			t: 1655421720000,
			n: 28
		},
		{
			v: 1889,
			vw: 130.2229,
			o: 130.25,
			c: 130.21,
			h: 130.25,
			l: 130.21,
			t: 1655421780000,
			n: 43
		},
		{
			v: 4252,
			vw: 130.2015,
			o: 130.24,
			c: 130.2,
			h: 130.24,
			l: 130.2,
			t: 1655421840000,
			n: 25
		},
		{
			v: 922,
			vw: 130.2373,
			o: 130.24,
			c: 130.25,
			h: 130.25,
			l: 130.24,
			t: 1655421900000,
			n: 22
		},
		{
			v: 2206,
			vw: 130.222,
			o: 130.22,
			c: 130.22,
			h: 130.22,
			l: 130.22,
			t: 1655421960000,
			n: 23
		},
		{
			v: 1479,
			vw: 130.2517,
			o: 130.24,
			c: 130.25,
			h: 130.25,
			l: 130.24,
			t: 1655422020000,
			n: 25
		},
		{
			v: 1761,
			vw: 130.2526,
			o: 130.25,
			c: 130.26,
			h: 130.26,
			l: 130.25,
			t: 1655422080000,
			n: 22
		},
		{
			v: 335,
			vw: 130.255,
			o: 130.26,
			c: 130.26,
			h: 130.26,
			l: 130.26,
			t: 1655422140000,
			n: 17
		},
		{
			v: 973,
			vw: 130.2585,
			o: 130.25,
			c: 130.26,
			h: 130.27,
			l: 130.25,
			t: 1655422200000,
			n: 27
		},
		{
			v: 1096,
			vw: 130.2855,
			o: 130.26,
			c: 130.3,
			h: 130.3,
			l: 130.26,
			t: 1655422260000,
			n: 22
		},
		{
			v: 413,
			vw: 130.3147,
			o: 130.33,
			c: 130.31,
			h: 130.33,
			l: 130.31,
			t: 1655422320000,
			n: 18
		},
		{
			v: 719,
			vw: 130.3372,
			o: 130.33,
			c: 130.35,
			h: 130.35,
			l: 130.33,
			t: 1655422380000,
			n: 21
		},
		{
			v: 1265,
			vw: 130.317,
			o: 130.32,
			c: 130.3,
			h: 130.32,
			l: 130.3,
			t: 1655422440000,
			n: 18
		},
		{
			v: 553,
			vw: 130.3015,
			o: 130.3,
			c: 130.3,
			h: 130.3,
			l: 130.3,
			t: 1655422500000,
			n: 22
		},
		{
			v: 1384,
			vw: 130.287,
			o: 130.29,
			c: 130.31,
			h: 130.31,
			l: 130.26,
			t: 1655422560000,
			n: 27
		},
		{
			v: 886,
			vw: 130.3014,
			o: 130.32,
			c: 130.28,
			h: 130.33,
			l: 130.28,
			t: 1655422680000,
			n: 17
		},
		{
			v: 2223,
			vw: 130.2954,
			o: 130.28,
			c: 130.32,
			h: 130.32,
			l: 130.28,
			t: 1655422740000,
			n: 36
		},
		{
			v: 382,
			vw: 130.3068,
			o: 130.3,
			c: 130.3,
			h: 130.3,
			l: 130.3,
			t: 1655422800000,
			n: 17
		},
		{
			v: 425,
			vw: 130.3187,
			o: 130.31,
			c: 130.31,
			h: 130.31,
			l: 130.31,
			t: 1655422860000,
			n: 17
		},
		{
			v: 852,
			vw: 130.3369,
			o: 130.34,
			c: 130.33,
			h: 130.34,
			l: 130.33,
			t: 1655422920000,
			n: 16
		},
		{
			v: 859,
			vw: 130.3239,
			o: 130.32,
			c: 130.32,
			h: 130.33,
			l: 130.32,
			t: 1655422980000,
			n: 26
		},
		{
			v: 1142,
			vw: 130.3285,
			o: 130.33,
			c: 130.34,
			h: 130.34,
			l: 130.32,
			t: 1655423040000,
			n: 25
		},
		{
			v: 467,
			vw: 130.3283,
			o: 130.34,
			c: 130.32,
			h: 130.34,
			l: 130.32,
			t: 1655423100000,
			n: 20
		},
		{
			v: 3045,
			vw: 130.3641,
			o: 130.34,
			c: 130.36,
			h: 130.4,
			l: 130.34,
			t: 1655423160000,
			n: 51
		},
		{
			v: 584,
			vw: 130.4057,
			o: 130.41,
			c: 130.41,
			h: 130.41,
			l: 130.4,
			t: 1655423220000,
			n: 31
		},
		{
			v: 355,
			vw: 130.3986,
			o: 130.4,
			c: 130.4,
			h: 130.4,
			l: 130.4,
			t: 1655423280000,
			n: 8
		},
		{
			v: 791,
			vw: 130.4035,
			o: 130.41,
			c: 130.4,
			h: 130.41,
			l: 130.4,
			t: 1655423340000,
			n: 20
		},
		{
			v: 6578,
			vw: 130.4312,
			o: 130.41,
			c: 130.47,
			h: 130.5,
			l: 130.4,
			t: 1655423400000,
			n: 47
		},
		{
			v: 3078,
			vw: 130.4589,
			o: 130.44,
			c: 130.47,
			h: 130.47,
			l: 130.44,
			t: 1655423460000,
			n: 44
		},
		{
			v: 835,
			vw: 130.4299,
			o: 130.41,
			c: 130.46,
			h: 130.46,
			l: 130.41,
			t: 1655423580000,
			n: 23
		},
		{
			v: 1470,
			vw: 130.4914,
			o: 130.49,
			c: 130.5,
			h: 130.5,
			l: 130.49,
			t: 1655423640000,
			n: 35
		},
		{
			v: 2128,
			vw: 130.4344,
			o: 130.43,
			c: 130.43,
			h: 130.46,
			l: 130.42,
			t: 1655423700000,
			n: 37
		},
		{
			v: 3750,
			vw: 130.554,
			o: 130.5,
			c: 130.6,
			h: 130.6,
			l: 130.5,
			t: 1655423760000,
			n: 64
		},
		{
			v: 3929,
			vw: 130.5576,
			o: 130.56,
			c: 130.58,
			h: 130.58,
			l: 130.53,
			t: 1655423820000,
			n: 32
		},
		{
			v: 1293,
			vw: 130.5467,
			o: 130.54,
			c: 130.56,
			h: 130.56,
			l: 130.53,
			t: 1655423880000,
			n: 31
		},
		{
			v: 3771,
			vw: 130.54,
			o: 130.55,
			c: 130.53,
			h: 130.55,
			l: 130.52,
			t: 1655423940000,
			n: 65
		},
		{
			v: 3628,
			vw: 131.027,
			a: 131.027,
			o: 131.01,
			c: 131.06,
			h: 131.06,
			l: 130.92,
			t: 1655452800000,
			n: 48
		},
		{
			v: 3466,
			vw: 131.244,
			a: 131.1331,
			o: 131.24,
			c: 131.15,
			h: 131.3,
			l: 131.15,
			t: 1655452860000,
			n: 66
		},
		{
			v: 976,
			vw: 131.0468,
			a: 131.1226,
			o: 131.03,
			c: 131.03,
			h: 131.03,
			l: 131.03,
			t: 1655452920000,
			n: 23
		},
		{
			v: 2543,
			vw: 131.1263,
			a: 131.1235,
			o: 131.04,
			c: 131.21,
			h: 131.21,
			l: 131.03,
			t: 1655452980000,
			n: 48
		},
		{
			v: 1144,
			vw: 131.2532,
			a: 131.1383,
			o: 131.22,
			c: 131.27,
			h: 131.27,
			l: 131.22,
			t: 1655453100000,
			n: 27
		},
		{
			v: 1287,
			vw: 131.3461,
			a: 131.1583,
			o: 131.34,
			c: 131.36,
			h: 131.36,
			l: 131.34,
			t: 1655453160000,
			n: 25
		},
		{
			v: 996,
			vw: 131.3325,
			a: 131.175,
			o: 131.34,
			c: 131.34,
			h: 131.34,
			l: 131.34,
			t: 1655453340000,
			n: 33
		},
		{
			v: 465,
			vw: 131.3489,
			a: 131.1803,
			o: 131.34,
			c: 131.38,
			h: 131.38,
			l: 131.34,
			t: 1655453400000,
			n: 14
		},
		{
			v: 3299,
			vw: 131.3345,
			a: 131.2078,
			o: 131.38,
			c: 131.34,
			h: 131.4,
			l: 131.28,
			t: 1655453460000,
			n: 56
		},
		{
			v: 733,
			vw: 131.3672,
			a: 131.2139,
			o: 131.35,
			c: 131.38,
			h: 131.38,
			l: 131.35,
			t: 1655453520000,
			n: 15
		},
		{
			v: 1867,
			vw: 131.3998,
			a: 131.2304,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655453580000,
			n: 33
		},
		{
			v: 724,
			vw: 131.4408,
			a: 131.2373,
			o: 131.42,
			c: 131.45,
			h: 131.45,
			l: 131.42,
			t: 1655453640000,
			n: 26
		},
		{
			v: 2504,
			vw: 131.4986,
			a: 131.2642,
			o: 131.49,
			c: 131.54,
			h: 131.54,
			l: 131.49,
			t: 1655453700000,
			n: 43
		},
		{
			v: 6083,
			vw: 131.4558,
			a: 131.3052,
			o: 131.52,
			c: 131.36,
			h: 131.52,
			l: 131.36,
			t: 1655453820000,
			n: 70
		},
		{
			v: 1408,
			vw: 131.4082,
			a: 131.3097,
			o: 131.37,
			c: 131.49,
			h: 131.49,
			l: 131.36,
			t: 1655453880000,
			n: 25
		},
		{
			v: 575,
			vw: 131.3973,
			a: 131.3112,
			o: 131.38,
			c: 131.38,
			h: 131.38,
			l: 131.38,
			t: 1655453940000,
			n: 21
		},
		{
			v: 554,
			vw: 131.3928,
			a: 131.3126,
			o: 131.38,
			c: 131.41,
			h: 131.41,
			l: 131.37,
			t: 1655454000000,
			n: 8
		},
		{
			v: 272,
			vw: 131.4608,
			a: 131.3138,
			o: 131.46,
			c: 131.46,
			h: 131.46,
			l: 131.46,
			t: 1655454060000,
			n: 12
		},
		{
			v: 851,
			vw: 131.445,
			a: 131.3171,
			o: 131.45,
			c: 131.41,
			h: 131.46,
			l: 131.41,
			t: 1655454120000,
			n: 18
		},
		{
			v: 332,
			vw: 131.4437,
			a: 131.3183,
			o: 131.45,
			c: 131.45,
			h: 131.45,
			l: 131.45,
			t: 1655454180000,
			n: 13
		},
		{
			v: 1371,
			vw: 131.5118,
			a: 131.3279,
			o: 131.5,
			c: 131.54,
			h: 131.54,
			l: 131.5,
			t: 1655454300000,
			n: 20
		},
		{
			v: 125,
			vw: 131.5313,
			a: 131.3286,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655454360000,
			n: 8
		},
		{
			v: 612,
			vw: 131.437,
			a: 131.3306,
			o: 131.47,
			c: 131.47,
			h: 131.47,
			l: 131.47,
			t: 1655454480000,
			n: 30
		},
		{
			v: 424,
			vw: 131.474,
			a: 131.3322,
			o: 131.47,
			c: 131.47,
			h: 131.47,
			l: 131.47,
			t: 1655454540000,
			n: 14
		},
		{
			v: 4628,
			vw: 131.59,
			a: 131.3603,
			o: 131.56,
			c: 131.63,
			h: 131.66,
			l: 131.55,
			t: 1655454600000,
			n: 70
		},
		{
			v: 367,
			vw: 131.6421,
			a: 131.3644,
			o: 131.65,
			c: 131.65,
			h: 131.65,
			l: 131.65,
			t: 1655454720000,
			n: 8
		},
		{
			v: 2764,
			vw: 131.6622,
			a: 131.3823,
			o: 131.65,
			c: 131.7,
			h: 131.7,
			l: 131.65,
			t: 1655454780000,
			n: 33
		},
		{
			v: 1426,
			vw: 131.6694,
			a: 131.391,
			o: 131.67,
			c: 131.66,
			h: 131.7,
			l: 131.66,
			t: 1655454840000,
			n: 32
		},
		{
			v: 340,
			vw: 131.6561,
			a: 131.3929,
			o: 131.66,
			c: 131.66,
			h: 131.66,
			l: 131.66,
			t: 1655454900000,
			n: 17
		},
		{
			v: 980,
			vw: 131.5593,
			a: 131.402,
			o: 131.59,
			c: 131.52,
			h: 131.59,
			l: 131.52,
			t: 1655455440000,
			n: 17
		},
		{
			v: 2300,
			vw: 131.4202,
			a: 131.4028,
			o: 131.5,
			c: 131.3,
			h: 131.5,
			l: 131.3,
			t: 1655455500000,
			n: 113
		},
		{
			v: 1354,
			vw: 131.3297,
			a: 131.401,
			o: 131.33,
			c: 131.33,
			h: 131.33,
			l: 131.33,
			t: 1655455560000,
			n: 24
		},
		{
			v: 580,
			vw: 131.4138,
			a: 131.4011,
			o: 131.43,
			c: 131.46,
			h: 131.46,
			l: 131.43,
			t: 1655455620000,
			n: 19
		},
		{
			v: 138,
			vw: 131.3796,
			a: 131.4011,
			o: 131.37,
			c: 131.37,
			h: 131.37,
			l: 131.37,
			t: 1655455680000,
			n: 10
		},
		{
			v: 707,
			vw: 131.414,
			a: 131.4012,
			o: 131.44,
			c: 131.42,
			h: 131.44,
			l: 131.42,
			t: 1655455740000,
			n: 13
		},
		{
			v: 564,
			vw: 131.3641,
			a: 131.4009,
			o: 131.35,
			c: 131.36,
			h: 131.36,
			l: 131.35,
			t: 1655455800000,
			n: 16
		},
		{
			v: 198,
			vw: 131.388,
			a: 131.4008,
			o: 131.39,
			c: 131.39,
			h: 131.39,
			l: 131.39,
			t: 1655455860000,
			n: 11
		},
		{
			v: 1362,
			vw: 131.3249,
			a: 131.399,
			o: 131.33,
			c: 131.32,
			h: 131.33,
			l: 131.32,
			t: 1655455920000,
			n: 49
		},
		{
			v: 987,
			vw: 131.2063,
			a: 131.3958,
			o: 131.27,
			c: 131.16,
			h: 131.27,
			l: 131.16,
			t: 1655455980000,
			n: 42
		},
		{
			v: 657,
			vw: 131.1918,
			a: 131.3935,
			o: 131.16,
			c: 131.2,
			h: 131.2,
			l: 131.16,
			t: 1655456040000,
			n: 8
		},
		{
			v: 1223,
			vw: 131.3564,
			a: 131.3925,
			o: 131.36,
			c: 131.36,
			h: 131.36,
			l: 131.36,
			t: 1655456220000,
			n: 23
		},
		{
			v: 1012,
			vw: 131.328,
			a: 131.3914,
			o: 131.3,
			c: 131.37,
			h: 131.37,
			l: 131.3,
			t: 1655456340000,
			n: 17
		},
		{
			v: 1248,
			vw: 131.3236,
			a: 131.3898,
			o: 131.3,
			c: 131.3,
			h: 131.3,
			l: 131.3,
			t: 1655456460000,
			n: 34
		},
		{
			v: 881,
			vw: 131.4063,
			a: 131.3901,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655456520000,
			n: 17
		},
		{
			v: 165,
			vw: 131.4803,
			a: 131.3905,
			o: 131.48,
			c: 131.48,
			h: 131.48,
			l: 131.48,
			t: 1655456700000,
			n: 3
		},
		{
			v: 633,
			vw: 131.4057,
			a: 131.3907,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655456760000,
			n: 21
		},
		{
			v: 153,
			vw: 131.4203,
			a: 131.3907,
			o: 131.43,
			c: 131.43,
			h: 131.43,
			l: 131.43,
			t: 1655456880000,
			n: 13
		},
		{
			v: 263,
			vw: 131.4103,
			a: 131.3908,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655457000000,
			n: 14
		},
		{
			v: 109,
			vw: 131.442,
			a: 131.3909,
			o: 131.44,
			c: 131.44,
			h: 131.44,
			l: 131.44,
			t: 1655457060000,
			n: 4
		},
		{
			v: 1108,
			vw: 131.4913,
			a: 131.3926,
			o: 131.48,
			c: 131.53,
			h: 131.53,
			l: 131.47,
			t: 1655457120000,
			n: 15
		},
		{
			v: 918,
			vw: 131.5195,
			a: 131.3943,
			o: 131.53,
			c: 131.49,
			h: 131.53,
			l: 131.49,
			t: 1655457180000,
			n: 11
		},
		{
			v: 1258,
			vw: 131.5419,
			a: 131.397,
			o: 131.54,
			c: 131.55,
			h: 131.55,
			l: 131.52,
			t: 1655457240000,
			n: 14
		},
		{
			v: 642,
			vw: 131.521,
			a: 131.3981,
			o: 131.54,
			c: 131.5,
			h: 131.54,
			l: 131.5,
			t: 1655457300000,
			n: 24
		},
		{
			v: 350,
			vw: 131.4971,
			a: 131.3986,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655457360000,
			n: 14
		},
		{
			v: 1185,
			vw: 131.463,
			a: 131.3997,
			o: 131.45,
			c: 131.45,
			h: 131.45,
			l: 131.45,
			t: 1655457420000,
			n: 30
		},
		{
			v: 339,
			vw: 131.4789,
			a: 131.4001,
			o: 131.45,
			c: 131.45,
			h: 131.45,
			l: 131.45,
			t: 1655457480000,
			n: 13
		},
		{
			v: 1657,
			vw: 131.454,
			a: 131.4013,
			o: 131.47,
			c: 131.44,
			h: 131.47,
			l: 131.44,
			t: 1655457600000,
			n: 20
		},
		{
			v: 999,
			vw: 131.4368,
			a: 131.4019,
			o: 131.45,
			c: 131.41,
			h: 131.45,
			l: 131.41,
			t: 1655457720000,
			n: 33
		},
		{
			v: 369,
			vw: 131.4724,
			a: 131.4025,
			o: 131.47,
			c: 131.47,
			h: 131.47,
			l: 131.47,
			t: 1655457960000,
			n: 8
		},
		{
			v: 2772,
			vw: 131.5865,
			a: 131.4092,
			o: 131.59,
			c: 131.58,
			h: 131.59,
			l: 131.58,
			t: 1655458080000,
			n: 31
		},
		{
			v: 611,
			vw: 131.5655,
			a: 131.4106,
			o: 131.57,
			c: 131.58,
			h: 131.58,
			l: 131.57,
			t: 1655458320000,
			n: 20
		},
		{
			v: 3526,
			vw: 131.4949,
			a: 131.4143,
			o: 131.51,
			c: 131.5,
			h: 131.59,
			l: 131.4,
			t: 1655458500000,
			n: 64
		},
		{
			v: 1904,
			vw: 131.3645,
			a: 131.4136,
			o: 131.38,
			c: 131.29,
			h: 131.38,
			l: 131.29,
			t: 1655458920000,
			n: 58
		},
		{
			v: 878,
			vw: 131.2779,
			a: 131.4122,
			o: 131.29,
			c: 131.27,
			h: 131.29,
			l: 131.27,
			t: 1655458980000,
			n: 11
		},
		{
			v: 519,
			vw: 131.2694,
			a: 131.4113,
			o: 131.27,
			c: 131.27,
			h: 131.27,
			l: 131.27,
			t: 1655459040000,
			n: 9
		},
		{
			v: 103,
			vw: 131.3209,
			a: 131.4112,
			o: 131.32,
			c: 131.32,
			h: 131.32,
			l: 131.32,
			t: 1655459100000,
			n: 2
		},
		{
			v: 1184,
			vw: 131.2673,
			a: 131.4093,
			o: 131.27,
			c: 131.26,
			h: 131.27,
			l: 131.26,
			t: 1655459220000,
			n: 31
		},
		{
			v: 1789,
			vw: 131.2183,
			a: 131.4054,
			o: 131.22,
			c: 131.2,
			h: 131.23,
			l: 131.2,
			t: 1655459280000,
			n: 40
		},
		{
			v: 350,
			vw: 131.3016,
			a: 131.405,
			o: 131.3,
			c: 131.3,
			h: 131.3,
			l: 131.3,
			t: 1655459400000,
			n: 15
		},
		{
			v: 1674,
			vw: 131.4868,
			a: 131.4065,
			o: 131.48,
			c: 131.5,
			h: 131.5,
			l: 131.48,
			t: 1655459580000,
			n: 21
		},
		{
			v: 2082,
			vw: 131.5438,
			a: 131.4096,
			o: 131.53,
			c: 131.55,
			h: 131.55,
			l: 131.53,
			t: 1655459640000,
			n: 15
		},
		{
			v: 1457,
			vw: 131.5657,
			a: 131.412,
			o: 131.56,
			c: 131.57,
			h: 131.58,
			l: 131.56,
			t: 1655459700000,
			n: 19
		},
		{
			v: 183,
			vw: 131.5355,
			a: 131.4122,
			o: 131.54,
			c: 131.54,
			h: 131.54,
			l: 131.54,
			t: 1655459820000,
			n: 8
		},
		{
			v: 455,
			vw: 131.5287,
			a: 131.4128,
			o: 131.55,
			c: 131.48,
			h: 131.55,
			l: 131.48,
			t: 1655459880000,
			n: 13
		},
		{
			v: 1196,
			vw: 131.4032,
			a: 131.4127,
			o: 131.4,
			c: 131.42,
			h: 131.42,
			l: 131.35,
			t: 1655460180000,
			n: 16
		},
		{
			v: 476,
			vw: 131.344,
			a: 131.4124,
			o: 131.36,
			c: 131.34,
			h: 131.36,
			l: 131.34,
			t: 1655460360000,
			n: 18
		},
		{
			v: 500,
			vw: 131.2189,
			a: 131.4114,
			o: 131.2,
			c: 131.2,
			h: 131.2,
			l: 131.2,
			t: 1655460420000,
			n: 30
		},
		{
			v: 248,
			vw: 131.2483,
			a: 131.4109,
			o: 131.25,
			c: 131.25,
			h: 131.25,
			l: 131.25,
			t: 1655460540000,
			n: 14
		},
		{
			v: 805,
			vw: 131.3025,
			a: 131.41,
			o: 131.26,
			c: 131.36,
			h: 131.36,
			l: 131.26,
			t: 1655460600000,
			n: 21
		},
		{
			v: 300,
			vw: 131.4083,
			a: 131.41,
			o: 131.43,
			c: 131.43,
			h: 131.43,
			l: 131.43,
			t: 1655460660000,
			n: 18
		},
		{
			v: 548,
			vw: 131.4878,
			a: 131.4104,
			o: 131.49,
			c: 131.5,
			h: 131.5,
			l: 131.49,
			t: 1655460720000,
			n: 10
		},
		{
			v: 438,
			vw: 131.4689,
			a: 131.4105,
			o: 131.45,
			c: 131.49,
			h: 131.49,
			l: 131.45,
			t: 1655461080000,
			n: 15
		},
		{
			v: 924,
			vw: 131.4106,
			a: 131.4105,
			o: 131.41,
			c: 131.41,
			h: 131.41,
			l: 131.41,
			t: 1655461200000,
			n: 7
		},
		{
			v: 1413,
			vw: 131.4962,
			a: 131.4117,
			o: 131.49,
			c: 131.49,
			h: 131.49,
			l: 131.49,
			t: 1655461320000,
			n: 44
		},
		{
			v: 2711,
			vw: 131.4974,
			a: 131.414,
			o: 131.48,
			c: 131.5,
			h: 131.5,
			l: 131.48,
			t: 1655461440000,
			n: 29
		},
		{
			v: 150,
			vw: 131.4502,
			a: 131.414,
			o: 131.45,
			c: 131.45,
			h: 131.45,
			l: 131.45,
			t: 1655461500000,
			n: 3
		},
		{
			v: 228,
			vw: 131.435,
			a: 131.4142,
			o: 131.45,
			c: 131.42,
			h: 131.45,
			l: 131.42,
			t: 1655461920000,
			n: 12
		},
		{
			v: 5037,
			vw: 131.5072,
			a: 131.4184,
			o: 131.5,
			c: 131.49,
			h: 131.55,
			l: 131.45,
			t: 1655462040000,
			n: 46
		},
		{
			v: 3194,
			vw: 131.5626,
			a: 131.4224,
			o: 131.49,
			c: 131.59,
			h: 131.6,
			l: 131.49,
			t: 1655462280000,
			n: 48
		},
		{
			v: 695,
			vw: 131.6297,
			a: 131.4236,
			o: 131.58,
			c: 131.68,
			h: 131.68,
			l: 131.58,
			t: 1655462340000,
			n: 16
		},
		{
			v: 2897,
			vw: 131.6735,
			a: 131.4296,
			o: 131.68,
			c: 131.66,
			h: 131.69,
			l: 131.65,
			t: 1655462400000,
			n: 48
		},
		{
			v: 636,
			vw: 131.5914,
			a: 131.4305,
			o: 131.6,
			c: 131.6,
			h: 131.6,
			l: 131.6,
			t: 1655462460000,
			n: 12
		},
		{
			v: 430,
			vw: 131.5809,
			a: 131.431,
			o: 131.58,
			c: 131.59,
			h: 131.59,
			l: 131.58,
			t: 1655462520000,
			n: 8
		},
		{
			v: 1416,
			vw: 131.4182,
			a: 131.4309,
			o: 131.44,
			c: 131.4,
			h: 131.44,
			l: 131.4,
			t: 1655462640000,
			n: 28
		},
		{
			v: 1039,
			vw: 131.4007,
			a: 131.4306,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655462700000,
			n: 18
		},
		{
			v: 831,
			vw: 131.4005,
			a: 131.4304,
			o: 131.42,
			c: 131.42,
			h: 131.42,
			l: 131.42,
			t: 1655462760000,
			n: 27
		},
		{
			v: 3954,
			vw: 131.3483,
			a: 131.4278,
			o: 131.35,
			c: 131.35,
			h: 131.35,
			l: 131.33,
			t: 1655462940000,
			n: 43
		},
		{
			v: 825,
			vw: 131.3445,
			a: 131.4273,
			o: 131.39,
			c: 131.31,
			h: 131.39,
			l: 131.31,
			t: 1655463180000,
			n: 22
		},
		{
			v: 2380,
			vw: 131.4511,
			a: 131.4275,
			o: 131.5,
			c: 131.42,
			h: 131.5,
			l: 131.42,
			t: 1655463540000,
			n: 44
		},
		{
			v: 10312,
			vw: 131.3912,
			a: 131.4249,
			o: 131.42,
			c: 131.3,
			h: 131.5,
			l: 131.29,
			t: 1655463600000,
			n: 253
		},
		{
			v: 5083,
			vw: 131.2978,
			a: 131.4205,
			o: 131.29,
			c: 131.31,
			h: 131.33,
			l: 131.26,
			t: 1655463660000,
			n: 93
		},
		{
			v: 2638,
			vw: 131.3773,
			a: 131.4198,
			o: 131.32,
			c: 131.41,
			h: 131.43,
			l: 131.32,
			t: 1655463720000,
			n: 60
		},
		{
			v: 3086,
			vw: 131.4015,
			a: 131.4194,
			o: 131.39,
			c: 131.41,
			h: 131.41,
			l: 131.39,
			t: 1655463780000,
			n: 30
		},
		{
			v: 1733,
			vw: 131.393,
			a: 131.4191,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655463840000,
			n: 29
		},
		{
			v: 1287,
			vw: 131.3945,
			a: 131.4189,
			o: 131.39,
			c: 131.39,
			h: 131.4,
			l: 131.39,
			t: 1655463900000,
			n: 13
		},
		{
			v: 1947,
			vw: 131.3855,
			a: 131.4185,
			o: 131.37,
			c: 131.4,
			h: 131.4,
			l: 131.37,
			t: 1655463960000,
			n: 42
		},
		{
			v: 777,
			vw: 131.4066,
			a: 131.4185,
			o: 131.4,
			c: 131.41,
			h: 131.41,
			l: 131.4,
			t: 1655464020000,
			n: 19
		},
		{
			v: 2650,
			vw: 131.475,
			a: 131.4194,
			o: 131.43,
			c: 131.5,
			h: 131.5,
			l: 131.43,
			t: 1655464080000,
			n: 47
		},
		{
			v: 1424,
			vw: 131.5375,
			a: 131.4205,
			o: 131.51,
			c: 131.53,
			h: 131.57,
			l: 131.51,
			t: 1655464200000,
			n: 46
		},
		{
			v: 1443,
			vw: 131.4999,
			a: 131.4212,
			o: 131.5,
			c: 131.53,
			h: 131.53,
			l: 131.5,
			t: 1655464260000,
			n: 28
		},
		{
			v: 1102,
			vw: 131.5624,
			a: 131.4221,
			o: 131.59,
			c: 131.59,
			h: 131.59,
			l: 131.59,
			t: 1655464320000,
			n: 42
		},
		{
			v: 1154,
			vw: 131.4905,
			a: 131.4226,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655464380000,
			n: 37
		},
		{
			v: 1346,
			vw: 131.4971,
			a: 131.4233,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655464560000,
			n: 30
		},
		{
			v: 4254,
			vw: 131.609,
			a: 131.4278,
			o: 131.55,
			c: 131.75,
			h: 131.75,
			l: 131.55,
			t: 1655464620000,
			n: 89
		},
		{
			v: 1041,
			vw: 131.6282,
			a: 131.429,
			o: 131.71,
			c: 131.58,
			h: 131.71,
			l: 131.58,
			t: 1655464680000,
			n: 14
		},
		{
			v: 2491,
			vw: 131.5109,
			a: 131.4301,
			o: 131.53,
			c: 131.49,
			h: 131.53,
			l: 131.49,
			t: 1655464740000,
			n: 66
		},
		{
			v: 1704,
			vw: 131.4438,
			a: 131.4303,
			o: 131.44,
			c: 131.47,
			h: 131.47,
			l: 131.44,
			t: 1655464800000,
			n: 13
		},
		{
			v: 1117,
			vw: 131.5021,
			a: 131.4308,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655464920000,
			n: 21
		},
		{
			v: 820,
			vw: 131.5155,
			a: 131.4312,
			o: 131.54,
			c: 131.49,
			h: 131.54,
			l: 131.49,
			t: 1655464980000,
			n: 18
		},
		{
			v: 2030,
			vw: 131.5839,
			a: 131.433,
			o: 131.53,
			c: 131.63,
			h: 131.63,
			l: 131.53,
			t: 1655465100000,
			n: 34
		},
		{
			v: 556,
			vw: 131.531,
			a: 131.4334,
			o: 131.53,
			c: 131.55,
			h: 131.55,
			l: 131.53,
			t: 1655465220000,
			n: 28
		},
		{
			v: 3058,
			vw: 131.626,
			a: 131.4365,
			o: 131.57,
			c: 131.6,
			h: 131.68,
			l: 131.57,
			t: 1655465280000,
			n: 44
		},
		{
			v: 7682,
			vw: 131.688,
			a: 131.4464,
			o: 131.63,
			c: 131.75,
			h: 131.75,
			l: 131.63,
			t: 1655465340000,
			n: 51
		},
		{
			v: 1187,
			vw: 131.7557,
			a: 131.4482,
			o: 131.75,
			c: 131.78,
			h: 131.78,
			l: 131.74,
			t: 1655465400000,
			n: 44
		},
		{
			v: 8326,
			vw: 131.854,
			a: 131.4647,
			o: 131.76,
			c: 131.85,
			h: 131.99,
			l: 131.75,
			t: 1655465460000,
			n: 143
		},
		{
			v: 7686,
			vw: 131.7937,
			a: 131.4766,
			o: 131.85,
			c: 131.75,
			h: 131.85,
			l: 131.7,
			t: 1655465520000,
			n: 108
		},
		{
			v: 1195,
			vw: 131.7687,
			a: 131.4782,
			o: 131.76,
			c: 131.8,
			h: 131.8,
			l: 131.75,
			t: 1655465580000,
			n: 22
		},
		{
			v: 6827,
			vw: 131.7663,
			a: 131.4871,
			o: 131.8,
			c: 131.81,
			h: 131.81,
			l: 131.7,
			t: 1655465640000,
			n: 82
		},
		{
			v: 6076,
			vw: 131.7974,
			a: 131.4954,
			o: 131.8,
			c: 131.75,
			h: 131.8,
			l: 131.75,
			t: 1655465700000,
			n: 24
		},
		{
			v: 2435,
			vw: 131.6937,
			a: 131.4975,
			o: 131.71,
			c: 131.65,
			h: 131.71,
			l: 131.65,
			t: 1655465760000,
			n: 42
		},
		{
			v: 2753,
			vw: 131.6192,
			a: 131.499,
			o: 131.64,
			c: 131.61,
			h: 131.64,
			l: 131.61,
			t: 1655465880000,
			n: 29
		},
		{
			v: 7548,
			vw: 131.5686,
			a: 131.5012,
			o: 131.56,
			c: 131.65,
			h: 131.65,
			l: 131.55,
			t: 1655465940000,
			n: 89
		},
		{
			v: 1857,
			vw: 131.5416,
			a: 131.5016,
			o: 131.57,
			c: 131.51,
			h: 131.57,
			l: 131.51,
			t: 1655466060000,
			n: 45
		},
		{
			v: 1763,
			vw: 131.5255,
			a: 131.5018,
			o: 131.53,
			c: 131.52,
			h: 131.53,
			l: 131.5,
			t: 1655466120000,
			n: 54
		},
		{
			v: 7686,
			vw: 131.5064,
			a: 131.5019,
			o: 131.56,
			c: 131.5,
			h: 131.56,
			l: 131.47,
			t: 1655466180000,
			n: 81
		},
		{
			v: 3410,
			vw: 131.5076,
			a: 131.502,
			o: 131.53,
			c: 131.5,
			h: 131.53,
			l: 131.5,
			t: 1655466240000,
			n: 36
		},
		{
			v: 8130,
			vw: 131.4964,
			a: 131.5018,
			o: 131.5,
			c: 131.47,
			h: 131.5,
			l: 131.45,
			t: 1655466300000,
			n: 48
		},
		{
			v: 2370,
			vw: 131.4182,
			a: 131.5011,
			o: 131.47,
			c: 131.4,
			h: 131.47,
			l: 131.4,
			t: 1655466360000,
			n: 90
		},
		{
			v: 462,
			vw: 131.4113,
			a: 131.5009,
			o: 131.42,
			c: 131.42,
			h: 131.42,
			l: 131.42,
			t: 1655466420000,
			n: 16
		},
		{
			v: 5877,
			vw: 131.4147,
			a: 131.4991,
			o: 131.45,
			c: 131.4,
			h: 131.47,
			l: 131.4,
			t: 1655466480000,
			n: 40
		},
		{
			v: 1693,
			vw: 131.3679,
			a: 131.4983,
			o: 131.4,
			c: 131.36,
			h: 131.4,
			l: 131.36,
			t: 1655466540000,
			n: 33
		},
		{
			v: 5298,
			vw: 131.3067,
			a: 131.4946,
			o: 131.35,
			c: 131.29,
			h: 131.35,
			l: 131.26,
			t: 1655466600000,
			n: 92
		},
		{
			v: 197,
			vw: 131.3264,
			a: 131.4945,
			o: 131.33,
			c: 131.33,
			h: 131.33,
			l: 131.33,
			t: 1655466660000,
			n: 6
		},
		{
			v: 831,
			vw: 131.3988,
			a: 131.4942,
			o: 131.39,
			c: 131.4,
			h: 131.4,
			l: 131.39,
			t: 1655466720000,
			n: 18
		},
		{
			v: 5888,
			vw: 131.283,
			a: 131.4899,
			o: 131.38,
			c: 131.25,
			h: 131.38,
			l: 131.23,
			t: 1655466780000,
			n: 119
		},
		{
			v: 606,
			vw: 131.2801,
			a: 131.4894,
			o: 131.28,
			c: 131.28,
			h: 131.28,
			l: 131.28,
			t: 1655466840000,
			n: 13
		},
		{
			v: 2284,
			vw: 131.2891,
			a: 131.4878,
			o: 131.28,
			c: 131.31,
			h: 131.31,
			l: 131.28,
			t: 1655466900000,
			n: 45
		},
		{
			v: 902,
			vw: 131.2876,
			a: 131.4872,
			o: 131.3,
			c: 131.3,
			h: 131.3,
			l: 131.3,
			t: 1655466960000,
			n: 34
		},
		{
			v: 699,
			vw: 131.3115,
			a: 131.4868,
			o: 131.32,
			c: 131.32,
			h: 131.32,
			l: 131.32,
			t: 1655467020000,
			n: 29
		},
		{
			v: 1873,
			vw: 131.3261,
			a: 131.4858,
			o: 131.33,
			c: 131.28,
			h: 131.33,
			l: 131.28,
			t: 1655467080000,
			n: 52
		},
		{
			v: 579,
			vw: 131.3495,
			a: 131.4855,
			o: 131.36,
			c: 131.36,
			h: 131.36,
			l: 131.36,
			t: 1655467140000,
			n: 18
		},
		{
			v: 237292,
			vw: 131.4631,
			a: 131.4755,
			o: 131.3999,
			c: 131.35,
			h: 131.95,
			l: 131.2,
			t: 1655467200000,
			n: 1438
		},
		{
			v: 3536,
			vw: 131.3219,
			a: 131.4745,
			o: 130.99,
			c: 131.32,
			h: 131.86,
			l: 130.99,
			t: 1655467260000,
			n: 434
		},
		{
			v: 27143,
			vw: 131.2906,
			a: 131.4656,
			o: 131.3,
			c: 131.19,
			h: 131.35,
			l: 131.19,
			t: 1655467320000,
			n: 238
		},
		{
			v: 6629,
			vw: 131.2006,
			a: 131.4625,
			o: 131.2199,
			c: 131.22,
			h: 131.24,
			l: 131.1609,
			t: 1655467380000,
			n: 80
		},
		{
			v: 3298,
			vw: 131.2728,
			a: 131.4614,
			o: 131.24,
			c: 131.31,
			h: 131.31,
			l: 131.23,
			t: 1655467440000,
			n: 61
		},
		{
			v: 4300,
			vw: 131.3051,
			a: 131.4602,
			o: 131.34,
			c: 131.3,
			h: 131.34,
			l: 131.27,
			t: 1655467500000,
			n: 75
		},
		{
			v: 1798,
			vw: 131.2656,
			a: 131.4596,
			o: 131.29,
			c: 131.26,
			h: 131.29,
			l: 131.25,
			t: 1655467560000,
			n: 34
		},
		{
			v: 1481,
			vw: 131.311,
			a: 131.4592,
			o: 131.29,
			c: 131.33,
			h: 131.33,
			l: 131.29,
			t: 1655467620000,
			n: 37
		},
		{
			v: 518,
			vw: 131.3298,
			a: 131.4591,
			o: 131.3499,
			c: 131.3217,
			h: 131.3499,
			l: 131.3217,
			t: 1655467680000,
			n: 26
		},
		{
			v: 853,
			vw: 131.3388,
			a: 131.4588,
			o: 131.35,
			c: 131.333,
			h: 131.35,
			l: 131.333,
			t: 1655467800000,
			n: 36
		},
		{
			v: 3234,
			vw: 131.3672,
			a: 131.4583,
			o: 131.32,
			c: 131.4,
			h: 131.4,
			l: 131.32,
			t: 1655467860000,
			n: 49
		},
		{
			v: 7940,
			vw: 131.415,
			a: 131.4577,
			o: 131.42,
			c: 131.42,
			h: 131.42,
			l: 131.38,
			t: 1655467920000,
			n: 47
		},
		{
			v: 1468,
			vw: 131.4044,
			a: 131.4575,
			o: 131.4,
			c: 131.4399,
			h: 131.4399,
			l: 131.4,
			t: 1655467980000,
			n: 51
		},
		{
			v: 603,
			vw: 131.3778,
			a: 131.4575,
			o: 131.4,
			c: 131.4,
			h: 131.4,
			l: 131.4,
			t: 1655468040000,
			n: 37
		},
		{
			v: 7337,
			vw: 131.2616,
			a: 131.4551,
			o: 131.35,
			c: 131.2,
			h: 131.35,
			l: 131.2,
			t: 1655468100000,
			n: 158
		},
		{
			v: 708,
			vw: 131.2325,
			a: 131.4548,
			o: 131.2202,
			c: 131.2204,
			h: 131.2204,
			l: 131.2202,
			t: 1655468160000,
			n: 28
		},
		{
			v: 7064,
			vw: 131.2528,
			a: 131.4525,
			o: 131.29,
			c: 131.29,
			h: 131.29,
			l: 131.22,
			t: 1655468220000,
			n: 67
		},
		{
			v: 5804,
			vw: 131.3611,
			a: 131.4516,
			o: 131.33,
			c: 131.3601,
			h: 131.42,
			l: 131.32,
			t: 1655468280000,
			n: 77
		},
		{
			v: 2938,
			vw: 131.3232,
			a: 131.451,
			o: 131.37,
			c: 131.3,
			h: 131.37,
			l: 131.3,
			t: 1655468340000,
			n: 70
		},
		{
			v: 17719,
			vw: 131.319,
			a: 131.4473,
			o: 131.29,
			c: 131.3,
			h: 131.35,
			l: 131.25,
			t: 1655468400000,
			n: 161
		},
		{
			v: 4181,
			vw: 131.3326,
			a: 131.4466,
			o: 131.34,
			c: 131.28,
			h: 131.3401,
			l: 131.28,
			t: 1655468460000,
			n: 60
		},
		{
			v: 5024,
			vw: 131.3081,
			a: 131.4455,
			o: 131.25,
			c: 131.2999,
			h: 131.33,
			l: 131.25,
			t: 1655468520000,
			n: 73
		},
		{
			v: 6915,
			vw: 131.3363,
			a: 131.4443,
			o: 131.28,
			c: 131.31,
			h: 131.35,
			l: 131.28,
			t: 1655468580000,
			n: 66
		},
		{
			v: 9660,
			vw: 131.3376,
			a: 131.4428,
			o: 131.36,
			c: 131.34,
			h: 131.37,
			l: 131.33,
			t: 1655468640000,
			n: 74
		},
		{
			v: 1744,
			vw: 131.3614,
			a: 131.4425,
			o: 131.39,
			c: 131.34,
			h: 131.39,
			l: 131.33,
			t: 1655468700000,
			n: 44
		},
		{
			v: 8090,
			vw: 131.3161,
			a: 131.441,
			o: 131.33,
			c: 131.3,
			h: 131.33,
			l: 131.29,
			t: 1655468760000,
			n: 123
		},
		{
			v: 7178,
			vw: 131.2583,
			a: 131.4391,
			o: 131.3,
			c: 131.27,
			h: 131.3,
			l: 131.25,
			t: 1655468820000,
			n: 102
		},
		{
			v: 14498,
			vw: 131.2674,
			a: 131.4355,
			o: 131.27,
			c: 131.3,
			h: 131.3,
			l: 131.26,
			t: 1655468880000,
			n: 73
		},
		{
			v: 3380,
			vw: 131.2954,
			a: 131.4348,
			o: 131.29,
			c: 131.3,
			h: 131.3,
			l: 131.28,
			t: 1655468940000,
			n: 54
		},
		{
			v: 7793,
			vw: 131.3297,
			a: 131.4336,
			o: 131.3,
			c: 131.41,
			h: 131.41,
			l: 131.3,
			t: 1655469000000,
			n: 74
		},
		{
			v: 7827,
			vw: 131.3779,
			a: 131.433,
			o: 131.37,
			c: 131.3447,
			h: 131.39,
			l: 131.33,
			t: 1655469060000,
			n: 102
		},
		{
			v: 10027,
			vw: 131.3256,
			a: 131.4315,
			o: 131.35,
			c: 131.32,
			h: 131.36,
			l: 131.31,
			t: 1655469120000,
			n: 119
		},
		{
			v: 15318,
			vw: 131.2617,
			a: 131.428,
			o: 131.29,
			c: 131.24,
			h: 131.29,
			l: 131.24,
			t: 1655469180000,
			n: 75
		},
		{
			v: 5009,
			vw: 131.243,
			a: 131.4268,
			o: 131.25,
			c: 131.23,
			h: 131.25,
			l: 131.2202,
			t: 1655469240000,
			n: 42
		},
		{
			v: 1722,
			vw: 131.2513,
			a: 131.4263,
			o: 131.23,
			c: 131.27,
			h: 131.27,
			l: 131.23,
			t: 1655469300000,
			n: 54
		},
		{
			v: 10247,
			vw: 131.2826,
			a: 131.4244,
			o: 131.28,
			c: 131.29,
			h: 131.29,
			l: 131.28,
			t: 1655469360000,
			n: 41
		},
		{
			v: 26382,
			vw: 131.3205,
			a: 131.4209,
			o: 131.29,
			c: 131.3303,
			h: 131.37,
			l: 131.29,
			t: 1655469420000,
			n: 172
		},
		{
			v: 9697,
			vw: 131.301,
			a: 131.4194,
			o: 131.35,
			c: 131.26,
			h: 131.35,
			l: 131.25,
			t: 1655469480000,
			n: 124
		},
		{
			v: 9169,
			vw: 131.276,
			a: 131.4178,
			o: 131.3,
			c: 131.25,
			h: 131.3,
			l: 131.25,
			t: 1655469540000,
			n: 98
		},
		{
			v: 11567,
			vw: 131.2128,
			a: 131.4148,
			o: 131.22,
			c: 131.18,
			h: 131.23,
			l: 131.17,
			t: 1655469600000,
			n: 161
		},
		{
			v: 17657,
			vw: 131.1001,
			a: 131.4081,
			o: 131.18,
			c: 131.13,
			h: 131.18,
			l: 131.07,
			t: 1655469660000,
			n: 197
		},
		{
			v: 5144,
			vw: 131.1611,
			a: 131.4066,
			o: 131.13,
			c: 131.18,
			h: 131.29,
			l: 131.12,
			t: 1655469720000,
			n: 80
		},
		{
			v: 6719,
			vw: 131.1913,
			a: 131.4049,
			o: 131.17,
			c: 131.16,
			h: 131.24,
			l: 131.16,
			t: 1655469780000,
			n: 100
		},
		{
			v: 2478,
			vw: 131.1664,
			a: 131.4042,
			o: 131.16,
			c: 131.16,
			h: 131.19,
			l: 131.16,
			t: 1655469840000,
			n: 59
		},
		{
			v: 23541,
			vw: 131.0246,
			a: 131.3939,
			o: 131.15,
			c: 130.99,
			h: 131.15,
			l: 130.98,
			t: 1655469900000,
			n: 408
		},
		{
			v: 13382,
			vw: 130.9957,
			a: 131.3878,
			o: 130.99,
			c: 130.99,
			h: 131,
			l: 130.97,
			t: 1655469960000,
			n: 93
		},
		{
			v: 3445,
			vw: 130.9841,
			a: 131.3862,
			o: 130.97,
			c: 131,
			h: 131,
			l: 130.96,
			t: 1655470020000,
			n: 87
		},
		{
			v: 16547,
			vw: 130.9498,
			a: 131.3782,
			o: 130.96,
			c: 130.88,
			h: 130.99,
			l: 130.88,
			t: 1655470080000,
			n: 144
		},
		{
			v: 7587,
			vw: 130.8454,
			a: 131.3737,
			o: 130.83,
			c: 130.8,
			h: 130.91,
			l: 130.8,
			t: 1655470140000,
			n: 126
		},
		{
			v: 31341,
			vw: 130.7949,
			a: 131.3544,
			o: 130.8,
			c: 130.81,
			h: 130.83,
			l: 130.75,
			t: 1655470200000,
			n: 215
		},
		{
			v: 33768,
			vw: 130.7642,
			a: 131.3339,
			o: 130.81,
			c: 130.74,
			h: 130.81,
			l: 130.69,
			t: 1655470260000,
			n: 228
		},
		{
			v: 5169,
			vw: 130.7556,
			a: 131.3308,
			o: 130.77,
			c: 130.72,
			h: 130.8,
			l: 130.72,
			t: 1655470320000,
			n: 92
		},
		{
			v: 17887,
			vw: 130.7387,
			a: 131.3202,
			o: 130.74,
			c: 130.75,
			h: 130.76,
			l: 130.71,
			t: 1655470380000,
			n: 125
		},
		{
			v: 10092,
			vw: 130.7499,
			a: 131.3145,
			o: 130.75,
			c: 130.74,
			h: 130.8,
			l: 130.72,
			t: 1655470440000,
			n: 152
		},
		{
			v: 10127,
			vw: 130.8465,
			a: 131.3098,
			o: 130.79,
			c: 130.79,
			h: 130.92,
			l: 130.79,
			t: 1655470500000,
			n: 181
		},
		{
			v: 6542,
			vw: 130.7856,
			a: 131.3064,
			o: 130.79,
			c: 130.77,
			h: 130.8099,
			l: 130.75,
			t: 1655470560000,
			n: 74
		},
		{
			v: 16557,
			vw: 130.7343,
			a: 131.2973,
			o: 130.75,
			c: 130.74,
			h: 130.76,
			l: 130.69,
			t: 1655470620000,
			n: 170
		},
		{
			v: 22984,
			vw: 130.6656,
			a: 131.2836,
			o: 130.74,
			c: 130.65,
			h: 130.74,
			l: 130.6,
			t: 1655470680000,
			n: 160
		},
		{
			v: 3986,
			vw: 130.6804,
			a: 131.2814,
			o: 130.64,
			c: 130.7,
			h: 130.72,
			l: 130.64,
			t: 1655470740000,
			n: 98
		},
		{
			v: 15928,
			vw: 130.6962,
			a: 131.2728,
			o: 130.7,
			c: 130.69,
			h: 130.74,
			l: 130.68,
			t: 1655470800000,
			n: 179
		},
		{
			v: 30546,
			vw: 130.6618,
			a: 131.256,
			o: 130.7,
			c: 130.68,
			h: 130.71,
			l: 130.61,
			t: 1655470860000,
			n: 193
		},
		{
			v: 39649,
			vw: 130.5841,
			a: 131.2328,
			o: 130.66,
			c: 130.59,
			h: 130.66,
			l: 130.53,
			t: 1655470920000,
			n: 386
		},
		{
			v: 6113,
			vw: 130.5852,
			a: 131.2294,
			o: 130.5744,
			c: 130.63,
			h: 130.64,
			l: 130.56,
			t: 1655470980000,
			n: 124
		},
		{
			v: 15468,
			vw: 130.564,
			a: 131.2207,
			o: 130.61,
			c: 130.54,
			h: 130.61,
			l: 130.52,
			t: 1655471040000,
			n: 203
		},
		{
			v: 23535,
			vw: 130.4881,
			a: 131.2062,
			o: 130.55,
			c: 130.4,
			h: 130.55,
			l: 130.39,
			t: 1655471100000,
			n: 381
		},
		{
			v: 49700,
			vw: 130.3482,
			a: 131.172,
			o: 130.4,
			c: 130.32,
			h: 130.4,
			l: 130.31,
			t: 1655471160000,
			n: 431
		},
		{
			v: 33330,
			vw: 130.3316,
			a: 131.1501,
			o: 130.32,
			c: 130.34,
			h: 130.36,
			l: 130.3,
			t: 1655471220000,
			n: 184
		},
		{
			v: 15752,
			vw: 130.2883,
			a: 131.1397,
			o: 130.32,
			c: 130.26,
			h: 130.34,
			l: 130.24,
			t: 1655471280000,
			n: 317
		},
		{
			v: 24940,
			vw: 130.2491,
			a: 131.1228,
			o: 130.24,
			c: 130.22,
			h: 130.35,
			l: 130.2,
			t: 1655471340000,
			n: 235
		},
		{
			v: 23775,
			vw: 130.2016,
			a: 131.1065,
			o: 130.2,
			c: 130.2,
			h: 130.3,
			l: 130.14,
			t: 1655471400000,
			n: 210
		},
		{
			v: 35225,
			vw: 130.1335,
			a: 131.0817,
			o: 130.21,
			c: 130.1,
			h: 130.21,
			l: 130.06,
			t: 1655471460000,
			n: 375
		},
		{
			v: 45396,
			vw: 130.0879,
			a: 131.05,
			o: 130.12,
			c: 130.02,
			h: 130.16,
			l: 130,
			t: 1655471520000,
			n: 576
		},
		{
			v: 17697,
			vw: 130.1342,
			a: 131.0388,
			o: 130.0575,
			c: 130.16,
			h: 130.22,
			l: 130.03,
			t: 1655471580000,
			n: 162
		},
		{
			v: 11325,
			vw: 130.1924,
			a: 131.0322,
			o: 130.17,
			c: 130.19,
			h: 130.25,
			l: 130.1017,
			t: 1655471640000,
			n: 190
		},
		{
			v: 35152,
			vw: 130.1349,
			a: 131.011,
			o: 130.18,
			c: 130.1,
			h: 130.18,
			l: 130.08,
			t: 1655471700000,
			n: 158
		},
		{
			v: 28958,
			vw: 130.2158,
			a: 130.9958,
			o: 130.2,
			c: 130.2499,
			h: 130.3,
			l: 130.1101,
			t: 1655471760000,
			n: 282
		},
		{
			v: 17305,
			vw: 130.2955,
			a: 130.988,
			o: 130.25,
			c: 130.32,
			h: 130.37,
			l: 130.19,
			t: 1655471820000,
			n: 260
		},
		{
			v: 10490,
			vw: 130.3776,
			a: 130.9838,
			o: 130.3,
			c: 130.41,
			h: 130.46,
			l: 130.29,
			t: 1655471880000,
			n: 144
		},
		{
			v: 19800,
			vw: 130.4254,
			a: 130.9767,
			o: 130.43,
			c: 130.37,
			h: 130.46,
			l: 130.37,
			t: 1655471940000,
			n: 176
		},
		{
			v: 18306,
			vw: 130.3061,
			a: 130.969,
			o: 130.36,
			c: 130.34,
			h: 130.39,
			l: 130.29,
			t: 1655472000000,
			n: 125
		},
		{
			v: 12019,
			vw: 130.4191,
			a: 130.9649,
			o: 130.38,
			c: 130.42,
			h: 130.45,
			l: 130.38,
			t: 1655472060000,
			n: 147
		},
		{
			v: 14720,
			vw: 130.3752,
			a: 130.9595,
			o: 130.39,
			c: 130.4399,
			h: 130.4399,
			l: 130.33,
			t: 1655472120000,
			n: 124
		},
		{
			v: 27443,
			vw: 130.3395,
			a: 130.9491,
			o: 130.42,
			c: 130.25,
			h: 130.47,
			l: 130.25,
			t: 1655472180000,
			n: 341
		},
		{
			v: 12824,
			vw: 130.2509,
			a: 130.9437,
			o: 130.2501,
			c: 130.22,
			h: 130.36,
			l: 130.18,
			t: 1655472240000,
			n: 187
		},
		{
			v: 21148,
			vw: 130.4155,
			a: 130.937,
			o: 130.34,
			c: 130.4599,
			h: 130.46,
			l: 130.29,
			t: 1655472300000,
			n: 316
		},
		{
			v: 13275,
			vw: 130.3941,
			a: 130.9327,
			o: 130.45,
			c: 130.4,
			h: 130.45,
			l: 130.34,
			t: 1655472360000,
			n: 162
		},
		{
			v: 114015,
			vw: 130.2683,
			a: 130.8906,
			o: 130.4,
			c: 129.95,
			h: 130.81,
			l: 129.76,
			t: 1655472420000,
			n: 1364
		},
		{
			v: 97635,
			vw: 130.005,
			a: 130.845,
			o: 129.79,
			c: 129.94,
			h: 130.31,
			l: 129.76,
			t: 1655472480000,
			n: 767
		},
		{
			v: 58130,
			vw: 129.9962,
			a: 130.8198,
			o: 129.95,
			c: 130.06,
			h: 130.07,
			l: 129.93,
			t: 1655472540000,
			n: 280
		},
		{
			v: 15255557,
			vw: 130.0642,
			a: 130.15,
			o: 130.065,
			c: 130.65,
			h: 130.82,
			l: 130.01,
			t: 1655472600000,
			n: 16674,
			op: 130.065
		},
		{
			v: 612065,
			vw: 130.8334,
			a: 130.1734,
			o: 130.68,
			c: 130.6,
			h: 131.09,
			l: 130.52,
			t: 1655472660000,
			n: 5455,
			op: 130.065
		},
		{
			v: 411121,
			vw: 130.5943,
			a: 130.1828,
			o: 130.6,
			c: 130.85,
			h: 130.87,
			l: 130.41,
			t: 1655472720000,
			n: 3098,
			op: 130.065
		},
		{
			v: 483493,
			vw: 131.1186,
			a: 130.2069,
			o: 130.85,
			c: 131.44,
			h: 131.4934,
			l: 130.7,
			t: 1655472780000,
			n: 4240,
			op: 130.065
		},
		{
			v: 442198,
			vw: 131.5113,
			a: 130.237,
			o: 131.44,
			c: 131.39,
			h: 131.68,
			l: 131.33,
			t: 1655472840000,
			n: 3166,
			op: 130.065
		},
		{
			v: 545883,
			vw: 131.6627,
			a: 130.2764,
			o: 131.3801,
			c: 131.78,
			h: 131.89,
			l: 131.38,
			t: 1655472900000,
			n: 5000,
			op: 130.065
		},
		{
			v: 390189,
			vw: 131.6908,
			a: 130.3038,
			o: 131.8,
			c: 131.639,
			h: 131.93,
			l: 131.51,
			t: 1655472960000,
			n: 3577,
			op: 130.065
		},
		{
			v: 430129,
			vw: 131.507,
			a: 130.3289,
			o: 131.63,
			c: 131.3971,
			h: 131.665,
			l: 131.33,
			t: 1655473020000,
			n: 3676,
			op: 130.065
		},
		{
			v: 315596,
			vw: 131.2895,
			a: 130.3434,
			o: 131.41,
			c: 131.359,
			h: 131.46,
			l: 131.1098,
			t: 1655473080000,
			n: 2966,
			op: 130.065
		},
		{
			v: 263809,
			vw: 131.2322,
			a: 130.3545,
			o: 131.35,
			c: 131.1896,
			h: 131.38,
			l: 131.07,
			t: 1655473140000,
			n: 2382,
			op: 130.065
		},
		{
			v: 463094,
			vw: 131.5712,
			a: 130.3806,
			o: 131.19,
			c: 131.69,
			h: 131.85,
			l: 131.19,
			t: 1655473200000,
			n: 3556,
			op: 130.065
		},
		{
			v: 493729,
			vw: 131.9565,
			a: 130.4158,
			o: 131.6901,
			c: 131.9881,
			h: 132.1,
			l: 131.69,
			t: 1655473260000,
			n: 4020,
			op: 130.065
		},
		{
			v: 470602,
			vw: 131.6196,
			a: 130.4409,
			o: 131.99,
			c: 131.39,
			h: 132.025,
			l: 131.3699,
			t: 1655473320000,
			n: 4492,
			op: 130.065
		},
		{
			v: 343179,
			vw: 131.7699,
			a: 130.4608,
			o: 131.39,
			c: 131.9036,
			h: 132.0553,
			l: 131.38,
			t: 1655473380000,
			n: 2860,
			op: 130.065
		},
		{
			v: 224618,
			vw: 131.7834,
			a: 130.4736,
			o: 131.9,
			c: 131.84,
			h: 131.9,
			l: 131.63,
			t: 1655473440000,
			n: 2272,
			op: 130.065
		},
		{
			v: 255655,
			vw: 131.8394,
			a: 130.4885,
			o: 131.85,
			c: 131.89,
			h: 131.94,
			l: 131.69,
			t: 1655473500000,
			n: 2581,
			op: 130.065
		},
		{
			v: 278975,
			vw: 131.9184,
			a: 130.5054,
			o: 131.8901,
			c: 131.8,
			h: 132.0269,
			l: 131.8,
			t: 1655473560000,
			n: 2325,
			op: 130.065
		},
		{
			v: 268562,
			vw: 131.7665,
			a: 130.5195,
			o: 131.81,
			c: 131.66,
			h: 131.9864,
			l: 131.62,
			t: 1655473620000,
			n: 2665,
			op: 130.065
		},
		{
			v: 314939,
			vw: 131.8853,
			a: 130.5372,
			o: 131.65,
			c: 132.07,
			h: 132.08,
			l: 131.63,
			t: 1655473680000,
			n: 2850,
			op: 130.065
		},
		{
			v: 448306,
			vw: 132.1051,
			a: 130.5657,
			o: 132.07,
			c: 132.27,
			h: 132.29,
			l: 131.86,
			t: 1655473740000,
			n: 3581,
			op: 130.065
		},
		{
			v: 361533,
			vw: 132.3195,
			a: 130.591,
			o: 132.28,
			c: 132.28,
			h: 132.49,
			l: 132.17,
			t: 1655473800000,
			n: 2991,
			op: 130.065
		},
		{
			v: 338962,
			vw: 132.4235,
			a: 130.6154,
			o: 132.28,
			c: 132.49,
			h: 132.53,
			l: 132.28,
			t: 1655473860000,
			n: 2839,
			op: 130.065
		},
		{
			v: 304757,
			vw: 132.5229,
			a: 130.638,
			o: 132.49,
			c: 132.48,
			h: 132.66,
			l: 132.4,
			t: 1655473920000,
			n: 2668,
			op: 130.065
		},
		{
			v: 240627,
			vw: 132.4788,
			a: 130.6551,
			o: 132.49,
			c: 132.5,
			h: 132.59,
			l: 132.36,
			t: 1655473980000,
			n: 2358,
			op: 130.065
		},
		{
			v: 257393,
			vw: 132.3819,
			a: 130.672,
			o: 132.5111,
			c: 132.3321,
			h: 132.55,
			l: 132.3,
			t: 1655474040000,
			n: 2504,
			op: 130.065
		},
		{
			v: 209886,
			vw: 132.418,
			a: 130.6859,
			o: 132.34,
			c: 132.38,
			h: 132.5,
			l: 132.3246,
			t: 1655474100000,
			n: 2108,
			op: 130.065
		},
		{
			v: 194417,
			vw: 132.4334,
			a: 130.6986,
			o: 132.38,
			c: 132.41,
			h: 132.5,
			l: 132.35,
			t: 1655474160000,
			n: 1740,
			op: 130.065
		},
		{
			v: 296984,
			vw: 132.2341,
			a: 130.7156,
			o: 132.4,
			c: 132.17,
			h: 132.41,
			l: 132.15,
			t: 1655474220000,
			n: 2741,
			op: 130.065
		},
		{
			v: 291783,
			vw: 132.0563,
			a: 130.73,
			o: 132.17,
			c: 132.0296,
			h: 132.22,
			l: 131.96,
			t: 1655474280000,
			n: 2931,
			op: 130.065
		},
		{
			v: 231979,
			vw: 132.0468,
			a: 130.7411,
			o: 132.025,
			c: 132.05,
			h: 132.12,
			l: 130.04,
			t: 1655474340000,
			n: 2335,
			op: 130.065
		},
		{
			v: 329537,
			vw: 131.9569,
			a: 130.7555,
			o: 132.06,
			c: 131.8861,
			h: 132.17,
			l: 131.82,
			t: 1655474400000,
			n: 3224,
			op: 130.065
		},
		{
			v: 209492,
			vw: 131.8676,
			a: 130.7638,
			o: 131.885,
			c: 131.8813,
			h: 131.96,
			l: 131.77,
			t: 1655474460000,
			n: 1965,
			op: 130.065
		},
		{
			v: 179964,
			vw: 131.9196,
			a: 130.7712,
			o: 131.89,
			c: 131.92,
			h: 131.97,
			l: 131.86,
			t: 1655474520000,
			n: 1679,
			op: 130.065
		},
		{
			v: 280225,
			vw: 131.8536,
			a: 130.7819,
			o: 131.93,
			c: 132.03,
			h: 132.03,
			l: 131.73,
			t: 1655474580000,
			n: 2563,
			op: 130.065
		},
		{
			v: 377058,
			vw: 132.2677,
			a: 130.8013,
			o: 132.04,
			c: 132.18,
			h: 132.4199,
			l: 132.025,
			t: 1655474640000,
			n: 3040,
			op: 130.065
		},
		{
			v: 271259,
			vw: 132.0372,
			a: 130.8129,
			o: 132.2,
			c: 131.9306,
			h: 132.29,
			l: 131.83,
			t: 1655474700000,
			n: 2476,
			op: 130.065
		},
		{
			v: 232087,
			vw: 131.9092,
			a: 130.8215,
			o: 131.93,
			c: 131.8899,
			h: 132.0055,
			l: 131.8,
			t: 1655474760000,
			n: 1837,
			op: 130.065
		},
		{
			v: 253770,
			vw: 131.8519,
			a: 130.8304,
			o: 131.89,
			c: 131.7,
			h: 132.07,
			l: 131.6608,
			t: 1655474820000,
			n: 2268,
			op: 130.065
		},
		{
			v: 195028,
			vw: 131.765,
			a: 130.8365,
			o: 131.7,
			c: 131.77,
			h: 131.87,
			l: 131.67,
			t: 1655474880000,
			n: 1843,
			op: 130.065
		},
		{
			v: 265438,
			vw: 131.6866,
			a: 130.844,
			o: 131.775,
			c: 131.66,
			h: 131.88,
			l: 131.58,
			t: 1655474940000,
			n: 2367,
			op: 130.065
		},
		{
			v: 180852,
			vw: 131.7305,
			a: 130.8493,
			o: 131.65,
			c: 131.73,
			h: 131.79,
			l: 131.625,
			t: 1655475000000,
			n: 1871,
			op: 130.065
		},
		{
			v: 167418,
			vw: 131.7694,
			a: 130.8544,
			o: 131.725,
			c: 131.83,
			h: 131.9,
			l: 131.64,
			t: 1655475060000,
			n: 1556,
			op: 130.065
		},
		{
			v: 235206,
			vw: 131.9893,
			a: 130.8631,
			o: 131.84,
			c: 132.02,
			h: 132.13,
			l: 131.83,
			t: 1655475120000,
			n: 1989,
			op: 130.065
		},
		{
			v: 174600,
			vw: 131.9768,
			a: 130.8694,
			o: 132.065,
			c: 131.86,
			h: 132.09,
			l: 131.85,
			t: 1655475180000,
			n: 1527,
			op: 130.065
		},
		{
			v: 154630,
			vw: 131.9122,
			a: 130.8746,
			o: 131.86,
			c: 131.94,
			h: 131.98,
			l: 131.84,
			t: 1655475240000,
			n: 1537,
			op: 130.065
		},
		{
			v: 240751,
			vw: 131.7558,
			a: 130.8814,
			o: 131.94,
			c: 131.5794,
			h: 132.01,
			l: 131.56,
			t: 1655475300000,
			n: 2196,
			op: 130.065
		},
		{
			v: 207227,
			vw: 131.557,
			a: 130.8859,
			o: 131.58,
			c: 131.48,
			h: 131.6472,
			l: 131.47,
			t: 1655475360000,
			n: 2073,
			op: 130.065
		},
		{
			v: 200964,
			vw: 131.4737,
			a: 130.8896,
			o: 131.471,
			c: 131.48,
			h: 131.56,
			l: 131.41,
			t: 1655475420000,
			n: 1924,
			op: 130.065
		},
		{
			v: 209486,
			vw: 131.6154,
			a: 130.8944,
			o: 131.5,
			c: 131.64,
			h: 131.73,
			l: 131.49,
			t: 1655475480000,
			n: 1809,
			op: 130.065
		},
		{
			v: 168570,
			vw: 131.5883,
			a: 130.8981,
			o: 131.66,
			c: 131.55,
			h: 131.66,
			l: 131.51,
			t: 1655475540000,
			n: 1648,
			op: 130.065
		},
		{
			v: 285852,
			vw: 131.3616,
			a: 130.9022,
			o: 131.55,
			c: 131.172,
			h: 131.58,
			l: 131.15,
			t: 1655475600000,
			n: 2580,
			op: 130.065
		},
		{
			v: 259025,
			vw: 131.1824,
			a: 130.9044,
			o: 131.17,
			c: 131.1267,
			h: 131.28,
			l: 131.08,
			t: 1655475660000,
			n: 2226,
			op: 130.065
		},
		{
			v: 322115,
			vw: 131.0484,
			a: 130.9058,
			o: 131.12,
			c: 131.0439,
			h: 131.18,
			l: 130.95,
			t: 1655475720000,
			n: 2855,
			op: 130.065
		},
		{
			v: 248161,
			vw: 130.9655,
			a: 130.9063,
			o: 131.03,
			c: 131.0486,
			h: 131.05,
			l: 130.88,
			t: 1655475780000,
			n: 1979,
			op: 130.065
		},
		{
			v: 215216,
			vw: 131.0752,
			a: 130.9073,
			o: 131.045,
			c: 131.0501,
			h: 131.13,
			l: 131.01,
			t: 1655475840000,
			n: 1963,
			op: 130.065
		},
		{
			v: 230010,
			vw: 131.1325,
			a: 130.9089,
			o: 131.06,
			c: 131.21,
			h: 131.25,
			l: 131.025,
			t: 1655475900000,
			n: 2103,
			op: 130.065
		},
		{
			v: 149428,
			vw: 131.2011,
			a: 130.9102,
			o: 131.22,
			c: 131.11,
			h: 131.27,
			l: 131.05,
			t: 1655475960000,
			n: 1556,
			op: 130.065
		},
		{
			v: 163002,
			vw: 131.0708,
			a: 130.911,
			o: 131.1,
			c: 130.975,
			h: 131.15,
			l: 130.9645,
			t: 1655476020000,
			n: 1431,
			op: 130.065
		},
		{
			v: 198748,
			vw: 130.9006,
			a: 130.9109,
			o: 130.97,
			c: 130.925,
			h: 130.97,
			l: 130.83,
			t: 1655476080000,
			n: 1816,
			op: 130.065
		},
		{
			v: 272646,
			vw: 130.9569,
			a: 130.9113,
			o: 130.93,
			c: 131.04,
			h: 131.09,
			l: 130.82,
			t: 1655476140000,
			n: 2828,
			op: 130.065
		},
		{
			v: 213493,
			vw: 131.0718,
			a: 130.9123,
			o: 131.05,
			c: 131.0801,
			h: 131.16,
			l: 130.95,
			t: 1655476200000,
			n: 2135,
			op: 130.065
		},
		{
			v: 184566,
			vw: 131.013,
			a: 130.9128,
			o: 131.08,
			c: 130.94,
			h: 131.14,
			l: 130.91,
			t: 1655476260000,
			n: 1848,
			op: 130.065
		},
		{
			v: 155360,
			vw: 130.9225,
			a: 130.9128,
			o: 130.94,
			c: 130.92,
			h: 131,
			l: 130.84,
			t: 1655476320000,
			n: 1464,
			op: 130.065
		},
		{
			v: 218728,
			vw: 130.7599,
			a: 130.9119,
			o: 130.9246,
			c: 130.67,
			h: 130.9278,
			l: 130.66,
			t: 1655476380000,
			n: 2237,
			op: 130.065
		},
		{
			v: 248811,
			vw: 130.6626,
			a: 130.9101,
			o: 130.68,
			c: 130.585,
			h: 130.74,
			l: 130.58,
			t: 1655476440000,
			n: 2087,
			op: 130.065
		},
		{
			v: 389211,
			vw: 130.4016,
			a: 130.9046,
			o: 130.58,
			c: 130.345,
			h: 130.58,
			l: 130.3,
			t: 1655476500000,
			n: 3524,
			op: 130.065
		},
		{
			v: 313165,
			vw: 130.2436,
			a: 130.8988,
			o: 130.3432,
			c: 130.33,
			h: 130.3432,
			l: 130.15,
			t: 1655476560000,
			n: 2620,
			op: 130.065
		},
		{
			v: 301453,
			vw: 130.4058,
			a: 130.8948,
			o: 130.33,
			c: 130.48,
			h: 130.51,
			l: 130.29,
			t: 1655476620000,
			n: 2407,
			op: 130.065
		},
		{
			v: 280305,
			vw: 130.5955,
			a: 130.8925,
			o: 130.485,
			c: 130.555,
			h: 130.69,
			l: 130.42,
			t: 1655476680000,
			n: 2247,
			op: 130.065
		},
		{
			v: 229272,
			vw: 130.4818,
			a: 130.8899,
			o: 130.55,
			c: 130.525,
			h: 130.56,
			l: 130.4,
			t: 1655476740000,
			n: 1780,
			op: 130.065
		},
		{
			v: 138859,
			vw: 130.4697,
			a: 130.8883,
			o: 130.53,
			c: 130.43,
			h: 130.53,
			l: 130.41,
			t: 1655476800000,
			n: 1381,
			op: 130.065
		},
		{
			v: 183695,
			vw: 130.394,
			a: 130.8859,
			o: 130.42,
			c: 130.32,
			h: 130.46,
			l: 130.3,
			t: 1655476860000,
			n: 1608,
			op: 130.065
		},
		{
			v: 194962,
			vw: 130.377,
			a: 130.8832,
			o: 130.32,
			c: 130.4918,
			h: 130.52,
			l: 130.27,
			t: 1655476920000,
			n: 1736,
			op: 130.065
		},
		{
			v: 198045,
			vw: 130.5161,
			a: 130.8813,
			o: 130.495,
			c: 130.5711,
			h: 130.58,
			l: 130.44,
			t: 1655476980000,
			n: 1696,
			op: 130.065
		},
		{
			v: 188394,
			vw: 130.5703,
			a: 130.8798,
			o: 130.57,
			c: 130.59,
			h: 130.63,
			l: 130.48,
			t: 1655477040000,
			n: 1700,
			op: 130.065
		},
		{
			v: 161947,
			vw: 130.479,
			a: 130.878,
			o: 130.58,
			c: 130.37,
			h: 130.6,
			l: 130.3501,
			t: 1655477100000,
			n: 1655,
			op: 130.065
		},
		{
			v: 188877,
			vw: 130.3003,
			a: 130.8752,
			o: 130.3708,
			c: 130.2289,
			h: 130.39,
			l: 130.22,
			t: 1655477160000,
			n: 1900,
			op: 130.065
		},
		{
			v: 229577,
			vw: 130.169,
			a: 130.8709,
			o: 130.225,
			c: 130.0301,
			h: 130.3,
			l: 130.03,
			t: 1655477220000,
			n: 2229,
			op: 130.065
		},
		{
			v: 383754,
			vw: 129.9993,
			a: 130.8623,
			o: 130.04,
			c: 129.91,
			h: 130.06,
			l: 129.89,
			t: 1655477280000,
			n: 5453,
			op: 130.065
		},
		{
			v: 278523,
			vw: 130.0511,
			a: 130.8565,
			o: 129.91,
			c: 130.185,
			h: 130.29,
			l: 129.81,
			t: 1655477340000,
			n: 2430,
			op: 130.065
		},
		{
			v: 216604,
			vw: 130.1957,
			a: 130.8529,
			o: 130.18,
			c: 130.2689,
			h: 130.39,
			l: 130.06,
			t: 1655477400000,
			n: 1911,
			op: 130.065
		},
		{
			v: 154133,
			vw: 130.1981,
			a: 130.8503,
			o: 130.2701,
			c: 130.22,
			h: 130.289,
			l: 130.12,
			t: 1655477460000,
			n: 1477,
			op: 130.065
		},
		{
			v: 211273,
			vw: 130.2384,
			a: 130.847,
			o: 130.23,
			c: 130.3,
			h: 130.36,
			l: 130.1101,
			t: 1655477520000,
			n: 2071,
			op: 130.065
		},
		{
			v: 116784,
			vw: 130.2518,
			a: 130.8453,
			o: 130.3,
			c: 130.25,
			h: 130.31,
			l: 130.2,
			t: 1655477580000,
			n: 1255,
			op: 130.065
		},
		{
			v: 116987,
			vw: 130.2629,
			a: 130.8436,
			o: 130.24,
			c: 130.2623,
			h: 130.33,
			l: 130.21,
			t: 1655477640000,
			n: 1188,
			op: 130.065
		},
		{
			v: 240415,
			vw: 130.3666,
			a: 130.8407,
			o: 130.26,
			c: 130.35,
			h: 130.47,
			l: 130.25,
			t: 1655477700000,
			n: 2366,
			op: 130.065
		},
		{
			v: 300099,
			vw: 130.5412,
			a: 130.8385,
			o: 130.36,
			c: 130.67,
			h: 130.68,
			l: 130.3161,
			t: 1655477760000,
			n: 2593,
			op: 130.065
		},
		{
			v: 229382,
			vw: 130.5212,
			a: 130.8367,
			o: 130.66,
			c: 130.555,
			h: 130.66,
			l: 130.39,
			t: 1655477820000,
			n: 2303,
			op: 130.065
		},
		{
			v: 136185,
			vw: 130.5261,
			a: 130.8357,
			o: 130.5509,
			c: 130.6,
			h: 130.6,
			l: 130.4501,
			t: 1655477880000,
			n: 1330,
			op: 130.065
		},
		{
			v: 127049,
			vw: 130.584,
			a: 130.8349,
			o: 130.6,
			c: 130.5968,
			h: 130.67,
			l: 130.5,
			t: 1655477940000,
			n: 1322,
			op: 130.065
		},
		{
			v: 156288,
			vw: 130.4601,
			a: 130.8334,
			o: 130.6,
			c: 130.3526,
			h: 130.6201,
			l: 130.34,
			t: 1655478000000,
			n: 1590,
			op: 130.065
		},
		{
			v: 320616,
			vw: 130.3568,
			a: 130.8297,
			o: 130.36,
			c: 130.565,
			h: 130.62,
			l: 130.205,
			t: 1655478060000,
			n: 3115,
			op: 130.065
		},
		{
			v: 295129,
			vw: 130.7304,
			a: 130.829,
			o: 130.58,
			c: 130.7861,
			h: 130.89,
			l: 130.51,
			t: 1655478120000,
			n: 2633,
			op: 130.065
		},
		{
			v: 367596,
			vw: 130.9053,
			a: 130.8297,
			o: 130.79,
			c: 131.03,
			h: 131.14,
			l: 130.715,
			t: 1655478180000,
			n: 3414,
			op: 130.065
		},
		{
			v: 187467,
			vw: 131.0229,
			a: 130.8306,
			o: 131.03,
			c: 130.98,
			h: 131.08,
			l: 130.9706,
			t: 1655478240000,
			n: 1723,
			op: 130.065
		},
		{
			v: 183572,
			vw: 131.0148,
			a: 130.8314,
			o: 130.98,
			c: 131.15,
			h: 131.15,
			l: 130.93,
			t: 1655478300000,
			n: 1711,
			op: 130.065
		},
		{
			v: 291062,
			vw: 131.1999,
			a: 130.8339,
			o: 131.14,
			c: 131.2117,
			h: 131.29,
			l: 131.1,
			t: 1655478360000,
			n: 2134,
			op: 130.065
		},
		{
			v: 304341,
			vw: 131.4475,
			a: 130.8382,
			o: 131.22,
			c: 131.67,
			h: 131.69,
			l: 131.205,
			t: 1655478420000,
			n: 2570,
			op: 130.065
		},
		{
			v: 201477,
			vw: 131.6033,
			a: 130.8418,
			o: 131.665,
			c: 131.551,
			h: 131.68,
			l: 131.551,
			t: 1655478480000,
			n: 1885,
			op: 130.065
		},
		{
			v: 182793,
			vw: 131.6544,
			a: 130.8452,
			o: 131.56,
			c: 131.755,
			h: 131.76,
			l: 131.55,
			t: 1655478540000,
			n: 1667,
			op: 130.065
		},
		{
			v: 211545,
			vw: 131.6471,
			a: 130.8491,
			o: 131.75,
			c: 131.63,
			h: 131.76,
			l: 131.58,
			t: 1655478600000,
			n: 1943,
			op: 130.065
		},
		{
			v: 202708,
			vw: 131.563,
			a: 130.8524,
			o: 131.635,
			c: 131.4801,
			h: 131.65,
			l: 131.45,
			t: 1655478660000,
			n: 1778,
			op: 130.065
		},
		{
			v: 215056,
			vw: 131.4352,
			a: 130.8553,
			o: 131.4899,
			c: 131.385,
			h: 131.53,
			l: 131.3712,
			t: 1655478720000,
			n: 1707,
			op: 130.065
		},
		{
			v: 120466,
			vw: 131.4122,
			a: 130.8568,
			o: 131.385,
			c: 131.4724,
			h: 131.49,
			l: 131.37,
			t: 1655478780000,
			n: 1271,
			op: 130.065
		},
		{
			v: 129713,
			vw: 131.4534,
			a: 130.8586,
			o: 131.4792,
			c: 131.4101,
			h: 131.5,
			l: 131.41,
			t: 1655478840000,
			n: 1270,
			op: 130.065
		},
		{
			v: 172122,
			vw: 131.5131,
			a: 130.8611,
			o: 131.415,
			c: 131.46,
			h: 131.621,
			l: 131.4,
			t: 1655478900000,
			n: 1668,
			op: 130.065
		},
		{
			v: 256648,
			vw: 131.6097,
			a: 130.8654,
			o: 131.45,
			c: 131.735,
			h: 131.74,
			l: 131.43,
			t: 1655478960000,
			n: 2194,
			op: 130.065
		},
		{
			v: 197190,
			vw: 131.749,
			a: 130.8693,
			o: 131.74,
			c: 131.7,
			h: 131.8397,
			l: 131.67,
			t: 1655479020000,
			n: 1893,
			op: 130.065
		},
		{
			v: 261351,
			vw: 131.8533,
			a: 130.875,
			o: 131.6999,
			c: 131.98,
			h: 132.01,
			l: 131.68,
			t: 1655479080000,
			n: 2243,
			op: 130.065
		},
		{
			v: 390754,
			vw: 132.1423,
			a: 130.8859,
			o: 131.975,
			c: 132.1499,
			h: 132.3,
			l: 131.9017,
			t: 1655479140000,
			n: 3531,
			op: 130.065
		},
		{
			v: 200968,
			vw: 132.0292,
			a: 130.8909,
			o: 132.15,
			c: 131.87,
			h: 132.249,
			l: 131.87,
			t: 1655479200000,
			n: 2042,
			op: 130.065
		},
		{
			v: 205725,
			vw: 131.961,
			a: 130.8957,
			o: 131.87,
			c: 131.93,
			h: 132.07,
			l: 131.84,
			t: 1655479260000,
			n: 1957,
			op: 130.065
		},
		{
			v: 159064,
			vw: 131.9296,
			a: 130.8993,
			o: 131.93,
			c: 131.9001,
			h: 132.02,
			l: 131.86,
			t: 1655479320000,
			n: 1994,
			op: 130.065
		},
		{
			v: 139890,
			vw: 131.915,
			a: 130.9023,
			o: 131.8918,
			c: 131.925,
			h: 131.95,
			l: 131.85,
			t: 1655479380000,
			n: 1417,
			op: 130.065
		},
		{
			v: 153407,
			vw: 131.8886,
			a: 130.9056,
			o: 131.925,
			c: 131.9201,
			h: 131.95,
			l: 131.82,
			t: 1655479440000,
			n: 1703,
			op: 130.065
		},
		{
			v: 183261,
			vw: 131.8858,
			a: 130.9095,
			o: 131.92,
			c: 131.925,
			h: 131.95,
			l: 131.82,
			t: 1655479500000,
			n: 1775,
			op: 130.065
		},
		{
			v: 295926,
			vw: 131.9656,
			a: 130.9161,
			o: 131.92,
			c: 132.055,
			h: 132.11,
			l: 131.85,
			t: 1655479560000,
			n: 2356,
			op: 130.065
		},
		{
			v: 204229,
			vw: 132.1536,
			a: 130.9215,
			o: 132.06,
			c: 132.27,
			h: 132.28,
			l: 132.04,
			t: 1655479620000,
			n: 2002,
			op: 130.065
		},
		{
			v: 393952,
			vw: 132.4119,
			a: 130.9339,
			o: 132.27,
			c: 132.505,
			h: 132.535,
			l: 132.1812,
			t: 1655479680000,
			n: 2883,
			op: 130.065
		},
		{
			v: 229081,
			vw: 132.4368,
			a: 130.9411,
			o: 132.51,
			c: 132.51,
			h: 132.5397,
			l: 132.36,
			t: 1655479740000,
			n: 2151,
			op: 130.065
		},
		{
			v: 282949,
			vw: 132.6167,
			a: 130.951,
			o: 132.5147,
			c: 132.72,
			h: 132.72,
			l: 132.47,
			t: 1655479800000,
			n: 2508,
			op: 130.065
		},
		{
			v: 309509,
			vw: 132.7155,
			a: 130.9623,
			o: 132.72,
			c: 132.59,
			h: 132.83,
			l: 132.575,
			t: 1655479860000,
			n: 2420,
			op: 130.065
		},
		{
			v: 198919,
			vw: 132.7074,
			a: 130.9695,
			o: 132.59,
			c: 132.755,
			h: 132.82,
			l: 132.55,
			t: 1655479920000,
			n: 1770,
			op: 130.065
		},
		{
			v: 249442,
			vw: 132.5529,
			a: 130.9776,
			o: 132.75,
			c: 132.45,
			h: 132.76,
			l: 132.43,
			t: 1655479980000,
			n: 2119,
			op: 130.065
		},
		{
			v: 250363,
			vw: 132.5644,
			a: 130.9857,
			o: 132.46,
			c: 132.4101,
			h: 132.695,
			l: 132.41,
			t: 1655480040000,
			n: 2275,
			op: 130.065
		},
		{
			v: 212471,
			vw: 132.3865,
			a: 130.9917,
			o: 132.41,
			c: 132.31,
			h: 132.4989,
			l: 132.27,
			t: 1655480100000,
			n: 1759,
			op: 130.065
		},
		{
			v: 217713,
			vw: 132.3504,
			a: 130.9977,
			o: 132.31,
			c: 132.31,
			h: 132.4401,
			l: 132.29,
			t: 1655480160000,
			n: 1636,
			op: 130.065
		},
		{
			v: 190970,
			vw: 132.3962,
			a: 131.0031,
			o: 132.3,
			c: 132.4,
			h: 132.46,
			l: 132.2747,
			t: 1655480220000,
			n: 1516,
			op: 130.065
		},
		{
			v: 169283,
			vw: 132.2846,
			a: 131.0075,
			o: 132.4,
			c: 132.19,
			h: 132.45,
			l: 132.17,
			t: 1655480280000,
			n: 1653,
			op: 130.065
		},
		{
			v: 153464,
			vw: 132.2709,
			a: 131.0114,
			o: 132.195,
			c: 132.36,
			h: 132.3952,
			l: 132.15,
			t: 1655480340000,
			n: 1560,
			op: 130.065
		},
		{
			v: 188274,
			vw: 132.5025,
			a: 131.017,
			o: 132.365,
			c: 132.505,
			h: 132.63,
			l: 132.36,
			t: 1655480400000,
			n: 1839,
			op: 130.065
		},
		{
			v: 158080,
			vw: 132.4424,
			a: 131.0215,
			o: 132.505,
			c: 132.345,
			h: 132.54,
			l: 132.34,
			t: 1655480460000,
			n: 1339,
			op: 130.065
		},
		{
			v: 215149,
			vw: 132.1285,
			a: 131.0262,
			o: 132.34,
			c: 132,
			h: 132.34,
			l: 131.99,
			t: 1655480520000,
			n: 2078,
			op: 130.065
		},
		{
			v: 134815,
			vw: 132.0417,
			a: 131.0289,
			o: 132,
			c: 132.03,
			h: 132.13,
			l: 131.96,
			t: 1655480580000,
			n: 1260,
			op: 130.065
		},
		{
			v: 176894,
			vw: 131.9153,
			a: 131.032,
			o: 132.04,
			c: 131.8239,
			h: 132.04,
			l: 131.81,
			t: 1655480640000,
			n: 1517,
			op: 130.065
		},
		{
			v: 290991,
			vw: 131.7029,
			a: 131.0358,
			o: 131.82,
			c: 131.77,
			h: 131.82,
			l: 131.61,
			t: 1655480700000,
			n: 2261,
			op: 130.065
		},
		{
			v: 121508,
			vw: 131.7206,
			a: 131.0374,
			o: 131.77,
			c: 131.63,
			h: 131.79,
			l: 131.63,
			t: 1655480760000,
			n: 1209,
			op: 130.065
		},
		{
			v: 121487,
			vw: 131.6883,
			a: 131.039,
			o: 131.6399,
			c: 131.72,
			h: 131.74,
			l: 131.6399,
			t: 1655480820000,
			n: 1196,
			op: 130.065
		},
		{
			v: 188672,
			vw: 131.7622,
			a: 131.0416,
			o: 131.72,
			c: 131.83,
			h: 131.86,
			l: 131.68,
			t: 1655480880000,
			n: 1547,
			op: 130.065
		},
		{
			v: 130535,
			vw: 131.8127,
			a: 131.0436,
			o: 131.8299,
			c: 131.75,
			h: 131.88,
			l: 131.7389,
			t: 1655480940000,
			n: 1189,
			op: 130.065
		},
		{
			v: 165104,
			vw: 131.7737,
			a: 131.0459,
			o: 131.75,
			c: 131.715,
			h: 131.87,
			l: 131.7,
			t: 1655481000000,
			n: 1230,
			op: 130.065
		},
		{
			v: 306209,
			vw: 131.5302,
			a: 131.0487,
			o: 131.715,
			c: 131.42,
			h: 131.73,
			l: 131.36,
			t: 1655481060000,
			n: 2606,
			op: 130.065
		},
		{
			v: 197062,
			vw: 131.3752,
			a: 131.05,
			o: 131.4258,
			c: 131.3586,
			h: 131.4888,
			l: 131.3,
			t: 1655481120000,
			n: 1625,
			op: 130.065
		},
		{
			v: 198621,
			vw: 131.4268,
			a: 131.0514,
			o: 131.34,
			c: 131.4047,
			h: 131.5,
			l: 131.33,
			t: 1655481180000,
			n: 1599,
			op: 130.065
		},
		{
			v: 182504,
			vw: 131.2998,
			a: 131.0523,
			o: 131.4,
			c: 131.305,
			h: 131.45,
			l: 131.205,
			t: 1655481240000,
			n: 1856,
			op: 130.065
		},
		{
			v: 184085,
			vw: 131.2636,
			a: 131.053,
			o: 131.31,
			c: 131.24,
			h: 131.355,
			l: 131.225,
			t: 1655481300000,
			n: 1520,
			op: 130.065
		},
		{
			v: 164787,
			vw: 131.2984,
			a: 131.0538,
			o: 131.24,
			c: 131.23,
			h: 131.38,
			l: 131.22,
			t: 1655481360000,
			n: 1739,
			op: 130.065
		},
		{
			v: 173595,
			vw: 131.3192,
			a: 131.0546,
			o: 131.23,
			c: 131.36,
			h: 131.4203,
			l: 131.2,
			t: 1655481420000,
			n: 1641,
			op: 130.065
		},
		{
			v: 146970,
			vw: 131.3522,
			a: 131.0554,
			o: 131.38,
			c: 131.435,
			h: 131.44,
			l: 131.27,
			t: 1655481480000,
			n: 1293,
			op: 130.065
		},
		{
			v: 219392,
			vw: 131.5091,
			a: 131.0573,
			o: 131.45,
			c: 131.3465,
			h: 131.6,
			l: 131.3465,
			t: 1655481540000,
			n: 1936,
			op: 130.065
		},
		{
			v: 158015,
			vw: 131.2693,
			a: 131.0579,
			o: 131.35,
			c: 131.3797,
			h: 131.3797,
			l: 131.18,
			t: 1655481600000,
			n: 1578,
			op: 130.065
		},
		{
			v: 133020,
			vw: 131.2073,
			a: 131.0583,
			o: 131.3749,
			c: 131.25,
			h: 131.375,
			l: 131.135,
			t: 1655481660000,
			n: 1397,
			op: 130.065
		},
		{
			v: 143005,
			vw: 131.1787,
			a: 131.0586,
			o: 131.24,
			c: 131.119,
			h: 131.2777,
			l: 131.08,
			t: 1655481720000,
			n: 1429,
			op: 130.065
		},
		{
			v: 97887,
			vw: 131.1587,
			a: 131.0588,
			o: 131.12,
			c: 131.18,
			h: 131.21,
			l: 131.1,
			t: 1655481780000,
			n: 1025,
			op: 130.065
		},
		{
			v: 128814,
			vw: 131.2622,
			a: 131.0593,
			o: 131.18,
			c: 131.335,
			h: 131.3497,
			l: 131.16,
			t: 1655481840000,
			n: 1324,
			op: 130.065
		},
		{
			v: 130135,
			vw: 131.2972,
			a: 131.0598,
			o: 131.33,
			c: 131.28,
			h: 131.39,
			l: 131.2,
			t: 1655481900000,
			n: 1309,
			op: 130.065
		},
		{
			v: 185429,
			vw: 131.4361,
			a: 131.0611,
			o: 131.275,
			c: 131.45,
			h: 131.53,
			l: 131.22,
			t: 1655481960000,
			n: 1780,
			op: 130.065
		},
		{
			v: 120405,
			vw: 131.5162,
			a: 131.0621,
			o: 131.46,
			c: 131.53,
			h: 131.56,
			l: 131.45,
			t: 1655482020000,
			n: 1147,
			op: 130.065
		},
		{
			v: 184719,
			vw: 131.5635,
			a: 131.0638,
			o: 131.52,
			c: 131.525,
			h: 131.68,
			l: 131.425,
			t: 1655482080000,
			n: 1613,
			op: 130.065
		},
		{
			v: 114806,
			vw: 131.4881,
			a: 131.0647,
			o: 131.53,
			c: 131.5287,
			h: 131.54,
			l: 131.44,
			t: 1655482140000,
			n: 1083,
			op: 130.065
		},
		{
			v: 160078,
			vw: 131.6122,
			a: 131.0663,
			o: 131.53,
			c: 131.5107,
			h: 131.7101,
			l: 131.51,
			t: 1655482200000,
			n: 1277,
			op: 130.065
		},
		{
			v: 141089,
			vw: 131.4773,
			a: 131.0673,
			o: 131.52,
			c: 131.52,
			h: 131.55,
			l: 131.43,
			t: 1655482260000,
			n: 1172,
			op: 130.065
		},
		{
			v: 143875,
			vw: 131.6132,
			a: 131.0688,
			o: 131.53,
			c: 131.56,
			h: 131.68,
			l: 131.485,
			t: 1655482320000,
			n: 1210,
			op: 130.065
		},
		{
			v: 182226,
			vw: 131.7374,
			a: 131.0709,
			o: 131.5699,
			c: 131.9062,
			h: 131.91,
			l: 131.565,
			t: 1655482380000,
			n: 1522,
			op: 130.065
		},
		{
			v: 227268,
			vw: 131.9099,
			a: 131.0744,
			o: 131.9,
			c: 132,
			h: 132.01,
			l: 131.7901,
			t: 1655482440000,
			n: 1740,
			op: 130.065
		},
		{
			v: 272621,
			vw: 132.0122,
			a: 131.0789,
			o: 132.01,
			c: 131.98,
			h: 132.09,
			l: 131.93,
			t: 1655482500000,
			n: 1959,
			op: 130.065
		},
		{
			v: 130925,
			vw: 132.0457,
			a: 131.0812,
			o: 131.97,
			c: 132.04,
			h: 132.1,
			l: 131.97,
			t: 1655482560000,
			n: 1293,
			op: 130.065
		},
		{
			v: 238204,
			vw: 131.9662,
			a: 131.0849,
			o: 132.04,
			c: 132.025,
			h: 132.05,
			l: 131.86,
			t: 1655482620000,
			n: 1623,
			op: 130.065
		},
		{
			v: 157552,
			vw: 131.8571,
			a: 131.0871,
			o: 132.0299,
			c: 131.82,
			h: 132.0299,
			l: 131.71,
			t: 1655482680000,
			n: 1426,
			op: 130.065
		},
		{
			v: 119261,
			vw: 131.8017,
			a: 131.0886,
			o: 131.83,
			c: 131.7765,
			h: 131.86,
			l: 131.73,
			t: 1655482740000,
			n: 1116,
			op: 130.065
		},
		{
			v: 136029,
			vw: 131.7892,
			a: 131.0902,
			o: 131.775,
			c: 131.77,
			h: 131.8428,
			l: 131.72,
			t: 1655482800000,
			n: 1059,
			op: 130.065
		},
		{
			v: 113335,
			vw: 131.7369,
			a: 131.0915,
			o: 131.77,
			c: 131.715,
			h: 131.8,
			l: 131.675,
			t: 1655482860000,
			n: 1064,
			op: 130.065
		},
		{
			v: 110324,
			vw: 131.7227,
			a: 131.0927,
			o: 131.72,
			c: 131.75,
			h: 131.7688,
			l: 131.67,
			t: 1655482920000,
			n: 1066,
			op: 130.065
		},
		{
			v: 125451,
			vw: 131.8358,
			a: 131.0944,
			o: 131.76,
			c: 131.835,
			h: 131.9,
			l: 131.74,
			t: 1655482980000,
			n: 1117,
			op: 130.065
		},
		{
			v: 207865,
			vw: 131.846,
			a: 131.0971,
			o: 131.8346,
			c: 131.865,
			h: 131.97,
			l: 131.74,
			t: 1655483040000,
			n: 1811,
			op: 130.065
		},
		{
			v: 136427,
			vw: 131.8104,
			a: 131.0988,
			o: 131.8687,
			c: 131.83,
			h: 131.94,
			l: 131.69,
			t: 1655483100000,
			n: 1117,
			op: 130.065
		},
		{
			v: 128070,
			vw: 131.9042,
			a: 131.1006,
			o: 131.825,
			c: 131.91,
			h: 131.99,
			l: 131.82,
			t: 1655483160000,
			n: 1056,
			op: 130.065
		},
		{
			v: 205425,
			vw: 131.9675,
			a: 131.1036,
			o: 131.9086,
			c: 132.065,
			h: 132.08,
			l: 131.88,
			t: 1655483220000,
			n: 1644,
			op: 130.065
		},
		{
			v: 121238,
			vw: 131.9358,
			a: 131.1054,
			o: 132.05,
			c: 131.825,
			h: 132.05,
			l: 131.79,
			t: 1655483280000,
			n: 1189,
			op: 130.065
		},
		{
			v: 133045,
			vw: 131.8365,
			a: 131.1071,
			o: 131.82,
			c: 131.89,
			h: 131.92,
			l: 131.76,
			t: 1655483340000,
			n: 1197,
			op: 130.065
		},
		{
			v: 115120,
			vw: 131.8729,
			a: 131.1086,
			o: 131.8987,
			c: 131.86,
			h: 131.94,
			l: 131.82,
			t: 1655483400000,
			n: 969,
			op: 130.065
		},
		{
			v: 136285,
			vw: 131.7792,
			a: 131.1101,
			o: 131.85,
			c: 131.8379,
			h: 131.86,
			l: 131.69,
			t: 1655483460000,
			n: 1307,
			op: 130.065
		},
		{
			v: 109940,
			vw: 131.8245,
			a: 131.1115,
			o: 131.84,
			c: 131.8,
			h: 131.89,
			l: 131.755,
			t: 1655483520000,
			n: 851,
			op: 130.065
		},
		{
			v: 99172,
			vw: 131.8479,
			a: 131.1127,
			o: 131.8,
			c: 131.92,
			h: 131.92,
			l: 131.76,
			t: 1655483580000,
			n: 966,
			op: 130.065
		},
		{
			v: 196754,
			vw: 131.9361,
			a: 131.1155,
			o: 131.92,
			c: 131.865,
			h: 132,
			l: 131.845,
			t: 1655483640000,
			n: 1259,
			op: 130.065
		},
		{
			v: 113685,
			vw: 131.8159,
			a: 131.1168,
			o: 131.86,
			c: 131.795,
			h: 131.88,
			l: 131.765,
			t: 1655483700000,
			n: 1011,
			op: 130.065
		},
		{
			v: 120051,
			vw: 131.7566,
			a: 131.1181,
			o: 131.785,
			c: 131.86,
			h: 131.88,
			l: 131.66,
			t: 1655483760000,
			n: 1176,
			op: 130.065
		},
		{
			v: 118707,
			vw: 131.8338,
			a: 131.1196,
			o: 131.87,
			c: 131.88,
			h: 131.88,
			l: 131.7599,
			t: 1655483820000,
			n: 1009,
			op: 130.065
		},
		{
			v: 151383,
			vw: 131.9387,
			a: 131.1217,
			o: 131.88,
			c: 132.1,
			h: 132.1199,
			l: 131.8,
			t: 1655483880000,
			n: 1247,
			op: 130.065
		},
		{
			v: 188690,
			vw: 132.0618,
			a: 131.1246,
			o: 132.11,
			c: 132.13,
			h: 132.1477,
			l: 131.97,
			t: 1655483940000,
			n: 1300,
			op: 130.065
		},
		{
			v: 131836,
			vw: 132.0667,
			a: 131.1267,
			o: 132.135,
			c: 132.0699,
			h: 132.15,
			l: 132,
			t: 1655484000000,
			n: 995,
			op: 130.065
		},
		{
			v: 129638,
			vw: 132.0761,
			a: 131.1288,
			o: 132.05,
			c: 132.04,
			h: 132.15,
			l: 132.01,
			t: 1655484060000,
			n: 1007,
			op: 130.065
		},
		{
			v: 178592,
			vw: 131.902,
			a: 131.1311,
			o: 132.03,
			c: 131.84,
			h: 132.035,
			l: 131.77,
			t: 1655484120000,
			n: 1482,
			op: 130.065
		},
		{
			v: 138007,
			vw: 131.8665,
			a: 131.1328,
			o: 131.85,
			c: 131.97,
			h: 131.98,
			l: 131.78,
			t: 1655484180000,
			n: 1197,
			op: 130.065
		},
		{
			v: 194844,
			vw: 132.0531,
			a: 131.1358,
			o: 131.97,
			c: 132.15,
			h: 132.15,
			l: 131.94,
			t: 1655484240000,
			n: 1347,
			op: 130.065
		},
		{
			v: 163577,
			vw: 132.0131,
			a: 131.1381,
			o: 132.14,
			c: 131.95,
			h: 132.18,
			l: 131.91,
			t: 1655484300000,
			n: 1354,
			op: 130.065
		},
		{
			v: 143969,
			vw: 132.0804,
			a: 131.1404,
			o: 131.93,
			c: 132.21,
			h: 132.21,
			l: 131.93,
			t: 1655484360000,
			n: 1139,
			op: 130.065
		},
		{
			v: 193170,
			vw: 132.2003,
			a: 131.1437,
			o: 132.2097,
			c: 132.24,
			h: 132.27,
			l: 132.09,
			t: 1655484420000,
			n: 1380,
			op: 130.065
		},
		{
			v: 172794,
			vw: 132.2406,
			a: 131.1468,
			o: 132.25,
			c: 132.21,
			h: 132.3189,
			l: 132.17,
			t: 1655484480000,
			n: 1405,
			op: 130.065
		},
		{
			v: 192113,
			vw: 132.1835,
			a: 131.1501,
			o: 132.2,
			c: 132.2538,
			h: 132.32,
			l: 132.155,
			t: 1655484540000,
			n: 1171,
			op: 130.065
		},
		{
			v: 132607,
			vw: 132.1307,
			a: 131.1522,
			o: 132.25,
			c: 132.1,
			h: 132.27,
			l: 132.04,
			t: 1655484600000,
			n: 1111,
			op: 130.065
		},
		{
			v: 166937,
			vw: 132.0038,
			a: 131.1545,
			o: 132.09,
			c: 131.875,
			h: 132.14,
			l: 131.87,
			t: 1655484660000,
			n: 1241,
			op: 130.065
		},
		{
			v: 158352,
			vw: 131.8782,
			a: 131.1564,
			o: 131.87,
			c: 131.9,
			h: 131.93,
			l: 131.83,
			t: 1655484720000,
			n: 1164,
			op: 130.065
		},
		{
			v: 129845,
			vw: 131.8745,
			a: 131.1579,
			o: 131.9,
			c: 131.795,
			h: 131.96,
			l: 131.79,
			t: 1655484780000,
			n: 1166,
			op: 130.065
		},
		{
			v: 108199,
			vw: 131.8019,
			a: 131.159,
			o: 131.79,
			c: 131.8,
			h: 131.84,
			l: 131.74,
			t: 1655484840000,
			n: 845,
			op: 130.065
		},
		{
			v: 117878,
			vw: 131.8369,
			a: 131.1603,
			o: 131.81,
			c: 131.8,
			h: 131.91,
			l: 131.76,
			t: 1655484900000,
			n: 1240,
			op: 130.065
		},
		{
			v: 123349,
			vw: 131.8575,
			a: 131.1617,
			o: 131.8,
			c: 131.84,
			h: 131.95,
			l: 131.74,
			t: 1655484960000,
			n: 1087,
			op: 130.065
		},
		{
			v: 94841,
			vw: 131.8556,
			a: 131.1628,
			o: 131.8399,
			c: 131.8053,
			h: 131.91,
			l: 131.8,
			t: 1655485020000,
			n: 930,
			op: 130.065
		},
		{
			v: 148524,
			vw: 131.9911,
			a: 131.1647,
			o: 131.81,
			c: 131.96,
			h: 132.11,
			l: 131.8,
			t: 1655485080000,
			n: 1261,
			op: 130.065
		},
		{
			v: 98179,
			vw: 131.989,
			a: 131.166,
			o: 131.96,
			c: 131.97,
			h: 132.05,
			l: 131.95,
			t: 1655485140000,
			n: 815,
			op: 130.065
		},
		{
			v: 115903,
			vw: 131.9651,
			a: 131.1675,
			o: 131.98,
			c: 131.9918,
			h: 132.07,
			l: 131.86,
			t: 1655485200000,
			n: 1146,
			op: 130.065
		},
		{
			v: 143610,
			vw: 132.0681,
			a: 131.1696,
			o: 132,
			c: 132.13,
			h: 132.145,
			l: 131.99,
			t: 1655485260000,
			n: 1046,
			op: 130.065
		},
		{
			v: 190351,
			vw: 132.2223,
			a: 131.1728,
			o: 132.13,
			c: 132.28,
			h: 132.314,
			l: 132.1,
			t: 1655485320000,
			n: 1386,
			op: 130.065
		},
		{
			v: 105198,
			vw: 132.2646,
			a: 131.1746,
			o: 132.28,
			c: 132.27,
			h: 132.31,
			l: 132.22,
			t: 1655485380000,
			n: 892,
			op: 130.065
		},
		{
			v: 116413,
			vw: 132.3186,
			a: 131.1767,
			o: 132.27,
			c: 132.325,
			h: 132.36,
			l: 132.27,
			t: 1655485440000,
			n: 1103,
			op: 130.065
		},
		{
			v: 320239,
			vw: 132.3716,
			a: 131.1827,
			o: 132.32,
			c: 132.3766,
			h: 132.45,
			l: 132.27,
			t: 1655485500000,
			n: 2540,
			op: 130.065
		},
		{
			v: 127462,
			vw: 132.3168,
			a: 131.185,
			o: 132.38,
			c: 132.29,
			h: 132.42,
			l: 132.25,
			t: 1655485560000,
			n: 1106,
			op: 130.065
		},
		{
			v: 214180,
			vw: 132.4656,
			a: 131.1893,
			o: 132.29,
			c: 132.49,
			h: 132.58,
			l: 132.27,
			t: 1655485620000,
			n: 1978,
			op: 130.065
		},
		{
			v: 207441,
			vw: 132.5552,
			a: 131.1937,
			o: 132.5,
			c: 132.6083,
			h: 132.65,
			l: 132.48,
			t: 1655485680000,
			n: 1764,
			op: 130.065
		},
		{
			v: 291988,
			vw: 132.6303,
			a: 131.2003,
			o: 132.59,
			c: 132.78,
			h: 132.86,
			l: 132.585,
			t: 1655485740000,
			n: 1863,
			op: 130.065
		},
		{
			v: 158176,
			vw: 132.6956,
			a: 131.2039,
			o: 132.7902,
			c: 132.695,
			h: 132.7902,
			l: 132.59,
			t: 1655485800000,
			n: 1215,
			op: 130.065
		},
		{
			v: 223031,
			vw: 132.8266,
			a: 131.2095,
			o: 132.7,
			c: 132.855,
			h: 132.93,
			l: 132.7,
			t: 1655485860000,
			n: 1697,
			op: 130.065
		},
		{
			v: 363948,
			vw: 132.9893,
			a: 131.2195,
			o: 132.85,
			c: 133.0107,
			h: 133.06,
			l: 132.8235,
			t: 1655485920000,
			n: 3414,
			op: 130.065
		},
		{
			v: 187830,
			vw: 132.947,
			a: 131.2245,
			o: 133.03,
			c: 132.82,
			h: 133.079,
			l: 132.81,
			t: 1655485980000,
			n: 1561,
			op: 130.065
		},
		{
			v: 140258,
			vw: 132.8078,
			a: 131.2279,
			o: 132.82,
			c: 132.86,
			h: 132.89,
			l: 132.75,
			t: 1655486040000,
			n: 1275,
			op: 130.065
		},
		{
			v: 148802,
			vw: 132.7578,
			a: 131.2314,
			o: 132.8576,
			c: 132.6557,
			h: 132.88,
			l: 132.64,
			t: 1655486100000,
			n: 1269,
			op: 130.065
		},
		{
			v: 112158,
			vw: 132.6735,
			a: 131.2338,
			o: 132.65,
			c: 132.74,
			h: 132.75,
			l: 132.61,
			t: 1655486160000,
			n: 1184,
			op: 130.065
		},
		{
			v: 151879,
			vw: 132.7686,
			a: 131.2374,
			o: 132.74,
			c: 132.83,
			h: 132.84,
			l: 132.6961,
			t: 1655486220000,
			n: 1039,
			op: 130.065
		},
		{
			v: 102699,
			vw: 132.7772,
			a: 131.2398,
			o: 132.84,
			c: 132.6872,
			h: 132.88,
			l: 132.68,
			t: 1655486280000,
			n: 964,
			op: 130.065
		},
		{
			v: 96425,
			vw: 132.6839,
			a: 131.2419,
			o: 132.69,
			c: 132.69,
			h: 132.75,
			l: 132.61,
			t: 1655486340000,
			n: 999,
			op: 130.065
		},
		{
			v: 102785,
			vw: 132.6232,
			a: 131.244,
			o: 132.69,
			c: 132.57,
			h: 132.7199,
			l: 132.53,
			t: 1655486400000,
			n: 1048,
			op: 130.065
		},
		{
			v: 111572,
			vw: 132.594,
			a: 131.2463,
			o: 132.56,
			c: 132.5986,
			h: 132.655,
			l: 132.51,
			t: 1655486460000,
			n: 962,
			op: 130.065
		},
		{
			v: 82059,
			vw: 132.5721,
			a: 131.248,
			o: 132.6,
			c: 132.58,
			h: 132.6199,
			l: 132.53,
			t: 1655486520000,
			n: 781,
			op: 130.065
		},
		{
			v: 168218,
			vw: 132.5515,
			a: 131.2513,
			o: 132.58,
			c: 132.6184,
			h: 132.64,
			l: 132.46,
			t: 1655486580000,
			n: 1353,
			op: 130.065
		},
		{
			v: 128055,
			vw: 132.7136,
			a: 131.2541,
			o: 132.61,
			c: 132.7896,
			h: 132.81,
			l: 132.5701,
			t: 1655486640000,
			n: 1193,
			op: 130.065
		},
		{
			v: 126146,
			vw: 132.6804,
			a: 131.2568,
			o: 132.78,
			c: 132.6,
			h: 132.8141,
			l: 132.56,
			t: 1655486700000,
			n: 1180,
			op: 130.065
		},
		{
			v: 124050,
			vw: 132.5081,
			a: 131.2591,
			o: 132.5937,
			c: 132.485,
			h: 132.6,
			l: 132.44,
			t: 1655486760000,
			n: 1072,
			op: 130.065
		},
		{
			v: 132858,
			vw: 132.521,
			a: 131.2616,
			o: 132.48,
			c: 132.4883,
			h: 132.615,
			l: 132.43,
			t: 1655486820000,
			n: 1237,
			op: 130.065
		},
		{
			v: 94147,
			vw: 132.4489,
			a: 131.2633,
			o: 132.48,
			c: 132.52,
			h: 132.52,
			l: 132.4,
			t: 1655486880000,
			n: 883,
			op: 130.065
		},
		{
			v: 102712,
			vw: 132.4621,
			a: 131.2651,
			o: 132.515,
			c: 132.4491,
			h: 132.57,
			l: 132.345,
			t: 1655486940000,
			n: 1085,
			op: 130.065
		},
		{
			v: 86498,
			vw: 132.4664,
			a: 131.2667,
			o: 132.45,
			c: 132.385,
			h: 132.51,
			l: 132.38,
			t: 1655487000000,
			n: 814,
			op: 130.065
		},
		{
			v: 139298,
			vw: 132.3436,
			a: 131.2689,
			o: 132.38,
			c: 132.285,
			h: 132.44,
			l: 132.245,
			t: 1655487060000,
			n: 1023,
			op: 130.065
		},
		{
			v: 159727,
			vw: 132.2329,
			a: 131.2712,
			o: 132.28,
			c: 132.23,
			h: 132.29,
			l: 132.17,
			t: 1655487120000,
			n: 1144,
			op: 130.065
		},
		{
			v: 123958,
			vw: 132.3057,
			a: 131.2731,
			o: 132.2201,
			c: 132.39,
			h: 132.39,
			l: 132.21,
			t: 1655487180000,
			n: 1071,
			op: 130.065
		},
		{
			v: 146593,
			vw: 132.3555,
			a: 131.2754,
			o: 132.37,
			c: 132.42,
			h: 132.42,
			l: 132.3,
			t: 1655487240000,
			n: 1090,
			op: 130.065
		},
		{
			v: 121730,
			vw: 132.3757,
			a: 131.2774,
			o: 132.41,
			c: 132.41,
			h: 132.46,
			l: 132.29,
			t: 1655487300000,
			n: 1042,
			op: 130.065
		},
		{
			v: 161545,
			vw: 132.3845,
			a: 131.28,
			o: 132.4185,
			c: 132.4757,
			h: 132.4958,
			l: 132.275,
			t: 1655487360000,
			n: 1173,
			op: 130.065
		},
		{
			v: 104496,
			vw: 132.3665,
			a: 131.2817,
			o: 132.47,
			c: 132.26,
			h: 132.49,
			l: 132.24,
			t: 1655487420000,
			n: 944,
			op: 130.065
		},
		{
			v: 123800,
			vw: 132.1892,
			a: 131.2833,
			o: 132.26,
			c: 132.145,
			h: 132.291,
			l: 132.13,
			t: 1655487480000,
			n: 1095,
			op: 130.065
		},
		{
			v: 119803,
			vw: 132.1318,
			a: 131.2848,
			o: 132.15,
			c: 132.1813,
			h: 132.19,
			l: 132.06,
			t: 1655487540000,
			n: 1131,
			op: 130.065
		},
		{
			v: 101393,
			vw: 132.1267,
			a: 131.2861,
			o: 132.17,
			c: 132.115,
			h: 132.195,
			l: 132.055,
			t: 1655487600000,
			n: 1069,
			op: 130.065
		},
		{
			v: 220486,
			vw: 132.2135,
			a: 131.289,
			o: 132.115,
			c: 132.17,
			h: 132.3,
			l: 132.115,
			t: 1655487660000,
			n: 1235,
			op: 130.065
		},
		{
			v: 135500,
			vw: 132.1847,
			a: 131.2908,
			o: 132.16,
			c: 132.265,
			h: 132.2889,
			l: 132.1,
			t: 1655487720000,
			n: 1259,
			op: 130.065
		},
		{
			v: 97945,
			vw: 132.1984,
			a: 131.2921,
			o: 132.26,
			c: 132.135,
			h: 132.26,
			l: 132.11,
			t: 1655487780000,
			n: 974,
			op: 130.065
		},
		{
			v: 87986,
			vw: 132.1652,
			a: 131.2932,
			o: 132.15,
			c: 132.12,
			h: 132.21,
			l: 132.1075,
			t: 1655487840000,
			n: 832,
			op: 130.065
		},
		{
			v: 139587,
			vw: 132.1003,
			a: 131.2948,
			o: 132.11,
			c: 132.085,
			h: 132.1562,
			l: 132.0456,
			t: 1655487900000,
			n: 1231,
			op: 130.065
		},
		{
			v: 283943,
			vw: 131.8904,
			a: 131.2973,
			o: 132.08,
			c: 131.74,
			h: 132.085,
			l: 131.74,
			t: 1655487960000,
			n: 2712,
			op: 130.065
		},
		{
			v: 185301,
			vw: 131.7443,
			a: 131.2984,
			o: 131.7449,
			c: 131.81,
			h: 131.85,
			l: 131.65,
			t: 1655488020000,
			n: 1721,
			op: 130.065
		},
		{
			v: 154879,
			vw: 131.886,
			a: 131.2997,
			o: 131.8101,
			c: 131.8977,
			h: 131.94,
			l: 131.8101,
			t: 1655488080000,
			n: 1427,
			op: 130.065
		},
		{
			v: 158380,
			vw: 132.0014,
			a: 131.3013,
			o: 131.9,
			c: 131.995,
			h: 132.08,
			l: 131.86,
			t: 1655488140000,
			n: 1290,
			op: 130.065
		},
		{
			v: 112028,
			vw: 132.0965,
			a: 131.3026,
			o: 131.99,
			c: 132.1001,
			h: 132.17,
			l: 131.99,
			t: 1655488200000,
			n: 985,
			op: 130.065
		},
		{
			v: 92843,
			vw: 132.1025,
			a: 131.3037,
			o: 132.1025,
			c: 132.08,
			h: 132.1689,
			l: 132.03,
			t: 1655488260000,
			n: 829,
			op: 130.065
		},
		{
			v: 96744,
			vw: 132.0804,
			a: 131.3047,
			o: 132.08,
			c: 132.07,
			h: 132.16,
			l: 132,
			t: 1655488320000,
			n: 934,
			op: 130.065
		},
		{
			v: 114856,
			vw: 132.032,
			a: 131.3059,
			o: 132.08,
			c: 131.935,
			h: 132.11,
			l: 131.93,
			t: 1655488380000,
			n: 1011,
			op: 130.065
		},
		{
			v: 109638,
			vw: 131.9775,
			a: 131.307,
			o: 131.93,
			c: 131.9607,
			h: 132.04,
			l: 131.915,
			t: 1655488440000,
			n: 1069,
			op: 130.065
		},
		{
			v: 143963,
			vw: 132.0325,
			a: 131.3084,
			o: 131.96,
			c: 132.06,
			h: 132.06,
			l: 131.91,
			t: 1655488500000,
			n: 1085,
			op: 130.065
		},
		{
			v: 165981,
			vw: 132.1309,
			a: 131.3104,
			o: 132.06,
			c: 132.2,
			h: 132.23,
			l: 132.02,
			t: 1655488560000,
			n: 1269,
			op: 130.065
		},
		{
			v: 150246,
			vw: 132.194,
			a: 131.3122,
			o: 132.195,
			c: 132.16,
			h: 132.25,
			l: 132.14,
			t: 1655488620000,
			n: 1310,
			op: 130.065
		},
		{
			v: 125823,
			vw: 132.0642,
			a: 131.3136,
			o: 132.165,
			c: 132.05,
			h: 132.165,
			l: 132,
			t: 1655488680000,
			n: 1123,
			op: 130.065
		},
		{
			v: 93363,
			vw: 132.0076,
			a: 131.3145,
			o: 132.05,
			c: 132.0737,
			h: 132.08,
			l: 131.95,
			t: 1655488740000,
			n: 906,
			op: 130.065
		},
		{
			v: 146937,
			vw: 132.0804,
			a: 131.3161,
			o: 132.08,
			c: 132.11,
			h: 132.14,
			l: 132,
			t: 1655488800000,
			n: 1013,
			op: 130.065
		},
		{
			v: 96047,
			vw: 132.1606,
			a: 131.3172,
			o: 132.11,
			c: 132.15,
			h: 132.23,
			l: 132.1098,
			t: 1655488860000,
			n: 997,
			op: 130.065
		},
		{
			v: 138463,
			vw: 132.2314,
			a: 131.319,
			o: 132.16,
			c: 132.26,
			h: 132.28,
			l: 132.16,
			t: 1655488920000,
			n: 1186,
			op: 130.065
		},
		{
			v: 153355,
			vw: 132.1016,
			a: 131.3206,
			o: 132.25,
			c: 132.0149,
			h: 132.2527,
			l: 132,
			t: 1655488980000,
			n: 1277,
			op: 130.065
		},
		{
			v: 105897,
			vw: 132.0427,
			a: 131.3217,
			o: 132.01,
			c: 132.05,
			h: 132.07,
			l: 131.99,
			t: 1655489040000,
			n: 1043,
			op: 130.065
		},
		{
			v: 136843,
			vw: 132.0781,
			a: 131.3231,
			o: 132.04,
			c: 132.14,
			h: 132.1599,
			l: 132,
			t: 1655489100000,
			n: 1095,
			op: 130.065
		},
		{
			v: 213775,
			vw: 131.977,
			a: 131.3251,
			o: 132.15,
			c: 131.91,
			h: 132.1587,
			l: 131.85,
			t: 1655489160000,
			n: 1607,
			op: 130.065
		},
		{
			v: 101123,
			vw: 131.9388,
			a: 131.3259,
			o: 131.91,
			c: 131.94,
			h: 131.985,
			l: 131.9,
			t: 1655489220000,
			n: 912,
			op: 130.065
		},
		{
			v: 104150,
			vw: 131.9871,
			a: 131.3269,
			o: 131.9406,
			c: 132.02,
			h: 132.04,
			l: 131.925,
			t: 1655489280000,
			n: 944,
			op: 130.065
		},
		{
			v: 116538,
			vw: 132.0952,
			a: 131.3281,
			o: 132.035,
			c: 132.16,
			h: 132.17,
			l: 131.99,
			t: 1655489340000,
			n: 1080,
			op: 130.065
		},
		{
			v: 90602,
			vw: 132.1251,
			a: 131.3291,
			o: 132.165,
			c: 132.13,
			h: 132.19,
			l: 132.0708,
			t: 1655489400000,
			n: 955,
			op: 130.065
		},
		{
			v: 102789,
			vw: 132.0475,
			a: 131.3301,
			o: 132.126,
			c: 132,
			h: 132.14,
			l: 131.98,
			t: 1655489460000,
			n: 853,
			op: 130.065
		},
		{
			v: 83829,
			vw: 132.0969,
			a: 131.331,
			o: 132,
			c: 132.17,
			h: 132.17,
			l: 131.99,
			t: 1655489520000,
			n: 767,
			op: 130.065
		},
		{
			v: 93837,
			vw: 132.059,
			a: 131.332,
			o: 132.17,
			c: 132.0902,
			h: 132.18,
			l: 131.98,
			t: 1655489580000,
			n: 965,
			op: 130.065
		},
		{
			v: 175939,
			vw: 132.1859,
			a: 131.334,
			o: 132.095,
			c: 132.2687,
			h: 132.29,
			l: 132.03,
			t: 1655489640000,
			n: 1453,
			op: 130.065
		},
		{
			v: 139222,
			vw: 132.2374,
			a: 131.3357,
			o: 132.26,
			c: 132.28,
			h: 132.32,
			l: 132.14,
			t: 1655489700000,
			n: 1187,
			op: 130.065
		},
		{
			v: 184340,
			vw: 132.4203,
			a: 131.3385,
			o: 132.3,
			c: 132.45,
			h: 132.48,
			l: 132.3,
			t: 1655489760000,
			n: 1562,
			op: 130.065
		},
		{
			v: 127386,
			vw: 132.4243,
			a: 131.3403,
			o: 132.46,
			c: 132.43,
			h: 132.4784,
			l: 132.35,
			t: 1655489820000,
			n: 982,
			op: 130.065
		},
		{
			v: 79095,
			vw: 132.4225,
			a: 131.3415,
			o: 132.43,
			c: 132.405,
			h: 132.47,
			l: 132.37,
			t: 1655489880000,
			n: 796,
			op: 130.065
		},
		{
			v: 94060,
			vw: 132.3657,
			a: 131.3428,
			o: 132.4,
			c: 132.345,
			h: 132.43,
			l: 132.32,
			t: 1655489940000,
			n: 919,
			op: 130.065
		},
		{
			v: 127539,
			vw: 132.4175,
			a: 131.3447,
			o: 132.345,
			c: 132.46,
			h: 132.47,
			l: 132.34,
			t: 1655490000000,
			n: 1020,
			op: 130.065
		},
		{
			v: 95604,
			vw: 132.4089,
			a: 131.3461,
			o: 132.46,
			c: 132.43,
			h: 132.47,
			l: 132.32,
			t: 1655490060000,
			n: 967,
			op: 130.065
		},
		{
			v: 66869,
			vw: 132.4128,
			a: 131.347,
			o: 132.445,
			c: 132.42,
			h: 132.445,
			l: 132.37,
			t: 1655490120000,
			n: 652,
			op: 130.065
		},
		{
			v: 196869,
			vw: 132.4667,
			a: 131.35,
			o: 132.43,
			c: 132.44,
			h: 132.52,
			l: 132.38,
			t: 1655490180000,
			n: 1557,
			op: 130.065
		},
		{
			v: 150151,
			vw: 132.4726,
			a: 131.3523,
			o: 132.44,
			c: 132.45,
			h: 132.54,
			l: 132.39,
			t: 1655490240000,
			n: 1383,
			op: 130.065
		},
		{
			v: 109962,
			vw: 132.4069,
			a: 131.3538,
			o: 132.45,
			c: 132.3935,
			h: 132.47,
			l: 132.37,
			t: 1655490300000,
			n: 1039,
			op: 130.065
		},
		{
			v: 109688,
			vw: 132.3996,
			a: 131.3554,
			o: 132.385,
			c: 132.445,
			h: 132.46,
			l: 132.34,
			t: 1655490360000,
			n: 1038,
			op: 130.065
		},
		{
			v: 90762,
			vw: 132.4562,
			a: 131.3567,
			o: 132.44,
			c: 132.47,
			h: 132.5056,
			l: 132.39,
			t: 1655490420000,
			n: 831,
			op: 130.065
		},
		{
			v: 122332,
			vw: 132.3843,
			a: 131.3584,
			o: 132.47,
			c: 132.27,
			h: 132.47,
			l: 132.27,
			t: 1655490480000,
			n: 963,
			op: 130.065
		},
		{
			v: 150572,
			vw: 132.2142,
			a: 131.3601,
			o: 132.27,
			c: 132.215,
			h: 132.2769,
			l: 132.16,
			t: 1655490540000,
			n: 1284,
			op: 130.065
		},
		{
			v: 157713,
			vw: 132.0988,
			a: 131.3617,
			o: 132.21,
			c: 132.0201,
			h: 132.215,
			l: 132.01,
			t: 1655490600000,
			n: 1351,
			op: 130.065
		},
		{
			v: 113541,
			vw: 132.0347,
			a: 131.3627,
			o: 132.03,
			c: 132.05,
			h: 132.09,
			l: 132.01,
			t: 1655490660000,
			n: 1051,
			op: 130.065
		},
		{
			v: 198073,
			vw: 132.0277,
			a: 131.3644,
			o: 132.05,
			c: 132,
			h: 132.1185,
			l: 132,
			t: 1655490720000,
			n: 1622,
			op: 130.065
		},
		{
			v: 229584,
			vw: 131.9145,
			a: 131.3661,
			o: 132,
			c: 131.88,
			h: 132.01,
			l: 131.81,
			t: 1655490780000,
			n: 1606,
			op: 130.065
		},
		{
			v: 184114,
			vw: 131.9725,
			a: 131.3676,
			o: 131.9,
			c: 132.095,
			h: 132.095,
			l: 131.89,
			t: 1655490840000,
			n: 1245,
			op: 130.065
		},
		{
			v: 211828,
			vw: 132.069,
			a: 131.3695,
			o: 132.1,
			c: 132.04,
			h: 132.13,
			l: 131.97,
			t: 1655490900000,
			n: 1318,
			op: 130.065
		},
		{
			v: 111147,
			vw: 132.0799,
			a: 131.3706,
			o: 132.0425,
			c: 132.08,
			h: 132.12,
			l: 132.0425,
			t: 1655490960000,
			n: 824,
			op: 130.065
		},
		{
			v: 116363,
			vw: 132.0752,
			a: 131.3716,
			o: 132.07,
			c: 132.02,
			h: 132.13,
			l: 132.01,
			t: 1655491020000,
			n: 964,
			op: 130.065
		},
		{
			v: 104007,
			vw: 132.0225,
			a: 131.3725,
			o: 132.02,
			c: 132.095,
			h: 132.11,
			l: 131.96,
			t: 1655491080000,
			n: 1028,
			op: 130.065
		},
		{
			v: 135767,
			vw: 132.1224,
			a: 131.3739,
			o: 132.0901,
			c: 132.2189,
			h: 132.22,
			l: 132.02,
			t: 1655491140000,
			n: 1208,
			op: 130.065
		},
		{
			v: 184945,
			vw: 132.3419,
			a: 131.3762,
			o: 132.22,
			c: 132.3299,
			h: 132.43,
			l: 132.22,
			t: 1655491200000,
			n: 1541,
			op: 130.065
		},
		{
			v: 123732,
			vw: 132.328,
			a: 131.3777,
			o: 132.33,
			c: 132.391,
			h: 132.391,
			l: 132.25,
			t: 1655491260000,
			n: 1112,
			op: 130.065
		},
		{
			v: 132442,
			vw: 132.3685,
			a: 131.3794,
			o: 132.4,
			c: 132.32,
			h: 132.44,
			l: 132.31,
			t: 1655491320000,
			n: 1068,
			op: 130.065
		},
		{
			v: 117586,
			vw: 132.3226,
			a: 131.3809,
			o: 132.34,
			c: 132.225,
			h: 132.4059,
			l: 132.22,
			t: 1655491380000,
			n: 1155,
			op: 130.065
		},
		{
			v: 211283,
			vw: 132.1153,
			a: 131.3829,
			o: 132.23,
			c: 132.04,
			h: 132.2333,
			l: 131.995,
			t: 1655491440000,
			n: 1883,
			op: 130.065
		},
		{
			v: 171020,
			vw: 131.9826,
			a: 131.3842,
			o: 132.04,
			c: 131.93,
			h: 132.08,
			l: 131.87,
			t: 1655491500000,
			n: 1553,
			op: 130.065
		},
		{
			v: 158981,
			vw: 132.021,
			a: 131.3855,
			o: 131.94,
			c: 132.11,
			h: 132.13,
			l: 131.94,
			t: 1655491560000,
			n: 1296,
			op: 130.065
		},
		{
			v: 138361,
			vw: 132.0054,
			a: 131.3866,
			o: 132.105,
			c: 131.965,
			h: 132.12,
			l: 131.94,
			t: 1655491620000,
			n: 1290,
			op: 130.065
		},
		{
			v: 112299,
			vw: 131.9798,
			a: 131.3875,
			o: 131.96,
			c: 132.01,
			h: 132.03,
			l: 131.93,
			t: 1655491680000,
			n: 1084,
			op: 130.065
		},
		{
			v: 143889,
			vw: 131.9711,
			a: 131.3886,
			o: 132.01,
			c: 131.92,
			h: 132.0599,
			l: 131.9,
			t: 1655491740000,
			n: 1217,
			op: 130.065
		},
		{
			v: 182359,
			vw: 131.9089,
			a: 131.3898,
			o: 131.915,
			c: 131.89,
			h: 132.02,
			l: 131.8204,
			t: 1655491800000,
			n: 1657,
			op: 130.065
		},
		{
			v: 114864,
			vw: 131.8699,
			a: 131.3905,
			o: 131.89,
			c: 131.86,
			h: 131.94,
			l: 131.81,
			t: 1655491860000,
			n: 1215,
			op: 130.065
		},
		{
			v: 147217,
			vw: 131.8539,
			a: 131.3913,
			o: 131.88,
			c: 131.79,
			h: 131.95,
			l: 131.77,
			t: 1655491920000,
			n: 1376,
			op: 130.065
		},
		{
			v: 136486,
			vw: 131.8157,
			a: 131.3921,
			o: 131.8,
			c: 131.83,
			h: 131.86,
			l: 131.77,
			t: 1655491980000,
			n: 1360,
			op: 130.065
		},
		{
			v: 119412,
			vw: 131.8242,
			a: 131.3927,
			o: 131.8302,
			c: 131.86,
			h: 131.87,
			l: 131.78,
			t: 1655492040000,
			n: 1178,
			op: 130.065
		},
		{
			v: 182076,
			vw: 131.953,
			a: 131.394,
			o: 131.87,
			c: 132,
			h: 132.04,
			l: 131.84,
			t: 1655492100000,
			n: 1557,
			op: 130.065
		},
		{
			v: 126685,
			vw: 131.9521,
			a: 131.3949,
			o: 132,
			c: 131.95,
			h: 132,
			l: 131.9,
			t: 1655492160000,
			n: 1142,
			op: 130.065
		},
		{
			v: 182866,
			vw: 131.8042,
			a: 131.3959,
			o: 131.95,
			c: 131.82,
			h: 131.95,
			l: 131.69,
			t: 1655492220000,
			n: 1645,
			op: 130.065
		},
		{
			v: 129957,
			vw: 131.817,
			a: 131.3966,
			o: 131.82,
			c: 131.82,
			h: 131.89,
			l: 131.73,
			t: 1655492280000,
			n: 1170,
			op: 130.065
		},
		{
			v: 114414,
			vw: 131.8344,
			a: 131.3972,
			o: 131.83,
			c: 131.874,
			h: 131.88,
			l: 131.78,
			t: 1655492340000,
			n: 1178,
			op: 130.065
		},
		{
			v: 198699,
			vw: 131.9701,
			a: 131.3986,
			o: 131.87,
			c: 131.975,
			h: 132.07,
			l: 131.87,
			t: 1655492400000,
			n: 1883,
			op: 130.065
		},
		{
			v: 135171,
			vw: 131.9064,
			a: 131.3995,
			o: 131.975,
			c: 131.9001,
			h: 131.985,
			l: 131.825,
			t: 1655492460000,
			n: 1323,
			op: 130.065
		},
		{
			v: 241351,
			vw: 132.0655,
			a: 131.4015,
			o: 131.9,
			c: 132.04,
			h: 132.22,
			l: 131.86,
			t: 1655492520000,
			n: 2288,
			op: 130.065
		},
		{
			v: 151465,
			vw: 132.0281,
			a: 131.4027,
			o: 132.04,
			c: 131.89,
			h: 132.12,
			l: 131.89,
			t: 1655492580000,
			n: 1252,
			op: 130.065
		},
		{
			v: 179819,
			vw: 131.8203,
			a: 131.4036,
			o: 131.89,
			c: 131.815,
			h: 131.9099,
			l: 131.75,
			t: 1655492640000,
			n: 1509,
			op: 130.065
		},
		{
			v: 156509,
			vw: 131.8202,
			a: 131.4044,
			o: 131.82,
			c: 131.79,
			h: 131.915,
			l: 131.73,
			t: 1655492700000,
			n: 1413,
			op: 130.065
		},
		{
			v: 255920,
			vw: 131.8,
			a: 131.4057,
			o: 131.79,
			c: 131.915,
			h: 131.92,
			l: 131.68,
			t: 1655492760000,
			n: 2068,
			op: 130.065
		},
		{
			v: 195533,
			vw: 131.9204,
			a: 131.4069,
			o: 131.92,
			c: 131.85,
			h: 131.98,
			l: 131.84,
			t: 1655492820000,
			n: 1484,
			op: 130.065
		},
		{
			v: 135428,
			vw: 131.8212,
			a: 131.4076,
			o: 131.84,
			c: 131.84,
			h: 131.87,
			l: 131.76,
			t: 1655492880000,
			n: 1401,
			op: 130.065
		},
		{
			v: 143736,
			vw: 131.7925,
			a: 131.4083,
			o: 131.84,
			c: 131.7,
			h: 131.86,
			l: 131.7,
			t: 1655492940000,
			n: 1474,
			op: 130.065
		},
		{
			v: 216341,
			vw: 131.6253,
			a: 131.4089,
			o: 131.71,
			c: 131.58,
			h: 131.74,
			l: 131.51,
			t: 1655493000000,
			n: 2183,
			op: 130.065
		},
		{
			v: 209160,
			vw: 131.5202,
			a: 131.4092,
			o: 131.58,
			c: 131.425,
			h: 131.59,
			l: 131.41,
			t: 1655493060000,
			n: 1721,
			op: 130.065
		},
		{
			v: 331285,
			vw: 131.2449,
			a: 131.4085,
			o: 131.4278,
			c: 131.099,
			h: 131.44,
			l: 131.09,
			t: 1655493120000,
			n: 2751,
			op: 130.065
		},
		{
			v: 213928,
			vw: 131.0901,
			a: 131.4077,
			o: 131.09,
			c: 131.08,
			h: 131.12,
			l: 131.0428,
			t: 1655493180000,
			n: 1773,
			op: 130.065
		},
		{
			v: 260256,
			vw: 131.1343,
			a: 131.4068,
			o: 131.07,
			c: 131.1799,
			h: 131.2,
			l: 131.055,
			t: 1655493240000,
			n: 2097,
			op: 130.065
		},
		{
			v: 240020,
			vw: 131.1258,
			a: 131.406,
			o: 131.17,
			c: 131.155,
			h: 131.18,
			l: 131.075,
			t: 1655493300000,
			n: 1966,
			op: 130.065
		},
		{
			v: 236369,
			vw: 131.2234,
			a: 131.4055,
			o: 131.16,
			c: 131.21,
			h: 131.27,
			l: 131.159,
			t: 1655493360000,
			n: 1764,
			op: 130.065
		},
		{
			v: 241308,
			vw: 131.3055,
			a: 131.4052,
			o: 131.21,
			c: 131.39,
			h: 131.4,
			l: 131.19,
			t: 1655493420000,
			n: 2028,
			op: 130.065
		},
		{
			v: 235129,
			vw: 131.4396,
			a: 131.4053,
			o: 131.39,
			c: 131.48,
			h: 131.5,
			l: 131.38,
			t: 1655493480000,
			n: 2031,
			op: 130.065
		},
		{
			v: 217534,
			vw: 131.3871,
			a: 131.4052,
			o: 131.4745,
			c: 131.36,
			h: 131.5,
			l: 131.31,
			t: 1655493540000,
			n: 1868,
			op: 130.065
		},
		{
			v: 300911,
			vw: 131.4961,
			a: 131.4056,
			o: 131.3661,
			c: 131.58,
			h: 131.62,
			l: 131.35,
			t: 1655493600000,
			n: 2231,
			op: 130.065
		},
		{
			v: 290279,
			vw: 131.703,
			a: 131.4066,
			o: 131.59,
			c: 131.62,
			h: 131.78,
			l: 131.58,
			t: 1655493660000,
			n: 2276,
			op: 130.065
		},
		{
			v: 263102,
			vw: 131.7428,
			a: 131.4076,
			o: 131.619,
			c: 131.84,
			h: 131.85,
			l: 131.6109,
			t: 1655493720000,
			n: 1910,
			op: 130.065
		},
		{
			v: 185451,
			vw: 131.8042,
			a: 131.4085,
			o: 131.8301,
			c: 131.86,
			h: 131.8699,
			l: 131.73,
			t: 1655493780000,
			n: 1633,
			op: 130.065
		},
		{
			v: 236609,
			vw: 131.8,
			a: 131.4096,
			o: 131.87,
			c: 131.8,
			h: 131.88,
			l: 131.72,
			t: 1655493840000,
			n: 1999,
			op: 130.065
		},
		{
			v: 193181,
			vw: 131.8205,
			a: 131.4105,
			o: 131.7932,
			c: 131.81,
			h: 131.86,
			l: 131.76,
			t: 1655493900000,
			n: 1697,
			op: 130.065
		},
		{
			v: 319985,
			vw: 131.9431,
			a: 131.4125,
			o: 131.82,
			c: 132.1,
			h: 132.1,
			l: 131.81,
			t: 1655493960000,
			n: 2318,
			op: 130.065
		},
		{
			v: 213851,
			vw: 132.0388,
			a: 131.4141,
			o: 132.101,
			c: 131.945,
			h: 132.121,
			l: 131.93,
			t: 1655494020000,
			n: 1773,
			op: 130.065
		},
		{
			v: 207653,
			vw: 131.9538,
			a: 131.4154,
			o: 131.9499,
			c: 132.025,
			h: 132.04,
			l: 131.87,
			t: 1655494080000,
			n: 1714,
			op: 130.065
		},
		{
			v: 138483,
			vw: 131.9945,
			a: 131.4163,
			o: 132.0232,
			c: 131.9776,
			h: 132.05,
			l: 131.95,
			t: 1655494140000,
			n: 1299,
			op: 130.065
		},
		{
			v: 202419,
			vw: 131.9666,
			a: 131.4176,
			o: 131.97,
			c: 131.8805,
			h: 132.06,
			l: 131.87,
			t: 1655494200000,
			n: 1834,
			op: 130.065
		},
		{
			v: 272277,
			vw: 131.8585,
			a: 131.419,
			o: 131.88,
			c: 131.88,
			h: 131.94,
			l: 131.76,
			t: 1655494260000,
			n: 2214,
			op: 130.065
		},
		{
			v: 344405,
			vw: 131.8905,
			a: 131.4209,
			o: 131.89,
			c: 132.04,
			h: 132.11,
			l: 131.69,
			t: 1655494320000,
			n: 2788,
			op: 130.065
		},
		{
			v: 208260,
			vw: 131.9659,
			a: 131.4222,
			o: 132.03,
			c: 131.9467,
			h: 132.09,
			l: 131.865,
			t: 1655494380000,
			n: 1976,
			op: 130.065
		},
		{
			v: 195029,
			vw: 131.9877,
			a: 131.4235,
			o: 131.94,
			c: 132,
			h: 132.08,
			l: 131.919,
			t: 1655494440000,
			n: 1622,
			op: 130.065
		},
		{
			v: 204995,
			vw: 132.0333,
			a: 131.4249,
			o: 132,
			c: 132.0801,
			h: 132.11,
			l: 131.9299,
			t: 1655494500000,
			n: 1609,
			op: 130.065
		},
		{
			v: 197866,
			vw: 132.019,
			a: 131.4262,
			o: 132.08,
			c: 131.97,
			h: 132.09,
			l: 131.9501,
			t: 1655494560000,
			n: 1783,
			op: 130.065
		},
		{
			v: 246654,
			vw: 131.9587,
			a: 131.4277,
			o: 131.9686,
			c: 132.03,
			h: 132.03,
			l: 131.9,
			t: 1655494620000,
			n: 2038,
			op: 130.065
		},
		{
			v: 193604,
			vw: 132.0278,
			a: 131.429,
			o: 132.03,
			c: 132.08,
			h: 132.085,
			l: 131.97,
			t: 1655494680000,
			n: 1815,
			op: 130.065
		},
		{
			v: 216486,
			vw: 132.0717,
			a: 131.4306,
			o: 132.085,
			c: 132.085,
			h: 132.14,
			l: 132.01,
			t: 1655494740000,
			n: 1768,
			op: 130.065
		},
		{
			v: 270095,
			vw: 131.9845,
			a: 131.4323,
			o: 132.0885,
			c: 131.97,
			h: 132.0885,
			l: 131.94,
			t: 1655494800000,
			n: 2065,
			op: 130.065
		},
		{
			v: 264381,
			vw: 131.9851,
			a: 131.434,
			o: 131.97,
			c: 131.97,
			h: 132.04,
			l: 131.9303,
			t: 1655494860000,
			n: 2130,
			op: 130.065
		},
		{
			v: 210787,
			vw: 132.0112,
			a: 131.4353,
			o: 131.97,
			c: 132.07,
			h: 132.09,
			l: 131.94,
			t: 1655494920000,
			n: 1933,
			op: 130.065
		},
		{
			v: 409431,
			vw: 131.9897,
			a: 131.4379,
			o: 132.07,
			c: 131.98,
			h: 132.0899,
			l: 131.94,
			t: 1655494980000,
			n: 3068,
			op: 130.065
		},
		{
			v: 268626,
			vw: 132.014,
			a: 131.4396,
			o: 131.99,
			c: 132.035,
			h: 132.08,
			l: 131.96,
			t: 1655495040000,
			n: 2005,
			op: 130.065
		},
		{
			v: 267871,
			vw: 131.8888,
			a: 131.4409,
			o: 132.03,
			c: 131.7474,
			h: 132.0352,
			l: 131.74,
			t: 1655495100000,
			n: 2556,
			op: 130.065
		},
		{
			v: 246058,
			vw: 131.8576,
			a: 131.4421,
			o: 131.745,
			c: 131.98,
			h: 131.99,
			l: 131.7411,
			t: 1655495160000,
			n: 2003,
			op: 130.065
		},
		{
			v: 380653,
			vw: 131.9877,
			a: 131.4444,
			o: 131.975,
			c: 132.005,
			h: 132.04,
			l: 131.95,
			t: 1655495220000,
			n: 2785,
			op: 130.065
		},
		{
			v: 283684,
			vw: 132.0469,
			a: 131.4462,
			o: 132.01,
			c: 132.07,
			h: 132.11,
			l: 131.93,
			t: 1655495280000,
			n: 2261,
			op: 130.065
		},
		{
			v: 309504,
			vw: 132.0172,
			a: 131.4482,
			o: 132.08,
			c: 132.0842,
			h: 132.09,
			l: 131.93,
			t: 1655495340000,
			n: 2515,
			op: 130.065
		},
		{
			v: 801997,
			vw: 131.7575,
			a: 131.4509,
			o: 132.07,
			c: 131.665,
			h: 132.07,
			l: 131.45,
			t: 1655495400000,
			n: 7003,
			op: 130.065
		},
		{
			v: 587118,
			vw: 131.6635,
			a: 131.4522,
			o: 131.665,
			c: 131.555,
			h: 131.6999,
			l: 131.55,
			t: 1655495460000,
			n: 3448,
			op: 130.065
		},
		{
			v: 429932,
			vw: 131.598,
			a: 131.4529,
			o: 131.56,
			c: 131.7582,
			h: 131.76,
			l: 131.48,
			t: 1655495520000,
			n: 3377,
			op: 130.065
		},
		{
			v: 362900,
			vw: 131.7525,
			a: 131.4541,
			o: 131.75,
			c: 131.705,
			h: 131.87,
			l: 131.64,
			t: 1655495580000,
			n: 2934,
			op: 130.065
		},
		{
			v: 547550,
			vw: 131.7447,
			a: 131.4558,
			o: 131.705,
			c: 131.69,
			h: 131.86,
			l: 131.6,
			t: 1655495640000,
			n: 4826,
			op: 130.065
		},
		{
			v: 675866,
			vw: 131.9169,
			a: 131.4591,
			o: 131.69,
			c: 132.04,
			h: 132.04,
			l: 131.6799,
			t: 1655495700000,
			n: 5642,
			op: 130.065
		},
		{
			v: 391407,
			vw: 131.998,
			a: 131.4613,
			o: 132.03,
			c: 131.97,
			h: 132.05,
			l: 131.955,
			t: 1655495760000,
			n: 3422,
			op: 130.065
		},
		{
			v: 498812,
			vw: 131.8393,
			a: 131.4633,
			o: 131.98,
			c: 131.74,
			h: 131.98,
			l: 131.73,
			t: 1655495820000,
			n: 4619,
			op: 130.065
		},
		{
			v: 824617,
			vw: 131.6562,
			a: 131.4649,
			o: 131.73,
			c: 131.57,
			h: 131.75,
			l: 131.56,
			t: 1655495880000,
			n: 5723,
			op: 130.065
		},
		{
			v: 1733898,
			vw: 131.4844,
			a: 131.4653,
			o: 131.58,
			c: 131.42,
			h: 131.65,
			l: 131.4,
			t: 1655495940000,
			n: 9229,
			op: 130.065
		},
		{
			v: 690266,
			vw: 131.5586,
			a: 131.4819,
			o: 131.56,
			c: 131.52,
			h: 131.61,
			l: 131.35,
			t: 1655496000000,
			n: 163,
			op: 130.065
		},
		{
			v: 18802,
			vw: 131.505,
			a: 131.4819,
			o: 131.5,
			c: 131.51,
			h: 131.555,
			l: 131.49,
			t: 1655496060000,
			n: 103,
			op: 130.065
		},
		{
			v: 360147,
			vw: 131.5598,
			a: 131.4822,
			o: 131.52,
			c: 131.5699,
			h: 131.5699,
			l: 131.52,
			t: 1655496120000,
			n: 124,
			op: 130.065
		},
		{
			v: 65202,
			vw: 131.5605,
			a: 131.4822,
			o: 131.56,
			c: 131.55,
			h: 131.6,
			l: 131.55,
			t: 1655496180000,
			n: 60,
			op: 130.065
		},
		{
			v: 58047,
			vw: 131.5753,
			a: 131.4823,
			o: 131.56,
			c: 131.69,
			h: 131.69,
			l: 131.56,
			t: 1655496240000,
			n: 93,
			op: 130.065
		},
		{
			v: 9931322,
			vw: 131.5603,
			a: 131.4883,
			o: 131.7,
			c: 131.56,
			h: 131.7,
			l: 131.56,
			t: 1655496300000,
			n: 119,
			op: 130.065
		},
		{
			v: 1321419,
			vw: 131.5599,
			a: 131.489,
			o: 131.56,
			c: 131.56,
			h: 131.64,
			l: 131.56,
			t: 1655496360000,
			n: 452,
			op: 130.065
		},
		{
			v: 417436,
			vw: 131.5629,
			a: 131.4892,
			o: 131.56,
			c: 131.65,
			h: 131.65,
			l: 131.56,
			t: 1655496420000,
			n: 91,
			op: 130.065
		},
		{
			v: 42097,
			vw: 131.5742,
			a: 131.4892,
			o: 131.65,
			c: 131.65,
			h: 131.66,
			l: 131.65,
			t: 1655496480000,
			n: 70,
			op: 130.065
		},
		{
			v: 67563,
			vw: 131.5632,
			a: 131.4893,
			o: 131.66,
			c: 131.66,
			h: 131.66,
			l: 131.56,
			t: 1655496540000,
			n: 65,
			op: 130.065
		},
		{
			v: 570606,
			vw: 131.5627,
			a: 131.4896,
			o: 131.65,
			c: 131.6,
			h: 131.68,
			l: 131.56,
			t: 1655496600000,
			n: 114,
			op: 130.065
		},
		{
			v: 285129,
			vw: 131.564,
			a: 131.4898,
			o: 131.64,
			c: 131.6,
			h: 131.64,
			l: 131.56,
			t: 1655496660000,
			n: 70,
			op: 130.065
		},
		{
			v: 3877,
			vw: 131.5849,
			a: 131.4898,
			o: 131.61,
			c: 131.57,
			h: 131.61,
			l: 131.51,
			t: 1655496720000,
			n: 69,
			op: 130.065
		},
		{
			v: 6568,
			vw: 131.5897,
			a: 131.4898,
			o: 131.56,
			c: 131.61,
			h: 131.61,
			l: 131.56,
			t: 1655496780000,
			n: 57,
			op: 130.065
		},
		{
			v: 5384,
			vw: 131.6115,
			a: 131.4898,
			o: 131.61,
			c: 131.6,
			h: 131.650061,
			l: 131.56,
			t: 1655496840000,
			n: 34,
			op: 130.065
		},
		{
			v: 954,
			vw: 131.5967,
			a: 131.4898,
			o: 131.59,
			c: 131.61,
			h: 131.61,
			l: 131.59,
			t: 1655496900000,
			n: 33,
			op: 130.065
		},
		{
			v: 968,
			vw: 131.5877,
			a: 131.4898,
			o: 131.61,
			c: 131.58,
			h: 131.61,
			l: 131.58,
			t: 1655496960000,
			n: 28,
			op: 130.065
		},
		{
			v: 1728,
			vw: 131.5786,
			a: 131.4898,
			o: 131.6,
			c: 131.58,
			h: 131.61,
			l: 131.56,
			t: 1655497020000,
			n: 45,
			op: 130.065
		},
		{
			v: 90272,
			vw: 131.5602,
			a: 131.4898,
			o: 131.58,
			c: 131.6,
			h: 131.6,
			l: 131.56,
			t: 1655497080000,
			n: 32,
			op: 130.065
		},
		{
			v: 1901,
			vw: 131.5747,
			a: 131.4898,
			o: 131.57,
			c: 131.58,
			h: 131.58,
			l: 131.56,
			t: 1655497140000,
			n: 54,
			op: 130.065
		},
		{
			v: 18531,
			vw: 131.571,
			a: 131.4898,
			o: 131.6,
			c: 131.6,
			h: 131.6,
			l: 131.550528,
			t: 1655497200000,
			n: 45,
			op: 130.065
		},
		{
			v: 7732,
			vw: 131.6142,
			a: 131.4898,
			o: 131.6,
			c: 131.6399,
			h: 131.6399,
			l: 131.6,
			t: 1655497260000,
			n: 48,
			op: 130.065
		},
		{
			v: 16793,
			vw: 131.6022,
			a: 131.4899,
			o: 131.61,
			c: 131.62,
			h: 131.64,
			l: 131.6,
			t: 1655497320000,
			n: 40,
			op: 130.065
		},
		{
			v: 20265,
			vw: 131.5621,
			a: 131.4899,
			o: 131.56,
			c: 131.6,
			h: 131.6,
			l: 131.56,
			t: 1655497380000,
			n: 31,
			op: 130.065
		},
		{
			v: 1922,
			vw: 131.6008,
			a: 131.4899,
			o: 131.6,
			c: 131.5766,
			h: 131.61,
			l: 131.5766,
			t: 1655497440000,
			n: 43,
			op: 130.065
		},
		{
			v: 1366,
			vw: 131.5779,
			a: 131.4899,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.57,
			t: 1655497500000,
			n: 48,
			op: 130.065
		},
		{
			v: 46930,
			vw: 131.5602,
			a: 131.4899,
			o: 131.56,
			c: 131.5601,
			h: 131.58,
			l: 131.56,
			t: 1655497560000,
			n: 32,
			op: 130.065
		},
		{
			v: 72953,
			vw: 131.5636,
			a: 131.4899,
			o: 131.58,
			c: 131.59,
			h: 131.59,
			l: 131.56,
			t: 1655497620000,
			n: 57,
			op: 130.065
		},
		{
			v: 22370,
			vw: 131.5809,
			a: 131.49,
			o: 131.58,
			c: 131.58,
			h: 131.6,
			l: 131.56,
			t: 1655497680000,
			n: 66,
			op: 130.065
		},
		{
			v: 230789,
			vw: 131.5628,
			a: 131.4901,
			o: 131.58,
			c: 131.65,
			h: 131.65,
			l: 131.56,
			t: 1655497740000,
			n: 51,
			op: 130.065
		},
		{
			v: 2500,
			vw: 131.5999,
			a: 131.4901,
			o: 131.5999,
			c: 131.6,
			h: 131.6,
			l: 131.59,
			t: 1655497800000,
			n: 28,
			op: 130.065
		},
		{
			v: 2585,
			vw: 131.6097,
			a: 131.4901,
			o: 131.61,
			c: 131.61,
			h: 131.61,
			l: 131.61,
			t: 1655497860000,
			n: 23,
			op: 130.065
		},
		{
			v: 1566,
			vw: 131.6141,
			a: 131.4901,
			o: 131.61,
			c: 131.62,
			h: 131.62,
			l: 131.61,
			t: 1655497920000,
			n: 33,
			op: 130.065
		},
		{
			v: 12366,
			vw: 131.5668,
			a: 131.4901,
			o: 131.59,
			c: 131.6,
			h: 131.6,
			l: 131.56,
			t: 1655497980000,
			n: 25,
			op: 130.065
		},
		{
			v: 4002,
			vw: 131.6142,
			a: 131.4901,
			o: 131.6,
			c: 131.6101,
			h: 131.62,
			l: 131.6,
			t: 1655498040000,
			n: 21,
			op: 130.065
		},
		{
			v: 1481,
			vw: 131.5933,
			a: 131.4901,
			o: 131.6,
			c: 131.59,
			h: 131.62,
			l: 131.58,
			t: 1655498100000,
			n: 31,
			op: 130.065
		},
		{
			v: 1731,
			vw: 131.6161,
			a: 131.4901,
			o: 131.62,
			c: 131.62,
			h: 131.62,
			l: 131.6,
			t: 1655498160000,
			n: 26,
			op: 130.065
		},
		{
			v: 1433,
			vw: 131.59,
			a: 131.4901,
			o: 131.6,
			c: 131.58,
			h: 131.62,
			l: 131.58,
			t: 1655498220000,
			n: 31,
			op: 130.065
		},
		{
			v: 1507,
			vw: 131.58,
			a: 131.4901,
			o: 131.58,
			c: 131.57,
			h: 131.58,
			l: 131.57,
			t: 1655498280000,
			n: 28,
			op: 130.065
		},
		{
			v: 1468,
			vw: 131.5799,
			a: 131.4901,
			o: 131.5799,
			c: 131.58,
			h: 131.58,
			l: 131.5799,
			t: 1655498340000,
			n: 25,
			op: 130.065
		},
		{
			v: 3249,
			vw: 131.5741,
			a: 131.4901,
			o: 131.58,
			c: 131.56,
			h: 131.59,
			l: 131.56,
			t: 1655498400000,
			n: 34,
			op: 130.065
		},
		{
			v: 5031,
			vw: 131.5608,
			a: 131.4901,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655498460000,
			n: 67,
			op: 130.065
		},
		{
			v: 1216,
			vw: 131.5604,
			a: 131.4901,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655498520000,
			n: 38,
			op: 130.065
		},
		{
			v: 1193,
			vw: 131.5559,
			a: 131.4901,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655498580000,
			n: 66,
			op: 130.065
		},
		{
			v: 596,
			vw: 131.5444,
			a: 131.4901,
			o: 131.54,
			c: 131.54,
			h: 131.55,
			l: 131.54,
			t: 1655498640000,
			n: 20,
			op: 130.065
		},
		{
			v: 2980,
			vw: 131.5481,
			a: 131.4901,
			o: 131.55,
			c: 131.54,
			h: 131.55,
			l: 131.54,
			t: 1655498700000,
			n: 39,
			op: 130.065
		},
		{
			v: 4654,
			vw: 131.5363,
			a: 131.4901,
			o: 131.54,
			c: 131.53,
			h: 131.55,
			l: 131.53,
			t: 1655498760000,
			n: 29,
			op: 130.065
		},
		{
			v: 1188,
			vw: 131.5344,
			a: 131.4901,
			o: 131.55,
			c: 131.53,
			h: 131.55,
			l: 131.53,
			t: 1655498820000,
			n: 36,
			op: 130.065
		},
		{
			v: 1301,
			vw: 131.5311,
			a: 131.4901,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655498880000,
			n: 27,
			op: 130.065
		},
		{
			v: 398,
			vw: 131.52,
			a: 131.4901,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655498940000,
			n: 24,
			op: 130.065
		},
		{
			v: 1805,
			vw: 131.5155,
			a: 131.4901,
			o: 131.51,
			c: 131.51,
			h: 131.52,
			l: 131.51,
			t: 1655499000000,
			n: 30,
			op: 130.065
		},
		{
			v: 1031,
			vw: 131.525,
			a: 131.4901,
			o: 131.52,
			c: 131.53,
			h: 131.53,
			l: 131.52,
			t: 1655499060000,
			n: 18,
			op: 130.065
		},
		{
			v: 3916,
			vw: 131.53,
			a: 131.4901,
			o: 131.53,
			c: 131.5299,
			h: 131.53,
			l: 131.5299,
			t: 1655499120000,
			n: 34,
			op: 130.065
		},
		{
			v: 865,
			vw: 131.5273,
			a: 131.4901,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655499180000,
			n: 25,
			op: 130.065
		},
		{
			v: 1951,
			vw: 131.519,
			a: 131.4901,
			o: 131.52,
			c: 131.5103,
			h: 131.53,
			l: 131.5097,
			t: 1655499240000,
			n: 19,
			op: 130.065
		},
		{
			v: 3036,
			vw: 131.5122,
			a: 131.4901,
			o: 131.52,
			c: 131.53,
			h: 131.53,
			l: 131.5,
			t: 1655499300000,
			n: 46,
			op: 130.065
		},
		{
			v: 1079,
			vw: 131.5186,
			a: 131.4901,
			o: 131.5201,
			c: 131.52,
			h: 131.53,
			l: 131.5017,
			t: 1655499360000,
			n: 33,
			op: 130.065
		},
		{
			v: 877,
			vw: 131.5172,
			a: 131.4901,
			o: 131.5299,
			c: 131.51,
			h: 131.53,
			l: 131.51,
			t: 1655499420000,
			n: 33,
			op: 130.065
		},
		{
			v: 5724,
			vw: 131.5016,
			a: 131.4901,
			o: 131.51,
			c: 131.49,
			h: 131.51,
			l: 131.49,
			t: 1655499480000,
			n: 62,
			op: 130.065
		},
		{
			v: 691,
			vw: 131.4938,
			a: 131.4901,
			o: 131.45,
			c: 131.52,
			h: 131.52,
			l: 131.45,
			t: 1655499540000,
			n: 24,
			op: 130.065
		},
		{
			v: 621,
			vw: 131.5136,
			a: 131.4901,
			o: 131.51,
			c: 131.51,
			h: 131.51,
			l: 131.51,
			t: 1655499600000,
			n: 22,
			op: 130.065
		},
		{
			v: 2035,
			vw: 131.5163,
			a: 131.4901,
			o: 131.53,
			c: 131.51,
			h: 131.53,
			l: 131.51,
			t: 1655499660000,
			n: 22,
			op: 130.065
		},
		{
			v: 2979,
			vw: 131.4772,
			a: 131.4901,
			o: 131.46,
			c: 131.45,
			h: 131.51,
			l: 131.45,
			t: 1655499720000,
			n: 34,
			op: 130.065
		},
		{
			v: 1332792,
			vw: 131.56,
			a: 131.4908,
			o: 131.49,
			c: 131.56,
			h: 131.56,
			l: 131.49,
			t: 1655499780000,
			n: 8,
			op: 130.065
		},
		{
			v: 3020,
			vw: 131.5169,
			a: 131.4908,
			o: 131.51,
			c: 131.5,
			h: 131.53,
			l: 131.5,
			t: 1655499900000,
			n: 22,
			op: 130.065
		},
		{
			v: 462,
			vw: 131.5015,
			a: 131.4908,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655499960000,
			n: 21,
			op: 130.065
		},
		{
			v: 830,
			vw: 131.523,
			a: 131.4908,
			o: 131.53,
			c: 131.52,
			h: 131.53,
			l: 131.52,
			t: 1655500020000,
			n: 28,
			op: 130.065
		},
		{
			v: 355,
			vw: 131.5164,
			a: 131.4908,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655500080000,
			n: 17,
			op: 130.065
		},
		{
			v: 525,
			vw: 131.5192,
			a: 131.4908,
			o: 131.5199,
			c: 131.52,
			h: 131.52,
			l: 131.5199,
			t: 1655500140000,
			n: 21,
			op: 130.065
		},
		{
			v: 1184,
			vw: 131.5271,
			a: 131.4908,
			o: 131.52,
			c: 131.53,
			h: 131.53,
			l: 131.52,
			t: 1655500260000,
			n: 24,
			op: 130.065
		},
		{
			v: 3699,
			vw: 131.52,
			a: 131.4908,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.5199,
			t: 1655500320000,
			n: 28,
			op: 130.065
		},
		{
			v: 319,
			vw: 131.5479,
			a: 131.4908,
			o: 131.55,
			c: 131.5499,
			h: 131.55,
			l: 131.5499,
			t: 1655500380000,
			n: 10,
			op: 130.065
		},
		{
			v: 389,
			vw: 131.5366,
			a: 131.4908,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655500440000,
			n: 17,
			op: 130.065
		},
		{
			v: 2939,
			vw: 131.5447,
			a: 131.4908,
			o: 131.53,
			c: 131.55,
			h: 131.55,
			l: 131.53,
			t: 1655500500000,
			n: 17,
			op: 130.065
		},
		{
			v: 307,
			vw: 131.5467,
			a: 131.4908,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655500560000,
			n: 12,
			op: 130.065
		},
		{
			v: 2280,
			vw: 131.5323,
			a: 131.4908,
			o: 131.54,
			c: 131.5,
			h: 131.55,
			l: 131.5,
			t: 1655500620000,
			n: 31,
			op: 130.065
		},
		{
			v: 3355,
			vw: 131.5139,
			a: 131.4908,
			o: 131.53,
			c: 131.52,
			h: 131.53,
			l: 131.5,
			t: 1655500680000,
			n: 43,
			op: 130.065
		},
		{
			v: 4271,
			vw: 131.4846,
			a: 131.4908,
			o: 131.5,
			c: 131.46,
			h: 131.5,
			l: 131.45,
			t: 1655500740000,
			n: 62,
			op: 130.065
		},
		{
			v: 2951,
			vw: 131.4905,
			a: 131.4908,
			o: 131.46,
			c: 131.48,
			h: 131.53,
			l: 131.46,
			t: 1655500800000,
			n: 74,
			op: 130.065
		},
		{
			v: 2185,
			vw: 131.4773,
			a: 131.4908,
			o: 131.48,
			c: 131.47,
			h: 131.48,
			l: 131.46,
			t: 1655500860000,
			n: 43,
			op: 130.065
		},
		{
			v: 15430,
			vw: 131.4374,
			a: 131.4908,
			o: 131.46,
			c: 131.43,
			h: 131.46,
			l: 131.42,
			t: 1655500920000,
			n: 68,
			op: 130.065
		},
		{
			v: 2695,
			vw: 131.4913,
			a: 131.4908,
			o: 131.48,
			c: 131.5001,
			h: 131.52,
			l: 131.43,
			t: 1655500980000,
			n: 43,
			op: 130.065
		},
		{
			v: 918,
			vw: 131.5142,
			a: 131.4908,
			o: 131.5013,
			c: 131.5027,
			h: 131.53,
			l: 131.5,
			t: 1655501040000,
			n: 27,
			op: 130.065
		},
		{
			v: 1188,
			vw: 131.5008,
			a: 131.4908,
			o: 131.5,
			c: 131.5,
			h: 131.5,
			l: 131.5,
			t: 1655501100000,
			n: 21,
			op: 130.065
		},
		{
			v: 830,
			vw: 131.461,
			a: 131.4908,
			o: 131.46,
			c: 131.455,
			h: 131.46,
			l: 131.45,
			t: 1655501160000,
			n: 22,
			op: 130.065
		},
		{
			v: 449,
			vw: 131.4855,
			a: 131.4908,
			o: 131.4878,
			c: 131.4878,
			h: 131.4878,
			l: 131.4878,
			t: 1655501220000,
			n: 26,
			op: 130.065
		},
		{
			v: 787,
			vw: 131.4612,
			a: 131.4908,
			o: 131.46,
			c: 131.46,
			h: 131.46,
			l: 131.46,
			t: 1655501280000,
			n: 20,
			op: 130.065
		},
		{
			v: 875,
			vw: 131.4797,
			a: 131.4908,
			o: 131.5,
			c: 131.48,
			h: 131.5,
			l: 131.48,
			t: 1655501340000,
			n: 38,
			op: 130.065
		},
		{
			v: 3296,
			vw: 131.467,
			a: 131.4908,
			o: 131.45,
			c: 131.4798,
			h: 131.4798,
			l: 131.45,
			t: 1655501400000,
			n: 43,
			op: 130.065
		},
		{
			v: 745,
			vw: 131.4679,
			a: 131.4908,
			o: 131.48,
			c: 131.4461,
			h: 131.48,
			l: 131.4461,
			t: 1655501460000,
			n: 29,
			op: 130.065
		},
		{
			v: 2820,
			vw: 131.4609,
			a: 131.4908,
			o: 131.47,
			c: 131.47,
			h: 131.47,
			l: 131.45,
			t: 1655501520000,
			n: 32,
			op: 130.065
		},
		{
			v: 1661,
			vw: 131.4534,
			a: 131.4908,
			o: 131.4501,
			c: 131.45,
			h: 131.4501,
			l: 131.45,
			t: 1655501580000,
			n: 31,
			op: 130.065
		},
		{
			v: 3155,
			vw: 131.4412,
			a: 131.4908,
			o: 131.44,
			c: 131.4409,
			h: 131.4409,
			l: 131.44,
			t: 1655501640000,
			n: 26,
			op: 130.065
		},
		{
			v: 256,
			vw: 131.4524,
			a: 131.4908,
			o: 131.4452,
			c: 131.4452,
			h: 131.4452,
			l: 131.4452,
			t: 1655501700000,
			n: 18,
			op: 130.065
		},
		{
			v: 1681,
			vw: 131.4514,
			a: 131.4908,
			o: 131.4501,
			c: 131.47,
			h: 131.47,
			l: 131.45,
			t: 1655501820000,
			n: 31,
			op: 130.065
		},
		{
			v: 1098,
			vw: 131.461,
			a: 131.4908,
			o: 131.45,
			c: 131.4535,
			h: 131.4699,
			l: 131.45,
			t: 1655501880000,
			n: 24,
			op: 130.065
		},
		{
			v: 7175,
			vw: 131.4933,
			a: 131.4908,
			o: 131.4699,
			c: 131.48,
			h: 131.52,
			l: 131.4699,
			t: 1655501940000,
			n: 53,
			op: 130.065
		},
		{
			v: 5051,
			vw: 131.4893,
			a: 131.4908,
			o: 131.48,
			c: 131.5,
			h: 131.54,
			l: 131.4799,
			t: 1655502000000,
			n: 59,
			op: 130.065
		},
		{
			v: 1372,
			vw: 131.5237,
			a: 131.4908,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655502060000,
			n: 43,
			op: 130.065
		},
		{
			v: 192991,
			vw: 131.5595,
			a: 131.4909,
			o: 131.5,
			c: 131.56,
			h: 131.56,
			l: 131.5,
			t: 1655502120000,
			n: 25,
			op: 130.065
		},
		{
			v: 2368,
			vw: 131.5041,
			a: 131.4909,
			o: 131.52,
			c: 131.4618,
			h: 131.55,
			l: 131.4618,
			t: 1655502180000,
			n: 33,
			op: 130.065
		},
		{
			v: 2856,
			vw: 131.4917,
			a: 131.4909,
			o: 131.48,
			c: 131.49,
			h: 131.5,
			l: 131.48,
			t: 1655502300000,
			n: 39,
			op: 130.065
		},
		{
			v: 1099,
			vw: 131.4975,
			a: 131.4909,
			o: 131.5044,
			c: 131.51,
			h: 131.52,
			l: 131.4901,
			t: 1655502360000,
			n: 39,
			op: 130.065
		},
		{
			v: 731,
			vw: 131.5064,
			a: 131.4909,
			o: 131.51,
			c: 131.51,
			h: 131.51,
			l: 131.51,
			t: 1655502420000,
			n: 26,
			op: 130.065
		},
		{
			v: 1853,
			vw: 131.4943,
			a: 131.4909,
			o: 131.4975,
			c: 131.47,
			h: 131.5,
			l: 131.47,
			t: 1655502540000,
			n: 37,
			op: 130.065
		},
		{
			v: 3641,
			vw: 131.5004,
			a: 131.4909,
			o: 131.49,
			c: 131.4864,
			h: 131.53,
			l: 131.4864,
			t: 1655502600000,
			n: 40,
			op: 130.065
		},
		{
			v: 391,
			vw: 131.5446,
			a: 131.4909,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655502660000,
			n: 13,
			op: 130.065
		},
		{
			v: 1912,
			vw: 131.528,
			a: 131.4909,
			o: 131.51,
			c: 131.54,
			h: 131.54,
			l: 131.51,
			t: 1655502780000,
			n: 22,
			op: 130.065
		},
		{
			v: 26769,
			vw: 131.5595,
			a: 131.4909,
			o: 131.56,
			c: 131.53,
			h: 131.56,
			l: 131.53,
			t: 1655502840000,
			n: 21,
			op: 130.065
		},
		{
			v: 1370,
			vw: 131.523,
			a: 131.4909,
			o: 131.52,
			c: 131.52,
			h: 131.53,
			l: 131.52,
			t: 1655502900000,
			n: 21,
			op: 130.065
		},
		{
			v: 1792,
			vw: 131.537,
			a: 131.4909,
			o: 131.54,
			c: 131.52,
			h: 131.54,
			l: 131.52,
			t: 1655502960000,
			n: 23,
			op: 130.065
		},
		{
			v: 2258,
			vw: 131.5375,
			a: 131.4909,
			o: 131.54,
			c: 131.55,
			h: 131.55,
			l: 131.53,
			t: 1655503080000,
			n: 41,
			op: 130.065
		},
		{
			v: 807,
			vw: 131.5356,
			a: 131.4909,
			o: 131.54,
			c: 131.5273,
			h: 131.54,
			l: 131.5273,
			t: 1655503140000,
			n: 35,
			op: 130.065
		},
		{
			v: 294,
			vw: 131.5632,
			a: 131.4909,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.57,
			t: 1655503200000,
			n: 18,
			op: 130.065
		},
		{
			v: 629,
			vw: 131.5468,
			a: 131.4909,
			o: 131.55,
			c: 131.56,
			h: 131.56,
			l: 131.55,
			t: 1655503260000,
			n: 25,
			op: 130.065
		},
		{
			v: 194,
			vw: 131.9002,
			a: 131.4909,
			o: 131.54,
			c: 131.54,
			h: 131.54,
			l: 131.54,
			t: 1655503320000,
			n: 12,
			op: 130.065
		},
		{
			v: 607,
			vw: 131.534,
			a: 131.4909,
			o: 131.55,
			c: 131.53,
			h: 131.55,
			l: 131.53,
			t: 1655503380000,
			n: 14,
			op: 130.065
		},
		{
			v: 2063,
			vw: 131.5729,
			a: 131.4909,
			o: 131.57,
			c: 131.58,
			h: 131.58,
			l: 131.5401,
			t: 1655503620000,
			n: 19,
			op: 130.065
		},
		{
			v: 407,
			vw: 131.5542,
			a: 131.4909,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.58,
			t: 1655503680000,
			n: 25,
			op: 130.065
		},
		{
			v: 247,
			vw: 131.5745,
			a: 131.4909,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.58,
			t: 1655503740000,
			n: 11,
			op: 130.065
		},
		{
			v: 1664,
			vw: 131.557,
			a: 131.4909,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655503860000,
			n: 19,
			op: 130.065
		},
		{
			v: 723,
			vw: 131.5543,
			a: 131.4909,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.55,
			t: 1655503980000,
			n: 14,
			op: 130.065
		},
		{
			v: 911,
			vw: 131.5534,
			a: 131.4909,
			o: 131.55,
			c: 131.56,
			h: 131.56,
			l: 131.55,
			t: 1655504100000,
			n: 14,
			op: 130.065
		},
		{
			v: 721,
			vw: 131.539,
			a: 131.4909,
			o: 131.53,
			c: 131.56,
			h: 131.56,
			l: 131.53,
			t: 1655504160000,
			n: 30,
			op: 130.065
		},
		{
			v: 173,
			vw: 131.5595,
			a: 131.4909,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655504220000,
			n: 10,
			op: 130.065
		},
		{
			v: 1132,
			vw: 131.5322,
			a: 131.4909,
			o: 131.53,
			c: 131.55,
			h: 131.55,
			l: 131.53,
			t: 1655504280000,
			n: 27,
			op: 130.065
		},
		{
			v: 301,
			vw: 131.5482,
			a: 131.4909,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655504340000,
			n: 11,
			op: 130.065
		},
		{
			v: 345,
			vw: 131.5309,
			a: 131.4909,
			o: 131.53,
			c: 131.53,
			h: 131.53,
			l: 131.53,
			t: 1655504400000,
			n: 12,
			op: 130.065
		},
		{
			v: 355,
			vw: 131.5495,
			a: 131.4909,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655504580000,
			n: 13,
			op: 130.065
		},
		{
			v: 405,
			vw: 131.5569,
			a: 131.4909,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655504640000,
			n: 12,
			op: 130.065
		},
		{
			v: 152,
			vw: 131.5683,
			a: 131.4909,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.58,
			t: 1655504700000,
			n: 12,
			op: 130.065
		},
		{
			v: 445,
			vw: 131.5643,
			a: 131.4909,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.58,
			t: 1655504760000,
			n: 23,
			op: 130.065
		},
		{
			v: 3015,
			vw: 131.5192,
			a: 131.4909,
			o: 131.54,
			c: 131.51,
			h: 131.54,
			l: 131.51,
			t: 1655504820000,
			n: 22,
			op: 130.065
		},
		{
			v: 294,
			vw: 131.5635,
			a: 131.4909,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655504940000,
			n: 11,
			op: 130.065
		},
		{
			v: 733,
			vw: 131.5774,
			a: 131.4909,
			o: 131.57,
			c: 131.58,
			h: 131.58,
			l: 131.57,
			t: 1655505000000,
			n: 11,
			op: 130.065
		},
		{
			v: 1304,
			vw: 131.5764,
			a: 131.4909,
			o: 131.56,
			c: 131.59,
			h: 131.59,
			l: 131.56,
			t: 1655505060000,
			n: 20,
			op: 130.065
		},
		{
			v: 1290,
			vw: 131.5833,
			a: 131.4909,
			o: 131.59,
			c: 131.58,
			h: 131.59,
			l: 131.58,
			t: 1655505180000,
			n: 26,
			op: 130.065
		},
		{
			v: 596,
			vw: 131.5804,
			a: 131.4909,
			o: 131.58,
			c: 131.58,
			h: 131.58,
			l: 131.58,
			t: 1655505240000,
			n: 15,
			op: 130.065
		},
		{
			v: 466,
			vw: 131.5794,
			a: 131.4909,
			o: 131.5899,
			c: 131.57,
			h: 131.5899,
			l: 131.57,
			t: 1655505360000,
			n: 16,
			op: 130.065
		},
		{
			v: 1141,
			vw: 131.556,
			a: 131.4909,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.53,
			t: 1655505480000,
			n: 21,
			op: 130.065
		},
		{
			v: 830,
			vw: 131.5716,
			a: 131.4909,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.57,
			t: 1655505540000,
			n: 13,
			op: 130.065
		},
		{
			v: 1594,
			vw: 131.5724,
			a: 131.4909,
			o: 131.57,
			c: 131.57,
			h: 131.5799,
			l: 131.57,
			t: 1655505600000,
			n: 13,
			op: 130.065
		},
		{
			v: 373,
			vw: 131.5707,
			a: 131.4909,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.57,
			t: 1655505660000,
			n: 13,
			op: 130.065
		},
		{
			v: 251,
			vw: 131.5912,
			a: 131.4909,
			o: 131.59,
			c: 131.59,
			h: 131.59,
			l: 131.59,
			t: 1655505720000,
			n: 13,
			op: 130.065
		},
		{
			v: 2158,
			vw: 131.6287,
			a: 131.4909,
			o: 131.6,
			c: 131.65,
			h: 131.66,
			l: 131.6,
			t: 1655505780000,
			n: 22,
			op: 130.065
		},
		{
			v: 307,
			vw: 131.6223,
			a: 131.4909,
			o: 131.65,
			c: 131.65,
			h: 131.65,
			l: 131.65,
			t: 1655505840000,
			n: 12,
			op: 130.065
		},
		{
			v: 303,
			vw: 131.64,
			a: 131.4909,
			o: 131.64,
			c: 131.64,
			h: 131.64,
			l: 131.64,
			t: 1655506020000,
			n: 5,
			op: 130.065
		},
		{
			v: 486,
			vw: 131.6333,
			a: 131.4911,
			o: 131.63,
			c: 131.63,
			h: 131.63,
			l: 131.63,
			t: 1655506200000,
			n: 18,
			op: 130.065
		},
		{
			v: 838,
			vw: 131.5991,
			a: 131.4911,
			o: 131.64,
			c: 131.6,
			h: 131.64,
			l: 131.59,
			t: 1655506260000,
			n: 15,
			op: 130.065
		},
		{
			v: 909,
			vw: 131.5701,
			a: 131.4911,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.57,
			t: 1655506380000,
			n: 5,
			op: 130.065
		},
		{
			v: 1068,
			vw: 131.6234,
			a: 131.4911,
			o: 131.62,
			c: 131.64,
			h: 131.64,
			l: 131.62,
			t: 1655506680000,
			n: 9,
			op: 130.065
		},
		{
			v: 1061,
			vw: 131.627,
			a: 131.4911,
			o: 131.65,
			c: 131.62,
			h: 131.65,
			l: 131.62,
			t: 1655506740000,
			n: 6,
			op: 130.065
		},
		{
			v: 629,
			vw: 131.5803,
			a: 131.4911,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655506860000,
			n: 26,
			op: 130.065
		},
		{
			v: 190,
			vw: 131.57,
			a: 131.4911,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655506920000,
			n: 8,
			op: 130.065
		},
		{
			v: 166,
			vw: 131.6012,
			a: 131.4911,
			o: 131.61,
			c: 131.61,
			h: 131.61,
			l: 131.61,
			t: 1655507040000,
			n: 13,
			op: 130.065
		},
		{
			v: 363,
			vw: 131.5736,
			a: 131.4911,
			o: 131.57,
			c: 131.57,
			h: 131.57,
			l: 131.57,
			t: 1655507160000,
			n: 12,
			op: 130.065
		},
		{
			v: 273,
			vw: 131.5497,
			a: 131.4911,
			o: 131.55,
			c: 131.55,
			h: 131.55,
			l: 131.55,
			t: 1655507280000,
			n: 17,
			op: 130.065
		},
		{
			v: 242,
			vw: 131.5149,
			a: 131.4911,
			o: 131.51,
			c: 131.51,
			h: 131.51,
			l: 131.51,
			t: 1655507340000,
			n: 12,
			op: 130.065
		},
		{
			v: 1791,
			vw: 131.5145,
			a: 131.4911,
			o: 131.51,
			c: 131.55,
			h: 131.55,
			l: 131.51,
			t: 1655507400000,
			n: 11,
			op: 130.065
		},
		{
			v: 3140,
			vw: 131.5204,
			a: 131.4911,
			o: 131.52,
			c: 131.5499,
			h: 131.5499,
			l: 131.51,
			t: 1655507460000,
			n: 22,
			op: 130.065
		},
		{
			v: 636,
			vw: 131.513,
			a: 131.4911,
			o: 131.52,
			c: 131.51,
			h: 131.52,
			l: 131.51,
			t: 1655507520000,
			n: 19,
			op: 130.065
		},
		{
			v: 883,
			vw: 131.507,
			a: 131.4911,
			o: 131.51,
			c: 131.5,
			h: 131.51,
			l: 131.5,
			t: 1655507580000,
			n: 22,
			op: 130.065
		},
		{
			v: 2540,
			vw: 131.5118,
			a: 131.4911,
			o: 131.52,
			c: 131.54,
			h: 131.54,
			l: 131.5,
			t: 1655508000000,
			n: 23,
			op: 130.065
		},
		{
			v: 473,
			vw: 131.536,
			a: 131.4911,
			o: 131.5399,
			c: 131.5399,
			h: 131.5399,
			l: 131.5399,
			t: 1655508060000,
			n: 9,
			op: 130.065
		},
		{
			v: 371,
			vw: 131.5533,
			a: 131.4911,
			o: 131.56,
			c: 131.56,
			h: 131.56,
			l: 131.56,
			t: 1655508300000,
			n: 27,
			op: 130.065
		},
		{
			v: 647,
			vw: 131.5359,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655508540000,
			n: 25,
			op: 130.065
		},
		{
			v: 303,
			vw: 131.5188,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655509080000,
			n: 12,
			op: 130.065
		},
		{
			v: 718,
			vw: 131.5272,
			a: 131.4911,
			o: 131.53,
			c: 131.52,
			h: 131.53,
			l: 131.52,
			t: 1655509260000,
			n: 10,
			op: 130.065
		},
		{
			v: 223,
			vw: 131.5251,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655509320000,
			n: 8,
			op: 130.065
		},
		{
			v: 294,
			vw: 131.5208,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655509560000,
			n: 19,
			op: 130.065
		},
		{
			v: 1256,
			vw: 131.5039,
			a: 131.4911,
			o: 131.5,
			c: 131.5,
			h: 131.52,
			l: 131.5,
			t: 1655509620000,
			n: 29,
			op: 130.065
		},
		{
			v: 518,
			vw: 131.5103,
			a: 131.4911,
			o: 131.5,
			c: 131.52,
			h: 131.52,
			l: 131.5,
			t: 1655509740000,
			n: 10,
			op: 130.065
		},
		{
			v: 434,
			vw: 131.5154,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655509800000,
			n: 13,
			op: 130.065
		},
		{
			v: 536,
			vw: 131.5226,
			a: 131.4911,
			o: 131.52,
			c: 131.52,
			h: 131.52,
			l: 131.52,
			t: 1655509860000,
			n: 18,
			op: 130.065
		},
		{
			v: 706,
			vw: 131.5344,
			a: 131.4911,
			o: 131.52,
			c: 131.55,
			h: 131.55,
			l: 131.52,
			t: 1655509920000,
			n: 11,
			op: 130.065
		},
		{
			v: 2014,
			vw: 131.557,
			a: 131.4911,
			o: 131.55,
			c: 131.57,
			h: 131.57,
			l: 131.55,
			t: 1655510040000,
			n: 27,
			op: 130.065
		},
		{
			v: 647,
			vw: 131.5287,
			a: 131.4911,
			o: 131.53,
			c: 131.52,
			h: 131.53,
			l: 131.52,
			t: 1655510220000,
			n: 21,
			op: 130.065
		},
		{
			v: 902,
			vw: 131.5651,
			a: 131.4911,
			o: 131.55,
			c: 131.56,
			h: 131.56,
			l: 131.55,
			t: 1655510340000,
			n: 32,
			op: 130.065
		}
	],
	status: 'OK',
	request_id: '5fe2a244ea41110d89bffad2c0b08895',
	count: 4254
};

const oneDayChartData = {
    stocks: {
		"ticker": "AAPL",
		"queryCount": 3777,
		"resultsCount": 3777,
		"adjusted": true,
		"results": [
			{
				"open": 5.1061,
				"close": 4.7057,
				"volume": 1770336876,
				"time": 1185854400000,
				"lowest": 4.6971,
				"highest": 5.1243,
				"volumePrice": 4.9034,
				"transactionCount": 291419,
				"voluemPrice": 4.9034
			},
			{
				"open": 4.7729,
				"close": 4.8214,
				"volume": 1751329076,
				"time": 1185940800000,
				"lowest": 4.5632,
				"highest": 4.835,
				"volumePrice": 4.7224,
				"transactionCount": 289237,
				"voluemPrice": 4.7224
			},
			{
				"open": 4.8804,
				"close": 4.8746,
				"volume": 853717172,
				"time": 1186027200000,
				"lowest": 4.7911,
				"highest": 4.8914,
				"volumePrice": 4.8429,
				"transactionCount": 139929,
				"voluemPrice": 4.8429
			},
			{
				"open": 4.8339,
				"close": 4.7089,
				"volume": 679185108,
				"time": 1186113600000,
				"lowest": 4.6964,
				"highest": 4.8554,
				"volumePrice": 4.7979,
				"transactionCount": 109984,
				"voluemPrice": 4.7979
			},
			{
				"open": 4.7464,
				"close": 4.8304,
				"volume": 925911476,
				"time": 1186372800000,
				"lowest": 4.5821,
				"highest": 4.8311,
				"volumePrice": 4.7221,
				"transactionCount": 150635,
				"voluemPrice": 4.7221
			},
			{
				"open": 4.8193,
				"close": 4.8225,
				"volume": 951391224,
				"time": 1186459200000,
				"lowest": 4.7368,
				"highest": 4.9014,
				"volumePrice": 4.8399,
				"transactionCount": 151418,
				"voluemPrice": 4.8399
			},
			{
				"open": 4.8839,
				"close": 4.7857,
				"volume": 808738112,
				"time": 1186545600000,
				"lowest": 4.7143,
				"highest": 4.8879,
				"volumePrice": 4.8001,
				"transactionCount": 123757,
				"voluemPrice": 4.8001
			},
			{
				"open": 4.6825,
				"close": 4.5139,
				"volume": 1124980220,
				"time": 1186632000000,
				"lowest": 4.4675,
				"highest": 4.75,
				"volumePrice": 4.6206,
				"transactionCount": 172083,
				"voluemPrice": 4.6206
			},
			{
				"open": 4.3929,
				"close": 4.4643,
				"volume": 1410592596,
				"time": 1186718400000,
				"lowest": 4.2964,
				"highest": 4.5625,
				"volumePrice": 4.4292,
				"transactionCount": 208763,
				"voluemPrice": 4.4292
			},
			{
				"open": 4.5832,
				"close": 4.5639,
				"volume": 754111624,
				"time": 1186977600000,
				"lowest": 4.5179,
				"highest": 4.6196,
				"volumePrice": 4.5762,
				"transactionCount": 116938,
				"voluemPrice": 4.5762
			},
			{
				"open": 4.5804,
				"close": 4.4296,
				"volume": 739163572,
				"time": 1187064000000,
				"lowest": 4.4182,
				"highest": 4.5821,
				"volumePrice": 4.487,
				"transactionCount": 121393,
				"voluemPrice": 4.487
			},
			{
				"open": 4.3836,
				"close": 4.2821,
				"volume": 992850628,
				"time": 1187150400000,
				"lowest": 4.2732,
				"highest": 4.4593,
				"volumePrice": 4.3676,
				"transactionCount": 168683,
				"voluemPrice": 4.3676
			},
			{
				"open": 4.1789,
				"close": 4.1804,
				"volume": 1866643184,
				"time": 1187236800000,
				"lowest": 3.9864,
				"highest": 4.2321,
				"volumePrice": 4.1003,
				"transactionCount": 311110,
				"voluemPrice": 4.1003
			},
			{
				"open": 4.3575,
				"close": 4.3593,
				"volume": 1194951072,
				"time": 1187323200000,
				"lowest": 4.2793,
				"highest": 4.4107,
				"volumePrice": 4.3432,
				"transactionCount": 194107,
				"voluemPrice": 4.3432
			},
			{
				"open": 4.4271,
				"close": 4.365,
				"volume": 803523112,
				"time": 1187582400000,
				"lowest": 4.3036,
				"highest": 4.4464,
				"volumePrice": 4.3666,
				"transactionCount": 135454,
				"voluemPrice": 4.3666
			},
			{
				"open": 4.3629,
				"close": 4.5561,
				"volume": 1303047060,
				"time": 1187668800000,
				"lowest": 4.3214,
				"highest": 4.6057,
				"volumePrice": 4.5229,
				"transactionCount": 202174,
				"voluemPrice": 4.5229
			},
			{
				"open": 4.6864,
				"close": 4.7325,
				"volume": 1060629780,
				"time": 1187755200000,
				"lowest": 4.6546,
				"highest": 4.7411,
				"volumePrice": 4.6973,
				"transactionCount": 162056,
				"voluemPrice": 4.6973
			},
			{
				"open": 4.7532,
				"close": 4.6811,
				"volume": 866836264,
				"time": 1187841600000,
				"lowest": 4.6343,
				"highest": 4.7621,
				"volumePrice": 4.6871,
				"transactionCount": 147292,
				"voluemPrice": 4.6871
			},
			{
				"open": 4.6625,
				"close": 4.8321,
				"volume": 911832852,
				"time": 1187928000000,
				"lowest": 4.6361,
				"highest": 4.8346,
				"volumePrice": 4.7714,
				"transactionCount": 144117,
				"voluemPrice": 4.7714
			},
			{
				"open": 4.764,
				"close": 4.7232,
				"volume": 707442848,
				"time": 1188187200000,
				"lowest": 4.7179,
				"highest": 4.8093,
				"volumePrice": 4.762,
				"transactionCount": 116978,
				"voluemPrice": 4.762
			},
			{
				"open": 4.6782,
				"close": 4.5293,
				"volume": 1179370220,
				"time": 1188273600000,
				"lowest": 4.5225,
				"highest": 4.7289,
				"volumePrice": 4.6128,
				"transactionCount": 198534,
				"voluemPrice": 4.6128
			},
			{
				"open": 4.6386,
				"close": 4.7886,
				"volume": 1166883956,
				"time": 1188360000000,
				"lowest": 4.6264,
				"highest": 4.7921,
				"volumePrice": 4.7185,
				"transactionCount": 209173,
				"voluemPrice": 4.7185
			},
			{
				"open": 4.7382,
				"close": 4.8661,
				"volume": 1435632156,
				"time": 1188446400000,
				"lowest": 4.725,
				"highest": 4.9375,
				"volumePrice": 4.8715,
				"transactionCount": 246052,
				"voluemPrice": 4.8715
			},
			{
				"open": 4.9818,
				"close": 4.9457,
				"volume": 876891120,
				"time": 1188532800000,
				"lowest": 4.9075,
				"highest": 4.9875,
				"volumePrice": 4.9407,
				"transactionCount": 148578,
				"voluemPrice": 4.9407
			},
			{
				"open": 4.9996,
				"close": 5.1486,
				"volume": 1316823452,
				"time": 1188878400000,
				"lowest": 4.9943,
				"highest": 5.2046,
				"volumePrice": 5.1158,
				"transactionCount": 234684,
				"voluemPrice": 5.1158
			},
			{
				"open": 5.1775,
				"close": 4.8843,
				"volume": 2328226796,
				"time": 1188964800000,
				"lowest": 4.8607,
				"highest": 5.2086,
				"volumePrice": 5.0518,
				"transactionCount": 399141,
				"voluemPrice": 5.0518
			},
			{
				"open": 4.8418,
				"close": 4.8218,
				"volume": 1900799544,
				"time": 1189051200000,
				"lowest": 4.7396,
				"highest": 4.9132,
				"volumePrice": 4.8156,
				"transactionCount": 328036,
				"voluemPrice": 4.8156
			},
			{
				"open": 4.7161,
				"close": 4.7061,
				"volume": 1430492476,
				"time": 1189137600000,
				"lowest": 4.6429,
				"highest": 4.725,
				"volumePrice": 4.6856,
				"transactionCount": 246451,
				"voluemPrice": 4.6856
			},
			{
				"open": 4.8929,
				"close": 4.8825,
				"volume": 1487831856,
				"time": 1189396800000,
				"lowest": 4.7839,
				"highest": 4.93,
				"volumePrice": 4.8481,
				"transactionCount": 254611,
				"voluemPrice": 4.8481
			},
			{
				"open": 4.925,
				"close": 4.8389,
				"volume": 971872272,
				"time": 1189483200000,
				"lowest": 4.7768,
				"highest": 4.9393,
				"volumePrice": 4.8494,
				"transactionCount": 170686,
				"voluemPrice": 4.8494
			},
			{
				"open": 4.8568,
				"close": 4.8875,
				"volume": 1023345288,
				"time": 1189569600000,
				"lowest": 4.8482,
				"highest": 4.9786,
				"volumePrice": 4.9193,
				"transactionCount": 185091,
				"voluemPrice": 4.9193
			},
			{
				"open": 4.9582,
				"close": 4.9,
				"volume": 656160792,
				"time": 1189656000000,
				"lowest": 4.8804,
				"highest": 4.9643,
				"volumePrice": 4.9168,
				"transactionCount": 117923,
				"voluemPrice": 4.9168
			},
			{
				"open": 4.8782,
				"close": 4.9575,
				"volume": 607070520,
				"time": 1189742400000,
				"lowest": 4.8643,
				"highest": 4.9636,
				"volumePrice": 4.9307,
				"transactionCount": 109489,
				"voluemPrice": 4.9307
			},
			{
				"open": 4.9643,
				"close": 4.9432,
				"volume": 793369192,
				"time": 1190001600000,
				"lowest": 4.9143,
				"highest": 5.0211,
				"volumePrice": 4.965,
				"transactionCount": 140768,
				"voluemPrice": 4.965
			},
			{
				"open": 4.9661,
				"close": 5.0329,
				"volume": 1064088060,
				"time": 1190088000000,
				"lowest": 4.9225,
				"highest": 5.1018,
				"volumePrice": 5.0034,
				"transactionCount": 189347,
				"voluemPrice": 5.0034
			},
			{
				"open": 5.1079,
				"close": 5.0275,
				"volume": 1026879392,
				"time": 1190174400000,
				"lowest": 4.9786,
				"highest": 5.1129,
				"volumePrice": 5.0645,
				"transactionCount": 187941,
				"voluemPrice": 5.0645
			},
			{
				"open": 5.0054,
				"close": 5.0111,
				"volume": 691839092,
				"time": 1190260800000,
				"lowest": 4.9757,
				"highest": 5.0639,
				"volumePrice": 5.0179,
				"transactionCount": 123045,
				"voluemPrice": 5.0179
			},
			{
				"open": 5.0407,
				"close": 5.1482,
				"volume": 1138878188,
				"time": 1190347200000,
				"lowest": 5.0221,
				"highest": 5.1661,
				"volumePrice": 5.1068,
				"transactionCount": 162545,
				"voluemPrice": 5.1068
			},
			{
				"open": 5.2404,
				"close": 5.2957,
				"volume": 1052109716,
				"time": 1190606400000,
				"lowest": 5.2375,
				"highest": 5.3518,
				"volumePrice": 5.2967,
				"transactionCount": 181473,
				"voluemPrice": 5.2967
			},
			{
				"open": 5.2443,
				"close": 5.4707,
				"volume": 1192548700,
				"time": 1190692800000,
				"lowest": 5.2436,
				"highest": 5.4721,
				"volumePrice": 5.3684,
				"transactionCount": 199320,
				"voluemPrice": 5.3684
			},
			{
				"open": 5.5168,
				"close": 5.4561,
				"volume": 975267160,
				"time": 1190779200000,
				"lowest": 5.4018,
				"highest": 5.5357,
				"volumePrice": 5.4648,
				"transactionCount": 169880,
				"voluemPrice": 5.4648
			},
			{
				"open": 5.4918,
				"close": 5.5179,
				"volume": 658196392,
				"time": 1190865600000,
				"lowest": 5.44,
				"highest": 5.5186,
				"volumePrice": 5.489,
				"transactionCount": 107688,
				"voluemPrice": 5.489
			},
			{
				"open": 5.48,
				"close": 5.4811,
				"volume": 615100724,
				"time": 1190952000000,
				"lowest": 5.4554,
				"highest": 5.5214,
				"volumePrice": 5.4865,
				"transactionCount": 108372,
				"voluemPrice": 5.4865
			},
			{
				"open": 5.5225,
				"close": 5.5835,
				"volume": 837065768,
				"time": 1191211200000,
				"lowest": 5.4618,
				"highest": 5.6218,
				"volumePrice": 5.5483,
				"transactionCount": 147310,
				"voluemPrice": 5.5483
			},
			{
				"open": 5.5911,
				"close": 5.6589,
				"volume": 792069376,
				"time": 1191297600000,
				"lowest": 5.5675,
				"highest": 5.6639,
				"volumePrice": 5.6181,
				"transactionCount": 135249,
				"voluemPrice": 5.6181
			},
			{
				"open": 5.635,
				"close": 5.64,
				"volume": 692516636,
				"time": 1191384000000,
				"lowest": 5.6075,
				"highest": 5.685,
				"volumePrice": 5.6452,
				"transactionCount": 121006,
				"voluemPrice": 5.6452
			},
			{
				"open": 5.6429,
				"close": 5.58,
				"volume": 656957868,
				"time": 1191470400000,
				"lowest": 5.4821,
				"highest": 5.6457,
				"volumePrice": 5.5691,
				"transactionCount": 106545,
				"voluemPrice": 5.5691
			},
			{
				"open": 5.6557,
				"close": 5.7661,
				"volume": 943468820,
				"time": 1191556800000,
				"lowest": 5.6321,
				"highest": 5.7707,
				"volumePrice": 5.7012,
				"transactionCount": 150592,
				"voluemPrice": 5.7012
			},
			{
				"open": 5.8271,
				"close": 5.9968,
				"volume": 835926308,
				"time": 1191816000000,
				"lowest": 5.8204,
				"highest": 5.9968,
				"volumePrice": 5.8927,
				"transactionCount": 142487,
				"voluemPrice": 5.8927
			},
			{
				"open": 6.0786,
				"close": 5.995,
				"volume": 1105845328,
				"time": 1191902400000,
				"lowest": 5.9529,
				"highest": 6.1111,
				"volumePrice": 6.0097,
				"transactionCount": 197940,
				"voluemPrice": 6.0097
			},
			{
				"open": 5.9839,
				"close": 5.9568,
				"volume": 667588236,
				"time": 1191988800000,
				"lowest": 5.9143,
				"highest": 5.9957,
				"volumePrice": 5.9494,
				"transactionCount": 124649,
				"voluemPrice": 5.9494
			},
			{
				"open": 6.0532,
				"close": 5.7939,
				"volume": 1643801572,
				"time": 1192075200000,
				"lowest": 5.4718,
				"highest": 6.1386,
				"volumePrice": 5.8994,
				"transactionCount": 298224,
				"voluemPrice": 5.8994
			},
			{
				"open": 5.8218,
				"close": 5.9733,
				"volume": 988173676,
				"time": 1192161600000,
				"lowest": 5.7786,
				"highest": 5.9743,
				"volumePrice": 5.9142,
				"transactionCount": 181715,
				"voluemPrice": 5.9142
			},
			{
				"open": 5.9993,
				"close": 5.9636,
				"volume": 1077928964,
				"time": 1192420800000,
				"lowest": 5.8393,
				"highest": 6.0561,
				"volumePrice": 5.943,
				"transactionCount": 202849,
				"voluemPrice": 5.943
			},
			{
				"open": 5.9121,
				"close": 6.0564,
				"volume": 1067829756,
				"time": 1192507200000,
				"lowest": 5.8993,
				"highest": 6.0779,
				"volumePrice": 6.0222,
				"transactionCount": 197289,
				"voluemPrice": 6.0222
			},
			{
				"open": 6.1675,
				"close": 6.1696,
				"volume": 1127612136,
				"time": 1192593600000,
				"lowest": 6.0421,
				"highest": 6.18,
				"volumePrice": 6.1204,
				"transactionCount": 206690,
				"voluemPrice": 6.1204
			},
			{
				"open": 6.125,
				"close": 6.1964,
				"volume": 824266072,
				"time": 1192680000000,
				"lowest": 6.1089,
				"highest": 6.2211,
				"volumePrice": 6.1698,
				"transactionCount": 148446,
				"voluemPrice": 6.1698
			},
			{
				"open": 6.2229,
				"close": 6.0864,
				"volume": 1291552528,
				"time": 1192766400000,
				"lowest": 6.0714,
				"highest": 6.2368,
				"volumePrice": 6.1627,
				"transactionCount": 230652,
				"voluemPrice": 6.1627
			},
			{
				"open": 6.0839,
				"close": 6.2271,
				"volume": 1649496940,
				"time": 1193025600000,
				"lowest": 6.0704,
				"highest": 6.2464,
				"volumePrice": 6.2839,
				"transactionCount": 295058,
				"voluemPrice": 6.2839
			},
			{
				"open": 6.7343,
				"close": 6.6486,
				"volume": 1794792972,
				"time": 1193112000000,
				"lowest": 6.5271,
				"highest": 6.7357,
				"volumePrice": 6.6436,
				"transactionCount": 318875,
				"voluemPrice": 6.6436
			},
			{
				"open": 6.6361,
				"close": 6.6404,
				"volume": 1288479696,
				"time": 1193198400000,
				"lowest": 6.4014,
				"highest": 6.6861,
				"volumePrice": 6.5577,
				"transactionCount": 245739,
				"voluemPrice": 6.5577
			},
			{
				"open": 6.6025,
				"close": 6.5279,
				"volume": 973615860,
				"time": 1193284800000,
				"lowest": 6.4879,
				"highest": 6.6393,
				"volumePrice": 6.5687,
				"transactionCount": 184628,
				"voluemPrice": 6.5687
			},
			{
				"open": 6.6175,
				"close": 6.5964,
				"volume": 706327356,
				"time": 1193371200000,
				"lowest": 6.5314,
				"highest": 6.6204,
				"volumePrice": 6.5865,
				"transactionCount": 128355,
				"voluemPrice": 6.5865
			},
			{
				"open": 6.6321,
				"close": 6.6104,
				"volume": 538028792,
				"time": 1193630400000,
				"lowest": 6.5964,
				"highest": 6.6639,
				"volumePrice": 6.6209,
				"transactionCount": 99309,
				"voluemPrice": 6.6209
			},
			{
				"open": 6.6493,
				"close": 6.6786,
				"volume": 939412908,
				"time": 1193716800000,
				"lowest": 6.5975,
				"highest": 6.7632,
				"volumePrice": 6.6887,
				"transactionCount": 171585,
				"voluemPrice": 6.6887
			},
			{
				"open": 6.7011,
				"close": 6.7839,
				"volume": 833310464,
				"time": 1193803200000,
				"lowest": 6.6054,
				"highest": 6.79,
				"volumePrice": 6.7353,
				"transactionCount": 148382,
				"voluemPrice": 6.7353
			},
			{
				"open": 6.7357,
				"close": 6.6943,
				"volume": 805015344,
				"time": 1193889600000,
				"lowest": 6.4286,
				"highest": 6.7893,
				"volumePrice": 6.7285,
				"transactionCount": 152376,
				"voluemPrice": 6.7285
			},
			{
				"open": 6.7575,
				"close": 6.7096,
				"volume": 1002113644,
				"time": 1193976000000,
				"lowest": 6.5532,
				"highest": 6.7657,
				"volumePrice": 6.6652,
				"transactionCount": 195998,
				"voluemPrice": 6.6652
			},
			{
				"open": 6.6175,
				"close": 6.6493,
				"volume": 804174644,
				"time": 1194238800000,
				"lowest": 6.58,
				"highest": 6.7486,
				"volumePrice": 6.6635,
				"transactionCount": 151334,
				"voluemPrice": 6.6635
			},
			{
				"open": 6.6804,
				"close": 6.8496,
				"volume": 954725296,
				"time": 1194325200000,
				"lowest": 6.6168,
				"highest": 6.8571,
				"volumePrice": 6.7245,
				"transactionCount": 181508,
				"voluemPrice": 6.7245
			},
			{
				"open": 6.8075,
				"close": 6.6536,
				"volume": 994309204,
				"time": 1194411600000,
				"lowest": 6.6475,
				"highest": 6.8814,
				"volumePrice": 6.7898,
				"transactionCount": 200683,
				"voluemPrice": 6.7898
			},
			{
				"open": 6.6668,
				"close": 6.2667,
				"volume": 1890377048,
				"time": 1194498000000,
				"lowest": 5.9918,
				"highest": 6.675,
				"volumePrice": 6.3405,
				"transactionCount": 356927,
				"voluemPrice": 6.3405
			},
			{
				"open": 6.1125,
				"close": 5.9061,
				"volume": 1526238112,
				"time": 1194584400000,
				"lowest": 5.9004,
				"highest": 6.2543,
				"volumePrice": 6.1013,
				"transactionCount": 295346,
				"voluemPrice": 6.1013
			},
			{
				"open": 5.9029,
				"close": 5.4914,
				"volume": 1769050528,
				"time": 1194843600000,
				"lowest": 5.3796,
				"highest": 5.9893,
				"volumePrice": 5.7113,
				"transactionCount": 330993,
				"voluemPrice": 5.7113
			},
			{
				"open": 5.7446,
				"close": 6.07,
				"volume": 1739446212,
				"time": 1194930000000,
				"lowest": 5.7193,
				"highest": 6.1064,
				"volumePrice": 5.9446,
				"transactionCount": 330696,
				"voluemPrice": 5.9446
			},
			{
				"open": 6.3271,
				"close": 5.9325,
				"volume": 1449167552,
				"time": 1195016400000,
				"lowest": 5.8479,
				"highest": 6.3418,
				"volumePrice": 6.145,
				"transactionCount": 268285,
				"voluemPrice": 6.145
			},
			{
				"open": 5.9425,
				"close": 5.8679,
				"volume": 1487409980,
				"time": 1195102800000,
				"lowest": 5.725,
				"highest": 6.0568,
				"volumePrice": 5.9247,
				"transactionCount": 272827,
				"voluemPrice": 5.9247
			},
			{
				"open": 5.9036,
				"close": 5.9425,
				"volume": 1382282244,
				"time": 1195189200000,
				"lowest": 5.6904,
				"highest": 5.965,
				"volumePrice": 5.8537,
				"transactionCount": 258386,
				"voluemPrice": 5.8537
			},
			{
				"open": 5.9321,
				"close": 5.8554,
				"volume": 1154419560,
				"time": 1195448400000,
				"lowest": 5.7893,
				"highest": 6.0071,
				"volumePrice": 5.894,
				"transactionCount": 213631,
				"voluemPrice": 5.894
			},
			{
				"open": 5.9168,
				"close": 6.0304,
				"volume": 1543640392,
				"time": 1195534800000,
				"lowest": 5.8404,
				"highest": 6.1354,
				"volumePrice": 6.0066,
				"transactionCount": 287453,
				"voluemPrice": 6.0066
			},
			{
				"open": 5.9229,
				"close": 6.0164,
				"volume": 1218585536,
				"time": 1195621200000,
				"lowest": 5.8811,
				"highest": 6.1554,
				"volumePrice": 6.0052,
				"transactionCount": 217755,
				"voluemPrice": 6.0052
			},
			{
				"open": 6.1429,
				"close": 6.1264,
				"volume": 465756004,
				"time": 1195794000000,
				"lowest": 6.0625,
				"highest": 6.1446,
				"volumePrice": 6.101,
				"transactionCount": 76584,
				"voluemPrice": 6.101
			},
			{
				"open": 6.1996,
				"close": 6.1622,
				"volume": 1305753736,
				"time": 1196053200000,
				"lowest": 6.1554,
				"highest": 6.3311,
				"volumePrice": 6.2506,
				"transactionCount": 242319,
				"voluemPrice": 6.2506
			},
			{
				"open": 6.2579,
				"close": 6.2432,
				"volume": 1317013908,
				"time": 1196139600000,
				"lowest": 6.0718,
				"highest": 6.2782,
				"volumePrice": 6.2053,
				"transactionCount": 257884,
				"voluemPrice": 6.2053
			},
			{
				"open": 6.315,
				"close": 6.4364,
				"volume": 1150911076,
				"time": 1196226000000,
				"lowest": 6.2625,
				"highest": 6.45,
				"volumePrice": 6.3696,
				"transactionCount": 213835,
				"voluemPrice": 6.3696
			},
			{
				"open": 6.4082,
				"close": 6.5818,
				"volume": 1049329624,
				"time": 1196312400000,
				"lowest": 6.3982,
				"highest": 6.6132,
				"volumePrice": 6.5228,
				"transactionCount": 197384,
				"voluemPrice": 6.5228
			},
			{
				"open": 6.6907,
				"close": 6.5079,
				"volume": 1186831968,
				"time": 1196398800000,
				"lowest": 6.4179,
				"highest": 6.7036,
				"volumePrice": 6.5535,
				"transactionCount": 225966,
				"voluemPrice": 6.5535
			},
			{
				"open": 6.495,
				"close": 6.3879,
				"volume": 961468984,
				"time": 1196658000000,
				"lowest": 6.3464,
				"highest": 6.5764,
				"volumePrice": 6.4565,
				"transactionCount": 186127,
				"voluemPrice": 6.4565
			},
			{
				"open": 6.3268,
				"close": 6.4218,
				"volume": 773799068,
				"time": 1196744400000,
				"lowest": 6.3211,
				"highest": 6.4607,
				"volumePrice": 6.4078,
				"transactionCount": 151141,
				"voluemPrice": 6.4078
			},
			{
				"open": 6.5318,
				"close": 6.625,
				"volume": 892401244,
				"time": 1196830800000,
				"lowest": 6.5146,
				"highest": 6.6429,
				"volumePrice": 6.5864,
				"transactionCount": 173175,
				"voluemPrice": 6.5864
			},
			{
				"open": 6.6568,
				"close": 6.784,
				"volume": 899730020,
				"time": 1196917200000,
				"lowest": 6.6471,
				"highest": 6.7857,
				"volumePrice": 6.7398,
				"transactionCount": 160679,
				"voluemPrice": 6.7398
			},
			{
				"open": 6.805,
				"close": 6.9393,
				"volume": 1066013172,
				"time": 1197003600000,
				"lowest": 6.7157,
				"highest": 6.9639,
				"volumePrice": 6.8474,
				"transactionCount": 190639,
				"voluemPrice": 6.8474
			},
			{
				"open": 6.91,
				"close": 6.9361,
				"volume": 721840840,
				"time": 1197262800000,
				"lowest": 6.8818,
				"highest": 6.9879,
				"volumePrice": 6.9374,
				"transactionCount": 128271,
				"voluemPrice": 6.9374
			},
			{
				"open": 6.9554,
				"close": 6.7336,
				"volume": 1110918732,
				"time": 1197349200000,
				"lowest": 6.6925,
				"highest": 7.0296,
				"volumePrice": 6.8867,
				"transactionCount": 217987,
				"voluemPrice": 6.8867
			},
			{
				"open": 6.9086,
				"close": 6.8164,
				"volume": 1225659736,
				"time": 1197435600000,
				"lowest": 6.6343,
				"highest": 6.9457,
				"volumePrice": 6.8283,
				"transactionCount": 243636,
				"voluemPrice": 6.8283
			},
			{
				"open": 6.7925,
				"close": 6.8511,
				"volume": 865917388,
				"time": 1197522000000,
				"lowest": 6.7079,
				"highest": 6.8614,
				"volumePrice": 6.792,
				"transactionCount": 175804,
				"voluemPrice": 6.792
			},
			{
				"open": 6.7989,
				"close": 6.7996,
				"volume": 674586892,
				"time": 1197608400000,
				"lowest": 6.7693,
				"highest": 7.1429,
				"volumePrice": 6.8242,
				"transactionCount": 125911,
				"voluemPrice": 6.8242
			},
			{
				"open": 6.8114,
				"close": 6.5857,
				"volume": 1024691752,
				"time": 1197867600000,
				"lowest": 6.535,
				"highest": 6.8804,
				"volumePrice": 6.6732,
				"transactionCount": 198714,
				"voluemPrice": 6.6732
			},
			{
				"open": 6.6614,
				"close": 6.535,
				"volume": 1222587884,
				"time": 1197954000000,
				"lowest": 6.3786,
				"highest": 6.6904,
				"volumePrice": 6.5433,
				"transactionCount": 230151,
				"voluemPrice": 6.5433
			},
			{
				"open": 6.535,
				"close": 6.54,
				"volume": 827477952,
				"time": 1198040400000,
				"lowest": 6.4607,
				"highest": 6.5943,
				"volumePrice": 6.5319,
				"transactionCount": 154484,
				"voluemPrice": 6.5319
			},
			{
				"open": 6.6225,
				"close": 6.6861,
				"volume": 773774484,
				"time": 1198126800000,
				"lowest": 6.5475,
				"highest": 6.7082,
				"volumePrice": 6.6243,
				"transactionCount": 145076,
				"voluemPrice": 6.6243
			},
			{
				"open": 6.79,
				"close": 6.9254,
				"volume": 995256696,
				"time": 1198213200000,
				"lowest": 6.7818,
				"highest": 6.9254,
				"volumePrice": 6.8517,
				"transactionCount": 146430,
				"voluemPrice": 6.8517
			},
			{
				"open": 6.9654,
				"close": 7.1,
				"volume": 480200700,
				"time": 1198472400000,
				"lowest": 6.9568,
				"highest": 7.1189,
				"volumePrice": 7.0502,
				"transactionCount": 77805,
				"voluemPrice": 7.0502
			},
			{
				"open": 7.1075,
				"close": 7.1054,
				"volume": 703732092,
				"time": 1198645200000,
				"lowest": 7.0293,
				"highest": 7.1771,
				"volumePrice": 7.116,
				"transactionCount": 116477,
				"voluemPrice": 7.116
			},
			{
				"open": 7.1054,
				"close": 7.0918,
				"volume": 795525472,
				"time": 1198731600000,
				"lowest": 7.0643,
				"highest": 7.2486,
				"volumePrice": 7.1711,
				"transactionCount": 139659,
				"voluemPrice": 7.1711
			},
			{
				"open": 7.1639,
				"close": 7.1368,
				"volume": 699647144,
				"time": 1198818000000,
				"lowest": 7.0314,
				"highest": 7.1986,
				"volumePrice": 7.1271,
				"transactionCount": 123409,
				"voluemPrice": 7.1271
			},
			{
				"open": 7.125,
				"close": 7.0743,
				"volume": 539331576,
				"time": 1199077200000,
				"lowest": 7.0625,
				"highest": 7.1607,
				"volumePrice": 7.1155,
				"transactionCount": 93841,
				"voluemPrice": 7.1155
			},
			{
				"open": 7.1168,
				"close": 6.9586,
				"volume": 1079176560,
				"time": 1199250000000,
				"lowest": 6.8768,
				"highest": 7.1521,
				"volumePrice": 7.0068,
				"transactionCount": 206561,
				"voluemPrice": 7.0068
			},
			{
				"open": 6.9789,
				"close": 6.9618,
				"volume": 842065840,
				"time": 1199336400000,
				"lowest": 6.8818,
				"highest": 7.0496,
				"volumePrice": 6.9778,
				"transactionCount": 152740,
				"voluemPrice": 6.9778
			},
			{
				"open": 6.8375,
				"close": 6.4304,
				"volume": 1455829312,
				"time": 1199422800000,
				"lowest": 6.3889,
				"highest": 6.8929,
				"volumePrice": 6.6595,
				"transactionCount": 272793,
				"voluemPrice": 6.6595
			},
			{
				"open": 6.4732,
				"close": 6.3443,
				"volume": 2071740160,
				"time": 1199682000000,
				"lowest": 6.0796,
				"highest": 6.5571,
				"volumePrice": 6.3225,
				"transactionCount": 399096,
				"voluemPrice": 6.3225
			},
			{
				"open": 6.4336,
				"close": 6.1161,
				"volume": 1523815552,
				"time": 1199768400000,
				"lowest": 6.1,
				"highest": 6.5164,
				"volumePrice": 6.3734,
				"transactionCount": 290427,
				"voluemPrice": 6.3734
			},
			{
				"open": 6.1179,
				"close": 6.4071,
				"volume": 1815538844,
				"time": 1199854800000,
				"lowest": 6.0107,
				"highest": 6.4107,
				"volumePrice": 6.1639,
				"transactionCount": 362654,
				"voluemPrice": 6.1639
			},
			{
				"open": 6.3421,
				"close": 6.3579,
				"volume": 1482866448,
				"time": 1199941200000,
				"lowest": 6.2646,
				"highest": 6.4643,
				"volumePrice": 6.3487,
				"transactionCount": 293027,
				"voluemPrice": 6.3487
			},
			{
				"open": 6.2857,
				"close": 6.1675,
				"volume": 1232280308,
				"time": 1200027600000,
				"lowest": 6.0714,
				"highest": 6.3518,
				"volumePrice": 6.2007,
				"transactionCount": 229730,
				"voluemPrice": 6.2007
			},
			{
				"open": 6.34,
				"close": 6.385,
				"volume": 1100444072,
				"time": 1200286800000,
				"lowest": 6.2561,
				"highest": 6.4079,
				"volumePrice": 6.338,
				"transactionCount": 196419,
				"voluemPrice": 6.338
			},
			{
				"open": 6.3471,
				"close": 6.0371,
				"volume": 2350253416,
				"time": 1200373200000,
				"lowest": 5.8807,
				"highest": 6.4007,
				"volumePrice": 6.1051,
				"transactionCount": 453024,
				"voluemPrice": 6.1051
			},
			{
				"open": 5.9011,
				"close": 5.7014,
				"volume": 2217375692,
				"time": 1200459600000,
				"lowest": 5.5964,
				"highest": 6.0361,
				"volumePrice": 5.8088,
				"transactionCount": 429461,
				"voluemPrice": 5.8088
			},
			{
				"open": 5.7682,
				"close": 5.7461,
				"volume": 1759895564,
				"time": 1200546000000,
				"lowest": 5.6579,
				"highest": 5.9057,
				"volumePrice": 5.7537,
				"transactionCount": 340697,
				"voluemPrice": 5.7537
			},
			{
				"open": 5.7754,
				"close": 5.7629,
				"volume": 1724342228,
				"time": 1200632400000,
				"lowest": 5.7004,
				"highest": 5.9196,
				"volumePrice": 5.8055,
				"transactionCount": 335520,
				"voluemPrice": 5.8055
			},
			{
				"open": 5.2879,
				"close": 5.6089,
				"volume": 2434977440,
				"time": 1200978000000,
				"lowest": 5.2143,
				"highest": 5.7136,
				"volumePrice": 5.4909,
				"transactionCount": 466312,
				"voluemPrice": 5.4909
			},
			{
				"open": 4.8639,
				"close": 4.9643,
				"volume": 3373042176,
				"time": 1201064400000,
				"lowest": 4.505,
				"highest": 5,
				"volumePrice": 4.778,
				"transactionCount": 643228,
				"voluemPrice": 4.778
			},
			{
				"open": 4.9996,
				"close": 4.8429,
				"volume": 2005864364,
				"time": 1201150800000,
				"lowest": 4.7146,
				"highest": 5.025,
				"volumePrice": 4.8464,
				"transactionCount": 361122,
				"voluemPrice": 4.8464
			},
			{
				"open": 4.9639,
				"close": 4.6432,
				"volume": 1554736792,
				"time": 1201237200000,
				"lowest": 4.6289,
				"highest": 4.9675,
				"volumePrice": 4.7694,
				"transactionCount": 271146,
				"voluemPrice": 4.7694
			},
			{
				"open": 4.5771,
				"close": 4.6432,
				"volume": 1474843272,
				"time": 1201496400000,
				"lowest": 4.5161,
				"highest": 4.7571,
				"volumePrice": 4.6383,
				"transactionCount": 268793,
				"voluemPrice": 4.6383
			},
			{
				"open": 4.6839,
				"close": 4.6979,
				"volume": 1099982408,
				"time": 1201582800000,
				"lowest": 4.6089,
				"highest": 4.7425,
				"volumePrice": 4.6829,
				"transactionCount": 204602,
				"voluemPrice": 4.6829
			},
			{
				"open": 4.6918,
				"close": 4.7207,
				"volume": 1243049332,
				"time": 1201669200000,
				"lowest": 4.6429,
				"highest": 4.8375,
				"volumePrice": 4.7396,
				"transactionCount": 246880,
				"voluemPrice": 4.7396
			},
			{
				"open": 4.6232,
				"close": 4.8343,
				"volume": 1345671964,
				"time": 1201755600000,
				"lowest": 4.6214,
				"highest": 4.8804,
				"volumePrice": 4.7704,
				"transactionCount": 259545,
				"voluemPrice": 4.7704
			},
			{
				"open": 4.8657,
				"close": 4.7768,
				"volume": 1010743496,
				"time": 1201842000000,
				"lowest": 4.7207,
				"highest": 4.8782,
				"volumePrice": 4.7948,
				"transactionCount": 196710,
				"voluemPrice": 4.7948
			},
			{
				"open": 4.7932,
				"close": 4.7018,
				"volume": 899233468,
				"time": 1202101200000,
				"lowest": 4.6936,
				"highest": 4.8536,
				"volumePrice": 4.7688,
				"transactionCount": 164753,
				"voluemPrice": 4.7688
			},
			{
				"open": 4.6582,
				"close": 4.62,
				"volume": 1140985748,
				"time": 1202187600000,
				"lowest": 4.6036,
				"highest": 4.7857,
				"volumePrice": 4.6761,
				"transactionCount": 227834,
				"voluemPrice": 4.6761
			},
			{
				"open": 4.6725,
				"close": 4.3571,
				"volume": 1573269740,
				"time": 1202274000000,
				"lowest": 4.3489,
				"highest": 4.7114,
				"volumePrice": 4.525,
				"transactionCount": 296146,
				"voluemPrice": 4.525
			},
			{
				"open": 4.2846,
				"close": 4.33,
				"volume": 2084607700,
				"time": 1202360400000,
				"lowest": 4.1882,
				"highest": 4.4564,
				"volumePrice": 4.3154,
				"transactionCount": 416219,
				"voluemPrice": 4.3154
			},
			{
				"open": 4.36,
				"close": 4.4814,
				"volume": 1355970560,
				"time": 1202446800000,
				"lowest": 4.3429,
				"highest": 4.4893,
				"volumePrice": 4.4254,
				"transactionCount": 254429,
				"voluemPrice": 4.4254
			},
			{
				"open": 4.5725,
				"close": 4.6232,
				"volume": 1201430076,
				"time": 1202706000000,
				"lowest": 4.5429,
				"highest": 4.6421,
				"volumePrice": 4.5971,
				"transactionCount": 221081,
				"voluemPrice": 4.5971
			},
			{
				"open": 4.6679,
				"close": 4.4593,
				"volume": 1225978404,
				"time": 1202792400000,
				"lowest": 4.415,
				"highest": 4.6786,
				"volumePrice": 4.5564,
				"transactionCount": 232900,
				"voluemPrice": 4.5564
			},
			{
				"open": 4.5243,
				"close": 4.6214,
				"volume": 968533132,
				"time": 1202878800000,
				"lowest": 4.4868,
				"highest": 4.635,
				"volumePrice": 4.5676,
				"transactionCount": 183680,
				"voluemPrice": 4.5676
			},
			{
				"open": 4.6214,
				"close": 4.5521,
				"volume": 954190384,
				"time": 1202965200000,
				"lowest": 4.5361,
				"highest": 4.6714,
				"volumePrice": 4.589,
				"transactionCount": 188111,
				"voluemPrice": 4.589
			},
			{
				"open": 4.5096,
				"close": 4.4511,
				"volume": 901298888,
				"time": 1203051600000,
				"lowest": 4.4307,
				"highest": 4.5386,
				"volumePrice": 4.4729,
				"transactionCount": 162698,
				"voluemPrice": 4.4729
			},
			{
				"open": 4.4996,
				"close": 4.3636,
				"volume": 1005045384,
				"time": 1203397200000,
				"lowest": 4.3371,
				"highest": 4.5268,
				"volumePrice": 4.4476,
				"transactionCount": 182380,
				"voluemPrice": 4.4476
			},
			{
				"open": 4.3643,
				"close": 4.4221,
				"volume": 967437968,
				"time": 1203483600000,
				"lowest": 4.3457,
				"highest": 4.45,
				"volumePrice": 4.3936,
				"transactionCount": 183836,
				"voluemPrice": 4.3936
			},
			{
				"open": 4.5018,
				"close": 4.3407,
				"volume": 938025312,
				"time": 1203570000000,
				"lowest": 4.3164,
				"highest": 4.5168,
				"volumePrice": 4.3948,
				"transactionCount": 174312,
				"voluemPrice": 4.3948
			},
			{
				"open": 4.3743,
				"close": 4.2664,
				"volume": 1529876236,
				"time": 1203656400000,
				"lowest": 4.1382,
				"highest": 4.3754,
				"volumePrice": 4.2356,
				"transactionCount": 271670,
				"voluemPrice": 4.2356
			},
			{
				"open": 4.2354,
				"close": 4.2764,
				"volume": 1256761100,
				"time": 1203915600000,
				"lowest": 4.1666,
				"highest": 4.2918,
				"volumePrice": 4.2283,
				"transactionCount": 237205,
				"voluemPrice": 4.2283
			},
			{
				"open": 4.2014,
				"close": 4.2554,
				"volume": 1504872292,
				"time": 1204002000000,
				"lowest": 4.1229,
				"highest": 4.3246,
				"volumePrice": 4.2373,
				"transactionCount": 278400,
				"voluemPrice": 4.2373
			},
			{
				"open": 4.2225,
				"close": 4.3914,
				"volume": 1475135564,
				"time": 1204088400000,
				"lowest": 4.2175,
				"highest": 4.3946,
				"volumePrice": 4.3268,
				"transactionCount": 275633,
				"voluemPrice": 4.3268
			},
			{
				"open": 4.5429,
				"close": 4.6396,
				"volume": 1618251712,
				"time": 1204174800000,
				"lowest": 4.4918,
				"highest": 4.7214,
				"volumePrice": 4.6168,
				"transactionCount": 292202,
				"voluemPrice": 4.6168
			},
			{
				"open": 4.6175,
				"close": 4.465,
				"volume": 1255479232,
				"time": 1204261200000,
				"lowest": 4.4571,
				"highest": 4.6504,
				"volumePrice": 4.5351,
				"transactionCount": 247765,
				"voluemPrice": 4.5351
			},
			{
				"open": 4.4443,
				"close": 4.3475,
				"volume": 1593641168,
				"time": 1204520400000,
				"lowest": 4.2143,
				"highest": 4.4993,
				"volumePrice": 4.3272,
				"transactionCount": 290759,
				"voluemPrice": 4.3272
			},
			{
				"open": 4.3568,
				"close": 4.4507,
				"volume": 1785383096,
				"time": 1204606800000,
				"lowest": 4.3,
				"highest": 4.46,
				"volumePrice": 4.379,
				"transactionCount": 334952,
				"voluemPrice": 4.379
			},
			{
				"open": 4.4136,
				"close": 4.4461,
				"volume": 1221834320,
				"time": 1204693200000,
				"lowest": 4.3661,
				"highest": 4.4693,
				"volumePrice": 4.4185,
				"transactionCount": 244734,
				"voluemPrice": 4.4185
			},
			{
				"open": 4.4505,
				"close": 4.3189,
				"volume": 1473683512,
				"time": 1204779600000,
				"lowest": 4.3146,
				"highest": 4.5536,
				"volumePrice": 4.4627,
				"transactionCount": 294391,
				"voluemPrice": 4.4627
			},
			{
				"open": 4.3004,
				"close": 4.3661,
				"volume": 1230703460,
				"time": 1204866000000,
				"lowest": 4.2518,
				"highest": 4.3921,
				"volumePrice": 4.3277,
				"transactionCount": 267118,
				"voluemPrice": 4.3277
			},
			{
				"open": 4.3564,
				"close": 4.2746,
				"volume": 1000693316,
				"time": 1205121600000,
				"lowest": 4.2632,
				"highest": 4.4093,
				"volumePrice": 4.3309,
				"transactionCount": 208488,
				"voluemPrice": 4.3309
			},
			{
				"open": 4.4321,
				"close": 4.5482,
				"volume": 1165385256,
				"time": 1205208000000,
				"lowest": 4.3571,
				"highest": 4.5529,
				"volumePrice": 4.4368,
				"transactionCount": 223317,
				"voluemPrice": 4.4368
			},
			{
				"open": 4.5371,
				"close": 4.5011,
				"volume": 1059375968,
				"time": 1205294400000,
				"lowest": 4.4704,
				"highest": 4.5957,
				"volumePrice": 4.5337,
				"transactionCount": 197233,
				"voluemPrice": 4.5337
			},
			{
				"open": 4.4321,
				"close": 4.5693,
				"volume": 1264179140,
				"time": 1205380800000,
				"lowest": 4.3929,
				"highest": 4.625,
				"volumePrice": 4.502,
				"transactionCount": 244338,
				"voluemPrice": 4.502
			},
			{
				"open": 4.6386,
				"close": 4.5218,
				"volume": 1156855532,
				"time": 1205467200000,
				"lowest": 4.4357,
				"highest": 4.6536,
				"volumePrice": 4.5318,
				"transactionCount": 233272,
				"voluemPrice": 4.5318
			},
			{
				"open": 4.3768,
				"close": 4.5261,
				"volume": 1072598072,
				"time": 1205726400000,
				"lowest": 4.3768,
				"highest": 4.5925,
				"volumePrice": 4.4791,
				"transactionCount": 209190,
				"voluemPrice": 4.4791
			},
			{
				"open": 4.6136,
				"close": 4.7436,
				"volume": 1205118460,
				"time": 1205812800000,
				"lowest": 4.5954,
				"highest": 4.7501,
				"volumePrice": 4.6659,
				"transactionCount": 234322,
				"voluemPrice": 4.6659
			},
			{
				"open": 4.7543,
				"close": 4.6311,
				"volume": 1010536800,
				"time": 1205899200000,
				"lowest": 4.6311,
				"highest": 4.7961,
				"volumePrice": 4.705,
				"transactionCount": 191607,
				"voluemPrice": 4.705
			},
			{
				"open": 4.6829,
				"close": 4.7596,
				"volume": 908786200,
				"time": 1205985600000,
				"lowest": 4.6136,
				"highest": 4.7604,
				"volumePrice": 4.6834,
				"transactionCount": 157277,
				"voluemPrice": 4.6834
			},
			{
				"open": 4.7861,
				"close": 4.9832,
				"volume": 1066562196,
				"time": 1206331200000,
				"lowest": 4.7729,
				"highest": 5.0304,
				"volumePrice": 4.9444,
				"transactionCount": 195980,
				"voluemPrice": 4.9444
			},
			{
				"open": 4.9986,
				"close": 5.035,
				"volume": 1052691920,
				"time": 1206417600000,
				"lowest": 4.9046,
				"highest": 5.1107,
				"volumePrice": 5.0195,
				"transactionCount": 200164,
				"voluemPrice": 5.0195
			},
			{
				"open": 5.0311,
				"close": 5.1807,
				"volume": 1182082524,
				"time": 1206504000000,
				"lowest": 5.0229,
				"highest": 5.205,
				"volumePrice": 5.1448,
				"transactionCount": 216300,
				"voluemPrice": 5.1448
			},
			{
				"open": 5.1768,
				"close": 5.0089,
				"volume": 999827668,
				"time": 1206590400000,
				"lowest": 4.9996,
				"highest": 5.1896,
				"volumePrice": 5.0978,
				"transactionCount": 191550,
				"voluemPrice": 5.0978
			},
			{
				"open": 5.0643,
				"close": 5.1075,
				"volume": 715318324,
				"time": 1206676800000,
				"lowest": 5.0571,
				"highest": 5.1661,
				"volumePrice": 5.1192,
				"transactionCount": 131420,
				"voluemPrice": 5.1192
			},
			{
				"open": 5.1168,
				"close": 5.125,
				"volume": 768063492,
				"time": 1206936000000,
				"lowest": 5.09,
				"highest": 5.2039,
				"volumePrice": 5.1429,
				"transactionCount": 141259,
				"voluemPrice": 5.1429
			},
			{
				"open": 5.225,
				"close": 5.3404,
				"volume": 1032564876,
				"time": 1207022400000,
				"lowest": 5.1782,
				"highest": 5.345,
				"volumePrice": 5.2587,
				"transactionCount": 185376,
				"voluemPrice": 5.2587
			},
			{
				"open": 5.3136,
				"close": 5.2675,
				"volume": 1040350192,
				"time": 1207108800000,
				"lowest": 5.2089,
				"highest": 5.4,
				"volumePrice": 5.3108,
				"transactionCount": 194024,
				"voluemPrice": 5.3108
			},
			{
				"open": 5.2521,
				"close": 5.4146,
				"volume": 1051764112,
				"time": 1207195200000,
				"lowest": 5.25,
				"highest": 5.4868,
				"volumePrice": 5.383,
				"transactionCount": 183157,
				"voluemPrice": 5.383
			},
			{
				"open": 5.4354,
				"close": 5.4671,
				"volume": 854767592,
				"time": 1207281600000,
				"lowest": 5.385,
				"highest": 5.5255,
				"volumePrice": 5.4491,
				"transactionCount": 156786,
				"voluemPrice": 5.4491
			},
			{
				"open": 5.5761,
				"close": 5.5675,
				"volume": 1159087664,
				"time": 1207540800000,
				"lowest": 5.5396,
				"highest": 5.7032,
				"volumePrice": 5.6095,
				"transactionCount": 219637,
				"voluemPrice": 5.6095
			},
			{
				"open": 5.4839,
				"close": 5.4586,
				"volume": 1015241528,
				"time": 1207627200000,
				"lowest": 5.44,
				"highest": 5.5875,
				"volumePrice": 5.5046,
				"transactionCount": 190575,
				"voluemPrice": 5.5046
			},
			{
				"open": 5.4754,
				"close": 5.4086,
				"volume": 873911500,
				"time": 1207713600000,
				"lowest": 5.3736,
				"highest": 5.4961,
				"volumePrice": 5.4185,
				"transactionCount": 172153,
				"voluemPrice": 5.4185
			},
			{
				"open": 5.3975,
				"close": 5.5196,
				"volume": 956488988,
				"time": 1207800000000,
				"lowest": 5.3786,
				"highest": 5.5507,
				"volumePrice": 5.5009,
				"transactionCount": 176376,
				"voluemPrice": 5.5009
			},
			{
				"open": 5.4543,
				"close": 5.255,
				"volume": 1210074488,
				"time": 1207886400000,
				"lowest": 5.2286,
				"highest": 5.475,
				"volumePrice": 5.3468,
				"transactionCount": 236635,
				"voluemPrice": 5.3468
			},
			{
				"open": 5.2418,
				"close": 5.2779,
				"volume": 845163088,
				"time": 1208145600000,
				"lowest": 5.1621,
				"highest": 5.3304,
				"volumePrice": 5.262,
				"transactionCount": 165678,
				"voluemPrice": 5.262
			},
			{
				"open": 5.3357,
				"close": 5.2993,
				"volume": 698036724,
				"time": 1208232000000,
				"lowest": 5.2043,
				"highest": 5.3471,
				"volumePrice": 5.2759,
				"transactionCount": 135729,
				"voluemPrice": 5.2759
			},
			{
				"open": 5.4186,
				"close": 5.4893,
				"volume": 795771760,
				"time": 1208318400000,
				"lowest": 5.3793,
				"highest": 5.5036,
				"volumePrice": 5.4566,
				"transactionCount": 155493,
				"voluemPrice": 5.4566
			},
			{
				"open": 5.5061,
				"close": 5.5175,
				"volume": 705299812,
				"time": 1208404800000,
				"lowest": 5.4768,
				"highest": 5.5714,
				"volumePrice": 5.5266,
				"transactionCount": 141951,
				"voluemPrice": 5.5266
			},
			{
				"open": 5.6829,
				"close": 5.7514,
				"volume": 1026763976,
				"time": 1208491200000,
				"lowest": 5.6564,
				"highest": 5.795,
				"volumePrice": 5.7287,
				"transactionCount": 192413,
				"voluemPrice": 5.7287
			},
			{
				"open": 5.7932,
				"close": 6.0057,
				"volume": 1039152520,
				"time": 1208750400000,
				"lowest": 5.7771,
				"highest": 6.0179,
				"volumePrice": 5.8928,
				"transactionCount": 199086,
				"voluemPrice": 5.8928
			},
			{
				"open": 5.9786,
				"close": 5.7214,
				"volume": 1439173372,
				"time": 1208836800000,
				"lowest": 5.6461,
				"highest": 6,
				"volumePrice": 5.7811,
				"transactionCount": 261273,
				"voluemPrice": 5.7811
			},
			{
				"open": 5.8589,
				"close": 5.8175,
				"volume": 1503966828,
				"time": 1208923200000,
				"lowest": 5.7529,
				"highest": 5.8871,
				"volumePrice": 5.8185,
				"transactionCount": 271674,
				"voluemPrice": 5.8185
			},
			{
				"open": 5.905,
				"close": 6.0336,
				"volume": 1697255644,
				"time": 1209009600000,
				"lowest": 5.6855,
				"highest": 6.0707,
				"volumePrice": 5.9283,
				"transactionCount": 299113,
				"voluemPrice": 5.9283
			},
			{
				"open": 6.0964,
				"close": 6.0618,
				"volume": 992462800,
				"time": 1209096000000,
				"lowest": 5.9436,
				"highest": 6.1107,
				"volumePrice": 6.0403,
				"transactionCount": 174063,
				"voluemPrice": 6.0403
			},
			{
				"open": 6.0625,
				"close": 6.1514,
				"volume": 787209752,
				"time": 1209355200000,
				"lowest": 6.0404,
				"highest": 6.2054,
				"volumePrice": 6.1401,
				"transactionCount": 147667,
				"voluemPrice": 6.1401
			},
			{
				"open": 6.1111,
				"close": 6.2518,
				"volume": 923475420,
				"time": 1209441600000,
				"lowest": 6.0804,
				"highest": 6.2736,
				"volumePrice": 6.2144,
				"transactionCount": 172005,
				"voluemPrice": 6.2144
			},
			{
				"open": 6.2925,
				"close": 6.2125,
				"volume": 1139516784,
				"time": 1209528000000,
				"lowest": 6.1757,
				"highest": 6.4286,
				"volumePrice": 6.3049,
				"transactionCount": 219537,
				"voluemPrice": 6.3049
			},
			{
				"open": 6.2486,
				"close": 6.4286,
				"volume": 903570304,
				"time": 1209614400000,
				"lowest": 6.245,
				"highest": 6.4286,
				"volumePrice": 6.3638,
				"transactionCount": 165969,
				"voluemPrice": 6.3638
			},
			{
				"open": 6.4354,
				"close": 6.4621,
				"volume": 1006079704,
				"time": 1209700800000,
				"lowest": 6.3768,
				"highest": 6.4971,
				"volumePrice": 6.4335,
				"transactionCount": 191070,
				"voluemPrice": 6.4335
			},
			{
				"open": 6.4971,
				"close": 6.5975,
				"volume": 854556892,
				"time": 1209960000000,
				"lowest": 6.4661,
				"highest": 6.6182,
				"volumePrice": 6.5674,
				"transactionCount": 163670,
				"voluemPrice": 6.5674
			},
			{
				"open": 6.595,
				"close": 6.6664,
				"volume": 918869420,
				"time": 1210046400000,
				"lowest": 6.5064,
				"highest": 6.6829,
				"volumePrice": 6.6068,
				"transactionCount": 179502,
				"voluemPrice": 6.6068
			},
			{
				"open": 6.6446,
				"close": 6.5211,
				"volume": 1157132900,
				"time": 1210132800000,
				"lowest": 6.4479,
				"highest": 6.7214,
				"volumePrice": 6.598,
				"transactionCount": 225750,
				"voluemPrice": 6.598
			},
			{
				"open": 6.5625,
				"close": 6.6093,
				"volume": 899084144,
				"time": 1210219200000,
				"lowest": 6.5382,
				"highest": 6.6607,
				"volumePrice": 6.5996,
				"transactionCount": 174704,
				"voluemPrice": 6.5996
			},
			{
				"open": 6.5414,
				"close": 6.5518,
				"volume": 673070860,
				"time": 1210305600000,
				"lowest": 6.4775,
				"highest": 6.5804,
				"volumePrice": 6.5359,
				"transactionCount": 133813,
				"voluemPrice": 6.5359
			},
			{
				"open": 6.6146,
				"close": 6.72,
				"volume": 819365960,
				"time": 1210564800000,
				"lowest": 6.5304,
				"highest": 6.7454,
				"volumePrice": 6.664,
				"transactionCount": 158365,
				"voluemPrice": 6.664
			},
			{
				"open": 6.7361,
				"close": 6.7843,
				"volume": 823235868,
				"time": 1210651200000,
				"lowest": 6.7093,
				"highest": 6.8375,
				"volumePrice": 6.7848,
				"transactionCount": 165913,
				"voluemPrice": 6.7848
			},
			{
				"open": 6.8311,
				"close": 6.6521,
				"volume": 916823040,
				"time": 1210737600000,
				"lowest": 6.6275,
				"highest": 6.8657,
				"volumePrice": 6.7678,
				"transactionCount": 174076,
				"voluemPrice": 6.7678
			},
			{
				"open": 6.6714,
				"close": 6.7761,
				"volume": 873207384,
				"time": 1210824000000,
				"lowest": 6.5786,
				"highest": 6.7821,
				"volumePrice": 6.6949,
				"transactionCount": 170163,
				"voluemPrice": 6.6949
			},
			{
				"open": 6.7896,
				"close": 6.7007,
				"volume": 765767436,
				"time": 1210910400000,
				"lowest": 6.6786,
				"highest": 6.7964,
				"volumePrice": 6.7307,
				"transactionCount": 145404,
				"voluemPrice": 6.7307
			},
			{
				"open": 6.7093,
				"close": 6.5571,
				"volume": 946293544,
				"time": 1211169600000,
				"lowest": 6.475,
				"highest": 6.7389,
				"volumePrice": 6.6291,
				"transactionCount": 186703,
				"voluemPrice": 6.6291
			},
			{
				"open": 6.4936,
				"close": 6.6393,
				"volume": 969849244,
				"time": 1211256000000,
				"lowest": 6.4329,
				"highest": 6.6486,
				"volumePrice": 6.5712,
				"transactionCount": 187321,
				"voluemPrice": 6.5712
			},
			{
				"open": 6.6311,
				"close": 6.3639,
				"volume": 1157656220,
				"time": 1211342400000,
				"lowest": 6.2946,
				"highest": 6.7143,
				"volumePrice": 6.4939,
				"transactionCount": 225526,
				"voluemPrice": 6.4939
			},
			{
				"open": 6.4021,
				"close": 6.3232,
				"volume": 1206962932,
				"time": 1211428800000,
				"lowest": 6.1429,
				"highest": 6.4761,
				"volumePrice": 6.3348,
				"transactionCount": 231438,
				"voluemPrice": 6.3348
			},
			{
				"open": 6.4561,
				"close": 6.4704,
				"volume": 908326832,
				"time": 1211515200000,
				"lowest": 6.35,
				"highest": 6.4996,
				"volumePrice": 6.4475,
				"transactionCount": 172984,
				"voluemPrice": 6.4475
			},
			{
				"open": 6.5268,
				"close": 6.6582,
				"volume": 789904388,
				"time": 1211860800000,
				"lowest": 6.4943,
				"highest": 6.6582,
				"volumePrice": 6.5757,
				"transactionCount": 147546,
				"voluemPrice": 6.5757
			},
			{
				"open": 6.6932,
				"close": 6.6789,
				"volume": 743977948,
				"time": 1211947200000,
				"lowest": 6.5614,
				"highest": 6.7125,
				"volumePrice": 6.6407,
				"transactionCount": 146332,
				"voluemPrice": 6.6407
			},
			{
				"open": 6.67,
				"close": 6.6675,
				"volume": 647186372,
				"time": 1212033600000,
				"lowest": 6.625,
				"highest": 6.7214,
				"volumePrice": 6.6774,
				"transactionCount": 123776,
				"voluemPrice": 6.6774
			},
			{
				"open": 6.6946,
				"close": 6.7411,
				"volume": 610182300,
				"time": 1212120000000,
				"lowest": 6.6921,
				"highest": 6.7693,
				"volumePrice": 6.7383,
				"transactionCount": 112393,
				"voluemPrice": 6.7383
			},
			{
				"open": 6.7357,
				"close": 6.6464,
				"volume": 679816900,
				"time": 1212379200000,
				"lowest": 6.5904,
				"highest": 6.7732,
				"volumePrice": 6.6533,
				"transactionCount": 136745,
				"voluemPrice": 6.6533
			},
			{
				"open": 6.6736,
				"close": 6.6204,
				"volume": 757387876,
				"time": 1212465600000,
				"lowest": 6.512,
				"highest": 6.7214,
				"volumePrice": 6.6392,
				"transactionCount": 145053,
				"voluemPrice": 6.6392
			},
			{
				"open": 6.5721,
				"close": 6.6139,
				"volume": 727467104,
				"time": 1212552000000,
				"lowest": 6.5439,
				"highest": 6.6818,
				"volumePrice": 6.6151,
				"transactionCount": 143501,
				"voluemPrice": 6.6151
			},
			{
				"open": 6.655,
				"close": 6.7654,
				"volume": 755438656,
				"time": 1212638400000,
				"lowest": 6.6321,
				"highest": 6.78,
				"volumePrice": 6.7209,
				"transactionCount": 142761,
				"voluemPrice": 6.7209
			},
			{
				"open": 6.7143,
				"close": 6.63,
				"volume": 965904100,
				"time": 1212724800000,
				"lowest": 6.6268,
				"highest": 6.7839,
				"volumePrice": 6.7014,
				"transactionCount": 189742,
				"voluemPrice": 6.7014
			},
			{
				"open": 6.5996,
				"close": 6.4861,
				"volume": 1888385380,
				"time": 1212984000000,
				"lowest": 6.2768,
				"highest": 6.605,
				"volumePrice": 6.461,
				"transactionCount": 353580,
				"voluemPrice": 6.461
			},
			{
				"open": 6.4468,
				"close": 6.63,
				"volume": 1140939660,
				"time": 1213070400000,
				"lowest": 6.3936,
				"highest": 6.6707,
				"volumePrice": 6.5942,
				"transactionCount": 204724,
				"voluemPrice": 6.5942
			},
			{
				"open": 6.5836,
				"close": 6.4575,
				"volume": 961067996,
				"time": 1213156800000,
				"lowest": 6.4139,
				"highest": 6.6429,
				"volumePrice": 6.508,
				"transactionCount": 182383,
				"voluemPrice": 6.508
			},
			{
				"open": 6.4818,
				"close": 6.1879,
				"volume": 1307771220,
				"time": 1213243200000,
				"lowest": 6.1143,
				"highest": 6.5214,
				"volumePrice": 6.2799,
				"transactionCount": 227108,
				"voluemPrice": 6.2799
			},
			{
				"open": 6.13,
				"close": 6.1561,
				"volume": 1345381212,
				"time": 1213329600000,
				"lowest": 5.9039,
				"highest": 6.22,
				"volumePrice": 6.0947,
				"transactionCount": 240018,
				"voluemPrice": 6.0947
			},
			{
				"open": 6.1179,
				"close": 6.3157,
				"volume": 1051729504,
				"time": 1213588800000,
				"lowest": 6.0382,
				"highest": 6.3536,
				"volumePrice": 6.2059,
				"transactionCount": 191621,
				"voluemPrice": 6.2059
			},
			{
				"open": 6.3607,
				"close": 6.4796,
				"volume": 900155732,
				"time": 1213675200000,
				"lowest": 6.3361,
				"highest": 6.4996,
				"volumePrice": 6.4415,
				"transactionCount": 165221,
				"voluemPrice": 6.4415
			},
			{
				"open": 6.4686,
				"close": 6.3839,
				"volume": 811467160,
				"time": 1213761600000,
				"lowest": 6.3339,
				"highest": 6.5071,
				"volumePrice": 6.413,
				"transactionCount": 158908,
				"voluemPrice": 6.413
			},
			{
				"open": 6.3768,
				"close": 6.4607,
				"volume": 792717604,
				"time": 1213848000000,
				"lowest": 6.3143,
				"highest": 6.5121,
				"volumePrice": 6.4314,
				"transactionCount": 154674,
				"voluemPrice": 6.4314
			},
			{
				"open": 6.4054,
				"close": 6.2596,
				"volume": 888367116,
				"time": 1213934400000,
				"lowest": 6.25,
				"highest": 6.4643,
				"volumePrice": 6.3254,
				"transactionCount": 158802,
				"voluemPrice": 6.3254
			},
			{
				"open": 6.2407,
				"close": 6.1843,
				"volume": 646016672,
				"time": 1214193600000,
				"lowest": 6.1271,
				"highest": 6.2814,
				"volumePrice": 6.2014,
				"transactionCount": 119903,
				"voluemPrice": 6.2014
			},
			{
				"open": 6.1507,
				"close": 6.1875,
				"volume": 622073844,
				"time": 1214280000000,
				"lowest": 6.1296,
				"highest": 6.2779,
				"volumePrice": 6.2036,
				"transactionCount": 124140,
				"voluemPrice": 6.2036
			},
			{
				"open": 6.2361,
				"close": 6.3354,
				"volume": 644449876,
				"time": 1214366400000,
				"lowest": 6.21,
				"highest": 6.3868,
				"volumePrice": 6.3054,
				"transactionCount": 126381,
				"voluemPrice": 6.3054
			},
			{
				"open": 6.2168,
				"close": 6.0093,
				"volume": 869608320,
				"time": 1214452800000,
				"lowest": 6.0004,
				"highest": 6.2444,
				"volumePrice": 6.0867,
				"transactionCount": 171352,
				"voluemPrice": 6.0867
			},
			{
				"open": 5.9486,
				"close": 6.0746,
				"volume": 1042174420,
				"time": 1214539200000,
				"lowest": 5.8625,
				"highest": 6.0918,
				"volumePrice": 5.9975,
				"transactionCount": 184619,
				"voluemPrice": 5.9975
			},
			{
				"open": 6.0782,
				"close": 5.98,
				"volume": 684338144,
				"time": 1214798400000,
				"lowest": 5.9507,
				"highest": 6.1429,
				"volumePrice": 6.0342,
				"transactionCount": 135495,
				"voluemPrice": 6.0342
			},
			{
				"open": 5.8618,
				"close": 6.2386,
				"volume": 1111279568,
				"time": 1214884800000,
				"lowest": 5.8571,
				"highest": 6.24,
				"volumePrice": 6.1195,
				"transactionCount": 220714,
				"voluemPrice": 6.1195
			},
			{
				"open": 6.2571,
				"close": 6.0064,
				"volume": 838648384,
				"time": 1214971200000,
				"lowest": 6.0064,
				"highest": 6.3375,
				"volumePrice": 6.1852,
				"transactionCount": 171351,
				"voluemPrice": 6.1852
			},
			{
				"open": 6.0568,
				"close": 6.0757,
				"volume": 523361748,
				"time": 1215057600000,
				"lowest": 5.9196,
				"highest": 6.1489,
				"volumePrice": 6.0601,
				"transactionCount": 107464,
				"voluemPrice": 6.0601
			},
			{
				"open": 6.1843,
				"close": 6.2557,
				"volume": 820388884,
				"time": 1215403200000,
				"lowest": 6.1393,
				"highest": 6.3261,
				"volumePrice": 6.2434,
				"transactionCount": 174400,
				"voluemPrice": 6.2434
			},
			{
				"open": 6.2643,
				"close": 6.4125,
				"volume": 888686092,
				"time": 1215489600000,
				"lowest": 6.1693,
				"highest": 6.4179,
				"volumePrice": 6.3034,
				"transactionCount": 185581,
				"voluemPrice": 6.3034
			},
			{
				"open": 6.4339,
				"close": 6.2232,
				"volume": 896097692,
				"time": 1215576000000,
				"lowest": 6.2193,
				"highest": 6.4611,
				"volumePrice": 6.3779,
				"transactionCount": 180263,
				"voluemPrice": 6.3779
			},
			{
				"open": 6.2471,
				"close": 6.3082,
				"volume": 840684516,
				"time": 1215662400000,
				"lowest": 6.1204,
				"highest": 6.3336,
				"volumePrice": 6.2582,
				"transactionCount": 168913,
				"voluemPrice": 6.2582
			},
			{
				"open": 6.2668,
				"close": 6.1636,
				"volume": 929930792,
				"time": 1215748800000,
				"lowest": 6.1071,
				"highest": 6.3254,
				"volumePrice": 6.211,
				"transactionCount": 188910,
				"voluemPrice": 6.211
			},
			{
				"open": 6.4014,
				"close": 6.21,
				"volume": 886054120,
				"time": 1216008000000,
				"lowest": 6.1814,
				"highest": 6.4036,
				"volumePrice": 6.28,
				"transactionCount": 179103,
				"voluemPrice": 6.28
			},
			{
				"open": 6.16,
				"close": 6.0586,
				"volume": 1040041856,
				"time": 1216094400000,
				"lowest": 5.9425,
				"highest": 6.205,
				"volumePrice": 6.089,
				"transactionCount": 203374,
				"voluemPrice": 6.089
			},
			{
				"open": 6.0786,
				"close": 6.1718,
				"volume": 748063876,
				"time": 1216180800000,
				"lowest": 6.0214,
				"highest": 6.1761,
				"volumePrice": 6.102,
				"transactionCount": 146775,
				"voluemPrice": 6.102
			},
			{
				"open": 6.2179,
				"close": 6.1361,
				"volume": 757477168,
				"time": 1216267200000,
				"lowest": 6.1211,
				"highest": 6.2493,
				"volumePrice": 6.1785,
				"transactionCount": 143287,
				"voluemPrice": 6.1785
			},
			{
				"open": 6.0232,
				"close": 5.8982,
				"volume": 868412188,
				"time": 1216353600000,
				"lowest": 5.8929,
				"highest": 6.0589,
				"volumePrice": 5.9688,
				"transactionCount": 157167,
				"voluemPrice": 5.9688
			},
			{
				"open": 5.9607,
				"close": 5.9389,
				"volume": 1359321460,
				"time": 1216612800000,
				"lowest": 5.7543,
				"highest": 5.9821,
				"volumePrice": 5.7987,
				"transactionCount": 232729,
				"voluemPrice": 5.7987
			},
			{
				"open": 5.3214,
				"close": 5.7864,
				"volume": 1879592092,
				"time": 1216699200000,
				"lowest": 5.2332,
				"highest": 5.8129,
				"volumePrice": 5.5121,
				"transactionCount": 338582,
				"voluemPrice": 5.5121
			},
			{
				"open": 5.8925,
				"close": 5.9379,
				"volume": 1061766104,
				"time": 1216785600000,
				"lowest": 5.77,
				"highest": 6.0132,
				"volumePrice": 5.9125,
				"transactionCount": 198735,
				"voluemPrice": 5.9125
			},
			{
				"open": 5.8707,
				"close": 5.6796,
				"volume": 839616960,
				"time": 1216872000000,
				"lowest": 5.6589,
				"highest": 5.9021,
				"volumePrice": 5.7556,
				"transactionCount": 165775,
				"voluemPrice": 5.7556
			},
			{
				"open": 5.7286,
				"close": 5.79,
				"volume": 633635016,
				"time": 1216958400000,
				"lowest": 5.6661,
				"highest": 5.8214,
				"volumePrice": 5.757,
				"transactionCount": 123435,
				"voluemPrice": 5.757
			},
			{
				"open": 5.7979,
				"close": 5.5143,
				"volume": 780954580,
				"time": 1217217600000,
				"lowest": 5.5007,
				"highest": 5.8025,
				"volumePrice": 5.6211,
				"transactionCount": 150122,
				"voluemPrice": 5.6211
			},
			{
				"open": 5.5504,
				"close": 5.61,
				"volume": 684221440,
				"time": 1217304000000,
				"lowest": 5.4875,
				"highest": 5.6946,
				"volumePrice": 5.623,
				"transactionCount": 139939,
				"voluemPrice": 5.623
			},
			{
				"open": 5.635,
				"close": 5.71,
				"volume": 725263308,
				"time": 1217390400000,
				"lowest": 5.5743,
				"highest": 5.7318,
				"volumePrice": 5.6648,
				"transactionCount": 147876,
				"voluemPrice": 5.6648
			},
			{
				"open": 5.6264,
				"close": 5.6768,
				"volume": 637518700,
				"time": 1217476800000,
				"lowest": 5.6064,
				"highest": 5.7929,
				"volumePrice": 5.7213,
				"transactionCount": 127510,
				"voluemPrice": 5.7213
			},
			{
				"open": 5.7107,
				"close": 5.595,
				"volume": 545049204,
				"time": 1217563200000,
				"lowest": 5.5625,
				"highest": 5.7139,
				"volumePrice": 5.6113,
				"transactionCount": 108392,
				"voluemPrice": 5.6113
			},
			{
				"open": 5.5929,
				"close": 5.4725,
				"volume": 592695488,
				"time": 1217822400000,
				"lowest": 5.4611,
				"highest": 5.6393,
				"volumePrice": 5.5637,
				"transactionCount": 118432,
				"voluemPrice": 5.5637
			},
			{
				"open": 5.5507,
				"close": 5.7371,
				"volume": 688635500,
				"time": 1217908800000,
				"lowest": 5.5293,
				"highest": 5.7429,
				"volumePrice": 5.6401,
				"transactionCount": 141116,
				"voluemPrice": 5.6401
			},
			{
				"open": 5.7132,
				"close": 5.8639,
				"volume": 790475224,
				"time": 1217995200000,
				"lowest": 5.6429,
				"highest": 5.9786,
				"volumePrice": 5.7909,
				"transactionCount": 156620,
				"voluemPrice": 5.7909
			},
			{
				"open": 5.8111,
				"close": 5.8418,
				"volume": 672498820,
				"time": 1218081600000,
				"lowest": 5.7679,
				"highest": 5.9339,
				"volumePrice": 5.8609,
				"transactionCount": 135073,
				"voluemPrice": 5.8609
			},
			{
				"open": 5.8521,
				"close": 6.0554,
				"volume": 714112560,
				"time": 1218168000000,
				"lowest": 5.8482,
				"highest": 6.0589,
				"volumePrice": 5.9889,
				"transactionCount": 143342,
				"voluemPrice": 5.9889
			},
			{
				"open": 6.0739,
				"close": 6.1986,
				"volume": 891301768,
				"time": 1218427200000,
				"lowest": 6.0596,
				"highest": 6.3036,
				"volumePrice": 6.1888,
				"transactionCount": 173648,
				"voluemPrice": 6.1888
			},
			{
				"open": 6.1971,
				"close": 6.3118,
				"volume": 836420648,
				"time": 1218513600000,
				"lowest": 6.1968,
				"highest": 6.4032,
				"volumePrice": 6.3339,
				"transactionCount": 163512,
				"voluemPrice": 6.3339
			},
			{
				"open": 6.3564,
				"close": 6.4036,
				"volume": 842822008,
				"time": 1218600000000,
				"lowest": 6.2821,
				"highest": 6.4286,
				"volumePrice": 6.3768,
				"transactionCount": 165634,
				"voluemPrice": 6.3768
			},
			{
				"open": 6.3689,
				"close": 6.4043,
				"volume": 711300828,
				"time": 1218686400000,
				"lowest": 6.3514,
				"highest": 6.4446,
				"volumePrice": 6.3993,
				"transactionCount": 133340,
				"voluemPrice": 6.3993
			},
			{
				"open": 6.3943,
				"close": 6.2764,
				"volume": 708314628,
				"time": 1218772800000,
				"lowest": 6.2518,
				"highest": 6.4196,
				"volumePrice": 6.335,
				"transactionCount": 126573,
				"voluemPrice": 6.335
			},
			{
				"open": 6.2704,
				"close": 6.2639,
				"volume": 552012496,
				"time": 1219032000000,
				"lowest": 6.2079,
				"highest": 6.3504,
				"volumePrice": 6.2806,
				"transactionCount": 103251,
				"voluemPrice": 6.2806
			},
			{
				"open": 6.2336,
				"close": 6.1975,
				"volume": 616202608,
				"time": 1219118400000,
				"lowest": 6.1361,
				"highest": 6.3239,
				"volumePrice": 6.2491,
				"transactionCount": 118618,
				"voluemPrice": 6.2491
			},
			{
				"open": 6.2419,
				"close": 6.28,
				"volume": 507123064,
				"time": 1219204800000,
				"lowest": 6.2004,
				"highest": 6.3193,
				"volumePrice": 6.261,
				"transactionCount": 98159,
				"voluemPrice": 6.261
			},
			{
				"open": 6.2311,
				"close": 6.2246,
				"volume": 540018220,
				"time": 1219291200000,
				"lowest": 6.1389,
				"highest": 6.2661,
				"volumePrice": 6.2068,
				"transactionCount": 106259,
				"voluemPrice": 6.2068
			},
			{
				"open": 6.2793,
				"close": 6.3139,
				"volume": 439758844,
				"time": 1219377600000,
				"lowest": 6.2704,
				"highest": 6.3393,
				"volumePrice": 6.314,
				"transactionCount": 83048,
				"voluemPrice": 6.314
			},
			{
				"open": 6.2911,
				"close": 6.1625,
				"volume": 484496600,
				"time": 1219636800000,
				"lowest": 6.1307,
				"highest": 6.2939,
				"volumePrice": 6.2,
				"transactionCount": 93157,
				"voluemPrice": 6.2
			},
			{
				"open": 6.17,
				"close": 6.2014,
				"volume": 445778144,
				"time": 1219723200000,
				"lowest": 6.1646,
				"highest": 6.2457,
				"volumePrice": 6.2062,
				"transactionCount": 82924,
				"voluemPrice": 6.2062
			},
			{
				"open": 6.1896,
				"close": 6.2382,
				"volume": 477779288,
				"time": 1219809600000,
				"lowest": 6.1497,
				"highest": 6.2771,
				"volumePrice": 6.2247,
				"transactionCount": 90569,
				"voluemPrice": 6.2247
			},
			{
				"open": 6.26,
				"close": 6.205,
				"volume": 431378584,
				"time": 1219896000000,
				"lowest": 6.1696,
				"highest": 6.2946,
				"volumePrice": 6.241,
				"transactionCount": 81460,
				"voluemPrice": 6.241
			},
			{
				"open": 6.1771,
				"close": 6.0546,
				"volume": 599348512,
				"time": 1219982400000,
				"lowest": 6.0371,
				"highest": 6.1964,
				"volumePrice": 6.0943,
				"transactionCount": 106846,
				"voluemPrice": 6.0943
			},
			{
				"open": 6.1571,
				"close": 5.9354,
				"volume": 781429404,
				"time": 1220328000000,
				"lowest": 5.8929,
				"highest": 6.1964,
				"volumePrice": 6.0388,
				"transactionCount": 152901,
				"voluemPrice": 6.0388
			},
			{
				"open": 5.9586,
				"close": 5.9629,
				"volume": 734947276,
				"time": 1220414400000,
				"lowest": 5.8571,
				"highest": 6.0243,
				"volumePrice": 5.948,
				"transactionCount": 143244,
				"voluemPrice": 5.948
			},
			{
				"open": 5.9236,
				"close": 5.7579,
				"volume": 744211496,
				"time": 1220500800000,
				"lowest": 5.7432,
				"highest": 5.9969,
				"volumePrice": 5.8523,
				"transactionCount": 148596,
				"voluemPrice": 5.8523
			},
			{
				"open": 5.6618,
				"close": 5.7207,
				"volume": 786882852,
				"time": 1220587200000,
				"lowest": 5.6304,
				"highest": 5.8,
				"volumePrice": 5.7165,
				"transactionCount": 161486,
				"voluemPrice": 5.7165
			},
			{
				"open": 5.8775,
				"close": 5.64,
				"volume": 1046231508,
				"time": 1220846400000,
				"lowest": 5.4092,
				"highest": 5.8889,
				"volumePrice": 5.6126,
				"transactionCount": 215160,
				"voluemPrice": 5.6126
			},
			{
				"open": 5.6021,
				"close": 5.4171,
				"volume": 1245021568,
				"time": 1220932800000,
				"lowest": 5.3496,
				"highest": 5.7129,
				"volumePrice": 5.5337,
				"transactionCount": 243915,
				"voluemPrice": 5.5337
			},
			{
				"open": 5.44,
				"close": 5.4146,
				"volume": 973324632,
				"time": 1221019200000,
				"lowest": 5.3143,
				"highest": 5.5354,
				"volumePrice": 5.4286,
				"transactionCount": 205353,
				"voluemPrice": 5.4286
			},
			{
				"open": 5.2911,
				"close": 5.4518,
				"volume": 971133016,
				"time": 1221105600000,
				"lowest": 5.2143,
				"highest": 5.4639,
				"volumePrice": 5.3249,
				"transactionCount": 192361,
				"voluemPrice": 5.3249
			},
			{
				"open": 5.3896,
				"close": 5.3193,
				"volume": 793295496,
				"time": 1221192000000,
				"lowest": 5.2321,
				"highest": 5.3896,
				"volumePrice": 5.3051,
				"transactionCount": 167975,
				"voluemPrice": 5.3051
			},
			{
				"open": 5.0725,
				"close": 5.0129,
				"volume": 920634036,
				"time": 1221451200000,
				"lowest": 5.0129,
				"highest": 5.2746,
				"volumePrice": 5.1392,
				"transactionCount": 189089,
				"voluemPrice": 5.1392
			},
			{
				"open": 4.7807,
				"close": 4.9957,
				"volume": 1199836176,
				"time": 1221537600000,
				"lowest": 4.7196,
				"highest": 5.0893,
				"volumePrice": 4.9142,
				"transactionCount": 236154,
				"voluemPrice": 4.9142
			},
			{
				"open": 4.9461,
				"close": 4.5654,
				"volume": 1200452960,
				"time": 1221624000000,
				"lowest": 4.5654,
				"highest": 4.9468,
				"volumePrice": 4.7335,
				"transactionCount": 232046,
				"voluemPrice": 4.7335
			},
			{
				"open": 4.6632,
				"close": 4.7889,
				"volume": 1676253236,
				"time": 1221710400000,
				"lowest": 4.31,
				"highest": 4.8368,
				"volumePrice": 4.5401,
				"transactionCount": 334771,
				"voluemPrice": 4.5401
			},
			{
				"open": 5.0929,
				"close": 5.0325,
				"volume": 1429474284,
				"time": 1221796800000,
				"lowest": 4.8929,
				"highest": 5.15,
				"volumePrice": 5.0333,
				"transactionCount": 259281,
				"voluemPrice": 5.0333
			},
			{
				"open": 4.9979,
				"close": 4.6804,
				"volume": 856699256,
				"time": 1222056000000,
				"lowest": 4.6664,
				"highest": 5.0089,
				"volumePrice": 4.8538,
				"transactionCount": 174899,
				"voluemPrice": 4.8538
			},
			{
				"open": 4.7089,
				"close": 4.53,
				"volume": 1283161460,
				"time": 1222142400000,
				"lowest": 4.5236,
				"highest": 4.85,
				"volumePrice": 4.6923,
				"transactionCount": 248322,
				"voluemPrice": 4.6923
			},
			{
				"open": 4.5454,
				"close": 4.5968,
				"volume": 1047365284,
				"time": 1222228800000,
				"lowest": 4.4696,
				"highest": 4.6768,
				"volumePrice": 4.5913,
				"transactionCount": 204566,
				"voluemPrice": 4.5913
			},
			{
				"open": 4.6357,
				"close": 4.7118,
				"volume": 1006043920,
				"time": 1222315200000,
				"lowest": 4.59,
				"highest": 4.8139,
				"volumePrice": 4.7078,
				"transactionCount": 203072,
				"voluemPrice": 4.7078
			},
			{
				"open": 4.4611,
				"close": 4.58,
				"volume": 1126449744,
				"time": 1222401600000,
				"lowest": 4.3929,
				"highest": 4.6357,
				"volumePrice": 4.5145,
				"transactionCount": 219475,
				"voluemPrice": 4.5145
			},
			{
				"open": 4.2721,
				"close": 3.7593,
				"volume": 2622026652,
				"time": 1222660800000,
				"lowest": 3.5925,
				"highest": 4.2743,
				"volumePrice": 3.899,
				"transactionCount": 511016,
				"voluemPrice": 3.899
			},
			{
				"open": 3.8639,
				"close": 4.0593,
				"volume": 1627420312,
				"time": 1222747200000,
				"lowest": 3.7964,
				"highest": 4.1071,
				"volumePrice": 3.9557,
				"transactionCount": 308969,
				"voluemPrice": 3.9557
			},
			{
				"open": 3.9971,
				"close": 3.8971,
				"volume": 1297392628,
				"time": 1222833600000,
				"lowest": 3.8354,
				"highest": 4.0129,
				"volumePrice": 3.9328,
				"transactionCount": 267853,
				"voluemPrice": 3.9328
			},
			{
				"open": 3.8575,
				"close": 3.575,
				"volume": 1610518616,
				"time": 1222920000000,
				"lowest": 3.5714,
				"highest": 3.8854,
				"volumePrice": 3.6748,
				"transactionCount": 312581,
				"voluemPrice": 3.6748
			},
			{
				"open": 3.7143,
				"close": 3.4668,
				"volume": 2299913952,
				"time": 1223006400000,
				"lowest": 3.3804,
				"highest": 3.8036,
				"volumePrice": 3.6182,
				"transactionCount": 445126,
				"voluemPrice": 3.6182
			},
			{
				"open": 3.2843,
				"close": 3.505,
				"volume": 2107829892,
				"time": 1223265600000,
				"lowest": 3.1264,
				"highest": 3.5279,
				"volumePrice": 3.2786,
				"transactionCount": 432156,
				"voluemPrice": 3.2786
			},
			{
				"open": 3.5886,
				"close": 3.1843,
				"volume": 1881767356,
				"time": 1223352000000,
				"lowest": 3.1768,
				"highest": 3.625,
				"volumePrice": 3.3566,
				"transactionCount": 393572,
				"voluemPrice": 3.3566
			},
			{
				"open": 3.0683,
				"close": 3.2068,
				"volume": 2209284280,
				"time": 1223438400000,
				"lowest": 3.06,
				"highest": 3.4404,
				"volumePrice": 3.2962,
				"transactionCount": 473327,
				"voluemPrice": 3.2962
			},
			{
				"open": 3.3339,
				"close": 3.1693,
				"volume": 1618189972,
				"time": 1223524800000,
				"lowest": 3.0929,
				"highest": 3.4214,
				"volumePrice": 3.2717,
				"transactionCount": 335400,
				"voluemPrice": 3.2717
			},
			{
				"open": 3.0607,
				"close": 3.4571,
				"volume": 2220293068,
				"time": 1223611200000,
				"lowest": 3.0357,
				"highest": 3.5714,
				"volumePrice": 3.3337,
				"transactionCount": 476280,
				"voluemPrice": 3.3337
			},
			{
				"open": 3.7386,
				"close": 3.9379,
				"volume": 1540480760,
				"time": 1223870400000,
				"lowest": 3.6079,
				"highest": 3.9475,
				"volumePrice": 3.7679,
				"transactionCount": 326754,
				"voluemPrice": 3.7679
			},
			{
				"open": 4.1521,
				"close": 3.7171,
				"volume": 1982388688,
				"time": 1223956800000,
				"lowest": 3.6836,
				"highest": 4.1571,
				"volumePrice": 3.8862,
				"transactionCount": 440592,
				"voluemPrice": 3.8862
			},
			{
				"open": 3.7086,
				"close": 3.4982,
				"volume": 1584175460,
				"time": 1224043200000,
				"lowest": 3.4961,
				"highest": 3.8214,
				"volumePrice": 3.6718,
				"transactionCount": 358603,
				"voluemPrice": 3.6718
			},
			{
				"open": 3.5632,
				"close": 3.6389,
				"volume": 1980520500,
				"time": 1224129600000,
				"lowest": 3.2764,
				"highest": 3.6939,
				"volumePrice": 3.4465,
				"transactionCount": 439280,
				"voluemPrice": 3.4465
			},
			{
				"open": 3.5571,
				"close": 3.4786,
				"volume": 1763151908,
				"time": 1224216000000,
				"lowest": 3.4646,
				"highest": 3.6443,
				"volumePrice": 3.5402,
				"transactionCount": 376705,
				"voluemPrice": 3.5402
			},
			{
				"open": 3.5636,
				"close": 3.5157,
				"volume": 1549168152,
				"time": 1224475200000,
				"lowest": 3.3443,
				"highest": 3.5725,
				"volumePrice": 3.4672,
				"transactionCount": 346628,
				"voluemPrice": 3.4672
			},
			{
				"open": 3.4625,
				"close": 3.2675,
				"volume": 2193657452,
				"time": 1224561600000,
				"lowest": 3.2557,
				"highest": 3.4964,
				"volumePrice": 3.3823,
				"transactionCount": 441313,
				"voluemPrice": 3.3823
			},
			{
				"open": 3.4775,
				"close": 3.4596,
				"volume": 2248808324,
				"time": 1224648000000,
				"lowest": 3.3189,
				"highest": 3.6161,
				"volumePrice": 3.4926,
				"transactionCount": 490212,
				"voluemPrice": 3.4926
			},
			{
				"open": 3.4468,
				"close": 3.5082,
				"volume": 1675401672,
				"time": 1224734400000,
				"lowest": 3.2821,
				"highest": 3.5446,
				"volumePrice": 3.4415,
				"transactionCount": 387992,
				"voluemPrice": 3.4415
			},
			{
				"open": 3.2261,
				"close": 3.4421,
				"volume": 1590058204,
				"time": 1224820800000,
				"lowest": 3.2182,
				"highest": 3.4964,
				"volumePrice": 3.387,
				"transactionCount": 351874,
				"voluemPrice": 3.387
			},
			{
				"open": 3.3954,
				"close": 3.2889,
				"volume": 1209388768,
				"time": 1225080000000,
				"lowest": 3.2807,
				"highest": 3.4868,
				"volumePrice": 3.4086,
				"transactionCount": 272341,
				"voluemPrice": 3.4086
			},
			{
				"open": 3.4082,
				"close": 3.5682,
				"volume": 1635709600,
				"time": 1225166400000,
				"lowest": 3.2989,
				"highest": 3.5893,
				"volumePrice": 3.4139,
				"transactionCount": 359789,
				"voluemPrice": 3.4139
			},
			{
				"open": 3.6021,
				"close": 3.7339,
				"volume": 1953271600,
				"time": 1225252800000,
				"lowest": 3.5693,
				"highest": 3.9121,
				"volumePrice": 3.7802,
				"transactionCount": 430771,
				"voluemPrice": 3.7802
			},
			{
				"open": 3.8654,
				"close": 3.9657,
				"volume": 1638087640,
				"time": 1225339200000,
				"lowest": 3.8432,
				"highest": 4.0068,
				"volumePrice": 3.9337,
				"transactionCount": 381226,
				"voluemPrice": 3.9337
			},
			{
				"open": 3.8357,
				"close": 3.8425,
				"volume": 1660171268,
				"time": 1225425600000,
				"lowest": 3.755,
				"highest": 3.9564,
				"volumePrice": 3.8558,
				"transactionCount": 369456,
				"voluemPrice": 3.8558
			},
			{
				"open": 3.7832,
				"close": 3.82,
				"volume": 1058438640,
				"time": 1225688400000,
				"lowest": 3.745,
				"highest": 3.8964,
				"volumePrice": 3.8335,
				"transactionCount": 241295,
				"voluemPrice": 3.8335
			},
			{
				"open": 3.9282,
				"close": 3.9639,
				"volume": 1399965812,
				"time": 1225774800000,
				"lowest": 3.8096,
				"highest": 3.9925,
				"volumePrice": 3.9047,
				"transactionCount": 303335,
				"voluemPrice": 3.9047
			},
			{
				"open": 3.8896,
				"close": 3.6893,
				"volume": 1257624564,
				"time": 1225861200000,
				"lowest": 3.6782,
				"highest": 3.9186,
				"volumePrice": 3.8116,
				"transactionCount": 285080,
				"voluemPrice": 3.8116
			},
			{
				"open": 3.61,
				"close": 3.5393,
				"volume": 1318115176,
				"time": 1225947600000,
				"lowest": 3.5,
				"highest": 3.6707,
				"volumePrice": 3.5609,
				"transactionCount": 304096,
				"voluemPrice": 3.5609
			},
			{
				"open": 3.5443,
				"close": 3.5086,
				"volume": 1095636612,
				"time": 1226034000000,
				"lowest": 3.4186,
				"highest": 3.5661,
				"volumePrice": 3.4997,
				"transactionCount": 243750,
				"voluemPrice": 3.4997
			},
			{
				"open": 3.5775,
				"close": 3.4243,
				"volume": 1125885992,
				"time": 1226293200000,
				"lowest": 3.375,
				"highest": 3.5857,
				"volumePrice": 3.4541,
				"transactionCount": 245862,
				"voluemPrice": 3.4541
			},
			{
				"open": 3.3861,
				"close": 3.3846,
				"volume": 1224996388,
				"time": 1226379600000,
				"lowest": 3.295,
				"highest": 3.4704,
				"volumePrice": 3.3601,
				"transactionCount": 273515,
				"voluemPrice": 3.3601
			},
			{
				"open": 3.3011,
				"close": 3.2186,
				"volume": 1178928268,
				"time": 1226466000000,
				"lowest": 3.2146,
				"highest": 3.33,
				"volumePrice": 3.2677,
				"transactionCount": 274354,
				"voluemPrice": 3.2677
			},
			{
				"open": 3.2096,
				"close": 3.4443,
				"volume": 1855361704,
				"time": 1226552400000,
				"lowest": 3.0721,
				"highest": 3.4443,
				"volumePrice": 3.2157,
				"transactionCount": 394533,
				"voluemPrice": 3.2157
			},
			{
				"open": 3.3486,
				"close": 3.2229,
				"volume": 1405278840,
				"time": 1226638800000,
				"lowest": 3.2143,
				"highest": 3.3568,
				"volumePrice": 3.2824,
				"transactionCount": 309108,
				"voluemPrice": 3.2824
			},
			{
				"open": 3.16,
				"close": 3.1479,
				"volume": 1162923916,
				"time": 1226898000000,
				"lowest": 3.1164,
				"highest": 3.2339,
				"volumePrice": 3.1756,
				"transactionCount": 258156,
				"voluemPrice": 3.1756
			},
			{
				"open": 3.2014,
				"close": 3.2111,
				"volume": 1210354096,
				"time": 1226984400000,
				"lowest": 3.1021,
				"highest": 3.2496,
				"volumePrice": 3.1796,
				"transactionCount": 264734,
				"voluemPrice": 3.1796
			},
			{
				"open": 3.1943,
				"close": 3.0818,
				"volume": 1171847628,
				"time": 1227070800000,
				"lowest": 3.0789,
				"highest": 3.2707,
				"volumePrice": 3.1816,
				"transactionCount": 273639,
				"voluemPrice": 3.1816
			},
			{
				"open": 3.0443,
				"close": 2.8746,
				"volume": 1716739836,
				"time": 1227157200000,
				"lowest": 2.8571,
				"highest": 3.0875,
				"volumePrice": 2.9855,
				"transactionCount": 388248,
				"voluemPrice": 2.9855
			},
			{
				"open": 2.9261,
				"close": 2.9493,
				"volume": 1569326192,
				"time": 1227243600000,
				"lowest": 2.8264,
				"highest": 3.0043,
				"volumePrice": 2.9192,
				"transactionCount": 345375,
				"voluemPrice": 2.9192
			},
			{
				"open": 3.0432,
				"close": 3.3196,
				"volume": 1442692552,
				"time": 1227502800000,
				"lowest": 3.03,
				"highest": 3.3854,
				"volumePrice": 3.2131,
				"transactionCount": 298251,
				"voluemPrice": 3.2131
			},
			{
				"open": 3.3796,
				"close": 3.2429,
				"volume": 1235556840,
				"time": 1227589200000,
				"lowest": 3.1486,
				"highest": 3.3825,
				"volumePrice": 3.2387,
				"transactionCount": 267281,
				"voluemPrice": 3.2387
			},
			{
				"open": 3.2114,
				"close": 3.3929,
				"volume": 900502428,
				"time": 1227675600000,
				"lowest": 3.2089,
				"highest": 3.4018,
				"volumePrice": 3.3493,
				"transactionCount": 184215,
				"voluemPrice": 3.3493
			},
			{
				"open": 3.3821,
				"close": 3.3096,
				"volume": 301196756,
				"time": 1227848400000,
				"lowest": 3.2807,
				"highest": 3.3843,
				"volumePrice": 3.313,
				"transactionCount": 54361,
				"voluemPrice": 3.313
			},
			{
				"open": 3.2607,
				"close": 3.1761,
				"volume": 924162092,
				"time": 1228107600000,
				"lowest": 3.1758,
				"highest": 3.2954,
				"volumePrice": 3.2262,
				"transactionCount": 195334,
				"voluemPrice": 3.2262
			},
			{
				"open": 3.2154,
				"close": 3.3025,
				"volume": 1149416436,
				"time": 1228194000000,
				"lowest": 3.0893,
				"highest": 3.3089,
				"volumePrice": 3.2195,
				"transactionCount": 233809,
				"voluemPrice": 3.2195
			},
			{
				"open": 3.1929,
				"close": 3.425,
				"volume": 1340217424,
				"time": 1228280400000,
				"lowest": 3.1714,
				"highest": 3.4368,
				"volumePrice": 3.328,
				"transactionCount": 272375,
				"voluemPrice": 3.328
			},
			{
				"open": 3.3725,
				"close": 3.2646,
				"volume": 1091685588,
				"time": 1228366800000,
				"lowest": 3.1807,
				"highest": 3.4004,
				"volumePrice": 3.3079,
				"transactionCount": 221770,
				"voluemPrice": 3.3079
			},
			{
				"open": 3.2282,
				"close": 3.3571,
				"volume": 1044403360,
				"time": 1228453200000,
				"lowest": 3.1736,
				"highest": 3.3746,
				"volumePrice": 3.2755,
				"transactionCount": 213971,
				"voluemPrice": 3.2755
			},
			{
				"open": 3.4743,
				"close": 3.5614,
				"volume": 1185692648,
				"time": 1228712400000,
				"lowest": 3.4214,
				"highest": 3.6,
				"volumePrice": 3.5301,
				"transactionCount": 245944,
				"voluemPrice": 3.5301
			},
			{
				"open": 3.5014,
				"close": 3.5736,
				"volume": 1204100828,
				"time": 1228798800000,
				"lowest": 3.4718,
				"highest": 3.7,
				"volumePrice": 3.602,
				"transactionCount": 242109,
				"voluemPrice": 3.602
			},
			{
				"open": 3.4954,
				"close": 3.5075,
				"volume": 938409836,
				"time": 1228885200000,
				"lowest": 3.4464,
				"highest": 3.5532,
				"volumePrice": 3.4895,
				"transactionCount": 185787,
				"voluemPrice": 3.4895
			},
			{
				"open": 3.4768,
				"close": 3.3929,
				"volume": 1043280196,
				"time": 1228971600000,
				"lowest": 3.3868,
				"highest": 3.6157,
				"volumePrice": 3.499,
				"transactionCount": 202318,
				"voluemPrice": 3.499
			},
			{
				"open": 3.3143,
				"close": 3.5096,
				"volume": 1042810244,
				"time": 1229058000000,
				"lowest": 3.3046,
				"highest": 3.5357,
				"volumePrice": 3.4669,
				"transactionCount": 214973,
				"voluemPrice": 3.4669
			},
			{
				"open": 3.4282,
				"close": 3.3839,
				"volume": 892051104,
				"time": 1229317200000,
				"lowest": 3.3214,
				"highest": 3.4361,
				"volumePrice": 3.3625,
				"transactionCount": 182175,
				"voluemPrice": 3.3625
			},
			{
				"open": 3.3564,
				"close": 3.4082,
				"volume": 1124473224,
				"time": 1229403600000,
				"lowest": 3.3125,
				"highest": 3.4457,
				"volumePrice": 3.3777,
				"transactionCount": 214619,
				"voluemPrice": 3.3777
			},
			{
				"open": 3.2511,
				"close": 3.1843,
				"volume": 1300088608,
				"time": 1229490000000,
				"lowest": 3.1436,
				"highest": 3.2536,
				"volumePrice": 3.1907,
				"transactionCount": 244136,
				"voluemPrice": 3.1907
			},
			{
				"open": 3.1896,
				"close": 3.1939,
				"volume": 857741892,
				"time": 1229576400000,
				"lowest": 3.1586,
				"highest": 3.2439,
				"volumePrice": 3.209,
				"transactionCount": 170226,
				"voluemPrice": 3.209
			},
			{
				"open": 3.2121,
				"close": 3.2143,
				"volume": 814963632,
				"time": 1229662800000,
				"lowest": 3.1714,
				"highest": 3.2479,
				"volumePrice": 3.2077,
				"transactionCount": 135328,
				"voluemPrice": 3.2077
			},
			{
				"open": 3.215,
				"close": 3.0621,
				"volume": 845102048,
				"time": 1229922000000,
				"lowest": 3.0246,
				"highest": 3.2154,
				"volumePrice": 3.0799,
				"transactionCount": 166092,
				"voluemPrice": 3.0799
			},
			{
				"open": 3.1025,
				"close": 3.085,
				"volume": 641676728,
				"time": 1230008400000,
				"lowest": 3.0679,
				"highest": 3.1382,
				"volumePrice": 3.0955,
				"transactionCount": 131723,
				"voluemPrice": 3.0955
			},
			{
				"open": 3.0764,
				"close": 3.0371,
				"volume": 271459832,
				"time": 1230094800000,
				"lowest": 3.0196,
				"highest": 3.0804,
				"volumePrice": 3.0366,
				"transactionCount": 54432,
				"voluemPrice": 3.0366
			},
			{
				"open": 3.0943,
				"close": 3.0646,
				"volume": 309205988,
				"time": 1230267600000,
				"lowest": 3.0443,
				"highest": 3.1221,
				"volumePrice": 3.0745,
				"transactionCount": 53943,
				"voluemPrice": 3.0745
			},
			{
				"open": 3.09,
				"close": 3.0932,
				"volume": 687882300,
				"time": 1230526800000,
				"lowest": 3.0382,
				"highest": 3.1293,
				"volumePrice": 3.083,
				"transactionCount": 112055,
				"voluemPrice": 3.083
			},
			{
				"open": 3.1221,
				"close": 3.0818,
				"volume": 968229360,
				"time": 1230613200000,
				"lowest": 3.0257,
				"highest": 3.1446,
				"volumePrice": 3.0876,
				"transactionCount": 187780,
				"voluemPrice": 3.0876
			},
			{
				"open": 3.0704,
				"close": 3.0482,
				"volume": 608041364,
				"time": 1230699600000,
				"lowest": 3.0479,
				"highest": 3.1336,
				"volumePrice": 3.0943,
				"transactionCount": 111427,
				"voluemPrice": 3.0943
			},
			{
				"open": 3.0671,
				"close": 3.2411,
				"volume": 754997880,
				"time": 1230872400000,
				"lowest": 3.0414,
				"highest": 3.2514,
				"volumePrice": 3.1758,
				"transactionCount": 144039,
				"voluemPrice": 3.1758
			},
			{
				"open": 3.3275,
				"close": 3.3779,
				"volume": 1184618512,
				"time": 1231131600000,
				"lowest": 3.3111,
				"highest": 3.435,
				"volumePrice": 3.3759,
				"transactionCount": 251771,
				"voluemPrice": 3.3759
			},
			{
				"open": 3.4268,
				"close": 3.3221,
				"volume": 1292175612,
				"time": 1231218000000,
				"lowest": 3.2996,
				"highest": 3.4704,
				"volumePrice": 3.3879,
				"transactionCount": 253627,
				"voluemPrice": 3.3879
			},
			{
				"open": 3.2789,
				"close": 3.2504,
				"volume": 756782824,
				"time": 1231304400000,
				"lowest": 3.2236,
				"highest": 3.3036,
				"volumePrice": 3.2587,
				"transactionCount": 149465,
				"voluemPrice": 3.2587
			},
			{
				"open": 3.2296,
				"close": 3.3107,
				"volume": 672212352,
				"time": 1231390800000,
				"lowest": 3.2157,
				"highest": 3.3268,
				"volumePrice": 3.2719,
				"transactionCount": 136211,
				"voluemPrice": 3.2719
			},
			{
				"open": 3.3289,
				"close": 3.235,
				"volume": 549782996,
				"time": 1231477200000,
				"lowest": 3.2193,
				"highest": 3.335,
				"volumePrice": 3.2513,
				"transactionCount": 110153,
				"voluemPrice": 3.2513
			},
			{
				"open": 3.2307,
				"close": 3.1664,
				"volume": 618084152,
				"time": 1231736400000,
				"lowest": 3.1268,
				"highest": 3.2496,
				"volumePrice": 3.1714,
				"transactionCount": 121048,
				"voluemPrice": 3.1714
			},
			{
				"open": 3.1514,
				"close": 3.1325,
				"volume": 798915124,
				"time": 1231822800000,
				"lowest": 3.0839,
				"highest": 3.205,
				"volumePrice": 3.1388,
				"transactionCount": 163687,
				"voluemPrice": 3.1388
			},
			{
				"open": 3.08,
				"close": 3.0475,
				"volume": 1071985656,
				"time": 1231909200000,
				"lowest": 3.0257,
				"highest": 3.1161,
				"volumePrice": 3.007,
				"transactionCount": 196085,
				"voluemPrice": 3.007
			},
			{
				"open": 2.8775,
				"close": 2.9779,
				"volume": 1843233560,
				"time": 1231995600000,
				"lowest": 2.8589,
				"highest": 3.0043,
				"volumePrice": 2.9242,
				"transactionCount": 347612,
				"voluemPrice": 2.9242
			},
			{
				"open": 3.0107,
				"close": 2.9404,
				"volume": 1048482036,
				"time": 1232082000000,
				"lowest": 2.8714,
				"highest": 3.0136,
				"volumePrice": 2.9144,
				"transactionCount": 208415,
				"voluemPrice": 2.9144
			},
			{
				"open": 2.9261,
				"close": 2.7929,
				"volume": 920830988,
				"time": 1232427600000,
				"lowest": 2.7929,
				"highest": 2.9286,
				"volumePrice": 2.841,
				"transactionCount": 184299,
				"voluemPrice": 2.841
			},
			{
				"open": 2.8354,
				"close": 2.9582,
				"volume": 1100050476,
				"time": 1232514000000,
				"lowest": 2.8325,
				"highest": 2.96,
				"volumePrice": 2.9736,
				"transactionCount": 204886,
				"voluemPrice": 2.9736
			},
			{
				"open": 3.1443,
				"close": 3.1557,
				"volume": 1409527308,
				"time": 1232600400000,
				"lowest": 3.065,
				"highest": 3.2143,
				"volumePrice": 3.1608,
				"transactionCount": 262058,
				"voluemPrice": 3.1608
			},
			{
				"open": 3.1007,
				"close": 3.1557,
				"volume": 763769496,
				"time": 1232686800000,
				"lowest": 3.0893,
				"highest": 3.2096,
				"volumePrice": 3.1524,
				"transactionCount": 161456,
				"voluemPrice": 3.1524
			},
			{
				"open": 3.1736,
				"close": 3.2014,
				"volume": 699415416,
				"time": 1232946000000,
				"lowest": 3.1536,
				"highest": 3.2489,
				"volumePrice": 3.2091,
				"transactionCount": 143719,
				"voluemPrice": 3.2091
			},
			{
				"open": 3.2211,
				"close": 3.2404,
				"volume": 616487452,
				"time": 1233032400000,
				"lowest": 3.205,
				"highest": 3.2696,
				"volumePrice": 3.2322,
				"transactionCount": 122089,
				"voluemPrice": 3.2322
			},
			{
				"open": 3.29,
				"close": 3.3643,
				"volume": 878807524,
				"time": 1233118800000,
				"lowest": 3.2679,
				"highest": 3.3929,
				"volumePrice": 3.3418,
				"transactionCount": 171225,
				"voluemPrice": 3.3418
			},
			{
				"open": 3.3246,
				"close": 3.3214,
				"volume": 593200020,
				"time": 1233205200000,
				"lowest": 3.3071,
				"highest": 3.3693,
				"volumePrice": 3.3325,
				"transactionCount": 126795,
				"voluemPrice": 3.3325
			},
			{
				"open": 3.3071,
				"close": 3.2189,
				"volume": 651779548,
				"time": 1233291600000,
				"lowest": 3.2146,
				"highest": 3.3436,
				"volumePrice": 3.2587,
				"transactionCount": 120409,
				"voluemPrice": 3.2587
			},
			{
				"open": 3.1821,
				"close": 3.2682,
				"volume": 558291608,
				"time": 1233550800000,
				"lowest": 3.175,
				"highest": 3.2857,
				"volumePrice": 3.2455,
				"transactionCount": 114884,
				"voluemPrice": 3.2455
			},
			{
				"open": 3.2829,
				"close": 3.3207,
				"volume": 599443264,
				"time": 1233637200000,
				"lowest": 3.2243,
				"highest": 3.335,
				"volumePrice": 3.2926,
				"transactionCount": 123911,
				"voluemPrice": 3.2926
			},
			{
				"open": 3.3293,
				"close": 3.3411,
				"volume": 808413032,
				"time": 1233723600000,
				"lowest": 3.325,
				"highest": 3.4375,
				"volumePrice": 3.3899,
				"transactionCount": 168722,
				"voluemPrice": 3.3899
			},
			{
				"open": 3.3132,
				"close": 3.445,
				"volume": 749414148,
				"time": 1233810000000,
				"lowest": 3.3079,
				"highest": 3.4732,
				"volumePrice": 3.4181,
				"transactionCount": 157836,
				"voluemPrice": 3.4181
			},
			{
				"open": 3.465,
				"close": 3.5614,
				"volume": 687137668,
				"time": 1233896400000,
				"lowest": 3.4643,
				"highest": 3.5714,
				"volumePrice": 3.5378,
				"transactionCount": 133316,
				"voluemPrice": 3.5378
			},
			{
				"open": 3.5714,
				"close": 3.6611,
				"volume": 714999712,
				"time": 1234155600000,
				"lowest": 3.5536,
				"highest": 3.6786,
				"volumePrice": 3.6447,
				"transactionCount": 147101,
				"voluemPrice": 3.6447
			},
			{
				"open": 3.6189,
				"close": 3.4939,
				"volume": 849318568,
				"time": 1234242000000,
				"lowest": 3.4664,
				"highest": 3.6611,
				"volumePrice": 3.5486,
				"transactionCount": 177151,
				"voluemPrice": 3.5486
			},
			{
				"open": 3.4418,
				"close": 3.4579,
				"volume": 675082772,
				"time": 1234328400000,
				"lowest": 3.4204,
				"highest": 3.5111,
				"volumePrice": 3.4699,
				"transactionCount": 147583,
				"voluemPrice": 3.4699
			},
			{
				"open": 3.4225,
				"close": 3.5454,
				"volume": 817342652,
				"time": 1234414800000,
				"lowest": 3.4225,
				"highest": 3.5625,
				"volumePrice": 3.4989,
				"transactionCount": 172507,
				"voluemPrice": 3.4989
			},
			{
				"open": 3.5354,
				"close": 3.5414,
				"volume": 609066360,
				"time": 1234501200000,
				"lowest": 3.5043,
				"highest": 3.5693,
				"volumePrice": 3.5421,
				"transactionCount": 127978,
				"voluemPrice": 3.5421
			},
			{
				"open": 3.4596,
				"close": 3.3761,
				"volume": 677166728,
				"time": 1234846800000,
				"lowest": 3.3671,
				"highest": 3.4657,
				"volumePrice": 3.401,
				"transactionCount": 149443,
				"voluemPrice": 3.401
			},
			{
				"open": 3.3946,
				"close": 3.3704,
				"volume": 683770164,
				"time": 1234933200000,
				"lowest": 3.3114,
				"highest": 3.4232,
				"volumePrice": 3.384,
				"transactionCount": 151015,
				"voluemPrice": 3.384
			},
			{
				"open": 3.3346,
				"close": 3.2371,
				"volume": 922802188,
				"time": 1235019600000,
				"lowest": 3.2182,
				"highest": 3.3661,
				"volumePrice": 3.2596,
				"transactionCount": 185140,
				"voluemPrice": 3.2596
			},
			{
				"open": 3.1929,
				"close": 3.2571,
				"volume": 750300376,
				"time": 1235106000000,
				"lowest": 3.1786,
				"highest": 3.3,
				"volumePrice": 3.233,
				"transactionCount": 169272,
				"voluemPrice": 3.233
			},
			{
				"open": 3.2732,
				"close": 3.1054,
				"volume": 786962428,
				"time": 1235365200000,
				"lowest": 3.0896,
				"highest": 3.2857,
				"volumePrice": 3.1477,
				"transactionCount": 168211,
				"voluemPrice": 3.1477
			},
			{
				"open": 3.1231,
				"close": 3.2232,
				"volume": 807092636,
				"time": 1235451600000,
				"lowest": 3.1071,
				"highest": 3.2461,
				"volumePrice": 3.1899,
				"transactionCount": 164391,
				"voluemPrice": 3.1899
			},
			{
				"open": 3.2093,
				"close": 3.2557,
				"volume": 833312004,
				"time": 1235538000000,
				"lowest": 3.1875,
				"highest": 3.3186,
				"volumePrice": 3.2571,
				"transactionCount": 165518,
				"voluemPrice": 3.2571
			},
			{
				"open": 3.2857,
				"close": 3.1854,
				"volume": 630055748,
				"time": 1235624400000,
				"lowest": 3.1771,
				"highest": 3.3186,
				"volumePrice": 3.2451,
				"transactionCount": 131952,
				"voluemPrice": 3.2451
			},
			{
				"open": 3.1404,
				"close": 3.1896,
				"volume": 706744192,
				"time": 1235710800000,
				"lowest": 3.1311,
				"highest": 3.2607,
				"volumePrice": 3.2054,
				"transactionCount": 148058,
				"voluemPrice": 3.2054
			},
			{
				"open": 3.1471,
				"close": 3.1407,
				"volume": 770971880,
				"time": 1235970000000,
				"lowest": 3.1311,
				"highest": 3.2571,
				"volumePrice": 3.1819,
				"transactionCount": 154919,
				"voluemPrice": 3.1819
			},
			{
				"open": 3.1761,
				"close": 3.1561,
				"volume": 724339672,
				"time": 1236056400000,
				"lowest": 3.1386,
				"highest": 3.2407,
				"volumePrice": 3.1821,
				"transactionCount": 145834,
				"voluemPrice": 3.1821
			},
			{
				"open": 3.2207,
				"close": 3.2561,
				"volume": 741526492,
				"time": 1236142800000,
				"lowest": 3.1946,
				"highest": 3.3132,
				"volumePrice": 3.2662,
				"transactionCount": 152416,
				"voluemPrice": 3.2662
			},
			{
				"open": 3.2307,
				"close": 3.1729,
				"volume": 706894552,
				"time": 1236229200000,
				"lowest": 3.1589,
				"highest": 3.2811,
				"volumePrice": 3.2001,
				"transactionCount": 149500,
				"voluemPrice": 3.2001
			},
			{
				"open": 3.155,
				"close": 3.0464,
				"volume": 1011146248,
				"time": 1236315600000,
				"lowest": 2.9404,
				"highest": 3.1571,
				"volumePrice": 3.0227,
				"transactionCount": 203266,
				"voluemPrice": 3.0227
			},
			{
				"open": 3.0064,
				"close": 2.9682,
				"volume": 698477416,
				"time": 1236571200000,
				"lowest": 2.9489,
				"highest": 3.1286,
				"volumePrice": 3.0187,
				"transactionCount": 136379,
				"voluemPrice": 3.0187
			},
			{
				"open": 3.0311,
				"close": 3.1653,
				"volume": 844505004,
				"time": 1236657600000,
				"lowest": 3.0129,
				"highest": 3.1846,
				"volumePrice": 3.13,
				"transactionCount": 166483,
				"voluemPrice": 3.13
			},
			{
				"open": 3.2075,
				"close": 3.31,
				"volume": 846785016,
				"time": 1236744000000,
				"lowest": 3.1993,
				"highest": 3.3596,
				"volumePrice": 3.3089,
				"transactionCount": 176627,
				"voluemPrice": 3.3089
			},
			{
				"open": 3.3179,
				"close": 3.4411,
				"volume": 768813360,
				"time": 1236830400000,
				"lowest": 3.2857,
				"highest": 3.4493,
				"volumePrice": 3.3851,
				"transactionCount": 151189,
				"voluemPrice": 3.3851
			},
			{
				"open": 3.4393,
				"close": 3.4261,
				"volume": 601280288,
				"time": 1236916800000,
				"lowest": 3.3932,
				"highest": 3.4714,
				"volumePrice": 3.4243,
				"transactionCount": 120923,
				"voluemPrice": 3.4243
			},
			{
				"open": 3.4475,
				"close": 3.4079,
				"volume": 797546960,
				"time": 1237176000000,
				"lowest": 3.3636,
				"highest": 3.4782,
				"volumePrice": 3.4172,
				"transactionCount": 143608,
				"voluemPrice": 3.4172
			},
			{
				"open": 3.4014,
				"close": 3.5593,
				"volume": 788020408,
				"time": 1237262400000,
				"lowest": 3.3954,
				"highest": 3.5604,
				"volumePrice": 3.496,
				"transactionCount": 145501,
				"voluemPrice": 3.496
			},
			{
				"open": 3.5682,
				"close": 3.6257,
				"volume": 796201420,
				"time": 1237348800000,
				"lowest": 3.5614,
				"highest": 3.6957,
				"volumePrice": 3.6224,
				"transactionCount": 146991,
				"voluemPrice": 3.6224
			},
			{
				"open": 3.6375,
				"close": 3.6293,
				"volume": 500317860,
				"time": 1237435200000,
				"lowest": 3.5804,
				"highest": 3.6857,
				"volumePrice": 3.627,
				"transactionCount": 102424,
				"voluemPrice": 3.627
			},
			{
				"open": 3.6461,
				"close": 3.6282,
				"volume": 695701692,
				"time": 1237521600000,
				"lowest": 3.5918,
				"highest": 3.6825,
				"volumePrice": 3.6374,
				"transactionCount": 120581,
				"voluemPrice": 3.6374
			},
			{
				"open": 3.6682,
				"close": 3.845,
				"volume": 666564388,
				"time": 1237780800000,
				"lowest": 3.6339,
				"highest": 3.8629,
				"volumePrice": 3.7541,
				"transactionCount": 122635,
				"voluemPrice": 3.7541
			},
			{
				"open": 3.7986,
				"close": 3.8036,
				"volume": 642472208,
				"time": 1237867200000,
				"lowest": 3.7643,
				"highest": 3.9086,
				"volumePrice": 3.8319,
				"transactionCount": 120430,
				"voluemPrice": 3.8319
			},
			{
				"open": 3.8421,
				"close": 3.8032,
				"volume": 646651544,
				"time": 1237953600000,
				"lowest": 3.7093,
				"highest": 3.87,
				"volumePrice": 3.8017,
				"transactionCount": 122575,
				"voluemPrice": 3.8017
			},
			{
				"open": 3.8511,
				"close": 3.9239,
				"volume": 616251104,
				"time": 1238040000000,
				"lowest": 3.8421,
				"highest": 3.9279,
				"volumePrice": 3.8902,
				"transactionCount": 115833,
				"voluemPrice": 3.8902
			},
			{
				"open": 3.8657,
				"close": 3.8161,
				"volume": 493042732,
				"time": 1238126400000,
				"lowest": 3.8,
				"highest": 3.8761,
				"volumePrice": 3.8311,
				"transactionCount": 95974,
				"voluemPrice": 3.8311
			},
			{
				"open": 3.7325,
				"close": 3.7318,
				"volume": 502861380,
				"time": 1238385600000,
				"lowest": 3.6646,
				"highest": 3.7504,
				"volumePrice": 3.7038,
				"transactionCount": 98718,
				"voluemPrice": 3.7038
			},
			{
				"open": 3.7661,
				"close": 3.7543,
				"volume": 570079636,
				"time": 1238472000000,
				"lowest": 3.75,
				"highest": 3.8375,
				"volumePrice": 3.783,
				"transactionCount": 105732,
				"voluemPrice": 3.783
			},
			{
				"open": 3.7175,
				"close": 3.8818,
				"volume": 589594264,
				"time": 1238558400000,
				"lowest": 3.7104,
				"highest": 3.8929,
				"volumePrice": 3.8322,
				"transactionCount": 119882,
				"voluemPrice": 3.8322
			},
			{
				"open": 3.9338,
				"close": 4.0254,
				"volume": 812365848,
				"time": 1238644800000,
				"lowest": 3.9207,
				"highest": 4.0982,
				"volumePrice": 4.0265,
				"transactionCount": 159877,
				"voluemPrice": 4.0265
			},
			{
				"open": 4.0782,
				"close": 4.1425,
				"volume": 636345052,
				"time": 1238731200000,
				"lowest": 4.0543,
				"highest": 4.1475,
				"volumePrice": 4.1168,
				"transactionCount": 125329,
				"voluemPrice": 4.1168
			},
			{
				"open": 4.105,
				"close": 4.2304,
				"volume": 658020328,
				"time": 1238990400000,
				"lowest": 4.0457,
				"highest": 4.2411,
				"volumePrice": 4.1449,
				"transactionCount": 136252,
				"voluemPrice": 4.1449
			},
			{
				"open": 4.1618,
				"close": 4.1071,
				"volume": 536785872,
				"time": 1239076800000,
				"lowest": 4.0782,
				"highest": 4.1668,
				"volumePrice": 4.1152,
				"transactionCount": 109724,
				"voluemPrice": 4.1152
			},
			{
				"open": 4.1225,
				"close": 4.1543,
				"volume": 455629468,
				"time": 1239163200000,
				"lowest": 4.0921,
				"highest": 4.1711,
				"volumePrice": 4.1318,
				"transactionCount": 89310,
				"voluemPrice": 4.1318
			},
			{
				"open": 4.2293,
				"close": 4.2704,
				"volume": 530936280,
				"time": 1239249600000,
				"lowest": 4.2129,
				"highest": 4.2857,
				"volumePrice": 4.2657,
				"transactionCount": 111394,
				"voluemPrice": 4.2657
			},
			{
				"open": 4.2861,
				"close": 4.2936,
				"volume": 386668212,
				"time": 1239595200000,
				"lowest": 4.25,
				"highest": 4.3207,
				"volumePrice": 4.2915,
				"transactionCount": 81539,
				"voluemPrice": 4.2915
			},
			{
				"open": 4.2704,
				"close": 4.2254,
				"volume": 454755700,
				"time": 1239681600000,
				"lowest": 4.1875,
				"highest": 4.2918,
				"volumePrice": 4.232,
				"transactionCount": 93186,
				"voluemPrice": 4.232
			},
			{
				"open": 4.1857,
				"close": 4.2014,
				"volume": 413027160,
				"time": 1239768000000,
				"lowest": 4.1343,
				"highest": 4.2232,
				"volumePrice": 4.1768,
				"transactionCount": 84944,
				"voluemPrice": 4.1768
			},
			{
				"open": 4.2568,
				"close": 4.3375,
				"volume": 593582248,
				"time": 1239854400000,
				"lowest": 4.2425,
				"highest": 4.3982,
				"volumePrice": 4.3242,
				"transactionCount": 114423,
				"voluemPrice": 4.3242
			},
			{
				"open": 4.3279,
				"close": 4.4079,
				"volume": 497682696,
				"time": 1239940800000,
				"lowest": 4.2946,
				"highest": 4.4375,
				"volumePrice": 4.3805,
				"transactionCount": 89613,
				"voluemPrice": 4.3805
			},
			{
				"open": 4.3475,
				"close": 4.3036,
				"volume": 466654076,
				"time": 1240200000000,
				"lowest": 4.2557,
				"highest": 4.3925,
				"volumePrice": 4.3087,
				"transactionCount": 90913,
				"voluemPrice": 4.3087
			},
			{
				"open": 4.2461,
				"close": 4.3486,
				"volume": 470684032,
				"time": 1240286400000,
				"lowest": 4.2357,
				"highest": 4.3621,
				"volumePrice": 4.3316,
				"transactionCount": 92098,
				"voluemPrice": 4.3316
			},
			{
				"open": 4.3796,
				"close": 4.3396,
				"volume": 938697088,
				"time": 1240372800000,
				"lowest": 4.3286,
				"highest": 4.4768,
				"volumePrice": 4.424,
				"transactionCount": 172131,
				"voluemPrice": 4.424
			},
			{
				"open": 4.5221,
				"close": 4.4786,
				"volume": 945114772,
				"time": 1240459200000,
				"lowest": 4.4111,
				"highest": 4.5429,
				"volumePrice": 4.4771,
				"transactionCount": 177069,
				"voluemPrice": 4.4771
			},
			{
				"open": 4.4514,
				"close": 4.425,
				"volume": 540932644,
				"time": 1240545600000,
				"lowest": 4.3918,
				"highest": 4.4693,
				"volumePrice": 4.4305,
				"transactionCount": 106399,
				"voluemPrice": 4.4305
			},
			{
				"open": 4.3893,
				"close": 4.4546,
				"volume": 480813732,
				"time": 1240804800000,
				"lowest": 4.3807,
				"highest": 4.4643,
				"volumePrice": 4.4381,
				"transactionCount": 85453,
				"voluemPrice": 4.4381
			},
			{
				"open": 4.4054,
				"close": 4.4251,
				"volume": 455856632,
				"time": 1240891200000,
				"lowest": 4.4021,
				"highest": 4.5075,
				"volumePrice": 4.4626,
				"transactionCount": 81665,
				"voluemPrice": 4.4626
			},
			{
				"open": 4.4589,
				"close": 4.4693,
				"volume": 458258220,
				"time": 1240977600000,
				"lowest": 4.4225,
				"highest": 4.5304,
				"volumePrice": 4.4769,
				"transactionCount": 88183,
				"voluemPrice": 4.4769
			},
			{
				"open": 4.5079,
				"close": 4.4939,
				"volume": 498463112,
				"time": 1241064000000,
				"lowest": 4.4614,
				"highest": 4.5357,
				"volumePrice": 4.5002,
				"transactionCount": 95204,
				"voluemPrice": 4.5002
			},
			{
				"open": 4.4929,
				"close": 4.5443,
				"volume": 397588940,
				"time": 1241150400000,
				"lowest": 4.4929,
				"highest": 4.5696,
				"volumePrice": 4.5358,
				"transactionCount": 75827,
				"voluemPrice": 4.5358
			},
			{
				"open": 4.58,
				"close": 4.7168,
				"volume": 609580776,
				"time": 1241409600000,
				"lowest": 4.56,
				"highest": 4.7232,
				"volumePrice": 4.659,
				"transactionCount": 117124,
				"voluemPrice": 4.659
			},
			{
				"open": 4.7054,
				"close": 4.7396,
				"volume": 398401248,
				"time": 1241496000000,
				"lowest": 4.6829,
				"highest": 4.745,
				"volumePrice": 4.7188,
				"transactionCount": 81279,
				"voluemPrice": 4.7188
			},
			{
				"open": 4.7618,
				"close": 4.7321,
				"volume": 473642064,
				"time": 1241582400000,
				"lowest": 4.6507,
				"highest": 4.7679,
				"volumePrice": 4.7046,
				"transactionCount": 97516,
				"voluemPrice": 4.7046
			},
			{
				"open": 4.7261,
				"close": 4.6093,
				"volume": 531895700,
				"time": 1241668800000,
				"lowest": 4.5679,
				"highest": 4.7282,
				"volumePrice": 4.6074,
				"transactionCount": 102971,
				"voluemPrice": 4.6074
			},
			{
				"open": 4.6086,
				"close": 4.6139,
				"volume": 468024256,
				"time": 1241755200000,
				"lowest": 4.5093,
				"highest": 4.6869,
				"volumePrice": 4.5965,
				"transactionCount": 90206,
				"voluemPrice": 4.5965
			},
			{
				"open": 4.5489,
				"close": 4.6275,
				"volume": 404782868,
				"time": 1242014400000,
				"lowest": 4.54,
				"highest": 4.6771,
				"volumePrice": 4.6263,
				"transactionCount": 80746,
				"voluemPrice": 4.6263
			},
			{
				"open": 4.6271,
				"close": 4.4436,
				"volume": 609479220,
				"time": 1242100800000,
				"lowest": 4.4018,
				"highest": 4.6325,
				"volumePrice": 4.4923,
				"transactionCount": 114183,
				"voluemPrice": 4.4923
			},
			{
				"open": 4.4004,
				"close": 4.2675,
				"volume": 595968884,
				"time": 1242187200000,
				"lowest": 4.2636,
				"highest": 4.4293,
				"volumePrice": 4.3235,
				"transactionCount": 111875,
				"voluemPrice": 4.3235
			},
			{
				"open": 4.2779,
				"close": 4.3911,
				"volume": 447907880,
				"time": 1242273600000,
				"lowest": 4.275,
				"highest": 4.4118,
				"volumePrice": 4.3601,
				"transactionCount": 80708,
				"voluemPrice": 4.3601
			},
			{
				"open": 4.3686,
				"close": 4.3721,
				"volume": 367550960,
				"time": 1242360000000,
				"lowest": 4.3432,
				"highest": 4.4507,
				"volumePrice": 4.3995,
				"transactionCount": 66720,
				"voluemPrice": 4.3995
			},
			{
				"open": 4.4189,
				"close": 4.5232,
				"volume": 459085424,
				"time": 1242619200000,
				"lowest": 4.3418,
				"highest": 4.525,
				"volumePrice": 4.455,
				"transactionCount": 85528,
				"voluemPrice": 4.455
			},
			{
				"open": 4.5293,
				"close": 4.5518,
				"volume": 372422036,
				"time": 1242705600000,
				"lowest": 4.4907,
				"highest": 4.6182,
				"volumePrice": 4.5658,
				"transactionCount": 71282,
				"voluemPrice": 4.5658
			},
			{
				"open": 4.5582,
				"close": 4.4954,
				"volume": 388772160,
				"time": 1242792000000,
				"lowest": 4.475,
				"highest": 4.6146,
				"volumePrice": 4.544,
				"transactionCount": 76414,
				"voluemPrice": 4.544
			},
			{
				"open": 4.4696,
				"close": 4.435,
				"volume": 408181032,
				"time": 1242878400000,
				"lowest": 4.3889,
				"highest": 4.5279,
				"volumePrice": 4.4392,
				"transactionCount": 76878,
				"voluemPrice": 4.4392
			},
			{
				"open": 4.4304,
				"close": 4.375,
				"volume": 298068820,
				"time": 1242964800000,
				"lowest": 4.3482,
				"highest": 4.435,
				"volumePrice": 4.3896,
				"transactionCount": 53852,
				"voluemPrice": 4.3896
			},
			{
				"open": 4.4557,
				"close": 4.6707,
				"volume": 636925716,
				"time": 1243310400000,
				"lowest": 4.4482,
				"highest": 4.6725,
				"volumePrice": 4.6119,
				"transactionCount": 123998,
				"voluemPrice": 4.6119
			},
			{
				"open": 4.7064,
				"close": 4.7518,
				"volume": 646962680,
				"time": 1243396800000,
				"lowest": 4.6754,
				"highest": 4.8207,
				"volumePrice": 4.7644,
				"transactionCount": 125673,
				"voluemPrice": 4.7644
			},
			{
				"open": 4.7661,
				"close": 4.8239,
				"volume": 487550112,
				"time": 1243483200000,
				"lowest": 4.7154,
				"highest": 4.8354,
				"volumePrice": 4.7857,
				"transactionCount": 97141,
				"voluemPrice": 4.7857
			},
			{
				"open": 4.8354,
				"close": 4.8504,
				"volume": 456654408,
				"time": 1243569600000,
				"lowest": 4.7804,
				"highest": 4.8536,
				"volumePrice": 4.8181,
				"transactionCount": 79410,
				"voluemPrice": 4.8181
			},
			{
				"open": 4.8739,
				"close": 4.9768,
				"volume": 452667936,
				"time": 1243828800000,
				"lowest": 4.8571,
				"highest": 4.9996,
				"volumePrice": 4.9578,
				"transactionCount": 88876,
				"voluemPrice": 4.9578
			},
			{
				"open": 4.9639,
				"close": 4.9818,
				"volume": 456222928,
				"time": 1243915200000,
				"lowest": 4.9411,
				"highest": 5.0479,
				"volumePrice": 4.991,
				"transactionCount": 89143,
				"voluemPrice": 4.991
			},
			{
				"open": 5,
				"close": 5.0339,
				"volume": 565198368,
				"time": 1244001600000,
				"lowest": 4.9668,
				"highest": 5.0396,
				"volumePrice": 5.0098,
				"transactionCount": 105890,
				"voluemPrice": 5.0098
			},
			{
				"open": 5.0046,
				"close": 5.1336,
				"volume": 550631956,
				"time": 1244088000000,
				"lowest": 5.0014,
				"highest": 5.1493,
				"volumePrice": 5.1103,
				"transactionCount": 97979,
				"voluemPrice": 5.1103
			},
			{
				"open": 5.1896,
				"close": 5.1668,
				"volume": 632393496,
				"time": 1244174400000,
				"lowest": 5.1146,
				"highest": 5.2286,
				"volumePrice": 5.1647,
				"transactionCount": 114362,
				"voluemPrice": 5.1647
			},
			{
				"open": 5.1364,
				"close": 5.1375,
				"volume": 931650692,
				"time": 1244433600000,
				"lowest": 4.9796,
				"highest": 5.1511,
				"volumePrice": 5.0617,
				"transactionCount": 181690,
				"voluemPrice": 5.0617
			},
			{
				"open": 5.1361,
				"close": 5.0971,
				"volume": 676963504,
				"time": 1244520000000,
				"lowest": 5.0196,
				"highest": 5.1629,
				"volumePrice": 5.095,
				"transactionCount": 124796,
				"voluemPrice": 5.095
			},
			{
				"open": 5.0814,
				"close": 5.0089,
				"volume": 688798908,
				"time": 1244606400000,
				"lowest": 4.9393,
				"highest": 5.0839,
				"volumePrice": 4.9827,
				"transactionCount": 123739,
				"voluemPrice": 4.9827
			},
			{
				"open": 4.9839,
				"close": 4.9982,
				"volume": 524823404,
				"time": 1244692800000,
				"lowest": 4.9482,
				"highest": 5.0557,
				"volumePrice": 5.0153,
				"transactionCount": 96599,
				"voluemPrice": 5.0153
			},
			{
				"open": 4.9575,
				"close": 4.8918,
				"volume": 563084928,
				"time": 1244779200000,
				"lowest": 4.8586,
				"highest": 4.9679,
				"volumePrice": 4.8891,
				"transactionCount": 99047,
				"voluemPrice": 4.8891
			},
			{
				"open": 4.8575,
				"close": 4.8604,
				"volume": 539948444,
				"time": 1245038400000,
				"lowest": 4.8175,
				"highest": 4.8904,
				"volumePrice": 4.8478,
				"transactionCount": 107885,
				"voluemPrice": 4.8478
			},
			{
				"open": 4.8807,
				"close": 4.8696,
				"volume": 514784592,
				"time": 1245124800000,
				"lowest": 4.8607,
				"highest": 4.9454,
				"volumePrice": 4.9063,
				"transactionCount": 97586,
				"voluemPrice": 4.9063
			},
			{
				"open": 4.8811,
				"close": 4.8421,
				"volume": 571412688,
				"time": 1245211200000,
				"lowest": 4.8046,
				"highest": 4.9089,
				"volumePrice": 4.8556,
				"transactionCount": 110492,
				"voluemPrice": 4.8556
			},
			{
				"open": 4.8611,
				"close": 4.8529,
				"volume": 427680092,
				"time": 1245297600000,
				"lowest": 4.8425,
				"highest": 4.9286,
				"volumePrice": 4.8907,
				"transactionCount": 78989,
				"voluemPrice": 4.8907
			},
			{
				"open": 4.9311,
				"close": 4.9814,
				"volume": 721854504,
				"time": 1245384000000,
				"lowest": 4.8893,
				"highest": 4.9821,
				"volumePrice": 4.9406,
				"transactionCount": 107740,
				"voluemPrice": 4.9406
			},
			{
				"open": 5.0239,
				"close": 4.9061,
				"volume": 634912348,
				"time": 1245643200000,
				"lowest": 4.8689,
				"highest": 5.0557,
				"volumePrice": 4.9327,
				"transactionCount": 114786,
				"voluemPrice": 4.9327
			},
			{
				"open": 4.8714,
				"close": 4.7861,
				"volume": 706529880,
				"time": 1245729600000,
				"lowest": 4.7457,
				"highest": 4.8911,
				"volumePrice": 4.7883,
				"transactionCount": 125666,
				"voluemPrice": 4.7883
			},
			{
				"open": 4.8364,
				"close": 4.865,
				"volume": 485524732,
				"time": 1245816000000,
				"lowest": 4.8164,
				"highest": 4.9107,
				"volumePrice": 4.8723,
				"transactionCount": 84916,
				"voluemPrice": 4.8723
			},
			{
				"open": 4.8482,
				"close": 4.995,
				"volume": 589688764,
				"time": 1245902400000,
				"lowest": 4.8289,
				"highest": 5.0071,
				"volumePrice": 4.9608,
				"transactionCount": 109962,
				"voluemPrice": 4.9608
			},
			{
				"open": 4.9925,
				"close": 5.0871,
				"volume": 439471928,
				"time": 1245988800000,
				"lowest": 4.9907,
				"highest": 5.1271,
				"volumePrice": 5.0674,
				"transactionCount": 73481,
				"voluemPrice": 5.0674
			},
			{
				"open": 5.1236,
				"close": 5.0704,
				"volume": 567645176,
				"time": 1246248000000,
				"lowest": 5.055,
				"highest": 5.1411,
				"volumePrice": 5.0946,
				"transactionCount": 92320,
				"voluemPrice": 5.0946
			},
			{
				"open": 5.0921,
				"close": 5.0868,
				"volume": 434003360,
				"time": 1246334400000,
				"lowest": 5.0643,
				"highest": 5.1357,
				"volumePrice": 5.0902,
				"transactionCount": 77935,
				"voluemPrice": 5.0902
			},
			{
				"open": 5.125,
				"close": 5.1011,
				"volume": 414272600,
				"time": 1246420800000,
				"lowest": 5.09,
				"highest": 5.1664,
				"volumePrice": 5.1298,
				"transactionCount": 78012,
				"voluemPrice": 5.1298
			},
			{
				"open": 5.0446,
				"close": 5.0007,
				"volume": 370615392,
				"time": 1246507200000,
				"lowest": 4.9925,
				"highest": 5.1011,
				"volumePrice": 5.0127,
				"transactionCount": 69626,
				"voluemPrice": 5.0127
			},
			{
				"open": 4.9536,
				"close": 4.9504,
				"volume": 498686132,
				"time": 1246852800000,
				"lowest": 4.8661,
				"highest": 4.9639,
				"volumePrice": 4.9081,
				"transactionCount": 96694,
				"voluemPrice": 4.9081
			},
			{
				"open": 4.9457,
				"close": 4.8357,
				"volume": 461780368,
				"time": 1246939200000,
				"lowest": 4.8279,
				"highest": 4.9886,
				"volumePrice": 4.8857,
				"transactionCount": 89045,
				"voluemPrice": 4.8857
			},
			{
				"open": 4.8543,
				"close": 4.9007,
				"volume": 575928192,
				"time": 1247025600000,
				"lowest": 4.8007,
				"highest": 4.93,
				"volumePrice": 4.8611,
				"transactionCount": 108759,
				"voluemPrice": 4.8611
			},
			{
				"open": 4.92,
				"close": 4.87,
				"volume": 343142940,
				"time": 1247112000000,
				"lowest": 4.8546,
				"highest": 4.9282,
				"volumePrice": 4.8891,
				"transactionCount": 67376,
				"voluemPrice": 4.8891
			},
			{
				"open": 4.8693,
				"close": 4.9471,
				"volume": 445273024,
				"time": 1247198400000,
				"lowest": 4.8686,
				"highest": 4.9632,
				"volumePrice": 4.9361,
				"transactionCount": 80318,
				"voluemPrice": 4.9361
			},
			{
				"open": 4.9836,
				"close": 5.0836,
				"volume": 483773976,
				"time": 1247457600000,
				"lowest": 4.9118,
				"highest": 5.0836,
				"volumePrice": 5.0214,
				"transactionCount": 90687,
				"voluemPrice": 5.0214
			},
			{
				"open": 5.0725,
				"close": 5.0811,
				"volume": 347501672,
				"time": 1247544000000,
				"lowest": 5.0415,
				"highest": 5.1136,
				"volumePrice": 5.0768,
				"transactionCount": 64450,
				"voluemPrice": 5.0768
			},
			{
				"open": 5.18,
				"close": 5.2457,
				"volume": 485584456,
				"time": 1247630400000,
				"lowest": 5.1543,
				"highest": 5.25,
				"volumePrice": 5.2138,
				"transactionCount": 89524,
				"voluemPrice": 5.2138
			},
			{
				"open": 5.2057,
				"close": 5.2686,
				"volume": 393568644,
				"time": 1247716800000,
				"lowest": 5.1989,
				"highest": 5.2864,
				"volumePrice": 5.2553,
				"transactionCount": 72134,
				"voluemPrice": 5.2553
			},
			{
				"open": 5.3243,
				"close": 5.4196,
				"volume": 599454604,
				"time": 1247803200000,
				"lowest": 5.3082,
				"highest": 5.4293,
				"volumePrice": 5.374,
				"transactionCount": 98791,
				"voluemPrice": 5.374
			},
			{
				"open": 5.4739,
				"close": 5.461,
				"volume": 735314748,
				"time": 1248062400000,
				"lowest": 5.3889,
				"highest": 5.537,
				"volumePrice": 5.471,
				"transactionCount": 132414,
				"voluemPrice": 5.471
			},
			{
				"open": 5.4746,
				"close": 5.4111,
				"volume": 874781236,
				"time": 1248148800000,
				"lowest": 5.3482,
				"highest": 5.4796,
				"volumePrice": 5.4393,
				"transactionCount": 164117,
				"voluemPrice": 5.4393
			},
			{
				"open": 5.6354,
				"close": 5.5979,
				"volume": 874103328,
				"time": 1248235200000,
				"lowest": 5.5754,
				"highest": 5.6689,
				"volumePrice": 5.6226,
				"transactionCount": 169881,
				"voluemPrice": 5.6226
			},
			{
				"open": 5.5939,
				"close": 5.6364,
				"volume": 526961512,
				"time": 1248321600000,
				"lowest": 5.5557,
				"highest": 5.6586,
				"volumePrice": 5.6269,
				"transactionCount": 105244,
				"voluemPrice": 5.6269
			},
			{
				"open": 5.6054,
				"close": 5.7138,
				"volume": 438359656,
				"time": 1248408000000,
				"lowest": 5.5893,
				"highest": 5.7143,
				"volumePrice": 5.6769,
				"transactionCount": 79943,
				"voluemPrice": 5.6769
			},
			{
				"open": 5.7204,
				"close": 5.7179,
				"volume": 433311228,
				"time": 1248667200000,
				"lowest": 5.6164,
				"highest": 5.7457,
				"volumePrice": 5.6885,
				"transactionCount": 80530,
				"voluemPrice": 5.6885
			},
			{
				"open": 5.6743,
				"close": 5.7143,
				"volume": 363552532,
				"time": 1248753600000,
				"lowest": 5.6286,
				"highest": 5.718,
				"volumePrice": 5.6751,
				"transactionCount": 73410,
				"voluemPrice": 5.6751
			},
			{
				"open": 5.675,
				"close": 5.7154,
				"volume": 382428200,
				"time": 1248840000000,
				"lowest": 5.6518,
				"highest": 5.7304,
				"volumePrice": 5.6965,
				"transactionCount": 75587,
				"voluemPrice": 5.6965
			},
			{
				"open": 5.775,
				"close": 5.8139,
				"volume": 469603232,
				"time": 1248926400000,
				"lowest": 5.7679,
				"highest": 5.8829,
				"volumePrice": 5.843,
				"transactionCount": 88496,
				"voluemPrice": 5.843
			},
			{
				"open": 5.821,
				"close": 5.8354,
				"volume": 422691808,
				"time": 1249012800000,
				"lowest": 5.8182,
				"highest": 5.8929,
				"volumePrice": 5.8604,
				"transactionCount": 78879,
				"voluemPrice": 5.8604
			},
			{
				"open": 5.9004,
				"close": 5.9439,
				"volume": 394239496,
				"time": 1249272000000,
				"lowest": 5.8882,
				"highest": 5.9514,
				"volumePrice": 5.9246,
				"transactionCount": 77024,
				"voluemPrice": 5.9246
			},
			{
				"open": 5.8904,
				"close": 5.9125,
				"volume": 395809484,
				"time": 1249358400000,
				"lowest": 5.8646,
				"highest": 5.9132,
				"volumePrice": 5.8917,
				"transactionCount": 80037,
				"voluemPrice": 5.8917
			},
			{
				"open": 5.9196,
				"close": 5.8968,
				"volume": 423181332,
				"time": 1249444800000,
				"lowest": 5.8646,
				"highest": 5.9782,
				"volumePrice": 5.9037,
				"transactionCount": 78631,
				"voluemPrice": 5.9037
			},
			{
				"open": 5.9136,
				"close": 5.8539,
				"volume": 341774524,
				"time": 1249531200000,
				"lowest": 5.8246,
				"highest": 5.9468,
				"volumePrice": 5.8774,
				"transactionCount": 69667,
				"voluemPrice": 5.8774
			},
			{
				"open": 5.9104,
				"close": 5.9111,
				"volume": 387483712,
				"time": 1249617600000,
				"lowest": 5.8857,
				"highest": 5.95,
				"volumePrice": 5.9245,
				"transactionCount": 77156,
				"voluemPrice": 5.9245
			},
			{
				"open": 5.9164,
				"close": 5.8829,
				"volume": 300435156,
				"time": 1249876800000,
				"lowest": 5.845,
				"highest": 5.95,
				"volumePrice": 5.893,
				"transactionCount": 63506,
				"voluemPrice": 5.893
			},
			{
				"open": 5.8461,
				"close": 5.8154,
				"volume": 355488616,
				"time": 1249963200000,
				"lowest": 5.7814,
				"highest": 5.8707,
				"volumePrice": 5.8222,
				"transactionCount": 71687,
				"voluemPrice": 5.8222
			},
			{
				"open": 5.8054,
				"close": 5.9039,
				"volume": 445070556,
				"time": 1250049600000,
				"lowest": 5.8021,
				"highest": 5.9539,
				"volumePrice": 5.9098,
				"transactionCount": 88083,
				"voluemPrice": 5.9098
			},
			{
				"open": 5.9518,
				"close": 6.015,
				"volume": 440162380,
				"time": 1250136000000,
				"lowest": 5.9464,
				"highest": 6.0239,
				"volumePrice": 5.9898,
				"transactionCount": 83128,
				"voluemPrice": 5.9898
			},
			{
				"open": 5.9979,
				"close": 5.9564,
				"volume": 306049100,
				"time": 1250222400000,
				"lowest": 5.9118,
				"highest": 6.0081,
				"volumePrice": 5.9406,
				"transactionCount": 62597,
				"voluemPrice": 5.9406
			},
			{
				"open": 5.8411,
				"close": 5.6996,
				"volume": 524381004,
				"time": 1250481600000,
				"lowest": 5.6936,
				"highest": 5.8425,
				"volumePrice": 5.7603,
				"transactionCount": 97955,
				"voluemPrice": 5.7603
			},
			{
				"open": 5.7725,
				"close": 5.8571,
				"volume": 431134788,
				"time": 1250568000000,
				"lowest": 5.7646,
				"highest": 5.8657,
				"volumePrice": 5.8232,
				"transactionCount": 84846,
				"voluemPrice": 5.8232
			},
			{
				"open": 5.8125,
				"close": 5.8786,
				"volume": 413271460,
				"time": 1250654400000,
				"lowest": 5.8018,
				"highest": 5.9036,
				"volumePrice": 5.8635,
				"transactionCount": 81956,
				"voluemPrice": 5.8635
			},
			{
				"open": 5.8921,
				"close": 5.9404,
				"volume": 342198920,
				"time": 1250740800000,
				"lowest": 5.8789,
				"highest": 5.9543,
				"volumePrice": 5.9317,
				"transactionCount": 71738,
				"voluemPrice": 5.9317
			},
			{
				"open": 5.9875,
				"close": 6.0436,
				"volume": 416020276,
				"time": 1250827200000,
				"lowest": 5.9571,
				"highest": 6.0489,
				"volumePrice": 6.0138,
				"transactionCount": 79279,
				"voluemPrice": 6.0138
			},
			{
				"open": 6.0757,
				"close": 6.0379,
				"volume": 406928704,
				"time": 1251086400000,
				"lowest": 6.0096,
				"highest": 6.0968,
				"volumePrice": 6.056,
				"transactionCount": 77970,
				"voluemPrice": 6.056
			},
			{
				"open": 6.0521,
				"close": 6.05,
				"volume": 324353372,
				"time": 1251172800000,
				"lowest": 6.0404,
				"highest": 6.105,
				"volumePrice": 6.0681,
				"transactionCount": 66509,
				"voluemPrice": 6.0681
			},
			{
				"open": 6.0329,
				"close": 5.9789,
				"volume": 303997876,
				"time": 1251259200000,
				"lowest": 5.9557,
				"highest": 6.0554,
				"volumePrice": 5.9973,
				"transactionCount": 61504,
				"voluemPrice": 5.9973
			},
			{
				"open": 6.0268,
				"close": 6.0518,
				"volume": 449179304,
				"time": 1251345600000,
				"lowest": 5.8868,
				"highest": 6.0561,
				"volumePrice": 5.9798,
				"transactionCount": 81734,
				"voluemPrice": 5.9798
			},
			{
				"open": 6.1525,
				"close": 6.0732,
				"volume": 453857712,
				"time": 1251432000000,
				"lowest": 6.0189,
				"highest": 6.1604,
				"volumePrice": 6.081,
				"transactionCount": 79084,
				"voluemPrice": 6.081
			},
			{
				"open": 6.0057,
				"close": 6.0075,
				"volume": 311540348,
				"time": 1251691200000,
				"lowest": 5.9464,
				"highest": 6.0304,
				"volumePrice": 5.9998,
				"transactionCount": 56812,
				"voluemPrice": 5.9998
			},
			{
				"open": 5.9996,
				"close": 5.9035,
				"volume": 469022540,
				"time": 1251777600000,
				"lowest": 5.8907,
				"highest": 6.0714,
				"volumePrice": 5.9671,
				"transactionCount": 90546,
				"voluemPrice": 5.9671
			},
			{
				"open": 5.8793,
				"close": 5.8993,
				"volume": 364400428,
				"time": 1251864000000,
				"lowest": 5.8611,
				"highest": 5.9861,
				"volumePrice": 5.9353,
				"transactionCount": 67882,
				"voluemPrice": 5.9353
			},
			{
				"open": 5.9443,
				"close": 5.9482,
				"volume": 294102844,
				"time": 1251950400000,
				"lowest": 5.8929,
				"highest": 5.9679,
				"volumePrice": 5.9376,
				"transactionCount": 57146,
				"voluemPrice": 5.9376
			},
			{
				"open": 5.9741,
				"close": 6.0825,
				"volume": 374626616,
				"time": 1252036800000,
				"lowest": 5.9675,
				"highest": 6.0964,
				"volumePrice": 6.0524,
				"transactionCount": 70863,
				"voluemPrice": 6.0524
			},
			{
				"open": 6.1779,
				"close": 6.1761,
				"volume": 315046508,
				"time": 1252382400000,
				"lowest": 6.1429,
				"highest": 6.1836,
				"volumePrice": 6.1632,
				"transactionCount": 61018,
				"voluemPrice": 6.1632
			},
			{
				"open": 6.1707,
				"close": 6.1121,
				"volume": 811291544,
				"time": 1252468800000,
				"lowest": 6.0607,
				"highest": 6.2311,
				"volumePrice": 6.1495,
				"transactionCount": 158997,
				"voluemPrice": 6.1495
			},
			{
				"open": 6.145,
				"close": 6.1629,
				"volume": 491133384,
				"time": 1252555200000,
				"lowest": 6.1004,
				"highest": 6.1875,
				"volumePrice": 6.1449,
				"transactionCount": 95223,
				"voluemPrice": 6.1449
			},
			{
				"open": 6.1754,
				"close": 6.1486,
				"volume": 348712252,
				"time": 1252641600000,
				"lowest": 6.1025,
				"highest": 6.185,
				"volumePrice": 6.1401,
				"transactionCount": 65811,
				"voluemPrice": 6.1401
			},
			{
				"open": 6.1011,
				"close": 6.2043,
				"volume": 322010444,
				"time": 1252900800000,
				"lowest": 6.0804,
				"highest": 6.2107,
				"volumePrice": 6.1633,
				"transactionCount": 59836,
				"voluemPrice": 6.1633
			},
			{
				"open": 6.2157,
				"close": 6.2557,
				"volume": 426470212,
				"time": 1252987200000,
				"lowest": 6.1996,
				"highest": 6.2732,
				"volumePrice": 6.2489,
				"transactionCount": 76297,
				"voluemPrice": 6.2489
			},
			{
				"open": 6.3568,
				"close": 6.4954,
				"volume": 753993996,
				"time": 1253073600000,
				"lowest": 6.3529,
				"highest": 6.5268,
				"volumePrice": 6.4571,
				"transactionCount": 145310,
				"voluemPrice": 6.4571
			},
			{
				"open": 6.4993,
				"close": 6.5911,
				"volume": 810567856,
				"time": 1253160000000,
				"lowest": 6.4989,
				"highest": 6.6711,
				"volumePrice": 6.5998,
				"transactionCount": 158494,
				"voluemPrice": 6.5998
			},
			{
				"open": 6.6368,
				"close": 6.6079,
				"volume": 601747440,
				"time": 1253246400000,
				"lowest": 6.5986,
				"highest": 6.6625,
				"volumePrice": 6.625,
				"transactionCount": 100457,
				"voluemPrice": 6.625
			},
			{
				"open": 6.5818,
				"close": 6.5721,
				"volume": 437715628,
				"time": 1253505600000,
				"lowest": 6.4864,
				"highest": 6.6129,
				"volumePrice": 6.5492,
				"transactionCount": 76376,
				"voluemPrice": 6.5492
			},
			{
				"open": 6.6139,
				"close": 6.5886,
				"volume": 356963964,
				"time": 1253592000000,
				"lowest": 6.5304,
				"highest": 6.6207,
				"volumePrice": 6.582,
				"transactionCount": 62099,
				"voluemPrice": 6.582
			},
			{
				"open": 6.6214,
				"close": 6.625,
				"volume": 594039852,
				"time": 1253678400000,
				"lowest": 6.6082,
				"highest": 6.7464,
				"volumePrice": 6.6875,
				"transactionCount": 112846,
				"voluemPrice": 6.6875
			},
			{
				"open": 6.6857,
				"close": 6.565,
				"volume": 550872364,
				"time": 1253764800000,
				"lowest": 6.5275,
				"highest": 6.7036,
				"volumePrice": 6.5863,
				"transactionCount": 104658,
				"voluemPrice": 6.5863
			},
			{
				"open": 6.5004,
				"close": 6.5132,
				"volume": 445487448,
				"time": 1253851200000,
				"lowest": 6.48,
				"highest": 6.625,
				"volumePrice": 6.5597,
				"transactionCount": 83258,
				"voluemPrice": 6.5597
			},
			{
				"open": 6.5668,
				"close": 6.6483,
				"volume": 337594796,
				"time": 1254110400000,
				"lowest": 6.5475,
				"highest": 6.6671,
				"volumePrice": 6.6234,
				"transactionCount": 61970,
				"voluemPrice": 6.6234
			},
			{
				"open": 6.6689,
				"close": 6.6207,
				"volume": 345319100,
				"time": 1254196800000,
				"lowest": 6.5825,
				"highest": 6.6929,
				"volumePrice": 6.6279,
				"transactionCount": 62986,
				"voluemPrice": 6.6279
			},
			{
				"open": 6.6475,
				"close": 6.6196,
				"volume": 539518140,
				"time": 1254283200000,
				"lowest": 6.5219,
				"highest": 6.6589,
				"volumePrice": 6.6009,
				"transactionCount": 96243,
				"voluemPrice": 6.6009
			},
			{
				"open": 6.6196,
				"close": 6.4593,
				"volume": 524708156,
				"time": 1254369600000,
				"lowest": 6.4536,
				"highest": 6.6507,
				"volumePrice": 6.5459,
				"transactionCount": 105633,
				"voluemPrice": 6.5459
			},
			{
				"open": 6.4789,
				"close": 6.6036,
				"volume": 553434672,
				"time": 1254456000000,
				"lowest": 6.4768,
				"highest": 6.6407,
				"volumePrice": 6.5874,
				"transactionCount": 105090,
				"voluemPrice": 6.5874
			},
			{
				"open": 6.65,
				"close": 6.6437,
				"volume": 423132248,
				"time": 1254715200000,
				"lowest": 6.5811,
				"highest": 6.6736,
				"volumePrice": 6.64,
				"transactionCount": 80439,
				"voluemPrice": 6.64
			},
			{
				"open": 6.705,
				"close": 6.7861,
				"volume": 605083500,
				"time": 1254801600000,
				"lowest": 6.6893,
				"highest": 6.7861,
				"volumePrice": 6.7499,
				"transactionCount": 105192,
				"voluemPrice": 6.7499
			},
			{
				"open": 6.7771,
				"close": 6.7946,
				"volume": 465667636,
				"time": 1254888000000,
				"lowest": 6.7511,
				"highest": 6.8054,
				"volumePrice": 6.7775,
				"transactionCount": 93958,
				"voluemPrice": 6.7775
			},
			{
				"open": 6.8093,
				"close": 6.7596,
				"volume": 438209184,
				"time": 1254974400000,
				"lowest": 6.7461,
				"highest": 6.8375,
				"volumePrice": 6.7969,
				"transactionCount": 82478,
				"voluemPrice": 6.7969
			},
			{
				"open": 6.7489,
				"close": 6.8025,
				"volume": 293393380,
				"time": 1255060800000,
				"lowest": 6.7364,
				"highest": 6.8107,
				"volumePrice": 6.7866,
				"transactionCount": 56830,
				"voluemPrice": 6.7866
			},
			{
				"open": 6.8221,
				"close": 6.8146,
				"volume": 288151024,
				"time": 1255320000000,
				"lowest": 6.7729,
				"highest": 6.8396,
				"volumePrice": 6.8077,
				"transactionCount": 53008,
				"voluemPrice": 6.8077
			},
			{
				"open": 6.8082,
				"close": 6.7864,
				"volume": 348018328,
				"time": 1255406400000,
				"lowest": 6.775,
				"highest": 6.8275,
				"volumePrice": 6.7938,
				"transactionCount": 58926,
				"voluemPrice": 6.7938
			},
			{
				"open": 6.8661,
				"close": 6.8318,
				"volume": 375759356,
				"time": 1255492800000,
				"lowest": 6.7939,
				"highest": 6.8686,
				"volumePrice": 6.8306,
				"transactionCount": 71610,
				"voluemPrice": 6.8306
			},
			{
				"open": 6.7725,
				"close": 6.8057,
				"volume": 373539096,
				"time": 1255579200000,
				"lowest": 6.7689,
				"highest": 6.8186,
				"volumePrice": 6.7915,
				"transactionCount": 71851,
				"voluemPrice": 6.7915
			},
			{
				"open": 6.7625,
				"close": 6.7161,
				"volume": 431419156,
				"time": 1255665600000,
				"lowest": 6.7086,
				"highest": 6.7986,
				"volumePrice": 6.7458,
				"transactionCount": 78596,
				"voluemPrice": 6.7458
			},
			{
				"open": 6.7089,
				"close": 6.7807,
				"volume": 941974544,
				"time": 1255924800000,
				"lowest": 6.6268,
				"highest": 6.7857,
				"volumePrice": 6.8436,
				"transactionCount": 179106,
				"voluemPrice": 6.8436
			},
			{
				"open": 7.1643,
				"close": 7.0986,
				"volume": 1140977936,
				"time": 1256011200000,
				"lowest": 7.0661,
				"highest": 7.2054,
				"volumePrice": 7.1316,
				"transactionCount": 201526,
				"voluemPrice": 7.1316
			},
			{
				"open": 7.1257,
				"close": 7.3186,
				"volume": 1193726100,
				"time": 1256097600000,
				"lowest": 7.1154,
				"highest": 7.4539,
				"volumePrice": 7.3242,
				"transactionCount": 223838,
				"voluemPrice": 7.3242
			},
			{
				"open": 7.3107,
				"close": 7.3286,
				"volume": 791375340,
				"time": 1256184000000,
				"lowest": 7.2325,
				"highest": 7.4232,
				"volumePrice": 7.3331,
				"transactionCount": 137053,
				"voluemPrice": 7.3331
			},
			{
				"open": 7.3464,
				"close": 7.2836,
				"volume": 420785736,
				"time": 1256270400000,
				"lowest": 7.2582,
				"highest": 7.35,
				"volumePrice": 7.3004,
				"transactionCount": 76025,
				"voluemPrice": 7.3004
			},
			{
				"open": 7.2739,
				"close": 7.2314,
				"volume": 484334732,
				"time": 1256529600000,
				"lowest": 7.1464,
				"highest": 7.3839,
				"volumePrice": 7.2881,
				"transactionCount": 85004,
				"voluemPrice": 7.2881
			},
			{
				"open": 7.2021,
				"close": 7.0489,
				"volume": 756549892,
				"time": 1256616000000,
				"lowest": 7.0161,
				"highest": 7.2432,
				"volumePrice": 7.1035,
				"transactionCount": 119804,
				"voluemPrice": 7.1035
			},
			{
				"open": 7.0611,
				"close": 6.8714,
				"volume": 818384868,
				"time": 1256702400000,
				"lowest": 6.825,
				"highest": 7.0721,
				"volumePrice": 6.9389,
				"transactionCount": 144643,
				"voluemPrice": 6.9389
			},
			{
				"open": 6.9643,
				"close": 7.0125,
				"volume": 570408244,
				"time": 1256788800000,
				"lowest": 6.8621,
				"highest": 7.0289,
				"volumePrice": 6.9709,
				"transactionCount": 103073,
				"voluemPrice": 6.9709
			},
			{
				"open": 7.002,
				"close": 6.7321,
				"volume": 717523576,
				"time": 1256875200000,
				"lowest": 6.6452,
				"highest": 7.0286,
				"volumePrice": 6.8366,
				"transactionCount": 127576,
				"voluemPrice": 6.8366
			},
			{
				"open": 6.7786,
				"close": 6.7611,
				"volume": 679588924,
				"time": 1257138000000,
				"lowest": 6.6275,
				"highest": 6.8886,
				"volumePrice": 6.759,
				"transactionCount": 119880,
				"voluemPrice": 6.759
			},
			{
				"open": 6.7089,
				"close": 6.7411,
				"volume": 522527656,
				"time": 1257224400000,
				"lowest": 6.64,
				"highest": 6.7686,
				"volumePrice": 6.7158,
				"transactionCount": 87869,
				"voluemPrice": 6.7158
			},
			{
				"open": 6.8118,
				"close": 6.8146,
				"volume": 487741996,
				"time": 1257310800000,
				"lowest": 6.7939,
				"highest": 6.9232,
				"volumePrice": 6.8632,
				"transactionCount": 88336,
				"voluemPrice": 6.8632
			},
			{
				"open": 6.8714,
				"close": 6.9296,
				"volume": 384570396,
				"time": 1257397200000,
				"lowest": 6.8507,
				"highest": 6.9643,
				"volumePrice": 6.9209,
				"transactionCount": 71426,
				"voluemPrice": 6.9209
			},
			{
				"open": 6.8754,
				"close": 6.9407,
				"volume": 295229452,
				"time": 1257483600000,
				"lowest": 6.8714,
				"highest": 6.9711,
				"volumePrice": 6.928,
				"transactionCount": 53593,
				"voluemPrice": 6.928
			},
			{
				"open": 7.0336,
				"close": 7.195,
				"volume": 528854732,
				"time": 1257742800000,
				"lowest": 7.0093,
				"highest": 7.2107,
				"volumePrice": 7.1291,
				"transactionCount": 85362,
				"voluemPrice": 7.1291
			},
			{
				"open": 7.1793,
				"close": 7.2493,
				"volume": 401193520,
				"time": 1257829200000,
				"lowest": 7.1789,
				"highest": 7.3207,
				"volumePrice": 7.2338,
				"transactionCount": 75120,
				"voluemPrice": 7.2338
			},
			{
				"open": 7.3057,
				"close": 7.2589,
				"volume": 444078040,
				"time": 1257915600000,
				"lowest": 7.2082,
				"highest": 7.3214,
				"volumePrice": 7.2786,
				"transactionCount": 81134,
				"voluemPrice": 7.2786
			},
			{
				"open": 7.255,
				"close": 7.2139,
				"volume": 363904212,
				"time": 1258002000000,
				"lowest": 7.1939,
				"highest": 7.3168,
				"volumePrice": 7.2604,
				"transactionCount": 66147,
				"voluemPrice": 7.2604
			},
			{
				"open": 7.2454,
				"close": 7.3018,
				"volume": 343328244,
				"time": 1258088400000,
				"lowest": 7.2168,
				"highest": 7.3154,
				"volumePrice": 7.2726,
				"transactionCount": 63058,
				"voluemPrice": 7.2726
			},
			{
				"open": 7.3386,
				"close": 7.3796,
				"volume": 485204076,
				"time": 1258347600000,
				"lowest": 7.3218,
				"highest": 7.4286,
				"volumePrice": 7.3908,
				"transactionCount": 87512,
				"voluemPrice": 7.3908
			},
			{
				"open": 7.36,
				"close": 7.3929,
				"volume": 395815056,
				"time": 1258434000000,
				"lowest": 7.3214,
				"highest": 7.4086,
				"volumePrice": 7.3686,
				"transactionCount": 69793,
				"voluemPrice": 7.3686
			},
			{
				"open": 7.3764,
				"close": 7.3557,
				"volume": 374311056,
				"time": 1258520400000,
				"lowest": 7.2857,
				"highest": 7.3929,
				"volumePrice": 7.3439,
				"transactionCount": 67355,
				"voluemPrice": 7.3439
			},
			{
				"open": 7.3075,
				"close": 7.1611,
				"volume": 542326092,
				"time": 1258606800000,
				"lowest": 7.1357,
				"highest": 7.3075,
				"volumePrice": 7.1894,
				"transactionCount": 103398,
				"voluemPrice": 7.1894
			},
			{
				"open": 7.1125,
				"close": 7.14,
				"volume": 406890260,
				"time": 1258693200000,
				"lowest": 7.0629,
				"highest": 7.1568,
				"volumePrice": 7.125,
				"transactionCount": 74842,
				"voluemPrice": 7.125
			},
			{
				"open": 7.25,
				"close": 7.3529,
				"volume": 475155324,
				"time": 1258952400000,
				"lowest": 7.2482,
				"highest": 7.3571,
				"volumePrice": 7.3111,
				"transactionCount": 78618,
				"voluemPrice": 7.3111
			},
			{
				"open": 7.3332,
				"close": 7.3014,
				"volume": 318436776,
				"time": 1259038800000,
				"lowest": 7.2464,
				"highest": 7.3529,
				"volumePrice": 7.2883,
				"transactionCount": 57393,
				"voluemPrice": 7.2883
			},
			{
				"open": 7.3357,
				"close": 7.2925,
				"volume": 286649244,
				"time": 1259125200000,
				"lowest": 7.2771,
				"highest": 7.3446,
				"volumePrice": 7.3032,
				"transactionCount": 47006,
				"voluemPrice": 7.3032
			},
			{
				"open": 7.115,
				"close": 7.1639,
				"volume": 295256976,
				"time": 1259298000000,
				"lowest": 7.0846,
				"highest": 7.2486,
				"volumePrice": 7.1806,
				"transactionCount": 53902,
				"voluemPrice": 7.1806
			},
			{
				"open": 7.1825,
				"close": 7.1396,
				"volume": 424855704,
				"time": 1259557200000,
				"lowest": 7.0989,
				"highest": 7.2029,
				"volumePrice": 7.1481,
				"transactionCount": 80476,
				"voluemPrice": 7.1481
			},
			{
				"open": 7.2229,
				"close": 7.0346,
				"volume": 465759532,
				"time": 1259643600000,
				"lowest": 7.0296,
				"highest": 7.2418,
				"volumePrice": 7.1587,
				"transactionCount": 90523,
				"voluemPrice": 7.1587
			},
			{
				"open": 7.1057,
				"close": 7.0082,
				"volume": 715230824,
				"time": 1259730000000,
				"lowest": 6.9911,
				"highest": 7.1936,
				"volumePrice": 7.0691,
				"transactionCount": 123832,
				"voluemPrice": 7.0691
			},
			{
				"open": 7.0507,
				"close": 7.0171,
				"volume": 448691572,
				"time": 1259816400000,
				"lowest": 7.0096,
				"highest": 7.1064,
				"volumePrice": 7.0586,
				"transactionCount": 89778,
				"voluemPrice": 7.0586
			},
			{
				"open": 7.1321,
				"close": 6.9043,
				"volume": 827106672,
				"time": 1259902800000,
				"lowest": 6.7957,
				"highest": 7.1386,
				"volumePrice": 6.9444,
				"transactionCount": 153670,
				"voluemPrice": 6.9444
			},
			{
				"open": 6.9043,
				"close": 6.7482,
				"volume": 714758632,
				"time": 1260162000000,
				"lowest": 6.7386,
				"highest": 6.9204,
				"volumePrice": 6.8004,
				"transactionCount": 131945,
				"voluemPrice": 6.8004
			},
			{
				"open": 6.7629,
				"close": 6.7811,
				"volume": 690397120,
				"time": 1260248400000,
				"lowest": 6.7393,
				"highest": 6.8696,
				"volumePrice": 6.8017,
				"transactionCount": 120623,
				"voluemPrice": 6.8017
			},
			{
				"open": 6.8314,
				"close": 7.0643,
				"volume": 684780740,
				"time": 1260334800000,
				"lowest": 6.7968,
				"highest": 7.0771,
				"volumePrice": 6.9307,
				"transactionCount": 131194,
				"voluemPrice": 6.9307
			},
			{
				"open": 7.125,
				"close": 7.0154,
				"volume": 489668620,
				"time": 1260421200000,
				"lowest": 7.0043,
				"highest": 7.1321,
				"volumePrice": 7.0574,
				"transactionCount": 94193,
				"voluemPrice": 7.0574
			},
			{
				"open": 7.0636,
				"close": 6.9525,
				"volume": 429773512,
				"time": 1260507600000,
				"lowest": 6.9082,
				"highest": 7.0714,
				"volumePrice": 6.9727,
				"transactionCount": 77279,
				"voluemPrice": 6.9727
			},
			{
				"open": 6.9775,
				"close": 7.035,
				"volume": 495788496,
				"time": 1260766800000,
				"lowest": 6.8771,
				"highest": 7.0511,
				"volumePrice": 6.9896,
				"transactionCount": 86287,
				"voluemPrice": 6.9896
			},
			{
				"open": 6.9939,
				"close": 6.9346,
				"volume": 419713812,
				"time": 1260853200000,
				"lowest": 6.9025,
				"highest": 7.0539,
				"volumePrice": 6.9811,
				"transactionCount": 73567,
				"voluemPrice": 6.9811
			},
			{
				"open": 6.9679,
				"close": 6.9654,
				"volume": 353078936,
				"time": 1260939600000,
				"lowest": 6.9482,
				"highest": 7.0179,
				"volumePrice": 6.9785,
				"transactionCount": 65704,
				"voluemPrice": 6.9785
			},
			{
				"open": 6.9379,
				"close": 6.8521,
				"volume": 388811584,
				"time": 1261026000000,
				"lowest": 6.8214,
				"highest": 6.9643,
				"volumePrice": 6.8845,
				"transactionCount": 68052,
				"voluemPrice": 6.8845
			},
			{
				"open": 6.8989,
				"close": 6.9796,
				"volume": 608753264,
				"time": 1261112400000,
				"lowest": 6.8786,
				"highest": 6.9822,
				"volumePrice": 6.9274,
				"transactionCount": 90847,
				"voluemPrice": 6.9274
			},
			{
				"open": 7.0018,
				"close": 7.0796,
				"volume": 612372432,
				"time": 1261371600000,
				"lowest": 6.9882,
				"highest": 7.1339,
				"volumePrice": 7.0813,
				"transactionCount": 75030,
				"voluemPrice": 7.0813
			},
			{
				"open": 7.1229,
				"close": 7.1557,
				"volume": 349501488,
				"time": 1261458000000,
				"lowest": 7.095,
				"highest": 7.1732,
				"volumePrice": 7.1366,
				"transactionCount": 64545,
				"voluemPrice": 7.1366
			},
			{
				"open": 7.1875,
				"close": 7.2179,
				"volume": 345523948,
				"time": 1261544400000,
				"lowest": 7.1718,
				"highest": 7.2279,
				"volumePrice": 7.2064,
				"transactionCount": 61035,
				"voluemPrice": 7.2064
			},
			{
				"open": 7.2696,
				"close": 7.4657,
				"volume": 500887940,
				"time": 1261630800000,
				"lowest": 7.2625,
				"highest": 7.4768,
				"volumePrice": 7.4112,
				"transactionCount": 87426,
				"voluemPrice": 7.4112
			},
			{
				"open": 7.5614,
				"close": 7.5575,
				"volume": 644416500,
				"time": 1261976400000,
				"lowest": 7.4861,
				"highest": 7.6411,
				"volumePrice": 7.5742,
				"transactionCount": 107522,
				"voluemPrice": 7.5742
			},
			{
				"open": 7.5939,
				"close": 7.4679,
				"volume": 445204284,
				"time": 1262062800000,
				"lowest": 7.4546,
				"highest": 7.5971,
				"volumePrice": 7.5075,
				"transactionCount": 74440,
				"voluemPrice": 7.5075
			},
			{
				"open": 7.4582,
				"close": 7.5586,
				"volume": 412082832,
				"time": 1262149200000,
				"lowest": 7.4396,
				"highest": 7.5714,
				"volumePrice": 7.5202,
				"transactionCount": 71601,
				"voluemPrice": 7.5202
			},
			{
				"open": 7.6118,
				"close": 7.5261,
				"volume": 352380532,
				"time": 1262235600000,
				"lowest": 7.52,
				"highest": 7.6196,
				"volumePrice": 7.5596,
				"transactionCount": 62344,
				"voluemPrice": 7.5596
			},
			{
				"open": 7.6225,
				"close": 7.6432,
				"volume": 493719800,
				"time": 1262581200000,
				"lowest": 7.585,
				"highest": 7.6607,
				"volumePrice": 7.6312,
				"transactionCount": 92456,
				"voluemPrice": 7.6312
			},
			{
				"open": 7.6643,
				"close": 7.6564,
				"volume": 601901216,
				"time": 1262667600000,
				"lowest": 7.6161,
				"highest": 7.6996,
				"volumePrice": 7.6656,
				"transactionCount": 103010,
				"voluemPrice": 7.6656
			},
			{
				"open": 7.6564,
				"close": 7.5346,
				"volume": 552158376,
				"time": 1262754000000,
				"lowest": 7.5268,
				"highest": 7.6868,
				"volumePrice": 7.6068,
				"transactionCount": 106579,
				"voluemPrice": 7.6068
			},
			{
				"open": 7.5625,
				"close": 7.5207,
				"volume": 477129296,
				"time": 1262840400000,
				"lowest": 7.4661,
				"highest": 7.5714,
				"volumePrice": 7.5124,
				"transactionCount": 90809,
				"voluemPrice": 7.5124
			},
			{
				"open": 7.5107,
				"close": 7.5707,
				"volume": 447872124,
				"time": 1262926800000,
				"lowest": 7.4664,
				"highest": 7.5714,
				"volumePrice": 7.5324,
				"transactionCount": 81435,
				"voluemPrice": 7.5324
			},
			{
				"open": 7.6,
				"close": 7.5039,
				"volume": 462229460,
				"time": 1263186000000,
				"lowest": 7.4446,
				"highest": 7.6071,
				"volumePrice": 7.5043,
				"transactionCount": 89808,
				"voluemPrice": 7.5043
			},
			{
				"open": 7.4711,
				"close": 7.4186,
				"volume": 594459096,
				"time": 1263272400000,
				"lowest": 7.3721,
				"highest": 7.4918,
				"volumePrice": 7.4315,
				"transactionCount": 111919,
				"voluemPrice": 7.4315
			},
			{
				"open": 7.4239,
				"close": 7.5232,
				"volume": 604688140,
				"time": 1263358800000,
				"lowest": 7.2893,
				"highest": 7.5332,
				"volumePrice": 7.4231,
				"transactionCount": 115900,
				"voluemPrice": 7.4231
			},
			{
				"open": 7.5039,
				"close": 7.4796,
				"volume": 431115244,
				"time": 1263445200000,
				"lowest": 7.465,
				"highest": 7.5164,
				"volumePrice": 7.4887,
				"transactionCount": 80495,
				"voluemPrice": 7.4887
			},
			{
				"open": 7.5332,
				"close": 7.3546,
				"volume": 594311872,
				"time": 1263531600000,
				"lowest": 7.3525,
				"highest": 7.5571,
				"volumePrice": 7.4328,
				"transactionCount": 109383,
				"voluemPrice": 7.4328
			},
			{
				"open": 7.4404,
				"close": 7.68,
				"volume": 730003680,
				"time": 1263877200000,
				"lowest": 7.4014,
				"highest": 7.6854,
				"volumePrice": 7.6038,
				"transactionCount": 120957,
				"voluemPrice": 7.6038
			},
			{
				"open": 7.6754,
				"close": 7.5616,
				"volume": 612151568,
				"time": 1263963600000,
				"lowest": 7.4821,
				"highest": 7.6982,
				"volumePrice": 7.5513,
				"transactionCount": 123097,
				"voluemPrice": 7.5513
			},
			{
				"open": 7.5743,
				"close": 7.4311,
				"volume": 608092660,
				"time": 1264050000000,
				"lowest": 7.4004,
				"highest": 7.6184,
				"volumePrice": 7.4685,
				"transactionCount": 112031,
				"voluemPrice": 7.4685
			},
			{
				"open": 7.385,
				"close": 7.0625,
				"volume": 881754216,
				"time": 1264136400000,
				"lowest": 7.0414,
				"highest": 7.4107,
				"volumePrice": 7.2225,
				"transactionCount": 166841,
				"voluemPrice": 7.2225
			},
			{
				"open": 7.2325,
				"close": 7.2527,
				"volume": 1065549884,
				"time": 1264395600000,
				"lowest": 7.1496,
				"highest": 7.3107,
				"volumePrice": 7.2471,
				"transactionCount": 206845,
				"voluemPrice": 7.2471
			},
			{
				"open": 7.3554,
				"close": 7.355,
				"volume": 1867070744,
				"time": 1264482000000,
				"lowest": 7.235,
				"highest": 7.6325,
				"volumePrice": 7.4414,
				"transactionCount": 321550,
				"voluemPrice": 7.4414
			},
			{
				"open": 7.3875,
				"close": 7.4244,
				"volume": 1722526120,
				"time": 1264568400000,
				"lowest": 7.1261,
				"highest": 7.5207,
				"volumePrice": 7.3404,
				"transactionCount": 317934,
				"voluemPrice": 7.3404
			},
			{
				"open": 7.3189,
				"close": 7.1175,
				"volume": 1173464264,
				"time": 1264654800000,
				"lowest": 7.0964,
				"highest": 7.3393,
				"volumePrice": 7.1668,
				"transactionCount": 223103,
				"voluemPrice": 7.1668
			},
			{
				"open": 7.1814,
				"close": 6.8594,
				"volume": 1245928740,
				"time": 1264741200000,
				"lowest": 6.7946,
				"highest": 7.2214,
				"volumePrice": 6.9714,
				"transactionCount": 235045,
				"voluemPrice": 6.9714
			},
			{
				"open": 6.8704,
				"close": 6.9546,
				"volume": 749831908,
				"time": 1265000400000,
				"lowest": 6.8321,
				"highest": 7,
				"volumePrice": 6.9207,
				"transactionCount": 141354,
				"voluemPrice": 6.9207
			},
			{
				"open": 6.9968,
				"close": 6.995,
				"volume": 698338452,
				"time": 1265086800000,
				"lowest": 6.9064,
				"highest": 7.0114,
				"volumePrice": 6.9658,
				"transactionCount": 113137,
				"voluemPrice": 6.9658
			},
			{
				"open": 6.9704,
				"close": 7.1154,
				"volume": 615327076,
				"time": 1265173200000,
				"lowest": 6.9436,
				"highest": 7.15,
				"volumePrice": 7.0903,
				"transactionCount": 115886,
				"voluemPrice": 7.0903
			},
			{
				"open": 7.0261,
				"close": 6.8589,
				"volume": 757583848,
				"time": 1265259600000,
				"lowest": 6.8418,
				"highest": 7.0846,
				"volumePrice": 6.9332,
				"transactionCount": 150335,
				"voluemPrice": 6.9332
			},
			{
				"open": 6.8795,
				"close": 6.9807,
				"volume": 850240440,
				"time": 1265346000000,
				"lowest": 6.8161,
				"highest": 7,
				"volumePrice": 6.909,
				"transactionCount": 167025,
				"voluemPrice": 6.909
			},
			{
				"open": 6.9889,
				"close": 6.9329,
				"volume": 478187276,
				"time": 1265605200000,
				"lowest": 6.9286,
				"highest": 7.0671,
				"volumePrice": 7.0037,
				"transactionCount": 89462,
				"voluemPrice": 7.0037
			},
			{
				"open": 7.015,
				"close": 7.0068,
				"volume": 632760268,
				"time": 1265691600000,
				"lowest": 6.9554,
				"highest": 7.0536,
				"volumePrice": 7.0126,
				"transactionCount": 107291,
				"voluemPrice": 7.0126
			},
			{
				"open": 6.9961,
				"close": 6.9684,
				"volume": 370015940,
				"time": 1265778000000,
				"lowest": 6.9379,
				"highest": 7.0214,
				"volumePrice": 6.9789,
				"transactionCount": 72681,
				"voluemPrice": 6.9789
			},
			{
				"open": 6.96,
				"close": 7.0954,
				"volume": 550057732,
				"time": 1265864400000,
				"lowest": 6.9307,
				"highest": 7.1339,
				"volumePrice": 7.0559,
				"transactionCount": 110744,
				"voluemPrice": 7.0559
			},
			{
				"open": 7.0754,
				"close": 7.1564,
				"volume": 655155592,
				"time": 1265950800000,
				"lowest": 6.9821,
				"highest": 7.2014,
				"volumePrice": 7.1268,
				"transactionCount": 123198,
				"voluemPrice": 7.1268
			},
			{
				"open": 7.2121,
				"close": 7.2643,
				"volume": 543836244,
				"time": 1266296400000,
				"lowest": 7.1971,
				"highest": 7.2746,
				"volumePrice": 7.2494,
				"transactionCount": 89254,
				"voluemPrice": 7.2494
			},
			{
				"open": 7.2925,
				"close": 7.2339,
				"volume": 436355640,
				"time": 1266382800000,
				"lowest": 7.1736,
				"highest": 7.2968,
				"volumePrice": 7.2371,
				"transactionCount": 87625,
				"voluemPrice": 7.2371
			},
			{
				"open": 7.2011,
				"close": 7.2474,
				"volume": 422378236,
				"time": 1266469200000,
				"lowest": 7.1757,
				"highest": 7.2818,
				"volumePrice": 7.2383,
				"transactionCount": 85647,
				"voluemPrice": 7.2383
			},
			{
				"open": 7.2093,
				"close": 7.2025,
				"volume": 415725464,
				"time": 1266555600000,
				"lowest": 7.1825,
				"highest": 7.2571,
				"volumePrice": 7.2144,
				"transactionCount": 79995,
				"voluemPrice": 7.2144
			},
			{
				"open": 7.2264,
				"close": 7.1577,
				"volume": 390723088,
				"time": 1266814800000,
				"lowest": 7.1139,
				"highest": 7.2321,
				"volumePrice": 7.1579,
				"transactionCount": 76698,
				"voluemPrice": 7.1579
			},
			{
				"open": 7.1429,
				"close": 7.0378,
				"volume": 575296764,
				"time": 1266901200000,
				"lowest": 6.9896,
				"highest": 7.1904,
				"volumePrice": 7.0636,
				"transactionCount": 96456,
				"voluemPrice": 7.0636
			},
			{
				"open": 7.0796,
				"close": 7.1625,
				"volume": 460383784,
				"time": 1266987600000,
				"lowest": 7.0657,
				"highest": 7.1943,
				"volumePrice": 7.1444,
				"transactionCount": 91980,
				"voluemPrice": 7.1444
			},
			{
				"open": 7.0493,
				"close": 7.2143,
				"volume": 665158312,
				"time": 1267074000000,
				"lowest": 7.0318,
				"highest": 7.245,
				"volumePrice": 7.1271,
				"transactionCount": 132227,
				"voluemPrice": 7.1271
			},
			{
				"open": 7.2279,
				"close": 7.3079,
				"volume": 507265556,
				"time": 1267160400000,
				"lowest": 7.2143,
				"highest": 7.3275,
				"volumePrice": 7.2943,
				"transactionCount": 93048,
				"voluemPrice": 7.2943
			},
			{
				"open": 7.3482,
				"close": 7.4639,
				"volume": 550043200,
				"time": 1267419600000,
				"lowest": 7.3375,
				"highest": 7.4821,
				"volumePrice": 7.4395,
				"transactionCount": 111557,
				"voluemPrice": 7.4395
			},
			{
				"open": 7.4975,
				"close": 7.4589,
				"volume": 566536208,
				"time": 1267506000000,
				"lowest": 7.4193,
				"highest": 7.5296,
				"volumePrice": 7.4816,
				"transactionCount": 96845,
				"voluemPrice": 7.4816
			},
			{
				"open": 7.4621,
				"close": 7.4761,
				"volume": 372238776,
				"time": 1267592400000,
				"lowest": 7.4264,
				"highest": 7.4954,
				"volumePrice": 7.4722,
				"transactionCount": 73166,
				"voluemPrice": 7.4722
			},
			{
				"open": 7.4743,
				"close": 7.5254,
				"volume": 366009504,
				"time": 1267678800000,
				"lowest": 7.4511,
				"highest": 7.5329,
				"volumePrice": 7.5056,
				"transactionCount": 71076,
				"voluemPrice": 7.5056
			},
			{
				"open": 7.6764,
				"close": 7.8196,
				"volume": 899564596,
				"time": 1267765200000,
				"lowest": 7.6654,
				"highest": 7.8464,
				"volumePrice": 7.7813,
				"transactionCount": 168716,
				"voluemPrice": 7.7813
			},
			{
				"open": 7.8575,
				"close": 7.8243,
				"volume": 429883356,
				"time": 1268024400000,
				"lowest": 7.7946,
				"highest": 7.8604,
				"volumePrice": 7.8315,
				"transactionCount": 84636,
				"voluemPrice": 7.8315
			},
			{
				"open": 7.7968,
				"close": 7.965,
				"volume": 920144120,
				"time": 1268110800000,
				"lowest": 7.7818,
				"highest": 8.0357,
				"volumePrice": 7.9569,
				"transactionCount": 157098,
				"voluemPrice": 7.9569
			},
			{
				"open": 7.9939,
				"close": 8.03,
				"volume": 596180172,
				"time": 1268197200000,
				"lowest": 7.9714,
				"highest": 8.0529,
				"volumePrice": 8.0189,
				"transactionCount": 104665,
				"voluemPrice": 8.0189
			},
			{
				"open": 7.9968,
				"close": 8.0536,
				"volume": 405690152,
				"time": 1268283600000,
				"lowest": 7.9757,
				"highest": 8.0536,
				"volumePrice": 8.014,
				"transactionCount": 77881,
				"voluemPrice": 8.014
			},
			{
				"open": 8.1204,
				"close": 8.0929,
				"volume": 416095876,
				"time": 1268370000000,
				"lowest": 8.0625,
				"highest": 8.1332,
				"volumePrice": 8.097,
				"transactionCount": 80188,
				"voluemPrice": 8.097
			},
			{
				"open": 8.0493,
				"close": 7.9943,
				"volume": 493500364,
				"time": 1268625600000,
				"lowest": 7.8661,
				"highest": 8.0536,
				"volumePrice": 7.9547,
				"transactionCount": 94019,
				"voluemPrice": 7.9547
			},
			{
				"open": 8.0064,
				"close": 8.0161,
				"volume": 446906544,
				"time": 1268712000000,
				"lowest": 7.9468,
				"highest": 8.035,
				"volumePrice": 8.0054,
				"transactionCount": 75628,
				"voluemPrice": 8.0054
			},
			{
				"open": 8.0321,
				"close": 8.0043,
				"volume": 450953188,
				"time": 1268798400000,
				"lowest": 7.9739,
				"highest": 8.0875,
				"volumePrice": 8.0397,
				"transactionCount": 80147,
				"voluemPrice": 8.0397
			},
			{
				"open": 8.0036,
				"close": 8.0232,
				"volume": 342108452,
				"time": 1268884800000,
				"lowest": 7.9504,
				"highest": 8.0357,
				"volumePrice": 7.9943,
				"transactionCount": 66874,
				"voluemPrice": 7.9943
			},
			{
				"open": 8.0282,
				"close": 7.9375,
				"volume": 559444592,
				"time": 1268971200000,
				"lowest": 7.9011,
				"highest": 8.0443,
				"volumePrice": 7.9581,
				"transactionCount": 86130,
				"voluemPrice": 7.9581
			},
			{
				"open": 7.8739,
				"close": 8.0268,
				"volume": 456418536,
				"time": 1269230400000,
				"lowest": 7.8625,
				"highest": 8.0714,
				"volumePrice": 8.0111,
				"transactionCount": 84320,
				"voluemPrice": 8.0111
			},
			{
				"open": 8.0586,
				"close": 8.1557,
				"volume": 602418572,
				"time": 1269316800000,
				"lowest": 8.0036,
				"highest": 8.1707,
				"volumePrice": 8.0931,
				"transactionCount": 108131,
				"voluemPrice": 8.0931
			},
			{
				"open": 8.13,
				"close": 8.1918,
				"volume": 598280928,
				"time": 1269403200000,
				"lowest": 8.1254,
				"highest": 8.2214,
				"volumePrice": 8.1928,
				"transactionCount": 100090,
				"voluemPrice": 8.1928
			},
			{
				"open": 8.2471,
				"close": 8.0946,
				"volume": 543266696,
				"time": 1269489600000,
				"lowest": 8.0804,
				"highest": 8.2489,
				"volumePrice": 8.189,
				"transactionCount": 105127,
				"voluemPrice": 8.189
			},
			{
				"open": 8.1768,
				"close": 8.2464,
				"volume": 640872540,
				"time": 1269576000000,
				"lowest": 8.1625,
				"highest": 8.2839,
				"volumePrice": 8.2316,
				"transactionCount": 107651,
				"voluemPrice": 8.2316
			},
			{
				"open": 8.3214,
				"close": 8.2996,
				"volume": 540727740,
				"time": 1269835200000,
				"lowest": 8.2721,
				"highest": 8.3525,
				"volumePrice": 8.3151,
				"transactionCount": 79488,
				"voluemPrice": 8.3151
			},
			{
				"open": 8.4501,
				"close": 8.423,
				"volume": 527309300,
				"time": 1269921600000,
				"lowest": 8.3661,
				"highest": 8.4814,
				"volumePrice": 8.4328,
				"transactionCount": 96266,
				"voluemPrice": 8.4328
			},
			{
				"open": 8.4104,
				"close": 8.3929,
				"volume": 361349968,
				"time": 1270008000000,
				"lowest": 8.3736,
				"highest": 8.4504,
				"volumePrice": 8.4111,
				"transactionCount": 66201,
				"voluemPrice": 8.4111
			},
			{
				"open": 8.4818,
				"close": 8.4275,
				"volume": 603143996,
				"time": 1270094400000,
				"lowest": 8.3125,
				"highest": 8.5261,
				"volumePrice": 8.4341,
				"transactionCount": 114279,
				"voluemPrice": 8.4341
			},
			{
				"open": 8.392,
				"close": 8.5175,
				"volume": 684478676,
				"time": 1270440000000,
				"lowest": 8.3846,
				"highest": 8.5182,
				"volumePrice": 8.4621,
				"transactionCount": 128636,
				"voluemPrice": 8.4621
			},
			{
				"open": 8.5071,
				"close": 8.555,
				"volume": 447008072,
				"time": 1270526400000,
				"lowest": 8.4643,
				"highest": 8.58,
				"volumePrice": 8.5217,
				"transactionCount": 80857,
				"voluemPrice": 8.5217
			},
			{
				"open": 8.5614,
				"close": 8.5929,
				"volume": 628500124,
				"time": 1270612800000,
				"lowest": 8.5236,
				"highest": 8.64,
				"volumePrice": 8.5958,
				"transactionCount": 110491,
				"voluemPrice": 8.5958
			},
			{
				"open": 8.5871,
				"close": 8.5696,
				"volume": 572967080,
				"time": 1270699200000,
				"lowest": 8.5014,
				"highest": 8.6264,
				"volumePrice": 8.563,
				"transactionCount": 109900,
				"voluemPrice": 8.563
			},
			{
				"open": 8.6225,
				"close": 8.6354,
				"volume": 334182576,
				"time": 1270785600000,
				"lowest": 8.5879,
				"highest": 8.6389,
				"volumePrice": 8.6146,
				"transactionCount": 59445,
				"voluemPrice": 8.6146
			},
			{
				"open": 8.65,
				"close": 8.6532,
				"volume": 333215344,
				"time": 1271044800000,
				"lowest": 8.6361,
				"highest": 8.6811,
				"volumePrice": 8.661,
				"transactionCount": 58814,
				"voluemPrice": 8.661
			},
			{
				"open": 8.6379,
				"close": 8.6582,
				"volume": 306200860,
				"time": 1271131200000,
				"lowest": 8.6111,
				"highest": 8.6714,
				"volumePrice": 8.6486,
				"transactionCount": 58668,
				"voluemPrice": 8.6486
			},
			{
				"open": 8.76,
				"close": 8.7746,
				"volume": 404074216,
				"time": 1271217600000,
				"lowest": 8.7168,
				"highest": 8.7789,
				"volumePrice": 8.7576,
				"transactionCount": 76073,
				"voluemPrice": 8.7576
			},
			{
				"open": 8.7779,
				"close": 8.89,
				"volume": 376755932,
				"time": 1271304000000,
				"lowest": 8.7682,
				"highest": 8.8939,
				"volumePrice": 8.8381,
				"transactionCount": 71303,
				"voluemPrice": 8.8381
			},
			{
				"open": 8.8775,
				"close": 8.8357,
				"volume": 750543864,
				"time": 1271390400000,
				"lowest": 8.7339,
				"highest": 8.9693,
				"volumePrice": 8.8603,
				"transactionCount": 128412,
				"voluemPrice": 8.8603
			},
			{
				"open": 8.8225,
				"close": 8.8239,
				"volume": 566918464,
				"time": 1271649600000,
				"lowest": 8.6346,
				"highest": 8.8532,
				"volumePrice": 8.7521,
				"transactionCount": 111497,
				"voluemPrice": 8.7521
			},
			{
				"open": 8.8764,
				"close": 8.7354,
				"volume": 738319736,
				"time": 1271736000000,
				"lowest": 8.677,
				"highest": 8.9018,
				"volumePrice": 8.8758,
				"transactionCount": 144544,
				"voluemPrice": 8.8758
			},
			{
				"open": 9.243,
				"close": 9.2579,
				"volume": 982391200,
				"time": 1271822400000,
				"lowest": 9.1332,
				"highest": 9.2946,
				"volumePrice": 9.238,
				"transactionCount": 187476,
				"voluemPrice": 9.238
			},
			{
				"open": 9.2229,
				"close": 9.5168,
				"volume": 793423260,
				"time": 1271908800000,
				"lowest": 9.15,
				"highest": 9.5268,
				"volumePrice": 9.3479,
				"transactionCount": 153727,
				"voluemPrice": 9.3479
			},
			{
				"open": 9.5711,
				"close": 9.6725,
				"volume": 796953416,
				"time": 1271995200000,
				"lowest": 9.5357,
				"highest": 9.7207,
				"volumePrice": 9.6565,
				"transactionCount": 150425,
				"voluemPrice": 9.6565
			},
			{
				"open": 9.71,
				"close": 9.625,
				"volume": 479068044,
				"time": 1272254400000,
				"lowest": 9.5782,
				"highest": 9.7307,
				"volumePrice": 9.6341,
				"transactionCount": 91700,
				"voluemPrice": 9.6341
			},
			{
				"open": 9.5454,
				"close": 9.3586,
				"volume": 707605696,
				"time": 1272340800000,
				"lowest": 9.3043,
				"highest": 9.5657,
				"volumePrice": 9.4268,
				"transactionCount": 135264,
				"voluemPrice": 9.4268
			},
			{
				"open": 9.4018,
				"close": 9.3429,
				"volume": 758399880,
				"time": 1272427200000,
				"lowest": 9.1575,
				"highest": 9.4286,
				"volumePrice": 9.2909,
				"transactionCount": 148837,
				"voluemPrice": 9.2909
			},
			{
				"open": 9.3936,
				"close": 9.5943,
				"volume": 558838980,
				"time": 1272513600000,
				"lowest": 9.3575,
				"highest": 9.6429,
				"volumePrice": 9.5531,
				"transactionCount": 107090,
				"voluemPrice": 9.5531
			},
			{
				"open": 9.6182,
				"close": 9.3246,
				"volume": 542462704,
				"time": 1272600000000,
				"lowest": 9.3214,
				"highest": 9.6632,
				"volumePrice": 9.4478,
				"transactionCount": 106030,
				"voluemPrice": 9.4478
			},
			{
				"open": 9.4229,
				"close": 9.5125,
				"volume": 454337324,
				"time": 1272859200000,
				"lowest": 9.3886,
				"highest": 9.5671,
				"volumePrice": 9.4997,
				"transactionCount": 88335,
				"voluemPrice": 9.4997
			},
			{
				"open": 9.3889,
				"close": 9.2386,
				"volume": 723806720,
				"time": 1272945600000,
				"lowest": 9.1696,
				"highest": 9.4032,
				"volumePrice": 9.2491,
				"transactionCount": 137287,
				"voluemPrice": 9.2491
			},
			{
				"open": 9.0368,
				"close": 9.1423,
				"volume": 883100344,
				"time": 1273032000000,
				"lowest": 8.8832,
				"highest": 9.2193,
				"volumePrice": 9.062,
				"transactionCount": 169334,
				"voluemPrice": 9.062
			},
			{
				"open": 9.0654,
				"close": 8.7946,
				"volume": 1285859568,
				"time": 1273118400000,
				"lowest": 7.1161,
				"highest": 9.2232,
				"volumePrice": 8.8235,
				"transactionCount": 254377,
				"voluemPrice": 8.8235
			},
			{
				"open": 8.7039,
				"close": 8.4236,
				"volume": 1676017168,
				"time": 1273204800000,
				"lowest": 8.0432,
				"highest": 8.8061,
				"volumePrice": 8.4787,
				"transactionCount": 341651,
				"voluemPrice": 8.4787
			},
			{
				"open": 8.9375,
				"close": 9.0711,
				"volume": 984304832,
				"time": 1273464000000,
				"lowest": 8.8761,
				"highest": 9.0946,
				"volumePrice": 9.0064,
				"transactionCount": 207144,
				"voluemPrice": 9.0064
			},
			{
				"open": 8.9943,
				"close": 9.1614,
				"volume": 850351236,
				"time": 1273550400000,
				"lowest": 8.9464,
				"highest": 9.2818,
				"volumePrice": 9.1462,
				"transactionCount": 185432,
				"voluemPrice": 9.1462
			},
			{
				"open": 9.2588,
				"close": 9.3604,
				"volume": 654377500,
				"time": 1273636800000,
				"lowest": 9.2393,
				"highest": 9.3975,
				"volumePrice": 9.3424,
				"transactionCount": 140776,
				"voluemPrice": 9.3424
			},
			{
				"open": 9.4007,
				"close": 9.2271,
				"volume": 599710104,
				"time": 1273723200000,
				"lowest": 9.1571,
				"highest": 9.4643,
				"volumePrice": 9.3485,
				"transactionCount": 125125,
				"voluemPrice": 9.3485
			},
			{
				"open": 9.113,
				"close": 9.065,
				"volume": 759361428,
				"time": 1273809600000,
				"lowest": 8.9107,
				"highest": 9.16,
				"volumePrice": 9.0192,
				"transactionCount": 150738,
				"voluemPrice": 9.0192
			},
			{
				"open": 9.0964,
				"close": 9.0793,
				"volume": 762833008,
				"time": 1274068800000,
				"lowest": 8.8468,
				"highest": 9.1493,
				"volumePrice": 9.0076,
				"transactionCount": 158442,
				"voluemPrice": 9.0076
			},
			{
				"open": 9.1779,
				"close": 9.0129,
				"volume": 782675824,
				"time": 1274155200000,
				"lowest": 8.9379,
				"highest": 9.2339,
				"volumePrice": 9.0647,
				"transactionCount": 157598,
				"voluemPrice": 9.0647
			},
			{
				"open": 8.9107,
				"close": 8.8693,
				"volume": 1025725624,
				"time": 1274241600000,
				"lowest": 8.7446,
				"highest": 9.0329,
				"volumePrice": 8.8488,
				"transactionCount": 212077,
				"voluemPrice": 8.8488
			},
			{
				"open": 8.6386,
				"close": 8.4914,
				"volume": 1282914948,
				"time": 1274328000000,
				"lowest": 8.4361,
				"highest": 8.7089,
				"volumePrice": 8.584,
				"transactionCount": 286808,
				"voluemPrice": 8.584
			},
			{
				"open": 8.315,
				"close": 8.6543,
				"volume": 1223871740,
				"time": 1274414400000,
				"lowest": 8.2625,
				"highest": 8.7321,
				"volumePrice": 8.5413,
				"transactionCount": 253889,
				"voluemPrice": 8.5413
			},
			{
				"open": 8.8314,
				"close": 8.8129,
				"volume": 754230764,
				"time": 1274673600000,
				"lowest": 8.795,
				"highest": 8.9607,
				"volumePrice": 8.8935,
				"transactionCount": 158816,
				"voluemPrice": 8.8935
			},
			{
				"open": 8.5482,
				"close": 8.7579,
				"volume": 1047997188,
				"time": 1274760000000,
				"lowest": 8.47,
				"highest": 8.8129,
				"volumePrice": 8.6131,
				"transactionCount": 221449,
				"voluemPrice": 8.6131
			},
			{
				"open": 8.9314,
				"close": 8.7182,
				"volume": 850648176,
				"time": 1274846400000,
				"lowest": 8.7054,
				"highest": 9.0046,
				"volumePrice": 8.8846,
				"transactionCount": 181765,
				"voluemPrice": 8.8846
			},
			{
				"open": 8.95,
				"close": 9.0482,
				"volume": 666260140,
				"time": 1274932800000,
				"lowest": 8.8968,
				"highest": 9.0675,
				"volumePrice": 8.9694,
				"transactionCount": 142101,
				"voluemPrice": 8.9694
			},
			{
				"open": 9.2639,
				"close": 9.1743,
				"volume": 815612868,
				"time": 1275019200000,
				"lowest": 9.0482,
				"highest": 9.2643,
				"volumePrice": 9.1666,
				"transactionCount": 165732,
				"voluemPrice": 9.1666
			},
			{
				"open": 9.2746,
				"close": 9.3154,
				"volume": 876467312,
				"time": 1275364800000,
				"lowest": 9.2486,
				"highest": 9.4979,
				"volumePrice": 9.3924,
				"transactionCount": 180635,
				"voluemPrice": 9.3924
			},
			{
				"open": 9.4479,
				"close": 9.4268,
				"volume": 688546096,
				"time": 1275451200000,
				"lowest": 9.2977,
				"highest": 9.4571,
				"volumePrice": 9.3828,
				"transactionCount": 146812,
				"voluemPrice": 9.3828
			},
			{
				"open": 9.4707,
				"close": 9.3971,
				"volume": 650051976,
				"time": 1275537600000,
				"lowest": 9.3004,
				"highest": 9.4839,
				"volumePrice": 9.388,
				"transactionCount": 134010,
				"voluemPrice": 9.388
			},
			{
				"open": 9.2218,
				"close": 9.1416,
				"volume": 758161768,
				"time": 1275624000000,
				"lowest": 9.0939,
				"highest": 9.3536,
				"volumePrice": 9.2296,
				"transactionCount": 161138,
				"voluemPrice": 9.2296
			},
			{
				"open": 9.2246,
				"close": 8.9621,
				"volume": 886920552,
				"time": 1275883200000,
				"lowest": 8.9482,
				"highest": 9.2554,
				"volumePrice": 9.0847,
				"transactionCount": 184560,
				"voluemPrice": 9.0847
			},
			{
				"open": 9.0443,
				"close": 8.9046,
				"volume": 1000768076,
				"time": 1275969600000,
				"lowest": 8.7732,
				"highest": 9.0643,
				"volumePrice": 8.9121,
				"transactionCount": 208312,
				"voluemPrice": 8.9121
			},
			{
				"open": 8.9811,
				"close": 8.6857,
				"volume": 854628684,
				"time": 1276056000000,
				"lowest": 8.6604,
				"highest": 8.9964,
				"volumePrice": 8.8384,
				"transactionCount": 177857,
				"voluemPrice": 8.8384
			},
			{
				"open": 8.7443,
				"close": 8.9468,
				"volume": 776355748,
				"time": 1276142400000,
				"lowest": 8.65,
				"highest": 8.9636,
				"volumePrice": 8.8271,
				"transactionCount": 162738,
				"voluemPrice": 8.8271
			},
			{
				"open": 8.8652,
				"close": 9.0539,
				"volume": 545939184,
				"time": 1276228800000,
				"lowest": 8.8346,
				"highest": 9.0664,
				"volumePrice": 8.9357,
				"transactionCount": 114265,
				"voluemPrice": 8.9357
			},
			{
				"open": 9.1414,
				"close": 9.0814,
				"volume": 602960400,
				"time": 1276488000000,
				"lowest": 9.0718,
				"highest": 9.2554,
				"volumePrice": 9.1692,
				"transactionCount": 119985,
				"voluemPrice": 9.1692
			},
			{
				"open": 9.13,
				"close": 9.2746,
				"volume": 585073188,
				"time": 1276574400000,
				"lowest": 9.125,
				"highest": 9.2804,
				"volumePrice": 9.2261,
				"transactionCount": 102793,
				"voluemPrice": 9.2261
			},
			{
				"open": 9.325,
				"close": 9.5446,
				"volume": 783661480,
				"time": 1276660800000,
				"lowest": 9.3082,
				"highest": 9.5625,
				"volumePrice": 9.4659,
				"transactionCount": 162403,
				"voluemPrice": 9.4659
			},
			{
				"open": 9.6643,
				"close": 9.7096,
				"volume": 872245136,
				"time": 1276747200000,
				"lowest": 9.625,
				"highest": 9.7464,
				"volumePrice": 9.6863,
				"transactionCount": 186003,
				"voluemPrice": 9.6863
			},
			{
				"open": 9.7232,
				"close": 9.7884,
				"volume": 784619780,
				"time": 1276833600000,
				"lowest": 9.6936,
				"highest": 9.8214,
				"volumePrice": 9.7646,
				"transactionCount": 146360,
				"voluemPrice": 9.7646
			},
			{
				"open": 9.9175,
				"close": 9.6489,
				"volume": 776489812,
				"time": 1277092800000,
				"lowest": 9.5975,
				"highest": 9.9646,
				"volumePrice": 9.8002,
				"transactionCount": 160177,
				"voluemPrice": 9.8002
			},
			{
				"open": 9.72,
				"close": 9.7804,
				"volume": 717259984,
				"time": 1277179200000,
				"lowest": 9.6964,
				"highest": 9.8561,
				"volumePrice": 9.7773,
				"transactionCount": 144351,
				"voluemPrice": 9.7773
			},
			{
				"open": 9.8064,
				"close": 9.6775,
				"volume": 768454932,
				"time": 1277265600000,
				"lowest": 9.5679,
				"highest": 9.8093,
				"volumePrice": 9.6622,
				"transactionCount": 155519,
				"voluemPrice": 9.6622
			},
			{
				"open": 9.6786,
				"close": 9.6071,
				"volume": 715097656,
				"time": 1277352000000,
				"lowest": 9.575,
				"highest": 9.757,
				"volumePrice": 9.6545,
				"transactionCount": 150305,
				"voluemPrice": 9.6545
			},
			{
				"open": 9.645,
				"close": 9.525,
				"volume": 549941084,
				"time": 1277438400000,
				"lowest": 9.4932,
				"highest": 9.6525,
				"volumePrice": 9.5546,
				"transactionCount": 109776,
				"voluemPrice": 9.5546
			},
			{
				"open": 9.5332,
				"close": 9.5821,
				"volume": 585433016,
				"time": 1277697600000,
				"lowest": 9.4471,
				"highest": 9.6339,
				"volumePrice": 9.5578,
				"transactionCount": 126663,
				"voluemPrice": 9.5578
			},
			{
				"open": 9.4329,
				"close": 9.1489,
				"volume": 1133344520,
				"time": 1277784000000,
				"lowest": 9.0821,
				"highest": 9.4425,
				"volumePrice": 9.2093,
				"transactionCount": 238426,
				"voluemPrice": 9.2093
			},
			{
				"open": 9.1682,
				"close": 8.9832,
				"volume": 739434640,
				"time": 1277870400000,
				"lowest": 8.9289,
				"highest": 9.2132,
				"volumePrice": 9.1067,
				"transactionCount": 150363,
				"voluemPrice": 9.1067
			},
			{
				"open": 9.0821,
				"close": 8.8743,
				"volume": 1022837760,
				"time": 1277956800000,
				"lowest": 8.6864,
				"highest": 9.1,
				"volumePrice": 8.8748,
				"transactionCount": 215096,
				"voluemPrice": 8.8748
			},
			{
				"open": 8.9461,
				"close": 8.8193,
				"volume": 694074724,
				"time": 1278043200000,
				"lowest": 8.6857,
				"highest": 8.9618,
				"volumePrice": 8.8199,
				"transactionCount": 143025,
				"voluemPrice": 8.8199
			},
			{
				"open": 8.9643,
				"close": 8.8796,
				"volume": 615234704,
				"time": 1278388800000,
				"lowest": 8.7914,
				"highest": 9.0286,
				"volumePrice": 8.9131,
				"transactionCount": 132768,
				"voluemPrice": 8.9131
			},
			{
				"open": 8.9461,
				"close": 9.238,
				"volume": 654548104,
				"time": 1278475200000,
				"lowest": 8.9196,
				"highest": 9.2418,
				"volumePrice": 9.0793,
				"transactionCount": 139651,
				"voluemPrice": 9.0793
			},
			{
				"open": 9.3743,
				"close": 9.2175,
				"volume": 738136644,
				"time": 1278561600000,
				"lowest": 9.1032,
				"highest": 9.3893,
				"volumePrice": 9.2343,
				"transactionCount": 153568,
				"voluemPrice": 9.2343
			},
			{
				"open": 9.1746,
				"close": 9.2721,
				"volume": 433574148,
				"time": 1278648000000,
				"lowest": 9.1129,
				"highest": 9.2821,
				"volumePrice": 9.212,
				"transactionCount": 86005,
				"voluemPrice": 9.212
			},
			{
				"open": 9.2332,
				"close": 9.1888,
				"volume": 562876944,
				"time": 1278907200000,
				"lowest": 9.1021,
				"highest": 9.3518,
				"volumePrice": 9.2189,
				"transactionCount": 119156,
				"voluemPrice": 9.2189
			},
			{
				"open": 9.1543,
				"close": 8.9928,
				"volume": 1190893032,
				"time": 1278993600000,
				"lowest": 8.8011,
				"highest": 9.1571,
				"volumePrice": 8.9448,
				"transactionCount": 249484,
				"voluemPrice": 8.9448
			},
			{
				"open": 8.9064,
				"close": 9.026,
				"volume": 812171136,
				"time": 1279080000000,
				"lowest": 8.8929,
				"highest": 9.1357,
				"volumePrice": 9.0267,
				"transactionCount": 171147,
				"voluemPrice": 9.0267
			},
			{
				"open": 8.8654,
				"close": 8.9804,
				"volume": 824863396,
				"time": 1279166400000,
				"lowest": 8.8321,
				"highest": 9.1775,
				"volumePrice": 8.917,
				"transactionCount": 180545,
				"voluemPrice": 8.917
			},
			{
				"open": 9.0421,
				"close": 8.925,
				"volume": 1039857504,
				"time": 1279252800000,
				"lowest": 8.8718,
				"highest": 9.1061,
				"volumePrice": 8.977,
				"transactionCount": 215339,
				"voluemPrice": 8.977
			},
			{
				"open": 8.9243,
				"close": 8.7707,
				"volume": 1024467136,
				"time": 1279512000000,
				"lowest": 8.5571,
				"highest": 8.9243,
				"volumePrice": 8.7087,
				"transactionCount": 203886,
				"voluemPrice": 8.7087
			},
			{
				"open": 8.675,
				"close": 8.9961,
				"volume": 1074950044,
				"time": 1279598400000,
				"lowest": 8.5718,
				"highest": 9.0321,
				"volumePrice": 8.9032,
				"transactionCount": 229109,
				"voluemPrice": 8.9032
			},
			{
				"open": 9.4675,
				"close": 9.08,
				"volume": 1185664088,
				"time": 1279684800000,
				"lowest": 9.0714,
				"highest": 9.4696,
				"volumePrice": 9.2411,
				"transactionCount": 253941,
				"voluemPrice": 9.2411
			},
			{
				"open": 9.2029,
				"close": 9.2509,
				"volume": 645317288,
				"time": 1279771200000,
				"lowest": 9.1182,
				"highest": 9.2857,
				"volumePrice": 9.2293,
				"transactionCount": 135536,
				"voluemPrice": 9.2293
			},
			{
				"open": 9.1818,
				"close": 9.2836,
				"volume": 533386644,
				"time": 1279857600000,
				"lowest": 9.1529,
				"highest": 9.2993,
				"volumePrice": 9.2445,
				"transactionCount": 113249,
				"voluemPrice": 9.2445
			},
			{
				"open": 9.2857,
				"close": 9.26,
				"volume": 420549808,
				"time": 1280116800000,
				"lowest": 9.2039,
				"highest": 9.2893,
				"volumePrice": 9.2431,
				"transactionCount": 81448,
				"voluemPrice": 9.2431
			},
			{
				"open": 9.3168,
				"close": 9.4314,
				"volume": 584770564,
				"time": 1280203200000,
				"lowest": 9.2964,
				"highest": 9.4571,
				"volumePrice": 9.3936,
				"transactionCount": 119720,
				"voluemPrice": 9.3936
			},
			{
				"open": 9.4168,
				"close": 9.32,
				"volume": 519984360,
				"time": 1280289600000,
				"lowest": 9.2946,
				"highest": 9.4996,
				"volumePrice": 9.3904,
				"transactionCount": 105712,
				"voluemPrice": 9.3904
			},
			{
				"open": 9.3111,
				"close": 9.2182,
				"volume": 643799044,
				"time": 1280376000000,
				"lowest": 9.1464,
				"highest": 9.3804,
				"volumePrice": 9.2523,
				"transactionCount": 127528,
				"voluemPrice": 9.2523
			},
			{
				"open": 9.139,
				"close": 9.1875,
				"volume": 448427840,
				"time": 1280462400000,
				"lowest": 9.1036,
				"highest": 9.275,
				"volumePrice": 9.1959,
				"transactionCount": 86159,
				"voluemPrice": 9.1959
			},
			{
				"open": 9.3014,
				"close": 9.3518,
				"volume": 428021944,
				"time": 1280721600000,
				"lowest": 9.2721,
				"highest": 9.3782,
				"volumePrice": 9.3438,
				"transactionCount": 87183,
				"voluemPrice": 9.3438
			},
			{
				"open": 9.3218,
				"close": 9.3546,
				"volume": 417652872,
				"time": 1280808000000,
				"lowest": 9.265,
				"highest": 9.4021,
				"volumePrice": 9.3372,
				"transactionCount": 79142,
				"voluemPrice": 9.3372
			},
			{
				"open": 9.3871,
				"close": 9.3921,
				"volume": 420556304,
				"time": 1280894400000,
				"lowest": 9.2968,
				"highest": 9.4386,
				"volumePrice": 9.3741,
				"transactionCount": 88601,
				"voluemPrice": 9.3741
			},
			{
				"open": 9.3475,
				"close": 9.3464,
				"volume": 289362472,
				"time": 1280980800000,
				"lowest": 9.3054,
				"highest": 9.3993,
				"volumePrice": 9.3467,
				"transactionCount": 60010,
				"voluemPrice": 9.3467
			},
			{
				"open": 9.2779,
				"close": 9.289,
				"volume": 445013240,
				"time": 1281067200000,
				"lowest": 9.2011,
				"highest": 9.3389,
				"volumePrice": 9.2659,
				"transactionCount": 96124,
				"voluemPrice": 9.2659
			},
			{
				"open": 9.3386,
				"close": 9.3482,
				"volume": 303125424,
				"time": 1281326400000,
				"lowest": 9.2704,
				"highest": 9.3625,
				"volumePrice": 9.3204,
				"transactionCount": 62035,
				"voluemPrice": 9.3204
			},
			{
				"open": 9.2804,
				"close": 9.2646,
				"volume": 451917620,
				"time": 1281412800000,
				"lowest": 9.1982,
				"highest": 9.3018,
				"volumePrice": 9.2528,
				"transactionCount": 95529,
				"voluemPrice": 9.2528
			},
			{
				"open": 9.1214,
				"close": 8.9354,
				"volume": 620052272,
				"time": 1281499200000,
				"lowest": 8.9218,
				"highest": 9.1318,
				"volumePrice": 8.9864,
				"transactionCount": 130273,
				"voluemPrice": 8.9864
			},
			{
				"open": 8.8104,
				"close": 8.9925,
				"volume": 534919336,
				"time": 1281585600000,
				"lowest": 8.79,
				"highest": 9.0393,
				"volumePrice": 8.9607,
				"transactionCount": 114982,
				"voluemPrice": 8.9607
			},
			{
				"open": 8.9875,
				"close": 8.8964,
				"volume": 354866540,
				"time": 1281672000000,
				"lowest": 8.8961,
				"highest": 8.9957,
				"volumePrice": 8.9295,
				"transactionCount": 71000,
				"voluemPrice": 8.9295
			},
			{
				"open": 8.8421,
				"close": 8.8443,
				"volume": 318427396,
				"time": 1281931200000,
				"lowest": 8.8079,
				"highest": 8.9289,
				"volumePrice": 8.8693,
				"transactionCount": 66452,
				"voluemPrice": 8.8693
			},
			{
				"open": 8.9314,
				"close": 8.9989,
				"volume": 422626540,
				"time": 1282017600000,
				"lowest": 8.9,
				"highest": 9.0939,
				"volumePrice": 9.01,
				"transactionCount": 85729,
				"voluemPrice": 9.01
			},
			{
				"open": 9.0129,
				"close": 9.0382,
				"volume": 339695692,
				"time": 1282104000000,
				"lowest": 8.985,
				"highest": 9.0954,
				"volumePrice": 9.0507,
				"transactionCount": 68057,
				"voluemPrice": 9.0507
			},
			{
				"open": 9.03,
				"close": 8.9243,
				"volume": 426704908,
				"time": 1282190400000,
				"lowest": 8.8814,
				"highest": 9.0529,
				"volumePrice": 8.9459,
				"transactionCount": 93432,
				"voluemPrice": 8.9459
			},
			{
				"open": 8.9068,
				"close": 8.9157,
				"volume": 384162884,
				"time": 1282276800000,
				"lowest": 8.8929,
				"highest": 9.0686,
				"volumePrice": 8.9265,
				"transactionCount": 76121,
				"voluemPrice": 8.9265
			},
			{
				"open": 8.9925,
				"close": 8.7786,
				"volume": 414011248,
				"time": 1282536000000,
				"lowest": 8.7589,
				"highest": 9,
				"volumePrice": 8.8524,
				"transactionCount": 84088,
				"voluemPrice": 8.8524
			},
			{
				"open": 8.6668,
				"close": 8.5689,
				"volume": 602552832,
				"time": 1282622400000,
				"lowest": 8.5232,
				"highest": 8.6786,
				"volumePrice": 8.6059,
				"transactionCount": 119974,
				"voluemPrice": 8.6059
			},
			{
				"open": 8.5014,
				"close": 8.6746,
				"volume": 596864912,
				"time": 1282708800000,
				"lowest": 8.4714,
				"highest": 8.7139,
				"volumePrice": 8.5731,
				"transactionCount": 125543,
				"voluemPrice": 8.5731
			},
			{
				"open": 8.7661,
				"close": 8.5814,
				"volume": 466503212,
				"time": 1282795200000,
				"lowest": 8.5814,
				"highest": 8.7768,
				"volumePrice": 8.6832,
				"transactionCount": 98622,
				"voluemPrice": 8.6832
			},
			{
				"open": 8.6339,
				"close": 8.6293,
				"volume": 548586304,
				"time": 1282881600000,
				"lowest": 8.4129,
				"highest": 8.6646,
				"volumePrice": 8.5769,
				"transactionCount": 111199,
				"voluemPrice": 8.5769
			},
			{
				"open": 8.5986,
				"close": 8.6607,
				"volume": 383287576,
				"time": 1283140800000,
				"lowest": 8.5957,
				"highest": 8.7768,
				"volumePrice": 8.7158,
				"transactionCount": 81047,
				"voluemPrice": 8.7158
			},
			{
				"open": 8.6375,
				"close": 8.6821,
				"volume": 420786408,
				"time": 1283227200000,
				"lowest": 8.5839,
				"highest": 8.7343,
				"volumePrice": 8.6791,
				"transactionCount": 88156,
				"voluemPrice": 8.6791
			},
			{
				"open": 8.8382,
				"close": 8.9404,
				"volume": 697035220,
				"time": 1283313600000,
				"lowest": 8.7957,
				"highest": 8.9807,
				"volumePrice": 8.9168,
				"transactionCount": 138490,
				"voluemPrice": 8.9168
			},
			{
				"open": 8.9737,
				"close": 9.0061,
				"volume": 415425192,
				"time": 1283400000000,
				"lowest": 8.8775,
				"highest": 9.0061,
				"volumePrice": 8.9456,
				"transactionCount": 84871,
				"voluemPrice": 8.9456
			},
			{
				"open": 9.1104,
				"close": 9.2418,
				"volume": 520726668,
				"time": 1283486400000,
				"lowest": 9.0893,
				"highest": 9.2421,
				"volumePrice": 9.1723,
				"transactionCount": 101799,
				"voluemPrice": 9.1723
			},
			{
				"open": 9.1657,
				"close": 9.2075,
				"volume": 342805652,
				"time": 1283832000000,
				"lowest": 9.1518,
				"highest": 9.2689,
				"volumePrice": 9.225,
				"transactionCount": 72638,
				"voluemPrice": 9.225
			},
			{
				"open": 9.2779,
				"close": 9.39,
				"volume": 526548456,
				"time": 1283918400000,
				"lowest": 9.2536,
				"highest": 9.4425,
				"volumePrice": 9.373,
				"transactionCount": 105587,
				"voluemPrice": 9.373
			},
			{
				"open": 9.4657,
				"close": 9.3954,
				"volume": 438574388,
				"time": 1284004800000,
				"lowest": 9.3936,
				"highest": 9.5186,
				"volumePrice": 9.4662,
				"transactionCount": 90657,
				"voluemPrice": 9.4662
			},
			{
				"open": 9.3996,
				"close": 9.4075,
				"volume": 387761276,
				"time": 1284091200000,
				"lowest": 9.3357,
				"highest": 9.4464,
				"volumePrice": 9.3967,
				"transactionCount": 81195,
				"voluemPrice": 9.3967
			},
			{
				"open": 9.4936,
				"close": 9.5371,
				"volume": 388779860,
				"time": 1284350400000,
				"lowest": 9.4914,
				"highest": 9.5814,
				"volumePrice": 9.5453,
				"transactionCount": 81357,
				"voluemPrice": 9.5453
			},
			{
				"open": 9.5075,
				"close": 9.5736,
				"volume": 408149252,
				"time": 1284436800000,
				"lowest": 9.4829,
				"highest": 9.6132,
				"volumePrice": 9.5677,
				"transactionCount": 82435,
				"voluemPrice": 9.5677
			},
			{
				"open": 9.5775,
				"close": 9.6507,
				"volume": 428400280,
				"time": 1284523200000,
				"lowest": 9.5657,
				"highest": 9.6564,
				"volumePrice": 9.6277,
				"transactionCount": 87857,
				"voluemPrice": 9.6277
			},
			{
				"open": 9.6514,
				"close": 9.8775,
				"volume": 652101548,
				"time": 1284609600000,
				"lowest": 9.625,
				"highest": 9.8811,
				"volumePrice": 9.7591,
				"transactionCount": 136036,
				"voluemPrice": 9.7591
			},
			{
				"open": 9.9175,
				"close": 9.8346,
				"volume": 634453904,
				"time": 1284696000000,
				"lowest": 9.7743,
				"highest": 9.9271,
				"volumePrice": 9.8511,
				"transactionCount": 108346,
				"voluemPrice": 9.8511
			},
			{
				"open": 9.86,
				"close": 10.1154,
				"volume": 658486612,
				"time": 1284955200000,
				"lowest": 9.8518,
				"highest": 10.135,
				"volumePrice": 10.0368,
				"transactionCount": 131811,
				"voluemPrice": 10.0368
			},
			{
				"open": 10.1379,
				"close": 10.1346,
				"volume": 669675524,
				"time": 1285041600000,
				"lowest": 10.0996,
				"highest": 10.2625,
				"volumePrice": 10.155,
				"transactionCount": 137274,
				"voluemPrice": 10.155
			},
			{
				"open": 10.0968,
				"close": 10.2768,
				"volume": 585415096,
				"time": 1285128000000,
				"lowest": 10.0861,
				"highest": 10.285,
				"volumePrice": 10.229,
				"transactionCount": 117229,
				"voluemPrice": 10.229
			},
			{
				"open": 10.2261,
				"close": 10.3186,
				"volume": 786115484,
				"time": 1285214400000,
				"lowest": 10.2143,
				"highest": 10.4557,
				"volumePrice": 10.3493,
				"transactionCount": 169432,
				"voluemPrice": 10.3493
			},
			{
				"open": 10.4321,
				"close": 10.44,
				"volume": 649487216,
				"time": 1285300800000,
				"lowest": 10.3768,
				"highest": 10.4832,
				"volumePrice": 10.4294,
				"transactionCount": 131670,
				"voluemPrice": 10.4294
			},
			{
				"open": 10.4991,
				"close": 10.3987,
				"volume": 483099652,
				"time": 1285560000000,
				"lowest": 10.3932,
				"highest": 10.5261,
				"volumePrice": 10.4685,
				"transactionCount": 101532,
				"voluemPrice": 10.4685
			},
			{
				"open": 10.4204,
				"close": 10.245,
				"volume": 1034476044,
				"time": 1285646400000,
				"lowest": 9.8214,
				"highest": 10.4204,
				"volumePrice": 10.2242,
				"transactionCount": 218327,
				"voluemPrice": 10.2242
			},
			{
				"open": 10.2582,
				"close": 10.2632,
				"volume": 469642432,
				"time": 1285732800000,
				"lowest": 10.2143,
				"highest": 10.3504,
				"volumePrice": 10.2805,
				"transactionCount": 100579,
				"voluemPrice": 10.2805
			},
			{
				"open": 10.3214,
				"close": 10.1339,
				"volume": 673389808,
				"time": 1285819200000,
				"lowest": 10.0446,
				"highest": 10.3571,
				"volumePrice": 10.1917,
				"transactionCount": 142211,
				"voluemPrice": 10.1917
			},
			{
				"open": 10.2195,
				"close": 10.09,
				"volume": 448405776,
				"time": 1285905600000,
				"lowest": 10.0482,
				"highest": 10.235,
				"volumePrice": 10.1285,
				"transactionCount": 92907,
				"voluemPrice": 10.1285
			},
			{
				"open": 10.0571,
				"close": 9.9514,
				"volume": 435299872,
				"time": 1286164800000,
				"lowest": 9.9204,
				"highest": 10.1036,
				"volumePrice": 9.9919,
				"transactionCount": 95398,
				"voluemPrice": 9.9919
			},
			{
				"open": 10.0714,
				"close": 10.3193,
				"volume": 501965800,
				"time": 1286251200000,
				"lowest": 10.065,
				"highest": 10.3375,
				"volumePrice": 10.2301,
				"transactionCount": 93280,
				"voluemPrice": 10.2301
			},
			{
				"open": 10.3425,
				"close": 10.3282,
				"volume": 670866056,
				"time": 1286337600000,
				"lowest": 10.1879,
				"highest": 10.4282,
				"volumePrice": 10.3027,
				"transactionCount": 133434,
				"voluemPrice": 10.3027
			},
			{
				"open": 10.3692,
				"close": 10.3293,
				"volume": 408397108,
				"time": 1286424000000,
				"lowest": 10.2468,
				"highest": 10.3743,
				"volumePrice": 10.3224,
				"transactionCount": 80534,
				"voluemPrice": 10.3224
			},
			{
				"open": 10.4183,
				"close": 10.5025,
				"volume": 658403256,
				"time": 1286510400000,
				"lowest": 10.3571,
				"highest": 10.5179,
				"volumePrice": 10.4594,
				"transactionCount": 126804,
				"voluemPrice": 10.4594
			},
			{
				"open": 10.5264,
				"close": 10.5486,
				"volume": 427750932,
				"time": 1286769600000,
				"lowest": 10.5214,
				"highest": 10.6157,
				"volumePrice": 10.5748,
				"transactionCount": 84306,
				"voluemPrice": 10.5748
			},
			{
				"open": 10.5504,
				"close": 10.6621,
				"volume": 558144552,
				"time": 1286856000000,
				"lowest": 10.4461,
				"highest": 10.6964,
				"volumePrice": 10.5963,
				"transactionCount": 118040,
				"voluemPrice": 10.5963
			},
			{
				"open": 10.7214,
				"close": 10.7193,
				"volume": 630090356,
				"time": 1286942400000,
				"lowest": 10.7071,
				"highest": 10.7843,
				"volumePrice": 10.7438,
				"transactionCount": 112854,
				"voluemPrice": 10.7438
			},
			{
				"open": 10.7746,
				"close": 10.7968,
				"volume": 435295700,
				"time": 1287028800000,
				"lowest": 10.7286,
				"highest": 10.8025,
				"volumePrice": 10.7717,
				"transactionCount": 89206,
				"voluemPrice": 10.7717
			},
			{
				"open": 10.98,
				"close": 11.2407,
				"volume": 922188456,
				"time": 1287115200000,
				"lowest": 10.8896,
				"highest": 11.25,
				"volumePrice": 11.046,
				"transactionCount": 182107,
				"voluemPrice": 11.046
			},
			{
				"open": 11.3739,
				"close": 11.3571,
				"volume": 1103638564,
				"time": 1287374400000,
				"lowest": 11.2246,
				"highest": 11.3929,
				"volumePrice": 11.2271,
				"transactionCount": 231988,
				"voluemPrice": 11.2271
			},
			{
				"open": 10.8357,
				"close": 11.0532,
				"volume": 1232280532,
				"time": 1287460800000,
				"lowest": 10.715,
				"highest": 11.2061,
				"volumePrice": 11.0216,
				"transactionCount": 262138,
				"voluemPrice": 11.0216
			},
			{
				"open": 11.0357,
				"close": 11.0904,
				"volume": 721624120,
				"time": 1287547200000,
				"lowest": 10.9596,
				"highest": 11.2232,
				"volumePrice": 11.1087,
				"transactionCount": 147551,
				"voluemPrice": 11.1087
			},
			{
				"open": 11.1557,
				"close": 11.0543,
				"volume": 551457284,
				"time": 1287633600000,
				"lowest": 10.9571,
				"highest": 11.2407,
				"volumePrice": 11.1029,
				"transactionCount": 108641,
				"voluemPrice": 11.1029
			},
			{
				"open": 11.0382,
				"close": 10.9811,
				"volume": 372775424,
				"time": 1287720000000,
				"lowest": 10.9393,
				"highest": 11.0729,
				"volumePrice": 11.0093,
				"transactionCount": 70003,
				"voluemPrice": 11.0093
			},
			{
				"open": 11.0389,
				"close": 11.03,
				"volume": 392431984,
				"time": 1287979200000,
				"lowest": 11.0157,
				"highest": 11.1286,
				"volumePrice": 11.0726,
				"transactionCount": 75385,
				"voluemPrice": 11.0726
			},
			{
				"open": 10.9596,
				"close": 11.0018,
				"volume": 392929348,
				"time": 1288065600000,
				"lowest": 10.9161,
				"highest": 11.0621,
				"volumePrice": 11.0011,
				"transactionCount": 76228,
				"voluemPrice": 11.0011
			},
			{
				"open": 10.9875,
				"close": 10.9939,
				"volume": 399000280,
				"time": 1288152000000,
				"lowest": 10.9143,
				"highest": 11.0679,
				"volumePrice": 10.968,
				"transactionCount": 78391,
				"voluemPrice": 10.968
			},
			{
				"open": 10.9982,
				"close": 10.9014,
				"volume": 551016480,
				"time": 1288238400000,
				"lowest": 10.7464,
				"highest": 11,
				"volumePrice": 10.878,
				"transactionCount": 117212,
				"voluemPrice": 10.878
			},
			{
				"open": 10.8654,
				"close": 10.7493,
				"volume": 430508512,
				"time": 1288324800000,
				"lowest": 10.7454,
				"highest": 10.9243,
				"volumePrice": 10.817,
				"transactionCount": 83180,
				"voluemPrice": 10.817
			},
			{
				"open": 10.7936,
				"close": 10.8636,
				"volume": 423889004,
				"time": 1288584000000,
				"lowest": 10.7929,
				"highest": 10.9143,
				"volumePrice": 10.8547,
				"transactionCount": 87170,
				"voluemPrice": 10.8547
			},
			{
				"open": 10.9643,
				"close": 11.0486,
				"volume": 433929748,
				"time": 1288670400000,
				"lowest": 10.9643,
				"highest": 11.0782,
				"volumePrice": 11.0356,
				"transactionCount": 87703,
				"voluemPrice": 11.0356
			},
			{
				"open": 11.1204,
				"close": 11.1714,
				"volume": 508347392,
				"time": 1288756800000,
				"lowest": 11.0189,
				"highest": 11.1743,
				"volumePrice": 11.1104,
				"transactionCount": 99649,
				"voluemPrice": 11.1104
			},
			{
				"open": 11.2661,
				"close": 11.3668,
				"volume": 642345116,
				"time": 1288843200000,
				"lowest": 11.2511,
				"highest": 11.435,
				"volumePrice": 11.3542,
				"transactionCount": 125070,
				"voluemPrice": 11.3542
			},
			{
				"open": 11.3568,
				"close": 11.3261,
				"volume": 361253060,
				"time": 1288929600000,
				"lowest": 11.3125,
				"highest": 11.4132,
				"volumePrice": 11.3561,
				"transactionCount": 70545,
				"voluemPrice": 11.3561
			},
			{
				"open": 11.3286,
				"close": 11.3793,
				"volume": 281728748,
				"time": 1289192400000,
				"lowest": 11.3129,
				"highest": 11.4204,
				"volumePrice": 11.383,
				"transactionCount": 53643,
				"voluemPrice": 11.383
			},
			{
				"open": 11.4661,
				"close": 11.2886,
				"volume": 383715220,
				"time": 1289278800000,
				"lowest": 11.2321,
				"highest": 11.475,
				"volumePrice": 11.3746,
				"transactionCount": 73881,
				"voluemPrice": 11.3746
			},
			{
				"open": 11.3086,
				"close": 11.3582,
				"volume": 384225492,
				"time": 1289365200000,
				"lowest": 11.1982,
				"highest": 11.3846,
				"volumePrice": 11.3087,
				"transactionCount": 78437,
				"voluemPrice": 11.3087
			},
			{
				"open": 11.25,
				"close": 11.3091,
				"volume": 358649732,
				"time": 1289451600000,
				"lowest": 11.2232,
				"highest": 11.3714,
				"volumePrice": 11.3157,
				"transactionCount": 78578,
				"voluemPrice": 11.3157
			},
			{
				"open": 11.2857,
				"close": 11.0011,
				"volume": 795775848,
				"time": 1289538000000,
				"lowest": 10.8439,
				"highest": 11.3036,
				"volumePrice": 11.076,
				"transactionCount": 169673,
				"voluemPrice": 11.076
			},
			{
				"open": 11.0164,
				"close": 10.9655,
				"volume": 403603816,
				"time": 1289797200000,
				"lowest": 10.9382,
				"highest": 11.0907,
				"volumePrice": 11.0111,
				"transactionCount": 84917,
				"voluemPrice": 11.0111
			},
			{
				"open": 10.9186,
				"close": 10.7711,
				"volume": 658949368,
				"time": 1289883600000,
				"lowest": 10.69,
				"highest": 10.9857,
				"volumePrice": 10.8025,
				"transactionCount": 137979,
				"voluemPrice": 10.8025
			},
			{
				"open": 10.7571,
				"close": 10.7321,
				"volume": 479449628,
				"time": 1289970000000,
				"lowest": 10.6343,
				"highest": 10.8568,
				"volumePrice": 10.7621,
				"transactionCount": 100956,
				"voluemPrice": 10.7621
			},
			{
				"open": 10.9,
				"close": 11.0154,
				"volume": 494489772,
				"time": 1290056400000,
				"lowest": 10.8818,
				"highest": 11.0596,
				"volumePrice": 10.9972,
				"transactionCount": 104979,
				"voluemPrice": 10.9972
			},
			{
				"open": 10.9989,
				"close": 10.9546,
				"volume": 384392372,
				"time": 1290142800000,
				"lowest": 10.9014,
				"highest": 11.0143,
				"volumePrice": 10.9597,
				"transactionCount": 75670,
				"voluemPrice": 10.9597
			},
			{
				"open": 10.9529,
				"close": 11.1914,
				"volume": 393073968,
				"time": 1290402000000,
				"lowest": 10.9239,
				"highest": 11.1914,
				"volumePrice": 11.0587,
				"transactionCount": 78854,
				"voluemPrice": 11.0587
			},
			{
				"open": 11.0875,
				"close": 11.0261,
				"volume": 519420776,
				"time": 1290488400000,
				"lowest": 10.9486,
				"highest": 11.1339,
				"volumePrice": 11.0239,
				"transactionCount": 97158,
				"voluemPrice": 11.0239
			},
			{
				"open": 11.1429,
				"close": 11.2427,
				"volume": 414248744,
				"time": 1290574800000,
				"lowest": 11.1339,
				"highest": 11.2643,
				"volumePrice": 11.2186,
				"transactionCount": 84933,
				"voluemPrice": 11.2186
			},
			{
				"open": 11.205,
				"close": 11.2771,
				"volume": 237744948,
				"time": 1290747600000,
				"lowest": 11.1764,
				"highest": 11.3464,
				"volumePrice": 11.2794,
				"transactionCount": 49671,
				"voluemPrice": 11.2794
			},
			{
				"open": 11.2679,
				"close": 11.3168,
				"volume": 445759580,
				"time": 1291006800000,
				"lowest": 11.1207,
				"highest": 11.3386,
				"volumePrice": 11.2346,
				"transactionCount": 91667,
				"voluemPrice": 11.2346
			},
			{
				"open": 11.1979,
				"close": 11.1125,
				"volume": 501853212,
				"time": 1291093200000,
				"lowest": 11.1025,
				"highest": 11.2271,
				"volumePrice": 11.1513,
				"transactionCount": 95595,
				"voluemPrice": 11.1513
			},
			{
				"open": 11.2596,
				"close": 11.3,
				"volume": 461750688,
				"time": 1291179600000,
				"lowest": 11.25,
				"highest": 11.3482,
				"volumePrice": 11.3043,
				"transactionCount": 85808,
				"voluemPrice": 11.3043
			},
			{
				"open": 11.3404,
				"close": 11.3625,
				"volume": 463646064,
				"time": 1291266000000,
				"lowest": 11.2461,
				"highest": 11.3929,
				"volumePrice": 11.3465,
				"transactionCount": 89861,
				"voluemPrice": 11.3465
			},
			{
				"open": 11.3218,
				"close": 11.3371,
				"volume": 342363532,
				"time": 1291352400000,
				"lowest": 11.2979,
				"highest": 11.3804,
				"volumePrice": 11.331,
				"transactionCount": 72914,
				"voluemPrice": 11.331
			},
			{
				"open": 11.38,
				"close": 11.4339,
				"volume": 446570908,
				"time": 1291611600000,
				"lowest": 11.3721,
				"highest": 11.5118,
				"volumePrice": 11.4466,
				"transactionCount": 92112,
				"voluemPrice": 11.4466
			},
			{
				"open": 11.5643,
				"close": 11.3646,
				"volume": 391439860,
				"time": 1291698000000,
				"lowest": 11.3614,
				"highest": 11.5711,
				"volumePrice": 11.471,
				"transactionCount": 78654,
				"voluemPrice": 11.471
			},
			{
				"open": 11.4154,
				"close": 11.4646,
				"volume": 321934004,
				"time": 1291784400000,
				"lowest": 11.3254,
				"highest": 11.465,
				"volumePrice": 11.4009,
				"transactionCount": 61937,
				"voluemPrice": 11.4009
			},
			{
				"open": 11.5047,
				"close": 11.4199,
				"volume": 294146104,
				"time": 1291870800000,
				"lowest": 11.3936,
				"highest": 11.5179,
				"volumePrice": 11.4404,
				"transactionCount": 58623,
				"voluemPrice": 11.4404
			},
			{
				"open": 11.4161,
				"close": 11.4486,
				"volume": 262509772,
				"time": 1291957200000,
				"lowest": 11.3786,
				"highest": 11.4661,
				"volumePrice": 11.4329,
				"transactionCount": 54352,
				"voluemPrice": 11.4329
			},
			{
				"open": 11.5846,
				"close": 11.4882,
				"volume": 439791772,
				"time": 1292216400000,
				"lowest": 11.4643,
				"highest": 11.6093,
				"volumePrice": 11.5674,
				"transactionCount": 83831,
				"voluemPrice": 11.5674
			},
			{
				"open": 11.4904,
				"close": 11.4389,
				"volume": 351567048,
				"time": 1292302800000,
				"lowest": 11.3929,
				"highest": 11.5193,
				"volumePrice": 11.4589,
				"transactionCount": 67322,
				"voluemPrice": 11.4589
			},
			{
				"open": 11.4286,
				"close": 11.4414,
				"volume": 417309256,
				"time": 1292389200000,
				"lowest": 11.3996,
				"highest": 11.5357,
				"volumePrice": 11.4534,
				"transactionCount": 74988,
				"voluemPrice": 11.4534
			},
			{
				"open": 11.4675,
				"close": 11.4732,
				"volume": 313947592,
				"time": 1292475600000,
				"lowest": 11.4321,
				"highest": 11.5218,
				"volumePrice": 11.4754,
				"transactionCount": 65848,
				"voluemPrice": 11.4754
			},
			{
				"open": 11.4868,
				"close": 11.4504,
				"volume": 387148272,
				"time": 1292562000000,
				"lowest": 11.4368,
				"highest": 11.4925,
				"volumePrice": 11.4645,
				"transactionCount": 50296,
				"voluemPrice": 11.4645
			},
			{
				"open": 11.4857,
				"close": 11.5075,
				"volume": 385605976,
				"time": 1292821200000,
				"lowest": 11.3654,
				"highest": 11.5446,
				"volumePrice": 11.4691,
				"transactionCount": 73067,
				"voluemPrice": 11.4691
			},
			{
				"open": 11.5357,
				"close": 11.5788,
				"volume": 256351536,
				"time": 1292907600000,
				"lowest": 11.5018,
				"highest": 11.5854,
				"volumePrice": 11.562,
				"transactionCount": 44646,
				"voluemPrice": 11.562
			},
			{
				"open": 11.5843,
				"close": 11.6129,
				"volume": 266144116,
				"time": 1292994000000,
				"lowest": 11.5554,
				"highest": 11.6329,
				"volumePrice": 11.6038,
				"transactionCount": 49621,
				"voluemPrice": 11.6038
			},
			{
				"open": 11.6071,
				"close": 11.5571,
				"volume": 223500704,
				"time": 1293080400000,
				"lowest": 11.5418,
				"highest": 11.6125,
				"volumePrice": 11.5681,
				"transactionCount": 42504,
				"voluemPrice": 11.5681
			},
			{
				"open": 11.5304,
				"close": 11.5957,
				"volume": 249814796,
				"time": 1293426000000,
				"lowest": 11.4829,
				"highest": 11.6229,
				"volumePrice": 11.554,
				"transactionCount": 49673,
				"voluemPrice": 11.554
			},
			{
				"open": 11.6396,
				"close": 11.6239,
				"volume": 175923188,
				"time": 1293512400000,
				"lowest": 11.6093,
				"highest": 11.6664,
				"volumePrice": 11.6311,
				"transactionCount": 34734,
				"voluemPrice": 11.6311
			},
			{
				"open": 11.6507,
				"close": 11.6175,
				"volume": 163270688,
				"time": 1293598800000,
				"lowest": 11.6107,
				"highest": 11.6589,
				"volumePrice": 11.6324,
				"transactionCount": 31446,
				"voluemPrice": 11.6324
			},
			{
				"open": 11.6243,
				"close": 11.5593,
				"volume": 157659964,
				"time": 1293685200000,
				"lowest": 11.5375,
				"highest": 11.6254,
				"volumePrice": 11.5682,
				"transactionCount": 30487,
				"voluemPrice": 11.5682
			},
			{
				"open": 11.5339,
				"close": 11.52,
				"volume": 193655308,
				"time": 1293771600000,
				"lowest": 11.4754,
				"highest": 11.5529,
				"volumePrice": 11.5014,
				"transactionCount": 36031,
				"voluemPrice": 11.5014
			},
			{
				"open": 11.63,
				"close": 11.7704,
				"volume": 444483228,
				"time": 1294030800000,
				"lowest": 11.6013,
				"highest": 11.795,
				"volumePrice": 11.7428,
				"transactionCount": 88977,
				"voluemPrice": 11.7428
			},
			{
				"open": 11.8729,
				"close": 11.8318,
				"volume": 309325604,
				"time": 1294117200000,
				"lowest": 11.7196,
				"highest": 11.875,
				"volumePrice": 11.8032,
				"transactionCount": 64926,
				"voluemPrice": 11.8032
			},
			{
				"open": 11.7696,
				"close": 11.9286,
				"volume": 255516772,
				"time": 1294203600000,
				"lowest": 11.7679,
				"highest": 11.9407,
				"volumePrice": 11.8844,
				"transactionCount": 53161,
				"voluemPrice": 11.8844
			},
			{
				"open": 11.9543,
				"close": 11.9189,
				"volume": 299779536,
				"time": 1294290000000,
				"lowest": 11.8893,
				"highest": 11.9732,
				"volumePrice": 11.9359,
				"transactionCount": 55263,
				"voluemPrice": 11.9359
			},
			{
				"open": 11.9282,
				"close": 12.0043,
				"volume": 311928848,
				"time": 1294376400000,
				"lowest": 11.8536,
				"highest": 12.0125,
				"volumePrice": 11.9373,
				"transactionCount": 64186,
				"voluemPrice": 11.9373
			},
			{
				"open": 12.1011,
				"close": 12.2305,
				"volume": 448417928,
				"time": 1294635600000,
				"lowest": 12.0418,
				"highest": 12.2582,
				"volumePrice": 12.1709,
				"transactionCount": 86590,
				"voluemPrice": 12.1709
			},
			{
				"open": 12.3171,
				"close": 12.2014,
				"volume": 444079972,
				"time": 1294722000000,
				"lowest": 12.1239,
				"highest": 12.32,
				"volumePrice": 12.2096,
				"transactionCount": 88901,
				"voluemPrice": 12.2096
			},
			{
				"open": 12.2589,
				"close": 12.3007,
				"volume": 302577240,
				"time": 1294808400000,
				"lowest": 12.2143,
				"highest": 12.3011,
				"volumePrice": 12.269,
				"transactionCount": 57183,
				"voluemPrice": 12.269
			},
			{
				"open": 12.3271,
				"close": 12.3457,
				"volume": 297640728,
				"time": 1294894800000,
				"lowest": 12.2804,
				"highest": 12.38,
				"volumePrice": 12.3381,
				"transactionCount": 57846,
				"voluemPrice": 12.3381
			},
			{
				"open": 12.3532,
				"close": 12.4457,
				"volume": 308838992,
				"time": 1294981200000,
				"lowest": 12.3014,
				"highest": 12.4457,
				"volumePrice": 12.3849,
				"transactionCount": 63570,
				"voluemPrice": 12.3849
			},
			{
				"open": 11.7686,
				"close": 12.1661,
				"volume": 1878142840,
				"time": 1295326800000,
				"lowest": 11.6429,
				"highest": 12.3129,
				"volumePrice": 12.0725,
				"transactionCount": 391057,
				"voluemPrice": 12.0725
			},
			{
				"open": 12.4411,
				"close": 12.1014,
				"volume": 1135583064,
				"time": 1295413200000,
				"lowest": 12.0314,
				"highest": 12.45,
				"volumePrice": 12.2365,
				"transactionCount": 239405,
				"voluemPrice": 12.2365
			},
			{
				"open": 12.0154,
				"close": 11.8814,
				"volume": 764789172,
				"time": 1295499600000,
				"lowest": 11.79,
				"highest": 12.0821,
				"volumePrice": 11.8877,
				"transactionCount": 163055,
				"voluemPrice": 11.8877
			},
			{
				"open": 11.9204,
				"close": 11.6686,
				"volume": 754398848,
				"time": 1295586000000,
				"lowest": 11.6654,
				"highest": 11.96,
				"volumePrice": 11.7987,
				"transactionCount": 146427,
				"voluemPrice": 11.7987
			},
			{
				"open": 11.6739,
				"close": 12.0518,
				"volume": 574682192,
				"time": 1295845200000,
				"lowest": 11.6686,
				"highest": 12.0518,
				"volumePrice": 11.9076,
				"transactionCount": 112438,
				"voluemPrice": 11.9076
			},
			{
				"open": 12.0118,
				"close": 12.1929,
				"volume": 546858200,
				"time": 1295931600000,
				"lowest": 11.9489,
				"highest": 12.1943,
				"volumePrice": 12.102,
				"transactionCount": 110979,
				"voluemPrice": 12.102
			},
			{
				"open": 12.2486,
				"close": 12.2804,
				"volume": 506757916,
				"time": 1296018000000,
				"lowest": 12.1964,
				"highest": 12.3429,
				"volumePrice": 12.294,
				"transactionCount": 100350,
				"voluemPrice": 12.294
			},
			{
				"open": 12.2779,
				"close": 12.2575,
				"volume": 285023844,
				"time": 1296104400000,
				"lowest": 12.244,
				"highest": 12.3105,
				"volumePrice": 12.2762,
				"transactionCount": 57050,
				"voluemPrice": 12.2762
			},
			{
				"open": 12.2918,
				"close": 12.0036,
				"volume": 592056584,
				"time": 1296190800000,
				"lowest": 11.9117,
				"highest": 12.3,
				"volumePrice": 12.0625,
				"transactionCount": 130797,
				"voluemPrice": 12.0625
			},
			{
				"open": 11.9929,
				"close": 12.1186,
				"volume": 377246408,
				"time": 1296450000000,
				"lowest": 11.9393,
				"highest": 12.1443,
				"volumePrice": 12.0625,
				"transactionCount": 73747,
				"voluemPrice": 12.0625
			},
			{
				"open": 12.1893,
				"close": 12.3225,
				"volume": 426626172,
				"time": 1296536400000,
				"lowest": 12.1779,
				"highest": 12.3446,
				"volumePrice": 12.2954,
				"transactionCount": 84332,
				"voluemPrice": 12.2954
			},
			{
				"open": 12.3018,
				"close": 12.2971,
				"volume": 257387228,
				"time": 1296622800000,
				"lowest": 12.2696,
				"highest": 12.3304,
				"volumePrice": 12.2977,
				"transactionCount": 54536,
				"voluemPrice": 12.2977
			},
			{
				"open": 12.2786,
				"close": 12.2657,
				"volume": 393796592,
				"time": 1296709200000,
				"lowest": 12.0911,
				"highest": 12.2943,
				"volumePrice": 12.2208,
				"transactionCount": 81220,
				"voluemPrice": 12.2208
			},
			{
				"open": 12.2729,
				"close": 12.375,
				"volume": 321838300,
				"time": 1296795600000,
				"lowest": 12.2682,
				"highest": 12.3821,
				"volumePrice": 12.3448,
				"transactionCount": 66370,
				"voluemPrice": 12.3448
			},
			{
				"open": 12.4246,
				"close": 12.5671,
				"volume": 485016840,
				"time": 1297054800000,
				"lowest": 12.4157,
				"highest": 12.6161,
				"volumePrice": 12.5515,
				"transactionCount": 88101,
				"voluemPrice": 12.5515
			},
			{
				"open": 12.6314,
				"close": 12.6857,
				"volume": 381014228,
				"time": 1297141200000,
				"lowest": 12.5768,
				"highest": 12.6971,
				"volumePrice": 12.6632,
				"transactionCount": 76402,
				"voluemPrice": 12.6632
			},
			{
				"open": 12.6854,
				"close": 12.7914,
				"volume": 482716920,
				"time": 1297227600000,
				"lowest": 12.6739,
				"highest": 12.8214,
				"volumePrice": 12.7668,
				"transactionCount": 98067,
				"voluemPrice": 12.7668
			},
			{
				"open": 12.7639,
				"close": 12.6621,
				"volume": 928549440,
				"time": 1297314000000,
				"lowest": 12.4286,
				"highest": 12.8571,
				"volumePrice": 12.7053,
				"transactionCount": 199968,
				"voluemPrice": 12.7053
			},
			{
				"open": 12.6697,
				"close": 12.7446,
				"volume": 367571792,
				"time": 1297400400000,
				"lowest": 12.6264,
				"highest": 12.7786,
				"volumePrice": 12.7316,
				"transactionCount": 76742,
				"voluemPrice": 12.7316
			},
			{
				"open": 12.7425,
				"close": 12.8279,
				"volume": 310411164,
				"time": 1297659600000,
				"lowest": 12.7396,
				"highest": 12.8386,
				"volumePrice": 12.816,
				"transactionCount": 62500,
				"voluemPrice": 12.816
			},
			{
				"open": 12.8282,
				"close": 12.8536,
				"volume": 284173764,
				"time": 1297746000000,
				"lowest": 12.7696,
				"highest": 12.8561,
				"volumePrice": 12.8222,
				"transactionCount": 58979,
				"voluemPrice": 12.8222
			},
			{
				"open": 12.8857,
				"close": 12.9689,
				"volume": 481155332,
				"time": 1297832400000,
				"lowest": 12.875,
				"highest": 13.0321,
				"volumePrice": 12.9592,
				"transactionCount": 103676,
				"voluemPrice": 12.9592
			},
			{
				"open": 12.7589,
				"close": 12.7964,
				"volume": 530568136,
				"time": 1297918800000,
				"lowest": 12.7329,
				"highest": 12.8668,
				"volumePrice": 12.7995,
				"transactionCount": 117563,
				"voluemPrice": 12.7995
			},
			{
				"open": 12.8111,
				"close": 12.52,
				"volume": 816046896,
				"time": 1298005200000,
				"lowest": 12.4829,
				"highest": 12.8393,
				"volumePrice": 12.6165,
				"transactionCount": 161539,
				"voluemPrice": 12.6165
			},
			{
				"open": 12.2196,
				"close": 12.0932,
				"volume": 872532444,
				"time": 1298350800000,
				"lowest": 12.0614,
				"highest": 12.3357,
				"volumePrice": 12.1811,
				"transactionCount": 182239,
				"voluemPrice": 12.1811
			},
			{
				"open": 12.0989,
				"close": 12.2364,
				"volume": 671851768,
				"time": 1298437200000,
				"lowest": 12.0932,
				"highest": 12.3086,
				"volumePrice": 12.2004,
				"transactionCount": 142728,
				"voluemPrice": 12.2004
			},
			{
				"open": 12.2864,
				"close": 12.2457,
				"volume": 499898056,
				"time": 1298523600000,
				"lowest": 12.0846,
				"highest": 12.3268,
				"volumePrice": 12.2124,
				"transactionCount": 108662,
				"voluemPrice": 12.2124
			},
			{
				"open": 12.3307,
				"close": 12.4343,
				"volume": 380001132,
				"time": 1298610000000,
				"lowest": 12.3143,
				"highest": 12.4439,
				"volumePrice": 12.3949,
				"transactionCount": 83472,
				"voluemPrice": 12.3949
			},
			{
				"open": 12.5443,
				"close": 12.6146,
				"volume": 403073916,
				"time": 1298869200000,
				"lowest": 12.54,
				"highest": 12.6804,
				"volumePrice": 12.6099,
				"transactionCount": 80638,
				"voluemPrice": 12.6099
			},
			{
				"open": 12.6954,
				"close": 12.4754,
				"volume": 456128652,
				"time": 1298955600000,
				"lowest": 12.4172,
				"highest": 12.7043,
				"volumePrice": 12.5301,
				"transactionCount": 91987,
				"voluemPrice": 12.5301
			},
			{
				"open": 12.4986,
				"close": 12.5757,
				"volume": 602588756,
				"time": 1299042000000,
				"lowest": 12.4429,
				"highest": 12.6554,
				"volumePrice": 12.562,
				"transactionCount": 128828,
				"voluemPrice": 12.562
			},
			{
				"open": 12.7568,
				"close": 12.8414,
				"volume": 500036656,
				"time": 1299128400000,
				"lowest": 12.7114,
				"highest": 12.8496,
				"volumePrice": 12.7944,
				"transactionCount": 108430,
				"voluemPrice": 12.7944
			},
			{
				"open": 12.8596,
				"close": 12.8571,
				"volume": 453257532,
				"time": 1299214800000,
				"lowest": 12.7768,
				"highest": 12.8675,
				"volumePrice": 12.824,
				"transactionCount": 94735,
				"voluemPrice": 12.824
			},
			{
				"open": 12.9071,
				"close": 12.6914,
				"volume": 546115276,
				"time": 1299474000000,
				"lowest": 12.5468,
				"highest": 12.9168,
				"volumePrice": 12.6909,
				"transactionCount": 118817,
				"voluemPrice": 12.6909
			},
			{
				"open": 12.6754,
				"close": 12.7057,
				"volume": 356315820,
				"time": 1299560400000,
				"lowest": 12.5804,
				"highest": 12.7643,
				"volumePrice": 12.7056,
				"transactionCount": 77114,
				"voluemPrice": 12.7056
			},
			{
				"open": 12.6675,
				"close": 12.5882,
				"volume": 453396132,
				"time": 1299646800000,
				"lowest": 12.5214,
				"highest": 12.67,
				"volumePrice": 12.5875,
				"transactionCount": 88182,
				"voluemPrice": 12.5875
			},
			{
				"open": 12.4686,
				"close": 12.3811,
				"volume": 507888220,
				"time": 1299733200000,
				"lowest": 12.3179,
				"highest": 12.4918,
				"volumePrice": 12.3841,
				"transactionCount": 112909,
				"voluemPrice": 12.3841
			},
			{
				"open": 12.3332,
				"close": 12.5711,
				"volume": 470791664,
				"time": 1299819600000,
				"lowest": 12.3214,
				"highest": 12.5829,
				"volumePrice": 12.4949,
				"transactionCount": 104378,
				"voluemPrice": 12.4949
			},
			{
				"open": 12.6136,
				"close": 12.6271,
				"volume": 436454760,
				"time": 1300075200000,
				"lowest": 12.5468,
				"highest": 12.7316,
				"volumePrice": 12.6328,
				"transactionCount": 93655,
				"voluemPrice": 12.6328
			},
			{
				"open": 12.2179,
				"close": 12.3368,
				"volume": 721077336,
				"time": 1300161600000,
				"lowest": 12.1464,
				"highest": 12.4229,
				"volumePrice": 12.3025,
				"transactionCount": 158279,
				"voluemPrice": 12.3025
			},
			{
				"open": 12.2143,
				"close": 11.7861,
				"volume": 1162008904,
				"time": 1300248000000,
				"lowest": 11.6521,
				"highest": 12.25,
				"volumePrice": 11.9506,
				"transactionCount": 253724,
				"voluemPrice": 11.9506
			},
			{
				"open": 12.0296,
				"close": 11.9514,
				"volume": 659398908,
				"time": 1300334400000,
				"lowest": 11.9364,
				"highest": 12.1289,
				"volumePrice": 12.0394,
				"transactionCount": 146291,
				"voluemPrice": 12.0394
			},
			{
				"open": 12.0404,
				"close": 11.8096,
				"volume": 753210416,
				"time": 1300420800000,
				"lowest": 11.7857,
				"highest": 12.0786,
				"volumePrice": 11.892,
				"transactionCount": 145927,
				"voluemPrice": 11.892
			},
			{
				"open": 11.9996,
				"close": 12.1179,
				"volume": 409390240,
				"time": 1300680000000,
				"lowest": 11.9736,
				"highest": 12.1336,
				"volumePrice": 12.0797,
				"transactionCount": 87977,
				"voluemPrice": 12.0797
			},
			{
				"open": 12.2343,
				"close": 12.1857,
				"volume": 326232648,
				"time": 1300766400000,
				"lowest": 12.1121,
				"highest": 12.2364,
				"volumePrice": 12.1767,
				"transactionCount": 69707,
				"voluemPrice": 12.1767
			},
			{
				"open": 12.1171,
				"close": 12.1139,
				"volume": 372993992,
				"time": 1300852800000,
				"lowest": 11.9982,
				"highest": 12.1507,
				"volumePrice": 12.0827,
				"transactionCount": 80538,
				"voluemPrice": 12.0827
			},
			{
				"open": 12.2089,
				"close": 12.3204,
				"volume": 404710880,
				"time": 1300939200000,
				"lowest": 12.1021,
				"highest": 12.3571,
				"volumePrice": 12.2468,
				"transactionCount": 87536,
				"voluemPrice": 12.2468
			},
			{
				"open": 12.4311,
				"close": 12.555,
				"volume": 449088584,
				"time": 1301025600000,
				"lowest": 12.3936,
				"highest": 12.5736,
				"volumePrice": 12.5113,
				"transactionCount": 95859,
				"voluemPrice": 12.5113
			},
			{
				"open": 12.6125,
				"close": 12.5157,
				"volume": 309349628,
				"time": 1301284800000,
				"lowest": 12.5157,
				"highest": 12.6543,
				"volumePrice": 12.5961,
				"transactionCount": 65603,
				"voluemPrice": 12.5961
			},
			{
				"open": 12.4164,
				"close": 12.5343,
				"volume": 352899820,
				"time": 1301371200000,
				"lowest": 12.3593,
				"highest": 12.5343,
				"volumePrice": 12.4677,
				"transactionCount": 74997,
				"voluemPrice": 12.4677
			},
			{
				"open": 12.5229,
				"close": 12.4511,
				"volume": 329405076,
				"time": 1301457600000,
				"lowest": 12.4086,
				"highest": 12.5314,
				"volumePrice": 12.4578,
				"transactionCount": 71815,
				"voluemPrice": 12.4578
			},
			{
				"open": 12.37,
				"close": 12.4467,
				"volume": 274368024,
				"time": 1301544000000,
				"lowest": 12.3593,
				"highest": 12.4929,
				"volumePrice": 12.4429,
				"transactionCount": 56956,
				"voluemPrice": 12.4429
			},
			{
				"open": 12.5396,
				"close": 12.3057,
				"volume": 418661600,
				"time": 1301630400000,
				"lowest": 12.2607,
				"highest": 12.5567,
				"volumePrice": 12.3973,
				"transactionCount": 88719,
				"voluemPrice": 12.3973
			},
			{
				"open": 12.2968,
				"close": 12.1854,
				"volume": 460076568,
				"time": 1301889600000,
				"lowest": 12.0857,
				"highest": 12.3071,
				"volumePrice": 12.1758,
				"transactionCount": 92909,
				"voluemPrice": 12.1758
			},
			{
				"open": 12.0354,
				"close": 12.1032,
				"volume": 482464276,
				"time": 1301976000000,
				"lowest": 12,
				"highest": 12.2232,
				"volumePrice": 12.1146,
				"transactionCount": 102092,
				"voluemPrice": 12.1146
			},
			{
				"open": 12.1864,
				"close": 12.0729,
				"volume": 402538724,
				"time": 1302062400000,
				"lowest": 12.0407,
				"highest": 12.2821,
				"volumePrice": 12.1327,
				"transactionCount": 83106,
				"voluemPrice": 12.1327
			},
			{
				"open": 12.075,
				"close": 12.0743,
				"volume": 373152080,
				"time": 1302148800000,
				"lowest": 12.0011,
				"highest": 12.1582,
				"volumePrice": 12.0735,
				"transactionCount": 84347,
				"voluemPrice": 12.0735
			},
			{
				"open": 12.14,
				"close": 11.9664,
				"volume": 377533268,
				"time": 1302235200000,
				"lowest": 11.9268,
				"highest": 12.1482,
				"volumePrice": 12.0142,
				"transactionCount": 81264,
				"voluemPrice": 12.0142
			},
			{
				"open": 11.9307,
				"close": 11.8143,
				"volume": 398986728,
				"time": 1302494400000,
				"lowest": 11.7864,
				"highest": 11.9882,
				"volumePrice": 11.8492,
				"transactionCount": 84092,
				"voluemPrice": 11.8492
			},
			{
				"open": 11.8032,
				"close": 11.8714,
				"volume": 426508320,
				"time": 1302580800000,
				"lowest": 11.7929,
				"highest": 11.9189,
				"volumePrice": 11.8517,
				"transactionCount": 94633,
				"voluemPrice": 11.8517
			},
			{
				"open": 11.965,
				"close": 12.0046,
				"volume": 346167052,
				"time": 1302667200000,
				"lowest": 11.8757,
				"highest": 12.005,
				"volumePrice": 11.9356,
				"transactionCount": 77219,
				"voluemPrice": 11.9356
			},
			{
				"open": 11.9571,
				"close": 11.8721,
				"volume": 301989604,
				"time": 1302753600000,
				"lowest": 11.8593,
				"highest": 12,
				"volumePrice": 11.9061,
				"transactionCount": 71332,
				"voluemPrice": 11.9061
			},
			{
				"open": 11.9036,
				"close": 11.695,
				"volume": 453832988,
				"time": 1302840000000,
				"lowest": 11.6714,
				"highest": 11.9157,
				"volumePrice": 11.7889,
				"transactionCount": 94296,
				"voluemPrice": 11.7889
			},
			{
				"open": 11.6464,
				"close": 11.8518,
				"volume": 609897708,
				"time": 1303099200000,
				"lowest": 11.4343,
				"highest": 11.8654,
				"volumePrice": 11.6007,
				"transactionCount": 137959,
				"voluemPrice": 11.6007
			},
			{
				"open": 11.8964,
				"close": 12.0664,
				"volume": 419147652,
				"time": 1303185600000,
				"lowest": 11.8661,
				"highest": 12.0707,
				"volumePrice": 11.9552,
				"transactionCount": 94033,
				"voluemPrice": 11.9552
			},
			{
				"open": 12.2682,
				"close": 12.2289,
				"volume": 698550244,
				"time": 1303272000000,
				"lowest": 12.1964,
				"highest": 12.3482,
				"volumePrice": 12.338,
				"transactionCount": 148914,
				"voluemPrice": 12.338
			},
			{
				"open": 12.6786,
				"close": 12.5249,
				"volume": 753798472,
				"time": 1303358400000,
				"lowest": 12.4471,
				"highest": 12.6832,
				"volumePrice": 12.5738,
				"transactionCount": 160347,
				"voluemPrice": 12.5738
			},
			{
				"open": 12.5121,
				"close": 12.6075,
				"volume": 266545160,
				"time": 1303704000000,
				"lowest": 12.5107,
				"highest": 12.6339,
				"volumePrice": 12.5848,
				"transactionCount": 56525,
				"voluemPrice": 12.5848
			},
			{
				"open": 12.6293,
				"close": 12.515,
				"volume": 337602580,
				"time": 1303790400000,
				"lowest": 12.4768,
				"highest": 12.6782,
				"volumePrice": 12.5527,
				"transactionCount": 68553,
				"voluemPrice": 12.5527
			},
			{
				"open": 12.58,
				"close": 12.5054,
				"volume": 356208748,
				"time": 1303876800000,
				"lowest": 12.3964,
				"highest": 12.5839,
				"volumePrice": 12.488,
				"transactionCount": 75859,
				"voluemPrice": 12.488
			},
			{
				"open": 12.3639,
				"close": 12.3839,
				"volume": 361188492,
				"time": 1303963200000,
				"lowest": 12.34,
				"highest": 12.4911,
				"volumePrice": 12.4008,
				"transactionCount": 74728,
				"voluemPrice": 12.4008
			},
			{
				"open": 12.385,
				"close": 12.5046,
				"volume": 1005363716,
				"time": 1304049600000,
				"lowest": 12.3809,
				"highest": 12.6411,
				"volumePrice": 12.4957,
				"transactionCount": 120383,
				"voluemPrice": 12.4957
			},
			{
				"open": 12.4907,
				"close": 12.3671,
				"volume": 442712984,
				"time": 1304308800000,
				"lowest": 12.3393,
				"highest": 12.5168,
				"volumePrice": 12.4242,
				"transactionCount": 91144,
				"voluemPrice": 12.4242
			},
			{
				"open": 12.4282,
				"close": 12.4357,
				"volume": 313560996,
				"time": 1304395200000,
				"lowest": 12.3436,
				"highest": 12.4961,
				"volumePrice": 12.4293,
				"transactionCount": 67940,
				"voluemPrice": 12.4293
			},
			{
				"open": 12.4379,
				"close": 12.4846,
				"volume": 389248916,
				"time": 1304481600000,
				"lowest": 12.3886,
				"highest": 12.5654,
				"volumePrice": 12.4774,
				"transactionCount": 82184,
				"voluemPrice": 12.4774
			},
			{
				"open": 12.4429,
				"close": 12.3839,
				"volume": 336261408,
				"time": 1304568000000,
				"lowest": 12.3589,
				"highest": 12.5339,
				"volumePrice": 12.4532,
				"transactionCount": 73501,
				"voluemPrice": 12.4532
			},
			{
				"open": 12.4889,
				"close": 12.3807,
				"volume": 280245140,
				"time": 1304654400000,
				"lowest": 12.3646,
				"highest": 12.5,
				"volumePrice": 12.4379,
				"transactionCount": 61062,
				"voluemPrice": 12.4379
			},
			{
				"open": 12.4236,
				"close": 12.4143,
				"volume": 204752604,
				"time": 1304913600000,
				"lowest": 12.376,
				"highest": 12.4714,
				"volumePrice": 12.4243,
				"transactionCount": 45938,
				"voluemPrice": 12.4243
			},
			{
				"open": 12.4604,
				"close": 12.4804,
				"volume": 282087876,
				"time": 1305000000000,
				"lowest": 12.3807,
				"highest": 12.4889,
				"volumePrice": 12.4276,
				"transactionCount": 59783,
				"voluemPrice": 12.4276
			},
			{
				"open": 12.465,
				"close": 12.4011,
				"volume": 336069132,
				"time": 1305086400000,
				"lowest": 12.33,
				"highest": 12.5,
				"volumePrice": 12.414,
				"transactionCount": 72786,
				"voluemPrice": 12.414
			},
			{
				"open": 12.3614,
				"close": 12.3775,
				"volume": 320958372,
				"time": 1305172800000,
				"lowest": 12.2239,
				"highest": 12.3971,
				"volumePrice": 12.3219,
				"transactionCount": 66689,
				"voluemPrice": 12.3219
			},
			{
				"open": 12.345,
				"close": 12.1607,
				"volume": 326191824,
				"time": 1305259200000,
				"lowest": 12.1554,
				"highest": 12.3661,
				"volumePrice": 12.2468,
				"transactionCount": 74373,
				"voluemPrice": 12.2468
			},
			{
				"open": 12.1143,
				"close": 11.9036,
				"volume": 450137576,
				"time": 1305518400000,
				"lowest": 11.8786,
				"highest": 12.1864,
				"volumePrice": 12.0131,
				"transactionCount": 97260,
				"voluemPrice": 12.0131
			},
			{
				"open": 11.8571,
				"close": 12.005,
				"volume": 452782316,
				"time": 1305604800000,
				"lowest": 11.8118,
				"highest": 12.005,
				"volumePrice": 11.8964,
				"transactionCount": 97747,
				"voluemPrice": 11.8964
			},
			{
				"open": 12.0168,
				"close": 12.1382,
				"volume": 334773068,
				"time": 1305691200000,
				"lowest": 12,
				"highest": 12.1804,
				"volumePrice": 12.1155,
				"transactionCount": 69503,
				"voluemPrice": 12.1155
			},
			{
				"open": 12.2171,
				"close": 12.1618,
				"volume": 261165240,
				"time": 1305777600000,
				"lowest": 12.0954,
				"highest": 12.2291,
				"volumePrice": 12.1664,
				"transactionCount": 56668,
				"voluemPrice": 12.1664
			},
			{
				"open": 12.1271,
				"close": 11.9721,
				"volume": 338190832,
				"time": 1305864000000,
				"lowest": 11.965,
				"highest": 12.1768,
				"volumePrice": 12.0617,
				"transactionCount": 71422,
				"voluemPrice": 12.0617
			},
			{
				"open": 11.7846,
				"close": 11.9429,
				"volume": 382508868,
				"time": 1306123200000,
				"lowest": 11.765,
				"highest": 11.9993,
				"volumePrice": 11.8825,
				"transactionCount": 84521,
				"voluemPrice": 11.8825
			},
			{
				"open": 11.9821,
				"close": 11.8639,
				"volume": 322082600,
				"time": 1306209600000,
				"lowest": 11.8336,
				"highest": 11.9964,
				"volumePrice": 11.9,
				"transactionCount": 68578,
				"voluemPrice": 11.9
			},
			{
				"open": 11.9082,
				"close": 12.0279,
				"volume": 294199332,
				"time": 1306296000000,
				"lowest": 11.8875,
				"highest": 12.0914,
				"volumePrice": 11.9994,
				"transactionCount": 60929,
				"voluemPrice": 11.9994
			},
			{
				"open": 11.9989,
				"close": 11.9643,
				"volume": 222695004,
				"time": 1306382400000,
				"lowest": 11.9441,
				"highest": 12.0318,
				"volumePrice": 11.9806,
				"transactionCount": 50215,
				"voluemPrice": 11.9806
			},
			{
				"open": 11.9571,
				"close": 12.0504,
				"volume": 203651588,
				"time": 1306468800000,
				"lowest": 11.9396,
				"highest": 12.0582,
				"volumePrice": 12.0096,
				"transactionCount": 45578,
				"voluemPrice": 12.0096
			},
			{
				"open": 12.1821,
				"close": 12.4225,
				"volume": 417736536,
				"time": 1306814400000,
				"lowest": 12.1786,
				"highest": 12.4225,
				"volumePrice": 12.3039,
				"transactionCount": 82858,
				"voluemPrice": 12.3039
			},
			{
				"open": 12.4596,
				"close": 12.3396,
				"volume": 554682408,
				"time": 1306900800000,
				"lowest": 12.3089,
				"highest": 12.5761,
				"volumePrice": 12.4449,
				"transactionCount": 114027,
				"voluemPrice": 12.4449
			},
			{
				"open": 12.375,
				"close": 12.3608,
				"volume": 338884448,
				"time": 1306987200000,
				"lowest": 12.2964,
				"highest": 12.4279,
				"volumePrice": 12.3713,
				"transactionCount": 71755,
				"voluemPrice": 12.3713
			},
			{
				"open": 12.2564,
				"close": 12.2657,
				"volume": 313443060,
				"time": 1307073600000,
				"lowest": 12.2146,
				"highest": 12.3332,
				"volumePrice": 12.2764,
				"transactionCount": 68317,
				"voluemPrice": 12.2764
			},
			{
				"open": 12.3464,
				"close": 12.0729,
				"volume": 461927508,
				"time": 1307332800000,
				"lowest": 12.0646,
				"highest": 12.3946,
				"volumePrice": 12.2223,
				"transactionCount": 100807,
				"voluemPrice": 12.2223
			},
			{
				"open": 12.0775,
				"close": 11.8586,
				"volume": 529972240,
				"time": 1307419200000,
				"lowest": 11.8536,
				"highest": 12.0793,
				"volumePrice": 11.9365,
				"transactionCount": 109597,
				"voluemPrice": 11.9365
			},
			{
				"open": 11.8493,
				"close": 11.8657,
				"volume": 333886308,
				"time": 1307505600000,
				"lowest": 11.8089,
				"highest": 11.9571,
				"volumePrice": 11.8775,
				"transactionCount": 71853,
				"voluemPrice": 11.8775
			},
			{
				"open": 11.9018,
				"close": 11.8389,
				"volume": 275364796,
				"time": 1307592000000,
				"lowest": 11.8125,
				"highest": 11.9168,
				"volumePrice": 11.8581,
				"transactionCount": 60682,
				"voluemPrice": 11.8581
			},
			{
				"open": 11.8054,
				"close": 11.6393,
				"volume": 434103600,
				"time": 1307678400000,
				"lowest": 11.6254,
				"highest": 11.845,
				"volumePrice": 11.7184,
				"transactionCount": 92091,
				"voluemPrice": 11.7184
			},
			{
				"open": 11.6857,
				"close": 11.6643,
				"volume": 329652792,
				"time": 1307937600000,
				"lowest": 11.6096,
				"highest": 11.7254,
				"volumePrice": 11.67,
				"transactionCount": 70573,
				"voluemPrice": 11.67
			},
			{
				"open": 11.7857,
				"close": 11.8729,
				"volume": 334567604,
				"time": 1308024000000,
				"lowest": 11.7611,
				"highest": 11.9018,
				"volumePrice": 11.8438,
				"transactionCount": 69930,
				"voluemPrice": 11.8438
			},
			{
				"open": 11.7768,
				"close": 11.6696,
				"volume": 399400904,
				"time": 1308110400000,
				"lowest": 11.6029,
				"highest": 11.7964,
				"volumePrice": 11.689,
				"transactionCount": 86377,
				"voluemPrice": 11.689
			},
			{
				"open": 11.675,
				"close": 11.6129,
				"volume": 509921916,
				"time": 1308196800000,
				"lowest": 11.3689,
				"highest": 11.7386,
				"volumePrice": 11.5838,
				"transactionCount": 110851,
				"voluemPrice": 11.5838
			},
			{
				"open": 11.7496,
				"close": 11.4379,
				"volume": 615490596,
				"time": 1308283200000,
				"lowest": 11.4057,
				"highest": 11.7589,
				"volumePrice": 11.5252,
				"transactionCount": 117293,
				"voluemPrice": 11.5252
			},
			{
				"open": 11.3341,
				"close": 11.2614,
				"volume": 639698696,
				"time": 1308542400000,
				"lowest": 11.0893,
				"highest": 11.3464,
				"volumePrice": 11.2364,
				"transactionCount": 135843,
				"voluemPrice": 11.2364
			},
			{
				"open": 11.31,
				"close": 11.6179,
				"volume": 493374056,
				"time": 1308628800000,
				"lowest": 11.2571,
				"highest": 11.6357,
				"volumePrice": 11.5072,
				"transactionCount": 107470,
				"voluemPrice": 11.5072
			},
			{
				"open": 11.6129,
				"close": 11.5218,
				"volume": 390567744,
				"time": 1308715200000,
				"lowest": 11.5136,
				"highest": 11.7464,
				"volumePrice": 11.6432,
				"transactionCount": 86495,
				"voluemPrice": 11.6432
			},
			{
				"open": 11.3907,
				"close": 11.8296,
				"volume": 559757016,
				"time": 1308801600000,
				"lowest": 11.3614,
				"highest": 11.8461,
				"volumePrice": 11.6279,
				"transactionCount": 120873,
				"voluemPrice": 11.6279
			},
			{
				"open": 11.8346,
				"close": 11.6554,
				"volume": 439921552,
				"time": 1308888000000,
				"lowest": 11.6104,
				"highest": 11.8982,
				"volumePrice": 11.7301,
				"transactionCount": 91614,
				"voluemPrice": 11.7301
			},
			{
				"open": 11.6996,
				"close": 11.8586,
				"volume": 339959732,
				"time": 1309147200000,
				"lowest": 11.6875,
				"highest": 11.925,
				"volumePrice": 11.8402,
				"transactionCount": 68514,
				"voluemPrice": 11.8402
			},
			{
				"open": 11.9161,
				"close": 11.9736,
				"volume": 294299628,
				"time": 1309233600000,
				"lowest": 11.9086,
				"highest": 12.025,
				"volumePrice": 11.9719,
				"transactionCount": 63191,
				"voluemPrice": 11.9719
			},
			{
				"open": 12.0014,
				"close": 11.93,
				"volume": 352716280,
				"time": 1309320000000,
				"lowest": 11.8529,
				"highest": 12.0132,
				"volumePrice": 11.9307,
				"transactionCount": 78249,
				"voluemPrice": 11.9307
			},
			{
				"open": 11.9536,
				"close": 11.9882,
				"volume": 323229564,
				"time": 1309406400000,
				"lowest": 11.8871,
				"highest": 12.0046,
				"volumePrice": 11.9642,
				"transactionCount": 68132,
				"voluemPrice": 11.9642
			},
			{
				"open": 11.9982,
				"close": 12.2593,
				"volume": 435297408,
				"time": 1309492800000,
				"lowest": 11.9357,
				"highest": 12.2679,
				"volumePrice": 12.1444,
				"transactionCount": 91467,
				"voluemPrice": 12.1444
			},
			{
				"open": 12.25,
				"close": 12.4796,
				"volume": 355274472,
				"time": 1309838400000,
				"lowest": 12.2321,
				"highest": 12.4939,
				"volumePrice": 12.4272,
				"transactionCount": 73844,
				"voluemPrice": 12.4272
			},
			{
				"open": 12.4625,
				"close": 12.5629,
				"volume": 444624964,
				"time": 1309924800000,
				"lowest": 12.3825,
				"highest": 12.6464,
				"volumePrice": 12.5322,
				"transactionCount": 97036,
				"voluemPrice": 12.5322
			},
			{
				"open": 12.6668,
				"close": 12.7571,
				"volume": 399661276,
				"time": 1310011200000,
				"lowest": 12.6429,
				"highest": 12.7857,
				"volumePrice": 12.738,
				"transactionCount": 83401,
				"voluemPrice": 12.738
			},
			{
				"open": 12.6193,
				"close": 12.8468,
				"volume": 489926556,
				"time": 1310097600000,
				"lowest": 12.5786,
				"highest": 12.8571,
				"volumePrice": 12.7835,
				"transactionCount": 108060,
				"voluemPrice": 12.7835
			},
			{
				"open": 12.7264,
				"close": 12.6429,
				"volume": 442855896,
				"time": 1310356800000,
				"lowest": 12.6007,
				"highest": 12.8489,
				"volumePrice": 12.7054,
				"transactionCount": 97746,
				"voluemPrice": 12.7054
			},
			{
				"open": 12.6261,
				"close": 12.6339,
				"volume": 451606764,
				"time": 1310443200000,
				"lowest": 12.4507,
				"highest": 12.7743,
				"volumePrice": 12.6488,
				"transactionCount": 100065,
				"voluemPrice": 12.6488
			},
			{
				"open": 12.7975,
				"close": 12.7864,
				"volume": 391638072,
				"time": 1310529600000,
				"lowest": 12.7279,
				"highest": 12.8571,
				"volumePrice": 12.8064,
				"transactionCount": 84115,
				"voluemPrice": 12.8064
			},
			{
				"open": 12.8932,
				"close": 12.7775,
				"volume": 430532620,
				"time": 1310616000000,
				"lowest": 12.7264,
				"highest": 12.9146,
				"volumePrice": 12.8292,
				"transactionCount": 96491,
				"voluemPrice": 12.8292
			},
			{
				"open": 12.8989,
				"close": 13.0329,
				"volume": 484465352,
				"time": 1310702400000,
				"lowest": 12.8275,
				"highest": 13.0357,
				"volumePrice": 12.915,
				"transactionCount": 103324,
				"voluemPrice": 12.915
			},
			{
				"open": 13.0511,
				"close": 13.35,
				"volume": 572630492,
				"time": 1310961600000,
				"lowest": 13.0457,
				"highest": 13.3804,
				"volumePrice": 13.2106,
				"transactionCount": 123236,
				"voluemPrice": 13.2106
			},
			{
				"open": 13.5,
				"close": 13.4589,
				"volume": 819145376,
				"time": 1311048000000,
				"lowest": 13.3329,
				"highest": 13.5232,
				"volumePrice": 13.5626,
				"transactionCount": 173102,
				"voluemPrice": 13.5626
			},
			{
				"open": 14.1471,
				"close": 13.8179,
				"volume": 941271240,
				"time": 1311134400000,
				"lowest": 13.7857,
				"highest": 14.1525,
				"volumePrice": 13.9121,
				"transactionCount": 205283,
				"voluemPrice": 13.9121
			},
			{
				"open": 13.8196,
				"close": 13.8318,
				"volume": 526516144,
				"time": 1311220800000,
				"lowest": 13.7107,
				"highest": 13.9307,
				"volumePrice": 13.8337,
				"transactionCount": 110293,
				"voluemPrice": 13.8337
			},
			{
				"open": 13.8686,
				"close": 14.0464,
				"volume": 516727540,
				"time": 1311307200000,
				"lowest": 13.8482,
				"highest": 14.1089,
				"volumePrice": 13.9924,
				"transactionCount": 113777,
				"voluemPrice": 13.9924
			},
			{
				"open": 13.9411,
				"close": 14.2321,
				"volume": 589798776,
				"time": 1311566400000,
				"lowest": 13.915,
				"highest": 14.2857,
				"volumePrice": 14.1754,
				"transactionCount": 125723,
				"voluemPrice": 14.1754
			},
			{
				"open": 14.2857,
				"close": 14.4075,
				"volume": 476580104,
				"time": 1311652800000,
				"lowest": 14.2743,
				"highest": 14.4464,
				"volumePrice": 14.3848,
				"transactionCount": 99580,
				"voluemPrice": 14.3848
			},
			{
				"open": 14.3068,
				"close": 14.0211,
				"volume": 659321880,
				"time": 1311739200000,
				"lowest": 14.0054,
				"highest": 14.38,
				"volumePrice": 14.1366,
				"transactionCount": 141257,
				"voluemPrice": 14.1366
			},
			{
				"open": 13.9864,
				"close": 13.9936,
				"volume": 594024368,
				"time": 1311825600000,
				"lowest": 13.8618,
				"highest": 14.1782,
				"volumePrice": 14.0425,
				"transactionCount": 124754,
				"voluemPrice": 14.0425
			},
			{
				"open": 13.8443,
				"close": 13.9457,
				"volume": 632583560,
				"time": 1311912000000,
				"lowest": 13.7143,
				"highest": 14.1125,
				"volumePrice": 13.9679,
				"transactionCount": 139093,
				"voluemPrice": 13.9679
			},
			{
				"open": 14.2064,
				"close": 14.1696,
				"volume": 612828048,
				"time": 1312171200000,
				"lowest": 14.0132,
				"highest": 14.2679,
				"volumePrice": 14.1219,
				"transactionCount": 132067,
				"voluemPrice": 14.1219
			},
			{
				"open": 14.2018,
				"close": 13.8896,
				"volume": 639539040,
				"time": 1312257600000,
				"lowest": 13.8696,
				"highest": 14.2107,
				"volumePrice": 14.0482,
				"transactionCount": 140479,
				"voluemPrice": 14.0482
			},
			{
				"open": 13.9636,
				"close": 14.0204,
				"volume": 732503184,
				"time": 1312344000000,
				"lowest": 13.6514,
				"highest": 14.0554,
				"volumePrice": 13.907,
				"transactionCount": 163844,
				"voluemPrice": 13.907
			},
			{
				"open": 13.9075,
				"close": 13.4775,
				"volume": 871405696,
				"time": 1312430400000,
				"lowest": 13.4768,
				"highest": 13.9757,
				"volumePrice": 13.7293,
				"transactionCount": 194643,
				"voluemPrice": 13.7293
			},
			{
				"open": 13.5871,
				"close": 13.3436,
				"volume": 1204382760,
				"time": 1312516800000,
				"lowest": 12.9489,
				"highest": 13.6964,
				"volumePrice": 13.3698,
				"transactionCount": 260524,
				"voluemPrice": 13.3698
			},
			{
				"open": 12.9175,
				"close": 12.6146,
				"volume": 1143779616,
				"time": 1312776000000,
				"lowest": 12.6079,
				"highest": 13.1346,
				"volumePrice": 12.8964,
				"transactionCount": 247335,
				"voluemPrice": 12.8964
			},
			{
				"open": 12.9036,
				"close": 13.3575,
				"volume": 1082568396,
				"time": 1312862400000,
				"lowest": 12.6786,
				"highest": 13.3789,
				"volumePrice": 13.0372,
				"transactionCount": 240080,
				"voluemPrice": 13.0372
			},
			{
				"open": 13.2554,
				"close": 12.9889,
				"volume": 878649184,
				"time": 1312948800000,
				"lowest": 12.9464,
				"highest": 13.3804,
				"volumePrice": 13.1544,
				"transactionCount": 195808,
				"voluemPrice": 13.1544
			},
			{
				"open": 13.2329,
				"close": 13.3464,
				"volume": 741964440,
				"time": 1313035200000,
				"lowest": 13.0257,
				"highest": 13.4089,
				"volumePrice": 13.2704,
				"transactionCount": 160215,
				"voluemPrice": 13.2704
			},
			{
				"open": 13.5025,
				"close": 13.4639,
				"volume": 528973620,
				"time": 1313121600000,
				"lowest": 13.3654,
				"highest": 13.5586,
				"volumePrice": 13.4729,
				"transactionCount": 117656,
				"voluemPrice": 13.4729
			},
			{
				"open": 13.5582,
				"close": 13.6932,
				"volume": 460550272,
				"time": 1313380800000,
				"lowest": 13.5032,
				"highest": 13.7489,
				"volumePrice": 13.6663,
				"transactionCount": 102699,
				"voluemPrice": 13.6663
			},
			{
				"open": 13.6257,
				"close": 13.5886,
				"volume": 498888488,
				"time": 1313467200000,
				"lowest": 13.4307,
				"highest": 13.6918,
				"volumePrice": 13.5804,
				"transactionCount": 108020,
				"voluemPrice": 13.5804
			},
			{
				"open": 13.6539,
				"close": 13.5871,
				"volume": 442059772,
				"time": 1313553600000,
				"lowest": 13.5,
				"highest": 13.7329,
				"volumePrice": 13.6046,
				"transactionCount": 96463,
				"voluemPrice": 13.6046
			},
			{
				"open": 13.2443,
				"close": 13.0732,
				"volume": 851433156,
				"time": 1313640000000,
				"lowest": 12.9061,
				"highest": 13.3089,
				"volumePrice": 13.0998,
				"transactionCount": 189606,
				"voluemPrice": 13.0998
			},
			{
				"open": 12.9346,
				"close": 12.7154,
				"volume": 775739384,
				"time": 1313726400000,
				"lowest": 12.7143,
				"highest": 13.1071,
				"volumePrice": 12.9098,
				"transactionCount": 166121,
				"voluemPrice": 12.9098
			},
			{
				"open": 13.0182,
				"close": 12.73,
				"volume": 535304840,
				"time": 1313985600000,
				"lowest": 12.6818,
				"highest": 13.0314,
				"volumePrice": 12.8209,
				"transactionCount": 118982,
				"voluemPrice": 12.8209
			},
			{
				"open": 12.8679,
				"close": 13.3429,
				"volume": 656834304,
				"time": 1314072000000,
				"lowest": 12.75,
				"highest": 13.3443,
				"volumePrice": 13.0957,
				"transactionCount": 147586,
				"voluemPrice": 13.0957
			},
			{
				"open": 13.3382,
				"close": 13.435,
				"volume": 626267628,
				"time": 1314158400000,
				"lowest": 13.2357,
				"highest": 13.5343,
				"volumePrice": 13.3376,
				"transactionCount": 137644,
				"voluemPrice": 13.3376
			},
			{
				"open": 13.0386,
				"close": 13.3471,
				"volume": 871300304,
				"time": 1314244800000,
				"lowest": 13.0357,
				"highest": 13.4089,
				"volumePrice": 13.2508,
				"transactionCount": 190529,
				"voluemPrice": 13.2508
			},
			{
				"open": 13.2561,
				"close": 13.6993,
				"volume": 641475044,
				"time": 1314331200000,
				"lowest": 13.2429,
				"highest": 13.7071,
				"volumePrice": 13.5588,
				"transactionCount": 141190,
				"voluemPrice": 13.5588
			},
			{
				"open": 13.8636,
				"close": 13.9275,
				"volume": 405252596,
				"time": 1314590400000,
				"lowest": 13.8571,
				"highest": 13.9821,
				"volumePrice": 13.9253,
				"transactionCount": 88068,
				"voluemPrice": 13.9253
			},
			{
				"open": 13.8661,
				"close": 13.9282,
				"volume": 417921056,
				"time": 1314676800000,
				"lowest": 13.7932,
				"highest": 13.9943,
				"volumePrice": 13.9025,
				"transactionCount": 96977,
				"voluemPrice": 13.9025
			},
			{
				"open": 13.9489,
				"close": 13.7439,
				"volume": 522755576,
				"time": 1314763200000,
				"lowest": 13.6379,
				"highest": 14.0029,
				"volumePrice": 13.8009,
				"transactionCount": 111331,
				"voluemPrice": 13.8009
			},
			{
				"open": 13.7793,
				"close": 13.6082,
				"volume": 343719012,
				"time": 1314849600000,
				"lowest": 13.5971,
				"highest": 13.8336,
				"volumePrice": 13.7079,
				"transactionCount": 78643,
				"voluemPrice": 13.7079
			},
			{
				"open": 13.3836,
				"close": 13.3589,
				"volume": 439166056,
				"time": 1314936000000,
				"lowest": 13.2796,
				"highest": 13.5,
				"volumePrice": 13.388,
				"transactionCount": 98792,
				"voluemPrice": 13.388
			},
			{
				"open": 13.1204,
				"close": 13.5621,
				"volume": 509696516,
				"time": 1315281600000,
				"lowest": 13.0886,
				"highest": 13.5832,
				"volumePrice": 13.3525,
				"transactionCount": 106841,
				"voluemPrice": 13.3525
			},
			{
				"open": 13.77,
				"close": 13.7118,
				"volume": 350575176,
				"time": 1315368000000,
				"lowest": 13.6429,
				"highest": 13.7714,
				"volumePrice": 13.7098,
				"transactionCount": 74861,
				"voluemPrice": 13.7098
			},
			{
				"open": 13.6571,
				"close": 13.7193,
				"volume": 416157840,
				"time": 1315454400000,
				"lowest": 13.6539,
				"highest": 13.8789,
				"volumePrice": 13.7627,
				"transactionCount": 91955,
				"voluemPrice": 13.7627
			},
			{
				"open": 13.7118,
				"close": 13.4814,
				"volume": 565077632,
				"time": 1315540800000,
				"lowest": 13.3936,
				"highest": 13.7857,
				"volumePrice": 13.5716,
				"transactionCount": 134847,
				"voluemPrice": 13.5716
			},
			{
				"open": 13.3214,
				"close": 13.5693,
				"volume": 467831952,
				"time": 1315800000000,
				"lowest": 13.2821,
				"highest": 13.6029,
				"volumePrice": 13.4402,
				"transactionCount": 108921,
				"voluemPrice": 13.4402
			},
			{
				"open": 13.6479,
				"close": 13.7364,
				"volume": 440737948,
				"time": 1315886400000,
				"lowest": 13.5804,
				"highest": 13.7932,
				"volumePrice": 13.6975,
				"transactionCount": 101965,
				"voluemPrice": 13.6975
			},
			{
				"open": 13.8221,
				"close": 13.9036,
				"volume": 534723392,
				"time": 1315972800000,
				"lowest": 13.7771,
				"highest": 14.0075,
				"volumePrice": 13.9102,
				"transactionCount": 118642,
				"voluemPrice": 13.9102
			},
			{
				"open": 13.9796,
				"close": 14.0343,
				"volume": 417818324,
				"time": 1316059200000,
				"lowest": 13.925,
				"highest": 14.0593,
				"volumePrice": 14.0086,
				"transactionCount": 91168,
				"voluemPrice": 14.0086
			},
			{
				"open": 14.1264,
				"close": 14.3036,
				"volume": 691488644,
				"time": 1316145600000,
				"lowest": 14.1082,
				"highest": 14.3037,
				"volumePrice": 14.2411,
				"transactionCount": 121552,
				"voluemPrice": 14.2411
			},
			{
				"open": 14.1786,
				"close": 14.7011,
				"volume": 823859120,
				"time": 1316404800000,
				"lowest": 14.1144,
				"highest": 14.7582,
				"volumePrice": 14.5141,
				"transactionCount": 179599,
				"voluemPrice": 14.5141
			},
			{
				"open": 14.8304,
				"close": 14.7661,
				"volume": 775749156,
				"time": 1316491200000,
				"lowest": 14.6854,
				"highest": 15.1021,
				"volumePrice": 14.9216,
				"transactionCount": 164306,
				"voluemPrice": 14.9216
			},
			{
				"open": 14.9871,
				"close": 14.7193,
				"volume": 605975020,
				"time": 1316577600000,
				"lowest": 14.7143,
				"highest": 15.0568,
				"volumePrice": 14.9251,
				"transactionCount": 129819,
				"voluemPrice": 14.9251
			},
			{
				"open": 14.3225,
				"close": 14.3507,
				"volume": 968480548,
				"time": 1316664000000,
				"lowest": 14.1679,
				"highest": 14.6364,
				"volumePrice": 14.3926,
				"transactionCount": 208998,
				"voluemPrice": 14.3926
			},
			{
				"open": 14.2957,
				"close": 14.4393,
				"volume": 546474040,
				"time": 1316750400000,
				"lowest": 14.2804,
				"highest": 14.5264,
				"volumePrice": 14.4374,
				"transactionCount": 118707,
				"voluemPrice": 14.4374
			},
			{
				"open": 14.2807,
				"close": 14.3989,
				"volume": 812875448,
				"time": 1317009600000,
				"lowest": 13.975,
				"highest": 14.4279,
				"volumePrice": 14.2042,
				"transactionCount": 165119,
				"voluemPrice": 14.2042
			},
			{
				"open": 14.5975,
				"close": 14.2593,
				"volume": 632491888,
				"time": 1317096000000,
				"lowest": 14.2164,
				"highest": 14.6161,
				"volumePrice": 14.4502,
				"transactionCount": 138171,
				"voluemPrice": 14.4502
			},
			{
				"open": 14.2925,
				"close": 14.1789,
				"volume": 429852724,
				"time": 1317182400000,
				"lowest": 14.1611,
				"highest": 14.4193,
				"volumePrice": 14.3044,
				"transactionCount": 94347,
				"voluemPrice": 14.3044
			},
			{
				"open": 14.3543,
				"close": 13.9489,
				"volume": 651364588,
				"time": 1317268800000,
				"lowest": 13.7932,
				"highest": 14.3646,
				"volumePrice": 14.0176,
				"transactionCount": 140027,
				"voluemPrice": 14.0176
			},
			{
				"open": 13.8257,
				"close": 13.6186,
				"volume": 548236892,
				"time": 1317355200000,
				"lowest": 13.6136,
				"highest": 13.8889,
				"volumePrice": 13.751,
				"transactionCount": 118756,
				"voluemPrice": 13.751
			},
			{
				"open": 13.5846,
				"close": 13.3786,
				"volume": 669076044,
				"time": 1317614400000,
				"lowest": 13.3275,
				"highest": 13.6657,
				"volumePrice": 13.4849,
				"transactionCount": 143439,
				"voluemPrice": 13.4849
			},
			{
				"open": 13.3775,
				"close": 13.3036,
				"volume": 1233665888,
				"time": 1317700800000,
				"lowest": 12.6514,
				"highest": 13.6357,
				"volumePrice": 13.1657,
				"transactionCount": 266165,
				"voluemPrice": 13.1657
			},
			{
				"open": 13.1379,
				"close": 13.5089,
				"volume": 786458232,
				"time": 1317787200000,
				"lowest": 12.8679,
				"highest": 13.565,
				"volumePrice": 13.2792,
				"transactionCount": 171545,
				"voluemPrice": 13.2792
			},
			{
				"open": 13.3332,
				"close": 13.4775,
				"volume": 812580216,
				"time": 1317873600000,
				"lowest": 13.2787,
				"highest": 13.7421,
				"volumePrice": 13.4957,
				"transactionCount": 179217,
				"voluemPrice": 13.4957
			},
			{
				"open": 13.4207,
				"close": 13.2071,
				"volume": 535431008,
				"time": 1317960000000,
				"lowest": 13.1603,
				"highest": 13.4907,
				"volumePrice": 13.2958,
				"transactionCount": 122020,
				"voluemPrice": 13.2958
			},
			{
				"open": 13.5389,
				"close": 13.8861,
				"volume": 442513148,
				"time": 1318219200000,
				"lowest": 13.5075,
				"highest": 13.8861,
				"volumePrice": 13.7009,
				"transactionCount": 94742,
				"voluemPrice": 13.7009
			},
			{
				"open": 14.0204,
				"close": 14.2961,
				"volume": 605672060,
				"time": 1318305600000,
				"lowest": 13.9821,
				"highest": 14.3993,
				"volumePrice": 14.2416,
				"transactionCount": 132807,
				"voluemPrice": 14.2416
			},
			{
				"open": 14.5479,
				"close": 14.3639,
				"volume": 616890288,
				"time": 1318392000000,
				"lowest": 14.2907,
				"highest": 14.6161,
				"volumePrice": 14.4849,
				"transactionCount": 132379,
				"voluemPrice": 14.4849
			},
			{
				"open": 14.4636,
				"close": 14.5868,
				"volume": 426183100,
				"time": 1318478400000,
				"lowest": 14.3875,
				"highest": 14.5868,
				"volumePrice": 14.5074,
				"transactionCount": 87027,
				"voluemPrice": 14.5074
			},
			{
				"open": 14.8868,
				"close": 15.0714,
				"volume": 573366920,
				"time": 1318564800000,
				"lowest": 14.8311,
				"highest": 15.0714,
				"volumePrice": 14.9395,
				"transactionCount": 119131,
				"voluemPrice": 14.9395
			},
			{
				"open": 15.0621,
				"close": 14.9996,
				"volume": 686042784,
				"time": 1318824000000,
				"lowest": 14.855,
				"highest": 15.2393,
				"volumePrice": 15.0466,
				"transactionCount": 141407,
				"voluemPrice": 15.0466
			},
			{
				"open": 15.0629,
				"close": 15.08,
				"volume": 881600384,
				"time": 1318910400000,
				"lowest": 14.8568,
				"highest": 15.1717,
				"volumePrice": 14.8456,
				"transactionCount": 187804,
				"voluemPrice": 14.8456
			},
			{
				"open": 14.3339,
				"close": 14.2364,
				"volume": 1103992372,
				"time": 1318996800000,
				"lowest": 14.2071,
				"highest": 14.5864,
				"volumePrice": 14.3718,
				"transactionCount": 240225,
				"voluemPrice": 14.3718
			},
			{
				"open": 14.2857,
				"close": 14.1182,
				"volume": 549529176,
				"time": 1319083200000,
				"lowest": 14.0789,
				"highest": 14.2982,
				"volumePrice": 14.1662,
				"transactionCount": 117207,
				"voluemPrice": 14.1662
			},
			{
				"open": 14.2179,
				"close": 14.0311,
				"volume": 621223400,
				"time": 1319169600000,
				"lowest": 13.9554,
				"highest": 14.255,
				"volumePrice": 14.1032,
				"transactionCount": 126926,
				"voluemPrice": 14.1032
			},
			{
				"open": 14.1493,
				"close": 14.4918,
				"volume": 502356176,
				"time": 1319428800000,
				"lowest": 14.1214,
				"highest": 14.5179,
				"volumePrice": 14.394,
				"transactionCount": 102793,
				"voluemPrice": 14.394
			},
			{
				"open": 14.4654,
				"close": 14.2061,
				"volume": 430600800,
				"time": 1319515200000,
				"lowest": 14.1921,
				"highest": 14.5196,
				"volumePrice": 14.3166,
				"transactionCount": 92589,
				"voluemPrice": 14.3166
			},
			{
				"open": 14.3486,
				"close": 14.3071,
				"volume": 456299928,
				"time": 1319601600000,
				"lowest": 14.0411,
				"highest": 14.3768,
				"volumePrice": 14.2395,
				"transactionCount": 92611,
				"voluemPrice": 14.2395
			},
			{
				"open": 14.5557,
				"close": 14.4532,
				"volume": 494849320,
				"time": 1319688000000,
				"lowest": 14.3532,
				"highest": 14.6071,
				"volumePrice": 14.4881,
				"transactionCount": 103544,
				"voluemPrice": 14.4881
			},
			{
				"open": 14.3929,
				"close": 14.4625,
				"volume": 322838992,
				"time": 1319774400000,
				"lowest": 14.3754,
				"highest": 14.5125,
				"volumePrice": 14.4446,
				"transactionCount": 65572,
				"voluemPrice": 14.4446
			},
			{
				"open": 14.3721,
				"close": 14.4564,
				"volume": 385625212,
				"time": 1320033600000,
				"lowest": 14.3232,
				"highest": 14.6189,
				"volumePrice": 14.4961,
				"transactionCount": 79929,
				"voluemPrice": 14.4961
			},
			{
				"open": 14.1932,
				"close": 14.1611,
				"volume": 531781908,
				"time": 1320120000000,
				"lowest": 14.0436,
				"highest": 14.2679,
				"volumePrice": 14.1771,
				"transactionCount": 118170,
				"voluemPrice": 14.1771
			},
			{
				"open": 14.2889,
				"close": 14.1932,
				"volume": 328254668,
				"time": 1320206400000,
				"lowest": 14.1111,
				"highest": 14.3014,
				"volumePrice": 14.1927,
				"transactionCount": 68667,
				"voluemPrice": 14.1927
			},
			{
				"open": 14.2525,
				"close": 14.3954,
				"volume": 441524300,
				"time": 1320292800000,
				"lowest": 14.12,
				"highest": 14.4071,
				"volumePrice": 14.2716,
				"transactionCount": 93417,
				"voluemPrice": 14.2716
			},
			{
				"open": 14.3582,
				"close": 14.2943,
				"volume": 302227968,
				"time": 1320379200000,
				"lowest": 14.2557,
				"highest": 14.4086,
				"volumePrice": 14.318,
				"transactionCount": 63172,
				"voluemPrice": 14.318
			},
			{
				"open": 14.2825,
				"close": 14.2761,
				"volume": 270274396,
				"time": 1320642000000,
				"lowest": 14.1475,
				"highest": 14.2857,
				"volumePrice": 14.2199,
				"transactionCount": 60582,
				"voluemPrice": 14.2199
			},
			{
				"open": 14.3646,
				"close": 14.5082,
				"volume": 400439368,
				"time": 1320728400000,
				"lowest": 14.3414,
				"highest": 14.5714,
				"volumePrice": 14.4689,
				"transactionCount": 83791,
				"voluemPrice": 14.4689
			},
			{
				"open": 14.1775,
				"close": 14.1171,
				"volume": 558683720,
				"time": 1320814800000,
				"lowest": 14.0796,
				"highest": 14.3175,
				"volumePrice": 14.1843,
				"transactionCount": 121459,
				"voluemPrice": 14.1843
			},
			{
				"open": 14.1796,
				"close": 13.7579,
				"volume": 744972676,
				"time": 1320901200000,
				"lowest": 13.6482,
				"highest": 14.1861,
				"volumePrice": 13.8031,
				"transactionCount": 163268,
				"voluemPrice": 13.8031
			},
			{
				"open": 13.8075,
				"close": 13.7364,
				"volume": 653784068,
				"time": 1320987600000,
				"lowest": 13.5807,
				"highest": 13.8821,
				"volumePrice": 13.7199,
				"transactionCount": 136391,
				"voluemPrice": 13.7199
			},
			{
				"open": 13.6971,
				"close": 13.545,
				"volume": 433056428,
				"time": 1321246800000,
				"lowest": 13.5071,
				"highest": 13.7589,
				"volumePrice": 13.6017,
				"transactionCount": 87326,
				"voluemPrice": 13.6017
			},
			{
				"open": 13.6,
				"close": 13.8868,
				"volume": 430979248,
				"time": 1321333200000,
				"lowest": 13.5518,
				"highest": 13.9107,
				"volumePrice": 13.7764,
				"transactionCount": 91171,
				"voluemPrice": 13.7764
			},
			{
				"open": 13.9018,
				"close": 13.7418,
				"volume": 349208048,
				"time": 1321419600000,
				"lowest": 13.7257,
				"highest": 13.9693,
				"volumePrice": 13.8455,
				"transactionCount": 72213,
				"voluemPrice": 13.8455
			},
			{
				"open": 13.7136,
				"close": 13.4789,
				"volume": 480120592,
				"time": 1321506000000,
				"lowest": 13.4107,
				"highest": 13.735,
				"volumePrice": 13.5416,
				"transactionCount": 102549,
				"voluemPrice": 13.5416
			},
			{
				"open": 13.5329,
				"close": 13.3907,
				"volume": 372075060,
				"time": 1321592400000,
				"lowest": 13.3886,
				"highest": 13.5711,
				"volumePrice": 13.4685,
				"transactionCount": 77316,
				"voluemPrice": 13.4685
			},
			{
				"open": 13.2286,
				"close": 13.1789,
				"volume": 448202356,
				"time": 1321851600000,
				"lowest": 13.0682,
				"highest": 13.2743,
				"volumePrice": 13.1541,
				"transactionCount": 96289,
				"voluemPrice": 13.1541
			},
			{
				"open": 13.2507,
				"close": 13.4468,
				"volume": 409297364,
				"time": 1321938000000,
				"lowest": 13.2479,
				"highest": 13.4975,
				"volumePrice": 13.379,
				"transactionCount": 87363,
				"voluemPrice": 13.379
			},
			{
				"open": 13.3754,
				"close": 13.1068,
				"volume": 428638000,
				"time": 1322024400000,
				"lowest": 13.1029,
				"highest": 13.4229,
				"volumePrice": 13.2004,
				"transactionCount": 91933,
				"voluemPrice": 13.2004
			},
			{
				"open": 13.1579,
				"close": 12.9846,
				"volume": 254759204,
				"time": 1322197200000,
				"lowest": 12.9757,
				"highest": 13.2554,
				"volumePrice": 13.0938,
				"transactionCount": 49734,
				"voluemPrice": 13.0938
			},
			{
				"open": 13.2982,
				"close": 13.4329,
				"volume": 346643416,
				"time": 1322456400000,
				"lowest": 13.2261,
				"highest": 13.4543,
				"volumePrice": 13.3614,
				"transactionCount": 68534,
				"voluemPrice": 13.3614
			},
			{
				"open": 13.4229,
				"close": 13.3286,
				"volume": 376031432,
				"time": 1322542800000,
				"lowest": 13.2214,
				"highest": 13.5296,
				"volumePrice": 13.3677,
				"transactionCount": 82736,
				"voluemPrice": 13.3677
			},
			{
				"open": 13.6175,
				"close": 13.65,
				"volume": 406038808,
				"time": 1322629200000,
				"lowest": 13.5107,
				"highest": 13.6527,
				"volumePrice": 13.5813,
				"transactionCount": 80067,
				"voluemPrice": 13.5813
			},
			{
				"open": 13.6621,
				"close": 13.8546,
				"volume": 387179352,
				"time": 1322715600000,
				"lowest": 13.5982,
				"highest": 13.8929,
				"volumePrice": 13.786,
				"transactionCount": 83610,
				"voluemPrice": 13.786
			},
			{
				"open": 13.9225,
				"close": 13.9179,
				"volume": 379270360,
				"time": 1322802000000,
				"lowest": 13.8779,
				"highest": 14.0582,
				"volumePrice": 13.9625,
				"transactionCount": 78492,
				"voluemPrice": 13.9625
			},
			{
				"open": 14.0532,
				"close": 14.0361,
				"volume": 357368508,
				"time": 1323061200000,
				"lowest": 13.9424,
				"highest": 14.1575,
				"volumePrice": 14.0706,
				"transactionCount": 77279,
				"voluemPrice": 14.0706
			},
			{
				"open": 14.0182,
				"close": 13.9625,
				"volume": 283804052,
				"time": 1323147600000,
				"lowest": 13.9064,
				"highest": 14.0939,
				"volumePrice": 13.992,
				"transactionCount": 61709,
				"voluemPrice": 13.992
			},
			{
				"open": 13.9261,
				"close": 13.8961,
				"volume": 304719716,
				"time": 1323234000000,
				"lowest": 13.8129,
				"highest": 13.9621,
				"volumePrice": 13.8767,
				"transactionCount": 64345,
				"voluemPrice": 13.8767
			},
			{
				"open": 13.9804,
				"close": 13.9521,
				"volume": 376356204,
				"time": 1323320400000,
				"lowest": 13.9368,
				"highest": 14.125,
				"volumePrice": 14.0394,
				"transactionCount": 88113,
				"voluemPrice": 14.0394
			},
			{
				"open": 14.0304,
				"close": 14.0579,
				"volume": 297131940,
				"time": 1323406800000,
				"lowest": 13.9654,
				"highest": 14.0729,
				"volumePrice": 14.0324,
				"transactionCount": 65495,
				"voluemPrice": 14.0324
			},
			{
				"open": 13.9886,
				"close": 13.9943,
				"volume": 301065464,
				"time": 1323666000000,
				"lowest": 13.9089,
				"highest": 14.0679,
				"volumePrice": 13.9795,
				"transactionCount": 64118,
				"voluemPrice": 13.9795
			},
			{
				"open": 14.0357,
				"close": 13.8861,
				"volume": 339136812,
				"time": 1323752400000,
				"lowest": 13.825,
				"highest": 14.1214,
				"volumePrice": 13.9959,
				"transactionCount": 73155,
				"voluemPrice": 13.9959
			},
			{
				"open": 13.8107,
				"close": 13.5782,
				"volume": 407154720,
				"time": 1323838800000,
				"lowest": 13.4886,
				"highest": 13.835,
				"volumePrice": 13.6157,
				"transactionCount": 88216,
				"voluemPrice": 13.6157
			},
			{
				"open": 13.6904,
				"close": 13.5336,
				"volume": 256448500,
				"time": 1323925200000,
				"lowest": 13.5111,
				"highest": 13.705,
				"volumePrice": 13.5793,
				"transactionCount": 56625,
				"voluemPrice": 13.5793
			},
			{
				"open": 13.5843,
				"close": 13.6079,
				"volume": 421572004,
				"time": 1324011600000,
				"lowest": 13.5561,
				"highest": 13.7196,
				"volumePrice": 13.6117,
				"transactionCount": 68320,
				"voluemPrice": 13.6117
			},
			{
				"open": 13.6596,
				"close": 13.6504,
				"volume": 235527656,
				"time": 1324270800000,
				"lowest": 13.5886,
				"highest": 13.7446,
				"volumePrice": 13.6721,
				"transactionCount": 50668,
				"voluemPrice": 13.6721
			},
			{
				"open": 13.8486,
				"close": 14.1411,
				"volume": 337402912,
				"time": 1324357200000,
				"lowest": 13.8307,
				"highest": 14.1464,
				"volumePrice": 14.0325,
				"transactionCount": 70198,
				"voluemPrice": 14.0325
			},
			{
				"open": 14.1675,
				"close": 14.1588,
				"volume": 262939712,
				"time": 1324443600000,
				"lowest": 14.0004,
				"highest": 14.1893,
				"volumePrice": 14.0974,
				"transactionCount": 56344,
				"voluemPrice": 14.0974
			},
			{
				"open": 14.1786,
				"close": 14.2339,
				"volume": 202356448,
				"time": 1324530000000,
				"lowest": 14.1464,
				"highest": 14.2546,
				"volumePrice": 14.2251,
				"transactionCount": 41632,
				"voluemPrice": 14.2251
			},
			{
				"open": 14.2746,
				"close": 14.4046,
				"volume": 269607856,
				"time": 1324616400000,
				"lowest": 14.2677,
				"highest": 14.4139,
				"volumePrice": 14.3358,
				"transactionCount": 55084,
				"voluemPrice": 14.3358
			},
			{
				"open": 14.3964,
				"close": 14.5189,
				"volume": 265200852,
				"time": 1324962000000,
				"lowest": 14.3936,
				"highest": 14.6104,
				"volumePrice": 14.5412,
				"transactionCount": 50711,
				"voluemPrice": 14.5412
			},
			{
				"open": 14.5318,
				"close": 14.38,
				"volume": 228831148,
				"time": 1325048400000,
				"lowest": 14.3336,
				"highest": 14.5804,
				"volumePrice": 14.4481,
				"transactionCount": 48915,
				"voluemPrice": 14.4481
			},
			{
				"open": 14.4071,
				"close": 14.4686,
				"volume": 216156164,
				"time": 1325134800000,
				"lowest": 14.3039,
				"highest": 14.4875,
				"volumePrice": 14.4222,
				"transactionCount": 43645,
				"voluemPrice": 14.4222
			},
			{
				"open": 14.4111,
				"close": 14.4643,
				"volume": 179774840,
				"time": 1325221200000,
				"lowest": 14.4104,
				"highest": 14.51,
				"volumePrice": 14.47,
				"transactionCount": 34884,
				"voluemPrice": 14.47
			},
			{
				"open": 14.6214,
				"close": 14.6868,
				"volume": 302253196,
				"time": 1325566800000,
				"lowest": 14.6071,
				"highest": 14.7321,
				"volumePrice": 14.6758,
				"transactionCount": 62903,
				"voluemPrice": 14.6758
			},
			{
				"open": 14.6429,
				"close": 14.7657,
				"volume": 260244432,
				"time": 1325653200000,
				"lowest": 14.6171,
				"highest": 14.81,
				"volumePrice": 14.7327,
				"transactionCount": 56155,
				"voluemPrice": 14.7327
			},
			{
				"open": 14.8196,
				"close": 14.9296,
				"volume": 271267220,
				"time": 1325739600000,
				"lowest": 14.7382,
				"highest": 14.9482,
				"volumePrice": 14.8585,
				"transactionCount": 58360,
				"voluemPrice": 14.8585
			},
			{
				"open": 14.9918,
				"close": 15.0857,
				"volume": 318385648,
				"time": 1325826000000,
				"lowest": 14.9721,
				"highest": 15.0982,
				"volumePrice": 15.0377,
				"transactionCount": 67787,
				"voluemPrice": 15.0377
			},
			{
				"open": 15.1964,
				"close": 15.0618,
				"volume": 393995168,
				"time": 1326085200000,
				"lowest": 15.0482,
				"highest": 15.2768,
				"volumePrice": 15.1659,
				"transactionCount": 80329,
				"voluemPrice": 15.1659
			},
			{
				"open": 15.2111,
				"close": 15.1157,
				"volume": 258327048,
				"time": 1326171600000,
				"lowest": 15.0536,
				"highest": 15.2143,
				"volumePrice": 15.1342,
				"transactionCount": 53758,
				"voluemPrice": 15.1342
			},
			{
				"open": 15.0957,
				"close": 15.0911,
				"volume": 215192236,
				"time": 1326258000000,
				"lowest": 14.9754,
				"highest": 15.1018,
				"volumePrice": 15.0443,
				"transactionCount": 45764,
				"voluemPrice": 15.0443
			},
			{
				"open": 15.0814,
				"close": 15.0496,
				"volume": 212708244,
				"time": 1326344400000,
				"lowest": 14.9554,
				"highest": 15.1036,
				"volumePrice": 15.0347,
				"transactionCount": 45345,
				"voluemPrice": 15.0347
			},
			{
				"open": 14.9893,
				"close": 14.9932,
				"volume": 226158996,
				"time": 1326430800000,
				"lowest": 14.9521,
				"highest": 15.0161,
				"volumePrice": 14.986,
				"transactionCount": 48689,
				"voluemPrice": 14.986
			},
			{
				"open": 15.15,
				"close": 15.1679,
				"volume": 242896220,
				"time": 1326776400000,
				"lowest": 15.1057,
				"highest": 15.2139,
				"volumePrice": 15.1718,
				"transactionCount": 50733,
				"voluemPrice": 15.1718
			},
			{
				"open": 15.2486,
				"close": 15.3254,
				"volume": 276791032,
				"time": 1326862800000,
				"lowest": 15.225,
				"highest": 15.3382,
				"volumePrice": 15.2811,
				"transactionCount": 59040,
				"voluemPrice": 15.2811
			},
			{
				"open": 15.3625,
				"close": 15.2768,
				"volume": 261717988,
				"time": 1326949200000,
				"lowest": 15.2325,
				"highest": 15.406,
				"volumePrice": 15.3229,
				"transactionCount": 55330,
				"voluemPrice": 15.3229
			},
			{
				"open": 15.2675,
				"close": 15.0107,
				"volume": 413968996,
				"time": 1327035600000,
				"lowest": 14.9911,
				"highest": 15.2679,
				"volumePrice": 15.0992,
				"transactionCount": 83166,
				"voluemPrice": 15.0992
			},
			{
				"open": 15.0954,
				"close": 15.2646,
				"volume": 306039384,
				"time": 1327294800000,
				"lowest": 15.0821,
				"highest": 15.3018,
				"volumePrice": 15.2213,
				"transactionCount": 63978,
				"voluemPrice": 15.2213
			},
			{
				"open": 15.1821,
				"close": 15.0146,
				"volume": 547633100,
				"time": 1327381200000,
				"lowest": 14.9839,
				"highest": 15.1821,
				"volumePrice": 15.4531,
				"transactionCount": 114621,
				"voluemPrice": 15.4531
			},
			{
				"open": 16.23,
				"close": 15.9521,
				"volume": 958253184,
				"time": 1327467600000,
				"lowest": 15.8475,
				"highest": 16.2304,
				"volumePrice": 16.0184,
				"transactionCount": 206885,
				"voluemPrice": 16.0184
			},
			{
				"open": 16.0129,
				"close": 15.8796,
				"volume": 324230844,
				"time": 1327554000000,
				"lowest": 15.8264,
				"highest": 16.0282,
				"volumePrice": 15.9093,
				"transactionCount": 70837,
				"voluemPrice": 15.9093
			},
			{
				"open": 15.8693,
				"close": 15.9743,
				"volume": 299880924,
				"time": 1327640400000,
				"lowest": 15.8489,
				"highest": 16.0171,
				"volumePrice": 15.9415,
				"transactionCount": 61481,
				"voluemPrice": 15.9415
			},
			{
				"open": 15.9182,
				"close": 16.1789,
				"volume": 379328292,
				"time": 1327899600000,
				"lowest": 15.9068,
				"highest": 16.2107,
				"volumePrice": 16.119,
				"transactionCount": 78403,
				"voluemPrice": 16.119
			},
			{
				"open": 16.2711,
				"close": 16.3029,
				"volume": 391908160,
				"time": 1327986000000,
				"lowest": 16.1811,
				"highest": 16.3657,
				"volumePrice": 16.2812,
				"transactionCount": 76720,
				"voluemPrice": 16.2812
			},
			{
				"open": 16.3718,
				"close": 16.2925,
				"volume": 270042248,
				"time": 1328072400000,
				"lowest": 16.2696,
				"highest": 16.3925,
				"volumePrice": 16.3127,
				"transactionCount": 59072,
				"voluemPrice": 16.3127
			},
			{
				"open": 16.2821,
				"close": 16.2543,
				"volume": 186794188,
				"time": 1328158800000,
				"lowest": 16.2136,
				"highest": 16.3275,
				"volumePrice": 16.2545,
				"transactionCount": 40265,
				"voluemPrice": 16.2545
			},
			{
				"open": 16.3321,
				"close": 16.4171,
				"volume": 286868036,
				"time": 1328245200000,
				"lowest": 16.27,
				"highest": 16.4286,
				"volumePrice": 16.3822,
				"transactionCount": 59137,
				"voluemPrice": 16.3822
			},
			{
				"open": 16.3707,
				"close": 16.5704,
				"volume": 249682384,
				"time": 1328504400000,
				"lowest": 16.3643,
				"highest": 16.6064,
				"volumePrice": 16.5267,
				"transactionCount": 50891,
				"voluemPrice": 16.5267
			},
			{
				"open": 16.6161,
				"close": 16.7439,
				"volume": 316221052,
				"time": 1328590800000,
				"lowest": 16.5922,
				"highest": 16.7768,
				"volumePrice": 16.7093,
				"transactionCount": 66911,
				"voluemPrice": 16.7093
			},
			{
				"open": 16.8036,
				"close": 17.0243,
				"volume": 407871520,
				"time": 1328677200000,
				"lowest": 16.775,
				"highest": 17.0282,
				"volumePrice": 16.9038,
				"transactionCount": 84115,
				"voluemPrice": 16.9038
			},
			{
				"open": 17.17,
				"close": 17.6132,
				"volume": 884214408,
				"time": 1328763600000,
				"lowest": 17.1629,
				"highest": 17.7411,
				"volumePrice": 17.5259,
				"transactionCount": 189994,
				"voluemPrice": 17.5259
			},
			{
				"open": 17.5343,
				"close": 17.6221,
				"volume": 631299900,
				"time": 1328850000000,
				"lowest": 17.4482,
				"highest": 17.7721,
				"volumePrice": 17.6506,
				"transactionCount": 136860,
				"voluemPrice": 17.6506
			},
			{
				"open": 17.8404,
				"close": 17.95,
				"volume": 517137824,
				"time": 1329109200000,
				"lowest": 17.7532,
				"highest": 17.9939,
				"volumePrice": 17.8775,
				"transactionCount": 110340,
				"voluemPrice": 17.8775
			},
			{
				"open": 18.0236,
				"close": 18.195,
				"volume": 461932044,
				"time": 1329195600000,
				"lowest": 17.9286,
				"highest": 18.1986,
				"volumePrice": 18.0329,
				"transactionCount": 93250,
				"voluemPrice": 18.0329
			},
			{
				"open": 18.3664,
				"close": 17.7739,
				"volume": 1506112160,
				"time": 1329282000000,
				"lowest": 17.7461,
				"highest": 18.7961,
				"volumePrice": 18.2847,
				"transactionCount": 323210,
				"voluemPrice": 18.2847
			},
			{
				"open": 17.5536,
				"close": 17.9361,
				"volume": 944469764,
				"time": 1329368400000,
				"lowest": 17.3796,
				"highest": 18.0318,
				"volumePrice": 17.7062,
				"transactionCount": 194836,
				"voluemPrice": 17.7062
			},
			{
				"open": 17.9682,
				"close": 17.9329,
				"volume": 535788764,
				"time": 1329454800000,
				"lowest": 17.8679,
				"highest": 18.1346,
				"volumePrice": 17.981,
				"transactionCount": 104134,
				"voluemPrice": 17.981
			},
			{
				"open": 18.1029,
				"close": 18.3875,
				"volume": 605585596,
				"time": 1329800400000,
				"lowest": 18.0043,
				"highest": 18.3875,
				"volumePrice": 18.2164,
				"transactionCount": 120631,
				"voluemPrice": 18.2164
			},
			{
				"open": 18.3243,
				"close": 18.3229,
				"volume": 483295232,
				"time": 1329886800000,
				"lowest": 18.1811,
				"highest": 18.4104,
				"volumePrice": 18.3148,
				"transactionCount": 101944,
				"voluemPrice": 18.3148
			},
			{
				"open": 18.3957,
				"close": 18.4425,
				"volume": 568025248,
				"time": 1329973200000,
				"lowest": 18.1964,
				"highest": 18.4939,
				"volumePrice": 18.3763,
				"transactionCount": 116822,
				"voluemPrice": 18.3763
			},
			{
				"open": 18.5596,
				"close": 18.6575,
				"volume": 415279620,
				"time": 1330059600000,
				"lowest": 18.5229,
				"highest": 18.675,
				"volumePrice": 18.5996,
				"transactionCount": 81650,
				"voluemPrice": 18.5996
			},
			{
				"open": 18.6182,
				"close": 18.7771,
				"volume": 547581216,
				"time": 1330318800000,
				"lowest": 18.4386,
				"highest": 18.875,
				"volumePrice": 18.7007,
				"transactionCount": 111319,
				"voluemPrice": 18.7007
			},
			{
				"open": 18.8557,
				"close": 19.1218,
				"volume": 600382720,
				"time": 1330405200000,
				"lowest": 18.7804,
				"highest": 19.1218,
				"volumePrice": 19.0072,
				"transactionCount": 122842,
				"voluemPrice": 19.0072
			},
			{
				"open": 19.3414,
				"close": 19.3729,
				"volume": 951746572,
				"time": 1330491600000,
				"lowest": 19.1321,
				"highest": 19.5575,
				"volumePrice": 19.3725,
				"transactionCount": 197934,
				"voluemPrice": 19.3725
			},
			{
				"open": 19.5775,
				"close": 19.4454,
				"volume": 683252752,
				"time": 1330578000000,
				"lowest": 19.2418,
				"highest": 19.5789,
				"volumePrice": 19.4047,
				"transactionCount": 143088,
				"voluemPrice": 19.4047
			},
			{
				"open": 19.4371,
				"close": 19.4707,
				"volume": 431710356,
				"time": 1330664400000,
				"lowest": 19.3757,
				"highest": 19.5286,
				"volumePrice": 19.4495,
				"transactionCount": 91514,
				"voluemPrice": 19.4495
			},
			{
				"open": 19.4793,
				"close": 19.0414,
				"volume": 809087048,
				"time": 1330923600000,
				"lowest": 18.7857,
				"highest": 19.5529,
				"volumePrice": 19.1789,
				"transactionCount": 167854,
				"voluemPrice": 19.1789
			},
			{
				"open": 18.7021,
				"close": 18.9379,
				"volume": 810217044,
				"time": 1331010000000,
				"lowest": 18.4365,
				"highest": 19.0604,
				"volumePrice": 18.7993,
				"transactionCount": 167029,
				"voluemPrice": 18.7993
			},
			{
				"open": 19.1714,
				"close": 18.9532,
				"volume": 798512596,
				"time": 1331096400000,
				"lowest": 18.6893,
				"highest": 19.2064,
				"volumePrice": 18.9933,
				"transactionCount": 171527,
				"voluemPrice": 18.9933
			},
			{
				"open": 19.0961,
				"close": 19.3568,
				"volume": 516442892,
				"time": 1331182800000,
				"lowest": 19.0043,
				"highest": 19.3925,
				"volumePrice": 19.2195,
				"transactionCount": 108507,
				"voluemPrice": 19.2195
			},
			{
				"open": 19.4361,
				"close": 19.4704,
				"volume": 418876528,
				"time": 1331269200000,
				"lowest": 19.3968,
				"highest": 19.5621,
				"volumePrice": 19.4708,
				"transactionCount": 88093,
				"voluemPrice": 19.4708
			},
			{
				"open": 19.6065,
				"close": 19.7143,
				"volume": 407280076,
				"time": 1331524800000,
				"lowest": 19.5357,
				"highest": 19.7143,
				"volumePrice": 19.6424,
				"transactionCount": 81084,
				"voluemPrice": 19.6424
			},
			{
				"open": 19.9121,
				"close": 20.2893,
				"volume": 690828236,
				"time": 1331611200000,
				"lowest": 19.8482,
				"highest": 20.2921,
				"volumePrice": 20.0756,
				"transactionCount": 138179,
				"voluemPrice": 20.0756
			},
			{
				"open": 20.6446,
				"close": 21.0564,
				"volume": 1418836188,
				"time": 1331697600000,
				"lowest": 20.55,
				"highest": 21.24,
				"volumePrice": 20.8723,
				"transactionCount": 287207,
				"voluemPrice": 20.8723
			},
			{
				"open": 21.4146,
				"close": 20.9129,
				"volume": 1159671072,
				"time": 1331784000000,
				"lowest": 20.6625,
				"highest": 21.4289,
				"volumePrice": 21.0679,
				"transactionCount": 239759,
				"voluemPrice": 21.0679
			},
			{
				"open": 20.8829,
				"close": 20.9132,
				"volume": 825487516,
				"time": 1331870400000,
				"lowest": 20.6429,
				"highest": 21.0429,
				"volumePrice": 20.8803,
				"transactionCount": 146422,
				"voluemPrice": 20.8803
			},
			{
				"open": 21.3704,
				"close": 21.4679,
				"volume": 901197724,
				"time": 1332129600000,
				"lowest": 21.0375,
				"highest": 21.4918,
				"volumePrice": 21.2924,
				"transactionCount": 184045,
				"voluemPrice": 21.2924
			},
			{
				"open": 21.4111,
				"close": 21.6414,
				"volume": 816668132,
				"time": 1332216000000,
				"lowest": 20.7857,
				"highest": 21.675,
				"volumePrice": 21.4186,
				"transactionCount": 158753,
				"voluemPrice": 21.4186
			},
			{
				"open": 21.5264,
				"close": 21.5179,
				"volume": 644041496,
				"time": 1332302400000,
				"lowest": 21.4789,
				"highest": 21.7732,
				"volumePrice": 21.6438,
				"transactionCount": 124720,
				"voluemPrice": 21.6438
			},
			{
				"open": 21.3493,
				"close": 21.405,
				"volume": 624131592,
				"time": 1332388800000,
				"lowest": 21.2689,
				"highest": 21.5893,
				"volumePrice": 21.4254,
				"transactionCount": 125677,
				"voluemPrice": 21.4254
			},
			{
				"open": 21.4461,
				"close": 21.2875,
				"volume": 430487260,
				"time": 1332475200000,
				"lowest": 21.2286,
				"highest": 21.4929,
				"volumePrice": 21.338,
				"transactionCount": 86580,
				"voluemPrice": 21.338
			},
			{
				"open": 21.4211,
				"close": 21.6779,
				"volume": 595736876,
				"time": 1332734400000,
				"lowest": 21.2593,
				"highest": 21.6839,
				"volumePrice": 21.4932,
				"transactionCount": 116081,
				"voluemPrice": 21.4932
			},
			{
				"open": 21.6493,
				"close": 21.9457,
				"volume": 607128564,
				"time": 1332820800000,
				"lowest": 21.645,
				"highest": 22.01,
				"volumePrice": 21.8956,
				"transactionCount": 123488,
				"voluemPrice": 21.8956
			},
			{
				"open": 22.085,
				"close": 22.0579,
				"volume": 655459476,
				"time": 1332907200000,
				"lowest": 21.7968,
				"highest": 22.1946,
				"volumePrice": 22.041,
				"transactionCount": 130782,
				"voluemPrice": 22.041
			},
			{
				"open": 21.885,
				"close": 21.7807,
				"volume": 608237728,
				"time": 1332993600000,
				"lowest": 21.6868,
				"highest": 22.02,
				"volumePrice": 21.8408,
				"transactionCount": 125172,
				"voluemPrice": 21.8408
			},
			{
				"open": 21.7418,
				"close": 21.4125,
				"volume": 731037580,
				"time": 1333080000000,
				"lowest": 21.355,
				"highest": 21.8057,
				"volumePrice": 21.514,
				"transactionCount": 152488,
				"voluemPrice": 21.514
			},
			{
				"open": 21.4939,
				"close": 22.0939,
				"volume": 598338580,
				"time": 1333339200000,
				"lowest": 21.4421,
				"highest": 22.0989,
				"volumePrice": 21.861,
				"transactionCount": 122088,
				"voluemPrice": 21.861
			},
			{
				"open": 22.4036,
				"close": 22.4757,
				"volume": 834553440,
				"time": 1333425600000,
				"lowest": 22.2325,
				"highest": 22.5789,
				"volumePrice": 22.444,
				"transactionCount": 180252,
				"voluemPrice": 22.444
			},
			{
				"open": 22.2982,
				"close": 22.2968,
				"volume": 572957980,
				"time": 1333512000000,
				"lowest": 22.0929,
				"highest": 22.3521,
				"volumePrice": 22.221,
				"transactionCount": 119170,
				"voluemPrice": 22.221
			},
			{
				"open": 22.3921,
				"close": 22.6314,
				"volume": 641275432,
				"time": 1333598400000,
				"lowest": 22.2643,
				"highest": 22.6664,
				"volumePrice": 22.5012,
				"transactionCount": 134785,
				"voluemPrice": 22.5012
			},
			{
				"open": 22.3618,
				"close": 22.7225,
				"volume": 597536184,
				"time": 1333944000000,
				"lowest": 22.3321,
				"highest": 22.8514,
				"volumePrice": 22.6398,
				"transactionCount": 121039,
				"voluemPrice": 22.6398
			},
			{
				"open": 22.8546,
				"close": 22.4443,
				"volume": 889678608,
				"time": 1334030400000,
				"lowest": 22.3571,
				"highest": 23,
				"volumePrice": 22.6829,
				"transactionCount": 186069,
				"voluemPrice": 22.6829
			},
			{
				"open": 22.7214,
				"close": 22.3643,
				"volume": 696611076,
				"time": 1334116800000,
				"lowest": 22.2622,
				"highest": 22.7454,
				"volumePrice": 22.4751,
				"transactionCount": 135694,
				"voluemPrice": 22.4751
			},
			{
				"open": 22.3214,
				"close": 22.2418,
				"volume": 614328652,
				"time": 1334203200000,
				"lowest": 22.1607,
				"highest": 22.5475,
				"volumePrice": 22.3246,
				"transactionCount": 123669,
				"voluemPrice": 22.3246
			},
			{
				"open": 22.2896,
				"close": 21.6154,
				"volume": 859644268,
				"time": 1334289600000,
				"lowest": 21.5539,
				"highest": 22.3107,
				"volumePrice": 21.8024,
				"transactionCount": 176679,
				"voluemPrice": 21.8024
			},
			{
				"open": 21.7879,
				"close": 20.7189,
				"volume": 1048885488,
				"time": 1334548800000,
				"lowest": 20.6518,
				"highest": 21.7957,
				"volumePrice": 21.0454,
				"transactionCount": 218257,
				"voluemPrice": 21.0454
			},
			{
				"open": 20.6764,
				"close": 21.775,
				"volume": 1025485888,
				"time": 1334635200000,
				"lowest": 20.4254,
				"highest": 21.7857,
				"volumePrice": 21.2948,
				"transactionCount": 218544,
				"voluemPrice": 21.2948
			},
			{
				"open": 21.9186,
				"close": 21.7264,
				"volume": 954529884,
				"time": 1334721600000,
				"lowest": 21.5254,
				"highest": 22.1518,
				"volumePrice": 21.8392,
				"transactionCount": 198266,
				"voluemPrice": 21.8392
			},
			{
				"open": 21.4364,
				"close": 20.98,
				"volume": 834705060,
				"time": 1334808000000,
				"lowest": 20.8757,
				"highest": 21.5976,
				"volumePrice": 21.2422,
				"transactionCount": 178070,
				"voluemPrice": 21.2422
			},
			{
				"open": 21.1207,
				"close": 20.4636,
				"volume": 1030975232,
				"time": 1334894400000,
				"lowest": 20.3721,
				"highest": 21.2364,
				"volumePrice": 20.7485,
				"transactionCount": 208900,
				"voluemPrice": 20.7485
			},
			{
				"open": 20.3789,
				"close": 20.4179,
				"volume": 966517916,
				"time": 1335153600000,
				"lowest": 19.8793,
				"highest": 20.5954,
				"volumePrice": 20.326,
				"transactionCount": 196845,
				"voluemPrice": 20.326
			},
			{
				"open": 20.0932,
				"close": 20.01,
				"volume": 1076399352,
				"time": 1335240000000,
				"lowest": 19.8214,
				"highest": 20.2746,
				"volumePrice": 20.2309,
				"transactionCount": 229411,
				"voluemPrice": 20.2309
			},
			{
				"open": 21.987,
				"close": 21.7857,
				"volume": 905654680,
				"time": 1335326400000,
				"lowest": 21.6543,
				"highest": 22.0714,
				"volumePrice": 21.851,
				"transactionCount": 195801,
				"voluemPrice": 21.851
			},
			{
				"open": 21.9382,
				"close": 21.7036,
				"volume": 536065096,
				"time": 1335412800000,
				"lowest": 21.5046,
				"highest": 21.9532,
				"volumePrice": 21.7047,
				"transactionCount": 110395,
				"voluemPrice": 21.7047
			},
			{
				"open": 21.6096,
				"close": 21.5357,
				"volume": 406770000,
				"time": 1335499200000,
				"lowest": 21.4464,
				"highest": 21.6493,
				"volumePrice": 21.539,
				"transactionCount": 88436,
				"voluemPrice": 21.539
			},
			{
				"open": 21.35,
				"close": 20.8564,
				"volume": 506140152,
				"time": 1335758400000,
				"lowest": 20.8214,
				"highest": 21.3714,
				"volumePrice": 21.0288,
				"transactionCount": 106584,
				"voluemPrice": 21.0288
			},
			{
				"open": 20.8893,
				"close": 20.7904,
				"volume": 610998052,
				"time": 1335844800000,
				"lowest": 20.7582,
				"highest": 21.3129,
				"volumePrice": 21.0365,
				"transactionCount": 125194,
				"voluemPrice": 21.0365
			},
			{
				"open": 20.7229,
				"close": 20.9279,
				"volume": 427624064,
				"time": 1335931200000,
				"lowest": 20.6736,
				"highest": 20.9786,
				"volumePrice": 20.8513,
				"transactionCount": 89935,
				"voluemPrice": 20.8513
			},
			{
				"open": 21.0893,
				"close": 20.7793,
				"volume": 390548228,
				"time": 1336017600000,
				"lowest": 20.725,
				"highest": 21.1214,
				"volumePrice": 20.8974,
				"transactionCount": 82126,
				"voluemPrice": 20.8974
			},
			{
				"open": 20.61,
				"close": 20.1875,
				"volume": 529989684,
				"time": 1336104000000,
				"lowest": 20.1846,
				"highest": 20.6557,
				"volumePrice": 20.3612,
				"transactionCount": 117909,
				"voluemPrice": 20.3612
			},
			{
				"open": 20.0536,
				"close": 20.3386,
				"volume": 460290068,
				"time": 1336363200000,
				"lowest": 20.0439,
				"highest": 20.4561,
				"volumePrice": 20.2688,
				"transactionCount": 97432,
				"voluemPrice": 20.2688
			},
			{
				"open": 20.3421,
				"close": 20.2921,
				"volume": 497250880,
				"time": 1336449600000,
				"lowest": 19.9546,
				"highest": 20.4107,
				"volumePrice": 20.1739,
				"transactionCount": 102809,
				"voluemPrice": 20.1739
			},
			{
				"open": 20.1321,
				"close": 20.3279,
				"volume": 480703692,
				"time": 1336536000000,
				"lowest": 20.0304,
				"highest": 20.4992,
				"volumePrice": 20.3055,
				"transactionCount": 99383,
				"voluemPrice": 20.3055
			},
			{
				"open": 20.5207,
				"close": 20.3757,
				"volume": 333271008,
				"time": 1336622400000,
				"lowest": 20.3015,
				"highest": 20.5671,
				"volumePrice": 20.4462,
				"transactionCount": 70374,
				"voluemPrice": 20.4462
			},
			{
				"open": 20.1786,
				"close": 20.2396,
				"volume": 399545328,
				"time": 1336708800000,
				"lowest": 20.1554,
				"highest": 20.5168,
				"volumePrice": 20.3166,
				"transactionCount": 85724,
				"voluemPrice": 20.3166
			},
			{
				"open": 20.0918,
				"close": 19.9364,
				"volume": 352615340,
				"time": 1336968000000,
				"lowest": 19.9143,
				"highest": 20.2682,
				"volumePrice": 20.0826,
				"transactionCount": 77656,
				"voluemPrice": 20.0826
			},
			{
				"open": 20.0518,
				"close": 19.7561,
				"volume": 476306712,
				"time": 1337054400000,
				"lowest": 19.7054,
				"highest": 20.115,
				"volumePrice": 19.9393,
				"transactionCount": 102189,
				"voluemPrice": 19.9393
			},
			{
				"open": 19.7875,
				"close": 19.5027,
				"volume": 560685496,
				"time": 1337140800000,
				"lowest": 19.3229,
				"highest": 19.8889,
				"volumePrice": 19.5951,
				"transactionCount": 118477,
				"voluemPrice": 19.5951
			},
			{
				"open": 19.4754,
				"close": 18.9329,
				"volume": 717218852,
				"time": 1337227200000,
				"lowest": 18.9329,
				"highest": 19.5536,
				"volumePrice": 19.154,
				"transactionCount": 154729,
				"voluemPrice": 19.154
			},
			{
				"open": 19.07,
				"close": 18.9421,
				"volume": 732210220,
				"time": 1337313600000,
				"lowest": 18.6493,
				"highest": 19.4075,
				"volumePrice": 19.1212,
				"transactionCount": 151430,
				"voluemPrice": 19.1212
			},
			{
				"open": 19.0893,
				"close": 20.0457,
				"volume": 631091160,
				"time": 1337572800000,
				"lowest": 19.0732,
				"highest": 20.055,
				"volumePrice": 19.6322,
				"transactionCount": 131967,
				"voluemPrice": 19.6322
			},
			{
				"open": 20.3411,
				"close": 19.8918,
				"volume": 694787016,
				"time": 1337659200000,
				"lowest": 19.735,
				"highest": 20.4957,
				"volumePrice": 20.1788,
				"transactionCount": 149192,
				"voluemPrice": 20.1788
			},
			{
				"open": 19.9107,
				"close": 20.3771,
				"volume": 584897460,
				"time": 1337745600000,
				"lowest": 19.7582,
				"highest": 20.4571,
				"volumePrice": 20.0546,
				"transactionCount": 127156,
				"voluemPrice": 20.0546
			},
			{
				"open": 20.5668,
				"close": 20.19,
				"volume": 496198024,
				"time": 1337832000000,
				"lowest": 20.0439,
				"highest": 20.5893,
				"volumePrice": 20.3016,
				"transactionCount": 103111,
				"voluemPrice": 20.3016
			},
			{
				"open": 20.1639,
				"close": 20.0818,
				"volume": 328466208,
				"time": 1337918400000,
				"lowest": 19.9454,
				"highest": 20.2089,
				"volumePrice": 20.041,
				"transactionCount": 71477,
				"voluemPrice": 20.041
			},
			{
				"open": 20.3893,
				"close": 20.4382,
				"volume": 380482172,
				"time": 1338264000000,
				"lowest": 20.1896,
				"highest": 20.5,
				"volumePrice": 20.3589,
				"transactionCount": 79322,
				"voluemPrice": 20.3589
			},
			{
				"open": 20.3286,
				"close": 20.6846,
				"volume": 529420836,
				"time": 1338350400000,
				"lowest": 20.2343,
				"highest": 20.7139,
				"volumePrice": 20.4988,
				"transactionCount": 117773,
				"voluemPrice": 20.4988
			},
			{
				"open": 20.7407,
				"close": 20.6332,
				"volume": 491882608,
				"time": 1338436800000,
				"lowest": 20.4093,
				"highest": 20.7679,
				"volumePrice": 20.6215,
				"transactionCount": 104367,
				"voluemPrice": 20.6215
			},
			{
				"open": 20.3271,
				"close": 20.0354,
				"volume": 520968028,
				"time": 1338523200000,
				"lowest": 20.0186,
				"highest": 20.4518,
				"volumePrice": 20.2135,
				"transactionCount": 115717,
				"voluemPrice": 20.2135
			},
			{
				"open": 20.0536,
				"close": 20.1532,
				"volume": 556988516,
				"time": 1338782400000,
				"lowest": 19.5893,
				"highest": 20.2679,
				"volumePrice": 19.9069,
				"transactionCount": 121570,
				"voluemPrice": 19.9069
			},
			{
				"open": 20.0454,
				"close": 20.1011,
				"volume": 388213504,
				"time": 1338868800000,
				"lowest": 19.9404,
				"highest": 20.2311,
				"volumePrice": 20.1001,
				"transactionCount": 84214,
				"voluemPrice": 20.1001
			},
			{
				"open": 20.2775,
				"close": 20.4093,
				"volume": 401449048,
				"time": 1338955200000,
				"lowest": 20.1964,
				"highest": 20.4946,
				"volumePrice": 20.3854,
				"transactionCount": 87963,
				"voluemPrice": 20.3854
			},
			{
				"open": 20.6175,
				"close": 20.4186,
				"volume": 379972656,
				"time": 1339041600000,
				"lowest": 20.375,
				"highest": 20.6186,
				"volumePrice": 20.4986,
				"transactionCount": 85281,
				"voluemPrice": 20.4986
			},
			{
				"open": 20.4143,
				"close": 20.7257,
				"volume": 347491480,
				"time": 1339128000000,
				"lowest": 20.3214,
				"highest": 20.7351,
				"volumePrice": 20.5479,
				"transactionCount": 74769,
				"voluemPrice": 20.5479
			},
			{
				"open": 20.99,
				"close": 20.3989,
				"volume": 591195836,
				"time": 1339387200000,
				"lowest": 20.3796,
				"highest": 21.0179,
				"volumePrice": 20.7853,
				"transactionCount": 128641,
				"voluemPrice": 20.7853
			},
			{
				"open": 20.5164,
				"close": 20.5771,
				"volume": 435579844,
				"time": 1339473600000,
				"lowest": 20.2393,
				"highest": 20.5936,
				"volumePrice": 20.4591,
				"transactionCount": 95942,
				"voluemPrice": 20.4591
			},
			{
				"open": 20.5186,
				"close": 20.4343,
				"volume": 293772920,
				"time": 1339560000000,
				"lowest": 20.3707,
				"highest": 20.66,
				"volumePrice": 20.5294,
				"transactionCount": 64205,
				"voluemPrice": 20.5294
			},
			{
				"open": 20.4014,
				"close": 20.4118,
				"volume": 345841132,
				"time": 1339646400000,
				"lowest": 20.2593,
				"highest": 20.4821,
				"volumePrice": 20.3926,
				"transactionCount": 77015,
				"voluemPrice": 20.3926
			},
			{
				"open": 20.3929,
				"close": 20.5046,
				"volume": 335253520,
				"time": 1339732800000,
				"lowest": 20.3411,
				"highest": 20.5221,
				"volumePrice": 20.421,
				"transactionCount": 61147,
				"voluemPrice": 20.421
			},
			{
				"open": 20.3914,
				"close": 20.9207,
				"volume": 440330240,
				"time": 1339992000000,
				"lowest": 20.3704,
				"highest": 20.9961,
				"volumePrice": 20.8044,
				"transactionCount": 98071,
				"voluemPrice": 20.8044
			},
			{
				"open": 20.8357,
				"close": 20.9789,
				"volume": 361403728,
				"time": 1340078400000,
				"lowest": 20.825,
				"highest": 21.0714,
				"volumePrice": 20.966,
				"transactionCount": 79525,
				"voluemPrice": 20.966
			},
			{
				"open": 21.0075,
				"close": 20.9193,
				"volume": 359063292,
				"time": 1340164800000,
				"lowest": 20.7429,
				"highest": 21.0446,
				"volumePrice": 20.8887,
				"transactionCount": 77070,
				"voluemPrice": 20.8887
			},
			{
				"open": 20.9086,
				"close": 20.6311,
				"volume": 326505032,
				"time": 1340251200000,
				"lowest": 20.6229,
				"highest": 21.0079,
				"volumePrice": 20.8187,
				"transactionCount": 73311,
				"voluemPrice": 20.8187
			},
			{
				"open": 20.68,
				"close": 20.7893,
				"volume": 284745244,
				"time": 1340337600000,
				"lowest": 20.5507,
				"highest": 20.7925,
				"volumePrice": 20.6755,
				"transactionCount": 59720,
				"voluemPrice": 20.6755
			},
			{
				"open": 20.6179,
				"close": 20.3845,
				"volume": 304354624,
				"time": 1340596800000,
				"lowest": 20.3704,
				"highest": 20.7071,
				"volumePrice": 20.48,
				"transactionCount": 69143,
				"voluemPrice": 20.48
			},
			{
				"open": 20.4046,
				"close": 20.4295,
				"volume": 276750012,
				"time": 1340683200000,
				"lowest": 20.2618,
				"highest": 20.5175,
				"volumePrice": 20.3859,
				"transactionCount": 61407,
				"voluemPrice": 20.3859
			},
			{
				"open": 20.5357,
				"close": 20.5179,
				"volume": 203059080,
				"time": 1340769600000,
				"lowest": 20.4257,
				"highest": 20.5979,
				"volumePrice": 20.5243,
				"transactionCount": 46891,
				"voluemPrice": 20.5243
			},
			{
				"open": 20.4168,
				"close": 20.3232,
				"volume": 282974384,
				"time": 1340856000000,
				"lowest": 20.2004,
				"highest": 20.5,
				"volumePrice": 20.3208,
				"transactionCount": 64943,
				"voluemPrice": 20.3208
			},
			{
				"open": 20.6429,
				"close": 20.8571,
				"volume": 421440124,
				"time": 1340942400000,
				"lowest": 20.5089,
				"highest": 20.8571,
				"volumePrice": 20.7155,
				"transactionCount": 91113,
				"voluemPrice": 20.7155
			},
			{
				"open": 20.8832,
				"close": 21.1614,
				"volume": 400091104,
				"time": 1341201600000,
				"lowest": 20.8429,
				"highest": 21.1954,
				"volumePrice": 21.0898,
				"transactionCount": 87306,
				"voluemPrice": 21.0898
			},
			{
				"open": 21.2457,
				"close": 21.4075,
				"volume": 241712660,
				"time": 1341288000000,
				"lowest": 21.2143,
				"highest": 21.4286,
				"volumePrice": 21.3538,
				"transactionCount": 54397,
				"voluemPrice": 21.3538
			},
			{
				"open": 21.4486,
				"close": 21.7836,
				"volume": 484380484,
				"time": 1341460800000,
				"lowest": 21.4161,
				"highest": 21.9407,
				"volumePrice": 21.7675,
				"transactionCount": 107731,
				"voluemPrice": 21.7675
			},
			{
				"open": 21.6818,
				"close": 21.6386,
				"volume": 419028624,
				"time": 1341547200000,
				"lowest": 21.485,
				"highest": 21.73,
				"volumePrice": 21.598,
				"transactionCount": 98224,
				"voluemPrice": 21.598
			},
			{
				"open": 21.6179,
				"close": 21.9246,
				"volume": 379400896,
				"time": 1341806400000,
				"lowest": 21.5754,
				"highest": 21.925,
				"volumePrice": 21.8231,
				"transactionCount": 83788,
				"voluemPrice": 21.8231
			},
			{
				"open": 22.0704,
				"close": 21.7218,
				"volume": 511902412,
				"time": 1341892800000,
				"lowest": 21.6182,
				"highest": 22.1382,
				"volumePrice": 21.9153,
				"transactionCount": 118249,
				"voluemPrice": 21.9153
			},
			{
				"open": 21.6471,
				"close": 21.5868,
				"volume": 469198464,
				"time": 1341979200000,
				"lowest": 21.3293,
				"highest": 21.7021,
				"volumePrice": 21.5349,
				"transactionCount": 92910,
				"voluemPrice": 21.5349
			},
			{
				"open": 21.4371,
				"close": 21.3893,
				"volume": 427956452,
				"time": 1342065600000,
				"lowest": 21.1671,
				"highest": 21.5525,
				"volumePrice": 21.3946,
				"transactionCount": 93268,
				"voluemPrice": 21.3946
			},
			{
				"open": 21.5339,
				"close": 21.6061,
				"volume": 311617796,
				"time": 1342152000000,
				"lowest": 21.4286,
				"highest": 21.6854,
				"volumePrice": 21.5765,
				"transactionCount": 68460,
				"voluemPrice": 21.5765
			},
			{
				"open": 21.6114,
				"close": 21.6754,
				"volume": 301305368,
				"time": 1342411200000,
				"lowest": 21.6079,
				"highest": 21.8436,
				"volumePrice": 21.7303,
				"transactionCount": 67887,
				"voluemPrice": 21.7303
			},
			{
				"open": 21.8139,
				"close": 21.6764,
				"volume": 293809348,
				"time": 1342497600000,
				"lowest": 21.5411,
				"highest": 21.8393,
				"volumePrice": 21.6974,
				"transactionCount": 64049,
				"voluemPrice": 21.6974
			},
			{
				"open": 21.6639,
				"close": 21.6521,
				"volume": 252699552,
				"time": 1342584000000,
				"lowest": 21.5557,
				"highest": 21.7264,
				"volumePrice": 21.6429,
				"transactionCount": 56864,
				"voluemPrice": 21.6429
			},
			{
				"open": 21.8314,
				"close": 21.94,
				"volume": 436858268,
				"time": 1342670400000,
				"lowest": 21.6429,
				"highest": 21.9768,
				"volumePrice": 21.8734,
				"transactionCount": 96967,
				"voluemPrice": 21.8734
			},
			{
				"open": 21.8939,
				"close": 21.5821,
				"volume": 397469800,
				"time": 1342756800000,
				"lowest": 21.5607,
				"highest": 21.9443,
				"volumePrice": 21.7429,
				"transactionCount": 81396,
				"voluemPrice": 21.7429
			},
			{
				"open": 21.2286,
				"close": 21.5654,
				"volume": 487924780,
				"time": 1343016000000,
				"lowest": 20.9896,
				"highest": 21.6393,
				"volumePrice": 21.3387,
				"transactionCount": 103416,
				"voluemPrice": 21.3387
			},
			{
				"open": 21.6921,
				"close": 21.4614,
				"volume": 565132176,
				"time": 1343102400000,
				"lowest": 21.3754,
				"highest": 21.7743,
				"volumePrice": 21.304,
				"transactionCount": 119521,
				"voluemPrice": 21.304
			},
			{
				"open": 20.5164,
				"close": 20.5346,
				"volume": 877244872,
				"time": 1343188800000,
				"lowest": 20.3571,
				"highest": 20.7429,
				"volumePrice": 20.5413,
				"transactionCount": 195958,
				"voluemPrice": 20.5413
			},
			{
				"open": 20.7057,
				"close": 20.5314,
				"volume": 406810320,
				"time": 1343275200000,
				"lowest": 20.37,
				"highest": 20.7286,
				"volumePrice": 20.5342,
				"transactionCount": 92196,
				"voluemPrice": 20.5342
			},
			{
				"open": 20.5361,
				"close": 20.8986,
				"volume": 404052964,
				"time": 1343361600000,
				"lowest": 20.4139,
				"highest": 20.9225,
				"volumePrice": 20.6947,
				"transactionCount": 87663,
				"voluemPrice": 20.6947
			},
			{
				"open": 21.1043,
				"close": 21.2511,
				"volume": 379139880,
				"time": 1343620800000,
				"lowest": 20.9936,
				"highest": 21.4086,
				"volumePrice": 21.265,
				"transactionCount": 84789,
				"voluemPrice": 21.265
			},
			{
				"open": 21.5439,
				"close": 21.8129,
				"volume": 462326704,
				"time": 1343707200000,
				"lowest": 21.5257,
				"highest": 21.8464,
				"volumePrice": 21.734,
				"transactionCount": 98426,
				"voluemPrice": 21.734
			},
			{
				"open": 21.9966,
				"close": 21.6718,
				"volume": 384498968,
				"time": 1343793600000,
				"lowest": 21.5357,
				"highest": 22.0143,
				"volumePrice": 21.7788,
				"transactionCount": 84594,
				"voluemPrice": 21.7788
			},
			{
				"open": 21.53,
				"close": 21.7068,
				"volume": 332194156,
				"time": 1343880000000,
				"lowest": 21.4375,
				"highest": 21.8104,
				"volumePrice": 21.6498,
				"transactionCount": 71385,
				"voluemPrice": 21.6498
			},
			{
				"open": 21.9154,
				"close": 21.9893,
				"volume": 344914416,
				"time": 1343966400000,
				"lowest": 21.8414,
				"highest": 22.0707,
				"volumePrice": 21.9856,
				"transactionCount": 76570,
				"voluemPrice": 21.9856
			},
			{
				"open": 22.0461,
				"close": 22.2339,
				"volume": 302320032,
				"time": 1344225600000,
				"lowest": 21.9736,
				"highest": 22.3167,
				"volumePrice": 22.1943,
				"transactionCount": 63438,
				"voluemPrice": 22.1943
			},
			{
				"open": 22.2418,
				"close": 22.1755,
				"volume": 290509968,
				"time": 1344312000000,
				"lowest": 22.0729,
				"highest": 22.3214,
				"volumePrice": 22.2162,
				"transactionCount": 60770,
				"voluemPrice": 22.2162
			},
			{
				"open": 22.1211,
				"close": 22.1379,
				"volume": 244704320,
				"time": 1344398400000,
				"lowest": 22.0393,
				"highest": 22.2814,
				"volumePrice": 22.1614,
				"transactionCount": 49379,
				"voluemPrice": 22.1614
			},
			{
				"open": 22.0661,
				"close": 22.1689,
				"volume": 221810344,
				"time": 1344484800000,
				"lowest": 22.0643,
				"highest": 22.2046,
				"volumePrice": 22.1577,
				"transactionCount": 38695,
				"voluemPrice": 22.1577
			},
			{
				"open": 22.0968,
				"close": 22.2036,
				"volume": 195060180,
				"time": 1344571200000,
				"lowest": 22.0964,
				"highest": 22.2057,
				"volumePrice": 22.1645,
				"transactionCount": 39528,
				"voluemPrice": 22.1645
			},
			{
				"open": 22.2639,
				"close": 22.5,
				"volume": 278829852,
				"time": 1344830400000,
				"lowest": 22.2589,
				"highest": 22.5,
				"volumePrice": 22.4143,
				"transactionCount": 59849,
				"voluemPrice": 22.4143
			},
			{
				"open": 22.5668,
				"close": 22.5604,
				"volume": 340167296,
				"time": 1344916800000,
				"lowest": 22.5075,
				"highest": 22.8075,
				"volumePrice": 22.6615,
				"transactionCount": 74593,
				"voluemPrice": 22.6615
			},
			{
				"open": 22.5464,
				"close": 22.5296,
				"volume": 257509112,
				"time": 1345003200000,
				"lowest": 22.4196,
				"highest": 22.6429,
				"volumePrice": 22.5392,
				"transactionCount": 50302,
				"voluemPrice": 22.5392
			},
			{
				"open": 22.5432,
				"close": 22.7264,
				"volume": 254776816,
				"time": 1345089600000,
				"lowest": 22.5179,
				"highest": 22.7414,
				"volumePrice": 22.6613,
				"transactionCount": 56292,
				"voluemPrice": 22.6613
			},
			{
				"open": 22.8571,
				"close": 23.1468,
				"volume": 442759576,
				"time": 1345176000000,
				"lowest": 22.8146,
				"highest": 23.1496,
				"volumePrice": 22.9936,
				"transactionCount": 91272,
				"voluemPrice": 22.9936
			},
			{
				"open": 23.2146,
				"close": 23.7554,
				"volume": 613382756,
				"time": 1345435200000,
				"lowest": 23.2107,
				"highest": 23.7554,
				"volumePrice": 23.5669,
				"transactionCount": 129678,
				"voluemPrice": 23.5669
			},
			{
				"open": 23.9579,
				"close": 23.4308,
				"volume": 812705040,
				"time": 1345521600000,
				"lowest": 23.2261,
				"highest": 24.1029,
				"volumePrice": 23.6374,
				"transactionCount": 177155,
				"voluemPrice": 23.6374
			},
			{
				"open": 23.3721,
				"close": 23.8882,
				"volume": 565322548,
				"time": 1345608000000,
				"lowest": 23.1468,
				"highest": 23.8929,
				"volumePrice": 23.5794,
				"transactionCount": 117656,
				"voluemPrice": 23.5794
			},
			{
				"open": 23.7896,
				"close": 23.6654,
				"volume": 420118272,
				"time": 1345694400000,
				"lowest": 23.6125,
				"highest": 23.925,
				"volumePrice": 23.7772,
				"transactionCount": 88435,
				"voluemPrice": 23.7772
			},
			{
				"open": 23.5539,
				"close": 23.6865,
				"volume": 437336452,
				"time": 1345780800000,
				"lowest": 23.4125,
				"highest": 23.91,
				"volumePrice": 23.7112,
				"transactionCount": 88489,
				"voluemPrice": 23.7112
			},
			{
				"open": 24.2854,
				"close": 24.1314,
				"volume": 426914404,
				"time": 1346040000000,
				"lowest": 24.055,
				"highest": 24.3168,
				"volumePrice": 24.18,
				"transactionCount": 86901,
				"voluemPrice": 24.18
			},
			{
				"open": 24.1063,
				"close": 24.1,
				"volume": 267292060,
				"time": 1346126400000,
				"lowest": 23.9526,
				"highest": 24.1464,
				"volumePrice": 24.0673,
				"transactionCount": 56507,
				"voluemPrice": 24.0673
			},
			{
				"open": 24.1161,
				"close": 24.0524,
				"volume": 202741812,
				"time": 1346212800000,
				"lowest": 24.0214,
				"highest": 24.2025,
				"volumePrice": 24.1015,
				"transactionCount": 41303,
				"voluemPrice": 24.1015
			},
			{
				"open": 23.9514,
				"close": 23.7096,
				"volume": 302591856,
				"time": 1346299200000,
				"lowest": 23.6733,
				"highest": 23.9841,
				"volumePrice": 23.8157,
				"transactionCount": 58497,
				"voluemPrice": 23.8157
			},
			{
				"open": 23.8304,
				"close": 23.7586,
				"volume": 338318428,
				"time": 1346385600000,
				"lowest": 23.4732,
				"highest": 23.8786,
				"volumePrice": 23.719,
				"transactionCount": 72589,
				"voluemPrice": 23.719
			},
			{
				"open": 23.7771,
				"close": 24.1061,
				"volume": 367854760,
				"time": 1346731200000,
				"lowest": 23.7321,
				"highest": 24.1121,
				"volumePrice": 23.9445,
				"transactionCount": 77654,
				"voluemPrice": 23.9445
			},
			{
				"open": 24.1275,
				"close": 23.9368,
				"volume": 336361900,
				"time": 1346817600000,
				"lowest": 23.9143,
				"highest": 24.1554,
				"volumePrice": 24.0282,
				"transactionCount": 68682,
				"voluemPrice": 24.0282
			},
			{
				"open": 24.0418,
				"close": 24.1525,
				"volume": 391172656,
				"time": 1346904000000,
				"lowest": 23.9571,
				"highest": 24.2246,
				"volumePrice": 24.1103,
				"transactionCount": 79698,
				"voluemPrice": 24.1103
			},
			{
				"open": 24.2161,
				"close": 24.3014,
				"volume": 329664580,
				"time": 1346990400000,
				"lowest": 24.1346,
				"highest": 24.3743,
				"volumePrice": 24.282,
				"transactionCount": 71053,
				"voluemPrice": 24.282
			},
			{
				"open": 24.3018,
				"close": 23.6693,
				"volume": 487995592,
				"time": 1347249600000,
				"lowest": 23.6464,
				"highest": 24.4032,
				"volumePrice": 23.9986,
				"transactionCount": 104008,
				"voluemPrice": 23.9986
			},
			{
				"open": 23.7539,
				"close": 23.5925,
				"volume": 503939408,
				"time": 1347336000000,
				"lowest": 23.4464,
				"highest": 23.9321,
				"volumePrice": 23.7061,
				"transactionCount": 102885,
				"voluemPrice": 23.7061
			},
			{
				"open": 23.8161,
				"close": 23.9211,
				"volume": 712217968,
				"time": 1347422400000,
				"lowest": 23.4286,
				"highest": 23.925,
				"volumePrice": 23.6623,
				"transactionCount": 151773,
				"voluemPrice": 23.6623
			},
			{
				"open": 24.1918,
				"close": 24.3921,
				"volume": 598358516,
				"time": 1347508800000,
				"lowest": 24.0989,
				"highest": 24.4821,
				"volumePrice": 24.296,
				"transactionCount": 118347,
				"voluemPrice": 24.296
			},
			{
				"open": 24.6413,
				"close": 24.6886,
				"volume": 600473244,
				"time": 1347595200000,
				"lowest": 24.5675,
				"highest": 24.8921,
				"volumePrice": 24.7537,
				"transactionCount": 126091,
				"voluemPrice": 24.7537
			},
			{
				"open": 24.9768,
				"close": 24.9922,
				"volume": 398025796,
				"time": 1347854400000,
				"lowest": 24.8075,
				"highest": 24.9929,
				"volumePrice": 24.923,
				"transactionCount": 77180,
				"voluemPrice": 24.923
			},
			{
				"open": 24.9957,
				"close": 25.0682,
				"volume": 373502668,
				"time": 1347940800000,
				"lowest": 24.8721,
				"highest": 25.0832,
				"volumePrice": 24.9955,
				"transactionCount": 78137,
				"voluemPrice": 24.9955
			},
			{
				"open": 25.0093,
				"close": 25.075,
				"volume": 326835656,
				"time": 1348027200000,
				"lowest": 24.9846,
				"highest": 25.1425,
				"volumePrice": 25.0679,
				"transactionCount": 64366,
				"voluemPrice": 25.0679
			},
			{
				"open": 24.97,
				"close": 24.9536,
				"volume": 336567728,
				"time": 1348113600000,
				"lowest": 24.7721,
				"highest": 25.0021,
				"volumePrice": 24.9215,
				"transactionCount": 64502,
				"voluemPrice": 24.9215
			},
			{
				"open": 25.0861,
				"close": 25.0034,
				"volume": 571588304,
				"time": 1348200000000,
				"lowest": 24.9772,
				"highest": 25.1811,
				"volumePrice": 25.0876,
				"transactionCount": 93681,
				"voluemPrice": 25.0876
			},
			{
				"open": 24.5307,
				"close": 24.6711,
				"volume": 639744784,
				"time": 1348459200000,
				"lowest": 24.3929,
				"highest": 24.8257,
				"volumePrice": 24.6123,
				"transactionCount": 131478,
				"voluemPrice": 24.6123
			},
			{
				"open": 24.5807,
				"close": 24.055,
				"volume": 518761796,
				"time": 1348545600000,
				"lowest": 24.0357,
				"highest": 24.7421,
				"volumePrice": 24.4199,
				"transactionCount": 107645,
				"voluemPrice": 24.4199
			},
			{
				"open": 23.8836,
				"close": 23.7564,
				"volume": 576703624,
				"time": 1348632000000,
				"lowest": 23.6143,
				"highest": 24.0246,
				"volumePrice": 23.795,
				"transactionCount": 116723,
				"voluemPrice": 23.795
			},
			{
				"open": 23.7246,
				"close": 24.3329,
				"volume": 594085324,
				"time": 1348718400000,
				"lowest": 23.5839,
				"highest": 24.3632,
				"volumePrice": 24.0335,
				"transactionCount": 127747,
				"voluemPrice": 24.0335
			},
			{
				"open": 24.2411,
				"close": 23.8252,
				"volume": 535108308,
				"time": 1348804800000,
				"lowest": 23.8125,
				"highest": 24.3254,
				"volumePrice": 24.0124,
				"transactionCount": 110693,
				"voluemPrice": 24.0124
			},
			{
				"open": 23.97,
				"close": 23.5496,
				"volume": 543583684,
				"time": 1349064000000,
				"lowest": 23.4464,
				"highest": 24.1696,
				"volumePrice": 23.8196,
				"transactionCount": 114240,
				"voluemPrice": 23.8196
			},
			{
				"open": 23.6361,
				"close": 23.6182,
				"volume": 627990552,
				"time": 1349150400000,
				"lowest": 23.2375,
				"highest": 23.7982,
				"volumePrice": 23.517,
				"transactionCount": 134137,
				"voluemPrice": 23.517
			},
			{
				"open": 23.745,
				"close": 23.9804,
				"volume": 424278876,
				"time": 1349236800000,
				"lowest": 23.6654,
				"highest": 23.995,
				"volumePrice": 23.8323,
				"transactionCount": 81612,
				"voluemPrice": 23.8323
			},
			{
				"open": 23.9732,
				"close": 23.8143,
				"volume": 370755252,
				"time": 1349323200000,
				"lowest": 23.7696,
				"highest": 24.0804,
				"volumePrice": 23.9136,
				"transactionCount": 75222,
				"voluemPrice": 23.9136
			},
			{
				"open": 23.7571,
				"close": 23.3068,
				"volume": 594004432,
				"time": 1349409600000,
				"lowest": 23.26,
				"highest": 23.7857,
				"volumePrice": 23.4938,
				"transactionCount": 124273,
				"voluemPrice": 23.4938
			},
			{
				"open": 23.1029,
				"close": 22.7918,
				"volume": 637964852,
				"time": 1349668800000,
				"lowest": 22.7182,
				"highest": 23.1271,
				"volumePrice": 22.8942,
				"transactionCount": 132925,
				"voluemPrice": 22.8942
			},
			{
				"open": 22.8089,
				"close": 22.7089,
				"volume": 838595548,
				"time": 1349755200000,
				"lowest": 22.2696,
				"highest": 22.8746,
				"volumePrice": 22.6128,
				"transactionCount": 176749,
				"voluemPrice": 22.6128
			},
			{
				"open": 22.8479,
				"close": 22.8896,
				"volume": 510355720,
				"time": 1349841600000,
				"lowest": 22.75,
				"highest": 23.035,
				"volumePrice": 22.8981,
				"transactionCount": 103997,
				"voluemPrice": 22.8981
			},
			{
				"open": 23.0893,
				"close": 22.4321,
				"volume": 546080388,
				"time": 1349928000000,
				"lowest": 22.4321,
				"highest": 23.1143,
				"volumePrice": 22.7024,
				"transactionCount": 111735,
				"voluemPrice": 22.7024
			},
			{
				"open": 22.4843,
				"close": 22.4898,
				"volume": 460014660,
				"time": 1350014400000,
				"lowest": 22.3321,
				"highest": 22.6921,
				"volumePrice": 22.522,
				"transactionCount": 98180,
				"voluemPrice": 22.522
			},
			{
				"open": 22.5839,
				"close": 22.67,
				"volume": 432499844,
				"time": 1350273600000,
				"lowest": 22.2804,
				"highest": 22.6832,
				"volumePrice": 22.5108,
				"transactionCount": 88523,
				"voluemPrice": 22.5108
			},
			{
				"open": 22.6918,
				"close": 23.2069,
				"volume": 549771264,
				"time": 1350360000000,
				"lowest": 22.5357,
				"highest": 23.225,
				"volumePrice": 22.981,
				"transactionCount": 116928,
				"voluemPrice": 22.981
			},
			{
				"open": 23.1739,
				"close": 23.0219,
				"volume": 389310572,
				"time": 1350446400000,
				"lowest": 23,
				"highest": 23.3139,
				"volumePrice": 23.1383,
				"transactionCount": 79773,
				"voluemPrice": 23.1383
			},
			{
				"open": 22.8425,
				"close": 22.5943,
				"volume": 476622048,
				"time": 1350532800000,
				"lowest": 22.5,
				"highest": 22.9307,
				"volumePrice": 22.6898,
				"transactionCount": 99978,
				"voluemPrice": 22.6898
			},
			{
				"open": 22.5375,
				"close": 21.78,
				"volume": 744079952,
				"time": 1350619200000,
				"lowest": 21.7721,
				"highest": 22.5632,
				"volumePrice": 22.12,
				"transactionCount": 149610,
				"voluemPrice": 22.12
			},
			{
				"open": 21.8721,
				"close": 22.6439,
				"volume": 546729568,
				"time": 1350878400000,
				"lowest": 21.8129,
				"highest": 22.6921,
				"volumePrice": 22.2714,
				"transactionCount": 118222,
				"voluemPrice": 22.2714
			},
			{
				"open": 22.5357,
				"close": 21.9056,
				"volume": 707144788,
				"time": 1350964800000,
				"lowest": 21.8464,
				"highest": 22.6393,
				"volumePrice": 22.2661,
				"transactionCount": 150412,
				"voluemPrice": 22.2661
			},
			{
				"open": 22.1943,
				"close": 22.0296,
				"volume": 558523112,
				"time": 1351051200000,
				"lowest": 21.8086,
				"highest": 22.3768,
				"volumePrice": 22.0661,
				"transactionCount": 117762,
				"voluemPrice": 22.0661
			},
			{
				"open": 22.1429,
				"close": 21.7692,
				"volume": 656325572,
				"time": 1351137600000,
				"lowest": 21.6268,
				"highest": 22.2143,
				"volumePrice": 21.8071,
				"transactionCount": 137143,
				"voluemPrice": 21.8071
			},
			{
				"open": 21.7654,
				"close": 21.5714,
				"volume": 1018423336,
				"time": 1351224000000,
				"lowest": 21.1071,
				"highest": 21.9286,
				"volumePrice": 21.571,
				"transactionCount": 217585,
				"voluemPrice": 21.571
			},
			{
				"open": 21.2457,
				"close": 21.2614,
				"volume": 510001884,
				"time": 1351656000000,
				"lowest": 20.9893,
				"highest": 21.4986,
				"volumePrice": 21.2245,
				"transactionCount": 106646,
				"voluemPrice": 21.2245
			},
			{
				"open": 21.365,
				"close": 21.305,
				"volume": 361447688,
				"time": 1351742400000,
				"lowest": 21.2204,
				"highest": 21.5357,
				"volumePrice": 21.3557,
				"transactionCount": 76215,
				"voluemPrice": 21.3557
			},
			{
				"open": 21.2818,
				"close": 20.6,
				"volume": 599372256,
				"time": 1351828800000,
				"lowest": 20.5268,
				"highest": 21.3196,
				"volumePrice": 20.8845,
				"transactionCount": 129334,
				"voluemPrice": 20.8845
			},
			{
				"open": 20.8398,
				"close": 20.8793,
				"volume": 529284896,
				"time": 1352091600000,
				"lowest": 20.6286,
				"highest": 20.9918,
				"volumePrice": 20.8326,
				"transactionCount": 105861,
				"voluemPrice": 20.8326
			},
			{
				"open": 21.0796,
				"close": 20.8161,
				"volume": 374916164,
				"time": 1352178000000,
				"lowest": 20.7175,
				"highest": 21.0979,
				"volumePrice": 20.8878,
				"transactionCount": 75937,
				"voluemPrice": 20.8878
			},
			{
				"open": 20.4941,
				"close": 19.9286,
				"volume": 793648744,
				"time": 1352264400000,
				"lowest": 19.8482,
				"highest": 20.5193,
				"volumePrice": 20.1155,
				"transactionCount": 162983,
				"voluemPrice": 20.1155
			},
			{
				"open": 20.0225,
				"close": 19.2054,
				"volume": 1056145356,
				"time": 1352350800000,
				"lowest": 19.1175,
				"highest": 20.0796,
				"volumePrice": 19.511,
				"transactionCount": 209820,
				"voluemPrice": 19.511
			},
			{
				"open": 19.3007,
				"close": 19.5379,
				"volume": 929905984,
				"time": 1352437200000,
				"lowest": 19.0614,
				"highest": 19.8171,
				"volumePrice": 19.4899,
				"transactionCount": 184069,
				"voluemPrice": 19.4899
			},
			{
				"open": 19.7911,
				"close": 19.3868,
				"volume": 515801916,
				"time": 1352696400000,
				"lowest": 19.2375,
				"highest": 19.8036,
				"volumePrice": 19.4693,
				"transactionCount": 103682,
				"voluemPrice": 19.4693
			},
			{
				"open": 19.2468,
				"close": 19.3892,
				"volume": 533210440,
				"time": 1352782800000,
				"lowest": 19.1557,
				"highest": 19.66,
				"volumePrice": 19.4534,
				"transactionCount": 102636,
				"voluemPrice": 19.4534
			},
			{
				"open": 19.4821,
				"close": 19.1743,
				"volume": 477167908,
				"time": 1352869200000,
				"lowest": 19.1493,
				"highest": 19.5518,
				"volumePrice": 19.3387,
				"transactionCount": 99638,
				"voluemPrice": 19.3387
			},
			{
				"open": 19.1975,
				"close": 18.7721,
				"volume": 789909540,
				"time": 1352955600000,
				"lowest": 18.665,
				"highest": 19.2679,
				"volumePrice": 18.9128,
				"transactionCount": 160496,
				"voluemPrice": 18.9128
			},
			{
				"open": 18.7571,
				"close": 18.8456,
				"volume": 1266891052,
				"time": 1353042000000,
				"lowest": 18.0625,
				"highest": 18.9286,
				"volumePrice": 18.5998,
				"transactionCount": 251411,
				"voluemPrice": 18.5998
			},
			{
				"open": 19.3111,
				"close": 20.2046,
				"volume": 823171860,
				"time": 1353301200000,
				"lowest": 19.2814,
				"highest": 20.2679,
				"volumePrice": 19.793,
				"transactionCount": 168954,
				"voluemPrice": 19.793
			},
			{
				"open": 20.4254,
				"close": 20.0326,
				"volume": 642751424,
				"time": 1353387600000,
				"lowest": 19.8064,
				"highest": 20.4268,
				"volumePrice": 20.0767,
				"transactionCount": 137093,
				"voluemPrice": 20.0767
			},
			{
				"open": 20.1518,
				"close": 20.0607,
				"volume": 373227036,
				"time": 1353474000000,
				"lowest": 19.8786,
				"highest": 20.2632,
				"volumePrice": 20.0568,
				"transactionCount": 75497,
				"voluemPrice": 20.0568
			},
			{
				"open": 20.2561,
				"close": 20.4107,
				"volume": 272826316,
				"time": 1353646800000,
				"lowest": 20.0929,
				"highest": 20.4286,
				"volumePrice": 20.2483,
				"transactionCount": 54190,
				"voluemPrice": 20.2483
			},
			{
				"open": 20.5679,
				"close": 21.0546,
				"volume": 630577724,
				"time": 1353906000000,
				"lowest": 20.4896,
				"highest": 21.0714,
				"volumePrice": 20.8393,
				"transactionCount": 120984,
				"voluemPrice": 20.8393
			},
			{
				"open": 21.0554,
				"close": 20.885,
				"volume": 533313816,
				"time": 1353992400000,
				"lowest": 20.7179,
				"highest": 21.0864,
				"volumePrice": 20.9055,
				"transactionCount": 101779,
				"voluemPrice": 20.9055
			},
			{
				"open": 20.6168,
				"close": 20.8193,
				"volume": 521059924,
				"time": 1354078800000,
				"lowest": 20.4379,
				"highest": 20.9214,
				"volumePrice": 20.6942,
				"transactionCount": 101030,
				"voluemPrice": 20.6942
			},
			{
				"open": 21.0791,
				"close": 21.0486,
				"volume": 514698100,
				"time": 1354165200000,
				"lowest": 20.9018,
				"highest": 21.2232,
				"volumePrice": 21.0695,
				"transactionCount": 99777,
				"voluemPrice": 21.0695
			},
			{
				"open": 20.9568,
				"close": 20.9029,
				"volume": 391299944,
				"time": 1354251600000,
				"lowest": 20.81,
				"highest": 21.0143,
				"volumePrice": 20.8979,
				"transactionCount": 68182,
				"voluemPrice": 20.8979
			},
			{
				"open": 21.2018,
				"close": 20.9354,
				"volume": 364273560,
				"time": 1354510800000,
				"lowest": 20.9107,
				"highest": 21.2354,
				"volumePrice": 21.0572,
				"transactionCount": 70175,
				"voluemPrice": 21.0572
			},
			{
				"open": 20.7786,
				"close": 20.5659,
				"volume": 557929568,
				"time": 1354597200000,
				"lowest": 20.4332,
				"highest": 20.7786,
				"volumePrice": 20.5783,
				"transactionCount": 97136,
				"voluemPrice": 20.5783
			},
			{
				"open": 20.3182,
				"close": 19.2426,
				"volume": 1044637412,
				"time": 1354683600000,
				"lowest": 19.2418,
				"highest": 20.3304,
				"volumePrice": 19.6993,
				"transactionCount": 205407,
				"voluemPrice": 19.6993
			},
			{
				"open": 18.8907,
				"close": 19.5444,
				"volume": 1177171044,
				"time": 1354770000000,
				"lowest": 18.5225,
				"highest": 19.7611,
				"volumePrice": 19.2937,
				"transactionCount": 233477,
				"voluemPrice": 19.2937
			},
			{
				"open": 19.7643,
				"close": 19.0446,
				"volume": 787767176,
				"time": 1354856400000,
				"lowest": 18.9286,
				"highest": 19.8286,
				"volumePrice": 19.2326,
				"transactionCount": 156239,
				"voluemPrice": 19.2326
			},
			{
				"open": 18.75,
				"close": 18.9222,
				"volume": 630482300,
				"time": 1355115600000,
				"lowest": 18.6279,
				"highest": 19.2325,
				"volumePrice": 18.9391,
				"transactionCount": 123471,
				"voluemPrice": 18.9391
			},
			{
				"open": 19.2775,
				"close": 19.3353,
				"volume": 592343612,
				"time": 1355202000000,
				"lowest": 19.1918,
				"highest": 19.6271,
				"volumePrice": 19.4083,
				"transactionCount": 119832,
				"voluemPrice": 19.4083
			},
			{
				"open": 19.5632,
				"close": 19.25,
				"volume": 487103540,
				"time": 1355288400000,
				"lowest": 19.1525,
				"highest": 19.5714,
				"volumePrice": 19.3119,
				"transactionCount": 98887,
				"voluemPrice": 19.3119
			},
			{
				"open": 18.9696,
				"close": 18.9175,
				"volume": 625224908,
				"time": 1355374800000,
				"lowest": 18.7786,
				"highest": 19.2014,
				"volumePrice": 18.9563,
				"transactionCount": 122286,
				"voluemPrice": 18.9563
			},
			{
				"open": 18.3839,
				"close": 18.2069,
				"volume": 1009566796,
				"time": 1355461200000,
				"lowest": 18.0564,
				"highest": 18.5047,
				"volumePrice": 18.2516,
				"transactionCount": 206432,
				"voluemPrice": 18.2516
			},
			{
				"open": 18.1761,
				"close": 18.5296,
				"volume": 758944088,
				"time": 1355720400000,
				"lowest": 17.9011,
				"highest": 18.5714,
				"volumePrice": 18.2426,
				"transactionCount": 156415,
				"voluemPrice": 18.2426
			},
			{
				"open": 18.75,
				"close": 19.0679,
				"volume": 625668092,
				"time": 1355806800000,
				"lowest": 18.5804,
				"highest": 19.1036,
				"volumePrice": 18.8504,
				"transactionCount": 128807,
				"voluemPrice": 18.8504
			},
			{
				"open": 18.9811,
				"close": 18.7968,
				"volume": 449361696,
				"time": 1355893200000,
				"lowest": 18.7679,
				"highest": 19.0607,
				"volumePrice": 18.9074,
				"transactionCount": 93785,
				"voluemPrice": 18.9074
			},
			{
				"open": 18.9286,
				"close": 18.6332,
				"volume": 481687192,
				"time": 1355979600000,
				"lowest": 18.5314,
				"highest": 18.9357,
				"volumePrice": 18.6891,
				"transactionCount": 94260,
				"voluemPrice": 18.6891
			},
			{
				"open": 18.3025,
				"close": 18.5475,
				"volume": 596518216,
				"time": 1356066000000,
				"lowest": 18.2229,
				"highest": 18.5596,
				"volumePrice": 18.3911,
				"transactionCount": 96501,
				"voluemPrice": 18.3911
			},
			{
				"open": 18.5839,
				"close": 18.5774,
				"volume": 175747908,
				"time": 1356325200000,
				"lowest": 18.5254,
				"highest": 18.7232,
				"volumePrice": 18.6153,
				"transactionCount": 34295,
				"voluemPrice": 18.6153
			},
			{
				"open": 18.5357,
				"close": 18.3214,
				"volume": 302436120,
				"time": 1356498000000,
				"lowest": 18.2543,
				"highest": 18.5521,
				"volumePrice": 18.3688,
				"transactionCount": 58905,
				"voluemPrice": 18.3688
			},
			{
				"open": 18.3407,
				"close": 18.395,
				"volume": 455118720,
				"time": 1356584400000,
				"lowest": 18.0236,
				"highest": 18.4375,
				"volumePrice": 18.2176,
				"transactionCount": 93383,
				"voluemPrice": 18.2176
			},
			{
				"open": 18.2246,
				"close": 18.1996,
				"volume": 354276972,
				"time": 1356670800000,
				"lowest": 18.1471,
				"highest": 18.3743,
				"volumePrice": 18.2691,
				"transactionCount": 71306,
				"voluemPrice": 18.2691
			},
			{
				"open": 18.2332,
				"close": 19.0062,
				"volume": 659491140,
				"time": 1356930000000,
				"lowest": 18.1786,
				"highest": 19.1214,
				"volumePrice": 18.7993,
				"transactionCount": 127739,
				"voluemPrice": 18.7993
			},
			{
				"open": 19.7793,
				"close": 19.6082,
				"volume": 560499464,
				"time": 1357102800000,
				"lowest": 19.3439,
				"highest": 19.8214,
				"volumePrice": 19.5856,
				"transactionCount": 116929,
				"voluemPrice": 19.5856
			},
			{
				"open": 19.5671,
				"close": 19.3606,
				"volume": 352963800,
				"time": 1357189200000,
				"lowest": 19.3214,
				"highest": 19.6311,
				"volumePrice": 19.4875,
				"transactionCount": 73604,
				"voluemPrice": 19.4875
			},
			{
				"open": 19.1773,
				"close": 18.8214,
				"volume": 594330240,
				"time": 1357275600000,
				"lowest": 18.7904,
				"highest": 19.2368,
				"volumePrice": 18.948,
				"transactionCount": 123468,
				"voluemPrice": 18.948
			},
			{
				"open": 18.6429,
				"close": 18.7107,
				"volume": 484152704,
				"time": 1357534800000,
				"lowest": 18.4,
				"highest": 18.9036,
				"volumePrice": 18.6531,
				"transactionCount": 99998,
				"voluemPrice": 18.6531
			},
			{
				"open": 18.9004,
				"close": 18.7611,
				"volume": 458707004,
				"time": 1357621200000,
				"lowest": 18.6161,
				"highest": 18.9961,
				"volumePrice": 18.772,
				"transactionCount": 94175,
				"voluemPrice": 18.772
			},
			{
				"open": 18.6607,
				"close": 18.4679,
				"volume": 407599836,
				"time": 1357707600000,
				"lowest": 18.4282,
				"highest": 18.7502,
				"volumePrice": 18.604,
				"transactionCount": 85713,
				"voluemPrice": 18.604
			},
			{
				"open": 18.8768,
				"close": 18.6968,
				"volume": 601141184,
				"time": 1357794000000,
				"lowest": 18.4114,
				"highest": 18.8829,
				"volumePrice": 18.6102,
				"transactionCount": 126780,
				"voluemPrice": 18.6102
			},
			{
				"open": 18.6071,
				"close": 18.5821,
				"volume": 350754964,
				"time": 1357880400000,
				"lowest": 18.5364,
				"highest": 18.7614,
				"volumePrice": 18.6168,
				"transactionCount": 71607,
				"voluemPrice": 18.6168
			},
			{
				"open": 17.9529,
				"close": 17.9196,
				"volume": 734177584,
				"time": 1358139600000,
				"lowest": 17.8039,
				"highest": 18.125,
				"volumePrice": 17.9552,
				"transactionCount": 149206,
				"voluemPrice": 17.9552
			},
			{
				"open": 17.7964,
				"close": 17.3543,
				"volume": 876763328,
				"time": 1358226000000,
				"lowest": 17.2636,
				"highest": 17.8211,
				"volumePrice": 17.4626,
				"transactionCount": 183768,
				"voluemPrice": 17.4626
			},
			{
				"open": 17.6657,
				"close": 18.0746,
				"volume": 690800180,
				"time": 1358312400000,
				"lowest": 17.5893,
				"highest": 18.1943,
				"volumePrice": 17.9406,
				"transactionCount": 144446,
				"voluemPrice": 17.9406
			},
			{
				"open": 18.2254,
				"close": 17.9529,
				"volume": 453650316,
				"time": 1358398800000,
				"lowest": 17.9296,
				"highest": 18.2411,
				"volumePrice": 18.0335,
				"transactionCount": 94140,
				"voluemPrice": 18.0335
			},
			{
				"open": 17.8041,
				"close": 17.8571,
				"volume": 473149068,
				"time": 1358485200000,
				"lowest": 17.7286,
				"highest": 17.9364,
				"volumePrice": 17.8271,
				"transactionCount": 87882,
				"voluemPrice": 17.8271
			},
			{
				"open": 18.02,
				"close": 18.0275,
				"volume": 461544692,
				"time": 1358830800000,
				"lowest": 17.7368,
				"highest": 18.1386,
				"volumePrice": 17.9215,
				"transactionCount": 98002,
				"voluemPrice": 17.9215
			},
			{
				"open": 18.1718,
				"close": 18.3573,
				"volume": 861503412,
				"time": 1358917200000,
				"lowest": 18.0275,
				"highest": 18.3925,
				"volumePrice": 17.9158,
				"transactionCount": 175598,
				"voluemPrice": 17.9158
			},
			{
				"open": 16.4286,
				"close": 16.0893,
				"volume": 1460851812,
				"time": 1359003600000,
				"lowest": 16.0804,
				"highest": 16.6332,
				"volumePrice": 16.3451,
				"transactionCount": 295817,
				"voluemPrice": 16.3451
			},
			{
				"open": 16.1318,
				"close": 15.71,
				"volume": 1207968132,
				"time": 1359090000000,
				"lowest": 15.5357,
				"highest": 16.2939,
				"volumePrice": 15.8935,
				"transactionCount": 236839,
				"voluemPrice": 15.8935
			},
			{
				"open": 15.6366,
				"close": 16.0654,
				"volume": 785515724,
				"time": 1359349200000,
				"lowest": 15.5664,
				"highest": 16.1861,
				"volumePrice": 16.0056,
				"transactionCount": 156723,
				"voluemPrice": 16.0056
			},
			{
				"open": 16.375,
				"close": 16.3668,
				"volume": 571072068,
				"time": 1359435600000,
				"lowest": 16.1471,
				"highest": 16.4357,
				"volumePrice": 16.3281,
				"transactionCount": 117722,
				"voluemPrice": 16.3281
			},
			{
				"open": 16.3214,
				"close": 16.3154,
				"volume": 417153268,
				"time": 1359522000000,
				"lowest": 16.2321,
				"highest": 16.5214,
				"volumePrice": 16.3877,
				"transactionCount": 87109,
				"voluemPrice": 16.3877
			},
			{
				"open": 16.3207,
				"close": 16.2675,
				"volume": 319332860,
				"time": 1359608400000,
				"lowest": 16.2493,
				"highest": 16.4029,
				"volumePrice": 16.3025,
				"transactionCount": 59221,
				"voluemPrice": 16.3025
			},
			{
				"open": 16.3968,
				"close": 16.2007,
				"volume": 539468356,
				"time": 1359694800000,
				"lowest": 16.0125,
				"highest": 16.41,
				"volumePrice": 16.1847,
				"transactionCount": 105983,
				"voluemPrice": 16.1847
			},
			{
				"open": 16.2111,
				"close": 15.797,
				"volume": 477115016,
				"time": 1359954000000,
				"lowest": 15.7857,
				"highest": 16.2836,
				"volumePrice": 15.9782,
				"transactionCount": 99165,
				"voluemPrice": 15.9782
			},
			{
				"open": 15.8589,
				"close": 16.3516,
				"volume": 573346144,
				"time": 1360040400000,
				"lowest": 15.7936,
				"highest": 16.4193,
				"volumePrice": 16.1637,
				"transactionCount": 118118,
				"voluemPrice": 16.1637
			},
			{
				"open": 16.3025,
				"close": 16.3339,
				"volume": 593704048,
				"time": 1360126800000,
				"lowest": 16.1636,
				"highest": 16.6607,
				"volumePrice": 16.4063,
				"transactionCount": 124343,
				"voluemPrice": 16.4063
			},
			{
				"open": 16.5446,
				"close": 16.7221,
				"volume": 704580296,
				"time": 1360213200000,
				"lowest": 16.2186,
				"highest": 16.7857,
				"volumePrice": 16.4649,
				"transactionCount": 146550,
				"voluemPrice": 16.4649
			},
			{
				"open": 16.9286,
				"close": 16.9636,
				"volume": 633155936,
				"time": 1360299600000,
				"lowest": 16.7946,
				"highest": 17.1004,
				"volumePrice": 16.9643,
				"transactionCount": 131931,
				"voluemPrice": 16.9643
			},
			{
				"open": 17.0179,
				"close": 17.1404,
				"volume": 517434064,
				"time": 1360558800000,
				"lowest": 16.9018,
				"highest": 17.3193,
				"volumePrice": 17.174,
				"transactionCount": 97756,
				"voluemPrice": 17.174
			},
			{
				"open": 17.1254,
				"close": 16.7107,
				"volume": 609050848,
				"time": 1360645200000,
				"lowest": 16.7051,
				"highest": 17.2279,
				"volumePrice": 16.8934,
				"transactionCount": 122189,
				"voluemPrice": 16.8934
			},
			{
				"open": 16.6861,
				"close": 16.6789,
				"volume": 475204884,
				"time": 1360731600000,
				"lowest": 16.5436,
				"highest": 16.9157,
				"volumePrice": 16.7203,
				"transactionCount": 90588,
				"voluemPrice": 16.7203
			},
			{
				"open": 16.59,
				"close": 16.6639,
				"volume": 355506004,
				"time": 1360818000000,
				"lowest": 16.5721,
				"highest": 16.8443,
				"volumePrice": 16.7054,
				"transactionCount": 66093,
				"voluemPrice": 16.7054
			},
			{
				"open": 16.7446,
				"close": 16.4343,
				"volume": 391924708,
				"time": 1360904400000,
				"lowest": 16.4257,
				"highest": 16.7914,
				"volumePrice": 16.5849,
				"transactionCount": 74324,
				"voluemPrice": 16.5849
			},
			{
				"open": 16.4679,
				"close": 16.4282,
				"volume": 435776852,
				"time": 1361250000000,
				"lowest": 16.2089,
				"highest": 16.5261,
				"volumePrice": 16.3567,
				"transactionCount": 84455,
				"voluemPrice": 16.3567
			},
			{
				"open": 16.3461,
				"close": 16.0304,
				"volume": 476299544,
				"time": 1361336400000,
				"lowest": 16.0286,
				"highest": 16.3461,
				"volumePrice": 16.1385,
				"transactionCount": 94991,
				"voluemPrice": 16.1385
			},
			{
				"open": 15.9286,
				"close": 15.9307,
				"volume": 447180720,
				"time": 1361422800000,
				"lowest": 15.815,
				"highest": 16.0418,
				"volumePrice": 15.9267,
				"transactionCount": 88750,
				"voluemPrice": 15.9267
			},
			{
				"open": 16.0446,
				"close": 16.1004,
				"volume": 330654576,
				"time": 1361509200000,
				"lowest": 15.95,
				"highest": 16.1286,
				"volumePrice": 16.0378,
				"transactionCount": 65709,
				"voluemPrice": 16.0378
			},
			{
				"open": 16.2089,
				"close": 15.8143,
				"volume": 372577856,
				"time": 1361768400000,
				"lowest": 15.8061,
				"highest": 16.2543,
				"volumePrice": 16.0481,
				"transactionCount": 76170,
				"voluemPrice": 16.0481
			},
			{
				"open": 15.8507,
				"close": 16.0346,
				"volume": 501496296,
				"time": 1361854800000,
				"lowest": 15.6307,
				"highest": 16.1264,
				"volumePrice": 15.866,
				"transactionCount": 100189,
				"voluemPrice": 15.866
			},
			{
				"open": 16.0154,
				"close": 15.8775,
				"volume": 587347712,
				"time": 1361941200000,
				"lowest": 15.7375,
				"highest": 16.1586,
				"volumePrice": 15.9474,
				"transactionCount": 118079,
				"voluemPrice": 15.9474
			},
			{
				"open": 15.8589,
				"close": 15.7643,
				"volume": 322514948,
				"time": 1362027600000,
				"lowest": 15.7643,
				"highest": 15.9954,
				"volumePrice": 15.8765,
				"transactionCount": 55678,
				"voluemPrice": 15.8765
			},
			{
				"open": 15.6429,
				"close": 15.3739,
				"volume": 552447168,
				"time": 1362114000000,
				"lowest": 15.3564,
				"highest": 15.6493,
				"volumePrice": 15.475,
				"transactionCount": 115174,
				"voluemPrice": 15.475
			},
			{
				"open": 15.2786,
				"close": 15.0018,
				"volume": 582737596,
				"time": 1362373200000,
				"lowest": 14.9643,
				"highest": 15.2929,
				"volumePrice": 15.1195,
				"transactionCount": 114913,
				"voluemPrice": 15.1195
			},
			{
				"open": 15.0529,
				"close": 15.398,
				"volume": 638428168,
				"time": 1362459600000,
				"lowest": 15.0268,
				"highest": 15.5425,
				"volumePrice": 15.334,
				"transactionCount": 122361,
				"voluemPrice": 15.334
			},
			{
				"open": 15.5182,
				"close": 15.2022,
				"volume": 460249076,
				"time": 1362546000000,
				"lowest": 15.1582,
				"highest": 15.5446,
				"volumePrice": 15.2769,
				"transactionCount": 87907,
				"voluemPrice": 15.2769
			},
			{
				"open": 15.1607,
				"close": 15.3779,
				"volume": 468471304,
				"time": 1362632400000,
				"lowest": 15.0379,
				"highest": 15.4289,
				"volumePrice": 15.2459,
				"transactionCount": 92319,
				"voluemPrice": 15.2459
			},
			{
				"open": 15.35,
				"close": 15.4186,
				"volume": 391547044,
				"time": 1362718800000,
				"lowest": 15.3075,
				"highest": 15.5511,
				"volumePrice": 15.4283,
				"transactionCount": 77576,
				"voluemPrice": 15.4283
			},
			{
				"open": 15.3482,
				"close": 15.6382,
				"volume": 474233424,
				"time": 1362974400000,
				"lowest": 15.1836,
				"highest": 15.6789,
				"volumePrice": 15.4452,
				"transactionCount": 91861,
				"voluemPrice": 15.4452
			},
			{
				"open": 15.5571,
				"close": 15.3011,
				"volume": 465909892,
				"time": 1363060800000,
				"lowest": 15.2704,
				"highest": 15.6743,
				"volumePrice": 15.4294,
				"transactionCount": 95815,
				"voluemPrice": 15.4294
			},
			{
				"open": 15.3018,
				"close": 15.2982,
				"volume": 405816768,
				"time": 1363147200000,
				"lowest": 15.1914,
				"highest": 15.5179,
				"volumePrice": 15.3624,
				"transactionCount": 75748,
				"voluemPrice": 15.3624
			},
			{
				"open": 15.4582,
				"close": 15.4464,
				"volume": 303874984,
				"time": 1363233600000,
				"lowest": 15.3732,
				"highest": 15.5229,
				"volumePrice": 15.4466,
				"transactionCount": 62833,
				"voluemPrice": 15.4466
			},
			{
				"open": 15.6404,
				"close": 15.845,
				"volume": 643959148,
				"time": 1363320000000,
				"lowest": 15.6161,
				"highest": 15.8654,
				"volumePrice": 15.7492,
				"transactionCount": 117295,
				"voluemPrice": 15.7492
			},
			{
				"open": 15.7661,
				"close": 16.2757,
				"volume": 606196360,
				"time": 1363579200000,
				"lowest": 15.7571,
				"highest": 16.3379,
				"volumePrice": 16.1218,
				"transactionCount": 117613,
				"voluemPrice": 16.1218
			},
			{
				"open": 16.4107,
				"close": 16.2318,
				"volume": 526773240,
				"time": 1363665600000,
				"lowest": 16.0179,
				"highest": 16.4632,
				"volumePrice": 16.2144,
				"transactionCount": 106968,
				"voluemPrice": 16.2144
			},
			{
				"open": 16.3364,
				"close": 16.1457,
				"volume": 308660632,
				"time": 1363752000000,
				"lowest": 16.0568,
				"highest": 16.3439,
				"volumePrice": 16.1491,
				"transactionCount": 62380,
				"voluemPrice": 16.1491
			},
			{
				"open": 16.0793,
				"close": 16.1689,
				"volume": 383253640,
				"time": 1363838400000,
				"lowest": 16.075,
				"highest": 16.3564,
				"volumePrice": 16.2202,
				"transactionCount": 77565,
				"voluemPrice": 16.2202
			},
			{
				"open": 16.235,
				"close": 16.4968,
				"volume": 395104444,
				"time": 1363924800000,
				"lowest": 16.1825,
				"highest": 16.5036,
				"volumePrice": 16.3884,
				"transactionCount": 80521,
				"voluemPrice": 16.3884
			},
			{
				"open": 16.5961,
				"close": 16.5564,
				"volume": 501133752,
				"time": 1364184000000,
				"lowest": 16.4921,
				"highest": 16.7839,
				"volumePrice": 16.6212,
				"transactionCount": 101997,
				"voluemPrice": 16.6212
			},
			{
				"open": 16.6229,
				"close": 16.4691,
				"volume": 294260092,
				"time": 1364270400000,
				"lowest": 16.4475,
				"highest": 16.6371,
				"volumePrice": 16.5118,
				"transactionCount": 60811,
				"voluemPrice": 16.5118
			},
			{
				"open": 16.3021,
				"close": 16.1457,
				"volume": 331395176,
				"time": 1364356800000,
				"lowest": 16.0975,
				"highest": 16.3143,
				"volumePrice": 16.1875,
				"transactionCount": 72166,
				"voluemPrice": 16.1875
			},
			{
				"open": 16.065,
				"close": 15.8093,
				"volume": 442982344,
				"time": 1364443200000,
				"lowest": 15.7721,
				"highest": 16.1364,
				"volumePrice": 15.9054,
				"transactionCount": 87844,
				"voluemPrice": 15.9054
			},
			{
				"open": 15.7821,
				"close": 15.3182,
				"volume": 389729956,
				"time": 1364788800000,
				"lowest": 15.2764,
				"highest": 15.8464,
				"volumePrice": 15.5059,
				"transactionCount": 83887,
				"voluemPrice": 15.5059
			},
			{
				"open": 15.2714,
				"close": 15.3497,
				"volume": 529772936,
				"time": 1364875200000,
				"lowest": 15.2286,
				"highest": 15.6479,
				"volumePrice": 15.4497,
				"transactionCount": 111548,
				"voluemPrice": 15.4497
			},
			{
				"open": 15.4061,
				"close": 15.4282,
				"volume": 363214908,
				"time": 1364961600000,
				"lowest": 15.3682,
				"highest": 15.6171,
				"volumePrice": 15.4827,
				"transactionCount": 72872,
				"voluemPrice": 15.4827
			},
			{
				"open": 15.4914,
				"close": 15.2757,
				"volume": 358658552,
				"time": 1365048000000,
				"lowest": 15.1875,
				"highest": 15.5357,
				"volumePrice": 15.3023,
				"transactionCount": 77269,
				"voluemPrice": 15.3023
			},
			{
				"open": 15.1607,
				"close": 15.1143,
				"volume": 383693912,
				"time": 1365134400000,
				"lowest": 14.9886,
				"highest": 15.1768,
				"volumePrice": 15.0912,
				"transactionCount": 80197,
				"voluemPrice": 15.0912
			},
			{
				"open": 15.1732,
				"close": 15.2218,
				"volume": 301058968,
				"time": 1365393600000,
				"lowest": 15.0889,
				"highest": 15.2679,
				"volumePrice": 15.1805,
				"transactionCount": 57695,
				"voluemPrice": 15.1805
			},
			{
				"open": 15.2271,
				"close": 15.2493,
				"volume": 306752460,
				"time": 1365480000000,
				"lowest": 15.0982,
				"highest": 15.3036,
				"volumePrice": 15.1976,
				"transactionCount": 59733,
				"voluemPrice": 15.1976
			},
			{
				"open": 15.2893,
				"close": 15.5604,
				"volume": 375926992,
				"time": 1365566400000,
				"lowest": 15.2146,
				"highest": 15.6093,
				"volumePrice": 15.4601,
				"transactionCount": 77910,
				"voluemPrice": 15.4601
			},
			{
				"open": 15.49,
				"close": 15.5118,
				"volume": 328616372,
				"time": 1365652800000,
				"lowest": 15.4,
				"highest": 15.6425,
				"volumePrice": 15.5121,
				"transactionCount": 68008,
				"voluemPrice": 15.5121
			},
			{
				"open": 15.5054,
				"close": 15.35,
				"volume": 238725116,
				"time": 1365739200000,
				"lowest": 15.3246,
				"highest": 15.5054,
				"volumePrice": 15.3827,
				"transactionCount": 46975,
				"voluemPrice": 15.3827
			},
			{
				"open": 15.25,
				"close": 14.9946,
				"volume": 317500792,
				"time": 1365998400000,
				"lowest": 14.9839,
				"highest": 15.2818,
				"volumePrice": 15.1017,
				"transactionCount": 62928,
				"voluemPrice": 15.1017
			},
			{
				"open": 15.0561,
				"close": 15.2229,
				"volume": 305771032,
				"time": 1366084800000,
				"lowest": 15.0204,
				"highest": 15.2361,
				"volumePrice": 15.1429,
				"transactionCount": 60517,
				"voluemPrice": 15.1429
			},
			{
				"open": 15.0096,
				"close": 14.3857,
				"volume": 945053844,
				"time": 1366171200000,
				"lowest": 14.2182,
				"highest": 15.0214,
				"volumePrice": 14.5029,
				"transactionCount": 197056,
				"voluemPrice": 14.5029
			},
			{
				"open": 14.4639,
				"close": 14.0018,
				"volume": 666296652,
				"time": 1366257600000,
				"lowest": 13.9194,
				"highest": 14.4925,
				"volumePrice": 14.1224,
				"transactionCount": 137532,
				"voluemPrice": 14.1224
			},
			{
				"open": 13.8561,
				"close": 13.9475,
				"volume": 609272804,
				"time": 1366344000000,
				"lowest": 13.7536,
				"highest": 14.2714,
				"volumePrice": 14.037,
				"transactionCount": 117052,
				"voluemPrice": 14.037
			},
			{
				"open": 14.0229,
				"close": 14.2382,
				"volume": 429914968,
				"time": 1366603200000,
				"lowest": 13.9741,
				"highest": 14.3643,
				"volumePrice": 14.1711,
				"transactionCount": 82336,
				"voluemPrice": 14.1711
			},
			{
				"open": 14.4282,
				"close": 14.5046,
				"volume": 664235796,
				"time": 1366689600000,
				"lowest": 14.2432,
				"highest": 14.585,
				"volumePrice": 14.568,
				"transactionCount": 124813,
				"voluemPrice": 14.568
			},
			{
				"open": 14.055,
				"close": 14.4808,
				"volume": 969833564,
				"time": 1366776000000,
				"lowest": 14.0179,
				"highest": 14.8304,
				"volumePrice": 14.4159,
				"transactionCount": 192812,
				"voluemPrice": 14.4159
			},
			{
				"open": 14.6866,
				"close": 14.585,
				"volume": 384836620,
				"time": 1366862400000,
				"lowest": 14.5357,
				"highest": 14.7836,
				"volumePrice": 14.636,
				"transactionCount": 72771,
				"voluemPrice": 14.636
			},
			{
				"open": 14.6361,
				"close": 14.9002,
				"volume": 764275960,
				"time": 1366948800000,
				"lowest": 14.5804,
				"highest": 14.956,
				"volumePrice": 14.8101,
				"transactionCount": 96206,
				"voluemPrice": 14.8101
			},
			{
				"open": 15.0161,
				"close": 15.3614,
				"volume": 640324440,
				"time": 1367208000000,
				"lowest": 15,
				"highest": 15.4863,
				"volumePrice": 15.3235,
				"transactionCount": 105930,
				"voluemPrice": 15.3235
			},
			{
				"open": 15.5393,
				"close": 15.8136,
				"volume": 691537448,
				"time": 1367294400000,
				"lowest": 15.4311,
				"highest": 15.9018,
				"volumePrice": 15.7027,
				"transactionCount": 128475,
				"voluemPrice": 15.7027
			},
			{
				"open": 15.8736,
				"close": 15.6889,
				"volume": 507154788,
				"time": 1367380800000,
				"lowest": 15.5139,
				"highest": 15.8904,
				"volumePrice": 15.6866,
				"transactionCount": 98230,
				"voluemPrice": 15.6866
			},
			{
				"open": 15.7779,
				"close": 15.9114,
				"volume": 422024736,
				"time": 1367467200000,
				"lowest": 15.7368,
				"highest": 16.0211,
				"volumePrice": 15.949,
				"transactionCount": 78564,
				"voluemPrice": 15.949
			},
			{
				"open": 16.1182,
				"close": 16.0707,
				"volume": 361518136,
				"time": 1367553600000,
				"lowest": 16.0411,
				"highest": 16.1868,
				"volumePrice": 16.1056,
				"transactionCount": 71674,
				"voluemPrice": 16.1056
			},
			{
				"open": 16.2754,
				"close": 16.4539,
				"volume": 496640648,
				"time": 1367812800000,
				"lowest": 16.2254,
				"highest": 16.5071,
				"volumePrice": 16.4216,
				"transactionCount": 87580,
				"voluemPrice": 16.4216
			},
			{
				"open": 16.6061,
				"close": 16.3806,
				"volume": 483752304,
				"time": 1367899200000,
				"lowest": 16.2036,
				"highest": 16.6339,
				"volumePrice": 16.398,
				"transactionCount": 94544,
				"voluemPrice": 16.398
			},
			{
				"open": 16.3943,
				"close": 16.5657,
				"volume": 472596152,
				"time": 1367985600000,
				"lowest": 16.2789,
				"highest": 16.6204,
				"volumePrice": 16.532,
				"transactionCount": 85824,
				"voluemPrice": 16.532
			},
			{
				"open": 16.4218,
				"close": 16.3132,
				"volume": 398749904,
				"time": 1368072000000,
				"lowest": 16.2707,
				"highest": 16.5357,
				"volumePrice": 16.4071,
				"transactionCount": 78482,
				"voluemPrice": 16.4071
			},
			{
				"open": 16.3561,
				"close": 16.1775,
				"volume": 334849928,
				"time": 1368158400000,
				"lowest": 16.0886,
				"highest": 16.4182,
				"volumePrice": 16.1964,
				"transactionCount": 67771,
				"voluemPrice": 16.1964
			},
			{
				"open": 16.1254,
				"close": 16.2407,
				"volume": 317109240,
				"time": 1368417600000,
				"lowest": 16.125,
				"highest": 16.3536,
				"volumePrice": 16.2629,
				"transactionCount": 57203,
				"voluemPrice": 16.2629
			},
			{
				"open": 16.2089,
				"close": 15.8521,
				"volume": 447115760,
				"time": 1368504000000,
				"lowest": 15.7911,
				"highest": 16.2571,
				"volumePrice": 16.0056,
				"transactionCount": 90136,
				"voluemPrice": 16.0056
			},
			{
				"open": 15.6843,
				"close": 15.3161,
				"volume": 741612340,
				"time": 1368590400000,
				"lowest": 15.0843,
				"highest": 15.75,
				"volumePrice": 15.3827,
				"transactionCount": 146673,
				"voluemPrice": 15.3827
			},
			{
				"open": 15.1157,
				"close": 15.5206,
				"volume": 603463112,
				"time": 1368676800000,
				"lowest": 14.9607,
				"highest": 15.6375,
				"volumePrice": 15.4146,
				"transactionCount": 119269,
				"voluemPrice": 15.4146
			},
			{
				"open": 15.6804,
				"close": 15.4736,
				"volume": 428079316,
				"time": 1368763200000,
				"lowest": 15.3932,
				"highest": 15.7175,
				"volumePrice": 15.5159,
				"transactionCount": 80136,
				"voluemPrice": 15.5159
			},
			{
				"open": 15.4254,
				"close": 15.8189,
				"volume": 451575936,
				"time": 1369022400000,
				"lowest": 15.3607,
				"highest": 15.9214,
				"volumePrice": 15.7525,
				"transactionCount": 80763,
				"voluemPrice": 15.7525
			},
			{
				"open": 15.6482,
				"close": 15.7021,
				"volume": 456296960,
				"time": 1369108800000,
				"lowest": 15.5071,
				"highest": 15.91,
				"volumePrice": 15.7048,
				"transactionCount": 88460,
				"voluemPrice": 15.7048
			},
			{
				"open": 15.8589,
				"close": 15.7626,
				"volume": 443259432,
				"time": 1369195200000,
				"lowest": 15.6507,
				"highest": 16.0125,
				"volumePrice": 15.8555,
				"transactionCount": 88653,
				"voluemPrice": 15.8555
			},
			{
				"open": 15.5696,
				"close": 15.7907,
				"volume": 353380944,
				"time": 1369281600000,
				"lowest": 15.5639,
				"highest": 15.9343,
				"volumePrice": 15.7955,
				"transactionCount": 75280,
				"voluemPrice": 15.7955
			},
			{
				"open": 15.7446,
				"close": 15.8982,
				"volume": 276426556,
				"time": 1369368000000,
				"lowest": 15.7271,
				"highest": 15.9164,
				"volumePrice": 15.8619,
				"transactionCount": 57607,
				"voluemPrice": 15.8619
			},
			{
				"open": 16.0679,
				"close": 15.7657,
				"volume": 386144752,
				"time": 1369713600000,
				"lowest": 15.7446,
				"highest": 16.1111,
				"volumePrice": 15.8922,
				"transactionCount": 73230,
				"voluemPrice": 15.8922
			},
			{
				"open": 15.7143,
				"close": 15.8911,
				"volume": 330782676,
				"time": 1369800000000,
				"lowest": 15.6929,
				"highest": 15.9821,
				"volumePrice": 15.8801,
				"transactionCount": 66167,
				"voluemPrice": 15.8801
			},
			{
				"open": 15.9161,
				"close": 16.1279,
				"volume": 353714592,
				"time": 1369886400000,
				"lowest": 15.8754,
				"highest": 16.2321,
				"volumePrice": 16.0601,
				"transactionCount": 70989,
				"voluemPrice": 16.0601
			},
			{
				"open": 16.1607,
				"close": 16.062,
				"volume": 384300168,
				"time": 1369972800000,
				"lowest": 16.0536,
				"highest": 16.325,
				"volumePrice": 16.2102,
				"transactionCount": 75074,
				"voluemPrice": 16.2102
			},
			{
				"open": 16.0975,
				"close": 16.0971,
				"volume": 372348564,
				"time": 1370232000000,
				"lowest": 15.8029,
				"highest": 16.1557,
				"volumePrice": 15.9622,
				"transactionCount": 73311,
				"voluemPrice": 15.9622
			},
			{
				"open": 16.1864,
				"close": 16.0468,
				"volume": 292726084,
				"time": 1370318400000,
				"lowest": 15.9782,
				"highest": 16.2296,
				"volumePrice": 16.1147,
				"transactionCount": 60252,
				"voluemPrice": 16.1147
			},
			{
				"open": 15.9161,
				"close": 15.8968,
				"volume": 290589348,
				"time": 1370404800000,
				"lowest": 15.8468,
				"highest": 16.0971,
				"volumePrice": 15.9415,
				"transactionCount": 59738,
				"voluemPrice": 15.9415
			},
			{
				"open": 15.9096,
				"close": 15.6593,
				"volume": 417073832,
				"time": 1370491200000,
				"lowest": 15.5018,
				"highest": 15.9643,
				"volumePrice": 15.6688,
				"transactionCount": 84272,
				"voluemPrice": 15.6688
			},
			{
				"open": 15.5893,
				"close": 15.779,
				"volume": 404742744,
				"time": 1370577600000,
				"lowest": 15.4561,
				"highest": 15.83,
				"volumePrice": 15.6611,
				"transactionCount": 79492,
				"voluemPrice": 15.6611
			},
			{
				"open": 15.8832,
				"close": 15.6746,
				"volume": 450356172,
				"time": 1370836800000,
				"lowest": 15.6,
				"highest": 16.0385,
				"volumePrice": 15.8471,
				"transactionCount": 91815,
				"voluemPrice": 15.8471
			},
			{
				"open": 15.5621,
				"close": 15.6286,
				"volume": 286384084,
				"time": 1370923200000,
				"lowest": 15.4757,
				"highest": 15.8129,
				"volumePrice": 15.6959,
				"transactionCount": 59037,
				"voluemPrice": 15.6959
			},
			{
				"open": 15.6964,
				"close": 15.4354,
				"volume": 265423704,
				"time": 1371009600000,
				"lowest": 15.4107,
				"highest": 15.7589,
				"volumePrice": 15.5427,
				"transactionCount": 55509,
				"voluemPrice": 15.5427
			},
			{
				"open": 15.4464,
				"close": 15.5702,
				"volume": 286112344,
				"time": 1371096000000,
				"lowest": 15.3125,
				"highest": 15.6121,
				"volumePrice": 15.4601,
				"transactionCount": 61953,
				"voluemPrice": 15.4601
			},
			{
				"open": 15.55,
				"close": 15.3589,
				"volume": 272083644,
				"time": 1371182400000,
				"lowest": 15.3036,
				"highest": 15.5818,
				"volumePrice": 15.4085,
				"transactionCount": 55911,
				"voluemPrice": 15.4085
			},
			{
				"open": 15.4086,
				"close": 15.4286,
				"volume": 259441812,
				"time": 1371441600000,
				"lowest": 15.3701,
				"highest": 15.5607,
				"volumePrice": 15.4652,
				"transactionCount": 55050,
				"voluemPrice": 15.4652
			},
			{
				"open": 15.4129,
				"close": 15.4204,
				"volume": 195161792,
				"time": 1371528000000,
				"lowest": 15.3646,
				"highest": 15.5321,
				"volumePrice": 15.4447,
				"transactionCount": 41529,
				"voluemPrice": 15.4447
			},
			{
				"open": 15.4071,
				"close": 15.1071,
				"volume": 311031140,
				"time": 1371614400000,
				"lowest": 15.1071,
				"highest": 15.4164,
				"volumePrice": 15.2318,
				"transactionCount": 59665,
				"voluemPrice": 15.2318
			},
			{
				"open": 14.975,
				"close": 14.8871,
				"volume": 357498232,
				"time": 1371700800000,
				"lowest": 14.8275,
				"highest": 15.2136,
				"volumePrice": 15.0018,
				"transactionCount": 76296,
				"voluemPrice": 15.0018
			},
			{
				"open": 14.9461,
				"close": 14.7679,
				"volume": 481249384,
				"time": 1371787200000,
				"lowest": 14.575,
				"highest": 15,
				"volumePrice": 14.7576,
				"transactionCount": 82695,
				"voluemPrice": 14.7576
			},
			{
				"open": 14.55,
				"close": 14.3764,
				"volume": 480745720,
				"time": 1372046400000,
				"lowest": 14.2161,
				"highest": 14.595,
				"volumePrice": 14.3687,
				"transactionCount": 102332,
				"voluemPrice": 14.3687
			},
			{
				"open": 14.4893,
				"close": 14.3796,
				"volume": 314339144,
				"time": 1372132800000,
				"lowest": 14.2439,
				"highest": 14.5639,
				"volumePrice": 14.362,
				"transactionCount": 65885,
				"voluemPrice": 14.362
			},
			{
				"open": 14.425,
				"close": 14.2168,
				"volume": 367946292,
				"time": 1372219200000,
				"lowest": 14.1307,
				"highest": 14.4568,
				"volumePrice": 14.2388,
				"transactionCount": 71281,
				"voluemPrice": 14.2388
			},
			{
				"open": 14.2589,
				"close": 14.0636,
				"volume": 337400196,
				"time": 1372305600000,
				"lowest": 14.055,
				"highest": 14.3354,
				"volumePrice": 14.1596,
				"transactionCount": 67385,
				"voluemPrice": 14.1596
			},
			{
				"open": 13.9771,
				"close": 14.1618,
				"volume": 578497668,
				"time": 1372392000000,
				"lowest": 13.8882,
				"highest": 14.2954,
				"volumePrice": 14.1094,
				"transactionCount": 93419,
				"voluemPrice": 14.1094
			},
			{
				"open": 14.3818,
				"close": 14.615,
				"volume": 391154540,
				"time": 1372651200000,
				"lowest": 14.3293,
				"highest": 14.7239,
				"volumePrice": 14.5769,
				"transactionCount": 79205,
				"voluemPrice": 14.5769
			},
			{
				"open": 14.6414,
				"close": 14.9461,
				"volume": 470086316,
				"time": 1372737600000,
				"lowest": 14.6239,
				"highest": 15.0582,
				"volumePrice": 14.8961,
				"transactionCount": 96879,
				"voluemPrice": 14.8961
			},
			{
				"open": 15.0307,
				"close": 15.0286,
				"volume": 240928632,
				"time": 1372824000000,
				"lowest": 14.9089,
				"highest": 15.1064,
				"volumePrice": 15.0093,
				"transactionCount": 53207,
				"voluemPrice": 15.0093
			},
			{
				"open": 15.0139,
				"close": 14.9079,
				"volume": 274083040,
				"time": 1372996800000,
				"lowest": 14.8339,
				"highest": 15.1175,
				"volumePrice": 14.9302,
				"transactionCount": 56970,
				"voluemPrice": 14.9302
			},
			{
				"open": 15.0039,
				"close": 14.8232,
				"volume": 298310880,
				"time": 1373256000000,
				"lowest": 14.6661,
				"highest": 15.0357,
				"volumePrice": 14.7982,
				"transactionCount": 61317,
				"voluemPrice": 14.7982
			},
			{
				"open": 14.7714,
				"close": 15.0839,
				"volume": 352688952,
				"time": 1373342400000,
				"lowest": 14.6564,
				"highest": 15.125,
				"volumePrice": 14.9416,
				"transactionCount": 72999,
				"voluemPrice": 14.9416
			},
			{
				"open": 14.9857,
				"close": 15.0261,
				"volume": 281403276,
				"time": 1373428800000,
				"lowest": 14.9375,
				"highest": 15.1714,
				"volumePrice": 15.0655,
				"transactionCount": 60572,
				"voluemPrice": 15.0655
			},
			{
				"open": 15.1054,
				"close": 15.2603,
				"volume": 326475856,
				"time": 1373515200000,
				"lowest": 15.0418,
				"highest": 15.2946,
				"volumePrice": 15.2029,
				"transactionCount": 65427,
				"voluemPrice": 15.2029
			},
			{
				"open": 15.2732,
				"close": 15.2325,
				"volume": 279620852,
				"time": 1373601600000,
				"lowest": 15.1218,
				"highest": 15.3496,
				"volumePrice": 15.2301,
				"transactionCount": 56933,
				"voluemPrice": 15.2301
			},
			{
				"open": 15.1789,
				"close": 15.2657,
				"volume": 242172420,
				"time": 1373860800000,
				"lowest": 15.1714,
				"highest": 15.4093,
				"volumePrice": 15.3072,
				"transactionCount": 42802,
				"voluemPrice": 15.3072
			},
			{
				"open": 15.2329,
				"close": 15.3641,
				"volume": 216537468,
				"time": 1373947200000,
				"lowest": 15.1489,
				"highest": 15.3825,
				"volumePrice": 15.2908,
				"transactionCount": 47563,
				"voluemPrice": 15.2908
			},
			{
				"open": 15.3464,
				"close": 15.3682,
				"volume": 199056256,
				"time": 1374033600000,
				"lowest": 15.2936,
				"highest": 15.4364,
				"volumePrice": 15.3662,
				"transactionCount": 40352,
				"voluemPrice": 15.3662
			},
			{
				"open": 15.4779,
				"close": 15.4199,
				"volume": 219043272,
				"time": 1374120000000,
				"lowest": 15.3789,
				"highest": 15.5311,
				"volumePrice": 15.4564,
				"transactionCount": 41705,
				"voluemPrice": 15.4564
			},
			{
				"open": 15.4679,
				"close": 15.1768,
				"volume": 268778860,
				"time": 1374206400000,
				"lowest": 15.1554,
				"highest": 15.4993,
				"volumePrice": 15.2753,
				"transactionCount": 52296,
				"voluemPrice": 15.2753
			},
			{
				"open": 15.3379,
				"close": 15.2254,
				"volume": 207990384,
				"time": 1374465600000,
				"lowest": 15.1953,
				"highest": 15.3482,
				"volumePrice": 15.2598,
				"transactionCount": 37680,
				"voluemPrice": 15.2598
			},
			{
				"open": 15.2143,
				"close": 14.9639,
				"volume": 369393612,
				"time": 1374552000000,
				"lowest": 14.9539,
				"highest": 15.2486,
				"volumePrice": 15.2196,
				"transactionCount": 82063,
				"voluemPrice": 15.2196
			},
			{
				"open": 15.6761,
				"close": 15.7325,
				"volume": 592055296,
				"time": 1374638400000,
				"lowest": 15.545,
				"highest": 15.8782,
				"volumePrice": 15.7481,
				"transactionCount": 126777,
				"voluemPrice": 15.7481
			},
			{
				"open": 15.7393,
				"close": 15.6607,
				"volume": 229682544,
				"time": 1374724800000,
				"lowest": 15.5646,
				"highest": 15.7643,
				"volumePrice": 15.6551,
				"transactionCount": 49010,
				"voluemPrice": 15.6551
			},
			{
				"open": 15.5464,
				"close": 15.7496,
				"volume": 200275012,
				"time": 1374811200000,
				"lowest": 15.5121,
				"highest": 15.7514,
				"volumePrice": 15.6792,
				"transactionCount": 43183,
				"voluemPrice": 15.6792
			},
			{
				"open": 15.7429,
				"close": 15.9925,
				"volume": 248441200,
				"time": 1375070400000,
				"lowest": 15.7214,
				"highest": 16.0711,
				"volumePrice": 15.9754,
				"transactionCount": 53235,
				"voluemPrice": 15.9754
			},
			{
				"open": 16.07,
				"close": 16.19,
				"volume": 309422260,
				"time": 1375156800000,
				"lowest": 16.0439,
				"highest": 16.3268,
				"volumePrice": 16.2064,
				"transactionCount": 67794,
				"voluemPrice": 16.2064
			},
			{
				"open": 16.2496,
				"close": 16.1618,
				"volume": 322957404,
				"time": 1375243200000,
				"lowest": 16.0511,
				"highest": 16.3337,
				"volumePrice": 16.1807,
				"transactionCount": 61991,
				"voluemPrice": 16.1807
			},
			{
				"open": 16.2769,
				"close": 16.3099,
				"volume": 206227448,
				"time": 1375329600000,
				"lowest": 16.1879,
				"highest": 16.3143,
				"volumePrice": 16.2693,
				"transactionCount": 46299,
				"voluemPrice": 16.2693
			},
			{
				"open": 16.3575,
				"close": 16.5193,
				"volume": 274781304,
				"time": 1375416000000,
				"lowest": 16.3093,
				"highest": 16.5304,
				"volumePrice": 16.4291,
				"transactionCount": 58160,
				"voluemPrice": 16.4291
			},
			{
				"open": 16.5961,
				"close": 16.7661,
				"volume": 318854368,
				"time": 1375675200000,
				"lowest": 16.5054,
				"highest": 16.8096,
				"volumePrice": 16.6986,
				"transactionCount": 64460,
				"voluemPrice": 16.6986
			},
			{
				"open": 16.715,
				"close": 16.6161,
				"volume": 334914160,
				"time": 1375761600000,
				"lowest": 16.5061,
				"highest": 16.8532,
				"volumePrice": 16.6656,
				"transactionCount": 66059,
				"voluemPrice": 16.6656
			},
			{
				"open": 16.5643,
				"close": 16.6064,
				"volume": 298855872,
				"time": 1375848000000,
				"lowest": 16.4918,
				"highest": 16.6786,
				"volumePrice": 16.5843,
				"transactionCount": 53073,
				"voluemPrice": 16.5843
			},
			{
				"open": 16.5663,
				"close": 16.4646,
				"volume": 255999352,
				"time": 1375934400000,
				"lowest": 16.3554,
				"highest": 16.575,
				"volumePrice": 16.4611,
				"transactionCount": 53426,
				"voluemPrice": 16.4611
			},
			{
				"open": 16.38,
				"close": 16.2304,
				"volume": 267620836,
				"time": 1376020800000,
				"lowest": 16.2018,
				"highest": 16.445,
				"volumePrice": 16.2887,
				"transactionCount": 57379,
				"voluemPrice": 16.2887
			},
			{
				"open": 16.3164,
				"close": 16.6914,
				"volume": 364433160,
				"time": 1376280000000,
				"lowest": 16.3082,
				"highest": 16.7375,
				"volumePrice": 16.6001,
				"transactionCount": 71897,
				"voluemPrice": 16.6001
			},
			{
				"open": 16.8193,
				"close": 17.4846,
				"volume": 881939744,
				"time": 1376366400000,
				"lowest": 16.7161,
				"highest": 17.6664,
				"volumePrice": 17.2335,
				"transactionCount": 193751,
				"voluemPrice": 17.2335
			},
			{
				"open": 17.7814,
				"close": 17.8036,
				"volume": 756371644,
				"time": 1376452800000,
				"lowest": 17.6214,
				"highest": 18.0089,
				"volumePrice": 17.8212,
				"transactionCount": 158744,
				"voluemPrice": 17.8212
			},
			{
				"open": 17.7293,
				"close": 17.7825,
				"volume": 490294028,
				"time": 1376539200000,
				"lowest": 17.6339,
				"highest": 17.9428,
				"volumePrice": 17.7893,
				"transactionCount": 93173,
				"voluemPrice": 17.7893
			},
			{
				"open": 17.8625,
				"close": 17.9404,
				"volume": 362303424,
				"time": 1376625600000,
				"lowest": 17.8164,
				"highest": 17.9621,
				"volumePrice": 17.9017,
				"transactionCount": 64264,
				"voluemPrice": 17.9017
			},
			{
				"open": 18.0121,
				"close": 18.1336,
				"volume": 510517924,
				"time": 1376884800000,
				"lowest": 18,
				"highest": 18.3479,
				"volumePrice": 18.224,
				"transactionCount": 99460,
				"voluemPrice": 18.224
			},
			{
				"open": 18.2039,
				"close": 17.8954,
				"volume": 358574972,
				"time": 1376971200000,
				"lowest": 17.8864,
				"highest": 18.2346,
				"volumePrice": 18.0039,
				"transactionCount": 76910,
				"voluemPrice": 18.0039
			},
			{
				"open": 17.9854,
				"close": 17.9414,
				"volume": 335877220,
				"time": 1377057600000,
				"lowest": 17.9,
				"highest": 18.1125,
				"volumePrice": 17.998,
				"transactionCount": 70371,
				"voluemPrice": 17.998
			},
			{
				"open": 18.035,
				"close": 17.9629,
				"volume": 244206704,
				"time": 1377144000000,
				"lowest": 17.7929,
				"highest": 18.0568,
				"volumePrice": 17.9371,
				"transactionCount": 51595,
				"voluemPrice": 17.9371
			},
			{
				"open": 17.9739,
				"close": 17.8936,
				"volume": 222728884,
				"time": 1377230400000,
				"lowest": 17.8339,
				"highest": 17.9768,
				"volumePrice": 17.8958,
				"transactionCount": 49984,
				"voluemPrice": 17.8958
			},
			{
				"open": 17.8839,
				"close": 17.9632,
				"volume": 330964200,
				"time": 1377489600000,
				"lowest": 17.875,
				"highest": 18.2214,
				"volumePrice": 18.0559,
				"transactionCount": 64477,
				"voluemPrice": 18.0559
			},
			{
				"open": 17.7857,
				"close": 17.4496,
				"volume": 424188436,
				"time": 1377576000000,
				"lowest": 17.3679,
				"highest": 17.9468,
				"volumePrice": 17.6329,
				"transactionCount": 90178,
				"voluemPrice": 17.6329
			},
			{
				"open": 17.3571,
				"close": 17.532,
				"volume": 307606712,
				"time": 1377662400000,
				"lowest": 17.3571,
				"highest": 17.7071,
				"volumePrice": 17.5714,
				"transactionCount": 64183,
				"voluemPrice": 17.5714
			},
			{
				"open": 17.5589,
				"close": 17.5607,
				"volume": 239654856,
				"time": 1377748800000,
				"lowest": 17.5404,
				"highest": 17.7321,
				"volumePrice": 17.6482,
				"transactionCount": 48562,
				"voluemPrice": 17.6482
			},
			{
				"open": 17.5714,
				"close": 17.4006,
				"volume": 272403152,
				"time": 1377835200000,
				"lowest": 17.375,
				"highest": 17.6054,
				"volumePrice": 17.4433,
				"transactionCount": 55300,
				"voluemPrice": 17.4433
			},
			{
				"open": 17.6107,
				"close": 17.4493,
				"volume": 332100664,
				"time": 1378180800000,
				"lowest": 17.4054,
				"highest": 17.8786,
				"volumePrice": 17.5911,
				"transactionCount": 68739,
				"voluemPrice": 17.5911
			},
			{
				"open": 17.8414,
				"close": 17.8104,
				"volume": 345031512,
				"time": 1378267200000,
				"lowest": 17.7243,
				"highest": 17.9371,
				"volumePrice": 17.8244,
				"transactionCount": 75501,
				"voluemPrice": 17.8244
			},
			{
				"open": 17.8661,
				"close": 17.6882,
				"volume": 236367516,
				"time": 1378353600000,
				"lowest": 17.63,
				"highest": 17.8814,
				"volumePrice": 17.7347,
				"transactionCount": 49588,
				"voluemPrice": 17.7347
			},
			{
				"open": 17.8014,
				"close": 17.7936,
				"volume": 359525012,
				"time": 1378440000000,
				"lowest": 17.4982,
				"highest": 17.835,
				"volumePrice": 17.7223,
				"transactionCount": 80182,
				"voluemPrice": 17.7223
			},
			{
				"open": 18.0357,
				"close": 18.0775,
				"volume": 340686332,
				"time": 1378699200000,
				"lowest": 17.9814,
				"highest": 18.14,
				"volumePrice": 18.0635,
				"transactionCount": 72420,
				"voluemPrice": 18.0635
			},
			{
				"open": 18.0786,
				"close": 17.6657,
				"volume": 743195488,
				"time": 1378785600000,
				"lowest": 17.4821,
				"highest": 18.1232,
				"volumePrice": 17.8434,
				"transactionCount": 159067,
				"voluemPrice": 17.8434
			},
			{
				"open": 16.6789,
				"close": 16.7039,
				"volume": 898693824,
				"time": 1378872000000,
				"lowest": 16.6004,
				"highest": 16.9175,
				"volumePrice": 16.7402,
				"transactionCount": 189504,
				"voluemPrice": 16.7402
			},
			{
				"open": 16.7321,
				"close": 16.8818,
				"volume": 404049884,
				"time": 1378958400000,
				"lowest": 16.6432,
				"highest": 16.9786,
				"volumePrice": 16.8372,
				"transactionCount": 85227,
				"voluemPrice": 16.8372
			},
			{
				"open": 16.7622,
				"close": 16.6036,
				"volume": 298833752,
				"time": 1379044800000,
				"lowest": 16.5964,
				"highest": 16.851,
				"volumePrice": 16.684,
				"transactionCount": 64137,
				"voluemPrice": 16.684
			},
			{
				"open": 16.4643,
				"close": 16.0757,
				"volume": 548550660,
				"time": 1379304000000,
				"lowest": 15.9721,
				"highest": 16.4861,
				"volumePrice": 16.2009,
				"transactionCount": 114002,
				"voluemPrice": 16.2009
			},
			{
				"open": 15.9986,
				"close": 16.2614,
				"volume": 399379092,
				"time": 1379390400000,
				"lowest": 15.9821,
				"highest": 16.4182,
				"volumePrice": 16.2711,
				"transactionCount": 83157,
				"voluemPrice": 16.2711
			},
			{
				"open": 16.5421,
				"close": 16.5957,
				"volume": 456861216,
				"time": 1379476800000,
				"lowest": 16.4521,
				"highest": 16.6554,
				"volumePrice": 16.5584,
				"transactionCount": 91881,
				"voluemPrice": 16.5584
			},
			{
				"open": 16.8107,
				"close": 16.8679,
				"volume": 404538848,
				"time": 1379563200000,
				"lowest": 16.7589,
				"highest": 16.9939,
				"volumePrice": 16.9021,
				"transactionCount": 89225,
				"voluemPrice": 16.9021
			},
			{
				"open": 17.0714,
				"close": 16.6932,
				"volume": 699301288,
				"time": 1379649600000,
				"lowest": 16.6429,
				"highest": 17.0911,
				"volumePrice": 16.8393,
				"transactionCount": 103859,
				"voluemPrice": 16.8393
			},
			{
				"open": 17.7179,
				"close": 17.5229,
				"volume": 762106100,
				"time": 1379908800000,
				"lowest": 17.2357,
				"highest": 17.7468,
				"volumePrice": 17.4753,
				"transactionCount": 163870,
				"voluemPrice": 17.4753
			},
			{
				"open": 17.6743,
				"close": 17.4679,
				"volume": 364342972,
				"time": 1379995200000,
				"lowest": 17.4221,
				"highest": 17.6954,
				"volumePrice": 17.5493,
				"transactionCount": 77490,
				"voluemPrice": 17.5493
			},
			{
				"open": 17.4714,
				"close": 17.1975,
				"volume": 316956668,
				"time": 1380081600000,
				"lowest": 17.1939,
				"highest": 17.4871,
				"volumePrice": 17.274,
				"transactionCount": 67277,
				"voluemPrice": 17.274
			},
			{
				"open": 17.3571,
				"close": 17.365,
				"volume": 237220732,
				"time": 1380168000000,
				"lowest": 17.2821,
				"highest": 17.4486,
				"volumePrice": 17.3523,
				"transactionCount": 48711,
				"voluemPrice": 17.3523
			},
			{
				"open": 17.2779,
				"close": 17.2411,
				"volume": 228038916,
				"time": 1380254400000,
				"lowest": 17.1686,
				"highest": 17.3096,
				"volumePrice": 17.2339,
				"transactionCount": 45261,
				"voluemPrice": 17.2339
			},
			{
				"open": 17.0446,
				"close": 17.0268,
				"volume": 260157632,
				"time": 1380513600000,
				"lowest": 16.9433,
				"highest": 17.2021,
				"volumePrice": 17.06,
				"transactionCount": 50740,
				"voluemPrice": 17.06
			},
			{
				"open": 17.0875,
				"close": 17.4271,
				"volume": 353882620,
				"time": 1380600000000,
				"lowest": 17.085,
				"highest": 17.4693,
				"volumePrice": 17.3137,
				"transactionCount": 72631,
				"voluemPrice": 17.3137
			},
			{
				"open": 17.3439,
				"close": 17.4843,
				"volume": 289183608,
				"time": 1380686400000,
				"lowest": 17.2768,
				"highest": 17.5643,
				"volumePrice": 17.4483,
				"transactionCount": 57849,
				"voluemPrice": 17.4483
			},
			{
				"open": 17.5182,
				"close": 17.2646,
				"volume": 322754012,
				"time": 1380772800000,
				"lowest": 17.1692,
				"highest": 17.5839,
				"volumePrice": 17.3257,
				"transactionCount": 64384,
				"voluemPrice": 17.3257
			},
			{
				"open": 17.2807,
				"close": 17.2511,
				"volume": 258869520,
				"time": 1380859200000,
				"lowest": 17.0929,
				"highest": 17.3071,
				"volumePrice": 17.1976,
				"transactionCount": 56504,
				"voluemPrice": 17.1976
			},
			{
				"open": 17.3771,
				"close": 17.4196,
				"volume": 312292428,
				"time": 1381118400000,
				"lowest": 17.3339,
				"highest": 17.5946,
				"volumePrice": 17.491,
				"transactionCount": 67630,
				"voluemPrice": 17.491
			},
			{
				"open": 17.4979,
				"close": 17.1764,
				"volume": 290916024,
				"time": 1381204800000,
				"lowest": 17.1621,
				"highest": 17.5229,
				"volumePrice": 17.3154,
				"transactionCount": 64101,
				"voluemPrice": 17.3154
			},
			{
				"open": 17.3086,
				"close": 17.3781,
				"volume": 301724864,
				"time": 1381291200000,
				"lowest": 17.0814,
				"highest": 17.4211,
				"volumePrice": 17.2723,
				"transactionCount": 62194,
				"voluemPrice": 17.2723
			},
			{
				"open": 17.5471,
				"close": 17.4871,
				"volume": 278601960,
				"time": 1381377600000,
				"lowest": 17.3943,
				"highest": 17.585,
				"volumePrice": 17.4965,
				"transactionCount": 61751,
				"voluemPrice": 17.4965
			},
			{
				"open": 17.3925,
				"close": 17.6004,
				"volume": 267739752,
				"time": 1381464000000,
				"lowest": 17.3271,
				"highest": 17.6371,
				"volumePrice": 17.5052,
				"transactionCount": 58102,
				"voluemPrice": 17.5052
			},
			{
				"open": 17.4939,
				"close": 17.7157,
				"volume": 261898168,
				"time": 1381723200000,
				"lowest": 17.4768,
				"highest": 17.7707,
				"volumePrice": 17.6877,
				"transactionCount": 55942,
				"voluemPrice": 17.6877
			},
			{
				"open": 17.768,
				"close": 17.81,
				"volume": 320074412,
				"time": 1381809600000,
				"lowest": 17.6971,
				"highest": 17.9286,
				"volumePrice": 17.8331,
				"transactionCount": 68913,
				"voluemPrice": 17.8331
			},
			{
				"open": 17.8854,
				"close": 17.8969,
				"volume": 251100052,
				"time": 1381896000000,
				"lowest": 17.8296,
				"highest": 17.9475,
				"volumePrice": 17.8872,
				"transactionCount": 54280,
				"voluemPrice": 17.8872
			},
			{
				"open": 17.8564,
				"close": 18.0179,
				"volume": 253593340,
				"time": 1381982400000,
				"lowest": 17.8457,
				"highest": 18.0279,
				"volumePrice": 17.9819,
				"transactionCount": 51575,
				"voluemPrice": 17.9819
			},
			{
				"open": 18.0711,
				"close": 18.1746,
				"volume": 290542280,
				"time": 1382068800000,
				"lowest": 18.0611,
				"highest": 18.1879,
				"volumePrice": 18.1439,
				"transactionCount": 56737,
				"voluemPrice": 18.1439
			},
			{
				"open": 18.2775,
				"close": 18.6201,
				"volume": 398107780,
				"time": 1382328000000,
				"lowest": 18.2686,
				"highest": 18.725,
				"volumePrice": 18.5835,
				"transactionCount": 85994,
				"voluemPrice": 18.5835
			},
			{
				"open": 18.8002,
				"close": 18.5667,
				"volume": 534063012,
				"time": 1382414400000,
				"lowest": 18.1439,
				"highest": 18.8732,
				"volumePrice": 18.558,
				"transactionCount": 117480,
				"voluemPrice": 18.558
			},
			{
				"open": 18.5357,
				"close": 18.7486,
				"volume": 305657688,
				"time": 1382500800000,
				"lowest": 18.5357,
				"highest": 18.7739,
				"volumePrice": 18.6876,
				"transactionCount": 64877,
				"voluemPrice": 18.6876
			},
			{
				"open": 18.75,
				"close": 18.9968,
				"volume": 384764380,
				"time": 1382587200000,
				"lowest": 18.6589,
				"highest": 19.0168,
				"volumePrice": 18.9006,
				"transactionCount": 76207,
				"voluemPrice": 18.9006
			},
			{
				"open": 18.9755,
				"close": 18.7842,
				"volume": 337792532,
				"time": 1382673600000,
				"lowest": 18.7539,
				"highest": 19.0439,
				"volumePrice": 18.8922,
				"transactionCount": 71723,
				"voluemPrice": 18.8922
			},
			{
				"open": 18.8943,
				"close": 18.9241,
				"volume": 550440492,
				"time": 1382932800000,
				"lowest": 18.6861,
				"highest": 18.9643,
				"volumePrice": 18.7924,
				"transactionCount": 117494,
				"voluemPrice": 18.7924
			},
			{
				"open": 19.1525,
				"close": 18.4528,
				"volume": 635808460,
				"time": 1383019200000,
				"lowest": 18.3764,
				"highest": 19.2589,
				"volumePrice": 18.7944,
				"transactionCount": 139166,
				"voluemPrice": 18.7944
			},
			{
				"open": 18.5575,
				"close": 18.7463,
				"volume": 354162788,
				"time": 1383105600000,
				"lowest": 18.465,
				"highest": 18.84,
				"volumePrice": 18.7027,
				"transactionCount": 75794,
				"voluemPrice": 18.7027
			},
			{
				"open": 18.75,
				"close": 18.6679,
				"volume": 275695140,
				"time": 1383192000000,
				"lowest": 18.6168,
				"highest": 18.8389,
				"volumePrice": 18.7142,
				"transactionCount": 55433,
				"voluemPrice": 18.7142
			},
			{
				"open": 18.715,
				"close": 18.5725,
				"volume": 274889216,
				"time": 1383278400000,
				"lowest": 18.4229,
				"highest": 18.7429,
				"volumePrice": 18.5718,
				"transactionCount": 61741,
				"voluemPrice": 18.5718
			},
			{
				"open": 18.6107,
				"close": 18.8125,
				"volume": 244628132,
				"time": 1383541200000,
				"lowest": 18.5289,
				"highest": 18.815,
				"volumePrice": 18.6869,
				"transactionCount": 52457,
				"voluemPrice": 18.6869
			},
			{
				"open": 18.735,
				"close": 18.766,
				"volume": 265472284,
				"time": 1383627600000,
				"lowest": 18.6786,
				"highest": 18.8888,
				"volumePrice": 18.7784,
				"transactionCount": 52351,
				"voluemPrice": 18.7784
			},
			{
				"open": 18.7196,
				"close": 18.6043,
				"volume": 223376608,
				"time": 1383714000000,
				"lowest": 18.5071,
				"highest": 18.745,
				"volumePrice": 18.6111,
				"transactionCount": 48016,
				"voluemPrice": 18.6111
			},
			{
				"open": 18.5564,
				"close": 18.3033,
				"volume": 262620400,
				"time": 1383800400000,
				"lowest": 18.2993,
				"highest": 18.6854,
				"volumePrice": 18.4841,
				"transactionCount": 61051,
				"voluemPrice": 18.4841
			},
			{
				"open": 18.3779,
				"close": 18.5914,
				"volume": 279318172,
				"time": 1383886800000,
				"lowest": 18.3068,
				"highest": 18.6118,
				"volumePrice": 18.5138,
				"transactionCount": 57477,
				"voluemPrice": 18.5138
			},
			{
				"open": 18.5711,
				"close": 18.5374,
				"volume": 227453212,
				"time": 1384146000000,
				"lowest": 18.3718,
				"highest": 18.6311,
				"volumePrice": 18.5009,
				"transactionCount": 50258,
				"voluemPrice": 18.5009
			},
			{
				"open": 18.4882,
				"close": 18.5718,
				"volume": 204458604,
				"time": 1384232400000,
				"lowest": 18.4643,
				"highest": 18.7114,
				"volumePrice": 18.5966,
				"transactionCount": 45258,
				"voluemPrice": 18.5966
			},
			{
				"open": 18.5,
				"close": 18.5941,
				"volume": 197219708,
				"time": 1384318800000,
				"lowest": 18.4629,
				"highest": 18.6518,
				"volumePrice": 18.564,
				"transactionCount": 41074,
				"voluemPrice": 18.564
			},
			{
				"open": 18.6718,
				"close": 18.8629,
				"volume": 282420348,
				"time": 1384405200000,
				"lowest": 18.6382,
				"highest": 18.9029,
				"volumePrice": 18.81,
				"transactionCount": 64418,
				"voluemPrice": 18.81
			},
			{
				"open": 18.8064,
				"close": 18.7497,
				"volume": 317919056,
				"time": 1384491600000,
				"lowest": 18.7318,
				"highest": 18.8961,
				"volumePrice": 18.7812,
				"transactionCount": 52812,
				"voluemPrice": 18.7812
			},
			{
				"open": 18.7496,
				"close": 18.5225,
				"volume": 244944896,
				"time": 1384750800000,
				"lowest": 18.5071,
				"highest": 18.8282,
				"volumePrice": 18.6533,
				"transactionCount": 50662,
				"voluemPrice": 18.6533
			},
			{
				"open": 18.5368,
				"close": 18.5554,
				"volume": 208938828,
				"time": 1384837200000,
				"lowest": 18.4989,
				"highest": 18.6921,
				"volumePrice": 18.5839,
				"transactionCount": 42653,
				"voluemPrice": 18.5839
			},
			{
				"open": 18.5439,
				"close": 18.3929,
				"volume": 194183192,
				"time": 1384923600000,
				"lowest": 18.3689,
				"highest": 18.5864,
				"volumePrice": 18.4803,
				"transactionCount": 43414,
				"voluemPrice": 18.4803
			},
			{
				"open": 18.4857,
				"close": 18.612,
				"volume": 262027444,
				"time": 1385010000000,
				"lowest": 18.3454,
				"highest": 18.6146,
				"volumePrice": 18.4876,
				"transactionCount": 55355,
				"voluemPrice": 18.4876
			},
			{
				"open": 18.5543,
				"close": 18.5643,
				"volume": 223582128,
				"time": 1385096400000,
				"lowest": 18.5189,
				"highest": 18.6486,
				"volumePrice": 18.5836,
				"transactionCount": 44079,
				"voluemPrice": 18.5836
			},
			{
				"open": 18.6079,
				"close": 18.705,
				"volume": 229393612,
				"time": 1385355600000,
				"lowest": 18.6071,
				"highest": 18.7811,
				"volumePrice": 18.7244,
				"transactionCount": 48930,
				"voluemPrice": 18.7244
			},
			{
				"open": 18.7186,
				"close": 19.05,
				"volume": 401382912,
				"time": 1385442000000,
				"lowest": 18.7143,
				"highest": 19.1479,
				"volumePrice": 18.9964,
				"transactionCount": 79374,
				"voluemPrice": 18.9964
			},
			{
				"open": 19.1539,
				"close": 19.4986,
				"volume": 363438404,
				"time": 1385528400000,
				"lowest": 19.1489,
				"highest": 19.5,
				"volumePrice": 19.3907,
				"transactionCount": 78508,
				"voluemPrice": 19.3907
			},
			{
				"open": 19.6243,
				"close": 19.8596,
				"volume": 318128860,
				"time": 1385701200000,
				"lowest": 19.5646,
				"highest": 19.9404,
				"volumePrice": 19.7711,
				"transactionCount": 60894,
				"voluemPrice": 19.7711
			},
			{
				"open": 19.9286,
				"close": 19.6868,
				"volume": 472543540,
				"time": 1385960400000,
				"lowest": 19.6721,
				"highest": 20.1546,
				"volumePrice": 19.8729,
				"transactionCount": 99576,
				"voluemPrice": 19.8729
			},
			{
				"open": 19.9393,
				"close": 20.2258,
				"volume": 450966936,
				"time": 1386046800000,
				"lowest": 19.9171,
				"highest": 20.2279,
				"volumePrice": 20.0661,
				"transactionCount": 89725,
				"voluemPrice": 20.0661
			},
			{
				"open": 20.1964,
				"close": 20.1786,
				"volume": 377810664,
				"time": 1386133200000,
				"lowest": 20.0293,
				"highest": 20.3282,
				"volumePrice": 20.1631,
				"transactionCount": 75328,
				"voluemPrice": 20.1631
			},
			{
				"open": 20.4518,
				"close": 20.2822,
				"volume": 447581260,
				"time": 1386219600000,
				"lowest": 20.2289,
				"highest": 20.5406,
				"volumePrice": 20.3862,
				"transactionCount": 92650,
				"voluemPrice": 20.3862
			},
			{
				"open": 20.2068,
				"close": 20.0007,
				"volume": 344353408,
				"time": 1386306000000,
				"lowest": 19.9846,
				"highest": 20.2411,
				"volumePrice": 20.1097,
				"transactionCount": 72578,
				"voluemPrice": 20.1097
			},
			{
				"open": 20.0321,
				"close": 20.2296,
				"volume": 320494132,
				"time": 1386565200000,
				"lowest": 20.0321,
				"highest": 20.3421,
				"volumePrice": 20.2317,
				"transactionCount": 99472,
				"voluemPrice": 20.2317
			},
			{
				"open": 20.1279,
				"close": 20.1982,
				"volume": 278270440,
				"time": 1386651600000,
				"lowest": 20.0429,
				"highest": 20.2814,
				"volumePrice": 20.1736,
				"transactionCount": 85928,
				"voluemPrice": 20.1736
			},
			{
				"open": 20.25,
				"close": 20.0486,
				"volume": 359718772,
				"time": 1386738000000,
				"lowest": 19.9889,
				"highest": 20.3918,
				"volumePrice": 20.1643,
				"transactionCount": 110256,
				"voluemPrice": 20.1643
			},
			{
				"open": 20.0765,
				"close": 20.0193,
				"volume": 262289272,
				"time": 1386824400000,
				"lowest": 20.0011,
				"highest": 20.1907,
				"volumePrice": 20.0784,
				"transactionCount": 84787,
				"voluemPrice": 20.0784
			},
			{
				"open": 20.1018,
				"close": 19.8011,
				"volume": 332821132,
				"time": 1386910800000,
				"lowest": 19.7739,
				"highest": 20.1029,
				"volumePrice": 19.8557,
				"transactionCount": 111527,
				"voluemPrice": 19.8557
			},
			{
				"open": 19.8221,
				"close": 19.9107,
				"volume": 282593808,
				"time": 1387170000000,
				"lowest": 19.8218,
				"highest": 20.0942,
				"volumePrice": 19.9784,
				"transactionCount": 88308,
				"voluemPrice": 19.9784
			},
			{
				"open": 19.8504,
				"close": 19.8211,
				"volume": 229790596,
				"time": 1387256400000,
				"lowest": 19.7634,
				"highest": 19.98,
				"volumePrice": 19.8602,
				"transactionCount": 77483,
				"voluemPrice": 19.8602
			},
			{
				"open": 19.6321,
				"close": 19.6704,
				"volume": 565858496,
				"time": 1387342800000,
				"lowest": 19.2429,
				"highest": 19.6946,
				"volumePrice": 19.4999,
				"transactionCount": 195620,
				"voluemPrice": 19.4999
			},
			{
				"open": 19.625,
				"close": 19.445,
				"volume": 320947676,
				"time": 1387429200000,
				"lowest": 19.4189,
				"highest": 19.6429,
				"volumePrice": 19.5061,
				"transactionCount": 104035,
				"voluemPrice": 19.5061
			},
			{
				"open": 19.4796,
				"close": 19.6079,
				"volume": 436413740,
				"time": 1387515600000,
				"lowest": 19.4578,
				"highest": 19.7004,
				"volumePrice": 19.6047,
				"transactionCount": 106210,
				"voluemPrice": 19.6047
			},
			{
				"open": 20.2857,
				"close": 20.3604,
				"volume": 501307324,
				"time": 1387774800000,
				"lowest": 20.0986,
				"highest": 20.3829,
				"volumePrice": 20.254,
				"transactionCount": 167070,
				"voluemPrice": 20.254
			},
			{
				"open": 20.3532,
				"close": 20.2739,
				"volume": 167554940,
				"time": 1387861200000,
				"lowest": 20.2154,
				"highest": 20.4243,
				"volumePrice": 20.3063,
				"transactionCount": 55406,
				"voluemPrice": 20.3063
			},
			{
				"open": 20.2893,
				"close": 20.1393,
				"volume": 204000580,
				"time": 1388034000000,
				"lowest": 20.1206,
				"highest": 20.3393,
				"volumePrice": 20.1916,
				"transactionCount": 65293,
				"voluemPrice": 20.1916
			},
			{
				"open": 20.1364,
				"close": 20.0032,
				"volume": 225885268,
				"time": 1388120400000,
				"lowest": 19.9821,
				"highest": 20.1575,
				"volumePrice": 20.031,
				"transactionCount": 75195,
				"voluemPrice": 20.031
			},
			{
				"open": 19.9093,
				"close": 19.8043,
				"volume": 253142596,
				"time": 1388379600000,
				"lowest": 19.7258,
				"highest": 20.0032,
				"volumePrice": 19.808,
				"transactionCount": 84926,
				"voluemPrice": 19.808
			},
			{
				"open": 19.7918,
				"close": 20.0364,
				"volume": 223277488,
				"time": 1388466000000,
				"lowest": 19.7857,
				"highest": 20.0457,
				"volumePrice": 19.9894,
				"transactionCount": 74221,
				"voluemPrice": 19.9894
			},
			{
				"open": 19.8457,
				"close": 19.7546,
				"volume": 235167828,
				"time": 1388638800000,
				"lowest": 19.715,
				"highest": 19.8939,
				"volumePrice": 19.7715,
				"transactionCount": 89519,
				"voluemPrice": 19.7715
			},
			{
				"open": 19.745,
				"close": 19.3207,
				"volume": 393215480,
				"time": 1388725200000,
				"lowest": 19.3011,
				"highest": 19.775,
				"volumePrice": 19.4749,
				"transactionCount": 148584,
				"voluemPrice": 19.4749
			},
			{
				"open": 19.1946,
				"close": 19.4261,
				"volume": 413436604,
				"time": 1388984400000,
				"lowest": 19.0571,
				"highest": 19.5286,
				"volumePrice": 19.3213,
				"transactionCount": 131664,
				"voluemPrice": 19.3213
			},
			{
				"open": 19.44,
				"close": 19.2871,
				"volume": 317731064,
				"time": 1389070800000,
				"lowest": 19.2116,
				"highest": 19.4986,
				"volumePrice": 19.3329,
				"transactionCount": 107327,
				"voluemPrice": 19.3329
			},
			{
				"open": 19.2432,
				"close": 19.4093,
				"volume": 258746656,
				"time": 1389157200000,
				"lowest": 19.2389,
				"highest": 19.4843,
				"volumePrice": 19.4038,
				"transactionCount": 86874,
				"voluemPrice": 19.4038
			},
			{
				"open": 19.5286,
				"close": 19.1614,
				"volume": 279620796,
				"time": 1389243600000,
				"lowest": 19.1196,
				"highest": 19.5307,
				"volumePrice": 19.2943,
				"transactionCount": 93562,
				"voluemPrice": 19.2943
			},
			{
				"open": 19.2796,
				"close": 19.0336,
				"volume": 305282656,
				"time": 1389330000000,
				"lowest": 18.9682,
				"highest": 19.3143,
				"volumePrice": 19.0659,
				"transactionCount": 113063,
				"voluemPrice": 19.0659
			},
			{
				"open": 18.9254,
				"close": 19.1332,
				"volume": 379443372,
				"time": 1389589200000,
				"lowest": 18.9243,
				"highest": 19.375,
				"volumePrice": 19.1801,
				"transactionCount": 130227,
				"voluemPrice": 19.1801
			},
			{
				"open": 19.2221,
				"close": 19.5139,
				"volume": 334937484,
				"time": 1389675600000,
				"lowest": 19.2021,
				"highest": 19.5261,
				"volumePrice": 19.4033,
				"transactionCount": 114856,
				"voluemPrice": 19.4033
			},
			{
				"open": 19.7686,
				"close": 19.9057,
				"volume": 393890476,
				"time": 1389762000000,
				"lowest": 19.7021,
				"highest": 20.0071,
				"volumePrice": 19.9105,
				"transactionCount": 136942,
				"voluemPrice": 19.9105
			},
			{
				"open": 19.8179,
				"close": 19.7946,
				"volume": 229885320,
				"time": 1389848400000,
				"lowest": 19.7029,
				"highest": 19.8875,
				"volumePrice": 19.8115,
				"transactionCount": 78507,
				"voluemPrice": 19.8115
			},
			{
				"open": 19.6957,
				"close": 19.3096,
				"volume": 433704348,
				"time": 1389934800000,
				"lowest": 19.2821,
				"highest": 19.7168,
				"volumePrice": 19.4976,
				"transactionCount": 133579,
				"voluemPrice": 19.4976
			},
			{
				"open": 19.3211,
				"close": 19.6096,
				"volume": 329022176,
				"time": 1390280400000,
				"lowest": 19.3007,
				"highest": 19.6454,
				"volumePrice": 19.5221,
				"transactionCount": 111405,
				"voluemPrice": 19.5221
			},
			{
				"open": 19.6755,
				"close": 19.6968,
				"volume": 380877336,
				"time": 1390366800000,
				"lowest": 19.5646,
				"highest": 19.9032,
				"volumePrice": 19.764,
				"transactionCount": 117241,
				"voluemPrice": 19.764
			},
			{
				"open": 19.6407,
				"close": 19.8636,
				"volume": 403913384,
				"time": 1390453200000,
				"lowest": 19.4574,
				"highest": 19.875,
				"volumePrice": 19.714,
				"transactionCount": 128538,
				"voluemPrice": 19.714
			},
			{
				"open": 19.7857,
				"close": 19.5025,
				"volume": 433537748,
				"time": 1390539600000,
				"lowest": 19.4554,
				"highest": 19.8436,
				"volumePrice": 19.6392,
				"transactionCount": 149602,
				"voluemPrice": 19.6392
			},
			{
				"open": 19.6454,
				"close": 19.6607,
				"volume": 576876608,
				"time": 1390798800000,
				"lowest": 19.4911,
				"highest": 19.8143,
				"volumePrice": 19.3585,
				"transactionCount": 225982,
				"voluemPrice": 19.3585
			},
			{
				"open": 18.17,
				"close": 18.0893,
				"volume": 1067333176,
				"time": 1390885200000,
				"lowest": 17.9311,
				"highest": 18.3929,
				"volumePrice": 18.1918,
				"transactionCount": 404596,
				"voluemPrice": 18.1918
			},
			{
				"open": 17.9982,
				"close": 17.8839,
				"volume": 503771184,
				"time": 1390971600000,
				"lowest": 17.8079,
				"highest": 18.1204,
				"volumePrice": 17.9307,
				"transactionCount": 185157,
				"voluemPrice": 17.9307
			},
			{
				"open": 17.9479,
				"close": 17.8494,
				"volume": 679051156,
				"time": 1391058000000,
				"lowest": 17.7393,
				"highest": 18.0893,
				"volumePrice": 17.8802,
				"transactionCount": 159294,
				"voluemPrice": 17.8802
			},
			{
				"open": 17.685,
				"close": 17.8786,
				"volume": 465345720,
				"time": 1391144400000,
				"lowest": 17.6268,
				"highest": 17.9118,
				"volumePrice": 17.7956,
				"transactionCount": 143250,
				"voluemPrice": 17.7956
			},
			{
				"open": 17.9504,
				"close": 17.9118,
				"volume": 402483088,
				"time": 1391403600000,
				"lowest": 17.8321,
				"highest": 18.1332,
				"volumePrice": 17.9578,
				"transactionCount": 136897,
				"voluemPrice": 17.9578
			},
			{
				"open": 18.0661,
				"close": 18.1711,
				"volume": 377094172,
				"time": 1391490000000,
				"lowest": 17.9557,
				"highest": 18.195,
				"volumePrice": 18.1172,
				"transactionCount": 117476,
				"voluemPrice": 18.1172
			},
			{
				"open": 18.0914,
				"close": 18.3068,
				"volume": 329288624,
				"time": 1391576400000,
				"lowest": 18.0804,
				"highest": 18.4029,
				"volumePrice": 18.2867,
				"transactionCount": 115413,
				"voluemPrice": 18.2867
			},
			{
				"open": 18.2164,
				"close": 18.3039,
				"volume": 257988892,
				"time": 1391662800000,
				"lowest": 18.1361,
				"highest": 18.3393,
				"volumePrice": 18.2852,
				"transactionCount": 95063,
				"voluemPrice": 18.2852
			},
			{
				"open": 18.6207,
				"close": 18.56,
				"volume": 374554264,
				"time": 1391749200000,
				"lowest": 18.4779,
				"highest": 18.6761,
				"volumePrice": 18.5897,
				"transactionCount": 126298,
				"voluemPrice": 18.5897
			},
			{
				"open": 18.5236,
				"close": 18.8925,
				"volume": 345804088,
				"time": 1392008400000,
				"lowest": 18.5,
				"highest": 18.9996,
				"volumePrice": 18.8447,
				"transactionCount": 119536,
				"voluemPrice": 18.8447
			},
			{
				"open": 18.9504,
				"close": 19.1414,
				"volume": 282689008,
				"time": 1392094800000,
				"lowest": 18.9107,
				"highest": 19.2054,
				"volumePrice": 19.1015,
				"transactionCount": 103219,
				"voluemPrice": 19.1015
			},
			{
				"open": 19.1768,
				"close": 19.14,
				"volume": 308507724,
				"time": 1392181200000,
				"lowest": 19.0443,
				"highest": 19.27,
				"volumePrice": 19.1592,
				"transactionCount": 96816,
				"voluemPrice": 19.1592
			},
			{
				"open": 19.095,
				"close": 19.4439,
				"volume": 307840624,
				"time": 1392267600000,
				"lowest": 19.0786,
				"highest": 19.4589,
				"volumePrice": 19.3361,
				"transactionCount": 108887,
				"voluemPrice": 19.3361
			},
			{
				"open": 19.3739,
				"close": 19.4282,
				"volume": 273872144,
				"time": 1392354000000,
				"lowest": 19.3289,
				"highest": 19.4993,
				"volumePrice": 19.4101,
				"transactionCount": 98533,
				"voluemPrice": 19.4101
			},
			{
				"open": 19.5,
				"close": 19.4996,
				"volume": 261219896,
				"time": 1392699600000,
				"lowest": 19.4861,
				"highest": 19.6854,
				"volumePrice": 19.5772,
				"transactionCount": 92096,
				"voluemPrice": 19.5772
			},
			{
				"open": 19.4554,
				"close": 19.1918,
				"volume": 314217680,
				"time": 1392786000000,
				"lowest": 19.0839,
				"highest": 19.5318,
				"volumePrice": 19.2707,
				"transactionCount": 112833,
				"voluemPrice": 19.2707
			},
			{
				"open": 19.0354,
				"close": 18.9696,
				"volume": 306116412,
				"time": 1392872400000,
				"lowest": 18.8929,
				"highest": 19.1786,
				"volumePrice": 18.9931,
				"transactionCount": 112660,
				"voluemPrice": 18.9931
			},
			{
				"open": 19.0283,
				"close": 18.7589,
				"volume": 279028988,
				"time": 1392958800000,
				"lowest": 18.7357,
				"highest": 19.0918,
				"volumePrice": 18.8794,
				"transactionCount": 101839,
				"voluemPrice": 18.8794
			},
			{
				"open": 18.6839,
				"close": 18.8411,
				"volume": 289452520,
				"time": 1393218000000,
				"lowest": 18.6579,
				"highest": 18.9257,
				"volumePrice": 18.8036,
				"transactionCount": 103510,
				"voluemPrice": 18.8036
			},
			{
				"open": 18.9064,
				"close": 18.645,
				"volume": 232987048,
				"time": 1393304400000,
				"lowest": 18.6071,
				"highest": 18.913,
				"volumePrice": 18.7399,
				"transactionCount": 88438,
				"voluemPrice": 18.7399
			},
			{
				"open": 18.7004,
				"close": 18.4768,
				"volume": 276525144,
				"time": 1393390800000,
				"lowest": 18.4143,
				"highest": 18.75,
				"volumePrice": 18.5278,
				"transactionCount": 110698,
				"voluemPrice": 18.5278
			},
			{
				"open": 18.4693,
				"close": 18.8454,
				"volume": 302229284,
				"time": 1393477200000,
				"lowest": 18.4304,
				"highest": 18.885,
				"volumePrice": 18.7251,
				"transactionCount": 109636,
				"voluemPrice": 18.7251
			},
			{
				"open": 18.8957,
				"close": 18.7943,
				"volume": 372298612,
				"time": 1393563600000,
				"lowest": 18.6471,
				"highest": 19.0268,
				"volumePrice": 18.8373,
				"transactionCount": 126428,
				"voluemPrice": 18.8373
			},
			{
				"open": 18.6936,
				"close": 18.8486,
				"volume": 238857976,
				"time": 1393822800000,
				"lowest": 18.6718,
				"highest": 18.9518,
				"volumePrice": 18.8005,
				"transactionCount": 93629,
				"voluemPrice": 18.8005
			},
			{
				"open": 18.9643,
				"close": 18.9729,
				"volume": 259536480,
				"time": 1393909200000,
				"lowest": 18.8489,
				"highest": 19.0229,
				"volumePrice": 18.9523,
				"transactionCount": 87229,
				"voluemPrice": 18.9523
			},
			{
				"open": 18.9614,
				"close": 19.0129,
				"volume": 200261684,
				"time": 1393995600000,
				"lowest": 18.8975,
				"highest": 19.0982,
				"volumePrice": 19.0029,
				"transactionCount": 73456,
				"voluemPrice": 19.0029
			},
			{
				"open": 19.0282,
				"close": 18.9554,
				"volume": 185689700,
				"time": 1394082000000,
				"lowest": 18.8607,
				"highest": 19.0871,
				"volumePrice": 18.9731,
				"transactionCount": 62878,
				"voluemPrice": 18.9731
			},
			{
				"open": 18.9675,
				"close": 18.9443,
				"volume": 221369764,
				"time": 1394168400000,
				"lowest": 18.7875,
				"highest": 18.9993,
				"volumePrice": 18.8709,
				"transactionCount": 78735,
				"voluemPrice": 18.8709
			},
			{
				"open": 18.87,
				"close": 18.9614,
				"volume": 175265720,
				"time": 1394424000000,
				"lowest": 18.8693,
				"highest": 19.0475,
				"volumePrice": 18.9777,
				"transactionCount": 66267,
				"voluemPrice": 18.9777
			},
			{
				"open": 19.1232,
				"close": 19.1461,
				"volume": 280795396,
				"time": 1394510400000,
				"lowest": 19.0211,
				"highest": 19.2407,
				"volumePrice": 19.1671,
				"transactionCount": 101996,
				"voluemPrice": 19.1671
			},
			{
				"open": 19.0896,
				"close": 19.1646,
				"volume": 200781280,
				"time": 1394596800000,
				"lowest": 19,
				"highest": 19.1911,
				"volumePrice": 19.111,
				"transactionCount": 72337,
				"voluemPrice": 19.111
			},
			{
				"open": 19.1943,
				"close": 18.9518,
				"volume": 257742436,
				"time": 1394683200000,
				"lowest": 18.8986,
				"highest": 19.2736,
				"volumePrice": 19.0632,
				"transactionCount": 92606,
				"voluemPrice": 19.0632
			},
			{
				"open": 18.8854,
				"close": 18.7389,
				"volume": 237197968,
				"time": 1394769600000,
				"lowest": 18.6786,
				"highest": 18.9604,
				"volumePrice": 18.7721,
				"transactionCount": 88356,
				"voluemPrice": 18.7721
			},
			{
				"open": 18.8464,
				"close": 18.8121,
				"volume": 199544296,
				"time": 1395028800000,
				"lowest": 18.7804,
				"highest": 18.9275,
				"volumePrice": 18.8593,
				"transactionCount": 75023,
				"voluemPrice": 18.8593
			},
			{
				"open": 18.7821,
				"close": 18.9786,
				"volume": 209647452,
				"time": 1395115200000,
				"lowest": 18.7571,
				"highest": 18.9989,
				"volumePrice": 18.9101,
				"transactionCount": 69436,
				"voluemPrice": 18.9101
			},
			{
				"open": 19.0093,
				"close": 18.9736,
				"volume": 224754628,
				"time": 1395201600000,
				"lowest": 18.8929,
				"highest": 19.1514,
				"volumePrice": 19.0493,
				"transactionCount": 80533,
				"voluemPrice": 19.0493
			},
			{
				"open": 18.9246,
				"close": 18.8821,
				"volume": 208398148,
				"time": 1395288000000,
				"lowest": 18.8339,
				"highest": 19.0239,
				"volumePrice": 18.9042,
				"transactionCount": 71898,
				"voluemPrice": 18.9042
			},
			{
				"open": 18.9975,
				"close": 19.0311,
				"volume": 374448676,
				"time": 1395374400000,
				"lowest": 18.7975,
				"highest": 19.0625,
				"volumePrice": 18.9459,
				"transactionCount": 92085,
				"voluemPrice": 18.9459
			},
			{
				"open": 19.2293,
				"close": 19.2568,
				"volume": 355699484,
				"time": 1395633600000,
				"lowest": 19.1093,
				"highest": 19.3036,
				"volumePrice": 19.2278,
				"transactionCount": 115298,
				"voluemPrice": 19.2278
			},
			{
				"open": 19.3393,
				"close": 19.4639,
				"volume": 280995064,
				"time": 1395720000000,
				"lowest": 19.2711,
				"highest": 19.4911,
				"volumePrice": 19.3828,
				"transactionCount": 103338,
				"voluemPrice": 19.3828
			},
			{
				"open": 19.5186,
				"close": 19.2779,
				"volume": 299768392,
				"time": 1395806400000,
				"lowest": 19.245,
				"highest": 19.6071,
				"volumePrice": 19.4493,
				"transactionCount": 101857,
				"voluemPrice": 19.4493
			},
			{
				"open": 19.2863,
				"close": 19.195,
				"volume": 222030424,
				"time": 1395892800000,
				"lowest": 19.1114,
				"highest": 19.3393,
				"volumePrice": 19.2054,
				"transactionCount": 83993,
				"voluemPrice": 19.2054
			},
			{
				"open": 19.2257,
				"close": 19.1736,
				"volume": 200564252,
				"time": 1395979200000,
				"lowest": 19.0804,
				"highest": 19.2479,
				"volumePrice": 19.1645,
				"transactionCount": 72959,
				"voluemPrice": 19.1645
			},
			{
				"open": 19.2582,
				"close": 19.1693,
				"volume": 168668752,
				"time": 1396238400000,
				"lowest": 19.1404,
				"highest": 19.3146,
				"volumePrice": 19.203,
				"transactionCount": 59218,
				"voluemPrice": 19.203
			},
			{
				"open": 19.2057,
				"close": 19.3446,
				"volume": 200758740,
				"time": 1396324800000,
				"lowest": 19.1704,
				"highest": 19.3525,
				"volumePrice": 19.2612,
				"transactionCount": 69365,
				"voluemPrice": 19.2612
			},
			{
				"open": 19.3707,
				"close": 19.3768,
				"volume": 180419484,
				"time": 1396411200000,
				"lowest": 19.295,
				"highest": 19.41,
				"volumePrice": 19.3514,
				"transactionCount": 63607,
				"voluemPrice": 19.3514
			},
			{
				"open": 19.3354,
				"close": 19.2425,
				"volume": 162591688,
				"time": 1396497600000,
				"lowest": 19.2014,
				"highest": 19.375,
				"volumePrice": 19.2777,
				"transactionCount": 60778,
				"voluemPrice": 19.2777
			},
			{
				"open": 19.2789,
				"close": 18.9936,
				"volume": 275249940,
				"time": 1396584000000,
				"lowest": 18.9493,
				"highest": 19.2857,
				"volumePrice": 19.0633,
				"transactionCount": 102314,
				"voluemPrice": 19.0633
			},
			{
				"open": 18.8579,
				"close": 18.6954,
				"volume": 289850120,
				"time": 1396843200000,
				"lowest": 18.6389,
				"highest": 18.9607,
				"volumePrice": 18.762,
				"transactionCount": 105626,
				"voluemPrice": 18.762
			},
			{
				"open": 18.7568,
				"close": 18.6943,
				"volume": 243886300,
				"time": 1396929600000,
				"lowest": 18.525,
				"highest": 18.79,
				"volumePrice": 18.6762,
				"transactionCount": 94467,
				"voluemPrice": 18.6762
			},
			{
				"open": 18.6657,
				"close": 18.94,
				"volume": 206170888,
				"time": 1397016000000,
				"lowest": 18.6436,
				"highest": 18.9461,
				"volumePrice": 18.8194,
				"transactionCount": 81903,
				"voluemPrice": 18.8194
			},
			{
				"open": 18.9529,
				"close": 18.6957,
				"volume": 239651272,
				"time": 1397102400000,
				"lowest": 18.6846,
				"highest": 19.0086,
				"volumePrice": 18.8223,
				"transactionCount": 95042,
				"voluemPrice": 18.8223
			},
			{
				"open": 18.5357,
				"close": 18.5575,
				"volume": 271900048,
				"time": 1397188800000,
				"lowest": 18.4693,
				"highest": 18.6725,
				"volumePrice": 18.5676,
				"transactionCount": 103858,
				"voluemPrice": 18.5676
			},
			{
				"open": 18.6393,
				"close": 18.6314,
				"volume": 205780008,
				"time": 1397448000000,
				"lowest": 18.4718,
				"highest": 18.6486,
				"volumePrice": 18.5626,
				"transactionCount": 79333,
				"voluemPrice": 18.5626
			},
			{
				"open": 18.5811,
				"close": 18.4986,
				"volume": 266490308,
				"time": 1397534400000,
				"lowest": 18.2618,
				"highest": 18.63,
				"volumePrice": 18.4434,
				"transactionCount": 101207,
				"voluemPrice": 18.4434
			},
			{
				"open": 18.5018,
				"close": 18.5361,
				"volume": 214931864,
				"time": 1397620800000,
				"lowest": 18.362,
				"highest": 18.6104,
				"volumePrice": 18.4919,
				"transactionCount": 83738,
				"voluemPrice": 18.4919
			},
			{
				"open": 18.5714,
				"close": 18.7479,
				"volume": 284426884,
				"time": 1397707200000,
				"lowest": 18.5429,
				"highest": 18.8486,
				"volumePrice": 18.701,
				"transactionCount": 98387,
				"voluemPrice": 18.701
			},
			{
				"open": 18.7621,
				"close": 18.9704,
				"volume": 182674800,
				"time": 1398052800000,
				"lowest": 18.7129,
				"highest": 19.005,
				"volumePrice": 18.9036,
				"transactionCount": 69011,
				"voluemPrice": 18.9036
			},
			{
				"open": 18.8682,
				"close": 18.9893,
				"volume": 202657812,
				"time": 1398139200000,
				"lowest": 18.8036,
				"highest": 18.9938,
				"volumePrice": 18.9256,
				"transactionCount": 71100,
				"voluemPrice": 18.9256
			},
			{
				"open": 18.895,
				"close": 18.7411,
				"volume": 394041312,
				"time": 1398225600000,
				"lowest": 18.7304,
				"highest": 18.9689,
				"volumePrice": 19.2887,
				"transactionCount": 143542,
				"voluemPrice": 19.2887
			},
			{
				"open": 20.2932,
				"close": 20.2775,
				"volume": 759865764,
				"time": 1398312000000,
				"lowest": 20.0261,
				"highest": 20.3571,
				"volumePrice": 20.2277,
				"transactionCount": 289789,
				"voluemPrice": 20.2277
			},
			{
				"open": 20.1618,
				"close": 20.4264,
				"volume": 390274556,
				"time": 1398398400000,
				"lowest": 20.1414,
				"highest": 20.4282,
				"volumePrice": 20.3017,
				"transactionCount": 149349,
				"voluemPrice": 20.3017
			},
			{
				"open": 20.4571,
				"close": 21.2175,
				"volume": 669485264,
				"time": 1398657600000,
				"lowest": 20.4482,
				"highest": 21.2768,
				"volumePrice": 20.9671,
				"transactionCount": 245376,
				"voluemPrice": 20.9671
			},
			{
				"open": 21.205,
				"close": 21.1546,
				"volume": 337378692,
				"time": 1398744000000,
				"lowest": 21.0539,
				"highest": 21.285,
				"volumePrice": 21.175,
				"transactionCount": 125492,
				"voluemPrice": 21.175
			},
			{
				"open": 21.1657,
				"close": 21.0746,
				"volume": 456883532,
				"time": 1398830400000,
				"lowest": 21.0643,
				"highest": 21.4082,
				"volumePrice": 21.2177,
				"transactionCount": 141619,
				"voluemPrice": 21.2177
			},
			{
				"open": 21.1429,
				"close": 21.1243,
				"volume": 244209672,
				"time": 1398916800000,
				"lowest": 20.9414,
				"highest": 21.2429,
				"volumePrice": 21.1177,
				"transactionCount": 100005,
				"voluemPrice": 21.1177
			},
			{
				"open": 21.155,
				"close": 21.1636,
				"volume": 191514288,
				"time": 1399003200000,
				"lowest": 21.0611,
				"highest": 21.2214,
				"volumePrice": 21.1498,
				"transactionCount": 79953,
				"voluemPrice": 21.1498
			},
			{
				"open": 21.0764,
				"close": 21.4629,
				"volume": 287061684,
				"time": 1399262400000,
				"lowest": 21.0714,
				"highest": 21.4643,
				"volumePrice": 21.3304,
				"transactionCount": 109777,
				"voluemPrice": 21.3304
			},
			{
				"open": 21.4929,
				"close": 21.2289,
				"volume": 374565408,
				"time": 1399348800000,
				"lowest": 21.2289,
				"highest": 21.5861,
				"volumePrice": 21.4045,
				"transactionCount": 120067,
				"voluemPrice": 21.4045
			},
			{
				"open": 21.2589,
				"close": 21.1546,
				"volume": 282863952,
				"time": 1399435200000,
				"lowest": 20.9904,
				"highest": 21.3318,
				"volumePrice": 21.1341,
				"transactionCount": 102095,
				"voluemPrice": 21.1341
			},
			{
				"open": 21.0089,
				"close": 20.9996,
				"volume": 230297452,
				"time": 1399521600000,
				"lowest": 20.9429,
				"highest": 21.2289,
				"volumePrice": 21.082,
				"transactionCount": 87910,
				"voluemPrice": 21.082
			},
			{
				"open": 20.8764,
				"close": 20.9122,
				"volume": 291597992,
				"time": 1399608000000,
				"lowest": 20.7261,
				"highest": 20.9375,
				"volumePrice": 20.8181,
				"transactionCount": 99525,
				"voluemPrice": 20.8181
			},
			{
				"open": 20.9818,
				"close": 21.1725,
				"volume": 213298652,
				"time": 1399867200000,
				"lowest": 20.9786,
				"highest": 21.2021,
				"volumePrice": 21.1128,
				"transactionCount": 75654,
				"voluemPrice": 21.1128
			},
			{
				"open": 21.1429,
				"close": 21.2057,
				"volume": 159738376,
				"time": 1399953600000,
				"lowest": 21.0964,
				"highest": 21.2336,
				"volumePrice": 21.1777,
				"transactionCount": 63046,
				"voluemPrice": 21.1777
			},
			{
				"open": 21.1582,
				"close": 21.2096,
				"volume": 166403384,
				"time": 1400040000000,
				"lowest": 21.1336,
				"highest": 21.3357,
				"volumePrice": 21.2386,
				"transactionCount": 64932,
				"voluemPrice": 21.2386
			},
			{
				"open": 21.2393,
				"close": 21.0293,
				"volume": 230846924,
				"time": 1400126400000,
				"lowest": 21.0014,
				"highest": 21.3071,
				"volumePrice": 21.115,
				"transactionCount": 88616,
				"voluemPrice": 21.115
			},
			{
				"open": 21.0225,
				"close": 21.3396,
				"volume": 276367224,
				"time": 1400212800000,
				"lowest": 20.9071,
				"highest": 21.3404,
				"volumePrice": 21.1148,
				"transactionCount": 93422,
				"voluemPrice": 21.1148
			},
			{
				"open": 21.3518,
				"close": 21.5925,
				"volume": 317756096,
				"time": 1400472000000,
				"lowest": 21.3332,
				"highest": 21.6904,
				"volumePrice": 21.5893,
				"transactionCount": 119368,
				"voluemPrice": 21.5893
			},
			{
				"open": 21.5896,
				"close": 21.5968,
				"volume": 233435944,
				"time": 1400558400000,
				"lowest": 21.4546,
				"highest": 21.6571,
				"volumePrice": 21.574,
				"transactionCount": 82980,
				"voluemPrice": 21.574
			},
			{
				"open": 21.5654,
				"close": 21.6539,
				"volume": 196999656,
				"time": 1400644800000,
				"lowest": 21.5021,
				"highest": 21.6679,
				"volumePrice": 21.5976,
				"transactionCount": 71969,
				"voluemPrice": 21.5976
			},
			{
				"open": 21.6643,
				"close": 21.6882,
				"volume": 200875612,
				"time": 1400731200000,
				"lowest": 21.575,
				"highest": 21.7804,
				"volumePrice": 21.675,
				"transactionCount": 71666,
				"voluemPrice": 21.675
			},
			{
				"open": 21.6875,
				"close": 21.9332,
				"volume": 232209936,
				"time": 1400817600000,
				"lowest": 21.6596,
				"highest": 21.9546,
				"volumePrice": 21.7972,
				"transactionCount": 85695,
				"voluemPrice": 21.7972
			},
			{
				"open": 21.9957,
				"close": 22.3439,
				"volume": 348865496,
				"time": 1401163200000,
				"lowest": 21.9868,
				"highest": 22.3521,
				"volumePrice": 22.2147,
				"transactionCount": 126359,
				"voluemPrice": 22.2147
			},
			{
				"open": 22.3579,
				"close": 22.2861,
				"volume": 315687540,
				"time": 1401249600000,
				"lowest": 22.2779,
				"highest": 22.4939,
				"volumePrice": 22.3771,
				"transactionCount": 116787,
				"voluemPrice": 22.3771
			},
			{
				"open": 22.4232,
				"close": 22.6921,
				"volume": 376474532,
				"time": 1401336000000,
				"lowest": 22.4204,
				"highest": 22.7454,
				"volumePrice": 22.5636,
				"transactionCount": 136521,
				"voluemPrice": 22.5636
			},
			{
				"open": 22.785,
				"close": 22.6071,
				"volume": 549516548,
				"time": 1401422400000,
				"lowest": 22.4607,
				"highest": 23.0061,
				"volumePrice": 22.74,
				"transactionCount": 201156,
				"voluemPrice": 22.74
			},
			{
				"open": 22.6414,
				"close": 22.4518,
				"volume": 369311824,
				"time": 1401681600000,
				"lowest": 22.2321,
				"highest": 22.6725,
				"volumePrice": 22.4353,
				"transactionCount": 151350,
				"voluemPrice": 22.4353
			},
			{
				"open": 22.445,
				"close": 22.7693,
				"volume": 292926480,
				"time": 1401768000000,
				"lowest": 22.4375,
				"highest": 22.8121,
				"volumePrice": 22.694,
				"transactionCount": 112247,
				"voluemPrice": 22.694
			},
			{
				"open": 22.7657,
				"close": 23.0293,
				"volume": 335482084,
				"time": 1401854400000,
				"lowest": 22.7182,
				"highest": 23.1389,
				"volumePrice": 22.9836,
				"transactionCount": 123332,
				"voluemPrice": 22.9836
			},
			{
				"open": 23.0786,
				"close": 23.1196,
				"volume": 303804564,
				"time": 1401940800000,
				"lowest": 22.9504,
				"highest": 23.1918,
				"volumePrice": 23.0699,
				"transactionCount": 114812,
				"voluemPrice": 23.0699
			},
			{
				"open": 23.2107,
				"close": 23.0561,
				"volume": 350483644,
				"time": 1402027200000,
				"lowest": 23.0168,
				"highest": 23.2593,
				"volumePrice": 23.1311,
				"transactionCount": 135530,
				"voluemPrice": 23.1311
			},
			{
				"open": 23.175,
				"close": 23.425,
				"volume": 300858088,
				"time": 1402286400000,
				"lowest": 22.9375,
				"highest": 23.47,
				"volumePrice": 23.2683,
				"transactionCount": 454111,
				"voluemPrice": 23.2683
			},
			{
				"open": 23.6825,
				"close": 23.5625,
				"volume": 251103668,
				"time": 1402372800000,
				"lowest": 23.3925,
				"highest": 23.7625,
				"volumePrice": 23.5601,
				"transactionCount": 344762,
				"voluemPrice": 23.5601
			},
			{
				"open": 23.5325,
				"close": 23.465,
				"volume": 182524956,
				"time": 1402459200000,
				"lowest": 23.3675,
				"highest": 23.69,
				"volumePrice": 23.5336,
				"transactionCount": 233662,
				"voluemPrice": 23.5336
			},
			{
				"open": 23.51,
				"close": 23.0725,
				"volume": 218995164,
				"time": 1402545600000,
				"lowest": 22.975,
				"highest": 23.53,
				"volumePrice": 23.1751,
				"transactionCount": 265667,
				"voluemPrice": 23.1751
			},
			{
				"open": 23.05,
				"close": 22.82,
				"volume": 217871120,
				"time": 1402632000000,
				"lowest": 22.72,
				"highest": 23.11,
				"volumePrice": 22.8911,
				"transactionCount": 284018,
				"voluemPrice": 22.8911
			},
			{
				"open": 22.8775,
				"close": 23.05,
				"volume": 142245080,
				"time": 1402891200000,
				"lowest": 22.8625,
				"highest": 23.1875,
				"volumePrice": 23.0255,
				"transactionCount": 190980,
				"voluemPrice": 23.0255
			},
			{
				"open": 23.0775,
				"close": 23.02,
				"volume": 118905388,
				"time": 1402977600000,
				"lowest": 22.95,
				"highest": 23.175,
				"volumePrice": 23.0513,
				"transactionCount": 164360,
				"voluemPrice": 23.0513
			},
			{
				"open": 23.0675,
				"close": 23.045,
				"volume": 134056292,
				"time": 1403064000000,
				"lowest": 22.8375,
				"highest": 23.0725,
				"volumePrice": 22.9624,
				"transactionCount": 179630,
				"voluemPrice": 22.9624
			},
			{
				"open": 23.0725,
				"close": 22.965,
				"volume": 142110744,
				"time": 1403150400000,
				"lowest": 22.8425,
				"highest": 23.075,
				"volumePrice": 22.9568,
				"transactionCount": 184269,
				"voluemPrice": 22.9568
			},
			{
				"open": 22.9625,
				"close": 22.7275,
				"volume": 403585064,
				"time": 1403236800000,
				"lowest": 22.725,
				"highest": 23.1375,
				"volumePrice": 22.864,
				"transactionCount": 214808,
				"voluemPrice": 22.864
			},
			{
				"open": 22.83,
				"close": 22.7075,
				"volume": 174777508,
				"time": 1403496000000,
				"lowest": 22.65,
				"highest": 22.905,
				"volumePrice": 22.7304,
				"transactionCount": 174458,
				"voluemPrice": 22.7304
			},
			{
				"open": 22.6875,
				"close": 22.57,
				"volume": 156144348,
				"time": 1403582400000,
				"lowest": 22.5475,
				"highest": 22.935,
				"volumePrice": 22.7727,
				"transactionCount": 198355,
				"voluemPrice": 22.7727
			},
			{
				"open": 22.5525,
				"close": 22.59,
				"volume": 147474160,
				"time": 1403668800000,
				"lowest": 22.4125,
				"highest": 22.675,
				"volumePrice": 22.5539,
				"transactionCount": 188720,
				"voluemPrice": 22.5539
			},
			{
				"open": 22.5925,
				"close": 22.725,
				"volume": 130517436,
				"time": 1403755200000,
				"lowest": 22.45,
				"highest": 22.7625,
				"volumePrice": 22.5783,
				"transactionCount": 181124,
				"voluemPrice": 22.5783
			},
			{
				"open": 22.705,
				"close": 22.995,
				"volume": 256115212,
				"time": 1403841600000,
				"lowest": 22.6925,
				"highest": 23,
				"volumePrice": 22.9332,
				"transactionCount": 200560,
				"voluemPrice": 22.9332
			},
			{
				"open": 23.025,
				"close": 23.2325,
				"volume": 198356112,
				"time": 1404100800000,
				"lowest": 23.0225,
				"highest": 23.4313,
				"volumePrice": 23.236,
				"transactionCount": 231434,
				"voluemPrice": 23.236
			},
			{
				"open": 23.38,
				"close": 23.38,
				"volume": 152893908,
				"time": 1404187200000,
				"lowest": 23.2825,
				"highest": 23.5175,
				"volumePrice": 23.4315,
				"transactionCount": 199900,
				"voluemPrice": 23.4315
			},
			{
				"open": 23.4663,
				"close": 23.37,
				"volume": 113860292,
				"time": 1404273600000,
				"lowest": 23.2725,
				"highest": 23.515,
				"volumePrice": 23.3999,
				"transactionCount": 147046,
				"voluemPrice": 23.3999
			},
			{
				"open": 23.4175,
				"close": 23.5075,
				"volume": 91565812,
				"time": 1404360000000,
				"lowest": 23.3,
				"highest": 23.525,
				"volumePrice": 23.4263,
				"transactionCount": 106453,
				"voluemPrice": 23.4263
			},
			{
				"open": 23.535,
				"close": 23.992,
				"volume": 225599336,
				"time": 1404705600000,
				"lowest": 23.525,
				"highest": 23.9975,
				"volumePrice": 23.8131,
				"transactionCount": 267451,
				"voluemPrice": 23.8131
			},
			{
				"open": 24.0675,
				"close": 23.8375,
				"volume": 260877584,
				"time": 1404792000000,
				"lowest": 23.48,
				"highest": 24.2,
				"volumePrice": 23.8304,
				"transactionCount": 311047,
				"voluemPrice": 23.8304
			},
			{
				"open": 23.86,
				"close": 23.8475,
				"volume": 145745160,
				"time": 1404878400000,
				"lowest": 23.69,
				"highest": 23.9875,
				"volumePrice": 23.8715,
				"transactionCount": 185189,
				"voluemPrice": 23.8715
			},
			{
				"open": 23.44,
				"close": 23.7588,
				"volume": 158741256,
				"time": 1404964800000,
				"lowest": 23.38,
				"highest": 23.8875,
				"volumePrice": 23.6726,
				"transactionCount": 204428,
				"voluemPrice": 23.6726
			},
			{
				"open": 23.84,
				"close": 23.805,
				"volume": 136072912,
				"time": 1405051200000,
				"lowest": 23.7156,
				"highest": 23.9725,
				"volumePrice": 23.8204,
				"transactionCount": 169492,
				"voluemPrice": 23.8204
			},
			{
				"open": 23.965,
				"close": 24.1125,
				"volume": 171240620,
				"time": 1405310400000,
				"lowest": 23.9125,
				"highest": 24.2225,
				"volumePrice": 24.1206,
				"transactionCount": 209183,
				"voluemPrice": 24.1206
			},
			{
				"open": 24.2,
				"close": 23.83,
				"volume": 182784284,
				"time": 1405396800000,
				"lowest": 23.7575,
				"highest": 24.2125,
				"volumePrice": 23.9456,
				"transactionCount": 210875,
				"voluemPrice": 23.9456
			},
			{
				"open": 24.2425,
				"close": 23.695,
				"volume": 214009512,
				"time": 1405483200000,
				"lowest": 23.685,
				"highest": 24.275,
				"volumePrice": 24.0314,
				"transactionCount": 246108,
				"voluemPrice": 24.0314
			},
			{
				"open": 23.7575,
				"close": 23.2725,
				"volume": 228990172,
				"time": 1405569600000,
				"lowest": 23.1425,
				"highest": 23.8198,
				"volumePrice": 23.4466,
				"transactionCount": 277446,
				"voluemPrice": 23.4466
			},
			{
				"open": 23.405,
				"close": 23.6075,
				"volume": 199946496,
				"time": 1405656000000,
				"lowest": 23.255,
				"highest": 23.685,
				"volumePrice": 23.5028,
				"transactionCount": 209616,
				"voluemPrice": 23.5028
			},
			{
				"open": 23.7475,
				"close": 23.4847,
				"volume": 156315008,
				"time": 1405915200000,
				"lowest": 23.43,
				"highest": 23.75,
				"volumePrice": 23.5347,
				"transactionCount": 188638,
				"voluemPrice": 23.5347
			},
			{
				"open": 23.67,
				"close": 23.68,
				"volume": 220785988,
				"time": 1406001600000,
				"lowest": 23.53,
				"highest": 23.7225,
				"volumePrice": 23.6073,
				"transactionCount": 253711,
				"voluemPrice": 23.6073
			},
			{
				"open": 23.855,
				"close": 24.2975,
				"volume": 371668876,
				"time": 1406088000000,
				"lowest": 23.7925,
				"highest": 24.47,
				"volumePrice": 24.2467,
				"transactionCount": 393765,
				"voluemPrice": 24.2467
			},
			{
				"open": 24.2588,
				"close": 24.2575,
				"volume": 182915372,
				"time": 1406174400000,
				"lowest": 24.105,
				"highest": 24.33,
				"volumePrice": 24.2248,
				"transactionCount": 205130,
				"voluemPrice": 24.2248
			},
			{
				"open": 24.2125,
				"close": 24.4178,
				"volume": 173876468,
				"time": 1406260800000,
				"lowest": 24.16,
				"highest": 24.46,
				"volumePrice": 24.3377,
				"transactionCount": 192071,
				"voluemPrice": 24.3377
			},
			{
				"open": 24.455,
				"close": 24.755,
				"volume": 221152516,
				"time": 1406520000000,
				"lowest": 24.3875,
				"highest": 24.81,
				"volumePrice": 24.6238,
				"transactionCount": 244677,
				"voluemPrice": 24.6238
			},
			{
				"open": 24.8325,
				"close": 24.595,
				"volume": 172572380,
				"time": 1406606400000,
				"lowest": 24.5625,
				"highest": 24.86,
				"volumePrice": 24.6712,
				"transactionCount": 218146,
				"voluemPrice": 24.6712
			},
			{
				"open": 24.61,
				"close": 24.5375,
				"volume": 132040004,
				"time": 1406692800000,
				"lowest": 24.4175,
				"highest": 24.675,
				"volumePrice": 24.5648,
				"transactionCount": 181171,
				"voluemPrice": 24.5648
			},
			{
				"open": 24.29,
				"close": 23.9,
				"volume": 227354604,
				"time": 1406779200000,
				"lowest": 23.8325,
				"highest": 24.3625,
				"volumePrice": 24.0323,
				"transactionCount": 276615,
				"voluemPrice": 24.0323
			},
			{
				"open": 23.725,
				"close": 24.0325,
				"volume": 194044724,
				"time": 1406865600000,
				"lowest": 23.7025,
				"highest": 24.155,
				"volumePrice": 23.9539,
				"transactionCount": 260539,
				"voluemPrice": 23.9539
			},
			{
				"open": 24.0925,
				"close": 23.8975,
				"volume": 159830496,
				"time": 1407124800000,
				"lowest": 23.7925,
				"highest": 24.145,
				"volumePrice": 23.9443,
				"transactionCount": 195199,
				"voluemPrice": 23.9443
			},
			{
				"open": 23.84,
				"close": 23.78,
				"volume": 223730652,
				"time": 1407211200000,
				"lowest": 23.59,
				"highest": 23.92,
				"volumePrice": 23.7462,
				"transactionCount": 270600,
				"voluemPrice": 23.7462
			},
			{
				"open": 23.6875,
				"close": 23.74,
				"volume": 154233368,
				"time": 1407297600000,
				"lowest": 23.6775,
				"highest": 23.87,
				"volumePrice": 23.7652,
				"transactionCount": 176276,
				"voluemPrice": 23.7652
			},
			{
				"open": 23.7325,
				"close": 23.62,
				"volume": 186844716,
				"time": 1407384000000,
				"lowest": 23.525,
				"highest": 23.9875,
				"volumePrice": 23.7606,
				"transactionCount": 211410,
				"voluemPrice": 23.7606
			},
			{
				"open": 23.565,
				"close": 23.685,
				"volume": 167460772,
				"time": 1407470400000,
				"lowest": 23.32,
				"highest": 23.705,
				"volumePrice": 23.5486,
				"transactionCount": 210736,
				"voluemPrice": 23.5486
			},
			{
				"open": 23.8175,
				"close": 23.9975,
				"volume": 146339376,
				"time": 1407729600000,
				"lowest": 23.7089,
				"highest": 24.02,
				"volumePrice": 23.9384,
				"transactionCount": 183403,
				"voluemPrice": 23.9384
			},
			{
				"open": 24.01,
				"close": 23.9925,
				"volume": 134988384,
				"time": 1407816000000,
				"lowest": 23.9025,
				"highest": 24.22,
				"volumePrice": 24.0568,
				"transactionCount": 177705,
				"voluemPrice": 24.0568
			},
			{
				"open": 24.0375,
				"close": 24.31,
				"volume": 127659076,
				"time": 1407902400000,
				"lowest": 24.01,
				"highest": 24.31,
				"volumePrice": 24.2022,
				"transactionCount": 164152,
				"voluemPrice": 24.2022
			},
			{
				"open": 24.3325,
				"close": 24.375,
				"volume": 112462264,
				"time": 1407988800000,
				"lowest": 24.2,
				"highest": 24.3925,
				"volumePrice": 24.3341,
				"transactionCount": 165842,
				"voluemPrice": 24.3341
			},
			{
				"open": 24.475,
				"close": 24.495,
				"volume": 195805324,
				"time": 1408075200000,
				"lowest": 24.215,
				"highest": 24.5475,
				"volumePrice": 24.416,
				"transactionCount": 234227,
				"voluemPrice": 24.416
			},
			{
				"open": 24.6225,
				"close": 24.79,
				"volume": 190288220,
				"time": 1408334400000,
				"lowest": 24.495,
				"highest": 24.8425,
				"volumePrice": 24.7361,
				"transactionCount": 219282,
				"voluemPrice": 24.7361
			},
			{
				"open": 24.8525,
				"close": 25.1325,
				"volume": 277595880,
				"time": 1408420800000,
				"lowest": 24.83,
				"highest": 25.17,
				"volumePrice": 25.0331,
				"transactionCount": 298945,
				"voluemPrice": 25.0331
			},
			{
				"open": 25.11,
				"close": 25.1425,
				"volume": 209205808,
				"time": 1408507200000,
				"lowest": 24.9875,
				"highest": 25.2725,
				"volumePrice": 25.1537,
				"transactionCount": 219353,
				"voluemPrice": 25.1537
			},
			{
				"open": 25.1425,
				"close": 25.145,
				"volume": 133912792,
				"time": 1408593600000,
				"lowest": 25.0275,
				"highest": 25.235,
				"volumePrice": 25.1381,
				"transactionCount": 163615,
				"voluemPrice": 25.1381
			},
			{
				"open": 25.0725,
				"close": 25.33,
				"volume": 176735336,
				"time": 1408680000000,
				"lowest": 25.0475,
				"highest": 25.3675,
				"volumePrice": 25.2689,
				"transactionCount": 206056,
				"voluemPrice": 25.2689
			},
			{
				"open": 25.4475,
				"close": 25.385,
				"volume": 161080692,
				"time": 1408939200000,
				"lowest": 25.32,
				"highest": 25.5425,
				"volumePrice": 25.4424,
				"transactionCount": 184675,
				"voluemPrice": 25.4424
			},
			{
				"open": 25.355,
				"close": 25.2223,
				"volume": 132607936,
				"time": 1409025600000,
				"lowest": 25.215,
				"highest": 25.375,
				"volumePrice": 25.2805,
				"transactionCount": 180093,
				"voluemPrice": 25.2805
			},
			{
				"open": 25.255,
				"close": 25.5325,
				"volume": 209475644,
				"time": 1409112000000,
				"lowest": 25.175,
				"highest": 25.6425,
				"volumePrice": 25.4441,
				"transactionCount": 214101,
				"voluemPrice": 25.4441
			},
			{
				"open": 25.3975,
				"close": 25.5625,
				"volume": 268428004,
				"time": 1409198400000,
				"lowest": 25.39,
				"highest": 25.695,
				"volumePrice": 25.5872,
				"transactionCount": 207656,
				"voluemPrice": 25.5872
			},
			{
				"open": 25.715,
				"close": 25.625,
				"volume": 178380564,
				"time": 1409284800000,
				"lowest": 25.55,
				"highest": 25.725,
				"volumePrice": 25.6281,
				"transactionCount": 176547,
				"voluemPrice": 25.6281
			},
			{
				"open": 25.765,
				"close": 25.825,
				"volume": 214041048,
				"time": 1409630400000,
				"lowest": 25.68,
				"highest": 25.935,
				"volumePrice": 25.8266,
				"transactionCount": 238757,
				"voluemPrice": 25.8266
			},
			{
				"open": 25.775,
				"close": 24.735,
				"volume": 501680884,
				"time": 1409716800000,
				"lowest": 24.645,
				"highest": 25.8,
				"volumePrice": 25.0003,
				"transactionCount": 637346,
				"voluemPrice": 25.0003
			},
			{
				"open": 24.7125,
				"close": 24.53,
				"volume": 342867588,
				"time": 1409803200000,
				"lowest": 24.4475,
				"highest": 25.0225,
				"volumePrice": 24.7488,
				"transactionCount": 421929,
				"voluemPrice": 24.7488
			},
			{
				"open": 24.7,
				"close": 24.7425,
				"volume": 227209612,
				"time": 1409889600000,
				"lowest": 24.5775,
				"highest": 24.8475,
				"volumePrice": 24.7138,
				"transactionCount": 266289,
				"voluemPrice": 24.7138
			},
			{
				"open": 24.825,
				"close": 24.59,
				"volume": 185425768,
				"time": 1410148800000,
				"lowest": 24.5125,
				"highest": 24.8275,
				"volumePrice": 24.6579,
				"transactionCount": 230667,
				"voluemPrice": 24.6579
			},
			{
				"open": 24.77,
				"close": 24.4975,
				"volume": 759345108,
				"time": 1410235200000,
				"lowest": 24.035,
				"highest": 25.77,
				"volumePrice": 24.9332,
				"transactionCount": 899638,
				"voluemPrice": 24.9332
			},
			{
				"open": 24.5025,
				"close": 25.25,
				"volume": 403325708,
				"time": 1410321600000,
				"lowest": 24.44,
				"highest": 25.2775,
				"volumePrice": 24.8879,
				"transactionCount": 430136,
				"voluemPrice": 24.8879
			},
			{
				"open": 25.1025,
				"close": 25.3575,
				"volume": 249598724,
				"time": 1410408000000,
				"lowest": 24.905,
				"highest": 25.36,
				"volumePrice": 25.1144,
				"transactionCount": 268800,
				"voluemPrice": 25.1144
			},
			{
				"open": 25.3025,
				"close": 25.415,
				"volume": 256387612,
				"time": 1410494400000,
				"lowest": 25.27,
				"highest": 25.5475,
				"volumePrice": 25.4189,
				"transactionCount": 273023,
				"voluemPrice": 25.4189
			},
			{
				"open": 25.7025,
				"close": 25.4075,
				"volume": 245265812,
				"time": 1410753600000,
				"lowest": 25.36,
				"highest": 25.7625,
				"volumePrice": 25.5335,
				"transactionCount": 281604,
				"voluemPrice": 25.5335
			},
			{
				"open": 24.95,
				"close": 25.215,
				"volume": 267629824,
				"time": 1410840000000,
				"lowest": 24.7225,
				"highest": 25.315,
				"volumePrice": 25.0553,
				"transactionCount": 319326,
				"voluemPrice": 25.0553
			},
			{
				"open": 25.3175,
				"close": 25.395,
				"volume": 243705992,
				"time": 1410926400000,
				"lowest": 25.1481,
				"highest": 25.45,
				"volumePrice": 25.3257,
				"transactionCount": 279087,
				"voluemPrice": 25.3257
			},
			{
				"open": 25.4825,
				"close": 25.4475,
				"volume": 149197672,
				"time": 1411012800000,
				"lowest": 25.39,
				"highest": 25.5875,
				"volumePrice": 25.4946,
				"transactionCount": 181817,
				"voluemPrice": 25.4946
			},
			{
				"open": 25.5725,
				"close": 25.24,
				"volume": 283209084,
				"time": 1411099200000,
				"lowest": 25.22,
				"highest": 25.5875,
				"volumePrice": 25.4172,
				"transactionCount": 234115,
				"voluemPrice": 25.4172
			},
			{
				"open": 25.45,
				"close": 25.265,
				"volume": 209674024,
				"time": 1411358400000,
				"lowest": 25.145,
				"highest": 25.535,
				"volumePrice": 25.2926,
				"transactionCount": 253583,
				"voluemPrice": 25.2926
			},
			{
				"open": 25.15,
				"close": 25.66,
				"volume": 253607040,
				"time": 1411444800000,
				"lowest": 25.135,
				"highest": 25.735,
				"volumePrice": 25.5715,
				"transactionCount": 274834,
				"voluemPrice": 25.5715
			},
			{
				"open": 25.54,
				"close": 25.4375,
				"volume": 240686112,
				"time": 1411531200000,
				"lowest": 25.3,
				"highest": 25.7125,
				"volumePrice": 25.4694,
				"transactionCount": 262640,
				"voluemPrice": 25.4694
			},
			{
				"open": 25.1275,
				"close": 24.4675,
				"volume": 400352540,
				"time": 1411617600000,
				"lowest": 24.43,
				"highest": 25.1775,
				"volumePrice": 24.6901,
				"transactionCount": 490929,
				"voluemPrice": 24.6901
			},
			{
				"open": 24.6325,
				"close": 25.1875,
				"volume": 249482004,
				"time": 1411704000000,
				"lowest": 24.6,
				"highest": 25.1875,
				"volumePrice": 24.9736,
				"transactionCount": 284699,
				"voluemPrice": 24.9736
			},
			{
				"open": 24.6625,
				"close": 25.0275,
				"volume": 199064828,
				"time": 1411963200000,
				"lowest": 24.6575,
				"highest": 25.1088,
				"volumePrice": 24.9605,
				"transactionCount": 220213,
				"voluemPrice": 24.9605
			},
			{
				"open": 25.2025,
				"close": 25.1875,
				"volume": 221050556,
				"time": 1412049600000,
				"lowest": 25.1325,
				"highest": 25.385,
				"volumePrice": 25.2512,
				"transactionCount": 234940,
				"voluemPrice": 25.2512
			},
			{
				"open": 25.1475,
				"close": 24.795,
				"volume": 205963876,
				"time": 1412136000000,
				"lowest": 24.675,
				"highest": 25.1725,
				"volumePrice": 24.8865,
				"transactionCount": 251176,
				"voluemPrice": 24.8865
			},
			{
				"open": 24.8175,
				"close": 24.975,
				"volume": 191027712,
				"time": 1412222400000,
				"lowest": 24.51,
				"highest": 25.055,
				"volumePrice": 24.8188,
				"transactionCount": 225041,
				"voluemPrice": 24.8188
			},
			{
				"open": 24.86,
				"close": 24.905,
				"volume": 173878340,
				"time": 1412308800000,
				"lowest": 24.76,
				"highest": 25.0525,
				"volumePrice": 24.9318,
				"transactionCount": 211827,
				"voluemPrice": 24.9318
			},
			{
				"open": 24.9875,
				"close": 24.905,
				"volume": 148204728,
				"time": 1412568000000,
				"lowest": 24.855,
				"highest": 25.1625,
				"volumePrice": 24.9937,
				"transactionCount": 186768,
				"voluemPrice": 24.9937
			},
			{
				"open": 24.8575,
				"close": 24.6875,
				"volume": 168376676,
				"time": 1412654400000,
				"lowest": 24.6825,
				"highest": 25.03,
				"volumePrice": 24.8676,
				"transactionCount": 204987,
				"voluemPrice": 24.8676
			},
			{
				"open": 24.69,
				"close": 25.2,
				"volume": 229616496,
				"time": 1412740800000,
				"lowest": 24.5775,
				"highest": 25.2775,
				"volumePrice": 24.9642,
				"transactionCount": 273850,
				"voluemPrice": 24.9642
			},
			{
				"open": 25.385,
				"close": 25.255,
				"volume": 309506020,
				"time": 1412827200000,
				"lowest": 25.1525,
				"highest": 25.595,
				"volumePrice": 25.3656,
				"transactionCount": 380579,
				"voluemPrice": 25.3656
			},
			{
				"open": 25.1725,
				"close": 25.1825,
				"volume": 265326368,
				"time": 1412913600000,
				"lowest": 25.075,
				"highest": 25.5075,
				"volumePrice": 25.3292,
				"transactionCount": 331621,
				"voluemPrice": 25.3292
			},
			{
				"open": 25.3325,
				"close": 24.9525,
				"volume": 214333472,
				"time": 1413172800000,
				"lowest": 24.9525,
				"highest": 25.445,
				"volumePrice": 25.1987,
				"transactionCount": 280270,
				"voluemPrice": 25.1987
			},
			{
				"open": 25.0975,
				"close": 24.6875,
				"volume": 253918248,
				"time": 1413259200000,
				"lowest": 24.6425,
				"highest": 25.13,
				"volumePrice": 24.8842,
				"transactionCount": 319981,
				"voluemPrice": 24.8842
			},
			{
				"open": 24.4925,
				"close": 24.385,
				"volume": 403734400,
				"time": 1413345600000,
				"lowest": 23.795,
				"highest": 24.7875,
				"volumePrice": 24.3346,
				"transactionCount": 554794,
				"voluemPrice": 24.3346
			},
			{
				"open": 23.8875,
				"close": 24.065,
				"volume": 288617272,
				"time": 1413432000000,
				"lowest": 23.8525,
				"highest": 24.43,
				"volumePrice": 24.1153,
				"transactionCount": 389422,
				"voluemPrice": 24.1153
			},
			{
				"open": 24.375,
				"close": 24.4175,
				"volume": 272578752,
				"time": 1413518400000,
				"lowest": 24.2025,
				"highest": 24.75,
				"volumePrice": 24.473,
				"transactionCount": 317060,
				"voluemPrice": 24.473
			},
			{
				"open": 24.5788,
				"close": 24.94,
				"volume": 309469116,
				"time": 1413777600000,
				"lowest": 24.575,
				"highest": 24.99,
				"volumePrice": 24.8829,
				"transactionCount": 353694,
				"voluemPrice": 24.8829
			},
			{
				"open": 25.755,
				"close": 25.6175,
				"volume": 378491832,
				"time": 1413864000000,
				"lowest": 25.3175,
				"highest": 25.755,
				"volumePrice": 25.5492,
				"transactionCount": 425374,
				"voluemPrice": 25.5492
			},
			{
				"open": 25.71,
				"close": 25.7475,
				"volume": 273052584,
				"time": 1413950400000,
				"lowest": 25.65,
				"highest": 26.0275,
				"volumePrice": 25.8361,
				"transactionCount": 312771,
				"voluemPrice": 25.8361
			},
			{
				"open": 26.02,
				"close": 26.2075,
				"volume": 284285752,
				"time": 1414036800000,
				"lowest": 25.9075,
				"highest": 26.2628,
				"volumePrice": 26.175,
				"transactionCount": 324727,
				"voluemPrice": 26.175
			},
			{
				"open": 26.295,
				"close": 26.305,
				"volume": 188215664,
				"time": 1414123200000,
				"lowest": 26.1325,
				"highest": 26.3725,
				"volumePrice": 26.2521,
				"transactionCount": 198636,
				"voluemPrice": 26.2521
			},
			{
				"open": 26.2125,
				"close": 26.2775,
				"volume": 136750776,
				"time": 1414382400000,
				"lowest": 26.175,
				"highest": 26.37,
				"volumePrice": 26.286,
				"transactionCount": 177701,
				"voluemPrice": 26.286
			},
			{
				"open": 26.35,
				"close": 26.685,
				"volume": 192243796,
				"time": 1414468800000,
				"lowest": 26.3375,
				"highest": 26.685,
				"volumePrice": 26.5905,
				"transactionCount": 197971,
				"voluemPrice": 26.5905
			},
			{
				"open": 26.6625,
				"close": 26.835,
				"volume": 210751320,
				"time": 1414555200000,
				"lowest": 26.59,
				"highest": 26.8425,
				"volumePrice": 26.7637,
				"transactionCount": 215818,
				"voluemPrice": 26.7637
			},
			{
				"open": 26.7398,
				"close": 26.745,
				"volume": 162619172,
				"time": 1414641600000,
				"lowest": 26.475,
				"highest": 26.8375,
				"volumePrice": 26.6935,
				"transactionCount": 183325,
				"voluemPrice": 26.6935
			},
			{
				"open": 27.0025,
				"close": 27,
				"volume": 178556700,
				"time": 1414728000000,
				"lowest": 26.8025,
				"highest": 27.01,
				"volumePrice": 26.9315,
				"transactionCount": 203847,
				"voluemPrice": 26.9315
			},
			{
				"open": 27.055,
				"close": 27.35,
				"volume": 209129300,
				"time": 1414990800000,
				"lowest": 27.0025,
				"highest": 27.575,
				"volumePrice": 27.3289,
				"transactionCount": 228423,
				"voluemPrice": 27.3289
			},
			{
				"open": 27.34,
				"close": 27.15,
				"volume": 166297460,
				"time": 1415077200000,
				"lowest": 26.93,
				"highest": 27.3725,
				"volumePrice": 27.171,
				"transactionCount": 204661,
				"voluemPrice": 27.171
			},
			{
				"open": 27.275,
				"close": 27.215,
				"volume": 149743620,
				"time": 1415163600000,
				"lowest": 27.0313,
				"highest": 27.325,
				"volumePrice": 27.204,
				"transactionCount": 162193,
				"voluemPrice": 27.204
			},
			{
				"open": 27.15,
				"close": 27.175,
				"volume": 139873828,
				"time": 1415250000000,
				"lowest": 26.95,
				"highest": 27.1975,
				"volumePrice": 27.1019,
				"transactionCount": 160204,
				"voluemPrice": 27.1019
			},
			{
				"open": 27.1875,
				"close": 27.2525,
				"volume": 134764656,
				"time": 1415336400000,
				"lowest": 27.1375,
				"highest": 27.33,
				"volumePrice": 27.2344,
				"transactionCount": 146600,
				"voluemPrice": 27.2344
			},
			{
				"open": 27.255,
				"close": 27.2075,
				"volume": 108770988,
				"time": 1415595600000,
				"lowest": 27.1675,
				"highest": 27.3325,
				"volumePrice": 27.2524,
				"transactionCount": 145029,
				"voluemPrice": 27.2524
			},
			{
				"open": 27.175,
				"close": 27.425,
				"volume": 109769008,
				"time": 1415682000000,
				"lowest": 27.1,
				"highest": 27.4375,
				"volumePrice": 27.2618,
				"transactionCount": 136876,
				"voluemPrice": 27.2618
			},
			{
				"open": 27.345,
				"close": 27.8125,
				"volume": 187437724,
				"time": 1415768400000,
				"lowest": 27.3425,
				"highest": 27.8575,
				"volumePrice": 27.6903,
				"transactionCount": 212162,
				"voluemPrice": 27.6903
			},
			{
				"open": 27.95,
				"close": 28.205,
				"volume": 238091312,
				"time": 1415854800000,
				"lowest": 27.9,
				"highest": 28.3625,
				"volumePrice": 28.1892,
				"transactionCount": 271164,
				"voluemPrice": 28.1892
			},
			{
				"open": 28.2875,
				"close": 28.545,
				"volume": 176254016,
				"time": 1415941200000,
				"lowest": 28.2625,
				"highest": 28.5475,
				"volumePrice": 28.4452,
				"transactionCount": 205896,
				"voluemPrice": 28.4452
			},
			{
				"open": 28.5675,
				"close": 28.4975,
				"volume": 186966828,
				"time": 1416200400000,
				"lowest": 28.325,
				"highest": 29.32,
				"volumePrice": 28.5568,
				"transactionCount": 223777,
				"voluemPrice": 28.5568
			},
			{
				"open": 28.485,
				"close": 28.8675,
				"volume": 176895904,
				"time": 1416286800000,
				"lowest": 28.4725,
				"highest": 28.9225,
				"volumePrice": 28.7558,
				"transactionCount": 204522,
				"voluemPrice": 28.7558
			},
			{
				"open": 28.86,
				"close": 28.6675,
				"volume": 166876636,
				"time": 1416373200000,
				"lowest": 28.45,
				"highest": 28.935,
				"volumePrice": 28.6721,
				"transactionCount": 209788,
				"voluemPrice": 28.6721
			},
			{
				"open": 28.7275,
				"close": 29.0775,
				"volume": 173582148,
				"time": 1416459600000,
				"lowest": 28.7125,
				"highest": 29.215,
				"volumePrice": 29.0404,
				"transactionCount": 198558,
				"voluemPrice": 29.0404
			},
			{
				"open": 29.3775,
				"close": 29.1175,
				"volume": 228711924,
				"time": 1416546000000,
				"lowest": 29.0075,
				"highest": 29.3925,
				"volumePrice": 29.147,
				"transactionCount": 227988,
				"voluemPrice": 29.147
			},
			{
				"open": 29.2125,
				"close": 29.6563,
				"volume": 189803296,
				"time": 1416805200000,
				"lowest": 29.155,
				"highest": 29.6925,
				"volumePrice": 29.4789,
				"transactionCount": 213258,
				"voluemPrice": 29.4789
			},
			{
				"open": 29.7675,
				"close": 29.4,
				"volume": 275361760,
				"time": 1416891600000,
				"lowest": 29.3625,
				"highest": 29.9375,
				"volumePrice": 29.6381,
				"transactionCount": 297828,
				"voluemPrice": 29.6381
			},
			{
				"open": 29.485,
				"close": 29.75,
				"volume": 163327544,
				"time": 1416978000000,
				"lowest": 29.4575,
				"highest": 29.775,
				"volumePrice": 29.6686,
				"transactionCount": 185015,
				"voluemPrice": 29.6686
			},
			{
				"open": 29.8175,
				"close": 29.7325,
				"volume": 99255928,
				"time": 1417150800000,
				"lowest": 29.5125,
				"highest": 29.85,
				"volumePrice": 29.6962,
				"transactionCount": 111898,
				"voluemPrice": 29.6962
			},
			{
				"open": 29.7025,
				"close": 28.7675,
				"volume": 335140404,
				"time": 1417410000000,
				"lowest": 27.8175,
				"highest": 29.8125,
				"volumePrice": 28.8935,
				"transactionCount": 454404,
				"voluemPrice": 28.8935
			},
			{
				"open": 28.375,
				"close": 28.6575,
				"volume": 237395720,
				"time": 1417496400000,
				"lowest": 28.1875,
				"highest": 28.9375,
				"volumePrice": 28.6391,
				"transactionCount": 287689,
				"voluemPrice": 28.6391
			},
			{
				"open": 28.9375,
				"close": 28.9825,
				"volume": 172252528,
				"time": 1417582800000,
				"lowest": 28.7775,
				"highest": 29.0875,
				"volumePrice": 28.9401,
				"transactionCount": 200515,
				"voluemPrice": 28.9401
			},
			{
				"open": 28.9425,
				"close": 28.8725,
				"volume": 168623104,
				"time": 1417669200000,
				"lowest": 28.8225,
				"highest": 29.3,
				"volumePrice": 29.0227,
				"transactionCount": 186067,
				"voluemPrice": 29.0227
			},
			{
				"open": 28.9975,
				"close": 28.75,
				"volume": 153273980,
				"time": 1417755600000,
				"lowest": 28.66,
				"highest": 29.02,
				"volumePrice": 28.8578,
				"transactionCount": 192506,
				"voluemPrice": 28.8578
			},
			{
				"open": 28.525,
				"close": 28.1,
				"volume": 230653056,
				"time": 1418014800000,
				"lowest": 27.905,
				"highest": 28.662,
				"volumePrice": 28.2288,
				"transactionCount": 302821,
				"voluemPrice": 28.2288
			},
			{
				"open": 27.5475,
				"close": 28.53,
				"volume": 240822548,
				"time": 1418101200000,
				"lowest": 27.3375,
				"highest": 28.575,
				"volumePrice": 28.019,
				"transactionCount": 308547,
				"voluemPrice": 28.019
			},
			{
				"open": 28.6025,
				"close": 27.9875,
				"volume": 178261272,
				"time": 1418187600000,
				"lowest": 27.885,
				"highest": 28.7125,
				"volumePrice": 28.2284,
				"transactionCount": 257745,
				"voluemPrice": 28.2284
			},
			{
				"open": 28.065,
				"close": 27.905,
				"volume": 165878136,
				"time": 1418274000000,
				"lowest": 27.835,
				"highest": 28.45,
				"volumePrice": 28.184,
				"transactionCount": 243100,
				"voluemPrice": 28.184
			},
			{
				"open": 27.615,
				"close": 27.4325,
				"volume": 224109232,
				"time": 1418360400000,
				"lowest": 27.395,
				"highest": 27.9675,
				"volumePrice": 27.6122,
				"transactionCount": 282047,
				"voluemPrice": 27.6122
			},
			{
				"open": 27.675,
				"close": 27.0563,
				"volume": 268848564,
				"time": 1418619600000,
				"lowest": 26.5875,
				"highest": 27.9,
				"volumePrice": 27.2657,
				"transactionCount": 373291,
				"voluemPrice": 27.2657
			},
			{
				"open": 26.5925,
				"close": 26.6863,
				"volume": 243162932,
				"time": 1418706000000,
				"lowest": 26.565,
				"highest": 27.54,
				"volumePrice": 26.9761,
				"transactionCount": 317965,
				"voluemPrice": 26.9761
			},
			{
				"open": 26.78,
				"close": 27.3525,
				"volume": 213646276,
				"time": 1418792400000,
				"lowest": 26.705,
				"highest": 27.46,
				"volumePrice": 27.0481,
				"transactionCount": 303948,
				"voluemPrice": 27.0481
			},
			{
				"open": 27.9675,
				"close": 28.1625,
				"volume": 235746744,
				"time": 1418878800000,
				"lowest": 27.665,
				"highest": 28.1625,
				"volumePrice": 27.9244,
				"transactionCount": 318147,
				"voluemPrice": 27.9244
			},
			{
				"open": 28.065,
				"close": 27.945,
				"volume": 353719080,
				"time": 1418965200000,
				"lowest": 27.915,
				"highest": 28.31,
				"volumePrice": 28.0231,
				"transactionCount": 282484,
				"voluemPrice": 28.0231
			},
			{
				"open": 28.04,
				"close": 28.235,
				"volume": 180669772,
				"time": 1419224400000,
				"lowest": 27.9925,
				"highest": 28.3725,
				"volumePrice": 28.2047,
				"transactionCount": 206314,
				"voluemPrice": 28.2047
			},
			{
				"open": 28.3075,
				"close": 28.135,
				"volume": 104113676,
				"time": 1419310800000,
				"lowest": 28.115,
				"highest": 28.3325,
				"volumePrice": 28.1922,
				"transactionCount": 140273,
				"voluemPrice": 28.1922
			},
			{
				"open": 28.145,
				"close": 28.0025,
				"volume": 57918444,
				"time": 1419397200000,
				"lowest": 28.0025,
				"highest": 28.1775,
				"volumePrice": 28.0448,
				"transactionCount": 61253,
				"voluemPrice": 28.0448
			},
			{
				"open": 28.025,
				"close": 28.4975,
				"volume": 134882404,
				"time": 1419570000000,
				"lowest": 28.0025,
				"highest": 28.63,
				"volumePrice": 28.3941,
				"transactionCount": 157684,
				"voluemPrice": 28.3941
			},
			{
				"open": 28.4475,
				"close": 28.4775,
				"volume": 110395680,
				"time": 1419829200000,
				"lowest": 28.425,
				"highest": 28.6925,
				"volumePrice": 28.558,
				"transactionCount": 145126,
				"voluemPrice": 28.558
			},
			{
				"open": 28.41,
				"close": 28.13,
				"volume": 119525908,
				"time": 1419915600000,
				"lowest": 28.0275,
				"highest": 28.48,
				"volumePrice": 28.2152,
				"transactionCount": 154551,
				"voluemPrice": 28.2152
			},
			{
				"open": 28.205,
				"close": 27.595,
				"volume": 165611996,
				"time": 1420002000000,
				"lowest": 27.5525,
				"highest": 28.2825,
				"volumePrice": 27.8396,
				"transactionCount": 199310,
				"voluemPrice": 27.8396
			},
			{
				"open": 27.8475,
				"close": 27.3325,
				"volume": 212818304,
				"time": 1420174800000,
				"lowest": 26.8375,
				"highest": 27.86,
				"volumePrice": 27.283,
				"transactionCount": 287734,
				"voluemPrice": 27.283
			},
			{
				"open": 27.0725,
				"close": 26.5625,
				"volume": 257139548,
				"time": 1420434000000,
				"lowest": 26.3525,
				"highest": 27.1625,
				"volumePrice": 26.6954,
				"transactionCount": 318488,
				"voluemPrice": 26.6954
			},
			{
				"open": 26.635,
				"close": 26.565,
				"volume": 263077504,
				"time": 1420520400000,
				"lowest": 26.1575,
				"highest": 26.8575,
				"volumePrice": 26.5291,
				"transactionCount": 364766,
				"voluemPrice": 26.5291
			},
			{
				"open": 26.8,
				"close": 26.9375,
				"volume": 160423736,
				"time": 1420606800000,
				"lowest": 26.6738,
				"highest": 27.05,
				"volumePrice": 26.9124,
				"transactionCount": 222954,
				"voluemPrice": 26.9124
			},
			{
				"open": 27.3075,
				"close": 27.9725,
				"volume": 237458188,
				"time": 1420693200000,
				"lowest": 27.175,
				"highest": 28.0375,
				"volumePrice": 27.763,
				"transactionCount": 305171,
				"voluemPrice": 27.763
			},
			{
				"open": 28.1675,
				"close": 28.0025,
				"volume": 214798108,
				"time": 1420779600000,
				"lowest": 27.5525,
				"highest": 28.3125,
				"volumePrice": 27.955,
				"transactionCount": 294203,
				"voluemPrice": 27.955
			},
			{
				"open": 28.15,
				"close": 27.3125,
				"volume": 198599200,
				"time": 1421038800000,
				"lowest": 27.2,
				"highest": 28.1575,
				"volumePrice": 27.4835,
				"transactionCount": 281093,
				"voluemPrice": 27.4835
			},
			{
				"open": 27.8575,
				"close": 27.555,
				"volume": 268363124,
				"time": 1421125200000,
				"lowest": 27.2275,
				"highest": 28.2,
				"volumePrice": 27.7718,
				"transactionCount": 370469,
				"voluemPrice": 27.7718
			},
			{
				"open": 27.26,
				"close": 27.45,
				"volume": 195826200,
				"time": 1421211600000,
				"lowest": 27.125,
				"highest": 27.6225,
				"volumePrice": 27.3653,
				"transactionCount": 267406,
				"voluemPrice": 27.3653
			},
			{
				"open": 27.5,
				"close": 26.705,
				"volume": 239863984,
				"time": 1421298000000,
				"lowest": 26.665,
				"highest": 27.515,
				"volumePrice": 26.9197,
				"transactionCount": 313444,
				"voluemPrice": 26.9197
			},
			{
				"open": 26.7575,
				"close": 26.4975,
				"volume": 314017200,
				"time": 1421384400000,
				"lowest": 26.3,
				"highest": 26.895,
				"volumePrice": 26.5258,
				"transactionCount": 345206,
				"voluemPrice": 26.5258
			},
			{
				"open": 26.96,
				"close": 27.18,
				"volume": 199573736,
				"time": 1421730000000,
				"lowest": 26.625,
				"highest": 27.195,
				"volumePrice": 26.9486,
				"transactionCount": 241930,
				"voluemPrice": 26.9486
			},
			{
				"open": 27.2375,
				"close": 27.3875,
				"volume": 194303588,
				"time": 1421816400000,
				"lowest": 27.0675,
				"highest": 27.765,
				"volumePrice": 27.4588,
				"transactionCount": 265510,
				"voluemPrice": 27.4588
			},
			{
				"open": 27.565,
				"close": 28.1,
				"volume": 215185636,
				"time": 1421902800000,
				"lowest": 27.43,
				"highest": 28.1175,
				"volumePrice": 27.8581,
				"transactionCount": 257510,
				"voluemPrice": 27.8581
			},
			{
				"open": 28.075,
				"close": 28.245,
				"volume": 185859312,
				"time": 1421989200000,
				"lowest": 27.8825,
				"highest": 28.4375,
				"volumePrice": 28.187,
				"transactionCount": 230297,
				"voluemPrice": 28.187
			},
			{
				"open": 28.435,
				"close": 28.275,
				"volume": 222459916,
				"time": 1422248400000,
				"lowest": 28.2,
				"highest": 28.5907,
				"volumePrice": 28.3717,
				"transactionCount": 255669,
				"voluemPrice": 28.3717
			},
			{
				"open": 28.105,
				"close": 27.285,
				"volume": 382257796,
				"time": 1422334800000,
				"lowest": 27.2575,
				"highest": 28.12,
				"volumePrice": 27.8568,
				"transactionCount": 477620,
				"voluemPrice": 27.8568
			},
			{
				"open": 29.4063,
				"close": 28.8275,
				"volume": 580708252,
				"time": 1422421200000,
				"lowest": 28.8275,
				"highest": 29.53,
				"volumePrice": 29.2478,
				"transactionCount": 737542,
				"voluemPrice": 29.2478
			},
			{
				"open": 29.08,
				"close": 29.725,
				"volume": 337727728,
				"time": 1422507600000,
				"lowest": 28.89,
				"highest": 29.7975,
				"volumePrice": 29.4023,
				"transactionCount": 484461,
				"voluemPrice": 29.4023
			},
			{
				"open": 29.6,
				"close": 29.29,
				"volume": 334754560,
				"time": 1422594000000,
				"lowest": 29.2125,
				"highest": 30,
				"volumePrice": 29.6907,
				"transactionCount": 412395,
				"voluemPrice": 29.6907
			},
			{
				"open": 29.5125,
				"close": 29.6575,
				"volume": 250956400,
				"time": 1422853200000,
				"lowest": 29.02,
				"highest": 29.7925,
				"volumePrice": 29.3979,
				"transactionCount": 324684,
				"voluemPrice": 29.3979
			},
			{
				"open": 29.625,
				"close": 29.6625,
				"volume": 207662996,
				"time": 1422939600000,
				"lowest": 29.4025,
				"highest": 29.7725,
				"volumePrice": 29.6057,
				"transactionCount": 249274,
				"voluemPrice": 29.6057
			},
			{
				"open": 29.625,
				"close": 29.89,
				"volume": 280598972,
				"time": 1423026000000,
				"lowest": 29.5773,
				"highest": 30.1275,
				"volumePrice": 29.9432,
				"transactionCount": 323515,
				"voluemPrice": 29.9432
			},
			{
				"open": 30.005,
				"close": 29.985,
				"volume": 168985532,
				"time": 1423112400000,
				"lowest": 29.8125,
				"highest": 30.0575,
				"volumePrice": 29.951,
				"transactionCount": 210401,
				"voluemPrice": 29.951
			},
			{
				"open": 30.005,
				"close": 29.7325,
				"volume": 174826268,
				"time": 1423198800000,
				"lowest": 29.6125,
				"highest": 30.0625,
				"volumePrice": 29.8783,
				"transactionCount": 234211,
				"voluemPrice": 29.8783
			},
			{
				"open": 29.6375,
				"close": 29.93,
				"volume": 155559188,
				"time": 1423458000000,
				"lowest": 29.6075,
				"highest": 29.96,
				"volumePrice": 29.8369,
				"transactionCount": 204013,
				"voluemPrice": 29.8369
			},
			{
				"open": 30.0425,
				"close": 30.505,
				"volume": 248031008,
				"time": 1423544400000,
				"lowest": 30.04,
				"highest": 30.5375,
				"volumePrice": 30.3448,
				"transactionCount": 311996,
				"voluemPrice": 30.3448
			},
			{
				"open": 30.6925,
				"close": 31.22,
				"volume": 289800548,
				"time": 1423630800000,
				"lowest": 30.625,
				"highest": 31.23,
				"volumePrice": 31.0185,
				"transactionCount": 368414,
				"voluemPrice": 31.0185
			},
			{
				"open": 31.515,
				"close": 31.615,
				"volume": 297896972,
				"time": 1423717200000,
				"lowest": 31.3925,
				"highest": 31.87,
				"volumePrice": 31.6428,
				"transactionCount": 395981,
				"voluemPrice": 31.6428
			},
			{
				"open": 31.82,
				"close": 31.77,
				"volume": 217086876,
				"time": 1423803600000,
				"lowest": 31.4125,
				"highest": 31.82,
				"volumePrice": 31.6216,
				"transactionCount": 276895,
				"voluemPrice": 31.6216
			},
			{
				"open": 31.8725,
				"close": 31.9575,
				"volume": 252516820,
				"time": 1424149200000,
				"lowest": 31.73,
				"highest": 32.22,
				"volumePrice": 31.9788,
				"transactionCount": 310744,
				"voluemPrice": 31.9788
			},
			{
				"open": 31.9063,
				"close": 32.1788,
				"volume": 179558052,
				"time": 1424235600000,
				"lowest": 31.8625,
				"highest": 32.195,
				"volumePrice": 32.0264,
				"transactionCount": 226461,
				"voluemPrice": 32.0264
			},
			{
				"open": 32.12,
				"close": 32.1125,
				"volume": 149449524,
				"time": 1424322000000,
				"lowest": 32.0825,
				"highest": 32.2575,
				"volumePrice": 32.169,
				"transactionCount": 199195,
				"voluemPrice": 32.169
			},
			{
				"open": 32.155,
				"close": 32.3738,
				"volume": 195793676,
				"time": 1424408400000,
				"lowest": 32.0125,
				"highest": 32.375,
				"volumePrice": 32.2149,
				"transactionCount": 236741,
				"voluemPrice": 32.2149
			},
			{
				"open": 32.505,
				"close": 33.25,
				"volume": 283896440,
				"time": 1424667600000,
				"lowest": 32.415,
				"highest": 33.25,
				"volumePrice": 32.9193,
				"transactionCount": 340905,
				"voluemPrice": 32.9193
			},
			{
				"open": 33.235,
				"close": 33.0425,
				"volume": 276900900,
				"time": 1424754000000,
				"lowest": 32.7925,
				"highest": 33.4,
				"volumePrice": 33.1255,
				"transactionCount": 359622,
				"voluemPrice": 33.1255
			},
			{
				"open": 32.89,
				"close": 32.1975,
				"volume": 298838192,
				"time": 1424840400000,
				"lowest": 32.0375,
				"highest": 32.9,
				"volumePrice": 32.49,
				"transactionCount": 398908,
				"voluemPrice": 32.49
			},
			{
				"open": 32.1963,
				"close": 32.6038,
				"volume": 365150116,
				"time": 1424926800000,
				"lowest": 31.6525,
				"highest": 32.7175,
				"volumePrice": 32.2584,
				"transactionCount": 445318,
				"voluemPrice": 32.2584
			},
			{
				"open": 32.5,
				"close": 32.115,
				"volume": 248051388,
				"time": 1425013200000,
				"lowest": 32.06,
				"highest": 32.6425,
				"volumePrice": 32.3212,
				"transactionCount": 291971,
				"voluemPrice": 32.3212
			},
			{
				"open": 32.3125,
				"close": 32.2725,
				"volume": 192386652,
				"time": 1425272400000,
				"lowest": 32.075,
				"highest": 32.57,
				"volumePrice": 32.2999,
				"transactionCount": 228204,
				"voluemPrice": 32.2999
			},
			{
				"open": 32.24,
				"close": 32.34,
				"volume": 151261112,
				"time": 1425358800000,
				"lowest": 32.0225,
				"highest": 32.38,
				"volumePrice": 32.2291,
				"transactionCount": 192706,
				"voluemPrice": 32.2291
			},
			{
				"open": 32.275,
				"close": 32.135,
				"volume": 126665360,
				"time": 1425445200000,
				"lowest": 32.08,
				"highest": 32.39,
				"volumePrice": 32.2013,
				"transactionCount": 164085,
				"voluemPrice": 32.2013
			},
			{
				"open": 32.145,
				"close": 31.6025,
				"volume": 226068196,
				"time": 1425531600000,
				"lowest": 31.44,
				"highest": 32.1875,
				"volumePrice": 31.7272,
				"transactionCount": 295277,
				"voluemPrice": 31.7272
			},
			{
				"open": 32.1,
				"close": 31.65,
				"volume": 291368120,
				"time": 1425618000000,
				"lowest": 31.565,
				"highest": 32.3425,
				"volumePrice": 31.9437,
				"transactionCount": 378539,
				"voluemPrice": 31.9437
			},
			{
				"open": 31.99,
				"close": 31.785,
				"volume": 354096672,
				"time": 1425873600000,
				"lowest": 31.265,
				"highest": 32.3925,
				"volumePrice": 31.8887,
				"transactionCount": 478757,
				"voluemPrice": 31.8887
			},
			{
				"open": 31.6025,
				"close": 31.1275,
				"volume": 275338928,
				"time": 1425960000000,
				"lowest": 30.95,
				"highest": 31.805,
				"volumePrice": 31.2691,
				"transactionCount": 364047,
				"voluemPrice": 31.2691
			},
			{
				"open": 31.1875,
				"close": 30.56,
				"volume": 275578544,
				"time": 1426046400000,
				"lowest": 30.5275,
				"highest": 31.1925,
				"volumePrice": 30.7556,
				"transactionCount": 365369,
				"voluemPrice": 30.7556
			},
			{
				"open": 30.5775,
				"close": 31.1125,
				"volume": 193446232,
				"time": 1426132800000,
				"lowest": 30.4075,
				"highest": 31.225,
				"volumePrice": 30.9799,
				"transactionCount": 252352,
				"voluemPrice": 30.9799
			},
			{
				"open": 31.1,
				"close": 30.8975,
				"volume": 207307132,
				"time": 1426219200000,
				"lowest": 30.645,
				"highest": 31.3488,
				"volumePrice": 30.937,
				"transactionCount": 279472,
				"voluemPrice": 30.937
			},
			{
				"open": 30.97,
				"close": 31.2375,
				"volume": 143497200,
				"time": 1426478400000,
				"lowest": 30.7175,
				"highest": 31.2375,
				"volumePrice": 31.0623,
				"transactionCount": 196271,
				"voluemPrice": 31.0623
			},
			{
				"open": 31.475,
				"close": 31.76,
				"volume": 204092416,
				"time": 1426564800000,
				"lowest": 31.4125,
				"highest": 31.83,
				"volumePrice": 31.6846,
				"transactionCount": 259804,
				"voluemPrice": 31.6846
			},
			{
				"open": 31.75,
				"close": 32.1175,
				"volume": 261083380,
				"time": 1426651200000,
				"lowest": 31.5925,
				"highest": 32.29,
				"volumePrice": 31.9773,
				"transactionCount": 302285,
				"voluemPrice": 31.9773
			},
			{
				"open": 32.1875,
				"close": 31.8738,
				"volume": 183237960,
				"time": 1426737600000,
				"lowest": 31.85,
				"highest": 32.3113,
				"volumePrice": 32.0232,
				"transactionCount": 228971,
				"voluemPrice": 32.0232
			},
			{
				"open": 32.0625,
				"close": 31.475,
				"volume": 274779744,
				"time": 1426824000000,
				"lowest": 31.29,
				"highest": 32.1,
				"volumePrice": 31.8107,
				"transactionCount": 223281,
				"voluemPrice": 31.8107
			},
			{
				"open": 31.78,
				"close": 31.8025,
				"volume": 150182868,
				"time": 1427083200000,
				"lowest": 31.63,
				"highest": 31.9625,
				"volumePrice": 31.8045,
				"transactionCount": 191462,
				"voluemPrice": 31.8045
			},
			{
				"open": 31.8075,
				"close": 31.6725,
				"volume": 131049216,
				"time": 1427169600000,
				"lowest": 31.64,
				"highest": 32.01,
				"volumePrice": 31.8086,
				"transactionCount": 179351,
				"voluemPrice": 31.8086
			},
			{
				"open": 31.635,
				"close": 30.845,
				"volume": 206620708,
				"time": 1427256000000,
				"lowest": 30.845,
				"highest": 31.705,
				"volumePrice": 31.2089,
				"transactionCount": 295184,
				"voluemPrice": 31.2089
			},
			{
				"open": 30.69,
				"close": 31.06,
				"volume": 190291476,
				"time": 1427342400000,
				"lowest": 30.65,
				"highest": 31.22,
				"volumePrice": 31.0089,
				"transactionCount": 268714,
				"voluemPrice": 31.0089
			},
			{
				"open": 31.1425,
				"close": 30.8125,
				"volume": 158184604,
				"time": 1427428800000,
				"lowest": 30.7275,
				"highest": 31.175,
				"volumePrice": 30.904,
				"transactionCount": 218111,
				"voluemPrice": 30.904
			},
			{
				"open": 31.0125,
				"close": 31.5925,
				"volume": 188395440,
				"time": 1427688000000,
				"lowest": 31,
				"highest": 31.6,
				"volumePrice": 31.3717,
				"transactionCount": 235559,
				"voluemPrice": 31.3717
			},
			{
				"open": 31.5225,
				"close": 31.1075,
				"volume": 168362212,
				"time": 1427774400000,
				"lowest": 31.0888,
				"highest": 31.6225,
				"volumePrice": 31.2922,
				"transactionCount": 204728,
				"voluemPrice": 31.2922
			},
			{
				"open": 31.205,
				"close": 31.0625,
				"volume": 162327348,
				"time": 1427860800000,
				"lowest": 30.775,
				"highest": 31.28,
				"volumePrice": 31.0051,
				"transactionCount": 228394,
				"voluemPrice": 31.0051
			},
			{
				"open": 31.2575,
				"close": 31.33,
				"volume": 128773304,
				"time": 1427947200000,
				"lowest": 31.0475,
				"highest": 31.39,
				"volumePrice": 31.2176,
				"transactionCount": 174504,
				"voluemPrice": 31.2176
			},
			{
				"open": 31.1175,
				"close": 31.8375,
				"volume": 148766700,
				"time": 1428292800000,
				"lowest": 31.0825,
				"highest": 31.8775,
				"volumePrice": 31.6855,
				"transactionCount": 197219,
				"voluemPrice": 31.6855
			},
			{
				"open": 31.91,
				"close": 31.5025,
				"volume": 140049072,
				"time": 1428379200000,
				"lowest": 31.495,
				"highest": 32.0305,
				"volumePrice": 31.749,
				"transactionCount": 188289,
				"voluemPrice": 31.749
			},
			{
				"open": 31.4625,
				"close": 31.4,
				"volume": 149316972,
				"time": 1428465600000,
				"lowest": 31.2425,
				"highest": 31.6,
				"volumePrice": 31.4025,
				"transactionCount": 204875,
				"voluemPrice": 31.4025
			},
			{
				"open": 31.4625,
				"close": 31.64,
				"volume": 129926296,
				"time": 1428552000000,
				"lowest": 31.165,
				"highest": 31.645,
				"volumePrice": 31.4199,
				"transactionCount": 172286,
				"voluemPrice": 31.4199
			},
			{
				"open": 31.4875,
				"close": 31.775,
				"volume": 160724612,
				"time": 1428638400000,
				"lowest": 31.315,
				"highest": 31.8025,
				"volumePrice": 31.6333,
				"transactionCount": 216624,
				"voluemPrice": 31.6333
			},
			{
				"open": 32.0925,
				"close": 31.7125,
				"volume": 145460072,
				"time": 1428897600000,
				"lowest": 31.6525,
				"highest": 32.1425,
				"volumePrice": 31.8776,
				"transactionCount": 196422,
				"voluemPrice": 31.8776
			},
			{
				"open": 31.75,
				"close": 31.575,
				"volume": 102098352,
				"time": 1428984000000,
				"lowest": 31.4775,
				"highest": 31.8225,
				"volumePrice": 31.6053,
				"transactionCount": 148628,
				"voluemPrice": 31.6053
			},
			{
				"open": 31.6025,
				"close": 31.695,
				"volume": 115881676,
				"time": 1429070400000,
				"lowest": 31.5025,
				"highest": 31.7825,
				"volumePrice": 31.6311,
				"transactionCount": 157321,
				"voluemPrice": 31.6311
			},
			{
				"open": 31.57,
				"close": 31.5425,
				"volume": 113475948,
				"time": 1429156800000,
				"lowest": 31.5275,
				"highest": 31.775,
				"volumePrice": 31.6237,
				"transactionCount": 155440,
				"voluemPrice": 31.6237
			},
			{
				"open": 31.3875,
				"close": 31.1875,
				"volume": 207816984,
				"time": 1429243200000,
				"lowest": 31.115,
				"highest": 31.535,
				"volumePrice": 31.2783,
				"transactionCount": 259640,
				"voluemPrice": 31.2783
			},
			{
				"open": 31.3925,
				"close": 31.9,
				"volume": 188215636,
				"time": 1429502400000,
				"lowest": 31.2925,
				"highest": 32.03,
				"volumePrice": 31.7892,
				"transactionCount": 228046,
				"voluemPrice": 31.7892
			},
			{
				"open": 32.025,
				"close": 31.7275,
				"volume": 129740228,
				"time": 1429588800000,
				"lowest": 31.6675,
				"highest": 32.05,
				"volumePrice": 31.789,
				"transactionCount": 169220,
				"voluemPrice": 31.789
			},
			{
				"open": 31.7475,
				"close": 32.155,
				"volume": 150420420,
				"time": 1429675200000,
				"lowest": 31.58,
				"highest": 32.2175,
				"volumePrice": 31.9222,
				"transactionCount": 184084,
				"voluemPrice": 31.9222
			},
			{
				"open": 32.075,
				"close": 32.4175,
				"volume": 182994608,
				"time": 1429761600000,
				"lowest": 32.035,
				"highest": 32.605,
				"volumePrice": 32.3857,
				"transactionCount": 226653,
				"voluemPrice": 32.3857
			},
			{
				"open": 32.6225,
				"close": 32.57,
				"volume": 177898620,
				"time": 1429848000000,
				"lowest": 32.3075,
				"highest": 32.6575,
				"volumePrice": 32.4963,
				"transactionCount": 219740,
				"voluemPrice": 32.4963
			},
			{
				"open": 33.0775,
				"close": 33.1625,
				"volume": 387756828,
				"time": 1430107200000,
				"lowest": 32.7875,
				"highest": 33.2825,
				"volumePrice": 33.1602,
				"transactionCount": 451266,
				"voluemPrice": 33.1602
			},
			{
				"open": 33.6138,
				"close": 32.64,
				"volume": 475691036,
				"time": 1430193600000,
				"lowest": 32.3925,
				"highest": 33.635,
				"volumePrice": 32.9308,
				"transactionCount": 628806,
				"voluemPrice": 32.9308
			},
			{
				"open": 32.54,
				"close": 32.16,
				"volume": 253544332,
				"time": 1430280000000,
				"lowest": 32.075,
				"highest": 32.8975,
				"volumePrice": 32.3533,
				"transactionCount": 307217,
				"voluemPrice": 32.3533
			},
			{
				"open": 31.875,
				"close": 31.2875,
				"volume": 325405328,
				"time": 1430366400000,
				"lowest": 31.145,
				"highest": 31.97,
				"volumePrice": 31.4748,
				"transactionCount": 420666,
				"voluemPrice": 31.4748
			},
			{
				"open": 31.525,
				"close": 32.2375,
				"volume": 234046512,
				"time": 1430452800000,
				"lowest": 31.325,
				"highest": 32.5325,
				"volumePrice": 31.797,
				"transactionCount": 285560,
				"voluemPrice": 31.797
			},
			{
				"open": 32.375,
				"close": 32.175,
				"volume": 203953084,
				"time": 1430712000000,
				"lowest": 32.065,
				"highest": 32.6425,
				"volumePrice": 32.3382,
				"transactionCount": 246130,
				"voluemPrice": 32.3382
			},
			{
				"open": 32.0375,
				"close": 31.45,
				"volume": 197085664,
				"time": 1430798400000,
				"lowest": 31.445,
				"highest": 32.1125,
				"volumePrice": 31.6465,
				"transactionCount": 248948,
				"voluemPrice": 31.6465
			},
			{
				"open": 31.64,
				"close": 31.2525,
				"volume": 288563960,
				"time": 1430884800000,
				"lowest": 30.84,
				"highest": 31.6875,
				"volumePrice": 31.1441,
				"transactionCount": 385440,
				"voluemPrice": 31.1441
			},
			{
				"open": 31.1925,
				"close": 31.315,
				"volume": 175763580,
				"time": 1430971200000,
				"lowest": 31.005,
				"highest": 31.52,
				"volumePrice": 31.324,
				"transactionCount": 215145,
				"voluemPrice": 31.324
			},
			{
				"open": 31.67,
				"close": 31.905,
				"volume": 222201528,
				"time": 1431057600000,
				"lowest": 31.5275,
				"highest": 31.905,
				"volumePrice": 31.7858,
				"transactionCount": 222357,
				"voluemPrice": 31.7858
			},
			{
				"open": 31.8475,
				"close": 31.58,
				"volume": 166861840,
				"time": 1431316800000,
				"lowest": 31.4063,
				"highest": 31.89,
				"volumePrice": 31.6104,
				"transactionCount": 194432,
				"voluemPrice": 31.6104
			},
			{
				"open": 31.4,
				"close": 31.4663,
				"volume": 192640128,
				"time": 1431403200000,
				"lowest": 31.205,
				"highest": 31.72,
				"volumePrice": 31.4683,
				"transactionCount": 221667,
				"voluemPrice": 31.4683
			},
			{
				"open": 31.5375,
				"close": 31.5025,
				"volume": 138776940,
				"time": 1431489600000,
				"lowest": 31.4675,
				"highest": 31.7975,
				"volumePrice": 31.6226,
				"transactionCount": 165880,
				"voluemPrice": 31.6226
			},
			{
				"open": 31.8525,
				"close": 32.2375,
				"volume": 180813824,
				"time": 1431576000000,
				"lowest": 31.79,
				"highest": 32.2375,
				"volumePrice": 32.0576,
				"transactionCount": 240199,
				"voluemPrice": 32.0576
			},
			{
				"open": 32.2675,
				"close": 32.1925,
				"volume": 152832136,
				"time": 1431662400000,
				"lowest": 32.0525,
				"highest": 32.3725,
				"volumePrice": 32.1723,
				"transactionCount": 186018,
				"voluemPrice": 32.1723
			},
			{
				"open": 32.095,
				"close": 32.5475,
				"volume": 203531672,
				"time": 1431921600000,
				"lowest": 32.09,
				"highest": 32.68,
				"volumePrice": 32.4842,
				"transactionCount": 237045,
				"voluemPrice": 32.4842
			},
			{
				"open": 32.6725,
				"close": 32.5175,
				"volume": 178532960,
				"time": 1432008000000,
				"lowest": 32.41,
				"highest": 32.72,
				"volumePrice": 32.5679,
				"transactionCount": 200222,
				"voluemPrice": 32.5679
			},
			{
				"open": 32.5,
				"close": 32.515,
				"volume": 145819728,
				"time": 1432094400000,
				"lowest": 32.335,
				"highest": 32.745,
				"volumePrice": 32.5342,
				"transactionCount": 167022,
				"voluemPrice": 32.5342
			},
			{
				"open": 32.5175,
				"close": 32.8475,
				"volume": 158921456,
				"time": 1432180800000,
				"lowest": 32.4575,
				"highest": 32.9075,
				"volumePrice": 32.77,
				"transactionCount": 180824,
				"voluemPrice": 32.77
			},
			{
				"open": 32.9,
				"close": 33.135,
				"volume": 182383888,
				"time": 1432267200000,
				"lowest": 32.85,
				"highest": 33.2425,
				"volumePrice": 33.1286,
				"transactionCount": 216176,
				"voluemPrice": 33.1286
			},
			{
				"open": 33.15,
				"close": 32.405,
				"volume": 282789440,
				"time": 1432612800000,
				"lowest": 32.28,
				"highest": 33.2275,
				"volumePrice": 32.5921,
				"transactionCount": 308139,
				"voluemPrice": 32.5921
			},
			{
				"open": 32.585,
				"close": 33.0112,
				"volume": 183331544,
				"time": 1432699200000,
				"lowest": 32.5125,
				"highest": 33.065,
				"volumePrice": 32.8912,
				"transactionCount": 237680,
				"voluemPrice": 32.8912
			},
			{
				"open": 32.965,
				"close": 32.945,
				"volume": 122932560,
				"time": 1432785600000,
				"lowest": 32.775,
				"highest": 32.9875,
				"volumePrice": 32.8961,
				"transactionCount": 149276,
				"voluemPrice": 32.8961
			},
			{
				"open": 32.8075,
				"close": 32.57,
				"volume": 203537808,
				"time": 1432872000000,
				"lowest": 32.475,
				"highest": 32.8625,
				"volumePrice": 32.6581,
				"transactionCount": 211178,
				"voluemPrice": 32.6581
			},
			{
				"open": 32.8,
				"close": 32.6338,
				"volume": 125339868,
				"time": 1433131200000,
				"lowest": 32.5125,
				"highest": 32.8475,
				"volumePrice": 32.6596,
				"transactionCount": 161836,
				"voluemPrice": 32.6596
			},
			{
				"open": 32.465,
				"close": 32.49,
				"volume": 134670508,
				"time": 1433217600000,
				"lowest": 32.33,
				"highest": 32.6638,
				"volumePrice": 32.5094,
				"transactionCount": 168400,
				"voluemPrice": 32.5094
			},
			{
				"open": 32.665,
				"close": 32.53,
				"volume": 123934168,
				"time": 1433304000000,
				"lowest": 32.475,
				"highest": 32.735,
				"volumePrice": 32.5938,
				"transactionCount": 152503,
				"voluemPrice": 32.5938
			},
			{
				"open": 32.395,
				"close": 32.34,
				"volume": 153800472,
				"time": 1433390400000,
				"lowest": 32.2275,
				"highest": 32.645,
				"volumePrice": 32.4026,
				"transactionCount": 203435,
				"voluemPrice": 32.4026
			},
			{
				"open": 32.375,
				"close": 32.1625,
				"volume": 142507200,
				"time": 1433476800000,
				"lowest": 32.09,
				"highest": 32.4225,
				"volumePrice": 32.2138,
				"transactionCount": 187929,
				"voluemPrice": 32.2138
			},
			{
				"open": 32.225,
				"close": 31.95,
				"volume": 210699144,
				"time": 1433736000000,
				"lowest": 31.7075,
				"highest": 32.3025,
				"volumePrice": 32.0099,
				"transactionCount": 263476,
				"voluemPrice": 32.0099
			},
			{
				"open": 31.675,
				"close": 31.855,
				"volume": 224301680,
				"time": 1433822400000,
				"lowest": 31.405,
				"highest": 32.02,
				"volumePrice": 31.7154,
				"transactionCount": 281559,
				"voluemPrice": 31.7154
			},
			{
				"open": 31.98,
				"close": 32.22,
				"volume": 156349000,
				"time": 1433908800000,
				"lowest": 31.9625,
				"highest": 32.335,
				"volumePrice": 32.2074,
				"transactionCount": 196305,
				"voluemPrice": 32.2074
			},
			{
				"open": 32.295,
				"close": 32.1475,
				"volume": 141526348,
				"time": 1433995200000,
				"lowest": 32.1188,
				"highest": 32.545,
				"volumePrice": 32.3042,
				"transactionCount": 160260,
				"voluemPrice": 32.3042
			},
			{
				"open": 32.0463,
				"close": 31.7925,
				"volume": 147440784,
				"time": 1434081600000,
				"lowest": 31.7775,
				"highest": 32.0825,
				"volumePrice": 31.903,
				"transactionCount": 173296,
				"voluemPrice": 31.903
			},
			{
				"open": 31.525,
				"close": 31.73,
				"volume": 175955784,
				"time": 1434340800000,
				"lowest": 31.4275,
				"highest": 31.81,
				"volumePrice": 31.6569,
				"transactionCount": 194305,
				"voluemPrice": 31.6569
			},
			{
				"open": 31.7575,
				"close": 31.9,
				"volume": 125976524,
				"time": 1434427200000,
				"lowest": 31.5925,
				"highest": 31.9625,
				"volumePrice": 31.7986,
				"transactionCount": 157128,
				"voluemPrice": 31.7986
			},
			{
				"open": 31.93,
				"close": 31.825,
				"volume": 131672284,
				"time": 1434513600000,
				"lowest": 31.685,
				"highest": 31.97,
				"volumePrice": 31.8282,
				"transactionCount": 173868,
				"voluemPrice": 31.8282
			},
			{
				"open": 31.8075,
				"close": 31.97,
				"volume": 141628880,
				"time": 1434600000000,
				"lowest": 31.805,
				"highest": 32.0775,
				"volumePrice": 31.9945,
				"transactionCount": 189897,
				"voluemPrice": 31.9945
			},
			{
				"open": 31.9275,
				"close": 31.65,
				"volume": 218867148,
				"time": 1434686400000,
				"lowest": 31.6,
				"highest": 31.955,
				"volumePrice": 31.7574,
				"transactionCount": 185142,
				"voluemPrice": 31.7574
			},
			{
				"open": 31.8725,
				"close": 31.9025,
				"volume": 136157380,
				"time": 1434945600000,
				"lowest": 31.77,
				"highest": 32.015,
				"volumePrice": 31.8906,
				"transactionCount": 154971,
				"voluemPrice": 31.8906
			},
			{
				"open": 31.87,
				"close": 31.7575,
				"volume": 121074632,
				"time": 1435032000000,
				"lowest": 31.7198,
				"highest": 31.9025,
				"volumePrice": 31.7966,
				"transactionCount": 144768,
				"voluemPrice": 31.7966
			},
			{
				"open": 31.8025,
				"close": 32.0275,
				"volume": 221122220,
				"time": 1435118400000,
				"lowest": 31.78,
				"highest": 32.45,
				"volumePrice": 32.1827,
				"transactionCount": 266459,
				"voluemPrice": 32.1827
			},
			{
				"open": 32.215,
				"close": 31.875,
				"volume": 127752400,
				"time": 1435204800000,
				"lowest": 31.875,
				"highest": 32.3,
				"volumePrice": 32.0557,
				"transactionCount": 157511,
				"voluemPrice": 32.0557
			},
			{
				"open": 31.9175,
				"close": 31.6875,
				"volume": 176179364,
				"time": 1435291200000,
				"lowest": 31.6275,
				"highest": 31.9975,
				"volumePrice": 31.7413,
				"transactionCount": 176856,
				"voluemPrice": 31.7413
			},
			{
				"open": 31.365,
				"close": 31.1325,
				"volume": 196642908,
				"time": 1435550400000,
				"lowest": 31.12,
				"highest": 31.6175,
				"volumePrice": 31.3424,
				"transactionCount": 257416,
				"voluemPrice": 31.3424
			},
			{
				"open": 31.3925,
				"close": 31.3563,
				"volume": 177482728,
				"time": 1435636800000,
				"lowest": 31.215,
				"highest": 31.53,
				"volumePrice": 31.3756,
				"transactionCount": 226506,
				"voluemPrice": 31.3756
			},
			{
				"open": 31.725,
				"close": 31.65,
				"volume": 120955244,
				"time": 1435723200000,
				"lowest": 31.4975,
				"highest": 31.735,
				"volumePrice": 31.6144,
				"transactionCount": 165318,
				"voluemPrice": 31.6144
			},
			{
				"open": 31.6075,
				"close": 31.61,
				"volume": 108843808,
				"time": 1435809600000,
				"lowest": 31.4425,
				"highest": 31.6725,
				"volumePrice": 31.5538,
				"transactionCount": 135864,
				"voluemPrice": 31.5538
			},
			{
				"open": 31.235,
				"close": 31.5,
				"volume": 112202716,
				"time": 1436155200000,
				"lowest": 31.2125,
				"highest": 31.5575,
				"volumePrice": 31.414,
				"transactionCount": 151371,
				"voluemPrice": 31.414
			},
			{
				"open": 31.4725,
				"close": 31.4225,
				"volume": 187787244,
				"time": 1436241600000,
				"lowest": 30.9425,
				"highest": 31.5375,
				"volumePrice": 31.2502,
				"transactionCount": 248488,
				"voluemPrice": 31.2502
			},
			{
				"open": 31.12,
				"close": 30.6425,
				"volume": 243046456,
				"time": 1436328000000,
				"lowest": 30.635,
				"highest": 31.16,
				"volumePrice": 30.8647,
				"transactionCount": 331930,
				"voluemPrice": 30.8647
			},
			{
				"open": 30.9625,
				"close": 30.0175,
				"volume": 314379292,
				"time": 1436414400000,
				"lowest": 29.805,
				"highest": 31.015,
				"volumePrice": 30.4088,
				"transactionCount": 413588,
				"voluemPrice": 30.4088
			},
			{
				"open": 30.485,
				"close": 30.82,
				"volume": 245417896,
				"time": 1436500800000,
				"lowest": 30.3025,
				"highest": 30.9625,
				"volumePrice": 30.7048,
				"transactionCount": 297282,
				"voluemPrice": 30.7048
			},
			{
				"open": 31.2575,
				"close": 31.415,
				"volume": 165762152,
				"time": 1436760000000,
				"lowest": 31.08,
				"highest": 31.4388,
				"volumePrice": 31.3053,
				"transactionCount": 185998,
				"voluemPrice": 31.3053
			},
			{
				"open": 31.51,
				"close": 31.4025,
				"volume": 127072556,
				"time": 1436846400000,
				"lowest": 31.26,
				"highest": 31.5925,
				"volumePrice": 31.435,
				"transactionCount": 166297,
				"voluemPrice": 31.435
			},
			{
				"open": 31.43,
				"close": 31.705,
				"volume": 134596628,
				"time": 1436932800000,
				"lowest": 31.395,
				"highest": 31.7875,
				"volumePrice": 31.6488,
				"transactionCount": 162268,
				"voluemPrice": 31.6488
			},
			{
				"open": 31.935,
				"close": 32.1275,
				"volume": 144889788,
				"time": 1437019200000,
				"lowest": 31.8375,
				"highest": 32.1425,
				"volumePrice": 32.0126,
				"transactionCount": 178671,
				"voluemPrice": 32.0126
			},
			{
				"open": 32.27,
				"close": 32.405,
				"volume": 184558840,
				"time": 1437105600000,
				"lowest": 32.0775,
				"highest": 32.405,
				"volumePrice": 32.2841,
				"transactionCount": 209380,
				"voluemPrice": 32.2841
			},
			{
				"open": 32.7425,
				"close": 33.0175,
				"volume": 235600812,
				"time": 1437364800000,
				"lowest": 32.675,
				"highest": 33.2425,
				"volumePrice": 32.9995,
				"transactionCount": 263106,
				"voluemPrice": 32.9995
			},
			{
				"open": 33.2125,
				"close": 32.6875,
				"volume": 307025708,
				"time": 1437451200000,
				"lowest": 32.58,
				"highest": 33.23,
				"volumePrice": 32.2446,
				"transactionCount": 374975,
				"voluemPrice": 32.2446
			},
			{
				"open": 30.4975,
				"close": 31.305,
				"volume": 461802428,
				"time": 1437537600000,
				"lowest": 30.4975,
				"highest": 31.375,
				"volumePrice": 31.0335,
				"transactionCount": 609648,
				"voluemPrice": 31.0335
			},
			{
				"open": 31.55,
				"close": 31.29,
				"volume": 203957808,
				"time": 1437624000000,
				"lowest": 31.265,
				"highest": 31.7725,
				"volumePrice": 31.5132,
				"transactionCount": 251582,
				"voluemPrice": 31.5132
			},
			{
				"open": 31.33,
				"close": 31.125,
				"volume": 168649328,
				"time": 1437710400000,
				"lowest": 30.975,
				"highest": 31.435,
				"volumePrice": 31.1938,
				"transactionCount": 220562,
				"voluemPrice": 31.1938
			},
			{
				"open": 30.7725,
				"close": 30.6925,
				"volume": 177548640,
				"time": 1437969600000,
				"lowest": 30.53,
				"highest": 30.9025,
				"volumePrice": 30.7187,
				"transactionCount": 240900,
				"voluemPrice": 30.7187
			},
			{
				"open": 30.845,
				"close": 30.845,
				"volume": 134472388,
				"time": 1438056000000,
				"lowest": 30.6375,
				"highest": 30.9775,
				"volumePrice": 30.8286,
				"transactionCount": 189978,
				"voluemPrice": 30.8286
			},
			{
				"open": 30.7875,
				"close": 30.7475,
				"volume": 147661612,
				"time": 1438142400000,
				"lowest": 30.5675,
				"highest": 30.875,
				"volumePrice": 30.7081,
				"transactionCount": 177796,
				"voluemPrice": 30.7081
			},
			{
				"open": 30.58,
				"close": 30.5925,
				"volume": 134512272,
				"time": 1438228800000,
				"lowest": 30.4275,
				"highest": 30.6425,
				"volumePrice": 30.5462,
				"transactionCount": 185579,
				"voluemPrice": 30.5462
			},
			{
				"open": 30.65,
				"close": 30.325,
				"volume": 171539812,
				"time": 1438315200000,
				"lowest": 30.2275,
				"highest": 30.6606,
				"volumePrice": 30.3531,
				"transactionCount": 224267,
				"voluemPrice": 30.3531
			},
			{
				"open": 30.375,
				"close": 29.61,
				"volume": 279903872,
				"time": 1438574400000,
				"lowest": 29.38,
				"highest": 30.6425,
				"volumePrice": 29.8545,
				"transactionCount": 389232,
				"voluemPrice": 29.8545
			},
			{
				"open": 29.355,
				"close": 28.66,
				"volume": 496423492,
				"time": 1438660800000,
				"lowest": 28.3125,
				"highest": 29.425,
				"volumePrice": 28.7645,
				"transactionCount": 648025,
				"voluemPrice": 28.7645
			},
			{
				"open": 28.2375,
				"close": 28.85,
				"volume": 397248840,
				"time": 1438747200000,
				"lowest": 28.025,
				"highest": 29.36,
				"volumePrice": 28.8564,
				"transactionCount": 505494,
				"voluemPrice": 28.8564
			},
			{
				"open": 28.9925,
				"close": 28.7825,
				"volume": 211612160,
				"time": 1438833600000,
				"lowest": 28.5288,
				"highest": 29.125,
				"volumePrice": 28.7513,
				"transactionCount": 293108,
				"voluemPrice": 28.7513
			},
			{
				"open": 28.645,
				"close": 28.88,
				"volume": 154681220,
				"time": 1438920000000,
				"lowest": 28.625,
				"highest": 29.0625,
				"volumePrice": 28.8333,
				"transactionCount": 230855,
				"voluemPrice": 28.8333
			},
			{
				"open": 29.1325,
				"close": 29.93,
				"volume": 219806388,
				"time": 1439179200000,
				"lowest": 29.1325,
				"highest": 29.9975,
				"volumePrice": 29.7052,
				"transactionCount": 279762,
				"voluemPrice": 29.7052
			},
			{
				"open": 29.4525,
				"close": 28.3725,
				"volume": 387390856,
				"time": 1439265600000,
				"lowest": 28.3325,
				"highest": 29.545,
				"volumePrice": 28.7677,
				"transactionCount": 483096,
				"voluemPrice": 28.7677
			},
			{
				"open": 28.1325,
				"close": 28.81,
				"volume": 406734440,
				"time": 1439352000000,
				"lowest": 27.4075,
				"highest": 28.855,
				"volumePrice": 28.2293,
				"transactionCount": 579303,
				"voluemPrice": 28.2293
			},
			{
				"open": 29.01,
				"close": 28.7875,
				"volume": 194143156,
				"time": 1439438400000,
				"lowest": 28.635,
				"highest": 29.1,
				"volumePrice": 28.8964,
				"transactionCount": 259262,
				"voluemPrice": 28.8964
			},
			{
				"open": 28.58,
				"close": 28.99,
				"volume": 171718064,
				"time": 1439524800000,
				"lowest": 28.5025,
				"highest": 29.0775,
				"volumePrice": 28.8249,
				"transactionCount": 229238,
				"voluemPrice": 28.8249
			},
			{
				"open": 29.01,
				"close": 29.29,
				"volume": 163537260,
				"time": 1439784000000,
				"lowest": 28.875,
				"highest": 29.4125,
				"volumePrice": 29.2368,
				"transactionCount": 211666,
				"voluemPrice": 29.2368
			},
			{
				"open": 29.1075,
				"close": 29.125,
				"volume": 138066432,
				"time": 1439870400000,
				"lowest": 29.0025,
				"highest": 29.36,
				"volumePrice": 29.1815,
				"transactionCount": 178877,
				"voluemPrice": 29.1815
			},
			{
				"open": 29.025,
				"close": 28.7525,
				"volume": 193146040,
				"time": 1439956800000,
				"lowest": 28.67,
				"highest": 29.13,
				"volumePrice": 28.8515,
				"transactionCount": 250302,
				"voluemPrice": 28.8515
			},
			{
				"open": 28.52,
				"close": 28.1625,
				"volume": 274006488,
				"time": 1440043200000,
				"lowest": 27.9075,
				"highest": 28.5875,
				"volumePrice": 28.188,
				"transactionCount": 359777,
				"voluemPrice": 28.188
			},
			{
				"open": 27.6075,
				"close": 26.44,
				"volume": 513067084,
				"time": 1440129600000,
				"lowest": 26.4113,
				"highest": 27.975,
				"volumePrice": 27.0711,
				"transactionCount": 711436,
				"voluemPrice": 27.0711
			},
			{
				"open": 23.7175,
				"close": 25.78,
				"volume": 648795400,
				"time": 1440388800000,
				"lowest": 23,
				"highest": 27.2,
				"volumePrice": 25.8019,
				"transactionCount": 902961,
				"voluemPrice": 25.8019
			},
			{
				"open": 27.7775,
				"close": 25.935,
				"volume": 414406196,
				"time": 1440475200000,
				"lowest": 25.875,
				"highest": 27.7775,
				"volumePrice": 26.8974,
				"transactionCount": 563713,
				"voluemPrice": 26.8974
			},
			{
				"open": 26.7713,
				"close": 27.4225,
				"volume": 387098084,
				"time": 1440561600000,
				"lowest": 26.2625,
				"highest": 27.4725,
				"volumePrice": 26.8374,
				"transactionCount": 534091,
				"voluemPrice": 26.8374
			},
			{
				"open": 28.0575,
				"close": 28.23,
				"volume": 338462944,
				"time": 1440648000000,
				"lowest": 27.505,
				"highest": 28.31,
				"volumePrice": 27.9768,
				"transactionCount": 456807,
				"voluemPrice": 27.9768
			},
			{
				"open": 28.0425,
				"close": 28.3225,
				"volume": 212657588,
				"time": 1440734400000,
				"lowest": 27.885,
				"highest": 28.3275,
				"volumePrice": 28.14,
				"transactionCount": 277948,
				"voluemPrice": 28.14
			},
			{
				"open": 28.0075,
				"close": 28.19,
				"volume": 224896684,
				"time": 1440993600000,
				"lowest": 28,
				"highest": 28.6325,
				"volumePrice": 28.2791,
				"transactionCount": 273601,
				"voluemPrice": 28.2791
			},
			{
				"open": 27.5375,
				"close": 26.93,
				"volume": 307363840,
				"time": 1441080000000,
				"lowest": 26.84,
				"highest": 27.97,
				"volumePrice": 27.4527,
				"transactionCount": 439337,
				"voluemPrice": 27.4527
			},
			{
				"open": 27.5575,
				"close": 28.085,
				"volume": 247555248,
				"time": 1441166400000,
				"lowest": 27.2828,
				"highest": 28.085,
				"volumePrice": 27.6491,
				"transactionCount": 311896,
				"voluemPrice": 27.6491
			},
			{
				"open": 28.1225,
				"close": 27.5925,
				"volume": 212935760,
				"time": 1441252800000,
				"lowest": 27.51,
				"highest": 28.195,
				"volumePrice": 27.8285,
				"transactionCount": 321562,
				"voluemPrice": 27.8285
			},
			{
				"open": 27.2425,
				"close": 27.3175,
				"volume": 199981764,
				"time": 1441339200000,
				"lowest": 27.1275,
				"highest": 27.6125,
				"volumePrice": 27.3231,
				"transactionCount": 321966,
				"voluemPrice": 27.3231
			},
			{
				"open": 27.9125,
				"close": 28.0775,
				"volume": 219343596,
				"time": 1441684800000,
				"lowest": 27.58,
				"highest": 28.14,
				"volumePrice": 27.9195,
				"transactionCount": 311140,
				"voluemPrice": 27.9195
			},
			{
				"open": 28.44,
				"close": 27.5375,
				"volume": 340043176,
				"time": 1441771200000,
				"lowest": 27.4425,
				"highest": 28.505,
				"volumePrice": 28.0121,
				"transactionCount": 511294,
				"voluemPrice": 28.0121
			},
			{
				"open": 27.5675,
				"close": 28.1425,
				"volume": 251571324,
				"time": 1441857600000,
				"lowest": 27.475,
				"highest": 28.3206,
				"volumePrice": 28.0114,
				"transactionCount": 359018,
				"voluemPrice": 28.0114
			},
			{
				"open": 27.9475,
				"close": 28.5525,
				"volume": 199661892,
				"time": 1441944000000,
				"lowest": 27.94,
				"highest": 28.5525,
				"volumePrice": 28.2691,
				"transactionCount": 273600,
				"voluemPrice": 28.2691
			},
			{
				"open": 29.145,
				"close": 28.8275,
				"volume": 233453724,
				"time": 1442203200000,
				"lowest": 28.715,
				"highest": 29.2225,
				"volumePrice": 28.9408,
				"transactionCount": 330315,
				"voluemPrice": 28.9408
			},
			{
				"open": 28.9825,
				"close": 29.07,
				"volume": 173364620,
				"time": 1442289600000,
				"lowest": 28.605,
				"highest": 29.1325,
				"volumePrice": 28.9621,
				"transactionCount": 250617,
				"voluemPrice": 28.9621
			},
			{
				"open": 29.0625,
				"close": 29.1025,
				"volume": 148693956,
				"time": 1442376000000,
				"lowest": 28.86,
				"highest": 29.135,
				"volumePrice": 29.0225,
				"transactionCount": 207726,
				"voluemPrice": 29.0225
			},
			{
				"open": 28.915,
				"close": 28.48,
				"volume": 256450564,
				"time": 1442462400000,
				"lowest": 28.4288,
				"highest": 29.1225,
				"volumePrice": 28.8125,
				"transactionCount": 386224,
				"voluemPrice": 28.8125
			},
			{
				"open": 28.0525,
				"close": 28.3625,
				"volume": 297141164,
				"time": 1442548800000,
				"lowest": 27.9675,
				"highest": 28.575,
				"volumePrice": 28.3392,
				"transactionCount": 324115,
				"voluemPrice": 28.3392
			},
			{
				"open": 28.4175,
				"close": 28.8025,
				"volume": 200887860,
				"time": 1442808000000,
				"lowest": 28.415,
				"highest": 28.8425,
				"volumePrice": 28.6616,
				"transactionCount": 252458,
				"voluemPrice": 28.6616
			},
			{
				"open": 28.345,
				"close": 28.35,
				"volume": 201384636,
				"time": 1442894400000,
				"lowest": 28.13,
				"highest": 28.545,
				"volumePrice": 28.3187,
				"transactionCount": 278317,
				"voluemPrice": 28.3187
			},
			{
				"open": 28.4075,
				"close": 28.58,
				"volume": 143026864,
				"time": 1442980800000,
				"lowest": 28.325,
				"highest": 28.68,
				"volumePrice": 28.5116,
				"transactionCount": 201263,
				"voluemPrice": 28.5116
			},
			{
				"open": 28.3125,
				"close": 28.75,
				"volume": 200876120,
				"time": 1443067200000,
				"lowest": 28.0925,
				"highest": 28.875,
				"volumePrice": 28.4649,
				"transactionCount": 272450,
				"voluemPrice": 28.4649
			},
			{
				"open": 29.11,
				"close": 28.6775,
				"volume": 224607704,
				"time": 1443153600000,
				"lowest": 28.505,
				"highest": 29.1725,
				"volumePrice": 28.8291,
				"transactionCount": 303675,
				"voluemPrice": 28.8291
			},
			{
				"open": 28.4625,
				"close": 28.11,
				"volume": 208435644,
				"time": 1443412800000,
				"lowest": 28.11,
				"highest": 28.6425,
				"volumePrice": 28.2812,
				"transactionCount": 296830,
				"voluemPrice": 28.2812
			},
			{
				"open": 28.2075,
				"close": 27.265,
				"volume": 292901532,
				"time": 1443499200000,
				"lowest": 26.965,
				"highest": 28.3775,
				"volumePrice": 27.6516,
				"transactionCount": 426866,
				"voluemPrice": 27.6516
			},
			{
				"open": 27.5425,
				"close": 27.575,
				"volume": 265892132,
				"time": 1443585600000,
				"lowest": 27.1825,
				"highest": 27.885,
				"volumePrice": 27.5039,
				"transactionCount": 348382,
				"voluemPrice": 27.5039
			},
			{
				"open": 27.2675,
				"close": 27.395,
				"volume": 255703000,
				"time": 1443672000000,
				"lowest": 26.8275,
				"highest": 27.405,
				"volumePrice": 27.1014,
				"transactionCount": 348065,
				"voluemPrice": 27.1014
			},
			{
				"open": 27.0025,
				"close": 27.595,
				"volume": 230957432,
				"time": 1443758400000,
				"lowest": 26.8875,
				"highest": 27.7534,
				"volumePrice": 27.3454,
				"transactionCount": 326206,
				"voluemPrice": 27.3454
			},
			{
				"open": 27.47,
				"close": 27.695,
				"volume": 208258432,
				"time": 1444017600000,
				"lowest": 27.2675,
				"highest": 27.8425,
				"volumePrice": 27.5329,
				"transactionCount": 273908,
				"voluemPrice": 27.5329
			},
			{
				"open": 27.6575,
				"close": 27.8275,
				"volume": 195427908,
				"time": 1444104000000,
				"lowest": 27.4413,
				"highest": 27.935,
				"volumePrice": 27.7148,
				"transactionCount": 262677,
				"voluemPrice": 27.7148
			},
			{
				"open": 27.935,
				"close": 27.695,
				"volume": 187061000,
				"time": 1444190400000,
				"lowest": 27.3525,
				"highest": 27.9425,
				"volumePrice": 27.6131,
				"transactionCount": 260825,
				"voluemPrice": 27.6131
			},
			{
				"open": 27.5475,
				"close": 27.375,
				"volume": 247915820,
				"time": 1444276800000,
				"lowest": 27.0525,
				"highest": 27.5475,
				"volumePrice": 27.2784,
				"transactionCount": 329722,
				"voluemPrice": 27.2784
			},
			{
				"open": 27.5,
				"close": 28.03,
				"volume": 211064216,
				"time": 1444363200000,
				"lowest": 27.3725,
				"highest": 28.07,
				"volumePrice": 27.8585,
				"transactionCount": 283109,
				"voluemPrice": 27.8585
			},
			{
				"open": 28.1825,
				"close": 27.9,
				"volume": 121868816,
				"time": 1444622400000,
				"lowest": 27.86,
				"highest": 28.1875,
				"volumePrice": 27.9818,
				"transactionCount": 168022,
				"voluemPrice": 27.9818
			},
			{
				"open": 27.705,
				"close": 27.9475,
				"volume": 132197024,
				"time": 1444708800000,
				"lowest": 27.67,
				"highest": 28.1125,
				"volumePrice": 27.947,
				"transactionCount": 177789,
				"voluemPrice": 27.947
			},
			{
				"open": 27.8225,
				"close": 27.5525,
				"volume": 177849796,
				"time": 1444795200000,
				"lowest": 27.39,
				"highest": 27.88,
				"volumePrice": 27.5753,
				"transactionCount": 240497,
				"voluemPrice": 27.5753
			},
			{
				"open": 27.7325,
				"close": 27.965,
				"volume": 150693808,
				"time": 1444881600000,
				"lowest": 27.6225,
				"highest": 28.025,
				"volumePrice": 27.8665,
				"transactionCount": 198769,
				"voluemPrice": 27.8665
			},
			{
				"open": 27.945,
				"close": 27.76,
				"volume": 156930436,
				"time": 1444968000000,
				"lowest": 27.6325,
				"highest": 28,
				"volumePrice": 27.8031,
				"transactionCount": 190505,
				"voluemPrice": 27.8031
			},
			{
				"open": 27.7,
				"close": 27.9325,
				"volume": 119036612,
				"time": 1445227200000,
				"lowest": 27.5275,
				"highest": 27.9375,
				"volumePrice": 27.7898,
				"transactionCount": 152732,
				"voluemPrice": 27.7898
			},
			{
				"open": 27.835,
				"close": 28.4425,
				"volume": 195649924,
				"time": 1445313600000,
				"lowest": 27.705,
				"highest": 28.5425,
				"volumePrice": 28.306,
				"transactionCount": 247949,
				"voluemPrice": 28.306
			},
			{
				"open": 28.5,
				"close": 28.44,
				"volume": 169307896,
				"time": 1445400000000,
				"lowest": 28.425,
				"highest": 28.895,
				"volumePrice": 28.6243,
				"transactionCount": 217784,
				"voluemPrice": 28.6243
			},
			{
				"open": 28.5825,
				"close": 28.875,
				"volume": 166616356,
				"time": 1445486400000,
				"lowest": 28.525,
				"highest": 28.875,
				"volumePrice": 28.7413,
				"transactionCount": 232439,
				"voluemPrice": 28.7413
			},
			{
				"open": 29.175,
				"close": 29.77,
				"volume": 237466856,
				"time": 1445572800000,
				"lowest": 29.0825,
				"highest": 29.807,
				"volumePrice": 29.5725,
				"transactionCount": 299213,
				"voluemPrice": 29.5725
			},
			{
				"open": 29.52,
				"close": 28.82,
				"volume": 265335124,
				"time": 1445832000000,
				"lowest": 28.73,
				"highest": 29.5325,
				"volumePrice": 28.9653,
				"transactionCount": 332130,
				"voluemPrice": 28.9653
			},
			{
				"open": 28.85,
				"close": 28.6375,
				"volume": 279537572,
				"time": 1445918400000,
				"lowest": 28.4975,
				"highest": 29.135,
				"volumePrice": 28.8502,
				"transactionCount": 343646,
				"voluemPrice": 28.8502
			},
			{
				"open": 29.2325,
				"close": 29.8175,
				"volume": 342205364,
				"time": 1446004800000,
				"lowest": 29.015,
				"highest": 29.825,
				"volumePrice": 29.3904,
				"transactionCount": 406073,
				"voluemPrice": 29.3904
			},
			{
				"open": 29.675,
				"close": 30.1325,
				"volume": 203127636,
				"time": 1446091200000,
				"lowest": 29.5675,
				"highest": 30.1725,
				"volumePrice": 29.8853,
				"transactionCount": 254848,
				"voluemPrice": 29.8853
			},
			{
				"open": 30.2475,
				"close": 29.875,
				"volume": 197461016,
				"time": 1446177600000,
				"lowest": 29.8625,
				"highest": 30.305,
				"volumePrice": 30.0717,
				"transactionCount": 241313,
				"voluemPrice": 30.0717
			},
			{
				"open": 29.9675,
				"close": 30.295,
				"volume": 128789068,
				"time": 1446440400000,
				"lowest": 29.9025,
				"highest": 30.34,
				"volumePrice": 30.1802,
				"transactionCount": 177876,
				"voluemPrice": 30.1802
			},
			{
				"open": 30.1975,
				"close": 30.6425,
				"volume": 181950904,
				"time": 1446526800000,
				"lowest": 30.175,
				"highest": 30.8725,
				"volumePrice": 30.6235,
				"transactionCount": 223066,
				"voluemPrice": 30.6235
			},
			{
				"open": 30.7825,
				"close": 30.5,
				"volume": 179505200,
				"time": 1446613200000,
				"lowest": 30.405,
				"highest": 30.955,
				"volumePrice": 30.6254,
				"transactionCount": 214464,
				"voluemPrice": 30.6254
			},
			{
				"open": 30.4625,
				"close": 30.23,
				"volume": 158153120,
				"time": 1446699600000,
				"lowest": 30.045,
				"highest": 30.6725,
				"volumePrice": 30.3027,
				"transactionCount": 197482,
				"voluemPrice": 30.3027
			},
			{
				"open": 30.2775,
				"close": 30.265,
				"volume": 132151672,
				"time": 1446786000000,
				"lowest": 30.155,
				"highest": 30.4525,
				"volumePrice": 30.2903,
				"transactionCount": 176894,
				"voluemPrice": 30.2903
			},
			{
				"open": 30.24,
				"close": 30.1425,
				"volume": 135485620,
				"time": 1447045200000,
				"lowest": 30.0125,
				"highest": 30.4525,
				"volumePrice": 30.1929,
				"transactionCount": 178246,
				"voluemPrice": 30.1929
			},
			{
				"open": 29.225,
				"close": 29.1925,
				"volume": 236502184,
				"time": 1447131600000,
				"lowest": 29.0153,
				"highest": 29.5175,
				"volumePrice": 29.2681,
				"transactionCount": 305856,
				"voluemPrice": 29.2681
			},
			{
				"open": 29.0925,
				"close": 29.0275,
				"volume": 180871884,
				"time": 1447218000000,
				"lowest": 28.8025,
				"highest": 29.355,
				"volumePrice": 29.0752,
				"transactionCount": 230426,
				"voluemPrice": 29.0752
			},
			{
				"open": 29.065,
				"close": 28.93,
				"volume": 130102316,
				"time": 1447304400000,
				"lowest": 28.9125,
				"highest": 29.205,
				"volumePrice": 29.0077,
				"transactionCount": 177103,
				"voluemPrice": 29.0077
			},
			{
				"open": 28.8,
				"close": 28.085,
				"volume": 183249612,
				"time": 1447390800000,
				"lowest": 28.0675,
				"highest": 28.8925,
				"volumePrice": 28.3596,
				"transactionCount": 248479,
				"voluemPrice": 28.3596
			},
			{
				"open": 27.845,
				"close": 28.5438,
				"volume": 152426804,
				"time": 1447650000000,
				"lowest": 27.75,
				"highest": 28.56,
				"volumePrice": 28.3105,
				"transactionCount": 206035,
				"voluemPrice": 28.3105
			},
			{
				"open": 28.73,
				"close": 28.4225,
				"volume": 110345008,
				"time": 1447736400000,
				"lowest": 28.33,
				"highest": 28.7625,
				"volumePrice": 28.5268,
				"transactionCount": 150013,
				"voluemPrice": 28.5268
			},
			{
				"open": 28.94,
				"close": 29.3225,
				"volume": 186698788,
				"time": 1447822800000,
				"lowest": 28.875,
				"highest": 29.3725,
				"volumePrice": 29.2066,
				"transactionCount": 240485,
				"voluemPrice": 29.2066
			},
			{
				"open": 29.41,
				"close": 29.695,
				"volume": 173170480,
				"time": 1447909200000,
				"lowest": 29.19,
				"highest": 29.9375,
				"volumePrice": 29.644,
				"transactionCount": 221281,
				"voluemPrice": 29.644
			},
			{
				"open": 29.8,
				"close": 29.825,
				"volume": 137148384,
				"time": 1447995600000,
				"lowest": 29.7125,
				"highest": 29.98,
				"volumePrice": 29.8403,
				"transactionCount": 176069,
				"voluemPrice": 29.8403
			},
			{
				"open": 29.8175,
				"close": 29.4375,
				"volume": 129658112,
				"time": 1448254800000,
				"lowest": 29.335,
				"highest": 29.9325,
				"volumePrice": 29.5837,
				"transactionCount": 166928,
				"voluemPrice": 29.5837
			},
			{
				"open": 29.3325,
				"close": 29.72,
				"volume": 171212688,
				"time": 1448341200000,
				"lowest": 29.28,
				"highest": 29.8375,
				"volumePrice": 29.6536,
				"transactionCount": 216631,
				"voluemPrice": 29.6536
			},
			{
				"open": 29.8025,
				"close": 29.5075,
				"volume": 85553232,
				"time": 1448427600000,
				"lowest": 29.48,
				"highest": 29.8075,
				"volumePrice": 29.5837,
				"transactionCount": 122008,
				"voluemPrice": 29.5837
			},
			{
				"open": 29.5725,
				"close": 29.4525,
				"volume": 52185780,
				"time": 1448600400000,
				"lowest": 29.4,
				"highest": 29.6025,
				"volumePrice": 29.4814,
				"transactionCount": 75070,
				"voluemPrice": 29.4814
			},
			{
				"open": 29.4975,
				"close": 29.575,
				"volume": 156720888,
				"time": 1448859600000,
				"lowest": 29.4375,
				"highest": 29.8525,
				"volumePrice": 29.6567,
				"transactionCount": 178079,
				"voluemPrice": 29.6567
			},
			{
				"open": 29.6875,
				"close": 29.335,
				"volume": 139409496,
				"time": 1448946000000,
				"lowest": 29.215,
				"highest": 29.7025,
				"volumePrice": 29.4392,
				"transactionCount": 187129,
				"voluemPrice": 29.4392
			},
			{
				"open": 29.2625,
				"close": 29.07,
				"volume": 133542572,
				"time": 1449032400000,
				"lowest": 29.02,
				"highest": 29.5275,
				"volumePrice": 29.2878,
				"transactionCount": 180616,
				"voluemPrice": 29.2878
			},
			{
				"open": 29.1375,
				"close": 28.8,
				"volume": 166243140,
				"time": 1449118800000,
				"lowest": 28.555,
				"highest": 29.1975,
				"volumePrice": 28.8587,
				"transactionCount": 245330,
				"voluemPrice": 28.8587
			},
			{
				"open": 28.8225,
				"close": 29.7575,
				"volume": 231107908,
				"time": 1449205200000,
				"lowest": 28.7775,
				"highest": 29.8125,
				"volumePrice": 29.5468,
				"transactionCount": 307788,
				"voluemPrice": 29.5468
			},
			{
				"open": 29.745,
				"close": 29.57,
				"volume": 128323016,
				"time": 1449464400000,
				"lowest": 29.4525,
				"highest": 29.965,
				"volumePrice": 29.6273,
				"transactionCount": 190809,
				"voluemPrice": 29.6273
			},
			{
				"open": 29.38,
				"close": 29.5575,
				"volume": 137237800,
				"time": 1449550800000,
				"lowest": 29.215,
				"highest": 29.65,
				"volumePrice": 29.4553,
				"transactionCount": 186353,
				"voluemPrice": 29.4553
			},
			{
				"open": 29.41,
				"close": 28.905,
				"volume": 183408108,
				"time": 1449637200000,
				"lowest": 28.77,
				"highest": 29.4225,
				"volumePrice": 29.0245,
				"transactionCount": 242397,
				"voluemPrice": 29.0245
			},
			{
				"open": 29.01,
				"close": 29.0425,
				"volume": 116850908,
				"time": 1449723600000,
				"lowest": 28.8775,
				"highest": 29.235,
				"volumePrice": 29.0422,
				"transactionCount": 169277,
				"voluemPrice": 29.0422
			},
			{
				"open": 28.7975,
				"close": 28.295,
				"volume": 187544644,
				"time": 1449810000000,
				"lowest": 28.2128,
				"highest": 28.8475,
				"volumePrice": 28.4255,
				"transactionCount": 273846,
				"voluemPrice": 28.4255
			},
			{
				"open": 28.045,
				"close": 28.12,
				"volume": 259766604,
				"time": 1450069200000,
				"lowest": 27.4475,
				"highest": 28.17,
				"volumePrice": 27.8532,
				"transactionCount": 371153,
				"voluemPrice": 27.8532
			},
			{
				"open": 27.985,
				"close": 27.6225,
				"volume": 213115620,
				"time": 1450155600000,
				"lowest": 27.5875,
				"highest": 28.2,
				"volumePrice": 27.8835,
				"transactionCount": 310644,
				"voluemPrice": 27.8835
			},
			{
				"open": 27.7675,
				"close": 27.835,
				"volume": 224924268,
				"time": 1450242000000,
				"lowest": 27.2,
				"highest": 27.9975,
				"volumePrice": 27.5677,
				"transactionCount": 330121,
				"voluemPrice": 27.5677
			},
			{
				"open": 28.005,
				"close": 27.245,
				"volume": 179091308,
				"time": 1450328400000,
				"lowest": 27.245,
				"highest": 28.0625,
				"volumePrice": 27.5197,
				"transactionCount": 255830,
				"voluemPrice": 27.5197
			},
			{
				"open": 27.2275,
				"close": 26.5075,
				"volume": 384673308,
				"time": 1450414800000,
				"lowest": 26.4525,
				"highest": 27.38,
				"volumePrice": 26.7508,
				"transactionCount": 370241,
				"voluemPrice": 26.7508
			},
			{
				"open": 26.82,
				"close": 26.8325,
				"volume": 190348420,
				"time": 1450674000000,
				"lowest": 26.3925,
				"highest": 26.8425,
				"volumePrice": 26.6757,
				"transactionCount": 240525,
				"voluemPrice": 26.6757
			},
			{
				"open": 26.85,
				"close": 26.8075,
				"volume": 131157468,
				"time": 1450760400000,
				"lowest": 26.6128,
				"highest": 26.93,
				"volumePrice": 26.7829,
				"transactionCount": 187168,
				"voluemPrice": 26.7829
			},
			{
				"open": 26.8175,
				"close": 27.1525,
				"volume": 130545416,
				"time": 1450846800000,
				"lowest": 26.8,
				"highest": 27.2125,
				"volumePrice": 27.0831,
				"transactionCount": 170514,
				"voluemPrice": 27.0831
			},
			{
				"open": 27.25,
				"close": 27.0075,
				"volume": 54386720,
				"time": 1450933200000,
				"lowest": 26.9875,
				"highest": 27.25,
				"volumePrice": 27.0876,
				"transactionCount": 77466,
				"voluemPrice": 27.0876
			},
			{
				"open": 26.8975,
				"close": 26.705,
				"volume": 106744840,
				"time": 1451278800000,
				"lowest": 26.5452,
				"highest": 26.9225,
				"volumePrice": 26.7133,
				"transactionCount": 157534,
				"voluemPrice": 26.7133
			},
			{
				"open": 26.74,
				"close": 27.185,
				"volume": 123724788,
				"time": 1451365200000,
				"lowest": 26.715,
				"highest": 27.3575,
				"volumePrice": 27.0909,
				"transactionCount": 174919,
				"voluemPrice": 27.0909
			},
			{
				"open": 27.145,
				"close": 26.83,
				"volume": 100855108,
				"time": 1451451600000,
				"lowest": 26.795,
				"highest": 27.175,
				"volumePrice": 26.9094,
				"transactionCount": 140575,
				"voluemPrice": 26.9094
			},
			{
				"open": 26.7525,
				"close": 26.315,
				"volume": 163409264,
				"time": 1451538000000,
				"lowest": 26.205,
				"highest": 26.7575,
				"volumePrice": 26.407,
				"transactionCount": 217791,
				"voluemPrice": 26.407
			},
			{
				"open": 25.6525,
				"close": 26.3375,
				"volume": 270446632,
				"time": 1451883600000,
				"lowest": 25.5,
				"highest": 26.342,
				"volumePrice": 26.0121,
				"transactionCount": 351450,
				"voluemPrice": 26.0121
			},
			{
				"open": 26.4375,
				"close": 25.6775,
				"volume": 223148768,
				"time": 1451970000000,
				"lowest": 25.6025,
				"highest": 26.4625,
				"volumePrice": 25.854,
				"transactionCount": 321363,
				"voluemPrice": 25.854
			},
			{
				"open": 25.14,
				"close": 25.175,
				"volume": 273361552,
				"time": 1452056400000,
				"lowest": 24.9675,
				"highest": 25.5925,
				"volumePrice": 25.2131,
				"transactionCount": 409162,
				"voluemPrice": 25.2131
			},
			{
				"open": 24.67,
				"close": 24.1125,
				"volume": 324377428,
				"time": 1452142800000,
				"lowest": 24.1075,
				"highest": 25.0325,
				"volumePrice": 24.5173,
				"transactionCount": 462834,
				"voluemPrice": 24.5173
			},
			{
				"open": 24.6375,
				"close": 24.24,
				"volume": 283191864,
				"time": 1452229200000,
				"lowest": 24.19,
				"highest": 24.7775,
				"volumePrice": 24.4808,
				"transactionCount": 419804,
				"voluemPrice": 24.4808
			},
			{
				"open": 24.7425,
				"close": 24.6325,
				"volume": 198902308,
				"time": 1452488400000,
				"lowest": 24.335,
				"highest": 24.765,
				"volumePrice": 24.5347,
				"transactionCount": 292281,
				"voluemPrice": 24.5347
			},
			{
				"open": 25.1375,
				"close": 24.99,
				"volume": 196616908,
				"time": 1452574800000,
				"lowest": 24.71,
				"highest": 25.1725,
				"volumePrice": 24.9381,
				"transactionCount": 282809,
				"voluemPrice": 24.9381
			},
			{
				"open": 25.08,
				"close": 24.3475,
				"volume": 249758524,
				"time": 1452661200000,
				"lowest": 24.325,
				"highest": 25.2975,
				"volumePrice": 24.8368,
				"transactionCount": 362015,
				"voluemPrice": 24.8368
			},
			{
				"open": 24.49,
				"close": 24.88,
				"volume": 252680508,
				"time": 1452747600000,
				"lowest": 23.935,
				"highest": 25.12,
				"volumePrice": 24.5758,
				"transactionCount": 362780,
				"voluemPrice": 24.5758
			},
			{
				"open": 24.05,
				"close": 24.2825,
				"volume": 319255564,
				"time": 1452834000000,
				"lowest": 23.84,
				"highest": 24.4275,
				"volumePrice": 24.1806,
				"transactionCount": 447030,
				"voluemPrice": 24.1806
			},
			{
				"open": 24.6025,
				"close": 24.165,
				"volume": 212350988,
				"time": 1453179600000,
				"lowest": 23.875,
				"highest": 24.6625,
				"volumePrice": 24.2205,
				"transactionCount": 291403,
				"voluemPrice": 24.2205
			},
			{
				"open": 23.775,
				"close": 24.1975,
				"volume": 289337664,
				"time": 1453266000000,
				"lowest": 23.355,
				"highest": 24.5474,
				"volumePrice": 23.8052,
				"transactionCount": 431729,
				"voluemPrice": 23.8052
			},
			{
				"open": 24.265,
				"close": 24.075,
				"volume": 208557432,
				"time": 1453352400000,
				"lowest": 23.735,
				"highest": 24.47,
				"volumePrice": 24.1611,
				"transactionCount": 301287,
				"voluemPrice": 24.1611
			},
			{
				"open": 24.6575,
				"close": 25.355,
				"volume": 263201868,
				"time": 1453438800000,
				"lowest": 24.5925,
				"highest": 25.365,
				"volumePrice": 25.0119,
				"transactionCount": 375468,
				"voluemPrice": 25.0119
			},
			{
				"open": 25.38,
				"close": 24.86,
				"volume": 207033148,
				"time": 1453698000000,
				"lowest": 24.8025,
				"highest": 25.3825,
				"volumePrice": 25.1018,
				"transactionCount": 281832,
				"voluemPrice": 25.1018
			},
			{
				"open": 24.9825,
				"close": 24.9975,
				"volume": 300308008,
				"time": 1453784400000,
				"lowest": 24.5175,
				"highest": 25.22,
				"volumePrice": 24.93,
				"transactionCount": 402738,
				"voluemPrice": 24.93
			},
			{
				"open": 24.01,
				"close": 23.355,
				"volume": 533478696,
				"time": 1453870800000,
				"lowest": 23.335,
				"highest": 24.1572,
				"volumePrice": 23.7215,
				"transactionCount": 683976,
				"voluemPrice": 23.7215
			},
			{
				"open": 23.4475,
				"close": 23.5225,
				"volume": 222714692,
				"time": 1453957200000,
				"lowest": 23.0975,
				"highest": 23.63,
				"volumePrice": 23.4064,
				"transactionCount": 338197,
				"voluemPrice": 23.4064
			},
			{
				"open": 23.6975,
				"close": 24.335,
				"volume": 257666016,
				"time": 1454043600000,
				"lowest": 23.5875,
				"highest": 24.335,
				"volumePrice": 24.0677,
				"transactionCount": 330860,
				"voluemPrice": 24.0677
			},
			{
				"open": 24.1175,
				"close": 24.1075,
				"volume": 163771272,
				"time": 1454302800000,
				"lowest": 23.85,
				"highest": 24.1775,
				"volumePrice": 24.0568,
				"transactionCount": 236716,
				"voluemPrice": 24.0568
			},
			{
				"open": 23.855,
				"close": 23.62,
				"volume": 149394860,
				"time": 1454389200000,
				"lowest": 23.57,
				"highest": 24.01,
				"volumePrice": 23.7719,
				"transactionCount": 237885,
				"voluemPrice": 23.7719
			},
			{
				"open": 23.75,
				"close": 24.0875,
				"volume": 183785376,
				"time": 1454475600000,
				"lowest": 23.52,
				"highest": 24.21,
				"volumePrice": 23.8357,
				"transactionCount": 297821,
				"voluemPrice": 23.8357
			},
			{
				"open": 23.965,
				"close": 24.15,
				"volume": 185886608,
				"time": 1454562000000,
				"lowest": 23.7975,
				"highest": 24.3325,
				"volumePrice": 24.0776,
				"transactionCount": 265044,
				"voluemPrice": 24.0776
			},
			{
				"open": 24.13,
				"close": 23.505,
				"volume": 185672256,
				"time": 1454648400000,
				"lowest": 23.4225,
				"highest": 24.23,
				"volumePrice": 23.7288,
				"transactionCount": 288658,
				"voluemPrice": 23.7288
			},
			{
				"open": 23.2825,
				"close": 23.7525,
				"volume": 216083900,
				"time": 1454907600000,
				"lowest": 23.26,
				"highest": 23.925,
				"volumePrice": 23.5537,
				"transactionCount": 353979,
				"voluemPrice": 23.5537
			},
			{
				"open": 23.5725,
				"close": 23.7475,
				"volume": 177324780,
				"time": 1454994000000,
				"lowest": 23.4825,
				"highest": 23.985,
				"volumePrice": 23.7159,
				"transactionCount": 273869,
				"voluemPrice": 23.7159
			},
			{
				"open": 23.98,
				"close": 23.5675,
				"volume": 169374404,
				"time": 1455080400000,
				"lowest": 23.525,
				"highest": 24.0875,
				"volumePrice": 23.8166,
				"transactionCount": 265623,
				"voluemPrice": 23.8166
			},
			{
				"open": 23.4475,
				"close": 23.425,
				"volume": 200298844,
				"time": 1455166800000,
				"lowest": 23.1475,
				"highest": 23.68,
				"volumePrice": 23.3968,
				"transactionCount": 315317,
				"voluemPrice": 23.3968
			},
			{
				"open": 23.5475,
				"close": 23.4975,
				"volume": 161405524,
				"time": 1455253200000,
				"lowest": 23.2525,
				"highest": 23.625,
				"volumePrice": 23.437,
				"transactionCount": 244084,
				"voluemPrice": 23.437
			},
			{
				"open": 23.755,
				"close": 24.16,
				"volume": 196230464,
				"time": 1455598800000,
				"lowest": 23.6525,
				"highest": 24.2125,
				"volumePrice": 23.9858,
				"transactionCount": 267841,
				"voluemPrice": 23.9858
			},
			{
				"open": 24.1675,
				"close": 24.53,
				"volume": 179452972,
				"time": 1455685200000,
				"lowest": 24.0375,
				"highest": 24.5525,
				"volumePrice": 24.3531,
				"transactionCount": 243457,
				"voluemPrice": 24.3531
			},
			{
				"open": 24.71,
				"close": 24.065,
				"volume": 156083932,
				"time": 1455771600000,
				"lowest": 24.0227,
				"highest": 24.7225,
				"volumePrice": 24.2463,
				"transactionCount": 215834,
				"voluemPrice": 24.2463
			},
			{
				"open": 24,
				"close": 24.01,
				"volume": 141381492,
				"time": 1455858000000,
				"lowest": 23.95,
				"highest": 24.19,
				"volumePrice": 24.0555,
				"transactionCount": 188224,
				"voluemPrice": 24.0555
			},
			{
				"open": 24.0775,
				"close": 24.22,
				"volume": 137121832,
				"time": 1456117200000,
				"lowest": 23.98,
				"highest": 24.225,
				"volumePrice": 24.102,
				"transactionCount": 187680,
				"voluemPrice": 24.102
			},
			{
				"open": 24.1,
				"close": 23.6725,
				"volume": 127770532,
				"time": 1456203600000,
				"lowest": 23.6375,
				"highest": 24.125,
				"volumePrice": 23.8191,
				"transactionCount": 199764,
				"voluemPrice": 23.8191
			},
			{
				"open": 23.495,
				"close": 24.025,
				"volume": 145022980,
				"time": 1456290000000,
				"lowest": 23.33,
				"highest": 24.095,
				"volumePrice": 23.6992,
				"transactionCount": 220035,
				"voluemPrice": 23.6992
			},
			{
				"open": 24.0125,
				"close": 24.19,
				"volume": 109330636,
				"time": 1456376400000,
				"lowest": 23.8125,
				"highest": 24.19,
				"volumePrice": 24.0115,
				"transactionCount": 173260,
				"voluemPrice": 24.0115
			},
			{
				"open": 24.3,
				"close": 24.2275,
				"volume": 115964524,
				"time": 1456462800000,
				"lowest": 24.145,
				"highest": 24.5059,
				"volumePrice": 24.3077,
				"transactionCount": 172880,
				"voluemPrice": 24.3077
			},
			{
				"open": 24.215,
				"close": 24.1725,
				"volume": 140865108,
				"time": 1456722000000,
				"lowest": 24.1625,
				"highest": 24.5575,
				"volumePrice": 24.3379,
				"transactionCount": 175887,
				"voluemPrice": 24.3379
			},
			{
				"open": 24.4125,
				"close": 25.1325,
				"volume": 201491860,
				"time": 1456808400000,
				"lowest": 24.355,
				"highest": 25.1925,
				"volumePrice": 24.9099,
				"transactionCount": 294792,
				"voluemPrice": 24.9099
			},
			{
				"open": 25.1275,
				"close": 25.1875,
				"volume": 132678240,
				"time": 1456894800000,
				"lowest": 24.91,
				"highest": 25.2225,
				"volumePrice": 25.0535,
				"transactionCount": 190904,
				"voluemPrice": 25.0535
			},
			{
				"open": 25.145,
				"close": 25.375,
				"volume": 146836968,
				"time": 1456981200000,
				"lowest": 25.1125,
				"highest": 25.4275,
				"volumePrice": 25.2993,
				"transactionCount": 209590,
				"voluemPrice": 25.2993
			},
			{
				"open": 25.5925,
				"close": 25.7525,
				"volume": 184193800,
				"time": 1457067600000,
				"lowest": 25.3425,
				"highest": 25.9375,
				"volumePrice": 25.6788,
				"transactionCount": 246861,
				"voluemPrice": 25.6788
			},
			{
				"open": 25.5975,
				"close": 25.4675,
				"volume": 143663240,
				"time": 1457326800000,
				"lowest": 25.24,
				"highest": 25.7075,
				"volumePrice": 25.4768,
				"transactionCount": 211392,
				"voluemPrice": 25.4768
			},
			{
				"open": 25.195,
				"close": 25.2575,
				"volume": 125927556,
				"time": 1457413200000,
				"lowest": 25.1,
				"highest": 25.44,
				"volumePrice": 25.2835,
				"transactionCount": 187692,
				"voluemPrice": 25.2835
			},
			{
				"open": 25.3275,
				"close": 25.28,
				"volume": 108806732,
				"time": 1457499600000,
				"lowest": 25.0675,
				"highest": 25.395,
				"volumePrice": 25.2488,
				"transactionCount": 169252,
				"voluemPrice": 25.2488
			},
			{
				"open": 25.3525,
				"close": 25.2925,
				"volume": 134054308,
				"time": 1457586000000,
				"lowest": 25.0375,
				"highest": 25.56,
				"volumePrice": 25.2782,
				"transactionCount": 206148,
				"voluemPrice": 25.2782
			},
			{
				"open": 25.56,
				"close": 25.565,
				"volume": 109576852,
				"time": 1457672400000,
				"lowest": 25.375,
				"highest": 25.57,
				"volumePrice": 25.4943,
				"transactionCount": 155549,
				"voluemPrice": 25.4943
			},
			{
				"open": 25.4775,
				"close": 25.63,
				"volume": 100304248,
				"time": 1457928000000,
				"lowest": 25.445,
				"highest": 25.7275,
				"volumePrice": 25.6132,
				"transactionCount": 145485,
				"voluemPrice": 25.6132
			},
			{
				"open": 25.99,
				"close": 26.145,
				"volume": 160270936,
				"time": 1458014400000,
				"lowest": 25.9625,
				"highest": 26.295,
				"volumePrice": 26.1534,
				"transactionCount": 225937,
				"voluemPrice": 26.1534
			},
			{
				"open": 26.1525,
				"close": 26.4925,
				"volume": 153130372,
				"time": 1458100800000,
				"lowest": 26.1475,
				"highest": 26.5775,
				"volumePrice": 26.3845,
				"transactionCount": 230348,
				"voluemPrice": 26.3845
			},
			{
				"open": 26.38,
				"close": 26.45,
				"volume": 137682820,
				"time": 1458187200000,
				"lowest": 26.24,
				"highest": 26.6175,
				"volumePrice": 26.4486,
				"transactionCount": 175510,
				"voluemPrice": 26.4486
			},
			{
				"open": 26.585,
				"close": 26.48,
				"volume": 176820684,
				"time": 1458273600000,
				"lowest": 26.2975,
				"highest": 26.625,
				"volumePrice": 26.4907,
				"transactionCount": 175168,
				"voluemPrice": 26.4907
			},
			{
				"open": 26.4825,
				"close": 26.4775,
				"volume": 141581912,
				"time": 1458532800000,
				"lowest": 26.285,
				"highest": 26.9125,
				"volumePrice": 26.5527,
				"transactionCount": 208778,
				"voluemPrice": 26.5527
			},
			{
				"open": 26.3125,
				"close": 26.68,
				"volume": 129776080,
				"time": 1458619200000,
				"lowest": 26.3025,
				"highest": 26.8225,
				"volumePrice": 26.6878,
				"transactionCount": 174143,
				"voluemPrice": 26.6878
			},
			{
				"open": 26.62,
				"close": 26.5325,
				"volume": 102813980,
				"time": 1458705600000,
				"lowest": 26.475,
				"highest": 26.7675,
				"volumePrice": 26.6191,
				"transactionCount": 146397,
				"voluemPrice": 26.6191
			},
			{
				"open": 26.3675,
				"close": 26.4175,
				"volume": 104531820,
				"time": 1458792000000,
				"lowest": 26.2225,
				"highest": 26.5625,
				"volumePrice": 26.373,
				"transactionCount": 143295,
				"voluemPrice": 26.373
			},
			{
				"open": 26.5,
				"close": 26.2975,
				"volume": 77645488,
				"time": 1459137600000,
				"lowest": 26.265,
				"highest": 26.5475,
				"volumePrice": 26.3628,
				"transactionCount": 112797,
				"voluemPrice": 26.3628
			},
			{
				"open": 26.2225,
				"close": 26.92,
				"volume": 124760332,
				"time": 1459224000000,
				"lowest": 26.22,
				"highest": 26.9475,
				"volumePrice": 26.7302,
				"transactionCount": 175677,
				"voluemPrice": 26.7302
			},
			{
				"open": 27.1625,
				"close": 27.39,
				"volume": 182404596,
				"time": 1459310400000,
				"lowest": 27.15,
				"highest": 27.605,
				"volumePrice": 27.392,
				"transactionCount": 230965,
				"voluemPrice": 27.392
			},
			{
				"open": 27.43,
				"close": 27.2475,
				"volume": 103553796,
				"time": 1459396800000,
				"lowest": 27.22,
				"highest": 27.475,
				"volumePrice": 27.3157,
				"transactionCount": 135628,
				"voluemPrice": 27.3157
			},
			{
				"open": 27.195,
				"close": 27.4975,
				"volume": 103495800,
				"time": 1459483200000,
				"lowest": 27.05,
				"highest": 27.5,
				"volumePrice": 27.3631,
				"transactionCount": 131199,
				"voluemPrice": 27.3631
			},
			{
				"open": 27.605,
				"close": 27.78,
				"volume": 148914816,
				"time": 1459742400000,
				"lowest": 27.5675,
				"highest": 28.0475,
				"volumePrice": 27.8459,
				"transactionCount": 208851,
				"voluemPrice": 27.8459
			},
			{
				"open": 27.3775,
				"close": 27.4525,
				"volume": 106314608,
				"time": 1459828800000,
				"lowest": 27.355,
				"highest": 27.6825,
				"volumePrice": 27.5057,
				"transactionCount": 162226,
				"voluemPrice": 27.5057
			},
			{
				"open": 27.5575,
				"close": 27.74,
				"volume": 105616308,
				"time": 1459915200000,
				"lowest": 27.3,
				"highest": 27.745,
				"volumePrice": 27.5368,
				"transactionCount": 150637,
				"voluemPrice": 27.5368
			},
			{
				"open": 27.4875,
				"close": 27.135,
				"volume": 127207480,
				"time": 1460001600000,
				"lowest": 27.0303,
				"highest": 27.605,
				"volumePrice": 27.2877,
				"transactionCount": 182057,
				"voluemPrice": 27.2877
			},
			{
				"open": 27.2275,
				"close": 27.165,
				"volume": 94326960,
				"time": 1460088000000,
				"lowest": 27.0425,
				"highest": 27.4425,
				"volumePrice": 27.2359,
				"transactionCount": 147761,
				"voluemPrice": 27.2359
			},
			{
				"open": 27.2425,
				"close": 27.255,
				"volume": 117496072,
				"time": 1460347200000,
				"lowest": 27.2075,
				"highest": 27.6525,
				"volumePrice": 27.4454,
				"transactionCount": 167025,
				"voluemPrice": 27.4454
			},
			{
				"open": 27.335,
				"close": 27.61,
				"volume": 108929300,
				"time": 1460433600000,
				"lowest": 27.165,
				"highest": 27.625,
				"volumePrice": 27.4453,
				"transactionCount": 166943,
				"voluemPrice": 27.4453
			},
			{
				"open": 27.7,
				"close": 28.01,
				"volume": 133029264,
				"time": 1460520000000,
				"lowest": 27.7,
				"highest": 28.085,
				"volumePrice": 27.9635,
				"transactionCount": 192789,
				"voluemPrice": 27.9635
			},
			{
				"open": 27.905,
				"close": 28.025,
				"volume": 101895692,
				"time": 1460606400000,
				"lowest": 27.8325,
				"highest": 28.0975,
				"volumePrice": 27.9758,
				"transactionCount": 142189,
				"voluemPrice": 27.9758
			},
			{
				"open": 28.0275,
				"close": 27.4625,
				"volume": 187471476,
				"time": 1460692800000,
				"lowest": 27.4325,
				"highest": 28.075,
				"volumePrice": 27.6704,
				"transactionCount": 227930,
				"voluemPrice": 27.6704
			},
			{
				"open": 27.2225,
				"close": 26.87,
				"volume": 243284844,
				"time": 1460952000000,
				"lowest": 26.735,
				"highest": 27.2375,
				"volumePrice": 26.9638,
				"transactionCount": 281030,
				"voluemPrice": 26.9638
			},
			{
				"open": 26.97,
				"close": 26.7275,
				"volume": 129539516,
				"time": 1461038400000,
				"lowest": 26.5575,
				"highest": 27,
				"volumePrice": 26.7455,
				"transactionCount": 191756,
				"voluemPrice": 26.7455
			},
			{
				"open": 26.66,
				"close": 26.7825,
				"volume": 122180120,
				"time": 1461124800000,
				"lowest": 26.515,
				"highest": 27.0225,
				"volumePrice": 26.8353,
				"transactionCount": 167265,
				"voluemPrice": 26.8353
			},
			{
				"open": 26.7325,
				"close": 26.4925,
				"volume": 126210100,
				"time": 1461211200000,
				"lowest": 26.38,
				"highest": 26.7325,
				"volumePrice": 26.5066,
				"transactionCount": 184327,
				"voluemPrice": 26.5066
			},
			{
				"open": 26.2525,
				"close": 26.42,
				"volume": 134732476,
				"time": 1461297600000,
				"lowest": 26.155,
				"highest": 26.62,
				"volumePrice": 26.3453,
				"transactionCount": 193373,
				"voluemPrice": 26.3453
			},
			{
				"open": 26.25,
				"close": 26.27,
				"volume": 112126304,
				"time": 1461556800000,
				"lowest": 26.1275,
				"highest": 26.4125,
				"volumePrice": 26.2593,
				"transactionCount": 160512,
				"voluemPrice": 26.2593
			},
			{
				"open": 25.9775,
				"close": 26.0875,
				"volume": 223679860,
				"time": 1461643200000,
				"lowest": 25.9775,
				"highest": 26.325,
				"volumePrice": 25.6341,
				"transactionCount": 312165,
				"voluemPrice": 25.6341
			},
			{
				"open": 24,
				"close": 24.455,
				"volume": 458408568,
				"time": 1461729600000,
				"lowest": 23.92,
				"highest": 24.6775,
				"volumePrice": 24.3403,
				"transactionCount": 653471,
				"voluemPrice": 24.3403
			},
			{
				"open": 24.4025,
				"close": 23.7075,
				"volume": 328970760,
				"time": 1461816000000,
				"lowest": 23.5625,
				"highest": 24.47,
				"volumePrice": 24.0351,
				"transactionCount": 473319,
				"voluemPrice": 24.0351
			},
			{
				"open": 23.4975,
				"close": 23.435,
				"volume": 274045912,
				"time": 1461902400000,
				"lowest": 23.1275,
				"highest": 23.68,
				"volumePrice": 23.3665,
				"transactionCount": 380711,
				"voluemPrice": 23.3665
			},
			{
				"open": 23.4913,
				"close": 23.41,
				"volume": 192623616,
				"time": 1462161600000,
				"lowest": 23.1,
				"highest": 23.52,
				"volumePrice": 23.2916,
				"transactionCount": 270948,
				"voluemPrice": 23.2916
			},
			{
				"open": 23.55,
				"close": 23.795,
				"volume": 227324624,
				"time": 1462248000000,
				"lowest": 23.42,
				"highest": 23.935,
				"volumePrice": 23.6715,
				"transactionCount": 312299,
				"voluemPrice": 23.6715
			},
			{
				"open": 23.8,
				"close": 23.5475,
				"volume": 161901900,
				"time": 1462334400000,
				"lowest": 23.455,
				"highest": 23.975,
				"volumePrice": 23.6225,
				"transactionCount": 225336,
				"voluemPrice": 23.6225
			},
			{
				"open": 23.5,
				"close": 23.31,
				"volume": 143562000,
				"time": 1462420800000,
				"lowest": 23.17,
				"highest": 23.5175,
				"volumePrice": 23.2811,
				"transactionCount": 199730,
				"voluemPrice": 23.2811
			},
			{
				"open": 23.3425,
				"close": 23.18,
				"volume": 174799544,
				"time": 1462507200000,
				"lowest": 22.9625,
				"highest": 23.3625,
				"volumePrice": 23.137,
				"transactionCount": 233138,
				"voluemPrice": 23.137
			},
			{
				"open": 23.25,
				"close": 23.1975,
				"volume": 131745744,
				"time": 1462766400000,
				"lowest": 23.1475,
				"highest": 23.4425,
				"volumePrice": 23.2816,
				"transactionCount": 183318,
				"voluemPrice": 23.2816
			},
			{
				"open": 23.3325,
				"close": 23.355,
				"volume": 134747344,
				"time": 1462852800000,
				"lowest": 23.0275,
				"highest": 23.3925,
				"volumePrice": 23.2487,
				"transactionCount": 194656,
				"voluemPrice": 23.2487
			},
			{
				"open": 23.37,
				"close": 23.1275,
				"volume": 114876436,
				"time": 1462939200000,
				"lowest": 23.115,
				"highest": 23.3925,
				"volumePrice": 23.2267,
				"transactionCount": 167020,
				"voluemPrice": 23.2267
			},
			{
				"open": 23.18,
				"close": 22.585,
				"volume": 305258760,
				"time": 1463025600000,
				"lowest": 22.3675,
				"highest": 23.195,
				"volumePrice": 22.627,
				"transactionCount": 432877,
				"voluemPrice": 22.627
			},
			{
				"open": 22.5,
				"close": 22.63,
				"volume": 177570952,
				"time": 1463112000000,
				"lowest": 22.5,
				"highest": 22.9175,
				"volumePrice": 22.6993,
				"transactionCount": 248037,
				"voluemPrice": 22.6993
			},
			{
				"open": 23.0975,
				"close": 23.47,
				"volume": 245039024,
				"time": 1463371200000,
				"lowest": 22.9125,
				"highest": 23.5975,
				"volumePrice": 23.3561,
				"transactionCount": 341761,
				"voluemPrice": 23.3561
			},
			{
				"open": 23.6375,
				"close": 23.3725,
				"volume": 187667756,
				"time": 1463457600000,
				"lowest": 23.2525,
				"highest": 23.675,
				"volumePrice": 23.433,
				"transactionCount": 264259,
				"voluemPrice": 23.433
			},
			{
				"open": 23.54,
				"close": 23.64,
				"volume": 168248772,
				"time": 1463544000000,
				"lowest": 23.4725,
				"highest": 23.8025,
				"volumePrice": 23.6435,
				"transactionCount": 241549,
				"voluemPrice": 23.6435
			},
			{
				"open": 23.66,
				"close": 23.55,
				"volume": 121768400,
				"time": 1463630400000,
				"lowest": 23.3925,
				"highest": 23.66,
				"volumePrice": 23.49,
				"transactionCount": 174529,
				"voluemPrice": 23.49
			},
			{
				"open": 23.66,
				"close": 23.805,
				"volume": 128103872,
				"time": 1463716800000,
				"lowest": 23.63,
				"highest": 23.8575,
				"volumePrice": 23.7614,
				"transactionCount": 171169,
				"voluemPrice": 23.7614
			},
			{
				"open": 23.9675,
				"close": 24.1075,
				"volume": 151982816,
				"time": 1463976000000,
				"lowest": 23.9175,
				"highest": 24.2975,
				"volumePrice": 24.1617,
				"transactionCount": 205675,
				"voluemPrice": 24.1617
			},
			{
				"open": 24.305,
				"close": 24.475,
				"volume": 140560696,
				"time": 1464062400000,
				"lowest": 24.21,
				"highest": 24.5225,
				"volumePrice": 24.4013,
				"transactionCount": 199216,
				"voluemPrice": 24.4013
			},
			{
				"open": 24.6675,
				"close": 24.905,
				"volume": 154568388,
				"time": 1464148800000,
				"lowest": 24.5275,
				"highest": 24.935,
				"volumePrice": 24.7834,
				"transactionCount": 214584,
				"voluemPrice": 24.7834
			},
			{
				"open": 24.92,
				"close": 25.1025,
				"volume": 225308636,
				"time": 1464235200000,
				"lowest": 24.66,
				"highest": 25.1825,
				"volumePrice": 24.9055,
				"transactionCount": 211636,
				"voluemPrice": 24.9055
			},
			{
				"open": 24.86,
				"close": 25.0875,
				"volume": 145364960,
				"time": 1464321600000,
				"lowest": 24.8113,
				"highest": 25.1175,
				"volumePrice": 24.989,
				"transactionCount": 186142,
				"voluemPrice": 24.989
			},
			{
				"open": 24.9,
				"close": 24.965,
				"volume": 169228788,
				"time": 1464667200000,
				"lowest": 24.705,
				"highest": 25.1,
				"volumePrice": 24.9371,
				"transactionCount": 198662,
				"voluemPrice": 24.9371
			},
			{
				"open": 24.755,
				"close": 24.615,
				"volume": 116693140,
				"time": 1464753600000,
				"lowest": 24.5825,
				"highest": 24.885,
				"volumePrice": 24.7475,
				"transactionCount": 168974,
				"voluemPrice": 24.7475
			},
			{
				"open": 24.4,
				"close": 24.43,
				"volume": 160766120,
				"time": 1464840000000,
				"lowest": 24.1575,
				"highest": 24.46,
				"volumePrice": 24.3395,
				"transactionCount": 221940,
				"voluemPrice": 24.3395
			},
			{
				"open": 24.4475,
				"close": 24.48,
				"volume": 114019548,
				"time": 1464926400000,
				"lowest": 24.3625,
				"highest": 24.5675,
				"volumePrice": 24.4758,
				"transactionCount": 159073,
				"voluemPrice": 24.4758
			},
			{
				"open": 24.4975,
				"close": 24.6575,
				"volume": 93170016,
				"time": 1465185600000,
				"lowest": 24.3875,
				"highest": 25.4725,
				"volumePrice": 24.6824,
				"transactionCount": 138140,
				"voluemPrice": 24.6824
			},
			{
				"open": 24.8125,
				"close": 24.7575,
				"volume": 89637800,
				"time": 1465272000000,
				"lowest": 24.74,
				"highest": 24.9675,
				"volumePrice": 24.8426,
				"transactionCount": 130380,
				"voluemPrice": 24.8426
			},
			{
				"open": 24.755,
				"close": 24.735,
				"volume": 83392524,
				"time": 1465358400000,
				"lowest": 24.67,
				"highest": 24.89,
				"volumePrice": 24.7549,
				"transactionCount": 120751,
				"voluemPrice": 24.7549
			},
			{
				"open": 24.625,
				"close": 24.9125,
				"volume": 106405416,
				"time": 1465444800000,
				"lowest": 24.615,
				"highest": 24.9975,
				"volumePrice": 24.8953,
				"transactionCount": 159312,
				"voluemPrice": 24.8953
			},
			{
				"open": 24.6325,
				"close": 24.7075,
				"volume": 126851744,
				"time": 1465531200000,
				"lowest": 24.62,
				"highest": 24.8364,
				"volumePrice": 24.7271,
				"transactionCount": 175475,
				"voluemPrice": 24.7271
			},
			{
				"open": 24.6725,
				"close": 24.335,
				"volume": 152081816,
				"time": 1465790400000,
				"lowest": 24.275,
				"highest": 24.78,
				"volumePrice": 24.4055,
				"transactionCount": 225983,
				"voluemPrice": 24.4055
			},
			{
				"open": 24.33,
				"close": 24.365,
				"volume": 127727256,
				"time": 1465876800000,
				"lowest": 24.1875,
				"highest": 24.6188,
				"volumePrice": 24.3573,
				"transactionCount": 196928,
				"voluemPrice": 24.3573
			},
			{
				"open": 24.455,
				"close": 24.285,
				"volume": 117780908,
				"time": 1465963200000,
				"lowest": 24.2575,
				"highest": 24.6025,
				"volumePrice": 24.452,
				"transactionCount": 168201,
				"voluemPrice": 24.452
			},
			{
				"open": 24.1125,
				"close": 24.3875,
				"volume": 124395660,
				"time": 1466049600000,
				"lowest": 24.0175,
				"highest": 24.4375,
				"volumePrice": 24.226,
				"transactionCount": 190545,
				"voluemPrice": 24.226
			},
			{
				"open": 24.155,
				"close": 23.8325,
				"volume": 244032072,
				"time": 1466136000000,
				"lowest": 23.825,
				"highest": 24.1625,
				"volumePrice": 23.9415,
				"transactionCount": 254396,
				"voluemPrice": 23.9415
			},
			{
				"open": 24,
				"close": 23.775,
				"volume": 137647604,
				"time": 1466395200000,
				"lowest": 23.7575,
				"highest": 24.1425,
				"volumePrice": 23.9742,
				"transactionCount": 200093,
				"voluemPrice": 23.9742
			},
			{
				"open": 23.735,
				"close": 23.9775,
				"volume": 142185432,
				"time": 1466481600000,
				"lowest": 23.669,
				"highest": 24.0875,
				"volumePrice": 23.9904,
				"transactionCount": 203067,
				"voluemPrice": 23.9904
			},
			{
				"open": 24.0625,
				"close": 23.8875,
				"volume": 116802080,
				"time": 1466568000000,
				"lowest": 23.8375,
				"highest": 24.2225,
				"volumePrice": 24.0112,
				"transactionCount": 181346,
				"voluemPrice": 24.0112
			},
			{
				"open": 23.985,
				"close": 24.025,
				"volume": 128960748,
				"time": 1466654400000,
				"lowest": 23.8125,
				"highest": 24.0725,
				"volumePrice": 23.9397,
				"transactionCount": 184291,
				"voluemPrice": 23.9397
			},
			{
				"open": 23.2275,
				"close": 23.35,
				"volume": 301249892,
				"time": 1466740800000,
				"lowest": 23.1625,
				"highest": 23.6638,
				"volumePrice": 23.3942,
				"transactionCount": 379479,
				"voluemPrice": 23.3942
			},
			{
				"open": 23.25,
				"close": 23.01,
				"volume": 186485872,
				"time": 1467000000000,
				"lowest": 22.875,
				"highest": 23.2625,
				"volumePrice": 23.0018,
				"transactionCount": 289998,
				"voluemPrice": 23.0018
			},
			{
				"open": 23.225,
				"close": 23.3975,
				"volume": 161779256,
				"time": 1467086400000,
				"lowest": 23.035,
				"highest": 23.415,
				"volumePrice": 23.2304,
				"transactionCount": 240677,
				"voluemPrice": 23.2304
			},
			{
				"open": 23.4925,
				"close": 23.6,
				"volume": 146124024,
				"time": 1467172800000,
				"lowest": 23.4075,
				"highest": 23.6375,
				"volumePrice": 23.5618,
				"transactionCount": 216403,
				"voluemPrice": 23.5618
			},
			{
				"open": 23.61,
				"close": 23.9,
				"volume": 143345416,
				"time": 1467259200000,
				"lowest": 23.575,
				"highest": 23.9425,
				"volumePrice": 23.8182,
				"transactionCount": 204308,
				"voluemPrice": 23.8182
			},
			{
				"open": 23.8725,
				"close": 23.9725,
				"volume": 102610160,
				"time": 1467345600000,
				"lowest": 23.8325,
				"highest": 24.1163,
				"volumePrice": 23.9999,
				"transactionCount": 154605,
				"voluemPrice": 23.9999
			},
			{
				"open": 23.8475,
				"close": 23.7475,
				"volume": 110820460,
				"time": 1467691200000,
				"lowest": 23.615,
				"highest": 23.85,
				"volumePrice": 23.7083,
				"transactionCount": 153252,
				"voluemPrice": 23.7083
			},
			{
				"open": 23.65,
				"close": 23.8825,
				"volume": 123796360,
				"time": 1467777600000,
				"lowest": 23.5925,
				"highest": 23.915,
				"volumePrice": 23.7886,
				"transactionCount": 187587,
				"voluemPrice": 23.7886
			},
			{
				"open": 23.925,
				"close": 23.985,
				"volume": 100505652,
				"time": 1467864000000,
				"lowest": 23.905,
				"highest": 24.125,
				"volumePrice": 24.0133,
				"transactionCount": 143921,
				"voluemPrice": 24.0133
			},
			{
				"open": 24.1225,
				"close": 24.17,
				"volume": 115648412,
				"time": 1467950400000,
				"lowest": 24.0125,
				"highest": 24.2225,
				"volumePrice": 24.1583,
				"transactionCount": 168666,
				"voluemPrice": 24.1583
			},
			{
				"open": 24.1875,
				"close": 24.245,
				"volume": 95179380,
				"time": 1468209600000,
				"lowest": 24.185,
				"highest": 24.4125,
				"volumePrice": 24.3091,
				"transactionCount": 144019,
				"voluemPrice": 24.3091
			},
			{
				"open": 24.2925,
				"close": 24.355,
				"volume": 96669852,
				"time": 1468296000000,
				"lowest": 24.28,
				"highest": 24.425,
				"volumePrice": 24.3514,
				"transactionCount": 151231,
				"voluemPrice": 24.3514
			},
			{
				"open": 24.3525,
				"close": 24.2175,
				"volume": 103568684,
				"time": 1468382400000,
				"lowest": 24.21,
				"highest": 24.4175,
				"volumePrice": 24.2856,
				"transactionCount": 144843,
				"voluemPrice": 24.2856
			},
			{
				"open": 24.3475,
				"close": 24.6975,
				"volume": 155675988,
				"time": 1468468800000,
				"lowest": 24.33,
				"highest": 24.7475,
				"volumePrice": 24.5979,
				"transactionCount": 231500,
				"voluemPrice": 24.5979
			},
			{
				"open": 24.73,
				"close": 24.695,
				"volume": 120547960,
				"time": 1468555200000,
				"lowest": 24.625,
				"highest": 24.825,
				"volumePrice": 24.7148,
				"transactionCount": 157724,
				"voluemPrice": 24.7148
			},
			{
				"open": 24.675,
				"close": 24.9575,
				"volume": 145937368,
				"time": 1468814400000,
				"lowest": 24.65,
				"highest": 25.0325,
				"volumePrice": 24.9457,
				"transactionCount": 200050,
				"voluemPrice": 24.9457
			},
			{
				"open": 24.89,
				"close": 24.9675,
				"volume": 95119056,
				"time": 1468900800000,
				"lowest": 24.835,
				"highest": 25,
				"volumePrice": 24.9499,
				"transactionCount": 134939,
				"voluemPrice": 24.9499
			},
			{
				"open": 25,
				"close": 24.99,
				"volume": 105103872,
				"time": 1468987200000,
				"lowest": 24.9338,
				"highest": 25.115,
				"volumePrice": 24.9968,
				"transactionCount": 146679,
				"voluemPrice": 24.9968
			},
			{
				"open": 24.9575,
				"close": 24.8575,
				"volume": 130808112,
				"time": 1469073600000,
				"lowest": 24.7825,
				"highest": 25.25,
				"volumePrice": 24.9761,
				"transactionCount": 169896,
				"voluemPrice": 24.9761
			},
			{
				"open": 24.815,
				"close": 24.665,
				"volume": 113121076,
				"time": 1469160000000,
				"lowest": 24.5775,
				"highest": 24.825,
				"volumePrice": 24.6738,
				"transactionCount": 153515,
				"voluemPrice": 24.6738
			},
			{
				"open": 24.5625,
				"close": 24.335,
				"volume": 161529684,
				"time": 1469419200000,
				"lowest": 24.23,
				"highest": 24.71,
				"volumePrice": 24.4153,
				"transactionCount": 224242,
				"voluemPrice": 24.4153
			},
			{
				"open": 24.205,
				"close": 24.1675,
				"volume": 224959288,
				"time": 1469505600000,
				"lowest": 24.105,
				"highest": 24.4925,
				"volumePrice": 24.5677,
				"transactionCount": 289092,
				"voluemPrice": 24.5677
			},
			{
				"open": 26.0663,
				"close": 25.7375,
				"volume": 369378760,
				"time": 1469592000000,
				"lowest": 25.6875,
				"highest": 26.0875,
				"volumePrice": 25.8852,
				"transactionCount": 484303,
				"voluemPrice": 25.8852
			},
			{
				"open": 25.7075,
				"close": 26.085,
				"volume": 159379356,
				"time": 1469678400000,
				"lowest": 25.705,
				"highest": 26.1125,
				"volumePrice": 25.9797,
				"transactionCount": 216360,
				"voluemPrice": 25.9797
			},
			{
				"open": 26.0475,
				"close": 26.0525,
				"volume": 110845052,
				"time": 1469764800000,
				"lowest": 25.92,
				"highest": 26.1375,
				"volumePrice": 26.0299,
				"transactionCount": 152936,
				"voluemPrice": 26.0299
			},
			{
				"open": 26.1025,
				"close": 26.5125,
				"volume": 152671484,
				"time": 1470024000000,
				"lowest": 26.1025,
				"highest": 26.5375,
				"volumePrice": 26.3545,
				"transactionCount": 212308,
				"voluemPrice": 26.3545
			},
			{
				"open": 26.5125,
				"close": 26.12,
				"volume": 135263824,
				"time": 1470110400000,
				"lowest": 26,
				"highest": 26.5175,
				"volumePrice": 26.1926,
				"transactionCount": 199996,
				"voluemPrice": 26.1926
			},
			{
				"open": 26.2025,
				"close": 26.4475,
				"volume": 120810564,
				"time": 1470196800000,
				"lowest": 26.1925,
				"highest": 26.46,
				"volumePrice": 26.3927,
				"transactionCount": 168032,
				"voluemPrice": 26.3927
			},
			{
				"open": 26.395,
				"close": 26.4675,
				"volume": 109634600,
				"time": 1470283200000,
				"lowest": 26.32,
				"highest": 26.5,
				"volumePrice": 26.432,
				"transactionCount": 147500,
				"voluemPrice": 26.432
			},
			{
				"open": 26.5675,
				"close": 26.87,
				"volume": 162213608,
				"time": 1470369600000,
				"lowest": 26.545,
				"highest": 26.9125,
				"volumePrice": 26.8327,
				"transactionCount": 182161,
				"voluemPrice": 26.8327
			},
			{
				"open": 26.88,
				"close": 27.0925,
				"volume": 112148880,
				"time": 1470628800000,
				"lowest": 26.79,
				"highest": 27.0925,
				"volumePrice": 26.9802,
				"transactionCount": 143108,
				"voluemPrice": 26.9802
			},
			{
				"open": 27.0575,
				"close": 27.2025,
				"volume": 105260576,
				"time": 1470715200000,
				"lowest": 27.0025,
				"highest": 27.235,
				"volumePrice": 27.1243,
				"transactionCount": 154391,
				"voluemPrice": 27.1243
			},
			{
				"open": 27.1775,
				"close": 27,
				"volume": 96034020,
				"time": 1470801600000,
				"lowest": 26.94,
				"highest": 27.225,
				"volumePrice": 27.0399,
				"transactionCount": 133573,
				"voluemPrice": 27.0399
			},
			{
				"open": 27.13,
				"close": 26.9825,
				"volume": 109938024,
				"time": 1470888000000,
				"lowest": 26.9625,
				"highest": 27.2325,
				"volumePrice": 27.0838,
				"transactionCount": 128934,
				"voluemPrice": 27.0838
			},
			{
				"open": 26.945,
				"close": 27.045,
				"volume": 74640940,
				"time": 1470974400000,
				"lowest": 26.945,
				"highest": 27.11,
				"volumePrice": 27.032,
				"transactionCount": 114633,
				"voluemPrice": 27.032
			},
			{
				"open": 27.035,
				"close": 27.37,
				"volume": 103472836,
				"time": 1471233600000,
				"lowest": 27.02,
				"highest": 27.385,
				"volumePrice": 27.3113,
				"transactionCount": 146986,
				"voluemPrice": 27.3113
			},
			{
				"open": 27.4075,
				"close": 27.345,
				"volume": 135177656,
				"time": 1471320000000,
				"lowest": 27.3025,
				"highest": 27.5575,
				"volumePrice": 27.4392,
				"transactionCount": 178821,
				"voluemPrice": 27.4392
			},
			{
				"open": 27.275,
				"close": 27.305,
				"volume": 101423904,
				"time": 1471406400000,
				"lowest": 27.085,
				"highest": 27.3425,
				"volumePrice": 27.2287,
				"transactionCount": 155181,
				"voluemPrice": 27.2287
			},
			{
				"open": 27.3075,
				"close": 27.27,
				"volume": 87938812,
				"time": 1471492800000,
				"lowest": 27.255,
				"highest": 27.4,
				"volumePrice": 27.3211,
				"transactionCount": 120957,
				"voluemPrice": 27.3211
			},
			{
				"open": 27.1925,
				"close": 27.34,
				"volume": 101472288,
				"time": 1471579200000,
				"lowest": 27.09,
				"highest": 27.4225,
				"volumePrice": 27.2871,
				"transactionCount": 146693,
				"voluemPrice": 27.2871
			},
			{
				"open": 27.215,
				"close": 27.1275,
				"volume": 103280920,
				"time": 1471838400000,
				"lowest": 26.9625,
				"highest": 27.275,
				"volumePrice": 27.1201,
				"transactionCount": 159760,
				"voluemPrice": 27.1201
			},
			{
				"open": 27.1475,
				"close": 27.2125,
				"volume": 85030676,
				"time": 1471924800000,
				"lowest": 27.1325,
				"highest": 27.33,
				"volumePrice": 27.246,
				"transactionCount": 125049,
				"voluemPrice": 27.246
			},
			{
				"open": 27.1413,
				"close": 27.0075,
				"volume": 94700324,
				"time": 1472011200000,
				"lowest": 26.92,
				"highest": 27.1875,
				"volumePrice": 27.0349,
				"transactionCount": 140592,
				"voluemPrice": 27.0349
			},
			{
				"open": 26.8475,
				"close": 26.8925,
				"volume": 100344992,
				"time": 1472097600000,
				"lowest": 26.67,
				"highest": 26.97,
				"volumePrice": 26.8249,
				"transactionCount": 159112,
				"voluemPrice": 26.8249
			},
			{
				"open": 26.8525,
				"close": 26.735,
				"volume": 111065164,
				"time": 1472184000000,
				"lowest": 26.5775,
				"highest": 26.9875,
				"volumePrice": 26.7772,
				"transactionCount": 181353,
				"voluemPrice": 26.7772
			},
			{
				"open": 26.655,
				"close": 26.705,
				"volume": 99881200,
				"time": 1472443200000,
				"lowest": 26.5725,
				"highest": 26.86,
				"volumePrice": 26.7198,
				"transactionCount": 146774,
				"voluemPrice": 26.7198
			},
			{
				"open": 26.45,
				"close": 26.5,
				"volume": 99455780,
				"time": 1472529600000,
				"lowest": 26.375,
				"highest": 26.625,
				"volumePrice": 26.4898,
				"transactionCount": 154474,
				"voluemPrice": 26.4898
			},
			{
				"open": 26.415,
				"close": 26.525,
				"volume": 118649384,
				"time": 1472616000000,
				"lowest": 26.41,
				"highest": 26.6425,
				"volumePrice": 26.5528,
				"transactionCount": 152274,
				"voluemPrice": 26.5528
			},
			{
				"open": 26.535,
				"close": 26.6825,
				"volume": 106806092,
				"time": 1472702400000,
				"lowest": 26.405,
				"highest": 26.7,
				"volumePrice": 26.5705,
				"transactionCount": 151070,
				"voluemPrice": 26.5705
			},
			{
				"open": 26.925,
				"close": 26.9325,
				"volume": 107209800,
				"time": 1472788800000,
				"lowest": 26.705,
				"highest": 27,
				"volumePrice": 26.8636,
				"transactionCount": 149198,
				"voluemPrice": 26.8636
			},
			{
				"open": 26.975,
				"close": 26.925,
				"volume": 107521564,
				"time": 1473134400000,
				"lowest": 26.8775,
				"highest": 27.075,
				"volumePrice": 26.9485,
				"transactionCount": 136403,
				"voluemPrice": 26.9485
			},
			{
				"open": 26.9575,
				"close": 27.09,
				"volume": 169457232,
				"time": 1473220800000,
				"lowest": 26.7675,
				"highest": 27.19,
				"volumePrice": 26.947,
				"transactionCount": 240163,
				"voluemPrice": 26.947
			},
			{
				"open": 26.8125,
				"close": 26.38,
				"volume": 212008104,
				"time": 1473307200000,
				"lowest": 26.31,
				"highest": 26.8175,
				"volumePrice": 26.4767,
				"transactionCount": 284024,
				"voluemPrice": 26.4767
			},
			{
				"open": 26.16,
				"close": 25.7825,
				"volume": 186227936,
				"time": 1473393600000,
				"lowest": 25.7825,
				"highest": 26.43,
				"volumePrice": 26.0156,
				"transactionCount": 275698,
				"voluemPrice": 26.0156
			},
			{
				"open": 25.6625,
				"close": 26.36,
				"volume": 181171080,
				"time": 1473652800000,
				"lowest": 25.6325,
				"highest": 26.43,
				"volumePrice": 26.1762,
				"transactionCount": 259269,
				"voluemPrice": 26.1762
			},
			{
				"open": 26.8775,
				"close": 26.9875,
				"volume": 248704760,
				"time": 1473739200000,
				"lowest": 26.81,
				"highest": 27.1975,
				"volumePrice": 27.0196,
				"transactionCount": 337192,
				"voluemPrice": 27.0196
			},
			{
				"open": 27.1825,
				"close": 27.9425,
				"volume": 449361272,
				"time": 1473825600000,
				"lowest": 27.15,
				"highest": 28.2575,
				"volumePrice": 27.8908,
				"transactionCount": 580554,
				"voluemPrice": 27.8908
			},
			{
				"open": 28.465,
				"close": 28.8925,
				"volume": 362452708,
				"time": 1473912000000,
				"lowest": 28.3725,
				"highest": 28.9325,
				"volumePrice": 28.735,
				"transactionCount": 461585,
				"voluemPrice": 28.735
			},
			{
				"open": 28.78,
				"close": 28.73,
				"volume": 319547644,
				"time": 1473998400000,
				"lowest": 28.51,
				"highest": 29.0325,
				"volumePrice": 28.7475,
				"transactionCount": 316028,
				"voluemPrice": 28.7475
			},
			{
				"open": 28.7975,
				"close": 28.395,
				"volume": 188092184,
				"time": 1474257600000,
				"lowest": 28.3125,
				"highest": 29.045,
				"volumePrice": 28.5745,
				"transactionCount": 242802,
				"voluemPrice": 28.5745
			},
			{
				"open": 28.2625,
				"close": 28.3925,
				"volume": 138055476,
				"time": 1474344000000,
				"lowest": 28.1275,
				"highest": 28.53,
				"volumePrice": 28.3919,
				"transactionCount": 184347,
				"voluemPrice": 28.3919
			},
			{
				"open": 28.4625,
				"close": 28.3875,
				"volume": 144012740,
				"time": 1474430400000,
				"lowest": 28.1103,
				"highest": 28.4973,
				"volumePrice": 28.2998,
				"transactionCount": 186221,
				"voluemPrice": 28.2998
			},
			{
				"open": 28.5875,
				"close": 28.655,
				"volume": 124295656,
				"time": 1474516800000,
				"lowest": 28.5,
				"highest": 28.735,
				"volumePrice": 28.6457,
				"transactionCount": 169947,
				"voluemPrice": 28.6457
			},
			{
				"open": 28.605,
				"close": 28.1775,
				"volume": 209924604,
				"time": 1474603200000,
				"lowest": 27.8875,
				"highest": 28.6975,
				"volumePrice": 28.2866,
				"transactionCount": 283295,
				"voluemPrice": 28.2866
			},
			{
				"open": 27.91,
				"close": 28.22,
				"volume": 119451768,
				"time": 1474862400000,
				"lowest": 27.8875,
				"highest": 28.3475,
				"volumePrice": 28.2122,
				"transactionCount": 167139,
				"voluemPrice": 28.2122
			},
			{
				"open": 28.25,
				"close": 28.2725,
				"volume": 98429648,
				"time": 1474948800000,
				"lowest": 28.085,
				"highest": 28.295,
				"volumePrice": 28.2172,
				"transactionCount": 138614,
				"voluemPrice": 28.2172
			},
			{
				"open": 28.4225,
				"close": 28.4875,
				"volume": 118564340,
				"time": 1475035200000,
				"lowest": 28.3575,
				"highest": 28.66,
				"volumePrice": 28.4988,
				"transactionCount": 166491,
				"voluemPrice": 28.4988
			},
			{
				"open": 28.29,
				"close": 28.045,
				"volume": 143547960,
				"time": 1475121600000,
				"lowest": 27.95,
				"highest": 28.45,
				"volumePrice": 28.1273,
				"transactionCount": 211659,
				"voluemPrice": 28.1273
			},
			{
				"open": 28.115,
				"close": 28.2625,
				"volume": 145516424,
				"time": 1475208000000,
				"lowest": 27.95,
				"highest": 28.3425,
				"volumePrice": 28.2174,
				"transactionCount": 184106,
				"voluemPrice": 28.2174
			},
			{
				"open": 28.1775,
				"close": 28.13,
				"volume": 86806864,
				"time": 1475467200000,
				"lowest": 28.07,
				"highest": 28.2625,
				"volumePrice": 28.1487,
				"transactionCount": 131075,
				"voluemPrice": 28.1487
			},
			{
				"open": 28.265,
				"close": 28.25,
				"volume": 118923740,
				"time": 1475553600000,
				"lowest": 28.1575,
				"highest": 28.5775,
				"volumePrice": 28.3685,
				"transactionCount": 173328,
				"voluemPrice": 28.3685
			},
			{
				"open": 28.35,
				"close": 28.2625,
				"volume": 85812356,
				"time": 1475640000000,
				"lowest": 28.1725,
				"highest": 28.415,
				"volumePrice": 28.2757,
				"transactionCount": 127356,
				"voluemPrice": 28.2757
			},
			{
				"open": 28.425,
				"close": 28.4725,
				"volume": 115116796,
				"time": 1475726400000,
				"lowest": 28.2825,
				"highest": 28.585,
				"volumePrice": 28.4668,
				"transactionCount": 168292,
				"voluemPrice": 28.4668
			},
			{
				"open": 28.5775,
				"close": 28.515,
				"volume": 97433772,
				"time": 1475812800000,
				"lowest": 28.3775,
				"highest": 28.64,
				"volumePrice": 28.52,
				"transactionCount": 134890,
				"voluemPrice": 28.52
			},
			{
				"open": 28.755,
				"close": 29.0125,
				"volume": 144223624,
				"time": 1476072000000,
				"lowest": 28.68,
				"highest": 29.1875,
				"volumePrice": 29.0142,
				"transactionCount": 196558,
				"voluemPrice": 29.0142
			},
			{
				"open": 29.425,
				"close": 29.075,
				"volume": 255654172,
				"time": 1476158400000,
				"lowest": 29.05,
				"highest": 29.6725,
				"volumePrice": 29.315,
				"transactionCount": 322691,
				"voluemPrice": 29.315
			},
			{
				"open": 29.3375,
				"close": 29.335,
				"volume": 150347148,
				"time": 1476244800000,
				"lowest": 29.1875,
				"highest": 29.495,
				"volumePrice": 29.3446,
				"transactionCount": 203123,
				"voluemPrice": 29.3446
			},
			{
				"open": 29.1975,
				"close": 29.245,
				"volume": 140769624,
				"time": 1476331200000,
				"lowest": 28.93,
				"highest": 29.36,
				"volumePrice": 29.1521,
				"transactionCount": 201253,
				"voluemPrice": 29.1521
			},
			{
				"open": 29.47,
				"close": 29.4075,
				"volume": 142601164,
				"time": 1476417600000,
				"lowest": 29.2825,
				"highest": 29.5425,
				"volumePrice": 29.403,
				"transactionCount": 182548,
				"voluemPrice": 29.403
			},
			{
				"open": 29.3325,
				"close": 29.3875,
				"volume": 94499584,
				"time": 1476676800000,
				"lowest": 29.195,
				"highest": 29.46,
				"volumePrice": 29.313,
				"transactionCount": 135059,
				"voluemPrice": 29.313
			},
			{
				"open": 29.545,
				"close": 29.3675,
				"volume": 98213912,
				"time": 1476763200000,
				"lowest": 29.3625,
				"highest": 29.5525,
				"volumePrice": 29.4645,
				"transactionCount": 133015,
				"voluemPrice": 29.4645
			},
			{
				"open": 29.3125,
				"close": 29.28,
				"volume": 80122376,
				"time": 1476849600000,
				"lowest": 28.45,
				"highest": 29.44,
				"volumePrice": 29.3234,
				"transactionCount": 124176,
				"voluemPrice": 29.3234
			},
			{
				"open": 29.215,
				"close": 29.265,
				"volume": 96502484,
				"time": 1476936000000,
				"lowest": 29.0825,
				"highest": 29.345,
				"volumePrice": 29.2393,
				"transactionCount": 150406,
				"voluemPrice": 29.2393
			},
			{
				"open": 29.2025,
				"close": 29.15,
				"volume": 92770660,
				"time": 1477022400000,
				"lowest": 29.07,
				"highest": 29.2275,
				"volumePrice": 29.152,
				"transactionCount": 122047,
				"voluemPrice": 29.152
			},
			{
				"open": 29.275,
				"close": 29.4125,
				"volume": 94154692,
				"time": 1477281600000,
				"lowest": 29.25,
				"highest": 29.435,
				"volumePrice": 29.3569,
				"transactionCount": 130477,
				"voluemPrice": 29.3569
			},
			{
				"open": 29.4875,
				"close": 29.5625,
				"volume": 192515880,
				"time": 1477368000000,
				"lowest": 29.3275,
				"highest": 29.59,
				"volumePrice": 29.4083,
				"transactionCount": 257396,
				"voluemPrice": 29.4083
			},
			{
				"open": 28.5775,
				"close": 28.8975,
				"volume": 264536876,
				"time": 1477454400000,
				"lowest": 28.3275,
				"highest": 28.925,
				"volumePrice": 28.6649,
				"transactionCount": 348946,
				"voluemPrice": 28.6649
			},
			{
				"open": 28.8475,
				"close": 28.62,
				"volume": 138248180,
				"time": 1477540800000,
				"lowest": 28.525,
				"highest": 28.965,
				"volumePrice": 28.7019,
				"transactionCount": 153097,
				"voluemPrice": 28.7019
			},
			{
				"open": 28.4675,
				"close": 28.43,
				"volume": 151446628,
				"time": 1477627200000,
				"lowest": 28.3625,
				"highest": 28.8025,
				"volumePrice": 28.552,
				"transactionCount": 207752,
				"voluemPrice": 28.552
			},
			{
				"open": 28.4125,
				"close": 28.385,
				"volume": 105677592,
				"time": 1477886400000,
				"lowest": 28.3,
				"highest": 28.5575,
				"volumePrice": 28.4188,
				"transactionCount": 137491,
				"voluemPrice": 28.4188
			},
			{
				"open": 28.365,
				"close": 27.8725,
				"volume": 175303248,
				"time": 1477972800000,
				"lowest": 27.6325,
				"highest": 28.4425,
				"volumePrice": 27.9438,
				"transactionCount": 240669,
				"voluemPrice": 27.9438
			},
			{
				"open": 27.85,
				"close": 27.8975,
				"volume": 113326836,
				"time": 1478059200000,
				"lowest": 27.8075,
				"highest": 28.0875,
				"volumePrice": 27.9623,
				"transactionCount": 165457,
				"voluemPrice": 27.9623
			},
			{
				"open": 27.745,
				"close": 27.4575,
				"volume": 107730408,
				"time": 1478145600000,
				"lowest": 27.3875,
				"highest": 27.865,
				"volumePrice": 27.5608,
				"transactionCount": 158176,
				"voluemPrice": 27.5608
			},
			{
				"open": 27.1325,
				"close": 27.21,
				"volume": 123347988,
				"time": 1478232000000,
				"lowest": 27.0275,
				"highest": 27.5625,
				"volumePrice": 27.3101,
				"transactionCount": 177293,
				"voluemPrice": 27.3101
			},
			{
				"open": 27.52,
				"close": 27.6025,
				"volume": 130240000,
				"time": 1478494800000,
				"lowest": 27.365,
				"highest": 27.6275,
				"volumePrice": 27.5206,
				"transactionCount": 176597,
				"voluemPrice": 27.5206
			},
			{
				"open": 27.5775,
				"close": 27.765,
				"volume": 97016716,
				"time": 1478581200000,
				"lowest": 27.425,
				"highest": 27.93,
				"volumePrice": 27.7045,
				"transactionCount": 145062,
				"voluemPrice": 27.7045
			},
			{
				"open": 27.47,
				"close": 27.72,
				"volume": 236705444,
				"time": 1478667600000,
				"lowest": 27.0125,
				"highest": 27.83,
				"volumePrice": 27.4388,
				"transactionCount": 349045,
				"voluemPrice": 27.4388
			},
			{
				"open": 27.7725,
				"close": 26.9475,
				"volume": 228402164,
				"time": 1478754000000,
				"lowest": 26.4575,
				"highest": 27.7725,
				"volumePrice": 27.0418,
				"transactionCount": 374398,
				"voluemPrice": 27.0418
			},
			{
				"open": 26.78,
				"close": 27.1075,
				"volume": 136575592,
				"time": 1478840400000,
				"lowest": 26.6375,
				"highest": 27.2175,
				"volumePrice": 27.0257,
				"transactionCount": 213710,
				"voluemPrice": 27.0257
			},
			{
				"open": 26.9275,
				"close": 26.4275,
				"volume": 204572016,
				"time": 1479099600000,
				"lowest": 26.02,
				"highest": 26.9523,
				"volumePrice": 26.3922,
				"transactionCount": 303631,
				"voluemPrice": 26.3922
			},
			{
				"open": 26.6425,
				"close": 26.7775,
				"volume": 128873240,
				"time": 1479186000000,
				"lowest": 26.5398,
				"highest": 26.92,
				"volumePrice": 26.7576,
				"transactionCount": 192013,
				"voluemPrice": 26.7576
			},
			{
				"open": 26.675,
				"close": 27.4975,
				"volume": 233962088,
				"time": 1479272400000,
				"lowest": 26.65,
				"highest": 27.5575,
				"volumePrice": 27.2917,
				"transactionCount": 226704,
				"voluemPrice": 27.2917
			},
			{
				"open": 27.4525,
				"close": 27.4875,
				"volume": 110528012,
				"time": 1479358800000,
				"lowest": 27.2075,
				"highest": 27.5875,
				"volumePrice": 27.396,
				"transactionCount": 169942,
				"voluemPrice": 27.396
			},
			{
				"open": 27.43,
				"close": 27.515,
				"volume": 113635668,
				"time": 1479445200000,
				"lowest": 27.415,
				"highest": 27.635,
				"volumePrice": 27.5013,
				"transactionCount": 145704,
				"voluemPrice": 27.5013
			},
			{
				"open": 27.53,
				"close": 27.9325,
				"volume": 117058284,
				"time": 1479704400000,
				"lowest": 27.5025,
				"highest": 27.9975,
				"volumePrice": 27.848,
				"transactionCount": 165483,
				"voluemPrice": 27.848
			},
			{
				"open": 27.9875,
				"close": 27.95,
				"volume": 103862136,
				"time": 1479790800000,
				"lowest": 27.85,
				"highest": 28.105,
				"volumePrice": 27.9597,
				"transactionCount": 131648,
				"voluemPrice": 27.9597
			},
			{
				"open": 27.84,
				"close": 27.8075,
				"volume": 109705576,
				"time": 1479877200000,
				"lowest": 27.5825,
				"highest": 27.8775,
				"volumePrice": 27.7445,
				"transactionCount": 144672,
				"voluemPrice": 27.7445
			},
			{
				"open": 27.7825,
				"close": 27.9475,
				"volume": 45902088,
				"time": 1480050000000,
				"lowest": 27.7375,
				"highest": 27.9675,
				"volumePrice": 27.8728,
				"transactionCount": 66302,
				"voluemPrice": 27.8728
			},
			{
				"open": 27.8575,
				"close": 27.8925,
				"volume": 108175932,
				"time": 1480309200000,
				"lowest": 27.8475,
				"highest": 28.1163,
				"volumePrice": 27.9459,
				"transactionCount": 135094,
				"voluemPrice": 27.9459
			},
			{
				"open": 27.695,
				"close": 27.865,
				"volume": 114115000,
				"time": 1480395600000,
				"lowest": 27.5175,
				"highest": 28.0075,
				"volumePrice": 27.7879,
				"transactionCount": 163352,
				"voluemPrice": 27.7879
			},
			{
				"open": 27.9,
				"close": 27.63,
				"volume": 144649032,
				"time": 1480482000000,
				"lowest": 27.5675,
				"highest": 28.05,
				"volumePrice": 27.7086,
				"transactionCount": 178893,
				"voluemPrice": 27.7086
			},
			{
				"open": 27.5913,
				"close": 27.3725,
				"volume": 148347448,
				"time": 1480568400000,
				"lowest": 27.2575,
				"highest": 27.735,
				"volumePrice": 27.4522,
				"transactionCount": 220992,
				"voluemPrice": 27.4522
			},
			{
				"open": 27.2925,
				"close": 27.475,
				"volume": 106111988,
				"time": 1480654800000,
				"lowest": 27.2125,
				"highest": 27.5225,
				"volumePrice": 27.4078,
				"transactionCount": 147420,
				"voluemPrice": 27.4078
			},
			{
				"open": 27.5,
				"close": 27.2775,
				"volume": 137298160,
				"time": 1480914000000,
				"lowest": 27.0625,
				"highest": 27.5075,
				"volumePrice": 27.2894,
				"transactionCount": 202227,
				"voluemPrice": 27.2894
			},
			{
				"open": 27.375,
				"close": 27.4875,
				"volume": 104781848,
				"time": 1481000400000,
				"lowest": 27.2975,
				"highest": 27.59,
				"volumePrice": 27.4317,
				"transactionCount": 156034,
				"voluemPrice": 27.4317
			},
			{
				"open": 27.315,
				"close": 27.7575,
				"volume": 119994876,
				"time": 1481086800000,
				"lowest": 27.29,
				"highest": 27.7975,
				"volumePrice": 27.5851,
				"transactionCount": 184164,
				"voluemPrice": 27.5851
			},
			{
				"open": 27.715,
				"close": 28.03,
				"volume": 108273264,
				"time": 1481173200000,
				"lowest": 27.65,
				"highest": 28.1075,
				"volumePrice": 27.9952,
				"transactionCount": 157684,
				"voluemPrice": 27.9952
			},
			{
				"open": 28.0775,
				"close": 28.4875,
				"volume": 137610508,
				"time": 1481259600000,
				"lowest": 28.0775,
				"highest": 28.675,
				"volumePrice": 28.4716,
				"transactionCount": 190213,
				"voluemPrice": 28.4716
			},
			{
				"open": 28.3225,
				"close": 28.325,
				"volume": 105497508,
				"time": 1481518800000,
				"lowest": 28.1225,
				"highest": 28.75,
				"volumePrice": 28.2457,
				"transactionCount": 161917,
				"voluemPrice": 28.2457
			},
			{
				"open": 28.46,
				"close": 28.7975,
				"volume": 174935244,
				"time": 1481605200000,
				"lowest": 28.4375,
				"highest": 28.98,
				"volumePrice": 28.8167,
				"transactionCount": 245487,
				"voluemPrice": 28.8167
			},
			{
				"open": 28.76,
				"close": 28.7975,
				"volume": 136127336,
				"time": 1481691600000,
				"lowest": 28.745,
				"highest": 29.05,
				"volumePrice": 28.8961,
				"transactionCount": 209026,
				"voluemPrice": 28.8961
			},
			{
				"open": 28.845,
				"close": 28.955,
				"volume": 181418176,
				"time": 1481778000000,
				"lowest": 28.8075,
				"highest": 29.1825,
				"volumePrice": 29.0218,
				"transactionCount": 190824,
				"voluemPrice": 29.0218
			},
			{
				"open": 29.1175,
				"close": 28.9925,
				"volume": 177404536,
				"time": 1481864400000,
				"lowest": 28.9113,
				"highest": 29.125,
				"volumePrice": 29.015,
				"transactionCount": 154131,
				"voluemPrice": 29.015
			},
			{
				"open": 28.95,
				"close": 29.16,
				"volume": 111117692,
				"time": 1482123600000,
				"lowest": 28.9375,
				"highest": 29.345,
				"volumePrice": 29.1928,
				"transactionCount": 136344,
				"voluemPrice": 29.1928
			},
			{
				"open": 29.185,
				"close": 29.2375,
				"volume": 85699860,
				"time": 1482210000000,
				"lowest": 29.17,
				"highest": 29.375,
				"volumePrice": 29.2528,
				"transactionCount": 109210,
				"voluemPrice": 29.2528
			},
			{
				"open": 29.2,
				"close": 29.265,
				"volume": 94332660,
				"time": 1482296400000,
				"lowest": 29.195,
				"highest": 29.35,
				"volumePrice": 29.2688,
				"transactionCount": 109902,
				"voluemPrice": 29.2688
			},
			{
				"open": 29.0875,
				"close": 29.0725,
				"volume": 104343416,
				"time": 1482382800000,
				"lowest": 28.91,
				"highest": 29.1275,
				"volumePrice": 29.0273,
				"transactionCount": 131198,
				"voluemPrice": 29.0273
			},
			{
				"open": 28.8975,
				"close": 29.13,
				"volume": 56997936,
				"time": 1482469200000,
				"lowest": 28.8975,
				"highest": 29.13,
				"volumePrice": 29.0789,
				"transactionCount": 83708,
				"voluemPrice": 29.0789
			},
			{
				"open": 29.13,
				"close": 29.315,
				"volume": 73187420,
				"time": 1482814800000,
				"lowest": 29.1225,
				"highest": 29.45,
				"volumePrice": 29.3584,
				"transactionCount": 109160,
				"voluemPrice": 29.3584
			},
			{
				"open": 29.38,
				"close": 29.19,
				"volume": 83623568,
				"time": 1482901200000,
				"lowest": 29.05,
				"highest": 29.5042,
				"volumePrice": 29.2706,
				"transactionCount": 122797,
				"voluemPrice": 29.2706
			},
			{
				"open": 29.1125,
				"close": 29.1825,
				"volume": 60158076,
				"time": 1482987600000,
				"lowest": 29.1,
				"highest": 29.2774,
				"volumePrice": 29.1745,
				"transactionCount": 95705,
				"voluemPrice": 29.1745
			},
			{
				"open": 29.1625,
				"close": 28.955,
				"volume": 122345060,
				"time": 1483074000000,
				"lowest": 28.8575,
				"highest": 29.3,
				"volumePrice": 28.9911,
				"transactionCount": 158067,
				"voluemPrice": 28.9911
			},
			{
				"open": 28.95,
				"close": 29.0375,
				"volume": 115127460,
				"time": 1483419600000,
				"lowest": 28.69,
				"highest": 29.0825,
				"volumePrice": 28.9032,
				"transactionCount": 158506,
				"voluemPrice": 28.9032
			},
			{
				"open": 28.9625,
				"close": 29.005,
				"volume": 84472464,
				"time": 1483506000000,
				"lowest": 28.9375,
				"highest": 29.1275,
				"volumePrice": 29.033,
				"transactionCount": 129946,
				"voluemPrice": 29.033
			},
			{
				"open": 28.98,
				"close": 29.1525,
				"volume": 88774348,
				"time": 1483592400000,
				"lowest": 28.9525,
				"highest": 29.2161,
				"volumePrice": 29.1153,
				"transactionCount": 136221,
				"voluemPrice": 29.1153
			},
			{
				"open": 29.195,
				"close": 29.4775,
				"volume": 127007600,
				"time": 1483678800000,
				"lowest": 29.1175,
				"highest": 29.54,
				"volumePrice": 29.4334,
				"transactionCount": 177880,
				"voluemPrice": 29.4334
			},
			{
				"open": 29.4875,
				"close": 29.7475,
				"volume": 134247792,
				"time": 1483938000000,
				"lowest": 29.485,
				"highest": 29.8575,
				"volumePrice": 29.745,
				"transactionCount": 184629,
				"voluemPrice": 29.745
			},
			{
				"open": 29.6925,
				"close": 29.7775,
				"volume": 97848204,
				"time": 1484024400000,
				"lowest": 29.575,
				"highest": 29.845,
				"volumePrice": 29.7444,
				"transactionCount": 146170,
				"voluemPrice": 29.7444
			},
			{
				"open": 29.685,
				"close": 29.9375,
				"volume": 110354372,
				"time": 1484110800000,
				"lowest": 29.65,
				"highest": 29.9825,
				"volumePrice": 29.8154,
				"transactionCount": 153267,
				"voluemPrice": 29.8154
			},
			{
				"open": 29.7238,
				"close": 29.8125,
				"volume": 108344880,
				"time": 1484197200000,
				"lowest": 29.5525,
				"highest": 29.825,
				"volumePrice": 29.7088,
				"transactionCount": 165854,
				"voluemPrice": 29.7088
			},
			{
				"open": 29.7775,
				"close": 29.76,
				"volume": 104447792,
				"time": 1484283600000,
				"lowest": 29.7025,
				"highest": 29.905,
				"volumePrice": 29.7669,
				"transactionCount": 132984,
				"voluemPrice": 29.7669
			},
			{
				"open": 29.585,
				"close": 30,
				"volume": 137509132,
				"time": 1484629200000,
				"lowest": 29.555,
				"highest": 30.06,
				"volumePrice": 29.9422,
				"transactionCount": 189129,
				"voluemPrice": 29.9422
			},
			{
				"open": 30,
				"close": 29.9975,
				"volume": 94851844,
				"time": 1484715600000,
				"lowest": 29.9275,
				"highest": 30.125,
				"volumePrice": 30.0072,
				"transactionCount": 126588,
				"voluemPrice": 30.0072
			},
			{
				"open": 29.85,
				"close": 29.945,
				"volume": 102389164,
				"time": 1484802000000,
				"lowest": 29.8425,
				"highest": 30.0225,
				"volumePrice": 29.9493,
				"transactionCount": 130085,
				"voluemPrice": 29.9493
			},
			{
				"open": 30.1125,
				"close": 30,
				"volume": 129991568,
				"time": 1484888400000,
				"lowest": 29.9337,
				"highest": 30.1125,
				"volumePrice": 30.0036,
				"transactionCount": 148466,
				"voluemPrice": 30.0036
			},
			{
				"open": 30,
				"close": 30.02,
				"volume": 88200872,
				"time": 1485147600000,
				"lowest": 29.9425,
				"highest": 30.2025,
				"volumePrice": 30.0327,
				"transactionCount": 128202,
				"voluemPrice": 30.0327
			},
			{
				"open": 29.8875,
				"close": 29.9925,
				"volume": 92844152,
				"time": 1485234000000,
				"lowest": 29.875,
				"highest": 30.025,
				"volumePrice": 29.9906,
				"transactionCount": 134779,
				"voluemPrice": 29.9906
			},
			{
				"open": 30.105,
				"close": 30.47,
				"volume": 130346692,
				"time": 1485320400000,
				"lowest": 30.07,
				"highest": 30.525,
				"volumePrice": 30.3792,
				"transactionCount": 198920,
				"voluemPrice": 30.3792
			},
			{
				"open": 30.4175,
				"close": 30.485,
				"volume": 105350304,
				"time": 1485406800000,
				"lowest": 30.4,
				"highest": 30.61,
				"volumePrice": 30.479,
				"transactionCount": 126531,
				"voluemPrice": 30.479
			},
			{
				"open": 30.535,
				"close": 30.4875,
				"volume": 82246056,
				"time": 1485493200000,
				"lowest": 30.4,
				"highest": 30.5875,
				"volumePrice": 30.4852,
				"transactionCount": 110698,
				"voluemPrice": 30.4852
			},
			{
				"open": 30.2325,
				"close": 30.4075,
				"volume": 121510012,
				"time": 1485752400000,
				"lowest": 30.165,
				"highest": 30.4075,
				"volumePrice": 30.2999,
				"transactionCount": 164886,
				"voluemPrice": 30.2999
			},
			{
				"open": 30.2875,
				"close": 30.3375,
				"volume": 196803972,
				"time": 1485838800000,
				"lowest": 30.155,
				"highest": 30.3475,
				"volumePrice": 30.4074,
				"transactionCount": 246120,
				"voluemPrice": 30.4074
			},
			{
				"open": 31.7575,
				"close": 32.1875,
				"volume": 447940160,
				"time": 1485925200000,
				"lowest": 31.7525,
				"highest": 32.6225,
				"volumePrice": 32.1322,
				"transactionCount": 537166,
				"voluemPrice": 32.1322
			},
			{
				"open": 31.9938,
				"close": 32.1325,
				"volume": 134841644,
				"time": 1486011600000,
				"lowest": 31.945,
				"highest": 32.3475,
				"volumePrice": 32.1028,
				"transactionCount": 190090,
				"voluemPrice": 32.1028
			},
			{
				"open": 32.0775,
				"close": 32.27,
				"volume": 97229204,
				"time": 1486098000000,
				"lowest": 32.04,
				"highest": 32.2975,
				"volumePrice": 32.1885,
				"transactionCount": 135271,
				"voluemPrice": 32.1885
			},
			{
				"open": 32.2825,
				"close": 32.5725,
				"volume": 107383696,
				"time": 1486357200000,
				"lowest": 32.23,
				"highest": 32.625,
				"volumePrice": 32.5251,
				"transactionCount": 150864,
				"voluemPrice": 32.5251
			},
			{
				"open": 32.635,
				"close": 32.8825,
				"volume": 152735364,
				"time": 1486443600000,
				"lowest": 32.6125,
				"highest": 33.0225,
				"volumePrice": 32.8847,
				"transactionCount": 190011,
				"voluemPrice": 32.8847
			},
			{
				"open": 32.8375,
				"close": 33.01,
				"volume": 92016288,
				"time": 1486530000000,
				"lowest": 32.805,
				"highest": 33.055,
				"volumePrice": 32.929,
				"transactionCount": 124773,
				"voluemPrice": 32.929
			},
			{
				"open": 32.9125,
				"close": 33.105,
				"volume": 113399436,
				"time": 1486616400000,
				"lowest": 32.78,
				"highest": 33.1113,
				"volumePrice": 33.0012,
				"transactionCount": 132024,
				"voluemPrice": 33.0012
			},
			{
				"open": 33.115,
				"close": 33.03,
				"volume": 80261832,
				"time": 1486702800000,
				"lowest": 33.0125,
				"highest": 33.235,
				"volumePrice": 33.0891,
				"transactionCount": 109758,
				"voluemPrice": 33.0891
			},
			{
				"open": 33.27,
				"close": 33.3225,
				"volume": 92065684,
				"time": 1486962000000,
				"lowest": 33.1875,
				"highest": 33.455,
				"volumePrice": 33.3696,
				"transactionCount": 131060,
				"voluemPrice": 33.3696
			},
			{
				"open": 33.3675,
				"close": 33.755,
				"volume": 132904892,
				"time": 1487048400000,
				"lowest": 33.3125,
				"highest": 33.7725,
				"volumePrice": 33.5978,
				"transactionCount": 170309,
				"voluemPrice": 33.5978
			},
			{
				"open": 33.88,
				"close": 33.8775,
				"volume": 142492400,
				"time": 1487134800000,
				"lowest": 33.655,
				"highest": 34.0675,
				"volumePrice": 33.8577,
				"transactionCount": 187386,
				"voluemPrice": 33.8577
			},
			{
				"open": 33.9175,
				"close": 33.8363,
				"volume": 90246220,
				"time": 1487221200000,
				"lowest": 33.71,
				"highest": 33.975,
				"volumePrice": 33.8174,
				"transactionCount": 135494,
				"voluemPrice": 33.8174
			},
			{
				"open": 33.775,
				"close": 33.93,
				"volume": 88792788,
				"time": 1487307600000,
				"lowest": 33.775,
				"highest": 33.9575,
				"volumePrice": 33.8648,
				"transactionCount": 115345,
				"voluemPrice": 33.8648
			},
			{
				"open": 34.0575,
				"close": 34.175,
				"volume": 98028624,
				"time": 1487653200000,
				"lowest": 33.995,
				"highest": 34.1875,
				"volumePrice": 34.113,
				"transactionCount": 129417,
				"voluemPrice": 34.113
			},
			{
				"open": 34.1075,
				"close": 34.2775,
				"volume": 83347728,
				"time": 1487739600000,
				"lowest": 34.0275,
				"highest": 34.28,
				"volumePrice": 34.1788,
				"transactionCount": 105694,
				"voluemPrice": 34.1788
			},
			{
				"open": 34.345,
				"close": 34.1325,
				"volume": 83152744,
				"time": 1487826000000,
				"lowest": 34.075,
				"highest": 34.37,
				"volumePrice": 34.176,
				"transactionCount": 131710,
				"voluemPrice": 34.176
			},
			{
				"open": 33.9775,
				"close": 34.165,
				"volume": 87106340,
				"time": 1487912400000,
				"lowest": 33.82,
				"highest": 34.165,
				"volumePrice": 34.0013,
				"transactionCount": 127244,
				"voluemPrice": 34.0013
			},
			{
				"open": 34.285,
				"close": 34.2325,
				"volume": 81029704,
				"time": 1488171600000,
				"lowest": 34.07,
				"highest": 34.3588,
				"volumePrice": 34.2465,
				"transactionCount": 123044,
				"voluemPrice": 34.2465
			},
			{
				"open": 34.27,
				"close": 34.2475,
				"volume": 93931440,
				"time": 1488258000000,
				"lowest": 34.175,
				"highest": 34.3588,
				"volumePrice": 34.2654,
				"transactionCount": 126839,
				"voluemPrice": 34.2654
			},
			{
				"open": 34.4725,
				"close": 34.9475,
				"volume": 145658340,
				"time": 1488344400000,
				"lowest": 34.3988,
				"highest": 35.0375,
				"volumePrice": 34.8464,
				"transactionCount": 207456,
				"voluemPrice": 34.8464
			},
			{
				"open": 35,
				"close": 34.74,
				"volume": 104800936,
				"time": 1488430800000,
				"lowest": 34.69,
				"highest": 35.0697,
				"volumePrice": 34.8489,
				"transactionCount": 157427,
				"voluemPrice": 34.8489
			},
			{
				"open": 34.695,
				"close": 34.945,
				"volume": 86284484,
				"time": 1488517200000,
				"lowest": 34.6475,
				"highest": 34.9575,
				"volumePrice": 34.8573,
				"transactionCount": 120905,
				"voluemPrice": 34.8573
			},
			{
				"open": 34.8413,
				"close": 34.835,
				"volume": 87000176,
				"time": 1488776400000,
				"lowest": 34.649,
				"highest": 34.9425,
				"volumePrice": 34.7848,
				"transactionCount": 121043,
				"voluemPrice": 34.7848
			},
			{
				"open": 34.765,
				"close": 34.88,
				"volume": 69785188,
				"time": 1488862800000,
				"lowest": 34.6975,
				"highest": 34.995,
				"volumePrice": 34.8734,
				"transactionCount": 110485,
				"voluemPrice": 34.8734
			},
			{
				"open": 34.7375,
				"close": 34.75,
				"volume": 74828944,
				"time": 1488949200000,
				"lowest": 34.705,
				"highest": 34.95,
				"volumePrice": 34.8077,
				"transactionCount": 113163,
				"voluemPrice": 34.8077
			},
			{
				"open": 34.685,
				"close": 34.67,
				"volume": 88623616,
				"time": 1489035600000,
				"lowest": 34.2625,
				"highest": 34.6975,
				"volumePrice": 34.5933,
				"transactionCount": 129686,
				"voluemPrice": 34.5933
			},
			{
				"open": 34.8125,
				"close": 34.785,
				"volume": 78451204,
				"time": 1489122000000,
				"lowest": 34.66,
				"highest": 34.8393,
				"volumePrice": 34.7734,
				"transactionCount": 115137,
				"voluemPrice": 34.7734
			},
			{
				"open": 34.7125,
				"close": 34.8,
				"volume": 69686868,
				"time": 1489377600000,
				"lowest": 34.705,
				"highest": 34.8575,
				"volumePrice": 34.7822,
				"transactionCount": 98285,
				"voluemPrice": 34.7822
			},
			{
				"open": 34.825,
				"close": 34.7475,
				"volume": 61203260,
				"time": 1489464000000,
				"lowest": 34.71,
				"highest": 34.9125,
				"volumePrice": 34.7777,
				"transactionCount": 98248,
				"voluemPrice": 34.7777
			},
			{
				"open": 34.8525,
				"close": 35.115,
				"volume": 102767096,
				"time": 1489550400000,
				"lowest": 34.7563,
				"highest": 35.1875,
				"volumePrice": 35.003,
				"transactionCount": 141143,
				"voluemPrice": 35.003
			},
			{
				"open": 35.18,
				"close": 35.1725,
				"volume": 76927992,
				"time": 1489636800000,
				"lowest": 35.065,
				"highest": 35.255,
				"volumePrice": 35.1596,
				"transactionCount": 121225,
				"voluemPrice": 35.1596
			},
			{
				"open": 35.25,
				"close": 34.9975,
				"volume": 175539808,
				"time": 1489723200000,
				"lowest": 34.9725,
				"highest": 35.25,
				"volumePrice": 35.106,
				"transactionCount": 122374,
				"voluemPrice": 35.106
			},
			{
				"open": 35.1,
				"close": 35.365,
				"volume": 86068152,
				"time": 1489982400000,
				"lowest": 35.0575,
				"highest": 35.375,
				"volumePrice": 35.2723,
				"transactionCount": 111759,
				"voluemPrice": 35.2723
			},
			{
				"open": 35.5275,
				"close": 34.96,
				"volume": 158119648,
				"time": 1490068800000,
				"lowest": 34.9325,
				"highest": 35.7,
				"volumePrice": 35.2801,
				"transactionCount": 238143,
				"voluemPrice": 35.2801
			},
			{
				"open": 34.9613,
				"close": 35.355,
				"volume": 103440460,
				"time": 1490155200000,
				"lowest": 34.94,
				"highest": 35.4,
				"volumePrice": 35.2178,
				"transactionCount": 151502,
				"voluemPrice": 35.2178
			},
			{
				"open": 35.315,
				"close": 35.23,
				"volume": 81385204,
				"time": 1490241600000,
				"lowest": 35.1525,
				"highest": 35.3961,
				"volumePrice": 35.2534,
				"transactionCount": 127777,
				"voluemPrice": 35.2534
			},
			{
				"open": 35.375,
				"close": 35.16,
				"volume": 89582252,
				"time": 1490328000000,
				"lowest": 35.0875,
				"highest": 35.435,
				"volumePrice": 35.2681,
				"transactionCount": 131775,
				"voluemPrice": 35.2681
			},
			{
				"open": 34.8475,
				"close": 35.22,
				"volume": 94300376,
				"time": 1490587200000,
				"lowest": 34.655,
				"highest": 35.305,
				"volumePrice": 35.1058,
				"transactionCount": 137310,
				"voluemPrice": 35.1058
			},
			{
				"open": 35.2275,
				"close": 35.95,
				"volume": 133499220,
				"time": 1490673600000,
				"lowest": 35.155,
				"highest": 36.01,
				"volumePrice": 35.7183,
				"transactionCount": 194591,
				"voluemPrice": 35.7183
			},
			{
				"open": 35.92,
				"close": 36.03,
				"volume": 116759820,
				"time": 1490760000000,
				"lowest": 35.7975,
				"highest": 36.1225,
				"volumePrice": 35.9677,
				"transactionCount": 160450,
				"voluemPrice": 35.9677
			},
			{
				"open": 36.0475,
				"close": 35.9825,
				"volume": 84829008,
				"time": 1490846400000,
				"lowest": 35.875,
				"highest": 36.125,
				"volumePrice": 35.9954,
				"transactionCount": 114207,
				"voluemPrice": 35.9954
			},
			{
				"open": 35.93,
				"close": 35.915,
				"volume": 78646604,
				"time": 1490932800000,
				"lowest": 35.7525,
				"highest": 36.0675,
				"volumePrice": 35.9555,
				"transactionCount": 109068,
				"voluemPrice": 35.9555
			},
			{
				"open": 35.9275,
				"close": 35.925,
				"volume": 79942856,
				"time": 1491192000000,
				"lowest": 35.7625,
				"highest": 36.03,
				"volumePrice": 35.9145,
				"transactionCount": 125528,
				"voluemPrice": 35.9145
			},
			{
				"open": 35.8125,
				"close": 36.1925,
				"volume": 79565416,
				"time": 1491278400000,
				"lowest": 35.7925,
				"highest": 36.2225,
				"volumePrice": 36.0693,
				"transactionCount": 123787,
				"voluemPrice": 36.0693
			},
			{
				"open": 36.055,
				"close": 36.005,
				"volume": 110871416,
				"time": 1491364800000,
				"lowest": 35.9525,
				"highest": 36.365,
				"volumePrice": 36.1962,
				"transactionCount": 169560,
				"voluemPrice": 36.1962
			},
			{
				"open": 36.0725,
				"close": 35.915,
				"volume": 84596136,
				"time": 1491451200000,
				"lowest": 35.8625,
				"highest": 36.13,
				"volumePrice": 35.9843,
				"transactionCount": 124823,
				"voluemPrice": 35.9843
			},
			{
				"open": 35.9325,
				"close": 35.835,
				"volume": 66688792,
				"time": 1491537600000,
				"lowest": 35.8175,
				"highest": 36.045,
				"volumePrice": 35.9285,
				"transactionCount": 110320,
				"voluemPrice": 35.9285
			},
			{
				"open": 35.9,
				"close": 35.7925,
				"volume": 75733588,
				"time": 1491796800000,
				"lowest": 35.725,
				"highest": 35.9698,
				"volumePrice": 35.8391,
				"transactionCount": 112264,
				"voluemPrice": 35.8391
			},
			{
				"open": 35.735,
				"close": 35.4075,
				"volume": 121517504,
				"time": 1491883200000,
				"lowest": 35.015,
				"highest": 35.8375,
				"volumePrice": 35.3628,
				"transactionCount": 194098,
				"voluemPrice": 35.3628
			},
			{
				"open": 35.4,
				"close": 35.45,
				"volume": 81400000,
				"time": 1491969600000,
				"lowest": 35.2525,
				"highest": 35.5375,
				"volumePrice": 35.3897,
				"transactionCount": 131853,
				"voluemPrice": 35.3897
			},
			{
				"open": 35.4775,
				"close": 35.2625,
				"volume": 71291520,
				"time": 1492056000000,
				"lowest": 35.2625,
				"highest": 35.595,
				"volumePrice": 35.4477,
				"transactionCount": 117139,
				"voluemPrice": 35.4477
			},
			{
				"open": 35.37,
				"close": 35.4575,
				"volume": 66328376,
				"time": 1492401600000,
				"lowest": 35.2175,
				"highest": 35.47,
				"volumePrice": 35.3602,
				"transactionCount": 102340,
				"voluemPrice": 35.3602
			},
			{
				"open": 35.3525,
				"close": 35.3,
				"volume": 58790176,
				"time": 1492488000000,
				"lowest": 35.2775,
				"highest": 35.51,
				"volumePrice": 35.3692,
				"transactionCount": 100312,
				"voluemPrice": 35.3692
			},
			{
				"open": 35.47,
				"close": 35.17,
				"volume": 69313500,
				"time": 1492574400000,
				"lowest": 35.1125,
				"highest": 35.5,
				"volumePrice": 35.3044,
				"transactionCount": 114136,
				"voluemPrice": 35.3044
			},
			{
				"open": 35.305,
				"close": 35.61,
				"volume": 93278248,
				"time": 1492660800000,
				"lowest": 35.29,
				"highest": 35.73,
				"volumePrice": 35.5778,
				"transactionCount": 145299,
				"voluemPrice": 35.5778
			},
			{
				"open": 35.61,
				"close": 35.5675,
				"volume": 68103712,
				"time": 1492747200000,
				"lowest": 35.4625,
				"highest": 35.67,
				"volumePrice": 35.5682,
				"transactionCount": 104116,
				"voluemPrice": 35.5682
			},
			{
				"open": 35.875,
				"close": 35.91,
				"volume": 68537332,
				"time": 1493006400000,
				"lowest": 35.795,
				"highest": 35.9875,
				"volumePrice": 35.9197,
				"transactionCount": 108051,
				"voluemPrice": 35.9197
			},
			{
				"open": 35.9775,
				"close": 36.1325,
				"volume": 75486004,
				"time": 1493092800000,
				"lowest": 35.9675,
				"highest": 36.225,
				"volumePrice": 36.1264,
				"transactionCount": 110705,
				"voluemPrice": 36.1264
			},
			{
				"open": 36.1175,
				"close": 35.92,
				"volume": 80164964,
				"time": 1493179200000,
				"lowest": 35.8441,
				"highest": 36.15,
				"volumePrice": 36.0132,
				"transactionCount": 130687,
				"voluemPrice": 36.0132
			},
			{
				"open": 35.9806,
				"close": 35.9475,
				"volume": 56985388,
				"time": 1493265600000,
				"lowest": 35.8275,
				"highest": 36.04,
				"volumePrice": 35.9223,
				"transactionCount": 97491,
				"voluemPrice": 35.9223
			},
			{
				"open": 36.0225,
				"close": 35.9125,
				"volume": 83441432,
				"time": 1493352000000,
				"lowest": 35.8175,
				"highest": 36.075,
				"volumePrice": 35.935,
				"transactionCount": 114783,
				"voluemPrice": 35.935
			},
			{
				"open": 36.275,
				"close": 36.645,
				"volume": 134411772,
				"time": 1493611200000,
				"lowest": 36.24,
				"highest": 36.8,
				"volumePrice": 36.5787,
				"transactionCount": 184549,
				"voluemPrice": 36.5787
			},
			{
				"open": 36.885,
				"close": 36.8775,
				"volume": 177987776,
				"time": 1493697600000,
				"lowest": 36.71,
				"highest": 37.0225,
				"volumePrice": 36.8072,
				"transactionCount": 255527,
				"voluemPrice": 36.8072
			},
			{
				"open": 36.3975,
				"close": 36.765,
				"volume": 182788136,
				"time": 1493784000000,
				"lowest": 36.0675,
				"highest": 36.8725,
				"volumePrice": 36.5525,
				"transactionCount": 282334,
				"voluemPrice": 36.5525
			},
			{
				"open": 36.63,
				"close": 36.6325,
				"volume": 93487488,
				"time": 1493870400000,
				"lowest": 36.4525,
				"highest": 36.785,
				"volumePrice": 36.5871,
				"transactionCount": 140288,
				"voluemPrice": 36.5871
			},
			{
				"open": 36.69,
				"close": 37.24,
				"volume": 109205300,
				"time": 1493956800000,
				"lowest": 36.69,
				"highest": 37.245,
				"volumePrice": 37.0625,
				"transactionCount": 157907,
				"voluemPrice": 37.0625
			},
			{
				"open": 37.2575,
				"close": 38.2525,
				"volume": 195009652,
				"time": 1494216000000,
				"lowest": 37.2575,
				"highest": 38.425,
				"volumePrice": 38.0078,
				"transactionCount": 255913,
				"voluemPrice": 38.0078
			},
			{
				"open": 38.4675,
				"close": 38.4975,
				"volume": 156521452,
				"time": 1494302400000,
				"lowest": 38.3625,
				"highest": 38.72,
				"volumePrice": 38.5286,
				"transactionCount": 221712,
				"voluemPrice": 38.5286
			},
			{
				"open": 38.4075,
				"close": 38.315,
				"volume": 103222768,
				"time": 1494388800000,
				"lowest": 38.0275,
				"highest": 38.485,
				"volumePrice": 38.2753,
				"transactionCount": 156835,
				"voluemPrice": 38.2753
			},
			{
				"open": 38.1125,
				"close": 38.4875,
				"volume": 109020232,
				"time": 1494475200000,
				"lowest": 38.0775,
				"highest": 38.5175,
				"volumePrice": 38.2983,
				"transactionCount": 137539,
				"voluemPrice": 38.2983
			},
			{
				"open": 38.675,
				"close": 39.025,
				"volume": 130108068,
				"time": 1494561600000,
				"lowest": 38.6675,
				"highest": 39.105,
				"volumePrice": 38.9626,
				"transactionCount": 187416,
				"voluemPrice": 38.9626
			},
			{
				"open": 39.0025,
				"close": 38.925,
				"volume": 104038876,
				"time": 1494820800000,
				"lowest": 38.7625,
				"highest": 39.1625,
				"volumePrice": 38.9042,
				"transactionCount": 141088,
				"voluemPrice": 38.9042
			},
			{
				"open": 38.985,
				"close": 38.8675,
				"volume": 80193512,
				"time": 1494907200000,
				"lowest": 38.68,
				"highest": 39.015,
				"volumePrice": 38.8334,
				"transactionCount": 129335,
				"voluemPrice": 38.8334
			},
			{
				"open": 38.4,
				"close": 37.5625,
				"volume": 203070712,
				"time": 1494993600000,
				"lowest": 37.4275,
				"highest": 38.6425,
				"volumePrice": 37.8822,
				"transactionCount": 320867,
				"voluemPrice": 37.8822
			},
			{
				"open": 37.8175,
				"close": 38.135,
				"volume": 134272860,
				"time": 1495080000000,
				"lowest": 37.7825,
				"highest": 38.335,
				"volumePrice": 38.1149,
				"transactionCount": 205658,
				"voluemPrice": 38.1149
			},
			{
				"open": 38.345,
				"close": 38.265,
				"volume": 107843152,
				"time": 1495166400000,
				"lowest": 38.1575,
				"highest": 38.495,
				"volumePrice": 38.333,
				"transactionCount": 148515,
				"voluemPrice": 38.333
			},
			{
				"open": 38.5,
				"close": 38.4975,
				"volume": 91865748,
				"time": 1495425600000,
				"lowest": 38.2275,
				"highest": 38.645,
				"volumePrice": 38.4463,
				"transactionCount": 128169,
				"voluemPrice": 38.4463
			},
			{
				"open": 38.725,
				"close": 38.45,
				"volume": 79675484,
				"time": 1495512000000,
				"lowest": 38.3275,
				"highest": 38.725,
				"volumePrice": 38.462,
				"transactionCount": 126250,
				"voluemPrice": 38.462
			},
			{
				"open": 38.46,
				"close": 38.335,
				"volume": 76876616,
				"time": 1495598400000,
				"lowest": 38.1675,
				"highest": 38.5425,
				"volumePrice": 38.3178,
				"transactionCount": 115231,
				"voluemPrice": 38.3178
			},
			{
				"open": 38.4325,
				"close": 38.4675,
				"volume": 76918696,
				"time": 1495684800000,
				"lowest": 38.2575,
				"highest": 38.5875,
				"volumePrice": 38.4528,
				"transactionCount": 121641,
				"voluemPrice": 38.4528
			},
			{
				"open": 38.5,
				"close": 38.4025,
				"volume": 87710548,
				"time": 1495771200000,
				"lowest": 38.3275,
				"highest": 38.56,
				"volumePrice": 38.4198,
				"transactionCount": 109429,
				"voluemPrice": 38.4198
			},
			{
				"open": 38.355,
				"close": 38.4175,
				"volume": 80507404,
				"time": 1496116800000,
				"lowest": 38.3325,
				"highest": 38.6075,
				"volumePrice": 38.4661,
				"transactionCount": 113952,
				"voluemPrice": 38.4661
			},
			{
				"open": 38.4925,
				"close": 38.19,
				"volume": 97804656,
				"time": 1496203200000,
				"lowest": 38.095,
				"highest": 38.5425,
				"volumePrice": 38.2335,
				"transactionCount": 131942,
				"voluemPrice": 38.2335
			},
			{
				"open": 38.2925,
				"close": 38.295,
				"volume": 65616352,
				"time": 1496289600000,
				"lowest": 38.055,
				"highest": 38.3325,
				"volumePrice": 38.2255,
				"transactionCount": 112144,
				"voluemPrice": 38.2255
			},
			{
				"open": 38.395,
				"close": 38.8625,
				"volume": 111082860,
				"time": 1496376000000,
				"lowest": 38.2225,
				"highest": 38.8625,
				"volumePrice": 38.6609,
				"transactionCount": 167741,
				"voluemPrice": 38.6609
			},
			{
				"open": 38.585,
				"close": 38.4825,
				"volume": 101326648,
				"time": 1496635200000,
				"lowest": 38.365,
				"highest": 38.6125,
				"volumePrice": 38.478,
				"transactionCount": 157952,
				"voluemPrice": 38.478
			},
			{
				"open": 38.475,
				"close": 38.6125,
				"volume": 106499704,
				"time": 1496721600000,
				"lowest": 38.445,
				"highest": 38.9525,
				"volumePrice": 38.6639,
				"transactionCount": 151820,
				"voluemPrice": 38.6639
			},
			{
				"open": 38.755,
				"close": 38.8425,
				"volume": 84278588,
				"time": 1496808000000,
				"lowest": 38.62,
				"highest": 38.995,
				"volumePrice": 38.8385,
				"transactionCount": 129496,
				"voluemPrice": 38.8385
			},
			{
				"open": 38.8125,
				"close": 38.7475,
				"volume": 85003192,
				"time": 1496894400000,
				"lowest": 38.6,
				"highest": 38.885,
				"volumePrice": 38.7301,
				"transactionCount": 132005,
				"voluemPrice": 38.7301
			},
			{
				"open": 38.7975,
				"close": 37.245,
				"volume": 259530628,
				"time": 1496980800000,
				"lowest": 36.505,
				"highest": 38.7975,
				"volumePrice": 37.5476,
				"transactionCount": 416021,
				"voluemPrice": 37.5476
			},
			{
				"open": 36.435,
				"close": 36.355,
				"volume": 289229320,
				"time": 1497240000000,
				"lowest": 35.6275,
				"highest": 36.5225,
				"volumePrice": 36.0862,
				"transactionCount": 455651,
				"voluemPrice": 36.0862
			},
			{
				"open": 36.79,
				"close": 36.6475,
				"volume": 136661780,
				"time": 1497326400000,
				"lowest": 36.2875,
				"highest": 36.8625,
				"volumePrice": 36.591,
				"transactionCount": 209032,
				"voluemPrice": 36.591
			},
			{
				"open": 36.875,
				"close": 36.29,
				"volume": 126124928,
				"time": 1497412800000,
				"lowest": 35.96,
				"highest": 36.875,
				"volumePrice": 36.3517,
				"transactionCount": 217288,
				"voluemPrice": 36.3517
			},
			{
				"open": 35.83,
				"close": 36.0725,
				"volume": 128661492,
				"time": 1497499200000,
				"lowest": 35.5525,
				"highest": 36.12,
				"volumePrice": 35.8869,
				"transactionCount": 215379,
				"voluemPrice": 35.8869
			},
			{
				"open": 35.945,
				"close": 35.5675,
				"volume": 201244372,
				"time": 1497585600000,
				"lowest": 35.55,
				"highest": 36.125,
				"volumePrice": 35.7572,
				"transactionCount": 196672,
				"voluemPrice": 35.7572
			},
			{
				"open": 35.915,
				"close": 36.585,
				"volume": 130165616,
				"time": 1497844800000,
				"lowest": 35.915,
				"highest": 36.685,
				"volumePrice": 36.4432,
				"transactionCount": 194318,
				"voluemPrice": 36.4432
			},
			{
				"open": 36.7175,
				"close": 36.2525,
				"volume": 99581796,
				"time": 1497931200000,
				"lowest": 36.235,
				"highest": 36.7175,
				"volumePrice": 36.365,
				"transactionCount": 163497,
				"voluemPrice": 36.365
			},
			{
				"open": 36.38,
				"close": 36.4675,
				"volume": 85063004,
				"time": 1498017600000,
				"lowest": 36.1525,
				"highest": 36.5173,
				"volumePrice": 36.3906,
				"transactionCount": 128182,
				"voluemPrice": 36.3906
			},
			{
				"open": 36.4425,
				"close": 36.4075,
				"volume": 76425176,
				"time": 1498104000000,
				"lowest": 36.28,
				"highest": 36.675,
				"volumePrice": 36.4406,
				"transactionCount": 119248,
				"voluemPrice": 36.4406
			},
			{
				"open": 36.2825,
				"close": 36.57,
				"volume": 141757556,
				"time": 1498190400000,
				"lowest": 36.2775,
				"highest": 36.79,
				"volumePrice": 36.5799,
				"transactionCount": 129654,
				"voluemPrice": 36.5799
			},
			{
				"open": 36.7925,
				"close": 36.455,
				"volume": 102769444,
				"time": 1498449600000,
				"lowest": 36.345,
				"highest": 37.07,
				"volumePrice": 36.6201,
				"transactionCount": 161363,
				"voluemPrice": 36.6201
			},
			{
				"open": 36.2525,
				"close": 35.9325,
				"volume": 99047564,
				"time": 1498536000000,
				"lowest": 35.905,
				"highest": 36.54,
				"volumePrice": 36.2052,
				"transactionCount": 163289,
				"voluemPrice": 36.2052
			},
			{
				"open": 36.1225,
				"close": 36.4575,
				"volume": 88329728,
				"time": 1498622400000,
				"lowest": 35.79,
				"highest": 36.5275,
				"volumePrice": 36.252,
				"transactionCount": 148160,
				"voluemPrice": 36.252
			},
			{
				"open": 36.1775,
				"close": 35.92,
				"volume": 125997472,
				"time": 1498708800000,
				"lowest": 35.57,
				"highest": 36.2825,
				"volumePrice": 35.9093,
				"transactionCount": 218929,
				"voluemPrice": 35.9093
			},
			{
				"open": 36.1125,
				"close": 36.005,
				"volume": 92096428,
				"time": 1498795200000,
				"lowest": 35.945,
				"highest": 36.24,
				"volumePrice": 36.0923,
				"transactionCount": 131789,
				"voluemPrice": 36.0923
			},
			{
				"open": 36.22,
				"close": 35.875,
				"volume": 57111392,
				"time": 1499054400000,
				"lowest": 35.775,
				"highest": 36.325,
				"volumePrice": 35.9924,
				"transactionCount": 98820,
				"voluemPrice": 35.9924
			},
			{
				"open": 35.9225,
				"close": 36.0225,
				"volume": 86278228,
				"time": 1499227200000,
				"lowest": 35.6809,
				"highest": 36.1975,
				"volumePrice": 35.9893,
				"transactionCount": 129490,
				"voluemPrice": 35.9893
			},
			{
				"open": 35.755,
				"close": 35.6825,
				"volume": 96515128,
				"time": 1499313600000,
				"lowest": 35.6025,
				"highest": 35.875,
				"volumePrice": 35.7106,
				"transactionCount": 160142,
				"voluemPrice": 35.7106
			},
			{
				"open": 35.725,
				"close": 36.045,
				"volume": 76806848,
				"time": 1499400000000,
				"lowest": 35.725,
				"highest": 36.1875,
				"volumePrice": 36.0203,
				"transactionCount": 119570,
				"voluemPrice": 36.0203
			},
			{
				"open": 36.0275,
				"close": 36.265,
				"volume": 84362544,
				"time": 1499659200000,
				"lowest": 35.8425,
				"highest": 36.4875,
				"volumePrice": 36.2305,
				"transactionCount": 130962,
				"voluemPrice": 36.2305
			},
			{
				"open": 36.1825,
				"close": 36.3825,
				"volume": 79127344,
				"time": 1499745600000,
				"lowest": 36.095,
				"highest": 36.4625,
				"volumePrice": 36.3115,
				"transactionCount": 122330,
				"voluemPrice": 36.3115
			},
			{
				"open": 36.4675,
				"close": 36.435,
				"volume": 99537912,
				"time": 1499832000000,
				"lowest": 36.205,
				"highest": 36.545,
				"volumePrice": 36.3737,
				"transactionCount": 148206,
				"voluemPrice": 36.3737
			},
			{
				"open": 36.375,
				"close": 36.9425,
				"volume": 100797492,
				"time": 1499918400000,
				"lowest": 36.36,
				"highest": 37.1225,
				"volumePrice": 36.8732,
				"transactionCount": 159844,
				"voluemPrice": 36.8732
			},
			{
				"open": 36.9925,
				"close": 37.26,
				"volume": 80528244,
				"time": 1500004800000,
				"lowest": 36.8325,
				"highest": 37.3325,
				"volumePrice": 37.1119,
				"transactionCount": 126767,
				"voluemPrice": 37.1119
			},
			{
				"open": 37.205,
				"close": 37.39,
				"volume": 95134624,
				"time": 1500264000000,
				"lowest": 37.1425,
				"highest": 37.725,
				"volumePrice": 37.4717,
				"transactionCount": 142953,
				"voluemPrice": 37.4717
			},
			{
				"open": 37.3,
				"close": 37.52,
				"volume": 71475168,
				"time": 1500350400000,
				"lowest": 37.1675,
				"highest": 37.5325,
				"volumePrice": 37.3636,
				"transactionCount": 117808,
				"voluemPrice": 37.3636
			},
			{
				"open": 37.62,
				"close": 37.755,
				"volume": 83691876,
				"time": 1500436800000,
				"lowest": 37.4875,
				"highest": 37.855,
				"volumePrice": 37.7112,
				"transactionCount": 121900,
				"voluemPrice": 37.7112
			},
			{
				"open": 37.875,
				"close": 37.585,
				"volume": 68974992,
				"time": 1500523200000,
				"lowest": 37.5475,
				"highest": 37.935,
				"volumePrice": 37.6873,
				"transactionCount": 106629,
				"voluemPrice": 37.6873
			},
			{
				"open": 37.4975,
				"close": 37.5675,
				"volume": 104810520,
				"time": 1500609600000,
				"lowest": 37.22,
				"highest": 37.61,
				"volumePrice": 37.4855,
				"transactionCount": 122868,
				"voluemPrice": 37.4855
			},
			{
				"open": 37.645,
				"close": 38.0225,
				"volume": 85972640,
				"time": 1500868800000,
				"lowest": 37.475,
				"highest": 38.11,
				"volumePrice": 37.8947,
				"transactionCount": 126523,
				"voluemPrice": 37.8947
			},
			{
				"open": 37.95,
				"close": 38.185,
				"volume": 75335728,
				"time": 1500955200000,
				"lowest": 37.95,
				"highest": 38.46,
				"volumePrice": 38.2857,
				"transactionCount": 124155,
				"voluemPrice": 38.2857
			},
			{
				"open": 38.3375,
				"close": 38.365,
				"volume": 63123804,
				"time": 1501041600000,
				"lowest": 38.265,
				"highest": 38.4825,
				"volumePrice": 38.3772,
				"transactionCount": 93657,
				"voluemPrice": 38.3772
			},
			{
				"open": 38.4375,
				"close": 37.64,
				"volume": 129905348,
				"time": 1501128000000,
				"lowest": 36.825,
				"highest": 38.4975,
				"volumePrice": 37.7492,
				"transactionCount": 218573,
				"voluemPrice": 37.7492
			},
			{
				"open": 37.4725,
				"close": 37.375,
				"volume": 68854612,
				"time": 1501214400000,
				"lowest": 37.2975,
				"highest": 37.5575,
				"volumePrice": 37.4263,
				"transactionCount": 119583,
				"voluemPrice": 37.4263
			},
			{
				"open": 37.475,
				"close": 37.1825,
				"volume": 79383680,
				"time": 1501473600000,
				"lowest": 37.0325,
				"highest": 37.5825,
				"volumePrice": 37.2273,
				"transactionCount": 132514,
				"voluemPrice": 37.2273
			},
			{
				"open": 37.275,
				"close": 37.5125,
				"volume": 141450780,
				"time": 1501560000000,
				"lowest": 37.1025,
				"highest": 37.555,
				"volumePrice": 37.9178,
				"transactionCount": 223046,
				"voluemPrice": 37.9178
			},
			{
				"open": 39.82,
				"close": 39.285,
				"volume": 279747200,
				"time": 1501646400000,
				"lowest": 39.04,
				"highest": 39.9375,
				"volumePrice": 39.4759,
				"transactionCount": 398098,
				"voluemPrice": 39.4759
			},
			{
				"open": 39.2625,
				"close": 38.8925,
				"volume": 108389184,
				"time": 1501732800000,
				"lowest": 38.755,
				"highest": 39.3025,
				"volumePrice": 38.9792,
				"transactionCount": 166574,
				"voluemPrice": 38.9792
			},
			{
				"open": 39.0175,
				"close": 39.0975,
				"volume": 82239408,
				"time": 1501819200000,
				"lowest": 38.9225,
				"highest": 39.35,
				"volumePrice": 39.1114,
				"transactionCount": 130145,
				"voluemPrice": 39.1114
			},
			{
				"open": 39.265,
				"close": 39.7025,
				"volume": 87481284,
				"time": 1502078400000,
				"lowest": 39.1675,
				"highest": 39.73,
				"volumePrice": 39.504,
				"transactionCount": 129564,
				"voluemPrice": 39.504
			},
			{
				"open": 39.65,
				"close": 40.02,
				"volume": 144823584,
				"time": 1502164800000,
				"lowest": 39.5675,
				"highest": 40.4575,
				"volumePrice": 40.1343,
				"transactionCount": 219335,
				"voluemPrice": 40.1343
			},
			{
				"open": 39.815,
				"close": 40.265,
				"volume": 104526120,
				"time": 1502251200000,
				"lowest": 39.7775,
				"highest": 40.3175,
				"volumePrice": 40.1198,
				"transactionCount": 165499,
				"voluemPrice": 40.1198
			},
			{
				"open": 39.975,
				"close": 38.83,
				"volume": 163217092,
				"time": 1502337600000,
				"lowest": 38.6575,
				"highest": 40,
				"volumePrice": 39.3252,
				"transactionCount": 257011,
				"voluemPrice": 39.3252
			},
			{
				"open": 39.15,
				"close": 39.37,
				"volume": 105028384,
				"time": 1502424000000,
				"lowest": 39.0175,
				"highest": 39.6432,
				"volumePrice": 39.3737,
				"transactionCount": 174115,
				"voluemPrice": 39.3737
			},
			{
				"open": 39.83,
				"close": 39.9625,
				"volume": 88490936,
				"time": 1502683200000,
				"lowest": 39.6875,
				"highest": 40.0525,
				"volumePrice": 39.8921,
				"transactionCount": 134856,
				"voluemPrice": 39.8921
			},
			{
				"open": 40.165,
				"close": 40.4,
				"volume": 117861948,
				"time": 1502769600000,
				"lowest": 40.035,
				"highest": 40.5488,
				"volumePrice": 40.3546,
				"transactionCount": 162155,
				"voluemPrice": 40.3546
			},
			{
				"open": 40.485,
				"close": 40.2375,
				"volume": 110686448,
				"time": 1502856000000,
				"lowest": 40.0375,
				"highest": 40.6275,
				"volumePrice": 40.3525,
				"transactionCount": 171491,
				"voluemPrice": 40.3525
			},
			{
				"open": 40.13,
				"close": 39.465,
				"volume": 111762260,
				"time": 1502942400000,
				"lowest": 39.46,
				"highest": 40.1775,
				"volumePrice": 39.7248,
				"transactionCount": 184570,
				"voluemPrice": 39.7248
			},
			{
				"open": 39.465,
				"close": 39.375,
				"volume": 109651876,
				"time": 1503028800000,
				"lowest": 39.18,
				"highest": 39.875,
				"volumePrice": 39.5441,
				"transactionCount": 172436,
				"voluemPrice": 39.5441
			},
			{
				"open": 39.375,
				"close": 39.3025,
				"volume": 105474112,
				"time": 1503288000000,
				"lowest": 38.7775,
				"highest": 39.4725,
				"volumePrice": 39.1681,
				"transactionCount": 160299,
				"voluemPrice": 39.1681
			},
			{
				"open": 39.5575,
				"close": 39.945,
				"volume": 86418340,
				"time": 1503374400000,
				"lowest": 39.505,
				"highest": 40,
				"volumePrice": 39.8854,
				"transactionCount": 131569,
				"voluemPrice": 39.8854
			},
			{
				"open": 39.7675,
				"close": 39.995,
				"volume": 77336324,
				"time": 1503460800000,
				"lowest": 39.72,
				"highest": 40.1175,
				"volumePrice": 39.9675,
				"transactionCount": 116333,
				"voluemPrice": 39.9675
			},
			{
				"open": 40.1075,
				"close": 39.8175,
				"volume": 79275672,
				"time": 1503547200000,
				"lowest": 39.6375,
				"highest": 40.185,
				"volumePrice": 39.8588,
				"transactionCount": 139809,
				"voluemPrice": 39.8588
			},
			{
				"open": 39.9125,
				"close": 39.965,
				"volume": 101920252,
				"time": 1503633600000,
				"lowest": 39.8175,
				"highest": 40.14,
				"volumePrice": 39.9698,
				"transactionCount": 127149,
				"voluemPrice": 39.9698
			},
			{
				"open": 40.035,
				"close": 40.3675,
				"volume": 103863888,
				"time": 1503892800000,
				"lowest": 39.9825,
				"highest": 40.5,
				"volumePrice": 40.3169,
				"transactionCount": 136231,
				"voluemPrice": 40.3169
			},
			{
				"open": 40.025,
				"close": 40.7275,
				"volume": 118017240,
				"time": 1503979200000,
				"lowest": 40,
				"highest": 40.78,
				"volumePrice": 40.5538,
				"transactionCount": 174460,
				"voluemPrice": 40.5538
			},
			{
				"open": 40.95,
				"close": 40.8375,
				"volume": 109078336,
				"time": 1504065600000,
				"lowest": 40.6525,
				"highest": 40.9725,
				"volumePrice": 40.8092,
				"transactionCount": 159712,
				"voluemPrice": 40.8092
			},
			{
				"open": 40.91,
				"close": 41,
				"volume": 107140384,
				"time": 1504152000000,
				"lowest": 40.87,
				"highest": 41.13,
				"volumePrice": 40.9961,
				"transactionCount": 153319,
				"voluemPrice": 40.9961
			},
			{
				"open": 41.2,
				"close": 41.0125,
				"volume": 66364204,
				"time": 1504238400000,
				"lowest": 40.9075,
				"highest": 41.235,
				"volumePrice": 41.0341,
				"transactionCount": 114947,
				"voluemPrice": 41.0341
			},
			{
				"open": 40.9375,
				"close": 40.52,
				"volume": 118145256,
				"time": 1504584000000,
				"lowest": 40.14,
				"highest": 41.0625,
				"volumePrice": 40.5978,
				"transactionCount": 202234,
				"voluemPrice": 40.5978
			},
			{
				"open": 40.6775,
				"close": 40.4775,
				"volume": 86606904,
				"time": 1504670400000,
				"lowest": 40.13,
				"highest": 40.7475,
				"volumePrice": 40.4446,
				"transactionCount": 139109,
				"voluemPrice": 40.4446
			},
			{
				"open": 40.5225,
				"close": 40.315,
				"volume": 87714008,
				"time": 1504756800000,
				"lowest": 40.09,
				"highest": 40.56,
				"volumePrice": 40.3549,
				"transactionCount": 151309,
				"voluemPrice": 40.3549
			},
			{
				"open": 40.215,
				"close": 39.6575,
				"volume": 114446140,
				"time": 1504843200000,
				"lowest": 39.6325,
				"highest": 40.2875,
				"volumePrice": 39.8616,
				"transactionCount": 167823,
				"voluemPrice": 39.8616
			},
			{
				"open": 40.125,
				"close": 40.375,
				"volume": 126323192,
				"time": 1505102400000,
				"lowest": 39.9725,
				"highest": 40.5125,
				"volumePrice": 40.3339,
				"transactionCount": 200560,
				"voluemPrice": 40.3339
			},
			{
				"open": 40.6525,
				"close": 40.215,
				"volume": 286856184,
				"time": 1505188800000,
				"lowest": 39.6925,
				"highest": 40.99,
				"volumePrice": 40.3655,
				"transactionCount": 460431,
				"voluemPrice": 40.3655
			},
			{
				"open": 39.9675,
				"close": 39.9125,
				"volume": 179629444,
				"time": 1505275200000,
				"lowest": 39.4775,
				"highest": 39.99,
				"volumePrice": 39.7505,
				"transactionCount": 280392,
				"voluemPrice": 39.7505
			},
			{
				"open": 39.7475,
				"close": 39.57,
				"volume": 94818996,
				"time": 1505361600000,
				"lowest": 39.5225,
				"highest": 39.85,
				"volumePrice": 39.6856,
				"transactionCount": 144771,
				"voluemPrice": 39.6856
			},
			{
				"open": 39.6175,
				"close": 39.97,
				"volume": 196458408,
				"time": 1505448000000,
				"lowest": 39.5,
				"highest": 40.2425,
				"volumePrice": 39.9181,
				"transactionCount": 200740,
				"voluemPrice": 39.9181
			},
			{
				"open": 40.0275,
				"close": 39.6675,
				"volume": 113077740,
				"time": 1505707200000,
				"lowest": 39.4988,
				"highest": 40.125,
				"volumePrice": 39.7983,
				"transactionCount": 161811,
				"voluemPrice": 39.7983
			},
			{
				"open": 39.8775,
				"close": 39.6825,
				"volume": 82842528,
				"time": 1505793600000,
				"lowest": 39.61,
				"highest": 39.9425,
				"volumePrice": 39.7511,
				"transactionCount": 127999,
				"voluemPrice": 39.7511
			},
			{
				"open": 39.475,
				"close": 39.0175,
				"volume": 209721856,
				"time": 1505880000000,
				"lowest": 38.4575,
				"highest": 39.565,
				"volumePrice": 38.9273,
				"transactionCount": 311268,
				"voluemPrice": 38.9273
			},
			{
				"open": 38.95,
				"close": 38.3475,
				"volume": 150046644,
				"time": 1505966400000,
				"lowest": 38.1875,
				"highest": 38.95,
				"volumePrice": 38.4529,
				"transactionCount": 235910,
				"voluemPrice": 38.4529
			},
			{
				"open": 38.005,
				"close": 37.9725,
				"volume": 186461772,
				"time": 1506052800000,
				"lowest": 37.64,
				"highest": 38.0675,
				"volumePrice": 37.8597,
				"transactionCount": 288347,
				"voluemPrice": 37.8597
			},
			{
				"open": 37.4975,
				"close": 37.6375,
				"volume": 177549344,
				"time": 1506312000000,
				"lowest": 37.29,
				"highest": 37.9575,
				"volumePrice": 37.6056,
				"transactionCount": 276732,
				"voluemPrice": 37.6056
			},
			{
				"open": 37.945,
				"close": 38.285,
				"volume": 146640180,
				"time": 1506398400000,
				"lowest": 37.9225,
				"highest": 38.48,
				"volumePrice": 38.2084,
				"transactionCount": 222989,
				"voluemPrice": 38.2084
			},
			{
				"open": 38.45,
				"close": 38.5575,
				"volume": 102016908,
				"time": 1506484800000,
				"lowest": 38.385,
				"highest": 38.6797,
				"volumePrice": 38.5593,
				"transactionCount": 157873,
				"voluemPrice": 38.5593
			},
			{
				"open": 38.4725,
				"close": 38.32,
				"volume": 88021820,
				"time": 1506571200000,
				"lowest": 38.175,
				"highest": 38.57,
				"volumePrice": 38.3038,
				"transactionCount": 146451,
				"voluemPrice": 38.3038
			},
			{
				"open": 38.3025,
				"close": 38.53,
				"volume": 105199240,
				"time": 1506657600000,
				"lowest": 38,
				"highest": 38.5325,
				"volumePrice": 38.3653,
				"transactionCount": 152681,
				"voluemPrice": 38.3653
			},
			{
				"open": 38.565,
				"close": 38.4525,
				"volume": 74795368,
				"time": 1506916800000,
				"lowest": 38.18,
				"highest": 38.6125,
				"volumePrice": 38.4007,
				"transactionCount": 131388,
				"voluemPrice": 38.4007
			},
			{
				"open": 38.5025,
				"close": 38.62,
				"volume": 64921172,
				"time": 1507003200000,
				"lowest": 38.4775,
				"highest": 38.7725,
				"volumePrice": 38.6376,
				"transactionCount": 105126,
				"voluemPrice": 38.6376
			},
			{
				"open": 38.4075,
				"close": 38.37,
				"volume": 80655000,
				"time": 1507089600000,
				"lowest": 38.115,
				"highest": 38.465,
				"volumePrice": 38.338,
				"transactionCount": 134334,
				"voluemPrice": 38.338
			},
			{
				"open": 38.545,
				"close": 38.8475,
				"volume": 85043876,
				"time": 1507176000000,
				"lowest": 38.5125,
				"highest": 38.86,
				"volumePrice": 38.7665,
				"transactionCount": 133112,
				"voluemPrice": 38.7665
			},
			{
				"open": 38.7425,
				"close": 38.825,
				"volume": 69630232,
				"time": 1507262400000,
				"lowest": 38.64,
				"highest": 38.8725,
				"volumePrice": 38.7646,
				"transactionCount": 98625,
				"voluemPrice": 38.7646
			},
			{
				"open": 38.9525,
				"close": 38.96,
				"volume": 65029884,
				"time": 1507521600000,
				"lowest": 38.8713,
				"highest": 39.1825,
				"volumePrice": 38.9988,
				"transactionCount": 101692,
				"voluemPrice": 38.9988
			},
			{
				"open": 39.0138,
				"close": 38.975,
				"volume": 62468056,
				"time": 1507608000000,
				"lowest": 38.775,
				"highest": 39.5,
				"volumePrice": 38.9591,
				"transactionCount": 103891,
				"voluemPrice": 38.9591
			},
			{
				"open": 38.9925,
				"close": 39.1375,
				"volume": 67622560,
				"time": 1507694400000,
				"lowest": 38.9375,
				"highest": 39.245,
				"volumePrice": 39.1332,
				"transactionCount": 104330,
				"voluemPrice": 39.1332
			},
			{
				"open": 39.0875,
				"close": 39,
				"volume": 64500216,
				"time": 1507780800000,
				"lowest": 38.9325,
				"highest": 39.3425,
				"volumePrice": 39.1475,
				"transactionCount": 110704,
				"voluemPrice": 39.1475
			},
			{
				"open": 39.1825,
				"close": 39.2475,
				"volume": 65576752,
				"time": 1507867200000,
				"lowest": 39.1025,
				"highest": 39.32,
				"volumePrice": 39.2266,
				"transactionCount": 102172,
				"voluemPrice": 39.2266
			},
			{
				"open": 39.475,
				"close": 39.97,
				"volume": 96485808,
				"time": 1508126400000,
				"lowest": 39.4125,
				"highest": 40,
				"volumePrice": 39.8232,
				"transactionCount": 147309,
				"voluemPrice": 39.8232
			},
			{
				"open": 39.945,
				"close": 40.1175,
				"volume": 75989100,
				"time": 1508212800000,
				"lowest": 39.8075,
				"highest": 40.2175,
				"volumePrice": 40.0155,
				"transactionCount": 112842,
				"voluemPrice": 40.0155
			},
			{
				"open": 40.105,
				"close": 39.94,
				"volume": 65496656,
				"time": 1508299200000,
				"lowest": 39.9,
				"highest": 40.1775,
				"volumePrice": 40.0231,
				"transactionCount": 99901,
				"voluemPrice": 40.0231
			},
			{
				"open": 39.1875,
				"close": 38.995,
				"volume": 170336664,
				"time": 1508385600000,
				"lowest": 38.755,
				"highest": 39.27,
				"volumePrice": 38.9621,
				"transactionCount": 243463,
				"voluemPrice": 38.9621
			},
			{
				"open": 39.1525,
				"close": 39.0625,
				"volume": 95896584,
				"time": 1508472000000,
				"lowest": 38.99,
				"highest": 39.4375,
				"volumePrice": 39.2172,
				"transactionCount": 133530,
				"voluemPrice": 39.2172
			},
			{
				"open": 39.2225,
				"close": 39.0425,
				"volume": 87937308,
				"time": 1508731200000,
				"lowest": 38.875,
				"highest": 39.4225,
				"volumePrice": 39.1411,
				"transactionCount": 128357,
				"voluemPrice": 39.1411
			},
			{
				"open": 39.0725,
				"close": 39.275,
				"volume": 71028920,
				"time": 1508817600000,
				"lowest": 39.05,
				"highest": 39.355,
				"volumePrice": 39.2353,
				"transactionCount": 109317,
				"voluemPrice": 39.2353
			},
			{
				"open": 39.2275,
				"close": 39.1025,
				"volume": 84828392,
				"time": 1508904000000,
				"lowest": 38.8175,
				"highest": 39.3875,
				"volumePrice": 39.095,
				"transactionCount": 130538,
				"voluemPrice": 39.095
			},
			{
				"open": 39.3075,
				"close": 39.3525,
				"volume": 68001876,
				"time": 1508990400000,
				"lowest": 39.195,
				"highest": 39.4574,
				"volumePrice": 39.3625,
				"transactionCount": 101043,
				"voluemPrice": 39.3625
			},
			{
				"open": 39.8225,
				"close": 40.7625,
				"volume": 177816640,
				"time": 1509076800000,
				"lowest": 39.675,
				"highest": 40.9,
				"volumePrice": 40.428,
				"transactionCount": 269608,
				"voluemPrice": 40.428
			},
			{
				"open": 40.9725,
				"close": 41.68,
				"volume": 178803088,
				"time": 1509336000000,
				"lowest": 40.93,
				"highest": 42.0175,
				"volumePrice": 41.61,
				"transactionCount": 267197,
				"voluemPrice": 41.61
			},
			{
				"open": 41.975,
				"close": 42.26,
				"volume": 144187312,
				"time": 1509422400000,
				"lowest": 41.735,
				"highest": 42.4125,
				"volumePrice": 42.1522,
				"transactionCount": 199376,
				"voluemPrice": 42.1522
			},
			{
				"open": 42.4675,
				"close": 41.7225,
				"volume": 134551048,
				"time": 1509508800000,
				"lowest": 41.4025,
				"highest": 42.485,
				"volumePrice": 41.7971,
				"transactionCount": 209939,
				"voluemPrice": 41.7971
			},
			{
				"open": 41.91,
				"close": 42.0275,
				"volume": 165461492,
				"time": 1509595200000,
				"lowest": 41.32,
				"highest": 42.125,
				"volumePrice": 42.1116,
				"transactionCount": 249910,
				"voluemPrice": 42.1116
			},
			{
				"open": 43.5,
				"close": 43.125,
				"volume": 237594524,
				"time": 1509681600000,
				"lowest": 42.78,
				"highest": 43.565,
				"volumePrice": 43.1798,
				"transactionCount": 336006,
				"voluemPrice": 43.1798
			},
			{
				"open": 43.0913,
				"close": 43.5625,
				"volume": 140105224,
				"time": 1509944400000,
				"lowest": 42.93,
				"highest": 43.7475,
				"volumePrice": 43.4954,
				"transactionCount": 194179,
				"voluemPrice": 43.4954
			},
			{
				"open": 43.4775,
				"close": 43.7025,
				"volume": 97445940,
				"time": 1510030800000,
				"lowest": 43.4,
				"highest": 43.8125,
				"volumePrice": 43.6555,
				"transactionCount": 145913,
				"voluemPrice": 43.6555
			},
			{
				"open": 43.665,
				"close": 44.06,
				"volume": 97638108,
				"time": 1510117200000,
				"lowest": 43.5825,
				"highest": 44.06,
				"volumePrice": 43.8765,
				"transactionCount": 135157,
				"voluemPrice": 43.8765
			},
			{
				"open": 43.7775,
				"close": 43.97,
				"volume": 117930384,
				"time": 1510203600000,
				"lowest": 43.285,
				"highest": 44.0238,
				"volumePrice": 43.7709,
				"transactionCount": 167075,
				"voluemPrice": 43.7709
			},
			{
				"open": 43.7775,
				"close": 43.6675,
				"volume": 100582000,
				"time": 1510290000000,
				"lowest": 43.5675,
				"highest": 43.845,
				"volumePrice": 43.7077,
				"transactionCount": 114341,
				"voluemPrice": 43.7077
			},
			{
				"open": 43.375,
				"close": 43.4925,
				"volume": 67928320,
				"time": 1510549200000,
				"lowest": 43.35,
				"highest": 43.625,
				"volumePrice": 43.5079,
				"transactionCount": 113757,
				"voluemPrice": 43.5079
			},
			{
				"open": 43.26,
				"close": 42.835,
				"volume": 99129948,
				"time": 1510635600000,
				"lowest": 42.795,
				"highest": 43.37,
				"volumePrice": 43.0583,
				"transactionCount": 154284,
				"voluemPrice": 43.0583
			},
			{
				"open": 42.4925,
				"close": 42.27,
				"volume": 116632280,
				"time": 1510722000000,
				"lowest": 42.095,
				"highest": 42.5799,
				"volumePrice": 42.3373,
				"transactionCount": 175223,
				"voluemPrice": 42.3373
			},
			{
				"open": 42.795,
				"close": 42.775,
				"volume": 94549936,
				"time": 1510808400000,
				"lowest": 42.575,
				"highest": 42.9675,
				"volumePrice": 42.7984,
				"transactionCount": 133293,
				"voluemPrice": 42.7984
			},
			{
				"open": 42.76,
				"close": 42.5375,
				"volume": 87598176,
				"time": 1510894800000,
				"lowest": 42.41,
				"highest": 42.8475,
				"volumePrice": 42.5985,
				"transactionCount": 130136,
				"voluemPrice": 42.5985
			},
			{
				"open": 42.5725,
				"close": 42.495,
				"volume": 65049788,
				"time": 1511154000000,
				"lowest": 42.39,
				"highest": 42.64,
				"volumePrice": 42.5002,
				"transactionCount": 95570,
				"voluemPrice": 42.5002
			},
			{
				"open": 42.695,
				"close": 43.285,
				"volume": 100525180,
				"time": 1511240400000,
				"lowest": 42.695,
				"highest": 43.425,
				"volumePrice": 43.2365,
				"transactionCount": 137407,
				"voluemPrice": 43.2365
			},
			{
				"open": 43.34,
				"close": 43.74,
				"volume": 102355700,
				"time": 1511326800000,
				"lowest": 43.2625,
				"highest": 43.75,
				"volumePrice": 43.5806,
				"transactionCount": 130434,
				"voluemPrice": 43.5806
			},
			{
				"open": 43.775,
				"close": 43.7425,
				"volume": 56106692,
				"time": 1511499600000,
				"lowest": 43.6615,
				"highest": 43.875,
				"volumePrice": 43.7734,
				"transactionCount": 64844,
				"voluemPrice": 43.7734
			},
			{
				"open": 43.7625,
				"close": 43.5225,
				"volume": 82867208,
				"time": 1511758800000,
				"lowest": 43.335,
				"highest": 43.77,
				"volumePrice": 43.566,
				"transactionCount": 118214,
				"voluemPrice": 43.566
			},
			{
				"open": 43.575,
				"close": 43.2675,
				"volume": 105715208,
				"time": 1511845200000,
				"lowest": 42.965,
				"highest": 43.7175,
				"volumePrice": 43.3066,
				"transactionCount": 140182,
				"voluemPrice": 43.3066
			},
			{
				"open": 43.1575,
				"close": 42.37,
				"volume": 166665456,
				"time": 1511931600000,
				"lowest": 41.79,
				"highest": 43.23,
				"volumePrice": 42.3548,
				"transactionCount": 285312,
				"voluemPrice": 42.3548
			},
			{
				"open": 42.6075,
				"close": 42.9625,
				"volume": 164890672,
				"time": 1512018000000,
				"lowest": 42.11,
				"highest": 43.035,
				"volumePrice": 42.7898,
				"transactionCount": 193995,
				"voluemPrice": 42.7898
			},
			{
				"open": 42.4875,
				"close": 42.7625,
				"volume": 159037152,
				"time": 1512104400000,
				"lowest": 42.125,
				"highest": 42.9175,
				"volumePrice": 42.6242,
				"transactionCount": 230316,
				"voluemPrice": 42.6242
			},
			{
				"open": 43.12,
				"close": 42.45,
				"volume": 130169540,
				"time": 1512363600000,
				"lowest": 42.4075,
				"highest": 43.155,
				"volumePrice": 42.7397,
				"transactionCount": 208562,
				"voluemPrice": 42.7397
			},
			{
				"open": 42.265,
				"close": 42.41,
				"volume": 109400616,
				"time": 1512450000000,
				"lowest": 42.1,
				"highest": 42.88,
				"volumePrice": 42.5573,
				"transactionCount": 172739,
				"voluemPrice": 42.5573
			},
			{
				"open": 41.875,
				"close": 42.2525,
				"volume": 114240000,
				"time": 1512536400000,
				"lowest": 41.615,
				"highest": 42.5512,
				"volumePrice": 42.2478,
				"transactionCount": 179979,
				"voluemPrice": 42.2478
			},
			{
				"open": 42.2575,
				"close": 42.33,
				"volume": 102693232,
				"time": 1512622800000,
				"lowest": 42.2275,
				"highest": 42.61,
				"volumePrice": 42.4052,
				"transactionCount": 161095,
				"voluemPrice": 42.4052
			},
			{
				"open": 42.6225,
				"close": 42.3425,
				"volume": 93420924,
				"time": 1512709200000,
				"lowest": 42.205,
				"highest": 42.75,
				"volumePrice": 42.4406,
				"transactionCount": 130251,
				"voluemPrice": 42.4406
			},
			{
				"open": 42.3,
				"close": 43.1675,
				"volume": 141095036,
				"time": 1512968400000,
				"lowest": 42.1975,
				"highest": 43.2225,
				"volumePrice": 42.969,
				"transactionCount": 177587,
				"voluemPrice": 42.969
			},
			{
				"open": 43.0375,
				"close": 42.925,
				"volume": 77636920,
				"time": 1513054800000,
				"lowest": 42.8653,
				"highest": 43.0975,
				"volumePrice": 42.9748,
				"transactionCount": 129478,
				"voluemPrice": 42.9748
			},
			{
				"open": 43.125,
				"close": 43.0675,
				"volume": 95273788,
				"time": 1513141200000,
				"lowest": 43,
				"highest": 43.385,
				"volumePrice": 43.1885,
				"transactionCount": 137309,
				"voluemPrice": 43.1885
			},
			{
				"open": 43.1,
				"close": 43.055,
				"volume": 81906164,
				"time": 1513227600000,
				"lowest": 42.9125,
				"highest": 43.2825,
				"volumePrice": 43.0721,
				"transactionCount": 138962,
				"voluemPrice": 43.0721
			},
			{
				"open": 43.4075,
				"close": 43.4925,
				"volume": 160677228,
				"time": 1513314000000,
				"lowest": 43.115,
				"highest": 43.5425,
				"volumePrice": 43.3895,
				"transactionCount": 155576,
				"voluemPrice": 43.3895
			},
			{
				"open": 43.72,
				"close": 44.105,
				"volume": 117684456,
				"time": 1513573200000,
				"lowest": 43.715,
				"highest": 44.3,
				"volumePrice": 44.0978,
				"transactionCount": 163229,
				"voluemPrice": 44.0978
			},
			{
				"open": 43.7575,
				"close": 43.635,
				"volume": 109687564,
				"time": 1513659600000,
				"lowest": 43.5225,
				"highest": 43.8475,
				"volumePrice": 43.6555,
				"transactionCount": 166197,
				"voluemPrice": 43.6555
			},
			{
				"open": 43.7175,
				"close": 43.5875,
				"volume": 93902596,
				"time": 1513746000000,
				"lowest": 43.3125,
				"highest": 43.855,
				"volumePrice": 43.5633,
				"transactionCount": 142780,
				"voluemPrice": 43.5633
			},
			{
				"open": 43.5425,
				"close": 43.7525,
				"volume": 83799584,
				"time": 1513832400000,
				"lowest": 43.525,
				"highest": 44.005,
				"volumePrice": 43.8082,
				"transactionCount": 117951,
				"voluemPrice": 43.8082
			},
			{
				"open": 43.67,
				"close": 43.7525,
				"volume": 65397776,
				"time": 1513918800000,
				"lowest": 43.625,
				"highest": 43.856,
				"volumePrice": 43.7588,
				"transactionCount": 97109,
				"voluemPrice": 43.7588
			},
			{
				"open": 42.7,
				"close": 42.6425,
				"volume": 132742144,
				"time": 1514264400000,
				"lowest": 42.4198,
				"highest": 42.8675,
				"volumePrice": 42.6138,
				"transactionCount": 222424,
				"voluemPrice": 42.6138
			},
			{
				"open": 42.525,
				"close": 42.65,
				"volume": 85992852,
				"time": 1514350800000,
				"lowest": 42.4275,
				"highest": 42.695,
				"volumePrice": 42.5683,
				"transactionCount": 134248,
				"voluemPrice": 42.5683
			},
			{
				"open": 42.75,
				"close": 42.77,
				"volume": 65920748,
				"time": 1514437200000,
				"lowest": 42.62,
				"highest": 42.9625,
				"volumePrice": 42.7892,
				"transactionCount": 106300,
				"voluemPrice": 42.7892
			},
			{
				"open": 42.63,
				"close": 42.3075,
				"volume": 103999688,
				"time": 1514523600000,
				"lowest": 42.305,
				"highest": 42.6475,
				"volumePrice": 42.439,
				"transactionCount": 144378,
				"voluemPrice": 42.439
			},
			{
				"open": 42.54,
				"close": 43.065,
				"volume": 102223736,
				"time": 1514869200000,
				"lowest": 42.315,
				"highest": 43.075,
				"volumePrice": 42.837,
				"transactionCount": 177086,
				"voluemPrice": 42.837
			},
			{
				"open": 43.1325,
				"close": 43.0575,
				"volume": 117981596,
				"time": 1514955600000,
				"lowest": 42.99,
				"highest": 43.6375,
				"volumePrice": 43.2781,
				"transactionCount": 188333,
				"voluemPrice": 43.2781
			},
			{
				"open": 43.135,
				"close": 43.2575,
				"volume": 89738388,
				"time": 1515042000000,
				"lowest": 43.02,
				"highest": 43.3675,
				"volumePrice": 43.2473,
				"transactionCount": 153150,
				"voluemPrice": 43.2473
			},
			{
				"open": 43.36,
				"close": 43.75,
				"volume": 94640072,
				"time": 1515128400000,
				"lowest": 43.2625,
				"highest": 43.8425,
				"volumePrice": 43.6732,
				"transactionCount": 152173,
				"voluemPrice": 43.6732
			},
			{
				"open": 43.5875,
				"close": 43.5875,
				"volume": 82271064,
				"time": 1515387600000,
				"lowest": 43.4825,
				"highest": 43.9025,
				"volumePrice": 43.6581,
				"transactionCount": 138842,
				"voluemPrice": 43.6581
			},
			{
				"open": 43.6375,
				"close": 43.5825,
				"volume": 86335988,
				"time": 1515474000000,
				"lowest": 43.3525,
				"highest": 43.765,
				"volumePrice": 43.5803,
				"transactionCount": 154006,
				"voluemPrice": 43.5803
			},
			{
				"open": 43.29,
				"close": 43.5725,
				"volume": 95839580,
				"time": 1515560400000,
				"lowest": 43.25,
				"highest": 43.575,
				"volumePrice": 43.4126,
				"transactionCount": 151201,
				"voluemPrice": 43.4126
			},
			{
				"open": 43.6475,
				"close": 43.82,
				"volume": 74670916,
				"time": 1515646800000,
				"lowest": 43.6225,
				"highest": 43.8722,
				"volumePrice": 43.7894,
				"transactionCount": 117864,
				"voluemPrice": 43.7894
			},
			{
				"open": 44.045,
				"close": 44.2725,
				"volume": 96812320,
				"time": 1515733200000,
				"lowest": 43.9125,
				"highest": 44.34,
				"volumePrice": 44.1806,
				"transactionCount": 151952,
				"voluemPrice": 44.1806
			},
			{
				"open": 44.475,
				"close": 44.0475,
				"volume": 118127788,
				"time": 1516078800000,
				"lowest": 44.035,
				"highest": 44.8475,
				"volumePrice": 44.3672,
				"transactionCount": 195534,
				"voluemPrice": 44.3672
			},
			{
				"open": 44.0375,
				"close": 44.775,
				"volume": 137547344,
				"time": 1516165200000,
				"lowest": 43.7675,
				"highest": 44.8125,
				"volumePrice": 44.3277,
				"transactionCount": 218162,
				"voluemPrice": 44.3277
			},
			{
				"open": 44.8425,
				"close": 44.815,
				"volume": 124773408,
				"time": 1516251600000,
				"lowest": 44.5625,
				"highest": 45.025,
				"volumePrice": 44.8455,
				"transactionCount": 185121,
				"voluemPrice": 44.8455
			},
			{
				"open": 44.6525,
				"close": 44.615,
				"volume": 129700268,
				"time": 1516338000000,
				"lowest": 44.3525,
				"highest": 44.895,
				"volumePrice": 44.6099,
				"transactionCount": 160569,
				"voluemPrice": 44.6099
			},
			{
				"open": 44.325,
				"close": 44.25,
				"volume": 108434204,
				"time": 1516597200000,
				"lowest": 44.1505,
				"highest": 44.445,
				"volumePrice": 44.2809,
				"transactionCount": 163847,
				"voluemPrice": 44.2809
			},
			{
				"open": 44.325,
				"close": 44.26,
				"volume": 130756584,
				"time": 1516683600000,
				"lowest": 44.205,
				"highest": 44.86,
				"volumePrice": 44.4905,
				"transactionCount": 211193,
				"voluemPrice": 44.4905
			},
			{
				"open": 44.3125,
				"close": 43.555,
				"volume": 204420320,
				"time": 1516770000000,
				"lowest": 43.3,
				"highest": 44.325,
				"volumePrice": 43.7034,
				"transactionCount": 325705,
				"voluemPrice": 43.7034
			},
			{
				"open": 43.6263,
				"close": 42.7775,
				"volume": 166116016,
				"time": 1516856400000,
				"lowest": 42.6325,
				"highest": 43.7375,
				"volumePrice": 43.0611,
				"transactionCount": 293742,
				"voluemPrice": 43.0611
			},
			{
				"open": 43,
				"close": 42.8775,
				"volume": 156498044,
				"time": 1516942800000,
				"lowest": 42.515,
				"highest": 43,
				"volumePrice": 42.7716,
				"transactionCount": 243617,
				"voluemPrice": 42.7716
			},
			{
				"open": 42.54,
				"close": 41.99,
				"volume": 202561624,
				"time": 1517202000000,
				"lowest": 41.7675,
				"highest": 42.54,
				"volumePrice": 42.0885,
				"transactionCount": 336844,
				"voluemPrice": 42.0885
			},
			{
				"open": 41.3813,
				"close": 41.7425,
				"volume": 184192740,
				"time": 1517288400000,
				"lowest": 41.175,
				"highest": 41.8425,
				"volumePrice": 41.5746,
				"transactionCount": 336706,
				"voluemPrice": 41.5746
			},
			{
				"open": 41.7175,
				"close": 41.8575,
				"volume": 129915720,
				"time": 1517374800000,
				"lowest": 41.625,
				"highest": 42.1104,
				"volumePrice": 41.8319,
				"transactionCount": 225335,
				"voluemPrice": 41.8319
			},
			{
				"open": 41.7913,
				"close": 41.945,
				"volume": 188923148,
				"time": 1517461200000,
				"lowest": 41.69,
				"highest": 42.155,
				"volumePrice": 41.9943,
				"transactionCount": 309291,
				"voluemPrice": 41.9943
			},
			{
				"open": 41.5,
				"close": 40.125,
				"volume": 346375300,
				"time": 1517547600000,
				"lowest": 40.025,
				"highest": 41.7,
				"volumePrice": 40.7329,
				"transactionCount": 604123,
				"voluemPrice": 40.7329
			},
			{
				"open": 39.775,
				"close": 39.1225,
				"volume": 290954088,
				"time": 1517806800000,
				"lowest": 39,
				"highest": 40.97,
				"volumePrice": 40.1086,
				"transactionCount": 528511,
				"voluemPrice": 40.1086
			},
			{
				"open": 38.7075,
				"close": 40.7575,
				"volume": 272875352,
				"time": 1517893200000,
				"lowest": 38.5,
				"highest": 40.93,
				"volumePrice": 39.9124,
				"transactionCount": 506990,
				"voluemPrice": 39.9124
			},
			{
				"open": 40.7713,
				"close": 39.885,
				"volume": 206434320,
				"time": 1517979600000,
				"lowest": 39.7671,
				"highest": 40.85,
				"volumePrice": 40.2772,
				"transactionCount": 341990,
				"voluemPrice": 40.2772
			},
			{
				"open": 40.0725,
				"close": 38.7875,
				"volume": 217562064,
				"time": 1518066000000,
				"lowest": 38.7575,
				"highest": 40.25,
				"volumePrice": 39.7284,
				"transactionCount": 366247,
				"voluemPrice": 39.7284
			},
			{
				"open": 39.2675,
				"close": 39.1025,
				"volume": 281690432,
				"time": 1518152400000,
				"lowest": 37.56,
				"highest": 39.4725,
				"volumePrice": 38.7376,
				"transactionCount": 442036,
				"voluemPrice": 38.7376
			},
			{
				"open": 39.625,
				"close": 40.6775,
				"volume": 240878156,
				"time": 1518411600000,
				"lowest": 39.3775,
				"highest": 40.9725,
				"volumePrice": 40.3959,
				"transactionCount": 362859,
				"voluemPrice": 40.3959
			},
			{
				"open": 40.4875,
				"close": 41.085,
				"volume": 130196652,
				"time": 1518498000000,
				"lowest": 40.4125,
				"highest": 41.1875,
				"volumePrice": 40.8481,
				"transactionCount": 219156,
				"voluemPrice": 40.8481
			},
			{
				"open": 40.7612,
				"close": 41.8425,
				"volume": 162514132,
				"time": 1518584400000,
				"lowest": 40.72,
				"highest": 41.885,
				"volumePrice": 41.4045,
				"transactionCount": 270794,
				"voluemPrice": 41.4045
			},
			{
				"open": 42.4475,
				"close": 43.2475,
				"volume": 204588684,
				"time": 1518670800000,
				"lowest": 42.25,
				"highest": 43.2725,
				"volumePrice": 42.8789,
				"transactionCount": 332578,
				"voluemPrice": 42.8789
			},
			{
				"open": 43.09,
				"close": 43.1075,
				"volume": 160647564,
				"time": 1518757200000,
				"lowest": 42.9425,
				"highest": 43.705,
				"volumePrice": 43.3616,
				"transactionCount": 255880,
				"voluemPrice": 43.3616
			},
			{
				"open": 43.0125,
				"close": 42.9625,
				"volume": 135722160,
				"time": 1519102800000,
				"lowest": 42.855,
				"highest": 43.565,
				"volumePrice": 43.2263,
				"transactionCount": 232791,
				"voluemPrice": 43.2263
			},
			{
				"open": 43.2075,
				"close": 42.7675,
				"volume": 149886492,
				"time": 1519189200000,
				"lowest": 42.7525,
				"highest": 43.53,
				"volumePrice": 43.2176,
				"transactionCount": 246543,
				"voluemPrice": 43.2176
			},
			{
				"open": 42.95,
				"close": 43.125,
				"volume": 123959360,
				"time": 1519275600000,
				"lowest": 42.9275,
				"highest": 43.4875,
				"volumePrice": 43.235,
				"transactionCount": 213686,
				"voluemPrice": 43.235
			},
			{
				"open": 43.4175,
				"close": 43.875,
				"volume": 135249440,
				"time": 1519362000000,
				"lowest": 43.385,
				"highest": 43.9125,
				"volumePrice": 43.672,
				"transactionCount": 215224,
				"voluemPrice": 43.672
			},
			{
				"open": 44.0875,
				"close": 44.7425,
				"volume": 152496696,
				"time": 1519621200000,
				"lowest": 44.0525,
				"highest": 44.8475,
				"volumePrice": 44.5999,
				"transactionCount": 248921,
				"voluemPrice": 44.5999
			},
			{
				"open": 44.775,
				"close": 44.5975,
				"volume": 155642500,
				"time": 1519707600000,
				"lowest": 44.54,
				"highest": 45.12,
				"volumePrice": 44.8211,
				"transactionCount": 259218,
				"voluemPrice": 44.8211
			},
			{
				"open": 44.815,
				"close": 44.53,
				"volume": 151128552,
				"time": 1519794000000,
				"lowest": 44.5125,
				"highest": 45.1538,
				"volumePrice": 44.7998,
				"transactionCount": 195754,
				"voluemPrice": 44.7998
			},
			{
				"open": 44.635,
				"close": 43.75,
				"volume": 195207880,
				"time": 1519880400000,
				"lowest": 43.165,
				"highest": 44.9438,
				"volumePrice": 44.023,
				"transactionCount": 345191,
				"voluemPrice": 44.023
			},
			{
				"open": 43.2,
				"close": 44.0525,
				"volume": 153815800,
				"time": 1519966800000,
				"lowest": 43.1125,
				"highest": 44.075,
				"volumePrice": 43.6924,
				"transactionCount": 249156,
				"voluemPrice": 43.6924
			},
			{
				"open": 43.8025,
				"close": 44.205,
				"volume": 113605464,
				"time": 1520226000000,
				"lowest": 43.63,
				"highest": 44.435,
				"volumePrice": 44.1388,
				"transactionCount": 183245,
				"voluemPrice": 44.1388
			},
			{
				"open": 44.4775,
				"close": 44.1675,
				"volume": 95154024,
				"time": 1520312400000,
				"lowest": 44.0325,
				"highest": 44.5625,
				"volumePrice": 44.2548,
				"transactionCount": 167005,
				"voluemPrice": 44.2548
			},
			{
				"open": 43.735,
				"close": 43.7575,
				"volume": 126813848,
				"time": 1520398800000,
				"lowest": 43.5675,
				"highest": 43.9625,
				"volumePrice": 43.7708,
				"transactionCount": 206266,
				"voluemPrice": 43.7708
			},
			{
				"open": 43.87,
				"close": 44.235,
				"volume": 95096428,
				"time": 1520485200000,
				"lowest": 43.7675,
				"highest": 44.28,
				"volumePrice": 44.0514,
				"transactionCount": 156523,
				"voluemPrice": 44.0514
			},
			{
				"open": 44.49,
				"close": 44.995,
				"volume": 128640648,
				"time": 1520571600000,
				"lowest": 44.3475,
				"highest": 45,
				"volumePrice": 44.7952,
				"transactionCount": 204157,
				"voluemPrice": 44.7952
			},
			{
				"open": 45.0725,
				"close": 45.43,
				"volume": 128822924,
				"time": 1520827200000,
				"lowest": 45.0525,
				"highest": 45.5975,
				"volumePrice": 45.4164,
				"transactionCount": 195339,
				"voluemPrice": 45.4164
			},
			{
				"open": 45.6475,
				"close": 44.9925,
				"volume": 126774116,
				"time": 1520913600000,
				"lowest": 44.81,
				"highest": 45.875,
				"volumePrice": 45.3926,
				"transactionCount": 216669,
				"voluemPrice": 45.3926
			},
			{
				"open": 45.08,
				"close": 44.61,
				"volume": 117473424,
				"time": 1521000000000,
				"lowest": 44.4525,
				"highest": 45.13,
				"volumePrice": 44.6973,
				"transactionCount": 211948,
				"voluemPrice": 44.6973
			},
			{
				"open": 44.625,
				"close": 44.6625,
				"volume": 90975192,
				"time": 1521086400000,
				"lowest": 44.5175,
				"highest": 45.06,
				"volumePrice": 44.7683,
				"transactionCount": 151401,
				"voluemPrice": 44.7683
			},
			{
				"open": 44.6625,
				"close": 44.505,
				"volume": 157618752,
				"time": 1521172800000,
				"lowest": 44.405,
				"highest": 44.78,
				"volumePrice": 44.5778,
				"transactionCount": 142731,
				"voluemPrice": 44.5778
			},
			{
				"open": 44.33,
				"close": 43.825,
				"volume": 133767084,
				"time": 1521432000000,
				"lowest": 43.415,
				"highest": 44.3675,
				"volumePrice": 43.8169,
				"transactionCount": 245568,
				"voluemPrice": 43.8169
			},
			{
				"open": 43.81,
				"close": 43.81,
				"volume": 78529600,
				"time": 1521518400000,
				"lowest": 43.735,
				"highest": 44.2,
				"volumePrice": 43.9334,
				"transactionCount": 135299,
				"voluemPrice": 43.9334
			},
			{
				"open": 43.76,
				"close": 42.8175,
				"volume": 148219740,
				"time": 1521604800000,
				"lowest": 42.815,
				"highest": 43.7725,
				"volumePrice": 43.2719,
				"transactionCount": 238349,
				"voluemPrice": 43.2719
			},
			{
				"open": 42.5,
				"close": 42.2125,
				"volume": 165563068,
				"time": 1521691200000,
				"lowest": 42.15,
				"highest": 43.17,
				"volumePrice": 42.545,
				"transactionCount": 289760,
				"voluemPrice": 42.545
			},
			{
				"open": 42.0975,
				"close": 41.235,
				"volume": 164115136,
				"time": 1521777600000,
				"lowest": 41.235,
				"highest": 42.48,
				"volumePrice": 41.8871,
				"transactionCount": 284667,
				"voluemPrice": 41.8871
			},
			{
				"open": 42.0175,
				"close": 43.1925,
				"volume": 150164944,
				"time": 1522036800000,
				"lowest": 41.61,
				"highest": 43.275,
				"volumePrice": 42.5127,
				"transactionCount": 232075,
				"voluemPrice": 42.5127
			},
			{
				"open": 43.42,
				"close": 42.085,
				"volume": 163690316,
				"time": 1522123200000,
				"lowest": 41.73,
				"highest": 43.7875,
				"volumePrice": 42.8488,
				"transactionCount": 276947,
				"voluemPrice": 42.8488
			},
			{
				"open": 41.8125,
				"close": 41.62,
				"volume": 162434180,
				"time": 1522209600000,
				"lowest": 41.2975,
				"highest": 42.505,
				"volumePrice": 41.803,
				"transactionCount": 305618,
				"voluemPrice": 41.803
			},
			{
				"open": 41.9513,
				"close": 41.945,
				"volume": 153594020,
				"time": 1522296000000,
				"lowest": 41.725,
				"highest": 42.9375,
				"volumePrice": 42.2911,
				"transactionCount": 219097,
				"voluemPrice": 42.2911
			},
			{
				"open": 41.97,
				"close": 41.67,
				"volume": 150144764,
				"time": 1522641600000,
				"lowest": 41.1175,
				"highest": 42.235,
				"volumePrice": 41.5902,
				"transactionCount": 291902,
				"voluemPrice": 41.5902
			},
			{
				"open": 41.91,
				"close": 42.0975,
				"volume": 121112184,
				"time": 1522728000000,
				"lowest": 41.22,
				"highest": 42.1864,
				"volumePrice": 41.7343,
				"transactionCount": 208588,
				"voluemPrice": 41.7343
			},
			{
				"open": 41.22,
				"close": 42.9025,
				"volume": 138421956,
				"time": 1522814400000,
				"lowest": 41.1925,
				"highest": 43.0025,
				"volumePrice": 42.3141,
				"transactionCount": 240829,
				"voluemPrice": 42.3141
			},
			{
				"open": 43.145,
				"close": 43.2,
				"volume": 107576788,
				"time": 1522900800000,
				"lowest": 43.02,
				"highest": 43.5576,
				"volumePrice": 43.3054,
				"transactionCount": 189682,
				"voluemPrice": 43.3054
			},
			{
				"open": 42.7425,
				"close": 42.095,
				"volume": 140021160,
				"time": 1522987200000,
				"lowest": 42.05,
				"highest": 43.12,
				"volumePrice": 42.4573,
				"transactionCount": 251948,
				"voluemPrice": 42.4573
			},
			{
				"open": 42.47,
				"close": 42.5125,
				"volume": 116070872,
				"time": 1523246400000,
				"lowest": 42.4613,
				"highest": 43.2725,
				"volumePrice": 42.8704,
				"transactionCount": 199533,
				"voluemPrice": 42.8704
			},
			{
				"open": 43.25,
				"close": 43.3125,
				"volume": 114456964,
				"time": 1523332800000,
				"lowest": 42.8825,
				"highest": 43.5,
				"volumePrice": 43.2577,
				"transactionCount": 202121,
				"voluemPrice": 43.2577
			},
			{
				"open": 43.0575,
				"close": 43.11,
				"volume": 89726560,
				"time": 1523419200000,
				"lowest": 42.925,
				"highest": 43.4808,
				"volumePrice": 43.1849,
				"transactionCount": 157146,
				"voluemPrice": 43.1849
			},
			{
				"open": 43.3525,
				"close": 43.535,
				"volume": 91557140,
				"time": 1523505600000,
				"lowest": 43.26,
				"highest": 43.75,
				"volumePrice": 43.5918,
				"transactionCount": 151617,
				"voluemPrice": 43.5918
			},
			{
				"open": 43.695,
				"close": 43.6825,
				"volume": 100497020,
				"time": 1523592000000,
				"lowest": 43.4625,
				"highest": 43.96,
				"volumePrice": 43.7154,
				"transactionCount": 156692,
				"voluemPrice": 43.7154
			},
			{
				"open": 43.7575,
				"close": 43.955,
				"volume": 86313680,
				"time": 1523851200000,
				"lowest": 43.7075,
				"highest": 44.0475,
				"volumePrice": 43.9117,
				"transactionCount": 155496,
				"voluemPrice": 43.9117
			},
			{
				"open": 44.1225,
				"close": 44.56,
				"volume": 106421768,
				"time": 1523937600000,
				"lowest": 44.1025,
				"highest": 44.7341,
				"volumePrice": 44.469,
				"transactionCount": 168077,
				"voluemPrice": 44.469
			},
			{
				"open": 44.4525,
				"close": 44.46,
				"volume": 83018152,
				"time": 1524024000000,
				"lowest": 44.22,
				"highest": 44.705,
				"volumePrice": 44.4873,
				"transactionCount": 136337,
				"voluemPrice": 44.4873
			},
			{
				"open": 43.7375,
				"close": 43.2,
				"volume": 139235200,
				"time": 1524110400000,
				"lowest": 43.165,
				"highest": 43.8475,
				"volumePrice": 43.4099,
				"transactionCount": 230444,
				"voluemPrice": 43.4099
			},
			{
				"open": 42.6488,
				"close": 41.43,
				"volume": 261964560,
				"time": 1524196800000,
				"lowest": 41.3575,
				"highest": 42.8046,
				"volumePrice": 41.7681,
				"transactionCount": 402197,
				"voluemPrice": 41.7681
			},
			{
				"open": 41.7087,
				"close": 41.31,
				"volume": 146051908,
				"time": 1524456000000,
				"lowest": 41.0225,
				"highest": 41.73,
				"volumePrice": 41.3961,
				"transactionCount": 238207,
				"voluemPrice": 41.3961
			},
			{
				"open": 41.4175,
				"close": 40.735,
				"volume": 134768068,
				"time": 1524542400000,
				"lowest": 40.305,
				"highest": 41.5825,
				"volumePrice": 40.9055,
				"transactionCount": 258893,
				"voluemPrice": 40.9055
			},
			{
				"open": 40.655,
				"close": 40.9125,
				"volume": 113528336,
				"time": 1524628800000,
				"lowest": 40.6025,
				"highest": 41.355,
				"volumePrice": 41.053,
				"transactionCount": 204096,
				"voluemPrice": 41.053
			},
			{
				"open": 41.03,
				"close": 41.055,
				"volume": 111852056,
				"time": 1524715200000,
				"lowest": 40.8425,
				"highest": 41.4325,
				"volumePrice": 41.1057,
				"transactionCount": 195095,
				"voluemPrice": 41.1057
			},
			{
				"open": 41,
				"close": 40.58,
				"volume": 142623356,
				"time": 1524801600000,
				"lowest": 40.1575,
				"highest": 41.0825,
				"volumePrice": 40.5722,
				"transactionCount": 253203,
				"voluemPrice": 40.5722
			},
			{
				"open": 40.5326,
				"close": 41.315,
				"volume": 169709696,
				"time": 1525060800000,
				"lowest": 40.46,
				"highest": 41.815,
				"volumePrice": 41.3794,
				"transactionCount": 264802,
				"voluemPrice": 41.3794
			},
			{
				"open": 41.6026,
				"close": 42.275,
				"volume": 214277504,
				"time": 1525147200000,
				"lowest": 41.3175,
				"highest": 42.3,
				"volumePrice": 42.2826,
				"transactionCount": 353860,
				"voluemPrice": 42.2826
			},
			{
				"open": 43.8063,
				"close": 44.1425,
				"volume": 266157484,
				"time": 1525233600000,
				"lowest": 43.45,
				"highest": 44.4375,
				"volumePrice": 44.0306,
				"transactionCount": 437594,
				"voluemPrice": 44.0306
			},
			{
				"open": 43.97,
				"close": 44.2225,
				"volume": 136272720,
				"time": 1525320000000,
				"lowest": 43.6103,
				"highest": 44.375,
				"volumePrice": 44.0252,
				"transactionCount": 246056,
				"voluemPrice": 44.0252
			},
			{
				"open": 44.5625,
				"close": 45.9575,
				"volume": 224805268,
				"time": 1525406400000,
				"lowest": 44.5425,
				"highest": 46.0625,
				"volumePrice": 45.6386,
				"transactionCount": 370760,
				"voluemPrice": 45.6386
			},
			{
				"open": 46.295,
				"close": 46.29,
				"volume": 169805692,
				"time": 1525665600000,
				"lowest": 46.1875,
				"highest": 46.9175,
				"volumePrice": 46.554,
				"transactionCount": 267001,
				"voluemPrice": 46.554
			},
			{
				"open": 46.2475,
				"close": 46.5125,
				"volume": 113611108,
				"time": 1525752000000,
				"lowest": 45.9163,
				"highest": 46.555,
				"volumePrice": 46.3212,
				"transactionCount": 180005,
				"voluemPrice": 46.3212
			},
			{
				"open": 46.6375,
				"close": 46.84,
				"volume": 92844964,
				"time": 1525838400000,
				"lowest": 46.305,
				"highest": 46.85,
				"volumePrice": 46.6121,
				"transactionCount": 161097,
				"voluemPrice": 46.6121
			},
			{
				"open": 46.935,
				"close": 47.51,
				"volume": 111957156,
				"time": 1525924800000,
				"lowest": 46.9125,
				"highest": 47.5925,
				"volumePrice": 47.3399,
				"transactionCount": 185513,
				"voluemPrice": 47.3399
			},
			{
				"open": 47.3725,
				"close": 47.1475,
				"volume": 104448884,
				"time": 1526011200000,
				"lowest": 46.8625,
				"highest": 47.515,
				"volumePrice": 47.1919,
				"transactionCount": 157650,
				"voluemPrice": 47.1919
			},
			{
				"open": 47.2525,
				"close": 47.0375,
				"volume": 82923888,
				"time": 1526270400000,
				"lowest": 46.965,
				"highest": 47.3825,
				"volumePrice": 47.1584,
				"transactionCount": 127757,
				"voluemPrice": 47.1584
			},
			{
				"open": 46.695,
				"close": 46.61,
				"volume": 94780636,
				"time": 1526356800000,
				"lowest": 46.275,
				"highest": 46.7675,
				"volumePrice": 46.5342,
				"transactionCount": 171232,
				"voluemPrice": 46.5342
			},
			{
				"open": 46.5175,
				"close": 47.045,
				"volume": 76732256,
				"time": 1526443200000,
				"lowest": 46.5,
				"highest": 47.115,
				"volumePrice": 46.9165,
				"transactionCount": 126071,
				"voluemPrice": 46.9165
			},
			{
				"open": 47,
				"close": 46.7475,
				"volume": 69176116,
				"time": 1526529600000,
				"lowest": 46.59,
				"highest": 47.2275,
				"volumePrice": 46.8974,
				"transactionCount": 116029,
				"voluemPrice": 46.8974
			},
			{
				"open": 46.7975,
				"close": 46.5775,
				"volume": 73004512,
				"time": 1526616000000,
				"lowest": 46.5325,
				"highest": 46.9526,
				"volumePrice": 46.7305,
				"transactionCount": 116586,
				"voluemPrice": 46.7305
			},
			{
				"open": 47,
				"close": 46.9075,
				"volume": 73603148,
				"time": 1526875200000,
				"lowest": 46.7276,
				"highest": 47.3175,
				"volumePrice": 47.0207,
				"transactionCount": 129162,
				"voluemPrice": 47.0207
			},
			{
				"open": 47.0938,
				"close": 46.79,
				"volume": 60962816,
				"time": 1526961600000,
				"lowest": 46.695,
				"highest": 47.22,
				"volumePrice": 46.8904,
				"transactionCount": 104401,
				"voluemPrice": 46.8904
			},
			{
				"open": 46.5875,
				"close": 47.09,
				"volume": 80233660,
				"time": 1527048000000,
				"lowest": 46.44,
				"highest": 47.125,
				"volumePrice": 46.791,
				"transactionCount": 137623,
				"voluemPrice": 46.791
			},
			{
				"open": 47.1925,
				"close": 47.0375,
				"volume": 92935900,
				"time": 1527134400000,
				"lowest": 46.5525,
				"highest": 47.21,
				"volumePrice": 46.9695,
				"transactionCount": 132054,
				"voluemPrice": 46.9695
			},
			{
				"open": 47.0575,
				"close": 47.145,
				"volume": 69843852,
				"time": 1527220800000,
				"lowest": 46.9125,
				"highest": 47.4125,
				"volumePrice": 47.2199,
				"transactionCount": 122853,
				"voluemPrice": 47.2199
			},
			{
				"open": 46.9,
				"close": 46.975,
				"volume": 90056300,
				"time": 1527566400000,
				"lowest": 46.7175,
				"highest": 47.1875,
				"volumePrice": 46.9229,
				"transactionCount": 161043,
				"voluemPrice": 46.9229
			},
			{
				"open": 46.93,
				"close": 46.875,
				"volume": 74762188,
				"time": 1527652800000,
				"lowest": 46.695,
				"highest": 47,
				"volumePrice": 46.827,
				"transactionCount": 132233,
				"voluemPrice": 46.827
			},
			{
				"open": 46.805,
				"close": 46.7175,
				"volume": 109931172,
				"time": 1527739200000,
				"lowest": 46.535,
				"highest": 47.0575,
				"volumePrice": 46.7823,
				"transactionCount": 151558,
				"voluemPrice": 46.7823
			},
			{
				"open": 46.9978,
				"close": 47.56,
				"volume": 93770040,
				"time": 1527825600000,
				"lowest": 46.9375,
				"highest": 47.565,
				"volumePrice": 47.3789,
				"transactionCount": 160822,
				"voluemPrice": 47.3789
			},
			{
				"open": 47.9088,
				"close": 47.9575,
				"volume": 105064696,
				"time": 1528084800000,
				"lowest": 47.8375,
				"highest": 48.355,
				"volumePrice": 48.0584,
				"transactionCount": 181277,
				"voluemPrice": 48.0584
			},
			{
				"open": 48.2663,
				"close": 48.3275,
				"volume": 86263852,
				"time": 1528171200000,
				"lowest": 48.09,
				"highest": 48.485,
				"volumePrice": 48.3029,
				"transactionCount": 149330,
				"voluemPrice": 48.3029
			},
			{
				"open": 48.4075,
				"close": 48.495,
				"volume": 82134476,
				"time": 1528257600000,
				"lowest": 47.98,
				"highest": 48.52,
				"volumePrice": 48.3226,
				"transactionCount": 132988,
				"voluemPrice": 48.3226
			},
			{
				"open": 48.535,
				"close": 48.365,
				"volume": 85315120,
				"time": 1528344000000,
				"lowest": 48.0838,
				"highest": 48.55,
				"volumePrice": 48.3307,
				"transactionCount": 142643,
				"voluemPrice": 48.3307
			},
			{
				"open": 47.7925,
				"close": 47.925,
				"volume": 106627196,
				"time": 1528430400000,
				"lowest": 47.4425,
				"highest": 48,
				"volumePrice": 47.74,
				"transactionCount": 176197,
				"voluemPrice": 47.74
			},
			{
				"open": 47.8375,
				"close": 47.8075,
				"volume": 73233840,
				"time": 1528689600000,
				"lowest": 47.5525,
				"highest": 47.9925,
				"volumePrice": 47.8011,
				"transactionCount": 132598,
				"voluemPrice": 47.8011
			},
			{
				"open": 47.8463,
				"close": 48.07,
				"volume": 67644564,
				"time": 1528776000000,
				"lowest": 47.7875,
				"highest": 48.1528,
				"volumePrice": 47.951,
				"transactionCount": 118184,
				"voluemPrice": 47.951
			},
			{
				"open": 48.105,
				"close": 47.675,
				"volume": 86553572,
				"time": 1528862400000,
				"lowest": 47.61,
				"highest": 48.22,
				"volumePrice": 47.8932,
				"transactionCount": 138317,
				"voluemPrice": 47.8932
			},
			{
				"open": 47.8875,
				"close": 47.7,
				"volume": 86440296,
				"time": 1528948800000,
				"lowest": 47.555,
				"highest": 47.8925,
				"volumePrice": 47.7265,
				"transactionCount": 143597,
				"voluemPrice": 47.7265
			},
			{
				"open": 47.5075,
				"close": 47.21,
				"volume": 246876640,
				"time": 1529035200000,
				"lowest": 47.065,
				"highest": 47.54,
				"volumePrice": 47.2652,
				"transactionCount": 210545,
				"voluemPrice": 47.2652
			},
			{
				"open": 46.97,
				"close": 47.185,
				"volume": 73939460,
				"time": 1529294400000,
				"lowest": 46.8,
				"highest": 47.305,
				"volumePrice": 47.155,
				"transactionCount": 134390,
				"voluemPrice": 47.155
			},
			{
				"open": 46.285,
				"close": 46.4225,
				"volume": 134313820,
				"time": 1529380800000,
				"lowest": 45.8625,
				"highest": 46.5825,
				"volumePrice": 46.3223,
				"transactionCount": 232336,
				"voluemPrice": 46.3223
			},
			{
				"open": 46.5875,
				"close": 46.625,
				"volume": 82514804,
				"time": 1529467200000,
				"lowest": 46.4325,
				"highest": 46.8,
				"volumePrice": 46.6308,
				"transactionCount": 145667,
				"voluemPrice": 46.6308
			},
			{
				"open": 46.8125,
				"close": 46.365,
				"volume": 102847592,
				"time": 1529553600000,
				"lowest": 46.235,
				"highest": 47.0875,
				"volumePrice": 46.5055,
				"transactionCount": 157832,
				"voluemPrice": 46.5055
			},
			{
				"open": 46.53,
				"close": 46.23,
				"volume": 108801788,
				"time": 1529640000000,
				"lowest": 46.175,
				"highest": 46.5375,
				"volumePrice": 46.3096,
				"transactionCount": 124480,
				"voluemPrice": 46.3096
			},
			{
				"open": 45.85,
				"close": 45.5425,
				"volume": 126652384,
				"time": 1529899200000,
				"lowest": 45.1825,
				"highest": 46.23,
				"volumePrice": 45.59,
				"transactionCount": 241961,
				"voluemPrice": 45.59
			},
			{
				"open": 45.7475,
				"close": 46.1075,
				"volume": 98276804,
				"time": 1529985600000,
				"lowest": 45.635,
				"highest": 46.6325,
				"volumePrice": 46.187,
				"transactionCount": 169648,
				"voluemPrice": 46.187
			},
			{
				"open": 46.307,
				"close": 46.04,
				"volume": 101141312,
				"time": 1530072000000,
				"lowest": 46.0075,
				"highest": 46.82,
				"volumePrice": 46.3712,
				"transactionCount": 179715,
				"voluemPrice": 46.3712
			},
			{
				"open": 46.025,
				"close": 46.375,
				"volume": 69460940,
				"time": 1530158400000,
				"lowest": 45.95,
				"highest": 46.5525,
				"volumePrice": 46.2875,
				"transactionCount": 145460,
				"voluemPrice": 46.2875
			},
			{
				"open": 46.5725,
				"close": 46.2775,
				"volume": 90950664,
				"time": 1530244800000,
				"lowest": 45.7275,
				"highest": 46.7975,
				"volumePrice": 46.4692,
				"transactionCount": 141204,
				"voluemPrice": 46.4692
			},
			{
				"open": 45.955,
				"close": 46.795,
				"volume": 70925372,
				"time": 1530504000000,
				"lowest": 45.855,
				"highest": 46.825,
				"volumePrice": 46.5618,
				"transactionCount": 137100,
				"voluemPrice": 46.5618
			},
			{
				"open": 46.9475,
				"close": 45.98,
				"volume": 55819224,
				"time": 1530590400000,
				"lowest": 45.885,
				"highest": 46.9875,
				"volumePrice": 46.4616,
				"transactionCount": 100904,
				"voluemPrice": 46.4616
			},
			{
				"open": 46.315,
				"close": 46.35,
				"volume": 66416992,
				"time": 1530763200000,
				"lowest": 46.07,
				"highest": 46.6025,
				"volumePrice": 46.371,
				"transactionCount": 131301,
				"voluemPrice": 46.371
			},
			{
				"open": 46.355,
				"close": 46.9925,
				"volume": 69940980,
				"time": 1530849600000,
				"lowest": 46.3,
				"highest": 47.1085,
				"volumePrice": 46.8394,
				"transactionCount": 137779,
				"voluemPrice": 46.8394
			},
			{
				"open": 47.375,
				"close": 47.645,
				"volume": 79026536,
				"time": 1531108800000,
				"lowest": 47.325,
				"highest": 47.67,
				"volumePrice": 47.555,
				"transactionCount": 141805,
				"voluemPrice": 47.555
			},
			{
				"open": 47.6775,
				"close": 47.5875,
				"volume": 63756596,
				"time": 1531195200000,
				"lowest": 47.545,
				"highest": 47.82,
				"volumePrice": 47.6624,
				"transactionCount": 120125,
				"voluemPrice": 47.6624
			},
			{
				"open": 47.125,
				"close": 46.97,
				"volume": 75325880,
				"time": 1531281600000,
				"lowest": 46.9025,
				"highest": 47.445,
				"volumePrice": 47.0876,
				"transactionCount": 136555,
				"voluemPrice": 47.0876
			},
			{
				"open": 47.3825,
				"close": 47.7575,
				"volume": 72164524,
				"time": 1531368000000,
				"lowest": 47.3275,
				"highest": 47.8525,
				"volumePrice": 47.668,
				"transactionCount": 132653,
				"voluemPrice": 47.668
			},
			{
				"open": 47.77,
				"close": 47.8325,
				"volume": 50079168,
				"time": 1531454400000,
				"lowest": 47.725,
				"highest": 47.96,
				"volumePrice": 47.8576,
				"transactionCount": 93741,
				"voluemPrice": 47.8576
			},
			{
				"open": 47.88,
				"close": 47.7275,
				"volume": 60172440,
				"time": 1531713600000,
				"lowest": 47.6038,
				"highest": 48.1625,
				"volumePrice": 47.8142,
				"transactionCount": 103711,
				"voluemPrice": 47.8142
			},
			{
				"open": 47.4375,
				"close": 47.8625,
				"volume": 62138092,
				"time": 1531800000000,
				"lowest": 47.3,
				"highest": 47.9675,
				"volumePrice": 47.7309,
				"transactionCount": 119530,
				"voluemPrice": 47.7309
			},
			{
				"open": 47.945,
				"close": 47.6,
				"volume": 65573524,
				"time": 1531886400000,
				"lowest": 47.4825,
				"highest": 47.95,
				"volumePrice": 47.5987,
				"transactionCount": 112844,
				"voluemPrice": 47.5987
			},
			{
				"open": 47.4225,
				"close": 47.97,
				"volume": 81147008,
				"time": 1531972800000,
				"lowest": 47.4225,
				"highest": 48.1375,
				"volumePrice": 47.9523,
				"transactionCount": 137847,
				"voluemPrice": 47.9523
			},
			{
				"open": 47.945,
				"close": 47.86,
				"volume": 82083368,
				"time": 1532059200000,
				"lowest": 47.5425,
				"highest": 48.1075,
				"volumePrice": 47.8918,
				"transactionCount": 119863,
				"voluemPrice": 47.8918
			},
			{
				"open": 47.67,
				"close": 47.9025,
				"volume": 63957460,
				"time": 1532318400000,
				"lowest": 47.39,
				"highest": 47.99,
				"volumePrice": 47.7305,
				"transactionCount": 114789,
				"voluemPrice": 47.7305
			},
			{
				"open": 48.1125,
				"close": 48.25,
				"volume": 74791592,
				"time": 1532404800000,
				"lowest": 48.0125,
				"highest": 48.415,
				"volumePrice": 48.2107,
				"transactionCount": 145009,
				"voluemPrice": 48.2107
			},
			{
				"open": 48.2651,
				"close": 48.705,
				"volume": 67305932,
				"time": 1532491200000,
				"lowest": 48.1075,
				"highest": 48.7125,
				"volumePrice": 48.4394,
				"transactionCount": 137883,
				"voluemPrice": 48.4394
			},
			{
				"open": 48.6525,
				"close": 48.5525,
				"volume": 76303856,
				"time": 1532577600000,
				"lowest": 48.4025,
				"highest": 48.99,
				"volumePrice": 48.6784,
				"transactionCount": 151386,
				"voluemPrice": 48.6784
			},
			{
				"open": 48.7475,
				"close": 47.745,
				"volume": 96095888,
				"time": 1532664000000,
				"lowest": 47.525,
				"highest": 48.7975,
				"volumePrice": 48.0499,
				"transactionCount": 195052,
				"voluemPrice": 48.0499
			},
			{
				"open": 47.975,
				"close": 47.4775,
				"volume": 84064140,
				"time": 1532923200000,
				"lowest": 47.2675,
				"highest": 48.05,
				"volumePrice": 47.5022,
				"transactionCount": 171100,
				"voluemPrice": 47.5022
			},
			{
				"open": 47.575,
				"close": 47.5725,
				"volume": 157492152,
				"time": 1533009600000,
				"lowest": 47.335,
				"highest": 48.035,
				"volumePrice": 47.9301,
				"transactionCount": 293159,
				"voluemPrice": 47.9301
			},
			{
				"open": 49.7825,
				"close": 50.375,
				"volume": 271742564,
				"time": 1533096000000,
				"lowest": 49.3275,
				"highest": 50.44,
				"volumePrice": 49.9819,
				"transactionCount": 459439,
				"voluemPrice": 49.9819
			},
			{
				"open": 50.145,
				"close": 51.8475,
				"volume": 249616048,
				"time": 1533182400000,
				"lowest": 50.0875,
				"highest": 52.095,
				"volumePrice": 51.4649,
				"transactionCount": 448697,
				"voluemPrice": 51.4649
			},
			{
				"open": 51.7575,
				"close": 51.9975,
				"volume": 133789584,
				"time": 1533268800000,
				"lowest": 51.3701,
				"highest": 52.185,
				"volumePrice": 51.834,
				"transactionCount": 247600,
				"voluemPrice": 51.834
			},
			{
				"open": 52,
				"close": 52.2675,
				"volume": 101701548,
				"time": 1533528000000,
				"lowest": 51.7675,
				"highest": 52.3125,
				"volumePrice": 52.1243,
				"transactionCount": 171199,
				"voluemPrice": 52.1243
			},
			{
				"open": 52.33,
				"close": 51.7775,
				"volume": 102349548,
				"time": 1533614400000,
				"lowest": 51.69,
				"highest": 52.375,
				"volumePrice": 51.8984,
				"transactionCount": 198995,
				"voluemPrice": 51.8984
			},
			{
				"open": 51.5125,
				"close": 51.8125,
				"volume": 90101948,
				"time": 1533700800000,
				"lowest": 51.13,
				"highest": 51.9525,
				"volumePrice": 51.6997,
				"transactionCount": 166918,
				"voluemPrice": 51.6997
			},
			{
				"open": 51.82,
				"close": 52.22,
				"volume": 93880444,
				"time": 1533787200000,
				"lowest": 51.8,
				"highest": 52.445,
				"volumePrice": 52.2695,
				"transactionCount": 162249,
				"voluemPrice": 52.2695
			},
			{
				"open": 51.84,
				"close": 51.8825,
				"volume": 98444808,
				"time": 1533873600000,
				"lowest": 51.6675,
				"highest": 52.275,
				"volumePrice": 51.9733,
				"transactionCount": 182720,
				"voluemPrice": 51.9733
			},
			{
				"open": 51.925,
				"close": 52.2175,
				"volume": 103562720,
				"time": 1534132800000,
				"lowest": 51.925,
				"highest": 52.738,
				"volumePrice": 52.3936,
				"transactionCount": 213855,
				"voluemPrice": 52.3936
			},
			{
				"open": 52.5388,
				"close": 52.4375,
				"volume": 82992040,
				"time": 1534219200000,
				"lowest": 52.065,
				"highest": 52.64,
				"volumePrice": 52.3732,
				"transactionCount": 152902,
				"voluemPrice": 52.3732
			},
			{
				"open": 52.305,
				"close": 52.56,
				"volume": 115230256,
				"time": 1534305600000,
				"lowest": 52.0825,
				"highest": 52.685,
				"volumePrice": 52.3546,
				"transactionCount": 243017,
				"voluemPrice": 52.3546
			},
			{
				"open": 52.9375,
				"close": 53.33,
				"volume": 114001468,
				"time": 1534392000000,
				"lowest": 52.8675,
				"highest": 53.453,
				"volumePrice": 53.255,
				"transactionCount": 197180,
				"voluemPrice": 53.255
			},
			{
				"open": 53.36,
				"close": 54.395,
				"volume": 141707988,
				"time": 1534478400000,
				"lowest": 53.29,
				"highest": 54.4875,
				"volumePrice": 54.0149,
				"transactionCount": 260634,
				"voluemPrice": 54.0149
			},
			{
				"open": 54.525,
				"close": 53.865,
				"volume": 121150780,
				"time": 1534737600000,
				"lowest": 53.7775,
				"highest": 54.795,
				"volumePrice": 54.1174,
				"transactionCount": 216769,
				"voluemPrice": 54.1174
			},
			{
				"open": 54.2,
				"close": 53.76,
				"volume": 104639020,
				"time": 1534824000000,
				"lowest": 53.5063,
				"highest": 54.2975,
				"volumePrice": 53.9052,
				"transactionCount": 198228,
				"voluemPrice": 53.9052
			},
			{
				"open": 53.525,
				"close": 53.7625,
				"volume": 76072524,
				"time": 1534910400000,
				"lowest": 53.46,
				"highest": 54.09,
				"volumePrice": 53.7963,
				"transactionCount": 141365,
				"voluemPrice": 53.7963
			},
			{
				"open": 53.6625,
				"close": 53.8725,
				"volume": 75452896,
				"time": 1534996800000,
				"lowest": 53.65,
				"highest": 54.2625,
				"volumePrice": 53.989,
				"transactionCount": 143267,
				"voluemPrice": 53.989
			},
			{
				"open": 54.15,
				"close": 54.04,
				"volume": 73905424,
				"time": 1535083200000,
				"lowest": 53.7775,
				"highest": 54.225,
				"volumePrice": 54.0207,
				"transactionCount": 135717,
				"voluemPrice": 54.0207
			},
			{
				"open": 54.2875,
				"close": 54.485,
				"volume": 82007668,
				"time": 1535342400000,
				"lowest": 54.0825,
				"highest": 54.685,
				"volumePrice": 54.4479,
				"transactionCount": 168317,
				"voluemPrice": 54.4479
			},
			{
				"open": 54.7525,
				"close": 54.925,
				"volume": 91107064,
				"time": 1535428800000,
				"lowest": 54.73,
				"highest": 55.135,
				"volumePrice": 54.9247,
				"transactionCount": 178899,
				"voluemPrice": 54.9247
			},
			{
				"open": 55.0375,
				"close": 55.745,
				"volume": 108947816,
				"time": 1535515200000,
				"lowest": 54.8525,
				"highest": 55.8725,
				"volumePrice": 55.4593,
				"transactionCount": 216646,
				"voluemPrice": 55.4593
			},
			{
				"open": 55.8125,
				"close": 56.2575,
				"volume": 195175296,
				"time": 1535601600000,
				"lowest": 55.6,
				"highest": 57.065,
				"volumePrice": 56.3121,
				"transactionCount": 364665,
				"voluemPrice": 56.3121
			},
			{
				"open": 56.6275,
				"close": 56.9075,
				"volume": 173360536,
				"time": 1535688000000,
				"lowest": 56.5525,
				"highest": 57.2175,
				"volumePrice": 56.9216,
				"transactionCount": 289585,
				"voluemPrice": 56.9216
			},
			{
				"open": 57.1025,
				"close": 57.09,
				"volume": 109560528,
				"time": 1536033600000,
				"lowest": 56.6575,
				"highest": 57.295,
				"volumePrice": 57.0274,
				"transactionCount": 221421,
				"voluemPrice": 57.0274
			},
			{
				"open": 57.2475,
				"close": 56.7175,
				"volume": 133195840,
				"time": 1536120000000,
				"lowest": 56.275,
				"highest": 57.4175,
				"volumePrice": 56.8047,
				"transactionCount": 265320,
				"voluemPrice": 56.8047
			},
			{
				"open": 56.5575,
				"close": 55.775,
				"volume": 137159904,
				"time": 1536206400000,
				"lowest": 55.325,
				"highest": 56.8375,
				"volumePrice": 55.8938,
				"transactionCount": 281778,
				"voluemPrice": 55.8938
			},
			{
				"open": 55.4625,
				"close": 55.325,
				"volume": 150479240,
				"time": 1536292800000,
				"lowest": 55.1775,
				"highest": 56.3425,
				"volumePrice": 55.7917,
				"transactionCount": 302905,
				"voluemPrice": 55.7917
			},
			{
				"open": 55.2375,
				"close": 54.5825,
				"volume": 158040612,
				"time": 1536552000000,
				"lowest": 54.1175,
				"highest": 55.4625,
				"volumePrice": 54.5691,
				"transactionCount": 349531,
				"voluemPrice": 54.5691
			},
			{
				"open": 54.5025,
				"close": 55.9625,
				"volume": 142996196,
				"time": 1536638400000,
				"lowest": 54.14,
				"highest": 56.0748,
				"volumePrice": 55.4857,
				"transactionCount": 308573,
				"voluemPrice": 55.4857
			},
			{
				"open": 56.235,
				"close": 55.2675,
				"volume": 197114960,
				"time": 1536724800000,
				"lowest": 54.96,
				"highest": 56.25,
				"volumePrice": 55.4357,
				"transactionCount": 422485,
				"voluemPrice": 55.4357
			},
			{
				"open": 55.88,
				"close": 56.6025,
				"volume": 166525508,
				"time": 1536811200000,
				"lowest": 55.6425,
				"highest": 57.0875,
				"volumePrice": 56.4775,
				"transactionCount": 323339,
				"voluemPrice": 56.4775
			},
			{
				"open": 56.4375,
				"close": 55.96,
				"volume": 127997156,
				"time": 1536897600000,
				"lowest": 55.6305,
				"highest": 56.71,
				"volumePrice": 56.087,
				"transactionCount": 244692,
				"voluemPrice": 56.087
			},
			{
				"open": 55.5375,
				"close": 54.47,
				"volume": 148780532,
				"time": 1537156800000,
				"lowest": 54.3175,
				"highest": 55.7375,
				"volumePrice": 54.9056,
				"transactionCount": 315705,
				"voluemPrice": 54.9056
			},
			{
				"open": 54.4475,
				"close": 54.56,
				"volume": 126286848,
				"time": 1537243200000,
				"lowest": 54.28,
				"highest": 55.4625,
				"volumePrice": 54.8653,
				"transactionCount": 263816,
				"voluemPrice": 54.8653
			},
			{
				"open": 54.625,
				"close": 54.5925,
				"volume": 108424932,
				"time": 1537329600000,
				"lowest": 53.825,
				"highest": 54.905,
				"volumePrice": 54.359,
				"transactionCount": 234513,
				"voluemPrice": 54.359
			},
			{
				"open": 55.06,
				"close": 55.0075,
				"volume": 106435176,
				"time": 1537416000000,
				"lowest": 54.7875,
				"highest": 55.57,
				"volumePrice": 55.1929,
				"transactionCount": 218332,
				"voluemPrice": 55.1929
			},
			{
				"open": 55.195,
				"close": 54.415,
				"volume": 384986992,
				"time": 1537502400000,
				"lowest": 54.3225,
				"highest": 55.34,
				"volumePrice": 54.586,
				"transactionCount": 281178,
				"voluemPrice": 54.586
			},
			{
				"open": 54.205,
				"close": 55.1975,
				"volume": 110773432,
				"time": 1537761600000,
				"lowest": 54.1575,
				"highest": 55.315,
				"volumePrice": 54.8693,
				"transactionCount": 198159,
				"voluemPrice": 54.8693
			},
			{
				"open": 54.9375,
				"close": 55.5475,
				"volume": 98217516,
				"time": 1537848000000,
				"lowest": 54.925,
				"highest": 55.705,
				"volumePrice": 55.4165,
				"transactionCount": 173707,
				"voluemPrice": 55.4165
			},
			{
				"open": 55.25,
				"close": 55.105,
				"volume": 95938824,
				"time": 1537934400000,
				"lowest": 54.94,
				"highest": 55.9375,
				"volumePrice": 55.4675,
				"transactionCount": 182735,
				"voluemPrice": 55.4675
			},
			{
				"open": 55.955,
				"close": 56.2375,
				"volume": 120724908,
				"time": 1538020800000,
				"lowest": 55.885,
				"highest": 56.61,
				"volumePrice": 56.2867,
				"transactionCount": 224618,
				"voluemPrice": 56.2867
			},
			{
				"open": 56.1975,
				"close": 56.435,
				"volume": 91717456,
				"time": 1538107200000,
				"lowest": 56.005,
				"highest": 56.46,
				"volumePrice": 56.299,
				"transactionCount": 178336,
				"voluemPrice": 56.299
			},
			{
				"open": 56.9875,
				"close": 56.815,
				"volume": 94403208,
				"time": 1538366400000,
				"lowest": 56.5875,
				"highest": 57.355,
				"volumePrice": 56.999,
				"transactionCount": 191834,
				"voluemPrice": 56.999
			},
			{
				"open": 56.8125,
				"close": 57.32,
				"volume": 99152680,
				"time": 1538452800000,
				"lowest": 56.6575,
				"highest": 57.5,
				"volumePrice": 57.2614,
				"transactionCount": 206803,
				"voluemPrice": 57.2614
			},
			{
				"open": 57.5125,
				"close": 58.0175,
				"volume": 114594196,
				"time": 1538539200000,
				"lowest": 57.445,
				"highest": 58.3675,
				"volumePrice": 58.0587,
				"transactionCount": 237796,
				"voluemPrice": 58.0587
			},
			{
				"open": 57.695,
				"close": 56.9975,
				"volume": 128168000,
				"time": 1538625600000,
				"lowest": 56.6825,
				"highest": 58.0875,
				"volumePrice": 57.2097,
				"transactionCount": 284898,
				"voluemPrice": 57.2097
			},
			{
				"open": 56.99,
				"close": 56.0725,
				"volume": 134321852,
				"time": 1538712000000,
				"lowest": 55.145,
				"highest": 57.1025,
				"volumePrice": 56.1078,
				"transactionCount": 316507,
				"voluemPrice": 56.1078
			},
			{
				"open": 55.5525,
				"close": 55.9425,
				"volume": 116615692,
				"time": 1538971200000,
				"lowest": 55.05,
				"highest": 56.2,
				"volumePrice": 55.7109,
				"transactionCount": 260878,
				"voluemPrice": 55.7109
			},
			{
				"open": 55.91,
				"close": 56.7175,
				"volume": 107564116,
				"time": 1539057600000,
				"lowest": 55.5616,
				"highest": 56.8175,
				"volumePrice": 56.4055,
				"transactionCount": 247142,
				"voluemPrice": 56.4055
			},
			{
				"open": 56.365,
				"close": 54.09,
				"volume": 167962216,
				"time": 1539144000000,
				"lowest": 54.0125,
				"highest": 56.5875,
				"volumePrice": 55.2396,
				"transactionCount": 406129,
				"voluemPrice": 55.2396
			},
			{
				"open": 53.63,
				"close": 53.6125,
				"volume": 212497568,
				"time": 1539230400000,
				"lowest": 53.08,
				"highest": 54.875,
				"volumePrice": 53.9455,
				"transactionCount": 545012,
				"voluemPrice": 53.9455
			},
			{
				"open": 55.105,
				"close": 55.5275,
				"volume": 161351404,
				"time": 1539316800000,
				"lowest": 54.21,
				"highest": 55.72,
				"volumePrice": 55.1283,
				"transactionCount": 343455,
				"voluemPrice": 55.1283
			},
			{
				"open": 55.29,
				"close": 54.34,
				"volume": 123164028,
				"time": 1539576000000,
				"lowest": 54.3175,
				"highest": 55.4575,
				"volumePrice": 54.671,
				"transactionCount": 257521,
				"voluemPrice": 54.671
			},
			{
				"open": 54.7325,
				"close": 55.5375,
				"volume": 116735852,
				"time": 1539662400000,
				"lowest": 54.1907,
				"highest": 55.7475,
				"volumePrice": 55.0193,
				"transactionCount": 267901,
				"voluemPrice": 55.0193
			},
			{
				"open": 55.575,
				"close": 55.2975,
				"volume": 91541588,
				"time": 1539748800000,
				"lowest": 54.835,
				"highest": 55.66,
				"volumePrice": 55.2595,
				"transactionCount": 227312,
				"voluemPrice": 55.2595
			},
			{
				"open": 54.465,
				"close": 54.005,
				"volume": 130325260,
				"time": 1539835200000,
				"lowest": 53.25,
				"highest": 54.935,
				"volumePrice": 54.258,
				"transactionCount": 311070,
				"voluemPrice": 54.258
			},
			{
				"open": 54.515,
				"close": 54.8275,
				"volume": 132308304,
				"time": 1539921600000,
				"lowest": 54.3575,
				"highest": 55.315,
				"volumePrice": 54.8459,
				"transactionCount": 290875,
				"voluemPrice": 54.8459
			},
			{
				"open": 54.9475,
				"close": 55.1625,
				"volume": 115084328,
				"time": 1540180800000,
				"lowest": 54.735,
				"highest": 55.84,
				"volumePrice": 55.3197,
				"transactionCount": 263533,
				"voluemPrice": 55.3197
			},
			{
				"open": 53.9575,
				"close": 55.6825,
				"volume": 155071384,
				"time": 1540267200000,
				"lowest": 53.675,
				"highest": 55.8125,
				"volumePrice": 54.7125,
				"transactionCount": 382393,
				"voluemPrice": 54.7125
			},
			{
				"open": 55.65,
				"close": 53.7725,
				"volume": 163700652,
				"time": 1540353600000,
				"lowest": 53.635,
				"highest": 56.0575,
				"volumePrice": 54.8292,
				"transactionCount": 388910,
				"voluemPrice": 54.8292
			},
			{
				"open": 54.4275,
				"close": 54.95,
				"volume": 119423072,
				"time": 1540440000000,
				"lowest": 54.1875,
				"highest": 55.345,
				"volumePrice": 54.8501,
				"transactionCount": 278369,
				"voluemPrice": 54.8501
			},
			{
				"open": 53.975,
				"close": 54.075,
				"volume": 189033500,
				"time": 1540526400000,
				"lowest": 53.1675,
				"highest": 55.0475,
				"volumePrice": 54.0619,
				"transactionCount": 438788,
				"voluemPrice": 54.0619
			},
			{
				"open": 54.7975,
				"close": 53.06,
				"volume": 183742080,
				"time": 1540785600000,
				"lowest": 51.5225,
				"highest": 54.9225,
				"volumePrice": 53.649,
				"transactionCount": 385844,
				"voluemPrice": 53.649
			},
			{
				"open": 52.7875,
				"close": 53.325,
				"volume": 146639960,
				"time": 1540872000000,
				"lowest": 52.3175,
				"highest": 53.795,
				"volumePrice": 53.2018,
				"transactionCount": 351259,
				"voluemPrice": 53.2018
			},
			{
				"open": 54.22,
				"close": 54.715,
				"volume": 153435732,
				"time": 1540958400000,
				"lowest": 54.155,
				"highest": 55.1125,
				"volumePrice": 54.7189,
				"transactionCount": 301126,
				"voluemPrice": 54.7189
			},
			{
				"open": 54.7625,
				"close": 55.555,
				"volume": 233292720,
				"time": 1541044800000,
				"lowest": 54.2025,
				"highest": 55.59,
				"volumePrice": 54.3946,
				"transactionCount": 505923,
				"voluemPrice": 54.3946
			},
			{
				"open": 52.3875,
				"close": 51.87,
				"volume": 365314616,
				"time": 1541131200000,
				"lowest": 51.3575,
				"highest": 53.4125,
				"volumePrice": 52.0234,
				"transactionCount": 756683,
				"voluemPrice": 52.0234
			},
			{
				"open": 51.075,
				"close": 50.3975,
				"volume": 264654676,
				"time": 1541394000000,
				"lowest": 49.5425,
				"highest": 51.0975,
				"volumePrice": 50.2271,
				"transactionCount": 569626,
				"voluemPrice": 50.2271
			},
			{
				"open": 50.48,
				"close": 50.9425,
				"volume": 127531524,
				"time": 1541480400000,
				"lowest": 50.4225,
				"highest": 51.18,
				"volumePrice": 50.8125,
				"transactionCount": 253888,
				"voluemPrice": 50.8125
			},
			{
				"open": 51.4925,
				"close": 52.4875,
				"volume": 133697736,
				"time": 1541566800000,
				"lowest": 51.0325,
				"highest": 52.515,
				"volumePrice": 51.8229,
				"transactionCount": 259449,
				"voluemPrice": 51.8229
			},
			{
				"open": 52.495,
				"close": 52.1225,
				"volume": 101450544,
				"time": 1541653200000,
				"lowest": 51.6875,
				"highest": 52.53,
				"volumePrice": 52.0297,
				"transactionCount": 187585,
				"voluemPrice": 52.0297
			},
			{
				"open": 51.3875,
				"close": 51.1175,
				"volume": 137463000,
				"time": 1541739600000,
				"lowest": 50.5625,
				"highest": 51.5025,
				"volumePrice": 51.027,
				"transactionCount": 237773,
				"voluemPrice": 51.027
			},
			{
				"open": 49.75,
				"close": 48.5425,
				"volume": 204542072,
				"time": 1541998800000,
				"lowest": 48.4475,
				"highest": 49.9625,
				"volumePrice": 48.9112,
				"transactionCount": 419162,
				"voluemPrice": 48.9112
			},
			{
				"open": 47.9075,
				"close": 48.0575,
				"volume": 187531744,
				"time": 1542085200000,
				"lowest": 47.8625,
				"highest": 49.295,
				"volumePrice": 48.4446,
				"transactionCount": 356240,
				"voluemPrice": 48.4446
			},
			{
				"open": 48.475,
				"close": 46.7,
				"volume": 243203828,
				"time": 1542171600000,
				"lowest": 46.4825,
				"highest": 48.62,
				"volumePrice": 47.166,
				"transactionCount": 453437,
				"voluemPrice": 47.166
			},
			{
				"open": 47.0975,
				"close": 47.8525,
				"volume": 185915204,
				"time": 1542258000000,
				"lowest": 46.725,
				"highest": 47.9925,
				"volumePrice": 47.472,
				"transactionCount": 340097,
				"voluemPrice": 47.472
			},
			{
				"open": 47.625,
				"close": 48.3825,
				"volume": 147713012,
				"time": 1542344400000,
				"lowest": 47.365,
				"highest": 48.7424,
				"volumePrice": 48.2946,
				"transactionCount": 269240,
				"voluemPrice": 48.2946
			},
			{
				"open": 47.5,
				"close": 46.465,
				"volume": 167678432,
				"time": 1542603600000,
				"lowest": 46.2475,
				"highest": 47.675,
				"volumePrice": 46.7418,
				"transactionCount": 320746,
				"voluemPrice": 46.7418
			},
			{
				"open": 44.5925,
				"close": 44.245,
				"volume": 271287388,
				"time": 1542690000000,
				"lowest": 43.8775,
				"highest": 45.3675,
				"volumePrice": 44.6213,
				"transactionCount": 534145,
				"voluemPrice": 44.6213
			},
			{
				"open": 44.9325,
				"close": 44.195,
				"volume": 124496840,
				"time": 1542776400000,
				"lowest": 44.1375,
				"highest": 45.0675,
				"volumePrice": 44.489,
				"transactionCount": 232593,
				"voluemPrice": 44.489
			},
			{
				"open": 43.735,
				"close": 43.0725,
				"volume": 94495888,
				"time": 1542949200000,
				"lowest": 43.025,
				"highest": 44.1488,
				"volumePrice": 43.4821,
				"transactionCount": 162142,
				"voluemPrice": 43.4821
			},
			{
				"open": 43.56,
				"close": 43.655,
				"volume": 179994080,
				"time": 1543208400000,
				"lowest": 42.565,
				"highest": 43.7375,
				"volumePrice": 43.2659,
				"transactionCount": 286198,
				"voluemPrice": 43.2659
			},
			{
				"open": 42.8775,
				"close": 43.56,
				"volume": 165549508,
				"time": 1543294800000,
				"lowest": 42.72,
				"highest": 43.6925,
				"volumePrice": 43.3367,
				"transactionCount": 265554,
				"voluemPrice": 43.3367
			},
			{
				"open": 44.1825,
				"close": 45.235,
				"volume": 184250156,
				"time": 1543381200000,
				"lowest": 43.7325,
				"highest": 45.3225,
				"volumePrice": 44.5617,
				"transactionCount": 315252,
				"voluemPrice": 44.5617
			},
			{
				"open": 45.665,
				"close": 44.8875,
				"volume": 167079968,
				"time": 1543467600000,
				"lowest": 44.425,
				"highest": 45.7,
				"volumePrice": 44.8368,
				"transactionCount": 268590,
				"voluemPrice": 44.8368
			},
			{
				"open": 45.0725,
				"close": 44.645,
				"volume": 158094196,
				"time": 1543554000000,
				"lowest": 44.2575,
				"highest": 45.0825,
				"volumePrice": 44.5805,
				"transactionCount": 222912,
				"voluemPrice": 44.5805
			},
			{
				"open": 46.115,
				"close": 46.205,
				"volume": 163192008,
				"time": 1543813200000,
				"lowest": 45.3025,
				"highest": 46.235,
				"volumePrice": 45.7535,
				"transactionCount": 277051,
				"voluemPrice": 45.7535
			},
			{
				"open": 45.2375,
				"close": 44.1725,
				"volume": 165377128,
				"time": 1543899600000,
				"lowest": 44.0675,
				"highest": 45.5975,
				"volumePrice": 44.678,
				"transactionCount": 290086,
				"voluemPrice": 44.678
			},
			{
				"open": 42.94,
				"close": 43.68,
				"volume": 172352840,
				"time": 1544072400000,
				"lowest": 42.605,
				"highest": 43.695,
				"volumePrice": 43.128,
				"transactionCount": 321495,
				"voluemPrice": 43.128
			},
			{
				"open": 43.3725,
				"close": 42.1225,
				"volume": 169126524,
				"time": 1544158800000,
				"lowest": 42.075,
				"highest": 43.6225,
				"volumePrice": 42.5991,
				"transactionCount": 313155,
				"voluemPrice": 42.5991
			},
			{
				"open": 41.25,
				"close": 42.4,
				"volume": 248103976,
				"time": 1544418000000,
				"lowest": 40.8325,
				"highest": 42.5225,
				"volumePrice": 41.7421,
				"transactionCount": 414556,
				"voluemPrice": 41.7421
			},
			{
				"open": 42.915,
				"close": 42.1575,
				"volume": 189126660,
				"time": 1544504400000,
				"lowest": 41.75,
				"highest": 42.9475,
				"volumePrice": 42.2395,
				"transactionCount": 260245,
				"voluemPrice": 42.2395
			},
			{
				"open": 42.6,
				"close": 42.275,
				"volume": 142510636,
				"time": 1544590800000,
				"lowest": 42.255,
				"highest": 42.98,
				"volumePrice": 42.568,
				"transactionCount": 203709,
				"voluemPrice": 42.568
			},
			{
				"open": 42.6225,
				"close": 42.7375,
				"volume": 127591308,
				"time": 1544677200000,
				"lowest": 42.3875,
				"highest": 43.1425,
				"volumePrice": 42.7426,
				"transactionCount": 191691,
				"voluemPrice": 42.7426
			},
			{
				"open": 42.25,
				"close": 41.37,
				"volume": 162677640,
				"time": 1544763600000,
				"lowest": 41.32,
				"highest": 42.27,
				"volumePrice": 41.6636,
				"transactionCount": 275805,
				"voluemPrice": 41.6636
			},
			{
				"open": 41.3625,
				"close": 40.985,
				"volume": 176888088,
				"time": 1545022800000,
				"lowest": 40.6825,
				"highest": 42.0875,
				"volumePrice": 41.3605,
				"transactionCount": 283929,
				"voluemPrice": 41.3605
			},
			{
				"open": 41.345,
				"close": 41.5175,
				"volume": 135366072,
				"time": 1545109200000,
				"lowest": 41.0975,
				"highest": 41.8825,
				"volumePrice": 41.4753,
				"transactionCount": 211722,
				"voluemPrice": 41.4753
			},
			{
				"open": 41.5,
				"close": 40.2225,
				"volume": 196189188,
				"time": 1545195600000,
				"lowest": 39.7725,
				"highest": 41.8625,
				"volumePrice": 40.812,
				"transactionCount": 313384,
				"voluemPrice": 40.812
			},
			{
				"open": 40.1,
				"close": 39.2075,
				"volume": 259091840,
				"time": 1545282000000,
				"lowest": 38.825,
				"highest": 40.5275,
				"volumePrice": 39.6012,
				"transactionCount": 435880,
				"voluemPrice": 39.6012
			},
			{
				"open": 39.215,
				"close": 37.6825,
				"volume": 382977536,
				"time": 1545368400000,
				"lowest": 37.4075,
				"highest": 39.54,
				"volumePrice": 38.2507,
				"transactionCount": 423901,
				"voluemPrice": 38.2507
			},
			{
				"open": 37.0375,
				"close": 36.7075,
				"volume": 148616928,
				"time": 1545627600000,
				"lowest": 36.6475,
				"highest": 37.8875,
				"volumePrice": 37.1895,
				"transactionCount": 249063,
				"voluemPrice": 37.1895
			},
			{
				"open": 37.075,
				"close": 39.2925,
				"volume": 234330176,
				"time": 1545800400000,
				"lowest": 36.68,
				"highest": 39.3075,
				"volumePrice": 37.9164,
				"transactionCount": 355008,
				"voluemPrice": 37.9164
			},
			{
				"open": 38.96,
				"close": 39.0375,
				"volume": 212468260,
				"time": 1545886800000,
				"lowest": 37.5175,
				"highest": 39.1925,
				"volumePrice": 38.3376,
				"transactionCount": 333535,
				"voluemPrice": 38.3376
			},
			{
				"open": 39.375,
				"close": 39.0575,
				"volume": 169165696,
				"time": 1545973200000,
				"lowest": 38.6375,
				"highest": 39.63,
				"volumePrice": 39.0996,
				"transactionCount": 272910,
				"voluemPrice": 39.0996
			},
			{
				"open": 39.6325,
				"close": 39.435,
				"volume": 140013864,
				"time": 1546232400000,
				"lowest": 39.12,
				"highest": 39.84,
				"volumePrice": 39.4213,
				"transactionCount": 205428,
				"voluemPrice": 39.4213
			},
			{
				"open": 38.7225,
				"close": 39.48,
				"volume": 148158948,
				"time": 1546405200000,
				"lowest": 38.5575,
				"highest": 39.7125,
				"volumePrice": 38.8871,
				"transactionCount": 249129,
				"voluemPrice": 38.8871
			},
			{
				"open": 35.995,
				"close": 35.5475,
				"volume": 365248780,
				"time": 1546491600000,
				"lowest": 35.5,
				"highest": 36.43,
				"volumePrice": 35.895,
				"transactionCount": 663513,
				"voluemPrice": 35.895
			},
			{
				"open": 36.1325,
				"close": 37.065,
				"volume": 234284280,
				"time": 1546578000000,
				"lowest": 35.95,
				"highest": 37.1375,
				"volumePrice": 36.7049,
				"transactionCount": 376070,
				"voluemPrice": 36.7049
			},
			{
				"open": 37.175,
				"close": 36.9825,
				"volume": 219111056,
				"time": 1546837200000,
				"lowest": 36.475,
				"highest": 37.2075,
				"volumePrice": 36.8423,
				"transactionCount": 348120,
				"voluemPrice": 36.8423
			},
			{
				"open": 37.39,
				"close": 37.6875,
				"volume": 164101256,
				"time": 1546923600000,
				"lowest": 37.13,
				"highest": 37.955,
				"volumePrice": 37.5382,
				"transactionCount": 272197,
				"voluemPrice": 37.5382
			},
			{
				"open": 37.8225,
				"close": 38.3275,
				"volume": 180396324,
				"time": 1547010000000,
				"lowest": 37.4075,
				"highest": 38.6325,
				"volumePrice": 38.2344,
				"transactionCount": 302931,
				"voluemPrice": 38.2344
			},
			{
				"open": 38.125,
				"close": 38.45,
				"volume": 143122680,
				"time": 1547096400000,
				"lowest": 37.715,
				"highest": 38.4925,
				"volumePrice": 38.1879,
				"transactionCount": 202210,
				"voluemPrice": 38.1879
			},
			{
				"open": 38.22,
				"close": 38.0725,
				"volume": 108082828,
				"time": 1547182800000,
				"lowest": 37.8775,
				"highest": 38.425,
				"volumePrice": 38.0991,
				"transactionCount": 174588,
				"voluemPrice": 38.0991
			},
			{
				"open": 37.7125,
				"close": 37.5,
				"volume": 129756744,
				"time": 1547442000000,
				"lowest": 37.305,
				"highest": 37.8175,
				"volumePrice": 37.5041,
				"transactionCount": 201651,
				"voluemPrice": 37.5041
			},
			{
				"open": 37.5675,
				"close": 38.2675,
				"volume": 114841296,
				"time": 1547528400000,
				"lowest": 37.5125,
				"highest": 38.3475,
				"volumePrice": 38.0894,
				"transactionCount": 178869,
				"voluemPrice": 38.0894
			},
			{
				"open": 38.27,
				"close": 38.735,
				"volume": 122278824,
				"time": 1547614800000,
				"lowest": 38.25,
				"highest": 38.97,
				"volumePrice": 38.7364,
				"transactionCount": 193215,
				"voluemPrice": 38.7364
			},
			{
				"open": 38.55,
				"close": 38.965,
				"volume": 119284640,
				"time": 1547701200000,
				"lowest": 38.315,
				"highest": 39.415,
				"volumePrice": 38.7477,
				"transactionCount": 194831,
				"voluemPrice": 38.7477
			},
			{
				"open": 39.375,
				"close": 39.205,
				"volume": 135004092,
				"time": 1547787600000,
				"lowest": 38.9952,
				"highest": 39.47,
				"volumePrice": 39.2336,
				"transactionCount": 196380,
				"voluemPrice": 39.2336
			},
			{
				"open": 39.1025,
				"close": 38.325,
				"volume": 121575880,
				"time": 1548133200000,
				"lowest": 38.155,
				"highest": 39.1825,
				"volumePrice": 38.5607,
				"transactionCount": 202666,
				"voluemPrice": 38.5607
			},
			{
				"open": 38.5375,
				"close": 38.48,
				"volume": 92522280,
				"time": 1548219600000,
				"lowest": 37.925,
				"highest": 38.785,
				"volumePrice": 38.3582,
				"transactionCount": 154692,
				"voluemPrice": 38.3582
			},
			{
				"open": 38.5275,
				"close": 38.175,
				"volume": 101766196,
				"time": 1548306000000,
				"lowest": 37.935,
				"highest": 38.62,
				"volumePrice": 38.1706,
				"transactionCount": 172118,
				"voluemPrice": 38.1706
			},
			{
				"open": 38.87,
				"close": 39.44,
				"volume": 133635572,
				"time": 1548392400000,
				"lowest": 38.58,
				"highest": 39.5325,
				"volumePrice": 39.2127,
				"transactionCount": 217279,
				"voluemPrice": 39.2127
			},
			{
				"open": 38.9475,
				"close": 39.075,
				"volume": 104768232,
				"time": 1548651600000,
				"lowest": 38.415,
				"highest": 39.0825,
				"volumePrice": 38.8396,
				"transactionCount": 178639,
				"voluemPrice": 38.8396
			},
			{
				"open": 39.0625,
				"close": 38.67,
				"volume": 166348956,
				"time": 1548738000000,
				"lowest": 38.5275,
				"highest": 39.5325,
				"volumePrice": 39.1956,
				"transactionCount": 283849,
				"voluemPrice": 39.1956
			},
			{
				"open": 40.8125,
				"close": 41.3125,
				"volume": 244337120,
				"time": 1548824400000,
				"lowest": 40.0575,
				"highest": 41.5375,
				"volumePrice": 40.8101,
				"transactionCount": 385270,
				"voluemPrice": 40.8101
			},
			{
				"open": 41.5275,
				"close": 41.61,
				"volume": 162958596,
				"time": 1548910800000,
				"lowest": 41.14,
				"highest": 42.25,
				"volumePrice": 41.7712,
				"transactionCount": 239804,
				"voluemPrice": 41.7712
			},
			{
				"open": 41.74,
				"close": 41.63,
				"volume": 130447352,
				"time": 1548997200000,
				"lowest": 41.4825,
				"highest": 42.245,
				"volumePrice": 41.7884,
				"transactionCount": 189769,
				"voluemPrice": 41.7884
			},
			{
				"open": 41.8525,
				"close": 42.8125,
				"volume": 125982328,
				"time": 1549256400000,
				"lowest": 41.82,
				"highest": 42.9138,
				"volumePrice": 42.609,
				"transactionCount": 202049,
				"voluemPrice": 42.609
			},
			{
				"open": 43.215,
				"close": 43.545,
				"volume": 144406512,
				"time": 1549342800000,
				"lowest": 43.0875,
				"highest": 43.77,
				"volumePrice": 43.502,
				"transactionCount": 227187,
				"voluemPrice": 43.502
			},
			{
				"open": 43.6625,
				"close": 43.56,
				"volume": 111958364,
				"time": 1549429200000,
				"lowest": 43.2133,
				"highest": 43.8925,
				"volumePrice": 43.5667,
				"transactionCount": 168576,
				"voluemPrice": 43.5667
			},
			{
				"open": 43.1,
				"close": 42.735,
				"volume": 126966760,
				"time": 1549515600000,
				"lowest": 42.585,
				"highest": 43.485,
				"volumePrice": 42.9095,
				"transactionCount": 211155,
				"voluemPrice": 42.9095
			},
			{
				"open": 42.2475,
				"close": 42.6025,
				"volume": 95279864,
				"time": 1549602000000,
				"lowest": 42.105,
				"highest": 42.665,
				"volumePrice": 42.451,
				"transactionCount": 157877,
				"voluemPrice": 42.451
			},
			{
				"open": 42.7625,
				"close": 42.3575,
				"volume": 83973700,
				"time": 1549861200000,
				"lowest": 42.3125,
				"highest": 42.8025,
				"volumePrice": 42.4874,
				"transactionCount": 144611,
				"voluemPrice": 42.4874
			},
			{
				"open": 42.525,
				"close": 42.7225,
				"volume": 89134092,
				"time": 1549947600000,
				"lowest": 42.425,
				"highest": 42.75,
				"volumePrice": 42.5964,
				"transactionCount": 144386,
				"voluemPrice": 42.5964
			},
			{
				"open": 42.8475,
				"close": 42.545,
				"volume": 89934932,
				"time": 1550034000000,
				"lowest": 42.48,
				"highest": 43.12,
				"volumePrice": 42.7437,
				"transactionCount": 150232,
				"voluemPrice": 42.7437
			},
			{
				"open": 42.4275,
				"close": 42.7,
				"volume": 87342988,
				"time": 1550120400000,
				"lowest": 42.345,
				"highest": 42.8154,
				"volumePrice": 42.6649,
				"transactionCount": 152535,
				"voluemPrice": 42.6649
			},
			{
				"open": 42.8125,
				"close": 42.605,
				"volume": 98507256,
				"time": 1550206800000,
				"lowest": 42.4375,
				"highest": 42.925,
				"volumePrice": 42.6236,
				"transactionCount": 156758,
				"voluemPrice": 42.6236
			},
			{
				"open": 42.4275,
				"close": 42.7325,
				"volume": 75891304,
				"time": 1550552400000,
				"lowest": 42.3725,
				"highest": 42.86,
				"volumePrice": 42.6366,
				"transactionCount": 131206,
				"voluemPrice": 42.6366
			},
			{
				"open": 42.7975,
				"close": 43.0075,
				"volume": 104457448,
				"time": 1550638800000,
				"lowest": 42.7475,
				"highest": 43.33,
				"volumePrice": 43.0665,
				"transactionCount": 162648,
				"voluemPrice": 43.0665
			},
			{
				"open": 42.95,
				"close": 42.765,
				"volume": 68998680,
				"time": 1550725200000,
				"lowest": 42.575,
				"highest": 43.0925,
				"volumePrice": 42.7832,
				"transactionCount": 138105,
				"voluemPrice": 42.7832
			},
			{
				"open": 42.895,
				"close": 43.2425,
				"volume": 75652616,
				"time": 1550811600000,
				"lowest": 42.845,
				"highest": 43.25,
				"volumePrice": 43.091,
				"transactionCount": 124549,
				"voluemPrice": 43.091
			},
			{
				"open": 43.54,
				"close": 43.5575,
				"volume": 87493432,
				"time": 1551070800000,
				"lowest": 43.4875,
				"highest": 43.9675,
				"volumePrice": 43.7151,
				"transactionCount": 158854,
				"voluemPrice": 43.7151
			},
			{
				"open": 43.4275,
				"close": 43.5825,
				"volume": 68280844,
				"time": 1551157200000,
				"lowest": 43.2933,
				"highest": 43.825,
				"volumePrice": 43.5947,
				"transactionCount": 119643,
				"voluemPrice": 43.5947
			},
			{
				"open": 43.3025,
				"close": 43.7175,
				"volume": 111341556,
				"time": 1551243600000,
				"lowest": 43.1825,
				"highest": 43.75,
				"volumePrice": 43.5158,
				"transactionCount": 125558,
				"voluemPrice": 43.5158
			},
			{
				"open": 43.58,
				"close": 43.2875,
				"volume": 112861664,
				"time": 1551330000000,
				"lowest": 43.23,
				"highest": 43.7275,
				"volumePrice": 43.4325,
				"transactionCount": 125783,
				"voluemPrice": 43.4325
			},
			{
				"open": 43.57,
				"close": 43.7425,
				"volume": 103544668,
				"time": 1551416400000,
				"lowest": 43.2225,
				"highest": 43.7875,
				"volumePrice": 43.5693,
				"transactionCount": 147230,
				"voluemPrice": 43.5693
			},
			{
				"open": 43.9225,
				"close": 43.9625,
				"volume": 109744812,
				"time": 1551675600000,
				"lowest": 43.4925,
				"highest": 44.4375,
				"volumePrice": 43.9892,
				"transactionCount": 189997,
				"voluemPrice": 43.9892
			},
			{
				"open": 43.985,
				"close": 43.8825,
				"volume": 78949676,
				"time": 1551762000000,
				"lowest": 43.635,
				"highest": 44,
				"volumePrice": 43.8465,
				"transactionCount": 127231,
				"voluemPrice": 43.8465
			},
			{
				"open": 43.6675,
				"close": 43.63,
				"volume": 83241536,
				"time": 1551848400000,
				"lowest": 43.485,
				"highest": 43.8725,
				"volumePrice": 43.6861,
				"transactionCount": 137662,
				"voluemPrice": 43.6861
			},
			{
				"open": 43.4675,
				"close": 43.125,
				"volume": 99185496,
				"time": 1551934800000,
				"lowest": 43.005,
				"highest": 43.61,
				"volumePrice": 43.2639,
				"transactionCount": 180597,
				"voluemPrice": 43.2639
			},
			{
				"open": 42.58,
				"close": 43.2275,
				"volume": 95997432,
				"time": 1552021200000,
				"lowest": 42.375,
				"highest": 43.2675,
				"volumePrice": 42.9599,
				"transactionCount": 167422,
				"voluemPrice": 42.9599
			},
			{
				"open": 43.8725,
				"close": 44.725,
				"volume": 128044136,
				"time": 1552276800000,
				"lowest": 43.8375,
				"highest": 44.78,
				"volumePrice": 44.545,
				"transactionCount": 211343,
				"voluemPrice": 44.545
			},
			{
				"open": 45,
				"close": 45.2275,
				"volume": 129870336,
				"time": 1552363200000,
				"lowest": 44.8425,
				"highest": 45.6675,
				"volumePrice": 45.2804,
				"transactionCount": 215962,
				"voluemPrice": 45.2804
			},
			{
				"open": 45.5625,
				"close": 45.4275,
				"volume": 124130096,
				"time": 1552449600000,
				"lowest": 45.23,
				"highest": 45.825,
				"volumePrice": 45.4885,
				"transactionCount": 188079,
				"voluemPrice": 45.4885
			},
			{
				"open": 45.975,
				"close": 45.9325,
				"volume": 94318032,
				"time": 1552536000000,
				"lowest": 45.64,
				"highest": 46.025,
				"volumePrice": 45.8882,
				"transactionCount": 155808,
				"voluemPrice": 45.8882
			},
			{
				"open": 46.2125,
				"close": 46.53,
				"volume": 156171648,
				"time": 1552622400000,
				"lowest": 45.935,
				"highest": 46.8325,
				"volumePrice": 46.4607,
				"transactionCount": 202477,
				"voluemPrice": 46.4607
			},
			{
				"open": 46.45,
				"close": 47.005,
				"volume": 104879328,
				"time": 1552881600000,
				"lowest": 46.4475,
				"highest": 47.0975,
				"volumePrice": 46.9156,
				"transactionCount": 163740,
				"voluemPrice": 46.9156
			},
			{
				"open": 47.0875,
				"close": 46.6325,
				"volume": 126585476,
				"time": 1552968000000,
				"lowest": 46.48,
				"highest": 47.2475,
				"volumePrice": 46.8458,
				"transactionCount": 197350,
				"voluemPrice": 46.8458
			},
			{
				"open": 46.5575,
				"close": 47.04,
				"volume": 124140924,
				"time": 1553054400000,
				"lowest": 46.1825,
				"highest": 47.3725,
				"volumePrice": 46.8448,
				"transactionCount": 193683,
				"voluemPrice": 46.8448
			},
			{
				"open": 47.505,
				"close": 48.7725,
				"volume": 204136948,
				"time": 1553140800000,
				"lowest": 47.4525,
				"highest": 49.0825,
				"volumePrice": 48.5783,
				"transactionCount": 309643,
				"voluemPrice": 48.5783
			},
			{
				"open": 48.835,
				"close": 47.7625,
				"volume": 169630664,
				"time": 1553227200000,
				"lowest": 47.695,
				"highest": 49.4225,
				"volumePrice": 48.4674,
				"transactionCount": 298951,
				"voluemPrice": 48.4674
			},
			{
				"open": 47.8775,
				"close": 47.185,
				"volume": 175381172,
				"time": 1553486400000,
				"lowest": 46.65,
				"highest": 47.995,
				"volumePrice": 47.22,
				"transactionCount": 308859,
				"voluemPrice": 47.22
			},
			{
				"open": 47.916,
				"close": 46.6975,
				"volume": 199202152,
				"time": 1553572800000,
				"lowest": 46.145,
				"highest": 48.22,
				"volumePrice": 47.347,
				"transactionCount": 344114,
				"voluemPrice": 47.347
			},
			{
				"open": 47.1875,
				"close": 47.1175,
				"volume": 119393708,
				"time": 1553659200000,
				"lowest": 46.6375,
				"highest": 47.44,
				"volumePrice": 47.1266,
				"transactionCount": 196103,
				"voluemPrice": 47.1266
			},
			{
				"open": 47.2375,
				"close": 47.18,
				"volume": 83121452,
				"time": 1553745600000,
				"lowest": 46.8825,
				"highest": 47.3898,
				"volumePrice": 47.104,
				"transactionCount": 152290,
				"voluemPrice": 47.104
			},
			{
				"open": 47.4575,
				"close": 47.4875,
				"volume": 94255844,
				"time": 1553832000000,
				"lowest": 47.135,
				"highest": 47.52,
				"volumePrice": 47.3815,
				"transactionCount": 149796,
				"voluemPrice": 47.3815
			},
			{
				"open": 47.91,
				"close": 47.81,
				"volume": 111447856,
				"time": 1554091200000,
				"lowest": 47.095,
				"highest": 47.92,
				"volumePrice": 47.5903,
				"transactionCount": 180998,
				"voluemPrice": 47.5903
			},
			{
				"open": 47.7725,
				"close": 48.505,
				"volume": 91062900,
				"time": 1554177600000,
				"lowest": 47.7625,
				"highest": 48.615,
				"volumePrice": 48.2881,
				"transactionCount": 169575,
				"voluemPrice": 48.2881
			},
			{
				"open": 48.3125,
				"close": 48.8375,
				"volume": 93087320,
				"time": 1554264000000,
				"lowest": 48.2875,
				"highest": 49.125,
				"volumePrice": 48.8239,
				"transactionCount": 164493,
				"voluemPrice": 48.8239
			},
			{
				"open": 48.6975,
				"close": 48.9225,
				"volume": 76431900,
				"time": 1554350400000,
				"lowest": 48.285,
				"highest": 49.0925,
				"volumePrice": 48.9187,
				"transactionCount": 134609,
				"voluemPrice": 48.9187
			},
			{
				"open": 49.1125,
				"close": 49.25,
				"volume": 74106576,
				"time": 1554436800000,
				"lowest": 48.9825,
				"highest": 49.275,
				"volumePrice": 49.1644,
				"transactionCount": 124211,
				"voluemPrice": 49.1644
			},
			{
				"open": 49.105,
				"close": 50.025,
				"volume": 103374712,
				"time": 1554696000000,
				"lowest": 49.085,
				"highest": 50.0575,
				"volumePrice": 49.7576,
				"transactionCount": 180911,
				"voluemPrice": 49.7576
			},
			{
				"open": 50.08,
				"close": 49.875,
				"volume": 143072948,
				"time": 1554782400000,
				"lowest": 49.8075,
				"highest": 50.7125,
				"volumePrice": 50.2263,
				"transactionCount": 232190,
				"voluemPrice": 50.2263
			},
			{
				"open": 49.67,
				"close": 50.155,
				"volume": 86781152,
				"time": 1554868800000,
				"lowest": 49.545,
				"highest": 50.185,
				"volumePrice": 49.8803,
				"transactionCount": 160818,
				"voluemPrice": 49.8803
			},
			{
				"open": 50.2125,
				"close": 49.7375,
				"volume": 83582112,
				"time": 1554955200000,
				"lowest": 49.6108,
				"highest": 50.25,
				"volumePrice": 49.928,
				"transactionCount": 142489,
				"voluemPrice": 49.928
			},
			{
				"open": 49.8,
				"close": 49.7175,
				"volume": 110658672,
				"time": 1555041600000,
				"lowest": 49.0525,
				"highest": 50.035,
				"volumePrice": 49.5873,
				"transactionCount": 195426,
				"voluemPrice": 49.5873
			},
			{
				"open": 49.645,
				"close": 49.8075,
				"volume": 70136984,
				"time": 1555300800000,
				"lowest": 49.5025,
				"highest": 49.9625,
				"volumePrice": 49.75,
				"transactionCount": 140393,
				"voluemPrice": 49.75
			},
			{
				"open": 49.865,
				"close": 49.8125,
				"volume": 102785540,
				"time": 1555387200000,
				"lowest": 49.64,
				"highest": 50.3425,
				"volumePrice": 49.978,
				"transactionCount": 201696,
				"voluemPrice": 49.978
			},
			{
				"open": 49.885,
				"close": 50.7825,
				"volume": 113627120,
				"time": 1555473600000,
				"lowest": 49.6525,
				"highest": 50.845,
				"volumePrice": 50.4613,
				"transactionCount": 207247,
				"voluemPrice": 50.4613
			},
			{
				"open": 50.78,
				"close": 50.965,
				"volume": 96783064,
				"time": 1555560000000,
				"lowest": 50.63,
				"highest": 51.0375,
				"volumePrice": 50.8719,
				"transactionCount": 146302,
				"voluemPrice": 50.8719
			},
			{
				"open": 50.7075,
				"close": 51.1325,
				"volume": 77758180,
				"time": 1555905600000,
				"lowest": 50.585,
				"highest": 51.235,
				"volumePrice": 51.0634,
				"transactionCount": 116209,
				"voluemPrice": 51.0634
			},
			{
				"open": 51.1075,
				"close": 51.87,
				"volume": 93291964,
				"time": 1555992000000,
				"lowest": 50.975,
				"highest": 51.9375,
				"volumePrice": 51.6252,
				"transactionCount": 166047,
				"voluemPrice": 51.6252
			},
			{
				"open": 51.84,
				"close": 51.79,
				"volume": 70162436,
				"time": 1556078400000,
				"lowest": 51.7625,
				"highest": 52.12,
				"volumePrice": 51.9161,
				"transactionCount": 124532,
				"voluemPrice": 51.9161
			},
			{
				"open": 51.7075,
				"close": 51.32,
				"volume": 74172824,
				"time": 1556164800000,
				"lowest": 51.28,
				"highest": 51.94,
				"volumePrice": 51.5108,
				"transactionCount": 146857,
				"voluemPrice": 51.5108
			},
			{
				"open": 51.225,
				"close": 51.075,
				"volume": 74596408,
				"time": 1556251200000,
				"lowest": 50.53,
				"highest": 51.25,
				"volumePrice": 51.0253,
				"transactionCount": 141086,
				"voluemPrice": 51.0253
			},
			{
				"open": 51.1,
				"close": 51.1525,
				"volume": 88818864,
				"time": 1556510400000,
				"lowest": 50.965,
				"highest": 51.4925,
				"volumePrice": 51.2354,
				"transactionCount": 138969,
				"voluemPrice": 51.2354
			},
			{
				"open": 50.765,
				"close": 50.1675,
				"volume": 186027504,
				"time": 1556596800000,
				"lowest": 49.7775,
				"highest": 50.85,
				"volumePrice": 50.5785,
				"transactionCount": 332062,
				"voluemPrice": 50.5785
			},
			{
				"open": 52.47,
				"close": 52.63,
				"volume": 259309312,
				"time": 1556683200000,
				"lowest": 52.3075,
				"highest": 53.8275,
				"volumePrice": 53.1677,
				"transactionCount": 441442,
				"voluemPrice": 53.1677
			},
			{
				"open": 52.46,
				"close": 52.2875,
				"volume": 127985296,
				"time": 1556769600000,
				"lowest": 52.0325,
				"highest": 53.1625,
				"volumePrice": 52.5388,
				"transactionCount": 233406,
				"voluemPrice": 52.5388
			},
			{
				"open": 52.7225,
				"close": 52.9375,
				"volume": 83569512,
				"time": 1556856000000,
				"lowest": 52.5575,
				"highest": 52.96,
				"volumePrice": 52.7894,
				"transactionCount": 148448,
				"voluemPrice": 52.7894
			},
			{
				"open": 51.0725,
				"close": 52.12,
				"volume": 129772452,
				"time": 1557115200000,
				"lowest": 50.875,
				"highest": 52.21,
				"volumePrice": 51.8274,
				"transactionCount": 236168,
				"voluemPrice": 51.8274
			},
			{
				"open": 51.47,
				"close": 50.715,
				"volume": 155054792,
				"time": 1557201600000,
				"lowest": 50.2063,
				"highest": 51.8544,
				"volumePrice": 50.8923,
				"transactionCount": 294532,
				"voluemPrice": 50.8923
			},
			{
				"open": 50.475,
				"close": 50.725,
				"volume": 105358016,
				"time": 1557288000000,
				"lowest": 50.4375,
				"highest": 51.335,
				"volumePrice": 50.9267,
				"transactionCount": 202764,
				"voluemPrice": 50.9267
			},
			{
				"open": 50.1,
				"close": 50.18,
				"volume": 139634428,
				"time": 1557374400000,
				"lowest": 49.165,
				"highest": 50.42,
				"volumePrice": 49.8333,
				"transactionCount": 290609,
				"voluemPrice": 49.8333
			},
			{
				"open": 49.3548,
				"close": 49.295,
				"volume": 164834848,
				"time": 1557460800000,
				"lowest": 48.1925,
				"highest": 49.7125,
				"volumePrice": 49.0126,
				"transactionCount": 324318,
				"voluemPrice": 49.0126
			},
			{
				"open": 46.9275,
				"close": 46.43,
				"volume": 229722492,
				"time": 1557720000000,
				"lowest": 45.7125,
				"highest": 47.37,
				"volumePrice": 46.6878,
				"transactionCount": 458554,
				"voluemPrice": 46.6878
			},
			{
				"open": 46.6025,
				"close": 47.165,
				"volume": 146118708,
				"time": 1557806400000,
				"lowest": 46.3525,
				"highest": 47.425,
				"volumePrice": 46.9941,
				"transactionCount": 273844,
				"voluemPrice": 46.9941
			},
			{
				"open": 46.5675,
				"close": 47.73,
				"volume": 106178872,
				"time": 1557892800000,
				"lowest": 46.505,
				"highest": 47.9375,
				"volumePrice": 47.4348,
				"transactionCount": 214960,
				"voluemPrice": 47.4348
			},
			{
				"open": 47.4775,
				"close": 47.52,
				"volume": 131765456,
				"time": 1557979200000,
				"lowest": 47.21,
				"highest": 48.1172,
				"volumePrice": 47.6079,
				"transactionCount": 227813,
				"voluemPrice": 47.6079
			},
			{
				"open": 46.7325,
				"close": 47.25,
				"volume": 131472860,
				"time": 1558065600000,
				"lowest": 46.69,
				"highest": 47.725,
				"volumePrice": 47.2264,
				"transactionCount": 231407,
				"voluemPrice": 47.2264
			},
			{
				"open": 45.88,
				"close": 45.7725,
				"volume": 154333760,
				"time": 1558324800000,
				"lowest": 45.071,
				"highest": 46.0873,
				"volumePrice": 45.6834,
				"transactionCount": 290339,
				"voluemPrice": 45.6834
			},
			{
				"open": 46.305,
				"close": 46.65,
				"volume": 113459392,
				"time": 1558411200000,
				"lowest": 46.175,
				"highest": 47,
				"volumePrice": 46.685,
				"transactionCount": 191742,
				"voluemPrice": 46.685
			},
			{
				"open": 46.165,
				"close": 45.695,
				"volume": 118955852,
				"time": 1558497600000,
				"lowest": 45.6375,
				"highest": 46.4275,
				"volumePrice": 45.925,
				"transactionCount": 213836,
				"voluemPrice": 45.925
			},
			{
				"open": 44.95,
				"close": 44.915,
				"volume": 145718944,
				"time": 1558584000000,
				"lowest": 44.4525,
				"highest": 45.135,
				"volumePrice": 44.8336,
				"transactionCount": 266841,
				"voluemPrice": 44.8336
			},
			{
				"open": 45.05,
				"close": 44.7425,
				"volume": 94858744,
				"time": 1558670400000,
				"lowest": 44.655,
				"highest": 45.535,
				"volumePrice": 45.0317,
				"transactionCount": 182468,
				"voluemPrice": 45.0317
			},
			{
				"open": 44.73,
				"close": 44.5575,
				"volume": 111792640,
				"time": 1559016000000,
				"lowest": 44.4775,
				"highest": 45.1475,
				"volumePrice": 44.7178,
				"transactionCount": 190007,
				"voluemPrice": 44.7178
			},
			{
				"open": 44.105,
				"close": 44.345,
				"volume": 113924660,
				"time": 1559102400000,
				"lowest": 44,
				"highest": 44.8375,
				"volumePrice": 44.3896,
				"transactionCount": 212723,
				"voluemPrice": 44.3896
			},
			{
				"open": 44.4875,
				"close": 44.575,
				"volume": 83953648,
				"time": 1559188800000,
				"lowest": 44.1675,
				"highest": 44.8075,
				"volumePrice": 44.4919,
				"transactionCount": 165752,
				"voluemPrice": 44.4919
			},
			{
				"open": 44.0575,
				"close": 43.7675,
				"volume": 108174336,
				"time": 1559275200000,
				"lowest": 43.7475,
				"highest": 44.4975,
				"volumePrice": 44.0876,
				"transactionCount": 204344,
				"voluemPrice": 44.0876
			},
			{
				"open": 43.9,
				"close": 43.325,
				"volume": 161584276,
				"time": 1559534400000,
				"lowest": 42.5675,
				"highest": 44.48,
				"volumePrice": 43.5999,
				"transactionCount": 306917,
				"voluemPrice": 43.5999
			},
			{
				"open": 43.86,
				"close": 44.91,
				"volume": 123871844,
				"time": 1559620800000,
				"lowest": 43.63,
				"highest": 44.9575,
				"volumePrice": 44.5316,
				"transactionCount": 239718,
				"voluemPrice": 44.5316
			},
			{
				"open": 46.07,
				"close": 45.635,
				"volume": 119093708,
				"time": 1559707200000,
				"lowest": 45.285,
				"highest": 46.2475,
				"volumePrice": 45.7111,
				"transactionCount": 239331,
				"voluemPrice": 45.7111
			},
			{
				"open": 45.77,
				"close": 46.305,
				"volume": 90105244,
				"time": 1559793600000,
				"lowest": 45.5372,
				"highest": 46.3675,
				"volumePrice": 45.9951,
				"transactionCount": 160315,
				"voluemPrice": 45.9951
			},
			{
				"open": 46.6275,
				"close": 47.5375,
				"volume": 122737572,
				"time": 1559880000000,
				"lowest": 46.4425,
				"highest": 47.98,
				"volumePrice": 47.4457,
				"transactionCount": 236608,
				"voluemPrice": 47.4457
			},
			{
				"open": 47.9525,
				"close": 48.145,
				"volume": 104883404,
				"time": 1560139200000,
				"lowest": 47.905,
				"highest": 48.8425,
				"volumePrice": 48.3259,
				"transactionCount": 198722,
				"voluemPrice": 48.3259
			},
			{
				"open": 48.715,
				"close": 48.7025,
				"volume": 107731528,
				"time": 1560225600000,
				"lowest": 48.4,
				"highest": 49,
				"volumePrice": 48.6678,
				"transactionCount": 180619,
				"voluemPrice": 48.6678
			},
			{
				"open": 48.4875,
				"close": 48.5475,
				"volume": 73012756,
				"time": 1560312000000,
				"lowest": 48.3463,
				"highest": 48.9925,
				"volumePrice": 48.6468,
				"transactionCount": 129977,
				"voluemPrice": 48.6468
			},
			{
				"open": 48.675,
				"close": 48.5375,
				"volume": 86598496,
				"time": 1560398400000,
				"lowest": 48.4,
				"highest": 49.1975,
				"volumePrice": 48.7626,
				"transactionCount": 143183,
				"voluemPrice": 48.7626
			},
			{
				"open": 47.8862,
				"close": 48.185,
				"volume": 75045896,
				"time": 1560484800000,
				"lowest": 47.575,
				"highest": 48.3966,
				"volumePrice": 48.0462,
				"transactionCount": 144375,
				"voluemPrice": 48.0462
			},
			{
				"open": 48.225,
				"close": 48.4725,
				"volume": 58676576,
				"time": 1560744000000,
				"lowest": 48.0425,
				"highest": 48.74,
				"volumePrice": 48.5258,
				"transactionCount": 114807,
				"voluemPrice": 48.5258
			},
			{
				"open": 49.0125,
				"close": 49.6125,
				"volume": 106204016,
				"time": 1560830400000,
				"lowest": 48.8025,
				"highest": 50.0725,
				"volumePrice": 49.6892,
				"transactionCount": 213982,
				"voluemPrice": 49.6892
			},
			{
				"open": 49.92,
				"close": 49.4675,
				"volume": 84496940,
				"time": 1560916800000,
				"lowest": 49.3275,
				"highest": 49.97,
				"volumePrice": 49.5686,
				"transactionCount": 155701,
				"voluemPrice": 49.5686
			},
			{
				"open": 50.0925,
				"close": 49.865,
				"volume": 85875952,
				"time": 1561003200000,
				"lowest": 49.5075,
				"highest": 50.1525,
				"volumePrice": 49.8312,
				"transactionCount": 168119,
				"voluemPrice": 49.8312
			},
			{
				"open": 49.7,
				"close": 49.695,
				"volume": 191202356,
				"time": 1561089600000,
				"lowest": 49.5375,
				"highest": 50.2125,
				"volumePrice": 49.8109,
				"transactionCount": 182762,
				"voluemPrice": 49.8109
			},
			{
				"open": 49.635,
				"close": 49.645,
				"volume": 72879684,
				"time": 1561348800000,
				"lowest": 49.5425,
				"highest": 50.04,
				"volumePrice": 49.7904,
				"transactionCount": 132991,
				"voluemPrice": 49.7904
			},
			{
				"open": 49.6075,
				"close": 48.8925,
				"volume": 80241336,
				"time": 1561435200000,
				"lowest": 48.8225,
				"highest": 49.815,
				"volumePrice": 49.2731,
				"transactionCount": 176385,
				"voluemPrice": 49.2731
			},
			{
				"open": 49.4425,
				"close": 49.95,
				"volume": 104270048,
				"time": 1561521600000,
				"lowest": 49.3375,
				"highest": 50.2475,
				"volumePrice": 49.9958,
				"transactionCount": 203309,
				"voluemPrice": 49.9958
			},
			{
				"open": 50.0725,
				"close": 49.935,
				"volume": 83598868,
				"time": 1561608000000,
				"lowest": 49.8925,
				"highest": 50.3925,
				"volumePrice": 50.0388,
				"transactionCount": 148527,
				"voluemPrice": 50.0388
			},
			{
				"open": 49.67,
				"close": 49.48,
				"volume": 124442568,
				"time": 1561694400000,
				"lowest": 49.2625,
				"highest": 49.8738,
				"volumePrice": 49.4973,
				"transactionCount": 182093,
				"voluemPrice": 49.4973
			},
			{
				"open": 50.7925,
				"close": 50.3875,
				"volume": 109266956,
				"time": 1561953600000,
				"lowest": 50.1625,
				"highest": 51.1225,
				"volumePrice": 50.5824,
				"transactionCount": 236349,
				"voluemPrice": 50.5824
			},
			{
				"open": 50.3525,
				"close": 50.6825,
				"volume": 67740868,
				"time": 1562040000000,
				"lowest": 50.34,
				"highest": 50.7831,
				"volumePrice": 50.5816,
				"transactionCount": 155946,
				"voluemPrice": 50.5816
			},
			{
				"open": 50.82,
				"close": 51.1025,
				"volume": 45448180,
				"time": 1562126400000,
				"lowest": 50.6725,
				"highest": 51.11,
				"volumePrice": 50.9358,
				"transactionCount": 93206,
				"voluemPrice": 50.9358
			},
			{
				"open": 50.8375,
				"close": 51.0575,
				"volume": 69062072,
				"time": 1562299200000,
				"lowest": 50.725,
				"highest": 51.27,
				"volumePrice": 51.0592,
				"transactionCount": 161208,
				"voluemPrice": 51.0592
			},
			{
				"open": 50.2025,
				"close": 50.005,
				"volume": 101354512,
				"time": 1562558400000,
				"lowest": 49.6025,
				"highest": 50.35,
				"volumePrice": 49.9456,
				"transactionCount": 217561,
				"voluemPrice": 49.9456
			},
			{
				"open": 49.8,
				"close": 50.31,
				"volume": 82312060,
				"time": 1562644800000,
				"lowest": 49.7025,
				"highest": 50.3775,
				"volumePrice": 50.1446,
				"transactionCount": 170459,
				"voluemPrice": 50.1446
			},
			{
				"open": 50.4625,
				"close": 50.8075,
				"volume": 71588552,
				"time": 1562731200000,
				"lowest": 50.39,
				"highest": 50.9325,
				"volumePrice": 50.7679,
				"transactionCount": 148879,
				"voluemPrice": 50.7679
			},
			{
				"open": 50.8275,
				"close": 50.4375,
				"volume": 80766768,
				"time": 1562817600000,
				"lowest": 50.4275,
				"highest": 51.0975,
				"volumePrice": 50.7619,
				"transactionCount": 155454,
				"voluemPrice": 50.7619
			},
			{
				"open": 50.6125,
				"close": 50.825,
				"volume": 70380848,
				"time": 1562904000000,
				"lowest": 50.55,
				"highest": 51,
				"volumePrice": 50.8533,
				"transactionCount": 145618,
				"voluemPrice": 50.8533
			},
			{
				"open": 51.0225,
				"close": 51.3025,
				"volume": 67789680,
				"time": 1563163200000,
				"lowest": 51,
				"highest": 51.4675,
				"volumePrice": 51.2542,
				"transactionCount": 138130,
				"voluemPrice": 51.2542
			},
			{
				"open": 51.1475,
				"close": 51.125,
				"volume": 67467264,
				"time": 1563249600000,
				"lowest": 50.875,
				"highest": 51.5275,
				"volumePrice": 51.1838,
				"transactionCount": 137720,
				"voluemPrice": 51.1838
			},
			{
				"open": 51.0125,
				"close": 50.8375,
				"volume": 56429800,
				"time": 1563336000000,
				"lowest": 50.8175,
				"highest": 51.2729,
				"volumePrice": 51.0522,
				"transactionCount": 109617,
				"voluemPrice": 51.0522
			},
			{
				"open": 51,
				"close": 51.415,
				"volume": 74328644,
				"time": 1563422400000,
				"lowest": 50.925,
				"highest": 51.47,
				"volumePrice": 51.2703,
				"transactionCount": 143289,
				"voluemPrice": 51.2703
			},
			{
				"open": 51.4475,
				"close": 50.6475,
				"volume": 83717228,
				"time": 1563508800000,
				"lowest": 50.59,
				"highest": 51.625,
				"volumePrice": 51.0647,
				"transactionCount": 156565,
				"voluemPrice": 51.0647
			},
			{
				"open": 50.9125,
				"close": 51.805,
				"volume": 89111460,
				"time": 1563768000000,
				"lowest": 50.9025,
				"highest": 51.8075,
				"volumePrice": 51.5456,
				"transactionCount": 166038,
				"voluemPrice": 51.5456
			},
			{
				"open": 52.115,
				"close": 52.21,
				"volume": 73420840,
				"time": 1563854400000,
				"lowest": 51.8225,
				"highest": 52.2275,
				"volumePrice": 52.0334,
				"transactionCount": 138003,
				"voluemPrice": 52.0334
			},
			{
				"open": 51.9175,
				"close": 52.1675,
				"volume": 59966268,
				"time": 1563940800000,
				"lowest": 51.7925,
				"highest": 52.2875,
				"volumePrice": 52.0614,
				"transactionCount": 114744,
				"voluemPrice": 52.0614
			},
			{
				"open": 52.2225,
				"close": 51.755,
				"volume": 55638248,
				"time": 1564027200000,
				"lowest": 51.6825,
				"highest": 52.31,
				"volumePrice": 51.9397,
				"transactionCount": 123729,
				"voluemPrice": 51.9397
			},
			{
				"open": 51.87,
				"close": 51.935,
				"volume": 69815496,
				"time": 1564113600000,
				"lowest": 51.785,
				"highest": 52.4325,
				"volumePrice": 52.0493,
				"transactionCount": 136946,
				"voluemPrice": 52.0493
			},
			{
				"open": 52.115,
				"close": 52.42,
				"volume": 86693556,
				"time": 1564372800000,
				"lowest": 52.11,
				"highest": 52.66,
				"volumePrice": 52.462,
				"transactionCount": 176159,
				"voluemPrice": 52.462
			},
			{
				"open": 52.19,
				"close": 52.195,
				"volume": 135742872,
				"time": 1564459200000,
				"lowest": 51.8275,
				"highest": 52.54,
				"volumePrice": 52.5065,
				"transactionCount": 267607,
				"voluemPrice": 52.5065
			},
			{
				"open": 54.105,
				"close": 53.26,
				"volume": 277125444,
				"time": 1564545600000,
				"lowest": 52.825,
				"highest": 55.3425,
				"volumePrice": 54.2863,
				"transactionCount": 528873,
				"voluemPrice": 54.2863
			},
			{
				"open": 53.475,
				"close": 52.1075,
				"volume": 216071648,
				"time": 1564632000000,
				"lowest": 51.6859,
				"highest": 54.5075,
				"volumePrice": 53.0459,
				"transactionCount": 452268,
				"voluemPrice": 53.0459
			},
			{
				"open": 51.3825,
				"close": 51.005,
				"volume": 163448488,
				"time": 1564718400000,
				"lowest": 50.4075,
				"highest": 51.6075,
				"volumePrice": 50.9767,
				"transactionCount": 344860,
				"voluemPrice": 50.9767
			},
			{
				"open": 49.4975,
				"close": 48.335,
				"volume": 209571876,
				"time": 1564977600000,
				"lowest": 48.145,
				"highest": 49.6623,
				"volumePrice": 48.6578,
				"transactionCount": 471602,
				"voluemPrice": 48.6578
			},
			{
				"open": 49.0775,
				"close": 49.25,
				"volume": 143299148,
				"time": 1565064000000,
				"lowest": 48.51,
				"highest": 49.5168,
				"volumePrice": 49.0696,
				"transactionCount": 282299,
				"voluemPrice": 49.0696
			},
			{
				"open": 48.8525,
				"close": 49.76,
				"volume": 133457600,
				"time": 1565150400000,
				"lowest": 48.455,
				"highest": 49.89,
				"volumePrice": 49.2889,
				"transactionCount": 277444,
				"voluemPrice": 49.2889
			},
			{
				"open": 50.05,
				"close": 50.8575,
				"volume": 108038092,
				"time": 1565236800000,
				"lowest": 49.8475,
				"highest": 50.8825,
				"volumePrice": 50.4796,
				"transactionCount": 245165,
				"voluemPrice": 50.4796
			},
			{
				"open": 50.325,
				"close": 50.2475,
				"volume": 98478984,
				"time": 1565323200000,
				"lowest": 49.8225,
				"highest": 50.69,
				"volumePrice": 50.3039,
				"transactionCount": 193223,
				"voluemPrice": 50.3039
			},
			{
				"open": 49.905,
				"close": 50.12,
				"volume": 89927556,
				"time": 1565582400000,
				"lowest": 49.7875,
				"highest": 50.5129,
				"volumePrice": 50.2032,
				"transactionCount": 181412,
				"voluemPrice": 50.2032
			},
			{
				"open": 50.255,
				"close": 52.2425,
				"volume": 190159144,
				"time": 1565668800000,
				"lowest": 50.2075,
				"highest": 53.035,
				"volumePrice": 52.1578,
				"transactionCount": 377969,
				"voluemPrice": 52.1578
			},
			{
				"open": 50.79,
				"close": 50.6875,
				"volume": 146189772,
				"time": 1565755200000,
				"lowest": 50.6467,
				"highest": 51.61,
				"volumePrice": 50.9787,
				"transactionCount": 292722,
				"voluemPrice": 50.9787
			},
			{
				"open": 50.865,
				"close": 50.435,
				"volume": 111533452,
				"time": 1565841600000,
				"lowest": 49.9175,
				"highest": 51.285,
				"volumePrice": 50.5156,
				"transactionCount": 246488,
				"voluemPrice": 50.5156
			},
			{
				"open": 51.07,
				"close": 51.625,
				"volume": 115254496,
				"time": 1565928000000,
				"lowest": 50.96,
				"highest": 51.79,
				"volumePrice": 51.4764,
				"transactionCount": 221213,
				"voluemPrice": 51.4764
			},
			{
				"open": 52.655,
				"close": 52.5875,
				"volume": 97727660,
				"time": 1566187200000,
				"lowest": 52.5063,
				"highest": 53.1827,
				"volumePrice": 52.8601,
				"transactionCount": 183023,
				"voluemPrice": 52.8601
			},
			{
				"open": 52.72,
				"close": 52.59,
				"volume": 107678116,
				"time": 1566273600000,
				"lowest": 52.58,
				"highest": 53.3375,
				"volumePrice": 52.8739,
				"transactionCount": 175160,
				"voluemPrice": 52.8739
			},
			{
				"open": 53.2475,
				"close": 53.16,
				"volume": 86258988,
				"time": 1566360000000,
				"lowest": 52.9008,
				"highest": 53.4125,
				"volumePrice": 53.2162,
				"transactionCount": 168033,
				"voluemPrice": 53.2162
			},
			{
				"open": 53.2975,
				"close": 53.115,
				"volume": 89071276,
				"time": 1566446400000,
				"lowest": 52.6875,
				"highest": 53.6088,
				"volumePrice": 53.1643,
				"transactionCount": 171708,
				"voluemPrice": 53.1643
			},
			{
				"open": 52.3575,
				"close": 50.66,
				"volume": 187479372,
				"time": 1566532800000,
				"lowest": 50.25,
				"highest": 53.0128,
				"volumePrice": 51.2925,
				"transactionCount": 388234,
				"voluemPrice": 51.2925
			},
			{
				"open": 51.465,
				"close": 51.6225,
				"volume": 104264520,
				"time": 1566792000000,
				"lowest": 51.2643,
				"highest": 51.7975,
				"volumePrice": 51.5434,
				"transactionCount": 201498,
				"voluemPrice": 51.5434
			},
			{
				"open": 51.965,
				"close": 51.04,
				"volume": 103589376,
				"time": 1566878400000,
				"lowest": 50.8825,
				"highest": 52.1375,
				"volumePrice": 51.2932,
				"transactionCount": 183064,
				"voluemPrice": 51.2932
			},
			{
				"open": 51.025,
				"close": 51.3825,
				"volume": 63566528,
				"time": 1566964800000,
				"lowest": 50.83,
				"highest": 51.43,
				"volumePrice": 51.2324,
				"transactionCount": 139861,
				"voluemPrice": 51.2324
			},
			{
				"open": 52.125,
				"close": 52.2525,
				"volume": 84030608,
				"time": 1567051200000,
				"lowest": 51.6638,
				"highest": 52.33,
				"volumePrice": 52.1302,
				"transactionCount": 168014,
				"voluemPrice": 52.1302
			},
			{
				"open": 52.54,
				"close": 52.185,
				"volume": 84434244,
				"time": 1567137600000,
				"lowest": 51.8,
				"highest": 52.6125,
				"volumePrice": 52.1128,
				"transactionCount": 159896,
				"voluemPrice": 52.1128
			},
			{
				"open": 51.6075,
				"close": 51.425,
				"volume": 80238296,
				"time": 1567483200000,
				"lowest": 51.055,
				"highest": 51.745,
				"volumePrice": 51.3551,
				"transactionCount": 169562,
				"voluemPrice": 51.3551
			},
			{
				"open": 52.0975,
				"close": 52.2975,
				"volume": 76867280,
				"time": 1567569600000,
				"lowest": 51.83,
				"highest": 52.37,
				"volumePrice": 52.1505,
				"transactionCount": 167047,
				"voluemPrice": 52.1505
			},
			{
				"open": 53,
				"close": 53.32,
				"volume": 95787936,
				"time": 1567656000000,
				"lowest": 52.8775,
				"highest": 53.4925,
				"volumePrice": 53.2207,
				"transactionCount": 202018,
				"voluemPrice": 53.2207
			},
			{
				"open": 53.5125,
				"close": 53.315,
				"volume": 77449176,
				"time": 1567742400000,
				"lowest": 53.1275,
				"highest": 53.605,
				"volumePrice": 53.3356,
				"transactionCount": 202795,
				"voluemPrice": 53.3356
			},
			{
				"open": 53.71,
				"close": 53.5425,
				"volume": 109237604,
				"time": 1568001600000,
				"lowest": 52.7675,
				"highest": 54.11,
				"volumePrice": 53.7299,
				"transactionCount": 298879,
				"voluemPrice": 53.7299
			},
			{
				"open": 53.465,
				"close": 54.175,
				"volume": 127111724,
				"time": 1568088000000,
				"lowest": 52.9275,
				"highest": 54.195,
				"volumePrice": 53.6098,
				"transactionCount": 349287,
				"voluemPrice": 53.6098
			},
			{
				"open": 54.5175,
				"close": 55.8975,
				"volume": 177158584,
				"time": 1568174400000,
				"lowest": 54.4325,
				"highest": 55.9275,
				"volumePrice": 55.3662,
				"transactionCount": 413158,
				"voluemPrice": 55.3662
			},
			{
				"open": 56.2,
				"close": 55.7713,
				"volume": 128906676,
				"time": 1568260800000,
				"lowest": 55.715,
				"highest": 56.605,
				"volumePrice": 56.1534,
				"transactionCount": 295048,
				"voluemPrice": 56.1534
			},
			{
				"open": 55,
				"close": 54.6875,
				"volume": 159053184,
				"time": 1568347200000,
				"lowest": 54.255,
				"highest": 55.1975,
				"volumePrice": 54.7267,
				"transactionCount": 481842,
				"voluemPrice": 54.7267
			},
			{
				"open": 54.4325,
				"close": 54.975,
				"volume": 84632564,
				"time": 1568606400000,
				"lowest": 54.39,
				"highest": 55.0325,
				"volumePrice": 54.8359,
				"transactionCount": 241282,
				"voluemPrice": 54.8359
			},
			{
				"open": 54.99,
				"close": 55.175,
				"volume": 73545872,
				"time": 1568692800000,
				"lowest": 54.78,
				"highest": 55.205,
				"volumePrice": 55.0205,
				"transactionCount": 154463,
				"voluemPrice": 55.0205
			},
			{
				"open": 55.265,
				"close": 55.6925,
				"volume": 102572372,
				"time": 1568779200000,
				"lowest": 54.86,
				"highest": 55.7125,
				"volumePrice": 55.4085,
				"transactionCount": 214153,
				"voluemPrice": 55.4085
			},
			{
				"open": 55.5025,
				"close": 55.24,
				"volume": 88751504,
				"time": 1568865600000,
				"lowest": 55.0925,
				"highest": 55.94,
				"volumePrice": 55.4778,
				"transactionCount": 197540,
				"voluemPrice": 55.4778
			},
			{
				"open": 55.345,
				"close": 54.4325,
				"volume": 231510776,
				"time": 1568952000000,
				"lowest": 54.3683,
				"highest": 55.64,
				"volumePrice": 54.7879,
				"transactionCount": 310364,
				"voluemPrice": 54.7879
			},
			{
				"open": 54.7375,
				"close": 54.68,
				"volume": 77678592,
				"time": 1569211200000,
				"lowest": 54.4125,
				"highest": 54.96,
				"volumePrice": 54.7102,
				"transactionCount": 211005,
				"voluemPrice": 54.7102
			},
			{
				"open": 55.2575,
				"close": 54.42,
				"volume": 125737468,
				"time": 1569297600000,
				"lowest": 54.2975,
				"highest": 55.6225,
				"volumePrice": 54.8171,
				"transactionCount": 357153,
				"voluemPrice": 54.8171
			},
			{
				"open": 54.6375,
				"close": 55.2575,
				"volume": 89924024,
				"time": 1569384000000,
				"lowest": 54.2851,
				"highest": 55.375,
				"volumePrice": 54.9921,
				"transactionCount": 270826,
				"voluemPrice": 54.9921
			},
			{
				"open": 55,
				"close": 54.9725,
				"volume": 76353248,
				"time": 1569470400000,
				"lowest": 54.7075,
				"highest": 55.235,
				"volumePrice": 54.97,
				"transactionCount": 236629,
				"voluemPrice": 54.97
			},
			{
				"open": 55.135,
				"close": 54.705,
				"volume": 101445140,
				"time": 1569556800000,
				"lowest": 54.3204,
				"highest": 55.24,
				"volumePrice": 54.7613,
				"transactionCount": 312399,
				"voluemPrice": 54.7613
			},
			{
				"open": 55.225,
				"close": 55.9925,
				"volume": 105274332,
				"time": 1569816000000,
				"lowest": 55.1975,
				"highest": 56.145,
				"volumePrice": 55.7919,
				"transactionCount": 281988,
				"voluemPrice": 55.7919
			},
			{
				"open": 56.2675,
				"close": 56.1475,
				"volume": 144748652,
				"time": 1569902400000,
				"lowest": 56.05,
				"highest": 57.055,
				"volumePrice": 56.4821,
				"transactionCount": 416942,
				"voluemPrice": 56.4821
			},
			{
				"open": 55.765,
				"close": 54.74,
				"volume": 143069028,
				"time": 1569988800000,
				"lowest": 54.4825,
				"highest": 55.895,
				"volumePrice": 54.9467,
				"transactionCount": 450383,
				"voluemPrice": 54.9467
			},
			{
				"open": 54.6075,
				"close": 55.205,
				"volume": 121410744,
				"time": 1570075200000,
				"lowest": 53.783,
				"highest": 55.24,
				"volumePrice": 54.7258,
				"transactionCount": 363653,
				"voluemPrice": 54.7258
			},
			{
				"open": 56.41,
				"close": 56.7525,
				"volume": 138562212,
				"time": 1570161600000,
				"lowest": 55.9725,
				"highest": 56.8725,
				"volumePrice": 56.4728,
				"transactionCount": 312653,
				"voluemPrice": 56.4728
			},
			{
				"open": 56.5675,
				"close": 56.765,
				"volume": 123557076,
				"time": 1570420800000,
				"lowest": 56.46,
				"highest": 57.4825,
				"volumePrice": 57.0237,
				"transactionCount": 322409,
				"voluemPrice": 57.0237
			},
			{
				"open": 56.455,
				"close": 56.1,
				"volume": 117130800,
				"time": 1570507200000,
				"lowest": 56.0825,
				"highest": 57.015,
				"volumePrice": 56.475,
				"transactionCount": 351136,
				"voluemPrice": 56.475
			},
			{
				"open": 56.7575,
				"close": 56.7575,
				"volume": 76117696,
				"time": 1570593600000,
				"lowest": 56.41,
				"highest": 56.9475,
				"volumePrice": 56.6916,
				"transactionCount": 206600,
				"voluemPrice": 56.6916
			},
			{
				"open": 56.9825,
				"close": 57.5225,
				"volume": 115851936,
				"time": 1570680000000,
				"lowest": 56.825,
				"highest": 57.61,
				"volumePrice": 57.405,
				"transactionCount": 308879,
				"voluemPrice": 57.405
			},
			{
				"open": 58.2375,
				"close": 59.0525,
				"volume": 167957480,
				"time": 1570766400000,
				"lowest": 58.0769,
				"highest": 59.41,
				"volumePrice": 58.7868,
				"transactionCount": 400878,
				"voluemPrice": 58.7868
			},
			{
				"open": 58.725,
				"close": 58.9675,
				"volume": 97653936,
				"time": 1571025600000,
				"lowest": 58.6675,
				"highest": 59.5336,
				"volumePrice": 59.169,
				"transactionCount": 218853,
				"voluemPrice": 59.169
			},
			{
				"open": 59.0975,
				"close": 58.83,
				"volume": 92161932,
				"time": 1571112000000,
				"lowest": 58.72,
				"highest": 59.4125,
				"volumePrice": 59.0056,
				"transactionCount": 205010,
				"voluemPrice": 59.0056
			},
			{
				"open": 58.3425,
				"close": 58.5925,
				"volume": 77146776,
				"time": 1571198400000,
				"lowest": 58.3,
				"highest": 58.81,
				"volumePrice": 58.545,
				"transactionCount": 196480,
				"voluemPrice": 58.545
			},
			{
				"open": 58.7725,
				"close": 58.82,
				"volume": 69091588,
				"time": 1571284800000,
				"lowest": 58.38,
				"highest": 59.0375,
				"volumePrice": 58.7266,
				"transactionCount": 179986,
				"voluemPrice": 58.7266
			},
			{
				"open": 58.6475,
				"close": 59.1025,
				"volume": 97508664,
				"time": 1571371200000,
				"lowest": 58.5725,
				"highest": 59.395,
				"volumePrice": 58.961,
				"transactionCount": 205169,
				"voluemPrice": 58.961
			},
			{
				"open": 59.38,
				"close": 60.1275,
				"volume": 89469932,
				"time": 1571630400000,
				"lowest": 59.33,
				"highest": 60.2475,
				"volumePrice": 60.0149,
				"transactionCount": 195954,
				"voluemPrice": 60.0149
			},
			{
				"open": 60.29,
				"close": 59.99,
				"volume": 90736004,
				"time": 1571716800000,
				"lowest": 59.9055,
				"highest": 60.55,
				"volumePrice": 60.2519,
				"transactionCount": 180732,
				"voluemPrice": 60.2519
			},
			{
				"open": 60.525,
				"close": 60.795,
				"volume": 79730180,
				"time": 1571803200000,
				"lowest": 60.305,
				"highest": 60.81,
				"volumePrice": 60.6161,
				"transactionCount": 156381,
				"voluemPrice": 60.6161
			},
			{
				"open": 61.1275,
				"close": 60.895,
				"volume": 71665020,
				"time": 1571889600000,
				"lowest": 60.4513,
				"highest": 61.2,
				"volumePrice": 60.7645,
				"transactionCount": 144675,
				"voluemPrice": 60.7645
			},
			{
				"open": 60.79,
				"close": 61.645,
				"volume": 73477184,
				"time": 1571976000000,
				"lowest": 60.72,
				"highest": 61.6825,
				"volumePrice": 61.3068,
				"transactionCount": 142574,
				"voluemPrice": 61.3068
			},
			{
				"open": 61.855,
				"close": 62.2625,
				"volume": 96572964,
				"time": 1572235200000,
				"lowest": 61.68,
				"highest": 62.3125,
				"volumePrice": 62.0886,
				"transactionCount": 190480,
				"voluemPrice": 62.0886
			},
			{
				"open": 62.2425,
				"close": 60.8225,
				"volume": 142806828,
				"time": 1572321600000,
				"lowest": 60.6425,
				"highest": 62.4375,
				"volumePrice": 61.201,
				"transactionCount": 280978,
				"voluemPrice": 61.201
			},
			{
				"open": 61.19,
				"close": 60.815,
				"volume": 124339688,
				"time": 1572408000000,
				"lowest": 60.3025,
				"highest": 61.325,
				"volumePrice": 60.892,
				"transactionCount": 267208,
				"voluemPrice": 60.892
			},
			{
				"open": 61.81,
				"close": 62.19,
				"volume": 139162080,
				"time": 1572494400000,
				"lowest": 59.315,
				"highest": 62.2925,
				"volumePrice": 61.962,
				"transactionCount": 272736,
				"voluemPrice": 61.962
			},
			{
				"open": 62.385,
				"close": 63.955,
				"volume": 151125336,
				"time": 1572580800000,
				"lowest": 62.29,
				"highest": 63.9825,
				"volumePrice": 63.3147,
				"transactionCount": 279590,
				"voluemPrice": 63.3147
			},
			{
				"open": 64.3325,
				"close": 64.375,
				"volume": 103271808,
				"time": 1572843600000,
				"lowest": 63.845,
				"highest": 64.4613,
				"volumePrice": 64.2299,
				"transactionCount": 204389,
				"voluemPrice": 64.2299
			},
			{
				"open": 64.2625,
				"close": 64.2825,
				"volume": 79897708,
				"time": 1572930000000,
				"lowest": 64.08,
				"highest": 64.5475,
				"volumePrice": 64.3589,
				"transactionCount": 162280,
				"voluemPrice": 64.3589
			},
			{
				"open": 64.1925,
				"close": 64.31,
				"volume": 75864496,
				"time": 1573016400000,
				"lowest": 63.8413,
				"highest": 64.3725,
				"volumePrice": 64.1628,
				"transactionCount": 154820,
				"voluemPrice": 64.1628
			},
			{
				"open": 64.685,
				"close": 64.8575,
				"volume": 94940332,
				"time": 1573102800000,
				"lowest": 64.5275,
				"highest": 65.0875,
				"volumePrice": 64.8234,
				"transactionCount": 187661,
				"voluemPrice": 64.8234
			},
			{
				"open": 64.6725,
				"close": 65.035,
				"volume": 70066380,
				"time": 1573189200000,
				"lowest": 64.2125,
				"highest": 65.11,
				"volumePrice": 64.7375,
				"transactionCount": 139066,
				"voluemPrice": 64.7375
			},
			{
				"open": 64.575,
				"close": 65.55,
				"volume": 82029836,
				"time": 1573448400000,
				"lowest": 64.57,
				"highest": 65.6175,
				"volumePrice": 65.2057,
				"transactionCount": 154646,
				"voluemPrice": 65.2057
			},
			{
				"open": 65.3875,
				"close": 65.49,
				"volume": 87388904,
				"time": 1573534800000,
				"lowest": 65.23,
				"highest": 65.6975,
				"volumePrice": 65.4792,
				"transactionCount": 170371,
				"voluemPrice": 65.4792
			},
			{
				"open": 65.2825,
				"close": 66.1175,
				"volume": 103259372,
				"time": 1573621200000,
				"lowest": 65.2675,
				"highest": 66.195,
				"volumePrice": 65.8956,
				"transactionCount": 203747,
				"voluemPrice": 65.8956
			},
			{
				"open": 65.9375,
				"close": 65.66,
				"volume": 89582224,
				"time": 1573707600000,
				"lowest": 65.525,
				"highest": 66.22,
				"volumePrice": 65.8242,
				"transactionCount": 200430,
				"voluemPrice": 65.8242
			},
			{
				"open": 65.92,
				"close": 66.44,
				"volume": 100374664,
				"time": 1573794000000,
				"lowest": 65.7525,
				"highest": 66.445,
				"volumePrice": 66.1462,
				"transactionCount": 178689,
				"voluemPrice": 66.1462
			},
			{
				"open": 66.45,
				"close": 66.775,
				"volume": 86803588,
				"time": 1574053200000,
				"lowest": 66.0575,
				"highest": 66.8575,
				"volumePrice": 66.5452,
				"transactionCount": 172419,
				"voluemPrice": 66.5452
			},
			{
				"open": 66.975,
				"close": 66.5725,
				"volume": 76278388,
				"time": 1574139600000,
				"lowest": 66.3482,
				"highest": 67,
				"volumePrice": 66.6321,
				"transactionCount": 136075,
				"voluemPrice": 66.6321
			},
			{
				"open": 66.385,
				"close": 65.7975,
				"volume": 106373676,
				"time": 1574226000000,
				"lowest": 65.1,
				"highest": 66.5208,
				"volumePrice": 65.8052,
				"transactionCount": 206431,
				"voluemPrice": 65.8052
			},
			{
				"open": 65.9225,
				"close": 65.5025,
				"volume": 119995112,
				"time": 1574312400000,
				"lowest": 65.295,
				"highest": 66.0013,
				"volumePrice": 65.5687,
				"transactionCount": 162665,
				"voluemPrice": 65.5687
			},
			{
				"open": 65.6475,
				"close": 65.445,
				"volume": 65325052,
				"time": 1574398800000,
				"lowest": 65.21,
				"highest": 65.795,
				"volumePrice": 65.4791,
				"transactionCount": 133445,
				"voluemPrice": 65.4791
			},
			{
				"open": 65.6775,
				"close": 66.5925,
				"volume": 84118068,
				"time": 1574658000000,
				"lowest": 65.63,
				"highest": 66.61,
				"volumePrice": 66.3526,
				"transactionCount": 129647,
				"voluemPrice": 66.3526
			},
			{
				"open": 66.735,
				"close": 66.0725,
				"volume": 105339528,
				"time": 1574744400000,
				"lowest": 65.625,
				"highest": 66.79,
				"volumePrice": 66.3289,
				"transactionCount": 135753,
				"voluemPrice": 66.3289
			},
			{
				"open": 66.395,
				"close": 66.96,
				"volume": 65544488,
				"time": 1574830800000,
				"lowest": 66.3275,
				"highest": 66.995,
				"volumePrice": 66.6647,
				"transactionCount": 120479,
				"voluemPrice": 66.6647
			},
			{
				"open": 66.65,
				"close": 66.8125,
				"volume": 46617452,
				"time": 1575003600000,
				"lowest": 66.475,
				"highest": 67,
				"volumePrice": 66.8303,
				"transactionCount": 75130,
				"voluemPrice": 66.8303
			},
			{
				"open": 66.8175,
				"close": 66.04,
				"volume": 94549800,
				"time": 1575262800000,
				"lowest": 65.8625,
				"highest": 67.0625,
				"volumePrice": 66.2698,
				"transactionCount": 173135,
				"voluemPrice": 66.2698
			},
			{
				"open": 64.5775,
				"close": 64.8625,
				"volume": 117509072,
				"time": 1575349200000,
				"lowest": 64.0725,
				"highest": 64.8825,
				"volumePrice": 64.5239,
				"transactionCount": 245821,
				"voluemPrice": 64.5239
			},
			{
				"open": 65.2675,
				"close": 65.435,
				"volume": 67241552,
				"time": 1575435600000,
				"lowest": 65.17,
				"highest": 65.8275,
				"volumePrice": 65.5129,
				"transactionCount": 141699,
				"voluemPrice": 65.5129
			},
			{
				"open": 65.9475,
				"close": 66.395,
				"volume": 74645372,
				"time": 1575522000000,
				"lowest": 65.6825,
				"highest": 66.4725,
				"volumePrice": 66.154,
				"transactionCount": 142919,
				"voluemPrice": 66.154
			},
			{
				"open": 66.87,
				"close": 67.6775,
				"volume": 106189972,
				"time": 1575608400000,
				"lowest": 66.825,
				"highest": 67.75,
				"volumePrice": 67.4681,
				"transactionCount": 192704,
				"voluemPrice": 67.4681
			},
			{
				"open": 67.5,
				"close": 66.73,
				"volume": 128730580,
				"time": 1575867600000,
				"lowest": 66.2275,
				"highest": 67.7,
				"volumePrice": 66.8076,
				"transactionCount": 220537,
				"voluemPrice": 66.8076
			},
			{
				"open": 67.15,
				"close": 67.12,
				"volume": 90529532,
				"time": 1575954000000,
				"lowest": 66.465,
				"highest": 67.5175,
				"volumePrice": 67.1673,
				"transactionCount": 180826,
				"voluemPrice": 67.1673
			},
			{
				"open": 67.2025,
				"close": 67.6925,
				"volume": 78893564,
				"time": 1576040400000,
				"lowest": 67.125,
				"highest": 67.775,
				"volumePrice": 67.5627,
				"transactionCount": 139866,
				"voluemPrice": 67.5627
			},
			{
				"open": 66.945,
				"close": 67.865,
				"volume": 137748168,
				"time": 1576126800000,
				"lowest": 66.8303,
				"highest": 68.14,
				"volumePrice": 67.5474,
				"transactionCount": 261547,
				"voluemPrice": 67.5474
			},
			{
				"open": 67.865,
				"close": 68.7875,
				"volume": 133731224,
				"time": 1576213200000,
				"lowest": 67.7325,
				"highest": 68.825,
				"volumePrice": 68.4777,
				"transactionCount": 253684,
				"voluemPrice": 68.4777
			},
			{
				"open": 69.25,
				"close": 69.965,
				"volume": 128318920,
				"time": 1576472400000,
				"lowest": 69.245,
				"highest": 70.1975,
				"volumePrice": 69.92,
				"transactionCount": 203140,
				"voluemPrice": 69.92
			},
			{
				"open": 69.8925,
				"close": 70.1025,
				"volume": 113645192,
				"time": 1576558800000,
				"lowest": 69.7,
				"highest": 70.4425,
				"volumePrice": 70.1524,
				"transactionCount": 195565,
				"voluemPrice": 70.1524
			},
			{
				"open": 69.95,
				"close": 69.935,
				"volume": 116093948,
				"time": 1576645200000,
				"lowest": 69.78,
				"highest": 70.475,
				"volumePrice": 70.0699,
				"transactionCount": 181285,
				"voluemPrice": 70.0699
			},
			{
				"open": 69.875,
				"close": 70.005,
				"volume": 98507788,
				"time": 1576731600000,
				"lowest": 69.7375,
				"highest": 70.295,
				"volumePrice": 69.9697,
				"transactionCount": 161576,
				"voluemPrice": 69.9697
			},
			{
				"open": 70.5575,
				"close": 69.86,
				"volume": 276130972,
				"time": 1576818000000,
				"lowest": 69.64,
				"highest": 70.6625,
				"volumePrice": 70.0428,
				"transactionCount": 214243,
				"voluemPrice": 70.0428
			},
			{
				"open": 70.1325,
				"close": 71,
				"volume": 98265932,
				"time": 1577077200000,
				"lowest": 70.0934,
				"highest": 71.0625,
				"volumePrice": 70.7994,
				"transactionCount": 169949,
				"voluemPrice": 70.7994
			},
			{
				"open": 71.1725,
				"close": 71.0675,
				"volume": 48478856,
				"time": 1577163600000,
				"lowest": 70.7299,
				"highest": 71.2225,
				"volumePrice": 71.0203,
				"transactionCount": 82669,
				"voluemPrice": 71.0203
			},
			{
				"open": 71.205,
				"close": 72.4775,
				"volume": 93336016,
				"time": 1577336400000,
				"lowest": 71.175,
				"highest": 72.495,
				"volumePrice": 72.036,
				"transactionCount": 170217,
				"voluemPrice": 72.036
			},
			{
				"open": 72.78,
				"close": 72.45,
				"volume": 146371744,
				"time": 1577422800000,
				"lowest": 72.03,
				"highest": 73.4925,
				"volumePrice": 72.6935,
				"transactionCount": 263594,
				"voluemPrice": 72.6935
			},
			{
				"open": 72.365,
				"close": 72.88,
				"volume": 144238456,
				"time": 1577682000000,
				"lowest": 71.305,
				"highest": 73.1725,
				"volumePrice": 72.4813,
				"transactionCount": 264186,
				"voluemPrice": 72.4813
			},
			{
				"open": 72.4825,
				"close": 73.4125,
				"volume": 100990500,
				"time": 1577768400000,
				"lowest": 72.38,
				"highest": 73.42,
				"volumePrice": 73.0982,
				"transactionCount": 185419,
				"voluemPrice": 73.0982
			},
			{
				"open": 74.06,
				"close": 75.0875,
				"volume": 135647456,
				"time": 1577941200000,
				"lowest": 73.7975,
				"highest": 75.15,
				"volumePrice": 74.6099,
				"transactionCount": 283480,
				"voluemPrice": 74.6099
			},
			{
				"open": 74.2875,
				"close": 74.3575,
				"volume": 146535512,
				"time": 1578027600000,
				"lowest": 74.125,
				"highest": 75.145,
				"volumePrice": 74.7026,
				"transactionCount": 280940,
				"voluemPrice": 74.7026
			},
			{
				"open": 73.4475,
				"close": 74.95,
				"volume": 118518576,
				"time": 1578286800000,
				"lowest": 73.1875,
				"highest": 74.99,
				"volumePrice": 74.4499,
				"transactionCount": 242860,
				"voluemPrice": 74.4499
			},
			{
				"open": 74.96,
				"close": 74.5975,
				"volume": 111510620,
				"time": 1578373200000,
				"lowest": 74.37,
				"highest": 75.225,
				"volumePrice": 74.7311,
				"transactionCount": 227868,
				"voluemPrice": 74.7311
			},
			{
				"open": 74.29,
				"close": 75.7975,
				"volume": 132363784,
				"time": 1578459600000,
				"lowest": 74.289,
				"highest": 76.11,
				"volumePrice": 75.2914,
				"transactionCount": 261770,
				"voluemPrice": 75.2914
			},
			{
				"open": 76.8088,
				"close": 77.4075,
				"volume": 170486168,
				"time": 1578546000000,
				"lowest": 76.55,
				"highest": 77.6075,
				"volumePrice": 77.161,
				"transactionCount": 328749,
				"voluemPrice": 77.161
			},
			{
				"open": 77.65,
				"close": 77.5825,
				"volume": 140869088,
				"time": 1578632400000,
				"lowest": 77.0625,
				"highest": 78.1675,
				"volumePrice": 77.6729,
				"transactionCount": 294841,
				"voluemPrice": 77.6729
			},
			{
				"open": 77.91,
				"close": 79.24,
				"volume": 122086888,
				"time": 1578891600000,
				"lowest": 77.7875,
				"highest": 79.2675,
				"volumePrice": 78.4206,
				"transactionCount": 254950,
				"voluemPrice": 78.4206
			},
			{
				"open": 79.175,
				"close": 78.17,
				"volume": 162613828,
				"time": 1578978000000,
				"lowest": 78.0425,
				"highest": 79.3925,
				"volumePrice": 78.6921,
				"transactionCount": 342959,
				"voluemPrice": 78.6921
			},
			{
				"open": 77.9625,
				"close": 77.835,
				"volume": 121923528,
				"time": 1579064400000,
				"lowest": 77.3875,
				"highest": 78.875,
				"volumePrice": 78.2459,
				"transactionCount": 264903,
				"voluemPrice": 78.2459
			},
			{
				"open": 78.3975,
				"close": 78.81,
				"volume": 107069016,
				"time": 1579150800000,
				"lowest": 78.0225,
				"highest": 78.925,
				"volumePrice": 78.5424,
				"transactionCount": 207421,
				"voluemPrice": 78.5424
			},
			{
				"open": 79.0675,
				"close": 79.6825,
				"volume": 137816468,
				"time": 1579237200000,
				"lowest": 78.75,
				"highest": 79.685,
				"volumePrice": 79.2386,
				"transactionCount": 238615,
				"voluemPrice": 79.2386
			},
			{
				"open": 79.2975,
				"close": 79.1425,
				"volume": 110643256,
				"time": 1579582800000,
				"lowest": 79,
				"highest": 79.755,
				"volumePrice": 79.3365,
				"transactionCount": 208931,
				"voluemPrice": 79.3365
			},
			{
				"open": 79.645,
				"close": 79.425,
				"volume": 101832460,
				"time": 1579669200000,
				"lowest": 79.3275,
				"highest": 79.9975,
				"volumePrice": 79.6217,
				"transactionCount": 208306,
				"voluemPrice": 79.6217
			},
			{
				"open": 79.48,
				"close": 79.8075,
				"volume": 104471972,
				"time": 1579755600000,
				"lowest": 78.9125,
				"highest": 79.89,
				"volumePrice": 79.5524,
				"transactionCount": 197084,
				"voluemPrice": 79.5524
			},
			{
				"open": 80.0625,
				"close": 79.5775,
				"volume": 146537520,
				"time": 1579842000000,
				"lowest": 79.3797,
				"highest": 80.8325,
				"volumePrice": 80.1019,
				"transactionCount": 296490,
				"voluemPrice": 80.1019
			},
			{
				"open": 77.515,
				"close": 77.2375,
				"volume": 161908020,
				"time": 1580101200000,
				"lowest": 76.22,
				"highest": 77.9425,
				"volumePrice": 77.4235,
				"transactionCount": 380394,
				"voluemPrice": 77.4235
			},
			{
				"open": 78.15,
				"close": 79.4225,
				"volume": 162233944,
				"time": 1580187600000,
				"lowest": 78.0475,
				"highest": 79.6,
				"volumePrice": 79.2654,
				"transactionCount": 353618,
				"voluemPrice": 79.2654
			},
			{
				"open": 81.1125,
				"close": 81.085,
				"volume": 216599620,
				"time": 1580274000000,
				"lowest": 80.345,
				"highest": 81.9625,
				"volumePrice": 81.365,
				"transactionCount": 464161,
				"voluemPrice": 81.365
			},
			{
				"open": 80.1359,
				"close": 80.9675,
				"volume": 126551232,
				"time": 1580360400000,
				"lowest": 79.6875,
				"highest": 81.0225,
				"volumePrice": 80.4192,
				"transactionCount": 280545,
				"voluemPrice": 80.4192
			},
			{
				"open": 80.2325,
				"close": 77.3775,
				"volume": 199588384,
				"time": 1580446800000,
				"lowest": 77.0725,
				"highest": 80.67,
				"volumePrice": 78.5039,
				"transactionCount": 484153,
				"voluemPrice": 78.5039
			},
			{
				"open": 76.075,
				"close": 77.165,
				"volume": 173985604,
				"time": 1580706000000,
				"lowest": 75.555,
				"highest": 78.3725,
				"volumePrice": 77.2738,
				"transactionCount": 405189,
				"voluemPrice": 77.2738
			},
			{
				"open": 78.8275,
				"close": 79.7125,
				"volume": 136616536,
				"time": 1580792400000,
				"lowest": 78.4086,
				"highest": 79.91,
				"volumePrice": 79.3666,
				"transactionCount": 288064,
				"voluemPrice": 79.3666
			},
			{
				"open": 80.88,
				"close": 80.3625,
				"volume": 118746872,
				"time": 1580878800000,
				"lowest": 79.7375,
				"highest": 81.19,
				"volumePrice": 80.3725,
				"transactionCount": 269725,
				"voluemPrice": 80.3725
			},
			{
				"open": 80.6425,
				"close": 81.3025,
				"volume": 105392140,
				"time": 1580965200000,
				"lowest": 80.0662,
				"highest": 81.305,
				"volumePrice": 80.8276,
				"transactionCount": 215446,
				"voluemPrice": 80.8276
			},
			{
				"open": 80.5925,
				"close": 80.0075,
				"volume": 117684048,
				"time": 1581051600000,
				"lowest": 79.5,
				"highest": 80.85,
				"volumePrice": 80.2554,
				"transactionCount": 261918,
				"voluemPrice": 80.2554
			},
			{
				"open": 78.545,
				"close": 80.3875,
				"volume": 109346860,
				"time": 1581310800000,
				"lowest": 78.4625,
				"highest": 80.3875,
				"volumePrice": 79.7929,
				"transactionCount": 234127,
				"voluemPrice": 79.7929
			},
			{
				"open": 80.9,
				"close": 79.9025,
				"volume": 94323120,
				"time": 1581397200000,
				"lowest": 79.6775,
				"highest": 80.975,
				"volumePrice": 80.3471,
				"transactionCount": 230343,
				"voluemPrice": 80.3471
			},
			{
				"open": 80.3675,
				"close": 81.8,
				"volume": 113730292,
				"time": 1581483600000,
				"lowest": 80.3675,
				"highest": 81.805,
				"volumePrice": 81.1997,
				"transactionCount": 244871,
				"voluemPrice": 81.1997
			},
			{
				"open": 81.0475,
				"close": 81.2175,
				"volume": 94451512,
				"time": 1581570000000,
				"lowest": 80.8375,
				"highest": 81.555,
				"volumePrice": 81.2497,
				"transactionCount": 216964,
				"voluemPrice": 81.2497
			},
			{
				"open": 81.185,
				"close": 81.2375,
				"volume": 80113788,
				"time": 1581656400000,
				"lowest": 80.7125,
				"highest": 81.495,
				"volumePrice": 81.1569,
				"transactionCount": 176169,
				"voluemPrice": 81.1569
			},
			{
				"open": 78.84,
				"close": 79.75,
				"volume": 152762180,
				"time": 1582002000000,
				"lowest": 78.6525,
				"highest": 79.9375,
				"volumePrice": 79.3387,
				"transactionCount": 365776,
				"voluemPrice": 79.3387
			},
			{
				"open": 80,
				"close": 80.905,
				"volume": 93983964,
				"time": 1582088400000,
				"lowest": 80,
				"highest": 81.1425,
				"volumePrice": 80.7971,
				"transactionCount": 205432,
				"voluemPrice": 80.7971
			},
			{
				"open": 80.6575,
				"close": 80.075,
				"volume": 100565956,
				"time": 1582174800000,
				"lowest": 79.5525,
				"highest": 81.1625,
				"volumePrice": 80.2818,
				"transactionCount": 243574,
				"voluemPrice": 80.2818
			},
			{
				"open": 79.655,
				"close": 78.2625,
				"volume": 129705660,
				"time": 1582261200000,
				"lowest": 77.625,
				"highest": 80.1125,
				"volumePrice": 78.6341,
				"transactionCount": 323163,
				"voluemPrice": 78.6341
			},
			{
				"open": 74.315,
				"close": 74.545,
				"volume": 222149312,
				"time": 1582520400000,
				"lowest": 72.3075,
				"highest": 76.045,
				"volumePrice": 74.8699,
				"transactionCount": 586879,
				"voluemPrice": 74.8699
			},
			{
				"open": 75.2375,
				"close": 72.02,
				"volume": 230623456,
				"time": 1582606800000,
				"lowest": 71.5325,
				"highest": 75.6325,
				"volumePrice": 73.3055,
				"transactionCount": 624788,
				"voluemPrice": 73.3055
			},
			{
				"open": 71.6325,
				"close": 73.1625,
				"volume": 198153724,
				"time": 1582693200000,
				"lowest": 71.625,
				"highest": 74.47,
				"volumePrice": 73.2211,
				"transactionCount": 494238,
				"voluemPrice": 73.2211
			},
			{
				"open": 70.275,
				"close": 68.38,
				"volume": 318605524,
				"time": 1582779600000,
				"lowest": 68.24,
				"highest": 71.5,
				"volumePrice": 69.9545,
				"transactionCount": 811075,
				"voluemPrice": 69.9545
			},
			{
				"open": 64.315,
				"close": 68.34,
				"volume": 426884920,
				"time": 1582866000000,
				"lowest": 64.0925,
				"highest": 69.6025,
				"volumePrice": 66.7569,
				"transactionCount": 1118286,
				"voluemPrice": 66.7569
			},
			{
				"open": 70.57,
				"close": 74.7025,
				"volume": 341329356,
				"time": 1583125200000,
				"lowest": 69.43,
				"highest": 75.36,
				"volumePrice": 72.1688,
				"transactionCount": 850990,
				"voluemPrice": 72.1688
			},
			{
				"open": 75.9175,
				"close": 72.33,
				"volume": 319474208,
				"time": 1583211600000,
				"lowest": 71.45,
				"highest": 76,
				"volumePrice": 73.6107,
				"transactionCount": 841732,
				"voluemPrice": 73.6107
			},
			{
				"open": 74.11,
				"close": 75.685,
				"volume": 219178272,
				"time": 1583298000000,
				"lowest": 73.2825,
				"highest": 75.85,
				"volumePrice": 74.5584,
				"transactionCount": 506987,
				"voluemPrice": 74.5584
			},
			{
				"open": 73.88,
				"close": 73.23,
				"volume": 187572876,
				"time": 1583384400000,
				"lowest": 72.8525,
				"highest": 74.8875,
				"volumePrice": 73.8242,
				"transactionCount": 433274,
				"voluemPrice": 73.8242
			},
			{
				"open": 70.5,
				"close": 72.2575,
				"volume": 226176984,
				"time": 1583470800000,
				"lowest": 70.3075,
				"highest": 72.705,
				"volumePrice": 71.4465,
				"transactionCount": 551657,
				"voluemPrice": 71.4465
			},
			{
				"open": 65.9375,
				"close": 66.5425,
				"volume": 286744832,
				"time": 1583726400000,
				"lowest": 65.75,
				"highest": 69.5225,
				"volumePrice": 67.8998,
				"transactionCount": 733182,
				"voluemPrice": 67.8998
			},
			{
				"open": 69.285,
				"close": 71.335,
				"volume": 285290080,
				"time": 1583812800000,
				"lowest": 67.3425,
				"highest": 71.61,
				"volumePrice": 69.4642,
				"transactionCount": 696310,
				"voluemPrice": 69.4642
			},
			{
				"open": 69.3475,
				"close": 68.8575,
				"volume": 256379880,
				"time": 1583899200000,
				"lowest": 67.965,
				"highest": 70.305,
				"volumePrice": 69.1036,
				"transactionCount": 587138,
				"voluemPrice": 69.1036
			},
			{
				"open": 63.985,
				"close": 62.0575,
				"volume": 418474068,
				"time": 1583985600000,
				"lowest": 62,
				"highest": 67.5,
				"volumePrice": 63.8272,
				"transactionCount": 1085533,
				"voluemPrice": 63.8272
			},
			{
				"open": 66.2225,
				"close": 69.4925,
				"volume": 368732128,
				"time": 1584072000000,
				"lowest": 63.2375,
				"highest": 69.98,
				"volumePrice": 65.7384,
				"transactionCount": 938015,
				"voluemPrice": 65.7384
			},
			{
				"open": 60.4875,
				"close": 60.5525,
				"volume": 322423460,
				"time": 1584331200000,
				"lowest": 60,
				"highest": 64.77,
				"volumePrice": 62.6169,
				"transactionCount": 826388,
				"voluemPrice": 62.6169
			},
			{
				"open": 61.8775,
				"close": 63.215,
				"volume": 323855860,
				"time": 1584417600000,
				"lowest": 59.6,
				"highest": 64.4025,
				"volumePrice": 62.4835,
				"transactionCount": 825893,
				"voluemPrice": 62.4835
			},
			{
				"open": 59.9425,
				"close": 61.6675,
				"volume": 300233624,
				"time": 1584504000000,
				"lowest": 59.28,
				"highest": 62.5,
				"volumePrice": 60.792,
				"transactionCount": 757704,
				"voluemPrice": 60.792
			},
			{
				"open": 61.8463,
				"close": 61.195,
				"volume": 271857020,
				"time": 1584590400000,
				"lowest": 60.6525,
				"highest": 63.21,
				"volumePrice": 61.9001,
				"transactionCount": 662842,
				"voluemPrice": 61.9001
			},
			{
				"open": 61.795,
				"close": 57.31,
				"volume": 401693384,
				"time": 1584676800000,
				"lowest": 57,
				"highest": 62.9575,
				"volumePrice": 60.2126,
				"transactionCount": 862652,
				"voluemPrice": 60.2126
			},
			{
				"open": 57.02,
				"close": 56.0925,
				"volume": 336752832,
				"time": 1584936000000,
				"lowest": 53.1525,
				"highest": 57.1249,
				"volumePrice": 55.3518,
				"transactionCount": 838611,
				"voluemPrice": 55.3518
			},
			{
				"open": 59.09,
				"close": 61.72,
				"volume": 287531092,
				"time": 1585022400000,
				"lowest": 58.575,
				"highest": 61.9225,
				"volumePrice": 60.3309,
				"transactionCount": 692491,
				"voluemPrice": 60.3309
			},
			{
				"open": 62.6875,
				"close": 61.38,
				"volume": 303602040,
				"time": 1585108800000,
				"lowest": 61.075,
				"highest": 64.5625,
				"volumePrice": 62.931,
				"transactionCount": 755720,
				"voluemPrice": 62.931
			},
			{
				"open": 61.63,
				"close": 64.61,
				"volume": 252560596,
				"time": 1585195200000,
				"lowest": 61.59,
				"highest": 64.67,
				"volumePrice": 63.194,
				"transactionCount": 584615,
				"voluemPrice": 63.194
			},
			{
				"open": 63.1875,
				"close": 61.935,
				"volume": 204216612,
				"time": 1585281600000,
				"lowest": 61.7625,
				"highest": 63.9675,
				"volumePrice": 62.7031,
				"transactionCount": 490477,
				"voluemPrice": 62.7031
			},
			{
				"open": 62.685,
				"close": 63.7025,
				"volume": 167976440,
				"time": 1585540800000,
				"lowest": 62.35,
				"highest": 63.88,
				"volumePrice": 63.1713,
				"transactionCount": 371745,
				"voluemPrice": 63.1713
			},
			{
				"open": 63.9,
				"close": 63.5725,
				"volume": 197002004,
				"time": 1585627200000,
				"lowest": 63,
				"highest": 65.6225,
				"volumePrice": 64.1079,
				"transactionCount": 405381,
				"voluemPrice": 64.1079
			},
			{
				"open": 61.625,
				"close": 60.2275,
				"volume": 176046552,
				"time": 1585713600000,
				"lowest": 59.7825,
				"highest": 62.18,
				"volumePrice": 60.9782,
				"transactionCount": 460605,
				"voluemPrice": 60.9782
			},
			{
				"open": 60.085,
				"close": 61.2325,
				"volume": 165933972,
				"time": 1585800000000,
				"lowest": 59.225,
				"highest": 61.2875,
				"volumePrice": 60.3945,
				"transactionCount": 380294,
				"voluemPrice": 60.3945
			},
			{
				"open": 60.7,
				"close": 60.3525,
				"volume": 129880068,
				"time": 1585886400000,
				"lowest": 59.7435,
				"highest": 61.425,
				"volumePrice": 60.4515,
				"transactionCount": 293699,
				"voluemPrice": 60.4515
			},
			{
				"open": 62.725,
				"close": 65.6175,
				"volume": 201820284,
				"time": 1586145600000,
				"lowest": 62.345,
				"highest": 65.7775,
				"volumePrice": 64.0392,
				"transactionCount": 486681,
				"voluemPrice": 64.0392
			},
			{
				"open": 67.7,
				"close": 64.8575,
				"volume": 202887324,
				"time": 1586232000000,
				"lowest": 64.75,
				"highest": 67.925,
				"volumePrice": 66.1413,
				"transactionCount": 467375,
				"voluemPrice": 66.1413
			},
			{
				"open": 65.685,
				"close": 66.5175,
				"volume": 168895284,
				"time": 1586318400000,
				"lowest": 65.3075,
				"highest": 66.8425,
				"volumePrice": 66.1893,
				"transactionCount": 391246,
				"voluemPrice": 66.1893
			},
			{
				"open": 67.175,
				"close": 66.9975,
				"volume": 162116492,
				"time": 1586404800000,
				"lowest": 66.175,
				"highest": 67.5175,
				"volumePrice": 66.8074,
				"transactionCount": 415870,
				"voluemPrice": 66.8074
			},
			{
				"open": 67.0775,
				"close": 68.3125,
				"volume": 131022924,
				"time": 1586750400000,
				"lowest": 66.4575,
				"highest": 68.425,
				"volumePrice": 67.3614,
				"transactionCount": 343829,
				"voluemPrice": 67.3614
			},
			{
				"open": 70,
				"close": 71.7625,
				"volume": 194994688,
				"time": 1586836800000,
				"lowest": 69.5125,
				"highest": 72.0625,
				"volumePrice": 71.2864,
				"transactionCount": 476983,
				"voluemPrice": 71.2864
			},
			{
				"open": 70.6,
				"close": 71.1075,
				"volume": 131154564,
				"time": 1586923200000,
				"lowest": 70.1575,
				"highest": 71.5825,
				"volumePrice": 70.9263,
				"transactionCount": 312185,
				"voluemPrice": 70.9263
			},
			{
				"open": 71.845,
				"close": 71.6725,
				"volume": 157125160,
				"time": 1587009600000,
				"lowest": 70.5875,
				"highest": 72.0494,
				"volumePrice": 71.4464,
				"transactionCount": 377056,
				"voluemPrice": 71.4464
			},
			{
				"open": 71.1725,
				"close": 70.7,
				"volume": 215249912,
				"time": 1587096000000,
				"lowest": 69.215,
				"highest": 71.7363,
				"volumePrice": 70.404,
				"transactionCount": 571192,
				"voluemPrice": 70.404
			},
			{
				"open": 69.4875,
				"close": 69.2325,
				"volume": 130015000,
				"time": 1587355200000,
				"lowest": 69.2125,
				"highest": 70.42,
				"volumePrice": 69.753,
				"transactionCount": 357160,
				"voluemPrice": 69.753
			},
			{
				"open": 69.07,
				"close": 67.0925,
				"volume": 180991572,
				"time": 1587441600000,
				"lowest": 66.3575,
				"highest": 69.3125,
				"volumePrice": 67.4343,
				"transactionCount": 530768,
				"voluemPrice": 67.4343
			},
			{
				"open": 68.4025,
				"close": 69.025,
				"volume": 117055368,
				"time": 1587528000000,
				"lowest": 68.05,
				"highest": 69.475,
				"volumePrice": 68.7543,
				"transactionCount": 287131,
				"voluemPrice": 68.7543
			},
			{
				"open": 68.9675,
				"close": 68.7575,
				"volume": 124801712,
				"time": 1587614400000,
				"lowest": 68.7175,
				"highest": 70.4375,
				"volumePrice": 69.4705,
				"transactionCount": 318296,
				"voluemPrice": 69.4705
			},
			{
				"open": 69.3,
				"close": 70.7425,
				"volume": 126508732,
				"time": 1587700800000,
				"lowest": 69.25,
				"highest": 70.7525,
				"volumePrice": 70.0964,
				"transactionCount": 306176,
				"voluemPrice": 70.0964
			},
			{
				"open": 70.45,
				"close": 70.7925,
				"volume": 117087572,
				"time": 1587960000000,
				"lowest": 69.9875,
				"highest": 71.135,
				"volumePrice": 70.5883,
				"transactionCount": 300771,
				"voluemPrice": 70.5883
			},
			{
				"open": 71.27,
				"close": 69.645,
				"volume": 112004748,
				"time": 1588046400000,
				"lowest": 69.55,
				"highest": 71.4575,
				"volumePrice": 70.406,
				"transactionCount": 285384,
				"voluemPrice": 70.406
			},
			{
				"open": 71.1825,
				"close": 71.9325,
				"volume": 137280816,
				"time": 1588132800000,
				"lowest": 70.9725,
				"highest": 72.4175,
				"volumePrice": 71.8512,
				"transactionCount": 324890,
				"voluemPrice": 71.8512
			},
			{
				"open": 72.49,
				"close": 73.45,
				"volume": 183063872,
				"time": 1588219200000,
				"lowest": 72.0875,
				"highest": 73.6325,
				"volumePrice": 72.9189,
				"transactionCount": 471129,
				"voluemPrice": 72.9189
			},
			{
				"open": 71.5625,
				"close": 72.2675,
				"volume": 240616692,
				"time": 1588305600000,
				"lowest": 71.4625,
				"highest": 74.75,
				"volumePrice": 73.1481,
				"transactionCount": 558318,
				"voluemPrice": 73.1481
			},
			{
				"open": 72.2925,
				"close": 73.29,
				"volume": 133446864,
				"time": 1588564800000,
				"lowest": 71.5793,
				"highest": 73.4225,
				"volumePrice": 72.6385,
				"transactionCount": 320744,
				"voluemPrice": 72.6385
			},
			{
				"open": 73.765,
				"close": 74.39,
				"volume": 147751180,
				"time": 1588651200000,
				"lowest": 73.615,
				"highest": 75.25,
				"volumePrice": 74.6384,
				"transactionCount": 361220,
				"voluemPrice": 74.6384
			},
			{
				"open": 75.115,
				"close": 75.1575,
				"volume": 142333256,
				"time": 1588737600000,
				"lowest": 74.7175,
				"highest": 75.81,
				"volumePrice": 75.4385,
				"transactionCount": 327327,
				"voluemPrice": 75.4385
			},
			{
				"open": 75.805,
				"close": 75.935,
				"volume": 115215056,
				"time": 1588824000000,
				"lowest": 75.4925,
				"highest": 76.2925,
				"volumePrice": 75.9291,
				"transactionCount": 273196,
				"voluemPrice": 75.9291
			},
			{
				"open": 76.41,
				"close": 77.5325,
				"volume": 134047940,
				"time": 1588910400000,
				"lowest": 76.0725,
				"highest": 77.5875,
				"volumePrice": 76.9476,
				"transactionCount": 318777,
				"voluemPrice": 76.9476
			},
			{
				"open": 77.025,
				"close": 78.7525,
				"volume": 145945400,
				"time": 1589169600000,
				"lowest": 76.81,
				"highest": 79.2625,
				"volumePrice": 78.2535,
				"transactionCount": 336670,
				"voluemPrice": 78.2535
			},
			{
				"open": 79.4575,
				"close": 77.8525,
				"volume": 162100936,
				"time": 1589256000000,
				"lowest": 77.7275,
				"highest": 79.922,
				"volumePrice": 79.0703,
				"transactionCount": 372891,
				"voluemPrice": 79.0703
			},
			{
				"open": 78.0375,
				"close": 76.9125,
				"volume": 200622556,
				"time": 1589342400000,
				"lowest": 75.8025,
				"highest": 78.9875,
				"volumePrice": 77.2421,
				"transactionCount": 489388,
				"voluemPrice": 77.2421
			},
			{
				"open": 76.1275,
				"close": 77.385,
				"volume": 158600728,
				"time": 1589428800000,
				"lowest": 75.3825,
				"highest": 77.4475,
				"volumePrice": 76.401,
				"transactionCount": 370233,
				"voluemPrice": 76.401
			},
			{
				"open": 75.0875,
				"close": 76.9275,
				"volume": 165283688,
				"time": 1589515200000,
				"lowest": 75.0525,
				"highest": 76.975,
				"volumePrice": 76.2066,
				"transactionCount": 385764,
				"voluemPrice": 76.2066
			},
			{
				"open": 78.2925,
				"close": 78.74,
				"volume": 135369872,
				"time": 1589774400000,
				"lowest": 77.581,
				"highest": 79.125,
				"volumePrice": 78.524,
				"transactionCount": 315508,
				"voluemPrice": 78.524
			},
			{
				"open": 78.7575,
				"close": 78.285,
				"volume": 101729540,
				"time": 1589860800000,
				"lowest": 78.2525,
				"highest": 79.63,
				"volumePrice": 79.087,
				"transactionCount": 253423,
				"voluemPrice": 79.087
			},
			{
				"open": 79.17,
				"close": 79.8075,
				"volume": 111504860,
				"time": 1589947200000,
				"lowest": 79.05,
				"highest": 79.88,
				"volumePrice": 79.6228,
				"transactionCount": 263855,
				"voluemPrice": 79.6228
			},
			{
				"open": 79.665,
				"close": 79.2125,
				"volume": 102688844,
				"time": 1590033600000,
				"lowest": 78.9675,
				"highest": 80.2225,
				"volumePrice": 79.5353,
				"transactionCount": 250904,
				"voluemPrice": 79.5353
			},
			{
				"open": 78.9425,
				"close": 79.7225,
				"volume": 81803016,
				"time": 1590120000000,
				"lowest": 78.8375,
				"highest": 79.8075,
				"volumePrice": 79.3895,
				"transactionCount": 205849,
				"voluemPrice": 79.3895
			},
			{
				"open": 80.875,
				"close": 79.1825,
				"volume": 125519176,
				"time": 1590465600000,
				"lowest": 79.125,
				"highest": 81.06,
				"volumePrice": 79.8905,
				"transactionCount": 309826,
				"voluemPrice": 79.8905
			},
			{
				"open": 79.035,
				"close": 79.5275,
				"volume": 112941436,
				"time": 1590552000000,
				"lowest": 78.2725,
				"highest": 79.6775,
				"volumePrice": 79.0398,
				"transactionCount": 279261,
				"voluemPrice": 79.0398
			},
			{
				"open": 79.1925,
				"close": 79.5625,
				"volume": 133795408,
				"time": 1590638400000,
				"lowest": 78.9075,
				"highest": 80.86,
				"volumePrice": 80.0894,
				"transactionCount": 320359,
				"voluemPrice": 80.0894
			},
			{
				"open": 79.8125,
				"close": 79.485,
				"volume": 153598128,
				"time": 1590724800000,
				"lowest": 79.1175,
				"highest": 80.2875,
				"volumePrice": 79.6771,
				"transactionCount": 307848,
				"voluemPrice": 79.6771
			},
			{
				"open": 79.4375,
				"close": 80.4625,
				"volume": 81000752,
				"time": 1590984000000,
				"lowest": 79.3025,
				"highest": 80.5875,
				"volumePrice": 80.2125,
				"transactionCount": 231707,
				"voluemPrice": 80.2125
			},
			{
				"open": 80.1863,
				"close": 80.835,
				"volume": 87642632,
				"time": 1591070400000,
				"lowest": 79.7325,
				"highest": 80.86,
				"volumePrice": 80.3801,
				"transactionCount": 226248,
				"voluemPrice": 80.3801
			},
			{
				"open": 81.165,
				"close": 81.28,
				"volume": 104490584,
				"time": 1591156800000,
				"lowest": 80.575,
				"highest": 81.55,
				"volumePrice": 81.084,
				"transactionCount": 258867,
				"voluemPrice": 81.084
			},
			{
				"open": 81.0975,
				"close": 80.58,
				"volume": 87560364,
				"time": 1591243200000,
				"lowest": 80.195,
				"highest": 81.405,
				"volumePrice": 80.8328,
				"transactionCount": 237171,
				"voluemPrice": 80.8328
			},
			{
				"open": 80.8375,
				"close": 82.875,
				"volume": 137248048,
				"time": 1591329600000,
				"lowest": 80.8075,
				"highest": 82.9375,
				"volumePrice": 82.2786,
				"transactionCount": 351615,
				"voluemPrice": 82.2786
			},
			{
				"open": 82.5625,
				"close": 83.365,
				"volume": 95650900,
				"time": 1591588800000,
				"lowest": 81.83,
				"highest": 83.4,
				"volumePrice": 82.7662,
				"transactionCount": 261203,
				"voluemPrice": 82.7662
			},
			{
				"open": 83.035,
				"close": 85.9975,
				"volume": 147712228,
				"time": 1591675200000,
				"lowest": 83.0025,
				"highest": 86.4025,
				"volumePrice": 85.3634,
				"transactionCount": 402768,
				"voluemPrice": 85.3634
			},
			{
				"open": 86.975,
				"close": 88.21,
				"volume": 166646980,
				"time": 1591761600000,
				"lowest": 86.5225,
				"highest": 88.6925,
				"volumePrice": 87.8736,
				"transactionCount": 449782,
				"voluemPrice": 87.8736
			},
			{
				"open": 87.3275,
				"close": 83.975,
				"volume": 201659684,
				"time": 1591848000000,
				"lowest": 83.87,
				"highest": 87.765,
				"volumePrice": 85.7741,
				"transactionCount": 577299,
				"voluemPrice": 85.7741
			},
			{
				"open": 86.18,
				"close": 84.7,
				"volume": 200145124,
				"time": 1591934400000,
				"lowest": 83.5558,
				"highest": 86.95,
				"volumePrice": 85.0132,
				"transactionCount": 514308,
				"voluemPrice": 85.0132
			},
			{
				"open": 83.3125,
				"close": 85.7475,
				"volume": 138808720,
				"time": 1592193600000,
				"lowest": 83.145,
				"highest": 86.42,
				"volumePrice": 84.8782,
				"transactionCount": 368615,
				"voluemPrice": 84.8782
			},
			{
				"open": 87.865,
				"close": 88.02,
				"volume": 165428728,
				"time": 1592280000000,
				"lowest": 86.18,
				"highest": 88.3,
				"volumePrice": 87.7181,
				"transactionCount": 446656,
				"voluemPrice": 87.7181
			},
			{
				"open": 88.7875,
				"close": 87.8975,
				"volume": 114388504,
				"time": 1592366400000,
				"lowest": 87.7725,
				"highest": 88.85,
				"volumePrice": 88.4283,
				"transactionCount": 321829,
				"voluemPrice": 88.4283
			},
			{
				"open": 87.8525,
				"close": 87.9325,
				"volume": 96820384,
				"time": 1592452800000,
				"lowest": 87.305,
				"highest": 88.3625,
				"volumePrice": 87.8912,
				"transactionCount": 274412,
				"voluemPrice": 87.8912
			},
			{
				"open": 88.6588,
				"close": 87.43,
				"volume": 264475808,
				"time": 1592539200000,
				"lowest": 86.2875,
				"highest": 89.14,
				"volumePrice": 87.6158,
				"transactionCount": 521900,
				"voluemPrice": 87.6158
			},
			{
				"open": 87.835,
				"close": 89.7175,
				"volume": 135445064,
				"time": 1592798400000,
				"lowest": 87.7875,
				"highest": 89.865,
				"volumePrice": 89.1444,
				"transactionCount": 365763,
				"voluemPrice": 89.1444
			},
			{
				"open": 91,
				"close": 91.6325,
				"volume": 212155476,
				"time": 1592884800000,
				"lowest": 90.5675,
				"highest": 93.095,
				"volumePrice": 91.9246,
				"transactionCount": 540691,
				"voluemPrice": 91.9246
			},
			{
				"open": 91.25,
				"close": 90.015,
				"volume": 192623396,
				"time": 1592971200000,
				"lowest": 89.63,
				"highest": 92.1975,
				"volumePrice": 90.5741,
				"transactionCount": 520696,
				"voluemPrice": 90.5741
			},
			{
				"open": 90.175,
				"close": 91.21,
				"volume": 137522512,
				"time": 1593057600000,
				"lowest": 89.3925,
				"highest": 91.25,
				"volumePrice": 90.4714,
				"transactionCount": 364768,
				"voluemPrice": 90.4714
			},
			{
				"open": 91.1025,
				"close": 88.4075,
				"volume": 205256844,
				"time": 1593144000000,
				"lowest": 88.255,
				"highest": 91.33,
				"volumePrice": 89.3208,
				"transactionCount": 478043,
				"voluemPrice": 89.3208
			},
			{
				"open": 88.3125,
				"close": 90.445,
				"volume": 130646076,
				"time": 1593403200000,
				"lowest": 87.82,
				"highest": 90.5434,
				"volumePrice": 89.6679,
				"transactionCount": 345350,
				"voluemPrice": 89.6679
			},
			{
				"open": 90.02,
				"close": 91.2,
				"volume": 140217996,
				"time": 1593489600000,
				"lowest": 90,
				"highest": 91.495,
				"volumePrice": 90.9687,
				"transactionCount": 332668,
				"voluemPrice": 90.9687
			},
			{
				"open": 91.28,
				"close": 91.0275,
				"volume": 110737236,
				"time": 1593576000000,
				"lowest": 90.9775,
				"highest": 91.84,
				"volumePrice": 91.4261,
				"transactionCount": 317158,
				"voluemPrice": 91.4261
			},
			{
				"open": 91.9625,
				"close": 91.0275,
				"volume": 114041468,
				"time": 1593662400000,
				"lowest": 90.91,
				"highest": 92.6175,
				"volumePrice": 91.761,
				"transactionCount": 304461,
				"voluemPrice": 91.761
			},
			{
				"open": 92.5,
				"close": 93.4625,
				"volume": 118655652,
				"time": 1594008000000,
				"lowest": 92.4675,
				"highest": 93.945,
				"volumePrice": 93.3518,
				"transactionCount": 335176,
				"voluemPrice": 93.3518
			},
			{
				"open": 93.8525,
				"close": 93.1725,
				"volume": 112419852,
				"time": 1594094400000,
				"lowest": 93.0575,
				"highest": 94.655,
				"volumePrice": 93.9268,
				"transactionCount": 312176,
				"voluemPrice": 93.9268
			},
			{
				"open": 94.18,
				"close": 95.3425,
				"volume": 117091880,
				"time": 1594180800000,
				"lowest": 94.09,
				"highest": 95.375,
				"volumePrice": 94.885,
				"transactionCount": 328566,
				"voluemPrice": 94.885
			},
			{
				"open": 96.2625,
				"close": 95.6825,
				"volume": 125632928,
				"time": 1594267200000,
				"lowest": 94.6725,
				"highest": 96.3175,
				"volumePrice": 95.6154,
				"transactionCount": 381750,
				"voluemPrice": 95.6154
			},
			{
				"open": 95.335,
				"close": 95.92,
				"volume": 90257320,
				"time": 1594353600000,
				"lowest": 94.7052,
				"highest": 95.98,
				"volumePrice": 95.4535,
				"transactionCount": 278154,
				"voluemPrice": 95.4535
			},
			{
				"open": 97.265,
				"close": 95.4775,
				"volume": 191649140,
				"time": 1594612800000,
				"lowest": 95.2575,
				"highest": 99.955,
				"volumePrice": 97.9313,
				"transactionCount": 582519,
				"voluemPrice": 97.9313
			},
			{
				"open": 94.84,
				"close": 97.0575,
				"volume": 170984564,
				"time": 1594699200000,
				"lowest": 93.8775,
				"highest": 97.255,
				"volumePrice": 95.8252,
				"transactionCount": 475794,
				"voluemPrice": 95.8252
			},
			{
				"open": 98.99,
				"close": 97.725,
				"volume": 153175132,
				"time": 1594785600000,
				"lowest": 96.49,
				"highest": 99.2475,
				"volumePrice": 97.7985,
				"transactionCount": 437741,
				"voluemPrice": 97.7985
			},
			{
				"open": 96.5625,
				"close": 96.5225,
				"volume": 110572372,
				"time": 1594872000000,
				"lowest": 95.905,
				"highest": 97.405,
				"volumePrice": 96.468,
				"transactionCount": 345189,
				"voluemPrice": 96.468
			},
			{
				"open": 96.9875,
				"close": 96.3275,
				"volume": 92164500,
				"time": 1594958400000,
				"lowest": 95.84,
				"highest": 97.1475,
				"volumePrice": 96.4832,
				"transactionCount": 257101,
				"voluemPrice": 96.4832
			},
			{
				"open": 96.4163,
				"close": 98.3575,
				"volume": 90317908,
				"time": 1595217600000,
				"lowest": 96.0625,
				"highest": 98.5,
				"volumePrice": 97.4448,
				"transactionCount": 281653,
				"voluemPrice": 97.4448
			},
			{
				"open": 99.1725,
				"close": 97,
				"volume": 103644436,
				"time": 1595304000000,
				"lowest": 96.7425,
				"highest": 99.25,
				"volumePrice": 97.8163,
				"transactionCount": 328226,
				"voluemPrice": 97.8163
			},
			{
				"open": 96.6925,
				"close": 97.2725,
				"volume": 88994732,
				"time": 1595390400000,
				"lowest": 96.6025,
				"highest": 97.975,
				"volumePrice": 97.1692,
				"transactionCount": 255648,
				"voluemPrice": 97.1692
			},
			{
				"open": 96.9984,
				"close": 92.845,
				"volume": 197003632,
				"time": 1595476800000,
				"lowest": 92.0098,
				"highest": 97.0775,
				"volumePrice": 94.0126,
				"transactionCount": 685256,
				"voluemPrice": 94.0126
			},
			{
				"open": 90.9875,
				"close": 92.615,
				"volume": 185433708,
				"time": 1595563200000,
				"lowest": 89.145,
				"highest": 92.97,
				"volumePrice": 91.6042,
				"transactionCount": 563053,
				"voluemPrice": 91.6042
			},
			{
				"open": 93.71,
				"close": 94.81,
				"volume": 121199392,
				"time": 1595822400000,
				"lowest": 93.48,
				"highest": 94.905,
				"volumePrice": 94.3839,
				"transactionCount": 342422,
				"voluemPrice": 94.3839
			},
			{
				"open": 94.3675,
				"close": 93.2525,
				"volume": 103581500,
				"time": 1595908800000,
				"lowest": 93.2475,
				"highest": 94.5497,
				"volumePrice": 93.8305,
				"transactionCount": 277120,
				"voluemPrice": 93.8305
			},
			{
				"open": 93.75,
				"close": 95.04,
				"volume": 90329256,
				"time": 1595995200000,
				"lowest": 93.7125,
				"highest": 95.23,
				"volumePrice": 94.5805,
				"transactionCount": 260932,
				"voluemPrice": 94.5805
			},
			{
				"open": 94.1875,
				"close": 96.19,
				"volume": 158116020,
				"time": 1596081600000,
				"lowest": 93.7675,
				"highest": 96.2975,
				"volumePrice": 96.4895,
				"transactionCount": 516222,
				"voluemPrice": 96.4895
			},
			{
				"open": 102.8838,
				"close": 106.26,
				"volume": 374295468,
				"time": 1596168000000,
				"lowest": 100.825,
				"highest": 106.415,
				"volumePrice": 103.4718,
				"transactionCount": 1145107,
				"voluemPrice": 103.4718
			},
			{
				"open": 108.2,
				"close": 108.9375,
				"volume": 308150416,
				"time": 1596427200000,
				"lowest": 107.8925,
				"highest": 111.6364,
				"volumePrice": 109.658,
				"transactionCount": 979838,
				"voluemPrice": 109.658
			},
			{
				"open": 109.1325,
				"close": 109.665,
				"volume": 172688068,
				"time": 1596513600000,
				"lowest": 108.3875,
				"highest": 110.79,
				"volumePrice": 109.6574,
				"transactionCount": 527331,
				"voluemPrice": 109.6574
			},
			{
				"open": 109.3775,
				"close": 110.0625,
				"volume": 121991952,
				"time": 1596600000000,
				"lowest": 108.8975,
				"highest": 110.3925,
				"volumePrice": 109.6622,
				"transactionCount": 387414,
				"voluemPrice": 109.6622
			},
			{
				"open": 110.405,
				"close": 113.9025,
				"volume": 202411900,
				"time": 1596686400000,
				"lowest": 109.7975,
				"highest": 114.4125,
				"volumePrice": 112.4859,
				"transactionCount": 585117,
				"voluemPrice": 112.4859
			},
			{
				"open": 113.205,
				"close": 111.1125,
				"volume": 198019812,
				"time": 1596772800000,
				"lowest": 110.2925,
				"highest": 113.675,
				"volumePrice": 111.9905,
				"transactionCount": 648627,
				"voluemPrice": 111.9905
			},
			{
				"open": 112.6,
				"close": 112.7275,
				"volume": 212402024,
				"time": 1597032000000,
				"lowest": 110,
				"highest": 113.775,
				"volumePrice": 112.3171,
				"transactionCount": 642375,
				"voluemPrice": 112.3171
			},
			{
				"open": 111.9688,
				"close": 109.375,
				"volume": 187902376,
				"time": 1597118400000,
				"lowest": 109.1067,
				"highest": 112.4825,
				"volumePrice": 111.0004,
				"transactionCount": 625323,
				"voluemPrice": 111.0004
			},
			{
				"open": 110.4975,
				"close": 113.01,
				"volume": 165942820,
				"time": 1597204800000,
				"lowest": 110.2975,
				"highest": 113.275,
				"volumePrice": 112.2537,
				"transactionCount": 515437,
				"voluemPrice": 112.2537
			},
			{
				"open": 114.43,
				"close": 115.01,
				"volume": 210082064,
				"time": 1597291200000,
				"lowest": 113.9275,
				"highest": 116.0425,
				"volumePrice": 114.9724,
				"transactionCount": 633333,
				"voluemPrice": 114.9724
			},
			{
				"open": 114.8288,
				"close": 114.9075,
				"volume": 165423808,
				"time": 1597377600000,
				"lowest": 113.045,
				"highest": 115,
				"volumePrice": 114.1782,
				"transactionCount": 538654,
				"voluemPrice": 114.1782
			},
			{
				"open": 116.0625,
				"close": 114.6075,
				"volume": 119561044,
				"time": 1597636800000,
				"lowest": 113.9625,
				"highest": 116.0875,
				"volumePrice": 114.8375,
				"transactionCount": 435125,
				"voluemPrice": 114.8375
			},
			{
				"open": 114.3525,
				"close": 115.5625,
				"volume": 105633540,
				"time": 1597723200000,
				"lowest": 114.0075,
				"highest": 116,
				"volumePrice": 115.2166,
				"transactionCount": 388889,
				"voluemPrice": 115.2166
			},
			{
				"open": 115.9833,
				"close": 115.7075,
				"volume": 145538008,
				"time": 1597809600000,
				"lowest": 115.61,
				"highest": 117.1625,
				"volumePrice": 116.4218,
				"transactionCount": 499399,
				"voluemPrice": 116.4218
			},
			{
				"open": 115.75,
				"close": 118.275,
				"volume": 126871188,
				"time": 1597896000000,
				"lowest": 115.7334,
				"highest": 118.392,
				"volumePrice": 117.3864,
				"transactionCount": 472757,
				"voluemPrice": 117.3864
			},
			{
				"open": 119.2625,
				"close": 124.37,
				"volume": 337454640,
				"time": 1597982400000,
				"lowest": 119.25,
				"highest": 124.868,
				"volumePrice": 122.6605,
				"transactionCount": 1092109,
				"voluemPrice": 122.6605
			},
			{
				"open": 128.6975,
				"close": 125.8575,
				"volume": 345905768,
				"time": 1598241600000,
				"lowest": 123.9363,
				"highest": 128.785,
				"volumePrice": 126.3212,
				"transactionCount": 1287610,
				"voluemPrice": 126.3212
			},
			{
				"open": 124.6975,
				"close": 124.825,
				"volume": 211234408,
				"time": 1598328000000,
				"lowest": 123.0525,
				"highest": 125.1793,
				"volumePrice": 124.1912,
				"transactionCount": 761144,
				"voluemPrice": 124.1912
			},
			{
				"open": 126.1791,
				"close": 126.5225,
				"volume": 162473268,
				"time": 1598414400000,
				"lowest": 125.0825,
				"highest": 126.9925,
				"volumePrice": 126.0815,
				"transactionCount": 627481,
				"voluemPrice": 126.0815
			},
			{
				"open": 127.1425,
				"close": 125.01,
				"volume": 155516384,
				"time": 1598500800000,
				"lowest": 123.8325,
				"highest": 127.485,
				"volumePrice": 125.6908,
				"transactionCount": 679923,
				"voluemPrice": 125.6908
			},
			{
				"open": 126.0125,
				"close": 124.8075,
				"volume": 187529916,
				"time": 1598587200000,
				"lowest": 124.5775,
				"highest": 126.4425,
				"volumePrice": 125.3296,
				"transactionCount": 690493,
				"voluemPrice": 125.3296
			},
			{
				"open": 127.58,
				"close": 129.04,
				"volume": 225631664,
				"time": 1598846400000,
				"lowest": 126,
				"highest": 131,
				"volumePrice": 128.9302,
				"transactionCount": 2753245,
				"voluemPrice": 128.9302
			},
			{
				"open": 132.76,
				"close": 134.18,
				"volume": 152050116,
				"time": 1598932800000,
				"lowest": 130.53,
				"highest": 134.8,
				"volumePrice": 132.8458,
				"transactionCount": 1494243,
				"voluemPrice": 132.8458
			},
			{
				"open": 137.59,
				"close": 131.4,
				"volume": 200117202,
				"time": 1599019200000,
				"lowest": 127,
				"highest": 137.98,
				"volumePrice": 131.6134,
				"transactionCount": 1841705,
				"voluemPrice": 131.6134
			},
			{
				"open": 126.91,
				"close": 120.88,
				"volume": 257589206,
				"time": 1599105600000,
				"lowest": 120.5,
				"highest": 128.84,
				"volumePrice": 123.526,
				"transactionCount": 2360712,
				"voluemPrice": 123.526
			},
			{
				"open": 120.07,
				"close": 120.96,
				"volume": 332549990,
				"time": 1599192000000,
				"lowest": 110.89,
				"highest": 123.7,
				"volumePrice": 117.9436,
				"transactionCount": 2966979,
				"voluemPrice": 117.9436
			},
			{
				"open": 113.95,
				"close": 112.82,
				"volume": 231349531,
				"time": 1599537600000,
				"lowest": 112.68,
				"highest": 118.99,
				"volumePrice": 115.3956,
				"transactionCount": 2003625,
				"voluemPrice": 115.3956
			},
			{
				"open": 117.26,
				"close": 117.32,
				"volume": 176833475,
				"time": 1599624000000,
				"lowest": 115.26,
				"highest": 119.14,
				"volumePrice": 117.4152,
				"transactionCount": 1308828,
				"voluemPrice": 117.4152
			},
			{
				"open": 120.36,
				"close": 113.49,
				"volume": 182274291,
				"time": 1599710400000,
				"lowest": 112.5,
				"highest": 120.5,
				"volumePrice": 116.0457,
				"transactionCount": 1444575,
				"voluemPrice": 116.0457
			},
			{
				"open": 114.57,
				"close": 112,
				"volume": 180846525,
				"time": 1599796800000,
				"lowest": 110,
				"highest": 115.23,
				"volumePrice": 112.0421,
				"transactionCount": 1405577,
				"voluemPrice": 112.0421
			},
			{
				"open": 114.72,
				"close": 115.355,
				"volume": 140112337,
				"time": 1600056000000,
				"lowest": 112.8,
				"highest": 115.93,
				"volumePrice": 114.5795,
				"transactionCount": 1013512,
				"voluemPrice": 114.5795
			},
			{
				"open": 118.33,
				"close": 115.54,
				"volume": 183786539,
				"time": 1600142400000,
				"lowest": 113.61,
				"highest": 118.829,
				"volumePrice": 116.4171,
				"transactionCount": 1290594,
				"voluemPrice": 116.4171
			},
			{
				"open": 115.23,
				"close": 112.13,
				"volume": 154719375,
				"time": 1600228800000,
				"lowest": 112.04,
				"highest": 116,
				"volumePrice": 113.639,
				"transactionCount": 1186032,
				"voluemPrice": 113.639
			},
			{
				"open": 109.72,
				"close": 110.34,
				"volume": 177056468,
				"time": 1600315200000,
				"lowest": 108.71,
				"highest": 112.2,
				"volumePrice": 110.1388,
				"transactionCount": 1363245,
				"voluemPrice": 110.1388
			},
			{
				"open": 110.4,
				"close": 106.84,
				"volume": 287076882,
				"time": 1600401600000,
				"lowest": 106.09,
				"highest": 110.88,
				"volumePrice": 107.7831,
				"transactionCount": 1650704,
				"voluemPrice": 107.7831
			},
			{
				"open": 104.54,
				"close": 110.08,
				"volume": 195547704,
				"time": 1600660800000,
				"lowest": 103.1,
				"highest": 110.19,
				"volumePrice": 106.9383,
				"transactionCount": 1362739,
				"voluemPrice": 106.9383
			},
			{
				"open": 112.68,
				"close": 111.81,
				"volume": 183053939,
				"time": 1600747200000,
				"lowest": 109.16,
				"highest": 112.86,
				"volumePrice": 110.9711,
				"transactionCount": 1128760,
				"voluemPrice": 110.9711
			},
			{
				"open": 111.62,
				"close": 107.12,
				"volume": 150718671,
				"time": 1600833600000,
				"lowest": 106.77,
				"highest": 112.11,
				"volumePrice": 109.1458,
				"transactionCount": 1007065,
				"voluemPrice": 109.1458
			},
			{
				"open": 105.17,
				"close": 108.22,
				"volume": 167743149,
				"time": 1600920000000,
				"lowest": 105,
				"highest": 110.25,
				"volumePrice": 108.5536,
				"transactionCount": 981871,
				"voluemPrice": 108.5536
			},
			{
				"open": 108.43,
				"close": 112.28,
				"volume": 149981441,
				"time": 1601006400000,
				"lowest": 107.67,
				"highest": 112.44,
				"volumePrice": 110.654,
				"transactionCount": 882580,
				"voluemPrice": 110.654
			},
			{
				"open": 115.01,
				"close": 114.96,
				"volume": 137668917,
				"time": 1601265600000,
				"lowest": 112.78,
				"highest": 115.32,
				"volumePrice": 114.0428,
				"transactionCount": 866145,
				"voluemPrice": 114.0428
			},
			{
				"open": 114.55,
				"close": 114.09,
				"volume": 100060326,
				"time": 1601352000000,
				"lowest": 113.57,
				"highest": 115.31,
				"volumePrice": 114.2186,
				"transactionCount": 607009,
				"voluemPrice": 114.2186
			},
			{
				"open": 113.79,
				"close": 115.81,
				"volume": 142675133,
				"time": 1601438400000,
				"lowest": 113.62,
				"highest": 117.26,
				"volumePrice": 115.9431,
				"transactionCount": 808528,
				"voluemPrice": 115.9431
			},
			{
				"open": 117.64,
				"close": 116.79,
				"volume": 115886840,
				"time": 1601524800000,
				"lowest": 115.83,
				"highest": 117.72,
				"volumePrice": 116.5914,
				"transactionCount": 673252,
				"voluemPrice": 116.5914
			},
			{
				"open": 112.89,
				"close": 113.02,
				"volume": 144707196,
				"time": 1601611200000,
				"lowest": 112.22,
				"highest": 115.37,
				"volumePrice": 113.8678,
				"transactionCount": 963891,
				"voluemPrice": 113.8678
			},
			{
				"open": 113.91,
				"close": 116.5,
				"volume": 105793839,
				"time": 1601870400000,
				"lowest": 113.55,
				"highest": 116.65,
				"volumePrice": 115.4597,
				"transactionCount": 613097,
				"voluemPrice": 115.4597
			},
			{
				"open": 115.7,
				"close": 113.16,
				"volume": 161498212,
				"time": 1601956800000,
				"lowest": 112.25,
				"highest": 116.12,
				"volumePrice": 114.4362,
				"transactionCount": 1030507,
				"voluemPrice": 114.4362
			},
			{
				"open": 114.62,
				"close": 115.08,
				"volume": 96848985,
				"time": 1602043200000,
				"lowest": 114.13,
				"highest": 115.55,
				"volumePrice": 114.9212,
				"transactionCount": 577788,
				"voluemPrice": 114.9212
			},
			{
				"open": 116.25,
				"close": 114.97,
				"volume": 83477028,
				"time": 1602129600000,
				"lowest": 114.5901,
				"highest": 116.4,
				"volumePrice": 115.2658,
				"transactionCount": 520894,
				"voluemPrice": 115.2658
			},
			{
				"open": 115.28,
				"close": 116.97,
				"volume": 100506865,
				"time": 1602216000000,
				"lowest": 114.92,
				"highest": 117,
				"volumePrice": 116.2428,
				"transactionCount": 635346,
				"voluemPrice": 116.2428
			},
			{
				"open": 120.06,
				"close": 124.4,
				"volume": 239983770,
				"time": 1602475200000,
				"lowest": 119.2845,
				"highest": 125.18,
				"volumePrice": 123.0438,
				"transactionCount": 1409210,
				"voluemPrice": 123.0438
			},
			{
				"open": 125.27,
				"close": 121.1,
				"volume": 262330451,
				"time": 1602561600000,
				"lowest": 119.65,
				"highest": 125.39,
				"volumePrice": 122.4262,
				"transactionCount": 1723602,
				"voluemPrice": 122.4262
			},
			{
				"open": 121,
				"close": 121.19,
				"volume": 151057198,
				"time": 1602648000000,
				"lowest": 119.62,
				"highest": 123.03,
				"volumePrice": 121.3042,
				"transactionCount": 872447,
				"voluemPrice": 121.3042
			},
			{
				"open": 118.72,
				"close": 120.71,
				"volume": 112382719,
				"time": 1602734400000,
				"lowest": 118.15,
				"highest": 121.2,
				"volumePrice": 120.0797,
				"transactionCount": 669233,
				"voluemPrice": 120.0797
			},
			{
				"open": 121.28,
				"close": 119.02,
				"volume": 115328808,
				"time": 1602820800000,
				"lowest": 118.81,
				"highest": 121.548,
				"volumePrice": 120.1349,
				"transactionCount": 653703,
				"voluemPrice": 120.1349
			},
			{
				"open": 119.96,
				"close": 115.98,
				"volume": 120639037,
				"time": 1603080000000,
				"lowest": 115.66,
				"highest": 120.419,
				"volumePrice": 118.086,
				"transactionCount": 773095,
				"voluemPrice": 118.086
			},
			{
				"open": 116.2,
				"close": 117.51,
				"volume": 124423228,
				"time": 1603166400000,
				"lowest": 115.63,
				"highest": 118.98,
				"volumePrice": 117.4342,
				"transactionCount": 687186,
				"voluemPrice": 117.4342
			},
			{
				"open": 116.67,
				"close": 116.87,
				"volume": 89945980,
				"time": 1603252800000,
				"lowest": 116.45,
				"highest": 118.705,
				"volumePrice": 117.4843,
				"transactionCount": 525410,
				"voluemPrice": 117.4843
			},
			{
				"open": 117.45,
				"close": 115.75,
				"volume": 101987951,
				"time": 1603339200000,
				"lowest": 114.59,
				"highest": 118.04,
				"volumePrice": 115.9703,
				"transactionCount": 650707,
				"voluemPrice": 115.9703
			},
			{
				"open": 116.39,
				"close": 115.04,
				"volume": 82304645,
				"time": 1603425600000,
				"lowest": 114.28,
				"highest": 116.55,
				"volumePrice": 114.9857,
				"transactionCount": 576736,
				"voluemPrice": 114.9857
			},
			{
				"open": 114.01,
				"close": 115.05,
				"volume": 111849857,
				"time": 1603684800000,
				"lowest": 112.88,
				"highest": 116.55,
				"volumePrice": 114.6132,
				"transactionCount": 731746,
				"voluemPrice": 114.6132
			},
			{
				"open": 115.49,
				"close": 116.6,
				"volume": 92168772,
				"time": 1603771200000,
				"lowest": 114.5399,
				"highest": 117.28,
				"volumePrice": 116.3162,
				"transactionCount": 554028,
				"voluemPrice": 116.3162
			},
			{
				"open": 115.05,
				"close": 111.2,
				"volume": 143937823,
				"time": 1603857600000,
				"lowest": 111.1,
				"highest": 115.43,
				"volumePrice": 112.727,
				"transactionCount": 969847,
				"voluemPrice": 112.727
			},
			{
				"open": 112.37,
				"close": 115.32,
				"volume": 146124673,
				"time": 1603944000000,
				"lowest": 112.2,
				"highest": 116.93,
				"volumePrice": 114.5124,
				"transactionCount": 1025460,
				"voluemPrice": 114.5124
			},
			{
				"open": 111.06,
				"close": 108.86,
				"volume": 190547476,
				"time": 1604030400000,
				"lowest": 107.72,
				"highest": 111.99,
				"volumePrice": 109.1006,
				"transactionCount": 1493887,
				"voluemPrice": 109.1006
			},
			{
				"open": 109.11,
				"close": 108.77,
				"volume": 122712099,
				"time": 1604293200000,
				"lowest": 107.32,
				"highest": 110.68,
				"volumePrice": 108.6262,
				"transactionCount": 865470,
				"voluemPrice": 108.6262
			},
			{
				"open": 109.66,
				"close": 110.44,
				"volume": 107414082,
				"time": 1604379600000,
				"lowest": 108.73,
				"highest": 111.49,
				"volumePrice": 110.4493,
				"transactionCount": 640197,
				"voluemPrice": 110.4493
			},
			{
				"open": 114.14,
				"close": 114.95,
				"volume": 138217782,
				"time": 1604466000000,
				"lowest": 112.35,
				"highest": 115.59,
				"volumePrice": 114.6156,
				"transactionCount": 846477,
				"voluemPrice": 114.6156
			},
			{
				"open": 117.95,
				"close": 119.03,
				"volume": 126261074,
				"time": 1604552400000,
				"lowest": 116.8686,
				"highest": 119.62,
				"volumePrice": 118.4798,
				"transactionCount": 762125,
				"voluemPrice": 118.4798
			},
			{
				"open": 118.32,
				"close": 118.69,
				"volume": 113892922,
				"time": 1604638800000,
				"lowest": 116.13,
				"highest": 119.2,
				"volumePrice": 118.086,
				"transactionCount": 646271,
				"voluemPrice": 118.086
			},
			{
				"open": 120.5,
				"close": 116.32,
				"volume": 154497315,
				"time": 1604898000000,
				"lowest": 116.05,
				"highest": 121.99,
				"volumePrice": 119.5403,
				"transactionCount": 997670,
				"voluemPrice": 119.5403
			},
			{
				"open": 115.55,
				"close": 115.97,
				"volume": 135838990,
				"time": 1604984400000,
				"lowest": 114.13,
				"highest": 117.59,
				"volumePrice": 116.3117,
				"transactionCount": 820541,
				"voluemPrice": 116.3117
			},
			{
				"open": 117.19,
				"close": 119.49,
				"volume": 112294954,
				"time": 1605070800000,
				"lowest": 116.44,
				"highest": 119.63,
				"volumePrice": 118.5071,
				"transactionCount": 621697,
				"voluemPrice": 118.5071
			},
			{
				"open": 119.62,
				"close": 119.21,
				"volume": 103350674,
				"time": 1605157200000,
				"lowest": 118.57,
				"highest": 120.53,
				"volumePrice": 119.4878,
				"transactionCount": 613387,
				"voluemPrice": 119.4878
			},
			{
				"open": 119.44,
				"close": 119.26,
				"volume": 81688586,
				"time": 1605243600000,
				"lowest": 117.87,
				"highest": 119.6717,
				"volumePrice": 118.8133,
				"transactionCount": 506252,
				"voluemPrice": 118.8133
			},
			{
				"open": 118.92,
				"close": 120.3,
				"volume": 91179618,
				"time": 1605502800000,
				"lowest": 118.146,
				"highest": 120.99,
				"volumePrice": 120.0986,
				"transactionCount": 593377,
				"voluemPrice": 120.0986
			},
			{
				"open": 119.55,
				"close": 119.39,
				"volume": 74270973,
				"time": 1605589200000,
				"lowest": 118.96,
				"highest": 120.6741,
				"volumePrice": 119.8848,
				"transactionCount": 494155,
				"voluemPrice": 119.8848
			},
			{
				"open": 118.61,
				"close": 118.03,
				"volume": 74755911,
				"time": 1605675600000,
				"lowest": 118,
				"highest": 119.82,
				"volumePrice": 118.9237,
				"transactionCount": 495971,
				"voluemPrice": 118.9237
			},
			{
				"open": 117.59,
				"close": 118.64,
				"volume": 73687972,
				"time": 1605762000000,
				"lowest": 116.81,
				"highest": 119.06,
				"volumePrice": 118.032,
				"transactionCount": 492499,
				"voluemPrice": 118.032
			},
			{
				"open": 118.64,
				"close": 117.34,
				"volume": 73534287,
				"time": 1605848400000,
				"lowest": 117.29,
				"highest": 118.77,
				"volumePrice": 117.9871,
				"transactionCount": 460975,
				"voluemPrice": 117.9871
			},
			{
				"open": 117.18,
				"close": 113.85,
				"volume": 127932918,
				"time": 1606107600000,
				"lowest": 113.75,
				"highest": 117.6202,
				"volumePrice": 114.9681,
				"transactionCount": 1020038,
				"voluemPrice": 114.9681
			},
			{
				"open": 113.91,
				"close": 115.17,
				"volume": 113874218,
				"time": 1606194000000,
				"lowest": 112.59,
				"highest": 115.85,
				"volumePrice": 114.527,
				"transactionCount": 809378,
				"voluemPrice": 114.527
			},
			{
				"open": 115.55,
				"close": 116.03,
				"volume": 76499234,
				"time": 1606280400000,
				"lowest": 115.17,
				"highest": 116.75,
				"volumePrice": 116.0926,
				"transactionCount": 497668,
				"voluemPrice": 116.0926
			},
			{
				"open": 116.57,
				"close": 116.59,
				"volume": 46691331,
				"time": 1606453200000,
				"lowest": 116.22,
				"highest": 117.49,
				"volumePrice": 116.8509,
				"transactionCount": 328643,
				"voluemPrice": 116.8509
			},
			{
				"open": 116.97,
				"close": 119.05,
				"volume": 169391476,
				"time": 1606712400000,
				"lowest": 116.81,
				"highest": 120.97,
				"volumePrice": 119.1583,
				"transactionCount": 911469,
				"voluemPrice": 119.1583
			},
			{
				"open": 121.01,
				"close": 122.72,
				"volume": 128166803,
				"time": 1606798800000,
				"lowest": 120.01,
				"highest": 123.4693,
				"volumePrice": 122.448,
				"transactionCount": 816060,
				"voluemPrice": 122.448
			},
			{
				"open": 122.02,
				"close": 123.08,
				"volume": 89004195,
				"time": 1606885200000,
				"lowest": 120.89,
				"highest": 123.37,
				"volumePrice": 122.5716,
				"transactionCount": 527794,
				"voluemPrice": 122.5716
			},
			{
				"open": 123.52,
				"close": 122.94,
				"volume": 78944630,
				"time": 1606971600000,
				"lowest": 122.21,
				"highest": 123.78,
				"volumePrice": 122.9675,
				"transactionCount": 473228,
				"voluemPrice": 122.9675
			},
			{
				"open": 122.6,
				"close": 122.25,
				"volume": 76401421,
				"time": 1607058000000,
				"lowest": 121.52,
				"highest": 122.8608,
				"volumePrice": 122.0795,
				"transactionCount": 459162,
				"voluemPrice": 122.0795
			},
			{
				"open": 122.31,
				"close": 123.75,
				"volume": 86711490,
				"time": 1607317200000,
				"lowest": 122.25,
				"highest": 124.57,
				"volumePrice": 123.8382,
				"transactionCount": 517945,
				"voluemPrice": 123.8382
			},
			{
				"open": 124.37,
				"close": 124.38,
				"volume": 82139512,
				"time": 1607403600000,
				"lowest": 123.09,
				"highest": 124.98,
				"volumePrice": 124.1914,
				"transactionCount": 504720,
				"voluemPrice": 124.1914
			},
			{
				"open": 124.53,
				"close": 121.78,
				"volume": 115005193,
				"time": 1607490000000,
				"lowest": 121,
				"highest": 125.95,
				"volumePrice": 123.2586,
				"transactionCount": 737741,
				"voluemPrice": 123.2586
			},
			{
				"open": 120.5,
				"close": 123.24,
				"volume": 81295670,
				"time": 1607576400000,
				"lowest": 120.15,
				"highest": 123.87,
				"volumePrice": 122.8994,
				"transactionCount": 512167,
				"voluemPrice": 122.8994
			},
			{
				"open": 122.43,
				"close": 122.41,
				"volume": 86561586,
				"time": 1607662800000,
				"lowest": 120.55,
				"highest": 122.76,
				"volumePrice": 121.7229,
				"transactionCount": 541967,
				"voluemPrice": 121.7229
			},
			{
				"open": 122.6,
				"close": 121.78,
				"volume": 79055188,
				"time": 1607922000000,
				"lowest": 121.54,
				"highest": 123.35,
				"volumePrice": 122.4325,
				"transactionCount": 451068,
				"voluemPrice": 122.4325
			},
			{
				"open": 124.34,
				"close": 127.88,
				"volume": 157572262,
				"time": 1608008400000,
				"lowest": 124.13,
				"highest": 127.9,
				"volumePrice": 126.5535,
				"transactionCount": 867462,
				"voluemPrice": 126.5535
			},
			{
				"open": 127.41,
				"close": 127.81,
				"volume": 95913591,
				"time": 1608094800000,
				"lowest": 126.56,
				"highest": 128.37,
				"volumePrice": 127.5459,
				"transactionCount": 550012,
				"voluemPrice": 127.5459
			},
			{
				"open": 128.9,
				"close": 128.7,
				"volume": 94359811,
				"time": 1608181200000,
				"lowest": 128.045,
				"highest": 129.58,
				"volumePrice": 128.6643,
				"transactionCount": 491620,
				"voluemPrice": 128.6643
			},
			{
				"open": 128.96,
				"close": 126.655,
				"volume": 191108396,
				"time": 1608267600000,
				"lowest": 126.12,
				"highest": 129.1,
				"volumePrice": 127.0953,
				"transactionCount": 662921,
				"voluemPrice": 127.0953
			},
			{
				"open": 125.02,
				"close": 128.23,
				"volume": 121250643,
				"time": 1608526800000,
				"lowest": 123.449,
				"highest": 128.31,
				"volumePrice": 126.3341,
				"transactionCount": 746686,
				"voluemPrice": 126.3341
			},
			{
				"open": 131.61,
				"close": 131.88,
				"volume": 169351825,
				"time": 1608613200000,
				"lowest": 129.65,
				"highest": 134.405,
				"volumePrice": 132.2514,
				"transactionCount": 1132116,
				"voluemPrice": 132.2514
			},
			{
				"open": 132.16,
				"close": 130.96,
				"volume": 88223692,
				"time": 1608699600000,
				"lowest": 130.78,
				"highest": 132.43,
				"volumePrice": 131.5993,
				"transactionCount": 557220,
				"voluemPrice": 131.5993
			},
			{
				"open": 131.32,
				"close": 131.97,
				"volume": 54930064,
				"time": 1608786000000,
				"lowest": 131.1,
				"highest": 133.46,
				"volumePrice": 132.3339,
				"transactionCount": 365718,
				"voluemPrice": 132.3339
			},
			{
				"open": 133.99,
				"close": 136.69,
				"volume": 124486237,
				"time": 1609131600000,
				"lowest": 133.51,
				"highest": 137.34,
				"volumePrice": 135.8414,
				"transactionCount": 782397,
				"voluemPrice": 135.8414
			},
			{
				"open": 138.05,
				"close": 134.87,
				"volume": 121047324,
				"time": 1609218000000,
				"lowest": 134.3409,
				"highest": 138.789,
				"volumePrice": 136.3766,
				"transactionCount": 797011,
				"voluemPrice": 136.3766
			},
			{
				"open": 135.58,
				"close": 133.72,
				"volume": 95452124,
				"time": 1609304400000,
				"lowest": 133.4,
				"highest": 135.99,
				"volumePrice": 134.3227,
				"transactionCount": 630840,
				"voluemPrice": 134.3227
			},
			{
				"open": 134.08,
				"close": 132.69,
				"volume": 99089086,
				"time": 1609390800000,
				"lowest": 131.72,
				"highest": 134.74,
				"volumePrice": 132.8801,
				"transactionCount": 652165,
				"voluemPrice": 132.8801
			},
			{
				"open": 133.52,
				"close": 129.41,
				"volume": 143285672,
				"time": 1609736400000,
				"lowest": 126.76,
				"highest": 133.6116,
				"volumePrice": 129.7326,
				"transactionCount": 1310217,
				"voluemPrice": 129.7326
			},
			{
				"open": 128.89,
				"close": 131.01,
				"volume": 97664898,
				"time": 1609822800000,
				"lowest": 128.43,
				"highest": 131.74,
				"volumePrice": 130.7179,
				"transactionCount": 707577,
				"voluemPrice": 130.7179
			},
			{
				"open": 127.72,
				"close": 126.6,
				"volume": 155087970,
				"time": 1609909200000,
				"lowest": 126.382,
				"highest": 131.0499,
				"volumePrice": 128.3502,
				"transactionCount": 1202574,
				"voluemPrice": 128.3502
			},
			{
				"open": 128.36,
				"close": 130.92,
				"volume": 109578157,
				"time": 1609995600000,
				"lowest": 127.86,
				"highest": 131.63,
				"volumePrice": 130.1539,
				"transactionCount": 718357,
				"voluemPrice": 130.1539
			},
			{
				"open": 132.43,
				"close": 132.05,
				"volume": 105158245,
				"time": 1610082000000,
				"lowest": 130.23,
				"highest": 132.63,
				"volumePrice": 131.5657,
				"transactionCount": 800069,
				"voluemPrice": 131.5657
			},
			{
				"open": 129.19,
				"close": 128.98,
				"volume": 100617160,
				"time": 1610341200000,
				"lowest": 128.5,
				"highest": 130.17,
				"volumePrice": 129.3232,
				"transactionCount": 775027,
				"voluemPrice": 129.3232
			},
			{
				"open": 128.5,
				"close": 128.8,
				"volume": 91851145,
				"time": 1610427600000,
				"lowest": 126.86,
				"highest": 129.69,
				"volumePrice": 128.4834,
				"transactionCount": 692719,
				"voluemPrice": 128.4834
			},
			{
				"open": 128.76,
				"close": 130.89,
				"volume": 88636831,
				"time": 1610514000000,
				"lowest": 128.49,
				"highest": 131.45,
				"volumePrice": 130.5588,
				"transactionCount": 596230,
				"voluemPrice": 130.5588
			},
			{
				"open": 130.8,
				"close": 128.91,
				"volume": 89671755,
				"time": 1610600400000,
				"lowest": 128.76,
				"highest": 131,
				"volumePrice": 129.7381,
				"transactionCount": 651392,
				"voluemPrice": 129.7381
			},
			{
				"open": 128.78,
				"close": 127.14,
				"volume": 111196831,
				"time": 1610686800000,
				"lowest": 127,
				"highest": 130.2242,
				"volumePrice": 128.264,
				"transactionCount": 713312,
				"voluemPrice": 128.264
			},
			{
				"open": 127.78,
				"close": 127.83,
				"volume": 90750329,
				"time": 1611032400000,
				"lowest": 126.938,
				"highest": 128.71,
				"volumePrice": 127.6974,
				"transactionCount": 599768,
				"voluemPrice": 127.6974
			},
			{
				"open": 128.66,
				"close": 132.03,
				"volume": 104134039,
				"time": 1611118800000,
				"lowest": 128.55,
				"highest": 132.49,
				"volumePrice": 131.4664,
				"transactionCount": 668571,
				"voluemPrice": 131.4664
			},
			{
				"open": 133.8,
				"close": 136.87,
				"volume": 120256319,
				"time": 1611205200000,
				"lowest": 133.59,
				"highest": 139.67,
				"volumePrice": 135.8584,
				"transactionCount": 834998,
				"voluemPrice": 135.8584
			},
			{
				"open": 136.28,
				"close": 139.07,
				"volume": 114234010,
				"time": 1611291600000,
				"lowest": 135.02,
				"highest": 139.85,
				"volumePrice": 137.8544,
				"transactionCount": 744041,
				"voluemPrice": 137.8544
			},
			{
				"open": 143.07,
				"close": 142.92,
				"volume": 157588183,
				"time": 1611550800000,
				"lowest": 136.54,
				"highest": 145.09,
				"volumePrice": 142.6255,
				"transactionCount": 1226813,
				"voluemPrice": 142.6255
			},
			{
				"open": 143.6,
				"close": 143.16,
				"volume": 98390205,
				"time": 1611637200000,
				"lowest": 141.37,
				"highest": 144.3,
				"volumePrice": 142.8442,
				"transactionCount": 743285,
				"voluemPrice": 142.8442
			},
			{
				"open": 143.43,
				"close": 142.06,
				"volume": 140613944,
				"time": 1611723600000,
				"lowest": 140.41,
				"highest": 144.3,
				"volumePrice": 142.3522,
				"transactionCount": 1155710,
				"voluemPrice": 142.3522
			},
			{
				"open": 139.52,
				"close": 137.09,
				"volume": 142508399,
				"time": 1611810000000,
				"lowest": 136.7,
				"highest": 141.99,
				"volumePrice": 139.1841,
				"transactionCount": 1239461,
				"voluemPrice": 139.1841
			},
			{
				"open": 135.83,
				"close": 131.96,
				"volume": 177506312,
				"time": 1611896400000,
				"lowest": 130.21,
				"highest": 136.74,
				"volumePrice": 132.7372,
				"transactionCount": 1695154,
				"voluemPrice": 132.7372
			},
			{
				"open": 133.75,
				"close": 134.14,
				"volume": 106239823,
				"time": 1612155600000,
				"lowest": 130.93,
				"highest": 135.38,
				"volumePrice": 133.7923,
				"transactionCount": 924206,
				"voluemPrice": 133.7923
			},
			{
				"open": 135.73,
				"close": 134.99,
				"volume": 83259967,
				"time": 1612242000000,
				"lowest": 134.61,
				"highest": 136.31,
				"volumePrice": 135.2222,
				"transactionCount": 689676,
				"voluemPrice": 135.2222
			},
			{
				"open": 135.76,
				"close": 133.94,
				"volume": 85880937,
				"time": 1612328400000,
				"lowest": 133.61,
				"highest": 135.77,
				"volumePrice": 134.6595,
				"transactionCount": 699067,
				"voluemPrice": 134.6595
			},
			{
				"open": 136.3,
				"close": 137.39,
				"volume": 84141061,
				"time": 1612414800000,
				"lowest": 134.59,
				"highest": 137.4,
				"volumePrice": 136.1869,
				"transactionCount": 658076,
				"voluemPrice": 136.1869
			},
			{
				"open": 137.35,
				"close": 136.76,
				"volume": 75303830,
				"time": 1612501200000,
				"lowest": 135.86,
				"highest": 137.42,
				"volumePrice": 136.6507,
				"transactionCount": 616037,
				"voluemPrice": 136.6507
			},
			{
				"open": 136.03,
				"close": 136.91,
				"volume": 71297214,
				"time": 1612760400000,
				"lowest": 134.92,
				"highest": 136.96,
				"volumePrice": 136.2094,
				"transactionCount": 614469,
				"voluemPrice": 136.2094
			},
			{
				"open": 136.62,
				"close": 136.01,
				"volume": 76774213,
				"time": 1612846800000,
				"lowest": 135.85,
				"highest": 137.877,
				"volumePrice": 136.7346,
				"transactionCount": 536824,
				"voluemPrice": 136.7346
			},
			{
				"open": 136.48,
				"close": 135.39,
				"volume": 72546563,
				"time": 1612933200000,
				"lowest": 134.4,
				"highest": 136.99,
				"volumePrice": 135.4825,
				"transactionCount": 605205,
				"voluemPrice": 135.4825
			},
			{
				"open": 135.9,
				"close": 135.13,
				"volume": 64280029,
				"time": 1613019600000,
				"lowest": 133.77,
				"highest": 136.39,
				"volumePrice": 134.8643,
				"transactionCount": 547785,
				"voluemPrice": 134.8643
			},
			{
				"open": 134.35,
				"close": 135.37,
				"volume": 60136930,
				"time": 1613106000000,
				"lowest": 133.6921,
				"highest": 135.53,
				"volumePrice": 134.5208,
				"transactionCount": 481804,
				"voluemPrice": 134.5208
			},
			{
				"open": 135.49,
				"close": 133.19,
				"volume": 80576316,
				"time": 1613451600000,
				"lowest": 132.79,
				"highest": 136.01,
				"volumePrice": 133.8643,
				"transactionCount": 741080,
				"voluemPrice": 133.8643
			},
			{
				"open": 131.25,
				"close": 130.84,
				"volume": 97991849,
				"time": 1613538000000,
				"lowest": 129.47,
				"highest": 132.22,
				"volumePrice": 130.5913,
				"transactionCount": 971318,
				"voluemPrice": 130.5913
			},
			{
				"open": 129.2,
				"close": 129.71,
				"volume": 96156748,
				"time": 1613624400000,
				"lowest": 127.41,
				"highest": 129.995,
				"volumePrice": 128.8633,
				"transactionCount": 859568,
				"voluemPrice": 128.8633
			},
			{
				"open": 130.24,
				"close": 129.87,
				"volume": 87340834,
				"time": 1613710800000,
				"lowest": 128.8,
				"highest": 130.71,
				"volumePrice": 129.766,
				"transactionCount": 683754,
				"voluemPrice": 129.766
			},
			{
				"open": 128.01,
				"close": 126,
				"volume": 103295419,
				"time": 1613970000000,
				"lowest": 125.6,
				"highest": 129.72,
				"volumePrice": 127.0448,
				"transactionCount": 969980,
				"voluemPrice": 127.0448
			},
			{
				"open": 123.76,
				"close": 125.86,
				"volume": 158252662,
				"time": 1614056400000,
				"lowest": 118.39,
				"highest": 126.71,
				"volumePrice": 123.3074,
				"transactionCount": 1547442,
				"voluemPrice": 123.3074
			},
			{
				"open": 124.94,
				"close": 125.35,
				"volume": 110789904,
				"time": 1614142800000,
				"lowest": 122.23,
				"highest": 125.56,
				"volumePrice": 124.4278,
				"transactionCount": 965716,
				"voluemPrice": 124.4278
			},
			{
				"open": 124.68,
				"close": 120.99,
				"volume": 148199540,
				"time": 1614229200000,
				"lowest": 120.54,
				"highest": 126.4585,
				"volumePrice": 122.6039,
				"transactionCount": 1365697,
				"voluemPrice": 122.6039
			},
			{
				"open": 122.59,
				"close": 121.26,
				"volume": 164559569,
				"time": 1614315600000,
				"lowest": 121.2,
				"highest": 124.85,
				"volumePrice": 122.9199,
				"transactionCount": 1289339,
				"voluemPrice": 122.9199
			},
			{
				"open": 123.75,
				"close": 127.79,
				"volume": 116307892,
				"time": 1614574800000,
				"lowest": 122.79,
				"highest": 127.93,
				"volumePrice": 126.0528,
				"transactionCount": 924487,
				"voluemPrice": 126.0528
			},
			{
				"open": 128.41,
				"close": 125.12,
				"volume": 102260945,
				"time": 1614661200000,
				"lowest": 125.01,
				"highest": 128.72,
				"volumePrice": 126.1136,
				"transactionCount": 831904,
				"voluemPrice": 126.1136
			},
			{
				"open": 124.81,
				"close": 122.06,
				"volume": 112966340,
				"time": 1614747600000,
				"lowest": 121.84,
				"highest": 125.71,
				"volumePrice": 123.378,
				"transactionCount": 992159,
				"voluemPrice": 123.378
			},
			{
				"open": 121.75,
				"close": 120.13,
				"volume": 178049975,
				"time": 1614834000000,
				"lowest": 118.62,
				"highest": 123.6,
				"volumePrice": 120.805,
				"transactionCount": 1689127,
				"voluemPrice": 120.805
			},
			{
				"open": 120.98,
				"close": 121.42,
				"volume": 153592201,
				"time": 1614920400000,
				"lowest": 117.57,
				"highest": 121.935,
				"volumePrice": 119.7787,
				"transactionCount": 1472273,
				"voluemPrice": 119.7787
			},
			{
				"open": 120.93,
				"close": 116.36,
				"volume": 154376610,
				"time": 1615179600000,
				"lowest": 116.21,
				"highest": 121,
				"volumePrice": 117.9393,
				"transactionCount": 1540729,
				"voluemPrice": 117.9393
			},
			{
				"open": 119.03,
				"close": 121.085,
				"volume": 129272780,
				"time": 1615266000000,
				"lowest": 118.79,
				"highest": 122.06,
				"volumePrice": 120.6902,
				"transactionCount": 1009102,
				"voluemPrice": 120.6902
			},
			{
				"open": 121.69,
				"close": 119.98,
				"volume": 111886327,
				"time": 1615352400000,
				"lowest": 119.45,
				"highest": 122.17,
				"volumePrice": 120.3297,
				"transactionCount": 1031831,
				"voluemPrice": 120.3297
			},
			{
				"open": 122.54,
				"close": 121.96,
				"volume": 102991014,
				"time": 1615438800000,
				"lowest": 121.26,
				"highest": 123.21,
				"volumePrice": 122.0659,
				"transactionCount": 790211,
				"voluemPrice": 122.0659
			},
			{
				"open": 120.4,
				"close": 121.03,
				"volume": 87955050,
				"time": 1615525200000,
				"lowest": 119.16,
				"highest": 121.17,
				"volumePrice": 120.2455,
				"transactionCount": 762384,
				"voluemPrice": 120.2455
			},
			{
				"open": 121.41,
				"close": 123.99,
				"volume": 92568955,
				"time": 1615780800000,
				"lowest": 120.42,
				"highest": 124,
				"volumePrice": 122.9775,
				"transactionCount": 711876,
				"voluemPrice": 122.9775
			},
			{
				"open": 125.7,
				"close": 125.57,
				"volume": 115226936,
				"time": 1615867200000,
				"lowest": 124.715,
				"highest": 127.22,
				"volumePrice": 125.9513,
				"transactionCount": 835613,
				"voluemPrice": 125.9513
			},
			{
				"open": 124.05,
				"close": 124.76,
				"volume": 111632636,
				"time": 1615953600000,
				"lowest": 122.336,
				"highest": 125.8599,
				"volumePrice": 123.7795,
				"transactionCount": 896781,
				"voluemPrice": 123.7795
			},
			{
				"open": 122.88,
				"close": 120.53,
				"volume": 121367255,
				"time": 1616040000000,
				"lowest": 120.32,
				"highest": 123.18,
				"volumePrice": 121.6504,
				"transactionCount": 970641,
				"voluemPrice": 121.6504
			},
			{
				"open": 119.9,
				"close": 119.99,
				"volume": 184668022,
				"time": 1616126400000,
				"lowest": 119.675,
				"highest": 121.43,
				"volumePrice": 120.2005,
				"transactionCount": 881988,
				"voluemPrice": 120.2005
			},
			{
				"open": 120.33,
				"close": 123.39,
				"volume": 111816184,
				"time": 1616385600000,
				"lowest": 120.26,
				"highest": 123.87,
				"volumePrice": 122.8192,
				"transactionCount": 723652,
				"voluemPrice": 122.8192
			},
			{
				"open": 123.33,
				"close": 122.54,
				"volume": 95051142,
				"time": 1616472000000,
				"lowest": 122.14,
				"highest": 124.24,
				"volumePrice": 122.999,
				"transactionCount": 641822,
				"voluemPrice": 122.999
			},
			{
				"open": 122.82,
				"close": 120.09,
				"volume": 88525685,
				"time": 1616558400000,
				"lowest": 120.065,
				"highest": 122.9,
				"volumePrice": 121.2133,
				"transactionCount": 653239,
				"voluemPrice": 121.2133
			},
			{
				"open": 119.54,
				"close": 120.59,
				"volume": 98809281,
				"time": 1616644800000,
				"lowest": 119,
				"highest": 121.66,
				"volumePrice": 120.244,
				"transactionCount": 807588,
				"voluemPrice": 120.244
			},
			{
				"open": 120.35,
				"close": 121.21,
				"volume": 94071234,
				"time": 1616731200000,
				"lowest": 118.92,
				"highest": 121.48,
				"volumePrice": 120.2314,
				"transactionCount": 716066,
				"voluemPrice": 120.2314
			},
			{
				"open": 121.65,
				"close": 121.39,
				"volume": 80819203,
				"time": 1616990400000,
				"lowest": 120.7299,
				"highest": 122.58,
				"volumePrice": 121.4709,
				"transactionCount": 584885,
				"voluemPrice": 121.4709
			},
			{
				"open": 120.11,
				"close": 119.9,
				"volume": 84507919,
				"time": 1617076800000,
				"lowest": 118.86,
				"highest": 120.4031,
				"volumePrice": 119.7036,
				"transactionCount": 667918,
				"voluemPrice": 119.7036
			},
			{
				"open": 121.65,
				"close": 122.15,
				"volume": 118323826,
				"time": 1617163200000,
				"lowest": 121.15,
				"highest": 123.52,
				"volumePrice": 122.6039,
				"transactionCount": 740850,
				"voluemPrice": 122.6039
			},
			{
				"open": 123.66,
				"close": 123,
				"volume": 75089134,
				"time": 1617249600000,
				"lowest": 122.49,
				"highest": 124.18,
				"volumePrice": 123.1513,
				"transactionCount": 526273,
				"voluemPrice": 123.1513
			},
			{
				"open": 123.87,
				"close": 125.9,
				"volume": 88251175,
				"time": 1617595200000,
				"lowest": 123.07,
				"highest": 126.1601,
				"volumePrice": 125.3311,
				"transactionCount": 642630,
				"voluemPrice": 125.3311
			},
			{
				"open": 126.5,
				"close": 126.21,
				"volume": 80171253,
				"time": 1617681600000,
				"lowest": 125.65,
				"highest": 127.13,
				"volumePrice": 126.364,
				"transactionCount": 564312,
				"voluemPrice": 126.364
			},
			{
				"open": 125.83,
				"close": 127.9,
				"volume": 83256716,
				"time": 1617768000000,
				"lowest": 125.14,
				"highest": 127.92,
				"volumePrice": 127.1011,
				"transactionCount": 555603,
				"voluemPrice": 127.1011
			},
			{
				"open": 128.95,
				"close": 130.36,
				"volume": 88844591,
				"time": 1617854400000,
				"lowest": 128.52,
				"highest": 130.39,
				"volumePrice": 129.7402,
				"transactionCount": 639632,
				"voluemPrice": 129.7402
			},
			{
				"open": 129.8,
				"close": 132.995,
				"volume": 106615653,
				"time": 1617940800000,
				"lowest": 129.47,
				"highest": 133.04,
				"volumePrice": 131.6977,
				"transactionCount": 701349,
				"voluemPrice": 131.6977
			},
			{
				"open": 132.52,
				"close": 131.24,
				"volume": 91388983,
				"time": 1618200000000,
				"lowest": 130.63,
				"highest": 132.85,
				"volumePrice": 131.5117,
				"transactionCount": 612036,
				"voluemPrice": 131.5117
			},
			{
				"open": 132.44,
				"close": 134.43,
				"volume": 90761995,
				"time": 1618286400000,
				"lowest": 131.93,
				"highest": 134.66,
				"volumePrice": 133.732,
				"transactionCount": 640753,
				"voluemPrice": 133.732
			},
			{
				"open": 134.94,
				"close": 132.03,
				"volume": 86222782,
				"time": 1618372800000,
				"lowest": 131.655,
				"highest": 135,
				"volumePrice": 133.0216,
				"transactionCount": 668827,
				"voluemPrice": 133.0216
			},
			{
				"open": 133.82,
				"close": 134.5,
				"volume": 89347102,
				"time": 1618459200000,
				"lowest": 133.64,
				"highest": 135,
				"volumePrice": 134.3964,
				"transactionCount": 608980,
				"voluemPrice": 134.3964
			},
			{
				"open": 134.3,
				"close": 134.16,
				"volume": 84360386,
				"time": 1618545600000,
				"lowest": 133.28,
				"highest": 134.67,
				"volumePrice": 134.0367,
				"transactionCount": 541233,
				"voluemPrice": 134.0367
			},
			{
				"open": 133.51,
				"close": 134.84,
				"volume": 94264215,
				"time": 1618804800000,
				"lowest": 133.34,
				"highest": 135.47,
				"volumePrice": 134.5998,
				"transactionCount": 625255,
				"voluemPrice": 134.5998
			},
			{
				"open": 135.02,
				"close": 133.11,
				"volume": 94812249,
				"time": 1618891200000,
				"lowest": 131.81,
				"highest": 135.53,
				"volumePrice": 133.4438,
				"transactionCount": 744673,
				"voluemPrice": 133.4438
			},
			{
				"open": 132.36,
				"close": 133.5,
				"volume": 68826135,
				"time": 1618977600000,
				"lowest": 131.3001,
				"highest": 133.75,
				"volumePrice": 132.8647,
				"transactionCount": 508230,
				"voluemPrice": 132.8647
			},
			{
				"open": 133.04,
				"close": 131.94,
				"volume": 84566456,
				"time": 1619064000000,
				"lowest": 131.41,
				"highest": 134.15,
				"volumePrice": 132.7723,
				"transactionCount": 615670,
				"voluemPrice": 132.7723
			},
			{
				"open": 132.16,
				"close": 134.32,
				"volume": 78666779,
				"time": 1619150400000,
				"lowest": 132.16,
				"highest": 135.12,
				"volumePrice": 134.1622,
				"transactionCount": 519533,
				"voluemPrice": 134.1622
			},
			{
				"open": 134.83,
				"close": 134.72,
				"volume": 66888509,
				"time": 1619409600000,
				"lowest": 133.56,
				"highest": 135.06,
				"volumePrice": 134.5418,
				"transactionCount": 484069,
				"voluemPrice": 134.5418
			},
			{
				"open": 135.01,
				"close": 134.39,
				"volume": 66015804,
				"time": 1619496000000,
				"lowest": 134.11,
				"highest": 135.41,
				"volumePrice": 134.5907,
				"transactionCount": 480003,
				"voluemPrice": 134.5907
			},
			{
				"open": 134.31,
				"close": 133.58,
				"volume": 107746597,
				"time": 1619582400000,
				"lowest": 133.08,
				"highest": 135.02,
				"volumePrice": 134.6526,
				"transactionCount": 783355,
				"voluemPrice": 134.6526
			},
			{
				"open": 136.47,
				"close": 133.48,
				"volume": 151100953,
				"time": 1619668800000,
				"lowest": 132.45,
				"highest": 137.07,
				"volumePrice": 134.1771,
				"transactionCount": 1059387,
				"voluemPrice": 134.1771
			},
			{
				"open": 131.78,
				"close": 131.46,
				"volume": 109425466,
				"time": 1619755200000,
				"lowest": 131.065,
				"highest": 133.56,
				"volumePrice": 132.194,
				"transactionCount": 701292,
				"voluemPrice": 132.194
			},
			{
				"open": 132.04,
				"close": 132.54,
				"volume": 75135100,
				"time": 1620014400000,
				"lowest": 131.83,
				"highest": 134.07,
				"volumePrice": 132.8122,
				"transactionCount": 580631,
				"voluemPrice": 132.8122
			},
			{
				"open": 131.19,
				"close": 127.85,
				"volume": 137544918,
				"time": 1620100800000,
				"lowest": 126.7,
				"highest": 131.4899,
				"volumePrice": 127.9654,
				"transactionCount": 1182700,
				"voluemPrice": 127.9654
			},
			{
				"open": 129.2,
				"close": 128.1,
				"volume": 84000900,
				"time": 1620187200000,
				"lowest": 127.97,
				"highest": 130.45,
				"volumePrice": 129.2267,
				"transactionCount": 659279,
				"voluemPrice": 129.2267
			},
			{
				"open": 127.89,
				"close": 129.74,
				"volume": 78064334,
				"time": 1620273600000,
				"lowest": 127.13,
				"highest": 129.75,
				"volumePrice": 128.6316,
				"transactionCount": 607088,
				"voluemPrice": 128.6316
			},
			{
				"open": 130.85,
				"close": 130.21,
				"volume": 77691273,
				"time": 1620360000000,
				"lowest": 129.475,
				"highest": 131.2582,
				"volumePrice": 130.4433,
				"transactionCount": 545296,
				"voluemPrice": 130.4433
			},
			{
				"open": 129.41,
				"close": 126.85,
				"volume": 88071229,
				"time": 1620619200000,
				"lowest": 126.81,
				"highest": 129.54,
				"volumePrice": 127.8422,
				"transactionCount": 680681,
				"voluemPrice": 127.8422
			},
			{
				"open": 123.5,
				"close": 125.91,
				"volume": 126142826,
				"time": 1620705600000,
				"lowest": 122.77,
				"highest": 126.27,
				"volumePrice": 124.7406,
				"transactionCount": 922237,
				"voluemPrice": 124.7406
			},
			{
				"open": 123.4,
				"close": 122.77,
				"volume": 112172282,
				"time": 1620792000000,
				"lowest": 122.25,
				"highest": 124.64,
				"volumePrice": 123.0599,
				"transactionCount": 876763,
				"voluemPrice": 123.0599
			},
			{
				"open": 124.58,
				"close": 124.97,
				"volume": 105850339,
				"time": 1620878400000,
				"lowest": 124.26,
				"highest": 126.15,
				"volumePrice": 125.1884,
				"transactionCount": 788603,
				"voluemPrice": 125.1884
			},
			{
				"open": 126.25,
				"close": 127.45,
				"volume": 81872951,
				"time": 1620964800000,
				"lowest": 125.85,
				"highest": 127.89,
				"volumePrice": 126.9616,
				"transactionCount": 535527,
				"voluemPrice": 126.9616
			},
			{
				"open": 126.82,
				"close": 126.27,
				"volume": 74216624,
				"time": 1621224000000,
				"lowest": 125.17,
				"highest": 126.93,
				"volumePrice": 125.9467,
				"transactionCount": 502288,
				"voluemPrice": 125.9467
			},
			{
				"open": 126.56,
				"close": 124.85,
				"volume": 63342929,
				"time": 1621310400000,
				"lowest": 124.78,
				"highest": 126.99,
				"volumePrice": 125.9763,
				"transactionCount": 493538,
				"voluemPrice": 125.9763
			},
			{
				"open": 123.16,
				"close": 124.69,
				"volume": 92593489,
				"time": 1621396800000,
				"lowest": 122.86,
				"highest": 124.915,
				"volumePrice": 124.0238,
				"transactionCount": 674397,
				"voluemPrice": 124.0238
			},
			{
				"open": 125.23,
				"close": 127.31,
				"volume": 76852123,
				"time": 1621483200000,
				"lowest": 125.1,
				"highest": 127.72,
				"volumePrice": 126.8581,
				"transactionCount": 494796,
				"voluemPrice": 126.8581
			},
			{
				"open": 127.82,
				"close": 125.43,
				"volume": 79295436,
				"time": 1621569600000,
				"lowest": 125.21,
				"highest": 128,
				"volumePrice": 126.1486,
				"transactionCount": 527860,
				"voluemPrice": 126.1486
			},
			{
				"open": 126.01,
				"close": 127.1,
				"volume": 63067944,
				"time": 1621828800000,
				"lowest": 125.94,
				"highest": 127.94,
				"volumePrice": 127.2698,
				"transactionCount": 442120,
				"voluemPrice": 127.2698
			},
			{
				"open": 127.82,
				"close": 126.9,
				"volume": 72009482,
				"time": 1621915200000,
				"lowest": 126.32,
				"highest": 128.32,
				"volumePrice": 127.0739,
				"transactionCount": 462909,
				"voluemPrice": 127.0739
			},
			{
				"open": 126.955,
				"close": 126.85,
				"volume": 56443920,
				"time": 1622001600000,
				"lowest": 126.42,
				"highest": 127.39,
				"volumePrice": 126.8655,
				"transactionCount": 415320,
				"voluemPrice": 126.8655
			},
			{
				"open": 126.44,
				"close": 125.28,
				"volume": 94625601,
				"time": 1622088000000,
				"lowest": 125.08,
				"highest": 127.64,
				"volumePrice": 126.1253,
				"transactionCount": 545582,
				"voluemPrice": 126.1253
			},
			{
				"open": 125.57,
				"close": 124.61,
				"volume": 71238509,
				"time": 1622174400000,
				"lowest": 124.55,
				"highest": 125.8,
				"volumePrice": 125.0642,
				"transactionCount": 527853,
				"voluemPrice": 125.0642
			},
			{
				"open": 125.08,
				"close": 124.28,
				"volume": 65562118,
				"time": 1622520000000,
				"lowest": 123.94,
				"highest": 125.35,
				"volumePrice": 124.5894,
				"transactionCount": 501106,
				"voluemPrice": 124.5894
			},
			{
				"open": 124.28,
				"close": 125.06,
				"volume": 58674262,
				"time": 1622606400000,
				"lowest": 124.05,
				"highest": 125.24,
				"volumePrice": 124.769,
				"transactionCount": 461408,
				"voluemPrice": 124.769
			},
			{
				"open": 124.68,
				"close": 123.54,
				"volume": 75699170,
				"time": 1622692800000,
				"lowest": 123.13,
				"highest": 124.85,
				"volumePrice": 123.7708,
				"transactionCount": 562249,
				"voluemPrice": 123.7708
			},
			{
				"open": 124.07,
				"close": 125.89,
				"volume": 75169343,
				"time": 1622779200000,
				"lowest": 123.85,
				"highest": 126.16,
				"volumePrice": 125.368,
				"transactionCount": 467760,
				"voluemPrice": 125.368
			},
			{
				"open": 126.17,
				"close": 125.9,
				"volume": 71057550,
				"time": 1623038400000,
				"lowest": 124.8321,
				"highest": 126.32,
				"volumePrice": 125.3691,
				"transactionCount": 504254,
				"voluemPrice": 125.3691
			},
			{
				"open": 126.6,
				"close": 126.74,
				"volume": 74403774,
				"time": 1623124800000,
				"lowest": 126.2101,
				"highest": 128.46,
				"volumePrice": 127.0742,
				"transactionCount": 532078,
				"voluemPrice": 127.0742
			},
			{
				"open": 127.21,
				"close": 127.13,
				"volume": 56695219,
				"time": 1623211200000,
				"lowest": 126.52,
				"highest": 127.75,
				"volumePrice": 127.0733,
				"transactionCount": 382625,
				"voluemPrice": 127.0733
			},
			{
				"open": 127.02,
				"close": 126.11,
				"volume": 71186421,
				"time": 1623297600000,
				"lowest": 125.94,
				"highest": 128.19,
				"volumePrice": 126.6607,
				"transactionCount": 498371,
				"voluemPrice": 126.6607
			},
			{
				"open": 126.53,
				"close": 127.35,
				"volume": 53522373,
				"time": 1623384000000,
				"lowest": 126.1,
				"highest": 127.44,
				"volumePrice": 127.0246,
				"transactionCount": 354080,
				"voluemPrice": 127.0246
			},
			{
				"open": 127.82,
				"close": 130.48,
				"volume": 96906490,
				"time": 1623643200000,
				"lowest": 127.07,
				"highest": 130.54,
				"volumePrice": 129.6322,
				"transactionCount": 563595,
				"voluemPrice": 129.6322
			},
			{
				"open": 129.94,
				"close": 129.64,
				"volume": 62725532,
				"time": 1623729600000,
				"lowest": 129.39,
				"highest": 130.6,
				"volumePrice": 129.8358,
				"transactionCount": 416230,
				"voluemPrice": 129.8358
			},
			{
				"open": 130.37,
				"close": 130.15,
				"volume": 91793025,
				"time": 1623816000000,
				"lowest": 128.461,
				"highest": 130.89,
				"volumePrice": 130.0001,
				"transactionCount": 619028,
				"voluemPrice": 130.0001
			},
			{
				"open": 129.8,
				"close": 131.79,
				"volume": 96721669,
				"time": 1623902400000,
				"lowest": 129.65,
				"highest": 132.55,
				"volumePrice": 131.5645,
				"transactionCount": 652025,
				"voluemPrice": 131.5645
			},
			{
				"open": 130.71,
				"close": 130.46,
				"volume": 108890309,
				"time": 1623988800000,
				"lowest": 130.24,
				"highest": 131.51,
				"volumePrice": 130.7868,
				"transactionCount": 557039,
				"voluemPrice": 130.7868
			},
			{
				"open": 130.3,
				"close": 132.3,
				"volume": 79269216,
				"time": 1624248000000,
				"lowest": 129.21,
				"highest": 132.41,
				"volumePrice": 131.4999,
				"transactionCount": 506660,
				"voluemPrice": 131.4999
			},
			{
				"open": 132.13,
				"close": 133.98,
				"volume": 73713618,
				"time": 1624334400000,
				"lowest": 131.62,
				"highest": 134.08,
				"volumePrice": 133.0905,
				"transactionCount": 481082,
				"voluemPrice": 133.0905
			},
			{
				"open": 133.77,
				"close": 133.7,
				"volume": 60214200,
				"time": 1624420800000,
				"lowest": 133.23,
				"highest": 134.32,
				"volumePrice": 133.7872,
				"transactionCount": 424752,
				"voluemPrice": 133.7872
			},
			{
				"open": 134.45,
				"close": 133.41,
				"volume": 68710998,
				"time": 1624507200000,
				"lowest": 132.93,
				"highest": 134.64,
				"volumePrice": 133.7663,
				"transactionCount": 443492,
				"voluemPrice": 133.7663
			},
			{
				"open": 133.46,
				"close": 133.11,
				"volume": 70783746,
				"time": 1624593600000,
				"lowest": 132.81,
				"highest": 133.89,
				"volumePrice": 133.2362,
				"transactionCount": 358692,
				"voluemPrice": 133.2362
			},
			{
				"open": 133.41,
				"close": 134.78,
				"volume": 61941303,
				"time": 1624852800000,
				"lowest": 133.35,
				"highest": 135.245,
				"volumePrice": 134.8066,
				"transactionCount": 454433,
				"voluemPrice": 134.8066
			},
			{
				"open": 134.8,
				"close": 136.33,
				"volume": 64291681,
				"time": 1624939200000,
				"lowest": 134.35,
				"highest": 136.49,
				"volumePrice": 135.9422,
				"transactionCount": 442545,
				"voluemPrice": 135.9422
			},
			{
				"open": 136.17,
				"close": 136.96,
				"volume": 63234093,
				"time": 1625025600000,
				"lowest": 135.87,
				"highest": 137.41,
				"volumePrice": 136.8187,
				"transactionCount": 419352,
				"voluemPrice": 136.8187
			},
			{
				"open": 136.6,
				"close": 137.27,
				"volume": 52417031,
				"time": 1625112000000,
				"lowest": 135.76,
				"highest": 137.33,
				"volumePrice": 136.6708,
				"transactionCount": 388276,
				"voluemPrice": 136.6708
			},
			{
				"open": 137.9,
				"close": 139.96,
				"volume": 78945572,
				"time": 1625198400000,
				"lowest": 137.745,
				"highest": 140,
				"volumePrice": 139.1986,
				"transactionCount": 521793,
				"voluemPrice": 139.1986
			},
			{
				"open": 140.07,
				"close": 142.02,
				"volume": 108081776,
				"time": 1625544000000,
				"lowest": 140.07,
				"highest": 143.15,
				"volumePrice": 141.7417,
				"transactionCount": 733637,
				"voluemPrice": 141.7417
			},
			{
				"open": 143.535,
				"close": 144.57,
				"volume": 104899789,
				"time": 1625630400000,
				"lowest": 142.66,
				"highest": 144.89,
				"volumePrice": 144.0183,
				"transactionCount": 732815,
				"voluemPrice": 144.0183
			},
			{
				"open": 141.58,
				"close": 143.24,
				"volume": 104225458,
				"time": 1625716800000,
				"lowest": 140.665,
				"highest": 144.06,
				"volumePrice": 142.5427,
				"transactionCount": 772469,
				"voluemPrice": 142.5427
			},
			{
				"open": 142.75,
				"close": 145.11,
				"volume": 99890799,
				"time": 1625803200000,
				"lowest": 142.6522,
				"highest": 145.65,
				"volumePrice": 144.7844,
				"transactionCount": 635911,
				"voluemPrice": 144.7844
			},
			{
				"open": 146.21,
				"close": 144.5,
				"volume": 76276717,
				"time": 1626062400000,
				"lowest": 144,
				"highest": 146.32,
				"volumePrice": 144.7868,
				"transactionCount": 516633,
				"voluemPrice": 144.7868
			},
			{
				"open": 144.03,
				"close": 145.64,
				"volume": 100798598,
				"time": 1626148800000,
				"lowest": 143.63,
				"highest": 147.46,
				"volumePrice": 146.0546,
				"transactionCount": 697061,
				"voluemPrice": 146.0546
			},
			{
				"open": 148.1,
				"close": 149.15,
				"volume": 127014683,
				"time": 1626235200000,
				"lowest": 147.68,
				"highest": 149.57,
				"volumePrice": 148.8217,
				"transactionCount": 797729,
				"voluemPrice": 148.8217
			},
			{
				"open": 149.24,
				"close": 148.48,
				"volume": 106559516,
				"time": 1626321600000,
				"lowest": 147.09,
				"highest": 150,
				"volumePrice": 148.4417,
				"transactionCount": 698332,
				"voluemPrice": 148.4417
			},
			{
				"open": 148.46,
				"close": 146.39,
				"volume": 93219926,
				"time": 1626408000000,
				"lowest": 145.88,
				"highest": 149.76,
				"volumePrice": 147.6247,
				"transactionCount": 613155,
				"voluemPrice": 147.6247
			},
			{
				"open": 143.75,
				"close": 142.45,
				"volume": 121434571,
				"time": 1626667200000,
				"lowest": 141.67,
				"highest": 144.07,
				"volumePrice": 142.6131,
				"transactionCount": 939624,
				"voluemPrice": 142.6131
			},
			{
				"open": 143.46,
				"close": 146.15,
				"volume": 96324436,
				"time": 1626753600000,
				"lowest": 142.96,
				"highest": 147.0997,
				"volumePrice": 145.5723,
				"transactionCount": 614255,
				"voluemPrice": 145.5723
			},
			{
				"open": 145.53,
				"close": 145.4,
				"volume": 74031460,
				"time": 1626840000000,
				"lowest": 144.63,
				"highest": 146.13,
				"volumePrice": 145.3658,
				"transactionCount": 473574,
				"voluemPrice": 145.3658
			},
			{
				"open": 145.935,
				"close": 146.8,
				"volume": 77287356,
				"time": 1626926400000,
				"lowest": 145.81,
				"highest": 148.195,
				"volumePrice": 146.991,
				"transactionCount": 480209,
				"voluemPrice": 146.991
			},
			{
				"open": 147.55,
				"close": 148.56,
				"volume": 71447416,
				"time": 1627012800000,
				"lowest": 146.92,
				"highest": 148.7177,
				"volumePrice": 148.0368,
				"transactionCount": 457247,
				"voluemPrice": 148.0368
			},
			{
				"open": 148.27,
				"close": 148.99,
				"volume": 72434089,
				"time": 1627272000000,
				"lowest": 147.7,
				"highest": 149.83,
				"volumePrice": 149.1182,
				"transactionCount": 489114,
				"voluemPrice": 149.1182
			},
			{
				"open": 149.12,
				"close": 146.77,
				"volume": 104803028,
				"time": 1627358400000,
				"lowest": 145.55,
				"highest": 149.21,
				"volumePrice": 146.8324,
				"transactionCount": 785621,
				"voluemPrice": 146.8324
			},
			{
				"open": 144.81,
				"close": 144.98,
				"volume": 118931191,
				"time": 1627444800000,
				"lowest": 142.54,
				"highest": 146.97,
				"volumePrice": 144.8875,
				"transactionCount": 829463,
				"voluemPrice": 144.8875
			},
			{
				"open": 144.685,
				"close": 145.64,
				"volume": 56699475,
				"time": 1627531200000,
				"lowest": 144.58,
				"highest": 146.55,
				"volumePrice": 145.8064,
				"transactionCount": 416123,
				"voluemPrice": 145.8064
			},
			{
				"open": 144.38,
				"close": 145.86,
				"volume": 70440626,
				"time": 1627617600000,
				"lowest": 144.11,
				"highest": 146.33,
				"volumePrice": 145.3967,
				"transactionCount": 466325,
				"voluemPrice": 145.3967
			},
			{
				"open": 146.36,
				"close": 145.52,
				"volume": 61351461,
				"time": 1627876800000,
				"lowest": 145.25,
				"highest": 146.95,
				"volumePrice": 145.8599,
				"transactionCount": 458358,
				"voluemPrice": 145.8599
			},
			{
				"open": 145.81,
				"close": 147.36,
				"volume": 63536618,
				"time": 1627963200000,
				"lowest": 145.18,
				"highest": 148.045,
				"volumePrice": 146.7499,
				"transactionCount": 462817,
				"voluemPrice": 146.7499
			},
			{
				"open": 147.27,
				"close": 146.95,
				"volume": 56357771,
				"time": 1628049600000,
				"lowest": 146.28,
				"highest": 147.79,
				"volumePrice": 147.0473,
				"transactionCount": 402501,
				"voluemPrice": 147.0473
			},
			{
				"open": 146.98,
				"close": 147.06,
				"volume": 46216322,
				"time": 1628136000000,
				"lowest": 146.17,
				"highest": 147.84,
				"volumePrice": 147.1115,
				"transactionCount": 356212,
				"voluemPrice": 147.1115
			},
			{
				"open": 146.35,
				"close": 146.14,
				"volume": 53854313,
				"time": 1628222400000,
				"lowest": 145.63,
				"highest": 147.11,
				"volumePrice": 146.2139,
				"transactionCount": 374119,
				"voluemPrice": 146.2139
			},
			{
				"open": 146.2,
				"close": 146.09,
				"volume": 48858689,
				"time": 1628481600000,
				"lowest": 145.52,
				"highest": 146.7,
				"volumePrice": 146.1113,
				"transactionCount": 352471,
				"voluemPrice": 146.1113
			},
			{
				"open": 146.44,
				"close": 145.6,
				"volume": 68286400,
				"time": 1628568000000,
				"lowest": 145.3,
				"highest": 147.71,
				"volumePrice": 146.1315,
				"transactionCount": 431274,
				"voluemPrice": 146.1315
			},
			{
				"open": 146.05,
				"close": 145.86,
				"volume": 48493463,
				"time": 1628654400000,
				"lowest": 145.53,
				"highest": 146.72,
				"volumePrice": 146.0228,
				"transactionCount": 365446,
				"voluemPrice": 146.0228
			},
			{
				"open": 146.19,
				"close": 148.89,
				"volume": 73771613,
				"time": 1628740800000,
				"lowest": 145.84,
				"highest": 149.05,
				"volumePrice": 148.0103,
				"transactionCount": 514669,
				"voluemPrice": 148.0103
			},
			{
				"open": 148.97,
				"close": 149.1,
				"volume": 59375009,
				"time": 1628827200000,
				"lowest": 148.27,
				"highest": 149.4444,
				"volumePrice": 148.9194,
				"transactionCount": 407900,
				"voluemPrice": 148.9194
			},
			{
				"open": 148.535,
				"close": 151.12,
				"volume": 103558782,
				"time": 1629086400000,
				"lowest": 146.47,
				"highest": 151.19,
				"volumePrice": 149.4001,
				"transactionCount": 704850,
				"voluemPrice": 149.4001
			},
			{
				"open": 150.23,
				"close": 150.19,
				"volume": 92202535,
				"time": 1629172800000,
				"lowest": 149.09,
				"highest": 151.68,
				"volumePrice": 150.2473,
				"transactionCount": 624238,
				"voluemPrice": 150.2473
			},
			{
				"open": 149.8,
				"close": 146.36,
				"volume": 86285490,
				"time": 1629259200000,
				"lowest": 146.15,
				"highest": 150.72,
				"volumePrice": 148.2985,
				"transactionCount": 647599,
				"voluemPrice": 148.2985
			},
			{
				"open": 145.03,
				"close": 146.7,
				"volume": 86753154,
				"time": 1629345600000,
				"lowest": 144.5,
				"highest": 148,
				"volumePrice": 146.4409,
				"transactionCount": 639427,
				"voluemPrice": 146.4409
			},
			{
				"open": 147.44,
				"close": 148.19,
				"volume": 60249630,
				"time": 1629432000000,
				"lowest": 146.78,
				"highest": 148.5,
				"volumePrice": 147.8157,
				"transactionCount": 413517,
				"voluemPrice": 147.8157
			},
			{
				"open": 148.31,
				"close": 149.71,
				"volume": 60105710,
				"time": 1629691200000,
				"lowest": 147.89,
				"highest": 150.19,
				"volumePrice": 149.4893,
				"transactionCount": 428947,
				"voluemPrice": 149.4893
			},
			{
				"open": 149.45,
				"close": 149.62,
				"volume": 48605228,
				"time": 1629777600000,
				"lowest": 149.15,
				"highest": 150.86,
				"volumePrice": 149.9034,
				"transactionCount": 370055,
				"voluemPrice": 149.9034
			},
			{
				"open": 149.81,
				"close": 148.36,
				"volume": 58691297,
				"time": 1629864000000,
				"lowest": 147.8,
				"highest": 150.32,
				"volumePrice": 148.5982,
				"transactionCount": 460865,
				"voluemPrice": 148.5982
			},
			{
				"open": 148.35,
				"close": 147.54,
				"volume": 48597195,
				"time": 1629950400000,
				"lowest": 147.51,
				"highest": 149.12,
				"volumePrice": 148.1147,
				"transactionCount": 382720,
				"voluemPrice": 148.1147
			},
			{
				"open": 147.48,
				"close": 148.6,
				"volume": 54858888,
				"time": 1630036800000,
				"lowest": 146.83,
				"highest": 148.75,
				"volumePrice": 148.1201,
				"transactionCount": 405190,
				"voluemPrice": 148.1201
			},
			{
				"open": 149,
				"close": 153.12,
				"volume": 90756723,
				"time": 1630296000000,
				"lowest": 148.61,
				"highest": 153.49,
				"volumePrice": 152.1466,
				"transactionCount": 659666,
				"voluemPrice": 152.1466
			},
			{
				"open": 152.66,
				"close": 151.83,
				"volume": 85962417,
				"time": 1630382400000,
				"lowest": 151.29,
				"highest": 152.8,
				"volumePrice": 152.0192,
				"transactionCount": 534512,
				"voluemPrice": 152.0192
			},
			{
				"open": 152.83,
				"close": 152.51,
				"volume": 80207311,
				"time": 1630468800000,
				"lowest": 152.34,
				"highest": 154.98,
				"volumePrice": 153.7673,
				"transactionCount": 593508,
				"voluemPrice": 153.7673
			},
			{
				"open": 153.87,
				"close": 153.65,
				"volume": 71171317,
				"time": 1630555200000,
				"lowest": 152.4,
				"highest": 154.72,
				"volumePrice": 153.6101,
				"transactionCount": 519974,
				"voluemPrice": 153.6101
			},
			{
				"open": 153.76,
				"close": 154.3,
				"volume": 57866066,
				"time": 1630641600000,
				"lowest": 153.09,
				"highest": 154.63,
				"volumePrice": 154.0218,
				"transactionCount": 431686,
				"voluemPrice": 154.0218
			},
			{
				"open": 154.97,
				"close": 156.69,
				"volume": 83620937,
				"time": 1630987200000,
				"lowest": 154.39,
				"highest": 157.26,
				"volumePrice": 156.1841,
				"transactionCount": 628035,
				"voluemPrice": 156.1841
			},
			{
				"open": 156.98,
				"close": 155.11,
				"volume": 74396207,
				"time": 1631073600000,
				"lowest": 153.975,
				"highest": 157.04,
				"volumePrice": 155.1093,
				"transactionCount": 598135,
				"voluemPrice": 155.1093
			},
			{
				"open": 155.49,
				"close": 154.07,
				"volume": 57305730,
				"time": 1631160000000,
				"lowest": 153.95,
				"highest": 156.11,
				"volumePrice": 154.8505,
				"transactionCount": 469366,
				"voluemPrice": 154.8505
			},
			{
				"open": 155,
				"close": 148.97,
				"volume": 140829235,
				"time": 1631246400000,
				"lowest": 148.7,
				"highest": 155.48,
				"volumePrice": 150.9988,
				"transactionCount": 1168239,
				"voluemPrice": 150.9988
			},
			{
				"open": 150.63,
				"close": 149.55,
				"volume": 104246371,
				"time": 1631505600000,
				"lowest": 148.75,
				"highest": 151.42,
				"volumePrice": 149.6982,
				"transactionCount": 760230,
				"voluemPrice": 149.6982
			},
			{
				"open": 150.35,
				"close": 148.12,
				"volume": 112148523,
				"time": 1631592000000,
				"lowest": 146.91,
				"highest": 151.07,
				"volumePrice": 148.8369,
				"transactionCount": 808779,
				"voluemPrice": 148.8369
			},
			{
				"open": 148.56,
				"close": 149.03,
				"volume": 84825078,
				"time": 1631678400000,
				"lowest": 146.37,
				"highest": 149.44,
				"volumePrice": 148.0322,
				"transactionCount": 635894,
				"voluemPrice": 148.0322
			},
			{
				"open": 148.44,
				"close": 148.79,
				"volume": 67981149,
				"time": 1631764800000,
				"lowest": 147.221,
				"highest": 148.97,
				"volumePrice": 148.151,
				"transactionCount": 501847,
				"voluemPrice": 148.151
			},
			{
				"open": 148.82,
				"close": 146.06,
				"volume": 129840322,
				"time": 1631851200000,
				"lowest": 145.76,
				"highest": 148.82,
				"volumePrice": 146.6992,
				"transactionCount": 713537,
				"voluemPrice": 146.6992
			},
			{
				"open": 143.8,
				"close": 142.94,
				"volume": 123462362,
				"time": 1632110400000,
				"lowest": 141.27,
				"highest": 144.84,
				"volumePrice": 142.7526,
				"transactionCount": 1022141,
				"voluemPrice": 142.7526
			},
			{
				"open": 143.93,
				"close": 143.43,
				"volume": 75833962,
				"time": 1632196800000,
				"lowest": 142.78,
				"highest": 144.6,
				"volumePrice": 143.74,
				"transactionCount": 578041,
				"voluemPrice": 143.74
			},
			{
				"open": 144.45,
				"close": 145.85,
				"volume": 76404341,
				"time": 1632283200000,
				"lowest": 143.7001,
				"highest": 146.43,
				"volumePrice": 145.4092,
				"transactionCount": 548480,
				"voluemPrice": 145.4092
			},
			{
				"open": 146.65,
				"close": 146.83,
				"volume": 64727170,
				"time": 1632369600000,
				"lowest": 145.64,
				"highest": 147.08,
				"volumePrice": 146.6924,
				"transactionCount": 472238,
				"voluemPrice": 146.6924
			},
			{
				"open": 145.66,
				"close": 146.92,
				"volume": 53475569,
				"time": 1632456000000,
				"lowest": 145.56,
				"highest": 147.4701,
				"volumePrice": 146.4928,
				"transactionCount": 400154,
				"voluemPrice": 146.4928
			},
			{
				"open": 145.47,
				"close": 145.37,
				"volume": 74150726,
				"time": 1632715200000,
				"lowest": 143.82,
				"highest": 145.96,
				"volumePrice": 145.1074,
				"transactionCount": 529650,
				"voluemPrice": 145.1074
			},
			{
				"open": 143.25,
				"close": 141.91,
				"volume": 108955840,
				"time": 1632801600000,
				"lowest": 141.69,
				"highest": 144.75,
				"volumePrice": 142.6723,
				"transactionCount": 823805,
				"voluemPrice": 142.6723
			},
			{
				"open": 142.47,
				"close": 142.83,
				"volume": 73592044,
				"time": 1632888000000,
				"lowest": 142.03,
				"highest": 144.45,
				"volumePrice": 143.3984,
				"transactionCount": 528983,
				"voluemPrice": 143.3984
			},
			{
				"open": 143.66,
				"close": 141.5,
				"volume": 90816408,
				"time": 1632974400000,
				"lowest": 141.28,
				"highest": 144.378,
				"volumePrice": 142.4016,
				"transactionCount": 660868,
				"voluemPrice": 142.4016
			},
			{
				"open": 141.9,
				"close": 142.65,
				"volume": 94606681,
				"time": 1633060800000,
				"lowest": 139.1101,
				"highest": 142.92,
				"volumePrice": 141.1056,
				"transactionCount": 730084,
				"voluemPrice": 141.1056
			},
			{
				"open": 141.76,
				"close": 139.14,
				"volume": 98322008,
				"time": 1633320000000,
				"lowest": 138.27,
				"highest": 142.21,
				"volumePrice": 139.2908,
				"transactionCount": 828320,
				"voluemPrice": 139.2908
			},
			{
				"open": 139.49,
				"close": 141.11,
				"volume": 80861062,
				"time": 1633406400000,
				"lowest": 139.36,
				"highest": 142.24,
				"volumePrice": 140.9927,
				"transactionCount": 562855,
				"voluemPrice": 140.9927
			},
			{
				"open": 139.47,
				"close": 142,
				"volume": 82821118,
				"time": 1633492800000,
				"lowest": 138.37,
				"highest": 142.15,
				"volumePrice": 140.3828,
				"transactionCount": 610961,
				"voluemPrice": 140.3828
			},
			{
				"open": 143.06,
				"close": 143.29,
				"volume": 61732655,
				"time": 1633579200000,
				"lowest": 142.72,
				"highest": 144.215,
				"volumePrice": 143.6657,
				"transactionCount": 450794,
				"voluemPrice": 143.6657
			},
			{
				"open": 144.03,
				"close": 142.9,
				"volume": 59892052,
				"time": 1633665600000,
				"lowest": 142.56,
				"highest": 144.1781,
				"volumePrice": 143.1747,
				"transactionCount": 423540,
				"voluemPrice": 143.1747
			},
			{
				"open": 142.27,
				"close": 142.81,
				"volume": 63952219,
				"time": 1633924800000,
				"lowest": 141.81,
				"highest": 144.81,
				"volumePrice": 143.6675,
				"transactionCount": 399220,
				"voluemPrice": 143.6675
			},
			{
				"open": 143.23,
				"close": 141.51,
				"volume": 73035859,
				"time": 1634011200000,
				"lowest": 141.0401,
				"highest": 143.25,
				"volumePrice": 141.8587,
				"transactionCount": 526042,
				"voluemPrice": 141.8587
			},
			{
				"open": 141.235,
				"close": 140.91,
				"volume": 78762121,
				"time": 1634097600000,
				"lowest": 139.2,
				"highest": 141.4,
				"volumePrice": 140.3071,
				"transactionCount": 600890,
				"voluemPrice": 140.3071
			},
			{
				"open": 142.11,
				"close": 143.76,
				"volume": 69891600,
				"time": 1634184000000,
				"lowest": 141.51,
				"highest": 143.88,
				"volumePrice": 143.2173,
				"transactionCount": 447186,
				"voluemPrice": 143.2173
			},
			{
				"open": 143.77,
				"close": 144.84,
				"volume": 67642684,
				"time": 1634270400000,
				"lowest": 143.51,
				"highest": 144.895,
				"volumePrice": 144.3214,
				"transactionCount": 429707,
				"voluemPrice": 144.3214
			},
			{
				"open": 143.445,
				"close": 146.55,
				"volume": 85389174,
				"time": 1634529600000,
				"lowest": 143.16,
				"highest": 146.84,
				"volumePrice": 145.4235,
				"transactionCount": 544217,
				"voluemPrice": 145.4235
			},
			{
				"open": 147.01,
				"close": 148.76,
				"volume": 76378894,
				"time": 1634616000000,
				"lowest": 146.55,
				"highest": 149.17,
				"volumePrice": 148.329,
				"transactionCount": 532883,
				"voluemPrice": 148.329
			},
			{
				"open": 148.7,
				"close": 149.26,
				"volume": 58418788,
				"time": 1634702400000,
				"lowest": 148.12,
				"highest": 149.7539,
				"volumePrice": 149.0905,
				"transactionCount": 437977,
				"voluemPrice": 149.0905
			},
			{
				"open": 148.81,
				"close": 149.48,
				"volume": 61345990,
				"time": 1634788800000,
				"lowest": 147.87,
				"highest": 149.64,
				"volumePrice": 149.0317,
				"transactionCount": 418119,
				"voluemPrice": 149.0317
			},
			{
				"open": 149.69,
				"close": 148.69,
				"volume": 58855143,
				"time": 1634875200000,
				"lowest": 148.64,
				"highest": 150.18,
				"volumePrice": 149.2955,
				"transactionCount": 437719,
				"voluemPrice": 149.2955
			},
			{
				"open": 148.68,
				"close": 148.64,
				"volume": 50220555,
				"time": 1635134400000,
				"lowest": 147.6211,
				"highest": 149.37,
				"volumePrice": 148.5067,
				"transactionCount": 425021,
				"voluemPrice": 148.5067
			},
			{
				"open": 149.33,
				"close": 149.32,
				"volume": 60893393,
				"time": 1635220800000,
				"lowest": 149.0101,
				"highest": 150.84,
				"volumePrice": 149.5911,
				"transactionCount": 459692,
				"voluemPrice": 149.5911
			},
			{
				"open": 149.36,
				"close": 148.85,
				"volume": 56094929,
				"time": 1635307200000,
				"lowest": 148.49,
				"highest": 149.73,
				"volumePrice": 149.1193,
				"transactionCount": 407592,
				"voluemPrice": 149.1193
			},
			{
				"open": 149.82,
				"close": 152.57,
				"volume": 100064388,
				"time": 1635393600000,
				"lowest": 149.72,
				"highest": 153.165,
				"volumePrice": 151.5945,
				"transactionCount": 749706,
				"voluemPrice": 151.5945
			},
			{
				"open": 147.215,
				"close": 149.8,
				"volume": 124953168,
				"time": 1635480000000,
				"lowest": 146.4128,
				"highest": 149.94,
				"volumePrice": 148.3331,
				"transactionCount": 863685,
				"voluemPrice": 148.3331
			},
			{
				"open": 148.985,
				"close": 148.96,
				"volume": 74588257,
				"time": 1635739200000,
				"lowest": 147.8,
				"highest": 149.7,
				"volumePrice": 148.8099,
				"transactionCount": 529659,
				"voluemPrice": 148.8099
			},
			{
				"open": 148.66,
				"close": 150.02,
				"volume": 69121986,
				"time": 1635825600000,
				"lowest": 148.65,
				"highest": 151.57,
				"volumePrice": 150.3583,
				"transactionCount": 517391,
				"voluemPrice": 150.3583
			},
			{
				"open": 150.39,
				"close": 151.49,
				"volume": 54511532,
				"time": 1635912000000,
				"lowest": 149.82,
				"highest": 151.97,
				"volumePrice": 150.9171,
				"transactionCount": 399115,
				"voluemPrice": 150.9171
			},
			{
				"open": 151.58,
				"close": 150.96,
				"volume": 60394613,
				"time": 1635998400000,
				"lowest": 150.64,
				"highest": 152.43,
				"volumePrice": 151.3974,
				"transactionCount": 457989,
				"voluemPrice": 151.3974
			},
			{
				"open": 151.89,
				"close": 151.28,
				"volume": 65163883,
				"time": 1636084800000,
				"lowest": 150.06,
				"highest": 152.2,
				"volumePrice": 151.0887,
				"transactionCount": 424255,
				"voluemPrice": 151.0887
			},
			{
				"open": 151.41,
				"close": 150.44,
				"volume": 55020868,
				"time": 1636347600000,
				"lowest": 150.16,
				"highest": 151.57,
				"volumePrice": 150.8187,
				"transactionCount": 416754,
				"voluemPrice": 150.8187
			},
			{
				"open": 150.2,
				"close": 150.81,
				"volume": 56787930,
				"time": 1636434000000,
				"lowest": 150.0601,
				"highest": 151.428,
				"volumePrice": 150.792,
				"transactionCount": 455006,
				"voluemPrice": 150.792
			},
			{
				"open": 150.02,
				"close": 147.92,
				"volume": 65160017,
				"time": 1636520400000,
				"lowest": 147.85,
				"highest": 150.13,
				"volumePrice": 148.6034,
				"transactionCount": 544210,
				"voluemPrice": 148.6034
			},
			{
				"open": 148.96,
				"close": 147.87,
				"volume": 40699950,
				"time": 1636606800000,
				"lowest": 147.681,
				"highest": 149.43,
				"volumePrice": 148.169,
				"transactionCount": 338584,
				"voluemPrice": 148.169
			},
			{
				"open": 148.43,
				"close": 149.99,
				"volume": 63804006,
				"time": 1636693200000,
				"lowest": 147.48,
				"highest": 150.4,
				"volumePrice": 149.3536,
				"transactionCount": 404421,
				"voluemPrice": 149.3536
			},
			{
				"open": 150.37,
				"close": 150,
				"volume": 59182803,
				"time": 1636952400000,
				"lowest": 149.43,
				"highest": 151.88,
				"volumePrice": 150.2881,
				"transactionCount": 422055,
				"voluemPrice": 150.2881
			},
			{
				"open": 149.94,
				"close": 151,
				"volume": 59256210,
				"time": 1637038800000,
				"lowest": 149.34,
				"highest": 151.488,
				"volumePrice": 150.6976,
				"transactionCount": 388990,
				"voluemPrice": 150.6976
			},
			{
				"open": 150.995,
				"close": 153.49,
				"volume": 88807000,
				"time": 1637125200000,
				"lowest": 150.99,
				"highest": 155,
				"volumePrice": 153.5371,
				"transactionCount": 625976,
				"voluemPrice": 153.5371
			},
			{
				"open": 153.71,
				"close": 157.87,
				"volume": 137777673,
				"time": 1637211600000,
				"lowest": 153.05,
				"highest": 158.67,
				"volumePrice": 156.3264,
				"transactionCount": 865061,
				"voluemPrice": 156.3264
			},
			{
				"open": 157.65,
				"close": 160.55,
				"volume": 117264897,
				"time": 1637298000000,
				"lowest": 156.5328,
				"highest": 161.02,
				"volumePrice": 159.5736,
				"transactionCount": 816964,
				"voluemPrice": 159.5736
			},
			{
				"open": 161.68,
				"close": 161.02,
				"volume": 117427889,
				"time": 1637557200000,
				"lowest": 161,
				"highest": 165.7,
				"volumePrice": 163.5891,
				"transactionCount": 890334,
				"voluemPrice": 163.5891
			},
			{
				"open": 161.12,
				"close": 161.41,
				"volume": 96022899,
				"time": 1637643600000,
				"lowest": 159.0601,
				"highest": 161.8,
				"volumePrice": 160.3405,
				"transactionCount": 712377,
				"voluemPrice": 160.3405
			},
			{
				"open": 160.75,
				"close": 161.94,
				"volume": 69263623,
				"time": 1637730000000,
				"lowest": 159.64,
				"highest": 162.14,
				"volumePrice": 161.1093,
				"transactionCount": 505241,
				"voluemPrice": 161.1093
			},
			{
				"open": 159.565,
				"close": 156.81,
				"volume": 76940752,
				"time": 1637902800000,
				"lowest": 156.36,
				"highest": 160.45,
				"volumePrice": 157.8439,
				"transactionCount": 666767,
				"voluemPrice": 157.8439
			},
			{
				"open": 159.37,
				"close": 160.24,
				"volume": 88498217,
				"time": 1638162000000,
				"lowest": 158.7901,
				"highest": 161.19,
				"volumePrice": 160.1333,
				"transactionCount": 663948,
				"voluemPrice": 160.1333
			},
			{
				"open": 159.985,
				"close": 165.3,
				"volume": 173958056,
				"time": 1638248400000,
				"lowest": 159.92,
				"highest": 165.52,
				"volumePrice": 163.775,
				"transactionCount": 1139625,
				"voluemPrice": 163.775
			},
			{
				"open": 167.48,
				"close": 164.77,
				"volume": 152303002,
				"time": 1638334800000,
				"lowest": 164.53,
				"highest": 170.3,
				"volumePrice": 167.6855,
				"transactionCount": 1191148,
				"voluemPrice": 167.6855
			},
			{
				"open": 158.735,
				"close": 163.76,
				"volume": 136739174,
				"time": 1638421200000,
				"lowest": 157.8,
				"highest": 164.2,
				"volumePrice": 161.8301,
				"transactionCount": 1163153,
				"voluemPrice": 161.8301
			},
			{
				"open": 164.02,
				"close": 161.84,
				"volume": 117949416,
				"time": 1638507600000,
				"lowest": 159.72,
				"highest": 164.96,
				"volumePrice": 161.8243,
				"transactionCount": 950980,
				"voluemPrice": 161.8243
			},
			{
				"open": 164.29,
				"close": 165.32,
				"volume": 107496982,
				"time": 1638766800000,
				"lowest": 164.28,
				"highest": 167.8799,
				"volumePrice": 165.8234,
				"transactionCount": 840677,
				"voluemPrice": 165.8234
			},
			{
				"open": 169.08,
				"close": 171.18,
				"volume": 120356951,
				"time": 1638853200000,
				"lowest": 168.34,
				"highest": 171.58,
				"volumePrice": 170.5609,
				"transactionCount": 834819,
				"voluemPrice": 170.5609
			},
			{
				"open": 172.125,
				"close": 175.08,
				"volume": 116982099,
				"time": 1638939600000,
				"lowest": 170.7,
				"highest": 175.96,
				"volumePrice": 174.0176,
				"transactionCount": 871401,
				"voluemPrice": 174.0176
			},
			{
				"open": 174.91,
				"close": 174.56,
				"volume": 108923738,
				"time": 1639026000000,
				"lowest": 173.92,
				"highest": 176.75,
				"volumePrice": 175.277,
				"transactionCount": 786129,
				"voluemPrice": 175.277
			},
			{
				"open": 175.205,
				"close": 179.45,
				"volume": 115402731,
				"time": 1639112400000,
				"lowest": 174.69,
				"highest": 179.63,
				"volumePrice": 177.2721,
				"transactionCount": 855797,
				"voluemPrice": 177.2721
			},
			{
				"open": 181.115,
				"close": 175.74,
				"volume": 153237019,
				"time": 1639371600000,
				"lowest": 175.53,
				"highest": 182.13,
				"volumePrice": 178.5988,
				"transactionCount": 1148448,
				"voluemPrice": 178.5988
			},
			{
				"open": 175.25,
				"close": 174.33,
				"volume": 139356181,
				"time": 1639458000000,
				"lowest": 172.21,
				"highest": 177.74,
				"volumePrice": 174.4001,
				"transactionCount": 985603,
				"voluemPrice": 174.4001
			},
			{
				"open": 175.11,
				"close": 179.3,
				"volume": 131063257,
				"time": 1639544400000,
				"lowest": 172.3108,
				"highest": 179.5,
				"volumePrice": 176.0771,
				"transactionCount": 937053,
				"voluemPrice": 176.0771
			},
			{
				"open": 179.28,
				"close": 172.26,
				"volume": 150185842,
				"time": 1639630800000,
				"lowest": 170.75,
				"highest": 181.14,
				"volumePrice": 174.4138,
				"transactionCount": 1202731,
				"voluemPrice": 174.4138
			},
			{
				"open": 169.93,
				"close": 171.14,
				"volume": 194579041,
				"time": 1639717200000,
				"lowest": 169.69,
				"highest": 173.47,
				"volumePrice": 171.2628,
				"transactionCount": 994729,
				"voluemPrice": 171.2628
			},
			{
				"open": 168.28,
				"close": 169.75,
				"volume": 107499114,
				"time": 1639976400000,
				"lowest": 167.46,
				"highest": 170.58,
				"volumePrice": 169.2626,
				"transactionCount": 816118,
				"voluemPrice": 169.2626
			},
			{
				"open": 171.555,
				"close": 172.99,
				"volume": 91167005,
				"time": 1640062800000,
				"lowest": 169.12,
				"highest": 173.2,
				"volumePrice": 171.4815,
				"transactionCount": 651088,
				"voluemPrice": 171.4815
			},
			{
				"open": 173.04,
				"close": 175.64,
				"volume": 92135303,
				"time": 1640149200000,
				"lowest": 172.15,
				"highest": 175.86,
				"volumePrice": 174.3316,
				"transactionCount": 643554,
				"voluemPrice": 174.3316
			},
			{
				"open": 175.85,
				"close": 176.28,
				"volume": 68356567,
				"time": 1640235600000,
				"lowest": 175.27,
				"highest": 176.8499,
				"volumePrice": 176.1477,
				"transactionCount": 502547,
				"voluemPrice": 176.1477
			},
			{
				"open": 177.085,
				"close": 180.33,
				"volume": 74901582,
				"time": 1640581200000,
				"lowest": 177.07,
				"highest": 180.42,
				"volumePrice": 179.0573,
				"transactionCount": 630107,
				"voluemPrice": 179.0573
			},
			{
				"open": 180.16,
				"close": 179.29,
				"volume": 78894335,
				"time": 1640667600000,
				"lowest": 178.53,
				"highest": 181.33,
				"volumePrice": 179.7048,
				"transactionCount": 631766,
				"voluemPrice": 179.7048
			},
			{
				"open": 179.33,
				"close": 179.38,
				"volume": 62348931,
				"time": 1640754000000,
				"lowest": 178.14,
				"highest": 180.63,
				"volumePrice": 179.4557,
				"transactionCount": 491790,
				"voluemPrice": 179.4557
			},
			{
				"open": 179.47,
				"close": 178.2,
				"volume": 59773014,
				"time": 1640840400000,
				"lowest": 178.09,
				"highest": 180.57,
				"volumePrice": 179.3744,
				"transactionCount": 498664,
				"voluemPrice": 179.3744
			},
			{
				"open": 178.085,
				"close": 177.57,
				"volume": 64062261,
				"time": 1640926800000,
				"lowest": 177.26,
				"highest": 179.23,
				"volumePrice": 177.8004,
				"transactionCount": 451687,
				"voluemPrice": 177.8004
			},
			{
				"open": 177.83,
				"close": 182.01,
				"volume": 104677470,
				"time": 1641186000000,
				"lowest": 177.71,
				"highest": 182.88,
				"volumePrice": 181.4156,
				"transactionCount": 772691,
				"voluemPrice": 181.4156
			},
			{
				"open": 182.63,
				"close": 179.7,
				"volume": 99110438,
				"time": 1641272400000,
				"lowest": 179.12,
				"highest": 182.94,
				"volumePrice": 180.5574,
				"transactionCount": 831890,
				"voluemPrice": 180.5574
			},
			{
				"open": 179.61,
				"close": 174.92,
				"volume": 94535602,
				"time": 1641358800000,
				"lowest": 174.64,
				"highest": 180.17,
				"volumePrice": 177.2884,
				"transactionCount": 848513,
				"voluemPrice": 177.2884
			},
			{
				"open": 172.7,
				"close": 172,
				"volume": 96882954,
				"time": 1641445200000,
				"lowest": 171.64,
				"highest": 175.3,
				"volumePrice": 173.08,
				"transactionCount": 960340,
				"voluemPrice": 173.08
			},
			{
				"open": 172.89,
				"close": 172.17,
				"volume": 86709147,
				"time": 1641531600000,
				"lowest": 171.03,
				"highest": 174.14,
				"volumePrice": 172.4383,
				"transactionCount": 716881,
				"voluemPrice": 172.4383
			},
			{
				"open": 169.08,
				"close": 172.19,
				"volume": 106754551,
				"time": 1641790800000,
				"lowest": 168.17,
				"highest": 172.5,
				"volumePrice": 170.2886,
				"transactionCount": 956337,
				"voluemPrice": 170.2886
			},
			{
				"open": 172.32,
				"close": 175.08,
				"volume": 76138312,
				"time": 1641877200000,
				"lowest": 170.82,
				"highest": 175.18,
				"volumePrice": 173.6337,
				"transactionCount": 649652,
				"voluemPrice": 173.6337
			},
			{
				"open": 176.12,
				"close": 175.53,
				"volume": 74805173,
				"time": 1641963600000,
				"lowest": 174.82,
				"highest": 177.18,
				"volumePrice": 175.8343,
				"transactionCount": 642756,
				"voluemPrice": 175.8343
			},
			{
				"open": 175.78,
				"close": 172.19,
				"volume": 84405760,
				"time": 1642050000000,
				"lowest": 171.79,
				"highest": 176.62,
				"volumePrice": 173.9711,
				"transactionCount": 692343,
				"voluemPrice": 173.9711
			},
			{
				"open": 171.34,
				"close": 173.07,
				"volume": 80440778,
				"time": 1642136400000,
				"lowest": 171.09,
				"highest": 173.78,
				"volumePrice": 172.3612,
				"transactionCount": 672552,
				"voluemPrice": 172.3612
			},
			{
				"open": 171.51,
				"close": 169.8,
				"volume": 91063979,
				"time": 1642482000000,
				"lowest": 169.405,
				"highest": 172.54,
				"volumePrice": 170.5404,
				"transactionCount": 780899,
				"voluemPrice": 170.5404
			},
			{
				"open": 170,
				"close": 166.23,
				"volume": 94814990,
				"time": 1642568400000,
				"lowest": 165.94,
				"highest": 171.08,
				"volumePrice": 168.2999,
				"transactionCount": 824022,
				"voluemPrice": 168.2999
			},
			{
				"open": 166.98,
				"close": 164.51,
				"volume": 91420515,
				"time": 1642654800000,
				"lowest": 164.18,
				"highest": 169.68,
				"volumePrice": 167.0706,
				"transactionCount": 795585,
				"voluemPrice": 167.0706
			},
			{
				"open": 164.415,
				"close": 162.41,
				"volume": 122824858,
				"time": 1642741200000,
				"lowest": 162.3,
				"highest": 166.33,
				"volumePrice": 163.8807,
				"transactionCount": 1079476,
				"voluemPrice": 163.8807
			},
			{
				"open": 160.02,
				"close": 161.62,
				"volume": 162706686,
				"time": 1643000400000,
				"lowest": 154.7,
				"highest": 162.3,
				"volumePrice": 158.6897,
				"transactionCount": 1632710,
				"voluemPrice": 158.6897
			},
			{
				"open": 158.98,
				"close": 159.78,
				"volume": 115777137,
				"time": 1643086800000,
				"lowest": 157.02,
				"highest": 162.76,
				"volumePrice": 159.4274,
				"transactionCount": 1008306,
				"voluemPrice": 159.4274
			},
			{
				"open": 163.5,
				"close": 159.69,
				"volume": 108275307,
				"time": 1643173200000,
				"lowest": 157.82,
				"highest": 164.3894,
				"volumePrice": 161.41,
				"transactionCount": 1017706,
				"voluemPrice": 161.41
			},
			{
				"open": 162.45,
				"close": 159.22,
				"volume": 121954638,
				"time": 1643259600000,
				"lowest": 158.28,
				"highest": 163.84,
				"volumePrice": 161.2969,
				"transactionCount": 1161003,
				"voluemPrice": 161.2969
			},
			{
				"open": 165.71,
				"close": 170.33,
				"volume": 179435660,
				"time": 1643346000000,
				"lowest": 162.8,
				"highest": 170.35,
				"volumePrice": 167.3083,
				"transactionCount": 1518049,
				"voluemPrice": 167.3083
			},
			{
				"open": 170.16,
				"close": 174.78,
				"volume": 115522090,
				"time": 1643605200000,
				"lowest": 169.51,
				"highest": 175,
				"volumePrice": 173.1568,
				"transactionCount": 933480,
				"voluemPrice": 173.1568
			},
			{
				"open": 174.01,
				"close": 174.61,
				"volume": 85595911,
				"time": 1643691600000,
				"lowest": 172.31,
				"highest": 174.84,
				"volumePrice": 173.7061,
				"transactionCount": 735250,
				"voluemPrice": 173.7061
			},
			{
				"open": 174.745,
				"close": 175.84,
				"volume": 84914158,
				"time": 1643778000000,
				"lowest": 173.33,
				"highest": 175.88,
				"volumePrice": 174.9413,
				"transactionCount": 676308,
				"voluemPrice": 174.9413
			},
			{
				"open": 174.48,
				"close": 172.9,
				"volume": 89418074,
				"time": 1643864400000,
				"lowest": 172.12,
				"highest": 176.2399,
				"volumePrice": 174.3835,
				"transactionCount": 724838,
				"voluemPrice": 174.3835
			},
			{
				"open": 171.68,
				"close": 172.39,
				"volume": 82446700,
				"time": 1643950800000,
				"lowest": 170.68,
				"highest": 174.1,
				"volumePrice": 172.3165,
				"transactionCount": 702966,
				"voluemPrice": 172.3165
			},
			{
				"open": 172.86,
				"close": 171.66,
				"volume": 77251204,
				"time": 1644210000000,
				"lowest": 170.95,
				"highest": 173.9458,
				"volumePrice": 172.4426,
				"transactionCount": 571410,
				"voluemPrice": 172.4426
			},
			{
				"open": 171.73,
				"close": 174.83,
				"volume": 74829217,
				"time": 1644296400000,
				"lowest": 171.43,
				"highest": 175.35,
				"volumePrice": 173.8127,
				"transactionCount": 634268,
				"voluemPrice": 173.8127
			},
			{
				"open": 176.05,
				"close": 176.28,
				"volume": 71204538,
				"time": 1644382800000,
				"lowest": 174.9,
				"highest": 176.65,
				"volumePrice": 175.8287,
				"transactionCount": 573996,
				"voluemPrice": 175.8287
			},
			{
				"open": 174.14,
				"close": 172.12,
				"volume": 90715899,
				"time": 1644469200000,
				"lowest": 171.55,
				"highest": 175.48,
				"volumePrice": 173.5408,
				"transactionCount": 761963,
				"voluemPrice": 173.5408
			},
			{
				"open": 172.33,
				"close": 168.64,
				"volume": 98490687,
				"time": 1644555600000,
				"lowest": 168.04,
				"highest": 173.08,
				"volumePrice": 170.1997,
				"transactionCount": 815714,
				"voluemPrice": 170.1997
			},
			{
				"open": 167.37,
				"close": 168.88,
				"volume": 86185530,
				"time": 1644814800000,
				"lowest": 166.56,
				"highest": 169.58,
				"volumePrice": 168.3917,
				"transactionCount": 757491,
				"voluemPrice": 168.3917
			},
			{
				"open": 170.97,
				"close": 172.79,
				"volume": 64286317,
				"time": 1644901200000,
				"lowest": 170.25,
				"highest": 172.95,
				"volumePrice": 171.6596,
				"transactionCount": 562851,
				"voluemPrice": 171.6596
			},
			{
				"open": 171.85,
				"close": 172.55,
				"volume": 61139697,
				"time": 1644987600000,
				"lowest": 170.05,
				"highest": 173.34,
				"volumePrice": 171.6489,
				"transactionCount": 543032,
				"voluemPrice": 171.6489
			},
			{
				"open": 171.03,
				"close": 168.88,
				"volume": 69589344,
				"time": 1645074000000,
				"lowest": 168.47,
				"highest": 171.91,
				"volumePrice": 170.1812,
				"transactionCount": 584118,
				"voluemPrice": 170.1812
			},
			{
				"open": 169.82,
				"close": 167.3,
				"volume": 82772674,
				"time": 1645160400000,
				"lowest": 166.19,
				"highest": 170.5413,
				"volumePrice": 167.7969,
				"transactionCount": 687677,
				"voluemPrice": 167.7969
			},
			{
				"open": 164.98,
				"close": 164.32,
				"volume": 91148358,
				"time": 1645506000000,
				"lowest": 162.15,
				"highest": 166.69,
				"volumePrice": 164.5551,
				"transactionCount": 897217,
				"voluemPrice": 164.5551
			},
			{
				"open": 165.54,
				"close": 160.07,
				"volume": 90009247,
				"time": 1645592400000,
				"lowest": 159.75,
				"highest": 166.15,
				"volumePrice": 162.1577,
				"transactionCount": 820313,
				"voluemPrice": 162.1577
			},
			{
				"open": 152.58,
				"close": 162.74,
				"volume": 141147540,
				"time": 1645678800000,
				"lowest": 152,
				"highest": 162.85,
				"volumePrice": 158.0144,
				"transactionCount": 1383829,
				"voluemPrice": 158.0144
			},
			{
				"open": 163.84,
				"close": 164.85,
				"volume": 91974222,
				"time": 1645765200000,
				"lowest": 160.8738,
				"highest": 165.12,
				"volumePrice": 163.7895,
				"transactionCount": 812155,
				"voluemPrice": 163.7895
			},
			{
				"open": 163.06,
				"close": 165.12,
				"volume": 95056629,
				"time": 1646024400000,
				"lowest": 162.43,
				"highest": 165.42,
				"volumePrice": 164.268,
				"transactionCount": 791485,
				"voluemPrice": 164.268
			},
			{
				"open": 164.695,
				"close": 163.2,
				"volume": 83468865,
				"time": 1646110800000,
				"lowest": 161.97,
				"highest": 166.6,
				"volumePrice": 164.16,
				"transactionCount": 749653,
				"voluemPrice": 164.16
			},
			{
				"open": 164.39,
				"close": 166.56,
				"volume": 79724750,
				"time": 1646197200000,
				"lowest": 162.95,
				"highest": 167.36,
				"volumePrice": 165.8095,
				"transactionCount": 668260,
				"voluemPrice": 165.8095
			},
			{
				"open": 168.47,
				"close": 166.23,
				"volume": 76678441,
				"time": 1646283600000,
				"lowest": 165.55,
				"highest": 168.91,
				"volumePrice": 166.918,
				"transactionCount": 651764,
				"voluemPrice": 166.918
			},
			{
				"open": 164.49,
				"close": 163.17,
				"volume": 83819592,
				"time": 1646370000000,
				"lowest": 162.1,
				"highest": 165.55,
				"volumePrice": 163.398,
				"transactionCount": 743696,
				"voluemPrice": 163.398
			},
			{
				"open": 163.36,
				"close": 159.3,
				"volume": 96418845,
				"time": 1646629200000,
				"lowest": 159.04,
				"highest": 165.02,
				"volumePrice": 161.4026,
				"transactionCount": 847057,
				"voluemPrice": 161.4026
			},
			{
				"open": 158.82,
				"close": 157.44,
				"volume": 131139480,
				"time": 1646715600000,
				"lowest": 155.8,
				"highest": 162.88,
				"volumePrice": 158.7737,
				"transactionCount": 1139149,
				"voluemPrice": 158.7737
			},
			{
				"open": 161.475,
				"close": 162.95,
				"volume": 91445405,
				"time": 1646802000000,
				"lowest": 159.41,
				"highest": 163.41,
				"volumePrice": 161.9446,
				"transactionCount": 733685,
				"voluemPrice": 161.9446
			},
			{
				"open": 160.2,
				"close": 158.52,
				"volume": 105342033,
				"time": 1646888400000,
				"lowest": 155.98,
				"highest": 160.39,
				"volumePrice": 158.0284,
				"transactionCount": 882861,
				"voluemPrice": 158.0284
			},
			{
				"open": 158.93,
				"close": 154.73,
				"volume": 96917302,
				"time": 1646974800000,
				"lowest": 154.5,
				"highest": 159.28,
				"volumePrice": 156.1598,
				"transactionCount": 880727,
				"voluemPrice": 156.1598
			},
			{
				"open": 151.45,
				"close": 150.62,
				"volume": 108712111,
				"time": 1647230400000,
				"lowest": 150.1,
				"highest": 154.12,
				"volumePrice": 151.683,
				"transactionCount": 969357,
				"voluemPrice": 151.683
			},
			{
				"open": 150.9,
				"close": 155.09,
				"volume": 92964302,
				"time": 1647316800000,
				"lowest": 150.38,
				"highest": 155.57,
				"volumePrice": 153.4741,
				"transactionCount": 719955,
				"voluemPrice": 153.4741
			},
			{
				"open": 157.05,
				"close": 159.59,
				"volume": 102300157,
				"time": 1647403200000,
				"lowest": 154.46,
				"highest": 160,
				"volumePrice": 157.6528,
				"transactionCount": 833066,
				"voluemPrice": 157.6528
			},
			{
				"open": 158.61,
				"close": 160.62,
				"volume": 75615376,
				"time": 1647489600000,
				"lowest": 157.63,
				"highest": 161,
				"volumePrice": 159.4208,
				"transactionCount": 629003,
				"voluemPrice": 159.4208
			},
			{
				"open": 160.51,
				"close": 163.98,
				"volume": 122610172,
				"time": 1647576000000,
				"lowest": 159.76,
				"highest": 164.48,
				"volumePrice": 162.5971,
				"transactionCount": 713986,
				"voluemPrice": 162.5971
			},
			{
				"open": 163.51,
				"close": 165.38,
				"volume": 95811352,
				"time": 1647835200000,
				"lowest": 163.015,
				"highest": 166.35,
				"volumePrice": 164.7355,
				"transactionCount": 721125,
				"voluemPrice": 164.7355
			},
			{
				"open": 165.51,
				"close": 168.82,
				"volume": 81532007,
				"time": 1647921600000,
				"lowest": 164.91,
				"highest": 169.42,
				"volumePrice": 168.2308,
				"transactionCount": 638943,
				"voluemPrice": 168.2308
			},
			{
				"open": 167.99,
				"close": 170.21,
				"volume": 98062674,
				"time": 1648008000000,
				"lowest": 167.65,
				"highest": 172.64,
				"volumePrice": 170.7915,
				"transactionCount": 784481,
				"voluemPrice": 170.7915
			},
			{
				"open": 171.06,
				"close": 174.07,
				"volume": 90131418,
				"time": 1648094400000,
				"lowest": 170.21,
				"highest": 174.14,
				"volumePrice": 172.2655,
				"transactionCount": 688102,
				"voluemPrice": 172.2655
			},
			{
				"open": 173.88,
				"close": 174.72,
				"volume": 80529356,
				"time": 1648180800000,
				"lowest": 172.75,
				"highest": 175.28,
				"volumePrice": 174.0232,
				"transactionCount": 663600,
				"voluemPrice": 174.0232
			},
			{
				"open": 172.17,
				"close": 175.6,
				"volume": 90371916,
				"time": 1648440000000,
				"lowest": 172,
				"highest": 175.73,
				"volumePrice": 174.3754,
				"transactionCount": 716107,
				"voluemPrice": 174.3754
			},
			{
				"open": 176.69,
				"close": 178.96,
				"volume": 99459940,
				"time": 1648526400000,
				"lowest": 176.34,
				"highest": 179.01,
				"volumePrice": 177.8604,
				"transactionCount": 775943,
				"voluemPrice": 177.8604
			},
			{
				"open": 178.55,
				"close": 177.77,
				"volume": 91340654,
				"time": 1648612800000,
				"lowest": 176.7,
				"highest": 179.61,
				"volumePrice": 178.1191,
				"transactionCount": 695863,
				"voluemPrice": 178.1191
			},
			{
				"open": 177.84,
				"close": 174.61,
				"volume": 103049285,
				"time": 1648699200000,
				"lowest": 174.4,
				"highest": 178.03,
				"volumePrice": 175.8765,
				"transactionCount": 716272,
				"voluemPrice": 175.8765
			},
			{
				"open": 174.03,
				"close": 174.31,
				"volume": 78251328,
				"time": 1648785600000,
				"lowest": 171.94,
				"highest": 174.88,
				"volumePrice": 173.4143,
				"transactionCount": 661160,
				"voluemPrice": 173.4143
			},
			{
				"open": 174.57,
				"close": 178.44,
				"volume": 76545983,
				"time": 1649044800000,
				"lowest": 174.44,
				"highest": 178.49,
				"volumePrice": 177.4855,
				"transactionCount": 630374,
				"voluemPrice": 177.4855
			},
			{
				"open": 177.5,
				"close": 175.06,
				"volume": 73401786,
				"time": 1649131200000,
				"lowest": 174.415,
				"highest": 178.3,
				"volumePrice": 175.9791,
				"transactionCount": 621541,
				"voluemPrice": 175.9791
			},
			{
				"open": 172.36,
				"close": 171.83,
				"volume": 89058782,
				"time": 1649217600000,
				"lowest": 170.13,
				"highest": 173.63,
				"volumePrice": 171.7887,
				"transactionCount": 769485,
				"voluemPrice": 171.7887
			},
			{
				"open": 171.16,
				"close": 172.14,
				"volume": 77594650,
				"time": 1649304000000,
				"lowest": 169.85,
				"highest": 173.36,
				"volumePrice": 171.8049,
				"transactionCount": 628352,
				"voluemPrice": 171.8049
			},
			{
				"open": 171.78,
				"close": 170.09,
				"volume": 76534208,
				"time": 1649390400000,
				"lowest": 169.2,
				"highest": 171.78,
				"volumePrice": 170.5154,
				"transactionCount": 614848,
				"voluemPrice": 170.5154
			},
			{
				"open": 168.71,
				"close": 165.75,
				"volume": 72246706,
				"time": 1649649600000,
				"lowest": 165.5,
				"highest": 169.03,
				"volumePrice": 166.692,
				"transactionCount": 677210,
				"voluemPrice": 166.692
			},
			{
				"open": 168.02,
				"close": 167.66,
				"volume": 79265181,
				"time": 1649736000000,
				"lowest": 166.64,
				"highest": 169.87,
				"volumePrice": 168.0916,
				"transactionCount": 678461,
				"voluemPrice": 168.0916
			},
			{
				"open": 167.39,
				"close": 170.4,
				"volume": 70618925,
				"time": 1649822400000,
				"lowest": 166.77,
				"highest": 171.04,
				"volumePrice": 169.6805,
				"transactionCount": 558072,
				"voluemPrice": 169.6805
			},
			{
				"open": 170.62,
				"close": 165.29,
				"volume": 75069376,
				"time": 1649908800000,
				"lowest": 165.04,
				"highest": 171.27,
				"volumePrice": 167.2264,
				"transactionCount": 596848,
				"voluemPrice": 167.2264
			},
			{
				"open": 163.92,
				"close": 165.07,
				"volume": 69023941,
				"time": 1650254400000,
				"lowest": 163.57,
				"highest": 166.5984,
				"volumePrice": 164.9284,
				"transactionCount": 576791,
				"voluemPrice": 164.9284
			},
			{
				"open": 165.02,
				"close": 167.4,
				"volume": 67723833,
				"time": 1650340800000,
				"lowest": 163.91,
				"highest": 167.82,
				"volumePrice": 166.5491,
				"transactionCount": 548306,
				"voluemPrice": 166.5491
			},
			{
				"open": 168.76,
				"close": 167.23,
				"volume": 67929814,
				"time": 1650427200000,
				"lowest": 166.1,
				"highest": 168.88,
				"volumePrice": 167.3492,
				"transactionCount": 595063,
				"voluemPrice": 167.3492
			},
			{
				"open": 168.91,
				"close": 166.42,
				"volume": 87227768,
				"time": 1650513600000,
				"lowest": 165.91,
				"highest": 171.53,
				"volumePrice": 168.8014,
				"transactionCount": 752017,
				"voluemPrice": 168.8014
			},
			{
				"open": 166.46,
				"close": 161.79,
				"volume": 84875424,
				"time": 1650600000000,
				"lowest": 161.5,
				"highest": 167.8699,
				"volumePrice": 164.2289,
				"transactionCount": 730001,
				"voluemPrice": 164.2289
			},
			{
				"open": 161.12,
				"close": 162.88,
				"volume": 96017376,
				"time": 1650859200000,
				"lowest": 158.46,
				"highest": 163.17,
				"volumePrice": 160.7032,
				"transactionCount": 925124,
				"voluemPrice": 160.7032
			},
			{
				"open": 162.25,
				"close": 156.8,
				"volume": 95595226,
				"time": 1650945600000,
				"lowest": 156.72,
				"highest": 162.34,
				"volumePrice": 158.6074,
				"transactionCount": 899965,
				"voluemPrice": 158.6074
			},
			{
				"open": 155.91,
				"close": 156.57,
				"volume": 88063191,
				"time": 1651032000000,
				"lowest": 155.38,
				"highest": 159.79,
				"volumePrice": 157.4448,
				"transactionCount": 777860,
				"voluemPrice": 157.4448
			},
			{
				"open": 159.25,
				"close": 163.64,
				"volume": 130149192,
				"time": 1651118400000,
				"lowest": 158.93,
				"highest": 164.515,
				"volumePrice": 161.8622,
				"transactionCount": 1126019,
				"voluemPrice": 161.8622
			},
			{
				"open": 161.84,
				"close": 157.65,
				"volume": 131747495,
				"time": 1651204800000,
				"lowest": 157.25,
				"highest": 166.2,
				"volumePrice": 160.7133,
				"transactionCount": 1032218,
				"voluemPrice": 160.7133
			},
			{
				"open": 156.71,
				"close": 157.96,
				"volume": 123050000,
				"time": 1651464000000,
				"lowest": 153.27,
				"highest": 158.23,
				"volumePrice": 156.0357,
				"transactionCount": 1154671,
				"voluemPrice": 156.0357
			},
			{
				"open": 158.15,
				"close": 159.48,
				"volume": 88966526,
				"time": 1651550400000,
				"lowest": 156.32,
				"highest": 160.71,
				"volumePrice": 158.8014,
				"transactionCount": 706626,
				"voluemPrice": 158.8014
			},
			{
				"open": 159.67,
				"close": 166.02,
				"volume": 108206503,
				"time": 1651636800000,
				"lowest": 159.26,
				"highest": 166.48,
				"volumePrice": 162.4245,
				"transactionCount": 873681,
				"voluemPrice": 162.4245
			},
			{
				"open": 163.85,
				"close": 156.77,
				"volume": 130419230,
				"time": 1651723200000,
				"lowest": 154.95,
				"highest": 164.08,
				"volumePrice": 158.143,
				"transactionCount": 1164746,
				"voluemPrice": 158.143
			},
			{
				"open": 156.01,
				"close": 157.28,
				"volume": 115494647,
				"time": 1651809600000,
				"lowest": 154.18,
				"highest": 159.44,
				"volumePrice": 157.3298,
				"transactionCount": 1023008,
				"voluemPrice": 157.3298
			},
			{
				"open": 154.925,
				"close": 152.06,
				"volume": 131577921,
				"time": 1652068800000,
				"lowest": 151.49,
				"highest": 155.83,
				"volumePrice": 153.1029,
				"transactionCount": 1103707,
				"voluemPrice": 153.1029
			},
			{
				"open": 155.52,
				"close": 154.51,
				"volume": 115366736,
				"time": 1652155200000,
				"lowest": 152.93,
				"highest": 156.74,
				"volumePrice": 154.8376,
				"transactionCount": 988257,
				"voluemPrice": 154.8376
			},
			{
				"open": 153.5,
				"close": 146.5,
				"volume": 142547625,
				"time": 1652241600000,
				"lowest": 145.81,
				"highest": 155.45,
				"volumePrice": 149.4468,
				"transactionCount": 1310796,
				"voluemPrice": 149.4468
			},
			{
				"open": 142.77,
				"close": 142.56,
				"volume": 182408091,
				"time": 1652328000000,
				"lowest": 138.8,
				"highest": 146.2,
				"volumePrice": 142.066,
				"transactionCount": 1678454,
				"voluemPrice": 142.066
			},
			{
				"open": 144.59,
				"close": 147.11,
				"volume": 113990852,
				"time": 1652414400000,
				"lowest": 143.11,
				"highest": 148.105,
				"volumePrice": 146.3255,
				"transactionCount": 920506,
				"voluemPrice": 146.3255
			},
			{
				"open": 145.55,
				"close": 145.54,
				"volume": 86643781,
				"time": 1652673600000,
				"lowest": 144.18,
				"highest": 147.5199,
				"volumePrice": 145.7814,
				"transactionCount": 699719,
				"voluemPrice": 145.7814
			},
			{
				"open": 148.86,
				"close": 149.24,
				"volume": 78138254,
				"time": 1652760000000,
				"lowest": 146.68,
				"highest": 149.77,
				"volumePrice": 148.443,
				"transactionCount": 609263,
				"voluemPrice": 148.443
			},
			{
				"open": 146.85,
				"close": 140.82,
				"volume": 109742890,
				"time": 1652846400000,
				"lowest": 139.9,
				"highest": 147.3601,
				"volumePrice": 142.6901,
				"transactionCount": 982288,
				"voluemPrice": 142.6901
			},
			{
				"open": 139.88,
				"close": 137.35,
				"volume": 135863540,
				"time": 1652932800000,
				"lowest": 136.6,
				"highest": 141.66,
				"volumePrice": 138.66,
				"transactionCount": 1219746,
				"voluemPrice": 138.66
			},
			{
				"open": 139.09,
				"close": 137.59,
				"volume": 137392625,
				"time": 1653019200000,
				"lowest": 132.61,
				"highest": 140.7,
				"volumePrice": 136.397,
				"transactionCount": 1133362,
				"voluemPrice": 136.397
			},
			{
				"open": 137.79,
				"close": 143.11,
				"volume": 117726265,
				"time": 1653278400000,
				"lowest": 137.65,
				"highest": 143.26,
				"volumePrice": 141.4344,
				"transactionCount": 851032,
				"voluemPrice": 141.4344
			},
			{
				"open": 140.805,
				"close": 140.36,
				"volume": 104132746,
				"time": 1653364800000,
				"lowest": 137.33,
				"highest": 141.97,
				"volumePrice": 139.4136,
				"transactionCount": 812515,
				"voluemPrice": 139.4136
			},
			{
				"open": 138.43,
				"close": 140.52,
				"volume": 92482696,
				"time": 1653451200000,
				"lowest": 138.34,
				"highest": 141.785,
				"volumePrice": 140.0769,
				"transactionCount": 688316,
				"voluemPrice": 140.0769
			},
			{
				"open": 137.39,
				"close": 143.78,
				"volume": 90601098,
				"time": 1653537600000,
				"lowest": 137.14,
				"highest": 144.34,
				"volumePrice": 142.3902,
				"transactionCount": 670652,
				"voluemPrice": 142.3902
			},
			{
				"open": 145.39,
				"close": 149.64,
				"volume": 91425110,
				"time": 1653624000000,
				"lowest": 145.26,
				"highest": 149.68,
				"volumePrice": 148.1995,
				"transactionCount": 686376,
				"voluemPrice": 148.1995
			},
			{
				"open": 149.07,
				"close": 148.84,
				"volume": 103718416,
				"time": 1653969600000,
				"lowest": 146.84,
				"highest": 150.66,
				"volumePrice": 149.0998,
				"transactionCount": 706652,
				"voluemPrice": 149.0998
			},
			{
				"open": 149.9,
				"close": 148.71,
				"volume": 74286635,
				"time": 1654056000000,
				"lowest": 147.68,
				"highest": 151.74,
				"volumePrice": 149.3747,
				"transactionCount": 629251,
				"voluemPrice": 149.3747
			},
			{
				"open": 147.83,
				"close": 151.21,
				"volume": 72348055,
				"time": 1654142400000,
				"lowest": 146.86,
				"highest": 151.27,
				"volumePrice": 149.5215,
				"transactionCount": 574552,
				"voluemPrice": 149.5215
			},
			{
				"open": 146.9,
				"close": 145.38,
				"volume": 88570289,
				"time": 1654228800000,
				"lowest": 144.46,
				"highest": 147.97,
				"volumePrice": 145.8127,
				"transactionCount": 761195,
				"voluemPrice": 145.8127
			},
			{
				"open": 147.03,
				"close": 146.14,
				"volume": 71598380,
				"time": 1654488000000,
				"lowest": 144.9,
				"highest": 148.5689,
				"volumePrice": 146.6167,
				"transactionCount": 605199,
				"voluemPrice": 146.6167
			},
			{
				"open": 144.345,
				"close": 148.71,
				"volume": 67808150,
				"time": 1654574400000,
				"lowest": 144.1,
				"highest": 149,
				"volumePrice": 147.5529,
				"transactionCount": 527360,
				"voluemPrice": 147.5529
			},
			{
				"open": 148.58,
				"close": 147.96,
				"volume": 53950201,
				"time": 1654660800000,
				"lowest": 147.46,
				"highest": 149.8697,
				"volumePrice": 148.6118,
				"transactionCount": 430174,
				"voluemPrice": 148.6118
			},
			{
				"open": 147.08,
				"close": 142.64,
				"volume": 69472976,
				"time": 1654747200000,
				"lowest": 142.53,
				"highest": 147.95,
				"volumePrice": 145.3266,
				"transactionCount": 558178,
				"voluemPrice": 145.3266
			},
			{
				"open": 140.28,
				"close": 137.13,
				"volume": 91566637,
				"time": 1654833600000,
				"lowest": 137.06,
				"highest": 140.76,
				"volumePrice": 138.1785,
				"transactionCount": 826197,
				"voluemPrice": 138.1785
			},
			{
				"open": 132.87,
				"close": 131.88,
				"volume": 122128099,
				"time": 1655092800000,
				"lowest": 131.44,
				"highest": 135.2,
				"volumePrice": 133.128,
				"transactionCount": 1019929,
				"voluemPrice": 133.128
			},
			{
				"open": 133.13,
				"close": 132.76,
				"volume": 84784326,
				"time": 1655179200000,
				"lowest": 131.48,
				"highest": 133.89,
				"volumePrice": 132.5738,
				"transactionCount": 674981,
				"voluemPrice": 132.5738
			},
			{
				"open": 134.29,
				"close": 135.43,
				"volume": 91532972,
				"time": 1655265600000,
				"lowest": 132.16,
				"highest": 137.34,
				"volumePrice": 134.625,
				"transactionCount": 733275,
				"voluemPrice": 134.625
			},
			{
				"open": 132.08,
				"close": 130.06,
				"volume": 107961508,
				"time": 1655352000000,
				"lowest": 129.04,
				"highest": 132.39,
				"volumePrice": 130.4622,
				"transactionCount": 909222,
				"voluemPrice": 130.4622
			},
			{
				"open": 130.065,
				"close": 131.56,
				"volume": 134520290,
				"time": 1655438400000,
				"lowest": 129.81,
				"highest": 133.079,
				"volumePrice": 131.491,
				"transactionCount": 744035,
				"voluemPrice": 131.491
			},
			{
				"open": 133.42,
				"close": 135.87,
				"volume": 81000488,
				"time": 1655784000000,
				"lowest": 133.32,
				"highest": 137.06,
				"volumePrice": 135.8508,
				"transactionCount": 604547,
				"voluemPrice": 135.8508
			},
			{
				"open": 134.79,
				"close": 135.35,
				"volume": 73409234,
				"time": 1655870400000,
				"lowest": 133.91,
				"highest": 137.76,
				"volumePrice": 135.8581,
				"transactionCount": 530526,
				"voluemPrice": 135.8581
			},
			{
				"open": 136.82,
				"close": 138.27,
				"volume": 72433768,
				"time": 1655956800000,
				"lowest": 135.63,
				"highest": 138.59,
				"volumePrice": 137.2859,
				"transactionCount": 554898,
				"voluemPrice": 137.2859
			},
			{
				"open": 139.9,
				"close": 141.66,
				"volume": 88401837,
				"time": 1656043200000,
				"lowest": 139.77,
				"highest": 141.91,
				"volumePrice": 140.8332,
				"transactionCount": 542278,
				"voluemPrice": 140.8332
			},
			{
				"open": 142.695,
				"close": 141.66,
				"volume": 70207908,
				"time": 1656302400000,
				"lowest": 140.965,
				"highest": 143.49,
				"volumePrice": 142.0413,
				"transactionCount": 516175,
				"voluemPrice": 142.0413
			},
			{
				"open": 142.13,
				"close": 137.44,
				"volume": 67315328,
				"time": 1656388800000,
				"lowest": 137.325,
				"highest": 143.422,
				"volumePrice": 139.3752,
				"transactionCount": 546240,
				"voluemPrice": 139.3752
			},
			{
				"open": 137.46,
				"close": 139.23,
				"volume": 66242411,
				"time": 1656475200000,
				"lowest": 136.67,
				"highest": 140.67,
				"volumePrice": 139.2737,
				"transactionCount": 572553,
				"voluemPrice": 139.2737
			},
			{
				"open": 137.25,
				"close": 136.72,
				"volume": 98964467,
				"time": 1656561600000,
				"lowest": 133.7737,
				"highest": 138.37,
				"volumePrice": 136.476,
				"transactionCount": 710706,
				"voluemPrice": 136.476
			},
			{
				"open": 136.04,
				"close": 138.93,
				"volume": 71051552,
				"time": 1656648000000,
				"lowest": 135.66,
				"highest": 139.04,
				"volumePrice": 137.4774,
				"transactionCount": 558680,
				"voluemPrice": 137.4774
			},
			{
				"open": 137.77,
				"close": 141.56,
				"volume": 73429641,
				"time": 1656993600000,
				"lowest": 136.93,
				"highest": 141.61,
				"volumePrice": 139.7669,
				"transactionCount": 601960,
				"voluemPrice": 139.7669
			},
			{
				"open": 141.355,
				"close": 142.92,
				"volume": 74064254,
				"time": 1657080000000,
				"lowest": 141.08,
				"highest": 144.12,
				"volumePrice": 142.5582,
				"transactionCount": 585624,
				"voluemPrice": 142.5582
			},
			{
				"open": 143.29,
				"close": 146.35,
				"volume": 66253709,
				"time": 1657166400000,
				"lowest": 143.28,
				"highest": 146.55,
				"volumePrice": 145.6754,
				"transactionCount": 539961,
				"voluemPrice": 145.6754
			},
			{
				"open": 145.265,
				"close": 147.04,
				"volume": 64543598,
				"time": 1657252800000,
				"lowest": 145,
				"highest": 147.55,
				"volumePrice": 146.6409,
				"transactionCount": 520274,
				"voluemPrice": 146.6409
			},
			{
				"open": 145.67,
				"close": 144.87,
				"volume": 63305113,
				"time": 1657512000000,
				"lowest": 143.78,
				"highest": 146.64,
				"volumePrice": 145.1524,
				"transactionCount": 496267,
				"voluemPrice": 145.1524
			},
			{
				"open": 145.76,
				"close": 145.86,
				"volume": 77588759,
				"time": 1657598400000,
				"lowest": 145.05,
				"highest": 148.45,
				"volumePrice": 146.7948,
				"transactionCount": 579531,
				"voluemPrice": 146.7948
			},
			{
				"open": 142.99,
				"close": 145.49,
				"volume": 71185560,
				"time": 1657684800000,
				"lowest": 142.1201,
				"highest": 146.45,
				"volumePrice": 145.1932,
				"transactionCount": 559357,
				"voluemPrice": 145.1932
			},
			{
				"open": 144.08,
				"close": 148.47,
				"volume": 78140744,
				"time": 1657771200000,
				"lowest": 143.25,
				"highest": 148.95,
				"volumePrice": 146.591,
				"transactionCount": 614951,
				"voluemPrice": 146.591
			},
			{
				"open": 149.78,
				"close": 150.17,
				"volume": 76259931,
				"time": 1657857600000,
				"lowest": 148.2,
				"highest": 150.86,
				"volumePrice": 149.5097,
				"transactionCount": 550061,
				"voluemPrice": 149.5097
			},
			{
				"open": 150.74,
				"close": 147.07,
				"volume": 81380868,
				"time": 1658116800000,
				"lowest": 146.7,
				"highest": 151.57,
				"volumePrice": 149.0297,
				"transactionCount": 620449,
				"voluemPrice": 149.0297
			},
			{
				"open": 147.92,
				"close": 151,
				"volume": 82969067,
				"time": 1658203200000,
				"lowest": 146.91,
				"highest": 151.23,
				"volumePrice": 149.7493,
				"transactionCount": 576469,
				"voluemPrice": 149.7493
			},
			{
				"open": 151.12,
				"close": 153.04,
				"volume": 64823413,
				"time": 1658289600000,
				"lowest": 150.37,
				"highest": 153.72,
				"volumePrice": 152.6255,
				"transactionCount": 523491,
				"voluemPrice": 152.6255
			},
			{
				"open": 154.5,
				"close": 155.35,
				"volume": 65064436,
				"time": 1658376000000,
				"lowest": 151.94,
				"highest": 155.57,
				"volumePrice": 154.3545,
				"transactionCount": 522802,
				"voluemPrice": 154.3545
			},
			{
				"open": 155.39,
				"close": 154.09,
				"volume": 66675373,
				"time": 1658462400000,
				"lowest": 153.41,
				"highest": 156.28,
				"volumePrice": 154.6233,
				"transactionCount": 527310,
				"voluemPrice": 154.6233
			},
			{
				"open": 154.01,
				"close": 152.95,
				"volume": 53580745,
				"time": 1658721600000,
				"lowest": 152.28,
				"highest": 155.04,
				"volumePrice": 153.3004,
				"transactionCount": 487391,
				"voluemPrice": 153.3004
			},
			{
				"open": 152.265,
				"close": 151.6,
				"volume": 55099091,
				"time": 1658808000000,
				"lowest": 150.8,
				"highest": 153.085,
				"volumePrice": 151.6799,
				"transactionCount": 472001,
				"voluemPrice": 151.6799
			},
			{
				"open": 152.58,
				"close": 156.79,
				"volume": 78620688,
				"time": 1658894400000,
				"lowest": 152.16,
				"highest": 157.33,
				"volumePrice": 155.2412,
				"transactionCount": 579181,
				"voluemPrice": 155.2412
			},
			{
				"open": 156.98,
				"close": 157.35,
				"volume": 81378731,
				"time": 1658980800000,
				"lowest": 154.41,
				"highest": 157.64,
				"volumePrice": 157.4654,
				"transactionCount": 738631,
				"voluemPrice": 157.4654
			},
			{
				"open": 161.24,
				"close": 162.51,
				"volume": 101763760,
				"time": 1659067200000,
				"lowest": 159.5,
				"highest": 163.63,
				"volumePrice": 162.249,
				"transactionCount": 773742,
				"voluemPrice": 162.249
			}
		],
		"status": "OK",
		"request_id": "6812ee11fa87356117f4ca2989d26fb1",
		"count": 3777
	},
	crypto: {
		"ticker": "AAPL",
		"queryCount": 5000,
		"resultsCount": 5000,
		"adjusted": true,
		"results": [
		  {
			"v": 5600,
			"vw": 21.7934,
			"o": 21.7929,
			"c": 21.7939,
			"h": 21.7939,
			"l": 21.7929,
			"t": 1343980800000,
			"n": 2
		  },
		  {
			"v": 22400,
			"vw": 21.8126,
			"o": 21.8129,
			"c": 21.8125,
			"h": 21.8129,
			"l": 21.8125,
			"t": 1343981400000,
			"n": 6
		  },
		  {
			"v": 7000,
			"vw": 21.8321,
			"o": 21.8321,
			"c": 21.8321,
			"h": 21.8321,
			"l": 21.8321,
			"t": 1343983320000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343985180000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343985480000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343986080000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343986140000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343986200000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343986260000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343986500000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8357,
			"o": 21.8357,
			"c": 21.8357,
			"h": 21.8357,
			"l": 21.8357,
			"t": 1343987400000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 21.8464,
			"o": 21.8464,
			"c": 21.8464,
			"h": 21.8464,
			"l": 21.8464,
			"t": 1343989380000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8564,
			"o": 21.8564,
			"c": 21.8564,
			"h": 21.8564,
			"l": 21.8564,
			"t": 1343989980000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8643,
			"o": 21.8643,
			"c": 21.8643,
			"h": 21.8643,
			"l": 21.8643,
			"t": 1343990040000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8571,
			"o": 21.8571,
			"c": 21.8571,
			"h": 21.8571,
			"l": 21.8571,
			"t": 1343990760000,
			"n": 1
		  },
		  {
			"v": 18200,
			"vw": 21.8473,
			"o": 21.8507,
			"c": 21.8393,
			"h": 21.8511,
			"l": 21.8393,
			"t": 1343991600000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 21.8357,
			"o": 21.8357,
			"c": 21.8357,
			"h": 21.8357,
			"l": 21.8357,
			"t": 1343991660000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 21.8393,
			"o": 21.8393,
			"c": 21.8393,
			"h": 21.8393,
			"l": 21.8393,
			"t": 1343991720000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 21.8657,
			"o": 21.8571,
			"c": 21.8714,
			"h": 21.8714,
			"l": 21.8571,
			"t": 1343991780000,
			"n": 5
		  },
		  {
			"v": 50008,
			"vw": 21.8579,
			"o": 21.8714,
			"c": 21.8571,
			"h": 21.8714,
			"l": 21.8561,
			"t": 1343991840000,
			"n": 11
		  },
		  {
			"v": 26348,
			"vw": 21.857,
			"o": 21.8571,
			"c": 21.8468,
			"h": 21.8632,
			"l": 21.8468,
			"t": 1343991900000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 21.8596,
			"o": 21.8596,
			"c": 21.8596,
			"h": 21.8596,
			"l": 21.8596,
			"t": 1343991960000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.8564,
			"o": 21.8564,
			"c": 21.8564,
			"h": 21.8564,
			"l": 21.8564,
			"t": 1343992200000,
			"n": 1
		  },
		  {
			"v": 28000,
			"vw": 21.8561,
			"o": 21.8546,
			"c": 21.8564,
			"h": 21.8564,
			"l": 21.8546,
			"t": 1343992440000,
			"n": 3
		  },
		  {
			"v": 6300,
			"vw": 21.8556,
			"o": 21.8571,
			"c": 21.8536,
			"h": 21.8571,
			"l": 21.8536,
			"t": 1343992500000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.8464,
			"o": 21.8464,
			"c": 21.8464,
			"h": 21.8464,
			"l": 21.8464,
			"t": 1343992920000,
			"n": 1
		  },
		  {
			"v": 3360,
			"vw": 21.8464,
			"o": 21.8464,
			"c": 21.8464,
			"h": 21.8464,
			"l": 21.8464,
			"t": 1343992980000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 21.8379,
			"o": 21.8429,
			"c": 21.8304,
			"h": 21.8429,
			"l": 21.8304,
			"t": 1343993160000,
			"n": 6
		  },
		  {
			"v": 160244,
			"vw": 21.8438,
			"o": 21.8389,
			"c": 21.8475,
			"h": 21.8529,
			"l": 21.8389,
			"t": 1343993220000,
			"n": 26
		  },
		  {
			"v": 5600,
			"vw": 21.8479,
			"o": 21.8482,
			"c": 21.8475,
			"h": 21.8482,
			"l": 21.8475,
			"t": 1343993280000,
			"n": 2
		  },
		  {
			"v": 65800,
			"vw": 21.82,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8296,
			"l": 21.8136,
			"t": 1343993400000,
			"n": 18
		  },
		  {
			"v": 75600,
			"vw": 21.8225,
			"o": 21.8211,
			"c": 21.8371,
			"h": 21.8371,
			"l": 21.8207,
			"t": 1343993460000,
			"n": 10
		  },
		  {
			"v": 19012,
			"vw": 21.8215,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8218,
			"l": 21.8214,
			"t": 1343993520000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 21.82,
			"o": 21.82,
			"c": 21.82,
			"h": 21.82,
			"l": 21.82,
			"t": 1343993580000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8214,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8214,
			"t": 1343993640000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 21.8214,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8214,
			"t": 1343993700000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8214,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8214,
			"t": 1343993760000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.8214,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8214,
			"t": 1343993880000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 21.8214,
			"o": 21.8214,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8214,
			"t": 1343993940000,
			"n": 5
		  },
		  {
			"v": 7560,
			"vw": 21.8212,
			"o": 21.8207,
			"c": 21.8214,
			"h": 21.8214,
			"l": 21.8207,
			"t": 1343994000000,
			"n": 2
		  },
		  {
			"v": 19600,
			"vw": 21.8151,
			"o": 21.8161,
			"c": 21.8125,
			"h": 21.8161,
			"l": 21.8125,
			"t": 1343994060000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 21.8193,
			"o": 21.8193,
			"c": 21.8193,
			"h": 21.8193,
			"l": 21.8193,
			"t": 1343994120000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 21.8122,
			"o": 21.8139,
			"c": 21.8107,
			"h": 21.8139,
			"l": 21.8107,
			"t": 1343994300000,
			"n": 4
		  },
		  {
			"v": 11200,
			"vw": 21.8015,
			"o": 21.8018,
			"c": 21.8014,
			"h": 21.8018,
			"l": 21.8014,
			"t": 1343994540000,
			"n": 3
		  },
		  {
			"v": 50400,
			"vw": 21.7952,
			"o": 21.7986,
			"c": 21.7946,
			"h": 21.7986,
			"l": 21.7946,
			"t": 1343994600000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 21.8036,
			"o": 21.8036,
			"c": 21.8036,
			"h": 21.8036,
			"l": 21.8036,
			"t": 1343994660000,
			"n": 1
		  },
		  {
			"v": 22400,
			"vw": 21.8034,
			"o": 21.8036,
			"c": 21.8036,
			"h": 21.8036,
			"l": 21.8029,
			"t": 1343994720000,
			"n": 6
		  },
		  {
			"v": 14000,
			"vw": 21.809,
			"o": 21.8089,
			"c": 21.8089,
			"h": 21.8093,
			"l": 21.8089,
			"t": 1343994780000,
			"n": 3
		  },
		  {
			"v": 3500,
			"vw": 21.8043,
			"o": 21.8043,
			"c": 21.8043,
			"h": 21.8043,
			"l": 21.8043,
			"t": 1343994960000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 21.7979,
			"o": 21.8004,
			"c": 21.7946,
			"h": 21.8004,
			"l": 21.7946,
			"t": 1343995080000,
			"n": 6
		  },
		  {
			"v": 120176,
			"vw": 21.804,
			"o": 21.7932,
			"c": 21.8089,
			"h": 21.8089,
			"l": 21.7893,
			"t": 1343995200000,
			"n": 22
		  },
		  {
			"v": 19600,
			"vw": 21.8075,
			"o": 21.8096,
			"c": 21.8071,
			"h": 21.8096,
			"l": 21.8036,
			"t": 1343995260000,
			"n": 7
		  },
		  {
			"v": 14000,
			"vw": 21.8071,
			"o": 21.8071,
			"c": 21.8071,
			"h": 21.8071,
			"l": 21.8071,
			"t": 1343995320000,
			"n": 1
		  },
		  {
			"v": 17164,
			"vw": 21.8036,
			"o": 21.8036,
			"c": 21.8039,
			"h": 21.8039,
			"l": 21.8036,
			"t": 1343995380000,
			"n": 2
		  },
		  {
			"v": 240464,
			"vw": 21.7935,
			"o": 21.7979,
			"c": 21.7982,
			"h": 21.7982,
			"l": 21.7893,
			"t": 1343995440000,
			"n": 23
		  },
		  {
			"v": 54628,
			"vw": 21.8048,
			"o": 21.7996,
			"c": 21.8071,
			"h": 21.8071,
			"l": 21.7996,
			"t": 1343995500000,
			"n": 12
		  },
		  {
			"v": 103600,
			"vw": 21.8068,
			"o": 21.8089,
			"c": 21.8018,
			"h": 21.8146,
			"l": 21.8018,
			"t": 1343995560000,
			"n": 23
		  },
		  {
			"v": 53984,
			"vw": 21.8017,
			"o": 21.8018,
			"c": 21.8018,
			"h": 21.8018,
			"l": 21.8,
			"t": 1343995620000,
			"n": 9
		  },
		  {
			"v": 47600,
			"vw": 21.7479,
			"o": 21.7943,
			"c": 21.7943,
			"h": 21.7943,
			"l": 21.7939,
			"t": 1343995680000,
			"n": 5
		  },
		  {
			"v": 5460,
			"vw": 21.7918,
			"o": 21.7918,
			"c": 21.7918,
			"h": 21.7918,
			"l": 21.7918,
			"t": 1343995740000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.7961,
			"o": 21.7961,
			"c": 21.7961,
			"h": 21.7961,
			"l": 21.7961,
			"t": 1343995860000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 21.7931,
			"o": 21.7957,
			"c": 21.7918,
			"h": 21.7957,
			"l": 21.7918,
			"t": 1343995980000,
			"n": 2
		  },
		  {
			"v": 55272,
			"vw": 21.7886,
			"o": 21.7946,
			"c": 21.7871,
			"h": 21.7946,
			"l": 21.7786,
			"t": 1343996040000,
			"n": 10
		  },
		  {
			"v": 57680,
			"vw": 21.7595,
			"o": 21.7736,
			"c": 21.7782,
			"h": 21.7782,
			"l": 21.7143,
			"t": 1343996160000,
			"n": 16
		  },
		  {
			"v": 5600,
			"vw": 21.7814,
			"o": 21.7786,
			"c": 21.7843,
			"h": 21.7843,
			"l": 21.7786,
			"t": 1343996220000,
			"n": 2
		  },
		  {
			"v": 27020,
			"vw": 21.7836,
			"o": 21.785,
			"c": 21.7857,
			"h": 21.7857,
			"l": 21.7789,
			"t": 1343996280000,
			"n": 8
		  },
		  {
			"v": 14000,
			"vw": 21.7883,
			"o": 21.7857,
			"c": 21.785,
			"h": 21.7925,
			"l": 21.785,
			"t": 1343996340000,
			"n": 5
		  },
		  {
			"v": 94220,
			"vw": 21.7858,
			"o": 21.7864,
			"c": 21.7857,
			"h": 21.7868,
			"l": 21.7854,
			"t": 1343996400000,
			"n": 14
		  },
		  {
			"v": 178472,
			"vw": 21.7808,
			"o": 21.7857,
			"c": 21.7782,
			"h": 21.7857,
			"l": 21.7782,
			"t": 1343996460000,
			"n": 18
		  },
		  {
			"v": 5600,
			"vw": 21.7784,
			"o": 21.7782,
			"c": 21.7786,
			"h": 21.7786,
			"l": 21.7782,
			"t": 1343996520000,
			"n": 2
		  },
		  {
			"v": 25200,
			"vw": 21.7819,
			"o": 21.7786,
			"c": 21.785,
			"h": 21.785,
			"l": 21.7782,
			"t": 1343996580000,
			"n": 6
		  },
		  {
			"v": 42000,
			"vw": 21.7776,
			"o": 21.7754,
			"c": 21.7814,
			"h": 21.7814,
			"l": 21.775,
			"t": 1343996640000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 21.7769,
			"o": 21.7768,
			"c": 21.7768,
			"h": 21.7771,
			"l": 21.7768,
			"t": 1343996700000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 21.7716,
			"o": 21.775,
			"c": 21.7682,
			"h": 21.775,
			"l": 21.7682,
			"t": 1343996760000,
			"n": 2
		  },
		  {
			"v": 28504,
			"vw": 21.7847,
			"o": 21.7818,
			"c": 21.7893,
			"h": 21.7893,
			"l": 21.7814,
			"t": 1343996820000,
			"n": 7
		  },
		  {
			"v": 22400,
			"vw": 21.7792,
			"o": 21.7807,
			"c": 21.7789,
			"h": 21.7807,
			"l": 21.7786,
			"t": 1343996880000,
			"n": 7
		  },
		  {
			"v": 16800,
			"vw": 21.7793,
			"o": 21.7786,
			"c": 21.7804,
			"h": 21.7814,
			"l": 21.7786,
			"t": 1343996940000,
			"n": 6
		  },
		  {
			"v": 523656,
			"vw": 21.8212,
			"o": 21.7861,
			"c": 21.8279,
			"h": 21.8479,
			"l": 21.7857,
			"t": 1343997000000,
			"n": 114
		  },
		  {
			"v": 469924,
			"vw": 21.8422,
			"o": 21.8207,
			"c": 21.8375,
			"h": 21.8604,
			"l": 21.8118,
			"t": 1343997060000,
			"n": 100
		  },
		  {
			"v": 434560,
			"vw": 21.8643,
			"o": 21.8625,
			"c": 21.8764,
			"h": 21.8893,
			"l": 21.8496,
			"t": 1343997120000,
			"n": 94
		  },
		  {
			"v": 204988,
			"vw": 21.8635,
			"o": 21.8725,
			"c": 21.8643,
			"h": 21.875,
			"l": 21.8543,
			"t": 1343997180000,
			"n": 50
		  },
		  {
			"v": 58800,
			"vw": 21.8551,
			"o": 21.8646,
			"c": 21.8446,
			"h": 21.8646,
			"l": 21.8443,
			"t": 1343997240000,
			"n": 17
		  },
		  {
			"v": 175000,
			"vw": 21.834,
			"o": 21.8518,
			"c": 21.8346,
			"h": 21.8518,
			"l": 21.8218,
			"t": 1343997300000,
			"n": 52
		  },
		  {
			"v": 77000,
			"vw": 21.8402,
			"o": 21.8407,
			"c": 21.8346,
			"h": 21.8464,
			"l": 21.8346,
			"t": 1343997360000,
			"n": 21
		  },
		  {
			"v": 55720,
			"vw": 21.8356,
			"o": 21.8389,
			"c": 21.8307,
			"h": 21.8393,
			"l": 21.8307,
			"t": 1343997420000,
			"n": 14
		  },
		  {
			"v": 89880,
			"vw": 21.8316,
			"o": 21.8293,
			"c": 21.8321,
			"h": 21.8339,
			"l": 21.8261,
			"t": 1343997480000,
			"n": 23
		  },
		  {
			"v": 185304,
			"vw": 21.8397,
			"o": 21.8375,
			"c": 21.8454,
			"h": 21.8518,
			"l": 21.8364,
			"t": 1343997540000,
			"n": 49
		  },
		  {
			"v": 39312,
			"vw": 21.8434,
			"o": 21.8432,
			"c": 21.8429,
			"h": 21.8471,
			"l": 21.8421,
			"t": 1343997600000,
			"n": 8
		  },
		  {
			"v": 105644,
			"vw": 21.8434,
			"o": 21.8429,
			"c": 21.8482,
			"h": 21.8482,
			"l": 21.8393,
			"t": 1343997660000,
			"n": 19
		  },
		  {
			"v": 29792,
			"vw": 21.8492,
			"o": 21.8464,
			"c": 21.85,
			"h": 21.85,
			"l": 21.8464,
			"t": 1343997720000,
			"n": 6
		  },
		  {
			"v": 108220,
			"vw": 21.8535,
			"o": 21.8518,
			"c": 21.8521,
			"h": 21.8571,
			"l": 21.85,
			"t": 1343997780000,
			"n": 23
		  },
		  {
			"v": 68712,
			"vw": 21.8515,
			"o": 21.8525,
			"c": 21.8482,
			"h": 21.8525,
			"l": 21.8482,
			"t": 1343997840000,
			"n": 10
		  },
		  {
			"v": 49840,
			"vw": 21.8504,
			"o": 21.8482,
			"c": 21.8504,
			"h": 21.8579,
			"l": 21.8479,
			"t": 1343997900000,
			"n": 16
		  },
		  {
			"v": 56896,
			"vw": 21.8514,
			"o": 21.8529,
			"c": 21.8482,
			"h": 21.8532,
			"l": 21.8482,
			"t": 1343997960000,
			"n": 12
		  },
		  {
			"v": 50680,
			"vw": 21.8506,
			"o": 21.8518,
			"c": 21.8532,
			"h": 21.8532,
			"l": 21.8482,
			"t": 1343998020000,
			"n": 13
		  },
		  {
			"v": 11200,
			"vw": 21.853,
			"o": 21.8532,
			"c": 21.8539,
			"h": 21.8539,
			"l": 21.8525,
			"t": 1343998080000,
			"n": 4
		  },
		  {
			"v": 167720,
			"vw": 21.8479,
			"o": 21.8518,
			"c": 21.8457,
			"h": 21.8518,
			"l": 21.8457,
			"t": 1343998140000,
			"n": 30
		  },
		  {
			"v": 58800,
			"vw": 21.8482,
			"o": 21.8479,
			"c": 21.8454,
			"h": 21.8525,
			"l": 21.8418,
			"t": 1343998200000,
			"n": 19
		  },
		  {
			"v": 174720,
			"vw": 21.8565,
			"o": 21.8457,
			"c": 21.8643,
			"h": 21.8643,
			"l": 21.8457,
			"t": 1343998260000,
			"n": 37
		  },
		  {
			"v": 117432,
			"vw": 21.8625,
			"o": 21.8618,
			"c": 21.8668,
			"h": 21.8696,
			"l": 21.8571,
			"t": 1343998320000,
			"n": 26
		  },
		  {
			"v": 33600,
			"vw": 21.8704,
			"o": 21.8711,
			"c": 21.8746,
			"h": 21.8746,
			"l": 21.8668,
			"t": 1343998380000,
			"n": 12
		  },
		  {
			"v": 87472,
			"vw": 21.8747,
			"o": 21.8739,
			"c": 21.8732,
			"h": 21.8782,
			"l": 21.8725,
			"t": 1343998440000,
			"n": 23
		  },
		  {
			"v": 82824,
			"vw": 21.8708,
			"o": 21.8729,
			"c": 21.8725,
			"h": 21.8732,
			"l": 21.8679,
			"t": 1343998500000,
			"n": 18
		  },
		  {
			"v": 5600,
			"vw": 21.8693,
			"o": 21.8693,
			"c": 21.8693,
			"h": 21.8693,
			"l": 21.8693,
			"t": 1343998560000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.8693,
			"o": 21.8693,
			"c": 21.8693,
			"h": 21.8693,
			"l": 21.8693,
			"t": 1343998620000,
			"n": 1
		  },
		  {
			"v": 6300,
			"vw": 21.8656,
			"o": 21.8704,
			"c": 21.8618,
			"h": 21.8704,
			"l": 21.8618,
			"t": 1343998680000,
			"n": 2
		  },
		  {
			"v": 27132,
			"vw": 21.8639,
			"o": 21.8629,
			"c": 21.8707,
			"h": 21.8707,
			"l": 21.86,
			"t": 1343998740000,
			"n": 9
		  },
		  {
			"v": 108332,
			"vw": 21.8718,
			"o": 21.8679,
			"c": 21.8732,
			"h": 21.8732,
			"l": 21.8664,
			"t": 1343998800000,
			"n": 21
		  },
		  {
			"v": 16940,
			"vw": 21.8732,
			"o": 21.875,
			"c": 21.8714,
			"h": 21.8786,
			"l": 21.8714,
			"t": 1343998860000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 21.8754,
			"o": 21.8754,
			"c": 21.875,
			"h": 21.8761,
			"l": 21.875,
			"t": 1343998980000,
			"n": 5
		  },
		  {
			"v": 18424,
			"vw": 21.879,
			"o": 21.8793,
			"c": 21.875,
			"h": 21.88,
			"l": 21.875,
			"t": 1343999040000,
			"n": 6
		  },
		  {
			"v": 77812,
			"vw": 21.8793,
			"o": 21.875,
			"c": 21.8821,
			"h": 21.8821,
			"l": 21.875,
			"t": 1343999100000,
			"n": 21
		  },
		  {
			"v": 59080,
			"vw": 21.8823,
			"o": 21.8761,
			"c": 21.8839,
			"h": 21.8843,
			"l": 21.875,
			"t": 1343999160000,
			"n": 18
		  },
		  {
			"v": 58660,
			"vw": 21.8874,
			"o": 21.8843,
			"c": 21.8914,
			"h": 21.8914,
			"l": 21.8839,
			"t": 1343999220000,
			"n": 18
		  },
		  {
			"v": 54432,
			"vw": 21.8924,
			"o": 21.8925,
			"c": 21.8929,
			"h": 21.8929,
			"l": 21.8893,
			"t": 1343999280000,
			"n": 12
		  },
		  {
			"v": 61600,
			"vw": 21.8911,
			"o": 21.8921,
			"c": 21.8918,
			"h": 21.8929,
			"l": 21.8875,
			"t": 1343999340000,
			"n": 15
		  },
		  {
			"v": 98896,
			"vw": 21.8916,
			"o": 21.8918,
			"c": 21.8929,
			"h": 21.8943,
			"l": 21.8864,
			"t": 1343999400000,
			"n": 20
		  },
		  {
			"v": 33600,
			"vw": 21.8939,
			"o": 21.8929,
			"c": 21.8932,
			"h": 21.8971,
			"l": 21.8907,
			"t": 1343999460000,
			"n": 12
		  },
		  {
			"v": 340116,
			"vw": 21.8892,
			"o": 21.8929,
			"c": 21.8857,
			"h": 21.8975,
			"l": 21.8714,
			"t": 1343999520000,
			"n": 35
		  },
		  {
			"v": 33600,
			"vw": 21.8799,
			"o": 21.8789,
			"c": 21.8839,
			"h": 21.8839,
			"l": 21.8754,
			"t": 1343999580000,
			"n": 12
		  },
		  {
			"v": 33880,
			"vw": 21.8846,
			"o": 21.8846,
			"c": 21.8839,
			"h": 21.8893,
			"l": 21.8839,
			"t": 1343999640000,
			"n": 10
		  },
		  {
			"v": 11200,
			"vw": 21.8859,
			"o": 21.8832,
			"c": 21.8918,
			"h": 21.8918,
			"l": 21.8832,
			"t": 1343999700000,
			"n": 4
		  },
		  {
			"v": 182560,
			"vw": 21.8902,
			"o": 21.8864,
			"c": 21.8936,
			"h": 21.8982,
			"l": 21.8839,
			"t": 1343999760000,
			"n": 40
		  },
		  {
			"v": 35504,
			"vw": 21.8938,
			"o": 21.8936,
			"c": 21.8964,
			"h": 21.8964,
			"l": 21.8929,
			"t": 1343999820000,
			"n": 5
		  },
		  {
			"v": 156212,
			"vw": 21.9012,
			"o": 21.8964,
			"c": 21.9036,
			"h": 21.9036,
			"l": 21.8964,
			"t": 1343999880000,
			"n": 20
		  },
		  {
			"v": 143528,
			"vw": 21.897,
			"o": 21.8975,
			"c": 21.8936,
			"h": 21.9096,
			"l": 21.8929,
			"t": 1343999940000,
			"n": 37
		  },
		  {
			"v": 14000,
			"vw": 21.8929,
			"o": 21.8929,
			"c": 21.8929,
			"h": 21.8929,
			"l": 21.8929,
			"t": 1344000000000,
			"n": 5
		  },
		  {
			"v": 218120,
			"vw": 21.8936,
			"o": 21.8929,
			"c": 21.8986,
			"h": 21.8986,
			"l": 21.8929,
			"t": 1344000060000,
			"n": 32
		  },
		  {
			"v": 27468,
			"vw": 21.9028,
			"o": 21.8989,
			"c": 21.9,
			"h": 21.9064,
			"l": 21.8989,
			"t": 1344000120000,
			"n": 8
		  },
		  {
			"v": 41356,
			"vw": 21.904,
			"o": 21.9029,
			"c": 21.9068,
			"h": 21.9082,
			"l": 21.9029,
			"t": 1344000180000,
			"n": 8
		  },
		  {
			"v": 89908,
			"vw": 21.9088,
			"o": 21.9089,
			"c": 21.9071,
			"h": 21.9107,
			"l": 21.9071,
			"t": 1344000240000,
			"n": 13
		  },
		  {
			"v": 14280,
			"vw": 21.905,
			"o": 21.905,
			"c": 21.905,
			"h": 21.905,
			"l": 21.905,
			"t": 1344000300000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 21.9099,
			"o": 21.9089,
			"c": 21.9107,
			"h": 21.9107,
			"l": 21.9089,
			"t": 1344000360000,
			"n": 4
		  },
		  {
			"v": 58940,
			"vw": 21.9118,
			"o": 21.9071,
			"c": 21.9107,
			"h": 21.9182,
			"l": 21.905,
			"t": 1344000420000,
			"n": 16
		  },
		  {
			"v": 398104,
			"vw": 21.9301,
			"o": 21.92,
			"c": 21.9386,
			"h": 21.9421,
			"l": 21.9107,
			"t": 1344000480000,
			"n": 64
		  },
		  {
			"v": 71344,
			"vw": 21.9289,
			"o": 21.9386,
			"c": 21.9186,
			"h": 21.9386,
			"l": 21.9186,
			"t": 1344000540000,
			"n": 23
		  },
		  {
			"v": 6507536,
			"vw": 21.9284,
			"o": 21.9154,
			"c": 21.9111,
			"h": 21.9414,
			"l": 21.9107,
			"t": 1344000600000,
			"n": 814
		  },
		  {
			"v": 4339860,
			"vw": 21.9148,
			"o": 21.9182,
			"c": 21.9029,
			"h": 21.9382,
			"l": 21.8929,
			"t": 1344000660000,
			"n": 992
		  },
		  {
			"v": 1901732,
			"vw": 21.9074,
			"o": 21.9007,
			"c": 21.9171,
			"h": 21.9211,
			"l": 21.8932,
			"t": 1344000720000,
			"n": 453
		  },
		  {
			"v": 1820420,
			"vw": 21.9133,
			"o": 21.9203,
			"c": 21.9089,
			"h": 21.9246,
			"l": 21.9018,
			"t": 1344000780000,
			"n": 422
		  },
		  {
			"v": 1890420,
			"vw": 21.8991,
			"o": 21.9085,
			"c": 21.8923,
			"h": 21.9085,
			"l": 21.8893,
			"t": 1344000840000,
			"n": 367
		  },
		  {
			"v": 2171708,
			"vw": 21.8793,
			"o": 21.8896,
			"c": 21.8654,
			"h": 21.8924,
			"l": 21.8643,
			"t": 1344000900000,
			"n": 494
		  },
		  {
			"v": 2244620,
			"vw": 21.8595,
			"o": 21.8632,
			"c": 21.849,
			"h": 21.8707,
			"l": 21.849,
			"t": 1344000960000,
			"n": 484
		  },
		  {
			"v": 2567712,
			"vw": 21.8549,
			"o": 21.8489,
			"c": 21.8571,
			"h": 21.865,
			"l": 21.8414,
			"t": 1344001020000,
			"n": 539
		  },
		  {
			"v": 1458296,
			"vw": 21.8616,
			"o": 21.8614,
			"c": 21.8579,
			"h": 21.8682,
			"l": 21.8557,
			"t": 1344001080000,
			"n": 269
		  },
		  {
			"v": 1446116,
			"vw": 21.8697,
			"o": 21.8571,
			"c": 21.8839,
			"h": 21.8839,
			"l": 21.8571,
			"t": 1344001140000,
			"n": 343
		  },
		  {
			"v": 1634276,
			"vw": 21.8906,
			"o": 21.8839,
			"c": 21.8968,
			"h": 21.8996,
			"l": 21.8768,
			"t": 1344001200000,
			"n": 388
		  },
		  {
			"v": 2463608,
			"vw": 21.9103,
			"o": 21.895,
			"c": 21.9218,
			"h": 21.9261,
			"l": 21.8857,
			"t": 1344001260000,
			"n": 510
		  },
		  {
			"v": 2055004,
			"vw": 21.9263,
			"o": 21.9218,
			"c": 21.9251,
			"h": 21.9311,
			"l": 21.9179,
			"t": 1344001320000,
			"n": 461
		  },
		  {
			"v": 2536688,
			"vw": 21.9296,
			"o": 21.9236,
			"c": 21.9286,
			"h": 21.9411,
			"l": 21.9157,
			"t": 1344001380000,
			"n": 566
		  },
		  {
			"v": 3033856,
			"vw": 21.9479,
			"o": 21.9346,
			"c": 21.9539,
			"h": 21.9589,
			"l": 21.9296,
			"t": 1344001440000,
			"n": 643
		  },
		  {
			"v": 5413548,
			"vw": 21.9714,
			"o": 21.9548,
			"c": 21.9832,
			"h": 22,
			"l": 21.9518,
			"t": 1344001500000,
			"n": 1120
		  },
		  {
			"v": 2859752,
			"vw": 21.993,
			"o": 21.9845,
			"c": 21.9961,
			"h": 22,
			"l": 21.9825,
			"t": 1344001560000,
			"n": 624
		  },
		  {
			"v": 3417260,
			"vw": 21.9888,
			"o": 21.9957,
			"c": 21.9668,
			"h": 22,
			"l": 21.965,
			"t": 1344001620000,
			"n": 739
		  },
		  {
			"v": 2346932,
			"vw": 21.9695,
			"o": 21.9669,
			"c": 21.9618,
			"h": 21.9836,
			"l": 21.9596,
			"t": 1344001680000,
			"n": 561
		  },
		  {
			"v": 1633716,
			"vw": 21.9775,
			"o": 21.9638,
			"c": 21.98,
			"h": 21.9852,
			"l": 21.9638,
			"t": 1344001740000,
			"n": 414
		  },
		  {
			"v": 1334424,
			"vw": 21.9655,
			"o": 21.9793,
			"c": 21.9537,
			"h": 21.9807,
			"l": 21.9482,
			"t": 1344001800000,
			"n": 324
		  },
		  {
			"v": 1805440,
			"vw": 21.9565,
			"o": 21.9543,
			"c": 21.9596,
			"h": 21.9671,
			"l": 21.9393,
			"t": 1344001860000,
			"n": 369
		  },
		  {
			"v": 1583680,
			"vw": 21.962,
			"o": 21.9582,
			"c": 21.9629,
			"h": 21.9714,
			"l": 21.955,
			"t": 1344001920000,
			"n": 355
		  },
		  {
			"v": 1373876,
			"vw": 21.9646,
			"o": 21.9593,
			"c": 21.9723,
			"h": 21.975,
			"l": 21.955,
			"t": 1344001980000,
			"n": 296
		  },
		  {
			"v": 1644300,
			"vw": 21.9772,
			"o": 21.9719,
			"c": 21.9796,
			"h": 21.9839,
			"l": 21.965,
			"t": 1344002040000,
			"n": 394
		  },
		  {
			"v": 1283772,
			"vw": 21.9793,
			"o": 21.98,
			"c": 21.9832,
			"h": 21.9864,
			"l": 21.9711,
			"t": 1344002100000,
			"n": 279
		  },
		  {
			"v": 1894760,
			"vw": 21.9804,
			"o": 21.9804,
			"c": 21.9893,
			"h": 21.9932,
			"l": 21.9682,
			"t": 1344002160000,
			"n": 487
		  },
		  {
			"v": 1159368,
			"vw": 21.9911,
			"o": 21.9925,
			"c": 21.9957,
			"h": 21.9986,
			"l": 21.9779,
			"t": 1344002220000,
			"n": 272
		  },
		  {
			"v": 2164092,
			"vw": 21.9921,
			"o": 21.9957,
			"c": 21.9861,
			"h": 22.005,
			"l": 21.9736,
			"t": 1344002280000,
			"n": 491
		  },
		  {
			"v": 1061984,
			"vw": 21.9829,
			"o": 21.9868,
			"c": 21.9811,
			"h": 21.9929,
			"l": 21.9743,
			"t": 1344002340000,
			"n": 230
		  },
		  {
			"v": 1446816,
			"vw": 21.9883,
			"o": 21.9821,
			"c": 21.9875,
			"h": 21.995,
			"l": 21.9779,
			"t": 1344002400000,
			"n": 376
		  },
		  {
			"v": 1028188,
			"vw": 21.9932,
			"o": 21.9879,
			"c": 21.9889,
			"h": 21.9996,
			"l": 21.9864,
			"t": 1344002460000,
			"n": 262
		  },
		  {
			"v": 1153292,
			"vw": 21.9795,
			"o": 21.9946,
			"c": 21.975,
			"h": 21.995,
			"l": 21.9697,
			"t": 1344002520000,
			"n": 278
		  },
		  {
			"v": 1097852,
			"vw": 21.9742,
			"o": 21.9739,
			"c": 21.9729,
			"h": 21.9832,
			"l": 21.965,
			"t": 1344002580000,
			"n": 230
		  },
		  {
			"v": 572516,
			"vw": 21.9747,
			"o": 21.9743,
			"c": 21.9721,
			"h": 21.98,
			"l": 21.9707,
			"t": 1344002640000,
			"n": 154
		  },
		  {
			"v": 500696,
			"vw": 21.9773,
			"o": 21.9771,
			"c": 21.9771,
			"h": 21.9818,
			"l": 21.9725,
			"t": 1344002700000,
			"n": 126
		  },
		  {
			"v": 720440,
			"vw": 21.9711,
			"o": 21.9743,
			"c": 21.9661,
			"h": 21.98,
			"l": 21.9654,
			"t": 1344002760000,
			"n": 168
		  },
		  {
			"v": 2433872,
			"vw": 21.968,
			"o": 21.9654,
			"c": 21.9711,
			"h": 21.9818,
			"l": 21.9571,
			"t": 1344002820000,
			"n": 578
		  },
		  {
			"v": 702828,
			"vw": 21.9704,
			"o": 21.9736,
			"c": 21.9736,
			"h": 21.9779,
			"l": 21.9643,
			"t": 1344002880000,
			"n": 175
		  },
		  {
			"v": 1021300,
			"vw": 21.9694,
			"o": 21.9739,
			"c": 21.965,
			"h": 21.9757,
			"l": 21.9614,
			"t": 1344002940000,
			"n": 169
		  },
		  {
			"v": 2075696,
			"vw": 21.9587,
			"o": 21.965,
			"c": 21.9636,
			"h": 21.9714,
			"l": 21.9532,
			"t": 1344003000000,
			"n": 428
		  },
		  {
			"v": 1203356,
			"vw": 21.9474,
			"o": 21.9589,
			"c": 21.9348,
			"h": 21.9596,
			"l": 21.9332,
			"t": 1344003060000,
			"n": 269
		  },
		  {
			"v": 2559396,
			"vw": 21.9305,
			"o": 21.9325,
			"c": 21.9357,
			"h": 21.9434,
			"l": 21.9175,
			"t": 1344003120000,
			"n": 647
		  },
		  {
			"v": 1255072,
			"vw": 21.9412,
			"o": 21.9393,
			"c": 21.95,
			"h": 21.9518,
			"l": 21.9339,
			"t": 1344003180000,
			"n": 328
		  },
		  {
			"v": 912576,
			"vw": 21.9526,
			"o": 21.9475,
			"c": 21.9579,
			"h": 21.9643,
			"l": 21.9454,
			"t": 1344003240000,
			"n": 206
		  },
		  {
			"v": 816508,
			"vw": 21.9593,
			"o": 21.9604,
			"c": 21.9482,
			"h": 21.9643,
			"l": 21.9482,
			"t": 1344003300000,
			"n": 200
		  },
		  {
			"v": 1080044,
			"vw": 21.9454,
			"o": 21.9479,
			"c": 21.9447,
			"h": 21.9536,
			"l": 21.9361,
			"t": 1344003360000,
			"n": 253
		  },
		  {
			"v": 1019956,
			"vw": 21.9375,
			"o": 21.9461,
			"c": 21.9339,
			"h": 21.9482,
			"l": 21.9296,
			"t": 1344003420000,
			"n": 256
		  },
		  {
			"v": 629664,
			"vw": 21.936,
			"o": 21.9336,
			"c": 21.9439,
			"h": 21.9464,
			"l": 21.9286,
			"t": 1344003480000,
			"n": 153
		  },
		  {
			"v": 417340,
			"vw": 21.939,
			"o": 21.9432,
			"c": 21.9321,
			"h": 21.9454,
			"l": 21.9321,
			"t": 1344003540000,
			"n": 108
		  },
		  {
			"v": 871052,
			"vw": 21.9399,
			"o": 21.9354,
			"c": 21.9379,
			"h": 21.9482,
			"l": 21.9289,
			"t": 1344003600000,
			"n": 236
		  },
		  {
			"v": 328832,
			"vw": 21.9429,
			"o": 21.9379,
			"c": 21.9425,
			"h": 21.9464,
			"l": 21.9354,
			"t": 1344003660000,
			"n": 83
		  },
		  {
			"v": 418712,
			"vw": 21.9429,
			"o": 21.9404,
			"c": 21.9436,
			"h": 21.9464,
			"l": 21.9379,
			"t": 1344003720000,
			"n": 116
		  },
		  {
			"v": 1188208,
			"vw": 21.9528,
			"o": 21.9436,
			"c": 21.9514,
			"h": 21.9621,
			"l": 21.9432,
			"t": 1344003780000,
			"n": 266
		  },
		  {
			"v": 560952,
			"vw": 21.958,
			"o": 21.9514,
			"c": 21.9607,
			"h": 21.9621,
			"l": 21.9514,
			"t": 1344003840000,
			"n": 137
		  },
		  {
			"v": 463064,
			"vw": 21.9612,
			"o": 21.9607,
			"c": 21.9643,
			"h": 21.9643,
			"l": 21.9564,
			"t": 1344003900000,
			"n": 97
		  },
		  {
			"v": 1380064,
			"vw": 21.9698,
			"o": 21.9643,
			"c": 21.975,
			"h": 21.98,
			"l": 21.9633,
			"t": 1344003960000,
			"n": 290
		  },
		  {
			"v": 831320,
			"vw": 21.9736,
			"o": 21.9754,
			"c": 21.9754,
			"h": 21.9789,
			"l": 21.9654,
			"t": 1344004020000,
			"n": 196
		  },
		  {
			"v": 535948,
			"vw": 21.9771,
			"o": 21.9776,
			"c": 21.9768,
			"h": 21.9818,
			"l": 21.9718,
			"t": 1344004080000,
			"n": 149
		  },
		  {
			"v": 1400028,
			"vw": 21.9842,
			"o": 21.9762,
			"c": 21.9893,
			"h": 21.99,
			"l": 21.9739,
			"t": 1344004140000,
			"n": 311
		  },
		  {
			"v": 1113252,
			"vw": 21.9912,
			"o": 21.9896,
			"c": 21.9911,
			"h": 21.9964,
			"l": 21.9854,
			"t": 1344004200000,
			"n": 226
		  },
		  {
			"v": 902692,
			"vw": 21.9926,
			"o": 21.9911,
			"c": 21.9936,
			"h": 21.9954,
			"l": 21.9893,
			"t": 1344004260000,
			"n": 205
		  },
		  {
			"v": 996520,
			"vw": 21.9883,
			"o": 21.9925,
			"c": 21.9818,
			"h": 21.9939,
			"l": 21.9804,
			"t": 1344004320000,
			"n": 221
		  },
		  {
			"v": 673960,
			"vw": 21.9837,
			"o": 21.9811,
			"c": 21.9825,
			"h": 21.9911,
			"l": 21.9793,
			"t": 1344004380000,
			"n": 149
		  },
		  {
			"v": 337764,
			"vw": 21.981,
			"o": 21.9843,
			"c": 21.9779,
			"h": 21.9846,
			"l": 21.9775,
			"t": 1344004440000,
			"n": 98
		  },
		  {
			"v": 634340,
			"vw": 21.9724,
			"o": 21.9807,
			"c": 21.9725,
			"h": 21.9807,
			"l": 21.9675,
			"t": 1344004500000,
			"n": 184
		  },
		  {
			"v": 1095668,
			"vw": 21.9624,
			"o": 21.9725,
			"c": 21.9618,
			"h": 21.9725,
			"l": 21.9569,
			"t": 1344004560000,
			"n": 235
		  },
		  {
			"v": 495684,
			"vw": 21.968,
			"o": 21.9614,
			"c": 21.9677,
			"h": 21.9732,
			"l": 21.961,
			"t": 1344004620000,
			"n": 122
		  },
		  {
			"v": 511252,
			"vw": 21.9759,
			"o": 21.9689,
			"c": 21.979,
			"h": 21.9796,
			"l": 21.9689,
			"t": 1344004680000,
			"n": 142
		  },
		  {
			"v": 254968,
			"vw": 21.9768,
			"o": 21.9757,
			"c": 21.9754,
			"h": 21.98,
			"l": 21.9721,
			"t": 1344004740000,
			"n": 61
		  },
		  {
			"v": 488824,
			"vw": 21.9725,
			"o": 21.9757,
			"c": 21.9743,
			"h": 21.9757,
			"l": 21.9689,
			"t": 1344004800000,
			"n": 123
		  },
		  {
			"v": 1001560,
			"vw": 21.9806,
			"o": 21.9722,
			"c": 21.9832,
			"h": 21.9857,
			"l": 21.9711,
			"t": 1344004860000,
			"n": 219
		  },
		  {
			"v": 927276,
			"vw": 21.9816,
			"o": 21.9868,
			"c": 21.9782,
			"h": 21.9879,
			"l": 21.9729,
			"t": 1344004920000,
			"n": 224
		  },
		  {
			"v": 329952,
			"vw": 21.9825,
			"o": 21.9789,
			"c": 21.9843,
			"h": 21.985,
			"l": 21.9775,
			"t": 1344004980000,
			"n": 78
		  },
		  {
			"v": 541352,
			"vw": 21.9799,
			"o": 21.9796,
			"c": 21.9846,
			"h": 21.985,
			"l": 21.9732,
			"t": 1344005040000,
			"n": 115
		  },
		  {
			"v": 1199240,
			"vw": 21.9828,
			"o": 21.985,
			"c": 21.9694,
			"h": 21.9925,
			"l": 21.9689,
			"t": 1344005100000,
			"n": 232
		  },
		  {
			"v": 413952,
			"vw": 21.9759,
			"o": 21.975,
			"c": 21.9836,
			"h": 21.9836,
			"l": 21.9693,
			"t": 1344005160000,
			"n": 98
		  },
		  {
			"v": 600488,
			"vw": 21.9801,
			"o": 21.9835,
			"c": 21.9839,
			"h": 21.985,
			"l": 21.9743,
			"t": 1344005220000,
			"n": 125
		  },
		  {
			"v": 542388,
			"vw": 21.9859,
			"o": 21.9838,
			"c": 21.9845,
			"h": 21.9904,
			"l": 21.9807,
			"t": 1344005280000,
			"n": 128
		  },
		  {
			"v": 752556,
			"vw": 21.989,
			"o": 21.985,
			"c": 21.9911,
			"h": 21.9929,
			"l": 21.9833,
			"t": 1344005340000,
			"n": 181
		  },
		  {
			"v": 503300,
			"vw": 21.9881,
			"o": 21.9918,
			"c": 21.9879,
			"h": 21.9929,
			"l": 21.9829,
			"t": 1344005400000,
			"n": 114
		  },
		  {
			"v": 1476552,
			"vw": 21.9944,
			"o": 21.9886,
			"c": 21.9964,
			"h": 21.9996,
			"l": 21.9854,
			"t": 1344005460000,
			"n": 356
		  },
		  {
			"v": 1934604,
			"vw": 21.9985,
			"o": 21.9946,
			"c": 21.9996,
			"h": 22.0029,
			"l": 21.9904,
			"t": 1344005520000,
			"n": 452
		  },
		  {
			"v": 1497300,
			"vw": 21.9993,
			"o": 21.9979,
			"c": 21.9985,
			"h": 22.0039,
			"l": 21.9946,
			"t": 1344005580000,
			"n": 354
		  },
		  {
			"v": 1278200,
			"vw": 22.0031,
			"o": 21.9982,
			"c": 22.0089,
			"h": 22.0093,
			"l": 21.9964,
			"t": 1344005640000,
			"n": 298
		  },
		  {
			"v": 3208996,
			"vw": 22.0194,
			"o": 22.0089,
			"c": 22.029,
			"h": 22.0321,
			"l": 22.0082,
			"t": 1344005700000,
			"n": 709
		  },
		  {
			"v": 2329012,
			"vw": 22.0339,
			"o": 22.0311,
			"c": 22.0311,
			"h": 22.0393,
			"l": 22.0254,
			"t": 1344005760000,
			"n": 573
		  },
		  {
			"v": 1365112,
			"vw": 22.0358,
			"o": 22.035,
			"c": 22.0389,
			"h": 22.04,
			"l": 22.0286,
			"t": 1344005820000,
			"n": 308
		  },
		  {
			"v": 1361108,
			"vw": 22.0439,
			"o": 22.0389,
			"c": 22.0489,
			"h": 22.0489,
			"l": 22.0386,
			"t": 1344005880000,
			"n": 329
		  },
		  {
			"v": 1508444,
			"vw": 22.0386,
			"o": 22.0489,
			"c": 22.035,
			"h": 22.0489,
			"l": 22.0293,
			"t": 1344005940000,
			"n": 398
		  },
		  {
			"v": 486780,
			"vw": 22.035,
			"o": 22.0354,
			"c": 22.0326,
			"h": 22.0378,
			"l": 22.0326,
			"t": 1344006000000,
			"n": 120
		  },
		  {
			"v": 695940,
			"vw": 22.0336,
			"o": 22.0325,
			"c": 22.0336,
			"h": 22.0357,
			"l": 22.03,
			"t": 1344006060000,
			"n": 178
		  },
		  {
			"v": 975072,
			"vw": 22.0332,
			"o": 22.0332,
			"c": 22.0354,
			"h": 22.0382,
			"l": 22.0296,
			"t": 1344006120000,
			"n": 231
		  },
		  {
			"v": 933800,
			"vw": 22.0416,
			"o": 22.0354,
			"c": 22.0461,
			"h": 22.0468,
			"l": 22.0354,
			"t": 1344006180000,
			"n": 257
		  },
		  {
			"v": 1402660,
			"vw": 22.0463,
			"o": 22.0461,
			"c": 22.0532,
			"h": 22.0532,
			"l": 22.0389,
			"t": 1344006240000,
			"n": 337
		  },
		  {
			"v": 1972460,
			"vw": 22.0536,
			"o": 22.0525,
			"c": 22.0543,
			"h": 22.0571,
			"l": 22.0483,
			"t": 1344006300000,
			"n": 414
		  },
		  {
			"v": 1414700,
			"vw": 22.0497,
			"o": 22.0554,
			"c": 22.0429,
			"h": 22.0586,
			"l": 22.0407,
			"t": 1344006360000,
			"n": 288
		  },
		  {
			"v": 985348,
			"vw": 22.0402,
			"o": 22.0443,
			"c": 22.0386,
			"h": 22.0468,
			"l": 22.0361,
			"t": 1344006420000,
			"n": 255
		  },
		  {
			"v": 799204,
			"vw": 22.0397,
			"o": 22.0364,
			"c": 22.0477,
			"h": 22.0496,
			"l": 22.0332,
			"t": 1344006480000,
			"n": 163
		  },
		  {
			"v": 722288,
			"vw": 22.0532,
			"o": 22.0496,
			"c": 22.0511,
			"h": 22.0578,
			"l": 22.0464,
			"t": 1344006540000,
			"n": 170
		  },
		  {
			"v": 553532,
			"vw": 22.0459,
			"o": 22.0507,
			"c": 22.0419,
			"h": 22.0521,
			"l": 22.0404,
			"t": 1344006600000,
			"n": 130
		  },
		  {
			"v": 546700,
			"vw": 22.0514,
			"o": 22.0446,
			"c": 22.0555,
			"h": 22.0579,
			"l": 22.0438,
			"t": 1344006660000,
			"n": 123
		  },
		  {
			"v": 1688260,
			"vw": 22.06,
			"o": 22.0554,
			"c": 22.0557,
			"h": 22.0657,
			"l": 22.0532,
			"t": 1344006720000,
			"n": 402
		  },
		  {
			"v": 899808,
			"vw": 22.056,
			"o": 22.055,
			"c": 22.0575,
			"h": 22.0629,
			"l": 22.0496,
			"t": 1344006780000,
			"n": 239
		  },
		  {
			"v": 874664,
			"vw": 22.0632,
			"o": 22.0592,
			"c": 22.0629,
			"h": 22.0664,
			"l": 22.0573,
			"t": 1344006840000,
			"n": 211
		  },
		  {
			"v": 827512,
			"vw": 22.0591,
			"o": 22.0611,
			"c": 22.0582,
			"h": 22.0661,
			"l": 22.0536,
			"t": 1344006900000,
			"n": 186
		  },
		  {
			"v": 488880,
			"vw": 22.0548,
			"o": 22.0557,
			"c": 22.05,
			"h": 22.0593,
			"l": 22.05,
			"t": 1344006960000,
			"n": 115
		  },
		  {
			"v": 996492,
			"vw": 22.0471,
			"o": 22.0525,
			"c": 22.0489,
			"h": 22.0536,
			"l": 22.0432,
			"t": 1344007020000,
			"n": 232
		  },
		  {
			"v": 697676,
			"vw": 22.0454,
			"o": 22.0464,
			"c": 22.0404,
			"h": 22.0496,
			"l": 22.0404,
			"t": 1344007080000,
			"n": 176
		  },
		  {
			"v": 854924,
			"vw": 22.0405,
			"o": 22.0411,
			"c": 22.0386,
			"h": 22.0468,
			"l": 22.0357,
			"t": 1344007140000,
			"n": 171
		  },
		  {
			"v": 508088,
			"vw": 22.0442,
			"o": 22.0393,
			"c": 22.0464,
			"h": 22.0504,
			"l": 22.0393,
			"t": 1344007200000,
			"n": 137
		  },
		  {
			"v": 534604,
			"vw": 22.048,
			"o": 22.0486,
			"c": 22.0486,
			"h": 22.0532,
			"l": 22.0425,
			"t": 1344007260000,
			"n": 145
		  },
		  {
			"v": 454972,
			"vw": 22.0525,
			"o": 22.05,
			"c": 22.0604,
			"h": 22.0604,
			"l": 22.0479,
			"t": 1344007320000,
			"n": 114
		  },
		  {
			"v": 948696,
			"vw": 22.0629,
			"o": 22.0582,
			"c": 22.0611,
			"h": 22.0654,
			"l": 22.0582,
			"t": 1344007380000,
			"n": 185
		  },
		  {
			"v": 559188,
			"vw": 22.0615,
			"o": 22.0611,
			"c": 22.0604,
			"h": 22.0639,
			"l": 22.0575,
			"t": 1344007440000,
			"n": 129
		  },
		  {
			"v": 1597960,
			"vw": 22.0675,
			"o": 22.0621,
			"c": 22.0705,
			"h": 22.0707,
			"l": 22.06,
			"t": 1344007500000,
			"n": 308
		  },
		  {
			"v": 596120,
			"vw": 22.0661,
			"o": 22.0696,
			"c": 22.0661,
			"h": 22.07,
			"l": 22.0632,
			"t": 1344007560000,
			"n": 152
		  },
		  {
			"v": 556136,
			"vw": 22.0643,
			"o": 22.0665,
			"c": 22.0657,
			"h": 22.0668,
			"l": 22.0614,
			"t": 1344007620000,
			"n": 145
		  },
		  {
			"v": 1182244,
			"vw": 22.0602,
			"o": 22.0632,
			"c": 22.0574,
			"h": 22.0632,
			"l": 22.0546,
			"t": 1344007680000,
			"n": 217
		  },
		  {
			"v": 454048,
			"vw": 22.054,
			"o": 22.0571,
			"c": 22.0493,
			"h": 22.0577,
			"l": 22.0489,
			"t": 1344007740000,
			"n": 136
		  },
		  {
			"v": 612052,
			"vw": 22.0536,
			"o": 22.0493,
			"c": 22.0529,
			"h": 22.0561,
			"l": 22.0489,
			"t": 1344007800000,
			"n": 167
		  },
		  {
			"v": 422744,
			"vw": 22.0567,
			"o": 22.0521,
			"c": 22.0568,
			"h": 22.0604,
			"l": 22.0521,
			"t": 1344007860000,
			"n": 116
		  },
		  {
			"v": 392756,
			"vw": 22.0562,
			"o": 22.0557,
			"c": 22.0596,
			"h": 22.0618,
			"l": 22.0521,
			"t": 1344007920000,
			"n": 116
		  },
		  {
			"v": 454244,
			"vw": 22.0547,
			"o": 22.0596,
			"c": 22.0539,
			"h": 22.0596,
			"l": 22.0511,
			"t": 1344007980000,
			"n": 130
		  },
		  {
			"v": 514304,
			"vw": 22.0574,
			"o": 22.0529,
			"c": 22.0624,
			"h": 22.0624,
			"l": 22.0529,
			"t": 1344008040000,
			"n": 133
		  },
		  {
			"v": 611436,
			"vw": 22.0631,
			"o": 22.0625,
			"c": 22.0643,
			"h": 22.0671,
			"l": 22.0596,
			"t": 1344008100000,
			"n": 144
		  },
		  {
			"v": 422716,
			"vw": 22.0606,
			"o": 22.0632,
			"c": 22.0604,
			"h": 22.0632,
			"l": 22.0575,
			"t": 1344008160000,
			"n": 105
		  },
		  {
			"v": 345688,
			"vw": 22.0585,
			"o": 22.0602,
			"c": 22.0571,
			"h": 22.0625,
			"l": 22.0557,
			"t": 1344008220000,
			"n": 92
		  },
		  {
			"v": 785232,
			"vw": 22.0541,
			"o": 22.0569,
			"c": 22.0579,
			"h": 22.0604,
			"l": 22.0457,
			"t": 1344008280000,
			"n": 202
		  },
		  {
			"v": 629300,
			"vw": 22.0528,
			"o": 22.0579,
			"c": 22.0488,
			"h": 22.0586,
			"l": 22.0471,
			"t": 1344008340000,
			"n": 156
		  },
		  {
			"v": 528808,
			"vw": 22.0486,
			"o": 22.05,
			"c": 22.0484,
			"h": 22.0518,
			"l": 22.0457,
			"t": 1344008400000,
			"n": 146
		  },
		  {
			"v": 885052,
			"vw": 22.0427,
			"o": 22.0468,
			"c": 22.0448,
			"h": 22.0471,
			"l": 22.0386,
			"t": 1344008460000,
			"n": 237
		  },
		  {
			"v": 809564,
			"vw": 22.0392,
			"o": 22.0457,
			"c": 22.0375,
			"h": 22.0461,
			"l": 22.0357,
			"t": 1344008520000,
			"n": 188
		  },
		  {
			"v": 1705060,
			"vw": 22.0335,
			"o": 22.0375,
			"c": 22.0264,
			"h": 22.0411,
			"l": 22.0232,
			"t": 1344008580000,
			"n": 341
		  },
		  {
			"v": 1209936,
			"vw": 22.0335,
			"o": 22.0261,
			"c": 22.0343,
			"h": 22.0382,
			"l": 22.0244,
			"t": 1344008640000,
			"n": 289
		  },
		  {
			"v": 906220,
			"vw": 22.0273,
			"o": 22.0297,
			"c": 22.0211,
			"h": 22.0339,
			"l": 22.0204,
			"t": 1344008700000,
			"n": 200
		  },
		  {
			"v": 657412,
			"vw": 22.0217,
			"o": 22.0229,
			"c": 22.0194,
			"h": 22.0261,
			"l": 22.0186,
			"t": 1344008760000,
			"n": 164
		  },
		  {
			"v": 822304,
			"vw": 22.0206,
			"o": 22.0213,
			"c": 22.0261,
			"h": 22.0275,
			"l": 22.0179,
			"t": 1344008820000,
			"n": 136
		  },
		  {
			"v": 1256696,
			"vw": 22.0316,
			"o": 22.0264,
			"c": 22.0275,
			"h": 22.0382,
			"l": 22.0254,
			"t": 1344008880000,
			"n": 306
		  },
		  {
			"v": 637392,
			"vw": 22.0212,
			"o": 22.0271,
			"c": 22.0184,
			"h": 22.0307,
			"l": 22.0179,
			"t": 1344008940000,
			"n": 141
		  },
		  {
			"v": 1014524,
			"vw": 22.0146,
			"o": 22.0179,
			"c": 22.0146,
			"h": 22.0246,
			"l": 22.0079,
			"t": 1344009000000,
			"n": 258
		  },
		  {
			"v": 783328,
			"vw": 22.0129,
			"o": 22.0154,
			"c": 22.0093,
			"h": 22.0182,
			"l": 22.0054,
			"t": 1344009060000,
			"n": 175
		  },
		  {
			"v": 1879892,
			"vw": 21.9982,
			"o": 22.0054,
			"c": 21.9934,
			"h": 22.0111,
			"l": 21.9846,
			"t": 1344009120000,
			"n": 404
		  },
		  {
			"v": 1088612,
			"vw": 21.9976,
			"o": 21.9914,
			"c": 22.0021,
			"h": 22.0046,
			"l": 21.9907,
			"t": 1344009180000,
			"n": 249
		  },
		  {
			"v": 793912,
			"vw": 22.0025,
			"o": 22.0018,
			"c": 22.0086,
			"h": 22.0118,
			"l": 21.9964,
			"t": 1344009240000,
			"n": 198
		  },
		  {
			"v": 731612,
			"vw": 22.0111,
			"o": 22.0004,
			"c": 22.0137,
			"h": 22.0179,
			"l": 22.0004,
			"t": 1344009300000,
			"n": 156
		  },
		  {
			"v": 496664,
			"vw": 22.016,
			"o": 22.0108,
			"c": 22.0182,
			"h": 22.0211,
			"l": 22.0107,
			"t": 1344009360000,
			"n": 127
		  },
		  {
			"v": 751604,
			"vw": 22.0187,
			"o": 22.0182,
			"c": 22.0139,
			"h": 22.0214,
			"l": 22.0118,
			"t": 1344009420000,
			"n": 140
		  },
		  {
			"v": 2315992,
			"vw": 22.0237,
			"o": 22.0157,
			"c": 22.0175,
			"h": 22.025,
			"l": 22.0121,
			"t": 1344009480000,
			"n": 78
		  },
		  {
			"v": 595112,
			"vw": 22.0168,
			"o": 22.0175,
			"c": 22.0175,
			"h": 22.0193,
			"l": 22.0125,
			"t": 1344009540000,
			"n": 121
		  },
		  {
			"v": 850080,
			"vw": 22.0237,
			"o": 22.0144,
			"c": 22.0239,
			"h": 22.0318,
			"l": 22.0144,
			"t": 1344009600000,
			"n": 170
		  },
		  {
			"v": 464968,
			"vw": 22.0291,
			"o": 22.024,
			"c": 22.0286,
			"h": 22.0339,
			"l": 22.0239,
			"t": 1344009660000,
			"n": 102
		  },
		  {
			"v": 385476,
			"vw": 22.0287,
			"o": 22.0311,
			"c": 22.0243,
			"h": 22.0336,
			"l": 22.0233,
			"t": 1344009720000,
			"n": 88
		  },
		  {
			"v": 219324,
			"vw": 22.0233,
			"o": 22.0261,
			"c": 22.0201,
			"h": 22.0261,
			"l": 22.0182,
			"t": 1344009780000,
			"n": 57
		  },
		  {
			"v": 511616,
			"vw": 22.0167,
			"o": 22.0208,
			"c": 22.0178,
			"h": 22.0208,
			"l": 22.0114,
			"t": 1344009840000,
			"n": 113
		  },
		  {
			"v": 452984,
			"vw": 22.0128,
			"o": 22.0146,
			"c": 22.0075,
			"h": 22.0182,
			"l": 22.0075,
			"t": 1344009900000,
			"n": 116
		  },
		  {
			"v": 618828,
			"vw": 22.0081,
			"o": 22.0118,
			"c": 22.0084,
			"h": 22.0143,
			"l": 22.0043,
			"t": 1344009960000,
			"n": 148
		  },
		  {
			"v": 382284,
			"vw": 22.0102,
			"o": 22.0084,
			"c": 22.0111,
			"h": 22.0161,
			"l": 22.0075,
			"t": 1344010020000,
			"n": 100
		  },
		  {
			"v": 860244,
			"vw": 22.0034,
			"o": 22.0119,
			"c": 22.0007,
			"h": 22.0119,
			"l": 21.9993,
			"t": 1344010080000,
			"n": 171
		  },
		  {
			"v": 612192,
			"vw": 21.9968,
			"o": 22.0004,
			"c": 21.9893,
			"h": 22.0018,
			"l": 21.9893,
			"t": 1344010140000,
			"n": 131
		  },
		  {
			"v": 1067780,
			"vw": 21.9841,
			"o": 21.9896,
			"c": 21.9844,
			"h": 21.9957,
			"l": 21.9786,
			"t": 1344010200000,
			"n": 258
		  },
		  {
			"v": 537796,
			"vw": 21.9945,
			"o": 21.985,
			"c": 21.9999,
			"h": 22.0011,
			"l": 21.985,
			"t": 1344010260000,
			"n": 140
		  },
		  {
			"v": 592452,
			"vw": 21.9937,
			"o": 21.9979,
			"c": 21.99,
			"h": 21.9998,
			"l": 21.9871,
			"t": 1344010320000,
			"n": 103
		  },
		  {
			"v": 752500,
			"vw": 21.992,
			"o": 21.9904,
			"c": 21.9911,
			"h": 21.9979,
			"l": 21.9875,
			"t": 1344010380000,
			"n": 184
		  },
		  {
			"v": 418516,
			"vw": 21.995,
			"o": 21.9921,
			"c": 21.9968,
			"h": 22,
			"l": 21.9893,
			"t": 1344010440000,
			"n": 82
		  },
		  {
			"v": 239624,
			"vw": 21.9965,
			"o": 21.9979,
			"c": 21.9982,
			"h": 21.9993,
			"l": 21.9926,
			"t": 1344010500000,
			"n": 44
		  },
		  {
			"v": 380268,
			"vw": 21.9928,
			"o": 21.9974,
			"c": 21.9893,
			"h": 21.9996,
			"l": 21.9893,
			"t": 1344010560000,
			"n": 83
		  },
		  {
			"v": 386540,
			"vw": 21.987,
			"o": 21.99,
			"c": 21.9832,
			"h": 21.9928,
			"l": 21.9825,
			"t": 1344010620000,
			"n": 91
		  },
		  {
			"v": 2471308,
			"vw": 21.967,
			"o": 21.9836,
			"c": 21.9496,
			"h": 21.9843,
			"l": 21.9475,
			"t": 1344010680000,
			"n": 528
		  },
		  {
			"v": 813008,
			"vw": 21.9619,
			"o": 21.9536,
			"c": 21.9661,
			"h": 21.9714,
			"l": 21.9536,
			"t": 1344010740000,
			"n": 187
		  },
		  {
			"v": 542836,
			"vw": 21.9689,
			"o": 21.9664,
			"c": 21.9668,
			"h": 21.9736,
			"l": 21.9646,
			"t": 1344010800000,
			"n": 134
		  },
		  {
			"v": 634844,
			"vw": 21.9712,
			"o": 21.9677,
			"c": 21.9739,
			"h": 21.9768,
			"l": 21.9672,
			"t": 1344010860000,
			"n": 124
		  },
		  {
			"v": 216356,
			"vw": 21.9711,
			"o": 21.9732,
			"c": 21.9705,
			"h": 21.9732,
			"l": 21.9689,
			"t": 1344010920000,
			"n": 50
		  },
		  {
			"v": 606480,
			"vw": 21.9816,
			"o": 21.9707,
			"c": 21.9882,
			"h": 21.9889,
			"l": 21.9696,
			"t": 1344010980000,
			"n": 136
		  },
		  {
			"v": 478436,
			"vw": 21.9894,
			"o": 21.9893,
			"c": 21.9896,
			"h": 21.9939,
			"l": 21.9861,
			"t": 1344011040000,
			"n": 104
		  },
		  {
			"v": 145936,
			"vw": 21.9887,
			"o": 21.9893,
			"c": 21.9914,
			"h": 21.9917,
			"l": 21.9871,
			"t": 1344011100000,
			"n": 35
		  },
		  {
			"v": 400232,
			"vw": 21.9935,
			"o": 21.9929,
			"c": 21.9954,
			"h": 21.9961,
			"l": 21.9911,
			"t": 1344011160000,
			"n": 88
		  },
		  {
			"v": 547148,
			"vw": 21.9955,
			"o": 21.9961,
			"c": 21.9964,
			"h": 21.9964,
			"l": 21.9929,
			"t": 1344011220000,
			"n": 93
		  },
		  {
			"v": 267652,
			"vw": 21.9957,
			"o": 21.9964,
			"c": 21.9936,
			"h": 21.9964,
			"l": 21.9921,
			"t": 1344011280000,
			"n": 62
		  },
		  {
			"v": 574672,
			"vw": 21.996,
			"o": 21.9922,
			"c": 21.9962,
			"h": 22,
			"l": 21.9914,
			"t": 1344011340000,
			"n": 158
		  },
		  {
			"v": 680260,
			"vw": 21.9995,
			"o": 21.9996,
			"c": 21.9979,
			"h": 22.0021,
			"l": 21.9968,
			"t": 1344011400000,
			"n": 147
		  },
		  {
			"v": 434196,
			"vw": 21.9947,
			"o": 21.9975,
			"c": 21.9968,
			"h": 21.9986,
			"l": 21.9915,
			"t": 1344011460000,
			"n": 99
		  },
		  {
			"v": 307132,
			"vw": 21.9939,
			"o": 21.9967,
			"c": 21.9893,
			"h": 21.9968,
			"l": 21.9882,
			"t": 1344011520000,
			"n": 78
		  },
		  {
			"v": 231000,
			"vw": 21.9928,
			"o": 21.9904,
			"c": 21.9932,
			"h": 21.9957,
			"l": 21.99,
			"t": 1344011580000,
			"n": 67
		  },
		  {
			"v": 398664,
			"vw": 21.9904,
			"o": 21.9932,
			"c": 21.9868,
			"h": 21.9936,
			"l": 21.9868,
			"t": 1344011640000,
			"n": 95
		  },
		  {
			"v": 342972,
			"vw": 21.9848,
			"o": 21.9868,
			"c": 21.9862,
			"h": 21.9871,
			"l": 21.9821,
			"t": 1344011700000,
			"n": 88
		  },
		  {
			"v": 188104,
			"vw": 21.965,
			"o": 21.9854,
			"c": 21.9854,
			"h": 21.9889,
			"l": 21.9836,
			"t": 1344011760000,
			"n": 48
		  },
		  {
			"v": 206416,
			"vw": 21.9903,
			"o": 21.9851,
			"c": 21.9929,
			"h": 21.9943,
			"l": 21.9843,
			"t": 1344011820000,
			"n": 58
		  },
		  {
			"v": 104496,
			"vw": 21.9908,
			"o": 21.9925,
			"c": 21.988,
			"h": 21.9925,
			"l": 21.9875,
			"t": 1344011880000,
			"n": 28
		  },
		  {
			"v": 273728,
			"vw": 21.9891,
			"o": 21.9875,
			"c": 21.9886,
			"h": 21.9921,
			"l": 21.9839,
			"t": 1344011940000,
			"n": 73
		  },
		  {
			"v": 182532,
			"vw": 21.9913,
			"o": 21.9911,
			"c": 21.9943,
			"h": 21.9946,
			"l": 21.9886,
			"t": 1344012000000,
			"n": 60
		  },
		  {
			"v": 281680,
			"vw": 21.9924,
			"o": 21.9946,
			"c": 21.9911,
			"h": 21.9946,
			"l": 21.9889,
			"t": 1344012060000,
			"n": 79
		  },
		  {
			"v": 278908,
			"vw": 21.9899,
			"o": 21.9886,
			"c": 21.9925,
			"h": 21.9961,
			"l": 21.9839,
			"t": 1344012120000,
			"n": 84
		  },
		  {
			"v": 214004,
			"vw": 21.9881,
			"o": 21.9883,
			"c": 21.991,
			"h": 21.991,
			"l": 21.9861,
			"t": 1344012180000,
			"n": 55
		  },
		  {
			"v": 407792,
			"vw": 21.9869,
			"o": 21.9884,
			"c": 21.9843,
			"h": 21.9904,
			"l": 21.9843,
			"t": 1344012240000,
			"n": 59
		  },
		  {
			"v": 710752,
			"vw": 21.9793,
			"o": 21.9836,
			"c": 21.9819,
			"h": 21.9846,
			"l": 21.9764,
			"t": 1344012300000,
			"n": 168
		  },
		  {
			"v": 598892,
			"vw": 21.9827,
			"o": 21.9819,
			"c": 21.9764,
			"h": 21.9871,
			"l": 21.9764,
			"t": 1344012360000,
			"n": 137
		  },
		  {
			"v": 179788,
			"vw": 21.9796,
			"o": 21.9786,
			"c": 21.9786,
			"h": 21.9818,
			"l": 21.9779,
			"t": 1344012420000,
			"n": 48
		  },
		  {
			"v": 303968,
			"vw": 21.9814,
			"o": 21.9818,
			"c": 21.9811,
			"h": 21.9836,
			"l": 21.9796,
			"t": 1344012480000,
			"n": 68
		  },
		  {
			"v": 295120,
			"vw": 21.9784,
			"o": 21.9804,
			"c": 21.9746,
			"h": 21.9814,
			"l": 21.9736,
			"t": 1344012540000,
			"n": 86
		  },
		  {
			"v": 476056,
			"vw": 21.9744,
			"o": 21.975,
			"c": 21.9764,
			"h": 21.9782,
			"l": 21.9714,
			"t": 1344012600000,
			"n": 117
		  },
		  {
			"v": 394688,
			"vw": 21.9763,
			"o": 21.9746,
			"c": 21.9729,
			"h": 21.9789,
			"l": 21.9729,
			"t": 1344012660000,
			"n": 67
		  },
		  {
			"v": 203168,
			"vw": 21.9743,
			"o": 21.9721,
			"c": 21.9786,
			"h": 21.9803,
			"l": 21.9704,
			"t": 1344012720000,
			"n": 63
		  },
		  {
			"v": 185752,
			"vw": 21.9758,
			"o": 21.9764,
			"c": 21.9739,
			"h": 21.9803,
			"l": 21.9732,
			"t": 1344012780000,
			"n": 50
		  },
		  {
			"v": 198744,
			"vw": 21.9735,
			"o": 21.9736,
			"c": 21.9724,
			"h": 21.9771,
			"l": 21.971,
			"t": 1344012840000,
			"n": 59
		  },
		  {
			"v": 431956,
			"vw": 21.9697,
			"o": 21.9725,
			"c": 21.9696,
			"h": 21.9743,
			"l": 21.9668,
			"t": 1344012900000,
			"n": 110
		  },
		  {
			"v": 598332,
			"vw": 21.9651,
			"o": 21.9675,
			"c": 21.9589,
			"h": 21.9704,
			"l": 21.9582,
			"t": 1344012960000,
			"n": 139
		  },
		  {
			"v": 642684,
			"vw": 21.9579,
			"o": 21.9596,
			"c": 21.9561,
			"h": 21.9621,
			"l": 21.9543,
			"t": 1344013020000,
			"n": 136
		  },
		  {
			"v": 367472,
			"vw": 21.9559,
			"o": 21.9568,
			"c": 21.9568,
			"h": 21.9586,
			"l": 21.9536,
			"t": 1344013080000,
			"n": 90
		  },
		  {
			"v": 374500,
			"vw": 21.9577,
			"o": 21.9543,
			"c": 21.9564,
			"h": 21.9603,
			"l": 21.9543,
			"t": 1344013140000,
			"n": 86
		  },
		  {
			"v": 615524,
			"vw": 21.9578,
			"o": 21.9568,
			"c": 21.9568,
			"h": 21.9636,
			"l": 21.9532,
			"t": 1344013200000,
			"n": 139
		  },
		  {
			"v": 566300,
			"vw": 21.9613,
			"o": 21.9568,
			"c": 21.969,
			"h": 21.97,
			"l": 21.9539,
			"t": 1344013260000,
			"n": 135
		  },
		  {
			"v": 1368752,
			"vw": 21.9773,
			"o": 21.9693,
			"c": 21.9789,
			"h": 21.9896,
			"l": 21.9668,
			"t": 1344013320000,
			"n": 307
		  },
		  {
			"v": 552384,
			"vw": 21.9724,
			"o": 21.9782,
			"c": 21.9761,
			"h": 21.9782,
			"l": 21.9668,
			"t": 1344013380000,
			"n": 130
		  },
		  {
			"v": 215908,
			"vw": 21.9749,
			"o": 21.9729,
			"c": 21.9743,
			"h": 21.9782,
			"l": 21.9721,
			"t": 1344013440000,
			"n": 44
		  },
		  {
			"v": 472024,
			"vw": 21.9753,
			"o": 21.9746,
			"c": 21.9804,
			"h": 21.9814,
			"l": 21.9694,
			"t": 1344013500000,
			"n": 111
		  },
		  {
			"v": 585704,
			"vw": 21.9845,
			"o": 21.9807,
			"c": 21.9882,
			"h": 21.9893,
			"l": 21.9796,
			"t": 1344013560000,
			"n": 135
		  },
		  {
			"v": 291928,
			"vw": 21.9836,
			"o": 21.9882,
			"c": 21.9836,
			"h": 21.9882,
			"l": 21.9804,
			"t": 1344013620000,
			"n": 70
		  },
		  {
			"v": 239960,
			"vw": 21.9812,
			"o": 21.9831,
			"c": 21.9807,
			"h": 21.985,
			"l": 21.9796,
			"t": 1344013680000,
			"n": 64
		  },
		  {
			"v": 206192,
			"vw": 21.9823,
			"o": 21.9804,
			"c": 21.9807,
			"h": 21.9846,
			"l": 21.98,
			"t": 1344013740000,
			"n": 42
		  },
		  {
			"v": 275044,
			"vw": 21.9791,
			"o": 21.9814,
			"c": 21.9804,
			"h": 21.9814,
			"l": 21.9782,
			"t": 1344013800000,
			"n": 66
		  },
		  {
			"v": 220416,
			"vw": 21.9798,
			"o": 21.9786,
			"c": 21.9804,
			"h": 21.9818,
			"l": 21.9782,
			"t": 1344013860000,
			"n": 67
		  },
		  {
			"v": 307636,
			"vw": 21.9816,
			"o": 21.9804,
			"c": 21.9861,
			"h": 21.9889,
			"l": 21.9786,
			"t": 1344013920000,
			"n": 73
		  },
		  {
			"v": 410620,
			"vw": 21.9845,
			"o": 21.9879,
			"c": 21.9821,
			"h": 21.9889,
			"l": 21.9814,
			"t": 1344013980000,
			"n": 99
		  },
		  {
			"v": 571760,
			"vw": 21.9898,
			"o": 21.9825,
			"c": 21.9931,
			"h": 21.9939,
			"l": 21.9825,
			"t": 1344014040000,
			"n": 98
		  },
		  {
			"v": 393232,
			"vw": 21.9923,
			"o": 21.9931,
			"c": 21.9939,
			"h": 21.9943,
			"l": 21.9904,
			"t": 1344014100000,
			"n": 86
		  },
		  {
			"v": 1324512,
			"vw": 21.9998,
			"o": 21.9925,
			"c": 22.0036,
			"h": 22.0104,
			"l": 21.9921,
			"t": 1344014160000,
			"n": 278
		  },
		  {
			"v": 688828,
			"vw": 22.0048,
			"o": 22.0032,
			"c": 22.0107,
			"h": 22.0107,
			"l": 21.9921,
			"t": 1344014220000,
			"n": 161
		  },
		  {
			"v": 812168,
			"vw": 22.0157,
			"o": 22.0114,
			"c": 22.0126,
			"h": 22.0179,
			"l": 22.0114,
			"t": 1344014280000,
			"n": 195
		  },
		  {
			"v": 849212,
			"vw": 22.0034,
			"o": 22.0121,
			"c": 21.9964,
			"h": 22.0132,
			"l": 21.995,
			"t": 1344014340000,
			"n": 186
		  },
		  {
			"v": 326816,
			"vw": 21.9987,
			"o": 21.9982,
			"c": 22.0014,
			"h": 22.0018,
			"l": 21.9954,
			"t": 1344014400000,
			"n": 89
		  },
		  {
			"v": 220640,
			"vw": 22.0051,
			"o": 22.0004,
			"c": 22.01,
			"h": 22.0107,
			"l": 22.0004,
			"t": 1344014460000,
			"n": 68
		  },
		  {
			"v": 345968,
			"vw": 22.0109,
			"o": 22.0079,
			"c": 22.0148,
			"h": 22.0157,
			"l": 22.0071,
			"t": 1344014520000,
			"n": 100
		  },
		  {
			"v": 634844,
			"vw": 22.0156,
			"o": 22.0168,
			"c": 22.0118,
			"h": 22.0179,
			"l": 22.0118,
			"t": 1344014580000,
			"n": 147
		  },
		  {
			"v": 345940,
			"vw": 22.0146,
			"o": 22.0118,
			"c": 22.0134,
			"h": 22.0164,
			"l": 22.0118,
			"t": 1344014640000,
			"n": 79
		  },
		  {
			"v": 314412,
			"vw": 22.0155,
			"o": 22.0136,
			"c": 22.0179,
			"h": 22.0186,
			"l": 22.0118,
			"t": 1344014700000,
			"n": 80
		  },
		  {
			"v": 484260,
			"vw": 22.0176,
			"o": 22.0181,
			"c": 22.0179,
			"h": 22.0204,
			"l": 22.0154,
			"t": 1344014760000,
			"n": 111
		  },
		  {
			"v": 386624,
			"vw": 22.0212,
			"o": 22.0207,
			"c": 22.0214,
			"h": 22.025,
			"l": 22.0175,
			"t": 1344014820000,
			"n": 110
		  },
		  {
			"v": 333228,
			"vw": 22.0228,
			"o": 22.0227,
			"c": 22.0268,
			"h": 22.0268,
			"l": 22.0204,
			"t": 1344014880000,
			"n": 86
		  },
		  {
			"v": 438732,
			"vw": 22.0283,
			"o": 22.0268,
			"c": 22.0296,
			"h": 22.0311,
			"l": 22.025,
			"t": 1344014940000,
			"n": 101
		  },
		  {
			"v": 514360,
			"vw": 22.0241,
			"o": 22.0296,
			"c": 22.0218,
			"h": 22.031,
			"l": 22.0171,
			"t": 1344015000000,
			"n": 126
		  },
		  {
			"v": 634228,
			"vw": 22.0117,
			"o": 22.0211,
			"c": 22.0107,
			"h": 22.0211,
			"l": 22.0046,
			"t": 1344015060000,
			"n": 152
		  },
		  {
			"v": 608300,
			"vw": 22.014,
			"o": 22.0086,
			"c": 22.0174,
			"h": 22.0189,
			"l": 22.0082,
			"t": 1344015120000,
			"n": 133
		  },
		  {
			"v": 413168,
			"vw": 22.0179,
			"o": 22.0171,
			"c": 22.0214,
			"h": 22.0214,
			"l": 22.0136,
			"t": 1344015180000,
			"n": 109
		  },
		  {
			"v": 230244,
			"vw": 22.019,
			"o": 22.0214,
			"c": 22.0161,
			"h": 22.0221,
			"l": 22.0161,
			"t": 1344015240000,
			"n": 59
		  },
		  {
			"v": 333984,
			"vw": 22.0154,
			"o": 22.0161,
			"c": 22.0121,
			"h": 22.0211,
			"l": 22.0121,
			"t": 1344015300000,
			"n": 90
		  },
		  {
			"v": 212688,
			"vw": 22.0167,
			"o": 22.0143,
			"c": 22.0193,
			"h": 22.0193,
			"l": 22.0129,
			"t": 1344015360000,
			"n": 62
		  },
		  {
			"v": 137900,
			"vw": 22.0191,
			"o": 22.0193,
			"c": 22.0239,
			"h": 22.0239,
			"l": 22.0171,
			"t": 1344015420000,
			"n": 35
		  },
		  {
			"v": 153692,
			"vw": 22.0209,
			"o": 22.0229,
			"c": 22.0236,
			"h": 22.0236,
			"l": 22.0182,
			"t": 1344015480000,
			"n": 45
		  },
		  {
			"v": 311332,
			"vw": 22.0245,
			"o": 22.0239,
			"c": 22.025,
			"h": 22.0261,
			"l": 22.0207,
			"t": 1344015540000,
			"n": 78
		  },
		  {
			"v": 822416,
			"vw": 22.0279,
			"o": 22.0257,
			"c": 22.0268,
			"h": 22.0332,
			"l": 22.0211,
			"t": 1344015600000,
			"n": 214
		  },
		  {
			"v": 336028,
			"vw": 22.0265,
			"o": 22.0268,
			"c": 22.02,
			"h": 22.0311,
			"l": 22.0193,
			"t": 1344015660000,
			"n": 89
		  },
		  {
			"v": 380660,
			"vw": 22.0206,
			"o": 22.0232,
			"c": 22.0243,
			"h": 22.0243,
			"l": 22.0168,
			"t": 1344015720000,
			"n": 82
		  },
		  {
			"v": 396396,
			"vw": 22.0141,
			"o": 22.0221,
			"c": 22.0179,
			"h": 22.0221,
			"l": 22.0082,
			"t": 1344015780000,
			"n": 89
		  },
		  {
			"v": 343476,
			"vw": 22.0129,
			"o": 22.0171,
			"c": 22.0107,
			"h": 22.0211,
			"l": 22.0071,
			"t": 1344015840000,
			"n": 76
		  },
		  {
			"v": 337736,
			"vw": 22.0111,
			"o": 22.0104,
			"c": 22.0139,
			"h": 22.0139,
			"l": 22.0082,
			"t": 1344015900000,
			"n": 73
		  },
		  {
			"v": 361788,
			"vw": 22.0105,
			"o": 22.0139,
			"c": 22.0131,
			"h": 22.0154,
			"l": 22.0071,
			"t": 1344015960000,
			"n": 94
		  },
		  {
			"v": 288960,
			"vw": 22.0155,
			"o": 22.0132,
			"c": 22.016,
			"h": 22.0171,
			"l": 22.0121,
			"t": 1344016020000,
			"n": 66
		  },
		  {
			"v": 197092,
			"vw": 22.0174,
			"o": 22.0154,
			"c": 22.0157,
			"h": 22.0207,
			"l": 22.015,
			"t": 1344016080000,
			"n": 52
		  },
		  {
			"v": 228144,
			"vw": 22.0177,
			"o": 22.0179,
			"c": 22.0161,
			"h": 22.0211,
			"l": 22.0157,
			"t": 1344016140000,
			"n": 57
		  },
		  {
			"v": 199640,
			"vw": 22.0166,
			"o": 22.0156,
			"c": 22.015,
			"h": 22.0186,
			"l": 22.015,
			"t": 1344016200000,
			"n": 59
		  },
		  {
			"v": 348264,
			"vw": 22.0133,
			"o": 22.0154,
			"c": 22.0084,
			"h": 22.0171,
			"l": 22.0084,
			"t": 1344016260000,
			"n": 91
		  },
		  {
			"v": 311724,
			"vw": 22.0119,
			"o": 22.0089,
			"c": 22.0089,
			"h": 22.0154,
			"l": 22.0086,
			"t": 1344016320000,
			"n": 75
		  },
		  {
			"v": 304864,
			"vw": 22.008,
			"o": 22.0086,
			"c": 22.0086,
			"h": 22.0114,
			"l": 22.0054,
			"t": 1344016380000,
			"n": 76
		  },
		  {
			"v": 312172,
			"vw": 22.0142,
			"o": 22.0082,
			"c": 22.0158,
			"h": 22.0179,
			"l": 22.0075,
			"t": 1344016440000,
			"n": 74
		  },
		  {
			"v": 441336,
			"vw": 22.0123,
			"o": 22.0164,
			"c": 22.0082,
			"h": 22.0168,
			"l": 22.0082,
			"t": 1344016500000,
			"n": 95
		  },
		  {
			"v": 734272,
			"vw": 22.0122,
			"o": 22.0071,
			"c": 22.0168,
			"h": 22.0175,
			"l": 22.0057,
			"t": 1344016560000,
			"n": 159
		  },
		  {
			"v": 470708,
			"vw": 22.0186,
			"o": 22.0164,
			"c": 22.0184,
			"h": 22.0229,
			"l": 22.0161,
			"t": 1344016620000,
			"n": 117
		  },
		  {
			"v": 179816,
			"vw": 22.0121,
			"o": 22.02,
			"c": 22.01,
			"h": 22.0204,
			"l": 22.0075,
			"t": 1344016680000,
			"n": 53
		  },
		  {
			"v": 212968,
			"vw": 22.0134,
			"o": 22.0129,
			"c": 22.0157,
			"h": 22.0171,
			"l": 22.0114,
			"t": 1344016740000,
			"n": 51
		  },
		  {
			"v": 92456,
			"vw": 22.0136,
			"o": 22.0127,
			"c": 22.0146,
			"h": 22.015,
			"l": 22.0122,
			"t": 1344016800000,
			"n": 18
		  },
		  {
			"v": 283080,
			"vw": 22.0156,
			"o": 22.0146,
			"c": 22.0143,
			"h": 22.0179,
			"l": 22.0129,
			"t": 1344016860000,
			"n": 69
		  },
		  {
			"v": 339444,
			"vw": 22.0176,
			"o": 22.0133,
			"c": 22.0165,
			"h": 22.0196,
			"l": 22.0133,
			"t": 1344016920000,
			"n": 77
		  },
		  {
			"v": 263564,
			"vw": 22.0158,
			"o": 22.0161,
			"c": 22.0175,
			"h": 22.02,
			"l": 22.0136,
			"t": 1344016980000,
			"n": 72
		  },
		  {
			"v": 340508,
			"vw": 22.0188,
			"o": 22.0171,
			"c": 22.0209,
			"h": 22.0239,
			"l": 22.0129,
			"t": 1344017040000,
			"n": 86
		  },
		  {
			"v": 450352,
			"vw": 22.0246,
			"o": 22.0207,
			"c": 22.0246,
			"h": 22.0268,
			"l": 22.0207,
			"t": 1344017100000,
			"n": 125
		  },
		  {
			"v": 517804,
			"vw": 22.0246,
			"o": 22.0243,
			"c": 22.0268,
			"h": 22.0268,
			"l": 22.0211,
			"t": 1344017160000,
			"n": 98
		  },
		  {
			"v": 476896,
			"vw": 22.0227,
			"o": 22.0268,
			"c": 22.0193,
			"h": 22.0282,
			"l": 22.0175,
			"t": 1344017220000,
			"n": 117
		  },
		  {
			"v": 402640,
			"vw": 22.0223,
			"o": 22.0196,
			"c": 22.0254,
			"h": 22.0275,
			"l": 22.0175,
			"t": 1344017280000,
			"n": 103
		  },
		  {
			"v": 430332,
			"vw": 22.0219,
			"o": 22.025,
			"c": 22.0193,
			"h": 22.0257,
			"l": 22.0175,
			"t": 1344017340000,
			"n": 69
		  },
		  {
			"v": 305396,
			"vw": 22.0217,
			"o": 22.0193,
			"c": 22.0236,
			"h": 22.0246,
			"l": 22.0186,
			"t": 1344017400000,
			"n": 91
		  },
		  {
			"v": 493668,
			"vw": 22.0163,
			"o": 22.0211,
			"c": 22.0171,
			"h": 22.0218,
			"l": 22.0114,
			"t": 1344017460000,
			"n": 108
		  },
		  {
			"v": 123956,
			"vw": 22.017,
			"o": 22.0189,
			"c": 22.0179,
			"h": 22.0218,
			"l": 22.0146,
			"t": 1344017520000,
			"n": 36
		  },
		  {
			"v": 241752,
			"vw": 22.0192,
			"o": 22.0161,
			"c": 22.0164,
			"h": 22.0221,
			"l": 22.016,
			"t": 1344017580000,
			"n": 57
		  },
		  {
			"v": 216748,
			"vw": 22.0151,
			"o": 22.0161,
			"c": 22.0171,
			"h": 22.0171,
			"l": 22.0125,
			"t": 1344017640000,
			"n": 58
		  },
		  {
			"v": 334264,
			"vw": 22.0205,
			"o": 22.0154,
			"c": 22.0232,
			"h": 22.0236,
			"l": 22.0146,
			"t": 1344017700000,
			"n": 75
		  },
		  {
			"v": 470484,
			"vw": 22.0231,
			"o": 22.0239,
			"c": 22.0229,
			"h": 22.0243,
			"l": 22.0204,
			"t": 1344017760000,
			"n": 95
		  },
		  {
			"v": 451948,
			"vw": 22.0189,
			"o": 22.0229,
			"c": 22.0171,
			"h": 22.0246,
			"l": 22.0104,
			"t": 1344017820000,
			"n": 101
		  },
		  {
			"v": 297080,
			"vw": 22.0221,
			"o": 22.0186,
			"c": 22.0218,
			"h": 22.0257,
			"l": 22.0182,
			"t": 1344017880000,
			"n": 82
		  },
		  {
			"v": 404572,
			"vw": 22.0229,
			"o": 22.025,
			"c": 22.0226,
			"h": 22.0257,
			"l": 22.0197,
			"t": 1344017940000,
			"n": 75
		  },
		  {
			"v": 475412,
			"vw": 22.0229,
			"o": 22.0225,
			"c": 22.0232,
			"h": 22.0256,
			"l": 22.0189,
			"t": 1344018000000,
			"n": 82
		  },
		  {
			"v": 717276,
			"vw": 22.0263,
			"o": 22.0246,
			"c": 22.0249,
			"h": 22.0304,
			"l": 22.0225,
			"t": 1344018060000,
			"n": 178
		  },
		  {
			"v": 757708,
			"vw": 22.0284,
			"o": 22.0229,
			"c": 22.0257,
			"h": 22.0321,
			"l": 22.0229,
			"t": 1344018120000,
			"n": 157
		  },
		  {
			"v": 478856,
			"vw": 22.0277,
			"o": 22.0274,
			"c": 22.0282,
			"h": 22.0304,
			"l": 22.0257,
			"t": 1344018180000,
			"n": 88
		  },
		  {
			"v": 1724716,
			"vw": 22.0347,
			"o": 22.0271,
			"c": 22.0357,
			"h": 22.0357,
			"l": 22.0271,
			"t": 1344018240000,
			"n": 325
		  },
		  {
			"v": 880068,
			"vw": 22.0345,
			"o": 22.0355,
			"c": 22.0357,
			"h": 22.0357,
			"l": 22.0304,
			"t": 1344018300000,
			"n": 204
		  },
		  {
			"v": 1427636,
			"vw": 22.0395,
			"o": 22.0357,
			"c": 22.0372,
			"h": 22.0446,
			"l": 22.0343,
			"t": 1344018360000,
			"n": 325
		  },
		  {
			"v": 800856,
			"vw": 22.0379,
			"o": 22.0389,
			"c": 22.0357,
			"h": 22.0436,
			"l": 22.0339,
			"t": 1344018420000,
			"n": 167
		  },
		  {
			"v": 652960,
			"vw": 22.0334,
			"o": 22.0361,
			"c": 22.0357,
			"h": 22.0368,
			"l": 22.0286,
			"t": 1344018480000,
			"n": 153
		  },
		  {
			"v": 514136,
			"vw": 22.0323,
			"o": 22.0354,
			"c": 22.0361,
			"h": 22.0379,
			"l": 22.0279,
			"t": 1344018540000,
			"n": 131
		  },
		  {
			"v": 483952,
			"vw": 22.0374,
			"o": 22.0346,
			"c": 22.0325,
			"h": 22.0407,
			"l": 22.0325,
			"t": 1344018600000,
			"n": 124
		  },
		  {
			"v": 589904,
			"vw": 22.0347,
			"o": 22.0343,
			"c": 22.0361,
			"h": 22.0361,
			"l": 22.0321,
			"t": 1344018660000,
			"n": 120
		  },
		  {
			"v": 441756,
			"vw": 22.0342,
			"o": 22.035,
			"c": 22.0321,
			"h": 22.0375,
			"l": 22.0314,
			"t": 1344018720000,
			"n": 112
		  },
		  {
			"v": 324408,
			"vw": 22.0303,
			"o": 22.0321,
			"c": 22.0307,
			"h": 22.0329,
			"l": 22.0282,
			"t": 1344018780000,
			"n": 79
		  },
		  {
			"v": 638820,
			"vw": 22.0207,
			"o": 22.0282,
			"c": 22.0182,
			"h": 22.0282,
			"l": 22.0179,
			"t": 1344018840000,
			"n": 169
		  },
		  {
			"v": 296772,
			"vw": 22.0218,
			"o": 22.0182,
			"c": 22.0225,
			"h": 22.0261,
			"l": 22.0175,
			"t": 1344018900000,
			"n": 71
		  },
		  {
			"v": 299012,
			"vw": 22.0222,
			"o": 22.0218,
			"c": 22.0243,
			"h": 22.0282,
			"l": 22.0196,
			"t": 1344018960000,
			"n": 82
		  },
		  {
			"v": 396508,
			"vw": 22.0242,
			"o": 22.025,
			"c": 22.0239,
			"h": 22.0264,
			"l": 22.0218,
			"t": 1344019020000,
			"n": 98
		  },
		  {
			"v": 310408,
			"vw": 22.0231,
			"o": 22.0226,
			"c": 22.0229,
			"h": 22.0246,
			"l": 22.0221,
			"t": 1344019080000,
			"n": 72
		  },
		  {
			"v": 386848,
			"vw": 22.0268,
			"o": 22.0236,
			"c": 22.0275,
			"h": 22.03,
			"l": 22.0229,
			"t": 1344019140000,
			"n": 93
		  },
		  {
			"v": 468272,
			"vw": 22.0326,
			"o": 22.0275,
			"c": 22.0379,
			"h": 22.038,
			"l": 22.0275,
			"t": 1344019200000,
			"n": 110
		  },
		  {
			"v": 528920,
			"vw": 22.0369,
			"o": 22.0366,
			"c": 22.035,
			"h": 22.0396,
			"l": 22.0332,
			"t": 1344019260000,
			"n": 118
		  },
		  {
			"v": 296940,
			"vw": 22.0303,
			"o": 22.0339,
			"c": 22.0243,
			"h": 22.035,
			"l": 22.0243,
			"t": 1344019320000,
			"n": 81
		  },
		  {
			"v": 342020,
			"vw": 22.0257,
			"o": 22.0241,
			"c": 22.0286,
			"h": 22.0289,
			"l": 22.0239,
			"t": 1344019380000,
			"n": 70
		  },
		  {
			"v": 302820,
			"vw": 22.0306,
			"o": 22.0289,
			"c": 22.0325,
			"h": 22.0332,
			"l": 22.0266,
			"t": 1344019440000,
			"n": 88
		  },
		  {
			"v": 296128,
			"vw": 22.0307,
			"o": 22.0329,
			"c": 22.0286,
			"h": 22.0339,
			"l": 22.0275,
			"t": 1344019500000,
			"n": 64
		  },
		  {
			"v": 162064,
			"vw": 22.0314,
			"o": 22.0298,
			"c": 22.0321,
			"h": 22.0336,
			"l": 22.0298,
			"t": 1344019560000,
			"n": 40
		  },
		  {
			"v": 308000,
			"vw": 22.0298,
			"o": 22.0321,
			"c": 22.0296,
			"h": 22.0321,
			"l": 22.0261,
			"t": 1344019620000,
			"n": 54
		  },
		  {
			"v": 174552,
			"vw": 22.0292,
			"o": 22.029,
			"c": 22.0284,
			"h": 22.0311,
			"l": 22.0271,
			"t": 1344019680000,
			"n": 44
		  },
		  {
			"v": 389088,
			"vw": 22.0291,
			"o": 22.0286,
			"c": 22.0243,
			"h": 22.0321,
			"l": 22.0243,
			"t": 1344019740000,
			"n": 95
		  },
		  {
			"v": 275632,
			"vw": 22.0263,
			"o": 22.0243,
			"c": 22.0257,
			"h": 22.0285,
			"l": 22.0229,
			"t": 1344019800000,
			"n": 64
		  },
		  {
			"v": 146860,
			"vw": 22.0291,
			"o": 22.0279,
			"c": 22.0286,
			"h": 22.0304,
			"l": 22.0279,
			"t": 1344019860000,
			"n": 37
		  },
		  {
			"v": 559104,
			"vw": 22.0226,
			"o": 22.0275,
			"c": 22.0225,
			"h": 22.0303,
			"l": 22.0189,
			"t": 1344019920000,
			"n": 117
		  },
		  {
			"v": 845852,
			"vw": 22.0165,
			"o": 22.0225,
			"c": 22.0129,
			"h": 22.0232,
			"l": 22.0118,
			"t": 1344019980000,
			"n": 177
		  },
		  {
			"v": 1312416,
			"vw": 22.0136,
			"o": 22.0132,
			"c": 22.0171,
			"h": 22.0204,
			"l": 22.0089,
			"t": 1344020040000,
			"n": 269
		  },
		  {
			"v": 471156,
			"vw": 22.0174,
			"o": 22.0168,
			"c": 22.0171,
			"h": 22.0193,
			"l": 22.0143,
			"t": 1344020100000,
			"n": 117
		  },
		  {
			"v": 644364,
			"vw": 22.0179,
			"o": 22.0171,
			"c": 22.02,
			"h": 22.0221,
			"l": 22.0157,
			"t": 1344020160000,
			"n": 189
		  },
		  {
			"v": 403704,
			"vw": 22.0172,
			"o": 22.0207,
			"c": 22.0136,
			"h": 22.0208,
			"l": 22.0132,
			"t": 1344020220000,
			"n": 85
		  },
		  {
			"v": 376040,
			"vw": 22.0121,
			"o": 22.0136,
			"c": 22.0082,
			"h": 22.0157,
			"l": 22.0082,
			"t": 1344020280000,
			"n": 99
		  },
		  {
			"v": 838236,
			"vw": 22.0091,
			"o": 22.0086,
			"c": 22.0071,
			"h": 22.015,
			"l": 22.005,
			"t": 1344020340000,
			"n": 208
		  },
		  {
			"v": 600824,
			"vw": 22.0132,
			"o": 22.0089,
			"c": 22.0175,
			"h": 22.02,
			"l": 22.0068,
			"t": 1344020400000,
			"n": 155
		  },
		  {
			"v": 334656,
			"vw": 22.0165,
			"o": 22.0175,
			"c": 22.0173,
			"h": 22.0196,
			"l": 22.0146,
			"t": 1344020460000,
			"n": 86
		  },
		  {
			"v": 318080,
			"vw": 22.0175,
			"o": 22.0175,
			"c": 22.0182,
			"h": 22.0193,
			"l": 22.0157,
			"t": 1344020520000,
			"n": 77
		  },
		  {
			"v": 269920,
			"vw": 22.0166,
			"o": 22.0172,
			"c": 22.0168,
			"h": 22.0186,
			"l": 22.0129,
			"t": 1344020580000,
			"n": 43
		  },
		  {
			"v": 402836,
			"vw": 22.0181,
			"o": 22.0168,
			"c": 22.0216,
			"h": 22.0236,
			"l": 22.0129,
			"t": 1344020640000,
			"n": 124
		  },
		  {
			"v": 358428,
			"vw": 22.0247,
			"o": 22.0236,
			"c": 22.025,
			"h": 22.0279,
			"l": 22.0218,
			"t": 1344020700000,
			"n": 93
		  },
		  {
			"v": 476924,
			"vw": 22.0277,
			"o": 22.0243,
			"c": 22.0304,
			"h": 22.0311,
			"l": 22.0243,
			"t": 1344020760000,
			"n": 98
		  },
		  {
			"v": 625212,
			"vw": 22.0296,
			"o": 22.0304,
			"c": 22.0275,
			"h": 22.0339,
			"l": 22.025,
			"t": 1344020820000,
			"n": 148
		  },
		  {
			"v": 388584,
			"vw": 22.0293,
			"o": 22.0273,
			"c": 22.0311,
			"h": 22.0332,
			"l": 22.025,
			"t": 1344020880000,
			"n": 100
		  },
		  {
			"v": 356692,
			"vw": 22.0294,
			"o": 22.0314,
			"c": 22.029,
			"h": 22.0321,
			"l": 22.0275,
			"t": 1344020940000,
			"n": 94
		  },
		  {
			"v": 551796,
			"vw": 22.0307,
			"o": 22.0293,
			"c": 22.0321,
			"h": 22.0343,
			"l": 22.0286,
			"t": 1344021000000,
			"n": 126
		  },
		  {
			"v": 345156,
			"vw": 22.0315,
			"o": 22.0311,
			"c": 22.0313,
			"h": 22.0339,
			"l": 22.0293,
			"t": 1344021060000,
			"n": 76
		  },
		  {
			"v": 891800,
			"vw": 22.0345,
			"o": 22.0313,
			"c": 22.0329,
			"h": 22.0364,
			"l": 22.0304,
			"t": 1344021120000,
			"n": 186
		  },
		  {
			"v": 440160,
			"vw": 22.0342,
			"o": 22.0336,
			"c": 22.034,
			"h": 22.0357,
			"l": 22.0329,
			"t": 1344021180000,
			"n": 108
		  },
		  {
			"v": 625968,
			"vw": 22.0332,
			"o": 22.0339,
			"c": 22.0293,
			"h": 22.035,
			"l": 22.0293,
			"t": 1344021240000,
			"n": 145
		  },
		  {
			"v": 536396,
			"vw": 22.0275,
			"o": 22.03,
			"c": 22.0261,
			"h": 22.0311,
			"l": 22.025,
			"t": 1344021300000,
			"n": 149
		  },
		  {
			"v": 513660,
			"vw": 22.0251,
			"o": 22.0264,
			"c": 22.025,
			"h": 22.0271,
			"l": 22.0232,
			"t": 1344021360000,
			"n": 137
		  },
		  {
			"v": 449232,
			"vw": 22.0253,
			"o": 22.025,
			"c": 22.0264,
			"h": 22.0286,
			"l": 22.0229,
			"t": 1344021420000,
			"n": 117
		  },
		  {
			"v": 679784,
			"vw": 22.0223,
			"o": 22.0264,
			"c": 22.0222,
			"h": 22.0264,
			"l": 22.0186,
			"t": 1344021480000,
			"n": 149
		  },
		  {
			"v": 281204,
			"vw": 22.0229,
			"o": 22.0236,
			"c": 22.0261,
			"h": 22.0261,
			"l": 22.0204,
			"t": 1344021540000,
			"n": 83
		  },
		  {
			"v": 520464,
			"vw": 22.0273,
			"o": 22.0263,
			"c": 22.0307,
			"h": 22.0307,
			"l": 22.0243,
			"t": 1344021600000,
			"n": 124
		  },
		  {
			"v": 803544,
			"vw": 22.0297,
			"o": 22.029,
			"c": 22.0304,
			"h": 22.0329,
			"l": 22.0286,
			"t": 1344021660000,
			"n": 151
		  },
		  {
			"v": 532028,
			"vw": 22.029,
			"o": 22.03,
			"c": 22.0275,
			"h": 22.0321,
			"l": 22.0243,
			"t": 1344021720000,
			"n": 141
		  },
		  {
			"v": 394352,
			"vw": 22.027,
			"o": 22.0268,
			"c": 22.0258,
			"h": 22.0289,
			"l": 22.0243,
			"t": 1344021780000,
			"n": 95
		  },
		  {
			"v": 598640,
			"vw": 22.0289,
			"o": 22.0271,
			"c": 22.0291,
			"h": 22.0329,
			"l": 22.025,
			"t": 1344021840000,
			"n": 143
		  },
		  {
			"v": 335972,
			"vw": 22.0285,
			"o": 22.0311,
			"c": 22.0293,
			"h": 22.0325,
			"l": 22.0261,
			"t": 1344021900000,
			"n": 91
		  },
		  {
			"v": 472892,
			"vw": 22.0259,
			"o": 22.0296,
			"c": 22.0261,
			"h": 22.03,
			"l": 22.0243,
			"t": 1344021960000,
			"n": 116
		  },
		  {
			"v": 342300,
			"vw": 22.0266,
			"o": 22.0261,
			"c": 22.0282,
			"h": 22.0293,
			"l": 22.025,
			"t": 1344022020000,
			"n": 93
		  },
		  {
			"v": 434588,
			"vw": 22.0259,
			"o": 22.0286,
			"c": 22.0274,
			"h": 22.0286,
			"l": 22.025,
			"t": 1344022080000,
			"n": 120
		  },
		  {
			"v": 978908,
			"vw": 22.0233,
			"o": 22.0254,
			"c": 22.0225,
			"h": 22.0275,
			"l": 22.0186,
			"t": 1344022140000,
			"n": 222
		  },
		  {
			"v": 1494220,
			"vw": 22.0185,
			"o": 22.0196,
			"c": 22.0139,
			"h": 22.0229,
			"l": 22.0139,
			"t": 1344022200000,
			"n": 334
		  },
		  {
			"v": 1184652,
			"vw": 22.0124,
			"o": 22.0139,
			"c": 22.0136,
			"h": 22.0179,
			"l": 22.0089,
			"t": 1344022260000,
			"n": 303
		  },
		  {
			"v": 1413916,
			"vw": 22.007,
			"o": 22.0114,
			"c": 22.0046,
			"h": 22.0136,
			"l": 22.0025,
			"t": 1344022320000,
			"n": 378
		  },
		  {
			"v": 1180424,
			"vw": 22.0024,
			"o": 22.0061,
			"c": 22.0014,
			"h": 22.0082,
			"l": 21.9971,
			"t": 1344022380000,
			"n": 297
		  },
		  {
			"v": 1622600,
			"vw": 21.9887,
			"o": 22.0014,
			"c": 21.9809,
			"h": 22.0014,
			"l": 21.98,
			"t": 1344022440000,
			"n": 429
		  },
		  {
			"v": 1662108,
			"vw": 21.979,
			"o": 21.9804,
			"c": 21.9704,
			"h": 21.9854,
			"l": 21.9693,
			"t": 1344022500000,
			"n": 399
		  },
		  {
			"v": 1690640,
			"vw": 21.971,
			"o": 21.9704,
			"c": 21.9757,
			"h": 21.9761,
			"l": 21.9661,
			"t": 1344022560000,
			"n": 397
		  },
		  {
			"v": 1715084,
			"vw": 21.9695,
			"o": 21.9757,
			"c": 21.9643,
			"h": 21.9786,
			"l": 21.9643,
			"t": 1344022620000,
			"n": 351
		  },
		  {
			"v": 1449196,
			"vw": 21.9667,
			"o": 21.9645,
			"c": 21.9707,
			"h": 21.9729,
			"l": 21.9625,
			"t": 1344022680000,
			"n": 346
		  },
		  {
			"v": 900284,
			"vw": 21.9704,
			"o": 21.9713,
			"c": 21.9728,
			"h": 21.9732,
			"l": 21.9657,
			"t": 1344022740000,
			"n": 209
		  },
		  {
			"v": 2421524,
			"vw": 21.9732,
			"o": 21.9729,
			"c": 21.9682,
			"h": 21.9786,
			"l": 21.9657,
			"t": 1344022800000,
			"n": 541
		  },
		  {
			"v": 873040,
			"vw": 21.9674,
			"o": 21.9682,
			"c": 21.9668,
			"h": 21.9699,
			"l": 21.9654,
			"t": 1344022860000,
			"n": 213
		  },
		  {
			"v": 1043560,
			"vw": 21.9674,
			"o": 21.9671,
			"c": 21.9643,
			"h": 21.9736,
			"l": 21.9639,
			"t": 1344022920000,
			"n": 266
		  },
		  {
			"v": 470484,
			"vw": 21.9647,
			"o": 21.9639,
			"c": 21.9654,
			"h": 21.9668,
			"l": 21.9629,
			"t": 1344022980000,
			"n": 122
		  },
		  {
			"v": 1470644,
			"vw": 21.9661,
			"o": 21.9639,
			"c": 21.9661,
			"h": 21.9707,
			"l": 21.9614,
			"t": 1344023040000,
			"n": 346
		  },
		  {
			"v": 1480948,
			"vw": 21.9703,
			"o": 21.9689,
			"c": 21.97,
			"h": 21.9757,
			"l": 21.9657,
			"t": 1344023100000,
			"n": 317
		  },
		  {
			"v": 2040248,
			"vw": 21.9689,
			"o": 21.9693,
			"c": 21.9679,
			"h": 21.9743,
			"l": 21.9632,
			"t": 1344023160000,
			"n": 490
		  },
		  {
			"v": 874160,
			"vw": 21.9663,
			"o": 21.9679,
			"c": 21.9632,
			"h": 21.971,
			"l": 21.9632,
			"t": 1344023220000,
			"n": 214
		  },
		  {
			"v": 1217636,
			"vw": 21.9658,
			"o": 21.9638,
			"c": 21.9657,
			"h": 21.9696,
			"l": 21.9632,
			"t": 1344023280000,
			"n": 313
		  },
		  {
			"v": 1897196,
			"vw": 21.9684,
			"o": 21.9668,
			"c": 21.9671,
			"h": 21.9728,
			"l": 21.9661,
			"t": 1344023340000,
			"n": 364
		  },
		  {
			"v": 3107804,
			"vw": 21.9694,
			"o": 21.9675,
			"c": 21.965,
			"h": 21.9746,
			"l": 21.965,
			"t": 1344023400000,
			"n": 699
		  },
		  {
			"v": 2211720,
			"vw": 21.9716,
			"o": 21.9679,
			"c": 21.9664,
			"h": 21.9771,
			"l": 21.9661,
			"t": 1344023460000,
			"n": 508
		  },
		  {
			"v": 880852,
			"vw": 21.9678,
			"o": 21.9675,
			"c": 21.9682,
			"h": 21.9704,
			"l": 21.9661,
			"t": 1344023520000,
			"n": 226
		  },
		  {
			"v": 1017044,
			"vw": 21.9681,
			"o": 21.9689,
			"c": 21.9686,
			"h": 21.9707,
			"l": 21.9661,
			"t": 1344023580000,
			"n": 256
		  },
		  {
			"v": 1198036,
			"vw": 21.9717,
			"o": 21.9704,
			"c": 21.9727,
			"h": 21.975,
			"l": 21.9689,
			"t": 1344023640000,
			"n": 297
		  },
		  {
			"v": 1608068,
			"vw": 21.9764,
			"o": 21.9744,
			"c": 21.9736,
			"h": 21.9893,
			"l": 21.9718,
			"t": 1344023700000,
			"n": 319
		  },
		  {
			"v": 1689632,
			"vw": 21.9711,
			"o": 21.9739,
			"c": 21.9707,
			"h": 21.9739,
			"l": 21.9682,
			"t": 1344023760000,
			"n": 401
		  },
		  {
			"v": 1915032,
			"vw": 21.9685,
			"o": 21.9704,
			"c": 21.9693,
			"h": 21.9714,
			"l": 21.9661,
			"t": 1344023820000,
			"n": 490
		  },
		  {
			"v": 2078356,
			"vw": 21.9739,
			"o": 21.9689,
			"c": 21.9764,
			"h": 21.9764,
			"l": 21.9686,
			"t": 1344023880000,
			"n": 508
		  },
		  {
			"v": 4297048,
			"vw": 21.9834,
			"o": 21.9771,
			"c": 21.9875,
			"h": 21.9879,
			"l": 21.975,
			"t": 1344023940000,
			"n": 904
		  },
		  {
			"v": 98000,
			"vw": 21.974,
			"o": 21.98,
			"c": 21.9793,
			"h": 21.9804,
			"l": 21.9686,
			"t": 1344024000000,
			"n": 20
		  },
		  {
			"v": 131936,
			"vw": 21.9775,
			"o": 21.975,
			"c": 21.9779,
			"h": 21.9789,
			"l": 21.9746,
			"t": 1344024060000,
			"n": 28
		  },
		  {
			"v": 84000,
			"vw": 21.9826,
			"o": 21.9804,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9804,
			"t": 1344024120000,
			"n": 17
		  },
		  {
			"v": 30940,
			"vw": 21.9855,
			"o": 21.9846,
			"c": 21.9843,
			"h": 21.9861,
			"l": 21.9843,
			"t": 1344024180000,
			"n": 10
		  },
		  {
			"v": 86380,
			"vw": 21.9888,
			"o": 21.9843,
			"c": 21.9868,
			"h": 21.9868,
			"l": 21.9843,
			"t": 1344024240000,
			"n": 5
		  },
		  {
			"v": 22400,
			"vw": 21.9884,
			"o": 21.9886,
			"c": 21.9882,
			"h": 21.9886,
			"l": 21.9882,
			"t": 1344024300000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 21.9864,
			"o": 21.9864,
			"c": 21.9864,
			"h": 21.9864,
			"l": 21.9864,
			"t": 1344024360000,
			"n": 1
		  },
		  {
			"v": 34412,
			"vw": 21.9858,
			"o": 21.9846,
			"c": 21.9861,
			"h": 21.9868,
			"l": 21.9846,
			"t": 1344024420000,
			"n": 6
		  },
		  {
			"v": 57400,
			"vw": 21.984,
			"o": 21.9839,
			"c": 21.9857,
			"h": 21.9864,
			"l": 21.9821,
			"t": 1344024480000,
			"n": 9
		  },
		  {
			"v": 38360,
			"vw": 21.9826,
			"o": 21.9821,
			"c": 21.9825,
			"h": 21.9854,
			"l": 21.9807,
			"t": 1344024540000,
			"n": 9
		  },
		  {
			"v": 72800,
			"vw": 21.9857,
			"o": 21.9836,
			"c": 21.9843,
			"h": 21.9846,
			"l": 21.9821,
			"t": 1344024600000,
			"n": 13
		  },
		  {
			"v": 30800,
			"vw": 21.9828,
			"o": 21.9821,
			"c": 21.9821,
			"h": 21.9836,
			"l": 21.9821,
			"t": 1344024660000,
			"n": 7
		  },
		  {
			"v": 82740,
			"vw": 21.9793,
			"o": 21.9807,
			"c": 21.9786,
			"h": 21.9807,
			"l": 21.9786,
			"t": 1344024720000,
			"n": 16
		  },
		  {
			"v": 16800,
			"vw": 21.9778,
			"o": 21.9814,
			"c": 21.9768,
			"h": 21.9814,
			"l": 21.9768,
			"t": 1344024780000,
			"n": 5
		  },
		  {
			"v": 140952,
			"vw": 21.9853,
			"o": 21.9754,
			"c": 21.9825,
			"h": 21.9825,
			"l": 21.9746,
			"t": 1344024840000,
			"n": 9
		  },
		  {
			"v": 167076,
			"vw": 21.9772,
			"o": 21.9821,
			"c": 21.9814,
			"h": 21.9839,
			"l": 21.9746,
			"t": 1344024900000,
			"n": 27
		  },
		  {
			"v": 104300,
			"vw": 21.9816,
			"o": 21.9818,
			"c": 21.9821,
			"h": 21.9821,
			"l": 21.9768,
			"t": 1344024960000,
			"n": 9
		  },
		  {
			"v": 25200,
			"vw": 21.9857,
			"o": 21.9857,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9857,
			"t": 1344025020000,
			"n": 3
		  },
		  {
			"v": 109200,
			"vw": 21.9809,
			"o": 21.9868,
			"c": 21.9796,
			"h": 21.9868,
			"l": 21.9768,
			"t": 1344025080000,
			"n": 28
		  },
		  {
			"v": 5600,
			"vw": 21.9814,
			"o": 21.9807,
			"c": 21.9821,
			"h": 21.9821,
			"l": 21.9807,
			"t": 1344025140000,
			"n": 2
		  },
		  {
			"v": 102760,
			"vw": 21.988,
			"o": 21.9857,
			"c": 21.9889,
			"h": 21.9893,
			"l": 21.9857,
			"t": 1344025200000,
			"n": 20
		  },
		  {
			"v": 30800,
			"vw": 21.9867,
			"o": 21.9875,
			"c": 21.9857,
			"h": 21.9875,
			"l": 21.9843,
			"t": 1344025260000,
			"n": 8
		  },
		  {
			"v": 137732,
			"vw": 21.9892,
			"o": 21.9871,
			"c": 21.9871,
			"h": 21.9871,
			"l": 21.9871,
			"t": 1344025320000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 21.9864,
			"o": 21.9857,
			"c": 21.9871,
			"h": 21.9871,
			"l": 21.9857,
			"t": 1344025380000,
			"n": 2
		  },
		  {
			"v": 145320,
			"vw": 21.9892,
			"o": 21.9871,
			"c": 21.9893,
			"h": 21.9911,
			"l": 21.9864,
			"t": 1344025440000,
			"n": 13
		  },
		  {
			"v": 2800,
			"vw": 21.9904,
			"o": 21.9904,
			"c": 21.9904,
			"h": 21.9904,
			"l": 21.9904,
			"t": 1344025500000,
			"n": 1
		  },
		  {
			"v": 507024,
			"vw": 21.9893,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344025560000,
			"n": 2
		  },
		  {
			"v": 30800,
			"vw": 21.99,
			"o": 21.9904,
			"c": 21.99,
			"h": 21.9904,
			"l": 21.99,
			"t": 1344025620000,
			"n": 3
		  },
		  {
			"v": 177968,
			"vw": 21.9935,
			"o": 21.9904,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9904,
			"t": 1344025680000,
			"n": 4
		  },
		  {
			"v": 820400,
			"vw": 22.0258,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344025740000,
			"n": 3
		  },
		  {
			"v": 43792,
			"vw": 21.9906,
			"o": 21.9907,
			"c": 21.9893,
			"h": 21.9907,
			"l": 21.9893,
			"t": 1344025800000,
			"n": 4
		  },
		  {
			"v": 7980,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344025920000,
			"n": 1
		  },
		  {
			"v": 54936,
			"vw": 21.9875,
			"o": 21.9875,
			"c": 21.99,
			"h": 21.99,
			"l": 21.9864,
			"t": 1344026040000,
			"n": 9
		  },
		  {
			"v": 21280,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344026100000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 21.9861,
			"o": 21.9864,
			"c": 21.9857,
			"h": 21.9864,
			"l": 21.9857,
			"t": 1344026220000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 21.9875,
			"o": 21.9875,
			"c": 21.9875,
			"h": 21.9875,
			"l": 21.9875,
			"t": 1344026340000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344026460000,
			"n": 1
		  },
		  {
			"v": 25200,
			"vw": 22.0235,
			"o": 21.9875,
			"c": 21.9875,
			"h": 21.9875,
			"l": 21.9875,
			"t": 1344026520000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.9896,
			"o": 21.9896,
			"c": 21.9896,
			"h": 21.9896,
			"l": 21.9896,
			"t": 1344026580000,
			"n": 1
		  },
		  {
			"v": 45248,
			"vw": 21.986,
			"o": 21.9875,
			"c": 21.9857,
			"h": 21.9875,
			"l": 21.9857,
			"t": 1344026640000,
			"n": 4
		  },
		  {
			"v": 16800,
			"vw": 21.9876,
			"o": 21.9871,
			"c": 21.9857,
			"h": 21.9893,
			"l": 21.9857,
			"t": 1344026700000,
			"n": 6
		  },
		  {
			"v": 39200,
			"vw": 21.9892,
			"o": 21.9889,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9889,
			"t": 1344026760000,
			"n": 8
		  },
		  {
			"v": 16100,
			"vw": 21.9842,
			"o": 21.9846,
			"c": 21.9839,
			"h": 21.9846,
			"l": 21.9839,
			"t": 1344026820000,
			"n": 3
		  },
		  {
			"v": 329084,
			"vw": 21.9893,
			"o": 21.9896,
			"c": 21.99,
			"h": 21.99,
			"l": 21.9893,
			"t": 1344026940000,
			"n": 9
		  },
		  {
			"v": 2800,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344027120000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 21.9894,
			"o": 21.99,
			"c": 21.9893,
			"h": 21.99,
			"l": 21.9893,
			"t": 1344027180000,
			"n": 3
		  },
		  {
			"v": 25200,
			"vw": 21.9869,
			"o": 21.9882,
			"c": 21.9857,
			"h": 21.9882,
			"l": 21.9857,
			"t": 1344027240000,
			"n": 4
		  },
		  {
			"v": 8400,
			"vw": 21.9846,
			"o": 21.9846,
			"c": 21.9846,
			"h": 21.9846,
			"l": 21.9846,
			"t": 1344027420000,
			"n": 1
		  },
		  {
			"v": 12600,
			"vw": 21.9857,
			"o": 21.9857,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9857,
			"t": 1344027480000,
			"n": 2
		  },
		  {
			"v": 4200,
			"vw": 21.9857,
			"o": 21.9857,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9857,
			"t": 1344027840000,
			"n": 1
		  },
		  {
			"v": 23800,
			"vw": 21.9902,
			"o": 21.99,
			"c": 21.9904,
			"h": 21.9904,
			"l": 21.99,
			"t": 1344027960000,
			"n": 5
		  },
		  {
			"v": 4760,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344028260000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9879,
			"o": 21.9879,
			"c": 21.9879,
			"h": 21.9879,
			"l": 21.9879,
			"t": 1344028320000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 21.9859,
			"o": 21.9861,
			"c": 21.9857,
			"h": 21.9861,
			"l": 21.9857,
			"t": 1344028440000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.9886,
			"o": 21.9886,
			"c": 21.9886,
			"h": 21.9886,
			"l": 21.9886,
			"t": 1344028500000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9875,
			"o": 21.9875,
			"c": 21.9875,
			"h": 21.9875,
			"l": 21.9875,
			"t": 1344028560000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 21.9875,
			"o": 21.9875,
			"c": 21.9875,
			"h": 21.9875,
			"l": 21.9875,
			"t": 1344028620000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 21.9884,
			"o": 21.9889,
			"c": 21.9879,
			"h": 21.9889,
			"l": 21.9879,
			"t": 1344029040000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 21.9886,
			"o": 21.9886,
			"c": 21.9886,
			"h": 21.9886,
			"l": 21.9886,
			"t": 1344029100000,
			"n": 1
		  },
		  {
			"v": 6384,
			"vw": 21.9879,
			"o": 21.9879,
			"c": 21.9879,
			"h": 21.9879,
			"l": 21.9879,
			"t": 1344029220000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 21.9886,
			"o": 21.9886,
			"c": 21.9886,
			"h": 21.9886,
			"l": 21.9886,
			"t": 1344029340000,
			"n": 2
		  },
		  {
			"v": 10948,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344029400000,
			"n": 2
		  },
		  {
			"v": 17780,
			"vw": 21.9883,
			"o": 21.9886,
			"c": 21.9875,
			"h": 21.9893,
			"l": 21.9875,
			"t": 1344029760000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344030120000,
			"n": 1
		  },
		  {
			"v": 35084,
			"vw": 21.9903,
			"o": 21.9904,
			"c": 21.9893,
			"h": 21.9907,
			"l": 21.9893,
			"t": 1344030720000,
			"n": 8
		  },
		  {
			"v": 8876,
			"vw": 21.9892,
			"o": 21.9889,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9889,
			"t": 1344030900000,
			"n": 3
		  },
		  {
			"v": 10360,
			"vw": 21.9882,
			"o": 21.9882,
			"c": 21.9882,
			"h": 21.9882,
			"l": 21.9882,
			"t": 1344031860000,
			"n": 1
		  },
		  {
			"v": 21308,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9904,
			"t": 1344031920000,
			"n": 3
		  },
		  {
			"v": 6160,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344032100000,
			"n": 1
		  },
		  {
			"v": 45696,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344032340000,
			"n": 4
		  },
		  {
			"v": 4900,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344032400000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344032460000,
			"n": 1
		  },
		  {
			"v": 16744,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344032580000,
			"n": 2
		  },
		  {
			"v": 18060,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344032820000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 21.9907,
			"o": 21.9907,
			"c": 21.9907,
			"h": 21.9907,
			"l": 21.9907,
			"t": 1344033000000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344033360000,
			"n": 1
		  },
		  {
			"v": 2996,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344033600000,
			"n": 1
		  },
		  {
			"v": 30856,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344033900000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.9861,
			"o": 21.9861,
			"c": 21.9861,
			"h": 21.9861,
			"l": 21.9861,
			"t": 1344034020000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9896,
			"o": 21.9896,
			"c": 21.9896,
			"h": 21.9896,
			"l": 21.9896,
			"t": 1344035220000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344035700000,
			"n": 1
		  },
		  {
			"v": 4200,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344035760000,
			"n": 1
		  },
		  {
			"v": 3556,
			"vw": 21.9864,
			"o": 21.9864,
			"c": 21.9864,
			"h": 21.9864,
			"l": 21.9864,
			"t": 1344036180000,
			"n": 1
		  },
		  {
			"v": 6300,
			"vw": 21.9857,
			"o": 21.9857,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9857,
			"t": 1344036240000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 21.9857,
			"o": 21.9857,
			"c": 21.9857,
			"h": 21.9857,
			"l": 21.9857,
			"t": 1344036360000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.99,
			"o": 21.99,
			"c": 21.99,
			"h": 21.99,
			"l": 21.99,
			"t": 1344036420000,
			"n": 1
		  },
		  {
			"v": 5460,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344036840000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9893,
			"o": 21.9893,
			"c": 21.9893,
			"h": 21.9893,
			"l": 21.9893,
			"t": 1344037020000,
			"n": 1
		  },
		  {
			"v": 146160,
			"vw": 21.9906,
			"o": 21.99,
			"c": 21.9911,
			"h": 21.9911,
			"l": 21.99,
			"t": 1344037140000,
			"n": 12
		  },
		  {
			"v": 5600,
			"vw": 21.9911,
			"o": 21.9911,
			"c": 21.9911,
			"h": 21.9911,
			"l": 21.9911,
			"t": 1344037260000,
			"n": 1
		  },
		  {
			"v": 19320,
			"vw": 21.9963,
			"o": 21.9957,
			"c": 21.9964,
			"h": 21.9964,
			"l": 21.9957,
			"t": 1344037320000,
			"n": 4
		  },
		  {
			"v": 3500,
			"vw": 21.9989,
			"o": 21.9989,
			"c": 21.9989,
			"h": 21.9989,
			"l": 21.9989,
			"t": 1344037440000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 21.9964,
			"o": 21.9964,
			"c": 21.9964,
			"h": 21.9964,
			"l": 21.9964,
			"t": 1344037740000,
			"n": 1
		  },
		  {
			"v": 6888,
			"vw": 21.9996,
			"o": 21.9996,
			"c": 21.9996,
			"h": 21.9996,
			"l": 21.9996,
			"t": 1344037980000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 21.9996,
			"o": 21.9996,
			"c": 21.9996,
			"h": 21.9996,
			"l": 21.9996,
			"t": 1344038040000,
			"n": 2
		  },
		  {
			"v": 18340,
			"vw": 22,
			"o": 22,
			"c": 22,
			"h": 22,
			"l": 22,
			"t": 1344038100000,
			"n": 5
		  },
		  {
			"v": 21140,
			"vw": 22.0002,
			"o": 22,
			"c": 22.0018,
			"h": 22.0018,
			"l": 22,
			"t": 1344038160000,
			"n": 3
		  },
		  {
			"v": 39760,
			"vw": 21.9985,
			"o": 21.9989,
			"c": 22,
			"h": 22,
			"l": 21.9964,
			"t": 1344038280000,
			"n": 4
		  },
		  {
			"v": 3332,
			"vw": 22,
			"o": 22,
			"c": 22,
			"h": 22,
			"l": 22,
			"t": 1344038340000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0539,
			"o": 22.0539,
			"c": 22.0539,
			"h": 22.0539,
			"l": 22.0539,
			"t": 1344242880000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.0661,
			"o": 22.0661,
			"c": 22.0661,
			"h": 22.0661,
			"l": 22.0661,
			"t": 1344248820000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 22.0555,
			"o": 22.0571,
			"c": 22.0554,
			"h": 22.0571,
			"l": 22.0554,
			"t": 1344248940000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0661,
			"o": 22.0661,
			"c": 22.0661,
			"h": 22.0661,
			"l": 22.0661,
			"t": 1344249300000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0661,
			"o": 22.0661,
			"c": 22.0661,
			"h": 22.0661,
			"l": 22.0661,
			"t": 1344249420000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.0596,
			"o": 22.0625,
			"c": 22.0589,
			"h": 22.0625,
			"l": 22.0589,
			"t": 1344250740000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0589,
			"o": 22.0589,
			"c": 22.0589,
			"h": 22.0589,
			"l": 22.0589,
			"t": 1344250800000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.0589,
			"o": 22.0589,
			"c": 22.0589,
			"h": 22.0589,
			"l": 22.0589,
			"t": 1344250860000,
			"n": 2
		  },
		  {
			"v": 11200,
			"vw": 22.0558,
			"o": 22.0554,
			"c": 22.0554,
			"h": 22.0571,
			"l": 22.0554,
			"t": 1344250920000,
			"n": 4
		  },
		  {
			"v": 27440,
			"vw": 22.0568,
			"o": 22.0554,
			"c": 22.0582,
			"h": 22.0582,
			"l": 22.0554,
			"t": 1344250980000,
			"n": 5
		  },
		  {
			"v": 20720,
			"vw": 22.0637,
			"o": 22.0586,
			"c": 22.0664,
			"h": 22.0664,
			"l": 22.0586,
			"t": 1344251040000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.0707,
			"o": 22.0707,
			"c": 22.0707,
			"h": 22.0707,
			"l": 22.0707,
			"t": 1344251100000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.0713,
			"o": 22.0707,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0707,
			"t": 1344251160000,
			"n": 2
		  },
		  {
			"v": 41272,
			"vw": 22.0656,
			"o": 22.0679,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0593,
			"t": 1344251280000,
			"n": 7
		  },
		  {
			"v": 8400,
			"vw": 22.0643,
			"o": 22.0643,
			"c": 22.0643,
			"h": 22.0643,
			"l": 22.0643,
			"t": 1344251340000,
			"n": 2
		  },
		  {
			"v": 14980,
			"vw": 22.0714,
			"o": 22.0643,
			"c": 22.0786,
			"h": 22.0786,
			"l": 22.0643,
			"t": 1344251400000,
			"n": 5
		  },
		  {
			"v": 13748,
			"vw": 22.0716,
			"o": 22.0718,
			"c": 22.0714,
			"h": 22.0718,
			"l": 22.0714,
			"t": 1344251460000,
			"n": 2
		  },
		  {
			"v": 30520,
			"vw": 22.0806,
			"o": 22.0804,
			"c": 22.0829,
			"h": 22.0829,
			"l": 22.0804,
			"t": 1344251520000,
			"n": 5
		  },
		  {
			"v": 22400,
			"vw": 22.0858,
			"o": 22.0832,
			"c": 22.09,
			"h": 22.09,
			"l": 22.0832,
			"t": 1344251580000,
			"n": 6
		  },
		  {
			"v": 36400,
			"vw": 22.0926,
			"o": 22.09,
			"c": 22.09,
			"h": 22.0982,
			"l": 22.09,
			"t": 1344251640000,
			"n": 7
		  },
		  {
			"v": 8400,
			"vw": 22.0889,
			"o": 22.0882,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0882,
			"t": 1344251760000,
			"n": 3
		  },
		  {
			"v": 14112,
			"vw": 22.098,
			"o": 22.095,
			"c": 22.1014,
			"h": 22.1014,
			"l": 22.095,
			"t": 1344251820000,
			"n": 4
		  },
		  {
			"v": 4200,
			"vw": 22.0961,
			"o": 22.0961,
			"c": 22.0961,
			"h": 22.0961,
			"l": 22.0961,
			"t": 1344251940000,
			"n": 1
		  },
		  {
			"v": 28000,
			"vw": 22.0902,
			"o": 22.0943,
			"c": 22.0754,
			"h": 22.0954,
			"l": 22.0754,
			"t": 1344252000000,
			"n": 9
		  },
		  {
			"v": 2800,
			"vw": 22.0754,
			"o": 22.0754,
			"c": 22.0754,
			"h": 22.0754,
			"l": 22.0754,
			"t": 1344252120000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.0773,
			"o": 22.0714,
			"c": 22.0839,
			"h": 22.0839,
			"l": 22.0714,
			"t": 1344252180000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0804,
			"o": 22.0804,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0804,
			"t": 1344252240000,
			"n": 1
		  },
		  {
			"v": 11424,
			"vw": 22.0661,
			"o": 22.0661,
			"c": 22.0661,
			"h": 22.0661,
			"l": 22.0661,
			"t": 1344252360000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344252720000,
			"n": 1
		  },
		  {
			"v": 6300,
			"vw": 22.0674,
			"o": 22.0686,
			"c": 22.0664,
			"h": 22.0686,
			"l": 22.0664,
			"t": 1344252780000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344252840000,
			"n": 1
		  },
		  {
			"v": 32340,
			"vw": 22.0785,
			"o": 22.0779,
			"c": 22.0786,
			"h": 22.0786,
			"l": 22.0779,
			"t": 1344252900000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.0754,
			"o": 22.0754,
			"c": 22.0754,
			"h": 22.0754,
			"l": 22.0754,
			"t": 1344252960000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.0792,
			"o": 22.0786,
			"c": 22.0829,
			"h": 22.0829,
			"l": 22.0786,
			"t": 1344253020000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.0844,
			"o": 22.0832,
			"c": 22.0846,
			"h": 22.0846,
			"l": 22.0832,
			"t": 1344253080000,
			"n": 4
		  },
		  {
			"v": 28000,
			"vw": 22.0864,
			"o": 22.0857,
			"c": 22.0921,
			"h": 22.0921,
			"l": 22.0857,
			"t": 1344253200000,
			"n": 3
		  },
		  {
			"v": 28000,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344253260000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.0921,
			"o": 22.0921,
			"c": 22.0921,
			"h": 22.0921,
			"l": 22.0921,
			"t": 1344253320000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.0925,
			"o": 22.0929,
			"c": 22.0921,
			"h": 22.0929,
			"l": 22.0921,
			"t": 1344253380000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0868,
			"o": 22.0868,
			"c": 22.0868,
			"h": 22.0868,
			"l": 22.0868,
			"t": 1344253440000,
			"n": 1
		  },
		  {
			"v": 4004,
			"vw": 22.0875,
			"o": 22.0875,
			"c": 22.0875,
			"h": 22.0875,
			"l": 22.0875,
			"t": 1344253500000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0874,
			"o": 22.0864,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0864,
			"t": 1344253560000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0864,
			"o": 22.0864,
			"c": 22.0864,
			"h": 22.0864,
			"l": 22.0864,
			"t": 1344253620000,
			"n": 1
		  },
		  {
			"v": 3920,
			"vw": 22.0929,
			"o": 22.0929,
			"c": 22.0929,
			"h": 22.0929,
			"l": 22.0929,
			"t": 1344253680000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.092,
			"o": 22.0868,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0868,
			"t": 1344253740000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0964,
			"o": 22.0964,
			"c": 22.0964,
			"h": 22.0964,
			"l": 22.0964,
			"t": 1344253800000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.0964,
			"o": 22.0964,
			"c": 22.0964,
			"h": 22.0964,
			"l": 22.0964,
			"t": 1344253860000,
			"n": 3
		  },
		  {
			"v": 5992,
			"vw": 22.0946,
			"o": 22.0946,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0946,
			"t": 1344253920000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.0946,
			"o": 22.0946,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0946,
			"t": 1344254220000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0919,
			"o": 22.0925,
			"c": 22.0914,
			"h": 22.0925,
			"l": 22.0914,
			"t": 1344254280000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0946,
			"o": 22.0946,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0946,
			"t": 1344254340000,
			"n": 1
		  },
		  {
			"v": 83636,
			"vw": 22.0845,
			"o": 22.0875,
			"c": 22.0754,
			"h": 22.0921,
			"l": 22.0736,
			"t": 1344254400000,
			"n": 19
		  },
		  {
			"v": 27440,
			"vw": 22.0746,
			"o": 22.0761,
			"c": 22.0725,
			"h": 22.0761,
			"l": 22.0725,
			"t": 1344254460000,
			"n": 9
		  },
		  {
			"v": 43820,
			"vw": 22.0762,
			"o": 22.0757,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0732,
			"t": 1344254520000,
			"n": 12
		  },
		  {
			"v": 36400,
			"vw": 22.0843,
			"o": 22.0839,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0839,
			"t": 1344254580000,
			"n": 5
		  },
		  {
			"v": 14000,
			"vw": 22.09,
			"o": 22.09,
			"c": 22.09,
			"h": 22.09,
			"l": 22.09,
			"t": 1344254640000,
			"n": 1
		  },
		  {
			"v": 6440,
			"vw": 22.0879,
			"o": 22.0861,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0861,
			"t": 1344254760000,
			"n": 2
		  },
		  {
			"v": 16100,
			"vw": 22.0818,
			"o": 22.0761,
			"c": 22.0839,
			"h": 22.085,
			"l": 22.0761,
			"t": 1344254820000,
			"n": 4
		  },
		  {
			"v": 31500,
			"vw": 22.0805,
			"o": 22.0821,
			"c": 22.0789,
			"h": 22.0839,
			"l": 22.0754,
			"t": 1344254880000,
			"n": 11
		  },
		  {
			"v": 213164,
			"vw": 22.0733,
			"o": 22.0786,
			"c": 22.0725,
			"h": 22.0786,
			"l": 22.0714,
			"t": 1344254940000,
			"n": 36
		  },
		  {
			"v": 36400,
			"vw": 22.0768,
			"o": 22.0757,
			"c": 22.0729,
			"h": 22.08,
			"l": 22.0729,
			"t": 1344255000000,
			"n": 11
		  },
		  {
			"v": 128800,
			"vw": 22.0723,
			"o": 22.0739,
			"c": 22.0714,
			"h": 22.0743,
			"l": 22.0714,
			"t": 1344255060000,
			"n": 42
		  },
		  {
			"v": 30800,
			"vw": 22.073,
			"o": 22.0714,
			"c": 22.0718,
			"h": 22.0793,
			"l": 22.0714,
			"t": 1344255120000,
			"n": 11
		  },
		  {
			"v": 49084,
			"vw": 22.0755,
			"o": 22.0714,
			"c": 22.0818,
			"h": 22.0818,
			"l": 22.0714,
			"t": 1344255180000,
			"n": 10
		  },
		  {
			"v": 8400,
			"vw": 22.08,
			"o": 22.0811,
			"c": 22.0768,
			"h": 22.0821,
			"l": 22.0768,
			"t": 1344255240000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.0757,
			"o": 22.0771,
			"c": 22.075,
			"h": 22.0771,
			"l": 22.075,
			"t": 1344255300000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0811,
			"o": 22.0811,
			"c": 22.0811,
			"h": 22.0811,
			"l": 22.0811,
			"t": 1344255360000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.0753,
			"o": 22.0786,
			"c": 22.0725,
			"h": 22.0786,
			"l": 22.0725,
			"t": 1344255420000,
			"n": 6
		  },
		  {
			"v": 14000,
			"vw": 22.0776,
			"o": 22.0771,
			"c": 22.0779,
			"h": 22.0779,
			"l": 22.0771,
			"t": 1344255480000,
			"n": 4
		  },
		  {
			"v": 16800,
			"vw": 22.0738,
			"o": 22.0732,
			"c": 22.0721,
			"h": 22.0771,
			"l": 22.0714,
			"t": 1344255540000,
			"n": 6
		  },
		  {
			"v": 22400,
			"vw": 22.0722,
			"o": 22.0775,
			"c": 22.0714,
			"h": 22.0775,
			"l": 22.0714,
			"t": 1344255600000,
			"n": 4
		  },
		  {
			"v": 69748,
			"vw": 22.0785,
			"o": 22.0718,
			"c": 22.0807,
			"h": 22.0811,
			"l": 22.0714,
			"t": 1344255660000,
			"n": 13
		  },
		  {
			"v": 15260,
			"vw": 22.082,
			"o": 22.0736,
			"c": 22.0839,
			"h": 22.0839,
			"l": 22.0736,
			"t": 1344255780000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0784,
			"o": 22.0779,
			"c": 22.0789,
			"h": 22.0789,
			"l": 22.0779,
			"t": 1344255840000,
			"n": 2
		  },
		  {
			"v": 2912,
			"vw": 22.0789,
			"o": 22.0789,
			"c": 22.0789,
			"h": 22.0789,
			"l": 22.0789,
			"t": 1344255900000,
			"n": 1
		  },
		  {
			"v": 10416,
			"vw": 22.0779,
			"o": 22.0779,
			"c": 22.0779,
			"h": 22.0779,
			"l": 22.0779,
			"t": 1344255960000,
			"n": 2
		  },
		  {
			"v": 8120,
			"vw": 22.0803,
			"o": 22.0796,
			"c": 22.0807,
			"h": 22.0807,
			"l": 22.0796,
			"t": 1344256020000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0764,
			"o": 22.0764,
			"c": 22.0764,
			"h": 22.0764,
			"l": 22.0764,
			"t": 1344256080000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0789,
			"o": 22.0789,
			"c": 22.0789,
			"h": 22.0789,
			"l": 22.0789,
			"t": 1344256140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0825,
			"o": 22.0825,
			"c": 22.0825,
			"h": 22.0825,
			"l": 22.0825,
			"t": 1344256200000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0789,
			"o": 22.0789,
			"c": 22.0789,
			"h": 22.0789,
			"l": 22.0789,
			"t": 1344256260000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.08,
			"o": 22.0796,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0796,
			"t": 1344256320000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.085,
			"o": 22.085,
			"c": 22.085,
			"h": 22.085,
			"l": 22.085,
			"t": 1344256380000,
			"n": 1
		  },
		  {
			"v": 11480,
			"vw": 22.085,
			"o": 22.085,
			"c": 22.085,
			"h": 22.085,
			"l": 22.085,
			"t": 1344256440000,
			"n": 3
		  },
		  {
			"v": 19600,
			"vw": 22.0822,
			"o": 22.0821,
			"c": 22.0821,
			"h": 22.0825,
			"l": 22.0821,
			"t": 1344256500000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.0846,
			"o": 22.0846,
			"c": 22.0846,
			"h": 22.0846,
			"l": 22.0846,
			"t": 1344256560000,
			"n": 1
		  },
		  {
			"v": 17080,
			"vw": 22.0838,
			"o": 22.085,
			"c": 22.0821,
			"h": 22.0861,
			"l": 22.0821,
			"t": 1344256620000,
			"n": 4
		  },
		  {
			"v": 66220,
			"vw": 22.0791,
			"o": 22.0825,
			"c": 22.075,
			"h": 22.0825,
			"l": 22.075,
			"t": 1344256740000,
			"n": 16
		  },
		  {
			"v": 30240,
			"vw": 22.0797,
			"o": 22.0821,
			"c": 22.0782,
			"h": 22.0821,
			"l": 22.0779,
			"t": 1344256860000,
			"n": 7
		  },
		  {
			"v": 47600,
			"vw": 22.08,
			"o": 22.0818,
			"c": 22.0818,
			"h": 22.0821,
			"l": 22.0782,
			"t": 1344256920000,
			"n": 10
		  },
		  {
			"v": 11200,
			"vw": 22.0812,
			"o": 22.0804,
			"c": 22.0818,
			"h": 22.0818,
			"l": 22.0804,
			"t": 1344256980000,
			"n": 4
		  },
		  {
			"v": 26180,
			"vw": 22.0817,
			"o": 22.0818,
			"c": 22.0821,
			"h": 22.0821,
			"l": 22.0804,
			"t": 1344257040000,
			"n": 7
		  },
		  {
			"v": 47600,
			"vw": 22.082,
			"o": 22.0821,
			"c": 22.08,
			"h": 22.0857,
			"l": 22.08,
			"t": 1344257100000,
			"n": 15
		  },
		  {
			"v": 5600,
			"vw": 22.077,
			"o": 22.0754,
			"c": 22.0786,
			"h": 22.0786,
			"l": 22.0754,
			"t": 1344257220000,
			"n": 2
		  },
		  {
			"v": 16800,
			"vw": 22.0751,
			"o": 22.075,
			"c": 22.075,
			"h": 22.0754,
			"l": 22.075,
			"t": 1344257280000,
			"n": 5
		  },
		  {
			"v": 25200,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344257340000,
			"n": 4
		  },
		  {
			"v": 21700,
			"vw": 22.0766,
			"o": 22.0768,
			"c": 22.075,
			"h": 22.0779,
			"l": 22.075,
			"t": 1344257400000,
			"n": 4
		  },
		  {
			"v": 16212,
			"vw": 22.0748,
			"o": 22.075,
			"c": 22.0739,
			"h": 22.075,
			"l": 22.0739,
			"t": 1344257460000,
			"n": 2
		  },
		  {
			"v": 25200,
			"vw": 22.0737,
			"o": 22.0736,
			"c": 22.0732,
			"h": 22.075,
			"l": 22.0732,
			"t": 1344257520000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0725,
			"o": 22.0725,
			"c": 22.0725,
			"h": 22.0725,
			"l": 22.0725,
			"t": 1344257580000,
			"n": 1
		  },
		  {
			"v": 81284,
			"vw": 22.0708,
			"o": 22.0732,
			"c": 22.0725,
			"h": 22.075,
			"l": 22.0689,
			"t": 1344257640000,
			"n": 18
		  },
		  {
			"v": 14000,
			"vw": 22.0714,
			"o": 22.0739,
			"c": 22.0696,
			"h": 22.0746,
			"l": 22.0693,
			"t": 1344257700000,
			"n": 5
		  },
		  {
			"v": 9800,
			"vw": 22.0701,
			"o": 22.0714,
			"c": 22.0693,
			"h": 22.0714,
			"l": 22.0689,
			"t": 1344257760000,
			"n": 3
		  },
		  {
			"v": 6580,
			"vw": 22.0726,
			"o": 22.0718,
			"c": 22.0732,
			"h": 22.0732,
			"l": 22.0718,
			"t": 1344257820000,
			"n": 2
		  },
		  {
			"v": 22400,
			"vw": 22.07,
			"o": 22.0693,
			"c": 22.0714,
			"h": 22.0725,
			"l": 22.0689,
			"t": 1344257880000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.0743,
			"o": 22.0743,
			"c": 22.0743,
			"h": 22.0743,
			"l": 22.0743,
			"t": 1344257940000,
			"n": 1
		  },
		  {
			"v": 44800,
			"vw": 22.0741,
			"o": 22.0743,
			"c": 22.075,
			"h": 22.075,
			"l": 22.0714,
			"t": 1344258060000,
			"n": 13
		  },
		  {
			"v": 2800,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344258120000,
			"n": 1
		  },
		  {
			"v": 20160,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344258180000,
			"n": 2
		  },
		  {
			"v": 141680,
			"vw": 22.0781,
			"o": 22.075,
			"c": 22.0821,
			"h": 22.0821,
			"l": 22.075,
			"t": 1344258240000,
			"n": 28
		  },
		  {
			"v": 212492,
			"vw": 22.086,
			"o": 22.0811,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.075,
			"t": 1344258300000,
			"n": 36
		  },
		  {
			"v": 39200,
			"vw": 22.0846,
			"o": 22.0857,
			"c": 22.0868,
			"h": 22.0875,
			"l": 22.0804,
			"t": 1344258480000,
			"n": 8
		  },
		  {
			"v": 36400,
			"vw": 22.0813,
			"o": 22.0789,
			"c": 22.0821,
			"h": 22.0825,
			"l": 22.0789,
			"t": 1344258540000,
			"n": 11
		  },
		  {
			"v": 16800,
			"vw": 22.0815,
			"o": 22.0818,
			"c": 22.0829,
			"h": 22.0829,
			"l": 22.0804,
			"t": 1344258600000,
			"n": 6
		  },
		  {
			"v": 11200,
			"vw": 22.0823,
			"o": 22.0821,
			"c": 22.0821,
			"h": 22.0829,
			"l": 22.0821,
			"t": 1344258660000,
			"n": 4
		  },
		  {
			"v": 21392,
			"vw": 22.081,
			"o": 22.0821,
			"c": 22.0789,
			"h": 22.0821,
			"l": 22.0789,
			"t": 1344258720000,
			"n": 5
		  },
		  {
			"v": 8400,
			"vw": 22.0827,
			"o": 22.0821,
			"c": 22.0839,
			"h": 22.0839,
			"l": 22.0821,
			"t": 1344258780000,
			"n": 3
		  },
		  {
			"v": 52500,
			"vw": 22.088,
			"o": 22.0861,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0861,
			"t": 1344258840000,
			"n": 13
		  },
		  {
			"v": 8400,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344258900000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0918,
			"o": 22.0918,
			"c": 22.0918,
			"h": 22.0918,
			"l": 22.0918,
			"t": 1344259020000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.0898,
			"o": 22.0911,
			"c": 22.0861,
			"h": 22.0911,
			"l": 22.0861,
			"t": 1344259080000,
			"n": 3
		  },
		  {
			"v": 77476,
			"vw": 22.0804,
			"o": 22.0861,
			"c": 22.0725,
			"h": 22.0861,
			"l": 22.0714,
			"t": 1344259140000,
			"n": 21
		  },
		  {
			"v": 44436,
			"vw": 22.073,
			"o": 22.0786,
			"c": 22.0714,
			"h": 22.0786,
			"l": 22.0714,
			"t": 1344259200000,
			"n": 9
		  },
		  {
			"v": 5600,
			"vw": 22.0752,
			"o": 22.075,
			"c": 22.0754,
			"h": 22.0754,
			"l": 22.075,
			"t": 1344259260000,
			"n": 2
		  },
		  {
			"v": 71792,
			"vw": 22.0717,
			"o": 22.0718,
			"c": 22.0714,
			"h": 22.0761,
			"l": 22.0714,
			"t": 1344259320000,
			"n": 15
		  },
		  {
			"v": 8400,
			"vw": 22.0692,
			"o": 22.0689,
			"c": 22.0696,
			"h": 22.0696,
			"l": 22.0689,
			"t": 1344259380000,
			"n": 2
		  },
		  {
			"v": 81116,
			"vw": 22.0655,
			"o": 22.0689,
			"c": 22.0625,
			"h": 22.07,
			"l": 22.0625,
			"t": 1344259440000,
			"n": 14
		  },
		  {
			"v": 47600,
			"vw": 22.0653,
			"o": 22.0586,
			"c": 22.0671,
			"h": 22.07,
			"l": 22.0586,
			"t": 1344259560000,
			"n": 14
		  },
		  {
			"v": 33600,
			"vw": 22.0635,
			"o": 22.0643,
			"c": 22.0629,
			"h": 22.0668,
			"l": 22.0625,
			"t": 1344259620000,
			"n": 10
		  },
		  {
			"v": 211484,
			"vw": 22.0501,
			"o": 22.0582,
			"c": 22.0479,
			"h": 22.0629,
			"l": 22.0375,
			"t": 1344259680000,
			"n": 35
		  },
		  {
			"v": 203812,
			"vw": 22.0415,
			"o": 22.0464,
			"c": 22.0393,
			"h": 22.0479,
			"l": 22.0361,
			"t": 1344259740000,
			"n": 22
		  },
		  {
			"v": 4489184,
			"vw": 22.0427,
			"o": 22.0461,
			"c": 22.0495,
			"h": 22.0554,
			"l": 22.0368,
			"t": 1344259800000,
			"n": 487
		  },
		  {
			"v": 2359196,
			"vw": 22.0278,
			"o": 22.045,
			"c": 22.0239,
			"h": 22.0543,
			"l": 22.0096,
			"t": 1344259860000,
			"n": 519
		  },
		  {
			"v": 2575972,
			"vw": 22.0058,
			"o": 22.0275,
			"c": 22.0107,
			"h": 22.0289,
			"l": 21.9972,
			"t": 1344259920000,
			"n": 424
		  },
		  {
			"v": 1689828,
			"vw": 22.0209,
			"o": 22.0143,
			"c": 22.0185,
			"h": 22.0325,
			"l": 22.0068,
			"t": 1344259980000,
			"n": 405
		  },
		  {
			"v": 2040500,
			"vw": 22.0035,
			"o": 22.0125,
			"c": 22.0024,
			"h": 22.0178,
			"l": 21.9911,
			"t": 1344260040000,
			"n": 424
		  },
		  {
			"v": 2866164,
			"vw": 21.9952,
			"o": 22.0014,
			"c": 22.0268,
			"h": 22.0268,
			"l": 21.9736,
			"t": 1344260100000,
			"n": 629
		  },
		  {
			"v": 2872884,
			"vw": 22.0472,
			"o": 22.019,
			"c": 22.0496,
			"h": 22.0686,
			"l": 22.019,
			"t": 1344260160000,
			"n": 673
		  },
		  {
			"v": 2114056,
			"vw": 22.0639,
			"o": 22.0511,
			"c": 22.0743,
			"h": 22.0775,
			"l": 22.0486,
			"t": 1344260220000,
			"n": 490
		  },
		  {
			"v": 2691528,
			"vw": 22.0718,
			"o": 22.0739,
			"c": 22.0593,
			"h": 22.0921,
			"l": 22.0536,
			"t": 1344260280000,
			"n": 544
		  },
		  {
			"v": 1859060,
			"vw": 22.0411,
			"o": 22.0593,
			"c": 22.0382,
			"h": 22.0614,
			"l": 22.0321,
			"t": 1344260340000,
			"n": 444
		  },
		  {
			"v": 1180676,
			"vw": 22.0419,
			"o": 22.0404,
			"c": 22.0532,
			"h": 22.0607,
			"l": 22.025,
			"t": 1344260400000,
			"n": 268
		  },
		  {
			"v": 1295924,
			"vw": 22.0701,
			"o": 22.0504,
			"c": 22.0704,
			"h": 22.0796,
			"l": 22.0496,
			"t": 1344260460000,
			"n": 293
		  },
		  {
			"v": 867692,
			"vw": 22.0644,
			"o": 22.065,
			"c": 22.0668,
			"h": 22.0711,
			"l": 22.0589,
			"t": 1344260520000,
			"n": 200
		  },
		  {
			"v": 901096,
			"vw": 22.068,
			"o": 22.0671,
			"c": 22.0721,
			"h": 22.0732,
			"l": 22.0589,
			"t": 1344260580000,
			"n": 234
		  },
		  {
			"v": 815136,
			"vw": 22.0684,
			"o": 22.0721,
			"c": 22.065,
			"h": 22.0732,
			"l": 22.0604,
			"t": 1344260640000,
			"n": 173
		  },
		  {
			"v": 979888,
			"vw": 22.0571,
			"o": 22.0629,
			"c": 22.0564,
			"h": 22.0671,
			"l": 22.0482,
			"t": 1344260700000,
			"n": 227
		  },
		  {
			"v": 1306508,
			"vw": 22.0685,
			"o": 22.0564,
			"c": 22.0775,
			"h": 22.0804,
			"l": 22.0564,
			"t": 1344260760000,
			"n": 319
		  },
		  {
			"v": 550564,
			"vw": 22.0724,
			"o": 22.0775,
			"c": 22.0741,
			"h": 22.0804,
			"l": 22.0621,
			"t": 1344260820000,
			"n": 138
		  },
		  {
			"v": 2453472,
			"vw": 22.091,
			"o": 22.0714,
			"c": 22.0893,
			"h": 22.1029,
			"l": 22.0714,
			"t": 1344260880000,
			"n": 524
		  },
		  {
			"v": 1755292,
			"vw": 22.0832,
			"o": 22.0886,
			"c": 22.0836,
			"h": 22.0974,
			"l": 22.0714,
			"t": 1344260940000,
			"n": 359
		  },
		  {
			"v": 2161040,
			"vw": 22.0555,
			"o": 22.0829,
			"c": 22.0496,
			"h": 22.0914,
			"l": 22.0357,
			"t": 1344261000000,
			"n": 496
		  },
		  {
			"v": 701736,
			"vw": 22.0521,
			"o": 22.05,
			"c": 22.0489,
			"h": 22.06,
			"l": 22.0404,
			"t": 1344261060000,
			"n": 148
		  },
		  {
			"v": 658084,
			"vw": 22.0552,
			"o": 22.0538,
			"c": 22.0511,
			"h": 22.0625,
			"l": 22.0446,
			"t": 1344261120000,
			"n": 155
		  },
		  {
			"v": 1203468,
			"vw": 22.0604,
			"o": 22.0471,
			"c": 22.0611,
			"h": 22.0704,
			"l": 22.0446,
			"t": 1344261180000,
			"n": 236
		  },
		  {
			"v": 513520,
			"vw": 22.0609,
			"o": 22.0625,
			"c": 22.0575,
			"h": 22.07,
			"l": 22.0546,
			"t": 1344261240000,
			"n": 148
		  },
		  {
			"v": 1531712,
			"vw": 22.0801,
			"o": 22.0589,
			"c": 22.0861,
			"h": 22.0964,
			"l": 22.0589,
			"t": 1344261300000,
			"n": 327
		  },
		  {
			"v": 754544,
			"vw": 22.0777,
			"o": 22.0868,
			"c": 22.075,
			"h": 22.0886,
			"l": 22.0643,
			"t": 1344261360000,
			"n": 176
		  },
		  {
			"v": 629356,
			"vw": 22.0762,
			"o": 22.0743,
			"c": 22.0686,
			"h": 22.0882,
			"l": 22.0643,
			"t": 1344261420000,
			"n": 152
		  },
		  {
			"v": 460740,
			"vw": 22.0719,
			"o": 22.0671,
			"c": 22.0761,
			"h": 22.0875,
			"l": 22.0643,
			"t": 1344261480000,
			"n": 115
		  },
		  {
			"v": 603428,
			"vw": 22.0658,
			"o": 22.0718,
			"c": 22.0646,
			"h": 22.0739,
			"l": 22.0596,
			"t": 1344261540000,
			"n": 139
		  },
		  {
			"v": 1036224,
			"vw": 22.0651,
			"o": 22.0622,
			"c": 22.075,
			"h": 22.075,
			"l": 22.0536,
			"t": 1344261600000,
			"n": 272
		  },
		  {
			"v": 803432,
			"vw": 22.0772,
			"o": 22.075,
			"c": 22.0714,
			"h": 22.0836,
			"l": 22.0704,
			"t": 1344261660000,
			"n": 174
		  },
		  {
			"v": 664412,
			"vw": 22.0823,
			"o": 22.0754,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0743,
			"t": 1344261720000,
			"n": 147
		  },
		  {
			"v": 812336,
			"vw": 22.0855,
			"o": 22.0893,
			"c": 22.0849,
			"h": 22.09,
			"l": 22.079,
			"t": 1344261780000,
			"n": 191
		  },
		  {
			"v": 1060136,
			"vw": 22.074,
			"o": 22.0836,
			"c": 22.0729,
			"h": 22.0854,
			"l": 22.0639,
			"t": 1344261840000,
			"n": 257
		  },
		  {
			"v": 546112,
			"vw": 22.073,
			"o": 22.0778,
			"c": 22.0704,
			"h": 22.0786,
			"l": 22.0704,
			"t": 1344261900000,
			"n": 123
		  },
		  {
			"v": 1783264,
			"vw": 22.0909,
			"o": 22.0732,
			"c": 22.0968,
			"h": 22.1021,
			"l": 22.0732,
			"t": 1344261960000,
			"n": 308
		  },
		  {
			"v": 2965200,
			"vw": 22.1088,
			"o": 22.1011,
			"c": 22.1121,
			"h": 22.1154,
			"l": 22.0957,
			"t": 1344262020000,
			"n": 640
		  },
		  {
			"v": 1590456,
			"vw": 22.0971,
			"o": 22.1085,
			"c": 22.0857,
			"h": 22.1139,
			"l": 22.0814,
			"t": 1344262080000,
			"n": 380
		  },
		  {
			"v": 681240,
			"vw": 22.092,
			"o": 22.0924,
			"c": 22.0986,
			"h": 22.1004,
			"l": 22.0836,
			"t": 1344262140000,
			"n": 144
		  },
		  {
			"v": 612136,
			"vw": 22.0994,
			"o": 22.1021,
			"c": 22.0893,
			"h": 22.1071,
			"l": 22.0893,
			"t": 1344262200000,
			"n": 158
		  },
		  {
			"v": 746312,
			"vw": 22.0923,
			"o": 22.0893,
			"c": 22.0989,
			"h": 22.1011,
			"l": 22.0846,
			"t": 1344262260000,
			"n": 215
		  },
		  {
			"v": 1003128,
			"vw": 22.1026,
			"o": 22.0986,
			"c": 22.1036,
			"h": 22.11,
			"l": 22.0932,
			"t": 1344262320000,
			"n": 241
		  },
		  {
			"v": 768712,
			"vw": 22.1062,
			"o": 22.0989,
			"c": 22.1036,
			"h": 22.1121,
			"l": 22.0989,
			"t": 1344262380000,
			"n": 177
		  },
		  {
			"v": 593656,
			"vw": 22.094,
			"o": 22.105,
			"c": 22.0864,
			"h": 22.1071,
			"l": 22.0843,
			"t": 1344262440000,
			"n": 143
		  },
		  {
			"v": 1041768,
			"vw": 22.0838,
			"o": 22.0861,
			"c": 22.0818,
			"h": 22.0932,
			"l": 22.0775,
			"t": 1344262500000,
			"n": 214
		  },
		  {
			"v": 1266524,
			"vw": 22.0781,
			"o": 22.0818,
			"c": 22.0758,
			"h": 22.0875,
			"l": 22.0714,
			"t": 1344262560000,
			"n": 283
		  },
		  {
			"v": 472780,
			"vw": 22.0774,
			"o": 22.0814,
			"c": 22.0736,
			"h": 22.0821,
			"l": 22.0736,
			"t": 1344262620000,
			"n": 120
		  },
		  {
			"v": 567700,
			"vw": 22.0753,
			"o": 22.0779,
			"c": 22.0825,
			"h": 22.0843,
			"l": 22.0714,
			"t": 1344262680000,
			"n": 129
		  },
		  {
			"v": 575092,
			"vw": 22.0938,
			"o": 22.0843,
			"c": 22.096,
			"h": 22.1018,
			"l": 22.0819,
			"t": 1344262740000,
			"n": 133
		  },
		  {
			"v": 547008,
			"vw": 22.0963,
			"o": 22.0975,
			"c": 22.0975,
			"h": 22.1007,
			"l": 22.0882,
			"t": 1344262800000,
			"n": 120
		  },
		  {
			"v": 536732,
			"vw": 22.0865,
			"o": 22.0918,
			"c": 22.0797,
			"h": 22.0937,
			"l": 22.0786,
			"t": 1344262860000,
			"n": 116
		  },
		  {
			"v": 204764,
			"vw": 22.0823,
			"o": 22.0807,
			"c": 22.0788,
			"h": 22.0857,
			"l": 22.0786,
			"t": 1344262920000,
			"n": 50
		  },
		  {
			"v": 364028,
			"vw": 22.0801,
			"o": 22.0786,
			"c": 22.0861,
			"h": 22.0886,
			"l": 22.0757,
			"t": 1344262980000,
			"n": 74
		  },
		  {
			"v": 325220,
			"vw": 22.0812,
			"o": 22.0886,
			"c": 22.0786,
			"h": 22.0886,
			"l": 22.0786,
			"t": 1344263040000,
			"n": 85
		  },
		  {
			"v": 353024,
			"vw": 22.08,
			"o": 22.0786,
			"c": 22.0818,
			"h": 22.0857,
			"l": 22.0761,
			"t": 1344263100000,
			"n": 88
		  },
		  {
			"v": 595784,
			"vw": 22.081,
			"o": 22.0804,
			"c": 22.0832,
			"h": 22.0868,
			"l": 22.0764,
			"t": 1344263160000,
			"n": 160
		  },
		  {
			"v": 276696,
			"vw": 22.0832,
			"o": 22.0811,
			"c": 22.0854,
			"h": 22.0864,
			"l": 22.079,
			"t": 1344263220000,
			"n": 51
		  },
		  {
			"v": 586124,
			"vw": 22.0912,
			"o": 22.0857,
			"c": 22.0929,
			"h": 22.0964,
			"l": 22.0846,
			"t": 1344263280000,
			"n": 116
		  },
		  {
			"v": 583940,
			"vw": 22.0973,
			"o": 22.0946,
			"c": 22.1018,
			"h": 22.1032,
			"l": 22.0932,
			"t": 1344263340000,
			"n": 104
		  },
		  {
			"v": 1042384,
			"vw": 22.1003,
			"o": 22.1029,
			"c": 22.1029,
			"h": 22.105,
			"l": 22.0922,
			"t": 1344263400000,
			"n": 277
		  },
		  {
			"v": 734580,
			"vw": 22.1026,
			"o": 22.1029,
			"c": 22.0984,
			"h": 22.1054,
			"l": 22.0971,
			"t": 1344263460000,
			"n": 145
		  },
		  {
			"v": 1899800,
			"vw": 22.1119,
			"o": 22.1029,
			"c": 22.1143,
			"h": 22.1239,
			"l": 22.0993,
			"t": 1344263520000,
			"n": 405
		  },
		  {
			"v": 1650096,
			"vw": 22.1173,
			"o": 22.115,
			"c": 22.1258,
			"h": 22.1261,
			"l": 22.1036,
			"t": 1344263580000,
			"n": 364
		  },
		  {
			"v": 1271368,
			"vw": 22.1192,
			"o": 22.1257,
			"c": 22.12,
			"h": 22.1296,
			"l": 22.1075,
			"t": 1344263640000,
			"n": 234
		  },
		  {
			"v": 1046416,
			"vw": 22.123,
			"o": 22.1171,
			"c": 22.1257,
			"h": 22.1307,
			"l": 22.1129,
			"t": 1344263700000,
			"n": 251
		  },
		  {
			"v": 542192,
			"vw": 22.1223,
			"o": 22.1246,
			"c": 22.1261,
			"h": 22.1261,
			"l": 22.1193,
			"t": 1344263760000,
			"n": 145
		  },
		  {
			"v": 1588272,
			"vw": 22.1295,
			"o": 22.1232,
			"c": 22.1293,
			"h": 22.1346,
			"l": 22.1232,
			"t": 1344263820000,
			"n": 348
		  },
		  {
			"v": 1335684,
			"vw": 22.134,
			"o": 22.1321,
			"c": 22.13,
			"h": 22.1371,
			"l": 22.1286,
			"t": 1344263880000,
			"n": 326
		  },
		  {
			"v": 661136,
			"vw": 22.1291,
			"o": 22.1311,
			"c": 22.1193,
			"h": 22.1364,
			"l": 22.1193,
			"t": 1344263940000,
			"n": 170
		  },
		  {
			"v": 1217496,
			"vw": 22.1214,
			"o": 22.12,
			"c": 22.1221,
			"h": 22.1339,
			"l": 22.1139,
			"t": 1344264000000,
			"n": 317
		  },
		  {
			"v": 698208,
			"vw": 22.1241,
			"o": 22.1221,
			"c": 22.1318,
			"h": 22.1321,
			"l": 22.1182,
			"t": 1344264060000,
			"n": 159
		  },
		  {
			"v": 1130108,
			"vw": 22.1339,
			"o": 22.1289,
			"c": 22.136,
			"h": 22.1371,
			"l": 22.1285,
			"t": 1344264120000,
			"n": 257
		  },
		  {
			"v": 1135036,
			"vw": 22.1323,
			"o": 22.1356,
			"c": 22.1337,
			"h": 22.1368,
			"l": 22.1286,
			"t": 1344264180000,
			"n": 224
		  },
		  {
			"v": 1169980,
			"vw": 22.1349,
			"o": 22.1357,
			"c": 22.1349,
			"h": 22.1382,
			"l": 22.1314,
			"t": 1344264240000,
			"n": 265
		  },
		  {
			"v": 983500,
			"vw": 22.133,
			"o": 22.135,
			"c": 22.1329,
			"h": 22.1357,
			"l": 22.1307,
			"t": 1344264300000,
			"n": 168
		  },
		  {
			"v": 770952,
			"vw": 22.1347,
			"o": 22.1346,
			"c": 22.135,
			"h": 22.1379,
			"l": 22.1304,
			"t": 1344264360000,
			"n": 187
		  },
		  {
			"v": 5877032,
			"vw": 22.1433,
			"o": 22.1357,
			"c": 22.1521,
			"h": 22.1604,
			"l": 22.1325,
			"t": 1344264420000,
			"n": 1013
		  },
		  {
			"v": 4301444,
			"vw": 22.1599,
			"o": 22.1564,
			"c": 22.165,
			"h": 22.1768,
			"l": 22.1471,
			"t": 1344264480000,
			"n": 920
		  },
		  {
			"v": 1466304,
			"vw": 22.1726,
			"o": 22.1636,
			"c": 22.173,
			"h": 22.1782,
			"l": 22.1636,
			"t": 1344264540000,
			"n": 370
		  },
		  {
			"v": 3437756,
			"vw": 22.1838,
			"o": 22.1736,
			"c": 22.1893,
			"h": 22.1946,
			"l": 22.1714,
			"t": 1344264600000,
			"n": 808
		  },
		  {
			"v": 3270064,
			"vw": 22.2002,
			"o": 22.1907,
			"c": 22.2093,
			"h": 22.2143,
			"l": 22.1889,
			"t": 1344264660000,
			"n": 700
		  },
		  {
			"v": 3296720,
			"vw": 22.2137,
			"o": 22.2104,
			"c": 22.2143,
			"h": 22.2207,
			"l": 22.2071,
			"t": 1344264720000,
			"n": 723
		  },
		  {
			"v": 1995112,
			"vw": 22.2094,
			"o": 22.2129,
			"c": 22.2118,
			"h": 22.2207,
			"l": 22.2,
			"t": 1344264780000,
			"n": 461
		  },
		  {
			"v": 2012612,
			"vw": 22.2177,
			"o": 22.2082,
			"c": 22.2236,
			"h": 22.2286,
			"l": 22.2072,
			"t": 1344264840000,
			"n": 415
		  },
		  {
			"v": 1202068,
			"vw": 22.2113,
			"o": 22.2226,
			"c": 22.2046,
			"h": 22.2235,
			"l": 22.2043,
			"t": 1344264900000,
			"n": 239
		  },
		  {
			"v": 876400,
			"vw": 22.2064,
			"o": 22.2043,
			"c": 22.21,
			"h": 22.2139,
			"l": 22.2004,
			"t": 1344264960000,
			"n": 234
		  },
		  {
			"v": 1060500,
			"vw": 22.2079,
			"o": 22.2117,
			"c": 22.2107,
			"h": 22.2132,
			"l": 22.2,
			"t": 1344265020000,
			"n": 254
		  },
		  {
			"v": 1002708,
			"vw": 22.2012,
			"o": 22.2093,
			"c": 22.2007,
			"h": 22.2114,
			"l": 22.1964,
			"t": 1344265080000,
			"n": 220
		  },
		  {
			"v": 559608,
			"vw": 22.203,
			"o": 22.2015,
			"c": 22.2032,
			"h": 22.2068,
			"l": 22.2,
			"t": 1344265140000,
			"n": 143
		  },
		  {
			"v": 1151500,
			"vw": 22.2087,
			"o": 22.2006,
			"c": 22.2118,
			"h": 22.2143,
			"l": 22.2006,
			"t": 1344265200000,
			"n": 237
		  },
		  {
			"v": 772184,
			"vw": 22.2114,
			"o": 22.2121,
			"c": 22.2171,
			"h": 22.2171,
			"l": 22.2036,
			"t": 1344265260000,
			"n": 186
		  },
		  {
			"v": 743848,
			"vw": 22.213,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2182,
			"l": 22.2071,
			"t": 1344265320000,
			"n": 158
		  },
		  {
			"v": 1146348,
			"vw": 22.2203,
			"o": 22.2156,
			"c": 22.2191,
			"h": 22.225,
			"l": 22.2125,
			"t": 1344265380000,
			"n": 272
		  },
		  {
			"v": 1363656,
			"vw": 22.2221,
			"o": 22.2204,
			"c": 22.2236,
			"h": 22.2279,
			"l": 22.215,
			"t": 1344265440000,
			"n": 307
		  },
		  {
			"v": 617708,
			"vw": 22.218,
			"o": 22.2232,
			"c": 22.2162,
			"h": 22.2267,
			"l": 22.2143,
			"t": 1344265500000,
			"n": 132
		  },
		  {
			"v": 1187256,
			"vw": 22.229,
			"o": 22.2205,
			"c": 22.2343,
			"h": 22.2364,
			"l": 22.2161,
			"t": 1344265560000,
			"n": 259
		  },
		  {
			"v": 633332,
			"vw": 22.2284,
			"o": 22.2354,
			"c": 22.2264,
			"h": 22.2356,
			"l": 22.2193,
			"t": 1344265620000,
			"n": 144
		  },
		  {
			"v": 1044848,
			"vw": 22.2153,
			"o": 22.2264,
			"c": 22.21,
			"h": 22.2264,
			"l": 22.2089,
			"t": 1344265680000,
			"n": 242
		  },
		  {
			"v": 1062740,
			"vw": 22.2094,
			"o": 22.2111,
			"c": 22.2079,
			"h": 22.2161,
			"l": 22.2039,
			"t": 1344265740000,
			"n": 271
		  },
		  {
			"v": 1154328,
			"vw": 22.2014,
			"o": 22.2079,
			"c": 22.1954,
			"h": 22.2111,
			"l": 22.1889,
			"t": 1344265800000,
			"n": 256
		  },
		  {
			"v": 903336,
			"vw": 22.1975,
			"o": 22.1954,
			"c": 22.2021,
			"h": 22.2064,
			"l": 22.1918,
			"t": 1344265860000,
			"n": 215
		  },
		  {
			"v": 833924,
			"vw": 22.2051,
			"o": 22.2,
			"c": 22.2003,
			"h": 22.2107,
			"l": 22.1943,
			"t": 1344265920000,
			"n": 137
		  },
		  {
			"v": 620032,
			"vw": 22.2103,
			"o": 22.2013,
			"c": 22.2136,
			"h": 22.2179,
			"l": 22.2011,
			"t": 1344265980000,
			"n": 149
		  },
		  {
			"v": 569128,
			"vw": 22.2185,
			"o": 22.2164,
			"c": 22.2158,
			"h": 22.2275,
			"l": 22.2125,
			"t": 1344266040000,
			"n": 148
		  },
		  {
			"v": 671132,
			"vw": 22.209,
			"o": 22.2161,
			"c": 22.2046,
			"h": 22.2164,
			"l": 22.2036,
			"t": 1344266100000,
			"n": 137
		  },
		  {
			"v": 586264,
			"vw": 22.2019,
			"o": 22.2036,
			"c": 22.2043,
			"h": 22.2107,
			"l": 22.1932,
			"t": 1344266160000,
			"n": 136
		  },
		  {
			"v": 549108,
			"vw": 22.2013,
			"o": 22.2041,
			"c": 22.1997,
			"h": 22.2085,
			"l": 22.1929,
			"t": 1344266220000,
			"n": 118
		  },
		  {
			"v": 402164,
			"vw": 22.2091,
			"o": 22.2021,
			"c": 22.2111,
			"h": 22.2132,
			"l": 22.2018,
			"t": 1344266280000,
			"n": 107
		  },
		  {
			"v": 530908,
			"vw": 22.2124,
			"o": 22.2107,
			"c": 22.208,
			"h": 22.2143,
			"l": 22.2071,
			"t": 1344266340000,
			"n": 100
		  },
		  {
			"v": 185892,
			"vw": 22.2044,
			"o": 22.2082,
			"c": 22.2049,
			"h": 22.2111,
			"l": 22.1997,
			"t": 1344266400000,
			"n": 55
		  },
		  {
			"v": 664888,
			"vw": 22.1967,
			"o": 22.2043,
			"c": 22.2011,
			"h": 22.2061,
			"l": 22.1889,
			"t": 1344266460000,
			"n": 136
		  },
		  {
			"v": 288400,
			"vw": 22.2041,
			"o": 22.2022,
			"c": 22.205,
			"h": 22.2111,
			"l": 22.1968,
			"t": 1344266520000,
			"n": 66
		  },
		  {
			"v": 261912,
			"vw": 22.2019,
			"o": 22.2089,
			"c": 22.2011,
			"h": 22.2096,
			"l": 22.1971,
			"t": 1344266580000,
			"n": 61
		  },
		  {
			"v": 575736,
			"vw": 22.2099,
			"o": 22.2014,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.1979,
			"t": 1344266640000,
			"n": 118
		  },
		  {
			"v": 936180,
			"vw": 22.2175,
			"o": 22.2207,
			"c": 22.2175,
			"h": 22.2254,
			"l": 22.2071,
			"t": 1344266700000,
			"n": 206
		  },
		  {
			"v": 466032,
			"vw": 22.2167,
			"o": 22.2214,
			"c": 22.2164,
			"h": 22.2214,
			"l": 22.2125,
			"t": 1344266760000,
			"n": 122
		  },
		  {
			"v": 783524,
			"vw": 22.2166,
			"o": 22.2179,
			"c": 22.2237,
			"h": 22.2264,
			"l": 22.2068,
			"t": 1344266820000,
			"n": 163
		  },
		  {
			"v": 1160768,
			"vw": 22.2286,
			"o": 22.2268,
			"c": 22.2214,
			"h": 22.2343,
			"l": 22.2186,
			"t": 1344266880000,
			"n": 247
		  },
		  {
			"v": 693476,
			"vw": 22.2154,
			"o": 22.2193,
			"c": 22.216,
			"h": 22.2221,
			"l": 22.2104,
			"t": 1344266940000,
			"n": 173
		  },
		  {
			"v": 839748,
			"vw": 22.2105,
			"o": 22.2157,
			"c": 22.2082,
			"h": 22.2161,
			"l": 22.2054,
			"t": 1344267000000,
			"n": 145
		  },
		  {
			"v": 822724,
			"vw": 22.2169,
			"o": 22.2104,
			"c": 22.2111,
			"h": 22.2293,
			"l": 22.2082,
			"t": 1344267060000,
			"n": 199
		  },
		  {
			"v": 405916,
			"vw": 22.2176,
			"o": 22.2114,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2114,
			"t": 1344267120000,
			"n": 101
		  },
		  {
			"v": 530320,
			"vw": 22.2173,
			"o": 22.2207,
			"c": 22.2157,
			"h": 22.2218,
			"l": 22.2139,
			"t": 1344267180000,
			"n": 100
		  },
		  {
			"v": 441084,
			"vw": 22.2212,
			"o": 22.2168,
			"c": 22.2211,
			"h": 22.225,
			"l": 22.2168,
			"t": 1344267240000,
			"n": 120
		  },
		  {
			"v": 565068,
			"vw": 22.2255,
			"o": 22.2211,
			"c": 22.2274,
			"h": 22.2321,
			"l": 22.2211,
			"t": 1344267300000,
			"n": 98
		  },
		  {
			"v": 533400,
			"vw": 22.2305,
			"o": 22.2314,
			"c": 22.2314,
			"h": 22.2339,
			"l": 22.225,
			"t": 1344267360000,
			"n": 111
		  },
		  {
			"v": 1226764,
			"vw": 22.2391,
			"o": 22.2289,
			"c": 22.2432,
			"h": 22.2464,
			"l": 22.2268,
			"t": 1344267420000,
			"n": 246
		  },
		  {
			"v": 1545992,
			"vw": 22.2477,
			"o": 22.2432,
			"c": 22.2496,
			"h": 22.2524,
			"l": 22.2379,
			"t": 1344267480000,
			"n": 328
		  },
		  {
			"v": 1357272,
			"vw": 22.2426,
			"o": 22.2496,
			"c": 22.2396,
			"h": 22.2504,
			"l": 22.2364,
			"t": 1344267540000,
			"n": 306
		  },
		  {
			"v": 665252,
			"vw": 22.2374,
			"o": 22.2396,
			"c": 22.2379,
			"h": 22.2411,
			"l": 22.2332,
			"t": 1344267600000,
			"n": 159
		  },
		  {
			"v": 727972,
			"vw": 22.2302,
			"o": 22.2376,
			"c": 22.2211,
			"h": 22.2393,
			"l": 22.2161,
			"t": 1344267660000,
			"n": 169
		  },
		  {
			"v": 912800,
			"vw": 22.2313,
			"o": 22.2271,
			"c": 22.2396,
			"h": 22.2446,
			"l": 22.22,
			"t": 1344267720000,
			"n": 193
		  },
		  {
			"v": 245588,
			"vw": 22.2334,
			"o": 22.2361,
			"c": 22.2304,
			"h": 22.2393,
			"l": 22.2286,
			"t": 1344267780000,
			"n": 63
		  },
		  {
			"v": 257768,
			"vw": 22.233,
			"o": 22.2364,
			"c": 22.2368,
			"h": 22.2368,
			"l": 22.2301,
			"t": 1344267840000,
			"n": 56
		  },
		  {
			"v": 436800,
			"vw": 22.2324,
			"o": 22.2308,
			"c": 22.2371,
			"h": 22.2371,
			"l": 22.2271,
			"t": 1344267900000,
			"n": 80
		  },
		  {
			"v": 306208,
			"vw": 22.2394,
			"o": 22.2364,
			"c": 22.2429,
			"h": 22.2436,
			"l": 22.2336,
			"t": 1344267960000,
			"n": 59
		  },
		  {
			"v": 691572,
			"vw": 22.2354,
			"o": 22.2414,
			"c": 22.2314,
			"h": 22.2471,
			"l": 22.2296,
			"t": 1344268020000,
			"n": 113
		  },
		  {
			"v": 560588,
			"vw": 22.2325,
			"o": 22.23,
			"c": 22.2357,
			"h": 22.2389,
			"l": 22.2261,
			"t": 1344268080000,
			"n": 130
		  },
		  {
			"v": 227808,
			"vw": 22.2365,
			"o": 22.2361,
			"c": 22.2339,
			"h": 22.2404,
			"l": 22.2336,
			"t": 1344268140000,
			"n": 55
		  },
		  {
			"v": 274820,
			"vw": 22.2375,
			"o": 22.2357,
			"c": 22.2361,
			"h": 22.2404,
			"l": 22.2357,
			"t": 1344268200000,
			"n": 76
		  },
		  {
			"v": 280504,
			"vw": 22.2313,
			"o": 22.2364,
			"c": 22.2275,
			"h": 22.2375,
			"l": 22.2232,
			"t": 1344268260000,
			"n": 64
		  },
		  {
			"v": 429184,
			"vw": 22.2341,
			"o": 22.2282,
			"c": 22.2407,
			"h": 22.2411,
			"l": 22.2239,
			"t": 1344268320000,
			"n": 89
		  },
		  {
			"v": 575344,
			"vw": 22.2426,
			"o": 22.2404,
			"c": 22.2457,
			"h": 22.2457,
			"l": 22.2393,
			"t": 1344268380000,
			"n": 127
		  },
		  {
			"v": 638456,
			"vw": 22.2465,
			"o": 22.2461,
			"c": 22.2413,
			"h": 22.2496,
			"l": 22.2411,
			"t": 1344268440000,
			"n": 138
		  },
		  {
			"v": 476196,
			"vw": 22.2449,
			"o": 22.2411,
			"c": 22.2444,
			"h": 22.2493,
			"l": 22.2407,
			"t": 1344268500000,
			"n": 87
		  },
		  {
			"v": 302456,
			"vw": 22.2471,
			"o": 22.2443,
			"c": 22.2493,
			"h": 22.2493,
			"l": 22.2443,
			"t": 1344268560000,
			"n": 74
		  },
		  {
			"v": 1113728,
			"vw": 22.2523,
			"o": 22.2492,
			"c": 22.2542,
			"h": 22.2582,
			"l": 22.2468,
			"t": 1344268620000,
			"n": 184
		  },
		  {
			"v": 905436,
			"vw": 22.2548,
			"o": 22.2532,
			"c": 22.255,
			"h": 22.2604,
			"l": 22.25,
			"t": 1344268680000,
			"n": 156
		  },
		  {
			"v": 394744,
			"vw": 22.2555,
			"o": 22.2564,
			"c": 22.2585,
			"h": 22.2586,
			"l": 22.2529,
			"t": 1344268740000,
			"n": 74
		  },
		  {
			"v": 618520,
			"vw": 22.2591,
			"o": 22.2585,
			"c": 22.2603,
			"h": 22.2614,
			"l": 22.2536,
			"t": 1344268800000,
			"n": 126
		  },
		  {
			"v": 386792,
			"vw": 22.257,
			"o": 22.2564,
			"c": 22.2589,
			"h": 22.2607,
			"l": 22.2539,
			"t": 1344268860000,
			"n": 82
		  },
		  {
			"v": 277172,
			"vw": 22.2606,
			"o": 22.2579,
			"c": 22.2618,
			"h": 22.2618,
			"l": 22.2576,
			"t": 1344268920000,
			"n": 60
		  },
		  {
			"v": 1290548,
			"vw": 22.2521,
			"o": 22.2618,
			"c": 22.2521,
			"h": 22.2618,
			"l": 22.2483,
			"t": 1344268980000,
			"n": 225
		  },
		  {
			"v": 567560,
			"vw": 22.253,
			"o": 22.2518,
			"c": 22.2503,
			"h": 22.2593,
			"l": 22.2493,
			"t": 1344269040000,
			"n": 131
		  },
		  {
			"v": 343028,
			"vw": 22.2521,
			"o": 22.25,
			"c": 22.25,
			"h": 22.2539,
			"l": 22.25,
			"t": 1344269100000,
			"n": 53
		  },
		  {
			"v": 612976,
			"vw": 22.2499,
			"o": 22.25,
			"c": 22.2488,
			"h": 22.2536,
			"l": 22.2475,
			"t": 1344269160000,
			"n": 115
		  },
		  {
			"v": 470680,
			"vw": 22.2489,
			"o": 22.2486,
			"c": 22.2514,
			"h": 22.2518,
			"l": 22.2482,
			"t": 1344269220000,
			"n": 90
		  },
		  {
			"v": 481012,
			"vw": 22.2488,
			"o": 22.2511,
			"c": 22.2482,
			"h": 22.2539,
			"l": 22.2414,
			"t": 1344269280000,
			"n": 106
		  },
		  {
			"v": 332024,
			"vw": 22.2436,
			"o": 22.2414,
			"c": 22.2458,
			"h": 22.2461,
			"l": 22.2414,
			"t": 1344269340000,
			"n": 64
		  },
		  {
			"v": 386148,
			"vw": 22.2454,
			"o": 22.2454,
			"c": 22.2457,
			"h": 22.2486,
			"l": 22.2432,
			"t": 1344269400000,
			"n": 81
		  },
		  {
			"v": 360612,
			"vw": 22.249,
			"o": 22.2464,
			"c": 22.2474,
			"h": 22.2532,
			"l": 22.2439,
			"t": 1344269460000,
			"n": 78
		  },
		  {
			"v": 204904,
			"vw": 22.2507,
			"o": 22.2461,
			"c": 22.2461,
			"h": 22.2536,
			"l": 22.2454,
			"t": 1344269520000,
			"n": 51
		  },
		  {
			"v": 240660,
			"vw": 22.2468,
			"o": 22.2475,
			"c": 22.2473,
			"h": 22.2496,
			"l": 22.2446,
			"t": 1344269580000,
			"n": 61
		  },
		  {
			"v": 301448,
			"vw": 22.2513,
			"o": 22.2493,
			"c": 22.2504,
			"h": 22.2539,
			"l": 22.2471,
			"t": 1344269640000,
			"n": 75
		  },
		  {
			"v": 248136,
			"vw": 22.2501,
			"o": 22.25,
			"c": 22.2498,
			"h": 22.2536,
			"l": 22.2486,
			"t": 1344269700000,
			"n": 47
		  },
		  {
			"v": 415324,
			"vw": 22.248,
			"o": 22.25,
			"c": 22.2446,
			"h": 22.2507,
			"l": 22.2446,
			"t": 1344269760000,
			"n": 82
		  },
		  {
			"v": 122780,
			"vw": 22.2464,
			"o": 22.2461,
			"c": 22.2454,
			"h": 22.2482,
			"l": 22.2446,
			"t": 1344269820000,
			"n": 33
		  },
		  {
			"v": 178948,
			"vw": 22.2468,
			"o": 22.2455,
			"c": 22.2464,
			"h": 22.2486,
			"l": 22.2454,
			"t": 1344269880000,
			"n": 40
		  },
		  {
			"v": 696976,
			"vw": 22.2479,
			"o": 22.2461,
			"c": 22.25,
			"h": 22.2529,
			"l": 22.2461,
			"t": 1344269940000,
			"n": 91
		  },
		  {
			"v": 969248,
			"vw": 22.2507,
			"o": 22.2507,
			"c": 22.2429,
			"h": 22.255,
			"l": 22.2421,
			"t": 1344270000000,
			"n": 247
		  },
		  {
			"v": 408800,
			"vw": 22.2463,
			"o": 22.2429,
			"c": 22.2536,
			"h": 22.2536,
			"l": 22.2411,
			"t": 1344270060000,
			"n": 106
		  },
		  {
			"v": 402528,
			"vw": 22.2551,
			"o": 22.2529,
			"c": 22.2546,
			"h": 22.2589,
			"l": 22.2505,
			"t": 1344270120000,
			"n": 82
		  },
		  {
			"v": 280476,
			"vw": 22.2524,
			"o": 22.2546,
			"c": 22.2501,
			"h": 22.2575,
			"l": 22.25,
			"t": 1344270180000,
			"n": 67
		  },
		  {
			"v": 493472,
			"vw": 22.2559,
			"o": 22.2504,
			"c": 22.2543,
			"h": 22.26,
			"l": 22.25,
			"t": 1344270240000,
			"n": 104
		  },
		  {
			"v": 241668,
			"vw": 22.2503,
			"o": 22.2518,
			"c": 22.25,
			"h": 22.2557,
			"l": 22.2489,
			"t": 1344270300000,
			"n": 44
		  },
		  {
			"v": 237860,
			"vw": 22.2466,
			"o": 22.2489,
			"c": 22.2489,
			"h": 22.2499,
			"l": 22.2443,
			"t": 1344270360000,
			"n": 65
		  },
		  {
			"v": 237580,
			"vw": 22.2506,
			"o": 22.2479,
			"c": 22.2454,
			"h": 22.2543,
			"l": 22.2454,
			"t": 1344270420000,
			"n": 52
		  },
		  {
			"v": 165928,
			"vw": 22.2457,
			"o": 22.2444,
			"c": 22.2475,
			"h": 22.2481,
			"l": 22.2443,
			"t": 1344270480000,
			"n": 34
		  },
		  {
			"v": 199640,
			"vw": 22.2477,
			"o": 22.2457,
			"c": 22.25,
			"h": 22.2507,
			"l": 22.2446,
			"t": 1344270540000,
			"n": 48
		  },
		  {
			"v": 221536,
			"vw": 22.2464,
			"o": 22.2486,
			"c": 22.2436,
			"h": 22.25,
			"l": 22.2436,
			"t": 1344270600000,
			"n": 51
		  },
		  {
			"v": 1131340,
			"vw": 22.2529,
			"o": 22.2448,
			"c": 22.2639,
			"h": 22.2643,
			"l": 22.2414,
			"t": 1344270660000,
			"n": 245
		  },
		  {
			"v": 333788,
			"vw": 22.2576,
			"o": 22.2636,
			"c": 22.2564,
			"h": 22.2643,
			"l": 22.2529,
			"t": 1344270720000,
			"n": 88
		  },
		  {
			"v": 574616,
			"vw": 22.2603,
			"o": 22.2571,
			"c": 22.2625,
			"h": 22.2643,
			"l": 22.255,
			"t": 1344270780000,
			"n": 149
		  },
		  {
			"v": 723856,
			"vw": 22.2654,
			"o": 22.2633,
			"c": 22.2629,
			"h": 22.2675,
			"l": 22.2621,
			"t": 1344270840000,
			"n": 132
		  },
		  {
			"v": 619360,
			"vw": 22.2683,
			"o": 22.2629,
			"c": 22.2721,
			"h": 22.2721,
			"l": 22.2611,
			"t": 1344270900000,
			"n": 131
		  },
		  {
			"v": 536340,
			"vw": 22.2724,
			"o": 22.2711,
			"c": 22.2714,
			"h": 22.2757,
			"l": 22.2683,
			"t": 1344270960000,
			"n": 124
		  },
		  {
			"v": 352548,
			"vw": 22.2691,
			"o": 22.2714,
			"c": 22.2693,
			"h": 22.2739,
			"l": 22.2654,
			"t": 1344271020000,
			"n": 97
		  },
		  {
			"v": 443352,
			"vw": 22.2654,
			"o": 22.2661,
			"c": 22.2654,
			"h": 22.2679,
			"l": 22.2611,
			"t": 1344271080000,
			"n": 119
		  },
		  {
			"v": 133000,
			"vw": 22.2648,
			"o": 22.2661,
			"c": 22.2643,
			"h": 22.2678,
			"l": 22.2618,
			"t": 1344271140000,
			"n": 28
		  },
		  {
			"v": 343308,
			"vw": 22.2641,
			"o": 22.265,
			"c": 22.2643,
			"h": 22.2675,
			"l": 22.2621,
			"t": 1344271200000,
			"n": 56
		  },
		  {
			"v": 519372,
			"vw": 22.2641,
			"o": 22.2632,
			"c": 22.264,
			"h": 22.2661,
			"l": 22.2618,
			"t": 1344271260000,
			"n": 128
		  },
		  {
			"v": 255248,
			"vw": 22.2633,
			"o": 22.2643,
			"c": 22.2611,
			"h": 22.2661,
			"l": 22.2611,
			"t": 1344271320000,
			"n": 67
		  },
		  {
			"v": 296464,
			"vw": 22.2654,
			"o": 22.2632,
			"c": 22.2671,
			"h": 22.2679,
			"l": 22.2614,
			"t": 1344271380000,
			"n": 58
		  },
		  {
			"v": 898072,
			"vw": 22.2714,
			"o": 22.2671,
			"c": 22.2696,
			"h": 22.275,
			"l": 22.2671,
			"t": 1344271440000,
			"n": 171
		  },
		  {
			"v": 651392,
			"vw": 22.2755,
			"o": 22.2714,
			"c": 22.275,
			"h": 22.2779,
			"l": 22.271,
			"t": 1344271500000,
			"n": 116
		  },
		  {
			"v": 364644,
			"vw": 22.273,
			"o": 22.275,
			"c": 22.2761,
			"h": 22.2761,
			"l": 22.2689,
			"t": 1344271560000,
			"n": 78
		  },
		  {
			"v": 1269436,
			"vw": 22.2823,
			"o": 22.2756,
			"c": 22.2854,
			"h": 22.2868,
			"l": 22.2722,
			"t": 1344271620000,
			"n": 226
		  },
		  {
			"v": 522648,
			"vw": 22.2841,
			"o": 22.2821,
			"c": 22.2839,
			"h": 22.2868,
			"l": 22.2814,
			"t": 1344271680000,
			"n": 137
		  },
		  {
			"v": 1049272,
			"vw": 22.2836,
			"o": 22.2843,
			"c": 22.2871,
			"h": 22.2886,
			"l": 22.2786,
			"t": 1344271740000,
			"n": 181
		  },
		  {
			"v": 545580,
			"vw": 22.2898,
			"o": 22.2864,
			"c": 22.2907,
			"h": 22.2954,
			"l": 22.2825,
			"t": 1344271800000,
			"n": 108
		  },
		  {
			"v": 648704,
			"vw": 22.2902,
			"o": 22.2921,
			"c": 22.2925,
			"h": 22.2946,
			"l": 22.2869,
			"t": 1344271860000,
			"n": 122
		  },
		  {
			"v": 824768,
			"vw": 22.2949,
			"o": 22.2939,
			"c": 22.2962,
			"h": 22.2993,
			"l": 22.2879,
			"t": 1344271920000,
			"n": 175
		  },
		  {
			"v": 652932,
			"vw": 22.2958,
			"o": 22.2946,
			"c": 22.2961,
			"h": 22.2992,
			"l": 22.2946,
			"t": 1344271980000,
			"n": 79
		  },
		  {
			"v": 478716,
			"vw": 22.2955,
			"o": 22.2961,
			"c": 22.295,
			"h": 22.2989,
			"l": 22.2904,
			"t": 1344272040000,
			"n": 97
		  },
		  {
			"v": 429016,
			"vw": 22.2961,
			"o": 22.2957,
			"c": 22.2939,
			"h": 22.2989,
			"l": 22.2936,
			"t": 1344272100000,
			"n": 92
		  },
		  {
			"v": 748412,
			"vw": 22.2921,
			"o": 22.2943,
			"c": 22.2943,
			"h": 22.2954,
			"l": 22.2889,
			"t": 1344272160000,
			"n": 138
		  },
		  {
			"v": 456456,
			"vw": 22.2968,
			"o": 22.2931,
			"c": 22.2982,
			"h": 22.3004,
			"l": 22.2914,
			"t": 1344272220000,
			"n": 101
		  },
		  {
			"v": 709128,
			"vw": 22.2998,
			"o": 22.3,
			"c": 22.2982,
			"h": 22.3018,
			"l": 22.2968,
			"t": 1344272280000,
			"n": 94
		  },
		  {
			"v": 1360044,
			"vw": 22.3033,
			"o": 22.2982,
			"c": 22.3059,
			"h": 22.3093,
			"l": 22.2982,
			"t": 1344272340000,
			"n": 265
		  },
		  {
			"v": 780052,
			"vw": 22.3047,
			"o": 22.3036,
			"c": 22.3079,
			"h": 22.3096,
			"l": 22.2964,
			"t": 1344272400000,
			"n": 149
		  },
		  {
			"v": 662480,
			"vw": 22.3074,
			"o": 22.3075,
			"c": 22.3079,
			"h": 22.3107,
			"l": 22.3054,
			"t": 1344272460000,
			"n": 169
		  },
		  {
			"v": 1158780,
			"vw": 22.3095,
			"o": 22.3104,
			"c": 22.3061,
			"h": 22.3132,
			"l": 22.3046,
			"t": 1344272520000,
			"n": 210
		  },
		  {
			"v": 735112,
			"vw": 22.3053,
			"o": 22.3061,
			"c": 22.3064,
			"h": 22.3086,
			"l": 22.3036,
			"t": 1344272580000,
			"n": 171
		  },
		  {
			"v": 818944,
			"vw": 22.2985,
			"o": 22.305,
			"c": 22.2951,
			"h": 22.305,
			"l": 22.2951,
			"t": 1344272640000,
			"n": 141
		  },
		  {
			"v": 339444,
			"vw": 22.2969,
			"o": 22.2952,
			"c": 22.2986,
			"h": 22.2996,
			"l": 22.2952,
			"t": 1344272700000,
			"n": 83
		  },
		  {
			"v": 503328,
			"vw": 22.2998,
			"o": 22.2996,
			"c": 22.3025,
			"h": 22.3032,
			"l": 22.2968,
			"t": 1344272760000,
			"n": 112
		  },
		  {
			"v": 251244,
			"vw": 22.302,
			"o": 22.3032,
			"c": 22.3054,
			"h": 22.3054,
			"l": 22.3004,
			"t": 1344272820000,
			"n": 57
		  },
		  {
			"v": 338240,
			"vw": 22.3063,
			"o": 22.3053,
			"c": 22.3068,
			"h": 22.31,
			"l": 22.3032,
			"t": 1344272880000,
			"n": 64
		  },
		  {
			"v": 349048,
			"vw": 22.3061,
			"o": 22.3068,
			"c": 22.3052,
			"h": 22.3082,
			"l": 22.3043,
			"t": 1344272940000,
			"n": 79
		  },
		  {
			"v": 311584,
			"vw": 22.3085,
			"o": 22.3081,
			"c": 22.3121,
			"h": 22.3121,
			"l": 22.3054,
			"t": 1344273000000,
			"n": 74
		  },
		  {
			"v": 964516,
			"vw": 22.3137,
			"o": 22.3089,
			"c": 22.3154,
			"h": 22.3154,
			"l": 22.3089,
			"t": 1344273060000,
			"n": 163
		  },
		  {
			"v": 525644,
			"vw": 22.3133,
			"o": 22.3143,
			"c": 22.3121,
			"h": 22.3146,
			"l": 22.3114,
			"t": 1344273120000,
			"n": 91
		  },
		  {
			"v": 392644,
			"vw": 22.3134,
			"o": 22.3143,
			"c": 22.3136,
			"h": 22.315,
			"l": 22.3093,
			"t": 1344273180000,
			"n": 80
		  },
		  {
			"v": 223524,
			"vw": 22.3127,
			"o": 22.3114,
			"c": 22.3112,
			"h": 22.3146,
			"l": 22.3107,
			"t": 1344273240000,
			"n": 40
		  },
		  {
			"v": 349496,
			"vw": 22.3122,
			"o": 22.3093,
			"c": 22.3118,
			"h": 22.3143,
			"l": 22.3093,
			"t": 1344273300000,
			"n": 87
		  },
		  {
			"v": 406112,
			"vw": 22.3134,
			"o": 22.3108,
			"c": 22.315,
			"h": 22.3157,
			"l": 22.3107,
			"t": 1344273360000,
			"n": 86
		  },
		  {
			"v": 523516,
			"vw": 22.3146,
			"o": 22.3143,
			"c": 22.3114,
			"h": 22.3167,
			"l": 22.3114,
			"t": 1344273420000,
			"n": 131
		  },
		  {
			"v": 469784,
			"vw": 22.3103,
			"o": 22.3118,
			"c": 22.3125,
			"h": 22.3127,
			"l": 22.3089,
			"t": 1344273480000,
			"n": 77
		  },
		  {
			"v": 138516,
			"vw": 22.3102,
			"o": 22.3107,
			"c": 22.3089,
			"h": 22.3125,
			"l": 22.3082,
			"t": 1344273540000,
			"n": 39
		  },
		  {
			"v": 425292,
			"vw": 22.3121,
			"o": 22.3093,
			"c": 22.3092,
			"h": 22.3143,
			"l": 22.3089,
			"t": 1344273600000,
			"n": 98
		  },
		  {
			"v": 3278380,
			"vw": 22.312,
			"o": 22.3082,
			"c": 22.3093,
			"h": 22.3125,
			"l": 22.3039,
			"t": 1344273660000,
			"n": 108
		  },
		  {
			"v": 239792,
			"vw": 22.3064,
			"o": 22.3079,
			"c": 22.3068,
			"h": 22.31,
			"l": 22.3036,
			"t": 1344273720000,
			"n": 67
		  },
		  {
			"v": 175504,
			"vw": 22.3073,
			"o": 22.3064,
			"c": 22.3096,
			"h": 22.3096,
			"l": 22.305,
			"t": 1344273780000,
			"n": 50
		  },
		  {
			"v": 287196,
			"vw": 22.3047,
			"o": 22.3061,
			"c": 22.3057,
			"h": 22.3096,
			"l": 22.3007,
			"t": 1344273840000,
			"n": 78
		  },
		  {
			"v": 538580,
			"vw": 22.306,
			"o": 22.3057,
			"c": 22.3043,
			"h": 22.3104,
			"l": 22.3039,
			"t": 1344273900000,
			"n": 72
		  },
		  {
			"v": 147224,
			"vw": 22.3056,
			"o": 22.3046,
			"c": 22.3043,
			"h": 22.3082,
			"l": 22.3039,
			"t": 1344273960000,
			"n": 45
		  },
		  {
			"v": 905072,
			"vw": 22.2953,
			"o": 22.3039,
			"c": 22.2946,
			"h": 22.3039,
			"l": 22.289,
			"t": 1344274020000,
			"n": 190
		  },
		  {
			"v": 250180,
			"vw": 22.2938,
			"o": 22.2954,
			"c": 22.2929,
			"h": 22.2961,
			"l": 22.2925,
			"t": 1344274080000,
			"n": 54
		  },
		  {
			"v": 1194256,
			"vw": 22.2819,
			"o": 22.2914,
			"c": 22.2782,
			"h": 22.2936,
			"l": 22.2736,
			"t": 1344274140000,
			"n": 264
		  },
		  {
			"v": 501396,
			"vw": 22.2787,
			"o": 22.2793,
			"c": 22.275,
			"h": 22.2839,
			"l": 22.2736,
			"t": 1344274200000,
			"n": 122
		  },
		  {
			"v": 338492,
			"vw": 22.2761,
			"o": 22.275,
			"c": 22.2775,
			"h": 22.2821,
			"l": 22.2732,
			"t": 1344274260000,
			"n": 86
		  },
		  {
			"v": 1425424,
			"vw": 22.2656,
			"o": 22.2782,
			"c": 22.2614,
			"h": 22.2782,
			"l": 22.255,
			"t": 1344274320000,
			"n": 308
		  },
		  {
			"v": 823452,
			"vw": 22.2605,
			"o": 22.2595,
			"c": 22.2568,
			"h": 22.2654,
			"l": 22.2568,
			"t": 1344274380000,
			"n": 158
		  },
		  {
			"v": 1108492,
			"vw": 22.2577,
			"o": 22.2607,
			"c": 22.2607,
			"h": 22.2625,
			"l": 22.2532,
			"t": 1344274440000,
			"n": 175
		  },
		  {
			"v": 874552,
			"vw": 22.2688,
			"o": 22.2607,
			"c": 22.2682,
			"h": 22.275,
			"l": 22.2582,
			"t": 1344274500000,
			"n": 221
		  },
		  {
			"v": 945028,
			"vw": 22.2567,
			"o": 22.2682,
			"c": 22.2539,
			"h": 22.2706,
			"l": 22.25,
			"t": 1344274560000,
			"n": 211
		  },
		  {
			"v": 944132,
			"vw": 22.2514,
			"o": 22.2507,
			"c": 22.2529,
			"h": 22.2564,
			"l": 22.245,
			"t": 1344274620000,
			"n": 233
		  },
		  {
			"v": 298536,
			"vw": 22.2572,
			"o": 22.2536,
			"c": 22.2546,
			"h": 22.2636,
			"l": 22.2525,
			"t": 1344274680000,
			"n": 81
		  },
		  {
			"v": 1138732,
			"vw": 22.2542,
			"o": 22.2579,
			"c": 22.2464,
			"h": 22.2603,
			"l": 22.2464,
			"t": 1344274740000,
			"n": 81
		  },
		  {
			"v": 411796,
			"vw": 22.2522,
			"o": 22.2461,
			"c": 22.2593,
			"h": 22.2607,
			"l": 22.2454,
			"t": 1344274800000,
			"n": 99
		  },
		  {
			"v": 458080,
			"vw": 22.2604,
			"o": 22.2595,
			"c": 22.2579,
			"h": 22.2643,
			"l": 22.2536,
			"t": 1344274860000,
			"n": 108
		  },
		  {
			"v": 268044,
			"vw": 22.2602,
			"o": 22.2586,
			"c": 22.2568,
			"h": 22.2629,
			"l": 22.2554,
			"t": 1344274920000,
			"n": 60
		  },
		  {
			"v": 475048,
			"vw": 22.2524,
			"o": 22.2561,
			"c": 22.2571,
			"h": 22.2586,
			"l": 22.2489,
			"t": 1344274980000,
			"n": 98
		  },
		  {
			"v": 199248,
			"vw": 22.2572,
			"o": 22.2571,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2539,
			"t": 1344275040000,
			"n": 42
		  },
		  {
			"v": 378420,
			"vw": 22.2611,
			"o": 22.2587,
			"c": 22.2628,
			"h": 22.2643,
			"l": 22.2561,
			"t": 1344275100000,
			"n": 67
		  },
		  {
			"v": 886564,
			"vw": 22.2682,
			"o": 22.2604,
			"c": 22.2743,
			"h": 22.2757,
			"l": 22.2597,
			"t": 1344275160000,
			"n": 204
		  },
		  {
			"v": 784392,
			"vw": 22.2777,
			"o": 22.2743,
			"c": 22.2796,
			"h": 22.2846,
			"l": 22.2693,
			"t": 1344275220000,
			"n": 169
		  },
		  {
			"v": 296828,
			"vw": 22.2758,
			"o": 22.2768,
			"c": 22.2708,
			"h": 22.2793,
			"l": 22.2708,
			"t": 1344275280000,
			"n": 59
		  },
		  {
			"v": 867468,
			"vw": 22.2688,
			"o": 22.2711,
			"c": 22.2657,
			"h": 22.2746,
			"l": 22.2568,
			"t": 1344275340000,
			"n": 129
		  },
		  {
			"v": 582792,
			"vw": 22.267,
			"o": 22.2706,
			"c": 22.2679,
			"h": 22.2768,
			"l": 22.2582,
			"t": 1344275400000,
			"n": 136
		  },
		  {
			"v": 500248,
			"vw": 22.2741,
			"o": 22.2686,
			"c": 22.2768,
			"h": 22.2786,
			"l": 22.2661,
			"t": 1344275460000,
			"n": 95
		  },
		  {
			"v": 204820,
			"vw": 22.2732,
			"o": 22.2761,
			"c": 22.2714,
			"h": 22.2775,
			"l": 22.2689,
			"t": 1344275520000,
			"n": 53
		  },
		  {
			"v": 160076,
			"vw": 22.2753,
			"o": 22.2749,
			"c": 22.2754,
			"h": 22.2779,
			"l": 22.2721,
			"t": 1344275580000,
			"n": 43
		  },
		  {
			"v": 1307796,
			"vw": 22.2661,
			"o": 22.2779,
			"c": 22.2746,
			"h": 22.2786,
			"l": 22.2689,
			"t": 1344275640000,
			"n": 69
		  },
		  {
			"v": 248052,
			"vw": 22.2769,
			"o": 22.2743,
			"c": 22.2786,
			"h": 22.28,
			"l": 22.2732,
			"t": 1344275700000,
			"n": 54
		  },
		  {
			"v": 805952,
			"vw": 22.2827,
			"o": 22.28,
			"c": 22.2879,
			"h": 22.2882,
			"l": 22.2769,
			"t": 1344275760000,
			"n": 148
		  },
		  {
			"v": 368228,
			"vw": 22.2834,
			"o": 22.2868,
			"c": 22.2846,
			"h": 22.2868,
			"l": 22.2811,
			"t": 1344275820000,
			"n": 93
		  },
		  {
			"v": 358288,
			"vw": 22.2831,
			"o": 22.285,
			"c": 22.2811,
			"h": 22.2857,
			"l": 22.2804,
			"t": 1344275880000,
			"n": 82
		  },
		  {
			"v": 496860,
			"vw": 22.2786,
			"o": 22.2818,
			"c": 22.2768,
			"h": 22.2829,
			"l": 22.275,
			"t": 1344275940000,
			"n": 78
		  },
		  {
			"v": 368844,
			"vw": 22.2768,
			"o": 22.2761,
			"c": 22.2771,
			"h": 22.2818,
			"l": 22.2725,
			"t": 1344276000000,
			"n": 98
		  },
		  {
			"v": 203672,
			"vw": 22.2794,
			"o": 22.2764,
			"c": 22.2793,
			"h": 22.2825,
			"l": 22.2744,
			"t": 1344276060000,
			"n": 49
		  },
		  {
			"v": 205016,
			"vw": 22.2756,
			"o": 22.2795,
			"c": 22.2774,
			"h": 22.2804,
			"l": 22.2714,
			"t": 1344276120000,
			"n": 46
		  },
		  {
			"v": 387688,
			"vw": 22.2757,
			"o": 22.2761,
			"c": 22.2768,
			"h": 22.2821,
			"l": 22.2714,
			"t": 1344276180000,
			"n": 97
		  },
		  {
			"v": 258720,
			"vw": 22.2785,
			"o": 22.2771,
			"c": 22.2794,
			"h": 22.2821,
			"l": 22.2718,
			"t": 1344276240000,
			"n": 41
		  },
		  {
			"v": 228564,
			"vw": 22.2822,
			"o": 22.2829,
			"c": 22.2825,
			"h": 22.285,
			"l": 22.2796,
			"t": 1344276300000,
			"n": 61
		  },
		  {
			"v": 157668,
			"vw": 22.2795,
			"o": 22.2825,
			"c": 22.2786,
			"h": 22.2825,
			"l": 22.2761,
			"t": 1344276360000,
			"n": 38
		  },
		  {
			"v": 91588,
			"vw": 22.279,
			"o": 22.2779,
			"c": 22.2793,
			"h": 22.2804,
			"l": 22.2779,
			"t": 1344276420000,
			"n": 26
		  },
		  {
			"v": 103488,
			"vw": 22.2811,
			"o": 22.2793,
			"c": 22.2811,
			"h": 22.2836,
			"l": 22.2775,
			"t": 1344276480000,
			"n": 31
		  },
		  {
			"v": 214508,
			"vw": 22.2814,
			"o": 22.2809,
			"c": 22.2804,
			"h": 22.2843,
			"l": 22.2786,
			"t": 1344276540000,
			"n": 45
		  },
		  {
			"v": 440132,
			"vw": 22.2825,
			"o": 22.2789,
			"c": 22.2814,
			"h": 22.2839,
			"l": 22.2789,
			"t": 1344276600000,
			"n": 48
		  },
		  {
			"v": 955612,
			"vw": 22.2944,
			"o": 22.2843,
			"c": 22.2989,
			"h": 22.3031,
			"l": 22.2839,
			"t": 1344276660000,
			"n": 214
		  },
		  {
			"v": 420056,
			"vw": 22.3025,
			"o": 22.2986,
			"c": 22.3035,
			"h": 22.3054,
			"l": 22.2986,
			"t": 1344276720000,
			"n": 80
		  },
		  {
			"v": 798196,
			"vw": 22.3042,
			"o": 22.3032,
			"c": 22.3007,
			"h": 22.3075,
			"l": 22.2993,
			"t": 1344276780000,
			"n": 130
		  },
		  {
			"v": 187432,
			"vw": 22.3064,
			"o": 22.3032,
			"c": 22.3063,
			"h": 22.3081,
			"l": 22.3032,
			"t": 1344276840000,
			"n": 32
		  },
		  {
			"v": 380716,
			"vw": 22.3054,
			"o": 22.3079,
			"c": 22.303,
			"h": 22.3089,
			"l": 22.3007,
			"t": 1344276900000,
			"n": 78
		  },
		  {
			"v": 836864,
			"vw": 22.2935,
			"o": 22.3011,
			"c": 22.2904,
			"h": 22.3011,
			"l": 22.2875,
			"t": 1344276960000,
			"n": 203
		  },
		  {
			"v": 241332,
			"vw": 22.2923,
			"o": 22.29,
			"c": 22.2921,
			"h": 22.2975,
			"l": 22.2879,
			"t": 1344277020000,
			"n": 62
		  },
		  {
			"v": 513548,
			"vw": 22.2917,
			"o": 22.29,
			"c": 22.2918,
			"h": 22.2964,
			"l": 22.2886,
			"t": 1344277080000,
			"n": 78
		  },
		  {
			"v": 295736,
			"vw": 22.2939,
			"o": 22.2925,
			"c": 22.2969,
			"h": 22.2996,
			"l": 22.2889,
			"t": 1344277140000,
			"n": 59
		  },
		  {
			"v": 184940,
			"vw": 22.294,
			"o": 22.2971,
			"c": 22.2955,
			"h": 22.2979,
			"l": 22.2893,
			"t": 1344277200000,
			"n": 45
		  },
		  {
			"v": 138628,
			"vw": 22.2934,
			"o": 22.2954,
			"c": 22.2943,
			"h": 22.2961,
			"l": 22.2907,
			"t": 1344277260000,
			"n": 35
		  },
		  {
			"v": 267708,
			"vw": 22.2986,
			"o": 22.2971,
			"c": 22.2982,
			"h": 22.3021,
			"l": 22.2964,
			"t": 1344277320000,
			"n": 67
		  },
		  {
			"v": 322924,
			"vw": 22.2956,
			"o": 22.2957,
			"c": 22.2992,
			"h": 22.2993,
			"l": 22.2932,
			"t": 1344277380000,
			"n": 52
		  },
		  {
			"v": 316764,
			"vw": 22.2972,
			"o": 22.2964,
			"c": 22.2968,
			"h": 22.2996,
			"l": 22.2909,
			"t": 1344277440000,
			"n": 45
		  },
		  {
			"v": 374472,
			"vw": 22.2966,
			"o": 22.2968,
			"c": 22.2954,
			"h": 22.2996,
			"l": 22.2933,
			"t": 1344277500000,
			"n": 90
		  },
		  {
			"v": 267120,
			"vw": 22.2942,
			"o": 22.2954,
			"c": 22.2946,
			"h": 22.2982,
			"l": 22.2911,
			"t": 1344277560000,
			"n": 70
		  },
		  {
			"v": 339976,
			"vw": 22.294,
			"o": 22.2979,
			"c": 22.2971,
			"h": 22.2979,
			"l": 22.2907,
			"t": 1344277620000,
			"n": 77
		  },
		  {
			"v": 186228,
			"vw": 22.2951,
			"o": 22.2957,
			"c": 22.2971,
			"h": 22.2975,
			"l": 22.2932,
			"t": 1344277680000,
			"n": 48
		  },
		  {
			"v": 121464,
			"vw": 22.2962,
			"o": 22.295,
			"c": 22.2969,
			"h": 22.2975,
			"l": 22.295,
			"t": 1344277740000,
			"n": 30
		  },
		  {
			"v": 444052,
			"vw": 22.2945,
			"o": 22.295,
			"c": 22.2958,
			"h": 22.2975,
			"l": 22.2921,
			"t": 1344277800000,
			"n": 71
		  },
		  {
			"v": 99680,
			"vw": 22.2934,
			"o": 22.296,
			"c": 22.2939,
			"h": 22.2961,
			"l": 22.292,
			"t": 1344277860000,
			"n": 24
		  },
		  {
			"v": 290276,
			"vw": 22.2921,
			"o": 22.2939,
			"c": 22.29,
			"h": 22.2964,
			"l": 22.29,
			"t": 1344277920000,
			"n": 90
		  },
		  {
			"v": 562996,
			"vw": 22.2868,
			"o": 22.2893,
			"c": 22.2915,
			"h": 22.2936,
			"l": 22.2814,
			"t": 1344277980000,
			"n": 131
		  },
		  {
			"v": 624848,
			"vw": 22.2912,
			"o": 22.2893,
			"c": 22.2857,
			"h": 22.2993,
			"l": 22.2825,
			"t": 1344278040000,
			"n": 121
		  },
		  {
			"v": 253848,
			"vw": 22.2869,
			"o": 22.2854,
			"c": 22.2832,
			"h": 22.2897,
			"l": 22.2832,
			"t": 1344278100000,
			"n": 53
		  },
		  {
			"v": 135604,
			"vw": 22.2869,
			"o": 22.2836,
			"c": 22.2871,
			"h": 22.2907,
			"l": 22.2829,
			"t": 1344278160000,
			"n": 44
		  },
		  {
			"v": 284508,
			"vw": 22.2912,
			"o": 22.2889,
			"c": 22.2918,
			"h": 22.2957,
			"l": 22.2888,
			"t": 1344278220000,
			"n": 48
		  },
		  {
			"v": 307300,
			"vw": 22.2896,
			"o": 22.2925,
			"c": 22.2961,
			"h": 22.2961,
			"l": 22.2865,
			"t": 1344278280000,
			"n": 87
		  },
		  {
			"v": 207396,
			"vw": 22.29,
			"o": 22.2904,
			"c": 22.2964,
			"h": 22.2964,
			"l": 22.2857,
			"t": 1344278340000,
			"n": 55
		  },
		  {
			"v": 273224,
			"vw": 22.2969,
			"o": 22.2929,
			"c": 22.2989,
			"h": 22.3,
			"l": 22.2925,
			"t": 1344278400000,
			"n": 75
		  },
		  {
			"v": 130200,
			"vw": 22.2952,
			"o": 22.2971,
			"c": 22.2982,
			"h": 22.2993,
			"l": 22.2911,
			"t": 1344278460000,
			"n": 41
		  },
		  {
			"v": 186620,
			"vw": 22.2934,
			"o": 22.2943,
			"c": 22.2926,
			"h": 22.2979,
			"l": 22.2896,
			"t": 1344278520000,
			"n": 43
		  },
		  {
			"v": 73472,
			"vw": 22.2942,
			"o": 22.2961,
			"c": 22.2946,
			"h": 22.2964,
			"l": 22.2925,
			"t": 1344278580000,
			"n": 23
		  },
		  {
			"v": 236796,
			"vw": 22.2921,
			"o": 22.2962,
			"c": 22.2929,
			"h": 22.2962,
			"l": 22.2886,
			"t": 1344278640000,
			"n": 51
		  },
		  {
			"v": 147560,
			"vw": 22.2892,
			"o": 22.2929,
			"c": 22.2879,
			"h": 22.2929,
			"l": 22.2875,
			"t": 1344278700000,
			"n": 41
		  },
		  {
			"v": 371952,
			"vw": 22.2863,
			"o": 22.2904,
			"c": 22.2851,
			"h": 22.2904,
			"l": 22.285,
			"t": 1344278760000,
			"n": 75
		  },
		  {
			"v": 299628,
			"vw": 22.2808,
			"o": 22.2854,
			"c": 22.2804,
			"h": 22.2857,
			"l": 22.2775,
			"t": 1344278820000,
			"n": 79
		  },
		  {
			"v": 239960,
			"vw": 22.2809,
			"o": 22.2814,
			"c": 22.2821,
			"h": 22.2829,
			"l": 22.2779,
			"t": 1344278880000,
			"n": 59
		  },
		  {
			"v": 536956,
			"vw": 22.2854,
			"o": 22.2818,
			"c": 22.2889,
			"h": 22.2918,
			"l": 22.2814,
			"t": 1344278940000,
			"n": 75
		  },
		  {
			"v": 265720,
			"vw": 22.2899,
			"o": 22.2889,
			"c": 22.2882,
			"h": 22.2939,
			"l": 22.2857,
			"t": 1344279000000,
			"n": 75
		  },
		  {
			"v": 213388,
			"vw": 22.2883,
			"o": 22.2882,
			"c": 22.2871,
			"h": 22.2911,
			"l": 22.2839,
			"t": 1344279060000,
			"n": 48
		  },
		  {
			"v": 173124,
			"vw": 22.2848,
			"o": 22.2852,
			"c": 22.2857,
			"h": 22.2868,
			"l": 22.2807,
			"t": 1344279120000,
			"n": 48
		  },
		  {
			"v": 510972,
			"vw": 22.2859,
			"o": 22.2829,
			"c": 22.2857,
			"h": 22.2889,
			"l": 22.2829,
			"t": 1344279180000,
			"n": 71
		  },
		  {
			"v": 418824,
			"vw": 22.2845,
			"o": 22.2868,
			"c": 22.2855,
			"h": 22.2889,
			"l": 22.2814,
			"t": 1344279240000,
			"n": 78
		  },
		  {
			"v": 692132,
			"vw": 22.2825,
			"o": 22.285,
			"c": 22.2761,
			"h": 22.2864,
			"l": 22.2754,
			"t": 1344279300000,
			"n": 107
		  },
		  {
			"v": 326480,
			"vw": 22.277,
			"o": 22.2766,
			"c": 22.2786,
			"h": 22.2789,
			"l": 22.2754,
			"t": 1344279360000,
			"n": 79
		  },
		  {
			"v": 960036,
			"vw": 22.2662,
			"o": 22.2751,
			"c": 22.2682,
			"h": 22.2768,
			"l": 22.2593,
			"t": 1344279420000,
			"n": 212
		  },
		  {
			"v": 773668,
			"vw": 22.262,
			"o": 22.2696,
			"c": 22.2536,
			"h": 22.2711,
			"l": 22.2525,
			"t": 1344279480000,
			"n": 159
		  },
		  {
			"v": 596764,
			"vw": 22.2626,
			"o": 22.2564,
			"c": 22.2675,
			"h": 22.2707,
			"l": 22.2518,
			"t": 1344279540000,
			"n": 145
		  },
		  {
			"v": 690704,
			"vw": 22.2698,
			"o": 22.2654,
			"c": 22.27,
			"h": 22.2786,
			"l": 22.2639,
			"t": 1344279600000,
			"n": 171
		  },
		  {
			"v": 253008,
			"vw": 22.2645,
			"o": 22.2664,
			"c": 22.2675,
			"h": 22.2719,
			"l": 22.2607,
			"t": 1344279660000,
			"n": 63
		  },
		  {
			"v": 242620,
			"vw": 22.2673,
			"o": 22.2643,
			"c": 22.2662,
			"h": 22.2706,
			"l": 22.2632,
			"t": 1344279720000,
			"n": 64
		  },
		  {
			"v": 210112,
			"vw": 22.2702,
			"o": 22.2664,
			"c": 22.2732,
			"h": 22.2732,
			"l": 22.2661,
			"t": 1344279780000,
			"n": 46
		  },
		  {
			"v": 2455516,
			"vw": 22.2837,
			"o": 22.2736,
			"c": 22.2728,
			"h": 22.2857,
			"l": 22.2679,
			"t": 1344279840000,
			"n": 71
		  },
		  {
			"v": 456456,
			"vw": 22.2635,
			"o": 22.2707,
			"c": 22.26,
			"h": 22.2711,
			"l": 22.2586,
			"t": 1344279900000,
			"n": 109
		  },
		  {
			"v": 476672,
			"vw": 22.2585,
			"o": 22.26,
			"c": 22.2644,
			"h": 22.2652,
			"l": 22.2554,
			"t": 1344279960000,
			"n": 109
		  },
		  {
			"v": 277032,
			"vw": 22.268,
			"o": 22.2629,
			"c": 22.2632,
			"h": 22.2739,
			"l": 22.2625,
			"t": 1344280020000,
			"n": 60
		  },
		  {
			"v": 288456,
			"vw": 22.2674,
			"o": 22.2639,
			"c": 22.2689,
			"h": 22.2714,
			"l": 22.2639,
			"t": 1344280080000,
			"n": 65
		  },
		  {
			"v": 254436,
			"vw": 22.2716,
			"o": 22.2682,
			"c": 22.2693,
			"h": 22.2739,
			"l": 22.2679,
			"t": 1344280140000,
			"n": 63
		  },
		  {
			"v": 703500,
			"vw": 22.2725,
			"o": 22.2693,
			"c": 22.2764,
			"h": 22.2768,
			"l": 22.2657,
			"t": 1344280200000,
			"n": 151
		  },
		  {
			"v": 237496,
			"vw": 22.2752,
			"o": 22.2768,
			"c": 22.2739,
			"h": 22.2768,
			"l": 22.2714,
			"t": 1344280260000,
			"n": 57
		  },
		  {
			"v": 301840,
			"vw": 22.2724,
			"o": 22.2714,
			"c": 22.2707,
			"h": 22.2768,
			"l": 22.2679,
			"t": 1344280320000,
			"n": 61
		  },
		  {
			"v": 357280,
			"vw": 22.2698,
			"o": 22.2696,
			"c": 22.2721,
			"h": 22.2721,
			"l": 22.2668,
			"t": 1344280380000,
			"n": 65
		  },
		  {
			"v": 117516,
			"vw": 22.2723,
			"o": 22.2711,
			"c": 22.2757,
			"h": 22.2757,
			"l": 22.27,
			"t": 1344280440000,
			"n": 33
		  },
		  {
			"v": 232512,
			"vw": 22.271,
			"o": 22.2721,
			"c": 22.2696,
			"h": 22.2738,
			"l": 22.2664,
			"t": 1344280500000,
			"n": 63
		  },
		  {
			"v": 392308,
			"vw": 22.2623,
			"o": 22.2661,
			"c": 22.2657,
			"h": 22.2682,
			"l": 22.2593,
			"t": 1344280560000,
			"n": 96
		  },
		  {
			"v": 168000,
			"vw": 22.2633,
			"o": 22.265,
			"c": 22.265,
			"h": 22.2654,
			"l": 22.2611,
			"t": 1344280620000,
			"n": 50
		  },
		  {
			"v": 207676,
			"vw": 22.2659,
			"o": 22.2638,
			"c": 22.2663,
			"h": 22.2671,
			"l": 22.2638,
			"t": 1344280680000,
			"n": 50
		  },
		  {
			"v": 321496,
			"vw": 22.2692,
			"o": 22.2671,
			"c": 22.2671,
			"h": 22.2729,
			"l": 22.2654,
			"t": 1344280740000,
			"n": 83
		  },
		  {
			"v": 217560,
			"vw": 22.2684,
			"o": 22.2693,
			"c": 22.2679,
			"h": 22.2711,
			"l": 22.2661,
			"t": 1344280800000,
			"n": 53
		  },
		  {
			"v": 271628,
			"vw": 22.265,
			"o": 22.2679,
			"c": 22.2611,
			"h": 22.2707,
			"l": 22.2589,
			"t": 1344280860000,
			"n": 67
		  },
		  {
			"v": 335804,
			"vw": 22.2641,
			"o": 22.2604,
			"c": 22.27,
			"h": 22.2711,
			"l": 22.2589,
			"t": 1344280920000,
			"n": 79
		  },
		  {
			"v": 553700,
			"vw": 22.2669,
			"o": 22.2701,
			"c": 22.2639,
			"h": 22.2701,
			"l": 22.2639,
			"t": 1344280980000,
			"n": 98
		  },
		  {
			"v": 223832,
			"vw": 22.2625,
			"o": 22.2621,
			"c": 22.2675,
			"h": 22.2675,
			"l": 22.2609,
			"t": 1344281040000,
			"n": 29
		  },
		  {
			"v": 255948,
			"vw": 22.2661,
			"o": 22.2659,
			"c": 22.2643,
			"h": 22.2682,
			"l": 22.2632,
			"t": 1344281100000,
			"n": 65
		  },
		  {
			"v": 276220,
			"vw": 22.2678,
			"o": 22.2675,
			"c": 22.2691,
			"h": 22.2707,
			"l": 22.2643,
			"t": 1344281160000,
			"n": 55
		  },
		  {
			"v": 861168,
			"vw": 22.2776,
			"o": 22.2693,
			"c": 22.2844,
			"h": 22.285,
			"l": 22.2693,
			"t": 1344281220000,
			"n": 170
		  },
		  {
			"v": 873124,
			"vw": 22.2813,
			"o": 22.2854,
			"c": 22.2744,
			"h": 22.2857,
			"l": 22.2743,
			"t": 1344281280000,
			"n": 171
		  },
		  {
			"v": 724696,
			"vw": 22.2811,
			"o": 22.2761,
			"c": 22.2832,
			"h": 22.2832,
			"l": 22.2761,
			"t": 1344281340000,
			"n": 156
		  },
		  {
			"v": 565012,
			"vw": 22.2833,
			"o": 22.2832,
			"c": 22.2827,
			"h": 22.285,
			"l": 22.2793,
			"t": 1344281400000,
			"n": 144
		  },
		  {
			"v": 825888,
			"vw": 22.2824,
			"o": 22.2839,
			"c": 22.2814,
			"h": 22.285,
			"l": 22.2804,
			"t": 1344281460000,
			"n": 192
		  },
		  {
			"v": 402780,
			"vw": 22.2787,
			"o": 22.2811,
			"c": 22.2786,
			"h": 22.2818,
			"l": 22.2764,
			"t": 1344281520000,
			"n": 95
		  },
		  {
			"v": 316372,
			"vw": 22.2793,
			"o": 22.2776,
			"c": 22.2784,
			"h": 22.2814,
			"l": 22.2775,
			"t": 1344281580000,
			"n": 82
		  },
		  {
			"v": 436296,
			"vw": 22.2811,
			"o": 22.2779,
			"c": 22.2832,
			"h": 22.285,
			"l": 22.2771,
			"t": 1344281640000,
			"n": 94
		  },
		  {
			"v": 400176,
			"vw": 22.2785,
			"o": 22.2825,
			"c": 22.2743,
			"h": 22.2843,
			"l": 22.2743,
			"t": 1344281700000,
			"n": 97
		  },
		  {
			"v": 355488,
			"vw": 22.2791,
			"o": 22.2745,
			"c": 22.2822,
			"h": 22.2839,
			"l": 22.2745,
			"t": 1344281760000,
			"n": 69
		  },
		  {
			"v": 217084,
			"vw": 22.2815,
			"o": 22.2816,
			"c": 22.2805,
			"h": 22.2832,
			"l": 22.2796,
			"t": 1344281820000,
			"n": 64
		  },
		  {
			"v": 197876,
			"vw": 22.2801,
			"o": 22.2804,
			"c": 22.2793,
			"h": 22.282,
			"l": 22.2789,
			"t": 1344281880000,
			"n": 49
		  },
		  {
			"v": 230860,
			"vw": 22.2804,
			"o": 22.2793,
			"c": 22.2807,
			"h": 22.2829,
			"l": 22.2786,
			"t": 1344281940000,
			"n": 66
		  },
		  {
			"v": 433804,
			"vw": 22.2793,
			"o": 22.2811,
			"c": 22.2809,
			"h": 22.2821,
			"l": 22.2754,
			"t": 1344282000000,
			"n": 102
		  },
		  {
			"v": 697284,
			"vw": 22.2743,
			"o": 22.2786,
			"c": 22.2743,
			"h": 22.2792,
			"l": 22.2707,
			"t": 1344282060000,
			"n": 197
		  },
		  {
			"v": 382844,
			"vw": 22.2735,
			"o": 22.2746,
			"c": 22.2711,
			"h": 22.2768,
			"l": 22.2711,
			"t": 1344282120000,
			"n": 83
		  },
		  {
			"v": 704704,
			"vw": 22.2724,
			"o": 22.2711,
			"c": 22.278,
			"h": 22.2786,
			"l": 22.2679,
			"t": 1344282180000,
			"n": 178
		  },
		  {
			"v": 444528,
			"vw": 22.2745,
			"o": 22.2779,
			"c": 22.2771,
			"h": 22.2779,
			"l": 22.2714,
			"t": 1344282240000,
			"n": 114
		  },
		  {
			"v": 565572,
			"vw": 22.2762,
			"o": 22.2775,
			"c": 22.2736,
			"h": 22.2796,
			"l": 22.2725,
			"t": 1344282300000,
			"n": 124
		  },
		  {
			"v": 1044736,
			"vw": 22.2682,
			"o": 22.2746,
			"c": 22.2692,
			"h": 22.2746,
			"l": 22.2625,
			"t": 1344282360000,
			"n": 268
		  },
		  {
			"v": 715736,
			"vw": 22.2626,
			"o": 22.2679,
			"c": 22.2554,
			"h": 22.2696,
			"l": 22.2543,
			"t": 1344282420000,
			"n": 171
		  },
		  {
			"v": 1413384,
			"vw": 22.2565,
			"o": 22.2571,
			"c": 22.2575,
			"h": 22.2629,
			"l": 22.2529,
			"t": 1344282480000,
			"n": 343
		  },
		  {
			"v": 2660336,
			"vw": 22.2409,
			"o": 22.2564,
			"c": 22.2286,
			"h": 22.2564,
			"l": 22.2229,
			"t": 1344282540000,
			"n": 660
		  },
		  {
			"v": 2563792,
			"vw": 22.2257,
			"o": 22.2282,
			"c": 22.2168,
			"h": 22.2389,
			"l": 22.2139,
			"t": 1344282600000,
			"n": 602
		  },
		  {
			"v": 2499812,
			"vw": 22.2259,
			"o": 22.215,
			"c": 22.235,
			"h": 22.2432,
			"l": 22.2111,
			"t": 1344282660000,
			"n": 622
		  },
		  {
			"v": 1194844,
			"vw": 22.2299,
			"o": 22.2368,
			"c": 22.2329,
			"h": 22.2371,
			"l": 22.2243,
			"t": 1344282720000,
			"n": 291
		  },
		  {
			"v": 952672,
			"vw": 22.2361,
			"o": 22.2339,
			"c": 22.2436,
			"h": 22.2443,
			"l": 22.2296,
			"t": 1344282780000,
			"n": 256
		  },
		  {
			"v": 923244,
			"vw": 22.2451,
			"o": 22.2411,
			"c": 22.2454,
			"h": 22.2493,
			"l": 22.2393,
			"t": 1344282840000,
			"n": 229
		  },
		  {
			"v": 979916,
			"vw": 22.2353,
			"o": 22.2462,
			"c": 22.2329,
			"h": 22.2464,
			"l": 22.2282,
			"t": 1344282900000,
			"n": 271
		  },
		  {
			"v": 1402184,
			"vw": 22.2235,
			"o": 22.2329,
			"c": 22.217,
			"h": 22.2356,
			"l": 22.2143,
			"t": 1344282960000,
			"n": 372
		  },
		  {
			"v": 2378656,
			"vw": 22.2111,
			"o": 22.2157,
			"c": 22.2061,
			"h": 22.2171,
			"l": 22.2061,
			"t": 1344283020000,
			"n": 561
		  },
		  {
			"v": 1931552,
			"vw": 22.2087,
			"o": 22.2071,
			"c": 22.2189,
			"h": 22.2195,
			"l": 22.1986,
			"t": 1344283080000,
			"n": 475
		  },
		  {
			"v": 3352104,
			"vw": 22.2238,
			"o": 22.2186,
			"c": 22.2315,
			"h": 22.235,
			"l": 22.2146,
			"t": 1344283140000,
			"n": 788
		  },
		  {
			"v": 67508,
			"vw": 22.2206,
			"o": 22.2275,
			"c": 22.22,
			"h": 22.2275,
			"l": 22.2146,
			"t": 1344283200000,
			"n": 18
		  },
		  {
			"v": 86184,
			"vw": 22.2209,
			"o": 22.22,
			"c": 22.2214,
			"h": 22.2229,
			"l": 22.2171,
			"t": 1344283260000,
			"n": 20
		  },
		  {
			"v": 22400,
			"vw": 22.2226,
			"o": 22.2218,
			"c": 22.2246,
			"h": 22.2246,
			"l": 22.2214,
			"t": 1344283320000,
			"n": 6
		  },
		  {
			"v": 78400,
			"vw": 22.2206,
			"o": 22.2229,
			"c": 22.2214,
			"h": 22.2229,
			"l": 22.2196,
			"t": 1344283380000,
			"n": 10
		  },
		  {
			"v": 34272,
			"vw": 22.1975,
			"o": 22.2196,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2196,
			"t": 1344283440000,
			"n": 3
		  },
		  {
			"v": 12600,
			"vw": 22.2204,
			"o": 22.2204,
			"c": 22.2204,
			"h": 22.2204,
			"l": 22.2204,
			"t": 1344283500000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.2189,
			"o": 22.2196,
			"c": 22.2182,
			"h": 22.2196,
			"l": 22.2182,
			"t": 1344283560000,
			"n": 2
		  },
		  {
			"v": 19180,
			"vw": 22.2145,
			"o": 22.215,
			"c": 22.2143,
			"h": 22.215,
			"l": 22.2143,
			"t": 1344283620000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2229,
			"o": 22.2229,
			"c": 22.2229,
			"h": 22.2229,
			"l": 22.2229,
			"t": 1344283740000,
			"n": 1
		  },
		  {
			"v": 98000,
			"vw": 22.1967,
			"o": 22.2196,
			"c": 22.2157,
			"h": 22.2196,
			"l": 22.2157,
			"t": 1344283800000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.2166,
			"o": 22.2157,
			"c": 22.2175,
			"h": 22.2175,
			"l": 22.2157,
			"t": 1344283860000,
			"n": 2
		  },
		  {
			"v": 10360,
			"vw": 22.2166,
			"o": 22.2161,
			"c": 22.2179,
			"h": 22.2179,
			"l": 22.2161,
			"t": 1344283920000,
			"n": 3
		  },
		  {
			"v": 27468,
			"vw": 22.2145,
			"o": 22.215,
			"c": 22.2143,
			"h": 22.215,
			"l": 22.2143,
			"t": 1344283980000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344284040000,
			"n": 1
		  },
		  {
			"v": 94304,
			"vw": 22.2116,
			"o": 22.2129,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2071,
			"t": 1344284100000,
			"n": 9
		  },
		  {
			"v": 57876,
			"vw": 22.2105,
			"o": 22.2093,
			"c": 22.2093,
			"h": 22.2093,
			"l": 22.2093,
			"t": 1344284160000,
			"n": 11
		  },
		  {
			"v": 5600,
			"vw": 22.2118,
			"o": 22.2118,
			"c": 22.2118,
			"h": 22.2118,
			"l": 22.2118,
			"t": 1344284220000,
			"n": 2
		  },
		  {
			"v": 12264,
			"vw": 22.2113,
			"o": 22.2118,
			"c": 22.2089,
			"h": 22.2125,
			"l": 22.2089,
			"t": 1344284280000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.2121,
			"o": 22.2086,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2086,
			"t": 1344284340000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344284400000,
			"n": 1
		  },
		  {
			"v": 101248,
			"vw": 22.2302,
			"o": 22.2168,
			"c": 22.2186,
			"h": 22.2186,
			"l": 22.2168,
			"t": 1344284460000,
			"n": 9
		  },
		  {
			"v": 15176,
			"vw": 22.2178,
			"o": 22.2179,
			"c": 22.2179,
			"h": 22.2179,
			"l": 22.2175,
			"t": 1344284520000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.2186,
			"o": 22.2186,
			"c": 22.2186,
			"h": 22.2186,
			"l": 22.2186,
			"t": 1344284580000,
			"n": 1
		  },
		  {
			"v": 209608,
			"vw": 22.2328,
			"o": 22.2179,
			"c": 22.2093,
			"h": 22.2179,
			"l": 22.2093,
			"t": 1344284700000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.2089,
			"o": 22.2089,
			"c": 22.2089,
			"h": 22.2089,
			"l": 22.2089,
			"t": 1344284820000,
			"n": 1
		  },
		  {
			"v": 44800,
			"vw": 22.2091,
			"o": 22.2096,
			"c": 22.2089,
			"h": 22.2096,
			"l": 22.2089,
			"t": 1344284880000,
			"n": 8
		  },
		  {
			"v": 5600,
			"vw": 22.2095,
			"o": 22.2093,
			"c": 22.2096,
			"h": 22.2096,
			"l": 22.2093,
			"t": 1344284940000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.2114,
			"o": 22.2121,
			"c": 22.2107,
			"h": 22.2121,
			"l": 22.2107,
			"t": 1344285060000,
			"n": 2
		  },
		  {
			"v": 13272,
			"vw": 22.2133,
			"o": 22.2132,
			"c": 22.2136,
			"h": 22.2136,
			"l": 22.2132,
			"t": 1344285300000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2093,
			"o": 22.2093,
			"c": 22.2093,
			"h": 22.2093,
			"l": 22.2093,
			"t": 1344285360000,
			"n": 1
		  },
		  {
			"v": 8680,
			"vw": 22.2128,
			"o": 22.2132,
			"c": 22.2125,
			"h": 22.2132,
			"l": 22.2125,
			"t": 1344285420000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.2127,
			"o": 22.2125,
			"c": 22.2129,
			"h": 22.2129,
			"l": 22.2125,
			"t": 1344285480000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2125,
			"o": 22.2125,
			"c": 22.2125,
			"h": 22.2125,
			"l": 22.2125,
			"t": 1344285540000,
			"n": 1
		  },
		  {
			"v": 4060,
			"vw": 22.2125,
			"o": 22.2125,
			"c": 22.2125,
			"h": 22.2125,
			"l": 22.2125,
			"t": 1344285600000,
			"n": 1
		  },
		  {
			"v": 11928,
			"vw": 22.2295,
			"o": 22.2107,
			"c": 22.2104,
			"h": 22.2107,
			"l": 22.2089,
			"t": 1344285720000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.2121,
			"o": 22.2121,
			"c": 22.2121,
			"h": 22.2121,
			"l": 22.2121,
			"t": 1344285840000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2089,
			"o": 22.2089,
			"c": 22.2089,
			"h": 22.2089,
			"l": 22.2089,
			"t": 1344285900000,
			"n": 1
		  },
		  {
			"v": 13384,
			"vw": 22.2116,
			"o": 22.2089,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2089,
			"t": 1344285960000,
			"n": 4
		  },
		  {
			"v": 24360,
			"vw": 22.1902,
			"o": 22.21,
			"c": 22.2107,
			"h": 22.2129,
			"l": 22.1092,
			"t": 1344286020000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 22.2074,
			"o": 22.2075,
			"c": 22.2071,
			"h": 22.2075,
			"l": 22.2071,
			"t": 1344286140000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.2071,
			"o": 22.2071,
			"c": 22.2071,
			"h": 22.2071,
			"l": 22.2071,
			"t": 1344286200000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2088,
			"o": 22.2079,
			"c": 22.2096,
			"h": 22.2096,
			"l": 22.2079,
			"t": 1344286560000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2125,
			"o": 22.2125,
			"c": 22.2125,
			"h": 22.2125,
			"l": 22.2125,
			"t": 1344286620000,
			"n": 1
		  },
		  {
			"v": 3220,
			"vw": 22.2136,
			"o": 22.2136,
			"c": 22.2136,
			"h": 22.2136,
			"l": 22.2136,
			"t": 1344286800000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2107,
			"o": 22.2107,
			"c": 22.2107,
			"h": 22.2107,
			"l": 22.2107,
			"t": 1344287220000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.21,
			"o": 22.21,
			"c": 22.21,
			"h": 22.21,
			"l": 22.21,
			"t": 1344287340000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2114,
			"o": 22.2114,
			"c": 22.2114,
			"h": 22.2114,
			"l": 22.2114,
			"t": 1344287520000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2082,
			"o": 22.2082,
			"c": 22.2082,
			"h": 22.2082,
			"l": 22.2082,
			"t": 1344287580000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2079,
			"o": 22.2079,
			"c": 22.2079,
			"h": 22.2079,
			"l": 22.2079,
			"t": 1344287640000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 22.2076,
			"o": 22.2079,
			"c": 22.2075,
			"h": 22.2079,
			"l": 22.2075,
			"t": 1344287820000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2075,
			"o": 22.2075,
			"c": 22.2075,
			"h": 22.2075,
			"l": 22.2075,
			"t": 1344287880000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2075,
			"o": 22.2075,
			"c": 22.2075,
			"h": 22.2075,
			"l": 22.2075,
			"t": 1344288120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2107,
			"o": 22.2107,
			"c": 22.2107,
			"h": 22.2107,
			"l": 22.2107,
			"t": 1344288180000,
			"n": 1
		  },
		  {
			"v": 3276,
			"vw": 22.2132,
			"o": 22.2132,
			"c": 22.2132,
			"h": 22.2132,
			"l": 22.2132,
			"t": 1344288300000,
			"n": 1
		  },
		  {
			"v": 15988,
			"vw": 22.2102,
			"o": 22.2104,
			"c": 22.2086,
			"h": 22.2107,
			"l": 22.2086,
			"t": 1344288720000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.2104,
			"o": 22.21,
			"c": 22.2107,
			"h": 22.2107,
			"l": 22.21,
			"t": 1344289020000,
			"n": 2
		  },
		  {
			"v": 8596,
			"vw": 22.2093,
			"o": 22.2093,
			"c": 22.2093,
			"h": 22.2093,
			"l": 22.2093,
			"t": 1344289080000,
			"n": 2
		  },
		  {
			"v": 10416,
			"vw": 22.2093,
			"o": 22.2093,
			"c": 22.2093,
			"h": 22.2093,
			"l": 22.2093,
			"t": 1344289380000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2132,
			"o": 22.2132,
			"c": 22.2132,
			"h": 22.2132,
			"l": 22.2132,
			"t": 1344289680000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2132,
			"o": 22.2132,
			"c": 22.2132,
			"h": 22.2132,
			"l": 22.2132,
			"t": 1344289860000,
			"n": 1
		  },
		  {
			"v": 5264,
			"vw": 22.2139,
			"o": 22.2139,
			"c": 22.2139,
			"h": 22.2139,
			"l": 22.2139,
			"t": 1344289920000,
			"n": 1
		  },
		  {
			"v": 10248,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344290160000,
			"n": 2
		  },
		  {
			"v": 3976,
			"vw": 22.2193,
			"o": 22.2193,
			"c": 22.2193,
			"h": 22.2193,
			"l": 22.2193,
			"t": 1344290340000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.2167,
			"o": 22.2179,
			"c": 22.2161,
			"h": 22.2179,
			"l": 22.2161,
			"t": 1344291060000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.2148,
			"o": 22.215,
			"c": 22.2146,
			"h": 22.215,
			"l": 22.2146,
			"t": 1344291540000,
			"n": 3
		  },
		  {
			"v": 33600,
			"vw": 22.2143,
			"o": 22.2146,
			"c": 22.2139,
			"h": 22.2146,
			"l": 22.2139,
			"t": 1344291600000,
			"n": 8
		  },
		  {
			"v": 16408,
			"vw": 22.2113,
			"o": 22.2107,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2107,
			"t": 1344291660000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344291720000,
			"n": 1
		  },
		  {
			"v": 6440,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344292020000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2118,
			"o": 22.2118,
			"c": 22.2118,
			"h": 22.2118,
			"l": 22.2118,
			"t": 1344292140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2143,
			"o": 22.2143,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2143,
			"t": 1344292260000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2171,
			"o": 22.2171,
			"c": 22.2171,
			"h": 22.2171,
			"l": 22.2171,
			"t": 1344292620000,
			"n": 1
		  },
		  {
			"v": 26320,
			"vw": 22.2188,
			"o": 22.2179,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2179,
			"t": 1344292680000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.2189,
			"o": 22.2189,
			"c": 22.2189,
			"h": 22.2189,
			"l": 22.2189,
			"t": 1344292740000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2186,
			"o": 22.2186,
			"c": 22.2186,
			"h": 22.2186,
			"l": 22.2186,
			"t": 1344293700000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2196,
			"o": 22.2196,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2196,
			"t": 1344294540000,
			"n": 1
		  },
		  {
			"v": 25452,
			"vw": 22.2189,
			"o": 22.2189,
			"c": 22.2182,
			"h": 22.2189,
			"l": 22.2182,
			"t": 1344294840000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.22,
			"o": 22.22,
			"c": 22.22,
			"h": 22.22,
			"l": 22.22,
			"t": 1344294900000,
			"n": 1
		  },
		  {
			"v": 4368,
			"vw": 22.22,
			"o": 22.22,
			"c": 22.22,
			"h": 22.22,
			"l": 22.22,
			"t": 1344295080000,
			"n": 1
		  },
		  {
			"v": 22232,
			"vw": 22.2179,
			"o": 22.2179,
			"c": 22.2179,
			"h": 22.2179,
			"l": 22.2179,
			"t": 1344295500000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2196,
			"o": 22.2196,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2196,
			"t": 1344295860000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2196,
			"o": 22.2196,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2196,
			"t": 1344295920000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2157,
			"o": 22.2157,
			"c": 22.2157,
			"h": 22.2157,
			"l": 22.2157,
			"t": 1344296220000,
			"n": 1
		  },
		  {
			"v": 7952,
			"vw": 22.2186,
			"o": 22.2186,
			"c": 22.2186,
			"h": 22.2186,
			"l": 22.2186,
			"t": 1344296640000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.2179,
			"o": 22.2179,
			"c": 22.2179,
			"h": 22.2179,
			"l": 22.2179,
			"t": 1344296880000,
			"n": 2
		  },
		  {
			"v": 20888,
			"vw": 22.2176,
			"o": 22.2179,
			"c": 22.2164,
			"h": 22.2179,
			"l": 22.2164,
			"t": 1344296940000,
			"n": 4
		  },
		  {
			"v": 9520,
			"vw": 22.2196,
			"o": 22.2168,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2168,
			"t": 1344297000000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.2186,
			"o": 22.2207,
			"c": 22.2179,
			"h": 22.2207,
			"l": 22.2179,
			"t": 1344297060000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.2146,
			"o": 22.2146,
			"c": 22.2146,
			"h": 22.2146,
			"l": 22.2146,
			"t": 1344297180000,
			"n": 1
		  },
		  {
			"v": 3192,
			"vw": 22.2171,
			"o": 22.2171,
			"c": 22.2171,
			"h": 22.2171,
			"l": 22.2171,
			"t": 1344297360000,
			"n": 1
		  },
		  {
			"v": 19180,
			"vw": 22.2166,
			"o": 22.2175,
			"c": 22.2154,
			"h": 22.2175,
			"l": 22.2154,
			"t": 1344297420000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.2161,
			"o": 22.2161,
			"c": 22.2161,
			"h": 22.2161,
			"l": 22.2161,
			"t": 1344297480000,
			"n": 1
		  },
		  {
			"v": 24808,
			"vw": 22.215,
			"o": 22.2179,
			"c": 22.2143,
			"h": 22.2179,
			"l": 22.2143,
			"t": 1344297540000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.3121,
			"o": 22.3029,
			"c": 22.3214,
			"h": 22.3214,
			"l": 22.3029,
			"t": 1344326400000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.3246,
			"o": 22.3246,
			"c": 22.3246,
			"h": 22.3246,
			"l": 22.3246,
			"t": 1344326820000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.3214,
			"o": 22.3214,
			"c": 22.3214,
			"h": 22.3214,
			"l": 22.3214,
			"t": 1344328680000,
			"n": 1
		  },
		  {
			"v": 10724,
			"vw": 22.3168,
			"o": 22.3214,
			"c": 22.3125,
			"h": 22.3214,
			"l": 22.3125,
			"t": 1344329340000,
			"n": 3
		  },
		  {
			"v": 8260,
			"vw": 22.3125,
			"o": 22.3125,
			"c": 22.3125,
			"h": 22.3125,
			"l": 22.3125,
			"t": 1344329700000,
			"n": 2
		  },
		  {
			"v": 3416,
			"vw": 22.3071,
			"o": 22.3071,
			"c": 22.3071,
			"h": 22.3071,
			"l": 22.3071,
			"t": 1344330480000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 22.3,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3,
			"l": 22.3,
			"t": 1344331140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2857,
			"o": 22.2857,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2857,
			"t": 1344334080000,
			"n": 1
		  },
		  {
			"v": 4200,
			"vw": 22.2857,
			"o": 22.2857,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2857,
			"t": 1344334200000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2857,
			"o": 22.2857,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2857,
			"t": 1344334620000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2946,
			"o": 22.2946,
			"c": 22.2946,
			"h": 22.2946,
			"l": 22.2946,
			"t": 1344335880000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.2786,
			"o": 22.2786,
			"c": 22.2786,
			"h": 22.2786,
			"l": 22.2786,
			"t": 1344337320000,
			"n": 2
		  },
		  {
			"v": 40460,
			"vw": 22.2951,
			"o": 22.2857,
			"c": 22.3032,
			"h": 22.3032,
			"l": 22.2857,
			"t": 1344337380000,
			"n": 8
		  },
		  {
			"v": 28000,
			"vw": 22.3025,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3036,
			"l": 22.3,
			"t": 1344337440000,
			"n": 6
		  },
		  {
			"v": 21000,
			"vw": 22.3,
			"o": 22.3,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.2871,
			"t": 1344337500000,
			"n": 6
		  },
		  {
			"v": 33600,
			"vw": 22.3003,
			"o": 22.2964,
			"c": 22.3029,
			"h": 22.3036,
			"l": 22.2946,
			"t": 1344337560000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.3125,
			"o": 22.3125,
			"c": 22.3125,
			"h": 22.3125,
			"l": 22.3125,
			"t": 1344337620000,
			"n": 2
		  },
		  {
			"v": 16800,
			"vw": 22.3045,
			"o": 22.3036,
			"c": 22.3089,
			"h": 22.3089,
			"l": 22.3036,
			"t": 1344337680000,
			"n": 2
		  },
		  {
			"v": 12488,
			"vw": 22.3068,
			"o": 22.3068,
			"c": 22.3061,
			"h": 22.3071,
			"l": 22.3061,
			"t": 1344337740000,
			"n": 4
		  },
		  {
			"v": 11200,
			"vw": 22.3055,
			"o": 22.3061,
			"c": 22.3039,
			"h": 22.3061,
			"l": 22.3039,
			"t": 1344337800000,
			"n": 3
		  },
		  {
			"v": 10080,
			"vw": 22.3014,
			"o": 22.3039,
			"c": 22.295,
			"h": 22.3039,
			"l": 22.295,
			"t": 1344337860000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.3036,
			"o": 22.3036,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3036,
			"t": 1344337980000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2946,
			"o": 22.2946,
			"c": 22.2946,
			"h": 22.2946,
			"l": 22.2946,
			"t": 1344338220000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2946,
			"o": 22.2946,
			"c": 22.2946,
			"h": 22.2946,
			"l": 22.2946,
			"t": 1344338580000,
			"n": 1
		  },
		  {
			"v": 68684,
			"vw": 22.2964,
			"o": 22.2961,
			"c": 22.2964,
			"h": 22.2964,
			"l": 22.2961,
			"t": 1344338760000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.288,
			"o": 22.2886,
			"c": 22.2875,
			"h": 22.2886,
			"l": 22.2875,
			"t": 1344338940000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2875,
			"o": 22.2875,
			"c": 22.2875,
			"h": 22.2875,
			"l": 22.2875,
			"t": 1344339120000,
			"n": 1
		  },
		  {
			"v": 24640,
			"vw": 22.2875,
			"o": 22.2875,
			"c": 22.2875,
			"h": 22.2875,
			"l": 22.2875,
			"t": 1344339180000,
			"n": 3
		  },
		  {
			"v": 64120,
			"vw": 22.2961,
			"o": 22.2946,
			"c": 22.2964,
			"h": 22.2964,
			"l": 22.2929,
			"t": 1344339360000,
			"n": 6
		  },
		  {
			"v": 42000,
			"vw": 22.3025,
			"o": 22.2964,
			"c": 22.3089,
			"h": 22.3089,
			"l": 22.2964,
			"t": 1344339420000,
			"n": 10
		  },
		  {
			"v": 19600,
			"vw": 22.3036,
			"o": 22.3036,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3036,
			"t": 1344339480000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.3,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3,
			"l": 22.3,
			"t": 1344339660000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.2988,
			"o": 22.2964,
			"c": 22.3,
			"h": 22.3,
			"l": 22.2964,
			"t": 1344339720000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2982,
			"o": 22.2982,
			"c": 22.2982,
			"h": 22.2982,
			"l": 22.2982,
			"t": 1344340140000,
			"n": 1
		  },
		  {
			"v": 12880,
			"vw": 22.2904,
			"o": 22.2911,
			"c": 22.2893,
			"h": 22.2911,
			"l": 22.2893,
			"t": 1344340380000,
			"n": 3
		  },
		  {
			"v": 24892,
			"vw": 22.2811,
			"o": 22.2896,
			"c": 22.2625,
			"h": 22.2896,
			"l": 22.2625,
			"t": 1344340500000,
			"n": 7
		  },
		  {
			"v": 10920,
			"vw": 22.2639,
			"o": 22.2639,
			"c": 22.2639,
			"h": 22.2639,
			"l": 22.2639,
			"t": 1344340680000,
			"n": 3
		  },
		  {
			"v": 128380,
			"vw": 22.28,
			"o": 22.2786,
			"c": 22.2764,
			"h": 22.2857,
			"l": 22.2764,
			"t": 1344340800000,
			"n": 14
		  },
		  {
			"v": 58408,
			"vw": 22.2806,
			"o": 22.2768,
			"c": 22.2718,
			"h": 22.2857,
			"l": 22.2718,
			"t": 1344340860000,
			"n": 11
		  },
		  {
			"v": 55944,
			"vw": 22.2826,
			"o": 22.2857,
			"c": 22.2754,
			"h": 22.2893,
			"l": 22.2721,
			"t": 1344340920000,
			"n": 12
		  },
		  {
			"v": 13524,
			"vw": 22.2739,
			"o": 22.2754,
			"c": 22.2736,
			"h": 22.2754,
			"l": 22.2736,
			"t": 1344340980000,
			"n": 2
		  },
		  {
			"v": 24360,
			"vw": 22.2822,
			"o": 22.2789,
			"c": 22.285,
			"h": 22.285,
			"l": 22.2786,
			"t": 1344341040000,
			"n": 5
		  },
		  {
			"v": 93996,
			"vw": 22.2874,
			"o": 22.2846,
			"c": 22.2957,
			"h": 22.2957,
			"l": 22.2843,
			"t": 1344341100000,
			"n": 20
		  },
		  {
			"v": 72184,
			"vw": 22.2846,
			"o": 22.2857,
			"c": 22.285,
			"h": 22.2929,
			"l": 22.2768,
			"t": 1344341160000,
			"n": 18
		  },
		  {
			"v": 25956,
			"vw": 22.2823,
			"o": 22.2857,
			"c": 22.2736,
			"h": 22.2857,
			"l": 22.2736,
			"t": 1344341220000,
			"n": 6
		  },
		  {
			"v": 34160,
			"vw": 22.2784,
			"o": 22.2736,
			"c": 22.2804,
			"h": 22.2804,
			"l": 22.2736,
			"t": 1344341280000,
			"n": 6
		  },
		  {
			"v": 44800,
			"vw": 22.2789,
			"o": 22.275,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2725,
			"t": 1344341340000,
			"n": 12
		  },
		  {
			"v": 44520,
			"vw": 22.2858,
			"o": 22.2854,
			"c": 22.2879,
			"h": 22.2879,
			"l": 22.2854,
			"t": 1344341400000,
			"n": 10
		  },
		  {
			"v": 19600,
			"vw": 22.2877,
			"o": 22.2861,
			"c": 22.2857,
			"h": 22.2907,
			"l": 22.2857,
			"t": 1344341460000,
			"n": 5
		  },
		  {
			"v": 19600,
			"vw": 22.2913,
			"o": 22.2875,
			"c": 22.2929,
			"h": 22.2929,
			"l": 22.2875,
			"t": 1344341520000,
			"n": 6
		  },
		  {
			"v": 95200,
			"vw": 22.2938,
			"o": 22.2911,
			"c": 22.3018,
			"h": 22.3018,
			"l": 22.2911,
			"t": 1344341880000,
			"n": 13
		  },
		  {
			"v": 70140,
			"vw": 22.3048,
			"o": 22.3021,
			"c": 22.3036,
			"h": 22.3054,
			"l": 22.3021,
			"t": 1344341940000,
			"n": 7
		  },
		  {
			"v": 134932,
			"vw": 22.3095,
			"o": 22.3036,
			"c": 22.3118,
			"h": 22.3125,
			"l": 22.3036,
			"t": 1344342000000,
			"n": 17
		  },
		  {
			"v": 36400,
			"vw": 22.3044,
			"o": 22.3036,
			"c": 22.3021,
			"h": 22.3096,
			"l": 22.3021,
			"t": 1344342060000,
			"n": 4
		  },
		  {
			"v": 75628,
			"vw": 22.303,
			"o": 22.2989,
			"c": 22.3029,
			"h": 22.3039,
			"l": 22.2979,
			"t": 1344342120000,
			"n": 13
		  },
		  {
			"v": 52472,
			"vw": 22.3046,
			"o": 22.3032,
			"c": 22.3054,
			"h": 22.3054,
			"l": 22.3032,
			"t": 1344342180000,
			"n": 13
		  },
		  {
			"v": 8400,
			"vw": 22.3038,
			"o": 22.305,
			"c": 22.3046,
			"h": 22.305,
			"l": 22.3018,
			"t": 1344342240000,
			"n": 3
		  },
		  {
			"v": 19600,
			"vw": 22.3028,
			"o": 22.3046,
			"c": 22.3018,
			"h": 22.3046,
			"l": 22.3018,
			"t": 1344342300000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.3036,
			"o": 22.3036,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3036,
			"t": 1344342360000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.3061,
			"o": 22.3061,
			"c": 22.3061,
			"h": 22.3061,
			"l": 22.3061,
			"t": 1344342420000,
			"n": 1
		  },
		  {
			"v": 69076,
			"vw": 22.3081,
			"o": 22.3036,
			"c": 22.3104,
			"h": 22.3104,
			"l": 22.3036,
			"t": 1344342480000,
			"n": 13
		  },
		  {
			"v": 36120,
			"vw": 22.3062,
			"o": 22.3075,
			"c": 22.3064,
			"h": 22.3082,
			"l": 22.3036,
			"t": 1344342540000,
			"n": 9
		  },
		  {
			"v": 15120,
			"vw": 22.3033,
			"o": 22.3021,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3021,
			"t": 1344342600000,
			"n": 3
		  },
		  {
			"v": 110488,
			"vw": 22.3038,
			"o": 22.3036,
			"c": 22.3107,
			"h": 22.3107,
			"l": 22.3004,
			"t": 1344342660000,
			"n": 15
		  },
		  {
			"v": 27160,
			"vw": 22.3113,
			"o": 22.3107,
			"c": 22.3125,
			"h": 22.3125,
			"l": 22.3089,
			"t": 1344342720000,
			"n": 9
		  },
		  {
			"v": 126000,
			"vw": 22.3135,
			"o": 22.3143,
			"c": 22.3143,
			"h": 22.3164,
			"l": 22.3125,
			"t": 1344342780000,
			"n": 22
		  },
		  {
			"v": 37240,
			"vw": 22.3173,
			"o": 22.3193,
			"c": 22.3164,
			"h": 22.3193,
			"l": 22.3157,
			"t": 1344342840000,
			"n": 11
		  },
		  {
			"v": 22400,
			"vw": 22.3174,
			"o": 22.3179,
			"c": 22.3179,
			"h": 22.3182,
			"l": 22.3161,
			"t": 1344342900000,
			"n": 7
		  },
		  {
			"v": 36400,
			"vw": 22.3164,
			"o": 22.3179,
			"c": 22.3175,
			"h": 22.3179,
			"l": 22.3143,
			"t": 1344342960000,
			"n": 11
		  },
		  {
			"v": 22400,
			"vw": 22.3143,
			"o": 22.3143,
			"c": 22.3143,
			"h": 22.3146,
			"l": 22.3143,
			"t": 1344343020000,
			"n": 5
		  },
		  {
			"v": 42000,
			"vw": 22.3169,
			"o": 22.3157,
			"c": 22.3179,
			"h": 22.3182,
			"l": 22.3157,
			"t": 1344343080000,
			"n": 14
		  },
		  {
			"v": 173656,
			"vw": 22.3185,
			"o": 22.3179,
			"c": 22.3196,
			"h": 22.3214,
			"l": 22.3175,
			"t": 1344343140000,
			"n": 32
		  },
		  {
			"v": 8400,
			"vw": 22.3205,
			"o": 22.3204,
			"c": 22.3207,
			"h": 22.3207,
			"l": 22.3204,
			"t": 1344343200000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.32,
			"o": 22.32,
			"c": 22.32,
			"h": 22.32,
			"l": 22.32,
			"t": 1344343260000,
			"n": 1
		  },
		  {
			"v": 65800,
			"vw": 22.316,
			"o": 22.3196,
			"c": 22.3179,
			"h": 22.3196,
			"l": 22.3146,
			"t": 1344343320000,
			"n": 18
		  },
		  {
			"v": 16800,
			"vw": 22.3185,
			"o": 22.3161,
			"c": 22.3193,
			"h": 22.3193,
			"l": 22.3161,
			"t": 1344343380000,
			"n": 4
		  },
		  {
			"v": 5320,
			"vw": 22.3186,
			"o": 22.3186,
			"c": 22.3186,
			"h": 22.3186,
			"l": 22.3186,
			"t": 1344343440000,
			"n": 1
		  },
		  {
			"v": 41720,
			"vw": 22.3169,
			"o": 22.3164,
			"c": 22.3161,
			"h": 22.3189,
			"l": 22.3161,
			"t": 1344343500000,
			"n": 7
		  },
		  {
			"v": 36792,
			"vw": 22.3162,
			"o": 22.3161,
			"c": 22.3164,
			"h": 22.3164,
			"l": 22.3161,
			"t": 1344343560000,
			"n": 10
		  },
		  {
			"v": 57540,
			"vw": 22.3161,
			"o": 22.3161,
			"c": 22.3125,
			"h": 22.3186,
			"l": 22.3125,
			"t": 1344343620000,
			"n": 14
		  },
		  {
			"v": 18900,
			"vw": 22.3116,
			"o": 22.3129,
			"c": 22.3121,
			"h": 22.3129,
			"l": 22.3089,
			"t": 1344343680000,
			"n": 6
		  },
		  {
			"v": 28980,
			"vw": 22.311,
			"o": 22.3121,
			"c": 22.3111,
			"h": 22.3121,
			"l": 22.3096,
			"t": 1344343740000,
			"n": 9
		  },
		  {
			"v": 30800,
			"vw": 22.3102,
			"o": 22.3104,
			"c": 22.3075,
			"h": 22.3118,
			"l": 22.3075,
			"t": 1344343800000,
			"n": 6
		  },
		  {
			"v": 47796,
			"vw": 22.3035,
			"o": 22.3075,
			"c": 22.2986,
			"h": 22.3075,
			"l": 22.2986,
			"t": 1344343860000,
			"n": 14
		  },
		  {
			"v": 5600,
			"vw": 22.3048,
			"o": 22.3043,
			"c": 22.3054,
			"h": 22.3054,
			"l": 22.3043,
			"t": 1344343980000,
			"n": 2
		  },
		  {
			"v": 19572,
			"vw": 22.3083,
			"o": 22.3071,
			"c": 22.3086,
			"h": 22.3121,
			"l": 22.3071,
			"t": 1344344040000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.3082,
			"o": 22.3104,
			"c": 22.3061,
			"h": 22.3104,
			"l": 22.3061,
			"t": 1344344100000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.3064,
			"o": 22.3064,
			"c": 22.3064,
			"h": 22.3064,
			"l": 22.3064,
			"t": 1344344160000,
			"n": 1
		  },
		  {
			"v": 30520,
			"vw": 22.3059,
			"o": 22.3071,
			"c": 22.3071,
			"h": 22.3089,
			"l": 22.3043,
			"t": 1344344220000,
			"n": 7
		  },
		  {
			"v": 39816,
			"vw": 22.3007,
			"o": 22.3036,
			"c": 22.2982,
			"h": 22.305,
			"l": 22.2982,
			"t": 1344344280000,
			"n": 12
		  },
		  {
			"v": 58800,
			"vw": 22.2973,
			"o": 22.3043,
			"c": 22.2996,
			"h": 22.3043,
			"l": 22.2957,
			"t": 1344344340000,
			"n": 18
		  },
		  {
			"v": 64652,
			"vw": 22.2939,
			"o": 22.2961,
			"c": 22.2954,
			"h": 22.2961,
			"l": 22.2925,
			"t": 1344344400000,
			"n": 14
		  },
		  {
			"v": 11200,
			"vw": 22.2987,
			"o": 22.2982,
			"c": 22.2979,
			"h": 22.2993,
			"l": 22.2979,
			"t": 1344344460000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.3008,
			"o": 22.3021,
			"c": 22.3,
			"h": 22.3021,
			"l": 22.3,
			"t": 1344344520000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.3006,
			"o": 22.3021,
			"c": 22.3004,
			"h": 22.3021,
			"l": 22.3,
			"t": 1344344640000,
			"n": 4
		  },
		  {
			"v": 40432,
			"vw": 22.3,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3,
			"l": 22.3,
			"t": 1344344700000,
			"n": 6
		  },
		  {
			"v": 25032,
			"vw": 22.2995,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3,
			"l": 22.2982,
			"t": 1344344760000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.2961,
			"o": 22.2961,
			"c": 22.2961,
			"h": 22.2961,
			"l": 22.2961,
			"t": 1344344820000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.2981,
			"o": 22.2968,
			"c": 22.2993,
			"h": 22.2993,
			"l": 22.2968,
			"t": 1344344940000,
			"n": 3
		  },
		  {
			"v": 21644,
			"vw": 22.2995,
			"o": 22.2993,
			"c": 22.3,
			"h": 22.3,
			"l": 22.2993,
			"t": 1344345000000,
			"n": 5
		  },
		  {
			"v": 28000,
			"vw": 22.3063,
			"o": 22.3029,
			"c": 22.3079,
			"h": 22.3079,
			"l": 22.3029,
			"t": 1344345060000,
			"n": 8
		  },
		  {
			"v": 39200,
			"vw": 22.3039,
			"o": 22.3064,
			"c": 22.3007,
			"h": 22.3064,
			"l": 22.3007,
			"t": 1344345120000,
			"n": 9
		  },
		  {
			"v": 22680,
			"vw": 22.3031,
			"o": 22.3032,
			"c": 22.3018,
			"h": 22.3036,
			"l": 22.3018,
			"t": 1344345180000,
			"n": 6
		  },
		  {
			"v": 5600,
			"vw": 22.3027,
			"o": 22.3018,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3018,
			"t": 1344345240000,
			"n": 2
		  },
		  {
			"v": 29400,
			"vw": 22.3032,
			"o": 22.3036,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3029,
			"t": 1344345360000,
			"n": 8
		  },
		  {
			"v": 5600,
			"vw": 22.3055,
			"o": 22.3064,
			"c": 22.3046,
			"h": 22.3064,
			"l": 22.3046,
			"t": 1344345480000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.3024,
			"o": 22.3018,
			"c": 22.3032,
			"h": 22.3032,
			"l": 22.3011,
			"t": 1344345540000,
			"n": 5
		  },
		  {
			"v": 59080,
			"vw": 22.3008,
			"o": 22.3,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3,
			"t": 1344345660000,
			"n": 15
		  },
		  {
			"v": 105672,
			"vw": 22.3036,
			"o": 22.3046,
			"c": 22.3,
			"h": 22.3075,
			"l": 22.2964,
			"t": 1344345720000,
			"n": 25
		  },
		  {
			"v": 8400,
			"vw": 22.3024,
			"o": 22.3007,
			"c": 22.3032,
			"h": 22.3032,
			"l": 22.3007,
			"t": 1344345780000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.3032,
			"o": 22.3032,
			"c": 22.3032,
			"h": 22.3032,
			"l": 22.3032,
			"t": 1344345840000,
			"n": 2
		  },
		  {
			"v": 39284,
			"vw": 22.2973,
			"o": 22.3036,
			"c": 22.2961,
			"h": 22.3036,
			"l": 22.2961,
			"t": 1344345900000,
			"n": 9
		  },
		  {
			"v": 63980,
			"vw": 22.2932,
			"o": 22.2957,
			"c": 22.2911,
			"h": 22.2957,
			"l": 22.2896,
			"t": 1344345960000,
			"n": 17
		  },
		  {
			"v": 171220,
			"vw": 22.2862,
			"o": 22.2904,
			"c": 22.2861,
			"h": 22.2904,
			"l": 22.2857,
			"t": 1344346020000,
			"n": 29
		  },
		  {
			"v": 453180,
			"vw": 22.2707,
			"o": 22.2821,
			"c": 22.2457,
			"h": 22.2857,
			"l": 22.2432,
			"t": 1344346080000,
			"n": 47
		  },
		  {
			"v": 458472,
			"vw": 22.2363,
			"o": 22.2454,
			"c": 22.2429,
			"h": 22.25,
			"l": 22.2257,
			"t": 1344346140000,
			"n": 71
		  },
		  {
			"v": 5890948,
			"vw": 22.2437,
			"o": 22.2418,
			"c": 22.2614,
			"h": 22.28,
			"l": 22.2286,
			"t": 1344346200000,
			"n": 658
		  },
		  {
			"v": 2956268,
			"vw": 22.2793,
			"o": 22.26,
			"c": 22.2932,
			"h": 22.3025,
			"l": 22.255,
			"t": 1344346260000,
			"n": 718
		  },
		  {
			"v": 2016112,
			"vw": 22.3001,
			"o": 22.2986,
			"c": 22.3061,
			"h": 22.3104,
			"l": 22.2879,
			"t": 1344346320000,
			"n": 518
		  },
		  {
			"v": 2589916,
			"vw": 22.3076,
			"o": 22.3082,
			"c": 22.2989,
			"h": 22.3161,
			"l": 22.2971,
			"t": 1344346380000,
			"n": 570
		  },
		  {
			"v": 990332,
			"vw": 22.3013,
			"o": 22.2986,
			"c": 22.3021,
			"h": 22.3071,
			"l": 22.2964,
			"t": 1344346440000,
			"n": 247
		  },
		  {
			"v": 3691940,
			"vw": 22.3143,
			"o": 22.3039,
			"c": 22.3086,
			"h": 22.3214,
			"l": 22.3011,
			"t": 1344346500000,
			"n": 832
		  },
		  {
			"v": 2669856,
			"vw": 22.2911,
			"o": 22.3057,
			"c": 22.284,
			"h": 22.3074,
			"l": 22.2796,
			"t": 1344346560000,
			"n": 599
		  },
		  {
			"v": 1670200,
			"vw": 22.2775,
			"o": 22.2839,
			"c": 22.2782,
			"h": 22.2879,
			"l": 22.2689,
			"t": 1344346620000,
			"n": 425
		  },
		  {
			"v": 1263052,
			"vw": 22.2836,
			"o": 22.2785,
			"c": 22.2846,
			"h": 22.2921,
			"l": 22.2725,
			"t": 1344346680000,
			"n": 308
		  },
		  {
			"v": 1551620,
			"vw": 22.2966,
			"o": 22.285,
			"c": 22.295,
			"h": 22.3071,
			"l": 22.285,
			"t": 1344346740000,
			"n": 321
		  },
		  {
			"v": 851480,
			"vw": 22.2938,
			"o": 22.2957,
			"c": 22.295,
			"h": 22.3021,
			"l": 22.2857,
			"t": 1344346800000,
			"n": 178
		  },
		  {
			"v": 820400,
			"vw": 22.288,
			"o": 22.2961,
			"c": 22.28,
			"h": 22.2989,
			"l": 22.2768,
			"t": 1344346860000,
			"n": 184
		  },
		  {
			"v": 1086036,
			"vw": 22.2851,
			"o": 22.2836,
			"c": 22.2718,
			"h": 22.2946,
			"l": 22.2718,
			"t": 1344346920000,
			"n": 255
		  },
		  {
			"v": 1688960,
			"vw": 22.2649,
			"o": 22.2719,
			"c": 22.2679,
			"h": 22.275,
			"l": 22.2593,
			"t": 1344346980000,
			"n": 394
		  },
		  {
			"v": 1750672,
			"vw": 22.2582,
			"o": 22.2643,
			"c": 22.2471,
			"h": 22.2775,
			"l": 22.2379,
			"t": 1344347040000,
			"n": 378
		  },
		  {
			"v": 1938300,
			"vw": 22.2521,
			"o": 22.2411,
			"c": 22.2475,
			"h": 22.2661,
			"l": 22.2389,
			"t": 1344347100000,
			"n": 490
		  },
		  {
			"v": 1065512,
			"vw": 22.2494,
			"o": 22.2493,
			"c": 22.2661,
			"h": 22.2661,
			"l": 22.2411,
			"t": 1344347160000,
			"n": 257
		  },
		  {
			"v": 4349968,
			"vw": 22.2152,
			"o": 22.2664,
			"c": 22.1889,
			"h": 22.2671,
			"l": 22.1704,
			"t": 1344347220000,
			"n": 934
		  },
		  {
			"v": 2785272,
			"vw": 22.1861,
			"o": 22.1929,
			"c": 22.1854,
			"h": 22.2071,
			"l": 22.1643,
			"t": 1344347280000,
			"n": 651
		  },
		  {
			"v": 1862308,
			"vw": 22.1948,
			"o": 22.1854,
			"c": 22.1901,
			"h": 22.2157,
			"l": 22.179,
			"t": 1344347340000,
			"n": 393
		  },
		  {
			"v": 2012416,
			"vw": 22.1778,
			"o": 22.1911,
			"c": 22.1525,
			"h": 22.1968,
			"l": 22.1525,
			"t": 1344347400000,
			"n": 465
		  },
		  {
			"v": 4954544,
			"vw": 22.1373,
			"o": 22.1543,
			"c": 22.1514,
			"h": 22.1604,
			"l": 22.1071,
			"t": 1344347460000,
			"n": 1097
		  },
		  {
			"v": 1969828,
			"vw": 22.1505,
			"o": 22.1511,
			"c": 22.1361,
			"h": 22.1586,
			"l": 22.1354,
			"t": 1344347520000,
			"n": 437
		  },
		  {
			"v": 3158316,
			"vw": 22.1107,
			"o": 22.1354,
			"c": 22.0933,
			"h": 22.1371,
			"l": 22.084,
			"t": 1344347580000,
			"n": 729
		  },
		  {
			"v": 3623564,
			"vw": 22.0954,
			"o": 22.0975,
			"c": 22.1125,
			"h": 22.1188,
			"l": 22.0729,
			"t": 1344347640000,
			"n": 821
		  },
		  {
			"v": 2636480,
			"vw": 22.1338,
			"o": 22.1125,
			"c": 22.1408,
			"h": 22.1529,
			"l": 22.1104,
			"t": 1344347700000,
			"n": 592
		  },
		  {
			"v": 1624896,
			"vw": 22.1555,
			"o": 22.1418,
			"c": 22.1625,
			"h": 22.1625,
			"l": 22.1414,
			"t": 1344347760000,
			"n": 394
		  },
		  {
			"v": 1258936,
			"vw": 22.1672,
			"o": 22.1607,
			"c": 22.17,
			"h": 22.1739,
			"l": 22.1607,
			"t": 1344347820000,
			"n": 330
		  },
		  {
			"v": 1868384,
			"vw": 22.181,
			"o": 22.1725,
			"c": 22.1829,
			"h": 22.1918,
			"l": 22.1714,
			"t": 1344347880000,
			"n": 421
		  },
		  {
			"v": 983276,
			"vw": 22.18,
			"o": 22.1839,
			"c": 22.1913,
			"h": 22.1913,
			"l": 22.17,
			"t": 1344347940000,
			"n": 252
		  },
		  {
			"v": 2295216,
			"vw": 22.2074,
			"o": 22.1914,
			"c": 22.2161,
			"h": 22.2211,
			"l": 22.1886,
			"t": 1344348000000,
			"n": 536
		  },
		  {
			"v": 1152592,
			"vw": 22.2072,
			"o": 22.2114,
			"c": 22.2036,
			"h": 22.2161,
			"l": 22.1964,
			"t": 1344348060000,
			"n": 296
		  },
		  {
			"v": 2982896,
			"vw": 22.2268,
			"o": 22.2007,
			"c": 22.2264,
			"h": 22.25,
			"l": 22.1968,
			"t": 1344348120000,
			"n": 635
		  },
		  {
			"v": 1679244,
			"vw": 22.2303,
			"o": 22.2302,
			"c": 22.2268,
			"h": 22.2421,
			"l": 22.2214,
			"t": 1344348180000,
			"n": 354
		  },
		  {
			"v": 1006432,
			"vw": 22.2362,
			"o": 22.2253,
			"c": 22.246,
			"h": 22.2464,
			"l": 22.2214,
			"t": 1344348240000,
			"n": 227
		  },
		  {
			"v": 1924888,
			"vw": 22.2454,
			"o": 22.2421,
			"c": 22.2582,
			"h": 22.2639,
			"l": 22.2304,
			"t": 1344348300000,
			"n": 396
		  },
		  {
			"v": 2377816,
			"vw": 22.2624,
			"o": 22.2543,
			"c": 22.2671,
			"h": 22.2732,
			"l": 22.2543,
			"t": 1344348360000,
			"n": 526
		  },
		  {
			"v": 1936032,
			"vw": 22.2632,
			"o": 22.2668,
			"c": 22.2529,
			"h": 22.2691,
			"l": 22.2521,
			"t": 1344348420000,
			"n": 367
		  },
		  {
			"v": 1288644,
			"vw": 22.2545,
			"o": 22.2554,
			"c": 22.2557,
			"h": 22.2661,
			"l": 22.2461,
			"t": 1344348480000,
			"n": 253
		  },
		  {
			"v": 1776936,
			"vw": 22.2652,
			"o": 22.2561,
			"c": 22.2679,
			"h": 22.2768,
			"l": 22.2504,
			"t": 1344348540000,
			"n": 344
		  },
		  {
			"v": 1792168,
			"vw": 22.2566,
			"o": 22.268,
			"c": 22.2364,
			"h": 22.2768,
			"l": 22.2307,
			"t": 1344348600000,
			"n": 402
		  },
		  {
			"v": 915880,
			"vw": 22.2413,
			"o": 22.2411,
			"c": 22.2357,
			"h": 22.2539,
			"l": 22.2321,
			"t": 1344348660000,
			"n": 198
		  },
		  {
			"v": 1095668,
			"vw": 22.2314,
			"o": 22.2382,
			"c": 22.2325,
			"h": 22.2421,
			"l": 22.2218,
			"t": 1344348720000,
			"n": 289
		  },
		  {
			"v": 656124,
			"vw": 22.2234,
			"o": 22.2318,
			"c": 22.2214,
			"h": 22.2325,
			"l": 22.215,
			"t": 1344348780000,
			"n": 153
		  },
		  {
			"v": 1010380,
			"vw": 22.2188,
			"o": 22.2186,
			"c": 22.2146,
			"h": 22.2296,
			"l": 22.2129,
			"t": 1344348840000,
			"n": 226
		  },
		  {
			"v": 1747732,
			"vw": 22.2106,
			"o": 22.2143,
			"c": 22.2043,
			"h": 22.2211,
			"l": 22.2007,
			"t": 1344348900000,
			"n": 396
		  },
		  {
			"v": 1609272,
			"vw": 22.1966,
			"o": 22.2071,
			"c": 22.1979,
			"h": 22.2089,
			"l": 22.1882,
			"t": 1344348960000,
			"n": 405
		  },
		  {
			"v": 1075116,
			"vw": 22.1956,
			"o": 22.2018,
			"c": 22.2054,
			"h": 22.2068,
			"l": 22.1811,
			"t": 1344349020000,
			"n": 258
		  },
		  {
			"v": 1178688,
			"vw": 22.2162,
			"o": 22.2018,
			"c": 22.2193,
			"h": 22.2429,
			"l": 22.1984,
			"t": 1344349080000,
			"n": 282
		  },
		  {
			"v": 502712,
			"vw": 22.214,
			"o": 22.2157,
			"c": 22.2071,
			"h": 22.2189,
			"l": 22.2071,
			"t": 1344349140000,
			"n": 124
		  },
		  {
			"v": 371084,
			"vw": 22.2155,
			"o": 22.2071,
			"c": 22.213,
			"h": 22.2196,
			"l": 22.2071,
			"t": 1344349200000,
			"n": 93
		  },
		  {
			"v": 1079708,
			"vw": 22.2304,
			"o": 22.2143,
			"c": 22.2411,
			"h": 22.2411,
			"l": 22.212,
			"t": 1344349260000,
			"n": 248
		  },
		  {
			"v": 1218280,
			"vw": 22.2446,
			"o": 22.2418,
			"c": 22.2418,
			"h": 22.2549,
			"l": 22.2364,
			"t": 1344349320000,
			"n": 266
		  },
		  {
			"v": 666288,
			"vw": 22.2258,
			"o": 22.2414,
			"c": 22.2207,
			"h": 22.2429,
			"l": 22.2161,
			"t": 1344349380000,
			"n": 163
		  },
		  {
			"v": 145152,
			"vw": 22.2262,
			"o": 22.2182,
			"c": 22.2306,
			"h": 22.2321,
			"l": 22.2182,
			"t": 1344349440000,
			"n": 35
		  },
		  {
			"v": 348320,
			"vw": 22.2312,
			"o": 22.2257,
			"c": 22.2364,
			"h": 22.2414,
			"l": 22.2254,
			"t": 1344349500000,
			"n": 60
		  },
		  {
			"v": 818188,
			"vw": 22.2479,
			"o": 22.2338,
			"c": 22.2554,
			"h": 22.2571,
			"l": 22.2325,
			"t": 1344349560000,
			"n": 172
		  },
		  {
			"v": 492436,
			"vw": 22.2452,
			"o": 22.2536,
			"c": 22.2454,
			"h": 22.2564,
			"l": 22.2368,
			"t": 1344349620000,
			"n": 99
		  },
		  {
			"v": 684544,
			"vw": 22.2327,
			"o": 22.246,
			"c": 22.2289,
			"h": 22.246,
			"l": 22.2221,
			"t": 1344349680000,
			"n": 162
		  },
		  {
			"v": 833140,
			"vw": 22.2203,
			"o": 22.2318,
			"c": 22.22,
			"h": 22.2326,
			"l": 22.2107,
			"t": 1344349740000,
			"n": 208
		  },
		  {
			"v": 1044036,
			"vw": 22.2095,
			"o": 22.2193,
			"c": 22.2143,
			"h": 22.2236,
			"l": 22.1968,
			"t": 1344349800000,
			"n": 264
		  },
		  {
			"v": 416640,
			"vw": 22.2081,
			"o": 22.2118,
			"c": 22.205,
			"h": 22.2134,
			"l": 22.2018,
			"t": 1344349860000,
			"n": 110
		  },
		  {
			"v": 273476,
			"vw": 22.2078,
			"o": 22.2054,
			"c": 22.2079,
			"h": 22.2118,
			"l": 22.2029,
			"t": 1344349920000,
			"n": 66
		  },
		  {
			"v": 825888,
			"vw": 22.1969,
			"o": 22.2079,
			"c": 22.1921,
			"h": 22.2079,
			"l": 22.1875,
			"t": 1344349980000,
			"n": 182
		  },
		  {
			"v": 1011864,
			"vw": 22.1811,
			"o": 22.1921,
			"c": 22.1789,
			"h": 22.1929,
			"l": 22.1704,
			"t": 1344350040000,
			"n": 242
		  },
		  {
			"v": 483420,
			"vw": 22.1827,
			"o": 22.1807,
			"c": 22.1821,
			"h": 22.1893,
			"l": 22.1786,
			"t": 1344350100000,
			"n": 117
		  },
		  {
			"v": 1205736,
			"vw": 22.1852,
			"o": 22.1821,
			"c": 22.1796,
			"h": 22.1925,
			"l": 22.1786,
			"t": 1344350160000,
			"n": 231
		  },
		  {
			"v": 1045856,
			"vw": 22.1724,
			"o": 22.1786,
			"c": 22.1678,
			"h": 22.1864,
			"l": 22.1607,
			"t": 1344350220000,
			"n": 218
		  },
		  {
			"v": 599564,
			"vw": 22.1712,
			"o": 22.1686,
			"c": 22.1736,
			"h": 22.1817,
			"l": 22.1643,
			"t": 1344350280000,
			"n": 143
		  },
		  {
			"v": 534856,
			"vw": 22.1751,
			"o": 22.1676,
			"c": 22.1729,
			"h": 22.1814,
			"l": 22.1675,
			"t": 1344350340000,
			"n": 146
		  },
		  {
			"v": 370804,
			"vw": 22.1753,
			"o": 22.1729,
			"c": 22.1779,
			"h": 22.1814,
			"l": 22.1696,
			"t": 1344350400000,
			"n": 78
		  },
		  {
			"v": 2800056,
			"vw": 22.1799,
			"o": 22.1814,
			"c": 22.1964,
			"h": 22.1964,
			"l": 22.1807,
			"t": 1344350460000,
			"n": 154
		  },
		  {
			"v": 378420,
			"vw": 22.195,
			"o": 22.1946,
			"c": 22.195,
			"h": 22.2004,
			"l": 22.1911,
			"t": 1344350520000,
			"n": 95
		  },
		  {
			"v": 380408,
			"vw": 22.1988,
			"o": 22.1986,
			"c": 22.1964,
			"h": 22.2018,
			"l": 22.1957,
			"t": 1344350580000,
			"n": 95
		  },
		  {
			"v": 426636,
			"vw": 22.1951,
			"o": 22.1929,
			"c": 22.1932,
			"h": 22.2,
			"l": 22.1904,
			"t": 1344350640000,
			"n": 87
		  },
		  {
			"v": 443156,
			"vw": 22.1936,
			"o": 22.1964,
			"c": 22.1914,
			"h": 22.2018,
			"l": 22.1871,
			"t": 1344350700000,
			"n": 120
		  },
		  {
			"v": 570388,
			"vw": 22.1838,
			"o": 22.1946,
			"c": 22.1757,
			"h": 22.195,
			"l": 22.1721,
			"t": 1344350760000,
			"n": 141
		  },
		  {
			"v": 588756,
			"vw": 22.1655,
			"o": 22.1761,
			"c": 22.1643,
			"h": 22.1782,
			"l": 22.1561,
			"t": 1344350820000,
			"n": 139
		  },
		  {
			"v": 361424,
			"vw": 22.1665,
			"o": 22.1614,
			"c": 22.1636,
			"h": 22.1714,
			"l": 22.1614,
			"t": 1344350880000,
			"n": 92
		  },
		  {
			"v": 568988,
			"vw": 22.1621,
			"o": 22.1693,
			"c": 22.1571,
			"h": 22.1693,
			"l": 22.1546,
			"t": 1344350940000,
			"n": 129
		  },
		  {
			"v": 1492260,
			"vw": 22.1519,
			"o": 22.155,
			"c": 22.1388,
			"h": 22.1639,
			"l": 22.1364,
			"t": 1344351000000,
			"n": 297
		  },
		  {
			"v": 832832,
			"vw": 22.1451,
			"o": 22.1489,
			"c": 22.1461,
			"h": 22.1506,
			"l": 22.1379,
			"t": 1344351060000,
			"n": 224
		  },
		  {
			"v": 822416,
			"vw": 22.1389,
			"o": 22.1464,
			"c": 22.1411,
			"h": 22.1479,
			"l": 22.1304,
			"t": 1344351120000,
			"n": 220
		  },
		  {
			"v": 416360,
			"vw": 22.1495,
			"o": 22.1432,
			"c": 22.1514,
			"h": 22.1579,
			"l": 22.1432,
			"t": 1344351180000,
			"n": 116
		  },
		  {
			"v": 456064,
			"vw": 22.1474,
			"o": 22.1479,
			"c": 22.145,
			"h": 22.1514,
			"l": 22.1429,
			"t": 1344351240000,
			"n": 124
		  },
		  {
			"v": 574980,
			"vw": 22.1416,
			"o": 22.1429,
			"c": 22.1482,
			"h": 22.1482,
			"l": 22.1375,
			"t": 1344351300000,
			"n": 129
		  },
		  {
			"v": 533232,
			"vw": 22.1513,
			"o": 22.1489,
			"c": 22.1493,
			"h": 22.1552,
			"l": 22.1457,
			"t": 1344351360000,
			"n": 105
		  },
		  {
			"v": 651000,
			"vw": 22.1626,
			"o": 22.1471,
			"c": 22.175,
			"h": 22.175,
			"l": 22.1471,
			"t": 1344351420000,
			"n": 161
		  },
		  {
			"v": 601076,
			"vw": 22.1765,
			"o": 22.175,
			"c": 22.1739,
			"h": 22.1786,
			"l": 22.1714,
			"t": 1344351480000,
			"n": 136
		  },
		  {
			"v": 333984,
			"vw": 22.1665,
			"o": 22.1721,
			"c": 22.1657,
			"h": 22.1736,
			"l": 22.1614,
			"t": 1344351540000,
			"n": 91
		  },
		  {
			"v": 399420,
			"vw": 22.1735,
			"o": 22.1661,
			"c": 22.1738,
			"h": 22.1786,
			"l": 22.1615,
			"t": 1344351600000,
			"n": 100
		  },
		  {
			"v": 263396,
			"vw": 22.1684,
			"o": 22.1734,
			"c": 22.1614,
			"h": 22.1734,
			"l": 22.1614,
			"t": 1344351660000,
			"n": 72
		  },
		  {
			"v": 467432,
			"vw": 22.1596,
			"o": 22.1669,
			"c": 22.1568,
			"h": 22.1669,
			"l": 22.1543,
			"t": 1344351720000,
			"n": 90
		  },
		  {
			"v": 413196,
			"vw": 22.1534,
			"o": 22.1571,
			"c": 22.1539,
			"h": 22.1618,
			"l": 22.1475,
			"t": 1344351780000,
			"n": 84
		  },
		  {
			"v": 300244,
			"vw": 22.1507,
			"o": 22.155,
			"c": 22.1514,
			"h": 22.1564,
			"l": 22.1479,
			"t": 1344351840000,
			"n": 74
		  },
		  {
			"v": 523908,
			"vw": 22.1449,
			"o": 22.1504,
			"c": 22.14,
			"h": 22.1532,
			"l": 22.1375,
			"t": 1344351900000,
			"n": 139
		  },
		  {
			"v": 436100,
			"vw": 22.1425,
			"o": 22.1404,
			"c": 22.1446,
			"h": 22.15,
			"l": 22.1357,
			"t": 1344351960000,
			"n": 78
		  },
		  {
			"v": 382844,
			"vw": 22.1386,
			"o": 22.1439,
			"c": 22.1321,
			"h": 22.1468,
			"l": 22.1319,
			"t": 1344352020000,
			"n": 79
		  },
		  {
			"v": 798056,
			"vw": 22.134,
			"o": 22.1321,
			"c": 22.1425,
			"h": 22.1479,
			"l": 22.125,
			"t": 1344352080000,
			"n": 194
		  },
		  {
			"v": 340872,
			"vw": 22.1442,
			"o": 22.1443,
			"c": 22.14,
			"h": 22.1496,
			"l": 22.1371,
			"t": 1344352140000,
			"n": 83
		  },
		  {
			"v": 279328,
			"vw": 22.1438,
			"o": 22.1375,
			"c": 22.1449,
			"h": 22.1514,
			"l": 22.1358,
			"t": 1344352200000,
			"n": 71
		  },
		  {
			"v": 217252,
			"vw": 22.1431,
			"o": 22.1454,
			"c": 22.1468,
			"h": 22.1496,
			"l": 22.1393,
			"t": 1344352260000,
			"n": 57
		  },
		  {
			"v": 247996,
			"vw": 22.1441,
			"o": 22.1457,
			"c": 22.1464,
			"h": 22.1496,
			"l": 22.1404,
			"t": 1344352320000,
			"n": 76
		  },
		  {
			"v": 209916,
			"vw": 22.1462,
			"o": 22.145,
			"c": 22.1482,
			"h": 22.1493,
			"l": 22.1429,
			"t": 1344352380000,
			"n": 63
		  },
		  {
			"v": 650552,
			"vw": 22.152,
			"o": 22.148,
			"c": 22.1486,
			"h": 22.1607,
			"l": 22.1454,
			"t": 1344352440000,
			"n": 159
		  },
		  {
			"v": 192612,
			"vw": 22.1527,
			"o": 22.1486,
			"c": 22.1557,
			"h": 22.1557,
			"l": 22.1475,
			"t": 1344352500000,
			"n": 44
		  },
		  {
			"v": 441392,
			"vw": 22.1646,
			"o": 22.1554,
			"c": 22.1589,
			"h": 22.1911,
			"l": 22.1525,
			"t": 1344352560000,
			"n": 78
		  },
		  {
			"v": 257600,
			"vw": 22.1577,
			"o": 22.16,
			"c": 22.1516,
			"h": 22.1632,
			"l": 22.1516,
			"t": 1344352620000,
			"n": 78
		  },
		  {
			"v": 424900,
			"vw": 22.158,
			"o": 22.1543,
			"c": 22.1561,
			"h": 22.1625,
			"l": 22.1521,
			"t": 1344352680000,
			"n": 96
		  },
		  {
			"v": 908684,
			"vw": 22.1731,
			"o": 22.1596,
			"c": 22.1704,
			"h": 22.18,
			"l": 22.1588,
			"t": 1344352740000,
			"n": 184
		  },
		  {
			"v": 531412,
			"vw": 22.176,
			"o": 22.1761,
			"c": 22.1707,
			"h": 22.18,
			"l": 22.1679,
			"t": 1344352800000,
			"n": 128
		  },
		  {
			"v": 250320,
			"vw": 22.1723,
			"o": 22.1707,
			"c": 22.1721,
			"h": 22.1749,
			"l": 22.1679,
			"t": 1344352860000,
			"n": 59
		  },
		  {
			"v": 353304,
			"vw": 22.1714,
			"o": 22.1739,
			"c": 22.1654,
			"h": 22.1774,
			"l": 22.1643,
			"t": 1344352920000,
			"n": 87
		  },
		  {
			"v": 592732,
			"vw": 22.174,
			"o": 22.1636,
			"c": 22.1756,
			"h": 22.1786,
			"l": 22.1629,
			"t": 1344352980000,
			"n": 142
		  },
		  {
			"v": 753872,
			"vw": 22.1801,
			"o": 22.1775,
			"c": 22.1784,
			"h": 22.1821,
			"l": 22.1768,
			"t": 1344353040000,
			"n": 152
		  },
		  {
			"v": 1027572,
			"vw": 22.1865,
			"o": 22.1804,
			"c": 22.186,
			"h": 22.1921,
			"l": 22.1786,
			"t": 1344353100000,
			"n": 247
		  },
		  {
			"v": 690816,
			"vw": 22.1901,
			"o": 22.1879,
			"c": 22.1886,
			"h": 22.1964,
			"l": 22.1854,
			"t": 1344353160000,
			"n": 156
		  },
		  {
			"v": 443996,
			"vw": 22.185,
			"o": 22.1907,
			"c": 22.1821,
			"h": 22.1914,
			"l": 22.1796,
			"t": 1344353220000,
			"n": 101
		  },
		  {
			"v": 437864,
			"vw": 22.1925,
			"o": 22.1832,
			"c": 22.1961,
			"h": 22.1975,
			"l": 22.1814,
			"t": 1344353280000,
			"n": 96
		  },
		  {
			"v": 590184,
			"vw": 22.1964,
			"o": 22.1954,
			"c": 22.1911,
			"h": 22.2018,
			"l": 22.1911,
			"t": 1344353340000,
			"n": 132
		  },
		  {
			"v": 1003912,
			"vw": 22.1988,
			"o": 22.1932,
			"c": 22.2068,
			"h": 22.2068,
			"l": 22.1921,
			"t": 1344353400000,
			"n": 219
		  },
		  {
			"v": 674800,
			"vw": 22.2097,
			"o": 22.2054,
			"c": 22.2136,
			"h": 22.2139,
			"l": 22.2036,
			"t": 1344353460000,
			"n": 159
		  },
		  {
			"v": 635684,
			"vw": 22.2047,
			"o": 22.2111,
			"c": 22.2029,
			"h": 22.2123,
			"l": 22.1979,
			"t": 1344353520000,
			"n": 122
		  },
		  {
			"v": 363412,
			"vw": 22.2088,
			"o": 22.2,
			"c": 22.205,
			"h": 22.2125,
			"l": 22.2,
			"t": 1344353580000,
			"n": 82
		  },
		  {
			"v": 658028,
			"vw": 22.198,
			"o": 22.2018,
			"c": 22.1992,
			"h": 22.2053,
			"l": 22.1929,
			"t": 1344353640000,
			"n": 127
		  },
		  {
			"v": 442176,
			"vw": 22.1948,
			"o": 22.1939,
			"c": 22.1953,
			"h": 22.1993,
			"l": 22.19,
			"t": 1344353700000,
			"n": 89
		  },
		  {
			"v": 566160,
			"vw": 22.1971,
			"o": 22.195,
			"c": 22.1957,
			"h": 22.1996,
			"l": 22.1936,
			"t": 1344353760000,
			"n": 110
		  },
		  {
			"v": 311024,
			"vw": 22.1938,
			"o": 22.1954,
			"c": 22.1957,
			"h": 22.1996,
			"l": 22.19,
			"t": 1344353820000,
			"n": 66
		  },
		  {
			"v": 254128,
			"vw": 22.1947,
			"o": 22.1979,
			"c": 22.1861,
			"h": 22.2011,
			"l": 22.1857,
			"t": 1344353880000,
			"n": 76
		  },
		  {
			"v": 261436,
			"vw": 22.1852,
			"o": 22.1857,
			"c": 22.1886,
			"h": 22.1921,
			"l": 22.1814,
			"t": 1344353940000,
			"n": 77
		  },
		  {
			"v": 289716,
			"vw": 22.1966,
			"o": 22.1889,
			"c": 22.2036,
			"h": 22.2036,
			"l": 22.1889,
			"t": 1344354000000,
			"n": 73
		  },
		  {
			"v": 528640,
			"vw": 22.2037,
			"o": 22.2039,
			"c": 22.1989,
			"h": 22.2093,
			"l": 22.1946,
			"t": 1344354060000,
			"n": 136
		  },
		  {
			"v": 1186612,
			"vw": 22.215,
			"o": 22.2004,
			"c": 22.2253,
			"h": 22.2279,
			"l": 22.2004,
			"t": 1344354120000,
			"n": 219
		  },
		  {
			"v": 918484,
			"vw": 22.2234,
			"o": 22.2239,
			"c": 22.2236,
			"h": 22.2321,
			"l": 22.2143,
			"t": 1344354180000,
			"n": 201
		  },
		  {
			"v": 693616,
			"vw": 22.2245,
			"o": 22.2268,
			"c": 22.2215,
			"h": 22.2317,
			"l": 22.2196,
			"t": 1344354240000,
			"n": 136
		  },
		  {
			"v": 305648,
			"vw": 22.2243,
			"o": 22.2225,
			"c": 22.23,
			"h": 22.23,
			"l": 22.2215,
			"t": 1344354300000,
			"n": 76
		  },
		  {
			"v": 565684,
			"vw": 22.2295,
			"o": 22.2268,
			"c": 22.2304,
			"h": 22.2329,
			"l": 22.2254,
			"t": 1344354360000,
			"n": 138
		  },
		  {
			"v": 867804,
			"vw": 22.2372,
			"o": 22.2265,
			"c": 22.2329,
			"h": 22.2429,
			"l": 22.2265,
			"t": 1344354420000,
			"n": 178
		  },
		  {
			"v": 504840,
			"vw": 22.2383,
			"o": 22.2332,
			"c": 22.2411,
			"h": 22.2424,
			"l": 22.2329,
			"t": 1344354480000,
			"n": 114
		  },
		  {
			"v": 918736,
			"vw": 22.2455,
			"o": 22.24,
			"c": 22.2474,
			"h": 22.25,
			"l": 22.2389,
			"t": 1344354540000,
			"n": 203
		  },
		  {
			"v": 714420,
			"vw": 22.2436,
			"o": 22.2479,
			"c": 22.2439,
			"h": 22.2479,
			"l": 22.2386,
			"t": 1344354600000,
			"n": 146
		  },
		  {
			"v": 1525972,
			"vw": 22.2535,
			"o": 22.2392,
			"c": 22.2561,
			"h": 22.2611,
			"l": 22.2391,
			"t": 1344354660000,
			"n": 282
		  },
		  {
			"v": 770000,
			"vw": 22.2517,
			"o": 22.26,
			"c": 22.2471,
			"h": 22.26,
			"l": 22.2468,
			"t": 1344354720000,
			"n": 151
		  },
		  {
			"v": 517860,
			"vw": 22.2469,
			"o": 22.2464,
			"c": 22.2465,
			"h": 22.25,
			"l": 22.2421,
			"t": 1344354780000,
			"n": 97
		  },
		  {
			"v": 776636,
			"vw": 22.2552,
			"o": 22.25,
			"c": 22.2496,
			"h": 22.2661,
			"l": 22.2454,
			"t": 1344354840000,
			"n": 160
		  },
		  {
			"v": 414792,
			"vw": 22.2532,
			"o": 22.2496,
			"c": 22.2564,
			"h": 22.2585,
			"l": 22.2454,
			"t": 1344354900000,
			"n": 102
		  },
		  {
			"v": 2560320,
			"vw": 22.2471,
			"o": 22.2561,
			"c": 22.2618,
			"h": 22.2679,
			"l": 22.255,
			"t": 1344354960000,
			"n": 234
		  },
		  {
			"v": 473060,
			"vw": 22.2616,
			"o": 22.2621,
			"c": 22.2614,
			"h": 22.2639,
			"l": 22.2571,
			"t": 1344355020000,
			"n": 104
		  },
		  {
			"v": 1042048,
			"vw": 22.2676,
			"o": 22.2614,
			"c": 22.2636,
			"h": 22.2746,
			"l": 22.2614,
			"t": 1344355080000,
			"n": 215
		  },
		  {
			"v": 508984,
			"vw": 22.2601,
			"o": 22.2671,
			"c": 22.2611,
			"h": 22.2675,
			"l": 22.2571,
			"t": 1344355140000,
			"n": 126
		  },
		  {
			"v": 400708,
			"vw": 22.2633,
			"o": 22.2607,
			"c": 22.2643,
			"h": 22.2661,
			"l": 22.2607,
			"t": 1344355200000,
			"n": 103
		  },
		  {
			"v": 581336,
			"vw": 22.2705,
			"o": 22.2639,
			"c": 22.2682,
			"h": 22.3214,
			"l": 22.2629,
			"t": 1344355260000,
			"n": 112
		  },
		  {
			"v": 686392,
			"vw": 22.2746,
			"o": 22.2714,
			"c": 22.2715,
			"h": 22.2768,
			"l": 22.2714,
			"t": 1344355320000,
			"n": 153
		  },
		  {
			"v": 952392,
			"vw": 22.2667,
			"o": 22.2714,
			"c": 22.2679,
			"h": 22.2736,
			"l": 22.2625,
			"t": 1344355380000,
			"n": 196
		  },
		  {
			"v": 299516,
			"vw": 22.2683,
			"o": 22.2639,
			"c": 22.2719,
			"h": 22.2729,
			"l": 22.2639,
			"t": 1344355440000,
			"n": 72
		  },
		  {
			"v": 298788,
			"vw": 22.2666,
			"o": 22.2729,
			"c": 22.2654,
			"h": 22.2729,
			"l": 22.2643,
			"t": 1344355500000,
			"n": 74
		  },
		  {
			"v": 556892,
			"vw": 22.266,
			"o": 22.265,
			"c": 22.2725,
			"h": 22.2725,
			"l": 22.2593,
			"t": 1344355560000,
			"n": 113
		  },
		  {
			"v": 363524,
			"vw": 22.2712,
			"o": 22.2714,
			"c": 22.2653,
			"h": 22.2732,
			"l": 22.265,
			"t": 1344355620000,
			"n": 82
		  },
		  {
			"v": 130592,
			"vw": 22.268,
			"o": 22.2646,
			"c": 22.2679,
			"h": 22.2711,
			"l": 22.2643,
			"t": 1344355680000,
			"n": 33
		  },
		  {
			"v": 355908,
			"vw": 22.2675,
			"o": 22.2696,
			"c": 22.2693,
			"h": 22.2714,
			"l": 22.2629,
			"t": 1344355740000,
			"n": 88
		  },
		  {
			"v": 712488,
			"vw": 22.2612,
			"o": 22.2679,
			"c": 22.2607,
			"h": 22.2702,
			"l": 22.255,
			"t": 1344355800000,
			"n": 75
		  },
		  {
			"v": 1000720,
			"vw": 22.2521,
			"o": 22.2607,
			"c": 22.2507,
			"h": 22.2607,
			"l": 22.2464,
			"t": 1344355860000,
			"n": 218
		  },
		  {
			"v": 256536,
			"vw": 22.251,
			"o": 22.2504,
			"c": 22.2536,
			"h": 22.2539,
			"l": 22.2475,
			"t": 1344355920000,
			"n": 56
		  },
		  {
			"v": 159936,
			"vw": 22.2542,
			"o": 22.2536,
			"c": 22.254,
			"h": 22.2571,
			"l": 22.2515,
			"t": 1344355980000,
			"n": 40
		  },
		  {
			"v": 285320,
			"vw": 22.2534,
			"o": 22.2571,
			"c": 22.255,
			"h": 22.2571,
			"l": 22.2504,
			"t": 1344356040000,
			"n": 74
		  },
		  {
			"v": 900956,
			"vw": 22.2673,
			"o": 22.255,
			"c": 22.2651,
			"h": 22.2761,
			"l": 22.255,
			"t": 1344356100000,
			"n": 216
		  },
		  {
			"v": 436912,
			"vw": 22.2711,
			"o": 22.2696,
			"c": 22.2714,
			"h": 22.275,
			"l": 22.2654,
			"t": 1344356160000,
			"n": 77
		  },
		  {
			"v": 1377152,
			"vw": 22.2785,
			"o": 22.2714,
			"c": 22.2836,
			"h": 22.2836,
			"l": 22.2696,
			"t": 1344356220000,
			"n": 300
		  },
		  {
			"v": 436464,
			"vw": 22.2776,
			"o": 22.2832,
			"c": 22.2781,
			"h": 22.2843,
			"l": 22.2725,
			"t": 1344356280000,
			"n": 103
		  },
		  {
			"v": 257040,
			"vw": 22.2763,
			"o": 22.278,
			"c": 22.2764,
			"h": 22.2804,
			"l": 22.2718,
			"t": 1344356340000,
			"n": 59
		  },
		  {
			"v": 554176,
			"vw": 22.2816,
			"o": 22.2725,
			"c": 22.28,
			"h": 22.285,
			"l": 22.2725,
			"t": 1344356400000,
			"n": 118
		  },
		  {
			"v": 332024,
			"vw": 22.281,
			"o": 22.2832,
			"c": 22.2829,
			"h": 22.2832,
			"l": 22.2779,
			"t": 1344356460000,
			"n": 80
		  },
		  {
			"v": 327768,
			"vw": 22.2812,
			"o": 22.2821,
			"c": 22.2821,
			"h": 22.2835,
			"l": 22.2768,
			"t": 1344356520000,
			"n": 86
		  },
		  {
			"v": 220360,
			"vw": 22.2751,
			"o": 22.2821,
			"c": 22.2786,
			"h": 22.2821,
			"l": 22.2697,
			"t": 1344356580000,
			"n": 56
		  },
		  {
			"v": 223972,
			"vw": 22.2733,
			"o": 22.2779,
			"c": 22.2764,
			"h": 22.2779,
			"l": 22.2704,
			"t": 1344356640000,
			"n": 40
		  },
		  {
			"v": 289828,
			"vw": 22.2803,
			"o": 22.2725,
			"c": 22.2821,
			"h": 22.2836,
			"l": 22.2725,
			"t": 1344356700000,
			"n": 89
		  },
		  {
			"v": 320628,
			"vw": 22.2821,
			"o": 22.2804,
			"c": 22.2814,
			"h": 22.2839,
			"l": 22.2797,
			"t": 1344356760000,
			"n": 86
		  },
		  {
			"v": 1774724,
			"vw": 22.2884,
			"o": 22.2801,
			"c": 22.293,
			"h": 22.2946,
			"l": 22.2789,
			"t": 1344356820000,
			"n": 365
		  },
		  {
			"v": 746648,
			"vw": 22.2946,
			"o": 22.2936,
			"c": 22.2961,
			"h": 22.2982,
			"l": 22.29,
			"t": 1344356880000,
			"n": 189
		  },
		  {
			"v": 674856,
			"vw": 22.2895,
			"o": 22.2941,
			"c": 22.2854,
			"h": 22.2968,
			"l": 22.2811,
			"t": 1344356940000,
			"n": 128
		  },
		  {
			"v": 514836,
			"vw": 22.2915,
			"o": 22.2843,
			"c": 22.2914,
			"h": 22.2957,
			"l": 22.2829,
			"t": 1344357000000,
			"n": 129
		  },
		  {
			"v": 683676,
			"vw": 22.296,
			"o": 22.2921,
			"c": 22.2968,
			"h": 22.2993,
			"l": 22.2915,
			"t": 1344357060000,
			"n": 165
		  },
		  {
			"v": 1864072,
			"vw": 22.3017,
			"o": 22.2983,
			"c": 22.3,
			"h": 22.3046,
			"l": 22.2961,
			"t": 1344357120000,
			"n": 295
		  },
		  {
			"v": 401184,
			"vw": 22.3016,
			"o": 22.3025,
			"c": 22.3029,
			"h": 22.3046,
			"l": 22.2993,
			"t": 1344357180000,
			"n": 79
		  },
		  {
			"v": 565628,
			"vw": 22.3025,
			"o": 22.3035,
			"c": 22.3054,
			"h": 22.3068,
			"l": 22.2993,
			"t": 1344357240000,
			"n": 117
		  },
		  {
			"v": 487732,
			"vw": 22.3032,
			"o": 22.3036,
			"c": 22.3054,
			"h": 22.3061,
			"l": 22.3,
			"t": 1344357300000,
			"n": 107
		  },
		  {
			"v": 680624,
			"vw": 22.3058,
			"o": 22.3032,
			"c": 22.3054,
			"h": 22.3096,
			"l": 22.3025,
			"t": 1344357360000,
			"n": 133
		  },
		  {
			"v": 738388,
			"vw": 22.3082,
			"o": 22.3054,
			"c": 22.3096,
			"h": 22.3107,
			"l": 22.3036,
			"t": 1344357420000,
			"n": 146
		  },
		  {
			"v": 570360,
			"vw": 22.3096,
			"o": 22.31,
			"c": 22.3111,
			"h": 22.3114,
			"l": 22.3039,
			"t": 1344357480000,
			"n": 117
		  },
		  {
			"v": 551152,
			"vw": 22.308,
			"o": 22.3086,
			"c": 22.3089,
			"h": 22.3111,
			"l": 22.3025,
			"t": 1344357540000,
			"n": 106
		  },
		  {
			"v": 651756,
			"vw": 22.3104,
			"o": 22.3104,
			"c": 22.3046,
			"h": 22.3125,
			"l": 22.3046,
			"t": 1344357600000,
			"n": 153
		  },
		  {
			"v": 623056,
			"vw": 22.3,
			"o": 22.305,
			"c": 22.3,
			"h": 22.3071,
			"l": 22.2925,
			"t": 1344357660000,
			"n": 139
		  },
		  {
			"v": 220920,
			"vw": 22.2974,
			"o": 22.2989,
			"c": 22.2936,
			"h": 22.3014,
			"l": 22.2936,
			"t": 1344357720000,
			"n": 51
		  },
		  {
			"v": 411180,
			"vw": 22.2949,
			"o": 22.2943,
			"c": 22.2961,
			"h": 22.2996,
			"l": 22.29,
			"t": 1344357780000,
			"n": 102
		  },
		  {
			"v": 364476,
			"vw": 22.297,
			"o": 22.2961,
			"c": 22.2964,
			"h": 22.3004,
			"l": 22.29,
			"t": 1344357840000,
			"n": 81
		  },
		  {
			"v": 413952,
			"vw": 22.2945,
			"o": 22.2936,
			"c": 22.2963,
			"h": 22.2964,
			"l": 22.2908,
			"t": 1344357900000,
			"n": 61
		  },
		  {
			"v": 229712,
			"vw": 22.2987,
			"o": 22.2941,
			"c": 22.3004,
			"h": 22.3014,
			"l": 22.2941,
			"t": 1344357960000,
			"n": 55
		  },
		  {
			"v": 381080,
			"vw": 22.2957,
			"o": 22.2979,
			"c": 22.295,
			"h": 22.2993,
			"l": 22.2946,
			"t": 1344358020000,
			"n": 46
		  },
		  {
			"v": 651952,
			"vw": 22.2871,
			"o": 22.2943,
			"c": 22.285,
			"h": 22.2946,
			"l": 22.2789,
			"t": 1344358080000,
			"n": 139
		  },
		  {
			"v": 330904,
			"vw": 22.2845,
			"o": 22.2825,
			"c": 22.2861,
			"h": 22.2875,
			"l": 22.2796,
			"t": 1344358140000,
			"n": 75
		  },
		  {
			"v": 260736,
			"vw": 22.2846,
			"o": 22.2826,
			"c": 22.2811,
			"h": 22.2875,
			"l": 22.2796,
			"t": 1344358200000,
			"n": 48
		  },
		  {
			"v": 213192,
			"vw": 22.2821,
			"o": 22.2807,
			"c": 22.2825,
			"h": 22.2857,
			"l": 22.2789,
			"t": 1344358260000,
			"n": 49
		  },
		  {
			"v": 280756,
			"vw": 22.2792,
			"o": 22.2832,
			"c": 22.2732,
			"h": 22.2854,
			"l": 22.2732,
			"t": 1344358320000,
			"n": 71
		  },
		  {
			"v": 1153712,
			"vw": 22.2646,
			"o": 22.2739,
			"c": 22.2546,
			"h": 22.2775,
			"l": 22.2546,
			"t": 1344358380000,
			"n": 276
		  },
		  {
			"v": 1130808,
			"vw": 22.2537,
			"o": 22.2582,
			"c": 22.2543,
			"h": 22.2607,
			"l": 22.25,
			"t": 1344358440000,
			"n": 255
		  },
		  {
			"v": 515340,
			"vw": 22.253,
			"o": 22.2561,
			"c": 22.2532,
			"h": 22.2561,
			"l": 22.2501,
			"t": 1344358500000,
			"n": 104
		  },
		  {
			"v": 728280,
			"vw": 22.2466,
			"o": 22.2511,
			"c": 22.245,
			"h": 22.2518,
			"l": 22.2411,
			"t": 1344358560000,
			"n": 144
		  },
		  {
			"v": 495460,
			"vw": 22.2489,
			"o": 22.2447,
			"c": 22.2471,
			"h": 22.2579,
			"l": 22.2443,
			"t": 1344358620000,
			"n": 120
		  },
		  {
			"v": 153384,
			"vw": 22.2483,
			"o": 22.2518,
			"c": 22.2447,
			"h": 22.2529,
			"l": 22.2443,
			"t": 1344358680000,
			"n": 35
		  },
		  {
			"v": 326060,
			"vw": 22.2506,
			"o": 22.2464,
			"c": 22.25,
			"h": 22.2546,
			"l": 22.2464,
			"t": 1344358740000,
			"n": 77
		  },
		  {
			"v": 276500,
			"vw": 22.2532,
			"o": 22.2472,
			"c": 22.2607,
			"h": 22.2607,
			"l": 22.2472,
			"t": 1344358800000,
			"n": 60
		  },
		  {
			"v": 289968,
			"vw": 22.2592,
			"o": 22.2604,
			"c": 22.2614,
			"h": 22.2614,
			"l": 22.254,
			"t": 1344358860000,
			"n": 80
		  },
		  {
			"v": 460964,
			"vw": 22.2607,
			"o": 22.2589,
			"c": 22.2514,
			"h": 22.2661,
			"l": 22.25,
			"t": 1344358920000,
			"n": 88
		  },
		  {
			"v": 244468,
			"vw": 22.2515,
			"o": 22.2504,
			"c": 22.2497,
			"h": 22.2571,
			"l": 22.2468,
			"t": 1344358980000,
			"n": 52
		  },
		  {
			"v": 268968,
			"vw": 22.248,
			"o": 22.2489,
			"c": 22.2481,
			"h": 22.2507,
			"l": 22.2432,
			"t": 1344359040000,
			"n": 44
		  },
		  {
			"v": 212212,
			"vw": 22.2517,
			"o": 22.2464,
			"c": 22.2511,
			"h": 22.2561,
			"l": 22.2457,
			"t": 1344359100000,
			"n": 53
		  },
		  {
			"v": 466676,
			"vw": 22.2446,
			"o": 22.2514,
			"c": 22.2414,
			"h": 22.2546,
			"l": 22.2389,
			"t": 1344359160000,
			"n": 102
		  },
		  {
			"v": 581168,
			"vw": 22.2361,
			"o": 22.2414,
			"c": 22.2393,
			"h": 22.2418,
			"l": 22.2321,
			"t": 1344359220000,
			"n": 107
		  },
		  {
			"v": 576380,
			"vw": 22.2304,
			"o": 22.2357,
			"c": 22.2261,
			"h": 22.24,
			"l": 22.2204,
			"t": 1344359280000,
			"n": 120
		  },
		  {
			"v": 681044,
			"vw": 22.2308,
			"o": 22.2219,
			"c": 22.2336,
			"h": 22.2386,
			"l": 22.2219,
			"t": 1344359340000,
			"n": 133
		  },
		  {
			"v": 464436,
			"vw": 22.229,
			"o": 22.2336,
			"c": 22.2282,
			"h": 22.2385,
			"l": 22.225,
			"t": 1344359400000,
			"n": 107
		  },
		  {
			"v": 186256,
			"vw": 22.2317,
			"o": 22.2325,
			"c": 22.2361,
			"h": 22.2382,
			"l": 22.2257,
			"t": 1344359460000,
			"n": 36
		  },
		  {
			"v": 450072,
			"vw": 22.2419,
			"o": 22.2375,
			"c": 22.2443,
			"h": 22.2454,
			"l": 22.235,
			"t": 1344359520000,
			"n": 90
		  },
		  {
			"v": 440188,
			"vw": 22.2427,
			"o": 22.2429,
			"c": 22.2429,
			"h": 22.2463,
			"l": 22.2393,
			"t": 1344359580000,
			"n": 84
		  },
		  {
			"v": 456288,
			"vw": 22.2439,
			"o": 22.2454,
			"c": 22.2421,
			"h": 22.2493,
			"l": 22.2364,
			"t": 1344359640000,
			"n": 95
		  },
		  {
			"v": 251608,
			"vw": 22.2427,
			"o": 22.2407,
			"c": 22.246,
			"h": 22.2464,
			"l": 22.2379,
			"t": 1344359700000,
			"n": 44
		  },
		  {
			"v": 140168,
			"vw": 22.2447,
			"o": 22.2457,
			"c": 22.2436,
			"h": 22.2492,
			"l": 22.2411,
			"t": 1344359760000,
			"n": 38
		  },
		  {
			"v": 100604,
			"vw": 22.2404,
			"o": 22.2423,
			"c": 22.2385,
			"h": 22.2423,
			"l": 22.2375,
			"t": 1344359820000,
			"n": 27
		  },
		  {
			"v": 650888,
			"vw": 22.2428,
			"o": 22.2421,
			"c": 22.2425,
			"h": 22.2449,
			"l": 22.2384,
			"t": 1344359880000,
			"n": 28
		  },
		  {
			"v": 105504,
			"vw": 22.2422,
			"o": 22.2421,
			"c": 22.2404,
			"h": 22.2432,
			"l": 22.24,
			"t": 1344359940000,
			"n": 23
		  },
		  {
			"v": 360108,
			"vw": 22.2473,
			"o": 22.2414,
			"c": 22.25,
			"h": 22.2514,
			"l": 22.2411,
			"t": 1344360000000,
			"n": 79
		  },
		  {
			"v": 272944,
			"vw": 22.248,
			"o": 22.251,
			"c": 22.2416,
			"h": 22.2518,
			"l": 22.2416,
			"t": 1344360060000,
			"n": 47
		  },
		  {
			"v": 282716,
			"vw": 22.2439,
			"o": 22.2422,
			"c": 22.2482,
			"h": 22.2487,
			"l": 22.2404,
			"t": 1344360120000,
			"n": 71
		  },
		  {
			"v": 104692,
			"vw": 22.2476,
			"o": 22.2482,
			"c": 22.2493,
			"h": 22.2496,
			"l": 22.2457,
			"t": 1344360180000,
			"n": 29
		  },
		  {
			"v": 751296,
			"vw": 22.2541,
			"o": 22.2486,
			"c": 22.2571,
			"h": 22.2593,
			"l": 22.2482,
			"t": 1344360240000,
			"n": 97
		  },
		  {
			"v": 2470748,
			"vw": 22.2494,
			"o": 22.2571,
			"c": 22.25,
			"h": 22.2571,
			"l": 22.2464,
			"t": 1344360300000,
			"n": 67
		  },
		  {
			"v": 248164,
			"vw": 22.2439,
			"o": 22.2468,
			"c": 22.2457,
			"h": 22.2496,
			"l": 22.2411,
			"t": 1344360360000,
			"n": 67
		  },
		  {
			"v": 481656,
			"vw": 22.2393,
			"o": 22.2414,
			"c": 22.2359,
			"h": 22.245,
			"l": 22.2339,
			"t": 1344360420000,
			"n": 79
		  },
		  {
			"v": 221200,
			"vw": 22.242,
			"o": 22.2357,
			"c": 22.245,
			"h": 22.2461,
			"l": 22.2357,
			"t": 1344360480000,
			"n": 51
		  },
		  {
			"v": 350224,
			"vw": 22.2495,
			"o": 22.245,
			"c": 22.2529,
			"h": 22.2536,
			"l": 22.245,
			"t": 1344360540000,
			"n": 84
		  },
		  {
			"v": 266308,
			"vw": 22.2505,
			"o": 22.2498,
			"c": 22.2465,
			"h": 22.2536,
			"l": 22.2464,
			"t": 1344360600000,
			"n": 58
		  },
		  {
			"v": 398636,
			"vw": 22.2452,
			"o": 22.249,
			"c": 22.2408,
			"h": 22.25,
			"l": 22.2375,
			"t": 1344360660000,
			"n": 79
		  },
		  {
			"v": 121324,
			"vw": 22.2477,
			"o": 22.2436,
			"c": 22.2456,
			"h": 22.2493,
			"l": 22.2436,
			"t": 1344360720000,
			"n": 26
		  },
		  {
			"v": 180600,
			"vw": 22.2419,
			"o": 22.2454,
			"c": 22.2412,
			"h": 22.2475,
			"l": 22.2358,
			"t": 1344360780000,
			"n": 45
		  },
		  {
			"v": 14056,
			"vw": 22.2406,
			"o": 22.2432,
			"c": 22.2404,
			"h": 22.2432,
			"l": 22.2393,
			"t": 1344360840000,
			"n": 4
		  },
		  {
			"v": 157304,
			"vw": 22.2442,
			"o": 22.2393,
			"c": 22.2468,
			"h": 22.2496,
			"l": 22.2386,
			"t": 1344360900000,
			"n": 39
		  },
		  {
			"v": 309932,
			"vw": 22.2402,
			"o": 22.2439,
			"c": 22.2386,
			"h": 22.2446,
			"l": 22.2364,
			"t": 1344360960000,
			"n": 78
		  },
		  {
			"v": 468048,
			"vw": 22.2338,
			"o": 22.2389,
			"c": 22.2325,
			"h": 22.2393,
			"l": 22.2304,
			"t": 1344361020000,
			"n": 75
		  },
		  {
			"v": 203252,
			"vw": 22.2292,
			"o": 22.2321,
			"c": 22.2272,
			"h": 22.2336,
			"l": 22.2261,
			"t": 1344361080000,
			"n": 57
		  },
		  {
			"v": 334628,
			"vw": 22.2279,
			"o": 22.2268,
			"c": 22.225,
			"h": 22.2314,
			"l": 22.225,
			"t": 1344361140000,
			"n": 75
		  },
		  {
			"v": 123900,
			"vw": 22.2282,
			"o": 22.2289,
			"c": 22.2257,
			"h": 22.2296,
			"l": 22.225,
			"t": 1344361200000,
			"n": 30
		  },
		  {
			"v": 204400,
			"vw": 22.2291,
			"o": 22.2286,
			"c": 22.2282,
			"h": 22.2343,
			"l": 22.2257,
			"t": 1344361260000,
			"n": 56
		  },
		  {
			"v": 218904,
			"vw": 22.232,
			"o": 22.2295,
			"c": 22.2329,
			"h": 22.2357,
			"l": 22.2282,
			"t": 1344361320000,
			"n": 56
		  },
		  {
			"v": 146860,
			"vw": 22.2291,
			"o": 22.2318,
			"c": 22.2293,
			"h": 22.2319,
			"l": 22.2261,
			"t": 1344361380000,
			"n": 34
		  },
		  {
			"v": 632800,
			"vw": 22.2199,
			"o": 22.2261,
			"c": 22.2221,
			"h": 22.2261,
			"l": 22.215,
			"t": 1344361440000,
			"n": 159
		  },
		  {
			"v": 146188,
			"vw": 22.2231,
			"o": 22.2221,
			"c": 22.2243,
			"h": 22.2261,
			"l": 22.2185,
			"t": 1344361500000,
			"n": 38
		  },
		  {
			"v": 177464,
			"vw": 22.222,
			"o": 22.2237,
			"c": 22.2279,
			"h": 22.2293,
			"l": 22.2168,
			"t": 1344361560000,
			"n": 53
		  },
		  {
			"v": 278096,
			"vw": 22.2226,
			"o": 22.2279,
			"c": 22.2202,
			"h": 22.2279,
			"l": 22.2189,
			"t": 1344361620000,
			"n": 44
		  },
		  {
			"v": 877660,
			"vw": 22.2096,
			"o": 22.2193,
			"c": 22.2071,
			"h": 22.2207,
			"l": 22.2034,
			"t": 1344361680000,
			"n": 183
		  },
		  {
			"v": 347788,
			"vw": 22.2092,
			"o": 22.2057,
			"c": 22.2083,
			"h": 22.2171,
			"l": 22.2046,
			"t": 1344361740000,
			"n": 101
		  },
		  {
			"v": 217476,
			"vw": 22.2088,
			"o": 22.2082,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2057,
			"t": 1344361800000,
			"n": 61
		  },
		  {
			"v": 580468,
			"vw": 22.2128,
			"o": 22.2118,
			"c": 22.2161,
			"h": 22.2193,
			"l": 22.2079,
			"t": 1344361860000,
			"n": 92
		  },
		  {
			"v": 598612,
			"vw": 22.2118,
			"o": 22.2152,
			"c": 22.2196,
			"h": 22.2196,
			"l": 22.2061,
			"t": 1344361920000,
			"n": 120
		  },
		  {
			"v": 250740,
			"vw": 22.2142,
			"o": 22.2174,
			"c": 22.21,
			"h": 22.2204,
			"l": 22.2071,
			"t": 1344361980000,
			"n": 58
		  },
		  {
			"v": 235984,
			"vw": 22.2189,
			"o": 22.2121,
			"c": 22.2175,
			"h": 22.2221,
			"l": 22.2121,
			"t": 1344362040000,
			"n": 68
		  },
		  {
			"v": 90524,
			"vw": 22.2164,
			"o": 22.2199,
			"c": 22.2129,
			"h": 22.2199,
			"l": 22.2129,
			"t": 1344362100000,
			"n": 18
		  },
		  {
			"v": 333452,
			"vw": 22.2082,
			"o": 22.2154,
			"c": 22.2061,
			"h": 22.2161,
			"l": 22.2032,
			"t": 1344362160000,
			"n": 85
		  },
		  {
			"v": 199528,
			"vw": 22.2106,
			"o": 22.2061,
			"c": 22.2136,
			"h": 22.2143,
			"l": 22.2061,
			"t": 1344362220000,
			"n": 47
		  },
		  {
			"v": 102900,
			"vw": 22.2095,
			"o": 22.2118,
			"c": 22.2075,
			"h": 22.2132,
			"l": 22.2068,
			"t": 1344362280000,
			"n": 27
		  },
		  {
			"v": 129752,
			"vw": 22.2099,
			"o": 22.2093,
			"c": 22.2089,
			"h": 22.2132,
			"l": 22.2064,
			"t": 1344362340000,
			"n": 40
		  },
		  {
			"v": 124544,
			"vw": 22.209,
			"o": 22.2111,
			"c": 22.2079,
			"h": 22.2119,
			"l": 22.2058,
			"t": 1344362400000,
			"n": 34
		  },
		  {
			"v": 272104,
			"vw": 22.2063,
			"o": 22.2072,
			"c": 22.2061,
			"h": 22.2129,
			"l": 22.2021,
			"t": 1344362460000,
			"n": 80
		  },
		  {
			"v": 444976,
			"vw": 22.2157,
			"o": 22.2063,
			"c": 22.2146,
			"h": 22.2221,
			"l": 22.206,
			"t": 1344362520000,
			"n": 114
		  },
		  {
			"v": 177968,
			"vw": 22.2062,
			"o": 22.2136,
			"c": 22.2076,
			"h": 22.2136,
			"l": 22.2014,
			"t": 1344362580000,
			"n": 58
		  },
		  {
			"v": 186424,
			"vw": 22.2046,
			"o": 22.2076,
			"c": 22.2079,
			"h": 22.2082,
			"l": 22.1986,
			"t": 1344362640000,
			"n": 60
		  },
		  {
			"v": 345576,
			"vw": 22.2161,
			"o": 22.2054,
			"c": 22.2179,
			"h": 22.2221,
			"l": 22.205,
			"t": 1344362700000,
			"n": 102
		  },
		  {
			"v": 245560,
			"vw": 22.2156,
			"o": 22.2182,
			"c": 22.2161,
			"h": 22.2186,
			"l": 22.2089,
			"t": 1344362760000,
			"n": 56
		  },
		  {
			"v": 201404,
			"vw": 22.2175,
			"o": 22.2143,
			"c": 22.2186,
			"h": 22.221,
			"l": 22.2143,
			"t": 1344362820000,
			"n": 36
		  },
		  {
			"v": 606396,
			"vw": 22.2262,
			"o": 22.2181,
			"c": 22.235,
			"h": 22.2357,
			"l": 22.2181,
			"t": 1344362880000,
			"n": 140
		  },
		  {
			"v": 327796,
			"vw": 22.233,
			"o": 22.2338,
			"c": 22.2321,
			"h": 22.2357,
			"l": 22.2279,
			"t": 1344362940000,
			"n": 80
		  },
		  {
			"v": 155680,
			"vw": 22.231,
			"o": 22.2296,
			"c": 22.2354,
			"h": 22.2354,
			"l": 22.2282,
			"t": 1344363000000,
			"n": 43
		  },
		  {
			"v": 215908,
			"vw": 22.228,
			"o": 22.2311,
			"c": 22.2243,
			"h": 22.2354,
			"l": 22.2225,
			"t": 1344363060000,
			"n": 54
		  },
		  {
			"v": 311724,
			"vw": 22.2248,
			"o": 22.2225,
			"c": 22.2193,
			"h": 22.2307,
			"l": 22.2193,
			"t": 1344363120000,
			"n": 71
		  },
		  {
			"v": 838824,
			"vw": 22.2084,
			"o": 22.2209,
			"c": 22.2071,
			"h": 22.2275,
			"l": 22.1968,
			"t": 1344363180000,
			"n": 213
		  },
		  {
			"v": 113008,
			"vw": 22.2116,
			"o": 22.2121,
			"c": 22.2113,
			"h": 22.2143,
			"l": 22.2089,
			"t": 1344363240000,
			"n": 28
		  },
		  {
			"v": 237720,
			"vw": 22.211,
			"o": 22.2125,
			"c": 22.2086,
			"h": 22.2141,
			"l": 22.2086,
			"t": 1344363300000,
			"n": 61
		  },
		  {
			"v": 352436,
			"vw": 22.2158,
			"o": 22.2082,
			"c": 22.2204,
			"h": 22.2257,
			"l": 22.205,
			"t": 1344363360000,
			"n": 99
		  },
		  {
			"v": 215516,
			"vw": 22.2175,
			"o": 22.2193,
			"c": 22.2136,
			"h": 22.2214,
			"l": 22.2136,
			"t": 1344363420000,
			"n": 38
		  },
		  {
			"v": 147336,
			"vw": 22.2118,
			"o": 22.2136,
			"c": 22.2142,
			"h": 22.2143,
			"l": 22.21,
			"t": 1344363480000,
			"n": 37
		  },
		  {
			"v": 41020,
			"vw": 22.2174,
			"o": 22.2125,
			"c": 22.2179,
			"h": 22.2208,
			"l": 22.2125,
			"t": 1344363540000,
			"n": 13
		  },
		  {
			"v": 171668,
			"vw": 22.2177,
			"o": 22.2161,
			"c": 22.215,
			"h": 22.22,
			"l": 22.215,
			"t": 1344363600000,
			"n": 45
		  },
		  {
			"v": 64988,
			"vw": 22.2153,
			"o": 22.2136,
			"c": 22.2139,
			"h": 22.2179,
			"l": 22.2136,
			"t": 1344363660000,
			"n": 16
		  },
		  {
			"v": 430388,
			"vw": 22.2083,
			"o": 22.2175,
			"c": 22.2094,
			"h": 22.2175,
			"l": 22.2036,
			"t": 1344363720000,
			"n": 107
		  },
		  {
			"v": 122696,
			"vw": 22.2112,
			"o": 22.21,
			"c": 22.2125,
			"h": 22.2136,
			"l": 22.2093,
			"t": 1344363780000,
			"n": 25
		  },
		  {
			"v": 98140,
			"vw": 22.2104,
			"o": 22.2118,
			"c": 22.2083,
			"h": 22.2132,
			"l": 22.2071,
			"t": 1344363840000,
			"n": 23
		  },
		  {
			"v": 126028,
			"vw": 22.2072,
			"o": 22.2071,
			"c": 22.2061,
			"h": 22.2096,
			"l": 22.2036,
			"t": 1344363900000,
			"n": 33
		  },
		  {
			"v": 194572,
			"vw": 22.2094,
			"o": 22.2096,
			"c": 22.2054,
			"h": 22.2132,
			"l": 22.2054,
			"t": 1344363960000,
			"n": 61
		  },
		  {
			"v": 275772,
			"vw": 22.2097,
			"o": 22.2111,
			"c": 22.2107,
			"h": 22.2111,
			"l": 22.2043,
			"t": 1344364020000,
			"n": 61
		  },
		  {
			"v": 605416,
			"vw": 22.1981,
			"o": 22.2114,
			"c": 22.1911,
			"h": 22.2114,
			"l": 22.1904,
			"t": 1344364080000,
			"n": 135
		  },
		  {
			"v": 388556,
			"vw": 22.1911,
			"o": 22.1907,
			"c": 22.1929,
			"h": 22.1943,
			"l": 22.1882,
			"t": 1344364140000,
			"n": 95
		  },
		  {
			"v": 257740,
			"vw": 22.1947,
			"o": 22.1908,
			"c": 22.2014,
			"h": 22.2018,
			"l": 22.1907,
			"t": 1344364200000,
			"n": 63
		  },
		  {
			"v": 187628,
			"vw": 22.2001,
			"o": 22.1971,
			"c": 22.2043,
			"h": 22.2043,
			"l": 22.195,
			"t": 1344364260000,
			"n": 48
		  },
		  {
			"v": 56000,
			"vw": 22.2021,
			"o": 22.1994,
			"c": 22.2004,
			"h": 22.2036,
			"l": 22.1994,
			"t": 1344364320000,
			"n": 13
		  },
		  {
			"v": 84196,
			"vw": 22.2024,
			"o": 22.2034,
			"c": 22.2023,
			"h": 22.2036,
			"l": 22.2011,
			"t": 1344364380000,
			"n": 27
		  },
		  {
			"v": 288708,
			"vw": 22.208,
			"o": 22.2018,
			"c": 22.2071,
			"h": 22.2125,
			"l": 22.2018,
			"t": 1344364440000,
			"n": 81
		  },
		  {
			"v": 116900,
			"vw": 22.208,
			"o": 22.2064,
			"c": 22.2107,
			"h": 22.2132,
			"l": 22.2032,
			"t": 1344364500000,
			"n": 28
		  },
		  {
			"v": 79128,
			"vw": 22.2077,
			"o": 22.2093,
			"c": 22.2067,
			"h": 22.2104,
			"l": 22.2043,
			"t": 1344364560000,
			"n": 23
		  },
		  {
			"v": 190456,
			"vw": 22.2075,
			"o": 22.2039,
			"c": 22.2096,
			"h": 22.2096,
			"l": 22.2039,
			"t": 1344364620000,
			"n": 52
		  },
		  {
			"v": 227444,
			"vw": 22.207,
			"o": 22.2091,
			"c": 22.2092,
			"h": 22.2093,
			"l": 22.2039,
			"t": 1344364680000,
			"n": 48
		  },
		  {
			"v": 701036,
			"vw": 22.2121,
			"o": 22.2096,
			"c": 22.2121,
			"h": 22.22,
			"l": 22.2082,
			"t": 1344364740000,
			"n": 152
		  },
		  {
			"v": 103292,
			"vw": 22.2087,
			"o": 22.2121,
			"c": 22.2043,
			"h": 22.2125,
			"l": 22.2043,
			"t": 1344364800000,
			"n": 27
		  },
		  {
			"v": 292432,
			"vw": 22.2117,
			"o": 22.2079,
			"c": 22.2125,
			"h": 22.2143,
			"l": 22.2054,
			"t": 1344364860000,
			"n": 84
		  },
		  {
			"v": 277844,
			"vw": 22.2077,
			"o": 22.2125,
			"c": 22.2118,
			"h": 22.2139,
			"l": 22.2043,
			"t": 1344364920000,
			"n": 55
		  },
		  {
			"v": 203000,
			"vw": 22.2107,
			"o": 22.2121,
			"c": 22.2093,
			"h": 22.2139,
			"l": 22.2086,
			"t": 1344364980000,
			"n": 45
		  },
		  {
			"v": 90804,
			"vw": 22.2108,
			"o": 22.2107,
			"c": 22.2113,
			"h": 22.2136,
			"l": 22.2093,
			"t": 1344365040000,
			"n": 26
		  },
		  {
			"v": 2550604,
			"vw": 22.208,
			"o": 22.2096,
			"c": 22.2106,
			"h": 22.2129,
			"l": 22.2093,
			"t": 1344365100000,
			"n": 29
		  },
		  {
			"v": 152740,
			"vw": 22.2128,
			"o": 22.2125,
			"c": 22.2125,
			"h": 22.2143,
			"l": 22.21,
			"t": 1344365160000,
			"n": 45
		  },
		  {
			"v": 96292,
			"vw": 22.212,
			"o": 22.2136,
			"c": 22.2125,
			"h": 22.2136,
			"l": 22.2093,
			"t": 1344365220000,
			"n": 25
		  },
		  {
			"v": 420980,
			"vw": 22.2113,
			"o": 22.2111,
			"c": 22.2089,
			"h": 22.2143,
			"l": 22.2086,
			"t": 1344365280000,
			"n": 124
		  },
		  {
			"v": 341068,
			"vw": 22.2205,
			"o": 22.2136,
			"c": 22.2271,
			"h": 22.2271,
			"l": 22.2118,
			"t": 1344365340000,
			"n": 61
		  },
		  {
			"v": 568456,
			"vw": 22.2255,
			"o": 22.2258,
			"c": 22.2179,
			"h": 22.23,
			"l": 22.2179,
			"t": 1344365400000,
			"n": 134
		  },
		  {
			"v": 200116,
			"vw": 22.2224,
			"o": 22.2228,
			"c": 22.2206,
			"h": 22.2246,
			"l": 22.2182,
			"t": 1344365460000,
			"n": 46
		  },
		  {
			"v": 72576,
			"vw": 22.2211,
			"o": 22.2214,
			"c": 22.2175,
			"h": 22.2243,
			"l": 22.2175,
			"t": 1344365520000,
			"n": 22
		  },
		  {
			"v": 248696,
			"vw": 22.2173,
			"o": 22.2185,
			"c": 22.2207,
			"h": 22.2232,
			"l": 22.2143,
			"t": 1344365580000,
			"n": 49
		  },
		  {
			"v": 137032,
			"vw": 22.2191,
			"o": 22.2207,
			"c": 22.215,
			"h": 22.2243,
			"l": 22.2143,
			"t": 1344365640000,
			"n": 39
		  },
		  {
			"v": 89152,
			"vw": 22.2172,
			"o": 22.215,
			"c": 22.2168,
			"h": 22.2207,
			"l": 22.2147,
			"t": 1344365700000,
			"n": 22
		  },
		  {
			"v": 239456,
			"vw": 22.214,
			"o": 22.2169,
			"c": 22.2093,
			"h": 22.2202,
			"l": 22.2086,
			"t": 1344365760000,
			"n": 49
		  },
		  {
			"v": 253568,
			"vw": 22.2112,
			"o": 22.21,
			"c": 22.2096,
			"h": 22.2152,
			"l": 22.2086,
			"t": 1344365820000,
			"n": 55
		  },
		  {
			"v": 80388,
			"vw": 22.2119,
			"o": 22.2118,
			"c": 22.2108,
			"h": 22.2139,
			"l": 22.21,
			"t": 1344365880000,
			"n": 16
		  },
		  {
			"v": 326368,
			"vw": 22.2091,
			"o": 22.2107,
			"c": 22.2104,
			"h": 22.2121,
			"l": 22.2086,
			"t": 1344365940000,
			"n": 78
		  },
		  {
			"v": 472220,
			"vw": 22.2115,
			"o": 22.2089,
			"c": 22.2139,
			"h": 22.2143,
			"l": 22.2086,
			"t": 1344366000000,
			"n": 95
		  },
		  {
			"v": 821940,
			"vw": 22.2117,
			"o": 22.2139,
			"c": 22.2118,
			"h": 22.2157,
			"l": 22.2071,
			"t": 1344366060000,
			"n": 109
		  },
		  {
			"v": 111664,
			"vw": 22.2103,
			"o": 22.2114,
			"c": 22.2086,
			"h": 22.2125,
			"l": 22.2086,
			"t": 1344366120000,
			"n": 30
		  },
		  {
			"v": 425376,
			"vw": 22.2037,
			"o": 22.21,
			"c": 22.1982,
			"h": 22.2114,
			"l": 22.1971,
			"t": 1344366180000,
			"n": 96
		  },
		  {
			"v": 597156,
			"vw": 22.1927,
			"o": 22.1971,
			"c": 22.1918,
			"h": 22.1979,
			"l": 22.19,
			"t": 1344366240000,
			"n": 160
		  },
		  {
			"v": 315896,
			"vw": 22.1916,
			"o": 22.1918,
			"c": 22.1921,
			"h": 22.1929,
			"l": 22.1904,
			"t": 1344366300000,
			"n": 72
		  },
		  {
			"v": 984536,
			"vw": 22.188,
			"o": 22.1907,
			"c": 22.1944,
			"h": 22.1964,
			"l": 22.1829,
			"t": 1344366360000,
			"n": 199
		  },
		  {
			"v": 325696,
			"vw": 22.186,
			"o": 22.1933,
			"c": 22.1829,
			"h": 22.1943,
			"l": 22.1829,
			"t": 1344366420000,
			"n": 91
		  },
		  {
			"v": 690060,
			"vw": 22.184,
			"o": 22.1832,
			"c": 22.1886,
			"h": 22.1943,
			"l": 22.1789,
			"t": 1344366480000,
			"n": 157
		  },
		  {
			"v": 197428,
			"vw": 22.1874,
			"o": 22.1875,
			"c": 22.1893,
			"h": 22.1907,
			"l": 22.1822,
			"t": 1344366540000,
			"n": 59
		  },
		  {
			"v": 238084,
			"vw": 22.1916,
			"o": 22.1907,
			"c": 22.1914,
			"h": 22.1957,
			"l": 22.1882,
			"t": 1344366600000,
			"n": 70
		  },
		  {
			"v": 284424,
			"vw": 22.1942,
			"o": 22.1914,
			"c": 22.1894,
			"h": 22.1996,
			"l": 22.1894,
			"t": 1344366660000,
			"n": 71
		  },
		  {
			"v": 287000,
			"vw": 22.1858,
			"o": 22.1893,
			"c": 22.1807,
			"h": 22.1921,
			"l": 22.1804,
			"t": 1344366720000,
			"n": 75
		  },
		  {
			"v": 1428364,
			"vw": 22.1778,
			"o": 22.1807,
			"c": 22.1796,
			"h": 22.1811,
			"l": 22.1725,
			"t": 1344366780000,
			"n": 314
		  },
		  {
			"v": 838852,
			"vw": 22.1768,
			"o": 22.1793,
			"c": 22.1786,
			"h": 22.1804,
			"l": 22.1714,
			"t": 1344366840000,
			"n": 181
		  },
		  {
			"v": 541716,
			"vw": 22.1799,
			"o": 22.175,
			"c": 22.1846,
			"h": 22.185,
			"l": 22.1714,
			"t": 1344366900000,
			"n": 130
		  },
		  {
			"v": 672616,
			"vw": 22.1749,
			"o": 22.185,
			"c": 22.1714,
			"h": 22.185,
			"l": 22.1693,
			"t": 1344366960000,
			"n": 165
		  },
		  {
			"v": 909272,
			"vw": 22.1712,
			"o": 22.1696,
			"c": 22.1771,
			"h": 22.1811,
			"l": 22.1671,
			"t": 1344367020000,
			"n": 130
		  },
		  {
			"v": 502264,
			"vw": 22.1769,
			"o": 22.1786,
			"c": 22.175,
			"h": 22.1814,
			"l": 22.1736,
			"t": 1344367080000,
			"n": 93
		  },
		  {
			"v": 212520,
			"vw": 22.1771,
			"o": 22.1757,
			"c": 22.1725,
			"h": 22.1793,
			"l": 22.1725,
			"t": 1344367140000,
			"n": 46
		  },
		  {
			"v": 452396,
			"vw": 22.1768,
			"o": 22.1729,
			"c": 22.1776,
			"h": 22.1796,
			"l": 22.1729,
			"t": 1344367200000,
			"n": 110
		  },
		  {
			"v": 553840,
			"vw": 22.1745,
			"o": 22.1782,
			"c": 22.1739,
			"h": 22.1786,
			"l": 22.1714,
			"t": 1344367260000,
			"n": 147
		  },
		  {
			"v": 146076,
			"vw": 22.1749,
			"o": 22.1725,
			"c": 22.1764,
			"h": 22.1779,
			"l": 22.1707,
			"t": 1344367320000,
			"n": 38
		  },
		  {
			"v": 296520,
			"vw": 22.1735,
			"o": 22.1764,
			"c": 22.173,
			"h": 22.1774,
			"l": 22.1696,
			"t": 1344367380000,
			"n": 80
		  },
		  {
			"v": 222684,
			"vw": 22.1741,
			"o": 22.1732,
			"c": 22.1732,
			"h": 22.1775,
			"l": 22.1714,
			"t": 1344367440000,
			"n": 51
		  },
		  {
			"v": 109396,
			"vw": 22.1724,
			"o": 22.1732,
			"c": 22.1714,
			"h": 22.1766,
			"l": 22.1696,
			"t": 1344367500000,
			"n": 31
		  },
		  {
			"v": 712124,
			"vw": 22.1764,
			"o": 22.1734,
			"c": 22.1754,
			"h": 22.1789,
			"l": 22.1705,
			"t": 1344367560000,
			"n": 155
		  },
		  {
			"v": 439264,
			"vw": 22.1773,
			"o": 22.1764,
			"c": 22.1792,
			"h": 22.1811,
			"l": 22.1718,
			"t": 1344367620000,
			"n": 106
		  },
		  {
			"v": 519148,
			"vw": 22.1789,
			"o": 22.1789,
			"c": 22.1786,
			"h": 22.1807,
			"l": 22.1771,
			"t": 1344367680000,
			"n": 117
		  },
		  {
			"v": 593236,
			"vw": 22.1762,
			"o": 22.1766,
			"c": 22.1746,
			"h": 22.1804,
			"l": 22.1725,
			"t": 1344367740000,
			"n": 150
		  },
		  {
			"v": 1329132,
			"vw": 22.1727,
			"o": 22.1746,
			"c": 22.1743,
			"h": 22.1814,
			"l": 22.1625,
			"t": 1344367800000,
			"n": 341
		  },
		  {
			"v": 568764,
			"vw": 22.1753,
			"o": 22.1729,
			"c": 22.1775,
			"h": 22.1811,
			"l": 22.1714,
			"t": 1344367860000,
			"n": 160
		  },
		  {
			"v": 516236,
			"vw": 22.1808,
			"o": 22.1782,
			"c": 22.1775,
			"h": 22.185,
			"l": 22.1754,
			"t": 1344367920000,
			"n": 106
		  },
		  {
			"v": 394240,
			"vw": 22.1753,
			"o": 22.1771,
			"c": 22.1746,
			"h": 22.1793,
			"l": 22.1707,
			"t": 1344367980000,
			"n": 118
		  },
		  {
			"v": 659372,
			"vw": 22.1769,
			"o": 22.1768,
			"c": 22.1746,
			"h": 22.1811,
			"l": 22.1714,
			"t": 1344368040000,
			"n": 124
		  },
		  {
			"v": 420336,
			"vw": 22.1692,
			"o": 22.1739,
			"c": 22.1671,
			"h": 22.1743,
			"l": 22.165,
			"t": 1344368100000,
			"n": 93
		  },
		  {
			"v": 954352,
			"vw": 22.1607,
			"o": 22.1671,
			"c": 22.1561,
			"h": 22.1707,
			"l": 22.1554,
			"t": 1344368160000,
			"n": 217
		  },
		  {
			"v": 1035608,
			"vw": 22.1541,
			"o": 22.1568,
			"c": 22.1491,
			"h": 22.1593,
			"l": 22.1486,
			"t": 1344368220000,
			"n": 232
		  },
		  {
			"v": 1026956,
			"vw": 22.151,
			"o": 22.1486,
			"c": 22.1568,
			"h": 22.1582,
			"l": 22.1471,
			"t": 1344368280000,
			"n": 268
		  },
		  {
			"v": 714812,
			"vw": 22.1718,
			"o": 22.1543,
			"c": 22.1525,
			"h": 22.1567,
			"l": 22.1483,
			"t": 1344368340000,
			"n": 138
		  },
		  {
			"v": 422184,
			"vw": 22.1537,
			"o": 22.1512,
			"c": 22.1525,
			"h": 22.1571,
			"l": 22.1512,
			"t": 1344368400000,
			"n": 103
		  },
		  {
			"v": 478548,
			"vw": 22.15,
			"o": 22.1532,
			"c": 22.1532,
			"h": 22.1537,
			"l": 22.1475,
			"t": 1344368460000,
			"n": 111
		  },
		  {
			"v": 672980,
			"vw": 22.1572,
			"o": 22.1514,
			"c": 22.1614,
			"h": 22.1621,
			"l": 22.1514,
			"t": 1344368520000,
			"n": 122
		  },
		  {
			"v": 377132,
			"vw": 22.1602,
			"o": 22.1621,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1554,
			"t": 1344368580000,
			"n": 101
		  },
		  {
			"v": 881356,
			"vw": 22.1666,
			"o": 22.1643,
			"c": 22.1764,
			"h": 22.1764,
			"l": 22.16,
			"t": 1344368640000,
			"n": 160
		  },
		  {
			"v": 1202376,
			"vw": 22.1816,
			"o": 22.1764,
			"c": 22.1779,
			"h": 22.1896,
			"l": 22.1704,
			"t": 1344368700000,
			"n": 245
		  },
		  {
			"v": 799932,
			"vw": 22.1833,
			"o": 22.1811,
			"c": 22.1832,
			"h": 22.1889,
			"l": 22.1786,
			"t": 1344368760000,
			"n": 171
		  },
		  {
			"v": 668528,
			"vw": 22.185,
			"o": 22.1829,
			"c": 22.1864,
			"h": 22.1899,
			"l": 22.1786,
			"t": 1344368820000,
			"n": 138
		  },
		  {
			"v": 896504,
			"vw": 22.1808,
			"o": 22.1878,
			"c": 22.1821,
			"h": 22.1878,
			"l": 22.1779,
			"t": 1344368880000,
			"n": 178
		  },
		  {
			"v": 1023708,
			"vw": 22.1728,
			"o": 22.1821,
			"c": 22.167,
			"h": 22.1821,
			"l": 22.1661,
			"t": 1344368940000,
			"n": 259
		  },
		  {
			"v": 1095668,
			"vw": 22.1676,
			"o": 22.1679,
			"c": 22.1636,
			"h": 22.1746,
			"l": 22.1607,
			"t": 1344369000000,
			"n": 286
		  },
		  {
			"v": 1062152,
			"vw": 22.1586,
			"o": 22.1643,
			"c": 22.1475,
			"h": 22.1643,
			"l": 22.1475,
			"t": 1344369060000,
			"n": 258
		  },
		  {
			"v": 1227128,
			"vw": 22.1496,
			"o": 22.1511,
			"c": 22.148,
			"h": 22.1557,
			"l": 22.1457,
			"t": 1344369120000,
			"n": 247
		  },
		  {
			"v": 2035544,
			"vw": 22.1435,
			"o": 22.1482,
			"c": 22.1411,
			"h": 22.1507,
			"l": 22.1393,
			"t": 1344369180000,
			"n": 412
		  },
		  {
			"v": 1889804,
			"vw": 22.1382,
			"o": 22.1421,
			"c": 22.1404,
			"h": 22.1425,
			"l": 22.1325,
			"t": 1344369240000,
			"n": 390
		  },
		  {
			"v": 860552,
			"vw": 22.1392,
			"o": 22.1396,
			"c": 22.1411,
			"h": 22.1425,
			"l": 22.135,
			"t": 1344369300000,
			"n": 213
		  },
		  {
			"v": 1003436,
			"vw": 22.1422,
			"o": 22.1414,
			"c": 22.1457,
			"h": 22.1457,
			"l": 22.1378,
			"t": 1344369360000,
			"n": 232
		  },
		  {
			"v": 1538712,
			"vw": 22.1529,
			"o": 22.1443,
			"c": 22.162,
			"h": 22.1625,
			"l": 22.1443,
			"t": 1344369420000,
			"n": 346
		  },
		  {
			"v": 2438604,
			"vw": 22.1638,
			"o": 22.1596,
			"c": 22.1696,
			"h": 22.1696,
			"l": 22.1593,
			"t": 1344369480000,
			"n": 464
		  },
		  {
			"v": 3613764,
			"vw": 22.173,
			"o": 22.1696,
			"c": 22.1775,
			"h": 22.1786,
			"l": 22.1679,
			"t": 1344369540000,
			"n": 689
		  },
		  {
			"v": 141624,
			"vw": 22.1756,
			"o": 22.1679,
			"c": 22.1782,
			"h": 22.1782,
			"l": 22.1679,
			"t": 1344369600000,
			"n": 16
		  },
		  {
			"v": 85260,
			"vw": 22.1792,
			"o": 22.1775,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1771,
			"t": 1344369660000,
			"n": 6
		  },
		  {
			"v": 8400,
			"vw": 22.1788,
			"o": 22.1789,
			"c": 22.1786,
			"h": 22.1789,
			"l": 22.1786,
			"t": 1344369720000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1789,
			"o": 22.1789,
			"c": 22.1789,
			"h": 22.1789,
			"l": 22.1789,
			"t": 1344369780000,
			"n": 2
		  },
		  {
			"v": 22400,
			"vw": 22.1835,
			"o": 22.1839,
			"c": 22.1832,
			"h": 22.1839,
			"l": 22.1814,
			"t": 1344369840000,
			"n": 5
		  },
		  {
			"v": 48272,
			"vw": 22.1811,
			"o": 22.1832,
			"c": 22.1786,
			"h": 22.1832,
			"l": 22.1786,
			"t": 1344369900000,
			"n": 6
		  },
		  {
			"v": 14000,
			"vw": 22.1779,
			"o": 22.1786,
			"c": 22.1775,
			"h": 22.1786,
			"l": 22.1775,
			"t": 1344369960000,
			"n": 3
		  },
		  {
			"v": 20300,
			"vw": 22.1797,
			"o": 22.1821,
			"c": 22.1786,
			"h": 22.1821,
			"l": 22.1775,
			"t": 1344370020000,
			"n": 7
		  },
		  {
			"v": 24780,
			"vw": 22.1851,
			"o": 22.1882,
			"c": 22.1821,
			"h": 22.1907,
			"l": 22.1821,
			"t": 1344370320000,
			"n": 4
		  },
		  {
			"v": 33432,
			"vw": 22.1791,
			"o": 22.1796,
			"c": 22.1789,
			"h": 22.1796,
			"l": 22.1789,
			"t": 1344370380000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.1789,
			"o": 22.1789,
			"c": 22.1789,
			"h": 22.1789,
			"l": 22.1789,
			"t": 1344370440000,
			"n": 1
		  },
		  {
			"v": 35840,
			"vw": 22.1775,
			"o": 22.1761,
			"c": 22.1761,
			"h": 22.1786,
			"l": 22.1761,
			"t": 1344370500000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.1761,
			"o": 22.1761,
			"c": 22.1761,
			"h": 22.1761,
			"l": 22.1761,
			"t": 1344370560000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1757,
			"o": 22.1757,
			"c": 22.1757,
			"h": 22.1757,
			"l": 22.1757,
			"t": 1344370620000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1757,
			"o": 22.1757,
			"c": 22.1757,
			"h": 22.1757,
			"l": 22.1757,
			"t": 1344370740000,
			"n": 2
		  },
		  {
			"v": 13608,
			"vw": 22.1739,
			"o": 22.1725,
			"c": 22.175,
			"h": 22.175,
			"l": 22.1725,
			"t": 1344370800000,
			"n": 3
		  },
		  {
			"v": 4060,
			"vw": 22.1714,
			"o": 22.1714,
			"c": 22.1714,
			"h": 22.1714,
			"l": 22.1714,
			"t": 1344370860000,
			"n": 1
		  },
		  {
			"v": 3612,
			"vw": 22.17,
			"o": 22.17,
			"c": 22.17,
			"h": 22.17,
			"l": 22.17,
			"t": 1344370920000,
			"n": 1
		  },
		  {
			"v": 13328,
			"vw": 22.1699,
			"o": 22.17,
			"c": 22.1696,
			"h": 22.17,
			"l": 22.1696,
			"t": 1344371040000,
			"n": 3
		  },
		  {
			"v": 46564,
			"vw": 22.1736,
			"o": 22.1682,
			"c": 22.175,
			"h": 22.1757,
			"l": 22.1679,
			"t": 1344371160000,
			"n": 12
		  },
		  {
			"v": 24136,
			"vw": 22.1783,
			"o": 22.1782,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1775,
			"t": 1344371220000,
			"n": 8
		  },
		  {
			"v": 14364,
			"vw": 22.1784,
			"o": 22.1782,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1782,
			"t": 1344371280000,
			"n": 3
		  },
		  {
			"v": 19320,
			"vw": 22.1676,
			"o": 22.1686,
			"c": 22.1668,
			"h": 22.1686,
			"l": 22.1668,
			"t": 1344371400000,
			"n": 3
		  },
		  {
			"v": 140056,
			"vw": 22.1807,
			"o": 22.1675,
			"c": 22.1679,
			"h": 22.1679,
			"l": 22.1668,
			"t": 1344371580000,
			"n": 4
		  },
		  {
			"v": 8960,
			"vw": 22.1703,
			"o": 22.1679,
			"c": 22.1714,
			"h": 22.1714,
			"l": 22.1679,
			"t": 1344371640000,
			"n": 2
		  },
		  {
			"v": 11200,
			"vw": 22.1773,
			"o": 22.1768,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1768,
			"t": 1344371700000,
			"n": 3
		  },
		  {
			"v": 39732,
			"vw": 22.1784,
			"o": 22.1779,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1775,
			"t": 1344371760000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.175,
			"o": 22.175,
			"c": 22.175,
			"h": 22.175,
			"l": 22.175,
			"t": 1344371880000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344371940000,
			"n": 1
		  },
		  {
			"v": 31948,
			"vw": 22.1679,
			"o": 22.1686,
			"c": 22.1671,
			"h": 22.1686,
			"l": 22.1671,
			"t": 1344372180000,
			"n": 5
		  },
		  {
			"v": 5040,
			"vw": 22.1668,
			"o": 22.1668,
			"c": 22.1668,
			"h": 22.1668,
			"l": 22.1668,
			"t": 1344372600000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1761,
			"o": 22.1761,
			"c": 22.1761,
			"h": 22.1761,
			"l": 22.1761,
			"t": 1344372960000,
			"n": 1
		  },
		  {
			"v": 2828,
			"vw": 22.1668,
			"o": 22.1668,
			"c": 22.1668,
			"h": 22.1668,
			"l": 22.1668,
			"t": 1344373020000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1718,
			"o": 22.1757,
			"c": 22.1671,
			"h": 22.1764,
			"l": 22.1671,
			"t": 1344373260000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.1729,
			"o": 22.1729,
			"c": 22.1729,
			"h": 22.1729,
			"l": 22.1729,
			"t": 1344373440000,
			"n": 1
		  },
		  {
			"v": 5628,
			"vw": 22.1696,
			"o": 22.1696,
			"c": 22.1696,
			"h": 22.1696,
			"l": 22.1696,
			"t": 1344373620000,
			"n": 2
		  },
		  {
			"v": 6272,
			"vw": 22.1668,
			"o": 22.1668,
			"c": 22.1668,
			"h": 22.1668,
			"l": 22.1668,
			"t": 1344373860000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1666,
			"o": 22.1664,
			"c": 22.1668,
			"h": 22.1668,
			"l": 22.1664,
			"t": 1344373920000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1643,
			"o": 22.1643,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1643,
			"t": 1344373980000,
			"n": 1
		  },
		  {
			"v": 18732,
			"vw": 22.172,
			"o": 22.1643,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1643,
			"t": 1344374040000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1607,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1607,
			"t": 1344374340000,
			"n": 1
		  },
		  {
			"v": 12936,
			"vw": 22.1622,
			"o": 22.1607,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1607,
			"t": 1344374820000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1611,
			"o": 22.1611,
			"c": 22.1611,
			"h": 22.1611,
			"l": 22.1611,
			"t": 1344374940000,
			"n": 1
		  },
		  {
			"v": 58800,
			"vw": 22.1607,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1607,
			"t": 1344375000000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.1607,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1607,
			"t": 1344375060000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.1608,
			"o": 22.1611,
			"c": 22.1607,
			"h": 22.1611,
			"l": 22.1607,
			"t": 1344375240000,
			"n": 3
		  },
		  {
			"v": 36932,
			"vw": 22.1607,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1607,
			"t": 1344375600000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1543,
			"o": 22.1543,
			"c": 22.1543,
			"h": 22.1543,
			"l": 22.1543,
			"t": 1344375780000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1571,
			"o": 22.1571,
			"c": 22.1571,
			"h": 22.1571,
			"l": 22.1571,
			"t": 1344375900000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1579,
			"o": 22.1579,
			"c": 22.1579,
			"h": 22.1579,
			"l": 22.1579,
			"t": 1344376140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1643,
			"o": 22.1643,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1643,
			"t": 1344376320000,
			"n": 1
		  },
		  {
			"v": 3780,
			"vw": 22.1571,
			"o": 22.1571,
			"c": 22.1571,
			"h": 22.1571,
			"l": 22.1571,
			"t": 1344376740000,
			"n": 1
		  },
		  {
			"v": 4480,
			"vw": 22.1571,
			"o": 22.1571,
			"c": 22.1571,
			"h": 22.1571,
			"l": 22.1571,
			"t": 1344377160000,
			"n": 1
		  },
		  {
			"v": 22372,
			"vw": 22.1707,
			"o": 22.1664,
			"c": 22.1714,
			"h": 22.1714,
			"l": 22.1664,
			"t": 1344377520000,
			"n": 7
		  },
		  {
			"v": 11200,
			"vw": 22.1624,
			"o": 22.1629,
			"c": 22.1618,
			"h": 22.1629,
			"l": 22.1618,
			"t": 1344377700000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1611,
			"o": 22.1611,
			"c": 22.1611,
			"h": 22.1611,
			"l": 22.1611,
			"t": 1344377760000,
			"n": 1
		  },
		  {
			"v": 5348,
			"vw": 22.1643,
			"o": 22.1643,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1643,
			"t": 1344378120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1714,
			"o": 22.1714,
			"c": 22.1714,
			"h": 22.1714,
			"l": 22.1714,
			"t": 1344379080000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1707,
			"o": 22.1707,
			"c": 22.1707,
			"h": 22.1707,
			"l": 22.1707,
			"t": 1344380760000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1693,
			"o": 22.1693,
			"c": 22.1693,
			"h": 22.1693,
			"l": 22.1693,
			"t": 1344382020000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1679,
			"o": 22.1679,
			"c": 22.1679,
			"h": 22.1679,
			"l": 22.1679,
			"t": 1344382440000,
			"n": 1
		  },
		  {
			"v": 3164,
			"vw": 22.1668,
			"o": 22.1668,
			"c": 22.1668,
			"h": 22.1668,
			"l": 22.1668,
			"t": 1344382860000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 22.1665,
			"o": 22.1668,
			"c": 22.1664,
			"h": 22.1668,
			"l": 22.1664,
			"t": 1344382920000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1643,
			"o": 22.1643,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1643,
			"t": 1344383160000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.1611,
			"o": 22.1611,
			"c": 22.1611,
			"h": 22.1611,
			"l": 22.1611,
			"t": 1344383340000,
			"n": 1
		  },
		  {
			"v": 3500,
			"vw": 22.1607,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1607,
			"t": 1344383760000,
			"n": 1
		  },
		  {
			"v": 6132,
			"vw": 22.1179,
			"o": 22.1179,
			"c": 22.1179,
			"h": 22.1179,
			"l": 22.1179,
			"t": 1344414960000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0911,
			"o": 22.0911,
			"c": 22.0911,
			"h": 22.0911,
			"l": 22.0911,
			"t": 1344415860000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344415980000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344418320000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344418500000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344418680000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344418860000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344418920000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0971,
			"o": 22.0971,
			"c": 22.0971,
			"h": 22.0971,
			"l": 22.0971,
			"t": 1344419100000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0786,
			"o": 22.0786,
			"c": 22.0786,
			"h": 22.0786,
			"l": 22.0786,
			"t": 1344421980000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0804,
			"o": 22.0804,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0804,
			"t": 1344422040000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.0857,
			"o": 22.0857,
			"c": 22.0857,
			"h": 22.0857,
			"l": 22.0857,
			"t": 1344422640000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.0968,
			"o": 22.0968,
			"c": 22.0968,
			"h": 22.0968,
			"l": 22.0968,
			"t": 1344424080000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1064,
			"o": 22.1064,
			"c": 22.1064,
			"h": 22.1064,
			"l": 22.1064,
			"t": 1344424140000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.113,
			"o": 22.1064,
			"c": 22.1143,
			"h": 22.1143,
			"l": 22.1064,
			"t": 1344424200000,
			"n": 3
		  },
		  {
			"v": 13832,
			"vw": 22.0998,
			"o": 22.1007,
			"c": 22.0993,
			"h": 22.1007,
			"l": 22.0993,
			"t": 1344424260000,
			"n": 4
		  },
		  {
			"v": 17136,
			"vw": 22.1165,
			"o": 22.1161,
			"c": 22.1175,
			"h": 22.1175,
			"l": 22.1161,
			"t": 1344424320000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.1137,
			"o": 22.1111,
			"c": 22.1164,
			"h": 22.1164,
			"l": 22.1111,
			"t": 1344424560000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.125,
			"o": 22.125,
			"c": 22.125,
			"h": 22.125,
			"l": 22.125,
			"t": 1344424680000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1321,
			"o": 22.1357,
			"c": 22.1286,
			"h": 22.1357,
			"l": 22.1286,
			"t": 1344424740000,
			"n": 2
		  },
		  {
			"v": 5516,
			"vw": 22.1375,
			"o": 22.1375,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1375,
			"t": 1344424860000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.1432,
			"o": 22.1504,
			"c": 22.1432,
			"h": 22.1504,
			"l": 22.1361,
			"t": 1344424920000,
			"n": 6
		  },
		  {
			"v": 11200,
			"vw": 22.1304,
			"o": 22.1325,
			"c": 22.1264,
			"h": 22.1325,
			"l": 22.1264,
			"t": 1344424980000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.1286,
			"o": 22.1286,
			"c": 22.1286,
			"h": 22.1286,
			"l": 22.1286,
			"t": 1344425040000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 22.1206,
			"o": 22.125,
			"c": 22.1146,
			"h": 22.125,
			"l": 22.1146,
			"t": 1344425160000,
			"n": 5
		  },
		  {
			"v": 33320,
			"vw": 22.1126,
			"o": 22.1139,
			"c": 22.1057,
			"h": 22.1139,
			"l": 22.1057,
			"t": 1344425460000,
			"n": 3
		  },
		  {
			"v": 22120,
			"vw": 22.1095,
			"o": 22.1136,
			"c": 22.1071,
			"h": 22.1136,
			"l": 22.1071,
			"t": 1344425820000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1071,
			"o": 22.1071,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1071,
			"t": 1344425940000,
			"n": 1
		  },
		  {
			"v": 8680,
			"vw": 22.0917,
			"o": 22.0968,
			"c": 22.0893,
			"h": 22.0968,
			"l": 22.0893,
			"t": 1344426000000,
			"n": 2
		  },
		  {
			"v": 9716,
			"vw": 22.0891,
			"o": 22.0879,
			"c": 22.0896,
			"h": 22.0896,
			"l": 22.0879,
			"t": 1344426120000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0964,
			"o": 22.0964,
			"c": 22.0964,
			"h": 22.0964,
			"l": 22.0964,
			"t": 1344426720000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0911,
			"o": 22.0911,
			"c": 22.0911,
			"h": 22.0911,
			"l": 22.0911,
			"t": 1344426780000,
			"n": 1
		  },
		  {
			"v": 25200,
			"vw": 22.1207,
			"o": 22.1182,
			"c": 22.1214,
			"h": 22.1214,
			"l": 22.1182,
			"t": 1344426900000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 22.1071,
			"o": 22.1071,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1071,
			"t": 1344427080000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.1407,
			"o": 22.1504,
			"c": 22.1214,
			"h": 22.1504,
			"l": 22.1214,
			"t": 1344427200000,
			"n": 3
		  },
		  {
			"v": 33600,
			"vw": 22.1071,
			"o": 22.1071,
			"c": 22.1064,
			"h": 22.1071,
			"l": 22.1064,
			"t": 1344427260000,
			"n": 4
		  },
		  {
			"v": 41636,
			"vw": 22.1072,
			"o": 22.1075,
			"c": 22.1071,
			"h": 22.1075,
			"l": 22.1071,
			"t": 1344427320000,
			"n": 6
		  },
		  {
			"v": 36260,
			"vw": 22.1088,
			"o": 22.1093,
			"c": 22.1143,
			"h": 22.1143,
			"l": 22.1071,
			"t": 1344427380000,
			"n": 6
		  },
		  {
			"v": 72212,
			"vw": 22.107,
			"o": 22.1071,
			"c": 22.1036,
			"h": 22.1071,
			"l": 22.1036,
			"t": 1344427440000,
			"n": 6
		  },
		  {
			"v": 5180,
			"vw": 22.0929,
			"o": 22.0929,
			"c": 22.0929,
			"h": 22.0929,
			"l": 22.0929,
			"t": 1344427560000,
			"n": 1
		  },
		  {
			"v": 25200,
			"vw": 22.1042,
			"o": 22.1018,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1018,
			"t": 1344427620000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.1011,
			"o": 22.1036,
			"c": 22.0986,
			"h": 22.1036,
			"l": 22.0986,
			"t": 1344427680000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0964,
			"o": 22.0964,
			"c": 22.0964,
			"h": 22.0964,
			"l": 22.0964,
			"t": 1344427740000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0936,
			"o": 22.0936,
			"c": 22.0936,
			"h": 22.0936,
			"l": 22.0936,
			"t": 1344427800000,
			"n": 2
		  },
		  {
			"v": 13244,
			"vw": 22.094,
			"o": 22.0964,
			"c": 22.0929,
			"h": 22.0964,
			"l": 22.0929,
			"t": 1344427860000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0929,
			"o": 22.0929,
			"c": 22.0929,
			"h": 22.0929,
			"l": 22.0929,
			"t": 1344427980000,
			"n": 1
		  },
		  {
			"v": 64400,
			"vw": 22.0874,
			"o": 22.0893,
			"c": 22.0857,
			"h": 22.0893,
			"l": 22.0857,
			"t": 1344428040000,
			"n": 10
		  },
		  {
			"v": 5600,
			"vw": 22.0891,
			"o": 22.0832,
			"c": 22.095,
			"h": 22.095,
			"l": 22.0832,
			"t": 1344428160000,
			"n": 2
		  },
		  {
			"v": 6300,
			"vw": 22.0796,
			"o": 22.0789,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0789,
			"t": 1344428220000,
			"n": 2
		  },
		  {
			"v": 5292,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344428280000,
			"n": 1
		  },
		  {
			"v": 25200,
			"vw": 22.0867,
			"o": 22.0839,
			"c": 22.0868,
			"h": 22.0889,
			"l": 22.0839,
			"t": 1344428340000,
			"n": 9
		  },
		  {
			"v": 2800,
			"vw": 22.0868,
			"o": 22.0868,
			"c": 22.0868,
			"h": 22.0868,
			"l": 22.0868,
			"t": 1344428400000,
			"n": 1
		  },
		  {
			"v": 20440,
			"vw": 22.0849,
			"o": 22.0839,
			"c": 22.0839,
			"h": 22.0868,
			"l": 22.0839,
			"t": 1344428460000,
			"n": 4
		  },
		  {
			"v": 19600,
			"vw": 22.083,
			"o": 22.0839,
			"c": 22.0864,
			"h": 22.0864,
			"l": 22.0821,
			"t": 1344428520000,
			"n": 4
		  },
		  {
			"v": 19600,
			"vw": 22.0799,
			"o": 22.0821,
			"c": 22.0786,
			"h": 22.0821,
			"l": 22.0786,
			"t": 1344428580000,
			"n": 6
		  },
		  {
			"v": 8400,
			"vw": 22.0786,
			"o": 22.0786,
			"c": 22.0786,
			"h": 22.0786,
			"l": 22.0786,
			"t": 1344428640000,
			"n": 2
		  },
		  {
			"v": 18312,
			"vw": 22.0847,
			"o": 22.0821,
			"c": 22.0868,
			"h": 22.0868,
			"l": 22.0821,
			"t": 1344428700000,
			"n": 6
		  },
		  {
			"v": 5600,
			"vw": 22.0775,
			"o": 22.0775,
			"c": 22.0775,
			"h": 22.0775,
			"l": 22.0775,
			"t": 1344428760000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0804,
			"o": 22.0804,
			"c": 22.0804,
			"h": 22.0804,
			"l": 22.0804,
			"t": 1344428820000,
			"n": 1
		  },
		  {
			"v": 13160,
			"vw": 22.0819,
			"o": 22.0821,
			"c": 22.0829,
			"h": 22.0829,
			"l": 22.0811,
			"t": 1344429000000,
			"n": 3
		  },
		  {
			"v": 13804,
			"vw": 22.0804,
			"o": 22.0804,
			"c": 22.0804,
			"h": 22.0807,
			"l": 22.0804,
			"t": 1344429060000,
			"n": 4
		  },
		  {
			"v": 61600,
			"vw": 22.0825,
			"o": 22.0779,
			"c": 22.0868,
			"h": 22.0868,
			"l": 22.0779,
			"t": 1344429120000,
			"n": 13
		  },
		  {
			"v": 42000,
			"vw": 22.0899,
			"o": 22.0832,
			"c": 22.0893,
			"h": 22.0968,
			"l": 22.0832,
			"t": 1344429180000,
			"n": 11
		  },
		  {
			"v": 4900,
			"vw": 22.0964,
			"o": 22.0964,
			"c": 22.0964,
			"h": 22.0964,
			"l": 22.0964,
			"t": 1344429240000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0989,
			"o": 22.1007,
			"c": 22.0946,
			"h": 22.1014,
			"l": 22.0946,
			"t": 1344429300000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.1,
			"o": 22.1004,
			"c": 22.1,
			"h": 22.1011,
			"l": 22.0975,
			"t": 1344429360000,
			"n": 5
		  },
		  {
			"v": 35000,
			"vw": 22.1043,
			"o": 22.0993,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.0993,
			"t": 1344429420000,
			"n": 10
		  },
		  {
			"v": 8400,
			"vw": 22.1096,
			"o": 22.1096,
			"c": 22.1096,
			"h": 22.1096,
			"l": 22.1096,
			"t": 1344429480000,
			"n": 3
		  },
		  {
			"v": 4200,
			"vw": 22.1143,
			"o": 22.1143,
			"c": 22.1143,
			"h": 22.1143,
			"l": 22.1143,
			"t": 1344429540000,
			"n": 1
		  },
		  {
			"v": 90272,
			"vw": 22.1082,
			"o": 22.1132,
			"c": 22.1054,
			"h": 22.1132,
			"l": 22.1054,
			"t": 1344429600000,
			"n": 12
		  },
		  {
			"v": 33600,
			"vw": 22.1091,
			"o": 22.0986,
			"c": 22.1071,
			"h": 22.1161,
			"l": 22.0986,
			"t": 1344429660000,
			"n": 8
		  },
		  {
			"v": 9352,
			"vw": 22.1071,
			"o": 22.1071,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1071,
			"t": 1344429720000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1125,
			"o": 22.1125,
			"c": 22.1125,
			"h": 22.1125,
			"l": 22.1125,
			"t": 1344429900000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.1098,
			"o": 22.1125,
			"c": 22.1079,
			"h": 22.1125,
			"l": 22.1079,
			"t": 1344430140000,
			"n": 3
		  },
		  {
			"v": 44800,
			"vw": 22.1073,
			"o": 22.1125,
			"c": 22.1036,
			"h": 22.1125,
			"l": 22.1036,
			"t": 1344430200000,
			"n": 11
		  },
		  {
			"v": 14000,
			"vw": 22.1061,
			"o": 22.1036,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1036,
			"t": 1344430260000,
			"n": 5
		  },
		  {
			"v": 6188,
			"vw": 22.1053,
			"o": 22.1068,
			"c": 22.1036,
			"h": 22.1068,
			"l": 22.1036,
			"t": 1344430320000,
			"n": 2
		  },
		  {
			"v": 76188,
			"vw": 22.0984,
			"o": 22.1007,
			"c": 22.095,
			"h": 22.1007,
			"l": 22.095,
			"t": 1344430380000,
			"n": 10
		  },
		  {
			"v": 42000,
			"vw": 22.0965,
			"o": 22.0968,
			"c": 22.0982,
			"h": 22.0982,
			"l": 22.0939,
			"t": 1344430500000,
			"n": 10
		  },
		  {
			"v": 53004,
			"vw": 22.0981,
			"o": 22.0968,
			"c": 22.0982,
			"h": 22.0982,
			"l": 22.0968,
			"t": 1344430560000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0989,
			"o": 22.0989,
			"c": 22.0989,
			"h": 22.0989,
			"l": 22.0989,
			"t": 1344430740000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1018,
			"o": 22.1018,
			"c": 22.1018,
			"h": 22.1018,
			"l": 22.1018,
			"t": 1344430800000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1014,
			"o": 22.1014,
			"c": 22.1014,
			"h": 22.1014,
			"l": 22.1014,
			"t": 1344430860000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1071,
			"o": 22.1071,
			"c": 22.1071,
			"h": 22.1071,
			"l": 22.1071,
			"t": 1344430920000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 22.1031,
			"o": 22.1071,
			"c": 22.1004,
			"h": 22.1104,
			"l": 22.1,
			"t": 1344430980000,
			"n": 8
		  },
		  {
			"v": 65408,
			"vw": 22.1149,
			"o": 22.1061,
			"c": 22.1229,
			"h": 22.1264,
			"l": 22.1036,
			"t": 1344431100000,
			"n": 18
		  },
		  {
			"v": 8400,
			"vw": 22.1127,
			"o": 22.1114,
			"c": 22.1139,
			"h": 22.1139,
			"l": 22.1114,
			"t": 1344431220000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.1207,
			"o": 22.12,
			"c": 22.1214,
			"h": 22.1214,
			"l": 22.12,
			"t": 1344431280000,
			"n": 4
		  },
		  {
			"v": 56280,
			"vw": 22.1286,
			"o": 22.1225,
			"c": 22.1275,
			"h": 22.1368,
			"l": 22.1225,
			"t": 1344431340000,
			"n": 17
		  },
		  {
			"v": 29456,
			"vw": 22.1351,
			"o": 22.1293,
			"c": 22.1339,
			"h": 22.1368,
			"l": 22.1293,
			"t": 1344431400000,
			"n": 9
		  },
		  {
			"v": 11200,
			"vw": 22.1338,
			"o": 22.1375,
			"c": 22.1304,
			"h": 22.1375,
			"l": 22.1304,
			"t": 1344431460000,
			"n": 4
		  },
		  {
			"v": 8400,
			"vw": 22.1302,
			"o": 22.1304,
			"c": 22.13,
			"h": 22.1304,
			"l": 22.13,
			"t": 1344431520000,
			"n": 3
		  },
		  {
			"v": 36960,
			"vw": 22.1251,
			"o": 22.1261,
			"c": 22.1236,
			"h": 22.1268,
			"l": 22.1236,
			"t": 1344431580000,
			"n": 11
		  },
		  {
			"v": 9772,
			"vw": 22.124,
			"o": 22.1236,
			"c": 22.125,
			"h": 22.125,
			"l": 22.1236,
			"t": 1344431640000,
			"n": 3
		  },
		  {
			"v": 25200,
			"vw": 22.1283,
			"o": 22.1271,
			"c": 22.1336,
			"h": 22.1336,
			"l": 22.1271,
			"t": 1344431700000,
			"n": 5
		  },
		  {
			"v": 16128,
			"vw": 22.1331,
			"o": 22.1339,
			"c": 22.1321,
			"h": 22.1339,
			"l": 22.1321,
			"t": 1344431760000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1254,
			"o": 22.1254,
			"c": 22.1254,
			"h": 22.1254,
			"l": 22.1254,
			"t": 1344431820000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1249,
			"o": 22.1254,
			"c": 22.125,
			"h": 22.1254,
			"l": 22.1243,
			"t": 1344431940000,
			"n": 4
		  },
		  {
			"v": 8904,
			"vw": 22.1267,
			"o": 22.1304,
			"c": 22.1246,
			"h": 22.1304,
			"l": 22.1246,
			"t": 1344432000000,
			"n": 3
		  },
		  {
			"v": 30520,
			"vw": 22.1246,
			"o": 22.1211,
			"c": 22.125,
			"h": 22.125,
			"l": 22.1211,
			"t": 1344432060000,
			"n": 5
		  },
		  {
			"v": 61656,
			"vw": 22.1319,
			"o": 22.1282,
			"c": 22.1357,
			"h": 22.1357,
			"l": 22.1282,
			"t": 1344432120000,
			"n": 13
		  },
		  {
			"v": 11200,
			"vw": 22.1389,
			"o": 22.1379,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1379,
			"t": 1344432240000,
			"n": 3
		  },
		  {
			"v": 30632,
			"vw": 22.1328,
			"o": 22.1357,
			"c": 22.1254,
			"h": 22.1364,
			"l": 22.125,
			"t": 1344432300000,
			"n": 9
		  },
		  {
			"v": 19040,
			"vw": 22.1339,
			"o": 22.1189,
			"c": 22.1389,
			"h": 22.1389,
			"l": 22.1189,
			"t": 1344432360000,
			"n": 4
		  },
		  {
			"v": 18480,
			"vw": 22.119,
			"o": 22.1186,
			"c": 22.1161,
			"h": 22.125,
			"l": 22.1161,
			"t": 1344432420000,
			"n": 6
		  },
		  {
			"v": 75964,
			"vw": 22.1143,
			"o": 22.1164,
			"c": 22.1107,
			"h": 22.1186,
			"l": 22.11,
			"t": 1344432480000,
			"n": 14
		  },
		  {
			"v": 164052,
			"vw": 22.1057,
			"o": 22.1075,
			"c": 22.1071,
			"h": 22.1104,
			"l": 22.1032,
			"t": 1344432540000,
			"n": 25
		  },
		  {
			"v": 4043032,
			"vw": 22.1141,
			"o": 22.1211,
			"c": 22.12,
			"h": 22.1339,
			"l": 22.1071,
			"t": 1344432600000,
			"n": 510
		  },
		  {
			"v": 1763328,
			"vw": 22.0913,
			"o": 22.1196,
			"c": 22.0843,
			"h": 22.1204,
			"l": 22.0714,
			"t": 1344432660000,
			"n": 376
		  },
		  {
			"v": 2625336,
			"vw": 22.0692,
			"o": 22.0886,
			"c": 22.0629,
			"h": 22.1189,
			"l": 22.0557,
			"t": 1344432720000,
			"n": 508
		  },
		  {
			"v": 2951648,
			"vw": 22.0508,
			"o": 22.0628,
			"c": 22.0465,
			"h": 22.0643,
			"l": 22.0393,
			"t": 1344432780000,
			"n": 581
		  },
		  {
			"v": 2233224,
			"vw": 22.0722,
			"o": 22.0486,
			"c": 22.0786,
			"h": 22.0918,
			"l": 22.0482,
			"t": 1344432840000,
			"n": 523
		  },
		  {
			"v": 1384040,
			"vw": 22.0914,
			"o": 22.0821,
			"c": 22.0982,
			"h": 22.1032,
			"l": 22.0761,
			"t": 1344432900000,
			"n": 271
		  },
		  {
			"v": 2429476,
			"vw": 22.1131,
			"o": 22.0977,
			"c": 22.1114,
			"h": 22.1429,
			"l": 22.0879,
			"t": 1344432960000,
			"n": 512
		  },
		  {
			"v": 1154608,
			"vw": 22.1268,
			"o": 22.1153,
			"c": 22.1375,
			"h": 22.1429,
			"l": 22.1075,
			"t": 1344433020000,
			"n": 251
		  },
		  {
			"v": 2186156,
			"vw": 22.1531,
			"o": 22.1414,
			"c": 22.1671,
			"h": 22.1696,
			"l": 22.1268,
			"t": 1344433080000,
			"n": 503
		  },
		  {
			"v": 2110024,
			"vw": 22.1754,
			"o": 22.1686,
			"c": 22.1729,
			"h": 22.1921,
			"l": 22.16,
			"t": 1344433140000,
			"n": 494
		  },
		  {
			"v": 1011668,
			"vw": 22.1775,
			"o": 22.17,
			"c": 22.1714,
			"h": 22.1875,
			"l": 22.1654,
			"t": 1344433200000,
			"n": 233
		  },
		  {
			"v": 1193780,
			"vw": 22.1689,
			"o": 22.1779,
			"c": 22.1543,
			"h": 22.1911,
			"l": 22.15,
			"t": 1344433260000,
			"n": 229
		  },
		  {
			"v": 1172220,
			"vw": 22.1574,
			"o": 22.1518,
			"c": 22.1511,
			"h": 22.1739,
			"l": 22.1511,
			"t": 1344433320000,
			"n": 209
		  },
		  {
			"v": 997332,
			"vw": 22.1446,
			"o": 22.15,
			"c": 22.1429,
			"h": 22.1518,
			"l": 22.1321,
			"t": 1344433380000,
			"n": 246
		  },
		  {
			"v": 397376,
			"vw": 22.1504,
			"o": 22.1439,
			"c": 22.1514,
			"h": 22.1571,
			"l": 22.1429,
			"t": 1344433440000,
			"n": 88
		  },
		  {
			"v": 767928,
			"vw": 22.1516,
			"o": 22.1482,
			"c": 22.1535,
			"h": 22.1571,
			"l": 22.1429,
			"t": 1344433500000,
			"n": 167
		  },
		  {
			"v": 920304,
			"vw": 22.136,
			"o": 22.1518,
			"c": 22.125,
			"h": 22.152,
			"l": 22.1196,
			"t": 1344433560000,
			"n": 217
		  },
		  {
			"v": 790804,
			"vw": 22.1268,
			"o": 22.125,
			"c": 22.1218,
			"h": 22.1325,
			"l": 22.1204,
			"t": 1344433620000,
			"n": 168
		  },
		  {
			"v": 1348732,
			"vw": 22.1076,
			"o": 22.1214,
			"c": 22.1157,
			"h": 22.1214,
			"l": 22.0893,
			"t": 1344433680000,
			"n": 294
		  },
		  {
			"v": 589036,
			"vw": 22.1223,
			"o": 22.1171,
			"c": 22.1239,
			"h": 22.1282,
			"l": 22.1127,
			"t": 1344433740000,
			"n": 126
		  },
		  {
			"v": 356188,
			"vw": 22.1181,
			"o": 22.1207,
			"c": 22.1239,
			"h": 22.1239,
			"l": 22.1114,
			"t": 1344433800000,
			"n": 103
		  },
		  {
			"v": 1732920,
			"vw": 22.1345,
			"o": 22.125,
			"c": 22.1468,
			"h": 22.1489,
			"l": 22.1143,
			"t": 1344433860000,
			"n": 386
		  },
		  {
			"v": 1110760,
			"vw": 22.1454,
			"o": 22.1457,
			"c": 22.1357,
			"h": 22.1525,
			"l": 22.1311,
			"t": 1344433920000,
			"n": 243
		  },
		  {
			"v": 309960,
			"vw": 22.1388,
			"o": 22.1396,
			"c": 22.1339,
			"h": 22.1436,
			"l": 22.1339,
			"t": 1344433980000,
			"n": 92
		  },
		  {
			"v": 696500,
			"vw": 22.1374,
			"o": 22.1339,
			"c": 22.1455,
			"h": 22.1492,
			"l": 22.1296,
			"t": 1344434040000,
			"n": 126
		  },
		  {
			"v": 302008,
			"vw": 22.136,
			"o": 22.1492,
			"c": 22.1339,
			"h": 22.1492,
			"l": 22.1289,
			"t": 1344434100000,
			"n": 88
		  },
		  {
			"v": 496748,
			"vw": 22.1363,
			"o": 22.1339,
			"c": 22.1339,
			"h": 22.1486,
			"l": 22.13,
			"t": 1344434160000,
			"n": 131
		  },
		  {
			"v": 1774976,
			"vw": 22.1205,
			"o": 22.1386,
			"c": 22.1039,
			"h": 22.1386,
			"l": 22.1039,
			"t": 1344434220000,
			"n": 425
		  },
		  {
			"v": 763784,
			"vw": 22.106,
			"o": 22.1039,
			"c": 22.1043,
			"h": 22.1089,
			"l": 22.0993,
			"t": 1344434280000,
			"n": 184
		  },
		  {
			"v": 860272,
			"vw": 22.1064,
			"o": 22.1046,
			"c": 22.1064,
			"h": 22.1142,
			"l": 22.0979,
			"t": 1344434340000,
			"n": 204
		  },
		  {
			"v": 676536,
			"vw": 22.1069,
			"o": 22.1064,
			"c": 22.1121,
			"h": 22.1157,
			"l": 22.0971,
			"t": 1344434400000,
			"n": 174
		  },
		  {
			"v": 460628,
			"vw": 22.1117,
			"o": 22.1114,
			"c": 22.1029,
			"h": 22.1196,
			"l": 22.1029,
			"t": 1344434460000,
			"n": 116
		  },
		  {
			"v": 1054816,
			"vw": 22.0938,
			"o": 22.105,
			"c": 22.096,
			"h": 22.1054,
			"l": 22.0846,
			"t": 1344434520000,
			"n": 274
		  },
		  {
			"v": 695772,
			"vw": 22.0916,
			"o": 22.0922,
			"c": 22.09,
			"h": 22.0996,
			"l": 22.0893,
			"t": 1344434580000,
			"n": 172
		  },
		  {
			"v": 815752,
			"vw": 22.1018,
			"o": 22.09,
			"c": 22.1061,
			"h": 22.1143,
			"l": 22.0882,
			"t": 1344434640000,
			"n": 196
		  },
		  {
			"v": 332332,
			"vw": 22.1039,
			"o": 22.1071,
			"c": 22.1004,
			"h": 22.1079,
			"l": 22.0975,
			"t": 1344434700000,
			"n": 85
		  },
		  {
			"v": 958804,
			"vw": 22.0898,
			"o": 22.1018,
			"c": 22.0946,
			"h": 22.1046,
			"l": 22.0821,
			"t": 1344434760000,
			"n": 242
		  },
		  {
			"v": 507808,
			"vw": 22.0968,
			"o": 22.0929,
			"c": 22.1065,
			"h": 22.1071,
			"l": 22.09,
			"t": 1344434820000,
			"n": 119
		  },
		  {
			"v": 251160,
			"vw": 22.101,
			"o": 22.1028,
			"c": 22.0949,
			"h": 22.1039,
			"l": 22.0949,
			"t": 1344434880000,
			"n": 69
		  },
		  {
			"v": 545888,
			"vw": 22.0967,
			"o": 22.0943,
			"c": 22.0989,
			"h": 22.1,
			"l": 22.0943,
			"t": 1344434940000,
			"n": 134
		  },
		  {
			"v": 483952,
			"vw": 22.1013,
			"o": 22.0982,
			"c": 22.1055,
			"h": 22.1068,
			"l": 22.0946,
			"t": 1344435000000,
			"n": 114
		  },
		  {
			"v": 803936,
			"vw": 22.1136,
			"o": 22.1067,
			"c": 22.1282,
			"h": 22.1282,
			"l": 22.1054,
			"t": 1344435060000,
			"n": 179
		  },
		  {
			"v": 616000,
			"vw": 22.1233,
			"o": 22.1271,
			"c": 22.1186,
			"h": 22.1282,
			"l": 22.1161,
			"t": 1344435120000,
			"n": 133
		  },
		  {
			"v": 417508,
			"vw": 22.1209,
			"o": 22.1186,
			"c": 22.1171,
			"h": 22.1243,
			"l": 22.1157,
			"t": 1344435180000,
			"n": 119
		  },
		  {
			"v": 383236,
			"vw": 22.1181,
			"o": 22.1196,
			"c": 22.1225,
			"h": 22.1225,
			"l": 22.1146,
			"t": 1344435240000,
			"n": 93
		  },
		  {
			"v": 457604,
			"vw": 22.1241,
			"o": 22.1225,
			"c": 22.1286,
			"h": 22.1286,
			"l": 22.1164,
			"t": 1344435300000,
			"n": 106
		  },
		  {
			"v": 879088,
			"vw": 22.1289,
			"o": 22.1271,
			"c": 22.1279,
			"h": 22.1332,
			"l": 22.1214,
			"t": 1344435360000,
			"n": 201
		  },
		  {
			"v": 218064,
			"vw": 22.1257,
			"o": 22.1278,
			"c": 22.1279,
			"h": 22.13,
			"l": 22.1225,
			"t": 1344435420000,
			"n": 54
		  },
		  {
			"v": 297220,
			"vw": 22.1209,
			"o": 22.1282,
			"c": 22.1228,
			"h": 22.1296,
			"l": 22.1179,
			"t": 1344435480000,
			"n": 65
		  },
		  {
			"v": 732284,
			"vw": 22.1164,
			"o": 22.1201,
			"c": 22.1094,
			"h": 22.1201,
			"l": 22.1089,
			"t": 1344435540000,
			"n": 140
		  },
		  {
			"v": 259280,
			"vw": 22.1161,
			"o": 22.1121,
			"c": 22.1158,
			"h": 22.1225,
			"l": 22.1093,
			"t": 1344435600000,
			"n": 80
		  },
		  {
			"v": 428596,
			"vw": 22.1135,
			"o": 22.1147,
			"c": 22.1114,
			"h": 22.1225,
			"l": 22.1082,
			"t": 1344435660000,
			"n": 110
		  },
		  {
			"v": 258888,
			"vw": 22.1158,
			"o": 22.1143,
			"c": 22.1219,
			"h": 22.1221,
			"l": 22.1107,
			"t": 1344435720000,
			"n": 46
		  },
		  {
			"v": 304612,
			"vw": 22.1119,
			"o": 22.1175,
			"c": 22.1089,
			"h": 22.1214,
			"l": 22.1071,
			"t": 1344435780000,
			"n": 80
		  },
		  {
			"v": 325444,
			"vw": 22.1095,
			"o": 22.1079,
			"c": 22.1043,
			"h": 22.1136,
			"l": 22.1029,
			"t": 1344435840000,
			"n": 84
		  },
		  {
			"v": 394240,
			"vw": 22.1013,
			"o": 22.1039,
			"c": 22.0983,
			"h": 22.1064,
			"l": 22.0954,
			"t": 1344435900000,
			"n": 89
		  },
		  {
			"v": 257712,
			"vw": 22.0974,
			"o": 22.1,
			"c": 22.0989,
			"h": 22.1021,
			"l": 22.0929,
			"t": 1344435960000,
			"n": 67
		  },
		  {
			"v": 424200,
			"vw": 22.0998,
			"o": 22.098,
			"c": 22.1045,
			"h": 22.1071,
			"l": 22.0934,
			"t": 1344436020000,
			"n": 104
		  },
		  {
			"v": 196924,
			"vw": 22.1068,
			"o": 22.1061,
			"c": 22.1107,
			"h": 22.1111,
			"l": 22.1033,
			"t": 1344436080000,
			"n": 49
		  },
		  {
			"v": 539448,
			"vw": 22.1107,
			"o": 22.1075,
			"c": 22.1132,
			"h": 22.1139,
			"l": 22.1043,
			"t": 1344436140000,
			"n": 137
		  },
		  {
			"v": 1139488,
			"vw": 22.1323,
			"o": 22.1132,
			"c": 22.1021,
			"h": 22.1429,
			"l": 22.1018,
			"t": 1344436200000,
			"n": 110
		  },
		  {
			"v": 426972,
			"vw": 22.1068,
			"o": 22.1018,
			"c": 22.1066,
			"h": 22.1146,
			"l": 22.0989,
			"t": 1344436260000,
			"n": 99
		  },
		  {
			"v": 571508,
			"vw": 22.1015,
			"o": 22.1043,
			"c": 22.1021,
			"h": 22.1086,
			"l": 22.0971,
			"t": 1344436320000,
			"n": 112
		  },
		  {
			"v": 444668,
			"vw": 22.0976,
			"o": 22.1035,
			"c": 22.1004,
			"h": 22.1035,
			"l": 22.0929,
			"t": 1344436380000,
			"n": 90
		  },
		  {
			"v": 714868,
			"vw": 22.0918,
			"o": 22.0972,
			"c": 22.0868,
			"h": 22.1025,
			"l": 22.0861,
			"t": 1344436440000,
			"n": 153
		  },
		  {
			"v": 785680,
			"vw": 22.0914,
			"o": 22.0868,
			"c": 22.0971,
			"h": 22.1007,
			"l": 22.0861,
			"t": 1344436500000,
			"n": 172
		  },
		  {
			"v": 455616,
			"vw": 22.088,
			"o": 22.1,
			"c": 22.085,
			"h": 22.1,
			"l": 22.0804,
			"t": 1344436560000,
			"n": 109
		  },
		  {
			"v": 410088,
			"vw": 22.0824,
			"o": 22.085,
			"c": 22.0768,
			"h": 22.0891,
			"l": 22.0768,
			"t": 1344436620000,
			"n": 96
		  },
		  {
			"v": 1095668,
			"vw": 22.0735,
			"o": 22.0768,
			"c": 22.0807,
			"h": 22.0821,
			"l": 22.0714,
			"t": 1344436680000,
			"n": 229
		  },
		  {
			"v": 430164,
			"vw": 22.0804,
			"o": 22.08,
			"c": 22.0838,
			"h": 22.0866,
			"l": 22.0746,
			"t": 1344436740000,
			"n": 103
		  },
		  {
			"v": 773864,
			"vw": 22.0882,
			"o": 22.0832,
			"c": 22.1021,
			"h": 22.1021,
			"l": 22.0804,
			"t": 1344436800000,
			"n": 148
		  },
		  {
			"v": 342860,
			"vw": 22.098,
			"o": 22.1025,
			"c": 22.0982,
			"h": 22.1025,
			"l": 22.0929,
			"t": 1344436860000,
			"n": 85
		  },
		  {
			"v": 714728,
			"vw": 22.1033,
			"o": 22.0993,
			"c": 22.1068,
			"h": 22.1086,
			"l": 22.0971,
			"t": 1344436920000,
			"n": 168
		  },
		  {
			"v": 1073660,
			"vw": 22.1149,
			"o": 22.1089,
			"c": 22.12,
			"h": 22.1207,
			"l": 22.1046,
			"t": 1344436980000,
			"n": 254
		  },
		  {
			"v": 284312,
			"vw": 22.1135,
			"o": 22.1189,
			"c": 22.1093,
			"h": 22.1189,
			"l": 22.1071,
			"t": 1344437040000,
			"n": 65
		  },
		  {
			"v": 426944,
			"vw": 22.112,
			"o": 22.1093,
			"c": 22.1114,
			"h": 22.1135,
			"l": 22.1075,
			"t": 1344437100000,
			"n": 85
		  },
		  {
			"v": 517048,
			"vw": 22.1095,
			"o": 22.1082,
			"c": 22.1101,
			"h": 22.1132,
			"l": 22.1043,
			"t": 1344437160000,
			"n": 127
		  },
		  {
			"v": 935536,
			"vw": 22.1065,
			"o": 22.1096,
			"c": 22.11,
			"h": 22.1125,
			"l": 22.1007,
			"t": 1344437220000,
			"n": 108
		  },
		  {
			"v": 812140,
			"vw": 22.1044,
			"o": 22.1086,
			"c": 22.1071,
			"h": 22.11,
			"l": 22.1011,
			"t": 1344437280000,
			"n": 82
		  },
		  {
			"v": 534660,
			"vw": 22.1125,
			"o": 22.1056,
			"c": 22.1179,
			"h": 22.1182,
			"l": 22.1056,
			"t": 1344437340000,
			"n": 116
		  },
		  {
			"v": 508480,
			"vw": 22.1197,
			"o": 22.1146,
			"c": 22.1221,
			"h": 22.1221,
			"l": 22.1125,
			"t": 1344437400000,
			"n": 111
		  },
		  {
			"v": 257880,
			"vw": 22.117,
			"o": 22.1199,
			"c": 22.1154,
			"h": 22.1214,
			"l": 22.1129,
			"t": 1344437460000,
			"n": 68
		  },
		  {
			"v": 1387820,
			"vw": 22.1243,
			"o": 22.1182,
			"c": 22.125,
			"h": 22.1311,
			"l": 22.1104,
			"t": 1344437520000,
			"n": 271
		  },
		  {
			"v": 647780,
			"vw": 22.1314,
			"o": 22.1307,
			"c": 22.1336,
			"h": 22.1336,
			"l": 22.1261,
			"t": 1344437580000,
			"n": 147
		  },
		  {
			"v": 1186696,
			"vw": 22.1351,
			"o": 22.1336,
			"c": 22.1364,
			"h": 22.1371,
			"l": 22.1301,
			"t": 1344437640000,
			"n": 268
		  },
		  {
			"v": 642964,
			"vw": 22.1362,
			"o": 22.1368,
			"c": 22.1361,
			"h": 22.1375,
			"l": 22.1343,
			"t": 1344437700000,
			"n": 176
		  },
		  {
			"v": 978824,
			"vw": 22.1368,
			"o": 22.1354,
			"c": 22.1325,
			"h": 22.1429,
			"l": 22.1318,
			"t": 1344437760000,
			"n": 186
		  },
		  {
			"v": 458164,
			"vw": 22.1336,
			"o": 22.1325,
			"c": 22.1311,
			"h": 22.1371,
			"l": 22.1296,
			"t": 1344437820000,
			"n": 115
		  },
		  {
			"v": 743036,
			"vw": 22.1286,
			"o": 22.1325,
			"c": 22.1254,
			"h": 22.1325,
			"l": 22.1254,
			"t": 1344437880000,
			"n": 135
		  },
		  {
			"v": 291452,
			"vw": 22.125,
			"o": 22.1261,
			"c": 22.1246,
			"h": 22.1282,
			"l": 22.1218,
			"t": 1344437940000,
			"n": 81
		  },
		  {
			"v": 801864,
			"vw": 22.1387,
			"o": 22.1279,
			"c": 22.1257,
			"h": 22.1429,
			"l": 22.1246,
			"t": 1344438000000,
			"n": 64
		  },
		  {
			"v": 173600,
			"vw": 22.1284,
			"o": 22.1261,
			"c": 22.1304,
			"h": 22.1318,
			"l": 22.1257,
			"t": 1344438060000,
			"n": 50
		  },
		  {
			"v": 319704,
			"vw": 22.1261,
			"o": 22.1282,
			"c": 22.1214,
			"h": 22.1304,
			"l": 22.1211,
			"t": 1344438120000,
			"n": 84
		  },
		  {
			"v": 838600,
			"vw": 22.1223,
			"o": 22.1243,
			"c": 22.1241,
			"h": 22.1268,
			"l": 22.1209,
			"t": 1344438180000,
			"n": 69
		  },
		  {
			"v": 425180,
			"vw": 22.1227,
			"o": 22.1264,
			"c": 22.1257,
			"h": 22.1264,
			"l": 22.1182,
			"t": 1344438240000,
			"n": 87
		  },
		  {
			"v": 272608,
			"vw": 22.1251,
			"o": 22.1254,
			"c": 22.1218,
			"h": 22.1293,
			"l": 22.1186,
			"t": 1344438300000,
			"n": 76
		  },
		  {
			"v": 184128,
			"vw": 22.1191,
			"o": 22.1186,
			"c": 22.1215,
			"h": 22.1225,
			"l": 22.1157,
			"t": 1344438360000,
			"n": 50
		  },
		  {
			"v": 228732,
			"vw": 22.1182,
			"o": 22.1214,
			"c": 22.1189,
			"h": 22.1243,
			"l": 22.1146,
			"t": 1344438420000,
			"n": 62
		  },
		  {
			"v": 283220,
			"vw": 22.124,
			"o": 22.1169,
			"c": 22.125,
			"h": 22.1268,
			"l": 22.1169,
			"t": 1344438480000,
			"n": 63
		  },
		  {
			"v": 319088,
			"vw": 22.1216,
			"o": 22.1225,
			"c": 22.1186,
			"h": 22.1264,
			"l": 22.1179,
			"t": 1344438540000,
			"n": 92
		  },
		  {
			"v": 469364,
			"vw": 22.1197,
			"o": 22.1182,
			"c": 22.1204,
			"h": 22.1254,
			"l": 22.1146,
			"t": 1344438600000,
			"n": 137
		  },
		  {
			"v": 650160,
			"vw": 22.1257,
			"o": 22.1218,
			"c": 22.1318,
			"h": 22.1318,
			"l": 22.1204,
			"t": 1344438660000,
			"n": 133
		  },
		  {
			"v": 435512,
			"vw": 22.1312,
			"o": 22.1321,
			"c": 22.1338,
			"h": 22.1346,
			"l": 22.1271,
			"t": 1344438720000,
			"n": 115
		  },
		  {
			"v": 802508,
			"vw": 22.1349,
			"o": 22.1344,
			"c": 22.1357,
			"h": 22.1368,
			"l": 22.1314,
			"t": 1344438780000,
			"n": 185
		  },
		  {
			"v": 982744,
			"vw": 22.1373,
			"o": 22.1343,
			"c": 22.1386,
			"h": 22.1396,
			"l": 22.1329,
			"t": 1344438840000,
			"n": 224
		  },
		  {
			"v": 968352,
			"vw": 22.1392,
			"o": 22.1386,
			"c": 22.1388,
			"h": 22.1404,
			"l": 22.1361,
			"t": 1344438900000,
			"n": 247
		  },
		  {
			"v": 680176,
			"vw": 22.1391,
			"o": 22.1382,
			"c": 22.1375,
			"h": 22.1411,
			"l": 22.1361,
			"t": 1344438960000,
			"n": 144
		  },
		  {
			"v": 569716,
			"vw": 22.1384,
			"o": 22.1389,
			"c": 22.1385,
			"h": 22.1411,
			"l": 22.1346,
			"t": 1344439020000,
			"n": 138
		  },
		  {
			"v": 191856,
			"vw": 22.1389,
			"o": 22.1389,
			"c": 22.1379,
			"h": 22.1404,
			"l": 22.1375,
			"t": 1344439080000,
			"n": 50
		  },
		  {
			"v": 5721632,
			"vw": 22.1526,
			"o": 22.1396,
			"c": 22.1725,
			"h": 22.175,
			"l": 22.1386,
			"t": 1344439140000,
			"n": 897
		  },
		  {
			"v": 2649808,
			"vw": 22.1795,
			"o": 22.1736,
			"c": 22.1797,
			"h": 22.191,
			"l": 22.1657,
			"t": 1344439200000,
			"n": 493
		  },
		  {
			"v": 957432,
			"vw": 22.1848,
			"o": 22.1768,
			"c": 22.1907,
			"h": 22.1918,
			"l": 22.1755,
			"t": 1344439260000,
			"n": 233
		  },
		  {
			"v": 958440,
			"vw": 22.1819,
			"o": 22.19,
			"c": 22.1792,
			"h": 22.1925,
			"l": 22.1743,
			"t": 1344439320000,
			"n": 207
		  },
		  {
			"v": 418320,
			"vw": 22.1802,
			"o": 22.1789,
			"c": 22.1853,
			"h": 22.1857,
			"l": 22.1768,
			"t": 1344439380000,
			"n": 96
		  },
		  {
			"v": 357700,
			"vw": 22.1771,
			"o": 22.1834,
			"c": 22.1764,
			"h": 22.1854,
			"l": 22.1714,
			"t": 1344439440000,
			"n": 87
		  },
		  {
			"v": 534772,
			"vw": 22.1783,
			"o": 22.175,
			"c": 22.1754,
			"h": 22.1821,
			"l": 22.1718,
			"t": 1344439500000,
			"n": 130
		  },
		  {
			"v": 221200,
			"vw": 22.1777,
			"o": 22.1764,
			"c": 22.1793,
			"h": 22.1811,
			"l": 22.1736,
			"t": 1344439560000,
			"n": 45
		  },
		  {
			"v": 1170428,
			"vw": 22.1905,
			"o": 22.1796,
			"c": 22.1843,
			"h": 22.2014,
			"l": 22.1779,
			"t": 1344439620000,
			"n": 252
		  },
		  {
			"v": 515984,
			"vw": 22.1912,
			"o": 22.1889,
			"c": 22.1954,
			"h": 22.1964,
			"l": 22.1829,
			"t": 1344439680000,
			"n": 126
		  },
		  {
			"v": 998284,
			"vw": 22.1972,
			"o": 22.1964,
			"c": 22.1984,
			"h": 22.2054,
			"l": 22.1911,
			"t": 1344439740000,
			"n": 179
		  },
		  {
			"v": 360332,
			"vw": 22.1923,
			"o": 22.1996,
			"c": 22.191,
			"h": 22.1996,
			"l": 22.1843,
			"t": 1344439800000,
			"n": 99
		  },
		  {
			"v": 519764,
			"vw": 22.1902,
			"o": 22.19,
			"c": 22.1786,
			"h": 22.1964,
			"l": 22.1786,
			"t": 1344439860000,
			"n": 116
		  },
		  {
			"v": 536312,
			"vw": 22.1806,
			"o": 22.1829,
			"c": 22.1786,
			"h": 22.1868,
			"l": 22.175,
			"t": 1344439920000,
			"n": 110
		  },
		  {
			"v": 192500,
			"vw": 22.1851,
			"o": 22.1811,
			"c": 22.1843,
			"h": 22.1893,
			"l": 22.1793,
			"t": 1344439980000,
			"n": 62
		  },
		  {
			"v": 250740,
			"vw": 22.1827,
			"o": 22.1832,
			"c": 22.185,
			"h": 22.1857,
			"l": 22.1789,
			"t": 1344440040000,
			"n": 62
		  },
		  {
			"v": 538636,
			"vw": 22.1935,
			"o": 22.185,
			"c": 22.1961,
			"h": 22.1964,
			"l": 22.1846,
			"t": 1344440100000,
			"n": 127
		  },
		  {
			"v": 459368,
			"vw": 22.1973,
			"o": 22.1943,
			"c": 22.2011,
			"h": 22.2018,
			"l": 22.1939,
			"t": 1344440160000,
			"n": 89
		  },
		  {
			"v": 266168,
			"vw": 22.1956,
			"o": 22.2018,
			"c": 22.1928,
			"h": 22.2018,
			"l": 22.1908,
			"t": 1344440220000,
			"n": 66
		  },
		  {
			"v": 402108,
			"vw": 22.198,
			"o": 22.1954,
			"c": 22.1964,
			"h": 22.2018,
			"l": 22.1921,
			"t": 1344440280000,
			"n": 92
		  },
		  {
			"v": 465248,
			"vw": 22.1995,
			"o": 22.2,
			"c": 22.1947,
			"h": 22.2054,
			"l": 22.1929,
			"t": 1344440340000,
			"n": 104
		  },
		  {
			"v": 592200,
			"vw": 22.1954,
			"o": 22.1946,
			"c": 22.1952,
			"h": 22.2018,
			"l": 22.1857,
			"t": 1344440400000,
			"n": 150
		  },
		  {
			"v": 371644,
			"vw": 22.1963,
			"o": 22.1968,
			"c": 22.1982,
			"h": 22.1993,
			"l": 22.1939,
			"t": 1344440460000,
			"n": 82
		  },
		  {
			"v": 651364,
			"vw": 22.1916,
			"o": 22.1973,
			"c": 22.1949,
			"h": 22.1973,
			"l": 22.185,
			"t": 1344440520000,
			"n": 111
		  },
		  {
			"v": 298116,
			"vw": 22.193,
			"o": 22.1957,
			"c": 22.1961,
			"h": 22.1964,
			"l": 22.19,
			"t": 1344440580000,
			"n": 53
		  },
		  {
			"v": 153720,
			"vw": 22.1951,
			"o": 22.1954,
			"c": 22.195,
			"h": 22.198,
			"l": 22.1932,
			"t": 1344440640000,
			"n": 31
		  },
		  {
			"v": 460040,
			"vw": 22.2011,
			"o": 22.1982,
			"c": 22.1986,
			"h": 22.2068,
			"l": 22.1943,
			"t": 1344440700000,
			"n": 101
		  },
		  {
			"v": 615440,
			"vw": 22.194,
			"o": 22.1986,
			"c": 22.1971,
			"h": 22.2007,
			"l": 22.1875,
			"t": 1344440760000,
			"n": 129
		  },
		  {
			"v": 599844,
			"vw": 22.1897,
			"o": 22.1911,
			"c": 22.1893,
			"h": 22.195,
			"l": 22.1843,
			"t": 1344440820000,
			"n": 107
		  },
		  {
			"v": 384916,
			"vw": 22.1975,
			"o": 22.1871,
			"c": 22.2004,
			"h": 22.2029,
			"l": 22.1861,
			"t": 1344440880000,
			"n": 109
		  },
		  {
			"v": 348460,
			"vw": 22.1996,
			"o": 22.2025,
			"c": 22.2014,
			"h": 22.2025,
			"l": 22.1929,
			"t": 1344440940000,
			"n": 90
		  },
		  {
			"v": 256032,
			"vw": 22.2019,
			"o": 22.2025,
			"c": 22.2018,
			"h": 22.2036,
			"l": 22.1936,
			"t": 1344441000000,
			"n": 51
		  },
		  {
			"v": 1119524,
			"vw": 22.2088,
			"o": 22.2021,
			"c": 22.2111,
			"h": 22.2136,
			"l": 22.2018,
			"t": 1344441060000,
			"n": 227
		  },
		  {
			"v": 1700860,
			"vw": 22.2172,
			"o": 22.2093,
			"c": 22.2214,
			"h": 22.2218,
			"l": 22.2093,
			"t": 1344441120000,
			"n": 366
		  },
		  {
			"v": 1035356,
			"vw": 22.2213,
			"o": 22.2214,
			"c": 22.2232,
			"h": 22.2264,
			"l": 22.2121,
			"t": 1344441180000,
			"n": 268
		  },
		  {
			"v": 649516,
			"vw": 22.2242,
			"o": 22.2264,
			"c": 22.2279,
			"h": 22.2311,
			"l": 22.2172,
			"t": 1344441240000,
			"n": 160
		  },
		  {
			"v": 898660,
			"vw": 22.2325,
			"o": 22.2286,
			"c": 22.2375,
			"h": 22.2382,
			"l": 22.2239,
			"t": 1344441300000,
			"n": 161
		  },
		  {
			"v": 975660,
			"vw": 22.2286,
			"o": 22.2361,
			"c": 22.2161,
			"h": 22.2386,
			"l": 22.2157,
			"t": 1344441360000,
			"n": 232
		  },
		  {
			"v": 828464,
			"vw": 22.2155,
			"o": 22.2164,
			"c": 22.219,
			"h": 22.2254,
			"l": 22.21,
			"t": 1344441420000,
			"n": 192
		  },
		  {
			"v": 303072,
			"vw": 22.2205,
			"o": 22.2214,
			"c": 22.22,
			"h": 22.2228,
			"l": 22.2175,
			"t": 1344441480000,
			"n": 71
		  },
		  {
			"v": 297892,
			"vw": 22.2204,
			"o": 22.2225,
			"c": 22.2196,
			"h": 22.2232,
			"l": 22.2196,
			"t": 1344441540000,
			"n": 70
		  },
		  {
			"v": 585004,
			"vw": 22.2162,
			"o": 22.2218,
			"c": 22.2121,
			"h": 22.2232,
			"l": 22.2121,
			"t": 1344441600000,
			"n": 123
		  },
		  {
			"v": 753956,
			"vw": 22.2125,
			"o": 22.2121,
			"c": 22.2152,
			"h": 22.2179,
			"l": 22.21,
			"t": 1344441660000,
			"n": 173
		  },
		  {
			"v": 416416,
			"vw": 22.2158,
			"o": 22.2143,
			"c": 22.2196,
			"h": 22.2207,
			"l": 22.2093,
			"t": 1344441720000,
			"n": 107
		  },
		  {
			"v": 401184,
			"vw": 22.2187,
			"o": 22.2171,
			"c": 22.2175,
			"h": 22.2214,
			"l": 22.2161,
			"t": 1344441780000,
			"n": 101
		  },
		  {
			"v": 263060,
			"vw": 22.2172,
			"o": 22.2171,
			"c": 22.2143,
			"h": 22.2196,
			"l": 22.2143,
			"t": 1344441840000,
			"n": 67
		  },
		  {
			"v": 233800,
			"vw": 22.2153,
			"o": 22.2143,
			"c": 22.2171,
			"h": 22.2171,
			"l": 22.2129,
			"t": 1344441900000,
			"n": 52
		  },
		  {
			"v": 525336,
			"vw": 22.2139,
			"o": 22.215,
			"c": 22.2171,
			"h": 22.2171,
			"l": 22.2104,
			"t": 1344441960000,
			"n": 74
		  },
		  {
			"v": 277396,
			"vw": 22.2187,
			"o": 22.215,
			"c": 22.2225,
			"h": 22.2232,
			"l": 22.2143,
			"t": 1344442020000,
			"n": 61
		  },
		  {
			"v": 243684,
			"vw": 22.2191,
			"o": 22.22,
			"c": 22.2195,
			"h": 22.2218,
			"l": 22.2164,
			"t": 1344442080000,
			"n": 46
		  },
		  {
			"v": 682640,
			"vw": 22.2252,
			"o": 22.2193,
			"c": 22.2375,
			"h": 22.2375,
			"l": 22.2186,
			"t": 1344442140000,
			"n": 149
		  },
		  {
			"v": 1356684,
			"vw": 22.2442,
			"o": 22.2371,
			"c": 22.2467,
			"h": 22.25,
			"l": 22.2321,
			"t": 1344442200000,
			"n": 300
		  },
		  {
			"v": 1306396,
			"vw": 22.249,
			"o": 22.2489,
			"c": 22.2491,
			"h": 22.2536,
			"l": 22.2429,
			"t": 1344442260000,
			"n": 242
		  },
		  {
			"v": 1762656,
			"vw": 22.2599,
			"o": 22.2491,
			"c": 22.2661,
			"h": 22.2671,
			"l": 22.2489,
			"t": 1344442320000,
			"n": 376
		  },
		  {
			"v": 703836,
			"vw": 22.2573,
			"o": 22.2655,
			"c": 22.2553,
			"h": 22.2661,
			"l": 22.2507,
			"t": 1344442380000,
			"n": 143
		  },
		  {
			"v": 839384,
			"vw": 22.2516,
			"o": 22.2553,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2454,
			"t": 1344442440000,
			"n": 161
		  },
		  {
			"v": 1237880,
			"vw": 22.2671,
			"o": 22.2573,
			"c": 22.2683,
			"h": 22.2739,
			"l": 22.2573,
			"t": 1344442500000,
			"n": 266
		  },
		  {
			"v": 669872,
			"vw": 22.2624,
			"o": 22.2693,
			"c": 22.2554,
			"h": 22.2725,
			"l": 22.2539,
			"t": 1344442560000,
			"n": 144
		  },
		  {
			"v": 386596,
			"vw": 22.2563,
			"o": 22.258,
			"c": 22.2564,
			"h": 22.2603,
			"l": 22.2532,
			"t": 1344442620000,
			"n": 94
		  },
		  {
			"v": 495824,
			"vw": 22.2564,
			"o": 22.2564,
			"c": 22.2582,
			"h": 22.2611,
			"l": 22.2511,
			"t": 1344442680000,
			"n": 115
		  },
		  {
			"v": 411376,
			"vw": 22.2628,
			"o": 22.2607,
			"c": 22.2671,
			"h": 22.2671,
			"l": 22.2571,
			"t": 1344442740000,
			"n": 106
		  },
		  {
			"v": 440748,
			"vw": 22.2671,
			"o": 22.2625,
			"c": 22.2654,
			"h": 22.2707,
			"l": 22.2625,
			"t": 1344442800000,
			"n": 94
		  },
		  {
			"v": 706468,
			"vw": 22.2708,
			"o": 22.2667,
			"c": 22.275,
			"h": 22.2768,
			"l": 22.264,
			"t": 1344442860000,
			"n": 153
		  },
		  {
			"v": 599648,
			"vw": 22.2694,
			"o": 22.2765,
			"c": 22.2704,
			"h": 22.2768,
			"l": 22.2621,
			"t": 1344442920000,
			"n": 137
		  },
		  {
			"v": 743988,
			"vw": 22.2768,
			"o": 22.2711,
			"c": 22.2721,
			"h": 22.2814,
			"l": 22.2693,
			"t": 1344442980000,
			"n": 146
		  },
		  {
			"v": 415632,
			"vw": 22.2693,
			"o": 22.27,
			"c": 22.2663,
			"h": 22.2749,
			"l": 22.2643,
			"t": 1344443040000,
			"n": 79
		  },
		  {
			"v": 508508,
			"vw": 22.259,
			"o": 22.2661,
			"c": 22.2579,
			"h": 22.2686,
			"l": 22.2536,
			"t": 1344443100000,
			"n": 121
		  },
		  {
			"v": 728196,
			"vw": 22.2547,
			"o": 22.2575,
			"c": 22.2553,
			"h": 22.2579,
			"l": 22.2507,
			"t": 1344443160000,
			"n": 178
		  },
		  {
			"v": 520240,
			"vw": 22.2518,
			"o": 22.2571,
			"c": 22.2502,
			"h": 22.2571,
			"l": 22.2493,
			"t": 1344443220000,
			"n": 101
		  },
		  {
			"v": 575428,
			"vw": 22.2455,
			"o": 22.2493,
			"c": 22.2414,
			"h": 22.2554,
			"l": 22.2414,
			"t": 1344443280000,
			"n": 106
		  },
		  {
			"v": 558516,
			"vw": 22.2407,
			"o": 22.2415,
			"c": 22.2382,
			"h": 22.2446,
			"l": 22.2336,
			"t": 1344443340000,
			"n": 136
		  },
		  {
			"v": 523852,
			"vw": 22.2378,
			"o": 22.2396,
			"c": 22.2396,
			"h": 22.2418,
			"l": 22.234,
			"t": 1344443400000,
			"n": 108
		  },
		  {
			"v": 485100,
			"vw": 22.2417,
			"o": 22.2371,
			"c": 22.2424,
			"h": 22.246,
			"l": 22.2371,
			"t": 1344443460000,
			"n": 111
		  },
		  {
			"v": 193788,
			"vw": 22.239,
			"o": 22.2454,
			"c": 22.2364,
			"h": 22.2454,
			"l": 22.2361,
			"t": 1344443520000,
			"n": 50
		  },
		  {
			"v": 221312,
			"vw": 22.2385,
			"o": 22.2389,
			"c": 22.2384,
			"h": 22.2429,
			"l": 22.2357,
			"t": 1344443580000,
			"n": 57
		  },
		  {
			"v": 357588,
			"vw": 22.2424,
			"o": 22.2391,
			"c": 22.2471,
			"h": 22.2489,
			"l": 22.2368,
			"t": 1344443640000,
			"n": 77
		  },
		  {
			"v": 491876,
			"vw": 22.241,
			"o": 22.2439,
			"c": 22.2407,
			"h": 22.2439,
			"l": 22.2364,
			"t": 1344443700000,
			"n": 130
		  },
		  {
			"v": 299964,
			"vw": 22.2406,
			"o": 22.2411,
			"c": 22.2421,
			"h": 22.2436,
			"l": 22.2375,
			"t": 1344443760000,
			"n": 86
		  },
		  {
			"v": 87640,
			"vw": 22.2419,
			"o": 22.2421,
			"c": 22.2428,
			"h": 22.2429,
			"l": 22.2393,
			"t": 1344443820000,
			"n": 24
		  },
		  {
			"v": 189476,
			"vw": 22.242,
			"o": 22.2429,
			"c": 22.2393,
			"h": 22.2439,
			"l": 22.2393,
			"t": 1344443880000,
			"n": 46
		  },
		  {
			"v": 85540,
			"vw": 22.2402,
			"o": 22.2389,
			"c": 22.2421,
			"h": 22.2425,
			"l": 22.2369,
			"t": 1344443940000,
			"n": 27
		  },
		  {
			"v": 151592,
			"vw": 22.2385,
			"o": 22.2393,
			"c": 22.2364,
			"h": 22.2417,
			"l": 22.2364,
			"t": 1344444000000,
			"n": 41
		  },
		  {
			"v": 880936,
			"vw": 22.2278,
			"o": 22.2357,
			"c": 22.2171,
			"h": 22.2357,
			"l": 22.2164,
			"t": 1344444060000,
			"n": 183
		  },
		  {
			"v": 382844,
			"vw": 22.2219,
			"o": 22.2168,
			"c": 22.2204,
			"h": 22.2268,
			"l": 22.2168,
			"t": 1344444120000,
			"n": 94
		  },
		  {
			"v": 1009820,
			"vw": 22.2101,
			"o": 22.2207,
			"c": 22.2093,
			"h": 22.2214,
			"l": 22.2018,
			"t": 1344444180000,
			"n": 231
		  },
		  {
			"v": 328776,
			"vw": 22.2094,
			"o": 22.2061,
			"c": 22.2114,
			"h": 22.2136,
			"l": 22.2057,
			"t": 1344444240000,
			"n": 79
		  },
		  {
			"v": 182000,
			"vw": 22.2094,
			"o": 22.2125,
			"c": 22.2079,
			"h": 22.2125,
			"l": 22.2054,
			"t": 1344444300000,
			"n": 44
		  },
		  {
			"v": 369068,
			"vw": 22.2056,
			"o": 22.2079,
			"c": 22.2065,
			"h": 22.2086,
			"l": 22.2036,
			"t": 1344444360000,
			"n": 87
		  },
		  {
			"v": 596316,
			"vw": 22.2012,
			"o": 22.2039,
			"c": 22.2042,
			"h": 22.2061,
			"l": 22.1936,
			"t": 1344444420000,
			"n": 128
		  },
		  {
			"v": 359212,
			"vw": 22.2045,
			"o": 22.2064,
			"c": 22.2014,
			"h": 22.2064,
			"l": 22.2014,
			"t": 1344444480000,
			"n": 77
		  },
		  {
			"v": 342552,
			"vw": 22.1976,
			"o": 22.2029,
			"c": 22.1932,
			"h": 22.2029,
			"l": 22.1921,
			"t": 1344444540000,
			"n": 67
		  },
		  {
			"v": 898016,
			"vw": 22.184,
			"o": 22.1929,
			"c": 22.1786,
			"h": 22.1939,
			"l": 22.1786,
			"t": 1344444600000,
			"n": 217
		  },
		  {
			"v": 1033928,
			"vw": 22.1834,
			"o": 22.1814,
			"c": 22.1914,
			"h": 22.1918,
			"l": 22.1782,
			"t": 1344444660000,
			"n": 237
		  },
		  {
			"v": 241556,
			"vw": 22.1917,
			"o": 22.1889,
			"c": 22.1932,
			"h": 22.1932,
			"l": 22.1879,
			"t": 1344444720000,
			"n": 68
		  },
		  {
			"v": 483924,
			"vw": 22.1986,
			"o": 22.1932,
			"c": 22.1986,
			"h": 22.2032,
			"l": 22.1921,
			"t": 1344444780000,
			"n": 119
		  },
		  {
			"v": 329280,
			"vw": 22.1933,
			"o": 22.1979,
			"c": 22.1914,
			"h": 22.1996,
			"l": 22.1875,
			"t": 1344444840000,
			"n": 61
		  },
		  {
			"v": 324520,
			"vw": 22.1878,
			"o": 22.1914,
			"c": 22.189,
			"h": 22.1914,
			"l": 22.1843,
			"t": 1344444900000,
			"n": 48
		  },
		  {
			"v": 357840,
			"vw": 22.1958,
			"o": 22.1943,
			"c": 22.1929,
			"h": 22.1993,
			"l": 22.1902,
			"t": 1344444960000,
			"n": 82
		  },
		  {
			"v": 295176,
			"vw": 22.1989,
			"o": 22.1914,
			"c": 22.2022,
			"h": 22.2036,
			"l": 22.1882,
			"t": 1344445020000,
			"n": 67
		  },
		  {
			"v": 148232,
			"vw": 22.203,
			"o": 22.2035,
			"c": 22.2016,
			"h": 22.2046,
			"l": 22.2,
			"t": 1344445080000,
			"n": 42
		  },
		  {
			"v": 389788,
			"vw": 22.2049,
			"o": 22.2,
			"c": 22.21,
			"h": 22.2107,
			"l": 22.1982,
			"t": 1344445140000,
			"n": 88
		  },
		  {
			"v": 766696,
			"vw": 22.2095,
			"o": 22.21,
			"c": 22.2079,
			"h": 22.2125,
			"l": 22.2054,
			"t": 1344445200000,
			"n": 99
		  },
		  {
			"v": 372568,
			"vw": 22.2096,
			"o": 22.2071,
			"c": 22.2107,
			"h": 22.2132,
			"l": 22.2065,
			"t": 1344445260000,
			"n": 88
		  },
		  {
			"v": 519568,
			"vw": 22.2119,
			"o": 22.2109,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2086,
			"t": 1344445320000,
			"n": 106
		  },
		  {
			"v": 1042972,
			"vw": 22.2166,
			"o": 22.2139,
			"c": 22.2139,
			"h": 22.2229,
			"l": 22.2125,
			"t": 1344445380000,
			"n": 189
		  },
		  {
			"v": 194516,
			"vw": 22.2137,
			"o": 22.2111,
			"c": 22.2134,
			"h": 22.2161,
			"l": 22.21,
			"t": 1344445440000,
			"n": 43
		  },
		  {
			"v": 591836,
			"vw": 22.2052,
			"o": 22.2143,
			"c": 22.1982,
			"h": 22.2143,
			"l": 22.1979,
			"t": 1344445500000,
			"n": 129
		  },
		  {
			"v": 683956,
			"vw": 22.1963,
			"o": 22.1996,
			"c": 22.1906,
			"h": 22.2004,
			"l": 22.1882,
			"t": 1344445560000,
			"n": 170
		  },
		  {
			"v": 439712,
			"vw": 22.1874,
			"o": 22.1882,
			"c": 22.1893,
			"h": 22.1923,
			"l": 22.1821,
			"t": 1344445620000,
			"n": 116
		  },
		  {
			"v": 384580,
			"vw": 22.1889,
			"o": 22.1893,
			"c": 22.1825,
			"h": 22.1914,
			"l": 22.1825,
			"t": 1344445680000,
			"n": 92
		  },
		  {
			"v": 418824,
			"vw": 22.1841,
			"o": 22.1825,
			"c": 22.1857,
			"h": 22.1879,
			"l": 22.1804,
			"t": 1344445740000,
			"n": 103
		  },
		  {
			"v": 459032,
			"vw": 22.1877,
			"o": 22.1839,
			"c": 22.185,
			"h": 22.1936,
			"l": 22.1814,
			"t": 1344445800000,
			"n": 105
		  },
		  {
			"v": 468216,
			"vw": 22.1839,
			"o": 22.1837,
			"c": 22.185,
			"h": 22.1889,
			"l": 22.1786,
			"t": 1344445860000,
			"n": 113
		  },
		  {
			"v": 569156,
			"vw": 22.1834,
			"o": 22.1857,
			"c": 22.1843,
			"h": 22.1864,
			"l": 22.1804,
			"t": 1344445920000,
			"n": 138
		  },
		  {
			"v": 126224,
			"vw": 22.1843,
			"o": 22.1832,
			"c": 22.1846,
			"h": 22.1864,
			"l": 22.183,
			"t": 1344445980000,
			"n": 38
		  },
		  {
			"v": 1062068,
			"vw": 22.1767,
			"o": 22.1847,
			"c": 22.1739,
			"h": 22.1857,
			"l": 22.1675,
			"t": 1344446040000,
			"n": 227
		  },
		  {
			"v": 388332,
			"vw": 22.1751,
			"o": 22.1746,
			"c": 22.1789,
			"h": 22.1857,
			"l": 22.1697,
			"t": 1344446100000,
			"n": 106
		  },
		  {
			"v": 161000,
			"vw": 22.1778,
			"o": 22.1811,
			"c": 22.1736,
			"h": 22.1811,
			"l": 22.1736,
			"t": 1344446160000,
			"n": 39
		  },
		  {
			"v": 1205484,
			"vw": 22.1793,
			"o": 22.1771,
			"c": 22.1808,
			"h": 22.1868,
			"l": 22.1771,
			"t": 1344446220000,
			"n": 48
		  },
		  {
			"v": 286160,
			"vw": 22.1842,
			"o": 22.185,
			"c": 22.1807,
			"h": 22.1861,
			"l": 22.1796,
			"t": 1344446280000,
			"n": 46
		  },
		  {
			"v": 160776,
			"vw": 22.1832,
			"o": 22.1818,
			"c": 22.1804,
			"h": 22.1868,
			"l": 22.1786,
			"t": 1344446340000,
			"n": 28
		  },
		  {
			"v": 219184,
			"vw": 22.1849,
			"o": 22.1818,
			"c": 22.1864,
			"h": 22.1875,
			"l": 22.1807,
			"t": 1344446400000,
			"n": 62
		  },
		  {
			"v": 328440,
			"vw": 22.1907,
			"o": 22.1871,
			"c": 22.1914,
			"h": 22.1946,
			"l": 22.1864,
			"t": 1344446460000,
			"n": 69
		  },
		  {
			"v": 546196,
			"vw": 22.1977,
			"o": 22.1916,
			"c": 22.2004,
			"h": 22.2043,
			"l": 22.1916,
			"t": 1344446520000,
			"n": 129
		  },
		  {
			"v": 540344,
			"vw": 22.2056,
			"o": 22.2029,
			"c": 22.2068,
			"h": 22.2103,
			"l": 22.1996,
			"t": 1344446580000,
			"n": 89
		  },
		  {
			"v": 293776,
			"vw": 22.2069,
			"o": 22.2068,
			"c": 22.2092,
			"h": 22.2093,
			"l": 22.2039,
			"t": 1344446640000,
			"n": 76
		  },
		  {
			"v": 665420,
			"vw": 22.2039,
			"o": 22.2086,
			"c": 22.1991,
			"h": 22.2096,
			"l": 22.1975,
			"t": 1344446700000,
			"n": 152
		  },
		  {
			"v": 176960,
			"vw": 22.2019,
			"o": 22.1969,
			"c": 22.2036,
			"h": 22.2068,
			"l": 22.1968,
			"t": 1344446760000,
			"n": 42
		  },
		  {
			"v": 119504,
			"vw": 22.2016,
			"o": 22.2036,
			"c": 22.2021,
			"h": 22.2061,
			"l": 22.1971,
			"t": 1344446820000,
			"n": 25
		  },
		  {
			"v": 36400,
			"vw": 22.2027,
			"o": 22.2021,
			"c": 22.2071,
			"h": 22.2071,
			"l": 22.2004,
			"t": 1344446880000,
			"n": 12
		  },
		  {
			"v": 82740,
			"vw": 22.2044,
			"o": 22.2029,
			"c": 22.2061,
			"h": 22.2068,
			"l": 22.2029,
			"t": 1344446940000,
			"n": 19
		  },
		  {
			"v": 90804,
			"vw": 22.2052,
			"o": 22.2032,
			"c": 22.2086,
			"h": 22.2093,
			"l": 22.2029,
			"t": 1344447000000,
			"n": 25
		  },
		  {
			"v": 216776,
			"vw": 22.2039,
			"o": 22.2057,
			"c": 22.2057,
			"h": 22.2064,
			"l": 22.1986,
			"t": 1344447060000,
			"n": 49
		  },
		  {
			"v": 142436,
			"vw": 22.2046,
			"o": 22.2059,
			"c": 22.205,
			"h": 22.2059,
			"l": 22.2018,
			"t": 1344447120000,
			"n": 28
		  },
		  {
			"v": 196000,
			"vw": 22.2046,
			"o": 22.2071,
			"c": 22.203,
			"h": 22.2071,
			"l": 22.2011,
			"t": 1344447180000,
			"n": 37
		  },
		  {
			"v": 111272,
			"vw": 22.2043,
			"o": 22.2004,
			"c": 22.2057,
			"h": 22.2057,
			"l": 22.2004,
			"t": 1344447240000,
			"n": 25
		  },
		  {
			"v": 1094884,
			"vw": 22.1993,
			"o": 22.2036,
			"c": 22.2007,
			"h": 22.2043,
			"l": 22.2004,
			"t": 1344447300000,
			"n": 59
		  },
		  {
			"v": 252028,
			"vw": 22.1997,
			"o": 22.2011,
			"c": 22.2032,
			"h": 22.2032,
			"l": 22.1979,
			"t": 1344447360000,
			"n": 52
		  },
		  {
			"v": 122360,
			"vw": 22.1996,
			"o": 22.1998,
			"c": 22.1989,
			"h": 22.2025,
			"l": 22.1979,
			"t": 1344447420000,
			"n": 28
		  },
		  {
			"v": 127988,
			"vw": 22.2003,
			"o": 22.2018,
			"c": 22.2025,
			"h": 22.2025,
			"l": 22.1982,
			"t": 1344447480000,
			"n": 35
		  },
		  {
			"v": 97244,
			"vw": 22.2001,
			"o": 22.2004,
			"c": 22.2005,
			"h": 22.2032,
			"l": 22.1979,
			"t": 1344447540000,
			"n": 23
		  },
		  {
			"v": 61236,
			"vw": 22.2004,
			"o": 22.2021,
			"c": 22.2032,
			"h": 22.2032,
			"l": 22.1986,
			"t": 1344447600000,
			"n": 15
		  },
		  {
			"v": 218456,
			"vw": 22.2059,
			"o": 22.1995,
			"c": 22.2084,
			"h": 22.2086,
			"l": 22.1995,
			"t": 1344447660000,
			"n": 41
		  },
		  {
			"v": 331548,
			"vw": 22.2019,
			"o": 22.2058,
			"c": 22.2043,
			"h": 22.2066,
			"l": 22.1986,
			"t": 1344447720000,
			"n": 82
		  },
		  {
			"v": 430192,
			"vw": 22.1954,
			"o": 22.2021,
			"c": 22.1894,
			"h": 22.2021,
			"l": 22.1894,
			"t": 1344447780000,
			"n": 88
		  },
		  {
			"v": 567952,
			"vw": 22.187,
			"o": 22.1936,
			"c": 22.1879,
			"h": 22.1936,
			"l": 22.1829,
			"t": 1344447840000,
			"n": 66
		  },
		  {
			"v": 103600,
			"vw": 22.1883,
			"o": 22.1889,
			"c": 22.1921,
			"h": 22.1921,
			"l": 22.1861,
			"t": 1344447900000,
			"n": 30
		  },
		  {
			"v": 517132,
			"vw": 22.1973,
			"o": 22.1918,
			"c": 22.1992,
			"h": 22.2036,
			"l": 22.19,
			"t": 1344447960000,
			"n": 106
		  },
		  {
			"v": 123200,
			"vw": 22.1952,
			"o": 22.1968,
			"c": 22.1907,
			"h": 22.1975,
			"l": 22.1907,
			"t": 1344448020000,
			"n": 25
		  },
		  {
			"v": 60900,
			"vw": 22.1932,
			"o": 22.1934,
			"c": 22.1946,
			"h": 22.1946,
			"l": 22.1908,
			"t": 1344448080000,
			"n": 9
		  },
		  {
			"v": 193508,
			"vw": 22.1938,
			"o": 22.1957,
			"c": 22.1936,
			"h": 22.1979,
			"l": 22.1911,
			"t": 1344448140000,
			"n": 49
		  },
		  {
			"v": 109060,
			"vw": 22.1972,
			"o": 22.1996,
			"c": 22.1963,
			"h": 22.2,
			"l": 22.1929,
			"t": 1344448200000,
			"n": 18
		  },
		  {
			"v": 237104,
			"vw": 22.1988,
			"o": 22.1961,
			"c": 22.2021,
			"h": 22.2021,
			"l": 22.1961,
			"t": 1344448260000,
			"n": 41
		  },
		  {
			"v": 176092,
			"vw": 22.2,
			"o": 22.2021,
			"c": 22.201,
			"h": 22.2021,
			"l": 22.1976,
			"t": 1344448320000,
			"n": 32
		  },
		  {
			"v": 150920,
			"vw": 22.1978,
			"o": 22.1982,
			"c": 22.2018,
			"h": 22.2018,
			"l": 22.196,
			"t": 1344448380000,
			"n": 35
		  },
		  {
			"v": 639828,
			"vw": 22.1997,
			"o": 22.2018,
			"c": 22.1936,
			"h": 22.2018,
			"l": 22.1936,
			"t": 1344448440000,
			"n": 23
		  },
		  {
			"v": 156352,
			"vw": 22.1905,
			"o": 22.1943,
			"c": 22.1943,
			"h": 22.1943,
			"l": 22.1864,
			"t": 1344448500000,
			"n": 43
		  },
		  {
			"v": 185976,
			"vw": 22.1963,
			"o": 22.1946,
			"c": 22.1951,
			"h": 22.1989,
			"l": 22.1933,
			"t": 1344448560000,
			"n": 36
		  },
		  {
			"v": 256312,
			"vw": 22.2004,
			"o": 22.1996,
			"c": 22.1964,
			"h": 22.2025,
			"l": 22.1964,
			"t": 1344448620000,
			"n": 55
		  },
		  {
			"v": 39200,
			"vw": 22.1949,
			"o": 22.1979,
			"c": 22.192,
			"h": 22.1979,
			"l": 22.1914,
			"t": 1344448680000,
			"n": 14
		  },
		  {
			"v": 163884,
			"vw": 22.1942,
			"o": 22.1918,
			"c": 22.1918,
			"h": 22.1968,
			"l": 22.1911,
			"t": 1344448740000,
			"n": 35
		  },
		  {
			"v": 222992,
			"vw": 22.1938,
			"o": 22.1961,
			"c": 22.1907,
			"h": 22.1975,
			"l": 22.1907,
			"t": 1344448800000,
			"n": 53
		  },
		  {
			"v": 485492,
			"vw": 22.186,
			"o": 22.1913,
			"c": 22.1821,
			"h": 22.1913,
			"l": 22.1821,
			"t": 1344448860000,
			"n": 86
		  },
		  {
			"v": 354564,
			"vw": 22.1829,
			"o": 22.1821,
			"c": 22.1825,
			"h": 22.185,
			"l": 22.1804,
			"t": 1344448920000,
			"n": 64
		  },
		  {
			"v": 254072,
			"vw": 22.1826,
			"o": 22.1804,
			"c": 22.18,
			"h": 22.1871,
			"l": 22.1793,
			"t": 1344448980000,
			"n": 58
		  },
		  {
			"v": 732452,
			"vw": 22.1788,
			"o": 22.1796,
			"c": 22.1747,
			"h": 22.1839,
			"l": 22.1732,
			"t": 1344449040000,
			"n": 158
		  },
		  {
			"v": 210028,
			"vw": 22.1777,
			"o": 22.1746,
			"c": 22.1746,
			"h": 22.1836,
			"l": 22.1739,
			"t": 1344449100000,
			"n": 54
		  },
		  {
			"v": 336028,
			"vw": 22.1758,
			"o": 22.1748,
			"c": 22.1715,
			"h": 22.1804,
			"l": 22.1714,
			"t": 1344449160000,
			"n": 84
		  },
		  {
			"v": 132356,
			"vw": 22.1749,
			"o": 22.1714,
			"c": 22.1764,
			"h": 22.1775,
			"l": 22.1714,
			"t": 1344449220000,
			"n": 37
		  },
		  {
			"v": 165872,
			"vw": 22.1776,
			"o": 22.1771,
			"c": 22.1782,
			"h": 22.1804,
			"l": 22.1751,
			"t": 1344449280000,
			"n": 32
		  },
		  {
			"v": 210224,
			"vw": 22.1811,
			"o": 22.1761,
			"c": 22.1814,
			"h": 22.1829,
			"l": 22.1761,
			"t": 1344449340000,
			"n": 41
		  },
		  {
			"v": 3252732,
			"vw": 22.1848,
			"o": 22.1796,
			"c": 22.1873,
			"h": 22.1882,
			"l": 22.1786,
			"t": 1344449400000,
			"n": 112
		  },
		  {
			"v": 347116,
			"vw": 22.1884,
			"o": 22.1864,
			"c": 22.1871,
			"h": 22.1921,
			"l": 22.1843,
			"t": 1344449460000,
			"n": 103
		  },
		  {
			"v": 130760,
			"vw": 22.1829,
			"o": 22.1871,
			"c": 22.1832,
			"h": 22.1871,
			"l": 22.1797,
			"t": 1344449520000,
			"n": 35
		  },
		  {
			"v": 299096,
			"vw": 22.1803,
			"o": 22.1805,
			"c": 22.1813,
			"h": 22.1839,
			"l": 22.1793,
			"t": 1344449580000,
			"n": 37
		  },
		  {
			"v": 244944,
			"vw": 22.1781,
			"o": 22.1802,
			"c": 22.1769,
			"h": 22.1804,
			"l": 22.1768,
			"t": 1344449640000,
			"n": 41
		  },
		  {
			"v": 439768,
			"vw": 22.1727,
			"o": 22.1761,
			"c": 22.1736,
			"h": 22.1768,
			"l": 22.1689,
			"t": 1344449700000,
			"n": 98
		  },
		  {
			"v": 825972,
			"vw": 22.1664,
			"o": 22.1736,
			"c": 22.1536,
			"h": 22.1786,
			"l": 22.1536,
			"t": 1344449760000,
			"n": 149
		  },
		  {
			"v": 460880,
			"vw": 22.157,
			"o": 22.1556,
			"c": 22.1582,
			"h": 22.1614,
			"l": 22.1536,
			"t": 1344449820000,
			"n": 117
		  },
		  {
			"v": 324772,
			"vw": 22.1555,
			"o": 22.1561,
			"c": 22.1564,
			"h": 22.1607,
			"l": 22.1504,
			"t": 1344449880000,
			"n": 94
		  },
		  {
			"v": 304472,
			"vw": 22.1603,
			"o": 22.1573,
			"c": 22.165,
			"h": 22.1656,
			"l": 22.1571,
			"t": 1344449940000,
			"n": 65
		  },
		  {
			"v": 265048,
			"vw": 22.1621,
			"o": 22.1629,
			"c": 22.1608,
			"h": 22.165,
			"l": 22.1575,
			"t": 1344450000000,
			"n": 62
		  },
		  {
			"v": 257376,
			"vw": 22.1574,
			"o": 22.159,
			"c": 22.155,
			"h": 22.1604,
			"l": 22.1546,
			"t": 1344450060000,
			"n": 56
		  },
		  {
			"v": 248556,
			"vw": 22.1591,
			"o": 22.1546,
			"c": 22.1604,
			"h": 22.1618,
			"l": 22.1546,
			"t": 1344450120000,
			"n": 55
		  },
		  {
			"v": 87584,
			"vw": 22.1593,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1611,
			"l": 22.1568,
			"t": 1344450180000,
			"n": 24
		  },
		  {
			"v": 320012,
			"vw": 22.1553,
			"o": 22.1596,
			"c": 22.1544,
			"h": 22.1596,
			"l": 22.1529,
			"t": 1344450240000,
			"n": 65
		  },
		  {
			"v": 152880,
			"vw": 22.1585,
			"o": 22.1571,
			"c": 22.1579,
			"h": 22.1607,
			"l": 22.1561,
			"t": 1344450300000,
			"n": 47
		  },
		  {
			"v": 207200,
			"vw": 22.1569,
			"o": 22.1579,
			"c": 22.1604,
			"h": 22.1607,
			"l": 22.1554,
			"t": 1344450360000,
			"n": 37
		  },
		  {
			"v": 443436,
			"vw": 22.168,
			"o": 22.1607,
			"c": 22.1696,
			"h": 22.1736,
			"l": 22.1596,
			"t": 1344450420000,
			"n": 100
		  },
		  {
			"v": 575764,
			"vw": 22.1775,
			"o": 22.1746,
			"c": 22.1786,
			"h": 22.1821,
			"l": 22.1696,
			"t": 1344450480000,
			"n": 125
		  },
		  {
			"v": 244552,
			"vw": 22.1807,
			"o": 22.175,
			"c": 22.1825,
			"h": 22.1871,
			"l": 22.175,
			"t": 1344450540000,
			"n": 70
		  },
		  {
			"v": 259168,
			"vw": 22.1788,
			"o": 22.1818,
			"c": 22.1752,
			"h": 22.1842,
			"l": 22.1739,
			"t": 1344450600000,
			"n": 53
		  },
		  {
			"v": 280252,
			"vw": 22.1812,
			"o": 22.1725,
			"c": 22.1814,
			"h": 22.1855,
			"l": 22.1725,
			"t": 1344450660000,
			"n": 52
		  },
		  {
			"v": 198940,
			"vw": 22.1902,
			"o": 22.1793,
			"c": 22.1821,
			"h": 22.1964,
			"l": 22.1789,
			"t": 1344450720000,
			"n": 18
		  },
		  {
			"v": 240156,
			"vw": 22.184,
			"o": 22.1818,
			"c": 22.1843,
			"h": 22.1868,
			"l": 22.1814,
			"t": 1344450780000,
			"n": 62
		  },
		  {
			"v": 263172,
			"vw": 22.1856,
			"o": 22.1836,
			"c": 22.1864,
			"h": 22.1911,
			"l": 22.1836,
			"t": 1344450840000,
			"n": 69
		  },
		  {
			"v": 339304,
			"vw": 22.1888,
			"o": 22.1864,
			"c": 22.1925,
			"h": 22.1925,
			"l": 22.1829,
			"t": 1344450900000,
			"n": 69
		  },
		  {
			"v": 320460,
			"vw": 22.1927,
			"o": 22.1928,
			"c": 22.1925,
			"h": 22.195,
			"l": 22.1896,
			"t": 1344450960000,
			"n": 60
		  },
		  {
			"v": 253512,
			"vw": 22.1923,
			"o": 22.1914,
			"c": 22.1868,
			"h": 22.1968,
			"l": 22.1868,
			"t": 1344451020000,
			"n": 66
		  },
		  {
			"v": 196868,
			"vw": 22.1908,
			"o": 22.1877,
			"c": 22.1881,
			"h": 22.1943,
			"l": 22.1861,
			"t": 1344451080000,
			"n": 48
		  },
		  {
			"v": 766024,
			"vw": 22.1793,
			"o": 22.1871,
			"c": 22.1944,
			"h": 22.1975,
			"l": 22.1871,
			"t": 1344451140000,
			"n": 52
		  },
		  {
			"v": 455700,
			"vw": 22.1981,
			"o": 22.1921,
			"c": 22.1999,
			"h": 22.2021,
			"l": 22.1921,
			"t": 1344451200000,
			"n": 118
		  },
		  {
			"v": 346612,
			"vw": 22.2003,
			"o": 22.1996,
			"c": 22.2036,
			"h": 22.2036,
			"l": 22.1971,
			"t": 1344451260000,
			"n": 81
		  },
		  {
			"v": 687316,
			"vw": 22.2052,
			"o": 22.2029,
			"c": 22.2061,
			"h": 22.2089,
			"l": 22.2002,
			"t": 1344451320000,
			"n": 123
		  },
		  {
			"v": 355656,
			"vw": 22.2054,
			"o": 22.2061,
			"c": 22.2064,
			"h": 22.2071,
			"l": 22.2031,
			"t": 1344451380000,
			"n": 76
		  },
		  {
			"v": 458780,
			"vw": 22.1979,
			"o": 22.2064,
			"c": 22.201,
			"h": 22.2071,
			"l": 22.1972,
			"t": 1344451440000,
			"n": 50
		  },
		  {
			"v": 370188,
			"vw": 22.1963,
			"o": 22.1993,
			"c": 22.1946,
			"h": 22.2,
			"l": 22.1925,
			"t": 1344451500000,
			"n": 96
		  },
		  {
			"v": 233268,
			"vw": 22.1947,
			"o": 22.1967,
			"c": 22.1954,
			"h": 22.1968,
			"l": 22.1925,
			"t": 1344451560000,
			"n": 63
		  },
		  {
			"v": 407316,
			"vw": 22.1891,
			"o": 22.1943,
			"c": 22.1879,
			"h": 22.195,
			"l": 22.1857,
			"t": 1344451620000,
			"n": 108
		  },
		  {
			"v": 192948,
			"vw": 22.1871,
			"o": 22.1899,
			"c": 22.1886,
			"h": 22.1902,
			"l": 22.1836,
			"t": 1344451680000,
			"n": 52
		  },
		  {
			"v": 830312,
			"vw": 22.1799,
			"o": 22.1868,
			"c": 22.1746,
			"h": 22.1879,
			"l": 22.1746,
			"t": 1344451740000,
			"n": 132
		  },
		  {
			"v": 248304,
			"vw": 22.1772,
			"o": 22.1766,
			"c": 22.1763,
			"h": 22.1799,
			"l": 22.1739,
			"t": 1344451800000,
			"n": 66
		  },
		  {
			"v": 294728,
			"vw": 22.172,
			"o": 22.1761,
			"c": 22.175,
			"h": 22.1764,
			"l": 22.1686,
			"t": 1344451860000,
			"n": 75
		  },
		  {
			"v": 78400,
			"vw": 22.1777,
			"o": 22.1782,
			"c": 22.1779,
			"h": 22.1782,
			"l": 22.1739,
			"t": 1344451920000,
			"n": 13
		  },
		  {
			"v": 144228,
			"vw": 22.1757,
			"o": 22.1771,
			"c": 22.1736,
			"h": 22.1786,
			"l": 22.1732,
			"t": 1344451980000,
			"n": 42
		  },
		  {
			"v": 316456,
			"vw": 22.1755,
			"o": 22.1725,
			"c": 22.175,
			"h": 22.1786,
			"l": 22.1722,
			"t": 1344452040000,
			"n": 63
		  },
		  {
			"v": 310772,
			"vw": 22.1733,
			"o": 22.1725,
			"c": 22.1693,
			"h": 22.1786,
			"l": 22.1679,
			"t": 1344452100000,
			"n": 48
		  },
		  {
			"v": 100604,
			"vw": 22.1728,
			"o": 22.1686,
			"c": 22.1729,
			"h": 22.1768,
			"l": 22.1686,
			"t": 1344452160000,
			"n": 26
		  },
		  {
			"v": 139076,
			"vw": 22.1692,
			"o": 22.1729,
			"c": 22.1686,
			"h": 22.1739,
			"l": 22.1668,
			"t": 1344452220000,
			"n": 39
		  },
		  {
			"v": 122500,
			"vw": 22.169,
			"o": 22.1679,
			"c": 22.1711,
			"h": 22.1711,
			"l": 22.1652,
			"t": 1344452280000,
			"n": 35
		  },
		  {
			"v": 449316,
			"vw": 22.1733,
			"o": 22.1707,
			"c": 22.1743,
			"h": 22.1757,
			"l": 22.1696,
			"t": 1344452340000,
			"n": 104
		  },
		  {
			"v": 413028,
			"vw": 22.1751,
			"o": 22.1718,
			"c": 22.1754,
			"h": 22.1786,
			"l": 22.1702,
			"t": 1344452400000,
			"n": 74
		  },
		  {
			"v": 519736,
			"vw": 22.1815,
			"o": 22.1774,
			"c": 22.1807,
			"h": 22.1868,
			"l": 22.1768,
			"t": 1344452460000,
			"n": 138
		  },
		  {
			"v": 430276,
			"vw": 22.185,
			"o": 22.1841,
			"c": 22.1877,
			"h": 22.1914,
			"l": 22.175,
			"t": 1344452520000,
			"n": 92
		  },
		  {
			"v": 500220,
			"vw": 22.1913,
			"o": 22.1893,
			"c": 22.1954,
			"h": 22.1964,
			"l": 22.1829,
			"t": 1344452580000,
			"n": 100
		  },
		  {
			"v": 247016,
			"vw": 22.1884,
			"o": 22.1901,
			"c": 22.1916,
			"h": 22.1932,
			"l": 22.1843,
			"t": 1344452640000,
			"n": 63
		  },
		  {
			"v": 321832,
			"vw": 22.19,
			"o": 22.1925,
			"c": 22.1901,
			"h": 22.1929,
			"l": 22.1857,
			"t": 1344452700000,
			"n": 79
		  },
		  {
			"v": 170408,
			"vw": 22.188,
			"o": 22.1895,
			"c": 22.191,
			"h": 22.1911,
			"l": 22.1856,
			"t": 1344452760000,
			"n": 37
		  },
		  {
			"v": 121548,
			"vw": 22.1848,
			"o": 22.1882,
			"c": 22.1846,
			"h": 22.1895,
			"l": 22.1818,
			"t": 1344452820000,
			"n": 35
		  },
		  {
			"v": 229740,
			"vw": 22.1869,
			"o": 22.1825,
			"c": 22.1832,
			"h": 22.1904,
			"l": 22.1825,
			"t": 1344452880000,
			"n": 70
		  },
		  {
			"v": 526960,
			"vw": 22.1797,
			"o": 22.1832,
			"c": 22.1761,
			"h": 22.1861,
			"l": 22.1746,
			"t": 1344452940000,
			"n": 107
		  },
		  {
			"v": 520828,
			"vw": 22.1696,
			"o": 22.1757,
			"c": 22.1693,
			"h": 22.1775,
			"l": 22.1639,
			"t": 1344453000000,
			"n": 125
		  },
		  {
			"v": 314076,
			"vw": 22.1728,
			"o": 22.1671,
			"c": 22.1761,
			"h": 22.1786,
			"l": 22.1661,
			"t": 1344453060000,
			"n": 63
		  },
		  {
			"v": 295092,
			"vw": 22.1796,
			"o": 22.1786,
			"c": 22.1761,
			"h": 22.1829,
			"l": 22.1754,
			"t": 1344453120000,
			"n": 73
		  },
		  {
			"v": 350140,
			"vw": 22.1755,
			"o": 22.1761,
			"c": 22.1793,
			"h": 22.1793,
			"l": 22.1718,
			"t": 1344453180000,
			"n": 87
		  },
		  {
			"v": 186480,
			"vw": 22.1769,
			"o": 22.1796,
			"c": 22.1754,
			"h": 22.1799,
			"l": 22.1729,
			"t": 1344453240000,
			"n": 44
		  },
		  {
			"v": 199304,
			"vw": 22.1831,
			"o": 22.1764,
			"c": 22.1829,
			"h": 22.1871,
			"l": 22.1764,
			"t": 1344453300000,
			"n": 54
		  },
		  {
			"v": 333508,
			"vw": 22.1853,
			"o": 22.186,
			"c": 22.1859,
			"h": 22.1879,
			"l": 22.1812,
			"t": 1344453360000,
			"n": 70
		  },
		  {
			"v": 67312,
			"vw": 22.1827,
			"o": 22.1846,
			"c": 22.1836,
			"h": 22.1847,
			"l": 22.1807,
			"t": 1344453420000,
			"n": 22
		  },
		  {
			"v": 498148,
			"vw": 22.1836,
			"o": 22.1836,
			"c": 22.1788,
			"h": 22.1882,
			"l": 22.1788,
			"t": 1344453480000,
			"n": 113
		  },
		  {
			"v": 330232,
			"vw": 22.1807,
			"o": 22.1793,
			"c": 22.185,
			"h": 22.185,
			"l": 22.1771,
			"t": 1344453540000,
			"n": 98
		  },
		  {
			"v": 256172,
			"vw": 22.1834,
			"o": 22.1838,
			"c": 22.1857,
			"h": 22.1857,
			"l": 22.1796,
			"t": 1344453600000,
			"n": 62
		  },
		  {
			"v": 97692,
			"vw": 22.1832,
			"o": 22.1857,
			"c": 22.1835,
			"h": 22.1857,
			"l": 22.1804,
			"t": 1344453660000,
			"n": 20
		  },
		  {
			"v": 173600,
			"vw": 22.1823,
			"o": 22.1808,
			"c": 22.1843,
			"h": 22.1854,
			"l": 22.18,
			"t": 1344453720000,
			"n": 47
		  },
		  {
			"v": 264600,
			"vw": 22.182,
			"o": 22.1829,
			"c": 22.1811,
			"h": 22.1854,
			"l": 22.1804,
			"t": 1344453780000,
			"n": 75
		  },
		  {
			"v": 369712,
			"vw": 22.1785,
			"o": 22.1821,
			"c": 22.1754,
			"h": 22.1824,
			"l": 22.175,
			"t": 1344453840000,
			"n": 100
		  },
		  {
			"v": 3447948,
			"vw": 22.1817,
			"o": 22.1764,
			"c": 22.1796,
			"h": 22.1821,
			"l": 22.1754,
			"t": 1344453900000,
			"n": 104
		  },
		  {
			"v": 290360,
			"vw": 22.1828,
			"o": 22.18,
			"c": 22.1867,
			"h": 22.1868,
			"l": 22.1783,
			"t": 1344453960000,
			"n": 72
		  },
		  {
			"v": 497364,
			"vw": 22.1884,
			"o": 22.1868,
			"c": 22.185,
			"h": 22.1929,
			"l": 22.1832,
			"t": 1344454020000,
			"n": 121
		  },
		  {
			"v": 252840,
			"vw": 22.1875,
			"o": 22.1854,
			"c": 22.1807,
			"h": 22.1925,
			"l": 22.1807,
			"t": 1344454080000,
			"n": 68
		  },
		  {
			"v": 226772,
			"vw": 22.1858,
			"o": 22.1821,
			"c": 22.1901,
			"h": 22.1911,
			"l": 22.1821,
			"t": 1344454140000,
			"n": 55
		  },
		  {
			"v": 372540,
			"vw": 22.1884,
			"o": 22.1886,
			"c": 22.1879,
			"h": 22.1918,
			"l": 22.1854,
			"t": 1344454200000,
			"n": 85
		  },
		  {
			"v": 344988,
			"vw": 22.1865,
			"o": 22.1884,
			"c": 22.1921,
			"h": 22.1921,
			"l": 22.1843,
			"t": 1344454260000,
			"n": 89
		  },
		  {
			"v": 293692,
			"vw": 22.1874,
			"o": 22.1896,
			"c": 22.1845,
			"h": 22.1907,
			"l": 22.1829,
			"t": 1344454320000,
			"n": 84
		  },
		  {
			"v": 775488,
			"vw": 22.1866,
			"o": 22.1854,
			"c": 22.1911,
			"h": 22.1911,
			"l": 22.1818,
			"t": 1344454380000,
			"n": 197
		  },
		  {
			"v": 313852,
			"vw": 22.1901,
			"o": 22.1914,
			"c": 22.1879,
			"h": 22.1932,
			"l": 22.1864,
			"t": 1344454440000,
			"n": 94
		  },
		  {
			"v": 502404,
			"vw": 22.1847,
			"o": 22.1864,
			"c": 22.1846,
			"h": 22.1879,
			"l": 22.18,
			"t": 1344454500000,
			"n": 128
		  },
		  {
			"v": 575204,
			"vw": 22.1785,
			"o": 22.1846,
			"c": 22.1804,
			"h": 22.1857,
			"l": 22.1729,
			"t": 1344454560000,
			"n": 140
		  },
		  {
			"v": 267288,
			"vw": 22.1764,
			"o": 22.1807,
			"c": 22.1778,
			"h": 22.1811,
			"l": 22.1746,
			"t": 1344454620000,
			"n": 66
		  },
		  {
			"v": 332948,
			"vw": 22.1741,
			"o": 22.1779,
			"c": 22.1711,
			"h": 22.1779,
			"l": 22.1693,
			"t": 1344454680000,
			"n": 76
		  },
		  {
			"v": 738444,
			"vw": 22.1645,
			"o": 22.1704,
			"c": 22.1589,
			"h": 22.1725,
			"l": 22.1589,
			"t": 1344454740000,
			"n": 158
		  },
		  {
			"v": 1196916,
			"vw": 22.1541,
			"o": 22.1596,
			"c": 22.1477,
			"h": 22.1611,
			"l": 22.1459,
			"t": 1344454800000,
			"n": 310
		  },
		  {
			"v": 2089248,
			"vw": 22.1433,
			"o": 22.1471,
			"c": 22.1393,
			"h": 22.1525,
			"l": 22.1336,
			"t": 1344454860000,
			"n": 508
		  },
		  {
			"v": 932568,
			"vw": 22.1411,
			"o": 22.1396,
			"c": 22.1425,
			"h": 22.1461,
			"l": 22.1375,
			"t": 1344454920000,
			"n": 228
		  },
		  {
			"v": 530656,
			"vw": 22.1422,
			"o": 22.1439,
			"c": 22.1429,
			"h": 22.1471,
			"l": 22.1379,
			"t": 1344454980000,
			"n": 131
		  },
		  {
			"v": 643524,
			"vw": 22.1457,
			"o": 22.145,
			"c": 22.1464,
			"h": 22.1511,
			"l": 22.1411,
			"t": 1344455040000,
			"n": 149
		  },
		  {
			"v": 655312,
			"vw": 22.148,
			"o": 22.1475,
			"c": 22.1464,
			"h": 22.1521,
			"l": 22.1464,
			"t": 1344455100000,
			"n": 148
		  },
		  {
			"v": 489972,
			"vw": 22.1506,
			"o": 22.1464,
			"c": 22.1564,
			"h": 22.1564,
			"l": 22.1464,
			"t": 1344455160000,
			"n": 119
		  },
		  {
			"v": 498848,
			"vw": 22.1525,
			"o": 22.1521,
			"c": 22.1486,
			"h": 22.1564,
			"l": 22.1486,
			"t": 1344455220000,
			"n": 136
		  },
		  {
			"v": 729876,
			"vw": 22.1486,
			"o": 22.1509,
			"c": 22.1482,
			"h": 22.1525,
			"l": 22.1454,
			"t": 1344455280000,
			"n": 192
		  },
		  {
			"v": 411292,
			"vw": 22.1492,
			"o": 22.1475,
			"c": 22.1454,
			"h": 22.1525,
			"l": 22.1446,
			"t": 1344455340000,
			"n": 110
		  },
		  {
			"v": 790888,
			"vw": 22.1489,
			"o": 22.1454,
			"c": 22.1504,
			"h": 22.1518,
			"l": 22.1454,
			"t": 1344455400000,
			"n": 212
		  },
		  {
			"v": 1351196,
			"vw": 22.146,
			"o": 22.1493,
			"c": 22.1446,
			"h": 22.1511,
			"l": 22.1429,
			"t": 1344455460000,
			"n": 339
		  },
		  {
			"v": 1310988,
			"vw": 22.1471,
			"o": 22.1446,
			"c": 22.1493,
			"h": 22.1504,
			"l": 22.1429,
			"t": 1344455520000,
			"n": 236
		  },
		  {
			"v": 1660512,
			"vw": 22.1499,
			"o": 22.15,
			"c": 22.1468,
			"h": 22.1539,
			"l": 22.1454,
			"t": 1344455580000,
			"n": 335
		  },
		  {
			"v": 1286768,
			"vw": 22.1474,
			"o": 22.1471,
			"c": 22.1461,
			"h": 22.15,
			"l": 22.1446,
			"t": 1344455640000,
			"n": 276
		  },
		  {
			"v": 1645812,
			"vw": 22.1435,
			"o": 22.1482,
			"c": 22.1393,
			"h": 22.1486,
			"l": 22.1393,
			"t": 1344455700000,
			"n": 350
		  },
		  {
			"v": 1482488,
			"vw": 22.1366,
			"o": 22.1393,
			"c": 22.1344,
			"h": 22.1411,
			"l": 22.1314,
			"t": 1344455760000,
			"n": 332
		  },
		  {
			"v": 2161012,
			"vw": 22.1296,
			"o": 22.1357,
			"c": 22.1243,
			"h": 22.1361,
			"l": 22.1236,
			"t": 1344455820000,
			"n": 446
		  },
		  {
			"v": 1499652,
			"vw": 22.1262,
			"o": 22.1243,
			"c": 22.1286,
			"h": 22.13,
			"l": 22.1236,
			"t": 1344455880000,
			"n": 337
		  },
		  {
			"v": 2729636,
			"vw": 22.1301,
			"o": 22.1261,
			"c": 22.1375,
			"h": 22.1421,
			"l": 22.1236,
			"t": 1344455940000,
			"n": 606
		  },
		  {
			"v": 459228,
			"vw": 22.1417,
			"o": 22.1379,
			"c": 22.1496,
			"h": 22.1496,
			"l": 22.1379,
			"t": 1344456000000,
			"n": 34
		  },
		  {
			"v": 41328,
			"vw": 22.1423,
			"o": 22.1414,
			"c": 22.1429,
			"h": 22.1429,
			"l": 22.1414,
			"t": 1344456060000,
			"n": 3
		  },
		  {
			"v": 169400,
			"vw": 22.1475,
			"o": 22.1461,
			"c": 22.1486,
			"h": 22.15,
			"l": 22.1461,
			"t": 1344456120000,
			"n": 21
		  },
		  {
			"v": 26320,
			"vw": 22.1499,
			"o": 22.1504,
			"c": 22.1518,
			"h": 22.1518,
			"l": 22.1486,
			"t": 1344456180000,
			"n": 7
		  },
		  {
			"v": 78960,
			"vw": 22.1438,
			"o": 22.1436,
			"c": 22.1446,
			"h": 22.1446,
			"l": 22.1429,
			"t": 1344456240000,
			"n": 11
		  },
		  {
			"v": 14000,
			"vw": 22.143,
			"o": 22.1436,
			"c": 22.1407,
			"h": 22.1436,
			"l": 22.1407,
			"t": 1344456300000,
			"n": 5
		  },
		  {
			"v": 42840,
			"vw": 22.1395,
			"o": 22.1396,
			"c": 22.1393,
			"h": 22.1396,
			"l": 22.1393,
			"t": 1344456360000,
			"n": 3
		  },
		  {
			"v": 20916,
			"vw": 22.1387,
			"o": 22.1407,
			"c": 22.1407,
			"h": 22.1407,
			"l": 22.1407,
			"t": 1344456420000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.1427,
			"o": 22.1425,
			"c": 22.1429,
			"h": 22.1429,
			"l": 22.1425,
			"t": 1344456480000,
			"n": 2
		  },
		  {
			"v": 39424,
			"vw": 22.1425,
			"o": 22.1425,
			"c": 22.1432,
			"h": 22.1432,
			"l": 22.1414,
			"t": 1344456540000,
			"n": 5
		  },
		  {
			"v": 11200,
			"vw": 22.1446,
			"o": 22.1446,
			"c": 22.1446,
			"h": 22.1446,
			"l": 22.1446,
			"t": 1344456600000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1443,
			"o": 22.1443,
			"c": 22.1443,
			"h": 22.1443,
			"l": 22.1443,
			"t": 1344456660000,
			"n": 1
		  },
		  {
			"v": 39200,
			"vw": 22.1359,
			"o": 22.1071,
			"c": 22.1418,
			"h": 22.1429,
			"l": 22.1071,
			"t": 1344456720000,
			"n": 6
		  },
		  {
			"v": 55076,
			"vw": 22.1407,
			"o": 22.1418,
			"c": 22.1386,
			"h": 22.1418,
			"l": 22.1379,
			"t": 1344456780000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.1411,
			"o": 22.1411,
			"c": 22.1411,
			"h": 22.1411,
			"l": 22.1411,
			"t": 1344456840000,
			"n": 1
		  },
		  {
			"v": 28616,
			"vw": 22.1381,
			"o": 22.1411,
			"c": 22.1375,
			"h": 22.1411,
			"l": 22.1375,
			"t": 1344456900000,
			"n": 8
		  },
		  {
			"v": 3612,
			"vw": 22.1357,
			"o": 22.1357,
			"c": 22.1357,
			"h": 22.1357,
			"l": 22.1357,
			"t": 1344457020000,
			"n": 1
		  },
		  {
			"v": 19208,
			"vw": 22.1346,
			"o": 22.1354,
			"c": 22.1339,
			"h": 22.1354,
			"l": 22.1339,
			"t": 1344457080000,
			"n": 2
		  },
		  {
			"v": 41300,
			"vw": 22.1397,
			"o": 22.1386,
			"c": 22.1411,
			"h": 22.1411,
			"l": 22.1386,
			"t": 1344457200000,
			"n": 8
		  },
		  {
			"v": 42000,
			"vw": 22.1382,
			"o": 22.1364,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1364,
			"t": 1344457260000,
			"n": 4
		  },
		  {
			"v": 6160,
			"vw": 22.1421,
			"o": 22.1421,
			"c": 22.1421,
			"h": 22.1421,
			"l": 22.1421,
			"t": 1344457320000,
			"n": 1
		  },
		  {
			"v": 10108,
			"vw": 22.1396,
			"o": 22.14,
			"c": 22.1393,
			"h": 22.14,
			"l": 22.1393,
			"t": 1344457380000,
			"n": 3
		  },
		  {
			"v": 8092,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344457440000,
			"n": 2
		  },
		  {
			"v": 6524,
			"vw": 22.1399,
			"o": 22.1407,
			"c": 22.1393,
			"h": 22.1407,
			"l": 22.1393,
			"t": 1344457560000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1411,
			"o": 22.1411,
			"c": 22.1411,
			"h": 22.1411,
			"l": 22.1411,
			"t": 1344457860000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.1394,
			"o": 22.1393,
			"c": 22.1396,
			"h": 22.1396,
			"l": 22.1393,
			"t": 1344457980000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.1386,
			"o": 22.1393,
			"c": 22.1375,
			"h": 22.1393,
			"l": 22.1375,
			"t": 1344458040000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.1376,
			"o": 22.1389,
			"c": 22.1357,
			"h": 22.1393,
			"l": 22.1357,
			"t": 1344458100000,
			"n": 4
		  },
		  {
			"v": 7616,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458280000,
			"n": 1
		  },
		  {
			"v": 31584,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458460000,
			"n": 6
		  },
		  {
			"v": 30800,
			"vw": 22.1647,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458520000,
			"n": 2
		  },
		  {
			"v": 3192,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458580000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458640000,
			"n": 2
		  },
		  {
			"v": 16968,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458760000,
			"n": 2
		  },
		  {
			"v": 18200,
			"vw": 22.1399,
			"o": 22.1421,
			"c": 22.1393,
			"h": 22.1421,
			"l": 22.1393,
			"t": 1344458820000,
			"n": 2
		  },
		  {
			"v": 3500,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344458880000,
			"n": 1
		  },
		  {
			"v": 23800,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344459000000,
			"n": 2
		  },
		  {
			"v": 25200,
			"vw": 22.1381,
			"o": 22.1382,
			"c": 22.1375,
			"h": 22.1393,
			"l": 22.1375,
			"t": 1344459060000,
			"n": 6
		  },
		  {
			"v": 40152,
			"vw": 22.1377,
			"o": 22.1379,
			"c": 22.1379,
			"h": 22.1379,
			"l": 22.1375,
			"t": 1344459120000,
			"n": 8
		  },
		  {
			"v": 56000,
			"vw": 22.1378,
			"o": 22.1379,
			"c": 22.1379,
			"h": 22.1379,
			"l": 22.1371,
			"t": 1344459180000,
			"n": 8
		  },
		  {
			"v": 19600,
			"vw": 22.1378,
			"o": 22.1379,
			"c": 22.1375,
			"h": 22.1379,
			"l": 22.1375,
			"t": 1344459240000,
			"n": 6
		  },
		  {
			"v": 5600,
			"vw": 22.1379,
			"o": 22.1379,
			"c": 22.1379,
			"h": 22.1379,
			"l": 22.1379,
			"t": 1344459300000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.1375,
			"o": 22.1375,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1375,
			"t": 1344459360000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1375,
			"o": 22.1375,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1375,
			"t": 1344459420000,
			"n": 1
		  },
		  {
			"v": 61320,
			"vw": 22.1374,
			"o": 22.1375,
			"c": 22.1364,
			"h": 22.1375,
			"l": 22.1364,
			"t": 1344459480000,
			"n": 5
		  },
		  {
			"v": 53760,
			"vw": 22.1378,
			"o": 22.1375,
			"c": 22.1389,
			"h": 22.1389,
			"l": 22.1375,
			"t": 1344459540000,
			"n": 7
		  },
		  {
			"v": 81200,
			"vw": 22.1386,
			"o": 22.1375,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1361,
			"t": 1344459600000,
			"n": 18
		  },
		  {
			"v": 78288,
			"vw": 22.1359,
			"o": 22.1375,
			"c": 22.1357,
			"h": 22.1375,
			"l": 22.1357,
			"t": 1344459660000,
			"n": 8
		  },
		  {
			"v": 78204,
			"vw": 22.134,
			"o": 22.1357,
			"c": 22.1321,
			"h": 22.1357,
			"l": 22.1321,
			"t": 1344459720000,
			"n": 11
		  },
		  {
			"v": 25200,
			"vw": 22.1321,
			"o": 22.1321,
			"c": 22.1321,
			"h": 22.1321,
			"l": 22.1321,
			"t": 1344459780000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1354,
			"o": 22.1354,
			"c": 22.1354,
			"h": 22.1354,
			"l": 22.1354,
			"t": 1344459840000,
			"n": 1
		  },
		  {
			"v": 9212,
			"vw": 22.1329,
			"o": 22.1329,
			"c": 22.1329,
			"h": 22.1329,
			"l": 22.1329,
			"t": 1344460440000,
			"n": 1
		  },
		  {
			"v": 12600,
			"vw": 22.1304,
			"o": 22.1304,
			"c": 22.1304,
			"h": 22.1304,
			"l": 22.1304,
			"t": 1344460500000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.135,
			"o": 22.135,
			"c": 22.135,
			"h": 22.135,
			"l": 22.135,
			"t": 1344460680000,
			"n": 1
		  },
		  {
			"v": 30352,
			"vw": 22.1373,
			"o": 22.1368,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1368,
			"t": 1344461040000,
			"n": 4
		  },
		  {
			"v": 30240,
			"vw": 22.1375,
			"o": 22.1375,
			"c": 22.1375,
			"h": 22.1375,
			"l": 22.1375,
			"t": 1344461220000,
			"n": 2
		  },
		  {
			"v": 53816,
			"vw": 22.144,
			"o": 22.1446,
			"c": 22.1343,
			"h": 22.1482,
			"l": 22.1343,
			"t": 1344461700000,
			"n": 10
		  },
		  {
			"v": 19796,
			"vw": 22.1486,
			"o": 22.1464,
			"c": 22.1489,
			"h": 22.1489,
			"l": 22.1464,
			"t": 1344461940000,
			"n": 4
		  },
		  {
			"v": 37856,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344462060000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344462420000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344462600000,
			"n": 1
		  },
		  {
			"v": 37380,
			"vw": 22.1452,
			"o": 22.1464,
			"c": 22.1429,
			"h": 22.1464,
			"l": 22.1375,
			"t": 1344462660000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.1443,
			"o": 22.1443,
			"c": 22.1443,
			"h": 22.1443,
			"l": 22.1443,
			"t": 1344463860000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1451,
			"o": 22.1454,
			"c": 22.145,
			"h": 22.1454,
			"l": 22.145,
			"t": 1344464040000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.145,
			"o": 22.145,
			"c": 22.145,
			"h": 22.145,
			"l": 22.145,
			"t": 1344464160000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.145,
			"o": 22.145,
			"c": 22.145,
			"h": 22.145,
			"l": 22.145,
			"t": 1344464220000,
			"n": 1
		  },
		  {
			"v": 20384,
			"vw": 22.1502,
			"o": 22.15,
			"c": 22.15,
			"h": 22.1518,
			"l": 22.15,
			"t": 1344464760000,
			"n": 3
		  },
		  {
			"v": 7280,
			"vw": 22.1478,
			"o": 22.1518,
			"c": 22.1454,
			"h": 22.1518,
			"l": 22.1454,
			"t": 1344464880000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1452,
			"o": 22.145,
			"c": 22.1454,
			"h": 22.1454,
			"l": 22.145,
			"t": 1344465000000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344465120000,
			"n": 1
		  },
		  {
			"v": 4256,
			"vw": 22.145,
			"o": 22.145,
			"c": 22.145,
			"h": 22.145,
			"l": 22.145,
			"t": 1344465600000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344465660000,
			"n": 1
		  },
		  {
			"v": 5180,
			"vw": 22.1436,
			"o": 22.1436,
			"c": 22.1436,
			"h": 22.1436,
			"l": 22.1436,
			"t": 1344465720000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1436,
			"o": 22.1436,
			"c": 22.1436,
			"h": 22.1436,
			"l": 22.1436,
			"t": 1344465780000,
			"n": 1
		  },
		  {
			"v": 11368,
			"vw": 22.1484,
			"o": 22.1461,
			"c": 22.1493,
			"h": 22.1493,
			"l": 22.1461,
			"t": 1344466860000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.15,
			"o": 22.15,
			"c": 22.15,
			"h": 22.15,
			"l": 22.15,
			"t": 1344467220000,
			"n": 1
		  },
		  {
			"v": 7756,
			"vw": 22.15,
			"o": 22.15,
			"c": 22.15,
			"h": 22.15,
			"l": 22.15,
			"t": 1344467280000,
			"n": 1
		  },
		  {
			"v": 19068,
			"vw": 22.1518,
			"o": 22.1518,
			"c": 22.1518,
			"h": 22.1518,
			"l": 22.1518,
			"t": 1344467400000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1518,
			"o": 22.1518,
			"c": 22.1518,
			"h": 22.1518,
			"l": 22.1518,
			"t": 1344467580000,
			"n": 1
		  },
		  {
			"v": 4984,
			"vw": 22.1564,
			"o": 22.1564,
			"c": 22.1564,
			"h": 22.1564,
			"l": 22.1564,
			"t": 1344467940000,
			"n": 1
		  },
		  {
			"v": 22400,
			"vw": 22.1527,
			"o": 22.1536,
			"c": 22.1518,
			"h": 22.1536,
			"l": 22.1518,
			"t": 1344468120000,
			"n": 3
		  },
		  {
			"v": 25200,
			"vw": 22.1422,
			"o": 22.145,
			"c": 22.1432,
			"h": 22.145,
			"l": 22.1411,
			"t": 1344468480000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.1464,
			"o": 22.1464,
			"c": 22.1464,
			"h": 22.1464,
			"l": 22.1464,
			"t": 1344469080000,
			"n": 1
		  },
		  {
			"v": 98448,
			"vw": 22.1418,
			"o": 22.1446,
			"c": 22.1411,
			"h": 22.1446,
			"l": 22.1411,
			"t": 1344469500000,
			"n": 12
		  },
		  {
			"v": 5600,
			"vw": 22.1475,
			"o": 22.1475,
			"c": 22.1475,
			"h": 22.1475,
			"l": 22.1475,
			"t": 1344469560000,
			"n": 1
		  },
		  {
			"v": 4256,
			"vw": 22.1471,
			"o": 22.1471,
			"c": 22.1471,
			"h": 22.1471,
			"l": 22.1471,
			"t": 1344469680000,
			"n": 1
		  },
		  {
			"v": 177100,
			"vw": 22.1402,
			"o": 22.1418,
			"c": 22.1386,
			"h": 22.1418,
			"l": 22.1386,
			"t": 1344470100000,
			"n": 16
		  },
		  {
			"v": 55440,
			"vw": 22.1386,
			"o": 22.1386,
			"c": 22.1386,
			"h": 22.1386,
			"l": 22.1386,
			"t": 1344470160000,
			"n": 2
		  },
		  {
			"v": 39200,
			"vw": 22.1383,
			"o": 22.1386,
			"c": 22.1375,
			"h": 22.1386,
			"l": 22.1375,
			"t": 1344470220000,
			"n": 5
		  },
		  {
			"v": 14000,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1393,
			"t": 1344470280000,
			"n": 1
		  },
		  {
			"v": 67200,
			"vw": 22.1375,
			"o": 22.1379,
			"c": 22.1375,
			"h": 22.1393,
			"l": 22.1368,
			"t": 1344470340000,
			"n": 8
		  },
		  {
			"v": 4200,
			"vw": 22.1179,
			"o": 22.1179,
			"c": 22.1179,
			"h": 22.1179,
			"l": 22.1179,
			"t": 1344499200000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344510420000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.0749,
			"o": 22.0907,
			"c": 22.0714,
			"h": 22.0907,
			"l": 22.0714,
			"t": 1344510480000,
			"n": 6
		  },
		  {
			"v": 5600,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344510540000,
			"n": 1
		  },
		  {
			"v": 5880,
			"vw": 22.0864,
			"o": 22.0857,
			"c": 22.0871,
			"h": 22.0871,
			"l": 22.0857,
			"t": 1344510600000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0732,
			"o": 22.0732,
			"c": 22.0732,
			"h": 22.0732,
			"l": 22.0732,
			"t": 1344510900000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344510960000,
			"n": 1
		  },
		  {
			"v": 22400,
			"vw": 22.0685,
			"o": 22.0732,
			"c": 22.0661,
			"h": 22.0732,
			"l": 22.0661,
			"t": 1344511020000,
			"n": 8
		  },
		  {
			"v": 2800,
			"vw": 22.0671,
			"o": 22.0671,
			"c": 22.0671,
			"h": 22.0671,
			"l": 22.0671,
			"t": 1344511140000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0661,
			"o": 22.0661,
			"c": 22.0661,
			"h": 22.0661,
			"l": 22.0661,
			"t": 1344511200000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344511380000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0679,
			"o": 22.0679,
			"c": 22.0679,
			"h": 22.0679,
			"l": 22.0679,
			"t": 1344511440000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.0664,
			"o": 22.0664,
			"c": 22.0664,
			"h": 22.0664,
			"l": 22.0664,
			"t": 1344511860000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0593,
			"o": 22.0593,
			"c": 22.0593,
			"h": 22.0593,
			"l": 22.0593,
			"t": 1344512400000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.0489,
			"o": 22.0518,
			"c": 22.0432,
			"h": 22.0518,
			"l": 22.0432,
			"t": 1344512700000,
			"n": 5
		  },
		  {
			"v": 37044,
			"vw": 22.0439,
			"o": 22.05,
			"c": 22.05,
			"h": 22.05,
			"l": 22.0411,
			"t": 1344512760000,
			"n": 4
		  },
		  {
			"v": 106400,
			"vw": 22.037,
			"o": 22.0464,
			"c": 22.005,
			"h": 22.0468,
			"l": 22.005,
			"t": 1344512820000,
			"n": 14
		  },
		  {
			"v": 14000,
			"vw": 22.0064,
			"o": 22.0089,
			"c": 22.0021,
			"h": 22.0089,
			"l": 22.0021,
			"t": 1344512880000,
			"n": 4
		  },
		  {
			"v": 35532,
			"vw": 22.028,
			"o": 22.0279,
			"c": 22.0354,
			"h": 22.0354,
			"l": 22.0107,
			"t": 1344512940000,
			"n": 11
		  },
		  {
			"v": 41664,
			"vw": 22.0203,
			"o": 22.0275,
			"c": 22.0196,
			"h": 22.0275,
			"l": 22.0179,
			"t": 1344513060000,
			"n": 13
		  },
		  {
			"v": 13328,
			"vw": 22.0217,
			"o": 22.0182,
			"c": 22.0268,
			"h": 22.0268,
			"l": 22.0179,
			"t": 1344513120000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.0236,
			"o": 22.0325,
			"c": 22.0214,
			"h": 22.0325,
			"l": 22.0214,
			"t": 1344513180000,
			"n": 4
		  },
		  {
			"v": 27720,
			"vw": 22.0233,
			"o": 22.0236,
			"c": 22.0232,
			"h": 22.0236,
			"l": 22.0232,
			"t": 1344513300000,
			"n": 5
		  },
		  {
			"v": 140700,
			"vw": 22.0234,
			"o": 22.0261,
			"c": 22.0182,
			"h": 22.0261,
			"l": 22.0182,
			"t": 1344513360000,
			"n": 28
		  },
		  {
			"v": 126140,
			"vw": 22.0278,
			"o": 22.0254,
			"c": 22.0357,
			"h": 22.0357,
			"l": 22.0179,
			"t": 1344513420000,
			"n": 23
		  },
		  {
			"v": 28000,
			"vw": 22.0347,
			"o": 22.0386,
			"c": 22.0396,
			"h": 22.0396,
			"l": 22.0271,
			"t": 1344513480000,
			"n": 6
		  },
		  {
			"v": 56000,
			"vw": 22.0416,
			"o": 22.0396,
			"c": 22.05,
			"h": 22.05,
			"l": 22.0396,
			"t": 1344513540000,
			"n": 8
		  },
		  {
			"v": 81060,
			"vw": 22.0403,
			"o": 22.0536,
			"c": 22.0325,
			"h": 22.0536,
			"l": 22.0325,
			"t": 1344513600000,
			"n": 22
		  },
		  {
			"v": 63364,
			"vw": 22.0207,
			"o": 22.0225,
			"c": 22.0179,
			"h": 22.0339,
			"l": 22.0179,
			"t": 1344513720000,
			"n": 13
		  },
		  {
			"v": 17276,
			"vw": 22.0274,
			"o": 22.0282,
			"c": 22.0336,
			"h": 22.0336,
			"l": 22.0254,
			"t": 1344513780000,
			"n": 5
		  },
		  {
			"v": 16800,
			"vw": 22.0217,
			"o": 22.0214,
			"c": 22.0318,
			"h": 22.0318,
			"l": 22.0182,
			"t": 1344513840000,
			"n": 6
		  },
		  {
			"v": 68712,
			"vw": 22.0286,
			"o": 22.0314,
			"c": 22.0357,
			"h": 22.0357,
			"l": 22.025,
			"t": 1344513900000,
			"n": 8
		  },
		  {
			"v": 125720,
			"vw": 22.0263,
			"o": 22.0271,
			"c": 22.0268,
			"h": 22.0275,
			"l": 22.025,
			"t": 1344514020000,
			"n": 18
		  },
		  {
			"v": 77504,
			"vw": 22.0272,
			"o": 22.0286,
			"c": 22.0268,
			"h": 22.0286,
			"l": 22.0268,
			"t": 1344514080000,
			"n": 11
		  },
		  {
			"v": 52920,
			"vw": 22.0334,
			"o": 22.025,
			"c": 22.0443,
			"h": 22.0443,
			"l": 22.025,
			"t": 1344514140000,
			"n": 11
		  },
		  {
			"v": 20468,
			"vw": 22.0342,
			"o": 22.0329,
			"c": 22.0389,
			"h": 22.0396,
			"l": 22.0304,
			"t": 1344514200000,
			"n": 5
		  },
		  {
			"v": 8400,
			"vw": 22.034,
			"o": 22.0318,
			"c": 22.0332,
			"h": 22.0371,
			"l": 22.0318,
			"t": 1344514260000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.0306,
			"o": 22.0332,
			"c": 22.0293,
			"h": 22.0332,
			"l": 22.0293,
			"t": 1344514320000,
			"n": 4
		  },
		  {
			"v": 18592,
			"vw": 22.032,
			"o": 22.0339,
			"c": 22.03,
			"h": 22.0354,
			"l": 22.03,
			"t": 1344514380000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0354,
			"o": 22.0354,
			"c": 22.0354,
			"h": 22.0354,
			"l": 22.0354,
			"t": 1344514440000,
			"n": 1
		  },
		  {
			"v": 28000,
			"vw": 22.0357,
			"o": 22.0357,
			"c": 22.0357,
			"h": 22.0357,
			"l": 22.0357,
			"t": 1344514680000,
			"n": 7
		  },
		  {
			"v": 5600,
			"vw": 22.0393,
			"o": 22.0393,
			"c": 22.0393,
			"h": 22.0393,
			"l": 22.0393,
			"t": 1344514740000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0429,
			"o": 22.0429,
			"c": 22.0429,
			"h": 22.0429,
			"l": 22.0429,
			"t": 1344514800000,
			"n": 1
		  },
		  {
			"v": 39200,
			"vw": 22.0469,
			"o": 22.0446,
			"c": 22.0518,
			"h": 22.0521,
			"l": 22.0446,
			"t": 1344514860000,
			"n": 14
		  },
		  {
			"v": 28000,
			"vw": 22.0521,
			"o": 22.0521,
			"c": 22.0518,
			"h": 22.0536,
			"l": 22.0518,
			"t": 1344514920000,
			"n": 7
		  },
		  {
			"v": 5600,
			"vw": 22.0471,
			"o": 22.0479,
			"c": 22.0464,
			"h": 22.0479,
			"l": 22.0464,
			"t": 1344514980000,
			"n": 2
		  },
		  {
			"v": 22400,
			"vw": 22.0469,
			"o": 22.0457,
			"c": 22.05,
			"h": 22.05,
			"l": 22.0446,
			"t": 1344515100000,
			"n": 8
		  },
		  {
			"v": 12600,
			"vw": 22.0504,
			"o": 22.0504,
			"c": 22.0504,
			"h": 22.0504,
			"l": 22.0504,
			"t": 1344515160000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0504,
			"o": 22.0504,
			"c": 22.0504,
			"h": 22.0504,
			"l": 22.0504,
			"t": 1344515220000,
			"n": 1
		  },
		  {
			"v": 33600,
			"vw": 22.0504,
			"o": 22.0504,
			"c": 22.0504,
			"h": 22.0504,
			"l": 22.0504,
			"t": 1344515280000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 22.0473,
			"o": 22.0504,
			"c": 22.045,
			"h": 22.0504,
			"l": 22.0446,
			"t": 1344515340000,
			"n": 5
		  },
		  {
			"v": 53200,
			"vw": 22.0542,
			"o": 22.05,
			"c": 22.0571,
			"h": 22.0571,
			"l": 22.0461,
			"t": 1344515400000,
			"n": 19
		  },
		  {
			"v": 33600,
			"vw": 22.064,
			"o": 22.0625,
			"c": 22.0668,
			"h": 22.0668,
			"l": 22.0625,
			"t": 1344515460000,
			"n": 12
		  },
		  {
			"v": 39200,
			"vw": 22.0605,
			"o": 22.0654,
			"c": 22.0539,
			"h": 22.0654,
			"l": 22.0539,
			"t": 1344515520000,
			"n": 13
		  },
		  {
			"v": 83272,
			"vw": 22.058,
			"o": 22.0536,
			"c": 22.0446,
			"h": 22.0714,
			"l": 22.0446,
			"t": 1344515580000,
			"n": 25
		  },
		  {
			"v": 2800,
			"vw": 22.0536,
			"o": 22.0536,
			"c": 22.0536,
			"h": 22.0536,
			"l": 22.0536,
			"t": 1344515640000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 22.0596,
			"o": 22.0596,
			"c": 22.0611,
			"h": 22.0611,
			"l": 22.0582,
			"t": 1344515700000,
			"n": 10
		  },
		  {
			"v": 67200,
			"vw": 22.0608,
			"o": 22.0614,
			"c": 22.0589,
			"h": 22.0618,
			"l": 22.0589,
			"t": 1344515760000,
			"n": 17
		  },
		  {
			"v": 77420,
			"vw": 22.0606,
			"o": 22.0586,
			"c": 22.0596,
			"h": 22.0618,
			"l": 22.0586,
			"t": 1344515820000,
			"n": 19
		  },
		  {
			"v": 26600,
			"vw": 22.067,
			"o": 22.0636,
			"c": 22.07,
			"h": 22.0711,
			"l": 22.0636,
			"t": 1344515880000,
			"n": 9
		  },
		  {
			"v": 25704,
			"vw": 22.0733,
			"o": 22.07,
			"c": 22.0736,
			"h": 22.0793,
			"l": 22.07,
			"t": 1344515940000,
			"n": 8
		  },
		  {
			"v": 25200,
			"vw": 22.0808,
			"o": 22.0789,
			"c": 22.0854,
			"h": 22.0854,
			"l": 22.0786,
			"t": 1344516000000,
			"n": 8
		  },
		  {
			"v": 30800,
			"vw": 22.0815,
			"o": 22.0829,
			"c": 22.0786,
			"h": 22.0854,
			"l": 22.0786,
			"t": 1344516060000,
			"n": 8
		  },
		  {
			"v": 26600,
			"vw": 22.0804,
			"o": 22.0796,
			"c": 22.0804,
			"h": 22.0818,
			"l": 22.0796,
			"t": 1344516120000,
			"n": 9
		  },
		  {
			"v": 92400,
			"vw": 22.0832,
			"o": 22.0818,
			"c": 22.0821,
			"h": 22.0889,
			"l": 22.0771,
			"t": 1344516180000,
			"n": 23
		  },
		  {
			"v": 22960,
			"vw": 22.0807,
			"o": 22.08,
			"c": 22.0818,
			"h": 22.0818,
			"l": 22.08,
			"t": 1344516240000,
			"n": 6
		  },
		  {
			"v": 25200,
			"vw": 22.0757,
			"o": 22.0814,
			"c": 22.0746,
			"h": 22.0814,
			"l": 22.0746,
			"t": 1344516300000,
			"n": 9
		  },
		  {
			"v": 60760,
			"vw": 22.0726,
			"o": 22.0746,
			"c": 22.0721,
			"h": 22.0746,
			"l": 22.0714,
			"t": 1344516360000,
			"n": 17
		  },
		  {
			"v": 37520,
			"vw": 22.0712,
			"o": 22.0654,
			"c": 22.0707,
			"h": 22.0746,
			"l": 22.0643,
			"t": 1344516420000,
			"n": 8
		  },
		  {
			"v": 42000,
			"vw": 22.0726,
			"o": 22.0714,
			"c": 22.075,
			"h": 22.075,
			"l": 22.0714,
			"t": 1344516540000,
			"n": 10
		  },
		  {
			"v": 3136,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344516600000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0782,
			"o": 22.0782,
			"c": 22.0782,
			"h": 22.0782,
			"l": 22.0782,
			"t": 1344516660000,
			"n": 1
		  },
		  {
			"v": 88732,
			"vw": 22.0694,
			"o": 22.0721,
			"c": 22.0632,
			"h": 22.0736,
			"l": 22.0632,
			"t": 1344516720000,
			"n": 18
		  },
		  {
			"v": 8400,
			"vw": 22.0655,
			"o": 22.0643,
			"c": 22.0664,
			"h": 22.0664,
			"l": 22.0643,
			"t": 1344516900000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.0685,
			"o": 22.0679,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0679,
			"t": 1344516960000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344517080000,
			"n": 1
		  },
		  {
			"v": 34412,
			"vw": 22.0719,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0746,
			"l": 22.0714,
			"t": 1344517140000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 22.075,
			"o": 22.075,
			"c": 22.075,
			"h": 22.075,
			"l": 22.075,
			"t": 1344517200000,
			"n": 2
		  },
		  {
			"v": 40600,
			"vw": 22.0821,
			"o": 22.0789,
			"c": 22.0839,
			"h": 22.0839,
			"l": 22.0789,
			"t": 1344517260000,
			"n": 10
		  },
		  {
			"v": 131600,
			"vw": 22.0827,
			"o": 22.0886,
			"c": 22.0868,
			"h": 22.0893,
			"l": 22.0793,
			"t": 1344517320000,
			"n": 19
		  },
		  {
			"v": 64288,
			"vw": 22.0814,
			"o": 22.0871,
			"c": 22.0804,
			"h": 22.0871,
			"l": 22.0804,
			"t": 1344517380000,
			"n": 13
		  },
		  {
			"v": 27300,
			"vw": 22.0825,
			"o": 22.0868,
			"c": 22.0839,
			"h": 22.0868,
			"l": 22.0793,
			"t": 1344517440000,
			"n": 5
		  },
		  {
			"v": 14000,
			"vw": 22.0806,
			"o": 22.0804,
			"c": 22.0821,
			"h": 22.0821,
			"l": 22.0796,
			"t": 1344517500000,
			"n": 4
		  },
		  {
			"v": 23100,
			"vw": 22.0821,
			"o": 22.0821,
			"c": 22.0821,
			"h": 22.0821,
			"l": 22.0821,
			"t": 1344517560000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.0854,
			"o": 22.0821,
			"c": 22.0886,
			"h": 22.0886,
			"l": 22.0821,
			"t": 1344517680000,
			"n": 2
		  },
		  {
			"v": 67900,
			"vw": 22.0911,
			"o": 22.0875,
			"c": 22.0968,
			"h": 22.0979,
			"l": 22.0875,
			"t": 1344517740000,
			"n": 19
		  },
		  {
			"v": 45500,
			"vw": 22.0901,
			"o": 22.0961,
			"c": 22.0882,
			"h": 22.0961,
			"l": 22.0882,
			"t": 1344517800000,
			"n": 13
		  },
		  {
			"v": 16940,
			"vw": 22.0927,
			"o": 22.0896,
			"c": 22.0954,
			"h": 22.0954,
			"l": 22.0893,
			"t": 1344517860000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.0954,
			"o": 22.0954,
			"c": 22.0954,
			"h": 22.0954,
			"l": 22.0954,
			"t": 1344517920000,
			"n": 1
		  },
		  {
			"v": 118860,
			"vw": 22.0954,
			"o": 22.0971,
			"c": 22.0889,
			"h": 22.0986,
			"l": 22.0889,
			"t": 1344517980000,
			"n": 28
		  },
		  {
			"v": 60620,
			"vw": 22.0882,
			"o": 22.0882,
			"c": 22.0871,
			"h": 22.0893,
			"l": 22.0871,
			"t": 1344518040000,
			"n": 10
		  },
		  {
			"v": 56000,
			"vw": 22.0799,
			"o": 22.0864,
			"c": 22.0804,
			"h": 22.0879,
			"l": 22.0736,
			"t": 1344518100000,
			"n": 12
		  },
		  {
			"v": 108472,
			"vw": 22.0744,
			"o": 22.0779,
			"c": 22.0714,
			"h": 22.0779,
			"l": 22.0714,
			"t": 1344518160000,
			"n": 11
		  },
		  {
			"v": 74424,
			"vw": 22.0686,
			"o": 22.0714,
			"c": 22.0664,
			"h": 22.0764,
			"l": 22.0646,
			"t": 1344518220000,
			"n": 15
		  },
		  {
			"v": 35700,
			"vw": 22.0669,
			"o": 22.0668,
			"c": 22.0661,
			"h": 22.0707,
			"l": 22.0661,
			"t": 1344518280000,
			"n": 9
		  },
		  {
			"v": 51800,
			"vw": 22.0708,
			"o": 22.0704,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0704,
			"t": 1344518340000,
			"n": 12
		  },
		  {
			"v": 23100,
			"vw": 22.0754,
			"o": 22.0768,
			"c": 22.0786,
			"h": 22.0804,
			"l": 22.0718,
			"t": 1344518400000,
			"n": 6
		  },
		  {
			"v": 63840,
			"vw": 22.0706,
			"o": 22.0721,
			"c": 22.0654,
			"h": 22.0721,
			"l": 22.0654,
			"t": 1344518460000,
			"n": 11
		  },
		  {
			"v": 16800,
			"vw": 22.0612,
			"o": 22.0643,
			"c": 22.0539,
			"h": 22.07,
			"l": 22.0539,
			"t": 1344518520000,
			"n": 6
		  },
		  {
			"v": 14000,
			"vw": 22.0541,
			"o": 22.0539,
			"c": 22.0539,
			"h": 22.0546,
			"l": 22.0539,
			"t": 1344518580000,
			"n": 3
		  },
		  {
			"v": 115808,
			"vw": 22.0545,
			"o": 22.0625,
			"c": 22.0536,
			"h": 22.0625,
			"l": 22.0536,
			"t": 1344518640000,
			"n": 14
		  },
		  {
			"v": 5600,
			"vw": 22.0536,
			"o": 22.0536,
			"c": 22.0536,
			"h": 22.0536,
			"l": 22.0536,
			"t": 1344518700000,
			"n": 2
		  },
		  {
			"v": 33600,
			"vw": 22.0643,
			"o": 22.0596,
			"c": 22.0675,
			"h": 22.0675,
			"l": 22.0582,
			"t": 1344518760000,
			"n": 5
		  },
		  {
			"v": 22288,
			"vw": 22.0615,
			"o": 22.06,
			"c": 22.0632,
			"h": 22.0632,
			"l": 22.06,
			"t": 1344518820000,
			"n": 7
		  },
		  {
			"v": 147980,
			"vw": 22.0646,
			"o": 22.0632,
			"c": 22.0661,
			"h": 22.0679,
			"l": 22.0632,
			"t": 1344518880000,
			"n": 29
		  },
		  {
			"v": 125664,
			"vw": 22.0681,
			"o": 22.0696,
			"c": 22.0661,
			"h": 22.0696,
			"l": 22.0661,
			"t": 1344518940000,
			"n": 14
		  },
		  {
			"v": 3834908,
			"vw": 22.0828,
			"o": 22.0661,
			"c": 22.1057,
			"h": 22.1196,
			"l": 22.0643,
			"t": 1344519000000,
			"n": 568
		  },
		  {
			"v": 1551844,
			"vw": 22.0988,
			"o": 22.1082,
			"c": 22.1063,
			"h": 22.1111,
			"l": 22.085,
			"t": 1344519060000,
			"n": 351
		  },
		  {
			"v": 1729028,
			"vw": 22.1155,
			"o": 22.1054,
			"c": 22.1254,
			"h": 22.1339,
			"l": 22.1021,
			"t": 1344519120000,
			"n": 425
		  },
		  {
			"v": 1641360,
			"vw": 22.1124,
			"o": 22.1218,
			"c": 22.1079,
			"h": 22.1281,
			"l": 22.0904,
			"t": 1344519180000,
			"n": 331
		  },
		  {
			"v": 775264,
			"vw": 22.1008,
			"o": 22.1086,
			"c": 22.094,
			"h": 22.1143,
			"l": 22.0893,
			"t": 1344519240000,
			"n": 170
		  },
		  {
			"v": 1144640,
			"vw": 22.1052,
			"o": 22.0939,
			"c": 22.115,
			"h": 22.1236,
			"l": 22.0907,
			"t": 1344519300000,
			"n": 199
		  },
		  {
			"v": 2444960,
			"vw": 22.1372,
			"o": 22.1161,
			"c": 22.1556,
			"h": 22.1589,
			"l": 22.1136,
			"t": 1344519360000,
			"n": 452
		  },
		  {
			"v": 2256772,
			"vw": 22.1522,
			"o": 22.1514,
			"c": 22.1607,
			"h": 22.1679,
			"l": 22.1357,
			"t": 1344519420000,
			"n": 421
		  },
		  {
			"v": 1497468,
			"vw": 22.1648,
			"o": 22.1565,
			"c": 22.1707,
			"h": 22.1714,
			"l": 22.1565,
			"t": 1344519480000,
			"n": 339
		  },
		  {
			"v": 1085616,
			"vw": 22.1652,
			"o": 22.1707,
			"c": 22.1636,
			"h": 22.1707,
			"l": 22.1575,
			"t": 1344519540000,
			"n": 247
		  },
		  {
			"v": 2200660,
			"vw": 22.1832,
			"o": 22.1639,
			"c": 22.1951,
			"h": 22.2,
			"l": 22.1611,
			"t": 1344519600000,
			"n": 499
		  },
		  {
			"v": 1538908,
			"vw": 22.1857,
			"o": 22.1961,
			"c": 22.1789,
			"h": 22.2,
			"l": 22.1704,
			"t": 1344519660000,
			"n": 339
		  },
		  {
			"v": 902636,
			"vw": 22.1759,
			"o": 22.1786,
			"c": 22.1675,
			"h": 22.1843,
			"l": 22.1661,
			"t": 1344519720000,
			"n": 176
		  },
		  {
			"v": 1404256,
			"vw": 22.1597,
			"o": 22.1686,
			"c": 22.1607,
			"h": 22.1718,
			"l": 22.1471,
			"t": 1344519780000,
			"n": 311
		  },
		  {
			"v": 1133244,
			"vw": 22.1667,
			"o": 22.1573,
			"c": 22.1643,
			"h": 22.1786,
			"l": 22.1525,
			"t": 1344519840000,
			"n": 244
		  },
		  {
			"v": 973840,
			"vw": 22.1724,
			"o": 22.1661,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1629,
			"t": 1344519900000,
			"n": 208
		  },
		  {
			"v": 960652,
			"vw": 22.1769,
			"o": 22.1818,
			"c": 22.1814,
			"h": 22.1835,
			"l": 22.1714,
			"t": 1344519960000,
			"n": 148
		  },
		  {
			"v": 942788,
			"vw": 22.1792,
			"o": 22.1786,
			"c": 22.1729,
			"h": 22.1871,
			"l": 22.1721,
			"t": 1344520020000,
			"n": 136
		  },
		  {
			"v": 720776,
			"vw": 22.1685,
			"o": 22.1732,
			"c": 22.1657,
			"h": 22.1807,
			"l": 22.1536,
			"t": 1344520080000,
			"n": 142
		  },
		  {
			"v": 474628,
			"vw": 22.1591,
			"o": 22.1657,
			"c": 22.1625,
			"h": 22.1675,
			"l": 22.1532,
			"t": 1344520140000,
			"n": 128
		  },
		  {
			"v": 646520,
			"vw": 22.1633,
			"o": 22.1625,
			"c": 22.1643,
			"h": 22.1679,
			"l": 22.1571,
			"t": 1344520200000,
			"n": 143
		  },
		  {
			"v": 608496,
			"vw": 22.1656,
			"o": 22.1656,
			"c": 22.1636,
			"h": 22.171,
			"l": 22.1571,
			"t": 1344520260000,
			"n": 116
		  },
		  {
			"v": 512344,
			"vw": 22.163,
			"o": 22.165,
			"c": 22.1653,
			"h": 22.1662,
			"l": 22.1582,
			"t": 1344520320000,
			"n": 112
		  },
		  {
			"v": 519960,
			"vw": 22.1638,
			"o": 22.1646,
			"c": 22.1674,
			"h": 22.1686,
			"l": 22.1582,
			"t": 1344520380000,
			"n": 108
		  },
		  {
			"v": 1285984,
			"vw": 22.1631,
			"o": 22.1671,
			"c": 22.1704,
			"h": 22.1729,
			"l": 22.1608,
			"t": 1344520440000,
			"n": 97
		  },
		  {
			"v": 258524,
			"vw": 22.1676,
			"o": 22.1689,
			"c": 22.1721,
			"h": 22.1721,
			"l": 22.159,
			"t": 1344520500000,
			"n": 68
		  },
		  {
			"v": 812700,
			"vw": 22.1724,
			"o": 22.1711,
			"c": 22.1718,
			"h": 22.1768,
			"l": 22.1671,
			"t": 1344520560000,
			"n": 186
		  },
		  {
			"v": 1465772,
			"vw": 22.1831,
			"o": 22.1756,
			"c": 22.1789,
			"h": 22.1914,
			"l": 22.1729,
			"t": 1344520620000,
			"n": 314
		  },
		  {
			"v": 985432,
			"vw": 22.1735,
			"o": 22.1789,
			"c": 22.1647,
			"h": 22.1856,
			"l": 22.1647,
			"t": 1344520680000,
			"n": 152
		  },
		  {
			"v": 1550836,
			"vw": 22.1578,
			"o": 22.1675,
			"c": 22.1593,
			"h": 22.1689,
			"l": 22.1493,
			"t": 1344520740000,
			"n": 320
		  },
		  {
			"v": 903560,
			"vw": 22.1659,
			"o": 22.1554,
			"c": 22.1664,
			"h": 22.1707,
			"l": 22.1546,
			"t": 1344520800000,
			"n": 190
		  },
		  {
			"v": 1783096,
			"vw": 22.1615,
			"o": 22.1664,
			"c": 22.1613,
			"h": 22.1714,
			"l": 22.1554,
			"t": 1344520860000,
			"n": 170
		  },
		  {
			"v": 726432,
			"vw": 22.1553,
			"o": 22.16,
			"c": 22.1579,
			"h": 22.1621,
			"l": 22.1486,
			"t": 1344520920000,
			"n": 166
		  },
		  {
			"v": 647248,
			"vw": 22.1566,
			"o": 22.1579,
			"c": 22.1596,
			"h": 22.1607,
			"l": 22.1507,
			"t": 1344520980000,
			"n": 120
		  },
		  {
			"v": 415352,
			"vw": 22.156,
			"o": 22.1596,
			"c": 22.1546,
			"h": 22.1607,
			"l": 22.1525,
			"t": 1344521040000,
			"n": 96
		  },
		  {
			"v": 573160,
			"vw": 22.1559,
			"o": 22.155,
			"c": 22.1586,
			"h": 22.1596,
			"l": 22.1504,
			"t": 1344521100000,
			"n": 116
		  },
		  {
			"v": 588056,
			"vw": 22.1558,
			"o": 22.1582,
			"c": 22.1489,
			"h": 22.1607,
			"l": 22.1486,
			"t": 1344521160000,
			"n": 135
		  },
		  {
			"v": 521668,
			"vw": 22.1537,
			"o": 22.1489,
			"c": 22.1604,
			"h": 22.1604,
			"l": 22.1489,
			"t": 1344521220000,
			"n": 110
		  },
		  {
			"v": 732284,
			"vw": 22.1603,
			"o": 22.1604,
			"c": 22.1546,
			"h": 22.1636,
			"l": 22.15,
			"t": 1344521280000,
			"n": 65
		  },
		  {
			"v": 1416828,
			"vw": 22.1469,
			"o": 22.1554,
			"c": 22.1355,
			"h": 22.1571,
			"l": 22.1311,
			"t": 1344521340000,
			"n": 276
		  },
		  {
			"v": 734356,
			"vw": 22.14,
			"o": 22.1375,
			"c": 22.1443,
			"h": 22.1482,
			"l": 22.1321,
			"t": 1344521400000,
			"n": 178
		  },
		  {
			"v": 672280,
			"vw": 22.1428,
			"o": 22.1407,
			"c": 22.15,
			"h": 22.15,
			"l": 22.1386,
			"t": 1344521460000,
			"n": 173
		  },
		  {
			"v": 409472,
			"vw": 22.146,
			"o": 22.1473,
			"c": 22.1479,
			"h": 22.15,
			"l": 22.1429,
			"t": 1344521520000,
			"n": 89
		  },
		  {
			"v": 695688,
			"vw": 22.1438,
			"o": 22.1482,
			"c": 22.1447,
			"h": 22.15,
			"l": 22.1361,
			"t": 1344521580000,
			"n": 130
		  },
		  {
			"v": 446264,
			"vw": 22.1403,
			"o": 22.1421,
			"c": 22.1375,
			"h": 22.1454,
			"l": 22.1371,
			"t": 1344521640000,
			"n": 106
		  },
		  {
			"v": 298340,
			"vw": 22.1422,
			"o": 22.1407,
			"c": 22.1414,
			"h": 22.1439,
			"l": 22.14,
			"t": 1344521700000,
			"n": 72
		  },
		  {
			"v": 560420,
			"vw": 22.1507,
			"o": 22.1429,
			"c": 22.1571,
			"h": 22.1571,
			"l": 22.1426,
			"t": 1344521760000,
			"n": 138
		  },
		  {
			"v": 420952,
			"vw": 22.156,
			"o": 22.1568,
			"c": 22.1518,
			"h": 22.16,
			"l": 22.1496,
			"t": 1344521820000,
			"n": 101
		  },
		  {
			"v": 227360,
			"vw": 22.1532,
			"o": 22.1511,
			"c": 22.1539,
			"h": 22.1571,
			"l": 22.1486,
			"t": 1344521880000,
			"n": 58
		  },
		  {
			"v": 556052,
			"vw": 22.1599,
			"o": 22.1532,
			"c": 22.1668,
			"h": 22.1675,
			"l": 22.1493,
			"t": 1344521940000,
			"n": 123
		  },
		  {
			"v": 1498560,
			"vw": 22.1753,
			"o": 22.1675,
			"c": 22.1786,
			"h": 22.1821,
			"l": 22.1614,
			"t": 1344522000000,
			"n": 303
		  },
		  {
			"v": 658784,
			"vw": 22.1787,
			"o": 22.1803,
			"c": 22.1753,
			"h": 22.1843,
			"l": 22.1729,
			"t": 1344522060000,
			"n": 133
		  },
		  {
			"v": 783384,
			"vw": 22.1714,
			"o": 22.1746,
			"c": 22.1729,
			"h": 22.1768,
			"l": 22.165,
			"t": 1344522120000,
			"n": 191
		  },
		  {
			"v": 348012,
			"vw": 22.1691,
			"o": 22.1732,
			"c": 22.1672,
			"h": 22.1736,
			"l": 22.1661,
			"t": 1344522180000,
			"n": 73
		  },
		  {
			"v": 267708,
			"vw": 22.1677,
			"o": 22.1675,
			"c": 22.1661,
			"h": 22.1696,
			"l": 22.1647,
			"t": 1344522240000,
			"n": 56
		  },
		  {
			"v": 640024,
			"vw": 22.1635,
			"o": 22.1675,
			"c": 22.1657,
			"h": 22.1679,
			"l": 22.1582,
			"t": 1344522300000,
			"n": 164
		  },
		  {
			"v": 945504,
			"vw": 22.1711,
			"o": 22.1611,
			"c": 22.1618,
			"h": 22.1786,
			"l": 22.1568,
			"t": 1344522360000,
			"n": 108
		  },
		  {
			"v": 316316,
			"vw": 22.1591,
			"o": 22.1589,
			"c": 22.1618,
			"h": 22.1639,
			"l": 22.1554,
			"t": 1344522420000,
			"n": 76
		  },
		  {
			"v": 560588,
			"vw": 22.1623,
			"o": 22.1618,
			"c": 22.165,
			"h": 22.165,
			"l": 22.1586,
			"t": 1344522480000,
			"n": 138
		  },
		  {
			"v": 411572,
			"vw": 22.1696,
			"o": 22.1654,
			"c": 22.1709,
			"h": 22.175,
			"l": 22.1629,
			"t": 1344522540000,
			"n": 87
		  },
		  {
			"v": 452732,
			"vw": 22.174,
			"o": 22.1721,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1696,
			"t": 1344522600000,
			"n": 119
		  },
		  {
			"v": 837396,
			"vw": 22.1811,
			"o": 22.1786,
			"c": 22.1789,
			"h": 22.1846,
			"l": 22.1765,
			"t": 1344522660000,
			"n": 163
		  },
		  {
			"v": 372204,
			"vw": 22.1787,
			"o": 22.179,
			"c": 22.181,
			"h": 22.1825,
			"l": 22.1746,
			"t": 1344522720000,
			"n": 90
		  },
		  {
			"v": 544012,
			"vw": 22.1817,
			"o": 22.1786,
			"c": 22.1874,
			"h": 22.1879,
			"l": 22.1736,
			"t": 1344522780000,
			"n": 121
		  },
		  {
			"v": 1464428,
			"vw": 22.1944,
			"o": 22.1871,
			"c": 22.1964,
			"h": 22.2046,
			"l": 22.1846,
			"t": 1344522840000,
			"n": 315
		  },
		  {
			"v": 1081948,
			"vw": 22.1953,
			"o": 22.1978,
			"c": 22.1929,
			"h": 22.2036,
			"l": 22.1861,
			"t": 1344522900000,
			"n": 246
		  },
		  {
			"v": 918764,
			"vw": 22.1881,
			"o": 22.1914,
			"c": 22.1896,
			"h": 22.1964,
			"l": 22.1829,
			"t": 1344522960000,
			"n": 185
		  },
		  {
			"v": 384636,
			"vw": 22.1865,
			"o": 22.1895,
			"c": 22.1891,
			"h": 22.1913,
			"l": 22.1829,
			"t": 1344523020000,
			"n": 85
		  },
		  {
			"v": 999516,
			"vw": 22.1823,
			"o": 22.1888,
			"c": 22.1825,
			"h": 22.1925,
			"l": 22.1786,
			"t": 1344523080000,
			"n": 162
		  },
		  {
			"v": 773696,
			"vw": 22.1834,
			"o": 22.1825,
			"c": 22.1852,
			"h": 22.1896,
			"l": 22.1786,
			"t": 1344523140000,
			"n": 178
		  },
		  {
			"v": 406196,
			"vw": 22.1876,
			"o": 22.1871,
			"c": 22.1891,
			"h": 22.1929,
			"l": 22.1829,
			"t": 1344523200000,
			"n": 98
		  },
		  {
			"v": 1661380,
			"vw": 22.1798,
			"o": 22.1846,
			"c": 22.1843,
			"h": 22.1879,
			"l": 22.175,
			"t": 1344523260000,
			"n": 226
		  },
		  {
			"v": 815024,
			"vw": 22.1786,
			"o": 22.1825,
			"c": 22.1786,
			"h": 22.1848,
			"l": 22.1707,
			"t": 1344523320000,
			"n": 153
		  },
		  {
			"v": 521080,
			"vw": 22.1833,
			"o": 22.1775,
			"c": 22.1804,
			"h": 22.1846,
			"l": 22.1743,
			"t": 1344523380000,
			"n": 62
		  },
		  {
			"v": 420448,
			"vw": 22.181,
			"o": 22.1796,
			"c": 22.1789,
			"h": 22.1848,
			"l": 22.1771,
			"t": 1344523440000,
			"n": 94
		  },
		  {
			"v": 507416,
			"vw": 22.1793,
			"o": 22.1793,
			"c": 22.1804,
			"h": 22.1832,
			"l": 22.1771,
			"t": 1344523500000,
			"n": 104
		  },
		  {
			"v": 333424,
			"vw": 22.1816,
			"o": 22.1789,
			"c": 22.1839,
			"h": 22.1857,
			"l": 22.1775,
			"t": 1344523560000,
			"n": 74
		  },
		  {
			"v": 352100,
			"vw": 22.1828,
			"o": 22.1839,
			"c": 22.1804,
			"h": 22.1873,
			"l": 22.1793,
			"t": 1344523620000,
			"n": 85
		  },
		  {
			"v": 682276,
			"vw": 22.1833,
			"o": 22.1796,
			"c": 22.1826,
			"h": 22.1886,
			"l": 22.1786,
			"t": 1344523680000,
			"n": 133
		  },
		  {
			"v": 289184,
			"vw": 22.1828,
			"o": 22.1825,
			"c": 22.1893,
			"h": 22.1896,
			"l": 22.1786,
			"t": 1344523740000,
			"n": 72
		  },
		  {
			"v": 449092,
			"vw": 22.1873,
			"o": 22.1889,
			"c": 22.1871,
			"h": 22.1936,
			"l": 22.1821,
			"t": 1344523800000,
			"n": 98
		  },
		  {
			"v": 239988,
			"vw": 22.1879,
			"o": 22.1871,
			"c": 22.1911,
			"h": 22.1911,
			"l": 22.1832,
			"t": 1344523860000,
			"n": 51
		  },
		  {
			"v": 6088264,
			"vw": 22.1883,
			"o": 22.1911,
			"c": 22.1821,
			"h": 22.1929,
			"l": 22.1804,
			"t": 1344523920000,
			"n": 130
		  },
		  {
			"v": 605276,
			"vw": 22.1808,
			"o": 22.1827,
			"c": 22.18,
			"h": 22.1854,
			"l": 22.1786,
			"t": 1344523980000,
			"n": 158
		  },
		  {
			"v": 788340,
			"vw": 22.182,
			"o": 22.1796,
			"c": 22.1746,
			"h": 22.1893,
			"l": 22.1732,
			"t": 1344524040000,
			"n": 188
		  },
		  {
			"v": 282548,
			"vw": 22.1769,
			"o": 22.1732,
			"c": 22.1739,
			"h": 22.1811,
			"l": 22.1725,
			"t": 1344524100000,
			"n": 81
		  },
		  {
			"v": 339248,
			"vw": 22.177,
			"o": 22.1757,
			"c": 22.1764,
			"h": 22.1814,
			"l": 22.1736,
			"t": 1344524160000,
			"n": 78
		  },
		  {
			"v": 622468,
			"vw": 22.1698,
			"o": 22.1764,
			"c": 22.1643,
			"h": 22.1786,
			"l": 22.1643,
			"t": 1344524220000,
			"n": 147
		  },
		  {
			"v": 640948,
			"vw": 22.1693,
			"o": 22.1643,
			"c": 22.1695,
			"h": 22.1718,
			"l": 22.1643,
			"t": 1344524280000,
			"n": 119
		  },
		  {
			"v": 735084,
			"vw": 22.1713,
			"o": 22.1707,
			"c": 22.1793,
			"h": 22.1793,
			"l": 22.1671,
			"t": 1344524340000,
			"n": 146
		  },
		  {
			"v": 410788,
			"vw": 22.1784,
			"o": 22.1786,
			"c": 22.1793,
			"h": 22.1825,
			"l": 22.1771,
			"t": 1344524400000,
			"n": 86
		  },
		  {
			"v": 318136,
			"vw": 22.1786,
			"o": 22.1789,
			"c": 22.1786,
			"h": 22.1818,
			"l": 22.1746,
			"t": 1344524460000,
			"n": 75
		  },
		  {
			"v": 954324,
			"vw": 22.1626,
			"o": 22.1786,
			"c": 22.1582,
			"h": 22.1789,
			"l": 22.1536,
			"t": 1344524520000,
			"n": 208
		  },
		  {
			"v": 416388,
			"vw": 22.1574,
			"o": 22.1539,
			"c": 22.1596,
			"h": 22.1639,
			"l": 22.1539,
			"t": 1344524580000,
			"n": 102
		  },
		  {
			"v": 476504,
			"vw": 22.1619,
			"o": 22.1607,
			"c": 22.1621,
			"h": 22.1696,
			"l": 22.1584,
			"t": 1344524640000,
			"n": 109
		  },
		  {
			"v": 827764,
			"vw": 22.1609,
			"o": 22.1618,
			"c": 22.1539,
			"h": 22.1786,
			"l": 22.1483,
			"t": 1344524700000,
			"n": 177
		  },
		  {
			"v": 283024,
			"vw": 22.1562,
			"o": 22.1539,
			"c": 22.1518,
			"h": 22.1596,
			"l": 22.1518,
			"t": 1344524760000,
			"n": 70
		  },
		  {
			"v": 498036,
			"vw": 22.1466,
			"o": 22.1514,
			"c": 22.1446,
			"h": 22.1529,
			"l": 22.1429,
			"t": 1344524820000,
			"n": 105
		  },
		  {
			"v": 500948,
			"vw": 22.1523,
			"o": 22.1446,
			"c": 22.1611,
			"h": 22.1614,
			"l": 22.1429,
			"t": 1344524880000,
			"n": 96
		  },
		  {
			"v": 1055404,
			"vw": 22.169,
			"o": 22.1582,
			"c": 22.1514,
			"h": 22.1813,
			"l": 22.1482,
			"t": 1344524940000,
			"n": 107
		  },
		  {
			"v": 235368,
			"vw": 22.1528,
			"o": 22.15,
			"c": 22.1578,
			"h": 22.1589,
			"l": 22.1468,
			"t": 1344525000000,
			"n": 67
		  },
		  {
			"v": 221816,
			"vw": 22.1553,
			"o": 22.1543,
			"c": 22.1532,
			"h": 22.1586,
			"l": 22.1514,
			"t": 1344525060000,
			"n": 56
		  },
		  {
			"v": 366632,
			"vw": 22.1535,
			"o": 22.1561,
			"c": 22.1468,
			"h": 22.1586,
			"l": 22.1468,
			"t": 1344525120000,
			"n": 101
		  },
		  {
			"v": 370132,
			"vw": 22.1483,
			"o": 22.1468,
			"c": 22.1521,
			"h": 22.1543,
			"l": 22.1443,
			"t": 1344525180000,
			"n": 96
		  },
		  {
			"v": 381444,
			"vw": 22.1574,
			"o": 22.1515,
			"c": 22.1589,
			"h": 22.1629,
			"l": 22.1502,
			"t": 1344525240000,
			"n": 61
		  },
		  {
			"v": 1324204,
			"vw": 22.1736,
			"o": 22.1595,
			"c": 22.1604,
			"h": 22.1786,
			"l": 22.1504,
			"t": 1344525300000,
			"n": 65
		  },
		  {
			"v": 221816,
			"vw": 22.1577,
			"o": 22.1607,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1536,
			"t": 1344525360000,
			"n": 47
		  },
		  {
			"v": 356692,
			"vw": 22.1625,
			"o": 22.1618,
			"c": 22.1625,
			"h": 22.1654,
			"l": 22.1607,
			"t": 1344525420000,
			"n": 73
		  },
		  {
			"v": 539196,
			"vw": 22.1698,
			"o": 22.1626,
			"c": 22.1768,
			"h": 22.1771,
			"l": 22.1626,
			"t": 1344525480000,
			"n": 117
		  },
		  {
			"v": 365848,
			"vw": 22.1739,
			"o": 22.1754,
			"c": 22.175,
			"h": 22.1786,
			"l": 22.1679,
			"t": 1344525540000,
			"n": 107
		  },
		  {
			"v": 451220,
			"vw": 22.1793,
			"o": 22.1754,
			"c": 22.185,
			"h": 22.185,
			"l": 22.175,
			"t": 1344525600000,
			"n": 112
		  },
		  {
			"v": 440860,
			"vw": 22.1848,
			"o": 22.1857,
			"c": 22.1821,
			"h": 22.1871,
			"l": 22.1786,
			"t": 1344525660000,
			"n": 80
		  },
		  {
			"v": 463288,
			"vw": 22.1854,
			"o": 22.1811,
			"c": 22.1854,
			"h": 22.1871,
			"l": 22.1811,
			"t": 1344525720000,
			"n": 90
		  },
		  {
			"v": 169456,
			"vw": 22.1825,
			"o": 22.1857,
			"c": 22.1804,
			"h": 22.1857,
			"l": 22.1786,
			"t": 1344525780000,
			"n": 37
		  },
		  {
			"v": 295988,
			"vw": 22.1826,
			"o": 22.1821,
			"c": 22.1786,
			"h": 22.1857,
			"l": 22.1786,
			"t": 1344525840000,
			"n": 71
		  },
		  {
			"v": 178556,
			"vw": 22.1777,
			"o": 22.1786,
			"c": 22.1782,
			"h": 22.1814,
			"l": 22.1736,
			"t": 1344525900000,
			"n": 45
		  },
		  {
			"v": 357672,
			"vw": 22.174,
			"o": 22.1812,
			"c": 22.17,
			"h": 22.1812,
			"l": 22.1689,
			"t": 1344525960000,
			"n": 56
		  },
		  {
			"v": 542388,
			"vw": 22.1688,
			"o": 22.1732,
			"c": 22.1643,
			"h": 22.1786,
			"l": 22.1643,
			"t": 1344526020000,
			"n": 135
		  },
		  {
			"v": 454300,
			"vw": 22.1605,
			"o": 22.1643,
			"c": 22.1678,
			"h": 22.1678,
			"l": 22.1557,
			"t": 1344526080000,
			"n": 114
		  },
		  {
			"v": 448140,
			"vw": 22.1684,
			"o": 22.1644,
			"c": 22.1693,
			"h": 22.1732,
			"l": 22.1643,
			"t": 1344526140000,
			"n": 82
		  },
		  {
			"v": 367640,
			"vw": 22.1587,
			"o": 22.1671,
			"c": 22.1561,
			"h": 22.1671,
			"l": 22.1539,
			"t": 1344526200000,
			"n": 74
		  },
		  {
			"v": 423976,
			"vw": 22.1548,
			"o": 22.1561,
			"c": 22.1554,
			"h": 22.1586,
			"l": 22.1543,
			"t": 1344526260000,
			"n": 73
		  },
		  {
			"v": 96208,
			"vw": 22.1567,
			"o": 22.1579,
			"c": 22.1593,
			"h": 22.1593,
			"l": 22.1543,
			"t": 1344526320000,
			"n": 22
		  },
		  {
			"v": 170660,
			"vw": 22.1625,
			"o": 22.1593,
			"c": 22.1604,
			"h": 22.165,
			"l": 22.1582,
			"t": 1344526380000,
			"n": 43
		  },
		  {
			"v": 221844,
			"vw": 22.1632,
			"o": 22.1636,
			"c": 22.1675,
			"h": 22.1693,
			"l": 22.1589,
			"t": 1344526440000,
			"n": 57
		  },
		  {
			"v": 228312,
			"vw": 22.1612,
			"o": 22.1688,
			"c": 22.1646,
			"h": 22.1688,
			"l": 22.1561,
			"t": 1344526500000,
			"n": 63
		  },
		  {
			"v": 84644,
			"vw": 22.1647,
			"o": 22.1675,
			"c": 22.1675,
			"h": 22.1675,
			"l": 22.1611,
			"t": 1344526560000,
			"n": 30
		  },
		  {
			"v": 119504,
			"vw": 22.1626,
			"o": 22.1671,
			"c": 22.16,
			"h": 22.1671,
			"l": 22.1582,
			"t": 1344526620000,
			"n": 26
		  },
		  {
			"v": 193900,
			"vw": 22.1626,
			"o": 22.1632,
			"c": 22.1646,
			"h": 22.1646,
			"l": 22.1582,
			"t": 1344526680000,
			"n": 47
		  },
		  {
			"v": 108416,
			"vw": 22.167,
			"o": 22.1671,
			"c": 22.1654,
			"h": 22.1689,
			"l": 22.165,
			"t": 1344526740000,
			"n": 32
		  },
		  {
			"v": 765576,
			"vw": 22.162,
			"o": 22.1654,
			"c": 22.1646,
			"h": 22.1693,
			"l": 22.1624,
			"t": 1344526800000,
			"n": 56
		  },
		  {
			"v": 642544,
			"vw": 22.1566,
			"o": 22.1625,
			"c": 22.1589,
			"h": 22.1625,
			"l": 22.1518,
			"t": 1344526860000,
			"n": 108
		  },
		  {
			"v": 1137360,
			"vw": 22.1562,
			"o": 22.1575,
			"c": 22.1483,
			"h": 22.1607,
			"l": 22.1464,
			"t": 1344526920000,
			"n": 114
		  },
		  {
			"v": 643888,
			"vw": 22.1493,
			"o": 22.1468,
			"c": 22.1441,
			"h": 22.1607,
			"l": 22.1429,
			"t": 1344526980000,
			"n": 114
		  },
		  {
			"v": 459032,
			"vw": 22.1468,
			"o": 22.1435,
			"c": 22.1489,
			"h": 22.1539,
			"l": 22.1429,
			"t": 1344527040000,
			"n": 113
		  },
		  {
			"v": 344484,
			"vw": 22.1474,
			"o": 22.1457,
			"c": 22.1471,
			"h": 22.1493,
			"l": 22.1454,
			"t": 1344527100000,
			"n": 75
		  },
		  {
			"v": 659484,
			"vw": 22.1506,
			"o": 22.1479,
			"c": 22.1504,
			"h": 22.1536,
			"l": 22.1468,
			"t": 1344527160000,
			"n": 116
		  },
		  {
			"v": 176064,
			"vw": 22.1504,
			"o": 22.1517,
			"c": 22.1507,
			"h": 22.1529,
			"l": 22.1482,
			"t": 1344527220000,
			"n": 49
		  },
		  {
			"v": 2331028,
			"vw": 22.1563,
			"o": 22.1524,
			"c": 22.1439,
			"h": 22.1643,
			"l": 22.1407,
			"t": 1344527280000,
			"n": 173
		  },
		  {
			"v": 901600,
			"vw": 22.1384,
			"o": 22.1432,
			"c": 22.1368,
			"h": 22.1461,
			"l": 22.1339,
			"t": 1344527340000,
			"n": 218
		  },
		  {
			"v": 614292,
			"vw": 22.1393,
			"o": 22.1368,
			"c": 22.1396,
			"h": 22.1429,
			"l": 22.1354,
			"t": 1344527400000,
			"n": 126
		  },
		  {
			"v": 253904,
			"vw": 22.1433,
			"o": 22.1418,
			"c": 22.1456,
			"h": 22.1464,
			"l": 22.1404,
			"t": 1344527460000,
			"n": 49
		  },
		  {
			"v": 658644,
			"vw": 22.1403,
			"o": 22.1439,
			"c": 22.1421,
			"h": 22.1471,
			"l": 22.1355,
			"t": 1344527520000,
			"n": 147
		  },
		  {
			"v": 525140,
			"vw": 22.1335,
			"o": 22.1428,
			"c": 22.1286,
			"h": 22.1428,
			"l": 22.1286,
			"t": 1344527580000,
			"n": 124
		  },
		  {
			"v": 1053248,
			"vw": 22.1233,
			"o": 22.1286,
			"c": 22.1146,
			"h": 22.1286,
			"l": 22.1146,
			"t": 1344527640000,
			"n": 222
		  },
		  {
			"v": 540876,
			"vw": 22.1214,
			"o": 22.1168,
			"c": 22.1249,
			"h": 22.125,
			"l": 22.115,
			"t": 1344527700000,
			"n": 104
		  },
		  {
			"v": 497840,
			"vw": 22.1209,
			"o": 22.125,
			"c": 22.1155,
			"h": 22.125,
			"l": 22.1146,
			"t": 1344527760000,
			"n": 83
		  },
		  {
			"v": 643860,
			"vw": 22.1129,
			"o": 22.1161,
			"c": 22.1125,
			"h": 22.1189,
			"l": 22.1089,
			"t": 1344527820000,
			"n": 169
		  },
		  {
			"v": 403760,
			"vw": 22.1157,
			"o": 22.1142,
			"c": 22.1168,
			"h": 22.1211,
			"l": 22.1107,
			"t": 1344527880000,
			"n": 101
		  },
		  {
			"v": 690452,
			"vw": 22.124,
			"o": 22.1171,
			"c": 22.1254,
			"h": 22.13,
			"l": 22.1161,
			"t": 1344527940000,
			"n": 141
		  },
		  {
			"v": 568624,
			"vw": 22.1234,
			"o": 22.1254,
			"c": 22.1236,
			"h": 22.1339,
			"l": 22.1175,
			"t": 1344528000000,
			"n": 126
		  },
		  {
			"v": 545636,
			"vw": 22.1247,
			"o": 22.1232,
			"c": 22.125,
			"h": 22.1282,
			"l": 22.1221,
			"t": 1344528060000,
			"n": 127
		  },
		  {
			"v": 894740,
			"vw": 22.131,
			"o": 22.1232,
			"c": 22.1444,
			"h": 22.1482,
			"l": 22.1221,
			"t": 1344528120000,
			"n": 146
		  },
		  {
			"v": 315924,
			"vw": 22.1448,
			"o": 22.1458,
			"c": 22.1429,
			"h": 22.1503,
			"l": 22.1393,
			"t": 1344528180000,
			"n": 86
		  },
		  {
			"v": 557088,
			"vw": 22.147,
			"o": 22.1461,
			"c": 22.1471,
			"h": 22.1504,
			"l": 22.1411,
			"t": 1344528240000,
			"n": 97
		  },
		  {
			"v": 335804,
			"vw": 22.1377,
			"o": 22.1456,
			"c": 22.1322,
			"h": 22.1456,
			"l": 22.1286,
			"t": 1344528300000,
			"n": 79
		  },
		  {
			"v": 168000,
			"vw": 22.1373,
			"o": 22.1343,
			"c": 22.1404,
			"h": 22.1414,
			"l": 22.1329,
			"t": 1344528360000,
			"n": 49
		  },
		  {
			"v": 42980,
			"vw": 22.1397,
			"o": 22.1393,
			"c": 22.1418,
			"h": 22.1418,
			"l": 22.1376,
			"t": 1344528420000,
			"n": 12
		  },
		  {
			"v": 168560,
			"vw": 22.1358,
			"o": 22.1417,
			"c": 22.1346,
			"h": 22.1417,
			"l": 22.1329,
			"t": 1344528480000,
			"n": 29
		  },
		  {
			"v": 425180,
			"vw": 22.1401,
			"o": 22.1414,
			"c": 22.1386,
			"h": 22.1429,
			"l": 22.1386,
			"t": 1344528540000,
			"n": 59
		  },
		  {
			"v": 300160,
			"vw": 22.1451,
			"o": 22.1404,
			"c": 22.1479,
			"h": 22.15,
			"l": 22.1396,
			"t": 1344528600000,
			"n": 59
		  },
		  {
			"v": 339416,
			"vw": 22.1496,
			"o": 22.1489,
			"c": 22.1532,
			"h": 22.1536,
			"l": 22.1432,
			"t": 1344528660000,
			"n": 74
		  },
		  {
			"v": 162484,
			"vw": 22.1502,
			"o": 22.1521,
			"c": 22.1471,
			"h": 22.1536,
			"l": 22.1457,
			"t": 1344528720000,
			"n": 34
		  },
		  {
			"v": 672224,
			"vw": 22.1444,
			"o": 22.1496,
			"c": 22.1482,
			"h": 22.1504,
			"l": 22.1464,
			"t": 1344528780000,
			"n": 45
		  },
		  {
			"v": 400008,
			"vw": 22.147,
			"o": 22.1484,
			"c": 22.1511,
			"h": 22.1511,
			"l": 22.1429,
			"t": 1344528840000,
			"n": 58
		  },
		  {
			"v": 221172,
			"vw": 22.1512,
			"o": 22.1496,
			"c": 22.1507,
			"h": 22.1532,
			"l": 22.1491,
			"t": 1344528900000,
			"n": 43
		  },
		  {
			"v": 455840,
			"vw": 22.1486,
			"o": 22.1483,
			"c": 22.1492,
			"h": 22.1521,
			"l": 22.1432,
			"t": 1344528960000,
			"n": 74
		  },
		  {
			"v": 551852,
			"vw": 22.1538,
			"o": 22.1479,
			"c": 22.1586,
			"h": 22.1586,
			"l": 22.1479,
			"t": 1344529020000,
			"n": 87
		  },
		  {
			"v": 585480,
			"vw": 22.1581,
			"o": 22.1596,
			"c": 22.1536,
			"h": 22.1621,
			"l": 22.1507,
			"t": 1344529080000,
			"n": 108
		  },
		  {
			"v": 158116,
			"vw": 22.154,
			"o": 22.1536,
			"c": 22.1525,
			"h": 22.1589,
			"l": 22.15,
			"t": 1344529140000,
			"n": 34
		  },
		  {
			"v": 384580,
			"vw": 22.1522,
			"o": 22.1536,
			"c": 22.1475,
			"h": 22.1613,
			"l": 22.1446,
			"t": 1344529200000,
			"n": 83
		  },
		  {
			"v": 191520,
			"vw": 22.1492,
			"o": 22.1432,
			"c": 22.1464,
			"h": 22.1529,
			"l": 22.1432,
			"t": 1344529260000,
			"n": 40
		  },
		  {
			"v": 350252,
			"vw": 22.1463,
			"o": 22.1496,
			"c": 22.1521,
			"h": 22.1521,
			"l": 22.1404,
			"t": 1344529320000,
			"n": 69
		  },
		  {
			"v": 189644,
			"vw": 22.1543,
			"o": 22.155,
			"c": 22.1589,
			"h": 22.1589,
			"l": 22.1514,
			"t": 1344529380000,
			"n": 44
		  },
		  {
			"v": 206304,
			"vw": 22.1565,
			"o": 22.1547,
			"c": 22.1532,
			"h": 22.1604,
			"l": 22.1525,
			"t": 1344529440000,
			"n": 39
		  },
		  {
			"v": 110964,
			"vw": 22.1573,
			"o": 22.1537,
			"c": 22.16,
			"h": 22.1604,
			"l": 22.1529,
			"t": 1344529500000,
			"n": 28
		  },
		  {
			"v": 195776,
			"vw": 22.1604,
			"o": 22.1604,
			"c": 22.1586,
			"h": 22.1639,
			"l": 22.1571,
			"t": 1344529560000,
			"n": 50
		  },
		  {
			"v": 649572,
			"vw": 22.1571,
			"o": 22.16,
			"c": 22.1546,
			"h": 22.16,
			"l": 22.1546,
			"t": 1344529620000,
			"n": 85
		  },
		  {
			"v": 243852,
			"vw": 22.1575,
			"o": 22.1543,
			"c": 22.1568,
			"h": 22.1625,
			"l": 22.1529,
			"t": 1344529680000,
			"n": 61
		  },
		  {
			"v": 170772,
			"vw": 22.1535,
			"o": 22.1561,
			"c": 22.1536,
			"h": 22.1561,
			"l": 22.15,
			"t": 1344529740000,
			"n": 34
		  },
		  {
			"v": 41944,
			"vw": 22.1535,
			"o": 22.1532,
			"c": 22.1532,
			"h": 22.1567,
			"l": 22.1521,
			"t": 1344529800000,
			"n": 11
		  },
		  {
			"v": 169680,
			"vw": 22.149,
			"o": 22.1504,
			"c": 22.1446,
			"h": 22.155,
			"l": 22.1414,
			"t": 1344529860000,
			"n": 45
		  },
		  {
			"v": 168756,
			"vw": 22.1451,
			"o": 22.1446,
			"c": 22.1429,
			"h": 22.1493,
			"l": 22.1429,
			"t": 1344529920000,
			"n": 48
		  },
		  {
			"v": 933688,
			"vw": 22.1455,
			"o": 22.1429,
			"c": 22.1486,
			"h": 22.1568,
			"l": 22.1357,
			"t": 1344529980000,
			"n": 185
		  },
		  {
			"v": 370188,
			"vw": 22.1461,
			"o": 22.1482,
			"c": 22.1425,
			"h": 22.1493,
			"l": 22.1411,
			"t": 1344530040000,
			"n": 74
		  },
		  {
			"v": 75796,
			"vw": 22.1415,
			"o": 22.1425,
			"c": 22.1407,
			"h": 22.145,
			"l": 22.1397,
			"t": 1344530100000,
			"n": 20
		  },
		  {
			"v": 395444,
			"vw": 22.1361,
			"o": 22.1407,
			"c": 22.1364,
			"h": 22.1407,
			"l": 22.1339,
			"t": 1344530160000,
			"n": 80
		  },
		  {
			"v": 345492,
			"vw": 22.129,
			"o": 22.1375,
			"c": 22.1302,
			"h": 22.1375,
			"l": 22.1236,
			"t": 1344530220000,
			"n": 77
		  },
		  {
			"v": 297752,
			"vw": 22.1282,
			"o": 22.1305,
			"c": 22.1281,
			"h": 22.1305,
			"l": 22.1264,
			"t": 1344530280000,
			"n": 69
		  },
		  {
			"v": 454608,
			"vw": 22.1294,
			"o": 22.1268,
			"c": 22.1375,
			"h": 22.1379,
			"l": 22.125,
			"t": 1344530340000,
			"n": 101
		  },
		  {
			"v": 320684,
			"vw": 22.1404,
			"o": 22.1368,
			"c": 22.1466,
			"h": 22.15,
			"l": 22.1325,
			"t": 1344530400000,
			"n": 90
		  },
		  {
			"v": 240800,
			"vw": 22.1467,
			"o": 22.1464,
			"c": 22.1471,
			"h": 22.15,
			"l": 22.1436,
			"t": 1344530460000,
			"n": 68
		  },
		  {
			"v": 145852,
			"vw": 22.1476,
			"o": 22.1493,
			"c": 22.1457,
			"h": 22.1493,
			"l": 22.1457,
			"t": 1344530520000,
			"n": 40
		  },
		  {
			"v": 152824,
			"vw": 22.1407,
			"o": 22.1468,
			"c": 22.1382,
			"h": 22.1468,
			"l": 22.1358,
			"t": 1344530580000,
			"n": 47
		  },
		  {
			"v": 179116,
			"vw": 22.1413,
			"o": 22.1393,
			"c": 22.1429,
			"h": 22.1479,
			"l": 22.1364,
			"t": 1344530640000,
			"n": 42
		  },
		  {
			"v": 67200,
			"vw": 22.1442,
			"o": 22.1429,
			"c": 22.1482,
			"h": 22.1482,
			"l": 22.1429,
			"t": 1344530700000,
			"n": 16
		  },
		  {
			"v": 304892,
			"vw": 22.1504,
			"o": 22.1468,
			"c": 22.1464,
			"h": 22.1596,
			"l": 22.1454,
			"t": 1344530760000,
			"n": 74
		  },
		  {
			"v": 267484,
			"vw": 22.1506,
			"o": 22.1466,
			"c": 22.15,
			"h": 22.1532,
			"l": 22.1466,
			"t": 1344530820000,
			"n": 64
		  },
		  {
			"v": 347872,
			"vw": 22.1496,
			"o": 22.1507,
			"c": 22.1507,
			"h": 22.1524,
			"l": 22.1461,
			"t": 1344530880000,
			"n": 76
		  },
		  {
			"v": 89684,
			"vw": 22.1537,
			"o": 22.1514,
			"c": 22.1543,
			"h": 22.1561,
			"l": 22.15,
			"t": 1344530940000,
			"n": 22
		  },
		  {
			"v": 328356,
			"vw": 22.1557,
			"o": 22.155,
			"c": 22.1564,
			"h": 22.1582,
			"l": 22.1529,
			"t": 1344531000000,
			"n": 70
		  },
		  {
			"v": 571340,
			"vw": 22.1628,
			"o": 22.1579,
			"c": 22.1686,
			"h": 22.1686,
			"l": 22.1579,
			"t": 1344531060000,
			"n": 117
		  },
		  {
			"v": 407400,
			"vw": 22.1676,
			"o": 22.1679,
			"c": 22.165,
			"h": 22.1732,
			"l": 22.1634,
			"t": 1344531120000,
			"n": 113
		  },
		  {
			"v": 326284,
			"vw": 22.1677,
			"o": 22.1671,
			"c": 22.1686,
			"h": 22.1714,
			"l": 22.165,
			"t": 1344531180000,
			"n": 66
		  },
		  {
			"v": 288988,
			"vw": 22.1701,
			"o": 22.1714,
			"c": 22.171,
			"h": 22.1729,
			"l": 22.1661,
			"t": 1344531240000,
			"n": 57
		  },
		  {
			"v": 309400,
			"vw": 22.1661,
			"o": 22.1707,
			"c": 22.1661,
			"h": 22.1711,
			"l": 22.1646,
			"t": 1344531300000,
			"n": 81
		  },
		  {
			"v": 580272,
			"vw": 22.1678,
			"o": 22.1675,
			"c": 22.1664,
			"h": 22.1711,
			"l": 22.165,
			"t": 1344531360000,
			"n": 69
		  },
		  {
			"v": 198800,
			"vw": 22.1645,
			"o": 22.1654,
			"c": 22.1615,
			"h": 22.1689,
			"l": 22.1607,
			"t": 1344531420000,
			"n": 56
		  },
		  {
			"v": 545272,
			"vw": 22.1625,
			"o": 22.1654,
			"c": 22.1608,
			"h": 22.1668,
			"l": 22.1575,
			"t": 1344531480000,
			"n": 77
		  },
		  {
			"v": 399532,
			"vw": 22.1621,
			"o": 22.1621,
			"c": 22.1671,
			"h": 22.1696,
			"l": 22.1561,
			"t": 1344531540000,
			"n": 66
		  },
		  {
			"v": 463960,
			"vw": 22.1664,
			"o": 22.165,
			"c": 22.1625,
			"h": 22.1714,
			"l": 22.1625,
			"t": 1344531600000,
			"n": 91
		  },
		  {
			"v": 604436,
			"vw": 22.165,
			"o": 22.165,
			"c": 22.1696,
			"h": 22.1732,
			"l": 22.1554,
			"t": 1344531660000,
			"n": 146
		  },
		  {
			"v": 120176,
			"vw": 22.1619,
			"o": 22.1664,
			"c": 22.1596,
			"h": 22.1682,
			"l": 22.1579,
			"t": 1344531720000,
			"n": 35
		  },
		  {
			"v": 168560,
			"vw": 22.1632,
			"o": 22.1589,
			"c": 22.1644,
			"h": 22.1657,
			"l": 22.1589,
			"t": 1344531780000,
			"n": 36
		  },
		  {
			"v": 372456,
			"vw": 22.1666,
			"o": 22.1647,
			"c": 22.1678,
			"h": 22.1696,
			"l": 22.1636,
			"t": 1344531840000,
			"n": 35
		  },
		  {
			"v": 76244,
			"vw": 22.1662,
			"o": 22.1636,
			"c": 22.1671,
			"h": 22.1675,
			"l": 22.1622,
			"t": 1344531900000,
			"n": 21
		  },
		  {
			"v": 36400,
			"vw": 22.1632,
			"o": 22.1643,
			"c": 22.1614,
			"h": 22.1661,
			"l": 22.1607,
			"t": 1344531960000,
			"n": 13
		  },
		  {
			"v": 133000,
			"vw": 22.1637,
			"o": 22.165,
			"c": 22.1618,
			"h": 22.1654,
			"l": 22.1614,
			"t": 1344532020000,
			"n": 30
		  },
		  {
			"v": 129528,
			"vw": 22.1611,
			"o": 22.1618,
			"c": 22.1593,
			"h": 22.1651,
			"l": 22.1589,
			"t": 1344532080000,
			"n": 27
		  },
		  {
			"v": 197344,
			"vw": 22.1613,
			"o": 22.1611,
			"c": 22.1594,
			"h": 22.1635,
			"l": 22.1579,
			"t": 1344532140000,
			"n": 29
		  },
		  {
			"v": 2133824,
			"vw": 22.1539,
			"o": 22.1601,
			"c": 22.1554,
			"h": 22.1611,
			"l": 22.1543,
			"t": 1344532200000,
			"n": 44
		  },
		  {
			"v": 309204,
			"vw": 22.1521,
			"o": 22.1554,
			"c": 22.1525,
			"h": 22.1554,
			"l": 22.1471,
			"t": 1344532260000,
			"n": 71
		  },
		  {
			"v": 239624,
			"vw": 22.1558,
			"o": 22.1532,
			"c": 22.155,
			"h": 22.1596,
			"l": 22.15,
			"t": 1344532320000,
			"n": 60
		  },
		  {
			"v": 140140,
			"vw": 22.1535,
			"o": 22.1546,
			"c": 22.155,
			"h": 22.1554,
			"l": 22.15,
			"t": 1344532380000,
			"n": 32
		  },
		  {
			"v": 102480,
			"vw": 22.1552,
			"o": 22.1568,
			"c": 22.1564,
			"h": 22.1568,
			"l": 22.1525,
			"t": 1344532440000,
			"n": 32
		  },
		  {
			"v": 260400,
			"vw": 22.1588,
			"o": 22.1549,
			"c": 22.1607,
			"h": 22.1614,
			"l": 22.1534,
			"t": 1344532500000,
			"n": 65
		  },
		  {
			"v": 214620,
			"vw": 22.1664,
			"o": 22.1625,
			"c": 22.1696,
			"h": 22.1696,
			"l": 22.1618,
			"t": 1344532560000,
			"n": 46
		  },
		  {
			"v": 306012,
			"vw": 22.1687,
			"o": 22.1675,
			"c": 22.1664,
			"h": 22.1707,
			"l": 22.1639,
			"t": 1344532620000,
			"n": 30
		  },
		  {
			"v": 400092,
			"vw": 22.1665,
			"o": 22.165,
			"c": 22.1679,
			"h": 22.1682,
			"l": 22.1639,
			"t": 1344532680000,
			"n": 42
		  },
		  {
			"v": 338240,
			"vw": 22.1696,
			"o": 22.1682,
			"c": 22.1697,
			"h": 22.1732,
			"l": 22.1654,
			"t": 1344532740000,
			"n": 89
		  },
		  {
			"v": 360836,
			"vw": 22.1628,
			"o": 22.1679,
			"c": 22.1618,
			"h": 22.1682,
			"l": 22.1586,
			"t": 1344532800000,
			"n": 74
		  },
		  {
			"v": 96180,
			"vw": 22.1627,
			"o": 22.1668,
			"c": 22.1614,
			"h": 22.1668,
			"l": 22.1608,
			"t": 1344532860000,
			"n": 21
		  },
		  {
			"v": 158200,
			"vw": 22.1661,
			"o": 22.1614,
			"c": 22.1682,
			"h": 22.1707,
			"l": 22.1614,
			"t": 1344532920000,
			"n": 47
		  },
		  {
			"v": 89600,
			"vw": 22.1683,
			"o": 22.169,
			"c": 22.1693,
			"h": 22.1698,
			"l": 22.1664,
			"t": 1344532980000,
			"n": 23
		  },
		  {
			"v": 367724,
			"vw": 22.172,
			"o": 22.1671,
			"c": 22.1739,
			"h": 22.1764,
			"l": 22.1671,
			"t": 1344533040000,
			"n": 63
		  },
		  {
			"v": 254436,
			"vw": 22.1752,
			"o": 22.1739,
			"c": 22.1749,
			"h": 22.1782,
			"l": 22.1715,
			"t": 1344533100000,
			"n": 49
		  },
		  {
			"v": 164976,
			"vw": 22.1721,
			"o": 22.175,
			"c": 22.1693,
			"h": 22.1757,
			"l": 22.1693,
			"t": 1344533160000,
			"n": 38
		  },
		  {
			"v": 181048,
			"vw": 22.1691,
			"o": 22.1702,
			"c": 22.1684,
			"h": 22.1704,
			"l": 22.1671,
			"t": 1344533220000,
			"n": 51
		  },
		  {
			"v": 306432,
			"vw": 22.1679,
			"o": 22.1671,
			"c": 22.1645,
			"h": 22.1696,
			"l": 22.1643,
			"t": 1344533280000,
			"n": 34
		  },
		  {
			"v": 195720,
			"vw": 22.1644,
			"o": 22.1654,
			"c": 22.1679,
			"h": 22.1689,
			"l": 22.1607,
			"t": 1344533340000,
			"n": 49
		  },
		  {
			"v": 2115008,
			"vw": 22.1777,
			"o": 22.167,
			"c": 22.1632,
			"h": 22.1786,
			"l": 22.1632,
			"t": 1344533400000,
			"n": 31
		  },
		  {
			"v": 286804,
			"vw": 22.1668,
			"o": 22.1664,
			"c": 22.1664,
			"h": 22.1718,
			"l": 22.1636,
			"t": 1344533460000,
			"n": 59
		  },
		  {
			"v": 418684,
			"vw": 22.1688,
			"o": 22.1664,
			"c": 22.1689,
			"h": 22.1732,
			"l": 22.1643,
			"t": 1344533520000,
			"n": 96
		  },
		  {
			"v": 145292,
			"vw": 22.1669,
			"o": 22.1675,
			"c": 22.1668,
			"h": 22.17,
			"l": 22.1643,
			"t": 1344533580000,
			"n": 42
		  },
		  {
			"v": 104020,
			"vw": 22.1636,
			"o": 22.1643,
			"c": 22.1628,
			"h": 22.1661,
			"l": 22.1621,
			"t": 1344533640000,
			"n": 30
		  },
		  {
			"v": 88200,
			"vw": 22.163,
			"o": 22.1625,
			"c": 22.1625,
			"h": 22.1643,
			"l": 22.1618,
			"t": 1344533700000,
			"n": 25
		  },
		  {
			"v": 351540,
			"vw": 22.1676,
			"o": 22.1632,
			"c": 22.165,
			"h": 22.1696,
			"l": 22.1632,
			"t": 1344533760000,
			"n": 46
		  },
		  {
			"v": 113316,
			"vw": 22.1642,
			"o": 22.165,
			"c": 22.1625,
			"h": 22.1689,
			"l": 22.1625,
			"t": 1344533820000,
			"n": 26
		  },
		  {
			"v": 383292,
			"vw": 22.1653,
			"o": 22.1625,
			"c": 22.1614,
			"h": 22.1661,
			"l": 22.1614,
			"t": 1344533880000,
			"n": 58
		  },
		  {
			"v": 191016,
			"vw": 22.1591,
			"o": 22.1625,
			"c": 22.1568,
			"h": 22.1625,
			"l": 22.155,
			"t": 1344533940000,
			"n": 35
		  },
		  {
			"v": 404040,
			"vw": 22.1623,
			"o": 22.1589,
			"c": 22.1611,
			"h": 22.1679,
			"l": 22.1568,
			"t": 1344534000000,
			"n": 101
		  },
		  {
			"v": 159880,
			"vw": 22.1605,
			"o": 22.1596,
			"c": 22.1618,
			"h": 22.1632,
			"l": 22.1576,
			"t": 1344534060000,
			"n": 32
		  },
		  {
			"v": 77896,
			"vw": 22.1645,
			"o": 22.1625,
			"c": 22.1643,
			"h": 22.1654,
			"l": 22.1625,
			"t": 1344534120000,
			"n": 17
		  },
		  {
			"v": 118776,
			"vw": 22.1661,
			"o": 22.1643,
			"c": 22.1657,
			"h": 22.1675,
			"l": 22.1639,
			"t": 1344534180000,
			"n": 31
		  },
		  {
			"v": 168868,
			"vw": 22.1646,
			"o": 22.1646,
			"c": 22.1646,
			"h": 22.1675,
			"l": 22.1636,
			"t": 1344534240000,
			"n": 37
		  },
		  {
			"v": 147308,
			"vw": 22.1668,
			"o": 22.1639,
			"c": 22.1675,
			"h": 22.1679,
			"l": 22.1639,
			"t": 1344534300000,
			"n": 38
		  },
		  {
			"v": 351176,
			"vw": 22.1645,
			"o": 22.1664,
			"c": 22.165,
			"h": 22.1671,
			"l": 22.1643,
			"t": 1344534360000,
			"n": 24
		  },
		  {
			"v": 120400,
			"vw": 22.1666,
			"o": 22.1646,
			"c": 22.1675,
			"h": 22.1682,
			"l": 22.1646,
			"t": 1344534420000,
			"n": 21
		  },
		  {
			"v": 214144,
			"vw": 22.1665,
			"o": 22.1682,
			"c": 22.1644,
			"h": 22.1703,
			"l": 22.1644,
			"t": 1344534480000,
			"n": 49
		  },
		  {
			"v": 82964,
			"vw": 22.1633,
			"o": 22.1661,
			"c": 22.1625,
			"h": 22.1671,
			"l": 22.1607,
			"t": 1344534540000,
			"n": 18
		  },
		  {
			"v": 131656,
			"vw": 22.1667,
			"o": 22.165,
			"c": 22.1668,
			"h": 22.1696,
			"l": 22.1632,
			"t": 1344534600000,
			"n": 32
		  },
		  {
			"v": 300328,
			"vw": 22.1647,
			"o": 22.1682,
			"c": 22.1643,
			"h": 22.1689,
			"l": 22.1621,
			"t": 1344534660000,
			"n": 43
		  },
		  {
			"v": 248276,
			"vw": 22.1642,
			"o": 22.1643,
			"c": 22.1621,
			"h": 22.1675,
			"l": 22.1621,
			"t": 1344534720000,
			"n": 53
		  },
		  {
			"v": 93660,
			"vw": 22.164,
			"o": 22.1646,
			"c": 22.1643,
			"h": 22.165,
			"l": 22.1622,
			"t": 1344534780000,
			"n": 20
		  },
		  {
			"v": 68460,
			"vw": 22.1655,
			"o": 22.1646,
			"c": 22.1661,
			"h": 22.1671,
			"l": 22.1639,
			"t": 1344534840000,
			"n": 20
		  },
		  {
			"v": 201152,
			"vw": 22.1669,
			"o": 22.1661,
			"c": 22.171,
			"h": 22.171,
			"l": 22.1643,
			"t": 1344534900000,
			"n": 47
		  },
		  {
			"v": 154420,
			"vw": 22.1666,
			"o": 22.1706,
			"c": 22.1668,
			"h": 22.1706,
			"l": 22.1643,
			"t": 1344534960000,
			"n": 38
		  },
		  {
			"v": 126672,
			"vw": 22.1646,
			"o": 22.1646,
			"c": 22.165,
			"h": 22.1677,
			"l": 22.1639,
			"t": 1344535020000,
			"n": 22
		  },
		  {
			"v": 813988,
			"vw": 22.16,
			"o": 22.165,
			"c": 22.1632,
			"h": 22.1651,
			"l": 22.1546,
			"t": 1344535080000,
			"n": 123
		  },
		  {
			"v": 224000,
			"vw": 22.1646,
			"o": 22.1608,
			"c": 22.1643,
			"h": 22.1689,
			"l": 22.1608,
			"t": 1344535140000,
			"n": 63
		  },
		  {
			"v": 340844,
			"vw": 22.1642,
			"o": 22.1657,
			"c": 22.165,
			"h": 22.1661,
			"l": 22.1618,
			"t": 1344535200000,
			"n": 79
		  },
		  {
			"v": 655564,
			"vw": 22.1686,
			"o": 22.165,
			"c": 22.1658,
			"h": 22.1727,
			"l": 22.1608,
			"t": 1344535260000,
			"n": 120
		  },
		  {
			"v": 279692,
			"vw": 22.1637,
			"o": 22.1686,
			"c": 22.16,
			"h": 22.1692,
			"l": 22.16,
			"t": 1344535320000,
			"n": 48
		  },
		  {
			"v": 330400,
			"vw": 22.1606,
			"o": 22.1604,
			"c": 22.1589,
			"h": 22.1642,
			"l": 22.1571,
			"t": 1344535380000,
			"n": 63
		  },
		  {
			"v": 215768,
			"vw": 22.1604,
			"o": 22.1601,
			"c": 22.1607,
			"h": 22.165,
			"l": 22.1589,
			"t": 1344535440000,
			"n": 54
		  },
		  {
			"v": 438928,
			"vw": 22.1554,
			"o": 22.1618,
			"c": 22.1525,
			"h": 22.1621,
			"l": 22.1511,
			"t": 1344535500000,
			"n": 100
		  },
		  {
			"v": 256956,
			"vw": 22.1546,
			"o": 22.1521,
			"c": 22.1559,
			"h": 22.1575,
			"l": 22.1518,
			"t": 1344535560000,
			"n": 59
		  },
		  {
			"v": 106960,
			"vw": 22.1563,
			"o": 22.1563,
			"c": 22.157,
			"h": 22.16,
			"l": 22.1536,
			"t": 1344535620000,
			"n": 22
		  },
		  {
			"v": 107800,
			"vw": 22.1571,
			"o": 22.1576,
			"c": 22.1571,
			"h": 22.1599,
			"l": 22.1543,
			"t": 1344535680000,
			"n": 25
		  },
		  {
			"v": 1108912,
			"vw": 22.1691,
			"o": 22.1582,
			"c": 22.1743,
			"h": 22.1743,
			"l": 22.1568,
			"t": 1344535740000,
			"n": 203
		  },
		  {
			"v": 293580,
			"vw": 22.1738,
			"o": 22.1721,
			"c": 22.1711,
			"h": 22.175,
			"l": 22.1704,
			"t": 1344535800000,
			"n": 55
		  },
		  {
			"v": 161476,
			"vw": 22.1738,
			"o": 22.1757,
			"c": 22.1707,
			"h": 22.1761,
			"l": 22.1707,
			"t": 1344535860000,
			"n": 37
		  },
		  {
			"v": 794444,
			"vw": 22.1756,
			"o": 22.1732,
			"c": 22.1715,
			"h": 22.1786,
			"l": 22.1696,
			"t": 1344535920000,
			"n": 142
		  },
		  {
			"v": 131096,
			"vw": 22.1727,
			"o": 22.1714,
			"c": 22.1721,
			"h": 22.1739,
			"l": 22.1714,
			"t": 1344535980000,
			"n": 30
		  },
		  {
			"v": 133756,
			"vw": 22.1723,
			"o": 22.1743,
			"c": 22.1696,
			"h": 22.1743,
			"l": 22.1696,
			"t": 1344536040000,
			"n": 22
		  },
		  {
			"v": 159320,
			"vw": 22.1697,
			"o": 22.1718,
			"c": 22.1711,
			"h": 22.1721,
			"l": 22.1679,
			"t": 1344536100000,
			"n": 40
		  },
		  {
			"v": 123200,
			"vw": 22.1683,
			"o": 22.1679,
			"c": 22.1657,
			"h": 22.1689,
			"l": 22.1657,
			"t": 1344536160000,
			"n": 31
		  },
		  {
			"v": 74452,
			"vw": 22.1686,
			"o": 22.1675,
			"c": 22.168,
			"h": 22.1707,
			"l": 22.1675,
			"t": 1344536220000,
			"n": 25
		  },
		  {
			"v": 179368,
			"vw": 22.1707,
			"o": 22.1703,
			"c": 22.1696,
			"h": 22.1732,
			"l": 22.1686,
			"t": 1344536280000,
			"n": 41
		  },
		  {
			"v": 129052,
			"vw": 22.1704,
			"o": 22.1696,
			"c": 22.17,
			"h": 22.172,
			"l": 22.1693,
			"t": 1344536340000,
			"n": 29
		  },
		  {
			"v": 96572,
			"vw": 22.1687,
			"o": 22.17,
			"c": 22.1679,
			"h": 22.1704,
			"l": 22.1659,
			"t": 1344536400000,
			"n": 27
		  },
		  {
			"v": 699020,
			"vw": 22.1683,
			"o": 22.1675,
			"c": 22.1665,
			"h": 22.1729,
			"l": 22.1657,
			"t": 1344536460000,
			"n": 74
		  },
		  {
			"v": 458948,
			"vw": 22.1697,
			"o": 22.1664,
			"c": 22.1696,
			"h": 22.1729,
			"l": 22.1661,
			"t": 1344536520000,
			"n": 33
		  },
		  {
			"v": 183400,
			"vw": 22.1684,
			"o": 22.1721,
			"c": 22.1682,
			"h": 22.1721,
			"l": 22.1657,
			"t": 1344536580000,
			"n": 40
		  },
		  {
			"v": 107828,
			"vw": 22.1664,
			"o": 22.171,
			"c": 22.1643,
			"h": 22.171,
			"l": 22.1643,
			"t": 1344536640000,
			"n": 21
		  },
		  {
			"v": 247940,
			"vw": 22.1718,
			"o": 22.1664,
			"c": 22.1729,
			"h": 22.1757,
			"l": 22.1664,
			"t": 1344536700000,
			"n": 52
		  },
		  {
			"v": 113932,
			"vw": 22.1728,
			"o": 22.174,
			"c": 22.1689,
			"h": 22.1757,
			"l": 22.1689,
			"t": 1344536760000,
			"n": 31
		  },
		  {
			"v": 112756,
			"vw": 22.1659,
			"o": 22.1668,
			"c": 22.1644,
			"h": 22.1691,
			"l": 22.1643,
			"t": 1344536820000,
			"n": 25
		  },
		  {
			"v": 372792,
			"vw": 22.1612,
			"o": 22.1643,
			"c": 22.1589,
			"h": 22.1644,
			"l": 22.1571,
			"t": 1344536880000,
			"n": 55
		  },
		  {
			"v": 442904,
			"vw": 22.1697,
			"o": 22.1607,
			"c": 22.1714,
			"h": 22.175,
			"l": 22.1595,
			"t": 1344536940000,
			"n": 72
		  },
		  {
			"v": 109480,
			"vw": 22.1712,
			"o": 22.1711,
			"c": 22.1714,
			"h": 22.1739,
			"l": 22.1683,
			"t": 1344537000000,
			"n": 28
		  },
		  {
			"v": 295232,
			"vw": 22.1715,
			"o": 22.1732,
			"c": 22.1753,
			"h": 22.1753,
			"l": 22.1686,
			"t": 1344537060000,
			"n": 58
		  },
		  {
			"v": 275212,
			"vw": 22.1712,
			"o": 22.1753,
			"c": 22.1704,
			"h": 22.1754,
			"l": 22.1686,
			"t": 1344537120000,
			"n": 44
		  },
		  {
			"v": 456344,
			"vw": 22.1717,
			"o": 22.1704,
			"c": 22.1725,
			"h": 22.1764,
			"l": 22.1646,
			"t": 1344537180000,
			"n": 97
		  },
		  {
			"v": 208796,
			"vw": 22.1731,
			"o": 22.175,
			"c": 22.1721,
			"h": 22.1768,
			"l": 22.1718,
			"t": 1344537240000,
			"n": 62
		  },
		  {
			"v": 565152,
			"vw": 22.1736,
			"o": 22.1718,
			"c": 22.1686,
			"h": 22.1786,
			"l": 22.1682,
			"t": 1344537300000,
			"n": 138
		  },
		  {
			"v": 443520,
			"vw": 22.1632,
			"o": 22.1679,
			"c": 22.1597,
			"h": 22.1682,
			"l": 22.1582,
			"t": 1344537360000,
			"n": 82
		  },
		  {
			"v": 551992,
			"vw": 22.1579,
			"o": 22.1572,
			"c": 22.1611,
			"h": 22.1664,
			"l": 22.1546,
			"t": 1344537420000,
			"n": 119
		  },
		  {
			"v": 251580,
			"vw": 22.1598,
			"o": 22.1629,
			"c": 22.1579,
			"h": 22.1656,
			"l": 22.1554,
			"t": 1344537480000,
			"n": 61
		  },
		  {
			"v": 94584,
			"vw": 22.1645,
			"o": 22.1596,
			"c": 22.1643,
			"h": 22.1664,
			"l": 22.1596,
			"t": 1344537540000,
			"n": 23
		  },
		  {
			"v": 145432,
			"vw": 22.1624,
			"o": 22.1625,
			"c": 22.1575,
			"h": 22.1661,
			"l": 22.1575,
			"t": 1344537600000,
			"n": 39
		  },
		  {
			"v": 153132,
			"vw": 22.1584,
			"o": 22.1558,
			"c": 22.1625,
			"h": 22.1625,
			"l": 22.1557,
			"t": 1344537660000,
			"n": 33
		  },
		  {
			"v": 137200,
			"vw": 22.1585,
			"o": 22.1609,
			"c": 22.1571,
			"h": 22.1611,
			"l": 22.1561,
			"t": 1344537720000,
			"n": 33
		  },
		  {
			"v": 170044,
			"vw": 22.1564,
			"o": 22.1596,
			"c": 22.1571,
			"h": 22.1596,
			"l": 22.1539,
			"t": 1344537780000,
			"n": 36
		  },
		  {
			"v": 199528,
			"vw": 22.1545,
			"o": 22.1539,
			"c": 22.1543,
			"h": 22.1575,
			"l": 22.1525,
			"t": 1344537840000,
			"n": 40
		  },
		  {
			"v": 226772,
			"vw": 22.1516,
			"o": 22.1518,
			"c": 22.1493,
			"h": 22.1546,
			"l": 22.1486,
			"t": 1344537900000,
			"n": 54
		  },
		  {
			"v": 295904,
			"vw": 22.1525,
			"o": 22.15,
			"c": 22.1518,
			"h": 22.1596,
			"l": 22.1493,
			"t": 1344537960000,
			"n": 55
		  },
		  {
			"v": 153608,
			"vw": 22.1528,
			"o": 22.1545,
			"c": 22.155,
			"h": 22.155,
			"l": 22.15,
			"t": 1344538020000,
			"n": 30
		  },
		  {
			"v": 116200,
			"vw": 22.1571,
			"o": 22.1564,
			"c": 22.1546,
			"h": 22.159,
			"l": 22.1532,
			"t": 1344538080000,
			"n": 32
		  },
		  {
			"v": 214704,
			"vw": 22.1551,
			"o": 22.1546,
			"c": 22.1543,
			"h": 22.1604,
			"l": 22.1529,
			"t": 1344538140000,
			"n": 50
		  },
		  {
			"v": 328188,
			"vw": 22.1535,
			"o": 22.1536,
			"c": 22.1529,
			"h": 22.1578,
			"l": 22.1507,
			"t": 1344538200000,
			"n": 71
		  },
		  {
			"v": 155064,
			"vw": 22.1535,
			"o": 22.1518,
			"c": 22.1525,
			"h": 22.1543,
			"l": 22.1518,
			"t": 1344538260000,
			"n": 18
		  },
		  {
			"v": 184856,
			"vw": 22.1564,
			"o": 22.1525,
			"c": 22.1575,
			"h": 22.1582,
			"l": 22.1521,
			"t": 1344538320000,
			"n": 40
		  },
		  {
			"v": 323960,
			"vw": 22.1578,
			"o": 22.1551,
			"c": 22.1554,
			"h": 22.1629,
			"l": 22.155,
			"t": 1344538380000,
			"n": 83
		  },
		  {
			"v": 280560,
			"vw": 22.16,
			"o": 22.1579,
			"c": 22.1575,
			"h": 22.1629,
			"l": 22.1575,
			"t": 1344538440000,
			"n": 67
		  },
		  {
			"v": 122052,
			"vw": 22.1599,
			"o": 22.1586,
			"c": 22.1617,
			"h": 22.1618,
			"l": 22.1575,
			"t": 1344538500000,
			"n": 37
		  },
		  {
			"v": 168588,
			"vw": 22.1602,
			"o": 22.1609,
			"c": 22.1575,
			"h": 22.1629,
			"l": 22.1575,
			"t": 1344538560000,
			"n": 37
		  },
		  {
			"v": 254240,
			"vw": 22.1555,
			"o": 22.1592,
			"c": 22.1568,
			"h": 22.1593,
			"l": 22.1518,
			"t": 1344538620000,
			"n": 43
		  },
		  {
			"v": 259196,
			"vw": 22.1521,
			"o": 22.1589,
			"c": 22.1532,
			"h": 22.1589,
			"l": 22.1482,
			"t": 1344538680000,
			"n": 65
		  },
		  {
			"v": 172424,
			"vw": 22.1533,
			"o": 22.1539,
			"c": 22.1504,
			"h": 22.1582,
			"l": 22.1504,
			"t": 1344538740000,
			"n": 34
		  },
		  {
			"v": 145600,
			"vw": 22.1518,
			"o": 22.1504,
			"c": 22.1504,
			"h": 22.1552,
			"l": 22.15,
			"t": 1344538800000,
			"n": 38
		  },
		  {
			"v": 86940,
			"vw": 22.1526,
			"o": 22.149,
			"c": 22.1554,
			"h": 22.1568,
			"l": 22.149,
			"t": 1344538860000,
			"n": 15
		  },
		  {
			"v": 47796,
			"vw": 22.1547,
			"o": 22.1557,
			"c": 22.1554,
			"h": 22.1557,
			"l": 22.1526,
			"t": 1344538920000,
			"n": 15
		  },
		  {
			"v": 168504,
			"vw": 22.1529,
			"o": 22.1546,
			"c": 22.1557,
			"h": 22.1561,
			"l": 22.1504,
			"t": 1344538980000,
			"n": 44
		  },
		  {
			"v": 452704,
			"vw": 22.1478,
			"o": 22.155,
			"c": 22.1454,
			"h": 22.1564,
			"l": 22.1432,
			"t": 1344539040000,
			"n": 119
		  },
		  {
			"v": 826840,
			"vw": 22.1405,
			"o": 22.1443,
			"c": 22.1357,
			"h": 22.1489,
			"l": 22.1357,
			"t": 1344539100000,
			"n": 174
		  },
		  {
			"v": 227388,
			"vw": 22.1405,
			"o": 22.1357,
			"c": 22.1415,
			"h": 22.1429,
			"l": 22.1357,
			"t": 1344539160000,
			"n": 41
		  },
		  {
			"v": 206584,
			"vw": 22.1416,
			"o": 22.1407,
			"c": 22.1411,
			"h": 22.146,
			"l": 22.1375,
			"t": 1344539220000,
			"n": 54
		  },
		  {
			"v": 238812,
			"vw": 22.1396,
			"o": 22.1414,
			"c": 22.1379,
			"h": 22.1429,
			"l": 22.1371,
			"t": 1344539280000,
			"n": 39
		  },
		  {
			"v": 495740,
			"vw": 22.1346,
			"o": 22.1382,
			"c": 22.1393,
			"h": 22.1405,
			"l": 22.1314,
			"t": 1344539340000,
			"n": 98
		  },
		  {
			"v": 250180,
			"vw": 22.1445,
			"o": 22.1414,
			"c": 22.1446,
			"h": 22.1486,
			"l": 22.1379,
			"t": 1344539400000,
			"n": 61
		  },
		  {
			"v": 342048,
			"vw": 22.1488,
			"o": 22.145,
			"c": 22.1468,
			"h": 22.1517,
			"l": 22.1446,
			"t": 1344539460000,
			"n": 85
		  },
		  {
			"v": 806596,
			"vw": 22.1324,
			"o": 22.147,
			"c": 22.1507,
			"h": 22.1521,
			"l": 22.1468,
			"t": 1344539520000,
			"n": 66
		  },
		  {
			"v": 215936,
			"vw": 22.1483,
			"o": 22.1475,
			"c": 22.1466,
			"h": 22.1525,
			"l": 22.145,
			"t": 1344539580000,
			"n": 53
		  },
		  {
			"v": 351512,
			"vw": 22.1538,
			"o": 22.1486,
			"c": 22.1538,
			"h": 22.1571,
			"l": 22.1469,
			"t": 1344539640000,
			"n": 101
		  },
		  {
			"v": 369180,
			"vw": 22.1581,
			"o": 22.1564,
			"c": 22.1571,
			"h": 22.1607,
			"l": 22.155,
			"t": 1344539700000,
			"n": 79
		  },
		  {
			"v": 207788,
			"vw": 22.1553,
			"o": 22.1579,
			"c": 22.1564,
			"h": 22.1593,
			"l": 22.1507,
			"t": 1344539760000,
			"n": 48
		  },
		  {
			"v": 546448,
			"vw": 22.1594,
			"o": 22.1564,
			"c": 22.1611,
			"h": 22.1639,
			"l": 22.1546,
			"t": 1344539820000,
			"n": 72
		  },
		  {
			"v": 346472,
			"vw": 22.1628,
			"o": 22.1639,
			"c": 22.1607,
			"h": 22.1654,
			"l": 22.1594,
			"t": 1344539880000,
			"n": 72
		  },
		  {
			"v": 170016,
			"vw": 22.1618,
			"o": 22.1611,
			"c": 22.1639,
			"h": 22.1654,
			"l": 22.16,
			"t": 1344539940000,
			"n": 43
		  },
		  {
			"v": 483868,
			"vw": 22.1668,
			"o": 22.1639,
			"c": 22.1611,
			"h": 22.1689,
			"l": 22.1611,
			"t": 1344540000000,
			"n": 101
		  },
		  {
			"v": 257572,
			"vw": 22.1622,
			"o": 22.1611,
			"c": 22.1643,
			"h": 22.1646,
			"l": 22.16,
			"t": 1344540060000,
			"n": 42
		  },
		  {
			"v": 313264,
			"vw": 22.1611,
			"o": 22.1618,
			"c": 22.1629,
			"h": 22.1643,
			"l": 22.16,
			"t": 1344540120000,
			"n": 58
		  },
		  {
			"v": 211512,
			"vw": 22.1645,
			"o": 22.1636,
			"c": 22.1661,
			"h": 22.1675,
			"l": 22.1611,
			"t": 1344540180000,
			"n": 52
		  },
		  {
			"v": 291172,
			"vw": 22.1669,
			"o": 22.1657,
			"c": 22.1632,
			"h": 22.1689,
			"l": 22.1632,
			"t": 1344540240000,
			"n": 78
		  },
		  {
			"v": 429156,
			"vw": 22.167,
			"o": 22.1648,
			"c": 22.1636,
			"h": 22.1696,
			"l": 22.1629,
			"t": 1344540300000,
			"n": 93
		  },
		  {
			"v": 114660,
			"vw": 22.1652,
			"o": 22.1633,
			"c": 22.1686,
			"h": 22.1689,
			"l": 22.1629,
			"t": 1344540360000,
			"n": 35
		  },
		  {
			"v": 898436,
			"vw": 22.1717,
			"o": 22.1679,
			"c": 22.1718,
			"h": 22.1746,
			"l": 22.167,
			"t": 1344540420000,
			"n": 122
		  },
		  {
			"v": 182140,
			"vw": 22.1699,
			"o": 22.1721,
			"c": 22.1707,
			"h": 22.1729,
			"l": 22.1661,
			"t": 1344540480000,
			"n": 45
		  },
		  {
			"v": 343728,
			"vw": 22.1696,
			"o": 22.1714,
			"c": 22.1661,
			"h": 22.1731,
			"l": 22.1661,
			"t": 1344540540000,
			"n": 66
		  },
		  {
			"v": 203084,
			"vw": 22.1666,
			"o": 22.1664,
			"c": 22.1679,
			"h": 22.17,
			"l": 22.1639,
			"t": 1344540600000,
			"n": 43
		  },
		  {
			"v": 210644,
			"vw": 22.1707,
			"o": 22.1661,
			"c": 22.1682,
			"h": 22.1731,
			"l": 22.1654,
			"t": 1344540660000,
			"n": 42
		  },
		  {
			"v": 1528184,
			"vw": 22.1593,
			"o": 22.1679,
			"c": 22.1661,
			"h": 22.1706,
			"l": 22.1621,
			"t": 1344540720000,
			"n": 154
		  },
		  {
			"v": 492128,
			"vw": 22.1636,
			"o": 22.1675,
			"c": 22.1643,
			"h": 22.1679,
			"l": 22.1625,
			"t": 1344540780000,
			"n": 100
		  },
		  {
			"v": 352940,
			"vw": 22.1578,
			"o": 22.1625,
			"c": 22.1554,
			"h": 22.1626,
			"l": 22.1554,
			"t": 1344540840000,
			"n": 98
		  },
		  {
			"v": 417088,
			"vw": 22.1573,
			"o": 22.1573,
			"c": 22.1568,
			"h": 22.1618,
			"l": 22.1536,
			"t": 1344540900000,
			"n": 104
		  },
		  {
			"v": 192668,
			"vw": 22.1573,
			"o": 22.1571,
			"c": 22.1593,
			"h": 22.1593,
			"l": 22.1536,
			"t": 1344540960000,
			"n": 58
		  },
		  {
			"v": 290164,
			"vw": 22.1548,
			"o": 22.1571,
			"c": 22.1518,
			"h": 22.1582,
			"l": 22.1518,
			"t": 1344541020000,
			"n": 91
		  },
		  {
			"v": 669900,
			"vw": 22.149,
			"o": 22.1521,
			"c": 22.1461,
			"h": 22.1532,
			"l": 22.145,
			"t": 1344541080000,
			"n": 194
		  },
		  {
			"v": 351876,
			"vw": 22.1503,
			"o": 22.1457,
			"c": 22.1511,
			"h": 22.1518,
			"l": 22.1457,
			"t": 1344541140000,
			"n": 95
		  },
		  {
			"v": 409668,
			"vw": 22.1464,
			"o": 22.1511,
			"c": 22.1479,
			"h": 22.1511,
			"l": 22.1429,
			"t": 1344541200000,
			"n": 108
		  },
		  {
			"v": 398328,
			"vw": 22.1507,
			"o": 22.1482,
			"c": 22.1543,
			"h": 22.1546,
			"l": 22.1457,
			"t": 1344541260000,
			"n": 114
		  },
		  {
			"v": 418572,
			"vw": 22.1519,
			"o": 22.1525,
			"c": 22.1536,
			"h": 22.1562,
			"l": 22.1482,
			"t": 1344541320000,
			"n": 107
		  },
		  {
			"v": 519820,
			"vw": 22.1563,
			"o": 22.1536,
			"c": 22.1554,
			"h": 22.1582,
			"l": 22.1536,
			"t": 1344541380000,
			"n": 108
		  },
		  {
			"v": 582008,
			"vw": 22.1596,
			"o": 22.1536,
			"c": 22.1624,
			"h": 22.1625,
			"l": 22.1536,
			"t": 1344541440000,
			"n": 91
		  },
		  {
			"v": 450940,
			"vw": 22.1576,
			"o": 22.1618,
			"c": 22.1554,
			"h": 22.1618,
			"l": 22.1527,
			"t": 1344541500000,
			"n": 108
		  },
		  {
			"v": 1002960,
			"vw": 22.1482,
			"o": 22.1521,
			"c": 22.1507,
			"h": 22.1536,
			"l": 22.1454,
			"t": 1344541560000,
			"n": 203
		  },
		  {
			"v": 670768,
			"vw": 22.1532,
			"o": 22.1502,
			"c": 22.1579,
			"h": 22.1607,
			"l": 22.1482,
			"t": 1344541620000,
			"n": 175
		  },
		  {
			"v": 309400,
			"vw": 22.1564,
			"o": 22.1562,
			"c": 22.1537,
			"h": 22.1586,
			"l": 22.1536,
			"t": 1344541680000,
			"n": 81
		  },
		  {
			"v": 432096,
			"vw": 22.1545,
			"o": 22.1536,
			"c": 22.1528,
			"h": 22.1568,
			"l": 22.1525,
			"t": 1344541740000,
			"n": 121
		  },
		  {
			"v": 468384,
			"vw": 22.1547,
			"o": 22.155,
			"c": 22.1554,
			"h": 22.1575,
			"l": 22.1508,
			"t": 1344541800000,
			"n": 138
		  },
		  {
			"v": 805420,
			"vw": 22.1566,
			"o": 22.153,
			"c": 22.1539,
			"h": 22.1604,
			"l": 22.153,
			"t": 1344541860000,
			"n": 206
		  },
		  {
			"v": 481544,
			"vw": 22.1531,
			"o": 22.1543,
			"c": 22.1536,
			"h": 22.1564,
			"l": 22.1504,
			"t": 1344541920000,
			"n": 132
		  },
		  {
			"v": 576240,
			"vw": 22.1584,
			"o": 22.1529,
			"c": 22.1557,
			"h": 22.1618,
			"l": 22.1514,
			"t": 1344541980000,
			"n": 158
		  },
		  {
			"v": 531552,
			"vw": 22.1536,
			"o": 22.1554,
			"c": 22.1546,
			"h": 22.1571,
			"l": 22.15,
			"t": 1344542040000,
			"n": 168
		  },
		  {
			"v": 801416,
			"vw": 22.151,
			"o": 22.155,
			"c": 22.1535,
			"h": 22.1568,
			"l": 22.1468,
			"t": 1344542100000,
			"n": 233
		  },
		  {
			"v": 659568,
			"vw": 22.1508,
			"o": 22.1536,
			"c": 22.1529,
			"h": 22.1554,
			"l": 22.1479,
			"t": 1344542160000,
			"n": 187
		  },
		  {
			"v": 867412,
			"vw": 22.1517,
			"o": 22.1521,
			"c": 22.15,
			"h": 22.1546,
			"l": 22.15,
			"t": 1344542220000,
			"n": 239
		  },
		  {
			"v": 1609048,
			"vw": 22.1511,
			"o": 22.1507,
			"c": 22.1529,
			"h": 22.1543,
			"l": 22.1479,
			"t": 1344542280000,
			"n": 400
		  },
		  {
			"v": 2486400,
			"vw": 22.1613,
			"o": 22.1529,
			"c": 22.1689,
			"h": 22.1721,
			"l": 22.1514,
			"t": 1344542340000,
			"n": 589
		  },
		  {
			"v": 285320,
			"vw": 22.1696,
			"o": 22.165,
			"c": 22.1711,
			"h": 22.1768,
			"l": 22.1568,
			"t": 1344542400000,
			"n": 38
		  },
		  {
			"v": 36400,
			"vw": 22.1735,
			"o": 22.1746,
			"c": 22.1754,
			"h": 22.1764,
			"l": 22.1693,
			"t": 1344542460000,
			"n": 8
		  },
		  {
			"v": 86800,
			"vw": 22.1707,
			"o": 22.175,
			"c": 22.1718,
			"h": 22.175,
			"l": 22.1689,
			"t": 1344542520000,
			"n": 26
		  },
		  {
			"v": 14000,
			"vw": 22.1714,
			"o": 22.1732,
			"c": 22.1536,
			"h": 22.1775,
			"l": 22.1536,
			"t": 1344542580000,
			"n": 5
		  },
		  {
			"v": 9800,
			"vw": 22.1775,
			"o": 22.1775,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1775,
			"t": 1344542640000,
			"n": 3
		  },
		  {
			"v": 14644,
			"vw": 22.1767,
			"o": 22.1761,
			"c": 22.1768,
			"h": 22.1768,
			"l": 22.1761,
			"t": 1344542700000,
			"n": 4
		  },
		  {
			"v": 18200,
			"vw": 22.1767,
			"o": 22.175,
			"c": 22.1779,
			"h": 22.1779,
			"l": 22.1746,
			"t": 1344542820000,
			"n": 6
		  },
		  {
			"v": 24080,
			"vw": 22.1769,
			"o": 22.1754,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1754,
			"t": 1344542940000,
			"n": 8
		  },
		  {
			"v": 17500,
			"vw": 22.177,
			"o": 22.1764,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1764,
			"t": 1344543300000,
			"n": 5
		  },
		  {
			"v": 39200,
			"vw": 22.1728,
			"o": 22.1746,
			"c": 22.175,
			"h": 22.175,
			"l": 22.1714,
			"t": 1344543420000,
			"n": 7
		  },
		  {
			"v": 4200,
			"vw": 22.1764,
			"o": 22.1764,
			"c": 22.1764,
			"h": 22.1764,
			"l": 22.1764,
			"t": 1344543480000,
			"n": 1
		  },
		  {
			"v": 23940,
			"vw": 22.1749,
			"o": 22.1754,
			"c": 22.1746,
			"h": 22.1754,
			"l": 22.1746,
			"t": 1344543540000,
			"n": 7
		  },
		  {
			"v": 39760,
			"vw": 22.178,
			"o": 22.1768,
			"c": 22.1782,
			"h": 22.1782,
			"l": 22.1768,
			"t": 1344543660000,
			"n": 5
		  },
		  {
			"v": 129556,
			"vw": 22.1784,
			"o": 22.1775,
			"c": 22.1811,
			"h": 22.1811,
			"l": 22.1775,
			"t": 1344543780000,
			"n": 19
		  },
		  {
			"v": 2800,
			"vw": 22.1775,
			"o": 22.1775,
			"c": 22.1775,
			"h": 22.1775,
			"l": 22.1775,
			"t": 1344543840000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1771,
			"o": 22.1768,
			"c": 22.1782,
			"h": 22.1782,
			"l": 22.1768,
			"t": 1344543900000,
			"n": 2
		  },
		  {
			"v": 59108,
			"vw": 22.171,
			"o": 22.1789,
			"c": 22.1821,
			"h": 22.1821,
			"l": 22.1786,
			"t": 1344543960000,
			"n": 5
		  },
		  {
			"v": 8400,
			"vw": 22.1807,
			"o": 22.1821,
			"c": 22.18,
			"h": 22.1821,
			"l": 22.18,
			"t": 1344544020000,
			"n": 2
		  },
		  {
			"v": 9800,
			"vw": 22.18,
			"o": 22.18,
			"c": 22.1804,
			"h": 22.1804,
			"l": 22.1796,
			"t": 1344544080000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1804,
			"o": 22.1804,
			"c": 22.1804,
			"h": 22.1804,
			"l": 22.1804,
			"t": 1344544140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1821,
			"o": 22.1821,
			"c": 22.1821,
			"h": 22.1821,
			"l": 22.1821,
			"t": 1344544200000,
			"n": 1
		  },
		  {
			"v": 44072,
			"vw": 22.1685,
			"o": 22.1804,
			"c": 22.1757,
			"h": 22.1804,
			"l": 22.1757,
			"t": 1344544260000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.1768,
			"o": 22.1768,
			"c": 22.1768,
			"h": 22.1768,
			"l": 22.1768,
			"t": 1344544380000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.1811,
			"o": 22.1811,
			"c": 22.1811,
			"h": 22.1811,
			"l": 22.1811,
			"t": 1344545340000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.1814,
			"o": 22.1814,
			"c": 22.1814,
			"h": 22.1814,
			"l": 22.1814,
			"t": 1344545400000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1821,
			"o": 22.1821,
			"c": 22.1821,
			"h": 22.1821,
			"l": 22.1821,
			"t": 1344545460000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1811,
			"o": 22.1811,
			"c": 22.1811,
			"h": 22.1811,
			"l": 22.1811,
			"t": 1344545520000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1811,
			"o": 22.1811,
			"c": 22.1811,
			"h": 22.1811,
			"l": 22.1811,
			"t": 1344545580000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.1835,
			"o": 22.1836,
			"c": 22.1832,
			"h": 22.1836,
			"l": 22.1832,
			"t": 1344545760000,
			"n": 2
		  },
		  {
			"v": 4704,
			"vw": 22.1829,
			"o": 22.1829,
			"c": 22.1829,
			"h": 22.1829,
			"l": 22.1829,
			"t": 1344546000000,
			"n": 1
		  },
		  {
			"v": 10920,
			"vw": 22.1835,
			"o": 22.1836,
			"c": 22.1832,
			"h": 22.1836,
			"l": 22.1832,
			"t": 1344546060000,
			"n": 2
		  },
		  {
			"v": 15456,
			"vw": 22.1816,
			"o": 22.1807,
			"c": 22.1832,
			"h": 22.1832,
			"l": 22.1796,
			"t": 1344546120000,
			"n": 4
		  },
		  {
			"v": 8400,
			"vw": 22.1748,
			"o": 22.1768,
			"c": 22.1732,
			"h": 22.1768,
			"l": 22.1732,
			"t": 1344547800000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1818,
			"o": 22.1818,
			"c": 22.1818,
			"h": 22.1818,
			"l": 22.1818,
			"t": 1344549060000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.1821,
			"o": 22.1821,
			"c": 22.1821,
			"h": 22.1821,
			"l": 22.1821,
			"t": 1344549120000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1804,
			"o": 22.1804,
			"c": 22.1804,
			"h": 22.1804,
			"l": 22.1804,
			"t": 1344549180000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1818,
			"o": 22.1818,
			"c": 22.1818,
			"h": 22.1818,
			"l": 22.1818,
			"t": 1344549240000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1789,
			"o": 22.1786,
			"c": 22.1793,
			"h": 22.1793,
			"l": 22.1786,
			"t": 1344549420000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.1789,
			"o": 22.1789,
			"c": 22.1789,
			"h": 22.1789,
			"l": 22.1789,
			"t": 1344550740000,
			"n": 1
		  },
		  {
			"v": 4200,
			"vw": 22.1757,
			"o": 22.1757,
			"c": 22.1757,
			"h": 22.1757,
			"l": 22.1757,
			"t": 1344551220000,
			"n": 1
		  },
		  {
			"v": 6160,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344551460000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344552120000,
			"n": 1
		  },
		  {
			"v": 27832,
			"vw": 22.1785,
			"o": 22.1782,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1782,
			"t": 1344552660000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344552720000,
			"n": 1
		  },
		  {
			"v": 4900,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344553020000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1768,
			"o": 22.1768,
			"c": 22.1768,
			"h": 22.1768,
			"l": 22.1768,
			"t": 1344553620000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.1825,
			"o": 22.1825,
			"c": 22.1825,
			"h": 22.1825,
			"l": 22.1825,
			"t": 1344553980000,
			"n": 3
		  },
		  {
			"v": 13300,
			"vw": 22.1829,
			"o": 22.1829,
			"c": 22.1829,
			"h": 22.1829,
			"l": 22.1829,
			"t": 1344554040000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1788,
			"o": 22.1789,
			"c": 22.1786,
			"h": 22.1789,
			"l": 22.1786,
			"t": 1344554220000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.1829,
			"o": 22.1829,
			"c": 22.1829,
			"h": 22.1829,
			"l": 22.1829,
			"t": 1344555240000,
			"n": 2
		  },
		  {
			"v": 4732,
			"vw": 22.1804,
			"o": 22.1804,
			"c": 22.1804,
			"h": 22.1804,
			"l": 22.1804,
			"t": 1344555540000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1811,
			"o": 22.1811,
			"c": 22.1811,
			"h": 22.1811,
			"l": 22.1811,
			"t": 1344555960000,
			"n": 1
		  },
		  {
			"v": 4620,
			"vw": 22.1829,
			"o": 22.1829,
			"c": 22.1829,
			"h": 22.1829,
			"l": 22.1829,
			"t": 1344556260000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.179,
			"o": 22.1796,
			"c": 22.1786,
			"h": 22.1796,
			"l": 22.1786,
			"t": 1344556320000,
			"n": 4
		  },
		  {
			"v": 12684,
			"vw": 22.1789,
			"o": 22.1789,
			"c": 22.1786,
			"h": 22.1789,
			"l": 22.1786,
			"t": 1344556380000,
			"n": 3
		  },
		  {
			"v": 4032,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344556440000,
			"n": 1
		  },
		  {
			"v": 37128,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344556680000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.1321,
			"o": 22.1321,
			"c": 22.1321,
			"h": 22.1321,
			"l": 22.1321,
			"t": 1344593280000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1321,
			"o": 22.1321,
			"c": 22.1321,
			"h": 22.1321,
			"l": 22.1321,
			"t": 1344594000000,
			"n": 1
		  },
		  {
			"v": 3780,
			"vw": 22.1321,
			"o": 22.1321,
			"c": 22.1321,
			"h": 22.1321,
			"l": 22.1321,
			"t": 1344594240000,
			"n": 1
		  },
		  {
			"v": 14980,
			"vw": 22.1206,
			"o": 22.1218,
			"c": 22.1204,
			"h": 22.1218,
			"l": 22.1204,
			"t": 1344596520000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.125,
			"o": 22.125,
			"c": 22.125,
			"h": 22.125,
			"l": 22.125,
			"t": 1344596760000,
			"n": 1
		  },
		  {
			"v": 26600,
			"vw": 22.1099,
			"o": 22.1204,
			"c": 22.1071,
			"h": 22.1204,
			"l": 22.1071,
			"t": 1344596820000,
			"n": 9
		  },
		  {
			"v": 15652,
			"vw": 22.1056,
			"o": 22.1071,
			"c": 22.0986,
			"h": 22.1071,
			"l": 22.0986,
			"t": 1344597000000,
			"n": 5
		  },
		  {
			"v": 11200,
			"vw": 22.0988,
			"o": 22.0993,
			"c": 22.0986,
			"h": 22.0993,
			"l": 22.0986,
			"t": 1344597060000,
			"n": 4
		  },
		  {
			"v": 19600,
			"vw": 22.0822,
			"o": 22.0893,
			"c": 22.08,
			"h": 22.0893,
			"l": 22.08,
			"t": 1344597240000,
			"n": 7
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344597360000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344597420000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.06,
			"o": 22.06,
			"c": 22.06,
			"h": 22.06,
			"l": 22.06,
			"t": 1344597660000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344597840000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.0711,
			"o": 22.0707,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0707,
			"t": 1344597900000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344598020000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0711,
			"o": 22.0711,
			"c": 22.0711,
			"h": 22.0711,
			"l": 22.0711,
			"t": 1344598140000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0714,
			"o": 22.0714,
			"c": 22.0714,
			"h": 22.0714,
			"l": 22.0714,
			"t": 1344598260000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344598320000,
			"n": 1
		  },
		  {
			"v": 25200,
			"vw": 22.0982,
			"o": 22.0982,
			"c": 22.0982,
			"h": 22.0982,
			"l": 22.0982,
			"t": 1344598740000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.0991,
			"o": 22.1071,
			"c": 22.0911,
			"h": 22.1071,
			"l": 22.0911,
			"t": 1344599400000,
			"n": 2
		  },
		  {
			"v": 44716,
			"vw": 22.0892,
			"o": 22.0893,
			"c": 22.0875,
			"h": 22.0893,
			"l": 22.0875,
			"t": 1344599580000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344599640000,
			"n": 1
		  },
		  {
			"v": 7140,
			"vw": 22.086,
			"o": 22.0839,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0839,
			"t": 1344599760000,
			"n": 2
		  },
		  {
			"v": 113904,
			"vw": 22.0899,
			"o": 22.0907,
			"c": 22.0893,
			"h": 22.0982,
			"l": 22.0893,
			"t": 1344600000000,
			"n": 5
		  },
		  {
			"v": 28980,
			"vw": 22.0976,
			"o": 22.0929,
			"c": 22.0982,
			"h": 22.0982,
			"l": 22.0929,
			"t": 1344600060000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0979,
			"o": 22.0979,
			"c": 22.0979,
			"h": 22.0979,
			"l": 22.0979,
			"t": 1344600120000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0979,
			"o": 22.0979,
			"c": 22.0979,
			"h": 22.0979,
			"l": 22.0979,
			"t": 1344600180000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0973,
			"o": 22.0986,
			"c": 22.0946,
			"h": 22.0986,
			"l": 22.0946,
			"t": 1344600360000,
			"n": 2
		  },
		  {
			"v": 16240,
			"vw": 22.0908,
			"o": 22.0893,
			"c": 22.0911,
			"h": 22.0911,
			"l": 22.0893,
			"t": 1344600420000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344600480000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344600540000,
			"n": 2
		  },
		  {
			"v": 25620,
			"vw": 22.0895,
			"o": 22.0911,
			"c": 22.0893,
			"h": 22.0911,
			"l": 22.0893,
			"t": 1344600660000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.0911,
			"o": 22.0911,
			"c": 22.0911,
			"h": 22.0911,
			"l": 22.0911,
			"t": 1344600720000,
			"n": 1
		  },
		  {
			"v": 19236,
			"vw": 22.0895,
			"o": 22.0911,
			"c": 22.0893,
			"h": 22.0911,
			"l": 22.0857,
			"t": 1344600780000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344600840000,
			"n": 1
		  },
		  {
			"v": 42000,
			"vw": 22.079,
			"o": 22.0907,
			"c": 22.0893,
			"h": 22.0907,
			"l": 22.0893,
			"t": 1344600960000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.1004,
			"o": 22.1004,
			"c": 22.1004,
			"h": 22.1004,
			"l": 22.1004,
			"t": 1344601320000,
			"n": 1
		  },
		  {
			"v": 12320,
			"vw": 22.0933,
			"o": 22.0946,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0886,
			"t": 1344601440000,
			"n": 4
		  },
		  {
			"v": 7980,
			"vw": 22.1029,
			"o": 22.1029,
			"c": 22.1029,
			"h": 22.1029,
			"l": 22.1029,
			"t": 1344601500000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.0989,
			"o": 22.0989,
			"c": 22.0989,
			"h": 22.0989,
			"l": 22.0989,
			"t": 1344601620000,
			"n": 1
		  },
		  {
			"v": 336000,
			"vw": 22.1689,
			"o": 22.1689,
			"c": 22.1689,
			"h": 22.1689,
			"l": 22.1689,
			"t": 1344601680000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.0979,
			"o": 22.0993,
			"c": 22.0993,
			"h": 22.0993,
			"l": 22.0936,
			"t": 1344601740000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.1004,
			"o": 22.1007,
			"c": 22.1,
			"h": 22.1007,
			"l": 22.1,
			"t": 1344601800000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.0929,
			"o": 22.0929,
			"c": 22.0929,
			"h": 22.0929,
			"l": 22.0929,
			"t": 1344601860000,
			"n": 1
		  },
		  {
			"v": 341600,
			"vw": 22.1678,
			"o": 22.1,
			"c": 22.1689,
			"h": 22.1689,
			"l": 22.1,
			"t": 1344601920000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.0959,
			"o": 22.0964,
			"c": 22.0954,
			"h": 22.0964,
			"l": 22.0954,
			"t": 1344602040000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.092,
			"o": 22.0893,
			"c": 22.0946,
			"h": 22.0946,
			"l": 22.0893,
			"t": 1344602100000,
			"n": 2
		  },
		  {
			"v": 75964,
			"vw": 22.0864,
			"o": 22.0896,
			"c": 22.0804,
			"h": 22.0896,
			"l": 22.0804,
			"t": 1344602160000,
			"n": 13
		  },
		  {
			"v": 21056,
			"vw": 22.0871,
			"o": 22.0811,
			"c": 22.0993,
			"h": 22.0993,
			"l": 22.0786,
			"t": 1344602220000,
			"n": 5
		  },
		  {
			"v": 13720,
			"vw": 22.0893,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0893,
			"t": 1344602280000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.0886,
			"o": 22.0811,
			"c": 22.0961,
			"h": 22.0961,
			"l": 22.0811,
			"t": 1344602340000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.0946,
			"o": 22.0929,
			"c": 22.0957,
			"h": 22.0957,
			"l": 22.0929,
			"t": 1344602400000,
			"n": 3
		  },
		  {
			"v": 28000,
			"vw": 22.0858,
			"o": 22.0893,
			"c": 22.0893,
			"h": 22.0893,
			"l": 22.0825,
			"t": 1344602460000,
			"n": 10
		  },
		  {
			"v": 12320,
			"vw": 22.0894,
			"o": 22.09,
			"c": 22.0893,
			"h": 22.09,
			"l": 22.0893,
			"t": 1344602520000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.0929,
			"o": 22.0929,
			"c": 22.0929,
			"h": 22.0929,
			"l": 22.0929,
			"t": 1344602580000,
			"n": 2
		  },
		  {
			"v": 32200,
			"vw": 22.0939,
			"o": 22.0936,
			"c": 22.0932,
			"h": 22.0946,
			"l": 22.0929,
			"t": 1344602700000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.0975,
			"o": 22.0975,
			"c": 22.0975,
			"h": 22.0975,
			"l": 22.0975,
			"t": 1344602760000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.0962,
			"o": 22.0932,
			"c": 22.0971,
			"h": 22.0982,
			"l": 22.0932,
			"t": 1344602880000,
			"n": 3
		  },
		  {
			"v": 30800,
			"vw": 22.098,
			"o": 22.0982,
			"c": 22.0964,
			"h": 22.0982,
			"l": 22.0964,
			"t": 1344602940000,
			"n": 11
		  },
		  {
			"v": 7000,
			"vw": 22.1,
			"o": 22.1,
			"c": 22.1,
			"h": 22.1,
			"l": 22.1,
			"t": 1344603000000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1077,
			"o": 22.1071,
			"c": 22.1082,
			"h": 22.1082,
			"l": 22.1071,
			"t": 1344603120000,
			"n": 2
		  },
		  {
			"v": 11200,
			"vw": 22.1147,
			"o": 22.1139,
			"c": 22.1154,
			"h": 22.1154,
			"l": 22.1139,
			"t": 1344603180000,
			"n": 3
		  },
		  {
			"v": 22400,
			"vw": 22.118,
			"o": 22.1175,
			"c": 22.12,
			"h": 22.12,
			"l": 22.1157,
			"t": 1344603300000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.1196,
			"o": 22.1196,
			"c": 22.1196,
			"h": 22.1196,
			"l": 22.1196,
			"t": 1344603360000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.1214,
			"o": 22.1196,
			"c": 22.1232,
			"h": 22.1232,
			"l": 22.1196,
			"t": 1344603420000,
			"n": 2
		  },
		  {
			"v": 51492,
			"vw": 22.1325,
			"o": 22.1207,
			"c": 22.1357,
			"h": 22.1357,
			"l": 22.1207,
			"t": 1344603480000,
			"n": 16
		  },
		  {
			"v": 27720,
			"vw": 22.1334,
			"o": 22.1364,
			"c": 22.1321,
			"h": 22.1364,
			"l": 22.1321,
			"t": 1344603660000,
			"n": 4
		  },
		  {
			"v": 89880,
			"vw": 22.1257,
			"o": 22.1332,
			"c": 22.1164,
			"h": 22.1332,
			"l": 22.1164,
			"t": 1344603720000,
			"n": 15
		  },
		  {
			"v": 25536,
			"vw": 22.125,
			"o": 22.1246,
			"c": 22.125,
			"h": 22.125,
			"l": 22.1246,
			"t": 1344603780000,
			"n": 8
		  },
		  {
			"v": 58912,
			"vw": 22.1216,
			"o": 22.1168,
			"c": 22.1232,
			"h": 22.1232,
			"l": 22.1168,
			"t": 1344603840000,
			"n": 11
		  },
		  {
			"v": 2800,
			"vw": 22.1293,
			"o": 22.1293,
			"c": 22.1293,
			"h": 22.1293,
			"l": 22.1293,
			"t": 1344603900000,
			"n": 1
		  },
		  {
			"v": 30156,
			"vw": 22.1228,
			"o": 22.125,
			"c": 22.1221,
			"h": 22.125,
			"l": 22.1221,
			"t": 1344603960000,
			"n": 6
		  },
		  {
			"v": 29540,
			"vw": 22.1218,
			"o": 22.1221,
			"c": 22.1214,
			"h": 22.1221,
			"l": 22.1214,
			"t": 1344604020000,
			"n": 5
		  },
		  {
			"v": 16800,
			"vw": 22.1237,
			"o": 22.1304,
			"c": 22.1207,
			"h": 22.1304,
			"l": 22.1179,
			"t": 1344604080000,
			"n": 5
		  },
		  {
			"v": 3640,
			"vw": 22.1164,
			"o": 22.1164,
			"c": 22.1164,
			"h": 22.1164,
			"l": 22.1164,
			"t": 1344604140000,
			"n": 1
		  },
		  {
			"v": 4620,
			"vw": 22.1179,
			"o": 22.1179,
			"c": 22.1179,
			"h": 22.1179,
			"l": 22.1179,
			"t": 1344604200000,
			"n": 1
		  },
		  {
			"v": 10220,
			"vw": 22.1125,
			"o": 22.1125,
			"c": 22.1125,
			"h": 22.1125,
			"l": 22.1125,
			"t": 1344604320000,
			"n": 2
		  },
		  {
			"v": 34692,
			"vw": 22.1072,
			"o": 22.1107,
			"c": 22.1082,
			"h": 22.1107,
			"l": 22.1046,
			"t": 1344604380000,
			"n": 10
		  },
		  {
			"v": 70000,
			"vw": 22.11,
			"o": 22.0989,
			"c": 22.1179,
			"h": 22.1179,
			"l": 22.0989,
			"t": 1344604440000,
			"n": 17
		  },
		  {
			"v": 32508,
			"vw": 22.1114,
			"o": 22.1071,
			"c": 22.1186,
			"h": 22.1186,
			"l": 22.1068,
			"t": 1344604500000,
			"n": 10
		  },
		  {
			"v": 2800,
			"vw": 22.1154,
			"o": 22.1154,
			"c": 22.1154,
			"h": 22.1154,
			"l": 22.1154,
			"t": 1344604560000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1164,
			"o": 22.1164,
			"c": 22.1164,
			"h": 22.1164,
			"l": 22.1164,
			"t": 1344604620000,
			"n": 1
		  },
		  {
			"v": 22232,
			"vw": 22.1125,
			"o": 22.1125,
			"c": 22.1125,
			"h": 22.1125,
			"l": 22.1125,
			"t": 1344604740000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.1102,
			"o": 22.1121,
			"c": 22.1082,
			"h": 22.1121,
			"l": 22.1082,
			"t": 1344604800000,
			"n": 2
		  },
		  {
			"v": 66024,
			"vw": 22.107,
			"o": 22.1093,
			"c": 22.1036,
			"h": 22.1096,
			"l": 22.1036,
			"t": 1344604860000,
			"n": 17
		  },
		  {
			"v": 39200,
			"vw": 22.0998,
			"o": 22.1061,
			"c": 22.0968,
			"h": 22.1061,
			"l": 22.0932,
			"t": 1344604920000,
			"n": 10
		  },
		  {
			"v": 22400,
			"vw": 22.0966,
			"o": 22.0982,
			"c": 22.0996,
			"h": 22.1004,
			"l": 22.0932,
			"t": 1344604980000,
			"n": 8
		  },
		  {
			"v": 16800,
			"vw": 22.0942,
			"o": 22.0993,
			"c": 22.0929,
			"h": 22.0993,
			"l": 22.0929,
			"t": 1344605040000,
			"n": 6
		  },
		  {
			"v": 26516,
			"vw": 22.0939,
			"o": 22.0982,
			"c": 22.0929,
			"h": 22.0982,
			"l": 22.0929,
			"t": 1344605160000,
			"n": 5
		  },
		  {
			"v": 4200,
			"vw": 22.1061,
			"o": 22.1061,
			"c": 22.1061,
			"h": 22.1061,
			"l": 22.1061,
			"t": 1344605220000,
			"n": 1
		  },
		  {
			"v": 153300,
			"vw": 22.1008,
			"o": 22.0982,
			"c": 22.115,
			"h": 22.115,
			"l": 22.0932,
			"t": 1344605280000,
			"n": 33
		  },
		  {
			"v": 86100,
			"vw": 22.1074,
			"o": 22.0932,
			"c": 22.11,
			"h": 22.1161,
			"l": 22.0921,
			"t": 1344605340000,
			"n": 20
		  },
		  {
			"v": 3222940,
			"vw": 22.1075,
			"o": 22.0968,
			"c": 22.1239,
			"h": 22.1296,
			"l": 22.0968,
			"t": 1344605400000,
			"n": 401
		  },
		  {
			"v": 1415484,
			"vw": 22.1272,
			"o": 22.1289,
			"c": 22.1428,
			"h": 22.1429,
			"l": 22.1136,
			"t": 1344605460000,
			"n": 364
		  },
		  {
			"v": 1376760,
			"vw": 22.1425,
			"o": 22.1429,
			"c": 22.1365,
			"h": 22.1494,
			"l": 22.1296,
			"t": 1344605520000,
			"n": 299
		  },
		  {
			"v": 1339268,
			"vw": 22.1207,
			"o": 22.1379,
			"c": 22.1204,
			"h": 22.1379,
			"l": 22.1118,
			"t": 1344605580000,
			"n": 315
		  },
		  {
			"v": 828156,
			"vw": 22.1213,
			"o": 22.1191,
			"c": 22.1182,
			"h": 22.1286,
			"l": 22.1148,
			"t": 1344605640000,
			"n": 191
		  },
		  {
			"v": 1958572,
			"vw": 22.1165,
			"o": 22.12,
			"c": 22.1239,
			"h": 22.1307,
			"l": 22.0964,
			"t": 1344605700000,
			"n": 481
		  },
		  {
			"v": 878724,
			"vw": 22.1247,
			"o": 22.1218,
			"c": 22.125,
			"h": 22.1307,
			"l": 22.1161,
			"t": 1344605760000,
			"n": 158
		  },
		  {
			"v": 2087960,
			"vw": 22.1456,
			"o": 22.1277,
			"c": 22.1554,
			"h": 22.1607,
			"l": 22.1275,
			"t": 1344605820000,
			"n": 472
		  },
		  {
			"v": 1649508,
			"vw": 22.156,
			"o": 22.1571,
			"c": 22.1546,
			"h": 22.1639,
			"l": 22.1518,
			"t": 1344605880000,
			"n": 402
		  },
		  {
			"v": 989856,
			"vw": 22.1487,
			"o": 22.16,
			"c": 22.1435,
			"h": 22.1604,
			"l": 22.1329,
			"t": 1344605940000,
			"n": 250
		  },
		  {
			"v": 640416,
			"vw": 22.1503,
			"o": 22.145,
			"c": 22.1518,
			"h": 22.1568,
			"l": 22.1432,
			"t": 1344606000000,
			"n": 167
		  },
		  {
			"v": 739256,
			"vw": 22.1567,
			"o": 22.1511,
			"c": 22.1486,
			"h": 22.1607,
			"l": 22.1486,
			"t": 1344606060000,
			"n": 169
		  },
		  {
			"v": 965188,
			"vw": 22.1445,
			"o": 22.1479,
			"c": 22.1386,
			"h": 22.1536,
			"l": 22.1364,
			"t": 1344606120000,
			"n": 201
		  },
		  {
			"v": 481124,
			"vw": 22.1474,
			"o": 22.1418,
			"c": 22.1507,
			"h": 22.1511,
			"l": 22.1414,
			"t": 1344606180000,
			"n": 117
		  },
		  {
			"v": 660212,
			"vw": 22.1516,
			"o": 22.1507,
			"c": 22.1432,
			"h": 22.1586,
			"l": 22.1429,
			"t": 1344606240000,
			"n": 151
		  },
		  {
			"v": 1025472,
			"vw": 22.1348,
			"o": 22.1454,
			"c": 22.1335,
			"h": 22.1471,
			"l": 22.125,
			"t": 1344606300000,
			"n": 232
		  },
		  {
			"v": 730156,
			"vw": 22.137,
			"o": 22.1282,
			"c": 22.1371,
			"h": 22.1518,
			"l": 22.1282,
			"t": 1344606360000,
			"n": 191
		  },
		  {
			"v": 917644,
			"vw": 22.1385,
			"o": 22.1407,
			"c": 22.135,
			"h": 22.1514,
			"l": 22.1307,
			"t": 1344606420000,
			"n": 210
		  },
		  {
			"v": 663488,
			"vw": 22.1347,
			"o": 22.135,
			"c": 22.1357,
			"h": 22.1425,
			"l": 22.1268,
			"t": 1344606480000,
			"n": 177
		  },
		  {
			"v": 652512,
			"vw": 22.1382,
			"o": 22.1354,
			"c": 22.1343,
			"h": 22.1471,
			"l": 22.1318,
			"t": 1344606540000,
			"n": 138
		  },
		  {
			"v": 803124,
			"vw": 22.1287,
			"o": 22.1375,
			"c": 22.13,
			"h": 22.1389,
			"l": 22.1218,
			"t": 1344606600000,
			"n": 178
		  },
		  {
			"v": 1157100,
			"vw": 22.1351,
			"o": 22.1289,
			"c": 22.1304,
			"h": 22.1479,
			"l": 22.1182,
			"t": 1344606660000,
			"n": 270
		  },
		  {
			"v": 893200,
			"vw": 22.1279,
			"o": 22.1314,
			"c": 22.1219,
			"h": 22.1361,
			"l": 22.1196,
			"t": 1344606720000,
			"n": 229
		  },
		  {
			"v": 897428,
			"vw": 22.128,
			"o": 22.122,
			"c": 22.1243,
			"h": 22.1357,
			"l": 22.1218,
			"t": 1344606780000,
			"n": 247
		  },
		  {
			"v": 638148,
			"vw": 22.124,
			"o": 22.1221,
			"c": 22.1208,
			"h": 22.1299,
			"l": 22.1182,
			"t": 1344606840000,
			"n": 185
		  },
		  {
			"v": 560952,
			"vw": 22.1257,
			"o": 22.1198,
			"c": 22.125,
			"h": 22.1339,
			"l": 22.1182,
			"t": 1344606900000,
			"n": 129
		  },
		  {
			"v": 395696,
			"vw": 22.1286,
			"o": 22.125,
			"c": 22.1286,
			"h": 22.1329,
			"l": 22.1232,
			"t": 1344606960000,
			"n": 95
		  },
		  {
			"v": 494704,
			"vw": 22.1243,
			"o": 22.1276,
			"c": 22.1239,
			"h": 22.1282,
			"l": 22.1193,
			"t": 1344607020000,
			"n": 91
		  },
		  {
			"v": 886256,
			"vw": 22.1403,
			"o": 22.1268,
			"c": 22.136,
			"h": 22.1482,
			"l": 22.1268,
			"t": 1344607080000,
			"n": 181
		  },
		  {
			"v": 412832,
			"vw": 22.1373,
			"o": 22.1344,
			"c": 22.1418,
			"h": 22.1418,
			"l": 22.1332,
			"t": 1344607140000,
			"n": 103
		  },
		  {
			"v": 994756,
			"vw": 22.1484,
			"o": 22.1429,
			"c": 22.1436,
			"h": 22.1546,
			"l": 22.14,
			"t": 1344607200000,
			"n": 201
		  },
		  {
			"v": 708540,
			"vw": 22.1456,
			"o": 22.1436,
			"c": 22.1432,
			"h": 22.15,
			"l": 22.1427,
			"t": 1344607260000,
			"n": 153
		  },
		  {
			"v": 653800,
			"vw": 22.1444,
			"o": 22.1432,
			"c": 22.1479,
			"h": 22.1511,
			"l": 22.1393,
			"t": 1344607320000,
			"n": 168
		  },
		  {
			"v": 1071756,
			"vw": 22.1592,
			"o": 22.1454,
			"c": 22.1605,
			"h": 22.1671,
			"l": 22.1443,
			"t": 1344607380000,
			"n": 234
		  },
		  {
			"v": 941864,
			"vw": 22.1582,
			"o": 22.1589,
			"c": 22.1539,
			"h": 22.1657,
			"l": 22.1486,
			"t": 1344607440000,
			"n": 213
		  },
		  {
			"v": 404068,
			"vw": 22.1528,
			"o": 22.155,
			"c": 22.1561,
			"h": 22.1561,
			"l": 22.1469,
			"t": 1344607500000,
			"n": 108
		  },
		  {
			"v": 284788,
			"vw": 22.1573,
			"o": 22.1516,
			"c": 22.1557,
			"h": 22.16,
			"l": 22.1516,
			"t": 1344607560000,
			"n": 81
		  },
		  {
			"v": 430108,
			"vw": 22.1544,
			"o": 22.1518,
			"c": 22.1604,
			"h": 22.1607,
			"l": 22.1468,
			"t": 1344607620000,
			"n": 121
		  },
		  {
			"v": 430472,
			"vw": 22.1577,
			"o": 22.1571,
			"c": 22.1595,
			"h": 22.1639,
			"l": 22.1526,
			"t": 1344607680000,
			"n": 93
		  },
		  {
			"v": 1047816,
			"vw": 22.1667,
			"o": 22.1631,
			"c": 22.1617,
			"h": 22.1729,
			"l": 22.1607,
			"t": 1344607740000,
			"n": 218
		  },
		  {
			"v": 1545488,
			"vw": 22.1745,
			"o": 22.1654,
			"c": 22.1733,
			"h": 22.1786,
			"l": 22.1614,
			"t": 1344607800000,
			"n": 367
		  },
		  {
			"v": 2161712,
			"vw": 22.1864,
			"o": 22.1764,
			"c": 22.1964,
			"h": 22.1964,
			"l": 22.1757,
			"t": 1344607860000,
			"n": 529
		  },
		  {
			"v": 1535940,
			"vw": 22.1974,
			"o": 22.1961,
			"c": 22.1933,
			"h": 22.2057,
			"l": 22.1786,
			"t": 1344607920000,
			"n": 351
		  },
		  {
			"v": 808332,
			"vw": 22.1899,
			"o": 22.1933,
			"c": 22.1843,
			"h": 22.1946,
			"l": 22.1843,
			"t": 1344607980000,
			"n": 190
		  },
		  {
			"v": 557564,
			"vw": 22.187,
			"o": 22.1832,
			"c": 22.1925,
			"h": 22.1939,
			"l": 22.1807,
			"t": 1344608040000,
			"n": 129
		  },
		  {
			"v": 565292,
			"vw": 22.1869,
			"o": 22.1929,
			"c": 22.1821,
			"h": 22.1957,
			"l": 22.1804,
			"t": 1344608100000,
			"n": 114
		  },
		  {
			"v": 677768,
			"vw": 22.1829,
			"o": 22.1811,
			"c": 22.1875,
			"h": 22.1904,
			"l": 22.1786,
			"t": 1344608160000,
			"n": 118
		  },
		  {
			"v": 790944,
			"vw": 22.1832,
			"o": 22.185,
			"c": 22.175,
			"h": 22.1886,
			"l": 22.1732,
			"t": 1344608220000,
			"n": 106
		  },
		  {
			"v": 496580,
			"vw": 22.1751,
			"o": 22.1739,
			"c": 22.1786,
			"h": 22.1821,
			"l": 22.1679,
			"t": 1344608280000,
			"n": 122
		  },
		  {
			"v": 757008,
			"vw": 22.1722,
			"o": 22.1792,
			"c": 22.1686,
			"h": 22.1804,
			"l": 22.1664,
			"t": 1344608340000,
			"n": 144
		  },
		  {
			"v": 602924,
			"vw": 22.1686,
			"o": 22.1718,
			"c": 22.1671,
			"h": 22.1743,
			"l": 22.1643,
			"t": 1344608400000,
			"n": 148
		  },
		  {
			"v": 428316,
			"vw": 22.1747,
			"o": 22.1679,
			"c": 22.1718,
			"h": 22.1807,
			"l": 22.1679,
			"t": 1344608460000,
			"n": 96
		  },
		  {
			"v": 386680,
			"vw": 22.1682,
			"o": 22.1718,
			"c": 22.1664,
			"h": 22.1721,
			"l": 22.165,
			"t": 1344608520000,
			"n": 100
		  },
		  {
			"v": 391944,
			"vw": 22.1728,
			"o": 22.1704,
			"c": 22.1696,
			"h": 22.175,
			"l": 22.1668,
			"t": 1344608580000,
			"n": 102
		  },
		  {
			"v": 262388,
			"vw": 22.1711,
			"o": 22.1693,
			"c": 22.1729,
			"h": 22.1742,
			"l": 22.1671,
			"t": 1344608640000,
			"n": 58
		  },
		  {
			"v": 386652,
			"vw": 22.1748,
			"o": 22.1746,
			"c": 22.1718,
			"h": 22.1786,
			"l": 22.1714,
			"t": 1344608700000,
			"n": 97
		  },
		  {
			"v": 758268,
			"vw": 22.166,
			"o": 22.1714,
			"c": 22.1721,
			"h": 22.1768,
			"l": 22.1611,
			"t": 1344608760000,
			"n": 176
		  },
		  {
			"v": 417312,
			"vw": 22.1693,
			"o": 22.1738,
			"c": 22.165,
			"h": 22.1738,
			"l": 22.165,
			"t": 1344608820000,
			"n": 109
		  },
		  {
			"v": 209020,
			"vw": 22.1671,
			"o": 22.1646,
			"c": 22.1682,
			"h": 22.1704,
			"l": 22.1636,
			"t": 1344608880000,
			"n": 57
		  },
		  {
			"v": 331240,
			"vw": 22.1665,
			"o": 22.1668,
			"c": 22.1679,
			"h": 22.1704,
			"l": 22.1618,
			"t": 1344608940000,
			"n": 104
		  },
		  {
			"v": 896112,
			"vw": 22.1772,
			"o": 22.1682,
			"c": 22.1786,
			"h": 22.1829,
			"l": 22.165,
			"t": 1344609000000,
			"n": 208
		  },
		  {
			"v": 641984,
			"vw": 22.1832,
			"o": 22.1786,
			"c": 22.1807,
			"h": 22.1868,
			"l": 22.1779,
			"t": 1344609060000,
			"n": 143
		  },
		  {
			"v": 1045156,
			"vw": 22.1851,
			"o": 22.1846,
			"c": 22.1855,
			"h": 22.1907,
			"l": 22.1768,
			"t": 1344609120000,
			"n": 268
		  },
		  {
			"v": 531440,
			"vw": 22.186,
			"o": 22.1867,
			"c": 22.1846,
			"h": 22.1886,
			"l": 22.1807,
			"t": 1344609180000,
			"n": 124
		  },
		  {
			"v": 336896,
			"vw": 22.1776,
			"o": 22.1825,
			"c": 22.1732,
			"h": 22.1846,
			"l": 22.1732,
			"t": 1344609240000,
			"n": 86
		  },
		  {
			"v": 265160,
			"vw": 22.1768,
			"o": 22.1743,
			"c": 22.1757,
			"h": 22.1821,
			"l": 22.1732,
			"t": 1344609300000,
			"n": 70
		  },
		  {
			"v": 644420,
			"vw": 22.1726,
			"o": 22.1764,
			"c": 22.1771,
			"h": 22.1796,
			"l": 22.1661,
			"t": 1344609360000,
			"n": 148
		  },
		  {
			"v": 179172,
			"vw": 22.1793,
			"o": 22.175,
			"c": 22.1807,
			"h": 22.1831,
			"l": 22.175,
			"t": 1344609420000,
			"n": 38
		  },
		  {
			"v": 472192,
			"vw": 22.1841,
			"o": 22.18,
			"c": 22.1832,
			"h": 22.1893,
			"l": 22.1779,
			"t": 1344609480000,
			"n": 99
		  },
		  {
			"v": 262556,
			"vw": 22.1813,
			"o": 22.1829,
			"c": 22.1797,
			"h": 22.1861,
			"l": 22.1764,
			"t": 1344609540000,
			"n": 55
		  },
		  {
			"v": 112812,
			"vw": 22.1788,
			"o": 22.1786,
			"c": 22.1779,
			"h": 22.1815,
			"l": 22.1761,
			"t": 1344609600000,
			"n": 31
		  },
		  {
			"v": 1043308,
			"vw": 22.178,
			"o": 22.1776,
			"c": 22.1764,
			"h": 22.1786,
			"l": 22.1725,
			"t": 1344609660000,
			"n": 60
		  },
		  {
			"v": 313936,
			"vw": 22.1721,
			"o": 22.175,
			"c": 22.1686,
			"h": 22.176,
			"l": 22.1682,
			"t": 1344609720000,
			"n": 59
		  },
		  {
			"v": 366380,
			"vw": 22.1744,
			"o": 22.1714,
			"c": 22.1711,
			"h": 22.1775,
			"l": 22.1711,
			"t": 1344609780000,
			"n": 86
		  },
		  {
			"v": 375844,
			"vw": 22.1735,
			"o": 22.17,
			"c": 22.1729,
			"h": 22.1775,
			"l": 22.1696,
			"t": 1344609840000,
			"n": 90
		  },
		  {
			"v": 186452,
			"vw": 22.1724,
			"o": 22.1768,
			"c": 22.1689,
			"h": 22.1779,
			"l": 22.1686,
			"t": 1344609900000,
			"n": 46
		  },
		  {
			"v": 695912,
			"vw": 22.1641,
			"o": 22.1693,
			"c": 22.1639,
			"h": 22.1754,
			"l": 22.1554,
			"t": 1344609960000,
			"n": 145
		  },
		  {
			"v": 956340,
			"vw": 22.157,
			"o": 22.1618,
			"c": 22.1571,
			"h": 22.1632,
			"l": 22.15,
			"t": 1344610020000,
			"n": 181
		  },
		  {
			"v": 284116,
			"vw": 22.1522,
			"o": 22.1525,
			"c": 22.1504,
			"h": 22.1574,
			"l": 22.15,
			"t": 1344610080000,
			"n": 67
		  },
		  {
			"v": 330316,
			"vw": 22.1566,
			"o": 22.1536,
			"c": 22.1586,
			"h": 22.1632,
			"l": 22.1479,
			"t": 1344610140000,
			"n": 85
		  },
		  {
			"v": 156660,
			"vw": 22.1571,
			"o": 22.1617,
			"c": 22.1543,
			"h": 22.1617,
			"l": 22.1536,
			"t": 1344610200000,
			"n": 28
		  },
		  {
			"v": 164556,
			"vw": 22.1551,
			"o": 22.1568,
			"c": 22.1561,
			"h": 22.1575,
			"l": 22.1511,
			"t": 1344610260000,
			"n": 44
		  },
		  {
			"v": 408184,
			"vw": 22.16,
			"o": 22.1561,
			"c": 22.1643,
			"h": 22.1643,
			"l": 22.1542,
			"t": 1344610320000,
			"n": 86
		  },
		  {
			"v": 230552,
			"vw": 22.1613,
			"o": 22.1636,
			"c": 22.1632,
			"h": 22.1636,
			"l": 22.1596,
			"t": 1344610380000,
			"n": 43
		  },
		  {
			"v": 283780,
			"vw": 22.164,
			"o": 22.1632,
			"c": 22.1664,
			"h": 22.1686,
			"l": 22.1607,
			"t": 1344610440000,
			"n": 50
		  },
		  {
			"v": 162820,
			"vw": 22.1677,
			"o": 22.1652,
			"c": 22.1696,
			"h": 22.1696,
			"l": 22.1639,
			"t": 1344610500000,
			"n": 31
		  },
		  {
			"v": 300776,
			"vw": 22.1644,
			"o": 22.1711,
			"c": 22.1611,
			"h": 22.1714,
			"l": 22.16,
			"t": 1344610560000,
			"n": 62
		  },
		  {
			"v": 333144,
			"vw": 22.1574,
			"o": 22.1613,
			"c": 22.1564,
			"h": 22.162,
			"l": 22.1546,
			"t": 1344610620000,
			"n": 58
		  },
		  {
			"v": 213136,
			"vw": 22.1567,
			"o": 22.1564,
			"c": 22.1607,
			"h": 22.1607,
			"l": 22.1536,
			"t": 1344610680000,
			"n": 49
		  },
		  {
			"v": 193760,
			"vw": 22.1613,
			"o": 22.1607,
			"c": 22.1625,
			"h": 22.1654,
			"l": 22.1568,
			"t": 1344610740000,
			"n": 56
		  },
		  {
			"v": 513856,
			"vw": 22.1649,
			"o": 22.1657,
			"c": 22.1546,
			"h": 22.1711,
			"l": 22.1546,
			"t": 1344610800000,
			"n": 137
		  },
		  {
			"v": 224896,
			"vw": 22.1598,
			"o": 22.1575,
			"c": 22.1607,
			"h": 22.1618,
			"l": 22.1571,
			"t": 1344610860000,
			"n": 62
		  },
		  {
			"v": 134008,
			"vw": 22.1617,
			"o": 22.1607,
			"c": 22.1604,
			"h": 22.1664,
			"l": 22.1589,
			"t": 1344610920000,
			"n": 27
		  },
		  {
			"v": 318304,
			"vw": 22.1557,
			"o": 22.1637,
			"c": 22.1493,
			"h": 22.1637,
			"l": 22.1493,
			"t": 1344610980000,
			"n": 78
		  },
		  {
			"v": 1061872,
			"vw": 22.1451,
			"o": 22.1529,
			"c": 22.1383,
			"h": 22.1546,
			"l": 22.1383,
			"t": 1344611040000,
			"n": 244
		  },
		  {
			"v": 928704,
			"vw": 22.1375,
			"o": 22.1421,
			"c": 22.1382,
			"h": 22.1454,
			"l": 22.1311,
			"t": 1344611100000,
			"n": 242
		  },
		  {
			"v": 670992,
			"vw": 22.1362,
			"o": 22.1375,
			"c": 22.1386,
			"h": 22.1418,
			"l": 22.1286,
			"t": 1344611160000,
			"n": 163
		  },
		  {
			"v": 669480,
			"vw": 22.1392,
			"o": 22.1393,
			"c": 22.1468,
			"h": 22.1468,
			"l": 22.1321,
			"t": 1344611220000,
			"n": 176
		  },
		  {
			"v": 357392,
			"vw": 22.1446,
			"o": 22.1463,
			"c": 22.1443,
			"h": 22.1463,
			"l": 22.1414,
			"t": 1344611280000,
			"n": 64
		  },
		  {
			"v": 415016,
			"vw": 22.1436,
			"o": 22.1432,
			"c": 22.1436,
			"h": 22.1489,
			"l": 22.1382,
			"t": 1344611340000,
			"n": 89
		  },
		  {
			"v": 130536,
			"vw": 22.1443,
			"o": 22.1404,
			"c": 22.1443,
			"h": 22.1475,
			"l": 22.1389,
			"t": 1344611400000,
			"n": 30
		  },
		  {
			"v": 432712,
			"vw": 22.1449,
			"o": 22.1482,
			"c": 22.1439,
			"h": 22.1489,
			"l": 22.1404,
			"t": 1344611460000,
			"n": 109
		  },
		  {
			"v": 413392,
			"vw": 22.1426,
			"o": 22.1418,
			"c": 22.1429,
			"h": 22.1482,
			"l": 22.1375,
			"t": 1344611520000,
			"n": 103
		  },
		  {
			"v": 621180,
			"vw": 22.1407,
			"o": 22.1457,
			"c": 22.1479,
			"h": 22.1496,
			"l": 22.14,
			"t": 1344611580000,
			"n": 76
		  },
		  {
			"v": 413672,
			"vw": 22.1444,
			"o": 22.1471,
			"c": 22.1479,
			"h": 22.1482,
			"l": 22.14,
			"t": 1344611640000,
			"n": 94
		  },
		  {
			"v": 174720,
			"vw": 22.1462,
			"o": 22.1457,
			"c": 22.1488,
			"h": 22.1488,
			"l": 22.1422,
			"t": 1344611700000,
			"n": 31
		  },
		  {
			"v": 293580,
			"vw": 22.1464,
			"o": 22.1482,
			"c": 22.1461,
			"h": 22.1482,
			"l": 22.144,
			"t": 1344611760000,
			"n": 65
		  },
		  {
			"v": 211680,
			"vw": 22.1471,
			"o": 22.1439,
			"c": 22.1443,
			"h": 22.15,
			"l": 22.1429,
			"t": 1344611820000,
			"n": 49
		  },
		  {
			"v": 409192,
			"vw": 22.1489,
			"o": 22.1482,
			"c": 22.1464,
			"h": 22.1529,
			"l": 22.1454,
			"t": 1344611880000,
			"n": 83
		  },
		  {
			"v": 153272,
			"vw": 22.1462,
			"o": 22.1468,
			"c": 22.1436,
			"h": 22.1517,
			"l": 22.1429,
			"t": 1344611940000,
			"n": 36
		  },
		  {
			"v": 220444,
			"vw": 22.1489,
			"o": 22.1448,
			"c": 22.1461,
			"h": 22.1514,
			"l": 22.1439,
			"t": 1344612000000,
			"n": 56
		  },
		  {
			"v": 121464,
			"vw": 22.1495,
			"o": 22.1496,
			"c": 22.1446,
			"h": 22.1525,
			"l": 22.1446,
			"t": 1344612060000,
			"n": 33
		  },
		  {
			"v": 46704,
			"vw": 22.1483,
			"o": 22.1479,
			"c": 22.1493,
			"h": 22.1507,
			"l": 22.1461,
			"t": 1344612120000,
			"n": 13
		  },
		  {
			"v": 194040,
			"vw": 22.1473,
			"o": 22.1493,
			"c": 22.1461,
			"h": 22.1507,
			"l": 22.1439,
			"t": 1344612180000,
			"n": 45
		  },
		  {
			"v": 290696,
			"vw": 22.1414,
			"o": 22.1475,
			"c": 22.1396,
			"h": 22.1475,
			"l": 22.1393,
			"t": 1344612240000,
			"n": 56
		  },
		  {
			"v": 251188,
			"vw": 22.1429,
			"o": 22.1396,
			"c": 22.1469,
			"h": 22.1479,
			"l": 22.1396,
			"t": 1344612300000,
			"n": 74
		  },
		  {
			"v": 793856,
			"vw": 22.1558,
			"o": 22.1465,
			"c": 22.1586,
			"h": 22.1621,
			"l": 22.1465,
			"t": 1344612360000,
			"n": 220
		  },
		  {
			"v": 255696,
			"vw": 22.159,
			"o": 22.1621,
			"c": 22.1596,
			"h": 22.1625,
			"l": 22.1564,
			"t": 1344612420000,
			"n": 42
		  },
		  {
			"v": 89320,
			"vw": 22.1586,
			"o": 22.1571,
			"c": 22.1599,
			"h": 22.16,
			"l": 22.1561,
			"t": 1344612480000,
			"n": 23
		  },
		  {
			"v": 284872,
			"vw": 22.1581,
			"o": 22.1596,
			"c": 22.1589,
			"h": 22.1618,
			"l": 22.1561,
			"t": 1344612540000,
			"n": 69
		  },
		  {
			"v": 296744,
			"vw": 22.1597,
			"o": 22.1589,
			"c": 22.1621,
			"h": 22.1632,
			"l": 22.1561,
			"t": 1344612600000,
			"n": 66
		  },
		  {
			"v": 168364,
			"vw": 22.158,
			"o": 22.1607,
			"c": 22.1554,
			"h": 22.1614,
			"l": 22.1554,
			"t": 1344612660000,
			"n": 39
		  },
		  {
			"v": 255388,
			"vw": 22.1535,
			"o": 22.155,
			"c": 22.1536,
			"h": 22.1607,
			"l": 22.1464,
			"t": 1344612720000,
			"n": 71
		  },
		  {
			"v": 167860,
			"vw": 22.1582,
			"o": 22.1554,
			"c": 22.1541,
			"h": 22.1621,
			"l": 22.1541,
			"t": 1344612780000,
			"n": 31
		  },
		  {
			"v": 104580,
			"vw": 22.1531,
			"o": 22.1539,
			"c": 22.1507,
			"h": 22.1589,
			"l": 22.1504,
			"t": 1344612840000,
			"n": 28
		  },
		  {
			"v": 162008,
			"vw": 22.1501,
			"o": 22.1479,
			"c": 22.155,
			"h": 22.1579,
			"l": 22.1475,
			"t": 1344612900000,
			"n": 44
		  },
		  {
			"v": 128464,
			"vw": 22.154,
			"o": 22.1554,
			"c": 22.155,
			"h": 22.1574,
			"l": 22.15,
			"t": 1344612960000,
			"n": 32
		  },
		  {
			"v": 76524,
			"vw": 22.1532,
			"o": 22.1546,
			"c": 22.1498,
			"h": 22.155,
			"l": 22.1498,
			"t": 1344613020000,
			"n": 18
		  },
		  {
			"v": 60480,
			"vw": 22.1506,
			"o": 22.1486,
			"c": 22.1521,
			"h": 22.1521,
			"l": 22.1486,
			"t": 1344613080000,
			"n": 18
		  },
		  {
			"v": 65324,
			"vw": 22.1496,
			"o": 22.1519,
			"c": 22.1493,
			"h": 22.1532,
			"l": 22.1468,
			"t": 1344613140000,
			"n": 17
		  },
		  {
			"v": 56980,
			"vw": 22.1536,
			"o": 22.1504,
			"c": 22.152,
			"h": 22.1561,
			"l": 22.1504,
			"t": 1344613200000,
			"n": 15
		  },
		  {
			"v": 33320,
			"vw": 22.1502,
			"o": 22.1511,
			"c": 22.1518,
			"h": 22.1521,
			"l": 22.1489,
			"t": 1344613260000,
			"n": 10
		  },
		  {
			"v": 92400,
			"vw": 22.1518,
			"o": 22.1494,
			"c": 22.1518,
			"h": 22.1543,
			"l": 22.1486,
			"t": 1344613320000,
			"n": 25
		  },
		  {
			"v": 110404,
			"vw": 22.1528,
			"o": 22.1518,
			"c": 22.1518,
			"h": 22.1543,
			"l": 22.1508,
			"t": 1344613380000,
			"n": 24
		  },
		  {
			"v": 398440,
			"vw": 22.1475,
			"o": 22.1521,
			"c": 22.1436,
			"h": 22.1529,
			"l": 22.1429,
			"t": 1344613440000,
			"n": 75
		  },
		  {
			"v": 147728,
			"vw": 22.145,
			"o": 22.1429,
			"c": 22.1436,
			"h": 22.1499,
			"l": 22.1429,
			"t": 1344613500000,
			"n": 38
		  },
		  {
			"v": 198520,
			"vw": 22.1492,
			"o": 22.1446,
			"c": 22.1482,
			"h": 22.15,
			"l": 22.1446,
			"t": 1344613560000,
			"n": 37
		  },
		  {
			"v": 343056,
			"vw": 22.1451,
			"o": 22.1477,
			"c": 22.1482,
			"h": 22.1493,
			"l": 22.1404,
			"t": 1344613620000,
			"n": 75
		  },
		  {
			"v": 141148,
			"vw": 22.1467,
			"o": 22.1482,
			"c": 22.1479,
			"h": 22.1486,
			"l": 22.1439,
			"t": 1344613680000,
			"n": 40
		  },
		  {
			"v": 179004,
			"vw": 22.1477,
			"o": 22.1485,
			"c": 22.1471,
			"h": 22.1485,
			"l": 22.1454,
			"t": 1344613740000,
			"n": 26
		  },
		  {
			"v": 354200,
			"vw": 22.1417,
			"o": 22.145,
			"c": 22.145,
			"h": 22.145,
			"l": 22.1389,
			"t": 1344613800000,
			"n": 87
		  },
		  {
			"v": 432992,
			"vw": 22.1427,
			"o": 22.1461,
			"c": 22.1382,
			"h": 22.15,
			"l": 22.1364,
			"t": 1344613860000,
			"n": 104
		  },
		  {
			"v": 258272,
			"vw": 22.1405,
			"o": 22.1393,
			"c": 22.1407,
			"h": 22.1425,
			"l": 22.1375,
			"t": 1344613920000,
			"n": 60
		  },
		  {
			"v": 120848,
			"vw": 22.1381,
			"o": 22.1389,
			"c": 22.1385,
			"h": 22.1411,
			"l": 22.1368,
			"t": 1344613980000,
			"n": 35
		  },
		  {
			"v": 435428,
			"vw": 22.1385,
			"o": 22.1396,
			"c": 22.1425,
			"h": 22.1425,
			"l": 22.1343,
			"t": 1344614040000,
			"n": 113
		  },
		  {
			"v": 176484,
			"vw": 22.1425,
			"o": 22.1425,
			"c": 22.1407,
			"h": 22.1439,
			"l": 22.1393,
			"t": 1344614100000,
			"n": 41
		  },
		  {
			"v": 409584,
			"vw": 22.146,
			"o": 22.1432,
			"c": 22.1464,
			"h": 22.1514,
			"l": 22.1382,
			"t": 1344614160000,
			"n": 99
		  },
		  {
			"v": 390992,
			"vw": 22.1461,
			"o": 22.1471,
			"c": 22.1482,
			"h": 22.1482,
			"l": 22.1414,
			"t": 1344614220000,
			"n": 74
		  },
		  {
			"v": 238980,
			"vw": 22.1492,
			"o": 22.1474,
			"c": 22.1471,
			"h": 22.1511,
			"l": 22.1468,
			"t": 1344614280000,
			"n": 37
		  },
		  {
			"v": 93100,
			"vw": 22.1474,
			"o": 22.1491,
			"c": 22.1504,
			"h": 22.1514,
			"l": 22.1457,
			"t": 1344614340000,
			"n": 26
		  },
		  {
			"v": 109508,
			"vw": 22.1495,
			"o": 22.1504,
			"c": 22.1461,
			"h": 22.1507,
			"l": 22.1461,
			"t": 1344614400000,
			"n": 23
		  },
		  {
			"v": 315924,
			"vw": 22.1492,
			"o": 22.1493,
			"c": 22.1479,
			"h": 22.1525,
			"l": 22.145,
			"t": 1344614460000,
			"n": 59
		  },
		  {
			"v": 28000,
			"vw": 22.1479,
			"o": 22.1471,
			"c": 22.1475,
			"h": 22.1496,
			"l": 22.1464,
			"t": 1344614520000,
			"n": 9
		  },
		  {
			"v": 213892,
			"vw": 22.148,
			"o": 22.1464,
			"c": 22.1489,
			"h": 22.1514,
			"l": 22.1443,
			"t": 1344614580000,
			"n": 38
		  },
		  {
			"v": 74200,
			"vw": 22.1455,
			"o": 22.1443,
			"c": 22.1454,
			"h": 22.1464,
			"l": 22.1443,
			"t": 1344614640000,
			"n": 21
		  },
		  {
			"v": 92400,
			"vw": 22.1452,
			"o": 22.1451,
			"c": 22.1436,
			"h": 22.1471,
			"l": 22.1432,
			"t": 1344614700000,
			"n": 17
		  },
		  {
			"v": 213836,
			"vw": 22.1433,
			"o": 22.1464,
			"c": 22.1396,
			"h": 22.1464,
			"l": 22.1393,
			"t": 1344614760000,
			"n": 48
		  },
		  {
			"v": 256928,
			"vw": 22.1421,
			"o": 22.1402,
			"c": 22.1396,
			"h": 22.1446,
			"l": 22.1396,
			"t": 1344614820000,
			"n": 62
		  },
		  {
			"v": 201152,
			"vw": 22.1401,
			"o": 22.14,
			"c": 22.1407,
			"h": 22.1439,
			"l": 22.1386,
			"t": 1344614880000,
			"n": 58
		  },
		  {
			"v": 199752,
			"vw": 22.1405,
			"o": 22.1382,
			"c": 22.142,
			"h": 22.1443,
			"l": 22.1375,
			"t": 1344614940000,
			"n": 45
		  },
		  {
			"v": 461608,
			"vw": 22.142,
			"o": 22.1429,
			"c": 22.1439,
			"h": 22.145,
			"l": 22.1411,
			"t": 1344615000000,
			"n": 79
		  },
		  {
			"v": 102900,
			"vw": 22.1405,
			"o": 22.1443,
			"c": 22.1393,
			"h": 22.1443,
			"l": 22.1393,
			"t": 1344615060000,
			"n": 18
		  },
		  {
			"v": 74144,
			"vw": 22.1386,
			"o": 22.1393,
			"c": 22.1407,
			"h": 22.1414,
			"l": 22.1375,
			"t": 1344615120000,
			"n": 21
		  },
		  {
			"v": 180264,
			"vw": 22.1411,
			"o": 22.1407,
			"c": 22.1379,
			"h": 22.1429,
			"l": 22.1379,
			"t": 1344615180000,
			"n": 31
		  },
		  {
			"v": 122612,
			"vw": 22.1385,
			"o": 22.1404,
			"c": 22.1408,
			"h": 22.1408,
			"l": 22.1375,
			"t": 1344615240000,
			"n": 31
		  },
		  {
			"v": 952280,
			"vw": 22.1341,
			"o": 22.1414,
			"c": 22.1297,
			"h": 22.1418,
			"l": 22.1286,
			"t": 1344615300000,
			"n": 229
		  },
		  {
			"v": 117600,
			"vw": 22.1321,
			"o": 22.13,
			"c": 22.1329,
			"h": 22.1354,
			"l": 22.1296,
			"t": 1344615360000,
			"n": 38
		  },
		  {
			"v": 262640,
			"vw": 22.1344,
			"o": 22.1338,
			"c": 22.1368,
			"h": 22.1368,
			"l": 22.1313,
			"t": 1344615420000,
			"n": 80
		  },
		  {
			"v": 226184,
			"vw": 22.1308,
			"o": 22.1368,
			"c": 22.1276,
			"h": 22.1368,
			"l": 22.1275,
			"t": 1344615480000,
			"n": 58
		  },
		  {
			"v": 255780,
			"vw": 22.1293,
			"o": 22.1286,
			"c": 22.1286,
			"h": 22.1329,
			"l": 22.1286,
			"t": 1344615540000,
			"n": 72
		  },
		  {
			"v": 125440,
			"vw": 22.1294,
			"o": 22.1286,
			"c": 22.1289,
			"h": 22.1318,
			"l": 22.1278,
			"t": 1344615600000,
			"n": 35
		  },
		  {
			"v": 176232,
			"vw": 22.1314,
			"o": 22.1289,
			"c": 22.1339,
			"h": 22.1339,
			"l": 22.1286,
			"t": 1344615660000,
			"n": 45
		  },
		  {
			"v": 228060,
			"vw": 22.1327,
			"o": 22.1339,
			"c": 22.1303,
			"h": 22.1339,
			"l": 22.13,
			"t": 1344615720000,
			"n": 28
		  },
		  {
			"v": 325752,
			"vw": 22.1335,
			"o": 22.1296,
			"c": 22.1353,
			"h": 22.1353,
			"l": 22.1296,
			"t": 1344615780000,
			"n": 58
		  },
		  {
			"v": 142800,
			"vw": 22.135,
			"o": 22.1339,
			"c": 22.1325,
			"h": 22.1359,
			"l": 22.1314,
			"t": 1344615840000,
			"n": 28
		  },
		  {
			"v": 443100,
			"vw": 22.1335,
			"o": 22.1336,
			"c": 22.1339,
			"h": 22.135,
			"l": 22.1318,
			"t": 1344615900000,
			"n": 96
		  },
		  {
			"v": 394212,
			"vw": 22.132,
			"o": 22.1339,
			"c": 22.1304,
			"h": 22.1343,
			"l": 22.1282,
			"t": 1344615960000,
			"n": 98
		  },
		  {
			"v": 567028,
			"vw": 22.1296,
			"o": 22.1311,
			"c": 22.1325,
			"h": 22.1343,
			"l": 22.1271,
			"t": 1344616020000,
			"n": 119
		  },
		  {
			"v": 422380,
			"vw": 22.1335,
			"o": 22.134,
			"c": 22.1329,
			"h": 22.1357,
			"l": 22.1304,
			"t": 1344616080000,
			"n": 95
		  },
		  {
			"v": 176848,
			"vw": 22.1334,
			"o": 22.1332,
			"c": 22.1339,
			"h": 22.1339,
			"l": 22.1316,
			"t": 1344616140000,
			"n": 44
		  },
		  {
			"v": 686252,
			"vw": 22.1367,
			"o": 22.1339,
			"c": 22.1402,
			"h": 22.1411,
			"l": 22.1339,
			"t": 1344616200000,
			"n": 148
		  },
		  {
			"v": 132132,
			"vw": 22.1385,
			"o": 22.1398,
			"c": 22.1361,
			"h": 22.1407,
			"l": 22.1361,
			"t": 1344616260000,
			"n": 28
		  },
		  {
			"v": 342104,
			"vw": 22.1371,
			"o": 22.1361,
			"c": 22.14,
			"h": 22.1407,
			"l": 22.1336,
			"t": 1344616320000,
			"n": 67
		  },
		  {
			"v": 2640316,
			"vw": 22.1343,
			"o": 22.14,
			"c": 22.1386,
			"h": 22.14,
			"l": 22.1354,
			"t": 1344616380000,
			"n": 68
		  },
		  {
			"v": 635376,
			"vw": 22.1376,
			"o": 22.1386,
			"c": 22.1407,
			"h": 22.1407,
			"l": 22.1343,
			"t": 1344616440000,
			"n": 137
		  },
		  {
			"v": 407708,
			"vw": 22.1395,
			"o": 22.1382,
			"c": 22.1386,
			"h": 22.1421,
			"l": 22.1369,
			"t": 1344616500000,
			"n": 101
		  },
		  {
			"v": 299264,
			"vw": 22.1369,
			"o": 22.1393,
			"c": 22.1339,
			"h": 22.1393,
			"l": 22.1339,
			"t": 1344616560000,
			"n": 80
		  },
		  {
			"v": 136164,
			"vw": 22.1361,
			"o": 22.1357,
			"c": 22.1364,
			"h": 22.1364,
			"l": 22.1344,
			"t": 1344616620000,
			"n": 38
		  },
		  {
			"v": 158844,
			"vw": 22.1387,
			"o": 22.1364,
			"c": 22.1389,
			"h": 22.1393,
			"l": 22.1361,
			"t": 1344616680000,
			"n": 31
		  },
		  {
			"v": 98980,
			"vw": 22.1369,
			"o": 22.1371,
			"c": 22.1357,
			"h": 22.1382,
			"l": 22.1357,
			"t": 1344616740000,
			"n": 30
		  },
		  {
			"v": 28000,
			"vw": 22.1375,
			"o": 22.1381,
			"c": 22.1382,
			"h": 22.1382,
			"l": 22.1354,
			"t": 1344616800000,
			"n": 8
		  },
		  {
			"v": 126672,
			"vw": 22.1387,
			"o": 22.1375,
			"c": 22.1393,
			"h": 22.14,
			"l": 22.1361,
			"t": 1344616860000,
			"n": 33
		  },
		  {
			"v": 555856,
			"vw": 22.1385,
			"o": 22.1393,
			"c": 22.1386,
			"h": 22.1429,
			"l": 22.135,
			"t": 1344616920000,
			"n": 124
		  },
		  {
			"v": 142156,
			"vw": 22.1405,
			"o": 22.1375,
			"c": 22.1386,
			"h": 22.1425,
			"l": 22.1375,
			"t": 1344616980000,
			"n": 44
		  },
		  {
			"v": 149828,
			"vw": 22.1389,
			"o": 22.1392,
			"c": 22.1374,
			"h": 22.1406,
			"l": 22.1368,
			"t": 1344617040000,
			"n": 38
		  },
		  {
			"v": 152320,
			"vw": 22.1375,
			"o": 22.1393,
			"c": 22.1354,
			"h": 22.1407,
			"l": 22.135,
			"t": 1344617100000,
			"n": 36
		  },
		  {
			"v": 206556,
			"vw": 22.1353,
			"o": 22.1375,
			"c": 22.135,
			"h": 22.1379,
			"l": 22.1329,
			"t": 1344617160000,
			"n": 60
		  },
		  {
			"v": 313600,
			"vw": 22.1372,
			"o": 22.1339,
			"c": 22.1361,
			"h": 22.1389,
			"l": 22.1339,
			"t": 1344617220000,
			"n": 45
		  },
		  {
			"v": 161364,
			"vw": 22.1377,
			"o": 22.1364,
			"c": 22.1379,
			"h": 22.1407,
			"l": 22.1338,
			"t": 1344617280000,
			"n": 53
		  },
		  {
			"v": 339780,
			"vw": 22.1386,
			"o": 22.1379,
			"c": 22.1389,
			"h": 22.14,
			"l": 22.1357,
			"t": 1344617340000,
			"n": 71
		  },
		  {
			"v": 315868,
			"vw": 22.1393,
			"o": 22.1389,
			"c": 22.1407,
			"h": 22.1407,
			"l": 22.1389,
			"t": 1344617400000,
			"n": 71
		  },
		  {
			"v": 294616,
			"vw": 22.1393,
			"o": 22.1393,
			"c": 22.14,
			"h": 22.1407,
			"l": 22.1389,
			"t": 1344617460000,
			"n": 57
		  },
		  {
			"v": 451864,
			"vw": 22.1389,
			"o": 22.1393,
			"c": 22.1379,
			"h": 22.1396,
			"l": 22.1375,
			"t": 1344617520000,
			"n": 80
		  },
		  {
			"v": 99232,
			"vw": 22.1384,
			"o": 22.1392,
			"c": 22.1375,
			"h": 22.1393,
			"l": 22.1375,
			"t": 1344617580000,
			"n": 17
		  },
		  {
			"v": 120456,
			"vw": 22.1378,
			"o": 22.1371,
			"c": 22.1393,
			"h": 22.1393,
			"l": 22.1364,
			"t": 1344617640000,
			"n": 26
		  },
		  {
			"v": 70000,
			"vw": 22.1374,
			"o": 22.1386,
			"c": 22.1379,
			"h": 22.1389,
			"l": 22.1361,
			"t": 1344617700000,
			"n": 22
		  },
		  {
			"v": 397208,
			"vw": 22.1423,
			"o": 22.1389,
			"c": 22.1418,
			"h": 22.1461,
			"l": 22.1389,
			"t": 1344617760000,
			"n": 78
		  },
		  {
			"v": 354004,
			"vw": 22.1477,
			"o": 22.1425,
			"c": 22.1496,
			"h": 22.1514,
			"l": 22.1425,
			"t": 1344617820000,
			"n": 82
		  },
		  {
			"v": 300804,
			"vw": 22.1522,
			"o": 22.1505,
			"c": 22.1539,
			"h": 22.1546,
			"l": 22.1496,
			"t": 1344617880000,
			"n": 67
		  },
		  {
			"v": 202328,
			"vw": 22.1498,
			"o": 22.1536,
			"c": 22.1471,
			"h": 22.1536,
			"l": 22.1457,
			"t": 1344617940000,
			"n": 54
		  },
		  {
			"v": 84000,
			"vw": 22.1524,
			"o": 22.1511,
			"c": 22.1525,
			"h": 22.1539,
			"l": 22.1496,
			"t": 1344618000000,
			"n": 20
		  },
		  {
			"v": 137984,
			"vw": 22.1528,
			"o": 22.1529,
			"c": 22.1504,
			"h": 22.1547,
			"l": 22.15,
			"t": 1344618060000,
			"n": 33
		  },
		  {
			"v": 143948,
			"vw": 22.1504,
			"o": 22.1511,
			"c": 22.1518,
			"h": 22.1525,
			"l": 22.1482,
			"t": 1344618120000,
			"n": 40
		  },
		  {
			"v": 220696,
			"vw": 22.1519,
			"o": 22.1519,
			"c": 22.1525,
			"h": 22.1532,
			"l": 22.1511,
			"t": 1344618180000,
			"n": 37
		  },
		  {
			"v": 117068,
			"vw": 22.1493,
			"o": 22.1532,
			"c": 22.15,
			"h": 22.1532,
			"l": 22.1486,
			"t": 1344618240000,
			"n": 35
		  },
		  {
			"v": 636272,
			"vw": 22.1477,
			"o": 22.1489,
			"c": 22.1436,
			"h": 22.15,
			"l": 22.1432,
			"t": 1344618300000,
			"n": 90
		  },
		  {
			"v": 205100,
			"vw": 22.1411,
			"o": 22.1433,
			"c": 22.1421,
			"h": 22.1443,
			"l": 22.1386,
			"t": 1344618360000,
			"n": 63
		  },
		  {
			"v": 297192,
			"vw": 22.1401,
			"o": 22.1404,
			"c": 22.1432,
			"h": 22.1436,
			"l": 22.1375,
			"t": 1344618420000,
			"n": 77
		  },
		  {
			"v": 162036,
			"vw": 22.1408,
			"o": 22.1432,
			"c": 22.1375,
			"h": 22.1432,
			"l": 22.1375,
			"t": 1344618480000,
			"n": 49
		  },
		  {
			"v": 273784,
			"vw": 22.1411,
			"o": 22.1391,
			"c": 22.1407,
			"h": 22.1439,
			"l": 22.1375,
			"t": 1344618540000,
			"n": 59
		  },
		  {
			"v": 362208,
			"vw": 22.1381,
			"o": 22.1425,
			"c": 22.1361,
			"h": 22.1425,
			"l": 22.1346,
			"t": 1344618600000,
			"n": 61
		  },
		  {
			"v": 431116,
			"vw": 22.1318,
			"o": 22.1361,
			"c": 22.1296,
			"h": 22.1371,
			"l": 22.1286,
			"t": 1344618660000,
			"n": 106
		  },
		  {
			"v": 298256,
			"vw": 22.1306,
			"o": 22.1314,
			"c": 22.13,
			"h": 22.1329,
			"l": 22.1293,
			"t": 1344618720000,
			"n": 82
		  },
		  {
			"v": 87332,
			"vw": 22.1326,
			"o": 22.1307,
			"c": 22.1318,
			"h": 22.1339,
			"l": 22.1307,
			"t": 1344618780000,
			"n": 23
		  },
		  {
			"v": 170548,
			"vw": 22.1309,
			"o": 22.1321,
			"c": 22.1328,
			"h": 22.1332,
			"l": 22.1296,
			"t": 1344618840000,
			"n": 33
		  },
		  {
			"v": 241108,
			"vw": 22.1322,
			"o": 22.1318,
			"c": 22.1296,
			"h": 22.1339,
			"l": 22.1296,
			"t": 1344618900000,
			"n": 45
		  },
		  {
			"v": 223608,
			"vw": 22.1316,
			"o": 22.13,
			"c": 22.1325,
			"h": 22.1339,
			"l": 22.1293,
			"t": 1344618960000,
			"n": 57
		  },
		  {
			"v": 162540,
			"vw": 22.1323,
			"o": 22.1318,
			"c": 22.1314,
			"h": 22.1339,
			"l": 22.1296,
			"t": 1344619020000,
			"n": 37
		  },
		  {
			"v": 229824,
			"vw": 22.1308,
			"o": 22.1307,
			"c": 22.1296,
			"h": 22.1333,
			"l": 22.1296,
			"t": 1344619080000,
			"n": 49
		  },
		  {
			"v": 276668,
			"vw": 22.1298,
			"o": 22.1293,
			"c": 22.1314,
			"h": 22.1314,
			"l": 22.1286,
			"t": 1344619140000,
			"n": 72
		  },
		  {
			"v": 396676,
			"vw": 22.1318,
			"o": 22.1314,
			"c": 22.1361,
			"h": 22.1364,
			"l": 22.1304,
			"t": 1344619200000,
			"n": 30
		  },
		  {
			"v": 525252,
			"vw": 22.1332,
			"o": 22.1339,
			"c": 22.1371,
			"h": 22.1393,
			"l": 22.1339,
			"t": 1344619260000,
			"n": 47
		  },
		  {
			"v": 109536,
			"vw": 22.1372,
			"o": 22.1382,
			"c": 22.1379,
			"h": 22.1382,
			"l": 22.1364,
			"t": 1344619320000,
			"n": 27
		  },
		  {
			"v": 140224,
			"vw": 22.1391,
			"o": 22.1375,
			"c": 22.14,
			"h": 22.1411,
			"l": 22.1368,
			"t": 1344619380000,
			"n": 33
		  },
		  {
			"v": 314328,
			"vw": 22.141,
			"o": 22.1382,
			"c": 22.1396,
			"h": 22.1429,
			"l": 22.1382,
			"t": 1344619440000,
			"n": 49
		  },
		  {
			"v": 135380,
			"vw": 22.1408,
			"o": 22.1393,
			"c": 22.1386,
			"h": 22.1425,
			"l": 22.1379,
			"t": 1344619500000,
			"n": 33
		  },
		  {
			"v": 968016,
			"vw": 22.1297,
			"o": 22.1386,
			"c": 22.1397,
			"h": 22.1404,
			"l": 22.1375,
			"t": 1344619560000,
			"n": 40
		  },
		  {
			"v": 243628,
			"vw": 22.14,
			"o": 22.1407,
			"c": 22.14,
			"h": 22.1407,
			"l": 22.1386,
			"t": 1344619620000,
			"n": 35
		  },
		  {
			"v": 470988,
			"vw": 22.1418,
			"o": 22.1407,
			"c": 22.1418,
			"h": 22.1429,
			"l": 22.1396,
			"t": 1344619680000,
			"n": 103
		  },
		  {
			"v": 168616,
			"vw": 22.1419,
			"o": 22.1414,
			"c": 22.1419,
			"h": 22.1436,
			"l": 22.1411,
			"t": 1344619740000,
			"n": 36
		  },
		  {
			"v": 124544,
			"vw": 22.1429,
			"o": 22.1415,
			"c": 22.1429,
			"h": 22.1454,
			"l": 22.14,
			"t": 1344619800000,
			"n": 34
		  },
		  {
			"v": 137480,
			"vw": 22.1429,
			"o": 22.1425,
			"c": 22.1429,
			"h": 22.1443,
			"l": 22.1425,
			"t": 1344619860000,
			"n": 29
		  },
		  {
			"v": 173684,
			"vw": 22.1438,
			"o": 22.1425,
			"c": 22.1464,
			"h": 22.1468,
			"l": 22.1411,
			"t": 1344619920000,
			"n": 43
		  },
		  {
			"v": 131824,
			"vw": 22.1433,
			"o": 22.1446,
			"c": 22.1421,
			"h": 22.1456,
			"l": 22.1421,
			"t": 1344619980000,
			"n": 24
		  },
		  {
			"v": 120120,
			"vw": 22.14,
			"o": 22.1421,
			"c": 22.1416,
			"h": 22.1432,
			"l": 22.1368,
			"t": 1344620040000,
			"n": 35
		  },
		  {
			"v": 198716,
			"vw": 22.1437,
			"o": 22.1386,
			"c": 22.1445,
			"h": 22.1461,
			"l": 22.1386,
			"t": 1344620100000,
			"n": 52
		  },
		  {
			"v": 135212,
			"vw": 22.1462,
			"o": 22.1446,
			"c": 22.1464,
			"h": 22.1471,
			"l": 22.1432,
			"t": 1344620160000,
			"n": 32
		  },
		  {
			"v": 106764,
			"vw": 22.145,
			"o": 22.1464,
			"c": 22.1429,
			"h": 22.1471,
			"l": 22.1418,
			"t": 1344620220000,
			"n": 32
		  },
		  {
			"v": 53200,
			"vw": 22.1421,
			"o": 22.1432,
			"c": 22.1443,
			"h": 22.1443,
			"l": 22.1414,
			"t": 1344620280000,
			"n": 19
		  },
		  {
			"v": 212044,
			"vw": 22.1446,
			"o": 22.1439,
			"c": 22.1446,
			"h": 22.1464,
			"l": 22.1421,
			"t": 1344620340000,
			"n": 52
		  },
		  {
			"v": 107240,
			"vw": 22.1441,
			"o": 22.1461,
			"c": 22.1429,
			"h": 22.1464,
			"l": 22.1429,
			"t": 1344620400000,
			"n": 27
		  },
		  {
			"v": 368760,
			"vw": 22.1471,
			"o": 22.1443,
			"c": 22.1479,
			"h": 22.15,
			"l": 22.1443,
			"t": 1344620460000,
			"n": 79
		  },
		  {
			"v": 884940,
			"vw": 22.1471,
			"o": 22.1464,
			"c": 22.1468,
			"h": 22.15,
			"l": 22.1421,
			"t": 1344620520000,
			"n": 210
		  },
		  {
			"v": 408912,
			"vw": 22.1498,
			"o": 22.1477,
			"c": 22.1511,
			"h": 22.1518,
			"l": 22.1468,
			"t": 1344620580000,
			"n": 102
		  },
		  {
			"v": 327488,
			"vw": 22.1496,
			"o": 22.1493,
			"c": 22.1499,
			"h": 22.1511,
			"l": 22.1471,
			"t": 1344620640000,
			"n": 82
		  },
		  {
			"v": 140532,
			"vw": 22.1493,
			"o": 22.15,
			"c": 22.1482,
			"h": 22.1504,
			"l": 22.1471,
			"t": 1344620700000,
			"n": 47
		  },
		  {
			"v": 115752,
			"vw": 22.1487,
			"o": 22.1492,
			"c": 22.1479,
			"h": 22.15,
			"l": 22.1475,
			"t": 1344620760000,
			"n": 28
		  },
		  {
			"v": 132188,
			"vw": 22.1484,
			"o": 22.1475,
			"c": 22.15,
			"h": 22.15,
			"l": 22.1475,
			"t": 1344620820000,
			"n": 31
		  },
		  {
			"v": 183120,
			"vw": 22.1483,
			"o": 22.15,
			"c": 22.15,
			"h": 22.1504,
			"l": 22.1461,
			"t": 1344620880000,
			"n": 40
		  },
		  {
			"v": 300076,
			"vw": 22.147,
			"o": 22.1487,
			"c": 22.1482,
			"h": 22.15,
			"l": 22.1446,
			"t": 1344620940000,
			"n": 73
		  },
		  {
			"v": 402696,
			"vw": 22.1474,
			"o": 22.1465,
			"c": 22.1475,
			"h": 22.15,
			"l": 22.1454,
			"t": 1344621000000,
			"n": 84
		  },
		  {
			"v": 343336,
			"vw": 22.1452,
			"o": 22.1482,
			"c": 22.1457,
			"h": 22.1496,
			"l": 22.1429,
			"t": 1344621060000,
			"n": 60
		  },
		  {
			"v": 162932,
			"vw": 22.1428,
			"o": 22.1436,
			"c": 22.1443,
			"h": 22.1443,
			"l": 22.1411,
			"t": 1344621120000,
			"n": 36
		  },
		  {
			"v": 126308,
			"vw": 22.1443,
			"o": 22.145,
			"c": 22.1429,
			"h": 22.1464,
			"l": 22.1429,
			"t": 1344621180000,
			"n": 34
		  },
		  {
			"v": 126924,
			"vw": 22.1446,
			"o": 22.1429,
			"c": 22.1464,
			"h": 22.1468,
			"l": 22.1429,
			"t": 1344621240000,
			"n": 38
		  },
		  {
			"v": 109676,
			"vw": 22.1451,
			"o": 22.1468,
			"c": 22.1432,
			"h": 22.1468,
			"l": 22.1418,
			"t": 1344621300000,
			"n": 26
		  },
		  {
			"v": 90412,
			"vw": 22.1422,
			"o": 22.1434,
			"c": 22.1411,
			"h": 22.1454,
			"l": 22.1411,
			"t": 1344621360000,
			"n": 22
		  },
		  {
			"v": 155988,
			"vw": 22.1435,
			"o": 22.1425,
			"c": 22.145,
			"h": 22.146,
			"l": 22.1415,
			"t": 1344621420000,
			"n": 42
		  },
		  {
			"v": 170100,
			"vw": 22.1423,
			"o": 22.1454,
			"c": 22.1414,
			"h": 22.1454,
			"l": 22.1411,
			"t": 1344621480000,
			"n": 39
		  },
		  {
			"v": 49560,
			"vw": 22.1425,
			"o": 22.1421,
			"c": 22.1418,
			"h": 22.1443,
			"l": 22.1414,
			"t": 1344621540000,
			"n": 13
		  },
		  {
			"v": 271880,
			"vw": 22.1447,
			"o": 22.1425,
			"c": 22.1468,
			"h": 22.1468,
			"l": 22.1421,
			"t": 1344621600000,
			"n": 69
		  },
		  {
			"v": 138040,
			"vw": 22.1468,
			"o": 22.1475,
			"c": 22.1457,
			"h": 22.1486,
			"l": 22.1457,
			"t": 1344621660000,
			"n": 35
		  },
		  {
			"v": 146580,
			"vw": 22.1446,
			"o": 22.1457,
			"c": 22.1471,
			"h": 22.1475,
			"l": 22.1429,
			"t": 1344621720000,
			"n": 33
		  },
		  {
			"v": 137172,
			"vw": 22.1467,
			"o": 22.1475,
			"c": 22.1454,
			"h": 22.1486,
			"l": 22.1432,
			"t": 1344621780000,
			"n": 43
		  },
		  {
			"v": 151284,
			"vw": 22.144,
			"o": 22.1448,
			"c": 22.1436,
			"h": 22.1448,
			"l": 22.1432,
			"t": 1344621840000,
			"n": 31
		  },
		  {
			"v": 176820,
			"vw": 22.1462,
			"o": 22.1454,
			"c": 22.1461,
			"h": 22.1486,
			"l": 22.1439,
			"t": 1344621900000,
			"n": 42
		  },
		  {
			"v": 370888,
			"vw": 22.1449,
			"o": 22.1454,
			"c": 22.1471,
			"h": 22.1489,
			"l": 22.1418,
			"t": 1344621960000,
			"n": 68
		  },
		  {
			"v": 68880,
			"vw": 22.1458,
			"o": 22.1454,
			"c": 22.1446,
			"h": 22.147,
			"l": 22.1446,
			"t": 1344622020000,
			"n": 24
		  },
		  {
			"v": 225204,
			"vw": 22.1462,
			"o": 22.1461,
			"c": 22.1461,
			"h": 22.1471,
			"l": 22.1449,
			"t": 1344622080000,
			"n": 44
		  },
		  {
			"v": 380408,
			"vw": 22.1462,
			"o": 22.1461,
			"c": 22.1464,
			"h": 22.1468,
			"l": 22.1446,
			"t": 1344622140000,
			"n": 35
		  },
		  {
			"v": 177688,
			"vw": 22.1466,
			"o": 22.1464,
			"c": 22.1449,
			"h": 22.1486,
			"l": 22.1446,
			"t": 1344622200000,
			"n": 41
		  },
		  {
			"v": 127708,
			"vw": 22.1461,
			"o": 22.1443,
			"c": 22.1482,
			"h": 22.1482,
			"l": 22.1443,
			"t": 1344622260000,
			"n": 30
		  },
		  {
			"v": 103684,
			"vw": 22.147,
			"o": 22.1482,
			"c": 22.1464,
			"h": 22.1482,
			"l": 22.1461,
			"t": 1344622320000,
			"n": 26
		  },
		  {
			"v": 185388,
			"vw": 22.1466,
			"o": 22.1464,
			"c": 22.1468,
			"h": 22.148,
			"l": 22.1446,
			"t": 1344622380000,
			"n": 45
		  },
		  {
			"v": 421344,
			"vw": 22.1444,
			"o": 22.1461,
			"c": 22.1432,
			"h": 22.1475,
			"l": 22.1411,
			"t": 1344622440000,
			"n": 100
		  },
		  {
			"v": 50400,
			"vw": 22.1446,
			"o": 22.1468,
			"c": 22.1454,
			"h": 22.1468,
			"l": 22.1429,
			"t": 1344622500000,
			"n": 15
		  },
		  {
			"v": 74900,
			"vw": 22.1461,
			"o": 22.1439,
			"c": 22.1468,
			"h": 22.1468,
			"l": 22.1439,
			"t": 1344622560000,
			"n": 15
		  },
		  {
			"v": 291284,
			"vw": 22.1464,
			"o": 22.1446,
			"c": 22.1439,
			"h": 22.1475,
			"l": 22.1436,
			"t": 1344622620000,
			"n": 64
		  },
		  {
			"v": 275352,
			"vw": 22.1446,
			"o": 22.1457,
			"c": 22.145,
			"h": 22.1475,
			"l": 22.1414,
			"t": 1344622680000,
			"n": 55
		  },
		  {
			"v": 202972,
			"vw": 22.1446,
			"o": 22.1468,
			"c": 22.1435,
			"h": 22.1475,
			"l": 22.1421,
			"t": 1344622740000,
			"n": 50
		  },
		  {
			"v": 187348,
			"vw": 22.1429,
			"o": 22.1428,
			"c": 22.1429,
			"h": 22.1457,
			"l": 22.1411,
			"t": 1344622800000,
			"n": 58
		  },
		  {
			"v": 232568,
			"vw": 22.1412,
			"o": 22.1433,
			"c": 22.1386,
			"h": 22.1454,
			"l": 22.1386,
			"t": 1344622860000,
			"n": 52
		  },
		  {
			"v": 251636,
			"vw": 22.1436,
			"o": 22.1393,
			"c": 22.1425,
			"h": 22.1464,
			"l": 22.1386,
			"t": 1344622920000,
			"n": 67
		  },
		  {
			"v": 206472,
			"vw": 22.1424,
			"o": 22.1418,
			"c": 22.1432,
			"h": 22.1443,
			"l": 22.1404,
			"t": 1344622980000,
			"n": 48
		  },
		  {
			"v": 148316,
			"vw": 22.1433,
			"o": 22.1429,
			"c": 22.1432,
			"h": 22.1443,
			"l": 22.1411,
			"t": 1344623040000,
			"n": 35
		  },
		  {
			"v": 164108,
			"vw": 22.1444,
			"o": 22.1439,
			"c": 22.1429,
			"h": 22.1461,
			"l": 22.1429,
			"t": 1344623100000,
			"n": 52
		  },
		  {
			"v": 65240,
			"vw": 22.1431,
			"o": 22.1432,
			"c": 22.1421,
			"h": 22.145,
			"l": 22.1421,
			"t": 1344623160000,
			"n": 20
		  },
		  {
			"v": 202412,
			"vw": 22.1442,
			"o": 22.1439,
			"c": 22.1439,
			"h": 22.1461,
			"l": 22.1425,
			"t": 1344623220000,
			"n": 52
		  },
		  {
			"v": 120260,
			"vw": 22.1428,
			"o": 22.1429,
			"c": 22.1446,
			"h": 22.1453,
			"l": 22.1396,
			"t": 1344623280000,
			"n": 32
		  },
		  {
			"v": 190372,
			"vw": 22.1462,
			"o": 22.1436,
			"c": 22.1475,
			"h": 22.1475,
			"l": 22.1432,
			"t": 1344623340000,
			"n": 45
		  },
		  {
			"v": 331660,
			"vw": 22.1482,
			"o": 22.1479,
			"c": 22.1482,
			"h": 22.1496,
			"l": 22.1461,
			"t": 1344623400000,
			"n": 79
		  },
		  {
			"v": 315896,
			"vw": 22.1503,
			"o": 22.1482,
			"c": 22.15,
			"h": 22.1525,
			"l": 22.1479,
			"t": 1344623460000,
			"n": 66
		  },
		  {
			"v": 156408,
			"vw": 22.1502,
			"o": 22.15,
			"c": 22.1511,
			"h": 22.1518,
			"l": 22.1489,
			"t": 1344623520000,
			"n": 30
		  },
		  {
			"v": 381612,
			"vw": 22.1517,
			"o": 22.1511,
			"c": 22.1507,
			"h": 22.1539,
			"l": 22.15,
			"t": 1344623580000,
			"n": 52
		  },
		  {
			"v": 128212,
			"vw": 22.1537,
			"o": 22.1536,
			"c": 22.1539,
			"h": 22.1539,
			"l": 22.1518,
			"t": 1344623640000,
			"n": 21
		  },
		  {
			"v": 551740,
			"vw": 22.1546,
			"o": 22.1539,
			"c": 22.1536,
			"h": 22.1568,
			"l": 22.1511,
			"t": 1344623700000,
			"n": 114
		  },
		  {
			"v": 391048,
			"vw": 22.155,
			"o": 22.1539,
			"c": 22.1552,
			"h": 22.1568,
			"l": 22.1521,
			"t": 1344623760000,
			"n": 101
		  },
		  {
			"v": 406000,
			"vw": 22.1562,
			"o": 22.1554,
			"c": 22.1561,
			"h": 22.1586,
			"l": 22.1539,
			"t": 1344623820000,
			"n": 97
		  },
		  {
			"v": 285460,
			"vw": 22.1548,
			"o": 22.1561,
			"c": 22.1586,
			"h": 22.1589,
			"l": 22.1532,
			"t": 1344623880000,
			"n": 52
		  },
		  {
			"v": 248556,
			"vw": 22.1591,
			"o": 22.1571,
			"c": 22.1604,
			"h": 22.1607,
			"l": 22.1564,
			"t": 1344623940000,
			"n": 53
		  },
		  {
			"v": 689080,
			"vw": 22.1643,
			"o": 22.1598,
			"c": 22.1679,
			"h": 22.1696,
			"l": 22.159,
			"t": 1344624000000,
			"n": 140
		  },
		  {
			"v": 267596,
			"vw": 22.1652,
			"o": 22.1675,
			"c": 22.1633,
			"h": 22.1682,
			"l": 22.1619,
			"t": 1344624060000,
			"n": 62
		  },
		  {
			"v": 302372,
			"vw": 22.1652,
			"o": 22.1621,
			"c": 22.1654,
			"h": 22.1671,
			"l": 22.1621,
			"t": 1344624120000,
			"n": 90
		  },
		  {
			"v": 339584,
			"vw": 22.1668,
			"o": 22.1654,
			"c": 22.165,
			"h": 22.1675,
			"l": 22.165,
			"t": 1344624180000,
			"n": 84
		  },
		  {
			"v": 218344,
			"vw": 22.168,
			"o": 22.1657,
			"c": 22.1689,
			"h": 22.1696,
			"l": 22.165,
			"t": 1344624240000,
			"n": 62
		  },
		  {
			"v": 1272376,
			"vw": 22.176,
			"o": 22.1689,
			"c": 22.1789,
			"h": 22.18,
			"l": 22.1686,
			"t": 1344624300000,
			"n": 201
		  },
		  {
			"v": 1828064,
			"vw": 22.1729,
			"o": 22.1793,
			"c": 22.1825,
			"h": 22.1839,
			"l": 22.1786,
			"t": 1344624360000,
			"n": 226
		  },
		  {
			"v": 698600,
			"vw": 22.1796,
			"o": 22.1807,
			"c": 22.1825,
			"h": 22.1839,
			"l": 22.1782,
			"t": 1344624420000,
			"n": 132
		  },
		  {
			"v": 540512,
			"vw": 22.1841,
			"o": 22.1821,
			"c": 22.1843,
			"h": 22.1854,
			"l": 22.1821,
			"t": 1344624480000,
			"n": 128
		  },
		  {
			"v": 624876,
			"vw": 22.1846,
			"o": 22.1839,
			"c": 22.1839,
			"h": 22.1875,
			"l": 22.1821,
			"t": 1344624540000,
			"n": 162
		  },
		  {
			"v": 834792,
			"vw": 22.1879,
			"o": 22.1846,
			"c": 22.1946,
			"h": 22.1946,
			"l": 22.1839,
			"t": 1344624600000,
			"n": 174
		  },
		  {
			"v": 673400,
			"vw": 22.195,
			"o": 22.1939,
			"c": 22.1925,
			"h": 22.1964,
			"l": 22.1914,
			"t": 1344624660000,
			"n": 191
		  },
		  {
			"v": 741076,
			"vw": 22.1928,
			"o": 22.1914,
			"c": 22.1954,
			"h": 22.1964,
			"l": 22.1879,
			"t": 1344624720000,
			"n": 168
		  },
		  {
			"v": 859460,
			"vw": 22.1991,
			"o": 22.1964,
			"c": 22.2036,
			"h": 22.2036,
			"l": 22.1947,
			"t": 1344624780000,
			"n": 197
		  },
		  {
			"v": 661024,
			"vw": 22.2018,
			"o": 22.2036,
			"c": 22.1989,
			"h": 22.2046,
			"l": 22.1982,
			"t": 1344624840000,
			"n": 163
		  },
		  {
			"v": 519540,
			"vw": 22.1941,
			"o": 22.1986,
			"c": 22.1946,
			"h": 22.1986,
			"l": 22.1904,
			"t": 1344624900000,
			"n": 140
		  },
		  {
			"v": 510132,
			"vw": 22.1984,
			"o": 22.1943,
			"c": 22.2025,
			"h": 22.2025,
			"l": 22.1929,
			"t": 1344624960000,
			"n": 119
		  },
		  {
			"v": 427700,
			"vw": 22.1998,
			"o": 22.2025,
			"c": 22.1964,
			"h": 22.2032,
			"l": 22.1954,
			"t": 1344625020000,
			"n": 113
		  },
		  {
			"v": 929432,
			"vw": 22.1888,
			"o": 22.1954,
			"c": 22.1836,
			"h": 22.1966,
			"l": 22.1786,
			"t": 1344625080000,
			"n": 211
		  },
		  {
			"v": 476280,
			"vw": 22.1807,
			"o": 22.1832,
			"c": 22.1802,
			"h": 22.185,
			"l": 22.1786,
			"t": 1344625140000,
			"n": 137
		  },
		  {
			"v": 853720,
			"vw": 22.1787,
			"o": 22.1802,
			"c": 22.1786,
			"h": 22.1818,
			"l": 22.1718,
			"t": 1344625200000,
			"n": 186
		  },
		  {
			"v": 327012,
			"vw": 22.1793,
			"o": 22.1789,
			"c": 22.1807,
			"h": 22.1814,
			"l": 22.1775,
			"t": 1344625260000,
			"n": 76
		  },
		  {
			"v": 360500,
			"vw": 22.181,
			"o": 22.1807,
			"c": 22.1779,
			"h": 22.1836,
			"l": 22.1775,
			"t": 1344625320000,
			"n": 87
		  },
		  {
			"v": 197596,
			"vw": 22.1761,
			"o": 22.1776,
			"c": 22.1746,
			"h": 22.18,
			"l": 22.1736,
			"t": 1344625380000,
			"n": 53
		  },
		  {
			"v": 193816,
			"vw": 22.1736,
			"o": 22.1754,
			"c": 22.1768,
			"h": 22.1768,
			"l": 22.1711,
			"t": 1344625440000,
			"n": 55
		  },
		  {
			"v": 111104,
			"vw": 22.1766,
			"o": 22.1757,
			"c": 22.1781,
			"h": 22.1782,
			"l": 22.1729,
			"t": 1344625500000,
			"n": 34
		  },
		  {
			"v": 320796,
			"vw": 22.1784,
			"o": 22.1757,
			"c": 22.1821,
			"h": 22.1821,
			"l": 22.1757,
			"t": 1344625560000,
			"n": 56
		  },
		  {
			"v": 280532,
			"vw": 22.1818,
			"o": 22.18,
			"c": 22.1818,
			"h": 22.1836,
			"l": 22.1786,
			"t": 1344625620000,
			"n": 52
		  },
		  {
			"v": 423752,
			"vw": 22.1849,
			"o": 22.1825,
			"c": 22.1892,
			"h": 22.1893,
			"l": 22.1804,
			"t": 1344625680000,
			"n": 84
		  },
		  {
			"v": 1202684,
			"vw": 22.1939,
			"o": 22.1875,
			"c": 22.1971,
			"h": 22.1982,
			"l": 22.1875,
			"t": 1344625740000,
			"n": 217
		  },
		  {
			"v": 626948,
			"vw": 22.1974,
			"o": 22.1989,
			"c": 22.1929,
			"h": 22.2004,
			"l": 22.1918,
			"t": 1344625800000,
			"n": 132
		  },
		  {
			"v": 480788,
			"vw": 22.1893,
			"o": 22.1918,
			"c": 22.1904,
			"h": 22.1918,
			"l": 22.1864,
			"t": 1344625860000,
			"n": 111
		  },
		  {
			"v": 310156,
			"vw": 22.1862,
			"o": 22.1911,
			"c": 22.1854,
			"h": 22.1911,
			"l": 22.1836,
			"t": 1344625920000,
			"n": 67
		  },
		  {
			"v": 430472,
			"vw": 22.1837,
			"o": 22.1832,
			"c": 22.1868,
			"h": 22.1878,
			"l": 22.1796,
			"t": 1344625980000,
			"n": 105
		  },
		  {
			"v": 212352,
			"vw": 22.1872,
			"o": 22.1878,
			"c": 22.1889,
			"h": 22.1893,
			"l": 22.1846,
			"t": 1344626040000,
			"n": 52
		  },
		  {
			"v": 202272,
			"vw": 22.1888,
			"o": 22.19,
			"c": 22.188,
			"h": 22.1907,
			"l": 22.1868,
			"t": 1344626100000,
			"n": 49
		  },
		  {
			"v": 427364,
			"vw": 22.1927,
			"o": 22.1893,
			"c": 22.1939,
			"h": 22.1968,
			"l": 22.1857,
			"t": 1344626160000,
			"n": 109
		  },
		  {
			"v": 372204,
			"vw": 22.1926,
			"o": 22.1939,
			"c": 22.1914,
			"h": 22.1961,
			"l": 22.19,
			"t": 1344626220000,
			"n": 80
		  },
		  {
			"v": 326312,
			"vw": 22.1909,
			"o": 22.1946,
			"c": 22.1907,
			"h": 22.1946,
			"l": 22.1875,
			"t": 1344626280000,
			"n": 61
		  },
		  {
			"v": 284228,
			"vw": 22.1894,
			"o": 22.1918,
			"c": 22.1921,
			"h": 22.1925,
			"l": 22.1864,
			"t": 1344626340000,
			"n": 66
		  },
		  {
			"v": 266700,
			"vw": 22.1886,
			"o": 22.1895,
			"c": 22.1911,
			"h": 22.1921,
			"l": 22.1856,
			"t": 1344626400000,
			"n": 58
		  },
		  {
			"v": 320124,
			"vw": 22.1945,
			"o": 22.1911,
			"c": 22.1961,
			"h": 22.1975,
			"l": 22.1911,
			"t": 1344626460000,
			"n": 61
		  },
		  {
			"v": 375228,
			"vw": 22.1954,
			"o": 22.1975,
			"c": 22.1968,
			"h": 22.1982,
			"l": 22.1929,
			"t": 1344626520000,
			"n": 95
		  },
		  {
			"v": 724164,
			"vw": 22.1987,
			"o": 22.1947,
			"c": 22.1996,
			"h": 22.2011,
			"l": 22.1946,
			"t": 1344626580000,
			"n": 180
		  },
		  {
			"v": 462084,
			"vw": 22.1988,
			"o": 22.1989,
			"c": 22.1959,
			"h": 22.2018,
			"l": 22.1936,
			"t": 1344626640000,
			"n": 70
		  },
		  {
			"v": 587748,
			"vw": 22.1972,
			"o": 22.1943,
			"c": 22.2018,
			"h": 22.2018,
			"l": 22.194,
			"t": 1344626700000,
			"n": 63
		  },
		  {
			"v": 259728,
			"vw": 22.2004,
			"o": 22.2011,
			"c": 22.1999,
			"h": 22.2021,
			"l": 22.1989,
			"t": 1344626760000,
			"n": 67
		  },
		  {
			"v": 252560,
			"vw": 22.1965,
			"o": 22.1986,
			"c": 22.1964,
			"h": 22.2004,
			"l": 22.1921,
			"t": 1344626820000,
			"n": 71
		  },
		  {
			"v": 321412,
			"vw": 22.1941,
			"o": 22.1968,
			"c": 22.1929,
			"h": 22.1968,
			"l": 22.1918,
			"t": 1344626880000,
			"n": 89
		  },
		  {
			"v": 365064,
			"vw": 22.1951,
			"o": 22.1946,
			"c": 22.1946,
			"h": 22.1975,
			"l": 22.1925,
			"t": 1344626940000,
			"n": 87
		  },
		  {
			"v": 269416,
			"vw": 22.1944,
			"o": 22.1946,
			"c": 22.1961,
			"h": 22.1964,
			"l": 22.1925,
			"t": 1344627000000,
			"n": 81
		  },
		  {
			"v": 440720,
			"vw": 22.1917,
			"o": 22.1936,
			"c": 22.1904,
			"h": 22.1943,
			"l": 22.19,
			"t": 1344627060000,
			"n": 103
		  },
		  {
			"v": 894096,
			"vw": 22.1928,
			"o": 22.1904,
			"c": 22.1889,
			"h": 22.1986,
			"l": 22.1864,
			"t": 1344627120000,
			"n": 272
		  },
		  {
			"v": 350280,
			"vw": 22.1916,
			"o": 22.1886,
			"c": 22.1921,
			"h": 22.2,
			"l": 22.1882,
			"t": 1344627180000,
			"n": 94
		  },
		  {
			"v": 314916,
			"vw": 22.1927,
			"o": 22.1932,
			"c": 22.1914,
			"h": 22.1943,
			"l": 22.1893,
			"t": 1344627240000,
			"n": 71
		  },
		  {
			"v": 492184,
			"vw": 22.1913,
			"o": 22.1893,
			"c": 22.1918,
			"h": 22.1929,
			"l": 22.1882,
			"t": 1344627300000,
			"n": 99
		  },
		  {
			"v": 262136,
			"vw": 22.188,
			"o": 22.1922,
			"c": 22.1864,
			"h": 22.1925,
			"l": 22.1861,
			"t": 1344627360000,
			"n": 71
		  },
		  {
			"v": 569772,
			"vw": 22.1826,
			"o": 22.1861,
			"c": 22.1811,
			"h": 22.1864,
			"l": 22.1811,
			"t": 1344627420000,
			"n": 105
		  },
		  {
			"v": 649656,
			"vw": 22.1788,
			"o": 22.1804,
			"c": 22.1793,
			"h": 22.1829,
			"l": 22.1743,
			"t": 1344627480000,
			"n": 161
		  },
		  {
			"v": 649628,
			"vw": 22.1826,
			"o": 22.1775,
			"c": 22.1831,
			"h": 22.1875,
			"l": 22.1775,
			"t": 1344627540000,
			"n": 162
		  },
		  {
			"v": 382004,
			"vw": 22.1857,
			"o": 22.1867,
			"c": 22.1864,
			"h": 22.1882,
			"l": 22.1829,
			"t": 1344627600000,
			"n": 101
		  },
		  {
			"v": 411180,
			"vw": 22.1853,
			"o": 22.1857,
			"c": 22.1844,
			"h": 22.1868,
			"l": 22.1829,
			"t": 1344627660000,
			"n": 109
		  },
		  {
			"v": 447216,
			"vw": 22.1869,
			"o": 22.1846,
			"c": 22.1875,
			"h": 22.1882,
			"l": 22.1836,
			"t": 1344627720000,
			"n": 123
		  },
		  {
			"v": 643664,
			"vw": 22.19,
			"o": 22.1868,
			"c": 22.1893,
			"h": 22.1929,
			"l": 22.1868,
			"t": 1344627780000,
			"n": 151
		  },
		  {
			"v": 1000636,
			"vw": 22.1941,
			"o": 22.1886,
			"c": 22.1949,
			"h": 22.1961,
			"l": 22.1886,
			"t": 1344627840000,
			"n": 183
		  },
		  {
			"v": 506044,
			"vw": 22.1929,
			"o": 22.195,
			"c": 22.1918,
			"h": 22.1964,
			"l": 22.1889,
			"t": 1344627900000,
			"n": 141
		  },
		  {
			"v": 839020,
			"vw": 22.1943,
			"o": 22.1925,
			"c": 22.1978,
			"h": 22.1982,
			"l": 22.1904,
			"t": 1344627960000,
			"n": 204
		  },
		  {
			"v": 643440,
			"vw": 22.1965,
			"o": 22.1975,
			"c": 22.1946,
			"h": 22.1993,
			"l": 22.1932,
			"t": 1344628020000,
			"n": 163
		  },
		  {
			"v": 572096,
			"vw": 22.1959,
			"o": 22.1943,
			"c": 22.1964,
			"h": 22.1975,
			"l": 22.1936,
			"t": 1344628080000,
			"n": 132
		  },
		  {
			"v": 1254148,
			"vw": 22.1977,
			"o": 22.1964,
			"c": 22.1986,
			"h": 22.2004,
			"l": 22.1939,
			"t": 1344628140000,
			"n": 278
		  },
		  {
			"v": 1429092,
			"vw": 22.2019,
			"o": 22.1989,
			"c": 22.2018,
			"h": 22.2046,
			"l": 22.1989,
			"t": 1344628200000,
			"n": 346
		  },
		  {
			"v": 1102556,
			"vw": 22.2018,
			"o": 22.2018,
			"c": 22.1968,
			"h": 22.2046,
			"l": 22.1964,
			"t": 1344628260000,
			"n": 277
		  },
		  {
			"v": 1791384,
			"vw": 22.1947,
			"o": 22.1961,
			"c": 22.1964,
			"h": 22.1996,
			"l": 22.1893,
			"t": 1344628320000,
			"n": 456
		  },
		  {
			"v": 1064896,
			"vw": 22.1945,
			"o": 22.1961,
			"c": 22.195,
			"h": 22.1975,
			"l": 22.1907,
			"t": 1344628380000,
			"n": 261
		  },
		  {
			"v": 721756,
			"vw": 22.1964,
			"o": 22.195,
			"c": 22.1954,
			"h": 22.1971,
			"l": 22.1939,
			"t": 1344628440000,
			"n": 185
		  },
		  {
			"v": 521080,
			"vw": 22.1961,
			"o": 22.1961,
			"c": 22.1968,
			"h": 22.1971,
			"l": 22.1946,
			"t": 1344628500000,
			"n": 144
		  },
		  {
			"v": 1271648,
			"vw": 22.1989,
			"o": 22.1968,
			"c": 22.1995,
			"h": 22.2007,
			"l": 22.1968,
			"t": 1344628560000,
			"n": 332
		  },
		  {
			"v": 1747956,
			"vw": 22.2002,
			"o": 22.2,
			"c": 22.1993,
			"h": 22.2025,
			"l": 22.1979,
			"t": 1344628620000,
			"n": 383
		  },
		  {
			"v": 2561580,
			"vw": 22.2,
			"o": 22.1993,
			"c": 22.2,
			"h": 22.2011,
			"l": 22.1964,
			"t": 1344628680000,
			"n": 542
		  },
		  {
			"v": 4306512,
			"vw": 22.2001,
			"o": 22.1996,
			"c": 22.2014,
			"h": 22.2043,
			"l": 22.1939,
			"t": 1344628740000,
			"n": 926
		  },
		  {
			"v": 205800,
			"vw": 22.2089,
			"o": 22.2014,
			"c": 22.2107,
			"h": 22.2107,
			"l": 22.2014,
			"t": 1344628800000,
			"n": 26
		  },
		  {
			"v": 47320,
			"vw": 22.2131,
			"o": 22.2107,
			"c": 22.2136,
			"h": 22.2136,
			"l": 22.2107,
			"t": 1344628860000,
			"n": 11
		  },
		  {
			"v": 79520,
			"vw": 22.2119,
			"o": 22.2107,
			"c": 22.2075,
			"h": 22.2143,
			"l": 22.2075,
			"t": 1344628920000,
			"n": 17
		  },
		  {
			"v": 92988,
			"vw": 22.2108,
			"o": 22.2132,
			"c": 22.2079,
			"h": 22.2143,
			"l": 22.2079,
			"t": 1344628980000,
			"n": 21
		  },
		  {
			"v": 82348,
			"vw": 22.2136,
			"o": 22.2082,
			"c": 22.2143,
			"h": 22.2143,
			"l": 22.2082,
			"t": 1344629040000,
			"n": 20
		  },
		  {
			"v": 11200,
			"vw": 22.2207,
			"o": 22.2136,
			"c": 22.225,
			"h": 22.225,
			"l": 22.2136,
			"t": 1344629100000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.2165,
			"o": 22.2161,
			"c": 22.2182,
			"h": 22.2182,
			"l": 22.2161,
			"t": 1344629160000,
			"n": 4
		  },
		  {
			"v": 69524,
			"vw": 22.2219,
			"o": 22.2196,
			"c": 22.2261,
			"h": 22.2261,
			"l": 22.2196,
			"t": 1344629220000,
			"n": 13
		  },
		  {
			"v": 24920,
			"vw": 22.2178,
			"o": 22.2171,
			"c": 22.2179,
			"h": 22.2179,
			"l": 22.2171,
			"t": 1344629280000,
			"n": 6
		  },
		  {
			"v": 75880,
			"vw": 22.206,
			"o": 22.2196,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2196,
			"t": 1344629340000,
			"n": 5
		  },
		  {
			"v": 60032,
			"vw": 22.1929,
			"o": 22.2204,
			"c": 22.215,
			"h": 22.2204,
			"l": 22.215,
			"t": 1344629400000,
			"n": 16
		  },
		  {
			"v": 98000,
			"vw": 22.2215,
			"o": 22.2171,
			"c": 22.2214,
			"h": 22.2232,
			"l": 22.2157,
			"t": 1344629460000,
			"n": 17
		  },
		  {
			"v": 8400,
			"vw": 22.2226,
			"o": 22.2214,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2214,
			"t": 1344629520000,
			"n": 2
		  },
		  {
			"v": 30800,
			"vw": 22.2276,
			"o": 22.2264,
			"c": 22.2279,
			"h": 22.2279,
			"l": 22.2264,
			"t": 1344629580000,
			"n": 8
		  },
		  {
			"v": 36344,
			"vw": 22.2276,
			"o": 22.2254,
			"c": 22.2286,
			"h": 22.23,
			"l": 22.2236,
			"t": 1344629640000,
			"n": 12
		  },
		  {
			"v": 60760,
			"vw": 22.2253,
			"o": 22.2286,
			"c": 22.2232,
			"h": 22.2314,
			"l": 22.2232,
			"t": 1344629700000,
			"n": 10
		  },
		  {
			"v": 5320,
			"vw": 22.2232,
			"o": 22.2232,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2232,
			"t": 1344629760000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.2206,
			"o": 22.2196,
			"c": 22.2229,
			"h": 22.2229,
			"l": 22.2196,
			"t": 1344629880000,
			"n": 2
		  },
		  {
			"v": 55300,
			"vw": 22.1818,
			"o": 22.2196,
			"c": 22.2179,
			"h": 22.2196,
			"l": 22.2179,
			"t": 1344629940000,
			"n": 5
		  },
		  {
			"v": 13300,
			"vw": 22.2188,
			"o": 22.2229,
			"c": 22.2229,
			"h": 22.2229,
			"l": 22.2229,
			"t": 1344630000000,
			"n": 4
		  },
		  {
			"v": 16800,
			"vw": 22.2237,
			"o": 22.2229,
			"c": 22.2246,
			"h": 22.2246,
			"l": 22.2229,
			"t": 1344630060000,
			"n": 6
		  },
		  {
			"v": 47320,
			"vw": 22.2256,
			"o": 22.225,
			"c": 22.2286,
			"h": 22.2286,
			"l": 22.225,
			"t": 1344630120000,
			"n": 6
		  },
		  {
			"v": 157976,
			"vw": 22.2023,
			"o": 22.2286,
			"c": 22.2261,
			"h": 22.2286,
			"l": 22.2261,
			"t": 1344630180000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.2279,
			"o": 22.2279,
			"c": 22.2279,
			"h": 22.2279,
			"l": 22.2279,
			"t": 1344630240000,
			"n": 1
		  },
		  {
			"v": 87948,
			"vw": 22.2168,
			"o": 22.2257,
			"c": 22.2189,
			"h": 22.2261,
			"l": 22.2039,
			"t": 1344630300000,
			"n": 17
		  },
		  {
			"v": 19600,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344630360000,
			"n": 6
		  },
		  {
			"v": 52864,
			"vw": 22.2254,
			"o": 22.2214,
			"c": 22.2286,
			"h": 22.2286,
			"l": 22.2214,
			"t": 1344630420000,
			"n": 7
		  },
		  {
			"v": 15960,
			"vw": 22.2239,
			"o": 22.2239,
			"c": 22.225,
			"h": 22.225,
			"l": 22.2236,
			"t": 1344630480000,
			"n": 4
		  },
		  {
			"v": 22400,
			"vw": 22.2286,
			"o": 22.2286,
			"c": 22.2286,
			"h": 22.2286,
			"l": 22.2286,
			"t": 1344630540000,
			"n": 5
		  },
		  {
			"v": 32704,
			"vw": 22.2259,
			"o": 22.2275,
			"c": 22.225,
			"h": 22.2275,
			"l": 22.225,
			"t": 1344630600000,
			"n": 7
		  },
		  {
			"v": 8400,
			"vw": 22.2249,
			"o": 22.2246,
			"c": 22.225,
			"h": 22.225,
			"l": 22.2246,
			"t": 1344630660000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.2225,
			"o": 22.22,
			"c": 22.225,
			"h": 22.225,
			"l": 22.22,
			"t": 1344630720000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.225,
			"o": 22.225,
			"c": 22.225,
			"h": 22.225,
			"l": 22.225,
			"t": 1344630840000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2207,
			"o": 22.2207,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2207,
			"t": 1344630900000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2246,
			"o": 22.2246,
			"c": 22.2246,
			"h": 22.2246,
			"l": 22.2246,
			"t": 1344631260000,
			"n": 2
		  },
		  {
			"v": 13972,
			"vw": 22.209,
			"o": 22.2221,
			"c": 22.2221,
			"h": 22.2221,
			"l": 22.2221,
			"t": 1344631320000,
			"n": 3
		  },
		  {
			"v": 36960,
			"vw": 22.2213,
			"o": 22.2214,
			"c": 22.2207,
			"h": 22.2214,
			"l": 22.2207,
			"t": 1344631440000,
			"n": 6
		  },
		  {
			"v": 32032,
			"vw": 22.2221,
			"o": 22.2221,
			"c": 22.2221,
			"h": 22.2225,
			"l": 22.2214,
			"t": 1344631500000,
			"n": 7
		  },
		  {
			"v": 40600,
			"vw": 22.2222,
			"o": 22.2225,
			"c": 22.2221,
			"h": 22.2225,
			"l": 22.2221,
			"t": 1344631560000,
			"n": 9
		  },
		  {
			"v": 13552,
			"vw": 22.2191,
			"o": 22.2207,
			"c": 22.2171,
			"h": 22.2207,
			"l": 22.2171,
			"t": 1344631620000,
			"n": 3
		  },
		  {
			"v": 30492,
			"vw": 22.2201,
			"o": 22.2211,
			"c": 22.2182,
			"h": 22.2211,
			"l": 22.2182,
			"t": 1344632100000,
			"n": 4
		  },
		  {
			"v": 11200,
			"vw": 22.2193,
			"o": 22.2196,
			"c": 22.2186,
			"h": 22.2196,
			"l": 22.2186,
			"t": 1344632400000,
			"n": 3
		  },
		  {
			"v": 19600,
			"vw": 22.2183,
			"o": 22.2179,
			"c": 22.2211,
			"h": 22.2211,
			"l": 22.2179,
			"t": 1344632520000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2211,
			"o": 22.2211,
			"c": 22.2211,
			"h": 22.2211,
			"l": 22.2211,
			"t": 1344632940000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344633000000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.225,
			"o": 22.225,
			"c": 22.225,
			"h": 22.225,
			"l": 22.225,
			"t": 1344633720000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2261,
			"o": 22.2261,
			"c": 22.2261,
			"h": 22.2261,
			"l": 22.2261,
			"t": 1344633780000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2218,
			"o": 22.2218,
			"c": 22.2218,
			"h": 22.2218,
			"l": 22.2218,
			"t": 1344633840000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2257,
			"o": 22.2257,
			"c": 22.2257,
			"h": 22.2257,
			"l": 22.2257,
			"t": 1344633960000,
			"n": 1
		  },
		  {
			"v": 8680,
			"vw": 22.2261,
			"o": 22.2261,
			"c": 22.2261,
			"h": 22.2261,
			"l": 22.2261,
			"t": 1344634080000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.2229,
			"o": 22.2229,
			"c": 22.2229,
			"h": 22.2229,
			"l": 22.2229,
			"t": 1344634260000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2232,
			"o": 22.2232,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2232,
			"t": 1344634500000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2221,
			"o": 22.2221,
			"c": 22.2221,
			"h": 22.2221,
			"l": 22.2221,
			"t": 1344636000000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2207,
			"o": 22.2207,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2207,
			"t": 1344636120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2175,
			"o": 22.2175,
			"c": 22.2175,
			"h": 22.2175,
			"l": 22.2175,
			"t": 1344636240000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2164,
			"o": 22.2164,
			"c": 22.2164,
			"h": 22.2164,
			"l": 22.2164,
			"t": 1344637260000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2207,
			"o": 22.2207,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2207,
			"t": 1344638640000,
			"n": 1
		  },
		  {
			"v": 4592,
			"vw": 22.2207,
			"o": 22.2207,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2207,
			"t": 1344639360000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2207,
			"o": 22.2207,
			"c": 22.2207,
			"h": 22.2207,
			"l": 22.2207,
			"t": 1344639600000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344639720000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344640020000,
			"n": 1
		  },
		  {
			"v": 21756,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344640080000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344640200000,
			"n": 1
		  },
		  {
			"v": 3920,
			"vw": 22.2189,
			"o": 22.2189,
			"c": 22.2189,
			"h": 22.2189,
			"l": 22.2189,
			"t": 1344640620000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2214,
			"o": 22.2214,
			"c": 22.2214,
			"h": 22.2214,
			"l": 22.2214,
			"t": 1344641820000,
			"n": 1
		  },
		  {
			"v": 5432,
			"vw": 22.2232,
			"o": 22.2232,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2232,
			"t": 1344642480000,
			"n": 1
		  },
		  {
			"v": 30268,
			"vw": 22.2231,
			"o": 22.2229,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2229,
			"t": 1344642900000,
			"n": 4
		  },
		  {
			"v": 28700,
			"vw": 22.2232,
			"o": 22.2232,
			"c": 22.2232,
			"h": 22.2232,
			"l": 22.2232,
			"t": 1344643140000,
			"n": 4
		  },
		  {
			"v": 15400,
			"vw": 22.1797,
			"o": 22.2036,
			"c": 22.1661,
			"h": 22.2036,
			"l": 22.1661,
			"t": 1344844800000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.1661,
			"o": 22.1661,
			"c": 22.1661,
			"h": 22.1661,
			"l": 22.1661,
			"t": 1344844860000,
			"n": 1
		  },
		  {
			"v": 3276,
			"vw": 22.1664,
			"o": 22.1664,
			"c": 22.1664,
			"h": 22.1664,
			"l": 22.1664,
			"t": 1344846240000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.1786,
			"o": 22.1786,
			"c": 22.1786,
			"h": 22.1786,
			"l": 22.1786,
			"t": 1344847800000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2054,
			"o": 22.2054,
			"c": 22.2054,
			"h": 22.2054,
			"l": 22.2054,
			"t": 1344849000000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2054,
			"o": 22.2054,
			"c": 22.2054,
			"h": 22.2054,
			"l": 22.2054,
			"t": 1344849240000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2054,
			"o": 22.2054,
			"c": 22.2054,
			"h": 22.2054,
			"l": 22.2054,
			"t": 1344850440000,
			"n": 1
		  },
		  {
			"v": 18060,
			"vw": 22.2054,
			"o": 22.2054,
			"c": 22.2054,
			"h": 22.2054,
			"l": 22.2054,
			"t": 1344850860000,
			"n": 2
		  },
		  {
			"v": 9800,
			"vw": 22.2321,
			"o": 22.2321,
			"c": 22.2321,
			"h": 22.2321,
			"l": 22.2321,
			"t": 1344853740000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.2321,
			"o": 22.2321,
			"c": 22.2321,
			"h": 22.2321,
			"l": 22.2321,
			"t": 1344854040000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2464,
			"o": 22.2464,
			"c": 22.2464,
			"h": 22.2464,
			"l": 22.2464,
			"t": 1344855540000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.2464,
			"o": 22.2464,
			"c": 22.2464,
			"h": 22.2464,
			"l": 22.2464,
			"t": 1344855600000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.2439,
			"o": 22.2439,
			"c": 22.2439,
			"h": 22.2439,
			"l": 22.2439,
			"t": 1344855780000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.24,
			"o": 22.24,
			"c": 22.24,
			"h": 22.24,
			"l": 22.24,
			"t": 1344856080000,
			"n": 1
		  },
		  {
			"v": 9268,
			"vw": 22.2693,
			"o": 22.2714,
			"c": 22.2679,
			"h": 22.2714,
			"l": 22.2679,
			"t": 1344856500000,
			"n": 2
		  },
		  {
			"v": 11900,
			"vw": 22.2632,
			"o": 22.2654,
			"c": 22.2625,
			"h": 22.2654,
			"l": 22.2625,
			"t": 1344856620000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.2589,
			"o": 22.2589,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2589,
			"t": 1344856800000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2571,
			"o": 22.2589,
			"c": 22.2554,
			"h": 22.2589,
			"l": 22.2554,
			"t": 1344856860000,
			"n": 2
		  },
		  {
			"v": 13328,
			"vw": 22.2491,
			"o": 22.2504,
			"c": 22.2464,
			"h": 22.2504,
			"l": 22.2464,
			"t": 1344856920000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.2454,
			"o": 22.2454,
			"c": 22.2454,
			"h": 22.2454,
			"l": 22.2454,
			"t": 1344856980000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2411,
			"o": 22.2411,
			"c": 22.2411,
			"h": 22.2411,
			"l": 22.2411,
			"t": 1344857100000,
			"n": 1
		  },
		  {
			"v": 27720,
			"vw": 22.249,
			"o": 22.2464,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2464,
			"t": 1344857160000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.258,
			"o": 22.2571,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2571,
			"t": 1344857280000,
			"n": 2
		  },
		  {
			"v": 16800,
			"vw": 22.2607,
			"o": 22.2589,
			"c": 22.2632,
			"h": 22.2632,
			"l": 22.2589,
			"t": 1344857340000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.263,
			"o": 22.2621,
			"c": 22.2639,
			"h": 22.2639,
			"l": 22.2621,
			"t": 1344857400000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.2619,
			"o": 22.2643,
			"c": 22.2589,
			"h": 22.2643,
			"l": 22.2589,
			"t": 1344857520000,
			"n": 5
		  },
		  {
			"v": 11200,
			"vw": 22.2589,
			"o": 22.2589,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2589,
			"t": 1344857820000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.2589,
			"o": 22.2589,
			"c": 22.2589,
			"h": 22.2589,
			"l": 22.2589,
			"t": 1344857880000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.2554,
			"o": 22.2571,
			"c": 22.2518,
			"h": 22.2571,
			"l": 22.2518,
			"t": 1344857940000,
			"n": 2
		  },
		  {
			"v": 96460,
			"vw": 22.2673,
			"o": 22.2607,
			"c": 22.2814,
			"h": 22.2814,
			"l": 22.2589,
			"t": 1344858120000,
			"n": 17
		  },
		  {
			"v": 5600,
			"vw": 22.2816,
			"o": 22.2811,
			"c": 22.2821,
			"h": 22.2821,
			"l": 22.2811,
			"t": 1344858300000,
			"n": 2
		  },
		  {
			"v": 141960,
			"vw": 22.3033,
			"o": 22.2857,
			"c": 22.3196,
			"h": 22.3196,
			"l": 22.2857,
			"t": 1344858360000,
			"n": 22
		  },
		  {
			"v": 14000,
			"vw": 22.3178,
			"o": 22.3175,
			"c": 22.3179,
			"h": 22.3179,
			"l": 22.3175,
			"t": 1344858420000,
			"n": 2
		  },
		  {
			"v": 36400,
			"vw": 22.3341,
			"o": 22.3171,
			"c": 22.3411,
			"h": 22.3443,
			"l": 22.3171,
			"t": 1344858480000,
			"n": 7
		  },
		  {
			"v": 34076,
			"vw": 22.3433,
			"o": 22.34,
			"c": 22.3482,
			"h": 22.3514,
			"l": 22.3393,
			"t": 1344858540000,
			"n": 9
		  },
		  {
			"v": 16800,
			"vw": 22.3542,
			"o": 22.3493,
			"c": 22.3571,
			"h": 22.3571,
			"l": 22.3493,
			"t": 1344858660000,
			"n": 5
		  },
		  {
			"v": 16800,
			"vw": 22.3569,
			"o": 22.3571,
			"c": 22.3571,
			"h": 22.3571,
			"l": 22.3568,
			"t": 1344858720000,
			"n": 3
		  },
		  {
			"v": 33236,
			"vw": 22.3474,
			"o": 22.345,
			"c": 22.3429,
			"h": 22.3568,
			"l": 22.3429,
			"t": 1344858780000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.3429,
			"o": 22.3429,
			"c": 22.3429,
			"h": 22.3429,
			"l": 22.3429,
			"t": 1344858840000,
			"n": 1
		  },
		  {
			"v": 36400,
			"vw": 22.3459,
			"o": 22.3464,
			"c": 22.3411,
			"h": 22.3464,
			"l": 22.3411,
			"t": 1344858900000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.3411,
			"o": 22.3411,
			"c": 22.3411,
			"h": 22.3411,
			"l": 22.3411,
			"t": 1344858960000,
			"n": 1
		  },
		  {
			"v": 9100,
			"vw": 22.3342,
			"o": 22.3393,
			"c": 22.3261,
			"h": 22.3393,
			"l": 22.3261,
			"t": 1344859020000,
			"n": 3
		  },
		  {
			"v": 324604,
			"vw": 22.3184,
			"o": 22.3268,
			"c": 22.3214,
			"h": 22.3429,
			"l": 22.2632,
			"t": 1344859200000,
			"n": 74
		  },
		  {
			"v": 47152,
			"vw": 22.3263,
			"o": 22.3336,
			"c": 22.3236,
			"h": 22.3343,
			"l": 22.3214,
			"t": 1344859260000,
			"n": 12
		  },
		  {
			"v": 89936,
			"vw": 22.31,
			"o": 22.3143,
			"c": 22.3018,
			"h": 22.3157,
			"l": 22.3018,
			"t": 1344859320000,
			"n": 22
		  },
		  {
			"v": 12376,
			"vw": 22.3023,
			"o": 22.3018,
			"c": 22.3021,
			"h": 22.3036,
			"l": 22.3018,
			"t": 1344859380000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.2972,
			"o": 22.3014,
			"c": 22.2968,
			"h": 22.3014,
			"l": 22.2957,
			"t": 1344859440000,
			"n": 5
		  },
		  {
			"v": 24920,
			"vw": 22.3027,
			"o": 22.3,
			"c": 22.3,
			"h": 22.3118,
			"l": 22.3,
			"t": 1344859500000,
			"n": 7
		  },
		  {
			"v": 16800,
			"vw": 22.3039,
			"o": 22.305,
			"c": 22.3036,
			"h": 22.305,
			"l": 22.3029,
			"t": 1344859560000,
			"n": 6
		  },
		  {
			"v": 11200,
			"vw": 22.3021,
			"o": 22.2961,
			"c": 22.2979,
			"h": 22.3071,
			"l": 22.2961,
			"t": 1344859620000,
			"n": 3
		  },
		  {
			"v": 100128,
			"vw": 22.2892,
			"o": 22.2979,
			"c": 22.2889,
			"h": 22.2979,
			"l": 22.2857,
			"t": 1344859680000,
			"n": 22
		  },
		  {
			"v": 20020,
			"vw": 22.2949,
			"o": 22.2929,
			"c": 22.2932,
			"h": 22.2964,
			"l": 22.2929,
			"t": 1344859920000,
			"n": 5
		  },
		  {
			"v": 52500,
			"vw": 22.2989,
			"o": 22.3014,
			"c": 22.2993,
			"h": 22.3036,
			"l": 22.2939,
			"t": 1344859980000,
			"n": 11
		  },
		  {
			"v": 28000,
			"vw": 22.3021,
			"o": 22.3025,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3,
			"t": 1344860040000,
			"n": 8
		  },
		  {
			"v": 11200,
			"vw": 22.3047,
			"o": 22.3054,
			"c": 22.3043,
			"h": 22.3054,
			"l": 22.3039,
			"t": 1344860100000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.3032,
			"o": 22.3011,
			"c": 22.3036,
			"h": 22.3036,
			"l": 22.3011,
			"t": 1344860160000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.3039,
			"o": 22.3039,
			"c": 22.3039,
			"h": 22.3039,
			"l": 22.3039,
			"t": 1344860280000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2979,
			"o": 22.2979,
			"c": 22.2979,
			"h": 22.2979,
			"l": 22.2979,
			"t": 1344860340000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.2986,
			"o": 22.2986,
			"c": 22.2986,
			"h": 22.2986,
			"l": 22.2986,
			"t": 1344860400000,
			"n": 1
		  },
		  {
			"v": 89516,
			"vw": 22.2962,
			"o": 22.2979,
			"c": 22.3011,
			"h": 22.3011,
			"l": 22.2929,
			"t": 1344860460000,
			"n": 16
		  },
		  {
			"v": 2800,
			"vw": 22.2993,
			"o": 22.2993,
			"c": 22.2993,
			"h": 22.2993,
			"l": 22.2993,
			"t": 1344860520000,
			"n": 1
		  },
		  {
			"v": 74004,
			"vw": 22.288,
			"o": 22.2929,
			"c": 22.2857,
			"h": 22.2929,
			"l": 22.2857,
			"t": 1344860580000,
			"n": 18
		  },
		  {
			"v": 5600,
			"vw": 22.2787,
			"o": 22.2796,
			"c": 22.2779,
			"h": 22.2796,
			"l": 22.2779,
			"t": 1344860640000,
			"n": 2
		  },
		  {
			"v": 33712,
			"vw": 22.2879,
			"o": 22.2857,
			"c": 22.2904,
			"h": 22.2904,
			"l": 22.2857,
			"t": 1344860700000,
			"n": 7
		  },
		  {
			"v": 9156,
			"vw": 22.2784,
			"o": 22.2782,
			"c": 22.2796,
			"h": 22.2796,
			"l": 22.2775,
			"t": 1344860760000,
			"n": 3
		  },
		  {
			"v": 41244,
			"vw": 22.276,
			"o": 22.2796,
			"c": 22.2732,
			"h": 22.2796,
			"l": 22.2732,
			"t": 1344860820000,
			"n": 12
		  },
		  {
			"v": 74172,
			"vw": 22.2684,
			"o": 22.2682,
			"c": 22.2689,
			"h": 22.2732,
			"l": 22.2679,
			"t": 1344860880000,
			"n": 16
		  },
		  {
			"v": 42504,
			"vw": 22.2776,
			"o": 22.2721,
			"c": 22.2786,
			"h": 22.2786,
			"l": 22.2721,
			"t": 1344860940000,
			"n": 9
		  },
		  {
			"v": 5600,
			"vw": 22.283,
			"o": 22.2807,
			"c": 22.2854,
			"h": 22.2854,
			"l": 22.2807,
			"t": 1344861000000,
			"n": 2
		  },
		  {
			"v": 51072,
			"vw": 22.281,
			"o": 22.2857,
			"c": 22.2761,
			"h": 22.2857,
			"l": 22.2761,
			"t": 1344861060000,
			"n": 10
		  },
		  {
			"v": 5600,
			"vw": 22.2798,
			"o": 22.2786,
			"c": 22.2811,
			"h": 22.2811,
			"l": 22.2786,
			"t": 1344861180000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.2818,
			"o": 22.2811,
			"c": 22.2832,
			"h": 22.2832,
			"l": 22.2811,
			"t": 1344861240000,
			"n": 3
		  },
		  {
			"v": 40712,
			"vw": 22.2831,
			"o": 22.2789,
			"c": 22.2827,
			"h": 22.2857,
			"l": 22.2789,
			"t": 1344861300000,
			"n": 6
		  },
		  {
			"v": 28000,
			"vw": 22.2843,
			"o": 22.2829,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2829,
			"t": 1344861360000,
			"n": 5
		  },
		  {
			"v": 22400,
			"vw": 22.2813,
			"o": 22.2811,
			"c": 22.2821,
			"h": 22.2821,
			"l": 22.2804,
			"t": 1344861420000,
			"n": 6
		  },
		  {
			"v": 37800,
			"vw": 22.2817,
			"o": 22.2764,
			"c": 22.2814,
			"h": 22.2821,
			"l": 22.2764,
			"t": 1344861480000,
			"n": 3
		  },
		  {
			"v": 57540,
			"vw": 22.2815,
			"o": 22.2821,
			"c": 22.2814,
			"h": 22.2825,
			"l": 22.2768,
			"t": 1344861540000,
			"n": 10
		  },
		  {
			"v": 2800,
			"vw": 22.2821,
			"o": 22.2821,
			"c": 22.2821,
			"h": 22.2821,
			"l": 22.2821,
			"t": 1344861600000,
			"n": 1
		  },
		  {
			"v": 231952,
			"vw": 22.2867,
			"o": 22.2843,
			"c": 22.2871,
			"h": 22.2911,
			"l": 22.2839,
			"t": 1344861660000,
			"n": 42
		  },
		  {
			"v": 54656,
			"vw": 22.291,
			"o": 22.2871,
			"c": 22.2929,
			"h": 22.2929,
			"l": 22.2871,
			"t": 1344861720000,
			"n": 15
		  },
		  {
			"v": 9604,
			"vw": 22.2877,
			"o": 22.2925,
			"c": 22.2857,
			"h": 22.2925,
			"l": 22.2857,
			"t": 1344861780000,
			"n": 3
		  },
		  {
			"v": 9800,
			"vw": 22.285,
			"o": 22.285,
			"c": 22.285,
			"h": 22.285,
			"l": 22.285,
			"t": 1344861840000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.2886,
			"o": 22.2886,
			"c": 22.2886,
			"h": 22.2886,
			"l": 22.2886,
			"t": 1344861900000,
			"n": 2
		  },
		  {
			"v": 28000,
			"vw": 22.2858,
			"o": 22.2857,
			"c": 22.2861,
			"h": 22.2861,
			"l": 22.2857,
			"t": 1344861960000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2857,
			"o": 22.2857,
			"c": 22.2857,
			"h": 22.2857,
			"l": 22.2857,
			"t": 1344862020000,
			"n": 1
		  },
		  {
			"v": 22400,
			"vw": 22.2855,
			"o": 22.2857,
			"c": 22.2839,
			"h": 22.2857,
			"l": 22.2839,
			"t": 1344862140000,
			"n": 4
		  },
		  {
			"v": 47600,
			"vw": 22.2804,
			"o": 22.2807,
			"c": 22.2821,
			"h": 22.2821,
			"l": 22.2782,
			"t": 1344862200000,
			"n": 13
		  },
		  {
			"v": 44660,
			"vw": 22.2775,
			"o": 22.2789,
			"c": 22.2768,
			"h": 22.2789,
			"l": 22.2768,
			"t": 1344862260000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 22.278,
			"o": 22.2786,
			"c": 22.2768,
			"h": 22.2786,
			"l": 22.2768,
			"t": 1344862320000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.2777,
			"o": 22.2768,
			"c": 22.2786,
			"h": 22.2786,
			"l": 22.2768,
			"t": 1344862380000,
			"n": 2
		  },
		  {
			"v": 70168,
			"vw": 22.284,
			"o": 22.2779,
			"c": 22.2929,
			"h": 22.2929,
			"l": 22.2768,
			"t": 1344862440000,
			"n": 19
		  },
		  {
			"v": 84000,
			"vw": 22.2882,
			"o": 22.2929,
			"c": 22.2882,
			"h": 22.2936,
			"l": 22.2857,
			"t": 1344862500000,
			"n": 25
		  },
		  {
			"v": 24332,
			"vw": 22.2862,
			"o": 22.2861,
			"c": 22.2857,
			"h": 22.2868,
			"l": 22.2857,
			"t": 1344862560000,
			"n": 6
		  },
		  {
			"v": 25200,
			"vw": 22.2862,
			"o": 22.2857,
			"c": 22.2857,
			"h": 22.2864,
			"l": 22.2857,
			"t": 1344862620000,
			"n": 6
		  },
		  {
			"v": 21700,
			"vw": 22.2859,
			"o": 22.2861,
			"c": 22.2857,
			"h": 22.2864,
			"l": 22.2857,
			"t": 1344862680000,
			"n": 5
		  },
		  {
			"v": 28000,
			"vw": 22.286,
			"o": 22.2857,
			"c": 22.2889,
			"h": 22.2889,
			"l": 22.2857,
			"t": 1344862740000,
			"n": 8
		  },
		  {
			"v": 154000,
			"vw": 22.2902,
			"o": 22.2864,
			"c": 22.2975,
			"h": 22.2975,
			"l": 22.2857,
			"t": 1344862800000,
			"n": 28
		  },
		  {
			"v": 11200,
			"vw": 22.3011,
			"o": 22.3,
			"c": 22.3025,
			"h": 22.3025,
			"l": 22.2996,
			"t": 1344862860000,
			"n": 4
		  },
		  {
			"v": 27440,
			"vw": 22.2941,
			"o": 22.3004,
			"c": 22.2925,
			"h": 22.3004,
			"l": 22.2925,
			"t": 1344862920000,
			"n": 9
		  },
		  {
			"v": 133364,
			"vw": 22.2875,
			"o": 22.2925,
			"c": 22.2857,
			"h": 22.2961,
			"l": 22.2854,
			"t": 1344862980000,
			"n": 30
		  },
		  {
			"v": 84000,
			"vw": 22.2909,
			"o": 22.2893,
			"c": 22.2946,
			"h": 22.2946,
			"l": 22.2886,
			"t": 1344863040000,
			"n": 15
		  },
		  {
			"v": 72800,
			"vw": 22.2864,
			"o": 22.29,
			"c": 22.2843,
			"h": 22.29,
			"l": 22.2843,
			"t": 1344863100000,
			"n": 20
		  },
		  {
			"v": 25200,
			"vw": 22.2835,
			"o": 22.2814,
			"c": 22.2814,
			"h": 22.2857,
			"l": 22.2807,
			"t": 1344863160000,
			"n": 8
		  },
		  {
			"v": 2800,
			"vw": 22.285,
			"o": 22.285,
			"c": 22.285,
			"h": 22.285,
			"l": 22.285,
			"t": 1344863220000,
			"n": 1
		  },
		  {
			"v": 41076,
			"vw": 22.2869,
			"o": 22.2857,
			"c": 22.2886,
			"h": 22.2889,
			"l": 22.2843,
			"t": 1344863280000,
			"n": 10
		  },
		  {
			"v": 5600,
			"vw": 22.2871,
			"o": 22.2886,
			"c": 22.2857,
			"h": 22.2886,
			"l": 22.2857,
			"t": 1344863340000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.2882,
			"o": 22.2882,
			"c": 22.2882,
			"h": 22.2882,
			"l": 22.2882,
			"t": 1344863400000,
			"n": 1
		  },
		  {
			"v": 35896,
			"vw": 22.2881,
			"o": 22.2882,
			"c": 22.2882,
			"h": 22.2882,
			"l": 22.2879,
			"t": 1344863520000,
			"n": 12
		  },
		  {
			"v": 33600,
			"vw": 22.2814,
			"o": 22.2825,
			"c": 22.2789,
			"h": 22.2836,
			"l": 22.2789,
			"t": 1344863580000,
			"n": 11
		  },
		  {
			"v": 64400,
			"vw": 22.2776,
			"o": 22.2786,
			"c": 22.2768,
			"h": 22.2786,
			"l": 22.2768,
			"t": 1344863640000,
			"n": 18
		  },
		  {
			"v": 45528,
			"vw": 22.2771,
			"o": 22.2786,
			"c": 22.2768,
			"h": 22.2786,
			"l": 22.2768,
			"t": 1344863700000,
			"n": 8
		  },
		  {
			"v": 144312,
			"vw": 22.2752,
			"o": 22.2796,
			"c": 22.2789,
			"h": 22.2796,
			"l": 22.2671,
			"t": 1344863760000,
			"n": 26
		  },
		  {
			"v": 5600,
			"vw": 22.2813,
			"o": 22.2804,
			"c": 22.2821,
			"h": 22.2821,
			"l": 22.2804,
			"t": 1344863820000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.2832,
			"o": 22.2832,
			"c": 22.2832,
			"h": 22.2832,
			"l": 22.2832,
			"t": 1344863880000,
			"n": 2
		  },
		  {
			"v": 11200,
			"vw": 22.2832,
			"o": 22.2829,
			"c": 22.2832,
			"h": 22.2836,
			"l": 22.2829,
			"t": 1344863940000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.2827,
			"o": 22.2829,
			"c": 22.2825,
			"h": 22.2829,
			"l": 22.2825,
			"t": 1344864000000,
			"n": 2
		  },
		  {
			"v": 45360,
			"vw": 22.2789,
			"o": 22.2832,
			"c": 22.2793,
			"h": 22.2832,
			"l": 22.2786,
			"t": 1344864060000,
			"n": 4
		  },
		  {
			"v": 42000,
			"vw": 22.2853,
			"o": 22.2829,
			"c": 22.2857,
			"h": 22.2879,
			"l": 22.2829,
			"t": 1344864120000,
			"n": 14
		  },
		  {
			"v": 8400,
			"vw": 22.2895,
			"o": 22.2882,
			"c": 22.2904,
			"h": 22.2904,
			"l": 22.2882,
			"t": 1344864180000,
			"n": 3
		  },
		  {
			"v": 19460,
			"vw": 22.2924,
			"o": 22.2936,
			"c": 22.2914,
			"h": 22.2936,
			"l": 22.29,
			"t": 1344864240000,
			"n": 5
		  },
		  {
			"v": 33600,
			"vw": 22.2943,
			"o": 22.2929,
			"c": 22.2946,
			"h": 22.2946,
			"l": 22.2929,
			"t": 1344864360000,
			"n": 9
		  },
		  {
			"v": 30800,
			"vw": 22.2945,
			"o": 22.2946,
			"c": 22.2946,
			"h": 22.295,
			"l": 22.2936,
			"t": 1344864420000,
			"n": 8
		  },
		  {
			"v": 625016,
			"vw": 22.2803,
			"o": 22.295,
			"c": 22.2775,
			"h": 22.2961,
			"l": 22.2625,
			"t": 1344864480000,
			"n": 89
		  },
		  {
			"v": 170520,
			"vw": 22.275,
			"o": 22.2775,
			"c": 22.2679,
			"h": 22.2857,
			"l": 22.2664,
			"t": 1344864540000,
			"n": 30
		  },
		  {
			"v": 5196716,
			"vw": 22.2716,
			"o": 22.2639,
			"c": 22.2904,
			"h": 22.2985,
			"l": 22.2589,
			"t": 1344864600000,
			"n": 652
		  },
		  {
			"v": 2037588,
			"vw": 22.2863,
			"o": 22.2893,
			"c": 22.2858,
			"h": 22.2956,
			"l": 22.2729,
			"t": 1344864660000,
			"n": 543
		  },
		  {
			"v": 1456056,
			"vw": 22.2812,
			"o": 22.2882,
			"c": 22.2775,
			"h": 22.2921,
			"l": 22.2711,
			"t": 1344864720000,
			"n": 377
		  },
		  {
			"v": 3176768,
			"vw": 22.2995,
			"o": 22.2754,
			"c": 22.3136,
			"h": 22.3157,
			"l": 22.2746,
			"t": 1344864780000,
			"n": 833
		  },
		  {
			"v": 2473772,
			"vw": 22.3102,
			"o": 22.3154,
			"c": 22.305,
			"h": 22.3164,
			"l": 22.2986,
			"t": 1344864840000,
			"n": 542
		  },
		  {
			"v": 5137020,
			"vw": 22.3267,
			"o": 22.3032,
			"c": 22.3461,
			"h": 22.3468,
			"l": 22.3004,
			"t": 1344864900000,
			"n": 1118
		  },
		  {
			"v": 3898048,
			"vw": 22.3521,
			"o": 22.3443,
			"c": 22.3664,
			"h": 22.3696,
			"l": 22.3368,
			"t": 1344864960000,
			"n": 947
		  },
		  {
			"v": 3499720,
			"vw": 22.3654,
			"o": 22.365,
			"c": 22.3751,
			"h": 22.3779,
			"l": 22.3571,
			"t": 1344865020000,
			"n": 792
		  },
		  {
			"v": 3813012,
			"vw": 22.39,
			"o": 22.3779,
			"c": 22.3936,
			"h": 22.4021,
			"l": 22.3732,
			"t": 1344865080000,
			"n": 828
		  },
		  {
			"v": 2133712,
			"vw": 22.3955,
			"o": 22.3982,
			"c": 22.3936,
			"h": 22.4024,
			"l": 22.3857,
			"t": 1344865140000,
			"n": 481
		  },
		  {
			"v": 3137904,
			"vw": 22.4085,
			"o": 22.3936,
			"c": 22.423,
			"h": 22.4232,
			"l": 22.3886,
			"t": 1344865200000,
			"n": 729
		  },
		  {
			"v": 3141852,
			"vw": 22.4158,
			"o": 22.4232,
			"c": 22.4139,
			"h": 22.4286,
			"l": 22.4018,
			"t": 1344865260000,
			"n": 768
		  },
		  {
			"v": 1791496,
			"vw": 22.4227,
			"o": 22.4118,
			"c": 22.4286,
			"h": 22.4286,
			"l": 22.409,
			"t": 1344865320000,
			"n": 420
		  },
		  {
			"v": 2698108,
			"vw": 22.4263,
			"o": 22.4285,
			"c": 22.4257,
			"h": 22.4386,
			"l": 22.4096,
			"t": 1344865380000,
			"n": 604
		  },
		  {
			"v": 2109968,
			"vw": 22.4368,
			"o": 22.4236,
			"c": 22.4296,
			"h": 22.445,
			"l": 22.4236,
			"t": 1344865440000,
			"n": 481
		  },
		  {
			"v": 1887984,
			"vw": 22.4294,
			"o": 22.4286,
			"c": 22.4268,
			"h": 22.4375,
			"l": 22.4214,
			"t": 1344865500000,
			"n": 396
		  },
		  {
			"v": 1406888,
			"vw": 22.422,
			"o": 22.4279,
			"c": 22.4307,
			"h": 22.4307,
			"l": 22.4143,
			"t": 1344865560000,
			"n": 371
		  },
		  {
			"v": 2478196,
			"vw": 22.4209,
			"o": 22.4312,
			"c": 22.4225,
			"h": 22.4336,
			"l": 22.4154,
			"t": 1344865620000,
			"n": 508
		  },
		  {
			"v": 843304,
			"vw": 22.4209,
			"o": 22.4225,
			"c": 22.4236,
			"h": 22.4261,
			"l": 22.4157,
			"t": 1344865680000,
			"n": 201
		  },
		  {
			"v": 1367380,
			"vw": 22.4226,
			"o": 22.4232,
			"c": 22.4189,
			"h": 22.4286,
			"l": 22.4161,
			"t": 1344865740000,
			"n": 310
		  },
		  {
			"v": 1396752,
			"vw": 22.4279,
			"o": 22.4246,
			"c": 22.4189,
			"h": 22.435,
			"l": 22.4176,
			"t": 1344865800000,
			"n": 316
		  },
		  {
			"v": 1495340,
			"vw": 22.422,
			"o": 22.42,
			"c": 22.42,
			"h": 22.4307,
			"l": 22.4154,
			"t": 1344865860000,
			"n": 330
		  },
		  {
			"v": 882112,
			"vw": 22.4195,
			"o": 22.42,
			"c": 22.4239,
			"h": 22.4264,
			"l": 22.4143,
			"t": 1344865920000,
			"n": 233
		  },
		  {
			"v": 1607060,
			"vw": 22.4286,
			"o": 22.4239,
			"c": 22.4308,
			"h": 22.4329,
			"l": 22.4229,
			"t": 1344865980000,
			"n": 338
		  },
		  {
			"v": 1372280,
			"vw": 22.4208,
			"o": 22.4329,
			"c": 22.4179,
			"h": 22.4329,
			"l": 22.4154,
			"t": 1344866040000,
			"n": 341
		  },
		  {
			"v": 4457320,
			"vw": 22.4082,
			"o": 22.4168,
			"c": 22.4029,
			"h": 22.4177,
			"l": 22.3957,
			"t": 1344866100000,
			"n": 561
		  },
		  {
			"v": 1695064,
			"vw": 22.3987,
			"o": 22.4054,
			"c": 22.3871,
			"h": 22.4146,
			"l": 22.3857,
			"t": 1344866160000,
			"n": 357
		  },
		  {
			"v": 1069236,
			"vw": 22.3948,
			"o": 22.3871,
			"c": 22.3975,
			"h": 22.4018,
			"l": 22.3871,
			"t": 1344866220000,
			"n": 239
		  },
		  {
			"v": 1402968,
			"vw": 22.3858,
			"o": 22.3957,
			"c": 22.3844,
			"h": 22.3986,
			"l": 22.379,
			"t": 1344866280000,
			"n": 341
		  },
		  {
			"v": 1217608,
			"vw": 22.3797,
			"o": 22.3844,
			"c": 22.3799,
			"h": 22.385,
			"l": 22.375,
			"t": 1344866340000,
			"n": 330
		  },
		  {
			"v": 1471764,
			"vw": 22.3979,
			"o": 22.3769,
			"c": 22.4111,
			"h": 22.4157,
			"l": 22.3769,
			"t": 1344866400000,
			"n": 354
		  },
		  {
			"v": 831404,
			"vw": 22.4089,
			"o": 22.4157,
			"c": 22.4136,
			"h": 22.4189,
			"l": 22.4018,
			"t": 1344866460000,
			"n": 187
		  },
		  {
			"v": 595252,
			"vw": 22.4047,
			"o": 22.4093,
			"c": 22.4025,
			"h": 22.4129,
			"l": 22.3991,
			"t": 1344866520000,
			"n": 152
		  },
		  {
			"v": 512932,
			"vw": 22.4024,
			"o": 22.4004,
			"c": 22.4032,
			"h": 22.4057,
			"l": 22.3971,
			"t": 1344866580000,
			"n": 138
		  },
		  {
			"v": 631568,
			"vw": 22.4088,
			"o": 22.405,
			"c": 22.4175,
			"h": 22.4186,
			"l": 22.4,
			"t": 1344866640000,
			"n": 160
		  },
		  {
			"v": 920668,
			"vw": 22.4085,
			"o": 22.4157,
			"c": 22.4104,
			"h": 22.4179,
			"l": 22.4004,
			"t": 1344866700000,
			"n": 196
		  },
		  {
			"v": 583296,
			"vw": 22.411,
			"o": 22.4092,
			"c": 22.41,
			"h": 22.4171,
			"l": 22.4064,
			"t": 1344866760000,
			"n": 132
		  },
		  {
			"v": 857052,
			"vw": 22.4093,
			"o": 22.4096,
			"c": 22.4079,
			"h": 22.4171,
			"l": 22.4032,
			"t": 1344866820000,
			"n": 233
		  },
		  {
			"v": 609672,
			"vw": 22.4061,
			"o": 22.4086,
			"c": 22.4068,
			"h": 22.4107,
			"l": 22.4007,
			"t": 1344866880000,
			"n": 161
		  },
		  {
			"v": 447748,
			"vw": 22.4022,
			"o": 22.4036,
			"c": 22.3993,
			"h": 22.4064,
			"l": 22.3979,
			"t": 1344866940000,
			"n": 122
		  },
		  {
			"v": 1394372,
			"vw": 22.3869,
			"o": 22.3996,
			"c": 22.3771,
			"h": 22.3996,
			"l": 22.3757,
			"t": 1344867000000,
			"n": 319
		  },
		  {
			"v": 1632596,
			"vw": 22.3732,
			"o": 22.3771,
			"c": 22.3686,
			"h": 22.3804,
			"l": 22.3668,
			"t": 1344867060000,
			"n": 385
		  },
		  {
			"v": 738136,
			"vw": 22.3704,
			"o": 22.3686,
			"c": 22.3683,
			"h": 22.3771,
			"l": 22.3647,
			"t": 1344867120000,
			"n": 175
		  },
		  {
			"v": 866432,
			"vw": 22.3758,
			"o": 22.37,
			"c": 22.3732,
			"h": 22.3839,
			"l": 22.3679,
			"t": 1344867180000,
			"n": 196
		  },
		  {
			"v": 1152032,
			"vw": 22.368,
			"o": 22.3711,
			"c": 22.3714,
			"h": 22.375,
			"l": 22.3618,
			"t": 1344867240000,
			"n": 261
		  },
		  {
			"v": 2159864,
			"vw": 22.3598,
			"o": 22.3718,
			"c": 22.3525,
			"h": 22.3725,
			"l": 22.3493,
			"t": 1344867300000,
			"n": 446
		  },
		  {
			"v": 1813364,
			"vw": 22.3516,
			"o": 22.3504,
			"c": 22.3579,
			"h": 22.3625,
			"l": 22.3429,
			"t": 1344867360000,
			"n": 419
		  },
		  {
			"v": 1098496,
			"vw": 22.3659,
			"o": 22.3589,
			"c": 22.3686,
			"h": 22.3721,
			"l": 22.3586,
			"t": 1344867420000,
			"n": 240
		  },
		  {
			"v": 772352,
			"vw": 22.3681,
			"o": 22.37,
			"c": 22.3711,
			"h": 22.3725,
			"l": 22.3608,
			"t": 1344867480000,
			"n": 135
		  },
		  {
			"v": 888972,
			"vw": 22.3783,
			"o": 22.3707,
			"c": 22.382,
			"h": 22.3839,
			"l": 22.3707,
			"t": 1344867540000,
			"n": 186
		  },
		  {
			"v": 738444,
			"vw": 22.3764,
			"o": 22.3824,
			"c": 22.37,
			"h": 22.3857,
			"l": 22.3693,
			"t": 1344867600000,
			"n": 174
		  },
		  {
			"v": 801472,
			"vw": 22.3692,
			"o": 22.37,
			"c": 22.3711,
			"h": 22.3764,
			"l": 22.362,
			"t": 1344867660000,
			"n": 207
		  },
		  {
			"v": 530824,
			"vw": 22.3754,
			"o": 22.3732,
			"c": 22.3804,
			"h": 22.3832,
			"l": 22.3682,
			"t": 1344867720000,
			"n": 130
		  },
		  {
			"v": 800492,
			"vw": 22.3809,
			"o": 22.3807,
			"c": 22.3771,
			"h": 22.3868,
			"l": 22.3768,
			"t": 1344867780000,
			"n": 162
		  },
		  {
			"v": 537432,
			"vw": 22.3714,
			"o": 22.3779,
			"c": 22.3643,
			"h": 22.3807,
			"l": 22.3614,
			"t": 1344867840000,
			"n": 131
		  },
		  {
			"v": 971152,
			"vw": 22.3591,
			"o": 22.3625,
			"c": 22.3564,
			"h": 22.3661,
			"l": 22.355,
			"t": 1344867900000,
			"n": 206
		  },
		  {
			"v": 1023092,
			"vw": 22.3652,
			"o": 22.3543,
			"c": 22.3746,
			"h": 22.3779,
			"l": 22.3532,
			"t": 1344867960000,
			"n": 190
		  },
		  {
			"v": 2477412,
			"vw": 22.3899,
			"o": 22.3779,
			"c": 22.3979,
			"h": 22.4014,
			"l": 22.3614,
			"t": 1344868020000,
			"n": 416
		  },
		  {
			"v": 629748,
			"vw": 22.3983,
			"o": 22.3975,
			"c": 22.4011,
			"h": 22.4011,
			"l": 22.3936,
			"t": 1344868080000,
			"n": 119
		  },
		  {
			"v": 893452,
			"vw": 22.4048,
			"o": 22.4011,
			"c": 22.4036,
			"h": 22.41,
			"l": 22.3993,
			"t": 1344868140000,
			"n": 196
		  },
		  {
			"v": 1067780,
			"vw": 22.3926,
			"o": 22.4036,
			"c": 22.3914,
			"h": 22.4036,
			"l": 22.3807,
			"t": 1344868200000,
			"n": 274
		  },
		  {
			"v": 420980,
			"vw": 22.3885,
			"o": 22.3871,
			"c": 22.3879,
			"h": 22.3918,
			"l": 22.3839,
			"t": 1344868260000,
			"n": 82
		  },
		  {
			"v": 353836,
			"vw": 22.3863,
			"o": 22.3861,
			"c": 22.3914,
			"h": 22.395,
			"l": 22.3804,
			"t": 1344868320000,
			"n": 95
		  },
		  {
			"v": 405188,
			"vw": 22.3841,
			"o": 22.3914,
			"c": 22.3836,
			"h": 22.3914,
			"l": 22.3779,
			"t": 1344868380000,
			"n": 107
		  },
		  {
			"v": 832748,
			"vw": 22.3844,
			"o": 22.3815,
			"c": 22.3875,
			"h": 22.3921,
			"l": 22.38,
			"t": 1344868440000,
			"n": 141
		  },
		  {
			"v": 345268,
			"vw": 22.3863,
			"o": 22.3911,
			"c": 22.3796,
			"h": 22.3929,
			"l": 22.3786,
			"t": 1344868500000,
			"n": 75
		  },
		  {
			"v": 722596,
			"vw": 22.3915,
			"o": 22.3796,
			"c": 22.399,
			"h": 22.3993,
			"l": 22.3796,
			"t": 1344868560000,
			"n": 123
		  },
		  {
			"v": 461832,
			"vw": 22.3925,
			"o": 22.3957,
			"c": 22.3886,
			"h": 22.3982,
			"l": 22.3861,
			"t": 1344868620000,
			"n": 115
		  },
		  {
			"v": 319480,
			"vw": 22.3837,
			"o": 22.3882,
			"c": 22.3796,
			"h": 22.3882,
			"l": 22.3796,
			"t": 1344868680000,
			"n": 72
		  },
		  {
			"v": 433104,
			"vw": 22.3779,
			"o": 22.3814,
			"c": 22.3811,
			"h": 22.385,
			"l": 22.375,
			"t": 1344868740000,
			"n": 107
		  },
		  {
			"v": 453320,
			"vw": 22.3903,
			"o": 22.3807,
			"c": 22.3912,
			"h": 22.3954,
			"l": 22.3807,
			"t": 1344868800000,
			"n": 104
		  },
		  {
			"v": 446040,
			"vw": 22.3867,
			"o": 22.3907,
			"c": 22.3857,
			"h": 22.3925,
			"l": 22.3821,
			"t": 1344868860000,
			"n": 95
		  },
		  {
			"v": 628264,
			"vw": 22.3905,
			"o": 22.3879,
			"c": 22.3946,
			"h": 22.3979,
			"l": 22.3832,
			"t": 1344868920000,
			"n": 159
		  },
		  {
			"v": 1381380,
			"vw": 22.4058,
			"o": 22.3971,
			"c": 22.4064,
			"h": 22.4129,
			"l": 22.3939,
			"t": 1344868980000,
			"n": 269
		  },
		  {
			"v": 926828,
			"vw": 22.4101,
			"o": 22.4086,
			"c": 22.4157,
			"h": 22.4168,
			"l": 22.3993,
			"t": 1344869040000,
			"n": 172
		  },
		  {
			"v": 961828,
			"vw": 22.4157,
			"o": 22.4146,
			"c": 22.4186,
			"h": 22.4207,
			"l": 22.4121,
			"t": 1344869100000,
			"n": 243
		  },
		  {
			"v": 759472,
			"vw": 22.4166,
			"o": 22.4179,
			"c": 22.4071,
			"h": 22.4232,
			"l": 22.4068,
			"t": 1344869160000,
			"n": 161
		  },
		  {
			"v": 920276,
			"vw": 22.4007,
			"o": 22.4132,
			"c": 22.4001,
			"h": 22.4132,
			"l": 22.3929,
			"t": 1344869220000,
			"n": 168
		  },
		  {
			"v": 225008,
			"vw": 22.4078,
			"o": 22.4021,
			"c": 22.4129,
			"h": 22.4153,
			"l": 22.4021,
			"t": 1344869280000,
			"n": 56
		  },
		  {
			"v": 723604,
			"vw": 22.4174,
			"o": 22.4114,
			"c": 22.4157,
			"h": 22.4232,
			"l": 22.4107,
			"t": 1344869340000,
			"n": 148
		  },
		  {
			"v": 491764,
			"vw": 22.4128,
			"o": 22.4166,
			"c": 22.4059,
			"h": 22.4221,
			"l": 22.4054,
			"t": 1344869400000,
			"n": 114
		  },
		  {
			"v": 858648,
			"vw": 22.3978,
			"o": 22.4082,
			"c": 22.4043,
			"h": 22.4082,
			"l": 22.3857,
			"t": 1344869460000,
			"n": 191
		  },
		  {
			"v": 515788,
			"vw": 22.4083,
			"o": 22.4057,
			"c": 22.411,
			"h": 22.415,
			"l": 22.4018,
			"t": 1344869520000,
			"n": 102
		  },
		  {
			"v": 594496,
			"vw": 22.4072,
			"o": 22.4107,
			"c": 22.4082,
			"h": 22.4136,
			"l": 22.4011,
			"t": 1344869580000,
			"n": 144
		  },
		  {
			"v": 818776,
			"vw": 22.4037,
			"o": 22.4068,
			"c": 22.3985,
			"h": 22.4107,
			"l": 22.3946,
			"t": 1344869640000,
			"n": 141
		  },
		  {
			"v": 468888,
			"vw": 22.3997,
			"o": 22.3982,
			"c": 22.4014,
			"h": 22.4035,
			"l": 22.3946,
			"t": 1344869700000,
			"n": 107
		  },
		  {
			"v": 225148,
			"vw": 22.4015,
			"o": 22.4014,
			"c": 22.4011,
			"h": 22.4029,
			"l": 22.3971,
			"t": 1344869760000,
			"n": 56
		  },
		  {
			"v": 1002764,
			"vw": 22.3886,
			"o": 22.3989,
			"c": 22.3875,
			"h": 22.3989,
			"l": 22.3821,
			"t": 1344869820000,
			"n": 214
		  },
		  {
			"v": 1232252,
			"vw": 22.3752,
			"o": 22.3875,
			"c": 22.3776,
			"h": 22.3875,
			"l": 22.3679,
			"t": 1344869880000,
			"n": 286
		  },
		  {
			"v": 416724,
			"vw": 22.379,
			"o": 22.3755,
			"c": 22.3804,
			"h": 22.3821,
			"l": 22.3732,
			"t": 1344869940000,
			"n": 99
		  },
		  {
			"v": 813288,
			"vw": 22.3904,
			"o": 22.3807,
			"c": 22.3928,
			"h": 22.3964,
			"l": 22.3807,
			"t": 1344870000000,
			"n": 194
		  },
		  {
			"v": 779772,
			"vw": 22.3915,
			"o": 22.3894,
			"c": 22.3918,
			"h": 22.3964,
			"l": 22.3893,
			"t": 1344870060000,
			"n": 131
		  },
		  {
			"v": 948836,
			"vw": 22.3809,
			"o": 22.3911,
			"c": 22.3729,
			"h": 22.3921,
			"l": 22.3714,
			"t": 1344870120000,
			"n": 232
		  },
		  {
			"v": 560084,
			"vw": 22.384,
			"o": 22.3775,
			"c": 22.3789,
			"h": 22.3929,
			"l": 22.3754,
			"t": 1344870180000,
			"n": 157
		  },
		  {
			"v": 539336,
			"vw": 22.3726,
			"o": 22.38,
			"c": 22.3661,
			"h": 22.3825,
			"l": 22.3661,
			"t": 1344870240000,
			"n": 127
		  },
		  {
			"v": 544908,
			"vw": 22.3694,
			"o": 22.3668,
			"c": 22.3743,
			"h": 22.3768,
			"l": 22.3636,
			"t": 1344870300000,
			"n": 132
		  },
		  {
			"v": 500752,
			"vw": 22.3679,
			"o": 22.3743,
			"c": 22.3728,
			"h": 22.3775,
			"l": 22.3625,
			"t": 1344870360000,
			"n": 135
		  },
		  {
			"v": 485016,
			"vw": 22.3735,
			"o": 22.3723,
			"c": 22.3743,
			"h": 22.3814,
			"l": 22.3679,
			"t": 1344870420000,
			"n": 98
		  },
		  {
			"v": 299264,
			"vw": 22.3679,
			"o": 22.3686,
			"c": 22.3704,
			"h": 22.371,
			"l": 22.3636,
			"t": 1344870480000,
			"n": 70
		  },
		  {
			"v": 413616,
			"vw": 22.3719,
			"o": 22.3668,
			"c": 22.3781,
			"h": 22.3786,
			"l": 22.364,
			"t": 1344870540000,
			"n": 73
		  },
		  {
			"v": 439712,
			"vw": 22.3744,
			"o": 22.3786,
			"c": 22.3707,
			"h": 22.3825,
			"l": 22.3679,
			"t": 1344870600000,
			"n": 93
		  },
		  {
			"v": 260400,
			"vw": 22.3696,
			"o": 22.3707,
			"c": 22.367,
			"h": 22.3739,
			"l": 22.3643,
			"t": 1344870660000,
			"n": 64
		  },
		  {
			"v": 324968,
			"vw": 22.3768,
			"o": 22.3656,
			"c": 22.377,
			"h": 22.3829,
			"l": 22.3656,
			"t": 1344870720000,
			"n": 68
		  },
		  {
			"v": 235732,
			"vw": 22.3785,
			"o": 22.3796,
			"c": 22.3775,
			"h": 22.3814,
			"l": 22.3758,
			"t": 1344870780000,
			"n": 54
		  },
		  {
			"v": 175560,
			"vw": 22.38,
			"o": 22.3775,
			"c": 22.3786,
			"h": 22.3828,
			"l": 22.3771,
			"t": 1344870840000,
			"n": 46
		  },
		  {
			"v": 309232,
			"vw": 22.3737,
			"o": 22.375,
			"c": 22.37,
			"h": 22.3782,
			"l": 22.3679,
			"t": 1344870900000,
			"n": 83
		  },
		  {
			"v": 348740,
			"vw": 22.3742,
			"o": 22.3682,
			"c": 22.3804,
			"h": 22.3804,
			"l": 22.3679,
			"t": 1344870960000,
			"n": 88
		  },
		  {
			"v": 387772,
			"vw": 22.3787,
			"o": 22.3804,
			"c": 22.3779,
			"h": 22.3825,
			"l": 22.3718,
			"t": 1344871020000,
			"n": 62
		  },
		  {
			"v": 518448,
			"vw": 22.3689,
			"o": 22.3771,
			"c": 22.3625,
			"h": 22.3807,
			"l": 22.3625,
			"t": 1344871080000,
			"n": 133
		  },
		  {
			"v": 641088,
			"vw": 22.3639,
			"o": 22.3618,
			"c": 22.3729,
			"h": 22.3729,
			"l": 22.3607,
			"t": 1344871140000,
			"n": 124
		  },
		  {
			"v": 420644,
			"vw": 22.3679,
			"o": 22.3746,
			"c": 22.3654,
			"h": 22.3768,
			"l": 22.3632,
			"t": 1344871200000,
			"n": 104
		  },
		  {
			"v": 304976,
			"vw": 22.3766,
			"o": 22.3687,
			"c": 22.3836,
			"h": 22.3836,
			"l": 22.3687,
			"t": 1344871260000,
			"n": 89
		  },
		  {
			"v": 317884,
			"vw": 22.3866,
			"o": 22.3836,
			"c": 22.387,
			"h": 22.3907,
			"l": 22.3807,
			"t": 1344871320000,
			"n": 65
		  },
		  {
			"v": 802732,
			"vw": 22.3953,
			"o": 22.3889,
			"c": 22.3989,
			"h": 22.3989,
			"l": 22.3864,
			"t": 1344871380000,
			"n": 135
		  },
		  {
			"v": 329812,
			"vw": 22.3937,
			"o": 22.3979,
			"c": 22.3957,
			"h": 22.3989,
			"l": 22.3904,
			"t": 1344871440000,
			"n": 84
		  },
		  {
			"v": 507248,
			"vw": 22.3976,
			"o": 22.3964,
			"c": 22.4018,
			"h": 22.4025,
			"l": 22.3921,
			"t": 1344871500000,
			"n": 103
		  },
		  {
			"v": 513660,
			"vw": 22.3969,
			"o": 22.4014,
			"c": 22.3882,
			"h": 22.4025,
			"l": 22.3858,
			"t": 1344871560000,
			"n": 121
		  },
		  {
			"v": 506744,
			"vw": 22.3897,
			"o": 22.3904,
			"c": 22.3964,
			"h": 22.3975,
			"l": 22.3832,
			"t": 1344871620000,
			"n": 112
		  },
		  {
			"v": 565656,
			"vw": 22.3987,
			"o": 22.3932,
			"c": 22.3982,
			"h": 22.4036,
			"l": 22.3929,
			"t": 1344871680000,
			"n": 126
		  },
		  {
			"v": 875112,
			"vw": 22.3958,
			"o": 22.3994,
			"c": 22.3861,
			"h": 22.4064,
			"l": 22.3821,
			"t": 1344871740000,
			"n": 176
		  },
		  {
			"v": 151172,
			"vw": 22.3868,
			"o": 22.3825,
			"c": 22.39,
			"h": 22.3921,
			"l": 22.3804,
			"t": 1344871800000,
			"n": 45
		  },
		  {
			"v": 188944,
			"vw": 22.3868,
			"o": 22.3902,
			"c": 22.3904,
			"h": 22.3907,
			"l": 22.3821,
			"t": 1344871860000,
			"n": 49
		  },
		  {
			"v": 228004,
			"vw": 22.3857,
			"o": 22.3906,
			"c": 22.3836,
			"h": 22.3906,
			"l": 22.3821,
			"t": 1344871920000,
			"n": 52
		  },
		  {
			"v": 117992,
			"vw": 22.3836,
			"o": 22.3832,
			"c": 22.3857,
			"h": 22.3864,
			"l": 22.3821,
			"t": 1344871980000,
			"n": 23
		  },
		  {
			"v": 206500,
			"vw": 22.3872,
			"o": 22.3857,
			"c": 22.3807,
			"h": 22.3921,
			"l": 22.3807,
			"t": 1344872040000,
			"n": 49
		  },
		  {
			"v": 829948,
			"vw": 22.3614,
			"o": 22.3804,
			"c": 22.3865,
			"h": 22.3886,
			"l": 22.3401,
			"t": 1344872100000,
			"n": 93
		  },
		  {
			"v": 683676,
			"vw": 22.3932,
			"o": 22.3868,
			"c": 22.4018,
			"h": 22.4036,
			"l": 22.385,
			"t": 1344872160000,
			"n": 129
		  },
		  {
			"v": 329532,
			"vw": 22.3964,
			"o": 22.4036,
			"c": 22.3929,
			"h": 22.4036,
			"l": 22.3929,
			"t": 1344872220000,
			"n": 99
		  },
		  {
			"v": 373520,
			"vw": 22.3893,
			"o": 22.3936,
			"c": 22.3879,
			"h": 22.3986,
			"l": 22.3875,
			"t": 1344872280000,
			"n": 72
		  },
		  {
			"v": 121100,
			"vw": 22.3945,
			"o": 22.3911,
			"c": 22.4,
			"h": 22.4,
			"l": 22.3896,
			"t": 1344872340000,
			"n": 25
		  },
		  {
			"v": 189420,
			"vw": 22.3967,
			"o": 22.3971,
			"c": 22.3918,
			"h": 22.4,
			"l": 22.3896,
			"t": 1344872400000,
			"n": 56
		  },
		  {
			"v": 140000,
			"vw": 22.3932,
			"o": 22.3911,
			"c": 22.3961,
			"h": 22.3979,
			"l": 22.3886,
			"t": 1344872460000,
			"n": 46
		  },
		  {
			"v": 300972,
			"vw": 22.3938,
			"o": 22.3968,
			"c": 22.3913,
			"h": 22.3986,
			"l": 22.3893,
			"t": 1344872520000,
			"n": 64
		  },
		  {
			"v": 318500,
			"vw": 22.3865,
			"o": 22.3936,
			"c": 22.3909,
			"h": 22.3936,
			"l": 22.3821,
			"t": 1344872580000,
			"n": 56
		  },
		  {
			"v": 129220,
			"vw": 22.3897,
			"o": 22.3875,
			"c": 22.3929,
			"h": 22.3929,
			"l": 22.3857,
			"t": 1344872640000,
			"n": 38
		  },
		  {
			"v": 233940,
			"vw": 22.3946,
			"o": 22.3918,
			"c": 22.3929,
			"h": 22.3982,
			"l": 22.39,
			"t": 1344872700000,
			"n": 54
		  },
		  {
			"v": 131656,
			"vw": 22.3927,
			"o": 22.3951,
			"c": 22.3925,
			"h": 22.3954,
			"l": 22.3879,
			"t": 1344872760000,
			"n": 38
		  },
		  {
			"v": 298228,
			"vw": 22.3967,
			"o": 22.3921,
			"c": 22.3993,
			"h": 22.3996,
			"l": 22.3896,
			"t": 1344872820000,
			"n": 61
		  },
		  {
			"v": 200508,
			"vw": 22.3997,
			"o": 22.3968,
			"c": 22.3947,
			"h": 22.4025,
			"l": 22.3946,
			"t": 1344872880000,
			"n": 61
		  },
		  {
			"v": 98196,
			"vw": 22.3952,
			"o": 22.3986,
			"c": 22.3968,
			"h": 22.3986,
			"l": 22.3914,
			"t": 1344872940000,
			"n": 24
		  },
		  {
			"v": 36400,
			"vw": 22.3939,
			"o": 22.3961,
			"c": 22.3929,
			"h": 22.3964,
			"l": 22.3921,
			"t": 1344873000000,
			"n": 11
		  },
		  {
			"v": 168168,
			"vw": 22.3927,
			"o": 22.3932,
			"c": 22.3929,
			"h": 22.3949,
			"l": 22.3896,
			"t": 1344873060000,
			"n": 44
		  },
		  {
			"v": 297332,
			"vw": 22.3895,
			"o": 22.3925,
			"c": 22.3879,
			"h": 22.3943,
			"l": 22.3846,
			"t": 1344873120000,
			"n": 65
		  },
		  {
			"v": 276724,
			"vw": 22.3969,
			"o": 22.3912,
			"c": 22.4018,
			"h": 22.4025,
			"l": 22.3907,
			"t": 1344873180000,
			"n": 68
		  },
		  {
			"v": 939624,
			"vw": 22.4074,
			"o": 22.4029,
			"c": 22.4107,
			"h": 22.4107,
			"l": 22.4029,
			"t": 1344873240000,
			"n": 216
		  },
		  {
			"v": 496664,
			"vw": 22.4106,
			"o": 22.4107,
			"c": 22.4121,
			"h": 22.4143,
			"l": 22.4068,
			"t": 1344873300000,
			"n": 110
		  },
		  {
			"v": 447160,
			"vw": 22.4096,
			"o": 22.4121,
			"c": 22.4036,
			"h": 22.4132,
			"l": 22.4025,
			"t": 1344873360000,
			"n": 121
		  },
		  {
			"v": 306180,
			"vw": 22.4096,
			"o": 22.4071,
			"c": 22.4093,
			"h": 22.4139,
			"l": 22.4036,
			"t": 1344873420000,
			"n": 68
		  },
		  {
			"v": 366268,
			"vw": 22.4108,
			"o": 22.41,
			"c": 22.4029,
			"h": 22.4143,
			"l": 22.4029,
			"t": 1344873480000,
			"n": 90
		  },
		  {
			"v": 343952,
			"vw": 22.3996,
			"o": 22.4025,
			"c": 22.4018,
			"h": 22.4071,
			"l": 22.3939,
			"t": 1344873540000,
			"n": 90
		  },
		  {
			"v": 365484,
			"vw": 22.4026,
			"o": 22.4018,
			"c": 22.4011,
			"h": 22.4071,
			"l": 22.3957,
			"t": 1344873600000,
			"n": 81
		  },
		  {
			"v": 46704,
			"vw": 22.3988,
			"o": 22.4004,
			"c": 22.3996,
			"h": 22.4024,
			"l": 22.3968,
			"t": 1344873660000,
			"n": 13
		  },
		  {
			"v": 601328,
			"vw": 22.3914,
			"o": 22.3993,
			"c": 22.3936,
			"h": 22.3993,
			"l": 22.3829,
			"t": 1344873720000,
			"n": 147
		  },
		  {
			"v": 358820,
			"vw": 22.3943,
			"o": 22.3929,
			"c": 22.3989,
			"h": 22.4018,
			"l": 22.3889,
			"t": 1344873780000,
			"n": 73
		  },
		  {
			"v": 169120,
			"vw": 22.3931,
			"o": 22.3986,
			"c": 22.3857,
			"h": 22.3989,
			"l": 22.3857,
			"t": 1344873840000,
			"n": 47
		  },
		  {
			"v": 443856,
			"vw": 22.3858,
			"o": 22.3857,
			"c": 22.3893,
			"h": 22.3907,
			"l": 22.3804,
			"t": 1344873900000,
			"n": 92
		  },
		  {
			"v": 93100,
			"vw": 22.3899,
			"o": 22.39,
			"c": 22.3908,
			"h": 22.3925,
			"l": 22.3877,
			"t": 1344873960000,
			"n": 21
		  },
		  {
			"v": 169484,
			"vw": 22.3907,
			"o": 22.3907,
			"c": 22.3894,
			"h": 22.3929,
			"l": 22.3882,
			"t": 1344874020000,
			"n": 42
		  },
		  {
			"v": 298676,
			"vw": 22.3889,
			"o": 22.3921,
			"c": 22.3821,
			"h": 22.3929,
			"l": 22.3821,
			"t": 1344874080000,
			"n": 81
		  },
		  {
			"v": 503300,
			"vw": 22.3821,
			"o": 22.3829,
			"c": 22.3861,
			"h": 22.3864,
			"l": 22.3804,
			"t": 1344874140000,
			"n": 101
		  },
		  {
			"v": 188300,
			"vw": 22.3892,
			"o": 22.3861,
			"c": 22.3893,
			"h": 22.3925,
			"l": 22.3846,
			"t": 1344874200000,
			"n": 61
		  },
		  {
			"v": 111860,
			"vw": 22.3901,
			"o": 22.3907,
			"c": 22.3889,
			"h": 22.3921,
			"l": 22.3868,
			"t": 1344874260000,
			"n": 27
		  },
		  {
			"v": 107268,
			"vw": 22.39,
			"o": 22.3907,
			"c": 22.3911,
			"h": 22.3921,
			"l": 22.3872,
			"t": 1344874320000,
			"n": 27
		  },
		  {
			"v": 323512,
			"vw": 22.3916,
			"o": 22.39,
			"c": 22.395,
			"h": 22.395,
			"l": 22.3868,
			"t": 1344874380000,
			"n": 83
		  },
		  {
			"v": 384468,
			"vw": 22.3973,
			"o": 22.395,
			"c": 22.3941,
			"h": 22.4,
			"l": 22.3918,
			"t": 1344874440000,
			"n": 99
		  },
		  {
			"v": 794192,
			"vw": 22.4016,
			"o": 22.3946,
			"c": 22.4046,
			"h": 22.4086,
			"l": 22.3921,
			"t": 1344874500000,
			"n": 199
		  },
		  {
			"v": 305956,
			"vw": 22.4048,
			"o": 22.4068,
			"c": 22.4021,
			"h": 22.4071,
			"l": 22.4014,
			"t": 1344874560000,
			"n": 72
		  },
		  {
			"v": 173852,
			"vw": 22.4019,
			"o": 22.4032,
			"c": 22.3995,
			"h": 22.4046,
			"l": 22.3982,
			"t": 1344874620000,
			"n": 49
		  },
		  {
			"v": 163548,
			"vw": 22.4027,
			"o": 22.4,
			"c": 22.4018,
			"h": 22.4043,
			"l": 22.4,
			"t": 1344874680000,
			"n": 45
		  },
		  {
			"v": 130172,
			"vw": 22.4007,
			"o": 22.4029,
			"c": 22.4018,
			"h": 22.4036,
			"l": 22.3982,
			"t": 1344874740000,
			"n": 35
		  },
		  {
			"v": 44800,
			"vw": 22.4014,
			"o": 22.4018,
			"c": 22.4029,
			"h": 22.4032,
			"l": 22.4,
			"t": 1344874800000,
			"n": 14
		  },
		  {
			"v": 383516,
			"vw": 22.4078,
			"o": 22.4032,
			"c": 22.4036,
			"h": 22.4107,
			"l": 22.4029,
			"t": 1344874860000,
			"n": 82
		  },
		  {
			"v": 125944,
			"vw": 22.4071,
			"o": 22.4071,
			"c": 22.4054,
			"h": 22.41,
			"l": 22.4036,
			"t": 1344874920000,
			"n": 35
		  },
		  {
			"v": 80640,
			"vw": 22.4072,
			"o": 22.4071,
			"c": 22.4061,
			"h": 22.4086,
			"l": 22.4057,
			"t": 1344874980000,
			"n": 22
		  },
		  {
			"v": 301224,
			"vw": 22.4108,
			"o": 22.4071,
			"c": 22.4101,
			"h": 22.4139,
			"l": 22.4057,
			"t": 1344875040000,
			"n": 65
		  },
		  {
			"v": 200508,
			"vw": 22.4078,
			"o": 22.4075,
			"c": 22.408,
			"h": 22.4107,
			"l": 22.4057,
			"t": 1344875100000,
			"n": 45
		  },
		  {
			"v": 381808,
			"vw": 22.4054,
			"o": 22.4089,
			"c": 22.4032,
			"h": 22.4107,
			"l": 22.3996,
			"t": 1344875160000,
			"n": 83
		  },
		  {
			"v": 146636,
			"vw": 22.3995,
			"o": 22.4011,
			"c": 22.3986,
			"h": 22.4011,
			"l": 22.3964,
			"t": 1344875220000,
			"n": 34
		  },
		  {
			"v": 118188,
			"vw": 22.3962,
			"o": 22.3986,
			"c": 22.3951,
			"h": 22.401,
			"l": 22.3929,
			"t": 1344875280000,
			"n": 34
		  },
		  {
			"v": 674016,
			"vw": 22.3902,
			"o": 22.3929,
			"c": 22.3929,
			"h": 22.3968,
			"l": 22.3846,
			"t": 1344875340000,
			"n": 148
		  },
		  {
			"v": 297696,
			"vw": 22.3923,
			"o": 22.3925,
			"c": 22.3925,
			"h": 22.3929,
			"l": 22.3886,
			"t": 1344875400000,
			"n": 53
		  },
		  {
			"v": 317772,
			"vw": 22.3948,
			"o": 22.3925,
			"c": 22.3985,
			"h": 22.3993,
			"l": 22.3925,
			"t": 1344875460000,
			"n": 57
		  },
		  {
			"v": 191296,
			"vw": 22.3974,
			"o": 22.3964,
			"c": 22.3964,
			"h": 22.4014,
			"l": 22.3954,
			"t": 1344875520000,
			"n": 40
		  },
		  {
			"v": 129948,
			"vw": 22.3949,
			"o": 22.3964,
			"c": 22.3964,
			"h": 22.3986,
			"l": 22.3925,
			"t": 1344875580000,
			"n": 33
		  },
		  {
			"v": 89404,
			"vw": 22.3962,
			"o": 22.3964,
			"c": 22.3939,
			"h": 22.3982,
			"l": 22.3936,
			"t": 1344875640000,
			"n": 25
		  },
		  {
			"v": 125300,
			"vw": 22.3956,
			"o": 22.3964,
			"c": 22.3932,
			"h": 22.3968,
			"l": 22.3929,
			"t": 1344875700000,
			"n": 34
		  },
		  {
			"v": 236992,
			"vw": 22.3988,
			"o": 22.3929,
			"c": 22.3971,
			"h": 22.4014,
			"l": 22.3929,
			"t": 1344875760000,
			"n": 58
		  },
		  {
			"v": 166040,
			"vw": 22.3993,
			"o": 22.3996,
			"c": 22.3954,
			"h": 22.4018,
			"l": 22.3954,
			"t": 1344875820000,
			"n": 51
		  },
		  {
			"v": 493136,
			"vw": 22.3975,
			"o": 22.395,
			"c": 22.405,
			"h": 22.405,
			"l": 22.3929,
			"t": 1344875880000,
			"n": 112
		  },
		  {
			"v": 1692376,
			"vw": 22.4122,
			"o": 22.4054,
			"c": 22.4132,
			"h": 22.4193,
			"l": 22.4029,
			"t": 1344875940000,
			"n": 335
		  },
		  {
			"v": 548772,
			"vw": 22.4168,
			"o": 22.4154,
			"c": 22.4175,
			"h": 22.4196,
			"l": 22.4129,
			"t": 1344876000000,
			"n": 149
		  },
		  {
			"v": 419664,
			"vw": 22.4144,
			"o": 22.4175,
			"c": 22.4128,
			"h": 22.4182,
			"l": 22.4107,
			"t": 1344876060000,
			"n": 68
		  },
		  {
			"v": 206192,
			"vw": 22.4127,
			"o": 22.4107,
			"c": 22.417,
			"h": 22.417,
			"l": 22.4075,
			"t": 1344876120000,
			"n": 59
		  },
		  {
			"v": 675752,
			"vw": 22.4209,
			"o": 22.4164,
			"c": 22.4214,
			"h": 22.425,
			"l": 22.4164,
			"t": 1344876180000,
			"n": 136
		  },
		  {
			"v": 667100,
			"vw": 22.4212,
			"o": 22.4196,
			"c": 22.4214,
			"h": 22.425,
			"l": 22.4157,
			"t": 1344876240000,
			"n": 101
		  },
		  {
			"v": 533148,
			"vw": 22.4231,
			"o": 22.419,
			"c": 22.4215,
			"h": 22.4268,
			"l": 22.419,
			"t": 1344876300000,
			"n": 89
		  },
		  {
			"v": 279972,
			"vw": 22.4213,
			"o": 22.4221,
			"c": 22.421,
			"h": 22.4243,
			"l": 22.4175,
			"t": 1344876360000,
			"n": 47
		  },
		  {
			"v": 353864,
			"vw": 22.415,
			"o": 22.417,
			"c": 22.415,
			"h": 22.4199,
			"l": 22.4107,
			"t": 1344876420000,
			"n": 94
		  },
		  {
			"v": 442596,
			"vw": 22.421,
			"o": 22.415,
			"c": 22.4243,
			"h": 22.425,
			"l": 22.4146,
			"t": 1344876480000,
			"n": 110
		  },
		  {
			"v": 371924,
			"vw": 22.4206,
			"o": 22.4239,
			"c": 22.4201,
			"h": 22.4239,
			"l": 22.4179,
			"t": 1344876540000,
			"n": 90
		  },
		  {
			"v": 445788,
			"vw": 22.4226,
			"o": 22.4182,
			"c": 22.4214,
			"h": 22.4261,
			"l": 22.4179,
			"t": 1344876600000,
			"n": 119
		  },
		  {
			"v": 354116,
			"vw": 22.4251,
			"o": 22.4218,
			"c": 22.4271,
			"h": 22.4275,
			"l": 22.4189,
			"t": 1344876660000,
			"n": 92
		  },
		  {
			"v": 1391544,
			"vw": 22.4287,
			"o": 22.4271,
			"c": 22.4321,
			"h": 22.4329,
			"l": 22.4208,
			"t": 1344876720000,
			"n": 244
		  },
		  {
			"v": 187460,
			"vw": 22.4278,
			"o": 22.4321,
			"c": 22.4271,
			"h": 22.4321,
			"l": 22.4236,
			"t": 1344876780000,
			"n": 60
		  },
		  {
			"v": 401996,
			"vw": 22.4233,
			"o": 22.4268,
			"c": 22.4214,
			"h": 22.4279,
			"l": 22.4182,
			"t": 1344876840000,
			"n": 91
		  },
		  {
			"v": 270396,
			"vw": 22.4246,
			"o": 22.4194,
			"c": 22.4261,
			"h": 22.4281,
			"l": 22.4194,
			"t": 1344876900000,
			"n": 64
		  },
		  {
			"v": 132916,
			"vw": 22.4254,
			"o": 22.4219,
			"c": 22.4268,
			"h": 22.4268,
			"l": 22.4219,
			"t": 1344876960000,
			"n": 33
		  },
		  {
			"v": 777896,
			"vw": 22.4282,
			"o": 22.4268,
			"c": 22.4268,
			"h": 22.4321,
			"l": 22.4214,
			"t": 1344877020000,
			"n": 172
		  },
		  {
			"v": 279272,
			"vw": 22.4251,
			"o": 22.4268,
			"c": 22.4268,
			"h": 22.4307,
			"l": 22.4219,
			"t": 1344877080000,
			"n": 73
		  },
		  {
			"v": 319032,
			"vw": 22.4246,
			"o": 22.4243,
			"c": 22.426,
			"h": 22.4279,
			"l": 22.4221,
			"t": 1344877140000,
			"n": 50
		  },
		  {
			"v": 342468,
			"vw": 22.4278,
			"o": 22.4268,
			"c": 22.4282,
			"h": 22.4307,
			"l": 22.4239,
			"t": 1344877200000,
			"n": 95
		  },
		  {
			"v": 308504,
			"vw": 22.4292,
			"o": 22.4276,
			"c": 22.4304,
			"h": 22.4307,
			"l": 22.4255,
			"t": 1344877260000,
			"n": 68
		  },
		  {
			"v": 719236,
			"vw": 22.4233,
			"o": 22.4286,
			"c": 22.4232,
			"h": 22.4296,
			"l": 22.4211,
			"t": 1344877320000,
			"n": 156
		  },
		  {
			"v": 439656,
			"vw": 22.4234,
			"o": 22.4211,
			"c": 22.4239,
			"h": 22.4254,
			"l": 22.4211,
			"t": 1344877380000,
			"n": 111
		  },
		  {
			"v": 487144,
			"vw": 22.4176,
			"o": 22.4214,
			"c": 22.4177,
			"h": 22.4236,
			"l": 22.4136,
			"t": 1344877440000,
			"n": 130
		  },
		  {
			"v": 428512,
			"vw": 22.4177,
			"o": 22.4175,
			"c": 22.4175,
			"h": 22.4211,
			"l": 22.4154,
			"t": 1344877500000,
			"n": 89
		  },
		  {
			"v": 495460,
			"vw": 22.4158,
			"o": 22.4154,
			"c": 22.4179,
			"h": 22.4204,
			"l": 22.4139,
			"t": 1344877560000,
			"n": 119
		  },
		  {
			"v": 134120,
			"vw": 22.4171,
			"o": 22.4204,
			"c": 22.4146,
			"h": 22.4204,
			"l": 22.4146,
			"t": 1344877620000,
			"n": 37
		  },
		  {
			"v": 282716,
			"vw": 22.4206,
			"o": 22.4147,
			"c": 22.42,
			"h": 22.4243,
			"l": 22.4147,
			"t": 1344877680000,
			"n": 75
		  },
		  {
			"v": 212660,
			"vw": 22.4202,
			"o": 22.4179,
			"c": 22.4218,
			"h": 22.4232,
			"l": 22.4179,
			"t": 1344877740000,
			"n": 43
		  },
		  {
			"v": 454076,
			"vw": 22.4261,
			"o": 22.4225,
			"c": 22.43,
			"h": 22.43,
			"l": 22.4221,
			"t": 1344877800000,
			"n": 91
		  },
		  {
			"v": 346724,
			"vw": 22.4257,
			"o": 22.4304,
			"c": 22.4213,
			"h": 22.4307,
			"l": 22.4196,
			"t": 1344877860000,
			"n": 77
		  },
		  {
			"v": 357504,
			"vw": 22.417,
			"o": 22.4229,
			"c": 22.4125,
			"h": 22.4236,
			"l": 22.4107,
			"t": 1344877920000,
			"n": 59
		  },
		  {
			"v": 245224,
			"vw": 22.4135,
			"o": 22.4107,
			"c": 22.4171,
			"h": 22.4175,
			"l": 22.4107,
			"t": 1344877980000,
			"n": 59
		  },
		  {
			"v": 260400,
			"vw": 22.4192,
			"o": 22.4143,
			"c": 22.4204,
			"h": 22.4232,
			"l": 22.4143,
			"t": 1344878040000,
			"n": 73
		  },
		  {
			"v": 318724,
			"vw": 22.4243,
			"o": 22.4189,
			"c": 22.4254,
			"h": 22.4289,
			"l": 22.4182,
			"t": 1344878100000,
			"n": 82
		  },
		  {
			"v": 402640,
			"vw": 22.4283,
			"o": 22.4286,
			"c": 22.4264,
			"h": 22.4307,
			"l": 22.4254,
			"t": 1344878160000,
			"n": 73
		  },
		  {
			"v": 894712,
			"vw": 22.4334,
			"o": 22.4286,
			"c": 22.435,
			"h": 22.4375,
			"l": 22.4275,
			"t": 1344878220000,
			"n": 205
		  },
		  {
			"v": 147924,
			"vw": 22.4318,
			"o": 22.4321,
			"c": 22.4318,
			"h": 22.4339,
			"l": 22.4307,
			"t": 1344878280000,
			"n": 31
		  },
		  {
			"v": 99148,
			"vw": 22.4317,
			"o": 22.4336,
			"c": 22.4321,
			"h": 22.4336,
			"l": 22.4296,
			"t": 1344878340000,
			"n": 32
		  },
		  {
			"v": 486948,
			"vw": 22.4223,
			"o": 22.4314,
			"c": 22.4175,
			"h": 22.4321,
			"l": 22.4146,
			"t": 1344878400000,
			"n": 91
		  },
		  {
			"v": 224000,
			"vw": 22.4229,
			"o": 22.415,
			"c": 22.4275,
			"h": 22.4296,
			"l": 22.415,
			"t": 1344878460000,
			"n": 68
		  },
		  {
			"v": 117796,
			"vw": 22.4252,
			"o": 22.4264,
			"c": 22.4275,
			"h": 22.4275,
			"l": 22.4236,
			"t": 1344878520000,
			"n": 23
		  },
		  {
			"v": 371560,
			"vw": 22.4301,
			"o": 22.4273,
			"c": 22.4294,
			"h": 22.4336,
			"l": 22.4254,
			"t": 1344878580000,
			"n": 88
		  },
		  {
			"v": 191800,
			"vw": 22.4282,
			"o": 22.4325,
			"c": 22.426,
			"h": 22.4332,
			"l": 22.4244,
			"t": 1344878640000,
			"n": 40
		  },
		  {
			"v": 202916,
			"vw": 22.4287,
			"o": 22.4275,
			"c": 22.4294,
			"h": 22.4318,
			"l": 22.4254,
			"t": 1344878700000,
			"n": 50
		  },
		  {
			"v": 108024,
			"vw": 22.4298,
			"o": 22.4259,
			"c": 22.43,
			"h": 22.4314,
			"l": 22.4259,
			"t": 1344878760000,
			"n": 29
		  },
		  {
			"v": 563472,
			"vw": 22.4329,
			"o": 22.4314,
			"c": 22.4357,
			"h": 22.4371,
			"l": 22.4286,
			"t": 1344878820000,
			"n": 102
		  },
		  {
			"v": 317604,
			"vw": 22.4312,
			"o": 22.4361,
			"c": 22.4318,
			"h": 22.4364,
			"l": 22.4275,
			"t": 1344878880000,
			"n": 84
		  },
		  {
			"v": 321356,
			"vw": 22.4298,
			"o": 22.4325,
			"c": 22.4286,
			"h": 22.4329,
			"l": 22.4286,
			"t": 1344878940000,
			"n": 76
		  },
		  {
			"v": 448588,
			"vw": 22.4263,
			"o": 22.4293,
			"c": 22.4247,
			"h": 22.4314,
			"l": 22.4221,
			"t": 1344879000000,
			"n": 90
		  },
		  {
			"v": 161000,
			"vw": 22.4261,
			"o": 22.4247,
			"c": 22.4218,
			"h": 22.4286,
			"l": 22.4218,
			"t": 1344879060000,
			"n": 47
		  },
		  {
			"v": 427364,
			"vw": 22.4196,
			"o": 22.425,
			"c": 22.4132,
			"h": 22.4255,
			"l": 22.4132,
			"t": 1344879120000,
			"n": 77
		  },
		  {
			"v": 267176,
			"vw": 22.4164,
			"o": 22.4139,
			"c": 22.42,
			"h": 22.4207,
			"l": 22.4129,
			"t": 1344879180000,
			"n": 73
		  },
		  {
			"v": 111384,
			"vw": 22.4176,
			"o": 22.4164,
			"c": 22.4168,
			"h": 22.42,
			"l": 22.4164,
			"t": 1344879240000,
			"n": 30
		  },
		  {
			"v": 281316,
			"vw": 22.4189,
			"o": 22.4191,
			"c": 22.4179,
			"h": 22.4211,
			"l": 22.4168,
			"t": 1344879300000,
			"n": 65
		  },
		  {
			"v": 162596,
			"vw": 22.4199,
			"o": 22.4175,
			"c": 22.4239,
			"h": 22.4254,
			"l": 22.4161,
			"t": 1344879360000,
			"n": 47
		  },
		  {
			"v": 130676,
			"vw": 22.4215,
			"o": 22.424,
			"c": 22.4223,
			"h": 22.425,
			"l": 22.4189,
			"t": 1344879420000,
			"n": 27
		  },
		  {
			"v": 59696,
			"vw": 22.4219,
			"o": 22.4232,
			"c": 22.4214,
			"h": 22.4246,
			"l": 22.4214,
			"t": 1344879480000,
			"n": 16
		  },
		  {
			"v": 131600,
			"vw": 22.4226,
			"o": 22.4196,
			"c": 22.4232,
			"h": 22.4246,
			"l": 22.4196,
			"t": 1344879540000,
			"n": 28
		  },
		  {
			"v": 126756,
			"vw": 22.4203,
			"o": 22.4204,
			"c": 22.4214,
			"h": 22.4218,
			"l": 22.4175,
			"t": 1344879600000,
			"n": 31
		  },
		  {
			"v": 658896,
			"vw": 22.4264,
			"o": 22.4214,
			"c": 22.422,
			"h": 22.4318,
			"l": 22.4214,
			"t": 1344879660000,
			"n": 140
		  },
		  {
			"v": 115528,
			"vw": 22.4238,
			"o": 22.4232,
			"c": 22.4232,
			"h": 22.4268,
			"l": 22.4211,
			"t": 1344879720000,
			"n": 29
		  },
		  {
			"v": 160776,
			"vw": 22.4236,
			"o": 22.4211,
			"c": 22.4268,
			"h": 22.4275,
			"l": 22.4204,
			"t": 1344879780000,
			"n": 47
		  },
		  {
			"v": 104440,
			"vw": 22.4281,
			"o": 22.4251,
			"c": 22.4279,
			"h": 22.4311,
			"l": 22.425,
			"t": 1344879840000,
			"n": 32
		  },
		  {
			"v": 289912,
			"vw": 22.4305,
			"o": 22.4261,
			"c": 22.4307,
			"h": 22.4336,
			"l": 22.4261,
			"t": 1344879900000,
			"n": 74
		  },
		  {
			"v": 122052,
			"vw": 22.4276,
			"o": 22.4304,
			"c": 22.425,
			"h": 22.4304,
			"l": 22.4229,
			"t": 1344879960000,
			"n": 32
		  },
		  {
			"v": 170772,
			"vw": 22.4256,
			"o": 22.4229,
			"c": 22.4271,
			"h": 22.4271,
			"l": 22.4229,
			"t": 1344880020000,
			"n": 36
		  },
		  {
			"v": 129220,
			"vw": 22.4246,
			"o": 22.4266,
			"c": 22.4254,
			"h": 22.4271,
			"l": 22.4214,
			"t": 1344880080000,
			"n": 37
		  },
		  {
			"v": 273168,
			"vw": 22.4305,
			"o": 22.4267,
			"c": 22.4336,
			"h": 22.4336,
			"l": 22.4236,
			"t": 1344880140000,
			"n": 63
		  },
		  {
			"v": 552692,
			"vw": 22.433,
			"o": 22.4321,
			"c": 22.4289,
			"h": 22.4364,
			"l": 22.4286,
			"t": 1344880200000,
			"n": 115
		  },
		  {
			"v": 270844,
			"vw": 22.4242,
			"o": 22.4289,
			"c": 22.4232,
			"h": 22.4293,
			"l": 22.4207,
			"t": 1344880260000,
			"n": 56
		  },
		  {
			"v": 185332,
			"vw": 22.4281,
			"o": 22.4257,
			"c": 22.4264,
			"h": 22.4286,
			"l": 22.4254,
			"t": 1344880320000,
			"n": 24
		  },
		  {
			"v": 383376,
			"vw": 22.4281,
			"o": 22.4286,
			"c": 22.4268,
			"h": 22.4318,
			"l": 22.425,
			"t": 1344880380000,
			"n": 88
		  },
		  {
			"v": 77084,
			"vw": 22.4282,
			"o": 22.4275,
			"c": 22.4302,
			"h": 22.4302,
			"l": 22.4257,
			"t": 1344880440000,
			"n": 25
		  },
		  {
			"v": 266448,
			"vw": 22.4302,
			"o": 22.4304,
			"c": 22.4291,
			"h": 22.4314,
			"l": 22.4269,
			"t": 1344880500000,
			"n": 66
		  },
		  {
			"v": 359940,
			"vw": 22.4304,
			"o": 22.4296,
			"c": 22.4273,
			"h": 22.4325,
			"l": 22.425,
			"t": 1344880560000,
			"n": 88
		  },
		  {
			"v": 394716,
			"vw": 22.4255,
			"o": 22.4257,
			"c": 22.4254,
			"h": 22.4286,
			"l": 22.4229,
			"t": 1344880620000,
			"n": 68
		  },
		  {
			"v": 318556,
			"vw": 22.4229,
			"o": 22.4257,
			"c": 22.4229,
			"h": 22.4261,
			"l": 22.4214,
			"t": 1344880680000,
			"n": 47
		  },
		  {
			"v": 289996,
			"vw": 22.4216,
			"o": 22.4221,
			"c": 22.4246,
			"h": 22.4261,
			"l": 22.4189,
			"t": 1344880740000,
			"n": 71
		  },
		  {
			"v": 105840,
			"vw": 22.4247,
			"o": 22.4254,
			"c": 22.4261,
			"h": 22.4268,
			"l": 22.423,
			"t": 1344880800000,
			"n": 32
		  },
		  {
			"v": 152992,
			"vw": 22.4265,
			"o": 22.4235,
			"c": 22.4275,
			"h": 22.4286,
			"l": 22.4235,
			"t": 1344880860000,
			"n": 34
		  },
		  {
			"v": 179956,
			"vw": 22.4243,
			"o": 22.4254,
			"c": 22.4252,
			"h": 22.4257,
			"l": 22.4225,
			"t": 1344880920000,
			"n": 39
		  },
		  {
			"v": 88788,
			"vw": 22.425,
			"o": 22.4246,
			"c": 22.4261,
			"h": 22.4264,
			"l": 22.4232,
			"t": 1344880980000,
			"n": 27
		  },
		  {
			"v": 141120,
			"vw": 22.4254,
			"o": 22.4268,
			"c": 22.4229,
			"h": 22.4282,
			"l": 22.4221,
			"t": 1344881040000,
			"n": 35
		  },
		  {
			"v": 466872,
			"vw": 22.419,
			"o": 22.4221,
			"c": 22.4182,
			"h": 22.4236,
			"l": 22.4151,
			"t": 1344881100000,
			"n": 109
		  },
		  {
			"v": 360444,
			"vw": 22.4224,
			"o": 22.4179,
			"c": 22.425,
			"h": 22.4268,
			"l": 22.4154,
			"t": 1344881160000,
			"n": 69
		  },
		  {
			"v": 92400,
			"vw": 22.4269,
			"o": 22.4271,
			"c": 22.4282,
			"h": 22.4282,
			"l": 22.4237,
			"t": 1344881220000,
			"n": 31
		  },
		  {
			"v": 300804,
			"vw": 22.4225,
			"o": 22.4246,
			"c": 22.4249,
			"h": 22.4282,
			"l": 22.42,
			"t": 1344881280000,
			"n": 50
		  },
		  {
			"v": 464772,
			"vw": 22.4276,
			"o": 22.4239,
			"c": 22.4261,
			"h": 22.4293,
			"l": 22.4229,
			"t": 1344881340000,
			"n": 108
		  },
		  {
			"v": 230328,
			"vw": 22.4274,
			"o": 22.4257,
			"c": 22.4293,
			"h": 22.4293,
			"l": 22.4236,
			"t": 1344881400000,
			"n": 59
		  },
		  {
			"v": 459984,
			"vw": 22.4294,
			"o": 22.4279,
			"c": 22.4311,
			"h": 22.4311,
			"l": 22.4268,
			"t": 1344881460000,
			"n": 92
		  },
		  {
			"v": 345968,
			"vw": 22.4303,
			"o": 22.4293,
			"c": 22.4321,
			"h": 22.4321,
			"l": 22.4275,
			"t": 1344881520000,
			"n": 62
		  },
		  {
			"v": 387212,
			"vw": 22.4329,
			"o": 22.4311,
			"c": 22.4329,
			"h": 22.4357,
			"l": 22.43,
			"t": 1344881580000,
			"n": 75
		  },
		  {
			"v": 1363516,
			"vw": 22.4387,
			"o": 22.4313,
			"c": 22.4428,
			"h": 22.4439,
			"l": 22.43,
			"t": 1344881640000,
			"n": 296
		  },
		  {
			"v": 2153676,
			"vw": 22.4476,
			"o": 22.4432,
			"c": 22.4529,
			"h": 22.4543,
			"l": 22.4411,
			"t": 1344881700000,
			"n": 462
		  },
		  {
			"v": 972860,
			"vw": 22.4535,
			"o": 22.4525,
			"c": 22.4546,
			"h": 22.4568,
			"l": 22.4504,
			"t": 1344881760000,
			"n": 192
		  },
		  {
			"v": 450996,
			"vw": 22.4544,
			"o": 22.4515,
			"c": 22.455,
			"h": 22.4561,
			"l": 22.4514,
			"t": 1344881820000,
			"n": 116
		  },
		  {
			"v": 1256220,
			"vw": 22.4505,
			"o": 22.4557,
			"c": 22.4414,
			"h": 22.4579,
			"l": 22.4389,
			"t": 1344881880000,
			"n": 254
		  },
		  {
			"v": 505092,
			"vw": 22.4477,
			"o": 22.4442,
			"c": 22.4504,
			"h": 22.4514,
			"l": 22.4429,
			"t": 1344881940000,
			"n": 119
		  },
		  {
			"v": 256396,
			"vw": 22.4469,
			"o": 22.4514,
			"c": 22.4493,
			"h": 22.4514,
			"l": 22.4429,
			"t": 1344882000000,
			"n": 58
		  },
		  {
			"v": 272972,
			"vw": 22.4457,
			"o": 22.4493,
			"c": 22.445,
			"h": 22.4507,
			"l": 22.4432,
			"t": 1344882060000,
			"n": 77
		  },
		  {
			"v": 222320,
			"vw": 22.4465,
			"o": 22.4446,
			"c": 22.4479,
			"h": 22.4486,
			"l": 22.4443,
			"t": 1344882120000,
			"n": 66
		  },
		  {
			"v": 487592,
			"vw": 22.4448,
			"o": 22.4475,
			"c": 22.4475,
			"h": 22.4482,
			"l": 22.4396,
			"t": 1344882180000,
			"n": 123
		  },
		  {
			"v": 1048740,
			"vw": 22.4486,
			"o": 22.4465,
			"c": 22.4496,
			"h": 22.4525,
			"l": 22.445,
			"t": 1344882240000,
			"n": 201
		  },
		  {
			"v": 667940,
			"vw": 22.4536,
			"o": 22.4496,
			"c": 22.4571,
			"h": 22.4582,
			"l": 22.4475,
			"t": 1344882300000,
			"n": 182
		  },
		  {
			"v": 2000908,
			"vw": 22.462,
			"o": 22.4566,
			"c": 22.4689,
			"h": 22.4689,
			"l": 22.4496,
			"t": 1344882360000,
			"n": 406
		  },
		  {
			"v": 891940,
			"vw": 22.4666,
			"o": 22.4679,
			"c": 22.4636,
			"h": 22.4714,
			"l": 22.4575,
			"t": 1344882420000,
			"n": 206
		  },
		  {
			"v": 530656,
			"vw": 22.456,
			"o": 22.4628,
			"c": 22.4543,
			"h": 22.4636,
			"l": 22.4506,
			"t": 1344882480000,
			"n": 119
		  },
		  {
			"v": 301000,
			"vw": 22.4551,
			"o": 22.4543,
			"c": 22.4536,
			"h": 22.4593,
			"l": 22.4514,
			"t": 1344882540000,
			"n": 76
		  },
		  {
			"v": 556164,
			"vw": 22.4576,
			"o": 22.4536,
			"c": 22.4571,
			"h": 22.4629,
			"l": 22.4532,
			"t": 1344882600000,
			"n": 118
		  },
		  {
			"v": 436800,
			"vw": 22.4557,
			"o": 22.4554,
			"c": 22.4554,
			"h": 22.4604,
			"l": 22.4546,
			"t": 1344882660000,
			"n": 125
		  },
		  {
			"v": 510944,
			"vw": 22.4606,
			"o": 22.455,
			"c": 22.4654,
			"h": 22.4661,
			"l": 22.4546,
			"t": 1344882720000,
			"n": 125
		  },
		  {
			"v": 294252,
			"vw": 22.4662,
			"o": 22.4643,
			"c": 22.4651,
			"h": 22.4693,
			"l": 22.46,
			"t": 1344882780000,
			"n": 80
		  },
		  {
			"v": 541940,
			"vw": 22.4636,
			"o": 22.465,
			"c": 22.4639,
			"h": 22.4679,
			"l": 22.46,
			"t": 1344882840000,
			"n": 125
		  },
		  {
			"v": 726628,
			"vw": 22.4613,
			"o": 22.4593,
			"c": 22.4639,
			"h": 22.465,
			"l": 22.4582,
			"t": 1344882900000,
			"n": 152
		  },
		  {
			"v": 511420,
			"vw": 22.4631,
			"o": 22.4636,
			"c": 22.4614,
			"h": 22.4668,
			"l": 22.4589,
			"t": 1344882960000,
			"n": 106
		  },
		  {
			"v": 345828,
			"vw": 22.459,
			"o": 22.4624,
			"c": 22.4589,
			"h": 22.4629,
			"l": 22.4564,
			"t": 1344883020000,
			"n": 84
		  },
		  {
			"v": 465920,
			"vw": 22.4572,
			"o": 22.4579,
			"c": 22.4596,
			"h": 22.4621,
			"l": 22.455,
			"t": 1344883080000,
			"n": 108
		  },
		  {
			"v": 617176,
			"vw": 22.4594,
			"o": 22.4579,
			"c": 22.4664,
			"h": 22.4668,
			"l": 22.4536,
			"t": 1344883140000,
			"n": 155
		  },
		  {
			"v": 1050980,
			"vw": 22.4701,
			"o": 22.4636,
			"c": 22.4721,
			"h": 22.4729,
			"l": 22.4632,
			"t": 1344883200000,
			"n": 177
		  },
		  {
			"v": 462028,
			"vw": 22.4715,
			"o": 22.4721,
			"c": 22.4711,
			"h": 22.4732,
			"l": 22.4679,
			"t": 1344883260000,
			"n": 105
		  },
		  {
			"v": 478492,
			"vw": 22.4663,
			"o": 22.4696,
			"c": 22.4669,
			"h": 22.4703,
			"l": 22.463,
			"t": 1344883320000,
			"n": 88
		  },
		  {
			"v": 392252,
			"vw": 22.4659,
			"o": 22.47,
			"c": 22.4646,
			"h": 22.4704,
			"l": 22.4629,
			"t": 1344883380000,
			"n": 52
		  },
		  {
			"v": 361928,
			"vw": 22.4669,
			"o": 22.4654,
			"c": 22.4657,
			"h": 22.4696,
			"l": 22.4618,
			"t": 1344883440000,
			"n": 72
		  },
		  {
			"v": 518252,
			"vw": 22.4611,
			"o": 22.4643,
			"c": 22.466,
			"h": 22.4671,
			"l": 22.4629,
			"t": 1344883500000,
			"n": 54
		  },
		  {
			"v": 296912,
			"vw": 22.4671,
			"o": 22.4661,
			"c": 22.4679,
			"h": 22.4689,
			"l": 22.4651,
			"t": 1344883560000,
			"n": 70
		  },
		  {
			"v": 310128,
			"vw": 22.4656,
			"o": 22.4667,
			"c": 22.4675,
			"h": 22.4679,
			"l": 22.4629,
			"t": 1344883620000,
			"n": 70
		  },
		  {
			"v": 489664,
			"vw": 22.4593,
			"o": 22.465,
			"c": 22.4536,
			"h": 22.465,
			"l": 22.4536,
			"t": 1344883680000,
			"n": 128
		  },
		  {
			"v": 634228,
			"vw": 22.4568,
			"o": 22.4539,
			"c": 22.4611,
			"h": 22.4618,
			"l": 22.4504,
			"t": 1344883740000,
			"n": 140
		  },
		  {
			"v": 531748,
			"vw": 22.4624,
			"o": 22.46,
			"c": 22.4611,
			"h": 22.4657,
			"l": 22.4575,
			"t": 1344883800000,
			"n": 109
		  },
		  {
			"v": 249872,
			"vw": 22.4635,
			"o": 22.4607,
			"c": 22.4642,
			"h": 22.4675,
			"l": 22.4582,
			"t": 1344883860000,
			"n": 59
		  },
		  {
			"v": 262976,
			"vw": 22.4603,
			"o": 22.4616,
			"c": 22.4586,
			"h": 22.4621,
			"l": 22.4579,
			"t": 1344883920000,
			"n": 45
		  },
		  {
			"v": 352632,
			"vw": 22.465,
			"o": 22.4616,
			"c": 22.463,
			"h": 22.4682,
			"l": 22.4604,
			"t": 1344883980000,
			"n": 71
		  },
		  {
			"v": 155400,
			"vw": 22.4654,
			"o": 22.4654,
			"c": 22.465,
			"h": 22.4682,
			"l": 22.4625,
			"t": 1344884040000,
			"n": 41
		  },
		  {
			"v": 674772,
			"vw": 22.4681,
			"o": 22.4632,
			"c": 22.4707,
			"h": 22.4725,
			"l": 22.4632,
			"t": 1344884100000,
			"n": 147
		  },
		  {
			"v": 1014048,
			"vw": 22.4699,
			"o": 22.4707,
			"c": 22.4636,
			"h": 22.4732,
			"l": 22.46,
			"t": 1344884160000,
			"n": 196
		  },
		  {
			"v": 255332,
			"vw": 22.4609,
			"o": 22.4639,
			"c": 22.4593,
			"h": 22.4639,
			"l": 22.4579,
			"t": 1344884220000,
			"n": 69
		  },
		  {
			"v": 230832,
			"vw": 22.4638,
			"o": 22.4596,
			"c": 22.4672,
			"h": 22.4675,
			"l": 22.4596,
			"t": 1344884280000,
			"n": 69
		  },
		  {
			"v": 369740,
			"vw": 22.4677,
			"o": 22.4646,
			"c": 22.4661,
			"h": 22.4714,
			"l": 22.4625,
			"t": 1344884340000,
			"n": 73
		  },
		  {
			"v": 310464,
			"vw": 22.4636,
			"o": 22.4636,
			"c": 22.4675,
			"h": 22.4675,
			"l": 22.4607,
			"t": 1344884400000,
			"n": 65
		  },
		  {
			"v": 167944,
			"vw": 22.466,
			"o": 22.4661,
			"c": 22.4674,
			"h": 22.4686,
			"l": 22.4625,
			"t": 1344884460000,
			"n": 41
		  },
		  {
			"v": 260176,
			"vw": 22.4663,
			"o": 22.467,
			"c": 22.4664,
			"h": 22.4693,
			"l": 22.4629,
			"t": 1344884520000,
			"n": 71
		  },
		  {
			"v": 360836,
			"vw": 22.4684,
			"o": 22.4657,
			"c": 22.4696,
			"h": 22.4711,
			"l": 22.4643,
			"t": 1344884580000,
			"n": 84
		  },
		  {
			"v": 356496,
			"vw": 22.4691,
			"o": 22.4686,
			"c": 22.4689,
			"h": 22.4711,
			"l": 22.4651,
			"t": 1344884640000,
			"n": 79
		  },
		  {
			"v": 600292,
			"vw": 22.4694,
			"o": 22.4685,
			"c": 22.4704,
			"h": 22.4711,
			"l": 22.4661,
			"t": 1344884700000,
			"n": 156
		  },
		  {
			"v": 1780128,
			"vw": 22.4739,
			"o": 22.4707,
			"c": 22.4768,
			"h": 22.4793,
			"l": 22.4696,
			"t": 1344884760000,
			"n": 267
		  },
		  {
			"v": 661024,
			"vw": 22.4743,
			"o": 22.4768,
			"c": 22.4746,
			"h": 22.4786,
			"l": 22.4686,
			"t": 1344884820000,
			"n": 154
		  },
		  {
			"v": 249228,
			"vw": 22.4708,
			"o": 22.476,
			"c": 22.4682,
			"h": 22.476,
			"l": 22.4679,
			"t": 1344884880000,
			"n": 64
		  },
		  {
			"v": 1281504,
			"vw": 22.4649,
			"o": 22.4718,
			"c": 22.4611,
			"h": 22.4725,
			"l": 22.4579,
			"t": 1344884940000,
			"n": 255
		  },
		  {
			"v": 433524,
			"vw": 22.4628,
			"o": 22.4582,
			"c": 22.4625,
			"h": 22.4654,
			"l": 22.4582,
			"t": 1344885000000,
			"n": 91
		  },
		  {
			"v": 704032,
			"vw": 22.4655,
			"o": 22.4636,
			"c": 22.4643,
			"h": 22.4679,
			"l": 22.4621,
			"t": 1344885060000,
			"n": 134
		  },
		  {
			"v": 317884,
			"vw": 22.4621,
			"o": 22.4643,
			"c": 22.4593,
			"h": 22.4664,
			"l": 22.4589,
			"t": 1344885120000,
			"n": 86
		  },
		  {
			"v": 332220,
			"vw": 22.4612,
			"o": 22.4589,
			"c": 22.4589,
			"h": 22.4642,
			"l": 22.4589,
			"t": 1344885180000,
			"n": 85
		  },
		  {
			"v": 806848,
			"vw": 22.4575,
			"o": 22.4596,
			"c": 22.4539,
			"h": 22.4607,
			"l": 22.4539,
			"t": 1344885240000,
			"n": 174
		  },
		  {
			"v": 600600,
			"vw": 22.4587,
			"o": 22.4552,
			"c": 22.4564,
			"h": 22.4625,
			"l": 22.4547,
			"t": 1344885300000,
			"n": 142
		  },
		  {
			"v": 327236,
			"vw": 22.4573,
			"o": 22.4557,
			"c": 22.4554,
			"h": 22.4603,
			"l": 22.4539,
			"t": 1344885360000,
			"n": 91
		  },
		  {
			"v": 476168,
			"vw": 22.4592,
			"o": 22.4579,
			"c": 22.4575,
			"h": 22.4636,
			"l": 22.4561,
			"t": 1344885420000,
			"n": 93
		  },
		  {
			"v": 1217580,
			"vw": 22.4485,
			"o": 22.4564,
			"c": 22.4471,
			"h": 22.4575,
			"l": 22.4429,
			"t": 1344885480000,
			"n": 259
		  },
		  {
			"v": 467460,
			"vw": 22.4456,
			"o": 22.4482,
			"c": 22.4479,
			"h": 22.4507,
			"l": 22.4432,
			"t": 1344885540000,
			"n": 116
		  },
		  {
			"v": 329952,
			"vw": 22.4516,
			"o": 22.4482,
			"c": 22.4496,
			"h": 22.4554,
			"l": 22.4468,
			"t": 1344885600000,
			"n": 108
		  },
		  {
			"v": 273784,
			"vw": 22.448,
			"o": 22.45,
			"c": 22.4461,
			"h": 22.4528,
			"l": 22.4461,
			"t": 1344885660000,
			"n": 66
		  },
		  {
			"v": 395668,
			"vw": 22.4462,
			"o": 22.4454,
			"c": 22.4509,
			"h": 22.4518,
			"l": 22.4407,
			"t": 1344885720000,
			"n": 106
		  },
		  {
			"v": 437080,
			"vw": 22.4551,
			"o": 22.4509,
			"c": 22.4607,
			"h": 22.4607,
			"l": 22.4486,
			"t": 1344885780000,
			"n": 107
		  },
		  {
			"v": 230944,
			"vw": 22.4573,
			"o": 22.4593,
			"c": 22.4539,
			"h": 22.4611,
			"l": 22.4539,
			"t": 1344885840000,
			"n": 64
		  },
		  {
			"v": 85736,
			"vw": 22.4527,
			"o": 22.4536,
			"c": 22.4511,
			"h": 22.4546,
			"l": 22.4511,
			"t": 1344885900000,
			"n": 26
		  },
		  {
			"v": 227192,
			"vw": 22.4548,
			"o": 22.4514,
			"c": 22.4588,
			"h": 22.4589,
			"l": 22.45,
			"t": 1344885960000,
			"n": 54
		  },
		  {
			"v": 328412,
			"vw": 22.452,
			"o": 22.4579,
			"c": 22.4514,
			"h": 22.4582,
			"l": 22.4489,
			"t": 1344886020000,
			"n": 73
		  },
		  {
			"v": 112420,
			"vw": 22.4515,
			"o": 22.4511,
			"c": 22.4518,
			"h": 22.4543,
			"l": 22.4489,
			"t": 1344886080000,
			"n": 35
		  },
		  {
			"v": 281568,
			"vw": 22.4499,
			"o": 22.4514,
			"c": 22.4464,
			"h": 22.4518,
			"l": 22.4464,
			"t": 1344886140000,
			"n": 62
		  },
		  {
			"v": 534128,
			"vw": 22.4486,
			"o": 22.4465,
			"c": 22.4471,
			"h": 22.4518,
			"l": 22.4446,
			"t": 1344886200000,
			"n": 136
		  },
		  {
			"v": 461832,
			"vw": 22.4487,
			"o": 22.4479,
			"c": 22.4464,
			"h": 22.4514,
			"l": 22.4454,
			"t": 1344886260000,
			"n": 140
		  },
		  {
			"v": 609364,
			"vw": 22.4453,
			"o": 22.4468,
			"c": 22.445,
			"h": 22.4479,
			"l": 22.4432,
			"t": 1344886320000,
			"n": 173
		  },
		  {
			"v": 761124,
			"vw": 22.4419,
			"o": 22.4432,
			"c": 22.4414,
			"h": 22.4461,
			"l": 22.4371,
			"t": 1344886380000,
			"n": 198
		  },
		  {
			"v": 687400,
			"vw": 22.4445,
			"o": 22.4414,
			"c": 22.4446,
			"h": 22.45,
			"l": 22.4414,
			"t": 1344886440000,
			"n": 176
		  },
		  {
			"v": 440076,
			"vw": 22.4416,
			"o": 22.4446,
			"c": 22.4447,
			"h": 22.4464,
			"l": 22.4386,
			"t": 1344886500000,
			"n": 114
		  },
		  {
			"v": 1880172,
			"vw": 22.4593,
			"o": 22.4468,
			"c": 22.4579,
			"h": 22.465,
			"l": 22.445,
			"t": 1344886560000,
			"n": 397
		  },
		  {
			"v": 649572,
			"vw": 22.4626,
			"o": 22.4582,
			"c": 22.4618,
			"h": 22.4654,
			"l": 22.4575,
			"t": 1344886620000,
			"n": 159
		  },
		  {
			"v": 770560,
			"vw": 22.4646,
			"o": 22.4636,
			"c": 22.4635,
			"h": 22.4668,
			"l": 22.4604,
			"t": 1344886680000,
			"n": 182
		  },
		  {
			"v": 1829968,
			"vw": 22.4684,
			"o": 22.4629,
			"c": 22.4682,
			"h": 22.4718,
			"l": 22.4625,
			"t": 1344886740000,
			"n": 345
		  },
		  {
			"v": 471324,
			"vw": 22.4678,
			"o": 22.4646,
			"c": 22.4682,
			"h": 22.4707,
			"l": 22.4646,
			"t": 1344886800000,
			"n": 124
		  },
		  {
			"v": 1256640,
			"vw": 22.4738,
			"o": 22.4671,
			"c": 22.4754,
			"h": 22.4786,
			"l": 22.4671,
			"t": 1344886860000,
			"n": 246
		  },
		  {
			"v": 1566040,
			"vw": 22.4781,
			"o": 22.475,
			"c": 22.4825,
			"h": 22.4854,
			"l": 22.4689,
			"t": 1344886920000,
			"n": 350
		  },
		  {
			"v": 813316,
			"vw": 22.4829,
			"o": 22.4842,
			"c": 22.4782,
			"h": 22.4868,
			"l": 22.4779,
			"t": 1344886980000,
			"n": 201
		  },
		  {
			"v": 1142092,
			"vw": 22.4805,
			"o": 22.4793,
			"c": 22.4807,
			"h": 22.4889,
			"l": 22.472,
			"t": 1344887040000,
			"n": 245
		  },
		  {
			"v": 1347920,
			"vw": 22.4824,
			"o": 22.4796,
			"c": 22.4786,
			"h": 22.4886,
			"l": 22.4764,
			"t": 1344887100000,
			"n": 302
		  },
		  {
			"v": 710024,
			"vw": 22.4792,
			"o": 22.4796,
			"c": 22.4754,
			"h": 22.4846,
			"l": 22.4754,
			"t": 1344887160000,
			"n": 191
		  },
		  {
			"v": 994588,
			"vw": 22.4764,
			"o": 22.4775,
			"c": 22.4779,
			"h": 22.4804,
			"l": 22.4707,
			"t": 1344887220000,
			"n": 227
		  },
		  {
			"v": 983164,
			"vw": 22.4819,
			"o": 22.4775,
			"c": 22.4854,
			"h": 22.4861,
			"l": 22.4775,
			"t": 1344887280000,
			"n": 201
		  },
		  {
			"v": 1034516,
			"vw": 22.4838,
			"o": 22.4839,
			"c": 22.4832,
			"h": 22.4875,
			"l": 22.4811,
			"t": 1344887340000,
			"n": 219
		  },
		  {
			"v": 1766996,
			"vw": 22.4869,
			"o": 22.4832,
			"c": 22.4811,
			"h": 22.4911,
			"l": 22.4804,
			"t": 1344887400000,
			"n": 484
		  },
		  {
			"v": 876932,
			"vw": 22.4832,
			"o": 22.4796,
			"c": 22.4825,
			"h": 22.4857,
			"l": 22.4793,
			"t": 1344887460000,
			"n": 222
		  },
		  {
			"v": 2129904,
			"vw": 22.4821,
			"o": 22.4825,
			"c": 22.4836,
			"h": 22.4839,
			"l": 22.4796,
			"t": 1344887520000,
			"n": 499
		  },
		  {
			"v": 1211000,
			"vw": 22.4812,
			"o": 22.4839,
			"c": 22.481,
			"h": 22.4839,
			"l": 22.4771,
			"t": 1344887580000,
			"n": 296
		  },
		  {
			"v": 1025024,
			"vw": 22.4813,
			"o": 22.4807,
			"c": 22.4804,
			"h": 22.4823,
			"l": 22.4796,
			"t": 1344887640000,
			"n": 265
		  },
		  {
			"v": 1360856,
			"vw": 22.4811,
			"o": 22.4796,
			"c": 22.4821,
			"h": 22.4832,
			"l": 22.4789,
			"t": 1344887700000,
			"n": 304
		  },
		  {
			"v": 1356320,
			"vw": 22.4858,
			"o": 22.4825,
			"c": 22.4875,
			"h": 22.4886,
			"l": 22.4821,
			"t": 1344887760000,
			"n": 321
		  },
		  {
			"v": 1468544,
			"vw": 22.4904,
			"o": 22.4879,
			"c": 22.4921,
			"h": 22.493,
			"l": 22.4864,
			"t": 1344887820000,
			"n": 372
		  },
		  {
			"v": 1544900,
			"vw": 22.4919,
			"o": 22.4929,
			"c": 22.4936,
			"h": 22.4943,
			"l": 22.49,
			"t": 1344887880000,
			"n": 333
		  },
		  {
			"v": 3774820,
			"vw": 22.4977,
			"o": 22.4921,
			"c": 22.5,
			"h": 22.5,
			"l": 22.4914,
			"t": 1344887940000,
			"n": 831
		  },
		  {
			"v": 453124,
			"vw": 22.4986,
			"o": 22.5,
			"c": 22.4929,
			"h": 22.5,
			"l": 22.4929,
			"t": 1344888000000,
			"n": 34
		  },
		  {
			"v": 243124,
			"vw": 22.4974,
			"o": 22.4982,
			"c": 22.4996,
			"h": 22.5,
			"l": 22.4893,
			"t": 1344888060000,
			"n": 32
		  },
		  {
			"v": 2018800,
			"vw": 22.4912,
			"o": 22.4989,
			"c": 22.4946,
			"h": 22.4989,
			"l": 22.4911,
			"t": 1344888120000,
			"n": 11
		  },
		  {
			"v": 78540,
			"vw": 22.4982,
			"o": 22.4946,
			"c": 22.4996,
			"h": 22.5,
			"l": 22.4946,
			"t": 1344888180000,
			"n": 15
		  },
		  {
			"v": 46508,
			"vw": 22.498,
			"o": 22.4971,
			"c": 22.5,
			"h": 22.5,
			"l": 22.4971,
			"t": 1344888240000,
			"n": 7
		  },
		  {
			"v": 68068,
			"vw": 22.5007,
			"o": 22.5036,
			"c": 22.5011,
			"h": 22.5039,
			"l": 22.5,
			"t": 1344888300000,
			"n": 7
		  },
		  {
			"v": 47292,
			"vw": 22.5047,
			"o": 22.5036,
			"c": 22.5071,
			"h": 22.5071,
			"l": 22.5036,
			"t": 1344888360000,
			"n": 12
		  },
		  {
			"v": 50176,
			"vw": 22.5092,
			"o": 22.5079,
			"c": 22.5107,
			"h": 22.5107,
			"l": 22.5071,
			"t": 1344888420000,
			"n": 10
		  },
		  {
			"v": 46536,
			"vw": 22.5117,
			"o": 22.5104,
			"c": 22.5093,
			"h": 22.5125,
			"l": 22.5089,
			"t": 1344888480000,
			"n": 8
		  },
		  {
			"v": 121016,
			"vw": 22.5072,
			"o": 22.5089,
			"c": 22.5125,
			"h": 22.5125,
			"l": 22.5089,
			"t": 1344888540000,
			"n": 12
		  },
		  {
			"v": 84308,
			"vw": 22.4946,
			"o": 22.5093,
			"c": 22.5089,
			"h": 22.5125,
			"l": 22.5089,
			"t": 1344888600000,
			"n": 10
		  },
		  {
			"v": 5600,
			"vw": 22.5089,
			"o": 22.5089,
			"c": 22.5089,
			"h": 22.5089,
			"l": 22.5089,
			"t": 1344888660000,
			"n": 1
		  },
		  {
			"v": 39200,
			"vw": 22.5089,
			"o": 22.5086,
			"c": 22.5089,
			"h": 22.5089,
			"l": 22.5086,
			"t": 1344888780000,
			"n": 10
		  },
		  {
			"v": 88928,
			"vw": 22.5091,
			"o": 22.5089,
			"c": 22.5107,
			"h": 22.5107,
			"l": 22.5086,
			"t": 1344888840000,
			"n": 14
		  },
		  {
			"v": 26096,
			"vw": 22.5113,
			"o": 22.5125,
			"c": 22.5071,
			"h": 22.5125,
			"l": 22.5071,
			"t": 1344888900000,
			"n": 5
		  },
		  {
			"v": 15680,
			"vw": 22.5105,
			"o": 22.5107,
			"c": 22.5093,
			"h": 22.5107,
			"l": 22.5093,
			"t": 1344888960000,
			"n": 2
		  },
		  {
			"v": 25396,
			"vw": 22.5125,
			"o": 22.5121,
			"c": 22.5125,
			"h": 22.5125,
			"l": 22.5121,
			"t": 1344889020000,
			"n": 2
		  },
		  {
			"v": 38360,
			"vw": 22.5122,
			"o": 22.5093,
			"c": 22.5132,
			"h": 22.5132,
			"l": 22.5093,
			"t": 1344889080000,
			"n": 7
		  },
		  {
			"v": 48440,
			"vw": 22.5134,
			"o": 22.5143,
			"c": 22.5125,
			"h": 22.5143,
			"l": 22.5125,
			"t": 1344889140000,
			"n": 7
		  },
		  {
			"v": 95788,
			"vw": 22.5139,
			"o": 22.5143,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5125,
			"t": 1344889200000,
			"n": 12
		  },
		  {
			"v": 2800,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1344889260000,
			"n": 1
		  },
		  {
			"v": 23660,
			"vw": 22.5163,
			"o": 22.5179,
			"c": 22.5125,
			"h": 22.5179,
			"l": 22.5125,
			"t": 1344889320000,
			"n": 5
		  },
		  {
			"v": 11200,
			"vw": 22.5134,
			"o": 22.5125,
			"c": 22.5143,
			"h": 22.5143,
			"l": 22.5125,
			"t": 1344889380000,
			"n": 2
		  },
		  {
			"v": 29176,
			"vw": 22.5167,
			"o": 22.5171,
			"c": 22.5132,
			"h": 22.5179,
			"l": 22.5132,
			"t": 1344889440000,
			"n": 5
		  },
		  {
			"v": 82936,
			"vw": 22.5175,
			"o": 22.5164,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5164,
			"t": 1344889500000,
			"n": 8
		  },
		  {
			"v": 56000,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1344889560000,
			"n": 14
		  },
		  {
			"v": 22484,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1344889620000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1344889680000,
			"n": 2
		  },
		  {
			"v": 50344,
			"vw": 22.4267,
			"o": 22.5196,
			"c": 22.5196,
			"h": 22.5196,
			"l": 22.5179,
			"t": 1344889740000,
			"n": 5
		  },
		  {
			"v": 46760,
			"vw": 22.5195,
			"o": 22.5179,
			"c": 22.5214,
			"h": 22.5214,
			"l": 22.5179,
			"t": 1344889800000,
			"n": 6
		  },
		  {
			"v": 19600,
			"vw": 22.5214,
			"o": 22.5214,
			"c": 22.5214,
			"h": 22.5214,
			"l": 22.5214,
			"t": 1344889860000,
			"n": 3
		  },
		  {
			"v": 36400,
			"vw": 22.5242,
			"o": 22.5182,
			"c": 22.525,
			"h": 22.525,
			"l": 22.5182,
			"t": 1344889920000,
			"n": 4
		  },
		  {
			"v": 46060,
			"vw": 22.5294,
			"o": 22.5286,
			"c": 22.5304,
			"h": 22.5304,
			"l": 22.5286,
			"t": 1344890160000,
			"n": 10
		  },
		  {
			"v": 87220,
			"vw": 22.5329,
			"o": 22.5282,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5282,
			"t": 1344890280000,
			"n": 14
		  },
		  {
			"v": 16800,
			"vw": 22.5357,
			"o": 22.5357,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5357,
			"t": 1344890340000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5393,
			"o": 22.5393,
			"c": 22.5393,
			"h": 22.5393,
			"l": 22.5393,
			"t": 1344890460000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5382,
			"o": 22.5386,
			"c": 22.5379,
			"h": 22.5386,
			"l": 22.5379,
			"t": 1344890520000,
			"n": 2
		  },
		  {
			"v": 15092,
			"vw": 22.5425,
			"o": 22.5425,
			"c": 22.5425,
			"h": 22.5425,
			"l": 22.5425,
			"t": 1344890580000,
			"n": 4
		  },
		  {
			"v": 25228,
			"vw": 22.5408,
			"o": 22.5429,
			"c": 22.5446,
			"h": 22.5446,
			"l": 22.5393,
			"t": 1344890760000,
			"n": 6
		  },
		  {
			"v": 14000,
			"vw": 22.5446,
			"o": 22.5446,
			"c": 22.5446,
			"h": 22.5446,
			"l": 22.5446,
			"t": 1344890820000,
			"n": 1
		  },
		  {
			"v": 85876,
			"vw": 22.5393,
			"o": 22.5393,
			"c": 22.5454,
			"h": 22.5454,
			"l": 22.535,
			"t": 1344890880000,
			"n": 17
		  },
		  {
			"v": 2800,
			"vw": 22.5364,
			"o": 22.5364,
			"c": 22.5364,
			"h": 22.5364,
			"l": 22.5364,
			"t": 1344890940000,
			"n": 1
		  },
		  {
			"v": 46704,
			"vw": 22.5357,
			"o": 22.5354,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5354,
			"t": 1344891060000,
			"n": 6
		  },
		  {
			"v": 19600,
			"vw": 22.5376,
			"o": 22.5436,
			"c": 22.5357,
			"h": 22.5436,
			"l": 22.5357,
			"t": 1344891120000,
			"n": 5
		  },
		  {
			"v": 12600,
			"vw": 22.539,
			"o": 22.5361,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5361,
			"t": 1344891360000,
			"n": 3
		  },
		  {
			"v": 14700,
			"vw": 22.5401,
			"o": 22.5393,
			"c": 22.5436,
			"h": 22.5436,
			"l": 22.5393,
			"t": 1344891420000,
			"n": 4
		  },
		  {
			"v": 42420,
			"vw": 22.5443,
			"o": 22.5436,
			"c": 22.545,
			"h": 22.545,
			"l": 22.5411,
			"t": 1344891480000,
			"n": 6
		  },
		  {
			"v": 5600,
			"vw": 22.5446,
			"o": 22.5429,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5429,
			"t": 1344891600000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.543,
			"o": 22.5432,
			"c": 22.5429,
			"h": 22.5432,
			"l": 22.5429,
			"t": 1344891660000,
			"n": 2
		  },
		  {
			"v": 37240,
			"vw": 22.5442,
			"o": 22.5454,
			"c": 22.5454,
			"h": 22.5454,
			"l": 22.5429,
			"t": 1344891720000,
			"n": 5
		  },
		  {
			"v": 5740,
			"vw": 22.5411,
			"o": 22.5411,
			"c": 22.5411,
			"h": 22.5411,
			"l": 22.5411,
			"t": 1344891780000,
			"n": 1
		  },
		  {
			"v": 7000,
			"vw": 22.5411,
			"o": 22.5411,
			"c": 22.5411,
			"h": 22.5411,
			"l": 22.5411,
			"t": 1344891840000,
			"n": 2
		  },
		  {
			"v": 23632,
			"vw": 22.5409,
			"o": 22.5411,
			"c": 22.5393,
			"h": 22.5411,
			"l": 22.5393,
			"t": 1344891960000,
			"n": 6
		  },
		  {
			"v": 7756,
			"vw": 22.5363,
			"o": 22.5368,
			"c": 22.5361,
			"h": 22.5368,
			"l": 22.5361,
			"t": 1344892020000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.5454,
			"o": 22.5454,
			"c": 22.5454,
			"h": 22.5454,
			"l": 22.5454,
			"t": 1344892080000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5454,
			"o": 22.5454,
			"c": 22.5454,
			"h": 22.5454,
			"l": 22.5454,
			"t": 1344892200000,
			"n": 1
		  },
		  {
			"v": 26152,
			"vw": 22.5459,
			"o": 22.5454,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5454,
			"t": 1344892260000,
			"n": 3
		  },
		  {
			"v": 11116,
			"vw": 22.5464,
			"o": 22.5464,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5464,
			"t": 1344892320000,
			"n": 2
		  },
		  {
			"v": 76496,
			"vw": 22.5508,
			"o": 22.5464,
			"c": 22.5482,
			"h": 22.5536,
			"l": 22.5464,
			"t": 1344892380000,
			"n": 10
		  },
		  {
			"v": 2800,
			"vw": 22.55,
			"o": 22.55,
			"c": 22.55,
			"h": 22.55,
			"l": 22.55,
			"t": 1344892440000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5534,
			"o": 22.5532,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5532,
			"t": 1344892500000,
			"n": 2
		  },
		  {
			"v": 26600,
			"vw": 22.5539,
			"o": 22.5539,
			"c": 22.5539,
			"h": 22.5539,
			"l": 22.5539,
			"t": 1344892560000,
			"n": 5
		  },
		  {
			"v": 89152,
			"vw": 22.5273,
			"o": 22.5475,
			"c": 22.5475,
			"h": 22.5475,
			"l": 22.5471,
			"t": 1344892620000,
			"n": 12
		  },
		  {
			"v": 2800,
			"vw": 22.5529,
			"o": 22.5529,
			"c": 22.5529,
			"h": 22.5529,
			"l": 22.5529,
			"t": 1344892740000,
			"n": 1
		  },
		  {
			"v": 15540,
			"vw": 22.55,
			"o": 22.55,
			"c": 22.55,
			"h": 22.55,
			"l": 22.55,
			"t": 1344892800000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5532,
			"o": 22.5532,
			"c": 22.5532,
			"h": 22.5532,
			"l": 22.5532,
			"t": 1344892980000,
			"n": 1
		  },
		  {
			"v": 17500,
			"vw": 22.5442,
			"o": 22.5446,
			"c": 22.5432,
			"h": 22.5446,
			"l": 22.5432,
			"t": 1344893040000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.543,
			"o": 22.5432,
			"c": 22.5429,
			"h": 22.5432,
			"l": 22.5429,
			"t": 1344893100000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5446,
			"o": 22.5446,
			"c": 22.5446,
			"h": 22.5446,
			"l": 22.5446,
			"t": 1344893160000,
			"n": 2
		  },
		  {
			"v": 51464,
			"vw": 22.5429,
			"o": 22.5429,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5429,
			"t": 1344893280000,
			"n": 4
		  },
		  {
			"v": 8400,
			"vw": 22.5411,
			"o": 22.5411,
			"c": 22.5411,
			"h": 22.5411,
			"l": 22.5411,
			"t": 1344893460000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5446,
			"o": 22.5446,
			"c": 22.5446,
			"h": 22.5446,
			"l": 22.5446,
			"t": 1344893520000,
			"n": 1
		  },
		  {
			"v": 12572,
			"vw": 22.5393,
			"o": 22.5393,
			"c": 22.5393,
			"h": 22.5393,
			"l": 22.5393,
			"t": 1344893580000,
			"n": 1
		  },
		  {
			"v": 22400,
			"vw": 22.5411,
			"o": 22.5393,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5393,
			"t": 1344893640000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.544,
			"o": 22.5429,
			"c": 22.5446,
			"h": 22.5446,
			"l": 22.5429,
			"t": 1344893700000,
			"n": 2
		  },
		  {
			"v": 19012,
			"vw": 22.5405,
			"o": 22.5393,
			"c": 22.5393,
			"h": 22.5429,
			"l": 22.5393,
			"t": 1344894000000,
			"n": 3
		  },
		  {
			"v": 3920,
			"vw": 22.5429,
			"o": 22.5429,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5429,
			"t": 1344894060000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5529,
			"o": 22.5529,
			"c": 22.5529,
			"h": 22.5529,
			"l": 22.5529,
			"t": 1344894300000,
			"n": 1
		  },
		  {
			"v": 28000,
			"vw": 22.5464,
			"o": 22.5464,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5464,
			"t": 1344894900000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.5518,
			"o": 22.5518,
			"c": 22.5518,
			"h": 22.5518,
			"l": 22.5518,
			"t": 1344894960000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5493,
			"o": 22.5493,
			"c": 22.5493,
			"h": 22.5493,
			"l": 22.5493,
			"t": 1344895260000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5429,
			"o": 22.5429,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5429,
			"t": 1344895800000,
			"n": 1
		  },
		  {
			"v": 6160,
			"vw": 22.545,
			"o": 22.5464,
			"c": 22.5432,
			"h": 22.5464,
			"l": 22.5432,
			"t": 1344895860000,
			"n": 2
		  },
		  {
			"v": 5180,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344896280000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344896340000,
			"n": 1
		  },
		  {
			"v": 22232,
			"vw": 22.5537,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5539,
			"l": 22.5536,
			"t": 1344896520000,
			"n": 5
		  },
		  {
			"v": 14000,
			"vw": 22.5525,
			"o": 22.5518,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.55,
			"t": 1344896580000,
			"n": 5
		  },
		  {
			"v": 3360,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344897060000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344897540000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5596,
			"o": 22.5596,
			"c": 22.5596,
			"h": 22.5596,
			"l": 22.5596,
			"t": 1344898440000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5589,
			"o": 22.5589,
			"c": 22.5589,
			"h": 22.5589,
			"l": 22.5589,
			"t": 1344898680000,
			"n": 1
		  },
		  {
			"v": 5740,
			"vw": 22.5607,
			"o": 22.5604,
			"c": 22.5611,
			"h": 22.5611,
			"l": 22.5604,
			"t": 1344899520000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5621,
			"o": 22.5618,
			"c": 22.5625,
			"h": 22.5625,
			"l": 22.5618,
			"t": 1344899580000,
			"n": 2
		  },
		  {
			"v": 10360,
			"vw": 22.5628,
			"o": 22.5643,
			"c": 22.5611,
			"h": 22.5643,
			"l": 22.5611,
			"t": 1344899640000,
			"n": 2
		  },
		  {
			"v": 16800,
			"vw": 22.566,
			"o": 22.5707,
			"c": 22.5539,
			"h": 22.5714,
			"l": 22.5539,
			"t": 1344899700000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.5568,
			"o": 22.5568,
			"c": 22.5568,
			"h": 22.5568,
			"l": 22.5568,
			"t": 1344899760000,
			"n": 1
		  },
		  {
			"v": 13160,
			"vw": 22.554,
			"o": 22.5543,
			"c": 22.5536,
			"h": 22.5543,
			"l": 22.5536,
			"t": 1344899820000,
			"n": 3
		  },
		  {
			"v": 11200,
			"vw": 22.5543,
			"o": 22.5543,
			"c": 22.5543,
			"h": 22.5543,
			"l": 22.5543,
			"t": 1344899940000,
			"n": 2
		  },
		  {
			"v": 9800,
			"vw": 22.5539,
			"o": 22.5539,
			"c": 22.5539,
			"h": 22.5539,
			"l": 22.5539,
			"t": 1344900000000,
			"n": 1
		  },
		  {
			"v": 24360,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344900060000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.5571,
			"o": 22.5571,
			"c": 22.5571,
			"h": 22.5571,
			"l": 22.5571,
			"t": 1344900120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5554,
			"o": 22.5554,
			"c": 22.5554,
			"h": 22.5554,
			"l": 22.5554,
			"t": 1344900180000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344900300000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344900840000,
			"n": 2
		  },
		  {
			"v": 3500,
			"vw": 22.5518,
			"o": 22.5518,
			"c": 22.5518,
			"h": 22.5518,
			"l": 22.5518,
			"t": 1344900960000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5532,
			"o": 22.5532,
			"c": 22.5532,
			"h": 22.5532,
			"l": 22.5532,
			"t": 1344901140000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5536,
			"o": 22.5536,
			"c": 22.5536,
			"h": 22.5536,
			"l": 22.5536,
			"t": 1344901500000,
			"n": 2
		  },
		  {
			"v": 55160,
			"vw": 22.5533,
			"o": 22.5543,
			"c": 22.5529,
			"h": 22.5543,
			"l": 22.5529,
			"t": 1344901560000,
			"n": 6
		  },
		  {
			"v": 33684,
			"vw": 22.553,
			"o": 22.5536,
			"c": 22.5504,
			"h": 22.5536,
			"l": 22.5504,
			"t": 1344901620000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.55,
			"o": 22.55,
			"c": 22.55,
			"h": 22.55,
			"l": 22.55,
			"t": 1344901740000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.55,
			"o": 22.55,
			"c": 22.55,
			"h": 22.55,
			"l": 22.55,
			"t": 1344901800000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.55,
			"o": 22.55,
			"c": 22.55,
			"h": 22.55,
			"l": 22.55,
			"t": 1344901860000,
			"n": 1
		  },
		  {
			"v": 12740,
			"vw": 22.5429,
			"o": 22.5432,
			"c": 22.5429,
			"h": 22.5432,
			"l": 22.5429,
			"t": 1344901920000,
			"n": 2
		  },
		  {
			"v": 15316,
			"vw": 22.5422,
			"o": 22.5429,
			"c": 22.5411,
			"h": 22.5429,
			"l": 22.5411,
			"t": 1344901980000,
			"n": 3
		  },
		  {
			"v": 52808,
			"vw": 22.5371,
			"o": 22.5393,
			"c": 22.5357,
			"h": 22.5393,
			"l": 22.5357,
			"t": 1344902160000,
			"n": 8
		  },
		  {
			"v": 12628,
			"vw": 22.5489,
			"o": 22.5489,
			"c": 22.5489,
			"h": 22.5489,
			"l": 22.5489,
			"t": 1344902220000,
			"n": 1
		  },
		  {
			"v": 15428,
			"vw": 22.5388,
			"o": 22.5429,
			"c": 22.5357,
			"h": 22.5429,
			"l": 22.5357,
			"t": 1344902280000,
			"n": 4
		  },
		  {
			"v": 13944,
			"vw": 22.5357,
			"o": 22.5357,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5357,
			"t": 1344902340000,
			"n": 2
		  },
		  {
			"v": 9800,
			"vw": 22.6158,
			"o": 22.6225,
			"c": 22.6107,
			"h": 22.6225,
			"l": 22.6107,
			"t": 1344931200000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5893,
			"o": 22.5893,
			"c": 22.5893,
			"h": 22.5893,
			"l": 22.5893,
			"t": 1344931560000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.593,
			"o": 22.5932,
			"c": 22.5929,
			"h": 22.5932,
			"l": 22.5929,
			"t": 1344931980000,
			"n": 2
		  },
		  {
			"v": 5180,
			"vw": 22.6211,
			"o": 22.6211,
			"c": 22.6211,
			"h": 22.6211,
			"l": 22.6211,
			"t": 1344932520000,
			"n": 1
		  },
		  {
			"v": 4200,
			"vw": 22.6375,
			"o": 22.6375,
			"c": 22.6375,
			"h": 22.6375,
			"l": 22.6375,
			"t": 1344932760000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6375,
			"o": 22.6375,
			"c": 22.6375,
			"h": 22.6375,
			"l": 22.6375,
			"t": 1344932820000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6214,
			"o": 22.6214,
			"c": 22.6214,
			"h": 22.6214,
			"l": 22.6214,
			"t": 1344933120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.625,
			"o": 22.625,
			"c": 22.625,
			"h": 22.625,
			"l": 22.625,
			"t": 1344933600000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6079,
			"o": 22.6079,
			"c": 22.6079,
			"h": 22.6079,
			"l": 22.6079,
			"t": 1344933900000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6521,
			"o": 22.6521,
			"c": 22.6521,
			"h": 22.6521,
			"l": 22.6521,
			"t": 1344934320000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6464,
			"o": 22.6464,
			"c": 22.6464,
			"h": 22.6464,
			"l": 22.6464,
			"t": 1344934560000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.6521,
			"o": 22.6521,
			"c": 22.6521,
			"h": 22.6521,
			"l": 22.6521,
			"t": 1344934680000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6464,
			"o": 22.6464,
			"c": 22.6464,
			"h": 22.6464,
			"l": 22.6464,
			"t": 1344934740000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6091,
			"o": 22.6093,
			"c": 22.6089,
			"h": 22.6093,
			"l": 22.6089,
			"t": 1344934980000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.6482,
			"o": 22.6482,
			"c": 22.6482,
			"h": 22.6482,
			"l": 22.6482,
			"t": 1344935100000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.6429,
			"o": 22.6429,
			"c": 22.6429,
			"h": 22.6429,
			"l": 22.6429,
			"t": 1344935580000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6429,
			"o": 22.6429,
			"c": 22.6429,
			"h": 22.6429,
			"l": 22.6429,
			"t": 1344936660000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.6357,
			"o": 22.6357,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.6357,
			"t": 1344936840000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.6339,
			"o": 22.6339,
			"c": 22.6339,
			"h": 22.6339,
			"l": 22.6339,
			"t": 1344937080000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6339,
			"o": 22.6339,
			"c": 22.6339,
			"h": 22.6339,
			"l": 22.6339,
			"t": 1344937560000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6214,
			"o": 22.6214,
			"c": 22.6214,
			"h": 22.6214,
			"l": 22.6214,
			"t": 1344937980000,
			"n": 2
		  },
		  {
			"v": 16660,
			"vw": 22.627,
			"o": 22.61,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.61,
			"t": 1344938040000,
			"n": 4
		  },
		  {
			"v": 14000,
			"vw": 22.6357,
			"o": 22.6357,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.6357,
			"t": 1344938100000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.6357,
			"o": 22.6357,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.6357,
			"t": 1344938160000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6354,
			"o": 22.6354,
			"c": 22.6354,
			"h": 22.6354,
			"l": 22.6354,
			"t": 1344938220000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6261,
			"o": 22.6261,
			"c": 22.6261,
			"h": 22.6261,
			"l": 22.6261,
			"t": 1344938520000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6354,
			"o": 22.6354,
			"c": 22.6354,
			"h": 22.6354,
			"l": 22.6354,
			"t": 1344938880000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6357,
			"o": 22.6357,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.6357,
			"t": 1344938940000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6495,
			"o": 22.6493,
			"c": 22.6496,
			"h": 22.6496,
			"l": 22.6493,
			"t": 1344939240000,
			"n": 2
		  },
		  {
			"v": 20440,
			"vw": 22.6358,
			"o": 22.6361,
			"c": 22.6357,
			"h": 22.6361,
			"l": 22.6357,
			"t": 1344939900000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.6493,
			"o": 22.6493,
			"c": 22.6493,
			"h": 22.6493,
			"l": 22.6493,
			"t": 1344940620000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6496,
			"o": 22.6496,
			"c": 22.6496,
			"h": 22.6496,
			"l": 22.6496,
			"t": 1344941460000,
			"n": 1
		  },
		  {
			"v": 30800,
			"vw": 22.6342,
			"o": 22.6357,
			"c": 22.6339,
			"h": 22.6357,
			"l": 22.6339,
			"t": 1344941820000,
			"n": 3
		  },
		  {
			"v": 34132,
			"vw": 22.6394,
			"o": 22.6389,
			"c": 22.6425,
			"h": 22.6425,
			"l": 22.6389,
			"t": 1344942000000,
			"n": 8
		  },
		  {
			"v": 28168,
			"vw": 22.6375,
			"o": 22.6393,
			"c": 22.6389,
			"h": 22.6393,
			"l": 22.6339,
			"t": 1344942060000,
			"n": 6
		  },
		  {
			"v": 25200,
			"vw": 22.632,
			"o": 22.6293,
			"c": 22.6346,
			"h": 22.635,
			"l": 22.62,
			"t": 1344942180000,
			"n": 6
		  },
		  {
			"v": 2800,
			"vw": 22.6261,
			"o": 22.6261,
			"c": 22.6261,
			"h": 22.6261,
			"l": 22.6261,
			"t": 1344942240000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6182,
			"o": 22.6182,
			"c": 22.6182,
			"h": 22.6182,
			"l": 22.6182,
			"t": 1344942300000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6194,
			"o": 22.6182,
			"c": 22.6161,
			"h": 22.6239,
			"l": 22.6161,
			"t": 1344942360000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.6161,
			"o": 22.6161,
			"c": 22.6161,
			"h": 22.6161,
			"l": 22.6161,
			"t": 1344942420000,
			"n": 2
		  },
		  {
			"v": 4172,
			"vw": 22.6143,
			"o": 22.6143,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6143,
			"t": 1344942480000,
			"n": 1
		  },
		  {
			"v": 15820,
			"vw": 22.6051,
			"o": 22.6121,
			"c": 22.6036,
			"h": 22.6121,
			"l": 22.6036,
			"t": 1344942600000,
			"n": 2
		  },
		  {
			"v": 26320,
			"vw": 22.6111,
			"o": 22.6036,
			"c": 22.6246,
			"h": 22.6246,
			"l": 22.6036,
			"t": 1344942660000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.6139,
			"o": 22.6139,
			"c": 22.6139,
			"h": 22.6139,
			"l": 22.6139,
			"t": 1344942780000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6125,
			"o": 22.6107,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6107,
			"t": 1344942960000,
			"n": 2
		  },
		  {
			"v": 13272,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6071,
			"t": 1344943080000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5985,
			"o": 22.5982,
			"c": 22.5982,
			"h": 22.5989,
			"l": 22.5982,
			"t": 1344943560000,
			"n": 3
		  },
		  {
			"v": 11228,
			"vw": 22.6059,
			"o": 22.605,
			"c": 22.6064,
			"h": 22.6064,
			"l": 22.605,
			"t": 1344943860000,
			"n": 4
		  },
		  {
			"v": 8400,
			"vw": 22.6032,
			"o": 22.5968,
			"c": 22.6064,
			"h": 22.6064,
			"l": 22.5968,
			"t": 1344943920000,
			"n": 2
		  },
		  {
			"v": 56672,
			"vw": 22.608,
			"o": 22.6036,
			"c": 22.6096,
			"h": 22.6143,
			"l": 22.6036,
			"t": 1344944160000,
			"n": 10
		  },
		  {
			"v": 16800,
			"vw": 22.6135,
			"o": 22.6118,
			"c": 22.6139,
			"h": 22.6143,
			"l": 22.6118,
			"t": 1344944280000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.6073,
			"o": 22.6075,
			"c": 22.6071,
			"h": 22.6075,
			"l": 22.6071,
			"t": 1344944340000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.5979,
			"o": 22.5986,
			"c": 22.5964,
			"h": 22.5986,
			"l": 22.5964,
			"t": 1344944400000,
			"n": 3
		  },
		  {
			"v": 14000,
			"vw": 22.596,
			"o": 22.5989,
			"c": 22.5918,
			"h": 22.5989,
			"l": 22.5918,
			"t": 1344944460000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.5914,
			"o": 22.5914,
			"c": 22.5914,
			"h": 22.5914,
			"l": 22.5914,
			"t": 1344944520000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.5953,
			"o": 22.5954,
			"c": 22.595,
			"h": 22.5954,
			"l": 22.595,
			"t": 1344944580000,
			"n": 6
		  },
		  {
			"v": 10948,
			"vw": 22.5903,
			"o": 22.5914,
			"c": 22.5879,
			"h": 22.5914,
			"l": 22.5879,
			"t": 1344944640000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5929,
			"o": 22.5929,
			"c": 22.5929,
			"h": 22.5929,
			"l": 22.5929,
			"t": 1344944700000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5893,
			"o": 22.5893,
			"c": 22.5893,
			"h": 22.5893,
			"l": 22.5893,
			"t": 1344944760000,
			"n": 1
		  },
		  {
			"v": 19824,
			"vw": 22.5817,
			"o": 22.5786,
			"c": 22.5857,
			"h": 22.5857,
			"l": 22.5786,
			"t": 1344944820000,
			"n": 5
		  },
		  {
			"v": 39200,
			"vw": 22.5953,
			"o": 22.5954,
			"c": 22.5993,
			"h": 22.5993,
			"l": 22.5946,
			"t": 1344944880000,
			"n": 13
		  },
		  {
			"v": 2800,
			"vw": 22.5979,
			"o": 22.5979,
			"c": 22.5979,
			"h": 22.5979,
			"l": 22.5979,
			"t": 1344944940000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6021,
			"o": 22.6021,
			"c": 22.6021,
			"h": 22.6021,
			"l": 22.6021,
			"t": 1344945060000,
			"n": 2
		  },
		  {
			"v": 58660,
			"vw": 22.6022,
			"o": 22.6025,
			"c": 22.6021,
			"h": 22.6025,
			"l": 22.6021,
			"t": 1344945120000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.6068,
			"o": 22.6068,
			"c": 22.6068,
			"h": 22.6068,
			"l": 22.6068,
			"t": 1344945180000,
			"n": 1
		  },
		  {
			"v": 26180,
			"vw": 22.609,
			"o": 22.6071,
			"c": 22.6036,
			"h": 22.6139,
			"l": 22.6036,
			"t": 1344945240000,
			"n": 6
		  },
		  {
			"v": 8400,
			"vw": 22.6139,
			"o": 22.6139,
			"c": 22.6139,
			"h": 22.6139,
			"l": 22.6139,
			"t": 1344945360000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.6143,
			"o": 22.6143,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6143,
			"t": 1344945420000,
			"n": 3
		  },
		  {
			"v": 40152,
			"vw": 22.6089,
			"o": 22.6089,
			"c": 22.61,
			"h": 22.6111,
			"l": 22.6071,
			"t": 1344945480000,
			"n": 5
		  },
		  {
			"v": 280700,
			"vw": 22.5909,
			"o": 22.6071,
			"c": 22.6025,
			"h": 22.6143,
			"l": 22.5536,
			"t": 1344945600000,
			"n": 51
		  },
		  {
			"v": 34664,
			"vw": 22.6059,
			"o": 22.6046,
			"c": 22.6054,
			"h": 22.6068,
			"l": 22.6046,
			"t": 1344945660000,
			"n": 11
		  },
		  {
			"v": 70252,
			"vw": 22.6129,
			"o": 22.6071,
			"c": 22.6086,
			"h": 22.6179,
			"l": 22.6071,
			"t": 1344945720000,
			"n": 18
		  },
		  {
			"v": 75600,
			"vw": 22.61,
			"o": 22.6114,
			"c": 22.6071,
			"h": 22.6171,
			"l": 22.6064,
			"t": 1344945780000,
			"n": 23
		  },
		  {
			"v": 35056,
			"vw": 22.6116,
			"o": 22.6161,
			"c": 22.615,
			"h": 22.6193,
			"l": 22.6071,
			"t": 1344945840000,
			"n": 8
		  },
		  {
			"v": 78400,
			"vw": 22.6076,
			"o": 22.6079,
			"c": 22.6104,
			"h": 22.6104,
			"l": 22.6071,
			"t": 1344945900000,
			"n": 14
		  },
		  {
			"v": 80640,
			"vw": 22.6154,
			"o": 22.6143,
			"c": 22.6143,
			"h": 22.6214,
			"l": 22.6139,
			"t": 1344945960000,
			"n": 16
		  },
		  {
			"v": 50624,
			"vw": 22.618,
			"o": 22.6143,
			"c": 22.6211,
			"h": 22.6211,
			"l": 22.6114,
			"t": 1344946020000,
			"n": 9
		  },
		  {
			"v": 9800,
			"vw": 22.6185,
			"o": 22.6161,
			"c": 22.6196,
			"h": 22.6196,
			"l": 22.6161,
			"t": 1344946080000,
			"n": 3
		  },
		  {
			"v": 14616,
			"vw": 22.617,
			"o": 22.6157,
			"c": 22.6146,
			"h": 22.6207,
			"l": 22.6146,
			"t": 1344946140000,
			"n": 5
		  },
		  {
			"v": 8400,
			"vw": 22.6144,
			"o": 22.6146,
			"c": 22.6143,
			"h": 22.6146,
			"l": 22.6143,
			"t": 1344946200000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.615,
			"o": 22.615,
			"c": 22.615,
			"h": 22.615,
			"l": 22.615,
			"t": 1344946320000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6208,
			"o": 22.6204,
			"c": 22.6211,
			"h": 22.6211,
			"l": 22.6204,
			"t": 1344946380000,
			"n": 2
		  },
		  {
			"v": 26320,
			"vw": 22.6186,
			"o": 22.6189,
			"c": 22.6189,
			"h": 22.6189,
			"l": 22.6179,
			"t": 1344946440000,
			"n": 5
		  },
		  {
			"v": 8484,
			"vw": 22.6179,
			"o": 22.6179,
			"c": 22.6179,
			"h": 22.6179,
			"l": 22.6179,
			"t": 1344946500000,
			"n": 1
		  },
		  {
			"v": 43400,
			"vw": 22.6156,
			"o": 22.6161,
			"c": 22.6143,
			"h": 22.6179,
			"l": 22.6143,
			"t": 1344946560000,
			"n": 11
		  },
		  {
			"v": 19824,
			"vw": 22.6147,
			"o": 22.6171,
			"c": 22.6143,
			"h": 22.6171,
			"l": 22.6143,
			"t": 1344946620000,
			"n": 2
		  },
		  {
			"v": 42000,
			"vw": 22.6111,
			"o": 22.6129,
			"c": 22.6093,
			"h": 22.6129,
			"l": 22.6093,
			"t": 1344946740000,
			"n": 5
		  },
		  {
			"v": 30212,
			"vw": 22.61,
			"o": 22.6125,
			"c": 22.6082,
			"h": 22.6139,
			"l": 22.6071,
			"t": 1344946800000,
			"n": 9
		  },
		  {
			"v": 27692,
			"vw": 22.6093,
			"o": 22.6079,
			"c": 22.6086,
			"h": 22.6139,
			"l": 22.6079,
			"t": 1344946860000,
			"n": 8
		  },
		  {
			"v": 4844,
			"vw": 22.6075,
			"o": 22.6075,
			"c": 22.6075,
			"h": 22.6075,
			"l": 22.6075,
			"t": 1344946920000,
			"n": 1
		  },
		  {
			"v": 12880,
			"vw": 22.6132,
			"o": 22.6111,
			"c": 22.6125,
			"h": 22.6146,
			"l": 22.6111,
			"t": 1344946980000,
			"n": 4
		  },
		  {
			"v": 33600,
			"vw": 22.6129,
			"o": 22.6125,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6125,
			"t": 1344947040000,
			"n": 7
		  },
		  {
			"v": 16800,
			"vw": 22.6124,
			"o": 22.6143,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6096,
			"t": 1344947100000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.6143,
			"o": 22.6143,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6143,
			"t": 1344947160000,
			"n": 2
		  },
		  {
			"v": 26516,
			"vw": 22.6087,
			"o": 22.6089,
			"c": 22.6132,
			"h": 22.6132,
			"l": 22.6075,
			"t": 1344947220000,
			"n": 6
		  },
		  {
			"v": 46452,
			"vw": 22.6146,
			"o": 22.6136,
			"c": 22.6179,
			"h": 22.6179,
			"l": 22.6132,
			"t": 1344947280000,
			"n": 7
		  },
		  {
			"v": 14364,
			"vw": 22.6196,
			"o": 22.6204,
			"c": 22.6214,
			"h": 22.6214,
			"l": 22.6179,
			"t": 1344947340000,
			"n": 4
		  },
		  {
			"v": 94780,
			"vw": 22.6234,
			"o": 22.6214,
			"c": 22.625,
			"h": 22.6279,
			"l": 22.6211,
			"t": 1344947400000,
			"n": 26
		  },
		  {
			"v": 76468,
			"vw": 22.6291,
			"o": 22.6246,
			"c": 22.6321,
			"h": 22.6321,
			"l": 22.6246,
			"t": 1344947460000,
			"n": 23
		  },
		  {
			"v": 42420,
			"vw": 22.6277,
			"o": 22.6304,
			"c": 22.6268,
			"h": 22.6304,
			"l": 22.6261,
			"t": 1344947520000,
			"n": 11
		  },
		  {
			"v": 84028,
			"vw": 22.6325,
			"o": 22.6304,
			"c": 22.6311,
			"h": 22.635,
			"l": 22.63,
			"t": 1344947580000,
			"n": 22
		  },
		  {
			"v": 23100,
			"vw": 22.6323,
			"o": 22.6336,
			"c": 22.6321,
			"h": 22.6336,
			"l": 22.6304,
			"t": 1344947640000,
			"n": 6
		  },
		  {
			"v": 84896,
			"vw": 22.634,
			"o": 22.6321,
			"c": 22.6338,
			"h": 22.6354,
			"l": 22.6321,
			"t": 1344947700000,
			"n": 22
		  },
		  {
			"v": 70056,
			"vw": 22.6325,
			"o": 22.6325,
			"c": 22.6336,
			"h": 22.6339,
			"l": 22.6318,
			"t": 1344947760000,
			"n": 15
		  },
		  {
			"v": 38388,
			"vw": 22.6335,
			"o": 22.6339,
			"c": 22.6339,
			"h": 22.6339,
			"l": 22.6325,
			"t": 1344947820000,
			"n": 8
		  },
		  {
			"v": 89768,
			"vw": 22.6355,
			"o": 22.6339,
			"c": 22.6371,
			"h": 22.6371,
			"l": 22.6336,
			"t": 1344947880000,
			"n": 24
		  },
		  {
			"v": 109508,
			"vw": 22.6363,
			"o": 22.6389,
			"c": 22.6321,
			"h": 22.6393,
			"l": 22.6321,
			"t": 1344947940000,
			"n": 20
		  },
		  {
			"v": 22400,
			"vw": 22.6342,
			"o": 22.6304,
			"c": 22.6357,
			"h": 22.6357,
			"l": 22.6304,
			"t": 1344948000000,
			"n": 7
		  },
		  {
			"v": 97076,
			"vw": 22.6319,
			"o": 22.6379,
			"c": 22.6275,
			"h": 22.6379,
			"l": 22.6275,
			"t": 1344948060000,
			"n": 26
		  },
		  {
			"v": 36316,
			"vw": 22.6367,
			"o": 22.6357,
			"c": 22.6382,
			"h": 22.6382,
			"l": 22.6339,
			"t": 1344948120000,
			"n": 11
		  },
		  {
			"v": 58660,
			"vw": 22.6292,
			"o": 22.6339,
			"c": 22.6239,
			"h": 22.6339,
			"l": 22.6239,
			"t": 1344948180000,
			"n": 12
		  },
		  {
			"v": 128576,
			"vw": 22.6178,
			"o": 22.6239,
			"c": 22.6129,
			"h": 22.6239,
			"l": 22.6125,
			"t": 1344948240000,
			"n": 35
		  },
		  {
			"v": 25200,
			"vw": 22.6121,
			"o": 22.6114,
			"c": 22.6125,
			"h": 22.615,
			"l": 22.6107,
			"t": 1344948300000,
			"n": 9
		  },
		  {
			"v": 181888,
			"vw": 22.6041,
			"o": 22.6096,
			"c": 22.5968,
			"h": 22.6121,
			"l": 22.5968,
			"t": 1344948360000,
			"n": 42
		  },
		  {
			"v": 84000,
			"vw": 22.6026,
			"o": 22.5971,
			"c": 22.6036,
			"h": 22.6054,
			"l": 22.5971,
			"t": 1344948420000,
			"n": 25
		  },
		  {
			"v": 56980,
			"vw": 22.6048,
			"o": 22.6036,
			"c": 22.6068,
			"h": 22.6071,
			"l": 22.6004,
			"t": 1344948480000,
			"n": 18
		  },
		  {
			"v": 23520,
			"vw": 22.6047,
			"o": 22.6071,
			"c": 22.6054,
			"h": 22.6071,
			"l": 22.6007,
			"t": 1344948540000,
			"n": 7
		  },
		  {
			"v": 94164,
			"vw": 22.6017,
			"o": 22.6057,
			"c": 22.6043,
			"h": 22.6071,
			"l": 22.5964,
			"t": 1344948600000,
			"n": 25
		  },
		  {
			"v": 44576,
			"vw": 22.5971,
			"o": 22.5975,
			"c": 22.5975,
			"h": 22.6,
			"l": 22.5936,
			"t": 1344948660000,
			"n": 11
		  },
		  {
			"v": 47040,
			"vw": 22.5982,
			"o": 22.5982,
			"c": 22.5979,
			"h": 22.6,
			"l": 22.5957,
			"t": 1344948720000,
			"n": 11
		  },
		  {
			"v": 91392,
			"vw": 22.6003,
			"o": 22.6,
			"c": 22.6064,
			"h": 22.6071,
			"l": 22.5954,
			"t": 1344948780000,
			"n": 29
		  },
		  {
			"v": 19600,
			"vw": 22.6042,
			"o": 22.6032,
			"c": 22.6039,
			"h": 22.6054,
			"l": 22.6032,
			"t": 1344948840000,
			"n": 6
		  },
		  {
			"v": 49000,
			"vw": 22.5988,
			"o": 22.6,
			"c": 22.6036,
			"h": 22.6036,
			"l": 22.5964,
			"t": 1344948900000,
			"n": 15
		  },
		  {
			"v": 83972,
			"vw": 22.6013,
			"o": 22.6032,
			"c": 22.5986,
			"h": 22.605,
			"l": 22.5964,
			"t": 1344948960000,
			"n": 21
		  },
		  {
			"v": 19600,
			"vw": 22.5982,
			"o": 22.5989,
			"c": 22.5982,
			"h": 22.5989,
			"l": 22.5975,
			"t": 1344949020000,
			"n": 5
		  },
		  {
			"v": 54040,
			"vw": 22.5984,
			"o": 22.5964,
			"c": 22.6,
			"h": 22.6025,
			"l": 22.5964,
			"t": 1344949080000,
			"n": 12
		  },
		  {
			"v": 8400,
			"vw": 22.5965,
			"o": 22.5968,
			"c": 22.5964,
			"h": 22.5968,
			"l": 22.5964,
			"t": 1344949140000,
			"n": 3
		  },
		  {
			"v": 19236,
			"vw": 22.5947,
			"o": 22.5946,
			"c": 22.595,
			"h": 22.595,
			"l": 22.5946,
			"t": 1344949200000,
			"n": 6
		  },
		  {
			"v": 36232,
			"vw": 22.5985,
			"o": 22.5979,
			"c": 22.5993,
			"h": 22.5993,
			"l": 22.5975,
			"t": 1344949260000,
			"n": 9
		  },
		  {
			"v": 64400,
			"vw": 22.6013,
			"o": 22.5993,
			"c": 22.6046,
			"h": 22.6046,
			"l": 22.5993,
			"t": 1344949320000,
			"n": 18
		  },
		  {
			"v": 19600,
			"vw": 22.6036,
			"o": 22.605,
			"c": 22.6043,
			"h": 22.605,
			"l": 22.6018,
			"t": 1344949380000,
			"n": 7
		  },
		  {
			"v": 42000,
			"vw": 22.6049,
			"o": 22.6043,
			"c": 22.6057,
			"h": 22.6057,
			"l": 22.6036,
			"t": 1344949440000,
			"n": 12
		  },
		  {
			"v": 11200,
			"vw": 22.6048,
			"o": 22.6046,
			"c": 22.6046,
			"h": 22.6054,
			"l": 22.6046,
			"t": 1344949500000,
			"n": 4
		  },
		  {
			"v": 55048,
			"vw": 22.6058,
			"o": 22.6054,
			"c": 22.6061,
			"h": 22.6071,
			"l": 22.6046,
			"t": 1344949560000,
			"n": 9
		  },
		  {
			"v": 19600,
			"vw": 22.6049,
			"o": 22.605,
			"c": 22.6054,
			"h": 22.6054,
			"l": 22.6046,
			"t": 1344949620000,
			"n": 5
		  },
		  {
			"v": 9800,
			"vw": 22.6039,
			"o": 22.6054,
			"c": 22.6039,
			"h": 22.6054,
			"l": 22.6029,
			"t": 1344949680000,
			"n": 3
		  },
		  {
			"v": 14840,
			"vw": 22.6044,
			"o": 22.6032,
			"c": 22.6054,
			"h": 22.6054,
			"l": 22.6032,
			"t": 1344949740000,
			"n": 4
		  },
		  {
			"v": 36484,
			"vw": 22.6076,
			"o": 22.6057,
			"c": 22.6082,
			"h": 22.6107,
			"l": 22.6057,
			"t": 1344949800000,
			"n": 11
		  },
		  {
			"v": 78400,
			"vw": 22.6123,
			"o": 22.6121,
			"c": 22.6125,
			"h": 22.6136,
			"l": 22.6118,
			"t": 1344949860000,
			"n": 14
		  },
		  {
			"v": 16800,
			"vw": 22.6085,
			"o": 22.6114,
			"c": 22.6071,
			"h": 22.6114,
			"l": 22.6071,
			"t": 1344949920000,
			"n": 6
		  },
		  {
			"v": 69972,
			"vw": 22.6023,
			"o": 22.6071,
			"c": 22.6014,
			"h": 22.6071,
			"l": 22.6004,
			"t": 1344949980000,
			"n": 15
		  },
		  {
			"v": 103740,
			"vw": 22.6003,
			"o": 22.6021,
			"c": 22.5968,
			"h": 22.6043,
			"l": 22.5964,
			"t": 1344950040000,
			"n": 25
		  },
		  {
			"v": 186816,
			"vw": 22.5924,
			"o": 22.5964,
			"c": 22.5829,
			"h": 22.5964,
			"l": 22.5829,
			"t": 1344950100000,
			"n": 32
		  },
		  {
			"v": 102032,
			"vw": 22.586,
			"o": 22.5854,
			"c": 22.5839,
			"h": 22.5893,
			"l": 22.5829,
			"t": 1344950160000,
			"n": 25
		  },
		  {
			"v": 33600,
			"vw": 22.5821,
			"o": 22.5832,
			"c": 22.585,
			"h": 22.585,
			"l": 22.5786,
			"t": 1344950220000,
			"n": 10
		  },
		  {
			"v": 30240,
			"vw": 22.5829,
			"o": 22.5807,
			"c": 22.5811,
			"h": 22.5864,
			"l": 22.5804,
			"t": 1344950280000,
			"n": 9
		  },
		  {
			"v": 74200,
			"vw": 22.5856,
			"o": 22.5836,
			"c": 22.5875,
			"h": 22.5875,
			"l": 22.5836,
			"t": 1344950400000,
			"n": 14
		  },
		  {
			"v": 17080,
			"vw": 22.5874,
			"o": 22.5875,
			"c": 22.5871,
			"h": 22.5875,
			"l": 22.5871,
			"t": 1344950460000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.5875,
			"o": 22.5875,
			"c": 22.5875,
			"h": 22.5875,
			"l": 22.5875,
			"t": 1344950520000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344950580000,
			"n": 1
		  },
		  {
			"v": 24360,
			"vw": 22.5849,
			"o": 22.5843,
			"c": 22.5868,
			"h": 22.5868,
			"l": 22.5843,
			"t": 1344950640000,
			"n": 7
		  },
		  {
			"v": 92204,
			"vw": 22.5857,
			"o": 22.5839,
			"c": 22.5875,
			"h": 22.5875,
			"l": 22.5839,
			"t": 1344950700000,
			"n": 12
		  },
		  {
			"v": 70644,
			"vw": 22.5877,
			"o": 22.5879,
			"c": 22.5839,
			"h": 22.5893,
			"l": 22.5839,
			"t": 1344950760000,
			"n": 17
		  },
		  {
			"v": 62272,
			"vw": 22.5811,
			"o": 22.5818,
			"c": 22.5804,
			"h": 22.5839,
			"l": 22.5786,
			"t": 1344950820000,
			"n": 12
		  },
		  {
			"v": 94864,
			"vw": 22.5742,
			"o": 22.5786,
			"c": 22.5714,
			"h": 22.5807,
			"l": 22.5714,
			"t": 1344950880000,
			"n": 18
		  },
		  {
			"v": 66388,
			"vw": 22.5705,
			"o": 22.5707,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5646,
			"t": 1344950940000,
			"n": 16
		  },
		  {
			"v": 8936620,
			"vw": 22.5809,
			"o": 22.5668,
			"c": 22.6106,
			"h": 22.6161,
			"l": 22.5661,
			"t": 1344951000000,
			"n": 1047
		  },
		  {
			"v": 3201968,
			"vw": 22.6095,
			"o": 22.6089,
			"c": 22.6157,
			"h": 22.6179,
			"l": 22.5964,
			"t": 1344951060000,
			"n": 770
		  },
		  {
			"v": 2701804,
			"vw": 22.6098,
			"o": 22.6175,
			"c": 22.6069,
			"h": 22.6179,
			"l": 22.6004,
			"t": 1344951120000,
			"n": 549
		  },
		  {
			"v": 2097956,
			"vw": 22.6027,
			"o": 22.6027,
			"c": 22.6071,
			"h": 22.6125,
			"l": 22.5893,
			"t": 1344951180000,
			"n": 495
		  },
		  {
			"v": 2466520,
			"vw": 22.595,
			"o": 22.6082,
			"c": 22.5882,
			"h": 22.6118,
			"l": 22.5814,
			"t": 1344951240000,
			"n": 631
		  },
		  {
			"v": 5048176,
			"vw": 22.6258,
			"o": 22.5879,
			"c": 22.6482,
			"h": 22.6546,
			"l": 22.5857,
			"t": 1344951300000,
			"n": 1161
		  },
		  {
			"v": 2223508,
			"vw": 22.6419,
			"o": 22.6421,
			"c": 22.6446,
			"h": 22.6507,
			"l": 22.6322,
			"t": 1344951360000,
			"n": 541
		  },
		  {
			"v": 1645224,
			"vw": 22.6453,
			"o": 22.6407,
			"c": 22.6511,
			"h": 22.6536,
			"l": 22.6339,
			"t": 1344951420000,
			"n": 393
		  },
		  {
			"v": 2422868,
			"vw": 22.6576,
			"o": 22.6498,
			"c": 22.6632,
			"h": 22.6675,
			"l": 22.6475,
			"t": 1344951480000,
			"n": 527
		  },
		  {
			"v": 2157624,
			"vw": 22.6643,
			"o": 22.6632,
			"c": 22.6579,
			"h": 22.6714,
			"l": 22.6568,
			"t": 1344951540000,
			"n": 482
		  },
		  {
			"v": 2352420,
			"vw": 22.649,
			"o": 22.6588,
			"c": 22.6436,
			"h": 22.6596,
			"l": 22.6396,
			"t": 1344951600000,
			"n": 584
		  },
		  {
			"v": 1527288,
			"vw": 22.6502,
			"o": 22.6436,
			"c": 22.6582,
			"h": 22.6589,
			"l": 22.6418,
			"t": 1344951660000,
			"n": 327
		  },
		  {
			"v": 1660848,
			"vw": 22.6545,
			"o": 22.6554,
			"c": 22.6661,
			"h": 22.6671,
			"l": 22.6454,
			"t": 1344951720000,
			"n": 386
		  },
		  {
			"v": 1774696,
			"vw": 22.6687,
			"o": 22.6661,
			"c": 22.6739,
			"h": 22.6739,
			"l": 22.6618,
			"t": 1344951780000,
			"n": 435
		  },
		  {
			"v": 1591100,
			"vw": 22.668,
			"o": 22.6697,
			"c": 22.6729,
			"h": 22.6739,
			"l": 22.6586,
			"t": 1344951840000,
			"n": 358
		  },
		  {
			"v": 1078056,
			"vw": 22.6687,
			"o": 22.6714,
			"c": 22.6691,
			"h": 22.6729,
			"l": 22.6629,
			"t": 1344951900000,
			"n": 248
		  },
		  {
			"v": 1144332,
			"vw": 22.6654,
			"o": 22.6682,
			"c": 22.6692,
			"h": 22.6707,
			"l": 22.66,
			"t": 1344951960000,
			"n": 291
		  },
		  {
			"v": 3773336,
			"vw": 22.6743,
			"o": 22.6668,
			"c": 22.6579,
			"h": 22.6893,
			"l": 22.6507,
			"t": 1344952020000,
			"n": 869
		  },
		  {
			"v": 3026016,
			"vw": 22.6452,
			"o": 22.6621,
			"c": 22.6479,
			"h": 22.6679,
			"l": 22.6321,
			"t": 1344952080000,
			"n": 658
		  },
		  {
			"v": 1021244,
			"vw": 22.649,
			"o": 22.6496,
			"c": 22.6507,
			"h": 22.6571,
			"l": 22.6389,
			"t": 1344952140000,
			"n": 252
		  },
		  {
			"v": 882700,
			"vw": 22.6511,
			"o": 22.6511,
			"c": 22.6493,
			"h": 22.6579,
			"l": 22.6436,
			"t": 1344952200000,
			"n": 205
		  },
		  {
			"v": 1267728,
			"vw": 22.641,
			"o": 22.6511,
			"c": 22.6329,
			"h": 22.6511,
			"l": 22.6329,
			"t": 1344952260000,
			"n": 305
		  },
		  {
			"v": 864360,
			"vw": 22.6369,
			"o": 22.6325,
			"c": 22.6434,
			"h": 22.645,
			"l": 22.6307,
			"t": 1344952320000,
			"n": 181
		  },
		  {
			"v": 807660,
			"vw": 22.635,
			"o": 22.6429,
			"c": 22.6326,
			"h": 22.646,
			"l": 22.6289,
			"t": 1344952380000,
			"n": 181
		  },
		  {
			"v": 798336,
			"vw": 22.6302,
			"o": 22.6321,
			"c": 22.6343,
			"h": 22.6346,
			"l": 22.6282,
			"t": 1344952440000,
			"n": 236
		  },
		  {
			"v": 961324,
			"vw": 22.6319,
			"o": 22.6343,
			"c": 22.6344,
			"h": 22.64,
			"l": 22.6282,
			"t": 1344952500000,
			"n": 251
		  },
		  {
			"v": 1421868,
			"vw": 22.6491,
			"o": 22.6389,
			"c": 22.6563,
			"h": 22.66,
			"l": 22.6361,
			"t": 1344952560000,
			"n": 341
		  },
		  {
			"v": 1300936,
			"vw": 22.6575,
			"o": 22.6575,
			"c": 22.6604,
			"h": 22.6607,
			"l": 22.6511,
			"t": 1344952620000,
			"n": 295
		  },
		  {
			"v": 976080,
			"vw": 22.66,
			"o": 22.6607,
			"c": 22.6575,
			"h": 22.6657,
			"l": 22.6521,
			"t": 1344952680000,
			"n": 202
		  },
		  {
			"v": 554568,
			"vw": 22.6591,
			"o": 22.655,
			"c": 22.6571,
			"h": 22.6632,
			"l": 22.6546,
			"t": 1344952740000,
			"n": 120
		  },
		  {
			"v": 1885660,
			"vw": 22.6731,
			"o": 22.6589,
			"c": 22.6796,
			"h": 22.6796,
			"l": 22.6575,
			"t": 1344952800000,
			"n": 413
		  },
		  {
			"v": 1646512,
			"vw": 22.6618,
			"o": 22.6807,
			"c": 22.6529,
			"h": 22.6807,
			"l": 22.6479,
			"t": 1344952860000,
			"n": 346
		  },
		  {
			"v": 969164,
			"vw": 22.6546,
			"o": 22.6536,
			"c": 22.6589,
			"h": 22.6643,
			"l": 22.6479,
			"t": 1344952920000,
			"n": 229
		  },
		  {
			"v": 810376,
			"vw": 22.662,
			"o": 22.6557,
			"c": 22.6696,
			"h": 22.6738,
			"l": 22.655,
			"t": 1344952980000,
			"n": 187
		  },
		  {
			"v": 724164,
			"vw": 22.667,
			"o": 22.6664,
			"c": 22.6704,
			"h": 22.6704,
			"l": 22.6607,
			"t": 1344953040000,
			"n": 149
		  },
		  {
			"v": 1980216,
			"vw": 22.681,
			"o": 22.6695,
			"c": 22.6929,
			"h": 22.6929,
			"l": 22.6682,
			"t": 1344953100000,
			"n": 444
		  },
		  {
			"v": 1513988,
			"vw": 22.6835,
			"o": 22.6918,
			"c": 22.6718,
			"h": 22.6929,
			"l": 22.67,
			"t": 1344953160000,
			"n": 344
		  },
		  {
			"v": 1544452,
			"vw": 22.6788,
			"o": 22.6707,
			"c": 22.6946,
			"h": 22.695,
			"l": 22.6657,
			"t": 1344953220000,
			"n": 383
		  },
		  {
			"v": 1984556,
			"vw": 22.6985,
			"o": 22.6911,
			"c": 22.6996,
			"h": 22.7054,
			"l": 22.6911,
			"t": 1344953280000,
			"n": 477
		  },
		  {
			"v": 1277080,
			"vw": 22.6936,
			"o": 22.6996,
			"c": 22.6868,
			"h": 22.7036,
			"l": 22.6836,
			"t": 1344953340000,
			"n": 303
		  },
		  {
			"v": 1475152,
			"vw": 22.6891,
			"o": 22.6868,
			"c": 22.6904,
			"h": 22.6936,
			"l": 22.6804,
			"t": 1344953400000,
			"n": 284
		  },
		  {
			"v": 765296,
			"vw": 22.6896,
			"o": 22.6918,
			"c": 22.6875,
			"h": 22.6936,
			"l": 22.6846,
			"t": 1344953460000,
			"n": 189
		  },
		  {
			"v": 886368,
			"vw": 22.6802,
			"o": 22.6878,
			"c": 22.6786,
			"h": 22.6904,
			"l": 22.6754,
			"t": 1344953520000,
			"n": 184
		  },
		  {
			"v": 935844,
			"vw": 22.6803,
			"o": 22.6786,
			"c": 22.6789,
			"h": 22.6853,
			"l": 22.6758,
			"t": 1344953580000,
			"n": 154
		  },
		  {
			"v": 535360,
			"vw": 22.6867,
			"o": 22.6793,
			"c": 22.6861,
			"h": 22.6907,
			"l": 22.6793,
			"t": 1344953640000,
			"n": 133
		  },
		  {
			"v": 270368,
			"vw": 22.6852,
			"o": 22.6871,
			"c": 22.6861,
			"h": 22.6889,
			"l": 22.6793,
			"t": 1344953700000,
			"n": 58
		  },
		  {
			"v": 1261428,
			"vw": 22.681,
			"o": 22.6821,
			"c": 22.6818,
			"h": 22.6889,
			"l": 22.6754,
			"t": 1344953760000,
			"n": 248
		  },
		  {
			"v": 397236,
			"vw": 22.6789,
			"o": 22.6814,
			"c": 22.6793,
			"h": 22.6857,
			"l": 22.675,
			"t": 1344953820000,
			"n": 103
		  },
		  {
			"v": 831236,
			"vw": 22.6787,
			"o": 22.6761,
			"c": 22.6793,
			"h": 22.6871,
			"l": 22.6736,
			"t": 1344953880000,
			"n": 158
		  },
		  {
			"v": 955388,
			"vw": 22.6809,
			"o": 22.6807,
			"c": 22.6889,
			"h": 22.6904,
			"l": 22.6707,
			"t": 1344953940000,
			"n": 247
		  },
		  {
			"v": 503552,
			"vw": 22.6826,
			"o": 22.6889,
			"c": 22.6771,
			"h": 22.6889,
			"l": 22.6771,
			"t": 1344954000000,
			"n": 115
		  },
		  {
			"v": 1003688,
			"vw": 22.6701,
			"o": 22.6771,
			"c": 22.6711,
			"h": 22.6793,
			"l": 22.6646,
			"t": 1344954060000,
			"n": 250
		  },
		  {
			"v": 768320,
			"vw": 22.6753,
			"o": 22.6711,
			"c": 22.6696,
			"h": 22.6839,
			"l": 22.6675,
			"t": 1344954120000,
			"n": 201
		  },
		  {
			"v": 529872,
			"vw": 22.6721,
			"o": 22.6718,
			"c": 22.6736,
			"h": 22.6768,
			"l": 22.6682,
			"t": 1344954180000,
			"n": 144
		  },
		  {
			"v": 588728,
			"vw": 22.6718,
			"o": 22.6736,
			"c": 22.6693,
			"h": 22.6775,
			"l": 22.6679,
			"t": 1344954240000,
			"n": 140
		  },
		  {
			"v": 1179108,
			"vw": 22.6638,
			"o": 22.6698,
			"c": 22.6629,
			"h": 22.6721,
			"l": 22.6561,
			"t": 1344954300000,
			"n": 286
		  },
		  {
			"v": 751996,
			"vw": 22.6618,
			"o": 22.6604,
			"c": 22.6621,
			"h": 22.6725,
			"l": 22.6536,
			"t": 1344954360000,
			"n": 198
		  },
		  {
			"v": 779632,
			"vw": 22.6624,
			"o": 22.6643,
			"c": 22.6618,
			"h": 22.6661,
			"l": 22.6575,
			"t": 1344954420000,
			"n": 185
		  },
		  {
			"v": 725312,
			"vw": 22.6672,
			"o": 22.6614,
			"c": 22.6711,
			"h": 22.6729,
			"l": 22.6611,
			"t": 1344954480000,
			"n": 146
		  },
		  {
			"v": 425572,
			"vw": 22.6719,
			"o": 22.671,
			"c": 22.6646,
			"h": 22.6771,
			"l": 22.6646,
			"t": 1344954540000,
			"n": 112
		  },
		  {
			"v": 482188,
			"vw": 22.6666,
			"o": 22.6664,
			"c": 22.6654,
			"h": 22.6714,
			"l": 22.6632,
			"t": 1344954600000,
			"n": 110
		  },
		  {
			"v": 636300,
			"vw": 22.6658,
			"o": 22.6646,
			"c": 22.6743,
			"h": 22.6743,
			"l": 22.6571,
			"t": 1344954660000,
			"n": 151
		  },
		  {
			"v": 350560,
			"vw": 22.67,
			"o": 22.673,
			"c": 22.6693,
			"h": 22.6743,
			"l": 22.6643,
			"t": 1344954720000,
			"n": 97
		  },
		  {
			"v": 633612,
			"vw": 22.6653,
			"o": 22.6686,
			"c": 22.6561,
			"h": 22.6746,
			"l": 22.6557,
			"t": 1344954780000,
			"n": 176
		  },
		  {
			"v": 582484,
			"vw": 22.664,
			"o": 22.6561,
			"c": 22.6655,
			"h": 22.6696,
			"l": 22.6561,
			"t": 1344954840000,
			"n": 147
		  },
		  {
			"v": 1306816,
			"vw": 22.6757,
			"o": 22.6647,
			"c": 22.6857,
			"h": 22.6857,
			"l": 22.6647,
			"t": 1344954900000,
			"n": 269
		  },
		  {
			"v": 674324,
			"vw": 22.6838,
			"o": 22.6857,
			"c": 22.6818,
			"h": 22.6893,
			"l": 22.6757,
			"t": 1344954960000,
			"n": 172
		  },
		  {
			"v": 848288,
			"vw": 22.6821,
			"o": 22.6825,
			"c": 22.6809,
			"h": 22.6871,
			"l": 22.6754,
			"t": 1344955020000,
			"n": 190
		  },
		  {
			"v": 699832,
			"vw": 22.671,
			"o": 22.6807,
			"c": 22.668,
			"h": 22.6818,
			"l": 22.6676,
			"t": 1344955080000,
			"n": 138
		  },
		  {
			"v": 391160,
			"vw": 22.6743,
			"o": 22.6696,
			"c": 22.6732,
			"h": 22.6768,
			"l": 22.6696,
			"t": 1344955140000,
			"n": 103
		  },
		  {
			"v": 252280,
			"vw": 22.6736,
			"o": 22.6729,
			"c": 22.675,
			"h": 22.6768,
			"l": 22.6707,
			"t": 1344955200000,
			"n": 67
		  },
		  {
			"v": 584780,
			"vw": 22.6783,
			"o": 22.6746,
			"c": 22.6764,
			"h": 22.6821,
			"l": 22.6721,
			"t": 1344955260000,
			"n": 147
		  },
		  {
			"v": 404908,
			"vw": 22.6738,
			"o": 22.675,
			"c": 22.6775,
			"h": 22.6786,
			"l": 22.6679,
			"t": 1344955320000,
			"n": 104
		  },
		  {
			"v": 394380,
			"vw": 22.6751,
			"o": 22.6771,
			"c": 22.6736,
			"h": 22.6779,
			"l": 22.6711,
			"t": 1344955380000,
			"n": 107
		  },
		  {
			"v": 359996,
			"vw": 22.6712,
			"o": 22.6767,
			"c": 22.6779,
			"h": 22.6779,
			"l": 22.6664,
			"t": 1344955440000,
			"n": 108
		  },
		  {
			"v": 919380,
			"vw": 22.6844,
			"o": 22.6775,
			"c": 22.6871,
			"h": 22.6893,
			"l": 22.675,
			"t": 1344955500000,
			"n": 200
		  },
		  {
			"v": 758548,
			"vw": 22.6876,
			"o": 22.6846,
			"c": 22.6907,
			"h": 22.6911,
			"l": 22.6832,
			"t": 1344955560000,
			"n": 165
		  },
		  {
			"v": 1617756,
			"vw": 22.6931,
			"o": 22.6875,
			"c": 22.6946,
			"h": 22.7,
			"l": 22.6821,
			"t": 1344955620000,
			"n": 327
		  },
		  {
			"v": 1507912,
			"vw": 22.7006,
			"o": 22.6946,
			"c": 22.7024,
			"h": 22.7054,
			"l": 22.6946,
			"t": 1344955680000,
			"n": 316
		  },
		  {
			"v": 612444,
			"vw": 22.7022,
			"o": 22.7043,
			"c": 22.6993,
			"h": 22.7054,
			"l": 22.6975,
			"t": 1344955740000,
			"n": 137
		  },
		  {
			"v": 1232420,
			"vw": 22.7048,
			"o": 22.7029,
			"c": 22.7086,
			"h": 22.7086,
			"l": 22.698,
			"t": 1344955800000,
			"n": 299
		  },
		  {
			"v": 1981000,
			"vw": 22.7152,
			"o": 22.7089,
			"c": 22.7185,
			"h": 22.7232,
			"l": 22.7089,
			"t": 1344955860000,
			"n": 455
		  },
		  {
			"v": 569716,
			"vw": 22.7191,
			"o": 22.715,
			"c": 22.7179,
			"h": 22.7207,
			"l": 22.715,
			"t": 1344955920000,
			"n": 81
		  },
		  {
			"v": 1434720,
			"vw": 22.7171,
			"o": 22.7206,
			"c": 22.7164,
			"h": 22.7225,
			"l": 22.7139,
			"t": 1344955980000,
			"n": 296
		  },
		  {
			"v": 501340,
			"vw": 22.7128,
			"o": 22.7175,
			"c": 22.7096,
			"h": 22.7175,
			"l": 22.7086,
			"t": 1344956040000,
			"n": 103
		  },
		  {
			"v": 709016,
			"vw": 22.7124,
			"o": 22.7118,
			"c": 22.7166,
			"h": 22.7168,
			"l": 22.7086,
			"t": 1344956100000,
			"n": 129
		  },
		  {
			"v": 874580,
			"vw": 22.7141,
			"o": 22.7143,
			"c": 22.7082,
			"h": 22.7221,
			"l": 22.7071,
			"t": 1344956160000,
			"n": 183
		  },
		  {
			"v": 360388,
			"vw": 22.7086,
			"o": 22.7112,
			"c": 22.7076,
			"h": 22.7112,
			"l": 22.7054,
			"t": 1344956220000,
			"n": 96
		  },
		  {
			"v": 659792,
			"vw": 22.7062,
			"o": 22.7079,
			"c": 22.7079,
			"h": 22.7118,
			"l": 22.7011,
			"t": 1344956280000,
			"n": 151
		  },
		  {
			"v": 264992,
			"vw": 22.7118,
			"o": 22.7066,
			"c": 22.7179,
			"h": 22.7179,
			"l": 22.7064,
			"t": 1344956340000,
			"n": 64
		  },
		  {
			"v": 361760,
			"vw": 22.7119,
			"o": 22.715,
			"c": 22.71,
			"h": 22.7159,
			"l": 22.7093,
			"t": 1344956400000,
			"n": 87
		  },
		  {
			"v": 361984,
			"vw": 22.7137,
			"o": 22.7093,
			"c": 22.7125,
			"h": 22.7179,
			"l": 22.7086,
			"t": 1344956460000,
			"n": 95
		  },
		  {
			"v": 298984,
			"vw": 22.7102,
			"o": 22.7136,
			"c": 22.7105,
			"h": 22.7139,
			"l": 22.707,
			"t": 1344956520000,
			"n": 64
		  },
		  {
			"v": 668136,
			"vw": 22.7187,
			"o": 22.7107,
			"c": 22.7236,
			"h": 22.7243,
			"l": 22.7107,
			"t": 1344956580000,
			"n": 182
		  },
		  {
			"v": 777756,
			"vw": 22.726,
			"o": 22.7243,
			"c": 22.7295,
			"h": 22.7304,
			"l": 22.7221,
			"t": 1344956640000,
			"n": 216
		  },
		  {
			"v": 544040,
			"vw": 22.7276,
			"o": 22.7293,
			"c": 22.7261,
			"h": 22.73,
			"l": 22.7232,
			"t": 1344956700000,
			"n": 125
		  },
		  {
			"v": 1113028,
			"vw": 22.7301,
			"o": 22.7296,
			"c": 22.7304,
			"h": 22.7321,
			"l": 22.7254,
			"t": 1344956760000,
			"n": 244
		  },
		  {
			"v": 754460,
			"vw": 22.7274,
			"o": 22.7311,
			"c": 22.7239,
			"h": 22.7361,
			"l": 22.7193,
			"t": 1344956820000,
			"n": 169
		  },
		  {
			"v": 606480,
			"vw": 22.7273,
			"o": 22.7268,
			"c": 22.7261,
			"h": 22.73,
			"l": 22.7243,
			"t": 1344956880000,
			"n": 157
		  },
		  {
			"v": 340928,
			"vw": 22.7235,
			"o": 22.7289,
			"c": 22.7214,
			"h": 22.73,
			"l": 22.7196,
			"t": 1344956940000,
			"n": 55
		  },
		  {
			"v": 670096,
			"vw": 22.7281,
			"o": 22.7207,
			"c": 22.7332,
			"h": 22.7332,
			"l": 22.7207,
			"t": 1344957000000,
			"n": 155
		  },
		  {
			"v": 429268,
			"vw": 22.7324,
			"o": 22.7336,
			"c": 22.7323,
			"h": 22.7364,
			"l": 22.7286,
			"t": 1344957060000,
			"n": 103
		  },
		  {
			"v": 534856,
			"vw": 22.7326,
			"o": 22.7318,
			"c": 22.7312,
			"h": 22.7357,
			"l": 22.7289,
			"t": 1344957120000,
			"n": 124
		  },
		  {
			"v": 393820,
			"vw": 22.7324,
			"o": 22.7305,
			"c": 22.7356,
			"h": 22.7361,
			"l": 22.7286,
			"t": 1344957180000,
			"n": 97
		  },
		  {
			"v": 1066072,
			"vw": 22.7399,
			"o": 22.7343,
			"c": 22.7425,
			"h": 22.7425,
			"l": 22.7321,
			"t": 1344957240000,
			"n": 201
		  },
		  {
			"v": 568400,
			"vw": 22.7403,
			"o": 22.7421,
			"c": 22.7418,
			"h": 22.7429,
			"l": 22.7368,
			"t": 1344957300000,
			"n": 130
		  },
		  {
			"v": 1081416,
			"vw": 22.745,
			"o": 22.7422,
			"c": 22.7464,
			"h": 22.7482,
			"l": 22.7396,
			"t": 1344957360000,
			"n": 232
		  },
		  {
			"v": 939456,
			"vw": 22.7458,
			"o": 22.7471,
			"c": 22.7457,
			"h": 22.7486,
			"l": 22.7404,
			"t": 1344957420000,
			"n": 209
		  },
		  {
			"v": 1677172,
			"vw": 22.7521,
			"o": 22.7458,
			"c": 22.7621,
			"h": 22.7625,
			"l": 22.7432,
			"t": 1344957480000,
			"n": 330
		  },
		  {
			"v": 1370796,
			"vw": 22.7605,
			"o": 22.7625,
			"c": 22.7549,
			"h": 22.7657,
			"l": 22.7518,
			"t": 1344957540000,
			"n": 275
		  },
		  {
			"v": 1474620,
			"vw": 22.7488,
			"o": 22.7532,
			"c": 22.7471,
			"h": 22.7557,
			"l": 22.7439,
			"t": 1344957600000,
			"n": 316
		  },
		  {
			"v": 956396,
			"vw": 22.7434,
			"o": 22.7468,
			"c": 22.7403,
			"h": 22.75,
			"l": 22.7379,
			"t": 1344957660000,
			"n": 193
		  },
		  {
			"v": 740824,
			"vw": 22.7423,
			"o": 22.7414,
			"c": 22.7461,
			"h": 22.7482,
			"l": 22.7386,
			"t": 1344957720000,
			"n": 156
		  },
		  {
			"v": 634368,
			"vw": 22.7469,
			"o": 22.7468,
			"c": 22.7434,
			"h": 22.7518,
			"l": 22.7411,
			"t": 1344957780000,
			"n": 113
		  },
		  {
			"v": 469028,
			"vw": 22.7457,
			"o": 22.7421,
			"c": 22.7477,
			"h": 22.7493,
			"l": 22.74,
			"t": 1344957840000,
			"n": 95
		  },
		  {
			"v": 1134980,
			"vw": 22.7586,
			"o": 22.7493,
			"c": 22.7621,
			"h": 22.7654,
			"l": 22.7472,
			"t": 1344957900000,
			"n": 285
		  },
		  {
			"v": 1608992,
			"vw": 22.7675,
			"o": 22.7607,
			"c": 22.7761,
			"h": 22.7764,
			"l": 22.7579,
			"t": 1344957960000,
			"n": 367
		  },
		  {
			"v": 880740,
			"vw": 22.7752,
			"o": 22.7733,
			"c": 22.7749,
			"h": 22.7789,
			"l": 22.7688,
			"t": 1344958020000,
			"n": 232
		  },
		  {
			"v": 1316056,
			"vw": 22.7812,
			"o": 22.7736,
			"c": 22.7832,
			"h": 22.7864,
			"l": 22.7729,
			"t": 1344958080000,
			"n": 277
		  },
		  {
			"v": 911204,
			"vw": 22.7836,
			"o": 22.7825,
			"c": 22.7854,
			"h": 22.7875,
			"l": 22.7796,
			"t": 1344958140000,
			"n": 217
		  },
		  {
			"v": 1479940,
			"vw": 22.7909,
			"o": 22.7875,
			"c": 22.7917,
			"h": 22.7964,
			"l": 22.7825,
			"t": 1344958200000,
			"n": 358
		  },
		  {
			"v": 706692,
			"vw": 22.7902,
			"o": 22.7886,
			"c": 22.7921,
			"h": 22.7961,
			"l": 22.7857,
			"t": 1344958260000,
			"n": 146
		  },
		  {
			"v": 1172976,
			"vw": 22.7918,
			"o": 22.7911,
			"c": 22.7893,
			"h": 22.7975,
			"l": 22.7861,
			"t": 1344958320000,
			"n": 223
		  },
		  {
			"v": 579292,
			"vw": 22.7975,
			"o": 22.7891,
			"c": 22.7996,
			"h": 22.8032,
			"l": 22.7891,
			"t": 1344958380000,
			"n": 147
		  },
		  {
			"v": 775964,
			"vw": 22.8023,
			"o": 22.8021,
			"c": 22.7986,
			"h": 22.8057,
			"l": 22.7986,
			"t": 1344958440000,
			"n": 170
		  },
		  {
			"v": 692160,
			"vw": 22.7981,
			"o": 22.8014,
			"c": 22.8014,
			"h": 22.8025,
			"l": 22.7925,
			"t": 1344958500000,
			"n": 154
		  },
		  {
			"v": 926716,
			"vw": 22.8032,
			"o": 22.8007,
			"c": 22.8039,
			"h": 22.8061,
			"l": 22.7972,
			"t": 1344958560000,
			"n": 217
		  },
		  {
			"v": 410788,
			"vw": 22.8043,
			"o": 22.8048,
			"c": 22.8036,
			"h": 22.8061,
			"l": 22.8014,
			"t": 1344958620000,
			"n": 94
		  },
		  {
			"v": 1524908,
			"vw": 22.8025,
			"o": 22.8036,
			"c": 22.7986,
			"h": 22.8075,
			"l": 22.7975,
			"t": 1344958680000,
			"n": 307
		  },
		  {
			"v": 1013712,
			"vw": 22.7964,
			"o": 22.8009,
			"c": 22.795,
			"h": 22.8011,
			"l": 22.7925,
			"t": 1344958740000,
			"n": 222
		  },
		  {
			"v": 1469776,
			"vw": 22.7888,
			"o": 22.795,
			"c": 22.7875,
			"h": 22.7961,
			"l": 22.7857,
			"t": 1344958800000,
			"n": 287
		  },
		  {
			"v": 865536,
			"vw": 22.7897,
			"o": 22.79,
			"c": 22.7921,
			"h": 22.7964,
			"l": 22.7857,
			"t": 1344958860000,
			"n": 197
		  },
		  {
			"v": 1201648,
			"vw": 22.7892,
			"o": 22.7929,
			"c": 22.7914,
			"h": 22.7961,
			"l": 22.7857,
			"t": 1344958920000,
			"n": 246
		  },
		  {
			"v": 939484,
			"vw": 22.785,
			"o": 22.7924,
			"c": 22.7841,
			"h": 22.7924,
			"l": 22.7786,
			"t": 1344958980000,
			"n": 201
		  },
		  {
			"v": 525000,
			"vw": 22.7838,
			"o": 22.7871,
			"c": 22.7814,
			"h": 22.7911,
			"l": 22.7793,
			"t": 1344959040000,
			"n": 133
		  },
		  {
			"v": 635572,
			"vw": 22.7827,
			"o": 22.7842,
			"c": 22.7846,
			"h": 22.7857,
			"l": 22.7786,
			"t": 1344959100000,
			"n": 134
		  },
		  {
			"v": 529648,
			"vw": 22.7842,
			"o": 22.7816,
			"c": 22.7804,
			"h": 22.7889,
			"l": 22.7786,
			"t": 1344959160000,
			"n": 128
		  },
		  {
			"v": 928340,
			"vw": 22.7746,
			"o": 22.7811,
			"c": 22.7638,
			"h": 22.7821,
			"l": 22.7629,
			"t": 1344959220000,
			"n": 182
		  },
		  {
			"v": 801892,
			"vw": 22.7668,
			"o": 22.7668,
			"c": 22.7686,
			"h": 22.7736,
			"l": 22.7639,
			"t": 1344959280000,
			"n": 198
		  },
		  {
			"v": 377608,
			"vw": 22.772,
			"o": 22.7696,
			"c": 22.7711,
			"h": 22.7757,
			"l": 22.7676,
			"t": 1344959340000,
			"n": 85
		  },
		  {
			"v": 478660,
			"vw": 22.768,
			"o": 22.7707,
			"c": 22.7626,
			"h": 22.7736,
			"l": 22.7618,
			"t": 1344959400000,
			"n": 128
		  },
		  {
			"v": 1019004,
			"vw": 22.7606,
			"o": 22.7627,
			"c": 22.7629,
			"h": 22.7679,
			"l": 22.7564,
			"t": 1344959460000,
			"n": 183
		  },
		  {
			"v": 822836,
			"vw": 22.7545,
			"o": 22.7611,
			"c": 22.75,
			"h": 22.7629,
			"l": 22.75,
			"t": 1344959520000,
			"n": 173
		  },
		  {
			"v": 897232,
			"vw": 22.7528,
			"o": 22.7539,
			"c": 22.7511,
			"h": 22.7614,
			"l": 22.7461,
			"t": 1344959580000,
			"n": 228
		  },
		  {
			"v": 740628,
			"vw": 22.7469,
			"o": 22.7511,
			"c": 22.7489,
			"h": 22.7527,
			"l": 22.7418,
			"t": 1344959640000,
			"n": 179
		  },
		  {
			"v": 838012,
			"vw": 22.7529,
			"o": 22.7482,
			"c": 22.7529,
			"h": 22.76,
			"l": 22.7471,
			"t": 1344959700000,
			"n": 180
		  },
		  {
			"v": 504560,
			"vw": 22.7477,
			"o": 22.7535,
			"c": 22.7404,
			"h": 22.7535,
			"l": 22.7396,
			"t": 1344959760000,
			"n": 133
		  },
		  {
			"v": 1282232,
			"vw": 22.7329,
			"o": 22.74,
			"c": 22.73,
			"h": 22.7407,
			"l": 22.7243,
			"t": 1344959820000,
			"n": 282
		  },
		  {
			"v": 1274112,
			"vw": 22.7256,
			"o": 22.73,
			"c": 22.7191,
			"h": 22.7364,
			"l": 22.7191,
			"t": 1344959880000,
			"n": 294
		  },
		  {
			"v": 1390480,
			"vw": 22.7198,
			"o": 22.7179,
			"c": 22.7261,
			"h": 22.7296,
			"l": 22.7107,
			"t": 1344959940000,
			"n": 268
		  },
		  {
			"v": 674352,
			"vw": 22.7325,
			"o": 22.7282,
			"c": 22.7339,
			"h": 22.7379,
			"l": 22.725,
			"t": 1344960000000,
			"n": 155
		  },
		  {
			"v": 467516,
			"vw": 22.7305,
			"o": 22.7339,
			"c": 22.73,
			"h": 22.7354,
			"l": 22.725,
			"t": 1344960060000,
			"n": 111
		  },
		  {
			"v": 519176,
			"vw": 22.7298,
			"o": 22.7318,
			"c": 22.7314,
			"h": 22.7346,
			"l": 22.7236,
			"t": 1344960120000,
			"n": 102
		  },
		  {
			"v": 441336,
			"vw": 22.7366,
			"o": 22.7314,
			"c": 22.7357,
			"h": 22.7393,
			"l": 22.7305,
			"t": 1344960180000,
			"n": 94
		  },
		  {
			"v": 750792,
			"vw": 22.7422,
			"o": 22.7357,
			"c": 22.7475,
			"h": 22.7475,
			"l": 22.7356,
			"t": 1344960240000,
			"n": 159
		  },
		  {
			"v": 680540,
			"vw": 22.7438,
			"o": 22.7454,
			"c": 22.739,
			"h": 22.7481,
			"l": 22.7375,
			"t": 1344960300000,
			"n": 148
		  },
		  {
			"v": 287280,
			"vw": 22.7417,
			"o": 22.7404,
			"c": 22.7443,
			"h": 22.7443,
			"l": 22.7396,
			"t": 1344960360000,
			"n": 66
		  },
		  {
			"v": 931056,
			"vw": 22.7487,
			"o": 22.7439,
			"c": 22.7536,
			"h": 22.7536,
			"l": 22.7418,
			"t": 1344960420000,
			"n": 175
		  },
		  {
			"v": 442568,
			"vw": 22.7533,
			"o": 22.7515,
			"c": 22.7526,
			"h": 22.7564,
			"l": 22.75,
			"t": 1344960480000,
			"n": 95
		  },
		  {
			"v": 526204,
			"vw": 22.7562,
			"o": 22.7529,
			"c": 22.7589,
			"h": 22.7604,
			"l": 22.7511,
			"t": 1344960540000,
			"n": 142
		  },
		  {
			"v": 635292,
			"vw": 22.7622,
			"o": 22.76,
			"c": 22.765,
			"h": 22.7661,
			"l": 22.7571,
			"t": 1344960600000,
			"n": 142
		  },
		  {
			"v": 745164,
			"vw": 22.7615,
			"o": 22.7656,
			"c": 22.7534,
			"h": 22.7679,
			"l": 22.7514,
			"t": 1344960660000,
			"n": 197
		  },
		  {
			"v": 340872,
			"vw": 22.7541,
			"o": 22.755,
			"c": 22.75,
			"h": 22.7593,
			"l": 22.75,
			"t": 1344960720000,
			"n": 88
		  },
		  {
			"v": 145656,
			"vw": 22.7526,
			"o": 22.75,
			"c": 22.7554,
			"h": 22.7571,
			"l": 22.75,
			"t": 1344960780000,
			"n": 32
		  },
		  {
			"v": 400988,
			"vw": 22.7594,
			"o": 22.7534,
			"c": 22.7607,
			"h": 22.7635,
			"l": 22.7534,
			"t": 1344960840000,
			"n": 88
		  },
		  {
			"v": 295820,
			"vw": 22.7561,
			"o": 22.7636,
			"c": 22.7543,
			"h": 22.7639,
			"l": 22.7507,
			"t": 1344960900000,
			"n": 65
		  },
		  {
			"v": 275800,
			"vw": 22.7532,
			"o": 22.7543,
			"c": 22.7521,
			"h": 22.7554,
			"l": 22.7518,
			"t": 1344960960000,
			"n": 58
		  },
		  {
			"v": 548100,
			"vw": 22.7458,
			"o": 22.7529,
			"c": 22.7429,
			"h": 22.7554,
			"l": 22.7379,
			"t": 1344961020000,
			"n": 128
		  },
		  {
			"v": 531132,
			"vw": 22.7403,
			"o": 22.7418,
			"c": 22.737,
			"h": 22.7461,
			"l": 22.7346,
			"t": 1344961080000,
			"n": 130
		  },
		  {
			"v": 1023092,
			"vw": 22.723,
			"o": 22.7346,
			"c": 22.7107,
			"h": 22.7367,
			"l": 22.7107,
			"t": 1344961140000,
			"n": 238
		  },
		  {
			"v": 573300,
			"vw": 22.7165,
			"o": 22.7132,
			"c": 22.7197,
			"h": 22.7211,
			"l": 22.7107,
			"t": 1344961200000,
			"n": 123
		  },
		  {
			"v": 690452,
			"vw": 22.7158,
			"o": 22.7204,
			"c": 22.7164,
			"h": 22.7207,
			"l": 22.7111,
			"t": 1344961260000,
			"n": 150
		  },
		  {
			"v": 960260,
			"vw": 22.7205,
			"o": 22.7164,
			"c": 22.7193,
			"h": 22.725,
			"l": 22.715,
			"t": 1344961320000,
			"n": 236
		  },
		  {
			"v": 991536,
			"vw": 22.7148,
			"o": 22.7196,
			"c": 22.7221,
			"h": 22.7229,
			"l": 22.7107,
			"t": 1344961380000,
			"n": 240
		  },
		  {
			"v": 581728,
			"vw": 22.7225,
			"o": 22.7193,
			"c": 22.7371,
			"h": 22.7371,
			"l": 22.7139,
			"t": 1344961440000,
			"n": 149
		  },
		  {
			"v": 717808,
			"vw": 22.7297,
			"o": 22.7346,
			"c": 22.7271,
			"h": 22.7371,
			"l": 22.7261,
			"t": 1344961500000,
			"n": 128
		  },
		  {
			"v": 287280,
			"vw": 22.7227,
			"o": 22.7271,
			"c": 22.7194,
			"h": 22.7282,
			"l": 22.7194,
			"t": 1344961560000,
			"n": 52
		  },
		  {
			"v": 176036,
			"vw": 22.7222,
			"o": 22.7207,
			"c": 22.7271,
			"h": 22.7271,
			"l": 22.7186,
			"t": 1344961620000,
			"n": 40
		  },
		  {
			"v": 304052,
			"vw": 22.7277,
			"o": 22.725,
			"c": 22.7229,
			"h": 22.7329,
			"l": 22.7225,
			"t": 1344961680000,
			"n": 63
		  },
		  {
			"v": 1322748,
			"vw": 22.7158,
			"o": 22.7239,
			"c": 22.728,
			"h": 22.7321,
			"l": 22.705,
			"t": 1344961740000,
			"n": 279
		  },
		  {
			"v": 779996,
			"vw": 22.7356,
			"o": 22.7279,
			"c": 22.7343,
			"h": 22.7436,
			"l": 22.7264,
			"t": 1344961800000,
			"n": 157
		  },
		  {
			"v": 233240,
			"vw": 22.7354,
			"o": 22.7343,
			"c": 22.7393,
			"h": 22.7393,
			"l": 22.7322,
			"t": 1344961860000,
			"n": 55
		  },
		  {
			"v": 400988,
			"vw": 22.7301,
			"o": 22.7396,
			"c": 22.7201,
			"h": 22.7399,
			"l": 22.7189,
			"t": 1344961920000,
			"n": 105
		  },
		  {
			"v": 300048,
			"vw": 22.7233,
			"o": 22.7198,
			"c": 22.7196,
			"h": 22.7268,
			"l": 22.7179,
			"t": 1344961980000,
			"n": 53
		  },
		  {
			"v": 448644,
			"vw": 22.7165,
			"o": 22.7193,
			"c": 22.7125,
			"h": 22.7232,
			"l": 22.7108,
			"t": 1344962040000,
			"n": 86
		  },
		  {
			"v": 781564,
			"vw": 22.709,
			"o": 22.7118,
			"c": 22.7136,
			"h": 22.7179,
			"l": 22.7043,
			"t": 1344962100000,
			"n": 178
		  },
		  {
			"v": 611492,
			"vw": 22.7132,
			"o": 22.7139,
			"c": 22.7104,
			"h": 22.7161,
			"l": 22.7093,
			"t": 1344962160000,
			"n": 87
		  },
		  {
			"v": 400484,
			"vw": 22.7106,
			"o": 22.7139,
			"c": 22.7139,
			"h": 22.7143,
			"l": 22.705,
			"t": 1344962220000,
			"n": 104
		  },
		  {
			"v": 2172716,
			"vw": 22.6973,
			"o": 22.7114,
			"c": 22.69,
			"h": 22.7125,
			"l": 22.6839,
			"t": 1344962280000,
			"n": 428
		  },
		  {
			"v": 1895460,
			"vw": 22.6861,
			"o": 22.6929,
			"c": 22.6786,
			"h": 22.6979,
			"l": 22.6786,
			"t": 1344962340000,
			"n": 315
		  },
		  {
			"v": 2115064,
			"vw": 22.6727,
			"o": 22.6786,
			"c": 22.667,
			"h": 22.6849,
			"l": 22.6543,
			"t": 1344962400000,
			"n": 471
		  },
		  {
			"v": 932540,
			"vw": 22.6668,
			"o": 22.6689,
			"c": 22.6689,
			"h": 22.6743,
			"l": 22.6604,
			"t": 1344962460000,
			"n": 186
		  },
		  {
			"v": 1478456,
			"vw": 22.6631,
			"o": 22.6668,
			"c": 22.6525,
			"h": 22.67,
			"l": 22.6525,
			"t": 1344962520000,
			"n": 297
		  },
		  {
			"v": 2150344,
			"vw": 22.6475,
			"o": 22.6546,
			"c": 22.65,
			"h": 22.6593,
			"l": 22.6396,
			"t": 1344962580000,
			"n": 486
		  },
		  {
			"v": 863436,
			"vw": 22.6492,
			"o": 22.6518,
			"c": 22.6571,
			"h": 22.6593,
			"l": 22.6425,
			"t": 1344962640000,
			"n": 216
		  },
		  {
			"v": 870436,
			"vw": 22.6582,
			"o": 22.6571,
			"c": 22.6554,
			"h": 22.6611,
			"l": 22.6554,
			"t": 1344962700000,
			"n": 169
		  },
		  {
			"v": 1193920,
			"vw": 22.6467,
			"o": 22.6571,
			"c": 22.6493,
			"h": 22.6571,
			"l": 22.6425,
			"t": 1344962760000,
			"n": 299
		  },
		  {
			"v": 414064,
			"vw": 22.6487,
			"o": 22.6539,
			"c": 22.6557,
			"h": 22.6568,
			"l": 22.6432,
			"t": 1344962820000,
			"n": 104
		  },
		  {
			"v": 371112,
			"vw": 22.6485,
			"o": 22.6568,
			"c": 22.6461,
			"h": 22.6568,
			"l": 22.6446,
			"t": 1344962880000,
			"n": 105
		  },
		  {
			"v": 363776,
			"vw": 22.6508,
			"o": 22.6475,
			"c": 22.6518,
			"h": 22.6539,
			"l": 22.6457,
			"t": 1344962940000,
			"n": 95
		  },
		  {
			"v": 697060,
			"vw": 22.6596,
			"o": 22.6486,
			"c": 22.6618,
			"h": 22.6643,
			"l": 22.6486,
			"t": 1344963000000,
			"n": 164
		  },
		  {
			"v": 1045240,
			"vw": 22.669,
			"o": 22.6611,
			"c": 22.6753,
			"h": 22.6753,
			"l": 22.6606,
			"t": 1344963060000,
			"n": 213
		  },
		  {
			"v": 1001364,
			"vw": 22.6763,
			"o": 22.6739,
			"c": 22.6806,
			"h": 22.6806,
			"l": 22.6718,
			"t": 1344963120000,
			"n": 226
		  },
		  {
			"v": 900648,
			"vw": 22.6801,
			"o": 22.6807,
			"c": 22.6757,
			"h": 22.6839,
			"l": 22.6746,
			"t": 1344963180000,
			"n": 180
		  },
		  {
			"v": 339724,
			"vw": 22.6764,
			"o": 22.6771,
			"c": 22.6729,
			"h": 22.68,
			"l": 22.6718,
			"t": 1344963240000,
			"n": 89
		  },
		  {
			"v": 1116304,
			"vw": 22.6796,
			"o": 22.6739,
			"c": 22.6814,
			"h": 22.685,
			"l": 22.6714,
			"t": 1344963300000,
			"n": 181
		  },
		  {
			"v": 1098300,
			"vw": 22.69,
			"o": 22.6843,
			"c": 22.6946,
			"h": 22.6964,
			"l": 22.6811,
			"t": 1344963360000,
			"n": 199
		  },
		  {
			"v": 580972,
			"vw": 22.687,
			"o": 22.6918,
			"c": 22.6839,
			"h": 22.6936,
			"l": 22.6811,
			"t": 1344963420000,
			"n": 116
		  },
		  {
			"v": 265132,
			"vw": 22.6854,
			"o": 22.6858,
			"c": 22.6861,
			"h": 22.6907,
			"l": 22.6814,
			"t": 1344963480000,
			"n": 69
		  },
		  {
			"v": 284788,
			"vw": 22.6832,
			"o": 22.684,
			"c": 22.6832,
			"h": 22.6892,
			"l": 22.6786,
			"t": 1344963540000,
			"n": 84
		  },
		  {
			"v": 289856,
			"vw": 22.6898,
			"o": 22.6839,
			"c": 22.6932,
			"h": 22.6964,
			"l": 22.6786,
			"t": 1344963600000,
			"n": 68
		  },
		  {
			"v": 696248,
			"vw": 22.6926,
			"o": 22.6944,
			"c": 22.6864,
			"h": 22.6982,
			"l": 22.685,
			"t": 1344963660000,
			"n": 161
		  },
		  {
			"v": 308532,
			"vw": 22.6881,
			"o": 22.6883,
			"c": 22.6864,
			"h": 22.6907,
			"l": 22.6857,
			"t": 1344963720000,
			"n": 82
		  },
		  {
			"v": 813792,
			"vw": 22.6984,
			"o": 22.6874,
			"c": 22.7011,
			"h": 22.7039,
			"l": 22.6868,
			"t": 1344963780000,
			"n": 191
		  },
		  {
			"v": 539532,
			"vw": 22.7053,
			"o": 22.7039,
			"c": 22.7036,
			"h": 22.7086,
			"l": 22.7014,
			"t": 1344963840000,
			"n": 132
		  },
		  {
			"v": 775012,
			"vw": 22.6936,
			"o": 22.7021,
			"c": 22.6851,
			"h": 22.7021,
			"l": 22.685,
			"t": 1344963900000,
			"n": 179
		  },
		  {
			"v": 264068,
			"vw": 22.6853,
			"o": 22.6857,
			"c": 22.6832,
			"h": 22.6896,
			"l": 22.6825,
			"t": 1344963960000,
			"n": 71
		  },
		  {
			"v": 268520,
			"vw": 22.6818,
			"o": 22.6867,
			"c": 22.6793,
			"h": 22.6868,
			"l": 22.6789,
			"t": 1344964020000,
			"n": 64
		  },
		  {
			"v": 772296,
			"vw": 22.6716,
			"o": 22.6789,
			"c": 22.6707,
			"h": 22.6796,
			"l": 22.6636,
			"t": 1344964080000,
			"n": 181
		  },
		  {
			"v": 454580,
			"vw": 22.6694,
			"o": 22.6689,
			"c": 22.6693,
			"h": 22.6743,
			"l": 22.6657,
			"t": 1344964140000,
			"n": 95
		  },
		  {
			"v": 450800,
			"vw": 22.6632,
			"o": 22.6693,
			"c": 22.6624,
			"h": 22.6711,
			"l": 22.6564,
			"t": 1344964200000,
			"n": 127
		  },
		  {
			"v": 613228,
			"vw": 22.6536,
			"o": 22.6618,
			"c": 22.6543,
			"h": 22.6618,
			"l": 22.6475,
			"t": 1344964260000,
			"n": 134
		  },
		  {
			"v": 442064,
			"vw": 22.6588,
			"o": 22.6529,
			"c": 22.6625,
			"h": 22.6654,
			"l": 22.6514,
			"t": 1344964320000,
			"n": 108
		  },
		  {
			"v": 399224,
			"vw": 22.6563,
			"o": 22.6627,
			"c": 22.6518,
			"h": 22.6627,
			"l": 22.6511,
			"t": 1344964380000,
			"n": 86
		  },
		  {
			"v": 374528,
			"vw": 22.6572,
			"o": 22.6536,
			"c": 22.6529,
			"h": 22.6646,
			"l": 22.6519,
			"t": 1344964440000,
			"n": 93
		  },
		  {
			"v": 381556,
			"vw": 22.6509,
			"o": 22.6561,
			"c": 22.6532,
			"h": 22.6561,
			"l": 22.6471,
			"t": 1344964500000,
			"n": 107
		  },
		  {
			"v": 804132,
			"vw": 22.6455,
			"o": 22.6536,
			"c": 22.6453,
			"h": 22.6536,
			"l": 22.6429,
			"t": 1344964560000,
			"n": 177
		  },
		  {
			"v": 1408540,
			"vw": 22.6394,
			"o": 22.6454,
			"c": 22.6393,
			"h": 22.6482,
			"l": 22.6332,
			"t": 1344964620000,
			"n": 302
		  },
		  {
			"v": 1885660,
			"vw": 22.6282,
			"o": 22.64,
			"c": 22.6229,
			"h": 22.64,
			"l": 22.6186,
			"t": 1344964680000,
			"n": 369
		  },
		  {
			"v": 1006656,
			"vw": 22.6241,
			"o": 22.6207,
			"c": 22.6196,
			"h": 22.6318,
			"l": 22.6175,
			"t": 1344964740000,
			"n": 211
		  },
		  {
			"v": 724360,
			"vw": 22.6183,
			"o": 22.6202,
			"c": 22.6139,
			"h": 22.6239,
			"l": 22.6125,
			"t": 1344964800000,
			"n": 182
		  },
		  {
			"v": 1936648,
			"vw": 22.6075,
			"o": 22.6125,
			"c": 22.6179,
			"h": 22.6204,
			"l": 22.5971,
			"t": 1344964860000,
			"n": 421
		  },
		  {
			"v": 998256,
			"vw": 22.6251,
			"o": 22.6193,
			"c": 22.6339,
			"h": 22.6341,
			"l": 22.6146,
			"t": 1344964920000,
			"n": 192
		  },
		  {
			"v": 795928,
			"vw": 22.6303,
			"o": 22.6343,
			"c": 22.6317,
			"h": 22.6371,
			"l": 22.622,
			"t": 1344964980000,
			"n": 173
		  },
		  {
			"v": 626724,
			"vw": 22.6363,
			"o": 22.6254,
			"c": 22.6393,
			"h": 22.6439,
			"l": 22.6254,
			"t": 1344965040000,
			"n": 137
		  },
		  {
			"v": 556836,
			"vw": 22.6308,
			"o": 22.6404,
			"c": 22.6296,
			"h": 22.6407,
			"l": 22.625,
			"t": 1344965100000,
			"n": 104
		  },
		  {
			"v": 854560,
			"vw": 22.6233,
			"o": 22.6318,
			"c": 22.6154,
			"h": 22.6329,
			"l": 22.6133,
			"t": 1344965160000,
			"n": 173
		  },
		  {
			"v": 260540,
			"vw": 22.6207,
			"o": 22.6157,
			"c": 22.624,
			"h": 22.6264,
			"l": 22.615,
			"t": 1344965220000,
			"n": 74
		  },
		  {
			"v": 519148,
			"vw": 22.6182,
			"o": 22.6218,
			"c": 22.62,
			"h": 22.6229,
			"l": 22.6139,
			"t": 1344965280000,
			"n": 108
		  },
		  {
			"v": 273224,
			"vw": 22.6159,
			"o": 22.6189,
			"c": 22.6132,
			"h": 22.6193,
			"l": 22.6118,
			"t": 1344965340000,
			"n": 56
		  },
		  {
			"v": 895188,
			"vw": 22.628,
			"o": 22.6154,
			"c": 22.6342,
			"h": 22.6389,
			"l": 22.6111,
			"t": 1344965400000,
			"n": 184
		  },
		  {
			"v": 1083852,
			"vw": 22.6482,
			"o": 22.6387,
			"c": 22.6517,
			"h": 22.655,
			"l": 22.6336,
			"t": 1344965460000,
			"n": 219
		  },
		  {
			"v": 828072,
			"vw": 22.6554,
			"o": 22.6489,
			"c": 22.6539,
			"h": 22.6607,
			"l": 22.6464,
			"t": 1344965520000,
			"n": 183
		  },
		  {
			"v": 344792,
			"vw": 22.6543,
			"o": 22.6544,
			"c": 22.652,
			"h": 22.6571,
			"l": 22.6509,
			"t": 1344965580000,
			"n": 68
		  },
		  {
			"v": 1058792,
			"vw": 22.6645,
			"o": 22.6507,
			"c": 22.6652,
			"h": 22.675,
			"l": 22.6507,
			"t": 1344965640000,
			"n": 211
		  },
		  {
			"v": 756840,
			"vw": 22.6679,
			"o": 22.6639,
			"c": 22.67,
			"h": 22.675,
			"l": 22.6593,
			"t": 1344965700000,
			"n": 178
		  },
		  {
			"v": 806848,
			"vw": 22.676,
			"o": 22.6696,
			"c": 22.6786,
			"h": 22.6829,
			"l": 22.665,
			"t": 1344965760000,
			"n": 144
		  },
		  {
			"v": 854224,
			"vw": 22.6814,
			"o": 22.6839,
			"c": 22.6764,
			"h": 22.6864,
			"l": 22.675,
			"t": 1344965820000,
			"n": 156
		  },
		  {
			"v": 827960,
			"vw": 22.672,
			"o": 22.6746,
			"c": 22.6721,
			"h": 22.6786,
			"l": 22.6643,
			"t": 1344965880000,
			"n": 142
		  },
		  {
			"v": 247968,
			"vw": 22.671,
			"o": 22.6736,
			"c": 22.6661,
			"h": 22.675,
			"l": 22.6661,
			"t": 1344965940000,
			"n": 63
		  },
		  {
			"v": 368732,
			"vw": 22.6745,
			"o": 22.6679,
			"c": 22.6754,
			"h": 22.6782,
			"l": 22.6664,
			"t": 1344966000000,
			"n": 61
		  },
		  {
			"v": 262780,
			"vw": 22.6724,
			"o": 22.6754,
			"c": 22.6746,
			"h": 22.6771,
			"l": 22.67,
			"t": 1344966060000,
			"n": 35
		  },
		  {
			"v": 370804,
			"vw": 22.6695,
			"o": 22.6746,
			"c": 22.6725,
			"h": 22.6757,
			"l": 22.6643,
			"t": 1344966120000,
			"n": 87
		  },
		  {
			"v": 324884,
			"vw": 22.6713,
			"o": 22.6725,
			"c": 22.6686,
			"h": 22.6768,
			"l": 22.6686,
			"t": 1344966180000,
			"n": 65
		  },
		  {
			"v": 248388,
			"vw": 22.6668,
			"o": 22.6671,
			"c": 22.6682,
			"h": 22.6725,
			"l": 22.6607,
			"t": 1344966240000,
			"n": 70
		  },
		  {
			"v": 685944,
			"vw": 22.668,
			"o": 22.6657,
			"c": 22.6739,
			"h": 22.6746,
			"l": 22.6604,
			"t": 1344966300000,
			"n": 137
		  },
		  {
			"v": 650300,
			"vw": 22.6812,
			"o": 22.6732,
			"c": 22.6856,
			"h": 22.6864,
			"l": 22.6725,
			"t": 1344966360000,
			"n": 161
		  },
		  {
			"v": 261548,
			"vw": 22.6838,
			"o": 22.6825,
			"c": 22.6854,
			"h": 22.6864,
			"l": 22.68,
			"t": 1344966420000,
			"n": 72
		  },
		  {
			"v": 494284,
			"vw": 22.6919,
			"o": 22.6854,
			"c": 22.6857,
			"h": 22.6964,
			"l": 22.6821,
			"t": 1344966480000,
			"n": 113
		  },
		  {
			"v": 442288,
			"vw": 22.6955,
			"o": 22.6889,
			"c": 22.6964,
			"h": 22.7028,
			"l": 22.6886,
			"t": 1344966540000,
			"n": 104
		  },
		  {
			"v": 430892,
			"vw": 22.6939,
			"o": 22.6982,
			"c": 22.6882,
			"h": 22.6999,
			"l": 22.6875,
			"t": 1344966600000,
			"n": 61
		  },
		  {
			"v": 371504,
			"vw": 22.6833,
			"o": 22.6901,
			"c": 22.6829,
			"h": 22.6901,
			"l": 22.6754,
			"t": 1344966660000,
			"n": 85
		  },
		  {
			"v": 338156,
			"vw": 22.6783,
			"o": 22.68,
			"c": 22.6818,
			"h": 22.6818,
			"l": 22.6743,
			"t": 1344966720000,
			"n": 69
		  },
		  {
			"v": 148680,
			"vw": 22.6835,
			"o": 22.6818,
			"c": 22.6786,
			"h": 22.6864,
			"l": 22.6786,
			"t": 1344966780000,
			"n": 41
		  },
		  {
			"v": 363580,
			"vw": 22.6896,
			"o": 22.6818,
			"c": 22.6933,
			"h": 22.6964,
			"l": 22.6789,
			"t": 1344966840000,
			"n": 66
		  },
		  {
			"v": 250768,
			"vw": 22.6919,
			"o": 22.6957,
			"c": 22.688,
			"h": 22.6964,
			"l": 22.6868,
			"t": 1344966900000,
			"n": 44
		  },
		  {
			"v": 246232,
			"vw": 22.6819,
			"o": 22.6882,
			"c": 22.6786,
			"h": 22.6907,
			"l": 22.6786,
			"t": 1344966960000,
			"n": 62
		  },
		  {
			"v": 432880,
			"vw": 22.676,
			"o": 22.6796,
			"c": 22.6711,
			"h": 22.6818,
			"l": 22.6697,
			"t": 1344967020000,
			"n": 61
		  },
		  {
			"v": 284508,
			"vw": 22.6727,
			"o": 22.671,
			"c": 22.675,
			"h": 22.6786,
			"l": 22.67,
			"t": 1344967080000,
			"n": 63
		  },
		  {
			"v": 437164,
			"vw": 22.6693,
			"o": 22.6746,
			"c": 22.6707,
			"h": 22.6746,
			"l": 22.665,
			"t": 1344967140000,
			"n": 96
		  },
		  {
			"v": 343672,
			"vw": 22.6744,
			"o": 22.67,
			"c": 22.6707,
			"h": 22.6779,
			"l": 22.67,
			"t": 1344967200000,
			"n": 80
		  },
		  {
			"v": 255668,
			"vw": 22.6668,
			"o": 22.6711,
			"c": 22.6679,
			"h": 22.6711,
			"l": 22.6625,
			"t": 1344967260000,
			"n": 60
		  },
		  {
			"v": 375424,
			"vw": 22.6754,
			"o": 22.6682,
			"c": 22.68,
			"h": 22.6804,
			"l": 22.6668,
			"t": 1344967320000,
			"n": 70
		  },
		  {
			"v": 212044,
			"vw": 22.6753,
			"o": 22.6793,
			"c": 22.6721,
			"h": 22.6793,
			"l": 22.6721,
			"t": 1344967380000,
			"n": 50
		  },
		  {
			"v": 671664,
			"vw": 22.6598,
			"o": 22.6721,
			"c": 22.6553,
			"h": 22.675,
			"l": 22.6511,
			"t": 1344967440000,
			"n": 141
		  },
		  {
			"v": 743036,
			"vw": 22.6529,
			"o": 22.6546,
			"c": 22.6568,
			"h": 22.6593,
			"l": 22.6482,
			"t": 1344967500000,
			"n": 168
		  },
		  {
			"v": 400540,
			"vw": 22.6603,
			"o": 22.6593,
			"c": 22.6625,
			"h": 22.6639,
			"l": 22.6562,
			"t": 1344967560000,
			"n": 61
		  },
		  {
			"v": 153440,
			"vw": 22.6609,
			"o": 22.6617,
			"c": 22.6557,
			"h": 22.6646,
			"l": 22.6536,
			"t": 1344967620000,
			"n": 42
		  },
		  {
			"v": 272440,
			"vw": 22.6566,
			"o": 22.654,
			"c": 22.6571,
			"h": 22.6593,
			"l": 22.6539,
			"t": 1344967680000,
			"n": 45
		  },
		  {
			"v": 442792,
			"vw": 22.664,
			"o": 22.6575,
			"c": 22.6686,
			"h": 22.6703,
			"l": 22.6571,
			"t": 1344967740000,
			"n": 88
		  },
		  {
			"v": 142156,
			"vw": 22.667,
			"o": 22.6685,
			"c": 22.6671,
			"h": 22.6711,
			"l": 22.6614,
			"t": 1344967800000,
			"n": 38
		  },
		  {
			"v": 654024,
			"vw": 22.676,
			"o": 22.6675,
			"c": 22.6754,
			"h": 22.6804,
			"l": 22.6664,
			"t": 1344967860000,
			"n": 130
		  },
		  {
			"v": 468272,
			"vw": 22.6803,
			"o": 22.6757,
			"c": 22.6839,
			"h": 22.6875,
			"l": 22.6757,
			"t": 1344967920000,
			"n": 102
		  },
		  {
			"v": 358960,
			"vw": 22.6774,
			"o": 22.6804,
			"c": 22.6664,
			"h": 22.6827,
			"l": 22.6664,
			"t": 1344967980000,
			"n": 74
		  },
		  {
			"v": 733488,
			"vw": 22.6607,
			"o": 22.6661,
			"c": 22.6539,
			"h": 22.6661,
			"l": 22.6539,
			"t": 1344968040000,
			"n": 162
		  },
		  {
			"v": 294168,
			"vw": 22.656,
			"o": 22.6539,
			"c": 22.6548,
			"h": 22.6607,
			"l": 22.6536,
			"t": 1344968100000,
			"n": 68
		  },
		  {
			"v": 99456,
			"vw": 22.6612,
			"o": 22.6582,
			"c": 22.6611,
			"h": 22.6646,
			"l": 22.6571,
			"t": 1344968160000,
			"n": 32
		  },
		  {
			"v": 43400,
			"vw": 22.6656,
			"o": 22.6643,
			"c": 22.6633,
			"h": 22.6675,
			"l": 22.6632,
			"t": 1344968220000,
			"n": 13
		  },
		  {
			"v": 523852,
			"vw": 22.6574,
			"o": 22.6643,
			"c": 22.65,
			"h": 22.6679,
			"l": 22.65,
			"t": 1344968280000,
			"n": 112
		  },
		  {
			"v": 300552,
			"vw": 22.6505,
			"o": 22.6557,
			"c": 22.6451,
			"h": 22.6557,
			"l": 22.6446,
			"t": 1344968340000,
			"n": 77
		  },
		  {
			"v": 728028,
			"vw": 22.6413,
			"o": 22.6454,
			"c": 22.6361,
			"h": 22.6479,
			"l": 22.635,
			"t": 1344968400000,
			"n": 174
		  },
		  {
			"v": 522480,
			"vw": 22.6345,
			"o": 22.635,
			"c": 22.6395,
			"h": 22.6406,
			"l": 22.6286,
			"t": 1344968460000,
			"n": 109
		  },
		  {
			"v": 322084,
			"vw": 22.6438,
			"o": 22.6375,
			"c": 22.6464,
			"h": 22.6482,
			"l": 22.6375,
			"t": 1344968520000,
			"n": 63
		  },
		  {
			"v": 511308,
			"vw": 22.6418,
			"o": 22.6429,
			"c": 22.6432,
			"h": 22.6514,
			"l": 22.6393,
			"t": 1344968580000,
			"n": 139
		  },
		  {
			"v": 159796,
			"vw": 22.647,
			"o": 22.6454,
			"c": 22.6482,
			"h": 22.6504,
			"l": 22.6434,
			"t": 1344968640000,
			"n": 42
		  },
		  {
			"v": 126000,
			"vw": 22.642,
			"o": 22.6482,
			"c": 22.6464,
			"h": 22.6482,
			"l": 22.6393,
			"t": 1344968700000,
			"n": 33
		  },
		  {
			"v": 205632,
			"vw": 22.6522,
			"o": 22.6464,
			"c": 22.6607,
			"h": 22.6607,
			"l": 22.6429,
			"t": 1344968760000,
			"n": 51
		  },
		  {
			"v": 179424,
			"vw": 22.6589,
			"o": 22.6607,
			"c": 22.6557,
			"h": 22.6625,
			"l": 22.6557,
			"t": 1344968820000,
			"n": 35
		  },
		  {
			"v": 173656,
			"vw": 22.6562,
			"o": 22.6543,
			"c": 22.6514,
			"h": 22.6616,
			"l": 22.6514,
			"t": 1344968880000,
			"n": 26
		  },
		  {
			"v": 198240,
			"vw": 22.6493,
			"o": 22.6493,
			"c": 22.6532,
			"h": 22.6543,
			"l": 22.6461,
			"t": 1344968940000,
			"n": 51
		  },
		  {
			"v": 422576,
			"vw": 22.6567,
			"o": 22.6557,
			"c": 22.6679,
			"h": 22.6679,
			"l": 22.65,
			"t": 1344969000000,
			"n": 92
		  },
		  {
			"v": 458584,
			"vw": 22.6692,
			"o": 22.6654,
			"c": 22.6696,
			"h": 22.6732,
			"l": 22.6646,
			"t": 1344969060000,
			"n": 90
		  },
		  {
			"v": 669620,
			"vw": 22.6742,
			"o": 22.6679,
			"c": 22.6782,
			"h": 22.6786,
			"l": 22.6664,
			"t": 1344969120000,
			"n": 129
		  },
		  {
			"v": 581028,
			"vw": 22.68,
			"o": 22.6782,
			"c": 22.678,
			"h": 22.6854,
			"l": 22.677,
			"t": 1344969180000,
			"n": 134
		  },
		  {
			"v": 441616,
			"vw": 22.674,
			"o": 22.6782,
			"c": 22.6693,
			"h": 22.6786,
			"l": 22.6679,
			"t": 1344969240000,
			"n": 72
		  },
		  {
			"v": 263592,
			"vw": 22.6656,
			"o": 22.6707,
			"c": 22.6641,
			"h": 22.6707,
			"l": 22.6636,
			"t": 1344969300000,
			"n": 53
		  },
		  {
			"v": 207536,
			"vw": 22.6606,
			"o": 22.6639,
			"c": 22.6654,
			"h": 22.6657,
			"l": 22.6532,
			"t": 1344969360000,
			"n": 62
		  },
		  {
			"v": 291844,
			"vw": 22.6641,
			"o": 22.6618,
			"c": 22.665,
			"h": 22.6679,
			"l": 22.6586,
			"t": 1344969420000,
			"n": 77
		  },
		  {
			"v": 261520,
			"vw": 22.6625,
			"o": 22.6646,
			"c": 22.6546,
			"h": 22.6654,
			"l": 22.6539,
			"t": 1344969480000,
			"n": 63
		  },
		  {
			"v": 215852,
			"vw": 22.6506,
			"o": 22.6578,
			"c": 22.6476,
			"h": 22.6597,
			"l": 22.6457,
			"t": 1344969540000,
			"n": 46
		  },
		  {
			"v": 506352,
			"vw": 22.6432,
			"o": 22.6504,
			"c": 22.6357,
			"h": 22.6507,
			"l": 22.6357,
			"t": 1344969600000,
			"n": 127
		  },
		  {
			"v": 315924,
			"vw": 22.6427,
			"o": 22.6385,
			"c": 22.6471,
			"h": 22.6514,
			"l": 22.6375,
			"t": 1344969660000,
			"n": 87
		  },
		  {
			"v": 225428,
			"vw": 22.6491,
			"o": 22.6525,
			"c": 22.6539,
			"h": 22.6575,
			"l": 22.6439,
			"t": 1344969720000,
			"n": 59
		  },
		  {
			"v": 472444,
			"vw": 22.6455,
			"o": 22.6557,
			"c": 22.64,
			"h": 22.6557,
			"l": 22.6393,
			"t": 1344969780000,
			"n": 87
		  },
		  {
			"v": 725284,
			"vw": 22.6444,
			"o": 22.6429,
			"c": 22.6421,
			"h": 22.6518,
			"l": 22.6368,
			"t": 1344969840000,
			"n": 171
		  },
		  {
			"v": 166488,
			"vw": 22.6416,
			"o": 22.6424,
			"c": 22.6411,
			"h": 22.6429,
			"l": 22.6375,
			"t": 1344969900000,
			"n": 44
		  },
		  {
			"v": 354424,
			"vw": 22.6383,
			"o": 22.6414,
			"c": 22.6391,
			"h": 22.6429,
			"l": 22.6357,
			"t": 1344969960000,
			"n": 88
		  },
		  {
			"v": 360668,
			"vw": 22.645,
			"o": 22.6393,
			"c": 22.6517,
			"h": 22.6518,
			"l": 22.6382,
			"t": 1344970020000,
			"n": 95
		  },
		  {
			"v": 482104,
			"vw": 22.6595,
			"o": 22.6517,
			"c": 22.6586,
			"h": 22.6696,
			"l": 22.65,
			"t": 1344970080000,
			"n": 105
		  },
		  {
			"v": 341796,
			"vw": 22.6606,
			"o": 22.6554,
			"c": 22.6586,
			"h": 22.6632,
			"l": 22.6554,
			"t": 1344970140000,
			"n": 43
		  },
		  {
			"v": 223412,
			"vw": 22.6591,
			"o": 22.6571,
			"c": 22.6575,
			"h": 22.6618,
			"l": 22.6525,
			"t": 1344970200000,
			"n": 45
		  },
		  {
			"v": 202608,
			"vw": 22.6562,
			"o": 22.6611,
			"c": 22.6543,
			"h": 22.6611,
			"l": 22.6518,
			"t": 1344970260000,
			"n": 54
		  },
		  {
			"v": 451584,
			"vw": 22.6598,
			"o": 22.6543,
			"c": 22.6677,
			"h": 22.6679,
			"l": 22.6518,
			"t": 1344970320000,
			"n": 105
		  },
		  {
			"v": 162400,
			"vw": 22.6625,
			"o": 22.6682,
			"c": 22.66,
			"h": 22.6689,
			"l": 22.6596,
			"t": 1344970380000,
			"n": 42
		  },
		  {
			"v": 314944,
			"vw": 22.6577,
			"o": 22.6596,
			"c": 22.6564,
			"h": 22.6629,
			"l": 22.6518,
			"t": 1344970440000,
			"n": 74
		  },
		  {
			"v": 135184,
			"vw": 22.6548,
			"o": 22.6539,
			"c": 22.6511,
			"h": 22.6589,
			"l": 22.65,
			"t": 1344970500000,
			"n": 43
		  },
		  {
			"v": 256816,
			"vw": 22.6531,
			"o": 22.6507,
			"c": 22.6536,
			"h": 22.6546,
			"l": 22.6479,
			"t": 1344970560000,
			"n": 41
		  },
		  {
			"v": 340116,
			"vw": 22.6536,
			"o": 22.6518,
			"c": 22.6589,
			"h": 22.6589,
			"l": 22.6496,
			"t": 1344970620000,
			"n": 85
		  },
		  {
			"v": 577948,
			"vw": 22.6675,
			"o": 22.6557,
			"c": 22.6736,
			"h": 22.675,
			"l": 22.6557,
			"t": 1344970680000,
			"n": 101
		  },
		  {
			"v": 720076,
			"vw": 22.6768,
			"o": 22.6749,
			"c": 22.6793,
			"h": 22.6832,
			"l": 22.6725,
			"t": 1344970740000,
			"n": 139
		  },
		  {
			"v": 398608,
			"vw": 22.6803,
			"o": 22.6804,
			"c": 22.6782,
			"h": 22.6832,
			"l": 22.675,
			"t": 1344970800000,
			"n": 65
		  },
		  {
			"v": 156800,
			"vw": 22.6737,
			"o": 22.6768,
			"c": 22.675,
			"h": 22.6781,
			"l": 22.6679,
			"t": 1344970860000,
			"n": 42
		  },
		  {
			"v": 360080,
			"vw": 22.6765,
			"o": 22.6764,
			"c": 22.6793,
			"h": 22.6807,
			"l": 22.6732,
			"t": 1344970920000,
			"n": 86
		  },
		  {
			"v": 304920,
			"vw": 22.6756,
			"o": 22.6793,
			"c": 22.6764,
			"h": 22.6811,
			"l": 22.6714,
			"t": 1344970980000,
			"n": 59
		  },
		  {
			"v": 112000,
			"vw": 22.6712,
			"o": 22.6745,
			"c": 22.6704,
			"h": 22.6745,
			"l": 22.6686,
			"t": 1344971040000,
			"n": 35
		  },
		  {
			"v": 191800,
			"vw": 22.6711,
			"o": 22.6704,
			"c": 22.6743,
			"h": 22.6743,
			"l": 22.6693,
			"t": 1344971100000,
			"n": 52
		  },
		  {
			"v": 628908,
			"vw": 22.6639,
			"o": 22.6736,
			"c": 22.6573,
			"h": 22.6764,
			"l": 22.6536,
			"t": 1344971160000,
			"n": 124
		  },
		  {
			"v": 276836,
			"vw": 22.6643,
			"o": 22.6604,
			"c": 22.6668,
			"h": 22.6693,
			"l": 22.6589,
			"t": 1344971220000,
			"n": 79
		  },
		  {
			"v": 224868,
			"vw": 22.6621,
			"o": 22.6643,
			"c": 22.6554,
			"h": 22.6668,
			"l": 22.6554,
			"t": 1344971280000,
			"n": 62
		  },
		  {
			"v": 471212,
			"vw": 22.6526,
			"o": 22.655,
			"c": 22.6536,
			"h": 22.6571,
			"l": 22.6468,
			"t": 1344971340000,
			"n": 113
		  },
		  {
			"v": 415464,
			"vw": 22.6485,
			"o": 22.6557,
			"c": 22.6514,
			"h": 22.6557,
			"l": 22.6436,
			"t": 1344971400000,
			"n": 104
		  },
		  {
			"v": 364896,
			"vw": 22.6508,
			"o": 22.65,
			"c": 22.6509,
			"h": 22.6582,
			"l": 22.6475,
			"t": 1344971460000,
			"n": 56
		  },
		  {
			"v": 744212,
			"vw": 22.6415,
			"o": 22.6529,
			"c": 22.6357,
			"h": 22.6564,
			"l": 22.6339,
			"t": 1344971520000,
			"n": 202
		  },
		  {
			"v": 951104,
			"vw": 22.6309,
			"o": 22.6361,
			"c": 22.63,
			"h": 22.6429,
			"l": 22.625,
			"t": 1344971580000,
			"n": 189
		  },
		  {
			"v": 327628,
			"vw": 22.6353,
			"o": 22.6332,
			"c": 22.6386,
			"h": 22.6429,
			"l": 22.6304,
			"t": 1344971640000,
			"n": 79
		  },
		  {
			"v": 294728,
			"vw": 22.6369,
			"o": 22.6421,
			"c": 22.6329,
			"h": 22.6421,
			"l": 22.6325,
			"t": 1344971700000,
			"n": 78
		  },
		  {
			"v": 1076740,
			"vw": 22.6249,
			"o": 22.6329,
			"c": 22.6147,
			"h": 22.6371,
			"l": 22.6146,
			"t": 1344971760000,
			"n": 202
		  },
		  {
			"v": 817180,
			"vw": 22.6249,
			"o": 22.6146,
			"c": 22.6321,
			"h": 22.6368,
			"l": 22.6146,
			"t": 1344971820000,
			"n": 206
		  },
		  {
			"v": 486780,
			"vw": 22.6364,
			"o": 22.6329,
			"c": 22.6386,
			"h": 22.6414,
			"l": 22.6325,
			"t": 1344971880000,
			"n": 87
		  },
		  {
			"v": 493948,
			"vw": 22.6398,
			"o": 22.6393,
			"c": 22.6425,
			"h": 22.6429,
			"l": 22.6337,
			"t": 1344971940000,
			"n": 97
		  },
		  {
			"v": 352800,
			"vw": 22.6403,
			"o": 22.6382,
			"c": 22.6418,
			"h": 22.6429,
			"l": 22.6375,
			"t": 1344972000000,
			"n": 108
		  },
		  {
			"v": 527296,
			"vw": 22.6367,
			"o": 22.6407,
			"c": 22.6357,
			"h": 22.6418,
			"l": 22.6357,
			"t": 1344972060000,
			"n": 68
		  },
		  {
			"v": 724108,
			"vw": 22.6411,
			"o": 22.6357,
			"c": 22.6457,
			"h": 22.6461,
			"l": 22.6311,
			"t": 1344972120000,
			"n": 145
		  },
		  {
			"v": 468580,
			"vw": 22.6474,
			"o": 22.6457,
			"c": 22.6429,
			"h": 22.65,
			"l": 22.6425,
			"t": 1344972180000,
			"n": 116
		  },
		  {
			"v": 340732,
			"vw": 22.6448,
			"o": 22.6429,
			"c": 22.6477,
			"h": 22.6479,
			"l": 22.6404,
			"t": 1344972240000,
			"n": 84
		  },
		  {
			"v": 429464,
			"vw": 22.6491,
			"o": 22.65,
			"c": 22.6386,
			"h": 22.656,
			"l": 22.6386,
			"t": 1344972300000,
			"n": 86
		  },
		  {
			"v": 228984,
			"vw": 22.6389,
			"o": 22.6379,
			"c": 22.6393,
			"h": 22.6429,
			"l": 22.6357,
			"t": 1344972360000,
			"n": 58
		  },
		  {
			"v": 265776,
			"vw": 22.6381,
			"o": 22.6386,
			"c": 22.6354,
			"h": 22.6425,
			"l": 22.6354,
			"t": 1344972420000,
			"n": 64
		  },
		  {
			"v": 820148,
			"vw": 22.6234,
			"o": 22.6343,
			"c": 22.6189,
			"h": 22.6366,
			"l": 22.6164,
			"t": 1344972480000,
			"n": 181
		  },
		  {
			"v": 820260,
			"vw": 22.6261,
			"o": 22.6196,
			"c": 22.6318,
			"h": 22.6354,
			"l": 22.6182,
			"t": 1344972540000,
			"n": 201
		  },
		  {
			"v": 577052,
			"vw": 22.6325,
			"o": 22.6311,
			"c": 22.6304,
			"h": 22.6386,
			"l": 22.6286,
			"t": 1344972600000,
			"n": 138
		  },
		  {
			"v": 1565676,
			"vw": 22.6236,
			"o": 22.6316,
			"c": 22.6182,
			"h": 22.6321,
			"l": 22.6129,
			"t": 1344972660000,
			"n": 338
		  },
		  {
			"v": 1548036,
			"vw": 22.6062,
			"o": 22.6171,
			"c": 22.6025,
			"h": 22.6182,
			"l": 22.5982,
			"t": 1344972720000,
			"n": 324
		  },
		  {
			"v": 1758932,
			"vw": 22.5946,
			"o": 22.6025,
			"c": 22.5972,
			"h": 22.6025,
			"l": 22.5893,
			"t": 1344972780000,
			"n": 443
		  },
		  {
			"v": 1295280,
			"vw": 22.6037,
			"o": 22.5973,
			"c": 22.5946,
			"h": 22.6082,
			"l": 22.5946,
			"t": 1344972840000,
			"n": 287
		  },
		  {
			"v": 2433732,
			"vw": 22.5815,
			"o": 22.5946,
			"c": 22.5714,
			"h": 22.5961,
			"l": 22.5714,
			"t": 1344972900000,
			"n": 614
		  },
		  {
			"v": 1834756,
			"vw": 22.5757,
			"o": 22.5714,
			"c": 22.5715,
			"h": 22.5854,
			"l": 22.5714,
			"t": 1344972960000,
			"n": 424
		  },
		  {
			"v": 2541532,
			"vw": 22.5648,
			"o": 22.5732,
			"c": 22.5704,
			"h": 22.5732,
			"l": 22.5571,
			"t": 1344973020000,
			"n": 637
		  },
		  {
			"v": 1847328,
			"vw": 22.5739,
			"o": 22.5682,
			"c": 22.5766,
			"h": 22.5818,
			"l": 22.5621,
			"t": 1344973080000,
			"n": 359
		  },
		  {
			"v": 1239700,
			"vw": 22.5811,
			"o": 22.5764,
			"c": 22.5769,
			"h": 22.5857,
			"l": 22.5751,
			"t": 1344973140000,
			"n": 271
		  },
		  {
			"v": 604968,
			"vw": 22.5765,
			"o": 22.5793,
			"c": 22.5745,
			"h": 22.5818,
			"l": 22.5721,
			"t": 1344973200000,
			"n": 143
		  },
		  {
			"v": 1336748,
			"vw": 22.5692,
			"o": 22.5732,
			"c": 22.5686,
			"h": 22.5761,
			"l": 22.5621,
			"t": 1344973260000,
			"n": 288
		  },
		  {
			"v": 1814876,
			"vw": 22.5571,
			"o": 22.5696,
			"c": 22.545,
			"h": 22.5728,
			"l": 22.545,
			"t": 1344973320000,
			"n": 476
		  },
		  {
			"v": 1591184,
			"vw": 22.5576,
			"o": 22.5461,
			"c": 22.5577,
			"h": 22.565,
			"l": 22.5454,
			"t": 1344973380000,
			"n": 418
		  },
		  {
			"v": 2148664,
			"vw": 22.5599,
			"o": 22.5643,
			"c": 22.5596,
			"h": 22.5786,
			"l": 22.5404,
			"t": 1344973440000,
			"n": 467
		  },
		  {
			"v": 1148252,
			"vw": 22.5515,
			"o": 22.56,
			"c": 22.5581,
			"h": 22.5607,
			"l": 22.5429,
			"t": 1344973500000,
			"n": 281
		  },
		  {
			"v": 1987776,
			"vw": 22.5453,
			"o": 22.56,
			"c": 22.5436,
			"h": 22.5668,
			"l": 22.5325,
			"t": 1344973560000,
			"n": 441
		  },
		  {
			"v": 1791888,
			"vw": 22.5335,
			"o": 22.5436,
			"c": 22.5251,
			"h": 22.5486,
			"l": 22.5218,
			"t": 1344973620000,
			"n": 406
		  },
		  {
			"v": 2145668,
			"vw": 22.522,
			"o": 22.5264,
			"c": 22.5264,
			"h": 22.535,
			"l": 22.5075,
			"t": 1344973680000,
			"n": 430
		  },
		  {
			"v": 1871800,
			"vw": 22.5322,
			"o": 22.5289,
			"c": 22.5436,
			"h": 22.5461,
			"l": 22.5225,
			"t": 1344973740000,
			"n": 440
		  },
		  {
			"v": 1498644,
			"vw": 22.536,
			"o": 22.5461,
			"c": 22.5355,
			"h": 22.547,
			"l": 22.5218,
			"t": 1344973800000,
			"n": 378
		  },
		  {
			"v": 1604372,
			"vw": 22.5488,
			"o": 22.5396,
			"c": 22.5557,
			"h": 22.5571,
			"l": 22.5346,
			"t": 1344973860000,
			"n": 357
		  },
		  {
			"v": 1130584,
			"vw": 22.5491,
			"o": 22.5557,
			"c": 22.545,
			"h": 22.5564,
			"l": 22.5421,
			"t": 1344973920000,
			"n": 282
		  },
		  {
			"v": 1923124,
			"vw": 22.5358,
			"o": 22.5457,
			"c": 22.5346,
			"h": 22.55,
			"l": 22.5268,
			"t": 1344973980000,
			"n": 444
		  },
		  {
			"v": 870016,
			"vw": 22.5351,
			"o": 22.5379,
			"c": 22.5364,
			"h": 22.5396,
			"l": 22.5304,
			"t": 1344974040000,
			"n": 201
		  },
		  {
			"v": 806428,
			"vw": 22.5432,
			"o": 22.5354,
			"c": 22.5468,
			"h": 22.5496,
			"l": 22.5354,
			"t": 1344974100000,
			"n": 198
		  },
		  {
			"v": 622076,
			"vw": 22.5391,
			"o": 22.5468,
			"c": 22.5379,
			"h": 22.5468,
			"l": 22.5357,
			"t": 1344974160000,
			"n": 152
		  },
		  {
			"v": 1663844,
			"vw": 22.5426,
			"o": 22.5388,
			"c": 22.5461,
			"h": 22.5486,
			"l": 22.5364,
			"t": 1344974220000,
			"n": 482
		  },
		  {
			"v": 2460052,
			"vw": 22.5482,
			"o": 22.5486,
			"c": 22.5532,
			"h": 22.5571,
			"l": 22.5382,
			"t": 1344974280000,
			"n": 629
		  },
		  {
			"v": 2865800,
			"vw": 22.5598,
			"o": 22.5532,
			"c": 22.5582,
			"h": 22.5654,
			"l": 22.5532,
			"t": 1344974340000,
			"n": 674
		  },
		  {
			"v": 87864,
			"vw": 22.5594,
			"o": 22.5618,
			"c": 22.5589,
			"h": 22.5618,
			"l": 22.5568,
			"t": 1344974400000,
			"n": 10
		  },
		  {
			"v": 78120,
			"vw": 22.5569,
			"o": 22.5586,
			"c": 22.5568,
			"h": 22.5593,
			"l": 22.5546,
			"t": 1344974460000,
			"n": 12
		  },
		  {
			"v": 72268,
			"vw": 22.5596,
			"o": 22.5571,
			"c": 22.5489,
			"h": 22.5571,
			"l": 22.5489,
			"t": 1344974520000,
			"n": 19
		  },
		  {
			"v": 133280,
			"vw": 22.555,
			"o": 22.5511,
			"c": 22.5571,
			"h": 22.5571,
			"l": 22.5511,
			"t": 1344974580000,
			"n": 34
		  },
		  {
			"v": 36400,
			"vw": 22.5575,
			"o": 22.5571,
			"c": 22.5593,
			"h": 22.5593,
			"l": 22.5561,
			"t": 1344974640000,
			"n": 10
		  },
		  {
			"v": 33600,
			"vw": 22.557,
			"o": 22.5589,
			"c": 22.5596,
			"h": 22.5596,
			"l": 22.5554,
			"t": 1344974700000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 22.559,
			"o": 22.5596,
			"c": 22.5571,
			"h": 22.5604,
			"l": 22.5571,
			"t": 1344974760000,
			"n": 3
		  },
		  {
			"v": 87024,
			"vw": 22.5619,
			"o": 22.5604,
			"c": 22.5607,
			"h": 22.5629,
			"l": 22.5604,
			"t": 1344974880000,
			"n": 12
		  },
		  {
			"v": 13048,
			"vw": 22.5593,
			"o": 22.5607,
			"c": 22.5589,
			"h": 22.5607,
			"l": 22.5589,
			"t": 1344974940000,
			"n": 3
		  },
		  {
			"v": 42000,
			"vw": 22.6217,
			"o": 22.5596,
			"c": 22.5579,
			"h": 22.5596,
			"l": 22.5579,
			"t": 1344975000000,
			"n": 5
		  },
		  {
			"v": 6356,
			"vw": 22.6172,
			"o": 22.5593,
			"c": 22.5593,
			"h": 22.5593,
			"l": 22.5593,
			"t": 1344975120000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5625,
			"o": 22.5625,
			"c": 22.5625,
			"h": 22.5625,
			"l": 22.5625,
			"t": 1344975180000,
			"n": 1
		  },
		  {
			"v": 28112,
			"vw": 22.5651,
			"o": 22.565,
			"c": 22.5686,
			"h": 22.5686,
			"l": 22.5625,
			"t": 1344975240000,
			"n": 7
		  },
		  {
			"v": 36484,
			"vw": 22.5649,
			"o": 22.5654,
			"c": 22.5643,
			"h": 22.5661,
			"l": 22.5625,
			"t": 1344975300000,
			"n": 5
		  },
		  {
			"v": 5600,
			"vw": 22.5646,
			"o": 22.5646,
			"c": 22.5646,
			"h": 22.5646,
			"l": 22.5646,
			"t": 1344975420000,
			"n": 1
		  },
		  {
			"v": 29960,
			"vw": 22.5676,
			"o": 22.5661,
			"c": 22.5679,
			"h": 22.5689,
			"l": 22.5661,
			"t": 1344975540000,
			"n": 7
		  },
		  {
			"v": 46200,
			"vw": 22.5707,
			"o": 22.5679,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5679,
			"t": 1344975600000,
			"n": 8
		  },
		  {
			"v": 8400,
			"vw": 22.5726,
			"o": 22.5725,
			"c": 22.5729,
			"h": 22.5729,
			"l": 22.5725,
			"t": 1344975660000,
			"n": 2
		  },
		  {
			"v": 6888,
			"vw": 22.5722,
			"o": 22.5718,
			"c": 22.5729,
			"h": 22.5729,
			"l": 22.5718,
			"t": 1344975720000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5716,
			"o": 22.5718,
			"c": 22.5714,
			"h": 22.5718,
			"l": 22.5714,
			"t": 1344975780000,
			"n": 2
		  },
		  {
			"v": 4116,
			"vw": 22.5714,
			"o": 22.5714,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5714,
			"t": 1344975840000,
			"n": 1
		  },
		  {
			"v": 37100,
			"vw": 22.5738,
			"o": 22.5732,
			"c": 22.5739,
			"h": 22.5739,
			"l": 22.5732,
			"t": 1344975900000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5718,
			"o": 22.5718,
			"c": 22.5718,
			"h": 22.5718,
			"l": 22.5718,
			"t": 1344975960000,
			"n": 1
		  },
		  {
			"v": 32228,
			"vw": 22.5659,
			"o": 22.5764,
			"c": 22.5764,
			"h": 22.5764,
			"l": 22.5764,
			"t": 1344976020000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.575,
			"o": 22.575,
			"c": 22.575,
			"h": 22.575,
			"l": 22.575,
			"t": 1344976200000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.575,
			"o": 22.575,
			"c": 22.575,
			"h": 22.575,
			"l": 22.575,
			"t": 1344976260000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.572,
			"o": 22.5732,
			"c": 22.5714,
			"h": 22.5732,
			"l": 22.5714,
			"t": 1344976320000,
			"n": 2
		  },
		  {
			"v": 5180,
			"vw": 22.5714,
			"o": 22.5714,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5714,
			"t": 1344976560000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5634,
			"o": 22.5671,
			"c": 22.5596,
			"h": 22.5671,
			"l": 22.5596,
			"t": 1344976620000,
			"n": 2
		  },
		  {
			"v": 131600,
			"vw": 22.5762,
			"o": 22.5743,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.5661,
			"t": 1344976740000,
			"n": 25
		  },
		  {
			"v": 7000,
			"vw": 22.5779,
			"o": 22.5732,
			"c": 22.5811,
			"h": 22.5811,
			"l": 22.5732,
			"t": 1344976860000,
			"n": 2
		  },
		  {
			"v": 56000,
			"vw": 22.6199,
			"o": 22.5814,
			"c": 22.5814,
			"h": 22.5814,
			"l": 22.5814,
			"t": 1344976920000,
			"n": 3
		  },
		  {
			"v": 6748,
			"vw": 22.5738,
			"o": 22.5736,
			"c": 22.5739,
			"h": 22.5739,
			"l": 22.5736,
			"t": 1344977160000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5775,
			"o": 22.5775,
			"c": 22.5775,
			"h": 22.5775,
			"l": 22.5775,
			"t": 1344977280000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5818,
			"o": 22.5818,
			"c": 22.5818,
			"h": 22.5818,
			"l": 22.5818,
			"t": 1344977340000,
			"n": 1
		  },
		  {
			"v": 70420,
			"vw": 22.5765,
			"o": 22.5789,
			"c": 22.5725,
			"h": 22.5789,
			"l": 22.5725,
			"t": 1344977400000,
			"n": 10
		  },
		  {
			"v": 8904,
			"vw": 22.5772,
			"o": 22.5779,
			"c": 22.5768,
			"h": 22.5779,
			"l": 22.5768,
			"t": 1344977460000,
			"n": 2
		  },
		  {
			"v": 28000,
			"vw": 22.5726,
			"o": 22.5739,
			"c": 22.57,
			"h": 22.5739,
			"l": 22.57,
			"t": 1344977520000,
			"n": 4
		  },
		  {
			"v": 11200,
			"vw": 22.5714,
			"o": 22.5714,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5714,
			"t": 1344977580000,
			"n": 1
		  },
		  {
			"v": 24528,
			"vw": 22.5663,
			"o": 22.5686,
			"c": 22.5646,
			"h": 22.5686,
			"l": 22.5646,
			"t": 1344977640000,
			"n": 3
		  },
		  {
			"v": 41860,
			"vw": 22.5649,
			"o": 22.5661,
			"c": 22.5643,
			"h": 22.5661,
			"l": 22.5643,
			"t": 1344977700000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.5611,
			"o": 22.5611,
			"c": 22.5611,
			"h": 22.5611,
			"l": 22.5611,
			"t": 1344978000000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5607,
			"o": 22.5607,
			"c": 22.5607,
			"h": 22.5607,
			"l": 22.5607,
			"t": 1344978120000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5625,
			"o": 22.5625,
			"c": 22.5625,
			"h": 22.5625,
			"l": 22.5625,
			"t": 1344978360000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5636,
			"o": 22.5636,
			"c": 22.5636,
			"h": 22.5636,
			"l": 22.5636,
			"t": 1344978540000,
			"n": 1
		  },
		  {
			"v": 8988,
			"vw": 22.5607,
			"o": 22.5607,
			"c": 22.5607,
			"h": 22.5607,
			"l": 22.5607,
			"t": 1344978600000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.5641,
			"o": 22.5636,
			"c": 22.5643,
			"h": 22.5643,
			"l": 22.5636,
			"t": 1344978660000,
			"n": 3
		  },
		  {
			"v": 5544,
			"vw": 22.5607,
			"o": 22.5607,
			"c": 22.5607,
			"h": 22.5607,
			"l": 22.5607,
			"t": 1344978780000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5675,
			"o": 22.5675,
			"c": 22.5675,
			"h": 22.5675,
			"l": 22.5675,
			"t": 1344978840000,
			"n": 1
		  },
		  {
			"v": 16800,
			"vw": 22.5623,
			"o": 22.5625,
			"c": 22.5614,
			"h": 22.5625,
			"l": 22.5614,
			"t": 1344978900000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.5638,
			"o": 22.5625,
			"c": 22.5664,
			"h": 22.5664,
			"l": 22.5625,
			"t": 1344978960000,
			"n": 2
		  },
		  {
			"v": 25200,
			"vw": 22.5677,
			"o": 22.5671,
			"c": 22.5679,
			"h": 22.5679,
			"l": 22.5671,
			"t": 1344979020000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.5686,
			"o": 22.5686,
			"c": 22.5686,
			"h": 22.5686,
			"l": 22.5686,
			"t": 1344979200000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5693,
			"o": 22.5693,
			"c": 22.5693,
			"h": 22.5693,
			"l": 22.5693,
			"t": 1344979260000,
			"n": 1
		  },
		  {
			"v": 27104,
			"vw": 22.5694,
			"o": 22.5679,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5679,
			"t": 1344979320000,
			"n": 4
		  },
		  {
			"v": 2800,
			"vw": 22.5725,
			"o": 22.5725,
			"c": 22.5725,
			"h": 22.5725,
			"l": 22.5725,
			"t": 1344979560000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5789,
			"o": 22.5789,
			"c": 22.5789,
			"h": 22.5789,
			"l": 22.5789,
			"t": 1344980460000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.5746,
			"o": 22.5754,
			"c": 22.5739,
			"h": 22.5754,
			"l": 22.5739,
			"t": 1344980580000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5804,
			"o": 22.5804,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.5804,
			"t": 1344980640000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5811,
			"o": 22.5811,
			"c": 22.5811,
			"h": 22.5811,
			"l": 22.5811,
			"t": 1344980760000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5788,
			"o": 22.5804,
			"c": 22.5768,
			"h": 22.5804,
			"l": 22.5768,
			"t": 1344981960000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5829,
			"o": 22.5829,
			"c": 22.5829,
			"h": 22.5829,
			"l": 22.5829,
			"t": 1344982500000,
			"n": 1
		  },
		  {
			"v": 5516,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344982740000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344982920000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.5849,
			"o": 22.5839,
			"c": 22.5889,
			"h": 22.5889,
			"l": 22.5839,
			"t": 1344983040000,
			"n": 2
		  },
		  {
			"v": 23800,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344983100000,
			"n": 2
		  },
		  {
			"v": 4060,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344983160000,
			"n": 1
		  },
		  {
			"v": 8428,
			"vw": 22.5839,
			"o": 22.5839,
			"c": 22.5839,
			"h": 22.5839,
			"l": 22.5839,
			"t": 1344983220000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5821,
			"o": 22.5821,
			"c": 22.5821,
			"h": 22.5821,
			"l": 22.5821,
			"t": 1344983340000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5861,
			"o": 22.5861,
			"c": 22.5861,
			"h": 22.5861,
			"l": 22.5861,
			"t": 1344983400000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5782,
			"o": 22.5782,
			"c": 22.5782,
			"h": 22.5782,
			"l": 22.5782,
			"t": 1344983460000,
			"n": 1
		  },
		  {
			"v": 7224,
			"vw": 22.5821,
			"o": 22.5821,
			"c": 22.5821,
			"h": 22.5821,
			"l": 22.5821,
			"t": 1344983520000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5836,
			"o": 22.5836,
			"c": 22.5836,
			"h": 22.5836,
			"l": 22.5836,
			"t": 1344983640000,
			"n": 1
		  },
		  {
			"v": 24500,
			"vw": 22.5849,
			"o": 22.5821,
			"c": 22.5857,
			"h": 22.5857,
			"l": 22.5821,
			"t": 1344983760000,
			"n": 6
		  },
		  {
			"v": 19600,
			"vw": 22.5838,
			"o": 22.5786,
			"c": 22.5846,
			"h": 22.5846,
			"l": 22.5786,
			"t": 1344984120000,
			"n": 3
		  },
		  {
			"v": 16044,
			"vw": 22.5846,
			"o": 22.5846,
			"c": 22.5846,
			"h": 22.5846,
			"l": 22.5846,
			"t": 1344984240000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5807,
			"o": 22.5807,
			"c": 22.5807,
			"h": 22.5807,
			"l": 22.5807,
			"t": 1344984300000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5804,
			"o": 22.5804,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.5804,
			"t": 1344985080000,
			"n": 1
		  },
		  {
			"v": 3220,
			"vw": 22.5786,
			"o": 22.5786,
			"c": 22.5786,
			"h": 22.5786,
			"l": 22.5786,
			"t": 1344985260000,
			"n": 1
		  },
		  {
			"v": 10612,
			"vw": 22.5769,
			"o": 22.5771,
			"c": 22.5768,
			"h": 22.5771,
			"l": 22.5768,
			"t": 1344985320000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5836,
			"o": 22.5836,
			"c": 22.5836,
			"h": 22.5836,
			"l": 22.5836,
			"t": 1344985680000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5768,
			"o": 22.5768,
			"c": 22.5768,
			"h": 22.5768,
			"l": 22.5768,
			"t": 1344985800000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5771,
			"o": 22.5771,
			"c": 22.5771,
			"h": 22.5771,
			"l": 22.5771,
			"t": 1344986040000,
			"n": 1
		  },
		  {
			"v": 18984,
			"vw": 22.5768,
			"o": 22.5761,
			"c": 22.5771,
			"h": 22.5771,
			"l": 22.5761,
			"t": 1344986640000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.5757,
			"o": 22.5771,
			"c": 22.5743,
			"h": 22.5771,
			"l": 22.5743,
			"t": 1344986700000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5739,
			"o": 22.5739,
			"c": 22.5739,
			"h": 22.5739,
			"l": 22.5739,
			"t": 1344986760000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5739,
			"o": 22.5739,
			"c": 22.5739,
			"h": 22.5739,
			"l": 22.5739,
			"t": 1344986820000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5749,
			"o": 22.575,
			"c": 22.5746,
			"h": 22.575,
			"l": 22.5746,
			"t": 1344987300000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5743,
			"o": 22.5743,
			"c": 22.5743,
			"h": 22.5743,
			"l": 22.5743,
			"t": 1344987420000,
			"n": 1
		  },
		  {
			"v": 17080,
			"vw": 22.5742,
			"o": 22.5746,
			"c": 22.5739,
			"h": 22.5746,
			"l": 22.5739,
			"t": 1344987840000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.5814,
			"o": 22.5814,
			"c": 22.5814,
			"h": 22.5814,
			"l": 22.5814,
			"t": 1344988020000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5814,
			"o": 22.5814,
			"c": 22.5814,
			"h": 22.5814,
			"l": 22.5814,
			"t": 1344988260000,
			"n": 1
		  },
		  {
			"v": 3948,
			"vw": 22.5804,
			"o": 22.5804,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.5804,
			"t": 1344988320000,
			"n": 1
		  },
		  {
			"v": 30072,
			"vw": 22.5762,
			"o": 22.575,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.575,
			"t": 1344988380000,
			"n": 9
		  },
		  {
			"v": 5600,
			"vw": 22.5755,
			"o": 22.5754,
			"c": 22.5757,
			"h": 22.5757,
			"l": 22.5754,
			"t": 1344988500000,
			"n": 2
		  },
		  {
			"v": 9660,
			"vw": 22.5753,
			"o": 22.5754,
			"c": 22.5754,
			"h": 22.5754,
			"l": 22.575,
			"t": 1344988620000,
			"n": 3
		  },
		  {
			"v": 16800,
			"vw": 22.5778,
			"o": 22.5836,
			"c": 22.5754,
			"h": 22.5836,
			"l": 22.5754,
			"t": 1344988680000,
			"n": 3
		  },
		  {
			"v": 57092,
			"vw": 22.5881,
			"o": 22.5832,
			"c": 22.5893,
			"h": 22.5893,
			"l": 22.5832,
			"t": 1344988740000,
			"n": 13
		  },
		  {
			"v": 5600,
			"vw": 22.5411,
			"o": 22.5357,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5357,
			"t": 1345018140000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5455,
			"o": 22.5468,
			"c": 22.5443,
			"h": 22.5468,
			"l": 22.5443,
			"t": 1345018260000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5396,
			"o": 22.5396,
			"c": 22.5396,
			"h": 22.5396,
			"l": 22.5396,
			"t": 1345018380000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5464,
			"o": 22.5464,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5464,
			"t": 1345018860000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.5464,
			"o": 22.5464,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5464,
			"t": 1345019100000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5786,
			"o": 22.5786,
			"c": 22.5786,
			"h": 22.5786,
			"l": 22.5786,
			"t": 1345021020000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6071,
			"t": 1345022940000,
			"n": 1
		  },
		  {
			"v": 9716,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6071,
			"t": 1345023600000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.6107,
			"o": 22.6107,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6107,
			"t": 1345023840000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6107,
			"o": 22.6107,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6107,
			"t": 1345024020000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.6107,
			"o": 22.6107,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6107,
			"t": 1345024620000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.5982,
			"o": 22.6071,
			"c": 22.5893,
			"h": 22.6071,
			"l": 22.5893,
			"t": 1345024680000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5786,
			"o": 22.5786,
			"c": 22.5786,
			"h": 22.5786,
			"l": 22.5786,
			"t": 1345025280000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5679,
			"o": 22.5679,
			"c": 22.5679,
			"h": 22.5679,
			"l": 22.5679,
			"t": 1345025700000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5571,
			"o": 22.5571,
			"c": 22.5571,
			"h": 22.5571,
			"l": 22.5571,
			"t": 1345026120000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5396,
			"o": 22.54,
			"c": 22.5393,
			"h": 22.54,
			"l": 22.5393,
			"t": 1345026300000,
			"n": 2
		  },
		  {
			"v": 4788,
			"vw": 22.5464,
			"o": 22.5464,
			"c": 22.5464,
			"h": 22.5464,
			"l": 22.5464,
			"t": 1345027380000,
			"n": 1
		  },
		  {
			"v": 4200,
			"vw": 22.5714,
			"o": 22.5714,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5714,
			"t": 1345027500000,
			"n": 1
		  },
		  {
			"v": 21000,
			"vw": 22.5712,
			"o": 22.5711,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5711,
			"t": 1345028400000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.5714,
			"o": 22.5714,
			"c": 22.5714,
			"h": 22.5714,
			"l": 22.5714,
			"t": 1345028460000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5679,
			"o": 22.5679,
			"c": 22.5679,
			"h": 22.5679,
			"l": 22.5679,
			"t": 1345028760000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5629,
			"o": 22.5629,
			"c": 22.5629,
			"h": 22.5629,
			"l": 22.5629,
			"t": 1345028820000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.5589,
			"o": 22.5625,
			"c": 22.5571,
			"h": 22.5625,
			"l": 22.5571,
			"t": 1345029000000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5354,
			"o": 22.5354,
			"c": 22.5354,
			"h": 22.5354,
			"l": 22.5354,
			"t": 1345029240000,
			"n": 1
		  },
		  {
			"v": 11200,
			"vw": 22.5203,
			"o": 22.5218,
			"c": 22.5196,
			"h": 22.5218,
			"l": 22.5196,
			"t": 1345029600000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.5229,
			"o": 22.5221,
			"c": 22.5236,
			"h": 22.5236,
			"l": 22.5221,
			"t": 1345029780000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5357,
			"o": 22.5357,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5357,
			"t": 1345029840000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5429,
			"o": 22.5429,
			"c": 22.5429,
			"h": 22.5429,
			"l": 22.5429,
			"t": 1345029960000,
			"n": 1
		  },
		  {
			"v": 16604,
			"vw": 22.543,
			"o": 22.5493,
			"c": 22.5339,
			"h": 22.5493,
			"l": 22.5339,
			"t": 1345030200000,
			"n": 3
		  },
		  {
			"v": 39200,
			"vw": 22.5139,
			"o": 22.52,
			"c": 22.5118,
			"h": 22.52,
			"l": 22.5118,
			"t": 1345030380000,
			"n": 5
		  },
		  {
			"v": 12460,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1345030500000,
			"n": 2
		  },
		  {
			"v": 96040,
			"vw": 22.5192,
			"o": 22.5207,
			"c": 22.5243,
			"h": 22.5243,
			"l": 22.5125,
			"t": 1345030560000,
			"n": 9
		  },
		  {
			"v": 127988,
			"vw": 22.5161,
			"o": 22.5136,
			"c": 22.5071,
			"h": 22.5357,
			"l": 22.5071,
			"t": 1345030620000,
			"n": 19
		  },
		  {
			"v": 5600,
			"vw": 22.5104,
			"o": 22.5118,
			"c": 22.5089,
			"h": 22.5118,
			"l": 22.5089,
			"t": 1345030980000,
			"n": 2
		  },
		  {
			"v": 8400,
			"vw": 22.5179,
			"o": 22.5179,
			"c": 22.5179,
			"h": 22.5179,
			"l": 22.5179,
			"t": 1345031100000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.5161,
			"o": 22.5161,
			"c": 22.5161,
			"h": 22.5161,
			"l": 22.5161,
			"t": 1345031220000,
			"n": 1
		  },
		  {
			"v": 22848,
			"vw": 22.5232,
			"o": 22.5229,
			"c": 22.5232,
			"h": 22.5232,
			"l": 22.5229,
			"t": 1345031280000,
			"n": 4
		  },
		  {
			"v": 18788,
			"vw": 22.5346,
			"o": 22.5325,
			"c": 22.5357,
			"h": 22.5357,
			"l": 22.5325,
			"t": 1345031520000,
			"n": 6
		  },
		  {
			"v": 47600,
			"vw": 22.5551,
			"o": 22.5518,
			"c": 22.5614,
			"h": 22.5614,
			"l": 22.5518,
			"t": 1345031640000,
			"n": 7
		  },
		  {
			"v": 5600,
			"vw": 22.5439,
			"o": 22.5439,
			"c": 22.5439,
			"h": 22.5439,
			"l": 22.5439,
			"t": 1345031880000,
			"n": 1
		  },
		  {
			"v": 5600,
			"vw": 22.5511,
			"o": 22.5475,
			"c": 22.5546,
			"h": 22.5546,
			"l": 22.5475,
			"t": 1345032000000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.5529,
			"o": 22.5529,
			"c": 22.5529,
			"h": 22.5529,
			"l": 22.5529,
			"t": 1345032120000,
			"n": 1
		  },
		  {
			"v": 14756,
			"vw": 22.5402,
			"o": 22.54,
			"c": 22.5429,
			"h": 22.5464,
			"l": 22.5357,
			"t": 1345032180000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.5514,
			"o": 22.5514,
			"c": 22.5514,
			"h": 22.5514,
			"l": 22.5514,
			"t": 1345032360000,
			"n": 1
		  },
		  {
			"v": 2800,
			"vw": 22.5454,
			"o": 22.5454,
			"c": 22.5454,
			"h": 22.5454,
			"l": 22.5454,
			"t": 1345032540000,
			"n": 1
		  },
		  {
			"v": 14392,
			"vw": 22.5468,
			"o": 22.5482,
			"c": 22.5446,
			"h": 22.5482,
			"l": 22.5446,
			"t": 1345032600000,
			"n": 3
		  },
		  {
			"v": 44520,
			"vw": 22.5508,
			"o": 22.5493,
			"c": 22.5571,
			"h": 22.5571,
			"l": 22.5493,
			"t": 1345032720000,
			"n": 12
		  },
		  {
			"v": 36400,
			"vw": 22.5683,
			"o": 22.5675,
			"c": 22.5725,
			"h": 22.5725,
			"l": 22.5675,
			"t": 1345032780000,
			"n": 8
		  },
		  {
			"v": 58800,
			"vw": 22.5289,
			"o": 22.5775,
			"c": 22.5775,
			"h": 22.5775,
			"l": 22.5775,
			"t": 1345032840000,
			"n": 2
		  },
		  {
			"v": 14000,
			"vw": 22.5811,
			"o": 22.5836,
			"c": 22.5821,
			"h": 22.5839,
			"l": 22.5743,
			"t": 1345032900000,
			"n": 5
		  },
		  {
			"v": 8400,
			"vw": 22.5833,
			"o": 22.5821,
			"c": 22.5857,
			"h": 22.5857,
			"l": 22.5821,
			"t": 1345032960000,
			"n": 2
		  },
		  {
			"v": 120064,
			"vw": 22.5835,
			"o": 22.5821,
			"c": 22.5893,
			"h": 22.5893,
			"l": 22.5732,
			"t": 1345033020000,
			"n": 20
		  },
		  {
			"v": 16800,
			"vw": 22.603,
			"o": 22.6014,
			"c": 22.6036,
			"h": 22.6036,
			"l": 22.6014,
			"t": 1345033080000,
			"n": 3
		  },
		  {
			"v": 25200,
			"vw": 22.6003,
			"o": 22.6,
			"c": 22.6,
			"h": 22.6025,
			"l": 22.6,
			"t": 1345033140000,
			"n": 4
		  },
		  {
			"v": 54656,
			"vw": 22.6058,
			"o": 22.605,
			"c": 22.5964,
			"h": 22.6129,
			"l": 22.5964,
			"t": 1345033260000,
			"n": 11
		  },
		  {
			"v": 38948,
			"vw": 22.6076,
			"o": 22.6229,
			"c": 22.5964,
			"h": 22.6229,
			"l": 22.5964,
			"t": 1345033320000,
			"n": 7
		  },
		  {
			"v": 10500,
			"vw": 22.6085,
			"o": 22.6068,
			"c": 22.6104,
			"h": 22.6104,
			"l": 22.6068,
			"t": 1345033380000,
			"n": 3
		  },
		  {
			"v": 38780,
			"vw": 22.6077,
			"o": 22.6089,
			"c": 22.6089,
			"h": 22.6089,
			"l": 22.6071,
			"t": 1345033500000,
			"n": 7
		  },
		  {
			"v": 19852,
			"vw": 22.6175,
			"o": 22.6154,
			"c": 22.6218,
			"h": 22.6218,
			"l": 22.6154,
			"t": 1345033560000,
			"n": 6
		  },
		  {
			"v": 19600,
			"vw": 22.6115,
			"o": 22.6161,
			"c": 22.6139,
			"h": 22.6161,
			"l": 22.6071,
			"t": 1345033620000,
			"n": 7
		  },
		  {
			"v": 18256,
			"vw": 22.6025,
			"o": 22.6071,
			"c": 22.5996,
			"h": 22.6071,
			"l": 22.5996,
			"t": 1345033680000,
			"n": 4
		  },
		  {
			"v": 42000,
			"vw": 22.6076,
			"o": 22.6061,
			"c": 22.6014,
			"h": 22.6093,
			"l": 22.6014,
			"t": 1345033740000,
			"n": 9
		  },
		  {
			"v": 54600,
			"vw": 22.5852,
			"o": 22.6,
			"c": 22.5732,
			"h": 22.6,
			"l": 22.5732,
			"t": 1345033800000,
			"n": 12
		  },
		  {
			"v": 8400,
			"vw": 22.584,
			"o": 22.5821,
			"c": 22.5904,
			"h": 22.5904,
			"l": 22.5796,
			"t": 1345033860000,
			"n": 3
		  },
		  {
			"v": 5600,
			"vw": 22.5925,
			"o": 22.5946,
			"c": 22.5904,
			"h": 22.5946,
			"l": 22.5904,
			"t": 1345033920000,
			"n": 2
		  },
		  {
			"v": 5600,
			"vw": 22.587,
			"o": 22.5857,
			"c": 22.5882,
			"h": 22.5882,
			"l": 22.5857,
			"t": 1345033980000,
			"n": 2
		  },
		  {
			"v": 38640,
			"vw": 22.5968,
			"o": 22.5889,
			"c": 22.6064,
			"h": 22.6064,
			"l": 22.5889,
			"t": 1345034040000,
			"n": 13
		  },
		  {
			"v": 59584,
			"vw": 22.6043,
			"o": 22.6057,
			"c": 22.6111,
			"h": 22.6111,
			"l": 22.6,
			"t": 1345034100000,
			"n": 15
		  },
		  {
			"v": 51800,
			"vw": 22.6155,
			"o": 22.6111,
			"c": 22.6118,
			"h": 22.6229,
			"l": 22.6111,
			"t": 1345034160000,
			"n": 12
		  },
		  {
			"v": 16800,
			"vw": 22.6105,
			"o": 22.6093,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6093,
			"t": 1345034280000,
			"n": 3
		  },
		  {
			"v": 12320,
			"vw": 22.6101,
			"o": 22.6079,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6079,
			"t": 1345034340000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.6137,
			"o": 22.6125,
			"c": 22.6143,
			"h": 22.6143,
			"l": 22.6125,
			"t": 1345034400000,
			"n": 3
		  },
		  {
			"v": 44240,
			"vw": 22.6125,
			"o": 22.6125,
			"c": 22.6107,
			"h": 22.6182,
			"l": 22.6107,
			"t": 1345034460000,
			"n": 10
		  },
		  {
			"v": 27832,
			"vw": 22.6095,
			"o": 22.6107,
			"c": 22.6189,
			"h": 22.6189,
			"l": 22.6036,
			"t": 1345034520000,
			"n": 7
		  },
		  {
			"v": 11004,
			"vw": 22.6152,
			"o": 22.6161,
			"c": 22.6143,
			"h": 22.6161,
			"l": 22.6143,
			"t": 1345034580000,
			"n": 2
		  },
		  {
			"v": 30800,
			"vw": 22.6165,
			"o": 22.6179,
			"c": 22.6179,
			"h": 22.6179,
			"l": 22.6143,
			"t": 1345034640000,
			"n": 6
		  },
		  {
			"v": 30940,
			"vw": 22.6185,
			"o": 22.6179,
			"c": 22.6179,
			"h": 22.6207,
			"l": 22.6118,
			"t": 1345034700000,
			"n": 9
		  },
		  {
			"v": 35840,
			"vw": 22.6111,
			"o": 22.6107,
			"c": 22.6082,
			"h": 22.6129,
			"l": 22.6071,
			"t": 1345034760000,
			"n": 7
		  },
		  {
			"v": 19040,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6068,
			"t": 1345034820000,
			"n": 6
		  },
		  {
			"v": 49896,
			"vw": 22.607,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6054,
			"t": 1345034880000,
			"n": 14
		  },
		  {
			"v": 135828,
			"vw": 22.612,
			"o": 22.6082,
			"c": 22.6171,
			"h": 22.6179,
			"l": 22.6071,
			"t": 1345034940000,
			"n": 31
		  },
		  {
			"v": 103600,
			"vw": 22.6154,
			"o": 22.6171,
			"c": 22.6075,
			"h": 22.6229,
			"l": 22.6075,
			"t": 1345035000000,
			"n": 26
		  },
		  {
			"v": 8400,
			"vw": 22.6069,
			"o": 22.6071,
			"c": 22.6064,
			"h": 22.6071,
			"l": 22.6064,
			"t": 1345035060000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.6075,
			"o": 22.6082,
			"c": 22.6071,
			"h": 22.6082,
			"l": 22.6071,
			"t": 1345035120000,
			"n": 2
		  },
		  {
			"v": 2800,
			"vw": 22.6004,
			"o": 22.6004,
			"c": 22.6004,
			"h": 22.6004,
			"l": 22.6004,
			"t": 1345035180000,
			"n": 1
		  },
		  {
			"v": 14000,
			"vw": 22.6001,
			"o": 22.6,
			"c": 22.6,
			"h": 22.6011,
			"l": 22.5993,
			"t": 1345035300000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.6057,
			"o": 22.6057,
			"c": 22.6057,
			"h": 22.6057,
			"l": 22.6057,
			"t": 1345035360000,
			"n": 1
		  },
		  {
			"v": 8400,
			"vw": 22.6068,
			"o": 22.6068,
			"c": 22.6064,
			"h": 22.6071,
			"l": 22.6064,
			"t": 1345035420000,
			"n": 3
		  },
		  {
			"v": 10976,
			"vw": 22.6083,
			"o": 22.6071,
			"c": 22.6068,
			"h": 22.6121,
			"l": 22.6068,
			"t": 1345035480000,
			"n": 3
		  },
		  {
			"v": 8400,
			"vw": 22.6081,
			"o": 22.6093,
			"c": 22.6071,
			"h": 22.6093,
			"l": 22.6071,
			"t": 1345035600000,
			"n": 3
		  },
		  {
			"v": 2800,
			"vw": 22.6114,
			"o": 22.6114,
			"c": 22.6114,
			"h": 22.6114,
			"l": 22.6114,
			"t": 1345035660000,
			"n": 1
		  },
		  {
			"v": 19600,
			"vw": 22.6157,
			"o": 22.6136,
			"c": 22.6161,
			"h": 22.6161,
			"l": 22.6136,
			"t": 1345035780000,
			"n": 7
		  },
		  {
			"v": 14000,
			"vw": 22.6084,
			"o": 22.6089,
			"c": 22.6082,
			"h": 22.6089,
			"l": 22.6082,
			"t": 1345035840000,
			"n": 5
		  },
		  {
			"v": 18312,
			"vw": 22.6168,
			"o": 22.6179,
			"c": 22.6171,
			"h": 22.6179,
			"l": 22.6157,
			"t": 1345035900000,
			"n": 5
		  },
		  {
			"v": 2800,
			"vw": 22.6175,
			"o": 22.6175,
			"c": 22.6175,
			"h": 22.6175,
			"l": 22.6175,
			"t": 1345035960000,
			"n": 1
		  },
		  {
			"v": 42000,
			"vw": 22.6083,
			"o": 22.6096,
			"c": 22.6079,
			"h": 22.6096,
			"l": 22.6079,
			"t": 1345036020000,
			"n": 6
		  },
		  {
			"v": 11200,
			"vw": 22.6067,
			"o": 22.6064,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6064,
			"t": 1345036260000,
			"n": 4
		  },
		  {
			"v": 5600,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6071,
			"t": 1345036320000,
			"n": 1
		  },
		  {
			"v": 28000,
			"vw": 22.6079,
			"o": 22.6071,
			"c": 22.6107,
			"h": 22.6107,
			"l": 22.6071,
			"t": 1345036500000,
			"n": 9
		  },
		  {
			"v": 61600,
			"vw": 22.6023,
			"o": 22.6057,
			"c": 22.6,
			"h": 22.6057,
			"l": 22.6,
			"t": 1345036680000,
			"n": 13
		  },
		  {
			"v": 2800,
			"vw": 22.6071,
			"o": 22.6071,
			"c": 22.6071,
			"h": 22.6071,
			"l": 22.6071,
			"t": 1345036800000,
			"n": 1
		  },
		  {
			"v": 71764,
			"vw": 22.6079,
			"o": 22.6079,
			"c": 22.6071,
			"h": 22.6125,
			"l": 22.6036,
			"t": 1345036860000,
			"n": 19
		  },
		  {
			"v": 5600,
			"vw": 22.6098,
			"o": 22.6107,
			"c": 22.6089,
			"h": 22.6107,
			"l": 22.6089,
			"t": 1345036920000,
			"n": 2
		  },
		  {
			"v": 39480,
			"vw": 22.6118,
			"o": 22.6089,
			"c": 22.6154,
			"h": 22.6154,
			"l": 22.6071,
			"t": 1345036980000,
			"n": 9
		  },
		  {
			"v": 37772,
			"vw": 22.612,
			"o": 22.6125,
			"c": 22.6107,
			"h": 22.6125,
			"l": 22.6107,
			"t": 1345037160000,
			"n": 3
		  },
		  {
			"v": 30240,
			"vw": 22.6102,
			"o": 22.6121,
			"c": 22.6089,
			"h": 22.6132,
			"l": 22.6089,
			"t": 1345037220000,
			"n": 9
		  },
		  {
			"v": 655704,
			"vw": 22.5933,
			"o": 22.6071,
			"c": 22.5764,
			"h": 22.6111,
			"l": 22.5729,
			"t": 1345037280000,
			"n": 87
		  },
		  {
			"v": 369012,
			"vw": 22.5632,
			"o": 22.5811,
			"c": 22.56,
			"h": 22.5811,
			"l": 22.5475,
			"t": 1345037340000,
			"n": 82
		  },
		  {
			"v": 4874996,
			"vw": 22.5604,
			"o": 22.5464,
			"c": 22.5936,
			"h": 22.6018,
			"l": 22.545,
			"t": 1345037400000,
			"n": 453
		  },
		  {
			"v": 1771924,
			"vw": 22.5983,
			"o": 22.5936,
			"c": 22.6132,
			"h": 22.6132,
			"l": 22.5868,
			"t": 1345037460000,
			"n": 410
		  },
		  {
			"v": 2112236,
			"vw": 22.6271,
			"o": 22.6144,
			"c": 22.6386,
			"h": 22.6429,
			"l": 22.6076,
			"t": 1345037520000,
			"n": 451
		  },
		  {
			"v": 1778000,
			"vw": 22.6254,
			"o": 22.64,
			"c": 22.6176,
			"h": 22.6418,
			"l": 22.6139,
			"t": 1345037580000,
			"n": 442
		  },
		  {
			"v": 2147348,
			"vw": 22.5886,
			"o": 22.6175,
			"c": 22.5768,
			"h": 22.6181,
			"l": 22.5679,
			"t": 1345037640000,
			"n": 487
		  },
		  {
			"v": 2555224,
			"vw": 22.554,
			"o": 22.5791,
			"c": 22.5386,
			"h": 22.5814,
			"l": 22.5282,
			"t": 1345037700000,
			"n": 614
		  },
		  {
			"v": 1147440,
			"vw": 22.5488,
			"o": 22.5432,
			"c": 22.5468,
			"h": 22.5618,
			"l": 22.5383,
			"t": 1345037760000,
			"n": 292
		  },
		  {
			"v": 2351860,
			"vw": 22.5378,
			"o": 22.5464,
			"c": 22.5277,
			"h": 22.5514,
			"l": 22.5203,
			"t": 1345037820000,
			"n": 518
		  },
		  {
			"v": 1657516,
			"vw": 22.542,
			"o": 22.5304,
			"c": 22.5486,
			"h": 22.55,
			"l": 22.5264,
			"t": 1345037880000,
			"n": 318
		  },
		  {
			"v": 1624280,
			"vw": 22.5629,
			"o": 22.5493,
			"c": 22.56,
			"h": 22.58,
			"l": 22.5466,
			"t": 1345037940000,
			"n": 360
		  },
		  {
			"v": 1611456,
			"vw": 22.5765,
			"o": 22.5625,
			"c": 22.5704,
			"h": 22.5839,
			"l": 22.5589,
			"t": 1345038000000,
			"n": 366
		  },
		  {
			"v": 1192492,
			"vw": 22.566,
			"o": 22.5693,
			"c": 22.5571,
			"h": 22.5778,
			"l": 22.5571,
			"t": 1345038060000,
			"n": 264
		  },
		  {
			"v": 1358840,
			"vw": 22.5467,
			"o": 22.5579,
			"c": 22.5525,
			"h": 22.5614,
			"l": 22.5357,
			"t": 1345038120000,
			"n": 313
		  },
		  {
			"v": 1554224,
			"vw": 22.5519,
			"o": 22.55,
			"c": 22.5504,
			"h": 22.5607,
			"l": 22.5439,
			"t": 1345038180000,
			"n": 367
		  },
		  {
			"v": 1115128,
			"vw": 22.5667,
			"o": 22.5479,
			"c": 22.5786,
			"h": 22.5786,
			"l": 22.5479,
			"t": 1345038240000,
			"n": 233
		  },
		  {
			"v": 1529360,
			"vw": 22.5948,
			"o": 22.5786,
			"c": 22.6164,
			"h": 22.6179,
			"l": 22.5761,
			"t": 1345038300000,
			"n": 309
		  },
		  {
			"v": 1601740,
			"vw": 22.599,
			"o": 22.6114,
			"c": 22.6014,
			"h": 22.6114,
			"l": 22.5829,
			"t": 1345038360000,
			"n": 318
		  },
		  {
			"v": 1599080,
			"vw": 22.6053,
			"o": 22.6014,
			"c": 22.6075,
			"h": 22.6096,
			"l": 22.5993,
			"t": 1345038420000,
			"n": 317
		  },
		  {
			"v": 1943312,
			"vw": 22.6128,
			"o": 22.6071,
			"c": 22.6286,
			"h": 22.6286,
			"l": 22.6018,
			"t": 1345038480000,
			"n": 340
		  },
		  {
			"v": 1682408,
			"vw": 22.6259,
			"o": 22.6268,
			"c": 22.6147,
			"h": 22.6332,
			"l": 22.6107,
			"t": 1345038540000,
			"n": 346
		  },
		  {
			"v": 798644,
			"vw": 22.6204,
			"o": 22.616,
			"c": 22.6282,
			"h": 22.6293,
			"l": 22.6107,
			"t": 1345038600000,
			"n": 196
		  },
		  {
			"v": 1158444,
			"vw": 22.6235,
			"o": 22.6254,
			"c": 22.6143,
			"h": 22.6311,
			"l": 22.6143,
			"t": 1345038660000,
			"n": 267
		  },
		  {
			"v": 1083740,
			"vw": 22.61,
			"o": 22.6143,
			"c": 22.6076,
			"h": 22.6193,
			"l": 22.6061,
			"t": 1345038720000,
			"n": 238
		  },
		  {
			"v": 1268988,
			"vw": 22.6081,
			"o": 22.6104,
			"c": 22.6128,
			"h": 22.6132,
			"l": 22.6043,
			"t": 1345038780000,
			"n": 328
		  },
		  {
			"v": 1015952,
			"vw": 22.6179,
			"o": 22.6116,
			"c": 22.6189,
			"h": 22.6239,
			"l": 22.61,
			"t": 1345038840000,
			"n": 227
		  },
		  {
			"v": 1595076,
			"vw": 22.6306,
			"o": 22.6221,
			"c": 22.6364,
			"h": 22.6368,
			"l": 22.619,
			"t": 1345038900000,
			"n": 400
		  },
		  {
			"v": 1003156,
			"vw": 22.6283,
			"o": 22.6364,
			"c": 22.6243,
			"h": 22.6368,
			"l": 22.6186,
			"t": 1345038960000,
			"n": 246
		  },
		  {
			"v": 1379364,
			"vw": 22.6092,
			"o": 22.6243,
			"c": 22.6096,
			"h": 22.625,
			"l": 22.6029,
			"t": 1345039020000,
			"n": 320
		  },
		  {
			"v": 781928,
			"vw": 22.6118,
			"o": 22.6089,
			"c": 22.6192,
			"h": 22.6239,
			"l": 22.6054,
			"t": 1345039080000,
			"n": 169
		  },
		  {
			"v": 1351700,
			"vw": 22.6077,
			"o": 22.6211,
			"c": 22.6068,
			"h": 22.6239,
			"l": 22.6011,
			"t": 1345039140000,
			"n": 315
		  },
		  {
			"v": 748664,
			"vw": 22.6071,
			"o": 22.6121,
			"c": 22.6068,
			"h": 22.6146,
			"l": 22.6011,
			"t": 1345039200000,
			"n": 175
		  },
		  {
			"v": 860440,
			"vw": 22.6051,
			"o": 22.6061,
			"c": 22.5964,
			"h": 22.6175,
			"l": 22.5964,
			"t": 1345039260000,
			"n": 207
		  },
		  {
			"v": 740516,
			"vw": 22.5957,
			"o": 22.5961,
			"c": 22.5929,
			"h": 22.6046,
			"l": 22.59,
			"t": 1345039320000,
			"n": 204
		  },
		  {
			"v": 1075872,
			"vw": 22.5893,
			"o": 22.5929,
			"c": 22.5961,
			"h": 22.5975,
			"l": 22.5832,
			"t": 1345039380000,
			"n": 265
		  },
		  {
			"v": 500668,
			"vw": 22.6003,
			"o": 22.5961,
			"c": 22.6001,
			"h": 22.6054,
			"l": 22.5882,
			"t": 1345039440000,
			"n": 125
		  },
		  {
			"v": 1031660,
			"vw": 22.5874,
			"o": 22.6032,
			"c": 22.5764,
			"h": 22.605,
			"l": 22.5764,
			"t": 1345039500000,
			"n": 231
		  },
		  {
			"v": 1668940,
			"vw": 22.5702,
			"o": 22.58,
			"c": 22.5611,
			"h": 22.5818,
			"l": 22.5589,
			"t": 1345039560000,
			"n": 374
		  },
		  {
			"v": 1234268,
			"vw": 22.5696,
			"o": 22.5629,
			"c": 22.5654,
			"h": 22.5829,
			"l": 22.5604,
			"t": 1345039620000,
			"n": 251
		  },
		  {
			"v": 735000,
			"vw": 22.567,
			"o": 22.5692,
			"c": 22.5654,
			"h": 22.5707,
			"l": 22.5632,
			"t": 1345039680000,
			"n": 172
		  },
		  {
			"v": 544180,
			"vw": 22.5702,
			"o": 22.568,
			"c": 22.57,
			"h": 22.5736,
			"l": 22.5639,
			"t": 1345039740000,
			"n": 132
		  },
		  {
			"v": 865060,
			"vw": 22.5783,
			"o": 22.5704,
			"c": 22.5807,
			"h": 22.585,
			"l": 22.5704,
			"t": 1345039800000,
			"n": 231
		  },
		  {
			"v": 693700,
			"vw": 22.585,
			"o": 22.5811,
			"c": 22.5882,
			"h": 22.5964,
			"l": 22.5732,
			"t": 1345039860000,
			"n": 194
		  },
		  {
			"v": 495964,
			"vw": 22.5854,
			"o": 22.5864,
			"c": 22.5814,
			"h": 22.5925,
			"l": 22.5789,
			"t": 1345039920000,
			"n": 127
		  },
		  {
			"v": 475104,
			"vw": 22.5808,
			"o": 22.5821,
			"c": 22.5779,
			"h": 22.5882,
			"l": 22.5771,
			"t": 1345039980000,
			"n": 137
		  },
		  {
			"v": 567336,
			"vw": 22.5835,
			"o": 22.5782,
			"c": 22.5918,
			"h": 22.5968,
			"l": 22.5768,
			"t": 1345040040000,
			"n": 162
		  },
		  {
			"v": 435400,
			"vw": 22.5925,
			"o": 22.5911,
			"c": 22.5886,
			"h": 22.5982,
			"l": 22.5846,
			"t": 1345040100000,
			"n": 96
		  },
		  {
			"v": 333480,
			"vw": 22.584,
			"o": 22.5839,
			"c": 22.5832,
			"h": 22.5889,
			"l": 22.5807,
			"t": 1345040160000,
			"n": 92
		  },
		  {
			"v": 1021048,
			"vw": 22.5699,
			"o": 22.5811,
			"c": 22.5657,
			"h": 22.5814,
			"l": 22.5625,
			"t": 1345040220000,
			"n": 224
		  },
		  {
			"v": 723660,
			"vw": 22.5634,
			"o": 22.5659,
			"c": 22.5575,
			"h": 22.5689,
			"l": 22.5557,
			"t": 1345040280000,
			"n": 146
		  },
		  {
			"v": 601580,
			"vw": 22.5546,
			"o": 22.5579,
			"c": 22.5582,
			"h": 22.5621,
			"l": 22.5486,
			"t": 1345040340000,
			"n": 121
		  },
		  {
			"v": 453628,
			"vw": 22.5569,
			"o": 22.56,
			"c": 22.5582,
			"h": 22.5625,
			"l": 22.5536,
			"t": 1345040400000,
			"n": 110
		  },
		  {
			"v": 427980,
			"vw": 22.5522,
			"o": 22.5546,
			"c": 22.5507,
			"h": 22.5568,
			"l": 22.5482,
			"t": 1345040460000,
			"n": 96
		  },
		  {
			"v": 682388,
			"vw": 22.5555,
			"o": 22.5514,
			"c": 22.5514,
			"h": 22.5586,
			"l": 22.5511,
			"t": 1345040520000,
			"n": 162
		  },
		  {
			"v": 532364,
			"vw": 22.549,
			"o": 22.5514,
			"c": 22.5457,
			"h": 22.5546,
			"l": 22.5451,
			"t": 1345040580000,
			"n": 125
		  },
		  {
			"v": 526792,
			"vw": 22.5557,
			"o": 22.5474,
			"c": 22.5618,
			"h": 22.5636,
			"l": 22.5464,
			"t": 1345040640000,
			"n": 117
		  },
		  {
			"v": 703220,
			"vw": 22.5666,
			"o": 22.5611,
			"c": 22.5693,
			"h": 22.5743,
			"l": 22.5575,
			"t": 1345040700000,
			"n": 153
		  },
		  {
			"v": 278796,
			"vw": 22.5693,
			"o": 22.5711,
			"c": 22.5718,
			"h": 22.5718,
			"l": 22.5643,
			"t": 1345040760000,
			"n": 61
		  },
		  {
			"v": 341908,
			"vw": 22.5706,
			"o": 22.5707,
			"c": 22.5682,
			"h": 22.5735,
			"l": 22.5682,
			"t": 1345040820000,
			"n": 77
		  },
		  {
			"v": 848148,
			"vw": 22.5831,
			"o": 22.5682,
			"c": 22.5809,
			"h": 22.5893,
			"l": 22.5682,
			"t": 1345040880000,
			"n": 184
		  },
		  {
			"v": 476000,
			"vw": 22.5829,
			"o": 22.578,
			"c": 22.5843,
			"h": 22.5882,
			"l": 22.5743,
			"t": 1345040940000,
			"n": 92
		  },
		  {
			"v": 695352,
			"vw": 22.5884,
			"o": 22.5832,
			"c": 22.5871,
			"h": 22.5929,
			"l": 22.5829,
			"t": 1345041000000,
			"n": 133
		  },
		  {
			"v": 129080,
			"vw": 22.5853,
			"o": 22.5871,
			"c": 22.5845,
			"h": 22.5871,
			"l": 22.5825,
			"t": 1345041060000,
			"n": 28
		  },
		  {
			"v": 475776,
			"vw": 22.575,
			"o": 22.5839,
			"c": 22.5718,
			"h": 22.5839,
			"l": 22.5664,
			"t": 1345041120000,
			"n": 91
		  },
		  {
			"v": 224364,
			"vw": 22.5747,
			"o": 22.5739,
			"c": 22.5804,
			"h": 22.5804,
			"l": 22.5714,
			"t": 1345041180000,
			"n": 63
		  },
		  {
			"v": 111160,
			"vw": 22.5782,
			"o": 22.5782,
			"c": 22.5776,
			"h": 22.5804,
			"l": 22.5744,
			"t": 1345041240000,
			"n": 25
		  },
		  {
			"v": 260372,
			"vw": 22.566,
			"o": 22.577,
			"c": 22.5607,
			"h": 22.5782,
			"l": 22.56,
			"t": 1345041300000,
			"n": 68
		  },
		  {
			"v": 587468,
			"vw": 22.5524,
			"o": 22.5629,
			"c": 22.55,
			"h": 22.565,
			"l": 22.5471,
			"t": 1345041360000,
			"n": 127
		  },
		  {
			"v": 700448,
			"vw": 22.5453,
			"o": 22.5504,
			"c": 22.5457,
			"h": 22.5564,
			"l": 22.54,
			"t": 1345041420000,
			"n": 166
		  },
		  {
			"v": 1399244,
			"vw": 22.5345,
			"o": 22.5436,
			"c": 22.5279,
			"h": 22.5436,
			"l": 22.5279,
			"t": 1345041480000,
			"n": 258
		  },
		  {
			"v": 2074184,
			"vw": 22.5264,
			"o": 22.5275,
			"c": 22.5282,
			"h": 22.5357,
			"l": 22.5196,
			"t": 1345041540000,
			"n": 438
		  },
		  {
			"v": 1115548,
			"vw": 22.5362,
			"o": 22.5268,
			"c": 22.5406,
			"h": 22.5443,
			"l": 22.5268,
			"t": 1345041600000,
			"n": 232
		  },
		  {
			"v": 746032,
			"vw": 22.5448,
			"o": 22.5432,
			"c": 22.5446,
			"h": 22.55,
			"l": 22.5379,
			"t": 1345041660000,
			"n": 108
		  },
		  {
			"v": 945168,
			"vw": 22.544,
			"o": 22.5407,
			"c": 22.5429,
			"h": 22.5514,
			"l": 22.5365,
			"t": 1345041720000,
			"n": 157
		  },
		  {
			"v": 722456,
			"vw": 22.5481,
			"o": 22.5432,
			"c": 22.5474,
			"h": 22.5532,
			"l": 22.5411,
			"t": 1345041780000,
			"n": 184
		  },
		  {
			"v": 402696,
			"vw": 22.5513,
			"o": 22.5496,
			"c": 22.5568,
			"h": 22.5568,
			"l": 22.5486,
			"t": 1345041840000,
			"n": 95
		  },
		  {
			"v": 1420496,
			"vw": 22.5641,
			"o": 22.5571,
			"c": 22.5536,
			"h": 22.575,
			"l": 22.5486,
			"t": 1345041900000,
			"n": 169
		  },
		  {
			"v": 330148,
			"vw": 22.5502,
			"o": 22.5536,
			"c": 22.5518,
			"h": 22.5561,
			"l": 22.5457,
			"t": 1345041960000,
			"n": 89
		  },
		  {
			"v": 198996,
			"vw": 22.5555,
			"o": 22.5529,
			"c": 22.5532,
			"h": 22.5596,
			"l": 22.5521,
			"t": 1345042020000,
			"n": 60
		  },
		  {
			"v": 347424,
			"vw": 22.5478,
			"o": 22.5532,
			"c": 22.5414,
			"h": 22.5589,
			"l": 22.5414,
			"t": 1345042080000,
			"n": 88
		  },
		  {
			"v": 759724,
			"vw": 22.5362,
			"o": 22.5436,
			"c": 22.5336,
			"h": 22.5436,
			"l": 22.5296,
			"t": 1345042140000,
			"n": 177
		  },
		  {
			"v": 237272,
			"vw": 22.543,
			"o": 22.5337,
			"c": 22.5461,
			"h": 22.5482,
			"l": 22.5337,
			"t": 1345042200000,
			"n": 70
		  },
		  {
			"v": 222768,
			"vw": 22.545,
			"o": 22.5461,
			"c": 22.5435,
			"h": 22.5506,
			"l": 22.5411,
			"t": 1345042260000,
			"n": 50
		  },
		  {
			"v": 288344,
			"vw": 22.5548,
			"o": 22.5479,
			"c": 22.5586,
			"h": 22.5593,
			"l": 22.5445,
			"t": 1345042320000,
			"n": 68
		  },
		  {
			"v": 383880,
			"vw": 22.5442,
			"o": 22.5586,
			"c": 22.5425,
			"h": 22.5586,
			"l": 22.5357,
			"t": 1345042380000,
			"n": 101
		  },
		  {
			"v": 238672,
			"vw": 22.5424,
			"o": 22.5421,
			"c": 22.55,
			"h": 22.55,
			"l": 22.5368,
			"t": 1345042440000,
			"n": 60
		  },
		  {
			"v": 917196,
			"vw": 22.5666,
			"o": 22.55,
			"c": 22.5679,
			"h": 22.5893,
			"l": 22.5496,
			"t": 1345042500000,
			"n": 130
		  },
		  {
			"v": 783328,
			"vw": 22.5732,
			"o": 22.5686,
			"c": 22.5704,
			"h": 22.5811,
			"l": 22.5639,
			"t": 1345042560000,
			"n": 177
		  },
		  {
			"v": 381080,
			"vw": 22.5734,
			"o": 22.5714,
			"c": 22.5696,
			"h": 22.5804,
			"l": 22.5675,
			"t": 1345042620000,
			"n": 86
		  },
		  {
			"v": 473564,
			"vw": 22.5755,
			"o": 22.5717,
			"c": 22.5736,
			"h": 22.5829,
			"l": 22.5676,
			"t": 1345042680000,
			"n": 125
		  },
		  {
			"v": 1035720,
			"vw": 22.5804,
			"o": 22.575,
			"c": 22.5829,
			"h": 22.5871,
			"l": 22.5714,
			"t": 1345042740000,
			"n": 224
		  },
		  {
			"v": 362376,
			"vw": 22.5805,
			"o": 22.5828,
			"c": 22.5807,
			"h": 22.5857,
			"l": 22.5765,
			"t": 1345042800000,
			"n": 92
		  },
		  {
			"v": 366044,
			"vw": 22.5747,
			"o": 22.5782,
			"c": 22.5736,
			"h": 22.5796,
			"l": 22.5711,
			"t": 1345042860000,
			"n": 90
		  },
		  {
			"v": 91000,
			"vw": 22.5753,
			"o": 22.5739,
			"c": 22.5732,
			"h": 22.5779,
			"l": 22.5732,
			"t": 1345042920000,
			"n": 29
		  },
		  {
			"v": 178752,
			"vw": 22.5742,
			"o": 22.5754,
			"c": 22.5764,
			"h": 22.5768,
			"l": 22.5693,
			"t": 1345042980000,
			"n": 41
		  },
		  {
			"v": 272440,
			"vw": 22.5794,
			"o": 22.5768,
			"c": 22.5796,
			"h": 22.5818,
			"l": 22.5754,
			"t": 1345043040000,
			"n": 68
		  },
		  {
			"v": 172200,
			"vw": 22.578,
			"o": 22.5796,
			"c": 22.5793,
			"h": 22.58,
			"l": 22.5757,
			"t": 1345043100000,
			"n": 51
		  },
		  {
			"v": 1513848,
			"vw": 22.5884,
			"o": 22.5796,
			"c": 22.5921,
			"h": 22.5964,
			"l": 22.5782,
			"t": 1345043160000,
			"n": 313
		  },
		  {
			"v": 1154300,
			"vw": 22.5994,
			"o": 22.5918,
			"c": 22.605,
			"h": 22.6054,
			"l": 22.5907,
			"t": 1345043220000,
			"n": 207
		  },
		  {
			"v": 1173340,
			"vw": 22.6019,
			"o": 22.6026,
			"c": 22.6014,
			"h": 22.6071,
			"l": 22.595,
			"t": 1345043280000,
			"n": 238
		  },
		  {
			"v": 751184,
			"vw": 22.609,
			"o": 22.6025,
			"c": 22.6139,
			"h": 22.6154,
			"l": 22.6025,
			"t": 1345043340000,
			"n": 182
		  },
		  {
			"v": 719180,
			"vw": 22.6124,
			"o": 22.6143,
			"c": 22.6086,
			"h": 22.6186,
			"l": 22.6068,
			"t": 1345043400000,
			"n": 179
		  },
		  {
			"v": 263816,
			"vw": 22.6084,
			"o": 22.6089,
			"c": 22.6071,
			"h": 22.6125,
			"l": 22.6068,
			"t": 1345043460000,
			"n": 82
		  },
		  {
			"v": 292264,
			"vw": 22.6095,
			"o": 22.6072,
			"c": 22.6086,
			"h": 22.6136,
			"l": 22.6071,
			"t": 1345043520000,
			"n": 71
		  },
		  {
			"v": 650188,
			"vw": 22.6092,
			"o": 22.6136,
			"c": 22.6096,
			"h": 22.6136,
			"l": 22.6061,
			"t": 1345043580000,
			"n": 107
		  },
		  {
			"v": 509516,
			"vw": 22.6122,
			"o": 22.6088,
			"c": 22.6139,
			"h": 22.615,
			"l": 22.6075,
			"t": 1345043640000,
			"n": 89
		  },
		  {
			"v": 1008112,
			"vw": 22.6181,
			"o": 22.6149,
			"c": 22.6143,
			"h": 22.6243,
			"l": 22.6104,
			"t": 1345043700000,
			"n": 222
		  },
		  {
			"v": 445648,
			"vw": 22.611,
			"o": 22.6143,
			"c": 22.6082,
			"h": 22.6171,
			"l": 22.6071,
			"t": 1345043760000,
			"n": 89
		  },
		  {
			"v": 542388,
			"vw": 22.6041,
			"o": 22.6082,
			"c": 22.6004,
			"h": 22.6105,
			"l": 22.6004,
			"t": 1345043820000,
			"n": 141
		  },
		  {
			"v": 200116,
			"vw": 22.6002,
			"o": 22.6011,
			"c": 22.6005,
			"h": 22.6039,
			"l": 22.5968,
			"t": 1345043880000,
			"n": 41
		  },
		  {
			"v": 133336,
			"vw": 22.5994,
			"o": 22.6011,
			"c": 22.5964,
			"h": 22.6018,
			"l": 22.595,
			"t": 1345043940000,
			"n": 40
		  },
		  {
			"v": 633808,
			"vw": 22.5938,
			"o": 22.5945,
			"c": 22.5939,
			"h": 22.6,
			"l": 22.5893,
			"t": 1345044000000,
			"n": 144
		  },
		  {
			"v": 542528,
			"vw": 22.5851,
			"o": 22.5918,
			"c": 22.5854,
			"h": 22.5918,
			"l": 22.5811,
			"t": 1345044060000,
			"n": 134
		  },
		  {
			"v": 405636,
			"vw": 22.582,
			"o": 22.585,
			"c": 22.5853,
			"h": 22.5861,
			"l": 22.5793,
			"t": 1345044120000,
			"n": 89
		  },
		  {
			"v": 513492,
			"vw": 22.5852,
			"o": 22.5825,
			"c": 22.5846,
			"h": 22.5939,
			"l": 22.5793,
			"t": 1345044180000,
			"n": 99
		  }
		],
		"status": "OK",
		"request_id": "2c66f9b3496c265ac37aae495e96a0c6",
		"count": 5000
	}
};

export {
	singleLineChartData,
	chartData,
	compareMultipleSeriesData,
	pophoverChartData,
	algoEditorChartData,
	metricsChartDetailData,
	accountBalanceData,
	mericsChartData,
	linearChartData,
	newsSentimentChartData,
	oneSecChartData,
	oneMinuteChartData,
	oneDayChartData
};
