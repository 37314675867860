import { Fragment, useCallback } from 'react';
import Paginator from '../Paginator/Paginator';

const Table = ({
	isPagination = true,
	tableClass,
	RowSubComponent,
	getTableProps,
	getTableBodyProps,
	headerGroups,
	prepareRow,
	page,
	canPreviousPage,
	canNextPage,
	pageOptions,
	pageCount,
	gotoPage,
	nextPage,
	previousPage,
	setPageSize,
	visibleColumns,
	state: { pageIndex, pageSize }
}) => {
	const renderRowSubComponent = useCallback(RowSubComponent, []);

	return (
		<div className={`list-wrapper ${tableClass || ''}`}>
			<table {...getTableProps()} className="list">
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr key={headerGroup.headers} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									key={column.id}
									{...column.getHeaderProps(column.getSortByToggleProps())}>
									{getHeader(column)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<Fragment key={row.id}>
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td
												key={cell}
												{...cell.getCellProps()}
												align={cell.column.alignment || 'left'}>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
								{row.isExpanded
									? renderRowSubComponent({ row, visibleColumns })
									: null}
							</Fragment>
						);
					})}
				</tbody>
			</table>
			{isPagination ? (
				<Paginator
					{...{
						gotoPage,
						previousPage,
						nextPage,
						canPreviousPage,
						canNextPage,
						pageIndex,
						pageOptions,
						pageSize,
						pageCount,
						setPageSize
					}}
				/>
			) : null}
		</div>
	);
};

const getHeader = (column) => {
	return (
		<div className={column.HeaderClass}>
			<span>
				<img
					src={`/images/${
						column.isSorted
							? column.isSortedDesc
								? 'down_arrow.png'
								: 'up_arrow.png'
							: 'right_arrow.png'
					}`}></img>{' '}
			</span>
			<span>{column.render('Header')}</span>
			{column.SubHeader && <span> {column.render('SubHeader')} </span>}
		</div>
	);
};

export default Table;
