import { useEffect, useState, useMemo } from 'react';
import { useExpanded, usePagination, useTable, useSortBy } from 'react-table';

const useList = (newData, newColumns, pageSize = 15) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		if (newData) {
			setData(newData);
		}
	});

	const tableProps = useTable(
		{
			columns: useMemo(() => newColumns, []),
			data: useMemo(() => data, [data]),
			initialState: { pageIndex: 0, pageSize }
		},
		useSortBy,
		useExpanded,
		usePagination
	);
	return tableProps;
};

export default useList;
