const OrderDatetime = ({ submitted_at, filled_at }) => {
	return (
		<div className="multi-line-cell order-datetime">
			<span>{submitted_at && new Date(submitted_at).toLocaleString()}</span>
			<span>{filled_at && new Date(filled_at).toLocaleString()}</span>
		</div>
	);
};

export default OrderDatetime;
