export default [
	{
		name: 'United States of America',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'US',
		alpha3Code: 'USA'
	},
	{
		name: 'Afghanistan',
		currencies: [
			{
				code: 'AFN',
				name: 'Afghan afghani',
				symbol: '؋'
			}
		],
		alpha2Code: 'AF',
		alpha3Code: 'AFG'
	},
	{
		name: 'Åland Islands',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'AX',
		alpha3Code: 'ALA'
	},
	{
		name: 'Albania',
		currencies: [
			{
				code: 'ALL',
				name: 'Albanian lek',
				symbol: 'L'
			}
		],
		alpha2Code: 'AL',
		alpha3Code: 'ALB'
	},
	{
		name: 'Algeria',
		currencies: [
			{
				code: 'DZD',
				name: 'Algerian dinar',
				symbol: 'د.ج'
			}
		],
		alpha2Code: 'DZ',
		alpha3Code: 'DZA'
	},
	{
		name: 'American Samoa',
		currencies: [
			{
				code: 'USD',
				name: 'United States Dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'AS',
		alpha3Code: 'ASM'
	},
	{
		name: 'Andorra',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'AD',
		alpha3Code: 'AND'
	},
	{
		name: 'Angola',
		currencies: [
			{
				code: 'AOA',
				name: 'Angolan kwanza',
				symbol: 'Kz'
			}
		],
		alpha2Code: 'AO',
		alpha3Code: 'AGO'
	},
	{
		name: 'Anguilla',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'AI',
		alpha3Code: 'AIA'
	},
	{
		name: 'Antarctica',
		alpha2Code: 'AQ',
		alpha3Code: 'ATA'
	},
	{
		name: 'Antigua and Barbuda',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'AG',
		alpha3Code: 'ATG'
	},
	{
		name: 'Argentina',
		currencies: [
			{
				code: 'ARS',
				name: 'Argentine peso',
				symbol: '$'
			}
		],
		alpha2Code: 'AR',
		alpha3Code: 'ARG'
	},
	{
		name: 'Armenia',
		currencies: [
			{
				code: 'AMD',
				name: 'Armenian dram',
				symbol: '֏'
			}
		],
		alpha2Code: 'AM',
		alpha3Code: 'ARM'
	},
	{
		name: 'Aruba',
		currencies: [
			{
				code: 'AWG',
				name: 'Aruban florin',
				symbol: 'ƒ'
			}
		],
		alpha2Code: 'AW',
		alpha3Code: 'ABW'
	},
	{
		name: 'Australia',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'AU',
		alpha3Code: 'AUS'
	},
	{
		name: 'Austria',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'AT',
		alpha3Code: 'AUT'
	},
	{
		name: 'Azerbaijan',
		currencies: [
			{
				code: 'AZN',
				name: 'Azerbaijani manat',
				symbol: '₼'
			}
		],
		alpha2Code: 'AZ',
		alpha3Code: 'AZE'
	},
	{
		name: 'Bahamas',
		currencies: [
			{
				code: 'BSD',
				name: 'Bahamian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BS',
		alpha3Code: 'BHS'
	},
	{
		name: 'Bahrain',
		currencies: [
			{
				code: 'BHD',
				name: 'Bahraini dinar',
				symbol: '.د.ب'
			}
		],
		alpha2Code: 'BH',
		alpha3Code: 'BHR'
	},
	{
		name: 'Bangladesh',
		currencies: [
			{
				code: 'BDT',
				name: 'Bangladeshi taka',
				symbol: '৳'
			}
		],
		alpha2Code: 'BD',
		alpha3Code: 'BGD'
	},
	{
		name: 'Barbados',
		currencies: [
			{
				code: 'BBD',
				name: 'Barbadian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BB',
		alpha3Code: 'BRB'
	},
	{
		name: 'Belarus',
		currencies: [
			{
				code: 'BYN',
				name: 'New Belarusian ruble',
				symbol: 'Br'
			},
			{
				code: 'BYR',
				name: 'Old Belarusian ruble',
				symbol: 'Br'
			}
		],
		alpha2Code: 'BY',
		alpha3Code: 'BLR'
	},
	{
		name: 'Belgium',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'BE',
		alpha3Code: 'BEL'
	},
	{
		name: 'Belize',
		currencies: [
			{
				code: 'BZD',
				name: 'Belize dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BZ',
		alpha3Code: 'BLZ'
	},
	{
		name: 'Benin',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'BJ',
		alpha3Code: 'BEN'
	},
	{
		name: 'Bermuda',
		currencies: [
			{
				code: 'BMD',
				name: 'Bermudian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BM',
		alpha3Code: 'BMU'
	},
	{
		name: 'Bhutan',
		currencies: [
			{
				code: 'BTN',
				name: 'Bhutanese ngultrum',
				symbol: 'Nu.'
			},
			{
				code: 'INR',
				name: 'Indian rupee',
				symbol: '₹'
			}
		],
		alpha2Code: 'BT',
		alpha3Code: 'BTN'
	},
	{
		name: 'Bolivia (Plurinational State of)',
		currencies: [
			{
				code: 'BOB',
				name: 'Bolivian boliviano',
				symbol: 'Bs.'
			}
		],
		alpha2Code: 'BO',
		alpha3Code: 'BOL'
	},
	{
		name: 'Bonaire, Sint Eustatius and Saba',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BQ',
		alpha3Code: 'BES'
	},
	{
		name: 'Bosnia and Herzegovina',
		currencies: [
			{
				code: 'BAM',
				name: 'Bosnia and Herzegovina convertible mark',
				symbol: 'KM'
			}
		],
		alpha2Code: 'BA',
		alpha3Code: 'BIH'
	},
	{
		name: 'Botswana',
		currencies: [
			{
				code: 'BWP',
				name: 'Botswana pula',
				symbol: 'P'
			}
		],
		alpha2Code: 'BW',
		alpha3Code: 'BWA'
	},
	{
		name: 'Bouvet Island',
		currencies: [
			{
				code: 'NOK',
				name: 'Norwegian krone',
				symbol: 'kr'
			}
		],
		alpha2Code: 'BV',
		alpha3Code: 'BVT'
	},
	{
		name: 'Brazil',
		currencies: [
			{
				code: 'BRL',
				name: 'Brazilian real',
				symbol: 'R$'
			}
		],
		alpha2Code: 'BR',
		alpha3Code: 'BRA'
	},
	{
		name: 'British Indian Ocean Territory',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'IO',
		alpha3Code: 'IOT'
	},
	{
		name: 'United States Minor Outlying Islands',
		currencies: [
			{
				code: 'GBP',
				name: 'British pound',
				symbol: '£'
			}
		],
		alpha2Code: 'UM',
		alpha3Code: 'UMI'
	},
	{
		name: 'Virgin Islands (British)',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'VG',
		alpha3Code: 'VGB'
	},
	{
		name: 'Virgin Islands (U.S.)',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'VI',
		alpha3Code: 'VIR'
	},
	{
		name: 'Brunei Darussalam',
		currencies: [
			{
				code: 'BND',
				name: 'Brunei dollar',
				symbol: '$'
			},
			{
				code: 'SGD',
				name: 'Singapore dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'BN',
		alpha3Code: 'BRN'
	},
	{
		name: 'Bulgaria',
		currencies: [
			{
				code: 'BGN',
				name: 'Bulgarian lev',
				symbol: 'лв'
			}
		],
		alpha2Code: 'BG',
		alpha3Code: 'BGR'
	},
	{
		name: 'Burkina Faso',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'BF',
		alpha3Code: 'BFA'
	},
	{
		name: 'Burundi',
		currencies: [
			{
				code: 'BIF',
				name: 'Burundian franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'BI',
		alpha3Code: 'BDI'
	},
	{
		name: 'Cambodia',
		currencies: [
			{
				code: 'KHR',
				name: 'Cambodian riel',
				symbol: '៛'
			},
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'KH',
		alpha3Code: 'KHM'
	},
	{
		name: 'Cameroon',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CM',
		alpha3Code: 'CMR'
	},
	{
		name: 'Canada',
		currencies: [
			{
				code: 'CAD',
				name: 'Canadian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'CA',
		alpha3Code: 'CAN'
	},
	{
		name: 'Cabo Verde',
		currencies: [
			{
				code: 'CVE',
				name: 'Cape Verdean escudo',
				symbol: 'Esc'
			}
		],
		alpha2Code: 'CV',
		alpha3Code: 'CPV'
	},
	{
		name: 'Cayman Islands',
		currencies: [
			{
				code: 'KYD',
				name: 'Cayman Islands dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'KY',
		alpha3Code: 'CYM'
	},
	{
		name: 'Central African Republic',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CF',
		alpha3Code: 'CAF'
	},
	{
		name: 'Chad',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'TD',
		alpha3Code: 'TCD'
	},
	{
		name: 'Chile',
		currencies: [
			{
				code: 'CLP',
				name: 'Chilean peso',
				symbol: '$'
			}
		],
		alpha2Code: 'CL',
		alpha3Code: 'CHL'
	},
	{
		name: 'China',
		currencies: [
			{
				code: 'CNY',
				name: 'Chinese yuan',
				symbol: '¥'
			}
		],
		alpha2Code: 'CN',
		alpha3Code: 'CHN'
	},
	{
		name: 'Christmas Island',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'CX',
		alpha3Code: 'CXR'
	},
	{
		name: 'Cocos (Keeling) Islands',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'CC',
		alpha3Code: 'CCK'
	},
	{
		name: 'Colombia',
		currencies: [
			{
				code: 'COP',
				name: 'Colombian peso',
				symbol: '$'
			}
		],
		alpha2Code: 'CO',
		alpha3Code: 'COL'
	},
	{
		name: 'Comoros',
		currencies: [
			{
				code: 'KMF',
				name: 'Comorian franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'KM',
		alpha3Code: 'COM'
	},
	{
		name: 'Congo',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CG',
		alpha3Code: 'COG'
	},
	{
		name: 'Congo (Democratic Republic of the)',
		currencies: [
			{
				code: 'CDF',
				name: 'Congolese franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CD',
		alpha3Code: 'COD'
	},
	{
		name: 'Cook Islands',
		currencies: [
			{
				code: 'NZD',
				name: 'New Zealand dollar',
				symbol: '$'
			},
			{
				code: 'CKD',
				name: 'Cook Islands dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'CK',
		alpha3Code: 'COK'
	},
	{
		name: 'Costa Rica',
		currencies: [
			{
				code: 'CRC',
				name: 'Costa Rican colón',
				symbol: '₡'
			}
		],
		alpha2Code: 'CR',
		alpha3Code: 'CRI'
	},
	{
		name: 'Croatia',
		currencies: [
			{
				code: 'HRK',
				name: 'Croatian kuna',
				symbol: 'kn'
			}
		],
		alpha2Code: 'HR',
		alpha3Code: 'HRV'
	},
	{
		name: 'Cuba',
		currencies: [
			{
				code: 'CUC',
				name: 'Cuban convertible peso',
				symbol: '$'
			},
			{
				code: 'CUP',
				name: 'Cuban peso',
				symbol: '$'
			}
		],
		alpha2Code: 'CU',
		alpha3Code: 'CUB'
	},
	{
		name: 'Curaçao',
		currencies: [
			{
				code: 'ANG',
				name: 'Netherlands Antillean guilder',
				symbol: 'ƒ'
			}
		],
		alpha2Code: 'CW',
		alpha3Code: 'CUW'
	},
	{
		name: 'Cyprus',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'CY',
		alpha3Code: 'CYP'
	},
	{
		name: 'Czech Republic',
		currencies: [
			{
				code: 'CZK',
				name: 'Czech koruna',
				symbol: 'Kč'
			}
		],
		alpha2Code: 'CZ',
		alpha3Code: 'CZE'
	},
	{
		name: 'Denmark',
		currencies: [
			{
				code: 'DKK',
				name: 'Danish krone',
				symbol: 'kr'
			}
		],
		alpha2Code: 'DK',
		alpha3Code: 'DNK'
	},
	{
		name: 'Djibouti',
		currencies: [
			{
				code: 'DJF',
				name: 'Djiboutian franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'DJ',
		alpha3Code: 'DJI'
	},
	{
		name: 'Dominica',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'DM',
		alpha3Code: 'DMA'
	},
	{
		name: 'Dominican Republic',
		currencies: [
			{
				code: 'DOP',
				name: 'Dominican peso',
				symbol: '$'
			}
		],
		alpha2Code: 'DO',
		alpha3Code: 'DOM'
	},
	{
		name: 'Ecuador',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'EC',
		alpha3Code: 'ECU'
	},
	{
		name: 'Egypt',
		currencies: [
			{
				code: 'EGP',
				name: 'Egyptian pound',
				symbol: '£'
			}
		],
		alpha2Code: 'EG',
		alpha3Code: 'EGY'
	},
	{
		name: 'El Salvador',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'SV',
		alpha3Code: 'SLV'
	},
	{
		name: 'Equatorial Guinea',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'GQ',
		alpha3Code: 'GNQ'
	},
	{
		name: 'Eritrea',
		currencies: [
			{
				code: 'ERN',
				name: 'Eritrean nakfa',
				symbol: 'Nfk'
			}
		],
		alpha2Code: 'ER',
		alpha3Code: 'ERI'
	},
	{
		name: 'Estonia',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'EE',
		alpha3Code: 'EST'
	},
	{
		name: 'Ethiopia',
		currencies: [
			{
				code: 'ETB',
				name: 'Ethiopian birr',
				symbol: 'Br'
			}
		],
		alpha2Code: 'ET',
		alpha3Code: 'ETH'
	},
	{
		name: 'Falkland Islands (Malvinas)',
		currencies: [
			{
				code: 'FKP',
				name: 'Falkland Islands pound',
				symbol: '£'
			}
		],
		alpha2Code: 'FK',
		alpha3Code: 'FLK'
	},
	{
		name: 'Faroe Islands',
		currencies: [
			{
				code: 'DKK',
				name: 'Danish krone',
				symbol: 'kr'
			},
			{
				code: 'FOK',
				name: 'Faroese króna',
				symbol: 'kr'
			}
		],
		alpha2Code: 'FO',
		alpha3Code: 'FRO'
	},
	{
		name: 'Fiji',
		currencies: [
			{
				code: 'FJD',
				name: 'Fijian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'FJ',
		alpha3Code: 'FJI'
	},
	{
		name: 'Finland',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'FI',
		alpha3Code: 'FIN'
	},
	{
		name: 'France',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'FR',
		alpha3Code: 'FRA'
	},
	{
		name: 'French Guiana',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'GF',
		alpha3Code: 'GUF'
	},
	{
		name: 'French Polynesia',
		currencies: [
			{
				code: 'XPF',
				name: 'CFP franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'PF',
		alpha3Code: 'PYF'
	},
	{
		name: 'French Southern Territories',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'TF',
		alpha3Code: 'ATF'
	},
	{
		name: 'Gabon',
		currencies: [
			{
				code: 'XAF',
				name: 'Central African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'GA',
		alpha3Code: 'GAB'
	},
	{
		name: 'Gambia',
		currencies: [
			{
				code: 'GMD',
				name: 'Gambian dalasi',
				symbol: 'D'
			}
		],
		alpha2Code: 'GM',
		alpha3Code: 'GMB'
	},
	{
		name: 'Georgia',
		currencies: [
			{
				code: 'GEL',
				name: 'Georgian Lari',
				symbol: 'ლ'
			}
		],
		alpha2Code: 'GE',
		alpha3Code: 'GEO'
	},
	{
		name: 'Germany',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'DE',
		alpha3Code: 'DEU'
	},
	{
		name: 'Ghana',
		currencies: [
			{
				code: 'GHS',
				name: 'Ghanaian cedi',
				symbol: '₵'
			}
		],
		alpha2Code: 'GH',
		alpha3Code: 'GHA'
	},
	{
		name: 'Gibraltar',
		currencies: [
			{
				code: 'GIP',
				name: 'Gibraltar pound',
				symbol: '£'
			}
		],
		alpha2Code: 'GI',
		alpha3Code: 'GIB'
	},
	{
		name: 'Greece',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'GR',
		alpha3Code: 'GRC'
	},
	{
		name: 'Greenland',
		currencies: [
			{
				code: 'DKK',
				name: 'Danish krone',
				symbol: 'kr'
			}
		],
		alpha2Code: 'GL',
		alpha3Code: 'GRL'
	},
	{
		name: 'Grenada',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'GD',
		alpha3Code: 'GRD'
	},
	{
		name: 'Guadeloupe',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'GP',
		alpha3Code: 'GLP'
	},
	{
		name: 'Guam',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'GU',
		alpha3Code: 'GUM'
	},
	{
		name: 'Guatemala',
		currencies: [
			{
				code: 'GTQ',
				name: 'Guatemalan quetzal',
				symbol: 'Q'
			}
		],
		alpha2Code: 'GT',
		alpha3Code: 'GTM'
	},
	{
		name: 'Guernsey',
		currencies: [
			{
				code: 'GBP',
				name: 'British pound',
				symbol: '£'
			},
			{
				code: 'GGP',
				name: 'Guernsey pound',
				symbol: '£'
			}
		],
		alpha2Code: 'GG',
		alpha3Code: 'GGY'
	},
	{
		name: 'Guinea',
		currencies: [
			{
				code: 'GNF',
				name: 'Guinean franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'GN',
		alpha3Code: 'GIN'
	},
	{
		name: 'Guinea-Bissau',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'GW',
		alpha3Code: 'GNB'
	},
	{
		name: 'Guyana',
		currencies: [
			{
				code: 'GYD',
				name: 'Guyanese dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'GY',
		alpha3Code: 'GUY'
	},
	{
		name: 'Haiti',
		currencies: [
			{
				code: 'HTG',
				name: 'Haitian gourde',
				symbol: 'G'
			}
		],
		alpha2Code: 'HT',
		alpha3Code: 'HTI'
	},
	{
		name: 'Heard Island and McDonald Islands',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'HM',
		alpha3Code: 'HMD'
	},
	{
		name: 'Vatican City',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'VA',
		alpha3Code: 'VAT'
	},
	{
		name: 'Honduras',
		currencies: [
			{
				code: 'HNL',
				name: 'Honduran lempira',
				symbol: 'L'
			}
		],
		alpha2Code: 'HN',
		alpha3Code: 'HND'
	},
	{
		name: 'Hungary',
		currencies: [
			{
				code: 'HUF',
				name: 'Hungarian forint',
				symbol: 'Ft'
			}
		],
		alpha2Code: 'HU',
		alpha3Code: 'HUN'
	},
	{
		name: 'Hong Kong',
		currencies: [
			{
				code: 'HKD',
				name: 'Hong Kong dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'HK',
		alpha3Code: 'HKG'
	},
	{
		name: 'Iceland',
		currencies: [
			{
				code: 'ISK',
				name: 'Icelandic króna',
				symbol: 'kr'
			}
		],
		alpha2Code: 'IS',
		alpha3Code: 'ISL'
	},
	{
		name: 'India',
		currencies: [
			{
				code: 'INR',
				name: 'Indian rupee',
				symbol: '₹'
			}
		],
		alpha2Code: 'IN',
		alpha3Code: 'IND'
	},
	{
		name: 'Indonesia',
		currencies: [
			{
				code: 'IDR',
				name: 'Indonesian rupiah',
				symbol: 'Rp'
			}
		],
		alpha2Code: 'ID',
		alpha3Code: 'IDN'
	},
	{
		name: 'Ivory Coast',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CI',
		alpha3Code: 'CIV'
	},
	{
		name: 'Iran (Islamic Republic of)',
		currencies: [
			{
				code: 'IRR',
				name: 'Iranian rial',
				symbol: '﷼'
			}
		],
		alpha2Code: 'IR',
		alpha3Code: 'IRN'
	},
	{
		name: 'Iraq',
		currencies: [
			{
				code: 'IQD',
				name: 'Iraqi dinar',
				symbol: 'ع.د'
			}
		],
		alpha2Code: 'IQ',
		alpha3Code: 'IRQ'
	},
	{
		name: 'Ireland',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'IE',
		alpha3Code: 'IRL'
	},
	{
		name: 'Isle of Man',
		currencies: [
			{
				code: 'GBP',
				name: 'British pound',
				symbol: '£'
			},
			{
				code: 'IMP[G]',
				name: 'Manx pound',
				symbol: '£'
			}
		],
		alpha2Code: 'IM',
		alpha3Code: 'IMN'
	},
	{
		name: 'Israel',
		currencies: [
			{
				code: 'ILS',
				name: 'Israeli new shekel',
				symbol: '₪'
			}
		],
		alpha2Code: 'IL',
		alpha3Code: 'ISR'
	},
	{
		name: 'Italy',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'IT',
		alpha3Code: 'ITA'
	},
	{
		name: 'Jamaica',
		currencies: [
			{
				code: 'JMD',
				name: 'Jamaican dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'JM',
		alpha3Code: 'JAM'
	},
	{
		name: 'Japan',
		currencies: [
			{
				code: 'JPY',
				name: 'Japanese yen',
				symbol: '¥'
			}
		],
		alpha2Code: 'JP',
		alpha3Code: 'JPN'
	},
	{
		name: 'Jersey',
		currencies: [
			{
				code: 'GBP',
				name: 'British pound',
				symbol: '£'
			},
			{
				code: 'JEP[G]',
				name: 'Jersey pound',
				symbol: '£'
			}
		],
		alpha2Code: 'JE',
		alpha3Code: 'JEY'
	},
	{
		name: 'Jordan',
		currencies: [
			{
				code: 'JOD',
				name: 'Jordanian dinar',
				symbol: 'د.ا'
			}
		],
		alpha2Code: 'JO',
		alpha3Code: 'JOR'
	},
	{
		name: 'Kazakhstan',
		currencies: [
			{
				code: 'KZT',
				name: 'Kazakhstani tenge',
				symbol: '₸'
			}
		],
		alpha2Code: 'KZ',
		alpha3Code: 'KAZ'
	},
	{
		name: 'Kenya',
		currencies: [
			{
				code: 'KES',
				name: 'Kenyan shilling',
				symbol: 'Sh'
			}
		],
		alpha2Code: 'KE',
		alpha3Code: 'KEN'
	},
	{
		name: 'Kiribati',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			},
			{
				code: 'KID',
				name: 'Kiribati dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'KI',
		alpha3Code: 'KIR'
	},
	{
		name: 'Kuwait',
		currencies: [
			{
				code: 'KWD',
				name: 'Kuwaiti dinar',
				symbol: 'د.ك'
			}
		],
		alpha2Code: 'KW',
		alpha3Code: 'KWT'
	},
	{
		name: 'Kyrgyzstan',
		currencies: [
			{
				code: 'KGS',
				name: 'Kyrgyzstani som',
				symbol: 'с'
			}
		],
		alpha2Code: 'KG',
		alpha3Code: 'KGZ'
	},
	{
		name: "Lao People's Democratic Republic",
		currencies: [
			{
				code: 'LAK',
				name: 'Lao kip',
				symbol: '₭'
			}
		],
		alpha2Code: 'LA',
		alpha3Code: 'LAO'
	},
	{
		name: 'Latvia',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'LV',
		alpha3Code: 'LVA'
	},
	{
		name: 'Lebanon',
		currencies: [
			{
				code: 'LBP',
				name: 'Lebanese pound',
				symbol: 'ل.ل'
			}
		],
		alpha2Code: 'LB',
		alpha3Code: 'LBN'
	},
	{
		name: 'Lesotho',
		currencies: [
			{
				code: 'LSL',
				name: 'Lesotho loti',
				symbol: 'L'
			},
			{
				code: 'ZAR',
				name: 'South African rand',
				symbol: 'R'
			}
		],
		alpha2Code: 'LS',
		alpha3Code: 'LSO'
	},
	{
		name: 'Liberia',
		currencies: [
			{
				code: 'LRD',
				name: 'Liberian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'LR',
		alpha3Code: 'LBR'
	},
	{
		name: 'Libya',
		currencies: [
			{
				code: 'LYD',
				name: 'Libyan dinar',
				symbol: 'ل.د'
			}
		],
		alpha2Code: 'LY',
		alpha3Code: 'LBY'
	},
	{
		name: 'Liechtenstein',
		currencies: [
			{
				code: 'CHF',
				name: 'Swiss franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'LI',
		alpha3Code: 'LIE'
	},
	{
		name: 'Lithuania',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'LT',
		alpha3Code: 'LTU'
	},
	{
		name: 'Luxembourg',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'LU',
		alpha3Code: 'LUX'
	},
	{
		name: 'Macao',
		currencies: [
			{
				code: 'MOP',
				name: 'Macanese pataca',
				symbol: 'P'
			}
		],
		alpha2Code: 'MO',
		alpha3Code: 'MAC'
	},
	{
		name: 'North Macedonia',
		currencies: [
			{
				code: 'MKD',
				name: 'Macedonian denar',
				symbol: 'ден'
			}
		],
		alpha2Code: 'MK',
		alpha3Code: 'MKD'
	},
	{
		name: 'Madagascar',
		currencies: [
			{
				code: 'MGA',
				name: 'Malagasy ariary',
				symbol: 'Ar'
			}
		],
		alpha2Code: 'MG',
		alpha3Code: 'MDG'
	},
	{
		name: 'Malawi',
		currencies: [
			{
				code: 'MWK',
				name: 'Malawian kwacha',
				symbol: 'MK'
			}
		],
		alpha2Code: 'MW',
		alpha3Code: 'MWI'
	},
	{
		name: 'Malaysia',
		currencies: [
			{
				code: 'MYR',
				name: 'Malaysian ringgit',
				symbol: 'RM'
			}
		],
		alpha2Code: 'MY',
		alpha3Code: 'MYS'
	},
	{
		name: 'Maldives',
		currencies: [
			{
				code: 'MVR',
				name: 'Maldivian rufiyaa',
				symbol: '.ރ'
			}
		],
		alpha2Code: 'MV',
		alpha3Code: 'MDV'
	},
	{
		name: 'Mali',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'ML',
		alpha3Code: 'MLI'
	},
	{
		name: 'Malta',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'MT',
		alpha3Code: 'MLT'
	},
	{
		name: 'Marshall Islands',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'MH',
		alpha3Code: 'MHL'
	},
	{
		name: 'Martinique',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'MQ',
		alpha3Code: 'MTQ'
	},
	{
		name: 'Mauritania',
		currencies: [
			{
				code: 'MRO',
				name: 'Mauritanian ouguiya',
				symbol: 'UM'
			}
		],
		alpha2Code: 'MR',
		alpha3Code: 'MRT'
	},
	{
		name: 'Mauritius',
		currencies: [
			{
				code: 'MUR',
				name: 'Mauritian rupee',
				symbol: '₨'
			}
		],
		alpha2Code: 'MU',
		alpha3Code: 'MUS'
	},
	{
		name: 'Mayotte',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'YT',
		alpha3Code: 'MYT'
	},
	{
		name: 'Mexico',
		currencies: [
			{
				code: 'MXN',
				name: 'Mexican peso',
				symbol: '$'
			}
		],
		alpha2Code: 'MX',
		alpha3Code: 'MEX'
	},
	{
		name: 'Micronesia (Federated States of)',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'FM',
		alpha3Code: 'FSM'
	},
	{
		name: 'Moldova (Republic of)',
		currencies: [
			{
				code: 'MDL',
				name: 'Moldovan leu',
				symbol: 'L'
			}
		],
		alpha2Code: 'MD',
		alpha3Code: 'MDA'
	},
	{
		name: 'Monaco',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'MC',
		alpha3Code: 'MCO'
	},
	{
		name: 'Mongolia',
		currencies: [
			{
				code: 'MNT',
				name: 'Mongolian tögrög',
				symbol: '₮'
			}
		],
		alpha2Code: 'MN',
		alpha3Code: 'MNG'
	},
	{
		name: 'Montenegro',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'ME',
		alpha3Code: 'MNE'
	},
	{
		name: 'Montserrat',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'MS',
		alpha3Code: 'MSR'
	},
	{
		name: 'Morocco',
		currencies: [
			{
				code: 'MAD',
				name: 'Moroccan dirham',
				symbol: 'د.م.'
			}
		],
		alpha2Code: 'MA',
		alpha3Code: 'MAR'
	},
	{
		name: 'Mozambique',
		currencies: [
			{
				code: 'MZN',
				name: 'Mozambican metical',
				symbol: 'MT'
			}
		],
		alpha2Code: 'MZ',
		alpha3Code: 'MOZ'
	},
	{
		name: 'Myanmar',
		currencies: [
			{
				code: 'MMK',
				name: 'Burmese kyat',
				symbol: 'Ks'
			}
		],
		alpha2Code: 'MM',
		alpha3Code: 'MMR'
	},
	{
		name: 'Namibia',
		currencies: [
			{
				code: 'NAD',
				name: 'Namibian dollar',
				symbol: '$'
			},
			{
				code: 'ZAR',
				name: 'South African rand',
				symbol: 'R'
			}
		],
		alpha2Code: 'NA',
		alpha3Code: 'NAM'
	},
	{
		name: 'Nauru',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'NR',
		alpha3Code: 'NRU'
	},
	{
		name: 'Nepal',
		currencies: [
			{
				code: 'NPR',
				name: 'Nepalese rupee',
				symbol: '₨'
			}
		],
		alpha2Code: 'NP',
		alpha3Code: 'NPL'
	},
	{
		name: 'Netherlands',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'NL',
		alpha3Code: 'NLD'
	},
	{
		name: 'New Caledonia',
		currencies: [
			{
				code: 'XPF',
				name: 'CFP franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'NC',
		alpha3Code: 'NCL'
	},
	{
		name: 'New Zealand',
		currencies: [
			{
				code: 'NZD',
				name: 'New Zealand dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'NZ',
		alpha3Code: 'NZL'
	},
	{
		name: 'Nicaragua',
		currencies: [
			{
				code: 'NIO',
				name: 'Nicaraguan córdoba',
				symbol: 'C$'
			}
		],
		alpha2Code: 'NI',
		alpha3Code: 'NIC'
	},
	{
		name: 'Niger',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'NE',
		alpha3Code: 'NER'
	},
	{
		name: 'Nigeria',
		currencies: [
			{
				code: 'NGN',
				name: 'Nigerian naira',
				symbol: '₦'
			}
		],
		alpha2Code: 'NG',
		alpha3Code: 'NGA'
	},
	{
		name: 'Niue',
		currencies: [
			{
				code: 'NZD',
				name: 'New Zealand dollar',
				symbol: '$'
			},
			{
				code: 'NZD',
				name: 'Niue dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'NU',
		alpha3Code: 'NIU'
	},
	{
		name: 'Norfolk Island',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'NF',
		alpha3Code: 'NFK'
	},
	{
		name: "Korea (Democratic People's Republic of)",
		currencies: [
			{
				code: 'KPW',
				name: 'North Korean won',
				symbol: '₩'
			}
		],
		alpha2Code: 'KP',
		alpha3Code: 'PRK'
	},
	{
		name: 'Northern Mariana Islands',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'MP',
		alpha3Code: 'MNP'
	},
	{
		name: 'Norway',
		currencies: [
			{
				code: 'NOK',
				name: 'Norwegian krone',
				symbol: 'kr'
			}
		],
		alpha2Code: 'NO',
		alpha3Code: 'NOR'
	},
	{
		name: 'Oman',
		currencies: [
			{
				code: 'OMR',
				name: 'Omani rial',
				symbol: 'ر.ع.'
			}
		],
		alpha2Code: 'OM',
		alpha3Code: 'OMN'
	},
	{
		name: 'Pakistan',
		currencies: [
			{
				code: 'PKR',
				name: 'Pakistani rupee',
				symbol: '₨'
			}
		],
		alpha2Code: 'PK',
		alpha3Code: 'PAK'
	},
	{
		name: 'Palau',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'PW',
		alpha3Code: 'PLW'
	},
	{
		name: 'Palestine, State of',
		currencies: [
			{
				code: 'EGP',
				name: 'Egyptian pound',
				symbol: 'E£'
			},
			{
				code: 'ILS',
				name: 'Israeli new shekel',
				symbol: '₪'
			},
			{
				code: 'JOD',
				name: 'Jordanian dinar',
				symbol: 'د.أ'
			}
		],
		alpha2Code: 'PS',
		alpha3Code: 'PSE'
	},
	{
		name: 'Panama',
		currencies: [
			{
				code: 'PAB',
				name: 'Panamanian balboa',
				symbol: 'B/.'
			},
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'PA',
		alpha3Code: 'PAN'
	},
	{
		name: 'Papua New Guinea',
		currencies: [
			{
				code: 'PGK',
				name: 'Papua New Guinean kina',
				symbol: 'K'
			}
		],
		alpha2Code: 'PG',
		alpha3Code: 'PNG'
	},
	{
		name: 'Paraguay',
		currencies: [
			{
				code: 'PYG',
				name: 'Paraguayan guaraní',
				symbol: '₲'
			}
		],
		alpha2Code: 'PY',
		alpha3Code: 'PRY'
	},
	{
		name: 'Peru',
		currencies: [
			{
				code: 'PEN',
				name: 'Peruvian sol',
				symbol: 'S/.'
			}
		],
		alpha2Code: 'PE',
		alpha3Code: 'PER'
	},
	{
		name: 'Philippines',
		currencies: [
			{
				code: 'PHP',
				name: 'Philippine peso',
				symbol: '₱'
			}
		],
		alpha2Code: 'PH',
		alpha3Code: 'PHL'
	},
	{
		name: 'Pitcairn',
		currencies: [
			{
				code: 'NZD',
				name: 'New Zealand dollar',
				symbol: '$'
			},
			{
				code: 'PND',
				name: 'Pitcairn Islands dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'PN',
		alpha3Code: 'PCN'
	},
	{
		name: 'Poland',
		currencies: [
			{
				code: 'PLN',
				name: 'Polish złoty',
				symbol: 'zł'
			}
		],
		alpha2Code: 'PL',
		alpha3Code: 'POL'
	},
	{
		name: 'Portugal',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'PT',
		alpha3Code: 'PRT'
	},
	{
		name: 'Puerto Rico',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'PR',
		alpha3Code: 'PRI'
	},
	{
		name: 'Qatar',
		currencies: [
			{
				code: 'QAR',
				name: 'Qatari riyal',
				symbol: 'ر.ق'
			}
		],
		alpha2Code: 'QA',
		alpha3Code: 'QAT'
	},
	{
		name: 'Republic of Kosovo',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'XK',
		alpha3Code: 'UNK'
	},
	{
		name: 'Réunion',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'RE',
		alpha3Code: 'REU'
	},
	{
		name: 'Romania',
		currencies: [
			{
				code: 'RON',
				name: 'Romanian leu',
				symbol: 'lei'
			}
		],
		alpha2Code: 'RO',
		alpha3Code: 'ROU'
	},
	{
		name: 'Russian Federation',
		currencies: [
			{
				code: 'RUB',
				name: 'Russian ruble',
				symbol: '₽'
			}
		],
		alpha2Code: 'RU',
		alpha3Code: 'RUS'
	},
	{
		name: 'Rwanda',
		currencies: [
			{
				code: 'RWF',
				name: 'Rwandan franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'RW',
		alpha3Code: 'RWA'
	},
	{
		name: 'Saint Barthélemy',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'BL',
		alpha3Code: 'BLM'
	},
	{
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		currencies: [
			{
				code: 'SHP',
				name: 'Saint Helena pound',
				symbol: '£'
			}
		],
		alpha2Code: 'SH',
		alpha3Code: 'SHN'
	},
	{
		name: 'Saint Kitts and Nevis',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'KN',
		alpha3Code: 'KNA'
	},
	{
		name: 'Saint Lucia',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'LC',
		alpha3Code: 'LCA'
	},
	{
		name: 'Saint Martin (French part)',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'MF',
		alpha3Code: 'MAF'
	},
	{
		name: 'Saint Pierre and Miquelon',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'PM',
		alpha3Code: 'SPM'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		currencies: [
			{
				code: 'XCD',
				name: 'East Caribbean dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'VC',
		alpha3Code: 'VCT'
	},
	{
		name: 'Samoa',
		currencies: [
			{
				code: 'WST',
				name: 'Samoan tālā',
				symbol: 'T'
			}
		],
		alpha2Code: 'WS',
		alpha3Code: 'WSM'
	},
	{
		name: 'San Marino',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'SM',
		alpha3Code: 'SMR'
	},
	{
		name: 'Sao Tome and Principe',
		currencies: [
			{
				code: 'STD',
				name: 'São Tomé and Príncipe dobra',
				symbol: 'Db'
			}
		],
		alpha2Code: 'ST',
		alpha3Code: 'STP'
	},
	{
		name: 'Saudi Arabia',
		currencies: [
			{
				code: 'SAR',
				name: 'Saudi riyal',
				symbol: 'ر.س'
			}
		],
		alpha2Code: 'SA',
		alpha3Code: 'SAU'
	},
	{
		name: 'Senegal',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'SN',
		alpha3Code: 'SEN'
	},
	{
		name: 'Serbia',
		currencies: [
			{
				code: 'RSD',
				name: 'Serbian dinar',
				symbol: 'дин.'
			}
		],
		alpha2Code: 'RS',
		alpha3Code: 'SRB'
	},
	{
		name: 'Seychelles',
		currencies: [
			{
				code: 'SCR',
				name: 'Seychellois rupee',
				symbol: '₨'
			}
		],
		alpha2Code: 'SC',
		alpha3Code: 'SYC'
	},
	{
		name: 'Sierra Leone',
		currencies: [
			{
				code: 'SLL',
				name: 'Sierra Leonean leone',
				symbol: 'Le'
			}
		],
		alpha2Code: 'SL',
		alpha3Code: 'SLE'
	},
	{
		name: 'Singapore',
		currencies: [
			{
				code: 'SGD',
				name: 'Singapore dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'SG',
		alpha3Code: 'SGP'
	},
	{
		name: 'Sint Maarten (Dutch part)',
		currencies: [
			{
				code: 'ANG',
				name: 'Netherlands Antillean guilder',
				symbol: 'ƒ'
			}
		],
		alpha2Code: 'SX',
		alpha3Code: 'SXM'
	},
	{
		name: 'Slovakia',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'SK',
		alpha3Code: 'SVK'
	},
	{
		name: 'Slovenia',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'SI',
		alpha3Code: 'SVN'
	},
	{
		name: 'Solomon Islands',
		currencies: [
			{
				code: 'SBD',
				name: 'Solomon Islands dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'SB',
		alpha3Code: 'SLB'
	},
	{
		name: 'Somalia',
		currencies: [
			{
				code: 'SOS',
				name: 'Somali shilling',
				symbol: 'Sh'
			}
		],
		alpha2Code: 'SO',
		alpha3Code: 'SOM'
	},
	{
		name: 'South Africa',
		currencies: [
			{
				code: 'ZAR',
				name: 'South African rand',
				symbol: 'R'
			}
		],
		alpha2Code: 'ZA',
		alpha3Code: 'ZAF'
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		currencies: [
			{
				code: 'FKP',
				name: 'Falkland Islands Pound',
				symbol: '£'
			}
		],
		alpha2Code: 'GS',
		alpha3Code: 'SGS'
	},
	{
		name: 'Korea (Republic of)',
		currencies: [
			{
				code: 'KRW',
				name: 'South Korean won',
				symbol: '₩'
			}
		],
		alpha2Code: 'KR',
		alpha3Code: 'KOR'
	},
	{
		name: 'Spain',
		currencies: [
			{
				code: 'EUR',
				name: 'Euro',
				symbol: '€'
			}
		],
		alpha2Code: 'ES',
		alpha3Code: 'ESP'
	},
	{
		name: 'Sri Lanka',
		currencies: [
			{
				code: 'LKR',
				name: 'Sri Lankan rupee',
				symbol: 'Rs'
			}
		],
		alpha2Code: 'LK',
		alpha3Code: 'LKA'
	},
	{
		name: 'Sudan',
		currencies: [
			{
				code: 'SDG',
				name: 'Sudanese pound',
				symbol: 'ج.س.'
			}
		],
		alpha2Code: 'SD',
		alpha3Code: 'SDN'
	},
	{
		name: 'South Sudan',
		currencies: [
			{
				code: 'SSP',
				name: 'South Sudanese pound',
				symbol: '£'
			}
		],
		alpha2Code: 'SS',
		alpha3Code: 'SSD'
	},
	{
		name: 'Suriname',
		currencies: [
			{
				code: 'SRD',
				name: 'Surinamese dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'SR',
		alpha3Code: 'SUR'
	},
	{
		name: 'Svalbard and Jan Mayen',
		currencies: [
			{
				code: 'NOK',
				name: 'Norwegian krone',
				symbol: 'kr'
			}
		],
		alpha2Code: 'SJ',
		alpha3Code: 'SJM'
	},
	{
		name: 'Swaziland',
		currencies: [
			{
				code: 'SZL',
				name: 'Swazi lilangeni',
				symbol: 'L'
			}
		],
		alpha2Code: 'SZ',
		alpha3Code: 'SWZ'
	},
	{
		name: 'Sweden',
		currencies: [
			{
				code: 'SEK',
				name: 'Swedish krona',
				symbol: 'kr'
			}
		],
		alpha2Code: 'SE',
		alpha3Code: 'SWE'
	},
	{
		name: 'Switzerland',
		currencies: [
			{
				code: 'CHF',
				name: 'Swiss franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'CH',
		alpha3Code: 'CHE'
	},
	{
		name: 'Syrian Arab Republic',
		currencies: [
			{
				code: 'SYP',
				name: 'Syrian pound',
				symbol: '£'
			}
		],
		alpha2Code: 'SY',
		alpha3Code: 'SYR'
	},
	{
		name: 'Taiwan',
		currencies: [
			{
				code: 'TWD',
				name: 'New Taiwan dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TW',
		alpha3Code: 'TWN'
	},
	{
		name: 'Tajikistan',
		currencies: [
			{
				code: 'TJS',
				name: 'Tajikistani somoni',
				symbol: 'ЅМ'
			}
		],
		alpha2Code: 'TJ',
		alpha3Code: 'TJK'
	},
	{
		name: 'Tanzania, United Republic of',
		currencies: [
			{
				code: 'TZS',
				name: 'Tanzanian shilling',
				symbol: 'Sh'
			}
		],
		alpha2Code: 'TZ',
		alpha3Code: 'TZA'
	},
	{
		name: 'Thailand',
		currencies: [
			{
				code: 'THB',
				name: 'Thai baht',
				symbol: '฿'
			}
		],
		alpha2Code: 'TH',
		alpha3Code: 'THA'
	},
	{
		name: 'Timor-Leste',
		currencies: [
			{
				code: 'USD',
				name: 'United States Dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TL',
		alpha3Code: 'TLS'
	},
	{
		name: 'Togo',
		currencies: [
			{
				code: 'XOF',
				name: 'West African CFA franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'TG',
		alpha3Code: 'TGO'
	},
	{
		name: 'Tokelau',
		currencies: [
			{
				code: 'NZD',
				name: 'New Zealand dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TK',
		alpha3Code: 'TKL'
	},
	{
		name: 'Tonga',
		currencies: [
			{
				code: 'TOP',
				name: 'Tongan paʻanga',
				symbol: 'T$'
			}
		],
		alpha2Code: 'TO',
		alpha3Code: 'TON'
	},
	{
		name: 'Trinidad and Tobago',
		currencies: [
			{
				code: 'TTD',
				name: 'Trinidad and Tobago dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TT',
		alpha3Code: 'TTO'
	},
	{
		name: 'Tunisia',
		currencies: [
			{
				code: 'TND',
				name: 'Tunisian dinar',
				symbol: 'د.ت'
			}
		],
		alpha2Code: 'TN',
		alpha3Code: 'TUN'
	},
	{
		name: 'Turkey',
		currencies: [
			{
				code: 'TRY',
				name: 'Turkish lira',
				symbol: '₺'
			}
		],
		alpha2Code: 'TR',
		alpha3Code: 'TUR'
	},
	{
		name: 'Turkmenistan',
		currencies: [
			{
				code: 'TMT',
				name: 'Turkmenistan manat',
				symbol: 'm'
			}
		],
		alpha2Code: 'TM',
		alpha3Code: 'TKM'
	},
	{
		name: 'Turks and Caicos Islands',
		currencies: [
			{
				code: 'USD',
				name: 'United States dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TC',
		alpha3Code: 'TCA'
	},
	{
		name: 'Tuvalu',
		currencies: [
			{
				code: 'AUD',
				name: 'Australian dollar',
				symbol: '$'
			},
			{
				code: 'TVD[G]',
				name: 'Tuvaluan dollar',
				symbol: '$'
			}
		],
		alpha2Code: 'TV',
		alpha3Code: 'TUV'
	},
	{
		name: 'Uganda',
		currencies: [
			{
				code: 'UGX',
				name: 'Ugandan shilling',
				symbol: 'Sh'
			}
		],
		alpha2Code: 'UG',
		alpha3Code: 'UGA'
	},
	{
		name: 'Ukraine',
		currencies: [
			{
				code: 'UAH',
				name: 'Ukrainian hryvnia',
				symbol: '₴'
			}
		],
		alpha2Code: 'UA',
		alpha3Code: 'UKR'
	},
	{
		name: 'United Arab Emirates',
		currencies: [
			{
				code: 'AED',
				name: 'United Arab Emirates dirham',
				symbol: 'د.إ'
			}
		],
		alpha2Code: 'AE',
		alpha3Code: 'ARE'
	},
	{
		name: 'United Kingdom of Great Britain and Northern Ireland',
		currencies: [
			{
				code: 'GBP',
				name: 'British pound',
				symbol: '£'
			}
		],
		alpha2Code: 'GB',
		alpha3Code: 'GBR'
	},
	{
		name: 'Uruguay',
		currencies: [
			{
				code: 'UYU',
				name: 'Uruguayan peso',
				symbol: '$'
			}
		],
		alpha2Code: 'UY',
		alpha3Code: 'URY'
	},
	{
		name: 'Uzbekistan',
		currencies: [
			{
				code: 'UZS',
				name: "Uzbekistani so'm",
				symbol: "so'm"
			}
		],
		alpha2Code: 'UZ',
		alpha3Code: 'UZB'
	},
	{
		name: 'Vanuatu',
		currencies: [
			{
				code: 'VUV',
				name: 'Vanuatu vatu',
				symbol: 'Vt'
			}
		],
		alpha2Code: 'VU',
		alpha3Code: 'VUT'
	},
	{
		name: 'Venezuela (Bolivarian Republic of)',
		currencies: [
			{
				code: 'VEF',
				name: 'Venezuelan bolívar',
				symbol: 'Bs S'
			}
		],
		alpha2Code: 'VE',
		alpha3Code: 'VEN'
	},
	{
		name: 'Vietnam',
		currencies: [
			{
				code: 'VND',
				name: 'Vietnamese đồng',
				symbol: '₫'
			}
		],
		alpha2Code: 'VN',
		alpha3Code: 'VNM'
	},
	{
		name: 'Wallis and Futuna',
		currencies: [
			{
				code: 'XPF',
				name: 'CFP franc',
				symbol: 'Fr'
			}
		],
		alpha2Code: 'WF',
		alpha3Code: 'WLF'
	},
	{
		name: 'Western Sahara',
		currencies: [
			{
				code: 'MAD',
				name: 'Moroccan dirham',
				symbol: 'د.م.'
			},
			{
				code: 'DZD',
				name: 'Algerian dinar',
				symbol: 'د.ج'
			}
		],
		alpha2Code: 'EH',
		alpha3Code: 'ESH'
	},
	{
		name: 'Yemen',
		currencies: [
			{
				code: 'YER',
				name: 'Yemeni rial',
				symbol: '﷼'
			}
		],
		alpha2Code: 'YE',
		alpha3Code: 'YEM'
	},
	{
		name: 'Zambia',
		currencies: [
			{
				code: 'ZMW',
				name: 'Zambian kwacha',
				symbol: 'ZK'
			}
		],
		alpha2Code: 'ZM',
		alpha3Code: 'ZMB'
	},
	{
		name: 'Zimbabwe',
		currencies: [
			{
				code: 'ZMW',
				name: 'Zambian kwacha',
				symbol: 'K'
			}
		],
		alpha2Code: 'ZW',
		alpha3Code: 'ZWE'
	}
];
