import React from 'react';
import useChart from './useChart';
import useDataApi from '../../hooks/useDataApi';
import { isData } from '../../utils';
import useChartFilter from './useChartFilter';
import ChartFilter from './ChartFilter';
import './Chart.css';

const View = (id, isError, onLineTypeChange, onIntervalChange, tickInterval, chartLineType) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			<div className="chart-type-item">
				<div>
					<label> Chart Type: </label>
					<ChartFilter
						type="linetype"
						data={chartLineType}
						handleChange={onLineTypeChange}
					/>
				</div>
				<div>
					<label> Intervals:</label>
					<ChartFilter
						type="interval"
						data={tickInterval}
						handleChange={onIntervalChange}
					/>
				</div>
			</div>

			<div className="padding-20 padding-top-0" id={id}></div>
		</>
	);
};

const MetricsDetailChart = (props) => {
	const { chartLineType, tickInterval, onLineTypeChange, onIntervalChange } = useChartFilter();
	const [dataProps] = useDataApi(
		`/fred_report`,
		{},
		{
			method: 'POST',
			body: JSON.stringify({ report_name: props.id, interval: tickInterval })
		}
	);
	const { data, isError } = dataProps;
	const chartData = isData(data) ? data : [];

	useChart({
		chartData,
		chartType: 'metricsDetail',
		chartLineType,
		tickInterval,
		...props
	});

	return View(
		props.chartId,
		isError,
		onLineTypeChange,
		onIntervalChange,
		tickInterval,
		chartLineType
	);
};

export default MetricsDetailChart;
