const AccountStatus = ({ value }) => {
	return (
		<div className="account-status">
			<span>
				{value === 'live' ? (
					<img src="/images/play.png" />
				) : value === 'paper' ? (
					<img src="/images/pause.png" />
				) : (
					''
				)}
			</span>
			<span>{value}</span>
		</div>
	);
};

export default AccountStatus;
