const PortfolioNameWithTicker = ({ id, portfolioname, symbol, name }) => {
	return (
		<div className="portfolio-name-column">
			<span className="grid-icon">
				<label>
					{id}, {portfolioname}
				</label>
				<label>{symbol}</label>
				<label>{name}</label>
			</span>
		</div>
	);
};

export default PortfolioNameWithTicker;
