import useList from './useList';
import Table from './Table/Table';
import './List.css';

const View = (tableProps) => {
	return <Table {...tableProps} />;
};

const List = ({ newData, columns, RowSubComponent, isPagination, pageSize, tableClass }) => {
	const tableProps = useList(newData, columns, pageSize);
	return (
		<View
			{...tableProps}
			RowSubComponent={RowSubComponent}
			isPagination={isPagination}
			tableClass={tableClass}
			pageSize={pageSize}
		/>
	);
};

export default List;
