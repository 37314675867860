import styled from 'styled-components';

const Form = styled.form.attrs({
	className: 'sign-right-side'
})`
	color: var(--font-black);
	h1 {
		text-align: center;
	}
	label {
		margin: 0.5rem 0;
	}
	.input-page {
		display: flex;
		flex-direction: column;
		max-width: 90%;
		margin: 0 auto;
		.input-container {
			display: flex;
			flex-wrap: wrap;
			label {
				width: 100%;
				margin: 0.5rem 0;
			}
			input {
				width: 100%;
				font-size: var(--font-size-l);
				color: var(--font-grey);
				border: none;
				border-bottom: 1px solid var(--font-grey);
				:focus {
					outline: none;
				}
			}
		}
	}
	.checkbox-page {
		max-width: 90%;
		margin: 0 auto;
		button {
			margin: 2rem 0;
		}
		.checkbox-container {
			margin: 1rem 0;
		}
	}
	.button-container {
		align-self: flex-start;
		margin: 2rem 0;
		width: 177px;
		display: flex;
		justify-content: space-between;
	}
	span.error {
		color: var(--font-red);
	}
	@media screen and (min-width: 677px) {
		.button-container {
			align-self: center;
			width: 265px;
		}
	}
`;

export const FirstPage = styled.div.attrs({
	className: 'input-page'
})`
	button {
		align-self: flex-start;
		margin: 2rem 0;
	}
	@media screen and (min-width: 677px) {
		button {
			align-self: center;
		}
	}
`;

export const SecondPage = styled.div.attrs({
	className: 'input-page'
})``;

export const ThirdPage = styled.div.attrs({
	className: 'checkbox-page'
})`
	.button-container {
		margin: 0;
	}
	@media screen and (min-width: 677px) {
		.button-container {
			width: 265px;
			margin: 0 auto;
		}
	}
`;

export const FourthPage = styled.div.attrs({
	className: 'checkbox-page'
})`
	.button-container {
		margin: 0;
		width: 145px;
	}
	@media screen and (min-width: 677px) {
		.button-container {
			width: 231px;
			margin: 0 auto;
		}
	}
`;

export const FifthPage = styled.div.attrs({
	className: 'success-page'
})`
	.text-center {
		text-align: center;
		margin-top: 100px;
	}

	.text-center h1 {
		font-family: Inter;
		font-size: 36px;
		line-height: 44px;
		letter-spacing: 0em;
	}
`;

export const Button = styled.button.attrs({
	className: 'button-sm btn-orange'
})``;

export const BackButton = styled.button`
	align-items: center;
	border-radius: 50px;
	font-weight: 600;
	font-size: var(--font-size-s);
	font-family: var(--font-family-rubik);
	cursor: pointer;
	padding: 15px 30px;
	letter-spacing: 1px;
	text-transform: uppercase;
	border: 2px solid var(--font-orange);
	outline: none;

	background: var(--bg-white);
	color: var(--font-orange);

	@media screen and (min-width: 100px) and (max-width: 676px) {
		font-size: var(--font-size-xs);
		padding: 8px 15px;
	}
`;

export default Form;
