import { useState } from 'react';
import { pg } from '../../components/forms/signUpAllSteps/config';
import SignUpAllSteps from '../../components/forms/signUpAllSteps/SignUpAllSteps';
import SignInLeftPanel from '../../components/signInLeftPanel/SignInLeftPanel';
import Styles from './SignUpAllStepsPageStyles';

const View = ({ showPage, setShowPage }) => {
	return (
		<Styles>
			<SignInLeftPanel>
				<div className="welcome-text">
					<p className="sign-text small-text">
						If your Login information fails to load the platform, please click the help
						button on the bottom right and we will get in touch with you shortly.
					</p>
				</div>

				<div className="page-count">{`${showPage + 1}/${Object.keys(pg).length}`}</div>
			</SignInLeftPanel>
			<SignUpAllSteps showPage={showPage} setShowPage={setShowPage} />
		</Styles>
	);
};
const SignUpAllStepsPage = () => {
	const [showPage, setShowPage] = useState(pg.PAGE_1);
	return <View showPage={showPage} setShowPage={setShowPage} />;
};

export default SignUpAllStepsPage;
