const View = ({ data, type, handleChange }) => {
	return (
		<select
			value={data}
			name={type}
			onChange={(e) => {
				handleChange(e);
				handleOnChange(e);
			}}
			className="chart-type-selector">
			{options[type].map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
	);
};

const ChartFilter = (props) => {
	return <View {...props} />;
};

const handleOnChange = () => {
	const element = document.getElementsByName('linetype');
	if (element[0].value === 'line') {
		options.interval = [
			{ label: 'Last Trades', value: 'sec' },
			{ label: '1 Minute Bars', value: 'min' },
			{ label: '1 Day Bars', value: 'day' }
		];
	} else {
		options.interval = [
			{ label: '1 Minute Bars', value: 'min' },
			{ label: '1 Day Bars', value: 'day' }
		];
	}
};

const options = {
	linetype: [
		{ label: 'Single Line', value: 'line' },
		{ label: 'Technical Indicators', value: 'indicator' },
		{ label: 'Custom', value: 'custom' }
	],
	interval: [
		{ label: 'Last Trades', value: 'sec' },
		{ label: '1 Minute Bars', value: 'min' },
		{ label: '1 Day Bars', value: 'day' }
	]
};

export default ChartFilter;
