import { NavLink } from 'react-router-dom';
import { Logo } from '../icons/icons';
import Styles from './CompanyLogoStyles';

const CompanyLogo = () => (
	<Styles>
		<NavLink to="/">
			<Logo />
			<div>
				MachineTrader <sup>TM</sup>
			</div>
		</NavLink>
	</Styles>
);

export default CompanyLogo;
