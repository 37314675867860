import { useEffect, useState } from 'react';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../tabs/Tabs';
import TradeChart from '../chart/TradeChart';
import TickerSelector from '../tickerSelector/TickerSelector';
import useDataApi from '../../hooks/useDataApi';
import TradeForm from './TradeForm';
import { createSelectedTickerFormat, getSymbolType } from '../../utils';

import './Trade.css';
import SymbolType from '../symbolType/SymbolType';

const SearchStock = ({ setTradeSymbol, symbolType, symbol }) => {
	return (
		<div className="multi-select-item">
			<label className="multi-select-label">Select ticker or token or crypto</label>
			<TickerSelector
				setSelectedValue={setTradeSymbol}
				symbolType={symbolType}
				searchValues={createSelectedTickerFormat(symbol)}
			/>
			<img className="multi-select-icon" src="/images/search-icon.png" alt="serch" />
		</div>
	);
};

const BuySellSwitch = ({ onTradeTypeChange }) => {
	return (
		<div className="switcher-container">
			<div className="toggler">
				<label className="label">Sell</label>

				<input
					type="checkbox"
					id="buy-sell-switch"
					className="toggle-switcher"
					onChange={(e) => {
						onTradeTypeChange(e.target.checked);
					}}
				/>
				<label htmlFor="buy-sell-switch" className="toggle-switch"></label>
				<label className="label">Buy</label>
			</div>
		</div>
	);
};

const TradeForms = ({
	activeTradeType,
	currentPrice,
	stockInfo,
	accountType,
	symbolType,
	refreshPage
}) => {
	return (
		<Tabs type={'trade'} activeId="quick-trade-tab">
			<TabNavItems>
				<TabNavItem id="quick-trade-tab" title="Quick Trade" />

				<TabNavItem id="manual-trade-tab" title="Manual Trade" />
			</TabNavItems>

			<TabContents>
				<TabContent id="quick-trade-tab">
					<TradeForm
						activeTradeType={activeTradeType}
						tradeTabType="quick"
						symbolType={symbolType}
						currentPrice={currentPrice}
						accountType={accountType}
						stockInfo={stockInfo}
						refreshPage={refreshPage}
					/>
				</TabContent>

				<TabContent id="manual-trade-tab">
					<TradeForm
						activeTradeType={activeTradeType}
						tradeTabType="manual"
						symbolType={symbolType}
						currentPrice={currentPrice}
						accountType={accountType}
						stockInfo={stockInfo}
						refreshPage={refreshPage}
					/>
				</TabContent>
			</TabContents>
		</Tabs>
	);
};
const View = ({
	activeTradeType,
	accountType,
	setSymbolType,
	symbolType,
	stockInfo,
	currentPrice,
	symbol,
	setTradeSymbol,
	onTradeTypeChange,
	refreshPage
}) => (
	<>
		<div className="symbol-type-selector">
			<SymbolType handleChange={setSymbolType} value={symbolType} />
		</div>
		<div className="trade-action">
			<SearchStock setTradeSymbol={setTradeSymbol} symbolType={symbolType} symbol={symbol} />
			<BuySellSwitch onTradeTypeChange={onTradeTypeChange} />
		</div>
		{symbol && stockInfo && currentPrice && (
			<>
				<TradeChart
					isFullScreen={true}
					chartId="trade-chart"
					symbol={stockInfo.ticker}
					symbolType={symbolType}
				/>
				<TradeForms
					activeTradeType={activeTradeType}
					currentPrice={currentPrice}
					stockInfo={stockInfo}
					accountType={accountType}
					symbolType={symbolType}
					refreshPage={refreshPage}
				/>
			</>
		)}
	</>
);

const Trade = (props) => {
	const currentPriceUrl = props.symbol ? `/last_price?symbol=${props.symbol}` : '';
	const stockInfoUrl = props.symbol ? `/ticker_detail?symbol=${props.symbol}` : '';

	const [activeTradeType, setTradeType] = useState('buy');
	const [symbolType, setSymbolType] = useState(
		props.activeSymbolType || getSymbolType(props.symbol)
	);
	const onTradeTypeChange = (value) => setTradeType(value ? 'sell' : 'buy');

	const [priceProps, setCurrentPriceUrl] = useDataApi(currentPriceUrl, 0, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: getCurrentPriceReqBody({ symbolType, ...props })
	});

	const [stockProps, setStockInfoUrl] = useDataApi(
		stockInfoUrl,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ symbol: props.symbol, account_type: props.accountType })
		}
	);

	useEffect(() => {
		setCurrentPriceUrl(currentPriceUrl);
		setStockInfoUrl(stockInfoUrl);
	}, [props.symbol]);

	console.log("BEN PRICE DATA:")
	console.log(priceProps.data)
	return (
		<View
			{...{
				activeTradeType,
				onTradeTypeChange,
				setSymbolType,
				symbolType,
				accountType: props.accountType,
				symbol: props.symbol,
				currentPrice: priceProps.data,
				setTradeSymbol: props.setTradeSymbol,
				stockInfo: stockProps.data?.results,
				refreshPage: props.refreshPage
			}}
		/>
	);
};

const getCurrentPriceReqBody = (props) => {
	return JSON.stringify({
		symbol: props.symbol,
		account_type: props.accountType,
		interval: 'min',
		symbol_type: props.symbolType
	});
};

export default Trade;
