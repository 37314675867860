import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import useDataApi from '../../hooks/useDataApi';
import { closeMultiSelectList, isData } from '../../utils';
import './TickerSelector.css';

const View = ({
	options,
	selectedValues,
	selectionLimit,
	setSymbol,
	isLoading,
	handleChange,
	handleSelect,
	selectedValueDecorator
}) => {
	return (
		<Multiselect
			options={options}
			selectionLimit={selectionLimit || 1}
			selectedValues={selectedValues}
			onSearch={setSymbol}
			loading={isLoading}
			caseSensitiveSearch={false}
			closeOnSelect={true}
			onSelect={handleSelect}
			onRemove={handleChange}
			emptyRecordMsg="Enter Keyword"
			displayValue="description"
			placeholder="Search"
			className="multi-select-wrapper"
			selectedValueDecorator={selectedValueDecorator}
		/>
	);
};

const TickerSelector = ({ setSelectedValue, selectionLimit, symbolType, searchValues }) => {
	const [selectedValues] = useState(searchValues || '');
	const [options, setOptions] = useState([]);
	const [symbol, setSymbol] = useState('');
	const url = symbol && `/select_symbol?symbol=${symbol}`;

	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ symbol: symbol, symbol_type: symbolType })
	});

	const { data, isLoading } = dataProps;
	const selectedValueDecorator = (item) => item.split(' ')[0];

	const handleSelect = (selectedList) => {
		closeMultiSelectList();
		handleChange(selectedList);
	};
	const handleChange = (selectedList) => {
		const selectedSymbols = selectedList.map((item) => item.symbol).join(',');
		setSelectedValue && setSelectedValue(selectedSymbols.split(',')[0]);
	};

	useEffect(() => {
		if (isData(data)) {
			data.map((item) => {
				item['description'] = `${item.symbol} (${item.name})`;
				return item;
			});
			setOptions(data);
		}
	}, [data]);

	useEffect(() => {
		setUrl(url);
	}, [symbol]);

	return (
		<View
			{...{
				options,
				selectedValues,
				setSymbol,
				isLoading,
				handleChange,
				handleSelect,
				symbol,
				selectionLimit,
				selectedValueDecorator
			}}
		/>
	);
};

export default TickerSelector;
