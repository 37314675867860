import { OrderHistoryIcon } from '../../styles/icons/icons';
import { TabContent, TabContents, TabNavItem, TabNavItems, Tabs } from '../tabs/Tabs';
import OrderHistory from './OrderHistory';

const OrderHistoryTabs = ({ accountType, isPagination = false, columns, isPage, refreshPage }) => (
	<Tabs activeId="open-order-history">
		<TabNavItems>
			{!isPage && (
				<div className="tab-group-info large-group-info">
					<OrderHistoryIcon />
					Order History
				</div>
			)}
			<TabNavItem id="open-order-history" title="OPEN ORDERS" />
			<TabNavItem id="closed-order-history" title="CLOSED ORDERS" />
		</TabNavItems>
		<TabContents>
			<TabContent id="open-order-history">
				<OrderHistory
					orderType="open"
					accountType={accountType}
					isPagination={isPagination}
					columns={columns}
					refreshPage={refreshPage}
				/>
			</TabContent>
			<TabContent id="closed-order-history">
				<OrderHistory
					orderType="closed"
					accountType={accountType}
					isPagination={isPagination}
					columns={columns}
					refreshPage={refreshPage}
				/>
			</TabContent>
		</TabContents>
	</Tabs>
);

export default OrderHistoryTabs;
