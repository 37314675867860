const OrderNameWithDatetime = ({ type, side, created_at }) => {
	return (
		<div className="multi-line-cell name-n-timestamp">
			<span>
				{type?.charAt(0).toUpperCase() + type?.slice(1)} {side?.toUpperCase()}
			</span>
			<span>{created_at && new Date(created_at).toLocaleString()}</span>
		</div>
	);
};

export default OrderNameWithDatetime;
