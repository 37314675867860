import { formatNumber } from '../../utils';
import Amount from '../list/cells/Amount';
// import ContextMenu from '../list/cells/ContextMenu';
import OrderDatetime from '../list/cells/OrderDatetime';
import OrderNameWithDatetime from '../list/cells/OrderNameWithDateime';
import SymbolWithName from '../list/cells/SymbolWithName';

// const actionMenuEventHandler = (e, a, v) => {
// 	console.log(e);
// 	console.log(a);
// 	console.log(v);
// };

const getColumns = (orderType) => {
	const nameCol = {
		Header: 'Name',
		accessor: 'symbol',
		Cell: (props) => {
			return <SymbolWithName {...props.row.original} />;
		}
	};
	const orderCol = {
		Header: 'Order',
		accessor: (d) => (
			<OrderNameWithDatetime type={d.type} side={d.side} created_at={d.created_at} />
		)
	};
	const quantityCol = {
		Header: 'Quantity',
		accessor: 'qty',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => formatNumber(props.value)
	};
	const orderTypeCol = {
		Header: 'Order Type',
		accessor: 'order_type',
		HeaderClass: 'text-center',
		alignment: 'center'
	};
	const limitPriceCol = {
		Header: 'Limit Price',
		accessor: 'limit_price',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	};
	const filledPriceCol = {
		Header: 'Filled Price',
		HeaderClass: 'text-center',
		accessor: 'filled_avg_price',
		alignment: 'center',
		Cell: (props) => <Amount {...props} />
	};
	const timeInForceCol = {
		Header: 'TIF',
		accessor: 'time_in_force',
		alignment: 'center',
		HeaderClass: 'text-center'
	};
	const timePlaceOrFilledCol = {
		Header: 'Time Placed/Filled',
		accessor: (d) => <OrderDatetime submitted_at={d.submitted_at} filled_at={d.filled_at} />
	};
	// const actionMenuCol = {
	// 	Header: '',
	// 	HeaderClass: 'hide',
	// 	accessor: 'id',
	// 	Cell: (props) => {
	// 		return <ContextMenu value={props.value} handleClick={actionMenuEventHandler} />;
	// 	}
	// };
	return orderType === 'closed'
		? [
				nameCol,
				orderCol,
				timePlaceOrFilledCol,
				quantityCol,
				orderTypeCol,
				limitPriceCol,
				filledPriceCol
		  ]
		: [
				nameCol,
				orderCol,
				quantityCol,
				orderTypeCol,
				limitPriceCol,
				timeInForceCol
				// actionMenuCol
		  ];
};

export default getColumns;
