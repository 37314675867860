import { useState } from 'react';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './alpacaErrorDialog.css';

const View = ({ isOpen, setIsOpen, data }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Error"
			headerIcon="/images/info-v2.png"
			handleStateChange={setIsOpen}>
			<ModalBody>
				<div className="alpaca-error-model">
					<div className="alpaca-error-info">
						<h2>Your order was not accepted!</h2>
						<div>
							Code: <span>{data.code}</span>
						</div>
						<div>
							Message: <span>{data.message}</span>
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="alpaca-error-footer">
					<a onClick={() => setIsOpen(false)} className="link-text">
						{' '}
						&gt; Dismiss
					</a>
				</div>
			</ModalFooter>
		</ModalDialog>
	);
};

const AlpacaErrorDialog = (props) => {
	const [isOpen, setIsOpen] = useState(props.isOpen || true);
	return <View setIsOpen={setIsOpen} isOpen={isOpen} data={props.data} />;
};

export default AlpacaErrorDialog;
