import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../../components/tabs/Tabs';
import BreakingNews from '../../components/breakingNews/BreakingNews';
import TopStocks from '../../components/topStocks/TopStocks';
import './InsightPage.css';
import NewsSentimentChart from '../../components/chart/NewsSentimentChart';
import OtherNews from '../../components/otherNews/OtherNews';
import SortBy from '../../components/sortBy/SortBy';
import NewsDialog from '../../components/modals/newsDialog/NewsDialog';
import TradeChart from '../../components/chart/TradeChart';
import MetricsContainer from '../../components/insight/MetricsContainer';
import CustomizeViewButton from '../../components/buttons/CustomizeViewButton';
import FredReportSelector from '../../components/fredReportSelector/FredReportSelector';
import TickerSelector from '../../components/tickerSelector/TickerSelector';
import { getSymbolType } from '../../utils';

import ErrorDialog from '../../components/modals/ErrorDialog/ErrorDialog';

const InsightPage = () => {
	const [activetradeSymbol, setTradeSymbol] = useState('');
	const [openNewsDialog, setOpenNewsDialog] = useState(false);
	const [selectedNewsId, setSelectedNewsId] = useState(false);
	const [selectedTicker, setSelectedTicker] = useState('');
	const openNewsDetail = (id) => {
		setSelectedNewsId(id);
		setOpenNewsDialog(true);
	};
	const onSortChage = (e, v) => {
		console.log(e);
		console.log(v);
	};
	console.log(activetradeSymbol);

	const [openErrorDialog, setOpenErrorDialog] = useState(false);


		var errors = localStorage.getItem("errors");
		console.log("errors:"+errors)
        var ws;
        var wsUri = "ws:";
        var loc = window.location;
        console.log(loc);
        if (loc.protocol === "https:") { wsUri = "wss:"; }
        // This needs to point to the web socket in the Node-RED flow
        // ... in this case it's ws/simple
		if ( !loc.host.includes("localhost"))
	    {
	        wsUri += "//" + loc.host + "/api/ws/errors";	//loc.pathname.replace("simple","ws/errors");
	    	console.log("server based")
	    }
        else
        {
        	wsUri += "//localhost:1880/ws/errors"
	    	console.log("localhost based")
        }
		console.log(wsUri)
        function wsConnect() {
            console.log("connect",wsUri);
            ws = new WebSocket(wsUri);
            //var line = "";    // either uncomment this for a building list of messages
            ws.onmessage = function(msg) {
                var line = "";  // or uncomment this to overwrite the existing message
                // parse the incoming message as a JSON object
                var data = JSON.parse(msg.data);
//                 console.log(data.error);
                // build the output from the topic and payload parts of the object
                if ("error" in data && typeof data.error === "string" && data.error.length !== 0)
				{
					line += "<p>"+data.error+"</p>";
					// replace the messages div with the new "line"
// 					document.getElementById('messages').innerHTML = line;
					localStorage.setItem("errors", data.error);

					setOpenErrorDialog(true);
					//ws.send(JSON.stringify({data:data}));
				}
	            else
					localStorage.setItem("errors", "");
//     	            document.getElementById('messages').innerHTML = "";
            }
            ws.onopen = function() {
                // update the status div with the connection status
//                 document.getElementById('status').innerHTML = "Interlisted socket connected";
                //ws.send("Open for data");
                console.log("Interlisted socket connected");
// 				if (errors.length > 0)
// 				{
// 					setOpenErrorDialog(true);
// 		
// 				}
            }
            ws.onclose = function() {
                // update the status div with the connection status
                document.getElementById('status').innerHTML = "not connected";
                // in case of lost connection tries to reconnect every 3 secs
                setTimeout(wsConnect,3000);
            }
        }
        
        function doit(m) {
            if (ws) { ws.send(m); }
        }
		wsConnect()
// 		ws.disconnect();

	return (
		<div className="wrapper-container">
			<div className="text-right padding-top-20">
				<CustomizeViewButton />
			</div>

			<div className="card-main-container">
				<div className="view-container">
					<div className="card sm-tab padding-0 insights-container">
						<Tabs activeId="insight-news">
							<TabNavItems>
								<TabNavItem
									id="insight-news"
									icon="/images/news-icon.png"
									title="News"
								/>

								<TabNavItem
									id="insight-metrics"
									title="Markets"
									icon="/images/metrics-icon.png"
								/>

								<TabNavItem
									id="insight-fred"
									title="Fred"
									icon="/images/metrics-icon.png"
								/>
							</TabNavItems>

							<TabContents>
								<TabContent id="insight-news">
									<div className="card-main-container">
										<div className="view-container">
											<div className="trade-action padding-left-zero">
												<div className="multi-select-item">
													<TickerSelector
														setSelectedValue={
															setTradeSymbol
														}></TickerSelector>

													<img
														className="multi-select-icon"
														src="/images/search-icon.png"
														alt="serch"
													/>
												</div>
												<SortBy
													handleChange={onSortChage}
													items={[
														{ text: 'Date', value: 'date' },
														{ text: 'relevance', value: 'Relevance' }
													]}
													value="date"
												/>
											</div>

											{activetradeSymbol && (
												<>
													<div>
														<TradeChart
															chartId="trade-chart-1"
															symbol={activetradeSymbol}
															chartType="trade"
															symbolType={getSymbolType(
																activetradeSymbol
															)}
														/>
														<div className="text-right">
															<NavLink to="/trades">
																<button className="button-sm btn-orange">
																	Make A Trade
																</button>
															</NavLink>
														</div>
													</div>
												</>
											)}
											<BreakingNews
												openNewsDetail={openNewsDetail}
												symbol={activetradeSymbol}
											/>
										</div>

										<div className="right-side-bar">
											<NewsSentimentChart
												isFullScreen={false}
												chartId="news-sentiment-chart-id"
											/>
											<OtherNews />
											<TopStocks />
										</div>
									</div>
								</TabContent>
								<TabContent id="insight-metrics">
									<div className="insights-info hide">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Urna ante sodales lectus ut fringilla adipiscing. In diam
										viverra suspendisse turpis a quis id sagittis praesent.
										Dictumst vitae quisque magnis turpis vulputate pharetra
										tortor tincidunt massa. Vitae quis eget nibh pellentesque
										quam enim risus, neque. Et ultrices orci faucibus varius at.
									</div>
									<div className="card flex-wrap-container">
										<MetricsContainer type="metrics" />
									</div>
								</TabContent>

								<TabContent id="insight-fred">
									<FredReportSelector
										selectedTicker={selectedTicker}
										setSelectedTicker={setSelectedTicker}
									/>
									<div className="insights-info hide">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Urna ante sodales lectus ut fringilla adipiscing. In diam
										viverra suspendisse turpis a quis id sagittis praesent.
										Dictumst vitae quisque magnis turpis vulputate pharetra
										tortor tincidunt massa. Vitae quis eget nibh pellentesque
										quam enim risus, neque. Et ultrices orci faucibus varius at.
									</div>
									<div className="card flex-wrap-container">
										<MetricsContainer
											type="fred-report"
											selectedTicker={selectedTicker}
										/>
									</div>
								</TabContent>
							</TabContents>
						</Tabs>
					</div>
				</div>
			</div>
			{openNewsDialog && (
				<NewsDialog
					id={selectedNewsId}
					isOpen={openNewsDialog}
					handleStateChange={setOpenNewsDialog}
				/>
			)}
			{openErrorDialog && (
				<ErrorDialog
					isOpen={openErrorDialog}
					handleStateChange={setOpenErrorDialog}
					
				/>
			)}
		</div>
	);
};

export default InsightPage;
