const priceEarningsData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 25.8043
		},
		{
			filedate: '2010-04-21',
			value: 17.028
		},
		{
			filedate: '2010-07-21',
			value: 18.6765
		},
		{
			filedate: '2010-10-27',
			value: 4.74238
		},
		{
			filedate: '2011-01-19',
			value: 12.389
		},
		{
			filedate: '2011-04-21',
			value: 13.5416
		},
		{
			filedate: '2011-07-20',
			value: 10.3406
		},
		{
			filedate: '2011-10-26',
			value: 3.60339
		},
		{
			filedate: '2012-01-25',
			value: 7.21668
		},
		{
			filedate: '2012-04-25',
			value: 12.0392
		},
		{
			filedate: '2012-07-25',
			value: 15.4989
		},
		{
			filedate: '2012-10-31',
			value: 3.73603
		},
		{
			filedate: '2013-01-24',
			value: 9.14553
		},
		{
			filedate: '2013-04-24',
			value: 10.8922
		},
		{
			filedate: '2013-07-24',
			value: 13.2001
		},
		{
			filedate: '2013-10-30',
			value: 3.01493
		},
		{
			filedate: '2014-01-28',
			value: 9.59716
		},
		{
			filedate: '2014-04-24',
			value: 11.4812
		},
		{
			filedate: '2014-07-23',
			value: 17.8256
		},
		{
			filedate: '2014-10-27',
			value: 3.88097
		},
		{
			filedate: '2015-01-28',
			value: 9.25243
		},
		{
			filedate: '2015-04-28',
			value: 13.1677
		},
		{
			filedate: '2015-07-22',
			value: 17.0363
		},
		{
			filedate: '2015-10-28',
			value: 3.09025
		},
		{
			filedate: '2016-01-27',
			value: 8.22803
		},
		{
			filedate: '2016-04-27',
			value: 0.664921
		},
		{
			filedate: '2016-07-27',
			value: 16.3287
		},
		{
			filedate: '2016-10-26',
			value: 3.37455
		},
		{
			filedate: '2017-02-01',
			value: 8.56657
		},
		{
			filedate: '2017-05-03',
			value: 17.0213
		},
		{
			filedate: '2017-08-02',
			value: 21.4315
		},
		{
			filedate: '2017-11-03',
			value: 4.15642
		},
		{
			filedate: '2018-02-02',
			value: 10.7927
		},
		{
			filedate: '2018-05-02',
			value: 15.2527
		},
		{
			filedate: '2018-08-01',
			value: 19.6091
		},
		{
			filedate: '2018-11-05',
			value: 4.699
		},
		{
			filedate: '2019-01-30',
			value: 9.25533
		},
		{
			filedate: '2019-05-01',
			value: 19.2257
		},
		{
			filedate: '2019-07-31',
			value: 22.4909
		},
		{
			filedate: '2019-10-31',
			value: 4.57018
		},
		{
			filedate: '2020-01-29',
			value: 14.375
		},
		{
			filedate: '2020-05-01',
			value: 24.0058
		},
		{
			filedate: '2020-07-31',
			value: 33.8726
		},
		{
			filedate: '2020-10-30',
			value: 8.48036
		},
		{
			filedate: '2021-01-28',
			value: 19.4074
		},
		{
			filedate: '2021-04-29',
			value: 21.4911
		},
		{
			filedate: '2021-07-28',
			value: 25.4027
		},
		{
			filedate: '2021-10-29',
			value: 6.47795
		},
		{
			filedate: '2022-01-28',
			value: 20.8863
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 25.8043
		},
		{
			filedate: '2010-04-21',
			value: 17.028
		},
		{
			filedate: '2010-07-21',
			value: 18.6765
		},
		{
			filedate: '2010-10-27',
			value: 4.74238
		},
		{
			filedate: '2011-01-19',
			value: 12.389
		},
		{
			filedate: '2011-04-21',
			value: 13.5416
		},
		{
			filedate: '2011-07-20',
			value: 10.3406
		},
		{
			filedate: '2011-10-26',
			value: 3.60339
		},
		{
			filedate: '2012-01-25',
			value: 7.21668
		},
		{
			filedate: '2012-04-25',
			value: 12.0392
		},
		{
			filedate: '2012-07-25',
			value: 15.4989
		},
		{
			filedate: '2012-10-31',
			value: 3.73603
		},
		{
			filedate: '2013-01-24',
			value: 9.14553
		},
		{
			filedate: '2013-04-24',
			value: 10.8922
		},
		{
			filedate: '2013-07-24',
			value: 13.2001
		},
		{
			filedate: '2013-10-30',
			value: 3.01493
		},
		{
			filedate: '2014-01-28',
			value: 9.59716
		},
		{
			filedate: '2014-04-24',
			value: 11.4812
		},
		{
			filedate: '2014-07-23',
			value: 17.8256
		},
		{
			filedate: '2014-10-27',
			value: 3.88097
		},
		{
			filedate: '2015-01-28',
			value: 9.25243
		},
		{
			filedate: '2015-04-28',
			value: 13.1677
		},
		{
			filedate: '2015-07-22',
			value: 17.0363
		},
		{
			filedate: '2015-10-28',
			value: 3.09025
		},
		{
			filedate: '2016-01-27',
			value: 8.22803
		},
		{
			filedate: '2016-04-27',
			value: 0.664921
		},
		{
			filedate: '2016-07-27',
			value: 16.3287
		},
		{
			filedate: '2016-10-26',
			value: 3.37455
		},
		{
			filedate: '2017-02-01',
			value: 8.56657
		},
		{
			filedate: '2017-05-03',
			value: 17.0213
		},
		{
			filedate: '2017-08-02',
			value: 21.4315
		},
		{
			filedate: '2017-11-03',
			value: 4.15642
		},
		{
			filedate: '2018-02-02',
			value: 10.7927
		},
		{
			filedate: '2018-05-02',
			value: 15.2527
		},
		{
			filedate: '2018-08-01',
			value: 19.6091
		},
		{
			filedate: '2018-11-05',
			value: 4.699
		},
		{
			filedate: '2019-01-30',
			value: 9.25533
		},
		{
			filedate: '2019-05-01',
			value: 19.2257
		},
		{
			filedate: '2019-07-31',
			value: 22.4909
		},
		{
			filedate: '2019-10-31',
			value: 4.57018
		},
		{
			filedate: '2020-01-29',
			value: 14.375
		},
		{
			filedate: '2020-05-01',
			value: 24.0058
		},
		{
			filedate: '2020-07-31',
			value: 33.8726
		},
		{
			filedate: '2020-10-30',
			value: 8.48036
		},
		{
			filedate: '2021-01-28',
			value: 19.4074
		},
		{
			filedate: '2021-04-29',
			value: 21.4911
		},
		{
			filedate: '2021-07-28',
			value: 25.4027
		},
		{
			filedate: '2021-10-29',
			value: 6.47795
		},
		{
			filedate: '2022-01-28',
			value: 20.8863
		}
	]
};

const currentData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 2.11092
		},
		{
			filedate: '2010-04-21',
			value: 2.64421
		},
		{
			filedate: '2010-07-21',
			value: 2.30803
		},
		{
			filedate: '2010-10-27',
			value: 2.01129
		},
		{
			filedate: '2011-01-19',
			value: 1.84606
		},
		{
			filedate: '2011-04-21',
			value: 1.93189
		},
		{
			filedate: '2011-07-20',
			value: 1.74608
		},
		{
			filedate: '2011-10-26',
			value: 1.60844
		},
		{
			filedate: '2012-01-25',
			value: 1.58266
		},
		{
			filedate: '2012-04-25',
			value: 1.58297
		},
		{
			filedate: '2012-07-25',
			value: 1.57117
		},
		{
			filedate: '2012-10-31',
			value: 1.49585
		},
		{
			filedate: '2013-01-24',
			value: 1.54329
		},
		{
			filedate: '2013-04-24',
			value: 1.78374
		},
		{
			filedate: '2013-07-24',
			value: 1.87833
		},
		{
			filedate: '2013-10-30',
			value: 1.67864
		},
		{
			filedate: '2014-01-28',
			value: 1.4943
		},
		{
			filedate: '2014-04-24',
			value: 1.63259
		},
		{
			filedate: '2014-07-23',
			value: 1.4706
		},
		{
			filedate: '2014-10-27',
			value: 1.08011
		},
		{
			filedate: '2015-01-28',
			value: 1.13302
		},
		{
			filedate: '2015-04-28',
			value: 1.156
		},
		{
			filedate: '2015-07-22',
			value: 1.08682
		},
		{
			filedate: '2015-10-28',
			value: 1.10877
		},
		{
			filedate: '2016-01-27',
			value: 1.00167
		},
		{
			filedate: '2016-04-27',
			value: 1.28312
		},
		{
			filedate: '2016-07-27',
			value: 1.3116
		},
		{
			filedate: '2016-10-26',
			value: 1.35267
		},
		{
			filedate: '2017-02-01',
			value: 1.22824
		},
		{
			filedate: '2017-05-03',
			value: 1.39061
		},
		{
			filedate: '2017-08-02',
			value: 1.38834
		},
		{
			filedate: '2017-11-03',
			value: 1.27606
		},
		{
			filedate: '2018-02-02',
			value: 1.24201
		},
		{
			filedate: '2018-05-02',
			value: 1.45603
		},
		{
			filedate: '2018-08-01',
			value: 1.30732
		},
		{
			filedate: '2018-11-05',
			value: 1.12384
		},
		{
			filedate: '2019-01-30',
			value: 1.30055
		},
		{
			filedate: '2019-05-01',
			value: 1.31538
		},
		{
			filedate: '2019-07-31',
			value: 1.50465
		},
		{
			filedate: '2019-10-31',
			value: 1.54013
		},
		{
			filedate: '2020-01-29',
			value: 1.59778
		},
		{
			filedate: '2020-05-01',
			value: 1.49596
		},
		{
			filedate: '2020-07-31',
			value: 1.46945
		},
		{
			filedate: '2020-10-30',
			value: 1.3636
		},
		{
			filedate: '2021-01-28',
			value: 1.163
		},
		{
			filedate: '2021-04-29',
			value: 1.14175
		},
		{
			filedate: '2021-07-28',
			value: 1.06189
		},
		{
			filedate: '2021-10-29',
			value: 1.07455
		},
		{
			filedate: '2022-01-28',
			value: 1.03781
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 2.11092
		},
		{
			filedate: '2010-04-21',
			value: 2.64421
		},
		{
			filedate: '2010-07-21',
			value: 2.30803
		},
		{
			filedate: '2010-10-27',
			value: 2.01129
		},
		{
			filedate: '2011-01-19',
			value: 1.84606
		},
		{
			filedate: '2011-04-21',
			value: 1.93189
		},
		{
			filedate: '2011-07-20',
			value: 1.74608
		},
		{
			filedate: '2011-10-26',
			value: 1.60844
		},
		{
			filedate: '2012-01-25',
			value: 1.58266
		},
		{
			filedate: '2012-04-25',
			value: 1.58297
		},
		{
			filedate: '2012-07-25',
			value: 1.57117
		},
		{
			filedate: '2012-10-31',
			value: 1.49585
		},
		{
			filedate: '2013-01-24',
			value: 1.54329
		},
		{
			filedate: '2013-04-24',
			value: 1.78374
		},
		{
			filedate: '2013-07-24',
			value: 1.87833
		},
		{
			filedate: '2013-10-30',
			value: 1.67864
		},
		{
			filedate: '2014-01-28',
			value: 1.4943
		},
		{
			filedate: '2014-04-24',
			value: 1.63259
		},
		{
			filedate: '2014-07-23',
			value: 1.4706
		},
		{
			filedate: '2014-10-27',
			value: 1.08011
		},
		{
			filedate: '2015-01-28',
			value: 1.13302
		},
		{
			filedate: '2015-04-28',
			value: 1.156
		},
		{
			filedate: '2015-07-22',
			value: 1.08682
		},
		{
			filedate: '2015-10-28',
			value: 1.10877
		},
		{
			filedate: '2016-01-27',
			value: 1.00167
		},
		{
			filedate: '2016-04-27',
			value: 1.28312
		},
		{
			filedate: '2016-07-27',
			value: 1.3116
		},
		{
			filedate: '2016-10-26',
			value: 1.35267
		},
		{
			filedate: '2017-02-01',
			value: 1.22824
		},
		{
			filedate: '2017-05-03',
			value: 1.39061
		},
		{
			filedate: '2017-08-02',
			value: 1.38834
		},
		{
			filedate: '2017-11-03',
			value: 1.27606
		},
		{
			filedate: '2018-02-02',
			value: 1.24201
		},
		{
			filedate: '2018-05-02',
			value: 1.45603
		},
		{
			filedate: '2018-08-01',
			value: 1.30732
		},
		{
			filedate: '2018-11-05',
			value: 1.12384
		},
		{
			filedate: '2019-01-30',
			value: 1.30055
		},
		{
			filedate: '2019-05-01',
			value: 1.31538
		},
		{
			filedate: '2019-07-31',
			value: 1.50465
		},
		{
			filedate: '2019-10-31',
			value: 1.54013
		},
		{
			filedate: '2020-01-29',
			value: 1.59778
		},
		{
			filedate: '2020-05-01',
			value: 1.49596
		},
		{
			filedate: '2020-07-31',
			value: 1.46945
		},
		{
			filedate: '2020-10-30',
			value: 1.3636
		},
		{
			filedate: '2021-01-28',
			value: 1.163
		},
		{
			filedate: '2021-04-29',
			value: 1.14175
		},
		{
			filedate: '2021-07-28',
			value: 1.06189
		},
		{
			filedate: '2021-10-29',
			value: 1.07455
		},
		{
			filedate: '2022-01-28',
			value: 1.03781
		}
	]
};

const cashflowData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0
		},
		{
			filedate: '2010-04-21',
			value: 0
		},
		{
			filedate: '2010-07-21',
			value: 0
		},
		{
			filedate: '2010-10-27',
			value: 0.446159
		},
		{
			filedate: '2011-01-19',
			value: 0.222483
		},
		{
			filedate: '2011-04-21',
			value: 0
		},
		{
			filedate: '2011-07-20',
			value: 0
		},
		{
			filedate: '2011-10-26',
			value: 0.8342
		},
		{
			filedate: '2012-01-25',
			value: 0.320498
		},
		{
			filedate: '2012-04-25',
			value: 0
		},
		{
			filedate: '2012-07-25',
			value: 0
		},
		{
			filedate: '2012-10-31',
			value: 0.882105
		},
		{
			filedate: '2013-01-24',
			value: 0.323796
		},
		{
			filedate: '2013-04-24',
			value: 0
		},
		{
			filedate: '2013-07-24',
			value: 0
		},
		{
			filedate: '2013-10-30',
			value: 0.732282
		},
		{
			filedate: '2014-01-28',
			value: 0.282151
		},
		{
			filedate: '2014-04-24',
			value: 0
		},
		{
			filedate: '2014-07-23',
			value: 0
		},
		{
			filedate: '2014-10-27',
			value: 0.871328
		},
		{
			filedate: '2015-01-28',
			value: 0.404326
		},
		{
			filedate: '2015-04-28',
			value: 0
		},
		{
			filedate: '2015-07-22',
			value: 0
		},
		{
			filedate: '2015-10-28',
			value: 0.909239
		},
		{
			filedate: '2016-01-27',
			value: 0.360317
		},
		{
			filedate: '2016-04-27',
			value: 0
		},
		{
			filedate: '2016-07-27',
			value: 0
		},
		{
			filedate: '2016-10-26',
			value: 0.615932
		},
		{
			filedate: '2017-02-01',
			value: 0.261836
		},
		{
			filedate: '2017-05-03',
			value: 0
		},
		{
			filedate: '2017-08-02',
			value: 0
		},
		{
			filedate: '2017-11-03',
			value: 0.494368
		},
		{
			filedate: '2018-02-02',
			value: 0.196739
		},
		{
			filedate: '2018-05-02',
			value: 0
		},
		{
			filedate: '2018-08-01',
			value: 0
		},
		{
			filedate: '2018-11-05',
			value: 0.589574
		},
		{
			filedate: '2019-01-30',
			value: 0.189522
		},
		{
			filedate: '2019-05-01',
			value: 0
		},
		{
			filedate: '2019-07-31',
			value: 0
		},
		{
			filedate: '2019-10-31',
			value: 0.426185
		},
		{
			filedate: '2020-01-29',
			value: 0.18695
		},
		{
			filedate: '2020-05-01',
			value: 0
		},
		{
			filedate: '2020-07-31',
			value: 0
		},
		{
			filedate: '2020-10-30',
			value: 0.561355
		},
		{
			filedate: '2021-01-28',
			value: 0.251535
		},
		{
			filedate: '2021-04-29',
			value: 0
		},
		{
			filedate: '2021-07-28',
			value: 0
		},
		{
			filedate: '2021-10-29',
			value: 0.771589
		},
		{
			filedate: '2022-01-28',
			value: 0.306659
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0
		},
		{
			filedate: '2010-04-21',
			value: 0
		},
		{
			filedate: '2010-07-21',
			value: 0
		},
		{
			filedate: '2010-10-27',
			value: 0.446159
		},
		{
			filedate: '2011-01-19',
			value: 0.222483
		},
		{
			filedate: '2011-04-21',
			value: 0
		},
		{
			filedate: '2011-07-20',
			value: 0
		},
		{
			filedate: '2011-10-26',
			value: 0.8342
		},
		{
			filedate: '2012-01-25',
			value: 0.320498
		},
		{
			filedate: '2012-04-25',
			value: 0
		},
		{
			filedate: '2012-07-25',
			value: 0
		},
		{
			filedate: '2012-10-31',
			value: 0.882105
		},
		{
			filedate: '2013-01-24',
			value: 0.323796
		},
		{
			filedate: '2013-04-24',
			value: 0
		},
		{
			filedate: '2013-07-24',
			value: 0
		},
		{
			filedate: '2013-10-30',
			value: 0.732282
		},
		{
			filedate: '2014-01-28',
			value: 0.282151
		},
		{
			filedate: '2014-04-24',
			value: 0
		},
		{
			filedate: '2014-07-23',
			value: 0
		},
		{
			filedate: '2014-10-27',
			value: 0.871328
		},
		{
			filedate: '2015-01-28',
			value: 0.404326
		},
		{
			filedate: '2015-04-28',
			value: 0
		},
		{
			filedate: '2015-07-22',
			value: 0
		},
		{
			filedate: '2015-10-28',
			value: 0.909239
		},
		{
			filedate: '2016-01-27',
			value: 0.360317
		},
		{
			filedate: '2016-04-27',
			value: 0
		},
		{
			filedate: '2016-07-27',
			value: 0
		},
		{
			filedate: '2016-10-26',
			value: 0.615932
		},
		{
			filedate: '2017-02-01',
			value: 0.261836
		},
		{
			filedate: '2017-05-03',
			value: 0
		},
		{
			filedate: '2017-08-02',
			value: 0
		},
		{
			filedate: '2017-11-03',
			value: 0.494368
		},
		{
			filedate: '2018-02-02',
			value: 0.196739
		},
		{
			filedate: '2018-05-02',
			value: 0
		},
		{
			filedate: '2018-08-01',
			value: 0
		},
		{
			filedate: '2018-11-05',
			value: 0.589574
		},
		{
			filedate: '2019-01-30',
			value: 0.189522
		},
		{
			filedate: '2019-05-01',
			value: 0
		},
		{
			filedate: '2019-07-31',
			value: 0
		},
		{
			filedate: '2019-10-31',
			value: 0.426185
		},
		{
			filedate: '2020-01-29',
			value: 0.18695
		},
		{
			filedate: '2020-05-01',
			value: 0
		},
		{
			filedate: '2020-07-31',
			value: 0
		},
		{
			filedate: '2020-10-30',
			value: 0.561355
		},
		{
			filedate: '2021-01-28',
			value: 0.251535
		},
		{
			filedate: '2021-04-29',
			value: 0
		},
		{
			filedate: '2021-07-28',
			value: 0
		},
		{
			filedate: '2021-10-29',
			value: 0.771589
		},
		{
			filedate: '2022-01-28',
			value: 0.306659
		}
	]
};

const debtData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.462235
		},
		{
			filedate: '2010-04-21',
			value: 0.310374
		},
		{
			filedate: '2010-07-21',
			value: 0.333936
		},
		{
			filedate: '2010-10-27',
			value: 0.364338
		},
		{
			filedate: '2011-01-19',
			value: 0.369786
		},
		{
			filedate: '2011-04-21',
			value: 0.352219
		},
		{
			filedate: '2011-07-20',
			value: 0.350466
		},
		{
			filedate: '2011-10-26',
			value: 0.341632
		},
		{
			filedate: '2012-01-25',
			value: 0.350639
		},
		{
			filedate: '2012-04-25',
			value: 0.320908
		},
		{
			filedate: '2012-07-25',
			value: 0.314004
		},
		{
			filedate: '2012-10-31',
			value: 0.328596
		},
		{
			filedate: '2013-01-24',
			value: 0.350567
		},
		{
			filedate: '2013-04-24',
			value: 0.304262
		},
		{
			filedate: '2013-07-24',
			value: 0.382786
		},
		{
			filedate: '2013-10-30',
			value: 0.403145
		},
		{
			filedate: '2014-01-28',
			value: 0.424098
		},
		{
			filedate: '2014-04-24',
			value: 0.416576
		},
		{
			filedate: '2014-07-23',
			value: 0.456498
		},
		{
			filedate: '2014-10-27',
			value: 0.51886
		},
		{
			filedate: '2015-01-28',
			value: 0.529092
		},
		{
			filedate: '2015-04-28',
			value: 0.506091
		},
		{
			filedate: '2015-07-22',
			value: 0.539899
		},
		{
			filedate: '2015-10-28',
			value: 0.58911
		},
		{
			filedate: '2016-01-27',
			value: 0.562653
		},
		{
			filedate: '2016-04-27',
			value: 0.57266
		},
		{
			filedate: '2016-07-27',
			value: 0.585929
		},
		{
			filedate: '2016-10-26',
			value: 0.601322
		},
		{
			filedate: '2017-02-01',
			value: 0.6002
		},
		{
			filedate: '2017-05-03',
			value: 0.599195
		},
		{
			filedate: '2017-08-02',
			value: 0.616352
		},
		{
			filedate: '2017-11-03',
			value: 0.642845
		},
		{
			filedate: '2018-02-02',
			value: 0.655356
		},
		{
			filedate: '2018-05-02',
			value: 0.654756
		},
		{
			filedate: '2018-08-01',
			value: 0.670819
		},
		{
			filedate: '2018-11-05',
			value: 0.707029
		},
		{
			filedate: '2019-01-30',
			value: 0.684544
		},
		{
			filedate: '2019-05-01',
			value: 0.690466
		},
		{
			filedate: '2019-07-31',
			value: 0.700669
		},
		{
			filedate: '2019-10-31',
			value: 0.732692
		},
		{
			filedate: '2020-01-29',
			value: 0.737151
		},
		{
			filedate: '2020-05-01',
			value: 0.755228
		},
		{
			filedate: '2020-07-31',
			value: 0.772228
		},
		{
			filedate: '2020-10-30',
			value: 0.798267
		},
		{
			filedate: '2021-01-28',
			value: 0.812955
		},
		{
			filedate: '2021-04-29',
			value: 0.79482
		},
		{
			filedate: '2021-07-28',
			value: 0.805118
		},
		{
			filedate: '2021-10-29',
			value: 0.820257
		},
		{
			filedate: '2022-01-28',
			value: 0.811297
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.462235
		},
		{
			filedate: '2010-04-21',
			value: 0.310374
		},
		{
			filedate: '2010-07-21',
			value: 0.333936
		},
		{
			filedate: '2010-10-27',
			value: 0.364338
		},
		{
			filedate: '2011-01-19',
			value: 0.369786
		},
		{
			filedate: '2011-04-21',
			value: 0.352219
		},
		{
			filedate: '2011-07-20',
			value: 0.350466
		},
		{
			filedate: '2011-10-26',
			value: 0.341632
		},
		{
			filedate: '2012-01-25',
			value: 0.350639
		},
		{
			filedate: '2012-04-25',
			value: 0.320908
		},
		{
			filedate: '2012-07-25',
			value: 0.314004
		},
		{
			filedate: '2012-10-31',
			value: 0.328596
		},
		{
			filedate: '2013-01-24',
			value: 0.350567
		},
		{
			filedate: '2013-04-24',
			value: 0.304262
		},
		{
			filedate: '2013-07-24',
			value: 0.382786
		},
		{
			filedate: '2013-10-30',
			value: 0.403145
		},
		{
			filedate: '2014-01-28',
			value: 0.424098
		},
		{
			filedate: '2014-04-24',
			value: 0.416576
		},
		{
			filedate: '2014-07-23',
			value: 0.456498
		},
		{
			filedate: '2014-10-27',
			value: 0.51886
		},
		{
			filedate: '2015-01-28',
			value: 0.529092
		},
		{
			filedate: '2015-04-28',
			value: 0.506091
		},
		{
			filedate: '2015-07-22',
			value: 0.539899
		},
		{
			filedate: '2015-10-28',
			value: 0.58911
		},
		{
			filedate: '2016-01-27',
			value: 0.562653
		},
		{
			filedate: '2016-04-27',
			value: 0.57266
		},
		{
			filedate: '2016-07-27',
			value: 0.585929
		},
		{
			filedate: '2016-10-26',
			value: 0.601322
		},
		{
			filedate: '2017-02-01',
			value: 0.6002
		},
		{
			filedate: '2017-05-03',
			value: 0.599195
		},
		{
			filedate: '2017-08-02',
			value: 0.616352
		},
		{
			filedate: '2017-11-03',
			value: 0.642845
		},
		{
			filedate: '2018-02-02',
			value: 0.655356
		},
		{
			filedate: '2018-05-02',
			value: 0.654756
		},
		{
			filedate: '2018-08-01',
			value: 0.670819
		},
		{
			filedate: '2018-11-05',
			value: 0.707029
		},
		{
			filedate: '2019-01-30',
			value: 0.684544
		},
		{
			filedate: '2019-05-01',
			value: 0.690466
		},
		{
			filedate: '2019-07-31',
			value: 0.700669
		},
		{
			filedate: '2019-10-31',
			value: 0.732692
		},
		{
			filedate: '2020-01-29',
			value: 0.737151
		},
		{
			filedate: '2020-05-01',
			value: 0.755228
		},
		{
			filedate: '2020-07-31',
			value: 0.772228
		},
		{
			filedate: '2020-10-30',
			value: 0.798267
		},
		{
			filedate: '2021-01-28',
			value: 0.812955
		},
		{
			filedate: '2021-04-29',
			value: 0.79482
		},
		{
			filedate: '2021-07-28',
			value: 0.805118
		},
		{
			filedate: '2021-10-29',
			value: 0.820257
		},
		{
			filedate: '2022-01-28',
			value: 0.811297
		}
	]
};

const debtEquityData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.859549
		},
		{
			filedate: '2010-04-21',
			value: 0.450061
		},
		{
			filedate: '2010-07-21',
			value: 0.501357
		},
		{
			filedate: '2010-10-27',
			value: 0.573162
		},
		{
			filedate: '2011-01-19',
			value: 0.586763
		},
		{
			filedate: '2011-04-21',
			value: 0.543732
		},
		{
			filedate: '2011-07-20',
			value: 0.539564
		},
		{
			filedate: '2011-10-26',
			value: 0.518906
		},
		{
			filedate: '2012-01-25',
			value: 0.539976
		},
		{
			filedate: '2012-04-25',
			value: 0.472556
		},
		{
			filedate: '2012-07-25',
			value: 0.457735
		},
		{
			filedate: '2012-10-31',
			value: 0.489417
		},
		{
			filedate: '2013-01-24',
			value: 0.539805
		},
		{
			filedate: '2013-04-24',
			value: 0.437324
		},
		{
			filedate: '2013-07-24',
			value: 0.620183
		},
		{
			filedate: '2013-10-30',
			value: 0.675449
		},
		{
			filedate: '2014-01-28',
			value: 0.736405
		},
		{
			filedate: '2014-04-24',
			value: 0.714018
		},
		{
			filedate: '2014-07-23',
			value: 0.839921
		},
		{
			filedate: '2014-10-27',
			value: 1.0784
		},
		{
			filedate: '2015-01-28',
			value: 1.12356
		},
		{
			filedate: '2015-04-28',
			value: 1.02467
		},
		{
			filedate: '2015-07-22',
			value: 1.17344
		},
		{
			filedate: '2015-10-28',
			value: 1.43374
		},
		{
			filedate: '2016-01-27',
			value: 1.28651
		},
		{
			filedate: '2016-04-27',
			value: 1.34006
		},
		{
			filedate: '2016-07-27',
			value: 1.41504
		},
		{
			filedate: '2016-10-26',
			value: 1.50829
		},
		{
			filedate: '2017-02-01',
			value: 1.50125
		},
		{
			filedate: '2017-05-03',
			value: 1.49498
		},
		{
			filedate: '2017-08-02',
			value: 1.60655
		},
		{
			filedate: '2017-11-03',
			value: 1.79991
		},
		{
			filedate: '2018-02-02',
			value: 1.90155
		},
		{
			filedate: '2018-05-02',
			value: 1.8965
		},
		{
			filedate: '2018-08-01',
			value: 2.03784
		},
		{
			filedate: '2018-11-05',
			value: 2.4133
		},
		{
			filedate: '2019-01-30',
			value: 2.17001
		},
		{
			filedate: '2019-05-01',
			value: 2.23066
		},
		{
			filedate: '2019-07-31',
			value: 2.34079
		},
		{
			filedate: '2019-10-31',
			value: 2.741
		},
		{
			filedate: '2020-01-29',
			value: 2.80447
		},
		{
			filedate: '2020-05-01',
			value: 3.08543
		},
		{
			filedate: '2020-07-31',
			value: 3.39036
		},
		{
			filedate: '2020-10-30',
			value: 3.95704
		},
		{
			filedate: '2021-01-28',
			value: 4.34631
		},
		{
			filedate: '2021-04-29',
			value: 3.87378
		},
		{
			filedate: '2021-07-28',
			value: 4.1313
		},
		{
			filedate: '2021-10-29',
			value: 4.56351
		},
		{
			filedate: '2022-01-28',
			value: 4.29932
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.859549
		},
		{
			filedate: '2010-04-21',
			value: 0.450061
		},
		{
			filedate: '2010-07-21',
			value: 0.501357
		},
		{
			filedate: '2010-10-27',
			value: 0.573162
		},
		{
			filedate: '2011-01-19',
			value: 0.586763
		},
		{
			filedate: '2011-04-21',
			value: 0.543732
		},
		{
			filedate: '2011-07-20',
			value: 0.539564
		},
		{
			filedate: '2011-10-26',
			value: 0.518906
		},
		{
			filedate: '2012-01-25',
			value: 0.539976
		},
		{
			filedate: '2012-04-25',
			value: 0.472556
		},
		{
			filedate: '2012-07-25',
			value: 0.457735
		},
		{
			filedate: '2012-10-31',
			value: 0.489417
		},
		{
			filedate: '2013-01-24',
			value: 0.539805
		},
		{
			filedate: '2013-04-24',
			value: 0.437324
		},
		{
			filedate: '2013-07-24',
			value: 0.620183
		},
		{
			filedate: '2013-10-30',
			value: 0.675449
		},
		{
			filedate: '2014-01-28',
			value: 0.736405
		},
		{
			filedate: '2014-04-24',
			value: 0.714018
		},
		{
			filedate: '2014-07-23',
			value: 0.839921
		},
		{
			filedate: '2014-10-27',
			value: 1.0784
		},
		{
			filedate: '2015-01-28',
			value: 1.12356
		},
		{
			filedate: '2015-04-28',
			value: 1.02467
		},
		{
			filedate: '2015-07-22',
			value: 1.17344
		},
		{
			filedate: '2015-10-28',
			value: 1.43374
		},
		{
			filedate: '2016-01-27',
			value: 1.28651
		},
		{
			filedate: '2016-04-27',
			value: 1.34006
		},
		{
			filedate: '2016-07-27',
			value: 1.41504
		},
		{
			filedate: '2016-10-26',
			value: 1.50829
		},
		{
			filedate: '2017-02-01',
			value: 1.50125
		},
		{
			filedate: '2017-05-03',
			value: 1.49498
		},
		{
			filedate: '2017-08-02',
			value: 1.60655
		},
		{
			filedate: '2017-11-03',
			value: 1.79991
		},
		{
			filedate: '2018-02-02',
			value: 1.90155
		},
		{
			filedate: '2018-05-02',
			value: 1.8965
		},
		{
			filedate: '2018-08-01',
			value: 2.03784
		},
		{
			filedate: '2018-11-05',
			value: 2.4133
		},
		{
			filedate: '2019-01-30',
			value: 2.17001
		},
		{
			filedate: '2019-05-01',
			value: 2.23066
		},
		{
			filedate: '2019-07-31',
			value: 2.34079
		},
		{
			filedate: '2019-10-31',
			value: 2.741
		},
		{
			filedate: '2020-01-29',
			value: 2.80447
		},
		{
			filedate: '2020-05-01',
			value: 3.08543
		},
		{
			filedate: '2020-07-31',
			value: 3.39036
		},
		{
			filedate: '2020-10-30',
			value: 3.95704
		},
		{
			filedate: '2021-01-28',
			value: 4.34631
		},
		{
			filedate: '2021-04-29',
			value: 3.87378
		},
		{
			filedate: '2021-07-28',
			value: 4.1313
		},
		{
			filedate: '2021-10-29',
			value: 4.56351
		},
		{
			filedate: '2022-01-28',
			value: 4.29932
		}
	]
};

const interestData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: null
		},
		{
			filedate: '2010-04-21',
			value: null
		},
		{
			filedate: '2010-07-21',
			value: null
		},
		{
			filedate: '2010-10-27',
			value: null
		},
		{
			filedate: '2011-01-19',
			value: null
		},
		{
			filedate: '2011-04-21',
			value: null
		},
		{
			filedate: '2011-07-20',
			value: null
		},
		{
			filedate: '2011-10-26',
			value: null
		},
		{
			filedate: '2012-01-25',
			value: null
		},
		{
			filedate: '2012-04-25',
			value: null
		},
		{
			filedate: '2012-07-25',
			value: null
		},
		{
			filedate: '2012-10-31',
			value: null
		},
		{
			filedate: '2013-01-24',
			value: null
		},
		{
			filedate: '2013-04-24',
			value: null
		},
		{
			filedate: '2013-07-24',
			value: null
		},
		{
			filedate: '2013-10-30',
			value: 360.287
		},
		{
			filedate: '2014-01-28',
			value: 207.893
		},
		{
			filedate: '2014-04-24',
			value: 159.918
		},
		{
			filedate: '2014-07-23',
			value: 102.82
		},
		{
			filedate: '2014-10-27',
			value: 136.727
		},
		{
			filedate: '2015-01-28',
			value: 185.084
		},
		{
			filedate: '2015-04-28',
			value: 112.135
		},
		{
			filedate: '2015-07-22',
			value: 70.0647
		},
		{
			filedate: '2015-10-28',
			value: 97.176
		},
		{
			filedate: '2016-01-27',
			value: 87.5761
		},
		{
			filedate: '2016-04-27',
			value: 43.5732
		},
		{
			filedate: '2016-07-27',
			value: 24.7066
		},
		{
			filedate: '2016-10-26',
			value: 41.2253
		},
		{
			filedate: '2017-02-01',
			value: 44.4933
		},
		{
			filedate: '2017-05-03',
			value: 26.5981
		},
		{
			filedate: '2017-08-02',
			value: 17.887
		},
		{
			filedate: '2017-11-03',
			value: 26.4072
		},
		{
			filedate: '2018-02-02',
			value: 35.7956
		},
		{
			filedate: '2018-05-02',
			value: 20.0682
		},
		{
			filedate: '2018-08-01',
			value: 14.9078
		},
		{
			filedate: '2018-11-05',
			value: 21.8821
		},
		{
			filedate: '2019-01-30',
			value: 26.2315
		},
		{
			filedate: '2019-05-01',
			value: 13.2822
		},
		{
			filedate: '2019-07-31',
			value: 13.3303
		},
		{
			filedate: '2019-10-31',
			value: 17.8775
		},
		{
			filedate: '2020-01-29',
			value: 32.572
		},
		{
			filedate: '2020-05-01',
			value: 16.9789
		},
		{
			filedate: '2020-07-31',
			value: 18.7819
		},
		{
			filedate: '2020-10-30',
			value: 23.0727
		},
		{
			filedate: '2021-01-28',
			value: 52.5611
		},
		{
			filedate: '2021-04-29',
			value: 41.0493
		},
		{
			filedate: '2021-07-28',
			value: 36.2797
		},
		{
			filedate: '2021-10-29',
			value: 41.1905
		},
		{
			filedate: '2022-01-28',
			value: 59.781
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: null
		},
		{
			filedate: '2010-04-21',
			value: null
		},
		{
			filedate: '2010-07-21',
			value: null
		},
		{
			filedate: '2010-10-27',
			value: null
		},
		{
			filedate: '2011-01-19',
			value: null
		},
		{
			filedate: '2011-04-21',
			value: null
		},
		{
			filedate: '2011-07-20',
			value: null
		},
		{
			filedate: '2011-10-26',
			value: null
		},
		{
			filedate: '2012-01-25',
			value: null
		},
		{
			filedate: '2012-04-25',
			value: null
		},
		{
			filedate: '2012-07-25',
			value: null
		},
		{
			filedate: '2012-10-31',
			value: null
		},
		{
			filedate: '2013-01-24',
			value: null
		},
		{
			filedate: '2013-04-24',
			value: null
		},
		{
			filedate: '2013-07-24',
			value: null
		},
		{
			filedate: '2013-10-30',
			value: 360.287
		},
		{
			filedate: '2014-01-28',
			value: 207.893
		},
		{
			filedate: '2014-04-24',
			value: 159.918
		},
		{
			filedate: '2014-07-23',
			value: 102.82
		},
		{
			filedate: '2014-10-27',
			value: 136.727
		},
		{
			filedate: '2015-01-28',
			value: 185.084
		},
		{
			filedate: '2015-04-28',
			value: 112.135
		},
		{
			filedate: '2015-07-22',
			value: 70.0647
		},
		{
			filedate: '2015-10-28',
			value: 97.176
		},
		{
			filedate: '2016-01-27',
			value: 87.5761
		},
		{
			filedate: '2016-04-27',
			value: 43.5732
		},
		{
			filedate: '2016-07-27',
			value: 24.7066
		},
		{
			filedate: '2016-10-26',
			value: 41.2253
		},
		{
			filedate: '2017-02-01',
			value: 44.4933
		},
		{
			filedate: '2017-05-03',
			value: 26.5981
		},
		{
			filedate: '2017-08-02',
			value: 17.887
		},
		{
			filedate: '2017-11-03',
			value: 26.4072
		},
		{
			filedate: '2018-02-02',
			value: 35.7956
		},
		{
			filedate: '2018-05-02',
			value: 20.0682
		},
		{
			filedate: '2018-08-01',
			value: 14.9078
		},
		{
			filedate: '2018-11-05',
			value: 21.8821
		},
		{
			filedate: '2019-01-30',
			value: 26.2315
		},
		{
			filedate: '2019-05-01',
			value: 13.2822
		},
		{
			filedate: '2019-07-31',
			value: 13.3303
		},
		{
			filedate: '2019-10-31',
			value: 17.8775
		},
		{
			filedate: '2020-01-29',
			value: 32.572
		},
		{
			filedate: '2020-05-01',
			value: 16.9789
		},
		{
			filedate: '2020-07-31',
			value: 18.7819
		},
		{
			filedate: '2020-10-30',
			value: 23.0727
		},
		{
			filedate: '2021-01-28',
			value: 52.5611
		},
		{
			filedate: '2021-04-29',
			value: 41.0493
		},
		{
			filedate: '2021-07-28',
			value: 36.2797
		},
		{
			filedate: '2021-10-29',
			value: 41.1905
		},
		{
			filedate: '2022-01-28',
			value: 59.781
		}
	]
};

const assetData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.173182
		},
		{
			filedate: '2010-04-21',
			value: 0.236588
		},
		{
			filedate: '2010-07-21',
			value: 0.242565
		},
		{
			filedate: '2010-10-27',
			value: 0.86755
		},
		{
			filedate: '2011-01-19',
			value: 0.308282
		},
		{
			filedate: '2011-04-21',
			value: 0.259915
		},
		{
			filedate: '2011-07-20',
			value: 0.267624
		},
		{
			filedate: '2011-10-26',
			value: 0.930206
		},
		{
			filedate: '2012-01-25',
			value: 0.334098
		},
		{
			filedate: '2012-04-25',
			value: 0.259623
		},
		{
			filedate: '2012-07-25',
			value: 0.215002
		},
		{
			filedate: '2012-10-31',
			value: 0.888927
		},
		{
			filedate: '2013-01-24',
			value: 0.277998
		},
		{
			filedate: '2013-04-24',
			value: 0.2239
		},
		{
			filedate: '2013-07-24',
			value: 0.176742
		},
		{
			filedate: '2013-10-30',
			value: 0.825652
		},
		{
			filedate: '2014-01-28',
			value: 0.255764
		},
		{
			filedate: '2014-04-24',
			value: 0.221594
		},
		{
			filedate: '2014-07-23',
			value: 0.168219
		},
		{
			filedate: '2014-10-27',
			value: 0.788457
		},
		{
			filedate: '2015-01-28',
			value: 0.284844
		},
		{
			filedate: '2015-04-28',
			value: 0.222095
		},
		{
			filedate: '2015-07-22',
			value: 0.181603
		},
		{
			filedate: '2015-10-28',
			value: 0.804585
		},
		{
			filedate: '2016-01-27',
			value: 0.258698
		},
		{
			filedate: '2016-04-27',
			value: 0.16561
		},
		{
			filedate: '2016-07-27',
			value: 0.138605
		},
		{
			filedate: '2016-10-26',
			value: 0.67034
		},
		{
			filedate: '2017-02-01',
			value: 0.236609
		},
		{
			filedate: '2017-05-03',
			value: 0.158119
		},
		{
			filedate: '2017-08-02',
			value: 0.131551
		},
		{
			filedate: '2017-11-03',
			value: 0.610771
		},
		{
			filedate: '2018-02-02',
			value: 0.217046
		},
		{
			filedate: '2018-05-02',
			value: 0.166358
		},
		{
			filedate: '2018-08-01',
			value: 0.152536
		},
		{
			filedate: '2018-11-05',
			value: 0.726215
		},
		{
			filedate: '2019-01-30',
			value: 0.225597
		},
		{
			filedate: '2019-05-01',
			value: 0.169635
		},
		{
			filedate: '2019-07-31',
			value: 0.166985
		},
		{
			filedate: '2019-10-31',
			value: 0.768572
		},
		{
			filedate: '2020-01-29',
			value: 0.269566
		},
		{
			filedate: '2020-05-01',
			value: 0.182001
		},
		{
			filedate: '2020-07-31',
			value: 0.188077
		},
		{
			filedate: '2020-10-30',
			value: 0.847562
		},
		{
			filedate: '2021-01-28',
			value: 0.314751
		},
		{
			filedate: '2021-04-29',
			value: 0.265703
		},
		{
			filedate: '2021-07-28',
			value: 0.246889
		},
		{
			filedate: '2021-10-29',
			value: 1.04221
		},
		{
			filedate: '2022-01-28',
			value: 0.325152
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.173182
		},
		{
			filedate: '2010-04-21',
			value: 0.236588
		},
		{
			filedate: '2010-07-21',
			value: 0.242565
		},
		{
			filedate: '2010-10-27',
			value: 0.86755
		},
		{
			filedate: '2011-01-19',
			value: 0.308282
		},
		{
			filedate: '2011-04-21',
			value: 0.259915
		},
		{
			filedate: '2011-07-20',
			value: 0.267624
		},
		{
			filedate: '2011-10-26',
			value: 0.930206
		},
		{
			filedate: '2012-01-25',
			value: 0.334098
		},
		{
			filedate: '2012-04-25',
			value: 0.259623
		},
		{
			filedate: '2012-07-25',
			value: 0.215002
		},
		{
			filedate: '2012-10-31',
			value: 0.888927
		},
		{
			filedate: '2013-01-24',
			value: 0.277998
		},
		{
			filedate: '2013-04-24',
			value: 0.2239
		},
		{
			filedate: '2013-07-24',
			value: 0.176742
		},
		{
			filedate: '2013-10-30',
			value: 0.825652
		},
		{
			filedate: '2014-01-28',
			value: 0.255764
		},
		{
			filedate: '2014-04-24',
			value: 0.221594
		},
		{
			filedate: '2014-07-23',
			value: 0.168219
		},
		{
			filedate: '2014-10-27',
			value: 0.788457
		},
		{
			filedate: '2015-01-28',
			value: 0.284844
		},
		{
			filedate: '2015-04-28',
			value: 0.222095
		},
		{
			filedate: '2015-07-22',
			value: 0.181603
		},
		{
			filedate: '2015-10-28',
			value: 0.804585
		},
		{
			filedate: '2016-01-27',
			value: 0.258698
		},
		{
			filedate: '2016-04-27',
			value: 0.16561
		},
		{
			filedate: '2016-07-27',
			value: 0.138605
		},
		{
			filedate: '2016-10-26',
			value: 0.67034
		},
		{
			filedate: '2017-02-01',
			value: 0.236609
		},
		{
			filedate: '2017-05-03',
			value: 0.158119
		},
		{
			filedate: '2017-08-02',
			value: 0.131551
		},
		{
			filedate: '2017-11-03',
			value: 0.610771
		},
		{
			filedate: '2018-02-02',
			value: 0.217046
		},
		{
			filedate: '2018-05-02',
			value: 0.166358
		},
		{
			filedate: '2018-08-01',
			value: 0.152536
		},
		{
			filedate: '2018-11-05',
			value: 0.726215
		},
		{
			filedate: '2019-01-30',
			value: 0.225597
		},
		{
			filedate: '2019-05-01',
			value: 0.169635
		},
		{
			filedate: '2019-07-31',
			value: 0.166985
		},
		{
			filedate: '2019-10-31',
			value: 0.768572
		},
		{
			filedate: '2020-01-29',
			value: 0.269566
		},
		{
			filedate: '2020-05-01',
			value: 0.182001
		},
		{
			filedate: '2020-07-31',
			value: 0.188077
		},
		{
			filedate: '2020-10-30',
			value: 0.847562
		},
		{
			filedate: '2021-01-28',
			value: 0.314751
		},
		{
			filedate: '2021-04-29',
			value: 0.265703
		},
		{
			filedate: '2021-07-28',
			value: 0.246889
		},
		{
			filedate: '2021-10-29',
			value: 1.04221
		},
		{
			filedate: '2022-01-28',
			value: 0.325152
		}
	]
};

const grossMarginData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.3626
		},
		{
			filedate: '2010-04-21',
			value: 0.416698
		},
		{
			filedate: '2010-07-21',
			value: 0.390828
		},
		{
			filedate: '2010-10-27',
			value: 0.393775
		},
		{
			filedate: '2011-01-19',
			value: 0.385102
		},
		{
			filedate: '2011-04-21',
			value: 0.414238
		},
		{
			filedate: '2011-07-20',
			value: 0.417276
		},
		{
			filedate: '2011-10-26',
			value: 0.404789
		},
		{
			filedate: '2012-01-25',
			value: 0.446831
		},
		{
			filedate: '2012-04-25',
			value: 0.473741
		},
		{
			filedate: '2012-07-25',
			value: 0.428119
		},
		{
			filedate: '2012-10-31',
			value: 0.438712
		},
		{
			filedate: '2013-01-24',
			value: 0.386337
		},
		{
			filedate: '2013-04-24',
			value: 0.374951
		},
		{
			filedate: '2013-07-24',
			value: 0.368712
		},
		{
			filedate: '2013-10-30',
			value: 0.376245
		},
		{
			filedate: '2014-01-28',
			value: 0.37931
		},
		{
			filedate: '2014-04-24',
			value: 0.393178
		},
		{
			filedate: '2014-07-23',
			value: 0.393647
		},
		{
			filedate: '2014-10-27',
			value: 0.38588
		},
		{
			filedate: '2015-01-28',
			value: 0.398678
		},
		{
			filedate: '2015-04-28',
			value: 0.407792
		},
		{
			filedate: '2015-07-22',
			value: 0.396754
		},
		{
			filedate: '2015-10-28',
			value: 0.400599
		},
		{
			filedate: '2016-01-27',
			value: 0.400978
		},
		{
			filedate: '2016-04-27',
			value: 0.39403
		},
		{
			filedate: '2016-07-27',
			value: 0.380235
		},
		{
			filedate: '2016-10-26',
			value: 0.39076
		},
		{
			filedate: '2017-02-01',
			value: 0.385139
		},
		{
			filedate: '2017-05-03',
			value: 0.389273
		},
		{
			filedate: '2017-08-02',
			value: 0.38513
		},
		{
			filedate: '2017-11-03',
			value: 0.384699
		},
		{
			filedate: '2018-02-02',
			value: 0.384085
		},
		{
			filedate: '2018-05-02',
			value: 0.383107
		},
		{
			filedate: '2018-08-01',
			value: 0.383385
		},
		{
			filedate: '2018-11-05',
			value: 0.383437
		},
		{
			filedate: '2019-01-30',
			value: 0.379919
		},
		{
			filedate: '2019-05-01',
			value: 0.376127
		},
		{
			filedate: '2019-07-31',
			value: 0.375904
		},
		{
			filedate: '2019-10-31',
			value: 0.378178
		},
		{
			filedate: '2020-01-29',
			value: 0.383548
		},
		{
			filedate: '2020-05-01',
			value: 0.383619
		},
		{
			filedate: '2020-07-31',
			value: 0.379995
		},
		{
			filedate: '2020-10-30',
			value: 0.382332
		},
		{
			filedate: '2021-01-28',
			value: 0.397778
		},
		{
			filedate: '2021-04-29',
			value: 0.425065
		},
		{
			filedate: '2021-07-28',
			value: 0.432927
		},
		{
			filedate: '2021-10-29',
			value: 0.417794
		},
		{
			filedate: '2022-01-28',
			value: 0.437638
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.3626
		},
		{
			filedate: '2010-04-21',
			value: 0.416698
		},
		{
			filedate: '2010-07-21',
			value: 0.390828
		},
		{
			filedate: '2010-10-27',
			value: 0.393775
		},
		{
			filedate: '2011-01-19',
			value: 0.385102
		},
		{
			filedate: '2011-04-21',
			value: 0.414238
		},
		{
			filedate: '2011-07-20',
			value: 0.417276
		},
		{
			filedate: '2011-10-26',
			value: 0.404789
		},
		{
			filedate: '2012-01-25',
			value: 0.446831
		},
		{
			filedate: '2012-04-25',
			value: 0.473741
		},
		{
			filedate: '2012-07-25',
			value: 0.428119
		},
		{
			filedate: '2012-10-31',
			value: 0.438712
		},
		{
			filedate: '2013-01-24',
			value: 0.386337
		},
		{
			filedate: '2013-04-24',
			value: 0.374951
		},
		{
			filedate: '2013-07-24',
			value: 0.368712
		},
		{
			filedate: '2013-10-30',
			value: 0.376245
		},
		{
			filedate: '2014-01-28',
			value: 0.37931
		},
		{
			filedate: '2014-04-24',
			value: 0.393178
		},
		{
			filedate: '2014-07-23',
			value: 0.393647
		},
		{
			filedate: '2014-10-27',
			value: 0.38588
		},
		{
			filedate: '2015-01-28',
			value: 0.398678
		},
		{
			filedate: '2015-04-28',
			value: 0.407792
		},
		{
			filedate: '2015-07-22',
			value: 0.396754
		},
		{
			filedate: '2015-10-28',
			value: 0.400599
		},
		{
			filedate: '2016-01-27',
			value: 0.400978
		},
		{
			filedate: '2016-04-27',
			value: 0.39403
		},
		{
			filedate: '2016-07-27',
			value: 0.380235
		},
		{
			filedate: '2016-10-26',
			value: 0.39076
		},
		{
			filedate: '2017-02-01',
			value: 0.385139
		},
		{
			filedate: '2017-05-03',
			value: 0.389273
		},
		{
			filedate: '2017-08-02',
			value: 0.38513
		},
		{
			filedate: '2017-11-03',
			value: 0.384699
		},
		{
			filedate: '2018-02-02',
			value: 0.384085
		},
		{
			filedate: '2018-05-02',
			value: 0.383107
		},
		{
			filedate: '2018-08-01',
			value: 0.383385
		},
		{
			filedate: '2018-11-05',
			value: 0.383437
		},
		{
			filedate: '2019-01-30',
			value: 0.379919
		},
		{
			filedate: '2019-05-01',
			value: 0.376127
		},
		{
			filedate: '2019-07-31',
			value: 0.375904
		},
		{
			filedate: '2019-10-31',
			value: 0.378178
		},
		{
			filedate: '2020-01-29',
			value: 0.383548
		},
		{
			filedate: '2020-05-01',
			value: 0.383619
		},
		{
			filedate: '2020-07-31',
			value: 0.379995
		},
		{
			filedate: '2020-10-30',
			value: 0.382332
		},
		{
			filedate: '2021-01-28',
			value: 0.397778
		},
		{
			filedate: '2021-04-29',
			value: 0.425065
		},
		{
			filedate: '2021-07-28',
			value: 0.432927
		},
		{
			filedate: '2021-10-29',
			value: 0.417794
		},
		{
			filedate: '2022-01-28',
			value: 0.437638
		}
	]
};

const operMarginData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.200552
		},
		{
			filedate: '2010-04-21',
			value: 0.294763
		},
		{
			filedate: '2010-07-21',
			value: 0.269682
		},
		{
			filedate: '2010-10-27',
			value: 0.28187
		},
		{
			filedate: '2011-01-19',
			value: 0.292697
		},
		{
			filedate: '2011-04-21',
			value: 0.319212
		},
		{
			filedate: '2011-07-20',
			value: 0.32827
		},
		{
			filedate: '2011-10-26',
			value: 0.312151
		},
		{
			filedate: '2012-01-25',
			value: 0.374247
		},
		{
			filedate: '2012-04-25',
			value: 0.392589
		},
		{
			filedate: '2012-07-25',
			value: 0.33044
		},
		{
			filedate: '2012-10-31',
			value: 0.35296
		},
		{
			filedate: '2013-01-24',
			value: 0.31571
		},
		{
			filedate: '2013-04-24',
			value: 0.288008
		},
		{
			filedate: '2013-07-24',
			value: 0.260482
		},
		{
			filedate: '2013-10-30',
			value: 0.286695
		},
		{
			filedate: '2014-01-28',
			value: 0.303209
		},
		{
			filedate: '2014-04-24',
			value: 0.297792
		},
		{
			filedate: '2014-07-23',
			value: 0.274685
		},
		{
			filedate: '2014-10-27',
			value: 0.287223
		},
		{
			filedate: '2015-01-28',
			value: 0.325018
		},
		{
			filedate: '2015-04-28',
			value: 0.315084
		},
		{
			filedate: '2015-07-22',
			value: 0.283903
		},
		{
			filedate: '2015-10-28',
			value: 0.304773
		},
		{
			filedate: '2016-01-27',
			value: 0.318576
		},
		{
			filedate: '2016-04-27',
			value: 0.276658
		},
		{
			filedate: '2016-07-27',
			value: 0.238562
		},
		{
			filedate: '2016-10-26',
			value: 0.278354
		},
		{
			filedate: '2017-02-01',
			value: 0.298133
		},
		{
			filedate: '2017-05-03',
			value: 0.266504
		},
		{
			filedate: '2017-08-02',
			value: 0.237139
		},
		{
			filedate: '2017-11-03',
			value: 0.267604
		},
		{
			filedate: '2018-02-02',
			value: 0.297577
		},
		{
			filedate: '2018-05-02',
			value: 0.259973
		},
		{
			filedate: '2018-08-01',
			value: 0.236778
		},
		{
			filedate: '2018-11-05',
			value: 0.26694
		},
		{
			filedate: '2019-01-30',
			value: 0.276907
		},
		{
			filedate: '2019-05-01',
			value: 0.231233
		},
		{
			filedate: '2019-07-31',
			value: 0.214537
		},
		{
			filedate: '2019-10-31',
			value: 0.24572
		},
		{
			filedate: '2020-01-29',
			value: 0.278472
		},
		{
			filedate: '2020-05-01',
			value: 0.220414
		},
		{
			filedate: '2020-07-31',
			value: 0.219335
		},
		{
			filedate: '2020-10-30',
			value: 0.241473
		},
		{
			filedate: '2021-01-28',
			value: 0.300918
		},
		{
			filedate: '2021-04-29',
			value: 0.307008
		},
		{
			filedate: '2021-07-28',
			value: 0.296264
		},
		{
			filedate: '2021-10-29',
			value: 0.297824
		},
		{
			filedate: '2022-01-28',
			value: 0.334729
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.200552
		},
		{
			filedate: '2010-04-21',
			value: 0.294763
		},
		{
			filedate: '2010-07-21',
			value: 0.269682
		},
		{
			filedate: '2010-10-27',
			value: 0.28187
		},
		{
			filedate: '2011-01-19',
			value: 0.292697
		},
		{
			filedate: '2011-04-21',
			value: 0.319212
		},
		{
			filedate: '2011-07-20',
			value: 0.32827
		},
		{
			filedate: '2011-10-26',
			value: 0.312151
		},
		{
			filedate: '2012-01-25',
			value: 0.374247
		},
		{
			filedate: '2012-04-25',
			value: 0.392589
		},
		{
			filedate: '2012-07-25',
			value: 0.33044
		},
		{
			filedate: '2012-10-31',
			value: 0.35296
		},
		{
			filedate: '2013-01-24',
			value: 0.31571
		},
		{
			filedate: '2013-04-24',
			value: 0.288008
		},
		{
			filedate: '2013-07-24',
			value: 0.260482
		},
		{
			filedate: '2013-10-30',
			value: 0.286695
		},
		{
			filedate: '2014-01-28',
			value: 0.303209
		},
		{
			filedate: '2014-04-24',
			value: 0.297792
		},
		{
			filedate: '2014-07-23',
			value: 0.274685
		},
		{
			filedate: '2014-10-27',
			value: 0.287223
		},
		{
			filedate: '2015-01-28',
			value: 0.325018
		},
		{
			filedate: '2015-04-28',
			value: 0.315084
		},
		{
			filedate: '2015-07-22',
			value: 0.283903
		},
		{
			filedate: '2015-10-28',
			value: 0.304773
		},
		{
			filedate: '2016-01-27',
			value: 0.318576
		},
		{
			filedate: '2016-04-27',
			value: 0.276658
		},
		{
			filedate: '2016-07-27',
			value: 0.238562
		},
		{
			filedate: '2016-10-26',
			value: 0.278354
		},
		{
			filedate: '2017-02-01',
			value: 0.298133
		},
		{
			filedate: '2017-05-03',
			value: 0.266504
		},
		{
			filedate: '2017-08-02',
			value: 0.237139
		},
		{
			filedate: '2017-11-03',
			value: 0.267604
		},
		{
			filedate: '2018-02-02',
			value: 0.297577
		},
		{
			filedate: '2018-05-02',
			value: 0.259973
		},
		{
			filedate: '2018-08-01',
			value: 0.236778
		},
		{
			filedate: '2018-11-05',
			value: 0.26694
		},
		{
			filedate: '2019-01-30',
			value: 0.276907
		},
		{
			filedate: '2019-05-01',
			value: 0.231233
		},
		{
			filedate: '2019-07-31',
			value: 0.214537
		},
		{
			filedate: '2019-10-31',
			value: 0.24572
		},
		{
			filedate: '2020-01-29',
			value: 0.278472
		},
		{
			filedate: '2020-05-01',
			value: 0.220414
		},
		{
			filedate: '2020-07-31',
			value: 0.219335
		},
		{
			filedate: '2020-10-30',
			value: 0.241473
		},
		{
			filedate: '2021-01-28',
			value: 0.300918
		},
		{
			filedate: '2021-04-29',
			value: 0.307008
		},
		{
			filedate: '2021-07-28',
			value: 0.296264
		},
		{
			filedate: '2021-10-29',
			value: 0.297824
		},
		{
			filedate: '2022-01-28',
			value: 0.334729
		}
	]
};

const returnOnEquityData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.0255297
		},
		{
			filedate: '2010-04-21',
			value: 0.0538759
		},
		{
			filedate: '2010-07-21',
			value: 0.0502588
		},
		{
			filedate: '2010-10-27',
			value: 0.186385
		},
		{
			filedate: '2011-01-19',
			value: 0.0692168
		},
		{
			filedate: '2011-04-21',
			value: 0.0630848
		},
		{
			filedate: '2011-07-20',
			value: 0.0684539
		},
		{
			filedate: '2011-10-26',
			value: 0.222753
		},
		{
			filedate: '2012-01-25',
			value: 0.0942018
		},
		{
			filedate: '2012-04-25',
			value: 0.0770005
		},
		{
			filedate: '2012-07-25',
			value: 0.0541695
		},
		{
			filedate: '2012-10-31',
			value: 0.237033
		},
		{
			filedate: '2013-01-24',
			value: 0.0666945
		},
		{
			filedate: '2013-04-24',
			value: 0.0490236
		},
		{
			filedate: '2013-07-24',
			value: 0.0345249
		},
		{
			filedate: '2013-10-30',
			value: 0.178923
		},
		{
			filedate: '2014-01-28',
			value: 0.0580503
		},
		{
			filedate: '2014-04-24',
			value: 0.0496289
		},
		{
			filedate: '2014-07-23',
			value: 0.0348193
		},
		{
			filedate: '2014-10-27',
			value: 0.17042
		},
		{
			filedate: '2015-01-28',
			value: 0.0688217
		},
		{
			filedate: '2015-04-28',
			value: 0.0519499
		},
		{
			filedate: '2015-07-22',
			value: 0.0390883
		},
		{
			filedate: '2015-10-28',
			value: 0.183814
		},
		{
			filedate: '2016-01-27',
			value: 0.0626049
		},
		{
			filedate: '2016-04-27',
			value: 0.0344474
		},
		{
			filedate: '2016-07-27',
			value: 0.0255103
		},
		{
			filedate: '2016-10-26',
			value: 0.142024
		},
		{
			filedate: '2017-02-01',
			value: 0.0540283
		},
		{
			filedate: '2017-05-03',
			value: 0.0329684
		},
		{
			filedate: '2017-08-02',
			value: 0.025254
		},
		{
			filedate: '2017-11-03',
			value: 0.128826
		},
		{
			filedate: '2018-02-02',
			value: 0.0493247
		},
		{
			filedate: '2018-05-02',
			value: 0.0376107
		},
		{
			filedate: '2018-08-01',
			value: 0.0329871
		},
		{
			filedate: '2018-11-05',
			value: 0.162775
		},
		{
			filedate: '2019-01-30',
			value: 0.0534225
		},
		{
			filedate: '2019-05-01',
			value: 0.0338043
		},
		{
			filedate: '2019-07-31',
			value: 0.0311694
		},
		{
			filedate: '2019-10-31',
			value: 0.16323
		},
		{
			filedate: '2020-01-29',
			value: 0.0652813
		},
		{
			filedate: '2020-05-01',
			value: 0.0351092
		},
		{
			filedate: '2020-07-31',
			value: 0.0354599
		},
		{
			filedate: '2020-10-30',
			value: 0.177256
		},
		{
			filedate: '2021-01-28',
			value: 0.0812164
		},
		{
			filedate: '2021-04-29',
			value: 0.0700858
		},
		{
			filedate: '2021-07-28',
			value: 0.0659229
		},
		{
			filedate: '2021-10-29',
			value: 0.269742
		},
		{
			filedate: '2022-01-28',
			value: 0.0908469
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.0255297
		},
		{
			filedate: '2010-04-21',
			value: 0.0538759
		},
		{
			filedate: '2010-07-21',
			value: 0.0502588
		},
		{
			filedate: '2010-10-27',
			value: 0.186385
		},
		{
			filedate: '2011-01-19',
			value: 0.0692168
		},
		{
			filedate: '2011-04-21',
			value: 0.0630848
		},
		{
			filedate: '2011-07-20',
			value: 0.0684539
		},
		{
			filedate: '2011-10-26',
			value: 0.222753
		},
		{
			filedate: '2012-01-25',
			value: 0.0942018
		},
		{
			filedate: '2012-04-25',
			value: 0.0770005
		},
		{
			filedate: '2012-07-25',
			value: 0.0541695
		},
		{
			filedate: '2012-10-31',
			value: 0.237033
		},
		{
			filedate: '2013-01-24',
			value: 0.0666945
		},
		{
			filedate: '2013-04-24',
			value: 0.0490236
		},
		{
			filedate: '2013-07-24',
			value: 0.0345249
		},
		{
			filedate: '2013-10-30',
			value: 0.178923
		},
		{
			filedate: '2014-01-28',
			value: 0.0580503
		},
		{
			filedate: '2014-04-24',
			value: 0.0496289
		},
		{
			filedate: '2014-07-23',
			value: 0.0348193
		},
		{
			filedate: '2014-10-27',
			value: 0.17042
		},
		{
			filedate: '2015-01-28',
			value: 0.0688217
		},
		{
			filedate: '2015-04-28',
			value: 0.0519499
		},
		{
			filedate: '2015-07-22',
			value: 0.0390883
		},
		{
			filedate: '2015-10-28',
			value: 0.183814
		},
		{
			filedate: '2016-01-27',
			value: 0.0626049
		},
		{
			filedate: '2016-04-27',
			value: 0.0344474
		},
		{
			filedate: '2016-07-27',
			value: 0.0255103
		},
		{
			filedate: '2016-10-26',
			value: 0.142024
		},
		{
			filedate: '2017-02-01',
			value: 0.0540283
		},
		{
			filedate: '2017-05-03',
			value: 0.0329684
		},
		{
			filedate: '2017-08-02',
			value: 0.025254
		},
		{
			filedate: '2017-11-03',
			value: 0.128826
		},
		{
			filedate: '2018-02-02',
			value: 0.0493247
		},
		{
			filedate: '2018-05-02',
			value: 0.0376107
		},
		{
			filedate: '2018-08-01',
			value: 0.0329871
		},
		{
			filedate: '2018-11-05',
			value: 0.162775
		},
		{
			filedate: '2019-01-30',
			value: 0.0534225
		},
		{
			filedate: '2019-05-01',
			value: 0.0338043
		},
		{
			filedate: '2019-07-31',
			value: 0.0311694
		},
		{
			filedate: '2019-10-31',
			value: 0.16323
		},
		{
			filedate: '2020-01-29',
			value: 0.0652813
		},
		{
			filedate: '2020-05-01',
			value: 0.0351092
		},
		{
			filedate: '2020-07-31',
			value: 0.0354599
		},
		{
			filedate: '2020-10-30',
			value: 0.177256
		},
		{
			filedate: '2021-01-28',
			value: 0.0812164
		},
		{
			filedate: '2021-04-29',
			value: 0.0700858
		},
		{
			filedate: '2021-07-28',
			value: 0.0659229
		},
		{
			filedate: '2021-10-29',
			value: 0.269742
		},
		{
			filedate: '2022-01-28',
			value: 0.0908469
		}
	]
};

const returnOnAssetsData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 0.0474737
		},
		{
			filedate: '2010-04-21',
			value: 0.0781234
		},
		{
			filedate: '2010-07-21',
			value: 0.0754564
		},
		{
			filedate: '2010-10-27',
			value: 0.293214
		},
		{
			filedate: '2011-01-19',
			value: 0.109831
		},
		{
			filedate: '2011-04-21',
			value: 0.097386
		},
		{
			filedate: '2011-07-20',
			value: 0.105389
		},
		{
			filedate: '2011-10-26',
			value: 0.338341
		},
		{
			filedate: '2012-01-25',
			value: 0.145069
		},
		{
			filedate: '2012-04-25',
			value: 0.113388
		},
		{
			filedate: '2012-07-25',
			value: 0.0789648
		},
		{
			filedate: '2012-10-31',
			value: 0.353041
		},
		{
			filedate: '2013-01-24',
			value: 0.102697
		},
		{
			filedate: '2013-04-24',
			value: 0.0704628
		},
		{
			filedate: '2013-07-24',
			value: 0.0559366
		},
		{
			filedate: '2013-10-30',
			value: 0.299776
		},
		{
			filedate: '2014-01-28',
			value: 0.100799
		},
		{
			filedate: '2014-04-24',
			value: 0.0850648
		},
		{
			filedate: '2014-07-23',
			value: 0.0640648
		},
		{
			filedate: '2014-10-27',
			value: 0.3542
		},
		{
			filedate: '2015-01-28',
			value: 0.146147
		},
		{
			filedate: '2015-04-28',
			value: 0.105181
		},
		{
			filedate: '2015-07-22',
			value: 0.0849559
		},
		{
			filedate: '2015-10-28',
			value: 0.447355
		},
		{
			filedate: '2016-01-27',
			value: 0.143147
		},
		{
			filedate: '2016-04-27',
			value: 0.0806089
		},
		{
			filedate: '2016-07-27',
			value: 0.0616085
		},
		{
			filedate: '2016-10-26',
			value: 0.356237
		},
		{
			filedate: '2017-02-01',
			value: 0.135139
		},
		{
			filedate: '2017-05-03',
			value: 0.0822556
		},
		{
			filedate: '2017-08-02',
			value: 0.0658259
		},
		{
			filedate: '2017-11-03',
			value: 0.360702
		},
		{
			filedate: '2018-02-02',
			value: 0.143118
		},
		{
			filedate: '2018-05-02',
			value: 0.108939
		},
		{
			filedate: '2018-08-01',
			value: 0.10021
		},
		{
			filedate: '2018-11-05',
			value: 0.555601
		},
		{
			filedate: '2019-01-30',
			value: 0.16935
		},
		{
			filedate: '2019-05-01',
			value: 0.10921
		},
		{
			filedate: '2019-07-31',
			value: 0.10413
		},
		{
			filedate: '2019-10-31',
			value: 0.610645
		},
		{
			filedate: '2020-01-29',
			value: 0.248361
		},
		{
			filedate: '2020-05-01',
			value: 0.143436
		},
		{
			filedate: '2020-07-31',
			value: 0.155682
		},
		{
			filedate: '2020-10-30',
			value: 0.878664
		},
		{
			filedate: '2021-01-28',
			value: 0.434208
		},
		{
			filedate: '2021-04-29',
			value: 0.341583
		},
		{
			filedate: '2021-07-28',
			value: 0.33827
		},
		{
			filedate: '2021-10-29',
			value: 1.50071
		},
		{
			filedate: '2022-01-28',
			value: 0.481427
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 0.0474737
		},
		{
			filedate: '2010-04-21',
			value: 0.0781234
		},
		{
			filedate: '2010-07-21',
			value: 0.0754564
		},
		{
			filedate: '2010-10-27',
			value: 0.293214
		},
		{
			filedate: '2011-01-19',
			value: 0.109831
		},
		{
			filedate: '2011-04-21',
			value: 0.097386
		},
		{
			filedate: '2011-07-20',
			value: 0.105389
		},
		{
			filedate: '2011-10-26',
			value: 0.338341
		},
		{
			filedate: '2012-01-25',
			value: 0.145069
		},
		{
			filedate: '2012-04-25',
			value: 0.113388
		},
		{
			filedate: '2012-07-25',
			value: 0.0789648
		},
		{
			filedate: '2012-10-31',
			value: 0.353041
		},
		{
			filedate: '2013-01-24',
			value: 0.102697
		},
		{
			filedate: '2013-04-24',
			value: 0.0704628
		},
		{
			filedate: '2013-07-24',
			value: 0.0559366
		},
		{
			filedate: '2013-10-30',
			value: 0.299776
		},
		{
			filedate: '2014-01-28',
			value: 0.100799
		},
		{
			filedate: '2014-04-24',
			value: 0.0850648
		},
		{
			filedate: '2014-07-23',
			value: 0.0640648
		},
		{
			filedate: '2014-10-27',
			value: 0.3542
		},
		{
			filedate: '2015-01-28',
			value: 0.146147
		},
		{
			filedate: '2015-04-28',
			value: 0.105181
		},
		{
			filedate: '2015-07-22',
			value: 0.0849559
		},
		{
			filedate: '2015-10-28',
			value: 0.447355
		},
		{
			filedate: '2016-01-27',
			value: 0.143147
		},
		{
			filedate: '2016-04-27',
			value: 0.0806089
		},
		{
			filedate: '2016-07-27',
			value: 0.0616085
		},
		{
			filedate: '2016-10-26',
			value: 0.356237
		},
		{
			filedate: '2017-02-01',
			value: 0.135139
		},
		{
			filedate: '2017-05-03',
			value: 0.0822556
		},
		{
			filedate: '2017-08-02',
			value: 0.0658259
		},
		{
			filedate: '2017-11-03',
			value: 0.360702
		},
		{
			filedate: '2018-02-02',
			value: 0.143118
		},
		{
			filedate: '2018-05-02',
			value: 0.108939
		},
		{
			filedate: '2018-08-01',
			value: 0.10021
		},
		{
			filedate: '2018-11-05',
			value: 0.555601
		},
		{
			filedate: '2019-01-30',
			value: 0.16935
		},
		{
			filedate: '2019-05-01',
			value: 0.10921
		},
		{
			filedate: '2019-07-31',
			value: 0.10413
		},
		{
			filedate: '2019-10-31',
			value: 0.610645
		},
		{
			filedate: '2020-01-29',
			value: 0.248361
		},
		{
			filedate: '2020-05-01',
			value: 0.143436
		},
		{
			filedate: '2020-07-31',
			value: 0.155682
		},
		{
			filedate: '2020-10-30',
			value: 0.878664
		},
		{
			filedate: '2021-01-28',
			value: 0.434208
		},
		{
			filedate: '2021-04-29',
			value: 0.341583
		},
		{
			filedate: '2021-07-28',
			value: 0.33827
		},
		{
			filedate: '2021-10-29',
			value: 1.50071
		},
		{
			filedate: '2022-01-28',
			value: 0.481427
		}
	]
};

const bookValueData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: null
		},
		{
			filedate: '2010-04-21',
			value: null
		},
		{
			filedate: '2010-07-21',
			value: null
		},
		{
			filedate: '2010-10-27',
			value: null
		},
		{
			filedate: '2011-01-19',
			value: null
		},
		{
			filedate: '2011-04-21',
			value: null
		},
		{
			filedate: '2011-07-20',
			value: null
		},
		{
			filedate: '2011-10-26',
			value: null
		},
		{
			filedate: '2012-01-25',
			value: null
		},
		{
			filedate: '2012-04-25',
			value: null
		},
		{
			filedate: '2012-07-25',
			value: null
		},
		{
			filedate: '2012-10-31',
			value: null
		},
		{
			filedate: '2013-01-24',
			value: null
		},
		{
			filedate: '2013-04-24',
			value: null
		},
		{
			filedate: '2013-07-24',
			value: null
		},
		{
			filedate: '2013-10-30',
			value: null
		},
		{
			filedate: '2014-01-28',
			value: null
		},
		{
			filedate: '2014-04-24',
			value: null
		},
		{
			filedate: '2014-07-23',
			value: null
		},
		{
			filedate: '2014-10-27',
			value: null
		},
		{
			filedate: '2015-01-28',
			value: null
		},
		{
			filedate: '2015-04-28',
			value: null
		},
		{
			filedate: '2015-07-22',
			value: null
		},
		{
			filedate: '2015-10-28',
			value: null
		},
		{
			filedate: '2016-01-27',
			value: null
		},
		{
			filedate: '2016-04-27',
			value: null
		},
		{
			filedate: '2016-07-27',
			value: null
		},
		{
			filedate: '2016-10-26',
			value: null
		},
		{
			filedate: '2017-02-01',
			value: null
		},
		{
			filedate: '2017-05-03',
			value: null
		},
		{
			filedate: '2017-08-02',
			value: null
		},
		{
			filedate: '2017-11-03',
			value: null
		},
		{
			filedate: '2018-02-02',
			value: null
		},
		{
			filedate: '2018-05-02',
			value: null
		},
		{
			filedate: '2018-08-01',
			value: null
		},
		{
			filedate: '2018-11-05',
			value: null
		},
		{
			filedate: '2019-01-30',
			value: null
		},
		{
			filedate: '2019-05-01',
			value: null
		},
		{
			filedate: '2019-07-31',
			value: null
		},
		{
			filedate: '2019-10-31',
			value: null
		},
		{
			filedate: '2020-01-29',
			value: null
		},
		{
			filedate: '2020-05-01',
			value: null
		},
		{
			filedate: '2020-07-31',
			value: null
		},
		{
			filedate: '2020-10-30',
			value: null
		},
		{
			filedate: '2021-01-28',
			value: null
		},
		{
			filedate: '2021-04-29',
			value: null
		},
		{
			filedate: '2021-07-28',
			value: null
		},
		{
			filedate: '2021-10-29',
			value: null
		},
		{
			filedate: '2022-01-28',
			value: null
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: null
		},
		{
			filedate: '2010-04-21',
			value: null
		},
		{
			filedate: '2010-07-21',
			value: null
		},
		{
			filedate: '2010-10-27',
			value: null
		},
		{
			filedate: '2011-01-19',
			value: null
		},
		{
			filedate: '2011-04-21',
			value: null
		},
		{
			filedate: '2011-07-20',
			value: null
		},
		{
			filedate: '2011-10-26',
			value: null
		},
		{
			filedate: '2012-01-25',
			value: null
		},
		{
			filedate: '2012-04-25',
			value: null
		},
		{
			filedate: '2012-07-25',
			value: null
		},
		{
			filedate: '2012-10-31',
			value: null
		},
		{
			filedate: '2013-01-24',
			value: null
		},
		{
			filedate: '2013-04-24',
			value: null
		},
		{
			filedate: '2013-07-24',
			value: null
		},
		{
			filedate: '2013-10-30',
			value: null
		},
		{
			filedate: '2014-01-28',
			value: null
		},
		{
			filedate: '2014-04-24',
			value: null
		},
		{
			filedate: '2014-07-23',
			value: null
		},
		{
			filedate: '2014-10-27',
			value: null
		},
		{
			filedate: '2015-01-28',
			value: null
		},
		{
			filedate: '2015-04-28',
			value: null
		},
		{
			filedate: '2015-07-22',
			value: null
		},
		{
			filedate: '2015-10-28',
			value: null
		},
		{
			filedate: '2016-01-27',
			value: null
		},
		{
			filedate: '2016-04-27',
			value: null
		},
		{
			filedate: '2016-07-27',
			value: null
		},
		{
			filedate: '2016-10-26',
			value: null
		},
		{
			filedate: '2017-02-01',
			value: null
		},
		{
			filedate: '2017-05-03',
			value: null
		},
		{
			filedate: '2017-08-02',
			value: null
		},
		{
			filedate: '2017-11-03',
			value: null
		},
		{
			filedate: '2018-02-02',
			value: null
		},
		{
			filedate: '2018-05-02',
			value: null
		},
		{
			filedate: '2018-08-01',
			value: null
		},
		{
			filedate: '2018-11-05',
			value: null
		},
		{
			filedate: '2019-01-30',
			value: null
		},
		{
			filedate: '2019-05-01',
			value: null
		},
		{
			filedate: '2019-07-31',
			value: null
		},
		{
			filedate: '2019-10-31',
			value: null
		},
		{
			filedate: '2020-01-29',
			value: null
		},
		{
			filedate: '2020-05-01',
			value: null
		},
		{
			filedate: '2020-07-31',
			value: null
		},
		{
			filedate: '2020-10-30',
			value: null
		},
		{
			filedate: '2021-01-28',
			value: null
		},
		{
			filedate: '2021-04-29',
			value: null
		},
		{
			filedate: '2021-07-28',
			value: null
		},
		{
			filedate: '2021-10-29',
			value: null
		},
		{
			filedate: '2022-01-28',
			value: null
		}
	]
};

const stockPriceData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 142.44
		},
		{
			filedate: '2010-04-21',
			value: 230.9
		},
		{
			filedate: '2010-07-21',
			value: 266.7
		},
		{
			filedate: '2010-10-27',
			value: 292.32
		},
		{
			filedate: '2011-01-19',
			value: 323.6
		},
		{
			filedate: '2011-04-21',
			value: 351.54
		},
		{
			filedate: '2011-07-20',
			value: 326.35
		},
		{
			filedate: '2011-10-26',
			value: 404.3
		},
		{
			filedate: '2012-01-25',
			value: 405
		},
		{
			filedate: '2012-04-25',
			value: 599.55
		},
		{
			filedate: '2012-07-25',
			value: 584
		},
		{
			filedate: '2012-10-31',
			value: 667.105
		},
		{
			filedate: '2013-01-24',
			value: 509.589
		},
		{
			filedate: '2013-04-24',
			value: 442.66
		},
		{
			filedate: '2013-07-24',
			value: 396.53
		},
		{
			filedate: '2013-10-30',
			value: 482.75
		},
		{
			filedate: '2014-01-28',
			value: 560.09
		},
		{
			filedate: '2014-04-24',
			value: 536.86
		},
		{
			filedate: '2014-07-23',
			value: 91.98
		},
		{
			filedate: '2014-10-27',
			value: 100.75
		},
		{
			filedate: '2015-01-28',
			value: 113.99
		},
		{
			filedate: '2015-04-28',
			value: 123.25
		},
		{
			filedate: '2015-07-22',
			value: 126.75
		},
		{
			filedate: '2015-10-28',
			value: 114.71
		},
		{
			filedate: '2016-01-27',
			value: 108.61
		},
		{
			filedate: '2016-04-27',
			value: 5.08
		},
		{
			filedate: '2016-07-27',
			value: 93.4
		},
		{
			filedate: '2016-10-26',
			value: 112.71
		},
		{
			filedate: '2017-02-01',
			value: 115.82
		},
		{
			filedate: '2017-05-03',
			value: 143.66
		},
		{
			filedate: '2017-08-02',
			value: 144.02
		},
		{
			filedate: '2017-11-03',
			value: 154.12
		},
		{
			filedate: '2018-02-02',
			value: 169.23
		},
		{
			filedate: '2018-05-02',
			value: 167.78
		},
		{
			filedate: '2018-08-01',
			value: 185.11
		},
		{
			filedate: '2018-11-05',
			value: 225.74
		},
		{
			filedate: '2019-01-30',
			value: 156.23
		},
		{
			filedate: '2019-05-01',
			value: 189.95
		},
		{
			filedate: '2019-07-31',
			value: 197.92
		},
		{
			filedate: '2019-10-31',
			value: 218.82
		},
		{
			filedate: '2020-01-29',
			value: 289.8
		},
		{
			filedate: '2020-05-01',
			value: 247.74
		},
		{
			filedate: '2020-07-31',
			value: 353.63
		},
		{
			filedate: '2020-10-30',
			value: 112.28
		},
		{
			filedate: '2021-01-28',
			value: 131.97
		},
		{
			filedate: '2021-04-29',
			value: 121.21
		},
		{
			filedate: '2021-07-28',
			value: 133.11
		},
		{
			filedate: '2021-10-29',
			value: 146.92
		},
		{
			filedate: '2022-01-28',
			value: 176.28
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 142.44
		},
		{
			filedate: '2010-04-21',
			value: 230.9
		},
		{
			filedate: '2010-07-21',
			value: 266.7
		},
		{
			filedate: '2010-10-27',
			value: 292.32
		},
		{
			filedate: '2011-01-19',
			value: 323.6
		},
		{
			filedate: '2011-04-21',
			value: 351.54
		},
		{
			filedate: '2011-07-20',
			value: 326.35
		},
		{
			filedate: '2011-10-26',
			value: 404.3
		},
		{
			filedate: '2012-01-25',
			value: 405
		},
		{
			filedate: '2012-04-25',
			value: 599.55
		},
		{
			filedate: '2012-07-25',
			value: 584
		},
		{
			filedate: '2012-10-31',
			value: 667.105
		},
		{
			filedate: '2013-01-24',
			value: 509.589
		},
		{
			filedate: '2013-04-24',
			value: 442.66
		},
		{
			filedate: '2013-07-24',
			value: 396.53
		},
		{
			filedate: '2013-10-30',
			value: 482.75
		},
		{
			filedate: '2014-01-28',
			value: 560.09
		},
		{
			filedate: '2014-04-24',
			value: 536.86
		},
		{
			filedate: '2014-07-23',
			value: 91.98
		},
		{
			filedate: '2014-10-27',
			value: 100.75
		},
		{
			filedate: '2015-01-28',
			value: 113.99
		},
		{
			filedate: '2015-04-28',
			value: 123.25
		},
		{
			filedate: '2015-07-22',
			value: 126.75
		},
		{
			filedate: '2015-10-28',
			value: 114.71
		},
		{
			filedate: '2016-01-27',
			value: 108.61
		},
		{
			filedate: '2016-04-27',
			value: 5.08
		},
		{
			filedate: '2016-07-27',
			value: 93.4
		},
		{
			filedate: '2016-10-26',
			value: 112.71
		},
		{
			filedate: '2017-02-01',
			value: 115.82
		},
		{
			filedate: '2017-05-03',
			value: 143.66
		},
		{
			filedate: '2017-08-02',
			value: 144.02
		},
		{
			filedate: '2017-11-03',
			value: 154.12
		},
		{
			filedate: '2018-02-02',
			value: 169.23
		},
		{
			filedate: '2018-05-02',
			value: 167.78
		},
		{
			filedate: '2018-08-01',
			value: 185.11
		},
		{
			filedate: '2018-11-05',
			value: 225.74
		},
		{
			filedate: '2019-01-30',
			value: 156.23
		},
		{
			filedate: '2019-05-01',
			value: 189.95
		},
		{
			filedate: '2019-07-31',
			value: 197.92
		},
		{
			filedate: '2019-10-31',
			value: 218.82
		},
		{
			filedate: '2020-01-29',
			value: 289.8
		},
		{
			filedate: '2020-05-01',
			value: 247.74
		},
		{
			filedate: '2020-07-31',
			value: 353.63
		},
		{
			filedate: '2020-10-30',
			value: 112.28
		},
		{
			filedate: '2021-01-28',
			value: 131.97
		},
		{
			filedate: '2021-04-29',
			value: 121.21
		},
		{
			filedate: '2021-07-28',
			value: 133.11
		},
		{
			filedate: '2021-10-29',
			value: 146.92
		},
		{
			filedate: '2022-01-28',
			value: 176.28
		}
	]
};

const epsData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 1.38
		},
		{
			filedate: '2010-04-21',
			value: 3.39
		},
		{
			filedate: '2010-07-21',
			value: 3.57
		},
		{
			filedate: '2010-10-27',
			value: 15.41
		},
		{
			filedate: '2011-01-19',
			value: 6.53
		},
		{
			filedate: '2011-04-21',
			value: 6.49
		},
		{
			filedate: '2011-07-20',
			value: 7.89
		},
		{
			filedate: '2011-10-26',
			value: 28.05
		},
		{
			filedate: '2012-01-25',
			value: 14.03
		},
		{
			filedate: '2012-04-25',
			value: 12.45
		},
		{
			filedate: '2012-07-25',
			value: 9.42
		},
		{
			filedate: '2012-10-31',
			value: 44.64
		},
		{
			filedate: '2013-01-24',
			value: 13.93
		},
		{
			filedate: '2013-04-24',
			value: 10.16
		},
		{
			filedate: '2013-07-24',
			value: 7.51
		},
		{
			filedate: '2013-10-30',
			value: 40.03
		},
		{
			filedate: '2014-01-28',
			value: 14.59
		},
		{
			filedate: '2014-04-24',
			value: 11.69
		},
		{
			filedate: '2014-07-23',
			value: 1.29
		},
		{
			filedate: '2014-10-27',
			value: 6.49
		},
		{
			filedate: '2015-01-28',
			value: 3.08
		},
		{
			filedate: '2015-04-28',
			value: 2.34
		},
		{
			filedate: '2015-07-22',
			value: 1.86
		},
		{
			filedate: '2015-10-28',
			value: 9.28
		},
		{
			filedate: '2016-01-27',
			value: 3.3
		},
		{
			filedate: '2016-04-27',
			value: 1.91
		},
		{
			filedate: '2016-07-27',
			value: 1.43
		},
		{
			filedate: '2016-10-26',
			value: 8.35
		},
		{
			filedate: '2017-02-01',
			value: 3.38
		},
		{
			filedate: '2017-05-03',
			value: 2.11
		},
		{
			filedate: '2017-08-02',
			value: 1.68
		},
		{
			filedate: '2017-11-03',
			value: 9.27
		},
		{
			filedate: '2018-02-02',
			value: 3.92
		},
		{
			filedate: '2018-05-02',
			value: 2.75
		},
		{
			filedate: '2018-08-01',
			value: 2.36
		},
		{
			filedate: '2018-11-05',
			value: 12.01
		},
		{
			filedate: '2019-01-30',
			value: 4.22
		},
		{
			filedate: '2019-05-01',
			value: 2.47
		},
		{
			filedate: '2019-07-31',
			value: 2.2
		},
		{
			filedate: '2019-10-31',
			value: 11.97
		},
		{
			filedate: '2020-01-29',
			value: 5.04
		},
		{
			filedate: '2020-05-01',
			value: 2.58
		},
		{
			filedate: '2020-07-31',
			value: 2.61
		},
		{
			filedate: '2020-10-30',
			value: 3.31
		},
		{
			filedate: '2021-01-28',
			value: 1.7
		},
		{
			filedate: '2021-04-29',
			value: 1.41
		},
		{
			filedate: '2021-07-28',
			value: 1.31
		},
		{
			filedate: '2021-10-29',
			value: 5.67
		},
		{
			filedate: '2022-01-28',
			value: 2.11
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 1.38
		},
		{
			filedate: '2010-04-21',
			value: 3.39
		},
		{
			filedate: '2010-07-21',
			value: 3.57
		},
		{
			filedate: '2010-10-27',
			value: 15.41
		},
		{
			filedate: '2011-01-19',
			value: 6.53
		},
		{
			filedate: '2011-04-21',
			value: 6.49
		},
		{
			filedate: '2011-07-20',
			value: 7.89
		},
		{
			filedate: '2011-10-26',
			value: 28.05
		},
		{
			filedate: '2012-01-25',
			value: 14.03
		},
		{
			filedate: '2012-04-25',
			value: 12.45
		},
		{
			filedate: '2012-07-25',
			value: 9.42
		},
		{
			filedate: '2012-10-31',
			value: 44.64
		},
		{
			filedate: '2013-01-24',
			value: 13.93
		},
		{
			filedate: '2013-04-24',
			value: 10.16
		},
		{
			filedate: '2013-07-24',
			value: 7.51
		},
		{
			filedate: '2013-10-30',
			value: 40.03
		},
		{
			filedate: '2014-01-28',
			value: 14.59
		},
		{
			filedate: '2014-04-24',
			value: 11.69
		},
		{
			filedate: '2014-07-23',
			value: 1.29
		},
		{
			filedate: '2014-10-27',
			value: 6.49
		},
		{
			filedate: '2015-01-28',
			value: 3.08
		},
		{
			filedate: '2015-04-28',
			value: 2.34
		},
		{
			filedate: '2015-07-22',
			value: 1.86
		},
		{
			filedate: '2015-10-28',
			value: 9.28
		},
		{
			filedate: '2016-01-27',
			value: 3.3
		},
		{
			filedate: '2016-04-27',
			value: 1.91
		},
		{
			filedate: '2016-07-27',
			value: 1.43
		},
		{
			filedate: '2016-10-26',
			value: 8.35
		},
		{
			filedate: '2017-02-01',
			value: 3.38
		},
		{
			filedate: '2017-05-03',
			value: 2.11
		},
		{
			filedate: '2017-08-02',
			value: 1.68
		},
		{
			filedate: '2017-11-03',
			value: 9.27
		},
		{
			filedate: '2018-02-02',
			value: 3.92
		},
		{
			filedate: '2018-05-02',
			value: 2.75
		},
		{
			filedate: '2018-08-01',
			value: 2.36
		},
		{
			filedate: '2018-11-05',
			value: 12.01
		},
		{
			filedate: '2019-01-30',
			value: 4.22
		},
		{
			filedate: '2019-05-01',
			value: 2.47
		},
		{
			filedate: '2019-07-31',
			value: 2.2
		},
		{
			filedate: '2019-10-31',
			value: 11.97
		},
		{
			filedate: '2020-01-29',
			value: 5.04
		},
		{
			filedate: '2020-05-01',
			value: 2.58
		},
		{
			filedate: '2020-07-31',
			value: 2.61
		},
		{
			filedate: '2020-10-30',
			value: 3.31
		},
		{
			filedate: '2021-01-28',
			value: 1.7
		},
		{
			filedate: '2021-04-29',
			value: 1.41
		},
		{
			filedate: '2021-07-28',
			value: 1.31
		},
		{
			filedate: '2021-10-29',
			value: 5.67
		},
		{
			filedate: '2022-01-28',
			value: 2.11
		}
	]
};

const ebitData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 1732000000
		},
		{
			filedate: '2010-04-21',
			value: 4029000000
		},
		{
			filedate: '2010-07-21',
			value: 4292000000
		},
		{
			filedate: '2010-10-27',
			value: 18540000000
		},
		{
			filedate: '2011-01-19',
			value: 7963000000
		},
		{
			filedate: '2011-04-21',
			value: 7900000000
		},
		{
			filedate: '2011-07-20',
			value: 9551000000
		},
		{
			filedate: '2011-10-26',
			value: 34205000000
		},
		{
			filedate: '2012-01-25',
			value: 17477000000
		},
		{
			filedate: '2012-04-25',
			value: 15532000000
		},
		{
			filedate: '2012-07-25',
			value: 11861000000
		},
		{
			filedate: '2012-10-31',
			value: 55763000000
		},
		{
			filedate: '2013-01-24',
			value: 17672000000
		},
		{
			filedate: '2013-04-24',
			value: 12905000000
		},
		{
			filedate: '2013-07-24',
			value: 9435000000
		},
		{
			filedate: '2013-10-30',
			value: 50155000000
		},
		{
			filedate: '2014-01-28',
			value: 17709000000
		},
		{
			filedate: '2014-04-24',
			value: 13818000000
		},
		{
			filedate: '2014-07-23',
			value: 10484000000
		},
		{
			filedate: '2014-10-27',
			value: 53483000000
		},
		{
			filedate: '2015-01-28',
			value: 24416000000
		},
		{
			filedate: '2015-04-28',
			value: 18564000000
		},
		{
			filedate: '2015-07-22',
			value: 14473000000
		},
		{
			filedate: '2015-10-28',
			value: 72515000000
		},
		{
			filedate: '2016-01-27',
			value: 24573000000
		},
		{
			filedate: '2016-04-27',
			value: 14142000000
		},
		{
			filedate: '2016-07-27',
			value: 10469000000
		},
		{
			filedate: '2016-10-26',
			value: 61372000000
		},
		{
			filedate: '2017-02-01',
			value: 24180000000
		},
		{
			filedate: '2017-05-03',
			value: 14684000000
		},
		{
			filedate: '2017-08-02',
			value: 11308000000
		},
		{
			filedate: '2017-11-03',
			value: 64089000000
		},
		{
			filedate: '2018-02-02',
			value: 27030000000
		},
		{
			filedate: '2018-05-02',
			value: 16168000000
		},
		{
			filedate: '2018-08-01',
			value: 13284000000
		},
		{
			filedate: '2018-11-05',
			value: 72903000000
		},
		{
			filedate: '2019-01-30',
			value: 23906000000
		},
		{
			filedate: '2019-05-01',
			value: 13793000000
		},
		{
			filedate: '2019-07-31',
			value: 11911000000
		},
		{
			filedate: '2019-10-31',
			value: 65737000000
		},
		{
			filedate: '2020-01-29',
			value: 25918000000
		},
		{
			filedate: '2020-05-01',
			value: 13135000000
		},
		{
			filedate: '2020-07-31',
			value: 13137000000
		},
		{
			filedate: '2020-10-30',
			value: 67091000000
		},
		{
			filedate: '2021-01-28',
			value: 33579000000
		},
		{
			filedate: '2021-04-29',
			value: 28011000000
		},
		{
			filedate: '2021-07-28',
			value: 24369000000
		},
		{
			filedate: '2021-10-29',
			value: 109207000000
		},
		{
			filedate: '2022-01-28',
			value: 41241000000
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 1732000000
		},
		{
			filedate: '2010-04-21',
			value: 4029000000
		},
		{
			filedate: '2010-07-21',
			value: 4292000000
		},
		{
			filedate: '2010-10-27',
			value: 18540000000
		},
		{
			filedate: '2011-01-19',
			value: 7963000000
		},
		{
			filedate: '2011-04-21',
			value: 7900000000
		},
		{
			filedate: '2011-07-20',
			value: 9551000000
		},
		{
			filedate: '2011-10-26',
			value: 34205000000
		},
		{
			filedate: '2012-01-25',
			value: 17477000000
		},
		{
			filedate: '2012-04-25',
			value: 15532000000
		},
		{
			filedate: '2012-07-25',
			value: 11861000000
		},
		{
			filedate: '2012-10-31',
			value: 55763000000
		},
		{
			filedate: '2013-01-24',
			value: 17672000000
		},
		{
			filedate: '2013-04-24',
			value: 12905000000
		},
		{
			filedate: '2013-07-24',
			value: 9435000000
		},
		{
			filedate: '2013-10-30',
			value: 50155000000
		},
		{
			filedate: '2014-01-28',
			value: 17709000000
		},
		{
			filedate: '2014-04-24',
			value: 13818000000
		},
		{
			filedate: '2014-07-23',
			value: 10484000000
		},
		{
			filedate: '2014-10-27',
			value: 53483000000
		},
		{
			filedate: '2015-01-28',
			value: 24416000000
		},
		{
			filedate: '2015-04-28',
			value: 18564000000
		},
		{
			filedate: '2015-07-22',
			value: 14473000000
		},
		{
			filedate: '2015-10-28',
			value: 72515000000
		},
		{
			filedate: '2016-01-27',
			value: 24573000000
		},
		{
			filedate: '2016-04-27',
			value: 14142000000
		},
		{
			filedate: '2016-07-27',
			value: 10469000000
		},
		{
			filedate: '2016-10-26',
			value: 61372000000
		},
		{
			filedate: '2017-02-01',
			value: 24180000000
		},
		{
			filedate: '2017-05-03',
			value: 14684000000
		},
		{
			filedate: '2017-08-02',
			value: 11308000000
		},
		{
			filedate: '2017-11-03',
			value: 64089000000
		},
		{
			filedate: '2018-02-02',
			value: 27030000000
		},
		{
			filedate: '2018-05-02',
			value: 16168000000
		},
		{
			filedate: '2018-08-01',
			value: 13284000000
		},
		{
			filedate: '2018-11-05',
			value: 72903000000
		},
		{
			filedate: '2019-01-30',
			value: 23906000000
		},
		{
			filedate: '2019-05-01',
			value: 13793000000
		},
		{
			filedate: '2019-07-31',
			value: 11911000000
		},
		{
			filedate: '2019-10-31',
			value: 65737000000
		},
		{
			filedate: '2020-01-29',
			value: 25918000000
		},
		{
			filedate: '2020-05-01',
			value: 13135000000
		},
		{
			filedate: '2020-07-31',
			value: 13137000000
		},
		{
			filedate: '2020-10-30',
			value: 67091000000
		},
		{
			filedate: '2021-01-28',
			value: 33579000000
		},
		{
			filedate: '2021-04-29',
			value: 28011000000
		},
		{
			filedate: '2021-07-28',
			value: 24369000000
		},
		{
			filedate: '2021-10-29',
			value: 109207000000
		},
		{
			filedate: '2022-01-28',
			value: 41241000000
		}
	]
};

const netData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 1229000000
		},
		{
			filedate: '2010-04-21',
			value: 3074000000
		},
		{
			filedate: '2010-07-21',
			value: 3253000000
		},
		{
			filedate: '2010-10-27',
			value: 14013000000
		},
		{
			filedate: '2011-01-19',
			value: 6004000000
		},
		{
			filedate: '2011-04-21',
			value: 5987000000
		},
		{
			filedate: '2011-07-20',
			value: 7308000000
		},
		{
			filedate: '2011-10-26',
			value: 25922000000
		},
		{
			filedate: '2012-01-25',
			value: 13064000000
		},
		{
			filedate: '2012-04-25',
			value: 11622000000
		},
		{
			filedate: '2012-07-25',
			value: 8824000000
		},
		{
			filedate: '2012-10-31',
			value: 41733000000
		},
		{
			filedate: '2013-01-24',
			value: 13078000000
		},
		{
			filedate: '2013-04-24',
			value: 9547000000
		},
		{
			filedate: '2013-07-24',
			value: 6900000000
		},
		{
			filedate: '2013-10-30',
			value: 37037000000
		},
		{
			filedate: '2014-01-28',
			value: 13072000000
		},
		{
			filedate: '2014-04-24',
			value: 10223000000
		},
		{
			filedate: '2014-07-23',
			value: 7748000000
		},
		{
			filedate: '2014-10-27',
			value: 39510000000
		},
		{
			filedate: '2015-01-28',
			value: 18024000000
		},
		{
			filedate: '2015-04-28',
			value: 13569000000
		},
		{
			filedate: '2015-07-22',
			value: 10677000000
		},
		{
			filedate: '2015-10-28',
			value: 53394000000
		},
		{
			filedate: '2016-01-27',
			value: 18361000000
		},
		{
			filedate: '2016-04-27',
			value: 10516000000
		},
		{
			filedate: '2016-07-27',
			value: 7796000000
		},
		{
			filedate: '2016-10-26',
			value: 45687000000
		},
		{
			filedate: '2017-02-01',
			value: 17891000000
		},
		{
			filedate: '2017-05-03',
			value: 11029000000
		},
		{
			filedate: '2017-08-02',
			value: 8717000000
		},
		{
			filedate: '2017-11-03',
			value: 48351000000
		},
		{
			filedate: '2018-02-02',
			value: 20065000000
		},
		{
			filedate: '2018-05-02',
			value: 13822000000
		},
		{
			filedate: '2018-08-01',
			value: 11519000000
		},
		{
			filedate: '2018-11-05',
			value: 59531000000
		},
		{
			filedate: '2019-01-30',
			value: 19965000000
		},
		{
			filedate: '2019-05-01',
			value: 11561000000
		},
		{
			filedate: '2019-07-31',
			value: 10044000000
		},
		{
			filedate: '2019-10-31',
			value: 55256000000
		},
		{
			filedate: '2020-01-29',
			value: 22236000000
		},
		{
			filedate: '2020-05-01',
			value: 11249000000
		},
		{
			filedate: '2020-07-31',
			value: 11253000000
		},
		{
			filedate: '2020-10-30',
			value: 57411000000
		},
		{
			filedate: '2021-01-28',
			value: 28755000000
		},
		{
			filedate: '2021-04-29',
			value: 23630000000
		},
		{
			filedate: '2021-07-28',
			value: 21744000000
		},
		{
			filedate: '2021-10-29',
			value: 94680000000
		},
		{
			filedate: '2022-01-28',
			value: 34630000000
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 1229000000
		},
		{
			filedate: '2010-04-21',
			value: 3074000000
		},
		{
			filedate: '2010-07-21',
			value: 3253000000
		},
		{
			filedate: '2010-10-27',
			value: 14013000000
		},
		{
			filedate: '2011-01-19',
			value: 6004000000
		},
		{
			filedate: '2011-04-21',
			value: 5987000000
		},
		{
			filedate: '2011-07-20',
			value: 7308000000
		},
		{
			filedate: '2011-10-26',
			value: 25922000000
		},
		{
			filedate: '2012-01-25',
			value: 13064000000
		},
		{
			filedate: '2012-04-25',
			value: 11622000000
		},
		{
			filedate: '2012-07-25',
			value: 8824000000
		},
		{
			filedate: '2012-10-31',
			value: 41733000000
		},
		{
			filedate: '2013-01-24',
			value: 13078000000
		},
		{
			filedate: '2013-04-24',
			value: 9547000000
		},
		{
			filedate: '2013-07-24',
			value: 6900000000
		},
		{
			filedate: '2013-10-30',
			value: 37037000000
		},
		{
			filedate: '2014-01-28',
			value: 13072000000
		},
		{
			filedate: '2014-04-24',
			value: 10223000000
		},
		{
			filedate: '2014-07-23',
			value: 7748000000
		},
		{
			filedate: '2014-10-27',
			value: 39510000000
		},
		{
			filedate: '2015-01-28',
			value: 18024000000
		},
		{
			filedate: '2015-04-28',
			value: 13569000000
		},
		{
			filedate: '2015-07-22',
			value: 10677000000
		},
		{
			filedate: '2015-10-28',
			value: 53394000000
		},
		{
			filedate: '2016-01-27',
			value: 18361000000
		},
		{
			filedate: '2016-04-27',
			value: 10516000000
		},
		{
			filedate: '2016-07-27',
			value: 7796000000
		},
		{
			filedate: '2016-10-26',
			value: 45687000000
		},
		{
			filedate: '2017-02-01',
			value: 17891000000
		},
		{
			filedate: '2017-05-03',
			value: 11029000000
		},
		{
			filedate: '2017-08-02',
			value: 8717000000
		},
		{
			filedate: '2017-11-03',
			value: 48351000000
		},
		{
			filedate: '2018-02-02',
			value: 20065000000
		},
		{
			filedate: '2018-05-02',
			value: 13822000000
		},
		{
			filedate: '2018-08-01',
			value: 11519000000
		},
		{
			filedate: '2018-11-05',
			value: 59531000000
		},
		{
			filedate: '2019-01-30',
			value: 19965000000
		},
		{
			filedate: '2019-05-01',
			value: 11561000000
		},
		{
			filedate: '2019-07-31',
			value: 10044000000
		},
		{
			filedate: '2019-10-31',
			value: 55256000000
		},
		{
			filedate: '2020-01-29',
			value: 22236000000
		},
		{
			filedate: '2020-05-01',
			value: 11249000000
		},
		{
			filedate: '2020-07-31',
			value: 11253000000
		},
		{
			filedate: '2020-10-30',
			value: 57411000000
		},
		{
			filedate: '2021-01-28',
			value: 28755000000
		},
		{
			filedate: '2021-04-29',
			value: 23630000000
		},
		{
			filedate: '2021-07-28',
			value: 21744000000
		},
		{
			filedate: '2021-10-29',
			value: 94680000000
		},
		{
			filedate: '2022-01-28',
			value: 34630000000
		}
	]
};

const revData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 8337000000
		},
		{
			filedate: '2010-04-21',
			value: 13499000000
		},
		{
			filedate: '2010-07-21',
			value: 15700000000
		},
		{
			filedate: '2010-10-27',
			value: 65225000000
		},
		{
			filedate: '2011-01-19',
			value: 26741000000
		},
		{
			filedate: '2011-04-21',
			value: 24667000000
		},
		{
			filedate: '2011-07-20',
			value: 28571000000
		},
		{
			filedate: '2011-10-26',
			value: 108249000000
		},
		{
			filedate: '2012-01-25',
			value: 46333000000
		},
		{
			filedate: '2012-04-25',
			value: 39186000000
		},
		{
			filedate: '2012-07-25',
			value: 35023000000
		},
		{
			filedate: '2012-10-31',
			value: 156508000000
		},
		{
			filedate: '2013-01-24',
			value: 54512000000
		},
		{
			filedate: '2013-04-24',
			value: 43603000000
		},
		{
			filedate: '2013-07-24',
			value: 35323000000
		},
		{
			filedate: '2013-10-30',
			value: 170910000000
		},
		{
			filedate: '2014-01-28',
			value: 57594000000
		},
		{
			filedate: '2014-04-24',
			value: 45646000000
		},
		{
			filedate: '2014-07-23',
			value: 37432000000
		},
		{
			filedate: '2014-10-27',
			value: 182795000000
		},
		{
			filedate: '2015-01-28',
			value: 74599000000
		},
		{
			filedate: '2015-04-28',
			value: 58010000000
		},
		{
			filedate: '2015-07-22',
			value: 49605000000
		},
		{
			filedate: '2015-10-28',
			value: 233715000000
		},
		{
			filedate: '2016-01-27',
			value: 75872000000
		},
		{
			filedate: '2016-04-27',
			value: 50557000000
		},
		{
			filedate: '2016-07-27',
			value: 42358000000
		},
		{
			filedate: '2016-10-26',
			value: 215639000000
		},
		{
			filedate: '2017-02-01',
			value: 78351000000
		},
		{
			filedate: '2017-05-03',
			value: 52896000000
		},
		{
			filedate: '2017-08-02',
			value: 45408000000
		},
		{
			filedate: '2017-11-03',
			value: 229234000000
		},
		{
			filedate: '2018-02-02',
			value: 88293000000
		},
		{
			filedate: '2018-05-02',
			value: 61137000000
		},
		{
			filedate: '2018-08-01',
			value: 53265000000
		},
		{
			filedate: '2018-11-05',
			value: 265595000000
		},
		{
			filedate: '2019-01-30',
			value: 84310000000
		},
		{
			filedate: '2019-05-01',
			value: 58015000000
		},
		{
			filedate: '2019-07-31',
			value: 53809000000
		},
		{
			filedate: '2019-10-31',
			value: 260174000000
		},
		{
			filedate: '2020-01-29',
			value: 91819000000
		},
		{
			filedate: '2020-05-01',
			value: 58313000000
		},
		{
			filedate: '2020-07-31',
			value: 59685000000
		},
		{
			filedate: '2020-10-30',
			value: 274515000000
		},
		{
			filedate: '2021-01-28',
			value: 111439000000
		},
		{
			filedate: '2021-04-29',
			value: 89584000000
		},
		{
			filedate: '2021-07-28',
			value: 81434000000
		},
		{
			filedate: '2021-10-29',
			value: 365817000000
		},
		{
			filedate: '2022-01-28',
			value: 123945000000
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 8337000000
		},
		{
			filedate: '2010-04-21',
			value: 13499000000
		},
		{
			filedate: '2010-07-21',
			value: 15700000000
		},
		{
			filedate: '2010-10-27',
			value: 65225000000
		},
		{
			filedate: '2011-01-19',
			value: 26741000000
		},
		{
			filedate: '2011-04-21',
			value: 24667000000
		},
		{
			filedate: '2011-07-20',
			value: 28571000000
		},
		{
			filedate: '2011-10-26',
			value: 108249000000
		},
		{
			filedate: '2012-01-25',
			value: 46333000000
		},
		{
			filedate: '2012-04-25',
			value: 39186000000
		},
		{
			filedate: '2012-07-25',
			value: 35023000000
		},
		{
			filedate: '2012-10-31',
			value: 156508000000
		},
		{
			filedate: '2013-01-24',
			value: 54512000000
		},
		{
			filedate: '2013-04-24',
			value: 43603000000
		},
		{
			filedate: '2013-07-24',
			value: 35323000000
		},
		{
			filedate: '2013-10-30',
			value: 170910000000
		},
		{
			filedate: '2014-01-28',
			value: 57594000000
		},
		{
			filedate: '2014-04-24',
			value: 45646000000
		},
		{
			filedate: '2014-07-23',
			value: 37432000000
		},
		{
			filedate: '2014-10-27',
			value: 182795000000
		},
		{
			filedate: '2015-01-28',
			value: 74599000000
		},
		{
			filedate: '2015-04-28',
			value: 58010000000
		},
		{
			filedate: '2015-07-22',
			value: 49605000000
		},
		{
			filedate: '2015-10-28',
			value: 233715000000
		},
		{
			filedate: '2016-01-27',
			value: 75872000000
		},
		{
			filedate: '2016-04-27',
			value: 50557000000
		},
		{
			filedate: '2016-07-27',
			value: 42358000000
		},
		{
			filedate: '2016-10-26',
			value: 215639000000
		},
		{
			filedate: '2017-02-01',
			value: 78351000000
		},
		{
			filedate: '2017-05-03',
			value: 52896000000
		},
		{
			filedate: '2017-08-02',
			value: 45408000000
		},
		{
			filedate: '2017-11-03',
			value: 229234000000
		},
		{
			filedate: '2018-02-02',
			value: 88293000000
		},
		{
			filedate: '2018-05-02',
			value: 61137000000
		},
		{
			filedate: '2018-08-01',
			value: 53265000000
		},
		{
			filedate: '2018-11-05',
			value: 265595000000
		},
		{
			filedate: '2019-01-30',
			value: 84310000000
		},
		{
			filedate: '2019-05-01',
			value: 58015000000
		},
		{
			filedate: '2019-07-31',
			value: 53809000000
		},
		{
			filedate: '2019-10-31',
			value: 260174000000
		},
		{
			filedate: '2020-01-29',
			value: 91819000000
		},
		{
			filedate: '2020-05-01',
			value: 58313000000
		},
		{
			filedate: '2020-07-31',
			value: 59685000000
		},
		{
			filedate: '2020-10-30',
			value: 274515000000
		},
		{
			filedate: '2021-01-28',
			value: 111439000000
		},
		{
			filedate: '2021-04-29',
			value: 89584000000
		},
		{
			filedate: '2021-07-28',
			value: 81434000000
		},
		{
			filedate: '2021-10-29',
			value: 365817000000
		},
		{
			filedate: '2022-01-28',
			value: 123945000000
		}
	]
};

const gpData = {
	AAPL: [
		{
			filedate: '2009-07-22',
			value: 3023000000
		},
		{
			filedate: '2010-04-21',
			value: 5625000000
		},
		{
			filedate: '2010-07-21',
			value: 6136000000
		},
		{
			filedate: '2010-10-27',
			value: 25684000000
		},
		{
			filedate: '2011-01-19',
			value: 10298000000
		},
		{
			filedate: '2011-04-21',
			value: 10218000000
		},
		{
			filedate: '2011-07-20',
			value: 11922000000
		},
		{
			filedate: '2011-10-26',
			value: 43818000000
		},
		{
			filedate: '2012-01-25',
			value: 20703000000
		},
		{
			filedate: '2012-04-25',
			value: 18564000000
		},
		{
			filedate: '2012-07-25',
			value: 14994000000
		},
		{
			filedate: '2012-10-31',
			value: 68662000000
		},
		{
			filedate: '2013-01-24',
			value: 21060000000
		},
		{
			filedate: '2013-04-24',
			value: 16349000000
		},
		{
			filedate: '2013-07-24',
			value: 13024000000
		},
		{
			filedate: '2013-10-30',
			value: 64304000000
		},
		{
			filedate: '2014-01-28',
			value: 21846000000
		},
		{
			filedate: '2014-04-24',
			value: 17947000000
		},
		{
			filedate: '2014-07-23',
			value: 14735000000
		},
		{
			filedate: '2014-10-27',
			value: 70537000000
		},
		{
			filedate: '2015-01-28',
			value: 29741000000
		},
		{
			filedate: '2015-04-28',
			value: 23656000000
		},
		{
			filedate: '2015-07-22',
			value: 19681000000
		},
		{
			filedate: '2015-10-28',
			value: 93626000000
		},
		{
			filedate: '2016-01-27',
			value: 30423000000
		},
		{
			filedate: '2016-04-27',
			value: 19921000000
		},
		{
			filedate: '2016-07-27',
			value: 16106000000
		},
		{
			filedate: '2016-10-26',
			value: 84263000000
		},
		{
			filedate: '2017-02-01',
			value: 30176000000
		},
		{
			filedate: '2017-05-03',
			value: 20591000000
		},
		{
			filedate: '2017-08-02',
			value: 17488000000
		},
		{
			filedate: '2017-11-03',
			value: 88186000000
		},
		{
			filedate: '2018-02-02',
			value: 33912000000
		},
		{
			filedate: '2018-05-02',
			value: 23422000000
		},
		{
			filedate: '2018-08-01',
			value: 20421000000
		},
		{
			filedate: '2018-11-05',
			value: 101839000000
		},
		{
			filedate: '2019-01-30',
			value: 32031000000
		},
		{
			filedate: '2019-05-01',
			value: 21821000000
		},
		{
			filedate: '2019-07-31',
			value: 20227000000
		},
		{
			filedate: '2019-10-31',
			value: 98392000000
		},
		{
			filedate: '2020-01-29',
			value: 35217000000
		},
		{
			filedate: '2020-05-01',
			value: 22370000000
		},
		{
			filedate: '2020-07-31',
			value: 22680000000
		},
		{
			filedate: '2020-10-30',
			value: 104956000000
		},
		{
			filedate: '2021-01-28',
			value: 44328000000
		},
		{
			filedate: '2021-04-29',
			value: 38079000000
		},
		{
			filedate: '2021-07-28',
			value: 35255000000
		},
		{
			filedate: '2021-10-29',
			value: 152836000000
		},
		{
			filedate: '2022-01-28',
			value: 54243000000
		}
	],
	SPY: [
		{
			filedate: '2009-07-22',
			value: 3023000000
		},
		{
			filedate: '2010-04-21',
			value: 5625000000
		},
		{
			filedate: '2010-07-21',
			value: 6136000000
		},
		{
			filedate: '2010-10-27',
			value: 25684000000
		},
		{
			filedate: '2011-01-19',
			value: 10298000000
		},
		{
			filedate: '2011-04-21',
			value: 10218000000
		},
		{
			filedate: '2011-07-20',
			value: 11922000000
		},
		{
			filedate: '2011-10-26',
			value: 43818000000
		},
		{
			filedate: '2012-01-25',
			value: 20703000000
		},
		{
			filedate: '2012-04-25',
			value: 18564000000
		},
		{
			filedate: '2012-07-25',
			value: 14994000000
		},
		{
			filedate: '2012-10-31',
			value: 68662000000
		},
		{
			filedate: '2013-01-24',
			value: 21060000000
		},
		{
			filedate: '2013-04-24',
			value: 16349000000
		},
		{
			filedate: '2013-07-24',
			value: 13024000000
		},
		{
			filedate: '2013-10-30',
			value: 64304000000
		},
		{
			filedate: '2014-01-28',
			value: 21846000000
		},
		{
			filedate: '2014-04-24',
			value: 17947000000
		},
		{
			filedate: '2014-07-23',
			value: 14735000000
		},
		{
			filedate: '2014-10-27',
			value: 70537000000
		},
		{
			filedate: '2015-01-28',
			value: 29741000000
		},
		{
			filedate: '2015-04-28',
			value: 23656000000
		},
		{
			filedate: '2015-07-22',
			value: 19681000000
		},
		{
			filedate: '2015-10-28',
			value: 93626000000
		},
		{
			filedate: '2016-01-27',
			value: 30423000000
		},
		{
			filedate: '2016-04-27',
			value: 19921000000
		},
		{
			filedate: '2016-07-27',
			value: 16106000000
		},
		{
			filedate: '2016-10-26',
			value: 84263000000
		},
		{
			filedate: '2017-02-01',
			value: 30176000000
		},
		{
			filedate: '2017-05-03',
			value: 20591000000
		},
		{
			filedate: '2017-08-02',
			value: 17488000000
		},
		{
			filedate: '2017-11-03',
			value: 88186000000
		},
		{
			filedate: '2018-02-02',
			value: 33912000000
		},
		{
			filedate: '2018-05-02',
			value: 23422000000
		},
		{
			filedate: '2018-08-01',
			value: 20421000000
		},
		{
			filedate: '2018-11-05',
			value: 101839000000
		},
		{
			filedate: '2019-01-30',
			value: 32031000000
		},
		{
			filedate: '2019-05-01',
			value: 21821000000
		},
		{
			filedate: '2019-07-31',
			value: 20227000000
		},
		{
			filedate: '2019-10-31',
			value: 98392000000
		},
		{
			filedate: '2020-01-29',
			value: 35217000000
		},
		{
			filedate: '2020-05-01',
			value: 22370000000
		},
		{
			filedate: '2020-07-31',
			value: 22680000000
		},
		{
			filedate: '2020-10-30',
			value: 104956000000
		},
		{
			filedate: '2021-01-28',
			value: 44328000000
		},
		{
			filedate: '2021-04-29',
			value: 38079000000
		},
		{
			filedate: '2021-07-28',
			value: 35255000000
		},
		{
			filedate: '2021-10-29',
			value: 152836000000
		},
		{
			filedate: '2022-01-28',
			value: 54243000000
		}
	]
};

export {
	priceEarningsData,
	currentData,
	cashflowData,
	debtData,
	debtEquityData,
	interestData,
	assetData,
	grossMarginData,
	operMarginData,
	returnOnEquityData,
	returnOnAssetsData,
	bookValueData,
	stockPriceData,
	epsData,
	ebitData,
	netData,
	revData,
	gpData
};
