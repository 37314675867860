export default [
	{
		symbol: 'AAPL',
		name: 'Apple Computers'
	},
	{
		symbol: 'SPY',
		name: 'SPDR S&P 500 ETF Trust'
	},
	{
		symbol: 'AA',
		name: 'Ares Acquisition'
	},
	{
		symbol: 'AROW',
		name: 'Arrow Financial	'
	}
];
