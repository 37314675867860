export default (data, symbol, rangeSelectorListener, rangeSelectorSelected) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)',
			height: 200
		},
		series: [
			{
				name: symbol,
				data,
				color: '#FF6B01'
			}
		],
		legend: {
			enabled: true,
			align: 'right',
			itemMarginTop: -35
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			selected: rangeSelectorSelected,
			allButtonsEnabled: true,
			verticalAlign: 'bottom',
			x: 0,
			y: 0,
			inputEnabled: false,
			buttons: [
				{
					type: 'day',
					count: 1,
					text: '1D',
					events: {
						click: function () {
							rangeSelectorListener('1D', 0);
						}
					}
				},
				{
					type: 'day',
					count: 7,
					text: '7D',
					events: {
						click: function () {
							rangeSelectorListener('7D', 1);
						}
					}
				},
				{
					type: 'month',
					count: 1,
					text: '1M',
					events: {
						click: function () {
							rangeSelectorListener('1M', 2);
						}
					}
				},
				{
					type: 'month',
					count: 3,
					text: '3M',
					events: {
						click: function () {
							rangeSelectorListener('3M', 3);
						}
					}
				},
				{
					type: 'year',
					count: 1,
					text: '1Y',
					events: {
						click: function () {
							rangeSelectorListener('1Y', 4);
						}
					}
				},
				{
					type: 'all',
					text: 'All',
					events: {
						click: function () {
							rangeSelectorListener('ALL', 5);
						}
					}
				}
			]
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			visible: true,
			gridLineWidth: 1,
			labels: {
				enabled: true
			}
		},
		yAxis: {
			visible: true,
			gridLineWidth: 1,
			labels: {
				enabled: true
			}
		},
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};
