import useDataApi from '../../../hooks/useDataApi';
import { ModalDialog, ModalBody } from '../ModalDialog/ModalDialog';
import './NewsDialog.css';

const View = ({ isOpen, handleStateChange, data }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle={data.title}
			headerIcon="/images/trade-ref-icon.png"
			headerClass="doted-border"
			rootClass="news-dialog"
			handleStateChange={handleStateChange}>
			<ModalBody>
				<div className="news-datetime-container">
					<div className="news-datetime">{data.dateTime}</div>
					<div className="card-tools">
						<span>
							<img
								src={
									data.sentimentsCategory === 1
										? '/images/green-smile.png'
										: data.sentimentsCategory === 2
										? '/images/red-smile.png'
										: '/images/blue-smile.png'
								}
								alt={data.image}
							/>{' '}
						</span>
						<span className="card-tools-text">{data.sentiments}</span>
					</div>
				</div>
				<div className="news-datetime-container">
					<div className="news-company-info">{data.company}</div>
					<div className="news-dialog-link">
						<a href="#" className="news-link-info">
							{' '}
							&gt; Add to Watchlist
						</a>
						<a href="#" className="news-link-info">
							{' '}
							&gt; Place Order
						</a>
					</div>
				</div>
				<div className="news-detail-item">
					<span>
						<img src={`/images/${data.image}`} alt={data.image} />{' '}
					</span>
				</div>
				<div className="like-share-container">
					<span>
						{data.comments}
						<img src="/images/icon_comment.png" alt="logo" />
					</span>
					<span>
						{data.likes}
						<img src="/images/icon_like.png" alt="logo" />
					</span>
					<span>
						5
						<img src="/images/icon_share.png" alt="logo" />
					</span>
				</div>
				<div className="news-dialog-btn-container">
					<a href="#"> Trend Analysis</a>
					<a href="#"> Amazon</a>
					<a href="#"> S&P 500 (SPX500)</a>
				</div>

				<div className="news-dialog-info">
					<span>{data.description}</span>
				</div>
			</ModalBody>
		</ModalDialog>
	);
};

const NewsDialog = (props) => {
	const [dataProps] = useDataApi(`/news-detail/${props.id}`, []);
	return <View {...props} data={dataProps.data} />;
};

export default NewsDialog;
