export default {
	trade: [
		{
			symbol: 'AAPL',
			name: 'Apple Computers'
		},
		{
			symbol: 'SPY',
			name: 'SPDR S&P 500 ETF Trust'
		},
		{
			symbol: 'AA',
			name: 'Ares Acquisition'
		},
		{
			symbol: 'AROW',
			name: 'Arrow Financial'
		}
	],
	currencies: [
		{
			symbol: 'GBPUSD',
			name: 'British Pound/U.S. Dollar'
		},
		{
			symbol: 'USDJPY',
			name: 'U.S. Dollar/Japanese Yen'
		},
		{
			symbol: 'USDCAD',
			name: 'U.S. Dollar/Canadian Dollar'
		}
	],
	crypto: [
		{
			symbol: 'Bitcoin',
			name: 'Bitcoin'
		},
		{
			symbol: 'Dogecoin',
			name: 'Dogecoin'
		}
	],
	indices: [
		{
			symbol: 'US Wall St 30',
			name: 'US Wall St 30'
		},
		{
			symbol: 'US SPX 500',
			name: 'US SPX 500'
		},
		{
			symbol: 'US Nas 100',
			name: 'US Nas 100'
		}
	],
	futures: [
		{
			symbol: 'DOW Futures',
			name: 'DOW Futures'
		},
		{
			symbol: 'SP 500 Futures',
			name: 'SP 500 Futures'
		},
		{
			symbol: 'NASDAQ Futures',
			name: 'NASDAQ Futures'
		}
	]
};
