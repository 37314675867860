import { useContext } from 'react';
import { Routes, HashRouter as Router, Route } from 'react-router-dom';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import { AnonymousPageLayout, PageLayout } from '../components/layout/PageLayout/PageLayout';
import TradePage from '../pages/Trade/TradePage';
import AlgosPage from '../pages/Algos/AlgosPage';
import InsightPage from '../pages/insight/InsightPage';
import MarketsPage from '../pages/markets/MarketsPage';
import AlgoEditorPage from '../pages/algoEditor/AlgoEditorPage';
import WatchListPage from '../pages/watchlist/WatchlistPage';
import OrdersPage from '../pages/orders/OrdersPage';
import PortfolioPage from '../pages/portfolio/PortfolioPage';
import ThemeContext from '../theme/ThemeContext';
import TopStocksPage from '../pages/topStocks/TopStocksPage';
import SettingsPage from '../pages/settings/SettingsPage';
import SignUpAllStepsPage from '../pages/signUpAllSteps/SignUpAllStepsPage';
import SignInPage from '../pages/signIn/SignInPage';
import TickerPage from '../pages/tickers/TickerPage';

const AppRouter = () => {
	const toggleTheme = useContext(ThemeContext)[1];
	return (
		<Router>
			<PageLayout toggleTheme={toggleTheme}>
				<Routes>
					<Route path="/" element={<DashboardPage />}></Route>
					<Route path="/trades" element={<TradePage />}></Route>
					<Route path="/settings/*" element={<SettingsPage />}></Route>
					<Route path="/algos" element={<AlgosPage />}></Route>
					<Route path="/insights" element={<InsightPage />}></Route>
					<Route path="/markets" element={<MarketsPage />}></Route>
					<Route path="/topstocks" element={<TopStocksPage />}></Route>
					<Route path="/algoeditor/:id" element={<AlgoEditorPage />}></Route>
					<Route path="/watchlist" element={<WatchListPage />}></Route>
					<Route path="/orders" element={<OrdersPage />}></Route>
					<Route path="/portfolio" element={<PortfolioPage />}></Route>
					<Route path="/tickers" element={<TickerPage />}></Route>
				</Routes>
			</PageLayout>
		</Router>
	);
};

export const NotAuthRouter = () => {
	return (
		<Router>
			<AnonymousPageLayout>
				<Routes>
					<Route path="/signup" element={<SignUpAllStepsPage />}></Route>
					<Route path="*" element={<SignInPage />}></Route>
				</Routes>
			</AnonymousPageLayout>
		</Router>
	);
};

export default AppRouter;
