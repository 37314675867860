import styled from 'styled-components';
import moon from '../../../styles/base64/moon';
import sun from '../../../styles/base64/sun';

export const Input = styled.input`
	display: none;

	&:checked + label {
		background: var(--bg-orange);

		&:before {
			background: var(--bg-white) url(${sun}) no-repeat 2px 3px;
			animation-name: switch;
			animation-duration: 350ms;
			animation-fill-mode: forwards;
		}

		.toggle-label-background:before {
			right: 40px;
			content: 'Bright';
		}
	}
	@keyframes switch {
		0% {
			left: 4px;
		}
		60% {
			left: 4px;
			width: 30px;
		}
		100% {
			left: 55px;
			width: 30px;
		}
	}
`;

export const Label = styled.label`
	width: 90px;
	height: 40px;
	background: var(--bg-grey);
	border-radius: 100px;
	display: flex;
	position: relative;
	transition: all 350ms ease-in;
	border: 1px solid #ffffff7a;
	cursor: pointer;

	&:before {
		animation-name: reverse;
		animation-duration: 200ms;
		animation-fill-mode: forwards;
		transition: all 200ms ease-in;
		content: '';
		width: 30px;
		height: 30px;
		top: 5px;
		left: 4px;
		z-index: 10;
		position: absolute;
		border-radius: 82px;
		background: var(--bg-white) url(${moon}) no-repeat 6px 6px;
	}

	.toggle-label-background:before {
		content: 'Dark';
		position: absolute;
		top: 12px;
		font-weight: var(--font-weight-700);
		border-radius: 5px;
		right: 15px;
		transition: all 150ms ease-in;
		color: var(--font-white);
	}

	@keyframes reverse {
		0% {
			left: 50px;
			width: 40px;
		}
		60% {
			left: 45px;
			width: 40px;
		}
		100% {
			left: 4px;
		}
	}
`;
