const View = ({ data, showSymbol }) => {
	return (
		<div className="grid-icon-info">
			<span className="grid-icon">
				<label>{showSymbol && data?.symbol}</label>
				<label>{data?.name}</label>
			</span>
		</div>
	);
};

const SymbolWithName = ({ symbol, name, showSymbol = true }) => {
	const data = { symbol, name };
	return <View data={data} showSymbol={showSymbol} />;
};

export default SymbolWithName;
