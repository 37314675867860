const Inputs = ({ id, label, register, type, required }) => {
	return (
		<div className="input-container">
			<label htmlFor={id}>{label}</label>
			<input
				name={id}
				id={id}
				type={type}
				{...register(id, {
					required: required
				})}
			/>
		</div>
	);
};

export default Inputs;
