import tradingOptions from '../components/trade/tradingOptions';

const gtmInstances = [
	'trial.machinetrader.io',
	'freedemo.machinetrader.io',
	'demo.machinetrader.io'
];

const demoInstances = [...gtmInstances, 'alpaca.machinetrader.io', 'polygon.machinetrader.io'];
export const isDemoInstance = () => demoInstances.includes(window.location.hostname);
export const isGTMInstances = () => gtmInstances.includes(window.location.hostname);

export const isData = (data) => Object.keys(data).length > 0;
export const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
export const getDataUpTo = (q, data) => data.slice(0, q);

export const parseSentiments = (sentiments) => {
	return sentiments && sentiments.length
		? Number(sentiments[0]['sentiment'] * 100).toFixed(2)
		: 0;
};

export const sentimentIconByText = (value) => {
	return value === 'Positive'
		? 'green-smile.png'
		: value === 'Negative'
		? 'red-smile.png'
		: 'blue-smile.png';
};

export const sentimentIconByValue = (value) => {
	return Number(value) > 0
		? 'green-smile.png'
		: Number(value) < 0
		? 'red-smile.png'
		: 'blue-smile.png';
};

export const getCurrencySymbol = (value) => {
	return value === 'usd' ? '$' : value === 'euro' ? '€' : '$';
};

export const getToken = () => {
	const tokenJSON = localStorage.getItem('auth-tokens');
	return tokenJSON && JSON.parse(tokenJSON).access_token;
};

export const addAuthHeader = (url, requestSettings) => {
	const isAuth = url?.includes('auth/token');
	if (isAuth) {
		return requestSettings;
	}

	const params = requestSettings && isData(requestSettings) ? requestSettings : {};
	!params.headers && (params.headers = {});
	params.headers['Authorization'] = `Bearer ${getToken()}`;
	return params;
};

export const isCrypto = (symbol) => /^X:/i.test(symbol);
export const getSymbolType = (symbol) => (isCrypto(symbol) ? 'crypto' : 'stocks');
export const getTextByValue = (key, value) =>
	tradingOptions[key].find((option) => option.value === value)?.text;

export const focusOnElement = (id) => document.getElementById(id).focus();
export const isPositive = (value) => parseFloat(value) > 0;
export const formatAmount = (value) =>
	!isNaN(value)
		? `$${Number(value).toLocaleString('en-US', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
		  })}`
		: 'N/A';
export const formatNumber = (value) =>
	!isNaN(value)
		? `${Number(value).toLocaleString('en-US', { maximumFractionDigits: 2 })}`
		: 'N/A';

export const formatNumberWithoutFraction = (value) =>
	!isNaN(value)
		? `${Number(value).toLocaleString('en-US', { maximumFractionDigits: 0 })}`
		: 'N/A';

export const timeStamp = () => {
	console.log('timeStamp');
	return new Date().getTime();
};
export const removeSymbolPrefix = (symbol) => symbol?.replace(/x:/gi, '');

export const createSelectedTickerFormat = (symbol) =>
	symbol
		? symbol.split(',').map((description) => {
				return { description };
		  })
		: [];

// issue persist in original package, for now its a tweak
//https://github.com/srigar/multiselect-react-dropdown/issues/216
export const closeMultiSelectList = () =>
	document.querySelector('input[name="search_name_input"]')?.blur();
