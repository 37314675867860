import { useState } from 'react';

const useChartFilter = () => {
	const [chartLineType, setChartLineType] = useState('line');
	const [tickInterval, setChartInterval] = useState('min');
	const [financialMetrics, setFinancialMetrics] = useState('eps');

	const onLineTypeChange = (event) => {
		setChartLineType(event.target.value);
	};

	const onIntervalChange = (event) => {
		setChartInterval(event.target.value);
	};

	const onFinancialMetricsChange = (event) => {
		setFinancialMetrics(event.target.value);
	};

	return {
		onLineTypeChange,
		onIntervalChange,
		onFinancialMetricsChange,
		chartLineType,
		tickInterval,
		financialMetrics
	};
};

export default useChartFilter;
