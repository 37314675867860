import useDataApi from '../../hooks/useDataApi';
import './FredReportSelector.css';

const View = ({ data, selectedTicker, setSelectedTicker }) => {
	return (
		<div className="dropdown-item">
			<select value={selectedTicker} onChange={(e) => setSelectedTicker(e.target.value)}>
				<option value="">All</option>
				{data.length &&
					data.map((item) => (
						<option key={`option-${item.id}`} value={item.id}>
							{item.description}
						</option>
					))}
			</select>
		</div>
	);
};

const FredReportSelector = ({ selectedTicker, setSelectedTicker }) => {
	const [dataProps] = useDataApi('/fred_report_list', [], { method: 'POST' });
	return <View {...{ data: dataProps.data, selectedTicker, setSelectedTicker }} />;
};

export default FredReportSelector;
