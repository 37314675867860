import useChart from './useChart';
import useDataApi from '../../hooks/useDataApi';
import { isData } from '../../utils';
import './Chart.css';

const View = (id, isError) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			<div className="padding-20 padding-top-0" id={id}></div>
		</>
	);
};

const AlgoEditorChart = (props) => {
	const [dataProps] = useDataApi(`/algo-editor-chart/${props.algoEditorId}`, []);
	const { data, isError } = dataProps;
	const chartData = isData(data) ? data : [];
	useChart({
		chartData,
		chartType: 'algoEditor',
		...props
	});
	return View(props.chartId, isError);
};

export default AlgoEditorChart;
