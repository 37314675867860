import useChart from './useChart';
import './Chart.css';
import useDataApi from '../../hooks/useDataApi';
import { isData } from '../../utils';
import SymbolWithName from '../list/cells/SymbolWithName';
import './PophoverChart.css';
import { SymbolLink } from '../../styles/links';

const View = (id, isError, symbol, isCrypto) => {
	const tardelink = isCrypto
		? `/trades?symbol=X:${symbol}&symbol_type=crypto`
		: `/trades?symbol=${symbol}`;
	return (
		<>
			{isError ? (
				<div>Something went wrong</div>
			) : (
				<div className="pophover-chart">
					<div className="pophover-chart-header">
						<span>{symbol}</span>
					</div>
					<div className="pophover-chart-info">
						{symbol && <SymbolWithName value={symbol} showSymbol={false} />}
					</div>
					<div id={id}></div>
					<div className="pophover-chart-footer">
						{!isCrypto && (
							<SymbolLink to={`/tickers?symbol=${symbol}`}>&gt; View More</SymbolLink>
						)}
						<SymbolLink to={tardelink}>&gt; Trade</SymbolLink>
					</div>
				</div>
			)}
		</>
	);
};

const PophoverChart = (props) => {
	const isCrypto = props.symbolType === 'crypto';
	const payload = {
		symbol: props.symbol,
		interval: 'min',
		symbol_type: isCrypto ? 'crypto' : 'stocks'
	};

	const [dataProps] = useDataApi(
		`/timeseries`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		}
	);
	const { data, isError } = dataProps;
	const chartData = isData(data) ? data : [];
	useChart({
		chartData: chartData,
		chartType: 'pophover',
		...props
	});
	return View(props.chartId, isError, props.symbol, isCrypto);
};

export default PophoverChart;
