export default [
	{
		id: 1,
		stock: {
			name: 'Bitcoin',
			symbol: 'BTC',
			thumbnail: '/images/btc-icon.PNG'
		},
		amount: -38.77
	},
	{
		id: 2,
		stock: {
			name: 'Ethereum',
			symbol: 'ETH',
			thumbnail: '/images/eth-icon.PNG'
		},
		amount: 86.5
	},
	{
		id: 3,
		stock: {
			name: 'Binance',
			symbol: 'BNB',
			thumbnail: '/images/bnb-icon.png'
		},
		amount: 22.35
	}
];
