import styled from 'styled-components';

const Styles = styled.div.attrs({
	className: 'sidebar-panel'
})`
	ul {
		list-style: none;

		li {
			cursor: pointer;
			line-height: 3.7rem;
			a {
				color: var(--font-black);
				font-size: var(--font-size-l);
			}
		}
	}
	.active {
		border-left: 5px solid var(--font-orange);
		padding-left: 0.5rem;
	}
`;

export default Styles;
