export default (data, symbol) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)',
			height: 530
		},
		series: [
			{
				name: symbol,
				data,
				color: '#FF6B01',
				type: 'line'
			}
		],
		legend: {
			enabled: false
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			buttonPosition: {
				align: 'right'
			},
			verticalAlign: 'top',
			x: 0,
			y: 0,
			inputEnabled: false,
			buttons: [
				{
					type: 'day',
					count: 1,
					text: '1D'
				},
				{
					type: 'day',
					count: 7,
					text: '1W'
				},
				{
					type: 'month',
					count: 1,
					text: '1M'
				},
				{
					type: 'year',
					count: 1,
					text: '1Y'
				},
				{
					type: 'all',
					text: 'All'
				}
			]
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			tickInterval: 1
		},
		yAxis: [
			{
				labels: {
					align: 'left'
				},
				height: '80%',
				resize: {
					enabled: true
				}
			},
			{
				labels: {
					align: 'left'
				},
				top: '80%',
				height: '20%',
				offset: 0
			}
		],
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 800,
						height: 800
					},
					chartOptions: {
						rangeSelector: {
							inputEnabled: false
						}
					}
				}
			]
		},
		stockTools: {
			gui: {
				enabled: true
			}
		}
	};
};
