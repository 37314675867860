import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SymbolLink = styled(Link)`
	cursor: pointer;

	label {
		cursor: pointer;
		color: var(--font-black);
	}
`;
