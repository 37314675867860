export default {
	live: {
		id: 'f8c7980f-1f7c-4ff4-a50f-5c9dcc6b9448',
		account_number: 'PA32QJZIXPXC',
		status: 'ACTIVE',
		crypto_status: 'ACTIVE',
		currency: 'USD',
		buying_power: '68910.25',
		regt_buying_power: '68910.25',
		daytrading_buying_power: '0',
		non_marginable_buying_power: '0',
		cash: '-34034.5',
		accrued_fees: '35.45260416666666780115',
		pending_transfer_in: '0',
		portfolio_value: '102944.75',
		pattern_day_trader: false,
		trading_blocked: false,
		transfers_blocked: false,
		account_blocked: false,
		created_at: '2022-05-25T17:59:26.586942Z',
		trade_suspended_by_user: false,
		multiplier: '2',
		shorting_enabled: true,
		equity: '102944.75',
		last_equity: '101695.19',
		long_market_value: '136979.25',
		short_market_value: '0',
		initial_margin: '68489.625',
		maintenance_margin: '62981.775',
		last_maintenance_margin: '40718.91',
		sma: '108506.62',
		daytrade_count: 0
	},
	paper: {
		id: '92301971-ff79-41ee-ad2d-4a294bae4635',
		account_number: 'PA3OXJ1MI7RO',
		status: 'ACTIVE',
		crypto_status: 'ACTIVE',
		currency: 'USD',
		buying_power: '179546.36',
		regt_buying_power: '179546.36',
		daytrading_buying_power: '0',
		non_marginable_buying_power: '117363.18',
		cash: '117363.18',
		accrued_fees: '0',
		pending_transfer_in: '0',
		portfolio_value: '115723.18',
		pattern_day_trader: false,
		trading_blocked: false,
		transfers_blocked: false,
		account_blocked: false,
		created_at: '2022-04-27T19:46:58.447246Z',
		trade_suspended_by_user: false,
		multiplier: '2',
		shorting_enabled: true,
		equity: '115723.18',
		last_equity: '115703.18',
		long_market_value: '25130',
		short_market_value: '-26770',
		initial_margin: '25950',
		maintenance_margin: '31632',
		last_maintenance_margin: '15576',
		sma: '110483.18',
		daytrade_count: 0
	}
};
