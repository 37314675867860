export default [
	'ICE BofA US Corporate Index Effective Yield',
	'ICE BofA A US Corporate Index Effective Yield',
	'ICE BofA 1-3 Year US Corporate Index Effective Yield',
	'ICE BofA 3-5 Year US Corporate Index Effective Yield',
	'ICE BofA US High Yield Index Effective Yield',
	'ICE BofA CC & Lower US High Yield Index Option-Adjusted Spread',
	'ICE BofA Euro High Yield Index Effective Yield',
	'Commercial Paper Funding Facility',
	'Federal Funds Target Range - Lower Limit',
	'Federal Funds Target Range - Upper Limit',
	'5-Year Treasury Inflation-Indexed Security, Constant Maturity',
	'7-Year Treasury Inflation-Indexed Security, Constant Maturity',
	'1-Year Treasury Constant Maturity Rate',
	'10-Year Treasury Constant Maturity Rate',
	'2-Year Treasury Constant Maturity Rate',
	'30-Year Treasury Constant Maturity Rate',
	'5-Year Treasury Constant Maturity Rate',
	'Treasury Inflation-Indexed Long-Term Average Yield',
	'1-Year Treasury Bill: Secondary Market Rate',
	'6-Month Treasury Bill: Secondary Market Rate',
	'10-Year 0.5% Treasury Inflation-Indexed Note, Due 1/15/2028',
	'10-Year 0.5% Treasury Inflation-Indexed Note, Due 1/15/2028'
];
