import { LiveAccountIcon } from '../../../styles/icons/icons';
// import AccountDetails from '../../accountDetails/AccountDetails';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './ErrorDialog.css';

const View = ({isOpen, handleStateChange }) => {
	const errors = localStorage.getItem("errors");
// 	console.log(errors)
	function errorCloser()
	{
		localStorage.setItem("errors", "");
		handleStateChange(false);
	}
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Interchange Listed Equity Error"
			handleStateChange={handleStateChange}>
			<header className="modal-header">
				<h1 className="modal-header-text">
					<LiveAccountIcon /> Interchange Listed Equity Error
				</h1>
				<span className="modal-close" onClick={() => errorCloser()}>
					&times;
				</span>
			</header>
			<ModalBody>
				{errors}
			</ModalBody>
			<ModalFooter>
				<button
					className="button-sm btn-orange width-200"
					onClick={() => errorCloser()}>
					Ok
				</button>
			</ModalFooter>
		</ModalDialog>
	);
};

const ErrorDialog = (props) => {
	return <View {...props} />;
};

export default ErrorDialog;
