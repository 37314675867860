import { formatAmount } from '../../../utils';

const Price = ({ value }) => {
	return (
		<div>
			<span>{formatAmount(value)}</span>
		</div>
	);
};

export default Price;
