import styled from 'styled-components';

const Styles = styled.article.attrs({
	className: 'wrapper-container'
})`
	.card {
		min-height: 200px;
	}
	.graph {
		height: 40px;
		max-width: 135px;
	}

	.trade.tab {
		padding: 0px;
	}

	.trade-action .trade-link {
		margin: auto;
		width: 250px;
		padding: 10px;
	}
	.trade-action .trade-link button {
		width: 250px;
	}

	.markets-tab-menu ul.nav li {
		flex: 1;
	}

	.trade.tab ul.nav li.active {
		color: var(--font-orange);
	}

	.multi-select-item .multi-select-icon {
		bottom: 10px;
	}

	.multi-select-wrapper .searchWrapper {
		min-height: unset;
		padding: 10px;
	}

	.multi-select-wrapper .searchWrapper input {
		padding: 10px 0px;
		margin-top: 0px;
	}

	.multi-select-wrapper .searchWrapper .chip {
		padding: 5px 5px;
	}

	@media screen and (min-width: 100px) and (max-width: 676px) {
		.graph {
			height: auto;
			max-width: 80px;
		}

		.trade.tab ul.nav li {
			font-size: var(--font-size-xs);
		}
	}
`;

export default Styles;
