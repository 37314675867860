import styled from 'styled-components';

const Styles = styled.article`
	margin-top: 107px;

	.filter-container {
		margin-bottom: -50px;
	}

	.algo-filter-item {
		display: flex;
		margin: 10px 15px;
		justify-content: right;
		margin-right: 15px;

		div {
			display: flex;
			align-items: center;
			margin-right: 10px;

			.refresh-button {
				top: 8px;
			}
		}
	}
`;

export default Styles;
