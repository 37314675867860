import { useEffect } from 'react';
import useDataApi from '../../hooks/useDataApi';
import { formatAmount } from '../../utils';
import './AccountDetails.css';

const View = ({ data: account = {}, isLoading, isError }) => (
	<div className="account-details">
		{isError && <div>Something went wrong</div>}
		{isLoading ? (
			<div>Loading...</div>
		) : (
			<>
				<div className="detail-item">
					<label>ID</label>
					<div>{account.account_number}</div>
				</div>
				<div className="detail-item">
					<label>Pattern Day Trade</label>
					<div>{account.pattern_day_trader ? 'TRUE' : 'FALSE'}</div>
				</div>
				<div className="detail-item">
					<label>Crypto Status</label>
					<div>{account.crypto_status}</div>
				</div>
				<div className="detail-item">
					<label>Cash Balance</label>
					<div>{formatAmount(account.cash)}</div>
				</div>
				<div className="detail-item">
					<label>Portfolio (Long)</label>
					<div>{formatAmount(account.long_market_value)}</div>
				</div>
				<div className="detail-item">
					<label>Portfolio (Short)</label>
					<div>{formatAmount(account.short_market_value)}</div>
				</div>
				<div className="detail-item">
					<label>Buying Power</label>
					<div>{formatAmount(account.buying_power)}</div>
				</div>
				<div className="detail-item">
					<label>Day Trading Buying Power</label>
					<div>{formatAmount(account.daytrading_buying_power)}</div>
				</div>
				<div className="detail-item">
					<label>RegT Buying Power</label>
					<div>{formatAmount(account.regt_buying_power)}</div>
				</div>
			</>
		)}
	</div>
);

const AccountDetails = (props) => {
	const [dataProps, setUrl] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: props.accountType })
		}
	);
	useEffect(() => {
		setUrl(`/alpaca_account?${props.accountType}`);
	}, [props.accountType]);
	return <View {...dataProps} />;
};

export default AccountDetails;
