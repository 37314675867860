import styled from 'styled-components';

const Styles = styled.div`
	ul {
		padding: 0;
		list-style-type: none;
		li {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			p {
				flex: 0 0 calc(100% / 3);
			}
			a {
				color: var(--font-orange);
				text-decoration: underline;
				cursor: pointer;
				flex: 0 0 calc(100% / 3);
				text-align: right;
			}
			p:first-child {
				color: var(--font-grey);
				::after {
					content: ':';
				}
			}
			.billing-info {
				flex-grow: 1;
				display: flex;
				align-items: center;
				h1 {
					width: 100%;
				}
				p {
					flex: 0 0 calc(100% / 3);
				}
				a {
					flex: 0 0 calc(100% / 3);
				}
				flex-wrap: wrap;
			}
		}
	}
`;

export default Styles;
