import styled from 'styled-components';

const Styles = styled.article.attrs({
	className: 'wrapper-container'
})`
	padding: 0 5rem;
	display: flex;
	h1 {
		border-bottom: var(--border-black-1);
		font-size: var(--font-size-xl);
		line-height: 5rem;
		margin: 0;
	}
	.sidebar-panel {
		width: 300px;
	}
	.profile-page {
		flex: 1 1 auto;
	}
`;

export default Styles;
