export default {
	live: [
		{
			id: 'c134a443-d7e6-4187-b1a5-7802fbf10a38',
			client_order_id: '6463f8b6-bb4f-460b-977b-110dcd32ccc1',
			created_at: '2022-05-25T17:26:58.542981Z',
			updated_at: '2022-05-25T17:26:58.961614Z',
			submitted_at: '2022-05-25T17:26:58.541641Z',
			filled_at: '2022-05-25T17:26:58.958068Z',
			expired_at: null,
			canceled_at: null,
			failed_at: null,
			replaced_at: null,
			replaced_by: null,
			replaces: null,
			asset_id: 'f633ecdd-2bdc-4460-9b33-a3b0e1abbea2',
			symbol: 'open_SPXS',
			name: 'Direxion Daily S&P 500 Bear 3x Shares',
			asset_class: 'us_equity',
			notional: null,
			qty: '1000',
			filled_qty: '1000',
			filled_avg_price: '24.8',
			order_class: '',
			order_type: 'market',
			type: 'market',
			side: 'buy',
			time_in_force: 'day',
			limit_price: null,
			stop_price: null,
			status: 'filled',
			extended_hours: false,
			legs: null,
			trail_percent: null,
			trail_price: null,
			hwm: null,
			source: null
		},
		{
			id: 'e6090598-1c76-40e0-b5e8-60386ba2a81f',
			client_order_id: 'ba0dae39-8e1d-414d-9363-d655f19800a1',
			created_at: '2022-05-25T17:25:28.688729Z',
			updated_at: '2022-05-25T17:25:28.799653Z',
			submitted_at: '2022-05-25T17:25:28.68828Z',
			filled_at: '2022-05-25T17:25:28.796316Z',
			expired_at: null,
			canceled_at: null,
			failed_at: null,
			replaced_at: null,
			replaced_by: null,
			replaces: null,
			asset_id: '33a38003-a8a3-4ce2-b786-71f88c9a03cb',
			symbol: 'open_SPXL',
			asset_class: 'us_equity',
			notional: null,
			qty: '500',
			filled_qty: '500',
			filled_avg_price: '76.07',
			order_class: '',
			order_type: 'market',
			type: 'market',
			side: 'sell',
			time_in_force: 'day',
			limit_price: null,
			stop_price: null,
			status: 'filled',
			extended_hours: false,
			legs: null,
			trail_percent: null,
			trail_price: null,
			hwm: null,
			source: null
		},
		{
			id: '47364073-b3eb-4cba-81df-3cdb1e0d303a',
			client_order_id: 'da42d8bb-4d5f-469a-a0b0-5797d7b3c406',
			created_at: '2022-05-25T17:24:20.408657Z',
			updated_at: '2022-05-25T17:24:20.533101Z',
			submitted_at: '2022-05-25T17:24:20.407407Z',
			filled_at: '2022-05-25T17:24:20.530064Z',
			expired_at: null,
			canceled_at: null,
			failed_at: null,
			replaced_at: null,
			replaced_by: null,
			replaces: null,
			asset_id: '33a38003-a8a3-4ce2-b786-71f88c9a03cb',
			symbol: 'open_SPXL',
			asset_class: 'us_equity',
			notional: null,
			qty: '500',
			filled_qty: '500',
			filled_avg_price: '76.12',
			order_class: '',
			order_type: 'market',
			type: 'market',
			side: 'buy',
			time_in_force: 'day',
			limit_price: null,
			stop_price: null,
			status: 'filled',
			extended_hours: false,
			legs: null,
			trail_percent: null,
			trail_price: null,
			hwm: null,
			source: null
		},
		{
			id: 'a3890d8a-7a0b-42e3-bc7d-50a40dd2ed44',
			client_order_id: '2cf6e487-9904-4a6c-92df-9358b8799c0f',
			created_at: '2022-04-28T19:34:27.194582Z',
			updated_at: '2022-04-28T19:35:04.10061Z',
			submitted_at: '2022-04-28T19:34:27.193979Z',
			filled_at: '2022-04-28T19:35:04.097281Z',
			expired_at: null,
			canceled_at: null,
			failed_at: null,
			replaced_at: null,
			replaced_by: null,
			replaces: null,
			asset_id: 'dce2ac30-c928-4416-be25-2213d057f30a',
			symbol: 'open_TQQQ',
			asset_class: 'us_equity',
			notional: null,
			qty: '1000',
			filled_qty: '1000',
			filled_avg_price: '42.19',
			order_class: '',
			order_type: 'limit',
			type: 'limit',
			side: 'sell',
			time_in_force: 'day',
			limit_price: '42.19',
			stop_price: null,
			status: 'filled',
			extended_hours: false,
			legs: null,
			trail_percent: null,
			trail_price: null,
			hwm: null,
			source: 'access_key'
		},
		{
			id: 'b7c5a28e-dbf3-427b-91df-3428e855dba0',
			client_order_id: '52a82633-8d97-4514-b0ab-9b555850525f',
			created_at: '2022-04-28T19:34:21.324235Z',
			updated_at: '2022-04-28T19:34:21.364019Z',
			submitted_at: '2022-04-28T19:34:21.323493Z',
			filled_at: '2022-04-28T19:34:21.360801Z',
			expired_at: null,
			canceled_at: null,
			failed_at: null,
			replaced_at: null,
			replaced_by: null,
			replaces: null,
			asset_id: 'dce2ac30-c928-4416-be25-2213d057f30a',
			symbol: 'open_TQQQ',
			asset_class: 'us_equity',
			notional: null,
			qty: '1',
			filled_qty: '1',
			filled_avg_price: '42.15',
			order_class: '',
			order_type: 'market',
			type: 'market',
			side: 'buy',
			time_in_force: 'day',
			limit_price: null,
			stop_price: null,
			status: 'filled',
			extended_hours: false,
			legs: null,
			trail_percent: null,
			trail_price: null,
			hwm: null,
			source: 'access_key'
		}
	],
	paper: []
};
