export default (data, symbol) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)',
			height: 189
		},
		series: [
			{
				name: symbol,
				data,
				color: '#FF6B01'
			}
		],
		legend: {
			enabled: true
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			visible: true,
			gridLineWidth: 1,
			tickPixelInterval: 50,
			labels: {
				enabled: true
			}
		},
		yAxis: {
			visible: true,
			gridLineWidth: 1,
			labels: {
				enabled: true
			}
		},
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};
