import { NavLink } from 'react-router-dom';
import ExternalLink from './ExternalLink';

const NavigationLinks = ({ navItems, onMobileNavIconClick, toggleMobileNavigation }) => {
	return (
		<>
			{navItems.map((item) => {
				return (
					<li key={item.text}>
						{!item.external ? (
							<NavLink
								to={item.url}
								onClick={toggleMobileNavigation ? onMobileNavIconClick : undefined}
								className={({ isActive }) =>
									isActive
										? toggleMobileNavigation
											? 'mobile-link-active'
											: 'link-active'
										: ''
								}>
								{item.text}
							</NavLink>
						) : (
							<ExternalLink item={item} />
						)}
					</li>
				);
			})}
		</>
	);
};

export default NavigationLinks;
