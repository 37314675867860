import './AlgoAds.css';
const View = () => {
	return (
		<div className="algo-ads-container hide">
			<div className="algo-ads-inner">
				<div className="algo-ads-info">
					Do you know you easily<span> automate </span> your orders and create your
					<span>
						custom <a href="#"> Algos</a>
					</span>
					<div className="text-center margin-top-30">
						<button className="button-sm btn-orange">Create a custom Algo</button>
					</div>
				</div>
				<div>
					{' '}
					<img src="/images/algos-ads-img.png"></img>{' '}
				</div>
			</div>
		</div>
	);
};

const AlgoAds = () => {
	return View();
};

export default AlgoAds;
