import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../../../static/styles/color-guide.css';
import '../../../../static/styles/font-guide.css';
import '../../../../static/styles/fonts.css';
import '../../../../static/styles/card.css';
import './PageLayout.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const ScrollToTop = (props) => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return <>{props.children}</>;
};

const PageLayout = ({ toggleTheme, children }) => {
	return (
		<ScrollToTop>
			<Header toggleTheme={toggleTheme} />
			<main>{children}</main>
			<Footer />
		</ScrollToTop>
	);
};

const AnonymousPageLayout = ({ children }) => {
	return <>{children}</>;
};

export { PageLayout, AnonymousPageLayout };
