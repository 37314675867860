import Stocks from '../list/cells/Stocks';
import Amount from '../list/cells/Amount';
import Delta from '../list/cells/Delta';
import Price from '../list/cells/Price';
import { SymbolLink } from '../../styles/links';
import { formatNumber } from '../../utils';

export const topStocksShort = [
	{
		Header: 'Symbol',
		accessor: 'stock',
		Cell: (props) => {
			const { symbol, name } = props.row.original;
			const thumbnail = '';
			const stockProps = { value: { symbol, name, thumbnail } };
			return (
				<SymbolLink to={`/tickers?symbol=${stockProps.value.symbol}`}>
					<Stocks {...stockProps} />
				</SymbolLink>
			);
		}
	},
	{
		Header: 'Change',
		accessor: 'price_change',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{
		Header: '%',
		accessor: 'price_change_pct',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Delta {...props} />;
		}
	}
];

export const topStocksLong = () => [
	{
		Header: 'Symbol',
		accessor: 'stock',
		Cell: (props) => {
			const { symbol, name } = props.row.original;
			const thumbnail = '';
			const stockProps = { value: { symbol, name, thumbnail } };
			return (
				<SymbolLink to={`/tickers?symbol=${stockProps.value.symbol}`}>
					<Stocks {...stockProps} />
				</SymbolLink>
			);
		}
	},
	{
		Header: 'Price',
		accessor: 'price',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Price value={props.value} />;
		}
	},
	{
		Header: 'Change',
		accessor: 'price_change',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{
		Header: '%',
		accessor: 'price_change_pct',
		alignment: 'center',
		HeaderClass: 'text-center',
		Cell: (props) => {
			return <Delta {...props} />;
		}
	},
	{
		Header: 'Volume',
		accessor: 'volume',
		Cell: (props) => formatNumber(props.value),
		alignment: 'center',
		HeaderClass: 'text-center'
	}
];
