import { SymbolLink } from '../../styles/links';
import { formatAmount } from '../../utils';
import Amount from '../list/cells/Amount';
import Delta from '../list/cells/Delta';
import Price from '../list/cells/Price';
import SymbolWithName from '../list/cells/SymbolWithName';

export const shortForm = [
	{
		Header: 'Symbol',
		accessor: 'symbol',
		Cell: (props) => {
			return (
				<SymbolLink to={`/tickers?symbol=${props.value}`}>
					<SymbolWithName {...props.row.original} />
				</SymbolLink>
			);
		}
	},
	{
		Header: 'Current Price',
		accessor: 'price24h',
		Cell: (props) => formatAmount(props.value)
	},
	{
		Header: 'Change',
		accessor: 'change24h',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{
		Header: '%',
		accessor: 'pct24',
		Cell: (props) => {
			return <Delta {...props} />;
		}
	}
];

export const getLongFormColumns = () => {
	return [
		{
			Header: 'Symbol',
			accessor: 'symbol',
			Cell: (props) => {
				return (
					<SymbolLink to={`/tickers?symbol=${props.value}`}>
						<SymbolWithName {...props.row.original} />
					</SymbolLink>
				);
			}
		},
		{
			Header: 'Price',
			accessor: 'price24h',
			alignment: 'center',
			HeaderClass: 'text-center',
			Cell: (props) => {
				return <Price value={props.value} />;
			}
		},
		{
			Header: '24h%',
			accessor: 'pct24',
			alignment: 'center',
			HeaderClass: 'text-center',
			Cell: (props) => {
				return <Delta value={props.value} />;
			}
		},
		{
			Header: '7d%',
			accessor: 'pct7d',
			alignment: 'center',
			HeaderClass: 'text-center',
			Cell: (props) => {
				return <Delta value={props.value} />;
			}
		},
		{
			Header: 'Market Cap',
			accessor: 'marketcap',
			alignment: 'center',
			HeaderClass: 'text-center',
			Cell: (props) => {
				return <Price value={props.value} />;
			}
		}
	];
};
