import { useState, useCallback } from 'react';
import useDataApi from '../../hooks/useDataApi';
import { useQueryState } from '../../hooks/useQueryState';
import AccountInfo from '../../components/accountInfo/AccountInfo';
import AccountBalanceChart from '../../components/chart/AccountBalanceChart';
import Portfolio from '../../components/portfolio/Portfolio';
import HeadLines from '../../components/headLines/HeadLines';
import WidgetBox from '../../components/layout/WidgetBox/WidgetBox';
import OrderHistory from '../../components/orderHistory/OrderHistory';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../../components/tabs/Tabs';
import Trade from '../../components/trade/Trade';
import StockInfo from '../../components/stockInfo/StockInfo';
import AlgoAds from '../../components/algoAds/AlgoAds';

import './TradePage.css';
import CustomizeViewButton from '../../components/buttons/CustomizeViewButton';
import OrdersButton from '../../components/buttons/OrdersButton';
import { formatAmount, timeStamp } from '../../utils';
import ConfirmDialog from '../../components/modals/ConfirmDialog/ConfirmDialog';
import CloseOrderButton from '../../components/buttons/CloseOrderButton';
import {
	ClosedOrdersIcon,
	LiveAccountIcon,
	OpenOrdersIcon,
	PaperAccountIcon,
	TradeIcon
} from '../../styles/icons/icons';

import ErrorDialog from '../../components/modals/ErrorDialog/ErrorDialog';

const TradePage = () => {
	const [activetradeSymbol, setTradeSymbol] = useQueryState('symbol');
	const [activeSymbolType] = useQueryState('symbol_type');
	const [accountType, setAccountType] = useState('live');
	const [isConfirmDialog, setIsConfirmDialog] = useState(false);
	const [refresh, setRefresh] = useState(timeStamp);

	const [openErrorDialog, setOpenErrorDialog] = useState(false);

	const refreshPage = useCallback(() => {
		setRefresh(timeStamp);
	}, [refresh]);

	const [paperAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'paper' })
		}
	);
	const [liveAccountProps] = useDataApi(
		`/alpaca_account`,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: 'live' })
		}
	);

		var errors = localStorage.getItem("errors");
		console.log("errors:"+errors)
        var ws;
        var wsUri = "ws:";
        var loc = window.location;
        console.log(loc);
        if (loc.protocol === "https:") { wsUri = "wss:"; }
        // This needs to point to the web socket in the Node-RED flow
        // ... in this case it's ws/simple
		if ( !loc.host.includes("localhost"))
	    {
	        wsUri += "//" + loc.host + "/api/ws/errors";	//loc.pathname.replace("simple","ws/errors");
	    	console.log("server based")
	    }
        else
        {
        	wsUri += "//localhost:1880/ws/errors"
	    	console.log("localhost based")
        }
		console.log(wsUri)
        function wsConnect() {
            console.log("connect",wsUri);
            ws = new WebSocket(wsUri);
            //var line = "";    // either uncomment this for a building list of messages
            ws.onmessage = function(msg) {
                console.log(msg);
                var line = "";  // or uncomment this to overwrite the existing message
                // parse the incoming message as a JSON object
                var data = JSON.parse(msg.data);
                console.log(data);
                // build the output from the topic and payload parts of the object
                if ("error" in data && typeof data.error === "string" && data.error.length !== 0)
				{
					line += "<p>"+data.error+"</p>";
					// replace the messages div with the new "line"
// 					document.getElementById('messages').innerHTML = line;
					localStorage.setItem("errors", data.error);

					setOpenErrorDialog(true);
					//ws.send(JSON.stringify({data:data}));
				}
	            else
					localStorage.setItem("errors", "");
//     	            document.getElementById('messages').innerHTML = "";
            }
            ws.onopen = function() {
                // update the status div with the connection status
//                 document.getElementById('status').innerHTML = "Interlisted socket connected";
                //ws.send("Open for data");
                console.log("Interlisted socket connected");
// 				if (errors.length > 0)
// 				{
// 					setOpenErrorDialog(true);
// 		
// 				}
            }
            ws.onclose = function() {
                // update the status div with the connection status
                document.getElementById('status').innerHTML = "not connected";
                // in case of lost connection tries to reconnect every 3 secs
                setTimeout(wsConnect,3000);
            }
        }
        
        function doit(m) {
            if (ws) { ws.send(m); }
        }
		wsConnect()
// 		ws.disconnect();

	return (
		<div className="wrapper-container">
			<div className="header-container padding-bttom-10 padding-top-20">
				<h3 className="card-title trade-icon">
					<TradeIcon /> Trade
				</h3>

				<div className="header-right-container">
					<CustomizeViewButton />
				</div>
			</div>

			<div className="card-main-container padding-top-0">
				<div className="view-container">
					<div className="card padding-left-zero padding-top-0 tab-transparent">
						<Tabs activeId="live-account-tab" setAccountType={setAccountType}>
							<TabNavItems>
								<TabNavItem
									id="live-account-tab"
									info={formatAmount(liveAccountProps.data?.equity)}
									title="Live Account">
									<LiveAccountIcon />
								</TabNavItem>
								<TabNavItem
									id="demo-account-tab"
									title="Paper Account"
									info={formatAmount(paperAccountProps.data?.equity)}>
									<PaperAccountIcon />
								</TabNavItem>
							</TabNavItems>

							<TabContents>
								<TabContent id="live-account-tab">
									<Trade
										symbol={activetradeSymbol}
										setTradeSymbol={setTradeSymbol}
										activeSymbolType={activeSymbolType}
										accountType="live"
										refreshPage={refreshPage}
									/>
								</TabContent>

								<TabContent id="demo-account-tab">
									<Trade
										symbol={activetradeSymbol}
										setTradeSymbol={setTradeSymbol}
										activeSymbolType={activeSymbolType}
										accountType="paper"
										refreshPage={refreshPage}
									/>
								</TabContent>
							</TabContents>
						</Tabs>

						<WidgetBox header="Open Orders">
							<div className="card-header">
								<h3 className="card-title">
									<OpenOrdersIcon /> Open Orders
								</h3>
							</div>
							<OrderHistory
								orderType="open"
								accountType={accountType}
								refreshPage={refresh}
							/>
							<div className="order-buttons">
								<OrdersButton />
								<CloseOrderButton handleClick={setIsConfirmDialog} />
							</div>
						</WidgetBox>

						<WidgetBox header="Closed Orders">
							<div className="card-header">
								<h3 className="card-title">
									<ClosedOrdersIcon /> Closed Orders
								</h3>
							</div>
							<OrderHistory
								orderType="closed"
								accountType={accountType}
								refreshPage={refresh}
							/>
							<OrdersButton />
						</WidgetBox>
						{isConfirmDialog && (
							<ConfirmDialog
								isOpen={isConfirmDialog}
								handleStateChange={setIsConfirmDialog}
								accountType={accountType}
								refreshPage={refreshPage}
							/>
						)}
						<AlgoAds />
					</div>
				</div>

				<div className="right-side-bar margin-top-30">
					<AccountBalanceChart
						chartId="account-balanace-chart"
						accountType={accountType}
					/>
					<AccountInfo accountType={accountType} />
					<div className="trade-portfolio">
						<Portfolio preview="compact" />
					</div>
					<HeadLines symbol={activetradeSymbol} />
					<div className="trade-info">
						{activetradeSymbol && <StockInfo symbol={activetradeSymbol} />}
					</div>
				</div>
			</div>
			{openErrorDialog && (
				<ErrorDialog
					isOpen={openErrorDialog}
					handleStateChange={setOpenErrorDialog}
					
				/>
			)}
		</div>
	);
};

export default TradePage;
