import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useDataApi from '../../hooks/useDataApi';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import AlgoNameWithId from '../list/cells/AlgoNameWithId';
import AlgoAmountWithDelta from '../list/cells/AlgoAmountWithDelta';
import List from '../list/List';
import AccountStatus from '../list/cells/AccountStatus';
import { AlgoStrategiesIcon } from '../../styles/icons/icons';
import Amount from '../list/cells/Amount';
import PriceWithoutSysmbol from '../list/cells/PriceWithoutSymbol';

const algoStrategiesColumns = [
	{
		Header: 'ID/Name',
		accessor: (data) => <AlgoNameWithId name={data.algo_name} id={data.id} />
	},
	{
		Header: 'Algo Type',
		accessor: 'algo_type',
		alignment: 'center',
		HeaderClass: 'text-center '
	},
	{
		Header: 'Status',
		accessor: 'status',
		Cell: (props) => {
			return <AccountStatus {...props} />;
		}
	},
	{
		Header: 'Portfolio Size',
		accessor: 'portfolio_size',
		alignment: 'center',
		HeaderClass: 'text-center ',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{
		Header: 'Today ($)',
		accessor: (data) => (
			<AlgoAmountWithDelta gain_loss={data.gain_today} gain_loss_pct={data.pctgain_today} />
		),
		SubHeader: '(%)',
		HeaderClass: 'header-notation text-center',
		alignment: 'center'
	},
	{
		Header: 'All Time ($)',
		accessor: (data) => (
			<AlgoAmountWithDelta
				gain_loss={data.gain_alltime}
				gain_loss_pct={data.pctgain_alltime}
			/>
		),
		SubHeader: '(%)',
		HeaderClass: 'header-notation text-center',
		alignment: 'center'
	},
	{
		Header: 'Benchmark',
		accessor: 'pctbench_today',
		Cell: (props) => <PriceWithoutSysmbol {...props} />,
		SubHeader: 'Today %',
		HeaderClass: 'header-notation text-center',
		alignment: 'center'
	},
	{
		Header: 'Benchmark',
		accessor: 'pctbench_alltime',
		Cell: (props) => <PriceWithoutSysmbol {...props} />,
		SubHeader: 'All Time %',
		HeaderClass: 'header-notation text-center',
		alignment: 'center'
	},
	{
		Header: 'Information',
		accessor: 'info_ratio',
		Cell: (props) => <PriceWithoutSysmbol {...props} />,
		alignment: 'center',
		SubHeader: 'Ratio',
		HeaderClass: 'header-notation text-center'
	}
];

const View = ({
	data,
	isLoading,
	isError,
	isPagination = false,
	columns = algoStrategiesColumns
}) => (
	<WidgetBox header="Your Algo Strategies">
		<div className="card-header">
			<h3 className="card-title">
				<AlgoStrategiesIcon /> Your Algo Strategies
			</h3>
		</div>
		{isError && <div>Something went wrong</div>}
		{isLoading ? (
			<div>Loading...</div>
		) : (
			<List newData={data} columns={columns} isPagination={isPagination} />
		)}

		<div className="text-right hide">
			<NavLink to="/algoeditor">
				<button className="button-sm btn-orange">CREATE A NEW AUTOMATION</button>
			</NavLink>
		</div>
	</WidgetBox>
);

const AlgoStrategies = (props) => {
	const url = `/algos?hash=${props.refreshPage}`;
	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST'
	});

	useEffect(() => {
		setUrl(url);
	}, [props.refreshPage]);

	return <View {...dataProps} {...props} />;
};

export default AlgoStrategies;
