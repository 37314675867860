import { useForm, useWatch } from 'react-hook-form';

const useTradeForm = (props) => {
	const {
		register,
		trigger,
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues
	} = useForm({
		defaultValues: props.defaultValues || {}
	});

	const onAmountChange = (e) => {
		setValue('tradeAmount', e.target.value);
		setValue('noOfShares', parseFloat(e.target.value / getValues('currentPrice')).toFixed(6));
	};

	const onCurrentPriceChange = (e) => {
		setValue(
			'tradeAmount',
			parseFloat(e.target.value * getValues('noOfShares') || 0).toFixed(2)
		);
	};

	const onShareCountChange = (e) => {
		setValue('noOfShares', e.target.value);
		setValue('tradeAmount', parseFloat(e.target.value * getValues('currentPrice')).toFixed(2));
	};

	const ShareCountWatch = ({ control, getValues }) => {
		useWatch({ control, name: 'noOfShares' });
		return <>{getValues('noOfShares')}</>;
	};

	return {
		control,
		errors,
		register,
		trigger,
		getValues,
		setValue,
		handleSubmit,
		ShareCountWatch,
		onAmountChange,
		onShareCountChange,
		onCurrentPriceChange
	};
};

export default useTradeForm;
