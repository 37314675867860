import FredReportChart from '../chart/FredReportChart';
import MetricsChart from '../chart/MetricsChart';

const View = ({ item, chartType }) => {
	return (
		<div className="border-dotted">
			<div className="">
				{chartType === 'metrics' && (
					<MetricsChart
						isFullScreen={true}
						chartId={`metrics-chart-${item.name}`}
						label={item.name}
						symbol={item.symbol}
					/>
				)}
				{chartType === 'fred-report' && (
					<FredReportChart
						isFullScreen={true}
						chartId={`fredReport-chart-${item.description}`}
						label={item.description}
						symbol={item.symbol}
						id={item.id}
					/>
				)}
			</div>
		</div>
	);
};

const MetricsBox = (props) => {
	return <View {...props} />;
};

export default MetricsBox;
