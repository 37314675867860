import { useEffect, useState } from 'react';
import useDataApi from '../../hooks/useDataApi';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import { formatNumberWithoutFraction, getCurrencySymbol, getSymbolType } from '../../utils';
import './StockInfo.css';

const View = ({ data = {}, isLoading, isError, toggleShowMore, showMore }) => {
	const tickerResult = data.results || {};
	return (
		<WidgetBox header="Info" icon="/images/trade-info.png">
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					{!tickerResult.name ? (
						<div>Stocks not selected!</div>
					) : (
						<>
							<div className="stock-name">
								<span className="stock-icon hide">
									<img
										src={tickerResult.branding?.icon_url}
										alt={tickerResult.name}
									/>
								</span>
								<span>
									<label>{tickerResult.ticker}</label>
									<label>{tickerResult.name}</label>
								</span>
							</div>
							<div className="stock-desc">
								<div className="stock-desc-head">Description</div>
								<span className={showMore ? 'view-less' : ''}>
									{tickerResult.description}
								</span>

								<div className="text-right">
									<a onClick={() => toggleShowMore()} className="link-text">
										{' '}
										&gt; View {showMore ? 'More' : 'Less'}
									</a>
								</div>
							</div>

							<div className="stock-detail">
								<div>
									<label>Shares</label>
									<span>
										{formatNumberWithoutFraction(
											tickerResult.share_class_shares_outstanding
										)}
									</span>
								</div>

								<div>
									<label>Market Cap</label>
									<span>
										{getCurrencySymbol(tickerResult.currency_name)}
										{formatNumberWithoutFraction(tickerResult.market_cap)}
									</span>
								</div>

								<div>
									<label>Sic</label>
									<span>
										{tickerResult.sic_description} {tickerResult.sic_code}
									</span>
								</div>

								<div>
									<label>Employees</label>
									<span>
										{formatNumberWithoutFraction(tickerResult.total_employees)}
									</span>
								</div>

								<div>
									<label>Listing Date</label>
									<span>{tickerResult.list_date}</span>
								</div>

								<div>
									<label>Primary Exchange</label>
									<span>{tickerResult.primary_exchange}</span>
								</div>
							</div>
							<div className="stock-contact">
								<div className="stock-desc-head">Contact</div>
								<span>{tickerResult.address?.address1}</span>
								<span>
									{tickerResult.address?.city} {tickerResult.address?.state}{' '}
									{tickerResult.address?.postal_code}
								</span>
							</div>
						</>
					)}
				</>
			)}
		</WidgetBox>
	);
};

const StockInfo = (props) => {
	const url = `/ticker_detail`;
	const [showMore, setShowMore] = useState(true);
	const symbol = props.symbol || '';
	const symbolType = props.symbolType || getSymbolType(props.symbol);
	const toggleShowMore = () => setShowMore(!showMore);

	const [dataprops, setUrl] = useDataApi(
		url,
		{},
		{
			method: 'POST',
			body: JSON.stringify({ symbol, symbolType }),
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	useEffect(() => {
		setUrl(`${url}?symbol=${symbol}`);
	}, [symbol]);

	return <View toggleShowMore={toggleShowMore} showMore={showMore} {...dataprops} />;
};

export default StockInfo;
