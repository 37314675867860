import { useEffect, useState } from 'react';
import useDataApi from '../../hooks/useDataApi';
import { removeSymbolPrefix } from '../../utils';
import TradeFormView from './TradeFormView';
import tradingOptions from './tradingOptions';
import useTradeForm from './useTradeForm';

const TradeForm = (props) => {
	const [showVerifyOrder, setShowVerifyOrder] = useState(false);
	const [tradeSubmitData, setTradeSubmitData] = useState({});

	const tradeFormProps = useTradeForm({
		defaultValues: {
			orderType: 'market',
			timeInForce: isCrypto(props.symbolType) ? 'gtc' : 'day'
		},
		...props
	});
	const { setValue } = tradeFormProps;
	const orderTypes = tradingOptions['orderType'];
	const timeInForceList = tradingOptions['timeInForce'];

	const tradeSubmitUrl = `/execute_alpaca_order?verify=${tradeSubmitData.verify}`;
	const [tradeResponseProps, setTradeUrl] = useDataApi(
		'',
		{},
		{
			method: 'POST',
			body: JSON.stringify(tradeSubmitData),
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	const onSubmit = (data) => {
		setTradeSubmitData(createTradeReqBody(props, data));
		setShowVerifyOrder(true);
	};

	const executeTradeOrder = (verify) => {
		const tradeReqBody = { verify, ...tradeSubmitData };
		setTradeSubmitData(tradeReqBody);
	};

	useEffect(() => {
		setDefaultValue(props, setValue);
	}, [props.tradeTabType]);

	useEffect(() => {
		setTradeUrl(tradeSubmitData.verify ? tradeSubmitUrl : '');
	}, [tradeSubmitUrl]);

	return (
		<TradeFormView
			orderTypes={orderTypes}
			timeInForceList={timeInForceList}
			showVerifyOrder={showVerifyOrder}
			setShowVerifyOrder={setShowVerifyOrder}
			executeTradeOrder={executeTradeOrder}
			onSubmit={onSubmit}
			tradeSubmitData={tradeSubmitData}
			tradeResponse={tradeResponseProps.data}
			refreshPage={props.refreshPage}
			{...props}
			{...tradeFormProps}
		/>
	);
};

const setDefaultValue = (props, setValue) => {
	const quickmode = props.tradeTabType === 'quick';
	if (Number(props.currentPrice)) {
		setValue('currentPrice', props.currentPrice || 0);
		setValue('noOfShares', quickmode ? 1 : '');
		setValue(
			'tradeAmount',
			quickmode ? parseFloat(1 * (props.currentPrice || 0).toFixed(2)) : ''
		);
		setValue('stopPrice', props.currentPrice);
		setValue('limitPrice', props.currentPrice);
		setValue('trailPrice', props.currentPrice);
	}
};

const createTradeReqBody = (props, data) => {
	return {
		symbol: removeSymbolPrefix(props.stockInfo.ticker),
		current_price: data.currentPrice,
		limit: data.limitPrice,
		stop: data.stopPrice,
		trail_price: data.trailPrice,
		trail_rate: data.trailRate,
		qty: data.noOfShares,
		side: props.activeTradeType,
		order_type: data.orderType,
		tif: isCrypto(props.symbolType) ? 'gtc' : data.timeInForce,
		amt: data.tradeAmount,
		account_type: props.accountType,
		portfolio: props.defaultPortfolio,
		symbol_type: props.symbolType
	};
};

const isCrypto = (type) => type === 'crypto';

export default TradeForm;
