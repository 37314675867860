import { useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import useDataApi from '../../hooks/useDataApi';
import loadChartModules from './loadChartModules';
import * as ChartConfig from './config/ChartConfig';
import { metricsChartData } from './utils';
import './Chart.css';

const View = ({ isError, isLoading, data, toggleFullScreen, chart, props }) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					<div className="fullscreen-container">
						<span>{props.label}</span>
						<a onClick={toggleFullScreen}>
							<img src="/images/icon_fullscreen.png"></img>
						</a>
					</div>
					<HighchartsReact
						highcharts={Highcharts}
						constructorType={'stockChart'}
						options={ChartConfig.FredReport(metricsChartData(data).data, props.label)}
						ref={chart}
					/>
				</>
			)}
		</>
	);
};

const FredReportChart = (props) => {
	const [dataProps] = useDataApi(`/fred_report`, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ report_name: props.id })
	});

	loadChartModules({
		chartType: 'fredReport',
		...props
	});
	const chart = useRef();
	const toggleFullScreen = () => {
		chart && chart.current.chart.fullscreen.toggle();
	};
	return <View {...dataProps} props={props} toggleFullScreen={toggleFullScreen} chart={chart} />;
};

export default FredReportChart;
