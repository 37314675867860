export const firstPageFormElements = [
	{
		label: 'Legal Name* (Must match government ID',
		id: 'legalname',
		type: 'text',
		required: true
	},
	{
		label: 'Date of Birth * (You must be at least 18 years old. Your DOB must match your government issued ID.)',
		id: 'dob',
		type: 'date',
		required: true
	},
	{ label: 'City', id: 'city', type: 'text', required: false },
	{ label: 'Zip Code', id: 'postalcode', type: 'text', required: false }
	// { label: 'Country of Citizenship', id: 'citizenship' }
];

export const secondPageFormElements = [
	{ label: 'Employer Name *', id: 'employer', type: 'text', required: true },
	{
		label: 'Occupation / Title',
		id: 'occupation',
		type: 'text',
		required: false
	},
	{ label: 'Employer Address', id: 'employer_addr', type: 'text', required: false },
	{ label: 'City', id: 'employer_city', type: 'text', required: false },
	{ label: 'Zip Code', id: 'employer_zip', type: 'text', required: false }
];

export const thirdPageFormElements = [
	{
		label: 'Do you use Market Data solely for your personal, non-business use?',
		id: 'marketdata_personal',
		type: 'checkbox'
	},
	{
		label: 'Do you receive Market Data for your business or any other entity?',
		id: 'marketdata_business',
		type: 'checkbox'
	},
	{
		label: 'Are you currently registered or qualified with the SEC or the CFTC?',
		id: 'registered_sec',
		type: 'checkbox'
	},
	{
		label: 'Are you currently registered or qualified with any securities agency, any securities exchange, association or regulatory body, or any commodities or future contract market, association or regulatory body, in the United States or elsewhere?',
		id: 'registered_any',
		type: 'checkbox'
	}
];

export const fourthPageFormElements = [
	{
		label: 'Whether you are located within or outside of the United States, do you perform any functions that are similar to those that require an individual to register or qualify with the SEC, the CFTC, any other securities agency or regulatory body, any securities exchange or association, or any commodities or futures contract market, association or regulatory body?',
		id: 'registered_need',
		type: 'checkbox'
	},
	{
		label: 'Are you engaged to provide investment advice to any individual or entity?',
		id: 'advice',
		type: 'checkbox'
	},
	{ label: 'Are you engaged as an asset manager?', id: 'asset_manager' },
	{
		label: 'Do you use the capital of any other individual or entity in the conduct of your trading? (trading on margin is ok)',
		id: 'manage_capital',
		type: 'checkbox'
	},
	{
		label: 'Do you conduct trading for the benefit of a corporation, partnership, or other entity?',
		id: 'manage_trading',
		type: 'checkbox'
	},
	{
		label: 'Have you entered into any agreement to share the profit of your trading activities or receive compensation for your trading activities?',
		id: 'share_profits',
		type: 'checkbox'
	},
	{
		label: 'Are you receiving office space, and equipment or other benefits in exchange for your trading or work as a financial consultant to any person, firm or business entity?',
		id: 'benefits',
		type: 'checkbox'
	},
	{
		label: 'By clicking here, I acknowledge that I have reviewed the data Agreement for Market Data Display Service and accept its terms and conditions.',
		id: 'accespt_term',
		type: 'checkbox'
	}
];
export const pg = {
	PAGE_1: 0,
	PAGE_2: 1,
	PAGE_3: 2,
	PAGE_4: 3,
	PAGE_5: 4
};
