import { useEffect, useState } from 'react';
import useDataApi from '../../hooks/useDataApi';
import MetricsDetailDialog from '../modals/MetricsDetailDialog/MetricsDetailDialog';
import MetricsBox from './MetricsBox';

const View = ({ openDialog, setOpenDialog, showDetailDialog, activeItem, data, props }) => {
	return (
		<>
			{data.length
				? data.slice(0, 6).map((item, i) => {
						return (
							<MetricsBox
								key={`key-${i}`}
								item={item}
								handleClick={showDetailDialog}
								chartType={props.type}
							/>
						);
				  })
				: 'Loading...'}

			{openDialog && (
				<MetricsDetailDialog
					isOpen={openDialog}
					item={activeItem}
					handleStateChange={setOpenDialog}
				/>
			)}
		</>
	);
};

const MetricsContainer = (props) => {
	const [openDialog, setOpenDialog] = useState(false);
	const [activeItem, setActiveItem] = useState(0);
	const url =
		props.type === 'metrics'
			? '/market_tickers'
			: props.selectedTicker
			? `/fred_report_list/ticker?${props.selectedTicker}`
			: '/fred_report_list';

	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: props.selectedTicker ? JSON.stringify({ ticker: props.selectedTicker }) : ''
	});

	useEffect(() => {
		setUrl(url);
	}, [props.selectedTicker]);

	const { data } = dataProps;
	const showDetailDialog = (item) => {
		setActiveItem(item);
		setOpenDialog(true);
	};

	return <View {...{ openDialog, setOpenDialog, activeItem, showDetailDialog, data, props }} />;
};

export default MetricsContainer;
