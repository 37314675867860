export default [
	{
		id: '1670966456989',
		asset_id: '1f2ff7fd-8f70-4d1b-aea1-77fcb7716d12',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'AAVEUSD',
		name: 'Aave',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 67.829999998,
		avg_entry_price: 62.00374428608078,
		side: 'long',
		market_value: 4225.13069987542,
		cost_basis: 4205.713974800852,
		unrealized_pl: 19.416725074568223,
		unrealized_plpc: 0.0046167488304974,
		unrealized_intraday_pl: 19.416725074568223,
		unrealized_intraday_plpc: 0.0046167488304974,
		current_price: 62.29,
		lastday_price: 59.59,
		change_today: 0.0453096157073334,
		qty_available: 67.829999998
	},
	{
		id: '1670966456989',
		asset_id: 'e52c17a4-7009-4962-9f71-465f1620874b',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'BATUSD',
		name: 'Basic Attention Token',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 18070.709999999,
		avg_entry_price: 0.2266146860586685,
		side: 'long',
		market_value: 4096.629956999774,
		cost_basis: 4095.088273507015,
		unrealized_pl: 1.54168349275847,
		unrealized_plpc: 0.0003764713700392,
		unrealized_intraday_pl: 1.541683492758465,
		unrealized_intraday_plpc: 0.0003764713700392,
		current_price: 0.2267,
		lastday_price: 0.2208,
		change_today: 0.0267210144927536,
		qty_available: 18070.709999999
	},
	{
		id: '1670966456989',
		asset_id: '4d752b8a-3245-45ca-a407-336c6baca7e6',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'BCHUSD',
		name: 'Bitcoin Cash',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 37.904999998,
		avg_entry_price: 109.23525547453349,
		side: 'long',
		market_value: 4187.74439977904,
		cost_basis: 4140.5623585437215,
		unrealized_pl: 47.18204123531864,
		unrealized_plpc: 0.011395080462431,
		unrealized_intraday_pl: 47.18204123531864,
		unrealized_intraday_plpc: 0.011395080462431,
		current_price: 110.48,
		lastday_price: 105.88,
		change_today: 0.0434454098979977,
		qty_available: 37.904999998
	},
	{
		id: '1670966456989',
		asset_id: 'a59e5f04-cb99-4756-9846-5dcdfc7f4553',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'DAIUSD',
		name: 'Dai',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 4155.585,
		avg_entry_price: 1.000841825,
		side: 'long',
		market_value: 4154.753883,
		cost_basis: 4159.083275342625,
		unrealized_pl: -4.329392342625,
		unrealized_plpc: -0.0010409487033578,
		unrealized_intraday_pl: -4.329392342625,
		unrealized_intraday_plpc: -0.0010409487033578,
		current_price: 0.9998,
		lastday_price: 1.0008,
		change_today: -0.0009992006394884,
		qty_available: 4155.585
	},
	{
		id: '1670966456989',
		asset_id: '0c077710-c350-4813-ad39-d6fe930a30ac',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'LTCUSD',
		name: 'Litecoin',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 53.865,
		avg_entry_price: 77.462975,
		side: 'long',
		market_value: 4185.3105,
		cost_basis: 4172.543148375,
		unrealized_pl: 12.767351625,
		unrealized_plpc: 0.0030598489149171,
		unrealized_intraday_pl: 12.767351625,
		unrealized_intraday_plpc: 0.0030598489149171,
		current_price: 77.7,
		lastday_price: 77.05,
		change_today: 0.0084360804672291,
		qty_available: 53.865
	},
	{
		id: '1670966456989',
		asset_id: 'd6f1de6a-0650-4841-bc16-b0442e7da54b',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'AVAXUSD',
		name: 'Avalanche',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 305.234999998,
		avg_entry_price: 13.637048105540455,
		side: 'long',
		market_value: 4200.03359997248,
		cost_basis: 4162.504378467367,
		unrealized_pl: 37.5292215051132,
		unrealized_plpc: 0.0090160197066102,
		unrealized_intraday_pl: 37.52922150511319,
		unrealized_intraday_plpc: 0.0090160197066102,
		current_price: 13.76,
		lastday_price: 12.89,
		change_today: 0.0674941815360745,
		qty_available: 305.234999998
	},
	{
		id: '1670966456989',
		asset_id: '18e88054-e0eb-41f1-a030-bcf7e8ac51ca',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'ALGOUSD',
		name: 'algorand',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 18892.649999998,
		avg_entry_price: 0.2231991664972713,
		side: 'long',
		market_value: 4231.953599999552,
		cost_basis: 4216.8237329242265,
		unrealized_pl: 15.129867075325322,
		unrealized_plpc: 0.0035879771205977,
		unrealized_intraday_pl: 15.129867075325773,
		unrealized_intraday_plpc: 0.0035879771205978,
		current_price: 0.224,
		lastday_price: 0.216,
		change_today: 0.037037037037037,
		qty_available: 18892.649999998
	},
	{
		id: '1670966603223',
		asset_id: 'b0b6dd9d-8b9b-48a9-ba46-b9d54906e415',
		portfolioname: 'Buy FANG Stocks',
		symbol: 'AAPL',
		name: 'Apple Inc. Common Stock',
		account: 'paper',
		exchange: 'NASDAQ',
		asset_class: 'us_equity',
		asset_marginable: 'false',
		qty: 69,
		avg_entry_price: 145.31,
		side: 'long',
		market_value: 10037.43,
		cost_basis: 10026.39,
		unrealized_pl: 11.04,
		unrealized_plpc: 0.0011010942123735,
		unrealized_intraday_pl: 11.04,
		unrealized_intraday_plpc: 0.0011010942123735,
		current_price: 145.47,
		lastday_price: 144.49,
		change_today: 0.0067824762959374,
		qty_available: 69
	},
	{
		id: '1670966603223',
		asset_id: 'f801f835-bfe6-4a9d-a6b1-ccbb84bfd75f',
		portfolioname: 'Buy FANG Stocks',
		symbol: 'AMZN',
		name: 'Amazon.com, Inc. Common Stock',
		account: 'paper',
		exchange: 'NASDAQ',
		asset_class: 'us_equity',
		asset_marginable: 'false',
		qty: 108,
		avg_entry_price: 92.56,
		side: 'long',
		market_value: 10005.12,
		cost_basis: 9996.48,
		unrealized_pl: 8.64,
		unrealized_plpc: 0.0008643042350908,
		unrealized_intraday_pl: 8.64,
		unrealized_intraday_plpc: 0.0008643042350908,
		current_price: 92.64,
		lastday_price: 90.55,
		change_today: 0.0230811706239647,
		qty_available: 108
	},
	{
		id: '1670966603223',
		asset_id: 'f30d734c-2806-4d0d-b145-f9fade61432b',
		portfolioname: 'Buy FANG Stocks',
		symbol: 'GOOG',
		name: 'Alphabet Inc. Class C Capital Stock',
		account: 'paper',
		exchange: 'NASDAQ',
		asset_class: 'us_equity',
		asset_marginable: 'false',
		qty: 104,
		avg_entry_price: 96.33,
		side: 'long',
		market_value: 9968.4,
		cost_basis: 10018.32,
		unrealized_pl: -49.92,
		unrealized_plpc: -0.0049828713796325,
		unrealized_intraday_pl: -49.92,
		unrealized_intraday_plpc: -0.0049828713796325,
		current_price: 95.85,
		lastday_price: 93.56,
		change_today: 0.0244762719110731,
		qty_available: 104
	},
	{
		id: '1670966603223',
		asset_id: 'bb2a26c0-4c77-4801-8afc-82e8142ac7b8',
		portfolioname: 'Buy FANG Stocks',
		symbol: 'NFLX',
		name: 'Netflix, Inc. Common Stock',
		account: 'paper',
		exchange: 'NASDAQ',
		asset_class: 'us_equity',
		asset_marginable: 'false',
		qty: 31,
		avg_entry_price: 320.78,
		side: 'long',
		market_value: 9929.92,
		cost_basis: 9944.18,
		unrealized_pl: -14.26,
		unrealized_plpc: -0.001434004613754,
		unrealized_intraday_pl: -14.26,
		unrealized_intraday_plpc: -0.001434004613754,
		current_price: 320.32,
		lastday_price: 315.18,
		change_today: 0.0163081413795292,
		qty_available: 31
	},
	{
		id: '1670966603223',
		asset_id: 'fc6a5dcd-4a70-4b8d-b64f-d83a6dae9ba4',
		portfolioname: 'Buy FANG Stocks',
		symbol: 'META',
		name: 'Meta Platforms, Inc. Class A Common Stock',
		account: 'paper',
		exchange: 'NASDAQ',
		asset_class: 'us_equity',
		asset_marginable: 'false',
		qty: 83,
		avg_entry_price: 120.1,
		side: 'long',
		market_value: 9984.9,
		cost_basis: 9968.3,
		unrealized_pl: 16.6,
		unrealized_plpc: 0.0016652789342215,
		unrealized_intraday_pl: 16.6,
		unrealized_intraday_plpc: 0.0016652789342215,
		current_price: 120.3,
		lastday_price: 114.71,
		change_today: 0.0487315839944207,
		qty_available: 83
	},
	{
		id: '1670966456989',
		asset_id: '10d47ad4-8a8f-4dc7-8a7f-079c96c9fedb',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'GRTUSD',
		name: 'The Graph',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 59375.189999997,
		avg_entry_price: 0.0672200741046036,
		side: 'long',
		market_value: 4007.8253249997974,
		cost_basis: 3991.2046717747185,
		unrealized_pl: 16.620653225078954,
		unrealized_plpc: 0.0041643199464608,
		unrealized_intraday_pl: 16.620653225080535,
		unrealized_intraday_plpc: 0.0041643199464612,
		current_price: 0.0675,
		lastday_price: 0.0676,
		change_today: -0.0014792899408284,
		qty_available: 59375.189999997
	},
	{
		id: '1670966456989',
		asset_id: '8ee31d0c-3068-4e7f-bcb5-19b9ba4bfdea',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'NEARUSD',
		name: 'Near',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 2451.855,
		avg_entry_price: 1.7020255268687168,
		side: 'long',
		market_value: 4217.1906,
		cost_basis: 4173.119798180697,
		unrealized_pl: 44.07080181930257,
		unrealized_plpc: 0.0105606366341353,
		unrealized_intraday_pl: 44.070801819302616,
		unrealized_intraday_plpc: 0.0105606366341354,
		current_price: 1.72,
		lastday_price: 1.623,
		change_today: 0.059765865680838,
		qty_available: 2451.855
	},
	{
		id: '1670966456989',
		asset_id: '714d258b-cfef-46be-8e00-9524ef9f42e4',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'PAXGUSD',
		name: 'PAX Gold',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 1.994999998,
		avg_entry_price: 1795.8392919899939,
		side: 'long',
		market_value: 3615.65819637528,
		cost_basis: 3582.699383928359,
		unrealized_pl: 32.95881244692079,
		unrealized_plpc: 0.0091994356531198,
		unrealized_intraday_pl: 32.95881244692079,
		unrealized_intraday_plpc: 0.0091994356531198,
		current_price: 1812.36,
		lastday_price: 1781.07,
		change_today: 0.0175680910913103,
		qty_available: 1.994999998
	},
	{
		id: '1670966456989',
		asset_id: '71a012ba-9ac2-4b08-9cf0-e640f4e45f6c',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'LINKUSD',
		name: 'Chainlink',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 603.487499998,
		avg_entry_price: 6.892331853107007,
		side: 'long',
		market_value: 4176.07315123616,
		cost_basis: 4159.43611918813,
		unrealized_pl: 16.637032048030132,
		unrealized_plpc: 0.0039998287198789,
		unrealized_intraday_pl: 16.637032048030157,
		unrealized_intraday_plpc: 0.0039998287198789,
		current_price: 6.9199,
		lastday_price: 6.6468,
		change_today: 0.0410874405729073,
		qty_available: 603.487499998
	},
	{
		id: '1670966456989',
		asset_id: '1cf35270-99ee-44e2-a77f-6fab902c7f80',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'SOLUSD',
		name: 'Solana',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 301.245,
		avg_entry_price: 13.7963645,
		side: 'long',
		market_value: 4187.9381145,
		cost_basis: 4156.0858238025,
		unrealized_pl: 31.8522906975,
		unrealized_plpc: 0.0076640117764357,
		unrealized_intraday_pl: 31.8522906975,
		unrealized_intraday_plpc: 0.0076640117764357,
		current_price: 13.9021,
		lastday_price: 13.12,
		change_today: 0.0596112804878049,
		qty_available: 301.245
	},
	{
		id: '1670966456989',
		asset_id: 'e39a5235-eafc-499c-937e-0769c4908a80',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'SUSHIUSD',
		name: 'Sushi',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 3583.019999998,
		avg_entry_price: 1.161478059383367,
		side: 'long',
		market_value: 4167.052259997674,
		cost_basis: 4161.599116329468,
		unrealized_pl: 5.4531436682057395,
		unrealized_plpc: 0.0013103481416094,
		unrealized_intraday_pl: 5.453143668205686,
		unrealized_intraday_plpc: 0.0013103481416094,
		current_price: 1.163,
		lastday_price: 1.129,
		change_today: 0.0301151461470328,
		qty_available: 3583.019999998
	},
	{
		id: '1670966456989',
		asset_id: '3530c688-9af2-45ab-98f5-ea31926a64e2',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'MKRUSD',
		name: 'Maker',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 5.984999998,
		avg_entry_price: 598.2678211029352,
		side: 'long',
		market_value: 3628.0938317876044,
		cost_basis: 3580.632908104531,
		unrealized_pl: 47.4609236830734,
		unrealized_plpc: 0.0132548979192055,
		unrealized_intraday_pl: 47.4609236830734,
		unrealized_intraday_plpc: 0.0132548979192055,
		current_price: 606.1978,
		lastday_price: 591.4723,
		change_today: 0.0248963476396105,
		qty_available: 5.984999998
	},
	{
		id: '1670966456989',
		asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'ETHUSD',
		name: 'Ethereum',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 3.99,
		avg_entry_price: 1318.09397185,
		side: 'long',
		market_value: 5279.6079,
		cost_basis: 5259.1949476815,
		unrealized_pl: 20.4129523185,
		unrealized_plpc: 0.0038813834667793,
		unrealized_intraday_pl: 20.4129523185,
		unrealized_intraday_plpc: 0.0038813834667793,
		current_price: 1323.21,
		lastday_price: 1270.21,
		change_today: 0.0417253839916234,
		qty_available: 3.99
	},
	{
		id: '1670966456989',
		asset_id: 'a3ba8ac0-166d-460b-b17a-1f035622dd47',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'DOGEUSD',
		name: 'Dogecoin',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 46180.26,
		avg_entry_price: 0.090597,
		side: 'long',
		market_value: 4230.111816,
		cost_basis: 4183.79301522,
		unrealized_pl: 46.31880078,
		unrealized_plpc: 0.0110710067662285,
		unrealized_intraday_pl: 46.31880078,
		unrealized_intraday_plpc: 0.0110710067662285,
		current_price: 0.0916,
		lastday_price: 0.09,
		change_today: 0.0177777777777778,
		qty_available: 46180.26
	},
	{
		id: '1670966456989',
		asset_id: 'e226ac04-b041-4e1d-a63c-65dacd1f3bb0',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'MATICUSD',
		name: 'Matic',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 4516.68,
		avg_entry_price: 0.92100965,
		side: 'long',
		market_value: 4185.155688,
		cost_basis: 4159.905865962,
		unrealized_pl: 25.249822038,
		unrealized_plpc: 0.0060698061089805,
		unrealized_intraday_pl: 25.249822038,
		unrealized_intraday_plpc: 0.0060698061089805,
		current_price: 0.9266,
		lastday_price: 0.904,
		change_today: 0.025,
		qty_available: 4516.68
	},
	{
		id: '1670966456989',
		asset_id: '24cc8b92-9c7a-41d6-96c0-20c2e9f85c18',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'UNIUSD',
		name: 'Uniswap',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 687.277499998,
		avg_entry_price: 6.05652872342445,
		side: 'long',
		market_value: 4208.818682237752,
		cost_basis: 4162.515919701234,
		unrealized_pl: 46.30276253651779,
		unrealized_plpc: 0.0111237442522121,
		unrealized_intraday_pl: 46.302762536517825,
		unrealized_intraday_plpc: 0.0111237442522121,
		current_price: 6.1239,
		lastday_price: 5.9076,
		change_today: 0.0366138533414585,
		qty_available: 687.277499998
	},
	{
		id: '1670966456989',
		asset_id: 'c4ab268a-0ebf-4332-82d7-a8ddb90304a0',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'TRXUSD',
		name: 'TRON',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 83125.665,
		avg_entry_price: 0.05441625,
		side: 'long',
		market_value: 4538.661309,
		cost_basis: 4523.38696805625,
		unrealized_pl: 15.27434094375,
		unrealized_plpc: 0.0033767486734202,
		unrealized_intraday_pl: 15.27434094375,
		unrealized_intraday_plpc: 0.0033767486734202,
		current_price: 0.0546,
		lastday_price: 0.0531,
		change_today: 0.0282485875706215,
		qty_available: 83125.665
	},
	{
		id: '1670966456989',
		asset_id: '91cc2c51-0853-47bc-af0a-430d4fce38dc',
		portfolioname: 'Buy Crypto Portfolio',
		symbol: 'WBTCUSD',
		name: 'Wrapped BTC',
		account: 'paper',
		exchange: 'FTXU',
		asset_class: 'crypto',
		asset_marginable: 'false',
		qty: 0.29925,
		avg_entry_price: 17762.79,
		side: 'long',
		market_value: 4992.98625,
		cost_basis: 5315.5149075,
		unrealized_pl: -322.5286575,
		unrealized_plpc: -0.060676841869999,
		unrealized_intraday_pl: 0,
		unrealized_intraday_plpc: 0,
		current_price: 16685,
		lastday_price: 16685,
		change_today: 0,
		qty_available: 0
	}
];
