import NextPrevNavigatorStyles from './NextPrevNavigatorStyle';

const View = ({ onChange, offset, limit, count }) => {
	return (
		<NextPrevNavigatorStyles>
			<button
				className="navigator round"
				onClick={() => onChange('prev')}
				disabled={offset === 0}>
				&lt;
			</button>
			<button
				className="navigator round"
				onClick={() => onChange('next')}
				disabled={limit > count}>
				&gt;
			</button>
		</NextPrevNavigatorStyles>
	);
};
const NextPrevNavigator = (props) => {
	const { offset, limit, setOffset } = props;
	const onChange = (direction) =>
		direction === 'prev' ? setOffset(offset - limit) : setOffset(offset + limit);
	return <View onChange={onChange} {...props} />;
};

export default NextPrevNavigator;
