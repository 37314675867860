import { useState } from 'react';
import AccountInfo from '../../components/accountInfo/AccountInfo';
import Portfolio from '../../components/portfolio/Portfolio';
import { Tabs, TabNavItems, TabNavItem, TabContents, TabContent } from '../../components/tabs/Tabs';
import { LiveAccountIcon, PaperAccountIcon } from '../../styles/icons/icons';

const PortfolioPage = () => {
	const [accountType, setAccountType] = useState('live');
	return (
		<div className="wrapper-container">
			<div className="header-container padding-bttom-10 padding-top-20">
				<h3 className="card-title trade-icon"></h3>
			</div>
			<div className="card-main-container padding-top-0">
				<div className="view-container">
					<div className="card padding-left-zero padding-top-0 tab-transparent">
						<Tabs activeId="live-account-tab" setAccountType={setAccountType}>
							<TabNavItems>
								<TabNavItem id="live-account-tab" title="Live Account">
									<LiveAccountIcon />
								</TabNavItem>

								<TabNavItem id="demo-account-tab" title="Paper Account">
									<PaperAccountIcon />
								</TabNavItem>
							</TabNavItems>

							<TabContents>
								<TabContent id="live-account-tab">
									<Portfolio type="page" accountType="live" />
								</TabContent>

								<TabContent id="demo-account-tab">
									<Portfolio type="page" accountType="paper" />
								</TabContent>
							</TabContents>
						</Tabs>
					</div>
				</div>
				<div className="right-side-bar">
					<AccountInfo accountType={accountType} />
				</div>
			</div>
		</div>
	);
};

export default PortfolioPage;
