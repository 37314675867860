import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useDataApi from '../../../hooks/useDataApi';
import Styles from '../profile/ProfileStyles';

const View = ({ register, handleSubmit, onSubmit, errors, data }) => {
	return (
		<Styles>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="inputs">
					<label htmlFor="oldPassword">Current Password</label>
					<input
						name="oldPassword"
						id="oldPassword"
						type="password"
						{...register('oldPassword')}
					/>
					<span className="error">{errors.oldPassword?.message}</span>
				</div>

				<div className="inputs">
					<label htmlFor="newPassword">New Password</label>
					<input
						name="newPassword"
						id="newPassword"
						type="password"
						{...register('newPassword')}
					/>
					<span className="error">{errors.newPassword?.message}</span>
				</div>

				<div className="inputs">
					<label htmlFor="confirmPassword">Confirm Password</label>
					<input
						name="confirmPassword"
						id="confirmPassword"
						type="password"
						{...register('confirmPassword')}
					/>
					<span className="error">{errors.confirmPassword?.message}</span>
				</div>
				<br />
				<button type="submit" className="button-sm btn-orange">
					{' '}
					Submit{' '}
				</button>
				<br />
				<br />
				<label>{data?.message}</label>
			</form>
		</Styles>
	);
};

const sendRequest = (data, setBody, setUrl) => {
	const timeStamp = new Date().getTime();
	const body = new URLSearchParams(data);
	setBody(body);
	setUrl(`/change-password?hash=${timeStamp}`);
};

const ChangePassword = () => {
	const [body, setBody] = useState('');

	const formSchema = Yup.object().shape({
		oldPassword: Yup.string().required('This field is required'),
		newPassword: Yup.string()
			.required('This field is required')
			.min(6, 'Password must be at 6 char long'),
		confirmPassword: Yup.string()
			.required('This field is required')
			.oneOf([Yup.ref('newPassword')], 'Passwords does not match')
	});
	const formOptions = { resolver: yupResolver(formSchema) };
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm(formOptions);

	const onSubmit = (data) => {
		sendRequest(data, setBody, setUrl);
		reset();
	};

	const [submitProps, setUrl] = useDataApi(
		null,
		{},
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
			body
		}
	);
	return (
		<View
			handleSubmit={handleSubmit}
			register={register}
			onSubmit={onSubmit}
			errors={errors}
			{...submitProps}
		/>
	);
};

export default ChangePassword;
