import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const newsTimeFormat = (datetime) => dayjs(datetime).format('ddd MMM DD YYYY HH:mm:ss z');
export const breakingNewsTimeFormat = (datetime) => dayjs(datetime).format('HH:mm - MMM DD, YYYY');
export const AlgoStrategiesTimeFormat = (datetime) => dayjs(datetime).format('DD/MM/YYYY HH:mm A');
