import SortByDiv from './SortByStyles';
const SortBy = (props) => {
	return (
		<SortByDiv>
			<label> Sort By: </label>
			<select onChange={props.handleChange} value={props.value}>
				{props.items.map((item) => {
					return (
						<option key={item.value} value={item.value}>
							{item.text}
						</option>
					);
				})}
			</select>
		</SortByDiv>
	);
};

export default SortBy;
