import { useEffect } from 'react';
import useChart from './useChart';
import useDataApi from '../../hooks/useDataApi';
import { isData } from '../../utils';
import ChartFilter from './ChartFilter';
import useChartFilter from './useChartFilter';
import './Chart.css';

const View = (
	id,
	toggleFullScreen,
	isError,
	onLineTypeChange,
	onIntervalChange,
	tickInterval,
	chartLineType
) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			<div className="margin-right-30 text-right fullscreen-icon">
				<a onClick={toggleFullScreen}>
					<img src="/images/icon_fullscreen.png"></img>
				</a>
			</div>
			<div className="chart-type-item ">
				<div>
					<label> Chart Type: </label>
					<ChartFilter
						type="linetype"
						data={chartLineType}
						handleChange={onLineTypeChange}
					/>
				</div>
				<div>
					<label> Intervals:</label>
					<ChartFilter
						type="interval"
						data={tickInterval}
						handleChange={onIntervalChange}
					/>
				</div>
			</div>

			<div id={id}></div>
		</>
	);
};

const TradeChart = (props) => {
	const { chartLineType, tickInterval, onLineTypeChange, onIntervalChange } = useChartFilter();
	const url = props.symbol
		? tickInterval === 'sec'
			? `/oneseccharts?symbol=${props.symbol}&symbol_type=${props.symbolType}&tickInterval=${tickInterval}`
			: `/timeseries?symbol=${props.symbol}&symbol_type=${props.symbolType}&tickInterval=${tickInterval}`
		: '';

	const [dataProps, setUrl] = useDataApi(
		url,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				symbol: props.symbol,
				symbol_type: props.symbolType,
				account_type: props.accountType,
				interval: tickInterval
			})
		}
	);

	useEffect(() => {
		setUrl(url);
	}, [url]);

	const { data, isError } = dataProps;
	const chartData = isData(data) ? data : [];

	const { chart } = useChart({
		chartData: chartData,
		chartType: 'trade',
		chartLineType,
		tickInterval,
		symbolType: props.symbolType,
		...props
	});

	const toggleFullScreen = () => {
		chart && chart.fullscreen.toggle();
	};
	return View(
		props.chartId,
		toggleFullScreen,
		isError,
		onLineTypeChange,
		onIntervalChange,
		tickInterval,
		chartLineType
	);
};

export default TradeChart;
