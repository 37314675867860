import { useState } from 'react';
import { AccountBalanceIcon } from '../../styles/icons/icons';
import FinancialChart from '../chart/FinancialChart';
import TradeChart from '../chart/TradeChart';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import TickerActionView from './TickerActionView';

const View = ({ symbol, symbolType, accountType, setActiveSymbol }) => (
	<>
		<TickerActionView
			symbol={symbol}
			setActiveSymbol={setActiveSymbol}
			symbolType={symbolType}
		/>

		<WidgetBox header="Ticker Price" link="#">
			<div className="card-header">
				<h3 className="card-title">
					<AccountBalanceIcon /> Ticker Price
				</h3>
			</div>
			{symbol && (
				<TradeChart
					isFullScreen={true}
					chartId="ticker-chart"
					symbol={symbol}
					symbolType={symbolType}
					accountType={accountType}
				/>
			)}
		</WidgetBox>

		<WidgetBox header="Financial Metrics" link="#">
			<div className="card-header">
				<h3 className="card-title">
					<AccountBalanceIcon /> Financial Metrics
				</h3>
			</div>
			{symbol && <FinancialChart symbol={symbol} />}
		</WidgetBox>
	</>
);

const TickerDetail = (props) => {
	const [symbolType, setSymbolType] = useState('stocks');

	return (
		<View
			{...{
				setSymbolType,
				symbolType,
				accountType: props.accountType,
				symbol: props.activeSymbol,
				setActiveSymbol: props.setActiveSymbol
			}}
		/>
	);
};

export default TickerDetail;
