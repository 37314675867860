import styled from 'styled-components';

const Styles = styled.article`
	margin-top: 115px;

	.card-header {
		border: none;
	}

	.filter-container {
		margin-bottom: -50px;
	}

	div.back-info {
		justify-content: space-between;

		a {
			color: var(--font-Darkgrey);
			font-weight: var(--font-weight-600);
		}
	}

	.order-filter-item {
		display: flex;
		margin: 10px 15px;
		justify-content: right;
		margin-right: 15px;

		div {
			display: flex;
			align-items: center;
			margin-right: 10px;

			label {
				font-size: var(--font-size-s);
				color: var(--font-Darkgrey);
				white-space: nowrap;
				display: block;
				margin-right: 10px;
				font-weight: var(--font-weight-600);
			}

			input[type='date'],
			select {
				border: var(--border-grey-1);
				color: var(--font-grey2);
				height: 30px;
				font-size: var(--font-size-m);
				background: var(--bg-white);
				border-radius: 5px;
				outline: none;
				margin-top: 5px;
				width: 150px;
				padding: 0px 5px;
			}

			select {
				height: 30px;
			}

			.button-sm {
				background: transparent;
				padding: 0px;
				margin-top: 5px;

				.button-icons {
					width: 25px;
				}
			}
		}
	}

	@media screen and (min-width: 100px) and (max-width: 840px) {
		margin-top: 80px;

		.filter-container {
			margin-bottom: 0px;
		}

		.order-filter-item div {
			display: block;

			input[type='date'],
			select {
				width: 130px;
			}

			.button-sm {
				margin-top: 24px;
			}
		}
	}
`;

export default Styles;
