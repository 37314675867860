import CompanyLogo from '../../../styles/companyLogo/CompanyLogo';
import { footerItems } from '../navigation/navigationItems';
import NavigationLinks from '../navigation/NavigationLinks';
import './Footer.css';

const Footer = () => {
	return (
		<div className="footer">
			<div className="footer-inner">
				<div className="footer-col first-col">
					<CompanyLogo />
					<div className="footer-about">
						Trade commission-free* while automating your strategies using our
						low-code/no-code MachineTrader™
						<div className="footer-star">* Low fees apply on Crypto trading</div>
					</div>
					<div className="social-icon">
						<ul>
							{socialMediaIcons.map((item) => {
								return (
									<li key={item.key}>
										<a href={item.url}>
											<img src={`${item.icon}`} alt={item.key} />
										</a>
									</li>
								);
							})}
						</ul>
					</div>
				</div>

				<div className="footer-col second-col">
					<div className="footer-header">NAVIGATION</div>
					<div className="footer-menu">
						<ul>
							<NavigationLinks navItems={footerItems} />
						</ul>
					</div>
				</div>

				<div className="footer-col">
					<div className="footer-header">ABOUT US</div>
					<div className="footer-address">
						<ul>
							<li>MachineTrader™</li>
							<li>30 Wall Street, 8th Floor</li>
							<li>New York, NY 10005</li>
						</ul>
					</div>

					<div className="footer-contact">
						<ul>
							<li>
								<a href="tel:2123654525">
									<img src="/images/icon-phone.png" />: 212-365-4525
								</a>
							</li>
							<li>
								<a href="mailto:info@machinetrader.io">
									<img src="/images/icon-email.png" />: info@machinetrader.io
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="copyright"> © 2022 MachineTrader. All Rights Reserved. </div>
		</div>
	);
};

const socialMediaIcons = [
	{ key: 'facebook', url: 'www.facebook.com', icon: '/images/facebook-icon.png' },
	{ key: 'twitter', url: 'www.twitter.com', icon: '/images/twitter-icon.png' },
	{ key: 'instagram', url: 'www.instagram.com', icon: '/images/instagram-icon.png' },
	{ key: 'youtube', url: 'www.youtube.com', icon: '/images/youtube-icon.png' }
];

export default Footer;
