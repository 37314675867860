export default {
	id: 1,
	title: 'AMZN, Decisive H-S-FORMATION, Setup For BROADER PULLBACKS!',
	sentiments: '85 % Positive Sentiment',
	sentimentsCategory: 1,
	dateTime: 'JANUARY 20 2022',
	company: 'Amazon.com,Inc (NASDAQ:AMZN)',
	comments: 12,
	likes: 5,
	image: 'news-picture.png',
	description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa non sed rhoncus etiam imperdiet dui
                odio sed nisl. Urna, donec risus nec magna duis mattis bibendum. Vitae nec nascetur sem magna
                urna mi. Fames interdum at varius auctor. A in pharetra, pellentesque fermentum vel. Sapien
                suspendisse tincidunt present in ac get. Mi dignissim vel aliquet get sit ut proin ac. Scelerisque
                massa massa risus aliquet matti turpis viverra. Volutpat vitae egestas purus vestibulum. convallis
                sollicitudin lorem. Elit nulla at get ultricies aliquam tortor. Sociis id neque, arcu dictum ipsum tellus
                posuere rhoncus. Vitae sed semper platea ut. Sit ornare quam volutpat pellentesque imperdiet
                tincidunt auctor in a. A sed suspendisse mi get venenatis etiam ut.
                Lacus, amet consectetur morbi feugiat. Tgtor semper enim et egestas cursus amet pulvinar nec.
                Dapibus scelerisque urna volutpat nibh a pellentesque nisi. Blandit porttitor suspendisse amet sit.
                Lacinia proin isl curabitur proin get parturient. Lectus tincidunt mi mi get aliquam quis urna at
                sed. Id ipsum, dignissim pellentesque faucibus risus malesuada duis praesent.
                Purus commodo est ipsum et. Mauris nunc at tincidunt velit, id nunc, Donec viverra elit, enim diam
                cras consequat. Leo netus neque, mauris, amet, vitae lacus, pharetra et. Urna, blandit ut dictum
                lorem tortor amet. Enim sed volutpat facilisis pellentesque bibendum iaculis ipsum. Et massa
                adipiscing elit, congue. Tortor tellus velit et, vestibulum tortor. Amet iaculis dictum pharetra
                pretium.
                Neque feugiat tincidunt tellus tincidunt. Ullamcorper sed in pellentesque sed vivamus. Ornare
                consequat in arcu netus. Urna, mi. iaculis in amet interdum tortor quis turpis rutrum. Vulputate
                interdum arcu senectus done vitae scelerisque mus tristique arcu. Mi, elementum amet odio
                adipiscing cursus turpis pharetra amet.
                Nisi pellentesque elit, malesuada posuere ut egestas purus. Pretium, non at fames present iaculis.
                At done nunc quis posuere sit imperdiet. Egestas enim quis suspendisse morbi vitae odio faucibus
                sit. Quam proin augue diam arcu massa. Commodo done pharetra tristique cras ac risus lorem
                tortor dui. Duis vel cursus auctor get done aliquam. Id aenean nulla dignissim massa commodo
                quisque sapien. Vitae nam aliquam suscipit magna viverra vitae porttitor blandit. Quam tellus ipsum
                maecenas in nullam. Etiam nulla natoque tellus aliquet diam. Quis quis cursus ultricies maecenas
                velit at tortor. Nascetur felis libero ac integer. Tellus arcu nunc diam iaculis ullamcorper curabitur
                facilisis nulla.
                A done magna amet felis nulla est. Pharetra, amet, tellus turpis blandit blandit integer aenean.
                Mauis consect&bur metus tristique pellentesque fermentum tempus, a. Id enim nulla donec
                imperdiet cras. Tincidunt ut scelerisque amet viverra. Sed elementum actor evismod magna et
                ipsum, faucibus adipiscing viverra. Enim fermentum ipsum quam et morbi proin amet vitae etiam.
                Cursus lectus et ut lacus volutpat placerat placerat urna. Metus massa vitae enim suspendisse.
                Purus, tincidunt netus leo quam nullam malesuada quam. Eu nunc, dui get morbi aliquam.
                Ullamcorper fermentum placerat erat adipiscing aliquet.
                Et placerat vulputate sem augue mauris id a eu. Matti pellentesque gravida pretium sapien. Nec
                vestibulum, diam proin gravida. In id tellus nec gravida faucibus nunc urna, arcu. Purus pulvinar
                eleifend laoreet dui sapien ut. Vitae amet, morbi risus duis. Sem ut elementum ultrices adipiscing
                enim sollicitudin. Etiam risus sed sit nulla at mi diam sed tempor. Pellentesque enim, lectus urna
                matti nec pellentesque viverra purus morbi. Mi mi mi blandit pharetra urna, tempus aliquet. Viverra condimentum aenean noque eget non quam
                vehicula. Bibendum eu amet, vitae nisl sapien, in faucibus. Nunc leo pulvinar accumsan mi auctor.
                Tellus sed a turpis placerat purus nibh facilisi. At sed pellentesque scelerisque porttitor facilisis in.
                Fames dictum mi elementum fuse vel ultricies. Aliquam a, pellentesque eu dictum. Quis mattis
                faucibus in nunc aliquet tellus sed leo. Aliquet risus ut velit pharetra malesuada sed dapibus dolor.
                Et sed posuere nulla pellentesque. Tristique sed etiam ac matti libero, aliquam, adipiscing tempus
                amet. Fringilla sit commodo tellus in tells tristique in gravida tincidunt. Non tells, eu consectetur
                malesuada nisi, leo venenatis ut.
                Tristique nunc sed pulvinar mi morbi. Riss in suspendisse vulputate nunc porttitor adipiscing.
                Pellentesque adipiscing gravida scelerisque temps dis ultrices integer. Pellentesque nulla odio
                cras duis facilisis volutpat diam. Convallis pron neque nisi quisque.
                Ornare mi aliquam urn, penatibus. Diam *lutpat mattis molestie justo, sed hendrerit dignissim.
                Diam, nil enim gravida aenean faucibus velit. Duis nibh at mi odio ullamcorper arcu. Commodo
                volutpat vestibulum ultricies id et vitae quam malesuada.
                Tellus tincidunt tristique convallis quisque quisque. At nulla volutpat id commodo tempus tempus nec.`
};
