// import './Paginator.css';
import Styles from './PaginatorStyles';

const Paginator = (props) => {
	const {
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageIndex,
		pageOptions,
		pageSize,
		pageCount,
		setPageSize
	} = props;

	const pageSizes = ['All', 3, 5, 10, 20, 30, 40, 50, 100, 500];
	return (
		<Styles>
			<div className="page-info">
				{pageIndex + 1} out of {pageOptions.length}
			</div>
			<div className="page-navigator">
				<button
					className="next-prev-btn"
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}>
					{' '}
					{'<<'}{' '}
				</button>{' '}
				<button
					className="next-prev-btn"
					onClick={() => previousPage()}
					disabled={!canPreviousPage}>
					{'<'}
				</button>{' '}
				<div className="page-numbers">
					{getPageNumbers(pageIndex, pageCount, 2).map((item, index) => {
						return (
							<div
								key={`page-number-${index}`}
								className={`page-number ${
									item === pageIndex + 1
										? 'selected'
										: isNaN(item)
										? 'dotted'
										: 'active'
								}`}
								onClick={() => gotoPage(item - 1)}>
								{item}
							</div>
						);
					})}
				</div>
				<button
					className="next-prev-btn"
					onClick={() => nextPage()}
					disabled={!canNextPage}>
					{'>'}
				</button>{' '}
				<button
					className="next-prev-btn"
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}>
					{'>>'}{' '}
				</button>{' '}
			</div>
			<div className="pagination">
				Show &nbsp;
				<select
					value={pageSize}
					onChange={(e) => {
						const pgSize = e.target.value === 'All' ? 999 : e.target.value;
						setPageSize(Number(pgSize));
					}}>
					{pageSizes.map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							{pageSize}
						</option>
					))}
				</select>
			</div>
		</Styles>
	);
};

const getRange = (start, end) =>
	Array(end - start + 1)
		.fill()
		.map((v, i) => i + start);

const getPageNumbers = (current, length, delta = 4) => {
	const range = {
		start: Math.round(current - delta / 2),
		end: Math.round(current + delta / 2)
	};

	if (range.start - 1 === 1 || range.end + 1 === length) {
		range.start += 1;
		range.end += 1;
	}

	let pages =
		current > delta
			? getRange(Math.min(range.start, length - delta), Math.min(range.end, length))
			: getRange(1, Math.min(length, delta + 1));

	const withDots = (value, pair) => (pages.length + 1 !== length ? pair : [value]);

	if (pages[0] !== 1) {
		pages = withDots(1, [1, '...']).concat(pages);
	}

	if (pages[pages.length - 1] < length) {
		pages = pages.concat(withDots(length, ['...', length]));
	}

	return pages;
};

export default Paginator;
