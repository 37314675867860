import Styles from './ProfileStyles';
import SingleInput from './SingleInput';

const profileInputs = [
	{
		label: 'Login Name',
		id: 'login',
		url: '/profile/login',
		type: 'text',
		required: true,
		defaultId: 'login_name'
	},
	{
		label: 'First Name',
		id: 'firstname',
		url: '/profile/firstname',
		type: 'text',
		required: true,
		defaultId: 'FIRSTNAME'
	},
	{
		label: 'Last Name',
		id: 'lastname',
		url: '/profile/lastname',
		type: 'text',
		required: true,
		defaultId: 'LASTNAME'
	},
	{
		label: 'Password',
		id: 'password',
		url: '/profile/password',
		type: 'password',
		required: true,
		defaultId: 'PASSWORD'
	},
	{
		label: 'Address',
		id: 'address',
		url: '/profile/address',
		type: 'text',
		required: true,
		defaultId: 'ADDRESS'
	},
	{
		label: 'City',
		id: 'city',
		url: '/profile/city',
		type: 'text',
		required: true,
		defaultId: 'CITY'
	},
	{
		label: 'Zip',
		id: 'postalcode',
		url: '/profile/postalcode',
		type: 'text',
		required: true,
		defaultId: 'POSTALCODE'
	},
	{
		label: 'State',
		id: 'state',
		url: '/profile/state',
		type: 'text',
		required: true,
		defaultId: 'STATE'
	},
	{
		label: 'Country',
		id: 'country',
		url: '/profile/country',
		type: 'country',
		required: true,
		defaultId: 'COUNTRY'
	}
];
const tradingAccInputs = [
	{
		label: 'Alpaca Status',
		id: 'alpaca_status',
		url: '/profile/alpaca_status',
		type: 'text',
		defaultId: 'ALPACA_STATUS'
	},
	{
		label: 'Live Account API Account ID',
		id: 'live_acctid',
		url: '/profile/live_acctid',
		type: 'text',
		defaultId: 'LIVE_ACCTID'
	},
	{
		label: 'Live Account API Secret Key',
		id: 'live_secretkey',
		url: '/profile/live_secretkey',
		type: 'password',
		defaultId: 'LIVE_SECRETKEY'
	},
	{
		label: 'Paper Account API Account ID',
		id: 'paper_acctid',
		url: '/profile/paper_acctid',
		type: 'text',
		defaultId: 'PAPER_ACCTID'
	},
	{
		label: 'Paper Account API Secret Key',
		id: 'paper_secretkey',
		url: '/profile/paper_secretkey',
		type: 'password',
		defaultId: 'PAPER_SECRETKEY'
	}
];

const View = ({ profileDefault }) => {
	return (
		<Styles>
			{profileInputs.map(({ label, id, url, type, required, defaultId }) => {
				return (
					<SingleInput
						key={id}
						id={id}
						label={label}
						url={url}
						type={type}
						defaultValues={{ [id]: profileDefault[defaultId] }}
						required={required}
					/>
				);
			})}
			<h2>Trading Accounts</h2>
			{tradingAccInputs.map(({ label, id, url, type, defaultId }) => {
				return (
					<SingleInput
						key={id}
						id={id}
						label={label}
						url={url}
						type={type}
						defaultValues={{ [id]: profileDefault[defaultId] }}
						required
					/>
				);
			})}
		</Styles>
	);
};

const Profile = ({ profileDefault = {} }) => {
	return <View profileDefault={profileDefault} />;
};

export default Profile;
