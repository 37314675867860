export default [
	{
		symbol: 'SPY',
		name: 'SPDR S&P 500 ETF Trust'
	},
	{
		symbol: 'TSLA',
		name: 'Tesla Inc'
	},
	{
		symbol: 'BTC',
		name: 'Bitcoin'
	},
	{
		symbol: 'AAPL',
		name: 'Apple Computer'
	},
	{
		symbol: 'TQQQ',
		name: 'ProShares UltraPro QQQ'
	},
	{
		symbol: 'BAC',
		name: 'Bank of America Corp'
	},
	{
		symbol: 'AROW',
		name: 'ARROW Computer'
	},
	{
		symbol: 'AA',
		name: 'Ares Acquisition'
	}
];
