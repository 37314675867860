export default {
	AAPL: {
		results: {
			ticker: 'AAPL',
			name: 'Apple Inc.',
			market: 'stocks',
			locale: 'us',
			primary_exchange: 'XNAS',
			type: 'CS',
			active: true,
			currency_name: 'usd',
			cik: '0000320193',
			composite_figi: 'BBG000B9XRY4',
			share_class_figi: 'BBG001S5N8V8',
			market_cap: 2418397837610.5,
			phone_number: '(408) 996-1010',
			address: {
				address1: 'ONE APPLE PARK WAY',
				city: 'CUPERTINO',
				state: 'CA',
				postal_code: '95014'
			},
			description:
				"Apple designs a wide variety of consumer electronic devices, including smartphones (iPhone), tablets (iPad), PCs (Mac), smartwatches (Apple Watch), AirPods, and TV boxes (Apple TV), among others. The iPhone makes up the majority of Apple's total revenue. In addition, Apple offers its customers a variety of services such as Apple Music, iCloud, Apple Care, Apple TV+, Apple Arcade, Apple Card, and Apple Pay, among others. Apple's products run internally developed software and semiconductors, and the firm is well known for its integration of hardware, software and services. Apple's products are distributed online as well as through company-owned stores and third-party retailers. The company generates roughly 40% of its revenue from the Americas, with the remainder earned internationally.",
			sic_code: '3571',
			sic_description: 'ELECTRONIC COMPUTERS',
			ticker_root: 'AAPL',
			homepage_url: 'https://www.apple.com',
			total_employees: 154000,
			list_date: '1980-12-12',
			branding: {
				logo_url:
					'https://api.polygon.io/v1/reference/company-branding/d3d3LmFwcGxlLmNvbQ/images/2022-05-01_logo.svg',
				icon_url:
					'https://api.polygon.io/v1/reference/company-branding/d3d3LmFwcGxlLmNvbQ/images/2022-05-01_icon.png'
			},
			share_class_shares_outstanding: 16185180000,
			weighted_shares_outstanding: 16185181000
		},
		status: 'OK',
		request_id: 'ddb1c2b2579ae595c5f042795485738a'
	},
	SPY: {
		results: {
			ticker: 'SPY',
			name: 'SPDR S&P 500 ETF Trust',
			market: 'stocks',
			locale: 'us',
			primary_exchange: 'XNAS',
			type: 'CS',
			active: true,
			currency_name: 'usd',
			cik: '0000320193',
			composite_figi: 'BBG000B9XRY4',
			share_class_figi: 'BBG001S5N8V8',
			market_cap: 2418397837610.5,
			phone_number: '(408) 996-1010',
			address: {
				address1: 'ONE SPY PARK WAY',
				city: 'CUPERTINO',
				state: 'CA',
				postal_code: '95014'
			},
			description:
				"The SPDR S&P 500 trust is an exchange-traded fund which trades on the NYSE Arca under the symbol. SPDR is an acronym for the Standard & Poor's Depositary Receipts, the former name of the ETF.",
			sic_code: '3571',
			sic_description: 'ELECTRONIC ITEM',
			ticker_root: 'AAPL',
			homepage_url: 'https://www.spy.com',
			total_employees: 154000,
			list_date: '1980-12-12',
			share_class_shares_outstanding: 16185180000,
			weighted_shares_outstanding: 16185181000
		},
		status: 'OK',
		request_id: 'ddb1c2b2579ae595c5f042795485738b'
	},
	'X:ETHUSD': {
		request_id: 'd419f9973cce43f411bff3b41cd53508',
		results: {
			active: true,
			base_currency_name: 'Ethereum',
			base_currency_symbol: 'ETH',
			currency_name: 'United States Dollar',
			currency_symbol: 'USD',
			locale: 'global',
			market: 'crypto',
			name: 'Ethereum - United States Dollar',
			ticker: 'X:ETHUSD'
		},
		status: 'OK'
	}
};
