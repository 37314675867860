import { useState } from 'react';
import OrderNameWithDatetime from '../list/cells/OrderNameWithDateime';
import Amount from '../list/cells/Amount';
import OrderHistoryStatus from '../list/cells/OrderHistoryStatus';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import SymbolWithName from '../list/cells/SymbolWithName';
import Styles from './OrderHistoryPageStyles';
import OrderHistoryTabs from './OrderHistoryTabs';
import RefreshButton from '../buttons/RefreshButton';
import { formatNumber, timeStamp } from '../../utils';

const orderHistoryColumns = [
	{
		Header: 'Name',
		accessor: 'symbol',
		Cell: (props) => {
			return <SymbolWithName {...props.row.original} />;
		}
	},
	{
		Header: 'Order',
		accessor: (d) => (
			<OrderNameWithDatetime type={d.type} side={d.side} created_at={d.created_at} />
		)
	},
	{
		Header: 'Quantity',
		accessor: 'qty',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => formatNumber(props.value)
	},
	{
		Header: 'Price',
		accessor: 'stop_price',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{
		Header: 'Value',
		accessor: 'filled_avg_price',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => {
			return <Amount {...props} />;
		}
	},
	{ Header: 'Account', accessor: 'account', HeaderClass: 'text-center', alignment: 'center' },
	{
		Header: 'Status',
		accessor: 'status',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => {
			return <OrderHistoryStatus {...props} />;
		}
	},
	{
		Header: 'Date',
		accessor: 'created_at',
		HeaderClass: 'text-center',
		alignment: 'center',
		Cell: (props) => {
			return new Date(props.value).toLocaleString();
		}
	}
];

const View = ({ handleChange, filters, refreshPage, setRefreshPage }) => (
	<Styles>
		<div className="filter-container">
			<div className="order-filter-item back-info">
				<div>
					<a href="#" className="hide">
						&lt; Back
					</a>
				</div>
				<div>
					<label>Account Type:</label>
					<select name="accountType" onChange={handleChange}>
						<option value="live" defaultValue>
							Live
						</option>
						<option value="paper">Paper</option>
					</select>
				</div>
			</div>
			<div className="order-filter-item">
				<div>
					<RefreshButton setRefreshPage={setRefreshPage} />
				</div>
			</div>
		</div>

		<WidgetBox header="Order History" icon="/images/watch-icon.png">
			<OrderHistoryTabs
				isPagination={true}
				isPage={true}
				columns={orderHistoryColumns}
				refreshPage={refreshPage}
				{...filters}
			/>
		</WidgetBox>
	</Styles>
);

const OrderHistoryPage = () => {
	const filterObj = {
		accountType: 'live',
		fromDate: new Date(),
		toDate: new Date()
	};
	const [filters, setFilters] = useState(filterObj);
	const [refreshPage, setRefreshPage] = useState(timeStamp);

	const handleChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	return (
		<View
			handleChange={handleChange}
			filters={filters}
			refreshPage={refreshPage}
			setRefreshPage={setRefreshPage}
		/>
	);
};

export default OrderHistoryPage;
