const settingsItems = {
// 	profile: { text: 'Profile', trigger: false, external: false, path: '/profile' },
	changePassword: {
		text: 'Change Password',
		trigger: false,
		external: false,
		path: '/change-password'
	},
// 	membership: { text: 'Membership', trigger: false, external: false, path: '/membership' },
// 	preferences: { text: 'Preferences', trigger: false, external: false, path: '/preferences' },
	termsOfService: {
		text: 'Terms of Service',
		trigger: false,
		external: true,
		url: 'https://www.machinetrader.io/terms-of-service'
	},
	cookiePolicy: {
		text: 'Cookie Policy',
		trigger: false,
		external: true,
		url: 'https://www.machinetrader.io/cookiepolicy'
	},
	privacyPolicy: {
		text: 'Privacy Policy',
		trigger: false,
		external: true,
		url: 'https://www.machinetrader.io/privacy-policy'
	},
	signOut: { text: 'Sign Out', trigger: true, external: false }
};

export default settingsItems;
