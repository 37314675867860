import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export const useQueryState = (query) => {
	const [searchParams] = useSearchParams();
	const location = useLocation();
	const navigate = useNavigate();

	const setQuery = (value) => navigate(`${location.pathname}?${query}=${value}`);
	return [searchParams.get(query), setQuery];
};
