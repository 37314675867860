import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useDataApi from '../../hooks/useDataApi';
import { newsTimeFormat } from '../../utils/datetime';
import { sentimentIconByText, sentimentIconByValue, parseSentiments } from '../../utils';
import Delta from '../list/cells/Delta';
import './HeadLines.css';
import { HeadlinesIcon } from '../../styles/icons/icons';

const HeadLinesView = ({ headlines }) => (
	<>
		{headlines?.slice(0, 4).map((headline, i) => {
			return (
				<div key={`headline-item-${i}`} className="headline-item">
					<label>{newsTimeFormat(headline.time_pub)}</label>
					<div>
						{' '}
						<span>
							<img
								src={`/images/${sentimentIconByText(headline.sentiment)}`}
								alt="icon"
							/>
						</span>{' '}
						<a href={headline.url} target="_blank" rel="noreferrer">
							{headline.txt}
						</a>
					</div>
				</div>
			);
		})}
	</>
);
const View = ({ data: headlines, sentiments, isLoading, isError }) => {
	return (
		<div className="card">
			{isError && <div>Something went wrong</div>}

			<div className="card-header">
				<h3 className="card-title">
					<HeadlinesIcon /> Headlines
				</h3>
				{sentiments && sentiments.length ? (
					<div className="card-tools">
						<span className="card-tools-icon">
							<img
								src={`/images/${sentimentIconByValue(parseSentiments(sentiments))}`}
								alt="hourly-sentiment-icon"
							/>
						</span>
						<span className="card-tools-text">
							<Delta value={parseSentiments(sentiments)} />
						</span>
					</div>
				) : (
					''
				)}
			</div>
			{isLoading ? <div>Loading...</div> : <HeadLinesView headlines={headlines} />}
			<div className="text-right">
				<NavLink to="/insights" className="link-text">
					{' '}
					&gt; View More
				</NavLink>
			</div>
		</div>
	);
};

const HeadLines = (props) => {
	const headlineUrl = props.symbol
		? `/news/headline/ticker?symbol=${props.symbol}`
		: `/news/headline`;
	const [dataProps, setHeadlineUrl] = useDataApi(headlineUrl, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ ticker: props.symbol, start: 0 })
	});
	const [sentimentsProps] = useDataApi('/news/hourly_sentiment', [], { method: 'POST' });

	useEffect(() => {
		setHeadlineUrl(headlineUrl);
	}, [headlineUrl]);

	return <View sentiments={sentimentsProps.data} {...dataProps} />;
};

export default HeadLines;
