import { useState } from 'react';

const getThemePref = () => {
	const themePref = localStorage.getItem('theme');
	return themePref || 'light';
};
const useDarkTheme = () => {
	const [theme, setTheme] = useState(getThemePref());
	const isDarkTheme = theme === 'dark';
	const toggleTheme = () => {
		const storeTheme = isDarkTheme ? 'light' : 'dark';
		setTheme(storeTheme);
		localStorage.setItem('theme', storeTheme);
	};

	return [isDarkTheme, toggleTheme];
};

export default useDarkTheme;
