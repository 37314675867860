import { createGlobalStyle } from 'styled-components';
export const GlobalStyles = createGlobalStyle`
  body {
    --bg-white: ${({ theme }) => theme.body};
    --font-black: ${({ theme }) => theme.text};   
  }

  .top-header {
    --bg-white: ${({ theme }) => theme.body};   
  }

  .top-navbar ul li a{    
    --font-black: ${({ theme }) => theme.textwhite}; 
  }

  .tab ul.nav li{
    --bg-grey-2: ${({ theme }) => theme.bggrey};
    --border-black-1:${({ theme }) => theme.borderWhite};      
  }

  .tab ul.nav li.active{
    --bg-white: ${({ theme }) => theme.body};
    border-bottom: ${({ theme }) => theme.borderBottom0};
  }

  .list tr:not(.separator):nth-child(2n+1){
    background: ${({ theme }) => theme.body};
  }
 
  .card-title{
    --font-black: ${({ theme }) => theme.textwhite};  
  }

  .news-info span{
    --font-black: ${({ theme }) => theme.textwhite};  
  }

  .news-large-info{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .card-header-v2{
    --bg-light-grey-2: ${({ theme }) => theme.bggrey};
  }

  .insights-container .tab ul.nav{    
    --border-black-1: ${({ theme }) => theme.borderBottom0};
  }

  .auto-suggestions{
    --bg-white: ${({ theme }) => theme.bgBlack2};
  }

  .auto-suggestions li.active-item, .auto-suggestions li:hover{
    --bg-grey-2: ${({ theme }) => theme.bggrey}; 
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .auto-suggestions li{
    --font-Darkgrey: ${({ theme }) => theme.textwhite};
  }

  .doted-border .modal-header-text{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .footer{
    --bg-light-grey: ${({ theme }) => theme.bggrey};
  }

  .footer-menu ul li a{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .copyright{
    --bg-light-grey3: ${({ theme }) => theme.body};
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .footer-address ul li{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .footer-contact ul li a{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .top-navbar ul li a{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .mobile-view-menu{
    --bg-orange: ${({ theme }) => theme.bggrey2};
  }

  .right-side-bar{
    --bg-light-grey: ${({ theme }) => theme.bgwhite2};
  }

  .headline-item div{
    --font-black: ${({ theme }) => theme.textwhite};
  }
  .list td{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .insights-info{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .insights-graph-info{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .insights-container .border-dotted .link-text{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .company-logo a span {
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .algo-editor-box-item label{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .algo-editor-box-item select{
    --bg-black: ${({ theme }) => theme.bgwhite2};
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .bg-grey{
    --bg-light-grey: ${({ theme }) => theme.bgwhite2};
  }

  .insights-list-col ul li{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .list tr:not(.separator):nth-child(2n+1){
    --bg-white: ${({ theme }) => theme.body};
  }

  .account-details .detail-item div{
    --font-Darkgrey: ${({ theme }) => theme.textwhite};
  }

  .trade-box-item select{
    --bg-white: ${({ theme }) => theme.bggrey};
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .sm-tab .tab .outlet{
    --border-black-1:${({ theme }) => theme.borderWhitebottom};
  }

  .modal-root{
    --bg-white: ${({ theme }) => theme.bgBlack2};
    --border-grey-1:${({ theme }) => theme.borderWhitebottom};
  }

  .modal-root header{
    --border-black-1: ${({ theme }) => theme.borderWhitebottom};
  }

  .modal-header-text{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .active .right-info{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .pophover.right{
    --bg-white: ${({ theme }) => theme.bgBlack2};
  }

  .pophover-chart-header{
    --border-black-1: ${({ theme }) => theme.borderWhitebottom};
  }

  .list .dropdown-menu-item{
    --bg-white: ${({ theme }) => theme.bgBlack2};
  }

  .list .dropdown-menu-item a{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .account-metrics .metrics-item div{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .tab .outlet{
    --border-black-1:${({ theme }) => theme.borderWhitebottom};
  }

  .trade-action .search-box-item input[type="text"]{
    --border-black-2:${({ theme }) => theme.borderWhitebottom};
    --font-black: ${({ theme }) => theme.textwhite};
    --bg-white: ${({ theme }) => theme.bggrey};
  }

  .trade-box-item input[type="text"], .trade-box-item input[type="number"]{
    --font-black: ${({ theme }) => theme.textwhite};
    --bg-white: ${({ theme }) => theme.bggrey};
  }

  .trade.tab ul.nav li.active{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  div .fxBA-dx button{
    --font-black: ${({ theme }) => theme.textwhite};
  }

  .multi-select-wrapper .searchWrapper {
    --border-black-2: ${({ theme }) => theme.borderWhite2};
    --font-black: ${({ theme }) => theme.textwhite}
  }

  .symbol-type-selector select {
    --border-black-2: ${({ theme }) => theme.borderWhite2};
  }

`;

export const lightTheme = {};
export const darkTheme = {
	body: '#000',
	text: '#f1f1f1',
	textwhite: '#fff',
	borderWhite: '1px #fff solid',
	bggrey: 'rgba(242, 242, 242, 0.2);',
	borderBottom0: '0px',
	bggrey2: '#343536;',
	borderWhitebottom: '1px #fff solid',
	bgwhite2: '#ffffff2b',
	bgBlack2: '#333',
	borderWhite2: '2px #fff solid'
};
