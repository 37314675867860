import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthContext from '../../auth/AuthContext';
import signOut from '../../auth/signOut';
import SideBar from '../../components/layout/SideBar/SideBar';
import MembershipPage from '../membership/MembershipPage';
import PreferencesPage from '../preferences/PreferencesPage';
import ProfilePage from '../profile/ProfilePage';
import ChangePasswordPage from '../changePassword/ChangePasswordPage';
import settingsItems from './settingsItems';
import Styles from './SettingsPageStyles';

const View = ({ eventHandler }) => (
	<Styles>
		<SideBar items={settingsItems} eventHandler={eventHandler} />
		<Routes>
			<Route path="" element={<Navigate to="change-password" />} />
			<Route path="/change-password" element={<ChangePasswordPage />} />
			<Route path="/profile" element={<ProfilePage />} />
			<Route path="/membership" element={<MembershipPage />} />
			<Route path="/preferences" element={<PreferencesPage />} />
		</Routes>
	</Styles>
);

const SettingsPage = () => {
	const setIsAuthenticated = useContext(AuthContext)[1];

	const eventHandler = (key) => {
		if (key === 'signOut') {
			return signOut(setIsAuthenticated);
		}
	};

	return <View eventHandler={eventHandler} />;
};

export default SettingsPage;
