import { useState } from 'react';
import Styles from './MarketPageStyles';
import MarketIndicators from '../../components/marketIndicators/MarketIndicators';
import TickerSelector from '../../components/tickerSelector/TickerSelector';
import RefreshButton from '../../components/buttons/RefreshButton';
import { timeStamp } from '../../utils';

const View = ({ refreshPage, setRefreshPage }) => {
	return (
		<Styles>
			<div className="card-header">
				<h3 className="card-title">
					<img src="/images/icon-markets@2x.png" alt="markets" /> Markets
				</h3>
				<div className="card-tools">
					<RefreshButton setRefreshPage={setRefreshPage} />
					<div className="multi-select-item">
						<TickerSelector />
						<img
							className="multi-select-icon"
							src="/images/search-icon.png"
							alt="serch"
						/>
					</div>
				</div>
			</div>
			<MarketIndicators type="page" refreshPage={refreshPage} />
		</Styles>
	);
};

const MarketsPage = () => {
	const [refreshPage, setRefreshPage] = useState(timeStamp);
	return <View {...{ refreshPage, setRefreshPage }} />;
};

export default MarketsPage;
