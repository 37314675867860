const SymbolType = (props) => {
	return (
		<select onChange={(e) => props.handleChange(e.target.value)} value={props.value}>
			<option value="stocks">Stock</option>
			<option value="crypto">Crypto</option>
		</select>
	);
};

export default SymbolType;
