export default [
	{
		symbol: 'HSTO',
		name: 'Histogen Inc. Common Stock',
		open: 0.1333,
		close: 0.1371,
		high: 0.145,
		low: 0.1311,
		volume: 0,
		vwap: 0.1377,
		date: '2022-06-03',
		price: 2.525,
		price_change: 2.3879,
		price_change_pct: 0.945703,
		type: 1,
		shortable: null
	},
	{
		symbol: 'EFOI',
		name: 'Energy Focus, Inc.',
		open: 1.09,
		close: 1.11,
		high: 1.23,
		low: 1.01,
		volume: 1,
		vwap: 1.1142,
		date: '2022-06-03',
		price: 1.7194,
		price_change: 0.6094,
		price_change_pct: 0.354426,
		type: 1,
		shortable: null
	},
	{
		symbol: 'YMTX',
		name: 'Yumanity Therapeutics  Inc. Common Stock',
		open: 1.4,
		close: 1.06,
		high: 1.56,
		low: 1.05,
		volume: 1,
		vwap: 1.4177,
		date: '2022-06-03',
		price: 1.3822,
		price_change: 0.3222,
		price_change_pct: 0.233107,
		type: 1,
		shortable: null
	},
	{
		symbol: 'IMTE',
		name: 'Integrated Media Technology Limited Ordinary Shares',
		open: 7.82,
		close: 9.92,
		high: 10.01,
		low: 7.79,
		volume: 8,
		vwap: 9.2988,
		date: '2022-06-03',
		price: 11.84,
		price_change: 1.92,
		price_change_pct: 0.162162,
		type: 1,
		shortable: null
	},
	{
		symbol: 'CRIS',
		name: 'Curis Inc',
		open: 0.84,
		close: 0.8099,
		high: 0.84,
		low: 0.7936,
		volume: 1,
		vwap: 0.8182,
		date: '2022-06-03',
		price: 0.9555,
		price_change: 0.1456,
		price_change_pct: 0.152381,
		type: 1,
		shortable: null
	},
	{
		symbol: 'AMRN',
		name: 'Amarin Corp Plc',
		open: 1.43,
		close: 1.46,
		high: 1.505,
		low: 1.42,
		volume: 1,
		vwap: 1.4656,
		date: '2022-06-03',
		price: 1.7,
		price_change: 0.2423,
		price_change_pct: 0.142337,
		type: 1,
		shortable: null
	},
	{
		symbol: 'MREO',
		name: 'Mereo BioPharma Group plc American Depositary Shares',
		open: 0.5395,
		close: 0.5001,
		high: 0.5395,
		low: 0.441,
		volume: 0,
		vwap: 0.4791,
		date: '2022-06-03',
		price: 0.579,
		price_change: 0.0789,
		price_change_pct: 0.136269,
		type: 1,
		shortable: null
	},
	{
		symbol: 'IOVA',
		name: 'Iovance Biotherapeutics, Inc. Common Stock',
		open: 6.3,
		close: 6.6,
		high: 6.755,
		low: 6.18,
		volume: 6,
		vwap: 6.5121,
		date: '2022-06-03',
		price: 7.595,
		price_change: 0.995,
		price_change_pct: 0.131007,
		type: 1,
		shortable: null
	},
	{
		symbol: 'CLVS',
		name: 'Clovis Oncology, Inc.',
		open: 0.66,
		close: 0.6581,
		high: 0.6705,
		low: 0.6367,
		volume: 1,
		vwap: 0.6508,
		date: '2022-06-03',
		price: 0.747099,
		price_change: 0.088999,
		price_change_pct: 0.119126,
		type: 1,
		shortable: null
	},
	{
		symbol: 'AKBA',
		name: 'Akebia Therapeutics, Inc.',
		open: 0.3481,
		close: 0.3595,
		high: 0.362,
		low: 0.3315,
		volume: 0,
		vwap: 0.3468,
		date: '2022-06-03',
		price: 0.41,
		price_change: 0.0482,
		price_change_pct: 0.118224,
		type: 1,
		shortable: null
	},
	{
		symbol: 'STNE',
		name: 'StoneCo Ltd. Class A Common Shares',
		open: 9.66,
		close: 10.42,
		high: 10.58,
		low: 9.5,
		volume: 10,
		vwap: 10.8506,
		date: '2022-06-03',
		price: 11.725,
		price_change: 1.305,
		price_change_pct: 0.111301,
		type: 1,
		shortable: null
	},
	{
		symbol: 'EVFM',
		name: 'Evofem Biosciences, Inc. Common Stock',
		open: 0.35,
		close: 0.3438,
		high: 0.36,
		low: 0.3234,
		volume: 0,
		vwap: 0.3382,
		date: '2022-06-03',
		price: 0.3856,
		price_change: 0.0418,
		price_change_pct: 0.108402,
		type: 1,
		shortable: null
	},
	{
		symbol: 'MEIP',
		name: 'MEI Pharma, Inc.',
		open: 0.4532,
		close: 0.4637,
		high: 0.48,
		low: 0.441,
		volume: 0,
		vwap: 0.4645,
		date: '2022-06-03',
		price: 0.5198,
		price_change: 0.0561,
		price_change_pct: 0.107926,
		type: 1,
		shortable: null
	},
	{
		symbol: 'AI',
		name: 'C3.ai, Inc.',
		open: 15.2,
		close: 17.62,
		high: 17.8399,
		low: 15.0701,
		volume: 15,
		vwap: 16.3422,
		date: '2022-06-03',
		price: 19.88,
		price_change: 2.1,
		price_change_pct: 0.106491,
		type: 1,
		shortable: null
	},
	{
		symbol: 'VERU',
		name: 'Veru Inc.',
		open: 12.19,
		close: 13.21,
		high: 13.32,
		low: 12.09,
		volume: 12,
		vwap: 12.9411,
		date: '2022-06-03',
		price: 14.75,
		price_change: 1.54,
		price_change_pct: 0.104407,
		type: 1,
		shortable: null
	},
	{
		symbol: 'SPPI',
		name: 'Spectrum Pharmaceuticals Inc',
		open: 0.72,
		close: 0.7595,
		high: 0.76,
		low: 0.6965,
		volume: 1,
		vwap: 0.7359,
		date: '2022-06-03',
		price: 0.8409,
		price_change: 0.0814,
		price_change_pct: 0.096801,
		type: 1,
		shortable: null
	},
	{
		symbol: 'MNKD',
		name: 'Mannkind Corporation',
		open: 4.17,
		close: 4.14,
		high: 4.27,
		low: 4.045,
		volume: 4,
		vwap: 4.1683,
		date: '2022-06-03',
		price: 4.56,
		price_change: 0.42,
		price_change_pct: 0.0921053,
		type: 1,
		shortable: null
	},
	{
		symbol: 'SNMP',
		name: 'Evolve Transition Infrastructure LP',
		open: 0.5,
		close: 0.49,
		high: 0.522,
		low: 0.481,
		volume: 0,
		vwap: 0.4944,
		date: '2022-06-03',
		price: 0.5383,
		price_change: 0.0483,
		price_change_pct: 0.0897269,
		type: 1,
		shortable: null
	},
	{
		symbol: 'EPZM',
		name: 'Epizyme, Inc',
		open: 0.4226,
		close: 0.466,
		high: 0.484,
		low: 0.4226,
		volume: 0,
		vwap: 0.4578,
		date: '2022-06-03',
		price: 0.5104,
		price_change: 0.0444,
		price_change_pct: 0.0869906,
		type: 1,
		shortable: null
	},
	{
		symbol: 'VTNR',
		name: 'Vertex Energy  Inc',
		open: 13.72,
		close: 15.37,
		high: 15.85,
		low: 13.718,
		volume: 14,
		vwap: 15.0915,
		date: '2022-06-03',
		price: 16.83,
		price_change: 1.46,
		price_change_pct: 0.0867499,
		type: 1,
		shortable: null
	}
];
