import { formatAmount } from '../../../utils';

const Amount = ({ value }) => {
	return (
		<div className={Number(value) >= 0 ? 'green-value' : 'red-value'}>
			<span>{formatAmount(value)}</span>
		</div>
	);
};

export default Amount;
