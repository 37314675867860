import { formatAmount } from '../../../utils';

const AlgoAmountWithDelta = ({ gain_loss, gain_loss_pct }) => {
	return (
		<div
			className={`multi-line-cell amount-n-delta ${
				Number(gain_loss) >= 0 ? 'green-value' : 'red-value'
			}`}>
			<span>{formatAmount(gain_loss)}</span>
			<span>{(Number(gain_loss) > 0 ? '+' : '') + Number(gain_loss_pct).toFixed(2)}%</span>
		</div>
	);
};

export default AlgoAmountWithDelta;
