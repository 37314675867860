export default {
	id: 1,
	name: 'Test Algo',
	symbol: 'AAPL',
	lambda: '0.9',
	signals: '3',
	trend: '1',
	sr: '0,952',
	noOfTrades: 359,
	strategyName: 'SVXy VWAP'
};
