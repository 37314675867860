import { ModalDialog, ModalBody } from '../ModalDialog/ModalDialog';
import MetricsDetailChart from '../../chart/MetricsDetailChart';
import TickerSelector from '../../tickerSelector/TickerSelector';
import './MetricsDetailDialog.css';

const View = ({ item, isOpen, handleStateChange }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalSize="large"
			modalTitle={item.label}
			headerIcon="/images/trade-ref-icon.png"
			headerClass="doted-border"
			handleStateChange={handleStateChange}>
			<ModalBody>
				<div className="metrics-detail-dialog">
					<div className="history-date">
						<img src="/images/history-date-icon.png"></img>
						<label> Updated on January 20, 2022</label>
					</div>
					<div className="auto-omplete-item">
						<div className="auto-omplete-info">
							<span>Amazon.com, Inc (NASDAQ:AMZN) </span>
							<a href="#" className="news-link-info">
								{' '}
								&gt; Add to Watch List
							</a>
							<a href="#" className="news-link-info">
								{' '}
								&gt; Place Order
							</a>
						</div>
						<div className="auto-omplete">
							<TickerSelector />
							<img src="/images/search-icon.png" alt="serch"></img>
						</div>
					</div>
				</div>
				<MetricsDetailChart
					isFullScreen={false}
					chartId={`metrics-detail-chart-${item.id}`}
					id={item.id}
				/>
			</ModalBody>
		</ModalDialog>
	);
};

const MetricsDetailDialog = (props) => {
	return <View {...props} />;
};

export default MetricsDetailDialog;
