import { createRef, useState } from 'react';
import { useForm, useWatch, FormProvider, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import AlgoEditorChart from '../chart/AlgoEditorChart';
import { Pophover, PophoverElement, PophoverContent } from '../pophover/Pophover';

const View = ({
	methods,
	onSubmit,
	isEditable,
	toggleEdit,
	refForm,
	editorData,
	symbolsData,
	navigate
}) => {
	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)} ref={refForm}>
				<input type="hidden" name="id"></input>
				<div className="card-header">
					<h3 className="card-title edit-input-box">
						Algo Strategy Editor :
						<NameInput {...{ toggleEdit, isEditable }} />
					</h3>
				</div>
				<div className="algo-editor-box">
					<div className="algo-editor-box-item">
						<label>Symbol:</label>
						<select
							className={methods.formState.errors.symbol && 'error'}
							{...methods.register('symbol', { required: true })}>
							<option value=""> Select Symbol</option>
							{symbolsData?.length &&
								symbolsData.map((item) => {
									return (
										<option key={item.symbol} value={item.symbol}>
											{item.name}({item.symbol})
										</option>
									);
								})}
						</select>
						{methods.formState.errors.symbol?.type === 'required' && (
							<span>Symbol is required.</span>
						)}
					</div>
					<div className="algo-editor-box-item">
						<RangeInput
							{...{
								min: '0.01',
								max: '0.99',
								step: '0.01',
								name: 'lambda',
								label: 'Lambda',
								data: editorData?.lambda
							}}
						/>
						<div className="slider-info">
							{' '}
							<span> 0.01</span> <span> 0.99</span>
						</div>
					</div>
					<div className="algo-editor-box-item">
						<RangeInput
							{...{
								min: '1',
								max: '5',
								step: '1',
								name: 'signals',
								label: 'Confirmation Signals',
								data: editorData?.signals
							}}
						/>
						<div className="slider-info">
							{' '}
							<span> 1</span> <span> 5</span>
						</div>
					</div>

					<div className="algo-editor-box-item">
						<label>Trend (1) Revert (-1):</label>
						<select
							name="trend"
							className={methods.formState.errors.trend && 'error'}
							{...methods.register('trend', { required: true })}>
							<option value="1">1</option>
							<option value="-1">-1</option>
						</select>
						{methods.formState.errors.trend?.type === 'required' && (
							<span>Trend is required.</span>
						)}
					</div>
				</div>

				<div className="algo-edit-graph">
					<div className="algo-edit-info">
						{' '}
						{editorData?.strageyName} strategy / Index SR={editorData?.sr} / Number of
						Trades = {editorData?.noOfTrades}
					</div>
					<AlgoEditorChart
						isFullScreen={true}
						chartId="algo-editor-chart-id"
						algoEditorId={editorData?.id}
					/>
				</div>
				<div className="algo-edit-btn">
					<button onClick={() => navigate(-1)} className="button-sm btn-orange-border">
						BACK
					</button>
					<button type="submit" className="button-sm btn-orange">
						LAUNCH ON PAPER ACCOUNT
					</button>
				</div>
			</form>
		</FormProvider>
	);
};

const RangeInput = (props) => {
	const { register, control, getValues } = useFormContext();
	return (
		<>
			<label>{props.label}: </label>
			<Pophover type="tooltip">
				<PophoverElement>
					<input
						type="range"
						className="slider"
						{...props}
						{...register(`${props.name}`)}
					/>
				</PophoverElement>
				<PophoverContent direction="top">
					<div>
						<FieldWatch {...{ control, getValues, field: props.name }} />
					</div>
				</PophoverContent>
			</Pophover>
		</>
	);
};

const NameInput = ({ toggleEdit, isEditable }) => {
	const {
		register,
		getValues,
		control,
		formState: { errors }
	} = useFormContext();
	return (
		<>
			{isEditable ? (
				<span>
					<input
						type="text"
						className={errors.symbol && 'error'}
						{...register('name', { required: true })}
						onKeyPress={(e) => e.key === 'Enter' && toggleEdit()}
					/>
				</span>
			) : (
				<span>
					<FieldWatch {...{ control, getValues, field: 'name' }} />
				</span>
			)}
			<img id="edit-icon" src="/images/Pencil.png" onClick={() => toggleEdit()} />
			{errors.name?.type === 'required' && <span>Name is required.</span>}
		</>
	);
};

const FieldWatch = ({ control, getValues, field }) => {
	useWatch({ control, name: field });
	return <>{getValues(field)}</>;
};

const onSubmit = (data) => console.log(data);

const AlgoEditorForm = ({ editorData, symbolsData }) => {
	const navigate = useNavigate();
	const refForm = createRef();
	const [isEditable, setIsEditable] = useState(false);

	const methods = useForm({
		defaultValues: {
			symbol: editorData?.symbol,
			trend: editorData?.trend,
			signals: editorData?.signals,
			lambda: editorData?.lambda,
			id: editorData?.id,
			name: editorData?.name
		}
	});

	const toggleEdit = () => {
		if (isEditable) {
			refForm.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
		}
		setIsEditable(!isEditable);
	};

	return View({
		...{
			methods,
			onSubmit,
			isEditable,
			toggleEdit,
			refForm,
			editorData,
			symbolsData,
			navigate
		}
	});
};

export default AlgoEditorForm;
