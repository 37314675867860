import styled from 'styled-components';

const Styles = styled.div`
	h2 {
		font-size: var(--font-size-l);
	}
	label {
		color: var(--font-grey);
		font-size: var(--font-size-m);
		font-style: normal;
		font-family: var(--font-family-montserrat);
		font-weight: var(--font-weight-500);
	}
	.inputs {
		color: var(--ebony-clay);
		border-bottom: 1px dotted var(--font-grey);
		line-height: 5rem;
		display: flex;
		justify-content: space-between;
		input {
			border: none;
			margin-left: 0.5rem;
			flex: 1 1 auto;
			font-family: var(--font-family-montserrat);
			font-size: var(--font-size-l);
			font-style: normal;
			font-weight: var(--font-weight-500);
			:focus {
				outline: none;
			}
		}
	}
`;

export default Styles;
