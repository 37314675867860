export const chartData = (payload) => {
	const symbol = payload[0]?.[0]?.toLocaleUpperCase();
	const data = [];
	for (const { 1: time, 5: close } of payload) {
		data.push([time, close]);
	}
	return { symbol, data };
};

export const metricsChartData = (payload) => {
	const symbol = '';
	const data = [];
	if (payload && payload.length) {
		for (const { date: time, 0: close } of payload.reverse()) {
			data.push([new Date(time).getTime(), close]);
		}
	}
	return { symbol, data };
};

export const multipleSeriesChartData = (payload) => {
	const data = new Map();
	for (let { 0: symbol, 1: time, 5: close } of payload) {
		symbol = symbol.toLocaleUpperCase();
		let series = data.get(symbol);
		if (!series) {
			series = { name: symbol, data: [] };
			data.set(symbol, series);
		}

		series.data.push([time, close]);
	}
	return { data };
};

export const portfolioChartData = (payload) => {
	const symbol = '';
	const data = [];
	for (const { unixtime: time, balance: close } of payload) {
		data.push([time * 1000, close]);
	}
	return { symbol, data };
};

export const oneSecChartData = (payload) => {
	const symbol = '';
	const data = [];
	if (payload && payload.results && payload.results.length) {
		for (const { participant_timestamp: time, price: close } of payload?.results || []) {
			data.push([new Date(time / 1000000), close]);
		}
	}
	return { symbol, data };
};

export const techIndicatorsChartData = (payload) => {
	const symbol = payload?.ticker || '';
	const data = { symbol, ohlc: [], volume: [] };
	if (payload && payload.results && payload.results.length) {
		for (const {
			time: time,
			open: open,
			highest: high,
			lowest: low,
			close: close,
			volume: volume
		} of payload?.results || []) {
			data.ohlc.push([time, open, high, low, close]);
			data.volume.push([time, volume]);
		}
	}

	return { symbol, data };
};

export const customChartData = (payload) => {
	const symbol = payload?.ticker || '';
	const data = [];
	if (payload && payload.results && payload.results.length) {
		for (const {
			time: time,
			open: open,
			highest: high,
			lowest: low,
			close: close
		} of payload?.results || []) {
			data.push([time, open, high, low, close]);
		}
	}

	return { symbol, data };
};

export const newsSenimentsChartData = (payload) => {
	const symbol = '';
	const data = [];
	for (const { date: time, sentiment: close } of payload) {
		data.push([new Date(time).getTime(), close]);
	}
	return { symbol, data };
};

export const linearChartData = (payload) => {
	const symbol = payload?.ticker || '';
	const data = [];
	if (payload && payload.results && payload.results.length) {
		for (const { time: time, close: close } of payload?.results || []) {
			data.push([time, close]);
		}
	}
	return { symbol, data };
};

export const financialChartData = (payload) => {
	const symbol = '';
	const data = [];
	if (payload && payload.length) {
		for (const { filedate: time, value: close } of payload || []) {
			data.push([new Date(time).getTime(), close]);
		}
	}
	return { symbol, data };
};
