import styled from 'styled-components';

const Styles = styled.div.attrs({
	className: 'sign-left-side'
})`
	display: flex;
	flex-direction: column;
	padding: 42px 33px;
	flex: 1;
	background: url(../../../images/sign-left-bg.png);
	background-size: 100% 100%;

	.company-logo {
		margin: 20px 0px 40px 0px;
		a span {
			color: var(--font-white);
		}
	}

	.sign-page-img {
		align-self: center;
		background-size: 100% 100%;
		height: 380px;
		margin-top: 71px;
		width: 576px;
		background: url(../../../images/sign-lft-img.png);
	}
	@media screen and (min-width: 731px) and (max-width: 980px) {
		.signin-main .sign-page-img {
			width: 100%;
			height: 280px;
		}
	}

	@media screen and (min-width: 10px) and (max-width: 730px) {
		.sign-page-img {
			width: 100%;
			height: 250px;
			margin-top: 0px;
		}
	}
`;

export default Styles;
