import useFocusElements from './useFocusElements';
import './ModalDialog.css';

const ModalDialog = (props) => {
	const { modalContentEL, handleKeyDown } = useFocusElements(props.isOpen);
	return View({ modalContentEL, handleKeyDown, ...props });
};

const View = ({
	modalContentEL,
	handleKeyDown,
	modalTitle,
	modalSize = 'medium',
	isOpen,
	handleStateChange,
	headerIcon,
	headerClass,
	rootClass,
	children
}) => {
	return (
		<>
			{isOpen && (
				<>
					<div className="modal-overlay" tabIndex="-1"></div>
					<div
						className={`modal-root ${rootClass ? rootClass : ''}`}
						role="dialog"
						aria-modal="true"
						modal-size={modalSize}>
						<div
							className="modal-content"
							ref={modalContentEL}
							onKeyDown={handleKeyDown}>
							{headerIcon && (
								<header className={`modal-header ${headerClass || ''}`}>
									<h1 className="modal-header-text">
										<img src={headerIcon} alt="logo" /> {modalTitle}
									</h1>
									<span
										className="modal-close"
										onClick={() => handleStateChange(false)}>
										&times;
									</span>
								</header>
							)}
							{children}
						</div>
					</div>
				</>
			)}
		</>
	);
};

const ModalBody = ({ children }) => {
	return <div className="modal-body">{children}</div>;
};

const ModalFooter = ({ children }) => {
	return <>{children && <div className="modal-footer">{children}</div>}</>;
};

export { ModalBody, ModalFooter, ModalDialog };
