import { useState } from 'react';
import Styles from './WatchlistPageStyles';
import TickerSelector from '../../components/tickerSelector/TickerSelector';
import WatchList from '../../components/watchList/WatchList';
import RefreshButton from '../../components/buttons/RefreshButton';
import { timeStamp } from '../../utils';

const View = ({ refreshPage, setRefreshPage }) => {
	return (
		<Styles>
			<div className="card-header">
				<h3 className="card-title">
					<img src="/images/eye-icon.png" alt="watchlist" /> Watchlist
				</h3>
				<div className="card-tools">
					<RefreshButton setRefreshPage={setRefreshPage} />
					<div className="multi-select-item">
						<TickerSelector />
						<img
							className="multi-select-icon"
							src="/images/search-icon.png"
							alt="serch"
						/>
					</div>
				</div>
			</div>
			<WatchList type="page" refreshPage={refreshPage} />
		</Styles>
	);
};

const WatchListPage = () => {
	const [refreshPage, setRefreshPage] = useState(timeStamp);
	return <View {...{ refreshPage, setRefreshPage }} />;
};

export default WatchListPage;
