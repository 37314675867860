import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './VerifyOrderDialog.css';
import { getCurrencySymbol, getTextByValue } from '../../../utils';

const View = ({ isOpen, handleStateChange, onTradeConfirm, data, stockInfo }) => {
	const currency = getCurrencySymbol(stockInfo?.currency_name);
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Verify Trade Order"
			headerIcon="/images/trade-ref-icon.png"
			headerClass="doted-border"
			handleStateChange={handleStateChange}>
			<ModalBody>
				<div className="order-datetime">{data && data.submitted_at}</div>
				<div className="order-detail">
					{data && stockInfo && (
						<>
							<div className="detail-item">
								<label>Ticker or Crypto</label>
								<div className="order-info">
									<span className="order-info-icon">
										<label>{data.symbol}</label>
										<label>{stockInfo.name}</label>
									</span>
								</div>
							</div>
						</>
					)}
					{data && (
						<>
							<div className="detail-item">
								<label>Order Type</label>
								<div>
									{data.side?.toUpperCase()}&nbsp;(
									{getTextByValue('orderType', data.order_type)})
								</div>
							</div>
							<div className="detail-item">
								<label>Number of Shares</label>
								<div>
									{Number(data.qty).toFixed(2)}&nbsp;({currency}
									{Number(data.amt).toFixed(2)})
								</div>
							</div>
							{['limit', 'stop_limit'].includes(data.order_type) && (
								<div className="detail-item">
									<label>Limit Price</label>
									<div>
										{currency}
										{data.limit}
									</div>
								</div>
							)}
							{['stop', 'stop_limit'].includes(data.order_type) && (
								<div className="detail-item">
									<label>Stop Price</label>
									<div>
										{currency}
										{data.stop}
									</div>
								</div>
							)}
							{data.order_type === 'trailing_stop' && (
								<>
									<div className="detail-item">
										<label>Trail Price</label>
										<div>
											{currency}
											{data.trail_price}
										</div>
									</div>
									<div className="detail-item">
										<label>Trail Rate</label>
										<div>{data.trail_rate}%</div>
									</div>
								</>
							)}
							<div className="detail-item">
								<label>Time in Force</label>
								<div>{getTextByValue('timeInForce', data.tif)}</div>
							</div>
							<div className="detail-item">
								<label>Account</label>
								<div>{data.account_type.toUpperCase()}</div>
							</div>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<button
					className="button-sm btn-orange width-200"
					onClick={() => onTradeConfirm(true)}>
					Ok
				</button>
			</ModalFooter>
		</ModalDialog>
	);
};

const VerifyOrderDialog = (props) => {
	const onTradeConfirm = () => {
		props.executeTradeOrder(true);
		props.handleStateChange(false);
	};

	return <View onTradeConfirm={onTradeConfirm} {...props} />;
};

export default VerifyOrderDialog;
