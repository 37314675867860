import { useContext } from 'react';
import AuthContext from './AuthContext';
import { NotAuthRouter } from '../route/AppRouter';

const AuthGuard = ({ children }) => {
	const [isAuthenticated] = useContext(AuthContext);
	return isAuthenticated ? children : <NotAuthRouter />;
};

export default AuthGuard;
