export default {
	orderType: [
		{ text: 'Market', value: 'market' },
		{ text: 'Limit', value: 'limit' },
		{ text: 'Stop', value: 'stop' }
		// { text: 'Stop Limit', value: 'stop_limit' },
		// { text: 'Trailing Stop', value: 'trailing_stop' }
	],
	timeInForce: [
		{ text: 'Day', value: 'day' },
		{ text: 'Good till Cancelled', value: 'gtc' },
		{ text: 'Fill or Kill', value: 'fok' },
		{ text: 'Immediate or Cancel', value: 'ioc' },
		{ text: 'At the Open', value: 'opg' },
		{ text: 'At the Close', value: 'opc' }
	]
};
