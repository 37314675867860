export default [
	{
		id: 1,
		title: 'News Title',
		sentiments: '85 % Positive Sentiment',
		sentimentsCategory: 1,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	},
	{
		id: 2,
		title: 'News Title',
		sentiments: '85 % Positive Sentiment',
		sentimentsCategory: 1,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	},
	{
		id: 3,
		title: 'News Title',
		sentiments: 'Neutral Sentiment',
		sentimentsCategory: 3,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	},
	{
		id: 4,
		title: 'News Title',
		sentiments: 'Neutral Sentiment',
		sentimentsCategory: 3,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	},
	{
		id: 5,
		title: 'News Title',
		sentiments: 'Neutral Sentiment',
		sentimentsCategory: 3,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	},
	{
		id: 6,
		title: 'News Title',
		sentiments: 'Neutral Sentiment',
		sentimentsCategory: 3,
		dateTime: '22:18 - JAN 30 2022',
		tag: 'REUTERS',
		image: 'news-picture.png',
		description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna ante sodales lectus ut fringilla
                      adipiscing. In diam viverra suspendisse turpis a quis id sagittis praesent. Dictumst vitae quisque
                      magnis turpis vulputate pharetra tortor tincidunt massa. Vitae quis get nibh pellentesque quam enim
                      risus, neque. Et ultrices orci faucibus varius at. Leo, et blandit curabitur sapien, turpis. Aliquam tortor
                      varius matti facilisi amet, aenean fringilla neque. Volutpat imperdiet est egestas ultricies imperdiet
                      volutpat. Vulputate condimentum nun ornare convallis pretium dictum.`
	}
];
