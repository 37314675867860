import styled from 'styled-components';

export const SortByDiv = styled.div`
	font-family: var(--font-family-inter);
	font-style: normal;
	line-height: 29px;
	display: flex;
	padding-top: 10px;
	align-items: center;
	width: 200px;
	margin-left: 20px;

	label {
		color: var(--font-grey3);
		font-size: var(--font-size-m);
	}

	& select {
		border: none;
		outline: none;
		font-size: var(--font-size-m);
		margin-left: 10px;
		height: 30px;
		border: var(--border-grey-1);
		background: var(--bg-white);
		border-radius: 10px;
		color: #8e8e8e;
	}
`;

export default SortByDiv;
