import { useEffect, useState, useMemo } from 'react';
import getConfig from './config/getConfig';
import loadChart from './loadChart';

const useChart = ({
	isFullScreen,
	isAnnotation,
	chartId,
	chartData,
	chartType,
	tickInterval,
	chartLineType,
	rangeSelectorListener,
	chartParam,
	symbolType
}) => {
	const [chart, setChart] = useState(null);
	const chartConfig = getConfig(
		chartData,
		chartType,
		tickInterval,
		chartLineType,
		rangeSelectorListener,
		symbolType
	);

	useEffect(() => {
		setChart(null);
	}, [tickInterval]);

	useEffect(() => {
		loadChart({
			chartData,
			setChart,
			isFullScreen,
			isAnnotation,
			chartType,
			tickInterval,
			chartLineType,
			chartConfig,
			chartParam,
			chartId
		});
	}, [chartData, chartLineType, chartParam]);

	return {
		chart: useMemo(() => chart, [isFullScreen, chartConfig])
	};
};

export default useChart;
