import { rest } from 'msw';
import {
	priceEarningsData,
	currentData,
	cashflowData,
	debtData,
	debtEquityData,
	interestData,
	assetData,
	grossMarginData,
	operMarginData,
	returnOnEquityData,
	returnOnAssetsData,
	bookValueData,
	stockPriceData,
	epsData,
	ebitData,
	netData,
	revData,
	gpData
} from '../fixtures/financialChartData';

export default [
	rest.post('/financials/price_earnings', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(priceEarningsData[symbol]));
	}),
	rest.post('/financials/current', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(currentData[symbol]));
	}),
	rest.post('/financials/cashflow', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(cashflowData[symbol]));
	}),
	rest.post('/financials/debt', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(debtData[symbol]));
	}),
	rest.post('/financials/debt_equity', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(debtEquityData[symbol]));
	}),
	rest.post('/financials/interest', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(interestData[symbol]));
	}),
	rest.post('/financials/asset', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(assetData[symbol]));
	}),
	rest.post('/financials/grossmargin', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(grossMarginData[symbol]));
	}),
	rest.post('/financials/opermargin', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(operMarginData[symbol]));
	}),
	rest.post('/financials/returnonequity', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(returnOnEquityData[symbol]));
	}),
	rest.post('/financials/returnonassets', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(returnOnAssetsData[symbol]));
	}),
	rest.post('/financials/bookvalue', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(bookValueData[symbol]));
	}),
	rest.post('/financials/stock_price', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(stockPriceData[symbol]));
	}),
	rest.post('/financials/eps', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(epsData[symbol]));
	}),
	rest.post('/financials/ebit', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(ebitData[symbol]));
	}),
	rest.post('/financials/net', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(netData[symbol]));
	}),
	rest.post('/financials/rev', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(revData[symbol]));
	}),
	rest.post('/financials/gp', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(gpData[symbol]));
	})
];
