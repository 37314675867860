import styled from 'styled-components';

const Form = styled.form`
	button {
		background: none;
		border: none;
		cursor: pointer;
	}
	svg {
		width: 32px;
		height: 32px;
	}
	.country-selector {
		flex: 1;
		div {
			border: none;
		}
		padding-left: 1rem;
	}
	.disabled {
		cursor: default;
		background: linear-gradient(0deg, rgba(239, 239, 239, 0.3), rgba(239, 239, 239, 0.3));
		border-color: rgba(118, 118, 118, 0.3);
	}
`;

export default Form;
