export default [
	{
		keyword: null,
		id: 'DGS1',
		description: '1-Year Treasury Constant Maturity Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DGS5',
		description: '5-Year Treasury Constant Maturity Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DGS30',
		description: '30-Year Treasury Constant Maturity Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DLTIIT',
		description: 'Treasury Inflation-Indexed Long-Term Average Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLH0A0HYM2EY',
		description: 'ICE BofA US High Yield Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'T10YIE',
		description: '10-Year Breakeven Inflation Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLC0A0CMEY',
		description: 'ICE BofA US Corporate Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLC0A2CAAEY',
		description: 'ICE BofA AA US Corporate Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLC1A0C13YEY',
		description: 'ICE BofA 1-3 Year US Corporate Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLC2A0C35YEY',
		description: 'ICE BofA 3-5 Year US Corporate Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLH0A3HYC',
		description: 'ICE BofA CCC & Lower US High Yield Index Option-Adjusted Spread',
		frequency: null
	},
	{
		keyword: null,
		id: 'BAMLHE00EHYIEY',
		description: 'ICE BofA Euro High Yield Index Effective Yield',
		frequency: null
	},
	{
		keyword: null,
		id: 'CPFF',
		description: '3-Month Commercial Paper Minus Federal Funds Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DFEDTARL',
		description: 'Federal Funds Target Range - Lower Limit',
		frequency: null
	},
	{
		keyword: null,
		id: 'DFEDTARU',
		description: 'Federal Funds Target Range - Upper Limit',
		frequency: null
	},
	{
		keyword: null,
		id: 'DFII5',
		description: '5-Year Treasury Inflation-Indexed Security, Constant Maturity',
		frequency: null
	},
	{
		keyword: null,
		id: 'DFII7',
		description: '7-Year Treasury Inflation-Indexed Security, Constant Maturity',
		frequency: null
	},
	{
		keyword: null,
		id: 'DGS10',
		description: '10-Year Treasury Constant Maturity Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DGS2',
		description: '2-Year Treasury Constant Maturity Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DTB1YR',
		description: '1-Year Treasury Bill: Secondary Market Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DTB6',
		description: '6-Month Treasury Bill: Secondary Market Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'DTP10J28',
		description: '10-Year 0.5% Treasury Inflation-Indexed Note, Due 1/15/2028',
		frequency: null
	},
	{
		keyword: null,
		id: 'IOER',
		description: 'Interest Rate on Excess Reserves',
		frequency: null
	},
	{
		keyword: null,
		id: 'T5YFF',
		description: '5-Year Treasury Constant Maturity Minus Federal Funds Rate',
		frequency: null
	},
	{
		keyword: null,
		id: 'T5YIE',
		description: '5-Year Breakeven Inflation Rate',
		frequency: null
	}
];
