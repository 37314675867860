import styled from 'styled-components';

const Styles = styled.article.attrs({
	className: 'wrapper-container'
})`
	.graph {
		height: 40px;
		width: 135px;
	}

	.multi-select-item .multi-select-icon {
		bottom: 10px;
	}

	.multi-select-wrapper .searchWrapper {
		min-height: unset;
		margin-top: 0px;
	}

	.multi-select-wrapper .searchWrapper input {
		padding: 5px 0px;
		margin-top: 0px;
	}

	.multi-select-wrapper .searchWrapper .chip {
		padding: 5px 5px;
	}

	@media screen and (min-width: 100px) and (max-width: 676px) {
		.graph {
			height: auto;
			max-width: 80px;
		}

		.trade.tab ul.nav li {
			font-size: var(--font-size-xs);
		}
	}
`;

export default Styles;
