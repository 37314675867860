import { useState, useEffect } from 'react';
import { getToken } from '../utils';
import AuthContext from './AuthContext';
import AuthGuard from './AuthGuard';

const AuthProvider = ({ children }) => {
	const authState = useState(false);
	const setIsAuthenticated = authState[1];
	useEffect(() => {
		const token = getToken();
		setIsAuthenticated(token);
	}, []);
	return (
		<AuthContext.Provider value={authState}>
			<AuthGuard>{children}</AuthGuard>
		</AuthContext.Provider>
	);
};

export default AuthProvider;
