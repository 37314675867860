import 'highcharts/css/stocktools/gui.css';
import 'highcharts/css/annotations/popup.css';
import Highchart from 'highcharts/highstock';
import loadChartModules from './loadChartModules';

const loadChart = ({
	chartData,
	setChart,
	isFullScreen,
	isAnnotation,
	chartType,
	tickInterval,
	chartLineType,
	chartConfig,
	chartParam,
	chartId
}) => {
	if (chartData.length < 1) {
		setChart(null);
	} else {
		loadChartModules({ isFullScreen, isAnnotation, chartType, tickInterval, chartLineType });
		if (['portfolio', 'accountBalance', 'metrics'].includes(chartType)) {
			chartConfig.rangeSelector.selected = chartParam;
		}
		setChart(Highchart.stockChart(chartId, chartConfig));
	}
};

export default loadChart;
