export default {
	AAPL: {
		id: 'b0b6dd9d-8b9b-48a9-ba46-b9d54906e415',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'AAPL',
		name: 'Apple Inc. Common Stock',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	SPY: {
		id: 'b28f4066-5c6d-479b-a2af-85dc1a8f16fb',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'SPY',
		name: 'SPDR S&P 500 ETF Trust',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	TSLA: {
		id: '8ccae427-5dd0-45b3-b5fe-7ba5e422c766',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'TSLA',
		name: 'Tesla, Inc. Common Stock',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	QQQ: {
		id: '2d9e926c-e17c-47c3-ad8c-26c7a594e48f',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'QQQ',
		name: 'Invesco QQQ Trust, Series 1',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	VXX: {
		id: 'b0a9ca31-93a6-401e-8171-d427b63476a4',
		class: 'us_equity',
		exchange: 'BATS',
		symbol: 'VXX',
		name: 'iPath Series B S&P 500 VIX Short-Term Futures ETN',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: false,
		easy_to_borrow: false,
		fractionable: true
	},
	BAC: {
		id: '061588a3-d70b-4b9c-a3f6-636aaa16acc4',
		class: 'us_equity',
		exchange: 'NYSE',
		symbol: 'BAC',
		name: 'Bank of America Corporation',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	SPXS: {
		id: 'f633ecdd-2bdc-4460-9b33-a3b0e1abbea2',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'SPXS',
		name: 'Direxion Daily S&P 500 Bear 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: false,
		easy_to_borrow: false,
		fractionable: false
	},
	SPXL: {
		id: '33a38003-a8a3-4ce2-b786-71f88c9a03cb',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'SPXL',
		name: 'Direxion Daily S&P 500  Bull 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	TQQQ: {
		id: 'dce2ac30-c928-4416-be25-2213d057f30a',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'TQQQ',
		name: 'ProShares UltraPro QQQ',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	ETHUSD: {
		id: 'dce2ac30-c928-4416-be25-2213d057f30a',
		class: 'crypto',
		exchange: 'NASDAQ',
		symbol: 'ETHUSD',
		name: 'Ethereum USD',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	open_SPXS: {
		id: 'f633ecdd-2bdc-4460-9b33-a3b0e1abbea2',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'open_SPXS',
		name: 'Direxion Daily S&P 500 Bear 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: false,
		easy_to_borrow: false,
		fractionable: false
	},
	open_SPXL: {
		id: '33a38003-a8a3-4ce2-b786-71f88c9a03cb',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'open_SPXL',
		name: 'Direxion Daily S&P 500  Bull 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	open_TQQQ: {
		id: 'dce2ac30-c928-4416-be25-2213d057f30a',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'open_TQQQ',
		name: 'ProShares UltraPro QQQ',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	paper_SPXS: {
		id: 'f633ecdd-2bdc-4460-9b33-a3b0e1abbea2',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'paper_SPXS',
		name: 'Direxion Daily S&P 500 Bear 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: false,
		easy_to_borrow: false,
		fractionable: false
	},
	paper_SPXL: {
		id: '33a38003-a8a3-4ce2-b786-71f88c9a03cb',
		class: 'us_equity',
		exchange: 'ARCA',
		symbol: 'paper_SPXL',
		name: 'Direxion Daily S&P 500  Bull 3x Shares',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	},
	paper_TQQQ: {
		id: 'dce2ac30-c928-4416-be25-2213d057f30a',
		class: 'us_equity',
		exchange: 'NASDAQ',
		symbol: 'paper_TQQQ',
		name: 'ProShares UltraPro QQQ',
		status: 'active',
		tradable: true,
		marginable: true,
		shortable: true,
		easy_to_borrow: true,
		fractionable: true
	}
};
