import { useEffect, useState } from 'react';
import useDataApi from '../../hooks/useDataApi';
import { AccountBalanceIcon } from '../../styles/icons/icons';
import { sentimentIconByText } from '../../utils';
import { breakingNewsTimeFormat } from '../../utils/datetime';
import NextPrevNavigator from '../nextPrevNavigator/NextPrevNavigator';
import './BreakingNews.css';

const View = ({ data: headlines, setOffset, offset, isLoading, isError }) => (
	<>
		<div className="card padding-left-zero">
			<div className="card-header header-dotted">
				<h3 className="card-title card-title">
					<AccountBalanceIcon /> Breaking news
				</h3>
			</div>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					{headlines &&
						headlines.slice(0, 10).map((headline) => {
							return (
								<div className="news-item" key={`news-item-${headline.url}`}>
									<div className="news-info">
										<label>
											{' '}
											{breakingNewsTimeFormat(headline.time_pub)} -{' '}
											{headline.source}
										</label>
										<a href={headline.url} target="_blank" rel="noreferrer">
											{headline.txt}
										</a>
										<div className="news-info-status">
											<img
												src={`/images/${sentimentIconByText(
													headline.sentiment
												)}`}
												alt="sentiment"
											/>
											<div> {headline.sentiment} Sentiment</div>
										</div>
									</div>
								</div>
							);
						})}
				</>
			)}
			<NextPrevNavigator
				setOffset={setOffset}
				offset={offset}
				count={headlines.length}
				limit={10}
			/>
		</div>
	</>
);

const BreakingNews = (props) => {
	const [offset, setOffset] = useState(0);

	const url = props.symbol
		? `/news/headline/ticker?symbol=${props.symbol}&start=${offset}`
		: `/news/headline?start=${offset}`;

	const body = { ticker: props.symbol, start: offset };
	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	});

	useEffect(() => {
		setUrl(url);
	}, [url]);

	return <View setOffset={setOffset} offset={offset} {...dataProps} {...props} />;
};

export default BreakingNews;
