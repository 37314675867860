const View = ({ data, type, handleChange }) => {
	return (
		<select
			value={data}
			name={type}
			onChange={(e) => {
				handleChange(e);
			}}
			className="chart-type-selector">
			{type === 'financialMetrics' ? (
				<OptionGroup optgroups={options[type]} />
			) : (
				<Option options={options[type]} />
			)}
		</select>
	);
};

const Option = ({ options }) => {
	return options.map((item) => (
		<option key={item.value} value={item.value}>
			{item.label}
		</option>
	));
};

const OptionGroup = ({ optgroups }) => {
	return optgroups.map((optgroup) => (
		<optgroup label={optgroup.groupText} key={optgroup.groupId}>
			<Option options={optgroup.items} />
		</optgroup>
	));
};

const FinancialChartFilter = (props) => {
	return <View {...props} />;
};

const options = {
	linetype: [{ label: 'Single Line', value: 'line' }],
	financialMetrics: [
		{
			groupText: 'Ratios - 0.00%',
			groupId: 'ratios',
			items: [
				{ label: 'Asset Turn', value: 'asset' },
				{ label: 'Debt to Equity Ratio', value: 'debt_equity' },
				{ label: 'Gross Margin', value: 'grossmargin' },
				{ label: 'Operating Margin', value: 'opermargin' },
				{ label: 'PE Ratio', value: 'price_earnings' },
				{ label: 'Return on Assets', value: 'returnonassets' },
				{ label: 'Return on Equity', value: 'returnonequity' }
			]
		},
		{
			groupText: 'Financials - 0,000 (millions)',
			groupId: 'financials',
			items: [
				{ label: 'EBIT', value: 'ebit' },
				{ label: 'Total Revenue', value: 'rev' },
				{ label: 'Total Debt', value: 'debt' },
				{ label: 'Net Income', value: 'net' }
			]
		},
		{
			groupText: 'Performance - $0.00',
			groupId: 'performance',
			items: [
				{ label: 'Earnings per Share', value: 'eps' },
				{ label: 'Gross Profit', value: 'gp' }
			]
		}
	]
};

export default FinancialChartFilter;
