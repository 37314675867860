export default [
	{
		time_pub: '2022-06-12T22:45:34.000Z',
		txt: 'Apple Complies With Dutch Regulators, Allows Other Payment Methods on Dating Apps',
		sentiment: 'Negative',
		summary:
			'Apple now plans to allow other methods of payment for Dutch dating apps, after intervention from the Netherlands Authority for Consumers and Markets (ACM), a report from ACM said. This will see a change to the old rules, in which customers of dating apps were only able to pay using payment methods imposed by Apple.',
		source: 'PYMNTS',
		url: 'https://www.pymnts.com/mobile-applications/2022/apple-complies-with-dutch-regulators-allows-other-payment-methods-on-dating-apps/'
	},
	{
		time_pub: '2022-06-12T16:49:30.000Z',
		txt: 'Apple may finally have to kill the Lightning port',
		sentiment: 'Neutral',
		summary:
			"This story is part of CNN Business' Nightcap newsletter. To get it in your inbox, sign up for free, here.",
		source: 'CNN Business',
		url: 'https://www.cnn.com/2022/06/12/tech/lightning-cable-usbc-nightcap/index.html'
	},
	{
		time_pub: '2022-06-12T09:18:24.000Z',
		txt: 'Fed decision, Apple union vote, Kroger earnings top week ahead',
		sentiment: 'Neutral',
		summary:
			'FOX Business takes a look at the upcoming events that are likely to move financial markets in the coming days.',
		source: 'Fox Business',
		url: 'https://www.foxbusiness.com/markets/fed-decision-apple-union-vote-kroger-earnings-week-ahead'
	},
	{
		time_pub: '2022-06-11T16:15:25.000Z',
		txt: "Apple's big week was not without its flaws",
		sentiment: 'Negative',
		summary:
			'Hi, friends! Welcome back to Week In Review, the newsletter where we recap some of the top stories to cross TechCrunch dot com this week.',
		source: 'TechCrunch',
		url: 'https://techcrunch.com/2022/06/11/apples-big-week-was-not-without-its-flaws/'
	},
	{
		time_pub: '2022-06-11T11:53:00.000Z',
		txt: '3 Unstoppable Growth Stocks to Buy in the Stock Market Sell-Off',
		sentiment: 'Positive',
		summary:
			'These three stocks have revenue and cash flows moving higher while stock prices are moving lower.',
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/11/3-unstoppable-growth-stocks-to-buy-in-the-stock/'
	},
	{
		time_pub: '2022-06-11T10:00:01.000Z',
		txt: "Apple's newest homegrown chips present a fresh challenge to Microsoft's Windows business",
		sentiment: 'Positive',
		summary:
			'Apple could gain share from Microsoft Windows in PC shipments after announcing its new and more powerful M2 processors.',
		source: 'CNBC',
		url: 'https://www.cnbc.com/2022/06/11/apple-could-gain-ground-against-microsoft-windows-with-m2-chips.html'
	},
	{
		time_pub: '2022-06-11T09:58:00.000Z',
		txt: 'Dutch watchdog says Apple to offer other payment methods in dating apps',
		sentiment: 'Negative',
		summary:
			"The Netherlands' antitrust watchdog on Saturday said Apple Inc will allow different payment methods in Dutch dating applications, ending a dispute that resulted in the company being fined 50 million euros ($52.58 million).",
		source: 'Reuters',
		url: 'https://www.reuters.com/technology/dutch-watchdog-says-apple-offer-other-payment-methods-dating-apps-2022-06-11/'
	},
	{
		time_pub: '2022-06-11T08:30:00.000Z',
		txt: '3 Warren Buffett Stocks to Buy Before the Next Bull Market',
		sentiment: 'Positive',
		summary:
			"Following the world's greatest investor can pay handsome rewards during tough market conditions.",
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/11/3-warren-buffett-stocks-to-buy-before-bull-market/'
	},
	{
		time_pub: '2022-06-11T07:45:00.000Z',
		txt: "An American Supercomputer Just Shattered the Speed Record. What's That Mean?",
		sentiment: 'Neutral',
		summary: 'The data derived from these machines is invaluable.',
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/11/an-american-supercomputer-just-shattered-the-speed/'
	},
	{
		time_pub: '2022-06-11T06:00:00.000Z',
		txt: 'Is Apple About to Conquer This Innovative Big Tech Space?',
		sentiment: 'Positive',
		summary: 'Its products are usually worth the wait.',
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/11/is-apple-about-to-conquer-this-innovative-big-tech/'
	},
	{
		time_pub: '2022-07-12T14:19:44.000Z',
		txt: 'This One-Time King Is in Danger of Becoming Just Another Electric Vehicle Company',
		sentiment: 'Negative',
		summary:
			'With competition and supply chain issues, is electric vehicle company Tesla losing its luster? The post This One-Time King Is in Danger of Becoming Just Another Electric Vehicle Company appeared first on InvestorPlace.',
		source: 'InvestorPlace',
		url: 'https://investorplace.com/smartmoney/2022/07/this-one-time-king-is-in-danger-of-becoming-just-another-electric-vehicle-company/'
	},
	{
		time_pub: '2022-07-12T12:50:40.000Z',
		txt: 'Why Apple Stock Is Down 17% So Far This Year',
		sentiment: 'Negative',
		summary: "Investors have been concerned about the company's supply chain problems.",
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/07/12/why-apple-stock-is-down-17-so-far-this-year/'
	},
	{
		time_pub: '2022-07-12T12:25:31.000Z',
		txt: "Apple is going to do a car and it's going to be a gamechanger, says Morgan Stanley's Adam Jonas",
		sentiment: 'Positive',
		summary:
			"Adam Jonas, Morgan Stanley head of global autos and shared mobility research, joins 'TechCheck' to discuss his thoughts on the Elon Musk-Twitter saga, what's going on with Apple's balance sheet and more.",
		source: 'CNBC Television',
		url: 'https://www.youtube.com/watch?v=isV0uTHrtQ0'
	},
	{
		time_pub: '2022-07-12T11:36:01.000Z',
		txt: 'Supply Woes, Weak Demand Cause Deepest Decline in PC Shipments',
		sentiment: 'Negative',
		summary:
			'Tepid consumer demand for PCs, lockdowns in China, supply-chain bottlenecks, plus logistics and geopolitical challenges induce the steepest personal computer shipment decline in years, according to Gartner and International Data Corporation.',
		source: 'Zacks Investment Research',
		url: 'https://www.zacks.com/stock/news/1951154/supply-woes-weak-demand-cause-deepest-decline-in-pc-shipments'
	},
	{
		time_pub: '2022-07-12T11:07:20.000Z',
		txt: "Don't Let Near-Term Issues Cloud Your View on Apple Stock",
		sentiment: 'Positive',
		summary:
			"The market could continue to have a cautious view on shares. Fortunately, if you're looking to enter a buy-and-hold position in AAPL stock, this works to your advantage, by providing a favorable entry point.",
		source: 'InvestorPlace',
		url: 'https://investorplace.com/2022/07/aapl-stock-dont-let-near-term-issues-cloud-your-view/'
	}
];
