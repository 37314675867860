export default {
	live: [
		{
			id: '47557269-a623-41a6-9d8d-80fa09fa0ea',
			status: 200
		},
		{
			id: '47557269-a623-41a6-9d8d-80fa09fa0eb',
			status: 200
		}
	],
	paper: [
		{
			id: '47557269-a623-41a6-9d8d-80fa09fa0ea',
			status: 200
		},
		{
			id: '47557269-a623-41a6-9d8d-80fa09fa0eb',
			status: 200
		}
	]
};
