import styled from 'styled-components';

const Styles = styled.div.attrs({
	className: 'company-logo'
})`
	margin: 0;

	a {
		display: flex;
		text-decoration: none;
		align-items: center;

		div {
			letter-spacing: -1px;
			margin-left: 8px;
			color: var(--font-black);
			font-size: var(--font-size-xxxl);
			font-weight: var(--font-weight-600);

			sup {
				font-size: var(--font-size-xs);
			}
		}
	}

	svg {
		height: 45px;
		width: 44px;
	}
`;

export default Styles;
