import { useState, useEffect } from 'react';
import getEndpoint from '../../config/endpoint';
import { addAuthHeader } from '../utils';

const useDataApi = (initialUrl, initialData, init = null) => {
	const [data, setData] = useState(initialData);
	const [url, setUrl] = useState(initialUrl);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	let timer = null;
	const cleanUp = () => {
		setData(initialData);
		setIsLoading(false);
		setIsError(false);
		clearTimeout(timer);
	};
	useEffect(() => {
		let retryCount = 0;
		const fetchData = async () => {
			setIsError(false);
			setIsLoading(true);

			try {
				const resposne = await fetch(getEndpoint(url), addAuthHeader(url, init));
				if ([502, 503, 504].includes(resposne.status) && retryCount < 2) {
					console.log(resposne);
					retryCount++;
					timer = setTimeout(fetchData(), retryCount * 500);
				} else {
					const result = await resposne.json();
					setData(result);
				}
			} catch (error) {
				console.log(error);
				setIsError(true);
			}
			setIsLoading(false);
		};
		url && fetchData();
		return () => cleanUp();
	}, [url]);
	return [{ data, isLoading, isError }, setUrl];
};

export default useDataApi;
