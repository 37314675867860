export const headerItems = [
	{ url: '/', text: 'Home' },
	{ url: '/trades', text: 'Trade' },
	{ url: '/tickers', text: 'Tickers' },
	{ url: '/algos', text: 'Algos' },
	{ url: '/insights', text: 'Insights' },
	{ url: 'https://www.machinetrader.io/learn', text: 'Learn', external: true }
];

export const connectItems = [
	{ url: 'https://www.machinetrader.io/blog-articles', text: 'Blog', external: true },
	{ url: 'https://www.machinetrader.io/history', text: 'Our History', external: true },
	{ url: 'https://www.machinetrader.io/about-us', text: 'About Us', external: true },
	{
		url: 'https://join.slack.com/t/machinetrader/shared_invite/zt-19g4ix2v6-uxCLZdAYFGSI32tnFVqVnw',
		text: 'Slack Channel',
		external: true
	}
];

const pricing = {
	url: 'https://www.machinetrader.io/pricing-new',
	text: 'Pricing',
	external: true
};
const footerItems = [...headerItems, pricing, ...connectItems];
footerItems[footerItems.length - 1] = {
	url: 'https://www.machinetrader.io/terms-of-service',
	text: 'Terms Of Service',
	external: true
};

footerItems.push({
	url: 'https://www.machinetrader.io/cookiepolicy',
	text: 'Cookie Policy',
	external: true
});
footerItems.push({
	url: 'https://www.machinetrader.io/privacy-policy',
	text: 'Privacy Policy',
	external: true
});

export { footerItems };
