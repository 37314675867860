import { useState } from 'react';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import { getCurrencySymbol, getTextByValue } from '../../../utils';
import { newsTimeFormat } from '../../../utils/datetime';
import './OrderConfirmationDialog.css';

const View = ({ isOpen, handleClose, data, stockInfo }) => {
	const currency = getCurrencySymbol(stockInfo?.currency_name);
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Order Confirmation"
			headerIcon="/images/trade-ref-icon.png"
			headerClass="doted-border"
			handleStateChange={handleClose}>
			<ModalBody>
				<div className="order-datetime">{data && newsTimeFormat(data.submitted_at)}</div>
				<div className="order-detail">
					{data?.status && stockInfo && (
						<>
							<div className="detail-item">
								<label>Ticker or Crypto</label>
								<div className="order-info">
									<span className="order-info-icon">
										<label>{data.symbol}</label>
										<label>{stockInfo.name}</label>
									</span>
								</div>
							</div>
						</>
					)}
					{data?.status && (
						<>
							<div className="detail-item">
								<label>Order Type</label>
								<div>
									{data.side?.toUpperCase()}&nbsp;(
									{getTextByValue('orderType', data.order_type)})
								</div>
							</div>
							<div className="detail-item">
								<label>Number of Shares</label>
								<div>{Number(data.qty).toFixed(2)}</div>
							</div>
							{['limit', 'stop_limit'].includes(data.order_type) && (
								<div className="detail-item">
									<label>Limit Price</label>
									<div>
										{currency}
										{data.limit_price}
									</div>
								</div>
							)}
							{['stop', 'stop_limit'].includes(data.order_type) && (
								<div className="detail-item">
									<label>Stop Price</label>
									<div>
										{currency}
										{data.stop_price}
									</div>
								</div>
							)}
							{data.order_type === 'trailing_stop' && (
								<>
									<div className="detail-item">
										<label>Trail Price</label>
										<div>
											{currency}
											{data.trail_price}
										</div>
									</div>
									<div className="detail-item">
										<label>Trail Rate</label>
										<div>{data.trail_percent}%</div>
									</div>
								</>
							)}
							<div className="detail-item">
								<label>Time in Force</label>
								<div>{getTextByValue('timeInForce', data.time_in_force)}</div>
							</div>
							<div className="detail-item">
								<label>Status</label>
								<div>{data.status.toUpperCase()}</div>
							</div>
							<div className="detail-item">
								<label>Order Id</label>
								<div> {data.client_order_id.toUpperCase()} </div>
							</div>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				<button className="button-sm btn-orange width-200" onClick={handleClose}>
					Ok
				</button>
			</ModalFooter>
		</ModalDialog>
	);
};

const OrderConfirmationDialog = (props) => {
	const [isOpen, setIsOpen] = useState(true);

	const handleClose = () => {
		setIsOpen(false);
		props.refreshPage();
	};

	return <View isOpen={isOpen} {...props} handleClose={handleClose} />;
};

export default OrderConfirmationDialog;
