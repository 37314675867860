import useChart from './useChart';
import useDataApi from '../../hooks/useDataApi';
import { isData } from '../../utils';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import './Chart.css';

const View = (id, isError) => {
	return (
		<WidgetBox header="News Sentiment" icon="/images/heart-icon.png" link="#">
			{isError && <div>Something went wrong</div>}
			<div className="padding-top-20 " id={id}></div>
		</WidgetBox>
	);
};

const NewsSentimentChart = (props) => {
	const [dataProps] = useDataApi(
		'/news/sentiment',
		{},
		{
			method: 'POST'
		}
	);
	const { data, isError } = dataProps;
	const chartData = isData(data) ? data : [];
	useChart({
		chartData,
		chartType: 'newsSentiment',
		...props
	});
	return View(props.chartId, isError);
};

export default NewsSentimentChart;
