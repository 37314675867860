import VerifyOrderDialog from '../modals/verifyOrderDialog/VerifyOrderDialog';
import OrderConfirmationDialog from '../modals/OrderConfirmationDialog/OrderConfirmationDialog';
import { isPositive } from '../../utils';
import NoAlpacaDialog from '../modals/noAlpacaDialog/NoAlpacaDialog';
import AlpacaErrorDialog from '../modals/alpacaErrorDialog/AlpacaErrorDialog';

const SubmitButton = ({ children }) => {
	return (
		<>
			<div className="trade-box-item no-flex margin-top-20">
				<button type="submit" className="button-sm btn-orange">
					{children}
				</button>
			</div>
		</>
	);
};

const showError = (errors, field) => {
	return (
		<>
			{errors[field]?.type === 'required' && <span>This field is required.</span>}
			{errors[field]?.type === 'min' && <span>Value should positive number.</span>}
			{errors[field]?.type === 'positive' && <span>Value should be greater than zero.</span>}
		</>
	);
};

const TradeFormView = ({
	orderTypes,
	timeInForceList,
	activeTradeType,
	tradeTabType,
	onAmountChange,
	onShareCountChange,
	onCurrentPriceChange,
	onSubmit,
	register,
	trigger,
	control,
	ShareCountWatch,
	handleSubmit,
	errors,
	getValues,
	setValue,
	showVerifyOrder,
	setShowVerifyOrder,
	executeTradeOrder,
	tradeSubmitData,
	tradeResponse,
	stockInfo,
	refreshPage
}) => {
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					<div className="trade-box">
						<div className="trade-box-item">
							<label>Number Of Shares:</label>
							<input
								type="number"
								placeholder="Number of Shares"
								step="0.0000000001"
								className={errors.noOfShares?.type ? 'error' : ''}
								{...register('noOfShares', {
									required: true,
									validate: {
										positive: isPositive
									}
								})}
								onChange={onShareCountChange}
							/>
							{showError(errors, 'noOfShares')}
						</div>
						<div className="trade-box-item">
							<label>Current Price:</label>
							<input
								type="number"
								placeholder="$0.0"
								step="0.0000000001"
								className={errors.currentPrice?.type ? 'error' : ''}
								{...register('currentPrice', {
									required: true,
									validate: {
										positive: isPositive
									}
								})}
								onChange={onCurrentPriceChange}
								disabled
							/>
							{showError(errors, 'currentPrice')}
						</div>
						<div className="trade-box-item">
							<label>Trade Amount:</label>
							<input
								type="number"
								step="0.01"
								placeholder="$0.0"
								className={errors.tradeAmount?.type ? 'error' : ''}
								{...register('tradeAmount', {
									required: true,
									validate: {
										positive: isPositive
									}
								})}
								onChange={onAmountChange}
							/>
							{showError(errors, 'tradeAmount')}
						</div>
						{tradeTabType === 'quick' && (
							<SubmitButton>
								{activeTradeType === 'buy' ? 'Buy' : 'Sell'}{' '}
								{<ShareCountWatch {...{ getValues, control }} />} Shares
							</SubmitButton>
						)}
					</div>
					{tradeTabType === 'manual' && (
						<>
							<div className="trade-box">
								<div className="trade-box-item">
									<label>Order Type:</label>
									<select
										{...register('orderType')}
										onChange={(e) => {
											setValue('orderType', e.target.value);
											trigger();
										}}>
										{orderTypes.length &&
											orderTypes.map((item, i) => {
												return (
													<option key={item.value + i} value={item.value}>
														{item.text}
													</option>
												);
											})}
									</select>
								</div>
								{['limit', 'stop_limit'].includes(getValues('orderType')) && (
									<div className="trade-box-item">
										<label>Limit Price:</label>
										<input
											type="number"
											step="0.0000000001"
											placeholder="$0.0"
											{...register('limitPrice', {
												required: ['limit', 'stoplimit'].includes(
													getValues('orderType')
												),
												min: 0
											})}
										/>
										{showError(errors, 'limitPrice')}
									</div>
								)}
								{['stop', 'stop_limit'].includes(getValues('orderType')) && (
									<div className="trade-box-item">
										<label>Stop Price:</label>
										<input
											type="number"
											placeholder="$0.0"
											step="0.0000000001"
											{...register('stopPrice', {
												required: ['stop', 'stoplimit'].includes(
													getValues('orderType')
												),
												min: 0
											})}
										/>
										{showError(errors, 'stopPrice')}
									</div>
								)}
								{getValues('orderType') === 'trailing_stop' && (
									<>
										<div className="trade-box-item">
											<label>Trail Price:</label>
											<input
												type="number"
												placeholder="$0.0"
												step="0.0000000001"
												{...register('trailPrice', {
													required: true,
													min: 0
												})}
											/>
											{showError(errors, 'trailPrice')}
										</div>
										<div className="trade-box-item">
											<label>Trail Rate:</label>
											<input
												type="number"
												placeholder="0.0"
												step="0.0000000001"
												{...register('trailRate', {
													required: true,
													min: 0
												})}
											/>
											{showError(errors, 'trailRate')}
										</div>
									</>
								)}
							</div>
							<div className="trade-box">
								<div className="trade-box-item">
									<label>Time in Force:</label>
									<select {...register('timeInForce')}>
										{timeInForceList.length &&
											timeInForceList.map((item, i) => {
												return (
													<option key={item.value + i} value={item.value}>
														{item.text}
													</option>
												);
											})}
									</select>
								</div>
								<div className="trade-box-item"></div>
								<SubmitButton>Place Order</SubmitButton>
							</div>
						</>
					)}
				</div>
			</form>

			{showVerifyOrder && (
				<VerifyOrderDialog
					isOpen={showVerifyOrder}
					handleStateChange={setShowVerifyOrder}
					executeTradeOrder={executeTradeOrder}
					data={tradeSubmitData}
					stockInfo={stockInfo}
				/>
			)}
			{tradeResponse?.status && (
				<OrderConfirmationDialog
					data={tradeResponse}
					stockInfo={stockInfo}
					refreshPage={refreshPage}
				/>
			)}
			{tradeResponse?.isBlocked && <NoAlpacaDialog />}
			{tradeResponse?.code && <AlpacaErrorDialog data={tradeResponse} />}
		</>
	);
};

export default TradeFormView;
