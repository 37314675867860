export default {
	currencies: [
		{
			id: 0,
			asset: {
				symbol: 'GBPUSD',
				delta: -0.009,
				image: 'images/screen-shot-2022-01-30-at-5-24-11@2x.png'
			},
			volume: 5313,
			marketcap: 5452313,
			price: { amount: 442.75, delta: -0.0235 },
			gainloss: { hr24: -0.0877, d7: -0.3872 },
			graphsrc: 'images/52-1-2@2x.png'
		},
		{
			id: 1,
			asset: {
				symbol: 'USDJPY',
				delta: -0.0219,
				image: 'images/screen-shot-2022-01-30-at-5-24-17@2x.png'
			},
			volume: 2228.8,
			marketcap: 2587228.8,
			price: { amount: 55.72, delta: -0.0578 },
			gainloss: { hr24: 0.065, d7: 0.165 },
			graphsrc: 'images/4172-1@2x.png'
		},
		{
			id: 2,
			asset: {
				symbol: 'USDCAD',
				delta: 0.0414,
				image: 'images/row@2x.png'
			},
			volume: 331.8,
			marketcap: 925331.8,
			price: { amount: 22.22, delta: -0.0865 },
			gainloss: { hr24: 0.0235, d7: 0.2207 },
			graphsrc: 'images/825-1@2x.png'
		}
	],
	indices: [
		{
			id: 0,
			asset: {
				symbol: 'US Wall St 30',
				delta: -0.009,
				image: 'images/screen-shot-2022-01-30-at-5-24-11@2x.png'
			},
			volume: 5313,
			marketcap: 5452313,
			price: { amount: 442.75, delta: -0.0235 },
			gainloss: { hr24: -0.0877, d7: -0.3872 },
			graphsrc: 'images/52-1-2@2x.png'
		},
		{
			id: 1,
			asset: {
				symbol: 'US SPX 500',
				delta: -0.0219,
				image: 'images/screen-shot-2022-01-30-at-5-24-17@2x.png'
			},
			volume: 2228.8,
			marketcap: 2587228.8,
			price: { amount: 55.72, delta: -0.0578 },
			gainloss: { hr24: 0.065, d7: 0.165 },
			graphsrc: 'images/4172-1@2x.png'
		},
		{
			id: 2,
			asset: {
				symbol: 'US Nas 100',
				delta: 0.0414,
				image: 'images/row@2x.png'
			},
			volume: 331.8,
			marketcap: 925331.8,
			price: { amount: 22.22, delta: -0.0865 },
			gainloss: { hr24: 0.0235, d7: 0.2207 },
			graphsrc: 'images/825-1@2x.png'
		}
	],
	futures: [
		{
			id: 0,
			asset: {
				symbol: 'DOW Futures',
				delta: -0.009,
				image: 'images/screen-shot-2022-01-30-at-5-24-11@2x.png'
			},
			volume: 5313,
			marketcap: 5452313,
			price: { amount: 442.75, delta: -0.0235 },
			gainloss: { hr24: -0.0877, d7: -0.3872 },
			graphsrc: 'images/52-1-2@2x.png'
		},
		{
			id: 1,
			asset: {
				symbol: 'SP 500 Futures',
				delta: -0.0219,
				image: 'images/screen-shot-2022-01-30-at-5-24-17@2x.png'
			},
			volume: 2228.8,
			marketcap: 2587228.8,
			price: { amount: 55.72, delta: -0.0578 },
			gainloss: { hr24: 0.065, d7: 0.165 },
			graphsrc: 'images/4172-1@2x.png'
		},
		{
			id: 2,
			asset: {
				symbol: 'NASDAQ Futures',
				delta: 0.0414,
				image: 'images/row@2x.png'
			},
			volume: 331.8,
			marketcap: 925331.8,
			price: { amount: 22.22, delta: -0.0865 },
			gainloss: { hr24: 0.0235, d7: 0.2207 },
			graphsrc: 'images/825-1@2x.png'
		}
	],
	crypto: [
		{
			id: 0,
			asset: {
				symbol: 'Bitcoin',
				delta: -0.009,
				image: 'images/screen-shot-2022-01-30-at-5-24-11@2x.png'
			},
			volume: 5313,
			marketcap: 5452313,
			price: { amount: 442.75, delta: -0.0235 },
			gainloss: { hr24: -0.0877, d7: -0.3872 },
			graphsrc: 'images/52-1-2@2x.png'
		},
		{
			id: 1,
			asset: {
				symbol: 'Ethereum',
				delta: -0.0219,
				image: 'images/screen-shot-2022-01-30-at-5-24-17@2x.png'
			},
			volume: 2228.8,
			marketcap: 2587228.8,
			price: { amount: 55.72, delta: -0.0578 },
			gainloss: { hr24: 0.065, d7: 0.165 },
			graphsrc: 'images/4172-1@2x.png'
		},
		{
			id: 2,
			asset: {
				symbol: 'Dogecoin',
				delta: 0.0414,
				image: 'images/row@2x.png'
			},
			volume: 331.8,
			marketcap: 925331.8,
			price: { amount: 22.22, delta: -0.0865 },
			gainloss: { hr24: 0.0235, d7: 0.2207 },
			graphsrc: 'images/825-1@2x.png'
		}
	]
};
