import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useDataApi from '../../hooks/useDataApi';
import { WatchlistIcon } from '../../styles/icons/icons';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import List from '../list/List';
import { shortForm, getLongFormColumns } from './WatchListColumns';

const Dashboard = ({ data }) => (
	<WidgetBox header="Watchlist">
		<div className="card-header">
			<h3 className="card-title">
				<WatchlistIcon /> WatchList
			</h3>
		</div>
		<List
			newData={data}
			columns={shortForm}
			isPagination={false}
			pageSize={3}
			tableClass="sidebar-widget"
		/>

		<div className="text-right">
			<NavLink to="/watchlist" className="link-text">
				&gt; View More
			</NavLink>
		</div>
	</WidgetBox>
);

const Page = ({ data }) => {
	return (
		<>
			<List newData={data} columns={getLongFormColumns()} />
		</>
	);
};

const ViewSelector = ({ data, type }) =>
	type === 'short' ? <Dashboard data={data} /> : <Page data={data} />;

const View = ({ data, isLoading, isError, type = 'short' }) => (
	<>
		{isError && <div>Something went wrong</div>}
		{isLoading ? <div>Loading...</div> : <ViewSelector data={data} type={type} />}
	</>
);

const WatchList = ({ type, refreshPage }) => {
	const url = `/watchlist?hash=${refreshPage}`;
	const [dataProps, setUrl] = useDataApi(url, [], { method: 'POST' });

	useEffect(() => {
		setUrl(url);
	}, [refreshPage]);

	return <View {...dataProps} type={type} />;
};

export default WatchList;
