import 'highcharts/css/stocktools/gui.css';
import 'highcharts/css/annotations/popup.css';
import Highchart from 'highcharts/highstock';
import Annotation from 'highcharts/modules/annotations';
import FullScreen from 'highcharts/modules/full-screen';
import Data from 'highcharts/modules/data';
import IndicatorsAll from 'highcharts/indicators/indicators-all';
import Dragpanes from 'highcharts/modules/drag-panes';
import AnnotationAdvance from 'highcharts/modules/annotations-advanced';
import PriceIndicator from 'highcharts/modules/price-indicator';
import StockTools from 'highcharts/modules/stock-tools';
import Heikinashi from 'highcharts/modules/heikinashi';
import Hollowcandlestick from 'highcharts/modules/hollowcandlestick';

const loadChartModules = ({
	isFullScreen,
	isAnnotation,
	chartType,
	tickInterval,
	chartLineType
}) => {
	isFullScreen && FullScreen(Highchart);
	isAnnotation && Annotation(Highchart);
	if (chartType === 'metricsDetail') {
		Data(Highchart);
		IndicatorsAll(Highchart);
		Dragpanes(Highchart);
		AnnotationAdvance(Highchart);
		PriceIndicator(Highchart);
		StockTools(Highchart);
		Heikinashi(Highchart);
		Hollowcandlestick(Highchart);
	}
	if (
		chartType === 'trade' &&
		(tickInterval === 'min' || tickInterval === 'day') &&
		(chartLineType === 'indicator' || chartLineType === 'custom')
	) {
		Data(Highchart);
		IndicatorsAll(Highchart);
	}
};

export default loadChartModules;
