import { createContext, useContext, useState, useEffect } from 'react';
import './Tabs.css';

const TabContent = ({ id, children }) => {
	const [activeTab] = useContext(TabContext);

	return activeTab === id ? <div className="tab-content">{children}</div> : null;
};

const TabNavItem = ({ id, title, icon, info, children }) => {
	const [activeTab, setActiveTab] = useContext(TabContext);
	const handleClick = () => {
		setActiveTab(id);
	};
	return (
		<li onClick={handleClick} className={activeTab === id ? 'active' : ''}>
			{icon && (
				<span className="tab-icon">
					<img src={icon} />
				</span>
			)}
			{children}
			<span className="tab-title">{title}</span>
			{info && <label className="float-right right-info">{info}</label>}
		</li>
	);
};

const TabNavItems = ({ children }) => <ul className="nav">{children}</ul>;

const TabContents = ({ children }) => <div className="outlet">{children}</div>;

const TabContext = createContext();

const Tabs = ({ type, activeId, children, setAccountType }) => {
	const tabState = useState(activeId);

	useEffect(() => {
		if (tabState[0] === 'live-account-tab') {
			setAccountType && setAccountType('live');
		} else if (tabState[0] === 'demo-account-tab') {
			setAccountType && setAccountType('paper');
		}
	}, [tabState[0]]);

	return (
		<TabContext.Provider value={tabState}>
			<div className={`tab ${type || ''}`}>{children}</div>
		</TabContext.Provider>
	);
};

export { Tabs, TabNavItems, TabContents, TabNavItem, TabContent };
