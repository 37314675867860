import { NavLink } from 'react-router-dom';
import { createSelectedTickerFormat } from '../../utils';
import TickerSelector from '../tickerSelector/TickerSelector';

const TickerActionView = ({ setActiveSymbol, symbolType, symbol }) => {
	return (
		<div className="trade-action">
			<div className="multi-select-item">
				<TickerSelector
					setSelectedValue={setActiveSymbol}
					symbolType={symbolType}
					searchValues={createSelectedTickerFormat(symbol)}
				/>
			</div>
			<div className="trade-link">
				<NavLink to={`/trades?${symbol ? `symbol=${symbol}` : ''}`}>
					<button className="button-sm btn-orange">Trade</button>
				</NavLink>
			</div>
		</div>
	);
};

export default TickerActionView;
