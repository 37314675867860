import { Input, Label } from './ThemeSwitchStyles';
const ThemeSwitch = ({ toggleTheme }) => {
	return (
		<>
			<div className="mobile-menu-text"> Theme</div>
			<Input type="checkbox" id="theme-switch" onChange={toggleTheme} />
			<Label htmlFor="theme-switch">
				<span className="toggle-label-background"> </span>
			</Label>
		</>
	);
};

export default ThemeSwitch;
