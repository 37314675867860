export default {
	AAPL: {
		symbol: 'AAPL',
		icon: '/images/apple-icon.png',
		name: 'Apple Computer',
		description:
			'Apple design a vide variety of consumer electronic devices, includeing smartphones (iPhone), tablets (iPad),PCs (Mac), smartwatches...',
		shares: '$16,31B',
		marketcap: '$262B',
		sic: 'Electronic Computers 3571',
		employees: '#154,000',
		listingDate: '1980-12-12',
		primaryExchange: 'XNAS',
		contact: 'One Apple Park Way Cupertino, CA 95014 USA (408) 996-1010 www.apple.com',
		price: 50
	},
	AA: {
		symbol: 'ARROW',
		icon: '/images/apple-icon.png',
		name: 'ARROW Computer',
		description:
			'Arrow design a vide variety of consumer electronic devices, includeing smartphones (iPhone), tablets (iPad),PCs (Mac), smartwatches...',
		shares: '$16,31B',
		marketcap: '$262B',
		sic: 'Electronic Computers 3571',
		employees: '#154,000',
		listingDate: '1980-12-12',
		primaryExchange: 'XNAS',
		contact: 'One Apple Park Way Cupertino, CA 95014 USA (408) 996-1010 www.apple.com',
		price: 40
	},
	AROW: {
		symbol: 'ARROW',
		icon: '/images/apple-icon.png',
		name: 'ARROW Computer',
		description:
			'Arrow design a vide variety of consumer electronic devices, includeing smartphones (iPhone), tablets (iPad),PCs (Mac), smartwatches...',
		shares: '$16,31B',
		marketcap: '$262B',
		sic: 'Electronic Computers 3571',
		employees: '#154,000',
		listingDate: '1980-12-12',
		primaryExchange: 'XNAS',
		contact: 'One Apple Park Way Cupertino, CA 95014 USA (408) 996-1010 www.apple.com',
		price: 30
	},
	SPY: {
		symbol: 'ARROW',
		icon: '/images/apple-icon.png',
		name: 'ARROW Computer',
		description:
			'Arrow design a vide variety of consumer electronic devices, includeing smartphones (iPhone), tablets (iPad),PCs (Mac), smartwatches...',
		shares: '$16,31B',
		marketcap: '$262B',
		sic: 'Electronic Computers 3571',
		employees: '#154,000',
		listingDate: '1980-12-12',
		primaryExchange: 'XNAS',
		contact: 'One Apple Park Way Cupertino, CA 95014 USA (408) 996-1010 www.apple.com',
		price: 20
	},
	ETHUSD: {
		symbol: 'ETHUSD',
		icon: '',
		name: 'Ethereum USD',
		description: '',
		shares: '$16,31B',
		marketcap: '$262B',
		sic: '',
		employees: '#154,000',
		listingDate: '1980-12-12',
		primaryExchange: 'XNAS',
		contact: '',
		price: 20
	}
};
