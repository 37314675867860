import { DownChevron } from '../../../styles/icons/icons';
import { connectItems } from './navigationItems';
import NavigationLinks from './NavigationLinks';

const Connect = ({ toggleNav, onNavigationClick, toggleNavList }) => (
	<li className={`mobile-view-profile connect ${toggleNav.connect ? 'user-navigation' : ''}`}>
		<a data-toggle="connect" onClick={onNavigationClick}>
			Connect
			<DownChevron />
		</a>
		<div className="user-navigation-menu" onClick={toggleNavList}>
			<ul>
				<NavigationLinks navItems={connectItems} />
			</ul>
		</div>
	</li>
);

export default Connect;
