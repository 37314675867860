import { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import useDataApi from '../../hooks/useDataApi';
import loadChartModules from './loadChartModules';
import * as ChartConfig from './config/ChartConfig';
import useChartFilter from './useChartFilter';
import FinancialChartFilter from './filters/FinancialChartFilter';
import { financialChartData } from './utils';
import './Chart.css';

const View = ({
	isError,
	isLoading,
	data,
	toggleFullScreen,
	chart,
	onLineTypeChange,
	onFinancialMetricsChange,
	financialMetrics,
	chartLineType,
	symbol
}) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					<div className="margin-right-30 text-right fullscreen-icon">
						<a onClick={toggleFullScreen}>
							<img src="/images/icon_fullscreen.png"></img>
						</a>
					</div>
					<div className="chart-type-item ">
						<div>
							<label> Select Financial Metrics- {symbol}: </label>
							<FinancialChartFilter
								type="financialMetrics"
								data={financialMetrics}
								handleChange={onFinancialMetricsChange}
							/>
						</div>
						<div>
							<label> Chart Type: </label>
							<FinancialChartFilter
								type="linetype"
								data={chartLineType}
								handleChange={onLineTypeChange}
							/>
						</div>
					</div>

					<HighchartsReact
						highcharts={Highcharts}
						constructorType={'stockChart'}
						options={ChartConfig.Financial(financialChartData(data).data, symbol)}
						ref={chart}
					/>
				</>
			)}
		</>
	);
};

const FinancialChart = (props) => {
	const { chartLineType, onLineTypeChange, financialMetrics, onFinancialMetricsChange } =
		useChartFilter();
	const url = `/financials/${financialMetrics}`;

	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			symbol: props.symbol
		})
	});

	useEffect(() => {
		setUrl(url);
	}, [url]);

	loadChartModules({
		chartType: 'portfolio',
		...props
	});
	const chart = useRef();
	const toggleFullScreen = () => {
		chart && chart.current.chart.fullscreen.toggle();
	};
	return (
		<View
			{...dataProps}
			toggleFullScreen={toggleFullScreen}
			chart={chart}
			onLineTypeChange={onLineTypeChange}
			onFinancialMetricsChange={onFinancialMetricsChange}
			financialMetrics={financialMetrics}
			chartLineType={chartLineType}
			symbol={props.symbol}
		/>
	);
};

export default FinancialChart;
