import { Fragment } from 'react';
import useDataApi from '../../hooks/useDataApi';
import Styles from './MembershipPageStyles';

const loading = (data, isLoading) => (isLoading ? '' : data);

const MemberSince = ({ memberSince, isLoading }) => (
	<>
		<p>Member Since</p>
		<p>{loading(memberSince, isLoading)}</p>
	</>
);

const MembershipStatus = ({ memberStatus, memberFreq, memberExpire, memberPrice, isLoading }) => (
	<>
		<p>Membership Status</p>
		<p>{`${loading(memberStatus, isLoading)} Plan (${loading(memberFreq, isLoading)})`}</p>
		<a>Upgrade</a>
		<p></p>
		<p>
			Your annual membership will renew on {loading(memberExpire, isLoading)} for $
			{loading(memberPrice, isLoading)}
		</p>
		<a>Cancel</a>
	</>
);

const UpgradePlan = ({ memberStatus, memberPlans }) => {
	const isUpgrade = () => memberPlans.indexOf(memberStatus) < memberPlans.length - 1;
	const nextPlan = () => memberPlans.indexOf(memberStatus) + (1 % memberPlans.length);
	return (
		<>
			{memberStatus && memberPlans && isUpgrade() ? (
				<>
					<p>Upgrade to the {memberPlans[nextPlan()]} Plan</p>
					<a>Learn More</a>
				</>
			) : (
				<>
					<p></p>
					<p></p>
				</>
			)}
		</>
	);
};

const DowngradePlan = ({ memberStatus, memberPlans, memberExpire }) => {
	const isDowngrade = () => memberPlans.indexOf(memberStatus) > 0;
	const nextPlan = () => (memberPlans.indexOf(memberStatus) - 1) % memberPlans.length;
	return (
		<>
			{memberStatus && memberPlans && isDowngrade() ? (
				<>
					<p>{`Downgrade to the ${
						memberPlans[nextPlan()]
					} Plan (effective on ${memberExpire})`}</p>
					<a>Learn More</a>
				</>
			) : (
				<>
					<p></p>
					<p></p>
				</>
			)}
		</>
	);
};

const OtherOptions = ({ memberStatus, memberPlans, memberExpire }) => (
	<>
		<p>Other Options</p>
		<UpgradePlan memberStatus={memberStatus} memberPlans={memberPlans} />
		<p></p>
		<p>Save $12/month and upgrade to the annual plan</p>
		<a>Learn More</a>
		<p></p>
		<DowngradePlan
			memberStatus={memberStatus}
			memberPlans={memberPlans}
			memberExpire={memberExpire}
		/>
	</>
);

const getPrimaryPayment = (paymentMethods) => {
	const [primaryPayment] = paymentMethods.filter((payment) => payment.primary);
	return primaryPayment.payment_method;
};

const PrimaryPayment = ({ paymentMethods, isLoading }) => {
	return (
		<>
			<p>Billing Info</p>
			{isLoading ? '' : paymentMethods && <p>{getPrimaryPayment(paymentMethods)}</p>}
			<a>Update</a>
		</>
	);
};

const PaymentMethods = ({ paymentMethods, isLoading }) => {
	return (
		<>
			{isLoading
				? ''
				: paymentMethods &&
				  paymentMethods.map(({ payment_method, primary }) => {
						return (
							<Fragment key={payment_method}>
								{primary ? (
									<>
										<p>Payment Method</p>
										<p>{payment_method}</p>
										<a>Remove</a>
									</>
								) : (
									<>
										<p></p>
										<p>{payment_method}</p>
										<a>Remove</a>
									</>
								)}
							</Fragment>
						);
				  })}
			<p></p>
			<p></p>
			<a>Add a Payment Method</a>
		</>
	);
};

const Billing = ({ billing, isLoading }) => (
	<>
		<PrimaryPayment paymentMethods={billing} isLoading={isLoading} />
		<div className="billing-info">
			<h1>Billing Info</h1>
			<PaymentMethods paymentMethods={billing} isLoading={isLoading} />
		</div>
	</>
);

const View = ({ isError, isLoading, data }) => {
	const {
		member_since,
		membership_status,
		membership_frequency,
		membership_expire,
		membership_price,
		membership_plans,
		billing_info
	} = data;
	return (
		<Styles>
			<h1>Membership Info</h1>
			{isError && <div>Something went wrong</div>}
			<ul>
				<li>
					<MemberSince memberSince={member_since} isLoading={isLoading} />
				</li>
				<li>
					<MembershipStatus
						memberStatus={membership_status}
						memberFreq={membership_frequency}
						memberPrice={membership_price}
						memberExpire={membership_expire}
						isLoading={isLoading}
					/>
				</li>
				<li>
					<OtherOptions
						memberStatus={membership_status}
						memberPlans={membership_plans}
						memberExpire={membership_expire}
					/>
				</li>
				<li>
					<Billing billing={billing_info} isLoading={isLoading} />
				</li>
			</ul>
		</Styles>
	);
};

const MembershipPage = () => {
	const [membershipProps] = useDataApi('/membership', {}, { method: 'POST' });
	return <View {...membershipProps} />;
};
export default MembershipPage;
