import useDataApi from '../../hooks/useDataApi';
import '../../../static/styles/color-guide.css';
import './AccountMetrics.css';
import { formatAmount } from '../../utils';

const View = ({ data: account, isLoading, isError }) => {
	console.log(account)
	return (
		<div className="account-metrics">
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					<div className="metrics-item">
						<label>Portfolio (Long)</label>
						<div>{formatAmount(account.long_market_value)}</div>
					</div>
					<div className="metrics-item">
						<label>Portfolio (Short)</label>
						<div>{formatAmount(account.short_market_value)}</div>
					</div>
					<div className="metrics-item">
						<label>Buying Power</label>
						<div>{formatAmount(account.buying_power)}</div>
					</div>
					<div className="metrics-item">
						<label>Day trading Buying Power</label>
						<div>{formatAmount(account.daytrading_buying_power)}</div>
					</div>
				</>
			)}
		</div>
	);
};

const AccountMetrics = (props) => {
	const [dataProps] = useDataApi(`/alpaca_account`, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ account_type: props.accountType })
	});
	return <View {...dataProps} />;
};

export default AccountMetrics;
