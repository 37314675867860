import { LiveAccountIcon } from '../../../styles/icons/icons';
import AccountDetails from '../../accountDetails/AccountDetails';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './AccountDetailDialog.css';

const View = ({ accountType, isOpen, handleStateChange }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Account Details"
			handleStateChange={handleStateChange}>
			<header className="modal-header">
				<h1 className="modal-header-text">
					<LiveAccountIcon /> Account Details
				</h1>
				<span className="modal-close" onClick={() => handleStateChange(false)}>
					&times;
				</span>
			</header>
			<ModalBody>
				<AccountDetails accountType={accountType} />
			</ModalBody>
			<ModalFooter>
				<button
					className="button-sm btn-orange width-200"
					onClick={() => handleStateChange(false)}>
					Ok
				</button>
			</ModalFooter>
		</ModalDialog>
	);
};

const AccountDetailDialog = (props) => {
	return <View {...props} />;
};

export default AccountDetailDialog;
