import { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import useDataApi from '../../hooks/useDataApi';
import loadChartModules from './loadChartModules';
import * as ChartConfig from './config/ChartConfig';
import { portfolioChartData } from './utils';
import './Chart.css';

const View = ({
	isError,
	isLoading,
	data,
	toggleFullScreen,
	rangeSelectorReciever,
	rangeSelectorSelected,
	chart
}) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					<div className="margin-right-30 text-right fullscreen-icon">
						<a onClick={toggleFullScreen}>
							<img src="/images/icon_fullscreen.png"></img>
						</a>
					</div>
					<HighchartsReact
						highcharts={Highcharts}
						constructorType={'stockChart'}
						options={ChartConfig.Portfolio(
							portfolioChartData(data).data,
							'Account Balance',
							rangeSelectorReciever,
							rangeSelectorSelected
						)}
						ref={chart}
					/>
				</>
			)}
		</>
	);
};

const PortfolioChart = (props) => {
	const [displayType, setDisplayType] = useState('intraday');
	const [chartParam, setChartParam] = useState(1);
	const url = `/account_balances?display_type=${displayType}`;
	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			account_type: props.accountType,
			display_type: displayType
		})
	});

	const rangeSelectorReciever = (selectedRange, selectedIndex) => {
		const displayType = ['1D', '7D'].includes(selectedRange) ? 'intraday' : 'interday';
		console.log(selectedRange)
		console.log(selectedIndex)
		console.log(displayType)
		setChartParam(selectedIndex);
		setDisplayType(displayType);
	};

	useEffect(() => {
		setUrl(url);
	}, [displayType]);

	loadChartModules({
		chartType: 'portfolio',
		...props
	});
	const chart = useRef();
	const toggleFullScreen = () => {
		chart && chart.current.chart.fullscreen.toggle();
	};
	return (
		<View
			{...dataProps}
			rangeSelectorReciever={rangeSelectorReciever}
			rangeSelectorSelected={chartParam}
			toggleFullScreen={toggleFullScreen}
			chart={chart}
		/>
	);
};

export default PortfolioChart;
