import styled from 'styled-components';

const Styles = styled.article`
	display: flex;
	height: 99vh;
	.sign-left-side {
		flex: 1;
	}
	.sign-right-side {
		flex: 1;
	}
	.page-count {
		color: var(--font-white);
	}
	.sign-text {
		color: var(--bg-white);
		font-weight: 500;
		line-height: 24px;
		font-size: var(--font-size-m);
	}
	@media screen and (min-width: 10px) and (max-width: 730px) {
		display: block;
	}
`;

export default Styles;
