import { rest } from 'msw';
import portfolioData from '../fixtures/portfolioData';
import marketindicatorsData from '../fixtures/marketIndicatorsData';
import algoStrategiesData from '../fixtures/algoStrategiesData';
import {
	pophoverChartData,
	singleLineChartData,
	algoEditorChartData,
	metricsChartDetailData,
	accountBalanceData,
	mericsChartData,
	linearChartData,
	newsSentimentChartData,
	oneSecChartData
} from '../fixtures/ChartJson';
import newsHeadLines from '../fixtures/HeadlinesJson';
import newsSentimentsData from '../fixtures/newsSentimentsData';
import topStocksData from '../fixtures/TopStocksData';
import watchlistData from '../fixtures/watchlistData';
import alpaca_Account from '../fixtures/accountMetrics';
import stockInfoData from '../fixtures/stockInfoData';
import ticklerDetailData from '../fixtures/ticklerDetailData';
import stocksData from '../fixtures/stocksData';
import otherReportData from '../fixtures/otherReportJson';
import yourHoldingsData from '../fixtures/yourHoldingsData';
import editorPicksData from '../fixtures/editorPicksData';
import newsDialogData from '../fixtures/newsDialogData';
import algoEditorData from '../fixtures/algoEditorData';
import signinData from '../fixtures/signinData';
import markets from '../fixtures/markets';
import searchListData from '../fixtures/searchListData';
import { price } from '../fixtures/currentPriceData';
import alpaca_Orders_open from '../fixtures/openOrdersData';
import alpaca_Orders_closed from '../fixtures/closedOrdersData';
import ticklerNewsData from '../fixtures/ticklerNewsData';
import assetsData from '../fixtures/assetsData';
import { executeOrderResponse } from '../fixtures/executeOrderResponseData';
import profilePageFields from '../fixtures/profilePageFields';
import tickerMetricsData from '../fixtures/tickerMetricsData';
import { stockData, cryptoData } from '../fixtures/symbolData';
import fredReportList from '../fixtures/fredReportListData';
import closeOrderResponse from '../fixtures/closeOrderResponse';
import financeHandler from './financeHandlers';

export const handlers = [
	rest.post('/alpaca_portfolio', (req, res, ctx) => {
		return res(ctx.json(portfolioData));
	}),
	rest.post('/watchlist', (req, res, ctx) => {
		return res(ctx.json(watchlistData));
	}),
	rest.post('/markets', (req, res, ctx) => {
		return res(ctx.json(marketindicatorsData));
	}),
	rest.get('/markets/:market', (req, res, ctx) => {
		let symbol = req.url.searchParams.get('symbol');
		if (symbol) {
			return res(
				ctx.json(markets[req.params.market].filter((data) => data.asset.symbol === symbol))
			);
		} else {
			return res(ctx.json(markets[req.params.market]));
		}
	}),
	rest.get('/algo-strategies', (req, res, ctx) => {
		return res(ctx.json(algoStrategiesData));
	}),
	rest.get('/single-line', (req, res, ctx) => {
		return res(ctx.json(singleLineChartData));
	}),
	rest.post('/news/headline', (req, res, ctx) => {
		const start = req.body.start || 0;
		return res(ctx.json(newsHeadLines.slice(start, 10 + start)));
	}),
	rest.post('/news/hourly_sentiment', (req, res, ctx) => {
		return res(ctx.json(newsSentimentsData));
	}),
	rest.post('/news/headline/ticker', (req, res, ctx) => {
		const start = req.body.start || 0;
		const ticker = req.body.ticker;
		return res(ctx.json(ticker ? ticklerNewsData.slice(start, 10 + start) : []));
	}),
	rest.post('/topstocks', (req, res, ctx) => {
		return res(ctx.json(topStocksData));
	}),
	rest.post('/alpaca_account', (req, res, ctx) => {
		const { account_type } = req.body;
		return account_type === 'live'
			? res(ctx.json(alpaca_Account[account_type]))
			: account_type === 'paper'
				? res(ctx.json(alpaca_Account[account_type]))
				: {};
	}),
	rest.get('/pophover-chart-detail', (req, res, ctx) => {
		return res(ctx.json(pophoverChartData));
	}),
	rest.get('/account-balance-chart', (req, res, ctx) => {
		return res(ctx.json(singleLineChartData));
	}),
	rest.get('/stocks', (req, res, ctx) => {
		return res(ctx.json(stocksData));
	}),
	rest.get('/stocks/:symbol', (req, res, ctx) => {
		return res(ctx.json(stockInfoData[req.params.symbol]));
	}),
	rest.get('/stocks/:symbol/info', (req, res, ctx) => {
		return res(ctx.json(stockInfoData[req.params.symbol]));
	}),
	rest.post('/execute_alpaca_order', (req, res, ctx) => {
		const { order_type, side, symbol } = req.body;
		return symbol === 'SPY'
			? res(ctx.json(executeOrderResponse[symbol][side][order_type])) :
			symbol === 'AAPL'
				? res(ctx.status(403), ctx.json({ "code": 40310000, "message": "cost basis must be >= minimal amount of order 1" }))
				: res(ctx.status(403), ctx.json({ isBlocked: true }));
	}),
	rest.get('/other-report', (req, res, ctx) => {
		return res(ctx.json(otherReportData));
	}),
	rest.get('/metrics-chart/:type', (req, res, ctx) => {
		return res(ctx.json(singleLineChartData));
	}),
	rest.get('/metrics-chart/:type/detail', (req, res, ctx) => {
		return res(ctx.json(metricsChartDetailData));
	}),
	rest.get('/your-holdings', (req, res, ctx) => {
		return res(ctx.json(yourHoldingsData));
	}),
	rest.get('/editor-picks', (req, res, ctx) => {
		return res(ctx.json(editorPicksData));
	}),
	rest.get('/news-detail/:id', (req, res, ctx) => {
		return res(ctx.json(newsDialogData));
	}),
	rest.get('/algo-editor-chart/:id', (req, res, ctx) => {
		return res(ctx.json(algoEditorChartData));
	}),
	rest.get('/algoeditor/:id', (req, res, ctx) => {
		return res(ctx.json(algoEditorData));
	}),
	rest.post('/auth/token', (req, res, ctx) => {
		if (
			req.body.includes(`username=${signinData['request'].username}`) &&
			req.body.includes(`password=${signinData['request'].password}`)
		) {
			return res(ctx.json(signinData['response']));
		} else {
			return res(ctx.status(403), ctx.json(signinData['errorResponse']));
		}
	}),
	rest.get('/searchlist/:type', (req, res, ctx) => {
		return res(ctx.json(searchListData[req.params.type] || []));
	}),
	rest.post('/alpaca_orders', (req, res, ctx) => {
		const { account_type, order_type } = req.body;
		return order_type === 'closed'
			? res(ctx.json(alpaca_Orders_closed[account_type]))
			: res(ctx.json(alpaca_Orders_open[account_type]));
	}),
	rest.post('/ticker_detail', (req, res, ctx) => {
		if (req.body.symbol) {
			return res(ctx.json(ticklerDetailData[req.body.symbol]));
		} else {
			return res(ctx.json({}));
		}
	}),
	rest.post('/account_balances', (req, res, ctx) => {
		const { display_type } = req.body;
		return res(ctx.json(accountBalanceData[display_type]));
	}),
	rest.post('/algos', (req, res, ctx) => {
		return res(ctx.json(algoStrategiesData));
	}),
	rest.post('/fred_report', (req, res, ctx) => {
		return res(ctx.json(mericsChartData));
	}),
	rest.post('/fred_report_list', (req, res, ctx) => {
		return res(ctx.json(fredReportList));
	}),
	rest.post('/timeseries', (req, res, ctx) => {
		const { symbol_type } = req.body;
		return res(ctx.json(['crypto', 'stocks'].includes(symbol_type) ? linearChartData : {}));
	}),
	rest.post('/last_price', (req, res, ctx) => {
		console.log("BEN LAST PRICE:")
		console.log(price)
		return res(ctx.json(price));
	}),
	rest.post('/news/sentiment', (req, res, ctx) => {
		return res(ctx.json(newsSentimentChartData));
	}),
	rest.post('/alpaca_asset', (req, res, ctx) => {
		const { symbol } = req.body;
		return res(ctx.json(assetsData[symbol]));
	}),
	rest.post('/oneseccharts', (req, res, ctx) => {
		return res(ctx.json(oneSecChartData));
	}),
	rest.post('/profile', (req, res, ctx) => {
		return res(
			ctx.json({
				login_name: 'BrianUser',
				FIRSTNAME: 'Brian',
				LASTNAME: 'Smith',
				PASSWORD: 'super secret',
				ADDRESS: '14 Pine Avenu',
				CITY: 'Carlsbad',
				COUNTRY: 'United States of America',
				POSTALCODE: '92008',
				STATE: 'CA',
				ALPACA_STATUS: 'Connected',
				LIVE_ACCTID: 'K658-5896-JL98562-429D3664',
				LIVE_SECRETKEY: 'K658-5896-JL98562-77777777',
				PAPER_ACCTID: 'K658-5896-JL98562-429D3775',
				PAPER_SECRETKEY: 'K658-5896-JL98562-88888888'
			})
		);
	}),
	...profilePageFields.map(({ url }) =>
		rest.post(url, (req, res, ctx) => res(ctx.json({ success: true })))
	),
	rest.post('/change-password', (req, res, ctx) => {
		return res(ctx.json({ success: true, message: 'Password changed!' }));
	}),
	rest.post('/membership', (req, res, ctx) => {
		return res(
			ctx.json({
				member_since: 'January 18, 2022',
				membership_status: 'pro',
				membership_frequency: 'monthly',
				membership_expire: 'August 28, 2022',
				membership_price: '59.00',
				membership_plans: ['free', 'pro', 'premium'],
				billing_info: [
					{ payment_method: 'Paypal', primary: true },
					{ payment_method: 'Visa ending 7956', primary: false }
				]
			})
		);
	}),
	rest.post('/market_tickers', (req, res, ctx) => {
		return res(ctx.json(tickerMetricsData));
	}),
	rest.post('/fred_report_list/ticker', (req, res, ctx) => {
		const { ticker } = req.body;
		return res(ctx.json(fredReportList.filter((item) => item.id === ticker)));
	}),
	rest.post('/account', (req, res, ctx) => {
		return res(ctx.json({}));
	}),
	rest.post('/select_symbol', (req, res, ctx) => {
		const { symbol } = req.body;
		if (symbol && symbol.includes('X:')) {
			return res(
				ctx.json(
					cryptoData.filter((item) =>
						item.symbol.toLowerCase().includes(symbol.toLowerCase())
					)
				)
			);
		} else if (symbol) {
			return res(
				ctx.json(
					stockData.filter((item) =>
						item.symbol.toLowerCase().includes(symbol.toLowerCase())
					)
				)
			);
		}
	}),
	rest.post('/close_all_orders', (req, res, ctx) => {
		const { account_type } = req.body;
		return res(ctx.json(closeOrderResponse[account_type]));
	}),
	...financeHandler
];
