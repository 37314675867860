export const executeOrderResponse = {
	AAPL: {
		buy: {
			market: {
				id: 'a073a9ea-3b81-417a-84bb-949cef1c218a',
				client_order_id: '9b954929-ad44-4cc1-a720-506eca874401',
				created_at: '2022-07-10T07:18:28.737024881Z',
				updated_at: '2022-07-10T07:18:28.737092401Z',
				submitted_at: '2022-07-10T07:18:28.734606791Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'market',
				type: 'market',
				side: 'buy',
				time_in_force: 'day',
				limit_price: null,
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '3.5601',
				subtag: null,
				source: null
			},
			limit: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'limit',
				type: 'limit',
				side: 'sell',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			stop: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'stop',
				type: 'stop',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			stop_limit: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'stop_limit',
				type: 'stop_limit',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			trailing_stop: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'trailing_stop',
				type: 'trailing_stop',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: '123.12',
				trail_price: '123.12',
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			}
		},
		sell: {
			market: {
				id: 'a073a9ea-3b81-417a-84bb-949cef1c218a',
				client_order_id: '9b954929-ad44-4cc1-a720-506eca874401',
				created_at: '2022-07-10T07:18:28.737024881Z',
				updated_at: '2022-07-10T07:18:28.737092401Z',
				submitted_at: '2022-07-10T07:18:28.734606791Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'AAPL',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'market',
				type: 'market',
				side: 'sell',
				time_in_force: 'day',
				limit_price: null,
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '3.5601',
				subtag: null,
				source: null
			}
		}
	},
	SPY: {
		buy: {
			market: {
				id: 'a073a9ea-3b81-417a-84bb-949cef1c218a',
				client_order_id: '9b954929-ad44-4cc1-a720-506eca874401',
				created_at: '2022-07-10T07:18:28.737024881Z',
				updated_at: '2022-07-10T07:18:28.737092401Z',
				submitted_at: '2022-07-10T07:18:28.734606791Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'market',
				type: 'market',
				side: 'buy',
				time_in_force: 'day',
				limit_price: null,
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '3.5601',
				subtag: null,
				source: null
			},
			limit: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'limit',
				type: 'limit',
				side: 'sell',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			stop: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'stop',
				type: 'stop',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			stop_limit: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'stop_limit',
				type: 'stop_limit',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			},
			trailing_stop: {
				id: '4b3db450-7c87-4f65-af55-38a9d641f5e8',
				client_order_id: 'ef602a9a-be32-4f8b-be6f-d8569f1495d6',
				created_at: '2022-07-10T10:31:23.705989694Z',
				updated_at: '2022-07-10T10:31:23.706065684Z',
				submitted_at: '2022-07-10T10:31:23.705445944Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'trailing_stop',
				type: 'trailing_stop',
				side: 'buy',
				time_in_force: 'day',
				limit_price: '141.1',
				stop_price: '141.2',
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: '123.12',
				trail_price: '123.12',
				hwm: null,
				commission: '0.4233',
				subtag: null,
				source: null
			}
		},
		sell: {
			market: {
				id: 'a073a9ea-3b81-417a-84bb-949cef1c218a',
				client_order_id: '9b954929-ad44-4cc1-a720-506eca874401',
				created_at: '2022-07-10T07:18:28.737024881Z',
				updated_at: '2022-07-10T07:18:28.737092401Z',
				submitted_at: '2022-07-10T07:18:28.734606791Z',
				filled_at: null,
				expired_at: null,
				canceled_at: null,
				failed_at: null,
				replaced_at: null,
				replaced_by: null,
				replaces: null,
				asset_id: '35f33a69-f5d6-4dc9-b158-4485e5e92e4b',
				symbol: 'SPY',
				asset_class: 'crypto',
				notional: null,
				qty: '1',
				filled_qty: '0',
				filled_avg_price: null,
				order_class: '',
				order_type: 'market',
				type: 'market',
				side: 'sell',
				time_in_force: 'day',
				limit_price: null,
				stop_price: null,
				status: 'pending_new',
				extended_hours: false,
				legs: null,
				trail_percent: null,
				trail_price: null,
				hwm: null,
				commission: '3.5601',
				subtag: null,
				source: null
			}
		}
	}
};
