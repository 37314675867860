const WidgetBox = ({ header, icon, children }) => {
	return (
		<div className="card">
			{icon && (
				<div className="card-header">
					<h3 className="card-title">
						<img src={icon} /> {header}
					</h3>
				</div>
			)}
			{children}
		</div>
	);
};

export default WidgetBox;
