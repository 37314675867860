export default (data, symbol) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)',
			height: 50,
			width: 100
		},
		series: [
			{
				name: symbol,
				data,
				color: '#FF6B01',
				showInLegend: false,
				tooltip: {
					enabled: false
				},
				enableMouseTracking: false
			}
		],
		legend: {
			enabled: false
		},
		navigator: {
			enabled: false
		},
		navigation: {
			buttonOptions: {
				enabled: false
			}
		},
		rangeSelector: {
			enabled: false
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			visible: false
		},
		yAxis: {
			visible: false
		},
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};
