const stockData = [
	{
		symbol: 'A',
		name: 'Agilent Technologies Inc.'
	},
	{
		symbol: 'AA',
		name: 'Alcoa Corporation'
	},
	{
		symbol: 'AAA',
		name: 'AAF First Priority CLO Bond ETF'
	},
	{
		symbol: 'AAAU',
		name: 'Goldman Sachs Physical Gold ETF Shares'
	},
	{
		symbol: 'AAC',
		name: 'Ares Acquisition Corporation'
	},
	{
		symbol: 'AAC.U',
		name: 'Ares Acquisition Corporation Units, each consisting of one Class A ordinary share, and one-fifth of'
	},
	{
		symbol: 'AACG',
		name: 'ATA Creativity Global American Depositary Shares'
	},
	{
		symbol: 'AACI',
		name: 'Armada Acquisition Corp. I Common Stock'
	},
	{
		symbol: 'AACIU',
		name: 'Armada Acquisition Corp. I Unit'
	},
	{
		symbol: 'AADI',
		name: 'Aadi Bioscience, Inc. Common Stock'
	},
	{
		symbol: 'AAPL',
		name: 'Apple Inc. Common Stock'
	},
	{
		symbol: 'SPY',
		name: 'SPDR S&P 500 ETF Trust'
	},
	{
		symbol: 'TSLA',
		name: 'Tesla Inc'
	},
	{
		symbol: 'BTC',
		name: 'Bitcoin'
	},
	{
		symbol: 'TQQQ',
		name: 'ProShares UltraPro QQQ'
	},
	{
		symbol: 'BAC',
		name: 'Bank of America Corp'
	},
	{
		symbol: 'AROW',
		name: 'ARROW Computer'
	}
];

const cryptoData = [
	{
		symbol: 'X:ETHUSD',
		name: 'Ethereum'
	},
	{
		symbol: 'X:AVAXUSD',
		name: 'Avalanche'
	}
];

export { stockData, cryptoData };
