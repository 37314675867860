import * as ChartConfig from './ChartConfig';
import {
	chartData,
	customChartData,
	financialChartData,
	linearChartData,
	metricsChartData,
	multipleSeriesChartData,
	newsSenimentsChartData,
	oneSecChartData,
	portfolioChartData,
	techIndicatorsChartData
} from '../utils';

const getConfig = (payload, chartType, tickInterval, chartLineType, rangeSelectorListener) => {
	let { data, symbol } =
		chartType === 'algoEditor'
			? multipleSeriesChartData(payload)
			: chartType === 'portfolio' || chartType === 'accountBalance'
			? portfolioChartData(payload)
			: chartType === 'fredReport' || chartType === 'metricsDetail'
			? metricsChartData(payload)
			: chartType === 'trade'
			? tickInterval === 'sec'
				? oneSecChartData(payload)
				: (tickInterval === 'min' || tickInterval === 'day') &&
				  chartLineType === 'indicator'
				? techIndicatorsChartData(payload)
				: (tickInterval === 'min' || tickInterval === 'day') && chartLineType === 'custom'
				? customChartData(payload)
				: tickInterval === 'min' || tickInterval === 'day'
				? linearChartData(payload)
				: []
			: chartType === 'newsSentiment'
			? newsSenimentsChartData(payload)
			: chartType === 'linear' || chartType === 'pophover' || chartType === 'metrics'
			? linearChartData(payload)
			: chartType === 'financial'
			? financialChartData(payload)
			: chartData(payload);

	return chartType === 'portfolio'
		? ChartConfig.Portfolio(data, symbol, rangeSelectorListener)
		: chartType === 'pophover'
		? ChartConfig.Pophover(data, symbol)
		: chartType === 'trade'
		? tickInterval === 'sec'
			? ChartConfig.singleLineChartConfig(data, symbol, tickInterval)
			: (tickInterval === 'min' || tickInterval === 'day') && chartLineType === 'indicator'
			? ChartConfig.technicalIndicatorChartConfig(data, symbol, tickInterval)
			: (tickInterval === 'min' || tickInterval === 'day') && chartLineType === 'custom'
			? ChartConfig.customChartConfig(data, symbol, tickInterval)
			: tickInterval === 'min' || tickInterval === 'day'
			? ChartConfig.singleLineChartConfig(data, symbol, tickInterval)
			: ChartConfig.singleLineChartConfig(data, symbol, tickInterval)
		: chartType === 'accountBalance'
		? ChartConfig.AccountBalance(data, symbol, rangeSelectorListener)
		: chartType === 'metrics'
		? ChartConfig.Metrics(data, symbol, rangeSelectorListener)
		: chartType === 'fredReport'
		? ChartConfig.FredReport(data, symbol)
		: chartType === 'newsSentiment'
		? ChartConfig.NewsSentiment(data, symbol)
		: chartType === 'algoEditor'
		? ChartConfig.algoEditor(data)
		: chartType === 'metricsDetail'
		? ChartConfig.MetricsDetail(data, symbol)
		: chartType === 'linear'
		? ChartConfig.Linear(data, symbol)
		: chartType === 'financial'
		? ChartConfig.Financial(data, symbol)
		: null;
};

export default getConfig;
