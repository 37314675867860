export default {
	request: {
		username: 'test',
		password: 'test'
	},
	response: {
		access_token:
			'LBwvRIg/a4YjnwZfo2oA201/kDLnFcGRBhuv9h4VXsjFsZT+hOGxTXLDBn28q0l4r5QOhmsKoPJZP745Yv4pEmBjAp5p+I9+mCpyhn1sGhxzjw4gK0cRhgtOvQmMSjGeoQYw5P+ZKECGyO8kiGGufCMEb29bmy5QK+6LhJhmG90=',
		expires_in: 604800,
		token_type: 'Bearer'
	},
	errorResponse: {
		error: 'invalid_grant',
		error_description: 'Invalid resource owner credentials!'
	}
};
