import React, { useState } from 'react';
import './Pophover.css';

const Pophover = (props) => {
	const [visible, setVisible] = useState(props.visible);

	return (
		<div
			className={`pophover-wrapper ${props.type || ''}`}
			onMouseEnter={() => setVisible(true)}
			onMouseLeave={() => setVisible(false)}>
			{props.children[0]}
			{visible && <>{props.children[1]}</>}
		</div>
	);
};

const PophoverElement = ({ children }) => {
	return <>{children}</>;
};

const PophoverContent = (props) => {
	return <div className={`pophover ${props.direction || 'top'}`}>{props.children}</div>;
};

export { Pophover, PophoverElement, PophoverContent };
