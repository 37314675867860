import { useEffect, useState } from 'react';
import useDataApi from '../../../hooks/useDataApi';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './ConfirmDialog.css';

const View = ({ isOpen, handleStateChange, handleClick, handleClose, data }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Confirmation"
			headerIcon="/images/trade-ref-icon.png"
			headerClass="doted-border"
			modalSize="small"
			handleStateChange={() => handleClose(data)}>
			<ModalBody>
				{Array.isArray(data) ? (
					<div className="confirm-modal-content">
						Close all orders successfully executed!
					</div>
				) : (
					<div className="confirm-modal-content">Do you want to close all orders?</div>
				)}
			</ModalBody>
			<ModalFooter>
				{!Array.isArray(data) && (
					<button
						className="button-sm btn-orange width-200 margin-10"
						onClick={() => handleStateChange(false)}>
						Cancel
					</button>
				)}
				<button
					className="button-sm btn-orange width-200 margin-10"
					onClick={() => handleClick(data)}>
					Ok
				</button>
			</ModalFooter>
		</ModalDialog>
	);
};

const ConfirmDialog = (props) => {
	const [isConfirm, setIsConfirm] = useState(false);
	const url = '/close_all_orders';

	const [dataProps, setUrl] = useDataApi(
		'',
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ account_type: props.accountType })
		}
	);

	useEffect(() => {
		setUrl(isConfirm ? url : '');
	}, [isConfirm]);

	const closeDialog = () => {
		props.handleStateChange(false);
		props.refreshPage();
	};

	const handleClose = (data) => {
		Array.isArray(data) ? closeDialog() : props.handleStateChange(false);
	};

	const handleClick = (data) => {
		Array.isArray(data) ? closeDialog() : setIsConfirm(true);
	};

	return <View handleClick={handleClick} handleClose={handleClose} {...dataProps} {...props} />;
};

export default ConfirmDialog;
