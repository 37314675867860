const singleLineChartConfig = (data, symbol, tickInterval) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)'
		},
		series: getSeriesData(data, symbol, tickInterval),
		legend: {
			enabled: true
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			selected: tickInterval === 'day' ? 0 : 4,
			verticalAlign: 'bottom',
			x: 0,
			y: 0,
			buttons: getRangeSelectorOptions(tickInterval)
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 1,
			//tickInterval: tickInterval === 'sec' ? 200 : 1,
			tickPixelInterval: 50
		},
		yAxis: {
			gridLineWidth: 1,
			gridLineColor: '#FF6B01'
		},
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};

const technicalIndicatorChartConfig = (data, symbol, tickInterval) => {
	return {
		action: 'draw-chart',
		yAxis: [
			{
				height: '60%',
				gridLineWidth: 1,
				gridLineColor: '#FF6B01'
			},
			{
				top: '60%',
				height: '20%',
				gridLineWidth: 1,
				gridLineColor: '#FF6B01'
			},
			{
				top: '80%',
				height: '20%',
				gridLineWidth: 1,
				gridLineColor: '#FF6B01'
			}
		],
		series: [
			{
				type: 'candlestick',
				id: 'symbol',
				name: symbol,
				data: data.ohlc
			},
			{
				type: 'column',
				id: 'volume',
				name: 'Volume',
				data: data.volume,
				yAxis: 1
			},
			{
				type: 'pc',
				id: 'overlay',
				linkedTo: 'aapl',
				yAxis: 0
			},
			{
				type: 'macd',
				id: 'oscillator',
				linkedTo: 'aapl',
				yAxis: 2
			}
		],
		legend: {
			enabled: true
		},
		rangeSelector: {
			selected: tickInterval === 'day' ? 0 : 4,
			verticalAlign: 'bottom',
			x: 0,
			y: 0,
			buttons: getRangeSelectorOptions(tickInterval)
		},
		xAxis: {
			gridLineWidth: 1,
			//tickInterval: 1
			tickPixelInterval: 50
		},
		navigator: {
			enabled: false
		}
	};
};

const customChartConfig = (data, symbol, tickInterval) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: '#f9f9f9'
		},
		series: [
			{
				name: symbol,
				type: 'candlestick',
				data: data,
				tooltip: {
					valueDecimals: 2
				}
			}
		],
		legend: {
			enabled: true
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			selected: tickInterval === 'day' ? 0 : 4,
			verticalAlign: 'bottom',
			x: 0,
			y: 0,
			buttons: getRangeSelectorOptions(tickInterval)
		},
		scrollbar: {
			enabled: false
		},
		xAxis: {
			gridLineWidth: 1,
			//tickInterval: 1
			tickPixelInterval: 50
		},
		yAxis: {
			gridLineWidth: 1,
			gridLineColor: '#FF6B01'
		},
		stockTools: {
			gui: {
				enabled: false
			}
		}
	};
};

const getRangeSelectorOptions = (tickInterval) => {
	if (tickInterval === 'sec') {
		return [
			{
				type: 'minute',
				count: 1,
				text: '1M'
			},
			{
				type: 'hour',
				count: 1,
				text: '1H'
			},
			{
				type: 'hour',
				count: 3,
				text: '3H'
			}
		];
	} else if (tickInterval === 'min') {
		return [
			{
				type: 'hour',
				count: 1,
				text: '1H'
			},
			{
				type: 'hour',
				count: 3,
				text: '3H'
			},
			{
				type: 'hour',
				count: 24,
				text: '24H'
			},
			{
				type: 'day',
				count: 7,
				text: '7D'
			}
		];
	} else if (tickInterval === 'day') {
		return [
			{
				type: 'month',
				count: 1,
				text: '1M'
			},
			{
				type: 'month',
				count: 6,
				text: '6M'
			},
			{
				type: 'year',
				count: 1,
				text: '1Y'
			},
			{
				type: 'year',
				count: 5,
				text: '5Y'
			},
			{
				type: 'all',
				text: 'All'
			}
		];
	}
};

const getSeriesData = (data, symbol, tickInterval) => {
	const seriesObj = {
		name: symbol,
		data,
		color: '#FF6B01'
	};
	if (tickInterval === 'sec') {
		seriesObj['pointStart'] = data?.length && data[0][0].getTime();
	}
	return [seriesObj];
};

export { singleLineChartConfig, technicalIndicatorChartConfig, customChartConfig };
