import { formatAmount } from '../../../utils';

const AmountWithDelta = ({ value }) => {
	return (
		<div
			className={`multi-line-cell amount-n-delta ${
				Number(value.amount) >= 0 ? 'green-value' : 'red-value'
			}`}>
			<span>{formatAmount(value.amount)}</span>
			<span>
				{(Number(value.amount) > 0 ? '+' : '') + Number(value.delta * 100).toFixed(2)}%
			</span>
		</div>
	);
};

export default AmountWithDelta;
