export default [
	{
		algo_name: 'Create Crypto Portfolio',
		status: 'running',
		id: 1668531792908,
		algo_type: 'interday',
		portfolio_size: 84413.18,
		gain_today: -3258.89,
		pctgain_today: -3.86,
		gain_alltime: 2010.32,
		pctgain_alltime: 2.38,
		pctbench_today: -1.99,
		pctbench_alltime: -2.99,
		info_ratio: 3.36
	},
	{
		algo_name: 'Create Portfolio2',
		id: 1668531792909,
		algo_type: 'interday',
		status: 'draft',
		portfolio_size: 84413.18,
		gain_today: -3258.89,
		pctgain_today: -3.86,
		gain_alltime: 2010.32,
		pctgain_alltime: 2.38,
		pctbench_today: -1.99,
		pctbench_alltime: -2.99,
		info_ratio: -1.1130248306577801
	},
	{
		algo_name: 'Create Portfolio3',
		status: 'draft',
		id: 1668531792910,
		algo_type: 'interday',
		portfolio_size: 84413.18,
		gain_today: -3258.89,
		pctgain_today: -3.86,
		gain_alltime: 2010.32,
		pctgain_alltime: 2.38,
		pctbench_today: -1.99,
		pctbench_alltime: -2.99,
		info_ratio: 0.6269904458598822
	}
];
