import Styles from './SignInLeftPanelStyles';

const SignInLeftPanel = ({ children }) => (
	<Styles>
		<div className="company-logo">
			<a href="/">
				<img src="/images/sign-logo.png" alt="logo" />
				<span> MachineTrader</span>
			</a>
		</div>
		{children}
		<div className="sign-page-img"></div>
	</Styles>
);

export default SignInLeftPanel;
