import { useParams } from 'react-router-dom';
import AlgoEditorForm from '../../components/algoEditor/AlgoEditorForm';
import useDataApi from '../../hooks/useDataApi';
import './AlgoEditorPage.css';

const AlgoEditorPage = () => {
	const { id } = useParams();
	const [editorProps] = useDataApi(`/algoeditor/${id}`, []);
	const editorData = editorProps.data;

	const [symbolsProps] = useDataApi(`/stocks`, []);
	const symbolsData = symbolsProps.data;

	return (
		<div className="wrapper-container">
			<div className="card">
				{editorData && Object.keys(editorData).length && (
					<AlgoEditorForm algoId={id} editorData={editorData} symbolsData={symbolsData} />
				)}
			</div>
		</div>
	);
};

export default AlgoEditorPage;
