import { useState, createRef } from 'react';
import { useForm } from 'react-hook-form';
import useDataApi from '../../../hooks/useDataApi';
import Countries from '../countries/Countries';
import Form from './SingleInputStyles';

const View = ({
	label,
	id,
	type,
	register,
	required,
	handleSubmit,
	onSubmit,
	errors,
	refForm,
	triggerSubmit,
	isError,
	onSelectCountry,
	selectedCountry
}) => {
	return (
		<Form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
			<div className="inputs">
				{type !== 'country' ? (
					<>
						<label htmlFor={id}>{label}</label>
						<input
							name={id}
							id={id}
							{...register(id, { required: required })}
							onBlur={triggerSubmit}
							type={type}
						/>
					</>
				) : (
					<>
						<label id={id}>{label}</label>
						<Countries onSelect={onSelectCountry} selectedElement={selectedCountry} />
					</>
				)}
				{errors[id] && <span>This field is required</span>}
				{isError && <span>Something went wrong</span>}
			</div>
		</Form>
	);
};

const sendRequest = (data, setBody, url, setUrl) => {
	const timeStamp = new Date().getTime();
	const body = new URLSearchParams(data);
	setBody(body);
	setUrl(`${url}?hash=${timeStamp}`);
};

const SingleInput = ({ label, id, url, type, defaultValues, required }) => {
	const [body, setBody] = useState('');
	const [selectedCountry, setSelectedCountry] = useState([{ name: defaultValues?.country }]);
	const refForm = createRef();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors }
	} = useForm({ defaultValues: defaultValues });

	const onSelectCountry = (country) => {
		const countryName = country[0].name;
		setValue('country', countryName);
		setSelectedCountry(country);
		onSubmit({ country: countryName });
	};

	const triggerSubmit = () =>
		refForm.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

	const onSubmit = (data) => {
		sendRequest(data, setBody, url, setUrl);
	};

	const [submitProps, setUrl] = useDataApi(
		null,
		{},
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
			body
		}
	);
	return (
		<View
			label={label}
			id={id}
			required={required}
			type={type}
			handleSubmit={handleSubmit}
			register={register}
			onSubmit={onSubmit}
			errors={errors}
			triggerSubmit={triggerSubmit}
			refForm={refForm}
			{...submitProps}
			onSelectCountry={onSelectCountry}
			selectedCountry={selectedCountry}
		/>
	);
};
export default SingleInput;
