import { useState, useEffect } from 'react';
import useDataApi from '../../hooks/useDataApi';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import { breakingNewsTimeFormat } from '../../utils/datetime';
import NextPrevNavigator from '../nextPrevNavigator/NextPrevNavigator';

const View = ({ data: headlines, setOffset, offset, isLoading, isError }) => (
	<WidgetBox header="Other News" icon="/images/head-line.png">
		{isError && <div>Something went wrong</div>}
		{isLoading ? (
			<div>Loading...</div>
		) : (
			<>
				{headlines &&
					headlines.slice(0, 5).map((headline) => {
						return (
							<div key={`news-item-${headline.url}`} className="headline-item">
								<label>
									{breakingNewsTimeFormat(headline.time_pub)} - {headline.source}
								</label>
								<div>
									<a href={headline.url} target="_blank" rel="noreferrer">
										&gt; {headline.txt}
									</a>
								</div>
							</div>
						);
					})}
			</>
		)}
		<NextPrevNavigator
			setOffset={setOffset}
			offset={offset}
			count={headlines.length}
			limit={10}
		/>
	</WidgetBox>
);

const OtherNews = () => {
	const [offset, setOffset] = useState(0);
	const url = `/news/headline?start=${offset}`;

	const body = { start: offset };
	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body)
	});

	useEffect(() => {
		setUrl(url);
	}, [url]);

	return <View setOffset={setOffset} offset={offset} {...dataProps} />;
};

export default OtherNews;
