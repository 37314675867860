import { render } from 'react-dom';
import AuthProvider from './auth/AuthProvider';
import AppRouter from './route/AppRouter';
import ThemeProvider from './theme/ThemeProvider';
import { isDev } from './utils';

const startFixtureServer = async () => {
	if (isDev) {
		const { worker } = require('./mocks/browser');
		await worker.start();
	}
};

const App = () => {
	return (
		<AuthProvider>
			<ThemeProvider>
				<AppRouter />
			</ThemeProvider>
		</AuthProvider>
	);
};

const main = async () => {
	await startFixtureServer();
	render(<App />, document.getElementById('root'));
};

main();
