export default (data, symbol) => {
	return {
		action: 'draw-chart',
		chart: {
			backgroundColor: 'rgba(255, 255, 255, 0.0)',
			height: 189
		},
		series: [
			{
				name: symbol,
				data,
				color: '#FF6B01',
				lineWidth: 2,
				zones: [
					{
						value: 0,
						color: '#e05353'
					},
					{
						color: '#56c876'
					}
				]
			}
		],
		legend: {
			enabled: false
		},
		navigator: {
			enabled: false
		},
		rangeSelector: {
			verticalAlign: 'bottom',
			x: 0,
			y: 0,
			inputEnabled: false,
			buttons: [
				{
					type: 'day',
					count: 1,
					text: '1D'
				},
				{
					type: 'day',
					count: 7,
					text: '1W'
				},
				{
					type: 'month',
					count: 1,
					text: '1M'
				},
				{
					type: 'year',
					count: 1,
					text: '1Y'
				},
				{
					type: 'all',
					text: 'All'
				}
			]
		},
		scrollbar: {
			enabled: false
		},
		stockTools: {
			gui: {
				enabled: false
			}
		},
		xAxis: {
			visible: true,
			gridLineWidth: 0,
			lineWidth: 2,
			lineColor: 'lightgrey',
			labels: {
				enabled: true
			}
		},
		yAxis: {
			opposite: false,
			gridLineWidth: 0,
			plotLines: [
				{
					value: 4,
					width: 1,
					color: 'lightgrey',
					zIndex: 10
				}
			],
			lineWidth: 2,
			lineColor: 'lightgrey',
			endOnTick: true,
			showLastLabel: true,
			labels: {
				useHTML: true,
				formatter: function () {
					if (this.isFirst == true) {
						return '<img src="/images/new-smile-red.png" "></img>';
					}
					if (this.isLast == true) {
						return '<img src="/images/new-smile-green.png"  style="margin-top: 5px;"></img>';
					}
				}
			}
		}
	};
};
