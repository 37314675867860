import ChangePassword from '../../components/forms/changePassword/ChangePassword';

const View = () => {
	return (
		<div className="change-password">
			<h1>Change Password</h1>
			<ChangePassword />
		</div>
	);
};

const ChangePasswordPage = () => {
	return <View />;
};

export default ChangePasswordPage;
