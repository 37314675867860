import { useEffect, useRef } from 'react';

const useFocusElements = (isOpen) => {
	let focusableEls = [];
	let focusElement = null;
	let modalContentEL = useRef(null);
	let lastFocusableEl = () => focusableEls[focusableEls.length - 1];
	let firstFocusableEl = () => focusableEls[0];

	useEffect(() => {
		let focusedBeforeOpen = document.activeElement;
		if (isOpen) {
			focusableEls = focusable(modalContentEL.current);
			if (focusableEls.length) {
				focusableEls[0].focus();
			}
			if (focusElement) {
				focusElement.focus();
			}
		} else {
			if (focusedBeforeOpen) {
				focusedBeforeOpen.focus();
			}
		}
	});

	const handleKeyDown = (event) => {
		const KEY_TAB = 9;
		const KEY_ESC = 27;

		switch (event.keyCode) {
			case KEY_ESC: {
				break;
			}
			case KEY_TAB: {
				if (event.shiftKey) {
					if (document.activeElement === firstFocusableEl()) {
						event.preventDefault();
						lastFocusableEl().focus();
					}
				} else {
					if (document.activeElement === lastFocusableEl()) {
						event.preventDefault();
						firstFocusableEl().focus();
					}
				}
				break;
			}
			default:
				break;
		}
	};

	return {
		modalContentEL,
		handleKeyDown
	};
};

const focusable = (root) => {
	let els = root.querySelectorAll(
		'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.modal-close), [tabindex="0"]'
	);
	return Array.from(els);
};

export default useFocusElements;
