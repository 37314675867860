import { useState } from 'react';

const ContextMenu = ({ value, handleClick }) => {
	const [showDropDownMenu, setShowDropDownMenu] = useState(false);
	const toggleMenu = () => {
		closeMenu();
		setShowDropDownMenu(!showDropDownMenu);
	};

	window.onclick = (event) => {
		if (!event.target.classList.contains('dropdown-dots-icon')) {
			closeMenu();
		}
	};

	return (
		<div className="dropdown-menu">
			<div>
				<img
					className="dropdown-dots-icon"
					src="/images/3dots.png"
					onClick={() => toggleMenu()}></img>
			</div>
			<div className={`dropdown-menu-item ${showDropDownMenu && 'show'}`}>
				{menuItems().map((item, i) => {
					return (
						<a key={i} onClick={(e) => handleClick(e, item.action, value)}>
							<img className="dropdown-menu-icon" src={item.icon}></img>
							<span>{item.text}</span>
						</a>
					);
				})}
			</div>
		</div>
	);
};

const closeMenu = () => {
	let dropdowns = document.querySelectorAll('.dropdown-menu-item.show');
	for (const dropdown of dropdowns) {
		dropdown.classList.remove('show');
	}
};

const menuItems = () => {
	return [
		{ action: 'view', text: 'View', icon: '/images/view-icon.png' },
		{ action: 'edit', text: 'Edit', icon: '/images/edit-icon.png' },
		{ action: 'Duplicate', text: 'Duplicate', icon: '/images/copy-icon.png' },
		{ action: 'delete', text: 'Delete', icon: '/images/delete-icon.png' }
	];
};

export default ContextMenu;
