import { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import './Chart.css';
import useDataApi from '../../hooks/useDataApi';
import WidgetBox from '../layout/WidgetBox/WidgetBox';
import * as ChartConfig from './config/ChartConfig';
import { portfolioChartData } from './utils';
import loadChartModules from './loadChartModules';
import { AccountBalanceIcon } from '../../styles/icons/icons';

const View = ({ isError, isLoading, data, rangeSelectorReciever, rangeSelectorSelected }) => {
	return (
		<WidgetBox header="Account Balance Chart" link="#">
			<div className="card-header">
				<h3 className="card-title">
					<AccountBalanceIcon /> Account Balance Chart
				</h3>
			</div>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<HighchartsReact
					highcharts={Highcharts}
					constructorType={'stockChart'}
					options={ChartConfig.AccountBalance(
						portfolioChartData(data).data,
						portfolioChartData(data).symbol,
						rangeSelectorReciever,
						rangeSelectorSelected
					)}
				/>
			)}
		</WidgetBox>
	);
};

const AccountBalanceChart = ({ accountType = 'live' }) => {
	const [displayType, setDisplayType] = useState('intraday');
	const [chartParam, setChartParam] = useState(1);
	const url = `/account_balances?display_type=${displayType}&account_type=${accountType}`;
	const [dataProps, setUrl] = useDataApi(url, [], {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			account_type: accountType,
			display_type: displayType
		})
	});

	const rangeSelectorReciever = (selectedRange, selectedIndex) => {
		const displayType = ['1D', '7D'].includes(selectedRange) ? 'intraday' : 'interday';
		setChartParam(selectedIndex);
		setDisplayType(displayType);
	};

	useEffect(() => {
		setUrl(url);
	}, [displayType, accountType]);

	loadChartModules({
		chartType: 'accountBalance'
	});

	return (
		<View
			{...dataProps}
			rangeSelectorReciever={rangeSelectorReciever}
			rangeSelectorSelected={chartParam}
		/>
	);
};

export default AccountBalanceChart;
