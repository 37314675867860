import { NavLink } from 'react-router-dom';
import ExternalLink from '../navigation/ExternalLink';
import Styles from './SideBarStyles';

const SideBar = ({ items, eventHandler }) => {
	return (
		<Styles>
			<ul>
				{Object.keys(items).map((key) => {
					const item = items[key];
					return (
						<li key={key}>
							{!item.external ? (
								!item.trigger ? (
									<NavLink
										to={`/settings${item.path}`}
										className={({ isActive }) => (isActive ? 'active' : '')}>
										{item.text}
									</NavLink>
								) : (
									<a onClick={() => eventHandler(key)}>{item.text}</a>
								)
							) : (
								<ExternalLink item={item} />
							)}
						</li>
					);
				})}
			</ul>
		</Styles>
	);
};

export default SideBar;
