import useDataApi from '../../hooks/useDataApi';
import Profile from '../../components/forms/profile/Profile';

const View = ({ isError, isLoading, data }) => {
	return (
		<div className="profile-page">
			<h1>Profile</h1>
			{isError && <div>Something went wrong</div>}
			{isLoading ? <div>Loading...</div> : <Profile profileDefault={data} />}
		</div>
	);
};

const ProfilePage = () => {
	const [profileProps] = useDataApi('/profile', {}, { method: 'POST' });
	return <View {...profileProps} />;
};

export default ProfilePage;
