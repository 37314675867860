const AlgoNameWithId = ({ name, id }) => {
	return (
		<div className="multi-line-cell name-n-timestamp">
			<span>{id}</span>
			<span>{name}</span>
		</div>
	);
};

export default AlgoNameWithId;
