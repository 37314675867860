export default [
	{
		symbol: 'QQQ',
		name: 'Invesco QQQ Trust, Series 1',
		asset_class: 'stocks',
		closing: 290.08,
		price24h: 296.445,
		price7d: 288.26,
		change24h: 6.365000000000009,
		change7d: 1.8199999999999932,
		pct24: 2.2249517919065593,
		pct7d: 0.5975320766584541
	},
	{
		symbol: 'VXX',
		name: 'iPath Series B S&P 500 VIX Short-Term Futures ETN',
		asset_class: 'stocks',
		closing: 14.2399,
		price24h: 14.345,
		price7d: 14.59,
		change24h: 0.1051000000000002,
		change7d: -0.3500999999999994,
		pct24: 0.7373595505618008,
		pct7d: -2.4578651685393234
	},
	{
		symbol: 'SPY',
		name: 'SPDR S&P 500 ETF Trust',
		asset_class: 'stocks',
		closing: 403.74,
		price24h: 409.63,
		price7d: 400.6,
		change24h: 5.889999999999986,
		change7d: 3.1399999999999864,
		pct24: 1.4726829087035667,
		pct7d: 0.7642097179731671
	},
	{
		symbol: 'TSLA',
		name: 'Tesla, Inc. Common Stock',
		asset_class: 'stocks',
		closing: 156.7682,
		price24h: 171.4501,
		price7d: 183.35,
		change24h: 14.681899999999985,
		change7d: -26.581799999999987,
		pct24: 9.247027488562365,
		pct7d: -16.829576010908774
	},
	{
		symbol: 'BTC/USD',
		name: 'Bitcoin',
		asset_class: 'crypto',
		closing: 18050.8,
		price24h: 17867.95,
		price7d: 16970.79,
		change24h: -182.84999999999854,
		change7d: 1080.0099999999984,
		pct24: -0.9293856529069997,
		pct7d: 5.9037779232926875
	},
	{
		symbol: 'ETH/USD',
		name: 'Ethereum',
		asset_class: 'crypto',
		closing: 1337.56,
		price24h: 1333.55,
		price7d: 1259.4,
		change24h: -4.009999999999991,
		change7d: 78.15999999999985,
		pct24: -0.17217501965040646,
		pct7d: 5.722947935771218
	}
];
