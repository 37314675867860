import styled from 'styled-components';

const Styles = styled.div.attrs({
	className: 'pagination'
})`
	padding: 10px;
	display: flex;
	justify-content: space-between;
	font-size: var(--font-size-s);
	align-items: center;
	border-top: var(--border-grey-1);

	button {
		border: none;
		background: none;
		color: var(--font-black);
		cursor: pointer;
	}
	.page-navigator {
		display: flex;
	}

	.next-prev-btn,
	.page-number {
		padding: 5px;
	}
	.page-numbers {
		display: flex;
		text-align: center;
	}

	.page-number {
		cursor: pointer;

		&.current {
			background: var(--font-orange);
		}
		&.selected {
			background-color: var(--mt-orange);
			border-radius: 50%;
			height: 10px;
			width: 10px;
			line-height: 10px;
			color: white;
			align-self: center;
		}
	}
`;

export default Styles;
