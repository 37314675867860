const ExpanderColumn = ({ row }) => {
	return (
		<>
			<span className="exapnder" {...row.getToggleRowExpandedProps()}>
				<img
					src={`/images/${
						row.isExpanded ? 'expander_down_arrow.png' : 'expander_right_arrow.png'
					}`}
				/>
				<label>{row.values.expander}</label>
			</span>
		</>
	);
};

export default ExpanderColumn;
