import { ThemeProvider as Provider } from 'styled-components';
import useDarkTheme from '../hooks/useDarkTheme';
import { lightTheme, darkTheme, GlobalStyles } from '../styles/theme';
import ThemeContext from './ThemeContext';

const ThemeProvider = ({ children }) => {
	const themeState = useDarkTheme();
	const [isDarkTheme] = themeState;
	return (
		<ThemeContext.Provider value={themeState}>
			<Provider theme={isDarkTheme ? darkTheme : lightTheme}>
				<GlobalStyles />
				{children}
			</Provider>
		</ThemeContext.Provider>
	);
};

export default ThemeProvider;
