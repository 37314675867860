import { useState } from 'react';
import { ModalDialog, ModalBody, ModalFooter } from '../ModalDialog/ModalDialog';
import './NoAlpacaDialog.css';

const View = ({ isOpen, setIsOpen }) => {
	return (
		<ModalDialog
			isOpen={isOpen}
			modalTitle="Sorry"
			headerIcon="/images/info-v2.png"
			handleStateChange={setIsOpen}>
			<ModalBody>
				<div className="noalpacadialog-model">
					<div className="noalpacadialog-info">
						<h2>You need to connect an alpaca account to perform this section.</h2>
						<div>
							You will need to open a trading account with MachineTrader to perform
							this action, which is billed at $99/mt. We highly recommend either
							attending a free Zoom training session or review the Learn section
							before signing up to make sure this service is right for you.
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="noalpacadialog-footer">
					<a onClick={() => setIsOpen(false)} className="link-text">
						{' '}
						&gt; Dismiss
					</a>
					<a
						className="button-sm btn-orange width-100"
						href="https://www.machinetrader.io/learn-articles/signup-for-free-machinetrader-training"
						target="_blank"
						rel="noreferrer">
						LET&apos;S DO THIS
					</a>
				</div>
			</ModalFooter>
		</ModalDialog>
	);
};

const NoAlpacaDialog = (props) => {
	const [isOpen, setIsOpen] = useState(props.isOpen || true);
	return <View setIsOpen={setIsOpen} isOpen={isOpen} />;
};

export default NoAlpacaDialog;
