const HeadLinesData = [
	{
		time_pub: '2022-06-03T06:50:00.000Z',
		txt: "BD Unveils World's First Spectral Cell Sorter with High-Speed Imaging Technology that Sorts Cells Based on Visual Charac",
		sentiment: 'Neutral',
		summary:
			'BD FACSDiscover™ S8 Cell Sorter with BD CellView™ Image Technology First to Combine Spectral Flow Cytometry with Sort-Capable Imaging FRANKLIN LAKES, N.J. , June 3, 2022 /PRNewswire/ -- BD (Becton, Dickinson and Company) (NYSE: BDX), a leading global medical technology company, today announced it will introduce new cell sorting technology at the International Society for Advancement of Cytometry (ISAC) CYTO 2022 conference June 3-7 that enables researchers to see and sort cells at speeds never before possible, which creates the potential to transform research and cell-based therapeutic development across a range of fields such as virology and oncology, as well as numerous disease states.',
		source: 'PRNewsWire',
		url: 'https://www.prnewswire.com/news-releases/bd-unveils-worlds-first-spectral-cell-sorter-with-high-speed-imaging-technology-that-sorts-cells-based-on-visual-characteristics-301560725.html'
	},
	{
		time_pub: '2022-06-03T06:50:00.000Z',
		txt: 'NuScale Power Reaffirms Financial Outlook and Provides Business Update',
		sentiment: 'Positive',
		summary:
			'PORTLAND, Ore.--(BUSINESS WIRE)--NuScale Power, industry-leading provider of proprietary advanced nuclear SMR technology, provided a business update; reaffirmed its financial outlook.',
		source: 'Business Wire',
		url: 'https://www.businesswire.com/news/home/20220603005088/en/NuScale-Power-Reaffirms-Financial-Outlook-and-Provides-Business-Update/'
	},
	{
		time_pub: '2022-06-03T06:50:00.000Z',
		txt: "Kimco Realty® Management to Present at Nareit's REITweek: 2022 Investor Conference",
		sentiment: 'Neutral',
		summary:
			"JERICHO, N.Y.--(BUSINESS WIRE)--Kimco Realty® (NYSE: KIM) announced today that its management will participate in the Nareit's REITweek: 2022 Investor Conference on Wednesday, June 8, 2022 in New York. Management will provide a general overview of the company followed by a question and answer session. The webcast information is as follows: Event: Kimco Realty® Management Presentation at Nareit's REITweek: 2022 Investor Conference When: Wednesday, June 8, 2022 from 12:30 PM – 1:00 PM, ET Live We",
		source: 'Business Wire',
		url: 'https://www.businesswire.com/news/home/20220603005053/en/Kimco-Realty%C2%AE-Management-to-Present-at-Nareit%E2%80%99s-REITweek-2022-Investor-Conference/'
	},
	{
		time_pub: '2022-06-03T06:50:00.000Z',
		txt: 'Tesla vs. the S&P 500: Which Is the Better First Investment?',
		sentiment: 'Negative',
		summary:
			'Is it better to build your investment portfolio by first buying a hot growth stock or by buying an index fund?',
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/03/tesla-vs-sp-500-which-better-first-investment/'
	},
	{
		time_pub: '2022-06-03T06:50:00.000Z',
		txt: 'Myovant Sciences to Participate in the Goldman Sachs 43rd Annual Global Healthcare Conference',
		sentiment: 'Neutral',
		summary:
			'BASEL, Switzerland, June 03, 2022 (GLOBE NEWSWIRE) -- Myovant Sciences (NYSE: MYOV), a biopharmaceutical company that aspires to redefine care for women and for men through purpose-driven science, empowering medicines, and transformative advocacy, today announced that David Marek, Chief Executive Officer and Uneek Mehra, Chief Financial and Business Officer of Myovant Sciences, Inc., will participate in a fireside chat at the Goldman Sachs 43rd Annual Global Healthcare Conference on Thursday, June 16, 2022 at 11:00 a.m. Eastern Time.',
		source: 'GlobeNewsWire',
		url: 'https://www.globenewswire.com/news-release/2022/06/03/2455879/0/en/Myovant-Sciences-to-Participate-in-the-Goldman-Sachs-43rd-Annual-Global-Healthcare-Conference.html'
	},
	{
		time_pub: '2022-06-03T06:48:00.000Z',
		txt: 'Velodyne Lidar Wins GeoBuiz Summit Award for Innovation in Mapping Technology',
		sentiment: 'Neutral',
		summary:
			'SAN JOSE, Calif.--(BUSINESS WIRE)-- #Lidar--Velodyne Lidar announced its Intelligent Infrastructure Solution won the GeoBuiz Summit Award in the Mapping Technology category.',
		source: 'Business Wire',
		url: 'https://www.businesswire.com/news/home/20220603005111/en/Velodyne-Lidar-Wins-GeoBuiz-Summit-Award-for-Innovation-in-Mapping-Technology/'
	},
	{
		time_pub: '2022-06-03T06:47:05.000Z',
		txt: 'Best Income Stocks to Buy for June 3rd',
		sentiment: 'Positive',
		summary:
			'SBLK, ARR, and KMI made it to the Zacks Rank #1 (Strong Buy) income stocks list on June 3, 2022.',
		source: 'Zacks Investment Research',
		url: 'https://www.zacks.com/commentary/1933459/best-income-stocks-to-buy-for-june-3rd'
	},
	{
		time_pub: '2022-06-03T06:47:03.000Z',
		txt: 'Best Value Stocks to Buy for June 3rd',
		sentiment: 'Positive',
		summary:
			'AMR, MBUU, and CC made it to the Zacks Rank #1 (Strong Buy) value stocks list on June 3, 2022.',
		source: 'Zacks Investment Research',
		url: 'https://www.zacks.com/commentary/1933474/best-value-stocks-to-buy-for-june-3rd'
	},
	{
		time_pub: '2022-06-03T06:47:00.000Z',
		txt: "This Recent SPAC IPO's Management Thinks Its Stock Is Very Cheap",
		sentiment: 'Positive',
		summary:
			"Nextdoor's management just authorized a large share buyback. Does that make it worth a look for your portfolio?",
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/06/03/this-recent-spac-ipos-management-thinks-its-stock/'
	},
	{
		time_pub: '2022-06-03T06:45:01.000Z',
		txt: 'The 3 Best Long-Term Stocks to Add to Your Q2 Buy List',
		sentiment: 'Positive',
		summary:
			'Recent declines on Wall Street create the perfect opportunity to invest in these three best long-term stocks. The post The 3 Best Long-Term Stocks to Add to Your Q2 Buy List appeared first on InvestorPlace.',
		source: 'InvestorPlace',
		url: 'https://investorplace.com/2022/06/the-3-best-long-term-stocks-to-add-to-your-q2-buy-list-gm-intc-mrk/'
	},
	{
		time_pub: '2022-07-13T15:42:57.000Z',
		txt: 'Genpact: Very Good Company, Slightly Overvalued',
		sentiment: 'Neutral',
		summary:
			'Genpact has only matched market returns as a public company, but this overshadows good quality fundamentally. Capital allocation will be a major factor in future returns, since Genpact is at a mature stage where growth slows and shareholder yield takes on more importance.',
		source: 'Seeking Alpha',
		url: 'https://seekingalpha.com/article/4523144-genpact-very-good-company-slightly-overvalued'
	},
	{
		time_pub: '2022-07-13T15:37:00.000Z',
		txt: 'Wells Fargo Class Action: Levi & Korsinsky Reminds Wells Fargo & Company Investors of the Pending Class Action Lawsuit w',
		sentiment: 'Neutral',
		summary:
			'New York, New York--(Newsfile Corp. - July 13, 2022) - Levi & Korsinsky, LLP notifies investors in Wells Fargo & Company ("Wells Fargo" or the "Company") (NYSE: WFC) of a class action securities lawsuit.The lawsuit on behalf of Wells Fargo investors has been commenced in the the United States District Court for the Northern District of California. This lawsuit is on behalf of persons and entities that purchased or otherwise acquired Wells Fargo common stock...',
		source: 'Newsfile Corp',
		url: 'https://www.newsfilecorp.com/release/130805/Wells-Fargo-Class-Action-Levi-Korsinsky-Reminds-Wells-Fargo-Company-Investors-of-the-Pending-Class-Action-Lawsuit-with-a-Lead-Plaintiff-Deadline-of-August-29-2022-WFC'
	},
	{
		time_pub: '2022-07-13T15:34:01.000Z',
		txt: 'Is Nio Looking Bullish Or Bearish? A Look At Two Timeframes',
		sentiment: 'Neutral',
		summary:
			'Nio, Inc (NYSE: NIO) was trading up about 2.4% on Wednesday after opening lower, a move that was triggered by inflation data, which came in higher than what was expected, at 9.1%. Despite showing strength in comparison to the general markets, Nio has been trading in a downtrend since topping out at.',
		source: 'Benzinga',
		url: 'https://www.benzinga.com/trading-ideas/movers/22/07/28055775/is-nio-looking-bullish-or-bearish-a-look-at-two-timeframes?SNAPI'
	},
	{
		time_pub: '2022-07-13T15:33:20.000Z',
		txt: "REV Stock Fans Want a Fighting Chance in Revlon's Bankruptcy",
		sentiment: 'Negative',
		summary:
			"Today, retail investors in Revlon and REV stock are bidding up shares of this company amid reports that there may be value in this name. The post REV Stock Fans Want a Fighting Chance in Revlon's Bankruptcy appeared first on InvestorPlace.",
		source: 'InvestorPlace',
		url: 'https://investorplace.com/2022/07/rev-stock-fans-want-a-fighting-chance-in-revlons-bankruptcy/'
	},
	{
		time_pub: '2022-07-13T15:30:37.000Z',
		txt: 'Three Stock Lunch: Diageo, Danone and Mondelez',
		sentiment: 'Neutral',
		summary:
			"Boris Schlossberg, BK Asset Management, joins 'Power Lunch' to discuss his investing take on three stocks: Diageo, Danone and Mondelez.",
		source: 'CNBC Television',
		url: 'https://www.youtube.com/watch?v=nvOT8hwkt-Y'
	},
	{
		time_pub: '2022-07-13T15:29:00.000Z',
		txt: 'Labor Market Disruption',
		sentiment: 'Negative',
		summary:
			'High demand and the unexpected shift within the economy left a deficit of workers, increasing bargaining power and inflation risks. Supply disruptions in the labor market increased wages at their fastest rate in decades.',
		source: 'Seeking Alpha',
		url: 'https://seekingalpha.com/article/4523143-labor-market-disruption'
	},
	{
		time_pub: '2022-07-13T15:28:37.000Z',
		txt: 'Altria: Time To Start Thinking About The End Of The Dividend',
		sentiment: 'Negative',
		summary:
			"The bad news for Altria never seems to end, with the FDA now proposing to ban JUUL E-cigarettes, thereby sending the share price plunging. This comes after Altria's flagship reduced-risk product, IQOS, was also banned, and the Biden administration is seeking to reduce nicotine levels in cigarettes to non-addictive levels.",
		source: 'Seeking Alpha',
		url: 'https://seekingalpha.com/article/4523142-altria-time-to-start-thinking-about-the-end-of-the-dividend'
	},
	{
		time_pub: '2022-07-13T15:27:27.000Z',
		txt: 'Netflix partners with Microsoft for its ad-supported plan',
		sentiment: 'Positive',
		summary:
			"It looks like things are coming together for Netflix's lower-priced ad-supported tier, reported to arrive later this year: Netflix just revealed today that it's partnering with Microsoft as its “global advertising technology and sales partner” to help the streaming service earn revenue through ads.",
		source: 'TechCrunch',
		url: 'https://techcrunch.com/2022/07/13/netflix-partners-with-microsoft-for-its-ad-supported-plan/'
	},
	{
		time_pub: '2022-07-13T15:27:00.000Z',
		txt: 'Netflix Picks Microsoft as Partner for Ad-Supported Streaming Services',
		sentiment: 'Positive',
		summary:
			"Both Netflix and Microsoft emphasized protecting customers' privacy as part of their approach to ad-supporting streaming.",
		source: 'Barrons',
		url: 'https://www.barrons.com/articles/netflix-stock-microsoft-ad-supported-streaming-subscription-51657740397'
	},
	{
		time_pub: '2022-07-13T15:26:58.000Z',
		txt: 'Millicom International: A Latin American Telecom Provider With Good Growth',
		sentiment: 'Positive',
		summary:
			'Millicom International Cellular SA ( TIGO , Financial) is a relatively unknown international pay-TV and wireless provide with operations mostly in emerging markets of Latin America. These low penetration rates of these markets allow for above industry average growth.',
		source: 'GuruFocus',
		url: 'https://www.gurufocus.com/news/1824079/millicom-international-a-latin-american-telecom-provider-with-good-growth'
	},
	{
		time_pub: '2022-07-13T15:26:58.000Z',
		txt: 'Millicom International: A Latin American Telecom Provider With Good Growth',
		sentiment: 'Positive',
		summary:
			'Millicom International Cellular SA ( TIGO , Financial) is a relatively unknown international pay-TV and wireless provide with operations mostly in emerging markets of Latin America. These low penetration rates of these markets allow for above industry average growth.',
		source: 'GuruFocus',
		url: 'https://www.gurufocus.com/news/1824079/millicom-international-a-latin-american-telecom-provider-with-good-growth'
	},
	{
		time_pub: '2022-07-13T15:26:21.000Z',
		txt: 'Euro Hits Parity With Dollar as Slump Continues',
		sentiment: 'Negative',
		summary:
			"The euro has suffered a swift and brutal slump this year, and now it's crossed a major threshold for the first time in more than two decades: parity with the dollar. Bloomberg's Kriti Gupta explains how it happened on \"Bloomberg Markets.",
		source: 'Bloomberg Markets and Finance',
		url: 'https://www.youtube.com/watch?v=LyH46xuEM_g'
	},
	{
		time_pub: '2022-07-13T15:25:48.000Z',
		txt: "Larry Summers warns inflation unlikely to fall without 'significant economic downturn'",
		sentiment: 'Negative',
		summary:
			'Former Treasury Secretary Larry Summers said Wednesday that the sizzling-hot June inflation report had ramped up the odds of a "significant" economic downturn.',
		source: 'Fox Business',
		url: 'https://www.foxbusiness.com/economy/larry-summers-warns-inflation-unlikely-fall-significant-economic-downturn'
	},
	{
		time_pub: '2022-07-13T15:24:58.000Z',
		txt: "We see opportunities in companies with sustainable dividends, says Franklin Templeton's Labella",
		sentiment: 'Positive',
		summary:
			"Mike Labella, Franklin Templeton Investment Solutions senior portfolio manager, joins 'Power Lunch' to discuss sectors that are the best dividend payers, if it's still safe to put money in energy and more.",
		source: 'CNBC Television',
		url: 'https://www.youtube.com/watch?v=DZci9mxf9js'
	},
	{
		time_pub: '2022-07-13T15:23:19.000Z',
		txt: 'Switch Stock On Track To Overtake Record Highs',
		sentiment: 'Positive',
		summary:
			'Switch (SWCH) stock has been middling above the $33 level since its May bull gap, resting just below its May 11 record high of $33.84.',
		source: 'Forbes',
		url: 'https://www.forbes.com/sites/greatspeculations/2022/07/13/switch-stock-on-track-to-overtake-record-highs/'
	},
	{
		time_pub: '2022-07-13T15:21:00.000Z',
		txt: "You won't hear the Federal Reserve talk about the good parts of this inflation report",
		sentiment: 'Neutral',
		summary:
			"Wednesday's inflation report put the markets and the Federal Reserve on notice that higher prices had yet to cool in June.",
		source: 'Market Watch',
		url: 'https://www.marketwatch.com/story/you-wont-hear-the-federal-reserve-talk-about-the-good-parts-of-this-inflation-report-11657740095'
	},
	{
		time_pub: '2022-07-13T15:20:48.000Z',
		txt: 'We are convinced the upside for Unity is incredibly clear, says CEO Riccitiello',
		sentiment: 'Positive',
		summary:
			"John Riccitiello, Unity CEO, joins 'Power Lunch' to discuss the company's merger with ironSource, macroeconomic factors affecting the company's guidance and why the value in the deal may not be immediately apparent.",
		source: 'CNBC Television',
		url: 'https://www.youtube.com/watch?v=dsjOargYaFs'
	},
	{
		time_pub: '2022-07-13T15:20:40.000Z',
		txt: 'Elon Musk Puts Lithium Battery Stocks LAC, LTHM, SQM on Watch',
		sentiment: 'Neutral',
		summary:
			'Today, lithium battery stocks are in focus as investors digest whether lithium could represent the new oil needed for the economy. The post Elon Musk Puts Lithium Battery Stocks LAC, LTHM, SQM on Watch appeared first on InvestorPlace.',
		source: 'InvestorPlace',
		url: 'https://investorplace.com/2022/07/elon-musk-puts-lithium-battery-stocks-lac-lthm-sqm-on-watch/'
	},
	{
		time_pub: '2022-07-13T15:18:03.000Z',
		txt: 'Read Why Did Credit Suisse Raise Price Target On Kura Oncology',
		sentiment: 'Positive',
		summary:
			'Credit Suisse has raised the price target on Kura Oncology Inc (NASDAQ: KURA) to $29 from $27. The analysts say that evidence of an effective and tolerable agent and a clear recommended phase 2 dose for ziftomenib should support an upside of ~$8-10/share to their NPV.',
		source: 'Benzinga',
		url: 'https://www.benzinga.com/general/biotech/22/07/28054065/read-why-did-credit-suisse-raised-price-target-on-kura-oncology?SNAPI'
	},
	{
		time_pub: '2022-07-13T15:18:02.000Z',
		txt: 'RBC Capital Downgrades GrafTech - Read Why',
		sentiment: 'Negative',
		summary:
			'RBC Capital analyst Arun Viswanathan downgraded GrafTech International Ltd (NYSE: EAF) to Sector Perform from Outperform and lowered the price target to $7 (an upside of 9%) from $10. The analyst states that the downgrade reflects cost/demand headwinds and lower average.',
		source: 'Benzinga',
		url: 'https://www.benzinga.com/news/22/07/28055960/rbc-capital-downgrades-graftech-internationals-ratings-cuts-price-target-by-30?SNAPI'
	},
	{
		time_pub: '2022-07-14T06:30:00.000Z',
		txt: 'Teleflex Announces Second Quarter 2022 Earnings Conference Call Information',
		sentiment: 'Neutral',
		summary:
			'WAYNE, Pa., July 14, 2022 (GLOBE NEWSWIRE) -- Teleflex Incorporated (NYSE:TFX) will host a conference call to discuss its second quarter financial results and provide an operational update at 8:00 a.m. Eastern Time on Thursday, July 28, 2022.',
		source: 'GlobeNewsWire',
		url: 'https://www.globenewswire.com/news-release/2022/07/14/2479536/0/en/Teleflex-Announces-Second-Quarter-2022-Earnings-Conference-Call-Information.html'
	},
	{
		time_pub: '2022-07-14T06:30:00.000Z',
		txt: 'Arhaus To Report Second Quarter 2022 Financial Results On August 11, 2022',
		sentiment: 'Neutral',
		summary:
			'BOSTON HEIGHTS, Ohio, July 14, 2022 (GLOBE NEWSWIRE) -- Arhaus (NASDAQ: ARHS), a rapidly growing lifestyle brand and omni-channel retailer of premium artisan-crafted home furnishings, will release its second quarter 2022 financial results before market open on Thursday, August 11, 2022. The Company will host a conference call at 8:30 a.m. Eastern Time the same day to review its financial and operational results and answer questions from the investment community.',
		source: 'GlobeNewsWire',
		url: 'https://www.globenewswire.com/news-release/2022/07/14/2479534/0/en/Arhaus-To-Report-Second-Quarter-2022-Financial-Results-On-August-11-2022.html'
	},
	{
		time_pub: '2022-07-14T06:30:00.000Z',
		txt: 'Theratechnologies Provides Update on the Dose Escalation Portion of Fast Track Designated TH1902 First-in-Human Study in',
		sentiment: 'Neutral',
		summary:
			"MONTREAL, July 14, 2022 (GLOBE NEWSWIRE) -- Theratechnologies Inc. (“Theratechnologies” or “the Company”) (TSX:TH) (NASDAQ:THTX), a biopharmaceutical company focused on the development and commercialization of innovative therapies, today provided an update on the dose escalation portion of the TH1902 Phase 1 clinical safety study. This is Theratechnologies' first-in-human study of TH1902, its investigational lead peptide drug conjugate (“PDC”) linked to docetaxel for the treatment of sortilin-expressing cancers. It has received Fast Track designation from the United States Food and Drug Administration (“FDA”).",
		source: 'GlobeNewsWire',
		url: 'https://www.globenewswire.com/news-release/2022/07/14/2479531/0/en/Theratechnologies-Provides-Update-on-the-Dose-Escalation-Portion-of-Fast-Track-Designated-TH1902-First-in-Human-Study-in-Advanced-Resistant-Malignancies.html'
	},
	{
		time_pub: '2022-07-14T06:25:36.000Z',
		txt: 'Amazon proposes concessions as it looks to settle EU antitrust cases--WSJ',
		sentiment: 'Negative',
		summary:
			"Amazon.com Inc. AMZN, +1.08% has proposed on Thursday certain concessions as the ecommerce giant looks to settle antitrust cases against it in the European Union, according to a report in The Wall Street Journal. Amazon's stock fell 0.9% in premarket trading.",
		source: 'Market Watch',
		url: 'https://www.marketwatch.com/story/amazon-proposes-concessions-as-it-looks-to-settle-eu-antitrust-cases--wsj-2022-07-14'
	},
	{
		time_pub: '2022-07-14T06:21:27.000Z',
		txt: "JPMorgan Chase is set to report second-quarter earnings – here's what the Street expects",
		sentiment: 'Negative',
		summary:
			'As a result of all the conflicting data, investors should brace themselves for a wider-than-usual range of outcomes for the banks this quarter.',
		source: 'CNBC',
		url: 'https://www.cnbc.com/2022/07/14/jpmorgan-jpm-2q-2022-earnings.html'
	},
	{
		time_pub: '2022-07-14T06:20:50.000Z',
		txt: 'Occidental Petroleum: 20% FCF Yield With Potentially Strong Returns',
		sentiment: 'Positive',
		summary: 'Berkshire Hathaway is clearly interested in Occidental Petroleum at',
		source: 'Seeking Alpha',
		url: 'https://seekingalpha.com/article/4523245-occidental-petroleum-potentially-strong-returns'
	},
	{
		time_pub: '2022-07-14T06:20:00.000Z',
		txt: 'FactSet Stock Is Beating the Market in 2022. Is It a Buy?',
		sentiment: 'Neutral',
		summary:
			'FactSet Research is putting up respectable returns as it integrates two acquisitions.',
		source: 'The Motley Fool',
		url: 'https://www.fool.com/investing/2022/07/14/factset-stock-beating-market-2022-is-it-a-buy/'
	},
	{
		time_pub: '2022-07-14T06:20:00.000Z',
		txt: 'Linde Included in FTSE4Good Index for Seventh Consecutive Year',
		sentiment: 'Neutral',
		summary:
			'WOKING, UK / ACCESSWIRE / July 14, 2022 / Linde (NYSE:LIN; FWB:LIN) today announced it has been included in the FTSE4Good Index for the seventh consecutive year. The FTSE4Good Index Series is a tool for investors seeking to invest in companies that demonstrate strong environmental, social and governance (ESG) practices measured against international standards.',
		source: 'Accesswire',
		url: 'https://www.accesswire.com/viewarticle.aspx?id=708557'
	}
];

export default HeadLinesData;
