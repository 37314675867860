import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../auth/AuthContext';
import { useForm } from 'react-hook-form';
import useDataApi from '../../hooks/useDataApi';
import './SignInPage.css';
import SignInLeftPanel from '../../components/signInLeftPanel/SignInLeftPanel';
import { NavLink } from 'react-router-dom';
import { isDemoInstance } from '../../utils';

const View = ({ register, handleSubmit, onSubmit, errors, data }) => {
	return (
		<div className="signin-main">
			<SignInLeftPanel>
				<div className="welcome-text">
					<div className="welcome-back header-large">
						{isDemoInstance()
							? `Welcome to the free demo version of MachineTrader!`
							: `Welcome to your personal AWS cloud!`}
					</div>
					<p className="sign-text">
						{isDemoInstance()
							? `Enter the userid and password provided in the email you received when you signed up.`
							: ``}

						<span className="hide">
							If not, click the link to request a MachineTrader account.
						</span>
					</p>
				</div>
				
			</SignInLeftPanel>
			<div className="sign-right-side">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-header">Sign in</div>
					<div className="form-main-container">
						<div className="form-main-container">
							<div className="form-group-container ">
								<label> Username * </label>
								<input
									autoFocus={true}
									type="text"
									placeholder="example@gmail.com"
									name="username"
									className={`form-control-text-box ${
										errors.username && 'error'
									}`}
									{...register('username', { required: true })}
								/>
								{errors.username?.type === 'required' && (
									<span className="error">This field is required.</span>
								)}
							</div>
							<div className="form-group-container form-group-4">
								<label> Password * </label>
								<input
									type="password"
									placeholder="*********"
									name="password"
									className={`form-control-text-box ${
										errors.password && 'error'
									}`}
									{...register('password', { required: true })}
								/>
								{errors.password?.type === 'required' && (
									<span className="error">This field is required.</span>
								)}
							</div>
						</div>
					</div>
					<div className="button-div button_primary_orange-6">
						<button type="submit" className="button-orange">
							{' '}
							SIGN IN
						</button>
					</div>
					<div className="button-div-error">
						{data.error_description && (
							<span className="error">{data.error_description}</span>
						)}
					</div>

					<div className="button-div hide">
						<button className="button-white">
							<img className="button-icons" src="/images/linkedin.png" />
							Sign-In with Google
						</button>
					</div>
					<div className="button-div hide">
						<button className="button-blue">
							<img className="button-icons" src="/images/linkedin.png" />
							Sign-In with LinkedIn
						</button>
					</div>

					<div className="button-div hide">
						<button className="button-blck">
							<img className="button-icons" src="/images/github.png" />
							Sign-In with GitHub
						</button>
					</div>
					<div className="margin-top-30 margin-bottom-30">
						<p className="signup-link-txt">
							{isDemoInstance()
								? `Enter your userid and password above. If you are a new user and have not received a welcome email,`
								: ``}
						</p>
						{isDemoInstance() ? (
							<a
								href="https://www.machinetrader.io/sign-up-beta"
								className="link-text"
								target="_blank"
								rel="noreferrer">
								{' '}
								Click here
							</a>
						) : (
							<p>
							</p>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

const prepareFormBody = (params) => {
	let authItems = {
		grant_type: 'password',
		client_id: 'node-red-admin',
		scope: '*'
	};
	authItems.username = params.username;
	authItems.password = params.password;

	let formBody = [];
	for (const property in authItems) {
		const encodedKey = encodeURIComponent(property);
		const encodedValue = encodeURIComponent(authItems[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}
	formBody = formBody.join('&');
	console.log(formBody);
	return formBody;
};

const SignInPage = () => {
	const setIsAuthenticated = useContext(AuthContext)[1];
	const timeStamp = new Date().getTime();
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm();
	const [authData, setAuthData] = useState('');

	const [dataProps, setUrl] = useDataApi(
		'',
		{},
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
			body: authData
		}
	);

	useEffect(() => {
		if (!dataProps.isLoading && dataProps.data) {
			if (dataProps.data?.access_token) {
				localStorage.setItem('auth-tokens', JSON.stringify(dataProps.data));
				setIsAuthenticated(true);
			}
		}
	}, [dataProps]);

	const onSubmit = (params) => {
		setAuthData(prepareFormBody(params));
		setUrl('/auth/token' + '?hash=' + timeStamp);
	};
	return <View data={dataProps.data} {...{ register, handleSubmit, onSubmit, errors }} />;
};

export default SignInPage;
