import { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import useDataApi from '../../hooks/useDataApi';
import loadChartModules from './loadChartModules';
import * as ChartConfig from './config/ChartConfig';
import { linearChartData } from './utils';
import { getSymbolType } from '../../utils';
import './Chart.css';

const View = ({
	isError,
	isLoading,
	data,
	toggleFullScreen,
	rangeSelectorReciever,
	rangeSelectorSelected,
	chart,
	props
}) => {
	return (
		<>
			{isError && <div>Something went wrong</div>}
			{isLoading ? (
				<div>Loading...</div>
			) : (
				<>
					<div className="fullscreen-container">
						<span>
							{props.symbol} ({props.label})
						</span>
						<a onClick={toggleFullScreen}>
							<img src="/images/icon_fullscreen.png"></img>
						</a>
					</div>
					<HighchartsReact
						highcharts={Highcharts}
						constructorType={'stockChart'}
						options={ChartConfig.Metrics(
							linearChartData(data).data,
							props.symbol,
							rangeSelectorReciever,
							rangeSelectorSelected
						)}
						ref={chart}
					/>
				</>
			)}
		</>
	);
};

const MetricsChart = (props) => {
	const chart = useRef();
	const [displayType, setDisplayType] = useState('min');
	const [chartParam, setChartParam] = useState(1);
	const url = `/timeseries?interval=${displayType}`;
	const [dataProps, setUrl] = useDataApi(
		url,
		{},
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				symbol: props.symbol,
				symbol_type: getSymbolType(props.symbol),
				account_type: props.accountType,
				interval: displayType
			})
		}
	);

	const rangeSelectorReciever = (selectedRange, selectedIndex) => {
		const isRangeExist = ['1D', '7D'].includes(selectedRange);
		setDisplayType(isRangeExist ? 'min' : 'day');
		setChartParam(selectedIndex);
	};

	useEffect(() => {
		setUrl(url);
	}, [url]);

	loadChartModules({
		chartType: 'metrics',
		...props
	});

	const toggleFullScreen = () => {
		chart && chart.current.chart.fullscreen.toggle();
	};
	return (
		<View
			{...dataProps}
			props={props}
			rangeSelectorReciever={rangeSelectorReciever}
			rangeSelectorSelected={chartParam}
			toggleFullScreen={toggleFullScreen}
			chart={chart}
			displayType={displayType}
		/>
	);
};

export default MetricsChart;
