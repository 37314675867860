export default [
	{
		symbol: 'VLON',
		name: 'Vallon Pharmaceuticals Inc. Common Stock',
		asset_class: 'stocks',
		closing: 133.55,
		price24h: 134.21,
		price7d: 131.5,
		change24h: 0.6599999999999966,
		change7d: 2.0500000000000114,
		pct24: 0.49419692998876563,
		pct7d: 1.5350056158742127
	},
	{
		symbol: 'GAME',
		name: 'Engine Gaming and Media, Inc. Common Stock',
		asset_class: 'stocks',
		closing: 168.1,
		price24h: 168.59,
		price7d: 165.45,
		change24h: 0.4900000000000091,
		change7d: 2.6500000000000057,
		pct24: 0.29149315883403276,
		pct7d: 1.5764425936942332
	},
	{
		symbol: 'BIVI',
		name: 'BioVie, Inc. Common Stock',
		asset_class: 'stocks',
		closing: 32.4,
		price24h: 32.755,
		price7d: 33.04,
		change24h: 0.355000000000004,
		change7d: -0.6400000000000006,
		pct24: 1.0956790123456914,
		pct7d: -1.9753086419753103
	},
	{
		symbol: 'SISI',
		name: 'Shineco, Inc. Common Stock',
		asset_class: 'stocks',
		closing: 143.43,
		price24h: 146.125,
		price7d: 141.99,
		change24h: 2.694999999999993,
		change7d: 1.4399999999999977,
		pct24: 1.8789653489507028,
		pct7d: 1.003974064003345
	}
];
